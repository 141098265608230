const pt = {
    "rated_google_reviews": "Avaliado como 5,0 no Google Reviews",
    "get_your_digital_menu": "Obtenha seu menu digital—",
    "instant_popup_menu": "Menu pop-up instantâneo sem leitura de QR",
    "free_forever": "Livre para sempre",
    "the_future_is_here": "O futuro está aqui!",
    "upgrade_your_restaurant": "Atualize seu restaurante com um menu digital deslumbrante a custo zero. Atraia mais clientes a partir de hoje.",
    "get_my_free_menu_now": "Obtenha meu menu grátis agora",
    "no_credit_card_required": "*Não é necessário cartão de crédito",
    "join_largest_marketplace": "Junte-se ao maior mercado de restaurantes!",
    "what_will_you_get": "O que você vai ganhar",
    "free": "LIVRE",
    "free_digital_menu": "Menu Digital Gratuito 2D ou 3D",
    "free_digital_menu_desc": "Diga adeus aos cardápios de papel e às reimpressões caras",
    "quick_and_easy_signup": "Inscrição rápida e fácil",
    "communicate_with_customers_title": "Comunique-se com clientes GRATUITAMENTE!",
    "features1": [
        "Economize em custos de impressão e aproveite atualizações instantâneas do menu sempre que precisar.",
        "Acesse seu menu digital em qualquer dispositivo",
        "Ecológico e sem complicações"
    ],
    "features2": [
        "Deixe que os clientes explorem virtualmente seus pratos com visuais 3D impressionantes.",
        "Aumente o envolvimento dando aos clientes uma prévia realista de sua refeição.",
        "Destaque-se com uma experiência de menu tão atraente quanto o sabor da sua comida."
    ],
    "features3": [
        "Receba os clientes imediatamente com seu menu quando eles entrarem no restaurante.",
        "Envie automaticamente atualizações sobre ofertas e promoções para seus telefones.",
        "Melhore a experiência gastronômica com acesso rápido e sem contato ao seu menu."
    ],
    "feature4": [
        "Destaque ofertas sazonais, especiais diários e promoções exclusivas.",
        "Chame a atenção dos clientes para seus itens mais populares.",
        "Crie urgência e entusiasmo com visuais e descrições atraentes."
    ],
    "feature5": [
        "Faça parte de uma comunidade online com milhares de visitantes diários.",
        "Aumente a visibilidade e alcance novos clientes que buscam ativamente opções gastronômicas.",
        "Garanta um lugar privilegiado com antecedência para maximizar sua exposição e atrair mais clientes."
    ],
    "section3_heading": "Por que se tornar digital? Porque é grátis e fácil!",
    "section3_description": "Desbloqueie recursos poderosos projetados para aumentar o apelo do seu restaurante.",
    "instant_menu": "Menu instantâneo",
    "instant_menu_notification": "Pop-up e notificação",
    "instant_menu_description": "Receba os hóspedes com acesso instantâneo ao menu assim que eles entrarem. Envie automaticamente o menu mais recente e os especiais diretamente para seus celulares",
    "digital_menu": "Cardápios Digitais",
    "at_zero_cost": "a custo zero",
    "digital_menu_description1": "Diga adeus aos cardápios de papel e reimpressões caras. Mude para um cardápio digital que está sempre atualizado — e é grátis para sempre!",
    "digital_menu_description2": "Com nosso menu digital, suas ofertas estão sempre atualizadas e acessíveis em qualquer dispositivo. Economize dinheiro e simplifique as atualizações do menu com uma solução perfeita e de custo zero.",
    "try_me_button": "Experimente-me",
    "try_3d_menu_title": "Menus 3D",
    "try_3d_menu_title2": "De graça — UAU!",
    "try_3d_menu_description1": "Faça seus pratos saltarem da tela. Carregue imagens 3D e deixe que os clientes experimentem virtualmente sua comida antes de entrarem.",
    "try_3d_menu_description2": "Imagens 3D de alta qualidade criam uma prévia de dar água na boca, aumentando o apelo e incentivando pedidos.",
    "try_now": "Experimente agora",
    "showcase_title": "Mostre o seu",
    "showcase_specials": "Especiais",
    "showcase_specials_description1": "Tem uma oferta de dar água na boca ou uma promoção imbatível? Destaque-a no seu menu digital e atraia mais pessoas famintas.",
    "showcase_specials_description2": "Faça promoções impossíveis de perder, chamando a atenção com recursos visuais ousados e descrições atraentes.",
    "marketplace": "Mercado",
    "marketplace_description": "Estamos criando um mercado com mais de 2.000 visitantes diários. Entre a bordo agora e você será o primeiro da fila quando lançarmos. Você pode alcançar novos clientes que estão procurando ativamente por opções de refeições. Garanta seu lugar com antecedência para maximizar a exposição!",
    "see_other_products": "Ver outros produtos",
    "about_us": "Sobre nós",
    "why_serving_free": "Por que estamos servindo isso para",
    "serving_free_highlight": "Livre",
    "about_desc": "Somos moradores locais aqui em Dallas, comprometidos em ajudar restaurantes como o seu a prosperar.",
    "about_tool_free": "Estamos oferecendo esta ferramenta poderosa de forma totalmente gratuita porque acreditamos em apoiar nossa comunidade.",
    "no_catch": "Não é brincadeira, não é pegadinha. Sabemos que quando você tem sucesso, nossa comunidade se torna mais forte. 😊",
    "understanding_challenges": "Entendemos os desafios de administrar um negócio, e é por isso que nos dedicamos a fornecer ferramentas que tornam suas operações mais fáceis e seu alcance mais amplo.",
    "community_growth": "À medida que você cresce, nossa comunidade cresce com você, criando um efeito cascata de sucesso e suporte. Juntos, vamos elevar a experiência gastronômica para todos.",
    "how_it_works": "Como funciona",
    "three_simple_steps": "Três passos simples para",
    "get_started_highlight": "Começar",
    "no_tech_skills_needed": "Não são necessárias habilidades tecnológicas. Se você consegue virar um hambúrguer, você consegue fazer isso!",
    "step1_title": "Exiba sua promoção GRATUITAMENTE!",
    "step1_desc": "Comece mais rápido do que você consegue dizer \"Faça seu pedido!\" Basta preencher um formulário simples.",
    "step2_title": "Personalize seu menu",
    "step2_desc": "Adicione seus pratos, carregue fotos (até mesmo em 3D) e destaque seus pratos especiais.",
    "step3_title": "Compartilhe e Cresça",
    "step3_desc": "Compartilhe seu cardápio digital por meio de QR codes, redes sociais ou seu site.",
    "testimonials": "Testemunhos",
    "testimonials_section_title": "Não acredite apenas na nossa palavra",
    "testimonials_section_desc": "Veja o que outros donos de restaurantes de Dallas estão dizendo.",
    "faq_section_title": "Tem perguntas? Nós temos respostas",
    "faq_section_desc": "Tudo o que você precisa saber antes de começar.",
    "faqs": "Perguntas frequentes",
    "faq_answers": [
        {
            "que": "É realmente grátis para sempre?",
            "ans": "Absolutamente! Sem taxas ocultas, sem cartões de crédito, sem amarras."
        },
        {
            "que": "Preciso de algum conhecimento técnico?",
            "ans": "Se você consegue enviar um e-mail, você consegue fazer isso. É simples assim."
        },
        {
            "que": "Por que você está oferecendo isso de graça?",
            "ans": "Acreditamos em dar primeiro. No futuro, podemos ter outros serviços que você vai adorar — mas isso depende inteiramente de você."
        },
        {
            "que": "Qual é a situação do mercado?",
            "ans": "Estamos cozinhando! Assim que tivermos restaurantes suficientes a bordo, lançaremos seu marketplace para que você tenha ainda mais exposição."
        }
    ],
    "testimonials_content1": "Mudar para um menu digital foi a melhor jogada que fizemos! Nossos clientes adoram a conveniência e os visuais atualizados!",
    "testimonials_name1": "Morris George",
    "testimonials_restaurant_name1": "O Restaurante Mansion",
    "testimonials_content2": "Nosso novo menu digital facilita a navegação dos clientes e realmente aumenta o engajamento!",
    "testimonials_name2": "Amélia Johnson",
    "testimonials_restaurant_name2": "Comidas urbanas",
    "testimonials_content3": "Os hóspedes ficam impressionados com os visuais 3D. Eles nos ajudam a nos destacar e melhoram a experiência gastronômica deles!",
    "testimonials_name3": "Davis Verde",
    "testimonials_restaurant_name3": "Lareira da cidade",
    "ready_to_boost_your_restaurant": "Pronto para impulsionar seu restaurante?",
    "ready_to_boost_desc": "Não fique para trás. Junte-se à revolução digital hoje mesmo!",
    "yes_i_want_my_free_digital_menu": "Sim, eu quero meu menu digital gratuito",
    "480_plus": "480+",
    "people_already_joined": "pessoas já aderiram",
    "menu_online_home": "Menu Início Online",
    "welcome": "Bem-vindo",
    "description_other": "Vamos aumentar a satisfação do seu cliente!",
    "benefits": "Benefícios",
    "greeting_text": "Bem-vindo 👋",
    "all_in_one_features": "🚀 Recursos completos para elevar seu restaurante",
    "menu_online_menu_name": "Cardápio Online",
    "menu_online_description": "Digitalize seu cardápio para facilitar o acesso online dos clientes.",
    "menu_online_benefits": "Aumente a conveniência do cliente e aumente o engajamento permitindo que ele visualize seu menu a qualquer hora e em qualquer lugar.",
    "ds_name": "Sinalização Digital",
    "ds_description": "Exiba ofertas e promoções em telas dinâmicas na loja.",
    "ds_benefits": "Cative a atenção dos clientes com recursos visuais envolventes, promovendo upsells e ofertas em tempo real sem esforço.",
    "loyalty_punch_name": "Cartão de fidelidade",
    "loyalty_punch_description": "Recompense clientes recorrentes com um programa de fidelidade digital.",
    "loyalty_punch_benefits": "Promova a retenção de clientes e aumente as vendas incentivando a fidelidade com recompensas resgatáveis por visitas frequentes.",
    "marketing_menu_name": "Marketing",
    "marketing_description": "Alcance mais clientes com campanhas de marketing digital direcionadas.",
    "marketing_benefits": "Expanda o alcance do seu restaurante e gere tráfego de clientes com promoções personalizadas para atrair clientes novos e recorrentes.",
    "get_more_reviews_menu_name": "Obtenha mais avaliações",
    "get_more_reviews_description": "Incentive clientes satisfeitos a deixar avaliações positivas on-line.",
    "get_more_reviews_benefits": "Aumente a credibilidade do seu restaurante e atraia novos clientes melhorando sua reputação online com avaliações mais positivas.",
    "guest_smart_wifi_menu_name": "Wi-Fi inteligente para hóspedes",
    "guest_smart_wifi_description": "Ofereça Wi-Fi gratuito para capturar dados dos hóspedes para marketing.",
    "guest_smart_wifi_benefits": "Aumente a satisfação do cliente enquanto coleta dados valiosos sobre eles para personalizar promoções futuras.",
    "reservation_menu_name": "Reserva",
    "reservation_description": "Permita que os clientes reservem mesas on-line com facilidade.",
    "reservation_benefits": "Melhore a experiência gastronômica minimizando os tempos de espera e simplifique as reservas para gerenciar melhor os assentos.",
    "pos_integration_menu_name": "Integração POS",
    "pos_integration_description": "Integre nosso sistema ao seu sistema POS.",
    "pos_integration_benefits": "Simplifique o processamento de pedidos e reduza erros mantendo seus sistemas online e na loja sincronizados.",
    "online_ordering_menu_name": "Pedidos on-line",
    "online_ordering_description": "Permita que os clientes façam pedidos diretamente do seu site ou aplicativo.",
    "online_ordering_benefits": "Aumente as vendas e reduza a dependência de plataformas de terceiros oferecendo uma opção de pedido direto que economiza taxas.",
    "pro_label": "Pró",
    "coming_soon": "Em breve",
    "locked_label": "Bloqueado",
    "forever_label": "Para sempre",
    "new_label": "Novo",
    "other_products_page_title": "Outros Produtos | Menu Online",
    "other_products_description": "Política de Privacidade Online",
    "other_products_header_title": "Outros produtos",
    "privacy_policy": {
        "title": "política de Privacidade",
        "lastUpdated": "Última atualização: 01 de julho de 2024",
        "introduction": {
            "header": "Política de Privacidade para Clientes Novos e Existentes",
            "paragraph1": "OS SEGUINTES PONTOS-CHAVE (INCLUINDO REFERÊNCIAS DE SEÇÃO) SÃO FORNECIDOS SOMENTE PARA SUA CONVENIÊNCIA E NÃO SUBSTITUEM OS TERMOS E CONDIÇÕES COMPLETOS. É SUA RESPONSABILIDADE LER ESTES TERMOS E CONDIÇÕES ANTES DE USAR O SERVIÇO MENUONLINE INC.",
            "paragraph2": "Usar o Menuonline significa que você concorda com os Termos e Condições e declara e garante que está autorizado por sua organização ou empresa a fazê-lo. (Consulte a seção 3 “Como funciona”)",
            "paragraph3": "Menuonline Inc é um serviço de marketing baseado em permissão. O envio deliberado de “spam” ou a violação dos Termos e Condições resultará no fechamento da sua conta. (Consulte s.16 “Uso Aceitável do Serviço”)",
            "paragraph4": "Os dados que você hospeda na Menuonline Inc, como registros de contato, conteúdo de e-mail e informações que você coleta por meio da Menuonline Inc, pertencem a você. (Consulte a seção 14 “O que você possui?”).",
            "paragraph5": "O software Menuonline Inc e o conteúdo em nosso site pertencem a nós. (Consulte s.13 “O que possuímos?”) Além de seus próprios usuários, os dados ou conteúdo não publicado que você hospeda com a Menuonline Inc podem ser visualizados apenas por funcionários e parceiros da Menuonline Inc que assinaram acordos de confidencialidade. (Consulte s.14 “O que você possui?”)",
            "paragraph6": "Todas as Informações Pessoais Identificáveis (PII) são mantidas apenas em servidores localizados no Canadá. (Consulte a seção 14 “O que você possui?”).",
            "paragraph7": "O serviço Menuonline Inc está sujeito às leis do Canadá e da província de Ontário. (Consulte s.24 “Lei Aplicável”)",
            "paragraph8": "Quaisquer solicitações de seus dados ou conteúdo por indivíduos, polícia ou outras agências reguladoras de dentro ou fora do Canadá sempre serão encaminhadas a você. A única exceção seria se isso nos colocasse em violação à lei canadense ou de Ontário; no entanto, em todos os casos, resistiremos vigorosamente a qualquer divulgação de seus dados por qualquer pessoa que não seja você. (Consulte s.14 “O que você possui?”).",
            "paragraph9": "A segurança da Menuonline Inc é tão boa quanto o seu gerenciamento de nomes de usuários e senhas. Não cobramos por contas de usuário da Menuonline Inc, então crie uma separada para cada usuário e mantenha as senhas em segredo. Você é responsável por sua conta. (Veja s.3 “Como funciona”)",
            "paragraph10": "Você pode cancelar sua conta a qualquer momento. Não oferecemos reembolsos de créditos não utilizados se você cancelar. (Veja s.3 “Como funciona”)"
        },
        "termsAndConditions": {
            "title": "TERMOS E CONDIÇÕES DA MENUONLINE INC",
            "lastUpdate": "ÚLTIMA ATUALIZAÇÃO: 14 de janeiro de 2020",
            "paragraph1": "Leia estes termos e condições de serviço (os “Termos”) cuidadosamente antes de usar este site (o “Site”). Estes Termos isentam a Menuonline Inc. (“Menuonline Inc”, “nós”, ou “nos“) e outros de responsabilidade e\/ou limitam nossa e a responsabilidade deles e contêm outras disposições importantes que se aplicam ao seu uso deste Site. Ao visitar ou usar este Site ou o Serviço, você concorda em seu próprio nome, e em nome de qualquer organização em cujo nome você possa agir (coletivamente referidos aqui como “você“), em aceitar e cumprir estes Termos para cada uso e cada visita a este Site."
        },
        "services": {
            "title": "Nossos serviços.",
            "paragraph": "Os serviços que oferecemos por meio do Site são um serviço que permite que você crie, envie e gerencie mensagens de e-mail (“E-mails”) para destinatários de sua escolha (o “Serviço”)."
        },
        "license": {
            "title": "Licença.",
            "paragraph": "Sujeito a estes Termos, a Menuonline Inc concede a você uma licença não exclusiva, limitada e intransferível durante o Prazo para visualizar o Site e usar o Serviço conforme permitido pelos recursos do Serviço. Reservamos todos os direitos não expressamente concedidos aqui no Serviço e no Conteúdo da Menuonline Inc (conforme definido abaixo)."
        },
        "emailingContent": {
            "title": "Enviando conteúdo por e-mail.",
            "paragraph": "Ao interagir com a Menuonline Inc por meio deste Site ou do Serviço, você concorda em fornecer informações verdadeiras e precisas. Além disso, você entende que fornecer informações confidenciais por e-mail é uma prática insegura. Você entende que é o único responsável por qualquer coisa que enviar a qualquer pessoa que use nosso Serviço."
        },
        "support": {
            "title": "Apoiar.",
            "paragraph": "Nós fornecemos suporte para ajudar você a se tornar autossuficiente com o Serviço. Nós fornecemos suporte na forma de documentação e vídeos no Site, no sistema de tickets de suporte da Menuonline Inc e por telefone. Você pode entrar em contato conosco clicando na aba “Suporte” na parte superior do Site. Não há cobrança pelo suporte do produto; no entanto, nós nos reservamos o direito de descontinuar o suporte para contas de clientes específicas a nosso critério."
        }
    },
    "terms_condition": {
        "title": "Termos de Serviço | Menu Online",
        "header": "Termos e Condições para Clientes Novos e Existentes"
    },
    "privacy_policy_title": "POLÍTICA DE PRIVACIDADE",
    "privacy_policy_effective_date": "Em vigor a partir de 16 de janeiro de 2020",
    "privacy_policy_general_title": "POLÍTICA GERAL DE PRIVACIDADE",
    "privacy_policy_intro": "menuonline.com (Menuonline e “nós”) entende a importância da proteção da privacidade das informações pessoais de indivíduos e a importância da segurança de informações confidenciais de todos os tipos. Criamos esta Política Privada (a “Política”) para demonstrar nosso comprometimento em proteger sua “privacidade”. Esta Política Privada explica:",
    "privacy_policy_points": [
        "Quais informações pessoais coletamos e por que as coletamos;",
        "Como usamos suas informações pessoais;",
        "Com quem compartilhamos suas informações pessoais; e",
        "As opções que você tem para acessar, atualizar e remover suas informações pessoais do serviço."
    ],
    "privacy_policy_pipeda": "O Menuonline gerencia a coleta de todas as informações de acordo com a Lei de Proteção de Informações Pessoais e Documentos Eletrônicos do Canadá (PIPEDA).",
    "privacy_policy_update_notice": "Observe que o Menuonline reserva-se o direito de atualizar ou alterar esta Política de Privacidade a qualquer momento. Nós o notificaremos sobre quaisquer alterações publicando uma Política atualizada neste site ou conforme disponibilizada por meio dos serviços. A versão mais atual desta Política regerá o uso das informações pessoais dos usuários pelo mycircle. Ao continuar acessando o site do mycircle ou ao continuar usando seus serviços após as alterações feitas nesta Política de Privacidade, você concorda em ficar vinculado aos termos revisados.",
    "privacy_policy_consent_title": "CONSENTIMENTO PARA INFORMAÇÕES PESSOAIS",
    "privacy_policy_consent_text": "Ao fornecer suas informações pessoais ao Menuonline por meio de nosso site ou de qualquer um de nossos formulários, você consente com a coleta, uso e divulgação de tais informações de acordo com esta Política ou para os propósitos identificados a você no momento em que forneceu as informações pessoais. Qualquer uso secundário das informações pessoais será processado somente com seu consentimento expresso, ou você terá a oportunidade de optar por não participar.",
    "privacy_policy_opt_out_text": "Você tem a oportunidade de retirar seu consentimento ou optar por sair a qualquer momento. Se desejar retirar seu consentimento ou optar por sair, você pode clicar no link de preferências de comunicação na parte inferior de qualquer e-mail que receber de nós.",
    "privacy_policy_info_collection_title": "INFORMAÇÕES QUE COLETAMOS",
    "privacy_policy_info_collection_text": "O Menuonline coleta e processa informações pessoais de acordo com esta Política. Não venderemos, compartilharemos ou alugaremos essas informações a terceiros, exceto conforme contabilizado nesta Política. O Menuonline coleta informações pessoais para fins de fornecer nossos serviços ou produtos a você.",
    "privacy_policy_info_types_text": "Os tipos de informações que o Menuonline coleta incluem:",
    "privacy_policy_account_holders_title": "Titulares de conta Menuonline",
    "privacy_policy_registration_info": "Informações de registro: Se você se inscrever para usar os serviços, precisará de uma conta Menuonline para se tornar um titular de conta (\"Titular da conta\"). Ao registrar uma conta, você será solicitado a fornecer certas informações básicas, como seu nome, endereço de e-mail, nome de usuário, senha, nome da empresa, ocupação, localização e número de telefone. As senhas são criptografadas - a equipe do Menuonline não consegue visualizar ou recuperar senhas.",
    "privacy_policy_billing_info": "Informações de cobrança: se você assinar nossos serviços, precisará nos fornecer informações de pagamento e cobrança.",
    "privacy_policy_cookies_info": "Cookies: Usamos cookies para rastrear dados de visitantes, como a origem das visitas ao site e o número de visitas ao nosso site.",
    "privacy_policy_ip_info": "Endereços IP: Endereços IP são coletados sobre você como parte do seu uso dos serviços (por exemplo, para fins de registro de localização em nível de país e endereços IP usados ao usar nossos serviços).",
    "privacy_policy_your_contacts_title": "Seus contatos",
    "privacy_policy_distribution_list": "Lista de distribuição: Como parte do seu uso de nossos serviços, você pode nos fornecer, ou nós podemos coletar como parte dos serviços, informações pessoais sobre seus contatos (\"Contatos\"). Essas informações incluem endereços de e-mail e podem incluir outras informações identificáveis, como nome, nome da empresa ou outras informações que você fornece ao Menuonline ao usar os serviços. Você deve garantir que obtém o consentimento apropriado de seus Contatos para permitir que suas informações sejam processadas por nós.",
    "privacy_policy_billing_info_contacts": "Informações de cobrança: se você assinar nossos serviços, precisará nos fornecer informações de pagamento e cobrança.",
    "privacy_policy_cookies_contacts": "Cookies: O Menuonline usará apenas cookies e tecnologias de rastreamento semelhantes para coletar e usar informações pessoais sobre seus Contatos se você tiver adicionado o código de rastreamento do Menuonline ao seu site. Quaisquer landing pages hospedadas pelo Menuonline terão automaticamente um código de rastreamento.",
    "privacy_policy_ip_contacts": "Endereços IP: Endereços IP são coletados sobre seus Contatos como parte dos serviços (por exemplo, para registrar a localização em nível de país e os endereços IP usados ao enviar um formulário e fornecer consentimento).",
    "privacy_policy_info_about_contacts_title": "INFORMAÇÕES QUE COLETAMOS SOBRE SEUS CONTATOS",
    "privacy_policy_info_about_contacts_notice": "*Esta seção se aplica somente às informações coletadas, usadas ou divulgadas pelo Menuonline sobre os Contatos de um Titular de Conta para fins de prestação de serviços*",
    "privacy_policy_info_about_contacts_desc": "O Menuonline somente coletará, usará ou divulgará informações pessoais sobre Contatos se o Titular da Conta tiver se registrado para certos serviços onde eles nos fornecem uma lista de distribuição. Os Titulares da Conta são os únicos responsáveis por garantir que cumpriram todas as leis aplicáveis ao fornecer informações pessoais sobre seus Contatos ao Menuonline por meio dos serviços.",
    "privacy_policy_info_auto_collect_title": "Informações que o Menuonline coleta automaticamente",
    "privacy_policy_info_auto_collect_desc1": "Quando os Contatos do Titular da Conta interagem com uma campanha de e-mail recebida de um Titular da Conta, ou interagem com um link para o site conectado de um Titular da Conta, o Menuonline pode coletar informações sobre o dispositivo desse Contato e a interação com o e-mail por meio do uso de cookies. Essas informações são coletadas apenas para fornecer os serviços e fornecer insights ao Titular da Conta.",
    "privacy_policy_info_auto_collect_desc2": "As informações que coletamos sobre o dispositivo de um Contato e os aplicativos que o Contato usa para acessar e-mails enviados pelo Titular da Conta, por meio de nossos serviços, podem incluir o endereço IP, o sistema operacional, o ID do navegador e outras informações relacionadas ao sistema e à conexão do Contato.",
    "privacy_policy_info_auto_collect_desc3": "As informações que coletamos sobre a interação do Contato com e-mails enviados por meio dos serviços podem incluir datas e horários em que o Contato acessa e-mails e atividades de navegação, além de informações sobre o desempenho dos serviços, como a capacidade de entrega de e-mails e outras comunicações enviadas ao Contato por um Titular da Conta por meio dos serviços.",
    "privacy_policy_info_auto_collect_desc4": "Observe que o Menuonline não entrará em contato com nenhum dos seus contatos, a menos que exigido pela lei aplicável.",
    "privacy_policy_why_we_collect_title": "POR QUE COLETAMOS SUAS INFORMAÇÕES",
    "privacy_policy_why_we_collect_desc": "O Menuonline coleta informações para os seguintes propósitos:",
    "privacy_policy_why_collect_1": "para fornecer aos Titulares de Conta Menuonline os serviços que eles assinam, incluindo o uso de informações do Titular de Conta para os serviços;",
    "privacy_policy_why_collect_2": "para administração e gerenciamento de contas, bem como acesso ao site do mycircle;",
    "privacy_policy_why_collect_3": "se você consentiu em receber materiais de marketing e promocionais, para informá-lo sobre outros produtos e serviços oferecidos pela Menuonline; e",
    "privacy_policy_why_collect_4": "para responder às comunicações do Titular da Conta ou para enviar informações solicitadas por você. Isso pode incluir, mas não está limitado a:",
    "privacy_policy_why_collect_5": "ofertas especiais e atualizações;",
    "privacy_policy_why_collect_6": "boletim informativo; e",
    "privacy_policy_why_collect_7": "anúncios de serviço.",
    "privacy_policy_opt_out_desc": "Você pode, a qualquer momento, optar por não receber mais comunicações clicando no botão de cancelamento de inscrição na parte inferior de cada e-mail enviado pelo Menuonline para você.",
    "privacy_policy_use_disclosure_title": "USO E DIVULGAÇÃO DE INFORMAÇÕES PESSOAIS",
    "privacy_policy_use_disclosure_desc1": "As informações pessoais enviadas ao Menuonline, incluindo por meio do site do Menuonline ou de um formulário prescrito, são mantidas estritamente confidenciais, a menos que sejam divulgadas de acordo com o consentimento expresso do Titular da Conta ou requisitos legais. Em qualquer caso, qualquer informação pessoal fornecida em qualquer plataforma \"Menuonline\" pelos Titulares da Conta é voluntária: qualquer informação pessoal fornecida por um Titular da Conta é enviada a critério do Titular da Conta. Observe que revogar seu consentimento pode resultar na impossibilidade de acessar ou utilizar determinados serviços do Menuonline.",
    "privacy_policy_use_disclosure_desc2": "O Menuonline usa terceiros e provedores de serviços externos com relação ao seu site e serviços. As informações pessoais do Titular da Conta podem ser compartilhadas com tais terceiros e provedores. Também podemos fornecer estatísticas agregadas sobre Titulares da Conta, vendas, padrões de tráfego e informações relacionadas a terceiros respeitáveis. O Menuonline exige que qualquer provedor terceirizado que tenha acesso a informações pessoais esteja vinculado a obrigações consistentes com esta Política de Privacidade e que tais provedores apenas coletem, usem ou divulguem as informações pessoais do Titular da Conta para fins de fornecer serviços Menuonline.",
    "privacy_policy_use_disclosure_desc3": "As informações pessoais fornecidas pelos Titulares de Conta não serão vendidas, compartilhadas ou alugadas para organizações externas, a menos que de acordo com seu consentimento implícito ou expresso. O Menuonline reserva-se o direito de entrar em contato com quaisquer Titulares de Conta para encaminhar informações, responder a perguntas ou fornecer notificação de quaisquer alterações em nosso programa ou em nossas políticas. Observe que o Menuonline não entrará em contato com nenhum de seus Contatos, a menos que exigido pela lei aplicável.",
    "privacy_policy_use_disclosure_desc4": "O Menuonline divulgará informações pessoais somente se o consentimento for dado pelo indivíduo relevante ou:",
    "privacy_policy_disclosure_list_1": "quando permitido ou exigido por lei, como em resposta a uma intimação ou outro processo legal;",
    "privacy_policy_disclosure_list_2": "a um cessionário de todo ou parte do Menuonline;",
    "privacy_policy_disclosure_list_3": "aos provedores de serviços do Menuonline de acordo com esta Política; ou",
    "privacy_policy_disclosure_list_4": "para cumprir obrigações legais, incluindo, mas não se limitando a, obrigações de relatórios regulatórios.",
    "privacy_policy_service_providers_title": "O Menuonline utiliza os seguintes terceiros e prestadores de serviços (as informações pessoais dos seus Contatos não são divulgadas a \"tais\" terceiros):",
    "privacy_policy_service_provider_1": "Usamos o ManyChat para fornecer serviços de bate-papo no site para vendas e suporte, bem como e-mails de acompanhamento sobre essas conversas.",
    "privacy_policy_service_provider_2": "Usamos o AskNicely para pesquisar nossos clientes com o objetivo de perguntar sobre a qualidade do serviço e do suporte que eles recebem.",
    "privacy_policy_service_provider_3": "Usamos o join.me para agendar reuniões com clientes e clientes em potencial, chamadas de suporte e demonstrações de software.",
    "privacy_policy_service_provider_4": "Usamos o Google Analytics para rastrear atividades anônimas do site e medir os padrões de uso em nosso site.",
    "privacy_policy_breach_notification_title": "NOTIFICAÇÃO DE VIOLAÇÃO",
    "privacy_policy_breach_notification_desc": "O Menuonline cumprirá e fornecerá aos Titulares da Conta assistência razoável em conformidade com as leis aplicáveis com relação a qualquer uso, acesso ou divulgação não autorizados de informações pessoais.",
    "privacy_policy_rights_access_title": "SEUS DIREITOS E ACESSO",
    "privacy_policy_rights_access_desc": "Você tem o direito de acessar informações pessoais que o Menuonline processa sobre você. Você pode solicitar de nós informações sobre:",
    "privacy_policy_rights_access_list_1": "As informações pessoais que temos sobre você",
    "privacy_policy_rights_access_list_2": "As categorias de informações pessoais em questão",
    "privacy_policy_rights_access_list_3": "As finalidades do processamento",
    "privacy_policy_rights_access_list_4": "Detalhes para quem suas informações pessoais foram\/serão divulgadas",
    "privacy_policy_rights_access_list_5": "Por quanto tempo retemos suas informações pessoais",
    "privacy_policy_rights_access_list_6": "Se não coletamos as informações diretamente de você, as informações sobre a fonte",
    "privacy_policy_rights_access_list_7": "Como apresentar uma reclamação à autoridade de supervisão correta",
    "privacy_policy_rights_access_list_8": "Você também pode solicitar de nós o seguinte:",
    "privacy_policy_rights_access_list_9": "Que atualizemos qualquer informação incompleta ou imprecisa sobre você",
    "privacy_policy_rights_access_list_10": "Solicitar que excluamos suas informações pessoais de acordo com as leis aplicáveis",
    "privacy_policy_rights_access_contact": "Você pode solicitar que ajamos em relação aos seus direitos entrando em contato conosco em 2851 Dufferin Street, Toronto, ON, Canadá, ou enviando um e-mail para {link}.",
    "privacy_policy_accountability_title": "RESPONSABILIDADE",
    "privacy_policy_accountability_desc": "O Menuonline está comprometido em ser responsável pelas informações pessoais e confidenciais que você nos fornece. Para informações de contato, consulte o final desta Política.",
    "privacy_policy_links_other_sites_title": "LINKS PARA OUTROS SITES",
    "privacy_policy_links_other_sites_desc1": "O site da Menuonline pode conter links para outros sites. Esteja ciente de que a Menuonline não é responsável pelas práticas de privacidade de outros sites. Incentivamos nossos Titulares de Conta a estarem cientes de que, quando saírem do nosso site, devem ler atentamente as declarações de política de privacidade de todo e qualquer site que colete informações pessoalmente identificáveis.",
    "privacy_policy_links_other_sites_desc2": "Esta Política de Privacidade se aplica somente às informações coletadas por nós.",
    "privacy_policy_links_other_sites_desc3": "Se você usar um link para ir do site Menuonline para comprar produtos ou serviços, estará concluindo uma transação de compra de acordo com as regras e regulamentos da empresa fornecedora, não do Menuonline.",
    "privacy_policy_cookies_title": "POLÍTICA DE PRIVACIDADE DE COOKIES",
    "privacy_policy_cookies_desc1": "O site e os serviços do Menuonline usam cookies para coletar e armazenar certas informações de acordo com esta política. Um \"cookie\" é um pequeno arquivo de texto enviado de um site e armazenado no computador do usuário pelo navegador da web do usuário. Ao visitar um site que usa cookies, um cookie é baixado para seu computador ou dispositivo móvel. Na próxima vez que você visitar esse site, seu dispositivo se lembrará de informações úteis, como preferências, páginas visitadas ou opções de login.",
    "privacy_policy_cookies_desc2": "Os cookies são amplamente utilizados para adicionar funcionalidade aos sites ou para garantir que eles funcionem de forma mais eficiente. Nosso site depende de cookies para otimizar a experiência do usuário e garantir que os serviços do site funcionem corretamente.",
    "privacy_policy_cookies_desc3": "A maioria dos navegadores da web permite algum controle para restringir ou bloquear cookies por meio das configurações do navegador. No entanto, se você desabilitar os cookies, poderá perceber que isso afetará sua capacidade de usar determinadas partes do nosso site ou serviços.",
    "privacy_policy_cookies_analytical_purpose": "<strong> Finalidades analíticas: <\/strong> Usamos cookies para analisar a atividade do Titular da conta para melhorar nosso site. Por exemplo, podemos usar cookies para analisar padrões agregados, como os recursos que os Titulares da conta usam. Podemos usar essa análise para obter insights sobre como melhorar a funcionalidade e a experiência do usuário em nosso site.",
    "privacy_policy_cookies_preferences_user_experience": "<strong> Suas Preferências e Experiência do Usuário: <\/strong> Usamos cookies para coletar certas informações sobre visitantes e Titulares de Conta, como tipo de navegador, servidor, preferência de idioma e configuração de país, a fim de armazenar as preferências do Titular de Conta em nosso site para tornar a experiência do Titular de Conta mais consistente e conveniente. Usamos cookies para manter seu estado de login quando você visita nosso site repetidamente.",
    "privacy_policy_cookies_targeted_ads": "<strong> Fornecer serviços de medição e anúncios mais bem direcionados e marketing: <\/strong> Usamos cookies, web beacons e outras tecnologias de armazenamento de parceiros terceirizados, como Google e Facebook, para serviços de medição, anúncios mais bem direcionados e para fins de marketing. Isso acontece quando você visita nosso site. Esses cookies, web beacons e outras tecnologias de armazenamento nos permitem exibir material promocional do Menuonline para você em outros sites que você visita na Internet.",
    "privacy_policy_security_title": "SEGURANÇA",
    "privacy_policy_security_desc1": "O Menuonline faz esforços para proteger informações confidenciais de todos os tipos, incluindo informações pessoais, com salvaguardas organizacionais, físicas, mecânicas e eletrônicas apropriadas à sensibilidade das informações. Nossos servidores estão localizados no Canadá e seguem os padrões da indústria relativos à segurança.",
    "privacy_policy_security_desc2": "Observe, no entanto, que não podemos garantir que as medidas que mantemos garantirão a segurança das informações.",
    "privacy_policy_retention_title": "RETENÇÃO",
    "privacy_policy_retention_desc": "Retemos informações pessoais sobre Titulares de Conta e Contatos pelo tempo necessário para os propósitos estabelecidos nesta Política de Privacidade e de acordo com as disposições de retenção nos Termos de Uso.",
    "privacy_policy_access_questions_title": "ACESSO, DÚVIDAS E PREOCUPAÇÕES",
    "privacy_policy_access_questions_desc": "Um indivíduo que enviou informações pessoais ao Menuonline pode enviar uma solicitação por escrito para acessar ou corrigir suas informações pessoais retidas pelo Menuonline.",
    "terms_obj": {
        "terms_conditions_title": "TERMOS E CONDIÇÕES",
        "terms_conditions_subtitle": "Termos e Condições para Clientes Novos e Existentes",
        "terms_conditions_desc1": "OS SEGUINTES PONTOS-CHAVE (INCLUINDO REFERÊNCIAS DE SEÇÃO) SÃO FORNECIDOS SOMENTE PARA SUA CONVENIÊNCIA E NÃO SUBSTITUEM OS TERMOS E CONDIÇÕES COMPLETOS. É SUA RESPONSABILIDADE LER ESTES TERMOS E CONDIÇÕES ANTES DE USAR O SERVIÇO Menuonline.",
        "terms_conditions_desc2": "Usar o Menuonline significa que você concorda com os Termos e Condições e declara e garante que está autorizado por sua organização ou empresa a fazê-lo. (Consulte a seção 3 “Como funciona”)",
        "terms_conditions_desc3": "Menuonline é um serviço de marketing baseado em permissão. O envio deliberado de “spam” ou a violação dos Termos e Condições resultará no fechamento da sua conta. (Consulte s.16 “Uso Aceitável do Serviço”)",
        "terms_conditions_desc4": "Os dados que você hospeda no Menuonline, como registros de contato, conteúdo de e-mail e informações que você coleta por meio do Menuonline pertencem a você. (Consulte a seção 14 “O que você possui?”).",
        "terms_conditions_desc5": "O software Menuonline e o conteúdo do nosso site pertencem a nós. (Consulte s.13 “O que possuímos?”)",
        "terms_conditions_desc6": "Além dos seus próprios usuários, os dados ou conteúdo não publicado que você hospeda com o Menuonline podem ser visualizados apenas por funcionários e parceiros do Menuonline que assinaram acordos de confidencialidade. (Consulte s.14 “O que você possui?”).",
        "terms_conditions_desc7": "Todas as Informações Pessoais Identificáveis (PII) são mantidas apenas em servidores localizados no Canadá. (Consulte a seção 14 “O que você possui?”).",
        "terms_conditions_desc8": "O serviço Menuonline está sujeito às leis do Canadá e da província de Ontário. (Consulte s.24 “Lei Aplicável”)",
        "terms_conditions_desc9": "Quaisquer solicitações de seus dados ou conteúdo por indivíduos, polícia ou outras agências reguladoras de dentro ou fora do Canadá sempre serão encaminhadas a você. A única exceção seria se isso nos colocasse em violação à lei canadense ou de Ontário; no entanto, em todos os casos, resistiremos vigorosamente a qualquer divulgação de seus dados por qualquer pessoa que não seja você. (Consulte s.14 “O que você possui?”).",
        "terms_conditions_desc10": "A segurança do Menuonline é tão boa quanto o seu gerenciamento de nomes de usuários e senhas. Não cobramos por contas de usuário do Menuonline, então crie uma separada para cada usuário e mantenha as senhas em segredo. Você é responsável por sua conta. (Veja s.3 “Como funciona”)",
        "terms_conditions_desc11": "Você pode cancelar sua conta a qualquer momento. Não oferecemos reembolsos de créditos não utilizados se você cancelar. (Veja s.3 “Como funciona”)",
        "terms_conditions_desc12": "Podemos cancelar sua conta a qualquer momento. Nós fornecemos reembolsos para créditos de e-mail e serviços não utilizados se cancelarmos sua conta. (Veja s.3 “Como funciona”)",
        "terms_conditions_desc13": "Seus dados serão excluídos após o cancelamento e também poderão ser excluídos se um usuário da conta não tiver feito login por 12 meses ou mais. Manteremos dados agregados da sua conta que não incluem informações pessoalmente identificáveis para fins estatísticos. (Consulte s.3 “Como funciona”)",
        "terms_conditions_desc14": "Os créditos de e-mail são comprados em uma base de pagamento conforme o uso e nunca expiram. A única exceção é se você não tiver feito login em sua conta por 12 meses. Neste caso, reservamo-nos o direito de excluir sua conta, seus dados e os créditos associados. (Consulte s.10 “Quanto eu pago pelo Serviço?”)",
        "terms_conditions_desc15": "Trabalhamos duro para garantir que o Menuonline esteja sempre funcionando e para fornecer aviso prévio se precisarmos suspender temporariamente o serviço para manutenção. No entanto, não garantimos que o serviço estará sempre funcionando e não somos responsáveis por quaisquer perdas que você sofra ao usar o Menuonline, incluindo perdas de sua parte se o sistema não estiver funcionando no momento em que você precisar dele. (Consulte s.17 “Isenções de responsabilidade”).",
        "terms_conditions_desc16": "Não monitoramos seu conteúdo ou para quem você envia mensagens. No entanto, monitoramos reclamações e outros problemas criados por suas atividades. Muitas reclamações ou problemas podem resultar no cancelamento do seu serviço a nosso critério. (Consulte s.7 “Monitoramento”)",
        "terms_conditions_desc17": "Estamos comprometidos em fornecer excelente Suporte ao Produto para ajudar você a se tornar autossuficiente com o Menuonline. Fornecemos suporte na forma de documentação e vídeo em nosso site, no sistema de tickets de suporte do Menuonline e por telefone. Não há cobrança pelo suporte ao produto; no entanto, reservamo-nos o direito de descontinuar o suporte para contas de clientes específicas a nosso critério. (Consulte s.5 “Suporte”)",
        "terms_conditions_desc18": "Product Services é para aqueles momentos em que você quer que façamos algum trabalho relacionado ao Menuonline em vez de fazê-lo você mesmo. Nossa taxa para Product Services é de US$ 135 por hora. Também fornecemos serviços de consultoria a taxas mais altas. Reservamo-nos o direito de alterar nossas taxas a qualquer momento. (Consulte s.6 “Product and Consulting Services”)",
        "terms_conditions_desc19": "Informaremos você se acreditarmos que houve uma violação de seus dados e faremos um esforço para fornecer detalhes e informações relacionadas. (Consulte s.14 “O que você possui?”)",
        "terms_conditions_desc20": "Reservamo-nos o direito de fazer alterações a este acordo a qualquer momento. Tentaremos informá-lo sobre alterações aos termos e condições, mas o uso do serviço significa que você concorda com as alterações nos Termos. (Consulte s.9 “Alterações”).",
        "terms_conditions_desc21": "Os Termos e Condições e nossa Política de Privacidade foram redigidos apenas em inglês, e você concorda que a versão em inglês dos Termos e Condições e da Política de Privacidade terá prioridade em caso de conflito com qualquer versão traduzida.",
        "terms_conditions_mycircle": "TERMOS E CONDIÇÕES DO MYCIRCLE",
        "terms_conditions_last_update": "ÚLTIMA ATUALIZAÇÃO: 14 de janeiro de 2020",
        "terms_conditions_usage_agreement": "Por favor, leia estes termos e condições de serviço (os 'Termos') cuidadosamente antes de usar este site (o 'Site'). Estes Termos isentam a Menuonline ('Menuonline', 'nós' ou 'nos') e outros de responsabilidade e\/ou limitam a nossa e a deles responsabilidade e contêm outras disposições importantes que se aplicam ao seu uso deste Site.",
        "terms_conditions_acceptance": "Ao visitar ou usar este Site ou o Serviço, você concorda, em seu próprio nome e em nome de qualquer organização em cujo nome você possa atuar (coletivamente referidas aqui como \"você\"), em aceitar e cumprir estes Termos para cada uso e cada visita a este Site.",
        "terms_conditions_services": "1. Nossos serviços.",
        "terms_conditions_services_desc": "Os serviços que oferecemos por meio do Site são um serviço que permite que você crie, envie e gerencie mensagens de e-mail ('E-mails') para destinatários de sua escolha (o 'Serviço').",
        "terms_conditions_license": "2. Licença.",
        "terms_conditions_license_desc": "Sujeito a estes Termos, o Menuonline concede a você uma licença não exclusiva, limitada e intransferível durante o Prazo para visualizar o Site e usar o Serviço conforme permitido pelos recursos do Serviço. Reservamos todos os direitos não expressamente concedidos aqui no Serviço e no Conteúdo do Menuonline (conforme definido abaixo).",
        "terms_conditions_account": "3. Como funciona.",
        "terms_conditions_account_desc": "Para usar o Serviço, você precisa registrar uma conta. Sua conta é gratuita. Sua conta lhe dá acesso ao Serviço e às \"funções\" que podemos estabelecer e manter de tempos em tempos. Para ser elegível para uma conta, você deve:",
        "terms_conditions_account_requirements": [
            "ter pelo menos dezoito (18) anos de idade e estar apto a celebrar contratos;",
            "concluir o processo de registro que definimos;",
            "concordar com estes Termos e Condições;",
            "fornecer informações de contato e cobrança\/pagamento verdadeiras, completas e atualizadas."
        ],
        "responsibility": "Responsabilidade.",
        "responsibilityText": "Você é o único responsável pela atividade que ocorre em sua conta, e você deve manter a senha da sua conta confidencial e segura. As contas são gratuitas, então nós encorajamos você a configurar uma conta diferente para cada usuário que você tem. Você também é responsável por qualquer conta que você tenha acesso, independentemente de você ter autorizado ou não o uso dessa conta. Você concorda que é o único responsável por suas interações com aqueles para quem você envia e-mails, e você só enviará e-mails para aquelas pessoas das quais você tem consentimento. Você deve nos notificar imediatamente sobre qualquer uso não autorizado de suas contas.",
        "compliance_with_CASL": "Conformidade com a Legislação Antispam Canadense (CASL).",
        "compliance_with_CASL_text": "A função do Menuonline é facilitar o gerenciamento das configurações de consentimento, não impor a legislação. É sua responsabilidade estar em conformidade com a CASL. Nós o ajudamos definindo a funcionalidade padrão e facilitando a obtenção e atualização do consentimento expresso documentado e gerenciando datas de expiração contínuas para consentimento implícito. Você tem controle total sobre o gerenciamento do consentimento para seu banco de dados; você pode atualizar o status do consentimento para todos os seus contatos em massa a qualquer momento, substituir as configurações padrão e desabilitar a expiração automática do status do consentimento implícito.",
        "closing_account": "Encerrando sua conta.",
        "closing_account_text": "Você ou a Menuonline podem rescindir este Contrato a qualquer momento e por qualquer motivo, mediante Notificação à outra parte. Se você fornecer qualquer informação falsa, imprecisa, desatualizada ou incompleta ou usar o Serviço de uma forma que viole estes Termos e Condições, temos o direito de suspender imediatamente sua conta e rescindir seu acesso ao Serviço, além de quaisquer outros recursos disponíveis para nós. Se você não usar sua conta por doze (12) meses, podemos cancelá-la. Se rescindirmos sua conta por qualquer motivo, reembolsaremos você por quaisquer Créditos que você tenha deixado. Uma vez rescindida, excluiremos permanentemente sua conta e todos os dados associados a ela, incluindo os E-mails que você enviou. Podemos manter dados agregados de sua conta que não incluem informações de identificação pessoal para fins estatísticos.",
        "emailing_content": "Enviando conteúdo por e-mail.",
        "emailing_content_text": "Ao interagir com o Menuonline por meio deste Site ou do Serviço, você concorda em fornecer informações verdadeiras e precisas. Além disso, você entende que fornecer informações confidenciais por e-mail é uma prática insegura. Você entende que é o único responsável por qualquer coisa que enviar a qualquer pessoa que use nosso Serviço. propósitos.",
        "support": "Apoiar.",
        "support_text": "Nós fornecemos suporte para ajudar você a se tornar autossuficiente com o Serviço. Nós fornecemos suporte na forma de documentação e vídeos no Site, no sistema de tickets de suporte Menuonline e por telefone. Você pode entrar em contato conosco clicando na aba “Suporte” na parte superior do Site. Não há cobrança pelo suporte do produto; no entanto, nós nos reservamos o direito de descontinuar o suporte para contas de clientes específicas a nosso critério.",
        "product_and_consulting_services": "Produtos e serviços de consultoria.",
        "product_and_consulting_services_text": "Product Services é para aqueles momentos em que você quer que façamos algum trabalho relacionado ao Menuonline em vez de fazer você mesmo. Nossa taxa para Product Services é de US$ 125 por hora. Também fornecemos serviços de consultoria a taxas mais altas. Reservamo-nos o direito de alterar nossas taxas a qualquer momento.",
        "monitoring": "Monitoramento.",
        "monitoring_text": "Não monitoraremos ou julgaremos o conteúdo das informações transmitidas pelo Serviço, mas investigaremos se recebermos reclamações de possível uso inapropriado. O Menuonline pode, a qualquer momento e a seu exclusivo critério, fazer determinações de que usos específicos são ou não apropriados com ou sem aviso a você, de acordo com as seguintes diretrizes.",
        "term": "Prazo.",
        "term_text": "O Termo começa quando você se inscreve para uma conta conosco e continua enquanto você usar o Serviço. Você declara e garante que tem autoridade e capacidade para se inscrever para uma conta para nós (incluindo se você se inscrever em nome de uma organização).",
        "changes": "Mudanças.",
        "changes_text": "O Termo começa quando você se inscreve para uma conta conosco e continua enquanto você usar o Serviço. Você declara e garante que tem autoridade e capacidade para se inscrever para uma conta para nós (incluindo se você se inscrever em nome de uma organização).",
        "payment": "PAGAMENTO",
        "what_do_i_pay_for_service": "Quanto pago pelo serviço?",
        "what_do_i_pay_for_service_text": "Você pode comprar créditos pré-pagos para enviar e-mails (“Créditos”) por meio do nosso Serviço, conforme descrevemos em detalhes na página “preços” do nosso Site. Os Créditos nunca expiram e não há taxas recorrentes para esses Créditos. A única exceção é que se você não usar sua conta por doze (12) meses, podemos cancelar sua conta e quaisquer Créditos não utilizados. Podemos alterar nossos preços a qualquer momento atualizando as páginas de preços do nosso Site, o que será aplicado à sua próxima compra de Créditos.",
        "how_do_i_pay_for_service": "Como pago pelo serviço?",
        "how_do_i_pay_for_service_text": "Você pode pagar por Créditos usando qualquer cartão de crédito principal. Podemos alterar os métodos de pagamento que aceitamos a qualquer momento.",
        "can_i_get_refund": "Posso obter um reembolso?",
        "can_i_get_refund_text": "Os valores que você pagou para usar o Serviço não são reembolsáveis se você cancelar sua conta. Se cancelarmos sua conta, reembolsaremos você por quaisquer Créditos que você tenha deixado.",
        "rights": "DIREITOS",
        "what_do_we_own": "O que possuímos?",
        "what_do_we_own_text": "Exceto pelo conteúdo que você nos fornece (incluindo, sem limitação, registros de contato, conteúdo de e-mail e informações que você coleta por meio do Serviço) (“Seu Conteúdo”), todos os materiais incluídos em nosso Site, o Serviço e todos os materiais nele contidos ou transferidos por meio dele, incluindo, sem limitação, software, imagens, texto, gráficos, ilustrações, logotipos, patentes, marcas registradas, marcas de serviço, fotografias, áudio, vídeos, música e conteúdo pertencentes a outros Usuários (o “Conteúdo Menuonline”) (e todos os direitos de propriedade intelectual relacionados a eles) são de nossa propriedade ou de nossos licenciadores.",
        "what_do_you_own": "O que você possui?",
        "what_do_you_own_text": "Não reivindicamos direitos de propriedade sobre Seu Conteúdo. Seu Conteúdo é seu e você retém a propriedade de Seu Conteúdo que você carrega no Serviço. Ao compartilhar Seu Conteúdo através do Serviço, você concorda em permitir que outros visualizem, editem e\/ou compartilhem Seu Conteúdo de acordo com suas configurações e estes Termos e Condições. Você declara e garante que possui ou tem permissão para usar todo o material que você usa em seus E-mails. Podemos usar ou divulgar seus materiais apenas conforme descrito nestes Termos e em nossa Política de Privacidade.",
        "privacy_statement": "O Menuonline respeita a privacidade de seus usuários. Qualquer informação pessoal que o Menuonline coleta por meio deste Site ou do Serviço está sujeita à Declaração de Privacidade do Menuonline, que é incorporada por referência a estes Termos. Podemos usar e divulgar suas informações de acordo com nossa Declaração de Privacidade.",
        "access_to_your_content": "O Menuonline não fornecerá acesso ao Seu Conteúdo a ninguém, exceto aos funcionários e contratados do Menuonline que tenham executado acordos de confidencialidade apropriados conosco ou que tenham o seu consentimento.",
        "governmentRequest": "Se recebermos uma solicitação de um governo ou autoridade regulatória para ter acesso ao Seu Conteúdo, nós o notificaremos sobre a solicitação e buscaremos seu consentimento antes de divulgarmos qualquer Conteúdo Seu. Resistiremos a quaisquer solicitações de Seu Conteúdo e não divulgaremos Seu Conteúdo em resposta a esse tipo de solicitação sem sua permissão ou sendo exigido por um tribunal de jurisdição competente ou pelo órgão regulatório aplicável.",
        "breachNotification": "No caso de tomarmos conhecimento de que Seu Conteúdo foi sujeito a acesso não autorizado, uso, cópia, roubo, perda ou uso ou divulgação em violação destes Termos (uma 'Violação'), nós o notificaremos prontamente e o informaremos sobre o que ocorreu. Você concorda que as Violações serão tratadas de acordo com a lei aplicável e, se precisarmos que você informe os membros de suas listas de distribuição de E-mail, você concordará em fazê-lo prontamente e conforme nossas instruções.",
        "contentRetention": "Nós reteremos, usaremos e transmitiremos Seu Conteúdo no Canadá, exceto com sua aprovação ou direção prévia por escrito (por exemplo, se você escolher enviar Seu Conteúdo para indivíduos fora do Canadá). Os servidores usados para fornecer o Serviço estão localizados no Canadá.",
        "accessTrouble": "Se você tiver problemas para acessar seu Conteúdo, o Menuonline ajudará você a recuperá-lo com base no tempo e nos materiais.",
        "reportingAbuse": "Se você acredita que alguém está violando qualquer um destes Termos, por favor, nos notifique imediatamente. Se você recebeu spams que você acha que vieram de outro usuário do Serviço, se você acha que outro usuário do Serviço postou material que viola quaisquer direitos autorais, ou se você acha que outro usuário do Serviço está violando estes Termos, por favor, entre em contato conosco em Support@menuonline.com.",
        "acceptableUse": "Você declara e garante que seu uso do Serviço estará em conformidade com todas as leis e regulamentos aplicáveis. Você concorda que é responsável por determinar se o Serviço estará em conformidade e é adequado para você usar em sua jurisdição. Para maior certeza, você concorda que deverá (e concorda em garantir que seus funcionários, agentes e usuários autorizados deverão) ...",
        "exportLaws": "Não fazemos nenhuma representação de que o Site ou o Serviço seja apropriado ou esteja disponível para uso fora do Canadá, e o acesso a eles de territórios onde seus conteúdos sejam ilegais é proibido. Você não pode usar, exportar ou reexportar o Conteúdo Menuonline ou outros materiais neste Site ou qualquer cópia ou adaptação em violação a quaisquer leis ou regulamentos aplicáveis, incluindo, sem limitação, leis e regulamentos de exportação canadenses e norte-americanos.",
        "disclaimers": "O Serviço e este Site são fornecidos \"como estão\", sem quaisquer representações, garantias ou condições de qualquer tipo, e o Menuonline expressamente se isenta, na extensão máxima permitida pelas leis aplicáveis, de todas as representações, garantias e condições, expressas ou implícitas, por operação de lei ou de outra forma, incluindo, sem limitação, quaisquer garantias e condições implícitas de comercialização ou adequação a qualquer propósito ou uso específico, ou não violação de direitos de terceiros...",
        "limitLiability": "A responsabilidade agregada da Menuonline sob este Contrato, seja por contrato ou ato ilícito (incluindo negligência), como resultado de violação de garantia, responsabilidade estrita ou sob qualquer outra teoria de responsabilidade, será limitada a danos diretos em um valor não superior ao total de taxas pagas à Menuonline sob estes Termos e Condições nos doze (12) meses imediatamente anteriores ao mês em que ocorreu o evento mais recente que deu origem à responsabilidade.",
        "liabilityExclusions": "Nenhum dos Menuonline, suas afiliadas ou seus respectivos diretores, executivos, funcionários, agentes ou outros representantes (coletivamente, \"Representantes\" do Menuonline\") ou os operadores de quaisquer redes vinculadas terão qualquer responsabilidade ou obrigação em conexão com este Site ou o Serviço por: (i) quaisquer danos indiretos, consequenciais, incidentais, exemplares, punitivos ou especiais; ou (ii) por quaisquer danos, sejam diretos, indiretos, consequenciais, incidentais, exemplares, punitivos ou especiais, caracterizados como perda de receita, perda de economia ou receita ou lucros cessantes...",
        "indemnity": "Você indenizará e isentará a Menuonline e\/ou os Representantes da Menuonline (as \"partes indenizadas da Menuonline\") de quaisquer reivindicações apresentadas por terceiros e quaisquer multas ou penalidades aplicadas contra nós, decorrentes do seu uso das informações acessadas neste Site ou do seu uso do Serviço, e qualquer violação destes Termos...",
        "ipInfringement": "O Menuonline defenderá, indenizará e isentará você de todas e quaisquer reivindicações, demandas, ações, processos ou procedimentos feitos ou movidos contra você por terceiros alegando que seu acesso ou uso do Serviço de acordo com este Contrato infringe os direitos de propriedade intelectual sob a lei canadense de um terceiro (uma 'Reivindicação de Violação de PI')...",
        "equitableRelief": "Se você violar estes Termos, então poderemos buscar uma medida liminar (o que significa que podemos solicitar uma ordem judicial para impedi-lo) ou outra medida equitativa. Você concorda que isso será adicional e sem prejuízo de quaisquer outros direitos que possamos ter por lei ou em equidade.",
        "linksToOtherSites": "Se fornecermos quaisquer links para sites de terceiros, eles serão fornecidos apenas para conveniência. Se você usar esses links, você sairá do nosso Site. Nós não controlamos esses sites de terceiros nem os endossamos. Você concorda que não somos responsáveis por nenhum desses sites de terceiros ou seu conteúdo...",
        "thirdPartyRequests": "Se tivermos que fornecer informações em resposta a uma solicitação legal ou governamental relacionada à sua conta ou ao seu uso do Serviço, você concorda que podemos cobrar nossos custos. Essas taxas podem incluir nossos honorários advocatícios e os custos do tempo de nossos funcionários gastos preparando a resposta à solicitação.",
        "assignment": "Este Contrato e quaisquer direitos e licenças aqui concedidos não podem ser transferidos ou cedidos por você, mas podem ser cedidos pela Menuonline sem restrições, mediante aviso prévio razoável.",
        "governingLaw": "Estes termos serão regidos e interpretados de acordo com todas as leis federais aplicáveis e as leis de Ontário, sem levar em conta suas disposições de conflito de leis. Você concorda em se submeter à jurisdição exclusiva dos tribunais de Ontário para a determinação de qualquer disputa referente a estes Termos.",
        "severability": "Se, por qualquer motivo, um tribunal de jurisdição competente considerar qualquer disposição destes Termos ou parte deles ilegal ou, por qualquer motivo, inexequível, essa disposição será aplicada na extensão máxima permitida para dar efeito à intenção destes Termos, e o restante destes Termos continuará em pleno vigor e efeito e permanecerá exequível.",
        "waiver": "Qualquer renúncia ou consentimento para se afastar dos requisitos destes Termos será efetivo somente se for por escrito e assinado por nós, e somente na instância específica e para o propósito específico para o qual foi dado. Nossa falha em exercer ou nosso atraso em exercer qualquer direito sob estes Termos não operará como uma renúncia de nossos direitos.",
        "furtherAssurances": "Cada parte concorda em assinar e fornecer todos os documentos e tomar quaisquer ações (ou fazer com que essas ações sejam tomadas) necessárias para cumprir com suas obrigações sob estes Termos e Condições.",
        "notices": "Todos os avisos para o Menuonline devem ser por escrito e enviados para nós por e-mail. Avisos para nós devem ser enviados para a atenção de nossos representantes de atendimento ao cliente em support@menuonline.com. Avisos para você podem ser enviados para o endereço ou endereço de e-mail fornecido por você como parte do seu registro. Além disso, podemos transmitir avisos ou mensagens por meio deste Site para informá-lo sobre alterações neste Site ou outros assuntos de importância, e essas transmissões constituirão aviso para você no momento em que as publicarmos.",
        "entireAgreement": "Estes Termos e nossa Declaração de Privacidade constituem o acordo integral entre você e nós com relação ao seu uso do Site e do Serviço, e substituem todos os acordos, representações e entendimentos anteriores entre a Menuonline e você com relação a este assunto. No caso de qualquer conflito entre a Declaração de Privacidade e estes Termos, estes Termos e condições prevalecerão.",
        "language": "As partes solicitaram e exigiram expressamente que estes Termos e condições, nossa Política de Privacidade e quaisquer documentos relacionados sejam redigidos em inglês. As partes concordam e exigem expressamente que este Contrato e todos os documentos que se referem a eles sejam redigidos em inglês. Você concorda que a versão em inglês destes Termos e Política de Privacidade terá prioridade em caso de conflito com qualquer versão traduzida.",
        "survival": "Todas as disposições destes Termos que, por sua natureza ou importância, devam sobreviver à expiração ou rescisão destes Termos, sobreviverão, incluindo, sem limitação, as Seções 17 a 19."
    },
    "collect_additional_info": "Gostaria de coletar informações adicionais?",
    "additionalInfoChoice": "Escolha qualquer informação adicional que você gostaria de coletar.",
    "additional_info_yes": "Sim",
    "additional_info_noSkip": "Não, pule",
    "additional_info_label": "Informações adicionais",
    "confirm_business_location": "Vamos primeiro confirmar a localização da sua empresa",
    "custom_get_more_reviews_form": "Formulário personalizado para obter mais avaliações",
    "set_up_custom_form": "Configure seu formulário personalizado Obter mais avaliações por grupo",
    "reputation_name_label": "Obtenha mais avaliações Nome",
    "reputation_name_placeholder": "Insira o nome da reputação",
    "group_label": "Grupo",
    "group_desc_title": "Título da descrição do grupo",
    "group_desc": "Descrição do grupo",
    "select_group_placeholder": "Selecione o grupo",
    "required_group_name": "O nome do grupo é obrigatório",
    "before_asking_review": "Antes de pedir uma avaliação aos clientes, vamos fazer de 1 a 4 perguntas.",
    "feel_free_edit": "Sinta-se à vontade para editar conforme necessário.",
    "incentive_settings": "Configurações de incentivo",
    "incentivize_customer": "Gostaria de incentivar seu cliente a fazer uma avaliação?",
    "choose_incentive": "Escolha o incentivo que você gostaria de indicar.",
    "scan_qr_feedback": "Escaneie o código QR e compartilhe conosco seu valioso feedback sobre sua experiência.",
    "qr_code": "Código QR",
    "scan_me": "ESCANEIE-ME!",
    "or_title": "OU",
    "send_otp_title": "Enviar OTP",
    "sign_in_title": "Entrar",
    "email_addresss_title": "Endereço de email",
    "reset_password_title": "Redefinir senha",
    "remember_password_title": "Lembra da senha?",
    "digit_code_title": "Por favor, digite o código de 4 dígitos que foi enviado ",
    "not_received_title": "Não recebi um código de verificação!",
    "resend_title": "Por favor reenvie",
    "sign_up_form": "Crie sua conta",
    "sign_up_sub_title": "Faça login agora para acessar os insights mais recentes",
    "already_registered_title": "Já tem uma conta Menuonline?",
    "reserved_title": "Direitos autorais ©2023 Menuonline. Todos os direitos reservados.",
    "terms_title": "Termos de serviço",
    "policy_title": "política de Privacidade",
    "sign_up_title": "Inscrever-se",
    "terms_conditions_title": "Termos e Condições",
    "business_type_title": "Tipo de negócio",
    "business_type_sub_title": "Selecione um dos tipos de negócios que melhor descreve o seu negócio!",
    "salon_title": "Salão",
    "restaurant_title": "Restaurante",
    "pizza_store_title": "Loja de pizza",
    "food_truck_title": "Caminhão de comida",
    "check_domain_title": "Verifique o nome do domínio",
    "start_exploring_title": "Comece a explorar",
    "sign_in_form": "Entre na sua conta",
    "sign_in_sub_title": "Faça login agora para acessar os insights mais recentes",
    "remember_title": "Lembre de mim",
    "otp_title": "OTP",
    "password_title": "Senha",
    "forgot_title": "Esqueceu sua senha?",
    "not_registered_title": "Não tem uma conta?",
    "create_account_title": "Inscreva-se agora",
    "create_form_title": "Criar formulário",
    "first_name_title": "Primeiro nome",
    "last_name_title": "Sobrenome",
    "email_title": "E-mail",
    "mobile_number_title": "Número de telemóvel",
    "birthday_title": "Aniversário",
    "gender_title": "Gênero",
    "address_title": "Endereço",
    "short_answer_title": "Resposta curta",
    "long_answer_title": "Resposta longa",
    "single_answer_title": "Resposta única",
    "single_checkbox_title": "Caixa de seleção única",
    "multiple_choice_title": "Múltipla escolha",
    "drop_down_title": "Suspenso",
    "yes_no_title": "Sim ou não",
    "description_text_title": "Texto descritivo",
    "file_upload": "Upload de arquivo",
    "reset_password_form": "Redefinir sua senha",
    "reset_password_sub_title": "Redefina sua senha rapidamente e tenha acesso à sua conta.",
    "required_full_name_title": "Nome completo é obrigatório",
    "required_first_name_title": "O primeiro nome é obrigatório",
    "valid_first_name_title": "Insira um primeiro nome válido",
    "valid_full_name_title": "Insira um nome completo válido",
    "required_block_time": "O tempo de bloqueio é necessário",
    "required_last_name_title": "Sobrenome é obrigatório",
    "valid_last_name_title": "Insira um sobrenome válido",
    "valid_mobile_number_title": "Insira um número de celular válido",
    "required_mobile_number_title": "Número de celular é obrigatório",
    "required_already_mobile_number_title": "O número do celular já é necessário",
    "valid_otp_title": "Insira otp válido",
    "valid_email_title": "Insira um e-mail válido",
    "required_email_title": "E-mail é obrigatório",
    "required_description_title": "Descrição é necessária",
    "required_description_500_characters": "Por favor, insira pelo menos 500 caracteres",
    "required_description_600_characters": "Por favor, insira no máximo 600 caracteres",
    "domain_title": "Nome de domínio",
    "required_password_title": "Senha é necessária",
    "required_password_regex_title": "Deve conter no mínimo 8 caracteres, pelo menos uma letra maiúscula, uma letra minúscula, um número e um caractere especial",
    "required_terms_title": "Por favor, concorde com os termos e condições",
    "required_note_title": "Nota é necessária",
    "required_birthday_title": "Aniversário é obrigatório",
    "no_access_title": "Você não tem acesso agora. Entre em contato com seu administrador",
    "menu_online_open_txt": "Abrir",
    "loyalty_program_description": "😊 Encontre seu programa de fidelidade ideal e personalize-o para combinar com sua marca!",
    "lp_list_title": "Lista de cartões perfurados de fidelidade",
    "lp_n_title": "Nome do cartão de fidelidade",
    "enter_details_title": "Insira os detalhes",
    "stamp_allowed_title": "Selo permitido",
    "stamp_items_title": "Itens de selo",
    "lpn_req_title": "O nome do cartão de fidelidade é obrigatório",
    "valid_lpn_title": "Insira um nome válido para o cartão de fidelidade",
    "brand_color_error": "A cor da marca deve ter exatamente 7 caracteres",
    "select_dark_brand_color_title": "Selecione uma cor de marca válida",
    "your_brand_color_title": "A cor da sua marca",
    "upload_logo_title": "Carregar logotipo",
    "locations_title": "Locais",
    "add_lp_title": "Criar programa",
    "location_error": "Selecione os locais",
    "pvs_title": "Quantos selos um cliente pode ganhar por visita?",
    "max_apd_title": "Qual é o número máximo de selos que um cliente pode ganhar em um dia?",
    "logo_error": "O logotipo é obrigatório",
    "expiry_title": "Definir expiração para recompensas",
    "add_more_rewards_title": "+ Adicionar mais recompensas",
    "prev_step_1_title": "Mostre o QR para ganhar pontos e aproveitar suas recompensas.",
    "delete_lp_title": "Este cartão de fidelidade será excluído permanentemente.",
    "delete_user_record_title": "Este registro de usuário será excluído permanentemente.",
    "redeem_title": "Resgatar",
    "lp_button_title": "Junte-se ao Cartão de Fidelidade",
    "lp_download_button_title": "Baixar cartão",
    "step_0_title": "Cadastre-se agora, recompensas deliciosas aguardam por fãs fiéis da gastronomia como você.",
    "step_1_title": "Você se juntou",
    "congrats_step_title": "Agora você tem direito a pizza grátis",
    "reward_title": "Ganhe recompensas em cada visita",
    "visit_title": "Visite e desbloqueie",
    "rewards_title": "Recompensas",
    "dummy_address": "3730 Frankfort Ave, Louisville Kentucky, Estados Unidos",
    "loyalty_qr_default_text": "Escaneie o código QR, junte-se ao nosso cartão de fidelidade e aproveite as recompensas",
    "lp_earn_stamp_txt": "Os clientes ganham selos em cada visita.",
    "lp_get_rewards_txt": "Ganhe recompensas sempre que visitar",
    "lp_1_time_visit_txt": "1 visita que você receberá",
    "lp_uppercase_stamp_text": "Carimbo",
    "lp_lowercase_stamp_text": "carimbo",
    "lp_txt": "Lealdade",
    "lp_per_visit_stamp_validation_msg": "O carimbo por visita deve ser menor que o carimbo máximo permitido por dia",
    "lp_per_max_stamp_validation_msg": "O máximo de carimbo permitido por dia deve ser maior que o carimbo por visita",
    "lp_valid_value_msg": "Insira um valor válido",
    "lp_required_field_msg": "Este campo é obrigatório",
    "lp_stamp_required_msg": "Carimbo é obrigatório",
    "lp_valid_stamp_msg": "Insira um valor de carimbo válido",
    "lp_reward_name_required_msg": "O nome da recompensa é obrigatório",
    "lp_unique_stamp_msg": "O valor do selo deve ser único",
    "lp_benefits": "Um programa de fidelidade aumenta as visitas repetidas e a receita, ao mesmo tempo que fornece insights do cliente para marketing personalizado",
    "lp_how_customer_txt": "Veja como seus clientes",
    "lp_punch_card_txt": "cartão de fidelidade",
    "lp_look_txt": "vai olhar! 😍",
    "lp_desc": "Os clientes ganharão selos a cada visita e, quando acumularem o suficiente, poderão resgatar recompensas especiais.",
    "lp_edit": "Editar",
    "lp_looks_good_txt": "Parece bom",
    "lp_customer_view_instruction": "Isto é o que seu cliente verá. Carregue seu logotipo e defina o tema de cores",
    "lp_customer_earn_stamps": "Permita que os clientes ganhem selos em cada visita e gerenciem suas recompensas",
    "lp_stamp_rewards_setup": "Defina recompensas para ganhar selos, permita que os clientes ganhem recompensas por selos",
    "lp_loyalty_program_setup": "Dê o nome do seu programa de fidelidade e defina sua localização para ter este programa",
    "lp_prev_step1": "Mostre o QR para ganhar pontos e aproveitar suas recompensas.",
    "lp_visit_1_time_txt": "Visite 1 vez e ganhe",
    "lp_get_free_txt": "Obtenha gratuitamente",
    "lp_visit_allow_txt": "Visite e desbloqueie",
    "lp_rewards": "Recompensas",
    "repsT": "Obtenha mais avaliações",
    "offSup": "Material de escritório",
    "repsNxtV": "Na sua próxima visita",
    "addRepT": "Adicionar Obter mais avaliações",
    "repEntrCpnTxt": "Insira o texto do cupom",
    "repEntrCpnTxtBtn": "Insira o texto do botão de cupom",
    "repMngNxtV": "na sua próxima visita?",
    "repMngWldL": "Você gostaria de obter",
    "discount": "Desconto",
    "repDisTyp": "Tipo de desconto",
    "repNameT": "Dê um nome à sua reputação",
    "crtRepT": "Criar reputação",
    "feedBackT": "Opinião",
    "confLocT": "Confirmar localização",
    "businLocT": "Localização do negócio",
    "addInfoT": "Informações adicionais",
    "repNReqT": "Obtenha mais avaliações nome é obrigatório",
    "validRepNT": "Insira um nome de reputação válido",
    "groupT": "Grupo",
    "groupLDescTitleT": "Empurre contatos automaticamente para um grupo",
    "groupLDescT": "Todos os clientes que preencherem o formulário serão adicionados ao grupo selecionado.",
    "que2": "Sua comida estava boa?",
    "que3": "Nosso serviço foi amigável?",
    "que4": "Nosso serviço foi rápido?",
    "addAdditionalT": "Gostaria de acrescentar alguma informação adicional?",
    "phNoMandT": "O número de telefone é obrigatório!",
    "qrGenT": "QR gerado",
    "repFDT": "Sua contribuição ajudará nossa equipe a atendê-lo melhor!",
    "letdiT": "Vamos fazê-lo",
    "tak15secT": "Leva 15 segundos",
    "defQue": "Gostaria de receber ótimas ofertas e promoções de tempos em tempos?",
    "defNQue": "Gostaria que alguém respondesse a você?",
    "rateOnGoogleDesc": "Você poderia gentilmente nos avaliar no Google?",
    "mayBeNT": "Talvez na próxima vez",
    "yesSureT": "Sim, claro",
    "valid_name": "Insira um nome válido",
    "nameReq": "Nome é obrigatório",
    "backToMainT": "Voltar para a página principal",
    "attachCopT": "Gostaria de incentivar seu cliente?",
    "thankYouT": "Obrigado",
    "lftyT": "Aguardamos sua próxima visita!",
    "repPreT": "Prévia da reputação",
    "offer_exclusive_discounts": "Ofereça descontos e promoções exclusivas para atrair seus clientes.",
    "couponT": "Cupom",
    "locStepDesc": "Confirme a localização da sua empresa",
    "createRepStepDesc": "Crie um formulário de reputação personalizado por grupos",
    "feedbackStepDesc": "Conjunto de perguntas de feedback do cliente",
    "addInfoStepDesc": "Campos de clientes e configurações de incentivos",
    "FedbkkWDT": "Feedback com dados",
    "repAnlytT": "Análise de reputação",
    "todayT": "Hoje",
    "rep_last_7_days_t": "Últimos 7 dias",
    "last30DaysT": "Últimos 30 dias",
    "lastMT": "Mês passado",
    "custRangeT": "Faixa personalizada",
    "queWiseAnltT": "Análise de perguntas sábias",
    "atL1QueErrT": "Selecione pelo menos uma pergunta",
    "deleteRepTitle": "Esta reputação será excluída permanentemente.",
    "incvCustT": "Sim, incentive meu cliente",
    "collAddInfoT": "Sim, gostaria de coletar informações adicionais",
    "totRewT": "Revisão total",
    "totPosRewT": "Avaliação Totalmente Positiva",
    "negFeedT": "Feedback negativo",
    "posFeedT": "Feedback Positivo",
    "fineT": "Letras miúdas",
    "enterFineT": "Insira o texto em letras pequenas",
    "setThemeT": "Definir cor do tema",
    "que1T": "Pergunta 1",
    "que2T": "Pergunta 2",
    "que3T": "Pergunta 3",
    "que4T": "Pergunta 4",
    "entMailT": "Insira seu e-mail",
    "reputationQRDefTxt": "Escaneie o código QR e compartilhe conosco seu valioso feedback sobre sua experiência.",
    "selUser": "Selecionar usuário",
    "userT": "Usuário",
    "subUReq": "Selecione os usuários",
    "updateLoc": "Atualizar localização",
    "leadGenT": "Geração de leads",
    "displayT": "Mostrar",
    "ratingT": "Avaliação",
    "rep_dashboard_negative_feedback": "Feedback negativo 0%",
    "rep_dashboard_position_feedback": "Feedback Positivo 0%",
    "rep_dashboard_total_Feedback_txt": "Feedback total",
    "rep_dashboard_anony_Feedback": "Feedback anônimo",
    "rep_dashboard_Feeedback_Data_txt": "Feedback com dados",
    "rep_dashboard_review_txt": "Obtenha mais avaliações",
    "rep_dashboard_total_Review_txt": "Revisão total",
    "rep_dashboard_total_positive_Review_txt": "Avaliação Totalmente Positiva",
    "rep_dashboard_negative_feedback_txt": "Feedback negativo",
    "rep_dashboard_connection_txt": "Conexão",
    "rep_dashboard_question_wise_analytics_txt": "Análise de perguntas sábias",
    "rep_dashboard_date_label": "Selecione a data",
    "rep_dashboard_custom_range_txt": "Faixa personalizada",
    "rep_tlt_min": "REPRESENTANTE",
    "emojis": "Emojis",
    "media_text": "Adicionar mídia",
    "custom_field_text": "Inserir campos personalizados",
    "shortlink_text": "Adicionar link curto",
    "use_template_text": "Usar modelo",
    "banner_must_have": "Deve ter",
    "res_seamless": "Sem costura",
    "res_table_reservation": "Reserva de mesa",
    "res_system": "Sistema",
    "res_text": "Reservas",
    "md_easily": "Facilmente",
    "md_manage_customers": "Gerenciar clientes",
    "md_visits_interactions": "visitas e interações",
    "md_desc": "Facilite as refeições para seus clientes com reservas de mesa em tempo real. Gerencie reservas sem esforço, reduza os tempos de espera e garanta uma experiência tranquila para a equipe e os clientes. Mantenha seu restaurante totalmente reservado, mantendo serviço e satisfação de primeira qualidade.",
    "engage_convert": "Engajar e converter SMS",
    "coupon_campaign": "e Campanha de Cupons",
    "sms_read_rate": "As mensagens SMS são lidas 98% das vezes",
    "real_time_promo": "Entregue promoções em tempo real para ação imediata",
    "affordable_reach": "Maneira acessível de alcançar clientes com ROI sólido",
    "stay_in_touch": "Mantenha contato com seu cliente o tempo todo",
    "bc_engage_customers": "Envolva os clientes Converta SMS",
    "bc_coupon_campaign": "e Campanha de Cupons",
    "bc_sms_read_rate": "As mensagens SMS são lidas 98% das vezes",
    "bc_real_time_promo": "Entregue promoções em tempo real para ação imediata",
    "bc_affordable_reach": "Maneira acessível de alcançar clientes com ROI sólido",
    "bc_stay_in_touch": "Mantenha contato com seu cliente o tempo todo",
    "ar_automate_engagement": "Automatize o envolvimento do seu cliente",
    "ar_with": "com",
    "ar_with_auto_responders": "Respostas automáticas",
    "ar_personalized_messages": "Envie automaticamente mensagens personalizadas para aniversários, dê boas-vindas a novos clientes e reconecte-se com clientes antigos. Economize tempo enquanto mantém cada interação significativa e oportuna.",
    "sq_custom_forms_tools": "Formulários e ferramentas personalizados - Simplifique",
    "sq_custom_forms_ci": "Interações com o cliente",
    "sq_smart_qr_desc": "Crie formulários personalizados, colete assinaturas eletrônicas e aproveite códigos QR inteligentes para otimizar seus processos de negócios. Adapte cada ferramenta para atender às necessidades de seus clientes e aprimorar a experiência do usuário.",
    "gnc_get_new_cus": "Obtenha novos clientes",
    "gnc_get_customers": "das mídias sociais — rápido e fácil!",
    "gnc_social_media_power": "Use o poder das plataformas sociais para atrair novos clientes e direcionar vendas diretamente para o seu negócio. Redirecione pedidos de entrega de terceiros diretamente para sua loja ou plataforma de pedidos online para obter o máximo de controle e receita.",
    "wifi_connect_guests": "Conecte os hóspedes com",
    "wifi_smart": "Wi-Fi inteligente",
    "wifi_connect_grow": "& Aumente seus Insights",
    "wifi_smart_wifi_desc": "Ofereça uma conexão Wi-Fi sem complicações aos seus clientes por meio de uma tela inicial personalizada enquanto coleta dados valiosos dos clientes para aprimorar seus esforços de marketing e engajamento.",
    "wifi_create_splash_page": "Criar página inicial do Wi-Fi para convidados",
    "instant_menu_create": "Crie seu",
    "instant_menu_text": "Menu instantâneo",
    "instant_menu_wm": "em minutos",
    "instant_menu_desc": "Melhora a experiência do cliente permitindo atualizações em tempo real, fácil personalização e melhor engajamento, facilitando a exibição de suas ofertas e mantendo seu conteúdo atualizado.",
    "instant_menu_create_digital_menu": "Crie um menu digital",
    "loyalty_program_rewards": "Ganhe recompensas",
    "loyalty_program_on_visits": "em cada visita",
    "loyalty_program_create": "Criar programa de fidelidade",
    "reputation_create": "Criar reputação",
    "reputation_boost": "Aumente seu",
    "reputation_text": "Reputação",
    "reputation_reviews": "com mais avaliações!",
    "reputation_desc": "Colete feedback valioso de seus clientes para melhorar seu serviço e reputação. Compartilhe sua experiência e nos ajude a crescer — sua avaliação é importante!",
    "dashboard": "Painel",
    "blast_campaign": "Campanha Blast",
    "blast_campaigns": "Campanhas de explosão",
    "blast_campaign_sub_title": "Lance campanhas de explosão para alcançar todos os seus clientes em poucos cliques",
    "auto_responders": "Respostas automáticas",
    "auto_responder": "Resposta automática",
    "auto_responder_sub_title": "Promoções baseadas em eventos fáceis de usar para seus clientes",
    "smart_qr_form": "QR inteligente \/ Formulário",
    "get_new_customer": "Obter novo cliente",
    "marketing": "Marketing",
    "clients": "Clientes",
    "group_data": "Dados de grupos",
    "clients_data": "Dados de clientes",
    "account": "Conta",
    "user_acc_info": "Informações da conta do usuário",
    "current_plan": "Plano atual",
    "payments": "Pagamentos",
    "location": "Localização",
    "location_list": "Lista de localização",
    "users": "Usuários",
    "users_sub_title": "Lista de todos os usuários e seus detalhes",
    "online_profile": "Perfil on-line",
    "change_password": "Alterar a senha",
    "menu": "Menu",
    "reseller_dashboard": "Painel do revendedor",
    "companies": "Empresas",
    "plans_bought": "Planos comprados",
    "agreement_t_c": "Termos e condições do acordo",
    "wifi_dashboard": "Painel de controle do Wi-Fi",
    "hot_spot": "Ponto de acesso",
    "splash_pages": "Páginas de apresentação",
    "get_more_reviews": "Obtenha mais avaliações",
    "office_supplies": "Material de escritório",
    "reservation": "Reserva",
    "floor_table": "Chão e Mesa",
    "guest_smart_wifi": "Wi-Fi inteligente para hóspedes",
    "digital_signage": "Sinalização Digital",
    "schedule_down": "Programação para baixo",
    "business_settings": "Configurações de negócios",
    "sub_user_login": "Login de subusuário",
    "help_center": "Central de Ajuda",
    "switch_to_hub": "Mudar para o Hub",
    "meeting_records": "Registros de Reuniões",
    "logout": "Sair",
    "no_access_text": "Você não tem acesso agora. Entre em contato com seu administrador",
    "menu_item": "ITEM DO CARDÁPIO",
    "menu_capital": "MENU",
    "good_day": "Bom dia",
    "no_permission_text": "Você não tem permissão para executar esta ação!!",
    "enter_valid_pin": "Insira um PIN válido",
    "add_reservation": "Adicionar reserva",
    "edit_reservation": "Editar Reserva",
    "per_req": "Número de pessoas necessário",
    "req_block_time": "O tempo de bloqueio é necessário",
    "pending_status": "Pendente",
    "booked_status": "Reservado",
    "completed_status": "Concluído",
    "cancelled_status": "Cancelado",
    "reservation_slot": "Slot de reserva",
    "block_time": "Tempo de bloco",
    "slot_time": "Horário do intervalo de reserva:  ",
    "template_name_req": "O nome do modelo é obrigatório",
    "template_type_req": "O tipo de modelo é obrigatório",
    "sms_template_req": "O modelo de SMS é obrigatório",
    "group_req": "O grupo é obrigatório",
    "valid_date_time": "Insira uma data e hora válidas",
    "valid_group_name": "Insira um nome de grupo válido",
    "reservation_mark_completed": "Esta reserva será marcada como concluída.",
    "customer_name": "Nome do cliente",
    "date_time": "Data e hora",
    "table_no": "Tabela No.",
    "floor": "Chão",
    "customer_name_placeholder": "nome_do_cliente",
    "date_time_placeholder": "data_hora",
    "table_no_placeholder": "tabela_no",
    "floor_placeholder": "chão",
    "select_floor": "Selecione o andar",
    "select_table": "Selecionar tabela",
    "customer_name_macro": "%% nome_do_cliente %%",
    "date_time_macro": "%% data_hora %%",
    "table_no_macro": "%% tabela_no %%",
    "floor_macro": "%% chão %%",
    "template_name": "Nome do modelo",
    "login_with_4_digit_pin": "Faça login com seu PIN de 4 dígitos",
    "login_now_to_access_latest_insights": "Faça login agora para acessar os últimos insignts",
    "mkt_BS_Title": "SMS de explosão",
    "step_1": "Personalizar mensagem",
    "step_2": "Escolha o público",
    "step_3": "Mensagem de programação",
    "step_4": "Enviar uma mensagem",
    "step_5": "Gerenciar mensagem",
    "bs_tab_title": "Resumo da campanha Blast",
    "default_message": "Olá %% firstname %%, Desconto especial no brunch! Jante conosco hoje e ganhe desconto exclusivo de 10% na sua conta!",
    "bs_Title": "Escolha um tipo de campanha",
    "sms_title": "SMS\/MMS",
    "reach_out_to_customer": "Entre em contato com os clientes por meio de mensagens de texto e multimídia.",
    "wp_text": "O que você acha do WhatsApp?",
    "connect_with_wp": "Conecte-se com seus clientes usando o WhatsApp 💬",
    "send_discount_offers": "Envie ofertas de descontos via SMS 📲",
    "send_coupons_via_sms": "Envie cupons via SMS para seus clientes 🎟️",
    "env_Cap": "Ofereça descontos e promoções exclusivas para atrair seus clientes",
    "cmt_Caption": "Entre em contato com os clientes por meio de mensagens de texto e multimídia",
    "wp_caption": "Conecte-se com os clientes usando o WhatsApp para uma comunicação direta e contínua",
    "msg_Prev_Title": "Prévia da mensagem",
    "cou_Prev_Title": "Prévia do cupom",
    "blast_SMS": "Campanha SMS\/MMS",
    "bs_P_Msg": "Na fila",
    "bs_S_Msg_C": "Fracassado",
    "bs_F_Msg_Count": "Entregue",
    "previous": "Anterior",
    "next_ST": "Próximo",
    "next_S": "Próximo passo",
    "req_Temp_Name_Title": "O nome do modelo é obrigatório",
    "req_Template_Tx_Title": "O texto do modelo é obrigatório",
    "add_Temp_Title": "Adicionar modelo",
    "link_Desc": "Adicione um link de sua escolha para que os clientes possam reservar com você pelo Facebook, Google ou seu próprio site.",
    "audience_Title": "Público",
    "client_G1_Title": "Novos clientes",
    "client_G2_Title": "Clientes Recentes",
    "client_G3_Title": "Clientes fiéis",
    "client_G4_Title": "Clientes inadimplentes",
    "client_G5_Title": "Clientes por data de conexão",
    "on_Which_Day": "Em que dia",
    "on_Day": "No dia",
    "immediate_Desc": "Sua mensagem será enviada imediatamente. Clique em Avançar para começar.",
    "never_Desc": "Esta campanha está programada para ser executada para sempre até que você a encerre manualmente.",
    "end_Desc": "A campanha terminará na data que você escolher aqui.",
    "success_Message": "Campanha salva com sucesso!",
    "send_Success_Message": "Campanha enviada com sucesso!",
    "msg_Name_Field_Title": "Título da campanha",
    "valid_campaign_title": "Insira um título de campanha válido",
    "msg_Type_Title": "Tipo de mensagem",
    "sms_Desc": "1 Crédito por SMS",
    "mms_Desc": "3 créditos por MMS",
    "client_GTO_1": "Dia",
    "client_GTO_2": "Horas",
    "minutes": "Minutos",
    "client_GTO_4": "Semana",
    "time_Option_1": "Dia",
    "time_Option_2": "Horas",
    "c_Grp_Day_1": "90 dias",
    "c_Grp_Day_2": "60 dias",
    "c_Grp_Day_3": "30 dias",
    "first": "Primeiro",
    "second_T": "Segundo",
    "third_T": "Terceiro",
    "fourth_T": "Quarto",
    "fifth_T": "Quinto",
    "delete_BS_T": "Esta campanha será excluída permanentemente.",
    "delete_MBS_T": "Essas campanhas serão excluídas permanentemente.",
    "cFT_1": "Telefone",
    "cFT_2": "Contato Primeiro Nome",
    "cFT_3": "Sobrenome do contato",
    "cFT_4": "E-mail de contato",
    "cFT_5": "Observação",
    "cFT_6": "Aniversário",
    "cFT_7": "nome da empresa",
    "cF_Meta_Tag_1": "%% telefone %%",
    "cF_Meta_Tag_2": "%% primeiro nome %%",
    "cF_Meta_Tag_3": "%% sobrenome %%",
    "cF_Meta_Tag_4": "%% e-mail %%",
    "cF_Meta_Tag_5": "%% observação %%",
    "cF_Meta_Tag_6": "%% aniversário %%",
    "cF_Meta_Tag_7": "%% nome da empresa %%",
    "cF_Name_1": "telefone",
    "cF_Name_2": "primeiro nome",
    "cF_Name_3": "sobrenome",
    "cF_Name_4": "e-mail",
    "cF_Name_5": "observação",
    "cF_Name_6": "aniversário",
    "cF_Name_7": "nome da empresa",
    "all_clients": "Todos os clientes",
    "aud_Type_2": "Grupos de clientes",
    "aud_Type_3": "Grupos de clientes",
    "aud_Type_T1": "Todos os clientes",
    "aud_Type_T2": "Grupos de clientes",
    "aud_Type_T3": "Grupos de clientes",
    "new_clients": "Novos clientes",
    "client_G2": "Clientes Recentes",
    "client_G3": "Clientes fiéis",
    "client_G4": "Clientes inadimplentes",
    "client_G5": "Clientes por data de conexão",
    "never_text": "Nunca",
    "ending_on": "Terminando em",
    "send_T2": "Agendado",
    "send_Type_3": "Recorrente",
    "daily": "Diário",
    "weekly_T": "Semanalmente",
    "monthly_T": "Mensal",
    "yearly_T": "Anual",
    "each": "Cada",
    "on_the": "No",
    "monday": "Segunda-feira",
    "exclusive_offer": "Oferta exclusiva para você",
    "redeem_now": "Resgatar agora",
    "redeem_with_cashier": "Por favor, resgate com o caixa",
    "lg_Day_2": "Terça-feira",
    "lg_Day_3": "Quarta-feira",
    "lg_Day_4": "Quinta-feira",
    "lg_Day_5": "Sexta-feira",
    "lg_Day_6": "Sábado",
    "lg_Day_7": "Domingo",
    "msg_Pre_T": "Dominó",
    "cA_Edit_T1": "Todos",
    "cA_Edit_T2": "Incluído",
    "cA_Edit_T3": "Excluído",
    "SMS": "SMS",
    "MMS": "MMS",
    "usd_T": "USD",
    "cad_T": "CAD",
    "msg": "Mensagem",
    "which_Day": "Quais dias",
    "end_Date_Tx": "Data de término",
    "sDate_Err": "A data de início deve ser maior ou igual a hoje",
    "eDate_Err": "A data de término deve ser posterior à data de início",
    "start_Date_Req": "Data de início é necessária",
    "end_Date_Req": "Data de término é necessária",
    "time_req": "Tempo é necessário",
    "client_GT1": "Clientes inscritos nos últimos",
    "client_GT2": "Clientes com conexão nos últimos",
    "client_GT3": "Clientes com pelo menos",
    "client_GT4": "com o último",
    "client_GT5": "que não retornou no último",
    "dummy_Phone_No": "+91987-654-3210",
    "test_T": "Teste",
    "dummy_Birth_Date": "01-01-2001",
    "image_Req": "Mídia ou URL é necessária",
    "time_Title": "Tempo",
    "start_date": "Data de início",
    "end_date": "Data de término",
    "auto_Send_T": "Enviar automaticamente para clientes",
    "add_Media": "Adicionar mídia",
    "in_Title": "Em",
    "c_Temp_T": "Modelo de campanha",
    "temp_NT": "Nome do modelo",
    "type_T": "Tipo",
    "select_template_type": "Selecione o tipo de modelo",
    "sel_Temp_T": "Selecione o tipo de modelo",
    "sms_temp_t": "Modelo de SMS",
    "temp_T": "Texto do modelo",
    "default_Msg": "Olá %% firstname %%, Desconto especial no brunch! Jante conosco hoje e ganhe um desconto exclusivo de 10% na sua conta!",
    "refill_T": "Recarga",
    "avl_Credit": "Créditos disponíveis",
    "req_Credit": "Total de créditos necessários",
    "rem_Credit": "Crédito restante",
    "refill_Credit": "Créditos a serem recarregados",
    "clients_Req": "Selecione pelo menos um cliente",
    "subclient_Req": "Clientes selecionados não são assinantes.",
    "refill_Desc": "Por favor, recarregue os créditos necessários",
    "url_Regex": "^((http|https):\/\/).....+$",
    "sd_Invalid": "Data de início inválida",
    "ed_Invalid": "Data de término inválida",
    "img_Url_Invalid": "URL de imagem inválida",
    "time_Invalid": "O tempo é inválido",
    "time_And_Date_Invalid": "Insira uma data e hora válidas",
    "time_Invalid_Bfr": "Selecione um horário pelo menos 5 minutos depois do horário atual",
    "sod_Req": "É necessário enviar no dia",
    "add_Card": "Adicionar um novo cartão",
    "sm_D_Rep_Title": "Relatório de entrega de SMS\/MMS",
    "send_Now_T": "Enviar agora",
    "schd_Now_T": "Agendar SMS",
    "test_SMS_T": "Enviar SMS de teste",
    "save_AD_T": "Salvar como rascunho",
    "back_TE_T": "Voltar para Editar",
    "reset_tex": "Reiniciar",
    "update_tex": "Atualizar",
    "dum_msg1": "Para exibir o nome do cliente, use %% customer_name %% no modelo",
    "dum_msg2": "Para exibir o horário da reserva, use %% date_time %% no modelo",
    "dum_msg3": "Para exibir o número da tabela, use %% table_no %% no modelo",
    "embedded_link": "Link incorporado",
    "img_title": "Imagem",
    "img_input_text": "Texto:",
    "img_join_text": " para se juntar à nossa lista",
    "img_copy_text": "Cópia",
    "img_not_found": "Imagem gerada não encontrada!!",
    "or_text": "OU",
    "web_signup_image_content": "Ao enviar este formulário e se inscrever por mensagem de texto, você consente em receber mensagens de texto de marketing (como códigos promocionais e lembretes). Podem ser aplicadas taxas de mensagem e dados. A frequência das mensagens varia. Você pode cancelar a assinatura a qualquer momento respondendo STOP",
    "img_download_success": "Imagem baixada com sucesso",
    "embedded_link_copied": "Link incorporado copiado com sucesso",
    "media_url_required": "Mídia ou URL é necessária",
    "invalid_image_url": "URL de imagem inválida",
    "invalid_file": "O arquivo é inválido",
    "image_error_1mb": "Insira uma imagem com menos de 1 MB",
    "image_error_2_5mb": "Insira uma imagem com menos de 2,5 MB",
    "image_error_3mb": "Insira uma imagem com menos de 3 MB",
    "change_title": "Mudar",
    "drop_file_text": "Solte o arquivo aqui ou clique para fazer upload",
    "apply_text": "Aplicar",
    "search_here": "Pesquisar aqui",
    "update_status": "Atualizar status",
    "reservation_deleted_permanently": "Esta reserva será excluída permanentemente.",
    "table_text": "Tabelas",
    "add_table_button": "Adicionar nova tabela",
    "add_table_text": "Adicionar tabela",
    "edit_table_text": "Editar Tabela",
    "table_delete_text": "Esta tabela será excluída permanentemente.",
    "multiple_table_delete_text": "Essas tabelas serão excluídas permanentemente.",
    "table_error_name": "Por favor, insira o nome da tabela",
    "table_error_invalid_name": "Por favor, insira um nome de tabela válido",
    "table_error_floor": "Por favor, selecione o andar",
    "table_error_select": "Por favor, selecione a tabela",
    "table_capacity_text": "Capacidade da mesa",
    "capacity_text": "Capacidade",
    "table_occasion_text": "Encontre sua mesa para qualquer ocasião",
    "table_name_text": "Nome da tabela",
    "table_capacity_error": "Capacidade de mesa é necessária",
    "cancel_text": "Cancelar",
    "submit_text": "Enviar",
    "select_valid_date": "Selecione uma data válida",
    "select_valid_time": "Selecione um horário válido",
    "valid_contact_number": "Insira um número de contato válido",
    "date_req": "Data é necessária",
    "date_error_later_than_today": "A data deve ser posterior ou igual à data de hoje",
    "time_error_later_than_5_minutes": "Selecione o horário pelo menos 5 minutos depois do horário atual",
    "number_person_req": "Número de pessoas necessário",
    "contact_no_req": "Número de contato é obrigatório",
    "contact_no": "Número de contato",
    "select_time": "Selecione o tempo",
    "for_how_many_person": "Para quantas pessoas?",
    "f_name": "Primeiro nome",
    "l_name": "Sobrenome",
    "business_name": "Nome da empresa",
    "business_no": "Número de negócios",
    "no_for_sign_in": "Este número é usado para fazer login",
    "business_email": "E-mail comercial",
    "notes_tex": "Notas",
    "floor_deleted_permanently": "Este andar será excluído permanentemente.",
    "add_floor": "Adicionar piso",
    "edit_floor": "Editar Piso",
    "name_text": "Nome",
    "mobile_no": "Número de celular.",
    "person_text": "Pessoa",
    "date_and_time": "Data e hora",
    "actions_text": "Ações",
    "extra_text": "Extra",
    "floor_name_req": "Nome do andar é obrigatório",
    "floor_name": "Nome do andar",
    "status_text": "Status",
    "category_status_req": "O status da categoria é obrigatório",
    "table_deleted_permanently": "Esta tabela será excluída permanentemente.",
    "tables_deleted_permanently": "Essas tabelas serão excluídas permanentemente.",
    "back_to_home": "Voltar para casa",
    "link_copied_text": "Link copiado para a área de transferência",
    "cust_dash_head_to_title": "para",
    "cust_dash_head_location_title": "Localização",
    "select_location_title": "Selecione a localização",
    "all_locations_label": "todos_os_locais",
    "rep_customer_feedback_analytics": "😊 Acompanhe o feedback do seu cliente com análises em tempo real",
    "rep_customer_txt": "Clientes",
    "rep_delete_title": "Esta reputação será excluída permanentemente.",
    "rep_qr_def_txt": "Escaneie o código QR e compartilhe conosco seu valioso feedback sobre sua experiência.",
    "delete_title": "Excluir",
    "user_list_title": "Lista de clientes",
    "os_nfc_txt": "Cartões NFC",
    "os_sign_holder_txt": "Suporte para suporte de sinal",
    "os_store_Sign_holder_txt": "Suportes para placas de loja",
    "StaT": "Estatísticas",
    "AllgT": "Todos os grupos",
    "FavT": "Favorito",
    "MostActT": "Mais Ativo",
    "grT": "Nome do grupo",
    "keywT": "Palavras-chave",
    "exSubT": "Mensagem para assinante existente",
    "ArchT": "Arquivado",
    "gNotiSms": "Notifique-me sobre cada novo contato por SMS",
    "gNotiEmail": "Notifique-me sobre cada novo contato por e-mail",
    "reqGName": "O nome do grupo é obrigatório",
    "validGN": "Insira um nome de grupo válido",
    "valid_phone_no": "Insira um número de telefone válido",
    "phone_no_req": "Número de telefone é obrigatório",
    "required_message_text": "Mensagem é necessária",
    "required_discount_text": "Desconto é necessário",
    "less_than_discount": "O valor do desconto deve ser inferior a 5000",
    "discount_percentage_invalid": "A porcentagem de desconto deve ser menor ou igual a 100",
    "discount_type_req": "O tipo de desconto é obrigatório",
    "discount_text_req": "O texto do cupom é obrigatório",
    "reqContactNo": "Número de contato é obrigatório",
    "reqMsgNT": "O título da campanha é obrigatório",
    "reqLinkT": "Link é necessário",
    "delMGT": "Esses grupos serão excluídos permanentemente.",
    "defMemMsg": "Digite sua mensagem aqui",
    "add_cust_to_grp_title": "Adicionar cliente ao grupo",
    "group_title": "Grupos",
    "group_create_title": "Criar grupo",
    "group_name_txt": "Nome do grupo",
    "group_table_keyword_title": "Palavra-chave",
    "actions_title": "Ações",
    "clients_title": "Clientes",
    "send_title": "Enviar",
    "qr_title": "Código QR",
    "delete_group_txt": "Este grupo será excluído permanentemente.",
    "delete_groups_txt": "Esses grupos serão excluídos permanentemente.",
    "delete_client_title": "Este cliente será excluído permanentemente.",
    "delete_clients_title": "Esses clientes serão excluídos permanentemente.",
    "delete_multiple_title": "Exclusão múltipla",
    "wel_sms_mms": "Enviar SMS\/MMS de boas-vindas?",
    "key_words_title": "Palavras-chave",
    "srch_plch_txt": "Pesquisar aqui",
    "req_location_id_title": "Localização é necessária",
    "create_text": "Criar",
    "view_text": "Visualizar",
    "immediately": "Imediatamente",
    "business_name_req": "Nome comercial é obrigatório",
    "business_no_req": "Número comercial é obrigatório",
    "valid_business_name": "Insira um nome comercial válido",
    "valid_business_no": "Insira um número comercial válido",
    "address_req": "Endereço é obrigatório",
    "valid_address": "Selecione um endereço válido",
    "time_zone_req": "Fuso horário é obrigatório",
    "image_req": "Insira uma imagem com menos de 2,5 MB",
    "valid_file": "O arquivo é inválido.",
    "logo": "Logotipo",
    "time_zone": "Fuso horário",
    "save": "Salvar",
    "account_type_req": "O tipo de conta é obrigatório",
    "gst_no_req": "O número GST é obrigatório",
    "gst_no_valid": "Insira um número GST válido",
    "set_up_payments": "Configurar pagamentos",
    "billing_details": "Insira seus dados de cobrança",
    "billing_details_desc": "Seus detalhes de cobrança serão exibidos na sua fatura mensal do menuonline.",
    "account_type": "Tipo de conta",
    "select_account_type": "Selecione o tipo de conta",
    "gst_no": "Número GST",
    "transaction_details": "Detalhes da transação",
    "payment_method": "Método de pagamento",
    "billing_period": "Período de cobrança",
    "paid_by": "Pago por",
    "download": "Download",
    "pay_now": "Pague agora",
    "pull_down_refresh": "Puxe para baixo para atualizar",
    "release_refresh": "Solte para atualizar",
    "billing_details_text": "Detalhes de cobrança",
    "payment_methods": "Métodos de pagamento",
    "invoice": "Fatura",
    "invoice_to": "Fatura para:",
    "issue_date": "Data de emissão",
    "due_date": "Data de vencimento",
    "amount_due": "Valor devido",
    "charges": "Taxas",
    "sub_total_capitalize": "Subtotal",
    "hst": "HST",
    "grand_total": "Total geral",
    "invoice_generated_on": "Fatura gerada em",
    "contact_us": "Contate-nos",
    "invoice_issue": "Se você já examinou sua fatura e ainda tem dúvidas",
    "call": "Chamar",
    "send_sms": "Enviar SMS",
    "payment_success": "O pagamento foi efetuado com sucesso",
    "edit_credit_card": "Editar Cartão de Crédito",
    "add_credit_card": "Adicionar cartão de crédito",
    "modify_card_info": "Modifique as informações do seu cartão",
    "enter_card_info": "Insira as informações do seu cartão",
    "account_no_req": "Número de conta é obrigatório",
    "card_name_req": "Nome no cartão é obrigatório",
    "expiry_date_req": "Data de validade é necessária",
    "valid_expiry_date": "Insira uma data de validade válida",
    "valid_cvv": "Insira CVV válido",
    "cvv_req": "CVV é obrigatório",
    "card_no": "Número do cartão",
    "name_of_card": "Nome no cartão",
    "expiry": "Termo",
    "mm_text": "MM\/AA",
    "cvv": "CVV",
    "set_as_default": "Definir como padrão",
    "add_new_card": "Adicionar novo cartão",
    "all_credit_card": "Todos os seus cartões de crédito",
    "fastest_checkout": "Check-out mais rápido com plataforma segura e confiável, confiável por um grande grupo de empresas",
    "coupon_button_req": "O texto do botão de cupom é obrigatório",
    "max19_characters_allowed": "São permitidos no máximo 19 caracteres",
    "fine_print_text_req": "É necessário texto em letras pequenas",
    "clients_req": "Selecione pelo menos um cliente",
    "client_groups": "Grupos de clientes",
    "day_text": "Dia",
    "valid_days": "O valor do dia é muito grande, insira dias válidos",
    "hours_text": "Horas",
    "enter_valid_hours": "O valor das horas é muito grande, insira horas válidas",
    "enter_valid_min": "O valor dos minutos é muito grande, insira minutos válidos",
    "clients_with_connection_required": "Clientes com conexão no último é necessário",
    "connection_required": "Conexão é necessária",
    "connection_value_too_large": "O valor das conexões é muito grande, insira uma conexão válida",
    "minutes_required": "Minutos necessários",
    "clients_with_at_least_connection_required": "Clientes com pelo menos conexão são necessários",
    "last_minutes_req": "Com os últimos minutos é necessário",
    "clients_not_returned_required": "Quem não retornou nos últimos minutos é obrigado",
    "not_return_in_last_minutes_invalid": "Não retornar em últimos minutos deve ser inferior aos últimos minutos.",
    "customerGroups": "Grupos de clientes",
    "select_customer_group": "Selecione o grupo de clientes",
    "location_required": "Localização é necessária",
    "start_date_required": "Data de início é necessária",
    "start_date_invalid": "Data de início inválida",
    "start_date_invalid_error": "A data de início deve ser maior ou igual a hoje",
    "recurring": "Recorrente",
    "end_date_required": "Data de término é necessária",
    "end_date_invalid": "Data de término inválida",
    "end_date_invalid_error": "A data de término deve ser posterior à data de início",
    "time_invalid": "O tempo é inválido",
    "monthly_text": "Mensal",
    "send_day_req": "É necessário enviar no dia",
    "loyal_clients": "Clientes fiéis",
    "recent_clients": "Clientes Recentes",
    "lapsed_clients": "Clientes inadimplentes",
    "clients_connection_date": "Clientes por data de conexão",
    "scheduled_text": "Agendado",
    "weekly": "Semanalmente",
    "selected_clients_not_subscriber": "Clientes selecionados não são assinantes.",
    "message_preview_title": "Prévia da mensagem",
    "coupon_preview_title": "Prévia do cupom",
    "form_text": "Forma",
    "preview_text": "Pré-visualização",
    "next_text": "Próximo",
    "send_test_SMS": "Enviar SMS de teste",
    "save_draft": "Salvar como rascunho",
    "back_to_edit": "Voltar para Editar",
    "select_payment_method": "Selecione o método de pagamento",
    "schedule_SMS": "Agendar SMS",
    "send_now": "Enviar agora",
    "get_more_ratings": "Obtenha mais avaliações",
    "overview": "Visão geral",
    "reset_campaign": "Reiniciar Campanha",
    "permissions": "Permissões",
    "location_name": "Nome do local",
    "phone_no": "Número de telefone",
    "location_email_address": "Localização Endereço de e-mail",
    "located_business": "Onde sua empresa está localizada?",
    "business_logo": "Logotipo da empresa",
    "congratulations": "Parabéns",
    "almost_done": "Você está quase terminando",
    "publish": "Publicar",
    "about_your_business": "Sobre o seu negócio",
    "add_your_location": "Adicione sua localização",
    "publish_location": "Local de publicação",
    "location_name_req": "O nome do local é obrigatório",
    "valid_location_name": "Insira um nome de local válido",
    "business_logo_req": "O logotipo da empresa é obrigatório",
    "please_accept_terms": "Por favor, aceite os termos e condições",
    "add_new_location": "Adicionar novo local",
    "edit_location": "Editar Localização",
    "add_location": "Adicionar localização",
    "existing_msg_subscriber_txt": "Mensagem para assinante existente",
    "msg_capitalize_txt": "Mensagem",
    "group_noti_sms": "Notifique-me sobre cada novo contato por SMS",
    "group_noti_email": "Notifique-me sobre cada novo contato por e-mail",
    "group_member_msg": "Parece que você já faz parte do nosso grupo!!",
    "group_mend_msg": "PARE para terminar. AJUDA para obter ajuda. Podem ser aplicadas taxas de SMS e dados",
    "Disclaimer_title": "Texto de isenção de responsabilidade: ",
    "group_def_msg": "Estamos online agora! Confira nosso menu de serviços e agende sua próxima consulta hoje mesmo.",
    "required_msg_txt": "Mensagem é necessária",
    "required_Key_txt": "Palavras-chave são necessárias",
    "required_mem_msg": "Mensagem do assinante é necessária",
    "client_list_title": "Lista de clientes",
    "add_contact_txt": "Adicionar contato",
    "delete_all_clients_txt": "Excluir todos os clientes",
    "delete_all_clients_msg": "Tem certeza de que deseja excluir todos os clientes? Eles não podem ser recuperados.",
    "delete_all_txt": "Apagar tudo",
    "timeline_title": "Linha do tempo",
    "unsubscribe_title": "Cancelar inscrição",
    "subscribe_title": "Inscrever-se",
    "unsubscribe_confirm_msg": "Gostaria de marcar este cliente como cancelador de assinatura?",
    "subscribe_confirm_msg": "Gostaria de marcar este cliente como assinante?",
    "no_title": "Não",
    "yes_title": "Sim",
    "client_name_title": "Nome do cliente",
    "source_title": "Fonte",
    "contact_file_Req": "O arquivo de contatos é obrigatório",
    "req_title": "Obrigatório",
    "opt_in_req": "Os contatos importados devem ser 100% opt-in",
    "image_invalid_error": "O arquivo é inválido.",
    "import_contacts_msg": "Importar contatos",
    "csv_upload_title": "Arquivo de contatos CSV para upload *",
    "csv_file_desc": "Por favor, carregue somente o arquivo csv - colunas do arquivo csv - Nome, Sobrenome, ID de e-mail, Número de celular, Sexo, Data de nascimento (MM\/DD\/AAAA) - sem espaços, traços ou parênteses no Número de celular",
    "to_download_title": "Para baixar o arquivo de demonstração clique aqui",
    "contains_header_title": "Sim, este arquivo contém cabeçalhos",
    "opt_in_100_txt": "Sim, esses contatos importados são 100% opt-in",
    "DisT": "Texto de isenção de responsabilidade: ",
    "gMendMsg": "PARE para terminar. AJUDA para obter ajuda. Podem ser aplicadas taxas de SMS e dados",
    "reqKeyT": "Palavras-chave são necessárias",
    "reqMemMsg": "Mensagem do assinante é necessária",
    "reqMsgT": "Mensagem é necessária",
    "gMemberMsg": "Parece que você já faz parte do nosso grupo!!",
    "gdefMsg": "Estamos online agora! Confira nosso menu de serviços e agende sua próxima consulta hoje mesmo.",
    "next_title": "Próximo",
    "male_title": "Macho",
    "male_val": "macho",
    "female_title": "Fêmea",
    "female_val": "fêmea",
    "others_txt": "Outros",
    "others_val": "outros",
    "validBirthdate": "Insira uma data de nascimento válida",
    "valid_phone_no_title": "Insira um número de telefone válido",
    "required_phone_no_title": "Número de telefone é obrigatório",
    "add_new_client_txt": "Adicionar um novo cliente",
    "update_client_txt": "Atualizar cliente",
    "phone_num_text": "Número de telefone",
    "dob_title": "Data de nascimento",
    "select_gender_title": "Selecione o gênero",
    "timelineTitle": "Linha do tempo",
    "confirm_location": "Confirmar localização",
    "feedback_title": "Opinião",
    "rep_question_1": "O negócio estava limpo?",
    "rep_que_2": "Sua comida estava boa?",
    "rep_que_3": "Nosso serviço foi amigável?",
    "rep_que_4": "Nosso serviço foi rápido?",
    "business_location_req_title": "A localização da empresa é obrigatória",
    "valid_location_err_txt": "Selecione um endereço válido",
    "rep_management_title": "Obtenha mais avaliações de gerenciamento",
    "rep_about_desc": "A reputação ajuda você a aumentar a classificação do Google pedindo feedback aos seus clientes",
    "preview_title": "Pré-visualização",
    "rep_preview_txt": "Prévia da reputação",
    "back_title": "Voltar",
    "fine_print_txt": "Por favor, resgate com o caixa",
    "redeem_me_title": "Redimir-me",
    "rep_nxt_visit_txt": "Na sua próxima visita",
    "device_type": "Tipo de dispositivo usado",
    "connection_method": "Método de conexão",
    "peak_days": "Dias de pico",
    "peak_hours": "Horários de pico",
    "guest_by_day": "Convidado por dia",
    "guest_visit": "Visita de Convidado",
    "connection": "Conexão",
    "connection_duration": "Duração da conexão",
    "guest_visit_1": "1 vez",
    "guest_visit_2": "2 vezes",
    "guest_visit_3_5": "3-5 vezes",
    "guest_visit_6_10": "6-10 vezes",
    "guest_visit_11_25": "11-25 vezes",
    "guest_visit_26_100": "26-100 vezes",
    "guest_visit_100_plus": "Mais de 100 vezes",
    "device_android_total": "Android total",
    "device_android": "Andróide",
    "device_desktop": "Área de trabalho",
    "device_ios": "iOS",
    "device_ios_total": "Total de Ios",
    "device_desktop_total": "Área de trabalho total",
    "connection_duration_10": "<=10 minutos",
    "connection_duration_20": "11-20 minutos",
    "connection_duration_30": "21-30 minutos",
    "connection_duration_40": "31-45 minutos",
    "connection_duration_60": "46-60 minutos",
    "connection_method_email": "E-mail",
    "connection_method_sms": "SMS",
    "connection_method_google": "Google",
    "connection_method_facebook": "Facebook",
    "age_category_1": "<18",
    "age_category_2": "18-24",
    "age_category_3": "25-34",
    "age_category_4": "35-44",
    "age_category_5": "45-54",
    "age_category_6": "55+",
    "all_guest_txt": "Todos os hóspedes",
    "new_Guest_txt": "Novo convidado",
    "connections_txt": "Conexões",
    "hotspot": "Ponto de acesso",
    "hotspot_list": "Lista de pontos de acesso",
    "add_new_hotspot": "Adicionar novo ponto de acesso",
    "hotspot_information": "Informações sobre pontos de acesso",
    "edit_details_button": "Editar detalhes",
    "wifi_info_message": "Conecte-se e aproveite o WiFi gratuito",
    "connection_message": "Ótimo, você foi conectado a ",
    "connection_message_suffix": " Wi-fi",
    "wifi": "Wi-fi",
    "login_to_access": "Faça login para obter acesso",
    "verification_code": "Código de Verificação",
    "verification_code_message": "Por favor, digite o código de verificação enviado para ",
    "wifi_user_email": "adamo@gmail.com",
    "wifi_label": "Wi-fi",
    "your_name": "A propósito, qual é seu nome?",
    "your_birthdate": "Você pode nos dizer qual é sua data de nascimento?",
    "request_guest_wifi_name": "Por favor, insira o nome do Wi-Fi do convidado",
    "request_device_key": "Por favor, insira a chave do dispositivo",
    "request_maximum_internet_access_length": "Selecione o comprimento máximo de acesso à Internet",
    "mac_address": "Endereço MAC",
    "device_port": "Porta do dispositivo",
    "hardware": "Hardware",
    "current_uptime": "Tempo de atividade atual",
    "nearby_devices": "Dispositivos próximos",
    "firmware": "Firmware",
    "who_are_you": "Quem é você?",
    "where_to_contact_you": "Onde podemos entrar em contato com você?",
    "your_area_code": "Qual é o seu código de área?",
    "connected": "Conectado",
    "delete_hotspot_message": "Este ponto de acesso será excluído permanentemente.",
    "delete_multiple_hotspots_message": "Esses pontos de acesso serão excluídos permanentemente.",
    "speed_error": "A velocidade deve ser de pelo menos 0,01",
    "speed_max_error": "Insira um valor de até 1024 para velocidade ilimitada ou selecione um valor menor dentro do intervalo suportado",
    "device_ssid": "SSID do dispositivo",
    "device_ssid_two": "Dispositivo SSID Dois",
    "device_ssid_two_wpa": "Dispositivo SSID Dois WPA",
    "device_key": "Chave do dispositivo",
    "select_location": "Selecione a localização",
    "select_maximum_internet_access_length": "Selecione o comprimento máximo de acesso à Internet",
    "download_speed": "Velocidade de download",
    "upload_speed": "Velocidade de upload",
    "network_length_1": "15 minutos",
    "network_length_2": "30 minutos",
    "network_length_3": "45 minutos",
    "network_length_4": "1 hora",
    "network_length_5": "2 horas",
    "network_length_6": "4 horas",
    "network_length_7": "6 horas",
    "network_length_8": "8 horas",
    "network_length_9": "10 horas",
    "network_length_10": "12 horas",
    "employee_wifi_name": "Nome do Wifi do Funcionário",
    "employee_wifi_password": "Senha do Wifi do Funcionário",
    "guest_wifi_name": "Nome do Wi-Fi do Convidado",
    "menu_other_products_txt": "Outros Produtos | Menu Online",
    "menu_home_text": "Início | Cardápio Online",
    "whatsapp_title": "Whatsapp",
    "select_campaign_type": "Escolha um tipo de campanha",
    "select_readymade_templates": "Escolha entre modelos prontos ou crie o seu próprio",
    "campaign_title_required": "O título da campanha é obrigatório",
    "type_here": "Digite aqui...",
    "location_permission_req": "É necessária permissão de localização",
    "platform_permission_req": "É necessária permissão de plataforma",
    "profile_picture": "Foto de perfil",
    "click_to_upload": "Clique para carregar",
    "location_permission": "Permissão de localização",
    "pin": "Alfinete",
    "platform_permission": "Permissão de plataforma",
    "set_pin": "Definir PIN",
    "list_of_users": "Lista de usuários",
    "create_user": "Criar usuário",
    "terms_and_condition_req": "Termos e Condições são obrigatórios",
    "terms_and_conditions": "Termos e Condições",
    "recommended": "RECOMENDADO",
    "mo": "\/mês",
    "unsubscribe": "Cancelar inscrição",
    "cancel_subscription": "Tem certeza de que deseja cancelar a assinatura?",
    "upgrade": "Atualizar",
    "plan_upgraded_successfully": "O plano foi atualizado com sucesso!",
    "menu_online": "Menuonline",
    "support": "Apoiar",
    "wifiC": "WI-FI",
    "billing": "Cobrança",
    "please_upgrade": "Por favor, atualize para aproveitar todos os recursos",
    "you_have_subscribed": "Você se inscreveu no ",
    "plan": "plano",
    "of_text": "de",
    "days": "Dias",
    "remaining_days_until_plan": " Dias restantes até que seu plano precise de atualização",
    "manage_your_screens": "Gerencie suas telas",
    "screens_you_save": "Telas que você tem",
    "add_more_screens": "Adicionar mais telas",
    "addition_screen": "Tela de adição",
    "per_screen": "$ por tela",
    "per_box": "$ por caixa",
    "shipping_changes": "Taxas de envio",
    "upto4_boxes": "até 4 caixas ",
    "charge_now": "Você receberá a carga agora",
    "updated_reoccuring": "Sua atualização recorrente será",
    "current_reoccuring": "Recorrência atual: ",
    "no_text": "Não",
    "android_box": "Caixa Android",
    "old_password_req": "É necessária uma senha antiga",
    "new_password_req": "Nova senha é necessária",
    "confirm_password_req": "Confirmar senha é obrigatório",
    "password_do_not_match": "As senhas não correspondem",
    "old_password": "Senha Antiga",
    "new_password": "Nova Senha",
    "confirm_password": "Confirme sua senha",
    "copy_working_hours": "Copiar esses horários de trabalho para todos os dias?",
    "yes_copy": "Sim, copie",
    "closed": "Fechado",
    "opening_time": "Horário de abertura",
    "closing_time": "Horário de encerramento",
    "description": "Descrição",
    "file_exist": "arquivo já existe, selecione outro arquivo",
    "bussiness_images": "Imagens de negócios",
    "display_info_on_market_place": "Gostaria de exibir as informações abaixo no seu Marketplace?",
    "profile_ready": "Seu perfil está pronto!",
    "client_book_with_online": "Os clientes agora podem reservar com você online. Compartilhe o link abaixo para começar.",
    "copy_link": "Copiar Link",
    "see_your_profile": "Veja seu perfil",
    "ok_got_it": "Ok, entendi",
    "preview_profile": "Visualize seu perfil e veja como ele ficaria.",
    "opening_hours": "Horário de funcionamento",
    "display_data": "Exibir dados",
    "manage_profile": "Gerenciar perfil",
    "phone_req": "Telefone é necessário",
    "market_place_img_req": "Imagens de mercado são necessárias",
    "add_new_market_place": "Adicionar novo mercado",
    "edit_market_place": "Editar MarketPlace",
    "no_reviews_yet": "Nenhuma avaliação ainda",
    "good": "Bom",
    "average": "Média",
    "add_marketPlace": "Adicionar MarketPlace",
    "all_title": "Todos",
    "included_title": "Incluído",
    "excluded_title": "Excluído",
    "clients_subscribed": "Clientes inscritos nos últimos",
    "clients_groups": "Grupos de clientes",
    "customer_groups": "Grupos de clientes",
    "audience_title": "Público",
    "client_gt5": "que não retornou no último",
    "select_all": "Selecionar tudo",
    "modify": "Modificar",
    "campaign_title": "Título da campanha",
    "msg_type": "Tipo de mensagem",
    "enter_discount": "Insira desconto",
    "discount_type": "Tipo de desconto",
    "coupon_text": "Texto do cupom",
    "enter_coupon_text": "Insira o texto do cupom",
    "coupon_button_text": "Texto do botão de cupom",
    "enter_coupon_button_text": "Insira o texto do botão de cupom",
    "fine_prin": "Letras miúdas",
    "enter_fine_prin": "Insira o texto em letras pequenas",
    "campaign_dec": "Você pode modificar a campanha 30 minutos antes do horário de início. As cobranças por campanha de SMS serão processadas 30 minutos antes do horário de início da campanha.",
    "blast_text_message_dec": "Sua mensagem de texto explosiva está pronta. Selecione seu método de pagamento e envie suas mensagens.",
    "payment_completed": " O pagamento será concluído em ",
    "total_cost": "Custo total",
    "close_title": "Fechar",
    "friday": "Sexta-feira",
    "saturday": "Sábado",
    "sunday": "Domingo",
    "thursday": "Quinta-feira",
    "tuesday": "Terça-feira",
    "wednesday": "Quarta-feira",
    "port_txt": "Porta",
    "today_title": "Hoje",
    "yesterday_title": "Ontem",
    "last_30_days_txt": "Últimos 30 dias",
    "this_month_txt": "Este mês",
    "last_month_txt": "Mês passado",
    "valid_date_title": "Selecione uma data válida",
    "valid_business_name_txt": "Insira um nome comercial válido",
    "req_bus_add_txt": "Endereço comercial é obrigatório",
    "req_domain_name_txt": "Nome de domínio é obrigatório",
    "basic_info_txt": "Informações básicas do seu negócio",
    "loyalty_qr_def_txt": "Escaneie o código QR, cadastre-se em nosso cartão de fidelidade e aproveite recompensas.",
    "last_stamp_txt": "Último carimbo",
    "collected_on_txt": "Coletado em",
    "stamp_details_txt": "Detalhes do selo",
    "add_stamp_txt": "Adicionar carimbo",
    "choose_brand_color_txt": "Escolha a cor e o idioma da sua marca 🎨",
    "change_anytime_txt": "Você pode mudar isso a qualquer momento",
    "like_to_get_txt": "Você gostaria de obter",
    "your_next_visit_txt": "na sua próxima visita?",
    "time_to_time_get_offers": "Gostaria de receber ótimas ofertas e promoções de tempos em tempos?",
    "respond_back_to_you": "Gostaria que alguém respondesse a você?",
    "input_serve_better": "Sua contribuição ajudará nossa equipe a atendê-lo melhor!",
    "do_it_txt": "Vamos fazê-lo",
    "take_15_s": "Leva 15 segundos",
    "rate_on_google_desc": "Você poderia gentilmente nos avaliar no Google?",
    "may_be_next_time": "Talvez na próxima vez",
    "thank_you_txt": "Obrigado",
    "look_next_txt": "Aguardamos sua próxima visita!",
    "deleteUserTitle": "Este usuário será excluído permanentemente.",
    "deleteMUserTitle": "Esses usuários serão excluídos permanentemente.",
    "change_pin": "Alterar PIN",
    "area_code": "Qual é o seu código de área? ",
    "add_menu": "Adicionar Menu",
    "menu_name": "Nome do menu",
    "add_menu_placeholder": "Pizza, Hambúrguer, Bebidas, etc.",
    "enable_data_Collection": "Habilitar coleta de dados",
    "add_new_menu": "Adicionar novo menu",
    "rename_menu": "Renomear Menu",
    "preview": "Pré-visualização",
    "generate_qr": "QR gerado",
    "edit_menu": "Editar Menu",
    "remove_menu": "Remover Menu",
    "menu_delete_msg": "Este menu será excluído permanentemente",
    "menus_delete_msg": "Este menu será excluído permanentemente",
    "view_menu_dialoage_msg": "Gostaria de ganhar um presente no seu aniversário?",
    "skip": "Pular",
    "cliam_your_gift": "Reivindique seu presente",
    "collect_form": "Formulário de coleta",
    "enter_first_name": "Digite o primeiro nome",
    "enter_last_name": "Digite o sobrenome",
    "enter_email": "Digite o e-mail",
    "enter_dob": "Insira a data de nascimento",
    "enter_number": "Digite o número",
    "select_gender": "Selecione o gênero",
    "congratulations_desc": "🎉 Parabéns! Sua solicitação de reivindicação de presente foi enviada com sucesso. Nossa equipe entrará em contato com você em breve. 🎉",
    "male_heading": "Macho",
    "male_text": "macho",
    "female_heading": "Fêmea",
    "female_text": "fêmea",
    "others_heading": "Outros",
    "other_text": "outros",
    "BirthD": "Data de nascimento",
    "GenderT": "Gênero",
    "EmailT": "E-mail",
    "dobT": "Data de nascimento",
    "capitalize_menu": "Menu",
    "select_menu": "Selecione o menu",
    "manage_variant": "Gerenciar Variante",
    "add_products": "Adicionar produtos",
    "add_category": "Adicionar categoria",
    "category_delete": "Esta categoria será excluída permanentemente",
    "variation_delete": "Esta variação será excluída do Produto.",
    "product_delete": "Este produto será excluído permanentemente.",
    "categories_delete": "Essas categorias serão excluídas permanentemente.",
    "products_delete": "Esses produtos serão excluídos permanentemente.",
    "category": "CATEGORIA",
    "price": "PREÇO",
    "food_product_placeholder": "Pizza, Hambúrguer, Bebidas, etc.",
    "active_title": "Ativo",
    "inActive_title": "Inativo",
    "status_capital": "STATUS",
    "cat_status_require": "O status da categoria é obrigatório",
    "cat_name_require": "O nome da categoria é obrigatório",
    "category_name": "Nome da categoria",
    "status": "Status",
    "lgDay1": "Segunda-feira",
    "lgDay2": "Terça-feira",
    "lgDay3": "Quarta-feira",
    "lgDay4": "Quinta-feira",
    "lgDay5": "Sexta-feira",
    "lgDay6": "Sábado",
    "lgDay7": "Domingo",
    "is_closed_title": "Fechado",
    "book_table_title": "Reserve uma mesa",
    "emailErrSub": "O assunto do e-mail é obrigatório",
    "email_subject": "Assunto do e-mail",
    "contactUsfrmTitleEmail": "Responder ao e-mail",
    "companyInfo": "Lorem Ipsum é lorem hrig, lorem ipsum é fraets.Lorem Ipsum é lorem hrig, lorem ipsum é fraets.Lorem Ipsum é lorem hrig, lorem ipsum é fraets.",
    "footerSTitle1": "Sobre Menuonline",
    "footerSTitle1Lnk2": "Características",
    "footerSTitle1Lnk3": "Blogue",
    "footerSTitle2": "Jurídico",
    "footerSTitle2Lnk1": "Termos e Condições",
    "footerSTitle2Lnk2": "política de Privacidade",
    "footerSTitle3": "Para negócios",
    "footerSTitle3Lnk1": "Para Parceiros",
    "footerSTitle3Lnk2": "Preços",
    "footerSTitle3Lnk3": "Suporte para Parceiros",
    "footerCopyRithgTxt": "  Menuonline ou suas afiliadas",
    "homeTitle": "Lar",
    "contactTitle": "Contate-nos",
    "aboutTitle": "Sobre nós",
    "homeMenuTitle": "Menu",
    "bookTableTitle": "Reserve uma mesa",
    "vDateT": "Selecione uma data válida",
    "reqDateT": "Data é necessária",
    "dateGtErr": "A data deve ser posterior ou igual à data de hoje",
    "timeInvalid": "O tempo é inválido",
    "reqTimeT": "Tempo é necessário",
    "timeInvalidBfr": "Selecione o horário pelo menos 5 minutos depois do horário atual ",
    "PerReq": "Número de pessoas necessário",
    "validcNoT": "Insira um número de contato válido",
    "reqCrT": "Moeda é necessária",
    "reqPrT": "Preço é obrigatório",
    "reCateT": "Categoria é obrigatória",
    "reqVrT": "Variante é necessária",
    "reqVrVldT": "Insira uma variante válida ",
    "validLNameT": "Insira um sobrenome válido",
    "sDateInValid": "A data é inválida",
    "minmReq": "O mínimo é necessário",
    "resLblDate": "Selecione a data",
    "resLblTime": "Selecione o tempo",
    "perT": "Para quantas pessoas?",
    "resLblEmail": "Por favor, informe seu e-mail ?",
    "resLblNote": "Adicionar uma nota de reserva",
    "imageError1MB": "Por favor insira uma imagem com menos de 1 MB",
    "imageError": "Insira uma imagem com menos de 2,5 MB",
    "imageError3Mb": "Por favor insira uma imagem com menos de 3 MB",
    "imageInVldError": "O arquivo é inválido.",
    "addProMT": "Menu de produtos",
    "proT": "Nome do produto",
    "reqProT": "Nome do produto é obrigatório",
    "proPT": "Preço do produto",
    "reservationSuccTitle": "Ótimo, sua reserva foi feita",
    "book": "Livro",
    "custzName": "Nome da personalização",
    "always": "Sempre",
    "proImgReq": "A imagem do produto é necessária",
    "selCustmT": "Selecione Personalização ou crie um novo",
    "visStR": "O status de visibilidade é obrigatório",
    "avlblScR": "Selecione a disponibilidade do cronograma",
    "addonPR": "Selecione produtos complementares",
    "upsellPR": "Selecione produtos de upsell",
    "markItemR": "Selecione os produtos do item de marca",
    "caloriesR": "Calorias são necessárias",
    "allergensR": "Selecione produtos alergênicos",
    "prepInstR": "Instruções de preparação são necessárias",
    "staffNR": "Notas da equipe são necessárias",
    "discountR": "Desconto é necessário",
    "validDiscE": "Insira um desconto válido",
    "disday": "Pelo dia",
    "plSelDayT": "Por favor selecione o dia",
    "sTimeReq": "A hora de início é necessária",
    "sTimeInValid": "A hora de início é inválida",
    "eTimeReq": "O horário de término é obrigatório",
    "eTimeInValid": "O horário de término é inválido",
    "sDateReq": "A data de início é necessária",
    "eDateReq": "Até a data é necessário",
    "eDateInValid": "Até a data é inválido",
    "disdate": "A partir da data",
    "disdate1": "A data",
    "disdate2": "A partir da data",
    "currT": "Moeda",
    "iconR": "Selecione o ícone de item de marca",
    "minT": "Mínimo",
    "maxT": "Máximo",
    "itemNT": "Nome do item",
    "itemPT": "Preço do item",
    "descProT": "Descreva seu produto",
    "cateT": "Categoria",
    "selProCateT": "Selecione a categoria do seu produto",
    "reqMT": "Nome do menu é obrigatório",
    "ViewMenu": "Ver menu",
    "CopyMenu": "Copiar menu",
    "EditMenu": "Editar menu",
    "RemoveMenu": "Remover menu",
    "welcomeMnuTitle": "Bem-vindo ao",
    "reviewT": "Avaliações",
    "userErrorMsg": "Desculpe, ocorreu um erro!!!",
    "reqCateT": "O nome da categoria é obrigatório",
    "mngCustT": "Gerenciar personalização",
    "custNReq": "É necessário personalizar o nome",
    "incReq": "Incluído é obrigatório",
    "minmValid": "Insira um valor mínimo válido",
    "maxmReq": "O máximo é necessário",
    "cust": "Personalização",
    "crCust": "Criar nova personalização",
    "custList": "Lista de personalização",
    "delCustmzT": "Esta personalização será excluída permanentemente.",
    "mkNameR": "O nome do item de marca é obrigatório",
    "mkDelT": "Este item de marca será excluído permanentemente.",
    "hideText": "Esconder",
    "showText": "Mostrar",
    "device_mac_txt": "Dispositivo Mac",
    "delete_hotspot_txt": "Este ponto de acesso será excluído permanentemente.",
    "delete_hotspots_txt": "Esses pontos de acesso serão excluídos permanentemente.",
    "emp_wifi_name": "Nome do Wifi do Funcionário",
    "max_limit_txt": "Insira '1024' para velocidade para designá-la como ILIMITADA. Este valor remove quaisquer limites, permitindo o máximo rendimento",
    "device_port_txt": "Porta do dispositivo",
    "firmware_txt": "Firmware",
    "hotspot_info_txt": "Informações sobre pontos de acesso",
    "editDBtn": "Editar detalhes",
    "birth_date": "Data de nascimento",
    "di_theme_clr": "Cor do tema exatamente 7 dígitos",
    "color_Invalid_txt": "Selecione um código de cor válido",
    "Req_theme_clr": "A cor do tema é obrigatória",
    "select_valid_color_txt": "Selecione uma cor de tema válida",
    "req_redir_link": "O link de redirecionamento é obrigatório",
    "val_redir_link": "Insira um link de redirecionamento válido",
    "req_business_name_txt": "Nome comercial é obrigatório",
    "splash_preview": "Prévia do Splash",
    "create_new_splash": "Criar novo splash",
    "splash_page": "Página inicial",
    "splash_page_builder": "Construtor de página inicial",
    "redirect_link": "Redirecionar link",
    "theme_color": "Cor do tema",
    "enable_social_login": "Habilitar opções de mídia social para login de usuários",
    "google": "Google",
    "facebook": "Facebook",
    "is_mandatory": "É obrigatório?",
    "field": "Campo",
    "name": "Nome",
    "first_name": "Primeiro nome",
    "last_name": "Sobrenome",
    "birthdate": "Data de nascimento",
    "gender": "Gênero",
    "email": "E-mail",
    "dob": "Data de nascimento",
    "zip_code": "CEP",
    "required_redirect_link": "O link de redirecionamento é obrigatório",
    "valid_redirect_link": "Insira um link de redirecionamento válido",
    "required_theme_color": "A cor do tema é obrigatória",
    "theme_color_length": "Cor do tema exatamente 7 dígitos",
    "required_name": "Nome é obrigatório",
    "delete_splash_message": "Você quer apagar esse splash?",
    "delete_multiple_splashes_message": "Você quer apagar esses respingos?",
    "user_login_required": "O login do usuário é necessário",
    "set_theme_color": "Definir cor do tema",
    "splash_colllect_additional_info_txt": "Coletar informações adicionais",
    "verify_mobile_otp_message": "Gostaria de enviar um OTP para verificar o número do celular?",
    "add_company": "Adicionar empresa",
    "edit_company": "Editar Empresa",
    "ds_device": "Dispositivo de sinalização digital",
    "ds_device_activation_fee": "Taxa de ativação do dispositivo de sinalização digital",
    "wifi_hardware": "Hardware Wi-Fi",
    "menu_design": "Design de cardápio",
    "ds_hardware": "Hardware de sinalização digital",
    "company_name_req": "O nome da empresa é obrigatório",
    "known_name_req": "Nome conhecido é obrigatório",
    "no_location_req": "Não é necessário nenhum local",
    "minimum_location": "Por favor, insira no mínimo 1 local",
    "terms_req": "Termos é obrigatório",
    "notes_req": "Notas são necessárias",
    "sms_charge_req": "Cobrança de SMS é necessária",
    "menuonline_quantity_req": "Menu Online Quantidade é necessária",
    "menuonline_cost_req": "Menu Online Custo é obrigatório",
    "ds_quantity_req": "Quantidade de sinalização digital é necessária",
    "ds_monthly_cost_req": "O custo mensal da sinalização digital é obrigatório",
    "reputation_quantity_req": "Quantidade de reputação é necessária",
    "reputation_cost_req": "Custo de reputação é necessário",
    "marketing_quantity_req": "Quantidade de marketing é necessária",
    "marketing_cost_req": "O custo de marketing é obrigatório",
    "tax_percentage_req": "Porcentagem de imposto é necessária",
    "router_hardware_quantity_req": "Quantidade de hardware do roteador é necessária",
    "wifi_hardware_cost_req": "O custo do hardware WIFI é obrigatório",
    "activation_cost_req": "Custo de ativação é necessário",
    "wifi_quantity_req": "Quantidade de WIFI é necessária",
    "wifi_router_req": "É necessário um roteador Wi-Fi",
    "menu_design_quantity_req": "Design de menu Quantidade é necessária",
    "menu_design_cost_req": "O custo do design do menu é obrigatório",
    "quantity_req": "Quantidade é necessária",
    "cost_req": "O custo é obrigatório",
    "sheduledown_quantity_req": "Quantidade de programação reduzida é necessária",
    "sheduledown_cost_req": "É necessário programar o custo de redução",
    "loyalty_program_quantity_req": "Quantidade do Programa de Fidelidade é necessária",
    "loyalty_program_cost_req": "O custo do programa de fidelidade é obrigatório",
    "loyalty_program_sms_quantity_req": "Quantidade de SMS do Programa de Fidelidade é necessária",
    "loyalty_program_sms_cost_req": "O custo do SMS do programa de fidelidade é obrigatório",
    "comapny_created": "Empresa criada com sucesso",
    "comapny_updated": "Empresa atualizada com sucesso",
    "billing_info": "INFORMAÇÕES DE PAGAMENTO",
    "business_name_acc_holder": "Nome da empresa (titular da conta)",
    "client_first_name": "Nome do cliente",
    "client_last_name": "Sobrenome do cliente",
    "dba_known_as": "DBA conhecido como",
    "business_address": "Endereço comercial",
    "no_of_locations": "Nº de Locais",
    "package_details": "DETALHES DO PACOTE",
    "terms": "Termos",
    "one_month": "1 mês",
    "per_sms_charge": "Por SMS Cobrança",
    "plan_test": "PLANO: TESTE",
    "desc_capital": "DESCRIÇÃO",
    "qty_capital": "QTD.",
    "cost_capital": "CUSTO",
    "total_price_capital": "PREÇO TOTAL",
    "quantity": "Quantidade",
    "can_not_access": "Você não pode selecionar mais de",
    "licenses": "licenças",
    "monthly_cost": "Custo mensal",
    "plan_cost": "Custo do Plano",
    "tax": "Imposto",
    "tax_percentage": "Porcentagem de imposto",
    "monthly_cost_after_tax": "Custo mensal após impostos",
    "activation_one_time_charge": "Ativação e cobrança única",
    "sub_total": "SUBTOTAL",
    "action": "Ação",
    "cost": "Custo",
    "shipping_charge": "Taxa de envio",
    "other": "Outro",
    "additional_cost": "Custo adicional (Outros encargos)",
    "other_tax": "Outros impostos",
    "total": "Total",
    "license_statistics": "Estatísticas de licença",
    "total_licenses": "Total de licenças",
    "available_licenses": "Licenças disponíveis",
    "stock": "Estoque",
    "has_permission": "Tem Permissão",
    "avg_stock_price": "Preço médio das ações",
    "average_price": "Preço médio",
    "allocated": "Alocado",
    "reward_regulars": "Recompense os frequentadores",
    "not_add_coupon_url": "Não é possível adicionar URL do cupom. O comprimento máximo da mensagem é 160",
    "like_attach_coupon": "Gostaria de anexar um cupom?",
    "advance_scheduling": "Agendamento antecipado",
    "choose_day": "Escolha o(s) dia(s) para enviar a mensagem",
    "select_essage_window": "Selecionar janela de mensagem",
    "subscription_outside_delivery_window": "Se um usuário se inscrever fora do período de entrega, ele receberá sua resposta automática no próximo horário disponível.",
    "remaining": "Restante",
    "processing_request": "Ocorreu um erro ao processar sua solicitação.",
    "list_companies": "Lista de empresas",
    "are_you_sure": "Tem certeza..?",
    "signin_agreement": "Ao confirmar isso, a empresa criará sem assinar um acordo.",
    "status_updated": "Atualização de status realizada com sucesso",
    "status_failed": "Falha ao atualizar o status",
    "new_editor_status_updated": "O status do Novo Editor do DS foi atualizado com sucesso.",
    "user_name": "Nome de usuário",
    "known_as": "conhecido como",
    "agreement_signed": "Acordo assinado",
    "deactivate": "Desativar",
    "activate": "Ativar",
    "login_to_user_acc": "Entrar na conta do usuário",
    "disable": "Desabilitar",
    "enable": "Habilitar",
    "ds_new_editor": "Novo Editor do DS",
    "sign_agreement": "Assinar acordo",
    "agreement_sign": "Acordo sem assinatura",
    "view_agreement": "Ver acordo",
    "download_agreement": "Acordo de download",
    "add_deleted_user": "Adicionar usuário excluído",
    "deleted_user": "Excluir usuário",
    "favourite": "Favorito",
    "refresh": "Atualizar",
    "delete_chat": "Excluir bate-papo",
    "responsive_drawer": "Gaveta responsiva",
    "delete_contact": "Excluir contato",
    "clear_chat_history": "Limpar histórico de bate-papo",
    "clear_chat_history_dec": "Tem certeza de que deseja excluir este bate-papo?",
    "clear_contact_dec": "Tem certeza de que deseja excluir este contato?",
    "select_contact": "Selecione Contato",
    "new_conversation": "Iniciar nova conversa",
    "type_msg": "Digite sua mensagem aqui",
    "select_delete_client": "Selecione o cliente para excluir",
    "select_delete_client_chat": "Selecione o cliente para excluir o chat",
    "select_delete_client_chat_err": "Você não tem chat com este cliente",
    "acquire_customers_business": "Conquiste novos clientes de outras empresas.",
    "customers_from_events": "Obtenha clientes de eventos e leve-os até você!",
    "customers_from_ads": "Obtenha clientes por meio de anúncios em jornais.",
    "smart_pamphlets": "Crie panfletos inteligentes para coletar dados dos clientes.",
    "smart_qr_group": "Um QR inteligente para ingressar em um grupo de clientes.",
    "opt_in_number": "Permitir que os clientes optem pelo número.",
    "qr_menu": "Crie um código QR para exibir seu menu.",
    "collect_birthdays": "Colete o aniversário e o aniversário de casamento do cliente.",
    "join_loyalty_program": "Conquiste novos clientes para participar do meu programa de fidelidade.",
    "create_feedback_forms": "Crie formulários para coletar feedback do cliente.",
    "exclusive_discounts_promotions": "Ofereça descontos e promoções exclusivas para promoções cruzadas.",
    "group_smart_qr_opt_in": "Depois de criar um grupo, você pode gerar e selecionar um código QR inteligente, permitindo que os clientes optem instantaneamente por um grupo",
    "group_smart_qr_opt_in_dec": "Após criar um grupo, o widget de imagem permite que os clientes optem por usar texto e receber promoções, por exemplo, envie uma mensagem de texto \"pizza\" para 555-555-5555",
    "in_store_discount_next_visit": "Ganhe um desconto na loja durante sua próxima visita à nossa loja",
    "delivery_redirection": "Criar redirecionamento de entrega",
    "additional_info": "Informações adicionais",
    "add_url": "Adicionar URL",
    "custom_fields": "Campos personalizados",
    "meta_tag": "Metatag",
    "max_chars": "Máximo de caracteres",
    "add": "Adicionar",
    "update_campaign": "Campanha de atualização",
    "last_week_required": "semana passada é necessária",
    "large_week_value": "O valor da semana é muito grande. Insira uma semana válida.",
    "less_than_last_week": "Não retorno na semana passada deve ser menor que na semana passada.",
    "last_week_req": "Não é necessário retornar na semana passada",
    "birthday_special": "Especial de aniversário",
    "birthday_message": "Mensagem automática enviada em seus aniversários com saudações personalizadas e ofertas tentadoras",
    "birthday_sms": "Crie saudações de aniversário personalizadas para SMS\/MMS",
    "target_audience": "Escolha o público-alvo",
    "client_by_birthday": "Cliente por aniversário",
    "clients_once_year": "Esta campanha é enviada automaticamente aos clientes uma vez por ano ",
    "auto_responder_default_msg_1": "Você é um dos nossos melhores clientes a quem adoramos dar desconto! Que você seja sempre rico e saudável. Feliz aniversário! %% firstname %%",
    "campaign_send_to_client_birthday": "Esta campanha automática é enviada para clientes que fazem aniversário. Você pode personalizar a segmentação abaixo.",
    "get_more_engagement": "Obtenha mais engajamento enviando SMS de aniversário 🎂",
    "welcome_new_clients": "Bem-vindos novos clientes",
    "make_lasting_impression": "Deixe uma impressão duradoura cumprimentando e dando as boas-vindas calorosas aos clientes que vêm pela primeira vez",
    "personalized_greetings": "Crie saudações personalizadas para novos clientes",
    "greetings_to_new_customers": "Esta campanha enviará saudações de boas-vindas aos novos clientes no dia seguinte à primeira interação com sua empresa",
    "audience_predefined": "O público deste autoresponder é predefinido, nós temos isso!",
    "send_clients_subscribed": "Este autoresponder está configurado para enviar aos clientes inscritos no último ",
    "first_interaction": "  após a primeira interação.",
    "default_msg_2": "Esperamos que tenha gostado da sua primeira visita, adoraríamos vê-lo novamente em breve, então estamos oferecendo um desconto só para você! Clique no link para agendar sua próxima consulta conosco e resgatar sua oferta especial online agora.",
    "new_clients_update": "Atualização de texto da campanha de boas-vindas a novos clientes",
    "new_clients_warm_greeting": "Boas-vindas aos novos clientes com uma saudação calorosa 💌",
    "win_back_clients": "Reconquiste clientes",
    "re_engage_past_customers": "Reconquiste clientes antigos com ofertas irresistíveis para incentivá-los a retornar",
    "target_disengaged_clients": "Faça com que clientes desinteressados retornem com uma oferta especial, uma tática comprovada para atrair clientes de volta.",
    "campaign_sends_to_clients": "Esta campanha é enviada para clientes que não retornaram após um determinado período de tempo.",
    "customize_targeting": "Personalize a segmentação abaixo para enviar SMS\/MMS para clientes inativos.",
    "clients_with_at_least": "Clientes com pelo menos ",
    "connection_last": " conexão no último ",
    "return_in_last_week": " semana, mas não retornou na última ",
    "arr_menu_44": " semana",
    "df_msg_4": "Queremos tanto você de volta que vamos lhe dar 10% de desconto só por devolver! Quer nos dar uma chance de novo hoje?",
    "update_campaign_text": "Campanha de atualização de reconquista de clientes",
    "re_invite_customers": "Convide novamente os clientes que não retornam há algum tempo",
    "loyal_patrons": "Mostre apreço aos clientes fiéis com descontos especiais e recompensas de fidelidade",
    "surprize_top_spenders": "Surpreenda os maiores gastadores com ofertas personalizadas e especiais.",
    "campaign_automatically_send": "Esta campanha envia automaticamente recompensas para aumentar o tráfego no seu negócio.",
    "sms_loyal_clients": "Esta campanha envia SMS\/MMS para clientes fiéis. Você pode alterar a segmentação abaixo.",
    "or_more_connection": " ou mais conexão, dentro do último ",
    "week_period": " período de semana.",
    "default_msg_5": "Você é cliente há muito tempo! Uau, o tempo voa! Nós o apreciamos e queremos agradecer por estar conosco.",
    "reward_regulars_update": "Recompensar regulares atualizam texto da campanha",
    "special_discounts": "Aprecie e dê descontos especiais💎",
    "leave_positive_online": "Incentive os clientes a deixar avaliações positivas on-line para aumentar a reputação do restaurante",
    "clients_service_online": "A campanha incentiva seus clientes a avaliar seu serviço online.",
    "increase_your_company": "Esta resposta automática é enviada para aumentar a classificação online da sua empresa.",
    "send_message_after": "Enviar mensagem depois ",
    "min_connection": " min da conexão",
    "default_msg_6": "Esperamos que você tenha gostado da sua primeira visita, adoraríamos vê-lo novamente em breve! [URL] Clique no link para nos avaliar no Google.",
    "ratings_update": "Obtenha mais atualizações de classificação de texto de campanha",
    "friendly_nudge": "Obtenha mais avaliações com um empurrãozinho amigável ⭐",
    "thanks_for_visiting": "Obrigado pela visita",
    "express_gratitude": "Expresse gratidão aos clientes por escolherem sua empresa com mensagens de agradecimento sinceras",
    "thank_you_message": "Crie uma mensagem de agradecimento para seus clientes.",
    "clients_for_visiting": "Envie um agradecimento caloroso aos seus clientes pela visita ao seu estabelecimento.",
    "campaign_audience": "Este público-alvo da campanha automática é predefinido, nós temos isso!",
    "campaign_automatically_sends": "Esta campanha automática envia automaticamente após ",
    "minutes_customer_visit": " minutos de visita ao cliente",
    "default_msg_7": "Obrigado por ser nosso cliente valioso. Somos muito gratos pelo prazer de atendê-lo e esperamos ter atendido às suas expectativas.",
    "visiting_update_campaign": "Obrigado por visitar o texto da campanha de atualização",
    "guests_thank_you": "Agradeça aos seus convidados pela visita 😊",
    "download_title": "Download",
    "qr_gen_title": "QR gerado",
    "qr_download_s_title": "Código QR baixado com sucesso",
    "sel_dark_brand_clr_msg": "Selecione uma cor de celeiro válida",
    "manage_customer_stamp_rewards": "Gerenciar recompensas de selos de clientes 🎁",
    "sel_loc_menu_title": "Selecione os locais para o seu menu",
    "ans_req": "Resposta é necessária",
    "valid_reputation_name": "Insira um nome de reputação válido",
    "reviews_name_req": "Obtenha mais avaliações nome é obrigatório",
    "birthdate_required": "Data de nascimento é necessária",
    "gender_required": "Gênero é obrigatório",
    "valid_birthdate_required": "Insira uma data de nascimento válida",
    "custom_delivery_redirection": "Crie um formulário de redirecionamento de entrega personalizado por grupos",
    "customer_fields_incentive_settings": "Campos de clientes e configurações de incentivos",
    "delivery_redirection_text": "Redirecionamento de entrega",
    "delivery_redirection_success": "Parabéns! Seu Widget de Redirecionamento de Entrega foi criado! 🎉",
    "swipe_to_preview_redirection": "Deslize para ver como ficará seu formulário de redirecionamento de entrega",
    "enter_item_name": "Insira o nome do item",
    "home_page_text": "Texto da página inicial",
    "settings": "Configurações",
    "anniversary_required": "A data de aniversário é obrigatória",
    "valid_anniversary": "Insira uma data de aniversário válida",
    "form_submited": "formulário enviado com sucesso",
    "notifications": "Notificações",
    "discount_message": "Ganhe 20% de desconto em pizza agora!",
    "is_required": " é necessário",
    "section_title_required": "O título da seção é obrigatório",
    "section_dec_required": "Descrição da seção é necessária",
    "client_details_required": "Os campos de detalhes do cliente são obrigatórios",
    "compliance": "Conformidade",
    "SMS_campaign1": "Campanha SMS 1",
    "mobile_number_mandatory": "O número do celular é obrigatório",
    "new_answer": "Nova resposta",
    "new_question": "Nova pergunta",
    "add_new_question": "Adicionar nova resposta",
    "select": "Selecione",
    "group_customers_question": "Onde você quer agrupar seus clientes?",
    "contacts_added_to_group": "Todos os contatos que enviarem o formulário serão adicionados ao grupo selecionado",
    "edit_client_details_section": "Editar seção de detalhes do cliente",
    "client_details_fields": "Campos de detalhes do cliente",
    "enter_client_details_section_title_desc": "Insira o título e a descrição da seção de detalhes do cliente",
    "choose_fields_displayed_client_side": "Escolha os campos a serem exibidos no lado do cliente",
    "section_title": "Título da Seção",
    "add_title": "Adicionar título",
    "section_description": "Descrição da Seção",
    "add_description": "Adicionar descrição",
    "enter_program_name_location": "Insira o nome do programa e a localização 📍",
    "brand_theme_clr_txt": "Cor do tema da marca",
    "set_loyalty_punch_card_theme": "Vamos definir um tema para seu cartão de fidelidade 🎨",
    "upload_logo_txt": "Carregue seu logotipo",
    "recommended_image_specs": "Recomendamos usar uma imagem de 512 x 512px para melhor exibição. JPG, SVG ou PNG. Tamanho máximo de 10 MB.",
    "valid_rep_name_txt": "Insira um nome de reputação válido",
    "rep_name_req_txt": "Obtenha mais avaliações nome é obrigatório",
    "que_req": "Pergunta é obrigatória",
    "day_of_birthday": "no dia do aniversário",
    "day_before_birthday": "3 dias antes do aniversário",
    "week_before_birthday": "uma semana antes do aniversário",
    "two_week_before_birthday": "duas semanas antes do aniversário",
    "de_active": "Desativar",
    "campaign_details": "Detalhes da campanha",
    "link_clicked": "Link clicado",
    "history": "História",
    "auto_responder_summary": "Resumo do Auto Responder",
    "vsop_1": "15 minutos",
    "vsop_2": "30 minutos",
    "vsop_3": "45 minutos",
    "vsop_4": "60 minutos",
    "vsop_5": "90 minutos",
    "vsop_6": "120 minutos",
    "in_the_last": " no ultimo ",
    "return_in_last": " mas não retornou no último ",
    "of_the_connection": "da conexão",
    "your_campaign": "Sua campanha",
    "you_have_successfully": "Você conseguiu com sucesso",
    "published_successfully": "publicado com sucesso",
    "updated_smart_campaign": "atualizou a campanha inteligente",
    "almost_done_text": "Quase pronto!",
    "update_campaign_desc": "Seu autoresponder está completo, uma vez habilitado, ele enviará mensagens aos clientes de forma contínua. Esta campanha pode ser facilmente modificada ou pausada a qualquer momento.",
    "update_and_publish": "Atualizar e publicar",
    "reset_campaign_title": "Ele excluirá permanentemente o texto personalizado, a imagem, as ofertas de desconto e o público-alvo da sua campanha!",
    "space_wifi": " Wi-fi",
    "custom_forms": "Formulários personalizados",
    "web_e_signup": "Inscrição eletrônica na Web",
    "import": "Importar",
    "permissions_req": "Permissões são necessárias",
    "redeemed": "Resgatado",
    "coupon_already_redeemed": "Cupom já resgatado em",
    "autoresponder_campaigns": "Campanhas de resposta automática",
    "autoresponder_campaign_desc": "Habilite o autoresponder e inicie a campanha personalizada",
    "mon": "SEG",
    "tue": "TER",
    "wed": "QUA",
    "thu": "QUI",
    "fri": "SEX",
    "sat": "SENTADO",
    "sun": "SOL",
    "duplicate": "Duplicado",
    "visibility": "Visibilidade",
    "availability": "Disponibilidade",
    "out_stok": "Fora de estoque",
    "edit_product": "Editar produto",
    "create_product": "Criar um produto",
    "basic_info": "Informações básicas",
    "basic_sub_info": "Lorem Ipsum é simplesmente um texto fictício da impressão...",
    "enter_product_price": "Insira o preço do produto",
    "upload_image": "Carregar imagem",
    "allowed_file_formats": "Somente arquivos JPG e PNG com tamanho máximo de 3 MB são permitidos.",
    "pre_select": "Pré-seleção",
    "promotions": "Promoções",
    "discount_availability": "Disponibilidade de desconto",
    "start_time": "Hora de início",
    "end_time": "Fim dos tempos",
    "select_day": "Selecione o dia",
    "menu_required": "O menu é obrigatório",
    "generated": "Gerado",
    "link": "Link",
    "variations": "Variações",
    "select_variant_to_add": "Selecione a variante que você gostaria de adicionar",
    "price_title": "Preço",
    "choose_image": "Escolha a imagem",
    "select_visiblity": "Selecione Visibilidade",
    "availability_schedule": "Cronograma de disponibilidade",
    "add_on_upsell": "Adicionar\/Upsell",
    "add_on_select_product": "Adicionar em Selecionar produto",
    "upsell_product": "Selecione o produto de upsell",
    "variant_deletion_warning": "Esta variante será excluída permanentemente.",
    "search_variations": "Variações de pesquisa",
    "add_variation": "Adicionar variação",
    "variation_text": "Variante",
    "select_customization": "Selecione personalização",
    "add_new": "Adicionar novo",
    "delete_customization_warning": "Deseja excluir esta personalização do produto?",
    "nutritional_allergen_info": "Informações nutricionais e alergênicas",
    "mark_item": "Marcar item",
    "calories_text": "Calorias",
    "allergens": "Alérgenos",
    "select_allergens": "Selecione alérgenos",
    "special_instructions": "Instruções especiais",
    "preparation_instructions": "Instruções de preparação",
    "staff_notes": "Notas da equipe",
    "checkbox1": "Uma caixa de seleção para permitir ou não instruções especiais dos clientes para este item de menu.",
    "menu_c": "MENU",
    "past_design": "Design passado",
    "file_name": "Nome do arquivo",
    "variation_name": "Nome da variação",
    "grid_items_title1": "Tenda de mesa",
    "grid_items_title2": "Cardápio Digital",
    "grid_items_title3": "Cardápio para viagem",
    "grid_items_title4": "Menu de meia página",
    "grid_items_title5": "Cardápio Digital 5",
    "grid_items_title6": "Cardápio Digital 6",
    "grid_items_title7": "Cardápio Digital 7",
    "grid_items_title8": "Cardápio Digital 8",
    "grid_items_title9": "Cardápio Digital 9",
    "enter_valid_form_name": "Insira um nome de formulário válido",
    "form_name_required": "O nome do formulário é obrigatório",
    "enter_valid_section": "Insira um título de seção válido",
    "fraction_half": "1\/2",
    "fraction_two_by_two": "2\/2",
    "form_name": "Nome do formulário",
    "contact_list": "Lista de Contatos",
    "label_notify_email": "Gostaria de ser notificado por e-mail?",
    "add_client_details_section": "Adicionar seção de detalhes do cliente",
    "demo_purpose_only": "É apenas para fins de demonstração",
    "form_updated_success": "formulário atualizado com sucesso",
    "form_creat_success": "formulário criado com sucesso",
    "add_custom_section": "Adicionar uma seção personalizada",
    "edit_custom_section": "Editar uma seção personalizada",
    "forms_list": "Lista de Formulários",
    "filled_custom_form_details": "Detalhes do formulário personalizado preenchido",
    "custom_form_deletion_warning": "Este formulário personalizado será excluído permanentemente",

    "menu_link": "Link do menu",
    "WebSignUpT": "Inscrição eletrônica",
    "lifeTConT": "Conexões para a vida toda",
    "repCustT": "Clientes recorrentes",
    "liftTSubT": "Assinantes vitalícios",
    "overallT": "GERAL",
    "loginCustBDT": "Clientes conectados por dia",
    "deviceT": "Dados demográficos do dispositivo",
    "ageTitle": "Demografia etária",
    "ageT": "Faixa etária",
    "convert_social_media_followers_into_customers": "Converta seguidores de mídia social em clientes",
    "signWidDesc": "Crie um widget para inscrição, criação de landing pages e geração de cupons do seu site ou mídia social",
    "qrWidT": "QR inteligente",
    "imgWidT": "Número de inscrição",
    "mobile": "Móvel",
    "YESTitle": "SIM",
    "NOTitle": "NÃO",
    "addDelRed": "Adicionar redirecionamento de entrega",
    "dlredNameT": "Nomeie seu redirecionamento de entrega",
    "conf_text_req": "Texto de confirmação é obrigatório",
    "cFDesc": "Crie formulários personalizados para coletar feedback e informações específicas dos clientes de forma eficiente",
    "pre_review_questions_enabled": "Antes de pedir uma avaliação aos clientes, vamos fazer de 1 a 4 perguntas.",
    "feel_free_edit": "Sinta-se à vontade para editar conforme necessário.",
    "option": "Opção",
    "confFL": "Esta confirmação será exibida após seu contato enviar o Formulário de inscrição na Web",
    "confT": "Texto de confirmação",
    "joinTitle": "Juntar",
    "mobValidL": "Por favor, verifique o número de celular válido",
    "mobRequiredL": "É necessário número de celular",

    "Business_location_label": "Localização do seu negócio",
    "qr_code_selection_txt": "Seleção de código QR",
    "choose_theme_txt": "Escolha o tema",
    "edit_qr_details_txt": "Editar detalhes do QR",
    "save_qr_code_txt": "Salvar código QR",
    "text_color_label": "Cor do texto",
    "background_color_label": "Cor de fundo",
    "print_txt": "Imprimir",
    "customer_will_see_txt": "É isso que seu cliente verá. Escolha a cor da sua marca na próxima etapa.",
    "choose_your_brand_color_&_langauge_title": "Escolha a cor e o idioma da sua marca",
    "custom_redirect_link_label": "Link de redirecionamento personalizado",
    "redirect_user_to_menu": "Redirecionar usuário para o menu",
    "language": "Idioma",
    "select_language_placeholder": "Selecione o idioma",
    "capitalize_location_name_txt": "Nome do local",
    "created_txt": "Criado",
    "swipe_reputation_management_txt": "Deslize para ver como ficará sua gestão de reputação",
    "sent_txt": "Enviado",
    "number_label": "Número",
    "date_label": "Data",
    "gender_demographics_title": "Demografia de gênero",

    "step1_preview_desc": "Nomeie seu formulário QR de inscrição eletrônica e adicione-o à sua lista de grupos",
    "main_head": "Adquira novos clientes",
    "main_desc": "Conquiste clientes inserindo um código QR que, quando escaneado, redireciona os usuários para um formulário de inscrição personalizado.",
    "step1_description": "Conquiste novos clientes de outras empresas",
    "form_name_field_placeholder": "Restro E-inscrição",
    "last_step_title": "Parabéns! Seu Widget de E-signup foi criado! 🎉",
    "last_step_desc": "Deslize para ver como ficará seu formulário de inscrição eletrônica",
    "events_step1_preview_desc": "Nomeie seu formulário QR de evento e adicione-o à sua lista de grupos",
    "events_main_head": "Obtenha clientes de eventos",
    "events_main_desc": "Conquiste clientes colocando um código QR no evento que, quando escaneado, redireciona os usuários para um formulário de inscrição personalizado.",
    "events_step1_desc": "Capte clientes de eventos e traga-os para a loja",
    "events_name": "Nome do evento",
    "events_form_name_field_placeholder": "Restro Evento de diversão e comida",
    "events_last_step_title": "Parabéns! Seu Formulário de Evento foi criado! 🎉",
    "events_last_step_desc": "Deslize para ver como ficará o seu formulário de evento",
    "birthday_anniversary_step1_preview_desc": "Nomeie seu formulário QR para coletar o aniversário ou data comemorativa do cliente e adicione-o à sua lista de grupos",
    "birthday_anniversary_main_head": "Colecione aniversário ou data comemorativa",
    "birthday_anniversary_main_desc": "Obtenha o aniversário ou aniversário do cliente, quando escaneado, redireciona os usuários para um formulário de inscrição personalizado.",
    "birthday_anniversary_step1_desc": "Coletar aniversário e data de nascimento do cliente",
    "restro_text": "Restro",
    "birthday_anniversary_last_step_title": "Parabéns! Obtenha o formulário de aniversário e aniversário de casamento dos clientes criado! 🎉",
    "newspaper_ads_step1_preview_desc": "Nomeie seu formulário QR para obter clientes de anúncios de jornal e adicioná-lo à sua lista de grupos",
    "newspaper_ads_main_head": "Obtenha clientes por meio de anúncios",
    "newspaper_ads_main_desc": "Obtenha clientes por meio de anúncios em jornais. Quando eles escaneiam, ele redireciona os usuários para um formulário de inscrição personalizado.",
    "newspaper_ads_step1_desc": "Obtenha clientes por meio de anúncios em jornais ou outdoors",
    "newspaper_ads_last_step_title": "Parabéns! Obtenha clientes a partir do formulário de anúncios de jornal criado! 🎉",
    "smart_pamphlets_step1_preview_desc": "Nomeie seu formulário QR para obter dados de clientes de panfletos e adicioná-lo à sua lista de grupos",
    "smart_pamphlets_main_head": "Obtenha clientes por meio de panfletos",
    "smart_pamphlets_main_desc": "Obtenha dados do cliente a partir de panfletos. Quando eles são escaneados, ele redireciona os usuários para um formulário de inscrição personalizado.",
    "smart_pamphlets_last_step_title": "Parabéns! Obtenha dados do cliente a partir do formulário de panfletos criado! 🎉",
    "social_media_step1_preview_desc": "Nomeie seu formulário QR para obter clientes das mídias sociais e adicioná-lo à sua lista de grupos",
    "social_media_main_desc": "Obtenha clientes nas redes sociais. Quando eles acessam as postagens, ele redireciona os usuários para um formulário de inscrição personalizado.",
    "social_media_form_name_field_placeholder": "Oferta Festival Restro - Postagem no Facebook",
    "social_media_last_step_title": "Parabéns! Criei um formulário de mídia social para clientes! 🎉",
    "claim_offer_text": "Preencha seus dados e reivindique a oferta agora",
    "form_title_description": "Adicione o título e a descrição do formulário para que seus clientes insiram os detalhes",
    "no_subscribed_clients": "O grupo de clientes selecionado não possui nenhum cliente inscrito",
    "welcome_message_offer": "Envie uma mensagem de boas-vindas aos seus clientes, depois que eles se inscreverem e dê a eles um cupom de oferta",
    "system_grp": "Grupos de sistemas",
    "import_clients": "Importar clientes",
    "delivery_redirection_name": "Nome de redirecionamento de entrega",
    "created_at": "Criado em",
    "provide_stamp_user": "Fornecer carimbo ao usuário",
    "scan_qr_code": "Escaneie o código QR",
    "add_point": "Adicionar ponto",
    "total_points": "Total de pontos",
    "user_profile": "Perfil do usuário",
    "stamp_history": "História do Selo",
    "reward_txt": "Recompensa",
    "variant_placeholder": "Pequeno, Médio, Grande, etc.",
    "sent_coupon": "Cupom enviado",
    "order_status": "Status do pedido",
    "payment_status": "Status do pagamento",
    "order_currency": "Moeda do pedido",
    "card_data": "Dados do cartão",
    "reward_expiring_on": "Recompensa expirando em",
    "reward_status": "Status de recompensa",
    "reward_get_date": "Recompensa Obter Data",
    "reward_name": "Nome da recompensa",
    "add_point_type": "Adicionar tipo de ponto",
    "restaurant_location": "Localização do restaurante",
    "collect_points": "Colecionar pontos",
    "rewards_expire": "Recompensas expiram",
    "rewards_redeem": "Recompensas Resgatar",
    "total_rewards": "Recompensas totais",
    "created_by": "Criado por",
    "anniversary": "Aniversário",
    "remove_user_group_txt": "Remover usuário do grupo",
    "delete_permanently_grp_msg": "Este cliente será excluído permanentemente do grupo.",
    "delete_multiple_permanently_grp_msg": "Esses clientes serão excluídos permanentemente do grupo.",
    "delete_multiple_client": "Esses clientes serão excluídos permanentemente.",
    "messages": "Mensagens",
    "management": "Gerenciamento",
    "client": "Cliente",
    "country": "País",
    "state": "Estado",
    "city": "Cidade",
    "zip_code": "CEP",
    "1_sparkly_point_txt": "🎉 Você ganhou 1 ponto brilhante! 🌟",
    "select_country_txt": "Selecione o país",
    "select_state": "Selecione o estado",
    "ls_thanks_for_join": "Obrigado por aderir ao nosso cartão de fidelidade.🎁🎈",
    "month": "Mês",
    "welcome_message": "Envie uma mensagem de boas-vindas aos seus clientes, depois que eles se inscreverem",
    "all_rewards_txt": "Todas as recompensas",
    "active_rewards": "Recompensas Ativas",
    "expired_and_redeemed": "Expirado e resgatado",
    "redirect_3rd_party_delivery": "Redirecione a entrega de terceiros para sua loja ou para sua plataforma de pedidos on-line",
    "search_or_start_new": "Pesquisar ou iniciar um novo",
    "contacts": "Contatos",
    "chats": "Bate-papos",
    "add_by_restaurant": "Adicionar por Restaurante",
    "using_scan_code": "Usando o código de digitalização",
    "step": "Etapa",
    "list_widget_text": "Lista de Widgets",
    "swipe_free_wifi_txt": "Deslize para obter Wi-Fi gratuito",
    "create_widget_txt": "Criar Widget",
    "redeem_award_msg": "Tem certeza? Você quer resgatar esta recompensa",
    "on_collect_msg": "Na coleta",
    "pause": "Pausa",
    "resume": "Retomar",
    "location_added": "localização adicionada",
    "sub_user_admin_permissions_txt": "Permitir que o subusuário acesse permissões de administrador no Digital Signage",
    "unlist": "Não listar",
    "cannot_delete_default_terms": "Não é possível excluir os termos e condições padrão",
    "something_went_wrong": "Algo deu errado!",
    "card_download_success": "Cartão baixado com sucesso",
    "permission_denied": "Permissão negada!",
    "invalid_qr_code": "Por favor, escaneie o código QR válido",
    "select_customer": "Selecione o cliente",
    "online": "ON-LINE",
    "offline": "OFFLINE",
    "no_data": "Ops, nenhum dado encontrado!",
    "forever_free": "Para sempre livre",
};

export default pt