const hu = {
    "rated_google_reviews": "5.0-ra értékelt a Google Reviews oldalon",
    "get_your_digital_menu": "Szerezd meg digitális étlapodat –",
    "instant_popup_menu": "Azonnali felugró menü QR szkennelés nélkül",
    "free_forever": "Ingyenes Forever",
    "the_future_is_here": "A jövő itt van!",
    "upgrade_your_restaurant": "Frissítse éttermét egy lenyűgöző digitális menüvel nulla költséggel. Vonzzon több ügyfelet a mai naptól.",
    "get_my_free_menu_now": "Szerezze meg az ingyenes menümet most",
    "no_credit_card_required": "*Nem szükséges hitelkártya",
    "join_largest_marketplace": "Csatlakozzon a legnagyobb étterem piacteréhez!",
    "what_will_you_get": "Mit kapsz",
    "free": "INGYENES",
    "free_digital_menu": "Ingyenes digitális menü 2D vagy 3Digitális",
    "free_digital_menu_desc": "Mondjon búcsút a papír menüknek és a drága utánnyomásoknak",
    "quick_and_easy_signup": "Gyors és egyszerű regisztráció",
    "communicate_with_customers_title": "Kommunikálj az ügyfelekkel INGYEN!",
    "features1": [
        "Takarítson meg nyomtatási költségeket, és élvezze az azonnali menüfrissítéseket, amikor csak szüksége van rájuk.",
        "Bármilyen eszközön elérheti digitális menüjét",
        "Környezetbarát és problémamentes"
    ],
    "features2": [
        "Hagyja, hogy az ügyfelek virtuálisan felfedezhessék ételeit lenyűgöző 3D-s látványvilággal.",
        "Fokozza az elkötelezettséget azáltal, hogy az étkezők valósághű előnézetet nyújtanak étkezésükről.",
        "Tűnjön ki egy olyan menüélménnyel, amely éppolyan csábító, mint az étel íze."
    ],
    "features3": [
        "Azonnal köszöntse a vendégeket az étlappal, amint belépnek az étterembe.",
        "Automatikusan küldjön frissítéseket a különleges ajánlatokról és promóciókról a telefonjára.",
        "Fokozza az étkezési élményt az étlaphoz való gyors, érintésmentes hozzáféréssel."
    ],
    "feature4": [
        "Emelje ki a szezonális ajánlatokat, napi ajánlatokat és exkluzív akciókat.",
        "Hívja fel ügyfelei figyelmét legnépszerűbb termékeire.",
        "Sürgősséget és izgalmat kelthet szemet gyönyörködtető látványelemekkel és leírásokkal."
    ],
    "feature5": [
        "Legyen része egy online közösségnek, amelynek naponta több ezer látogatója van.",
        "Növelje a láthatóságot, és érjen el új ügyfeleket, akik aktívan keresnek étkezési lehetőségeket.",
        "Korán szerezzen első helyet, hogy maximalizálja az expozíciót, és több vendéget vonzzon."
    ],
    "section3_heading": "Miért érdemes Digitalizálni? Mert ingyenes és egyszerű!",
    "section3_description": "Fedezze fel a hatékony funkciókat, amelyek célja az étterem vonzerejének növelése.",
    "instant_menu": "Azonnali menü",
    "instant_menu_notification": "Felugró ablak és értesítés",
    "instant_menu_description": "Üdvözöljük a vendégeket azonnali menühozzáféréssel, amint belépnek. Automatikusan elküldi a legújabb menüt és különleges ajánlatokat közvetlenül a mobiltelefonjára",
    "digital_menu": "Digitális menük",
    "at_zero_cost": "nulla költséggel",
    "digital_menu_description1": "Mondjon búcsút a papír menüknek és a drága utánnyomásoknak. Váltson egy digitális menüre, amely mindig naprakész – és örökre ingyenes!",
    "digital_menu_description2": "Digitális étlapunknak köszönhetően kínálata mindig naprakész és bármely eszközről elérhető. Takarítson meg pénzt és egyszerűsítse a menüfrissítéseket egy zökkenőmentes, költségmentes megoldással.",
    "try_me_button": "Próbálj meg engem",
    "try_3d_menu_title": "3D menük",
    "try_3d_menu_title2": "Ingyen – WOW!",
    "try_3d_menu_description1": "Tedd le az ételeidet a képernyőről. Töltsön fel 3D képeket, és hagyja, hogy az ügyfelek virtuálisan megkóstolhassák ételeit, mielőtt belépnének.",
    "try_3d_menu_description2": "A kiváló minőségű 3D képek ínycsiklandó előnézetet hoznak létre, fokozva a vonzerőt és bátorítva a rendeléseket.",
    "try_now": "Próbálja ki most",
    "showcase_title": "Mutasd be a sajátodat",
    "showcase_specials": "Különlegességek",
    "showcase_specials_description1": "Van egy ínycsiklandó üzlet vagy egy verhetetlen promóció? Jelölje ki digitális menüjében, és vonzzon be több éhes embert.",
    "showcase_specials_description2": "Lehetővé tegye a promóciók kihagyását, felhívja a figyelmet merész látványvilággal és csábító leírásokkal.",
    "marketplace": "Piactér",
    "marketplace_description": "Naponta több mint 2000 látogatóval rendelkező piacteret hozunk létre. Ugorjon fel most, és Ön lesz az első a sorban, amikor elindulunk. Új ügyfeleket érhet el, akik aktívan keresnek étkezési lehetőségeket. Biztosítsa a helyét korán, hogy maximalizálja az expozíciót!",
    "see_other_products": "Lásd Egyéb termékek",
    "about_us": "Rólunk",
    "why_serving_free": "Miért tálaljuk ezt?",
    "serving_free_highlight": "Ingyenes",
    "about_desc": "Helyiek vagyunk itt, Dallasban, és elkötelezettek amellett, hogy segítsünk az Önéhez hasonló éttermek boldogulásában.",
    "about_tool_free": "Ezt a hatékony eszközt teljesen ingyenesen kínáljuk, mert hiszünk közösségünk támogatásában.",
    "no_catch": "Nincs fogás, ne vicceljünk. Tudjuk, hogy ha sikerül, közösségünk erősebbé válik. 😊",
    "understanding_challenges": "Megértjük a vállalkozás vezetésével járó kihívásokat, ezért elkötelezettek vagyunk olyan eszközök biztosítása mellett, amelyek gördülékenyebbé teszik az Ön működését, és szélesebb körűvé teszik az elérhetőséget.",
    "community_growth": "Ahogy nő, közösségünk veled együtt növekszik, ami a siker és a támogatás hullámzó hatását hozza létre. Emeljük együtt mindenki számára az étkezési élményt.",
    "how_it_works": "Hogyan működik",
    "three_simple_steps": "Három egyszerű lépés a",
    "get_started_highlight": "Kezdje el",
    "no_tech_skills_needed": "Nincs szükség műszaki ismeretekre. Ha fel tud forgatni egy hamburgert, akkor ezt megteheti!",
    "step1_title": "Jelenítse meg promócióját INGYEN!",
    "step1_desc": "Kezdje el gyorsabban, mint ahogy azt kimondaná: \"Rendeld fel!\" Csak tölts ki egy egyszerű űrlapot.",
    "step2_title": "Testreszabhatja menüjét",
    "step2_desc": "Adja hozzá ételeit, töltsön fel fényképeket akár 3D-ben is, és emelje ki különlegességeit.",
    "step3_title": "Megosztás és növekedés",
    "step3_desc": "Ossza meg digitális menüjét QR-kódokon, közösségi médián vagy webhelyén.",
    "testimonials": "Beszámolók",
    "testimonials_section_title": "Ne csak fogadd a szavunkat",
    "testimonials_section_desc": "Nézze meg, mit mondanak más dallasi étteremtulajdonosok.",
    "faq_section_title": "Kérdései vannak? Megvannak a válaszaink",
    "faq_section_desc": "Minden, amit tudnia kell, mielőtt elkezdi.",
    "faqs": "GYIK",
    "faq_answers": [
        {
            "que": "Tényleg örökre ingyenes?",
            "ans": "Teljesen! Nincsenek rejtett díjak, nincsenek hitelkártyák, nincsenek kötöttségek."
        },
        {
            "que": "Szükségem van valamilyen műszaki know-how-ra?",
            "ans": "Ha tudsz e-mailt küldeni, megteheted. Ilyen egyszerű."
        },
        {
            "que": "Miért ajánlod ezt ingyen?",
            "ans": "Hiszünk abban, hogy először adunk. A sor végén más szolgáltatásaink is lehetnek, amelyek tetszeni fognak – de ez kizárólag Önön múlik."
        },
        {
            "que": "Mi a helyzet a piactérrel?",
            "ans": "Főzzük! Amint elegendő étterem lesz a fedélzeten, elindítjuk piacterét, hogy még nagyobb nyilvánosságot kapjon."
        }
    ],
    "testimonials_content1": "A digitális menüre váltás volt a legjobb lépés, amit tettünk! Ügyfeleink szeretik a kényelmet és az újszerű megjelenést!",
    "testimonials_name1": "Morris George",
    "testimonials_restaurant_name1": "A Mansion étterem",
    "testimonials_content2": "Új digitális étlapunk megkönnyíti a böngészést a vendégek számára, és ez valóban fokozza az elköteleződést!",
    "testimonials_name2": "Amelia Johnson",
    "testimonials_restaurant_name2": "Urban Eats",
    "testimonials_content3": "A vendégeket lenyűgözi a 3D-s látvány – ez segített kitűnnünk, és fokozta étkezési élményüket!",
    "testimonials_name3": "Davis Green",
    "testimonials_restaurant_name3": "Városi kandalló",
    "ready_to_boost_your_restaurant": "Készen áll, hogy feldobja éttermét?",
    "ready_to_boost_desc": "Ne maradj le. Csatlakozzon még ma a digitális forradalomhoz!",
    "yes_i_want_my_free_digital_menu": "Igen, szeretném az ingyenes digitális menümet",
    "480_plus": "480+",
    "people_already_joined": "emberek már csatlakoztak",
    "menu_online_home": "Menü Online Főoldal",
    "welcome": "Üdvözöljük",
    "description_other": "Növeljük vevői elégedettségét!",
    "benefits": "Előnyök",
    "greeting_text": "Üdvözöllek 👋",
    "all_in_one_features": "🚀 Minden az egyben funkciók az étterem felemeléséhez",
    "menu_online_menu_name": "Menü Online",
    "menu_online_description": "Digitalizálja étlapját az ügyfelek egyszerű online eléréséhez.",
    "menu_online_benefits": "Növelje az ügyfelek kényelmét és fokozza az elköteleződést azáltal, hogy lehetővé teszi számukra, hogy bárhol és bármikor megtekinthessék menüjét.",
    "ds_name": "Digital Signage",
    "ds_description": "Mutasson be ajánlatokat és promóciókat a dinamikus bolti képernyőkön.",
    "ds_benefits": "Könnyedén ragadja meg ügyfelei figyelmét lenyűgöző látványvilággal, népszerűsítse az upsell-eket és a valós idejű ajánlatokat.",
    "loyalty_punch_name": "Hűség-lyukkártya",
    "loyalty_punch_description": "Jutalmazza a visszatérő vásárlókat digitális hűségprogrammal.",
    "loyalty_punch_benefits": "Növelje az ügyfelek megtartását és növelje az eladásokat azáltal, hogy a gyakori látogatásokért beváltható jutalmakkal ösztönzi a hűséget.",
    "marketing_menu_name": "Marketing",
    "marketing_description": "Érjen el több ügyfelet célzott digitális marketingkampányokkal.",
    "marketing_benefits": "Bővítse éttermének elérhetőségét, és növelje a látogatottságot az új és visszatérő ügyfelek vonzására szabott egyedi promóciókkal.",
    "get_more_reviews_menu_name": "További vélemények",
    "get_more_reviews_description": "Ösztönözze az elégedett ügyfeleket, hogy pozitív online véleményeket írjanak.",
    "get_more_reviews_benefits": "Növelje éttermének hitelességét, és vonzzon új éttermeket azáltal, hogy online hírnevét még több pozitív értékeléssel javítja.",
    "guest_smart_wifi_menu_name": "Vendég Smart Wifi",
    "guest_smart_wifi_description": "Ingyenes Wi-Fi-t kínál a vendégadatok marketing célú rögzítéséhez.",
    "guest_smart_wifi_benefits": "Növelje az ügyfelek elégedettségét, miközben értékes ügyféladatokat gyűjt a jövőbeli promóciók személyre szabásához.",
    "reservation_menu_name": "Foglalás",
    "reservation_description": "Lehetővé teszi az ügyfelek számára, hogy könnyedén asztalt foglaljanak online.",
    "reservation_benefits": "Javítsa az étkezési élményt a várakozási idő minimalizálásával, és egyszerűsítse a foglalásokat az ülések jobb kezeléséhez.",
    "pos_integration_menu_name": "POS integráció",
    "pos_integration_description": "Integrálja rendszerünket POS rendszerével.",
    "pos_integration_benefits": "Egyszerűsítse a rendelések feldolgozását és csökkentse a hibákat azáltal, hogy szinkronban tartja online és bolti rendszereit.",
    "online_ordering_menu_name": "Online rendelés",
    "online_ordering_description": "Lehetővé teszi az ügyfelek számára, hogy közvetlenül az Ön webhelyéről vagy alkalmazásából rendeljenek.",
    "online_ordering_benefits": "Növelje az eladásokat és csökkentse a harmadik felek platformjaitól való függőséget azáltal, hogy közvetlen rendelési lehetőséget kínál, amely díjakat takarít meg.",
    "pro_label": "Pro",
    "coming_soon": "Hamarosan",
    "locked_label": "Bezárt",
    "forever_label": "Örökre",
    "new_label": "Új",
    "other_products_page_title": "Egyéb termékek | Menü Online",
    "other_products_description": "Menü Online adatvédelmi szabályzat",
    "other_products_header_title": "Egyéb termékek",
    "privacy_policy": {
        "title": "Adatvédelmi szabályzat",
        "lastUpdated": "Utolsó frissítés: 2024. július 1",
        "introduction": {
            "header": "Adatvédelmi szabályzat új és meglévő ügyfelek számára",
            "paragraph1": "AZ ALÁBBI FONTOS PONTOK (BEÉVEZETETT A RÉSZRE VONATKOZÓKAT is) KIZÁRÓLAG AZ ÖN KÉNYELMÉÉRT BIZTOSÍTOK, ÉS NEM HELYETTIK A TELJES SZERZŐDÉSI FELTÉTELEKET. AZ ÖN FELELŐSSÉGE, HOGY A MENUONLINE INC SZOLGÁLTATÁS HASZNÁLATA ELŐTT OLVASSA EL EZEKET A FELTÉTELEKET.",
            "paragraph2": "A Menuonline használata azt jelenti, hogy Ön elfogadja az Általános Szerződési Feltételeket, és kijelenti és szavatolja, hogy erre a szervezete vagy vállalata felhatalmazta magát. (Lásd a 3. szakaszt „Hogyan működik”)",
            "paragraph3": "A Menuonline Inc egy engedélyen alapuló marketingszolgáltatás. A „spam” szándékos küldése vagy az Általános Szerződési Feltételek megsértése fiókja bezárását vonja maga után. (Lásd a 16. szakaszt „A szolgáltatás elfogadható használata”)",
            "paragraph4": "A Menuonline Inc.-ben tárolt adatok, például a kapcsolatfelvételi adatok, az e-mailek tartalma és a Menuonline Inc.-n keresztül gyűjtött információk az Öné. (Lásd a 14. szakaszt „Mi a tulajdonod?”).",
            "paragraph5": "A Menuonline Inc szoftvere és a weboldalunkon található tartalom a miénk. (Lásd a 13. szakaszt, „Mi a tulajdonunk?”) A saját felhasználóin kívül a Menuonline Inc.-nél tárolt adatokat vagy közzé nem tett tartalmakat csak a Menuonline Inc. alkalmazottai és partnerei tekinthetik meg, akik aláírták a titoktartási megállapodást. (Lásd a 14. szakaszt „Mi a tulajdonod?”)",
            "paragraph6": "Minden személyazonosításra alkalmas adatot (PII) csak Kanadában található szervereken tárolunk. (Lásd a 14. szakaszt „Mi a tulajdonod?”).",
            "paragraph7": "A Menuonline Inc szolgáltatásra Kanada és Ontario tartomány törvényei vonatkoznak. (Lásd a 24. szakaszt „Irányadó jog”)",
            "paragraph8": "Az Ön adataira vagy tartalmára vonatkozó, Kanadán belüli vagy Kanadán kívüli személyek, rendőrség vagy más szabályozó ügynökségek által benyújtott kéréseket mindig Önhöz irányítjuk. Az egyetlen kivétel az, ha ezzel megsértjük a kanadai vagy ontariói törvényeket; mindazonáltal minden esetben keményen ellenállunk, hogy adatait Önön kívül bárki kiadja. (Lásd a 14. szakaszt „Mi a tulajdonod?”).",
            "paragraph9": "A Menuonline Inc biztonsága csak annyira jó, mint a felhasználónevek és jelszavak kezelése. Nem számítunk fel díjat a Menuonline Inc felhasználói fiókokért, ezért minden felhasználó számára hozzon létre külön fiókot, és tartsa titokban a jelszavakat. Ön felelős a fiókjáért. (Lásd a 3. szakaszt „Hogyan működik”)",
            "paragraph10": "Fiókját bármikor megszüntetheti. Lemondás esetén a fel nem használt hitelt nem térítjük vissza. (Lásd a 3. szakaszt „Hogyan működik”)"
        },
        "termsAndConditions": {
            "title": "MENUONLINE INC. FELTÉTELEK",
            "lastUpdate": "UTOLSÓ FRISSÍTÉS: 2020. január 14",
            "paragraph1": "Kérjük, figyelmesen olvassa el ezeket a szolgáltatási feltételeket (a „Feltételek”), mielőtt használná ezt a webhelyet (a „Webhely”). Ezek a Feltételek mentesítik a Menuonline Inc.-t (\"Menuonline Inc\" \"mi\", vagy \"mi\") és másokat a felelősség alól, és\/vagy korlátozzák a mi és az ő felelősségüket, és egyéb fontos rendelkezéseket is tartalmaznak, amelyek az oldal Ön általi használatára vonatkoznak. A Webhely vagy a Szolgáltatás meglátogatásával vagy használatával Ön a saját nevében és bármely olyan szervezet nevében, amelynek nevében eljárhat (a továbbiakban együttesen „Ön”) elfogadja és betartja ezeket a Feltételeket a webhely minden egyes használatára és minden látogatására vonatkozóan."
        },
        "services": {
            "title": "Szolgáltatásaink.",
            "paragraph": "A webhelyen keresztül kínált szolgáltatások, amelyek lehetővé teszik e-mail üzenetek („E-mailek”) létrehozását, küldését és kezelését az Ön által választott címzetteknek (a „Szolgáltatás”)."
        },
        "license": {
            "title": "Engedély.",
            "paragraph": "A jelen Feltételek értelmében a Menuonline Inc ezennel nem kizárólagos, korlátozott, nem átruházható licencet biztosít Önnek az időtartam alatt a Webhely megtekintésére és a Szolgáltatás használatára a Szolgáltatás funkcióinak megfelelően. Fenntartunk minden olyan jogot, amelyet itt kifejezetten nem biztosítunk a Szolgáltatással és a Menuonline Inc Tartalommal kapcsolatban (az alábbiakban meghatározottak szerint)."
        },
        "emailingContent": {
            "title": "Tartalom küldése e-mailben.",
            "paragraph": "Amikor kapcsolatba lép a Menuonline Inc-vel ezen a webhelyen vagy a szolgáltatáson keresztül, Ön beleegyezik, hogy valós és pontos információkat adjon meg. Ezenkívül tisztában van azzal, hogy a bizalmas információk e-mailben történő megadása nem biztonságos gyakorlat. Ön tudomásul veszi, hogy kizárólag Ön felelős mindenért, amit a Szolgáltatásunkat használó bárkinek elküld."
        },
        "support": {
            "title": "Támogatás.",
            "paragraph": "Támogatást nyújtunk Önnek, hogy önellátóvá váljon a Szolgáltatással. Támogatást nyújtunk Önnek dokumentáció és videók formájában az Oldalon, a Menuonline Inc támogatási jegyrendszerén és telefonon. Az oldal tetején található „Támogatás” fülre kattintva érhet el minket. A terméktámogatásért nem kell fizetni; azonban fenntartjuk a jogot arra, hogy saját belátásunk szerint megszüntessük bizonyos ügyfélfiókok támogatását."
        }
    },
    "terms_condition": {
        "title": "Szolgáltatási feltételek | Menü Online",
        "header": "Általános Szerződési Feltételek új és meglévő ügyfelek számára"
    },
    "privacy_policy_title": "MAGÁNPOLITIKA",
    "privacy_policy_effective_date": "Érvényes: 2020. január 16-tól",
    "privacy_policy_general_title": "ÁLTALÁNOS MAGÁNPOLITIKA",
    "privacy_policy_intro": "A menuonline.com (a Menuonline és „mi”) tisztában van az egyének személyes adatainak védelmének fontosságával, valamint minden típusú bizalmas információ biztonságának fontosságával. Azért hoztuk létre ezt az adatvédelmi szabályzatot (a „Szabályzat”), hogy demonstráljuk elkötelezettségünket az Ön „adatainak védelme” mellett. Ez a magánpolitika a következőket magyarázza:",
    "privacy_policy_points": [
        "Milyen személyes adatokat gyűjtünk és miért gyűjtjük azokat;",
        "Hogyan használjuk fel az Ön személyes adatait;",
        "Kivel osztjuk meg személyes adatait; és",
        "Azok a lehetőségek, amelyekkel hozzáférhet, frissítheti és eltávolíthatja személyes adatait a szolgáltatásból."
    ],
    "privacy_policy_pipeda": "A Menuonline az összes információ gyűjtését Kanada személyes adatok védelméről és elektronikus dokumentumokról szóló törvényének (PIPEDA) megfelelően kezeli.",
    "privacy_policy_update_notice": "Kérjük, vegye figyelembe, hogy a Menuonline fenntartja a jogot, hogy ezt az Adatvédelmi szabályzatot bármikor frissítse vagy módosítsa. Minden változásról értesítjük Önt egy frissített szabályzat közzétételével ezen a weboldalon vagy a szolgáltatásokon keresztül. A jelen Szabályzat legújabb verziója szabályozza a felhasználók személyes adatainak mycircle általi felhasználását. Ha továbbra is hozzáfér a mycircle webhelyéhez, vagy folytatja szolgáltatásainak használatát a jelen Adatvédelmi szabályzat módosítása után, Ön magára nézve kötelezőnek ismeri el a felülvizsgált feltételeket.",
    "privacy_policy_consent_title": "SZEMÉLYES INFORMÁCIÓKHOZ HASZNÁLHATÓ BEJELENTKEZÉS",
    "privacy_policy_consent_text": "Ha weboldalunkon vagy bármely űrlapunkon keresztül megadja személyes adatait a Menuonline-nak, Ön hozzájárul az ilyen információk gyűjtéséhez, felhasználásához és nyilvánosságra hozatalához a jelen Szabályzattal összhangban, vagy a személyes adatok megadásakor Ön által meghatározott célokra. A személyes adatok bármilyen másodlagos felhasználása csak az Ön kifejezett hozzájárulásával kerül feldolgozásra, vagy lehetőséget biztosítunk a leiratkozásra.",
    "privacy_policy_opt_out_text": "Bármikor lehetősége van visszavonni hozzájárulását vagy leiratkozni. Ha szeretné visszavonni hozzájárulását vagy leiratkozni, kattintson a tőlünk kapott e-mailek alján található kommunikációs beállítások linkre.",
    "privacy_policy_info_collection_title": "GYŰJTÉSÜNK INFORMÁCIÓK",
    "privacy_policy_info_collection_text": "A Menuonline a jelen Szabályzatnak megfelelően gyűjti és dolgozza fel a személyes adatokat. Ezeket az információkat nem adjuk el, osztjuk meg vagy béreljük másoknak, kivéve a jelen Szabályzatban meghatározott eseteket. A Menuonline személyes adatokat gyűjt abból a célból, hogy szolgáltatásainkat vagy termékeinket nyújthassa Önnek.",
    "privacy_policy_info_types_text": "A Menuonline által gyűjtött információk a következők:",
    "privacy_policy_account_holders_title": "Menuonline számlatulajdonosok",
    "privacy_policy_registration_info": "Regisztrációs információ: Ha regisztrál a szolgáltatások használatára, akkor Menuonline fiókra van szüksége, hogy számlatulajdonossá váljon (\"Számlatulajdonos\"). Amikor regisztrál egy fiókot, meg kell adnia bizonyos alapvető információkat, például nevét, e-mail címét, felhasználónevét, jelszavát, cégnevét, foglalkozását, tartózkodási helyét és telefonszámát. A jelszavak titkosítottak – A Menuonline munkatársai nem tudják megtekinteni vagy lekérni a jelszavakat.",
    "privacy_policy_billing_info": "Számlázási információk: Ha előfizet szolgáltatásainkra, meg kell adnia számunkra fizetési és számlázási információkat.",
    "privacy_policy_cookies_info": "Cookie-k: Cookie-kat használunk a látogatói adatok nyomon követésére, mint például a webhelylátogatások forrása és a webhelyünk látogatásainak száma.",
    "privacy_policy_ip_info": "IP-címek: Az IP-címeket a szolgáltatások igénybevétele során gyűjtjük Önről (pl. országszintű hely és a szolgáltatásaink használata során használt IP-címek rögzítése céljából).",
    "privacy_policy_your_contacts_title": "Az Ön névjegyei",
    "privacy_policy_distribution_list": "Terjesztési lista: Szolgáltatásaink használatának részeként megadhat nekünk, vagy a szolgáltatások részeként gyűjthetünk személyes adatokat az Ön kapcsolattartóiról („Kapcsolattartók”). Ezek az információk magukban foglalják az e-mail címeket, és tartalmazhatnak más azonosítható információkat is, mint például a név, a cégnév vagy más olyan információ, amelyet a Menuonline-nak a szolgáltatások használata során ad meg. Gondoskodnia kell arról, hogy megszerezze kapcsolattartóitól a megfelelő hozzájárulást ahhoz, hogy adataikat feldolgozhassuk.",
    "privacy_policy_billing_info_contacts": "Számlázási információk: Ha előfizet szolgáltatásainkra, meg kell adnia számunkra fizetési és számlázási információkat.",
    "privacy_policy_cookies_contacts": "Cookie-k: A Menuonline csak akkor használ cookie-kat és hasonló nyomkövetési technológiákat az Ön kapcsolataira vonatkozó személyes adatok gyűjtésére és felhasználására, ha Ön hozzáadta a Menuonline követőkódot webhelyéhez. A Menuonline által üzemeltetett összes céloldal automatikusan követőkóddal fog rendelkezni.",
    "privacy_policy_ip_contacts": "IP-címek: A szolgáltatások részeként IP-címeket gyűjtünk az Ön Kapcsolattartóiról (pl. országszintű tartózkodási hely és az űrlap elküldésekor és a hozzájárulás megadásakor használt IP-címek rögzítése céljából).",
    "privacy_policy_info_about_contacts_title": "AZ ÖN KAPCSOLATAIRA VONATKOZÓ INFORMÁCIÓK GYŰJTÉSÜNK",
    "privacy_policy_info_about_contacts_notice": "*Ez a szakasz csak azokra az információkra vonatkozik, amelyeket a Menuonline gyűjtött, használt vagy nyilvánosságra hozott a Számlatulajdonos elérhetőségeiről a szolgáltatások nyújtása céljából*",
    "privacy_policy_info_about_contacts_desc": "A Menuonline csak akkor gyűjt, használ vagy ad ki a Kapcsolattartókkal kapcsolatos személyes adatokat, ha a Számlatulajdonos regisztrált bizonyos szolgáltatásokra, ahol terjesztési listát bocsát rendelkezésünkre. A Számlatulajdonosok kizárólagos felelőssége annak biztosításáért, hogy minden vonatkozó jogszabályt betartsanak, amikor a Menuonline-nak a szolgáltatásokon keresztül adják meg a Kapcsolattartóik személyes adatait.",
    "privacy_policy_info_auto_collect_title": "A Menuonline automatikusan gyűjti az információkat",
    "privacy_policy_info_auto_collect_desc1": "Amikor a Számlatulajdonos Kapcsolattartói részt vesznek egy Számlatulajdonostól kapott e-mail kampányban, vagy interakcióba lépnek a Számlatulajdonos kapcsolódó webhelyére mutató hivatkozással, a Menuonline cookie-k használatával információkat gyűjthet az adott kapcsolattartó eszközéről és az e-mailekkel való interakciójáról. Ezeket az információkat csak a szolgáltatások nyújtása és a Számlatulajdonos számára történő betekintés érdekében gyűjtjük.",
    "privacy_policy_info_auto_collect_desc2": "A Kapcsolattartó eszközéről és azokról az alkalmazásokról gyűjtött információk, amelyeket a Kapcsolattartó a Számlatulajdonostól a szolgáltatásainkon keresztül küldött e-mailek eléréséhez használ, magukban foglalhatják a Kapcsolattartó IP-címét, operációs rendszerét, böngészőazonosítóját, valamint a Kapcsolattartó rendszerével és kapcsolatával kapcsolatos egyéb kapcsolódó információkat.",
    "privacy_policy_info_auto_collect_desc3": "A Kapcsolattartónak a szolgáltatásokon keresztül küldött e-mailekkel való interakciójáról gyűjtött információk közé tartozhatnak azok a dátumok és időpontok, amikor a Contact hozzáfér az e-mailekhez és a böngészési tevékenységekhez, valamint a szolgáltatások teljesítményével kapcsolatos információk, például a Számlatulajdonos által a szolgáltatásokon keresztül a kapcsolattartónak küldött e-mailek kézbesíthetősége és egyéb kommunikáció.",
    "privacy_policy_info_auto_collect_desc4": "Kérjük, vegye figyelembe, hogy a Menuonline nem veszi fel a kapcsolatot az Ön Kapcsolattartóival, kivéve, ha ezt a vonatkozó jogszabályok előírják.",
    "privacy_policy_why_we_collect_title": "MIÉRT GYŰJÜNK AZ ÖN INFORMÁCIÓJÁT",
    "privacy_policy_why_we_collect_desc": "A Menuonline a következő célokra gyűjt információkat:",
    "privacy_policy_why_collect_1": "a Menuonline Számlatulajdonosok számára az általuk előfizetett szolgáltatások biztosítása, beleértve a Számlatulajdonos adatainak a szolgáltatásokhoz való felhasználását;",
    "privacy_policy_why_collect_2": "adminisztrációhoz és számlavezetéshez, valamint a mycircle weboldalához való hozzáféréshez;",
    "privacy_policy_why_collect_3": "ha hozzájárult a marketing és promóciós anyagok fogadásához, hogy tájékoztassa a Menuonline által kínált egyéb termékekről és szolgáltatásokról; és",
    "privacy_policy_why_collect_4": "válaszolni a Számlatulajdonos közléseire vagy elküldeni az Ön által kért információkat. Ez magában foglalhatja, de nem kizárólagosan:",
    "privacy_policy_why_collect_5": "különleges ajánlatok és frissítések;",
    "privacy_policy_why_collect_6": "hírlevél; és",
    "privacy_policy_why_collect_7": "szolgálati közlemények.",
    "privacy_policy_opt_out_desc": "A Menuonline-tól Önnek küldött e-mailek alján található leiratkozás gombra kattintva bármikor leiratkozhat az üzenetek fogadásáról.",
    "privacy_policy_use_disclosure_title": "SZEMÉLYES INFORMÁCIÓK FELHASZNÁLÁSA ÉS NYILVÁNTARTÁSA",
    "privacy_policy_use_disclosure_desc1": "A Menuonline-nak átadott személyes adatokat, beleértve a Menuonline webhelyén vagy az előírt űrlapon keresztül is, szigorúan bizalmasan kezeljük, kivéve, ha a Számlatulajdonos kifejezett hozzájárulásával vagy jogszabályi előírásaival összhangban nyilvánosságra hozzák. A Számlatulajdonosok által bármely „Menuonline” platformon megadott személyes információ minden esetben önkéntes: a Számlatulajdonos által megadott személyes adatokat a Számlatulajdonos belátása szerint adják meg. Felhívjuk figyelmét, hogy a hozzájárulás visszavonása azt eredményezheti, hogy bizonyos Menuonline szolgáltatásokat nem tud majd elérni vagy használni.",
    "privacy_policy_use_disclosure_desc2": "A Menuonline harmadik feleket és külső szolgáltatókat vesz igénybe weboldalával és szolgáltatásaival kapcsolatban. A Számlatulajdonos személyes adatai megoszthatók ilyen harmadik felekkel és szolgáltatókkal. Összesített statisztikákat is biztosíthatunk a Számlatulajdonosokról, az eladásokról, a forgalmi szokásokról és a kapcsolódó információkról jó hírű harmadik feleknek. A Menuonline megköveteli, hogy minden harmadik fél szolgáltató, aki hozzáfér a személyes adatokhoz, legyen kötve a jelen Adatvédelmi szabályzattal összhangban álló kötelezettségekhez, és hogy az ilyen szolgáltatók csak a Menuonline szolgáltatások nyújtása céljából gyűjtsék, használják vagy fedjék fel a Számlatulajdonos személyes adatait.",
    "privacy_policy_use_disclosure_desc3": "A Számlatulajdonosok által megadott személyes adatokat nem adjuk el, osztjuk meg vagy adjuk bérbe külső szervezeteknek, kivéve, ha az Ön hallgatólagos vagy kifejezett hozzájárulásával összhangban áll. A Menuonline fenntartja a jogot, hogy felvegye a kapcsolatot bármely Számlatulajdonossal információ továbbítása, kérdések megválaszolása vagy programunk vagy szabályzatunk változásairól szóló értesítések érdekében. Felhívjuk figyelmét, hogy a Menuonline nem veszi fel a kapcsolatot az Ön Kapcsolattartóival, kivéve, ha a vonatkozó törvény ezt megköveteli.",
    "privacy_policy_use_disclosure_desc4": "A Menuonline csak abban az esetben ad ki személyes adatokat, ha ehhez az érintett személy hozzájárul, vagy:",
    "privacy_policy_disclosure_list_1": "ha azt törvény megengedi vagy megköveteli, például idézés vagy más jogi eljárás esetén;",
    "privacy_policy_disclosure_list_2": "a Menuonline egészének vagy egy részének átvevőjének;",
    "privacy_policy_disclosure_list_3": "a Menuonline szolgáltatóihoz a jelen Szabályzat szerint; vagy",
    "privacy_policy_disclosure_list_4": "jogi kötelezettségek teljesítése érdekében, beleértve, de nem kizárólagosan a hatósági jelentéstételi kötelezettségeket.",
    "privacy_policy_service_providers_title": "A Menuonline a következő harmadik feleket és szolgáltatókat használja (az Ön Kapcsolattartóinak személyes adatait nem adják ki \"ilyen\" harmadik feleknek):",
    "privacy_policy_service_provider_1": "A ManyChat szolgáltatást arra használjuk, hogy webhelyen csevegési szolgáltatásokat nyújtsunk értékesítési és támogatási célokra, valamint e-maileket küldjünk ezekről a beszélgetésekről.",
    "privacy_policy_service_provider_2": "Az AskNicely-t használjuk ügyfeleink felmérésére annak érdekében, hogy megkérdezzük az általuk kapott szolgáltatás és támogatás minőségét.",
    "privacy_policy_service_provider_3": "A join.me-t használjuk az ügyfelekkel és leendő ügyfelekkel való találkozók, támogatási hívások és szoftverbemutatók ütemezésére.",
    "privacy_policy_service_provider_4": "A Google Analytics segítségével nyomon követjük az anonim weboldalak tevékenységét, és mérjük a webhelyünk használati szokásait.",
    "privacy_policy_breach_notification_title": "ÉRTESÍTÉS SZABÁLYOZÁSSAL",
    "privacy_policy_breach_notification_desc": "A Menuonline köteles megfelelni a vonatkozó jogszabályoknak, és ésszerű segítséget kell nyújtania a Számlatulajdonosoknak a személyes adatok jogosulatlan felhasználása, hozzáférése vagy nyilvánosságra hozatala tekintetében.",
    "privacy_policy_rights_access_title": "AZ ÖN JOGAI ÉS HOZZÁFÉRÉSE",
    "privacy_policy_rights_access_desc": "Önnek joga van hozzáférni a Menuonline által Önről feldolgozott személyes adatokhoz. Információt kérhet tőlünk:",
    "privacy_policy_rights_access_list_1": "Az Önről tárolt személyes adatok",
    "privacy_policy_rights_access_list_2": "Az érintett személyes adatok kategóriái",
    "privacy_policy_rights_access_list_3": "Az adatkezelés céljai",
    "privacy_policy_rights_access_list_4": "Részletek, hogy az Ön személyes adatait kinek adják\/hozzák nyilvánosságra",
    "privacy_policy_rights_access_list_5": "Mennyi ideig őrizzük meg személyes adatait",
    "privacy_policy_rights_access_list_6": "Ha nem közvetlenül Öntől gyűjtöttük az információkat, akkor a forrásra vonatkozó információkat",
    "privacy_policy_rights_access_list_7": "Hogyan lehet panaszt benyújtani a megfelelő felügyeleti hatósághoz",
    "privacy_policy_rights_access_list_8": "Az alábbiakat is kérheti tőlünk:",
    "privacy_policy_rights_access_list_9": "Frissítjük az Önnel kapcsolatos hiányos vagy pontatlan információkat",
    "privacy_policy_rights_access_list_10": "Kérje, hogy töröljük személyes adatait a vonatkozó jogszabályoknak megfelelően",
    "privacy_policy_rights_access_contact": "Kérheti tőlünk jogai érvényesítését: vegye fel velünk a kapcsolatot a 2851 Dufferin Street, Toronto, ON, Kanada címen, vagy írjon nekünk e-mailt a {link} címre.",
    "privacy_policy_accountability_title": "ELszámoltathatóság",
    "privacy_policy_accountability_desc": "A Menuonline elkötelezett amellett, hogy elszámoltatható legyen az Ön által számunkra közölt személyes és bizalmas információkért. Az elérhetőségeket lásd a jelen szabályzat végén.",
    "privacy_policy_links_other_sites_title": "LINKEK MÁS OLDALRA",
    "privacy_policy_links_other_sites_desc1": "A Menuonline webhelye más oldalakra mutató hivatkozásokat tartalmazhat. Kérjük, vegye figyelembe, hogy a Menuonline nem vállal felelősséget más oldalak adatvédelmi gyakorlatáért. Arra biztatjuk Számlatulajdonosainkat, hogy legyenek tudatában annak, hogy amikor elhagyják oldalunkat, alaposan olvassák el minden személyes azonosításra alkalmas adatokat gyűjtő webhely magánpolitikai nyilatkozatát.",
    "privacy_policy_links_other_sites_desc2": "Ez az adatvédelmi szabályzat kizárólag az általunk gyűjtött információkra vonatkozik.",
    "privacy_policy_links_other_sites_desc3": "Ha egy hivatkozást használ a Menuonline webhelyről termékek vagy szolgáltatások vásárlásához, akkor vásárlási tranzakciót hajt végre a szállító cég szabályai és előírásai szerint, nem a Menuonline.",
    "privacy_policy_cookies_title": "MAGÁNSZABÁLYZAT COOKIE-K",
    "privacy_policy_cookies_desc1": "A Menuonline weboldal és szolgáltatásai cookie-kat használnak bizonyos információk gyűjtésére és tárolására a jelen szabályzatnak megfelelően. A „cookie” egy webhelyről küldött kis szöveges fájl, amelyet a felhasználó webböngészője tárol a felhasználó számítógépén. A cookie-kat használó webhely meglátogatásakor egy cookie töltődik le számítógépére vagy mobileszközére. Amikor legközelebb meglátogatja ezt a webhelyet, készüléke megjegyzi a hasznos információkat, például a beállításokat, a meglátogatott oldalakat vagy a bejelentkezési lehetőségeket.",
    "privacy_policy_cookies_desc2": "A sütiket széles körben használják a webhelyek funkcionalitásának bővítésére vagy a hatékonyabb működés biztosítására. Oldalunk cookie-kat használ a felhasználói élmény optimalizálása és az oldal szolgáltatásainak megfelelő működése érdekében.",
    "privacy_policy_cookies_desc3": "A legtöbb webböngésző bizonyos szabályozást tesz lehetővé a cookie-k korlátozására vagy blokkolására a böngésző beállításain keresztül, azonban a cookie-k letiltása azt tapasztalhatja, hogy ez befolyásolja webhelyünk vagy szolgáltatásaink bizonyos részei használatának lehetőségét.",
    "privacy_policy_cookies_analytical_purpose": "<strong> Elemzési célok: <\/strong> Cookie-kat használunk a számlatulajdonosok tevékenységének elemzésére webhelyünk fejlesztése érdekében. Például cookie-kat használhatunk az összesített minták megtekintésére, például a számlatulajdonosok által használt funkciókra. Az ilyen elemzések segítségével betekintést nyerhetünk webhelyünk funkcionalitásának és felhasználói élményének javításába.",
    "privacy_policy_cookies_preferences_user_experience": "<strong> Az Ön beállításai és felhasználói élménye: <\/strong> Cookie-kat használunk, hogy bizonyos információkat gyűjtsünk a látogatókról és a számlatulajdonosokról, például a böngésző típusát, a szervert, a nyelvi preferenciát és az országbeállítást, hogy a Számlatulajdonos beállításait eltároljuk webhelyünkön, hogy a fióktulajdonosok élményét egységesebbé és kényelmesebbé tegyük. Cookie-kat használunk, hogy fenntartsuk a bejelentkezett állapotát, amikor ismételten meglátogatja webhelyünket.",
    "privacy_policy_cookies_targeted_ads": "<strong> Mérési szolgáltatások, jobban célzott hirdetések és marketing: <\/strong> Cookie-kat, webjelzőket és egyéb tárolótechnológiákat használunk külső partnerektől, például a Google-tól és a Facebooktól mérési szolgáltatásokhoz, hirdetések jobb célzásához és marketing célokra. Ez akkor történik meg, amikor meglátogatja weboldalunkat. Ezek a cookie-k, webjelzők és egyéb tárolási technológiák lehetővé teszik számunkra, hogy a Menuonline promóciós anyagokat megjelenítsük az Ön által az interneten meglátogatott más webhelyeken.",
    "privacy_policy_security_title": "BIZTONSÁG",
    "privacy_policy_security_desc1": "A Menuonline erőfeszítéseket tesz minden típusú bizalmas információ védelmére, beleértve a személyes adatokat is, az információ érzékenységének megfelelő szervezeti, fizikai, mechanikai és elektronikus biztosítékokkal. Szervereink Kanadában találhatók, és követik a biztonsági ipari szabványokat.",
    "privacy_policy_security_desc2": "Felhívjuk azonban figyelmét, hogy nem tudjuk garantálni, hogy az általunk alkalmazott intézkedések garantálják az információk biztonságát.",
    "privacy_policy_retention_title": "VISSZATARTÁS",
    "privacy_policy_retention_desc": "A Számlatulajdonosok és Kapcsolattartók személyes adatait addig őrizzük meg, ameddig a jelen Adatvédelmi szabályzatban meghatározott célokhoz és a Felhasználási feltételek megőrzési rendelkezései szerint szükséges.",
    "privacy_policy_access_questions_title": "HOZZÁFÉRÉS, KÉRDÉSEK ÉS Aggályok",
    "privacy_policy_access_questions_desc": "Az a személy, aki személyes adatokat adott meg a Menuonline-nak, írásban kérheti a Menuonline által megőrzött személyes adataihoz való hozzáférést vagy azok javítását.",
    "terms_obj": {
        "terms_conditions_title": "FELTÉTELEK",
        "terms_conditions_subtitle": "Általános Szerződési Feltételek új és meglévő ügyfelek számára",
        "terms_conditions_desc1": "AZ ALÁBBI FONTOS PONTOK (BEÉVEZETETT A RÉSZRE VONATKOZÓKAT is) KIZÁRÓLAG AZ ÖN KÉNYELMÉÉRT BIZTOSÍTOK, ÉS NEM HELYETTIK A TELJES SZERZŐDÉSI FELTÉTELEKET. AZ ÖN FELELŐSSÉGE, HOGY A Menuonline SZOLGÁLTATÁS HASZNÁLATA ELŐTT OLVASSA EL EZEKET A FELTÉTELEKET.",
        "terms_conditions_desc2": "A Menuonline használata azt jelenti, hogy Ön elfogadja az Általános Szerződési Feltételeket, és kijelenti és szavatolja, hogy erre a szervezete vagy vállalata felhatalmazta magát. (Lásd a 3. szakaszt „Hogyan működik”)",
        "terms_conditions_desc3": "A Menuonline egy engedélyalapú marketingszolgáltatás. A „spam” szándékos küldése vagy az Általános Szerződési Feltételek megsértése fiókja bezárását vonja maga után. (Lásd a 16. szakaszt „A szolgáltatás elfogadható használata”)",
        "terms_conditions_desc4": "A Menuonline-on tárolt adatok, például kapcsolatfelvételi adatok, e-mailek tartalma és a Menuonline-on keresztül gyűjtött információk az Öné. (Lásd a 14. szakaszt „Mi a tulajdonod?”).",
        "terms_conditions_desc5": "A Menuonline szoftver és a weboldalunkon található tartalom a miénk. (Lásd a 13. szakaszt „Mi a tulajdonunk?”)",
        "terms_conditions_desc6": "A Menuonline-on tárolt adatokat vagy közzé nem tett tartalmakat a saját felhasználóin kívül csak a Menuonline munkatársai és partnerei tekinthetik meg, akik aláírták a titoktartási megállapodást. (Lásd a 14. szakaszt „Mi a tulajdonod?”).",
        "terms_conditions_desc7": "Minden személyazonosításra alkalmas adatot (PII) csak Kanadában található szervereken tárolunk. (Lásd a 14. szakaszt „Mi a tulajdonod?”).",
        "terms_conditions_desc8": "A Menuonline szolgáltatásra Kanada és Ontario tartomány törvényei vonatkoznak. (Lásd: 24. szakasz „Irányadó jog”)",
        "terms_conditions_desc9": "Az Ön adataira vagy tartalmára vonatkozó, Kanadán belüli vagy Kanadán kívüli személyek, rendőrség vagy más szabályozó ügynökségek által benyújtott kéréseket mindig Önhöz irányítjuk. Az egyetlen kivétel az, ha ezzel megsértjük a kanadai vagy ontariói törvényeket; mindazonáltal minden esetben keményen ellenállunk, hogy adatait Önön kívül bárki kiadja. (Lásd a 14. szakaszt „Mi a tulajdonod?”).",
        "terms_conditions_desc10": "A Menuonline biztonság csak annyira jó, mint a felhasználónevek és jelszavak kezelése. A Menuonline felhasználói fiókokért nem számítunk fel díjat, ezért minden felhasználó számára hozzon létre külön fiókot, és tartsa titokban a jelszavakat. Ön felelős a fiókjáért. (Lásd a 3. szakaszt „Hogyan működik”)",
        "terms_conditions_desc11": "Fiókját bármikor megszüntetheti. Lemondás esetén a fel nem használt hitelt nem térítjük vissza. (Lásd a 3. szakaszt „Hogyan működik”)",
        "terms_conditions_desc12": "Fiókját bármikor törölhetjük. Visszatérítést biztosítunk az e-mail jóváírásokért és a fel nem használt szolgáltatásokért, ha megszüntetjük a fiókját. (Lásd a 3. szakaszt „Hogyan működik”)",
        "terms_conditions_desc13": "Adatai a törlést követően törlésre kerülnek, és akkor is törölhetők, ha a fiók felhasználója legalább 12 hónapig nem jelentkezett be. A fiókjából olyan összesített adatokat fogunk kezelni, amelyek nem tartalmaznak személyes azonosításra alkalmas adatokat statisztikai célból. (Lásd a 3. szakaszt „Hogyan működik”)",
        "terms_conditions_desc14": "Az e-mail jóváírásokat felosztó-kirovó alapon vásárolják meg, és soha nem járnak le. Az egyetlen kivétel az, ha 12 hónapja nem jelentkezett be fiókjába. Ebben az esetben fenntartjuk a jogot, hogy töröljük fiókját, adatait és a kapcsolódó jóváírásokat. (Lásd a 10. szakaszt „Mit kell fizetni a szolgáltatásért?”)",
        "terms_conditions_desc15": "Keményen dolgozunk annak érdekében, hogy a Menuonline folyamatosan működjön, és előzetesen értesítsük, ha karbantartás miatt átmenetileg fel kell függesztenünk a szolgáltatást. Mindazonáltal nem garantáljuk, hogy a szolgáltatás folyamatosan üzemel, és nem vállalunk felelősséget a Menuonline használatából eredő veszteségekért, beleértve azokat a veszteségeket sem, amelyek az Ön részéről jelentkeznek, ha a rendszer nem fut akkor, amikor szüksége van rá. (Lásd a 17. szakaszt, „Felelősségi nyilatkozatok”).",
        "terms_conditions_desc16": "Nem figyeljük a tartalmát vagy azt, hogy kinek küld üzenetet. Mindazonáltal figyelemmel kísérjük a panaszokat és egyéb, az Ön tevékenysége által okozott problémákat. Túl sok panasz vagy probléma azt eredményezheti, hogy saját belátásunk szerint töröljük szolgáltatását. (Lásd a 7. szakaszt „Monitoring”)",
        "terms_conditions_desc17": "Elkötelezettek vagyunk amellett, hogy kiváló terméktámogatást nyújtsunk, hogy segítsünk önellátóvá válni a Menuonline segítségével. Támogatást nyújtunk dokumentáció és videó formájában honlapunkon, a Menuonline támogatási jegyrendszerben és telefonon. A terméktámogatásért nem kell fizetni; azonban fenntartjuk a jogot arra, hogy saját belátásunk szerint megszüntessük bizonyos ügyfélfiókok támogatását. (Lásd 5. szakasz „Támogatás”)",
        "terms_conditions_desc18": "A Termékszolgáltatások azokra az időszakokra szólnak, amikor azt szeretné, hogy a Menuonline-nal kapcsolatos munkát végezzünk el, nem pedig saját maga. Termékszolgáltatásaink díja óránként 135 USD. Magasabb áron nyújtunk tanácsadást is. Fenntartjuk a jogot, hogy árainkat bármikor megváltoztassuk. (Lásd a 6. szakaszt „Termékek és tanácsadási szolgáltatások”)",
        "terms_conditions_desc19": "Tájékoztatjuk Önt, ha úgy véljük, hogy adatait megsértették, és igyekszünk megadni a kapcsolódó részleteket és információkat. (Lásd a 14. szakaszt „Mi a tulajdonod?”)",
        "terms_conditions_desc20": "Fenntartjuk a jogot, hogy ezt a megállapodást bármikor módosítsuk. Megpróbáljuk tájékoztatni Önt a feltételek változásairól, de a szolgáltatás használata azt jelenti, hogy Ön elfogadja a Feltételek változásait. (Lásd a 9. szakaszt „Változások”).",
        "terms_conditions_desc21": "Az Általános Szerződési Feltételek és az Adatvédelmi szabályzatunk csak angol nyelven készült, és Ön elfogadja, hogy az Általános Szerződési Feltételek és az Adatvédelmi szabályzat angol nyelvű változata élvez elsőbbséget a lefordított változattal való ütközés esetén.",
        "terms_conditions_mycircle": "A MYCIRCLE SZERZŐDÉSI FELTÉTELEI",
        "terms_conditions_last_update": "UTOLSÓ FRISSÍTÉS: 2020. január 14",
        "terms_conditions_usage_agreement": "Kérjük, figyelmesen olvassa el ezeket a szolgáltatási feltételeket (a „Feltételek”), mielőtt használná ezt a webhelyet (a „Webhely”). Ezek a feltételek mentesítik a Menuonline-t (\"Menuonline\", \"mi\" vagy \"mi\") és másokat a felelősség alól, és\/vagy korlátozzák a mi és az ő felelősségüket, és további fontos rendelkezéseket tartalmaznak, amelyek az oldal Ön általi használatára vonatkoznak.",
        "terms_conditions_acceptance": "A Webhely vagy a Szolgáltatás meglátogatásával vagy használatával Ön a saját nevében és minden olyan szervezet nevében, amelynek nevében eljár (a továbbiakban együttesen „Ön”), beleegyezik abba, hogy elfogadja és betartja a jelen Feltételeket a webhely minden egyes használatára és minden látogatására vonatkozóan.",
        "terms_conditions_services": "1. Szolgáltatásaink.",
        "terms_conditions_services_desc": "A webhelyen keresztül kínált szolgáltatások olyan szolgáltatások, amelyek lehetővé teszik e-mail üzenetek („E-mailek”) létrehozását, küldését és kezelését az Ön által választott címzetteknek (a „Szolgáltatás”).",
        "terms_conditions_license": "2. Engedély.",
        "terms_conditions_license_desc": "A jelen Feltételek értelmében a Menuonline ezennel nem kizárólagos, korlátozott, át nem ruházható licencet biztosít Önnek az Időtartam alatt a Webhely megtekintésére és a Szolgáltatás használatára a Szolgáltatás funkciói által megengedett módon. Fenntartunk minden olyan jogot, amelyet itt kifejezetten nem biztosítunk a Szolgáltatásban és a Menüonline Tartalomban (az alábbiakban meghatározottak szerint).",
        "terms_conditions_account": "3. Hogyan működik.",
        "terms_conditions_account_desc": "A Szolgáltatás használatához fiókot kell regisztrálnia. Fiókja ingyenes. Fiókja hozzáférést biztosít a Szolgáltatáshoz és a „funkciókhoz”, amelyeket időről időre létrehozhatunk és fenntarthatunk. Ahhoz, hogy jogosult legyen fiókra, a következőket kell tennie:",
        "terms_conditions_account_requirements": [
            "legalább tizennyolc (18) évesnek kell lennie, és képes lesz szerződést kötni;",
            "fejezze be az általunk meghatározott regisztrációs folyamatot;",
            "elfogadja ezeket a feltételeket;",
            "valós, teljes és naprakész kapcsolattartási és számlázási\/fizetési információkat adjon meg."
        ],
        "responsibility": "Felelősség.",
        "responsibilityText": "Kizárólag Ön felelős a fiókjában végzett tevékenységekért, és fiókja jelszavát bizalmasan és biztonságosan kell kezelnie. A fiókok ingyenesek, ezért azt javasoljuk, hogy minden egyes felhasználó számára hozzon létre egy másik fiókot. Ön felelős minden olyan fiókért is, amelyhez hozzáféréssel rendelkezik, függetlenül attól, hogy engedélyezte-e a fiók használatát. Ön elfogadja, hogy kizárólagos felelősséggel tartozik azokkal az interakcióiért, akiknek e-maileket küld, és csak azoknak küld e-maileket, akiktől ehhez hozzájárult. Azonnal értesítenie kell minket fiókjainak bármilyen jogosulatlan használatáról.",
        "compliance_with_CASL": "Megfelelés a kanadai spamellenes jogszabályoknak (CASL).",
        "compliance_with_CASL_text": "A Menuonline szerepe a hozzájárulási beállítások kezelésének megkönnyítése, nem pedig a jogszabályok betartatása. Az Ön felelőssége, hogy megfeleljen a CASL-nek. Segítünk Önnek az alapértelmezett funkciók beállításával, valamint a dokumentált kifejezett hozzájárulás megszerzésének és frissítésének megkönnyítésével, valamint a hallgatólagos hozzájárulás lejárati dátumainak kezelésével. Ön teljes mértékben kézben tartja az adatbázisához tartozó hozzájárulás kezelését; bármikor tömegesen frissítheti az összes ismerőse hozzájárulási állapotát, felülbírálhatja az alapértelmezett beállításokat, és letilthatja a beleegyezési állapot automatikus lejártát.",
        "closing_account": "Fiókjának bezárása.",
        "closing_account_text": "Ön vagy a Menuonline bármikor és bármilyen okból felmondhatja a jelen Szerződést a másik fél értesítésével. Ha olyan információkat ad meg, amelyek valótlanok, pontatlanok, nem aktuálisak vagy hiányosak, vagy olyan módon használja a Szolgáltatást, amely sérti a jelen Általános Szerződési Feltételeket, jogunkban áll haladéktalanul felfüggeszteni fiókját és megszüntetni a Szolgáltatáshoz való hozzáférését a rendelkezésünkre álló egyéb jogorvoslati lehetőségek mellett. Ha tizenkét (12) hónapig nem használja fiókját, törölhetjük fiókját. Ha bármilyen okból megszüntetjük a fiókját, visszatérítjük a fennmaradó jóváírásait. A megszüntetést követően véglegesen töröljük fiókját és a hozzá kapcsolódó összes adatot, beleértve az Ön által küldött e-maileket is. A fiókjából olyan összesített adatokat tárolhatunk, amelyek nem tartalmaznak személyazonosításra alkalmas adatokat statisztikai célból.",
        "emailing_content": "Tartalom küldése e-mailben.",
        "emailing_content_text": "Amikor ezen az oldalon vagy a Szolgáltatáson keresztül kapcsolatba lép a Menuonline-nal, Ön vállalja, hogy valós és pontos információkat ad. Ezenkívül tisztában van azzal, hogy a bizalmas információk e-mailben történő megadása nem biztonságos gyakorlat. Ön tudomásul veszi, hogy kizárólag Ön felelős mindenért, amit a Szolgáltatásunkat használó bárkinek elküld. célokra.",
        "support": "Támogatás.",
        "support_text": "Támogatást nyújtunk Önnek, hogy önellátóvá váljon a Szolgáltatással. Támogatást nyújtunk Önnek dokumentáció és videó formájában az Oldalon, a Menuonline támogatási jegyrendszerben és telefonon. Az oldal tetején található „Támogatás” fülre kattintva érhet el minket. A terméktámogatásért nem kell fizetni; azonban fenntartjuk a jogot, hogy saját belátásunk szerint megszüntessük bizonyos ügyfélfiókok támogatását.",
        "product_and_consulting_services": "Termék- és tanácsadási szolgáltatások.",
        "product_and_consulting_services_text": "A Termékszolgáltatások azokra az időszakokra szólnak, amikor azt szeretné, hogy a Menuonline-nal kapcsolatos munkát végezzünk el, nem pedig saját maga. Termékszolgáltatásaink díja óránként 125 USD. Magasabb áron nyújtunk tanácsadást is. Fenntartjuk a jogot, hogy árainkat bármikor megváltoztassuk.",
        "monitoring": "Monitoring.",
        "monitoring_text": "Nem figyeljük és nem ítéljük meg a Szolgáltatáson keresztül továbbított információk tartalmát, de kivizsgáljuk, ha panasz érkezik az esetleges nem megfelelő felhasználásra vonatkozóan. A Menuonline bármikor és saját belátása szerint dönthet arról, hogy bizonyos felhasználások megfelelőek vagy nem megfelelőek, előzetes értesítéssel vagy anélkül, az alábbi irányelvek szerint.",
        "term": "Term.",
        "term_text": "Az időtartam akkor kezdődik, amikor Ön fiókot regisztrál nálunk, és mindaddig tart, amíg a Szolgáltatást használja. Ön kijelenti és szavatolja, hogy rendelkezik felhatalmazással és képességgel, hogy fiókot regisztráljon számunkra (beleértve azt is, ha egy szervezet nevében regisztrál).",
        "changes": "Változások.",
        "changes_text": "Az időtartam akkor kezdődik, amikor Ön fiókot regisztrál nálunk, és mindaddig tart, amíg a Szolgáltatást használja. Ön kijelenti és szavatolja, hogy rendelkezik felhatalmazással és képességgel, hogy fiókot regisztráljon számunkra (beleértve azt is, ha egy szervezet nevében regisztrál).",
        "payment": "FIZETÉS",
        "what_do_i_pay_for_service": "Mit kell fizetni a szolgáltatásért?.",
        "what_do_i_pay_for_service_text": "Előre kifizetett jóváírásokat vásárolhat, hogy e-mailt küldjön (\"Jóváírások\") a Szolgáltatásunkon keresztül, amint azt a Webhelyünk \"árazás\" oldalán részletesen leírjuk. A kreditek soha nem járnak le, és ezekért a kreditekért nincs visszatérő díj. Az egyetlen kivétel az, hogy ha tizenkét (12) hónapig nem használja fiókját, törölhetjük fiókját és a fel nem használt krediteket. Árainkat bármikor módosíthatjuk a Webhelyünk árképzési oldalainak frissítésével, amely a következő kreditvásárlásra vonatkozik.",
        "how_do_i_pay_for_service": "Hogyan fizethetek a Szolgáltatásért?.",
        "how_do_i_pay_for_service_text": "Bármilyen nagyobb hitelkártyával fizethet kreditet. Az általunk elfogadott fizetési módokat bármikor módosíthatjuk.",
        "can_i_get_refund": "Kaphatok visszatérítést?.",
        "can_i_get_refund_text": "A Szolgáltatás használatáért kifizetett összegek nem téríthetők vissza, ha megszünteti fiókját. Ha megszüntetjük a fiókját, visszatérítjük a fennmaradó jóváírásait.",
        "rights": "JOGOK",
        "what_do_we_own": "Mit birtokolunk?.",
        "what_do_we_own_text": "Az Ön által számunkra biztosított tartalom kivételével (beleértve, korlátozás nélkül a kapcsolatfelvételi rekordokat, az e-mail tartalmakat és a Szolgáltatáson keresztül gyűjtött információkat) („Ön Tartalom”), a Webhelyünkön található összes anyag, a Szolgáltatás és az ott található vagy az általa továbbított összes anyag, ideértve korlátozás nélkül a szoftvereket, képeket, szövegeket, grafikákat, illusztrációkat, logókat, szabadalmakat, védjegyeket, szolgáltatási védjegyeket, fényképeket, egyéb felhasználói tartalmakat, hanganyagokat, Tartalom“) (és a hozzá kapcsolódó összes szellemi tulajdonjog) a mi vagy licencadóink tulajdonában van.",
        "what_do_you_own": "Mit birtokol?.",
        "what_do_you_own_text": "Nem tartunk igényt tulajdonjogra az Ön Tartalmára vonatkozóan. Az Ön Tartalma az Öné, és Ön fenntartja a Szolgáltatásba feltöltött Tartalma tulajdonjogát. Ha megosztja Tartalmát a Szolgáltatáson keresztül, Ön beleegyezik abba, hogy az Ön beállításaival és jelen Általános Szerződési Feltételeivel összhangban másoknak is megtekintheti, szerkesztheti és\/vagy megoszthatja Tartalmát. Ön kijelenti és szavatolja, hogy az Ön e-mailjeiben használt összes anyag tulajdonosa, vagy engedélye van arra. Az Ön anyagait csak a jelen Feltételekben és az Adatvédelmi szabályzatunkban leírtak szerint használhatjuk fel vagy fedhetjük fel.",
        "privacy_statement": "A Menuonline tiszteletben tartja felhasználóinak magánéletét. A Menuonline ezen a Webhelyen vagy a Szolgáltatáson keresztül gyűjtött személyes adataira a Menuonline Adatvédelmi nyilatkozat vonatkozik, amely hivatkozásként szerepel a jelen Feltételekben. Az Ön adatait az Adatvédelmi nyilatkozatunknak megfelelően felhasználhatjuk és kiadhatjuk.",
        "access_to_your_content": "A Menuonline senkinek nem biztosít hozzáférést az Ön Tartalmához, kivéve a Menuonline azon alkalmazottait és alvállalkozóit, akik megfelelő titoktartási megállapodást kötöttek velünk, vagy mi az Ön hozzájárulásával.",
        "governmentRequest": "Ha egy kormánytól vagy szabályozó hatóságtól kérést kapunk az Ön Tartalmához való hozzáférésre vonatkozóan, értesíteni fogjuk a kérésről, és kikérjük az Ön hozzájárulását, mielőtt az Ön Tartalmát kiadnánk. Ellenállunk minden, az Ön Tartalmára vonatkozó kérésnek, és nem adjuk ki az Ön Tartalmát az ilyen típusú kérésekre válaszul az Ön engedélye nélkül, illetve az illetékes bíróság vagy az illetékes szabályozó testület előírása nélkül.",
        "breachNotification": "Abban az esetben, ha tudomásunkra jut, hogy az Ön Tartalmát jogosulatlan hozzáférésnek, felhasználásnak, másolásnak, ellopásnak, elvesztésnek, vagy a jelen Feltételek megsértésével történő felhasználásnak vagy nyilvánosságra hozatalnak vetették alá (\"Sértés\"), haladéktalanul értesítjük Önt, és tájékoztatjuk a történtekről. Ön beleegyezik abba, hogy a jogsértéseket a vonatkozó jogszabályoknak megfelelően kezeljük, és ha szükségünk van arra, hogy tájékoztassa az e-mail terjesztési listáinak tagjait, akkor beleegyezik, hogy ezt haladéktalanul és az általunk előírt módon megteszi.",
        "contentRetention": "Megőrizzük, használjuk és továbbítjuk az Ön Tartalmát Kanadában, kivéve az Ön előzetes írásos jóváhagyásával vagy utasításával (például ha úgy dönt, hogy Kanadán kívüli személyeknek küldi el Tartalmát). A Szolgáltatás nyújtásához használt szerverek Kanadában találhatók.",
        "accessTrouble": "Ha problémái vannak a Tartalmához való hozzáféréssel, a Menuonline segít Önnek a Tartalma időbeli és anyagi alapon történő lekérésében.",
        "reportingAbuse": "Ha úgy gondolja, hogy valaki megsérti a jelen Feltételek bármelyikét, kérjük, azonnal értesítsen bennünket. Ha Ön úgy gondolja, hogy spameket kapott a Szolgáltatás egy másik felhasználójától, ha úgy gondolja, hogy a Szolgáltatás egy másik felhasználója olyan anyagot tett közzé, amely sérti a szerzői jogokat, vagy ha úgy gondolja, hogy a Szolgáltatás egy másik felhasználója egyébként megsérti a jelen Feltételeket, kérjük, lépjen kapcsolatba velünk a Support@menuonline.com címen.",
        "acceptableUse": "Ön kijelenti és szavatolja, hogy a Szolgáltatás használata megfelel az összes vonatkozó törvénynek és szabályozásnak. Ön elfogadja, hogy Ön felelős annak megállapításáért, hogy a Szolgáltatás megfelel-e és alkalmas-e az Ön joghatósága szerinti használatra. A nagyobb biztonság érdekében Ön beleegyezik abba, hogy köteles (és vállalja annak biztosítását, hogy alkalmazottai, ügynökei és felhatalmazott felhasználói) ...",
        "exportLaws": "Nem vállalunk kijelentést arról, hogy a Webhely vagy a Szolgáltatás Kanadán kívüli használatra megfelelő vagy elérhető, és tilos hozzáférni olyan területekről, ahol tartalmuk illegális. Nem használhatja, exportálhatja vagy újraexportálhatja a Menuonline tartalmat vagy az ezen az oldalon található egyéb anyagokat, vagy bármely másolatot vagy adaptációt, amely megsérti a vonatkozó törvényeket vagy rendelkezéseket, ideértve korlátozás nélkül a kanadai és az Egyesült Államok exporttörvényeit és szabályozásait.",
        "disclaimers": "A szolgáltatást és a jelen webhelyet „ahogyan” biztosítják, mindenféle nyilatkozat, jótállás vagy feltétel nélkül, és a Menuonline a vonatkozó törvények által megengedett teljes mértékben elzárkózik minden nyilatkozattól, szavatosságtól és feltételtől, akár kifejezett, akár hallgatólagos, a törvény által vagy más módon, beleértve korlátozás nélkül a hallgatólagos garanciákat és feltételeket, amelyek a harmadik félnek a kereskedésre, nem vagy sértésére való felhasználásra vonatkoznak. jogok...",
        "limitLiability": "A jelen Szerződés értelmében a Menuonline összesített felelőssége, akár szerződésből, akár jogsértésből eredően (beleértve a gondatlanságot is), a garancia megsértéséből, az objektív felelősségből vagy bármely más felelősségelméletből eredően, a közvetlen károkra korlátozódik, amelyek összege nem haladja meg a Menuonline-nak a jelen Feltételek alapján az eseményt közvetlenül megelőző tizenkét (12) hónapban kifizetett díjakat.",
        "liabilityExclusions": "A Menuonline, annak leányvállalatai vagy megfelelő igazgatóik, tisztségviselőik, alkalmazottak, ügynökeik vagy egyéb képviselőik (együttesen „Menuonline „Képviselők”), illetve a kapcsolódó hálózatok üzemeltetői nem vállalnak felelősséget vagy felelősséget ezzel a Webhellyel vagy a Szolgáltatással kapcsolatban: (i) bármilyen közvetett, következményi, véletlenszerű, büntető jellegű vagy különleges kárért; vagy (ii) bármilyen közvetlen, közvetett, következményes, járulékos, példaértékű, büntető vagy különleges kárért, amelyet bevételkiesésként, kiesett megtakarítások vagy bevételek vagy elmaradt haszonként jellemeznek...",
        "indemnity": "Ön kártalanítja és mentesíti a Menuonline-t és\/vagy a Menuonline Képviselőit (a „Károsított Menuonline felek”) a harmadik felek által előterjesztett bármely követeléstől, valamint a velünk szemben kiszabott pénzbírságtól vagy szankciótól, amely abból ered, hogy Ön az e Webhelyről elért információkat vagy a Szolgáltatás igénybevételét, illetve a jelen Feltételek Ön általi felhasználását…",
        "ipInfringement": "A Menuonline megvédi, kártalanítja és védi Önt minden olyan követeléstől, követeléstől, keresettől, keresettől vagy eljárástól, amelyet egy harmadik fél támaszt Ön ellen, és azt állítja, hogy a Szolgáltatáshoz való hozzáférése vagy annak használata a jelen Szerződéssel összhangban sérti egy harmadik fél kanadai törvényei szerinti szellemi tulajdonjogait (\"IP-sértési követelés\")...",
        "equitableRelief": "Ha megsérti ezeket a Feltételeket, akkor jogorvoslatot kérhetünk (azaz bírósági végzést kérhetünk az Ön megállítására) vagy más méltányos jogorvoslatért. Ön beleegyezik, hogy ez kiegészíti és nem sérti a törvényből vagy a méltányosságból fakadó egyéb jogainkat.",
        "linksToOtherSites": "Ha harmadik felek webhelyeire mutató hivatkozásokat adunk meg, akkor azokat csak a kényelem érdekében közöljük. Ha ezeket a linkeket használja, akkor elhagyja Webhelyünket. Nem ellenőrizzük ezeket a harmadik felek webhelyeit, és nem támogatjuk őket. Ön elfogadja, hogy nem vállalunk felelősséget ezen harmadik felek webhelyeiért vagy azok tartalmáért...",
        "thirdPartyRequests": "Ha az Ön fiókjával vagy a Szolgáltatás használatával kapcsolatos jogi vagy kormányzati kérésre válaszolva információkat kell megadnunk, akkor Ön beleegyezik, hogy költségeinket felszámíthatjuk. Ezek a díjak magukban foglalhatják ügyvédi díjainkat és a megkeresésre adott válasz elkészítésével töltött idő költségeit.",
        "assignment": "Ön nem ruházhatja át vagy ruházhatja át a jelen Megállapodást, valamint az abban biztosított jogokat és licenceket, de a Menuonline korlátozás nélkül átruházhatja azokat, az Ön ésszerű előzetes értesítése alapján.",
        "governingLaw": "Ezekre a feltételekre az összes vonatkozó szövetségi törvény és Ontario törvényei vonatkoznak, és azokkal összhangban értelmezendők, tekintet nélkül a kollíziós rendelkezésekre. Ön beleegyezik abba, hogy aláveti magát az ontariói bíróságok kizárólagos joghatóságának a jelen Feltételekkel kapcsolatos viták eldöntése tekintetében.",
        "severability": "Ha bármely okból az illetékes bíróság a jelen Feltételek bármely rendelkezését vagy annak egy részét jogellenesnek vagy bármilyen okból végrehajthatatlannak találja, akkor az adott rendelkezést a jelen Feltételek szándékának érvényre juttatásához megengedett maximális mértékig érvényesítik, a Feltételek többi része pedig továbbra is teljes hatályú és hatályos, és végrehajtható marad.",
        "waiver": "A jelen Feltételek követelményeitől való bármilyen lemondás vagy az azoktól való eltérés csak akkor lesz hatályos, ha azt írásban és általunk aláírt módon, és csak abban a konkrét esetben és arra a célra, amelyre adták. A gyakorlás elmulasztása vagy késedelmes gyakorlása, a jelen Feltételek szerinti jogok nem minősülnek jogainkról való lemondásnak.",
        "furtherAssurances": "Mindegyik fél vállalja, hogy aláír és rendelkezésre bocsát minden dokumentumot, és megtesz minden olyan intézkedést (vagy előidézi e műveletek végrehajtását), amelyek a jelen Feltételek és feltételek szerinti kötelezettségeik teljesítéséhez szükségesek.",
        "notices": "A Menuonline-nak szóló minden értesítést írásban kell megküldeni, és e-mailben kell elküldeni nekünk. A nekünk küldött értesítéseket ügyfélszolgálati képviselőink figyelmébe kell küldeni a support@menuonline.com címre. Az Ön értesítéseit a regisztráció részeként megadott címre vagy e-mail címre küldhetjük. Ezen túlmenően ezen a Webhelyen keresztül értesítéseket vagy üzeneteket sugározhatunk, hogy tájékoztassuk Önt a Webhely változásairól vagy más fontos kérdésekről, és ezek a közvetítések értesítésnek minősülnek Ön felé, amikor közzétesszük őket.",
        "entireAgreement": "Ezek a Feltételek és az Adatvédelmi nyilatkozatunk alkotják a teljes megállapodást Ön és közöttünk a Webhely és a Szolgáltatás Ön általi használatára vonatkozóan, és felülírnak minden korábbi megállapodást, nyilatkozatot és egyetértést a Menuonline és Ön között ebben a témában. Az Adatvédelmi nyilatkozat és a jelen Feltételek közötti bármilyen ütközés esetén a jelen Feltételek az irányadók.",
        "language": "A felek kifejezetten kérték és megkövetelték, hogy jelen Általános Szerződési Feltételek, Adatvédelmi szabályzatunk és minden kapcsolódó dokumentum angol nyelven készüljön el. Les party conviennent et exigent expressément que ce Contrat et tous les documents qui s'y rapportent soient rédigés en anglais. Ön elfogadja, hogy a jelen Feltételek és Adatvédelmi Szabályzat angol nyelvű változata élvez elsőbbséget a lefordított változattal való ütközés esetén.",
        "survival": "A jelen Feltételek minden olyan rendelkezése, amely természetüknél fogva a jelen Feltételek lejárta vagy megszűnése után is fennmarad, érvényben marad, beleértve – korlátozás nélkül – a 17-19. szakaszt."
    },
    "collect_additional_info": "Szeretne további információkat gyűjteni?",
    "additionalInfoChoice": "Válassza ki a gyűjteni kívánt további információkat.",
    "additional_info_yes": "Igen",
    "additional_info_noSkip": "Nem, Skip",
    "additional_info_label": "További információk",
    "confirm_business_location": "Először erősítsük meg vállalkozása helyét",
    "custom_get_more_reviews_form": "Egyedi További véleményezési űrlap",
    "set_up_custom_form": "Állítsa be egyéni További véleményezési űrlapját csoportonként",
    "reputation_name_label": "További vélemények Név",
    "reputation_name_placeholder": "Adja meg a hírnév nevét",
    "group_label": "Csoport",
    "group_desc_title": "Csoportleírás címe",
    "group_desc": "Csoport leírása",
    "select_group_placeholder": "Válassza a Csoport lehetőséget",
    "required_group_name": "A csoportnév megadása kötelező",
    "before_asking_review": "Mielőtt véleményt kérnénk az ügyfelektől, tegyünk fel nekik 1-4 kérdést.",
    "feel_free_edit": "Szükség szerint szerkessze nyugodtan.",
    "incentive_settings": "Ösztönző beállítások",
    "incentivize_customer": "Szeretné ösztönözni ügyfelét véleményezésre?",
    "choose_incentive": "Válassza ki az utalni kívánt ösztönzőt.",
    "scan_qr_feedback": "Olvassa be a QR-kódot, és ossza meg velünk tapasztalataival kapcsolatos értékes visszajelzését.",
    "qr_code": "QR-kód",
    "scan_me": "SCEN ME!",
    "or_title": "VAGY",
    "send_otp_title": "OTP küldése",
    "sign_in_title": "Jelentkezzen be",
    "email_addresss_title": "E-mail cím",
    "reset_password_title": "Jelszó visszaállítása",
    "remember_password_title": "Emlékezett jelszó?",
    "digit_code_title": "Kérjük, adja meg a 4 számjegyű kódot, amelyet elküldtek ",
    "not_received_title": "Nem kaptam ellenőrző kódot!",
    "resend_title": "Kérjük, küldje el újra",
    "sign_up_form": "Hozzon létre fiókot",
    "sign_up_sub_title": "Jelentkezzen be most, hogy hozzáférjen a legújabb információkhoz",
    "already_registered_title": "Már van Menuonline fiókod?",
    "reserved_title": "Szerzői jogok ©2023 Menuonline. Minden jog fenntartva.",
    "terms_title": "Szolgáltatási feltételek",
    "policy_title": "Adatvédelmi szabályzat",
    "sign_up_title": "Regisztráljon",
    "terms_conditions_title": "Általános Szerződési Feltételek",
    "business_type_title": "Üzleti típus",
    "business_type_sub_title": "Kérjük, válasszon egy olyan vállalkozástípust, amely jobban jellemzi vállalkozását!",
    "salon_title": "Szalon",
    "restaurant_title": "Étterem",
    "pizza_store_title": "Pizza bolt",
    "food_truck_title": "Food Truck",
    "check_domain_title": "Ellenőrizze a Domain nevet",
    "start_exploring_title": "Kezdje el a felfedezést",
    "sign_in_form": "Jelentkezzen be fiókjába",
    "sign_in_sub_title": "Jelentkezzen be most, hogy hozzáférjen a legújabb információkhoz",
    "remember_title": "Emlékezz Rám",
    "otp_title": "OTP",
    "password_title": "Jelszó",
    "forgot_title": "Elfelejtett jelszó?",
    "not_registered_title": "Nincs fiókod?",
    "create_account_title": "Regisztráljon most",
    "create_form_title": "Űrlap létrehozása",
    "first_name_title": "Keresztnév",
    "last_name_title": "Vezetéknév",
    "email_title": "Email",
    "mobile_number_title": "Mobilszám",
    "birthday_title": "Születésnap",
    "gender_title": "Nem",
    "address_title": "Cím",
    "short_answer_title": "Rövid válasz",
    "long_answer_title": "Hosszú válasz",
    "single_answer_title": "Egyetlen válasz",
    "single_checkbox_title": "Egyetlen jelölőnégyzet",
    "multiple_choice_title": "Több választás",
    "drop_down_title": "Legördülő menü",
    "yes_no_title": "Igen vagy Nem",
    "description_text_title": "Leíró szöveg",
    "file_upload": "Fájl feltöltése",
    "reset_password_form": "Állítsa vissza jelszavát",
    "reset_password_sub_title": "Gyorsan állítsa vissza jelszavát, és férhet hozzá fiókjához.",
    "required_full_name_title": "A teljes név megadása kötelező",
    "required_first_name_title": "A keresztnév megadása kötelező",
    "valid_first_name_title": "Adja meg az érvényes keresztnevet",
    "valid_full_name_title": "Adja meg az érvényes teljes nevet",
    "required_block_time": "Blokkolási idő szükséges",
    "required_last_name_title": "A vezetéknév megadása kötelező",
    "valid_last_name_title": "Adja meg az érvényes vezetéknevet",
    "valid_mobile_number_title": "Adja meg az érvényes mobilszámot",
    "required_mobile_number_title": "Mobilszám megadása kötelező",
    "required_already_mobile_number_title": "A mobilszám már kötelező",
    "valid_otp_title": "Írja be az érvényes otp-t",
    "valid_email_title": "Adjon meg egy érvényes e-mail-címet",
    "required_email_title": "E-mail megadása kötelező",
    "required_description_title": "Leírás szükséges",
    "required_description_500_characters": "Kérjük, adjon meg legalább 500 karaktert",
    "required_description_600_characters": "Kérjük, adjon meg legfeljebb 600 karaktert",
    "domain_title": "Domain név",
    "required_password_title": "Jelszó szükséges",
    "required_password_regex_title": "Tartalmaznia kell legalább 8 karaktert, legalább egy nagybetűt, egy kisbetűt, egy számot és egy speciális karaktert",
    "required_terms_title": "Kérjük, fogadja el a feltételeket",
    "required_note_title": "Megjegyzés szükséges",
    "required_birthday_title": "Születésnap kötelező",
    "no_access_title": "Jelenleg nincs hozzáférése. Kérjük, forduljon rendszergazdájához",
    "menu_online_open_txt": "Nyitott",
    "loyalty_program_description": "😊 Találja meg ideális hűségprogramját, és szabja testre a márkájához!",
    "lp_list_title": "A hűséglyukkártya listája",
    "lp_n_title": "Hűség-lyukkártya neve",
    "enter_details_title": "Írja be a részleteket",
    "stamp_allowed_title": "Bélyeg engedélyezve",
    "stamp_items_title": "Bélyegző elemek",
    "lpn_req_title": "A törzsvásárlói lyukkártya név megadása kötelező",
    "valid_lpn_title": "Adja meg az érvényes hűségkártya nevét",
    "brand_color_error": "A márka színének pontosan 7 karakterből kell állnia",
    "select_dark_brand_color_title": "Kérjük, válasszon érvényes márkaszínt",
    "your_brand_color_title": "A márka színe",
    "upload_logo_title": "Logó feltöltése",
    "locations_title": "Helyszínek",
    "add_lp_title": "Program létrehozása",
    "location_error": "Kérjük, válasszon helyszíneket",
    "pvs_title": "Hány bélyeget kereshet egy ügyfél látogatásonként?",
    "max_apd_title": "Legfeljebb hány bélyeget szerezhet egy ügyfél egy nap alatt?",
    "logo_error": "Logó megadása kötelező",
    "expiry_title": "Állítsa be a jutalmak lejáratát",
    "add_more_rewards_title": "+ További jutalmak hozzáadása",
    "prev_step_1_title": "Mutasson QR-kódot, hogy pontokat szerezzen, és élvezze jutalmait.",
    "delete_lp_title": "Ez a hűségkártya véglegesen törlődik.",
    "delete_user_record_title": "Ez a felhasználói rekord véglegesen törlődik.",
    "redeem_title": "Beváltani",
    "lp_button_title": "Csatlakozzon a hűséglyukkártyához",
    "lp_download_button_title": "Kártya letöltése",
    "step_0_title": "Csatlakozzon most, ízletes jutalmak várják a hozzád hasonló hűséges ínyencségeket.",
    "step_1_title": "Ön csatlakozott",
    "congrats_step_title": "Most már jogosult az ingyenes pizzára",
    "reward_title": "Szerezzen jutalmat minden látogatáskor",
    "visit_title": "Látogassa meg és oldja fel",
    "rewards_title": "Jutalmak",
    "dummy_address": "3730 Frankfort Ave, Louisville Kentucky, Egyesült Államok",
    "loyalty_qr_default_text": "Olvassa be a QR-kódot, csatlakozzon hűségkártyánkhoz, és élvezze a jutalmakat",
    "lp_earn_stamp_txt": "Az ügyfelek minden látogatáskor bélyeget kapnak.",
    "lp_get_rewards_txt": "Szerezzen jutalmakat minden látogatáskor",
    "lp_1_time_visit_txt": "Egyszeri látogatást kap",
    "lp_uppercase_stamp_text": "Bélyeg",
    "lp_lowercase_stamp_text": "bélyeg",
    "lp_txt": "Hűség",
    "lp_per_visit_stamp_validation_msg": "A látogatásonkénti bélyegnek kevesebbnek kell lennie, mint a maximálisan megengedett napi bélyeg",
    "lp_per_max_stamp_validation_msg": "A maximálisan megengedett napi bélyegzőnek nagyobbnak kell lennie, mint a látogatásonkénti bélyegzőnek",
    "lp_valid_value_msg": "Adjon meg érvényes értéket",
    "lp_required_field_msg": "Ezt a mezőt kötelező kitölteni",
    "lp_stamp_required_msg": "Bélyegző szükséges",
    "lp_valid_stamp_msg": "Adjon meg érvényes bélyegzőértéket",
    "lp_reward_name_required_msg": "A jutalom név megadása kötelező",
    "lp_unique_stamp_msg": "A bélyegző értékének egyedinek kell lennie",
    "lp_benefits": "A hűségprogram növeli az ismétlődő látogatásokat és a bevételt, miközben vásárlói betekintést nyújt a személyre szabott marketinghez",
    "lp_how_customer_txt": "Így az Ön ügyfelei",
    "lp_punch_card_txt": "hűség lyukkártya",
    "lp_look_txt": "nézni fog! 😍",
    "lp_desc": "Az ügyfelek minden látogatásért bélyeget kapnak, és ha elegendő összeget gyűjtenek össze, különleges jutalmakat válthatnak be.",
    "lp_edit": "Szerkesztés",
    "lp_looks_good_txt": "Jól néz ki",
    "lp_customer_view_instruction": "Ezt fogja látni az ügyfele. Töltse fel logóját és állítsa be a színtémát",
    "lp_customer_earn_stamps": "Lehetővé teszi az ügyfelek számára, hogy minden látogatáskor bélyeget szerezzenek, és kezeljék jutalmaikat",
    "lp_stamp_rewards_setup": "Állítson be bélyegszerzési jutalmakat, tegye lehetővé az ügyfelek számára, hogy bélyegenként jutalmakat kapjanak",
    "lp_loyalty_program_setup": "Adja meg a hűségprogram nevét, és állítsa be a helyét, hogy legyen ez a program",
    "lp_prev_step1": "Mutasson QR-kódot, hogy pontokat szerezzen, és élvezze jutalmait.",
    "lp_visit_1_time_txt": "Látogassa meg 1 alkalommal, és kap",
    "lp_get_free_txt": "Get Free",
    "lp_visit_allow_txt": "Vist & Unlock",
    "lp_rewards": "Jutalmak",
    "repsT": "További vélemények",
    "offSup": "Irodai kellékek",
    "repsNxtV": "A következő látogatás alkalmával",
    "addRepT": "Add További vélemények",
    "repEntrCpnTxt": "Írja be a kupon szövegét",
    "repEntrCpnTxtBtn": "Írja be a kupongomb szövegét",
    "repMngNxtV": "következő látogatása alkalmával?",
    "repMngWldL": "Szeretnél kapni",
    "discount": "Kedvezmény",
    "repDisTyp": "Kedvezmény típusa",
    "repNameT": "Nevezze meg a hírnevét",
    "crtRepT": "Hírnév létrehozása",
    "feedBackT": "Visszacsatolás",
    "confLocT": "Hely megerősítése",
    "businLocT": "Üzleti hely",
    "addInfoT": "További információk",
    "repNReqT": "További vélemények kérése név megadása kötelező",
    "validRepNT": "Adja meg az érvényes hírnév nevét",
    "groupT": "Csoport",
    "groupLDescTitleT": "A névjegyek automatikus beküldése egy csoportba",
    "groupLDescT": "Minden ügyfél, aki kitölti az űrlapot, bekerül a kiválasztott csoportba.",
    "que2": "jó volt az ételed?",
    "que3": "Szolgáltatásunk barátságos volt?",
    "que4": "Gyors volt a szolgáltatásunk?",
    "addAdditionalT": "Szeretne további információkat hozzáadni?",
    "phNoMandT": "A telefonszám megadása kötelező!",
    "qrGenT": "QR generálva",
    "repFDT": "Hozzájárulása segít csapatunknak abban, hogy jobban kiszolgálja Önt!",
    "letdiT": "Csináljuk meg",
    "tak15secT": "15 másodpercet vesz igénybe",
    "defQue": "Szeretne időről időre nagyszerű ajánlatokat és promóciókat kapni?",
    "defNQue": "Szeretnéd, ha valaki válaszolna neked?",
    "rateOnGoogleDesc": "Lenne olyan kedves, hogy értékeljen minket a google-n?",
    "mayBeNT": "Talán Legközelebb",
    "yesSureT": "Igen, persze",
    "valid_name": "Adjon meg érvényes nevet",
    "nameReq": "A név megadása kötelező",
    "backToMainT": "Vissza a főoldalra",
    "attachCopT": "Szeretné ösztönözni ügyfelét?",
    "thankYouT": "Köszönöm",
    "lftyT": "Nagyon várjuk a következő látogatását!",
    "repPreT": "Hírnév előnézete",
    "offer_exclusive_discounts": "Kínáljon exkluzív kedvezményeket és promóciókat, hogy csábítsa ügyfeleit.",
    "couponT": "Kupon",
    "locStepDesc": "Erősítse meg vállalkozása helyét",
    "createRepStepDesc": "Hozzon létre egy egyéni hírnév-űrlapot csoportonként",
    "feedbackStepDesc": "Ügyfél-visszajelzési kérdéskészlet",
    "addInfoStepDesc": "Ügyfélmezők és ösztönző beállítások",
    "FedbkkWDT": "Visszajelzés adatokkal",
    "repAnlytT": "Hírnévelemzés",
    "todayT": "Ma",
    "rep_last_7_days_t": "Utolsó 7 nap",
    "last30DaysT": "Az elmúlt 30 nap",
    "lastMT": "Múlt hónap",
    "custRangeT": "Egyedi tartomány",
    "queWiseAnltT": "Kérdés Wise Analytics",
    "atL1QueErrT": "Kérjük, válasszon legalább egy kérdést",
    "deleteRepTitle": "Ez a hírnév véglegesen törlődik.",
    "incvCustT": "Igen, ösztönözze az ügyfelemet",
    "collAddInfoT": "Igen, szeretnék további információkat gyűjteni",
    "totRewT": "Total Review",
    "totPosRewT": "Teljesen pozitív értékelés",
    "negFeedT": "Negatív visszajelzés",
    "posFeedT": "Pozitív visszajelzés",
    "fineT": "Fine Print",
    "enterFineT": "Írjon be apró betűs szöveget",
    "setThemeT": "Állítsa be a téma színét",
    "que1T": "1. kérdés",
    "que2T": "2. kérdés",
    "que3T": "3. kérdés",
    "que4T": "4. kérdés",
    "entMailT": "Adja meg e-mail címét",
    "reputationQRDefTxt": "Olvassa be a QR-kódot, és ossza meg velünk tapasztalataival kapcsolatos értékes visszajelzését.",
    "selUser": "Válassza a Felhasználó lehetőséget",
    "userT": "Felhasználó",
    "subUReq": "Kérjük, válasszon felhasználókat",
    "updateLoc": "Hely frissítése",
    "leadGenT": "Lead Generation",
    "displayT": "Kijelző",
    "ratingT": "Értékelés",
    "rep_dashboard_negative_feedback": "Negatív visszajelzés 0%",
    "rep_dashboard_position_feedback": "Pozitív visszajelzés 0%",
    "rep_dashboard_total_Feedback_txt": "Összes visszajelzés",
    "rep_dashboard_anony_Feedback": "Névtelen visszajelzés",
    "rep_dashboard_Feeedback_Data_txt": "Visszajelzés adatokkal",
    "rep_dashboard_review_txt": "További vélemények",
    "rep_dashboard_total_Review_txt": "Total Review",
    "rep_dashboard_total_positive_Review_txt": "Teljesen pozitív értékelés",
    "rep_dashboard_negative_feedback_txt": "Negatív visszajelzés",
    "rep_dashboard_connection_txt": "Kapcsolat",
    "rep_dashboard_question_wise_analytics_txt": "Kérdés Wise Analytics",
    "rep_dashboard_date_label": "Válassza a Dátum lehetőséget",
    "rep_dashboard_custom_range_txt": "Egyedi tartomány",
    "rep_tlt_min": "REP",
    "emojis": "Hangulatjelek",
    "media_text": "Média hozzáadása",
    "custom_field_text": "Egyéni mezők beszúrása",
    "shortlink_text": "Rövid hivatkozás hozzáadása",
    "use_template_text": "Sablon használata",
    "banner_must_have": "Must Have",
    "res_seamless": "Zökkenőmentes",
    "res_table_reservation": "Asztalfoglalás",
    "res_system": "Rendszer",
    "res_text": "Fenntartások",
    "md_easily": "Könnyen",
    "md_manage_customers": "Ügyfelek kezelése",
    "md_visits_interactions": "látogatások és interakciók",
    "md_desc": "Tegye könnyebbé az étkezést ügyfelei számára valós idejű asztalfoglalással. Könnyedén kezelheti a foglalásokat, csökkentheti a várakozási időt, és zökkenőmentes élményt biztosíthat mind a személyzet, mind a vendégek számára. Tartsa tele éttermét, miközben megőrzi a kiváló kiszolgálást és az elégedettséget.",
    "engage_convert": "Engage & Convert SMS",
    "coupon_campaign": "és kuponkampány",
    "sms_read_rate": "Az SMS üzeneteket az esetek 98%-ában elolvassák",
    "real_time_promo": "Az azonnali cselekvés érdekében valós időben jelenítsen meg promóciókat",
    "affordable_reach": "Megfizethető módja az ügyfelek elérésének szilárd ROI-val",
    "stay_in_touch": "Maradjon mindig kapcsolatban ügyfelével",
    "bc_engage_customers": "Ügyfelek bevonása SMS-ek konvertálására",
    "bc_coupon_campaign": "és kuponkampány",
    "bc_sms_read_rate": "Az SMS üzeneteket az esetek 98%-ában elolvassák",
    "bc_real_time_promo": "Az azonnali cselekvés érdekében valós időben jelenítsen meg promóciókat",
    "bc_affordable_reach": "Megfizethető módja az ügyfelek elérésének szilárd ROI-val",
    "bc_stay_in_touch": "Maradjon mindig kapcsolatban ügyfelével",
    "ar_automate_engagement": "Automatizálja az ügyfelek bevonását",
    "ar_with": "-vel",
    "ar_with_auto_responders": "Automatikus válaszadók",
    "ar_personalized_messages": "Automatikusan küldjön személyre szabott üzeneteket születésnapokra, üdvözölje az új ügyfeleket, és lépjen kapcsolatba korábbi ügyfelekkel. Takarítson meg időt, miközben minden interakció értelmes és időszerű marad.",
    "sq_custom_forms_tools": "Egyéni űrlapok és eszközök – egyszerűsítés",
    "sq_custom_forms_ci": "Kliens interakciók",
    "sq_smart_qr_desc": "Hozzon létre egyéni űrlapokat, gyűjtsön e-aláírásokat, és használjon intelligens QR-kódokat az üzleti folyamatok egyszerűsítéséhez. Minden eszközt úgy alakítson ki, hogy megfeleljen ügyfelei igényeinek, és javítsa a felhasználói élményt.",
    "gnc_get_new_cus": "Szerezzen új ügyfeleket",
    "gnc_get_customers": "a közösségi médiából – Gyorsan és egyszerűen!",
    "gnc_social_media_power": "Használja ki a közösségi platformok erejét, hogy új ügyfeleket vonzzon, és közvetlenül a vállalkozásához irányítsa az eladásokat. A maximális ellenőrzés és bevétel érdekében irányítsa át a harmadik féltől származó kézbesítési rendeléseket közvetlenül az üzletébe vagy az online rendelési platformra.",
    "wifi_connect_guests": "Csatlakoztassa a vendégeket",
    "wifi_smart": "Intelligens Wi-Fi",
    "wifi_connect_grow": "& Bővítse belátásait",
    "wifi_smart_wifi_desc": "Problémamentes Wi-Fi-kapcsolatot biztosít ügyfelei számára egy márkás nyitóképernyőn keresztül, miközben értékes ügyféladatokat gyűjt a marketingtevékenység és az elkötelezettség fokozása érdekében.",
    "wifi_create_splash_page": "Hozzon létre vendég Wifi Splash oldalt",
    "instant_menu_create": "Készítse el saját",
    "instant_menu_text": "Azonnali menü",
    "instant_menu_wm": "perceken belül",
    "instant_menu_desc": "Javítja az ügyfelek élményét azáltal, hogy lehetővé teszi a valós idejű frissítéseket, az egyszerű testreszabást és a jobb elköteleződést, így könnyebben bemutathatja kínálatát, és frissen tarthatja a tartalmat.",
    "instant_menu_create_digital_menu": "Készítsen digitális menüt",
    "loyalty_program_rewards": "Nyerj jutalmakat",
    "loyalty_program_on_visits": "minden látogatáskor",
    "loyalty_program_create": "Hűségprogram létrehozása",
    "reputation_create": "Hírnév létrehozása",
    "reputation_boost": "Boost Your",
    "reputation_text": "Hírnév",
    "reputation_reviews": "További véleményekkel!",
    "reputation_desc": "Gyűjtsön értékes visszajelzéseket ügyfeleitől szolgáltatása és hírneve javítása érdekében. Ossza meg tapasztalatait, és segítsen növekedésünknek – az Ön véleménye számít!",
    "dashboard": "Műszerfal",
    "blast_campaign": "Robbantási kampány",
    "blast_campaigns": "Robbantási kampányok",
    "blast_campaign_sub_title": "Indítson robbanásszerű kampányokat, hogy néhány kattintással elérje az összes ügyfelét",
    "auto_responders": "Automatikus válaszadók",
    "auto_responder": "Auto Responder",
    "auto_responder_sub_title": "Könnyen használható eseményalapú promóciók ügyfelei számára",
    "smart_qr_form": "Intelligens QR \/ űrlap",
    "get_new_customer": "Szerezzen új ügyfelet",
    "marketing": "Marketing",
    "clients": "Ügyfelek",
    "group_data": "Csoportok adatai",
    "clients_data": "Ügyfelek adatai",
    "account": "fiók",
    "user_acc_info": "Felhasználói fiók adatai",
    "current_plan": "Jelenlegi terv",
    "payments": "Kifizetések",
    "location": "Elhelyezkedés",
    "location_list": "Helyek listája",
    "users": "Felhasználók",
    "users_sub_title": "Az összes felhasználó listája és adatai",
    "online_profile": "Online profil",
    "change_password": "Jelszó módosítása",
    "menu": "Menü",
    "reseller_dashboard": "Viszonteladói irányítópult",
    "companies": "Vállalatok",
    "plans_bought": "Tervek megvásárolva",
    "agreement_t_c": "Megállapodási feltételek",
    "wifi_dashboard": "Wifi műszerfal",
    "hot_spot": "Hotspot",
    "splash_pages": "Splash Pages",
    "get_more_reviews": "További vélemények",
    "office_supplies": "Irodai kellékek",
    "reservation": "Foglalás",
    "floor_table": "Padló és asztal",
    "guest_smart_wifi": "Vendég Smart WiFi",
    "digital_signage": "Digital Signage",
    "schedule_down": "Ütemezés",
    "business_settings": "Üzleti beállítások",
    "sub_user_login": "Alfelhasználói bejelentkezés",
    "help_center": "Súgó",
    "switch_to_hub": "Váltson Hub-ra",
    "meeting_records": "Találkozók jegyzőkönyvei",
    "logout": "Kijelentkezés",
    "no_access_text": "Jelenleg nincs hozzáférése. forduljon rendszergazdájához",
    "menu_item": "MENÜLÉP",
    "menu_capital": "MENÜ",
    "good_day": "Jó napot kívánok",
    "no_permission_text": "Nincs engedélye ennek a műveletnek a végrehajtására!!",
    "enter_valid_pin": "Adjon meg érvényes PIN-kódot",
    "add_reservation": "Foglalás hozzáadása",
    "edit_reservation": "Foglalás szerkesztése",
    "per_req": "Személyek száma kötelező",
    "req_block_time": "Blokkolási idő szükséges",
    "pending_status": "Függőben levő",
    "booked_status": "Lefoglalt",
    "completed_status": "Befejezve",
    "cancelled_status": "Törölve",
    "reservation_slot": "Foglalás Slot",
    "block_time": "Idő blokkolása",
    "slot_time": "Foglalási idő:  ",
    "template_name_req": "A sablonnév megadása kötelező",
    "template_type_req": "A sablon típusa kötelező",
    "sms_template_req": "SMS-sablon szükséges",
    "group_req": "Csoport szükséges",
    "valid_date_time": "Adja meg az érvényes dátumot és időt",
    "valid_group_name": "Adja meg az érvényes csoportnevet",
    "reservation_mark_completed": "Ez a foglalás befejezettként lesz megjelölve.",
    "customer_name": "Ügyfél neve",
    "date_time": "Dátum Idő",
    "table_no": "számú táblázat",
    "floor": "Emelet",
    "customer_name_placeholder": "ügyfél_neve",
    "date_time_placeholder": "dátum_idő",
    "table_no_placeholder": "táblázat_sz",
    "floor_placeholder": "emelet",
    "select_floor": "Válassza az Emelet lehetőséget",
    "select_table": "Válassza a Táblázatot",
    "customer_name_macro": "%% ügyfél_neve %%",
    "date_time_macro": "%% dátum_idő %%",
    "table_no_macro": "%% táblázat_nem %%",
    "floor_macro": "%% emelet %%",
    "template_name": "Sablon neve",
    "login_with_4_digit_pin": "Jelentkezzen be 4 számjegyű PIN-kódjával",
    "login_now_to_access_latest_insights": "Jelentkezzen be most, hogy hozzáférjen a legújabb feliratokhoz",
    "mkt_BS_Title": "Blast SMS",
    "step_1": "Üzenet testreszabása",
    "step_2": "Válassza a Közönség lehetőséget",
    "step_3": "Üzenet ütemezése",
    "step_4": "Üzenet küldése",
    "step_5": "Üzenet kezelése",
    "bs_tab_title": "Robbantási kampány összefoglalója",
    "default_message": "Szia %% keresztnév %%! Különleges kedvezmény a villásreggelire! Vacsorázzon még ma, és exkluzív 10% kedvezményt kap a számlájára!",
    "bs_Title": "Válasszon kampánytípust",
    "sms_title": "SMS\/MMS",
    "reach_out_to_customer": "Érje el az ügyfeleket szöveges és multimédiás üzenetekkel.",
    "wp_text": "WhatsApp",
    "connect_with_wp": "Lépjen kapcsolatba ügyfeleivel a WhatsApp 💬 használatával",
    "send_discount_offers": "Küldjön kedvezményes ajánlatokat SMS-ben 📲",
    "send_coupons_via_sms": "Küldj kuponokat SMS-ben ügyfeleidnek 🎟️",
    "env_Cap": "Kínáljon exkluzív kedvezményeket és promóciókat, hogy csábítsa ügyfeleit",
    "cmt_Caption": "Érje el az ügyfeleket szöveges és multimédiás üzenetekkel",
    "wp_caption": "A zökkenőmentes és közvetlen kommunikáció érdekében a WhatsApp használatával kapcsolatba léphet a mecénásokkal",
    "msg_Prev_Title": "Üzenet előnézete",
    "cou_Prev_Title": "Kupon előnézet",
    "blast_SMS": "SMS\/MMS kampány",
    "bs_P_Msg": "Sorban",
    "bs_S_Msg_C": "Sikertelen",
    "bs_F_Msg_Count": "Szállítva",
    "previous": "Előző",
    "next_ST": "Következő",
    "next_S": "Következő lépés",
    "req_Temp_Name_Title": "A sablonnév megadása kötelező",
    "req_Template_Tx_Title": "A sablon szövege kötelező",
    "add_Temp_Title": "Sablon hozzáadása",
    "link_Desc": "Adjon hozzá egy választott linket, hogy az ügyfelek a Facebookon, a Google-on vagy a saját webhelyén keresztül foglalhassanak Önnél.",
    "audience_Title": "Közönség",
    "client_G1_Title": "Új ügyfelek",
    "client_G2_Title": "Legutóbbi ügyfelek",
    "client_G3_Title": "Hűséges Ügyfelek",
    "client_G4_Title": "Elévült ügyfelek",
    "client_G5_Title": "Ügyfelek a csatlakozás dátuma szerint",
    "on_Which_Day": "Melyik napon",
    "on_Day": "A napon",
    "immediate_Desc": "Üzenetét azonnal elküldjük, a kezdéshez kattintson a Tovább gombra.",
    "never_Desc": "Ez a kampány a tervek szerint mindaddig futni fog, amíg manuálisan be nem fejezi.",
    "end_Desc": "A kampány az Ön által kiválasztott napon ér véget.",
    "success_Message": "A kampány sikeresen mentve!",
    "send_Success_Message": "A kampány sikeresen elküldve!",
    "msg_Name_Field_Title": "Kampány címe",
    "valid_campaign_title": "Adja meg a kampány érvényes címét",
    "msg_Type_Title": "Üzenet típusa",
    "sms_Desc": "SMS-enként 1 jóváírás",
    "mms_Desc": "3 kredit MMS-enként",
    "client_GTO_1": "Nap",
    "client_GTO_2": "Órák",
    "minutes": "Jegyzőkönyv",
    "client_GTO_4": "Hét",
    "time_Option_1": "Nap",
    "time_Option_2": "Órák",
    "c_Grp_Day_1": "90 nap",
    "c_Grp_Day_2": "60 nap",
    "c_Grp_Day_3": "30 nap",
    "first": "Első",
    "second_T": "Második",
    "third_T": "Harmadik",
    "fourth_T": "Negyedik",
    "fifth_T": "Ötödik",
    "delete_BS_T": "Ez a kampány véglegesen törlésre kerül.",
    "delete_MBS_T": "Ezek a kampányok véglegesen törlődnek.",
    "cFT_1": "Telefon",
    "cFT_2": "Kapcsolatfelvétel Keresztnév",
    "cFT_3": "Kapcsolattartó vezetéknév",
    "cFT_4": "Kapcsolatfelvételi e-mail",
    "cFT_5": "Jegyzet",
    "cFT_6": "Születésnap",
    "cFT_7": "Cégnév",
    "cF_Meta_Tag_1": "%% telefon %%",
    "cF_Meta_Tag_2": "%% keresztnév %%",
    "cF_Meta_Tag_3": "%% vezetéknév %%",
    "cF_Meta_Tag_4": "%% email %%",
    "cF_Meta_Tag_5": "%% megjegyzés %%",
    "cF_Meta_Tag_6": "%% születésnap %%",
    "cF_Meta_Tag_7": "%% cégnév %%",
    "cF_Name_1": "telefon",
    "cF_Name_2": "keresztnév",
    "cF_Name_3": "vezetéknév",
    "cF_Name_4": "email",
    "cF_Name_5": "jegyzet",
    "cF_Name_6": "születésnap",
    "cF_Name_7": "cégnév",
    "all_clients": "AllClients",
    "aud_Type_2": "ClientGroups",
    "aud_Type_3": "Ügyfélcsoportok",
    "aud_Type_T1": "Minden ügyfél",
    "aud_Type_T2": "Ügyfélcsoportok",
    "aud_Type_T3": "Ügyfélcsoportok",
    "new_clients": "Új ügyfelek",
    "client_G2": "Legutóbbi ügyfelek",
    "client_G3": "Hűséges Ügyfelek",
    "client_G4": "Elévült ügyfelek",
    "client_G5": "Ügyfelek a csatlakozás dátuma szerint",
    "never_text": "Soha",
    "ending_on": "Vége",
    "send_T2": "Ütemezett",
    "send_Type_3": "Visszatérő",
    "daily": "Napi",
    "weekly_T": "Heti",
    "monthly_T": "Havi",
    "yearly_T": "Évi",
    "each": "Minden",
    "on_the": "A",
    "monday": "hétfő",
    "exclusive_offer": "Exkluzív ajánlat az Ön számára",
    "redeem_now": "Váltsd be most",
    "redeem_with_cashier": "Kérjük, váltsák be pénztárosnál",
    "lg_Day_2": "kedd",
    "lg_Day_3": "szerda",
    "lg_Day_4": "csütörtök",
    "lg_Day_5": "péntek",
    "lg_Day_6": "szombat",
    "lg_Day_7": "vasárnap",
    "msg_Pre_T": "Dominos",
    "cA_Edit_T1": "Minden",
    "cA_Edit_T2": "Beleértve",
    "cA_Edit_T3": "Kizárva",
    "SMS": "SMS",
    "MMS": "MMS",
    "usd_T": "USD",
    "cad_T": "CAD",
    "msg": "Üzenet",
    "which_Day": "Melyik napokon",
    "end_Date_Tx": "Befejezés dátuma",
    "sDate_Err": "A kezdő dátumnak nagyobbnak vagy egyenlőnek kell lennie, mint a mai nap",
    "eDate_Err": "A befejező dátumnak későbbinek kell lennie, mint a kezdő dátum",
    "start_Date_Req": "A kezdési dátum megadása kötelező",
    "end_Date_Req": "A befejezési dátum megadása kötelező",
    "time_req": "Idő kell",
    "client_GT1": "Ügyfelek előfizetett az utolsó",
    "client_GT2": "Ügyfelek kapcsolattal az utolsó",
    "client_GT3": "Ügyfelek legalább",
    "client_GT4": "az utolsóval",
    "client_GT5": "aki nem tért vissza az utolsóban",
    "dummy_Phone_No": "+91987-654-3210",
    "test_T": "Teszt",
    "dummy_Birth_Date": "2001-01-01",
    "image_Req": "Média vagy URL szükséges",
    "time_Title": "Idő",
    "start_date": "Kezdés dátuma",
    "end_date": "Befejezés dátuma",
    "auto_Send_T": "Automatikusan elküldi az ügyfeleknek",
    "add_Media": "Média hozzáadása",
    "in_Title": "In",
    "c_Temp_T": "Kampánysablon",
    "temp_NT": "Sablon neve",
    "type_T": "Írja be",
    "select_template_type": "Válassza ki a sablon típusát",
    "sel_Temp_T": "Válassza ki a Sablon típusát",
    "sms_temp_t": "SMS sablon",
    "temp_T": "Sablon szövege",
    "default_Msg": "Szia %% keresztnév %%! Különleges kedvezmény a villásreggelire! Vacsorázzon velünk még ma, és exkluzív 10% kedvezményt kap a számlájára!",
    "refill_T": "Utántöltés",
    "avl_Credit": "Rendelkezésre álló kreditek",
    "req_Credit": "Összes szükséges kredit",
    "rem_Credit": "Fennmaradó hitel",
    "refill_Credit": "Újratöltendő kreditek",
    "clients_Req": "Válasszon ki legalább egy ügyfelet",
    "subclient_Req": "A kiválasztott ügyfelek nem előfizetők.",
    "refill_Desc": "Kérjük, töltse fel a szükséges krediteket",
    "url_Regex": "^((http|https):\/\/).....+$",
    "sd_Invalid": "A kezdő dátum érvénytelen",
    "ed_Invalid": "A befejezés dátuma érvénytelen",
    "img_Url_Invalid": "Érvénytelen kép URL-je",
    "time_Invalid": "Az idő érvénytelen",
    "time_And_Date_Invalid": "Adjon meg egy érvényes dátumot és időt",
    "time_Invalid_Bfr": "Válasszon az aktuális időtől legalább 5 perccel későbbi időpontot",
    "sod_Req": "Napi elküldés kötelező",
    "add_Card": "Új kártya hozzáadása",
    "sm_D_Rep_Title": "SMS\/MMS kézbesítési jelentés",
    "send_Now_T": "Küldés most",
    "schd_Now_T": "Ütemezzen SMS-t",
    "test_SMS_T": "Teszt SMS küldése",
    "save_AD_T": "Mentés piszkozatként",
    "back_TE_T": "Vissza a Szerkesztéshez",
    "reset_tex": "Reset",
    "update_tex": "Frissítés",
    "dum_msg1": "Az ügyfél nevének megjelenítéséhez használja %% ügyfélnév %% kifejezést a sablonban",
    "dum_msg2": "A foglalási idő megjelenítéséhez használja %% date_time %% parancsot a sablonban",
    "dum_msg3": "A táblaszám megjelenítéséhez használja %% table_no %% parancsot a sablonban",
    "embedded_link": "Beágyazott hivatkozás",
    "img_title": "Kép",
    "img_input_text": "Szöveg:",
    "img_join_text": " hogy csatlakozzon a listánkhoz",
    "img_copy_text": "Másolat",
    "img_not_found": "A generált kép nem található!!",
    "or_text": "VAGY",
    "web_signup_image_content": "Az űrlap elküldésével és szöveges feliratkozással hozzájárul ahhoz, hogy marketing szöveges üzeneteket (például promóciós kódokat és emlékeztetőket) kapjon. Üzenet- és adatátviteli díjak vonatkozhatnak. Az üzenetek gyakorisága változó. A STOP válaszával bármikor leiratkozhat",
    "img_download_success": "A kép sikeresen letöltve",
    "embedded_link_copied": "A beágyazott hivatkozás sikeresen másolva",
    "media_url_required": "A média vagy az url megadása kötelező",
    "invalid_image_url": "Érvénytelen kép URL-je",
    "invalid_file": "A fájl érvénytelen",
    "image_error_1mb": "Kérjük, helyezzen be 1 MB-nál kisebb képet",
    "image_error_2_5mb": "Kérjük, helyezzen be egy 2,5 MB-nál kisebb képet",
    "image_error_3mb": "Kérjük, helyezzen be egy 3 MB-nál kisebb képet",
    "change_title": "Változás",
    "drop_file_text": "Húzza ide a fájlt, vagy kattintson a feltöltéshez",
    "apply_text": "Alkalmazni",
    "search_here": "Keresés itt",
    "update_status": "Állapot frissítése",
    "reservation_deleted_permanently": "Ez a foglalás véglegesen törlődik.",
    "table_text": "Táblázatok",
    "add_table_button": "Új táblázat hozzáadása",
    "add_table_text": "Táblázat hozzáadása",
    "edit_table_text": "Táblázat szerkesztése",
    "table_delete_text": "Ez a táblázat véglegesen törlődik.",
    "multiple_table_delete_text": "Ezek a táblázatok véglegesen törlődnek.",
    "table_error_name": "Kérjük, adja meg a táblázat nevét",
    "table_error_invalid_name": "Adja meg az érvényes táblázatnevet",
    "table_error_floor": "Kérjük, válassza ki az emeletet",
    "table_error_select": "Kérjük, válassza ki a táblázatot",
    "table_capacity_text": "Asztalkapacitás",
    "capacity_text": "Kapacitás",
    "table_occasion_text": "Találja meg asztalát minden alkalomra",
    "table_name_text": "Táblázat neve",
    "table_capacity_error": "Az asztal kapacitása szükséges",
    "cancel_text": "Mégsem",
    "submit_text": "Beküld",
    "select_valid_date": "Válasszon érvényes dátumot",
    "select_valid_time": "Válasszon érvényes időt",
    "valid_contact_number": "Adja meg az érvényes telefonszámot",
    "date_req": "A dátum megadása kötelező",
    "date_error_later_than_today": "A dátumnak későbbinek kell lennie a mai dátumnál, vagy azzal egyenlőnek kell lennie",
    "time_error_later_than_5_minutes": "Az aktuális időhöz képest legalább 5 perccel későbbi időt válasszon",
    "number_person_req": "A létszám megadása kötelező",
    "contact_no_req": "A kapcsolattartó szám megadása kötelező",
    "contact_no": "Kapcsolattartási szám",
    "select_time": "Válassza az Idő lehetőséget",
    "for_how_many_person": "Hány főre?",
    "f_name": "Keresztnév",
    "l_name": "Vezetéknév",
    "business_name": "Vállalkozás neve",
    "business_no": "Üzleti szám",
    "no_for_sign_in": "Ezt a számot használják a bejelentkezéshez",
    "business_email": "Üzleti e-mail",
    "notes_tex": "Megjegyzések",
    "floor_deleted_permanently": "Ez az emelet véglegesen törlésre kerül.",
    "add_floor": "Emelet hozzáadása",
    "edit_floor": "Emelet szerkesztése",
    "name_text": "Név",
    "mobile_no": "Mobil sz.",
    "person_text": "Személy",
    "date_and_time": "Dátum és idő",
    "actions_text": "Műveletek",
    "extra_text": "Külön",
    "floor_name_req": "Emelet neve kötelező",
    "floor_name": "Emelet neve",
    "status_text": "Állapot",
    "category_status_req": "A kategória állapota kötelező",
    "table_deleted_permanently": "Ez a táblázat véglegesen törlődik.",
    "tables_deleted_permanently": "Ezek a táblázatok véglegesen törlődnek.",
    "back_to_home": "Vissza a Kezdőlapra",
    "link_copied_text": "Link a vágólapra másolva",
    "cust_dash_head_to_title": "hogy",
    "cust_dash_head_location_title": "Elhelyezkedés",
    "select_location_title": "Válassza a Hely lehetőséget",
    "all_locations_label": "all_locations",
    "rep_customer_feedback_analytics": "😊 Kövesse nyomon vásárlói visszajelzéseit valós idejű elemzésekkel",
    "rep_customer_txt": "Ügyfelek",
    "rep_delete_title": "Ez a hírnév véglegesen törlődik.",
    "rep_qr_def_txt": "Olvassa be a QR-kódot, és ossza meg velünk tapasztalataival kapcsolatos értékes visszajelzését.",
    "delete_title": "Töröl",
    "user_list_title": "Ügyfelek listája",
    "os_nfc_txt": "NFC kártyák",
    "os_sign_holder_txt": "Jeltartó állvány",
    "os_store_Sign_holder_txt": "Üzleti táblatartók",
    "StaT": "Statisztika",
    "AllgT": "Minden csoport",
    "FavT": "Kedvenc",
    "MostActT": "A legaktívabb",
    "grT": "Csoport neve",
    "keywT": "Kulcsszavak",
    "exSubT": "Üzenet a meglévő előfizetőnek",
    "ArchT": "Archivált",
    "gNotiSms": "Minden új kapcsolatfelvételről értesítsen SMS-ben",
    "gNotiEmail": "Értesítsen minden új kapcsolatfelvételről e-mailben",
    "reqGName": "A csoportnév megadása kötelező",
    "validGN": "Adja meg az érvényes csoportnevet",
    "valid_phone_no": "Adja meg az érvényes telefonszámot",
    "phone_no_req": "Telefonszám megadása kötelező",
    "required_message_text": "Üzenet szükséges",
    "required_discount_text": "Kedvezmény szükséges",
    "less_than_discount": "A kedvezmény összegének 5000-nél kisebbnek kell lennie",
    "discount_percentage_invalid": "A kedvezmény százalékának 100-nál kisebbnek vagy azzal egyenlőnek kell lennie",
    "discount_type_req": "A kedvezmény típusa kötelező",
    "discount_text_req": "A kupon szövege kötelező",
    "reqContactNo": "A kapcsolattartó szám megadása kötelező",
    "reqMsgNT": "A kampány címe kötelező",
    "reqLinkT": "Link szükséges",
    "delMGT": "Ezek a csoportok véglegesen törlődnek.",
    "defMemMsg": "Írja be üzenetét ide",
    "add_cust_to_grp_title": "Ügyfél hozzáadása a csoporthoz",
    "group_title": "Csoportok",
    "group_create_title": "Csoport létrehozása",
    "group_name_txt": "Csoport neve",
    "group_table_keyword_title": "Kulcsszó",
    "actions_title": "Műveletek",
    "clients_title": "Ügyfelek",
    "send_title": "Elküld",
    "qr_title": "QR",
    "delete_group_txt": "Ez a csoport véglegesen törlődik.",
    "delete_groups_txt": "Ezek a csoportok véglegesen törlődnek.",
    "delete_client_title": "Ez az ügyfél véglegesen törlődik.",
    "delete_clients_title": "Ezek az ügyfelek véglegesen törlődnek.",
    "delete_multiple_title": "Többszörös törlés",
    "wel_sms_mms": "Üdvözlő SMS-t\/MMS-t küld?",
    "key_words_title": "Kulcsszavak",
    "srch_plch_txt": "Keresés itt",
    "req_location_id_title": "Helyszín kötelező",
    "create_text": "Teremt",
    "view_text": "Kilátás",
    "immediately": "Azonnal",
    "business_name_req": "A cégnév megadása kötelező",
    "business_no_req": "Az üzletszám megadása kötelező",
    "valid_business_name": "Adja meg a vállalkozás érvényes nevét",
    "valid_business_no": "Adja meg az érvényes üzleti számot",
    "address_req": "Cím megadása kötelező",
    "valid_address": "Kérjük, válasszon érvényes címet",
    "time_zone_req": "Időzóna megadása kötelező",
    "image_req": "Kérjük, helyezzen be 2,5 MB-nál kisebb képet",
    "valid_file": "A fájl érvénytelen.",
    "logo": "Logó",
    "time_zone": "Időzóna",
    "save": "Megtakarítás",
    "account_type_req": "A fiók típusa kötelező",
    "gst_no_req": "GST-szám megadása kötelező",
    "gst_no_valid": "Adja meg az érvényes GST-számot",
    "set_up_payments": "Állítsa be a fizetéseket",
    "billing_details": "Adja meg számlázási adatait",
    "billing_details_desc": "Számlázási adatai megjelennek a menuonline havi számláján.",
    "account_type": "Számla típusa",
    "select_account_type": "Válassza ki a fiók típusát",
    "gst_no": "GST-szám",
    "transaction_details": "Tranzakció részletei",
    "payment_method": "Fizetési mód",
    "billing_period": "Számlázási időszak",
    "paid_by": "által fizetett",
    "download": "Letöltés",
    "pay_now": "Fizessen most",
    "pull_down_refresh": "Húzza le a frissítéshez",
    "release_refresh": "Engedje el a frissítéshez",
    "billing_details_text": "Számlázási részletek",
    "payment_methods": "Fizetési módok",
    "invoice": "Számla",
    "invoice_to": "Számla címzettje:",
    "issue_date": "Kiadás dátuma",
    "due_date": "Esedékesség",
    "amount_due": "Esedékes összeg",
    "charges": "Díjak",
    "sub_total_capitalize": "Részösszeg",
    "hst": "HST",
    "grand_total": "Grand Total",
    "invoice_generated_on": "Számla generálás dátuma:",
    "contact_us": "Lépjen kapcsolatba velünk",
    "invoice_issue": "Ha átnézte a számlát, és még mindig kérdése van",
    "call": "Hívás",
    "send_sms": "SMS küldése",
    "payment_success": "A fizetés sikeres",
    "edit_credit_card": "Hitelkártya szerkesztése",
    "add_credit_card": "Hitelkártya hozzáadása",
    "modify_card_info": "Módosítsa a kártya adatait",
    "enter_card_info": "Adja meg a kártya adatait",
    "account_no_req": "Számlaszám megadása kötelező",
    "card_name_req": "A kártyán szereplő név kötelező",
    "expiry_date_req": "A lejárati dátum megadása kötelező",
    "valid_expiry_date": "Adja meg az érvényes lejárati dátumot",
    "valid_cvv": "Adja meg az érvényes cvv-t",
    "cvv_req": "CVV szükséges",
    "card_no": "Kártyaszám",
    "name_of_card": "Név a kártyán",
    "expiry": "Lejárat",
    "mm_text": "HH\/ÉÉ",
    "cvv": "CVV",
    "set_as_default": "Beállítás alapértelmezettként",
    "add_new_card": "Új kártya hozzáadása",
    "all_credit_card": "Az összes hitelkártyája",
    "fastest_checkout": "A leggyorsabb kijelentkezés biztonságos és megbízható platformon, nagy üzleti csoportok által megbízhatóan",
    "coupon_button_req": "A kupongomb szövege kötelező",
    "max19_characters_allowed": "Maximum 19 karakter megengedett",
    "fine_print_text_req": "Finom betűs szöveg szükséges",
    "clients_req": "Válasszon ki legalább egy ügyfelet",
    "client_groups": "ClientGroups",
    "day_text": "Nap",
    "valid_days": "A nap értéke túl nagy, írjon be érvényes napokat",
    "hours_text": "Órák",
    "enter_valid_hours": "Az órák értéke túl nagy, és adjon meg érvényes órákat",
    "enter_valid_min": "A percérték túl nagy, adjon meg érvényes percet",
    "clients_with_connection_required": "Az utolsó kapcsolattal rendelkező ügyfelek szükségesek",
    "connection_required": "Csatlakozás szükséges",
    "connection_value_too_large": "A kapcsolatok értéke túl nagy, írjon be érvényes kapcsolatot",
    "minutes_required": "Percek szükségesek",
    "clients_with_at_least_connection_required": "Legalább kapcsolattal rendelkező ügyfelek szükségesek",
    "last_minutes_req": "Az utolsó percekre van szükség",
    "clients_not_returned_required": "Aki nem tért vissza az utolsó percekben, kötelező",
    "not_return_in_last_minutes_invalid": "Az utolsó percekben való visszatérés nem lehet kevesebb, mint az utolsó percekben.",
    "customerGroups": "Ügyfélcsoportok",
    "select_customer_group": "Kérjük, válassza ki az ügyfélcsoportot",
    "location_required": "Helyszín szükséges",
    "start_date_required": "A kezdési dátum megadása kötelező",
    "start_date_invalid": "A kezdő dátum érvénytelen",
    "start_date_invalid_error": "A kezdő dátumnak nagyobbnak kell lennie a mai napnál, vagy egyenlőnek kell lennie azzal",
    "recurring": "Visszatérő",
    "end_date_required": "A befejezési dátum megadása kötelező",
    "end_date_invalid": "A befejezés dátuma érvénytelen",
    "end_date_invalid_error": "A befejező dátumnak későbbinek kell lennie, mint a kezdő dátum",
    "time_invalid": "Az idő érvénytelen",
    "monthly_text": "Havi",
    "send_day_req": "Napi elküldés kötelező",
    "loyal_clients": "Hűséges Ügyfelek",
    "recent_clients": "Legutóbbi ügyfelek",
    "lapsed_clients": "Elévült ügyfelek",
    "clients_connection_date": "Ügyfelek a csatlakozás dátuma szerint",
    "scheduled_text": "Ütemezett",
    "weekly": "Heti",
    "selected_clients_not_subscriber": "A kiválasztott ügyfelek nem előfizetők.",
    "message_preview_title": "Üzenet előnézete",
    "coupon_preview_title": "Kupon előnézet",
    "form_text": "Forma",
    "preview_text": "Előnézet",
    "next_text": "Következő",
    "send_test_SMS": "Teszt SMS küldése",
    "save_draft": "Mentés piszkozatként",
    "back_to_edit": "Vissza a Szerkesztéshez",
    "select_payment_method": "Kérjük, válassza ki a fizetési módot",
    "schedule_SMS": "Ütemezzen SMS-t",
    "send_now": "Küldje el most",
    "get_more_ratings": "További értékelések",
    "overview": "Áttekintés",
    "reset_campaign": "Kampány visszaállítása",
    "permissions": "Engedélyek",
    "location_name": "Hely neve",
    "phone_no": "Telefonszám",
    "location_email_address": "Hely E-mail cím",
    "located_business": "Hol található a vállalkozása?",
    "business_logo": "Üzleti logó",
    "congratulations": "Gratulálok",
    "almost_done": "Már majdnem kész",
    "publish": "Közzététel",
    "about_your_business": "Az Ön vállalkozásáról",
    "add_your_location": "Adja hozzá a tartózkodási helyét",
    "publish_location": "Közzététel helye",
    "location_name_req": "A helynév megadása kötelező",
    "valid_location_name": "Adja meg a hely érvényes nevét",
    "business_logo_req": "Az üzleti logó kötelező",
    "please_accept_terms": "Kérjük, fogadja el a feltételeket",
    "add_new_location": "Új hely hozzáadása",
    "edit_location": "Hely szerkesztése",
    "add_location": "Hely hozzáadása elemre",
    "existing_msg_subscriber_txt": "Üzenet a meglévő előfizetőnek",
    "msg_capitalize_txt": "Üzenet",
    "group_noti_sms": "Minden új kapcsolatfelvételről értesítsen SMS-ben",
    "group_noti_email": "Értesítsen minden új kapcsolatfelvételről e-mailben",
    "group_member_msg": "Úgy tűnik, már a csoportunk tagja vagy!!",
    "group_mend_msg": "STOP a végéhez. SEGÍTSÉG a segítségért. SMS- és adatátviteli díjak vonatkozhatnak",
    "Disclaimer_title": "Felelősségi nyilatkozat szövege: ",
    "group_def_msg": "Most online vagyunk! Tekintse meg szolgáltatás étlapunkat, és foglalja le a következő időpontját még ma.",
    "required_msg_txt": "Üzenet szükséges",
    "required_Key_txt": "Kulcsszavak megadása kötelező",
    "required_mem_msg": "Előfizetői üzenet szükséges",
    "client_list_title": "Ügyféllista",
    "add_contact_txt": "Névjegy hozzáadása elemre",
    "delete_all_clients_txt": "Az összes ügyfél törlése",
    "delete_all_clients_msg": "Biztosan törli az összes ügyfelet? Nem lehet visszakeresni.",
    "delete_all_txt": "Összes törlése",
    "timeline_title": "Idővonal",
    "unsubscribe_title": "Leiratkozás",
    "subscribe_title": "Iratkozz fel",
    "unsubscribe_confirm_msg": "Szeretné ezt az ügyfelet leiratkozóként megjelölni?",
    "subscribe_confirm_msg": "Szeretné ezt az ügyfelet előfizetőként megjelölni?",
    "no_title": "Nem",
    "yes_title": "Igen",
    "client_name_title": "Ügyfél neve",
    "source_title": "Forrás",
    "contact_file_Req": "Névjegyfájl szükséges",
    "req_title": "Kívánt",
    "opt_in_req": "Az importált névjegyeknek 100%-ban feliratkozniuk kell",
    "image_invalid_error": "A fájl érvénytelen.",
    "import_contacts_msg": "Névjegyek importálása",
    "csv_upload_title": "Feltöltendő CSV névjegyfájl *",
    "csv_file_desc": "Csak csv fájlt töltsön fel – csv fájl oszlopai – Keresztnév, Vezetéknév, E-mail azonosító, Mobilszám, Nem, DOB(MM\/NN\/ÉÉÉÉ) – ne legyen szóköz, kötőjel vagy zárójel a mobilszámban",
    "to_download_title": "A demo fájl letöltéséhez kattintson ide",
    "contains_header_title": "Igen, ez a fájl fejléceket tartalmaz",
    "opt_in_100_txt": "Igen, ezek az importált névjegyek 100%-ban feliratkoztak",
    "DisT": "Felelősségi nyilatkozat szövege: ",
    "gMendMsg": "STOP a végéhez. SEGÍTSÉG a segítségért. SMS- és adatátviteli díjak vonatkozhatnak",
    "reqKeyT": "Kulcsszavak megadása kötelező",
    "reqMemMsg": "Előfizetői üzenet szükséges",
    "reqMsgT": "Üzenet szükséges",
    "gMemberMsg": "Úgy tűnik, már a csoportunk tagja vagy!!",
    "gdefMsg": "Most online vagyunk! Tekintse meg szolgáltatás étlapunkat, és foglalja le a következő időpontját még ma.",
    "next_title": "Következő",
    "male_title": "Férfi",
    "male_val": "férfi",
    "female_title": "Női",
    "female_val": "női",
    "others_txt": "Mások",
    "others_val": "mások",
    "validBirthdate": "Adja meg az érvényes születési dátumot",
    "valid_phone_no_title": "Adja meg az érvényes telefonszámot",
    "required_phone_no_title": "Telefonszám megadása kötelező",
    "add_new_client_txt": "Új kliens hozzáadása",
    "update_client_txt": "Frissítse a klienst",
    "phone_num_text": "Telefonszám",
    "dob_title": "DOB",
    "select_gender_title": "Válassza a Nem lehetőséget",
    "timelineTitle": "Idővonal",
    "confirm_location": "Hely megerősítése",
    "feedback_title": "Visszacsatolás",
    "rep_question_1": "Tiszta volt az üzlet?",
    "rep_que_2": "jó volt az ételed?",
    "rep_que_3": "Szolgáltatásunk barátságos volt?",
    "rep_que_4": "Gyors volt a szolgáltatásunk?",
    "business_location_req_title": "Az üzlet helye kötelező",
    "valid_location_err_txt": "Kérjük, válasszon érvényes címet",
    "rep_management_title": "További vélemények kezelése",
    "rep_about_desc": "A hírnév segít növelni a Google értékelését azáltal, hogy visszajelzést kér az ügyfelektől",
    "preview_title": "Előnézet",
    "rep_preview_txt": "Hírnév előnézete",
    "back_title": "Vissza",
    "fine_print_txt": "Kérjük, váltsák be pénztárosnál",
    "redeem_me_title": "Válts meg engem",
    "rep_nxt_visit_txt": "A következő látogatás alkalmával",
    "device_type": "A használt eszköz típusa",
    "connection_method": "Csatlakozási mód",
    "peak_days": "Csúcsnapok",
    "peak_hours": "Csúcsidő",
    "guest_by_day": "Napi vendég",
    "guest_visit": "Vendéglátogatás",
    "connection": "Kapcsolat",
    "connection_duration": "Csatlakozás időtartama",
    "guest_visit_1": "1 alkalommal",
    "guest_visit_2": "2 alkalommal",
    "guest_visit_3_5": "3-5 alkalommal",
    "guest_visit_6_10": "6-10 alkalommal",
    "guest_visit_11_25": "11-25 alkalommal",
    "guest_visit_26_100": "26-100 alkalommal",
    "guest_visit_100_plus": "100+ alkalommal",
    "device_android_total": "Teljes Android",
    "device_android": "Android",
    "device_desktop": "Asztali",
    "device_ios": "Ios",
    "device_ios_total": "Total Ios",
    "device_desktop_total": "Total Desktop",
    "connection_duration_10": "<=10 perc",
    "connection_duration_20": "11-20 perc",
    "connection_duration_30": "21-30 perc",
    "connection_duration_40": "31-45 min",
    "connection_duration_60": "46-60 min",
    "connection_method_email": "Email",
    "connection_method_sms": "SMS",
    "connection_method_google": "Google",
    "connection_method_facebook": "Facebook",
    "age_category_1": "<18",
    "age_category_2": "18-24",
    "age_category_3": "25-34",
    "age_category_4": "35-44",
    "age_category_5": "45-54",
    "age_category_6": "55+",
    "all_guest_txt": "Minden vendég",
    "new_Guest_txt": "Új Vendég",
    "connections_txt": "Kapcsolatok",
    "hotspot": "Hotspot",
    "hotspot_list": "Hotspot lista",
    "add_new_hotspot": "Új hotspot hozzáadása",
    "hotspot_information": "Hotspot információk",
    "edit_details_button": "Részletek szerkesztése",
    "wifi_info_message": "Csatlakozzon és élvezze az ingyenes wifit",
    "connection_message": "Nagyszerű, csatlakozott hozzád ",
    "connection_message_suffix": " WiFi",
    "wifi": "WiFi",
    "login_to_access": "Jelentkezzen be a hozzáféréshez",
    "verification_code": "Ellenőrző kód",
    "verification_code_message": "Kérjük, írja be a címre küldött ellenőrző kódot ",
    "wifi_user_email": "adamo@gmail.com",
    "wifi_label": "Wifi",
    "your_name": "Egyébként mi a neved?",
    "your_birthdate": "Elárulnád, mi a születési dátumod?",
    "request_guest_wifi_name": "Kérjük, adja meg a vendég Wifi nevét",
    "request_device_key": "Kérjük, adja meg az eszközkulcsot",
    "request_maximum_internet_access_length": "Kérjük, válassza ki az Internet hozzáférés maximális hosszát",
    "mac_address": "MAC cím",
    "device_port": "Eszköz port",
    "hardware": "Hardver",
    "current_uptime": "Aktuális üzemidő",
    "nearby_devices": "Közeli eszközök",
    "firmware": "Firmware",
    "who_are_you": "ki vagy te?",
    "where_to_contact_you": "Hol vegyük fel Önnel a kapcsolatot?",
    "your_area_code": "Mi a körzetszámod?",
    "connected": "Csatlakozva",
    "delete_hotspot_message": "Ez a hotspot véglegesen törlődik.",
    "delete_multiple_hotspots_message": "Ezek a hotspotok véglegesen törlődnek.",
    "speed_error": "A sebességnek legalább 0,01-nek kell lennie",
    "speed_max_error": "Kérjük, adjon meg egy 1024-ig terjedő értéket a korlátlan sebességhez, vagy válasszon egy alacsonyabb értéket a támogatott tartományon belül",
    "device_ssid": "Eszköz SSID",
    "device_ssid_two": "Eszköz SSID kettő",
    "device_ssid_two_wpa": "Eszköz SSID Kettő WPA",
    "device_key": "Eszközkulcs",
    "select_location": "Válassza a Hely lehetőséget",
    "select_maximum_internet_access_length": "Válassza a Maximális internet-hozzáférési hosszt",
    "download_speed": "Letöltési sebesség",
    "upload_speed": "Feltöltési sebesség",
    "network_length_1": "15 perc",
    "network_length_2": "30 perc",
    "network_length_3": "45 perc",
    "network_length_4": "1 óra",
    "network_length_5": "2 óra",
    "network_length_6": "4 óra",
    "network_length_7": "6 óra",
    "network_length_8": "8 óra",
    "network_length_9": "10 óra",
    "network_length_10": "12 óra",
    "employee_wifi_name": "Alkalmazott Wifi neve",
    "employee_wifi_password": "Munkavállalói wifi jelszó",
    "guest_wifi_name": "Vendég Wi-Fi neve",
    "menu_other_products_txt": "Egyéb termékek | Menü Online",
    "menu_home_text": "Kezdőlap | Menü Online",
    "whatsapp_title": "Whatsapp",
    "select_campaign_type": "Válasszon kampánytípust",
    "select_readymade_templates": "Válasszon kész sablonok közül, vagy készítse el saját magát",
    "campaign_title_required": "A kampány címe kötelező",
    "type_here": "Írd ide...",
    "location_permission_req": "Helymeghatározási engedély szükséges",
    "platform_permission_req": "Platformengedély szükséges",
    "profile_picture": "Profilkép",
    "click_to_upload": "Kattintson a feltöltéshez",
    "location_permission": "Hely engedélye",
    "pin": "Pin",
    "platform_permission": "Platformengedély",
    "set_pin": "PIN-kód beállítása",
    "list_of_users": "Felhasználók listája",
    "create_user": "Felhasználó létrehozása",
    "terms_and_condition_req": "Feltételek és feltételek kötelezőek",
    "terms_and_conditions": "Általános Szerződési Feltételek",
    "recommended": "AJÁNLOTT",
    "mo": "\/mo",
    "unsubscribe": "Leiratkozás",
    "cancel_subscription": "Biztosan lemondja az előfizetést?",
    "upgrade": "Frissítés",
    "plan_upgraded_successfully": "A terv sikeresen frissítve!",
    "menu_online": "Menuonline",
    "support": "Támogatás",
    "wifiC": "WIFI",
    "billing": "Számlázás",
    "please_upgrade": "Kérjük, frissítse az összes funkciót",
    "you_have_subscribed": "Ön előfizetett a ",
    "plan": "terv",
    "of_text": "-ból",
    "days": "Napok",
    "remaining_days_until_plan": " Napok vannak hátra a terv frissítéséig",
    "manage_your_screens": "Képernyők kezelése",
    "screens_you_save": "Képernyői vannak",
    "add_more_screens": "További képernyők hozzáadása",
    "addition_screen": "Kiegészítés képernyő",
    "per_screen": "$ képernyőnként",
    "per_box": "$ dobozonként",
    "shipping_changes": "Szállítási díjak",
    "upto4_boxes": "akár 4 doboz ",
    "charge_now": "Most kapsz díjat",
    "updated_reoccuring": "A frissített ismétlésed lesz",
    "current_reoccuring": "Jelenlegi ismétlődés: ",
    "no_text": "Nem",
    "android_box": "Android Box",
    "old_password_req": "Régi jelszó szükséges",
    "new_password_req": "Új jelszó szükséges",
    "confirm_password_req": "A jelszó megerősítése szükséges",
    "password_do_not_match": "A jelszavak nem egyeznek",
    "old_password": "Régi jelszó",
    "new_password": "Új jelszó",
    "confirm_password": "Jelszó megerősítése",
    "copy_working_hours": "Másolja ezeket a munkaidőket minden napra?",
    "yes_copy": "Igen, másolni",
    "closed": "Zárt",
    "opening_time": "Nyitva tartás",
    "closing_time": "Záróra",
    "description": "Leírás",
    "file_exist": "fájl már létezik, válasszon másik fájlt",
    "bussiness_images": "Üzleti képek",
    "display_info_on_market_place": "Szeretné megjeleníteni az alábbi információkat a piacterén?",
    "profile_ready": "Elkészült a profilod!",
    "client_book_with_online": "Az ügyfelek mostantól online foglalhatnak Önnél. Oszd meg az alábbi linket a kezdéshez.",
    "copy_link": "Link másolása",
    "see_your_profile": "Nézze meg a profilját",
    "ok_got_it": "Rendben, értem",
    "preview_profile": "Tekintse meg profilja előnézetét, és nézze meg, hogyan fog kinézni.",
    "opening_hours": "Nyitva tartás",
    "display_data": "Adatok megjelenítése",
    "manage_profile": "Profil kezelése",
    "phone_req": "Telefon szükséges",
    "market_place_img_req": "A piactér képei kötelezőek",
    "add_new_market_place": "Új piac hozzáadása",
    "edit_market_place": "Szerkessze a MarketPlacet",
    "no_reviews_yet": "Még nincsenek vélemények",
    "good": "Jó",
    "average": "Átlagos",
    "add_marketPlace": "Piactér hozzáadása",
    "all_title": "Minden",
    "included_title": "Beleértve",
    "excluded_title": "Kizárva",
    "clients_subscribed": "Ügyfelek előfizetett az utolsó",
    "clients_groups": "Ügyfélcsoportok",
    "customer_groups": "Ügyfélcsoportok",
    "audience_title": "Közönség",
    "client_gt5": "aki nem tért vissza az utolsóban",
    "select_all": "Válassza az Összes lehetőséget",
    "modify": "Módosít",
    "campaign_title": "Kampány címe",
    "msg_type": "Üzenet típusa",
    "enter_discount": "Adja meg a kedvezményt",
    "discount_type": "Kedvezmény típusa",
    "coupon_text": "Kupon szövege",
    "enter_coupon_text": "Írja be a kupon szövegét",
    "coupon_button_text": "Kupon gomb szövege",
    "enter_coupon_button_text": "Írja be a kupongomb szövegét",
    "fine_prin": "Fine Print",
    "enter_fine_prin": "Írjon be apró betűs szöveget",
    "campaign_dec": "A kampányt a kezdési időpontja előtt 30 perccel módosíthatja. Az SMS kampány díjazása a kampány kezdete előtt 30 perccel történik.",
    "blast_text_message_dec": "A gyors szöveges üzenet készen áll. Válassza ki a fizetési módot, és küldje el üzeneteit.",
    "payment_completed": " A fizetés időpontja: ",
    "total_cost": "Teljes költség",
    "close_title": "Közeli",
    "friday": "péntek",
    "saturday": "szombat",
    "sunday": "vasárnap",
    "thursday": "csütörtök",
    "tuesday": "kedd",
    "wednesday": "szerda",
    "port_txt": "Kikötő",
    "today_title": "Ma",
    "yesterday_title": "Tegnap",
    "last_30_days_txt": "Az elmúlt 30 nap",
    "this_month_txt": "Ebben a hónapban",
    "last_month_txt": "Múlt hónap",
    "valid_date_title": "Válasszon érvényes dátumot",
    "valid_business_name_txt": "Adja meg a vállalkozás érvényes nevét",
    "req_bus_add_txt": "Az üzleti cím megadása kötelező",
    "req_domain_name_txt": "A domain név megadása kötelező",
    "basic_info_txt": "Vállalkozásának alapinformációi",
    "loyalty_qr_def_txt": "Olvassa be a QR-kódot, csatlakozzon hűségkártyánkhoz, és élvezze a jutalmakat.",
    "last_stamp_txt": "Utolsó bélyegző",
    "collected_on_txt": "-on gyűjtve",
    "stamp_details_txt": "Bélyegző részletei",
    "add_stamp_txt": "Bélyegző hozzáadása",
    "choose_brand_color_txt": "Válassza ki a márka színét és nyelvét 🎨",
    "change_anytime_txt": "Ezt bármikor megváltoztathatja",
    "like_to_get_txt": "Szeretnél kapni",
    "your_next_visit_txt": "következő látogatása alkalmával?",
    "time_to_time_get_offers": "Szeretne időről időre nagyszerű ajánlatokat és promóciókat kapni?",
    "respond_back_to_you": "Szeretnéd, ha valaki válaszolna neked?",
    "input_serve_better": "Hozzájárulása segít csapatunknak abban, hogy jobban kiszolgálja Önt!",
    "do_it_txt": "Csináljuk meg",
    "take_15_s": "15 másodpercet vesz igénybe",
    "rate_on_google_desc": "Lenne olyan kedves, hogy értékeljen minket a google-n?",
    "may_be_next_time": "Talán Legközelebb",
    "thank_you_txt": "Köszönöm",
    "look_next_txt": "Nagyon várjuk a következő látogatását!",
    "deleteUserTitle": "Ez a felhasználó véglegesen törlődik.",
    "deleteMUserTitle": "Ezek a felhasználók véglegesen törlődnek.",
    "change_pin": "Pin módosítása",
    "area_code": "Mi a körzetszámod? ",
    "add_menu": "Menü hozzáadása",
    "menu_name": "Menü neve",
    "add_menu_placeholder": "Pizza, Burger, Baverages stb",
    "enable_data_Collection": "Adatgyűjtés engedélyezése",
    "add_new_menu": "Új menü hozzáadása",
    "rename_menu": "Menü átnevezése",
    "preview": "Előnézet",
    "generate_qr": "Generált QR",
    "edit_menu": "Szerkesztés menü",
    "remove_menu": "Menü eltávolítása",
    "menu_delete_msg": "Ez a menü véglegesen törlődik",
    "menus_delete_msg": "Ezek a menük véglegesen törlődnek",
    "view_menu_dialoage_msg": "Születésnapján szeretnél ajándékot kapni?",
    "skip": "Kihagyás",
    "cliam_your_gift": "Igényelje ajándékát",
    "collect_form": "Űrlap összegyűjtése",
    "enter_first_name": "Írja be a keresztnevet",
    "enter_last_name": "Írja be a vezetéknevet",
    "enter_email": "Írja be az E-mail címet",
    "enter_dob": "Írja be a születési dátumot",
    "enter_number": "Írja be a számot",
    "select_gender": "Válassza a Nem lehetőséget",
    "congratulations_desc": "🎉 Gratulálunk! Ajándékigénylésed sikeresen elküldve. Csapatunk hamarosan felveszi Önnel a kapcsolatot. 🎉",
    "male_heading": "Férfi",
    "male_text": "férfi",
    "female_heading": "Női",
    "female_text": "női",
    "others_heading": "Mások",
    "other_text": "mások",
    "BirthD": "Születési dátum",
    "GenderT": "Nem",
    "EmailT": "Email",
    "dobT": "DOB",
    "capitalize_menu": "Menü",
    "select_menu": "Válassza ki a menüt",
    "manage_variant": "Változat kezelése",
    "add_products": "Termékek hozzáadása",
    "add_category": "Kategória hozzáadása",
    "category_delete": "Ez a kategória véglegesen törlésre kerül",
    "variation_delete": "Ez a változat törlésre kerül a Termékből.",
    "product_delete": "Ez a termék véglegesen törlésre kerül.",
    "categories_delete": "Ezek a kategóriák véglegesen törlődnek.",
    "products_delete": "Ezek a termékek véglegesen törlésre kerülnek.",
    "category": "KATEGÓRIA",
    "price": "ÁR",
    "food_product_placeholder": "Pizza, Burger, Baverages stb",
    "active_title": "Aktív",
    "inActive_title": "Inaktív",
    "status_capital": "ÁLLAPOT",
    "cat_status_require": "A kategória állapota kötelező",
    "cat_name_require": "A kategória neve kötelező",
    "category_name": "Kategória neve",
    "status": "Állapot",
    "lgDay1": "hétfő",
    "lgDay2": "kedd",
    "lgDay3": "szerda",
    "lgDay4": "csütörtök",
    "lgDay5": "péntek",
    "lgDay6": "szombat",
    "lgDay7": "vasárnap",
    "is_closed_title": "Zárt",
    "book_table_title": "Foglaljon asztalt",
    "emailErrSub": "Az e-mail tárgya kötelező",
    "email_subject": "E-mail tárgya",
    "contactUsfrmTitleEmail": "Válasz e-mailre",
    "companyInfo": "A Lorem Ipsum a lorem hrig, a lorem ipsum a fraets. A Lorem Ipsum a lorem hrig, a lorem ipsum a fraets. A Lorem Ipsum a lorem hrig, a lorem ipsum a fraets.",
    "footerSTitle1": "A Menuonline-ról",
    "footerSTitle1Lnk2": "Jellemzők",
    "footerSTitle1Lnk3": "Blog",
    "footerSTitle2": "Jogi",
    "footerSTitle2Lnk1": "Általános Szerződési Feltételek",
    "footerSTitle2Lnk2": "Adatvédelmi szabályzat",
    "footerSTitle3": "Vállalkozásoknak",
    "footerSTitle3Lnk1": "Partnereknek",
    "footerSTitle3Lnk2": "Árképzés",
    "footerSTitle3Lnk3": "Partnerek támogatása",
    "footerCopyRithgTxt": "  Menuonline vagy leányvállalatai",
    "homeTitle": "Otthon",
    "contactTitle": "Lépjen kapcsolatba velünk",
    "aboutTitle": "Rólunk",
    "homeMenuTitle": "Menü",
    "bookTableTitle": "Foglaljon asztalt",
    "vDateT": "Válasszon érvényes dátumot",
    "reqDateT": "A dátum megadása kötelező",
    "dateGtErr": "A dátumnak későbbinek kell lennie a mai dátumnál, vagy azzal egyenlőnek kell lennie",
    "timeInvalid": "Az idő érvénytelen",
    "reqTimeT": "Idő kell",
    "timeInvalidBfr": "Az aktuális időhöz képest legalább 5 perccel későbbi időt válasszon ",
    "PerReq": "A létszám megadása kötelező",
    "validcNoT": "Adja meg az érvényes telefonszámot",
    "reqCrT": "A pénznem megadása kötelező",
    "reqPrT": "Az ár kötelező",
    "reCateT": "Kategória kötelező",
    "reqVrT": "Változat szükséges",
    "reqVrVldT": "Írja be az érvényes változatot ",
    "validLNameT": "Adja meg az érvényes vezetéknevet",
    "sDateInValid": "A kezdő dátum érvénytelen",
    "minmReq": "Minimum szükséges",
    "resLblDate": "Válassza a Dátum lehetőséget",
    "resLblTime": "Válassza az Idő lehetőséget",
    "perT": "Hány főre?",
    "resLblEmail": "Kérem adja meg e-mail címét?",
    "resLblNote": "Foglalási megjegyzés hozzáadása",
    "imageError1MB": "Kérjük, helyezzen be 1 MB-nál kisebb képet",
    "imageError": "Kérjük, helyezzen be 2,5 MB-nál kisebb képet",
    "imageError3Mb": "Kérjük, helyezzen be 3 MB-nál kisebb képet",
    "imageInVldError": "A fájl érvénytelen.",
    "addProMT": "Termék menü",
    "proT": "Termék neve",
    "reqProT": "A terméknév megadása kötelező",
    "proPT": "Termék ára",
    "reservationSuccTitle": "Remek, a foglalás megtörtént",
    "book": "Könyv",
    "custzName": "Testreszabási név",
    "always": "Mindig",
    "proImgReq": "A termék képe kötelező",
    "selCustmT": "Kérjük, válassza a Testreszabás lehetőséget, vagy hozzon létre újat",
    "visStR": "A láthatósági állapot megadása kötelező",
    "avlblScR": "Kérjük, válassza ki a rendelkezésre állás ütemezését",
    "addonPR": "Kérjük, válasszon kiegészítő termékeket",
    "upsellPR": "Kérjük, válasszon továbbértékesített termékeket",
    "markItemR": "Kérjük, válassza ki a termékek megjelölését",
    "caloriesR": "Kalória szükséges",
    "allergensR": "Kérjük, válasszon allergén termékeket",
    "prepInstR": "Előkészítési utasítások szükségesek",
    "staffNR": "A személyzet megjegyzése kötelező",
    "discountR": "Kedvezmény szükséges",
    "validDiscE": "Adja meg az érvényes kedvezményt",
    "disday": "Nappal",
    "plSelDayT": "Kérjük, válassza ki a napot",
    "sTimeReq": "Kezdési időpont szükséges",
    "sTimeInValid": "A kezdési időpont érvénytelen",
    "eTimeReq": "A befejezési idő megadása kötelező",
    "eTimeInValid": "A befejezési idő érvénytelen",
    "sDateReq": "A kezdő dátum kötelező",
    "eDateReq": "A mai napig kötelező",
    "eDateInValid": "A mai napig érvénytelen",
    "disdate": "A dátumtól",
    "disdate1": "A dátumig",
    "disdate2": "Dátumtól",
    "currT": "Valuta",
    "iconR": "Kérjük, válassza ki az elem megjelölése ikont",
    "minT": "Minimális",
    "maxT": "Maximális",
    "itemNT": "Elem neve",
    "itemPT": "Cikk ára",
    "descProT": "Ismertesse a termékét",
    "cateT": "Kategória",
    "selProCateT": "Válassza ki termékkategóriáját",
    "reqMT": "A menü neve kötelező",
    "ViewMenu": "Nézet menü",
    "CopyMenu": "Menü másolása",
    "EditMenu": "Szerkesztés menü",
    "RemoveMenu": "Menü eltávolítása",
    "welcomeMnuTitle": "Üdvözöljük",
    "reviewT": "Vélemények",
    "userErrorMsg": "Elnézést, hiba történt!!!",
    "reqCateT": "A kategória neve kötelező",
    "mngCustT": "Testreszabás kezelése",
    "custNReq": "A testreszabási név megadása kötelező",
    "incReq": "Be kell foglalni",
    "minmValid": "Adjon meg érvényes minimális értéket",
    "maxmReq": "Maximum szükséges",
    "cust": "Testreszabás",
    "crCust": "Új testreszabás létrehozása",
    "custList": "Testreszabási lista",
    "delCustmzT": "Ez a testreszabás véglegesen törlődik.",
    "mkNameR": "Az elem nevének megjelölése kötelező",
    "mkDelT": "Ez a megjelölt elem véglegesen törlődik.",
    "hideText": "Elrejt",
    "showText": "Megmutat",
    "device_mac_txt": "Eszköz Mac",
    "delete_hotspot_txt": "Ez a hotspot véglegesen törlődik.",
    "delete_hotspots_txt": "Ezek a hotspotok véglegesen törlődnek.",
    "emp_wifi_name": "Alkalmazott Wifi neve",
    "max_limit_txt": "Írja be az „1024”-et a sebességhez, hogy korlátlannak jelölje. Ez az érték eltávolítja a korlátokat, így maximális átvitelt tesz lehetővé",
    "device_port_txt": "Eszköz port",
    "firmware_txt": "Firmware",
    "hotspot_info_txt": "Hotspot információk",
    "editDBtn": "Részletek szerkesztése",
    "birth_date": "Születési dátum",
    "di_theme_clr": "A téma színe pontosan 7 számjegyű",
    "color_Invalid_txt": "Válasszon érvényes színkódot",
    "Req_theme_clr": "A téma színe kötelező",
    "select_valid_color_txt": "Kérjük, válasszon érvényes témaszínt",
    "req_redir_link": "Átirányítási hivatkozás szükséges",
    "val_redir_link": "Adjon meg érvényes átirányítási hivatkozást",
    "req_business_name_txt": "A cégnév megadása kötelező",
    "splash_preview": "Splash előnézet",
    "create_new_splash": "Új Splash létrehozása",
    "splash_page": "Splash oldal",
    "splash_page_builder": "Splash Page Builder",
    "redirect_link": "Link átirányítása",
    "theme_color": "Téma színe",
    "enable_social_login": "Engedélyezze a közösségi média opciókat a felhasználók bejelentkezéséhez",
    "google": "Google",
    "facebook": "Facebook",
    "is_mandatory": "Kötelező?",
    "field": "Mező",
    "name": "Név",
    "first_name": "Keresztnév",
    "last_name": "Vezetéknév",
    "birthdate": "Születési dátum",
    "gender": "Nem",
    "email": "Email",
    "dob": "DOB",
    "zip_code": "Irányítószám",
    "required_redirect_link": "Átirányítási hivatkozás szükséges",
    "valid_redirect_link": "Adjon meg érvényes átirányítási hivatkozást",
    "required_theme_color": "A téma színe kötelező",
    "theme_color_length": "A téma színe pontosan 7 számjegyű",
    "required_name": "A név megadása kötelező",
    "delete_splash_message": "Törölni szeretnéd ezt a splasht?",
    "delete_multiple_splashes_message": "Törölni szeretné ezeket a fröccsenéseket?",
    "user_login_required": "Felhasználói bejelentkezés szükséges",
    "set_theme_color": "Állítsa be a téma színét",
    "splash_colllect_additional_info_txt": "További információk gyűjtése",
    "verify_mobile_otp_message": "Szeretne OTP-t küldeni a mobilszám ellenőrzésére?",
    "add_company": "Cég hozzáadása lehetőségre",
    "edit_company": "Cég szerkesztése",
    "ds_device": "Digital Signage Device",
    "ds_device_activation_fee": "Digital Signage eszköz aktiválási díja",
    "wifi_hardware": "Wifi hardver",
    "menu_design": "Menü tervezés",
    "ds_hardware": "Digital Signage hardver",
    "company_name_req": "A cégnév megadása kötelező",
    "known_name_req": "Ismert név kötelező",
    "no_location_req": "A helyszín száma kötelező",
    "minimum_location": "Kérjük, adjon meg legalább 1 helyet",
    "terms_req": "Feltételek szükségesek",
    "notes_req": "Megjegyzések szükségesek",
    "sms_charge_req": "SMS-díj szükséges",
    "menuonline_quantity_req": "Menü Online Mennyiség kötelező",
    "menuonline_cost_req": "A menü Online költsége kötelező",
    "ds_quantity_req": "A digitális jelzések mennyisége kötelező",
    "ds_monthly_cost_req": "A digitális jelzések havi költsége kötelező",
    "reputation_quantity_req": "Hírnév A mennyiség megadása kötelező",
    "reputation_cost_req": "A hírnév költsége kötelező",
    "marketing_quantity_req": "Marketing Mennyiség kötelező",
    "marketing_cost_req": "Marketingköltség szükséges",
    "tax_percentage_req": "Adószázalék megadása kötelező",
    "router_hardware_quantity_req": "A router hardver mennyisége kötelező",
    "wifi_hardware_cost_req": "WIFI hardverköltség szükséges",
    "activation_cost_req": "Aktiválási költség szükséges",
    "wifi_quantity_req": "WIFI mennyiség szükséges",
    "wifi_router_req": "Wifi router szükséges",
    "menu_design_quantity_req": "Menütervezés A mennyiség megadása kötelező",
    "menu_design_cost_req": "A menü tervezési költsége kötelező",
    "quantity_req": "Mennyiség szükséges",
    "cost_req": "Költség szükséges",
    "sheduledown_quantity_req": "Az ütemezés csökkentése szükséges",
    "sheduledown_cost_req": "Lejárati költség szükséges",
    "loyalty_program_quantity_req": "A hűségprogram mennyisége kötelező",
    "loyalty_program_cost_req": "A hűségprogram költsége kötelező",
    "loyalty_program_sms_quantity_req": "Hűségprogram SMS Mennyiség szükséges",
    "loyalty_program_sms_cost_req": "A hűségprogram SMS költsége kötelező",
    "comapny_created": "A cég sikeresen létrejött",
    "comapny_updated": "A cég sikeresen frissítve",
    "billing_info": "SZÁMLÁZÁSI INFORMÁCIÓK",
    "business_name_acc_holder": "Vállalkozás neve (számlatulajdonos)",
    "client_first_name": "Ügyfél keresztneve",
    "client_last_name": "Ügyfél vezetékneve",
    "dba_known_as": "DBA néven ismert",
    "business_address": "Üzleti cím",
    "no_of_locations": "Helyek száma",
    "package_details": "CSOMAG RÉSZLETEK",
    "terms": "Feltételek",
    "one_month": "1 hónap",
    "per_sms_charge": "SMS díjonként",
    "plan_test": "TERV: TESZT",
    "desc_capital": "LEÍRÁS",
    "qty_capital": "MENNY",
    "cost_capital": "KÖLTSÉG",
    "total_price_capital": "TELJES ÁR",
    "quantity": "Mennyiség",
    "can_not_access": "Nem választhat többet, mint",
    "licenses": "engedélyeket",
    "monthly_cost": "Havi költség",
    "plan_cost": "Terv költsége",
    "tax": "Adó",
    "tax_percentage": "Adószázalék",
    "monthly_cost_after_tax": "Havi költség adózás után",
    "activation_one_time_charge": "Aktiválás és egyszeri töltés",
    "sub_total": "RÉSZÖSSZESEN",
    "action": "Akció",
    "cost": "Költség",
    "shipping_charge": "Szállítási díj",
    "other": "Más",
    "additional_cost": "További költségek (egyéb költségek)",
    "other_tax": "Egyéb adó",
    "total": "Teljes",
    "license_statistics": "Licenc statisztika",
    "total_licenses": "Összes licenc",
    "available_licenses": "Rendelkezésre álló licencek",
    "stock": "Készlet",
    "has_permission": "Engedélye van",
    "avg_stock_price": "AVG részvényárfolyam",
    "average_price": "Átlagár",
    "allocated": "Kiosztva",
    "reward_regulars": "Jutalmazza a törzsvendégeket",
    "not_add_coupon_url": "A kupon URL-címe nem adható hozzá. Az üzenet maximális hossza 160",
    "like_attach_coupon": "Szeretnél kupont csatolni?",
    "advance_scheduling": "Előzetes ütemezés",
    "choose_day": "Válassza ki az üzenet küldésének napját",
    "select_essage_window": "Válassza az Üzenetablak lehetőséget",
    "subscription_outside_delivery_window": "Ha egy felhasználó a kézbesítési időszakon kívül iratkozik fel, a következő elérhető időpontban megkapja az automatikus válaszadót.",
    "remaining": "Többi",
    "processing_request": "Hiba történt kérésének feldolgozása közben.",
    "list_companies": "Cégek listája",
    "are_you_sure": "Biztos vagy benne..?",
    "signin_agreement": "Ennek megerősítésével a társaság szerződés aláírása nélkül hoz létre.",
    "status_updated": "Sikeres állapotfrissítés",
    "status_failed": "Nem sikerült frissíteni az állapotot",
    "new_editor_status_updated": "A DS New Editor állapota sikeresen frissítve.",
    "user_name": "Felhasználónév",
    "known_as": "néven ismert",
    "agreement_signed": "Megállapodás aláírva",
    "deactivate": "Deaktiválás",
    "activate": "Aktiválja",
    "login_to_user_acc": "Jelentkezzen be a felhasználói fiókba",
    "disable": "Letiltás",
    "enable": "Engedélyezés",
    "ds_new_editor": "DS új szerkesztő",
    "sign_agreement": "Aláírja a megállapodást",
    "agreement_sign": "Megállapodás W\/O aláírással",
    "view_agreement": "Megállapodás megtekintése",
    "download_agreement": "Letöltési megállapodás",
    "add_deleted_user": "Törölt felhasználó hozzáadása",
    "deleted_user": "Felhasználó törlése",
    "favourite": "Kedvenc",
    "refresh": "Frissítés",
    "delete_chat": "Csevegés törlése",
    "responsive_drawer": "Reszponzív fiók",
    "delete_contact": "Névjegy törlése",
    "clear_chat_history": "Csevegési előzmények törlése",
    "clear_chat_history_dec": "Biztosan törli ezt a csevegést?",
    "clear_contact_dec": "Biztosan törli ezt a névjegyet?",
    "select_contact": "Válassza a Kapcsolat lehetőséget",
    "new_conversation": "Új beszélgetés indítása",
    "type_msg": "Írja be üzenetét ide",
    "select_delete_client": "Kérjük, válassza ki a törölni kívánt klienst",
    "select_delete_client_chat": "Kérjük, válassza ki az ügyfelet a csevegés törléséhez",
    "select_delete_client_chat_err": "Nem cseveg ezzel az ügyféllel",
    "acquire_customers_business": "Szerezzen új ügyfeleket más vállalkozásoktól.",
    "customers_from_events": "Szerezzen ügyfeleket az eseményekről és vigye el Önhöz!",
    "customers_from_ads": "Szerezzen ügyfeleket az újsághirdetésekből.",
    "smart_pamphlets": "Készítsen intelligens füzeteket az ügyfelek adatainak gyűjtéséhez.",
    "smart_qr_group": "Intelligens QR-kód az ügyfelek csoportjához való csatlakozáshoz.",
    "opt_in_number": "Engedélyezze az ügyfelek számára a szám megadását.",
    "qr_menu": "Készítsen QR-kódot a menü megjelenítéséhez.",
    "collect_birthdays": "Gyűjtsd össze az ügyfél születésnapját és évfordulóját.",
    "join_loyalty_program": "Szerezzen új ügyfeleket a hűségprogramomhoz.",
    "create_feedback_forms": "Hozzon létre űrlapokat az ügyfelek visszajelzéseinek gyűjtéséhez.",
    "exclusive_discounts_promotions": "Exkluzív kedvezmények és promóciók a keresztpromóciókhoz.",
    "group_smart_qr_opt_in": "A csoport létrehozása után létrehozhat és kiválaszthat egy intelligens QR-kódot, amely lehetővé teszi az ügyfelek számára, hogy azonnal jelentkezzenek egy csoportba.",
    "group_smart_qr_opt_in_dec": "A csoport létrehozása után a Kép widget lehetővé teszi az ügyfelek számára, hogy feliratkozhassanak szöveges használatra, és promóciókat kapjanak, például a „pizza” szöveget az 555-555-5555-ös számra.",
    "in_store_discount_next_visit": "Szerezzen kedvezményt az üzletben, amikor legközelebb meglátogatja telephelyünket",
    "delivery_redirection": "Hozzon létre kézbesítési átirányítást",
    "additional_info": "További információk",
    "add_url": "URL hozzáadása",
    "custom_fields": "Egyéni mezők",
    "meta_tag": "Meta tag",
    "max_chars": "Max karakter",
    "add": "Hozzáadás",
    "update_campaign": "Kampány frissítése",
    "last_week_required": "múlt hét szükséges",
    "large_week_value": "A heti érték túl nagy, írja be az érvényes hetet",
    "less_than_last_week": "A múlt héten vissza nem térőnek kevesebbnek kell lennie, mint a múlt héten.",
    "last_week_req": "A múlt héten nem kell visszaküldeni",
    "birthday_special": "Születésnapi különlegesség",
    "birthday_message": "Születésnapjukon kiküldött automatikus üzenetek személyre szabott üdvözletekkel és csábító ajánlatokkal",
    "birthday_sms": "Hozzon létre személyre szabott születésnapi üdvözletet SMS-hez\/MMS-hez",
    "target_audience": "Válassza ki a célközönséget",
    "client_by_birthday": "Ügyfél születésnapja szerint",
    "clients_once_year": "Ezt a kampányt évente egyszer automatikusan elküldik az ügyfeleknek ",
    "auto_responder_default_msg_1": "Ön az egyik legjobb vásárlónk, akinek szeretünk kedvezményt adni! Legyél mindig gazdag és egészséges. Boldog születésnapot! %% keresztnév %%",
    "campaign_send_to_client_birthday": "Ez az automatikus kampány születésnapos ügyfeleknek küldi el, a célzást alább testreszabhatja.",
    "get_more_engagement": "Szerezzen elköteleződést szülinapi SMS-ek küldésével 🎂",
    "welcome_new_clients": "Üdvözöljük az új ügyfeleket",
    "make_lasting_impression": "Maradandó benyomást kelt azzal, hogy melegen köszönti és fogadja az első ízben étkezőket",
    "personalized_greetings": "Hozzon létre személyre szabott üdvözletet az új ügyfelek számára",
    "greetings_to_new_customers": "Ez a kampány üdvözletet küld az új ügyfeleknek a vállalkozásával való első interakció másnapján",
    "audience_predefined": "Ennek az automatikus válaszadónak a közönsége előre meghatározott, ez megvan!",
    "send_clients_subscribed": "Ez az automatikus válasz úgy van beállítva, hogy elküldje a legutóbbi előfizetéssel rendelkező ügyfeleket ",
    "first_interaction": "  első interakciójuk után.",
    "default_msg_2": "Reméljük, hogy tetszett az első látogatása, hamarosan újra találkozunk, ezért kedvezményt biztosítunk Önnek! Kattintson a linkre, hogy lefoglalja következő időpontját nálunk, és most váltsa be különleges online ajánlatát.",
    "new_clients_update": "Üdvözöljük, új ügyfelek frissítik a kampányszöveget",
    "new_clients_warm_greeting": "Üdvözöljük új ügyfeleinket meleg üdvözlettel 💌",
    "win_back_clients": "Nyerj vissza ügyfeleket",
    "re_engage_past_customers": "Vonja vissza korábbi ügyfeleit visszautasíthatatlan ajánlatokkal, hogy ösztönözze őket a visszatérésre",
    "target_disengaged_clients": "Célozza meg az elszakadt ügyfeleket, hogy különleges ajánlattal térjenek vissza, ami egy bevált taktika az ügyfelek visszacsábítására.",
    "campaign_sends_to_clients": "Ez a kampány azoknak az ügyfeleknek szól, akik bizonyos idő elteltével nem tértek vissza.",
    "customize_targeting": "Testreszabhatja a célzást alább, hogy SMS-t\/MMS-t küldjön a lejárt ügyfeleknek.",
    "clients_with_at_least": "Ügyfelek legalább ",
    "connection_last": " kapcsolat az utolsóban ",
    "return_in_last_week": " héten, de a múltkor nem tért vissza ",
    "arr_menu_44": " hét",
    "df_msg_4": "Annyira vissza akarjuk kapni, hogy 10% kedvezményt adunk a visszaküldésért! Szeretné még ma kipróbálni velünk?",
    "update_campaign_text": "Nyerje vissza az ügyfeleket, frissítse a kampány szövegét",
    "re_invite_customers": "Hívja meg újra azokat az ügyfeleket, akik egy ideje nem jöttek vissza",
    "loyal_patrons": "Különleges kedvezményekkel és hűségjutalmakkal fejezze ki elismerését a hűséges mecénásoknak",
    "surprize_top_spenders": "Lepje meg a legtöbbet költőket személyre szabott és különleges ajánlatokkal.",
    "campaign_automatically_send": "Ez a kampány automatikusan jutalmakat oszt ki, hogy növelje vállalkozása lábhullását.",
    "sms_loyal_clients": "Ez a kampány SMS\/MMS-t küld hűséges ügyfeleknek, a célzást lent módosíthatja.",
    "or_more_connection": " vagy több kapcsolat, az utolsó ",
    "week_period": " heti időszak.",
    "default_msg_5": "Ön már régóta vásárlója! Hú, repül az idő! Nagyra értékeljük, és szeretnénk megköszönni, hogy velünk vagy.",
    "reward_regulars_update": "Jutalom rendszeres frissíti a kampány szövegét",
    "special_discounts": "Értékeld és adj különleges kedvezményeket💎",
    "leave_positive_online": "Ösztönözze az ügyfeleket, hogy írjanak pozitív online véleményeket az étterem hírnevének növelése érdekében",
    "clients_service_online": "A kampány arra ösztönzi ügyfeleit, hogy online értékeljék szolgáltatását.",
    "increase_your_company": "Ezt az automatikus válaszadót azért küldtük, hogy növelje cége online minősítését.",
    "send_message_after": "Üzenet küldése után ",
    "min_connection": " a kapcsolat min",
    "default_msg_6": "Reméljük, hogy tetszett az első látogatás, hamarosan újra találkozunk! [URL] Kattintson a linkre, hogy értékeljen minket a Google-on.",
    "ratings_update": "További értékelések frissítési kampányszöveg",
    "friendly_nudge": "Szerezzen még több véleményt egy barátságos bökéssel ⭐",
    "thanks_for_visiting": "Köszönjük látogatását",
    "express_gratitude": "Szívből jövő köszönő üzenetekkel fejezze ki háláját az ügyfeleknek, hogy az Ön vállalkozását választották",
    "thank_you_message": "Hozzon létre köszönő üzenetet ügyfelei számára.",
    "clients_for_visiting": "Küldjön meleg köszönetet ügyfeleinek, hogy ellátogattak hozzád.",
    "campaign_audience": "Ez az automatikus kampány közönsége előre meghatározott, megvan ez!",
    "campaign_automatically_sends": "Ez az automatikus kampány automatikusan elküldi ezt követően ",
    "minutes_customer_visit": " percnyi ügyféllátogatás",
    "default_msg_7": "Köszönjük, hogy tisztelt ügyfelünk vagy. Nagyon hálásak vagyunk az örömért, hogy szolgálhattuk Önt, és reméljük, hogy megfeleltünk elvárásainak.",
    "visiting_update_campaign": "Köszönjük, hogy meglátogatta a kampányszöveg frissítését",
    "guests_thank_you": "Köszönjük Vendégeinek látogatásukat 😊",
    "download_title": "Letöltés",
    "qr_gen_title": "QR generálva",
    "qr_download_s_title": "A QR-kód sikeresen letöltve",
    "sel_dark_brand_clr_msg": "Kérjük, válasszon érvényes barnd színt",
    "manage_customer_stamp_rewards": "Ügyfélbélyegző jutalmak kezelése 🎁",
    "sel_loc_menu_title": "Válasszon helyet a menühöz",
    "ans_req": "Válasz szükséges",
    "valid_reputation_name": "Adja meg az érvényes hírnév nevét",
    "reviews_name_req": "További vélemények kérése név megadása kötelező",
    "birthdate_required": "Születési dátum megadása kötelező",
    "gender_required": "A nem kötelező",
    "valid_birthdate_required": "Adja meg az érvényes születési dátumot",
    "custom_delivery_redirection": "Hozzon létre egyéni kézbesítés-átirányítási űrlapot csoportok szerint",
    "customer_fields_incentive_settings": "Ügyfélmezők és ösztönző beállítások",
    "delivery_redirection_text": "Kézbesítés átirányítása",
    "delivery_redirection_success": "Gratulálunk! A kézbesítés-átirányítási widgete elkészült! 🎉",
    "swipe_to_preview_redirection": "Csúsztassa ujját, hogy megtudja, hogyan fog kinézni a kézbesítési átirányítási űrlap",
    "enter_item_name": "Írja be az elem nevét",
    "home_page_text": "Kezdőlap szövege",
    "settings": "Beállítások elemre",
    "anniversary_required": "Az évforduló dátuma kötelező",
    "valid_anniversary": "Adja meg az érvényes évforduló dátumát",
    "form_submited": "űrlap sikeresen elküldve",
    "notifications": "Értesítések",
    "discount_message": "Igényelje 20% kedvezményét a Pizzára most!",
    "is_required": " szükséges",
    "section_title_required": "A szakasz címe kötelező",
    "section_dec_required": "A szakasz leírása kötelező",
    "client_details_required": "Az ügyféladatok mezőket kötelező kitölteni",
    "compliance": "Megfelelés",
    "SMS_campaign1": "SMS kampány 1",
    "mobile_number_mandatory": "A mobilszám megadása kötelező",
    "new_answer": "Új válasz",
    "new_question": "Új kérdés",
    "add_new_question": "Új válasz hozzáadása",
    "select": "Válassza ki",
    "group_customers_question": "Hová szeretné csoportosítani ügyfeleit?",
    "contacts_added_to_group": "Az űrlapot beküldő összes kapcsolattartó bekerül a kiválasztott csoportba",
    "edit_client_details_section": "Ügyféladatok szakasz szerkesztése",
    "client_details_fields": "Ügyféladatok mezők",
    "enter_client_details_section_title_desc": "Adja meg az ügyfél adatai szakasz címét és leírását",
    "choose_fields_displayed_client_side": "Válassza ki a kliens oldalon megjelenítendő mezőket",
    "section_title": "Szakasz címe",
    "add_title": "Cím hozzáadása",
    "section_description": "szakasz leírása",
    "add_description": "Leírás hozzáadása",
    "enter_program_name_location": "Adja meg a program nevét és helyét 📍",
    "brand_theme_clr_txt": "Márka téma színe",
    "set_loyalty_punch_card_theme": "Állítsunk be témát a hűségkártyádhoz 🎨",
    "upload_logo_txt": "Töltse fel logóját",
    "recommended_image_specs": "A legjobb megjelenítés érdekében 512 x 512 képpont méretű képet javasolunk. JPG, SVG vagy PNG. Maximális méret 10 MB.",
    "valid_rep_name_txt": "Adja meg az érvényes hírnév nevét",
    "rep_name_req_txt": "További vélemények kérése név megadása kötelező",
    "que_req": "Kérdés kötelező",
    "day_of_birthday": "születésnapján",
    "day_before_birthday": "3 nappal születésnap előtt",
    "week_before_birthday": "születésnap előtt egy héttel",
    "two_week_before_birthday": "születésnapja előtt két héttel",
    "de_active": "De-Active",
    "campaign_details": "A kampány részletei",
    "link_clicked": "Link kattintott",
    "history": "Történelem",
    "auto_responder_summary": "Auto Responder Summary",
    "vsop_1": "15 perc",
    "vsop_2": "30 perc",
    "vsop_3": "45 perc",
    "vsop_4": "60 perc",
    "vsop_5": "90 perc",
    "vsop_6": "120 perc",
    "in_the_last": " az utolsóban ",
    "return_in_last": " de nem tért vissza utoljára ",
    "of_the_connection": "a kapcsolatról",
    "your_campaign": "Az Ön kampánya",
    "you_have_successfully": "Sikeresen sikerült",
    "published_successfully": "sikeresen közzétett",
    "updated_smart_campaign": "frissítette az intelligens kampányt",
    "almost_done_text": "Majdnem kész!",
    "update_campaign_desc": "Az automatikus válaszadó elkészült, engedélyezése után folyamatosan üzeneteket küld az ügyfeleknek. Ez a kampány bármikor könnyen módosítható vagy szüneteltethető.",
    "update_and_publish": "Frissítés és közzététel",
    "reset_campaign_title": "Véglegesen törli a kampány egyéni szövegét, képét, kedvezményes ajánlatait és célközönségét!",
    "space_wifi": " WiFi",
    "custom_forms": "Egyedi űrlapok",
    "web_e_signup": "Webes e-regisztráció",
    "import": "Importálás",
    "permissions_req": "Engedélyek szükségesek",
    "redeemed": "Megváltva",
    "coupon_already_redeemed": "A kupon már beváltva",
    "autoresponder_campaigns": "Automatikus válasz kampányok",
    "autoresponder_campaign_desc": "Engedélyezze az automatikus választ, és indítsa el a személyre szabott kampányt",
    "mon": "MON",
    "tue": "KED",
    "wed": "HÁZASODIK",
    "thu": "Cs",
    "fri": "FRI",
    "sat": "ÜLT",
    "sun": "NAP",
    "duplicate": "Másolat",
    "visibility": "Láthatóság",
    "availability": "Elérhetőség",
    "out_stok": "Elfogyott",
    "edit_product": "Termék szerkesztése",
    "create_product": "Hozzon létre egy terméket",
    "basic_info": "Alapvető információk",
    "basic_sub_info": "A Lorem Ipsum egyszerűen a nyomtatás hamis szövege...",
    "enter_product_price": "Adja meg a termék árát",
    "upload_image": "Kép feltöltése",
    "allowed_file_formats": "Csak a legfeljebb 3 MB fájlmérettel rendelkező JPG és PNG fájlok engedélyezettek.",
    "pre_select": "Előválasztás",
    "promotions": "Promóciók",
    "discount_availability": "Kedvezmény elérhetősége",
    "start_time": "Kezdési idő",
    "end_time": "Vége",
    "select_day": "Válassza a Nap lehetőséget",
    "menu_required": "A menü kötelező",
    "generated": "Generált",
    "link": "Link",
    "variations": "Variációk",
    "select_variant_to_add": "Válassza ki a hozzáadni kívánt változatot",
    "price_title": "Ár",
    "choose_image": "Válassza a Kép lehetőséget",
    "select_visiblity": "Válassza a Láthatóság lehetőséget",
    "availability_schedule": "Elérhetőségi ütemterv",
    "add_on_upsell": "Add on \/ Upsell",
    "add_on_select_product": "Hozzáadás a termék kiválasztásához",
    "upsell_product": "Felárértékesítés Termék kiválasztása",
    "variant_deletion_warning": "Ez a változat véglegesen törlődik.",
    "search_variations": "Keresési változatok",
    "add_variation": "Variáció hozzáadása",
    "variation_text": "Változat",
    "select_customization": "Válassza ki a testreszabást",
    "add_new": "Új hozzáadása",
    "delete_customization_warning": "Törli ezt a testreszabást a termékből?",
    "nutritional_allergen_info": "Táplálkozási és allergén információk",
    "mark_item": "Tétel megjelölése",
    "calories_text": "Kalória",
    "allergens": "Allergének",
    "select_allergens": "Válasszon allergéneket",
    "special_instructions": "Különleges utasítások",
    "preparation_instructions": "Elkészítési útmutató",
    "staff_notes": "A személyzet megjegyzései",
    "checkbox1": "Jelölőnégyzet, amellyel engedélyezheti vagy letilthatja az ügyfelektől érkező speciális utasításokat ehhez a menüponthoz.",
    "menu_c": "MENÜ",
    "past_design": "Múltbeli tervezés",
    "file_name": "Fájlnév",
    "variation_name": "Változat neve",
    "grid_items_title1": "Asztali sátor",
    "grid_items_title2": "Digitális menü",
    "grid_items_title3": "Elvitel menü",
    "grid_items_title4": "Féloldalas menü",
    "grid_items_title5": "Digitális menü 5",
    "grid_items_title6": "Digitális menü 6",
    "grid_items_title7": "Digitális menü 7",
    "grid_items_title8": "Digitális menü 8",
    "grid_items_title9": "Digitális menü 9",
    "enter_valid_form_name": "Adja meg az érvényes űrlapnevet",
    "form_name_required": "Az űrlapnév megadása kötelező",
    "enter_valid_section": "Adja meg az érvényes szakaszcímet",
    "fraction_half": "1\/2",
    "fraction_two_by_two": "2\/2",
    "form_name": "Űrlap neve",
    "contact_list": "Névjegylista",
    "label_notify_email": "Szeretne e-mailben értesítést kapni?",
    "add_client_details_section": "Ügyféladatok szakasz hozzáadása",
    "demo_purpose_only": "Csak demó céljára szolgál",
    "form_updated_success": "űrlap sikeresen frissítve",
    "form_creat_success": "űrlap sikeresen létrehozva",
    "add_custom_section": "Egyéni szakasz hozzáadása",
    "edit_custom_section": "Egyéni szakasz szerkesztése",
    "forms_list": "Űrlapok listája",
    "filled_custom_form_details": "Kitöltött egyéni űrlap részletei",
    "custom_form_deletion_warning": "Ez az egyéni űrlap véglegesen törlődik",

    "menu_link": "Menü hivatkozás",
    "WebSignUpT": "E-regisztráció",
    "lifeTConT": "Életre szóló kapcsolatok",
    "repCustT": "Ismételt ügyfelek",
    "liftTSubT": "Életre szóló előfizetők",
    "overallT": "ÁTFOGÓ",
    "loginCustBDT": "Naponta bejelentkezett ügyfelek",
    "deviceT": "Eszköz demográfiai adatai",
    "ageTitle": "Életkori demográfia",
    "ageT": "Korcsoport",
    "convert_social_media_followers_into_customers": "A közösségi média követőit ügyfelekké alakíthatja",
    "signWidDesc": "Hozzon létre egy widgetet a regisztrációhoz, a céloldalak létrehozásához és a kuponok generálásához webhelyéről vagy közösségi médiából",
    "qrWidT": "Intelligens QR",
    "imgWidT": "Szám megadása",
    "mobile": "Mozgó",
    "YESTitle": "IGEN",
    "NOTitle": "NEM",
    "addDelRed": "Kézbesítési átirányítás hozzáadása",
    "dlredNameT": "Nevezze el a kézbesítés átirányítását",
    "conf_text_req": "Megerősítő szöveg kötelező",
    "cFDesc": "Hozzon létre személyre szabott űrlapokat, hogy hatékonyan gyűjtsön konkrét visszajelzéseket és információkat az ügyfelektől",
    "pre_review_questions_enabled": "Mielőtt véleményt kérnénk az ügyfelektől, tegyünk fel nekik 1-4 kérdést.",
    "feel_free_edit": "Szükség szerint szerkessze nyugodtan.",
    "option": "Opció",
    "confFL": "Ez a visszaigazolás akkor jelenik meg, ha a kapcsolattartó elküldi a webes regisztrációs űrlapot",
    "confT": "Megerősítő szöveg",
    "joinTitle": "Csatlakozik",
    "mobValidL": "Kérjük, ellenőrizze az érvényes mobilszámot",
    "mobRequiredL": "Mobil szám szükséges",

    "Business_location_label": "Az Ön vállalkozásának helye",
    "qr_code_selection_txt": "QR-kód kiválasztása",
    "choose_theme_txt": "Válassza a Témát",
    "edit_qr_details_txt": "QR-adatok szerkesztése",
    "save_qr_code_txt": "Mentse el a QR-kódot",
    "text_color_label": "Szöveg színe",
    "background_color_label": "Háttérszín",
    "print_txt": "Nyomtatás",
    "customer_will_see_txt": "Ezt fogja látni ügyfele. A következő lépésben válassza ki a márka színét.",
    "choose_your_brand_color_&_langauge_title": "Válassza ki a márka színét és nyelvét",
    "custom_redirect_link_label": "Egyéni átirányítási hivatkozás",
    "redirect_user_to_menu": "A felhasználó átirányítása a menübe",
    "language": "Nyelv",
    "select_language_placeholder": "Válassza a Nyelv lehetőséget",
    "capitalize_location_name_txt": "Hely neve",
    "created_txt": "Létrehozva",
    "swipe_reputation_management_txt": "Csúsztassa ujját, hogy megnézze, hogyan fog kinézni a hírnévkezelése",
    "sent_txt": "Küldött",
    "number_label": "Szám",
    "date_label": "Dátum",
    "gender_demographics_title": "Nemi demográfia",

    "step1_preview_desc": "Nevezze el az e-regisztrációs QR-űrlapot, és adja hozzá a csoportlistájához",
    "main_head": "Szerezzen új ügyfeleket",
    "main_desc": "Szerezzen ügyfeleket egy QR-kód elhelyezésével, amely beolvasáskor átirányítja a felhasználókat egy személyre szabott regisztrációs űrlapra.",
    "step1_description": "Szerezzen új ügyfeleket más vállalkozásoktól",
    "form_name_field_placeholder": "Restro E-regisztráció",
    "last_step_title": "Gratulálunk! Elkészült az E-regisztrációs widget! 🎉",
    "last_step_desc": "Csúsztassa ujját, hogy megtudja, hogyan fog kinézni az e-regisztrációs űrlap",
    "events_step1_preview_desc": "Nevezze el az esemény QR-űrlapját, és adja hozzá a csoportlistájához",
    "events_main_head": "Szerezzen ügyfeleket az eseményekről",
    "events_main_desc": "Szerezzen ügyfeleket azáltal, hogy QR-kódot helyez el az eseményen. A beolvasás után a felhasználók egy személyre szabott regisztrációs űrlapra irányítják át.",
    "events_step1_desc": "Ragadja meg az ügyfeleket az eseményekről, és vigye be őket az üzletbe",
    "events_name": "Esemény neve",
    "events_form_name_field_placeholder": "Restro szórakozás és étkezési esemény",
    "events_last_step_title": "Gratulálunk! Eseményűrlapod elkészült! 🎉",
    "events_last_step_desc": "Csúsztassa ujját, hogy megtudja, hogyan fog kinézni az eseményűrlap",
    "birthday_anniversary_step1_preview_desc": "Nevezze el QR-űrlapját az ügyfél születésnapjának vagy évfordulójának összegyűjtéséhez, és adja hozzá a csoportlistájához",
    "birthday_anniversary_main_head": "Gyűjtsd össze a B'day-t vagy az Anniversary-t",
    "birthday_anniversary_main_desc": "Az Ügyfél születésnapja vagy évfordulója beolvasásakor átirányítja a felhasználókat egy személyre szabott regisztrációs űrlapra.",
    "birthday_anniversary_step1_desc": "Gyűjtsd össze az ügyfél születésnapját és évfordulóját",
    "restro_text": "Restro",
    "birthday_anniversary_last_step_title": "Gratulálunk! Get B'day & Anniversary ügyfelek űrlapot készített! 🎉",
    "newspaper_ads_step1_preview_desc": "Nevezze el QR-űrlapját, hogy ügyfeleket szerezzen az újsághirdetésekből, és hozzáadja a csoportlistájához",
    "newspaper_ads_main_head": "Szerezzen ügyfeleket a hirdetésekből",
    "newspaper_ads_main_desc": "Szerezzen ügyfeleket az újsághirdetésekből, amikor beolvassák, átirányítja a felhasználókat egy személyre szabott regisztrációs űrlapra.",
    "newspaper_ads_step1_desc": "Szerezzen ügyfeleket újsághirdetésből vagy kültéri hirdetésből",
    "newspaper_ads_last_step_title": "Gratulálunk! Szerezzen ügyfeleket a létrehozott újsághirdetési űrlapról! 🎉",
    "smart_pamphlets_step1_preview_desc": "Nevezze el QR-űrlapját, hogy vásárlói adatokat kapjon a brosúrákból, és hozzáadja a csoportlistához",
    "smart_pamphlets_main_head": "Szerezzen ügyfeleket brosúrákból",
    "smart_pamphlets_main_desc": "Szerezze be az ügyfelek adatait a brosúrákból, és a beolvasáskor átirányítja a felhasználókat egy személyre szabott regisztrációs űrlapra.",
    "smart_pamphlets_last_step_title": "Gratulálunk! Szerezze be az ügyfél adatait a létrehozott füzetekből! 🎉",
    "social_media_step1_preview_desc": "Nevezze el QR-űrlapját, hogy az ügyfeleket a közösségi médiából az ügyfelekhez vonzza, és hozzáadja a csoportlistájához",
    "social_media_main_desc": "Szerezzen ügyfeleket a közösségi médiából, amikor a bejegyzésekből szkennelnek, átirányítja a felhasználókat egy személyre szabott regisztrációs űrlapra.",
    "social_media_form_name_field_placeholder": "Restro Fesztivál ajánlat – Facebook-bejegyzés",
    "social_media_last_step_title": "Gratulálunk! Hozd létre az ügyfelek közösségi média űrlapját! 🎉",
    "claim_offer_text": "Töltse ki adatait, és vegye igénybe az ajánlatot most",
    "form_title_description": "Adja hozzá az űrlap címét és leírását az ügyfelek számára a részletek megadásához",
    "no_subscribed_clients": "A kiválasztott ügyfélcsoportnak nincs előfizetett ügyfele",
    "welcome_message_offer": "Küldjön üdvözlő üzenetet ügyfeleinek, miután feliratkoztak, és adjon nekik ajánlatkupont",
    "system_grp": "Rendszercsoportok",
    "import_clients": "Ügyfelek importálása",
    "delivery_redirection_name": "Kézbesítési átirányítás neve",
    "created_at": "Létrehozva:",
    "provide_stamp_user": "Bélyegző megadása a felhasználónak",
    "scan_qr_code": "QR-kód beolvasása",
    "add_point": "Pont hozzáadása",
    "total_points": "Összes pont",
    "user_profile": "Felhasználói profil",
    "stamp_history": "Bélyegtörténet",
    "reward_txt": "Jutalom",
    "variant_placeholder": "Kicsi, Közepes, Nagy stb",
    "sent_coupon": "Kupon elküldve",
    "order_status": "Megrendelés állapota",
    "payment_status": "Fizetési állapot",
    "order_currency": "Megrendelés pénzneme",
    "card_data": "Kártyaadatok",
    "reward_expiring_on": "Jutalom lejár",
    "reward_status": "Jutalom állapota",
    "reward_get_date": "Jutalomszerzés dátuma",
    "reward_name": "Jutalom neve",
    "add_point_type": "Ponttípus hozzáadása",
    "restaurant_location": "Étterem helye",
    "collect_points": "Gyűjtse pontokat",
    "rewards_expire": "A jutalmak lejárnak",
    "rewards_redeem": "Jutalmak beváltása",
    "total_rewards": "Összes jutalom",
    "created_by": "Készítette",
    "anniversary": "Évforduló",
    "remove_user_group_txt": "Felhasználó eltávolítása a csoportból",
    "delete_permanently_grp_msg": "Ez az ügyfél végleg törlődik a csoportból.",
    "delete_multiple_permanently_grp_msg": "Ezek az ügyfelek véglegesen törlődnek a csoportból.",
    "delete_multiple_client": "Ezek az ügyfelek véglegesen törlődnek.",
    "messages": "Üzenetek",
    "management": "Menedzsment",
    "client": "Ügyfél",
    "country": "Ország",
    "state": "Állami",
    "city": "Város",
    "zip_code": "Irányítószám",
    "1_sparkly_point_txt": "🎉 1 csillogó pontot gyűjtöttél! 🌟",
    "select_country_txt": "Válasszon országot",
    "select_state": "Válassza ki az állapotot",
    "ls_thanks_for_join": "Köszönjük, hogy csatlakozott hűségkártyánkhoz.🎁🎈",
    "month": "Hónap",
    "welcome_message": "A regisztráció után küldjön üdvözlő üzenetet ügyfeleinek",
    "all_rewards_txt": "Minden jutalom",
    "active_rewards": "Aktív jutalmak",
    "expired_and_redeemed": "Lejárt és beváltva",
    "redirect_3rd_party_delivery": "A harmadik fél kézbesítését irányítsa át üzletébe vagy online rendelési platformjára",
    "search_or_start_new": "Keressen vagy indítson újat",
    "contacts": "Kapcsolatok",
    "chats": "Csevegés",
    "add_by_restaurant": "Hozzáadás étterem szerint",
    "using_scan_code": "Scan Code használata",
    "step": "Lépés",
    "list_widget_text": "Widgetek listája",
    "swipe_free_wifi_txt": "Csúsztassa az ingyenes wifit",
    "create_widget_txt": "Widget létrehozása",
    "redeem_award_msg": "Biztos ? Szeretnéd beváltani ezt a jutalmat?",
    "on_collect_msg": "On Collect",
    "pause": "Szünet",
    "resume": "Folytatás",
    "location_added": "hely hozzáadva",
    "sub_user_admin_permissions_txt": "Engedélyezze az alfelhasználók számára a rendszergazdai jogosultságok elérését a Digital Signage szolgáltatásban",
    "unlist": "Listázás nélkül",
    "cannot_delete_default_terms": "Az alapértelmezett szerződési feltételek nem törölhetők",
    "something_went_wrong": "Valami elromlott!",
    "card_download_success": "A kártya sikeresen letöltve",
    "permission_denied": "Engedély megtagadva!",
    "invalid_qr_code": "Kérjük, olvassa be az érvényes QR-kódot",
    "select_customer": "Kérjük, válassza ki az ügyfelet",
    "online": "ONLINE",
    "offline": "OFFLINE",
    "no_data": "Hoppá, nem található adat!",
    "forever_free": "Örökké ingyenes",
};

export default hu