// React Import
import React, { useState } from "react";

// MUI Imports
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

// Third Party Import
import QRCode from "react-qr-code";
import copy from "copy-to-clipboard";
import { useSnackbar } from "notistack";
import { toPng } from "html-to-image";

// Custom Component Import
import CustCheckBox from "../../../Components/CustCheckBox/CustCheckBox.component";
import RepStyle from "../../ReputationModule/Reputation/AddEditReputation/AddEditReputation.style";
import CustTextField from "../../../Components/CusTextField/CustTextField.component";
import MandatoryBox from "../../WifiModule/SplashPage/MandatoryBox";

// Graphql Import
import AppUtils from "../../../Utils/AppUtils";
import { useMutation } from "@apollo/client";
import { ADDITIONAL_FIELDS } from "./MenusGraphQl";

// Styles Imports
import mvStyles from "./MenuView.styles";

// Helper Imports
import staticIcons from "../../../Helper/Images";
import ColorsHelper from "../../../Helper/Colors";
import ConstantsHelper from "../../../Helper/Constants";
import useLanguageChange from "../../../Utils/useLanguageChange";

const InformationSettings = (props: any) => {
  const localizedVariable = useLanguageChange()

  // Props
  const {
    dialog,
    openDialog,
    openQr,
    setOpenQr,
    setFieldArr,
    fieldArr,
    qrValue,
    setDragItems,
  } = props;

  // State
  const [loader, setLoader] = useState<boolean>(false);
  const [firstDialogOpen, setFirstDialogOpen] = useState<boolean>(true);

  // Hooks
  const { enqueueSnackbar } = useSnackbar();
  const [saveAdditionalFields] = useMutation(ADDITIONAL_FIELDS);

  // download qr code
  const downloadQRCode = async (fileName: string) => {
    try {
      setLoader(true);
      const downloadResponse = await toPng(
        document.getElementById("QR-Code") as HTMLCanvasElement
      ).then(function (dataUrl: any) {
        let downloadLink = document.createElement("a");
        downloadLink.href = dataUrl;
        downloadLink.download = `${fileName}.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        return true;
      });
      if (downloadResponse) {
        setLoader(false);
        enqueueSnackbar(localizedVariable['qr_download_s_title'], {
          variant: "success",
        });
        return;
      }
      setLoader(false);
      return;
    } catch (e: any) {
      setLoader(false);
      enqueueSnackbar(AppUtils.catchErrorHandling(e), { variant: "error" });
      return;
    }
  };

  const handleSaveFirstDialog = async () => {
    setLoader(true);
    const selectedFields: any = {
      field: {},
      field_required: {},
    };

    fieldArr.forEach((field: any) => {
      selectedFields.field[field.name] = field.is_checked;
      selectedFields.field_required[field.name] = field.is_mendatory;
    });

    try {
      const { data } = await saveAdditionalFields({
        variables: {
          menuId: parseInt(dialog?.item?.id),
          additionalFields: JSON.stringify(selectedFields),
        },
      });

      if (
        data &&
        data.saveAdditionalFields &&
        data.saveAdditionalFields.status === true
      ) {
        enqueueSnackbar(data.saveAdditionalFields?.message, {
          variant: "success",
        });
        setDragItems((prev: any) => {
          return prev.map((val: any) => {
            if (val?.id === dialog?.item?.id) {
              return {
                ...val,
                additional_fields: JSON.stringify(selectedFields),
              };
            }
            return val;
          });
        });

        setLoader(true);
        setFirstDialogOpen(false);
        openDialog({ open: false, item: {} });
      } else {
        enqueueSnackbar(data.saveAdditionalFields?.message, {
          variant: "error",
        });
        setLoader(true);
      }
    } catch (error) {
      console.error("Error saving additional fields:", error);
      setLoader(true);
    }
    setLoader(true);
    setFirstDialogOpen(false);
    openDialog({ open: false, item: {} });
  };

  return (
    <>
      <Dialog
        open={dialog?.open && firstDialogOpen}
        fullWidth
        onClose={() => openDialog({ open: false, item: {} })}
        PaperProps={{ sx: mvStyles.paperStyle }}
      >
        <DialogTitle sx={mvStyles.dialogTitle}>
          <Typography sx={mvStyles.infoTxt}>{localizedVariable['addInfoT']}</Typography>
          <Box
            component={"img"}
            src={staticIcons.CloseCircle}
            onClick={() => openDialog({ open: false, item: {} })}
            sx={{ cursor: "pointer" }}
          />
        </DialogTitle>
        <Divider />
        <DialogContent sx={mvStyles.contentStyle}>
          <Box sx={mvStyles.infoBox}>
            <Typography sx={mvStyles.additionalTxt}>
              {localizedVariable['splash_colllect_additional_info_txt']}
            </Typography>
            <Grid container sx={mvStyles.container}>
              <Grid item xs={6} md={8}>
                <Typography
                  sx={[RepStyle.repDesc, { mb: "0.5rem", pl: "0.5rem" }]}
                >
                  {localizedVariable['field']}
                </Typography>
              </Grid>
              <Grid item xs={6} md={4}>
                <Typography sx={[RepStyle.repDesc, { mb: "0.625rem" }]}>
                  {localizedVariable['is_mandatory']}
                </Typography>
              </Grid>
              {Array.isArray(fieldArr) &&
                fieldArr?.length > 0 &&
                fieldArr?.map((item: any, index: number) => (
                  <React.Fragment key={index}>
                    <Grid
                      item
                      xs={6}
                      md={8}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <CustCheckBox
                        id={`checkbox-${index}`}
                        classnameform="spSocialForm"
                        defaultChecked={
                          item?.name === "mobile" || item?.name === "dob"
                        }
                        disabled={
                          item?.name === "mobile" || item?.name === "dob"
                        }
                        value={item?.is_checked}
                        onChange={(e: any) => {
                          if (item.name !== "mobile" || item?.name === "dob") {
                            const arr = fieldArr.map((val, i) => ({
                              ...val,
                              is_checked:
                                i === index ? !val.is_checked : val.is_checked,
                              is_mendatory:
                                i === index
                                  ? !val.is_checked && false
                                  : val.is_mendatory,
                            }));
                            setFieldArr(arr);
                          }
                        }}
                      />
                      <label
                        htmlFor={`checkbox-${index}`}
                        style={{ cursor: "pointer" }}
                      >
                        {item?.title}
                      </label>
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <MandatoryBox
                        ColorsHelper={ColorsHelper}
                        ConstantsHelper={ConstantsHelper}
                        id={"is_mendatory"}
                        disabled={
                          item?.name === "mobile" || item?.name === "dob"
                        }
                        sx={{ mb: "0.5rem" }}
                        data={item}
                        value={item?.is_mendatory}
                        handleChange={(val: any) => {
                          const updatedFields = [...fieldArr];
                          updatedFields[index].is_mendatory = val;
                          setFieldArr(updatedFields);
                        }}
                      />
                    </Grid>
                  </React.Fragment>
                ))}
            </Grid>
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ p: 2 }}>
          <LoadingButton
            variant="contained"
            type="button"
            sx={mvStyles.cancelBtn}
            disabled={loader}
            onClick={() => openDialog({ open: false, item: {} })}
          >
            {localizedVariable['cancel_text']}
          </LoadingButton>
          <LoadingButton
            variant="contained"
            type="button"
            sx={mvStyles.submitBtn}
            loading={loader}
            onClick={handleSaveFirstDialog}
          >
            {localizedVariable['save']}
          </LoadingButton>
        </DialogActions>
      </Dialog>

      {/* Qr Dialog */}
      <Dialog
        open={openQr?.open}
        fullWidth
        onClose={() => setOpenQr({ open: false, item: {} })}
        PaperProps={{ sx: mvStyles.qrPaper }}
      >
        <DialogContent
          sx={[mvStyles.contentStyle, { flexDirection: "column" }]}
        >
          <Box sx={mvStyles.qrBox}>
            <Typography sx={mvStyles.qrTxt}>{localizedVariable['generate_qr']}</Typography>
            <LoadingButton
              variant="contained"
              type="button"
              sx={mvStyles.submitBtn}
              loading={loader}
              onClick={() => downloadQRCode(qrValue)}
            >
              {localizedVariable['download_title']}
            </LoadingButton>
          </Box>
          <Box sx={mvStyles.qrContainer}>
            <Box
              component={"img"}
              src={staticIcons.QrBorder}
              sx={mvStyles.borderBox}
            />
            <Box sx={{ position: "absolute" }}>
              <QRCode
                id="QR-Code"
                value={qrValue}
                bgColor={ColorsHelper.whiteClr}
                fgColor={ColorsHelper.blackClr}
                size={138}
              />
            </Box>
          </Box>
          <Box sx={mvStyles.previewBox}>
            <CustTextField
              margin="dense"
              fullWidth
              sx={{
                mt: 0,
                ".MuiInputBase-root": { height: "45px" },
                "& > div": {
                  pr: 0,
                },
              }}
              txttitle={localizedVariable['menu_link']}
              value={qrValue}
              id="qrValue"
              name="Qr Link"
              placeholder={"Qr Link"}
              type={"text"}
              size="small"
              InputProps={{
                endAdornment: (
                  <LoadingButton
                    variant="contained"
                    type="button"
                    sx={[mvStyles.submitBtn, { height: "2.75rem" }]}
                    disabled={loader}
                    onClick={() => {
                      copy(qrValue);
                      enqueueSnackbar(localizedVariable['link_copied_text'], {
                        variant: "success",
                      });
                    }}
                  >
                    {localizedVariable['img_copy_text']}
                  </LoadingButton>
                ),
              }}
            />
            <LoadingButton
              variant="outlined"
              type="button"
              sx={mvStyles.previewBtn}
              loading={loader}
              onClick={() => {
                setOpenQr({ open: false, item: {} });
                window.open(qrValue, "_blank");
              }}
            >
              {localizedVariable['preview_text']}
            </LoadingButton>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default InformationSettings;
