const ko = {
    "rated_google_reviews": "Google 리뷰에서 5.0점 평가",
    "get_your_digital_menu": "디지털 메뉴를 받으세요—",
    "instant_popup_menu": "QR 스캔 없이 즉시 팝업 메뉴 생성",
    "free_forever": "영원히 무료",
    "the_future_is_here": "미래가 여기에 있습니다!",
    "upgrade_your_restaurant": "무료로 놀라운 디지털 메뉴로 레스토랑을 업그레이드하세요. 오늘부터 더 많은 고객을 유치하세요.",
    "get_my_free_menu_now": "지금 무료 메뉴를 받으세요",
    "no_credit_card_required": "*신용카드 불필요",
    "join_largest_marketplace": "최대 규모의 레스토랑 마켓플레이스에 참여하세요!",
    "what_will_you_get": "당신은 무엇을 얻을 것인가",
    "free": "무료",
    "free_digital_menu": "무료 디지털 메뉴 2D 또는 3D",
    "free_digital_menu_desc": "종이 메뉴와 값비싼 재인쇄본에 작별 인사를 전하세요",
    "quick_and_easy_signup": "빠르고 쉬운 가입",
    "communicate_with_customers_title": "고객과 무료로 소통하세요!",
    "features1": [
        "인쇄 비용을 절약하고 필요할 때마다 바로 메뉴를 업데이트해 보세요.",
        "모든 기기에서 디지털 메뉴에 액세스하세요",
        "친환경적이고 번거로움 없음"
    ],
    "features2": [
        "고객이 놀라운 3D 영상으로 가상으로 요리를 살펴볼 수 있도록 하세요.",
        "식사하는 손님에게 음식의 실제 모습을 미리 보여줌으로써 참여도를 높이세요.",
        "음식의 맛만큼이나 매력적인 메뉴 경험으로 두각을 나타내세요."
    ],
    "features3": [
        "손님이 레스토랑에 들어오면 바로 메뉴를 보여주어 맞이하세요.",
        "특별 행사 및 프로모션에 대한 업데이트를 휴대폰으로 자동으로 전송합니다.",
        "메뉴에 비대면으로 빠르게 접근해 더욱 즐거운 식사 경험을 선사하세요."
    ],
    "feature4": [
        "계절별 특가 상품, 일일 특가 상품, 특별 프로모션을 강조해서 소개합니다.",
        "가장 인기 있는 상품으로 고객의 관심을 끌어보세요.",
        "눈길을 끄는 시각적 요소와 설명으로 긴박감과 흥분을 조성하세요."
    ],
    "feature5": [
        "매일 수천 명의 방문객이 찾는 온라인 커뮤니티에 참여하세요.",
        "가시성을 높이고 적극적으로 식당 옵션을 검색하는 새로운 고객에게 다가가세요.",
        "일찍 최고의 자리를 확보하면 노출을 극대화하고 더 많은 식사 손님을 유치할 수 있습니다."
    ],
    "section3_heading": "왜 디지털로 가야 하나요? 무료이고 쉽기 때문입니다!",
    "section3_description": "레스토랑의 매력을 높이는 데 도움이 되는 강력한 기능을 사용해 보세요.",
    "instant_menu": "인스턴트 메뉴",
    "instant_menu_notification": "팝업 및 알림",
    "instant_menu_description": "손님이 들어오자마자 메뉴에 바로 접근할 수 있도록 환영하세요. 최신 메뉴와 특가 상품을 자동으로 모바일 폰으로 직접 전송합니다.",
    "digital_menu": "디지털 메뉴",
    "at_zero_cost": "무료로",
    "digital_menu_description1": "종이 메뉴와 값비싼 재인쇄에 작별 인사를 전하세요. 항상 최신 상태인 디지털 메뉴로 전환하세요. 영원히 무료입니다!",
    "digital_menu_description2": "디지털 메뉴를 사용하면 모든 기기에서 항상 최신 상태로 제공되고 액세스할 수 있습니다. 매끄럽고 비용이 들지 않는 솔루션으로 비용을 절감하고 메뉴 업데이트를 간소화하세요.",
    "try_me_button": "나를 시도해 보세요",
    "try_3d_menu_title": "3D 메뉴",
    "try_3d_menu_title2": "무료로 - 와우!",
    "try_3d_menu_description1": "요리가 화면에서 튀어나오게 하세요. 3D 이미지를 업로드하여 고객이 들어오기 전에 음식을 가상으로 맛보게 하세요.",
    "try_3d_menu_description2": "고품질 3D 이미지로 입맛을 돋우는 미리보기가 만들어져 매력을 더하고 주문을 촉진합니다.",
    "try_now": "지금 시도해보세요",
    "showcase_title": "당신의 쇼케이스",
    "showcase_specials": "스페셜",
    "showcase_specials_description1": "입맛을 돋우는 할인 혜택이나 타의 추종을 불허하는 프로모션이 있나요? 디지털 메뉴에서 강조 표시하여 배고픈 사람들을 더 많이 끌어들이세요.",
    "showcase_specials_description2": "눈길을 끄는 시각적 요소와 매혹적인 설명으로 시선을 끌어 놓치기 어려운 프로모션을 만드세요.",
    "marketplace": "시장",
    "marketplace_description": "저희는 매일 2,000명 이상의 방문객이 찾는 마켓플레이스를 만들고 있습니다. 지금 바로 참여하세요. 저희가 출시하면 가장 먼저 줄을 설 것입니다. 식당 옵션을 적극적으로 찾는 새로운 고객에게 다가갈 수 있습니다. 노출을 극대화하려면 일찍 자리를 확보하세요!",
    "see_other_products": "다른 제품 보기",
    "about_us": "우리에 대하여",
    "why_serving_free": "우리가 이것을 제공하는 이유",
    "serving_free_highlight": "무료",
    "about_desc": "우리는 댈러스의 지역 주민으로서, 여러분과 같은 레스토랑이 성공할 수 있도록 돕는 데 전념하고 있습니다.",
    "about_tool_free": "우리는 지역 사회를 지원해야 한다고 믿기 때문에 이 강력한 도구를 완전히 무료로 제공합니다.",
    "no_catch": "함정은 없고, 농담도 아닙니다. 우리는 당신이 성공하면 우리 커뮤니티가 더 강해진다는 것을 알고 있습니다. 😊",
    "understanding_challenges": "우리는 기업 운영의 어려움을 알고 있습니다. 그래서 우리는 여러분의 운영을 보다 원활하게 하고 영향력을 넓히는 데 도움이 되는 도구를 제공하는 데 전념합니다.",
    "community_growth": "여러분이 성장함에 따라, 저희 커뮤니티도 여러분과 함께 성장하여 성공과 지원의 파장 효과를 만들어냅니다. 함께, 모든 사람을 위한 식사 경험을 향상시키자.",
    "how_it_works": "작동 원리",
    "three_simple_steps": "세 가지 간단한 단계",
    "get_started_highlight": "시작하기",
    "no_tech_skills_needed": "기술적인 기술은 필요 없습니다. 버거를 뒤집을 수 있다면, 이것도 할 수 있습니다!",
    "step1_title": "무료로 프로모션을 공개하세요!",
    "step1_desc": "\"주문하세요!\"라고 말하기 전에 더 빨리 시작하세요. 간단한 양식을 작성하기만 하세요.",
    "step2_title": "메뉴 사용자 정의",
    "step2_desc": "요리 내용을 추가하고, 3D로 사진을 업로드하고, 특별 메뉴를 강조하세요.",
    "step3_title": "공유하고 성장하세요",
    "step3_desc": "QR 코드, 소셜 미디어 또는 웹사이트를 통해 디지털 메뉴를 공유하세요.",
    "testimonials": "추천사",
    "testimonials_section_title": "우리의 말만 믿지 마세요",
    "testimonials_section_desc": "다른 댈러스 레스토랑 주인들이 무슨 말을 하는지 들어보세요.",
    "faq_section_title": "질문이 있으신가요? 답변이 있습니다",
    "faq_section_desc": "시작하기 전에 알아야 할 모든 사항",
    "faqs": "자주 묻는 질문",
    "faq_answers": [
        {
            "que": "정말 영원히 무료인가요?",
            "ans": "물론입니다! 숨은 비용도 없고, 신용카드도 없고, 조건도 없습니다."
        },
        {
            "que": "기술적인 노하우가 필요한가요?",
            "ans": "이메일을 보낼 수 있다면, 이렇게 할 수 있습니다. 정말 간단하죠."
        },
        {
            "que": "왜 이것을 무료로 제공하시나요?",
            "ans": "우리는 먼저 주는 것을 믿습니다. 앞으로, 우리는 당신이 좋아할 다른 서비스를 제공할 수도 있지만, 그것은 전적으로 당신에게 달려 있습니다."
        },
        {
            "que": "시장에서는 무슨 일이 일어나고 있나요?",
            "ans": "우리가 요리하고 있어요! 충분한 레스토랑이 모이면, 우리는 당신의 마켓플레이스를 출시해서 당신이 더 많이 노출되도록 할 거예요."
        }
    ],
    "testimonials_content1": "디지털 메뉴로 전환한 것은 우리가 한 가장 좋은 결정이었습니다! 고객들은 편리함과 업데이트된 모습을 좋아합니다!",
    "testimonials_name1": "모리스 조지",
    "testimonials_restaurant_name1": "더 맨션 레스토랑",
    "testimonials_content2": "새로운 디지털 메뉴 덕분에 고객이 메뉴를 더 쉽게 둘러볼 수 있게 되었고 참여도도 정말 높아졌습니다!",
    "testimonials_name2": "아멜리아 존슨",
    "testimonials_restaurant_name2": "어반이츠",
    "testimonials_content3": "손님들은 3D 영상에 깜짝 놀랐습니다. 덕분에 우리가 눈에 띄고 식사 경험이 더욱 향상되었습니다!",
    "testimonials_name3": "데이비스 그린",
    "testimonials_restaurant_name3": "타운 하스",
    "ready_to_boost_your_restaurant": "레스토랑을 성장시킬 준비가 되셨나요?",
    "ready_to_boost_desc": "뒤처지지 마세요. 오늘 디지털 혁명에 동참하세요!",
    "yes_i_want_my_free_digital_menu": "네, 저는 무료 디지털 메뉴를 원합니다",
    "480_plus": "480+",
    "people_already_joined": "사람들이 이미 가입했습니다",
    "menu_online_home": "메뉴 온라인 홈",
    "welcome": "환영",
    "description_other": "고객 만족도를 높여 보세요!",
    "benefits": "이익",
    "greeting_text": "환영합니다 👋",
    "all_in_one_features": "🚀 레스토랑을 한 단계 업그레이드할 올인원 기능",
    "menu_online_menu_name": "메뉴 온라인",
    "menu_online_description": "고객이 온라인으로 쉽게 접근할 수 있도록 메뉴를 디지털화하세요.",
    "menu_online_benefits": "언제 어디서나 고객이 메뉴를 볼 수 있도록 하여 고객 편의성을 높이고 참여도를 높이세요.",
    "ds_name": "디지털 사이니지",
    "ds_description": "매장 내 역동적인 화면에 특가 상품과 프로모션을 선보입니다.",
    "ds_benefits": "매력적인 비주얼로 고객의 관심을 사로잡고, 추가 판매와 실시간 특가를 손쉽게 홍보하세요.",
    "loyalty_punch_name": "로열티 펀치 카드",
    "loyalty_punch_description": "디지털 로열티 프로그램으로 재구매 고객에게 보상을 제공하세요.",
    "loyalty_punch_benefits": "자주 방문하는 고객에게 교환 가능한 보상을 제공하여 충성도를 높이고 고객 유지율을 높이고 매출을 늘리세요.",
    "marketing_menu_name": "마케팅",
    "marketing_description": "타겟팅된 디지털 마케팅 캠페인을 통해 더 많은 고객에게 다가가세요.",
    "marketing_benefits": "새로운 고객과 재방문 고객을 유치하기 위해 맞춤화된 프로모션을 통해 레스토랑의 영향력을 확대하고 고객 수를 늘리세요.",
    "get_more_reviews_menu_name": "더 많은 리뷰를 받으세요",
    "get_more_reviews_description": "만족한 고객이 긍정적인 온라인 리뷰를 남기도록 장려하세요.",
    "get_more_reviews_benefits": "보다 긍정적인 리뷰를 통해 온라인 평판을 개선하여 레스토랑의 신뢰도를 높이고 새로운 고객을 유치하세요.",
    "guest_smart_wifi_menu_name": "게스트 스마트 와이파이",
    "guest_smart_wifi_description": "마케팅을 위한 고객 데이터를 수집하기 위해 무료 Wi-Fi를 제공합니다.",
    "guest_smart_wifi_benefits": "향후 프로모션을 개인화하기 위해 귀중한 고객 데이터를 수집하는 동시에 고객 만족도를 높입니다.",
    "reservation_menu_name": "예약",
    "reservation_description": "고객이 온라인으로 쉽게 테이블을 예약할 수 있도록 하세요.",
    "reservation_benefits": "대기 시간을 최소화하여 식사 경험을 개선하고, 예약을 간소화하여 좌석을 보다 효과적으로 관리합니다.",
    "pos_integration_menu_name": "POS 통합",
    "pos_integration_description": "귀사의 POS 시스템과 당사 시스템을 통합하세요.",
    "pos_integration_benefits": "온라인과 매장 시스템을 동기화하여 주문 처리를 간소화하고 오류를 줄이세요.",
    "online_ordering_menu_name": "온라인 주문",
    "online_ordering_description": "고객이 귀하의 웹사이트나 앱에서 직접 주문할 수 있도록 하세요.",
    "online_ordering_benefits": "수수료를 절감하는 직접 주문 옵션을 제공하여 매출을 늘리고 타사 플랫폼에 대한 의존도를 낮추세요.",
    "pro_label": "찬성",
    "coming_soon": "곧 출시 예정",
    "locked_label": "잠김",
    "forever_label": "영원히",
    "new_label": "새로운",
    "other_products_page_title": "기타 제품 | 메뉴 온라인",
    "other_products_description": "메뉴 온라인 개인정보 보호정책",
    "other_products_header_title": "기타 제품",
    "privacy_policy": {
        "title": "개인정보 보호정책",
        "lastUpdated": "최종 업데이트 : 2024년 7월 1일",
        "introduction": {
            "header": "신규 및 기존 고객을 위한 개인정보 보호정책",
            "paragraph1": "다음 주요 사항(섹션 참조 포함)은 귀하의 편의를 위해 제공되며 전체 약관을 대신하지 않습니다. MENUONLINE INC 서비스를 사용하기 전에 해당 약관을 읽는 것은 귀하의 책임입니다.",
            "paragraph2": "Menuonline을 사용한다는 것은 귀하가 이용 약관에 동의하고 귀하의 조직 또는 회사에서 그렇게 하도록 허가를 받았다는 것을 진술하고 보증한다는 것을 의미합니다. (s.3 “작동 방식” 참조)",
            "paragraph3": "Menuonline Inc는 허가 기반 마케팅 서비스입니다. 고의로 \"스팸\"을 보내거나 이용 약관을 위반하면 계정이 폐쇄됩니다. (s.16 \"서비스의 허용 가능한 사용\" 참조)",
            "paragraph4": "Menuonline Inc에서 호스팅하는 데이터, 예를 들어 연락처 기록, 이메일 내용 및 Menuonline Inc를 통해 수집한 정보는 귀하의 소유입니다. (s.14 “귀하가 소유한 것은 무엇입니까?” 참조).",
            "paragraph5": "Menuonline Inc 소프트웨어와 당사 웹사이트의 콘텐츠는 당사 소유입니다. (13절 \"당사가 소유한 것은 무엇인가?\" 참조) 귀사의 사용자 외에 Menuonline Inc에서 호스팅하는 데이터 또는 미공개 콘텐츠는 기밀 유지 계약에 서명한 Menuonline Inc 직원과 파트너만 볼 수 있습니다. (14절 \"당사가 소유한 것은 무엇인가?\" 참조)",
            "paragraph6": "모든 개인 식별 정보(PII)는 캐나다에 있는 서버에만 보관됩니다. (s.14 “무엇을 소유하고 있습니까?” 참조).",
            "paragraph7": "Menuonline Inc 서비스는 캐나다 및 온타리오주의 법률을 따릅니다. (s.24 “준거법” 참조)",
            "paragraph8": "캐나다 내부 또는 외부의 개인, 경찰 또는 기타 규제 기관에서 귀하의 데이터 또는 콘텐츠에 대한 요청은 항상 귀하에게 전달됩니다. 유일한 예외는 캐나다 또는 온타리오 주법을 위반하는 경우입니다. 그러나 모든 경우에 귀하 외의 다른 사람이 귀하의 데이터를 공개하는 것을 강력히 거부합니다. (s.14 \"귀하가 소유한 것은 무엇입니까?\" 참조).",
            "paragraph9": "Menuonline Inc 보안은 사용자 이름과 비밀번호 관리만큼만 좋습니다. Menuonline Inc 사용자 계정에 대해 요금을 청구하지 않으므로 각 사용자에 대해 별도의 계정을 만들고 비밀번호를 비밀로 유지하세요. 귀하는 귀하의 계정에 대한 책임이 있습니다. (s.3 “작동 방식” 참조)",
            "paragraph10": "언제든지 계정을 취소할 수 있습니다. 취소 시 사용하지 않은 크레딧에 대한 환불은 제공하지 않습니다. (3절 \"작동 방식\" 참조)"
        },
        "termsAndConditions": {
            "title": "MENUONLINE INC 이용약관",
            "lastUpdate": "마지막 업데이트: 2020년 1월 14일",
            "paragraph1": "이 웹사이트(이하 \"사이트\")를 사용하기 전에 이 서비스 약관(이하 \"약관\")을 주의 깊게 읽어보세요. 이 약관은 Menuonline Inc.(\"Menuonline Inc\" \"당사\" 또는 \"저희\") 및 기타 당사자의 책임을 면제하고\/하거나 당사 및 당사자의 책임을 제한하며 이 사이트 사용에 적용되는 기타 중요한 조항을 포함합니다. 이 사이트 또는 서비스를 방문하거나 사용함으로써 귀하는 본인 및 귀하가 대신 행동할 수 있는 모든 조직(이하 \"귀하\"라고 통칭)을 대신하여 이 사이트를 사용하고 방문할 때마다 이 약관을 수락하고 준수하는 데 동의합니다."
        },
        "services": {
            "title": "당사의 서비스",
            "paragraph": "사이트를 통해 제공되는 서비스는 귀하가 선택한 수신자에게 이메일 메시지(\"이메일\")를 작성, 전송 및 관리할 수 있는 서비스입니다(\"서비스\")."
        },
        "license": {
            "title": "특허.",
            "paragraph": "이러한 약관에 따라 Menuonline Inc는 귀하에게 기간 동안 사이트를 보고 서비스의 기능에 따라 허용되는 대로 서비스를 사용할 수 있는 비독점적이고 제한적이며 양도 불가능한 라이선스를 부여합니다. 당사는 서비스와 Menuonline Inc 콘텐츠(아래 정의)에 대해 여기에 명시적으로 부여되지 않은 모든 권리를 보유합니다."
        },
        "emailingContent": {
            "title": "이메일로 내용 전송.",
            "paragraph": "이 사이트나 서비스를 통해 Menuonline Inc와 상호 작용할 때, 귀하는 진실하고 정확한 정보를 제공하는 데 동의합니다. 또한 이메일을 통해 기밀 정보를 제공하는 것은 안전하지 않은 관행임을 이해합니다. 귀하는 당사 서비스를 사용하여 누군가에게 보내는 모든 것에 대해 전적으로 책임을 져야 함을 이해합니다."
        },
        "support": {
            "title": "지원하다.",
            "paragraph": "당사는 귀하가 서비스를 통해 자립할 수 있도록 지원합니다. 당사는 사이트의 문서 및 비디오, Menuonline Inc 지원 티켓 시스템 및 전화로 지원을 제공합니다. 사이트 상단의 \"지원\" 탭을 클릭하면 당사에 연락할 수 있습니다. 제품 지원에는 비용이 청구되지 않지만, 당사는 재량에 따라 특정 클라이언트 계정에 대한 지원을 중단할 권리를 보유합니다."
        }
    },
    "terms_condition": {
        "title": "서비스 약관 | 메뉴 온라인",
        "header": "신규 및 기존 고객을 위한 이용 약관"
    },
    "privacy_policy_title": "개인 정책",
    "privacy_policy_effective_date": "2020년 1월 16일부터 유효",
    "privacy_policy_general_title": "일반 개인 정책",
    "privacy_policy_intro": "menuonline.com(Menuonline 및 \"당사\")은 개인의 개인 정보의 프라이버시 보호와 모든 유형의 기밀 정보의 보안의 중요성을 이해합니다. 당사는 귀하의 \"프라이버시\"를 보호하려는 당사의 의지를 보여주기 위해 이 개인정보 보호정책(이하 \"정책\")을 만들었습니다. 이 개인정보 보호정책은 다음을 설명합니다.",
    "privacy_policy_points": [
        "우리가 수집하는 개인정보는 무엇이며, 왜 수집하는가;",
        "귀하의 개인 정보를 사용하는 방법",
        "귀하의 개인 정보를 누구와 공유하는지;",
        "서비스에서 개인 정보에 접근하고, 이를 업데이트하고, 삭제할 수 있는 선택권이 있습니다."
    ],
    "privacy_policy_pipeda": "Menuonline은 캐나다 개인정보 보호 및 전자문서법(PIPEDA)에 따라 모든 정보 수집을 관리합니다.",
    "privacy_policy_update_notice": "Menuonline은 언제든지 이 개인정보 보호정책을 업데이트하거나 변경할 권리가 있음을 알려드립니다. 당사는 이 웹사이트에 업데이트된 정책을 게시하거나 서비스를 통해 제공함으로써 변경 사항을 알려드립니다. 이 정책의 최신 버전은 mycircle이 사용자의 개인정보를 사용하는 것을 관장합니다. mycircle의 웹사이트에 계속 액세스하거나 이 개인정보 보호정책이 변경된 후에도 서비스를 계속 사용하는 경우 개정된 약관에 동의하는 것으로 간주됩니다.",
    "privacy_policy_consent_title": "개인정보에 대한 동의",
    "privacy_policy_consent_text": "당사 웹사이트 또는 당사 양식을 통해 Menuonline에 개인 정보를 제공함으로써, 귀하는 본 정책에 따라 또는 개인 정보를 제공할 당시 귀하에게 식별된 목적에 따라 해당 정보의 수집, 사용 및 공개에 동의합니다. 개인 정보의 2차 사용은 귀하의 명시적 동의가 있어야만 처리되거나, 귀하는 옵트아웃할 수 있는 기회를 제공받습니다.",
    "privacy_policy_opt_out_text": "언제든지 동의를 철회하거나 옵트아웃할 수 있습니다. 동의를 철회하거나 옵트아웃하려면 당사에서 보낸 이메일 하단에 있는 커뮤니케이션 환경 설정 링크를 클릭할 수 있습니다.",
    "privacy_policy_info_collection_title": "우리가 수집하는 정보",
    "privacy_policy_info_collection_text": "Menuonline은 이 정책에 따라 개인 정보를 수집하고 처리합니다. 이 정책에 설명된 경우를 제외하고, 당사는 이 정보를 타인에게 판매, 공유 또는 임대하지 않습니다. Menuonline은 귀하에게 서비스 또는 제품을 제공하기 위해 개인 정보를 수집합니다.",
    "privacy_policy_info_types_text": "Menuonline이 수집하는 정보 유형은 다음과 같습니다.",
    "privacy_policy_account_holders_title": "Menuonline 계정 보유자",
    "privacy_policy_registration_info": "등록 정보: 서비스를 사용하기 위해 가입하는 경우 계정 소유자(\"계정 소유자\")가 되려면 Menuonline 계정이 필요합니다. 계정을 등록할 때 이름, 이메일 주소, 사용자 이름, 비밀번호, 회사 이름, 직업, 위치 및 전화번호와 같은 특정 기본 정보를 제공하라는 요청을 받게 됩니다. 비밀번호는 암호화되어 있으므로 Menuonline 직원은 비밀번호를 보거나 검색할 수 없습니다.",
    "privacy_policy_billing_info": "결제 정보: 서비스를 구독하는 경우 결제 및 결제 정보를 제공해야 합니다.",
    "privacy_policy_cookies_info": "쿠키: 당사는 웹사이트 방문 출처, 당사 웹사이트 방문 횟수 등 방문자 데이터를 추적하기 위해 쿠키를 사용합니다.",
    "privacy_policy_ip_info": "IP 주소: IP 주소는 귀하가 당사 서비스를 이용하는 과정에서 수집됩니다(예: 국가 수준의 위치와 당사 서비스를 이용할 때 사용되는 IP 주소를 기록하는 목적).",
    "privacy_policy_your_contacts_title": "귀하의 연락처",
    "privacy_policy_distribution_list": "배포 목록: 당사 서비스를 사용하는 과정에서 귀하는 당사에 귀하의 연락처(\"연락처\")에 대한 개인 정보를 제공하거나 당사가 서비스의 일환으로 이를 수집할 수 있습니다. 이 정보에는 이메일 주소가 포함되며 이름, 회사 이름 또는 서비스를 사용하는 동안 Menuonline에 제공하는 기타 정보와 같은 기타 식별 가능한 정보가 포함될 수 있습니다. 귀하는 연락처로부터 적절한 동의를 얻어 당사가 해당 정보를 처리할 수 있도록 해야 합니다.",
    "privacy_policy_billing_info_contacts": "결제 정보: 서비스를 구독하는 경우 결제 및 결제 정보를 제공해야 합니다.",
    "privacy_policy_cookies_contacts": "쿠키: Menuonline은 귀하가 웹사이트에 Menuonline 추적 코드를 추가한 경우에만 쿠키 및 유사한 추적 기술을 사용하여 귀하의 연락처에 대한 개인 정보를 수집하고 사용합니다. Menuonline에서 호스팅하는 모든 랜딩 페이지에는 자동으로 추적 코드가 있습니다.",
    "privacy_policy_ip_contacts": "IP 주소: IP 주소는 서비스의 일환으로 귀하의 연락처에 대한 수집됩니다(예: 국가 수준의 위치와 양식을 제출하고 동의를 제공할 때 사용된 IP 주소를 기록하는 목적).",
    "privacy_policy_info_about_contacts_title": "귀하의 연락처에 대해 수집하는 정보",
    "privacy_policy_info_about_contacts_notice": "*이 섹션은 Menuonline이 서비스 제공을 목적으로 계정 소유자의 연락처에 대해 수집, 사용 또는 공개하는 정보에만 적용됩니다.*",
    "privacy_policy_info_about_contacts_desc": "Menuonline은 계정 소유자가 특정 서비스에 등록하여 배포 목록을 제공한 경우에만 연락처에 대한 개인 정보를 수집, 사용 또는 공개합니다. 계정 소유자는 서비스를 통해 Menuonline에 연락처에 대한 개인 정보를 제공할 때 모든 해당 법률을 준수했는지 확인하는 데 전적으로 책임이 있습니다.",
    "privacy_policy_info_auto_collect_title": "Menuonline에서 자동으로 수집하는 정보",
    "privacy_policy_info_auto_collect_desc1": "계정 소유자의 연락처가 계정 소유자로부터 받은 이메일 캠페인에 참여하거나 계정 소유자의 연결된 웹사이트 링크와 상호 작용할 때 Menuonline은 쿠키를 사용하여 해당 연락처의 기기 및 이메일과의 상호 작용에 대한 정보를 수집할 수 있습니다. 이 정보는 서비스를 제공하고 계정 소유자에게 통찰력을 제공하기 위해서만 수집됩니다.",
    "privacy_policy_info_auto_collect_desc2": "당사 서비스를 통해 계정 소유자로부터 보낸 이메일에 액세스하기 위해 연락처가 사용하는 기기 및 애플리케이션에 대해 수집하는 정보에는 연락처의 IP 주소, 운영 체제, 브라우저 ID 및 연락처의 시스템 및 연결에 대한 기타 관련 정보가 포함될 수 있습니다.",
    "privacy_policy_info_auto_collect_desc3": "서비스를 통해 전송된 이메일과 Contact의 상호작용에 대해 수집된 정보에는 Contact가 이메일과 탐색 활동에 액세스한 날짜 및 시간, 그리고 서비스 성능과 관련된 정보(예: 서비스를 통해 계정 소유자가 Contact에 보낸 이메일 및 기타 커뮤니케이션의 전달 가능성)가 포함될 수 있습니다.",
    "privacy_policy_info_auto_collect_desc4": "참고로, Menuonline은 해당 법률에 의해 요구되지 않는 한 귀하의 연락처에 연락하지 않습니다.",
    "privacy_policy_why_we_collect_title": "당사가 귀하의 정보를 수집하는 이유",
    "privacy_policy_why_we_collect_desc": "Menuonline은 다음과 같은 목적을 위해 정보를 수집합니다.",
    "privacy_policy_why_collect_1": "Menuonline 계정 소유자에게 그들이 구독한 서비스를 제공하고, 해당 서비스를 위해 계정 소유자 정보를 사용하는 것을 포함합니다.",
    "privacy_policy_why_collect_2": "관리 및 계정 관리, 그리고 mycircle 웹사이트 접속을 위해서입니다.",
    "privacy_policy_why_collect_3": "마케팅 및 홍보 자료 수신에 동의한 경우 Menuonline에서 제공하는 기타 제품 및 서비스에 대해 알려드립니다.",
    "privacy_policy_why_collect_4": "계정 소유자의 커뮤니케이션에 응답하거나 귀하가 요청한 정보를 전송하기 위함입니다. 여기에는 다음이 포함될 수 있지만 이에 국한되지 않습니다.",
    "privacy_policy_why_collect_5": "특별 할인 및 업데이트",
    "privacy_policy_why_collect_6": "뉴스레터; 및",
    "privacy_policy_why_collect_7": "서비스 공지사항.",
    "privacy_policy_opt_out_desc": "언제든지 Menuonline에서 보낸 각 이메일 하단에 있는 구독 취소 버튼을 클릭하여 소식 수신을 거부할 수 있습니다.",
    "privacy_policy_use_disclosure_title": "개인정보의 이용 및 공개",
    "privacy_policy_use_disclosure_desc1": "Menuonline 웹사이트나 규정된 양식을 포함하여 Menuonline에 제출된 개인 정보는 계정 소유자의 명시적 동의나 법적 요구 사항에 따라 공개되지 않는 한 엄격히 비밀로 유지됩니다. 어떤 경우든 계정 소유자가 \"Menuonline\" 플랫폼에 제공하는 모든 개인 정보는 자발적입니다. 계정 소유자가 제공하는 모든 개인 정보는 계정 소유자의 재량에 따라 제출됩니다. 동의를 철회하면 특정 Menuonline 서비스에 액세스하거나 사용할 수 없게 될 수 있습니다.",
    "privacy_policy_use_disclosure_desc2": "Menuonline은 웹사이트 및 서비스와 관련하여 제3자 및 외부 서비스 제공자를 사용합니다. 계정 소유자의 개인 정보는 이러한 제3자 및 제공자와 공유될 수 있습니다. 또한 계정 소유자, 판매, 트래픽 패턴 및 관련 정보에 대한 집계된 통계를 평판 좋은 제3자에게 제공할 수도 있습니다. Menuonline은 개인 정보에 액세스할 수 있는 모든 제3자 제공자가 이 개인 정보 보호 정책과 일치하는 의무를 준수해야 하며 이러한 제공자가 Menuonline 서비스를 제공하는 목적으로만 계정 소유자의 개인 정보를 수집, 사용 또는 공개해야 함을 요구합니다.",
    "privacy_policy_use_disclosure_desc3": "계정 소유자가 제공한 개인 정보는 귀하의 묵시적 또는 명시적 동의에 따라 외부 기관에 판매, 공유 또는 임대되지 않습니다. Menuonline은 정보를 전달하고, 질문에 답변하고, 프로그램 또는 정책의 변경 사항을 알리기 위해 모든 계정 소유자에게 연락할 권리가 있습니다. Menuonline은 해당 법률에 의해 요구되지 않는 한 귀하의 연락처에 연락하지 않습니다.",
    "privacy_policy_use_disclosure_desc4": "Menuonline은 해당 개인의 동의가 있는 경우에만 개인 정보를 공개합니다.",
    "privacy_policy_disclosure_list_1": "소환장이나 기타 법적 절차에 응답하는 경우처럼 법률에 의해 허용되거나 요구되는 경우",
    "privacy_policy_disclosure_list_2": "Menuonline의 일부 또는 전부를 양도받은 사람에게;",
    "privacy_policy_disclosure_list_3": "이 정책에 따라 Menuonline의 서비스 제공자에게; 또는",
    "privacy_policy_disclosure_list_4": "규제 보고 의무를 포함하되 이에 국한되지 않는 법적 의무를 준수하기 위해.",
    "privacy_policy_service_providers_title": "Menuonline은 다음의 제3자 및 서비스 제공자를 사용합니다(귀하의 연락처의 개인 정보는 \"해당\" 제3자에게 공개되지 않습니다):",
    "privacy_policy_service_provider_1": "저희는 ManyChat을 사용하여 판매 및 지원을 위한 웹사이트 채팅 서비스를 제공하고, 이러한 대화에 대한 후속 이메일을 보냅니다.",
    "privacy_policy_service_provider_2": "저희는 AskNicely를 통해 고객에게 서비스 품질과 지원에 대한 내용을 알아보는 설문조사를 실시합니다.",
    "privacy_policy_service_provider_3": "저희는 join.me를 이용해 고객 및 잠재 고객과의 미팅, 지원 전화, 소프트웨어 데모 일정을 조정합니다.",
    "privacy_policy_service_provider_4": "당사는 Google Analytics를 사용하여 익명의 웹사이트 활동을 추적하고 당사 웹사이트의 사용 패턴을 측정합니다.",
    "privacy_policy_breach_notification_title": "침해 통지",
    "privacy_policy_breach_notification_desc": "Menuonline은 개인정보의 무단 사용, 접근 또는 공개와 관련하여 적용 가능한 법률을 준수하고, 계정 소유자에게 합리적인 지원을 제공해야 합니다.",
    "privacy_policy_rights_access_title": "귀하의 권리 및 접근",
    "privacy_policy_rights_access_desc": "귀하는 Menuonline이 귀하에 대해 처리하는 개인 정보에 접근할 권리가 있습니다. 귀하는 다음에 대한 정보를 당사에 요청할 수 있습니다.",
    "privacy_policy_rights_access_list_1": "귀하에 대한 당사가 보유한 개인 정보",
    "privacy_policy_rights_access_list_2": "해당 개인정보의 종류",
    "privacy_policy_rights_access_list_3": "처리 목적",
    "privacy_policy_rights_access_list_4": "귀하의 개인 정보가 공개된\/공개될 대상의 세부 정보",
    "privacy_policy_rights_access_list_5": "귀하의 개인 정보를 보관하는 기간",
    "privacy_policy_rights_access_list_6": "당사가 귀하로부터 직접 정보를 수집하지 않은 경우 출처에 대한 정보",
    "privacy_policy_rights_access_list_7": "올바른 감독 기관에 불만을 제기하는 방법",
    "privacy_policy_rights_access_list_8": "또한 다음 사항을 요청하실 수 있습니다.",
    "privacy_policy_rights_access_list_9": "귀하에 대한 불완전하거나 부정확한 정보를 업데이트합니다.",
    "privacy_policy_rights_access_list_10": "해당 법률에 따라 귀하의 개인정보 삭제를 요청합니다.",
    "privacy_policy_rights_access_contact": "캐나다 온타리오주 토론토 더퍼린 스트리트 2851번지로 연락하시거나 {link} 로 이메일을 보내시면 귀하의 권리를 행사해 달라고 요청하실 수 있습니다.",
    "privacy_policy_accountability_title": "책임",
    "privacy_policy_accountability_desc": "Menuonline은 귀하가 당사에 제공하는 개인 및 기밀 정보에 대한 책임을 지는 데 전념합니다. 연락처 정보는 이 정책의 끝 부분을 참조하십시오.",
    "privacy_policy_links_other_sites_title": "다른 사이트로의 링크",
    "privacy_policy_links_other_sites_desc1": "Menuonline의 웹사이트에는 다른 사이트로 연결되는 링크가 포함되어 있을 수 있습니다. Menuonline은 다른 사이트의 개인정보 보호 관행에 대해 책임을 지지 않습니다. 당사는 계정 소유자에게 당사 사이트를 떠날 때 개인 식별 정보를 수집하는 모든 웹사이트의 개인정보 보호 정책을 철저히 읽어볼 것을 권장합니다.",
    "privacy_policy_links_other_sites_desc2": "본 개인정보 보호정책은 당사가 수집하는 정보에만 적용됩니다.",
    "privacy_policy_links_other_sites_desc3": "Menuonline 사이트에서 링크를 통해 제품이나 서비스를 구매하는 경우 Menuonline이 아니라 공급업체의 규칙과 규정에 따라 구매 거래를 완료하는 것입니다.",
    "privacy_policy_cookies_title": "개인 정보 보호 정책 쿠키",
    "privacy_policy_cookies_desc1": "Menuonline 웹사이트와 서비스는 이 정책에 따라 특정 정보를 수집하고 저장하기 위해 쿠키를 사용합니다. '쿠키'는 웹사이트에서 전송되어 사용자의 웹 브라우저가 사용자의 컴퓨터에 저장하는 작은 텍스트 파일입니다. 쿠키를 사용하는 사이트를 방문하면 쿠키가 컴퓨터나 모바일 기기에 다운로드됩니다. 다음에 해당 사이트를 방문하면 기기는 기본 설정, 방문한 페이지 또는 로그인 옵션과 같은 유용한 정보를 기억합니다.",
    "privacy_policy_cookies_desc2": "쿠키는 웹사이트에 기능을 추가하거나 웹사이트가 더 효율적으로 작동하도록 하는 데 널리 사용됩니다. 당사 사이트는 사용자 경험을 최적화하고 사이트 서비스가 제대로 작동하도록 하기 위해 쿠키에 의존합니다.",
    "privacy_policy_cookies_desc3": "대부분의 웹 브라우저는 브라우저 설정을 통해 쿠키를 제한하거나 차단할 수 있는 어느 정도의 제어 기능을 제공하지만, 쿠키를 비활성화할 경우 당사 웹사이트나 서비스의 특정 부분을 사용하는 데 영향을 받을 수 있습니다.",
    "privacy_policy_cookies_analytical_purpose": "<strong> 분석 목적: <\/strong> 당사는 웹사이트를 개선하기 위해 계정 소유자 활동을 분석하기 위해 쿠키를 사용합니다. 예를 들어, 당사는 계정 소유자가 사용하는 기능과 같은 집계 패턴을 살펴보기 위해 쿠키를 사용할 수 있습니다. 당사는 이러한 분석을 사용하여 당사 웹사이트의 기능과 사용자 경험을 개선하는 방법에 대한 통찰력을 얻을 수 있습니다.",
    "privacy_policy_cookies_preferences_user_experience": "<strong> 귀하의 선호 사항 및 사용자 경험: <\/strong> 당사는 방문자 및 계정 보유자에 대한 특정 정보(브라우저 유형, 서버, 언어 선호 사항, 국가 설정 등)를 수집하기 위해 쿠키를 사용하여 계정 보유자 선호 사항을 당사 웹사이트에 저장하여 계정 보유자 경험을 보다 일관되고 편리하게 만듭니다. 당사는 귀하가 당사 웹사이트를 반복적으로 방문할 때 귀하의 로그인 상태를 유지하기 위해 쿠키를 사용합니다.",
    "privacy_policy_cookies_targeted_ads": "<strong> 측정 서비스 및 더 나은 타겟팅 광고 및 마케팅 제공: <\/strong> Google 및 Facebook과 같은 제3자 파트너의 쿠키, 웹 비콘 및 기타 저장 기술을 측정 서비스, 더 나은 타겟팅 광고 및 마케팅 목적으로 사용합니다. 이는 귀하가 당사 웹사이트를 방문할 때 발생합니다. 이러한 쿠키, 웹 비콘 및 기타 저장 기술을 통해 귀하가 인터넷에서 방문하는 다른 사이트에서 Menuonline 홍보 자료를 표시할 수 있습니다.",
    "privacy_policy_security_title": "보안",
    "privacy_policy_security_desc1": "Menuonline은 개인 정보를 포함한 모든 유형의 기밀 정보를 정보의 민감성에 적합한 조직적, 물리적, 기계적 및 전자적 보호 장치로 보호하기 위해 노력합니다. 당사 서버는 캐나다에 있으며 보안과 관련된 산업 표준을 따릅니다.",
    "privacy_policy_security_desc2": "그러나 당사가 유지하는 조치가 정보의 보안을 보장할 것이라고 보장할 수는 없습니다.",
    "privacy_policy_retention_title": "보유",
    "privacy_policy_retention_desc": "당사는 계정 소유자 및 연락처에 대한 개인 정보를 본 개인정보 처리방침에 명시된 목적을 위해 필요한 기간 동안 보관하며, 이용약관의 보관 조항에 따라 보관합니다.",
    "privacy_policy_access_questions_title": "접근, 질문 및 우려 사항",
    "privacy_policy_access_questions_desc": "Menuonline에 개인 정보를 제출한 개인은 Menuonline이 보관하는 자신의 개인 정보에 대한 접근이나 수정을 요구하는 서면 요청을 제출할 수 있습니다.",
    "terms_obj": {
        "terms_conditions_title": "이용 약관",
        "terms_conditions_subtitle": "신규 및 기존 고객을 위한 이용 약관",
        "terms_conditions_desc1": "다음 주요 사항(섹션 참조 포함)은 귀하의 편의를 위해 제공되며 전체 약관을 대신하지 않습니다. Menuonline 서비스를 사용하기 전에 해당 약관을 읽는 것은 귀하의 책임입니다.",
        "terms_conditions_desc2": "Menuonline을 사용한다는 것은 귀하가 이용 약관에 동의하고 귀하의 조직 또는 회사에서 그렇게 하도록 허가를 받았다는 것을 진술하고 보증한다는 것을 의미합니다. (s.3 “작동 방식” 참조)",
        "terms_conditions_desc3": "Menuonline은 허가 기반 마케팅 서비스입니다. 고의로 \"스팸\"을 보내거나 이용 약관을 위반하면 계정이 폐쇄됩니다. (s.16 \"서비스의 허용 가능한 사용\" 참조)",
        "terms_conditions_desc4": "Menuonline에서 호스팅하는 데이터, 예를 들어 연락처 기록, 이메일 내용 및 Menuonline을 통해 수집한 정보는 귀하의 소유입니다. (s.14 “귀하가 소유한 것은 무엇입니까?” 참조).",
        "terms_conditions_desc5": "Menuonline 소프트웨어와 당사 웹사이트의 콘텐츠는 당사 소유입니다. (s.13 “당사는 무엇을 소유합니까?” 참조)",
        "terms_conditions_desc6": "귀하의 사용자 외에 Menuonline에서 호스팅하는 데이터 또는 미공개 콘텐츠는 기밀 유지 계약에 서명한 Menuonline 직원 및 파트너만 볼 수 있습니다. (s.14 “귀하가 소유한 것은 무엇입니까?” 참조).",
        "terms_conditions_desc7": "모든 개인 식별 정보(PII)는 캐나다에 있는 서버에만 보관됩니다. (s.14 “무엇을 소유하고 있습니까?” 참조).",
        "terms_conditions_desc8": "Menuonline 서비스는 캐나다 및 온타리오주의 법률을 따릅니다. (s.24 “준거법” 참조)",
        "terms_conditions_desc9": "캐나다 내부 또는 외부의 개인, 경찰 또는 기타 규제 기관에서 귀하의 데이터 또는 콘텐츠에 대한 요청은 항상 귀하에게 전달됩니다. 유일한 예외는 캐나다 또는 온타리오 주법을 위반하는 경우입니다. 그러나 모든 경우에 귀하 외의 다른 사람이 귀하의 데이터를 공개하는 것을 강력히 거부합니다. (s.14 \"귀하가 소유한 것은 무엇입니까?\" 참조).",
        "terms_conditions_desc10": "Menuonline 보안은 사용자 이름과 비밀번호를 잘 관리하는 것만큼만 좋습니다. Menuonline 사용자 계정은 요금을 부과하지 않으므로 각 사용자마다 별도의 계정을 만들고 비밀번호를 비밀로 유지하세요. 귀하는 귀하의 계정에 대한 책임이 있습니다. (s.3 “작동 방식” 참조)",
        "terms_conditions_desc11": "언제든지 계정을 취소할 수 있습니다. 취소 시 사용하지 않은 크레딧에 대한 환불은 제공하지 않습니다. (3절 \"작동 방식\" 참조)",
        "terms_conditions_desc12": "우리는 언제든지 귀하의 계정을 취소할 수 있습니다. 귀하의 계정을 취소하는 경우 이메일 크레딧과 사용하지 않은 서비스에 대한 환불을 제공합니다. (s.3 “작동 방식” 참조)",
        "terms_conditions_desc13": "귀하의 데이터는 취소 후 삭제되며, 계정 사용자가 12개월 이상 로그인하지 않은 경우에도 삭제될 수 있습니다. 통계 목적으로 개인 식별 정보가 포함되지 않은 귀하의 계정에서 집계된 데이터를 유지합니다. (s.3 “작동 방식” 참조)",
        "terms_conditions_desc14": "이메일 크레딧은 종량제 방식으로 구매되며 만료되지 않습니다. 유일한 예외는 12개월 동안 계정에 로그인하지 않은 경우입니다. 이 경우, 당사는 귀하의 계정, 귀하의 데이터 및 관련 크레딧을 삭제할 권리가 있습니다. (10절 \"서비스에 대해 얼마를 지불해야 합니까?\" 참조)",
        "terms_conditions_desc15": "저희는 Menuonline이 항상 가동되도록 최선을 다하고 있으며 유지 관리를 위해 일시적으로 서비스를 중단해야 하는 경우 사전 통지를 제공합니다. 그러나 저희는 서비스가 항상 가동될 것이라고 보장하지 않으며, 시스템이 필요한 시간에 실행되지 않는 경우 발생하는 손실을 포함하여 Menuonline을 사용함으로써 발생하는 모든 손실에 대해 책임을 지지 않습니다. (s.17 “면책 조항” 참조)",
        "terms_conditions_desc16": "우리는 귀하의 콘텐츠나 귀하가 누구에게 메시지를 보내는지 모니터링하지 않습니다. 그러나 귀하의 활동으로 인해 발생한 불만 및 기타 문제는 모니터링합니다. 불만이나 문제가 너무 많으면 당사의 재량에 따라 귀하의 서비스가 취소될 수 있습니다. (s.7 “모니터링” 참조)",
        "terms_conditions_desc17": "저희는 Menuonline에서 자립할 수 있도록 돕기 위해 탁월한 제품 지원을 제공하는 데 전념합니다. 저희는 웹사이트, Menuonline 지원 티켓 시스템 및 전화로 문서 및 비디오 형태로 지원을 제공합니다. 제품 지원에는 비용이 청구되지 않지만, 저희는 재량에 따라 특정 클라이언트 계정에 대한 지원을 중단할 권리를 보유합니다. (s.5 “지원” 참조)",
        "terms_conditions_desc18": "제품 서비스는 직접 하기보다는 Menuonline과 관련된 작업을 해달라고 요청하는 경우를 위한 것입니다. 제품 서비스에 대한 요금은 시간당 135달러입니다. 또한 더 높은 요금으로 컨설팅 서비스도 제공합니다. 당사는 언제든지 요금을 변경할 권리가 있습니다. (6절 \"제품 및 컨설팅 서비스\" 참조)",
        "terms_conditions_desc19": "귀하의 데이터가 침해되었다고 생각되면 알려드리고 관련 세부 정보와 정보를 제공하기 위해 노력할 것입니다. (14절 “귀하가 소유한 것은 무엇입니까?” 참조)",
        "terms_conditions_desc20": "당사는 언제든지 이 계약을 변경할 권리가 있습니다. 당사는 이용 약관의 변경 사항을 귀하에게 알리려고 노력하지만 서비스를 사용하면 귀하는 이용 약관의 변경 사항에 동의하는 것입니다. (s.9 “변경” 참조)",
        "terms_conditions_desc21": "이용 약관 및 개인정보 보호정책은 영어로만 작성되었으며, 번역된 버전과 충돌하는 경우 영어 버전의 이용 약관 및 개인정보 보호정책이 우선한다는 데 동의합니다.",
        "terms_conditions_mycircle": "MYCIRCLE 이용약관",
        "terms_conditions_last_update": "마지막 업데이트: 2020년 1월 14일",
        "terms_conditions_usage_agreement": "이 웹사이트(이하 '사이트')를 사용하기 전에 이 서비스 약관(이하 '약관')을 주의 깊게 읽어보세요. 이 약관은 Menuonline(이하 'Menuonline', '우리' 또는 '저희') 및 기타 사람들의 책임을 면제하고\/하거나 저희와 그들의 책임을 제한하며 이 사이트 사용에 적용되는 기타 중요한 조항을 포함합니다.",
        "terms_conditions_acceptance": "이 사이트나 서비스를 방문하거나 이용함으로써 귀하는 본인 및 귀하가 대신 행동할 수 있는 모든 조직을 대신하여(이하 '귀하'라 함) 이 사이트를 방문하거나 이용할 때마다 이러한 약관을 수락하고 준수하는 데 동의합니다.",
        "terms_conditions_services": "1. 당사 서비스",
        "terms_conditions_services_desc": "사이트를 통해 제공되는 서비스는 귀하가 선택한 수신자에게 이메일 메시지('이메일')를 작성, 발송 및 관리할 수 있는 서비스입니다('서비스').",
        "terms_conditions_license": "2. 라이센스.",
        "terms_conditions_license_desc": "이러한 약관에 따라 Menuonline은 귀하에게 기간 동안 사이트를 보고 서비스의 기능에 따라 허용되는 대로 서비스를 사용할 수 있는 비독점적이고 제한적이며 양도 불가능한 라이선스를 부여합니다. 당사는 서비스와 Menuonline 콘텐츠(아래 정의)에 대해 여기에 명시적으로 부여되지 않은 모든 권리를 보유합니다.",
        "terms_conditions_account": "3. 작동 원리",
        "terms_conditions_account_desc": "서비스를 사용하려면 계정을 등록해야 합니다. 귀하의 계정은 무료입니다. 귀하의 계정은 귀하에게 서비스와 당사가 수시로 설정하고 유지할 수 있는 \"기능\"에 대한 액세스 권한을 제공합니다. 계정 자격을 갖추려면 다음이 필요합니다.",
        "terms_conditions_account_requirements": [
            "최소한 18세(18) 이상이고 계약을 체결할 수 있어야 합니다.",
            "우리가 정한 등록 절차를 완료하세요.",
            "본 이용약관에 동의합니다.",
            "진실하고 완전하며 최신의 연락처, 청구\/지불 정보를 제공하세요."
        ],
        "responsibility": "책임.",
        "responsibilityText": "귀하는 귀하의 계정에서 발생하는 활동에 대해 전적으로 책임을 져야 하며, 귀하의 계정 비밀번호를 기밀로 유지하고 안전하게 보호해야 합니다. 계정은 무료이므로, 귀하가 소유한 각 사용자에 대해 다른 계정을 설정하는 것이 좋습니다. 또한 귀하는 해당 계정의 사용을 허가했는지 여부와 관계없이 귀하가 액세스할 수 있는 모든 계정에 대해 책임을 져야 합니다. 귀하는 이메일을 보내는 사람과의 상호 작용에 대해 전적으로 책임을 져야 하며, 귀하의 동의를 받은 사람에게만 이메일을 보내는 데 동의합니다. 귀하는 귀하의 계정에 대한 무단 사용이 있는 경우 즉시 당사에 알려야 합니다.",
        "compliance_with_CASL": "캐나다 스팸 방지 법률(CASL)을 준수합니다.",
        "compliance_with_CASL_text": "Menuonline의 역할은 법률을 시행하는 것이 아니라 동의 설정 관리를 용이하게 하는 것입니다. CASL을 준수하는 것은 귀하의 책임입니다. 기본 기능을 설정하고 문서화된 명시적 동의를 얻고 업데이트하고 묵시적 동의에 대한 롤링 만료 날짜를 관리하여 귀하를 돕습니다. 귀하는 귀하의 데이터베이스에 대한 동의 관리를 완전히 제어할 수 있습니다. 언제든지 모든 연락처에 대한 동의 상태를 대량으로 업데이트하고, 기본 설정을 재정의하고, 묵시적 동의 상태의 자동 만료를 비활성화할 수 있습니다.",
        "closing_account": "계정 폐쇄.",
        "closing_account_text": "귀하 또는 Menuonline은 상대방에게 통지함으로써 언제든지 어떤 이유로든 본 계약을 해지할 수 있습니다. 귀하가 허위, 부정확, 최신이 아니거나 불완전한 정보를 제공하거나 본 이용 약관을 위반하는 방식으로 서비스를 사용하는 경우, 당사는 귀하의 계정을 즉시 정지하고 서비스에 대한 귀하의 접근 권한을 종료할 권리가 있으며, 당사가 이용할 수 있는 다른 구제책도 있습니다. 귀하가 12개월(12) 동안 계정을 사용하지 않으면 당사는 귀하의 계정을 취소할 수 있습니다. 당사가 어떤 이유로든 귀하의 계정을 해지하는 경우, 당사는 귀하에게 남은 크레딧을 환불합니다. 해지되면 당사는 귀하의 계정과 귀하가 보낸 이메일을 포함하여 관련된 모든 데이터를 영구적으로 삭제합니다. 당사는 통계 목적으로 개인 식별 정보가 포함되지 않은 귀하의 계정에서 집계된 데이터를 유지할 수 있습니다.",
        "emailing_content": "이메일로 내용 전송.",
        "emailing_content_text": "이 사이트나 서비스를 통해 Menuonline과 상호 작용할 때, 귀하는 진실하고 정확한 정보를 제공하는 데 동의합니다. 또한 귀하는 이메일을 통해 기밀 정보를 제공하는 것이 안전하지 않은 관행임을 이해합니다. 귀하는 당사 서비스를 사용하여 누군가에게 보내는 모든 것에 대해 전적으로 책임을 져야 함을 이해합니다. 목적.",
        "support": "지원하다.",
        "support_text": "당사는 귀하가 서비스를 통해 자립할 수 있도록 지원합니다. 당사는 사이트의 문서 및 비디오, Menuonline 지원 티켓 시스템 및 전화로 지원을 제공합니다. 사이트 상단의 \"지원\" 탭을 클릭하면 당사에 연락할 수 있습니다. 제품 지원에는 비용이 청구되지 않지만, 당사는 재량에 따라 특정 클라이언트 계정에 대한 지원을 중단할 권리를 보유합니다.",
        "product_and_consulting_services": "제품 및 컨설팅 서비스.",
        "product_and_consulting_services_text": "제품 서비스는 직접 하기보다는 Menuonline과 관련된 작업을 해달라고 요청하는 경우를 위한 것입니다. 제품 서비스에 대한 요금은 시간당 125달러입니다. 또한 더 높은 요금으로 컨설팅 서비스도 제공합니다. 언제든지 요금을 변경할 권리가 있습니다.",
        "monitoring": "모니터링.",
        "monitoring_text": "당사는 서비스를 통해 전송된 정보의 내용을 모니터링하거나 판단하지 않지만, 부적절한 사용에 대한 불만이 접수되면 조사할 것입니다. Menuonline은 언제든지 단독 재량에 따라 다음 지침에 따라 귀하에게 통지 여부와 관계없이 특정 사용이 적절하거나 적절하지 않은지 판단할 수 있습니다.",
        "term": "용어.",
        "term_text": "기간은 귀하가 당사에 계정을 등록할 때 시작되어 서비스를 사용하는 한 계속됩니다. 귀하는 당사를 대신하여 계정을 등록할 권한과 능력이 있음을 진술하고 보증합니다(조직을 대신하여 등록하는 경우 포함).",
        "changes": "변화.",
        "changes_text": "기간은 귀하가 당사에 계정을 등록할 때 시작되어 서비스를 사용하는 한 계속됩니다. 귀하는 당사를 대신하여 계정을 등록할 권한과 능력이 있음을 진술하고 보증합니다(조직을 대신하여 등록하는 경우 포함).",
        "payment": "지불",
        "what_do_i_pay_for_service": "서비스에 대한 비용은 얼마인가요?.",
        "what_do_i_pay_for_service_text": "당사 사이트의 \"가격\" 페이지에 자세히 설명된 대로, 당사 서비스를 통해 이메일을 보내기 위한 선불 크레딧(\"크레딧\")을 구매할 수 있습니다. 크레딧은 만료되지 않으며 이러한 크레딧에 대한 반복 수수료는 없습니다. 유일한 예외는 귀하가 12개월(12) 동안 귀하의 계정을 사용하지 않으면 귀하의 계정과 사용하지 않은 크레딧을 취소할 수 있다는 것입니다. 당사는 언제든지 당사 사이트의 가격 페이지를 업데이트하여 가격을 변경할 수 있으며, 이는 귀하의 다음 크레딧 구매에 적용됩니다.",
        "how_do_i_pay_for_service": "서비스 비용은 어떻게 지불하나요?.",
        "how_do_i_pay_for_service_text": "모든 주요 신용 카드를 사용하여 크레딧을 지불할 수 있습니다. 당사는 언제든지 수락하는 지불 방법을 변경할 수 있습니다.",
        "can_i_get_refund": "환불을 받을 수 있나요?.",
        "can_i_get_refund_text": "계정을 취소하는 경우 서비스 사용에 대해 지불한 금액은 환불되지 않습니다. 계정을 취소하는 경우 남은 크레딧을 환불해 드립니다.",
        "rights": "진상",
        "what_do_we_own": "우리는 무엇을 소유하고 있는가?.",
        "what_do_we_own_text": "귀하가 당사에 제공하는 콘텐츠(연락처 기록, 이메일 콘텐츠 및 서비스를 통해 수집한 정보 포함하되 이에 국한되지 않음)(이하 \"귀하의 콘텐츠\")를 제외하고, 당사 사이트, 서비스 및 이에 포함되거나 이를 통해 전송되는 모든 자료(소프트웨어, 이미지, 텍스트, 그래픽, 일러스트레이션, 로고, 특허, 상표, 서비스 마크, 사진, 오디오, 비디오, 음악 및 다른 사용자의 콘텐츠(이하 \"Menuonline 콘텐츠\") 포함하되 이에 국한되지 않음)(및 이와 관련된 모든 지적 재산권)는 당사 또는 당사 라이센스 제공자가 소유합니다.",
        "what_do_you_own": "당신은 무엇을 소유하고 있나요?.",
        "what_do_you_own_text": "당사는 귀하의 콘텐츠에 대한 소유권을 주장하지 않습니다. 귀하의 콘텐츠는 귀하의 것이며 귀하는 귀하가 서비스에 업로드한 귀하의 콘텐츠에 대한 소유권을 유지합니다. 서비스를 통해 귀하의 콘텐츠를 공유함으로써 귀하는 귀하의 설정 및 본 이용 약관에 따라 다른 사람이 귀하의 콘텐츠를 보고, 편집하고,\/또는 공유하도록 허용하는 데 동의합니다. 귀하는 귀하가 이메일에서 사용하는 모든 자료를 소유하거나 사용할 수 있는 권한이 있음을 진술하고 보증합니다. 당사는 본 이용 약관 및 개인정보 보호정책에 설명된 대로만 귀하의 자료를 사용하거나 공개할 수 있습니다.",
        "privacy_statement": "Menuonline은 사용자의 개인 정보를 존중합니다. Menuonline이 이 사이트 또는 서비스를 통해 수집하는 모든 개인 정보는 이 약관에 참조로 통합된 Menuonline 개인 정보 보호 정책의 적용을 받습니다. 당사는 개인 정보 보호 정책에 따라 귀하의 정보를 사용하고 공개할 수 있습니다.",
        "access_to_your_content": "Menuonline은 적절한 기밀 유지 계약을 체결한 Menuonline의 직원 및 계약자 외에는 누구에게도 귀하의 콘텐츠에 대한 접근 권한을 제공하지 않습니다. 또한 귀하의 동의도 받지 않습니다.",
        "governmentRequest": "정부 또는 규제 기관에서 귀하의 콘텐츠에 대한 접근 요청을 받으면, 당사는 귀하의 콘텐츠를 공개하기 전에 해당 요청을 귀하에게 알리고 귀하의 동의를 구할 것입니다. 당사는 귀하의 콘텐츠에 대한 모든 요청을 거부하고 귀하의 허가 없이 또는 관할 법원 또는 해당 규제 기관의 요구 없이 이러한 유형의 요청에 대한 응답으로 귀하의 콘텐츠를 공개하지 않을 것입니다.",
        "breachNotification": "귀하의 콘텐츠가 이러한 약관을 위반하여 허가되지 않은 접근, 사용, 복사, 도난, 손실 또는 사용 또는 공개된 사실을 알게 되는 경우(이하 '위반'이라 함), 당사는 즉시 귀하에게 통지하고 발생한 사항을 알려드립니다. 귀하는 위반 사항이 해당 법률에 따라 처리될 것에 동의하며, 귀하가 귀하의 이메일 배포 목록의 구성원에게 알려야 하는 경우 즉시 당사가 지시하는 대로 알리는 데 동의합니다.",
        "contentRetention": "귀하의 사전 서면 승인 또는 지시(예: 귀하가 캐나다 외부의 개인에게 귀하의 콘텐츠를 보내기로 선택한 경우)가 없는 한, 당사는 귀하의 콘텐츠를 캐나다에서 보관, 사용 및 전송합니다. 서비스를 제공하는 데 사용되는 서버는 캐나다에 있습니다.",
        "accessTrouble": "귀하가 귀하의 콘텐츠에 접근하는 데 문제가 있는 경우 Menuonline은 시간 및 자료 기준으로 귀하의 콘텐츠를 검색하도록 도와드립니다.",
        "reportingAbuse": "누군가가 이러한 약관을 위반하고 있다고 생각되면 즉시 알려주십시오. 서비스의 다른 사용자로부터 온 것으로 생각되는 스팸을 받거나, 서비스의 다른 사용자가 저작권을 위반하는 자료를 게시했다고 생각되거나, 서비스의 다른 사용자가 이러한 약관을 위반하고 있다고 생각되면 Support@menuonline.com으로 문의하십시오.",
        "acceptableUse": "귀하는 귀하의 서비스 사용이 모든 해당 법률 및 규정을 준수할 것임을 진술하고 보증합니다. 귀하는 귀하의 관할권에서 서비스가 준수되고 귀하가 사용하기에 적합한지 여부를 판단할 책임이 있음에 동의합니다. 보다 확실하게 하기 위해 귀하는 (귀하의 직원, 대리인 및 승인된 사용자가) ...",
        "exportLaws": "당사는 사이트 또는 서비스가 캐나다 외부에서 사용하기에 적절하거나 사용 가능하다는 진술을 하지 않으며, 콘텐츠가 불법인 지역에서 이에 액세스하는 것은 금지됩니다. 귀하는 캐나다 및 미국 수출법 및 규정을 포함하되 이에 국한되지 않는 해당 법률 또는 규정을 위반하여 Menuonline 콘텐츠 또는 이 사이트의 다른 자료나 사본 또는 각색을 사용하거나 수출 또는 재수출할 수 없습니다.",
        "disclaimers": "본 서비스와 사이트는 어떠한 종류의 진술, 보증 또는 조건 없이 '있는 그대로' 제공되며, Menuonline은 해당 법률에서 허용하는 최대 범위 내에서 상품성, 특정 목적이나 용도에 대한 적합성, 제3자 권리의 비침해에 대한 묵시적 보증 및 조건을 포함하되 이에 국한되지 않고, 법의 적용 또는 기타 방식에 의해 명시적이든 묵시적이든 모든 진술, 보증 및 조건을 명확하게 부인합니다.",
        "limitLiability": "이 계약에 따른 Menuonline의 누적 책임은 계약 위반, 불법행위(과실 포함), 보증 위반, 엄격책임 또는 기타 책임 이론에 따른 것인지 여부를 불문하고, 이러한 책임은 가장 최근에 책임을 발생시킨 사건이 발생한 달의 바로 전 12(12)개월 동안 이 약관에 따라 Menuonline에 지불한 총 수수료를 초과하지 않는 직접 손해로 제한됩니다.",
        "liabilityExclusions": "Menuonline, 그 계열사 또는 각각의 이사, 임원, 직원, 대리인 또는 기타 대표자(총칭하여 'Menuonline \"대표자\") 또는 연결된 네트워크 운영자는 이 사이트 또는 서비스와 관련하여 다음에 대한 책임이나 의무를 지지 않습니다. (i) 간접적, 결과적, 우발적, 모범적, 징벌적 또는 특별 손해; 또는 (ii) 직접적, 간접적, 결과적, 우발적, 모범적, 징벌적 또는 특별 손해를 불문하고 수익 손실, 저축 또는 수익 손실 또는 이익 손실로 특징지어지는 손해...",
        "indemnity": "귀하는 Menuonline 및\/또는 Menuonline 담당자(이하 'Menuonline 면책 당사자')를 이 사이트에서 접근한 정보 사용 또는 서비스 사용과 이러한 약관 위반으로 인해 제3자가 제기한 모든 청구 및 당사에 부과된 모든 벌금이나 벌칙에 대해 보상하고 면책해야 합니다.",
        "ipInfringement": "Menuonline은 본 계약에 따라 귀하가 서비스에 접근하거나 서비스를 이용하는 것이 제3자의 캐나다 법에 따른 지적 재산권을 침해한다는 주장을 하는 제3자가 귀하를 상대로 제기한 모든 청구, 요구, 조치, 소송 또는 절차(이하 'IP 침해 청구'라 함)로부터 귀하를 방어하고 면책하며 보상해 드립니다.",
        "equitableRelief": "귀하가 이 약관을 위반하는 경우, 당사는 가처분 구제(즉, 귀하를 중단하기 위해 법원 명령을 요청할 수 있음) 또는 기타 형평적 구제를 구할 수 있습니다. 귀하는 이것이 당사가 법률 또는 형평에 따라 가질 수 있는 다른 권리에 추가되며 이를 침해하지 않는다는 데 동의합니다.",
        "linksToOtherSites": "타사 웹사이트에 대한 링크를 제공하는 경우, 이는 편의를 위해서만 제공됩니다. 이러한 링크를 사용하면 당사 사이트를 떠나게 됩니다. 당사는 이러한 타사 사이트를 통제하거나 보증하지 않습니다. 귀하는 당사가 이러한 타사 사이트나 해당 콘텐츠에 대해 책임을 지지 않는다는 데 동의합니다...",
        "thirdPartyRequests": "귀하의 계정 또는 서비스 사용과 관련된 법적 또는 정부 요청에 응답하여 정보를 제공해야 하는 경우, 귀하는 당사가 귀하에게 비용을 청구할 수 있다는 데 동의합니다. 이러한 비용에는 당사의 법률 비용과 당사 직원이 요청에 대한 응답을 준비하는 데 소요된 시간 비용이 포함될 수 있습니다.",
        "assignment": "본 계약과 이에 따라 부여된 모든 권리와 라이선스는 귀하가 양도하거나 할당할 수 없지만 Menuonline은 합리적인 사전 통지를 통해 제한 없이 할당할 수 있습니다.",
        "governingLaw": "이러한 약관은 모든 해당 연방법 및 온타리오주의 법률에 따라 지배되고 해석되며, 해당 법률의 충돌 조항은 고려되지 않습니다. 귀하는 이러한 약관과 관련된 모든 분쟁의 결정을 위해 온타리오주 법원의 전속 관할권에 복종하는 데 동의합니다.",
        "severability": "어떤 이유로든 관할 법원이 본 약관의 어떤 조항 또는 일부를 불법으로 판결하거나 어떤 이유로든 시행할 수 없다고 판단하는 경우, 해당 조항은 본 약관의 의도를 실현하는 데 허용되는 최대 한도까지 시행되고, 본 약관의 나머지 조항은 계속해서 완전한 효력을 유지하며 시행 가능합니다.",
        "waiver": "이러한 약관의 요건에 대한 포기 또는 이탈에 대한 동의는 서면으로 작성되어 당사가 서명한 경우에만 유효하며, 해당 조항이 부여된 특정 사례 및 특정 목적에만 유효합니다. 당사가 이러한 약관에 따른 권리를 행사하지 않거나 행사를 지연하더라도 당사의 권리 포기로 간주되지 않습니다.",
        "furtherAssurances": "각 당사자는 본 약관에 따른 의무를 이행하는 데 필요한 모든 서류에 서명하고 이를 제공하고 모든 조치를 취(또는 그러한 조치를 취하게 함)하는 데 동의합니다.",
        "notices": "Menuonline에 대한 모든 통지는 서면으로 작성하여 이메일로 보내야 합니다. 당사에 대한 통지는 support@menuonline.com의 고객 서비스 담당자에게 보내야 합니다. 귀하에게 보내는 통지는 귀하가 등록 시 제공한 주소 또는 이메일 주소로 보낼 수 있습니다. 또한, 당사는 이 사이트의 변경 사항 또는 기타 중요한 사항을 알리기 위해 이 사이트를 통해 통지 또는 메시지를 방송할 수 있으며, 이러한 방송은 당사가 게시할 당시 귀하에게 보내는 통지로 간주합니다.",
        "entireAgreement": "이러한 약관과 당사의 개인정보 보호정책은 귀하의 사이트 및 서비스 사용과 관련하여 귀하와 당사 간의 전체 계약을 구성하며, 이 주제와 관련하여 Menuonline과 귀하 간의 모든 이전 계약, 진술 및 이해관계를 대체합니다. 개인정보 보호정책과 이러한 약관 사이에 충돌이 있는 경우, 이러한 약관이 적용됩니다.",
        "language": "당사자들은 이 약관, 개인정보 보호정책 및 관련 문서가 영어로 작성되기를 명시적으로 요청하고 요구했습니다. 당사자들은 이 계약이 편리하고 긴급하다고 표현하며 모든 문서가 영어로 작성되어야 한다고 주장합니다. 귀하는 번역된 버전과 충돌이 있는 경우 이 약관 및 개인정보 보호정책의 영어 버전이 우선한다는 데 동의합니다.",
        "survival": "본 약관의 모든 조항 중 본질이나 중요성으로 인해 본 약관의 만료 또는 종료 후에도 유효하도록 의도된 조항은 제17조~제19조를 포함하되 이에 국한되지 않고 유효합니다."
    },
    "collect_additional_info": "추가 정보를 수집하시겠습니까?",
    "additionalInfoChoice": "수집하고 싶은 추가 정보를 선택하세요.",
    "additional_info_yes": "예",
    "additional_info_noSkip": "아니, 건너뛰세요",
    "additional_info_label": "추가 정보",
    "confirm_business_location": "먼저 사업장 위치를 확인해 보세요.",
    "custom_get_more_reviews_form": "사용자 정의 더 많은 리뷰 양식 받기",
    "set_up_custom_form": "그룹별로 사용자 정의 추가 리뷰 양식 설정",
    "reputation_name_label": "더 많은 리뷰 이름 받기",
    "reputation_name_placeholder": "평판 이름을 입력하세요",
    "group_label": "그룹",
    "group_desc_title": "그룹 설명 제목",
    "group_desc": "그룹 설명",
    "select_group_placeholder": "그룹 선택",
    "required_group_name": "그룹 이름이 필요합니다",
    "before_asking_review": "고객에게 리뷰를 요청하기 전에, 1~4개의 질문을 던져보세요.",
    "feel_free_edit": "필요에 따라 자유롭게 편집하세요.",
    "incentive_settings": "인센티브 설정",
    "incentivize_customer": "고객에게 리뷰를 남기도록 인센티브를 제공하고 싶으신가요?",
    "choose_incentive": "추천하고 싶은 인센티브를 선택하세요.",
    "scan_qr_feedback": "QR 코드를 스캔하여 귀하의 경험에 대한 귀중한 피드백을 공유해 주세요.",
    "qr_code": "QR 코드",
    "scan_me": "나를 스캔해!",
    "or_title": "또는",
    "send_otp_title": "OTP 보내기",
    "sign_in_title": "로그인",
    "email_addresss_title": "이메일 주소",
    "reset_password_title": "비밀번호 재설정",
    "remember_password_title": "비밀번호를 기억하셨나요?",
    "digit_code_title": "전송된 4자리 코드를 입력하세요. ",
    "not_received_title": "인증코드를 받지 못했습니다!",
    "resend_title": "다시 보내주세요",
    "sign_up_form": "계정 생성",
    "sign_up_sub_title": "지금 로그인하여 최신 통찰력에 액세스하세요",
    "already_registered_title": "이미 Menuonline 계정이 있으신가요?",
    "reserved_title": "저작권 ©2023 Menuonline. 모든 권리 보유.",
    "terms_title": "서비스 약관",
    "policy_title": "개인정보 보호정책",
    "sign_up_title": "가입하기",
    "terms_conditions_title": "이용약관",
    "business_type_title": "사업 유형",
    "business_type_sub_title": "귀하의 사업을 더 잘 설명하는 사업 유형 중 하나를 선택하세요!",
    "salon_title": "가게",
    "restaurant_title": "식당",
    "pizza_store_title": "피자 가게",
    "food_truck_title": "푸드트럭",
    "check_domain_title": "도메인 이름 확인",
    "start_exploring_title": "탐색을 시작하세요",
    "sign_in_form": "귀하의 계정에 로그인하세요",
    "sign_in_sub_title": "지금 로그인하여 최신 통찰력에 액세스하세요",
    "remember_title": "나를 기억해",
    "otp_title": "오티피",
    "password_title": "비밀번호",
    "forgot_title": "비밀번호를 잊으셨나요?",
    "not_registered_title": "계정이 없으신가요?",
    "create_account_title": "지금 가입하세요",
    "create_form_title": "양식 만들기",
    "first_name_title": "이름",
    "last_name_title": "성",
    "email_title": "이메일",
    "mobile_number_title": "휴대폰 번호",
    "birthday_title": "생일",
    "gender_title": "성별",
    "address_title": "주소",
    "short_answer_title": "짧은 대답",
    "long_answer_title": "긴 답변",
    "single_answer_title": "단일 답변",
    "single_checkbox_title": "단일 체크박스",
    "multiple_choice_title": "다중 선택",
    "drop_down_title": "드롭다운",
    "yes_no_title": "예 또는 아니오",
    "description_text_title": "설명 텍스트",
    "file_upload": "파일 업로드",
    "reset_password_form": "비밀번호 재설정",
    "reset_password_sub_title": "신속하게 비밀번호를 재설정하고 계정에 접속하세요.",
    "required_full_name_title": "전체 이름이 필요합니다",
    "required_first_name_title": "이름은 필수입니다",
    "valid_first_name_title": "유효한 이름을 입력하세요",
    "valid_full_name_title": "유효한 전체 이름을 입력하세요",
    "required_block_time": "블록 시간이 필요합니다",
    "required_last_name_title": "성이 필요합니다",
    "valid_last_name_title": "유효한 성을 입력하세요",
    "valid_mobile_number_title": "유효한 휴대폰 번호를 입력하세요",
    "required_mobile_number_title": "휴대폰 번호가 필요합니다",
    "required_already_mobile_number_title": "휴대폰 번호가 이미 필요합니다",
    "valid_otp_title": "유효한 OTP를 입력하세요",
    "valid_email_title": "유효한 이메일을 입력하세요",
    "required_email_title": "이메일이 필요합니다",
    "required_description_title": "설명이 필요합니다",
    "required_description_500_characters": "최소 500자 이상을 입력해주세요.",
    "required_description_600_characters": "최대 600자를 입력하세요.",
    "domain_title": "도메인 이름",
    "required_password_title": "비밀번호가 필요합니다",
    "required_password_regex_title": "최소 8자 이상, 대문자 1자 이상, 소문자 1자 이상, 숫자 1자, 특수문자 1자를 포함해야 합니다.",
    "required_terms_title": "이용약관에 동의해주세요.",
    "required_note_title": "메모가 필요합니다",
    "required_birthday_title": "생일은 필수입니다",
    "no_access_title": "지금은 액세스할 수 없습니다. 관리자에게 문의하세요.",
    "menu_online_open_txt": "열려 있는",
    "loyalty_program_description": "😊 귀하에게 이상적인 로열티 프로그램을 찾아 귀하의 브랜드에 맞게 맞춤 설정하세요!",
    "lp_list_title": "충성도 펀치 카드 목록",
    "lp_n_title": "로열티 펀치 카드 이름",
    "enter_details_title": "세부 정보 입력",
    "stamp_allowed_title": "스탬프 허용",
    "stamp_items_title": "스탬프 항목",
    "lpn_req_title": "로열티 펀치 카드 이름이 필요합니다.",
    "valid_lpn_title": "유효한 로열티 펀치 카드 이름을 입력하세요",
    "brand_color_error": "브랜드 색상은 정확히 7자여야 합니다.",
    "select_dark_brand_color_title": "유효한 브랜드 색상을 선택해주세요.",
    "your_brand_color_title": "귀하의 브랜드 색상",
    "upload_logo_title": "로고 업로드",
    "locations_title": "위치",
    "add_lp_title": "프로그램 생성",
    "location_error": "위치를 선택해주세요",
    "pvs_title": "고객은 방문 당 얼마나 많은 스탬프를 적립할 수 있나요?",
    "max_apd_title": "고객이 하루에 적립할 수 있는 스탬프의 최대 개수는 얼마인가요?",
    "logo_error": "로고가 필요합니다",
    "expiry_title": "보상에 만료일 설정",
    "add_more_rewards_title": "+ 더 많은 보상 추가",
    "prev_step_1_title": "QR 코드를 보여주시면 포인트를 적립하고 보상을 받으실 수 있습니다.",
    "delete_lp_title": "이 로열티 펀치 카드는 영구적으로 삭제됩니다.",
    "delete_user_record_title": "이 사용자 기록은 영구적으로 삭제됩니다.",
    "redeem_title": "상환하다",
    "lp_button_title": "Loyalty Punch Card에 가입하세요",
    "lp_download_button_title": "카드 다운로드",
    "step_0_title": "지금 가입하세요. 여러분처럼 충성스러운 미식가를 위한 맛있는 보상이 여러분을 기다리고 있습니다.",
    "step_1_title": "당신은 가입했습니다",
    "congrats_step_title": "이제 무료 피자를 받으실 수 있습니다.",
    "reward_title": "방문할 때마다 보상을 받으세요",
    "visit_title": "방문 및 잠금 해제",
    "rewards_title": "보상",
    "dummy_address": "3730 Frankfort Ave, Louisville Kentucky, 미국",
    "loyalty_qr_default_text": "QR 코드를 스캔하고 로열티 펀치 카드에 가입하고 보상을 받으세요",
    "lp_earn_stamp_txt": "고객은 방문할 때마다 스탬프를 적립합니다.",
    "lp_get_rewards_txt": "방문할 때마다 보상을 받으세요",
    "lp_1_time_visit_txt": "1회 방문하면 얻을 수 있는 것",
    "lp_uppercase_stamp_text": "우표",
    "lp_lowercase_stamp_text": "우표",
    "lp_txt": "충의",
    "lp_per_visit_stamp_validation_msg": "방문당 스탬프는 하루에 허용되는 최대 스탬프보다 적어야 합니다.",
    "lp_per_max_stamp_validation_msg": "하루에 허용되는 최대 스탬프는 방문당 스탬프보다 커야 합니다.",
    "lp_valid_value_msg": "유효한 값을 입력하세요",
    "lp_required_field_msg": "이 필드는 필수입니다",
    "lp_stamp_required_msg": "스탬프가 필요합니다",
    "lp_valid_stamp_msg": "유효한 스탬프 값을 입력하세요",
    "lp_reward_name_required_msg": "보상 이름이 필요합니다",
    "lp_unique_stamp_msg": "스탬프 값은 고유해야 합니다.",
    "lp_benefits": "로열티 프로그램은 개인화된 마케팅을 위한 고객 통찰력을 제공하는 동시에 재방문율과 수익을 높여줍니다.",
    "lp_how_customer_txt": "고객이 다음과 같은 방법을 사용하는지 알아보세요.",
    "lp_punch_card_txt": "충성도 펀치 카드",
    "lp_look_txt": "찾아볼게! 😍",
    "lp_desc": "고객은 방문할 때마다 스탬프를 적립하고, 스탬프가 충분히 모이면 특별 보상을 받을 수 있습니다.",
    "lp_edit": "편집하다",
    "lp_looks_good_txt": "좋아 보인다",
    "lp_customer_view_instruction": "고객이 보게 될 내용입니다. 로고를 업로드하고 색상 테마를 설정하세요.",
    "lp_customer_earn_stamps": "고객이 방문할 때마다 스탬프를 적립하고 보상을 관리할 수 있도록 합니다.",
    "lp_stamp_rewards_setup": "스탬프 적립 보상을 설정하고 고객이 스탬프 당 보상을 받을 수 있도록 합니다.",
    "lp_loyalty_program_setup": "귀하의 로열티 프로그램 이름을 입력하고 이 프로그램을 사용할 위치를 설정하세요.",
    "lp_prev_step1": "QR 코드를 보여주시면 포인트를 적립하고 보상을 받으실 수 있습니다.",
    "lp_visit_1_time_txt": "1번 방문하고 받으세요",
    "lp_get_free_txt": "무료로 받으세요",
    "lp_visit_allow_txt": "방문 및 잠금 해제",
    "lp_rewards": "보상",
    "repsT": "더 많은 리뷰를 받으세요",
    "offSup": "사무용품",
    "repsNxtV": "다음 방문 시",
    "addRepT": "추가 더 많은 리뷰 받기",
    "repEntrCpnTxt": "쿠폰 텍스트 입력",
    "repEntrCpnTxtBtn": "쿠폰 버튼 텍스트 입력",
    "repMngNxtV": "다음 방문에는요?",
    "repMngWldL": "당신은 얻고 싶습니까?",
    "discount": "할인",
    "repDisTyp": "할인 유형",
    "repNameT": "당신의 평판을 명명하세요",
    "crtRepT": "평판을 만드세요",
    "feedBackT": "피드백",
    "confLocT": "위치 확인",
    "businLocT": "사업장 위치",
    "addInfoT": "추가 정보",
    "repNReqT": "더 많은 리뷰를 받아보려면 이름이 필요합니다.",
    "validRepNT": "유효한 평판 이름을 입력하세요",
    "groupT": "그룹",
    "groupLDescTitleT": "연락처를 자동으로 그룹에 푸시합니다.",
    "groupLDescT": "양식을 작성한 모든 고객은 선택한 그룹에 추가됩니다.",
    "que2": "음식은 맛있었어요?",
    "que3": "우리의 서비스는 친절했나요?",
    "que4": "우리 서비스가 빠른가요?",
    "addAdditionalT": "추가 정보를 추가하시겠습니까?",
    "phNoMandT": "전화번호는 필수입니다!",
    "qrGenT": "QR 생성됨",
    "repFDT": "귀하의 의견은 저희 팀이 더 나은 서비스를 제공하는 데 도움이 됩니다!",
    "letdiT": "그것을 해보자",
    "tak15secT": "15초 걸립니다",
    "defQue": "수시로 좋은 혜택과 프로모션을 받아보시겠습니까?",
    "defNQue": "누군가가 당신에게 답장하길 원하시나요?",
    "rateOnGoogleDesc": "구글에서 저희를 평가해 주실 수 있을까요?",
    "mayBeNT": "아마 다음 시간에",
    "yesSureT": "네, 물론이죠",
    "valid_name": "유효한 이름을 입력하세요",
    "nameReq": "이름이 필요합니다",
    "backToMainT": "메인 페이지로 돌아가기",
    "attachCopT": "고객에게 인센티브를 제공하고 싶으신가요?",
    "thankYouT": "감사합니다",
    "lftyT": "다음 방문을 기대하겠습니다!",
    "repPreT": "평판 미리보기",
    "offer_exclusive_discounts": "고객을 유혹하기 위해 특별한 할인과 프로모션을 제공하세요.",
    "couponT": "쿠폰",
    "locStepDesc": "사업장 위치를 확인하세요",
    "createRepStepDesc": "그룹별로 사용자 정의 평판 양식 만들기",
    "feedbackStepDesc": "고객 피드백 질문 세트",
    "addInfoStepDesc": "고객 필드 및 인센티브 설정",
    "FedbkkWDT": "데이터를 통한 피드백",
    "repAnlytT": "평판 분석",
    "todayT": "오늘",
    "rep_last_7_days_t": "지난 7일",
    "last30DaysT": "지난 30일",
    "lastMT": "전달",
    "custRangeT": "사용자 정의 범위",
    "queWiseAnltT": "질문별 분석",
    "atL1QueErrT": "최소한 하나의 질문을 선택해주세요.",
    "deleteRepTitle": "이 평판은 영구적으로 삭제됩니다.",
    "incvCustT": "네, 고객에게 인센티브를 제공합니다.",
    "collAddInfoT": "네, 추가 정보를 수집하고 싶습니다.",
    "totRewT": "총 리뷰",
    "totPosRewT": "총 긍정적 리뷰",
    "negFeedT": "부정적인 피드백",
    "posFeedT": "긍정적 피드백",
    "fineT": "미세 인쇄",
    "enterFineT": "작은 글씨로 된 텍스트를 입력하세요",
    "setThemeT": "테마 색상 설정",
    "que1T": "질문 1",
    "que2T": "질문 2",
    "que3T": "질문 3",
    "que4T": "질문 4",
    "entMailT": "이메일을 입력하세요",
    "reputationQRDefTxt": "QR 코드를 스캔하여 귀하의 경험에 대한 귀중한 피드백을 공유해 주세요.",
    "selUser": "사용자 선택",
    "userT": "사용자",
    "subUReq": "사용자를 선택해주세요",
    "updateLoc": "위치 업데이트",
    "leadGenT": "리드 생성",
    "displayT": "표시하다",
    "ratingT": "평가",
    "rep_dashboard_negative_feedback": "부정적인 피드백 0%",
    "rep_dashboard_position_feedback": "긍정적 피드백 0%",
    "rep_dashboard_total_Feedback_txt": "전체 피드백",
    "rep_dashboard_anony_Feedback": "익명 피드백",
    "rep_dashboard_Feeedback_Data_txt": "데이터를 통한 피드백",
    "rep_dashboard_review_txt": "더 많은 리뷰를 받으세요",
    "rep_dashboard_total_Review_txt": "총 리뷰",
    "rep_dashboard_total_positive_Review_txt": "총 긍정적 리뷰",
    "rep_dashboard_negative_feedback_txt": "부정적인 피드백",
    "rep_dashboard_connection_txt": "연결",
    "rep_dashboard_question_wise_analytics_txt": "질문별 분석",
    "rep_dashboard_date_label": "날짜 선택",
    "rep_dashboard_custom_range_txt": "사용자 정의 범위",
    "rep_tlt_min": "대표",
    "emojis": "이모티콘",
    "media_text": "미디어 추가",
    "custom_field_text": "사용자 정의 필드 삽입",
    "shortlink_text": "Shortlink 추가",
    "use_template_text": "템플릿 사용",
    "banner_must_have": "꼭 가져야 함",
    "res_seamless": "원활한",
    "res_table_reservation": "테이블 예약",
    "res_system": "체계",
    "res_text": "전세",
    "md_easily": "쉽게",
    "md_manage_customers": "고객 관리",
    "md_visits_interactions": "방문 및 상호 작용",
    "md_desc": "실시간 테이블 예약으로 고객의 식사를 더욱 편리하게 만드세요. 예약을 손쉽게 관리하고, 대기 시간을 줄이고, 직원과 손님 모두에게 원활한 경험을 보장하세요. 최고의 서비스와 만족을 유지하면서 레스토랑을 만석으로 유지하세요.",
    "engage_convert": "SMS 참여 및 변환",
    "coupon_campaign": "및 쿠폰 캠페인",
    "sms_read_rate": "SMS 메시지는 98%의 시간 동안 읽힙니다.",
    "real_time_promo": "즉각적인 조치를 위해 실시간으로 프로모션을 전달합니다.",
    "affordable_reach": "견고한 ROI로 고객에게 다가갈 수 있는 저렴한 방법",
    "stay_in_touch": "항상 고객과 연락을 유지하세요",
    "bc_engage_customers": "고객 참여 SMS 변환",
    "bc_coupon_campaign": "및 쿠폰 캠페인",
    "bc_sms_read_rate": "SMS 메시지는 98%의 시간 동안 읽힙니다.",
    "bc_real_time_promo": "즉각적인 조치를 위해 실시간으로 프로모션을 전달합니다.",
    "bc_affordable_reach": "견고한 ROI로 고객에게 다가갈 수 있는 저렴한 방법",
    "bc_stay_in_touch": "항상 고객과 연락을 유지하세요",
    "ar_automate_engagement": "고객 참여를 자동화하세요",
    "ar_with": "~와 함께",
    "ar_with_auto_responders": "자동 응답기",
    "ar_personalized_messages": "생일에 개인화된 메시지를 자동으로 보내고, 새로운 고객을 환영하고, 이전 고객과 다시 연결하세요. 모든 상호작용을 의미 있고 시기적절하게 유지하면서 시간을 절약하세요.",
    "sq_custom_forms_tools": "사용자 정의 양식 및 도구 - 단순화",
    "sq_custom_forms_ci": "클라이언트 상호작용",
    "sq_smart_qr_desc": "사용자 정의 양식을 만들고, 전자 서명을 수집하고, 스마트 QR 코드를 활용하여 비즈니스 프로세스를 간소화하세요. 각 도구를 고객의 요구에 맞게 조정하고 사용자 경험을 향상하세요.",
    "gnc_get_new_cus": "새로운 고객 확보",
    "gnc_get_customers": "소셜 미디어에서 빠르고 쉽게!",
    "gnc_social_media_power": "소셜 플랫폼의 힘을 활용해 새로운 고객을 유치하고 매출을 직접 귀사의 사업으로 유도하세요. 제3자 배달 주문을 매장이나 온라인 주문 플랫폼으로 직접 리디렉션하여 최대한의 통제력과 수익을 확보하세요.",
    "wifi_connect_guests": "게스트와 연결",
    "wifi_smart": "스마트 와이파이",
    "wifi_connect_grow": "& 통찰력을 키우세요",
    "wifi_smart_wifi_desc": "브랜드가 표시된 스플래시 화면을 통해 고객에게 번거로움 없는 Wi-Fi 연결을 제공하는 동시에, 마케팅 활동과 참여를 강화하기 위해 귀중한 고객 데이터를 수집합니다.",
    "wifi_create_splash_page": "게스트 Wifi 스플래시 페이지 만들기",
    "instant_menu_create": "당신의 것을 만드십시오",
    "instant_menu_text": "즉석 메뉴",
    "instant_menu_wm": "몇 분 안에",
    "instant_menu_desc": "실시간 업데이트, 쉬운 사용자 정의, 향상된 참여를 통해 고객 경험을 향상시키고, 제품을 선보이고 콘텐츠를 최신 상태로 유지하기가 더 쉬워집니다.",
    "instant_menu_create_digital_menu": "디지털 메뉴 만들기",
    "loyalty_program_rewards": "보상을 받으세요",
    "loyalty_program_on_visits": "모든 방문에서",
    "loyalty_program_create": "로열티 프로그램 만들기",
    "reputation_create": "평판을 만드세요",
    "reputation_boost": "당신의 부스트",
    "reputation_text": "평판",
    "reputation_reviews": "더 많은 리뷰와 함께!",
    "reputation_desc": "고객으로부터 귀중한 피드백을 수집하여 서비스와 평판을 향상시키세요. 경험을 공유하고 저희가 성장하도록 도와주세요. 리뷰가 중요합니다!",
    "dashboard": "계기반",
    "blast_campaign": "블래스트 캠페인",
    "blast_campaigns": "블라스트 캠페인",
    "blast_campaign_sub_title": "몇 번의 클릭만으로 모든 고객에게 도달하는 폭발 캠페인을 시작하세요",
    "auto_responders": "자동 응답기",
    "auto_responder": "자동 응답기",
    "auto_responder_sub_title": "고객을 위한 사용하기 쉬운 이벤트 기반 프로모션",
    "smart_qr_form": "스마트 QR \/ 양식",
    "get_new_customer": "새로운 고객 얻기",
    "marketing": "마케팅",
    "clients": "고객",
    "group_data": "그룹 데이터",
    "clients_data": "클라이언트 데이터",
    "account": "계정",
    "user_acc_info": "사용자 계정 정보",
    "current_plan": "현재 계획",
    "payments": "결제",
    "location": "위치",
    "location_list": "위치 목록",
    "users": "사용자",
    "users_sub_title": "모든 사용자 및 세부 정보 목록",
    "online_profile": "온라인 프로필",
    "change_password": "비밀번호 변경",
    "menu": "메뉴",
    "reseller_dashboard": "리셀러 대시보드",
    "companies": "회사",
    "plans_bought": "구매한 계획",
    "agreement_t_c": "동의 약관",
    "wifi_dashboard": "와이파이 대시보드",
    "hot_spot": "핫스팟",
    "splash_pages": "스플래시 페이지",
    "get_more_reviews": "더 많은 리뷰를 받으세요",
    "office_supplies": "사무용품",
    "reservation": "예약",
    "floor_table": "바닥 및 테이블",
    "guest_smart_wifi": "게스트 스마트 와이파이",
    "digital_signage": "디지털 사이니지",
    "schedule_down": "스케줄다운",
    "business_settings": "비즈니스 설정",
    "sub_user_login": "서브유저 로그인",
    "help_center": "도움말 센터",
    "switch_to_hub": "허브로 전환",
    "meeting_records": "회의 기록",
    "logout": "로그아웃",
    "no_access_text": "지금은 액세스할 수 없습니다. 관리자에게 문의하세요.",
    "menu_item": "메뉴 항목",
    "menu_capital": "메뉴",
    "good_day": "안녕하세요",
    "no_permission_text": "이 작업을 수행할 권한이 없습니다!!",
    "enter_valid_pin": "유효한 PIN을 입력하세요",
    "add_reservation": "예약 추가",
    "edit_reservation": "예약 편집",
    "per_req": "인원수가 필요합니다",
    "req_block_time": "블록 시간이 필요합니다",
    "pending_status": "보류 중",
    "booked_status": "예약됨",
    "completed_status": "완전한",
    "cancelled_status": "취소",
    "reservation_slot": "예약 슬롯",
    "block_time": "블록 시간",
    "slot_time": "예약 슬롯 시간:  ",
    "template_name_req": "템플릿 이름이 필요합니다",
    "template_type_req": "템플릿 유형이 필요합니다.",
    "sms_template_req": "SMS 템플릿이 필요합니다",
    "group_req": "그룹이 필요합니다",
    "valid_date_time": "유효한 날짜와 시간을 입력하세요",
    "valid_group_name": "유효한 그룹 이름을 입력하세요",
    "reservation_mark_completed": "이 예약은 완료된 것으로 표시됩니다.",
    "customer_name": "고객 이름",
    "date_time": "날짜 시간",
    "table_no": "테이블 번호",
    "floor": "바닥",
    "customer_name_placeholder": "고객 이름",
    "date_time_placeholder": "날짜_시간",
    "table_no_placeholder": "테이블 번호",
    "floor_placeholder": "바닥",
    "select_floor": "층 선택",
    "select_table": "테이블 선택",
    "customer_name_macro": "%% 고객 이름 %%",
    "date_time_macro": "%% 날짜_시간 %%",
    "table_no_macro": "%% 테이블_번호 %%",
    "floor_macro": "%% 바닥 %%",
    "template_name": "템플릿 이름",
    "login_with_4_digit_pin": "4자리 PIN으로 로그인하세요",
    "login_now_to_access_latest_insights": "지금 로그인하여 최신 소식을 받아보세요",
    "mkt_BS_Title": "블래스트 SMS",
    "step_1": "메시지 사용자 정의",
    "step_2": "청중을 선택하세요",
    "step_3": "일정 메시지",
    "step_4": "메시지 보내기",
    "step_5": "메시지 관리",
    "bs_tab_title": "블래스트 캠페인 요약",
    "default_message": "안녕 %% firstname %%, 브런치에 특별 할인! 오늘 저희와 함께 식사하고 청구서에서 독점 10% 할인을 받으세요!",
    "bs_Title": "캠페인 유형을 선택하세요",
    "sms_title": "문자\/MMS",
    "reach_out_to_customer": "문자 메시지와 멀티미디어 메시지를 통해 고객에게 다가가세요.",
    "wp_text": "왓츠앱",
    "connect_with_wp": "WhatsApp 💬을 사용하여 고객과 소통하세요",
    "send_discount_offers": "SMS로 할인 혜택 보내기 📲",
    "send_coupons_via_sms": "고객에게 SMS로 쿠폰을 보내세요 🎟️",
    "env_Cap": "고객을 유혹하기 위해 독점적인 할인 및 프로모션을 제공하세요",
    "cmt_Caption": "문자 메시지와 멀티미디어 메시지를 통해 고객에게 다가가세요",
    "wp_caption": "WhatsApp을 사용하여 고객과 원활하고 직접적인 소통을 하세요",
    "msg_Prev_Title": "메시지 미리보기",
    "cou_Prev_Title": "쿠폰 미리보기",
    "blast_SMS": "SMS\/MMS 캠페인",
    "bs_P_Msg": "대기 중",
    "bs_S_Msg_C": "실패한",
    "bs_F_Msg_Count": "전달됨",
    "previous": "이전의",
    "next_ST": "다음",
    "next_S": "다음 단계",
    "req_Temp_Name_Title": "템플릿 이름이 필요합니다",
    "req_Template_Tx_Title": "템플릿 텍스트가 필요합니다",
    "add_Temp_Title": "템플릿 추가",
    "link_Desc": "고객이 Facebook, Google 또는 귀하의 웹사이트를 통해 예약을 할 수 있도록 원하는 링크를 추가하세요.",
    "audience_Title": "청중",
    "client_G1_Title": "새로운 고객",
    "client_G2_Title": "최근 고객",
    "client_G3_Title": "충성스러운 고객",
    "client_G4_Title": "만료된 고객",
    "client_G5_Title": "연결 날짜별 클라이언트",
    "on_Which_Day": "어느 날",
    "on_Day": "하루 동안",
    "immediate_Desc": "귀하의 메시지가 즉시 전송됩니다. 시작하려면 '다음'을 클릭해 주세요.",
    "never_Desc": "이 캠페인은 수동으로 종료할 때까지 계속 실행되도록 예약되어 있습니다.",
    "end_Desc": "캠페인은 여기서 선택한 날짜에 종료됩니다.",
    "success_Message": "캠페인이 성공적으로 저장되었습니다!",
    "send_Success_Message": "캠페인이 성공적으로 전송되었습니다!",
    "msg_Name_Field_Title": "캠페인 제목",
    "valid_campaign_title": "유효한 캠페인 제목을 입력하세요",
    "msg_Type_Title": "메시지 유형",
    "sms_Desc": "SMS 당 1 크레딧",
    "mms_Desc": "MMS당 3 크레딧",
    "client_GTO_1": "낮",
    "client_GTO_2": "시간",
    "minutes": "분",
    "client_GTO_4": "주",
    "time_Option_1": "낮",
    "time_Option_2": "시간",
    "c_Grp_Day_1": "90일",
    "c_Grp_Day_2": "60일",
    "c_Grp_Day_3": "30일",
    "first": "첫 번째",
    "second_T": "두번째",
    "third_T": "제삼",
    "fourth_T": "네번째",
    "fifth_T": "제오",
    "delete_BS_T": "이 캠페인은 영구적으로 삭제됩니다.",
    "delete_MBS_T": "해당 캠페인은 영구적으로 삭제됩니다.",
    "cFT_1": "핸드폰",
    "cFT_2": "연락처 이름",
    "cFT_3": "연락처 성",
    "cFT_4": "연락처 이메일",
    "cFT_5": "메모",
    "cFT_6": "생일",
    "cFT_7": "회사 이름",
    "cF_Meta_Tag_1": "%% 핸드폰 %%",
    "cF_Meta_Tag_2": "%% 이름 %%",
    "cF_Meta_Tag_3": "%% 성 %%",
    "cF_Meta_Tag_4": "%% 이메일 %%",
    "cF_Meta_Tag_5": "%% 메모 %%",
    "cF_Meta_Tag_6": "%% 생일 %%",
    "cF_Meta_Tag_7": "%% 회사 이름 %%",
    "cF_Name_1": "핸드폰",
    "cF_Name_2": "이름",
    "cF_Name_3": "성",
    "cF_Name_4": "이메일",
    "cF_Name_5": "메모",
    "cF_Name_6": "생일",
    "cF_Name_7": "회사 이름",
    "all_clients": "모든 클라이언트",
    "aud_Type_2": "클라이언트 그룹",
    "aud_Type_3": "고객 그룹",
    "aud_Type_T1": "모든 클라이언트",
    "aud_Type_T2": "클라이언트 그룹",
    "aud_Type_T3": "고객 그룹",
    "new_clients": "새로운 고객",
    "client_G2": "최근 고객",
    "client_G3": "충성스러운 고객",
    "client_G4": "만료된 고객",
    "client_G5": "연결 날짜별 클라이언트",
    "never_text": "절대",
    "ending_on": "종료",
    "send_T2": "예정됨",
    "send_Type_3": "반복되는",
    "daily": "일일",
    "weekly_T": "주간",
    "monthly_T": "월간 간행물",
    "yearly_T": "매년",
    "each": "각",
    "on_the": "에",
    "monday": "월요일",
    "exclusive_offer": "당신을 위한 특별 혜택",
    "redeem_now": "지금 사용하세요",
    "redeem_with_cashier": "계산대에서 환전해주세요",
    "lg_Day_2": "화요일",
    "lg_Day_3": "수요일",
    "lg_Day_4": "목요일",
    "lg_Day_5": "금요일",
    "lg_Day_6": "토요일",
    "lg_Day_7": "일요일",
    "msg_Pre_T": "도미노",
    "cA_Edit_T1": "모두",
    "cA_Edit_T2": "포함됨",
    "cA_Edit_T3": "제외된",
    "SMS": "문자 메시지",
    "MMS": "엠에스엠에스",
    "usd_T": "미국 달러",
    "cad_T": "치사한 사람",
    "msg": "메시지",
    "which_Day": "어느 날",
    "end_Date_Tx": "종료일",
    "sDate_Err": "시작 날짜는 오늘보다 크거나 같아야 합니다.",
    "eDate_Err": "종료 날짜는 시작 날짜보다 이후여야 합니다.",
    "start_Date_Req": "시작 날짜가 필요합니다",
    "end_Date_Req": "종료 날짜가 필요합니다",
    "time_req": "시간이 필요합니다",
    "client_GT1": "마지막에 구독한 클라이언트",
    "client_GT2": "지난번에 연결이 된 클라이언트",
    "client_GT3": "최소한 클라이언트",
    "client_GT4": "마지막으로",
    "client_GT5": "지난번에 돌아오지 않은 사람",
    "dummy_Phone_No": "+91987-654-3210",
    "test_T": "시험",
    "dummy_Birth_Date": "01-01-2001",
    "image_Req": "미디어 또는 URL이 필요합니다.",
    "time_Title": "시간",
    "start_date": "시작 날짜",
    "end_date": "종료일",
    "auto_Send_T": "자동으로 클라이언트에게 전송",
    "add_Media": "미디어 추가",
    "in_Title": "~ 안에",
    "c_Temp_T": "캠페인 템플릿",
    "temp_NT": "템플릿 이름",
    "type_T": "유형",
    "select_template_type": "템플릿 유형을 선택하세요",
    "sel_Temp_T": "템플릿 유형 선택",
    "sms_temp_t": "SMS 템플릿",
    "temp_T": "템플릿 텍스트",
    "default_Msg": "안녕 %% firstname %%, 브런치에 특별 할인! 오늘 저희와 함께 식사하고 청구서에서 독점 10% 할인을 받으세요!",
    "refill_T": "다시 메우다",
    "avl_Credit": "사용 가능한 크레딧",
    "req_Credit": "총 필요 학점",
    "rem_Credit": "남은 크레딧",
    "refill_Credit": "다시 채워야 할 크레딧",
    "clients_Req": "최소한 한 명의 클라이언트를 선택하세요",
    "subclient_Req": "선택된 고객은 구독자가 아닙니다.",
    "refill_Desc": "필요한 학점을 다시 채워주세요.",
    "url_Regex": "^((http|https):\/\/).....+$",
    "sd_Invalid": "시작 날짜가 잘못되었습니다.",
    "ed_Invalid": "종료 날짜가 잘못되었습니다.",
    "img_Url_Invalid": "잘못된 이미지 URL",
    "time_Invalid": "시간이 잘못되었습니다",
    "time_And_Date_Invalid": "유효한 날짜와 시간을 입력하세요",
    "time_Invalid_Bfr": "현재 시간보다 최소 5분 후의 시간을 선택하세요.",
    "sod_Req": "발송날짜가 필수입니다",
    "add_Card": "새로운 카드 추가",
    "sm_D_Rep_Title": "SMS\/MMS 전달 보고서",
    "send_Now_T": "지금 보내기",
    "schd_Now_T": "SMS 일정",
    "test_SMS_T": "테스트 SMS 보내기",
    "save_AD_T": "초안으로 저장",
    "back_TE_T": "편집으로 돌아가기",
    "reset_tex": "다시 놓기",
    "update_tex": "업데이트",
    "dum_msg1": "고객 이름을 표시하려면 템플릿에서 %% customer_name %% 사용하세요.",
    "dum_msg2": "예약 시간을 표시하려면 템플릿에서 %% date_time %% 사용하세요.",
    "dum_msg3": "테이블 번호를 표시하려면 템플릿에서 %% table_no %% 를 사용하세요.",
    "embedded_link": "내장된 링크",
    "img_title": "영상",
    "img_input_text": "텍스트:",
    "img_join_text": " 우리 목록에 가입하려면",
    "img_copy_text": "복사",
    "img_not_found": "생성된 이미지를 찾을 수 없습니다!!",
    "or_text": "또는",
    "web_signup_image_content": "이 양식을 제출하고 문자로 가입하면 마케팅 문자 메시지(프로모션 코드 및 알림 등)를 받는 데 동의하는 것입니다. 메시지 및 데이터 요금이 적용될 수 있습니다. 메시지 빈도는 다양합니다. 언제든지 STOP으로 답장하여 구독을 취소할 수 있습니다.",
    "img_download_success": "이미지가 성공적으로 다운로드되었습니다",
    "embedded_link_copied": "내장된 링크가 성공적으로 복사되었습니다.",
    "media_url_required": "미디어 또는 URL이 필요합니다.",
    "invalid_image_url": "잘못된 이미지 URL입니다",
    "invalid_file": "파일이 잘못되었습니다",
    "image_error_1mb": "1MB 이하의 이미지를 삽입해 주세요.",
    "image_error_2_5mb": "2.5MB 이하의 이미지를 삽입해 주세요.",
    "image_error_3mb": "3MB 이하의 이미지를 삽입해 주세요.",
    "change_title": "변화",
    "drop_file_text": "여기에 파일을 놓거나 클릭하여 업로드하세요.",
    "apply_text": "적용하다",
    "search_here": "여기에서 검색",
    "update_status": "상태 업데이트",
    "reservation_deleted_permanently": "이 예약은 영구적으로 삭제됩니다.",
    "table_text": "테이블",
    "add_table_button": "새로운 테이블 추가",
    "add_table_text": "테이블 추가",
    "edit_table_text": "테이블 편집",
    "table_delete_text": "이 표는 영구적으로 삭제됩니다.",
    "multiple_table_delete_text": "이러한 표는 영구적으로 삭제됩니다.",
    "table_error_name": "테이블 이름을 입력하세요",
    "table_error_invalid_name": "유효한 테이블 이름을 입력하세요",
    "table_error_floor": "층을 선택해주세요",
    "table_error_select": "테이블을 선택해주세요",
    "table_capacity_text": "테이블 용량",
    "capacity_text": "용량",
    "table_occasion_text": "모든 행사에 맞는 테이블을 찾으세요",
    "table_name_text": "테이블 이름",
    "table_capacity_error": "테이블 수용 인원이 필요합니다.",
    "cancel_text": "취소",
    "submit_text": "제출하다",
    "select_valid_date": "유효한 날짜를 선택하세요",
    "select_valid_time": "유효한 시간을 선택하세요",
    "valid_contact_number": "유효한 연락처 번호를 입력하세요.",
    "date_req": "날짜가 필요합니다",
    "date_error_later_than_today": "날짜는 오늘 날짜보다 이후이거나 같아야 합니다.",
    "time_error_later_than_5_minutes": "현재 시간으로부터 최소 5분 후의 시간을 선택하세요",
    "number_person_req": "인원수가 필요합니다",
    "contact_no_req": "연락처 번호가 필요합니다",
    "contact_no": "연락처 번호",
    "select_time": "시간 선택",
    "for_how_many_person": "인원은 몇 명인가요?",
    "f_name": "이름",
    "l_name": "성",
    "business_name": "사업체 이름",
    "business_no": "사업자번호",
    "no_for_sign_in": "이 번호는 로그인에 사용됩니다",
    "business_email": "비즈니스 이메일",
    "notes_tex": "노트",
    "floor_deleted_permanently": "이 층은 영구적으로 삭제됩니다.",
    "add_floor": "층 추가",
    "edit_floor": "층 편집",
    "name_text": "이름",
    "mobile_no": "휴대폰 번호",
    "person_text": "사람",
    "date_and_time": "날짜 및 시간",
    "actions_text": "행위",
    "extra_text": "추가의",
    "floor_name_req": "층 이름이 필요합니다",
    "floor_name": "층 이름",
    "status_text": "상태",
    "category_status_req": "카테고리 상태가 필요합니다",
    "table_deleted_permanently": "이 표는 영구적으로 삭제됩니다.",
    "tables_deleted_permanently": "이 표는 영구적으로 삭제됩니다.",
    "back_to_home": "홈으로 돌아가기",
    "link_copied_text": "링크가 클립보드에 복사되었습니다",
    "cust_dash_head_to_title": "에게",
    "cust_dash_head_location_title": "위치",
    "select_location_title": "위치 선택",
    "all_locations_label": "모든 위치",
    "rep_customer_feedback_analytics": "😊 실시간 분석을 통해 고객 피드백을 추적하세요",
    "rep_customer_txt": "고객",
    "rep_delete_title": "이 평판은 영구적으로 삭제됩니다.",
    "rep_qr_def_txt": "QR 코드를 스캔하여 귀하의 경험에 대한 귀중한 피드백을 공유해 주세요.",
    "delete_title": "삭제",
    "user_list_title": "고객 목록",
    "os_nfc_txt": "NFC 카드",
    "os_sign_holder_txt": "간판 홀더 스탠드",
    "os_store_Sign_holder_txt": "매장 간판 홀더",
    "StaT": "통계",
    "AllgT": "모든 그룹",
    "FavT": "가장 좋아하는",
    "MostActT": "가장 활동적인",
    "grT": "그룹 이름",
    "keywT": "키워드",
    "exSubT": "기존 구독자를 위한 메시지",
    "ArchT": "보관됨",
    "gNotiSms": "SMS를 통해 새로운 연락처가 있을 때마다 알림을 받으세요",
    "gNotiEmail": "이메일을 통해 새로운 연락처가 있을 때마다 알려주세요",
    "reqGName": "그룹 이름이 필요합니다",
    "validGN": "유효한 그룹 이름을 입력하세요",
    "valid_phone_no": "유효한 전화번호를 입력하세요",
    "phone_no_req": "전화번호가 필요합니다",
    "required_message_text": "메시지가 필요합니다",
    "required_discount_text": "할인이 필요합니다",
    "less_than_discount": "할인금액은 5000원 미만이어야 합니다.",
    "discount_percentage_invalid": "할인율은 100보다 작거나 같아야 합니다.",
    "discount_type_req": "할인 유형이 필요합니다",
    "discount_text_req": "쿠폰 문구가 필요합니다.",
    "reqContactNo": "연락처 번호가 필요합니다",
    "reqMsgNT": "캠페인 제목이 필요합니다.",
    "reqLinkT": "링크가 필요합니다",
    "delMGT": "이 그룹은 영구적으로 삭제됩니다.",
    "defMemMsg": "여기에 메시지를 입력하세요",
    "add_cust_to_grp_title": "그룹에 클라이언트 추가",
    "group_title": "여러 떼",
    "group_create_title": "그룹 생성",
    "group_name_txt": "그룹 이름",
    "group_table_keyword_title": "예어",
    "actions_title": "행위",
    "clients_title": "고객",
    "send_title": "보내다",
    "qr_title": "한국어:",
    "delete_group_txt": "이 그룹은 영구적으로 삭제됩니다.",
    "delete_groups_txt": "이 그룹은 영구적으로 삭제됩니다.",
    "delete_client_title": "이 클라이언트는 영구적으로 삭제됩니다.",
    "delete_clients_title": "해당 클라이언트는 영구적으로 삭제됩니다.",
    "delete_multiple_title": "다중 삭제",
    "wel_sms_mms": "환영 SMS\/MMS를 보내시겠습니까?",
    "key_words_title": "키워드",
    "srch_plch_txt": "여기에서 검색",
    "req_location_id_title": "위치가 필요합니다",
    "create_text": "만들다",
    "view_text": "보다",
    "immediately": "즉시",
    "business_name_req": "사업자명이 필요합니다",
    "business_no_req": "사업자번호가 필요합니다.",
    "valid_business_name": "유효한 사업체 이름을 입력하세요",
    "valid_business_no": "유효한 사업자 번호를 입력하세요.",
    "address_req": "주소가 필요합니다",
    "valid_address": "유효한 주소를 선택해주세요",
    "time_zone_req": "시간대가 필요합니다",
    "image_req": "2.5mb 이하의 이미지를 삽입해 주세요.",
    "valid_file": "파일이 잘못되었습니다.",
    "logo": "심벌 마크",
    "time_zone": "시간대",
    "save": "구하다",
    "account_type_req": "계정 유형이 필요합니다",
    "gst_no_req": "GST 번호가 필요합니다",
    "gst_no_valid": "유효한 GST 번호를 입력하세요",
    "set_up_payments": "결제 설정",
    "billing_details": "결제 세부 정보를 입력하세요",
    "billing_details_desc": "귀하의 결제 세부정보는 menuonline의 월별 청구서에 표시됩니다.",
    "account_type": "계정 유형",
    "select_account_type": "계정 유형을 선택하세요",
    "gst_no": "GST 번호",
    "transaction_details": "거래 세부 정보",
    "payment_method": "결제방법",
    "billing_period": "결제 기간",
    "paid_by": "지불자",
    "download": "다운로드",
    "pay_now": "지금 지불하세요",
    "pull_down_refresh": "아래로 당겨서 새로 고침",
    "release_refresh": "새로 고침하려면 놓으세요",
    "billing_details_text": "결제 세부 정보",
    "payment_methods": "결제 방법",
    "invoice": "송장",
    "invoice_to": "송장 받는 사람:",
    "issue_date": "발행일",
    "due_date": "마감일",
    "amount_due": "미납 금액",
    "charges": "요금",
    "sub_total_capitalize": "소계",
    "hst": "하버드세틀먼트",
    "grand_total": "총계",
    "invoice_generated_on": "송장 생성일",
    "contact_us": "문의하기",
    "invoice_issue": "청구서를 살펴봤는데도 여전히 궁금한 점이 있는 경우",
    "call": "부르다",
    "send_sms": "SMS 보내기",
    "payment_success": "결제가 성공되었습니다",
    "edit_credit_card": "신용카드 수정",
    "add_credit_card": "신용 카드 추가",
    "modify_card_info": "카드 정보 수정",
    "enter_card_info": "카드 정보를 입력하세요",
    "account_no_req": "계좌번호가 필요합니다",
    "card_name_req": "카드에 적힌 이름이 필요합니다.",
    "expiry_date_req": "유효기간이 필요합니다",
    "valid_expiry_date": "유효한 만료일을 입력하세요",
    "valid_cvv": "유효한 이력서를 입력하세요",
    "cvv_req": "CVV가 필요합니다",
    "card_no": "카드 번호",
    "name_of_card": "카드에 적힌 이름",
    "expiry": "만료",
    "mm_text": "월\/년",
    "cvv": "영어: CVV는 영어입니다.",
    "set_as_default": "기본값으로 설정",
    "add_new_card": "새로운 카드 추가",
    "all_credit_card": "귀하의 모든 신용 카드",
    "fastest_checkout": "안전하고 안정적인 플랫폼을 통한 가장 빠른 체크아웃, 많은 기업으로부터 신뢰받고 있습니다.",
    "coupon_button_req": "쿠폰 버튼 텍스트가 필요합니다.",
    "max19_characters_allowed": "최대 19자까지 허용됩니다.",
    "fine_print_text_req": "작은 글씨로 된 텍스트가 필요합니다.",
    "clients_req": "최소한 한 명의 클라이언트를 선택하세요",
    "client_groups": "클라이언트 그룹",
    "day_text": "낮",
    "valid_days": "일 값이 너무 큽니다. 유효한 일자를 입력하세요.",
    "hours_text": "시간",
    "enter_valid_hours": "시간 값이 너무 큽니다. 유효한 시간을 입력하세요.",
    "enter_valid_min": "분 값이 너무 큽니다. 유효한 분을 입력하세요.",
    "clients_with_connection_required": "마지막에 연결이 있는 클라이언트가 필요합니다.",
    "connection_required": "연결이 필요합니다",
    "connection_value_too_large": "연결 값이 너무 큽니다. 유효한 연결을 입력하세요.",
    "minutes_required": "소요 시간",
    "clients_with_at_least_connection_required": "최소한 연결이 있는 클라이언트가 필요합니다.",
    "last_minutes_req": "마지막 순간이 필요합니다",
    "clients_not_returned_required": "마지막 순간에 돌아오지 않은 사람이 필요합니다.",
    "not_return_in_last_minutes_invalid": "마지막 순간에 돌아오지 않는 시간은 마지막 분보다 적어야 합니다.",
    "customerGroups": "고객 그룹",
    "select_customer_group": "고객그룹을 선택해주세요.",
    "location_required": "위치가 필요합니다",
    "start_date_required": "시작 날짜가 필요합니다",
    "start_date_invalid": "시작 날짜가 잘못되었습니다.",
    "start_date_invalid_error": "시작 날짜는 오늘보다 크거나 같아야 합니다.",
    "recurring": "반복되는",
    "end_date_required": "종료 날짜가 필요합니다",
    "end_date_invalid": "종료 날짜가 잘못되었습니다.",
    "end_date_invalid_error": "종료 날짜는 시작 날짜보다 이후여야 합니다.",
    "time_invalid": "시간이 잘못되었습니다",
    "monthly_text": "월간 간행물",
    "send_day_req": "발송날짜가 필수입니다",
    "loyal_clients": "충성스러운 고객",
    "recent_clients": "최근 고객",
    "lapsed_clients": "만료된 고객",
    "clients_connection_date": "연결 날짜별 클라이언트",
    "scheduled_text": "예정됨",
    "weekly": "주간",
    "selected_clients_not_subscriber": "선택된 고객은 구독자가 아닙니다.",
    "message_preview_title": "메시지 미리보기",
    "coupon_preview_title": "쿠폰 미리보기",
    "form_text": "형태",
    "preview_text": "시사",
    "next_text": "다음",
    "send_test_SMS": "테스트 SMS 보내기",
    "save_draft": "초안으로 저장",
    "back_to_edit": "편집으로 돌아가기",
    "select_payment_method": "결제방법을 선택해주세요",
    "schedule_SMS": "SMS 일정",
    "send_now": "지금 보내기",
    "get_more_ratings": "더 많은 평가를 받으세요",
    "overview": "개요",
    "reset_campaign": "캠페인 재설정",
    "permissions": "권한",
    "location_name": "위치 이름",
    "phone_no": "전화 번호",
    "location_email_address": "위치 이메일 주소",
    "located_business": "귀하의 사업장은 어디에 위치해 있나요?",
    "business_logo": "사업 로고",
    "congratulations": "축하해요",
    "almost_done": "거의 끝났어요",
    "publish": "게시하다",
    "about_your_business": "귀하의 사업에 대하여",
    "add_your_location": "위치 추가",
    "publish_location": "게시 위치",
    "location_name_req": "위치 이름이 필요합니다",
    "valid_location_name": "유효한 위치 이름을 입력하세요",
    "business_logo_req": "사업체 로고가 필요합니다.",
    "please_accept_terms": "이용약관에 동의해 주세요.",
    "add_new_location": "새로운 위치 추가",
    "edit_location": "위치 편집",
    "add_location": "위치 추가",
    "existing_msg_subscriber_txt": "기존 구독자를 위한 메시지",
    "msg_capitalize_txt": "메시지",
    "group_noti_sms": "SMS를 통해 새로운 연락처가 있을 때마다 알림을 받으세요",
    "group_noti_email": "이메일을 통해 새로운 연락처가 있을 때마다 알려주세요",
    "group_member_msg": "당신은 이미 우리 그룹의 일원인 것 같습니다!!",
    "group_mend_msg": "STOP을 눌러 종료합니다. HELP를 눌러 도움을 요청합니다. SMS 및 데이터 요금이 적용될 수 있습니다.",
    "Disclaimer_title": "면책 조항 텍스트: ",
    "group_def_msg": "지금 온라인 상태입니다! 서비스 메뉴를 확인하고 오늘 다음 예약을 예약하세요.",
    "required_msg_txt": "메시지가 필요합니다",
    "required_Key_txt": "키워드가 필요합니다",
    "required_mem_msg": "구독자 메시지가 필요합니다.",
    "client_list_title": "고객 목록",
    "add_contact_txt": "연락처 추가",
    "delete_all_clients_txt": "모든 클라이언트 삭제",
    "delete_all_clients_msg": "모든 클라이언트를 삭제하시겠습니까? 복구할 수 없습니다.",
    "delete_all_txt": "모두 삭제",
    "timeline_title": "타임라인",
    "unsubscribe_title": "구독 취소",
    "subscribe_title": "구독하다",
    "unsubscribe_confirm_msg": "이 고객을 구독 취소자로 표시하시겠습니까?",
    "subscribe_confirm_msg": "이 고객을 구독자로 표시하시겠습니까?",
    "no_title": "아니요",
    "yes_title": "예",
    "client_name_title": "고객 이름",
    "source_title": "원천",
    "contact_file_Req": "연락처 파일이 필요합니다",
    "req_title": "필수의",
    "opt_in_req": "가져온 연락처는 100% 선택 참여해야 합니다.",
    "image_invalid_error": "파일이 잘못되었습니다.",
    "import_contacts_msg": "연락처 가져오기",
    "csv_upload_title": "업로드할 CSV 연락처 파일 *",
    "csv_file_desc": "csv 파일만 업로드하세요. csv 파일 열은 이름, 성, 이메일 ID, 휴대전화 번호, 성별, 생년월일(MM\/DD\/YYYY)입니다. 휴대전화 번호에는 공백, 대시 또는 괄호를 사용할 수 없습니다.",
    "to_download_title": "데모 파일을 다운로드하려면 여기를 클릭하세요",
    "contains_header_title": "예, 이 파일에는 헤더가 포함되어 있습니다.",
    "opt_in_100_txt": "예, 이러한 가져온 연락처는 100% 선택 참여입니다.",
    "DisT": "면책 조항 텍스트: ",
    "gMendMsg": "STOP을 눌러 종료합니다. HELP를 눌러 도움을 요청합니다. SMS 및 데이터 요금이 적용될 수 있습니다.",
    "reqKeyT": "키워드가 필요합니다",
    "reqMemMsg": "구독자 메시지가 필요합니다.",
    "reqMsgT": "메시지가 필요합니다",
    "gMemberMsg": "당신은 이미 우리 그룹의 일원인 것 같습니다!!",
    "gdefMsg": "지금 온라인 상태입니다! 서비스 메뉴를 확인하고 오늘 다음 예약을 예약하세요.",
    "next_title": "다음",
    "male_title": "남성",
    "male_val": "남성",
    "female_title": "여성",
    "female_val": "여성",
    "others_txt": "기타",
    "others_val": "다른 사람들",
    "validBirthdate": "유효한 생년월일을 입력하세요",
    "valid_phone_no_title": "유효한 전화번호를 입력하세요",
    "required_phone_no_title": "전화번호가 필요합니다",
    "add_new_client_txt": "새로운 클라이언트 추가",
    "update_client_txt": "클라이언트 업데이트",
    "phone_num_text": "전화 번호",
    "dob_title": "생년월일",
    "select_gender_title": "성별을 선택하세요",
    "timelineTitle": "타임라인",
    "confirm_location": "위치 확인",
    "feedback_title": "피드백",
    "rep_question_1": "사업장은 깨끗했나요?",
    "rep_que_2": "음식은 맛있었어요?",
    "rep_que_3": "우리의 서비스는 친절했나요?",
    "rep_que_4": "우리 서비스가 빠른가요?",
    "business_location_req_title": "사업장 주소가 필요합니다.",
    "valid_location_err_txt": "유효한 주소를 선택해주세요",
    "rep_management_title": "더 많은 리뷰 관리 받기",
    "rep_about_desc": "평판은 고객에게 피드백을 요청하여 Google 평가를 높이는 데 도움이 됩니다.",
    "preview_title": "시사",
    "rep_preview_txt": "평판 미리보기",
    "back_title": "뒤쪽에",
    "fine_print_txt": "계산대에서 환전해주세요",
    "redeem_me_title": "나를 구원해 주세요",
    "rep_nxt_visit_txt": "다음 방문 시",
    "device_type": "사용된 장치 유형",
    "connection_method": "연결 방법",
    "peak_days": "성수기",
    "peak_hours": "피크 시간",
    "guest_by_day": "낮에 손님",
    "guest_visit": "방문",
    "connection": "연결",
    "connection_duration": "연결 기간",
    "guest_visit_1": "1회",
    "guest_visit_2": "2번",
    "guest_visit_3_5": "3-5회",
    "guest_visit_6_10": "6~10회",
    "guest_visit_11_25": "11~25회",
    "guest_visit_26_100": "26~100회",
    "guest_visit_100_plus": "100회 이상",
    "device_android_total": "토탈 안드로이드",
    "device_android": "기계적 인조 인간",
    "device_desktop": "데스크탑",
    "device_ios": "이오스",
    "device_ios_total": "총 Ios",
    "device_desktop_total": "전체 데스크탑",
    "connection_duration_10": "<=10분",
    "connection_duration_20": "11-20분",
    "connection_duration_30": "21-30분",
    "connection_duration_40": "31-45분",
    "connection_duration_60": "46-60분",
    "connection_method_email": "이메일",
    "connection_method_sms": "문자 메시지",
    "connection_method_google": "Google",
    "connection_method_facebook": "페이스북",
    "age_category_1": "<18",
    "age_category_2": "18-24",
    "age_category_3": "25-34",
    "age_category_4": "35-44",
    "age_category_5": "45-54",
    "age_category_6": "55+",
    "all_guest_txt": "모든 게스트",
    "new_Guest_txt": "새로운 손님",
    "connections_txt": "사이",
    "hotspot": "핫스팟",
    "hotspot_list": "핫스팟 목록",
    "add_new_hotspot": "새로운 핫스팟 추가",
    "hotspot_information": "핫스팟 정보",
    "edit_details_button": "세부 정보 편집",
    "wifi_info_message": "연결하고 무료 WiFi를 즐기세요",
    "connection_message": "좋습니다. 연결되었습니다. ",
    "connection_message_suffix": " 와이파이",
    "wifi": "와이파이",
    "login_to_access": "접속하려면 로그인하세요",
    "verification_code": "검증 코드",
    "verification_code_message": "전송된 인증코드를 입력해주세요. ",
    "wifi_user_email": "adamo@gmail.com",
    "wifi_label": "와이파이",
    "your_name": "그런데 당신의 이름은 뭐죠?",
    "your_birthdate": "당신의 생년월일이 언제인지 말씀해 주시겠습니까?",
    "request_guest_wifi_name": "게스트 Wifi 이름을 입력하세요",
    "request_device_key": "장치 키를 입력하세요",
    "request_maximum_internet_access_length": "최대 인터넷 접속 길이를 선택해주세요",
    "mac_address": "MAC 주소",
    "device_port": "장치 포트",
    "hardware": "하드웨어",
    "current_uptime": "현재 가동 시간",
    "nearby_devices": "근처 기기",
    "firmware": "펌웨어",
    "who_are_you": "누구세요?",
    "where_to_contact_you": "어디로 연락하면 될까요?",
    "your_area_code": "당신의 지역번호는 무엇입니까?",
    "connected": "연결됨",
    "delete_hotspot_message": "이 핫스팟은 영구적으로 삭제됩니다.",
    "delete_multiple_hotspots_message": "이러한 핫스팟은 영구적으로 삭제됩니다.",
    "speed_error": "속도는 최소 0.01이어야 합니다.",
    "speed_max_error": "무제한 속도를 원하시면 최대 1024까지의 값을 입력하시거나, 지원 범위 내에서 더 낮은 값을 선택해주세요.",
    "device_ssid": "장치 SSID",
    "device_ssid_two": "장치 SSID 2",
    "device_ssid_two_wpa": "장치 SSID Two WPA",
    "device_key": "장치 키",
    "select_location": "위치 선택",
    "select_maximum_internet_access_length": "최대 인터넷 접속 길이 선택",
    "download_speed": "다운로드 속도",
    "upload_speed": "업로드 속도",
    "network_length_1": "15분",
    "network_length_2": "30분",
    "network_length_3": "45분",
    "network_length_4": "1시간",
    "network_length_5": "2시간",
    "network_length_6": "4시간",
    "network_length_7": "6시간",
    "network_length_8": "8시간",
    "network_length_9": "10시간",
    "network_length_10": "12시간",
    "employee_wifi_name": "직원 Wifi 이름",
    "employee_wifi_password": "직원 Wifi 비밀번호",
    "guest_wifi_name": "게스트 Wifi 이름",
    "menu_other_products_txt": "기타 제품 | 메뉴 온라인",
    "menu_home_text": "홈 | 메뉴 온라인",
    "whatsapp_title": "왓츠앱",
    "select_campaign_type": "캠페인 유형을 선택하세요",
    "select_readymade_templates": "기성 템플릿에서 선택하거나 직접 만들어보세요",
    "campaign_title_required": "캠페인 제목이 필요합니다.",
    "type_here": "여기에 입력하세요...",
    "location_permission_req": "위치 권한이 필요합니다",
    "platform_permission_req": "플랫폼 권한이 필요합니다",
    "profile_picture": "프로필 사진",
    "click_to_upload": "업로드하려면 클릭하세요",
    "location_permission": "위치 허가",
    "pin": "핀",
    "platform_permission": "플랫폼 허가",
    "set_pin": "PIN 설정",
    "list_of_users": "사용자 목록",
    "create_user": "사용자 생성",
    "terms_and_condition_req": "이용약관이 필요합니다.",
    "terms_and_conditions": "이용약관",
    "recommended": "추천",
    "mo": "\/월",
    "unsubscribe": "구독 취소",
    "cancel_subscription": "구독을 취소하시겠습니까?",
    "upgrade": "치받이",
    "plan_upgraded_successfully": "계획이 성공적으로 업그레이드되었습니다!",
    "menu_online": "메뉴온라인",
    "support": "지원하다",
    "wifiC": "와이파이",
    "billing": "청구",
    "please_upgrade": "모든 기능을 즐기려면 업그레이드하세요",
    "you_have_subscribed": "당신은 구독했습니다 ",
    "plan": "계획",
    "of_text": "~의",
    "days": "날",
    "remaining_days_until_plan": " 플랜을 업데이트해야 할 때까지 남은 날짜",
    "manage_your_screens": "화면 관리",
    "screens_you_save": "당신이 가지고 있는 화면",
    "add_more_screens": "더 많은 화면 추가",
    "addition_screen": "추가 화면",
    "per_screen": "화면당 $",
    "per_box": "상자당 $",
    "shipping_changes": "배송 비용",
    "upto4_boxes": "최대 4개 상자 ",
    "charge_now": "지금 요금을 받게 됩니다",
    "updated_reoccuring": "업데이트된 재발생은 다음과 같습니다.",
    "current_reoccuring": "현재 재발 : ",
    "no_text": "아니요",
    "android_box": "안드로이드 박스",
    "old_password_req": "이전 비밀번호가 필요합니다.",
    "new_password_req": "새로운 비밀번호가 필요합니다",
    "confirm_password_req": "비밀번호 확인이 필요합니다",
    "password_do_not_match": "비밀번호가 일치하지 않습니다",
    "old_password": "이전 비밀번호",
    "new_password": "새로운 비밀번호",
    "confirm_password": "비밀번호 확인",
    "copy_working_hours": "이 근무 시간을 모든 요일에 복사하시겠습니까?",
    "yes_copy": "네, 복사합니다",
    "closed": "닫은",
    "opening_time": "영업 시간",
    "closing_time": "마감 시간",
    "description": "설명",
    "file_exist": "파일이 이미 존재합니다. 다른 파일을 선택하세요.",
    "bussiness_images": "비즈니스 이미지",
    "display_info_on_market_place": "아래 정보를 마켓플레이스에 표시하시겠습니까?",
    "profile_ready": "프로필이 준비되었습니다!",
    "client_book_with_online": "이제 클라이언트가 온라인으로 귀하와 예약할 수 있습니다. 아래 링크를 공유하여 시작하세요.",
    "copy_link": "링크 복사",
    "see_your_profile": "프로필 보기",
    "ok_got_it": "알았어요. 알았어요.",
    "preview_profile": "프로필을 미리 보고 어떻게 보일지 확인하세요.",
    "opening_hours": "영업 시간",
    "display_data": "디스플레이 데이터",
    "manage_profile": "프로필 관리",
    "phone_req": "전화번호가 필요합니다",
    "market_place_img_req": "마켓플레이스 이미지가 필요합니다",
    "add_new_market_place": "새로운 마켓플레이스 추가",
    "edit_market_place": "마켓플레이스 편집",
    "no_reviews_yet": "아직 리뷰가 없습니다",
    "good": "좋은",
    "average": "평균",
    "add_marketPlace": "마켓플레이스 추가",
    "all_title": "모두",
    "included_title": "포함됨",
    "excluded_title": "제외된",
    "clients_subscribed": "마지막에 구독한 클라이언트",
    "clients_groups": "클라이언트 그룹",
    "customer_groups": "고객 그룹",
    "audience_title": "청중",
    "client_gt5": "지난번에 돌아오지 않은 사람",
    "select_all": "모두 선택",
    "modify": "수정하다",
    "campaign_title": "캠페인 제목",
    "msg_type": "메시지 유형",
    "enter_discount": "할인 입력",
    "discount_type": "할인 유형",
    "coupon_text": "쿠폰 텍스트",
    "enter_coupon_text": "쿠폰 텍스트 입력",
    "coupon_button_text": "쿠폰 버튼 텍스트",
    "enter_coupon_button_text": "쿠폰 버튼 텍스트 입력",
    "fine_prin": "미세 인쇄",
    "enter_fine_prin": "작은 글씨로 된 텍스트를 입력하세요",
    "campaign_dec": "캠페인은 시작 시간 30분 전에 수정할 수 있습니다. SMS 캠페인 요금은 캠페인 시작 시간 30분 전에 부과됩니다.",
    "blast_text_message_dec": "귀하의 일괄 문자 메시지가 준비되었습니다. 결제 방법을 선택하고 메시지를 보내세요.",
    "payment_completed": " 결제는 다음 시간에 완료됩니다. ",
    "total_cost": "총 비용",
    "close_title": "닫다",
    "friday": "금요일",
    "saturday": "토요일",
    "sunday": "일요일",
    "thursday": "목요일",
    "tuesday": "화요일",
    "wednesday": "수요일",
    "port_txt": "포트",
    "today_title": "오늘",
    "yesterday_title": "어제",
    "last_30_days_txt": "지난 30일",
    "this_month_txt": "이번 달",
    "last_month_txt": "전달",
    "valid_date_title": "유효한 날짜를 선택하세요",
    "valid_business_name_txt": "유효한 사업체 이름을 입력하세요",
    "req_bus_add_txt": "사업장 주소가 필요합니다",
    "req_domain_name_txt": "도메인 이름이 필요합니다",
    "basic_info_txt": "귀하의 사업에 대한 기본 정보",
    "loyalty_qr_def_txt": "QR 코드를 스캔하고 로열티 펀치 카드에 가입하고 보상을 받으세요.",
    "last_stamp_txt": "마지막 스탬프",
    "collected_on_txt": "수집된",
    "stamp_details_txt": "우표 세부 정보",
    "add_stamp_txt": "스탬프 추가",
    "choose_brand_color_txt": "브랜드 색상 및 언어를 선택하세요 🎨",
    "change_anytime_txt": "언제든지 이것을 변경할 수 있습니다",
    "like_to_get_txt": "당신은 얻고 싶습니까?",
    "your_next_visit_txt": "다음 방문에는요?",
    "time_to_time_get_offers": "수시로 좋은 혜택과 프로모션을 받아보시겠습니까?",
    "respond_back_to_you": "누군가가 당신에게 답장하길 원하시나요?",
    "input_serve_better": "귀하의 의견은 저희 팀이 더 나은 서비스를 제공하는 데 도움이 됩니다!",
    "do_it_txt": "그것을 해보자",
    "take_15_s": "15초 걸립니다",
    "rate_on_google_desc": "구글에서 저희를 평가해 주실 수 있을까요?",
    "may_be_next_time": "아마 다음 시간에",
    "thank_you_txt": "감사합니다",
    "look_next_txt": "다음 방문을 기대하겠습니다!",
    "deleteUserTitle": "이 사용자는 영구적으로 삭제됩니다.",
    "deleteMUserTitle": "해당 사용자는 영구적으로 삭제됩니다.",
    "change_pin": "핀 변경",
    "area_code": "당신의 지역번호는 무엇입니까? ",
    "add_menu": "메뉴 추가",
    "menu_name": "메뉴 이름",
    "add_menu_placeholder": "피자, 버거, 베이커리 등",
    "enable_data_Collection": "데이터 수집 활성화",
    "add_new_menu": "새로운 메뉴 추가",
    "rename_menu": "메뉴 이름 바꾸기",
    "preview": "시사",
    "generate_qr": "생성된 QR",
    "edit_menu": "메뉴 편집",
    "remove_menu": "메뉴 제거",
    "menu_delete_msg": "이 메뉴는 영구적으로 삭제됩니다.",
    "menus_delete_msg": "이 메뉴는 영구적으로 삭제됩니다.",
    "view_menu_dialoage_msg": "생일에 선물을 받고 싶으신가요?",
    "skip": "건너뛰다",
    "cliam_your_gift": "선물을 청구하세요",
    "collect_form": "수집 양식",
    "enter_first_name": "이름을 입력하세요",
    "enter_last_name": "성을 입력하세요",
    "enter_email": "이메일을 입력하세요",
    "enter_dob": "생년월일을 입력하세요",
    "enter_number": "숫자를 입력하세요",
    "select_gender": "성별을 선택하세요",
    "congratulations_desc": "🎉 축하합니다! 귀하의 선물 청구 요청이 성공적으로 제출되었습니다. 저희 팀에서 곧 연락드리겠습니다. 🎉",
    "male_heading": "남성",
    "male_text": "남성",
    "female_heading": "여성",
    "female_text": "여성",
    "others_heading": "기타",
    "other_text": "다른 사람들",
    "BirthD": "생년월일",
    "GenderT": "성별",
    "EmailT": "이메일",
    "dobT": "생년월일",
    "capitalize_menu": "메뉴",
    "select_menu": "메뉴 선택",
    "manage_variant": "변형 관리",
    "add_products": "제품 추가",
    "add_category": "카테고리 추가",
    "category_delete": "이 카테고리는 영구적으로 삭제됩니다.",
    "variation_delete": "이 변형은 제품에서 삭제됩니다.",
    "product_delete": "이 제품은 영구적으로 삭제됩니다.",
    "categories_delete": "이 카테고리는 영구적으로 삭제됩니다.",
    "products_delete": "이 제품은 영구적으로 삭제됩니다.",
    "category": "범주",
    "price": "가격",
    "food_product_placeholder": "피자, 버거, 베이커리 등",
    "active_title": "활동적인",
    "inActive_title": "비활성",
    "status_capital": "상태",
    "cat_status_require": "카테고리 상태가 필요합니다",
    "cat_name_require": "카테고리 이름이 필요합니다",
    "category_name": "카테고리 이름",
    "status": "상태",
    "lgDay1": "월요일",
    "lgDay2": "화요일",
    "lgDay3": "수요일",
    "lgDay4": "목요일",
    "lgDay5": "금요일",
    "lgDay6": "토요일",
    "lgDay7": "일요일",
    "is_closed_title": "닫은",
    "book_table_title": "테이블 예약",
    "emailErrSub": "이메일 제목은 필수입니다.",
    "email_subject": "이메일 제목",
    "contactUsfrmTitleEmail": "이메일로 답장하기",
    "companyInfo": "Lorem Ipsum은 lorem hrig, lorem ipsum은 fraets입니다. Lorem Ipsum은 lorem hrig, lorem ipsum은 fraets입니다. Lorem Ipsum은 lorem hrig, lorem ipsum은 fraets입니다.",
    "footerSTitle1": "Menuonline 소개",
    "footerSTitle1Lnk2": "특징",
    "footerSTitle1Lnk3": "블로그",
    "footerSTitle2": "합법적인",
    "footerSTitle2Lnk1": "이용약관",
    "footerSTitle2Lnk2": "개인정보 보호정책",
    "footerSTitle3": "비즈니스를 위해",
    "footerSTitle3Lnk1": "파트너를 위해",
    "footerSTitle3Lnk2": "가격",
    "footerSTitle3Lnk3": "파트너 지원",
    "footerCopyRithgTxt": "  Menuonline 또는 그 계열사",
    "homeTitle": "집",
    "contactTitle": "문의하기",
    "aboutTitle": "회사 소개",
    "homeMenuTitle": "메뉴",
    "bookTableTitle": "테이블 예약",
    "vDateT": "유효한 날짜를 선택하세요",
    "reqDateT": "날짜가 필요합니다",
    "dateGtErr": "날짜는 오늘 날짜보다 이후이거나 같아야 합니다.",
    "timeInvalid": "시간이 잘못되었습니다",
    "reqTimeT": "시간이 필요합니다",
    "timeInvalidBfr": "현재 시간으로부터 최소 5분 후의 시간을 선택하세요 ",
    "PerReq": "인원수가 필요합니다",
    "validcNoT": "유효한 연락처 번호를 입력하세요.",
    "reqCrT": "화폐가 필요합니다",
    "reqPrT": "가격이 필요합니다",
    "reCateT": "카테고리가 필요합니다",
    "reqVrT": "변형이 필요합니다",
    "reqVrVldT": "유효한 변형을 입력하세요 ",
    "validLNameT": "유효한 성을 입력하세요",
    "sDateInValid": "날짜가 잘못되었습니다",
    "minmReq": "최소한 필요합니다",
    "resLblDate": "날짜 선택",
    "resLblTime": "시간 선택",
    "perT": "몇 명까지 가능?",
    "resLblEmail": "이메일을 입력해 주세요 ?",
    "resLblNote": "예약 메모 추가",
    "imageError1MB": "1mb 이하의 이미지를 삽입해 주세요.",
    "imageError": "2.5mb 이하의 이미지를 삽입해 주세요.",
    "imageError3Mb": "3mb 이하의 이미지를 삽입해 주세요.",
    "imageInVldError": "파일이 잘못되었습니다.",
    "addProMT": "제품 메뉴",
    "proT": "제품 이름",
    "reqProT": "제품 이름이 필요합니다",
    "proPT": "제품 가격",
    "reservationSuccTitle": "좋습니다. 예약이 완료되었습니다.",
    "book": "책",
    "custzName": "사용자 정의 이름",
    "always": "언제나",
    "proImgReq": "제품 이미지가 필요합니다.",
    "selCustmT": "사용자 정의를 선택하거나 새로 만드십시오.",
    "visStR": "가시성 상태가 필요합니다",
    "avlblScR": "이용 가능 일정을 선택해주세요.",
    "addonPR": "애드온 제품을 선택해주세요",
    "upsellPR": "업셀 상품을 선택해주세요",
    "markItemR": "표시 항목 제품을 선택해 주세요",
    "caloriesR": "칼로리가 필요합니다",
    "allergensR": "알레르기 제품을 선택해주세요.",
    "prepInstR": "준비 지침이 필요합니다",
    "staffNR": "직원 메모가 필요합니다.",
    "discountR": "할인이 필요합니다",
    "validDiscE": "유효한 할인을 입력하세요",
    "disday": "하루 동안",
    "plSelDayT": "요일을 선택해주세요",
    "sTimeReq": "시작시간이 필요합니다",
    "sTimeInValid": "시작 시간이 잘못되었습니다.",
    "eTimeReq": "종료시간이 필요합니다",
    "eTimeInValid": "종료시간이 잘못되었습니다.",
    "sDateReq": "날짜부터가 필요합니다",
    "eDateReq": "날짜가 필요합니다",
    "eDateInValid": "현재까지는 유효하지 않습니다",
    "disdate": "날짜로부터",
    "disdate1": "현재까지",
    "disdate2": "날짜부터",
    "currT": "통화",
    "iconR": "항목 표시 아이콘을 선택해주세요",
    "minT": "최저한의",
    "maxT": "최고",
    "itemNT": "품목 이름",
    "itemPT": "품목 가격",
    "descProT": "귀하의 제품을 설명해주세요",
    "cateT": "범주",
    "selProCateT": "제품 카테고리를 선택하세요",
    "reqMT": "메뉴 이름이 필요합니다",
    "ViewMenu": "메뉴보기",
    "CopyMenu": "메뉴 복사",
    "EditMenu": "메뉴 편집",
    "RemoveMenu": "메뉴 제거",
    "welcomeMnuTitle": "환영합니다",
    "reviewT": "리뷰",
    "userErrorMsg": "죄송합니다. 오류가 발생했습니다!!!",
    "reqCateT": "카테고리 이름이 필요합니다",
    "mngCustT": "사용자 정의 관리",
    "custNReq": "사용자 정의 이름이 필요합니다",
    "incReq": "포함이 필요합니다",
    "minmValid": "유효한 최소값을 입력하세요",
    "maxmReq": "최대값이 필요합니다",
    "cust": "사용자 정의",
    "crCust": "새로운 사용자 정의 만들기",
    "custList": "사용자 정의 목록",
    "delCustmzT": "이 사용자 지정 내용은 영구적으로 삭제됩니다.",
    "mkNameR": "항목 이름을 입력하세요.",
    "mkDelT": "이 표시 항목은 영구적으로 삭제됩니다.",
    "hideText": "숨다",
    "showText": "보여주다",
    "device_mac_txt": "장치 Mac",
    "delete_hotspot_txt": "이 핫스팟은 영구적으로 삭제됩니다.",
    "delete_hotspots_txt": "이 핫스팟은 영구적으로 삭제됩니다.",
    "emp_wifi_name": "직원 Wifi 이름",
    "max_limit_txt": "속도를 UNLIMITED로 지정하려면 '1024'를 입력합니다. 이 값은 모든 캡을 제거하여 최대 처리량을 허용합니다.",
    "device_port_txt": "장치 포트",
    "firmware_txt": "펌웨어",
    "hotspot_info_txt": "핫스팟 정보",
    "editDBtn": "세부 정보 편집",
    "birth_date": "생년월일",
    "di_theme_clr": "테마 색상 정확히 7자리",
    "color_Invalid_txt": "유효한 색상 코드를 선택하세요",
    "Req_theme_clr": "테마 색상이 필요합니다",
    "select_valid_color_txt": "유효한 테마 색상을 선택해주세요.",
    "req_redir_link": "리디렉트 링크가 필요합니다",
    "val_redir_link": "유효한 리디렉션 링크를 입력하세요",
    "req_business_name_txt": "사업자명이 필요합니다",
    "splash_preview": "스플래시 미리보기",
    "create_new_splash": "새로운 스플래시 만들기",
    "splash_page": "스플래시 페이지",
    "splash_page_builder": "스플래시 페이지 빌더",
    "redirect_link": "링크 리디렉션",
    "theme_color": "테마 색상",
    "enable_social_login": "사용자 로그인에 대한 소셜 미디어 옵션 활성화",
    "google": "Google",
    "facebook": "페이스북",
    "is_mandatory": "의무적인가요?",
    "field": "필드",
    "name": "이름",
    "first_name": "이름",
    "last_name": "성",
    "birthdate": "생년월일",
    "gender": "성별",
    "email": "이메일",
    "dob": "생년월일",
    "zip_code": "우편 번호",
    "required_redirect_link": "리디렉트 링크가 필요합니다",
    "valid_redirect_link": "유효한 리디렉션 링크를 입력하세요",
    "required_theme_color": "테마 색상이 필요합니다",
    "theme_color_length": "테마 색상 정확히 7자리",
    "required_name": "이름이 필요합니다",
    "delete_splash_message": "이 스플래시를 삭제하시겠습니까?",
    "delete_multiple_splashes_message": "이 얼룩을 삭제하시겠습니까?",
    "user_login_required": "사용자 로그인이 필요합니다",
    "set_theme_color": "테마 색상 설정",
    "splash_colllect_additional_info_txt": "추가 정보 수집",
    "verify_mobile_otp_message": "휴대폰 번호를 확인하기 위해 OTP를 보내시겠습니까?",
    "add_company": "회사 추가",
    "edit_company": "회사 편집",
    "ds_device": "디지털 사이니지 장치",
    "ds_device_activation_fee": "디지털 사이니지 장치 활성화 수수료",
    "wifi_hardware": "와이파이 하드웨어",
    "menu_design": "메뉴 디자인",
    "ds_hardware": "디지털 사이니지 하드웨어",
    "company_name_req": "회사 이름이 필요합니다",
    "known_name_req": "알려진 이름이 필요합니다",
    "no_location_req": "위치 번호가 필요합니다",
    "minimum_location": "최소 1개의 위치를 입력하세요",
    "terms_req": "약관이 필요합니다",
    "notes_req": "메모는 필수입니다",
    "sms_charge_req": "SMS 요금이 필요합니다.",
    "menuonline_quantity_req": "메뉴 온라인 수량이 필요합니다",
    "menuonline_cost_req": "메뉴 온라인 비용이 필요합니다",
    "ds_quantity_req": "디지털사이니지 수량이 필요합니다.",
    "ds_monthly_cost_req": "디지털사이니지 월비용이 필요합니다.",
    "reputation_quantity_req": "평판 수량이 필요합니다",
    "reputation_cost_req": "평판 비용이 필요합니다",
    "marketing_quantity_req": "마케팅 수량이 필요합니다.",
    "marketing_cost_req": "마케팅 비용이 필요합니다",
    "tax_percentage_req": "세금 비율이 필요합니다.",
    "router_hardware_quantity_req": "라우터 하드웨어 수량이 필요합니다.",
    "wifi_hardware_cost_req": "WIFI 하드웨어 비용이 필요합니다",
    "activation_cost_req": "활성화 비용이 필요합니다",
    "wifi_quantity_req": "WIFI 수량이 필요합니다",
    "wifi_router_req": "Wifi 라우터가 필요합니다",
    "menu_design_quantity_req": "메뉴 디자인 수량이 필요합니다",
    "menu_design_cost_req": "메뉴 디자인 비용이 필요합니다",
    "quantity_req": "수량이 필요합니다",
    "cost_req": "비용이 필요합니다",
    "sheduledown_quantity_req": "스케줄 다운 수량이 필요합니다.",
    "sheduledown_cost_req": "스케줄 다운 비용이 필요합니다.",
    "loyalty_program_quantity_req": "로열티 프로그램 수량이 필요합니다.",
    "loyalty_program_cost_req": "로열티 프로그램 비용이 필요합니다.",
    "loyalty_program_sms_quantity_req": "로열티 프로그램 SMS 수량이 필요합니다.",
    "loyalty_program_sms_cost_req": "로열티 프로그램 SMS 비용이 필요합니다.",
    "comapny_created": "회사가 성공적으로 생성되었습니다",
    "comapny_updated": "회사가 성공적으로 업데이트되었습니다",
    "billing_info": "청구 정보",
    "business_name_acc_holder": "사업체 이름(계좌 소유자)",
    "client_first_name": "고객 이름",
    "client_last_name": "고객 성",
    "dba_known_as": "DBA로 알려진",
    "business_address": "사업 주소",
    "no_of_locations": "위치 수",
    "package_details": "패키지 세부 정보",
    "terms": "자귀",
    "one_month": "1개월",
    "per_sms_charge": "SMS당 요금",
    "plan_test": "계획 : 테스트",
    "desc_capital": "설명",
    "qty_capital": "수량",
    "cost_capital": "비용",
    "total_price_capital": "총 가격",
    "quantity": "수량",
    "can_not_access": "여러 개를 선택할 수 없습니다.",
    "licenses": "라이센스",
    "monthly_cost": "월별 비용",
    "plan_cost": "계획 비용",
    "tax": "세",
    "tax_percentage": "세금 비율",
    "monthly_cost_after_tax": "세금 후 월 비용",
    "activation_one_time_charge": "활성화 및 일회성 요금",
    "sub_total": "소계",
    "action": "행동",
    "cost": "비용",
    "shipping_charge": "배송비",
    "other": "다른",
    "additional_cost": "추가 비용 (기타 비용)",
    "other_tax": "기타 세금",
    "total": "총",
    "license_statistics": "라이센스 통계",
    "total_licenses": "총 라이센스",
    "available_licenses": "사용 가능한 라이센스",
    "stock": "재고",
    "has_permission": "권한이 있습니다",
    "avg_stock_price": "평균 주가",
    "average_price": "평균 가격",
    "allocated": "할당됨",
    "reward_regulars": "단골 고객에게 보상하세요",
    "not_add_coupon_url": "쿠폰 URL을 추가할 수 없습니다. 메시지의 최대 길이는 160입니다.",
    "like_attach_coupon": "쿠폰을 첨부하시겠습니까?",
    "advance_scheduling": "사전 일정 조정",
    "choose_day": "메시지를 보낼 날짜를 선택하세요",
    "select_essage_window": "메시지 창 선택",
    "subscription_outside_delivery_window": "사용자가 배송 기간 외에 구독을 신청한 경우, 다음에 가능한 시간에 자동 응답을 받게 됩니다.",
    "remaining": "남은",
    "processing_request": "요청을 처리하는 동안 오류가 발생했습니다.",
    "list_companies": "회사 목록",
    "are_you_sure": "정말이에요..?",
    "signin_agreement": "이를 확인하시면 회사는 별도의 계약 없이 제작을 진행하게 됩니다.",
    "status_updated": "상태 업데이트가 성공적으로 완료되었습니다.",
    "status_failed": "상태 업데이트에 실패했습니다",
    "new_editor_status_updated": "DS 신규 편집자 상태가 성공적으로 업데이트되었습니다.",
    "user_name": "사용자 이름",
    "known_as": "~로 알려진",
    "agreement_signed": "계약 체결",
    "deactivate": "비활성화",
    "activate": "활성화",
    "login_to_user_acc": "사용자 계정에 로그인",
    "disable": "장애를 입히다",
    "enable": "할 수 있게 하다",
    "ds_new_editor": "DS 신규 편집자",
    "sign_agreement": "계약서에 서명하다",
    "agreement_sign": "서명 없는 계약",
    "view_agreement": "계약 보기",
    "download_agreement": "다운로드 계약",
    "add_deleted_user": "삭제된 사용자 추가",
    "deleted_user": "사용자 삭제",
    "favourite": "가장 좋아하는",
    "refresh": "새로 고치다",
    "delete_chat": "채팅 삭제",
    "responsive_drawer": "반응형 서랍",
    "delete_contact": "연락처 삭제",
    "clear_chat_history": "채팅 기록 지우기",
    "clear_chat_history_dec": "이 채팅을 삭제하시겠습니까?",
    "clear_contact_dec": "이 연락처를 삭제하시겠습니까?",
    "select_contact": "연락처 선택",
    "new_conversation": "새로운 대화 시작",
    "type_msg": "여기에 메시지를 입력하세요",
    "select_delete_client": "삭제할 클라이언트를 선택하세요",
    "select_delete_client_chat": "채팅을 삭제할 클라이언트를 선택하세요",
    "select_delete_client_chat_err": "이 클라이언트와 채팅할 수 없습니다.",
    "acquire_customers_business": "다른 회사에서 새로운 고객을 확보합니다.",
    "customers_from_events": "이벤트를 통해 고객을 유치하고 귀사로 모셔오세요!",
    "customers_from_ads": "신문 광고를 통해 고객을 확보하세요.",
    "smart_pamphlets": "고객 데이터를 수집하기 위해 스마트 팜플렛을 만들어보세요.",
    "smart_qr_group": "고객이 그룹에 가입할 수 있도록 돕는 스마트 QR 코드.",
    "opt_in_number": "고객이 참여 번호를 선택할 수 있도록 합니다.",
    "qr_menu": "메뉴를 표시하기 위한 QR 코드를 만드세요.",
    "collect_birthdays": "고객의 생일 및 기념일을 수집합니다.",
    "join_loyalty_program": "내 로열티 프로그램에 새로운 고객을 가입시켜 주세요.",
    "create_feedback_forms": "고객의 피드백을 수집하기 위한 양식을 만듭니다.",
    "exclusive_discounts_promotions": "교차 프로모션에 대한 특별 할인 및 프로모션을 제공합니다.",
    "group_smart_qr_opt_in": "그룹을 만든 후 스마트 QR 코드를 생성하고 선택할 수 있어 고객이 즉시 그룹에 참여할 수 있습니다.",
    "group_smart_qr_opt_in_dec": "그룹을 만든 후, 고객이 텍스트를 사용하여 선택하고 프로모션을 받을 수 있도록 하는 이미지 위젯(예: \"피자\"를 555-555-5555로 텍스트로 보내기)",
    "in_store_discount_next_visit": "다음에 당사 매장을 방문하시면 매장 할인 혜택을 받으실 수 있습니다.",
    "delivery_redirection": "배달 리디렉션 생성",
    "additional_info": "추가 정보",
    "add_url": "URL 추가",
    "custom_fields": "사용자 정의 필드",
    "meta_tag": "메타 태그",
    "max_chars": "최대 문자 수",
    "add": "추가하다",
    "update_campaign": "캠페인 업데이트",
    "last_week_required": "지난주가 필요합니다",
    "large_week_value": "주 값이 너무 큽니다. 유효한 주를 입력하세요.",
    "less_than_last_week": "지난주에 돌아오지 않는 횟수는 지난주보다 적어야 합니다.",
    "last_week_req": "지난주에 돌아오지 않아야 합니다.",
    "birthday_special": "생일 특집",
    "birthday_message": "개인화된 인사말과 매력적인 혜택을 담은 자동 메시지를 생일날 발송합니다.",
    "birthday_sms": "SMS\/MMS를 위한 개인화된 생일 인사말을 만드세요",
    "target_audience": "타겟 고객을 선택하세요",
    "client_by_birthday": "생일별 고객",
    "clients_once_year": "이 캠페인은 1년에 한 번씩 자동으로 클라이언트에게 전송됩니다. ",
    "auto_responder_default_msg_1": "당신은 우리가 할인을 제공하는 것을 좋아하는 최고의 고객 중 한 명입니다! 항상 부유하고 건강하시길 바랍니다. 생일 축하합니다! %% firstname %%",
    "campaign_send_to_client_birthday": "이 자동 캠페인은 생일이 있는 고객에게 전송되며, 아래에서 타겟팅을 사용자 지정할 수 있습니다.",
    "get_more_engagement": "생일 문자 메시지를 보내 더 많은 참여를 얻으세요 🎂",
    "welcome_new_clients": "새로운 고객을 환영합니다",
    "make_lasting_impression": "처음 식사하는 손님을 따뜻하게 맞이하고 환영하여 지속적인 인상을 남겨보세요.",
    "personalized_greetings": "새로운 고객을 위해 개인화된 인사말을 작성하세요",
    "greetings_to_new_customers": "이 캠페인은 고객이 귀사와 처음 상호 작용한 다음날에 환영 인사를 보냅니다.",
    "audience_predefined": "이 자동응답기의 대상 고객은 미리 정의되어 있습니다. 저희가 준비해 두었습니다!",
    "send_clients_subscribed": "이 자동 응답기는 마지막에 구독한 클라이언트에게 보내도록 설정되어 있습니다. ",
    "first_interaction": "  첫 번째 상호작용 이후.",
    "default_msg_2": "첫 방문을 즐기셨기를 바랍니다. 곧 다시 뵙기를 바라기 때문에 여러분을 위한 할인 혜택을 제공합니다! 링크를 클릭하여 다음 예약을 하고 지금 바로 온라인 특별 혜택을 받으세요.",
    "new_clients_update": "신규 고객 업데이트 캠페인 텍스트 환영",
    "new_clients_warm_greeting": "따뜻한 인사로 새로운 고객을 환영하세요 💌",
    "win_back_clients": "고객을 되찾으세요",
    "re_engage_past_customers": "거부할 수 없는 제안을 통해 과거 고객을 다시 참여시켜 다시 방문하도록 유도합니다.",
    "target_disengaged_clients": "특별 할인 혜택을 제공하여 관심을 잃은 고객이 다시 찾아오도록 하는 것은 고객을 다시 끌어들이는 검증된 전략입니다.",
    "campaign_sends_to_clients": "이 캠페인은 일정 기간 내에 돌아오지 않은 고객에게 전송됩니다.",
    "customize_targeting": "아래의 타겟팅을 사용자 지정하여 만료된 고객에게 SMS\/MMS를 보내세요.",
    "clients_with_at_least": "최소한 클라이언트 ",
    "connection_last": " 마지막에 연결 ",
    "return_in_last_week": " 주에 돌아왔지만 지난주에는 돌아오지 않았습니다. ",
    "arr_menu_44": " 주",
    "df_msg_4": "우리는 당신이 다시 돌아오기를 너무나 원해서 당신이 돌아오기만 하면 10% 할인을 드리겠습니다! 오늘 다시 한번 우리에게 기회를 주시겠습니까?",
    "update_campaign_text": "클라이언트 업데이트 캠페인 텍스트를 다시 확보하세요",
    "re_invite_customers": "한동안 방문하지 않은 고객을 다시 초대하세요.",
    "loyal_patrons": "특별 할인 및 충성도 보상을 통해 충성도 높은 고객에게 감사의 마음을 전하세요.",
    "surprize_top_spenders": "개인화된 특별 혜택으로 최고 소비자에게 놀라움을 선사하세요.",
    "campaign_automatically_send": "이 캠페인은 귀하의 사업 참여를 늘리기 위해 자동으로 보상을 제공합니다.",
    "sms_loyal_clients": "이 캠페인은 충성도 높은 고객에게 SMS\/MMS를 전송합니다. 아래에서 타겟팅을 변경할 수 있습니다.",
    "or_more_connection": " 또는 마지막에 더 많은 연결 ",
    "week_period": " 주간.",
    "default_msg_5": "당신은 오랫동안 고객이었습니다! 와, 시간은 정말 빨리 가요! 우리는 당신에게 감사하고, 우리와 함께 해주셔서 감사하고 싶습니다.",
    "reward_regulars_update": "정기 보상 업데이트 캠페인 텍스트",
    "special_discounts": "감사드리고 특별 할인 혜택을 제공하세요💎",
    "leave_positive_online": "고객이 긍정적인 온라인 리뷰를 남기도록 장려하여 레스토랑의 평판을 높이세요.",
    "clients_service_online": "이 캠페인은 고객이 온라인으로 서비스를 평가하도록 장려합니다.",
    "increase_your_company": "이 자동 응답은 회사의 온라인 평가를 높이기 위해 전송됩니다.",
    "send_message_after": "메시지를 보낸 후 ",
    "min_connection": " 연결의 최소",
    "default_msg_6": "첫 방문을 즐기셨기를 바랍니다. 곧 다시 뵙기를 바랍니다! [URL] 링크를 클릭하여 Google에서 평가해 주세요.",
    "ratings_update": "더 많은 평가 업데이트 캠페인 텍스트를 받으세요",
    "friendly_nudge": "친절한 넛지로 더 많은 리뷰를 받으세요 ⭐",
    "thanks_for_visiting": "방문해 주셔서 감사합니다",
    "express_gratitude": "고객이 귀사의 사업을 선택한 데 대해 진심 어린 감사 메시지를 통해 감사를 표하십시오.",
    "thank_you_message": "고객에게 감사 메시지를 작성하세요.",
    "clients_for_visiting": "귀하의 회사를 방문해 주신 고객에게 따뜻한 감사의 말씀을 전해 보세요.",
    "campaign_audience": "자동 캠페인 대상 고객은 미리 정의되어 있습니다. 저희가 도와드리겠습니다!",
    "campaign_automatically_sends": "이 자동 캠페인은 자동으로 전송됩니다. ",
    "minutes_customer_visit": " 고객 방문 시간",
    "default_msg_7": "저희의 소중한 고객이 되어 주셔서 감사합니다. 여러분께 서비스를 제공할 수 있어 매우 감사하며, 여러분의 기대에 부응했기를 바랍니다.",
    "visiting_update_campaign": "업데이트 캠페인 텍스트를 방문해 주셔서 감사합니다.",
    "guests_thank_you": "방문해주신 손님에게 감사의 말씀을 전하세요 😊",
    "download_title": "다운로드",
    "qr_gen_title": "QR 생성됨",
    "qr_download_s_title": "QR코드가 성공적으로 다운로드되었습니다",
    "sel_dark_brand_clr_msg": "유효한 헛간 색상을 선택해주세요",
    "manage_customer_stamp_rewards": "고객 스탬프 리워드 관리 🎁",
    "sel_loc_menu_title": "메뉴의 위치를 선택하세요",
    "ans_req": "답변이 필요합니다",
    "valid_reputation_name": "유효한 평판 이름을 입력하세요",
    "reviews_name_req": "더 많은 리뷰를 받아보려면 이름이 필요합니다.",
    "birthdate_required": "생년월일은 필수입니다",
    "gender_required": "성별은 필수입니다",
    "valid_birthdate_required": "유효한 생년월일을 입력하세요",
    "custom_delivery_redirection": "그룹별로 사용자 정의 배송 리디렉션 양식 만들기",
    "customer_fields_incentive_settings": "고객 필드 및 인센티브 설정",
    "delivery_redirection_text": "배달 리디렉션",
    "delivery_redirection_success": "축하합니다! 배달 리디렉션 위젯이 생성되었습니다! 🎉",
    "swipe_to_preview_redirection": "배송 리디렉션 양식이 어떻게 표시되는지 확인하려면 스와이프하세요.",
    "enter_item_name": "품목 이름을 입력하세요",
    "home_page_text": "홈페이지 텍스트",
    "settings": "설정",
    "anniversary_required": "기념일 날짜가 필요합니다",
    "valid_anniversary": "유효한 기념일을 입력하세요",
    "form_submited": "양식이 성공적으로 제출되었습니다",
    "notifications": "알림",
    "discount_message": "지금 피자 20% 할인을 받으세요!",
    "is_required": " 필요합니다",
    "section_title_required": "섹션 제목이 필요합니다.",
    "section_dec_required": "섹션 설명이 필요합니다.",
    "client_details_required": "클라이언트 세부 정보 필드는 필수입니다.",
    "compliance": "규정 준수",
    "SMS_campaign1": "SMS 캠페인 1",
    "mobile_number_mandatory": "휴대폰 번호는 필수입니다",
    "new_answer": "새로운 답변",
    "new_question": "새로운 질문",
    "add_new_question": "새로운 답변 추가",
    "select": "선택하다",
    "group_customers_question": "고객을 어디에 그룹화하시겠습니까?",
    "contacts_added_to_group": "양식을 제출한 모든 연락처는 선택한 그룹에 추가됩니다.",
    "edit_client_details_section": "클라이언트 세부 정보 섹션 편집",
    "client_details_fields": "클라이언트 세부 정보 필드",
    "enter_client_details_section_title_desc": "클라이언트 세부 정보 섹션 제목과 설명을 입력하세요.",
    "choose_fields_displayed_client_side": "클라이언트 측에 표시할 필드를 선택하세요",
    "section_title": "섹션 제목",
    "add_title": "제목 추가",
    "section_description": "섹션 설명",
    "add_description": "설명 추가",
    "enter_program_name_location": "프로그램 이름 및 위치를 입력하세요 📍",
    "brand_theme_clr_txt": "브랜드 테마 색상",
    "set_loyalty_punch_card_theme": "귀하의 충성도 펀치 카드에 테마를 설정해 보세요 🎨",
    "upload_logo_txt": "로고 업로드",
    "recommended_image_specs": "최상의 디스플레이를 위해 512 x 512px 이미지를 사용하는 것이 좋습니다. JPG, SVG 또는 PNG. 최대 크기는 10MB입니다.",
    "valid_rep_name_txt": "유효한 평판 이름을 입력하세요",
    "rep_name_req_txt": "더 많은 리뷰를 받아보세요 이름이 필요합니다",
    "que_req": "질문이 필요합니다",
    "day_of_birthday": "생일날에",
    "day_before_birthday": "생일 3일 전",
    "week_before_birthday": "생일 일주일 전",
    "two_week_before_birthday": "생일 2주 전",
    "de_active": "비활성화",
    "campaign_details": "캠페인 세부 정보",
    "link_clicked": "링크 클릭됨",
    "history": "역사",
    "auto_responder_summary": "자동 응답 요약",
    "vsop_1": "15분",
    "vsop_2": "30분",
    "vsop_3": "45분",
    "vsop_4": "60분",
    "vsop_5": "90분",
    "vsop_6": "120분",
    "in_the_last": " 마지막에 ",
    "return_in_last": " 하지만 지난번에는 돌아오지 않았어 ",
    "of_the_connection": "연결의",
    "your_campaign": "귀하의 캠페인",
    "you_have_successfully": "당신은 성공적으로",
    "published_successfully": "성공적으로 게시되었습니다",
    "updated_smart_campaign": "스마트 캠페인을 업데이트했습니다",
    "almost_done_text": "거의 끝났어요!",
    "update_campaign_desc": "귀하의 자동 응답기가 완료되고, 활성화되면 지속적으로 클라이언트에게 메시지를 보냅니다. 이 캠페인은 언제든지 쉽게 수정하거나 일시 중지할 수 있습니다.",
    "update_and_publish": "업데이트 및 게시",
    "reset_campaign_title": "캠페인의 사용자 정의 텍스트, 이미지, 할인 혜택 및 타겟 고객이 영구적으로 삭제됩니다!",
    "space_wifi": " 와이파이",
    "custom_forms": "사용자 정의 양식",
    "web_e_signup": "웹 전자 가입",
    "import": "수입",
    "permissions_req": "권한이 필요합니다",
    "redeemed": "구속됨",
    "coupon_already_redeemed": "쿠폰은 이미 사용되었습니다",
    "autoresponder_campaigns": "자동 응답 캠페인",
    "autoresponder_campaign_desc": "자동 응답 기능을 활성화하고 개인화된 캠페인을 시작하세요",
    "mon": "월",
    "tue": "화요일",
    "wed": "수요일",
    "thu": "목",
    "fri": "금",
    "sat": "앉았다",
    "sun": "해",
    "duplicate": "복제하다",
    "visibility": "시계",
    "availability": "유효성",
    "out_stok": "스톡 아웃",
    "edit_product": "제품 편집",
    "create_product": "제품 만들기",
    "basic_info": "기본 정보",
    "basic_sub_info": "Lorem Ipsum은 단순히 인쇄된 더미 텍스트입니다...",
    "enter_product_price": "제품 가격을 입력하세요",
    "upload_image": "이미지 업로드",
    "allowed_file_formats": "최대 파일 크기가 3MB인 JPG 및 PNG 파일만 허용됩니다.",
    "pre_select": "사전 선택",
    "promotions": "프로모션",
    "discount_availability": "할인 가능 여부",
    "start_time": "시작 시간",
    "end_time": "종료 시간",
    "select_day": "요일 선택",
    "menu_required": "메뉴가 필요합니다",
    "generated": "생성됨",
    "link": "링크",
    "variations": "변형",
    "select_variant_to_add": "추가하려는 변형을 선택하세요",
    "price_title": "가격",
    "choose_image": "이미지 선택",
    "select_visiblity": "가시성 선택",
    "availability_schedule": "이용 가능 일정",
    "add_on_upsell": "추가 \/ 업셀",
    "add_on_select_product": "선택 상품에 추가",
    "upsell_product": "업셀 선택 제품",
    "variant_deletion_warning": "이 변형은 영구적으로 삭제됩니다.",
    "search_variations": "검색 변형",
    "add_variation": "변형 추가",
    "variation_text": "변종",
    "select_customization": "사용자 정의를 선택하세요",
    "add_new": "새로 추가",
    "delete_customization_warning": "제품에서 이 사용자 지정을 삭제하시겠습니까?",
    "nutritional_allergen_info": "영양 및 알레르기 정보",
    "mark_item": "항목 표시",
    "calories_text": "칼로리",
    "allergens": "알레르겐",
    "select_allergens": "알레르기 유발 물질을 선택하세요",
    "special_instructions": "특별 지침",
    "preparation_instructions": "준비 지침",
    "staff_notes": "직원 노트",
    "checkbox1": "이 메뉴 항목에 대해 고객의 특별 지시를 허용하거나 허용하지 않는 체크박스입니다.",
    "menu_c": "메뉴",
    "past_design": "과거 디자인",
    "file_name": "파일 이름",
    "variation_name": "변형 이름",
    "grid_items_title1": "테이블 텐트",
    "grid_items_title2": "디지털 메뉴",
    "grid_items_title3": "테이크아웃 메뉴",
    "grid_items_title4": "반페이지 메뉴",
    "grid_items_title5": "디지털 메뉴 5",
    "grid_items_title6": "디지털 메뉴 6",
    "grid_items_title7": "디지털 메뉴 7",
    "grid_items_title8": "디지털 메뉴 8",
    "grid_items_title9": "디지털 메뉴 9",
    "enter_valid_form_name": "유효한 양식 이름을 입력하세요",
    "form_name_required": "양식 이름이 필요합니다",
    "enter_valid_section": "유효한 섹션 제목을 입력하세요",
    "fraction_half": "1\/2",
    "fraction_two_by_two": "2\/2",
    "form_name": "양식 이름",
    "contact_list": "연락처 목록",
    "label_notify_email": "이메일로 알림을 받으시겠습니까?",
    "add_client_details_section": "클라이언트 세부 정보 섹션 추가",
    "demo_purpose_only": "이것은 데모 목적으로만 사용됩니다.",
    "form_updated_success": "양식이 성공적으로 업데이트되었습니다",
    "form_creat_success": "양식이 성공적으로 생성되었습니다",
    "add_custom_section": "사용자 정의 섹션 추가",
    "edit_custom_section": "사용자 정의 섹션 편집",
    "forms_list": "양식 목록",
    "filled_custom_form_details": "사용자 정의 양식 세부 정보 작성",
    "custom_form_deletion_warning": "이 사용자 정의 양식은 영구적으로 삭제됩니다.",


    "menu_link": "메뉴 링크",
    "WebSignUpT": "전자 가입",
    "lifeTConT": "평생 연결",
    "repCustT": "재방문 고객",
    "liftTSubT": "평생 구독자",
    "overallT": "전반적인",
    "loginCustBDT": "일별 로그인 고객",
    "deviceT": "기기 인구 통계",
    "ageTitle": "연령 인구 통계",
    "ageT": "연령대",
    "convert_social_media_followers_into_customers": "소셜 미디어 팔로워를 고객으로 전환하세요",
    "signWidDesc": "귀하의 웹사이트나 소셜 미디어에서 가입, 랜딩 페이지 생성, 쿠폰 생성을 위한 위젯을 구축하세요",
    "qrWidT": "스마트QR",
    "imgWidT": "참여 번호",
    "mobile": "이동하는",
    "YESTitle": "예",
    "NOTitle": "아니요",
    "addDelRed": "배달 리디렉션 추가",
    "dlredNameT": "배송지 변경을 위한 이름을 지정하세요",
    "conf_text_req": "확인 문자가 필요합니다.",
    "cFDesc": "고객의 특정 피드백과 정보를 효율적으로 수집하기 위해 맞춤형 양식을 만듭니다.",
    "pre_review_questions_enabled": "고객에게 리뷰를 요청하기 전에, 1~4개의 질문을 던져보세요.",
    "feel_free_edit": "필요에 따라 자유롭게 편집하세요.",
    "option": "옵션",
    "confFL": "이 확인은 귀하의 연락처가 웹 가입 양식을 제출한 후에 표시됩니다.",
    "confT": "확인 문자",
    "joinTitle": "가입하다",
    "mobValidL": "유효한 휴대폰 번호를 확인해 주세요.",
    "mobRequiredL": "휴대폰 번호가 필요합니다",

    "Business_location_label": "귀하의 사업장 위치",
    "qr_code_selection_txt": "QR코드 선택",
    "choose_theme_txt": "테마 선택",
    "edit_qr_details_txt": "QR 세부 정보 편집",
    "save_qr_code_txt": "QR코드 저장",
    "text_color_label": "텍스트 색상",
    "background_color_label": "배경색",
    "print_txt": "인쇄",
    "customer_will_see_txt": "이것이 고객에게 보이는 모습입니다. 다음 단계에서 브랜드 색상을 선택하세요.",
    "choose_your_brand_color_&_langauge_title": "브랜드 색상 및 언어를 선택하세요",
    "custom_redirect_link_label": "사용자 지정 리디렉션 링크",
    "redirect_user_to_menu": "사용자를 메뉴로 리디렉션",
    "language": "언어",
    "select_language_placeholder": "언어 선택",
    "capitalize_location_name_txt": "위치 이름",
    "created_txt": "생성됨",
    "swipe_reputation_management_txt": "스와이프하여 평판 관리가 어떻게 보일지 확인하세요",
    "sent_txt": "전송된",
    "number_label": "숫자",
    "date_label": "날짜",
    "gender_demographics_title": "성별 인구 통계",


    "step1_preview_desc": "E-가입 QR 양식에 이름을 지정하고 그룹 목록에 추가하세요.",
    "main_head": "새로운 고객 확보",
    "main_desc": "스캔하면 개인화된 가입 양식으로 이동하는 QR 코드를 삽입하여 고객을 확보하세요.",
    "step1_description": "다른 사업체에서 새로운 고객 확보",
    "form_name_field_placeholder": "레스트로 E-가입",
    "last_step_title": "축하합니다! E-signup 위젯이 생성되었습니다! 🎉",
    "last_step_desc": "스와이프하여 E-가입 양식이 어떻게 보일지 확인하세요.",
    "events_step1_preview_desc": "이벤트 QR 양식에 이름을 지정하고 그룹 목록에 추가하세요.",
    "events_main_head": "이벤트에서 고객 확보",
    "events_main_desc": "이벤트에 QR 코드를 배치하여 고객을 확보한 후, 스캔하면 개인화된 가입 양식으로 이동합니다.",
    "events_step1_desc": "이벤트에서 고객을 포착하여 매장으로 데려오세요",
    "events_name": "이벤트 이름",
    "events_form_name_field_placeholder": "Restro Fun and Food 이벤트",
    "events_last_step_title": "축하합니다! 이벤트 양식이 생성되었습니다! 🎉",
    "events_last_step_desc": "이벤트 양식이 어떻게 보일지 확인하려면 스와이프하세요.",
    "birthday_anniversary_step1_preview_desc": "고객의 생일이나 기념일을 수집하기 위해 QR 양식에 이름을 지정하고 그룹 목록에 추가하세요.",
    "birthday_anniversary_main_head": "생일이나 기념일을 모아보세요",
    "birthday_anniversary_main_desc": "고객의 생일이나 기념일을 알고 스캔하면 개인화된 가입 양식으로 리디렉션됩니다.",
    "birthday_anniversary_step1_desc": "고객의 생일 및 기념일을 수집합니다.",
    "restro_text": "레스트로",
    "birthday_anniversary_last_step_title": "축하합니다! 고객으로부터 생일 및 기념일을 받으세요 양식이 생성되었습니다! 🎉",
    "newspaper_ads_step1_preview_desc": "신문 광고를 통해 고객을 유치하기 위해 QR 양식에 이름을 지정하고 그룹 목록에 추가하세요.",
    "newspaper_ads_main_head": "광고에서 고객 확보",
    "newspaper_ads_main_desc": "신문 광고를 통해 고객을 확보한 후, 광고를 스캔하면 개인화된 가입 양식으로 사용자를 리디렉션합니다.",
    "newspaper_ads_step1_desc": "신문광고나 옥외광고로 고객을 확보하세요",
    "newspaper_ads_last_step_title": "축하합니다! 신문 광고 양식에서 고객을 얻으세요! 🎉",
    "smart_pamphlets_step1_preview_desc": "팜플렛에서 고객 데이터를 얻기 위해 QR 양식의 이름을 지정하고 그룹 목록에 추가하세요.",
    "smart_pamphlets_main_head": "팜플렛으로 고객을 확보하세요",
    "smart_pamphlets_main_desc": "팜플렛에서 고객 데이터를 얻은 후, 스캔하면 사용자를 개인화된 가입 양식으로 리디렉션합니다.",
    "smart_pamphlets_last_step_title": "축하합니다! 팜플렛 양식에서 고객 데이터를 얻었습니다! 🎉",
    "social_media_step1_preview_desc": "소셜 미디어에서 고객을 유치하고 고객으로 전환하려면 QR 양식에 이름을 지정하고 그룹 목록에 추가하세요.",
    "social_media_main_desc": "소셜 미디어에서 고객을 유치하고, 고객이 게시물을 스캔하면 개인화된 가입 양식으로 리디렉션합니다.",
    "social_media_form_name_field_placeholder": "Resto 축제 제안 - Facebook 게시물",
    "social_media_last_step_title": "축하합니다! 고객 소셜 미디어 양식을 만들었습니다! 🎉",
    "claim_offer_text": "귀하의 세부 정보를 입력하고 지금 제안을 신청하세요",
    "form_title_description": "고객이 세부 정보를 입력할 수 있도록 양식 제목 및 설명을 추가하세요.",
    "no_subscribed_clients": "선택된 고객 그룹에는 구독된 클라이언트가 없습니다.",
    "welcome_message_offer": "고객이 가입한 후 환영 메시지를 보내고 쿠폰을 제공하세요.",
    "system_grp": "시스템 그룹",
    "import_clients": "클라이언트 가져오기",
    "delivery_redirection_name": "배달 리디렉션 이름",
    "created_at": "생성됨",
    "provide_stamp_user": "사용자에게 스탬프 제공",
    "scan_qr_code": "QR코드 스캔",
    "add_point": "포인트 추가",
    "total_points": "총 포인트",
    "user_profile": "사용자 프로필",
    "stamp_history": "우표 역사",
    "reward_txt": "보상",
    "variant_placeholder": "소형, 중형, 대형 등",
    "sent_coupon": "쿠폰 전송됨",
    "order_status": "주문 상태",
    "payment_status": "결제 상태",
    "order_currency": "주문 통화",
    "card_data": "카드 데이터",
    "reward_expiring_on": "보상 만료일",
    "reward_status": "보상 상태",
    "reward_get_date": "보상 날짜 받기",
    "reward_name": "보상 이름",
    "add_point_type": "포인트 유형 추가",
    "restaurant_location": "레스토랑 위치",
    "collect_points": "포인트 모으기",
    "rewards_expire": "보상 만료",
    "rewards_redeem": "보상 교환",
    "total_rewards": "총 보상",
    "created_by": "만든 사람",
    "anniversary": "기념일",
    "remove_user_group_txt": "그룹에서 사용자 제거",
    "delete_permanently_grp_msg": "이 클라이언트는 그룹에서 영구적으로 삭제됩니다.",
    "delete_multiple_permanently_grp_msg": "이 클라이언트는 그룹에서 영구적으로 삭제됩니다.",
    "delete_multiple_client": "해당 클라이언트는 영구적으로 삭제됩니다.",
    "messages": "메시지",
    "management": "관리",
    "client": "고객",
    "country": "국가",
    "state": "상태",
    "city": "도시",
    "zip_code": "우편 번호",
    "1_sparkly_point_txt": "🎉 반짝이는 포인트 1개를 획득했습니다! 🌟",
    "select_country_txt": "국가 선택",
    "select_state": "주를 선택하세요",
    "ls_thanks_for_join": "로열티 펀치 카드에 가입해 주셔서 감사합니다.🎁🎈",
    "month": "월",
    "welcome_message": "고객이 가입한 후 환영 메시지를 보내세요.",
    "all_rewards_txt": "모든 보상",
    "active_rewards": "활성 보상",
    "expired_and_redeemed": "만료 및 사용됨",
    "redirect_3rd_party_delivery": "제3자 배송을 귀하의 매장이나 온라인 주문 플랫폼으로 리디렉션합니다.",
    "search_or_start_new": "검색 또는 새로 시작",
    "contacts": "콘택트 렌즈",
    "chats": "채팅",
    "add_by_restaurant": "레스토랑으로 추가",
    "using_scan_code": "스캔 코드 사용",
    "step": "단계",
    "list_widget_text": "위젯 목록",
    "swipe_free_wifi_txt": "무료 wifi를 위해 스와이프하세요",
    "create_widget_txt": "위젯 생성",
    "redeem_award_msg": "정말인가요? 이 보상을 받으시겠습니까?",
    "on_collect_msg": "수집 중",
    "pause": "정지시키다",
    "resume": "재개하다",
    "location_added": "위치 추가됨",
    "sub_user_admin_permissions_txt": "디지털 사이니지에서 하위 사용자에게 관리자 권한 접근 허용",
    "unlist": "목록에서 제외",
    "cannot_delete_default_terms": "기본 약관을 삭제할 수 없습니다",
    "something_went_wrong": "문제가 발생했습니다!",
    "card_download_success": "카드가 성공적으로 다운로드되었습니다",
    "permission_denied": "허가가 거부되었습니다!",
    "invalid_qr_code": "유효한 QR 코드를 스캔하세요",
    "select_customer": "고객을 선택해주세요",
    "online": "온라인",
    "offline": "오프라인",
    "no_data": "죄송합니다. 데이터를 찾을 수 없습니다!",
    "forever_free": "영원히 무료",
};

export default ko