const ar = {
    "rated_google_reviews": "تم تقييمه بـ 5.0 على تقييمات جوجل",
    "get_your_digital_menu": "احصل على قائمتك الرقمية",
    "instant_popup_menu": "قائمة منبثقة فورية بدون مسح رمز الاستجابة السريعة",
    "free_forever": "مجاني للأبد",
    "the_future_is_here": "المستقبل هنا!",
    "upgrade_your_restaurant": "قم بتحديث مطعمك بقائمة رقمية مذهلة بتكلفة مجانية. اجتذب المزيد من العملاء بدءًا من اليوم.",
    "get_my_free_menu_now": "احصل على قائمتي المجانية الآن",
    "no_credit_card_required": "*لا حاجة لبطاقة ائتمان",
    "join_largest_marketplace": "انضم إلى أكبر سوق للمطاعم!",
    "what_will_you_get": "ماذا سوف تحصل عليه",
    "free": "حر",
    "free_digital_menu": "قائمة رقمية مجانية ثنائية الأبعاد أو ثلاثية الأبعاد",
    "free_digital_menu_desc": "قل وداعًا لقوائم الطعام الورقية وإعادة الطباعة باهظة الثمن",
    "quick_and_easy_signup": "التسجيل سريع وسهل",
    "communicate_with_customers_title": "التواصل مع العملاء مجانا!",
    "features1": [
        "وفِّر تكاليف الطباعة واستمتع بتحديثات القائمة الفورية عندما تحتاج إليها.",
        "يمكنك الوصول إلى القائمة الرقمية الخاصة بك على أي جهاز",
        "صديق للبيئة وخالٍ من المتاعب"
    ],
    "features2": [
        "دع العملاء يستكشفون أطباقك افتراضيًا باستخدام صور ثلاثية الأبعاد مذهلة.",
        "قم بتعزيز المشاركة من خلال منح رواد المطعم معاينة واقعية لوجباتهم.",
        "تميز مع تجربة قائمة طعام جذابة مثل مذاق طعامك."
    ],
    "features3": [
        "قم بترحيب الضيوف بقائمتك على الفور عند دخولهم المطعم.",
        "إرسال التحديثات الخاصة بالعروض الترويجية والعروض الترويجية إلى هواتفهم تلقائيًا.",
        "قم بتعزيز تجربة تناول الطعام الخاصة بك من خلال الوصول السريع إلى قائمتك دون تلامس."
    ],
    "feature4": [
        "تسليط الضوء على العروض الموسمية والعروض اليومية والعروض الترويجية الحصرية.",
        "اجذب انتباه العملاء إلى العناصر الأكثر شهرة لديك.",
        "قم بإنشاء حالة من الإلحاح والإثارة باستخدام الصور والأوصاف الجذابة."
    ],
    "feature5": [
        "كن جزءًا من مجتمع عبر الإنترنت يضم آلاف الزوار يوميًا.",
        "قم بتعزيز الرؤية والوصول إلى عملاء جدد يبحثون بنشاط عن خيارات تناول الطعام.",
        "احصل على مكان مميز في وقت مبكر لزيادة ظهورك وجذب المزيد من الرواد."
    ],
    "section3_heading": "لماذا تتجه إلى العالم الرقمي؟ لأنه مجاني وسهل!",
    "section3_description": "استخدم الميزات القوية المصممة لتعزيز جاذبية مطعمك.",
    "instant_menu": "القائمة الفورية",
    "instant_menu_notification": "النوافذ المنبثقة والإشعارات",
    "instant_menu_description": "الترحيب بالضيوف من خلال الوصول الفوري إلى القائمة بمجرد دخولهم. إرسال أحدث القائمة والعروض الخاصة تلقائيًا مباشرة إلى هواتفهم المحمولة",
    "digital_menu": "القوائم الرقمية",
    "at_zero_cost": "بدون تكلفة",
    "digital_menu_description1": "وداعًا لقوائم الطعام الورقية وإعادة طباعتها باهظة الثمن. انتقل إلى قائمة طعام رقمية محدثة دائمًا - وهي مجانية إلى الأبد!",
    "digital_menu_description2": "بفضل قائمتنا الرقمية، ستكون عروضك محدثة دائمًا ومتاحة على أي جهاز. وفِّر المال وبسّط تحديثات القائمة باستخدام حل سلس وخالٍ من التكاليف.",
    "try_me_button": "جربني",
    "try_3d_menu_title": "القوائم ثلاثية الأبعاد",
    "try_3d_menu_title2": "مجانًا - واو!",
    "try_3d_menu_description1": "اجعل أطباقك تبرز من الشاشة. قم بتحميل صور ثلاثية الأبعاد ودع العملاء يتذوقون طعامك افتراضيًا قبل دخولهم.",
    "try_3d_menu_description2": "إن الصور ثلاثية الأبعاد عالية الجودة تخلق معاينة شهية، مما يعزز الجاذبية ويشجع الطلبات.",
    "try_now": "جرب الآن",
    "showcase_title": "عرض منتجاتك",
    "showcase_specials": "العروض الخاصة",
    "showcase_specials_description1": "هل لديك صفقة شهية أو عرض ترويجي لا يضاهى؟ قم بتسليط الضوء عليه في قائمتك الرقمية واجتذب المزيد من الأشخاص الجائعين.",
    "showcase_specials_description2": "اجعل العروض الترويجية أمرًا لا يمكن تفويته، من خلال جذب الانتباه باستخدام صور جريئة وأوصاف جذابة.",
    "marketplace": "السوق",
    "marketplace_description": "نحن نعمل على إنشاء سوق يضم أكثر من 2000 زائر يوميًا. انضم إلينا الآن، وستكون أول من يصطف في الطابور عند إطلاقنا. يمكنك الوصول إلى عملاء جدد يبحثون بنشاط عن خيارات لتناول الطعام. احجز مكانك مبكرًا لتحقيق أقصى استفادة من العرض!",
    "see_other_products": "شاهد المنتجات الأخرى",
    "about_us": "معلومات عنا",
    "why_serving_free": "لماذا نقدم هذا من أجلك",
    "serving_free_highlight": "حر",
    "about_desc": "نحن من السكان المحليين هنا في دالاس، ملتزمون بمساعدة المطاعم مثل مطعمك على النجاح.",
    "about_tool_free": "نحن نقدم هذه الأداة القوية مجانًا تمامًا لأننا نؤمن بدعم مجتمعنا.",
    "no_catch": "لا مجال للكذب، فنحن نعلم أنه عندما تنجح، يصبح مجتمعنا أقوى. 😊",
    "understanding_challenges": "نحن نتفهم التحديات التي تواجه إدارة الأعمال، ولهذا السبب نحن ملتزمون بتوفير الأدوات التي تجعل عملياتك أكثر سلاسة ونطاقك أوسع.",
    "community_growth": "مع نموك، ينمو مجتمعنا معك، مما يخلق تأثيرًا متموجًا من النجاح والدعم. معًا، دعنا نرفع مستوى تجربة تناول الطعام للجميع.",
    "how_it_works": "كيف يعمل",
    "three_simple_steps": "ثلاث خطوات بسيطة لـ",
    "get_started_highlight": "البدء",
    "no_tech_skills_needed": "لا تحتاج إلى مهارات تقنية. إذا كنت تستطيع قلب البرجر، فيمكنك القيام بذلك!",
    "step1_title": "اعرض عرضك الترويجي مجانًا!",
    "step1_desc": "ابدأ بشكل أسرع مما يمكنك قوله \"اطلب ما تريد!\" فقط املأ نموذجًا بسيطًا.",
    "step2_title": "قم بتخصيص القائمة الخاصة بك",
    "step2_desc": "أضف أطباقك، وقم بتحميل الصور حتى لو كانت ثلاثية الأبعاد، وقم بتسليط الضوء على العروض الخاصة بك.",
    "step3_title": "شارك ونمو",
    "step3_desc": "شارك قائمتك الرقمية عبر رموز الاستجابة السريعة (QRs)، أو وسائل التواصل الاجتماعي، أو موقع الويب الخاص بك.",
    "testimonials": "شهادات",
    "testimonials_section_title": "لا تكتفي بكلامنا فقط",
    "testimonials_section_desc": "شاهد ما يقوله أصحاب المطاعم الآخرين في دالاس.",
    "faq_section_title": "هل لديك أسئلة؟ لدينا الإجابات",
    "faq_section_desc": "كل ما تحتاج إلى معرفته قبل البدء.",
    "faqs": "الأسئلة الشائعة",
    "faq_answers": [
        {
            "que": "هل هو حقا مجاني إلى الأبد؟",
            "ans": "بالتأكيد! لا توجد رسوم خفية، ولا بطاقات ائتمان، ولا شروط."
        },
        {
            "que": "هل أحتاج إلى أي معرفة تقنية؟",
            "ans": "إذا كان بإمكانك إرسال بريد إلكتروني، فيمكنك القيام بذلك. الأمر بهذه البساطة."
        },
        {
            "que": "لماذا تقدم هذا مجانًا؟",
            "ans": "نحن نؤمن بأن العطاء يأتي في المقام الأول. قد نقدم خدمات أخرى ستعجبك في المستقبل، لكن الأمر متروك لك تمامًا."
        },
        {
            "que": "ما هي الصفقة مع السوق؟",
            "ans": "نحن نعمل على إعدادها! بمجرد أن نمتلك عددًا كافيًا من المطاعم على متن الطائرة، سنطلق سوقك لنمنحك مزيدًا من التعرض."
        }
    ],
    "testimonials_content1": "كان التحول إلى القائمة الرقمية هو أفضل خطوة قمنا بها! عملاؤنا يحبون الراحة والمظهر المحدث!",
    "testimonials_name1": "موريس جورج",
    "testimonials_restaurant_name1": "مطعم القصر",
    "testimonials_content2": "قائمتنا الرقمية الجديدة تجعل التصفح أسهل بالنسبة للضيوف، كما أنها تزيد من المشاركة بشكل كبير!",
    "testimonials_name2": "أميليا جونسون",
    "testimonials_restaurant_name2": "أكلات حضرية",
    "testimonials_content3": "ينبهر الضيوف بالصور ثلاثية الأبعاد - فقد ساعدتنا على التميز وتعزيز تجربة تناول الطعام الخاصة بهم!",
    "testimonials_name3": "ديفيس جرين",
    "testimonials_restaurant_name3": "موقد المدينة",
    "ready_to_boost_your_restaurant": "هل أنت مستعد لتعزيز مطعمك؟",
    "ready_to_boost_desc": "لا تتخلف عن الركب. انضم إلى الثورة الرقمية اليوم!",
    "yes_i_want_my_free_digital_menu": "نعم، أريد قائمتي الرقمية المجانية",
    "480_plus": "480+",
    "people_already_joined": "الناس انضموا بالفعل",
    "menu_online_home": "القائمة الرئيسية على الإنترنت",
    "welcome": "مرحباً",
    "description_other": "دعونا نعمل على تعزيز رضا العملاء الخاص بك!",
    "benefits": "فوائد",
    "greeting_text": "مرحباً بكم 👋",
    "all_in_one_features": "🚀 ميزات متكاملة لرفع مستوى مطعمك",
    "menu_online_menu_name": "القائمة على الإنترنت",
    "menu_online_description": "قم بتحويل قائمتك إلى قائمة رقمية لتسهيل وصول العملاء إليها عبر الإنترنت.",
    "menu_online_benefits": "قم بتعزيز راحة العملاء وتعزيز مشاركتهم من خلال السماح لهم بمشاهدة قائمتك في أي وقت وفي أي مكان.",
    "ds_name": "اللافتات الرقمية",
    "ds_description": "عرض العروض والعروض الترويجية على شاشات ديناميكية داخل المتجر.",
    "ds_benefits": "اجذب انتباه العملاء باستخدام الصور الجذابة، والترويج للعروض الإضافية والعروض في الوقت الفعلي دون عناء.",
    "loyalty_punch_name": "بطاقة الولاء",
    "loyalty_punch_description": "كافئ العملاء المتكررين ببرنامج الولاء الرقمي.",
    "loyalty_punch_benefits": "تعزيز الاحتفاظ بالعملاء وزيادة المبيعات من خلال تحفيز الولاء من خلال المكافآت القابلة للاسترداد للزيارات المتكررة.",
    "marketing_menu_name": "تسويق",
    "marketing_description": "الوصول إلى المزيد من العملاء باستخدام الحملات التسويقية الرقمية المستهدفة.",
    "marketing_benefits": "قم بتوسيع نطاق وصول مطعمك وزيادة حركة المشاة باستخدام العروض الترويجية المخصصة المصممة لجذب العملاء الجدد والعملاء المتكررين.",
    "get_more_reviews_menu_name": "احصل على المزيد من المراجعات",
    "get_more_reviews_description": "شجع العملاء الراضين على ترك تقييمات إيجابية عبر الإنترنت.",
    "get_more_reviews_benefits": "قم بتعزيز مصداقية مطعمك واجتذب عملاء جدد من خلال تعزيز سمعتك عبر الإنترنت من خلال المزيد من التقييمات الإيجابية.",
    "guest_smart_wifi_menu_name": "واي فاي ذكي للضيوف",
    "guest_smart_wifi_description": "توفير خدمة الواي فاي المجانية لالتقاط بيانات الضيوف للتسويق.",
    "guest_smart_wifi_benefits": "زيادة رضا العملاء من خلال جمع بيانات العملاء القيمة لتخصيص العروض الترويجية المستقبلية.",
    "reservation_menu_name": "حجز",
    "reservation_description": "السماح للعملاء بحجز الطاولات عبر الإنترنت بكل سهولة.",
    "reservation_benefits": "تحسين تجربة تناول الطعام عن طريق تقليل أوقات الانتظار وتبسيط الحجوزات لإدارة المقاعد بشكل أفضل.",
    "pos_integration_menu_name": "تكامل نقاط البيع",
    "pos_integration_description": "دمج نظامنا مع نظام نقاط البيع الخاص بك.",
    "pos_integration_benefits": "قم بتبسيط عملية معالجة الطلبات وتقليل الأخطاء عن طريق الحفاظ على مزامنة أنظمتك عبر الإنترنت وفي المتجر.",
    "online_ordering_menu_name": "الطلب عبر الإنترنت",
    "online_ordering_description": "تمكين العملاء من تقديم الطلبات مباشرة من موقع الويب الخاص بك أو التطبيق.",
    "online_ordering_benefits": "قم بزيادة المبيعات وتقليل الاعتماد على منصات الطرف الثالث من خلال تقديم خيار الطلب المباشر الذي يوفر الرسوم.",
    "pro_label": "محترف",
    "coming_soon": "قريباً",
    "locked_label": "مغلق",
    "forever_label": "للأبد",
    "new_label": "جديد",
    "other_products_page_title": "منتجات أخرى | القائمة على الإنترنت",
    "other_products_description": "سياسة الخصوصية عبر الإنترنت",
    "other_products_header_title": "منتجات أخرى",
    "privacy_policy": {
        "title": "سياسة الخصوصية",
        "lastUpdated": "آخر تحديث: 1 يوليو 2024",
        "introduction": {
            "header": "سياسة الخصوصية للعملاء الجدد والحاليين",
            "paragraph1": "النقاط الرئيسية التالية (بما في ذلك مراجع الأقسام) مقدمة لراحتك فقط ولا تحل محل الشروط والأحكام الكاملة. تقع على عاتقك مسؤولية قراءة هذه الشروط والأحكام قبل استخدام خدمة MENUONLINE INC.",
            "paragraph2": "إن استخدام Menuonline يعني موافقتك على الشروط والأحكام وتؤكد وتضمن أنك مفوض من قبل مؤسستك أو شركتك للقيام بذلك. (راجع القسم 3 \"كيفية العمل\")",
            "paragraph3": "Menuonline Inc هي خدمة تسويقية تعتمد على الإذن. سيؤدي الإرسال المتعمد لـ \"البريد العشوائي\" أو انتهاك الشروط والأحكام إلى إغلاق حسابك. (انظر القسم 16 \"الاستخدام المقبول للخدمة\")",
            "paragraph4": "البيانات التي تستضيفها في Menuonline Inc مثل سجلات الاتصال ومحتوى البريد الإلكتروني والمعلومات التي تجمعها من خلال Menuonline Inc هي ملك لك. (راجع القسم 14 \"ما الذي تملكه؟\").",
            "paragraph5": "برنامج Menuonline Inc والمحتوى الموجود على موقعنا الإلكتروني مملوك لنا. (انظر القسم 13 \"ما الذي نملكه؟\") وبصرف النظر عن المستخدمين الخاصين بك، لا يمكن الاطلاع على البيانات أو المحتوى غير المنشور الذي تستضيفه مع Menuonline Inc إلا من قبل موظفي Menuonline Inc وشركائها الذين وقعوا اتفاقيات سرية. (انظر القسم 14 \"ما الذي تملكه؟\")",
            "paragraph6": "يتم الاحتفاظ بجميع المعلومات الشخصية القابلة للتحديد (PII) فقط على الخوادم الموجودة في كندا. (راجع القسم 14 \"ما هي ممتلكاتك؟\").",
            "paragraph7": "تخضع خدمة Menuonline Inc لقوانين كندا ومقاطعة أونتاريو. (راجع المادة 24 \"القانون الحاكم\")",
            "paragraph8": "سيتم دائمًا إحالة أي طلبات للحصول على بياناتك أو محتواك من قبل الأفراد أو الشرطة أو الهيئات التنظيمية الأخرى من داخل كندا أو خارجها إليك. الاستثناء الوحيد هو إذا كان هذا يجعلنا ننتهك القانون الكندي أو قانون أونتاريو؛ ومع ذلك، في جميع الحالات سنقاوم بشدة أي إصدار لبياناتك من قبل أي شخص غيرك. (انظر القسم 14 \"ما هي ممتلكاتك؟\").",
            "paragraph9": "إن أمان Menuonline Inc لا يعتمد إلا على قدرتك على إدارة أسماء المستخدمين وكلمات المرور. لا نفرض رسومًا على حسابات مستخدمي Menuonline Inc، لذا أنشئ حسابًا منفصلًا لكل مستخدم واحتفظ بكلمات المرور سرية. أنت مسؤول عن حسابك. (راجع القسم 3 \"كيفية العمل\")",
            "paragraph10": "يمكنك إلغاء حسابك في أي وقت. لا نقدم لك استرداد الأموال على أي رصيد غير مستخدم إذا قمت بالإلغاء. (راجع القسم 3 \"كيفية العمل\")"
        },
        "termsAndConditions": {
            "title": "الشروط والأحكام الخاصة بـ MENUONLINE INC",
            "lastUpdate": "آخر تحديث: 14 يناير 2020",
            "paragraph1": "يرجى قراءة شروط وأحكام الخدمة هذه (\"الشروط\") بعناية قبل استخدام هذا الموقع الإلكتروني (\"الموقع\"). تعفي هذه الشروط شركة Menuonline Inc. (\"Menuonline Inc\" أو \"نحن\") وغيرها من الشركات من المسؤولية و\/أو تحد من مسؤوليتنا ومسؤوليتهم وتحتوي على أحكام مهمة أخرى تنطبق على استخدامك لهذا الموقع. من خلال زيارة أو استخدام هذا الموقع أو الخدمة، فإنك توافق نيابة عن نفسك، ونيابة عن أي منظمة قد تتصرف نيابة عنها (يشار إليها مجتمعة هنا باسم \"أنت\")، على قبول هذه الشروط والالتزام بها لكل استخدام وكل زيارة لهذا الموقع."
        },
        "services": {
            "title": "خدماتنا.",
            "paragraph": "الخدمات التي نقدمها من خلال الموقع هي خدمة تسمح لك بإنشاء وإرسال وإدارة رسائل البريد الإلكتروني (\"رسائل البريد الإلكتروني\") إلى المستلمين الذين تختارهم (\"الخدمة\")."
        },
        "license": {
            "title": "رخصة.",
            "paragraph": "بموجب هذه الشروط، تمنحك Menuonline Inc بموجب هذا ترخيصًا غير حصري ومحدود وغير قابل للتحويل أثناء مدة الاستخدام لعرض الموقع واستخدام الخدمة وفقًا لما تسمح به ميزات الخدمة. نحتفظ بجميع الحقوق غير الممنوحة صراحةً بموجب هذه الشروط في الخدمة ومحتوى Menuonline Inc (كما هو موضح أدناه)."
        },
        "emailingContent": {
            "title": "إرسال المحتوى عبر البريد الإلكتروني.",
            "paragraph": "عند التعامل مع Menuonline Inc عبر هذا الموقع أو الخدمة، فإنك توافق على تقديم معلومات صحيحة ودقيقة. علاوة على ذلك، فإنك تفهم أن تقديم معلومات سرية عبر البريد الإلكتروني يعد ممارسة غير آمنة. كما أنك تفهم أنك المسؤول الوحيد عن أي شيء ترسله إلى أي شخص يستخدم خدمتنا."
        },
        "support": {
            "title": "يدعم.",
            "paragraph": "نحن نقدم لك الدعم لمساعدتك على الاعتماد على نفسك في استخدام الخدمة. نقدم لك الدعم في شكل وثائق ومقاطع فيديو على الموقع ونظام تذاكر الدعم الخاص بشركة Menuonline Inc وعن طريق الهاتف. يمكنك التواصل معنا بالنقر فوق علامة التبويب \"الدعم\" أعلى الموقع. لا توجد رسوم مقابل دعم المنتج؛ ومع ذلك، نحتفظ بالحق في إيقاف الدعم لحسابات عملاء محددة وفقًا لتقديرنا."
        }
    },
    "terms_condition": {
        "title": "شروط الخدمة | القائمة على الإنترنت",
        "header": "الشروط والأحكام للعملاء الجدد والحاليين"
    },
    "privacy_policy_title": "سياسة الخصوصية",
    "privacy_policy_effective_date": "ساري المفعول اعتبارًا من 16 يناير 2020",
    "privacy_policy_general_title": "سياسة الخصوصية العامة",
    "privacy_policy_intro": "تدرك شركة menuonline.com (Menuonline و\"نحن\") أهمية حماية خصوصية المعلومات الشخصية للأفراد وأهمية أمن المعلومات السرية من جميع الأنواع. لقد أنشأنا سياسة الخصوصية هذه (\"السياسة\") لإثبات التزامنا بحماية \"خصوصيتك\". تشرح سياسة الخصوصية هذه:",
    "privacy_policy_points": [
        "ما هي المعلومات الشخصية التي نجمعها ولماذا نجمعها؛",
        "كيف نستخدم معلوماتك الشخصية؛",
        "مع من نشارك معلوماتك الشخصية؛ و",
        "الخيارات المتاحة لك للوصول إلى معلوماتك الشخصية وتحديثها وإزالتها من الخدمة."
    ],
    "privacy_policy_pipeda": "تتولى Menuonline إدارة جمع كافة المعلومات وفقًا لقانون حماية المعلومات الشخصية والوثائق الإلكترونية في كندا (PIPEDA).",
    "privacy_policy_update_notice": "يرجى ملاحظة أن Menuonline تحتفظ بالحق في تحديث أو تغيير سياسة الخصوصية هذه في أي وقت. وسنقوم بإخطارك بأي تغييرات من خلال نشر سياسة محدثة على هذا الموقع أو كما يتم توفيرها من خلال الخدمات. ستحكم النسخة الحالية من هذه السياسة استخدام mycircle للمعلومات الشخصية للمستخدمين. من خلال الاستمرار في الوصول إلى موقع mycircle أو الاستمرار في استخدام خدماته بعد إجراء تغييرات على سياسة الخصوصية هذه، فإنك توافق على الالتزام بالشروط المنقحة.",
    "privacy_policy_consent_title": "الموافقة على المعلومات الشخصية",
    "privacy_policy_consent_text": "من خلال تزويد Menuonline بمعلوماتك الشخصية من خلال موقعنا الإلكتروني أو أي من نماذجنا، فإنك توافق على جمع هذه المعلومات واستخدامها والإفصاح عنها وفقًا لهذه السياسة أو للأغراض التي تم تحديدها لك في وقت تقديمك للمعلومات الشخصية. لن تتم معالجة أي استخدام ثانوي للمعلومات الشخصية إلا بموافقتك الصريحة، أو ستتاح لك فرصة إلغاء الاشتراك.",
    "privacy_policy_opt_out_text": "لديك الفرصة لسحب موافقتك أو إلغاء الاشتراك في أي وقت. إذا كنت ترغب في سحب موافقتك أو إلغاء الاشتراك، يمكنك النقر فوق رابط تفضيلات الاتصالات الموجود أسفل أي بريد إلكتروني تتلقاه منا.",
    "privacy_policy_info_collection_title": "المعلومات التي نجمعها",
    "privacy_policy_info_collection_text": "تقوم Menuonline بجمع ومعالجة المعلومات الشخصية وفقًا لهذه السياسة. لن نبيع أو نشارك أو نؤجر هذه المعلومات للآخرين، باستثناء ما هو منصوص عليه في هذه السياسة. تقوم Menuonline بجمع المعلومات الشخصية لأغراض تقديم خدماتنا أو منتجاتنا إليك.",
    "privacy_policy_info_types_text": "تتضمن أنواع المعلومات التي تجمعها Menuonline ما يلي:",
    "privacy_policy_account_holders_title": "أصحاب حسابات Menuonline",
    "privacy_policy_registration_info": "معلومات التسجيل: إذا قمت بالتسجيل لاستخدام الخدمات، فأنت بحاجة إلى حساب Menuonline لتصبح صاحب حساب (\"صاحب الحساب\"). عند التسجيل للحصول على حساب، سيُطلب منك تقديم بعض المعلومات الأساسية، مثل اسمك وعنوان بريدك الإلكتروني واسم المستخدم وكلمة المرور واسم الشركة والمهنة والموقع ورقم الهاتف. كلمات المرور مشفرة - لا يستطيع موظفو Menuonline عرض أو استرداد كلمات المرور.",
    "privacy_policy_billing_info": "معلومات الفواتير: إذا قمت بالاشتراك في خدماتنا، فسوف تحتاج إلى تزويدنا بمعلومات الدفع والفوترة.",
    "privacy_policy_cookies_info": "ملفات تعريف الارتباط: نستخدم ملفات تعريف الارتباط لتتبع بيانات الزوار، مثل مصدر زيارات الموقع وعدد زيارات الموقع على موقعنا.",
    "privacy_policy_ip_info": "عناوين IP: يتم جمع عناوين IP عنك كجزء من استخدامك للخدمات (على سبيل المثال لغرض تسجيل الموقع على مستوى البلد وعناوين IP المستخدمة عند استخدام خدماتنا).",
    "privacy_policy_your_contacts_title": "جهات الاتصال الخاصة بك",
    "privacy_policy_distribution_list": "قائمة التوزيع: كجزء من استخدامك لخدماتنا، قد تزودنا بمعلومات شخصية عن جهات اتصالك (\"جهات الاتصال\") أو قد نجمعها كجزء من الخدمات. تتضمن هذه المعلومات عناوين البريد الإلكتروني وقد تتضمن معلومات تعريفية أخرى مثل الاسم أو اسم الشركة أو معلومات أخرى تقدمها إلى Menuonline أثناء استخدام الخدمات. يجب عليك التأكد من حصولك على الموافقة المناسبة من جهات اتصالك للسماح لنا بمعالجة معلوماتهم.",
    "privacy_policy_billing_info_contacts": "معلومات الفواتير: إذا قمت بالاشتراك في خدماتنا، فسوف تحتاج إلى تزويدنا بمعلومات الدفع والفوترة.",
    "privacy_policy_cookies_contacts": "ملفات تعريف الارتباط: لن تستخدم Menuonline ملفات تعريف الارتباط وتقنيات التتبع المماثلة إلا لجمع واستخدام المعلومات الشخصية عن جهات الاتصال الخاصة بك إذا قمت بإضافة رمز تتبع Menuonline إلى موقع الويب الخاص بك. ستحتوي أي صفحات مقصودة تستضيفها Menuonline تلقائيًا على رمز تتبع.",
    "privacy_policy_ip_contacts": "عناوين IP: يتم جمع عناوين IP الخاصة بجهات الاتصال الخاصة بك كجزء من الخدمات (على سبيل المثال لغرض تسجيل الموقع على مستوى البلد وعناوين IP المستخدمة عند إرسال نموذج وتقديم الموافقة).",
    "privacy_policy_info_about_contacts_title": "المعلومات التي نجمعها عن جهات الاتصال الخاصة بك",
    "privacy_policy_info_about_contacts_notice": "*ينطبق هذا القسم فقط على المعلومات التي تم جمعها أو استخدامها أو الكشف عنها بواسطة Menuonline حول جهات اتصال صاحب الحساب لأغراض تقديم الخدمات*",
    "privacy_policy_info_about_contacts_desc": "لن تقوم Menuonline بجمع أو استخدام أو الكشف عن المعلومات الشخصية الخاصة بجهات الاتصال إلا إذا قام صاحب الحساب بالتسجيل في خدمات معينة حيث يقدم لنا قائمة توزيع. يتحمل أصحاب الحسابات وحدهم المسؤولية عن ضمان امتثالهم لجميع القوانين المعمول بها عند تقديم معلومات شخصية عن جهات الاتصال الخاصة بهم إلى Menuonline من خلال الخدمات.",
    "privacy_policy_info_auto_collect_title": "يقوم موقع Menuonline بجمع المعلومات تلقائيًا",
    "privacy_policy_info_auto_collect_desc1": "عندما يتفاعل جهات اتصال صاحب الحساب مع حملة بريد إلكتروني مستلمة من صاحب الحساب، أو يتفاعل مع رابط لموقع ويب مرتبط بصاحب الحساب، قد تجمع Menuonline معلومات حول جهاز جهة الاتصال هذه وتفاعلها مع البريد الإلكتروني من خلال استخدام ملفات تعريف الارتباط. يتم جمع هذه المعلومات فقط لتقديم الخدمات وتزويد صاحب الحساب بالمعلومات.",
    "privacy_policy_info_auto_collect_desc2": "قد تتضمن المعلومات التي نجمعها عن جهاز جهة الاتصال والتطبيقات التي تستخدمها جهة الاتصال للوصول إلى رسائل البريد الإلكتروني المرسلة من صاحب الحساب، من خلال خدماتنا، عنوان IP الخاص بجهة الاتصال، ونظام التشغيل، ومعرف المتصفح، وغيرها من المعلومات ذات الصلة بنظام جهة الاتصال واتصالها.",
    "privacy_policy_info_auto_collect_desc3": "قد تتضمن المعلومات التي نجمعها حول تفاعل Contact مع رسائل البريد الإلكتروني المرسلة من خلال الخدمات التواريخ والأوقات التي يصل فيها Contact إلى البريد الإلكتروني وأنشطة التصفح، والمعلومات المتعلقة بأداء الخدمات، مثل إمكانية توصيل رسائل البريد الإلكتروني والاتصالات الأخرى المرسلة إلى Contact من قبل صاحب الحساب من خلال الخدمات.",
    "privacy_policy_info_auto_collect_desc4": "يرجى ملاحظة أن Menuonline لن يتصل بأي من جهات الاتصال الخاصة بك، ما لم يكن ذلك مطلوبًا بموجب القانون المعمول به.",
    "privacy_policy_why_we_collect_title": "لماذا نجمع معلوماتك؟",
    "privacy_policy_why_we_collect_desc": "تقوم Menuonline بجمع المعلومات للأغراض التالية:",
    "privacy_policy_why_collect_1": "لتزويد أصحاب حسابات Menuonline بالخدمات التي اشتركوا فيها، بما في ذلك استخدام معلومات صاحب الحساب للخدمات؛",
    "privacy_policy_why_collect_2": "للإدارة وإدارة الحساب، بالإضافة إلى الوصول إلى موقع mycircle الإلكتروني؛",
    "privacy_policy_why_collect_3": "إذا وافقت على تلقي المواد التسويقية والترويجية، لإعلامك بالمنتجات والخدمات الأخرى التي تقدمها Menuonline؛ و",
    "privacy_policy_why_collect_4": "للرد على اتصالات صاحب الحساب أو لإرسال المعلومات التي تطلبها. قد يشمل هذا، على سبيل المثال لا الحصر:",
    "privacy_policy_why_collect_5": "العروض الخاصة والتحديثات؛",
    "privacy_policy_why_collect_6": "النشرة الإخبارية؛ و",
    "privacy_policy_why_collect_7": "إعلانات الخدمة.",
    "privacy_policy_opt_out_desc": "يجوز لك، في أي وقت، اختيار إلغاء الاشتراك في تلقي الاتصالات من خلال النقر على زر إلغاء الاشتراك في أسفل كل بريد إلكتروني يتم إرساله إليك من Menuonline.",
    "privacy_policy_use_disclosure_title": "استخدام المعلومات الشخصية والإفصاح عنها",
    "privacy_policy_use_disclosure_desc1": "يتم الاحتفاظ بالمعلومات الشخصية المقدمة إلى Menuonline، بما في ذلك من خلال موقع Menuonline الإلكتروني أو نموذج محدد، بسرية تامة ما لم يتم الكشف عنها وفقًا لموافقة صريحة من صاحب الحساب أو المتطلبات القانونية. في أي حال من الأحوال، فإن أي معلومات شخصية يتم تقديمها على أي منصة \"Menuonline\" من قبل أصحاب الحسابات هي طوعية: يتم تقديم أي معلومات شخصية يقدمها صاحب الحساب وفقًا لتقدير صاحب الحساب. يرجى ملاحظة أن إلغاء موافقتك قد يؤدي إلى عدم القدرة على الوصول إلى خدمات Menuonline معينة أو الاستفادة منها.",
    "privacy_policy_use_disclosure_desc2": "تستعين Menuonline بأطراف ثالثة ومقدمي خدمات خارجيين فيما يتعلق بموقعها الإلكتروني وخدماتها. وقد تتم مشاركة المعلومات الشخصية لصاحب الحساب مع هذه الأطراف الثالثة ومقدمي الخدمات. كما يجوز لنا تقديم إحصائيات مجمعة حول أصحاب الحسابات والمبيعات وأنماط الزيارات والمعلومات ذات الصلة لأطراف ثالثة ذات سمعة طيبة. وتشترط Menuonline أن يكون أي مقدم خدمة خارجي لديه حق الوصول إلى المعلومات الشخصية ملزمًا بالالتزامات المتوافقة مع سياسة الخصوصية هذه وأن يقوم هؤلاء المقدمون بجمع أو استخدام أو الكشف عن المعلومات الشخصية لصاحب الحساب لأغراض تقديم خدمات Menuonline فقط.",
    "privacy_policy_use_disclosure_desc3": "لن يتم بيع أو مشاركة أو تأجير المعلومات الشخصية المقدمة من أصحاب الحسابات إلى منظمات خارجية إلا وفقًا لموافقتك الضمنية أو الصريحة. تحتفظ Menuonline بالحق في الاتصال بأي من أصحاب الحسابات من أجل إرسال المعلومات أو الرد على الأسئلة أو تقديم إشعار بأي تغييرات تطرأ على برنامجنا أو سياساتنا. يرجى ملاحظة أن Menuonline لن تتواصل مع أي من جهات الاتصال الخاصة بك، ما لم يكن ذلك مطلوبًا بموجب القانون المعمول به.",
    "privacy_policy_use_disclosure_desc4": "لن تكشف Menuonline عن المعلومات الشخصية إلا إذا تم منح الموافقة من قبل الفرد المعني أو:",
    "privacy_policy_disclosure_list_1": "عندما يسمح القانون بذلك أو يقتضيه، مثل الاستجابة لاستدعاء أو أي عملية قانونية أخرى؛",
    "privacy_policy_disclosure_list_2": "إلى المحول إليه كليًا أو جزئيًا من Menuonline؛",
    "privacy_policy_disclosure_list_3": "إلى مقدمي خدمات Menuonline وفقًا لهذه السياسة؛ أو",
    "privacy_policy_disclosure_list_4": "للوفاء بالالتزامات القانونية، بما في ذلك على سبيل المثال لا الحصر التزامات إعداد التقارير التنظيمية.",
    "privacy_policy_service_providers_title": "تستخدم Menuonline الأطراف الثالثة ومقدمي الخدمات التاليين (لا يتم الكشف عن المعلومات الشخصية لجهات الاتصال الخاصة بك إلى \"هذه\" الأطراف الثالثة):",
    "privacy_policy_service_provider_1": "نحن نستخدم ManyChat لتوفير خدمات الدردشة على الموقع الإلكتروني للمبيعات والدعم، بالإضافة إلى رسائل البريد الإلكتروني المتابعة حول هذه المحادثات.",
    "privacy_policy_service_provider_2": "نحن نستخدم AskNicely لاستطلاع آراء عملائنا بغرض الاستفسار عن جودة الخدمة والدعم الذي يتلقونه.",
    "privacy_policy_service_provider_3": "نحن نستخدم join.me لجدولة اجتماعات العملاء والعملاء المحتملين، ومكالمات الدعم، وعروض البرامج.",
    "privacy_policy_service_provider_4": "نحن نستخدم Google Analytics لتتبع نشاط موقع الويب المجهول وقياس أنماط الاستخدام على موقع الويب الخاص بنا.",
    "privacy_policy_breach_notification_title": "إشعار الخرق",
    "privacy_policy_breach_notification_desc": "يجب على Menuonline الامتثال للقوانين السارية وتزويد أصحاب الحسابات بالمساعدة المعقولة فيما يتعلق بأي استخدام أو وصول أو الكشف غير المصرح به عن المعلومات الشخصية.",
    "privacy_policy_rights_access_title": "حقوقك وإمكانية الوصول إليك",
    "privacy_policy_rights_access_desc": "لديك الحق في الوصول إلى المعلومات الشخصية التي تعالجها Menuonline عنك. يمكنك أن تطلب منا معلومات حول:",
    "privacy_policy_rights_access_list_1": "المعلومات الشخصية التي نحتفظ بها عنك",
    "privacy_policy_rights_access_list_2": "فئات المعلومات الشخصية المعنية",
    "privacy_policy_rights_access_list_3": "أغراض المعالجة",
    "privacy_policy_rights_access_list_4": "التفاصيل حول الأشخاص الذين تم الكشف عن معلوماتك الشخصية لهم\/سيتم الكشف عنها",
    "privacy_policy_rights_access_list_5": "ما هي المدة التي نحتفظ فيها بمعلوماتك الشخصية؟",
    "privacy_policy_rights_access_list_6": "إذا لم نجمع المعلومات منك بشكل مباشر، فلن نتمكن من جمع المعلومات حول المصدر.",
    "privacy_policy_rights_access_list_7": "كيفية تقديم شكوى إلى السلطة الإشرافية الصحيحة",
    "privacy_policy_rights_access_list_8": "ويمكنك أيضًا أن تطلب منا ما يلي:",
    "privacy_policy_rights_access_list_9": "أن نقوم بتحديث أي معلومات غير كاملة أو غير دقيقة عنك",
    "privacy_policy_rights_access_list_10": "اطلب منا حذف معلوماتك الشخصية وفقًا للقوانين المعمول بها",
    "privacy_policy_rights_access_contact": "يمكنك طلب اتخاذ إجراءات بشأن حقوقك عن طريق الاتصال بنا على 2851 Dufferin Street، Toronto، ON، Canada، أو عن طريق مراسلتنا عبر البريد الإلكتروني على {link}.",
    "privacy_policy_accountability_title": "المساءلة",
    "privacy_policy_accountability_desc": "تلتزم Menuonline بتحمل المسؤولية عن المعلومات الشخصية والسرية التي تقدمها لنا. للحصول على معلومات الاتصال، يرجى الاطلاع على نهاية هذه السياسة.",
    "privacy_policy_links_other_sites_title": "روابط لمواقع أخرى",
    "privacy_policy_links_other_sites_desc1": "قد يحتوي موقع Menuonline على روابط لمواقع أخرى. يرجى العلم بأن Menuonline ليست مسؤولة عن ممارسات الخصوصية للمواقع الأخرى. نشجع أصحاب الحسابات لدينا على الانتباه إلى أنه عند مغادرتهم لموقعنا، يجب عليهم قراءة بيانات سياسة الخصوصية الخاصة بكل موقع يجمع معلومات تعريف شخصية بعناية.",
    "privacy_policy_links_other_sites_desc2": "تنطبق سياسة الخصوصية هذه فقط على المعلومات التي نجمعها.",
    "privacy_policy_links_other_sites_desc3": "إذا كنت تستخدم رابطًا للانتقال من موقع Menuonline لشراء المنتجات أو الخدمات، فأنت تكمل معاملة الشراء وفقًا لقواعد وأنظمة شركة البائع، وليس Menuonline.",
    "privacy_policy_cookies_title": "سياسة الخصوصية لملفات تعريف الارتباط",
    "privacy_policy_cookies_desc1": "يستخدم موقع Menuonline وخدماته ملفات تعريف الارتباط لجمع وتخزين معلومات معينة وفقًا لهذه السياسة. ملف تعريف الارتباط هو ملف نصي صغير يتم إرساله من موقع ويب وتخزينه على جهاز الكمبيوتر الخاص بالمستخدم بواسطة متصفح الويب الخاص بالمستخدم. عند زيارة موقع يستخدم ملفات تعريف الارتباط، يتم تنزيل ملف تعريف ارتباط على جهاز الكمبيوتر أو الجهاز المحمول الخاص بك. في المرة التالية التي تزور فيها هذا الموقع، سيتذكر جهازك معلومات مفيدة مثل التفضيلات أو الصفحات التي تمت زيارتها أو خيارات تسجيل الدخول.",
    "privacy_policy_cookies_desc2": "تُستخدم ملفات تعريف الارتباط على نطاق واسع لإضافة وظائف إلى مواقع الويب، أو لضمان عملها بكفاءة أكبر. يعتمد موقعنا على ملفات تعريف الارتباط لتحسين تجربة المستخدم وضمان عمل خدمات المواقع بشكل صحيح.",
    "privacy_policy_cookies_desc3": "تسمح معظم متصفحات الويب ببعض التحكم لتقييد أو حظر ملفات تعريف الارتباط من خلال إعدادات المتصفح، ومع ذلك، إذا قمت بتعطيل ملفات تعريف الارتباط، فقد تجد أن هذا يؤثر على قدرتك على استخدام أجزاء معينة من موقعنا الإلكتروني أو خدماتنا.",
    "privacy_policy_cookies_analytical_purpose": "<strong> الأغراض التحليلية: <\/strong> نستخدم ملفات تعريف الارتباط لتحليل نشاط حاملي الحسابات بهدف تحسين موقعنا الإلكتروني. على سبيل المثال، يمكننا استخدام ملفات تعريف الارتباط للنظر في الأنماط المجمعة مثل الميزات التي يستخدمها حاملو الحسابات. يمكننا استخدام مثل هذا التحليل للحصول على رؤى حول كيفية تحسين وظائف وتجربة المستخدم لموقعنا الإلكتروني.",
    "privacy_policy_cookies_preferences_user_experience": "<strong> تفضيلاتك وتجربة المستخدم: <\/strong> نستخدم ملفات تعريف الارتباط لجمع معلومات معينة عن الزوار وأصحاب الحسابات، مثل نوع المتصفح والخادم وتفضيلات اللغة وإعدادات البلد، وذلك لتخزين تفضيلات أصحاب الحسابات على موقعنا الإلكتروني لجعل تجربة أصحاب الحسابات أكثر اتساقًا وراحة. نستخدم ملفات تعريف الارتباط للحفاظ على حالة تسجيل الدخول الخاصة بك عندما تزور موقعنا الإلكتروني بشكل متكرر.",
    "privacy_policy_cookies_targeted_ads": "<strong> توفير خدمات القياس وإعلانات وتسويق أفضل استهدافًا: <\/strong> نستخدم ملفات تعريف الارتباط ومنارات الويب وتقنيات التخزين الأخرى من شركاء تابعين لجهات خارجية مثل Google وFacebook لخدمات القياس وإعلانات أفضل استهدافًا ولأغراض التسويق. يحدث هذا عندما تزور موقعنا على الويب. تتيح لنا ملفات تعريف الارتباط ومنارات الويب وتقنيات التخزين الأخرى عرض المواد الترويجية الخاصة بـ Menuonline لك على المواقع الأخرى التي تزورها عبر الإنترنت.",
    "privacy_policy_security_title": "حماية",
    "privacy_policy_security_desc1": "تبذل Menuonline جهودًا لحماية المعلومات السرية من جميع الأنواع، بما في ذلك المعلومات الشخصية، من خلال الضمانات التنظيمية والمادية والميكانيكية والإلكترونية المناسبة لحساسية المعلومات. تقع خوادمنا في كندا، وتتبع معايير الصناعة المتعلقة بالأمان.",
    "privacy_policy_security_desc2": "ومع ذلك، يرجى ملاحظة أننا لا نستطيع ضمان أن التدابير التي نتخذها ستضمن أمن المعلومات.",
    "privacy_policy_retention_title": "حفظ",
    "privacy_policy_retention_desc": "نحن نحتفظ بالمعلومات الشخصية الخاصة بأصحاب الحسابات وجهات الاتصال طالما كان ذلك ضروريًا للأغراض المنصوص عليها في سياسة الخصوصية هذه ووفقًا لأحكام الاحتفاظ الواردة في شروط الاستخدام.",
    "privacy_policy_access_questions_title": "الوصول والأسئلة والمخاوف",
    "privacy_policy_access_questions_desc": "يجوز للشخص الذي قدم معلومات شخصية إلى Menuonline تقديم طلب كتابي للوصول إلى معلوماته الشخصية التي يحتفظ بها Menuonline أو تصحيحها.",
    "terms_obj": {
        "terms_conditions_title": "الشروط والأحكام",
        "terms_conditions_subtitle": "الشروط والأحكام للعملاء الجدد والحاليين",
        "terms_conditions_desc1": "النقاط الرئيسية التالية (بما في ذلك مراجع الأقسام) مقدمة لراحتك فقط ولا تحل محل الشروط والأحكام الكاملة. تقع على عاتقك مسؤولية قراءة هذه الشروط والأحكام قبل استخدام خدمة Menuonline.",
        "terms_conditions_desc2": "إن استخدام Menuonline يعني موافقتك على الشروط والأحكام وتؤكد وتضمن أنك مفوض من قبل مؤسستك أو شركتك للقيام بذلك. (راجع القسم 3 \"كيفية العمل\")",
        "terms_conditions_desc3": "Menuonline هي خدمة تسويقية تعتمد على الإذن. سيؤدي الإرسال المتعمد لرسائل \"البريد العشوائي\" أو انتهاك الشروط والأحكام إلى إغلاق حسابك. (انظر القسم 16 \"الاستخدام المقبول للخدمة\")",
        "terms_conditions_desc4": "البيانات التي تستضيفها في Menuonline مثل سجلات الاتصال ومحتوى البريد الإلكتروني والمعلومات التي تجمعها من خلال Menuonline هي ملك لك. (راجع القسم 14 \"ما هي ممتلكاتك؟\").",
        "terms_conditions_desc5": "برنامج Menuonline والمحتوى الموجود على موقعنا الإلكتروني مملوك لنا. (راجع القسم 13 \"ما هي ممتلكاتنا؟\")",
        "terms_conditions_desc6": "باستثناء المستخدمين الخاصين بك، لا يمكن عرض البيانات أو المحتوى غير المنشور الذي تستضيفه مع Menuonline إلا من قبل موظفي Menuonline وشركائها الذين وقعوا اتفاقيات سرية. (راجع القسم 14 \"ما هي ممتلكاتك؟\").",
        "terms_conditions_desc7": "يتم الاحتفاظ بجميع المعلومات الشخصية القابلة للتحديد (PII) فقط على الخوادم الموجودة في كندا. (راجع القسم 14 \"ما هي ممتلكاتك؟\").",
        "terms_conditions_desc8": "تخضع خدمة Menuonline لقوانين كندا ومقاطعة أونتاريو. (راجع المادة 24 \"القانون الحاكم\")",
        "terms_conditions_desc9": "سيتم دائمًا إحالة أي طلبات للحصول على بياناتك أو محتواك من قبل الأفراد أو الشرطة أو الهيئات التنظيمية الأخرى من داخل كندا أو خارجها إليك. الاستثناء الوحيد هو إذا كان هذا يجعلنا ننتهك القانون الكندي أو قانون أونتاريو؛ ومع ذلك، في جميع الحالات سنقاوم بشدة أي إصدار لبياناتك من قبل أي شخص غيرك. (انظر القسم 14 \"ما هي ممتلكاتك؟\").",
        "terms_conditions_desc10": "إن أمان Menuonline يعتمد فقط على قدرتك على إدارة أسماء المستخدمين وكلمات المرور. لا نفرض رسومًا على حسابات مستخدمي Menuonline، لذا قم بإنشاء حساب منفصل لكل مستخدم واحتفظ بكلمات المرور سرية. أنت مسؤول عن حسابك. (راجع القسم 3 \"كيفية العمل\")",
        "terms_conditions_desc11": "يمكنك إلغاء حسابك في أي وقت. لا نقدم لك استرداد الأموال على أي رصيد غير مستخدم إذا قمت بالإلغاء. (راجع القسم 3 \"كيفية العمل\")",
        "terms_conditions_desc12": "يمكننا إلغاء حسابك في أي وقت. نحن نقدم لك استرداد الأموال مقابل أرصدة البريد الإلكتروني والخدمات غير المستخدمة إذا قمنا بإلغاء حسابك. (راجع القسم 3 \"كيفية العمل\")",
        "terms_conditions_desc13": "سيتم حذف بياناتك بعد الإلغاء وقد يتم حذفها أيضًا إذا لم يسجل مستخدم الحساب الدخول لمدة 12 شهرًا أو أكثر. سنحتفظ بالبيانات المجمعة من حسابك والتي لا تتضمن معلومات تعريف شخصية لأغراض إحصائية. (انظر القسم 3 \"كيفية العمل\")",
        "terms_conditions_desc14": "يتم شراء رصيد البريد الإلكتروني على أساس الدفع حسب الاستخدام ولا تنتهي صلاحيته أبدًا. الاستثناء الوحيد هو إذا لم تقم بتسجيل الدخول إلى حسابك لمدة 12 شهرًا. في هذه الحالة، نحتفظ بالحق في حذف حسابك وبياناتك والرصيد المرتبط به. (انظر القسم 10 \"ما المبلغ الذي أدفعه مقابل الخدمة؟\")",
        "terms_conditions_desc15": "نحن نعمل بجد لضمان تشغيل Menuonline في جميع الأوقات وتقديم إشعار مسبق إذا احتجنا إلى تعليق الخدمة مؤقتًا للصيانة. ومع ذلك، لا نضمن أن الخدمة ستكون جاهزة للعمل في جميع الأوقات ولا نتحمل المسؤولية عن أي خسائر تتكبدها نتيجة لاستخدام Menuonline، بما في ذلك الخسائر التي تتكبدها إذا لم يكن النظام يعمل في الوقت الذي تحتاج إليه. (انظر المادة 17 \"إخلاء المسؤولية\").",
        "terms_conditions_desc16": "لا نراقب المحتوى الخاص بك أو الأشخاص الذين ترسل إليهم الرسائل. ومع ذلك، فإننا نراقب الشكاوى والمشكلات الأخرى التي تنشأ عن أنشطتك. قد يؤدي كثرة الشكاوى أو المشكلات إلى إلغاء الخدمة حسب تقديرنا. (انظر القسم 7 \"المراقبة\")",
        "terms_conditions_desc17": "نحن ملتزمون بتقديم دعم ممتاز للمنتج لمساعدتك على تحقيق الاكتفاء الذاتي باستخدام Menuonline. نحن نقدم الدعم في شكل وثائق ومقاطع فيديو على موقعنا الإلكتروني ونظام تذاكر دعم Menuonline وعن طريق الهاتف. لا توجد رسوم مقابل دعم المنتج؛ ومع ذلك، نحتفظ بالحق في إيقاف الدعم لحسابات عملاء محددة وفقًا لتقديرنا. (انظر القسم 5 \"الدعم\")",
        "terms_conditions_desc18": "خدمات المنتج مخصصة لتلك الأوقات التي تريد فيها منا القيام ببعض الأعمال المتعلقة بـ Menuonline بدلاً من القيام بها بنفسك. سعر خدمات المنتج لدينا هو 135 دولارًا في الساعة. كما نقدم خدمات استشارية بأسعار أعلى. نحتفظ بالحق في تغيير أسعارنا في أي وقت. (انظر القسم 6 \"خدمات المنتج والاستشارات\")",
        "terms_conditions_desc19": "سنبلغك إذا اعتقدنا أن هناك انتهاكًا لبياناتك وسنبذل قصارى جهدنا لتزويدك بالتفاصيل والمعلومات ذات الصلة. (راجع القسم 14 \"ما هي ممتلكاتك؟\")",
        "terms_conditions_desc20": "نحتفظ بالحق في إجراء تغييرات على هذه الاتفاقية في أي وقت. وسنحاول إبلاغك بالتغييرات التي تطرأ على الشروط والأحكام، ولكن استخدام الخدمة يعني موافقتك على التغييرات التي تطرأ على الشروط. (انظر القسم 9 \"التغييرات\").",
        "terms_conditions_desc21": "لقد تمت صياغة الشروط والأحكام وسياسة الخصوصية الخاصة بنا باللغة الإنجليزية فقط، وتوافق على أن النسخة الإنجليزية من الشروط والأحكام وسياسة الخصوصية ستكون لها الأولوية في حالة وجود أي تعارض مع أي نسخة مترجمة.",
        "terms_conditions_mycircle": "شروط وأحكام ماي سيركل",
        "terms_conditions_last_update": "آخر تحديث: 14 يناير 2020",
        "terms_conditions_usage_agreement": "يرجى قراءة شروط وأحكام الخدمة هذه (المشار إليها فيما يلي باسم \"الشروط\") بعناية قبل استخدام هذا الموقع الإلكتروني (المشار إليه فيما يلي باسم \"الموقع\"). تعفي هذه الشروط Menuonline (المشار إليها فيما يلي باسم \"Menuonline\" أو \"نحن\") وغيرها من الشركات من المسؤولية و\/أو تحد من مسؤوليتنا ومسؤوليتهم، كما تحتوي على أحكام مهمة أخرى تنطبق على استخدامك لهذا الموقع.",
        "terms_conditions_acceptance": "من خلال زيارة أو استخدام هذا الموقع أو الخدمة، فإنك توافق بالنيابة عن نفسك، وبالنيابة عن أي منظمة قد تتصرف نيابة عنها (يشار إليها مجتمعة هنا باسم \"أنت\")، على قبول هذه الشروط والالتزام بها لكل استخدام وكل زيارة لهذا الموقع.",
        "terms_conditions_services": "1. خدماتنا.",
        "terms_conditions_services_desc": "الخدمات التي نقدمها من خلال الموقع هي خدمة تسمح لك بإنشاء رسائل البريد الإلكتروني وإرسالها وإدارتها (رسائل البريد الإلكتروني) إلى المستلمين الذين تختارهم (الخدمة).",
        "terms_conditions_license": "2. الترخيص.",
        "terms_conditions_license_desc": "بموجب هذه الشروط، تمنحك Menuonline بموجب هذا ترخيصًا غير حصري ومحدود وغير قابل للتحويل أثناء مدة الاستخدام لعرض الموقع واستخدام الخدمة وفقًا لما تسمح به ميزات الخدمة. نحتفظ بجميع الحقوق غير الممنوحة صراحةً بموجب هذه الشروط في الخدمة ومحتوى Menuonline (كما هو موضح أدناه).",
        "terms_conditions_account": "3. كيف يعمل.",
        "terms_conditions_account_desc": "لاستخدام الخدمة، يتعين عليك التسجيل للحصول على حساب. حسابك مجاني. يمنحك حسابك إمكانية الوصول إلى الخدمة و\"الوظائف\" التي قد ننشئها ونحافظ عليها من وقت لآخر. لكي تكون مؤهلاً للحصول على حساب، يجب عليك:",
        "terms_conditions_account_requirements": [
            "أن يكون عمره ثمانية عشر (18) عامًا على الأقل وقادرًا على إبرام العقود؛",
            "اكمال عملية التسجيل التي وضعناها؛",
            "الموافقة على هذه الشروط والأحكام؛",
            "توفير معلومات الاتصال والفواتير\/الدفع الصحيحة والكاملة والمحدثة."
        ],
        "responsibility": "مسؤولية.",
        "responsibilityText": "أنت المسؤول الوحيد عن النشاط الذي يحدث في حسابك، ويجب عليك الحفاظ على سرية كلمة مرور حسابك وأمانها. الحسابات مجانية، لذا نشجعك على إنشاء حساب مختلف لكل مستخدم لديك. أنت أيضًا مسؤول عن أي حساب يمكنك الوصول إليه، سواء كنت قد سمحت باستخدام هذا الحساب أم لا. أنت توافق على أنك المسؤول الوحيد عن تفاعلاتك مع الأشخاص الذين ترسل إليهم رسائل البريد الإلكتروني، ولن ترسل رسائل البريد الإلكتروني إلا إلى الأشخاص الذين حصلت على موافقتهم. يجب عليك إخطارنا فورًا بأي استخدام غير مصرح به لحساباتك.",
        "compliance_with_CASL": "الامتثال للتشريعات الكندية لمكافحة البريد العشوائي (CASL).",
        "compliance_with_CASL_text": "يتمثل دور Menuonline في تسهيل إدارة إعدادات الموافقة، وليس فرض التشريعات. تقع على عاتقك مسؤولية الامتثال لقانون البريد العشوائي الكندي. نساعدك من خلال تعيين الوظائف الافتراضية وتسهيل الحصول على الموافقة الصريحة الموثقة وتحديثها وإدارة تواريخ انتهاء الصلاحية المتجددة للموافقة الضمنية. أنت تتحكم بشكل كامل في إدارة الموافقة لقاعدة البيانات الخاصة بك؛ يمكنك تحديث حالة الموافقة لجميع جهات الاتصال الخاصة بك بشكل مجمع في أي وقت، وتجاوز الإعدادات الافتراضية وتعطيل انتهاء الصلاحية التلقائي لحالة الموافقة الضمنية.",
        "closing_account": "إغلاق حسابك.",
        "closing_account_text": "يجوز لك أو لـ Menuonline إنهاء هذه الاتفاقية في أي وقت ولأي سبب من الأسباب عن طريق إخطار الطرف الآخر. إذا قدمت أي معلومات غير صحيحة أو غير دقيقة أو غير محدثة أو غير كاملة أو استخدمت الخدمة بطريقة تنتهك هذه الشروط والأحكام، فلدينا الحق في تعليق حسابك على الفور وإنهاء وصولك إلى الخدمة بالإضافة إلى أي حلول أخرى متاحة لنا. إذا لم تستخدم حسابك لمدة اثني عشر (12) شهرًا، فقد نقوم بإلغاء حسابك. إذا قمنا بإنهاء حسابك لأي سبب من الأسباب، فسنعيد إليك أي رصيد متبقي لديك. بمجرد إنهاء الحساب، سنقوم بحذف حسابك بشكل دائم وجميع البيانات المرتبطة به، بما في ذلك رسائل البريد الإلكتروني التي أرسلتها. يجوز لنا الاحتفاظ ببيانات مجمعة من حسابك لا تتضمن معلومات تعريف شخصية لأغراض إحصائية.",
        "emailing_content": "إرسال المحتوى عبر البريد الإلكتروني.",
        "emailing_content_text": "عند التفاعل مع Menuonline عبر هذا الموقع أو الخدمة، فإنك توافق على تقديم معلومات صحيحة ودقيقة. علاوة على ذلك، فإنك تفهم أن تقديم معلومات سرية عبر البريد الإلكتروني يعد ممارسة غير آمنة. كما أنك تفهم أنك المسؤول الوحيد عن أي شيء ترسله إلى أي شخص يستخدم خدمتنا.",
        "support": "يدعم.",
        "support_text": "نحن نقدم لك الدعم لمساعدتك على الاعتماد على نفسك في استخدام الخدمة. نقدم لك الدعم في شكل وثائق ومقاطع فيديو على الموقع ونظام تذاكر الدعم Menuonline وعن طريق الهاتف. يمكنك التواصل معنا بالنقر فوق علامة التبويب \"الدعم\" أعلى الموقع. لا توجد رسوم مقابل دعم المنتج؛ ومع ذلك، نحتفظ بالحق في إيقاف الدعم لحسابات عملاء محددة وفقًا لتقديرنا.",
        "product_and_consulting_services": "خدمات المنتجات والاستشارات.",
        "product_and_consulting_services_text": "خدمات المنتج مخصصة لتلك الأوقات التي تريد فيها منا القيام ببعض الأعمال المتعلقة بـ Menuonline بدلاً من القيام بها بنفسك. سعر خدمات المنتج لدينا هو 125 دولارًا في الساعة. كما نقدم خدمات استشارية بأسعار أعلى. نحتفظ بالحق في تغيير أسعارنا في أي وقت.",
        "monitoring": "يراقب.",
        "monitoring_text": "لن نقوم بمراقبة أو الحكم على محتوى المعلومات المنقولة عبر الخدمة، ولكننا سنحقق في حال تلقينا شكاوى حول الاستخدام غير المناسب المحتمل. يجوز لـ Menuonline، في أي وقت ووفقًا لتقديرها الخاص، اتخاذ قرارات بشأن ما إذا كانت استخدامات معينة مناسبة أم لا مع إخطارك أو بدون إخطارك، وفقًا للإرشادات التالية.",
        "term": "شرط.",
        "term_text": "تبدأ المدة عند تسجيلك حسابًا لدينا وتستمر طالما تستخدم الخدمة. أنت تقر وتضمن أنك تتمتع بالسلطة والقدرة على التسجيل للحصول على حساب لنا (بما في ذلك إذا قمت بالتسجيل نيابة عن مؤسسة).",
        "changes": "التغييرات.",
        "changes_text": "تبدأ المدة عند تسجيلك حسابًا لدينا وتستمر طالما تستخدم الخدمة. أنت تقر وتضمن أنك تتمتع بالسلطة والقدرة على التسجيل للحصول على حساب لنا (بما في ذلك إذا قمت بالتسجيل نيابة عن مؤسسة).",
        "payment": "قسط",
        "what_do_i_pay_for_service": "ما هو المبلغ الذي أدفعه مقابل الخدمة؟",
        "what_do_i_pay_for_service_text": "يجوز لك شراء رصيد مدفوع مسبقًا لإرسال البريد الإلكتروني (\"الرصيد\") من خلال خدمتنا، كما نوضح بالتفصيل في صفحة \"التسعير\" على موقعنا. لا تنتهي صلاحية الرصيد ولا توجد رسوم متكررة لهذه الرصيد. الاستثناء الوحيد هو أنه إذا لم تستخدم حسابك لمدة اثني عشر (12) شهرًا، فقد نقوم بإلغاء حسابك وأي رصيد غير مستخدم. يجوز لنا تغيير تسعيرنا في أي وقت عن طريق تحديث صفحات التسعير على موقعنا، والتي ستطبق على عملية الشراء التالية للرصيد.",
        "how_do_i_pay_for_service": "كيف أدفع ثمن الخدمة؟",
        "how_do_i_pay_for_service_text": "يمكنك دفع ثمن الائتمانات باستخدام أي بطاقة ائتمان رئيسية. يجوز لنا تغيير طرق الدفع التي نقبلها في أي وقت.",
        "can_i_get_refund": "هل يمكنني الحصول على استرداد المبلغ؟",
        "can_i_get_refund_text": "المبالغ التي دفعتها مقابل استخدام الخدمة غير قابلة للاسترداد إذا قمت بإلغاء حسابك. إذا قمنا بإلغاء حسابك، فسوف نقوم برد أي رصيد متبقي لديك.",
        "rights": "حقوق",
        "what_do_we_own": "ماذا نملك؟",
        "what_do_we_own_text": "باستثناء المحتوى الذي تقدمه لنا (بما في ذلك على سبيل المثال لا الحصر سجلات الاتصال ومحتوى البريد الإلكتروني والمعلومات التي تجمعها من خلال الخدمة) (\"المحتوى الخاص بك\")، فإن جميع المواد المضمنة في موقعنا والخدمة وجميع المواد الموجودة فيها أو المنقولة بموجبها، بما في ذلك على سبيل المثال لا الحصر، البرامج والصور والنصوص والرسومات والرسوم التوضيحية والشعارات وبراءات الاختراع والعلامات التجارية وعلامات الخدمة والصور الفوتوغرافية والصوت والفيديو والموسيقى والمحتوى الذي ينتمي إلى مستخدمين آخرين (\"محتوى Menuonline\") (وكل حقوق الملكية الفكرية المتعلقة بها) مملوكة لنا أو لمرخصينا.",
        "what_do_you_own": "ماذا تملك؟",
        "what_do_you_own_text": "لا ندعي أي حقوق ملكية على المحتوى الخاص بك. المحتوى الخاص بك هو ملكك وأنت تحتفظ بملكية المحتوى الخاص بك الذي تقوم بتحميله إلى الخدمة. من خلال مشاركة المحتوى الخاص بك من خلال الخدمة، فإنك توافق على السماح للآخرين بعرض وتحرير و\/أو مشاركة المحتوى الخاص بك وفقًا لإعداداتك وهذه الشروط والأحكام. أنت تقر وتضمن أنك تمتلك أو لديك إذن لاستخدام جميع المواد التي تستخدمها في رسائل البريد الإلكتروني الخاصة بك. يجوز لنا استخدام أو الكشف عن المواد الخاصة بك فقط كما هو موضح في هذه الشروط وسياسة الخصوصية الخاصة بنا.",
        "privacy_statement": "تحترم Menuonline خصوصية مستخدميها. تخضع أي معلومات شخصية تجمعها Menuonline عبر هذا الموقع أو الخدمة لبيان الخصوصية الخاص بـ Menuonline والذي تم تضمينه بالإشارة إلى هذه الشروط. يجوز لنا استخدام معلوماتك والإفصاح عنها وفقًا لبيان الخصوصية الخاص بنا.",
        "access_to_your_content": "لن توفر Menuonline إمكانية الوصول إلى المحتوى الخاص بك لأي شخص باستثناء الموظفين والمقاولين لدى Menuonline الذين وقعوا اتفاقيات سرية مناسبة معنا أو حصلنا على موافقتك.",
        "governmentRequest": "إذا تلقينا طلبًا من جهة حكومية أو جهة تنظيمية للوصول إلى محتواك، فسوف نخطرك بالطلب ونسعى للحصول على موافقتك قبل إصدار أي من محتواك. وسوف نقاوم أي طلبات للحصول على محتواك ولن نصدر محتواك استجابة لهذا النوع من الطلبات دون إذنك أو مطالبة محكمة مختصة أو الهيئة التنظيمية المختصة بذلك.",
        "breachNotification": "في حالة علمنا بأن المحتوى الخاص بك قد تعرض للوصول غير المصرح به أو الاستخدام أو النسخ أو السرقة أو الفقدان أو الاستخدام أو الكشف عنه في انتهاك لهذه الشروط (يُطلق عليه \"الانتهاك\")، فسوف نخطرك على الفور ونعلمك بما حدث. أنت توافق على أن الانتهاكات سيتم التعامل معها وفقًا للقانون المعمول به، وإذا احتجنا إليك لإبلاغ أعضاء قوائم توزيع البريد الإلكتروني الخاصة بك، فإنك توافق على القيام بذلك على الفور وكما نوجهك.",
        "contentRetention": "سنحتفظ بمحتواك ونستخدمه وننقله في كندا ما لم نحصل على موافقتك الكتابية المسبقة أو توجيهك (على سبيل المثال، إذا اخترت إرسال محتواك إلى أفراد خارج كندا). تقع الخوادم المستخدمة لتقديم الخدمة في كندا.",
        "accessTrouble": "إذا كنت تواجه مشكلة في الوصول إلى المحتوى الخاص بك، فسوف تساعدك Menuonline في استرداد المحتوى الخاص بك على أساس الوقت والمواد.",
        "reportingAbuse": "إذا كنت تعتقد أن أي شخص ينتهك أيًا من هذه الشروط، فيرجى إخطارنا على الفور. إذا تلقيت رسائل غير مرغوب فيها تعتقد أنها جاءت من مستخدم آخر للخدمة، أو إذا كنت تعتقد أن مستخدمًا آخر للخدمة نشر مواد تنتهك أي حقوق طبع ونشر، أو إذا كنت تعتقد أن مستخدمًا آخر للخدمة ينتهك هذه الشروط بأي شكل آخر، فيرجى الاتصال بنا على Support@menuonline.com.",
        "acceptableUse": "أنت تقر وتضمن أن استخدامك للخدمة سيكون متوافقًا مع جميع القوانين واللوائح المعمول بها. وتوافق على أنك مسؤول عن تحديد ما إذا كانت الخدمة متوافقة مع ولايتك القضائية ومناسبة للاستخدام. لمزيد من اليقين، فإنك توافق على أنك (وتوافق على ضمان قيام موظفيك ووكلائك والمستخدمين المعتمدين لديك) ...",
        "exportLaws": "لا نقدم أي إقرار بأن الموقع أو الخدمة مناسبان أو متاحان للاستخدام خارج كندا، ويُحظر الوصول إليهما من المناطق التي يكون محتواهما فيها غير قانوني. لا يجوز لك استخدام أو تصدير أو إعادة تصدير محتوى Menuonline أو المواد الأخرى الموجودة في هذا الموقع أو أي نسخة أو تعديل يخالف أي قوانين أو لوائح سارية بما في ذلك على سبيل المثال لا الحصر قوانين ولوائح التصدير الكندية والأمريكية.",
        "disclaimers": "يتم توفير الخدمة وهذا الموقع \"كما هو\" دون أي تعهدات أو ضمانات أو شروط من أي نوع، وتخلي Menuonline صراحة، إلى الحد الأقصى المسموح به بموجب القوانين المعمول بها، عن جميع التعهدات والضمانات والشروط، الصريحة أو الضمنية، بموجب القانون أو غير ذلك، بما في ذلك على سبيل المثال لا الحصر أي ضمانات وشروط ضمنية للتسويق، أو الملاءمة لأي غرض أو استخدام معين، أو عدم انتهاك حقوق الطرف الثالث...",
        "limitLiability": "تقتصر مسؤولية Menuonline الإجمالية بموجب هذه الاتفاقية، سواء في العقد أو الضرر (بما في ذلك الإهمال)، نتيجة لخرق الضمان أو المسؤولية الصارمة أو بموجب أي نظرية أخرى للمسؤولية مهما كانت، على الأضرار المباشرة بمبلغ لا يتجاوز إجمالي الرسوم المدفوعة إلى Menuonline بموجب هذه الشروط والأحكام في الاثني عشر (12) شهرًا التي تسبق مباشرة الشهر الذي حدث فيه الحدث الأخير الذي أدى إلى المسؤولية.",
        "liabilityExclusions": "لن يتحمل أي من Menuonline أو الشركات التابعة لها أو مديريها أو مسؤوليها أو موظفيها أو وكلائها أو الممثلين الآخرين (يُشار إليهم إجمالاً باسم \"ممثلو Menuonline\") أو مشغلي أي شبكات مرتبطة أي مسؤولية أو التزام فيما يتعلق بهذا الموقع أو الخدمة عن: (أ) أي أضرار غير مباشرة أو تبعية أو عرضية أو نموذجية أو عقابية أو خاصة؛ أو (ب) عن أي أضرار، سواء كانت مباشرة أو غير مباشرة أو تبعية أو عرضية أو نموذجية أو عقابية أو خاصة، والتي توصف بأنها خسارة في الإيرادات أو خسارة في المدخرات أو الإيرادات أو خسارة في الأرباح...",
        "indemnity": "أنت توافق على تعويض وحماية Menuonline و\/أو ممثلي Menuonline (الأطراف المعفاة من المسؤولية في Menuonline) من أي مطالبات يقدمها أطراف ثالثة، وأي غرامات أو عقوبات يتم فرضها علينا، والتي تنشأ عن استخدامك للمعلومات التي تم الوصول إليها من هذا الموقع أو استخدامك للخدمة، وأي خرق لهذه الشروط.",
        "ipInfringement": "يجب على Menuonline الدفاع عنك وتعويضك وحمايتك من أي مطالبات أو طلبات أو إجراءات أو دعاوى أو إجراءات تم رفعها ضدك من قبل طرف ثالث يزعم أن وصولك إلى الخدمة أو استخدامك لها وفقًا لهذه الاتفاقية ينتهك حقوق الملكية الفكرية بموجب القانون الكندي لطرف ثالث ('مطالبة انتهاك الملكية الفكرية')...",
        "equitableRelief": "إذا انتهكت هذه الشروط، فيجوز لنا طلب الحصول على أمر قضائي (بمعنى أننا قد نطلب أمرًا قضائيًا بإيقافك) أو أي تعويض عادل آخر. أنت توافق على أن هذا سيكون بالإضافة إلى أي حقوق أخرى قد نتمتع بها بموجب القانون أو العدالة، ودون المساس بها.",
        "linksToOtherSites": "إذا قمنا بتوفير أي روابط لمواقع ويب تابعة لجهات خارجية، فإن هذه الروابط مقدمة للراحة فقط. إذا استخدمت هذه الروابط، فسوف تغادر موقعنا. نحن لا نتحكم في هذه المواقع التابعة لجهات خارجية ولا نؤيدها. أنت توافق على أننا لسنا مسؤولين عن أي من هذه المواقع التابعة لجهات خارجية أو محتواها...",
        "thirdPartyRequests": "إذا كان علينا تقديم معلومات استجابة لطلب قانوني أو حكومي يتعلق بحسابك أو استخدامك للخدمة، فإنك توافق على أنه يجوز لنا تحصيل تكاليفنا منك. وقد تتضمن هذه الرسوم أتعابنا القانونية وتكاليف الوقت الذي قضاه موظفونا في إعداد الرد على الطلب.",
        "assignment": "لا يجوز لك نقل أو التنازل عن هذه الاتفاقية، وأي حقوق أو تراخيص ممنوحة بموجبها، ولكن يجوز لـ Menuonline التنازل عنها دون قيود، بعد إخطارك مسبقًا بفترة معقولة.",
        "governingLaw": "تخضع هذه الشروط لقوانين أونتاريو وقوانين الحكومة الفيدرالية المعمول بها، وتفسر وفقًا لها دون مراعاة تعارضها مع أحكام القانون. أنت توافق على الخضوع للاختصاص الحصري لمحاكم أونتاريو لتحديد أي نزاع يتعلق بهذه الشروط.",
        "severability": "إذا وجدت محكمة مختصة، لأي سبب من الأسباب، أن أي حكم من أحكام هذه الشروط أو جزء منه غير قانوني أو غير قابل للتنفيذ لأي سبب من الأسباب، فسيتم تنفيذ هذا الحكم إلى أقصى حد مسموح به لإعطاء تأثير لهدف هذه الشروط، وستظل بقية هذه الشروط سارية المفعول بالكامل وقابلة للتنفيذ.",
        "waiver": "لن يكون أي تنازل عن متطلبات هذه الشروط أو الموافقة على الخروج عنها ساريًا إلا إذا كان مكتوبًا وموقعًا من قبلنا، وفي الحالة المحددة وللغرض المحدد الذي تم منحه من أجله فقط. لن يعمل فشلنا في ممارسة أي حق بموجب هذه الشروط أو تأخيرنا في ممارسته كتنازل عن حقوقنا.",
        "furtherAssurances": "يتفق كل طرف على التوقيع وتقديم جميع المستندات واتخاذ أي إجراءات (أو التسبب في القيام بهذه الإجراءات) اللازمة للوفاء بالتزاماته بموجب هذه الشروط والأحكام.",
        "notices": "يجب أن تكون جميع الإشعارات الموجهة إلى Menuonline مكتوبة ومرسلة إلينا عبر البريد الإلكتروني. ويجب إرسال الإشعارات الموجهة إلينا إلى ممثلي خدمة العملاء لدينا على support@menuonline.com. قد يتم إرسال الإشعارات إليك إلى العنوان أو عنوان البريد الإلكتروني الذي قدمته كجزء من تسجيلك. بالإضافة إلى ذلك، يجوز لنا بث إشعارات أو رسائل عبر هذا الموقع لإبلاغك بالتغييرات التي تطرأ على هذا الموقع أو أي أمور أخرى ذات أهمية، وستشكل هذه الإشعارات إشعارًا لك في وقت نشرها.",
        "entireAgreement": "تشكل هذه الشروط وبيان الخصوصية الخاص بنا الاتفاقية الكاملة بينك وبيننا فيما يتعلق باستخدامك للموقع والخدمة، وتحل محل جميع الاتفاقيات والتصريحات والتفاهمات السابقة بين Menuonline وبينك فيما يتعلق بهذا الموضوع. وفي حالة وجود أي تعارض بين بيان الخصوصية وهذه الشروط، ستحكم هذه الشروط والأحكام.",
        "language": "لقد طلبت الأطراف صراحةً واشترطت أن يتم صياغة هذه الشروط والأحكام، وسياسة الخصوصية الخاصة بنا، وأي مستندات ذات صلة باللغة الإنجليزية. ويعبر الأطراف بشكل ملائم وضروري عن أن هذا العقد وجميع المستندات التي يتم تقديمها مكتوبة باللغة الإنجليزية. أنت توافق على أن النسخة الإنجليزية من هذه الشروط وسياسة الخصوصية ستكون لها الأولوية في حالة وجود أي تعارض مع أي نسخة مترجمة.",
        "survival": "تظل جميع أحكام هذه الشروط، التي بطبيعتها أو معناها تهدف إلى البقاء بعد انتهاء أو إنهاء هذه الشروط، سارية، بما في ذلك، على سبيل المثال لا الحصر، البنود من 17 إلى 19."
    },
    "collect_additional_info": "هل ترغب في جمع معلومات إضافية؟",
    "additionalInfoChoice": "اختر أي معلومات إضافية ترغب في جمعها.",
    "additional_info_yes": "نعم",
    "additional_info_noSkip": "لا، تخطي",
    "additional_info_label": "معلومات إضافية",
    "confirm_business_location": "دعونا أولاً نؤكد موقع عملك",
    "custom_get_more_reviews_form": "نموذج مخصص للحصول على المزيد من المراجعات",
    "set_up_custom_form": "قم بإعداد نموذج \"احصل على المزيد من المراجعات\" المخصص حسب المجموعة",
    "reputation_name_label": "احصل على المزيد من التقييمات الاسم",
    "reputation_name_placeholder": "أدخل اسم السمعة",
    "group_label": "مجموعة",
    "group_desc_title": "عنوان وصف المجموعة",
    "group_desc": "وصف المجموعة",
    "select_group_placeholder": "اختر المجموعة",
    "required_group_name": "اسم المجموعة مطلوب",
    "before_asking_review": "قبل أن نطلب من العملاء المراجعة، دعنا نسألهم من 1 إلى 4 أسئلة.",
    "incentive_settings": "إعدادات الحوافز",
    "incentivize_customer": "هل ترغب في تحفيز عميلك على تقديم مراجعة؟",
    "choose_incentive": "اختر الحافز الذي ترغب في الإشارة إليه.",
    "scan_qr_feedback": "قم بمسح رمز الاستجابة السريعة (QR) وشاركنا بتعليقاتك القيمة بخصوص تجربتك.",
    "qr_code": "رمز الاستجابة السريعة",
    "scan_me": "إمسحني!",
    "or_title": "أو",
    "send_otp_title": "إرسال OTP",
    "sign_in_title": "تسجيل الدخول",
    "email_addresss_title": "عنوان البريد الإلكتروني",
    "reset_password_title": "إعادة تعيين كلمة المرور",
    "remember_password_title": "تذكرت كلمة المرور؟",
    "digit_code_title": "يرجى إدخال الرمز المكون من 4 أرقام الذي تم إرساله ",
    "not_received_title": "لم أتلقى رمز التحقق!",
    "resend_title": "يرجى إعادة الإرسال",
    "sign_up_form": "إنشاء حسابك",
    "sign_up_sub_title": "قم بتسجيل الدخول الآن للوصول إلى أحدث الأفكار",
    "already_registered_title": "هل لديك حساب Menuonline بالفعل؟",
    "reserved_title": "جميع الحقوق محفوظة لـ Menuonline © 2023.",
    "terms_title": "شروط الخدمة",
    "policy_title": "سياسة الخصوصية",
    "sign_up_title": "اشتراك",
    "terms_conditions_title": "الشروط والأحكام",
    "business_type_title": "نوع العمل",
    "business_type_sub_title": "يرجى اختيار أحد أنواع الأعمال التي تصف عملك بشكل أفضل!",
    "salon_title": "صالون",
    "restaurant_title": "مطعم",
    "pizza_store_title": "محل بيتزا",
    "food_truck_title": "شاحنة طعام",
    "check_domain_title": "التحقق من اسم النطاق",
    "start_exploring_title": "ابدأ الاستكشاف",
    "sign_in_form": "تسجيل الدخول إلى حسابك",
    "sign_in_sub_title": "قم بتسجيل الدخول الآن للوصول إلى أحدث الأفكار",
    "remember_title": "تذكرنى",
    "otp_title": "كلمة مرور لمرة واحدة",
    "password_title": "كلمة المرور",
    "forgot_title": "هل نسيت كلمة السر؟",
    "not_registered_title": "ليس لديك حساب؟",
    "create_account_title": "سجل الآن",
    "create_form_title": "إنشاء نموذج",
    "first_name_title": "الاسم الأول",
    "last_name_title": "اسم العائلة",
    "email_title": "بريد إلكتروني",
    "mobile_number_title": "رقم الهاتف المحمول",
    "birthday_title": "عيد ميلاد",
    "gender_title": "جنس",
    "address_title": "عنوان",
    "short_answer_title": "إجابة قصيرة",
    "long_answer_title": "إجابة طويلة",
    "single_answer_title": "إجابة واحدة",
    "single_checkbox_title": "مربع اختيار واحد",
    "multiple_choice_title": "اختيار متعدد",
    "drop_down_title": "اسقاط",
    "yes_no_title": "نعم أو لا",
    "description_text_title": "نص الوصف",
    "file_upload": "تحميل الملف",
    "reset_password_form": "إعادة تعيين كلمة المرور الخاصة بك",
    "reset_password_sub_title": "قم بإعادة تعيين كلمة المرور الخاصة بك بسرعة، واحصل على إمكانية الوصول إلى حسابك.",
    "required_full_name_title": "الاسم الكامل مطلوب",
    "required_first_name_title": "الاسم الأول مطلوب",
    "valid_first_name_title": "أدخل الاسم الأول الصحيح",
    "valid_full_name_title": "أدخل الاسم الكامل الصحيح",
    "required_block_time": "مطلوب وقت الحظر",
    "required_last_name_title": "الاسم الأخير مطلوب",
    "valid_last_name_title": "أدخل اسم العائلة الصحيح",
    "valid_mobile_number_title": "أدخل رقم الهاتف المحمول الصحيح",
    "required_mobile_number_title": "رقم الجوال مطلوب",
    "required_already_mobile_number_title": "رقم الهاتف المحمول مطلوب بالفعل",
    "valid_otp_title": "أدخل كلمة مرور صالحة",
    "valid_email_title": "أدخل بريد إلكتروني صالح",
    "required_email_title": "البريد الإلكتروني مطلوب",
    "required_description_title": "الوصف مطلوب",
    "required_description_500_characters": "الرجاء إدخال 500 حرف على الأقل",
    "required_description_600_characters": "الرجاء إدخال الحد الأقصى 600 حرف",
    "domain_title": "اسم المجال",
    "required_password_title": "كلمة المرور مطلوبة",
    "required_password_regex_title": "يجب أن يحتوي على 8 أحرف على الأقل، حرف كبير واحد على الأقل، وحرف صغير واحد، ورقم واحد، وحرف حالة خاصة واحد على الأقل",
    "required_terms_title": "الرجاء الموافقة على الشروط والأحكام",
    "required_note_title": "ملاحظة مطلوبة",
    "required_birthday_title": "يجب أن يكون تاريخ الميلاد",
    "no_access_title": "ليس لديك إمكانية الوصول الآن. يرجى الاتصال بالمسؤول الخاص بك",
    "menu_online_open_txt": "يفتح",
    "loyalty_program_description": "😊 ابحث عن برنامج الولاء المثالي الخاص بك وقم بتخصيصه ليتناسب مع علامتك التجارية!",
    "lp_list_title": "قائمة بطاقات الولاء",
    "lp_n_title": "بطاقة الولاء المثقبة الاسم",
    "enter_details_title": "أدخل التفاصيل",
    "stamp_allowed_title": "الطوابع المسموح بها",
    "stamp_items_title": "طوابع العناصر",
    "lpn_req_title": "مطلوب اسم بطاقة الولاء المثقبة",
    "valid_lpn_title": "أدخل اسم بطاقة الولاء الصالحة",
    "brand_color_error": "يجب أن يكون لون العلامة التجارية 7 أحرف بالضبط",
    "select_dark_brand_color_title": "الرجاء تحديد لون العلامة التجارية الصالحة",
    "your_brand_color_title": "لون علامتك التجارية",
    "upload_logo_title": "تحميل الشعار",
    "locations_title": "المواقع",
    "add_lp_title": "إنشاء برنامج",
    "location_error": "الرجاء تحديد المواقع",
    "pvs_title": "كم عدد الطوابع التي يمكن للعميل كسبها في كل زيارة؟",
    "max_apd_title": "ما هو الحد الأقصى لعدد الطوابع التي يمكن للعميل كسبها في اليوم؟",
    "logo_error": "الشعار مطلوب",
    "expiry_title": "تعيين تاريخ انتهاء الصلاحية للمكافآت",
    "add_more_rewards_title": "+ أضف المزيد من المكافآت",
    "prev_step_1_title": "أظهر رمز الاستجابة السريعة (QR) لكسب النقاط والاستمتاع بالمكافآت الخاصة بك.",
    "delete_lp_title": "سيتم حذف بطاقة الولاء هذه بشكل دائم.",
    "delete_user_record_title": "سيتم حذف سجل المستخدم هذا نهائيًا.",
    "redeem_title": "يسترد",
    "lp_button_title": "انضم إلى بطاقة الولاء",
    "lp_download_button_title": "تنزيل البطاقة",
    "step_0_title": "انضم الآن، مكافآت لذيذة تنتظر محبي الطعام المخلصين مثلك.",
    "step_1_title": "لقد انضممت",
    "congrats_step_title": "أنت الآن مؤهل للحصول على بيتزا مجانية",
    "reward_title": "احصل على مكافآت في كل زيارة",
    "visit_title": "قم بزيارة وفتح",
    "rewards_title": "المكافآت",
    "dummy_address": "3730 Frankfort Ave، لويزفيل، كنتاكي، الولايات المتحدة",
    "loyalty_qr_default_text": "قم بمسح رمز الاستجابة السريعة، وانضم إلى بطاقة الولاء لدينا، واستمتع بالمكافآت",
    "lp_earn_stamp_txt": "يحصل العملاء على طوابع في كل زيارة.",
    "lp_get_rewards_txt": "احصل على مكافآت في كل مرة تقوم فيها بالزيارة",
    "lp_1_time_visit_txt": "1 مرة الزيارة سوف تحصل على",
    "lp_uppercase_stamp_text": "ختم",
    "lp_lowercase_stamp_text": "ختم",
    "lp_txt": "وفاء",
    "lp_per_visit_stamp_validation_msg": "يجب أن يكون عدد الطوابع لكل زيارة أقل من الحد الأقصى المسموح به للطوابع في اليوم الواحد",
    "lp_per_max_stamp_validation_msg": "يجب أن يكون الحد الأقصى المسموح به للطوابع في اليوم أكبر من طابع الزيارة الواحدة",
    "lp_valid_value_msg": "أدخل قيمة صالحة",
    "lp_required_field_msg": "هذه الخانة مطلوبه",
    "lp_stamp_required_msg": "مطلوب ختم",
    "lp_valid_stamp_msg": "أدخل قيمة الطابع الصالحة",
    "lp_reward_name_required_msg": "اسم المكافأة مطلوب",
    "lp_unique_stamp_msg": "يجب أن تكون قيمة الطابع فريدة",
    "lp_benefits": "يعمل برنامج الولاء على تعزيز الزيارات المتكررة والإيرادات مع توفير رؤى العملاء للتسويق الشخصي",
    "lp_how_customer_txt": "هكذا يتعامل عملاؤك",
    "lp_punch_card_txt": "بطاقة الولاء",
    "lp_look_txt": "سوف ننظر! 😍",
    "lp_desc": "وسيحصل العملاء على طوابع مقابل كل زيارة، وبمجرد جمعهم ما يكفي منها، يمكنهم استبدالها بمكافآت خاصة.",
    "lp_edit": "يحرر",
    "lp_looks_good_txt": "يبدو جيدا",
    "lp_customer_view_instruction": "هذا ما سيرى عميلك. قم بتحميل شعارك وحدد سمة اللون",
    "lp_customer_earn_stamps": "السماح للعملاء بكسب الطوابع في كل زيارة وإدارة مكافآتهم",
    "lp_stamp_rewards_setup": "تعيين مكافآت كسب الطوابع، والسماح للعملاء بالحصول على مكافآت لكل طوابع",
    "lp_loyalty_program_setup": "قم بإدخال اسم برنامج الولاء الخاص بك وقم بتعيين موقعك للحصول على هذا البرنامج",
    "lp_prev_step1": "أظهر رمز الاستجابة السريعة (QR) لكسب النقاط والاستمتاع بالمكافآت الخاصة بك.",
    "lp_visit_1_time_txt": "قم بزيارة 1 مرة واحصل على",
    "lp_get_free_txt": "احصل عليه مجانا",
    "lp_visit_allow_txt": "زيارة وفتح",
    "lp_rewards": "المكافآت",
    "repsT": "احصل على المزيد من المراجعات",
    "offSup": "اللوازم المكتبية",
    "repsNxtV": "في زيارتك القادمة",
    "addRepT": "أضف واحصل على المزيد من المراجعات",
    "repEntrCpnTxt": "أدخل نص القسيمة",
    "repEntrCpnTxtBtn": "أدخل نص زر القسيمة",
    "repMngNxtV": "في زيارتك القادمة؟",
    "repMngWldL": "هل ترغب في الحصول على",
    "discount": "تخفيض",
    "repDisTyp": "نوع الخصم",
    "repNameT": "قم بتسمية سمعتك",
    "crtRepT": "إنشاء السمعة",
    "feedBackT": "تعليق",
    "confLocT": "تأكيد الموقع",
    "businLocT": "موقع العمل",
    "addInfoT": "معلومات إضافية",
    "repNReqT": "احصل على المزيد من المراجعات الاسم مطلوب",
    "validRepNT": "أدخل اسم سمعة صالحًا",
    "groupT": "مجموعة",
    "groupLDescTitleT": "دفع جهات الاتصال تلقائيًا إلى مجموعة",
    "groupLDescT": "سيتم إضافة جميع العملاء الذين يقومون بملء النموذج إلى المجموعة المحددة.",
    "que2": "هل كان طعامك جيدا؟",
    "que3": "هل كانت خدمتنا ودية؟",
    "que4": "هل كانت خدمتنا سريعة؟",
    "addAdditionalT": "هل ترغب بإضافة أي معلومات إضافية؟",
    "phNoMandT": "رقم الهاتف إلزامي!",
    "qrGenT": "تم إنشاء رمز الاستجابة السريعة",
    "repFDT": "ستساعد مدخلاتك فريقنا على خدمتك بشكل أفضل!",
    "letdiT": "دعونا نفعل ذلك",
    "tak15secT": "يستغرق 15 ثانية",
    "defQue": "هل ترغب في الحصول على عروض وترويجات رائعة من وقت لآخر؟",
    "defNQue": "هل ترغب في أن يرد عليك أحد؟",
    "rateOnGoogleDesc": "هل من الممكن أن تتكرم بتقييمنا على جوجل؟",
    "mayBeNT": "ربما في المرة القادمة",
    "yesSureT": "نعم بالتأكيد",
    "valid_name": "أدخل اسم صالح",
    "nameReq": "الاسم مطلوب",
    "backToMainT": "العودة إلى الصفحة الرئيسية",
    "attachCopT": "هل ترغب في تحفيز عميلك؟",
    "thankYouT": "شكرًا لك",
    "lftyT": "نحن نتطلع إلى زيارتك القادمة!",
    "repPreT": "معاينة السمعة",
    "offer_exclusive_discounts": "تقديم خصومات وعروض ترويجية حصرية لجذب عملائك.",
    "couponT": "قسيمة",
    "locStepDesc": "تأكيد موقع عملك",
    "createRepStepDesc": "إنشاء نموذج سمعة مخصص حسب المجموعات",
    "feedbackStepDesc": "مجموعة أسئلة تعليقات العملاء",
    "addInfoStepDesc": "حقول العملاء وإعدادات الحوافز",
    "FedbkkWDT": "ردود الفعل مع البيانات",
    "repAnlytT": "تحليلات السمعة",
    "todayT": "اليوم",
    "rep_last_7_days_t": "آخر 7 أيام",
    "last30DaysT": "آخر 30 يوما",
    "lastMT": "الشهر الماضي",
    "custRangeT": "مجموعة مخصصة",
    "queWiseAnltT": "تحليلات حسب السؤال",
    "atL1QueErrT": "الرجاء اختيار سؤال واحد على الأقل",
    "deleteRepTitle": "سيتم حذف هذه السمعة نهائيا.",
    "incvCustT": "نعم، تحفيز عملائي",
    "collAddInfoT": "نعم، أرغب في جمع معلومات إضافية",
    "totRewT": "المراجعة الكاملة",
    "totPosRewT": "مراجعة إيجابية كاملة",
    "negFeedT": "ردود الفعل السلبية",
    "posFeedT": "ردود الفعل الإيجابية",
    "fineT": "الطباعة الدقيقة",
    "enterFineT": "أدخل نصًا مطبوعًا بخط صغير",
    "setThemeT": "تعيين لون السمة",
    "que1T": "السؤال 1",
    "que2T": "السؤال رقم 2",
    "que3T": "السؤال رقم 3",
    "que4T": "السؤال رقم 4",
    "entMailT": "أدخل بريدك الإلكتروني",
    "reputationQRDefTxt": "قم بمسح رمز الاستجابة السريعة (QR) وشاركنا بتعليقاتك القيمة بخصوص تجربتك.",
    "selUser": "حدد المستخدم",
    "userT": "مستخدم",
    "subUReq": "الرجاء تحديد المستخدمين",
    "updateLoc": "تحديث الموقع",
    "leadGenT": "توليد العملاء المحتملين",
    "displayT": "عرض",
    "ratingT": "تصنيف",
    "rep_dashboard_negative_feedback": "ردود الفعل السلبية 0%",
    "rep_dashboard_position_feedback": "ردود الفعل الإيجابية 0%",
    "rep_dashboard_total_Feedback_txt": "مجموع التعليقات",
    "rep_dashboard_anony_Feedback": "تعليقات مجهولة المصدر",
    "rep_dashboard_Feeedback_Data_txt": "ردود الفعل مع البيانات",
    "rep_dashboard_review_txt": "احصل على المزيد من المراجعات",
    "rep_dashboard_total_Review_txt": "المراجعة الكاملة",
    "rep_dashboard_total_positive_Review_txt": "مراجعة إيجابية كاملة",
    "rep_dashboard_negative_feedback_txt": "ردود الفعل السلبية",
    "rep_dashboard_connection_txt": "اتصال",
    "rep_dashboard_question_wise_analytics_txt": "تحليلات حسب السؤال",
    "rep_dashboard_date_label": "حدد التاريخ",
    "rep_dashboard_custom_range_txt": "مجموعة مخصصة",
    "rep_tlt_min": "مندوب",
    "emojis": "الرموز التعبيرية",
    "media_text": "إضافة الوسائط",
    "custom_field_text": "إدراج الحقول المخصصة",
    "shortlink_text": "إضافة رابط مختصر",
    "use_template_text": "استخدم القالب",
    "banner_must_have": "يجب أن يكون",
    "res_seamless": "سلس",
    "res_table_reservation": "حجز الطاولة",
    "res_system": "نظام",
    "res_text": "الحجوزات",
    "md_easily": "بسهولة",
    "md_manage_customers": "إدارة العملاء",
    "md_visits_interactions": "الزيارات والتفاعلات",
    "md_desc": "اجعل تناول الطعام أسهل لعملائك من خلال حجز الطاولات في الوقت الفعلي. قم بإدارة الحجوزات دون عناء، وقلل من أوقات الانتظار، وضمن تجربة سلسة لكل من الموظفين والضيوف. حافظ على حجز مطعمك بالكامل مع الحفاظ على الخدمة الممتازة والرضا.",
    "engage_convert": "إشراك وتحويل الرسائل النصية القصيرة",
    "coupon_campaign": "وحملة القسائم",
    "sms_read_rate": "تتم قراءة الرسائل النصية القصيرة بنسبة 98% من الوقت",
    "real_time_promo": "تقديم العروض الترويجية في الوقت الفعلي لاتخاذ إجراءات فورية",
    "affordable_reach": "طريقة ميسورة التكلفة للوصول إلى العملاء مع عائد استثمار قوي",
    "stay_in_touch": "ابقى على تواصل مع عميلك في جميع الأوقات",
    "bc_engage_customers": "إشراك العملاء وتحويل الرسائل النصية القصيرة",
    "bc_coupon_campaign": "وحملة القسائم",
    "bc_sms_read_rate": "تتم قراءة الرسائل النصية القصيرة بنسبة 98% من الوقت",
    "bc_real_time_promo": "تقديم العروض الترويجية في الوقت الفعلي لاتخاذ إجراءات فورية",
    "bc_affordable_reach": "طريقة ميسورة التكلفة للوصول إلى العملاء مع عائد استثمار قوي",
    "bc_stay_in_touch": "ابقى على تواصل مع عميلك في جميع الأوقات",
    "ar_automate_engagement": "أتمتة عملية التواصل مع العملاء",
    "ar_with": "مع",
    "ar_with_auto_responders": "الردود التلقائية",
    "ar_personalized_messages": "أرسل رسائل مخصصة تلقائيًا لأعياد الميلاد، ورحب بالعملاء الجدد، وأعد الاتصال بالعملاء السابقين. وفِّر الوقت مع الحفاظ على كل تفاعل ذي معنى وفي الوقت المناسب.",
    "sq_custom_forms_tools": "النماذج والأدوات المخصصة - تبسيط",
    "sq_custom_forms_ci": "تفاعلات العملاء",
    "sq_smart_qr_desc": "أنشئ نماذج مخصصة وجمع التوقيعات الإلكترونية واستفد من رموز الاستجابة السريعة الذكية لتبسيط عمليات عملك. قم بتخصيص كل أداة لتلبية احتياجات عملائك وتحسين تجربة المستخدم.",
    "gnc_get_new_cus": "احصل على عملاء جدد",
    "gnc_get_customers": "من وسائل التواصل الاجتماعي – سريع وسهل!",
    "gnc_social_media_power": "استخدم قوة منصات التواصل الاجتماعي لجذب عملاء جدد ودفع المبيعات مباشرة إلى عملك. قم بإعادة توجيه طلبات التوصيل من جهات خارجية مباشرة إلى متجرك أو منصة الطلب عبر الإنترنت لتحقيق أقصى قدر من التحكم والإيرادات.",
    "wifi_connect_guests": "ربط الضيوف بـ",
    "wifi_smart": "واي فاي ذكي",
    "wifi_connect_grow": "& تنمية رؤيتك",
    "wifi_smart_wifi_desc": "قم بتوفير اتصال Wi-Fi خالٍ من المتاعب لعملائك من خلال شاشة البداية ذات العلامة التجارية أثناء جمع بيانات العملاء القيمة لتعزيز جهودك التسويقية وتفاعلك.",
    "wifi_create_splash_page": "إنشاء صفحة البداية لشبكة Wi-Fi للضيوف",
    "instant_menu_create": "إنشاء الخاص بك",
    "instant_menu_text": "القائمة الفورية",
    "instant_menu_wm": "في غضون دقائق",
    "instant_menu_desc": "يعمل على تحسين تجارب العملاء من خلال السماح بالتحديثات في الوقت الفعلي والتخصيص السهل وتحسين المشاركة مما يجعل من الأسهل عرض عروضك والحفاظ على محتواك جديدًا.",
    "instant_menu_create_digital_menu": "إنشاء قائمة رقمية",
    "loyalty_program_rewards": "اكسب المكافآت",
    "loyalty_program_on_visits": "في كل زيارة",
    "loyalty_program_create": "إنشاء برنامج الولاء",
    "reputation_create": "إنشاء السمعة",
    "reputation_boost": "عزز نفسك",
    "reputation_text": "سمعة",
    "reputation_reviews": "مع المزيد من المراجعات!",
    "reputation_desc": "اجمع تعليقات قيمة من عملائك لتحسين خدمتك وسمعتك. شارك تجربتك وساعدنا على النمو - تعليقك مهم!",
    "dashboard": "لوحة القيادة",
    "blast_campaign": "حملة التفجير",
    "blast_campaigns": "حملات التفجير",
    "blast_campaign_sub_title": "قم بإطلاق حملات إعلانية مكثفة للوصول إلى جميع عملائك ببضع نقرات",
    "auto_responders": "الردود التلقائية",
    "auto_responder": "الرد التلقائي",
    "auto_responder_sub_title": "عروض ترويجية سهلة الاستخدام تعتمد على الأحداث لعملائك",
    "smart_qr_form": "نموذج \/ QR الذكي",
    "get_new_customer": "احصل على عميل جديد",
    "marketing": "تسويق",
    "clients": "العملاء",
    "group_data": "بيانات المجموعات",
    "clients_data": "بيانات العملاء",
    "account": "حساب",
    "user_acc_info": "معلومات حساب المستخدم",
    "current_plan": "الخطة الحالية",
    "payments": "المدفوعات",
    "location": "موقع",
    "location_list": "قائمة المواقع",
    "users": "المستخدمون",
    "users_sub_title": "قائمة بجميع المستخدمين وتفاصيلهم",
    "online_profile": "الملف الشخصي على الإنترنت",
    "change_password": "تغيير كلمة المرور",
    "menu": "قائمة طعام",
    "reseller_dashboard": "لوحة معلومات الموزع",
    "companies": "شركات",
    "plans_bought": "الخطط التي تم شراؤها",
    "agreement_t_c": "شروط وأحكام الاتفاقية",
    "wifi_dashboard": "لوحة تحكم الواي فاي",
    "hot_spot": "نقطة اتصال",
    "splash_pages": "صفحات البداية",
    "get_more_reviews": "احصل على المزيد من المراجعات",
    "office_supplies": "اللوازم المكتبية",
    "reservation": "حجز",
    "floor_table": "الأرضية والطاولة",
    "guest_smart_wifi": "واي فاي ذكي للضيوف",
    "digital_signage": "اللافتات الرقمية",
    "schedule_down": "جدول زمني",
    "business_settings": "إعدادات الأعمال",
    "sub_user_login": "تسجيل دخول المستخدم الفرعي",
    "help_center": "مركز المساعدة",
    "switch_to_hub": "التبديل إلى المحور",
    "meeting_records": "سجلات الاجتماع",
    "logout": "تسجيل الخروج",
    "no_access_text": "ليس لديك حق الوصول الآن. يرجى الاتصال بالمسؤول الخاص بك",
    "menu_item": "عنصر القائمة",
    "menu_capital": "قائمة طعام",
    "good_day": "يوم جيد",
    "no_permission_text": "ليس لديك الإذن للقيام بهذا الإجراء!!",
    "enter_valid_pin": "أدخل رقم التعريف الشخصي الصحيح",
    "add_reservation": "إضافة حجز",
    "edit_reservation": "تعديل الحجز",
    "per_req": "عدد الأشخاص مطلوب",
    "req_block_time": "مطلوب وقت الحظر",
    "pending_status": "قيد الانتظار",
    "booked_status": "تم الحجز",
    "completed_status": "مكتمل",
    "cancelled_status": "تم الإلغاء",
    "reservation_slot": "فتحة الحجز",
    "block_time": "وقت الحظر",
    "slot_time": "وقت الحجز:  ",
    "template_name_req": "اسم القالب مطلوب",
    "template_type_req": "نوع القالب مطلوب",
    "sms_template_req": "مطلوب قالب الرسائل القصيرة",
    "group_req": "المجموعة مطلوبة",
    "valid_date_time": "أدخل التاريخ والوقت الصحيحين",
    "valid_group_name": "أدخل اسم المجموعة الصحيح",
    "reservation_mark_completed": "سيتم وضع علامة على هذا الحجز على أنه مكتمل.",
    "customer_name": "اسم العميل",
    "date_time": "التاريخ والوقت",
    "table_no": "الجدول رقم",
    "floor": "أرضية",
    "customer_name_placeholder": "اسم العميل",
    "date_time_placeholder": "التاريخ والوقت",
    "table_no_placeholder": "الجدول_لا",
    "floor_placeholder": "أرضية",
    "select_floor": "حدد الطابق",
    "select_table": "حدد الجدول",
    "customer_name_macro": "%% اسم العميل %%",
    "date_time_macro": "%% التاريخ والوقت %%",
    "table_no_macro": "%% جدول_لا %%",
    "floor_macro": "%% أرضية %%",
    "template_name": "اسم القالب",
    "login_with_4_digit_pin": "تسجيل الدخول باستخدام رقم التعريف الشخصي المكون من 4 أرقام",
    "login_now_to_access_latest_insights": "قم بتسجيل الدخول الآن للوصول إلى أحدث اللافتات",
    "mkt_BS_Title": "انفجار الرسائل القصيرة",
    "step_1": "تخصيص الرسالة",
    "step_2": "اختر الجمهور",
    "step_3": "رسالة الجدول الزمني",
    "step_4": "أرسل رسالة",
    "step_5": "إدارة الرسالة",
    "bs_tab_title": "ملخص حملة Blast",
    "default_message": "مرحبًا %% الاسم الأول %% ، خصم خاص على وجبة الإفطار المتأخرة! تناول الطعام معنا اليوم واحصل على خصم حصري بنسبة 10% على فاتورتك!",
    "bs_Title": "اختر نوع الحملة",
    "sms_title": "الرسائل القصيرة\/الرسائل المتعددة الوسائط",
    "reach_out_to_customer": "التواصل مع العملاء من خلال الرسائل النصية والوسائط المتعددة.",
    "wp_text": "واتساب",
    "connect_with_wp": "تواصل مع عملائك باستخدام WhatsApp 💬",
    "send_discount_offers": "أرسل عروض الخصومات عبر الرسائل القصيرة 📲",
    "send_coupons_via_sms": "أرسل قسائم عبر الرسائل القصيرة إلى عملائك 🎟️",
    "env_Cap": "تقديم خصومات وعروض ترويجية حصرية لجذب عملائك",
    "cmt_Caption": "التواصل مع العملاء من خلال الرسائل النصية والوسائط المتعددة",
    "wp_caption": "تواصل مع العملاء باستخدام WhatsApp للتواصل السلس والمباشر",
    "msg_Prev_Title": "معاينة الرسالة",
    "cou_Prev_Title": "معاينة القسيمة",
    "blast_SMS": "حملة الرسائل القصيرة\/الرسائل المتعددة الوسائط",
    "bs_P_Msg": "في قائمة الانتظار",
    "bs_S_Msg_C": "فشل",
    "bs_F_Msg_Count": "تم التوصيل",
    "previous": "سابق",
    "next_ST": "التالي",
    "next_S": "الخطوة التالية",
    "req_Temp_Name_Title": "اسم القالب مطلوب",
    "req_Template_Tx_Title": "النص القالب مطلوب",
    "add_Temp_Title": "إضافة قالب",
    "link_Desc": "أضف رابطًا من اختيارك حتى يتمكن العملاء من الحجز معك عبر Facebook أو Google أو موقع الويب الخاص بك.",
    "audience_Title": "جمهور",
    "client_G1_Title": "العملاء الجدد",
    "client_G2_Title": "العملاء الجدد",
    "client_G3_Title": "العملاء المخلصين",
    "client_G4_Title": "العملاء المنتهية خدمتهم",
    "client_G5_Title": "العملاء حسب تاريخ الاتصال",
    "on_Which_Day": "في أي يوم؟",
    "on_Day": "في يوم",
    "immediate_Desc": "سيتم إرسال رسالتك على الفور، الرجاء الضغط على التالي للبدء.",
    "never_Desc": "تم جدولة هذه الحملة لتستمر إلى الأبد حتى تقوم بإنهائها يدويًا.",
    "end_Desc": "ستنتهي الحملة في التاريخ الذي تختاره من هنا.",
    "success_Message": "تم حفظ الحملة بنجاح!",
    "send_Success_Message": "تم إرسال الحملة بنجاح!",
    "msg_Name_Field_Title": "عنوان الحملة",
    "valid_campaign_title": "أدخل عنوان حملة صالحًا",
    "msg_Type_Title": "نوع الرسالة",
    "sms_Desc": "1 رصيد لكل رسالة نصية قصيرة",
    "mms_Desc": "3 نقاط ائتمانية لكل رسالة MMS",
    "client_GTO_1": "يوم",
    "client_GTO_2": "ساعات",
    "minutes": "دقائق",
    "client_GTO_4": "أسبوع",
    "time_Option_1": "يوم",
    "time_Option_2": "ساعات",
    "c_Grp_Day_1": "90 يوما",
    "c_Grp_Day_2": "60 يوما",
    "c_Grp_Day_3": "30 يوما",
    "first": "أولاً",
    "second_T": "ثانية",
    "third_T": "ثالث",
    "fourth_T": "رابعا",
    "fifth_T": "الخامس",
    "delete_BS_T": "سيتم حذف هذه الحملة نهائيًا.",
    "delete_MBS_T": "سيتم حذف هذه الحملات نهائيًا.",
    "cFT_1": "هاتف",
    "cFT_2": "الاسم الأول للاتصال",
    "cFT_3": "اسم جهة الاتصال الأخير",
    "cFT_4": "البريد الإلكتروني للتواصل",
    "cFT_5": "ملحوظة",
    "cFT_6": "عيد ميلاد",
    "cFT_7": "اسم الشركة",
    "cF_Meta_Tag_1": "%% هاتف %%",
    "cF_Meta_Tag_2": "%% الاسم الأول %%",
    "cF_Meta_Tag_3": "%% اسم العائلة %%",
    "cF_Meta_Tag_4": "%% بريد إلكتروني %%",
    "cF_Meta_Tag_5": "%% ملحوظة %%",
    "cF_Meta_Tag_6": "%% عيد ميلاد %%",
    "cF_Meta_Tag_7": "%% اسم الشركة %%",
    "cF_Name_1": "هاتف",
    "cF_Name_2": "الاسم الأول",
    "cF_Name_3": "اسم العائلة",
    "cF_Name_4": "بريد إلكتروني",
    "cF_Name_5": "ملحوظة",
    "cF_Name_6": "عيد ميلاد",
    "cF_Name_7": "اسم الشركة",
    "all_clients": "جميع العملاء",
    "aud_Type_2": "مجموعات العملاء",
    "aud_Type_3": "مجموعات العملاء",
    "aud_Type_T1": "جميع العملاء",
    "aud_Type_T2": "مجموعات العملاء",
    "aud_Type_T3": "مجموعات العملاء",
    "new_clients": "العملاء الجدد",
    "client_G2": "العملاء الجدد",
    "client_G3": "العملاء المخلصين",
    "client_G4": "العملاء المنتهية خدمتهم",
    "client_G5": "العملاء حسب تاريخ الاتصال",
    "never_text": "أبداً",
    "ending_on": "تنتهي في",
    "send_T2": "مجدولة",
    "send_Type_3": "يتكرر",
    "daily": "يوميًا",
    "weekly_T": "أسبوعي",
    "monthly_T": "شهريا",
    "yearly_T": "سنوي",
    "each": "كل",
    "on_the": "على",
    "monday": "الاثنين",
    "exclusive_offer": "عرض حصري لك",
    "redeem_now": "استرداد الآن",
    "redeem_with_cashier": "يرجى استبدالها مع أمين الصندوق",
    "lg_Day_2": "يوم الثلاثاء",
    "lg_Day_3": "الأربعاء",
    "lg_Day_4": "يوم الخميس",
    "lg_Day_5": "جمعة",
    "lg_Day_6": "السبت",
    "lg_Day_7": "الأحد",
    "msg_Pre_T": "الدومينو",
    "cA_Edit_T1": "الجميع",
    "cA_Edit_T2": "متضمن",
    "cA_Edit_T3": "مستبعد",
    "SMS": "رسالة قصيرة",
    "MMS": "رسائل الوسائط المتعددة",
    "usd_T": "دولار أمريكي",
    "cad_T": "كاد",
    "msg": "رسالة",
    "which_Day": "أي الأيام",
    "end_Date_Tx": "تاريخ النهاية",
    "sDate_Err": "يجب أن يكون تاريخ البدء أكبر من أو يساوي اليوم",
    "eDate_Err": "يجب أن يكون تاريخ النهاية لاحقًا لتاريخ البدء",
    "start_Date_Req": "تاريخ البدء مطلوب",
    "end_Date_Req": "تاريخ النهاية مطلوب",
    "time_req": "الوقت مطلوب",
    "client_GT1": "العملاء المشتركين في الماضي",
    "client_GT2": "العملاء الذين لديهم اتصال في الماضي",
    "client_GT3": "العملاء مع ما لا يقل عن",
    "client_GT4": "مع الاخير",
    "client_GT5": "من لم يعود في المرة الماضية",
    "dummy_Phone_No": "+91987-654-3210",
    "test_T": "امتحان",
    "dummy_Birth_Date": "01-01-2001",
    "image_Req": "مطلوب الوسائط أو عنوان URL",
    "time_Title": "وقت",
    "start_date": "تاريخ البدء",
    "end_date": "تاريخ النهاية",
    "auto_Send_T": "إرسال تلقائي إلى العملاء",
    "add_Media": "إضافة الوسائط",
    "in_Title": "في",
    "c_Temp_T": "قالب الحملة",
    "temp_NT": "اسم القالب",
    "type_T": "يكتب",
    "select_template_type": "حدد نوع القالب",
    "sel_Temp_T": "حدد نوع القالب",
    "sms_temp_t": "قالب الرسائل القصيرة",
    "temp_T": "نص القالب",
    "default_Msg": "مرحبًا %% الاسم الأول %% ، خصم خاص على وجبة الإفطار المتأخرة! تناول الطعام معنا اليوم واحصل على خصم حصري بنسبة 10% على فاتورتك!",
    "refill_T": "إعادة التعبئة",
    "avl_Credit": "الاعتمادات المتاحة",
    "req_Credit": "مجموع الاعتمادات المطلوبة",
    "rem_Credit": "الرصيد المتبقي",
    "refill_Credit": "الرصيد المطلوب إعادة تعبئته",
    "clients_Req": "حدد عميل واحد على الأقل",
    "subclient_Req": "العملاء المختارون ليسوا مشتركين.",
    "refill_Desc": "يرجى إعادة تعبئة الاعتمادات المطلوبة",
    "url_Regex": "^((http|https):\/\/).....+$",
    "sd_Invalid": "تاريخ البدء غير صالح",
    "ed_Invalid": "تاريخ النهاية غير صالح",
    "img_Url_Invalid": "عنوان URL للصورة غير صالح",
    "time_Invalid": "الوقت غير صالح",
    "time_And_Date_Invalid": "أدخل تاريخًا ووقتًا صالحًا",
    "time_Invalid_Bfr": "حدد وقتًا لا يقل عن 5 دقائق لاحقًا عن الوقت الحالي",
    "sod_Req": "إرسال في اليوم المطلوب",
    "add_Card": "إضافة بطاقة جديدة",
    "sm_D_Rep_Title": "تقرير تسليم الرسائل القصيرة\/الرسائل المتعددة الوسائط",
    "send_Now_T": "أرسل الآن",
    "schd_Now_T": "جدولة الرسائل القصيرة",
    "test_SMS_T": "إرسال رسالة نصية اختبارية",
    "save_AD_T": "حفظ كمسودة",
    "back_TE_T": "العودة إلى التحرير",
    "reset_tex": "إعادة ضبط",
    "update_tex": "تحديث",
    "dum_msg1": "لعرض اسم العميل، استخدم %% customer_name %% في القالب",
    "dum_msg2": "لعرض وقت الحجز، استخدم %% date_time %% في القالب",
    "dum_msg3": "لعرض رقم الجدول، استخدم %% table_no %% في القالب",
    "embedded_link": "رابط مضمن",
    "img_title": "صورة",
    "img_input_text": "نص:",
    "img_join_text": " للانضمام لقائمتنا",
    "img_copy_text": "ينسخ",
    "img_not_found": "لم يتم العثور على الصورة المولدة!!",
    "or_text": "أو",
    "web_signup_image_content": "بإرسال هذا النموذج والتسجيل عبر الرسائل النصية، فإنك توافق على تلقي رسائل نصية تسويقية (مثل أكواد الترويج والتذكيرات). قد يتم تطبيق رسوم الرسائل والبيانات. يختلف تكرار الرسائل. يمكنك إلغاء الاشتراك في أي وقت عن طريق الرد بـ STOP",
    "img_download_success": "تم تحميل الصورة بنجاح",
    "embedded_link_copied": "تم نسخ الرابط المضمن بنجاح",
    "media_url_required": "الوسائط أو عنوان URL مطلوب",
    "invalid_image_url": "عنوان URL للصورة غير صالح",
    "invalid_file": "الملف غير صالح",
    "image_error_1mb": "الرجاء إدخال صورة أقل من 1 ميجا بايت",
    "image_error_2_5mb": "الرجاء إدخال صورة أقل من 2.5 ميجا بايت",
    "image_error_3mb": "الرجاء إدخال صورة أقل من 3 ميجا بايت",
    "change_title": "يتغير",
    "drop_file_text": "قم بإسقاط الملف هنا أو انقر للتحميل",
    "apply_text": "يتقدم",
    "search_here": "ابحث هنا",
    "update_status": "تحديث الحالة",
    "reservation_deleted_permanently": "سيتم حذف هذا الحجز نهائيًا.",
    "table_text": "الجداول",
    "add_table_button": "إضافة جدول جديد",
    "add_table_text": "إضافة جدول",
    "edit_table_text": "تحرير الجدول",
    "table_delete_text": "سيتم حذف هذا الجدول نهائيًا.",
    "multiple_table_delete_text": "سيتم حذف هذه الجداول نهائيًا.",
    "table_error_name": "الرجاء إدخال اسم الجدول",
    "table_error_invalid_name": "الرجاء إدخال اسم الجدول الصحيح",
    "table_error_floor": "الرجاء تحديد الطابق",
    "table_error_select": "الرجاء اختيار الجدول",
    "table_capacity_text": "سعة الطاولة",
    "capacity_text": "سعة",
    "table_occasion_text": "ابحث عن طاولتك لأي مناسبة",
    "table_name_text": "اسم الجدول",
    "table_capacity_error": "مطلوب سعة الجدول",
    "cancel_text": "يلغي",
    "submit_text": "يُقدِّم",
    "select_valid_date": "حدد تاريخًا صالحًا",
    "select_valid_time": "حدد الوقت الصحيح",
    "valid_contact_number": "أدخل رقم اتصال صالح",
    "date_req": "التاريخ مطلوب",
    "date_error_later_than_today": "يجب أن يكون التاريخ أحدث من تاريخ اليوم أو مساويًا له",
    "time_error_later_than_5_minutes": "حدد الوقت على الأقل 5 دقائق لاحقًا من الوقت الحالي",
    "number_person_req": "عدد الأشخاص مطلوب",
    "contact_no_req": "رقم التواصل مطلوب",
    "contact_no": "رقم التواصل",
    "select_time": "حدد الوقت",
    "for_how_many_person": "لعدد كم شخص؟",
    "f_name": "الاسم الأول",
    "l_name": "اسم العائلة",
    "business_name": "اسم العمل",
    "business_no": "رقم العمل",
    "no_for_sign_in": "يتم استخدام هذا الرقم لتسجيل الدخول",
    "business_email": "البريد الإلكتروني الخاص بالعمل",
    "notes_tex": "ملحوظات",
    "floor_deleted_permanently": "سيتم حذف هذا الطابق نهائيًا.",
    "add_floor": "إضافة طابق",
    "edit_floor": "تحرير الطابق",
    "name_text": "اسم",
    "mobile_no": "رقم الجوال",
    "person_text": "شخص",
    "date_and_time": "التاريخ والوقت",
    "actions_text": "الإجراءات",
    "extra_text": "إضافي",
    "floor_name_req": "اسم الطابق مطلوب",
    "floor_name": "اسم الطابق",
    "status_text": "حالة",
    "category_status_req": "الحالة الفئة مطلوبة",
    "table_deleted_permanently": "سيتم حذف هذا الجدول نهائيًا.",
    "tables_deleted_permanently": "سيتم حذف هذه الجداول نهائيًا.",
    "back_to_home": "العودة إلى الصفحة الرئيسية",
    "link_copied_text": "تم نسخ الرابط إلى الحافظة",
    "cust_dash_head_to_title": "ل",
    "cust_dash_head_location_title": "موقع",
    "select_location_title": "حدد الموقع",
    "all_locations_label": "جميع_المواقع",
    "rep_customer_feedback_analytics": "😊 تتبع تعليقات عملائك باستخدام التحليلات في الوقت الفعلي",
    "rep_customer_txt": "عملاء",
    "rep_delete_title": "سيتم حذف هذه السمعة نهائيا.",
    "rep_qr_def_txt": "قم بمسح رمز الاستجابة السريعة (QR) وشاركنا بتعليقاتك القيمة بخصوص تجربتك.",
    "delete_title": "يمسح",
    "user_list_title": "قائمة العملاء",
    "os_nfc_txt": "بطاقات NFC",
    "os_sign_holder_txt": "حامل اللافتات",
    "os_store_Sign_holder_txt": "حاملات لافتات المتاجر",
    "StaT": "إحصائيات",
    "AllgT": "جميع المجموعات",
    "FavT": "مفضل",
    "MostActT": "الأكثر نشاطا",
    "grT": "اسم المجموعة",
    "keywT": "الكلمات الرئيسية",
    "exSubT": "رسالة للمشترك الحالي",
    "ArchT": "مؤرشفة",
    "gNotiSms": "أعلمني بكل جهة اتصال جديدة عبر الرسائل القصيرة",
    "gNotiEmail": "أعلمني بكل جهة اتصال جديدة عبر البريد الإلكتروني",
    "reqGName": "اسم المجموعة مطلوب",
    "validGN": "أدخل اسم المجموعة الصحيح",
    "valid_phone_no": "أدخل رقم هاتف صالح",
    "phone_no_req": "رقم الهاتف مطلوب",
    "required_message_text": "الرسالة مطلوبة",
    "required_discount_text": "يجب الحصول على خصم",
    "less_than_discount": "يجب أن يكون مبلغ الخصم أقل من 5000",
    "discount_percentage_invalid": "يجب أن تكون نسبة الخصم أقل من أو تساوي 100",
    "discount_type_req": "نوع الخصم مطلوب",
    "discount_text_req": "نص القسيمة مطلوب",
    "reqContactNo": "رقم التواصل مطلوب",
    "reqMsgNT": "عنوان الحملة مطلوب",
    "reqLinkT": "الرابط مطلوب",
    "delMGT": "سيتم حذف هذه المجموعات نهائيًا.",
    "defMemMsg": "اكتب رسالتك هنا",
    "add_cust_to_grp_title": "إضافة العميل إلى المجموعة",
    "group_title": "المجموعات",
    "group_create_title": "إنشاء مجموعة",
    "group_name_txt": "اسم المجموعة",
    "group_table_keyword_title": "الكلمة الرئيسية",
    "actions_title": "الإجراءات",
    "clients_title": "العملاء",
    "send_title": "يرسل",
    "qr_title": "رمز الاستجابة السريعة",
    "delete_group_txt": "سيتم حذف هذه المجموعة نهائيا.",
    "delete_groups_txt": "سيتم حذف هذه المجموعات نهائيًا.",
    "delete_client_title": "سيتم حذف هذا العميل نهائيًا.",
    "delete_clients_title": "سيتم حذف هؤلاء العملاء نهائيًا.",
    "delete_multiple_title": "حذف متعدد",
    "wel_sms_mms": "إرسال رسالة ترحيبية قصيرة\/رسائل وسائط متعددة؟",
    "key_words_title": "الكلمات الرئيسية",
    "srch_plch_txt": "ابحث هنا",
    "req_location_id_title": "الموقع مطلوب",
    "create_text": "يخلق",
    "view_text": "منظر",
    "immediately": "في الحال",
    "business_name_req": "الاسم التجاري مطلوب",
    "business_no_req": "رقم العمل مطلوب",
    "valid_business_name": "أدخل اسم تجاري صالح",
    "valid_business_no": "أدخل رقم العمل الصحيح",
    "address_req": "العنوان مطلوب",
    "valid_address": "الرجاء تحديد عنوان صالح",
    "time_zone_req": "المنطقة الزمنية مطلوبة",
    "image_req": "الرجاء إدخال صورة أقل من 2.5 ميجا بايت",
    "valid_file": "الملف غير صالح.",
    "logo": "الشعار",
    "time_zone": "المنطقة الزمنية",
    "save": "يحفظ",
    "account_type_req": "نوع الحساب مطلوب",
    "gst_no_req": "رقم ضريبة السلع والخدمات مطلوب",
    "gst_no_valid": "أدخل رقم ضريبة السلع والخدمات الصحيح",
    "set_up_payments": "إعداد المدفوعات",
    "billing_details": "أدخل تفاصيل الفاتورة الخاصة بك",
    "billing_details_desc": "سيتم عرض تفاصيل الفاتورة الخاصة بك على فاتورتك الشهرية من menuonline.",
    "account_type": "نوع الحساب",
    "select_account_type": "حدد نوع الحساب",
    "gst_no": "رقم ضريبة السلع والخدمات",
    "transaction_details": "تفاصيل المعاملة",
    "payment_method": "طريقة الدفع",
    "billing_period": "فترة الفوترة",
    "paid_by": "تم الدفع بواسطة",
    "download": "تحميل",
    "pay_now": "ادفع الآن",
    "pull_down_refresh": "اسحب لأسفل للتحديث",
    "release_refresh": "إصدار للتحديث",
    "billing_details_text": "تفاصيل الفاتورة",
    "payment_methods": "طرق الدفع",
    "invoice": "فاتورة",
    "invoice_to": "الفاتورة إلى :",
    "issue_date": "تاريخ الإصدار",
    "due_date": "تاريخ الاستحقاق",
    "amount_due": "المبلغ المستحق",
    "charges": "رسوم",
    "sub_total_capitalize": "المجموع الفرعي",
    "hst": "ضريبة السلع والخدمات",
    "grand_total": "المجموع الإجمالي",
    "invoice_generated_on": "تم إنشاء الفاتورة في",
    "contact_us": "اتصل بنا",
    "invoice_issue": "إذا قمت بمراجعة فاتورتك ولا يزال لديك أسئلة",
    "call": "يتصل",
    "send_sms": "إرسال رسالة نصية قصيرة",
    "payment_success": "تم الدفع بنجاح",
    "edit_credit_card": "تعديل بطاقة الائتمان",
    "add_credit_card": "إضافة بطاقة الائتمان",
    "modify_card_info": "تعديل معلومات بطاقتك",
    "enter_card_info": "أدخل معلومات بطاقتك",
    "account_no_req": "رقم الحساب مطلوب",
    "card_name_req": "الاسم الموجود على البطاقة مطلوب",
    "expiry_date_req": "تاريخ انتهاء الصلاحية مطلوب",
    "valid_expiry_date": "أدخل تاريخ انتهاء الصلاحية الصحيح",
    "valid_cvv": "أدخل رمز CVV صالحًا",
    "cvv_req": "يجب أن يكون رمز CVV مطلوبًا",
    "card_no": "رقم البطاقة",
    "name_of_card": "الاسم الموجود على البطاقة",
    "expiry": "انتهاء الصلاحية",
    "mm_text": "شهر\/سنة",
    "cvv": "قيمة رمز التحقق",
    "set_as_default": "تعيين كإعداد افتراضي",
    "add_new_card": "إضافة بطاقة جديدة",
    "all_credit_card": "جميع بطاقات الائتمان الخاصة بك",
    "fastest_checkout": "أسرع عملية دفع مع منصة آمنة وموثوقة، تحظى بثقة مجموعة كبيرة من الشركات",
    "coupon_button_req": "يجب إدخال نص زر القسيمة",
    "max19_characters_allowed": "الحد الأقصى المسموح به هو 19 حرفًا",
    "fine_print_text_req": "يجب أن يكون النص مطبوعًا بخط صغير",
    "clients_req": "حدد عميل واحد على الأقل",
    "client_groups": "مجموعات العملاء",
    "day_text": "يوم",
    "valid_days": "قيمة اليوم كبيرة جدًا، أدخل أيامًا صالحة",
    "hours_text": "ساعات",
    "enter_valid_hours": "قيمة الساعات كبيرة جدًا، أدخل ساعات صالحة",
    "enter_valid_min": "قيمة الدقائق كبيرة جدًا، أدخل دقائق صالحة",
    "clients_with_connection_required": "العملاء الذين لديهم اتصال في الأخير مطلوبون",
    "connection_required": "مطلوب الاتصال",
    "connection_value_too_large": "قيمة الاتصالات كبيرة جدًا، أدخل اتصالاً صالحًا",
    "minutes_required": "الدقائق المطلوبة",
    "clients_with_at_least_connection_required": "مطلوب عملاء لديهم اتصال على الأقل",
    "last_minutes_req": "مع الدقائق الأخيرة مطلوبة",
    "clients_not_returned_required": "من لم يعود في الدقائق الاخيرة مطلوب",
    "not_return_in_last_minutes_invalid": "عدم العودة في الدقائق الأخيرة يجب أن تكون أقل من الدقائق الأخيرة.",
    "customerGroups": "مجموعات العملاء",
    "select_customer_group": "الرجاء تحديد مجموعة العملاء",
    "location_required": "مطلوب الموقع",
    "start_date_required": "تاريخ البدء مطلوب",
    "start_date_invalid": "تاريخ البدء غير صالح",
    "start_date_invalid_error": "يجب أن يكون تاريخ البدء أكبر من أو يساوي اليوم",
    "recurring": "يتكرر",
    "end_date_required": "تاريخ النهاية مطلوب",
    "end_date_invalid": "تاريخ النهاية غير صالح",
    "end_date_invalid_error": "يجب أن يكون تاريخ النهاية لاحقًا لتاريخ البدء",
    "time_invalid": "الوقت غير صالح",
    "monthly_text": "شهريا",
    "send_day_req": "إرسال في اليوم المطلوب",
    "loyal_clients": "العملاء المخلصين",
    "recent_clients": "العملاء الجدد",
    "lapsed_clients": "العملاء المنتهية خدمتهم",
    "clients_connection_date": "العملاء حسب تاريخ الاتصال",
    "scheduled_text": "مجدولة",
    "weekly": "أسبوعي",
    "selected_clients_not_subscriber": "العملاء المحددون ليسوا مشتركين.",
    "message_preview_title": "معاينة الرسالة",
    "coupon_preview_title": "معاينة القسيمة",
    "form_text": "استمارة",
    "preview_text": "معاينة",
    "next_text": "التالي",
    "send_test_SMS": "إرسال رسالة نصية اختبارية",
    "save_draft": "حفظ كمسودة",
    "back_to_edit": "العودة إلى التحرير",
    "select_payment_method": "الرجاء اختيار طريقة الدفع",
    "schedule_SMS": "جدولة الرسائل القصيرة",
    "send_now": "أرسل الآن",
    "get_more_ratings": "احصل على المزيد من التقييمات",
    "overview": "ملخص",
    "reset_campaign": "حملة إعادة الضبط",
    "permissions": "الأذونات",
    "location_name": "اسم الموقع",
    "phone_no": "رقم التليفون",
    "location_email_address": "الموقع عنوان البريد الإلكتروني",
    "located_business": "أين يقع عملك؟",
    "business_logo": "شعار العمل",
    "congratulations": "مبروك",
    "almost_done": "لقد انتهيت تقريبا",
    "publish": "نشر",
    "about_your_business": "حول عملك",
    "add_your_location": "أضف موقعك",
    "publish_location": "نشر الموقع",
    "location_name_req": "اسم الموقع مطلوب",
    "valid_location_name": "أدخل اسم الموقع الصحيح",
    "business_logo_req": "مطلوب شعار العمل",
    "please_accept_terms": "الرجاء قبول الشروط والأحكام",
    "add_new_location": "إضافة موقع جديد",
    "edit_location": "تعديل الموقع",
    "add_location": "إضافة الموقع",
    "existing_msg_subscriber_txt": "رسالة للمشترك الحالي",
    "msg_capitalize_txt": "رسالة",
    "group_noti_sms": "أعلمني بكل جهة اتصال جديدة عبر الرسائل القصيرة",
    "group_noti_email": "أعلمني بكل جهة اتصال جديدة عبر البريد الإلكتروني",
    "group_member_msg": "يبدو أنك جزء من مجموعتنا بالفعل!!",
    "group_mend_msg": "توقف لإنهاء الأمر. المساعدة للحصول على المساعدة. قد يتم تطبيق رسوم الرسائل القصيرة والبيانات",
    "Disclaimer_title": "نص إخلاء المسؤولية: ",
    "group_def_msg": "نحن متواجدون الآن! اطلع على قائمة خدماتنا واحجز موعدك التالي اليوم.",
    "required_msg_txt": "الرسالة مطلوبة",
    "required_Key_txt": "الكلمات الرئيسية مطلوبة",
    "required_mem_msg": "رسالة المشترك مطلوبة",
    "client_list_title": "قائمة العملاء",
    "add_contact_txt": "إضافة جهة اتصال",
    "delete_all_clients_txt": "حذف جميع العملاء",
    "delete_all_clients_msg": "هل أنت متأكد من أنك تريد حذف جميع العملاء؟ لا يمكن استردادهم.",
    "delete_all_txt": "حذف الكل",
    "timeline_title": "الخط الزمني",
    "unsubscribe_title": "إلغاء الاشتراك",
    "subscribe_title": "يشترك",
    "unsubscribe_confirm_msg": "هل ترغب في وضع علامة على هذا العميل باعتباره ملغى الاشتراك؟",
    "subscribe_confirm_msg": "هل ترغب في وضع علامة على هذا العميل كمشترك؟",
    "no_title": "لا",
    "yes_title": "نعم",
    "client_name_title": "اسم العميل",
    "source_title": "مصدر",
    "contact_file_Req": "ملف جهات الاتصال مطلوب",
    "req_title": "مطلوب",
    "opt_in_req": "يجب أن تكون جهات الاتصال المستوردة متوافقة بنسبة 100%",
    "image_invalid_error": "الملف غير صالح.",
    "import_contacts_msg": "استيراد جهات الاتصال",
    "csv_upload_title": "ملف جهات اتصال CSV للتحميل *",
    "csv_file_desc": "يرجى تحميل ملف csv فقط - أعمدة ملف csv - الاسم الأول، الاسم الأخير، معرف البريد الإلكتروني، رقم الهاتف المحمول، الجنس، تاريخ الميلاد (MM\/DD\/YYYY) - لا توجد مسافات أو شرطات أو أقواس في رقم الهاتف المحمول",
    "to_download_title": "لتحميل الملف التجريبي اضغط هنا",
    "contains_header_title": "نعم، هذا الملف يحتوي على رؤوس",
    "opt_in_100_txt": "نعم، هذه جهات الاتصال المستوردة اختيارية بنسبة 100%",
    "DisT": "نص إخلاء المسؤولية: ",
    "gMendMsg": "توقف لإنهاء الأمر. المساعدة للحصول على المساعدة. قد يتم تطبيق رسوم الرسائل القصيرة والبيانات",
    "reqKeyT": "الكلمات الرئيسية مطلوبة",
    "reqMemMsg": "رسالة المشترك مطلوبة",
    "reqMsgT": "الرسالة مطلوبة",
    "gMemberMsg": "يبدو أنك جزء من مجموعتنا بالفعل!!",
    "gdefMsg": "نحن متواجدون الآن! اطلع على قائمة خدماتنا واحجز موعدك التالي اليوم.",
    "next_title": "التالي",
    "male_title": "ذكر",
    "male_val": "ذكر",
    "female_title": "أنثى",
    "female_val": "أنثى",
    "others_txt": "آحرون",
    "others_val": "آحرون",
    "validBirthdate": "أدخل تاريخ ميلاد صحيح",
    "valid_phone_no_title": "أدخل رقم هاتف صالح",
    "required_phone_no_title": "رقم الهاتف مطلوب",
    "add_new_client_txt": "إضافة عميل جديد",
    "update_client_txt": "تحديث العميل",
    "phone_num_text": "رقم التليفون",
    "dob_title": "تاريخ الميلاد",
    "select_gender_title": "حدد الجنس",
    "timelineTitle": "الخط الزمني",
    "confirm_location": "تأكيد الموقع",
    "feedback_title": "تعليق",
    "rep_question_1": "هل كان العمل نظيفا؟",
    "rep_que_2": "هل كان طعامك جيدا؟",
    "rep_que_3": "هل كانت خدمتنا ودية؟",
    "rep_que_4": "هل كانت خدمتنا سريعة؟",
    "business_location_req_title": "مطلوب موقع العمل",
    "valid_location_err_txt": "الرجاء تحديد عنوان صالح",
    "rep_management_title": "احصل على المزيد من إدارة المراجعات",
    "rep_about_desc": "تساعدك السمعة على زيادة تصنيف جوجل من خلال طلب ملاحظات من عملائك",
    "preview_title": "معاينة",
    "rep_preview_txt": "معاينة السمعة",
    "back_title": "خلف",
    "fine_print_txt": "يرجى استبدالها مع أمين الصندوق",
    "redeem_me_title": "خلصني",
    "rep_nxt_visit_txt": "في زيارتك القادمة",
    "device_type": "نوع الجهاز المستخدم",
    "connection_method": "طريقة الاتصال",
    "peak_days": "أيام الذروة",
    "peak_hours": "ساعات الذروة",
    "guest_by_day": "ضيف اليوم",
    "guest_visit": "زيارة الضيف",
    "connection": "اتصال",
    "connection_duration": "مدة الاتصال",
    "guest_visit_1": "1 مرة",
    "guest_visit_2": "مرتين",
    "guest_visit_3_5": "3-5 مرات",
    "guest_visit_6_10": "6-10 مرات",
    "guest_visit_11_25": "11-25 مرة",
    "guest_visit_26_100": "26-100 مرة",
    "guest_visit_100_plus": "100+ مرة",
    "device_android_total": "مجموع الاندرويد",
    "device_android": "أندرويد",
    "device_desktop": "سطح المكتب",
    "device_ios": "اي او اس",
    "device_ios_total": "مجموع IOS",
    "device_desktop_total": "مجموع سطح المكتب",
    "connection_duration_10": "<=10 دقيقة",
    "connection_duration_20": "11-20 دقيقة",
    "connection_duration_30": "21-30 دقيقة",
    "connection_duration_40": "31-45 دقيقة",
    "connection_duration_60": "46-60 دقيقة",
    "connection_method_email": "بريد إلكتروني",
    "connection_method_sms": "رسالة قصيرة",
    "connection_method_google": "جوجل",
    "connection_method_facebook": "فيسبوك",
    "age_category_1": "<18",
    "age_category_2": "18-24",
    "age_category_3": "25-34",
    "age_category_4": "35-44",
    "age_category_5": "45-54",
    "age_category_6": "55+",
    "all_guest_txt": "جميع الضيوف",
    "new_Guest_txt": "ضيف جديد",
    "connections_txt": "الاتصالات",
    "hotspot": "نقطة اتصال",
    "hotspot_list": "قائمة النقاط الساخنة",
    "add_new_hotspot": "إضافة نقطة اتصال جديدة",
    "hotspot_information": "معلومات عن نقطة الاتصال",
    "edit_details_button": "تعديل التفاصيل",
    "wifi_info_message": "اتصل واستمتع بخدمة WiFi المجانية",
    "connection_message": "رائع، لقد تم توصيلك بـ ",
    "connection_message_suffix": " واي فاي",
    "wifi": "واي فاي",
    "login_to_access": "تسجيل الدخول للحصول على حق الوصول",
    "verification_code": "رمز التحقق",
    "verification_code_message": "الرجاء كتابة رمز التحقق المرسل إلى ",
    "wifi_user_email": "adamo@gmail.com",
    "wifi_label": "واي فاي",
    "your_name": "ما هو اسمك بالمناسبة؟",
    "your_birthdate": "هل يمكنك أن تخبرنا ما هو تاريخ ميلادك؟",
    "request_guest_wifi_name": "الرجاء إدخال اسم شبكة واي فاي الضيف",
    "request_device_key": "الرجاء إدخال مفتاح الجهاز",
    "request_maximum_internet_access_length": "الرجاء تحديد الحد الأقصى لطول مدة الوصول إلى الإنترنت",
    "mac_address": "عنوان MAC",
    "device_port": "منفذ الجهاز",
    "hardware": "الأجهزة",
    "current_uptime": "الوقت الحالي",
    "nearby_devices": "الأجهزة القريبة",
    "firmware": "البرامج الثابتة",
    "who_are_you": "من أنت؟",
    "where_to_contact_you": "أين يمكننا التواصل معك؟",
    "your_area_code": "ما هو رمز منطقتك؟",
    "connected": "متصل",
    "delete_hotspot_message": "سيتم حذف هذه النقطة الساخنة نهائيًا.",
    "delete_multiple_hotspots_message": "سيتم حذف هذه النقاط الساخنة نهائيًا.",
    "speed_error": "يجب أن تكون السرعة 0.01 على الأقل",
    "speed_max_error": "يرجى إدخال قيمة تصل إلى 1024 للحصول على سرعة غير محدودة، أو حدد قيمة أقل ضمن النطاق المدعوم",
    "device_ssid": "معرف SSID للجهاز",
    "device_ssid_two": "معرف جهاز SSID الثاني",
    "device_ssid_two_wpa": "جهاز SSID اثنان WPA",
    "device_key": "مفتاح الجهاز",
    "select_location": "حدد الموقع",
    "select_maximum_internet_access_length": "حدد الحد الأقصى لطول الوصول إلى الإنترنت",
    "download_speed": "سرعة التنزيل",
    "upload_speed": "سرعة التحميل",
    "network_length_1": "15 دقيقة",
    "network_length_2": "30 دقيقة",
    "network_length_3": "45 دقيقة",
    "network_length_4": "ساعة واحدة",
    "network_length_5": "ساعاتين",
    "network_length_6": "4 ساعات",
    "network_length_7": "6 ساعات",
    "network_length_8": "8 ساعات",
    "network_length_9": "10 ساعات",
    "network_length_10": "12 ساعة",
    "employee_wifi_name": "اسم واي فاي الموظف",
    "employee_wifi_password": "كلمة مرور واي فاي الموظف",
    "guest_wifi_name": "اسم واي فاي الضيف",
    "menu_other_products_txt": "منتجات أخرى | القائمة على الإنترنت",
    "menu_home_text": "الصفحة الرئيسية | القائمة على الإنترنت",
    "whatsapp_title": "واتساب",
    "select_campaign_type": "اختر نوع الحملة",
    "select_readymade_templates": "اختر من بين القوالب الجاهزة أو قم بإنشاء قالبك الخاص",
    "campaign_title_required": "عنوان الحملة مطلوب",
    "type_here": "اكتب هنا...",
    "location_permission_req": "مطلوب إذن الموقع",
    "platform_permission_req": "مطلوب إذن المنصة",
    "profile_picture": "الصورة الشخصية",
    "click_to_upload": "انقر للتحميل",
    "location_permission": "إذن الموقع",
    "pin": "دبوس",
    "platform_permission": "إذن المنصة",
    "set_pin": "تعيين رقم التعريف الشخصي (PIN)",
    "list_of_users": "قائمة المستخدمين",
    "create_user": "إنشاء مستخدم",
    "terms_and_condition_req": "الشروط والأحكام مطلوبة",
    "terms_and_conditions": "الشروط والأحكام",
    "recommended": "مُستَحسَن",
    "mo": "\/شهر",
    "unsubscribe": "إلغاء الاشتراك",
    "cancel_subscription": "هل أنت متأكد أنك تريد إلغاء الاشتراك؟",
    "upgrade": "يرقي",
    "plan_upgraded_successfully": "تم ترقية الخطة بنجاح!",
    "menu_online": "القائمة على الإنترنت",
    "support": "يدعم",
    "wifiC": "واي فاي",
    "billing": "الفواتير",
    "please_upgrade": "يرجى الترقية للاستمتاع بجميع الميزات",
    "you_have_subscribed": "لقد اشتركت في ",
    "plan": "يخطط",
    "of_text": "ل",
    "days": "أيام",
    "remaining_days_until_plan": " الأيام المتبقية حتى تتطلب خطتك التحديث",
    "manage_your_screens": "إدارة شاشاتك",
    "screens_you_save": "الشاشات التي لديك",
    "add_more_screens": "إضافة المزيد من الشاشات",
    "addition_screen": "شاشة الإضافة",
    "per_screen": "دولار لكل شاشة",
    "per_box": "دولار لكل صندوق",
    "shipping_changes": "رسوم الشحن",
    "upto4_boxes": "حتى 4 صناديق ",
    "charge_now": "سوف تحصل على الرسوم الآن",
    "updated_reoccuring": "سيتم تحديث تكرارك",
    "current_reoccuring": "يتكرر حاليا: ",
    "no_text": "لا",
    "android_box": "صندوق أندرويد",
    "old_password_req": "مطلوب كلمة المرور القديمة",
    "new_password_req": "مطلوب كلمة مرور جديدة",
    "confirm_password_req": "تأكيد كلمة المرور مطلوب",
    "password_do_not_match": "كلمات المرور غير متطابقة",
    "old_password": "كلمة المرور القديمة",
    "new_password": "كلمة المرور الجديدة",
    "confirm_password": "تأكيد كلمة المرور",
    "copy_working_hours": "نسخ ساعات العمل هذه لجميع الأيام؟",
    "yes_copy": "نعم، نسخ",
    "closed": "مغلق",
    "opening_time": "وقت الافتتاح",
    "closing_time": "وقت الإغلاق",
    "description": "وصف",
    "file_exist": "الملف موجود بالفعل، الرجاء تحديد ملف آخر",
    "bussiness_images": "صور الأعمال",
    "display_info_on_market_place": "هل ترغب في عرض المعلومات أدناه على السوق الخاص بك؟",
    "profile_ready": "ملفك الشخصي جاهز!",
    "client_book_with_online": "يمكن للعملاء الآن الحجز معك عبر الإنترنت. شارك الرابط أدناه للبدء.",
    "copy_link": "نسخ الوصلة",
    "see_your_profile": "انظر ملفك الشخصي",
    "ok_got_it": "حسنا، حصلت عليه",
    "preview_profile": "قم بمعاينة ملفك الشخصي وشاهد كيف سيبدو.",
    "opening_hours": "ساعات العمل",
    "display_data": "عرض البيانات",
    "manage_profile": "إدارة الملف الشخصي",
    "phone_req": "الهاتف مطلوب",
    "market_place_img_req": "صور السوق مطلوبة",
    "add_new_market_place": "إضافة سوق جديد",
    "edit_market_place": "تعديل السوق",
    "no_reviews_yet": "لا توجد مراجعات حتى الآن",
    "good": "جيد",
    "average": "متوسط",
    "add_marketPlace": "إضافة MarketPlace",
    "all_title": "الجميع",
    "included_title": "متضمن",
    "excluded_title": "مستبعد",
    "clients_subscribed": "العملاء المشتركين في الماضي",
    "clients_groups": "مجموعات العملاء",
    "customer_groups": "مجموعات العملاء",
    "audience_title": "جمهور",
    "client_gt5": "من لم يعود في المرة الماضية",
    "select_all": "حدد الكل",
    "modify": "يُعدِّل",
    "campaign_title": "عنوان الحملة",
    "msg_type": "نوع الرسالة",
    "enter_discount": "أدخل الخصم",
    "discount_type": "نوع الخصم",
    "coupon_text": "نص القسيمة",
    "enter_coupon_text": "أدخل نص القسيمة",
    "coupon_button_text": "نص زر القسيمة",
    "enter_coupon_button_text": "أدخل نص زر القسيمة",
    "fine_prin": "الطباعة الدقيقة",
    "enter_fine_prin": "أدخل نصًا مطبوعًا بخط صغير",
    "campaign_dec": "يمكنك تعديل الحملة قبل 30 دقيقة من وقت بدايتها. سيتم تحصيل رسوم حملة الرسائل القصيرة قبل 30 دقيقة من وقت بدء الحملة.",
    "blast_text_message_dec": "أصبحت رسالتك النصية جاهزة. حدد طريقة الدفع وأرسل رسائلك.",
    "payment_completed": " سيتم إكمال الدفع في ",
    "total_cost": "التكلفة الاجمالية",
    "close_title": "يغلق",
    "friday": "جمعة",
    "saturday": "السبت",
    "sunday": "الأحد",
    "thursday": "يوم الخميس",
    "tuesday": "يوم الثلاثاء",
    "wednesday": "الأربعاء",
    "port_txt": "ميناء",
    "today_title": "اليوم",
    "yesterday_title": "أمس",
    "last_30_days_txt": "آخر 30 يوما",
    "this_month_txt": "هذا الشهر",
    "last_month_txt": "الشهر الماضي",
    "valid_date_title": "حدد تاريخًا صالحًا",
    "valid_business_name_txt": "أدخل اسم تجاري صالح",
    "req_bus_add_txt": "عنوان العمل مطلوب",
    "req_domain_name_txt": "اسم المجال مطلوب",
    "basic_info_txt": "معلومات أساسية عن عملك",
    "loyalty_qr_def_txt": "قم بمسح رمز الاستجابة السريعة (QR Code)، وانضم إلى بطاقة الولاء الخاصة بنا، واستمتع بالمكافآت.",
    "last_stamp_txt": "الختم الأخير",
    "collected_on_txt": "تم جمعها على",
    "stamp_details_txt": "تفاصيل الطوابع",
    "add_stamp_txt": "إضافة طابع",
    "choose_brand_color_txt": "اختر لون علامتك التجارية ولغتك 🎨",
    "change_anytime_txt": "يمكنك تغيير هذا في أي وقت",
    "like_to_get_txt": "هل ترغب في الحصول على",
    "your_next_visit_txt": "في زيارتك القادمة؟",
    "time_to_time_get_offers": "هل ترغب في الحصول على عروض وترويجات رائعة من وقت لآخر؟",
    "respond_back_to_you": "هل ترغب في أن يرد عليك أحد؟",
    "input_serve_better": "ستساعد مدخلاتك فريقنا على خدمتك بشكل أفضل!",
    "do_it_txt": "دعونا نفعل ذلك",
    "take_15_s": "يستغرق 15 ثانية",
    "rate_on_google_desc": "هل يمكنك أن تتكرم بتقييمنا على جوجل؟",
    "may_be_next_time": "ربما في المرة القادمة",
    "thank_you_txt": "شكرًا لك",
    "look_next_txt": "نحن نتطلع إلى زيارتك القادمة!",
    "deleteUserTitle": "سيتم حذف هذا المستخدم نهائيًا.",
    "deleteMUserTitle": "سيتم حذف هؤلاء المستخدمين نهائيًا.",
    "change_pin": "تغيير الدبوس",
    "area_code": "ما هو رمز منطقتك؟ ",
    "add_menu": "إضافة القائمة",
    "menu_name": "اسم القائمة",
    "add_menu_placeholder": "بيتزا، برجر، مشروبات، الخ",
    "enable_data_Collection": "تمكين جمع البيانات",
    "add_new_menu": "إضافة قائمة جديدة",
    "rename_menu": "إعادة تسمية القائمة",
    "preview": "معاينة",
    "generate_qr": "تم إنشاء رمز الاستجابة السريعة",
    "edit_menu": "تحرير القائمة",
    "remove_menu": "إزالة القائمة",
    "menu_delete_msg": "سيتم حذف هذه القائمة نهائيًا",
    "menus_delete_msg": "سيتم حذف هذه القائمة نهائيًا",
    "view_menu_dialoage_msg": "هل ترغب في الحصول على هدية في عيد ميلادك؟",
    "skip": "يتخطى",
    "cliam_your_gift": "اطلب هديتك",
    "collect_form": "جمع النموذج",
    "enter_first_name": "أدخل الاسم الأول",
    "enter_last_name": "أدخل الاسم الأخير",
    "enter_email": "أدخل البريد الإلكتروني",
    "enter_dob": "أدخل تاريخ الميلاد",
    "enter_number": "أدخل الرقم",
    "select_gender": "حدد الجنس",
    "congratulations_desc": "🎉 تهانينا! لقد تم تقديم طلب الحصول على الهدية بنجاح. سيتواصل فريقنا معك قريبًا. 🎉",
    "male_heading": "ذكر",
    "male_text": "ذكر",
    "female_heading": "أنثى",
    "female_text": "أنثى",
    "others_heading": "آحرون",
    "other_text": "آحرون",
    "BirthD": "تاريخ الميلاد",
    "GenderT": "جنس",
    "EmailT": "بريد إلكتروني",
    "dobT": "تاريخ الميلاد",
    "capitalize_menu": "قائمة طعام",
    "select_menu": "اختر القائمة",
    "manage_variant": "إدارة المتغير",
    "add_products": "إضافة المنتجات",
    "add_category": "إضافة فئة",
    "category_delete": "سيتم حذف هذه الفئة نهائيا",
    "variation_delete": "سيتم حذف هذا الإصدار من المنتج.",
    "product_delete": "سيتم حذف هذا المنتج نهائيًا.",
    "categories_delete": "سيتم حذف هذه الفئات نهائيًا.",
    "products_delete": "سيتم حذف هذا المنتج نهائيًا.",
    "category": "فئة",
    "price": "سعر",
    "food_product_placeholder": "بيتزا، برجر، مشروبات، الخ",
    "active_title": "نشيط",
    "inActive_title": "غير نشط",
    "status_capital": "حالة",
    "cat_status_require": "الحالة الفئة مطلوبة",
    "cat_name_require": "اسم الفئة مطلوب",
    "category_name": "اسم الفئة",
    "status": "حالة",
    "lgDay1": "الاثنين",
    "lgDay2": "يوم الثلاثاء",
    "lgDay3": "الأربعاء",
    "lgDay4": "يوم الخميس",
    "lgDay5": "جمعة",
    "lgDay6": "السبت",
    "lgDay7": "الأحد",
    "is_closed_title": "مغلق",
    "book_table_title": "احجز طاولة",
    "emailErrSub": "موضوع البريد الإلكتروني مطلوب",
    "email_subject": "موضوع البريد الإلكتروني",
    "contactUsfrmTitleEmail": "الرد على البريد الإلكتروني",
    "companyInfo": "لوريم إيبسوم هو لوريم حريج، لوريم إيبسوم فريتس.لوريم إيبسوم هو لوريم حريج، لوريم إيبسوم هو فريتس.لوريم إيبسوم هو لوريم حريج، لوريم إيبسوم هو فريتس.",
    "footerSTitle1": "حول منيو اون لاين",
    "footerSTitle1Lnk2": "سمات",
    "footerSTitle1Lnk3": "مدونة",
    "footerSTitle2": "قانوني",
    "footerSTitle2Lnk1": "الشروط والأحكام",
    "footerSTitle2Lnk2": "سياسة الخصوصية",
    "footerSTitle3": "للأعمال",
    "footerSTitle3Lnk1": "للشركاء",
    "footerSTitle3Lnk2": "التسعير",
    "footerSTitle3Lnk3": "دعم الشركاء",
    "footerCopyRithgTxt": "  Menuonline أو الشركات التابعة لها",
    "homeTitle": "بيت",
    "contactTitle": "اتصل بنا",
    "aboutTitle": "معلومات عنا",
    "homeMenuTitle": "قائمة طعام",
    "bookTableTitle": "احجز طاولة",
    "vDateT": "حدد تاريخًا صالحًا",
    "reqDateT": "التاريخ مطلوب",
    "dateGtErr": "يجب أن يكون التاريخ أحدث من تاريخ اليوم أو مساويًا له",
    "timeInvalid": "الوقت غير صالح",
    "reqTimeT": "الوقت مطلوب",
    "timeInvalidBfr": "حدد الوقت على الأقل 5 دقائق لاحقًا من الوقت الحالي ",
    "PerReq": "عدد الأشخاص مطلوب",
    "validcNoT": "أدخل رقم اتصال صالح",
    "reqCrT": "العملة مطلوبة",
    "reqPrT": "السعر مطلوب",
    "reCateT": "الفئة مطلوبة",
    "reqVrT": "المتغير مطلوب",
    "reqVrVldT": "أدخل متغير صالح ",
    "validLNameT": "أدخل اسم العائلة الصحيح",
    "sDateInValid": "من التاريخ غير صالح",
    "minmReq": "الحد الأدنى مطلوب",
    "resLblDate": "حدد التاريخ",
    "resLblTime": "حدد الوقت",
    "perT": "لعدد كم شخص؟",
    "resLblEmail": "يرجى تقديم بريدك الإلكتروني؟",
    "resLblNote": "أضف ملاحظة الحجز",
    "imageError1MB": "الرجاء إدخال صورة أقل من 1 ميجا بايت",
    "imageError": "الرجاء إدخال صورة أقل من 2.5 ميجا بايت",
    "imageError3Mb": "الرجاء إدخال صورة أقل من 3 ميجا بايت",
    "imageInVldError": "الملف غير صالح.",
    "addProMT": "قائمة المنتجات",
    "proT": "اسم المنتج",
    "reqProT": "اسم المنتج مطلوب",
    "proPT": "سعر المنتج",
    "reservationSuccTitle": "رائع، تم الانتهاء من حجزك",
    "book": "كتاب",
    "custzName": "اسم التخصيص",
    "always": "دائماً",
    "proImgReq": "صورة المنتج مطلوبة",
    "selCustmT": "الرجاء تحديد التخصيص أو إنشاء جديد",
    "visStR": "مطلوب حالة الرؤية",
    "avlblScR": "الرجاء تحديد جدول التوفر",
    "addonPR": "الرجاء تحديد المنتجات الإضافية",
    "upsellPR": "الرجاء اختيار المنتجات الإضافية",
    "markItemR": "الرجاء تحديد منتجات العلامة",
    "caloriesR": "السعرات الحرارية مطلوبة",
    "allergensR": "الرجاء اختيار المنتجات المسببة للحساسية",
    "prepInstR": "تعليمات التحضير مطلوبة",
    "staffNR": "ملاحظات الموظفين مطلوبة",
    "discountR": "يجب الحصول على خصم",
    "validDiscE": "أدخل خصمًا صالحًا",
    "disday": "باليوم",
    "plSelDayT": "الرجاء تحديد اليوم",
    "sTimeReq": "مطلوب وقت البدء",
    "sTimeInValid": "وقت البدء غير صالح",
    "eTimeReq": "يجب تحديد وقت النهاية",
    "eTimeInValid": "وقت النهاية غير صالح",
    "sDateReq": "من التاريخ مطلوب",
    "eDateReq": "مطلوب حتى الآن",
    "eDateInValid": "حتى الآن غير صالح",
    "disdate": "من التاريخ",
    "disdate1": "حتى الآن",
    "disdate2": "من تاريخ",
    "currT": "عملة",
    "iconR": "الرجاء تحديد رمز العنصر",
    "minT": "الحد الأدنى",
    "maxT": "الحد الأقصى",
    "itemNT": "اسم العنصر",
    "itemPT": "سعر السلعة",
    "descProT": "وصف منتجك",
    "cateT": "فئة",
    "selProCateT": "حدد فئة المنتج الخاص بك",
    "reqMT": "اسم القائمة مطلوب",
    "ViewMenu": "عرض القائمة",
    "CopyMenu": "نسخ القائمة",
    "EditMenu": "تحرير القائمة",
    "RemoveMenu": "إزالة القائمة",
    "welcomeMnuTitle": "مرحبا بكم في",
    "reviewT": "المراجعات",
    "userErrorMsg": "عذرا، حدث خطأ !!!",
    "reqCateT": "اسم الفئة مطلوب",
    "mngCustT": "إدارة التخصيص",
    "custNReq": "اسم التخصيص مطلوب",
    "incReq": "مطلوب تضمينه",
    "minmValid": "أدخل الحد الأدنى للقيمة الصالحة",
    "maxmReq": "الحد الأقصى مطلوب",
    "cust": "التخصيص",
    "crCust": "إنشاء تخصيص جديد",
    "custList": "قائمة التخصيص",
    "delCustmzT": "سيتم حذف هذا التخصيص نهائيًا.",
    "mkNameR": "يجب أن يكون اسم العنصر مطلوبًا",
    "mkDelT": "سيتم حذف هذا العنصر نهائيًا.",
    "hideText": "يخفي",
    "showText": "يعرض",
    "device_mac_txt": "جهاز ماك",
    "delete_hotspot_txt": "سيتم حذف هذه النقطة الفعالة نهائيًا.",
    "delete_hotspots_txt": "سيتم حذف هذه النقاط الساخنة نهائيًا.",
    "emp_wifi_name": "اسم واي فاي الموظف",
    "max_limit_txt": "أدخل \"1024\" للسرعة لتعيينها على أنها غير محدودة. تعمل هذه القيمة على إزالة أي حدود، مما يسمح بأقصى إنتاجية",
    "device_port_txt": "منفذ الجهاز",
    "firmware_txt": "البرامج الثابتة",
    "hotspot_info_txt": "معلومات عن نقطة الاتصال",
    "editDBtn": "تعديل التفاصيل",
    "birth_date": "تاريخ الميلاد",
    "di_theme_clr": "لون الموضوع هو بالضبط 7 أرقام",
    "color_Invalid_txt": "حدد رمز اللون الصحيح",
    "Req_theme_clr": "لون الموضوع مطلوب",
    "select_valid_color_txt": "الرجاء تحديد لون الموضوع الصحيح",
    "req_redir_link": "مطلوب رابط إعادة التوجيه",
    "val_redir_link": "أدخل رابط إعادة التوجيه الصحيح",
    "req_business_name_txt": "الاسم التجاري مطلوب",
    "splash_preview": "معاينة البداية",
    "create_new_splash": "إنشاء نقطة إنطلاق جديدة",
    "splash_page": "صفحة البداية",
    "splash_page_builder": "منشئ صفحات البداية",
    "redirect_link": "رابط إعادة التوجيه",
    "theme_color": "لون الموضوع",
    "enable_social_login": "تمكين خيارات الوسائط الاجتماعية لتسجيل الدخول للمستخدمين",
    "google": "جوجل",
    "facebook": "فيسبوك",
    "is_mandatory": "هل هو إلزامي؟",
    "field": "مجال",
    "name": "اسم",
    "first_name": "الاسم الأول",
    "last_name": "اسم العائلة",
    "birthdate": "تاريخ الميلاد",
    "gender": "جنس",
    "email": "بريد إلكتروني",
    "dob": "تاريخ الميلاد",
    "zip_code": "الرمز البريدي",
    "required_redirect_link": "مطلوب رابط إعادة التوجيه",
    "valid_redirect_link": "أدخل رابط إعادة التوجيه الصحيح",
    "required_theme_color": "لون الموضوع مطلوب",
    "theme_color_length": "لون الموضوع هو بالضبط 7 أرقام",
    "required_name": "الاسم مطلوب",
    "delete_splash_message": "هل تريد حذف هذه البقعة؟",
    "delete_multiple_splashes_message": "هل تريد حذف هذه البقع؟",
    "user_login_required": "مطلوب تسجيل دخول المستخدم",
    "set_theme_color": "تعيين لون السمة",
    "splash_colllect_additional_info_txt": "جمع معلومات إضافية",
    "verify_mobile_otp_message": "هل ترغب في إرسال OTP للتحقق من رقم الهاتف المحمول؟",
    "add_company": "إضافة شركة",
    "edit_company": "تعديل الشركة",
    "ds_device": "جهاز الإشارات الرقمية",
    "ds_device_activation_fee": "رسوم تفعيل جهاز اللافتات الرقمية",
    "wifi_hardware": "أجهزة واي فاي",
    "menu_design": "تصميم القائمة",
    "ds_hardware": "أجهزة اللافتات الرقمية",
    "company_name_req": "اسم الشركة مطلوب",
    "known_name_req": "الاسم المعروف مطلوب",
    "no_location_req": "رقم الموقع المطلوب",
    "minimum_location": "الرجاء إدخال موقع واحد على الأقل",
    "terms_req": "الشروط مطلوبة",
    "notes_req": "ملاحظات مطلوبة",
    "sms_charge_req": "رسوم الرسائل القصيرة مطلوبة",
    "menuonline_quantity_req": "القائمة عبر الإنترنت الكمية المطلوبة",
    "menuonline_cost_req": "القائمة عبر الإنترنت مطلوبة التكلفة",
    "ds_quantity_req": "مطلوب كمية اللافتات الرقمية",
    "ds_monthly_cost_req": "مطلوب تكلفة شهرية للإشارات الرقمية",
    "reputation_quantity_req": "السمعة الكمية مطلوبة",
    "reputation_cost_req": "تكلفة السمعة مطلوبة",
    "marketing_quantity_req": "مطلوب كمية التسويق",
    "marketing_cost_req": "تكلفة التسويق مطلوبة",
    "tax_percentage_req": "يجب تحديد نسبة الضريبة",
    "router_hardware_quantity_req": "مطلوب كمية أجهزة التوجيه",
    "wifi_hardware_cost_req": "مطلوب تكلفة أجهزة الواي فاي",
    "activation_cost_req": "مطلوب تكلفة التنشيط",
    "wifi_quantity_req": "مطلوب كمية واي فاي",
    "wifi_router_req": "مطلوب جهاز توجيه واي فاي",
    "menu_design_quantity_req": "تصميم القائمة الكمية المطلوبة",
    "menu_design_cost_req": "مطلوب تكلفة تصميم القائمة",
    "quantity_req": "الكمية المطلوبة",
    "cost_req": "التكلفة مطلوبة",
    "sheduledown_quantity_req": "الجدول الزمني للكمية المطلوبة",
    "sheduledown_cost_req": "مطلوب جدولة التكلفة",
    "loyalty_program_quantity_req": "برنامج الولاء الكمية المطلوبة",
    "loyalty_program_cost_req": "تكلفة برنامج الولاء مطلوبة",
    "loyalty_program_sms_quantity_req": "عدد رسائل SMS الخاصة ببرنامج الولاء مطلوب",
    "loyalty_program_sms_cost_req": "تكلفة الرسائل القصيرة لبرنامج الولاء مطلوبة",
    "comapny_created": "تم إنشاء الشركة بنجاح",
    "comapny_updated": "تم تحديث الشركة بنجاح",
    "billing_info": "معلومات الفواتير",
    "business_name_acc_holder": "اسم العمل (صاحب الحساب)",
    "client_first_name": "الاسم الأول للعميل",
    "client_last_name": "اسم العميل الأخير",
    "dba_known_as": "DBA معروف باسم",
    "business_address": "عنوان العمل",
    "no_of_locations": "عدد المواقع",
    "package_details": "تفاصيل الحزمة",
    "terms": "شروط",
    "one_month": "شهر واحد",
    "per_sms_charge": "رسوم لكل رسالة نصية قصيرة",
    "plan_test": "الخطة : اختبار",
    "desc_capital": "وصف",
    "qty_capital": "الكمية",
    "cost_capital": "يكلف",
    "total_price_capital": "السعر الاجمالي",
    "quantity": "كمية",
    "can_not_access": "لا يمكنك تحديد أكثر من",
    "licenses": "التراخيص",
    "monthly_cost": "التكلفة الشهرية",
    "plan_cost": "تكلفة الخطة",
    "tax": "ضريبة",
    "tax_percentage": "نسبة الضريبة",
    "monthly_cost_after_tax": "التكلفة الشهرية بعد الضريبة",
    "activation_one_time_charge": "التفعيل والدفع مرة واحدة",
    "sub_total": "المجموع الفرعي",
    "action": "فعل",
    "cost": "يكلف",
    "shipping_charge": "رسوم الشحن",
    "other": "آخر",
    "additional_cost": "تكلفة إضافية (رسوم أخرى)",
    "other_tax": "ضريبة أخرى",
    "total": "المجموع",
    "license_statistics": "إحصائيات الترخيص",
    "total_licenses": "مجموع التراخيص",
    "available_licenses": "التراخيص المتاحة",
    "stock": "مخزون",
    "has_permission": "لديه إذن",
    "avg_stock_price": "متوسط سعر السهم",
    "average_price": "متوسط السعر",
    "allocated": "مُخصصة",
    "reward_regulars": "مكافأة العملاء المنتظمين",
    "not_add_coupon_url": "لا يمكن إضافة عنوان URL للقسيمة. الحد الأقصى لطول الرسالة هو 160",
    "like_attach_coupon": "هل ترغب في إرفاق قسيمة؟",
    "advance_scheduling": "الجدولة المسبقة",
    "choose_day": "اختر يومًا(أيامًا) لإرسال الرسالة",
    "select_essage_window": "حدد نافذة الرسالة",
    "subscription_outside_delivery_window": "إذا اشترك المستخدم خارج فترة التسليم، فسوف يتلقى الرد التلقائي الخاص بك في الوقت المتاح التالي.",
    "remaining": "متبقي",
    "processing_request": "حدث خطأ أثناء معالجة طلبك.",
    "list_companies": "قائمة الشركات",
    "are_you_sure": "هل أنت متأكد..؟",
    "signin_agreement": "ومن خلال تأكيد ذلك فإن الشركة ستقوم بالإنشاء دون توقيع اتفاقية.",
    "status_updated": "تم تحديث الحالة بنجاح",
    "status_failed": "فشل في تحديث الحالة",
    "new_editor_status_updated": "تم تحديث حالة DS New Editor بنجاح.",
    "user_name": "اسم المستخدم",
    "known_as": "معروف باسم",
    "agreement_signed": "تم توقيع الاتفاقية",
    "deactivate": "إلغاء التنشيط",
    "activate": "فعل",
    "login_to_user_acc": "تسجيل الدخول إلى حساب المستخدم",
    "disable": "إبطال",
    "enable": "يُمكَِن",
    "ds_new_editor": "محرر جديد لـ DS",
    "sign_agreement": "توقيع الاتفاقية",
    "agreement_sign": "اتفاقية بدون توقيع",
    "view_agreement": "عرض الاتفاقية",
    "download_agreement": "تنزيل الاتفاقية",
    "add_deleted_user": "إضافة المستخدم المحذوف",
    "deleted_user": "حذف المستخدم",
    "favourite": "مفضل",
    "refresh": "ينعش",
    "delete_chat": "حذف الدردشة",
    "responsive_drawer": "درج متجاوب",
    "delete_contact": "حذف جهة الاتصال",
    "clear_chat_history": "مسح سجل الدردشة",
    "clear_chat_history_dec": "هل أنت متأكد أنك تريد حذف هذه الدردشة؟",
    "clear_contact_dec": "هل أنت متأكد أنك تريد حذف جهة الاتصال هذه؟",
    "select_contact": "حدد جهة الاتصال",
    "new_conversation": "ابدأ محادثة جديدة",
    "type_msg": "اكتب رسالتك هنا",
    "select_delete_client": "الرجاء تحديد العميل الذي تريد حذفه",
    "select_delete_client_chat": "الرجاء تحديد العميل لحذف الدردشة",
    "select_delete_client_chat_err": "ليس لديك محادثة مع هذا العميل",
    "acquire_customers_business": "الحصول على عملاء جدد من شركات أخرى.",
    "customers_from_events": "احصل على العملاء من الأحداث وأحضرها إليك!",
    "customers_from_ads": "احصل على العملاء من إعلانات الصحف.",
    "smart_pamphlets": "إنشاء كتيبات ذكية لجمع بيانات العملاء.",
    "smart_qr_group": "رمز الاستجابة السريعة الذكي للانضمام إلى مجموعة للعملاء.",
    "opt_in_number": "تمكين العملاء من اختيار الرقم.",
    "qr_menu": "قم بإنشاء رمز QR لعرض القائمة الخاصة بك.",
    "collect_birthdays": "جمع أعياد ميلاد العميل والذكرى السنوية.",
    "join_loyalty_program": "اجذب عملاء جدد للانضمام إلى برنامج الولاء الخاص بي.",
    "create_feedback_forms": "إنشاء نماذج لجمع تعليقات العملاء.",
    "exclusive_discounts_promotions": "تقديم خصومات وعروض ترويجية حصرية للعروض الترويجية المتبادلة.",
    "group_smart_qr_opt_in": "بعد إنشاء مجموعة، يمكنك إنشاء رمز QR ذكي وتحديده، مما يتيح للعملاء الاشتراك فورًا في مجموعة",
    "group_smart_qr_opt_in_dec": "بعد إنشاء مجموعة، تتيح أداة الصور للعملاء الاشتراك باستخدام النص وتلقي العروض الترويجية، على سبيل المثال، إرسال رسالة نصية \"بيتزا\" إلى 555-555-5555",
    "in_store_discount_next_visit": "احصل على خصم داخل المتجر خلال زيارتك القادمة لموقعنا",
    "delivery_redirection": "إنشاء إعادة توجيه التسليم",
    "additional_info": "معلومات إضافية",
    "add_url": "إضافة عنوان URL",
    "custom_fields": "الحقول المخصصة",
    "meta_tag": "العلامة التعريفية",
    "max_chars": "الحد الأقصى للأحرف",
    "add": "يضيف",
    "update_campaign": "تحديث الحملة",
    "last_week_required": "الاسبوع الماضي مطلوب",
    "large_week_value": "قيمة الأسبوع كبيرة جدًا، أدخل أسبوعًا صالحًا",
    "less_than_last_week": "عدم العودة في الأسبوع الماضي يجب أن يكون أقل من الأسبوع الماضي.",
    "last_week_req": "لا يجب العودة في الأسبوع الماضي",
    "birthday_special": "عيد ميلاد خاص",
    "birthday_message": "إرسال رسالة آلية في أعياد ميلادهم مع تحيات شخصية وعروض مغرية",
    "birthday_sms": "إنشاء تحيات عيد ميلاد مخصصة للرسائل القصيرة\/الرسائل المتعددة الوسائط",
    "target_audience": "اختر الجمهور المستهدف",
    "client_by_birthday": "العميل حسب عيد الميلاد",
    "clients_once_year": "يتم إرسال هذه الحملة تلقائيًا إلى العملاء مرة واحدة في السنة ",
    "auto_responder_default_msg_1": "أنت أحد أفضل عملائنا الذين نحب أن نمنحهم خصمًا! أتمنى لك دائمًا الثروة والصحة. عيد ميلاد سعيد! %% الاسم الأول %%",
    "campaign_send_to_client_birthday": "ترسل هذه الحملة التلقائية رسائل إلى العملاء الذين لديهم أعياد ميلاد، ويمكنك تخصيص الاستهداف أدناه.",
    "get_more_engagement": "احصل على مزيد من التفاعل من خلال إرسال رسائل نصية قصيرة بمناسبة عيد ميلادك 🎂",
    "welcome_new_clients": "مرحبا بالعملاء الجدد",
    "make_lasting_impression": "اترك انطباعًا دائمًا من خلال الترحيب الحار والترحيب بالضيوف الجدد",
    "personalized_greetings": "إنشاء تحيات مخصصة للعملاء الجدد",
    "greetings_to_new_customers": "سترسل هذه الحملة تحيات ترحيبية للعملاء الجدد في اليوم التالي لتفاعلهم الأول مع عملك",
    "audience_predefined": "تم تحديد جمهور هذه الإجابة التلقائية مسبقًا، لقد قمنا بذلك!",
    "send_clients_subscribed": "تم ضبط هذا الرد التلقائي لإرسال العملاء المشتركين في آخر ",
    "first_interaction": "  بعد تفاعلهم الأول.",
    "default_msg_2": "نأمل أن تكون قد استمتعت بزيارتك الأولى، ونتمنى أن نراك مرة أخرى قريبًا، لذا نقدم لك خصمًا خاصًا! انقر فوق الرابط لحجز موعدك التالي معنا والاستفادة من عرضك الخاص عبر الإنترنت الآن.",
    "new_clients_update": "مرحبًا بالعملاء الجدد تحديث نص الحملة",
    "new_clients_warm_greeting": "نرحب بالعملاء الجدد بتحية حارة 💌",
    "win_back_clients": "استعادة العملاء",
    "re_engage_past_customers": "إعادة جذب العملاء السابقين بعروض لا تقاوم لتشجيعهم على العودة",
    "target_disengaged_clients": "استهدف العملاء غير المتفاعلين لتشجيعهم على العودة بعرض خاص، وهي تكتيك أثبت كفاءته لجذب العملاء مرة أخرى.",
    "campaign_sends_to_clients": "هذه الحملة ترسل للعملاء الذين لم يعودوا بعد فترة زمنية معينة.",
    "customize_targeting": "قم بتخصيص الاستهداف أدناه لإرسال رسائل نصية قصيرة\/رسائل وسائط متعددة إلى العملاء الذين انقطعت اتصالاتهم.",
    "clients_with_at_least": "العملاء مع ما لا يقل عن ",
    "connection_last": " اتصال في الاخير ",
    "return_in_last_week": " اسبوع ولكن لم اعد في الاسبوع الماضي ",
    "arr_menu_44": " أسبوع",
    "df_msg_4": "نحن نريدك بشدة أن تعود إلينا، لذا سنمنحك خصمًا بنسبة 10% لمجرد إرجاعك! هل تريد أن تجربنا مرة أخرى اليوم؟",
    "update_campaign_text": "استعادة العملاء تحديث نص الحملة",
    "re_invite_customers": "إعادة دعوة العملاء الذين لم يعودوا منذ فترة",
    "loyal_patrons": "أظهر تقديرك للعملاء المخلصين من خلال الخصومات الخاصة ومكافآت الولاء",
    "surprize_top_spenders": "فاجئ أكبر المنفقين بعروض خاصة ومخصصة.",
    "campaign_automatically_send": "تقوم هذه الحملة بإرسال مكافآت تلقائيًا لتعزيز إقبال العملاء على عملك.",
    "sms_loyal_clients": "ترسل هذه الحملة رسائل نصية قصيرة\/رسائل وسائط متعددة إلى العملاء المخلصين، ويمكنك تغيير الاستهداف أدناه.",
    "or_more_connection": " أو أكثر من اتصال، في غضون آخر ",
    "week_period": " فترة الاسبوع.",
    "default_msg_5": "لقد كنت عميلاً لدينا منذ فترة طويلة! يا إلهي، الوقت يمر بسرعة! نحن نقدر لك ذلك ونريد أن نشكرك على تواجدك معنا.",
    "reward_regulars_update": "نص حملة تحديث مكافآت العملاء المنتظمين",
    "special_discounts": "تقدير وإعطاء خصومات خاصة💎",
    "leave_positive_online": "شجع العملاء على ترك تعليقات إيجابية عبر الإنترنت لتعزيز سمعة المطعم",
    "clients_service_online": "تشجع الحملة عملائك على تقييم خدمتك عبر الإنترنت.",
    "increase_your_company": "يتم إرسال هذه الإجابة التلقائية لزيادة تصنيف شركتك عبر الإنترنت.",
    "send_message_after": "إرسال رسالة بعد ",
    "min_connection": " دقيقة من الاتصال",
    "default_msg_6": "نأمل أن تكون قد استمتعت بزيارتك الأولى، ويسعدنا رؤيتك مرة أخرى قريبًا! [URL] انقر على الرابط لتقييمنا على Google.",
    "ratings_update": "احصل على المزيد من نص حملة تحديث التقييمات",
    "friendly_nudge": "احصل على المزيد من المراجعات من خلال التذكير الودي ⭐",
    "thanks_for_visiting": "شكرا لزيارتك",
    "express_gratitude": "عبر عن امتنانك للعملاء لاختيارهم عملك من خلال رسائل الشكر الصادقة",
    "thank_you_message": "إنشاء رسالة شكر لعملائك.",
    "clients_for_visiting": "أرسل شكرًا جزيلاً إلى عملائك لزيارة مكانك.",
    "campaign_audience": "تم تحديد جمهور الحملة التلقائية مسبقًا، وقد حصلنا عليه!",
    "campaign_automatically_sends": "ترسل هذه الحملة التلقائية تلقائيًا بعد ",
    "minutes_customer_visit": " دقائق زيارة العميل",
    "default_msg_7": "نشكرك على كونك أحد عملائنا المميزين. نحن ممتنون جدًا لسرورنا بخدمتك ونأمل أن نكون قد استوفينا توقعاتك.",
    "visiting_update_campaign": "شكرا لزيارتك تحديث نص الحملة",
    "guests_thank_you": "اشكر ضيوفك على زيارتهم 😊",
    "download_title": "تحميل",
    "qr_gen_title": "تم إنشاء رمز الاستجابة السريعة",
    "qr_download_s_title": "تم تنزيل رمز الاستجابة السريعة بنجاح",
    "sel_dark_brand_clr_msg": "الرجاء تحديد لون الحظيرة الصالح",
    "manage_customer_stamp_rewards": "إدارة مكافآت طوابع العملاء 🎁",
    "sel_loc_menu_title": "حدد المواقع لقائمتك",
    "ans_req": "الإجابة مطلوبة",
    "valid_reputation_name": "أدخل اسم سمعة صالحًا",
    "reviews_name_req": "احصل على المزيد من المراجعات الاسم مطلوب",
    "birthdate_required": "تاريخ الميلاد مطلوب",
    "gender_required": "الجنس مطلوب",
    "valid_birthdate_required": "أدخل تاريخ ميلاد صحيح",
    "custom_delivery_redirection": "إنشاء نموذج إعادة توجيه تسليم مخصص حسب المجموعات",
    "customer_fields_incentive_settings": "حقول العملاء وإعدادات الحوافز",
    "delivery_redirection_text": "إعادة توجيه التسليم",
    "delivery_redirection_success": "مبروك! لقد تم إنشاء أداة إعادة توجيه التسليم الخاصة بك! 🎉",
    "swipe_to_preview_redirection": "مرر لرؤية كيف سيبدو نموذج إعادة توجيه التسليم الخاص بك",
    "enter_item_name": "أدخل اسم العنصر",
    "home_page_text": "نص الصفحة الرئيسية",
    "settings": "إعدادات",
    "anniversary_required": "تاريخ الذكرى السنوية مطلوب",
    "valid_anniversary": "أدخل تاريخ الذكرى السنوية الصحيح",
    "form_submited": "تم إرسال النموذج بنجاح",
    "notifications": "إشعارات",
    "discount_message": "احصل على خصم 20% على البيتزا الآن!",
    "is_required": " مطلوب",
    "section_title_required": "عنوان القسم مطلوب",
    "section_dec_required": "وصف القسم مطلوب",
    "client_details_required": "حقول تفاصيل العميل مطلوبة",
    "compliance": "امتثال",
    "SMS_campaign1": "حملة الرسائل القصيرة 1",
    "mobile_number_mandatory": "رقم الجوال إلزامي",
    "new_answer": "إجابة جديدة",
    "new_question": "سؤال جديد",
    "add_new_question": "أضف إجابة جديدة",
    "select": "يختار",
    "group_customers_question": "أين تريد تجميع عملائك؟",
    "contacts_added_to_group": "سيتم إضافة جميع جهات الاتصال التي ترسل النموذج إلى المجموعة المحددة",
    "edit_client_details_section": "قسم تعديل تفاصيل العميل",
    "client_details_fields": "حقول تفاصيل العميل",
    "enter_client_details_section_title_desc": "أدخل عنوان ووصف قسم تفاصيل العميل",
    "choose_fields_displayed_client_side": "اختر الحقول التي سيتم عرضها على جانب العميل",
    "section_title": "عنوان القسم",
    "add_title": "أضف عنوان",
    "section_description": "وصف القسم",
    "add_description": "أضف وصفًا",
    "enter_program_name_location": "أدخل اسم البرنامج والموقع 📍",
    "brand_theme_clr_txt": "لون موضوع العلامة التجارية",
    "set_loyalty_punch_card_theme": "دعنا نحدد موضوعًا لبطاقة الولاء الخاصة بك 🎨",
    "upload_logo_txt": "قم بتحميل شعارك",
    "recommended_image_specs": "نوصي باستخدام صورة بحجم 512 × 512 بكسل للحصول على أفضل عرض. JPG أو SVG أو PNG. الحد الأقصى للحجم 10 ميجا بايت.",
    "valid_rep_name_txt": "أدخل اسم سمعة صالحًا",
    "rep_name_req_txt": "احصل على المزيد من المراجعات الاسم مطلوب",
    "que_req": "السؤال مطلوب",
    "day_of_birthday": "في يوم عيد ميلادي",
    "day_before_birthday": "قبل عيد الميلاد بثلاثة أيام",
    "week_before_birthday": "قبل عيد الميلاد باسبوع",
    "two_week_before_birthday": "قبل اسبوعين من عيد الميلاد",
    "de_active": "إلغاء التنشيط",
    "campaign_details": "تفاصيل الحملة",
    "link_clicked": "تم النقر على الرابط",
    "history": "تاريخ",
    "auto_responder_summary": "ملخص الرد التلقائي",
    "vsop_1": "15 دقيقة",
    "vsop_2": "30 دقيقة",
    "vsop_3": "45 دقيقة",
    "vsop_4": "60 دقيقة",
    "vsop_5": "90 دقيقة",
    "vsop_6": "120 دقيقة",
    "in_the_last": " في الاخير ",
    "return_in_last": " ولكن لم يعود في المرة الأخيرة ",
    "of_the_connection": "من الاتصال",
    "your_campaign": "حملتك",
    "you_have_successfully": "لقد نجحت",
    "published_successfully": "تم النشر بنجاح",
    "updated_smart_campaign": "تم تحديث الحملة الذكية",
    "almost_done_text": "تم الانتهاء تقريبا!",
    "update_campaign_desc": "لقد اكتملت ميزة الرد التلقائي، وبمجرد تمكينها، ستُرسل الرسائل إلى العملاء بشكل مستمر. ويمكن تعديل هذه الحملة أو إيقافها مؤقتًا في أي وقت بسهولة.",
    "update_and_publish": "التحديث والنشر",
    "reset_campaign_title": "سيؤدي ذلك إلى حذف النص المخصص لحملتك والصورة وعروض الخصم والجمهور المستهدف بشكل دائم!",
    "space_wifi": " واي فاي",
    "custom_forms": "نماذج مخصصة",
    "web_e_signup": "التسجيل الإلكتروني على الويب",
    "import": "يستورد",
    "permissions_req": "مطلوب الأذونات",
    "redeemed": "مُفتدى",
    "coupon_already_redeemed": "تم استرداد القسيمة بالفعل",
    "autoresponder_campaigns": "حملات الرد التلقائي",
    "autoresponder_campaign_desc": "قم بتمكين الرد التلقائي وإطلاق الحملة المخصصة",
    "mon": "الاثنين",
    "tue": "الثلاثاء",
    "wed": "تزوج",
    "thu": "الخميس",
    "fri": "الجمعة",
    "sat": "قعد",
    "sun": "شمس",
    "duplicate": "ينسخ",
    "visibility": "الرؤية",
    "availability": "التوفر",
    "out_stok": "خارج المخزون",
    "edit_product": "تعديل المنتج",
    "create_product": "إنشاء منتج",
    "basic_info": "معلومات اساسية",
    "basic_sub_info": "لوريم إيبسوم هو ببساطة نص وهمي مطبوع...",
    "enter_product_price": "أدخل سعر المنتج",
    "upload_image": "تحميل الصورة",
    "allowed_file_formats": "يُسمح فقط بملفات JPG و PNG بحجم أقصى 3 ميجا بايت.",
    "pre_select": "الاختيار المسبق",
    "promotions": "العروض الترويجية",
    "discount_availability": "توفر الخصم",
    "start_time": "وقت البدء",
    "end_time": "وقت النهاية",
    "select_day": "حدد اليوم",
    "menu_required": "القائمة مطلوبة",
    "generated": "تم توليده",
    "link": "وصلة",
    "variations": "الاختلافات",
    "select_variant_to_add": "حدد المتغير الذي ترغب في إضافته",
    "price_title": "سعر",
    "choose_image": "اختر الصورة",
    "select_visiblity": "حدد الرؤية",
    "availability_schedule": "جدول التوفر",
    "add_on_upsell": "إضافة \/ بيع إضافي",
    "add_on_select_product": "أضف منتجًا محددًا",
    "upsell_product": "بيع منتج محدد",
    "variant_deletion_warning": "سيتم حذف هذا المتغير نهائيًا.",
    "search_variations": "تنويعات البحث",
    "add_variation": "إضافة التنوع",
    "variation_text": "متغير",
    "select_customization": "حدد التخصيص",
    "add_new": "إضافة جديد",
    "delete_customization_warning": "هل تريد حذف هذا التخصيص من المنتج؟",
    "nutritional_allergen_info": "معلومات غذائية ومعلومات عن المواد المسببة للحساسية",
    "mark_item": "وضع علامة على العنصر",
    "calories_text": "سعرات حرارية",
    "allergens": "المواد المسببة للحساسية",
    "select_allergens": "اختر المواد المسببة للحساسية",
    "special_instructions": "تعليمات خاصة",
    "preparation_instructions": "تعليمات التحضير",
    "staff_notes": "ملاحظات الموظفين",
    "checkbox1": "مربع اختيار للسماح أو عدم السماح بتلقي تعليمات خاصة من العملاء لهذا العنصر من القائمة.",
    "menu_c": "قائمة طعام",
    "past_design": "التصميم السابق",
    "file_name": "اسم الملف",
    "variation_name": "اسم الاختلاف",
    "grid_items_title1": "خيمة الطاولة",
    "grid_items_title2": "القائمة الرقمية",
    "grid_items_title3": "قائمة الوجبات الجاهزة",
    "grid_items_title4": "قائمة نصف الصفحة",
    "grid_items_title5": "القائمة الرقمية 5",
    "grid_items_title6": "القائمة الرقمية 6",
    "grid_items_title7": "القائمة الرقمية 7",
    "grid_items_title8": "القائمة الرقمية 8",
    "grid_items_title9": "القائمة الرقمية 9",
    "enter_valid_form_name": "أدخل اسم النموذج الصحيح",
    "form_name_required": "اسم النموذج مطلوب",
    "enter_valid_section": "أدخل عنوان القسم الصحيح",
    "fraction_half": "1\/2",
    "fraction_two_by_two": "2\/2",
    "form_name": "اسم النموذج",
    "contact_list": "قائمة الاتصال",
    "label_notify_email": "هل ترغب في الحصول على إشعار عبر البريد الإلكتروني؟",
    "add_client_details_section": "إضافة قسم تفاصيل العميل",
    "demo_purpose_only": "إنه لغرض العرض التوضيحي فقط",
    "form_updated_success": "تم تحديث النموذج بنجاح",
    "form_creat_success": "تم إنشاء النموذج بنجاح",
    "add_custom_section": "إضافة قسم مخصص",
    "edit_custom_section": "تعديل قسم مخصص",
    "forms_list": "قائمة النماذج",
    "filled_custom_form_details": "تفاصيل النموذج المخصص المملوء",
    "custom_form_deletion_warning": "سيتم حذف هذا النموذج المخصص نهائيًا",
    "menu_link": "رابط القائمة",
    "WebSignUpT": "التسجيل الإلكتروني",
    "lifeTConT": "اتصالات مدى الحياة",
    "repCustT": "العملاء المتكررون",
    "liftTSubT": "المشتركين مدى الحياة",
    "overallT": "إجمالي",
    "loginCustBDT": "العملاء المسجلين حسب اليوم",
    "deviceT": "ديموغرافيات الجهاز",
    "ageTitle": "التركيبة السكانية حسب العمر",
    "ageT": "الفئة العمرية",
    "convert_social_media_followers_into_customers": "تحويل متابعي وسائل التواصل الاجتماعي إلى عملاء",
    "signWidDesc": "قم بإنشاء أداة للتسجيل وإنشاء صفحات الهبوط وتوليد القسائم من موقع الويب الخاص بك أو وسائل التواصل الاجتماعي",
    "qrWidT": "رمز الاستجابة السريعة الذكي",
    "imgWidT": "رقم الاشتراك",
    "mobile": "متحرك",
    "YESTitle": "نعم",
    "NOTitle": "لا",
    "addDelRed": "إضافة إعادة توجيه التسليم",
    "dlredNameT": "قم بتسمية إعادة توجيه التسليم الخاص بك",
    "conf_text_req": "مطلوب نص التأكيد",
    "cFDesc": "إنشاء نماذج مخصصة لجمع ملاحظات ومعلومات محددة من العملاء بكفاءة",
    "pre_review_questions_enabled": "قبل أن نطلب من العملاء المراجعة، دعنا نسألهم من 1 إلى 4 أسئلة.",
    "feel_free_edit": "لا تتردد في التعديل حسب الحاجة.",
    "option": "خيار",
    "confFL": "سيتم عرض هذا التأكيد بعد أن يقوم جهة الاتصال الخاصة بك بإرسال نموذج التسجيل على الويب",
    "confT": "نص التأكيد",
    "joinTitle": "ينضم",
    "mobValidL": "يرجى التحقق من رقم الهاتف المحمول الصحيح",
    "mobRequiredL": "رقم الهاتف المحمول مطلوب",
    "Business_location_label": "موقع عملك",
    "qr_code_selection_txt": "اختيار رمز الاستجابة السريعة",
    "choose_theme_txt": "اختر الموضوع",
    "edit_qr_details_txt": "تعديل تفاصيل QR",
    "save_qr_code_txt": "حفظ رمز الاستجابة السريعة",
    "text_color_label": "لون النص",
    "background_color_label": "لون الخلفية",
    "print_txt": "مطبعة",
    "customer_will_see_txt": "هذا ما سيراه عميلك. اختر لون علامتك التجارية في الخطوة التالية.",
    "choose_your_brand_color_&_langauge_title": "اختر لون علامتك التجارية ولغتك",
    "custom_redirect_link_label": "رابط إعادة التوجيه المخصص",
    "redirect_user_to_menu": "إعادة توجيه المستخدم إلى القائمة",
    "language": "اللغة",
    "select_language_placeholder": "اختر اللغة",
    "capitalize_location_name_txt": "اسم الموقع",
    "created_txt": "مخلوق",
    "swipe_reputation_management_txt": "مرر لرؤية كيف ستبدو إدارة سمعتك",
    "sent_txt": "مرسل",
    "number_label": "رقم",
    "date_label": "تاريخ",
    "gender_demographics_title": "التركيبة السكانية حسب الجنس",
    "step1_preview_desc": "قم بتسمية نموذج التسجيل الإلكتروني QR الخاص بك وأضفه إلى قائمة مجموعتك",
    "main_head": "الحصول على عملاء جدد",
    "main_desc": "احصل على العملاء من خلال وضع رمز الاستجابة السريعة QR، والذي عند مسحه ضوئيًا، يعيد توجيه المستخدمين إلى نموذج تسجيل مخصص.",
    "step1_description": "الحصول على عملاء جدد من شركات أخرى",
    "form_name_field_placeholder": "التسجيل الإلكتروني في Restro",
    "last_step_title": "مبروك! لقد تم إنشاء أداة التسجيل الإلكتروني الخاصة بك! 🎉",
    "last_step_desc": "مرر لرؤية كيف سيبدو نموذج التسجيل الإلكتروني الخاص بك",
    "events_step1_preview_desc": "قم بتسمية نموذج QR الخاص بحدثك وأضفه إلى قائمة مجموعتك",
    "events_main_head": "احصل على عملاء من الأحداث",
    "events_main_desc": "احصل على العملاء من خلال وضع رمز الاستجابة السريعة (QR) على الحدث، وعند مسحه ضوئيًا، يتم إعادة توجيه المستخدمين إلى نموذج تسجيل مخصص.",
    "events_step1_desc": "استقطاب العملاء من الأحداث وإحضارهم إلى المتجر",
    "events_name": "اسم الحدث",
    "events_form_name_field_placeholder": "حدث الترفيه والطعام",
    "events_last_step_title": "مبروك! تم إنشاء نموذج الحدث الخاص بك! 🎉",
    "events_last_step_desc": "مرر لرؤية كيف سيبدو نموذج الحدث الخاص بك",
    "birthday_anniversary_step1_preview_desc": "قم بتسمية نموذج QR الخاص بك لجمع أعياد ميلاد العملاء أو الذكرى السنوية وإضافته إلى قائمة مجموعتك",
    "birthday_anniversary_main_head": "جمع هدايا عيد الميلاد أو الذكرى السنوية",
    "birthday_anniversary_main_desc": "احصل على تاريخ ميلاد العميل أو الذكرى السنوية، وعند مسحه، يتم إعادة توجيه المستخدمين إلى نموذج تسجيل مخصص.",
    "birthday_anniversary_step1_desc": "جمع أعياد ميلاد العميل والذكرى السنوية",
    "restro_text": "رستور",
    "birthday_anniversary_last_step_title": "مبروك! احصل على بطاقات عيد ميلاد وذكرى سنوية من العملاء الذين تم إنشاء النموذج لهم! 🎉",
    "newspaper_ads_step1_preview_desc": "قم بتسمية نموذج QR الخاص بك للحصول على عملاء من إعلانات الصحف وإضافته إلى قائمة مجموعتك",
    "newspaper_ads_main_head": "احصل على العملاء من الإعلانات",
    "newspaper_ads_main_desc": "احصل على العملاء من إعلانات الصحف، وعندما يقومون بمسحها ضوئيًا، يتم إعادة توجيه المستخدمين إلى نموذج تسجيل مخصص.",
    "newspaper_ads_step1_desc": "احصل على العملاء من خلال إعلانات الصحف أو الإعلانات الخارجية",
    "newspaper_ads_last_step_title": "مبروك! احصل على عملاء من خلال نموذج إعلانات الصحف الذي تم إنشاؤه! 🎉",
    "smart_pamphlets_step1_preview_desc": "قم بتسمية نموذج QR الخاص بك للحصول على بيانات العملاء من الكتيبات وإضافتها إلى قائمة مجموعتك",
    "smart_pamphlets_main_head": "احصل على عملاء من خلال الكتيبات",
    "smart_pamphlets_main_desc": "احصل على بيانات العملاء من الكتيبات، وعندما يقومون بمسحها ضوئيًا، يتم إعادة توجيه المستخدمين إلى نموذج تسجيل مخصص.",
    "smart_pamphlets_last_step_title": "مبروك! تم الحصول على بيانات العملاء من نموذج الكتيبات الذي تم إنشاؤه! 🎉",
    "social_media_step1_preview_desc": "قم بتسمية نموذج QR الخاص بك لجذب العملاء من وسائل التواصل الاجتماعي والعملاء وإضافته إلى قائمة مجموعتك",
    "social_media_main_desc": "احصل على العملاء من وسائل التواصل الاجتماعي، وعندما يقومون بالمسح الضوئي من المنشورات، فإنه يعيد توجيه المستخدمين إلى نموذج تسجيل مخصص.",
    "social_media_form_name_field_placeholder": "عرض مهرجان ريسترو - منشور على الفيسبوك",
    "social_media_last_step_title": "مبروك! تم إنشاء نموذج التواصل الاجتماعي للعملاء! 🎉",
    "claim_offer_text": "املأ بياناتك واحصل على العرض الآن",
    "form_title_description": "أضف عنوان النموذج والوصف ليتمكن عملاؤك من إدخال التفاصيل",
    "no_subscribed_clients": "لا تحتوي مجموعة العملاء المحددة على أي عملاء مشتركين",
    "welcome_message_offer": "أرسل رسالة ترحيب إلى عملائك بعد التسجيل وقدم لهم قسيمة عرض",
    "system_grp": "مجموعات النظام",
    "import_clients": "استيراد العملاء",
    "delivery_redirection_name": "اسم إعادة توجيه التسليم",
    "created_at": "تم إنشاؤه في",
    "provide_stamp_user": "توفير الطوابع للمستخدم",
    "scan_qr_code": "مسح رمز الاستجابة السريعة",
    "add_point": "إضافة نقطة",
    "total_points": "مجموع النقاط",
    "user_profile": "الملف الشخصي للمستخدم",
    "stamp_history": "تاريخ الطوابع",
    "reward_txt": "جائزة",
    "variant_placeholder": "صغير، متوسط، كبير، الخ",
    "sent_coupon": "تم إرسال القسيمة",
    "order_status": "حالة الطلب",
    "payment_status": "حالة الدفع",
    "order_currency": "طلب العملة",
    "card_data": "بيانات البطاقة",
    "reward_expiring_on": "تنتهي صلاحية المكافأة في",
    "reward_status": "حالة المكافأة",
    "reward_get_date": "تاريخ الحصول على المكافأة",
    "reward_name": "اسم المكافأة",
    "add_point_type": "إضافة نوع النقطة",
    "restaurant_location": "موقع المطعم",
    "collect_points": "جمع النقاط",
    "rewards_expire": "انتهاء صلاحية المكافآت",
    "rewards_redeem": "استرداد المكافآت",
    "total_rewards": "مجموع المكافآت",
    "created_by": "تم الإنشاء بواسطة",
    "anniversary": "عيد",
    "remove_user_group_txt": "إزالة المستخدم من المجموعة",
    "delete_permanently_grp_msg": "سيتم حذف هذا العميل نهائيًا من المجموعة.",
    "delete_multiple_permanently_grp_msg": "سيتم حذف هؤلاء العملاء نهائيًا من المجموعة.",
    "delete_multiple_client": "سيتم حذف هؤلاء العملاء نهائيًا.",
    "messages": "رسائل",
    "management": "إدارة",
    "client": "عميل",
    "country": "دولة",
    "state": "ولاية",
    "city": "مدينة",
    "1_sparkly_point_txt": "🎉 لقد حصلت على نقطة لامعة واحدة! 🌟",
    "select_country_txt": "اختر البلد",
    "select_state": "حدد الولاية",
    "ls_thanks_for_join": "نشكرك على الانضمام إلى بطاقة الولاء الخاصة بنا.🎁🎈",
    "month": "شهر",
    "welcome_message": "أرسل رسالة ترحيب إلى عملائك بعد تسجيلهم",
    "all_rewards_txt": "جميع المكافآت",
    "active_rewards": "المكافآت النشطة",
    "expired_and_redeemed": "انتهت صلاحيتها وتم استردادها",
    "redirect_3rd_party_delivery": "إعادة توجيه تسليم الطرف الثالث إلى متجرك أو إلى منصة الطلب عبر الإنترنت",
    "search_or_start_new": "ابحث أو ابدأ جديدًا",
    "contacts": "جهات الاتصال",
    "chats": "الدردشات",
    "add_by_restaurant": "أضف حسب المطعم",
    "using_scan_code": "استخدام رمز المسح الضوئي",
    "step": "خطوة",
    "list_widget_text": "قائمة الأدوات",
    "swipe_free_wifi_txt": "مرر للحصول على واي فاي مجاني",
    "create_widget_txt": "إنشاء عنصر واجهة مستخدم",
    "redeem_award_msg": "هل أنت متأكد؟ هل تريد استرداد هذه المكافأة؟",
    "on_collect_msg": "على جمع",
    "pause": "يوقف",
    "resume": "سيرة ذاتية",
    "location_added": "تمت إضافة الموقع",
    "sub_user_admin_permissions_txt": "السماح للمستخدم الفرعي بالوصول إلى أذونات المسؤول في Digital Signage",
    "unlist": "إلغاء القائمة",
    "cannot_delete_default_terms": "لا يمكن حذف الشروط والأحكام الافتراضية",
    "something_went_wrong": "لقد حدث خطأ ما!",
    "card_download_success": "تم تنزيل البطاقة بنجاح",
    "permission_denied": "تم رفض الإذن!",
    "invalid_qr_code": "يرجى مسح رمز الاستجابة السريعة الصحيح",
    "select_customer": "الرجاء اختيار العميل",
    "online": "متصل",
    "offline": "غير متصل",
    "no_data": "عفواً، لم يتم العثور على أي بيانات!",
    "forever_free": "مجانية للأبد"
}

export default ar;