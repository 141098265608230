const fr = {
    "rated_google_reviews": "Noté 5.0 sur Google Reviews",
    "get_your_digital_menu": "Obtenez votre menu numérique—",
    "instant_popup_menu": "Menu contextuel instantané sans scan QR",
    "free_forever": "Libre pour toujours",
    "the_future_is_here": "Le futur est là !",
    "upgrade_your_restaurant": "Améliorez votre restaurant avec un superbe menu numérique à coût nul. Attirez plus de clients dès aujourd'hui.",
    "get_my_free_menu_now": "Obtenez mon menu gratuit maintenant",
    "no_credit_card_required": "*Aucune carte de crédit requise",
    "join_largest_marketplace": "Rejoignez la plus grande place de marché de restauration !",
    "what_will_you_get": "Qu'obtiendrez-vous",
    "free": "GRATUIT",
    "free_digital_menu": "Menu numérique gratuit 2D ou 3D",
    "free_digital_menu_desc": "Dites adieu aux menus papier et aux réimpressions coûteuses",
    "quick_and_easy_signup": "Inscription rapide et facile",
    "communicate_with_customers_title": "Communiquez avec vos clients GRATUITEMENT !",
    "features1": [
        "Économisez sur les coûts d’impression et profitez de mises à jour de menu instantanées chaque fois que vous en avez besoin.",
        "Accédez à votre menu numérique sur n'importe quel appareil",
        "Écologique et sans tracas"
    ],
    "features2": [
        "Permettez aux clients d'explorer virtuellement vos plats avec des visuels 3D époustouflants.",
        "Améliorez l’engagement en offrant aux convives un aperçu réaliste de leur repas.",
        "Démarquez-vous avec une expérience de menu aussi attrayante que le goût de vos plats."
    ],
    "features3": [
        "Accueillez instantanément vos clients avec votre menu dès qu'ils entrent dans le restaurant.",
        "Envoyez automatiquement des mises à jour sur les offres spéciales et les promotions sur leurs téléphones.",
        "Améliorez l'expérience culinaire avec un accès rapide et sans contact à votre menu."
    ],
    "feature4": [
        "Mettez en valeur les offres saisonnières, les spécialités quotidiennes et les promotions exclusives.",
        "Attirez l’attention des clients sur vos articles les plus populaires.",
        "Créez de l’urgence et de l’enthousiasme avec des visuels et des descriptions accrocheurs."
    ],
    "feature5": [
        "Faites partie d'une communauté en ligne avec des milliers de visiteurs quotidiens.",
        "Augmentez votre visibilité et atteignez de nouveaux clients qui recherchent activement des options de restauration.",
        "Assurez-vous d’avoir une place de choix dès le début pour maximiser votre visibilité et attirer plus de convives."
    ],
    "section3_heading": "Pourquoi passer au numérique ? Parce que c'est gratuit et facile !",
    "section3_description": "Débloquez des fonctionnalités puissantes conçues pour renforcer l'attrait de votre restaurant.",
    "instant_menu": "Menu instantané",
    "instant_menu_notification": "Pop-up et notification",
    "instant_menu_description": "Accueillez vos invités avec un accès instantané au menu dès leur arrivée. Envoyez automatiquement le dernier menu et les offres spéciales directement sur leurs téléphones portables",
    "digital_menu": "Menus numériques",
    "at_zero_cost": "à coût zéro",
    "digital_menu_description1": "Dites adieu aux menus papier et aux réimpressions coûteuses. Passez à un menu numérique toujours à jour, et ce, gratuitement et pour toujours !",
    "digital_menu_description2": "Grâce à notre menu numérique, vos offres sont toujours à jour et accessibles sur n'importe quel appareil. Économisez de l'argent et simplifiez les mises à jour de menu avec une solution transparente et sans frais.",
    "try_me_button": "Essaie-moi",
    "try_3d_menu_title": "Menus 3D",
    "try_3d_menu_title2": "Gratuitement — WOW!",
    "try_3d_menu_description1": "Faites ressortir vos plats à l'écran. Téléchargez des images 3D et laissez vos clients goûter virtuellement vos plats avant d'entrer.",
    "try_3d_menu_description2": "Les images 3D de haute qualité créent un aperçu appétissant, améliorant l'attrait et encourageant les commandes.",
    "try_now": "Essayez maintenant",
    "showcase_title": "Présentez votre",
    "showcase_specials": "Spécialités",
    "showcase_specials_description1": "Vous avez une offre alléchante ou une promotion imbattable ? Mettez-la en avant sur votre menu numérique et attirez plus de clients affamés.",
    "showcase_specials_description2": "Rendez vos promotions impossibles à manquer, en attirant l’attention avec des visuels audacieux et des descriptions attrayantes.",
    "marketplace": "Marché",
    "marketplace_description": "Nous créons une place de marché avec plus de 2 000 visiteurs quotidiens. Montez à bord dès maintenant et vous serez le premier sur la liste lors de notre lancement. Vous pourrez atteindre de nouveaux clients qui recherchent activement des options de restauration. Réservez votre place tôt pour maximiser votre visibilité !",
    "see_other_products": "Voir d'autres produits",
    "about_us": "À propos de nous",
    "why_serving_free": "Pourquoi nous servons cela pour",
    "serving_free_highlight": "Gratuit",
    "about_desc": "Nous sommes des gens du coin, ici à Dallas, déterminés à aider les restaurants comme le vôtre à prospérer.",
    "about_tool_free": "Nous offrons cet outil puissant absolument gratuitement car nous croyons qu'il est important de soutenir notre communauté.",
    "no_catch": "Pas de piège, pas de blague. Nous savons que lorsque vous réussissez, notre communauté devient plus forte. 😊",
    "understanding_challenges": "Nous comprenons les défis liés à la gestion d’une entreprise, c’est pourquoi nous nous engageons à fournir des outils qui rendent vos opérations plus fluides et votre portée plus large.",
    "community_growth": "Au fur et à mesure que vous grandissez, notre communauté grandit avec vous, créant un effet d'entraînement de réussite et de soutien. Ensemble, rehaussons l'expérience culinaire pour tous.",
    "how_it_works": "Comment ça marche",
    "three_simple_steps": "Trois étapes simples pour",
    "get_started_highlight": "Commencer",
    "no_tech_skills_needed": "Aucune compétence technique n'est requise. Si vous savez retourner un hamburger, vous pouvez le faire !",
    "step1_title": "Affichez votre promo GRATUITEMENT !",
    "step1_desc": "Commencez plus vite que vous ne pouvez dire « Commandez ! » Il vous suffit de remplir un simple formulaire.",
    "step2_title": "Personnalisez votre menu",
    "step2_desc": "Ajoutez vos plats, téléchargez des photos même en 3D et mettez en valeur vos spécialités.",
    "step3_title": "Partager et grandir",
    "step3_desc": "Partagez votre menu numérique via des QR, des réseaux sociaux ou votre site Web.",
    "testimonials": "Témoignages",
    "testimonials_section_title": "Ne nous croyez pas sur parole",
    "testimonials_section_desc": "Découvrez ce que disent les autres propriétaires de restaurants de Dallas.",
    "faq_section_title": "Vous avez des questions ? Nous avons les réponses",
    "faq_section_desc": "Tout ce que vous devez savoir avant de commencer.",
    "faqs": "FAQ",
    "faq_answers": [
        {
            "que": "Est-ce vraiment gratuit pour toujours ?",
            "ans": "Absolument ! Pas de frais cachés, pas de carte de crédit, pas d'engagement."
        },
        {
            "que": "Ai-je besoin de connaissances techniques ?",
            "ans": "Si vous savez envoyer un e-mail, vous pouvez le faire. C'est aussi simple que ça."
        },
        {
            "que": "Pourquoi proposez-vous cela gratuitement ?",
            "ans": "Nous croyons qu'il faut donner avant tout. Nous pourrions avoir d'autres services que vous apprécierez plus tard, mais c'est à vous de décider."
        },
        {
            "que": "Quel est le problème avec le marché ?",
            "ans": "Nous sommes en train de préparer le terrain ! Une fois que nous aurons suffisamment de restaurants à bord, nous lancerons votre marketplace pour vous offrir encore plus de visibilité."
        }
    ],
    "testimonials_content1": "Passer à un menu numérique a été la meilleure décision que nous ayons prise ! Nos clients adorent la commodité et le look modernisé !",
    "testimonials_name1": "George Morris",
    "testimonials_restaurant_name1": "Le restaurant du Manoir",
    "testimonials_content2": "Notre nouveau menu numérique facilite la navigation des clients et renforce réellement l'engagement !",
    "testimonials_name2": "Amélia Johnson",
    "testimonials_restaurant_name2": "Repas urbains",
    "testimonials_content3": "Les clients sont impressionnés par les visuels 3D : cela nous a aidés à nous démarquer et à améliorer leur expérience culinaire !",
    "testimonials_name3": "Davis Green",
    "testimonials_restaurant_name3": "Cœur de la ville",
    "ready_to_boost_your_restaurant": "Prêt à booster votre restaurant ?",
    "ready_to_boost_desc": "Ne restez pas à la traîne. Rejoignez la révolution numérique dès aujourd'hui !",
    "yes_i_want_my_free_digital_menu": "Oui, je veux mon menu numérique gratuit",
    "480_plus": "480+",
    "people_already_joined": "les gens ont déjà rejoint",
    "menu_online_home": "Menu Accueil en ligne",
    "welcome": "Accueillir",
    "description_other": "Boostons la satisfaction de vos clients !",
    "benefits": "Avantages",
    "greeting_text": "Bienvenue 👋",
    "all_in_one_features": "🚀 Des fonctionnalités tout-en-un pour améliorer votre restaurant",
    "menu_online_menu_name": "Menu en ligne",
    "menu_online_description": "Numérisez votre menu pour un accès en ligne facile par les clients.",
    "menu_online_benefits": "Améliorez le confort des clients et stimulez leur engagement en leur permettant de consulter votre menu à tout moment et en tout lieu.",
    "ds_name": "Affichage numérique",
    "ds_description": "Présentez vos offres et promotions sur des écrans dynamiques en magasin.",
    "ds_benefits": "Captivez l'attention des clients avec des visuels attrayants, en promouvant les ventes incitatives et les offres en temps réel sans effort.",
    "loyalty_punch_name": "Carte perforée de fidélité",
    "loyalty_punch_description": "Récompensez vos clients fidèles avec un programme de fidélité numérique.",
    "loyalty_punch_benefits": "Favorisez la fidélisation des clients et augmentez les ventes en encourageant la fidélité avec des récompenses échangeables pour les visites fréquentes.",
    "marketing_menu_name": "Commercialisation",
    "marketing_description": "Atteignez plus de clients avec des campagnes de marketing numérique ciblées.",
    "marketing_benefits": "Élargissez la portée de votre restaurant et augmentez le trafic piétonnier avec des promotions personnalisées conçues pour attirer des clients nouveaux et réguliers.",
    "get_more_reviews_menu_name": "Obtenez plus d'avis",
    "get_more_reviews_description": "Encouragez les clients satisfaits à laisser des avis positifs en ligne.",
    "get_more_reviews_benefits": "Boostez la crédibilité de votre restaurant et attirez de nouveaux convives en améliorant votre réputation en ligne avec des avis plus positifs.",
    "guest_smart_wifi_menu_name": "Wifi intelligent pour les invités",
    "guest_smart_wifi_description": "Offrez une connexion Wi-Fi gratuite pour recueillir les données des clients à des fins de marketing.",
    "guest_smart_wifi_benefits": "Augmentez la satisfaction client tout en collectant des données clients précieuses pour personnaliser les promotions futures.",
    "reservation_menu_name": "Réservation",
    "reservation_description": "Permettez aux clients de réserver des tables en ligne en toute simplicité.",
    "reservation_benefits": "Améliorez l'expérience culinaire en minimisant les temps d'attente et rationalisez les réservations pour mieux gérer les places assises.",
    "pos_integration_menu_name": "Intégration POS",
    "pos_integration_description": "Intégrez notre système à votre système de point de vente.",
    "pos_integration_benefits": "Simplifiez le traitement des commandes et réduisez les erreurs en synchronisant vos systèmes en ligne et en magasin.",
    "online_ordering_menu_name": "Commande en ligne",
    "online_ordering_description": "Permettez aux clients de passer des commandes directement depuis votre site Web ou votre application.",
    "online_ordering_benefits": "Augmentez vos ventes et réduisez votre dépendance aux plateformes tierces en proposant une option de commande directe qui permet d'économiser des frais.",
    "pro_label": "Pro",
    "coming_soon": "À venir",
    "locked_label": "Fermé",
    "forever_label": "Pour toujours",
    "new_label": "Nouveau",
    "other_products_page_title": "Autres produits | Menu en ligne",
    "other_products_description": "Menu Politique de confidentialité en ligne",
    "other_products_header_title": "Autres produits",
    "privacy_policy": {
        "title": "politique de confidentialité",
        "lastUpdated": "Dernière mise à jour : 01 juillet 2024",
        "introduction": {
            "header": "Politique de confidentialité pour les clients nouveaux et existants",
            "paragraph1": "LES POINTS CLÉS SUIVANTS (Y COMPRIS LES RÉFÉRENCES AUX SECTIONS) SONT FOURNIS POUR VOTRE COMMODITÉ UNIQUEMENT ET NE REMPLACENT PAS LES CONDITIONS GÉNÉRALES COMPLÈTES. IL EST DE VOTRE RESPONSABILITÉ DE LIRE CES CONDITIONS GÉNÉRALES AVANT D'UTILISER LE SERVICE MENUONLINE INC.",
            "paragraph2": "En utilisant Menuonline, vous acceptez les Conditions générales et déclarez et garantissez que vous êtes autorisé par votre organisation ou entreprise à le faire. (Voir s.3 « Comment cela fonctionne »)",
            "paragraph3": "Menuonline Inc est un service de marketing basé sur l'autorisation. L'envoi délibéré de « spam » ou la violation des conditions générales entraînera la fermeture de votre compte. (Voir s.16 « Utilisation acceptable du service »)",
            "paragraph4": "Les données que vous hébergez chez Menuonline Inc, telles que les enregistrements de contacts, le contenu des e-mails et les informations que vous collectez via Menuonline Inc, vous appartiennent. (Voir s.14 « Que possédez-vous ? »).",
            "paragraph5": "Le logiciel Menuonline Inc et le contenu de notre site Web nous appartiennent. (Voir s.13 « Que possédons-nous ? ») Outre vos propres utilisateurs, les données ou le contenu non publié que vous hébergez chez Menuonline Inc ne peuvent être consultés que par les employés et partenaires de Menuonline Inc qui ont signé des accords de confidentialité. (Voir s.14 « Que possédez-vous ? »)",
            "paragraph6": "Toutes les informations personnelles identifiables (IPI) sont conservées uniquement sur des serveurs situés au Canada. (Voir l’article 14 « Que possédez-vous ? »)",
            "paragraph7": "Le service Menuonline Inc est soumis aux lois du Canada et de la province de l'Ontario. (Voir art. 24 « Loi applicable »)",
            "paragraph8": "Toute demande de données ou de contenu vous concernant émanant de particuliers, de la police ou d’autres organismes de réglementation du Canada ou de l’extérieur du Canada vous sera toujours adressée. La seule exception serait si cela nous met en infraction avec la loi canadienne ou ontarienne. Cependant, dans tous les cas, nous résisterons vigoureusement à toute divulgation de vos données par quiconque autre que vous. (Voir l’article 14 « Que possédez-vous ? »).",
            "paragraph9": "La sécurité de Menuonline Inc dépend de votre gestion des noms d'utilisateur et des mots de passe. Nous ne facturons pas les comptes d'utilisateur de Menuonline Inc. Créez donc un compte distinct pour chaque utilisateur et gardez les mots de passe secrets. Vous êtes responsable de votre compte. (Voir s.3 « Comment ça marche »)",
            "paragraph10": "Vous pouvez annuler votre compte à tout moment. Nous ne remboursons pas les crédits non utilisés si vous annulez votre abonnement. (Voir § 3 « Comment ça marche »)"
        },
        "termsAndConditions": {
            "title": "CONDITIONS GÉNÉRALES DE MENUONLINE INC",
            "lastUpdate": "DERNIÈRE MISE À JOUR : 14 janvier 2020",
            "paragraph1": "Veuillez lire attentivement les présentes conditions générales de service (les « Conditions ») avant d’utiliser ce site Web (le « Site »). Ces Conditions exonèrent Menuonline Inc. (« Menuonline Inc », « nous » ou « nos ») et d’autres de toute responsabilité et\/ou limitent notre responsabilité et la leur et contiennent d’autres dispositions importantes qui s’appliquent à votre utilisation de ce Site. En visitant ou en utilisant ce Site ou le Service, vous acceptez en votre nom propre et au nom de toute organisation au nom de laquelle vous pouvez agir (collectivement dénommées ci-après « vous »), d’accepter et de respecter les présentes Conditions pour chaque utilisation et chaque visite de ce Site."
        },
        "services": {
            "title": "Nos services.",
            "paragraph": "Les services que nous proposons via le Site sont un service qui vous permet de créer, d'envoyer et de gérer des messages électroniques (« E-mails ») aux destinataires de votre choix (le « Service »)."
        },
        "license": {
            "title": "Licence.",
            "paragraph": "Sous réserve des présentes Conditions, Menuonline Inc vous accorde par la présente une licence non exclusive, limitée et non transférable pendant la Durée pour consulter le Site et utiliser le Service comme le permettent les fonctionnalités du Service. Nous nous réservons tous les droits non expressément accordés dans les présentes sur le Service et le Contenu de Menuonline Inc (tel que défini ci-dessous)."
        },
        "emailingContent": {
            "title": "Envoi de contenu par courrier électronique.",
            "paragraph": "Lorsque vous interagissez avec Menuonline Inc via ce site ou le service, vous acceptez de fournir des informations véridiques et exactes. De plus, vous comprenez que fournir des informations confidentielles par courrier électronique est une pratique dangereuse. Vous comprenez que vous êtes seul responsable de tout ce que vous envoyez à quiconque utilisant notre service."
        },
        "support": {
            "title": "Soutien.",
            "paragraph": "Nous vous offrons une assistance pour vous aider à devenir autonome avec le Service. Nous vous fournissons une assistance sous forme de documentation et de vidéos sur le Site, le système de ticket d'assistance de Menuonline Inc et par téléphone. Vous pouvez nous contacter en cliquant sur l'onglet « Assistance » en haut du Site. L'assistance produit est gratuite ; cependant, nous nous réservons le droit d'interrompre l'assistance pour des comptes clients spécifiques à notre discrétion."
        }
    },
    "terms_condition": {
        "title": "Conditions d'utilisation | Menu en ligne",
        "header": "Conditions générales pour les clients nouveaux et existants"
    },
    "privacy_policy_title": "POLITIQUE DE CONFIDENTIALITÉ",
    "privacy_policy_effective_date": "En vigueur à compter du 16 janvier 2020",
    "privacy_policy_general_title": "POLITIQUE DE CONFIDENTIALITÉ GÉNÉRALE",
    "privacy_policy_intro": "menuonline.com (Menuonline et « nous ») comprend l’importance de la protection de la confidentialité des informations personnelles des individus et l’importance de la sécurité des informations confidentielles de tous types. Nous avons créé cette politique de confidentialité (la « Politique ») pour démontrer notre engagement à protéger votre « vie privée ». Cette politique de confidentialité explique :",
    "privacy_policy_points": [
        "Quelles informations personnelles nous collectons et pourquoi nous les collectons ;",
        "Comment nous utilisons vos informations personnelles ;",
        "Avec qui partageons-nous vos informations personnelles ; et",
        "Les choix dont vous disposez pour accéder, mettre à jour et supprimer vos informations personnelles du service."
    ],
    "privacy_policy_pipeda": "Menuonline gère la collecte de toutes les informations conformément à la Loi sur la protection des renseignements personnels et les documents électroniques (LPRPDE) du Canada.",
    "privacy_policy_update_notice": "Veuillez noter que Menuonline se réserve le droit de mettre à jour ou de modifier cette politique de confidentialité à tout moment. Nous vous informerons de tout changement en publiant une politique mise à jour sur ce site Web ou telle que mise à disposition via les services. La version la plus récente de cette politique régira l'utilisation par mycircle des informations personnelles des utilisateurs. En continuant à accéder au site Web de mycircle ou en continuant à utiliser ses services après que des modifications ont été apportées à cette politique de confidentialité, vous acceptez d'être lié par les conditions révisées.",
    "privacy_policy_consent_title": "CONSENTEMENT POUR LES INFORMATIONS PERSONNELLES",
    "privacy_policy_consent_text": "En fournissant à Menuonline vos informations personnelles via notre site Web ou l'un de nos formulaires, vous consentez à la collecte, à l'utilisation et à la divulgation de ces informations conformément à la présente politique ou aux fins qui vous ont été identifiées au moment où vous avez fourni les informations personnelles. Toute utilisation secondaire des informations personnelles ne sera traitée qu'avec votre consentement exprès, ou vous aurez la possibilité de vous retirer.",
    "privacy_policy_opt_out_text": "Vous avez la possibilité de retirer votre consentement ou de vous désinscrire à tout moment. Si vous souhaitez retirer votre consentement ou vous désinscrire, vous pouvez cliquer sur le lien de préférences de communication au bas de tout e-mail que vous recevez de notre part.",
    "privacy_policy_info_collection_title": "INFORMATIONS QUE NOUS COLLECTONS",
    "privacy_policy_info_collection_text": "Menuonline collecte et traite les informations personnelles conformément à la présente politique. Nous ne vendrons, ne partagerons ni ne louerons ces informations à des tiers, sauf dans les cas prévus par la présente politique. Menuonline collecte des informations personnelles dans le but de vous fournir nos services ou produits.",
    "privacy_policy_info_types_text": "Les types d'informations collectées par Menuonline incluent :",
    "privacy_policy_account_holders_title": "Titulaires de compte Menuonline",
    "privacy_policy_registration_info": "Informations d'inscription : Si vous vous inscrivez pour utiliser les services, vous devez disposer d'un compte Menuonline pour devenir titulaire du compte (« titulaire du compte »). Lorsque vous vous inscrivez pour un compte, il vous sera demandé de fournir certaines informations de base, telles que votre nom, votre adresse e-mail, votre nom d'utilisateur, votre mot de passe, le nom de votre entreprise, votre profession, votre emplacement et votre numéro de téléphone. Les mots de passe sont cryptés : le personnel de Menuonline n'est pas en mesure de consulter ou de récupérer les mots de passe.",
    "privacy_policy_billing_info": "Informations de facturation : Si vous vous abonnez à nos services, vous devrez nous fournir des informations de paiement et de facturation.",
    "privacy_policy_cookies_info": "Cookies : Nous utilisons des cookies pour suivre les données des visiteurs, telles que la source des visites du site Web et le nombre de visites sur notre site Web.",
    "privacy_policy_ip_info": "Adresses IP : les adresses IP sont collectées à votre sujet dans le cadre de votre utilisation des services (par exemple dans le but d'enregistrer la localisation au niveau du pays et les adresses IP utilisées lors de l'utilisation de nos services).",
    "privacy_policy_your_contacts_title": "Vos contacts",
    "privacy_policy_distribution_list": "Liste de distribution : Dans le cadre de votre utilisation de nos services, vous pouvez nous fournir, ou nous pouvons collecter dans le cadre des services, des informations personnelles sur vos contacts (« Contacts »). Ces informations comprennent les adresses e-mail et peuvent inclure d'autres informations identifiables telles que le nom, le nom de l'entreprise ou d'autres informations que vous fournissez à Menuonline lors de l'utilisation des services. Vous devez vous assurer d'obtenir le consentement approprié de vos contacts pour autoriser le traitement de leurs informations par nos soins.",
    "privacy_policy_billing_info_contacts": "Informations de facturation : Si vous vous abonnez à nos services, vous devrez nous fournir des informations de paiement et de facturation.",
    "privacy_policy_cookies_contacts": "Cookies : Menuonline n'utilisera des cookies et des technologies de suivi similaires pour collecter et utiliser les informations personnelles de vos contacts que si vous avez ajouté le code de suivi Menuonline à votre site Web. Toutes les pages de destination hébergées par Menuonline auront automatiquement un code de suivi.",
    "privacy_policy_ip_contacts": "Adresses IP : les adresses IP sont collectées sur vos contacts dans le cadre des services (par exemple dans le but d'enregistrer la localisation au niveau du pays et les adresses IP utilisées lors de la soumission d'un formulaire et de la fourniture du consentement).",
    "privacy_policy_info_about_contacts_title": "INFORMATIONS QUE NOUS RECUEILLONS SUR VOS CONTACTS",
    "privacy_policy_info_about_contacts_notice": "*Cette section s'applique uniquement aux informations collectées, utilisées ou divulguées par Menuonline sur les contacts d'un titulaire de compte aux fins de la fourniture des services*",
    "privacy_policy_info_about_contacts_desc": "Menuonline ne recueillera, n'utilisera ou ne divulguera des informations personnelles sur les contacts que si le titulaire du compte s'est inscrit à certains services pour lesquels il nous fournit une liste de distribution. Les titulaires de compte sont seuls responsables de s'assurer qu'ils ont respecté toutes les lois applicables lorsqu'ils fournissent des informations personnelles sur leurs contacts à Menuonline via les services.",
    "privacy_policy_info_auto_collect_title": "Informations collectées automatiquement par Menuonline",
    "privacy_policy_info_auto_collect_desc1": "Lorsque les contacts du titulaire du compte interagissent avec une campagne par courrier électronique reçue d'un titulaire du compte ou interagissent avec un lien vers le site Web connecté d'un titulaire du compte, Menuonline peut collecter des informations sur l'appareil de ce contact et son interaction avec le courrier électronique via l'utilisation de cookies. Ces informations ne sont collectées que pour fournir les services et pour fournir des informations au titulaire du compte.",
    "privacy_policy_info_auto_collect_desc2": "Les informations que nous collectons sur l'appareil d'un contact et les applications qu'il utilise pour accéder aux e-mails envoyés par le titulaire du compte, via nos services, peuvent inclure l'adresse IP du contact, le système d'exploitation, l'ID du navigateur et d'autres informations connexes sur le système et la connexion du contact.",
    "privacy_policy_info_auto_collect_desc3": "Les informations que nous collectons sur l'interaction de Contact avec les e-mails envoyés via les services peuvent inclure les dates et heures auxquelles Contact accède aux e-mails et aux activités de navigation, ainsi que des informations concernant les performances des services, telles que la délivrabilité des e-mails et autres communications envoyées à Contact par un titulaire de compte via les services.",
    "privacy_policy_info_auto_collect_desc4": "Veuillez noter que Menuonline ne contactera aucun de vos contacts, sauf si la loi applicable l'exige.",
    "privacy_policy_why_we_collect_title": "POURQUOI NOUS COLLECTONS VOS INFORMATIONS",
    "privacy_policy_why_we_collect_desc": "Menuonline collecte des informations aux fins suivantes :",
    "privacy_policy_why_collect_1": "pour fournir aux titulaires de compte Menuonline les services auxquels ils souscrivent, y compris en utilisant les informations du titulaire de compte pour les services ;",
    "privacy_policy_why_collect_2": "pour l'administration et la gestion des comptes, ainsi que l'accès au site Web de mycircle ;",
    "privacy_policy_why_collect_3": "si vous avez consenti à recevoir du matériel marketing et promotionnel, pour vous informer d'autres produits et services proposés par Menuonline ; et",
    "privacy_policy_why_collect_4": "pour répondre aux communications du titulaire du compte ou pour envoyer les informations que vous avez demandées. Cela peut inclure, sans toutefois s'y limiter :",
    "privacy_policy_why_collect_5": "offres spéciales et mises à jour ;",
    "privacy_policy_why_collect_6": "bulletin d'information; et",
    "privacy_policy_why_collect_7": "annonces de service.",
    "privacy_policy_opt_out_desc": "Vous pouvez, à tout moment, choisir de ne plus recevoir de communications en cliquant sur le bouton de désabonnement situé au bas de chaque e-mail qui vous est envoyé par Menuonline.",
    "privacy_policy_use_disclosure_title": "UTILISATION ET DIVULGATION DES RENSEIGNEMENTS PERSONNELS",
    "privacy_policy_use_disclosure_desc1": "Les informations personnelles soumises à Menuonline, y compris via le site Web de Menuonline ou un formulaire prescrit, sont strictement confidentielles, sauf si elles sont divulguées conformément au consentement exprès du titulaire de compte ou aux exigences légales. Dans tous les cas, toute information personnelle fournie sur toute plateforme « Menuonline » par les titulaires de compte est volontaire : toute information personnelle fournie par un titulaire de compte est soumise à la discrétion du titulaire de compte. Veuillez noter que la révocation de votre consentement peut entraîner l'impossibilité d'accéder ou d'utiliser certains services de Menuonline.",
    "privacy_policy_use_disclosure_desc2": "Menuonline fait appel à des tiers et à des prestataires de services extérieurs pour son site Web et ses services. Les informations personnelles du titulaire du compte peuvent être partagées avec ces tiers et fournisseurs. Nous pouvons également fournir des statistiques agrégées sur les titulaires de compte, les ventes, les modèles de trafic et les informations connexes à des tiers réputés. Menuonline exige que tout fournisseur tiers ayant accès aux informations personnelles soit lié par des obligations conformes à la présente politique de confidentialité et que ces fournisseurs ne collectent, n'utilisent ou ne divulguent les informations personnelles du titulaire du compte qu'aux fins de la fourniture des services Menuonline.",
    "privacy_policy_use_disclosure_desc3": "Les informations personnelles fournies par les titulaires de compte ne seront pas vendues, partagées ou louées à des organisations extérieures, sauf si cela est conforme à votre consentement implicite ou exprès. Menuonline se réserve le droit de contacter tout titulaire de compte afin de transmettre des informations, de répondre à des questions ou de fournir une notification de tout changement apporté à notre programme ou à nos politiques. Veuillez noter que Menuonline ne contactera aucun de vos contacts, sauf si la loi applicable l'exige.",
    "privacy_policy_use_disclosure_desc4": "Menuonline divulguera des informations personnelles uniquement si le consentement est donné par la personne concernée ou :",
    "privacy_policy_disclosure_list_1": "lorsque la loi le permet ou l’exige, par exemple en réponse à une assignation à comparaître ou à une autre procédure judiciaire ;",
    "privacy_policy_disclosure_list_2": "à un cessionnaire de tout ou partie de Menuonline ;",
    "privacy_policy_disclosure_list_3": "aux prestataires de services de Menuonline conformément à la présente politique ; ou",
    "privacy_policy_disclosure_list_4": "pour répondre aux obligations légales, y compris, mais sans s’y limiter, aux obligations de déclaration réglementaire.",
    "privacy_policy_service_providers_title": "Menuonline utilise les tiers et prestataires de services suivants (les informations personnelles de vos contacts ne sont pas divulguées à « ces » tiers) :",
    "privacy_policy_service_provider_1": "Nous utilisons ManyChat pour fournir des services de chat sur site Web pour les ventes et l'assistance, ainsi que des e-mails de suivi sur ces conversations.",
    "privacy_policy_service_provider_2": "Nous utilisons AskNicely pour interroger nos clients afin de les interroger sur la qualité du service et de l'assistance qu'ils reçoivent.",
    "privacy_policy_service_provider_3": "Nous utilisons join.me pour planifier des réunions avec nos clients et nos clients potentiels, des appels d'assistance et des démonstrations de logiciels.",
    "privacy_policy_service_provider_4": "Nous utilisons Google Analytics pour suivre l'activité anonyme du site Web et mesurer les modèles d'utilisation sur notre site Web.",
    "privacy_policy_breach_notification_title": "NOTIFICATION DE VIOLATION",
    "privacy_policy_breach_notification_desc": "Menuonline doit se conformer et fournir aux titulaires de compte une assistance raisonnable conformément aux lois applicables concernant toute utilisation, accès ou divulgation non autorisés d'informations personnelles.",
    "privacy_policy_rights_access_title": "VOS DROITS ET ACCÈS",
    "privacy_policy_rights_access_desc": "Vous avez le droit d'accéder aux informations personnelles que Menuonline traite à votre sujet. Vous pouvez nous demander des informations sur :",
    "privacy_policy_rights_access_list_1": "Les informations personnelles que nous détenons à votre sujet",
    "privacy_policy_rights_access_list_2": "Les catégories de renseignements personnels concernés",
    "privacy_policy_rights_access_list_3": "Finalités du traitement",
    "privacy_policy_rights_access_list_4": "Détails à qui vos informations personnelles ont été\/seront divulguées",
    "privacy_policy_rights_access_list_5": "Combien de temps conservons-nous vos informations personnelles",
    "privacy_policy_rights_access_list_6": "Si nous n'avons pas collecté les informations directement auprès de vous, des informations sur la source",
    "privacy_policy_rights_access_list_7": "Comment déposer une plainte auprès de l'autorité de contrôle compétente",
    "privacy_policy_rights_access_list_8": "Vous pouvez également nous demander les éléments suivants :",
    "privacy_policy_rights_access_list_9": "Que nous mettions à jour toute information incomplète ou inexacte vous concernant",
    "privacy_policy_rights_access_list_10": "Demander que nous supprimions vos informations personnelles conformément aux lois applicables",
    "privacy_policy_rights_access_contact": "Vous pouvez demander que nous exercions vos droits en nous contactant au 2851 Dufferin Street, Toronto, ON, Canada, ou en nous envoyant un courriel à {link}.",
    "privacy_policy_accountability_title": "RESPONSABILITÉ",
    "privacy_policy_accountability_desc": "Menuonline s'engage à être responsable des informations personnelles et confidentielles que vous nous fournissez. Pour les coordonnées, veuillez consulter la fin de cette politique.",
    "privacy_policy_links_other_sites_title": "LIENS VERS D'AUTRES SITES",
    "privacy_policy_links_other_sites_desc1": "Le site Internet de Menuonline peut contenir des liens vers d'autres sites. Veuillez noter que Menuonline n'est pas responsable des pratiques de confidentialité des autres sites. Nous encourageons nos titulaires de compte à être conscients que lorsqu'ils quittent notre site, ils doivent lire attentivement les déclarations de politique de confidentialité de chaque site Internet qui collecte des informations personnelles identifiables.",
    "privacy_policy_links_other_sites_desc2": "Cette politique de confidentialité s'applique uniquement aux informations collectées par nous.",
    "privacy_policy_links_other_sites_desc3": "Si vous utilisez un lien pour accéder au site Menuonline afin d'acheter des produits ou des services, vous effectuez une transaction d'achat conformément aux règles et réglementations de la société fournisseur, et non de Menuonline.",
    "privacy_policy_cookies_title": "POLITIQUE DE CONFIDENTIALITÉ COOKIES",
    "privacy_policy_cookies_desc1": "Le site Web et les services Menuonline utilisent des cookies pour collecter et stocker certaines informations conformément à cette politique. Un « cookie » est un petit fichier texte envoyé par un site Web et stocké sur l'ordinateur de l'utilisateur par le navigateur Web de l'utilisateur. Lorsque vous visitez un site qui utilise des cookies, un cookie est téléchargé sur votre ordinateur ou appareil mobile. La prochaine fois que vous visiterez ce site, votre appareil se souviendra d'informations utiles telles que vos préférences, les pages visitées ou les options de connexion.",
    "privacy_policy_cookies_desc2": "Les cookies sont largement utilisés pour ajouter des fonctionnalités aux sites Web ou pour garantir leur fonctionnement plus efficace. Notre site utilise des cookies pour optimiser l'expérience utilisateur et garantir le bon fonctionnement des services du site.",
    "privacy_policy_cookies_desc3": "La plupart des navigateurs Web permettent un certain contrôle pour restreindre ou bloquer les cookies via les paramètres du navigateur. Cependant, si vous désactivez les cookies, vous constaterez peut-être que cela affecte votre capacité à utiliser certaines parties de notre site Web ou de nos services.",
    "privacy_policy_cookies_analytical_purpose": "<strong> Fins d'analyse : <\/strong> nous utilisons des cookies pour analyser l'activité des titulaires de compte afin d'améliorer notre site Web. Par exemple, nous pouvons utiliser des cookies pour examiner des modèles agrégés tels que les fonctionnalités utilisées par les titulaires de compte. Nous pouvons utiliser cette analyse pour obtenir des informations sur la manière d'améliorer la fonctionnalité et l'expérience utilisateur de notre site Web.",
    "privacy_policy_cookies_preferences_user_experience": "<strong> Vos préférences et votre expérience utilisateur : <\/strong> nous utilisons des cookies pour recueillir certaines informations sur les visiteurs et les titulaires de compte, telles que le type de navigateur, le serveur, la préférence linguistique et le paramètre de pays, afin de stocker les préférences du titulaire de compte sur notre site Web pour rendre l'expérience du titulaire de compte plus cohérente et plus pratique. Nous utilisons des cookies pour maintenir votre état de connexion lorsque vous visitez notre site Web à plusieurs reprises.",
    "privacy_policy_cookies_targeted_ads": "<strong> Fournir des services de mesure et des publicités et des campagnes marketing mieux ciblées : <\/strong> Nous utilisons des cookies, des balises Web et d'autres technologies de stockage de partenaires tiers tels que Google et Facebook pour des services de mesure, un meilleur ciblage des publicités et à des fins de marketing. Cela se produit lorsque vous visitez notre site Web. Ces cookies, balises Web et autres technologies de stockage nous permettent de vous présenter du matériel promotionnel Menuonline sur d'autres sites que vous visitez sur Internet.",
    "privacy_policy_security_title": "SÉCURITÉ",
    "privacy_policy_security_desc1": "Menuonline s'efforce de protéger les informations confidentielles de tous types, y compris les informations personnelles, avec des mesures de protection organisationnelles, physiques, mécaniques et électroniques adaptées à la sensibilité des informations. Nos serveurs sont situés au Canada et respectent les normes de l'industrie en matière de sécurité.",
    "privacy_policy_security_desc2": "Veuillez toutefois noter que nous ne pouvons pas garantir que les mesures que nous maintenons garantiront la sécurité des informations.",
    "privacy_policy_retention_title": "RÉTENTION",
    "privacy_policy_retention_desc": "Nous conservons les informations personnelles sur les titulaires de compte et les contacts aussi longtemps que nécessaire aux fins énoncées dans la présente politique de confidentialité et conformément aux dispositions de conservation des conditions d'utilisation.",
    "privacy_policy_access_questions_title": "ACCÈS, QUESTIONS ET PRÉOCCUPATIONS",
    "privacy_policy_access_questions_desc": "Une personne ayant soumis des informations personnelles à Menuonline peut soumettre une demande écrite pour accéder ou corriger ses informations personnelles conservées par Menuonline.",
    "terms_obj": {
        "terms_conditions_title": "TERMES ET CONDITIONS",
        "terms_conditions_subtitle": "Conditions générales pour les clients nouveaux et existants",
        "terms_conditions_desc1": "LES POINTS CLÉS SUIVANTS (Y COMPRIS LES RÉFÉRENCES AUX SECTIONS) SONT FOURNIS UNIQUEMENT POUR VOTRE COMMODITÉ ET NE REMPLACENT PAS LES CONDITIONS GÉNÉRALES COMPLÈTES. IL EST DE VOTRE RESPONSABILITÉ DE LIRE CES CONDITIONS GÉNÉRALES AVANT D'UTILISER LE SERVICE Menuonline.",
        "terms_conditions_desc2": "En utilisant Menuonline, vous acceptez les Conditions générales et déclarez et garantissez que vous êtes autorisé par votre organisation ou entreprise à le faire. (Voir s.3 « Comment cela fonctionne »)",
        "terms_conditions_desc3": "Menuonline est un service de marketing basé sur le consentement. L'envoi délibéré de « spam » ou la violation des conditions générales entraînera la fermeture de votre compte. (Voir s.16 « Utilisation acceptable du service »)",
        "terms_conditions_desc4": "Les données que vous hébergez dans Menuonline, telles que les enregistrements de contacts, le contenu des e-mails et les informations que vous collectez via Menuonline, vous appartiennent. (Voir s.14 « Que possédez-vous ? »).",
        "terms_conditions_desc5": "Le logiciel Menuonline et le contenu de notre site Web nous appartiennent. (Voir article 13 « Que possédons-nous ? »)",
        "terms_conditions_desc6": "Outre vos propres utilisateurs, les données ou contenus non publiés que vous hébergez chez Menuonline ne peuvent être consultés que par les employés et partenaires de Menuonline qui ont signé des accords de confidentialité. (Voir s.14 « Que possédez-vous ? »).",
        "terms_conditions_desc7": "Toutes les informations personnelles identifiables (IPI) sont conservées uniquement sur des serveurs situés au Canada. (Voir l’article 14 « Que possédez-vous ? »).",
        "terms_conditions_desc8": "Le service Menuonline est soumis aux lois du Canada et de la province de l'Ontario. (Voir art. 24 « Loi applicable »)",
        "terms_conditions_desc9": "Toute demande de données ou de contenu vous concernant émanant de particuliers, de la police ou d’autres organismes de réglementation du Canada ou de l’extérieur du Canada vous sera toujours adressée. La seule exception serait si cela nous met en infraction avec la loi canadienne ou ontarienne. Cependant, dans tous les cas, nous résisterons vigoureusement à toute divulgation de vos données par quiconque autre que vous. (Voir l’article 14 « Que possédez-vous ? »).",
        "terms_conditions_desc10": "La sécurité de Menuonline dépend de votre gestion des noms d'utilisateur et des mots de passe. Nous ne facturons pas les comptes d'utilisateur Menuonline. Créez donc un compte distinct pour chaque utilisateur et gardez les mots de passe secrets. Vous êtes responsable de votre compte. (Voir s.3 « Comment ça marche »)",
        "terms_conditions_desc11": "Vous pouvez annuler votre compte à tout moment. Nous ne remboursons pas les crédits non utilisés si vous annulez votre abonnement. (Voir § 3 « Comment ça marche »)",
        "terms_conditions_desc12": "Nous pouvons annuler votre compte à tout moment. Nous remboursons les crédits de messagerie et les services non utilisés si nous annulons votre compte. (Voir § 3 « Comment ça marche »)",
        "terms_conditions_desc13": "Vos données seront supprimées après annulation et pourront également être supprimées si un utilisateur du compte ne s'est pas connecté pendant 12 mois ou plus. Nous conserverons des données agrégées de votre compte qui n'incluent pas d'informations personnelles identifiables à des fins statistiques. (Voir s.3 « Comment cela fonctionne »)",
        "terms_conditions_desc14": "Les crédits de messagerie sont achetés à l'utilisation et n'expirent jamais. La seule exception est si vous ne vous êtes pas connecté à votre compte pendant 12 mois. Dans ce cas, nous nous réservons le droit de supprimer votre compte, vos données et les crédits associés. (Voir s.10 « Quel est le prix du service ? »)",
        "terms_conditions_desc15": "Nous travaillons dur pour garantir que Menuonline soit opérationnel à tout moment et pour vous avertir à l'avance si nous devons suspendre temporairement le service pour maintenance. Cependant, nous ne garantissons pas que le service sera opérationnel à tout moment et nous ne sommes pas responsables des pertes que vous subissez en utilisant Menuonline, y compris les pertes de votre part si le système ne fonctionne pas au moment où vous en avez besoin. (Voir s.17 « Avis de non-responsabilité »).",
        "terms_conditions_desc16": "Nous ne surveillons pas votre contenu ni les personnes à qui vous envoyez des messages. Cependant, nous surveillons les plaintes et autres problèmes créés par vos activités. Un nombre trop élevé de plaintes ou de problèmes peut entraîner l'annulation de votre service à notre discrétion. (Voir § 7 « Surveillance »)",
        "terms_conditions_desc17": "Nous nous engageons à fournir un excellent support produit pour vous aider à devenir autonome avec Menuonline. Nous fournissons une assistance sous forme de documentation et de vidéo sur notre site Web, le système de tickets d'assistance Menuonline et par téléphone. L'assistance produit est gratuite ; cependant, nous nous réservons le droit d'interrompre l'assistance pour des comptes clients spécifiques à notre discrétion. (Voir s.5 « Assistance »)",
        "terms_conditions_desc18": "Les services de produits sont destinés aux situations où vous souhaitez que nous effectuions des travaux liés à Menuonline plutôt que de le faire vous-même. Notre tarif pour les services de produits est de 135 $ par heure. Nous fournissons également des services de conseil à des tarifs plus élevés. Nous nous réservons le droit de modifier nos tarifs à tout moment. (Voir s.6 « Services de produits et de conseil »)",
        "terms_conditions_desc19": "Nous vous informerons si nous pensons qu'il y a eu une violation de vos données et nous nous efforcerons de vous fournir les détails et informations correspondants. (Voir s.14 « Que possédez-vous ? »)",
        "terms_conditions_desc20": "Nous nous réservons le droit de modifier cet accord à tout moment. Nous nous efforcerons de vous informer des modifications apportées aux conditions générales, mais l'utilisation du service signifie que vous acceptez les modifications apportées aux conditions générales. (Voir s.9 « Modifications »).",
        "terms_conditions_desc21": "Les Conditions générales et notre Politique de confidentialité ont été rédigées en anglais uniquement, et vous acceptez que la version anglaise des Conditions générales et de la Politique de confidentialité ait la priorité en cas de conflit avec une version traduite.",
        "terms_conditions_mycircle": "CONDITIONS GÉNÉRALES DE MYCIRCLE",
        "terms_conditions_last_update": "DERNIÈRE MISE À JOUR : 14 janvier 2020",
        "terms_conditions_usage_agreement": "Veuillez lire attentivement les présentes conditions générales de service (les « Conditions ») avant d'utiliser ce site Web (le « Site »). Ces Conditions exonèrent Menuonline (« Menuonline », « nous » ou « nos ») et d'autres de toute responsabilité et\/ou limitent notre responsabilité et la leur et contiennent d'autres dispositions importantes qui s'appliquent à votre utilisation de ce Site.",
        "terms_conditions_acceptance": "En visitant ou en utilisant ce Site ou le Service, vous acceptez, en votre nom propre et au nom de toute organisation au nom de laquelle vous pouvez agir (collectivement dénommées ci-après « vous »), d'accepter et de respecter les présentes Conditions pour chaque utilisation et chaque visite sur ce Site.",
        "terms_conditions_services": "1. Nos services.",
        "terms_conditions_services_desc": "Les services que nous proposons via le Site sont un service qui vous permet de créer, d'envoyer et de gérer des messages électroniques (« E-mails ») aux destinataires de votre choix (le « Service »).",
        "terms_conditions_license": "2. Licence.",
        "terms_conditions_license_desc": "Sous réserve des présentes Conditions, Menuonline vous accorde par la présente une licence non exclusive, limitée et non transférable pendant la Durée pour consulter le Site et utiliser le Service comme le permettent les fonctionnalités du Service. Nous nous réservons tous les droits non expressément accordés dans les présentes sur le Service et le Contenu Menuonline (tel que défini ci-dessous).",
        "terms_conditions_account": "3. Comment ça marche.",
        "terms_conditions_account_desc": "Pour utiliser le Service, vous devez créer un compte. Votre compte est gratuit. Votre compte vous donne accès au Service et aux « fonctions » que nous pouvons établir et maintenir de temps à autre. Pour pouvoir ouvrir un compte, vous devez :",
        "terms_conditions_account_requirements": [
            "être âgé d’au moins dix-huit (18) ans et capable de conclure des contrats ;",
            "compléter le processus d’inscription que nous avons défini ;",
            "accepter les présentes conditions générales ;",
            "fournir des informations de contact et de facturation\/paiement véridiques, complètes et à jour."
        ],
        "responsibility": "Responsabilité.",
        "responsibilityText": "Vous êtes seul responsable de l'activité qui se produit sur votre compte et vous devez garder le mot de passe de votre compte confidentiel et sécurisé. Les comptes sont gratuits, nous vous encourageons donc à créer un compte différent pour chaque utilisateur que vous possédez. Vous êtes également responsable de tout compte auquel vous avez accès, que vous ayez autorisé ou non l'utilisation de ce compte. Vous acceptez d'être seul responsable de vos interactions avec les personnes à qui vous envoyez des e-mails et vous n'enverrez des e-mails qu'aux personnes dont vous avez obtenu le consentement. Vous devez nous informer immédiatement de toute utilisation non autorisée de vos comptes.",
        "compliance_with_CASL": "Conformité à la Loi canadienne anti-pourriel (LCAP).",
        "compliance_with_CASL_text": "Le rôle de Menuonline est de faciliter la gestion des paramètres de consentement, et non de faire respecter la législation. Il est de votre responsabilité de vous conformer à la LCAP. Nous vous aidons en définissant des fonctionnalités par défaut et en facilitant l'obtention et la mise à jour du consentement exprès documenté et la gestion des dates d'expiration échelonnées pour le consentement implicite. Vous avez le contrôle total de la gestion du consentement pour votre base de données ; vous pouvez mettre à jour le statut de consentement de tous vos contacts en masse à tout moment, remplacer les paramètres par défaut et désactiver l'expiration automatique du statut de consentement implicite.",
        "closing_account": "Fermeture de votre compte.",
        "closing_account_text": "Vous ou Menuonline pouvez résilier le présent Contrat à tout moment et pour quelque raison que ce soit en adressant un préavis à l'autre partie. Si vous fournissez des informations fausses, inexactes, obsolètes ou incomplètes ou si vous utilisez le Service d'une manière qui viole les présentes Conditions générales, nous avons le droit de suspendre immédiatement votre compte et de résilier votre accès au Service en plus de tout autre recours à notre disposition. Si vous n'utilisez pas votre compte pendant douze (12) mois, nous pouvons annuler votre compte. Si nous résilions votre compte pour quelque raison que ce soit, nous vous rembourserons les crédits restants. Une fois résilié, nous supprimerons définitivement votre compte et toutes les données qui y sont associées, y compris les e-mails que vous avez envoyés. Nous pouvons conserver des données agrégées de votre compte qui n'incluent pas d'informations personnellement identifiables à des fins statistiques.",
        "emailing_content": "Envoi de contenu par courrier électronique.",
        "emailing_content_text": "Lorsque vous interagissez avec Menuonline via ce Site ou le Service, vous acceptez de fournir des informations véridiques et exactes. De plus, vous comprenez que fournir des informations confidentielles par courrier électronique est une pratique dangereuse. Vous comprenez que vous êtes seul responsable de tout ce que vous envoyez à quiconque utilisant notre Service.",
        "support": "Soutien.",
        "support_text": "Nous vous offrons une assistance pour vous aider à devenir autonome avec le Service. Nous vous fournissons une assistance sous forme de documentation et de vidéos sur le Site, via le système de tickets d'assistance Menuonline et par téléphone. Vous pouvez nous contacter en cliquant sur l'onglet « Assistance » en haut du Site. L'assistance produit est gratuite ; cependant, nous nous réservons le droit d'interrompre l'assistance pour des comptes clients spécifiques à notre discrétion.",
        "product_and_consulting_services": "Services de produits et de conseil.",
        "product_and_consulting_services_text": "Les services de produits sont destinés aux situations où vous souhaitez que nous effectuions des travaux liés à Menuonline plutôt que de le faire vous-même. Notre tarif pour les services de produits est de 125 $ de l'heure. Nous fournissons également des services de conseil à des tarifs plus élevés. Nous nous réservons le droit de modifier nos tarifs à tout moment.",
        "monitoring": "Surveillance.",
        "monitoring_text": "Nous ne surveillerons ni ne jugerons le contenu des informations transmises via le Service, mais nous enquêterons si nous recevons des plaintes concernant une éventuelle utilisation inappropriée. Menuonline peut, à tout moment et à sa seule discrétion, déterminer si des utilisations particulières sont ou non appropriées, avec ou sans préavis, conformément aux directives suivantes.",
        "term": "Terme.",
        "term_text": "La durée de validité commence lorsque vous créez un compte chez nous et se poursuit tant que vous utilisez le Service. Vous déclarez et garantissez que vous avez l'autorité et la capacité de créer un compte pour nous (y compris si vous vous inscrivez au nom d'une organisation).",
        "changes": "Changements.",
        "changes_text": "La durée de validité commence lorsque vous créez un compte chez nous et se poursuit tant que vous utilisez le Service. Vous déclarez et garantissez que vous avez l'autorité et la capacité de créer un compte pour nous (y compris si vous vous inscrivez au nom d'une organisation).",
        "payment": "PAIEMENT",
        "what_do_i_pay_for_service": "Combien dois-je payer pour le service ?",
        "what_do_i_pay_for_service_text": "Vous pouvez acheter des crédits prépayés pour envoyer des e-mails (« Crédits ») via notre Service, comme nous le décrivons en détail sur la page « Tarifs » de notre Site. Les Crédits n'expirent jamais et il n'y a pas de frais récurrents pour ces Crédits. La seule exception est que si vous n'utilisez pas votre compte pendant douze (12) mois, nous pouvons annuler votre compte et tous les Crédits inutilisés. Nous pouvons modifier nos tarifs à tout moment en mettant à jour les pages de tarifs de notre Site, ce qui s'appliquera à votre prochain achat de Crédits.",
        "how_do_i_pay_for_service": "Comment puis-je payer le service ?",
        "how_do_i_pay_for_service_text": "Vous pouvez payer vos crédits avec n'importe quelle carte de crédit. Nous pouvons modifier les modes de paiement que nous acceptons à tout moment.",
        "can_i_get_refund": "Puis-je obtenir un remboursement ?",
        "can_i_get_refund_text": "Les sommes que vous avez payées pour utiliser le Service ne sont pas remboursables si vous annulez votre compte. Si nous annulons votre compte, nous vous rembourserons les crédits restants.",
        "rights": "DROITS",
        "what_do_we_own": "Que possédons-nous ?",
        "what_do_we_own_text": "À l'exception du contenu que vous nous fournissez (y compris, sans limitation, les enregistrements de contacts, le contenu des e-mails et les informations que vous collectez via le Service) (« Votre Contenu »), tous les éléments inclus sur notre Site, le Service et tous les éléments qui y sont contenus ou transférés par celui-ci, y compris, sans limitation, les logiciels, images, textes, graphiques, illustrations, logos, brevets, marques de commerce, marques de service, photographies, audio, vidéos, musique et contenu appartenant à d'autres Utilisateurs (le « Contenu Menuonline ») (et tous les droits de propriété intellectuelle y afférents) sont notre propriété ou celle de nos concédants de licence.",
        "what_do_you_own": "Que possédez-vous ?",
        "what_do_you_own_text": "Nous ne revendiquons aucun droit de propriété sur votre contenu. Votre contenu vous appartient et vous conservez la propriété du contenu que vous téléchargez sur le service. En partageant votre contenu via le service, vous acceptez de permettre à d'autres personnes de visualiser, de modifier et\/ou de partager votre contenu conformément à vos paramètres et aux présentes conditions générales. Vous déclarez et garantissez que vous possédez ou avez l'autorisation d'utiliser tout le matériel que vous utilisez dans vos e-mails. Nous pouvons utiliser ou divulguer vos documents uniquement comme nous le décrivons dans les présentes conditions générales et dans notre politique de confidentialité.",
        "privacy_statement": "Menuonline respecte la vie privée de ses utilisateurs. Toutes les informations personnelles collectées par Menuonline via ce Site ou le Service sont soumises à la Déclaration de confidentialité de Menuonline, qui est incorporée par référence dans les présentes Conditions. Nous pouvons utiliser et divulguer vos informations conformément à notre Déclaration de confidentialité.",
        "access_to_your_content": "Menuonline ne fournira pas l'accès à votre contenu à quiconque, à l'exception des employés et des sous-traitants de Menuonline qui ont conclu des accords de confidentialité appropriés avec nous ou avec votre consentement.",
        "governmentRequest": "Si nous recevons une demande d'un gouvernement ou d'une autorité de réglementation pour avoir accès à votre contenu, nous vous informerons de la demande et solliciterons votre consentement avant de divulguer une partie de votre contenu. Nous résisterons à toute demande concernant votre contenu et ne divulguerons pas votre contenu en réponse à ce type de demande sans votre autorisation ou sans y être obligés par un tribunal compétent ou l'organisme de réglementation applicable.",
        "breachNotification": "Dans le cas où nous aurions connaissance que votre contenu a fait l'objet d'un accès, d'une utilisation, d'une copie, d'un vol, d'une perte ou d'une utilisation ou d'une divulgation non autorisés en violation des présentes conditions (une « violation »), nous vous en informerons rapidement et vous informerons de ce qui s'est passé. Vous acceptez que les violations soient traitées conformément à la loi applicable, et si nous avons besoin que vous informiez les membres de vos listes de distribution de courrier électronique, vous acceptez de le faire rapidement et selon nos instructions.",
        "contentRetention": "Nous conserverons, utiliserons et transmettrons votre contenu au Canada, sauf avec votre accord ou instruction écrite préalable (par exemple, si vous choisissez d'envoyer votre contenu à des personnes situées à l'extérieur du Canada). Les serveurs utilisés pour fournir le service sont situés au Canada.",
        "accessTrouble": "Si vous rencontrez des difficultés pour accéder à votre contenu, Menuonline vous aidera à récupérer votre contenu en fonction du temps et des matériaux disponibles.",
        "reportingAbuse": "Si vous pensez que quelqu'un enfreint l'une de ces conditions, veuillez nous en informer immédiatement. Si vous avez reçu des spams que vous pensez provenir d'un autre utilisateur du Service, si vous pensez qu'un autre utilisateur du Service a publié du contenu qui viole des droits d'auteur, ou si vous pensez qu'un autre utilisateur du Service viole ces conditions, veuillez nous contacter à l'adresse Support@menuonline.com.",
        "acceptableUse": "Vous déclarez et garantissez que votre utilisation du Service sera conforme à toutes les lois et réglementations applicables. Vous acceptez qu'il vous incombe de déterminer si le Service sera conforme et s'il convient à votre utilisation dans votre juridiction. Pour plus de certitude, vous acceptez de vous conformer (et de vous assurer que vos employés, agents et utilisateurs autorisés le feront) ...",
        "exportLaws": "Nous ne garantissons pas que le Site ou le Service sont appropriés ou disponibles pour une utilisation en dehors du Canada, et l'accès à ces derniers à partir de territoires où leur contenu est illégal est interdit. Vous ne pouvez pas utiliser, exporter ou réexporter le Contenu de Menuonline ou d'autres éléments de ce Site ou toute copie ou adaptation en violation des lois ou réglementations applicables, y compris, sans limitation, les lois et réglementations canadiennes et américaines en matière d'exportation.",
        "disclaimers": "Le Service et ce Site sont fournis « tels quels » sans aucune représentation, garantie ou condition d'aucune sorte, et Menuonline décline expressément, dans toute la mesure permise par les lois applicables, toutes représentations, garanties et conditions, expresses ou implicites, de plein droit ou autrement, y compris, sans limitation, toute garantie et condition implicite de qualité marchande, ou d'adéquation à un usage ou à une fin particulière, ou de non-violation des droits de tiers...",
        "limitLiability": "La responsabilité globale de Menuonline en vertu du présent Contrat, qu'elle soit contractuelle ou délictuelle (y compris la négligence), résultant d'une violation de garantie, d'une responsabilité stricte ou de toute autre théorie de responsabilité, sera limitée aux dommages directs d'un montant ne dépassant pas le total des frais payés à Menuonline en vertu des présentes Conditions générales au cours des douze (12) mois précédant immédiatement le mois au cours duquel l'événement le plus récent donnant lieu à la responsabilité s'est produit.",
        "liabilityExclusions": "Aucun de Menuonline, ses sociétés affiliées ou leurs directeurs, dirigeants, employés, agents ou autres représentants respectifs (collectivement « Représentants de Menuonline ») ou les opérateurs de tout réseau rattaché n'auront aucune responsabilité en relation avec ce Site ou le Service pour : (i) tout dommage indirect, consécutif, accessoire, exemplaire, punitif ou spécial ; ou (ii) pour tout dommage, qu'il soit direct, indirect, consécutif, accessoire, exemplaire, punitif ou spécial, caractérisé comme perte de revenus, perte d'économies ou de revenus ou perte de bénéfices...",
        "indemnity": "Vous indemniserez et dégagerez Menuonline et\/ou les représentants de Menuonline (les « parties indemnisées de Menuonline ») de toute réclamation formulée par des tiers, et de toute amende ou pénalité imposée à notre encontre, découlant de votre utilisation des informations consultées sur ce site ou de votre utilisation du service, et de toute violation des présentes conditions.",
        "ipInfringement": "Menuonline vous défendra, vous indemnisera et vous dégagera de toute responsabilité contre toute réclamation, demande, action, poursuite ou procédure faite ou intentée contre vous par un tiers alléguant que votre accès ou votre utilisation du Service conformément au présent Contrat viole les droits de propriété intellectuelle en vertu de la loi canadienne d'un tiers (une « réclamation pour violation de propriété intellectuelle »).",
        "equitableRelief": "Si vous violez les présentes conditions générales, nous pouvons demander une injonction (c'est-à-dire une ordonnance du tribunal pour vous arrêter) ou toute autre mesure équitable. Vous acceptez que cela s'ajoute et ne porte pas préjudice à tout autre droit que nous pouvons avoir en droit ou en équité.",
        "linksToOtherSites": "Si nous fournissons des liens vers des sites Web tiers, ils sont fournis uniquement pour des raisons de commodité. Si vous utilisez ces liens, vous quitterez notre site. Nous ne contrôlons pas ces sites tiers et ne les approuvons pas. Vous acceptez que nous ne soyons pas responsables de ces sites tiers ou de leur contenu...",
        "thirdPartyRequests": "Si nous devons fournir des informations en réponse à une demande légale ou gouvernementale relative à votre compte ou à votre utilisation du Service, vous acceptez que nous puissions vous facturer nos frais. Ces frais peuvent inclure nos honoraires juridiques et les frais liés au temps passé par nos employés à préparer la réponse à la demande.",
        "assignment": "Le présent Contrat, ainsi que tous les droits et licences accordés en vertu de celui-ci, ne peuvent être transférés ou cédés par vous, mais peuvent être cédés par Menuonline sans restriction, moyennant un préavis raisonnable.",
        "governingLaw": "Les présentes conditions générales seront régies et interprétées conformément à toutes les lois fédérales applicables et aux lois de l'Ontario, sans égard aux dispositions relatives aux conflits de lois. Vous acceptez de vous soumettre à la compétence exclusive des tribunaux de l'Ontario pour la résolution de tout litige concernant les présentes conditions générales.",
        "severability": "Si, pour une raison quelconque, un tribunal compétent juge qu'une disposition des présentes Conditions ou une partie de celles-ci est illégale ou pour une raison quelconque inapplicable, cette disposition sera alors appliquée dans la mesure maximale autorisée pour donner effet à l'intention des présentes Conditions, et le reste des présentes Conditions restera pleinement en vigueur et applicable.",
        "waiver": "Toute renonciation ou tout consentement à déroger aux exigences des présentes Conditions ne sera effectif que s'il est formulé par écrit et signé par nous, et uniquement dans le cas spécifique et aux fins spécifiques pour lesquelles il a été donné. Notre non-exercice ou notre retard à exercer un droit en vertu des présentes Conditions ne sera pas considéré comme une renonciation à nos droits.",
        "furtherAssurances": "Chaque partie s'engage à signer et à fournir tous les documents et à prendre toutes les mesures (ou à faire prendre ces mesures) nécessaires pour respecter ses obligations en vertu des présentes Conditions générales.",
        "notices": "Tous les avis adressés à Menuonline doivent être formulés par écrit et nous être envoyés par courrier électronique. Les avis qui nous sont adressés doivent être adressés à l'attention de nos représentants du service clientèle à l'adresse support@menuonline.com. Les avis qui vous sont adressés peuvent être envoyés à l'adresse ou à l'adresse électronique que vous avez fournie lors de votre inscription. En outre, nous pouvons diffuser des avis ou des messages via ce site pour vous informer des modifications apportées à ce site ou d'autres questions importantes, et ces diffusions constitueront un avis qui vous sera adressé au moment où nous les publierons.",
        "entireAgreement": "Les présentes Conditions et notre Déclaration de confidentialité constituent l'intégralité de l'accord entre vous et nous concernant votre utilisation du Site et du Service, et remplacent tous les accords, représentations et ententes antérieurs entre Menuonline et vous concernant ce sujet. En cas de conflit entre la Déclaration de confidentialité et les présentes Conditions, les présentes Conditions générales prévaudront.",
        "language": "Les parties ont expressément demandé et exigé que les présentes Conditions générales, notre Politique de confidentialité et tous les documents connexes soient rédigés en anglais. Les parties conviennent et exigent expressément que ce Contrat et tous les documents qui s'y rapportent soient rédigés en anglais. Vous acceptez que la version en langue anglaise des présentes Conditions générales et de la Politique de confidentialité ait la priorité en cas de conflit avec une version traduite.",
        "survival": "Toutes les dispositions des présentes Conditions qui, par leur nature ou leur importance, sont destinées à survivre à l'expiration ou à la résiliation des présentes Conditions, survivront, y compris, sans limitation, les articles 17 à 19."
    },
    "collect_additional_info": "Souhaitez-vous recueillir des informations complémentaires ?",
    "additionalInfoChoice": "Choisissez les informations supplémentaires que vous souhaitez collecter.",
    "additional_info_yes": "Oui",
    "additional_info_noSkip": "Non, sauter",
    "additional_info_label": "Informations Complémentaires",
    "confirm_business_location": "Confirmons d'abord l'emplacement de votre entreprise",
    "custom_get_more_reviews_form": "Formulaire personnalisé « Obtenir plus d'avis »",
    "set_up_custom_form": "Configurez votre formulaire personnalisé « Obtenir plus d'avis » par groupes",
    "reputation_name_label": "Obtenir plus d'avis Nom",
    "reputation_name_placeholder": "Entrez le nom de la réputation",
    "group_label": "Groupe",
    "group_desc_title": "Titre de la description du groupe",
    "group_desc": "Description du groupe",
    "select_group_placeholder": "Sélectionner le groupe",
    "required_group_name": "Le nom du groupe est obligatoires",
    "before_asking_review": "Avant de demander l'avis des clients, posons-leur 1 à 4 questions.",
    "feel_free_edit": "N'hésitez pas à modifier selon vos besoins.",
    "incentive_settings": "Paramètres d'incitation",
    "incentivize_customer": "Souhaitez-vous inciter votre client à donner son avis ?",
    "choose_incentive": "Choisissez l’incitatif que vous souhaitez parrainer.",
    "scan_qr_feedback": "Scannez le code QR et partagez avec nous vos précieux commentaires concernant votre expérience.",
    "qr_code": "Code QR",
    "scan_me": "SCANNEZ-MOI!",
    "or_title": "OU",
    "send_otp_title": "Envoyer OTP",
    "sign_in_title": "Se connecter",
    "email_addresss_title": "Adresse email",
    "reset_password_title": "Réinitialiser le mot de passe",
    "remember_password_title": "Mot de passe mémorisé ?",
    "digit_code_title": "Veuillez saisir le code à 4 chiffres qui sera envoyé ",
    "not_received_title": "Je n'ai pas reçu de code de vérification !",
    "resend_title": "Veuillez renvoyer",
    "sign_up_form": "Créez votre compte",
    "sign_up_sub_title": "Connectez-vous maintenant pour accéder aux dernières informations",
    "already_registered_title": "Vous avez déjà un compte Menuonline ?",
    "reserved_title": "Droits d'auteur © 2023 Menuonline. Tous droits réservés.",
    "terms_title": "Conditions d'utilisation",
    "policy_title": "politique de confidentialité",
    "sign_up_title": "S'inscrire",
    "terms_conditions_title": "Conditions générales",
    "business_type_title": "Type d'entreprise",
    "business_type_sub_title": "Veuillez sélectionner l’un des types d’entreprise qui décrit le mieux votre entreprise !",
    "salon_title": "Salon",
    "restaurant_title": "Restaurant",
    "pizza_store_title": "Magasin de pizzas",
    "food_truck_title": "Camion de restauration",
    "check_domain_title": "Vérifier le nom de domaine",
    "start_exploring_title": "Commencer à explorer",
    "sign_in_form": "Connectez-vous à votre compte",
    "sign_in_sub_title": "Connectez-vous maintenant pour accéder aux dernières informations",
    "remember_title": "Souviens-toi de moi",
    "otp_title": "OTP",
    "password_title": "Mot de passe",
    "forgot_title": "Mot de passe oublié?",
    "not_registered_title": "Vous n'avez pas de compte ?",
    "create_account_title": "Inscrivez-vous maintenant",
    "create_form_title": "Créer un formulaire",
    "first_name_title": "Prénom",
    "last_name_title": "Nom de famille",
    "email_title": "E-mail",
    "mobile_number_title": "Numéro de portable",
    "birthday_title": "Anniversaire",
    "gender_title": "Genre",
    "address_title": "Adresse",
    "short_answer_title": "Réponse courte",
    "long_answer_title": "Réponse longue",
    "single_answer_title": "Réponse unique",
    "single_checkbox_title": "Case à cocher unique",
    "multiple_choice_title": "Choix multiple",
    "drop_down_title": "Dérouler",
    "yes_no_title": "Oui ou Non",
    "description_text_title": "Texte descriptif",
    "file_upload": "Téléchargement de fichier",
    "reset_password_form": "Réinitialiser votre mot de passe",
    "reset_password_sub_title": "Réinitialisez rapidement votre mot de passe et accédez à votre compte.",
    "required_full_name_title": "Le nom complet est obligatoire",
    "required_first_name_title": "Le prénom est obligatoire",
    "valid_first_name_title": "Entrez un prénom valide",
    "valid_full_name_title": "Entrez un nom complet valide",
    "required_block_time": "Le temps de blocage est requise",
    "required_last_name_title": "Le nom de famille est obligatoires",
    "valid_last_name_title": "Entrez un nom de famille valide",
    "valid_mobile_number_title": "Entrez un numéro de téléphone mobile valide",
    "required_mobile_number_title": "Le numéro de téléphone portable est requises",
    "required_already_mobile_number_title": "Le numéro de téléphone portable est déjà requis",
    "valid_otp_title": "Entrez un OTP valide",
    "valid_email_title": "Entrez une adresse e-mail valide",
    "required_email_title": "L'email est obligatoire",
    "required_description_title": "La description est obligatoires",
    "required_description_500_characters": "Veuillez saisir au moins 500 caractères",
    "required_description_600_characters": "Veuillez saisir un maximum de 600 caractères",
    "domain_title": "Nom de domaine",
    "required_password_title": "Le mot de passe est requise",
    "required_password_regex_title": "Doit contenir un minimum de 8 caractères, au moins un caractère majuscule, un caractère minuscule, un chiffre et un caractère de casse spéciale",
    "required_terms_title": "Veuillez accepter les termes et conditions",
    "required_note_title": "La note est obligatoires",
    "required_birthday_title": "L'anniversaire est obligatoires",
    "no_access_title": "Vous n'avez pas d'accès pour le moment. Veuillez contacter votre administrateur",
    "menu_online_open_txt": "Ouvrir",
    "loyalty_program_description": "😊 Trouvez votre programme de fidélité idéal et personnalisez-le pour qu'il corresponde à votre marque !",
    "lp_list_title": "Liste des cartes perforées de fidélité",
    "lp_n_title": "Nom de la carte perforée de fidélité",
    "enter_details_title": "Entrez les détails",
    "stamp_allowed_title": "Timbre autorisé",
    "stamp_items_title": "Articles de timbres",
    "lpn_req_title": "Le nom de la carte perforée de fidélité est obligatoires",
    "valid_lpn_title": "Saisissez un nom de carte de fidélité valide",
    "brand_color_error": "La couleur de la marque doit comporter exactement 7 caractères",
    "select_dark_brand_color_title": "Veuillez sélectionner une couleur de marque valide",
    "your_brand_color_title": "La couleur de votre marque",
    "upload_logo_title": "Télécharger le logo",
    "locations_title": "Emplacements",
    "add_lp_title": "Créer un programme",
    "location_error": "Veuillez sélectionner des emplacements",
    "pvs_title": "Combien de tampons un client peut-il gagner par visite ?",
    "max_apd_title": "Quel est le nombre maximum de timbres qu'un client peut gagner en une journée ?",
    "logo_error": "Le logo est obligatoires",
    "expiry_title": "Définir l'expiration des récompenses",
    "add_more_rewards_title": "+ Ajouter plus de récompenses",
    "prev_step_1_title": "Affichez le QR pour gagner des points et profiter de vos récompenses.",
    "delete_lp_title": "Cette carte de fidélité sera supprimée définitivement.",
    "delete_user_record_title": "Cet enregistrement d'utilisateur sera supprimé définitivement.",
    "redeem_title": "Racheter",
    "lp_button_title": "Rejoignez Loyalty Punch Card",
    "lp_download_button_title": "Télécharger la carte",
    "step_0_title": "Rejoignez-nous maintenant, de délicieuses récompenses attendent les gourmets fidèles comme vous.",
    "step_1_title": "Vous avez rejoint",
    "congrats_step_title": "Vous avez désormais droit à une pizza gratuite",
    "reward_title": "Obtenez des récompenses à chaque visite",
    "visit_title": "Visitez et déverrouillez",
    "rewards_title": "Récompenses",
    "dummy_address": "3730 Frankfort Ave, Louisville Kentucky, États-Unis",
    "loyalty_qr_default_text": "Scannez le code QR, rejoignez notre carte de fidélité et profitez de récompenses",
    "lp_earn_stamp_txt": "Les clients gagnent des tampons à chaque visite.",
    "lp_get_rewards_txt": "Obtenez des récompenses à chaque visite",
    "lp_1_time_visit_txt": "Une seule visite vous sera offerte",
    "lp_uppercase_stamp_text": "Timbres",
    "lp_lowercase_stamp_text": "timbres",
    "lp_txt": "Loyauté",
    "lp_per_visit_stamp_validation_msg": "Le montant du timbre par visite doit être inférieur au montant maximum autorisé par jour",
    "lp_per_max_stamp_validation_msg": "Le montant maximal de timbres autorisé par jour doit être supérieur au montant de timbres par visite.",
    "lp_valid_value_msg": "Entrez une valeur valide",
    "lp_required_field_msg": "Ce champ est obligatoires",
    "lp_stamp_required_msg": "Le timbre est requise",
    "lp_valid_stamp_msg": "Entrez une valeur de timbre valide",
    "lp_reward_name_required_msg": "Le nom de la récompense est obligatoires",
    "lp_unique_stamp_msg": "La valeur du timbre doit être unique",
    "lp_benefits": "Un programme de fidélité augmente les visites répétées et les revenus tout en fournissant des informations sur les clients pour un marketing personnalisé",
    "lp_how_customer_txt": "Voici comment vos clients",
    "lp_punch_card_txt": "carte perforée de fidélité",
    "lp_look_txt": "je vais regarder! 😍",
    "lp_desc": "Les clients gagneront des tampons à chaque visite et, une fois qu'ils en auront collecté suffisamment, ils pourront échanger des récompenses spéciales.",
    "lp_edit": "Modifier",
    "lp_looks_good_txt": "Ça a l'air bien",
    "lp_customer_view_instruction": "Voici ce que votre client verra. Téléchargez votre logo et définissez le thème de couleurs",
    "lp_customer_earn_stamps": "Permettez aux clients de gagner des tampons à chaque visite et de gérer leurs récompenses",
    "lp_stamp_rewards_setup": "Définissez des récompenses pour gagner des timbres, permettez aux clients d'obtenir des récompenses par timbres",
    "lp_loyalty_program_setup": "Donnez le nom de votre programme de fidélité et définissez votre emplacement pour bénéficier de ce programme",
    "lp_prev_step1": "Affichez le QR pour gagner des points et profiter de vos récompenses.",
    "lp_visit_1_time_txt": "Visitez 1 fois et obtenez",
    "lp_get_free_txt": "Obtenez gratuitement",
    "lp_visit_allow_txt": "Visiter et déverrouiller",
    "lp_rewards": "Récompenses",
    "repsT": "Obtenez plus d'avis",
    "offSup": "Fournitures de bureau",
    "repsNxtV": "Lors de votre prochaine visite",
    "addRepT": "Ajouter Obtenir plus d'avis",
    "repEntrCpnTxt": "Entrez le texte du coupon",
    "repEntrCpnTxtBtn": "Entrez le texte du bouton de coupon",
    "repMngNxtV": "lors de votre prochaine visite ?",
    "repMngWldL": "Souhaitez-vous obtenir",
    "discount": "Rabais",
    "repDisTyp": "Type de remises",
    "repNameT": "Nommez votre réputation",
    "crtRepT": "Créer une réputation",
    "feedBackT": "Retour",
    "confLocT": "Confirmer l'emplacement",
    "businLocT": "Localisation de l'entreprise",
    "addInfoT": "Informations Complémentaires",
    "repNReqT": "Obtenir plus d'avis Le nom est obligatoires",
    "validRepNT": "Entrez un nom de réputation valide",
    "groupT": "Groupe",
    "groupLDescTitleT": "Pousser automatiquement les contacts dans un groupe",
    "groupLDescT": "Tous les clients qui remplissent le formulaire seront ajoutés au groupe sélectionné.",
    "que2": "Est-ce que ta nourriture était bonne ?",
    "que3": "Notre service était-il convivial ?",
    "que4": "Notre service était-il rapide ?",
    "addAdditionalT": "Souhaitez-vous ajouter des informations supplémentaires ?",
    "phNoMandT": "Le numéro de téléphone est obligatoire !",
    "qrGenT": "QR générée",
    "repFDT": "Votre contribution aidera notre équipe à mieux vous servir !",
    "letdiT": "Faisons-le",
    "tak15secT": "Cela prend 15 secondes",
    "defQue": "Souhaitez-vous recevoir de temps en temps des offres et des promotions intéressantes ?",
    "defNQue": "Souhaitez-vous que quelqu'un vous réponde ?",
    "rateOnGoogleDesc": "Auriez-vous la gentillesse de nous noter sur Google ?",
    "mayBeNT": "La prochaine fois peut-être",
    "yesSureT": "Oui bien sûr",
    "valid_name": "Entrez un nom valide",
    "nameReq": "Name is required",
    "backToMainT": "Retour à la page principale",
    "attachCopT": "Souhaitez-vous motiver votre client ?",
    "thankYouT": "Merci",
    "lftyT": "Nous attendons avec impatience votre prochaine visite !",
    "repPreT": "Aperçu de la réputation",
    "offer_exclusive_discounts": "Offrez des remises et des promotions exclusives pour attirer vos clients.",
    "couponT": "Coupon",
    "locStepDesc": "Confirmez l'emplacement de votre entreprise",
    "createRepStepDesc": "Créer un formulaire de réputation personnalisé par groupes",
    "feedbackStepDesc": "Ensemble de questions de commentaires des clients",
    "addInfoStepDesc": "Champs clients et paramètres d'incitation",
    "FedbkkWDT": "Rétroaction avec données",
    "repAnlytT": "Analyse de la réputation",
    "todayT": "Aujourd'hui",
    "rep_last_7_days_t": "Les 7 derniers jours",
    "last30DaysT": "Les 30 derniers jours",
    "lastMT": "Mois dernier",
    "custRangeT": "Gamme personnalisée",
    "queWiseAnltT": "Question Wise Analytics",
    "atL1QueErrT": "Veuillez sélectionner au moins une question",
    "deleteRepTitle": "Cette réputation sera supprimée définitivement.",
    "incvCustT": "Oui, motiver mon client",
    "collAddInfoT": "Oui, je souhaite recueillir des informations complémentaires",
    "totRewT": "Revue totale",
    "totPosRewT": "Avis totalement positif",
    "negFeedT": "Commentaires négatifs",
    "posFeedT": "Commentaires positifs",
    "fineT": "Petits caractères",
    "enterFineT": "Entrez le texte en petits caractères",
    "setThemeT": "Définir la couleur du thème",
    "que1T": "Question 1",
    "que2T": "Question 2",
    "que3T": "Question 3",
    "que4T": "Question 4",
    "entMailT": "Entrez votre email",
    "reputationQRDefTxt": "Scannez le code QR et partagez avec nous vos précieux commentaires concernant votre expérience.",
    "selUser": "Sélectionner l'utilisateur",
    "userT": "Utilisateur",
    "subUReq": "Veuillez sélectionner les utilisateurs",
    "updateLoc": "Mettre à jour l'emplacement",
    "leadGenT": "Génération de leads",
    "displayT": "Afficher",
    "ratingT": "Notation",
    "rep_dashboard_negative_feedback": "Commentaires négatifs 0%",
    "rep_dashboard_position_feedback": "Commentaires positifs 0%",
    "rep_dashboard_total_Feedback_txt": "Commentaires totaux",
    "rep_dashboard_anony_Feedback": "Commentaires anonymes",
    "rep_dashboard_Feeedback_Data_txt": "Rétroaction avec données",
    "rep_dashboard_review_txt": "Obtenez plus d'avis",
    "rep_dashboard_total_Review_txt": "Revue totalement",
    "rep_dashboard_total_positive_Review_txt": "Avis totalement positifs",
    "rep_dashboard_negative_feedback_txt": "Commentaires négatifs",
    "rep_dashboard_connection_txt": "Connexion",
    "rep_dashboard_question_wise_analytics_txt": "Question Wise Analytics",
    "rep_dashboard_date_label": "Sélectionner une date",
    "rep_dashboard_custom_range_txt": "Gamme personnalisée",
    "rep_tlt_min": "REPRÉSENTANT",
    "emojis": "Les émojis",
    "media_text": "Ajouter un médias",
    "custom_field_text": "Insérer des champs personnalisés",
    "shortlink_text": "Ajouter un lien court",
    "use_template_text": "Utiliser le modèle",
    "banner_must_have": "Doit avoir",
    "res_seamless": "Sans couture",
    "res_table_reservation": "Réservation de table",
    "res_system": "Système",
    "res_text": "Réservations",
    "md_easily": "Facilement",
    "md_manage_customers": "Gérer les clients",
    "md_visits_interactions": "Visites et interactions",
    "md_desc": "Facilitez la vie de vos clients en réservant des tables en temps réel. Gérez vos réservations sans effort, réduisez les temps d'attente et assurez une expérience agréable pour le personnel et les clients. Gardez votre restaurant complet tout en maintenant un service et une satisfaction de premier ordre.",
    "engage_convert": "Engagez et convertissez les SMS",
    "coupon_campaign": "et campagne de coupons",
    "sms_read_rate": "Les messages SMS sont lus dans 98 % des cas",
    "real_time_promo": "Diffusez des promotions en temps réel pour une action immédiate",
    "affordable_reach": "Un moyen abordable d'atteindre les clients avec un retour sur investissement solide",
    "stay_in_touch": "Restez en contact avec votre client à tout moment",
    "bc_engage_customers": "Engagez vos clients Convertissez vos SMS",
    "bc_coupon_campaign": "et campagne de coupons",
    "bc_sms_read_rate": "Les messages SMS sont lus dans 98 % des cas",
    "bc_real_time_promo": "Diffusez des promotions en temps réel pour une action immédiate",
    "bc_affordable_reach": "Un moyen abordable d'atteindre les clients avec un retour sur investissement solide",
    "bc_stay_in_touch": "Restez en contact avec votre client à tout moment",
    "ar_automate_engagement": "Automatisez l'engagement de vos clients",
    "ar_with": "avec",
    "ar_with_auto_responders": "Répondeurs automatiques",
    "ar_personalized_messages": "Envoyez automatiquement des messages personnalisés pour les anniversaires, accueillez les nouveaux clients et renouez avec les anciens clients. Gagnez du temps tout en gardant chaque interaction significative et opportune.",
    "sq_custom_forms_tools": "Formulaires et outils personnalisés - Simplifier",
    "sq_custom_forms_ci": "Interactions avec les clients",
    "sq_smart_qr_desc": "Créez des formulaires personnalisés, collectez des signatures électroniques et exploitez les codes QR intelligents pour rationaliser vos processus métier. Personnalisez chaque outil pour répondre aux besoins de vos clients et améliorer l'expérience utilisateur.",
    "gnc_get_new_cus": "Obtenez de nouveaux clients",
    "gnc_get_customers": "Depuis les médias sociaux — Rapide et facile !",
    "gnc_social_media_power": "Utilisez la puissance des plateformes sociales pour attirer de nouveaux clients et générer des ventes directement pour votre entreprise. Redirigez les commandes de livraison de tiers directement vers votre magasin ou votre plateforme de commande en ligne pour un contrôle et des revenus maximum.",
    "wifi_connect_guests": "Connectez les invités avec",
    "wifi_smart": "Wi-Fi intelligent",
    "wifi_connect_grow": "& Développez vos connaissances",
    "wifi_smart_wifi_desc": "Offrez une connexion Wi-Fi sans tracas à vos clients via un écran de démarrage de marque tout en collectant des données client précieuses pour améliorer vos efforts marketing et votre engagement.",
    "wifi_create_splash_page": "Créer une page d'accueil Wi-Fi pour les invités",
    "instant_menu_create": "Créez votre",
    "instant_menu_text": "Menu instantané",
    "instant_menu_wm": "en quelques minutes",
    "instant_menu_desc": "Améliore l'expérience client en permettant des mises à jour en temps réel, une personnalisation facile et un engagement amélioré, ce qui facilite la présentation de vos offres et la mise à jour de votre contenu.",
    "instant_menu_create_digital_menu": "Créer un menu numérique",
    "loyalty_program_rewards": "Gagnez des récompenses",
    "loyalty_program_on_visits": "à chaque visite",
    "loyalty_program_create": "Créer un programme de fidélité",
    "reputation_create": "Créer une réputation",
    "reputation_boost": "Boostez votre",
    "reputation_text": "Réputation",
    "reputation_reviews": "avec plus d'avis !",
    "reputation_desc": "Recueillez les précieux commentaires de vos clients pour améliorer votre service et votre réputation. Partagez votre expérience et aidez-nous à nous développer : votre avis compte !",
    "dashboard": "Tableau de bord",
    "blast_campaign": "Campagne de déflagration",
    "blast_campaigns": "Campagnes de dynamitage",
    "blast_campaign_sub_title": "Lancez des campagnes de diffusion pour atteindre tous vos clients en quelques clics",
    "auto_responders": "Répondeurs automatiques",
    "auto_responder": "Répondeur automatique",
    "auto_responder_sub_title": "Des promotions basées sur des événements faciles à utiliser pour vos clients",
    "smart_qr_form": "QR intelligent \/ Formulaire",
    "get_new_customer": "Obtenir un nouveau client",
    "marketing": "Commercialisation",
    "clients": "Clients",
    "group_data": "Données des groupes",
    "clients_data": "Données clients",
    "account": "Compte",
    "user_acc_info": "Informations sur le compte utilisateur",
    "current_plan": "Plan actuel",
    "payments": "Paiements",
    "location": "Emplacement",
    "location_list": "Liste des emplacements",
    "users": "Utilisateurs",
    "users_sub_title": "Liste de tous les utilisateurs et leurs détails",
    "online_profile": "Profil en ligne",
    "change_password": "Changer le mot de passe",
    "menu": "Menus",
    "reseller_dashboard": "Tableau de bord du revendeur",
    "companies": "Entreprises",
    "plans_bought": "Plans achetés",
    "agreement_t_c": "Accord CGV",
    "wifi_dashboard": "Tableau de bord Wi-Fi",
    "hot_spot": "Point chaud",
    "splash_pages": "Pages d'accueil",
    "get_more_reviews": "Obtenez plus d'avis",
    "office_supplies": "Fournitures de bureau",
    "reservation": "Réservation",
    "floor_table": "Plancher et table",
    "guest_smart_wifi": "WiFi intelligent pour les invités",
    "digital_signage": "Affichage numériques",
    "schedule_down": "Horaire",
    "business_settings": "Paramètres d'entreprise",
    "sub_user_login": "Connexion du sous-utilisateur",
    "help_center": "Centre d'aide",
    "switch_to_hub": "Passer au hub",
    "meeting_records": "Comptes rendus de réunions",
    "logout": "Déconnexion",
    "no_access_text": "Vous n'avez aucun accès pour le moment. Veuillez contacter votre administrateur",
    "menu_item": "ÉLÉMENT DE MENU",
    "menu_capital": "MENU",
    "good_day": "Bonne journée",
    "no_permission_text": "Vous n'avez pas la permission d'effectuer cette action !",
    "enter_valid_pin": "Entrez un code PIN valide",
    "add_reservation": "Ajouter une réservation",
    "edit_reservation": "Modifier la réservation",
    "per_req": "Le nombre de personnes est requises",
    "req_block_time": "Le temps de blocage est requise",
    "pending_status": "En attente",
    "booked_status": "Réservé",
    "completed_status": "Complété",
    "cancelled_status": "Annulé",
    "reservation_slot": "Emplacement de réservation",
    "block_time": "Temps de blocage",
    "slot_time": "Heure de réservation :  ",
    "template_name_req": "Le nom du modèle est obligatoires",
    "template_type_req": "Le type de modèle est requise",
    "sms_template_req": "Un modèle de SMS est requises",
    "group_req": "Le groupe est requise",
    "valid_date_time": "Entrez une date et une heure valides",
    "valid_group_name": "Entrez un nom de groupe valide",
    "reservation_mark_completed": "Cette réservation sera marquée comme terminée.",
    "customer_name": "Nom du clients",
    "date_time": "Date Heure",
    "table_no": "Tableau n°",
    "floor": "Sol",
    "customer_name_placeholder": "nom_client",
    "date_time_placeholder": "date_heure",
    "table_no_placeholder": "table_no",
    "floor_placeholder": "sol",
    "select_floor": "Sélectionnez l'étage",
    "select_table": "Sélectionner le tableau",
    "customer_name_macro": "%% nom_client %%",
    "date_time_macro": "%% date_heure %%",
    "table_no_macro": "%% numéro_table %%",
    "floor_macro": "%% sol %%",
    "template_name": "Nom du modèle",
    "login_with_4_digit_pin": "Connectez-vous avec votre code PIN à 4 chiffres",
    "login_now_to_access_latest_insights": "Connectez-vous maintenant pour accéder aux dernières nouveautés",
    "mkt_BS_Title": "SMS de sécurité",
    "step_1": "Personnaliser le message",
    "step_2": "Choisissez votre public",
    "step_3": "Message de planification",
    "step_4": "Envoyer un message",
    "step_5": "Gérer les messages",
    "bs_tab_title": "Résumé de la campagne Blast",
    "default_message": "Bonjour %% prénom %%, Rabais spécial sur le brunch ! Venez dîner avec nous aujourd'hui et obtenez un rabais exclusif de 10 % sur votre facture !",
    "bs_Title": "Choisissez un type de campagne",
    "sms_title": "SMS\/MMS",
    "reach_out_to_customer": "Communiquez avec vos clients par SMS et messages multimédias.",
    "wp_text": "WhatsApp",
    "connect_with_wp": "Connectez-vous avec vos clients via WhatsApp 💬",
    "send_discount_offers": "Envoyez des offres de réduction par SMS 📲",
    "send_coupons_via_sms": "Envoyez des coupons par SMS à vos clients 🎟️",
    "env_Cap": "Offrez des remises et des promotions exclusives pour attirer vos clients",
    "cmt_Caption": "Contactez vos clients via des messages texte et multimédias",
    "wp_caption": "Connectez-vous avec vos clients via WhatsApp pour une communication fluide et directe",
    "msg_Prev_Title": "Aperçu du message",
    "cou_Prev_Title": "Aperçu du coupons",
    "blast_SMS": "Campagne SMS\/MMS",
    "bs_P_Msg": "En file d'attente",
    "bs_S_Msg_C": "Échoué",
    "bs_F_Msg_Count": "Livré",
    "previous": "Précédent",
    "next_ST": "Suivant",
    "next_S": "Étape suivante",
    "req_Temp_Name_Title": "Le nom du modèle est obligatoires",
    "req_Template_Tx_Title": "Le texte du modèle est obligatoires",
    "add_Temp_Title": "Ajouter un modèle",
    "link_Desc": "Ajoutez un lien de votre choix afin que les clients puissent réserver avec vous via Facebook, Google ou votre propre site Web.",
    "audience_Title": "Public",
    "client_G1_Title": "Nouveaux clients",
    "client_G2_Title": "Clients récents",
    "client_G3_Title": "Clients fidèles",
    "client_G4_Title": "Clients en attente",
    "client_G5_Title": "Clients par date de connexion",
    "on_Which_Day": "Quel jour",
    "on_Day": "Le jour",
    "immediate_Desc": "Votre message sera envoyé immédiatement, veuillez cliquer sur Suivant pour commencer.",
    "never_Desc": "Cette campagne est programmée pour s'exécuter indéfiniment jusqu'à ce que vous la terminiez manuellement.",
    "end_Desc": "La campagne se terminera à la date que vous aurez choisie ici.",
    "success_Message": "Campagne enregistrée avec succès !",
    "send_Success_Message": "Campagne envoyée avec succès !",
    "msg_Name_Field_Title": "Titre de la campagne",
    "valid_campaign_title": "Entrez un titre de campagne valide",
    "msg_Type_Title": "Type de messages",
    "sms_Desc": "1 crédit par SMS",
    "mms_Desc": "3 crédits par MMS",
    "client_GTO_1": "Jour",
    "client_GTO_2": "Heures",
    "minutes": "Minutes",
    "client_GTO_4": "Semaine",
    "time_Option_1": "Jour",
    "time_Option_2": "Heures",
    "c_Grp_Day_1": "90 jours",
    "c_Grp_Day_2": "60 jours",
    "c_Grp_Day_3": "30 jours",
    "first": "D'abord",
    "second_T": "Deuxième",
    "third_T": "Troisièmement",
    "fourth_T": "Quatrième",
    "fifth_T": "Cinquième",
    "delete_BS_T": "Cette campagne sera supprimée définitivement.",
    "delete_MBS_T": "Ces campagnes seront supprimées définitivement.",
    "cFT_1": "Téléphone",
    "cFT_2": "Prénom du contactez",
    "cFT_3": "Nom de famille du contactez",
    "cFT_4": "Courriel de contacts",
    "cFT_5": "Note",
    "cFT_6": "Anniversaire",
    "cFT_7": "Nom de l'entreprise",
    "cF_Meta_Tag_1": "%% téléphone %%",
    "cF_Meta_Tag_2": "%% prénom %%",
    "cF_Meta_Tag_3": "%% nom de famille %%",
    "cF_Meta_Tag_4": "%% e-mail %%",
    "cF_Meta_Tag_5": "%% note %%",
    "cF_Meta_Tag_6": "%% anniversaire %%",
    "cF_Meta_Tag_7": "%% Nom de l'entreprise %%",
    "cF_Name_1": "téléphone",
    "cF_Name_2": "prénom",
    "cF_Name_3": "nom de famille",
    "cF_Name_4": "e-mail",
    "cF_Name_5": "note",
    "cF_Name_6": "anniversaire",
    "cF_Name_7": "Nom de l'entreprise",
    "all_clients": "Tous les clients",
    "aud_Type_2": "Groupes de clients",
    "aud_Type_3": "Groupes de clients",
    "aud_Type_T1": "Tous les clients",
    "aud_Type_T2": "Groupes de clients",
    "aud_Type_T3": "Groupes de clients",
    "new_clients": "Nouveaux clients",
    "client_G2": "Clients récents",
    "client_G3": "Clients fidèles",
    "client_G4": "Clients en attente",
    "client_G5": "Clients par date de connexion",
    "never_text": "Jamais",
    "ending_on": "Se terminant le",
    "send_T2": "Programmé",
    "send_Type_3": "Récurrent",
    "daily": "Tous les jours",
    "weekly_T": "Hebdomadaire",
    "monthly_T": "Mensuel",
    "yearly_T": "Annuel",
    "each": "Chaque",
    "on_the": "Sur le",
    "monday": "Lundi",
    "exclusive_offer": "Offre exclusive pour vous",
    "redeem_now": "Échangez maintenant",
    "redeem_with_cashier": "Veuillez échanger auprès du caissier",
    "lg_Day_2": "Mardi",
    "lg_Day_3": "Mercredi",
    "lg_Day_4": "Jeudi",
    "lg_Day_5": "Vendredi",
    "lg_Day_6": "Samedi",
    "lg_Day_7": "Dimanche",
    "msg_Pre_T": "Dominos",
    "cA_Edit_T1": "Tous",
    "cA_Edit_T2": "Compris",
    "cA_Edit_T3": "Exclus",
    "SMS": "SMS",
    "MMS": "MMS",
    "usd_T": "Dollars américains",
    "cad_T": "GOUJAT",
    "msg": "Message",
    "which_Day": "Quels jours",
    "end_Date_Tx": "Date de fin",
    "sDate_Err": "La date de début doit être supérieure ou égale à aujourd'hui",
    "eDate_Err": "La date de fin doit être postérieure à la date de début",
    "start_Date_Req": "La date de début est obligatoires",
    "end_Date_Req": "La date de fin est obligatoires",
    "time_req": "Le temps est nécessaire",
    "client_GT1": "Clients abonnés au cours des derniers",
    "client_GT2": "Clients avec connexion au cours de la dernière",
    "client_GT3": "Clients avec au moins",
    "client_GT4": "avec le dernier",
    "client_GT5": "qui n'est pas revenu dans le dernier",
    "dummy_Phone_No": "+91987-654-3210",
    "test_T": "Test",
    "dummy_Birth_Date": "01-01-2001",
    "image_Req": "Le média ou l'URL est requis",
    "time_Title": "Temps",
    "start_date": "Date de début",
    "end_date": "Date de fin",
    "auto_Send_T": "Envoyer automatiquement aux clients",
    "add_Media": "Ajouter un média",
    "in_Title": "Dans",
    "c_Temp_T": "Modèle de campagne",
    "temp_NT": "Nom du modèle",
    "type_T": "Taper",
    "select_template_type": "Sélectionnez le type de modèle",
    "sel_Temp_T": "Sélectionnez le type de modèle",
    "sms_temp_t": "Modèle de SMS",
    "temp_T": "Texte du modèle",
    "default_Msg": "Bonjour %% prénom %%, Rabais spécial sur le brunch ! Venez dîner avec nous aujourd'hui et obtenez un rabais exclusif de 10 % sur votre facture !",
    "refill_T": "Recharge",
    "avl_Credit": "Crédits disponibles",
    "req_Credit": "Nombre total de crédits requis",
    "rem_Credit": "Crédit restant",
    "refill_Credit": "Crédits à recharger",
    "clients_Req": "Sélectionnez au moins un client",
    "subclient_Req": "Les clients sélectionnés ne sont pas des abonnés.",
    "refill_Desc": "Veuillez remplir les crédits requis",
    "url_Regex": "^((http|https):\/\/).....+$",
    "sd_Invalid": "La date de début n'est pas valide",
    "ed_Invalid": "La date de fin n'est pas valide",
    "img_Url_Invalid": "URL d'image non valide",
    "time_Invalid": "L'heure n'est pas valide",
    "time_And_Date_Invalid": "Entrez une date et une heure valides",
    "time_Invalid_Bfr": "Sélectionnez une heure au moins 5 minutes plus tard que l'heure actuelle",
    "sod_Req": "L'envoi le jour même est obligatoire",
    "add_Card": "Ajouter une nouvelle carte",
    "sm_D_Rep_Title": "Rapport de livraison SMS\/MMS",
    "send_Now_T": "Envoyer maintenant",
    "schd_Now_T": "Programmer des SMS",
    "test_SMS_T": "Envoyer un SMS de test",
    "save_AD_T": "Enregistrer comme brouillon",
    "back_TE_T": "Retour à l'édition",
    "reset_tex": "Réinitialiser",
    "update_tex": "Mise à jour",
    "dum_msg1": "Pour afficher le nom du client, utilisez %% customer_name %% dans le modèle",
    "dum_msg2": "Pour afficher l'heure de réservation, utilisez %% date_time %% dans le modèle",
    "dum_msg3": "Pour afficher le numéro de table, utilisez %% table_no %% dans le modèle",
    "embedded_link": "Lien intégré",
    "img_title": "Image",
    "img_input_text": "Texte:",
    "img_join_text": " pour rejoindre notre liste",
    "img_copy_text": "Copie",
    "img_not_found": "Image générée non trouvée !!",
    "or_text": "OU",
    "web_signup_image_content": "En soumettant ce formulaire et en vous inscrivant par SMS, vous acceptez de recevoir des SMS marketing (tels que des codes promotionnels et des rappels). Des frais de messagerie et de données peuvent s'appliquer. La fréquence des messages varie. Vous pouvez vous désabonner à tout moment en répondant STOP",
    "img_download_success": "Image téléchargée avec succès",
    "embedded_link_copied": "Lien intégré copié avec succès",
    "media_url_required": "Le média ou l'URL est requis",
    "invalid_image_url": "URL d'image non valide",
    "invalid_file": "Le fichier n'est pas valide",
    "image_error_1mb": "Veuillez insérer une image de moins de 1 Mo",
    "image_error_2_5mb": "Veuillez insérer une image de moins de 2,5 Mo",
    "image_error_3mb": "Veuillez insérer une image de moins de 3 Mo",
    "change_title": "Changement",
    "drop_file_text": "Déposez le fichier ici ou cliquez pour télécharger",
    "apply_text": "Appliquer",
    "search_here": "Rechercher ici",
    "update_status": "Mettre à jour le statut",
    "reservation_deleted_permanently": "Cette réservation sera supprimée définitivement.",
    "table_text": "Tableaux",
    "add_table_button": "Ajouter une nouvelle table",
    "add_table_text": "Ajouter une table",
    "edit_table_text": "Modifier le tableau",
    "table_delete_text": "Ce tableau sera supprimé définitivement.",
    "multiple_table_delete_text": "Ces tables seront supprimées définitivement.",
    "table_error_name": "Veuillez saisir le nom de la table",
    "table_error_invalid_name": "Veuillez saisir un nom de table valide",
    "table_error_floor": "Veuillez sélectionner l'étage",
    "table_error_select": "Veuillez sélectionner le tableau",
    "table_capacity_text": "Capacité de la table",
    "capacity_text": "Capacité",
    "table_occasion_text": "Trouvez votre table pour chaque occasion",
    "table_name_text": "Nom de la table",
    "table_capacity_error": "La capacité de la table est requise",
    "cancel_text": "Annuler",
    "submit_text": "Soumettre",
    "select_valid_date": "Sélectionnez une date valide",
    "select_valid_time": "Sélectionnez une heure valide",
    "valid_contact_number": "Entrez un numéro de contact valide",
    "date_req": "La date est obligatoire",
    "date_error_later_than_today": "La date doit être postérieure ou égale à la date du jour",
    "time_error_later_than_5_minutes": "Sélectionnez l'heure au moins 5 minutes plus tard que l'heure actuelle",
    "number_person_req": "Le nombre de personnes est requis",
    "contact_no_req": "Le numéro de contact est requis",
    "contact_no": "Numéro de contact",
    "select_time": "Sélectionnez l'heure",
    "for_how_many_person": "Pour combien de personnes ?",
    "f_name": "Prénom",
    "l_name": "Nom de famille",
    "business_name": "Nom de l'entreprise",
    "business_no": "Numéro d'entreprise",
    "no_for_sign_in": "Ce numéro est utilisé pour la connexion",
    "business_email": "Courriel professionnel",
    "notes_tex": "Remarques",
    "floor_deleted_permanently": "Cet étage sera supprimé définitivement.",
    "add_floor": "Ajouter un étage",
    "edit_floor": "Modifier l'étage",
    "name_text": "Nom",
    "mobile_no": "Numéro de portable",
    "person_text": "Personne",
    "date_and_time": "Date et heure",
    "actions_text": "Actes",
    "extra_text": "Supplémentaire",
    "floor_name_req": "Le nom de l'étage est obligatoires",
    "floor_name": "Nom de l'étage",
    "status_text": "Statut",
    "category_status_req": "Le statut de la catégorie est requises",
    "table_deleted_permanently": "Ce tableau sera supprimé définitivement.",
    "tables_deleted_permanently": "Ces tables seront supprimées définitivement.",
    "back_to_home": "Retour à la page d'accueil",
    "link_copied_text": "Lien copié dans le presse-papiers",
    "cust_dash_head_to_title": "à",
    "cust_dash_head_location_title": "Emplacements",
    "select_location_title": "Sélectionnez l'emplacement",
    "all_locations_label": "tous_les_emplacements",
    "rep_customer_feedback_analytics": "😊 Suivez les commentaires de vos clients grâce à des analyses en temps réel",
    "rep_customer_txt": "Clients",
    "rep_delete_title": "Cette réputation sera supprimée définitivement.",
    "rep_qr_def_txt": "Scannez le code QR et partagez avec nous vos précieux commentaires concernant votre expérience.",
    "delete_title": "Supprimer",
    "user_list_title": "Liste des clients",
    "os_nfc_txt": "Cartes NFC",
    "os_sign_holder_txt": "Support pour panneau d'affichage",
    "os_store_Sign_holder_txt": "Porte-enseignes de magasin",
    "StaT": "Statistiques",
    "AllgT": "Tous les groupes",
    "FavT": "Préféré",
    "MostActT": "Le plus actifs",
    "grT": "Nom du groupe",
    "keywT": "Mots clés",
    "exSubT": "Message pour les abonnés existants",
    "ArchT": "Archivés",
    "gNotiSms": "Prévenez-moi de chaque nouveau contact par SMS",
    "gNotiEmail": "Informez-moi de chaque nouveau contact par e-mail",
    "reqGName": "Le nom du groupe est obligatoires",
    "validGN": "Entrez un nom de groupe valide",
    "valid_phone_no": "Entrez un numéro de téléphone valide",
    "phone_no_req": "Le numéro de téléphone est obligatoire",
    "required_message_text": "Le message est obligatoires",
    "required_discount_text": "Une remise est requises",
    "less_than_discount": "Le montant de la remise doit être inférieur à 5000",
    "discount_percentage_invalid": "Le pourcentage de remise doit être inférieur ou égal à 100",
    "discount_type_req": "Le type de remise est obligatoires",
    "discount_text_req": "Le texte du coupon est obligatoires",
    "reqContactNo": "Le numéro de contact est requises",
    "reqMsgNT": "Le titre de la campagne est obligatoires",
    "reqLinkT": "Le lien est requises",
    "delMGT": "Ce groupe sera supprimé définitivement.",
    "defMemMsg": "Tapez votre message ici",
    "add_cust_to_grp_title": "Ajouter un client au groupes",
    "group_title": "Groupes",
    "group_create_title": "Créer un groupe",
    "group_name_txt": "Nom du groupe",
    "group_table_keyword_title": "Mot-clé",
    "actions_title": "Actes",
    "clients_title": "Clients",
    "send_title": "Envoyer",
    "qr_title": "QR",
    "delete_group_txt": "Ce groupe sera supprimé définitivement.",
    "delete_groups_txt": "Ce groupe sera supprimé définitivement.",
    "delete_client_title": "Ce client sera supprimé définitivement.",
    "delete_clients_title": "Ces clients seront supprimés définitivement.",
    "delete_multiple_title": "Suppression multiple",
    "wel_sms_mms": "Envoyer un SMS\/MMS de bienvenue ?",
    "key_words_title": "Mots clés",
    "srch_plch_txt": "Rechercher ici",
    "req_location_id_title": "L'emplacement est requises",
    "create_text": "Créer",
    "view_text": "Voir",
    "immediately": "Immédiatement",
    "business_name_req": "Le nom de l'entreprise est obligatoires",
    "business_no_req": "Le numéro d'entreprise est requises",
    "valid_business_name": "Entrez un nom d'entreprise valide",
    "valid_business_no": "Entrez un numéro d'entreprise valide",
    "address_req": "L'adresse est obligatoires",
    "valid_address": "Veuillez sélectionner une adresse valide",
    "time_zone_req": "Le fuseau horaire est requises",
    "image_req": "Veuillez insérer une image inférieure à 2,5 Moins",
    "valid_file": "Le fichier n'est pas valide.",
    "logo": "Logo",
    "time_zone": "Fuseau horaire",
    "save": "Sauvegarder",
    "account_type_req": "Le type de compte est requises",
    "gst_no_req": "Le numéro GST est requises",
    "gst_no_valid": "Entrez un numéro de TPS valide",
    "set_up_payments": "Configurer les paiements",
    "billing_details": "Entrez vos informations de facturation",
    "billing_details_desc": "Vos détails de facturation seront affichés sur votre facture mensuelle de menuonline.",
    "account_type": "Type de compte",
    "select_account_type": "Sélectionnez le type de compte",
    "gst_no": "Numéro GST",
    "transaction_details": "Détails de la transaction",
    "payment_method": "Mode de paiement",
    "billing_period": "Période de facturation",
    "paid_by": "Payé par",
    "download": "Télécharger",
    "pay_now": "Payer maintenant",
    "pull_down_refresh": "Tirez vers le bas pour actualiser",
    "release_refresh": "Relâcher pour rafraîchir",
    "billing_details_text": "Détails de facturation",
    "payment_methods": "Modes de paiement",
    "invoice": "Facture",
    "invoice_to": "Facture à :",
    "issue_date": "Date d'émission",
    "due_date": "Date d'échéance",
    "amount_due": "Montant dû",
    "charges": "Frais",
    "sub_total_capitalize": "Sous-total",
    "hst": "TVH",
    "grand_total": "Total",
    "invoice_generated_on": "Facture générée le",
    "contact_us": "Contactez-nous",
    "invoice_issue": "Si vous avez parcouru votre facture et que vous avez encore des questions",
    "call": "Appel",
    "send_sms": "Envoyer un SMS",
    "payment_success": "Le paiement a réussi",
    "edit_credit_card": "Modifier la carte de crédit",
    "add_credit_card": "Ajouter une carte de crédit",
    "modify_card_info": "Modifier les informations de votre carte",
    "enter_card_info": "Entrez les informations de votre carte",
    "account_no_req": "Le numéro de compte est requises",
    "card_name_req": "Le nom sur la carte est obligatoires",
    "expiry_date_req": "La date d'expiration est obligatoires",
    "valid_expiry_date": "Entrez une date d'expiration valide",
    "valid_cvv": "Entrez un CVV valide",
    "cvv_req": "Le CVV est obligatoires",
    "card_no": "Numéro de carte",
    "name_of_card": "Nom sur la carte",
    "expiry": "Expiration",
    "mm_text": "MM\/AA",
    "cvv": "CVV",
    "set_as_default": "Définir par défaut",
    "add_new_card": "Ajouter une nouvelle carte",
    "all_credit_card": "Toutes vos cartes de crédit",
    "fastest_checkout": "Paiement le plus rapide avec une plateforme sécurisée et fiable, approuvée par un grand groupe d'entreprises",
    "coupon_button_req": "Le texte du bouton de coupon est obligatoire",
    "max19_characters_allowed": "Un maximum de 19 caractères sont autorisés",
    "fine_print_text_req": "Le texte en petits caractères est obligatoires",
    "clients_req": "Sélectionnez au moins un clients",
    "client_groups": "Groupes de clients",
    "day_text": "Jour",
    "valid_days": "La valeur du jour est trop grande, entrez des jours valides",
    "hours_text": "Heures",
    "enter_valid_hours": "La valeur des heures est trop grande, entrez des heures valides",
    "enter_valid_min": "La valeur des minutes est trop grande, entrez des minutes valides",
    "clients_with_connection_required": "Les clients avec une connexion dans le dernier sont requis",
    "connection_required": "La connexion est requises",
    "connection_value_too_large": "La valeur des connexions est trop grande, entrez une connexion valide",
    "minutes_required": "Minutes requises",
    "clients_with_at_least_connection_required": "Les clients avec au moins une connexion sont requis",
    "last_minutes_req": "Avec les dernières minutes il faut",
    "clients_not_returned_required": "Celui qui n'est pas revenu dans les dernières minutes est requis",
    "not_return_in_last_minutes_invalid": "Le retour dans les dernières minutes doit être inférieur aux dernières minutes.",
    "customerGroups": "Groupes de clients",
    "select_customer_group": "Veuillez sélectionner le groupe de clients",
    "location_required": "L'emplacement est requises",
    "start_date_required": "La date de début est obligatoire",
    "start_date_invalid": "La date de début n'est pas valide",
    "start_date_invalid_error": "La date de début doit être supérieure ou égale à aujourd'hui",
    "recurring": "Récurrent",
    "end_date_required": "La date de fin est obligatoires",
    "end_date_invalid": "La date de fin n'est pas valide",
    "end_date_invalid_error": "La date de fin doit être postérieure à la date de début",
    "time_invalid": "L'heure n'est pas valide",
    "monthly_text": "Mensuel",
    "send_day_req": "L'envoi le jour même est obligatoires",
    "loyal_clients": "Clients fidèles",
    "recent_clients": "Clients récents",
    "lapsed_clients": "Clients en attente",
    "clients_connection_date": "Clients par date de connexion",
    "scheduled_text": "Programmé",
    "weekly": "Hebdomadaire",
    "selected_clients_not_subscriber": "Les clients sélectionnés ne sont pas abonnés.",
    "message_preview_title": "Aperçu du message",
    "coupon_preview_title": "Aperçu du coupons",
    "form_text": "Formulaire",
    "preview_text": "Aperçu",
    "next_text": "Suivant",
    "send_test_SMS": "Envoyer un SMS de test",
    "save_draft": "Enregistrer comme brouillon",
    "back_to_edit": "Retour à l'édition",
    "select_payment_method": "Veuillez sélectionner le mode de paiement",
    "schedule_SMS": "Programmer des SMS",
    "send_now": "Envoyer maintenant",
    "get_more_ratings": "Obtenez plus de notes",
    "overview": "Aperçu",
    "reset_campaign": "Campagne de réinitialisation",
    "permissions": "Autorisations",
    "location_name": "Nom de l'emplacement",
    "phone_no": "Numéro de téléphone",
    "location_email_address": "Emplacement Adresse e-mail",
    "located_business": "Où est située votre entreprise?",
    "business_logo": "Logo d'entreprise",
    "congratulations": "Félicitations",
    "almost_done": "Vous avez presque fini",
    "publish": "Publier",
    "about_your_business": "À propos de votre entreprises",
    "add_your_location": "Ajoutez votre emplacement",
    "publish_location": "Emplacement de publication",
    "location_name_req": "Le nom de l'emplacement est obligatoire",
    "valid_location_name": "Entrez un nom d'emplacement valide",
    "business_logo_req": "Le logo de l'entreprise est obligatoire",
    "please_accept_terms": "Veuillez accepter les termes et conditions",
    "add_new_location": "Ajouter un nouvel emplacement",
    "edit_location": "Modifier l'emplacement",
    "add_location": "Ajouter un emplacement",
    "existing_msg_subscriber_txt": "Message pour les abonnés existants",
    "msg_capitalize_txt": "Message",
    "group_noti_sms": "Prévenez-moi de chaque nouveau contact par SMS",
    "group_noti_email": "Informez-moi de chaque nouveau contact par e-mail",
    "group_member_msg": "Il semble que vous fassiez déjà partie de notre groupe !!",
    "group_mend_msg": "STOP pour terminer. HELP pour obtenir de l'aide. Des frais de SMS et de données peuvent s'appliquer",
    "Disclaimer_title": "Texte de l'avertissement : ",
    "group_def_msg": "Nous sommes maintenant en ligne ! Consultez notre menu de services et réservez votre prochain rendez-vous dès aujourd'hui.",
    "required_msg_txt": "Le message est obligatoires",
    "required_Key_txt": "Les mots clés sont obligatoires",
    "required_mem_msg": "Le message de l'abonné est obligatoire",
    "client_list_title": "Liste des clients",
    "add_contact_txt": "Ajouter un contacts",
    "delete_all_clients_txt": "Supprimer tous les clients",
    "delete_all_clients_msg": "Etes-vous sûr de vouloir supprimer tous les clients ? Ils ne peuvent pas être récupérés.",
    "delete_all_txt": "Supprimer tout",
    "timeline_title": "Chronologie",
    "unsubscribe_title": "Se désabonner",
    "subscribe_title": "S'abonner",
    "unsubscribe_confirm_msg": "Souhaitez-vous marquer ce client comme désabonné ?",
    "subscribe_confirm_msg": "Souhaitez-vous marquer ce client comme abonné ?",
    "no_title": "Non",
    "yes_title": "Oui",
    "client_name_title": "Nom du clients",
    "source_title": "Source",
    "contact_file_Req": "Le fichier de contacts est requise",
    "req_title": "Requise",
    "opt_in_req": "Les contacts importés doivent être 100 % opt-in",
    "image_invalid_error": "Le fichier n'est pas valide.",
    "import_contacts_msg": "Importer des contacts",
    "csv_upload_title": "Fichier de contacts CSV à télécharger *",
    "csv_file_desc": "Veuillez télécharger uniquement un fichier csv - colonnes du fichier csv - Prénom, Nom, ID e-mail, Numéro de portable, Sexe, Date de naissance (MM\/JJ\/AAAA) - pas d'espaces, de tirets ou de parenthèses dans le numéro de portable",
    "to_download_title": "Pour télécharger le fichier de démonstration, cliquez ici",
    "contains_header_title": "Oui, ce fichier contient des en-têtes",
    "opt_in_100_txt": "Oui, ces contacts importés sont 100 % opt-in",
    "DisT": "Texte de l'avertissement : ",
    "gMendMsg": "STOP pour terminer. HELP pour obtenir de l'aide. Des frais de SMS et de données peuvent s'appliquer",
    "reqKeyT": "Les mots clés sont obligatoires",
    "reqMemMsg": "Le message de l'abonné est obligatoires",
    "reqMsgT": "Le message est obligatoires",
    "gMemberMsg": "Il semble que vous fassiez déjà partie de notre groupe !!",
    "gdefMsg": "Nous sommes maintenant en ligne ! Consultez notre menu de services et réservez votre prochain rendez-vous dès aujourd'hui.",
    "next_title": "Suivant",
    "male_title": "Mâle",
    "male_val": "mâle",
    "female_title": "Femelle",
    "female_val": "femelle",
    "others_txt": "Autres",
    "others_val": "autres",
    "validBirthdate": "Entrez une date de naissance valide",
    "valid_phone_no_title": "Entrez un numéro de téléphone valide",
    "required_phone_no_title": "Le numéro de téléphone est obligatoire",
    "add_new_client_txt": "Ajouter un nouveau client",
    "update_client_txt": "Mise à jour du client",
    "phone_num_text": "Numéro de téléphone",
    "dob_title": "Date de naissance",
    "select_gender_title": "Sélectionnez le sexe",
    "timelineTitle": "Chronologie",
    "confirm_location": "Confirmer l'emplacement",
    "feedback_title": "Retour",
    "rep_question_1": "L’entreprise était-elle propre ?",
    "rep_que_2": "Est-ce que ta nourriture était bonne ?",
    "rep_que_3": "Notre service était-il convivial ?",
    "rep_que_4": "Notre service était-il rapide ?",
    "business_location_req_title": "L'emplacement de l'entreprise est requise",
    "valid_location_err_txt": "Veuillez sélectionner une adresse valide",
    "rep_management_title": "Obtenez plus de gestion des avis",
    "rep_about_desc": "La réputation vous aide à augmenter votre classement Google en demandant l'avis de vos clients",
    "preview_title": "Aperçu",
    "rep_preview_txt": "Aperçu de la réputation",
    "back_title": "Dos",
    "fine_print_txt": "Veuillez échanger auprès du caissier",
    "redeem_me_title": "Rachète-moi",
    "rep_nxt_visit_txt": "Lors de votre prochaine visite",
    "device_type": "Type d'appareil utilisé",
    "connection_method": "Méthode de connexion",
    "peak_days": "Jours de pointe",
    "peak_hours": "Heures de pointe",
    "guest_by_day": "Invité de jour",
    "guest_visit": "Visite d'invité",
    "connection": "Connexion",
    "connection_duration": "Durée de la connexion",
    "guest_visit_1": "1 fois",
    "guest_visit_2": "2 fois",
    "guest_visit_3_5": "3 à 5 fois",
    "guest_visit_6_10": "6 à 10 fois",
    "guest_visit_11_25": "11 à 25 fois",
    "guest_visit_26_100": "26 à 100 fois",
    "guest_visit_100_plus": "100+ fois",
    "device_android_total": "Android total",
    "device_android": "Androïde",
    "device_desktop": "Bureau",
    "device_ios": "iOS",
    "device_ios_total": "Total iOS",
    "device_desktop_total": "Bureau total",
    "connection_duration_10": "<=10 min",
    "connection_duration_20": "11-20 min",
    "connection_duration_30": "21-30 min",
    "connection_duration_40": "31-45 min",
    "connection_duration_60": "46-60 min",
    "connection_method_email": "E-mail",
    "connection_method_sms": "SMS",
    "connection_method_google": "Google",
    "connection_method_facebook": "Facebook",
    "age_category_1": "<18",
    "age_category_2": "18-24",
    "age_category_3": "25-34",
    "age_category_4": "35-44",
    "age_category_5": "45-54",
    "age_category_6": "55+",
    "all_guest_txt": "Tous les invités",
    "new_Guest_txt": "Nouvel invité",
    "connections_txt": "Relations",
    "hotspot": "Point chaud",
    "hotspot_list": "Liste des points d'accès",
    "add_new_hotspot": "Ajouter un nouveau point d'accès",
    "hotspot_information": "Informations sur les points d'accès",
    "edit_details_button": "Modifier les détails",
    "wifi_info_message": "Connectez-vous et profitez du WiFi gratuit",
    "connection_message": "Super, vous avez été connecté à ",
    "connection_message_suffix": " Wi-Fi",
    "wifi": "Wi-Fi",
    "login_to_access": "Connectez-vous pour accéder",
    "verification_code": "Le code de vérification",
    "verification_code_message": "Veuillez saisir le code de vérification envoyé à ",
    "wifi_user_email": "adamo@gmail.com",
    "wifi_label": "Wifi",
    "your_name": "Au fait, comment t'appelles-tu ?",
    "your_birthdate": "Peux-tu nous dire, quelle est ta date de naissance ?",
    "request_guest_wifi_name": "Veuillez saisir le nom du Wi-Fi invité",
    "request_device_key": "Veuillez saisir la clé de l'appareil",
    "request_maximum_internet_access_length": "Veuillez sélectionner la durée maximale d'accès à Internet",
    "mac_address": "Adresse MAC",
    "device_port": "Port de l'appareil",
    "hardware": "Matériel",
    "current_uptime": "Temps de disponibilité actuel",
    "nearby_devices": "Appareils à proximité",
    "firmware": "Micrologiciel",
    "who_are_you": "Qui es-tu?",
    "where_to_contact_you": "Où pouvons-nous vous contacter ?",
    "your_area_code": "Quel est votre indicatif régional?",
    "connected": "Connecté",
    "delete_hotspot_message": "Ce point d'accès sera supprimé définitivement.",
    "delete_multiple_hotspots_message": "Ces points d'accès seront supprimés définitivement.",
    "speed_error": "La vitesse doit être d'au moins 0,01",
    "speed_max_error": "Veuillez saisir une valeur allant jusqu'à 1024 pour une vitesse illimitée, ou sélectionnez une valeur inférieure dans la plage prise en charge",
    "device_ssid": "SSID de l'appareil",
    "device_ssid_two": "SSID de l'appareil Deux",
    "device_ssid_two_wpa": "SSID de l'appareil Deux WPA",
    "device_key": "Clé de l'appareil",
    "select_location": "Sélectionnez l'emplacement",
    "select_maximum_internet_access_length": "Sélectionnez la durée maximale d'accès à Internet",
    "download_speed": "Vitesse de téléchargement",
    "upload_speed": "Vitesse de téléchargement",
    "network_length_1": "15 minutes",
    "network_length_2": "30 minutes",
    "network_length_3": "45 minutes",
    "network_length_4": "1 heure",
    "network_length_5": "2 heures",
    "network_length_6": "4 heures",
    "network_length_7": "6 heures",
    "network_length_8": "8 heures",
    "network_length_9": "10 heures",
    "network_length_10": "12 heures",
    "employee_wifi_name": "Nom du Wi-Fi de l'employé",
    "employee_wifi_password": "Mot de passe Wi-Fi des employés",
    "guest_wifi_name": "Nom du Wifi invités",
    "menu_other_products_txt": "Autres produits | Menu en ligne",
    "menu_home_text": "Accueil | Menu en ligne",
    "whatsapp_title": "WhatsApp",
    "select_campaign_type": "Choisissez un type de campagne",
    "select_readymade_templates": "Choisissez parmi des modèles prêts à l'emploi ou créez le vôtre",
    "campaign_title_required": "Le titre de la campagne est obligatoires",
    "type_here": "Tapez ici...",
    "location_permission_req": "Une autorisation de localisation est requise",
    "platform_permission_req": "L'autorisation de la plateforme est requises",
    "profile_picture": "Photo de profil",
    "click_to_upload": "Cliquez pour télécharger",
    "location_permission": "Autorisation de localisation",
    "pin": "Épingler",
    "platform_permission": "Autorisation de la plateforme",
    "set_pin": "Définir le code PIN",
    "list_of_users": "Liste des utilisateurs",
    "create_user": "Créer un utilisateur",
    "terms_and_condition_req": "Les conditions générales sont obligatoires",
    "terms_and_conditions": "Termes et conditions",
    "recommended": "RECOMMANDÉ",
    "mo": "\/mois",
    "unsubscribe": "Se désabonner",
    "cancel_subscription": "Etes-vous sûr de vouloir annuler l'abonnement ?",
    "upgrade": "Mise à niveau",
    "plan_upgraded_successfully": "Le plan a été mis à niveau avec succès !",
    "menu_online": "Menu en ligne",
    "support": "Soutien",
    "wifiC": "WIFI",
    "billing": "Facturation",
    "please_upgrade": "Veuillez effectuer une mise à niveau pour profiter de toutes les fonctionnalités",
    "you_have_subscribed": "Vous êtes abonné au ",
    "plan": "plan",
    "of_text": "de",
    "days": "Jours",
    "remaining_days_until_plan": " Jours restants avant que votre forfait nécessite une mise à jours",
    "manage_your_screens": "Gérez vos écrans",
    "screens_you_save": "Les écrans dont vous disposez",
    "add_more_screens": "Ajouter plus d'écrans",
    "addition_screen": "Écran d'ajout",
    "per_screen": "$ par écran",
    "per_box": "$ par boîte",
    "shipping_changes": "Frais d'expédition",
    "upto4_boxes": "jusqu'à 4 boîtes ",
    "charge_now": "Vous serez facturé maintenant",
    "updated_reoccuring": "Votre mise à jour récurrente sera",
    "current_reoccuring": "Récurrent actuel : ",
    "no_text": "Non",
    "android_box": "Boîte Android",
    "old_password_req": "L'ancien mot de passe est requis",
    "new_password_req": "Un nouveau mot de passe est requis",
    "confirm_password_req": "Confirmer le mot de passe est requise",
    "password_do_not_match": "Les mots de passe ne correspondent passe",
    "old_password": "Ancien mot de passe",
    "new_password": "Nouveau mot de passe",
    "confirm_password": "Confirmez le mot de passe",
    "copy_working_hours": "Copier ces horaires de travail sur tous les jours ?",
    "yes_copy": "Oui, copier",
    "closed": "Fermé",
    "opening_time": "Heures d'ouverture",
    "closing_time": "Heure de fermeture",
    "description": "Description",
    "file_exist": "le fichier existe déjà, veuillez sélectionner un autre fichier",
    "bussiness_images": "Images d'entreprise",
    "display_info_on_market_place": "Souhaitez-vous afficher les informations ci-dessous sur votre Marketplace ?",
    "profile_ready": "Votre profil est prêt !",
    "client_book_with_online": "Les clients peuvent désormais réserver avec vous en ligne. Partagez le lien ci-dessous pour commencer.",
    "copy_link": "Copier le lien",
    "see_your_profile": "Voir votre profil",
    "ok_got_it": "Ok, j'ai compris",
    "preview_profile": "Prévisualisez votre profil et voyez à quoi il ressemblerait.",
    "opening_hours": "Horaires d'ouverture",
    "display_data": "Afficher les données",
    "manage_profile": "Gérer le profil",
    "phone_req": "Le téléphone est requise",
    "market_place_img_req": "Les images du marché sont obligatoires",
    "add_new_market_place": "Ajouter un nouveau marché",
    "edit_market_place": "Modifier la place de marché",
    "no_reviews_yet": "Pas encore d'avis",
    "good": "Bien",
    "average": "Moyenne",
    "add_marketPlace": "Ajouter une place de marché",
    "all_title": "Tous",
    "included_title": "Compris",
    "excluded_title": "Exclus",
    "clients_subscribed": "Clients abonnés au cours des derniers",
    "clients_groups": "Groupes de clients",
    "customer_groups": "Groupes de clients",
    "audience_title": "Public",
    "client_gt5": "qui n'est pas revenu dans le dernier",
    "select_all": "Sélectionner tout",
    "modify": "Modifier",
    "campaign_title": "Titre de la campagne",
    "msg_type": "Type de message",
    "enter_discount": "Entrer la remise",
    "discount_type": "Type de remises",
    "coupon_text": "Texte du coupon",
    "enter_coupon_text": "Entrez le texte du coupon",
    "coupon_button_text": "Texte du bouton de coupon",
    "enter_coupon_button_text": "Entrez le texte du bouton de coupon",
    "fine_prin": "Petits caractères",
    "enter_fine_prin": "Entrez le texte en petits caractères",
    "campaign_dec": "Vous pouvez modifier la campagne 30 minutes avant son heure de début. Les frais pour la campagne SMS seront facturés 30 minutes avant l'heure de début de la campagne.",
    "blast_text_message_dec": "Votre SMS est prêt. Sélectionnez votre mode de paiement et envoyez vos messages.",
    "payment_completed": " Le paiement sera effectué à ",
    "total_cost": "Coût total",
    "close_title": "Fermer",
    "friday": "Vendredi",
    "saturday": "Samedi",
    "sunday": "Dimanche",
    "thursday": "Jeudi",
    "tuesday": "Mardi",
    "wednesday": "Mercredi",
    "port_txt": "Port",
    "today_title": "Aujourd'hui",
    "yesterday_title": "Hier",
    "last_30_days_txt": "Les 30 derniers jours",
    "this_month_txt": "Ce mois-ci",
    "last_month_txt": "Mois dernier",
    "valid_date_title": "Sélectionnez une date valide",
    "valid_business_name_txt": "Entrez un nom d'entreprise valide",
    "req_bus_add_txt": "L'adresse de l'entreprise est obligatoires",
    "req_domain_name_txt": "Le nom de domaine est obligatoires",
    "basic_info_txt": "Informations de base sur votre entreprises",
    "loyalty_qr_def_txt": "Scannez le code QR, rejoignez notre carte de fidélité et profitez de récompenses.",
    "last_stamp_txt": "Dernier timbre",
    "collected_on_txt": "Recueilli le",
    "stamp_details_txt": "Détails du timbre",
    "add_stamp_txt": "Ajouter un tampon",
    "choose_brand_color_txt": "Choisissez la couleur et la langue de votre marque 🎨",
    "change_anytime_txt": "Vous pouvez modifier cela à tout moment",
    "like_to_get_txt": "Souhaitez-vous obtenir",
    "your_next_visit_txt": "lors de votre prochaine visite ?",
    "time_to_time_get_offers": "Souhaitez-vous recevoir de temps en temps des offres et des promotions intéressantes ?",
    "respond_back_to_you": "Souhaitez-vous que quelqu'un vous réponde ?",
    "input_serve_better": "Votre contribution aidera notre équipe à mieux vous servir !",
    "do_it_txt": "Faisons-le",
    "take_15_s": "Cela prend 15 secondes",
    "rate_on_google_desc": "Auriez-vous la gentillesse de nous noter sur Google ?",
    "may_be_next_time": "La prochaine fois peut-être",
    "thank_you_txt": "Merci",
    "look_next_txt": "Nous attendons avec impatience votre prochaine visite !",
    "deleteUserTitle": "Cet utilisateur sera supprimé définitivement.",
    "deleteMUserTitle": "Ces utilisateurs seront supprimés définitivement.",
    "change_pin": "Changer le code PIN",
    "area_code": "Quel est votre indicatif régional? ",
    "add_menu": "Ajouter un menus",
    "menu_name": "Nom du menus",
    "add_menu_placeholder": "Pizza, Burger, Boissons, etc.",
    "enable_data_Collection": "Activer la collecte de données",
    "add_new_menu": "Ajouter un nouveau menu",
    "rename_menu": "Renommer le menus",
    "preview": "Aperçu",
    "generate_qr": "QR générée",
    "edit_menu": "Menu Édition",
    "remove_menu": "Supprimer le menus",
    "menu_delete_msg": "Ce menu sera supprimé définitivement",
    "menus_delete_msg": "Ces menus seront supprimés définitivement",
    "view_menu_dialoage_msg": "Souhaitez-vous recevoir un cadeau pour votre anniversaire ?",
    "skip": "Sauter",
    "cliam_your_gift": "Réclamez votre cadeau",
    "collect_form": "Formulaire de collecte",
    "enter_first_name": "Entrez le prénom",
    "enter_last_name": "Entrez le nom de famille",
    "enter_email": "Entrez votre adresse e-mail",
    "enter_dob": "Entrez la date de naissance",
    "enter_number": "Entrer le numéro",
    "select_gender": "Sélectionnez le sexe",
    "congratulations_desc": "🎉 Félicitations ! Votre demande de réclamation de cadeau a été envoyée avec succès. Notre équipe vous contactera sous peu. 🎉",
    "male_heading": "Mâle",
    "male_text": "mâle",
    "female_heading": "Femelle",
    "female_text": "femelle",
    "others_heading": "Autres",
    "other_text": "autres",
    "BirthD": "Date de naissance",
    "GenderT": "Genre",
    "EmailT": "E-mail",
    "dobT": "Date de naissance",
    "capitalize_menu": "Menus",
    "select_menu": "Sélectionner le menu",
    "manage_variant": "Gérer les variantes",
    "add_products": "Ajouter des produits",
    "add_category": "Ajouter une catégorie",
    "category_delete": "Cette catégorie sera supprimée définitivement",
    "variation_delete": "Cette variante sera supprimée du produit.",
    "product_delete": "Ce produit sera supprimé définitivement.",
    "categories_delete": "Ces catégories seront supprimées définitivement.",
    "products_delete": "Ces produits seront supprimés définitivement.",
    "category": "CATÉGORIE",
    "price": "PRIX",
    "food_product_placeholder": "Pizza, Burger, Boissons, etc.",
    "active_title": "Actif",
    "inActive_title": "Inactif",
    "status_capital": "STATUT",
    "cat_status_require": "Le statut de la catégorie est requises",
    "cat_name_require": "Le nom de la catégorie est obligatoires",
    "category_name": "Nom de la catégorie",
    "status": "Statut",
    "lgDay1": "Lundi",
    "lgDay2": "Mardi",
    "lgDay3": "Mercredi",
    "lgDay4": "Jeudi",
    "lgDay5": "Vendredi",
    "lgDay6": "Samedi",
    "lgDay7": "Dimanche",
    "is_closed_title": "Fermé",
    "book_table_title": "Réserver une table",
    "emailErrSub": "L'objet de l'e-mail est obligatoires",
    "email_subject": "Objet du courrier électronique",
    "contactUsfrmTitleEmail": "Répondre à l'email",
    "companyInfo": "Lorem Ipsum est lorem hrig, lorem ipsum est fraets.Lorem Ipsum est lorem hrig, lorem ipsum est fraets.Lorem Ipsum est lorem hrig, lorem ipsum est fraets.",
    "footerSTitle1": "À propos de Menuonline",
    "footerSTitle1Lnk2": "Caractéristiques",
    "footerSTitle1Lnk3": "Blog",
    "footerSTitle2": "Légal",
    "footerSTitle2Lnk1": "Termes et conditions",
    "footerSTitle2Lnk2": "politique de confidentialité",
    "footerSTitle3": "Pour les entreprises",
    "footerSTitle3Lnk1": "Pour les partenaires",
    "footerSTitle3Lnk2": "Tarifs",
    "footerSTitle3Lnk3": "Soutien aux partenaires",
    "footerCopyRithgTxt": "  Menuonline ou ses filiales",
    "homeTitle": "Maison",
    "contactTitle": "Contactez-nous",
    "aboutTitle": "À propos de nous",
    "homeMenuTitle": "Menus",
    "bookTableTitle": "Réserver une table",
    "vDateT": "Sélectionnez une date valide",
    "reqDateT": "La date est obligatoires",
    "dateGtErr": "La date doit être postérieure ou égale à la date du jour",
    "timeInvalid": "L'heure n'est pas valide",
    "reqTimeT": "Le temps est nécessaire",
    "timeInvalidBfr": "Sélectionnez l'heure au moins 5 minutes plus tard que l'heure actuelle ",
    "PerReq": "Le nombre de personnes est requises",
    "validcNoT": "Entrez un numéro de contact valide",
    "reqCrT": "La devise est requises",
    "reqPrT": "Le prix est obligatoires",
    "reCateT": "La catégorie est obligatoires",
    "reqVrT": "Une variante est requises",
    "reqVrVldT": "Entrez une variante valide ",
    "validLNameT": "Entrez un nom de famille valide",
    "sDateInValid": "La date de début n'est pas valide",
    "minmReq": "Le minimum est requise",
    "resLblDate": "Sélectionner une date",
    "resLblTime": "Sélectionnez l'heure",
    "perT": "Pour combien de personnes ?",
    "resLblEmail": "Veuillez fournir votre e-mail ?",
    "resLblNote": "Ajouter une note de réservation",
    "imageError1MB": "Veuillez insérer une image inférieure à 1 Mo",
    "imageError": "Veuillez insérer une image inférieure à 2,5 Moins",
    "imageError3Mb": "Veuillez insérer une image inférieure à 3 Moins",
    "imageInVldError": "Le fichier n'est pas valide.",
    "addProMT": "Menu des produits",
    "proT": "Nom du produit",
    "reqProT": "Le nom du produit est obligatoires",
    "proPT": "Prix du produit",
    "reservationSuccTitle": "Super, votre réservation est effectuée",
    "book": "Livre",
    "custzName": "Nom de personnalisation",
    "always": "Toujours",
    "proImgReq": "L'image du produit est requises",
    "selCustmT": "Veuillez sélectionner la personnalisation ou en créer une nouvelle",
    "visStR": "Le statut de visibilité est requises",
    "avlblScR": "Veuillez sélectionner le calendrier de disponibilités",
    "addonPR": "Veuillez sélectionner des produits complémentaires",
    "upsellPR": "Veuillez sélectionner les produits de vente incitative",
    "markItemR": "Veuillez sélectionner les produits marqués comme articles",
    "caloriesR": "Les calories sont nécessaires",
    "allergensR": "Veuillez sélectionner les produits allergènes",
    "prepInstR": "Des instructions de préparation sont requises",
    "staffNR": "Des notes du personnel sont requises",
    "discountR": "Une remise est requises",
    "validDiscE": "Entrez une remise valide",
    "disday": "À la journée",
    "plSelDayT": "Veuillez sélectionner le jour",
    "sTimeReq": "L'heure de début est obligatoires",
    "sTimeInValid": "L'heure de début n'est pas valide",
    "eTimeReq": "L'heure de fin est requises",
    "eTimeInValid": "L'heure de fin n'est pas valide",
    "sDateReq": "La date de début est obligatoires",
    "eDateReq": "À ce jour est requises",
    "eDateInValid": "À ce jour, c'est invalide",
    "disdate": "À partir de la date",
    "disdate1": "À ce jour",
    "disdate2": "À partir de la date",
    "currT": "Devise",
    "iconR": "Veuillez sélectionner l'icône de l'élément de marquage",
    "minT": "Minimum",
    "maxT": "Maximum",
    "itemNT": "Nom de l'article",
    "itemPT": "Prix de l'article",
    "descProT": "Décrivez votre produit",
    "cateT": "Catégorie",
    "selProCateT": "Sélectionnez votre catégorie de produit",
    "reqMT": "Le nom du menu est obligatoire",
    "ViewMenu": "Voir le menu",
    "CopyMenu": "Copier le menu",
    "EditMenu": "Menu d'édition",
    "RemoveMenu": "Supprimer le menu",
    "welcomeMnuTitle": "Bienvenue à",
    "reviewT": "Avis",
    "userErrorMsg": "Désolé, une erreur s'est produite !!!",
    "reqCateT": "Le nom de la catégorie est obligatoire",
    "mngCustT": "Gérer la personnalisation",
    "custNReq": "Le nom de personnalisation est requis",
    "incReq": "Inclus est obligatoire",
    "minmValid": "Entrez une valeur minimale valide",
    "maxmReq": "Le maximum est requis",
    "cust": "Personnalisation",
    "crCust": "Créer une nouvelle personnalisation",
    "custList": "Liste de personnalisation",
    "delCustmzT": "Cette personnalisation sera supprimée définitivement.",
    "mkNameR": "Le nom de l'élément marqué est obligatoires",
    "mkDelT": "Cet élément de marque sera supprimé définitivement.",
    "hideText": "Cacher",
    "showText": "Montrer",
    "device_mac_txt": "Appareil Mac",
    "delete_hotspot_txt": "Ce hotspot sera supprimé définitivement.",
    "delete_hotspots_txt": "Ces hotspots seront supprimés définitivement.",
    "emp_wifi_name": "Nom du Wi-Fi de l'employé",
    "max_limit_txt": "Saisissez « 1024 » pour la vitesse afin de la désigner comme ILLIMITÉE. Cette valeur supprime toutes les limites, permettant un débit maximal",
    "device_port_txt": "Port de l'appareil",
    "firmware_txt": "Micrologiciel",
    "hotspot_info_txt": "Informations sur les points d'accès",
    "editDBtn": "Modifier les détails",
    "birth_date": "Date de naissance",
    "di_theme_clr": "Couleur du thème exactement à 7 chiffres",
    "color_Invalid_txt": "Sélectionnez un code couleur valide",
    "Req_theme_clr": "La couleur du thème est obligatoires",
    "select_valid_color_txt": "Veuillez sélectionner une couleur de thème valide",
    "req_redir_link": "Un lien de redirection est requises",
    "val_redir_link": "Entrez un lien de redirection valide",
    "req_business_name_txt": "Le nom de l'entreprise est obligatoires",
    "splash_preview": "Aperçu de Splash",
    "create_new_splash": "Créer un nouveau splash",
    "splash_page": "Page d'accueil",
    "splash_page_builder": "Générateur de pages de démarrage",
    "redirect_link": "Lien de redirection",
    "theme_color": "Couleur du thème",
    "enable_social_login": "Activer les options de médias sociaux pour la connexion des utilisateurs",
    "google": "Google",
    "facebook": "Facebook",
    "is_mandatory": "Est-ce obligatoire ?",
    "field": "Champ",
    "name": "Nom",
    "first_name": "Prénom",
    "last_name": "Nom de famille",
    "birthdate": "Date de naissance",
    "gender": "Genre",
    "email": "E-mail",
    "dob": "Date de naissance",
    "zip_code": "Code postal",
    "required_redirect_link": "Un lien de redirection est requises",
    "valid_redirect_link": "Entrez un lien de redirection valide",
    "required_theme_color": "La couleur du thème est obligatoires",
    "theme_color_length": "Couleur du thème exactement à 7 chiffres",
    "required_name": "Le nom est obligatoires",
    "delete_splash_message": "Vous souhaitez supprimer ce splash ?",
    "delete_multiple_splashes_message": "Vous souhaitez supprimer ces éclaboussures ?",
    "user_login_required": "La connexion de l'utilisateur est requises",
    "set_theme_color": "Définir la couleur du thème",
    "splash_colllect_additional_info_txt": "Recueillir des informations supplémentaires",
    "verify_mobile_otp_message": "Souhaitez-vous envoyer un OTP pour vérifier le numéro de téléphone portable ?",
    "add_company": "Ajouter une entreprise",
    "edit_company": "Modifier l'entreprise",
    "ds_device": "Dispositif d'affichage numérique",
    "ds_device_activation_fee": "Frais d'activation d'un appareil d'affichage numérique",
    "wifi_hardware": "Matériel Wi-Fi",
    "menu_design": "Conception de menus",
    "ds_hardware": "Matériel d'affichage numérique",
    "company_name_req": "Le nom de l'entreprise est obligatoires",
    "known_name_req": "Le nom connu est requises",
    "no_location_req": "Le numéro d'emplacement est requises",
    "minimum_location": "Veuillez saisir au moins 1 emplacement",
    "terms_req": "Les conditions sont obligatoires",
    "notes_req": "Des notes sont requises",
    "sms_charge_req": "Des frais de SMS sont requises",
    "menuonline_quantity_req": "Menu en ligne La quantité est requises",
    "menuonline_cost_req": "Le coût du menu en ligne est obligatoires",
    "ds_quantity_req": "Affichage numérique La quantité est requises",
    "ds_monthly_cost_req": "Un coût mensuel pour l'affichage numérique est requises",
    "reputation_quantity_req": "La quantité de réputation est requises",
    "reputation_cost_req": "Le coût de la réputation est requises",
    "marketing_quantity_req": "La quantité de marketing est requises",
    "marketing_cost_req": "Le coût du marketing est requises",
    "tax_percentage_req": "Le pourcentage d'impôt est requises",
    "router_hardware_quantity_req": "La quantité de matériel de routeur est requises",
    "wifi_hardware_cost_req": "Le coût du matériel WIFI est requises",
    "activation_cost_req": "Le coût d'activation est requises",
    "wifi_quantity_req": "La quantité WIFI est requises",
    "wifi_router_req": "Un routeur Wifi est requises",
    "menu_design_quantity_req": "Conception de menu La quantité est requises",
    "menu_design_cost_req": "Le coût de conception du menu est requises",
    "quantity_req": "La quantité est requise",
    "cost_req": "Le coût est requises",
    "sheduledown_quantity_req": "La quantité à planifier est requise",
    "sheduledown_cost_req": "Le coût de la réduction du calendrier est requises",
    "loyalty_program_quantity_req": "Programme de fidélité La quantité est requise",
    "loyalty_program_cost_req": "Le coût du programme de fidélité est obligatoires",
    "loyalty_program_sms_quantity_req": "La quantité de SMS du programme de fidélité est requise",
    "loyalty_program_sms_cost_req": "Le coût du SMS du programme de fidélité est obligatoires",
    "comapny_created": "Entreprise créée avec succès",
    "comapny_updated": "Mise à jour de l'entreprise avec succès",
    "billing_info": "INFORMATIONS DE FACTURATION",
    "business_name_acc_holder": "Nom de l'entreprise (titulaire du compte)",
    "client_first_name": "Prénom du client",
    "client_last_name": "Nom du client",
    "dba_known_as": "DBA connu sous le nom de",
    "business_address": "Adresse de l'entreprise",
    "no_of_locations": "Nombre d'emplacements",
    "package_details": "DÉTAILS DU FORFAIT",
    "terms": "Termes",
    "one_month": "1 mois",
    "per_sms_charge": "Frais par SMS",
    "plan_test": "PLAN : TESTer",
    "desc_capital": "DESCRIPTION",
    "qty_capital": "Qté",
    "cost_capital": "COÛT",
    "total_price_capital": "PRIX TOTAL",
    "quantity": "Quantité",
    "can_not_access": "Vous ne pouvez pas sélectionner plus de",
    "licenses": "licences",
    "monthly_cost": "Coût mensuel",
    "plan_cost": "Coût du plan",
    "tax": "Impôts",
    "tax_percentage": "Pourcentage d'impôt",
    "monthly_cost_after_tax": "Coût mensuel après impôts",
    "activation_one_time_charge": "Activation et facturation unique",
    "sub_total": "SOUS-TOTAL",
    "action": "Action",
    "cost": "Coût",
    "shipping_charge": "Frais d'expédition",
    "other": "Autres",
    "additional_cost": "Coût supplémentaire (Autres frais)",
    "other_tax": "Autres impôts",
    "total": "Total",
    "license_statistics": "Statistiques sur les licences",
    "total_licenses": "Nombre total de licences",
    "available_licenses": "Licences disponibles",
    "stock": "Action",
    "has_permission": "A la permission",
    "avg_stock_price": "Cours de l'action AVG",
    "average_price": "Prix moyen",
    "allocated": "Attribué",
    "reward_regulars": "Récompensez les habitués",
    "not_add_coupon_url": "Impossible d'ajouter l'URL du coupon. La longueur maximale du message est de 160",
    "like_attach_coupon": "Souhaitez-vous joindre un coupon ?",
    "advance_scheduling": "Planification à l'avance",
    "choose_day": "Choisissez le(s) jour(s) pour envoyer le message",
    "select_essage_window": "Sélectionner la fenêtre de message",
    "subscription_outside_delivery_window": "Si un utilisateur s'abonne en dehors de la fenêtre de livraison, il recevra votre répondeur automatique dès que possible.",
    "remaining": "Restant",
    "processing_request": "Une erreur s'est produite lors du traitement de votre demande.",
    "list_companies": "Liste des entreprises",
    "are_you_sure": "Es-tu sûr..?",
    "signin_agreement": "En confirmant cela, l'entreprise créera sans signer d'accord.",
    "status_updated": "Mise à jour du statut réussie",
    "status_failed": "Impossible de mettre à jour le statut",
    "new_editor_status_updated": "Le statut du nouvel éditeur DS a été mis à jour avec succès.",
    "user_name": "Nom d'utilisateur",
    "known_as": "connu sous le nom de",
    "agreement_signed": "Accord signé",
    "deactivate": "Désactiver",
    "activate": "Activer",
    "login_to_user_acc": "Se connecter au compte utilisateur",
    "disable": "Désactiver",
    "enable": "Activer",
    "ds_new_editor": "DS Nouveau rédacteur en chef",
    "sign_agreement": "Signer l'accord",
    "agreement_sign": "Accord sans signature",
    "view_agreement": "Voir l'accord",
    "download_agreement": "Télécharger l'accord",
    "add_deleted_user": "Ajouter un utilisateur supprimé",
    "deleted_user": "Supprimer l'utilisateur",
    "favourite": "Préféré",
    "refresh": "Rafraîchir",
    "delete_chat": "Supprimer la discussion",
    "responsive_drawer": "Tiroir réactif",
    "delete_contact": "Supprimer le contacts",
    "clear_chat_history": "Effacer l'historique des discussions",
    "clear_chat_history_dec": "Etes-vous sûr de vouloir supprimer cette discussion ?",
    "clear_contact_dec": "Etes-vous sûr de vouloir supprimer ce contact ?",
    "select_contact": "Sélectionner un contactez",
    "new_conversation": "Démarrer une nouvelle conversation",
    "type_msg": "Tapez votre message ici",
    "select_delete_client": "Veuillez sélectionner le client à supprimer",
    "select_delete_client_chat": "Veuillez sélectionner le client pour supprimer le chat",
    "select_delete_client_chat_err": "Vous n'avez pas de chat avec ce client",
    "acquire_customers_business": "Acquérir de nouveaux clients auprès d’autres entreprises.",
    "customers_from_events": "Obtenez des clients lors d'événements et amenez-les chez vous !",
    "customers_from_ads": "Obtenez des clients grâce aux annonces dans les journaux.",
    "smart_pamphlets": "Créez des brochures intelligentes pour collecter des données clients.",
    "smart_qr_group": "Un QR intelligent pour rejoindre un groupe de clients.",
    "opt_in_number": "Permettre aux clients d'opter pour un numéro.",
    "qr_menu": "Créez un code QR pour afficher votre menu.",
    "collect_birthdays": "Collectez les anniversaires et les fêtes de fin d'année du client.",
    "join_loyalty_program": "Inciter de nouveaux clients à rejoindre mon programme de fidélité.",
    "create_feedback_forms": "Créez des formulaires pour recueillir les commentaires des clients.",
    "exclusive_discounts_promotions": "Offrez des remises et des promotions exclusives pour les promotions croisées.",
    "group_smart_qr_opt_in": "Après avoir créé un groupe, vous pouvez générer et sélectionner un code QR intelligent, permettant aux clients d'adhérer instantanément à un groupe",
    "group_smart_qr_opt_in_dec": "Après avoir créé un groupe, widget d'image permettant aux clients d'accepter l'utilisation de SMS et de recevoir des promotions, par exemple, envoyez « pizza » par SMS au 555-555-5555",
    "in_store_discount_next_visit": "Bénéficiez d'une réduction en magasin lors de votre prochaine visite dans nos locaux",
    "delivery_redirection": "Créer une redirection de livraisons",
    "additional_info": "Informations Complémentaires",
    "add_url": "Ajouter une URL",
    "custom_fields": "Champs personnalisés",
    "meta_tag": "Balise méta",
    "max_chars": "Nombre max de caractères",
    "add": "Ajouter",
    "update_campaign": "Campagne de mise à jour",
    "last_week_required": "la semaine dernière est obligatoires",
    "large_week_value": "La valeur de la semaine est trop grande, entrez une semaine valide",
    "less_than_last_week": "Le retour de la semaine dernière doit être inférieur à celui de la semaine dernière.",
    "last_week_req": "Il n'est pas nécessaire de retourner la semaine dernière",
    "birthday_special": "Spécial anniversaire",
    "birthday_message": "Message automatique envoyé le jour de leur anniversaire avec des salutations personnalisées et des offres alléchantes",
    "birthday_sms": "Créez des vœux d'anniversaire personnalisés pour SMS\/MMS",
    "target_audience": "Choisissez le public cible",
    "client_by_birthday": "Client par date d'anniversaire",
    "clients_once_year": "Cette campagne est envoyée automatiquement aux clients une fois par an ",
    "auto_responder_default_msg_1": "Vous êtes l'un de nos meilleurs clients à qui nous aimons offrir des réductions ! Que vous soyez toujours riche et en bonne santé. Joyeux anniversaire ! %% prénom %%",
    "campaign_send_to_client_birthday": "Cette campagne automatique est envoyée aux clients dont la date d'anniversaire est fixée, vous pouvez personnaliser le ciblage ci-dessous.",
    "get_more_engagement": "Obtenez plus d'engagement en envoyant des SMS d'anniversaire 🎂",
    "welcome_new_clients": "Bienvenue aux nouveaux clients",
    "make_lasting_impression": "Faites une impression durable en saluant et en accueillant chaleureusement les nouveaux convives",
    "personalized_greetings": "Créez des salutations personnalisées pour les nouveaux clients",
    "greetings_to_new_customers": "Cette campagne enverra des salutations de bienvenue aux nouveaux clients le jour suivant leur première interaction avec votre entreprise",
    "audience_predefined": "L'audience de ce répondeur automatique est prédéfinie, nous l'avons !",
    "send_clients_subscribed": "Ce répondeur automatique est configuré pour envoyer des messages aux clients abonnés au cours de la dernière ",
    "first_interaction": "  après leur première interaction.",
    "default_msg_2": "Nous espérons que vous avez apprécié votre première visite. Nous serions ravis de vous revoir bientôt, c'est pourquoi nous vous offrons une réduction rien que pour vous ! Cliquez sur le lien pour réserver votre prochain rendez-vous avec nous et profitez de votre offre spéciale en ligne dès maintenant.",
    "new_clients_update": "Bienvenue aux nouveaux clients, mettez à jour le texte de la campagne",
    "new_clients_warm_greeting": "Accueillez les nouveaux clients avec un message d'accueil chaleureux 💌",
    "win_back_clients": "Reconquérir des clients",
    "re_engage_past_customers": "Réengagez vos anciens clients avec des offres irrésistibles pour les encourager à revenir",
    "target_disengaged_clients": "Ciblez les clients désengagés pour qu'ils reviennent avec une offre spéciale, une tactique éprouvée pour attirer les clients.",
    "campaign_sends_to_clients": "Cette campagne est envoyée aux clients qui ne sont pas revenus après une certaine période de temps.",
    "customize_targeting": "Personnalisez le ciblage ci-dessous pour envoyer des SMS\/MMS aux clients inactifs.",
    "clients_with_at_least": "Clients avec au moins ",
    "connection_last": " connexion dans le dernier ",
    "return_in_last_week": " semaine, mais n'est pas revenu la dernière ",
    "arr_menu_44": " semaine",
    "df_msg_4": "Nous voulons tellement que vous reveniez que nous allons vous offrir 10 % de réduction rien que pour votre retour ! Vous voulez nous essayer à nouveau aujourd'hui ?",
    "update_campaign_text": "Texte de la campagne de mise à jour « Reconquérir des clients »",
    "re_invite_customers": "Réinviter les clients qui ne sont pas revenus depuis un certain temps",
    "loyal_patrons": "Montrez votre reconnaissance aux clients fidèles en leur offrant des remises spéciales et des récompenses de fidélité",
    "surprize_top_spenders": "Surprenez les plus gros dépensiers avec des offres personnalisées et spéciales.",
    "campaign_automatically_send": "Cette campagne envoie automatiquement des récompenses pour augmenter la fréquentation de votre entreprise.",
    "sms_loyal_clients": "Cette campagne envoie des SMS\/MMS aux clients fidèles, vous pouvez modifier le ciblage ci-dessous.",
    "or_more_connection": " ou plus de connexion, au cours de la dernière ",
    "week_period": " période d'une semaine.",
    "default_msg_5": "Vous êtes client depuis longtemps maintenant ! Waouh, le temps passe vite ! Nous vous remercions et vous remercions d'être parmi nous.",
    "reward_regulars_update": "Texte de la campagne de mise à jour des récompenses pour les habitués",
    "special_discounts": "Appréciez et offrez des remises spéciales💎",
    "leave_positive_online": "Encouragez les clients à laisser des avis positifs en ligne pour améliorer la réputation du restaurant",
    "clients_service_online": "La campagne encourage vos clients à évaluer votre service en ligne.",
    "increase_your_company": "Ce répondeur automatique est envoyé pour augmenter la note en ligne de votre entreprise.",
    "send_message_after": "Envoyer un message après ",
    "min_connection": " min de la connexion",
    "default_msg_6": "Nous espérons que vous avez apprécié votre première visite, nous serions ravis de vous revoir bientôt ! [URL] Cliquez sur le lien pour nous évaluer sur Google.",
    "ratings_update": "Obtenez plus de texte de campagne de mise à jour des notes",
    "friendly_nudge": "Obtenez plus d'avis avec un petit coup de pouce amical ⭐",
    "thanks_for_visiting": "Merci de votre visite",
    "express_gratitude": "Exprimez votre gratitude aux clients qui ont choisi votre entreprise avec des messages de remerciement sincères",
    "thank_you_message": "Créez un message de remerciement pour vos clients.",
    "clients_for_visiting": "Envoyez un merci chaleureux à vos clients pour leur visite chez vous.",
    "campaign_audience": "Cette audience de campagne automatique est prédéfinie, nous l'avons !",
    "campaign_automatically_sends": "Cette campagne automatique envoie automatiquement après ",
    "minutes_customer_visit": " minutes de visite client",
    "default_msg_7": "Nous vous remercions d'être notre précieux client. Nous sommes très reconnaissants du plaisir que vous avez eu à nous servir et espérons avoir répondu à vos attentes.",
    "visiting_update_campaign": "Merci de votre visite pour mettre à jour le texte de la campagne",
    "guests_thank_you": "Remerciez vos invités pour leur visite 😊",
    "download_title": "Télécharger",
    "qr_gen_title": "QR générée",
    "qr_download_s_title": "Code QR téléchargé avec succès",
    "sel_dark_brand_clr_msg": "Veuillez sélectionner une couleur de grange valide",
    "manage_customer_stamp_rewards": "Gérer les récompenses des tampons clients 🎁",
    "sel_loc_menu_title": "Sélectionnez des emplacements pour votre menu",
    "ans_req": "La réponse est obligatoires",
    "valid_reputation_name": "Entrez un nom de réputation valide",
    "reviews_name_req": "Obtenir plus d'avis Le nom est obligatoires",
    "birthdate_required": "La date de naissance est obligatoires",
    "gender_required": "Le sexe est obligatoires",
    "valid_birthdate_required": "Entrez une date de naissance valide",
    "custom_delivery_redirection": "Créer un formulaire de redirection de livraison personnalisé par groupes",
    "customer_fields_incentive_settings": "Champs clients et paramètres d'incitation",
    "delivery_redirection_text": "Redirection de livraisons",
    "delivery_redirection_success": "Félicitations ! Votre widget de redirection de livraison a été créé ! 🎉",
    "swipe_to_preview_redirection": "Faites glisser pour voir à quoi ressemblera votre formulaire de redirection de livraisons",
    "enter_item_name": "Entrez le nom de l'élément",
    "home_page_text": "Texte de la page d'accueil",
    "settings": "Paramètres",
    "anniversary_required": "La date d'anniversaire est obligatoires",
    "valid_anniversary": "Entrez une date d'anniversaire valide",
    "form_submited": "formulaire soumis avec succès",
    "notifications": "Notifications",
    "discount_message": "Réclamez dès maintenant votre réduction de 20 % sur la pizza !",
    "is_required": " est requise",
    "section_title_required": "Le titre de la section est obligatoires",
    "section_dec_required": "La description de la section est requise",
    "client_details_required": "Les champs relatifs aux détails du client sont obligatoires",
    "compliance": "Conformité",
    "SMS_campaign1": "Campagne SMS 1",
    "mobile_number_mandatory": "Le numéro de portable est obligatoire",
    "new_answer": "Nouvelle réponse",
    "new_question": "Nouvelle question",
    "add_new_question": "Ajouter une nouvelle réponse",
    "select": "Sélectionner",
    "group_customers_question": "Où souhaitez-vous regrouper vos clients ?",
    "contacts_added_to_group": "Tous les contacts qui soumettent le formulaire seront ajoutés au groupe sélectionné",
    "edit_client_details_section": "Modifier la section Détails du client",
    "client_details_fields": "Champs des détails du client",
    "enter_client_details_section_title_desc": "Saisissez le titre et la description de la section des détails du client",
    "choose_fields_displayed_client_side": "Choisissez les champs à afficher côté client",
    "section_title": "Titre de la section",
    "add_title": "Ajouter un titre",
    "section_description": "Description de la section",
    "add_description": "Ajouter une description",
    "enter_program_name_location": "Entrez le nom et l'emplacement du programme 📍",
    "brand_theme_clr_txt": "Couleur du thème de la marque",
    "set_loyalty_punch_card_theme": "Définissons un thème pour votre carte de fidélité 🎨",
    "upload_logo_txt": "Téléchargez votre logo",
    "recommended_image_specs": "Nous vous recommandons d'utiliser une image de 512 x 512 px pour un affichage optimal. JPG, SVG ou PNG. Taille maximale de 10 Mo.",
    "valid_rep_name_txt": "Entrez un nom de réputation valide",
    "rep_name_req_txt": "Obtenir plus d'avis Le nom est obligatoire",
    "que_req": "La question est obligatoire",
    "day_of_birthday": "le jour de l'anniversaire",
    "day_before_birthday": "3 jours avant l'anniversaire",
    "week_before_birthday": "une semaine avant l'anniversaire",
    "two_week_before_birthday": "deux semaines avant l'anniversaire",
    "de_active": "Désactiver",
    "campaign_details": "Détails de la campagne",
    "link_clicked": "Lien cliqué",
    "history": "Histoire",
    "auto_responder_summary": "Résumé du répondeur automatique",
    "vsop_1": "15 minutes",
    "vsop_2": "30 minutes",
    "vsop_3": "45 minutes",
    "vsop_4": "60 minutes",
    "vsop_5": "90 minutes",
    "vsop_6": "120 minutes",
    "in_the_last": " dans le dernier ",
    "return_in_last": " mais n'est pas revenu la dernière fois ",
    "of_the_connection": "de la connexion",
    "your_campaign": "Votre campagne",
    "you_have_successfully": "Vous avez réussi",
    "published_successfully": "publié avec succès",
    "updated_smart_campaign": "mise à jour de la campagne intelligente",
    "almost_done_text": "Presque fini !",
    "update_campaign_desc": "Votre répondeur automatique est terminé, une fois activé, il enverra des messages aux clients de manière continue. Cette campagne peut être facilement modifiée ou mise en pause à tout moment.",
    "update_and_publish": "Mettre à jour et publier",
    "reset_campaign_title": "Il supprimera définitivement le texte personnalisé, l'image, les offres de réduction et le public ciblé de votre campagne !",
    "space_wifi": " Wi-Fi",
    "custom_forms": "Formulaires personnalisés",
    "web_e_signup": "Inscription électronique sur le Web",
    "import": "Importer",
    "permissions_req": "Des autorisations sont requises",
    "redeemed": "Rachetés",
    "coupon_already_redeemed": "Coupon déjà utilisé sur",
    "autoresponder_campaigns": "Campagnes de réponse automatique",
    "autoresponder_campaign_desc": "Activez le répondeur automatique et lancez la campagne personnalisée",
    "mon": "LUN",
    "tue": "MAR",
    "wed": "ÉPOUSER",
    "thu": "JEU",
    "fri": "VEN",
    "sat": "ASSIS",
    "sun": "SOLEIL",
    "duplicate": "Double",
    "visibility": "Visibilité",
    "availability": "Disponibilités",
    "out_stok": "En rupture de stock",
    "edit_product": "Modifier le produit",
    "create_product": "Créer un produit",
    "basic_info": "Informations de base",
    "basic_sub_info": "Lorem Ipsum est simplement un texte factice de l'impression...",
    "enter_product_price": "Entrez le prix du produit",
    "upload_image": "Télécharger l'image",
    "allowed_file_formats": "Seuls les fichiers JPG et PNG avec une taille de fichier maximale de 3 Mo sont autorisés.",
    "pre_select": "Présélectionner",
    "promotions": "Promotions",
    "discount_availability": "Disponibilité des remises",
    "start_time": "Heure de début",
    "end_time": "Fin des temps",
    "select_day": "Sélectionnez le jour",
    "menu_required": "Le menu est obligatoires",
    "generated": "Générée",
    "link": "Lien",
    "variations": "Variations",
    "select_variant_to_add": "Sélectionnez la variante que vous souhaitez ajouter",
    "price_title": "Prix",
    "choose_image": "Choisir une image",
    "select_visiblity": "Sélectionner la visibilité",
    "availability_schedule": "Calendrier de disponibilités",
    "add_on_upsell": "Ajouter \/ vendre plus",
    "add_on_select_product": "Ajouter sur Sélectionner un produit",
    "upsell_product": "Vente incitative d'un produit sélectionné",
    "variant_deletion_warning": "Cette variante sera supprimée définitivement.",
    "search_variations": "Variantes de recherche",
    "add_variation": "Ajouter une variante",
    "variation_text": "Variante",
    "select_customization": "Sélectionnez la personnalisation",
    "add_new": "Ajouter un nouveau",
    "delete_customization_warning": "Voulez-vous supprimer cette personnalisation du produit ?",
    "nutritional_allergen_info": "Informations nutritionnelles et allergènes",
    "mark_item": "Marquer l'article",
    "calories_text": "Calories",
    "allergens": "Allergènes",
    "select_allergens": "Sélectionnez les allergènes",
    "special_instructions": "Instructions spéciales",
    "preparation_instructions": "Instructions de préparation",
    "staff_notes": "Notes du personnel",
    "checkbox1": "Une case à cocher pour autoriser ou interdire les instructions spéciales des clients pour cet élément de menu.",
    "menu_c": "MENU",
    "past_design": "Conception passée",
    "file_name": "Nom de fichier",
    "variation_name": "Nom de la variante",
    "grid_items_title1": "Tente de table",
    "grid_items_title2": "Menu numérique",
    "grid_items_title3": "Menu à emporter",
    "grid_items_title4": "Menu demi-page",
    "grid_items_title5": "Menu numérique 5",
    "grid_items_title6": "Menu numérique 6",
    "grid_items_title7": "Menu numérique 7",
    "grid_items_title8": "Menu numérique 8",
    "grid_items_title9": "Menu numérique 9",
    "enter_valid_form_name": "Entrez un nom de formulaire valide",
    "form_name_required": "Le nom du formulaire est obligatoire",
    "enter_valid_section": "Entrez un titre de section valide",
    "fraction_half": "1\/2",
    "fraction_two_by_two": "2\/2",
    "form_name": "Nom du formulaire",
    "contact_list": "Liste de contacts",
    "label_notify_email": "Souhaitez-vous être averti par email ?",
    "add_client_details_section": "Ajouter une section de détails sur le client",
    "demo_purpose_only": "C'est uniquement à des fins de démonstration",
    "form_updated_success": "formulaire mis à jour avec succès",
    "form_creat_success": "formulaire créé avec succès",
    "add_custom_section": "Ajouter une section personnalisée",
    "edit_custom_section": "Modifier une section personnalisée",
    "forms_list": "Liste des formulaires",
    "filled_custom_form_details": "Détails du formulaire personnalisé rempli",
    "custom_form_deletion_warning": "Ce formulaire personnalisé sera supprimé définitivement",

    "menu_link": "Lien vers le menu",
    "WebSignUpT": "Inscription électronique",
    "lifeTConT": "Des connexions à vie",
    "repCustT": "Clients réguliers",
    "liftTSubT": "Abonnés à vie",
    "overallT": "DANS L'ENSEMBLE",
    "loginCustBDT": "Clients connectés par jour",
    "deviceT": "Données démographiques des appareils",
    "ageTitle": "Âge Démographie",
    "ageT": "Groupe d'âge",
    "convert_social_media_followers_into_customers": "Convertissez les abonnés des réseaux sociaux en clients",
    "signWidDesc": "Créez un widget pour vous inscrire, créer des pages de destination et générer des coupons à partir de votre site Web ou de vos réseaux sociaux",
    "qrWidT": "QR intelligent",
    "imgWidT": "Numéro d'inscription",
    "mobile": "Mobile",
    "YESTitle": "OUI",
    "NOTitle": "NON",
    "addDelRed": "Ajouter une redirection de livraison",
    "dlredNameT": "Nommez votre redirection de livraison",
    "conf_text_req": "Un texte de confirmation est requis",
    "cFDesc": "Créez des formulaires personnalisés pour recueillir efficacement des commentaires et des informations spécifiques auprès des clients",
    "pre_review_questions_enabled": "Avant de demander l'avis des clients, posons-leur 1 à 4 questions.",
    "feel_free_edit": "N'hésitez pas à modifier selon vos besoins.",
    "option": "Option",
    "confFL": "Cette confirmation s'affichera après que votre contact aura soumis le formulaire d'inscription Web",
    "confT": "Texte de confirmation",
    "joinTitle": "Rejoindre",
    "mobValidL": "Veuillez vérifier le numéro de téléphone portable valide",
    "mobRequiredL": "Le numéro de portable est requis",

    "Business_location_label": "L'emplacement de votre entreprise",
    "qr_code_selection_txt": "Sélection de code QR",
    "choose_theme_txt": "Choisissez le thème",
    "edit_qr_details_txt": "Modifier les détails du QR",
    "save_qr_code_txt": "Enregistrer le code QR",
    "text_color_label": "Couleur du texte",
    "background_color_label": "Couleur d'arrière-plan",
    "print_txt": "Imprimer",
    "customer_will_see_txt": "Voici ce que votre client verra. Choisissez la couleur de votre marque à l’étape suivante.",
    "choose_your_brand_color_&_langauge_title": "Choisissez la couleur et la langue de votre marque",
    "custom_redirect_link_label": "Lien de redirection personnalisé",
    "redirect_user_to_menu": "Rediriger l'utilisateur vers le menu",
    "language": "Langue",
    "select_language_placeholder": "Sélectionner la langue",
    "capitalize_location_name_txt": "Nom de l'emplacement",
    "created_txt": "Créé",
    "swipe_reputation_management_txt": "Faites glisser votre doigt pour voir à quoi ressemblera votre gestion de la réputation",
    "sent_txt": "Envoyé",
    "number_label": "Nombre",
    "date_label": "Date",
    "gender_demographics_title": "Démographie par sexe",

    "step1_preview_desc": "Nommez votre formulaire QR d'inscription électronique et ajoutez-le à votre liste de groupe",
    "main_head": "Acquérir de nouveaux clients",
    "main_desc": "Attirez des clients en plaçant un code QR qui, une fois scanné, redirige les utilisateurs vers un formulaire d'inscription personnalisé.",
    "step1_description": "Acquérir de nouveaux clients auprès d'autres entreprises",
    "form_name_field_placeholder": "Inscription électronique à Restro",
    "last_step_title": "Félicitations ! Votre widget d'inscription électronique a été créé ! 🎉",
    "last_step_desc": "Faites glisser pour voir à quoi ressemblera votre formulaire d'inscription électronique",
    "events_step1_preview_desc": "Nommez votre formulaire QR d'événement et ajoutez-le à votre liste de groupe",
    "events_main_head": "Obtenez des clients grâce aux événements",
    "events_main_desc": "Attirez des clients en plaçant un code QR sur l'événement, une fois scanné, il redirige les utilisateurs vers un formulaire d'inscription personnalisé.",
    "events_step1_desc": "Captez les clients lors d'événements et amenez-les en magasin",
    "events_name": "Nom de l'événement",
    "events_form_name_field_placeholder": "Événement Restro Fun et Food",
    "events_last_step_title": "Félicitations ! Votre formulaire d'événement a été créé ! 🎉",
    "events_last_step_desc": "Faites glisser pour voir à quoi ressemblera votre formulaire d'événement",
    "birthday_anniversary_step1_preview_desc": "Nommez votre formulaire QR pour collecter l'anniversaire ou la date de naissance du client et ajoutez-le à votre liste de groupe",
    "birthday_anniversary_main_head": "Collectionnez les anniversaires ou les anniversaires",
    "birthday_anniversary_main_desc": "Obtenez l'anniversaire ou la date d'anniversaire du client, une fois scanné, redirigez les utilisateurs vers un formulaire d'inscription personnalisé.",
    "birthday_anniversary_step1_desc": "Recueillir les anniversaires et les anniversaires des clients",
    "restro_text": "Resto",
    "birthday_anniversary_last_step_title": "Félicitations ! Le formulaire d'anniversaire et de célébration des clients a été créé ! 🎉",
    "newspaper_ads_step1_preview_desc": "Nommez votre formulaire QR pour obtenir des clients à partir des annonces dans les journaux et ajoutez-le à votre liste de groupe",
    "newspaper_ads_main_head": "Obtenez des clients grâce aux publicités",
    "newspaper_ads_main_desc": "Obtenez des clients à partir des annonces dans les journaux, lorsqu'ils sont scannés, ils redirigent les utilisateurs vers un formulaire d'inscription personnalisé.",
    "newspaper_ads_step1_desc": "Obtenez des clients grâce à une annonce dans un journal ou une publicité extérieure",
    "newspaper_ads_last_step_title": "Félicitations ! Le formulaire d'annonces dans les journaux a été créé pour attirer des clients ! 🎉",
    "smart_pamphlets_step1_preview_desc": "Nommez votre formulaire QR pour obtenir les données des clients à partir des brochures et ajoutez-le à votre liste de groupes",
    "smart_pamphlets_main_head": "Obtenez des clients grâce aux brochures",
    "smart_pamphlets_main_desc": "Récupérez les données des clients à partir des brochures, lorsqu'elles sont scannées, elles redirigent les utilisateurs vers un formulaire d'inscription personnalisé.",
    "smart_pamphlets_last_step_title": "Félicitations ! Récupérez les données du client à partir du formulaire de brochures créé ! 🎉",
    "social_media_step1_preview_desc": "Nommez votre formulaire QR pour attirer les clients des réseaux sociaux et les intégrer à votre liste de groupes.",
    "social_media_main_desc": "Obtenez des clients à partir des réseaux sociaux, lorsqu'ils scannent des publications, cela redirige les utilisateurs vers un formulaire d'inscription personnalisé.",
    "social_media_form_name_field_placeholder": "Offre Restro Festival - Publication Facebook",
    "social_media_last_step_title": "Félicitations ! Le formulaire de médias sociaux de vos clients est désormais créé ! 🎉",
    "claim_offer_text": "Remplissez vos coordonnées et réclamez l'offre maintenant",
    "form_title_description": "Ajoutez un titre et une description du formulaire pour que vos clients puissent saisir les détails",
    "no_subscribed_clients": "Le groupe de clients sélectionné n'a aucun client abonné",
    "welcome_message_offer": "Envoyez un message de bienvenue à vos clients après leur inscription et offrez-leur un coupon de réduction",
    "system_grp": "Groupes de systèmes",
    "import_clients": "Importer des clients",
    "delivery_redirection_name": "Nom de redirection de livraison",
    "created_at": "Créé à",
    "provide_stamp_user": "Fournir un tampon à l'utilisateur",
    "scan_qr_code": "Scanner le code QR",
    "add_point": "Ajouter un point",
    "total_points": "Total de points",
    "user_profile": "Profil d'utilisateur",
    "stamp_history": "Histoire du timbre",
    "reward_txt": "Récompense",
    "variant_placeholder": "Petit, moyen, grand, etc.",
    "sent_coupon": "Coupon envoyé",
    "order_status": "Statut de la commande",
    "payment_status": "Statut de paiement",
    "order_currency": "Devise de la commande",
    "card_data": "Données de la carte",
    "reward_expiring_on": "Récompense expirant le",
    "reward_status": "Statut de récompense",
    "reward_get_date": "Date d'obtention de la récompense",
    "reward_name": "Nom de la récompense",
    "add_point_type": "Ajouter un type de point",
    "restaurant_location": "Emplacement du restaurant",
    "collect_points": "Collecter des points",
    "rewards_expire": "Les récompenses expirent",
    "rewards_redeem": "Échanger des récompenses",
    "total_rewards": "Rémunération totale",
    "created_by": "Créé par",
    "anniversary": "Anniversaire",
    "remove_user_group_txt": "Supprimer l'utilisateur du groupe",
    "delete_permanently_grp_msg": "Ce client sera définitivement supprimé du groupe.",
    "delete_multiple_permanently_grp_msg": "Ces clients seront définitivement supprimés du groupe.",
    "delete_multiple_client": "Ces clients seront supprimés définitivement.",
    "messages": "Messages",
    "management": "Gestion",
    "client": "Client",
    "country": "Pays",
    "state": "État",
    "city": "Ville",
    "zip_code": "Code postal",
    "1_sparkly_point_txt": "🎉 Vous avez gagné 1 point étincelant ! 🌟",
    "select_country_txt": "Sélectionnez un pays",
    "select_state": "Sélectionnez l'état",
    "ls_thanks_for_join": "Merci d'avoir rejoint notre programme de fidélité.🎁🎈",
    "month": "Mois",
    "welcome_message": "Envoyez un message de bienvenue à vos clients après leur inscription",
    "all_rewards_txt": "Toutes les récompenses",
    "active_rewards": "Récompenses actives",
    "expired_and_redeemed": "Expiré et remboursé",
    "redirect_3rd_party_delivery": "Rediriger la livraison par un tiers vers votre magasin ou vers votre plateforme de commande en ligne",
    "search_or_start_new": "Rechercher ou démarrer une nouvelle recherche",
    "contacts": "Contacts",
    "chats": "Chats",
    "add_by_restaurant": "Ajouter par restaurant",
    "using_scan_code": "Utilisation du code de numérisation",
    "step": "Étape",
    "list_widget_text": "Liste des widgets",
    "swipe_free_wifi_txt": "Glissez pour un accès Wi-Fi gratuit",
    "create_widget_txt": "Créer un widget",
    "redeem_award_msg": "Etes-vous sûr ? Voulez-vous utiliser cette récompense ?",
    "on_collect_msg": "À collecter",
    "pause": "Pause",
    "resume": "CV",
    "location_added": "emplacement ajouté",
    "sub_user_admin_permissions_txt": "Autoriser le sous-utilisateur à accéder aux autorisations d'administrateur dans l'affichage numérique",
    "unlist": "Désinscrire",
    "cannot_delete_default_terms": "Impossible de supprimer les conditions générales par défaut",
    "something_went_wrong": "Une erreur s'est produite !",
    "card_download_success": "Carte téléchargée avec succès",
    "permission_denied": "Permission refusée !",
    "invalid_qr_code": "Veuillez scanner un code QR valide",
    "select_customer": "Veuillez sélectionner le client",
    "online": "EN LIGNE",
    "offline": "HORS LIGNE",
    "no_data": "Oups, aucune donnée trouvée !",
    "forever_free": "Libre pour toujours",
};

export default fr