// Created By ND
import React from "react";
import { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { publicIpv4 } from "public-ip";
import * as Sentry from "@sentry/react";
import { SnackbarProvider } from "notistack";

// custom component
import Progressbar from "./Components/Progressbar/progressbar.component";

// local files
import { AppContextProvider } from "./Context/AppContext";
import ApolloClientConfig from "./Configurations/ApolloClient/ApolloClient.component";
import Layout from "./Layout";

// helper functions
import ConstantsHelper from "./Helper/Constants";
import AppUtils from "./Utils/AppUtils";

// css
import "./App.css";
import { LOCALISE_LANGUAGES } from "./Helper/Language/languageConstant";

// lazy loading components
const Spinner = React.lazy(
  () => import("./Components/Spinner/spinner.component")
);

function App(props: any) {
  const { urlsHelper } = props;
  // ip address
  const [currentIp, setCurrentIp] = useState<string>("");
  const [updateToken, setUpdateToken] = useState<boolean>(false);

  window.onbeforeunload = null;

  if (urlsHelper.urlMode === "production") {
    console.log = console.warn = console.error = () => {};
  }
  useEffect(() => {
    const getAsyncIpAddress = async () => {
      try {
        const pIP = await publicIpv4();
        return pIP ? pIP : "";
      } catch (e: any) {
        return "";
      }
    };

    const getIpAddress = () => {
      getAsyncIpAddress().then((val) => {
        setCurrentIp(val);
        return;
      });
    };

    getIpAddress();
  }, []);

  useEffect(() => {
    let link = document.querySelector(
      "link[rel~='icon']"
    ) as HTMLLinkElement | null;
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }

    if (AppUtils.isFromScheduledown()) {
      link.href = window.location.origin + "/scheduledown.ico";
      document.title = "Scheduledown";
    } else {
      link.href = window.location.origin + "/favicon.ico";
      document.title = "Menuonline";
    }

    if(localStorage.getItem("Language")){
      const obj: any = LOCALISE_LANGUAGES.find((lang) => lang.languageCode === localStorage.getItem("Language"))
        if (obj) {
            AppUtils.setLanguageObject(obj)
        }
    }
  }, []);
  
  return (
    <AppContextProvider>
      <GoogleOAuthProvider clientId={ConstantsHelper.googleAuthClientId}>
        {currentIp !== "" ? (
          <>
            <BrowserRouter>
              <SnackbarProvider
                maxSnack={1}
                autoHideDuration={2000}
                preventDuplicate={true}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <ApolloClientConfig
                  pIP={currentIp}
                  updateToken={(val: any) => {
                    setUpdateToken(val);
                  }}
                >
                  <Layout
                    ConstantsHelper={ConstantsHelper}
                    updateToken={updateToken}
                  />
                </ApolloClientConfig>
              </SnackbarProvider>
            </BrowserRouter>
            <Progressbar />
          </>
        ) : (
          <React.Suspense fallback={<></>}>
            <Spinner />
          </React.Suspense>
        )}
      </GoogleOAuthProvider>
    </AppContextProvider>
  );
}

export default Sentry.withProfiler(App);
// export default App;
