// React Imports
import * as React from 'react';

// MUI Imports
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Popper from '@mui/material/Popper';


// Third Party Imports
import { useNavigate } from 'react-router-dom';

// Helper Imports
import useLanguageChange from '../../../../Utils/useLanguageChange';

// Style Imports
import headerStyle from './Header.style';
import staticIcons from '../../../../Helper/Images';

const Header = () => {

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    // Hooks
    const navigate = useNavigate()
    const localizedVariable = useLanguageChange()

    return (
        <Box>
            <CssBaseline />
            <AppBar sx={headerStyle.main}>
                <Toolbar sx={{ px: '0 !important', display: 'flex', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Box component={'img'} src={staticIcons.MycircleLoginLogo} alt='Logo'
                            sx={{ width: '140px', height: 'auto', cursor: 'pointer' }} onClick={() => { navigate('/') }} />
                    </Box>
                    <Box sx={{ display: { xs: 'none', sm: 'flex' }, alignItems: "center", justifyContent: 'flex-end' }}>
                        <Button
                            sx={[headerStyle.borderBtn, { mr: '1rem' }]}
                            onClick={() => {
                                if (window.location.pathname === '/other-products')
                                    navigate('/')
                                else
                                    navigate('/other-products')
                            }}
                        >{window.location.pathname === '/other-products' ? "Home" : "Other Products" }</Button>
                        <Button
                            sx={headerStyle.orangeBtn}
                            onClick={() => {
                                window.location.href = '/main'
                            }}
                        >Sign In</Button>
                    </Box>
                    <IconButton sx={{ display: { xs: 'flex', sm: 'none' }, }} onClick={(event) => {
                        setAnchorEl(anchorEl ? null : event.currentTarget);
                    }}>
                        <img
                            src={anchorEl ? staticIcons.Close : staticIcons.Bars}
                            style={{ width: '1.7rem' }}
                            alt="bar"
                        />
                    </IconButton>
                </Toolbar>
            </AppBar>

            <Popper
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                sx={{ zIndex: 99 }}
            >
                <Box
                    sx={{
                        p: "1rem",
                        width: "100vw",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        background: '#fff',
                        borderRadius: "1rem",
                        position: "relative",
                        boxShadow: '0 1px 3px #989696'
                    }}
                >
                    <Button
                        sx={[headerStyle.borderBtn, { width: '100%', my: '1rem' }]}
                        onClick={() => {
                            if (window.location.pathname === '/other-products')
                                navigate('/')
                            else
                                navigate('/other-products')
                        }}
                    >{window.location.pathname === '/other-products' ? "Home" : 'Other Products'}</Button>
                    <Button
                        sx={[headerStyle.orangeBtn, { width: '100%' }]}
                        onClick={() => {
                            window.location.href = '/main'
                        }}
                    >Sign In</Button>
                </Box>
            </Popper>

        </Box>
    );
}

export default Header
