const tr = {
    "rated_google_reviews": "Google Yorumlarında 5.0 olarak derecelendirildi",
    "get_your_digital_menu": "Dijital Menünüzü Alın—",
    "instant_popup_menu": "QR taraması olmadan anında açılan menü",
    "free_forever": "Sonsuza dek özgür",
    "the_future_is_here": "Gelecek burada!",
    "upgrade_your_restaurant": "Restoranınızı sıfır maliyetle çarpıcı bir dijital menüyle yükseltin. Bugünden itibaren daha fazla müşteri çekin.",
    "get_my_free_menu_now": "Ücretsiz Menümü Hemen Alın",
    "no_credit_card_required": "*Kredi Kartı gerekmez",
    "join_largest_marketplace": "En büyük restoran pazaryerine katılın!",
    "what_will_you_get": "Ne Alacaksınız",
    "free": "ÖZGÜR",
    "free_digital_menu": "Ücretsiz Dijital Menü 2D veya 3D",
    "free_digital_menu_desc": "Kağıt menülere ve pahalı yeniden basımlara elveda deyin",
    "quick_and_easy_signup": "Hızlı ve Kolay Kayıt",
    "communicate_with_customers_title": "Müşterilerinizle ÜCRETSİZ iletişim kurun!",
    "features1": [
        "Baskı maliyetlerinden tasarruf edin ve ihtiyaç duyduğunuzda anında menü güncellemelerinin keyfini çıkarın.",
        "Dijital menünüze herhangi bir cihazdan erişin",
        "Çevre dostu ve zahmetsiz"
    ],
    "features2": [
        "Müşterilerinizin yemeklerinizi muhteşem 3 boyutlu görsellerle sanal olarak keşfetmesini sağlayın.",
        "Müşterilere yemeklerinin gerçekçi bir ön izlemesini sunarak etkileşimi artırın.",
        "Yemeklerinizin tadı kadar cezbedici bir menü deneyimiyle öne çıkın."
    ],
    "features3": [
        "Restorana giren misafirleri anında menünüzle karşılayın.",
        "Kampanya ve promosyonlarla ilgili güncellemeleri otomatik olarak telefonlarına gönderin.",
        "Menünüze hızlı ve temassız erişimle yemek deneyiminizi geliştirin."
    ],
    "feature4": [
        "Mevsimsel teklifleri, günlük spesiyalleri ve özel promosyonları vurgulayın.",
        "Müşterilerinizin dikkatini en popüler ürünlerinize çekin.",
        "Dikkat çekici görseller ve açıklamalarla aciliyet ve heyecan yaratın."
    ],
    "feature5": [
        "Günlük binlerce ziyaretçinin olduğu çevrimiçi bir topluluğun parçası olun.",
        "Görünürlüğünüzü artırın ve aktif olarak yemek seçenekleri arayan yeni müşterilere ulaşın.",
        "Görünürlüğünüzü en üst düzeye çıkarmak ve daha fazla müşteri çekmek için erkenden en iyi yeri ayırtın."
    ],
    "section3_heading": "Neden Dijitalleşmeliyiz? Çünkü Ücretsiz ve Kolay!",
    "section3_description": "Restoranınızın çekiciliğini artırmak için tasarlanmış güçlü özelliklerin kilidini açın.",
    "instant_menu": "Anlık Menü",
    "instant_menu_notification": "Pop-up ve Bildirim",
    "instant_menu_description": "Misafirlerinizi içeri girdikleri anda anında menü erişimiyle karşılayın. En son menüyü ve spesiyalleri otomatik olarak doğrudan cep telefonlarına gönderin",
    "digital_menu": "Dijital Menüler",
    "at_zero_cost": "Sıfır Maliyetle",
    "digital_menu_description1": "Kağıt menülere ve pahalı yeniden baskılara elveda deyin. Her zaman güncel olan dijital bir menüye geçin ve sonsuza dek ücretsiz!",
    "digital_menu_description2": "Dijital menümüzle, teklifleriniz her zaman güncel ve her cihazda erişilebilir olur. Sorunsuz, sıfır maliyetli bir çözümle paradan tasarruf edin ve menü güncellemelerini basitleştirin.",
    "try_me_button": "Beni dene",
    "try_3d_menu_title": "3D Menüler",
    "try_3d_menu_title2": "Ücretsiz — Vay canına!",
    "try_3d_menu_description1": "Yemeklerinizin ekrandan fırlamasını sağlayın. 3D görseller yükleyin ve müşterilerin içeri girmeden önce yemeğinizi sanal olarak tatmalarını sağlayın.",
    "try_3d_menu_description2": "Yüksek kaliteli 3D görseller ağız sulandıran bir ön izleme oluşturarak çekiciliği artırır ve siparişleri teşvik eder.",
    "try_now": "Şimdi deneyin",
    "showcase_title": "Serginizi sergileyin",
    "showcase_specials": "Özel ürünler",
    "showcase_specials_description1": "Ağız sulandıran bir fırsatınız veya rakipsiz bir promosyonunuz mu var? Bunu dijital menünüzde öne çıkarın ve daha fazla aç insanı kendinize çekin.",
    "showcase_specials_description2": "Dikkat çekici görseller ve ilgi çekici açıklamalarla tanıtımlarınızı gözden kaçırılmayacak hale getirin.",
    "marketplace": "Pazar Yeri",
    "marketplace_description": "Günlük 2.000'den fazla ziyaretçisi olan bir pazar yeri kuruyoruz. Hemen katılın ve lansmanımızda ilk sırada siz olun. Yemek seçenekleri arayan yeni müşterilere aktif olarak ulaşabilirsiniz. Görünürlüğünüzü en üst düzeye çıkarmak için yerinizi erkenden ayırtın!",
    "see_other_products": "Diğer Ürünlere Bakın",
    "about_us": "Hakkımızda",
    "why_serving_free": "Bunu Neden Sunuyoruz?",
    "serving_free_highlight": "Özgür",
    "about_desc": "Biz Dallas'ta yaşayan yerel insanlarız ve sizin gibi restoranların gelişmesine yardımcı olmaya kendimizi adadık.",
    "about_tool_free": "Topluluğumuzu desteklemeye inandığımız için bu güçlü aracı tamamen ücretsiz sunuyoruz.",
    "no_catch": "Hiçbir sorun yok, şaka değil. Başarılı olduğunuzda topluluğumuzun daha da güçlendiğini biliyoruz. 😊",
    "understanding_challenges": "Bir işletmeyi yönetmenin zorluklarının farkındayız; bu nedenle operasyonlarınızı daha sorunsuz hale getirecek ve erişiminizi genişletecek araçlar sunmaya kendimizi adadık.",
    "community_growth": "Siz büyüdükçe, topluluğumuz da sizinle birlikte büyür ve başarı ve destek dalga etkisi yaratır. Birlikte, herkes için yemek deneyimini yükseltelim.",
    "how_it_works": "Nasıl çalışır?",
    "three_simple_steps": "Üç Basit Adım",
    "get_started_highlight": "Başlayın",
    "no_tech_skills_needed": "Hiçbir teknik beceriye gerek yok. Eğer bir burgeri çevirebiliyorsanız, bunu da yapabilirsiniz!",
    "step1_title": "Promosyonunuzu ÜCRETSİZ görüntüleyin!",
    "step1_desc": "\"Sipariş verin!\" diyebildiğinizden daha hızlı başlayın. Basit bir formu doldurmanız yeterli.",
    "step2_title": "Menünüzü Özelleştirin",
    "step2_desc": "Yemeklerinizi ekleyin, 3D olarak fotoğraf yükleyin ve spesiyallerinizi vurgulayın.",
    "step3_title": "Paylaş ve Büyüt",
    "step3_desc": "Dijital menünüzü QR kodları, sosyal medya veya web siteniz aracılığıyla paylaşın.",
    "testimonials": "Referanslar",
    "testimonials_section_title": "Sadece Bizim Sözümüze Güvenmeyin",
    "testimonials_section_desc": "Dallas'taki diğer restoran sahiplerinin neler söylediğine bakın.",
    "faq_section_title": "Sorularınız mı var? Cevaplarımız var",
    "faq_section_desc": "Başlamadan önce bilmeniz gereken her şey.",
    "faqs": "SSS",
    "faq_answers": [
        {
            "que": "Gerçekten sonsuza kadar ücretsiz mi?",
            "ans": "Kesinlikle! Hiçbir gizli ücret, kredi kartı veya koşul yok."
        },
        {
            "que": "Herhangi bir teknik bilgiye ihtiyacım var mı?",
            "ans": "Eğer bir e-posta gönderebiliyorsanız, bunu yapabilirsiniz. Bu kadar basit."
        },
        {
            "que": "Bunu neden ücretsiz sunuyorsunuz?",
            "ans": "Önce vermenin önemli olduğuna inanıyoruz. Gelecekte seveceğiniz başka hizmetlerimiz de olabilir, ancak bu tamamen size kalmış."
        },
        {
            "que": "Pazaryerinde durum ne?",
            "ans": "Pişirmeye devam ediyoruz! Yeterli sayıda restoranımız olduğunda, daha fazla görünürlük elde etmeniz için pazaryerinizi başlatacağız."
        }
    ],
    "testimonials_content1": "Dijital menüye geçmek yaptığımız en iyi hamleydi! Müşterilerimiz rahatlığı ve güncellenmiş görünümü seviyor!",
    "testimonials_name1": "Morris George",
    "testimonials_restaurant_name1": "Konak Restoranı",
    "testimonials_content2": "Yeni dijital menümüz, misafirlerimizin menüye göz atmasını kolaylaştırıyor ve etkileşimi gerçekten artırdı!",
    "testimonials_name2": "Amelia Johnson",
    "testimonials_restaurant_name2": "Kentsel Yemekler",
    "testimonials_content3": "Misafirlerimiz 3 boyutlu görsellerden çok etkilendiler. Bu sayede öne çıktık ve yemek deneyimlerini geliştirdik!",
    "testimonials_name3": "Davis Yeşil",
    "testimonials_restaurant_name3": "Şehir Ocağı",
    "ready_to_boost_your_restaurant": "Restoranınızı Canlandırmaya Hazır Mısınız?",
    "ready_to_boost_desc": "Geride kalmayın. Bugün dijital devrime katılın!",
    "yes_i_want_my_free_digital_menu": "Evet, Ücretsiz Dijital Menüm'ü İstiyorum",
    "480_plus": "480+",
    "people_already_joined": "kişi zaten katıldı",
    "menu_online_home": "Menü Çevrimiçi Ana Sayfa",
    "welcome": "Hoş geldin",
    "description_other": "Müşteri memnuniyetinizi arttıralım!",
    "benefits": "Faydalar",
    "greeting_text": "Hoşgeldiniz 👋",
    "all_in_one_features": "🚀 Restoranınızı Bir Üst Seviyeye Taşıyacak Hepsi Bir Arada Özellikler",
    "menu_online_menu_name": "Menü Çevrimiçi",
    "menu_online_description": "Müşterilerinizin çevrimiçi olarak kolayca erişebilmesi için menünüzü dijitalleştirin.",
    "menu_online_benefits": "Müşterilerinizin menünüzü istedikleri zaman, istedikleri yerden görüntüleyebilmelerini sağlayarak onların rahatlığını artırın ve etkileşimi güçlendirin.",
    "ds_name": "Dijital Tabela",
    "ds_description": "Mağaza içi dinamik ekranlarda teklifleri ve promosyonları sergileyin.",
    "ds_benefits": "Müşterilerinizin dikkatini ilgi çekici görsellerle çekin, satışları artırın ve gerçek zamanlı teklifleri zahmetsizce sunun.",
    "loyalty_punch_name": "Sadakat Punch Kartı",
    "loyalty_punch_description": "Tekrar eden müşterilerinizi dijital sadakat programıyla ödüllendirin.",
    "loyalty_punch_benefits": "Sık ziyaretler için kullanılabilen ödüllerle müşteri sadakatini teşvik ederek müşteri sadakatini artırın ve satışları yükseltin.",
    "marketing_menu_name": "Pazarlama",
    "marketing_description": "Hedefli dijital pazarlama kampanyalarıyla daha fazla müşteriye ulaşın.",
    "marketing_benefits": "Yeni ve tekrar eden müşterileri çekmek için tasarlanmış özel promosyonlarla restoranınızın erişimini genişletin ve müşteri trafiğini artırın.",
    "get_more_reviews_menu_name": "Daha Fazla Yorum Alın",
    "get_more_reviews_description": "Memnun müşterilerinizi olumlu çevrimiçi yorumlar bırakmaya teşvik edin.",
    "get_more_reviews_benefits": "Restoranınızın güvenilirliğini artırın ve daha fazla olumlu yorumla çevrimiçi itibarınızı güçlendirerek yeni müşteriler çekin.",
    "guest_smart_wifi_menu_name": "Misafir Akıllı Wifi",
    "guest_smart_wifi_description": "Pazarlama amacıyla misafir verilerini toplamak için ücretsiz Wi-Fi sunun.",
    "guest_smart_wifi_benefits": "Gelecekteki promosyonları kişiselleştirmek için değerli müşteri verilerini toplarken müşteri memnuniyetini artırın.",
    "reservation_menu_name": "Rezervasyon",
    "reservation_description": "Müşterilerin çevrimiçi olarak kolayca masa rezervasyonu yapmalarını sağlayın.",
    "reservation_benefits": "Bekleme sürelerini en aza indirerek yemek yeme deneyimini iyileştirin ve oturma düzenini daha iyi yönetmek için rezervasyonları kolaylaştırın.",
    "pos_integration_menu_name": "POS Entegrasyonu",
    "pos_integration_description": "Sistemimizi POS sisteminizle entegre edin.",
    "pos_integration_benefits": "Çevrimiçi ve mağaza içi sistemlerinizi senkronize tutarak sipariş işlemeyi basitleştirin ve hataları azaltın.",
    "online_ordering_menu_name": "Çevrimiçi Sipariş",
    "online_ordering_description": "Müşterilerinizin doğrudan web sitenizden veya uygulamanızdan sipariş vermesini sağlayın.",
    "online_ordering_benefits": "Ücret tasarrufu sağlayan doğrudan sipariş seçeneği sunarak satışlarınızı artırın ve üçüncü taraf platformlara olan bağımlılığınızı azaltın.",
    "pro_label": "Profesyonel",
    "coming_soon": "Yakında gelecek",
    "locked_label": "Kilitli",
    "forever_label": "Sonsuza kadar",
    "new_label": "Yeni",
    "other_products_page_title": "Diğer Ürünler | Menü Çevrimiçi",
    "other_products_description": "Menü Çevrimiçi Gizlilik Politikası",
    "other_products_header_title": "Diğer Ürünler",
    "privacy_policy": {
        "title": "Gizlilik Politikası",
        "lastUpdated": "Son Güncelleme : 01 Temmuz 2024",
        "introduction": {
            "header": "Yeni ve Mevcut Müşteriler için Gizlilik Politikası",
            "paragraph1": "AŞAĞIDAKİ ANA NOKTALAR (BÖLÜM REFERANSLARI DAHİL) SADECE RAHATLIĞINIZ İÇİN SAĞLANMIŞTIR VE TAM ŞARTLAR VE KOŞULLARIN YERİNİ ALMAZ. MENUONLINE INC HİZMETİNİ KULLANMADAN ÖNCE BU ŞARTLAR VE KOŞULLARI OKUMAK SİZİN SORUMLULUĞUNUZDADIR.",
            "paragraph2": "Menuonline'ı kullanmanız, Şartlar ve Koşulları kabul ettiğiniz ve kuruluşunuz veya şirketiniz tarafından bunu yapmaya yetkili olduğunuzu beyan ve garanti ettiğiniz anlamına gelir. (Bkz. s.3 “Nasıl çalışır”)",
            "paragraph3": "Menuonline Inc, izin tabanlı bir pazarlama hizmetidir. \"Spam\" gönderme veya Şartlar ve Koşullar'ı ihlal etme kasıtlı olarak hesabınızın kapatılmasıyla sonuçlanacaktır. (Bkz. s.16 \"Hizmet'in Kabul Edilebilir Kullanımı\")",
            "paragraph4": "Menuonline Inc'de barındırdığınız veriler, örneğin iletişim kayıtları, e-posta içeriği ve Menuonline Inc aracılığıyla topladığınız bilgiler size aittir. (Bkz. m.14 “Neye sahipsiniz?”).",
            "paragraph5": "Menuonline Inc yazılımı ve web sitemizdeki içerik bize aittir. (Bkz. s.13 “Neye sahibiz?”) Kendi kullanıcılarınız dışında, Menuonline Inc ile barındırdığınız veriler veya yayınlanmamış içerikler yalnızca gizlilik sözleşmeleri imzalamış olan Menuonline Inc çalışanları ve ortakları tarafından görüntülenebilir. (Bkz. s.14 “Neye sahibiz?”)",
            "paragraph6": "Tüm Kişisel Tanımlayıcı Bilgiler (PII) yalnızca Kanada'da bulunan sunucularda tutulur. (Bkz. m.14 “Neye sahipsiniz?”).",
            "paragraph7": "Menuonline Inc hizmeti Kanada ve Ontario eyaletinin yasalarına tabidir. (Bkz. s.24 “Yürürlükteki Yasa”)",
            "paragraph8": "Kanada'dan veya Kanada dışından bireyler, polis veya diğer düzenleyici kurumlar tarafından verileriniz veya içeriklerinizle ilgili herhangi bir talep her zaman size iletilecektir. Tek istisna, bunun bizi Kanada veya Ontario yasalarını ihlal etmeye itmesidir; ancak her durumda verilerinizin sizden başkası tarafından ifşa edilmesine şiddetle karşı çıkacağız. (Bkz. s.14 \"Neye sahipsiniz?\").",
            "paragraph9": "Menuonline Inc güvenliği yalnızca kullanıcı adları ve parolalarınızın yönetimi kadar iyidir. Menuonline Inc kullanıcı hesapları için ücret almıyoruz, bu nedenle her kullanıcı için ayrı bir hesap oluşturun ve parolaları gizli tutun. Hesabınızdan siz sorumlusunuz. (Bkz. s.3 “Nasıl çalışır”)",
            "paragraph10": "Hesabınızı istediğiniz zaman iptal edebilirsiniz. İptal etmeniz durumunda kullanılmayan krediler için geri ödeme sağlamayız. (Bkz. s.3 “Nasıl çalışır”)"
        },
        "termsAndConditions": {
            "title": "MENUONLINE INC ŞARTLAR VE KOŞULLARI",
            "lastUpdate": "SON GÜNCELLEME: 14 Ocak 2020",
            "paragraph1": "Lütfen bu web sitesini (Site) kullanmadan önce bu hizmet şartlarını ve koşullarını (Koşullar) dikkatlice okuyun. Bu Şartlar, Menuonline Inc.'i (Menuonline Inc.) ve diğerlerini sorumluluktan muaf tutar ve\/veya bizim ve onların sorumluluğunu sınırlar ve bu Sitenin kullanımınıza uygulanan diğer önemli hükümleri içerir. Bu Siteyi veya Hizmeti ziyaret ederek veya kullanarak, kendi adınıza ve adına hareket edebileceğiniz herhangi bir kuruluş adına (toplu olarak bundan sonra \"siz\" olarak anılacaktır), bu Sitenin her kullanımı ve her ziyaretiniz için bu Şartları kabul etmeyi ve bunlara uymayı kabul edersiniz."
        },
        "services": {
            "title": "Hizmetlerimiz.",
            "paragraph": "Site aracılığıyla sunduğumuz hizmetler, seçtiğiniz alıcılara e-posta mesajları (\"E-postalar\") oluşturmanıza, göndermenize ve yönetmenize olanak tanıyan bir hizmettir (\"Hizmet\")."
        },
        "license": {
            "title": "Lisans.",
            "paragraph": "Bu Şartlara tabi olarak, Menuonline Inc. size Siteyi görüntülemeniz ve Hizmeti Hizmetin özellikleri tarafından izin verildiği şekilde kullanmanız için Süre boyunca münhasır olmayan, sınırlı, devredilemez bir lisans verir. Hizmette ve Menuonline Inc İçeriğinde (aşağıda tanımlandığı şekilde) açıkça verilmeyen tüm hakları saklı tutuyoruz."
        },
        "emailingContent": {
            "title": "İçeriği E-postayla Gönderme.",
            "paragraph": "Bu Site veya Hizmet aracılığıyla Menuonline Inc ile etkileşim kurduğunuzda, doğru ve kesin bilgiler sağlamayı kabul edersiniz. Ayrıca, e-posta yoluyla gizli bilgi sağlamanın güvenli olmayan bir uygulama olduğunu anlarsınız. Hizmetimizi kullanan herhangi birine gönderdiğiniz her şeyden yalnızca kendinizin sorumlu olduğunu anlarsınız."
        },
        "support": {
            "title": "Destek.",
            "paragraph": "Hizmetle kendi kendinize yetebilmenize yardımcı olmak için size destek sağlıyoruz. Sitede, Menuonline Inc destek bileti sisteminde ve telefonla dokümantasyon ve videolar şeklinde destek sağlıyoruz. Sitenin en üstündeki \"Destek\" sekmesine tıklayarak bize ulaşabilirsiniz. Ürün desteği için herhangi bir ücret alınmamaktadır; ancak, kendi takdirimize bağlı olarak belirli müşteri hesaplarına desteği sonlandırma hakkını saklı tutuyoruz."
        }
    },
    "terms_condition": {
        "title": "Hizmet Şartları | Menü Çevrimiçi",
        "header": "Yeni ve Mevcut Müşteriler İçin Şartlar ve Koşullar"
    },
    "privacy_policy_title": "ÖZEL POLİTİKA",
    "privacy_policy_effective_date": "16 Ocak 2020'den itibaren geçerlidir",
    "privacy_policy_general_title": "GENEL ÖZEL POLİTİKA",
    "privacy_policy_intro": "menuonline.com (Menuonline ve “biz”), bireylerin kişisel bilgilerinin gizliliğinin korunmasının ve her türlü gizli bilginin güvenliğinin önemini anlıyor. Bu Gizlilik Politikasını (“Politika”), “gizliliğinizi” koruma taahhüdümüzü göstermek için oluşturduk. Bu Gizlilik Politikası şunları açıklar:",
    "privacy_policy_points": [
        "Hangi kişisel bilgileri topluyoruz ve neden topluyoruz;",
        "Kişisel bilgilerinizi nasıl kullanıyoruz;",
        "Kişisel bilgilerinizi kimlerle paylaşıyoruz; ve",
        "Hizmete erişim, kişisel bilgilerinizi güncelleme ve kaldırma konusunda sahip olduğunuz seçenekler."
    ],
    "privacy_policy_pipeda": "Menuonline, tüm bilgilerin toplanmasını Kanada Kişisel Bilgilerin Korunması ve Elektronik Belgeler Yasası'na (PIPEDA) uygun şekilde yönetir.",
    "privacy_policy_update_notice": "Menuonline'ın bu Gizlilik Politikasını herhangi bir zamanda güncelleme veya değiştirme hakkını saklı tuttuğunu lütfen unutmayın. Bu web sitesinde veya hizmetler aracılığıyla kullanıma sunulduğunda güncellenmiş bir Politika yayınlayarak sizi herhangi bir değişiklikten haberdar edeceğiz. Bu Politikanın en güncel sürümü mycircle'ın kullanıcıların kişisel bilgilerini kullanımını yönetecektir. Bu Gizlilik Politikasında değişiklik yapıldıktan sonra mycircle'ın web sitesine erişmeye veya hizmetlerini kullanmaya devam ederek, revize edilmiş şartlara bağlı kalmayı kabul edersiniz.",
    "privacy_policy_consent_title": "KİŞİSEL BİLGİLER İÇİN ONAY",
    "privacy_policy_consent_text": "Kişisel bilgilerinizi Menuonline'a web sitemiz veya formlarımızdan herhangi biri aracılığıyla sağladığınızda, bu Politika uyarınca veya kişisel bilgileri sağladığınız sırada size belirtilen amaçlar doğrultusunda söz konusu bilgilerin toplanmasına, kullanılmasına ve ifşa edilmesine onay vermiş olursunuz. Kişisel bilgilerin herhangi bir ikincil kullanımı yalnızca açık izninizle işlenecek veya size vazgeçme fırsatı sunulacaktır.",
    "privacy_policy_opt_out_text": "İstediğiniz zaman onayınızı geri çekme veya devre dışı bırakma fırsatınız var. Onayınızı geri çekmek veya devre dışı bırakmak isterseniz, bizden aldığınız herhangi bir e-postanın alt kısmındaki iletişim tercihleri bağlantısına tıklayabilirsiniz.",
    "privacy_policy_info_collection_title": "TOPLADIĞIMIZ BİLGİLER",
    "privacy_policy_info_collection_text": "Menuonline, kişisel bilgileri bu Politika uyarınca toplar ve işler. Bu bilgileri, bu Politikada belirtilenler dışında başkalarına satmayacağız, paylaşmayacağız veya kiralamayacağız. Menuonline, size hizmetlerimizi veya ürünlerimizi sağlama amaçlarıyla kişisel bilgiler toplar.",
    "privacy_policy_info_types_text": "Menuonline'ın topladığı bilgi türleri şunları içerir:",
    "privacy_policy_account_holders_title": "Menuonline Hesap Sahipleri",
    "privacy_policy_registration_info": "Kayıt Bilgileri: Hizmetleri kullanmak için kaydolursanız, hesap sahibi olmak için bir Menuonline hesabına ihtiyacınız vardır (\"Hesap Sahibi\"). Bir hesap için kaydolduğunuzda, adınız, e-posta adresiniz, kullanıcı adınız, parolanız, şirket adınız, mesleğiniz, konumunuz ve telefon numaranız gibi belirli temel bilgileri vermeniz istenecektir. Parolalar şifrelenir – Menuonline personeli parolaları görüntüleyemez veya alamaz.",
    "privacy_policy_billing_info": "Fatura bilgileri: Hizmetlerimize abone olursanız, bize ödeme ve fatura bilgilerinizi vermeniz gerekecektir.",
    "privacy_policy_cookies_info": "Çerezler: Web sitemize gelen ziyaretçilerin verilerini (örneğin web sitemize gelen web sitesi ziyaretlerinin kaynağı ve web sitemize gelen ziyaret sayısı) izlemek için çerezleri kullanıyoruz.",
    "privacy_policy_ip_info": "IP Adresleri: Hizmetlerimizi kullanımınızın bir parçası olarak hakkınızda IP adresleri toplanır (örneğin, hizmetlerimizi kullanırken ülke düzeyinde konumunuzu ve kullanılan IP adreslerini kaydetme amacıyla).",
    "privacy_policy_your_contacts_title": "İletişim Bilgileriniz",
    "privacy_policy_distribution_list": "Dağıtım listesi: Hizmetlerimizi kullanımınızın bir parçası olarak, bize kişileriniz (\"Kişiler\") hakkında kişisel bilgiler sağlayabilirsiniz veya biz hizmetlerin bir parçası olarak bu bilgileri toplayabiliriz. Bu bilgiler e-posta adreslerini içerir ve ad, şirket adı veya hizmetleri kullanırken Menuonline'a sağladığınız diğer bilgiler gibi diğer tanımlanabilir bilgileri içerebilir. Kişilerinizden bilgilerinin tarafımızca işlenmesine izin vermek için uygun onayı aldığınızdan emin olmalısınız.",
    "privacy_policy_billing_info_contacts": "Fatura bilgileri: Hizmetlerimize abone olursanız, bize ödeme ve fatura bilgilerinizi vermeniz gerekecektir.",
    "privacy_policy_cookies_contacts": "Çerezler: Menuonline, yalnızca Menuonline izleme kodunu web sitenize eklediyseniz, Kişileriniz hakkında kişisel bilgileri toplamak ve kullanmak için çerezleri ve benzer izleme teknolojilerini kullanacaktır. Menuonline tarafından barındırılan tüm açılış sayfaları otomatik olarak bir izleme koduna sahip olacaktır.",
    "privacy_policy_ip_contacts": "IP Adresleri: Hizmetlerin bir parçası olarak Kişileriniz hakkında IP adresleri toplanır (örneğin ülke düzeyindeki konumu ve bir form gönderirken ve onay verirken kullanılan IP adreslerini kaydetme amacıyla).",
    "privacy_policy_info_about_contacts_title": "İLETİŞİM KİŞİLERİNİZ HAKKINDA TOPLADIĞIMIZ BİLGİLER",
    "privacy_policy_info_about_contacts_notice": "*Bu bölüm yalnızca Menuonline tarafından hizmetlerin sağlanması amacıyla bir Hesap Sahibinin Kişileri hakkında toplanan, kullanılan veya ifşa edilen bilgiler için geçerlidir*",
    "privacy_policy_info_about_contacts_desc": "Menuonline, yalnızca Hesap Sahibinin bize bir dağıtım listesi sağladığı belirli hizmetlere kaydolması durumunda Kişiler hakkında kişisel bilgileri toplayacak, kullanacak veya ifşa edecektir. Hesap Sahipleri, hizmetler aracılığıyla Menuonline'a Kişileri hakkında kişisel bilgiler sağlarken tüm geçerli yasalara uyduklarından emin olmaktan münhasıran sorumludur.",
    "privacy_policy_info_auto_collect_title": "Menuonline'ın otomatik olarak topladığı bilgiler",
    "privacy_policy_info_auto_collect_desc1": "Hesap Sahibinin Kişileri, bir Hesap Sahibinden alınan bir e-posta kampanyasıyla etkileşime girdiğinde veya bir Hesap Sahibinin bağlı web sitesine giden bir bağlantıyla etkileşime girdiğinde, Menuonline çerezler kullanarak söz konusu Kişinin cihazı ve e-postayla etkileşimi hakkında bilgi toplayabilir. Bu bilgiler yalnızca hizmetleri sağlamak ve Hesap Sahibine içgörü sağlamak için toplanır.",
    "privacy_policy_info_auto_collect_desc2": "Bir Kişinin cihazı ve Kişinin, Hesap Sahibinden hizmetlerimiz aracılığıyla gönderilen e-postalara erişmek için kullandığı uygulamalar hakkında topladığımız bilgiler, Kişinin IP adresini, işletim sistemini, tarayıcı kimliğini ve Kişinin sistemi ve bağlantısıyla ilgili diğer bilgileri içerebilir.",
    "privacy_policy_info_auto_collect_desc3": "Hizmetler aracılığıyla gönderilen e-postalarla Contact'ın etkileşimi hakkında topladığımız bilgiler, Contact'ın e-postaya erişim ve tarama etkinliklerinin tarih ve saatlerini ve bir Hesap Sahibi tarafından hizmetler aracılığıyla Contact'a gönderilen e-postaların ve diğer iletişimlerin teslim edilebilirliği gibi hizmetlerin performansına ilişkin bilgileri içerebilir.",
    "privacy_policy_info_auto_collect_desc4": "Lütfen dikkat edin, Menuonline yürürlükteki yasalarca zorunlu kılınmadığı sürece Sizin Kişilerinizden hiçbiriyle iletişime geçmeyecektir.",
    "privacy_policy_why_we_collect_title": "BİLGİLERİNİZİ NEDEN TOPLUYORUZ",
    "privacy_policy_why_we_collect_desc": "Menuonline aşağıdaki amaçlar doğrultusunda bilgi toplar:",
    "privacy_policy_why_collect_1": "Menuonline Hesap Sahiplerine abone oldukları hizmetleri sağlamak, bu hizmetler için Hesap Sahibi bilgilerini kullanmak da dahil;",
    "privacy_policy_why_collect_2": "yönetim ve hesap yönetimi için ve ayrıca mycircle'ın web sitesine erişim için;",
    "privacy_policy_why_collect_3": "pazarlama ve tanıtım materyalleri almayı kabul ettiyseniz, Menuonline tarafından sunulan diğer ürünler ve hizmetler hakkında sizi bilgilendirmek için; ve",
    "privacy_policy_why_collect_4": "Hesap Sahibi iletişimlerine yanıt vermek veya sizin tarafınızdan talep edilen bilgileri göndermek. Bunlar şunları içerebilir ancak bunlarla sınırlı değildir:",
    "privacy_policy_why_collect_5": "özel teklifler ve güncellemeler;",
    "privacy_policy_why_collect_6": "haber bülteni; ve",
    "privacy_policy_why_collect_7": "hizmet duyuruları.",
    "privacy_policy_opt_out_desc": "Menuonline'dan size gönderilen her e-postanın alt kısmında bulunan aboneliği iptal et butonuna tıklayarak istediğiniz zaman iletişimleri almaktan vazgeçebilirsiniz.",
    "privacy_policy_use_disclosure_title": "KİŞİSEL BİLGİLERİN KULLANIMI VE AÇIKLANMASI",
    "privacy_policy_use_disclosure_desc1": "Menuonline'a gönderilen kişisel bilgiler, Menuonline'ın web sitesi veya öngörülen bir form aracılığıyla da dahil olmak üzere, bir Hesap Sahibinin açık rızası veya yasal gereklilikler uyarınca ifşa edilmediği sürece kesinlikle gizli tutulur. Her durumda, Hesap Sahipleri tarafından herhangi bir \"Menuonline\" platformunda sağlanan herhangi bir kişisel bilgi gönüllüdür: Hesap Sahibi tarafından sağlanan herhangi bir kişisel bilgi, Hesap Sahibinin takdirine bağlı olarak gönderilir. Lütfen rızanızı geri çekmenin belirli Menuonline hizmetlerine erişememenize veya bunları kullanamamanıza neden olabileceğini unutmayın.",
    "privacy_policy_use_disclosure_desc2": "Menuonline, web sitesi ve hizmetleriyle ilgili olarak üçüncü tarafları ve dış hizmet sağlayıcılarını kullanır. Hesap Sahibinin kişisel bilgileri bu tür üçüncü taraflar ve sağlayıcılarla paylaşılabilir. Ayrıca, Hesap Sahipleri, satışlar, trafik kalıpları ve ilgili bilgiler hakkında toplu istatistikler de saygın üçüncü taraflara sağlayabiliriz. Menuonline, kişisel bilgilere erişimi olan herhangi bir üçüncü taraf sağlayıcının bu Gizlilik Politikası ile tutarlı yükümlülüklere bağlı olmasını ve bu tür sağlayıcıların Hesap Sahibinin kişisel bilgilerini yalnızca Menuonline hizmetlerini sağlama amaçları doğrultusunda toplamasını, kullanmasını veya ifşa etmesini gerektirir.",
    "privacy_policy_use_disclosure_desc3": "Hesap Sahipleri tarafından sağlanan kişisel bilgiler, sizin zımni veya açık izniniz dışında dış kuruluşlara satılmayacak, paylaşılmayacak veya kiralanmayacaktır. Menuonline, bilgi iletmek, sorulara yanıt vermek veya programımızda veya politikalarımızda herhangi bir değişiklik bildiriminde bulunmak için herhangi bir Hesap Sahibiyle iletişime geçme hakkını saklı tutar. Lütfen unutmayın, Menuonline, yürürlükteki yasalarca gerekli olmadığı sürece, Kişilerinizden hiçbiriyle iletişime geçmeyecektir.",
    "privacy_policy_use_disclosure_desc4": "Menuonline, kişisel bilgileri yalnızca ilgili kişinin onayı olması veya:",
    "privacy_policy_disclosure_list_1": "kanunen izin verildiğinde veya gerekli görüldüğünde, örneğin bir celp veya diğer yasal bir işleme yanıt olarak;",
    "privacy_policy_disclosure_list_2": "Menuonline'ın tamamının veya bir kısmının devralanına;",
    "privacy_policy_disclosure_list_3": "Bu Politika uyarınca Menuonline'ın hizmet sağlayıcılarına; veya",
    "privacy_policy_disclosure_list_4": "düzenleyici raporlama yükümlülükleri dahil ancak bunlarla sınırlı olmamak üzere yasal yükümlülükleri yerine getirmek.",
    "privacy_policy_service_providers_title": "Menuonline aşağıdaki üçüncü tarafları ve hizmet sağlayıcıları kullanmaktadır (Kişilerinizin kişisel bilgileri \"bu\" üçüncü taraflara açıklanmaz):",
    "privacy_policy_service_provider_1": "Satış ve destek için web sitesi sohbet hizmetleri sağlamak ve bu görüşmelerle ilgili takip e-postaları göndermek için ManyChat'i kullanıyoruz.",
    "privacy_policy_service_provider_2": "Müşterilerimize, aldıkları hizmet ve desteğin kalitesini sormak amacıyla anket yapmak için AskNicely'i kullanıyoruz.",
    "privacy_policy_service_provider_3": "Müşterilerimiz ve potansiyel müşterilerimizle toplantılar, destek görüşmeleri ve yazılım demoları planlamak için join.me'yi kullanıyoruz.",
    "privacy_policy_service_provider_4": "Web sitemizdeki anonim web sitesi etkinliğini izlemek ve kullanım modellerini ölçmek için Google Analytics kullanıyoruz.",
    "privacy_policy_breach_notification_title": "İHLAL BİLDİRİMİ",
    "privacy_policy_breach_notification_desc": "Menuonline, kişisel bilgilerin yetkisiz kullanımı, erişimi veya ifşası ile ilgili olarak yürürlükteki yasalara uymalı ve Hesap Sahiplerine bu yasalara uyum konusunda makul yardım sağlamalıdır.",
    "privacy_policy_rights_access_title": "HAKLARINIZ VE ERİŞİMİNİZ",
    "privacy_policy_rights_access_desc": "Menuonline'ın sizin hakkınızda işlediği kişisel bilgilere erişim hakkınız vardır. Bizden şu konularda bilgi talep edebilirsiniz:",
    "privacy_policy_rights_access_list_1": "Hakkınızda tuttuğumuz kişisel bilgiler",
    "privacy_policy_rights_access_list_2": "İlgili kişisel bilgi kategorileri",
    "privacy_policy_rights_access_list_3": "İşlemenin amaçları",
    "privacy_policy_rights_access_list_4": "Kişisel bilgilerinizin kimlere açıklandığı\/açıklanacağı hakkında ayrıntılar",
    "privacy_policy_rights_access_list_5": "Kişisel bilgilerinizi ne kadar süreyle saklıyoruz?",
    "privacy_policy_rights_access_list_6": "Bilgileri doğrudan sizden toplamadıysak, kaynak hakkında bilgi",
    "privacy_policy_rights_access_list_7": "Doğru denetim makamına nasıl şikayette bulunulur",
    "privacy_policy_rights_access_list_8": "Ayrıca bizden aşağıdakileri de talep edebilirsiniz:",
    "privacy_policy_rights_access_list_9": "Hakkınızda eksik veya yanlış olan bilgileri güncellememiz",
    "privacy_policy_rights_access_list_10": "Yürürlükteki yasalara uygun olarak kişisel bilgilerinizin silinmesini talep edin",
    "privacy_policy_rights_access_contact": "2851 Dufferin Street, Toronto, ON, Kanada adresinden bize ulaşarak veya {link} adresine e-posta göndererek haklarınızın kullanılmasını talep edebilirsiniz.",
    "privacy_policy_accountability_title": "SORUMLULUK",
    "privacy_policy_accountability_desc": "Menuonline, bize sağladığınız kişisel ve gizli bilgilerden sorumlu olmayı taahhüt eder. İletişim bilgileri için lütfen bu Politikanın sonuna bakın.",
    "privacy_policy_links_other_sites_title": "DİĞER SİTELERE BAĞLANTILAR",
    "privacy_policy_links_other_sites_desc1": "Menuonline'ın web sitesi diğer sitelere bağlantılar içerebilir. Lütfen Menuonline'ın diğer sitelerin gizlilik uygulamalarından sorumlu olmadığını unutmayın. Hesap Sahiplerimizi sitemizden ayrıldıklarında, kişisel olarak tanımlanabilir bilgileri toplayan her bir web sitesinin gizlilik politikası bildirimlerini dikkatlice okumaları gerektiğinin farkında olmaya teşvik ediyoruz.",
    "privacy_policy_links_other_sites_desc2": "Bu Gizlilik Politikası yalnızca bizim tarafımızdan toplanan bilgiler için geçerlidir.",
    "privacy_policy_links_other_sites_desc3": "Ürün veya hizmet satın almak için Menuonline sitesinden bir bağlantı kullanıyorsanız, Menuonline'ın değil, satıcı şirketin kurallarına ve düzenlemelerine göre bir satın alma işlemini tamamlamış olursunuz.",
    "privacy_policy_cookies_title": "GİZLİLİK POLİTİKASI ÇEREZLERİ",
    "privacy_policy_cookies_desc1": "Menuonline web sitesi ve hizmetleri, bu politikaya uygun olarak belirli bilgileri toplamak ve depolamak için çerezler kullanır. Bir 'çerez', bir web sitesinden gönderilen ve kullanıcının web tarayıcısı tarafından kullanıcının bilgisayarında depolanan küçük bir metin dosyasıdır. Çerez kullanan bir siteyi ziyaret ettiğinizde, bilgisayarınıza veya mobil cihazınıza bir çerez indirilir. Bu siteyi bir sonraki ziyaretinizde, cihazınız tercihler, ziyaret edilen sayfalar veya oturum açma seçenekleri gibi yararlı bilgileri hatırlayacaktır.",
    "privacy_policy_cookies_desc2": "Çerezler, web sitelerine işlevsellik eklemek veya daha verimli çalışmalarını sağlamak için yaygın olarak kullanılır. Sitemiz, kullanıcı deneyimini optimize etmek ve site hizmetlerinin düzgün çalışmasını sağlamak için çerezlere güvenir.",
    "privacy_policy_cookies_desc3": "Çoğu web tarayıcısı, tarayıcı ayarları aracılığıyla çerezleri kısıtlamak veya engellemek için bir miktar kontrol olanağı sağlar; ancak çerezleri devre dışı bırakırsanız, bunun web sitemizin veya hizmetlerimizin belirli bölümlerini kullanma yeteneğinizi etkileyebileceğini görebilirsiniz.",
    "privacy_policy_cookies_analytical_purpose": "<strong> Analitik Amaçlar: <\/strong> Web sitemizi iyileştirmek için Hesap Sahibi etkinliğini analiz etmek amacıyla çerezler kullanırız. Örneğin, Hesap Sahiplerinin kullandığı özellikler gibi toplu kalıplara bakmak için çerezleri kullanabiliriz. Bu tür analizleri web sitemizin işlevselliğini ve kullanıcı deneyimini nasıl iyileştirebileceğimize dair içgörüler elde etmek için kullanabiliriz.",
    "privacy_policy_cookies_preferences_user_experience": "<strong> Tercihleriniz ve Kullanıcı Deneyiminiz: <\/strong> Hesap Sahibinin deneyimini daha tutarlı ve kullanışlı hale getirmek için web sitemizde Hesap Sahibi tercihlerini depolamak amacıyla, tarayıcı türü, sunucu, dil tercihi ve ülke ayarı gibi ziyaretçiler ve Hesap Sahipleri hakkında belirli bilgileri toplamak için çerezleri kullanırız. Web sitemizi tekrar tekrar ziyaret ettiğinizde oturum açmış durumunuzu korumak için çerezleri kullanırız.",
    "privacy_policy_cookies_targeted_ads": "<strong> Ölçüm Hizmetleri ve Daha İyi Hedeflenen Reklamlar ve Pazarlama Sağlayın: <\/strong> Ölçüm hizmetleri, daha iyi hedeflenen reklamlar ve pazarlama amaçları için Google ve Facebook gibi üçüncü taraf ortaklardan çerezler, web işaretçileri ve diğer depolama teknolojilerini kullanırız. Bu, web sitemizi ziyaret ettiğinizde gerçekleşir. Bu çerezler, web işaretçileri ve diğer depolama teknolojileri, Menuonline tanıtım materyalini İnternet genelinde ziyaret ettiğiniz diğer sitelerde size göstermemizi sağlar.",
    "privacy_policy_security_title": "GÜVENLİK",
    "privacy_policy_security_desc1": "Menuonline, kişisel bilgiler de dahil olmak üzere her türlü gizli bilgiyi, bilgilerin hassasiyetine uygun organizasyonel, fiziksel, mekanik ve elektronik güvenlik önlemleriyle korumak için çaba göstermektedir. Sunucularımız Kanada'da bulunmaktadır ve güvenlikle ilgili endüstri standartlarını takip etmektedir.",
    "privacy_policy_security_desc2": "Ancak, aldığımız önlemlerin bilgilerin güvenliğini garanti altına alacağını garanti edemeyeceğimizi lütfen unutmayın.",
    "privacy_policy_retention_title": "TUTULMA",
    "privacy_policy_retention_desc": "Hesap Sahipleri ve İletişim Kişileri hakkındaki kişisel bilgileri, bu Gizlilik Politikasında belirtilen amaçlar için gerekli olduğu sürece ve Kullanım Şartları'ndaki saklama hükümlerine uygun olarak saklarız.",
    "privacy_policy_access_questions_title": "ERİŞİM, SORULAR VE ENDİŞELER",
    "privacy_policy_access_questions_desc": "Kişisel bilgilerini Menuonline'a ileten bir birey, Menuonline tarafından saklanan kişisel bilgilerine erişmek veya bunları düzeltmek için yazılı bir talepte bulunabilir.",
    "terms_obj": {
        "terms_conditions_title": "ŞARTLAR VE KOŞULLAR",
        "terms_conditions_subtitle": "Yeni ve Mevcut Müşteriler İçin Şartlar ve Koşullar",
        "terms_conditions_desc1": "AŞAĞIDAKİ ANA NOKTALAR (BÖLÜM REFERANSLARI DAHİL) SADECE RAHATLIĞINIZ İÇİN SAĞLANMIŞTIR VE TAM ŞARTLAR VE KOŞULLARIN YERİNİ ALMAZ. Menuonline HİZMETİNİ KULLANMADAN ÖNCE BU ŞARTLAR VE KOŞULLARI OKUMAK SİZİN SORUMLULUĞUNUZDADIR.",
        "terms_conditions_desc2": "Menuonline'ı kullanmanız, Şartlar ve Koşulları kabul ettiğiniz ve kuruluşunuz veya şirketiniz tarafından bunu yapmaya yetkili olduğunuzu beyan ve garanti ettiğiniz anlamına gelir. (Bkz. s.3 “Nasıl çalışır”)",
        "terms_conditions_desc3": "Menuonline, izin tabanlı bir pazarlama hizmetidir. \"Spam\" gönderme veya Şartlar ve Koşullar'ı ihlal etme kasıtlı olarak hesabınızın kapatılmasıyla sonuçlanacaktır. (Bkz. s.16 \"Hizmet'in Kabul Edilebilir Kullanımı\")",
        "terms_conditions_desc4": "Menuonline'da barındırdığınız veriler (iletişim kayıtları, e-posta içeriği ve Menuonline aracılığıyla topladığınız bilgiler gibi) size aittir. (Bkz. m.14 “Neye sahipsiniz?”).",
        "terms_conditions_desc5": "Menuonline yazılımı ve web sitemizdeki içerik bize aittir. (Bkz. m.13 “Neye sahibiz?”)",
        "terms_conditions_desc6": "Kendi kullanıcılarınızın dışında, Menuonline'da barındırdığınız veriler veya yayınlanmamış içerikler yalnızca gizlilik sözleşmeleri imzalamış olan Menuonline çalışanları ve iş ortakları tarafından görüntülenebilir. (Bkz. m.14 “Neye sahipsiniz?”).",
        "terms_conditions_desc7": "Tüm Kişisel Tanımlayıcı Bilgiler (PII) yalnızca Kanada'da bulunan sunucularda tutulur. (Bkz. m.14 “Neye sahipsiniz?”).",
        "terms_conditions_desc8": "Menuonline hizmeti Kanada ve Ontario eyaletinin yasalarına tabidir. (Bkz. s.24 “Yürürlükteki Yasa”)",
        "terms_conditions_desc9": "Kanada'dan veya Kanada dışından bireyler, polis veya diğer düzenleyici kurumlar tarafından verileriniz veya içeriklerinizle ilgili herhangi bir talep her zaman size iletilecektir. Tek istisna, bunun bizi Kanada veya Ontario yasalarını ihlal etmeye itmesidir; ancak her durumda verilerinizin sizden başkası tarafından ifşa edilmesine şiddetle karşı çıkacağız. (Bkz. s.14 \"Neye sahipsiniz?\").",
        "terms_conditions_desc10": "Menuonline güvenliği yalnızca kullanıcı adları ve parolalarınızın yönetimi kadar iyidir. Menuonline kullanıcı hesapları için ücret almıyoruz, bu nedenle her kullanıcı için ayrı bir hesap oluşturun ve parolaları gizli tutun. Hesabınızdan siz sorumlusunuz. (Bkz. s.3 “Nasıl çalışır”)",
        "terms_conditions_desc11": "Hesabınızı istediğiniz zaman iptal edebilirsiniz. İptal etmeniz durumunda kullanılmayan krediler için geri ödeme sağlamayız. (Bkz. s.3 “Nasıl çalışır”)",
        "terms_conditions_desc12": "Hesabınızı istediğimiz zaman iptal edebiliriz. Hesabınızı iptal edersek e-posta kredileri ve kullanılmayan hizmetler için geri ödeme sağlarız. (Bkz. s.3 “Nasıl çalışır”)",
        "terms_conditions_desc13": "Verileriniz iptalden sonra silinecektir ve ayrıca bir hesap kullanıcısı 12 ay veya daha uzun süredir oturum açmamışsa silinebilir. Kişisel olarak tanımlanabilir bilgiler içermeyen hesabınızdan toplanan verileri istatistiksel amaçlar için saklayacağız. (Bkz. s.3 \"Nasıl çalışır\")",
        "terms_conditions_desc14": "E-posta kredileri ödeme yaptıkça satın alınır ve asla sona ermez. Tek istisna, hesabınıza 12 ay boyunca giriş yapmamış olmanızdır. Bu durumda, hesabınızı, verilerinizi ve ilişkili kredileri silme hakkını saklı tutarız. (Bkz. s.10 \"Hizmet için ne ödüyorum?\")",
        "terms_conditions_desc15": "Menuonline'ın her zaman çalışır durumda olmasını sağlamak ve bakım için hizmeti geçici olarak askıya almamız gerekirse önceden bildirimde bulunmak için çok çalışıyoruz. Ancak, hizmetin her zaman çalışır durumda olacağını garanti etmiyoruz ve Menuonline'ı kullanmanızdan kaynaklanan herhangi bir kayıptan, sistemin ihtiyaç duyduğunuz bir zamanda çalışmaması durumunda sizin tarafınızdan uğranılan kayıplar dahil, sorumlu değiliz. (Bkz. s.17 “Sorumluluk Reddi”).",
        "terms_conditions_desc16": "İçeriğinizi veya kime mesaj gönderdiğinizi izlemiyoruz. Ancak, faaliyetlerinizin yarattığı şikayetleri ve diğer sorunları izliyoruz. Çok fazla şikayet veya sorun, takdirimize bağlı olarak hizmetinizin iptal edilmesine yol açabilir. (Bkz. s.7 “İzleme”)",
        "terms_conditions_desc17": "Menuonline ile kendi kendinize yetebilmenize yardımcı olmak için mükemmel Ürün Desteği sağlamaya kararlıyız. Web sitemizdeki dokümantasyon ve video, Menuonline destek bileti sistemi ve telefonla destek sağlıyoruz. Ürün desteği için herhangi bir ücret alınmamaktadır; ancak, takdirimize bağlı olarak belirli müşteri hesaplarına desteği sonlandırma hakkını saklı tutuyoruz. (Bkz. s.5 “Destek”)",
        "terms_conditions_desc18": "Ürün Hizmetleri, Menuonline ile ilgili bazı işleri kendiniz yapmak yerine bizim yapmamızı istediğiniz zamanlar içindir. Ürün Hizmetleri için ücretimiz saat başına 135 dolardır. Ayrıca daha yüksek ücretlerle danışmanlık hizmetleri de sağlıyoruz. Ücretlerimizi herhangi bir zamanda değiştirme hakkını saklı tutuyoruz. (Bkz. s.6 “Ürün ve Danışmanlık Hizmetleri”)",
        "terms_conditions_desc19": "Verilerinizin ihlal edildiğine inanırsak sizi bilgilendireceğiz ve ilgili ayrıntıları ve bilgileri sağlamak için çaba göstereceğiz. (Bkz. m.14 “Neye sahipsiniz?”)",
        "terms_conditions_desc20": "Bu sözleşmede herhangi bir zamanda değişiklik yapma hakkını saklı tutuyoruz. Şartlar ve koşullardaki değişiklikler hakkında sizi bilgilendirmeye çalışacağız ancak hizmeti kullanmanız, Şartlar'daki değişiklikleri kabul ettiğiniz anlamına gelir. (Bkz. s.9 \"Değişiklikler\").",
        "terms_conditions_desc21": "Şartlar ve Koşullar ile Gizlilik Politikamız yalnızca İngilizce olarak hazırlanmıştır ve herhangi bir tercüme edilmiş versiyonla herhangi bir çelişki olması halinde Şartlar ve Koşullar ile Gizlilik Politikasının İngilizce versiyonunun öncelikli olacağını kabul edersiniz.",
        "terms_conditions_mycircle": "MYCIRCLE ŞARTLARI VE KOŞULLARI",
        "terms_conditions_last_update": "SON GÜNCELLEME: 14 Ocak 2020",
        "terms_conditions_usage_agreement": "Lütfen bu web sitesini ('Site') kullanmadan önce bu hizmet şartlarını ve koşullarını ('Şartlar') dikkatlice okuyun. Bu Şartlar, Menuonline'ı ('Menuonline', 'biz' veya 'bize') ve diğerlerini sorumluluktan muaf tutar ve\/veya bizim ve onların sorumluluğunu sınırlar ve bu Sitenin kullanımınıza uygulanan diğer önemli hükümleri içerir.",
        "terms_conditions_acceptance": "Bu Siteyi veya Hizmeti ziyaret ederek veya kullanarak, kendi adınıza ve adına hareket edebileceğiniz herhangi bir kuruluş adına (bundan böyle topluca 'siz' olarak anılacaktır), bu Sitenin her kullanımında ve her ziyaretinizde bu Şartları kabul etmeyi ve bunlara uymayı kabul edersiniz.",
        "terms_conditions_services": "1. Hizmetlerimiz.",
        "terms_conditions_services_desc": "Site aracılığıyla sunduğumuz hizmetler, seçtiğiniz alıcılara e-posta mesajları ('E-postalar') oluşturmanıza, göndermenize ve yönetmenize olanak tanıyan bir hizmettir ('Hizmet').",
        "terms_conditions_license": "2. Lisans.",
        "terms_conditions_license_desc": "Bu Şartlara tabi olarak, Menuonline size Siteyi görüntülemeniz ve Hizmeti Hizmetin özellikleri tarafından izin verildiği şekilde kullanmanız için Süre boyunca münhasır olmayan, sınırlı, devredilemez bir lisans verir. Hizmette ve Menuonline İçeriğinde (aşağıda tanımlandığı şekilde) açıkça verilmeyen tüm hakları saklı tutuyoruz.",
        "terms_conditions_account": "3. Nasıl çalışır?",
        "terms_conditions_account_desc": "Hizmeti kullanmak için bir hesap için kayıt olmanız gerekir. Hesabınız ücretsizdir. Hesabınız size Hizmete ve zaman zaman oluşturup sürdürebileceğimiz \"işlevlere\" erişim sağlar. Bir hesap için uygun olmak için şunları yapmalısınız:",
        "terms_conditions_account_requirements": [
            "en az on sekiz (18) yaşında olmak ve sözleşme yapabilecek durumda olmak;",
            "Belirlediğimiz kayıt sürecini tamamlayın;",
            "bu Şartlar ve Koşulları kabul ediyorum;",
            "doğru, eksiksiz ve güncel iletişim ve faturalama\/ödeme bilgileri sağlayın."
        ],
        "responsibility": "Sorumluluk.",
        "responsibilityText": "Hesabınızda gerçekleşen etkinlikten yalnızca siz sorumlusunuz ve hesap şifrenizi gizli ve güvenli tutmalısınız. Hesaplar ücretsizdir, bu nedenle sahip olduğunuz her kullanıcı için farklı bir hesap oluşturmanızı öneririz. Ayrıca, bu hesabın kullanımını yetkilendirmiş olsanız da olmasanız da, erişiminiz olan herhangi bir hesaptan da siz sorumlusunuz. E-posta gönderdiğiniz kişilerle etkileşimlerinizden yalnızca siz sorumlu olduğunuzu kabul edersiniz ve yalnızca onayını aldığınız kişilere E-posta göndereceksiniz. Hesaplarınızın yetkisiz kullanımı durumunda bizi derhal bilgilendirmelisiniz.",
        "compliance_with_CASL": "Kanada Anti Spam Mevzuatına (CASL) uygunluk.",
        "compliance_with_CASL_text": "Menuonline'ın rolü, mevzuatı uygulamak değil, rıza ayarlarının yönetimini kolaylaştırmaktır. CASL ile uyumlu olmak sizin sorumluluğunuzdadır. Varsayılan işlevselliği ayarlayarak ve belgelenmiş açık rızayı edinmenizi ve güncellemenizi kolaylaştırarak ve zımni rıza için yuvarlanan son kullanma tarihlerini yöneterek size yardımcı oluyoruz. Veritabanınız için rızayı yönetme konusunda tam kontrol sizdedir; tüm kişilerinizin rıza durumunu toplu olarak istediğiniz zaman güncelleyebilir, varsayılan ayarları geçersiz kılabilir ve zımni rıza durumunun otomatik sona ermesini devre dışı bırakabilirsiniz.",
        "closing_account": "Hesabınızı Kapatma.",
        "closing_account_text": "Siz veya Menuonline, diğer tarafa Bildirimde bulunarak bu Sözleşmeyi istediğiniz zaman ve herhangi bir nedenle feshedebilirsiniz. Yanlış, hatalı, güncel olmayan veya eksik herhangi bir bilgi vermeniz veya Hizmeti bu Şartlar ve Koşulları ihlal eden bir şekilde kullanmanız durumunda, hesabınızı derhal askıya alma ve Hizmete erişiminizi sonlandırma hakkına ek olarak, bizim için mevcut diğer tüm çözümlere sahibiz. Hesabınızı on iki (12) ay boyunca kullanmazsanız, hesabınızı iptal edebiliriz. Hesabınızı herhangi bir nedenle feshedersek, kalan Kredilerinizi size iade edeceğiz. Feshedildikten sonra, gönderdiğiniz E-postalar dahil olmak üzere hesabınızı ve onunla ilişkili tüm verileri kalıcı olarak sileceğiz. İstatistiksel amaçlar için kişisel olarak tanımlanabilir bilgiler içermeyen hesabınızdan toplu verileri saklayabiliriz.",
        "emailing_content": "İçeriği E-postayla Gönderme.",
        "emailing_content_text": "Bu Site veya Hizmet aracılığıyla Menuonline ile etkileşim kurduğunuzda, gerçek ve doğru bilgiler sağlamayı kabul edersiniz. Ayrıca, e-posta yoluyla gizli bilgi sağlamanın güvenli olmayan bir uygulama olduğunu anlıyorsunuz. Hizmetimizi kullanan herhangi birine gönderdiğiniz her şeyden yalnızca sizin sorumlu olduğunuzu anlıyorsunuz.",
        "support": "Destek.",
        "support_text": "Hizmetle kendi kendinize yetebilmenize yardımcı olmak için size destek sağlıyoruz. Sitede, Menuonline destek bileti sisteminde ve telefonla dokümantasyon ve videolar şeklinde destek sağlıyoruz. Sitenin en üstündeki \"Destek\" sekmesine tıklayarak bize ulaşabilirsiniz. Ürün desteği için herhangi bir ücret alınmamaktadır; ancak, kendi takdirimize bağlı olarak belirli müşteri hesaplarına desteği sonlandırma hakkını saklı tutuyoruz.",
        "product_and_consulting_services": "Ürün ve Danışmanlık Hizmetleri.",
        "product_and_consulting_services_text": "Ürün Hizmetleri, Menuonline ile ilgili bir işi kendiniz yapmak yerine bizim yapmamızı istediğiniz zamanlar içindir. Ürün Hizmetleri için ücretimiz saat başına 125 dolardır. Ayrıca daha yüksek ücretlerle danışmanlık hizmetleri de sağlıyoruz. Ücretlerimizi herhangi bir zamanda değiştirme hakkını saklı tutuyoruz.",
        "monitoring": "İzleme.",
        "monitoring_text": "Hizmet üzerinden iletilen bilgilerin içeriğini izlemeyeceğiz veya yargılamayacağız, ancak olası uygunsuz kullanım şikayetleri alırsak araştıracağız. Menuonline, herhangi bir zamanda ve kendi takdirine bağlı olarak, aşağıdaki yönergelere göre size bildirimde bulunarak veya bulunmadan belirli kullanımların uygun olup olmadığına karar verebilir.",
        "term": "Terim.",
        "term_text": "Terim, bizimle bir hesap için kaydolduğunuzda başlar ve Hizmeti kullandığınız sürece devam eder. Bizim için bir hesap için kaydolma yetkiniz ve yeteneğiniz olduğunu beyan ve garanti edersiniz (bir kuruluş adına kaydolmanız dahil).",
        "changes": "Değişiklikler.",
        "changes_text": "Terim, bizimle bir hesap için kaydolduğunuzda başlar ve Hizmeti kullandığınız sürece devam eder. Bizim için bir hesap için kaydolma yetkiniz ve yeteneğiniz olduğunu beyan ve garanti edersiniz (bir kuruluş adına kaydolmanız dahil).",
        "payment": "ÖDEME",
        "what_do_i_pay_for_service": "Hizmet için ne kadar ödeme yapacağım?",
        "what_do_i_pay_for_service_text": "Sitemizin \"fiyatlandırma\" sayfasında ayrıntılı olarak açıkladığımız gibi, Hizmetimiz aracılığıyla E-posta göndermek için ön ödemeli krediler (\"Krediler\") satın alabilirsiniz. Krediler asla sona ermez ve bu Krediler için tekrarlayan ücretler yoktur. Tek istisna, hesabınızı on iki (12) ay boyunca kullanmazsanız, hesabınızı ve kullanılmayan Kredileri iptal edebiliriz. Sitemizin fiyatlandırma sayfalarını güncelleyerek fiyatlandırmamızı istediğimiz zaman değiştirebiliriz; bu, bir sonraki Kredi satın alımınıza uygulanacaktır.",
        "how_do_i_pay_for_service": "Hizmet için nasıl ödeme yaparım?",
        "how_do_i_pay_for_service_text": "Krediler için herhangi bir büyük kredi kartını kullanarak ödeme yapabilirsiniz. Kabul ettiğimiz ödeme yöntemlerini her an değiştirebiliriz.",
        "can_i_get_refund": "Para iadesi alabilir miyim?",
        "can_i_get_refund_text": "Hizmeti kullanmak için ödediğiniz tutarlar, hesabınızı iptal ederseniz iade edilmez. Hesabınızı iptal edersek, kalan Kredilerinizi size iade edeceğiz.",
        "rights": "HAKLAR",
        "what_do_we_own": "Bizim neye sahibiz?",
        "what_do_we_own_text": "Bize sağladığınız içerik (sınırlama olmaksızın iletişim kayıtları, e-posta içeriği ve Hizmet aracılığıyla topladığınız bilgiler dahil) hariç (\"İçeriğiniz\"), Sitemizde, Hizmette ve bunlarda yer alan veya bunlar aracılığıyla aktarılan tüm materyaller, sınırlama olmaksızın yazılım, resim, metin, grafik, çizim, logo, patent, ticari marka, hizmet markası, fotoğraf, ses, video, müzik ve diğer Kullanıcılara ait içerik (\"Menuonline İçeriği\") (ve bunlarla ilgili tüm fikri mülkiyet hakları) bize veya lisans verenlerimize aittir.",
        "what_do_you_own": "Neye sahipsin?",
        "what_do_you_own_text": "İçeriğiniz üzerinde hiçbir mülkiyet hakkı iddia etmiyoruz. İçeriğiniz size aittir ve Hizmete yüklediğiniz İçeriğinizin mülkiyetini elinizde tutarsınız. İçeriğinizi Hizmet aracılığıyla paylaşarak, başkalarının İçeriğinizi ayarlarınıza ve bu Şartlar ve Koşullara uygun şekilde görüntülemesine, düzenlemesine ve\/veya paylaşmasına izin vermeyi kabul edersiniz. E-postalarınızda kullandığınız tüm materyalin sahibi olduğunuzu veya bunları kullanma izninizin olduğunu beyan ve garanti edersiniz. Materyallerinizi yalnızca bu Şartlarda ve Gizlilik Politikamızda açıkladığımız şekilde kullanabilir veya ifşa edebiliriz.",
        "privacy_statement": "Menuonline kullanıcılarının gizliliğine saygı duyar. Menuonline'ın bu Site veya Hizmet aracılığıyla topladığı tüm kişisel bilgiler, bu Şartlara atıf yoluyla dahil edilen Menuonline Gizlilik Beyanı'na tabidir. Bilgilerinizi Gizlilik Beyanımıza göre kullanabilir ve ifşa edebiliriz.",
        "access_to_your_content": "Menuonline, bizimle uygun gizlilik sözleşmeleri imzalamış olan veya sizin onayınızı almış olan Menuonline çalışanları ve yüklenicileri dışında hiç kimseye İçeriğinize erişim sağlamayacaktır.",
        "governmentRequest": "İçeriğinize erişim için bir hükümet veya düzenleyici otoriteden bir talep alırsak, talebi size bildireceğiz ve İçeriğinizden herhangi birini yayınlamadan önce onayınızı isteyeceğiz. İçeriğinize yönelik herhangi bir talebe karşı koyacağız ve izniniz olmadan veya yetkili bir yargı alanındaki mahkeme veya ilgili düzenleyici kurum tarafından zorunlu kılınmadan bu tür bir talebe yanıt olarak İçeriğinizi yayınlamayacağız.",
        "breachNotification": "İçeriğinizin yetkisiz erişime, kullanıma, kopyalanmaya, çalınmaya, kaybolmaya veya bu Şartlar'ın ihlaliyle kullanım veya ifşaya maruz kaldığını fark etmemiz halinde (bir 'İhlal'), sizi derhal bilgilendireceğiz ve ne olduğunu bildireceğiz. İhlallerin yürürlükteki yasaya uygun şekilde ele alınacağını ve E-posta dağıtım listenizdeki üyeleri bilgilendirmeniz gerekirse bunu derhal ve bizim talimatımız doğrultusunda yapmayı kabul edersiniz.",
        "contentRetention": "İçeriğinizi, önceden yazılı onayınız veya talimatınız olmadığı sürece Kanada'da saklayacağız, kullanacağız ve ileteceğiz (örneğin, İçeriğinizi Kanada dışındaki kişilere göndermeyi seçerseniz). Hizmeti sağlamak için kullanılan sunucular Kanada'da bulunmaktadır.",
        "accessTrouble": "İçeriğinize erişimde sorun yaşıyorsanız Menuonline, İçeriğinizi zaman ve malzeme bazında almanıza yardımcı olacaktır.",
        "reportingAbuse": "Herhangi birinin bu Şartlardan herhangi birini ihlal ettiğini düşünüyorsanız lütfen hemen bize bildirin. Hizmetin başka bir kullanıcısından geldiğini düşündüğünüz spam'ler aldıysanız, Hizmetin başka bir kullanıcısının telif haklarını ihlal eden materyaller yayınladığını düşünüyorsanız veya Hizmetin başka bir kullanıcısının bu Şartları başka bir şekilde ihlal ettiğini düşünüyorsanız lütfen Support@menuonline.com adresinden bizimle iletişime geçin.",
        "acceptableUse": "Hizmeti kullanımınızın tüm geçerli yasa ve yönetmeliklere uyacağını beyan ve garanti edersiniz. Hizmetin yargı bölgenizde sizin için uygun olup olmadığını ve uyumlu olup olmadığını belirlemekten sorumlu olduğunuzu kabul edersiniz. Daha fazla kesinlik için, ... yapacağınızı (ve çalışanlarınızın, temsilcilerinizin ve yetkili kullanıcılarınızın bunu yapmasını sağlamayı) kabul edersiniz.",
        "exportLaws": "Sitenin veya Hizmetin Kanada dışında kullanıma uygun veya erişilebilir olduğuna dair hiçbir beyanda bulunmuyoruz ve içeriklerinin yasadışı olduğu bölgelerden bunlara erişim yasaktır. Menuonline İçeriğini veya bu Sitedeki diğer materyalleri veya herhangi bir kopyasını veya uyarlamasını, Kanada ve ABD ihracat yasaları ve yönetmelikleri dahil ancak bunlarla sınırlı olmamak üzere geçerli herhangi bir yasa veya yönetmeliği ihlal ederek kullanamaz, ihraç edemez veya yeniden ihraç edemezsiniz.",
        "disclaimers": "Hizmet ve bu Site, herhangi bir beyan, garanti veya koşul olmaksızın 'olduğu gibi' sağlanır ve Menuonline, uygulanabilir yasaların izin verdiği azami ölçüde, pazarlanabilirlik, belirli bir amaç veya kullanıma uygunluk veya üçüncü taraf haklarının ihlal edilmemesi dahil ancak bunlarla sınırlı olmamak üzere, yasa gereği veya başka bir şekilde açık veya zımni tüm beyan, garanti ve koşulları açıkça reddeder...",
        "limitLiability": "Menuonline'ın bu Sözleşme kapsamındaki toplam sorumluluğu, sözleşmeden veya haksız fiilden (ihmal dahil) kaynaklansın veya garanti ihlali, kesin sorumluluk veya herhangi bir sorumluluk teorisinin bir sonucu olsun, sorumluluğun ortaya çıkmasına neden olan en son olayın meydana geldiği aydan hemen önceki on iki (12) ayda Menuonline'a bu Şartlar ve Koşullar kapsamında ödenen toplam ücretleri aşmayacak bir miktarda doğrudan zararlarla sınırlı olacaktır.",
        "liabilityExclusions": "Menuonline'ın, bağlı şirketlerinin veya bunların ilgili yöneticilerinin, memurlarının, çalışanlarının, acentelerinin veya diğer temsilcilerinin (toplu olarak 'Menuonline \"Temsilcileri\") veya bağlı ağların operatörlerinin hiçbiri, bu Site veya Hizmet ile bağlantılı olarak aşağıdakilerden dolayı herhangi bir sorumluluk veya yükümlülüğe sahip olmayacaktır: (i) herhangi bir dolaylı, sonuçsal, tesadüfi, örnek teşkil eden, cezalandırıcı veya özel zararlar; veya (ii) doğrudan, dolaylı, sonuçsal, tesadüfi, örnek teşkil eden, cezalandırıcı veya özel olsun, gelir kaybı, tasarruf kaybı veya gelir veya kar kaybı olarak nitelendirilen herhangi bir zarar...",
        "indemnity": "Bu Siteden erişilen bilgileri kullanmanız veya Hizmeti kullanmanız ve bu Şartların herhangi bir ihlalinden kaynaklanan üçüncü kişiler tarafından getirilen her türlü talep ve aleyhimize verilen her türlü para cezası veya yaptırıma karşı Menuonline'ı ve\/veya Menuonline Temsilcilerini ('Menuonline tarafından tazmin edilen taraflar') tazmin edecek ve zarar görmemelerini sağlayacaksınız...",
        "ipInfringement": "Menuonline, bu Sözleşme uyarınca Hizmete erişiminizin veya Hizmeti kullanımınızın, Kanada yasalarına göre üçüncü bir tarafın fikri mülkiyet haklarını ihlal ettiğini iddia eden üçüncü bir tarafça size karşı yapılan veya açılan her türlü talep, istek, eylem, dava veya yasal işleme karşı sizi savunacak, tazmin edecek ve zarar görmemenizi sağlayacaktır ('Fikri Mülkiyet İhlali Talebi').",
        "equitableRelief": "Bu Şartları ihlal ederseniz, ihtiyati tedbir (yani sizi durdurmak için mahkeme emri talep edebiliriz) veya diğer eşitlikçi tedbirler talep edebiliriz. Bunun, kanunen veya eşitlik açısından sahip olabileceğimiz diğer haklara ek olacağını ve bunlara halel getirmeyeceğini kabul edersiniz.",
        "linksToOtherSites": "Üçüncü taraf web sitelerine herhangi bir bağlantı sağlarsak, bunlar yalnızca kolaylık sağlamak amacıyla sağlanır. Bu bağlantıları kullanırsanız, Sitemizden ayrılırsınız. Bu üçüncü taraf sitelerini kontrol etmiyoruz veya onaylamıyoruz. Bu üçüncü taraf sitelerinden veya içeriklerinden hiçbirinden sorumlu olmadığımızı kabul edersiniz...",
        "thirdPartyRequests": "Hesabınız veya Hizmeti kullanımınızla ilgili yasal veya resmi bir talebe yanıt olarak bilgi sağlamamız gerekirse, masraflarımızı sizden tahsil edebileceğimizi kabul edersiniz. Bu ücretlere yasal ücretlerimiz ve çalışanlarımızın talebe yanıt hazırlamak için harcadıkları zamanın maliyetleri dahil olabilir.",
        "assignment": "Bu Sözleşme ve bu Sözleşme kapsamında verilen tüm haklar ve lisanslar sizin tarafınızdan devredilemez veya tahsis edilemez, ancak Menuonline tarafından size makul bir süre önceden bildirimde bulunulması koşuluyla herhangi bir kısıtlama olmaksızın tahsis edilebilir.",
        "governingLaw": "Bu şartlar, kanunlar ihtilafı hükümleri dikkate alınmaksızın tüm geçerli federal yasalar ve Ontario yasalarına göre yönetilecek ve yorumlanacaktır. Bu Şartlar ile ilgili herhangi bir anlaşmazlığın çözümü için Ontario mahkemelerinin münhasır yargı yetkisine tabi olmayı kabul edersiniz.",
        "severability": "Herhangi bir nedenle yetkili bir mahkeme bu Şartların herhangi bir hükmünü veya bir bölümünü hukuka aykırı veya herhangi bir nedenle uygulanamaz bulursa, söz konusu hüküm bu Şartların amacına uygun olarak izin verilen azami ölçüde uygulanacak ve bu Şartların geri kalanı tam olarak yürürlükte kalmaya devam edecek ve uygulanabilir kalacaktır.",
        "waiver": "Bu Şartların gerekliliklerinden feragat veya bu şartlardan ayrılmaya onay, yalnızca yazılı olarak ve tarafımızca imzalanmışsa ve yalnızca verildiği belirli örnekte ve belirli amaç için geçerli olacaktır. Bu Şartlar kapsamındaki herhangi bir hakkı kullanmamamız veya kullanmada gecikmemiz, haklarımızdan feragat ettiğimiz anlamına gelmeyecektir.",
        "furtherAssurances": "Her taraf, bu Şartlar ve koşullar kapsamındaki yükümlülüklerini yerine getirmek için gerekli tüm belgeleri imzalamayı ve sağlamayı ve her türlü eylemi yapmayı (veya bu eylemlerin yapılmasını sağlamayı) kabul eder.",
        "notices": "Menuonline'a yapılacak tüm bildirimler yazılı olmalı ve bize e-posta ile gönderilmelidir. Bize yapılacak bildirimler, support@menuonline.com adresindeki müşteri hizmetleri temsilcilerimizin dikkatine gönderilmelidir. Size yapılacak bildirimler, kaydınızın bir parçası olarak tarafınızca sağlanan adrese veya e-posta adresine gönderilebilir. Ayrıca, bu Site üzerinden bu Site'de yapılan değişiklikler veya diğer önemli konular hakkında sizi bilgilendirmek için bildirimler veya mesajlar yayınlayabiliriz ve bu yayınlar, bunları yayınladığımız anda size bildirim teşkil edecektir.",
        "entireAgreement": "Bu Şartlar ve Gizlilik Beyanımız, Site ve Hizmet kullanımınızla ilgili olarak sizinle aramızdaki tüm sözleşmeyi oluşturur ve bu konu ile ilgili olarak Menuonline ile aranızdaki tüm önceki sözleşmelerin, beyanların ve anlayışların yerini alır. Gizlilik Beyanı ile bu Şartlar arasında herhangi bir çelişki olması durumunda, bu Şartlar ve koşullar geçerli olacaktır.",
        "language": "Taraflar, bu Şartlar ve Koşullar, Gizlilik Politikamız ve ilgili tüm belgelerin İngilizce olarak hazırlanmasını açıkça talep etmiş ve gerektirmiştir. Taraflar, bu Sözleşmenin ve tüm belgelerin İngilizce olarak hazırlanmasının uygun ve gerekli olduğunu ifade etmektedir. Herhangi bir çevrilmiş sürümle herhangi bir çelişki olması durumunda, bu Şartlar ve Gizlilik Politikasının İngilizce dil sürümünün öncelikli olacağını kabul edersiniz.",
        "survival": "Bu Şartların doğası veya önemi gereği bu Şartların sona ermesinden veya feshedilmesinden sonra da geçerliliğini koruması amaçlanan tüm hükümler, 17-19. Bölümler dahil ancak bunlarla sınırlı olmamak üzere geçerliliğini koruyacaktır."
    },
    "collect_additional_info": "Ek bilgi toplamak ister misiniz?",
    "additionalInfoChoice": "Toplamak istediğiniz ek bilgileri seçin.",
    "additional_info_yes": "Evet",
    "additional_info_noSkip": "Hayır, Atla",
    "additional_info_label": "Ek Bilgiler",
    "confirm_business_location": "Öncelikle işletmenizin yerini doğrulayalım",
    "custom_get_more_reviews_form": "Özel Daha Fazla İnceleme Al Formu",
    "set_up_custom_form": "Gruplara Göre Özel Daha Fazla Yorum Alın Formunuzu Ayarlayın",
    "reputation_name_label": "Daha Fazla Yorum Alın Adı",
    "reputation_name_placeholder": "İtibar adını girin",
    "group_label": "Grup",
    "group_desc_title": "Grup açıklaması başlığı",
    "group_desc": "Grup tanımı",
    "select_group_placeholder": "Grup Seç",
    "required_group_name": "Grup adı gereklidir",
    "before_asking_review": "Müşterilerden yorum istemeden önce onlara 1 ila 4 soru soralım.",
    "incentive_settings": "Teşvik ayarları",
    "incentivize_customer": "Müşterinizi yorum yapmaya teşvik etmek ister misiniz?",
    "choose_incentive": "Yönlendirmek istediğiniz teşviki seçin.",
    "scan_qr_feedback": "QR kodunu tarayın ve deneyiminizle ilgili değerli geri bildirimlerinizi bizimle paylaşın.",
    "qr_code": "QR Kodu",
    "scan_me": "BENİ TARA!",
    "or_title": "VEYA",
    "send_otp_title": "OTP gönder",
    "sign_in_title": "Oturum aç",
    "email_addresss_title": "E-posta Adresi",
    "reset_password_title": "Şifreyi Sıfırla",
    "remember_password_title": "Şifrenizi mi hatırladınız?",
    "digit_code_title": "Lütfen size gönderilen 4 haneli kodu giriniz. ",
    "not_received_title": "Doğrulama kodunu almadım!",
    "resend_title": "Lütfen tekrar gönderin",
    "sign_up_form": "Hesabınızı Oluşturun",
    "sign_up_sub_title": "En son bilgilere erişmek için hemen giriş yapın",
    "already_registered_title": "Zaten bir Menuonline Hesabınız var mı?",
    "reserved_title": "Telif Hakları ©2023 Menuonline. Tüm hakları saklıdır.",
    "terms_title": "Hizmet Şartları",
    "policy_title": "Gizlilik Politikası",
    "sign_up_title": "Üye olmak",
    "terms_conditions_title": "Şartlar ve Koşullar",
    "business_type_title": "İşletme Türü",
    "business_type_sub_title": "Lütfen işletmenizi daha iyi tanımlayan iş türlerinden birini seçin!",
    "salon_title": "Salon",
    "restaurant_title": "Restoran",
    "pizza_store_title": "Pizza Mağazası",
    "food_truck_title": "Yemek Kamyonu",
    "check_domain_title": "Alan Adını Kontrol Et",
    "start_exploring_title": "Keşfetmeye Başlayın",
    "sign_in_form": "Hesabınıza giriş yapın",
    "sign_in_sub_title": "En son bilgilere erişmek için hemen giriş yapın",
    "remember_title": "Beni Hatırla",
    "otp_title": "OTP",
    "password_title": "Şifre",
    "forgot_title": "Parolanızı mı unuttunuz?",
    "not_registered_title": "Hesabınız yok mu?",
    "create_account_title": "Hemen kaydolun",
    "create_form_title": "Form Oluştur",
    "first_name_title": "İlk adı",
    "last_name_title": "Soy isim",
    "email_title": "E-posta",
    "mobile_number_title": "Cep numarası",
    "birthday_title": "Doğum günü",
    "gender_title": "Cinsiyet",
    "address_title": "Adres",
    "short_answer_title": "Kısa cevap",
    "long_answer_title": "Uzun cevap",
    "single_answer_title": "Tek cevap",
    "single_checkbox_title": "Tek onay kutusu",
    "multiple_choice_title": "Çoktan seçmeli",
    "drop_down_title": "Yıkılmak",
    "yes_no_title": "Evet veya hayır",
    "description_text_title": "Açıklama metni",
    "file_upload": "Dosya yükleme",
    "reset_password_form": "Şifrenizi Sıfırlayın",
    "reset_password_sub_title": "Şifrenizi hızlıca sıfırlayın ve hesabınıza erişim sağlayın.",
    "required_full_name_title": "Tam Ad gereklidir",
    "required_first_name_title": "Ad gereklidir",
    "valid_first_name_title": "Geçerli bir ad girin",
    "valid_full_name_title": "Geçerli tam adınızı girin",
    "required_block_time": "Blok Zamanı gereklidir",
    "required_last_name_title": "Soyadı gereklidir",
    "valid_last_name_title": "Geçerli bir soyadı girin",
    "valid_mobile_number_title": "Geçerli cep telefonu numarası girin",
    "required_mobile_number_title": "Cep telefonu numarası gereklidir",
    "required_already_mobile_number_title": "Cep telefonu numarası zaten gerekli",
    "valid_otp_title": "Geçerli otp girin",
    "valid_email_title": "Geçerli bir e-posta girin",
    "required_email_title": "E-posta gereklidir",
    "required_description_title": "Açıklama gereklidir",
    "required_description_500_characters": "Lütfen en az 500 karakter girin",
    "required_description_600_characters": "Lütfen maksimum 600 karakter giriniz",
    "domain_title": "Alan Adı",
    "required_password_title": "Şifre gerekli",
    "required_password_regex_title": "En az 8 karakter, en az bir büyük harf, bir küçük harf, bir rakam ve bir özel durum karakteri içermelidir",
    "required_terms_title": "Lütfen şartlar ve koşulları kabul edin",
    "required_note_title": "Not gereklidir",
    "required_birthday_title": "Doğum günü gereklidir",
    "no_access_title": "Şu anda erişiminiz yok. Lütfen yöneticinizle iletişime geçin",
    "menu_online_open_txt": "Açık",
    "loyalty_program_description": "😊 İdeal sadakat programınızı bulun ve markanıza uyacak şekilde özelleştirin!",
    "lp_list_title": "Sadakat Punch Kartı Listesi",
    "lp_n_title": "Sadakat Punch Kartı Adı",
    "enter_details_title": "Ayrıntıları Girin",
    "stamp_allowed_title": "Damgaya İzin Verildi",
    "stamp_items_title": "Damga Öğeleri",
    "lpn_req_title": "Sadakat kartı adı zorunludur",
    "valid_lpn_title": "Geçerli sadakat kartı adını girin",
    "brand_color_error": "Marka rengi tam olarak 7 karakter olmalıdır",
    "select_dark_brand_color_title": "Lütfen geçerli marka rengini seçin",
    "your_brand_color_title": "Marka Renginiz",
    "upload_logo_title": "Logoyu Yükle",
    "locations_title": "Konumlar",
    "add_lp_title": "Program Oluştur",
    "location_error": "Lütfen lokasyonları seçin",
    "pvs_title": "Bir müşteri ziyaret başına kaç pul kazanabilir?",
    "max_apd_title": "Bir müşteri bir günde kazanabileceği maksimum damga sayısı nedir?",
    "logo_error": "Logo gerekli",
    "expiry_title": "Ödüllerin son kullanma tarihini ayarlayın",
    "add_more_rewards_title": "+ Daha Fazla Ödül Ekle",
    "prev_step_1_title": "Puan Kazanmak ve Ödüllerinizin Keyfini Çıkarmak İçin QR Kodunuzu Gösterin.",
    "delete_lp_title": "Bu sadakat kartı kalıcı olarak silinecektir.",
    "delete_user_record_title": "Bu kullanıcı kaydı kalıcı olarak silinecektir.",
    "redeem_title": "Kurtarmak",
    "lp_button_title": "Sadakat Kartına Katılın",
    "lp_download_button_title": "Kartı İndir",
    "step_0_title": "Hemen katılın, sizin gibi sadık lezzet tutkunlarını lezzetli ödüller bekliyor.",
    "step_1_title": "Katıldınız",
    "congrats_step_title": "Artık Ücretsiz Pizza'ya hak kazandınız",
    "reward_title": "Her ziyaretinizde Ödüller kazanın",
    "visit_title": "Ziyaret edin ve Kilidini Açın",
    "rewards_title": "Ödüller",
    "dummy_address": "3730 Frankfort Ave, Louisville Kentucky, Amerika Birleşik Devletleri",
    "loyalty_qr_default_text": "QR kodunu tarayın, sadakat kartımıza katılın ve ödüllerin tadını çıkarın",
    "lp_earn_stamp_txt": "Müşteriler her ziyaretlerinde pul kazanıyorlar.",
    "lp_get_rewards_txt": "Her Ziyaretinizde Ödüller Kazanın",
    "lp_1_time_visit_txt": "1 Kez Ziyaret Edeceksiniz",
    "lp_uppercase_stamp_text": "Damga vurmak",
    "lp_lowercase_stamp_text": "damga vurmak",
    "lp_txt": "Bağlılık",
    "lp_per_visit_stamp_validation_msg": "Ziyaret başına damga, günlük izin verilen maksimum damgadan az olmalıdır",
    "lp_per_max_stamp_validation_msg": "Günlük izin verilen maksimum damga sayısı, ziyaret başına damga sayısından fazla olmalıdır",
    "lp_valid_value_msg": "Geçerli bir değer girin",
    "lp_required_field_msg": "Bu alan gereklidir",
    "lp_stamp_required_msg": "Damga gerekli",
    "lp_valid_stamp_msg": "Geçerli damga değerini girin",
    "lp_reward_name_required_msg": "Ödül adı gereklidir",
    "lp_unique_stamp_msg": "Damga değeri benzersiz olmalıdır",
    "lp_benefits": "Bir sadakat programı, kişiselleştirilmiş pazarlama için müşteri içgörüleri sağlarken tekrarlanan ziyaretleri ve geliri artırır",
    "lp_how_customer_txt": "Müşterilerinizin nasıl",
    "lp_punch_card_txt": "sadakat kartı",
    "lp_look_txt": "bakacağım! 😍",
    "lp_desc": "Müşteriler her ziyaretlerinde pul kazanacak ve yeterli sayıda pul topladıklarında özel ödüller kazanabilecekler.",
    "lp_edit": "Düzenlemek",
    "lp_looks_good_txt": "Görünüşü Güzel",
    "lp_customer_view_instruction": "Müşterinizin göreceği şey budur. Logonuzu yükleyin ve renk temasını ayarlayın",
    "lp_customer_earn_stamps": "Müşterilerin her ziyarette damga kazanmalarına ve ödüllerini yönetmelerine izin verin",
    "lp_stamp_rewards_setup": "Pul kazanma ödülleri belirleyin, müşterilerin pul başına ödül almalarına izin verin",
    "lp_loyalty_program_setup": "Sadakat programınıza bir ad verin ve bu programı kullanmak için konumunuzu ayarlayın",
    "lp_prev_step1": "Puan Kazanmak ve Ödüllerinizin Keyfini Çıkarmak İçin QR Kodunuzu Gösterin.",
    "lp_visit_1_time_txt": "1 Kez Ziyaret Edin ve Kazanın",
    "lp_get_free_txt": "Ücretsiz Alın",
    "lp_visit_allow_txt": "Ziyaret Et ve Kilidini Aç",
    "lp_rewards": "Ödüller",
    "repsT": "Daha Fazla Yorum Alın",
    "offSup": "Ofis Malzemeleri",
    "repsNxtV": "Bir sonraki ziyaretinizde",
    "addRepT": "Daha Fazla Yorum Al Ekle",
    "repEntrCpnTxt": "Kupon Metnini Girin",
    "repEntrCpnTxtBtn": "Kupon Düğmesi Metnini Girin",
    "repMngNxtV": "Bir sonraki ziyaretinizde?",
    "repMngWldL": "Almak ister misin?",
    "discount": "İndirim",
    "repDisTyp": "İndirim Türü",
    "repNameT": "İtibarınızı adlandırın",
    "crtRepT": "İtibar Yarat",
    "feedBackT": "Geri bildirim",
    "confLocT": "Konumu Onayla",
    "businLocT": "İşletmenin Yeri",
    "addInfoT": "Ek Bilgiler",
    "repNReqT": "Daha Fazla Yorum Alın isim gereklidir",
    "validRepNT": "Geçerli itibar adını girin",
    "groupT": "Grup",
    "groupLDescTitleT": "Kişileri otomatik olarak bir gruba itin",
    "groupLDescT": "Formu dolduran tüm müşterilerimiz seçilen gruba eklenecektir.",
    "que2": "Yemeğiniz güzel miydi?",
    "que3": "Hizmetimiz güler yüzlü müydü?",
    "que4": "Hizmetimiz hızlı mıydı?",
    "addAdditionalT": "Eklemek istediğiniz ek bir bilgi var mı?",
    "phNoMandT": "Telefon numarası zorunludur!",
    "qrGenT": "QR Oluşturuldu",
    "repFDT": "Görüşleriniz ekibimizin size daha iyi hizmet vermesine yardımcı olacak!",
    "letdiT": "Hadi yapalım",
    "tak15secT": "15 saniye sürer",
    "defQue": "Zaman zaman harika teklifler ve promosyonlar almak ister misiniz?",
    "defNQue": "Birinin size geri dönüş yapmasını ister misiniz?",
    "rateOnGoogleDesc": "Bizi Google'da puanlayabilir misiniz?",
    "mayBeNT": "Belki başka zaman",
    "yesSureT": "Evet, kesinlikle",
    "valid_name": "Geçerli bir ad girin",
    "nameReq": "İsim gerekli",
    "backToMainT": "Ana Sayfaya Dön",
    "attachCopT": "Müşterinize teşvik vermek ister misiniz?",
    "thankYouT": "Teşekkür ederim",
    "lftyT": "Bir sonraki ziyaretinizi sabırsızlıkla bekliyoruz!",
    "repPreT": "İtibar Önizlemesi",
    "offer_exclusive_discounts": "Müşterilerinizi cezbetmek için özel indirimler ve promosyonlar sunun.",
    "couponT": "Kupon",
    "locStepDesc": "İşletmenizin yerini onaylayın",
    "createRepStepDesc": "Gruplara göre özel bir itibar formu oluşturun",
    "feedbackStepDesc": "Müşteri geri bildirimi soru seti",
    "addInfoStepDesc": "Müşteri alanları ve teşvik ayarları",
    "FedbkkWDT": "Verilerle Geribildirim",
    "repAnlytT": "İtibar Analitiği",
    "todayT": "Bugün",
    "rep_last_7_days_t": "Son 7 Gün",
    "last30DaysT": "Son 30 Gün",
    "lastMT": "Geçen ay",
    "custRangeT": "Özel Aralık",
    "queWiseAnltT": "Soru Bilge Analizler",
    "atL1QueErrT": "Lütfen en az bir soru seçin",
    "deleteRepTitle": "Bu itibar kalıcı olarak silinecektir.",
    "incvCustT": "Evet, Müşterimi teşvik et",
    "collAddInfoT": "Evet, ek bilgi toplamak istiyorum",
    "totRewT": "Toplam İnceleme",
    "totPosRewT": "Toplam Olumlu İnceleme",
    "negFeedT": "Olumsuz Geribildirim",
    "posFeedT": "Olumlu Geribildirim",
    "fineT": "Küçük Yazı",
    "enterFineT": "Küçük puntolu metni girin",
    "setThemeT": "Tema Rengini Ayarla",
    "que1T": "Soru 1",
    "que2T": "Soru 2",
    "que3T": "Soru 3",
    "que4T": "Soru 4",
    "entMailT": "E-postanızı girin",
    "reputationQRDefTxt": "QR kodunu tarayın ve deneyiminizle ilgili değerli geri bildirimlerinizi bizimle paylaşın.",
    "selUser": "Kullanıcı Seç",
    "userT": "Kullanıcı",
    "subUReq": "Lütfen kullanıcıları seçin",
    "updateLoc": "Konumu Güncelle",
    "leadGenT": "Potansiyel Müşteri Üretimi",
    "displayT": "Görüntülemek",
    "ratingT": "Derecelendirme",
    "rep_dashboard_negative_feedback": "Olumsuz Geribildirim 0%",
    "rep_dashboard_position_feedback": "Olumlu Geribildirim 0%",
    "rep_dashboard_total_Feedback_txt": "Toplam Geribildirim",
    "rep_dashboard_anony_Feedback": "Anonim Geribildirim",
    "rep_dashboard_Feeedback_Data_txt": "Verilerle Geribildirim",
    "rep_dashboard_review_txt": "Daha Fazla Yorum Alın",
    "rep_dashboard_total_Review_txt": "Toplam İnceleme",
    "rep_dashboard_total_positive_Review_txt": "Toplam Olumlu İnceleme",
    "rep_dashboard_negative_feedback_txt": "Olumsuz Geribildirim",
    "rep_dashboard_connection_txt": "Bağlantı",
    "rep_dashboard_question_wise_analytics_txt": "Soru Bilge Analizler",
    "rep_dashboard_date_label": "Tarih Seçin",
    "rep_dashboard_custom_range_txt": "Özel Aralık",
    "rep_tlt_min": "Temsilci",
    "emojis": "Emojiler",
    "media_text": "Medya Ekle",
    "custom_field_text": "Özel Alanlar Ekle",
    "shortlink_text": "Kısa Bağlantı Ekle",
    "use_template_text": "Şablonu Kullan",
    "banner_must_have": "Sahip Olunması Gereken",
    "res_seamless": "Kusursuz",
    "res_table_reservation": "Masa Rezervasyonu",
    "res_system": "Sistem",
    "res_text": "Rezervasyonlar",
    "md_easily": "Kolayca",
    "md_manage_customers": "Müşterileri Yönet",
    "md_visits_interactions": "ziyaretler ve etkileşimler",
    "md_desc": "Gerçek zamanlı masa rezervasyonlarıyla müşterileriniz için yemek yemeyi kolaylaştırın. Rezervasyonları zahmetsizce yönetin, bekleme sürelerini azaltın ve hem personel hem de misafirler için sorunsuz bir deneyim sağlayın. Birinci sınıf hizmet ve memnuniyeti korurken restoranınızı tamamen dolu tutun.",
    "engage_convert": "Engage & Convert SMS",
    "coupon_campaign": "ve Kupon Kampanyası",
    "sms_read_rate": "SMS mesajları %98 oranında okunuyor",
    "real_time_promo": "Anında eylem için promosyonları gerçek zamanlı olarak sunun",
    "affordable_reach": "Sağlam yatırım getirisi ile müşterilere ulaşmanın uygun maliyetli yolu",
    "stay_in_touch": "Müşterinizle her zaman iletişim halinde kalın",
    "bc_engage_customers": "Müşterileri Etkileşime Geçirin SMS'i Dönüştürün",
    "bc_coupon_campaign": "ve Kupon Kampanyası",
    "bc_sms_read_rate": "SMS mesajları %98 oranında okunuyor",
    "bc_real_time_promo": "Anında eylem için promosyonları gerçek zamanlı olarak sunun",
    "bc_affordable_reach": "Sağlam yatırım getirisi ile müşterilere ulaşmanın uygun maliyetli yolu",
    "bc_stay_in_touch": "Müşterinizle her zaman iletişim halinde kalın",
    "ar_automate_engagement": "Müşteri Etkileşiminizi Otomatikleştirin",
    "ar_with": "ile",
    "ar_with_auto_responders": "Otomatik Yanıtlayıcılar",
    "ar_personalized_messages": "Doğum günleri için kişiselleştirilmiş mesajları otomatik olarak gönderin, yeni müşterileri karşılayın ve geçmiş müşterilerinizle yeniden bağlantı kurun. Her etkileşimi anlamlı ve zamanında tutarken zamandan tasarruf edin.",
    "sq_custom_forms_tools": "Özel Formlar ve Araçlar - Simplify",
    "sq_custom_forms_ci": "Müşteri Etkileşimleri",
    "sq_smart_qr_desc": "İş süreçlerinizi kolaylaştırmak için özel formlar oluşturun, e-imzalar toplayın ve akıllı QR kodlarından yararlanın. Her aracı müşterilerinizin ihtiyaçlarını karşılayacak şekilde özelleştirin ve kullanıcı deneyimini geliştirin.",
    "gnc_get_new_cus": "Yeni Müşteriler Edinin",
    "gnc_get_customers": "Sosyal Medyadan - Hızlı ve Kolay!",
    "gnc_social_media_power": "Yeni müşteriler çekmek ve satışları doğrudan işletmenize yönlendirmek için sosyal platformların gücünü kullanın. Maksimum kontrol ve gelir için üçüncü taraf teslimat siparişlerini doğrudan mağazanıza veya çevrimiçi sipariş platformunuza yönlendirin.",
    "wifi_connect_guests": "Misafirleri şu şekilde bağlayın:",
    "wifi_smart": "Akıllı Wi-Fi",
    "wifi_connect_grow": "& İçgörülerinizi Geliştirin",
    "wifi_smart_wifi_desc": "Pazarlama çabalarınızı ve etkileşiminizi artırmak için değerli müşteri verileri toplarken, markalı bir açılış ekranı aracılığıyla müşterilerinize sorunsuz bir Wi-Fi bağlantısı sağlayın.",
    "wifi_create_splash_page": "Misafir Wifi Açılış Sayfası Oluştur",
    "instant_menu_create": "Senin yarat",
    "instant_menu_text": "Anlık menü",
    "instant_menu_wm": "dakikalar içinde",
    "instant_menu_desc": "Gerçek zamanlı güncellemeler, kolay özelleştirme ve gelişmiş etkileşim sağlayarak müşteri deneyimlerini geliştirir, tekliflerinizi sergilemenizi ve içeriğinizi güncel tutmanızı kolaylaştırır.",
    "instant_menu_create_digital_menu": "Dijital Menü Oluşturun",
    "loyalty_program_rewards": "Ödüller Kazanın",
    "loyalty_program_on_visits": "her ziyarette",
    "loyalty_program_create": "Sadakat Programı Oluşturun",
    "reputation_create": "İtibar Yarat",
    "reputation_boost": "Güçlendirin",
    "reputation_text": "İtibar",
    "reputation_reviews": "Daha Fazla Yorumla!",
    "reputation_desc": "Hizmetinizi ve itibarınızı geliştirmek için müşterilerinizden değerli geri bildirimler toplayın. Deneyiminizi paylaşın ve büyümemize yardımcı olun—yorumunuz önemlidir!",
    "dashboard": "Gösterge Paneli",
    "blast_campaign": "Patlama Kampanyası",
    "blast_campaigns": "Patlama Kampanyaları",
    "blast_campaign_sub_title": "Birkaç tıklamayla tüm müşterilerinize ulaşmak için patlama kampanyaları başlatın",
    "auto_responders": "Otomatik Yanıtlayıcılar",
    "auto_responder": "Otomatik Yanıtlayıcı",
    "auto_responder_sub_title": "Müşterileriniz için kullanımı kolay etkinlik tabanlı promosyonlar",
    "smart_qr_form": "Akıllı QR \/ Form",
    "get_new_customer": "Yeni Müşteri Edinin",
    "marketing": "Pazarlama",
    "clients": "Müşteriler",
    "group_data": "Grup Verileri",
    "clients_data": "Müşteri Verileri",
    "account": "Hesap",
    "user_acc_info": "Kullanıcı Hesap Bilgileri",
    "current_plan": "Güncel Plan",
    "payments": "Ödemeler",
    "location": "Konum",
    "location_list": "Konum Listesi",
    "users": "Kullanıcılar",
    "users_sub_title": "Tüm kullanıcıların listesi ve ayrıntıları",
    "online_profile": "Çevrimiçi Profil",
    "change_password": "Şifre değiştir",
    "menu": "Menü",
    "reseller_dashboard": "Bayi Panosu",
    "companies": "Şirketler",
    "plans_bought": "Planlar Satın Alındı",
    "agreement_t_c": "Anlaşma Şartları ve Koşulları",
    "wifi_dashboard": "Wifi Kontrol Paneli",
    "hot_spot": "Erişim noktası",
    "splash_pages": "Açılış Sayfaları",
    "get_more_reviews": "Daha Fazla Yorum Alın",
    "office_supplies": "Ofis Malzemeleri",
    "reservation": "Rezervasyon",
    "floor_table": "Zemin ve Masa",
    "guest_smart_wifi": "Misafir Akıllı WiFi",
    "digital_signage": "Dijital Tabela",
    "schedule_down": "Programlanmış",
    "business_settings": "İş Ayarları",
    "sub_user_login": "Alt Kullanıcı Girişi",
    "help_center": "Yardım Merkezi",
    "switch_to_hub": "Hub'a geçin",
    "meeting_records": "Toplantı Kayıtları",
    "logout": "Çıkış Yap",
    "no_access_text": "Şu anda erişiminiz yok. Lütfen yöneticinizle iletişime geçin.",
    "menu_item": "MENÜ ÖĞESİ",
    "menu_capital": "MENÜ",
    "good_day": "İyi günler",
    "no_permission_text": "Bu eylemi gerçekleştirme yetkiniz yok!",
    "enter_valid_pin": "Geçerli pin kodunu girin",
    "add_reservation": "Rezervasyon Ekle",
    "edit_reservation": "Rezervasyonu Düzenle",
    "per_req": "Kişi sayısı gereklidir",
    "req_block_time": "Blok Zamanı gereklidir",
    "pending_status": "Askıda olması",
    "booked_status": "Rezervasyon yapıldı",
    "completed_status": "Tamamlanmış",
    "cancelled_status": "İptal edildi",
    "reservation_slot": "Rezervasyon Yuvası",
    "block_time": "Blok Zamanı",
    "slot_time": "Rezervasyon Aralığı Saati:  ",
    "template_name_req": "Şablon adı gerekli",
    "template_type_req": "Şablon türü gerekli",
    "sms_template_req": "SMS şablonu gerekli",
    "group_req": "Grup gereklidir",
    "valid_date_time": "Geçerli tarih ve saat girin",
    "valid_group_name": "Geçerli grup adı girin",
    "reservation_mark_completed": "Bu rezervasyon tamamlanmış olarak işaretlenecek.",
    "customer_name": "Müşteri Adı",
    "date_time": "Tarih Saat",
    "table_no": "Tablo No",
    "floor": "Zemin",
    "customer_name_placeholder": "müşteri_adı",
    "date_time_placeholder": "tarih_saat",
    "table_no_placeholder": "tablo_no",
    "floor_placeholder": "zemin",
    "select_floor": "Kat Seçin",
    "select_table": "Tabloyu Seçin",
    "customer_name_macro": "%% müşteri_adı %%",
    "date_time_macro": "%% tarih_saat %%",
    "table_no_macro": "%% tablo_no %%",
    "floor_macro": "%% zemin %%",
    "template_name": "Şablon Adı",
    "login_with_4_digit_pin": "4 Haneli Pininizle Giriş Yapın",
    "login_now_to_access_latest_insights": "En son haberlere erişmek için hemen giriş yapın",
    "mkt_BS_Title": "SMS patlatma",
    "step_1": "Mesajı Özelleştir",
    "step_2": "Hedef Kitleyi Seçin",
    "step_3": "Program Mesajı",
    "step_4": "Mesaj Gönder",
    "step_5": "Mesajı Yönet",
    "bs_tab_title": "Patlama Kampanyası Özeti",
    "default_message": "Hey %% firstname %%, Brunch'ta özel indirim! Bugün bizimle yemek yiyin ve hesabınızda özel %10 indirim kazanın!",
    "bs_Title": "Bir Kampanya Türü Seçin",
    "sms_title": "SMS\/MMS",
    "reach_out_to_customer": "Müşterilerinize metin ve multimedya mesajları aracılığıyla ulaşın.",
    "wp_text": "WhatsApp",
    "connect_with_wp": "Müşterilerinizle WhatsApp kullanarak bağlantı kurun 💬",
    "send_discount_offers": "İndirim tekliflerini SMS ile gönderin 📲",
    "send_coupons_via_sms": "Müşterilerinize SMS yoluyla kupon gönderin 🎟️",
    "env_Cap": "Müşterilerinizi cezbetmek için özel indirimler ve promosyonlar sunun",
    "cmt_Caption": "Müşterilere metin ve multimedya mesajları aracılığıyla ulaşın",
    "wp_caption": "Sorunsuz ve doğrudan iletişim için WhatsApp'ı kullanarak müşterilerinizle bağlantı kurun",
    "msg_Prev_Title": "Mesaj Önizlemesi",
    "cou_Prev_Title": "Kupon Önizlemesi",
    "blast_SMS": "SMS\/MMS Kampanyası",
    "bs_P_Msg": "Sırada",
    "bs_S_Msg_C": "Arızalı",
    "bs_F_Msg_Count": "Teslim edilmiş",
    "previous": "Öncesi",
    "next_ST": "Sonraki",
    "next_S": "Sonraki Adım",
    "req_Temp_Name_Title": "Şablon adı gerekli",
    "req_Template_Tx_Title": "Şablon metni gerekli",
    "add_Temp_Title": "Şablon Ekle",
    "link_Desc": "Müşterilerinizin Facebook, Google veya kendi web siteniz üzerinden sizden rezervasyon yapabilmeleri için seçtiğiniz bir bağlantıyı ekleyin.",
    "audience_Title": "Kitle",
    "client_G1_Title": "Yeni Müşteriler",
    "client_G2_Title": "Son Müşteriler",
    "client_G3_Title": "Sadık Müşteriler",
    "client_G4_Title": "Eski Müşteriler",
    "client_G5_Title": "Bağlantı Tarihine Göre Müşteriler",
    "on_Which_Day": "Hangi Gün",
    "on_Day": "Gün İçinde",
    "immediate_Desc": "Mesajınız hemen gönderilecektir, başlamak için lütfen İleri'ye tıklayın.",
    "never_Desc": "Bu kampanya, siz manuel olarak sonlandırana kadar sonsuza kadar devam edecek şekilde planlanmıştır.",
    "end_Desc": "Kampanya buradan seçeceğiniz tarihte sona erecektir.",
    "success_Message": "Kampanya başarıyla kaydedildi!",
    "send_Success_Message": "Kampanya başarıyla gönderildi!",
    "msg_Name_Field_Title": "Kampanya Başlığı",
    "valid_campaign_title": "Geçerli Kampanya başlığını girin",
    "msg_Type_Title": "Mesaj Türü",
    "sms_Desc": "SMS başına 1 Kredi",
    "mms_Desc": "MMS başına 3 Kredi",
    "client_GTO_1": "Gün",
    "client_GTO_2": "Saat",
    "minutes": "Dakikalar",
    "client_GTO_4": "Hafta",
    "time_Option_1": "Gün",
    "time_Option_2": "Saat",
    "c_Grp_Day_1": "90 Gün",
    "c_Grp_Day_2": "60 Gün",
    "c_Grp_Day_3": "30 Gün",
    "first": "Birinci",
    "second_T": "Saniye",
    "third_T": "Üçüncü",
    "fourth_T": "Dördüncü",
    "fifth_T": "Beşinci",
    "delete_BS_T": "Bu kampanya kalıcı olarak silinecektir.",
    "delete_MBS_T": "Bu kampanyalar kalıcı olarak silinecektir.",
    "cFT_1": "Telefon",
    "cFT_2": "İletişim Adı",
    "cFT_3": "İletişim Soyadı",
    "cFT_4": "İletişim E-postası",
    "cFT_5": "Not",
    "cFT_6": "Doğum günü",
    "cFT_7": "Firma Adı",
    "cF_Meta_Tag_1": "%% telefon %%",
    "cF_Meta_Tag_2": "%% ilk adı %%",
    "cF_Meta_Tag_3": "%% soy isim %%",
    "cF_Meta_Tag_4": "%% e-posta %%",
    "cF_Meta_Tag_5": "%% Not %%",
    "cF_Meta_Tag_6": "%% doğum günü %%",
    "cF_Meta_Tag_7": "%% Firma Adı %%",
    "cF_Name_1": "telefon",
    "cF_Name_2": "ilk adı",
    "cF_Name_3": "soy isim",
    "cF_Name_4": "e-posta",
    "cF_Name_5": "Not",
    "cF_Name_6": "doğum günü",
    "cF_Name_7": "Firma Adı",
    "all_clients": "Tüm Müşteriler",
    "aud_Type_2": "Müşteri Grupları",
    "aud_Type_3": "Müşteri Grupları",
    "aud_Type_T1": "Tüm Müşteriler",
    "aud_Type_T2": "Müşteri Grupları",
    "aud_Type_T3": "Müşteri Grupları",
    "new_clients": "Yeni Müşteriler",
    "client_G2": "Son Müşteriler",
    "client_G3": "Sadık Müşteriler",
    "client_G4": "Eski Müşteriler",
    "client_G5": "Bağlantı Tarihine Göre Müşteriler",
    "never_text": "Asla",
    "ending_on": "Bitiş tarihi",
    "send_T2": "Planlanmış",
    "send_Type_3": "Tekrarlayan",
    "daily": "Günlük",
    "weekly_T": "Haftalık",
    "monthly_T": "Aylık",
    "yearly_T": "Yıllık",
    "each": "Her biri",
    "on_the": "Üzerinde",
    "monday": "Pazartesi",
    "exclusive_offer": "Size özel teklif",
    "redeem_now": "Şimdi Kullan",
    "redeem_with_cashier": "Lütfen kasiyerle ödeme yapın",
    "lg_Day_2": "Salı",
    "lg_Day_3": "Çarşamba",
    "lg_Day_4": "Perşembe",
    "lg_Day_5": "Cuma",
    "lg_Day_6": "Cumartesi",
    "lg_Day_7": "Pazar",
    "msg_Pre_T": "Dominolar",
    "cA_Edit_T1": "Tüm",
    "cA_Edit_T2": "Dahil",
    "cA_Edit_T3": "Hariç tutuldu",
    "SMS": "SMS",
    "MMS": "MMS",
    "usd_T": "Amerikan Doları",
    "cad_T": "CAD",
    "msg": "Mesaj",
    "which_Day": "Hangi Günler",
    "end_Date_Tx": "Bitiş Tarihi",
    "sDate_Err": "Başlangıç tarihi bugünden büyük veya ona eşit olmalıdır",
    "eDate_Err": "Bitiş tarihi başlangıç tarihinden sonra olmalıdır",
    "start_Date_Req": "Başlangıç tarihi gereklidir",
    "end_Date_Req": "Bitiş tarihi gereklidir",
    "time_req": "Zaman gerekli",
    "client_GT1": "Son abone olan müşteriler",
    "client_GT2": "Son bağlantısı olan müşteriler",
    "client_GT3": "En azından müşteriler",
    "client_GT4": "sonuncusuyla",
    "client_GT5": "son olarak geri dönmeyen",
    "dummy_Phone_No": "+91987-654-3210",
    "test_T": "Test",
    "dummy_Birth_Date": "01-01-2001",
    "image_Req": "Medya veya URL gerekli",
    "time_Title": "Zaman",
    "start_date": "Başlangıç Tarihi",
    "end_date": "Bitiş Tarihi",
    "auto_Send_T": "Müşterilere otomatik olarak gönder",
    "add_Media": "Medya Ekle",
    "in_Title": "İçinde",
    "c_Temp_T": "Kampanya Şablonu",
    "temp_NT": "Şablon Adı",
    "type_T": "Tip",
    "select_template_type": "Şablon türünü seçin",
    "sel_Temp_T": "Şablon Türünü Seçin",
    "sms_temp_t": "SMS Şablonu",
    "temp_T": "Şablon Metni",
    "default_Msg": "Hey %% firstname %%, Brunch'ta özel indirim! Bugün bizimle yemek yiyin ve hesabınızda özel %10 indirim kazanın!",
    "refill_T": "Yeniden Doldurma",
    "avl_Credit": "Mevcut Krediler",
    "req_Credit": "Toplam Gerekli Krediler",
    "rem_Credit": "Kalan Kredi",
    "refill_Credit": "Yeniden Doldurulacak Krediler",
    "clients_Req": "En az bir müşteri seçin",
    "subclient_Req": "Seçilen müşteriler abone değildir.",
    "refill_Desc": "Lütfen gerekli kredileri doldurun",
    "url_Regex": "^((http|https):\/\/).....+$",
    "sd_Invalid": "Başlangıç tarihi geçersiz",
    "ed_Invalid": "Bitiş tarihi geçersiz",
    "img_Url_Invalid": "Geçersiz resim URL'si",
    "time_Invalid": "Zaman geçersiz",
    "time_And_Date_Invalid": "Geçerli bir tarih ve saat girin",
    "time_Invalid_Bfr": "Mevcut zamandan en az 5 dakika sonraki bir zamanı seçin",
    "sod_Req": "Gönderim günü gereklidir",
    "add_Card": "Yeni Bir Kart Ekle",
    "sm_D_Rep_Title": "SMS\/MMS İletim Raporu",
    "send_Now_T": "Şimdi Gönder",
    "schd_Now_T": "SMS zamanlaması",
    "test_SMS_T": "Test SMS'i gönder",
    "save_AD_T": "Taslak Olarak Kaydet",
    "back_TE_T": "Düzenlemeye Geri Dön",
    "reset_tex": "Sıfırla",
    "update_tex": "Güncelleme",
    "dum_msg1": "Müşteri adını görüntülemek için şablonda %% customer_name %% kullanın",
    "dum_msg2": "Rezervasyon saatini görüntülemek için şablonda %% date_time %% kullanın",
    "dum_msg3": "Tablo numarasını görüntülemek için şablonda %% table_no %% kullanın",
    "embedded_link": "Gömülü Bağlantı",
    "img_title": "Resim",
    "img_input_text": "Metin:",
    "img_join_text": " listemize katılmak için",
    "img_copy_text": "Kopyala",
    "img_not_found": "Oluşturulan resim bulunamadı!!",
    "or_text": "VEYA",
    "web_signup_image_content": "Bu formu göndererek ve metin yoluyla kaydolarak, pazarlama metin mesajları (promosyon kodları ve hatırlatıcılar gibi) almayı kabul etmiş olursunuz. Mesaj ve veri ücretleri uygulanabilir. Mesaj sıklığı değişir. İstediğiniz zaman STOP yanıtını vererek aboneliğinizi iptal edebilirsiniz.",
    "img_download_success": "Resim başarıyla indirildi",
    "embedded_link_copied": "Gömülü bağlantı başarıyla kopyalandı",
    "media_url_required": "Medya veya URL gerekli",
    "invalid_image_url": "Geçersiz resim url'si",
    "invalid_file": "Dosya geçersiz",
    "image_error_1mb": "Lütfen 1 MB'den küçük bir resim ekleyin",
    "image_error_2_5mb": "Lütfen 2,5 MB'den küçük bir resim ekleyin",
    "image_error_3mb": "Lütfen 3 MB'den küçük bir resim ekleyin",
    "change_title": "Değiştirmek",
    "drop_file_text": "Dosyayı buraya bırakın veya yüklemek için tıklayın",
    "apply_text": "Uygula",
    "search_here": "Burada Ara",
    "update_status": "Güncelleme Durumu",
    "reservation_deleted_permanently": "Bu rezervasyon kalıcı olarak silinecektir.",
    "table_text": "Tablolar",
    "add_table_button": "Yeni Tablo Ekle",
    "add_table_text": "Tablo Ekle",
    "edit_table_text": "Tabloyu Düzenle",
    "table_delete_text": "Bu tablo kalıcı olarak silinecektir.",
    "multiple_table_delete_text": "Bu tablolar kalıcı olarak silinecektir.",
    "table_error_name": "Lütfen Tablo Adını Giriniz",
    "table_error_invalid_name": "Lütfen geçerli bir tablo adı girin",
    "table_error_floor": "Lütfen Kat Seçiniz",
    "table_error_select": "Lütfen Tabloyu Seçin",
    "table_capacity_text": "Tablo Kapasitesi",
    "capacity_text": "Kapasite",
    "table_occasion_text": "Herhangi Bir Durum İçin Masanızı Bulun",
    "table_name_text": "Tablo Adı",
    "table_capacity_error": "Masa kapasitesi gereklidir",
    "cancel_text": "İptal etmek",
    "submit_text": "Göndermek",
    "select_valid_date": "Geçerli bir tarih seçin",
    "select_valid_time": "Geçerli bir zaman seçin",
    "valid_contact_number": "Geçerli bir iletişim numarası girin",
    "date_req": "Tarih gerekli",
    "date_error_later_than_today": "Tarih bugünün tarihinden sonra veya ona eşit olmalıdır",
    "time_error_later_than_5_minutes": "Mevcut saatten en az 5 dakika sonrasını seçin",
    "number_person_req": "Kişi sayısı gereklidir",
    "contact_no_req": "İletişim Numarası gereklidir",
    "contact_no": "İletişim Numarası",
    "select_time": "Zaman Seçin",
    "for_how_many_person": "Kaç kişilik?",
    "f_name": "İlk adı",
    "l_name": "Soy isim",
    "business_name": "İşletme Adı",
    "business_no": "İşletme Numarası",
    "no_for_sign_in": "Bu numara oturum açmak için kullanılır",
    "business_email": "İş E-postası",
    "notes_tex": "Notlar",
    "floor_deleted_permanently": "Bu kat kalıcı olarak silinecektir.",
    "add_floor": "Kat Ekle",
    "edit_floor": "Kat Düzenle",
    "name_text": "İsim",
    "mobile_no": "Cep Telefonu No.",
    "person_text": "Kişi",
    "date_and_time": "Tarih ve Saat",
    "actions_text": "Eylemler",
    "extra_text": "Ekstra",
    "floor_name_req": "Kat Adı gereklidir",
    "floor_name": "Kat Adı",
    "status_text": "Durum",
    "category_status_req": "Kategori Durumu gereklidir",
    "table_deleted_permanently": "Bu tablo kalıcı olarak silinecektir.",
    "tables_deleted_permanently": "Bu tablolar kalıcı olarak silinecektir.",
    "back_to_home": "Ana Sayfaya Dön",
    "link_copied_text": "Bağlantı panoya kopyalandı",
    "cust_dash_head_to_title": "ile",
    "cust_dash_head_location_title": "Konum",
    "select_location_title": "Konum Seçin",
    "all_locations_label": "tüm_konumlar",
    "rep_customer_feedback_analytics": "😊 Gerçek zamanlı analizlerle müşteri geri bildirimlerinizi takip edin",
    "rep_customer_txt": "Müşteriler",
    "rep_delete_title": "Bu itibar kalıcı olarak silinecektir.",
    "rep_qr_def_txt": "QR kodunu tarayın ve deneyiminizle ilgili değerli geri bildirimlerinizi bizimle paylaşın.",
    "delete_title": "Silmek",
    "user_list_title": "Müşteri Listesi",
    "os_nfc_txt": "NFC Kartları",
    "os_sign_holder_txt": "Tabela Tutucu Standı",
    "os_store_Sign_holder_txt": "Mağaza Tabela Tutucuları",
    "StaT": "İstatistikler",
    "AllgT": "Tüm Gruplar",
    "FavT": "Favori",
    "MostActT": "En Aktif",
    "grT": "Grup Adı",
    "keywT": "Anahtar kelimeler",
    "exSubT": "Mevcut abonelere mesaj",
    "ArchT": "Arşivlendi",
    "gNotiSms": "Her yeni iletişimde bana SMS yoluyla bildirim gönder",
    "gNotiEmail": "Her yeni iletişim hakkında bana e-posta yoluyla bildirim gönder",
    "reqGName": "Grup adı gereklidir",
    "validGN": "Geçerli grup adı girin",
    "valid_phone_no": "Geçerli Telefon Numarası girin",
    "phone_no_req": "Telefon Numarası gereklidir",
    "required_message_text": "Mesaj gereklidir",
    "required_discount_text": "İndirim gerekli",
    "less_than_discount": "İndirim tutarı 5000'den az olmalıdır",
    "discount_percentage_invalid": "İndirim yüzdesi 100'den az veya ona eşit olmalıdır",
    "discount_type_req": "İndirim türü gereklidir",
    "discount_text_req": "Kupon metni gereklidir",
    "reqContactNo": "İletişim Numarası gereklidir",
    "reqMsgNT": "Kampanya başlığı gereklidir",
    "reqLinkT": "Bağlantı gerekli",
    "delMGT": "Bu gruplar kalıcı olarak silinecektir.",
    "defMemMsg": "Mesajınızı buraya yazın",
    "add_cust_to_grp_title": "Müşteriyi Gruba Ekle",
    "group_title": "Gruplar",
    "group_create_title": "Grup Oluştur",
    "group_name_txt": "Grup Adı",
    "group_table_keyword_title": "Anahtar kelime",
    "actions_title": "Eylemler",
    "clients_title": "Müşteriler",
    "send_title": "Göndermek",
    "qr_title": "Soru-Cevap",
    "delete_group_txt": "Bu grup kalıcı olarak silinecektir.",
    "delete_groups_txt": "Bu gruplar kalıcı olarak silinecektir.",
    "delete_client_title": "Bu istemci kalıcı olarak silinecektir.",
    "delete_clients_title": "Bu istemci kalıcı olarak silinecektir.",
    "delete_multiple_title": "Çoklu Silme",
    "wel_sms_mms": "Hoş geldiniz SMS\/MMS'i gönderilsin mi?",
    "key_words_title": "Anahtar kelimeler",
    "srch_plch_txt": "Burada Ara",
    "req_location_id_title": "Konum Gereklidir",
    "create_text": "Yaratmak",
    "view_text": "Görüş",
    "immediately": "Hemen",
    "business_name_req": "İşletme adı gereklidir",
    "business_no_req": "İşletme numarası gereklidir",
    "valid_business_name": "Geçerli bir işletme adı girin",
    "valid_business_no": "Geçerli işletme numarası girin",
    "address_req": "Adres gerekli",
    "valid_address": "Lütfen geçerli bir adres seçin",
    "time_zone_req": "Saat dilimi gerekli",
    "image_req": "Lütfen 2,5 MB'den küçük bir resim ekleyin",
    "valid_file": "Dosya geçersiz.",
    "logo": "Logo",
    "time_zone": "Zaman Dilimi",
    "save": "Kaydetmek",
    "account_type_req": "Hesap türü gereklidir",
    "gst_no_req": "GST numarası gereklidir",
    "gst_no_valid": "Geçerli GST numarasını girin",
    "set_up_payments": "Ödemeleri ayarlayın",
    "billing_details": "Fatura bilgilerinizi girin",
    "billing_details_desc": "Fatura bilgileriniz menuonline üzerinden aylık faturanızda gösterilecektir.",
    "account_type": "Hesap Türü",
    "select_account_type": "Hesap türünü seçin",
    "gst_no": "GST Numarası",
    "transaction_details": "İşlem Ayrıntıları",
    "payment_method": "Ödeme yöntemi",
    "billing_period": "Fatura dönemi",
    "paid_by": "Tarafından ödendi",
    "download": "İndirmek",
    "pay_now": "Şimdi Öde",
    "pull_down_refresh": "Yenilemek için aşağı çekin",
    "release_refresh": "Yenilemek için serbest bırakın",
    "billing_details_text": "Fatura Detayları",
    "payment_methods": "Ödeme Yöntemleri",
    "invoice": "Fatura",
    "invoice_to": "Fatura Adresi :",
    "issue_date": "Düzenleme tarihi",
    "due_date": "Bitiş tarihi",
    "amount_due": "Ödenecek Tutar",
    "charges": "Ücretler",
    "sub_total_capitalize": "Alt Toplam",
    "hst": "HST",
    "grand_total": "Genel Toplam",
    "invoice_generated_on": "Fatura Oluşturuldu",
    "contact_us": "Bize Ulaşın",
    "invoice_issue": "Faturanızı incelediyseniz ve hala sorularınız varsa",
    "call": "Arama",
    "send_sms": "SMS gönder",
    "payment_success": "Ödeme başarılı",
    "edit_credit_card": "Kredi Kartını Düzenle",
    "add_credit_card": "Kredi Kartı Ekle",
    "modify_card_info": "Kart Bilgilerinizi Değiştirin",
    "enter_card_info": "Kart Bilgilerinizi Girin",
    "account_no_req": "Hesap numarası gereklidir",
    "card_name_req": "Kart üzerindeki isim zorunludur",
    "expiry_date_req": "Son kullanma tarihi gereklidir",
    "valid_expiry_date": "Geçerli son kullanma tarihini girin",
    "valid_cvv": "Geçerli cvv girin",
    "cvv_req": "CVV gereklidir",
    "card_no": "Kart Numarası",
    "name_of_card": "Kart Üzerindeki İsim",
    "expiry": "Son kullanma tarihi",
    "mm_text": "AA\/YY",
    "cvv": "Özgeçmiş",
    "set_as_default": "Varsayılan olarak ayarla",
    "add_new_card": "Yeni Kart Ekle",
    "all_credit_card": "Tüm Kredi Kartlarınız",
    "fastest_checkout": "Güvenli ve güvenilir platformla en hızlı ödeme, büyük bir işletme grubu tarafından güveniliyor",
    "coupon_button_req": "Kupon butonu metni gereklidir",
    "max19_characters_allowed": "En fazla 19 karaktere izin verilir",
    "fine_print_text_req": "Küçük puntolu metin gereklidir",
    "clients_req": "En az bir müşteri seçin",
    "client_groups": "Müşteri Grupları",
    "day_text": "Gün",
    "valid_days": "Gün değeri çok büyük geçerli günleri girin",
    "hours_text": "Saat",
    "enter_valid_hours": "Saat değeri çok büyük geçerli saat girin",
    "enter_valid_min": "Dakika değeri çok büyük geçerli dakikaları girin",
    "clients_with_connection_required": "Son bağlantıya sahip istemciler gereklidir",
    "connection_required": "Bağlantı gerekli",
    "connection_value_too_large": "Bağlantı değeri çok büyük geçerli bağlantı girin",
    "minutes_required": "Dakikalar gerekli",
    "clients_with_at_least_connection_required": "En azından bağlantısı olan istemciler gereklidir",
    "last_minutes_req": "Son dakikalar gerekli",
    "clients_not_returned_required": "Son dakikalarda geri dönmeyenler gerekli",
    "not_return_in_last_minutes_invalid": "Son dakikalarda geri dönmemek için son dakikalardan daha az süre olması gerekiyor.",
    "customerGroups": "Müşteri Grupları",
    "select_customer_group": "Lütfen müşteri grubunu seçiniz",
    "location_required": "Konum gerekli",
    "start_date_required": "Başlangıç tarihi gereklidir",
    "start_date_invalid": "Başlangıç tarihi geçersiz",
    "start_date_invalid_error": "Başlangıç tarihi bugünden büyük veya ona eşit olmalıdır",
    "recurring": "Tekrarlayan",
    "end_date_required": "Bitiş tarihi gereklidir",
    "end_date_invalid": "Bitiş tarihi geçersiz",
    "end_date_invalid_error": "Bitiş tarihi başlangıç tarihinden sonra olmalıdır",
    "time_invalid": "Zaman geçersiz",
    "monthly_text": "Aylık",
    "send_day_req": "Gönderim günü gereklidir",
    "loyal_clients": "Sadık Müşteriler",
    "recent_clients": "Son Müşteriler",
    "lapsed_clients": "Eski Müşteriler",
    "clients_connection_date": "Bağlantı Tarihine Göre Müşteriler",
    "scheduled_text": "Planlanmış",
    "weekly": "Haftalık",
    "selected_clients_not_subscriber": "Seçili müşteriler abone değil.",
    "message_preview_title": "Mesaj Önizlemesi",
    "coupon_preview_title": "Kupon Önizlemesi",
    "form_text": "Biçim",
    "preview_text": "Önizleme",
    "next_text": "Sonraki",
    "send_test_SMS": "Test SMS'i gönder",
    "save_draft": "Taslak Olarak Kaydet",
    "back_to_edit": "Düzenlemeye Geri Dön",
    "select_payment_method": "Lütfen ödeme yöntemini seçin",
    "schedule_SMS": "SMS zamanlaması",
    "send_now": "Şimdi gönder",
    "get_more_ratings": "Daha Fazla Puan Alın",
    "overview": "Genel bakış",
    "reset_campaign": "Sıfırlama Kampanyası",
    "permissions": "İzinler",
    "location_name": "Konum adı",
    "phone_no": "Telefon Numarası",
    "location_email_address": "Konum E-posta Adresi",
    "located_business": "İşletmeniz nerede bulunuyor?",
    "business_logo": "İşletme logosu",
    "congratulations": "Tebrikler",
    "almost_done": "Neredeyse bitti",
    "publish": "Yayımla",
    "about_your_business": "İşletmeniz Hakkında",
    "add_your_location": "Konumunuzu Ekleyin",
    "publish_location": "Yayın Yeri",
    "location_name_req": "Konum adı gereklidir",
    "valid_location_name": "Geçerli bir konum adı girin",
    "business_logo_req": "İşletme logosu gereklidir",
    "please_accept_terms": "Lütfen şartlar ve koşulları kabul edin",
    "add_new_location": "Yeni Konum Ekle",
    "edit_location": "Konumu Düzenle",
    "add_location": "Konum Ekle",
    "existing_msg_subscriber_txt": "Mevcut abonelere mesaj",
    "msg_capitalize_txt": "Mesaj",
    "group_noti_sms": "Her yeni iletişimde bana SMS yoluyla bildirim gönder",
    "group_noti_email": "Her yeni iletişim hakkında bana e-posta yoluyla bildirim gönder",
    "group_member_msg": "Görünüşe göre sen de bizim grubumuzun bir parçasısın!!",
    "group_mend_msg": "Sonlandırmak için DURDURUN. Yardım için YARDIM. SMS ve veri ücretleri uygulanabilir",
    "Disclaimer_title": "Yasal Uyarı Metni: ",
    "group_def_msg": "Artık çevrimiçiyiz! Hizmet menümüze göz atın ve bir sonraki randevunuzu bugün ayırtın.",
    "required_msg_txt": "Mesaj gereklidir",
    "required_Key_txt": "Anahtar kelimeler gereklidir",
    "required_mem_msg": "Abone mesajı gereklidir",
    "client_list_title": "Müşteri Listesi",
    "add_contact_txt": "Kişi Ekle",
    "delete_all_clients_txt": "Tüm İstemcileri Sil",
    "delete_all_clients_msg": "Tüm istemcileri silmek istediğinizden emin misiniz? Bunlar alınamaz.",
    "delete_all_txt": "Tümünü Sil",
    "timeline_title": "Zaman çizelgesi",
    "unsubscribe_title": "Abonelikten çık",
    "subscribe_title": "Abone",
    "unsubscribe_confirm_msg": "Bu müşteriyi aboneliğinden çıkan olarak işaretlemek ister misiniz?",
    "subscribe_confirm_msg": "Bu müşteriyi abone olarak işaretlemek ister misiniz?",
    "no_title": "HAYIR",
    "yes_title": "Evet",
    "client_name_title": "Müşteri Adı",
    "source_title": "Kaynak",
    "contact_file_Req": "Kişiler dosyası gerekli",
    "req_title": "Gerekli",
    "opt_in_req": "İthal edilen kişilerin %100 katılımlı olması gerekir",
    "image_invalid_error": "Dosya geçersiz.",
    "import_contacts_msg": "İthalat Kişileri",
    "csv_upload_title": "Yüklenecek CSV Kişiler dosyası *",
    "csv_file_desc": "Lütfen yalnızca csv dosyasını yükleyin - csv dosyası sütunları - Ad, Soyad, E-posta kimliği, Cep telefonu numarası, Cinsiyet, DOB(AA\/GG\/YYYY) - Cep telefonu numarasında boşluk, tire veya parantez yok",
    "to_download_title": "Demo dosyasını indirmek için buraya tıklayın",
    "contains_header_title": "Evet, bu dosya başlıkları içeriyor",
    "opt_in_100_txt": "Evet, bu içe aktarılan kişiler %100 katılıma açıktır",
    "DisT": "Yasal Uyarı Metni: ",
    "gMendMsg": "Sonlandırmak için DURDURUN. Yardım için YARDIM. SMS ve veri ücretleri uygulanabilir",
    "reqKeyT": "Anahtar kelimeler gereklidir",
    "reqMemMsg": "Abone mesajı gereklidir",
    "reqMsgT": "Mesaj gereklidir",
    "gMemberMsg": "Görünüşe göre sen de bizim grubumuzun bir parçasısın!!",
    "gdefMsg": "Artık çevrimiçiyiz! Hizmet menümüze göz atın ve bir sonraki randevunuzu bugün ayırtın.",
    "next_title": "Sonraki",
    "male_title": "Erkek",
    "male_val": "erkek",
    "female_title": "Dişi",
    "female_val": "dişi",
    "others_txt": "Diğerleri",
    "others_val": "diğerleri",
    "validBirthdate": "Geçerli doğum tarihi girin",
    "valid_phone_no_title": "Geçerli Telefon Numarası girin",
    "required_phone_no_title": "Telefon Numarası gereklidir",
    "add_new_client_txt": "Yeni bir müşteri ekle",
    "update_client_txt": "İstemciyi Güncelle",
    "phone_num_text": "Telefon Numarası",
    "dob_title": "Doğum tarihi",
    "select_gender_title": "Cinsiyet Seçin",
    "timelineTitle": "Zaman çizelgesi",
    "confirm_location": "Konumu Onayla",
    "feedback_title": "Geri bildirim",
    "rep_question_1": "İş yeri temiz miydi?",
    "rep_que_2": "Yemeğiniz güzel miydi?",
    "rep_que_3": "Hizmetimiz güler yüzlü müydü?",
    "rep_que_4": "Hizmetimiz hızlı mıydı?",
    "business_location_req_title": "İşletme konumu gereklidir",
    "valid_location_err_txt": "Lütfen geçerli bir adres seçin",
    "rep_management_title": "Daha Fazla Yorum Yönetimi Alın",
    "rep_about_desc": "İtibar, müşterilerinizden geri bildirim isteyerek Google derecelendirmenizi artırmanıza yardımcı olur",
    "preview_title": "Önizleme",
    "rep_preview_txt": "İtibar Önizlemesi",
    "back_title": "Geri",
    "fine_print_txt": "Lütfen kasiyerle ödeme yapın",
    "redeem_me_title": "Beni Kurtar",
    "rep_nxt_visit_txt": "Bir sonraki ziyaretinizde",
    "device_type": "Kullanılan Cihaz Türü",
    "connection_method": "Bağlantı Yöntemi",
    "peak_days": "Yoğun Günler",
    "peak_hours": "Yoğun Saatler",
    "guest_by_day": "Günlük Misafir",
    "guest_visit": "Misafir Ziyareti",
    "connection": "Bağlantı",
    "connection_duration": "Bağlantı Süresi",
    "guest_visit_1": "1 kez",
    "guest_visit_2": "2 kez",
    "guest_visit_3_5": "3-5 kez",
    "guest_visit_6_10": "6-10 kez",
    "guest_visit_11_25": "11-25 kez",
    "guest_visit_26_100": "26-100 kez",
    "guest_visit_100_plus": "100+ kez",
    "device_android_total": "Toplam Android",
    "device_android": "Android",
    "device_desktop": "Masaüstü",
    "device_ios": "İos",
    "device_ios_total": "Toplam İos",
    "device_desktop_total": "Toplam Masaüstü",
    "connection_duration_10": "<=10 Dakika",
    "connection_duration_20": "11-20 Dakika",
    "connection_duration_30": "21-30 Dakika",
    "connection_duration_40": "31-45 Dakika",
    "connection_duration_60": "46-60 Dakika",
    "connection_method_email": "E-posta",
    "connection_method_sms": "SMS",
    "connection_method_google": "Google",
    "connection_method_facebook": "Facebook",
    "age_category_1": "<18",
    "age_category_2": "18-24",
    "age_category_3": "25-34",
    "age_category_4": "35-44",
    "age_category_5": "45-54",
    "age_category_6": "55+",
    "all_guest_txt": "Tüm Misafirler",
    "new_Guest_txt": "Yeni Misafir",
    "connections_txt": "Bağlantılar",
    "hotspot": "Erişim noktası",
    "hotspot_list": "Hotspot Listesi",
    "add_new_hotspot": "Yeni Erişim Noktası Ekle",
    "hotspot_information": "Hotspot Bilgileri",
    "edit_details_button": "Ayrıntıları Düzenle",
    "wifi_info_message": "Bağlanın ve ücretsiz WiFi'nin keyfini çıkarın",
    "connection_message": "Harika, bağlandınız ",
    "connection_message_suffix": " Wifi",
    "wifi": "Wifi",
    "login_to_access": "Erişim sağlamak için giriş yapın",
    "verification_code": "Doğrulama Kodu",
    "verification_code_message": "Lütfen size gönderilen doğrulama kodunu yazın. ",
    "wifi_user_email": "adamo@gmail.com",
    "wifi_label": "Wifi",
    "your_name": "Bu arada adın ne?",
    "your_birthdate": "Doğum tarihinizi söyleyebilir misiniz?",
    "request_guest_wifi_name": "Lütfen Misafir Wifi Adını Girin",
    "request_device_key": "Lütfen Cihaz Anahtarını Girin",
    "request_maximum_internet_access_length": "Lütfen Maksimum İnternet Erişim Uzunluğunu Seçin",
    "mac_address": "MAC adresi",
    "device_port": "Aygıt Bağlantı Noktası",
    "hardware": "Donanım",
    "current_uptime": "Mevcut Çalışma Süresi",
    "nearby_devices": "Yakındaki Cihazlar",
    "firmware": "Donanım yazılımı",
    "who_are_you": "Sen kimsin?",
    "where_to_contact_you": "Sizinle nereden iletişime geçebiliriz?",
    "your_area_code": "Alan kodunuz nedir?",
    "connected": "Bağlı",
    "delete_hotspot_message": "Bu etkin nokta kalıcı olarak silinecek.",
    "delete_multiple_hotspots_message": "Bu etkin noktalar kalıcı olarak silinecektir.",
    "speed_error": "Hız en az 0,01 olmalıdır",
    "speed_max_error": "Sınırsız hız için lütfen 1024'e kadar bir değer girin veya desteklenen aralıkta daha düşük bir değer seçin",
    "device_ssid": "Aygıt SSID'si",
    "device_ssid_two": "Aygıt SSID İki",
    "device_ssid_two_wpa": "Aygıt SSID İki WPA",
    "device_key": "Cihaz Anahtarı",
    "select_location": "Konum Seçin",
    "select_maximum_internet_access_length": "Maksimum İnternet Erişim Uzunluğunu Seçin",
    "download_speed": "İndirme Hızı",
    "upload_speed": "Yükleme Hızı",
    "network_length_1": "15 Dakika",
    "network_length_2": "30 Dakika",
    "network_length_3": "45 Dakika",
    "network_length_4": "1 Saat",
    "network_length_5": "2 Saat",
    "network_length_6": "4 Saat",
    "network_length_7": "6 Saat",
    "network_length_8": "8 Saat",
    "network_length_9": "10 Saat",
    "network_length_10": "12 Saat",
    "employee_wifi_name": "Çalışan Wifi Adı",
    "employee_wifi_password": "Çalışan Wifi Şifresi",
    "guest_wifi_name": "Misafir Wifi Adı",
    "menu_other_products_txt": "Diğer Ürünler | Menü Çevrimiçi",
    "menu_home_text": "Ana Sayfa | Menü Çevrimiçi",
    "whatsapp_title": "Whatsapp",
    "select_campaign_type": "Bir Kampanya Türü Seçin",
    "select_readymade_templates": "Hazır şablonlardan birini seçin veya kendinizinkini yapın",
    "campaign_title_required": "Kampanya başlığı gereklidir",
    "type_here": "Buraya yazın...",
    "location_permission_req": "Konum izni gereklidir",
    "platform_permission_req": "Platform izni gereklidir",
    "profile_picture": "Profil resmi",
    "click_to_upload": "Yüklemek için tıklayın",
    "location_permission": "Konum İzni",
    "pin": "Pin",
    "platform_permission": "Platform İzni",
    "set_pin": "PIN Ayarla",
    "list_of_users": "Kullanıcı Listesi",
    "create_user": "Kullanıcı Oluştur",
    "terms_and_condition_req": "Şartlar ve Koşullar gereklidir",
    "terms_and_conditions": "Şartlar ve koşullar",
    "recommended": "TAVSİYE EDİLEN",
    "mo": "\/ay",
    "unsubscribe": "Abonelikten çık",
    "cancel_subscription": "Aboneliğinizi iptal etmek istediğinizden emin misiniz?",
    "upgrade": "Güncelleme",
    "plan_upgraded_successfully": "Plan başarıyla yükseltildi!",
    "menu_online": "Menüçevrimiçi",
    "support": "Destek",
    "wifiC": "WİFİ",
    "billing": "Faturalama",
    "please_upgrade": "Tüm özelliklerin keyfini çıkarmak için lütfen yükseltin",
    "you_have_subscribed": "Abone oldunuz ",
    "plan": "plan",
    "of_text": "ile ilgili",
    "days": "Günler",
    "remaining_days_until_plan": " Planınızın güncellenmesi gerekene kadar kalan gün sayısı",
    "manage_your_screens": "Ekranlarınızı yönetin",
    "screens_you_save": "Ekranlarınız var",
    "add_more_screens": "Daha fazla Ekran ekle",
    "addition_screen": "Ekleme Ekranı",
    "per_screen": "ekran başına $",
    "per_box": "kutu başına $",
    "shipping_changes": "Kargo Ücretleri",
    "upto4_boxes": "4 kutuya kadar ",
    "charge_now": "Şimdi ücretlendirileceksiniz",
    "updated_reoccuring": "Güncellenen tekrarlayanınız",
    "current_reoccuring": "Şu anda tekrar eden: ",
    "no_text": "HAYIR",
    "android_box": "Android Kutusu",
    "old_password_req": "Eski şifre gerekli",
    "new_password_req": "Yeni şifre gerekli",
    "confirm_password_req": "Şifreyi onaylamanız gerekiyor",
    "password_do_not_match": "Şifreler eşleşmiyor",
    "old_password": "eski Şifre",
    "new_password": "Yeni Şifre",
    "confirm_password": "Şifreyi Onayla",
    "copy_working_hours": "Bu çalışma saatlerini tüm günlere kopyala?",
    "yes_copy": "Evet, kopyala",
    "closed": "Kapalı",
    "opening_time": "Açılış Saati",
    "closing_time": "Kapanış Saati",
    "description": "Tanım",
    "file_exist": "dosya zaten mevcut, lütfen başka bir dosya seçin",
    "bussiness_images": "İş Görüntüleri",
    "display_info_on_market_place": "Aşağıdaki bilgilerin Marketplace'inizde görüntülenmesini ister misiniz?",
    "profile_ready": "Profiliniz hazır!",
    "client_book_with_online": "Müşteriler artık çevrimiçi olarak sizinle rezervasyon yapabilir. Başlamak için aşağıdaki bağlantıyı paylaşın.",
    "copy_link": "Bağlantıyı Kopyala",
    "see_your_profile": "Profilinizi görün",
    "ok_got_it": "Tamam, anladım",
    "preview_profile": "Profilinizin önizlemesini yapın ve nasıl göründüğüne bakın.",
    "opening_hours": "Açılış Saatleri",
    "display_data": "Verileri görüntüle",
    "manage_profile": "Profili Yönet",
    "phone_req": "Telefon gerekli",
    "market_place_img_req": "Pazar yeri görselleri gereklidir",
    "add_new_market_place": "Yeni MarketPlace Ekle",
    "edit_market_place": "MarketPlace'i Düzenle",
    "no_reviews_yet": "Henüz Yorum Yok",
    "good": "İyi",
    "average": "Ortalama",
    "add_marketPlace": "MarketPlace'i ekle",
    "all_title": "Tüm",
    "included_title": "Dahil",
    "excluded_title": "Hariç tutuldu",
    "clients_subscribed": "Son abone olan müşteriler",
    "clients_groups": "Müşteri Grupları",
    "customer_groups": "Müşteri Grupları",
    "audience_title": "Kitle",
    "client_gt5": "son olarak geri dönmeyen",
    "select_all": "Tümünü Seç",
    "modify": "Değiştir",
    "campaign_title": "Kampanya Başlığı",
    "msg_type": "Mesaj Türü",
    "enter_discount": "İndirimi girin",
    "discount_type": "İndirim Türü",
    "coupon_text": "Kupon Metni",
    "enter_coupon_text": "Kupon Metnini Girin",
    "coupon_button_text": "Kupon Düğmesi Metni",
    "enter_coupon_button_text": "Kupon Düğmesi Metnini Girin",
    "fine_prin": "Küçük Yazı",
    "enter_fine_prin": "Küçük puntolu metni girin",
    "campaign_dec": "Kampanya başlangıç saatinden 30 dakika öncesine kadar değişiklik yapabilirsiniz. SMS kampanyası için ücretlendirme kampanya başlangıç saatinden 30 dakika önce işleme alınacaktır.",
    "blast_text_message_dec": "Patlama metin mesajınız hazır. Ödeme yönteminizi seçin ve mesajlarınızı gönderin.",
    "payment_completed": " Ödeme şu tarihte tamamlanacaktır: ",
    "total_cost": "Toplam Maliyet",
    "close_title": "Kapalı",
    "friday": "Cuma",
    "saturday": "Cumartesi",
    "sunday": "Pazar",
    "thursday": "Perşembe",
    "tuesday": "Salı",
    "wednesday": "Çarşamba",
    "port_txt": "Liman",
    "today_title": "Bugün",
    "yesterday_title": "Dün",
    "last_30_days_txt": "Son 30 Gün",
    "this_month_txt": "Bu Ay",
    "last_month_txt": "Geçen ay",
    "valid_date_title": "Geçerli bir tarih seçin",
    "valid_business_name_txt": "Geçerli bir işletme adı girin",
    "req_bus_add_txt": "İş adresi gereklidir",
    "req_domain_name_txt": "Alan adı gereklidir",
    "basic_info_txt": "İşletmenizin Temel Bilgileri",
    "loyalty_qr_def_txt": "QR kodunu tarayın, sadakat kartımıza katılın ve ödüllerin tadını çıkarın.",
    "last_stamp_txt": "Son Pul",
    "collected_on_txt": "Toplandı",
    "stamp_details_txt": "Pul Detayları",
    "add_stamp_txt": "Damga Ekle",
    "choose_brand_color_txt": "Marka renginizi ve dilinizi seçin 🎨",
    "change_anytime_txt": "Bunu istediğiniz zaman değiştirebilirsiniz",
    "like_to_get_txt": "Almak ister misin?",
    "your_next_visit_txt": "Bir sonraki ziyaretinizde?",
    "time_to_time_get_offers": "Zaman zaman harika teklifler ve promosyonlar almak ister misiniz?",
    "respond_back_to_you": "Birinin size geri dönüş yapmasını ister misiniz?",
    "input_serve_better": "Görüşleriniz ekibimizin size daha iyi hizmet vermesine yardımcı olacak!",
    "do_it_txt": "Hadi yapalım",
    "take_15_s": "15 saniye sürer",
    "rate_on_google_desc": "Bizi Google'da puanlayabilir misiniz?",
    "may_be_next_time": "Belki başka zaman",
    "thank_you_txt": "Teşekkür ederim",
    "look_next_txt": "Bir sonraki ziyaretinizi sabırsızlıkla bekliyoruz!",
    "deleteUserTitle": "Bu kullanıcı kalıcı olarak silinecektir.",
    "deleteMUserTitle": "Bu kullanıcı kalıcı olarak silinecektir.",
    "change_pin": "Pin'i Değiştir",
    "area_code": "Alan kodunuz nedir? ",
    "add_menu": "Menü Ekle",
    "menu_name": "Menü Adı",
    "add_menu_placeholder": "Pizza, Burger, İçecekler vb.",
    "enable_data_Collection": "Veri Toplamayı Etkinleştir",
    "add_new_menu": "Yeni Menü Ekle",
    "rename_menu": "Menüyü Yeniden Adlandır",
    "preview": "Önizleme",
    "generate_qr": "Oluşturulan QR",
    "edit_menu": "Düzenleme Menüsü",
    "remove_menu": "Menüyü Kaldır",
    "menu_delete_msg": "Bu menü kalıcı olarak silinecek",
    "menus_delete_msg": "Bu menüler kalıcı olarak silinecektir",
    "view_menu_dialoage_msg": "Doğum gününüzde hediye almak ister misiniz?",
    "skip": "Atlamak",
    "cliam_your_gift": "Hediyenizi Talep Edin",
    "collect_form": "Formu Topla",
    "enter_first_name": "Adınızı Girin",
    "enter_last_name": "Soyadını Girin",
    "enter_email": "E-posta girin",
    "enter_dob": "Doğum Tarihini Girin",
    "enter_number": "Sayıyı Girin",
    "select_gender": "Cinsiyet Seçin",
    "congratulations_desc": "🎉 Tebrikler! Hediye talebiniz başarıyla iletildi. Ekibimiz kısa süre içinde sizinle iletişime geçecek. 🎉",
    "male_heading": "Erkek",
    "male_text": "erkek",
    "female_heading": "Dişi",
    "female_text": "dişi",
    "others_heading": "Diğerleri",
    "other_text": "diğerleri",
    "BirthD": "Doğum tarihi",
    "GenderT": "Cinsiyet",
    "EmailT": "E-posta",
    "dobT": "Doğum tarihi",
    "capitalize_menu": "Menü",
    "select_menu": "Menü seç",
    "manage_variant": "Varyantı Yönet",
    "add_products": "Ürün Ekle",
    "add_category": "Kategori Ekle",
    "category_delete": "Bu kategori kalıcı olarak silinecek",
    "variation_delete": "Bu değişiklik Ürün'den silinecektir.",
    "product_delete": "Bu ürün kalıcı olarak silinecektir.",
    "categories_delete": "Bu kategoriler kalıcı olarak silinecektir.",
    "products_delete": "Bu ürünler kalıcı olarak silinecektir.",
    "category": "KATEGORİ",
    "price": "FİYAT",
    "food_product_placeholder": "Pizza, Burger, İçecekler vb.",
    "active_title": "Aktif",
    "inActive_title": "Etkin değil",
    "status_capital": "DURUM",
    "cat_status_require": "Kategori Durumu gereklidir",
    "cat_name_require": "Kategori Adı gereklidir",
    "category_name": "Kategori Adı",
    "status": "Durum",
    "lgDay1": "Pazartesi",
    "lgDay2": "Salı",
    "lgDay3": "Çarşamba",
    "lgDay4": "Perşembe",
    "lgDay5": "Cuma",
    "lgDay6": "Cumartesi",
    "lgDay7": "Pazar",
    "is_closed_title": "Kapalı",
    "book_table_title": "Masa Rezervasyonu Yapın",
    "emailErrSub": "E-posta Konusu gereklidir",
    "email_subject": "E-posta Konusu",
    "contactUsfrmTitleEmail": "E-postaya cevap ver",
    "companyInfo": "Lorem Ipsum lorem hrig'dir, lorem ipsum fraets'tir.Lorem Ipsum lorem hrig'dir, lorem ipsum fraets'tir.Lorem Ipsum lorem hrig'dir, lorem ipsum fraets'tir.",
    "footerSTitle1": "Menuonline Hakkında",
    "footerSTitle1Lnk2": "Özellikler",
    "footerSTitle1Lnk3": "Blog",
    "footerSTitle2": "Yasal",
    "footerSTitle2Lnk1": "Şartlar ve koşullar",
    "footerSTitle2Lnk2": "Gizlilik Politikası",
    "footerSTitle3": "İşletmeler İçin",
    "footerSTitle3Lnk1": "Ortaklar İçin",
    "footerSTitle3Lnk2": "Fiyatlandırma",
    "footerSTitle3Lnk3": "Ortaklara Destek",
    "footerCopyRithgTxt": "  Menuonline veya bağlı kuruluşları",
    "homeTitle": "Ev",
    "contactTitle": "Bize Ulaşın",
    "aboutTitle": "Hakkımızda",
    "homeMenuTitle": "Menü",
    "bookTableTitle": "Masa Rezervasyonu Yapın",
    "vDateT": "Geçerli bir tarih seçin",
    "reqDateT": "Tarih gerekli",
    "dateGtErr": "Tarih bugünün tarihinden sonra veya ona eşit olmalıdır",
    "timeInvalid": "Zaman geçersiz",
    "reqTimeT": "Zaman gerekli",
    "timeInvalidBfr": "Mevcut saatten en az 5 dakika sonrasını seçin ",
    "PerReq": "Kişi sayısı gereklidir",
    "validcNoT": "Geçerli bir iletişim numarası girin",
    "reqCrT": "Para birimi gereklidir",
    "reqPrT": "Fiyat gerekli",
    "reCateT": "Kategori gereklidir",
    "reqVrT": "Varyant gerekli",
    "reqVrVldT": "Geçerli Varyantı Girin ",
    "validLNameT": "Geçerli bir soyadı girin",
    "sDateInValid": "Başlangıç tarihi geçersiz",
    "minmReq": "Minimum gereklidir",
    "resLblDate": "Tarih Seçin",
    "resLblTime": "Zaman Seçin",
    "perT": "Kaç Kişilik?",
    "resLblEmail": "Lütfen E-postanızı giriniz ?",
    "resLblNote": "Rezervasyon notu ekle",
    "imageError1MB": "Lütfen 1mb'den küçük bir resim ekleyin",
    "imageError": "Lütfen 2,5 MB'den küçük bir resim ekleyin",
    "imageError3Mb": "Lütfen 3mb'den küçük bir resim ekleyin",
    "imageInVldError": "Dosya geçersiz.",
    "addProMT": "Ürün Menüsü",
    "proT": "Ürün Adı",
    "reqProT": "Ürün Adı gereklidir",
    "proPT": "Ürün Fiyatı",
    "reservationSuccTitle": "Harika, Rezervasyonunuz Tamamlandı",
    "book": "Kitap",
    "custzName": "Özelleştirme Adı",
    "always": "Her zaman",
    "proImgReq": "Ürün görseli gereklidir",
    "selCustmT": "Lütfen Özelleştirmeyi seçin veya yenisini oluşturun",
    "visStR": "Görünürlük durumu gereklidir",
    "avlblScR": "Lütfen müsaitlik programını seçin",
    "addonPR": "Lütfen eklenti ürünleri seçin",
    "upsellPR": "Lütfen ek satış ürünlerini seçin",
    "markItemR": "Lütfen işaretli ürün ürünlerini seçin",
    "caloriesR": "Kalori gerekli",
    "allergensR": "Lütfen alerjen ürünleri seçin",
    "prepInstR": "Hazırlık talimatları gereklidir",
    "staffNR": "Personel notları gereklidir",
    "discountR": "İndirim gerekli",
    "validDiscE": "Geçerli indirimi girin",
    "disday": "Gün geçtikçe",
    "plSelDayT": "Lütfen gün seçin",
    "sTimeReq": "Başlangıç saati gereklidir",
    "sTimeInValid": "Başlangıç saati geçersiz",
    "eTimeReq": "Bitiş zamanı gereklidir",
    "eTimeInValid": "Bitiş zamanı geçersiz",
    "sDateReq": "Tarihten itibaren gereklidir",
    "eDateReq": "Bugüne kadar gerekli",
    "eDateInValid": "Tarih geçersiz",
    "disdate": "Tarihten itibaren",
    "disdate1": "Bugüne kadar",
    "disdate2": "Tarihten itibaren",
    "currT": "Para birimi",
    "iconR": "Lütfen öğeyi işaretle simgesini seçin",
    "minT": "Asgari",
    "maxT": "Maksimum",
    "itemNT": "Ürün Adı",
    "itemPT": "Ürün Fiyatı",
    "descProT": "Ürününüzü tanımlayın",
    "cateT": "Kategori",
    "selProCateT": "Ürün kategorinizi seçin",
    "reqMT": "Menü Adı gereklidir",
    "ViewMenu": "Menüyü görüntüle",
    "CopyMenu": "Menüyü kopyala",
    "EditMenu": "Düzenleme menüsü",
    "RemoveMenu": "Menüyü kaldır",
    "welcomeMnuTitle": "Hoş geldiniz",
    "reviewT": "İncelemeler",
    "userErrorMsg": "Üzgünüz, Bir Hata Oluştu !!!",
    "reqCateT": "Kategori Adı gereklidir",
    "mngCustT": "Özelleştirmeyi Yönet",
    "custNReq": "Özelleştirme adı gereklidir",
    "incReq": "Dahil edilmesi zorunludur",
    "minmValid": "Geçerli bir asgari değer girin",
    "maxmReq": "Maksimum gereklidir",
    "cust": "Özelleştirme",
    "crCust": "Yeni Özelleştirme Oluştur",
    "custList": "Özelleştirme Listesi",
    "delCustmzT": "Bu özelleştirme kalıcı olarak silinecektir.",
    "mkNameR": "Öğe adını işaretlemek zorunludur",
    "mkDelT": "Bu işaret öğesi kalıcı olarak silinecektir.",
    "hideText": "Saklamak",
    "showText": "Göstermek",
    "device_mac_txt": "Aygıt Mac",
    "delete_hotspot_txt": "Bu etkin nokta kalıcı olarak silinecektir.",
    "delete_hotspots_txt": "Bu etkin noktalar kalıcı olarak silinecektir.",
    "emp_wifi_name": "Çalışan Wifi Adı",
    "max_limit_txt": "Hızı SINIRSIZ olarak belirlemek için '1024' girin. Bu değer, tüm sınırlamaları kaldırarak maksimum verim sağlar.",
    "device_port_txt": "Aygıt Bağlantı Noktası",
    "firmware_txt": "Donanım yazılımı",
    "hotspot_info_txt": "Hotspot Bilgileri",
    "editDBtn": "Ayrıntıları Düzenle",
    "birth_date": "Doğum tarihi",
    "di_theme_clr": "Tema Rengi tam 7 haneli",
    "color_Invalid_txt": "Geçerli renk kodunu seçin",
    "Req_theme_clr": "Tema Rengi gereklidir",
    "select_valid_color_txt": "Lütfen geçerli bir tema rengi seçin",
    "req_redir_link": "Yönlendirme Bağlantısı gereklidir",
    "val_redir_link": "Geçerli yönlendirme bağlantısını girin",
    "req_business_name_txt": "İşletme adı gereklidir",
    "splash_preview": "Açılış Önizlemesi",
    "create_new_splash": "Yeni Açılış Ekranı Oluştur",
    "splash_page": "Açılış Sayfası",
    "splash_page_builder": "Açılış Sayfası Oluşturucu",
    "redirect_link": "Yönlendirme Bağlantısı",
    "theme_color": "Tema Rengi",
    "enable_social_login": "Kullanıcıların oturum açması için sosyal medya seçeneklerini etkinleştirin",
    "google": "Google",
    "facebook": "Facebook",
    "is_mandatory": "Zorunlu mu?",
    "field": "Alan",
    "name": "İsim",
    "first_name": "İlk adı",
    "last_name": "Soy isim",
    "birthdate": "Doğum tarihi",
    "gender": "Cinsiyet",
    "email": "E-posta",
    "dob": "Doğum tarihi",
    "zip_code": "Posta kodu",
    "required_redirect_link": "Yönlendirme Bağlantısı gereklidir",
    "valid_redirect_link": "Geçerli yönlendirme bağlantısını girin",
    "required_theme_color": "Tema Rengi gereklidir",
    "theme_color_length": "Tema Rengi tam 7 haneli",
    "required_name": "İsim gerekli",
    "delete_splash_message": "Bu açılışı silmek istiyor musunuz?",
    "delete_multiple_splashes_message": "Bu açılış ekranlarını silmek istiyor musunuz?",
    "user_login_required": "Kullanıcı Girişi gereklidir",
    "set_theme_color": "Tema Rengini Ayarla",
    "splash_colllect_additional_info_txt": "Ek Bilgi Topla",
    "verify_mobile_otp_message": "Mobil numaranızı doğrulamak için OTP göndermek ister misiniz?",
    "add_company": "Şirket Ekle",
    "edit_company": "Şirketi Düzenle",
    "ds_device": "Dijital Tabela Cihazı",
    "ds_device_activation_fee": "Dijital Tabela Cihazı Aktivasyon Ücreti",
    "wifi_hardware": "Wifi Donanımı",
    "menu_design": "Menü Tasarımı",
    "ds_hardware": "Dijital Tabela Donanımı",
    "company_name_req": "Şirket adı gereklidir",
    "known_name_req": "Bilinen isim gereklidir",
    "no_location_req": "Konum numarası gereklidir",
    "minimum_location": "Lütfen en az 1 lokasyon giriniz",
    "terms_req": "Şartlar gereklidir",
    "notes_req": "Notlar gereklidir",
    "sms_charge_req": "SMS Ücreti gereklidir",
    "menuonline_quantity_req": "Menü Çevrimiçi Miktar gereklidir",
    "menuonline_cost_req": "Menü Çevrimiçi Maliyet gereklidir",
    "ds_quantity_req": "Dijital Tabela Miktarı gereklidir",
    "ds_monthly_cost_req": "Dijital Tabela Aylık Maliyeti gereklidir",
    "reputation_quantity_req": "İtibar Miktarı gereklidir",
    "reputation_cost_req": "İtibar Maliyeti gereklidir",
    "marketing_quantity_req": "Pazarlama Miktarı gereklidir",
    "marketing_cost_req": "Pazarlama Maliyeti gereklidir",
    "tax_percentage_req": "Vergi Yüzdesi gereklidir",
    "router_hardware_quantity_req": "Yönlendirici Donanım Miktarı gereklidir",
    "wifi_hardware_cost_req": "WIFI Donanım Maliyeti gereklidir",
    "activation_cost_req": "Aktivasyon maliyeti gereklidir",
    "wifi_quantity_req": "WIFI Miktarı gerekli",
    "wifi_router_req": "Wifi Yönlendirici gereklidir",
    "menu_design_quantity_req": "Menü Tasarım Miktarı gereklidir",
    "menu_design_cost_req": "Menü Tasarım Maliyeti gereklidir",
    "quantity_req": "Miktar gerekli",
    "cost_req": "Maliyet gereklidir",
    "sheduledown_quantity_req": "Program Aşağı Miktarı gereklidir",
    "sheduledown_cost_req": "Maliyeti Düşürme Programı gereklidir",
    "loyalty_program_quantity_req": "Sadakat Programı Miktarı gereklidir",
    "loyalty_program_cost_req": "Sadakat Programı Maliyeti gereklidir",
    "loyalty_program_sms_quantity_req": "Sadakat Programı SMS Miktarı gereklidir",
    "loyalty_program_sms_cost_req": "Sadakat Programı SMS Maliyeti gereklidir",
    "comapny_created": "Şirket Başarıyla Oluşturuldu",
    "comapny_updated": "Şirket Başarıyla Güncellendi",
    "billing_info": "FATURA BİLGİLERİ",
    "business_name_acc_holder": "İşletme Adı (Hesap Sahibi)",
    "client_first_name": "Müşteri Adı",
    "client_last_name": "Müşteri Soyadı",
    "dba_known_as": "DBA Olarak Bilinir",
    "business_address": "İş Adresi",
    "no_of_locations": "Lokasyon Sayısı",
    "package_details": "PAKET DETAYLARI",
    "terms": "Şartlar",
    "one_month": "1 ay",
    "per_sms_charge": "SMS Başına Ücret",
    "plan_test": "PLAN : TEST",
    "desc_capital": "TANIM",
    "qty_capital": "Miktar",
    "cost_capital": "MALİYET",
    "total_price_capital": "TOPLAM FİYAT",
    "quantity": "Miktar",
    "can_not_access": "Birden fazla seçemezsiniz",
    "licenses": "lisanslar",
    "monthly_cost": "Aylık Maliyet",
    "plan_cost": "Plan Maliyeti",
    "tax": "Vergi",
    "tax_percentage": "Vergi Yüzdesi",
    "monthly_cost_after_tax": "Vergi Sonrası Aylık Maliyet",
    "activation_one_time_charge": "Aktivasyon ve Tek Seferlik Şarj",
    "sub_total": "ARA TOPLAM",
    "action": "Aksiyon",
    "cost": "Maliyet",
    "shipping_charge": "Kargo Ücreti",
    "other": "Diğer",
    "additional_cost": "Ek maliyet (Diğer masraflar)",
    "other_tax": "Diğer Vergi",
    "total": "Toplam",
    "license_statistics": "Lisans İstatistikleri",
    "total_licenses": "Toplam Lisanslar",
    "available_licenses": "Mevcut Lisanslar",
    "stock": "Stoklamak",
    "has_permission": "İzin Var",
    "avg_stock_price": "AVG Hisse Senedi Fiyatı",
    "average_price": "Ortalama Fiyat",
    "allocated": "Tahsis edilmiş",
    "reward_regulars": "Düzenlileri ödüllendirin",
    "not_add_coupon_url": "Kupon URL'si eklenemiyor. Mesajın maksimum uzunluğu 160'tır",
    "like_attach_coupon": "Kupon eklemek ister misiniz?",
    "advance_scheduling": "İleri Planlama",
    "choose_day": "Mesaj göndermek için gün(ler) seçin",
    "select_essage_window": "Mesaj Penceresini Seçin",
    "subscription_outside_delivery_window": "Bir kullanıcı teslimat penceresi dışında abone olursa, otomatik yanıtlayıcınızı en yakın zamanda alacaktır.",
    "remaining": "Geriye kalan",
    "processing_request": "Talebiniz işlenirken bir hata oluştu.",
    "list_companies": "Şirketler Listesi",
    "are_you_sure": "Emin misin..?",
    "signin_agreement": "Bunu teyit ederek, Şirket herhangi bir sözleşme imzalamadan kurulacaktır.",
    "status_updated": "Durum güncellemesi başarıyla gerçekleştirildi",
    "status_failed": "Durum güncellenemedi",
    "new_editor_status_updated": "DS Yeni Editör durumu başarıyla güncellendi.",
    "user_name": "Kullanıcı adı",
    "known_as": "olarak bilinir",
    "agreement_signed": "Anlaşma İmzalandı",
    "deactivate": "Devre dışı bırak",
    "activate": "Etkinleştir",
    "login_to_user_acc": "Kullanıcı hesabına giriş yap",
    "disable": "Devre dışı bırakmak",
    "enable": "Olanak vermek",
    "ds_new_editor": "DS Yeni Editör",
    "sign_agreement": "Anlaşmayı İmzala",
    "agreement_sign": "İmzasız Anlaşma",
    "view_agreement": "Sözleşmeyi Görüntüle",
    "download_agreement": "İndirme Anlaşması",
    "add_deleted_user": "Silinen Kullanıcıyı Ekle",
    "deleted_user": "Kullanıcıyı Sil",
    "favourite": "Favori",
    "refresh": "Yenile",
    "delete_chat": "Sohbeti Sil",
    "responsive_drawer": "Duyarlı çekmece",
    "delete_contact": "Kişiyi Sil",
    "clear_chat_history": "Sohbet geçmişini temizle",
    "clear_chat_history_dec": "Bu sohbeti silmek istediğinizden emin misiniz?",
    "clear_contact_dec": "Bu kişiyi silmek istediğinizden emin misiniz?",
    "select_contact": "İletişim Seçin",
    "new_conversation": "Yeni bir konuşma başlat",
    "type_msg": "Mesajınızı buraya yazın",
    "select_delete_client": "Lütfen silinecek istemciyi seçin",
    "select_delete_client_chat": "Lütfen sohbeti silmek için istemciyi seçin",
    "select_delete_client_chat_err": "Bu müşteriyle sohbetiniz yok",
    "acquire_customers_business": "Diğer işletmelerden yeni müşteriler edinin.",
    "customers_from_events": "Etkinliklerden müşteri edinin ve size getirin!",
    "customers_from_ads": "Gazete ilanlarından müşteri edinin.",
    "smart_pamphlets": "Müşteri verilerini toplamak için akıllı broşürler oluşturun.",
    "smart_qr_group": "Müşterilerin bir gruba katılmasını sağlayan Akıllı QR.",
    "opt_in_number": "Müşterilerin sayıya katılımını sağlayın.",
    "qr_menu": "Menünüzü görüntülemek için QR kodu oluşturun.",
    "collect_birthdays": "Müşterinin doğum günü ve yıldönümünü toplayın.",
    "join_loyalty_program": "Sadakat programıma yeni müşterilerin katılmasını sağlayın.",
    "create_feedback_forms": "Müşterilerinizin geri bildirimlerini toplamak için formlar oluşturun.",
    "exclusive_discounts_promotions": "Çapraz promosyonlar için özel indirimler ve promosyonlar sunun.",
    "group_smart_qr_opt_in": "Bir grup oluşturduktan sonra, müşterilerin anında bir gruba katılmasını sağlayan akıllı bir QR kodu oluşturabilir ve seçebilirsiniz",
    "group_smart_qr_opt_in_dec": "Bir grup oluşturulduktan sonra, müşterilerin metin kullanarak katılmasını ve promosyonlar almasını sağlayan Görüntü widget'ı, örneğin \"pizza\"yı 555-555-5555 numarasına gönderin",
    "in_store_discount_next_visit": "Bir sonraki mağaza ziyaretinizde mağaza içi indirim kazanın",
    "delivery_redirection": "Teslimat Yönlendirmesi Oluştur",
    "additional_info": "Ek Bilgiler",
    "add_url": "URL ekle",
    "custom_fields": "Özel alanlar",
    "meta_tag": "Meta etiketi",
    "max_chars": "Maksimum karakter sayısı",
    "add": "Eklemek",
    "update_campaign": "Güncelleme Kampanyası",
    "last_week_required": "geçen hafta gerekli",
    "large_week_value": "Hafta değeri çok büyük geçerli hafta girin",
    "less_than_last_week": "Geçen hafta iade edilmeyenlerin geçen haftadan az olması gerekmektedir.",
    "last_week_req": "Geçtiğimiz hafta içinde geri dönmemeniz gerekiyor",
    "birthday_special": "Doğum günü özel",
    "birthday_message": "Doğum günlerinde kişiselleştirilmiş tebrikler ve cazip tekliflerle otomatik mesaj gönderildi",
    "birthday_sms": "SMS\/MMS için kişiselleştirilmiş doğum günü tebrikleri oluşturun",
    "target_audience": "Hedef kitleyi seçin",
    "client_by_birthday": "Müşteri Doğum Gününe Göre",
    "clients_once_year": "Bu kampanya yılda bir kez otomatik olarak müşterilere gönderilir ",
    "auto_responder_default_msg_1": "Siz bizim indirim yapmayı sevdiğimiz en iyi müşterilerimizden birisiniz! Her zaman zengin ve sağlıklı olmanız dileğiyle. Doğum gününüz kutlu olsun! %% firstname %%",
    "campaign_send_to_client_birthday": "Bu otomatik kampanyayı doğum günü olan müşterilere gönderirsiniz, aşağıdan hedeflemeyi özelleştirebilirsiniz.",
    "get_more_engagement": "Doğum günü SMS'leri göndererek daha fazla etkileşim alın 🎂",
    "welcome_new_clients": "Yeni müşterilere hoş geldiniz",
    "make_lasting_impression": "İlk kez yemek yiyenleri sıcak bir şekilde karşılayarak ve ağırlayarak kalıcı bir izlenim bırakın",
    "personalized_greetings": "Yeni müşteriler için kişiselleştirilmiş selamlamalar oluşturun",
    "greetings_to_new_customers": "Bu kampanya, işletmenizle ilk etkileşimlerinin ertesi günü yeni müşterilere hoş geldiniz selamları gönderecektir.",
    "audience_predefined": "Bu otomatik yanıtlayıcının hedef kitlesi önceden tanımlanmıştır, bunu hallederiz!",
    "send_clients_subscribed": "Bu otomatik yanıtlayıcı, son abone olan istemcilere gönderilecek şekilde ayarlanmıştır ",
    "first_interaction": "  ilk etkileşimlerinden sonra.",
    "default_msg_2": "İlk ziyaretinizden keyif aldığınızı umuyoruz, sizi yakında tekrar görmek isteriz, bu yüzden sadece sizin için bir indirim sunuyoruz! Bir sonraki randevunuzu ayarlamak ve özel çevrimiçi teklifinizi hemen kullanmak için bağlantıya tıklayın.",
    "new_clients_update": "Yeni müşterilere hoş geldiniz güncelleme kampanya metni",
    "new_clients_warm_greeting": "Yeni Müşterileri Sıcak Bir Karşılamayla Karşılayın 💌",
    "win_back_clients": "Müşterileri geri kazanın",
    "re_engage_past_customers": "Geçmişteki müşterilerinizle karşı konulamaz tekliflerle yeniden etkileşime geçerek onları tekrar gelmeye teşvik edin",
    "target_disengaged_clients": "Hedef, müşterileri geri çekmek için kanıtlanmış bir taktik olan, özel bir teklifle geri dönmelerini sağlamak için ilgisiz müşterileri hedef alır.",
    "campaign_sends_to_clients": "Bu kampanya belirli bir süre sonra geri dönmeyen müşterilerimize gönderilmektedir.",
    "customize_targeting": "Aşağıda hedeflemeyi özelleştirerek, aboneliği sona eren müşterilere SMS\/MMS gönderebilirsiniz.",
    "clients_with_at_least": "En azından müşteriler ",
    "connection_last": " son bağlantı ",
    "return_in_last_week": " hafta, ancak geçen hafta geri dönmedi ",
    "arr_menu_44": " hafta",
    "df_msg_4": "Sizi O KADAR ÇOK geri istiyoruz ki, sadece geri döndüğünüzde size %10 indirim yapacağız! Bugün tekrar denemek ister misiniz?",
    "update_campaign_text": "Müşterileri geri kazanın kampanya metnini güncelleyin",
    "re_invite_customers": "Bir süredir gelmeyen müşterileri tekrar davet edin",
    "loyal_patrons": "Sadık müşterilere özel indirimler ve sadakat ödülleriyle takdirinizi gösterin",
    "surprize_top_spenders": "En çok harcama yapanları kişiselleştirilmiş ve özel tekliflerle şaşırtın.",
    "campaign_automatically_send": "Bu kampanya, işletmenizdeki müşteri trafiğini artırmak için otomatik olarak ödüller gönderir.",
    "sms_loyal_clients": "Bu kampanya sadık müşterilerinize SMS\/MMS gönderir, hedeflemeyi aşağıdan değiştirebilirsiniz.",
    "or_more_connection": " veya daha fazla bağlantı, son içinde ",
    "week_period": " haftalık süre.",
    "default_msg_5": "Uzun zamandır müşterimizsiniz! Vay canına, zaman su gibi akıp geçiyor! Sizi takdir ediyoruz ve bizimle olduğunuz için teşekkür etmek istiyoruz.",
    "reward_regulars_update": "Ödül düzenli güncelleme kampanya metni",
    "special_discounts": "Takdir edin ve özel indirimler verin💎",
    "leave_positive_online": "Müşterileri restoranın itibarını artırmak için olumlu çevrimiçi yorumlar bırakmaya teşvik edin",
    "clients_service_online": "Kampanya, müşterilerinizi hizmetinizi çevrimiçi olarak derecelendirmeye teşvik eder.",
    "increase_your_company": "Bu otomatik yanıtlayıcı, şirketinizin çevrimiçi puanını artırmak için gönderilir.",
    "send_message_after": "Mesaj gönderdikten sonra ",
    "min_connection": " bağlantının min'i",
    "default_msg_6": "İlk ziyaretinizden memnun kaldığınızı umuyoruz, sizi tekrar aramızda görmekten mutluluk duyarız! [URL] Bizi Google'da değerlendirmek için bağlantıya tıklayın.",
    "ratings_update": "Daha fazla Derecelendirme güncelleme kampanya metni alın",
    "friendly_nudge": "Dostça Bir Teşvikle Daha Fazla Yorum Alın ⭐",
    "thanks_for_visiting": "Ziyaretiniz için teşekkür ederiz",
    "express_gratitude": "Müşterilerinize işletmenizi seçtikleri için içten teşekkür mesajlarıyla minnettarlığınızı ifade edin",
    "thank_you_message": "Müşterileriniz için teşekkür mesajı oluşturun.",
    "clients_for_visiting": "Müşterilerinize mekanınızı ziyaret ettikleri için sıcak bir teşekkür gönderin.",
    "campaign_audience": "Bu otomatik kampanyanın hedef kitlesi önceden belirlenmiş, bunu başarabiliriz!",
    "campaign_automatically_sends": "Bu otomatik kampanya otomatik olarak gönderilir ",
    "minutes_customer_visit": " müşteri ziyareti dakikaları",
    "default_msg_7": "Değerli müşterimiz olduğunuz için teşekkür ederiz. Size hizmet etmekten büyük mutluluk duyuyoruz ve beklentilerinizi karşıladığımızı umuyoruz.",
    "visiting_update_campaign": "Güncelleme kampanya metnini ziyaret ettiğiniz için teşekkür ederiz",
    "guests_thank_you": "Misafirlerinize Ziyaretleri İçin Teşekkür Edin 😊",
    "download_title": "İndirmek",
    "qr_gen_title": "QR Oluşturuldu",
    "qr_download_s_title": "QR Kodu başarıyla indirildi",
    "sel_dark_brand_clr_msg": "Lütfen geçerli bir barnd rengi seçin",
    "manage_customer_stamp_rewards": "Müşteri Damga Ödüllerini Yönetin 🎁",
    "sel_loc_menu_title": "Menünüz için lokasyon seçin",
    "ans_req": "Cevap gerekli",
    "valid_reputation_name": "Geçerli itibar adını girin",
    "reviews_name_req": "Daha Fazla Yorum Alın isim gereklidir",
    "birthdate_required": "Doğum tarihi gereklidir",
    "gender_required": "Cinsiyet gereklidir",
    "valid_birthdate_required": "Geçerli doğum tarihi girin",
    "custom_delivery_redirection": "Gruplara göre özel bir teslimat yönlendirme formu oluşturun",
    "customer_fields_incentive_settings": "Müşteri alanları ve teşvik ayarları",
    "delivery_redirection_text": "Teslimat Yönlendirmesi",
    "delivery_redirection_success": "Tebrikler! Teslimat Yönlendirme Widget'ınız oluşturuldu! 🎉",
    "swipe_to_preview_redirection": "Teslimat Yönlendirme formunuzun nasıl görüneceğini görmek için kaydırın",
    "enter_item_name": "Öğe adını girin",
    "home_page_text": "Ana sayfa metni",
    "settings": "Ayarlar",
    "anniversary_required": "Yıldönümü tarihi gereklidir",
    "valid_anniversary": "Geçerli yıldönümü tarihini girin",
    "form_submited": "form başarıyla gönderildi",
    "notifications": "Bildirimler",
    "discount_message": "Pizza'da %20 indirim fırsatını hemen yakalayın!",
    "is_required": " gereklidir",
    "section_title_required": "Bölüm başlığı gereklidir",
    "section_dec_required": "Bölüm açıklaması gereklidir",
    "client_details_required": "Müşteri ayrıntıları alanları zorunludur",
    "compliance": "Uyumluluk",
    "SMS_campaign1": "SMS Kampanyası 1",
    "mobile_number_mandatory": "Cep telefonu numarası zorunludur",
    "new_answer": "Yeni cevap",
    "new_question": "Yeni soru",
    "add_new_question": "Yeni cevap ekle",
    "select": "Seçme",
    "group_customers_question": "Müşterilerinizi nerede gruplandırmak istiyorsunuz?",
    "contacts_added_to_group": "Formu gönderen tüm kişiler seçili gruba eklenecektir",
    "edit_client_details_section": "Müşteri Ayrıntıları Bölümünü Düzenle",
    "client_details_fields": "Müşteri Ayrıntıları Alanları",
    "enter_client_details_section_title_desc": "Müşteri ayrıntıları bölümünün başlığını ve açıklamasını girin",
    "choose_fields_displayed_client_side": "İstemci tarafında görüntülenecek alanları seçin",
    "section_title": "Bölüm Başlığı",
    "add_title": "Başlık Ekle",
    "section_description": "Bölüm Açıklaması",
    "add_description": "Açıklama Ekle",
    "enter_program_name_location": "Program adını ve yerini girin 📍",
    "brand_theme_clr_txt": "Marka Tema Rengi",
    "set_loyalty_punch_card_theme": "Sadakat kartınız için bir tema belirleyelim 🎨",
    "upload_logo_txt": "Logonuzu Yükleyin",
    "recommended_image_specs": "En iyi görüntü için 512 x 512px boyutunda bir resim kullanmanızı öneririz. JPG, SVG veya PNG. Maksimum boyut 10MB.",
    "valid_rep_name_txt": "Geçerli itibar adını girin",
    "rep_name_req_txt": "Daha Fazla Yorum Alın isim gereklidir",
    "que_req": "Soru gerekli",
    "day_of_birthday": "doğum günü gününde",
    "day_before_birthday": "Doğum gününden 3 gün önce",
    "week_before_birthday": "doğum gününden bir hafta önce",
    "two_week_before_birthday": "doğum gününden iki hafta önce",
    "de_active": "Deaktif",
    "campaign_details": "Kampanya detayları",
    "link_clicked": "Bağlantı tıklandı",
    "history": "Tarih",
    "auto_responder_summary": "Otomatik Yanıtlayıcı Özeti",
    "vsop_1": "15 dk",
    "vsop_2": "30 dk",
    "vsop_3": "45 dk",
    "vsop_4": "60 dk",
    "vsop_5": "90 dk",
    "vsop_6": "120 dk",
    "in_the_last": " sonunda ",
    "return_in_last": " ama son olarak geri dönmedi ",
    "of_the_connection": "bağlantının",
    "your_campaign": "Kampanyanız",
    "you_have_successfully": "Başarılı oldunuz",
    "published_successfully": "başarıyla yayınlandı",
    "updated_smart_campaign": "akıllı kampanya güncellendi",
    "almost_done_text": "Neredeyse bitti!",
    "update_campaign_desc": "Otomatik yanıtlayıcınız tamamlandı, etkinleştirildiğinde, müşterilere sürekli olarak mesaj gönderecektir. Bu kampanya herhangi bir zamanda kolayca değiştirilebilir veya duraklatılabilir.",
    "update_and_publish": "Güncelle ve Yayımla",
    "reset_campaign_title": "Kampanyanızın özel metinlerini, görsellerini, indirim tekliflerini ve hedef kitlesini kalıcı olarak silecektir!",
    "space_wifi": " Wifi",
    "custom_forms": "Özel Formlar",
    "web_e_signup": "Web E-kayıt",
    "import": "İçe aktarmak",
    "permissions_req": "İzinler gerekli",
    "redeemed": "Kurtarılmış",
    "coupon_already_redeemed": "Kupon zaten kullanıldı",
    "autoresponder_campaigns": "Otomatik Yanıtlayıcı Kampanyaları",
    "autoresponder_campaign_desc": "Otomatik yanıtlayıcıyı etkinleştirin ve kişiselleştirilmiş kampanyayı başlatın",
    "mon": "Pzt",
    "tue": "SALI",
    "wed": "ÇARŞAMBA",
    "thu": "PERŞEMBE",
    "fri": "Cuma",
    "sat": "DOYGUNLUK",
    "sun": "GÜNEŞ",
    "duplicate": "Kopyalamak",
    "visibility": "Görünürlük",
    "availability": "Kullanılabilirlik",
    "out_stok": "Stokta yok",
    "edit_product": "Ürünü Düzenle",
    "create_product": "Bir Ürün Oluşturun",
    "basic_info": "Temel Bilgiler",
    "basic_sub_info": "Lorem Ipsum, basım metninin sahte metnidir...",
    "enter_product_price": "Ürün fiyatını girin",
    "upload_image": "Resim Yükle",
    "allowed_file_formats": "Sadece maksimum dosya boyutu 3MB olan JPG ve PNG dosyalarına izin verilmektedir.",
    "pre_select": "Ön Seçim",
    "promotions": "Promosyonlar",
    "discount_availability": "İndirim Mevcutluğu",
    "start_time": "Başlangıç Zamanı",
    "end_time": "Son Zaman",
    "select_day": "Gün Seçin",
    "menu_required": "Menü gerekli",
    "generated": "Oluşturuldu",
    "link": "Bağlantı",
    "variations": "Varyasyonlar",
    "select_variant_to_add": "Eklemek istediğiniz Varyantı seçin",
    "price_title": "Fiyat",
    "choose_image": "Resim Seçin",
    "select_visiblity": "Görünürlüğü Seç",
    "availability_schedule": "Uygunluk Programı",
    "add_on_upsell": "Ekleme \/ Satış artırma",
    "add_on_select_product": "Ürün Seç'e Ekle",
    "upsell_product": "Ürün Seçin",
    "variant_deletion_warning": "Bu Varyant kalıcı olarak silinecektir.",
    "search_variations": "Arama Varyasyonları",
    "add_variation": "Varyasyon Ekle",
    "variation_text": "Varyant",
    "select_customization": "Özelleştirmeyi seçin",
    "add_new": "Yeni Ekle",
    "delete_customization_warning": "Bu özelleştirmeyi üründen silmek istiyor musunuz?",
    "nutritional_allergen_info": "Beslenme ve Alerjen Bilgileri",
    "mark_item": "Öğeyi İşaretle",
    "calories_text": "Kalori",
    "allergens": "Alerjenler",
    "select_allergens": "Alerjenleri seçin",
    "special_instructions": "Özel Talimatlar",
    "preparation_instructions": "Hazırlanış Talimatları",
    "staff_notes": "Personel Notları",
    "checkbox1": "Bu menü öğesi için müşterilerden özel talimatlar alınmasına izin vermek veya vermemek üzere bir onay kutusu.",
    "menu_c": "MENÜ",
    "past_design": "Geçmiş Tasarım",
    "file_name": "Dosya adı",
    "variation_name": "Varyasyon Adı",
    "grid_items_title1": "Masa Çadırı",
    "grid_items_title2": "Dijital Menü",
    "grid_items_title3": "Paket Servis Menüsü",
    "grid_items_title4": "Yarım Sayfa Menü",
    "grid_items_title5": "Dijital Menü 5",
    "grid_items_title6": "Dijital Menü 6",
    "grid_items_title7": "Dijital Menü 7",
    "grid_items_title8": "Dijital Menü 8",
    "grid_items_title9": "Dijital Menü 9",
    "enter_valid_form_name": "Geçerli form adını girin",
    "form_name_required": "Form adı gereklidir",
    "enter_valid_section": "Geçerli bölüm başlığını girin",
    "fraction_half": "1\/2",
    "fraction_two_by_two": "2\/2",
    "form_name": "Form Adı",
    "contact_list": "İletişim Listesi",
    "label_notify_email": "E-posta yoluyla bildirim almak ister misiniz?",
    "add_client_details_section": "Müşteri Ayrıntıları Bölümünü Ekle",
    "demo_purpose_only": "Bu sadece demo amaçlıdır",
    "form_updated_success": "form başarıyla güncellendi",
    "form_creat_success": "form başarıyla oluşturuldu",
    "add_custom_section": "Özel Bir Bölüm Ekle",
    "edit_custom_section": "Özel Bir Bölümü Düzenle",
    "forms_list": "Formların Listesi",
    "filled_custom_form_details": "Doldurulmuş Özel Form Ayrıntıları",
    "custom_form_deletion_warning": "Bu özel form kalıcı olarak silinecek",
    "menu_link": "Menü Bağlantısı",
    "WebSignUpT": "E-kayıt",
    "lifeTConT": "Ömür Boyu Bağlantılar",
    "repCustT": "Tekrarlayan Müşteriler",
    "liftTSubT": "Ömür boyu aboneler",
    "overallT": "ETRAFLI",
    "loginCustBDT": "Güne Göre Giriş Yapan Müşteriler",
    "deviceT": "Cihaz Demografisi",
    "ageTitle": "Yaş Demografisi",
    "ageT": "Yaş Grubu",
    "convert_social_media_followers_into_customers": "Sosyal medya takipçilerinizi müşterilere dönüştürün",
    "signWidDesc": "Kayıt olmak, açılış sayfaları oluşturmak ve web sitenizden veya sosyal medyadan kupon üretmek için bir widget oluşturun",
    "qrWidT": "Akıllı QR",
    "imgWidT": "Katılım sayısı",
    "mobile": "Mobil",
    "YESTitle": "EVET",
    "NOTitle": "HAYIR",
    "addDelRed": "Teslimat Yönlendirmesi Ekle",
    "dlredNameT": "Teslimat yönlendirmenize bir ad verin",
    "conf_text_req": "Onay metni gereklidir",
    "cFDesc": "Müşterilerden belirli geri bildirimleri ve bilgileri etkili bir şekilde toplamak için özel formlar oluşturun",
    "pre_review_questions_enabled": "Müşterilerden yorum istemeden önce onlara 1 ila 4 soru soralım.",
    "feel_free_edit": "Gerektiğinde düzenleme yapmaktan çekinmeyin.",
    "option": "Seçenek",
    "confFL": "Bu onay, kişinizin Web Kayıt Formunu göndermesinden sonra gösterilecektir.",
    "confT": "Onay metni",
    "joinTitle": "Katılmak",
    "mobValidL": "Lütfen geçerli cep telefonu numarasını kontrol edin",
    "mobRequiredL": "Cep telefonu numarası gereklidir",
    "Business_location_label": "İşletmenizin Lokasyonu",
    "qr_code_selection_txt": "QR Kod Seçimi",
    "choose_theme_txt": "Tema Seçin",
    "edit_qr_details_txt": "QR Ayrıntılarını Düzenle",
    "save_qr_code_txt": "QR Kodunu Kaydet",
    "text_color_label": "Metin Rengi",
    "background_color_label": "Arkaplan Rengi",
    "print_txt": "Yazdır",
    "customer_will_see_txt": "Müşterinizin göreceği şey budur. Bir sonraki adımda marka renginizi seçin.",
    "choose_your_brand_color_&_langauge_title": "Marka renginizi ve dilinizi seçin",
    "custom_redirect_link_label": "Özel Yönlendirme Bağlantısı",
    "redirect_user_to_menu": "Kullanıcıyı Menüye Yönlendir",
    "language": "Dil",
    "select_language_placeholder": "Dil Seçin",
    "capitalize_location_name_txt": "Konum Adı",
    "created_txt": "Oluşturuldu",
    "swipe_reputation_management_txt": "İtibar yönetiminizin nasıl görüneceğini görmek için kaydırın",
    "sent_txt": "Gönderilmiş",
    "number_label": "Sayı",
    "date_label": "Tarih",
    "gender_demographics_title": "Cinsiyet Demografisi",
    "step1_preview_desc": "E-kayıt QR Formunuza bir isim verin ve grup listenize ekleyin",
    "main_head": "Yeni Müşteriler Edinin",
    "main_desc": "Kullanıcıları tarandığında kişiselleştirilmiş bir kayıt formuna yönlendiren bir QR kodu yerleştirerek müşteri edinin.",
    "step1_description": "Diğer işletmelerden yeni müşteriler edinin",
    "form_name_field_placeholder": "Geri E-kayıt",
    "last_step_title": "Tebrikler! E-kayıt Widget'ınız oluşturuldu! 🎉",
    "last_step_desc": "E-kayıt formunuzun nasıl görüneceğini görmek için kaydırın",
    "events_step1_preview_desc": "Etkinlik QR Formunuza bir isim verin ve grup listenize ekleyin",
    "events_main_head": "Etkinliklerden Müşteri Kazanın",
    "events_main_desc": "Etkinliğe bir QR kodu yerleştirerek müşteri edinin, kod tarandığında kullanıcılar kişiselleştirilmiş bir kayıt formuna yönlendirilir.",
    "events_step1_desc": "Müşterileri etkinliklerden yakalayın ve onları mağazaya getirin",
    "events_name": "Etkinlik Adı",
    "events_form_name_field_placeholder": "Restro Eğlence ve Yemek Etkinliği",
    "events_last_step_title": "Tebrikler! Etkinlik Formunuz oluşturuldu! 🎉",
    "events_last_step_desc": "Etkinlik formunuzun nasıl görüneceğini görmek için kaydırın",
    "birthday_anniversary_step1_preview_desc": "Müşterilerinizin doğum günü veya yıl dönümlerini toplamak için QR Formunuza bir ad verin ve bunu grup listenize ekleyin",
    "birthday_anniversary_main_head": "Doğum günü veya Yıldönümü topla",
    "birthday_anniversary_main_desc": "Müşterinin doğum günü veya yıldönümü tarandığında, kullanıcılar kişiselleştirilmiş bir kayıt formuna yönlendirilir.",
    "birthday_anniversary_step1_desc": "Müşterinin doğum gününü ve yıldönümünü topla",
    "restro_text": "Geriye dönük",
    "birthday_anniversary_last_step_title": "Tebrikler! Müşterilerden Doğum Günü ve Yıldönümü formu oluşturuldu! 🎉",
    "newspaper_ads_step1_preview_desc": "Gazete ilanlarından müşteri çekmek için QR Formunuza bir isim verin ve bunu grup listenize ekleyin",
    "newspaper_ads_main_head": "Reklamlardan müşteri edinin",
    "newspaper_ads_main_desc": "Müşterilerinizi gazete ilanlarından alın, taradıklarında kişiselleştirilmiş bir kayıt formuna yönlendirin.",
    "newspaper_ads_step1_desc": "Gazete Reklamından veya Açık Hava Reklamından Müşteri Edinin",
    "newspaper_ads_last_step_title": "Tebrikler! Gazete ilanlarından müşteri edinin formu oluşturuldu! 🎉",
    "smart_pamphlets_step1_preview_desc": "Broşürlerden müşterilerinizin verilerini almak ve grup listenize eklemek için QR Formunuza bir ad verin",
    "smart_pamphlets_main_head": "Broşürlerden müşteri edinin",
    "smart_pamphlets_main_desc": "Müşterilerinizin verilerini broşürlerden alın, taradıklarında kullanıcıları kişiselleştirilmiş bir kayıt formuna yönlendirin.",
    "smart_pamphlets_last_step_title": "Tebrikler! Broşür formundan müşteri verilerini alın! 🎉",
    "social_media_step1_preview_desc": "Sosyal medyadan müşteri çekmek ve müşterilerinizi grup listenize eklemek için QR Formunuza bir isim verin",
    "social_media_main_desc": "Sosyal medyadan müşteri edinin, gönderileri tarayarak kullanıcıları kişiselleştirilmiş bir kayıt formuna yönlendirin.",
    "social_media_form_name_field_placeholder": "Restro Festival Teklifi - Facebook Gönderisi",
    "social_media_last_step_title": "Tebrikler! Müşterilerin sosyal medya formunu oluşturun! 🎉",
    "claim_offer_text": "Ayrıntılarınızı doldurun ve teklifi hemen talep edin",
    "form_title_description": "Müşterilerinizin ayrıntıları girmesi için Form başlığı ve açıklaması ekleyin",
    "no_subscribed_clients": "Seçilen müşteri grubunun abone olmuş müşterisi yok",
    "welcome_message_offer": "Müşterilerinize kaydolduktan sonra bir hoş geldiniz mesajı gönderin ve onlara bir teklif kuponu verin",
    "system_grp": "Sistem Grupları",
    "import_clients": "İthalat Müşterileri",
    "delivery_redirection_name": "Teslimat Yönlendirme Adı",
    "created_at": "Oluşturuldu",
    "provide_stamp_user": "Kullanıcıya Damga Sağlayın",
    "scan_qr_code": "QR Kodunu Tara",
    "add_point": "Nokta Ekle",
    "total_points": "Toplam Puan",
    "user_profile": "Kullanıcı Profili",
    "stamp_history": "Pul Tarihi",
    "reward_txt": "Ödül",
    "variant_placeholder": "Küçük, Orta, Büyük vb.",
    "sent_coupon": "Kupon Gönderildi",
    "order_status": "Sipariş Durumu",
    "payment_status": "Ödeme Durumu",
    "order_currency": "Sipariş Para Birimi",
    "card_data": "Kart Verileri",
    "reward_expiring_on": "Ödül Sona Eriyor",
    "reward_status": "Ödül Durumu",
    "reward_get_date": "Ödül Alma Tarihi",
    "reward_name": "Ödül Adı",
    "add_point_type": "Nokta Türü Ekle",
    "restaurant_location": "Restoranın Yeri",
    "collect_points": "Puan Topla",
    "rewards_expire": "Ödüllerin Son Kullanma Tarihi",
    "rewards_redeem": "Ödüller Kullan",
    "total_rewards": "Toplam Ödüller",
    "created_by": "Tarafından oluşturuldu",
    "anniversary": "Yıl dönümü",
    "remove_user_group_txt": "Kullanıcıyı Gruptan Kaldır",
    "delete_permanently_grp_msg": "Bu müşteri gruptan kalıcı olarak silinecek.",
    "delete_multiple_permanently_grp_msg": "Bu istemci gruptan kalıcı olarak silinecektir.",
    "delete_multiple_client": "Bu istemci kalıcı olarak silinecektir.",
    "messages": "Mesajlar",
    "management": "Yönetmek",
    "client": "Müşteri",
    "country": "Ülke",
    "state": "Durum",
    "city": "Şehir",
    "1_sparkly_point_txt": "🎉 1 parlak puan kazandınız! 🌟",
    "select_country_txt": "Ülke seçin",
    "select_state": "Eyalet seçin",
    "ls_thanks_for_join": "Sadakat kartımıza katıldığınız için teşekkür ederiz.🎁🎈",
    "month": "Ay",
    "welcome_message": "Müşterilerinize kaydolduktan sonra bir karşılama mesajı gönderin",
    "all_rewards_txt": "Tüm Ödüller",
    "active_rewards": "Aktif Ödüller",
    "expired_and_redeemed": "Süresi Dolmuş ve Kullanılmış",
    "redirect_3rd_party_delivery": "Üçüncü taraf teslimatını mağazanıza veya çevrimiçi sipariş platformunuza yönlendirin",
    "search_or_start_new": "Ara veya yeni bir tane başlat",
    "contacts": "İletişim",
    "chats": "Sohbetler",
    "add_by_restaurant": "Restorana göre ekle",
    "using_scan_code": "Tarama Kodunu Kullanma",
    "step": "Adım",
    "list_widget_text": "Widget'ların Listesi",
    "swipe_free_wifi_txt": "Ücretsiz wifi için kaydırın",
    "create_widget_txt": "Widget Oluştur",
    "redeem_award_msg": "Emin misiniz? Bu ödülü kullanmak istiyor musunuz?",
    "on_collect_msg": "Toplamada",
    "pause": "Duraklama",
    "resume": "Sürdürmek",
    "location_added": "konum eklendi",
    "sub_user_admin_permissions_txt": "Dijital Tabelada alt kullanıcının yönetici izinlerine erişmesine izin verin",
    "unlist": "Listeden çıkar",
    "cannot_delete_default_terms": "Varsayılan Şartlar ve Koşullar silinemiyor",
    "something_went_wrong": "Bir şeyler ters gitti!",
    "card_download_success": "Kart Başarıyla İndirildi",
    "permission_denied": "İzin reddedildi!",
    "invalid_qr_code": "Lütfen geçerli QR kodunu tarayın",
    "select_customer": "Lütfen müşteri seçin",
    "online": "ÇEVRİMİÇİ",
    "offline": "ÇEVRİMDIŞI",
    "no_data": "Üzgünüz, veri bulunamadı!",
    "forever_free": "Sonsuza dek özgür"
}

export default tr;