const fa = {
    "rated_google_reviews": "امتیاز 5.0 در Google Reviews",
    "get_your_digital_menu": "منوی دیجیتال خود را دریافت کنید—",
    "instant_popup_menu": "منوی پاپ آپ فوری بدون اسکن QR",
    "free_forever": "رایگان برای همیشه",
    "the_future_is_here": "آینده اینجاست!",
    "upgrade_your_restaurant": "رستوران خود را با یک منوی دیجیتال خیره کننده با هزینه صفر ارتقا دهید. از امروز مشتریان بیشتری را جذب کنید.",
    "get_my_free_menu_now": "اکنون منوی رایگان من را دریافت کنید",
    "no_credit_card_required": "*بدون نیاز به کارت اعتباری",
    "join_largest_marketplace": "به بزرگترین بازار رستوران بپیوندید!",
    "what_will_you_get": "چه چیزی را دریافت خواهید کرد",
    "free": "رایگان",
    "free_digital_menu": "منوی دیجیتال رایگان دو بعدی یا سه بعدی",
    "free_digital_menu_desc": "با منوهای کاغذی و چاپ مجدد گران قیمت خداحافظی کنید",
    "quick_and_easy_signup": "ثبت نام سریع و آسان",
    "communicate_with_customers_title": "با مشتریان به صورت رایگان ارتباط برقرار کنید!",
    "features1": [
        "در هزینه های چاپ صرفه جویی کنید و هر زمان که به آنها نیاز داشتید از به روز رسانی فوری منو لذت ببرید.",
        "به منوی دیجیتال خود در هر دستگاهی دسترسی داشته باشید",
        "سازگار با محیط زیست و بدون دردسر"
    ],
    "features2": [
        "به مشتریان اجازه دهید به طور مجازی غذاهای شما را با تصاویر سه بعدی خیره کننده کشف کنند.",
        "با ارائه یک پیش نمایش واقع بینانه از وعده غذایی خود، تعامل را افزایش دهید.",
        "با تجربه منویی که به اندازه طعم غذای شما فریبنده است، متمایز شوید."
    ],
    "features3": [
        "با ورود مهمانان به رستوران فوراً با منوی خود از آنها استقبال کنید.",
        "به‌روزرسانی‌های ویژه و تبلیغات را به‌طور خودکار به تلفن‌های خود ارسال کنید.",
        "تجربه غذاخوری را با دسترسی سریع و بدون تماس به منوی خود بهبود بخشید."
    ],
    "feature4": [
        "پیشنهادهای فصلی، ویژه روزانه، و تبلیغات منحصر به فرد را برجسته کنید.",
        "توجه مشتریان را به محبوب ترین اقلام خود جلب کنید.",
        "با تصاویر و توضیحات چشم نواز، فوریت و هیجان ایجاد کنید."
    ],
    "feature5": [
        "بخشی از یک جامعه آنلاین با هزاران بازدیدکننده روزانه باشید.",
        "افزایش دید و رسیدن به مشتریان جدیدی که فعالانه گزینه‌های غذاخوری را جستجو می‌کنند.",
        "برای به حداکثر رساندن قرار گرفتن در معرض و جذب تعداد بیشتری از غذاخوری‌ها، زودتر یک مکان برتر را ایمن کنید."
    ],
    "section3_heading": "چرا دیجیتال برویم؟ زیرا رایگان و آسان است!",
    "section3_description": "قفل ویژگی های قدرتمندی را باز کنید که برای افزایش جذابیت رستوران شما طراحی شده اند.",
    "instant_menu": "منوی فوری",
    "instant_menu_notification": "پاپ آپ و اعلان",
    "instant_menu_description": "به محض ورود از مهمانان با دسترسی فوری به منو استقبال کنید. به طور خودکار آخرین منوها و موارد ویژه را مستقیماً به تلفن همراه خود ارسال کنید",
    "digital_menu": "منوهای دیجیتال",
    "at_zero_cost": "با هزینه صفر",
    "digital_menu_description1": "با منوهای کاغذی و چاپ مجدد گران قیمت خداحافظی کنید. به منوی دیجیتالی بروید که همیشه به‌روز است—و برای همیشه رایگان است!",
    "digital_menu_description2": "با منوی دیجیتال ما، پیشنهادات شما همیشه جاری و در هر دستگاهی قابل دسترسی است. در هزینه صرفه جویی کنید و به روز رسانی منو را با یک راه حل بدون درز و بدون هزینه ساده کنید.",
    "try_me_button": "من را امتحان کن",
    "try_3d_menu_title": "منوهای سه بعدی",
    "try_3d_menu_title2": "به صورت رایگان - وای!",
    "try_3d_menu_description1": "ظروف خود را از صفحه نمایش بیرون بیاورید. تصاویر سه بعدی را آپلود کنید و اجازه دهید مشتریان قبل از ورود به داخل، غذای شما را به صورت مجازی بچشند.",
    "try_3d_menu_description2": "تصاویر سه بعدی با کیفیت بالا، پیش‌نمایش شگفت‌انگیزی ایجاد می‌کنند، جذابیت را افزایش می‌دهند و سفارش‌ها را تشویق می‌کنند.",
    "try_now": "اکنون امتحان کنید",
    "showcase_title": "خود را به نمایش بگذارید",
    "showcase_specials": "ویژه",
    "showcase_specials_description1": "یک معامله بی‌نظیر یا یک تبلیغ بی‌رقیب دارید؟ آن را در منوی دیجیتال خود برجسته کنید و افراد گرسنه بیشتری را جذب کنید.",
    "showcase_specials_description2": "تبلیغات را غیرممکن کنید که از دست ندهید، با تصاویری جسورانه و توضیحات فریبنده توجه را به خود جلب کنید.",
    "marketplace": "بازار",
    "marketplace_description": "ما در حال ایجاد بازاری با بیش از 2000 بازدیدکننده روزانه هستیم. اکنون سوار شوید، و در هنگام راه‌اندازی اولین صف خواهید بود. می توانید به مشتریان جدیدی دسترسی پیدا کنید که فعالانه به دنبال گزینه های غذاخوری هستند. محل خود را زودتر ایمن کنید تا نوردهی را به حداکثر برسانید!",
    "see_other_products": "سایر محصولات را ببینید",
    "about_us": "درباره ما",
    "why_serving_free": "چرا ما در حال خدمت این تا برای",
    "serving_free_highlight": "رایگان",
    "about_desc": "ما مردم محلی همین جا در دالاس هستیم و متعهد هستیم که به رستوران‌هایی مانند شما کمک کنیم تا پیشرفت کنند.",
    "about_tool_free": "ما این ابزار قدرتمند را کاملا رایگان ارائه می کنیم زیرا به حمایت از جامعه خود معتقدیم.",
    "no_catch": "نه شکار، نه شوخی. ما می دانیم که وقتی موفق می شوید، جامعه ما قوی تر می شود. 😊",
    "understanding_challenges": "ما چالش‌های اداره یک کسب‌وکار را درک می‌کنیم، به همین دلیل است که به ارائه ابزارهایی اختصاص داده شده‌ایم که عملیات شما را روان‌تر و دسترسی شما را وسیع‌تر می‌کنند.",
    "community_growth": "همانطور که شما رشد می کنید، جامعه ما با شما رشد می کند و یک اثر موجی از موفقیت و حمایت ایجاد می کند. بیایید با هم تجربه غذا خوردن را برای همه ارتقا دهیم.",
    "how_it_works": "چگونه کار می کند",
    "three_simple_steps": "سه گام ساده برای",
    "get_started_highlight": "شروع کنید",
    "no_tech_skills_needed": "بدون نیاز به مهارت های فنی اگر می توانید برگر را برگردانید، می توانید این کار را انجام دهید!",
    "step1_title": "تبلیغات خود را به صورت رایگان نمایش دهید!",
    "step1_desc": "سریعتر از آن چیزی که بتوانید بگویید \"سفارش دهید!\" فقط یک فرم ساده را پر کنید.",
    "step2_title": "منوی خود را سفارشی کنید",
    "step2_desc": "غذاهای خود را اضافه کنید، عکس ها را حتی به صورت سه بعدی آپلود کنید و موارد ویژه خود را برجسته کنید.",
    "step3_title": "به اشتراک بگذارید و رشد کنید",
    "step3_desc": "منوی دیجیتال خود را از طریق QR، رسانه های اجتماعی یا وب سایت خود به اشتراک بگذارید.",
    "testimonials": "گواهینامه ها",
    "testimonials_section_title": "فقط حرف ما را قبول نکنید",
    "testimonials_section_desc": "ببینید دیگر صاحبان رستوران دالاس چه می گویند.",
    "faq_section_title": "سوالاتی دارید؟ ما جواب هایی داریم",
    "faq_section_desc": "همه چیزهایی که قبل از شروع باید بدانید.",
    "faqs": "سوالات متداول",
    "faq_answers": [
        {
            "que": "آیا واقعا برای همیشه رایگان است؟",
            "ans": "قطعا! بدون کارمزد پنهان، بدون کارت اعتباری، بدون رشته متصل شده است."
        },
        {
            "que": "آیا به دانش فنی نیاز دارم؟",
            "ans": "اگر می توانید یک ایمیل بفرستید، می توانید این کار را انجام دهید. به همین سادگی است."
        },
        {
            "que": "چرا این را رایگان ارائه می دهید؟",
            "ans": "ما معتقدیم که اول دادن. در ادامه، ممکن است خدمات دیگری داشته باشیم که شما دوست داشته باشید — اما این کاملاً به شما بستگی دارد."
        },
        {
            "que": "معامله با بازار چیست؟",
            "ans": "ما در حال پختن آن هستیم! هنگامی که تعداد کافی رستوران در کشتی داشته باشیم، بازار شما را راه اندازی می کنیم تا بیشتر در معرض دید شما قرار بگیریم."
        }
    ],
    "testimonials_content1": "تغییر به منوی دیجیتال بهترین حرکتی بود که انجام دادیم! مشتریان ما راحتی و ظاهر به روز را دوست دارند!",
    "testimonials_name1": "موریس جورج",
    "testimonials_restaurant_name1": "رستوران عمارت",
    "testimonials_content2": "منوی دیجیتال جدید ما مرور را برای مهمانان آسان‌تر می‌کند و واقعاً تعامل را افزایش می‌دهد!",
    "testimonials_name2": "آملیا جانسون",
    "testimonials_restaurant_name2": "شهری می خورد",
    "testimonials_content3": "میهمانان از تصاویر سه بعدی شگفت زده می شوند - این به ما کمک کرد متمایز شویم و تجربه غذاخوری آنها را افزایش دهیم!",
    "testimonials_name3": "دیویس گرین",
    "testimonials_restaurant_name3": "آتشگاه شهر",
    "ready_to_boost_your_restaurant": "برای تقویت رستوران خود آماده اید؟",
    "ready_to_boost_desc": "عقب نمانید امروز به انقلاب دیجیتال بپیوندید!",
    "yes_i_want_my_free_digital_menu": "بله، من منوی دیجیتال رایگان خود را می خواهم",
    "480_plus": "480+",
    "people_already_joined": "افراد قبلاً ملحق شده اند",
    "menu_online_home": "منوی آنلاین صفحه اصلی",
    "welcome": "خوش آمدید",
    "description_other": "بیایید رضایت مشتری خود را افزایش دهیم!",
    "benefits": "مزایا",
    "greeting_text": "خوش آمدید 👋",
    "all_in_one_features": "🚀 ویژگی های All-in-One برای ارتقای رستوران شما",
    "menu_online_menu_name": "منوی آنلاین",
    "menu_online_description": "منوی خود را برای دسترسی آسان آنلاین مشتریان دیجیتالی کنید.",
    "menu_online_benefits": "با اجازه دادن به آنها برای مشاهده منوی شما در هر زمان و هر مکان، راحتی مشتری را افزایش دهید و تعامل را افزایش دهید.",
    "ds_name": "تابلوهای دیجیتال",
    "ds_description": "پیشنهادات و تبلیغات را در صفحه نمایش های پویا در فروشگاه به نمایش بگذارید.",
    "ds_benefits": "توجه مشتریان را با تصاویر بصری جذاب، ترویج فروش بیشتر و پیشنهادهای بی‌درنگ به خود جلب کنید.",
    "loyalty_punch_name": "کارت پانچ وفاداری",
    "loyalty_punch_description": "به مشتریان تکراری با یک برنامه وفاداری دیجیتال پاداش دهید.",
    "loyalty_punch_benefits": "با تشویق وفاداری با پاداش‌های قابل بازخرید برای بازدیدهای مکرر، حفظ مشتری را افزایش دهید و فروش را افزایش دهید.",
    "marketing_menu_name": "بازاریابی",
    "marketing_description": "با کمپین های بازاریابی دیجیتال هدفمند به مشتریان بیشتری دست پیدا کنید.",
    "marketing_benefits": "با تبلیغات سفارشی که برای جذب مشتریان جدید و مکرر طراحی شده اند، دسترسی رستوران خود را افزایش دهید و ترافیک پیاده روی کنید.",
    "get_more_reviews_menu_name": "دریافت نظرات بیشتر",
    "get_more_reviews_description": "مشتریان راضی را تشویق کنید تا نظرات مثبت آنلاین خود را به جای بگذارند.",
    "get_more_reviews_benefits": "با افزایش اعتبار آنلاین خود با نظرات مثبت بیشتر، اعتبار رستوران خود را افزایش دهید و مهمانان جدید را جذب کنید.",
    "guest_smart_wifi_menu_name": "وای فای هوشمند مهمان",
    "guest_smart_wifi_description": "ارائه Wi-Fi رایگان برای گرفتن اطلاعات مهمان برای بازاریابی.",
    "guest_smart_wifi_benefits": "افزایش رضایت مشتری در حین جمع آوری داده های ارزشمند مشتری برای شخصی سازی تبلیغات آینده.",
    "reservation_menu_name": "رزرو",
    "reservation_description": "به مشتریان اجازه دهید تا به راحتی میز را به صورت آنلاین رزرو کنند.",
    "reservation_benefits": "با به حداقل رساندن زمان انتظار، تجربه غذاخوری را بهبود بخشید و برای مدیریت بهتر صندلی، رزروها را ساده کنید.",
    "pos_integration_menu_name": "یکپارچه سازی POS",
    "pos_integration_description": "سیستم ما را با سیستم POS خود یکپارچه کنید.",
    "pos_integration_benefits": "با همگام نگه داشتن سیستم های آنلاین و درون فروشگاهی خود، پردازش سفارش را ساده کنید و خطاها را کاهش دهید.",
    "online_ordering_menu_name": "سفارش آنلاین",
    "online_ordering_description": "به مشتریان امکان دهید مستقیماً از وب سایت یا برنامه شما سفارش دهند.",
    "online_ordering_benefits": "با ارائه گزینه سفارش مستقیم که باعث صرفه جویی در هزینه ها می شود، فروش را افزایش دهید و اتکا به سیستم عامل های شخص ثالث را کاهش دهید.",
    "pro_label": "حرفه ای",
    "coming_soon": "به زودی",
    "locked_label": "قفل شده است",
    "forever_label": "برای همیشه",
    "new_label": "جدید",
    "other_products_page_title": "سایر محصولات | منوی آنلاین",
    "other_products_description": "منوی سیاست حفظ حریم خصوصی آنلاین",
    "other_products_header_title": "سایر محصولات",
    "privacy_policy": {
        "title": "سیاست حفظ حریم خصوصی",
        "lastUpdated": "آخرین به روز رسانی: 01 جولای 2024",
        "introduction": {
            "header": "سیاست حفظ حریم خصوصی برای مشتریان جدید و موجود",
            "paragraph1": "نکات کلیدی زیر (از جمله مراجع بخش) فقط برای راحتی شما ارائه شده است و جایگزین شرایط و ضوابط کامل نیست. خواندن این شرایط و ضوابط قبل از استفاده از خدمات MENUONLINE INC مسئولیت شماست.",
            "paragraph2": "استفاده از Menuonline به این معنی است که شما با شرایط و ضوابط موافقت می کنید و نشان می دهید و تضمین می کنید که توسط سازمان یا شرکت خود مجاز به انجام این کار هستید. (نگاه کنید به بخش 3 \"چگونه کار می کند\")",
            "paragraph3": "Menuonline Inc یک سرویس بازاریابی مبتنی بر مجوز است. ارسال عمدی \"هرزنامه\" یا نقض شرایط و ضوابط منجر به بسته شدن حساب شما می شود. (به بخش 16 «استفاده قابل قبول از سرویس» مراجعه کنید)",
            "paragraph4": "داده هایی که در Menuonline Inc میزبانی می کنید مانند سوابق تماس، محتوای ایمیل و اطلاعاتی که از طریق Menuonline Inc جمع آوری می کنید متعلق به شما است. (به بخش 14 «چه چیزی دارید؟» مراجعه کنید.",
            "paragraph5": "نرم افزار Menuonline Inc و محتوای وب سایت ما متعلق به ما است. (به بخش 13 \"مالک ما چیست؟\" مراجعه کنید.) جدا از کاربران خود، داده ها یا محتوای منتشر نشده ای که با Menuonline Inc میزبانی می کنید فقط توسط کارمندان Menuonline Inc و شرکای قابل مشاهده است که قراردادهای محرمانه را امضا کرده اند. (ص 14 را ببینید \"چه چیزی دارید؟\")",
            "paragraph6": "تمام اطلاعات شناسایی شخصی (PII) فقط در سرورهای واقع در کانادا نگهداری می شود. (به بخش 14 «چه چیزی دارید؟» مراجعه کنید.",
            "paragraph7": "خدمات Menuonline Inc تابع قوانین کانادا و استان انتاریو است. (به بخش 24 «قانون حاکم» مراجعه کنید)",
            "paragraph8": "هر گونه درخواست برای داده ها یا محتوای شما توسط افراد، پلیس یا سایر سازمان های نظارتی از داخل کانادا یا خارج از کانادا همیشه به شما ارجاع داده می شود. تنها استثنا در صورتی است که ما را در نقض قوانین کانادا یا انتاریو قرار دهد. با این حال، در همه موارد، ما شدیداً در برابر هرگونه انتشار داده‌های شما توسط هر کسی غیر از شما مقاومت خواهیم کرد. (به بخش 14 «چه چیزی دارید؟» مراجعه کنید.",
            "paragraph9": "امنیت Menuonline Inc فقط به اندازه مدیریت نام کاربری و رمز عبور شما خوب است. ما برای حساب های کاربری Menuonline Inc هزینه ای دریافت نمی کنیم، بنابراین یک حساب جداگانه برای هر کاربر ایجاد کنید و رمزهای عبور را مخفی نگه دارید. شما مسئول حساب خود هستید. (نگاه کنید به بخش 3 \"چگونه کار می کند\")",
            "paragraph10": "در هر زمانی می توانید حساب خود را لغو کنید. در صورت لغو، ما هیچ اعتبار استفاده نشده ای را بازپرداخت نمی کنیم. (نگاه کنید به بخش 3 \"چگونه کار می کند\")"
        },
        "termsAndConditions": {
            "title": "شرایط و ضوابط MENUONLINE INC",
            "lastUpdate": "آخرین به روز رسانی: 14 ژانویه 2020",
            "paragraph1": "لطفاً قبل از استفاده از این وب سایت («سایت») این شرایط و ضوابط خدمات («شرایط») را به دقت بخوانید. این شرایط Menuonline Inc. (\"Menuonline Inc\" \"ما\" یا \"ما\") و دیگران را از مسئولیت معاف می کند و\/یا مسئولیت ما و آنها را محدود می کند و شامل سایر مقررات مهمی است که در مورد استفاده شما از این سایت اعمال می شود. با بازدید یا استفاده از این سایت یا سرویس، از طرف خود، و از طرف هر سازمانی که از طرف آن می‌توانید اقدام کنید (که در اینجا به آن «شما» گفته می‌شود) موافقت می‌کنید که این شرایط را برای هر استفاده و هر بازدید از این سایت بپذیرید و از آن پیروی کنید."
        },
        "services": {
            "title": "خدمات ما",
            "paragraph": "خدماتی که ما از طریق سایت ارائه می‌دهیم، سرویسی است که به شما امکان می‌دهد پیام‌های ایمیل (\"ایمیل\") را برای گیرندگان انتخابی خود (\"سرویس\") ایجاد، ارسال و مدیریت کنید."
        },
        "license": {
            "title": "مجوز.",
            "paragraph": "با توجه به این شرایط، Menuonline Inc بدینوسیله به شما مجوزی غیر انحصاری، محدود و غیرقابل انتقال در طول مدت برای مشاهده سایت و استفاده از سرویس آنگونه که ویژگی‌های سرویس مجاز است به شما می‌دهد. ما تمام حقوقی را که در این قسمت در سرویس و محتوای Menuonline Inc (همانطور که در زیر تعریف شده است) اعطا نشده است محفوظ می داریم."
        },
        "emailingContent": {
            "title": "ارسال محتوای ایمیل",
            "paragraph": "هنگام تعامل با Menuonline Inc از طریق این سایت یا سرویس، با ارائه اطلاعات واقعی و دقیق موافقت می کنید. علاوه بر این، می‌دانید که ارائه اطلاعات محرمانه از طریق ایمیل یک عمل ناامن است. شما می‌دانید که مسئولیت هر چیزی که برای هر کسی که از سرویس ما استفاده می‌کند، بر عهده شماست."
        },
        "support": {
            "title": "پشتیبانی کنید.",
            "paragraph": "ما از شما پشتیبانی می کنیم تا به شما کمک کنیم تا با خدمات خودکفا شوید. ما به شما در قالب مستندات و فیلم ها در سایت، سیستم بلیط پشتیبانی Menuonline Inc و تلفنی پشتیبانی می کنیم. با کلیک بر روی برگه \"پشتیبانی\" در بالای سایت می توانید با ما تماس بگیرید. هیچ هزینه ای برای پشتیبانی محصول وجود ندارد. با این حال، ما این حق را برای خود محفوظ می داریم که بنا به صلاحدید خود، پشتیبانی از حساب های مشتری خاص را متوقف کنیم."
        }
    },
    "terms_condition": {
        "title": "شرایط خدمات | منوی آنلاین",
        "header": "شرایط و ضوابط برای مشتریان جدید و موجود"
    },
    "privacy_policy_title": "سیاست خصوصی",
    "privacy_policy_effective_date": "از 16 ژانویه 2020 قابل اجرا است",
    "privacy_policy_general_title": "سیاست خصوصی عمومی",
    "privacy_policy_intro": "menuonline.com (Menuonline و \"ما\") اهمیت حفاظت از حریم خصوصی اطلاعات شخصی افراد و اهمیت امنیت اطلاعات محرمانه از همه نوع را درک می کند. ما این خط مشی خصوصی (\"خط مشی\") را ایجاد کرده ایم تا تعهد خود را به محافظت از \"حریم خصوصی\" شما نشان دهیم. این خط مشی خصوصی توضیح می دهد:",
    "privacy_policy_points": [
        "چه اطلاعات شخصی را جمع آوری می کنیم و چرا آنها را جمع آوری می کنیم.",
        "نحوه استفاده ما از اطلاعات شخصی شما؛",
        "اطلاعات شخصی شما را با چه کسانی به اشتراک می گذاریم؛ و",
        "انتخاب هایی که برای دسترسی، به روز رسانی و حذف اطلاعات شخصی خود از سرویس دارید."
    ],
    "privacy_policy_pipeda": "Menuonline مجموعه تمام اطلاعات را مطابق با قانون حفاظت از اطلاعات شخصی و اسناد الکترونیکی کانادا (PIPEDA) مدیریت می کند.",
    "privacy_policy_update_notice": "لطفاً توجه داشته باشید که Menuonline این حق را برای خود محفوظ می دارد که هر زمان که بخواهید این خط مشی خصوصی را به روز کند یا تغییر دهد. ما با ارسال یک خط‌مشی به‌روز شده در این وب‌سایت یا همانطور که از طریق خدمات در دسترس است، شما را از هرگونه تغییر مطلع خواهیم کرد. جدیدترین نسخه این خط‌مشی استفاده mycircle از اطلاعات شخصی کاربران را کنترل می‌کند. با ادامه دسترسی به وب‌سایت mycircle یا ادامه استفاده از خدمات آن پس از ایجاد تغییرات در این خط‌مشی خصوصی، موافقت می‌کنید که به شرایط اصلاح شده پایبند باشید.",
    "privacy_policy_consent_title": "رضایت برای اطلاعات شخصی",
    "privacy_policy_consent_text": "با ارائه اطلاعات شخصی خود به Menuonline از طریق وب سایت ما یا هر یک از فرم های ما، با جمع آوری، استفاده و افشای چنین اطلاعاتی مطابق با این خط مشی یا برای اهدافی که در زمان ارائه اطلاعات شخصی برای شما مشخص شده است، موافقت می کنید. هرگونه استفاده ثانویه از اطلاعات شخصی فقط با رضایت صریح شما پردازش می شود، یا فرصتی برای انصراف در اختیار شما قرار می گیرد.",
    "privacy_policy_opt_out_text": "شما این فرصت را دارید که رضایت خود را پس بگیرید یا هر زمان که بخواهید انصراف دهید. اگر می‌خواهید رضایت خود را پس بگیرید یا انصراف دهید، می‌توانید روی پیوند تنظیمات برگزیده ارتباطات در پایین هر ایمیلی که از ما دریافت می‌کنید کلیک کنید.",
    "privacy_policy_info_collection_title": "اطلاعاتی که ما جمع آوری می کنیم",
    "privacy_policy_info_collection_text": "Menuonline اطلاعات شخصی را مطابق با این خط مشی جمع آوری و پردازش می کند. ما این اطلاعات را به دیگران نمی فروشیم، به اشتراک نمی گذاریم، یا اجاره نمی دهیم، مگر در مواردی که در این خط مشی ذکر شده است. منوآنلاین اطلاعات شخصی را به منظور ارائه خدمات یا محصولات خود به شما جمع آوری می کند.",
    "privacy_policy_info_types_text": "انواع اطلاعاتی که Menuonline جمع آوری می کند عبارتند از:",
    "privacy_policy_account_holders_title": "دارندگان حساب منوآنلاین",
    "privacy_policy_registration_info": "اطلاعات ثبت نام: اگر برای استفاده از خدمات ثبت نام می کنید، برای داشتن یک حساب کاربری (\"دارنده حساب\") به یک حساب Menuonline نیاز دارید. هنگامی که برای یک حساب ثبت نام می کنید، از شما خواسته می شود اطلاعات اولیه خاصی مانند نام، آدرس ایمیل، نام کاربری، رمز عبور، نام شرکت، شغل، موقعیت مکانی و شماره تلفن خود را ارائه دهید. گذرواژه‌ها رمزگذاری شده‌اند - کارکنان Menuonline قادر به مشاهده یا بازیابی رمزهای عبور نیستند.",
    "privacy_policy_billing_info": "اطلاعات صورتحساب: اگر مشترک خدمات ما هستید، باید اطلاعات پرداخت و صورتحساب را در اختیار ما قرار دهید.",
    "privacy_policy_cookies_info": "کوکی ها: ما از کوکی ها برای ردیابی داده های بازدیدکنندگان مانند منبع بازدید از وب سایت و تعداد بازدید از وب سایت در وب سایت خود استفاده می کنیم.",
    "privacy_policy_ip_info": "آدرس‌های IP: آدرس‌های IP در مورد شما به عنوان بخشی از استفاده شما از خدمات جمع‌آوری می‌شوند (مثلاً به منظور ثبت موقعیت مکانی در سطح کشور و آدرس‌های IP مورد استفاده در هنگام استفاده از خدمات ما).",
    "privacy_policy_your_contacts_title": "مخاطبین شما",
    "privacy_policy_distribution_list": "فهرست توزیع: به عنوان بخشی از استفاده شما از خدمات ما، ممکن است اطلاعات شخصی مخاطبین شما (\"مخاطبین\") را به ما ارائه دهید، یا ممکن است به عنوان بخشی از خدمات جمع آوری کنیم. این اطلاعات شامل آدرس‌های ایمیل است و ممکن است شامل اطلاعات قابل شناسایی دیگری مانند نام، نام شرکت یا سایر اطلاعاتی باشد که هنگام استفاده از خدمات به Menuonline ارائه می‌دهید. شما باید اطمینان حاصل کنید که رضایت مناسب را از مخاطبین خود دریافت کرده اید تا اجازه دهید اطلاعات آنها توسط ما پردازش شود.",
    "privacy_policy_billing_info_contacts": "اطلاعات صورتحساب: اگر مشترک خدمات ما هستید، باید اطلاعات پرداخت و صورتحساب را در اختیار ما قرار دهید.",
    "privacy_policy_cookies_contacts": "کوکی‌ها: Menuonline تنها در صورتی از کوکی‌ها و فناوری‌های ردیابی مشابه برای جمع‌آوری و استفاده از اطلاعات شخصی مخاطبین خود استفاده می‌کند که کد رهگیری Menuonline را به وب‌سایت خود اضافه کرده باشید. هر صفحه فرود که توسط Menuonline میزبانی می شود به طور خودکار دارای کد رهگیری خواهد بود.",
    "privacy_policy_ip_contacts": "آدرس‌های IP: آدرس‌های IP درباره مخاطبین شما به‌عنوان بخشی از خدمات جمع‌آوری می‌شوند (مثلاً به منظور ثبت موقعیت مکانی در سطح کشور و آدرس‌های IP مورد استفاده هنگام ارسال فرم و ارائه رضایت).",
    "privacy_policy_info_about_contacts_title": "اطلاعاتی که ما در مورد تماس های شما جمع آوری می کنیم",
    "privacy_policy_info_about_contacts_notice": "*این بخش فقط برای اطلاعات جمع آوری، استفاده یا افشا شده توسط Menuonline در مورد مخاطبین صاحب حساب برای اهداف ارائه خدمات اعمال می شود*",
    "privacy_policy_info_about_contacts_desc": "Menuonline تنها در صورتی اطلاعات شخصی مخاطبین را جمع‌آوری، استفاده یا افشا می‌کند که دارنده حساب برای خدمات خاصی ثبت نام کرده باشد، جایی که فهرست توزیع را در اختیار ما قرار دهد. دارندگان حساب صرفاً مسئول اطمینان از رعایت کلیه قوانین قابل اجرا در هنگام ارائه اطلاعات شخصی مخاطبین خود به Menuonline از طریق خدمات هستند.",
    "privacy_policy_info_auto_collect_title": "اطلاعات Menuonline به طور خودکار جمع آوری می شود",
    "privacy_policy_info_auto_collect_desc1": "هنگامی که مخاطبین دارنده حساب با یک کمپین ایمیل دریافتی از یک دارنده حساب درگیر می شوند، یا با پیوندی به وب سایت متصل دارنده حساب تعامل دارند، Menuonline ممکن است اطلاعات مربوط به دستگاه این مخاطب و تعامل با ایمیل را از طریق استفاده از کوکی ها جمع آوری کند. این اطلاعات فقط برای ارائه خدمات و ارائه بینش به صاحب حساب جمع آوری می شود.",
    "privacy_policy_info_auto_collect_desc2": "اطلاعاتی که ما در مورد دستگاه مخاطب و برنامه‌هایی که مخاطب برای دسترسی به ایمیل‌های ارسال شده از دارنده حساب از طریق خدمات خود استفاده می‌کند، جمع‌آوری می‌کنیم، ممکن است شامل آدرس IP مخاطب، سیستم عامل، شناسه مرورگر و سایر اطلاعات مرتبط درباره سیستم و اتصال مخاطب باشد.",
    "privacy_policy_info_auto_collect_desc3": "اطلاعاتی که در مورد تعامل مخاطب با ایمیل‌های ارسال شده از طریق سرویس‌ها جمع‌آوری می‌کنیم ممکن است شامل تاریخ‌ها و زمان‌هایی باشد که مخاطب به ایمیل و فعالیت‌های مرور دسترسی پیدا می‌کند، و اطلاعات مربوط به عملکرد سرویس‌ها، مانند قابلیت تحویل ایمیل‌ها و سایر ارتباطاتی که توسط دارنده حساب از طریق خدمات به مخاطب ارسال می‌شود.",
    "privacy_policy_info_auto_collect_desc4": "لطفاً توجه داشته باشید، Menuonline با هیچ یک از مخاطبین شما تماس نخواهد گرفت، مگر اینکه طبق قانون قابل اجرا الزامی باشد.",
    "privacy_policy_why_we_collect_title": "چرا ما اطلاعات شما را جمع آوری می کنیم",
    "privacy_policy_why_we_collect_desc": "Menuonline اطلاعات را برای اهداف زیر جمع آوری می کند:",
    "privacy_policy_why_collect_1": "برای ارائه خدماتی که برای دارندگان حساب منوآنلاین مشترک هستند، از جمله استفاده از اطلاعات دارنده حساب برای خدمات؛",
    "privacy_policy_why_collect_2": "برای مدیریت و مدیریت حساب، و همچنین دسترسی به وب سایت mycircle؛",
    "privacy_policy_why_collect_3": "در صورتی که با دریافت مطالب بازاریابی و تبلیغاتی موافقت کرده اید، از سایر محصولات و خدمات ارائه شده توسط منوآنلاین مطلع شوید. و",
    "privacy_policy_why_collect_4": "برای پاسخ به ارتباطات صاحب حساب یا ارسال اطلاعات درخواستی شما. این ممکن است شامل، اما محدود به موارد زیر نباشد:",
    "privacy_policy_why_collect_5": "پیشنهادات ویژه و به روز رسانی؛",
    "privacy_policy_why_collect_6": "خبرنامه؛ و",
    "privacy_policy_why_collect_7": "اطلاعیه های خدمات",
    "privacy_policy_opt_out_desc": "می‌توانید در هر زمان، با کلیک روی دکمه لغو اشتراک در پایین هر ایمیلی که از Menuonline برای شما ارسال می‌شود، از دریافت ارتباطات انصراف دهید.",
    "privacy_policy_use_disclosure_title": "استفاده و افشای اطلاعات شخصی",
    "privacy_policy_use_disclosure_desc1": "اطلاعات شخصی ارسال شده به Menuonline، از جمله از طریق وب سایت Menuonline یا یک فرم تجویز شده، کاملاً محرمانه نگهداری می شود مگر اینکه مطابق با رضایت صریح صاحب حساب یا الزامات قانونی افشا شود. در هر صورت، هر گونه اطلاعات شخصی ارائه شده در هر پلتفرم «Menuonline» توسط دارندگان حساب، داوطلبانه است: هر گونه اطلاعات شخصی ارائه شده توسط دارنده حساب به صلاحدید دارنده حساب ارسال می شود. لطفاً توجه داشته باشید که لغو رضایت شما ممکن است منجر به عدم دسترسی یا استفاده از برخی خدمات Menuonline شود.",
    "privacy_policy_use_disclosure_desc2": "منوآنلاین با توجه به وب سایت و خدمات خود از اشخاص ثالث و ارائه دهندگان خدمات خارجی استفاده می کند. اطلاعات شخصی دارنده حساب ممکن است با این اشخاص ثالث و ارائه دهندگان به اشتراک گذاشته شود. ما همچنین ممکن است آمار جمع آوری شده در مورد دارندگان حساب، فروش، الگوهای ترافیک و اطلاعات مرتبط را به اشخاص ثالث معتبر ارائه دهیم. Menuonline ایجاب می کند که هر ارائه دهنده شخص ثالثی که به اطلاعات شخصی دسترسی دارد، متعهد به تعهدات منطبق با این خط مشی خصوصی باشد و این ارائه دهندگان فقط اطلاعات شخصی دارنده حساب را برای اهداف ارائه خدمات Menuonline جمع آوری، استفاده یا افشا کنند.",
    "privacy_policy_use_disclosure_desc3": "اطلاعات شخصی ارائه شده توسط دارندگان حساب به سازمان های خارجی فروخته، به اشتراک گذاشته یا اجاره نمی شود مگر اینکه مطابق با رضایت ضمنی یا صریح شما باشد. Menuonline این حق را برای خود محفوظ می دارد که با دارندگان حساب به منظور ارسال اطلاعات، پاسخ به سؤالات یا ارائه اعلان در مورد هر گونه تغییر در برنامه یا خط مشی های ما، تماس بگیرد. لطفاً توجه داشته باشید، Menuonline با هیچ یک از مخاطبین شما تماس نخواهد گرفت، مگر اینکه طبق قانون قابل اجرا الزامی باشد.",
    "privacy_policy_use_disclosure_desc4": "منوآنلاین تنها در صورتی اطلاعات شخصی را فاش می کند که رضایت فرد مربوطه داده شود یا:",
    "privacy_policy_disclosure_list_1": "هنگامی که توسط قانون مجاز یا مورد نیاز است، مانند پاسخ به احضاریه یا سایر مراحل قانونی؛",
    "privacy_policy_disclosure_list_2": "به انتقال گیرنده تمام یا بخشی از منوآنلاین؛",
    "privacy_policy_disclosure_list_3": "به ارائه دهندگان خدمات Menuonline مطابق با این خط مشی؛ یا",
    "privacy_policy_disclosure_list_4": "برای انجام تعهدات قانونی، از جمله اما نه محدود به تعهدات گزارشگری نظارتی.",
    "privacy_policy_service_providers_title": "Menuonline از اشخاص ثالث و ارائه دهندگان خدمات زیر استفاده می کند (اطلاعات شخصی مخاطبین شما در اختیار \"چنین\" اشخاص ثالث قرار نمی گیرد):",
    "privacy_policy_service_provider_1": "ما از ManyChat برای ارائه خدمات چت وب سایت برای فروش و پشتیبانی و همچنین ایمیل های بعدی درباره این مکالمات استفاده می کنیم.",
    "privacy_policy_service_provider_2": "ما از AskNicely برای نظرسنجی از مشتریان خود استفاده می کنیم تا در مورد کیفیت خدمات و پشتیبانی آنها بپرسیم.",
    "privacy_policy_service_provider_3": "ما از join.me برای برنامه‌ریزی جلسات مشتریان و مشتریان احتمالی، تماس‌های پشتیبانی و نمایش‌های نرم‌افزاری استفاده می‌کنیم.",
    "privacy_policy_service_provider_4": "ما از Google Analytics برای ردیابی فعالیت وب سایت ناشناس و اندازه گیری الگوهای استفاده در وب سایت خود استفاده می کنیم.",
    "privacy_policy_breach_notification_title": "اطلاعیه نقض",
    "privacy_policy_breach_notification_desc": "Menuonline باید با رعایت قوانین قابل اجرا در رابطه با هرگونه استفاده، دسترسی یا افشای غیرمجاز از اطلاعات شخصی، با دارندگان حساب مطابقت داشته باشد و به آنها کمک منطقی ارائه دهد.",
    "privacy_policy_rights_access_title": "حقوق و دسترسی شما",
    "privacy_policy_rights_access_desc": "شما حق دسترسی به اطلاعات شخصی را دارید که Menuonline درباره شما پردازش می کند. شما می توانید از ما اطلاعاتی در مورد:",
    "privacy_policy_rights_access_list_1": "اطلاعات شخصی که در مورد شما نگهداری می کنیم",
    "privacy_policy_rights_access_list_2": "دسته بندی اطلاعات شخصی مربوطه",
    "privacy_policy_rights_access_list_3": "اهداف پردازش",
    "privacy_policy_rights_access_list_4": "جزئیاتی که اطلاعات شخصی شما در اختیار چه کسانی قرار می گیرد\/افشا خواهد شد",
    "privacy_policy_rights_access_list_5": "چه مدت اطلاعات شخصی شما را حفظ می کنیم",
    "privacy_policy_rights_access_list_6": "اگر ما اطلاعات را مستقیماً از شما جمع آوری نکردیم، اطلاعات مربوط به منبع",
    "privacy_policy_rights_access_list_7": "نحوه ثبت شکایت نزد مرجع نظارتی صحیح",
    "privacy_policy_rights_access_list_8": "همچنین می توانید موارد زیر را از ما درخواست کنید:",
    "privacy_policy_rights_access_list_9": "که ما هر گونه اطلاعات ناقص یا نادرست در مورد شما را به روز می کنیم",
    "privacy_policy_rights_access_list_10": "از ما بخواهید که اطلاعات شخصی شما را مطابق با قوانین قابل اجرا حذف کنیم",
    "privacy_policy_rights_access_contact": "شما می‌توانید با تماس با ما در 2851 Dufferin Street, Toronto, ON, Canada یا با ایمیل زدن به ما در {link} از ما درخواست کنید که حقوق شما را بررسی کنیم.",
    "privacy_policy_accountability_title": "پاسخگویی",
    "privacy_policy_accountability_desc": "منوآنلاین متعهد است در قبال اطلاعات شخصی و محرمانه ای که در اختیار ما قرار می دهید پاسخگو باشد. برای اطلاعات تماس، لطفاً به انتهای این خط مشی مراجعه کنید.",
    "privacy_policy_links_other_sites_title": "پیوند به سایت های دیگر",
    "privacy_policy_links_other_sites_desc1": "وب سایت Menuonline ممکن است حاوی پیوندهایی به سایت های دیگر باشد. لطفا توجه داشته باشید که منوآنلاین مسئولیتی در قبال اقدامات حفظ حریم خصوصی سایر سایت ها ندارد. ما دارندگان حساب خود را تشویق می‌کنیم که بدانند وقتی سایت ما را ترک می‌کنند، باید بیانیه‌های خط‌مشی خصوصی هر وب‌سایتی را که اطلاعات شناسایی شخصی را جمع‌آوری می‌کند، به‌طور کامل مطالعه کنند.",
    "privacy_policy_links_other_sites_desc2": "این سیاست خصوصی صرفاً در مورد اطلاعات جمع آوری شده توسط ما اعمال می شود.",
    "privacy_policy_links_other_sites_desc3": "اگر از لینکی برای رفتن از سایت منوآنلاین برای خرید محصولات یا خدمات استفاده می کنید، تراکنش خرید را طبق قوانین و مقررات شرکت فروشنده انجام می دهید نه منوآنلاین.",
    "privacy_policy_cookies_title": "کوکی های سیاست خصوصی",
    "privacy_policy_cookies_desc1": "وب سایت و خدمات منوآنلاین از کوکی ها برای جمع آوری و ذخیره اطلاعات خاص مطابق با این سیاست استفاده می کند. \"کوکی\" یک فایل متنی کوچک است که از یک وب سایت ارسال می شود و توسط مرورگر وب کاربر در رایانه کاربر ذخیره می شود. پس از بازدید از سایتی که از کوکی ها استفاده می کند، یک کوکی بر روی رایانه یا دستگاه تلفن همراه شما دانلود می شود. دفعه بعد که از آن سایت بازدید می کنید، دستگاه شما اطلاعات مفیدی مانند تنظیمات برگزیده، صفحات بازدید شده یا گزینه های ورود به سیستم را به خاطر می آورد.",
    "privacy_policy_cookies_desc2": "کوکی ها به طور گسترده ای برای افزودن قابلیت به وب سایت ها یا اطمینان از کارآمدتر کارکرد آنها استفاده می شود. سایت ما برای بهینه سازی تجربه کاربر و اطمینان از عملکرد صحیح خدمات سایت، به کوکی ها متکی است.",
    "privacy_policy_cookies_desc3": "اکثر مرورگرهای وب به برخی از کنترل‌ها اجازه می‌دهند تا کوکی‌ها را از طریق تنظیمات مرورگر محدود یا مسدود کنند، اما اگر کوکی‌ها را غیرفعال کنید ممکن است متوجه شوید که این روی توانایی شما برای استفاده از بخش‌های خاصی از وب‌سایت یا خدمات ما تأثیر می‌گذارد.",
    "privacy_policy_cookies_analytical_purpose": "<strong> اهداف تحلیلی: <\/strong> ما از کوکی ها برای تجزیه و تحلیل فعالیت دارنده حساب به منظور بهبود وب سایت خود استفاده می کنیم. برای مثال، می‌توانیم از کوکی‌ها برای مشاهده الگوهای انبوهی مانند ویژگی‌هایی که دارندگان حساب استفاده می‌کنند، استفاده کنیم. ما می توانیم از چنین تجزیه و تحلیلی برای به دست آوردن بینشی در مورد چگونگی بهبود عملکرد و تجربه کاربری وب سایت خود استفاده کنیم.",
    "privacy_policy_cookies_preferences_user_experience": "<strong> تنظیمات برگزیده و تجربه کاربری شما: <\/strong> ما از کوکی‌ها برای جمع‌آوری اطلاعات خاصی در مورد بازدیدکنندگان و دارندگان حساب، مانند نوع مرورگر، سرور، اولویت زبان، و تنظیمات کشور استفاده می‌کنیم تا تنظیمات برگزیده دارنده حساب را در وب‌سایت خود ذخیره کنیم تا تجربه دارنده حساب سازگارتر و راحت‌تر باشد. هنگامی که به طور مکرر از وب سایت ما بازدید می کنید، از کوکی ها برای حفظ وضعیت ورود به سیستم شما استفاده می کنیم.",
    "privacy_policy_cookies_targeted_ads": "<strong> ارائه خدمات اندازه‌گیری و تبلیغات هدفمند بهتر و بازاریابی: <\/strong> ما از کوکی‌ها، چراغ‌های وب و سایر فناوری‌های ذخیره‌سازی شرکای شخص ثالث مانند Google و Facebook برای خدمات اندازه‌گیری، هدف‌یابی بهتر تبلیغات و اهداف بازاریابی استفاده می‌کنیم. این زمانی اتفاق می افتد که از وب سایت ما بازدید می کنید. این کوکی‌ها، چراغ‌های وب و سایر فناوری‌های ذخیره‌سازی به ما اجازه می‌دهند تا مطالب تبلیغاتی Menuonline را در سایت‌های دیگری که در سراسر اینترنت بازدید می‌کنید به شما نمایش دهیم.",
    "privacy_policy_security_title": "امنیت",
    "privacy_policy_security_desc1": "منوآنلاین تلاش می کند تا از اطلاعات محرمانه از همه نوع، از جمله اطلاعات شخصی، با حفاظت های سازمانی، فیزیکی، مکانیکی و الکترونیکی متناسب با حساسیت اطلاعات محافظت کند. سرورهای ما در کانادا واقع شده اند و از استانداردهای صنعت مربوط به امنیت پیروی می کنند.",
    "privacy_policy_security_desc2": "لطفاً توجه داشته باشید که ما نمی توانیم تضمین کنیم که اقداماتی که انجام می دهیم امنیت اطلاعات را تضمین می کند.",
    "privacy_policy_retention_title": "حفظ",
    "privacy_policy_retention_desc": "ما اطلاعات شخصی در مورد دارندگان حساب و مخاطبین را تا زمانی که برای اهداف تعیین شده در این خط مشی خصوصی و مطابق با مفاد حفظ در شرایط استفاده لازم است حفظ می کنیم.",
    "privacy_policy_access_questions_title": "دسترسی، پرسش ها و نگرانی ها",
    "privacy_policy_access_questions_desc": "فردی که اطلاعات شخصی خود را به منوآنلاین ارسال کرده است می تواند درخواست کتبی برای دسترسی یا تصحیح اطلاعات شخصی خود که توسط Menuonline حفظ شده است ارسال کند.",
    "terms_obj": {
        "terms_conditions_title": "شرایط و ضوابط",
        "terms_conditions_subtitle": "شرایط و ضوابط برای مشتریان جدید و موجود",
        "terms_conditions_desc1": "نکات کلیدی زیر (از جمله مراجع بخش) فقط برای راحتی شما ارائه شده است و جایگزین شرایط و ضوابط کامل نیست. خواندن این شرایط و ضوابط قبل از استفاده از خدمات منوآنلاین بر عهده شماست.",
        "terms_conditions_desc2": "استفاده از Menuonline به این معنی است که شما با شرایط و ضوابط موافقت می کنید و نشان می دهید و تضمین می کنید که توسط سازمان یا شرکت خود مجاز به انجام این کار هستید. (نگاه کنید به بخش 3 \"چگونه کار می کند\")",
        "terms_conditions_desc3": "Menuonline یک سرویس بازاریابی مبتنی بر مجوز است. ارسال عمدی \"هرزنامه\" یا نقض شرایط و ضوابط منجر به بسته شدن حساب شما می شود. (به بخش 16 «استفاده قابل قبول از سرویس» مراجعه کنید)",
        "terms_conditions_desc4": "داده هایی که در Menuonline میزبانی می کنید مانند سوابق تماس، محتوای ایمیل و اطلاعاتی که از طریق Menuonline جمع آوری می کنید متعلق به شما است. (به بخش 14 «چه چیزی دارید؟» مراجعه کنید.",
        "terms_conditions_desc5": "نرم افزار منوآنلاین و محتوای وب سایت ما متعلق به ماست. (به بخش 13 \"مالک ما چیست؟\") مراجعه کنید.",
        "terms_conditions_desc6": "جدا از کاربران خود، داده‌ها یا محتوای منتشر نشده‌ای که با Menuonline میزبانی می‌کنید فقط توسط کارمندان Menuonline و شرکای قابل مشاهده است که قراردادهای محرمانه امضا کرده‌اند. (به بخش 14 «چه چیزی دارید؟» مراجعه کنید.",
        "terms_conditions_desc7": "تمام اطلاعات شناسایی شخصی (PII) فقط در سرورهای واقع در کانادا نگهداری می شود. (به بخش 14 «چه چیزی دارید؟» مراجعه کنید.",
        "terms_conditions_desc8": "خدمات منوآنلاین تابع قوانین کانادا و استان انتاریو است. (به بخش 24 «قانون حاکم» مراجعه کنید)",
        "terms_conditions_desc9": "هر گونه درخواست برای داده ها یا محتوای شما توسط افراد، پلیس یا سایر سازمان های نظارتی از داخل یا خارج از کانادا همیشه به شما ارجاع داده می شود. تنها استثنا در صورتی است که ما را در نقض قوانین کانادا یا انتاریو قرار دهد. با این حال، در همه موارد، ما شدیداً در برابر هرگونه انتشار اطلاعات شما توسط هر کسی غیر از شما مقاومت خواهیم کرد. (ص 14 را ببینید \"چه چیزی دارید؟\").",
        "terms_conditions_desc10": "امنیت منوآنلاین فقط به اندازه مدیریت نام کاربری و رمز عبور شما است. ما برای حساب های کاربری Menuonline هزینه ای دریافت نمی کنیم، بنابراین یک حساب جداگانه برای هر کاربر ایجاد کنید و رمزهای عبور را مخفی نگه دارید. شما مسئول حساب خود هستید. (نگاه کنید به بخش 3 \"چگونه کار می کند\")",
        "terms_conditions_desc11": "در هر زمانی می توانید حساب خود را لغو کنید. در صورت لغو، ما هیچ اعتبار استفاده نشده ای را بازپرداخت نمی کنیم. (نگاه کنید به بخش 3 \"چگونه کار می کند\")",
        "terms_conditions_desc12": "ما می توانیم حساب شما را در هر زمان لغو کنیم. اگر حساب شما را لغو کنیم، اعتبارات ایمیل و خدمات استفاده نشده را بازپرداخت می کنیم. (نگاه کنید به بخش 3 \"چگونه کار می کند\")",
        "terms_conditions_desc13": "داده های شما پس از لغو حذف خواهند شد و همچنین ممکن است در صورتی که کاربر حساب کاربری به مدت 12 ماه یا بیشتر وارد حساب کاربری خود نشده باشد، حذف خواهد شد. ما داده های جمع آوری شده از حساب شما را حفظ خواهیم کرد که شامل اطلاعات شناسایی شخصی برای اهداف آماری نمی شود. (نگاه کنید به بخش 3 \"چگونه کار می کند\")",
        "terms_conditions_desc14": "اعتبارات ایمیل به صورت پرداختی خریداری می شوند و هرگز منقضی نمی شوند. تنها استثنا در صورتی است که به مدت 12 ماه به حساب خود وارد نشده باشید. در این صورت، ما این حق را برای خود محفوظ می داریم که حساب، داده های شما و اعتبارات مربوطه را حذف کنیم. (به بخش 10 «چه چیزی برای خدمات پرداخت کنم؟» مراجعه کنید.)",
        "terms_conditions_desc15": "ما سخت کار می کنیم تا اطمینان حاصل کنیم که Menuonline همیشه فعال است و در صورت نیاز به تعلیق موقت سرویس برای تعمیر و نگهداری، از قبل اطلاع رسانی می کنیم. با این حال، ما تضمین نمی‌کنیم که این سرویس همیشه در حال اجرا و راه‌اندازی باشد و مسئولیتی در قبال خساراتی که از استفاده از Menuonline متحمل می‌شوید، نداریم، از جمله ضررهایی که از جانب شما در صورت عدم اجرای سیستم در زمانی که شما به آن نیاز دارید. (به بخش 17 \"سلب مسئولیت\" مراجعه کنید).",
        "terms_conditions_desc16": "ما بر محتوای شما یا افرادی که به آنها پیام می‌فرستید نظارت نمی‌کنیم. با این حال، ما بر شکایات و سایر مشکلات ایجاد شده توسط فعالیت های شما نظارت می کنیم. شکایات یا مشکلات بیش از حد می تواند به صلاحدید ما منجر به لغو خدمات شما شود. (نگاه کنید به بخش 7 \"نظارت\")",
        "terms_conditions_desc17": "ما متعهد به ارائه پشتیبانی عالی از محصول هستیم تا به شما کمک کنیم با Menuonline خودکفا شوید. ما پشتیبانی را در قالب مستندات و ویدیو در وب سایت خود، سیستم بلیط پشتیبانی Menuonline و تلفنی ارائه می دهیم. هیچ هزینه ای برای پشتیبانی محصول وجود ندارد. با این حال، ما این حق را برای خود محفوظ می داریم که بنا به صلاحدید خود، پشتیبانی از حساب های مشتری خاص را متوقف کنیم. (به بخش 5 «پشتیبانی» مراجعه کنید)",
        "terms_conditions_desc18": "خدمات محصول برای مواقعی است که از ما می‌خواهید برخی از کارهای مربوط به Menuonline را انجام دهیم نه اینکه خودتان آن را انجام دهید. نرخ ما برای خدمات محصول 135 دلار در ساعت است. ما همچنین خدمات مشاوره ای را با قیمت های بالاتر ارائه می دهیم. ما حق تغییر نرخ های خود را در هر زمان برای خود محفوظ می داریم. (به بخش 6 \"محصول و خدمات مشاوره\" مراجعه کنید)",
        "terms_conditions_desc19": "اگر فکر کنیم در داده های شما نقض شده است به شما اطلاع خواهیم داد و تلاش خواهیم کرد جزئیات و اطلاعات مرتبط را ارائه دهیم. (ص 14 را ببینید \"چه چیزی دارید؟\")",
        "terms_conditions_desc20": "ما این حق را برای خود محفوظ می داریم که در هر زمان تغییراتی در این قرارداد ایجاد کنیم. ما سعی خواهیم کرد در مورد تغییرات در شرایط و ضوابط به شما اطلاع دهیم، اما استفاده از سرویس به این معنی است که شما با تغییرات در شرایط موافقت می کنید. (S.9 \"تغییرات\" را ببینید).",
        "terms_conditions_desc21": "شرایط و ضوابط و خط مشی رازداری ما فقط به زبان انگلیسی تهیه شده است و شما موافقت می کنید که نسخه انگلیسی شرایط و ضوابط و خط مشی رازداری در صورت تضاد با هر نسخه ترجمه شده در اولویت قرار دارد.",
        "terms_conditions_mycircle": "شرایط و ضوابط MYCIRCLE",
        "terms_conditions_last_update": "آخرین به روز رسانی: 14 ژانویه 2020",
        "terms_conditions_usage_agreement": "لطفاً قبل از استفاده از این وب سایت («سایت») این شرایط و ضوابط خدمات (\"شرایط\") را به دقت مطالعه کنید. این شرایط Menuonline (\"Menuonline\"، \"ما\"، یا \"ما\") و دیگران را از مسئولیت معاف می کند و\/یا مسئولیت ما و آنها را محدود می کند و شامل سایر مقررات مهمی است که در مورد استفاده شما از این سایت اعمال می شود.",
        "terms_conditions_acceptance": "با بازدید یا استفاده از این سایت یا سرویس، از طرف خود، و از طرف هر سازمانی که از طرف آن می‌توانید اقدام کنید (که در اینجا به آن «شما» گفته می‌شود) موافقت می‌کنید که این شرایط را برای هر استفاده و هر بازدید از این سایت بپذیرید و از آن پیروی کنید.",
        "terms_conditions_services": "1. خدمات ما.",
        "terms_conditions_services_desc": "خدماتی که ما از طریق سایت ارائه می‌دهیم، سرویسی است که به شما امکان می‌دهد پیام‌های ایمیل (\"ایمیل\") را برای گیرندگان مورد نظر خود ایجاد، ارسال و مدیریت کنید (\"سرویس\").",
        "terms_conditions_license": "2. مجوز.",
        "terms_conditions_license_desc": "با رعایت این شرایط، منوآنلاین بدینوسیله مجوزی غیر انحصاری، محدود و غیرقابل انتقال در طول مدت به شما اعطا می‌کند تا بتوانید سایت را مشاهده کنید و از خدمات مطابق با ویژگی‌های سرویس استفاده کنید. ما تمامی حقوقی را که در این قسمت در سرویس و محتوای منوآنلاین (همانطور که در زیر تعریف شده است) اعطا نشده است محفوظ می داریم.",
        "terms_conditions_account": "3. چگونه کار می کند.",
        "terms_conditions_account_desc": "برای استفاده از سرویس باید برای یک حساب کاربری ثبت نام کنید. حساب شما رایگان است. حساب شما به شما امکان دسترسی به سرویس و \"عملکردهایی\" را می دهد که ممکن است هر از گاهی ایجاد و نگهداری کنیم. برای واجد شرایط بودن برای یک حساب کاربری باید:",
        "terms_conditions_account_requirements": [
            "داشتن حداقل هجده (18) سال سن و قادر به انعقاد قرارداد.",
            "مراحل ثبت نام را که ما تعیین کرده ایم تکمیل کنید.",
            "موافقت با این شرایط و ضوابط؛",
            "اطلاعات تماس و صورتحساب\/پرداخت واقعی، کامل و به روز را ارائه دهید."
        ],
        "responsibility": "مسئولیت پذیری.",
        "responsibilityText": "مسئولیت فعالیت هایی که در حساب کاربری شما انجام می شود به عهده شماست و باید رمز عبور حساب خود را محرمانه و ایمن نگه دارید. حساب‌ها رایگان هستند، بنابراین ما شما را تشویق می‌کنیم که برای هر کاربری که دارید یک حساب متفاوت ایجاد کنید. شما همچنین مسئول هر حسابی هستید که به آن دسترسی دارید، خواه استفاده از آن حساب را مجاز کرده باشید یا نه. شما موافقت می کنید که تنها مسئول تعاملات خود با کسانی هستید که برای آنها ایمیل ارسال می کنید، و فقط برای افرادی که از آنها رضایت دارید ایمیل ارسال می کنید. شما باید فوراً ما را از هرگونه استفاده غیرمجاز از حساب های خود مطلع کنید.",
        "compliance_with_CASL": "مطابقت با قانون ضد هرزنامه کانادا (CASL).",
        "compliance_with_CASL_text": "نقش منوآنلاین تسهیل مدیریت تنظیمات رضایت است، نه اجرای قانون. این مسئولیت شماست که با CASL مطابقت داشته باشید. ما با تنظیم عملکرد پیش‌فرض و تسهیل دریافت و به‌روزرسانی رضایت صریح مستند و مدیریت تاریخ‌های انقضای متوالی برای رضایت ضمنی به شما کمک می‌کنیم. شما کنترل کامل مدیریت رضایت برای پایگاه داده خود را دارید. می‌توانید در هر زمان وضعیت رضایت را برای همه مخاطبین خود به‌صورت انبوه به‌روزرسانی کنید، تنظیمات پیش‌فرض را لغو کنید و انقضای خودکار وضعیت رضایت ضمنی را غیرفعال کنید.",
        "closing_account": "بستن حساب شما",
        "closing_account_text": "هم شما یا هم منوآنلاین می توانید در هر زمان و به هر دلیلی با دادن اخطار به طرف مقابل، این قرارداد را فسخ کنید. اگر اطلاعاتی را ارائه دهید که نادرست، نادرست، فعلی یا ناقص نیست یا از خدمات به گونه ای استفاده می کنید که این شرایط و ضوابط را نقض می کند، ما این حق را داریم که فوراً حساب شما را به حالت تعلیق درآوریم و دسترسی شما به این سرویس را علاوه بر هر راه حل دیگری که در دسترس ما است، قطع کنیم. اگر به مدت دوازده (12) ماه از حساب خود استفاده نکنید، ممکن است حساب شما را لغو کنیم. اگر به هر دلیلی حساب شما را خاتمه دهیم، هر اعتباری را که باقی مانده به شما بازپرداخت خواهیم کرد. پس از فسخ، ما حساب شما و تمام داده‌های مرتبط با آن، از جمله ایمیل‌هایی که ارسال کرده‌اید را برای همیشه حذف می‌کنیم. ما ممکن است داده های جمع آوری شده از حساب شما را حفظ کنیم که شامل اطلاعات شناسایی شخصی برای اهداف آماری نمی شود.",
        "emailing_content": "ارسال محتوای ایمیل",
        "emailing_content_text": "هنگام تعامل با منوآنلاین از طریق این سایت یا سرویس، با ارائه اطلاعات واقعی و دقیق موافقت می کنید. علاوه بر این، می‌دانید که ارائه اطلاعات محرمانه از طریق ایمیل یک عمل ناامن است. شما می‌دانید که مسئولیت هر چیزی که برای هر کسی که از سرویس ما استفاده می‌کند، بر عهده شماست. اهداف",
        "support": "پشتیبانی کنید.",
        "support_text": "ما از شما پشتیبانی می کنیم تا به شما کمک کنیم تا با خدمات خودکفا شوید. ما به شما در قالب مستندات و فیلم در سایت، سیستم تیکت پشتیبانی منوآنلاین و تلفنی پشتیبانی می کنیم. با کلیک بر روی برگه \"پشتیبانی\" در بالای سایت می توانید با ما تماس بگیرید. هیچ هزینه ای برای پشتیبانی محصول وجود ندارد. با این حال، ما این حق را برای خود محفوظ می داریم که بنا به صلاحدید خود، پشتیبانی از حساب های مشتری خاص را متوقف کنیم.",
        "product_and_consulting_services": "محصولات و خدمات مشاوره.",
        "product_and_consulting_services_text": "خدمات محصول برای مواقعی است که از ما می‌خواهید برخی از کارهای مربوط به Menuonline را انجام دهیم نه اینکه خودتان آن را انجام دهید. نرخ ما برای خدمات محصول 125 دلار در ساعت است. ما همچنین خدمات مشاوره ای را با قیمت های بالاتر ارائه می دهیم. ما حق تغییر نرخ های خود را در هر زمان برای خود محفوظ می داریم.",
        "monitoring": "نظارت.",
        "monitoring_text": "ما بر محتوای اطلاعات ارسال شده از طریق سرویس نظارت یا قضاوت نخواهیم کرد، اما اگر شکایت هایی مبنی بر استفاده نامناسب احتمالی دریافت کنیم، بررسی خواهیم کرد. منوآنلاین ممکن است در هر زمان و به صلاحدید خود، طبق دستورالعمل‌های زیر، با یا بدون اطلاع شما، استفاده‌های خاص را تشخیص دهد.",
        "term": "مدت.",
        "term_text": "این مدت زمانی شروع می شود که برای یک حساب کاربری با ما ثبت نام کنید و تا زمانی که از خدمات استفاده می کنید ادامه می یابد. شما نشان می‌دهید و تضمین می‌کنید که از اختیار و توانایی برای ثبت حساب برای ما (از جمله اگر از طرف یک سازمان ثبت نام کنید) دارید.",
        "changes": "تغییرات.",
        "changes_text": "این مدت زمانی شروع می شود که برای یک حساب کاربری با ما ثبت نام کنید و تا زمانی که از خدمات استفاده می کنید ادامه می یابد. شما نشان می‌دهید و تضمین می‌کنید که از اختیار و توانایی برای ثبت حساب برای ما (از جمله اگر از طرف یک سازمان ثبت نام کنید) دارید.",
        "payment": "پرداخت",
        "what_do_i_pay_for_service": "برای سرویس چه پولی می پردازم؟",
        "what_do_i_pay_for_service_text": "همانطور که در صفحه «قیمت‌گذاری» سایت خود توضیح می‌دهیم، می‌توانید اعتبارات پیش‌پرداختی را برای ارسال ایمیل (\"اعتبار\") از طریق سرویس ما خریداری کنید. اعتبارات هرگز منقضی نمی شوند و هیچ کارمزد تکراری برای این اعتبارات وجود ندارد. تنها استثنا این است که اگر به مدت دوازده (12) ماه از حساب خود استفاده نکنید، ممکن است حساب شما و هرگونه اعتبار استفاده نشده را لغو کنیم. ما ممکن است در هر زمان با به‌روزرسانی صفحات قیمت‌گذاری سایت خود، قیمت‌های خود را تغییر دهیم، که برای خرید بعدی اعتبار شما اعمال می‌شود.",
        "how_do_i_pay_for_service": "چگونه هزینه خدمات را پرداخت کنم؟",
        "how_do_i_pay_for_service_text": "می توانید با استفاده از هر کارت اعتباری اصلی، اعتبارات را پرداخت کنید. ما ممکن است در هر زمان روش های پرداخت را که می پذیریم تغییر دهیم.",
        "can_i_get_refund": "آیا می توانم بازپرداخت دریافت کنم؟",
        "can_i_get_refund_text": "مبالغی که بابت استفاده از سرویس پرداخت کرده اید، در صورت لغو حساب، قابل استرداد نیستند. اگر حساب شما را لغو کنیم، هر اعتباری که باقی مانده است به شما بازپرداخت می کنیم.",
        "rights": "حقوق",
        "what_do_we_own": "ما چه چیزی داریم؟",
        "what_do_we_own_text": "به جز محتوایی که در اختیار ما قرار می‌دهید (از جمله، بدون محدودیت، سوابق تماس، محتوای ایمیل و اطلاعاتی که از طریق سرویس جمع‌آوری می‌کنید) (\"محتوای شما\")، همه مطالب موجود در سایت ما، سرویس و همه مطالب موجود در آن یا منتقل شده از طریق آن، از جمله بدون محدودیت، نرم‌افزار، تصاویر، متن، گرافیک، تصاویر، آرم‌ها، پتنت‌ها، محتوای خدمات، عکس‌ها، علائم تجاری، موسیقی، عکس‌ها، علائم تجاری، عکس‌ها، امتیازات، عکس‌ها، علائم تجاری دیگر کاربران (\"محتوای آنلاین منو\") (و کلیه حقوق مالکیت معنوی مربوط به آن) متعلق به ما یا مجوز دهندگان ما هستند.",
        "what_do_you_own": "شما چه چیزی دارید؟",
        "what_do_you_own_text": "ما ادعای هیچ حق مالکیتی بر محتوای شما نداریم. محتوای شما متعلق به شما است و شما مالکیت محتوای خود را که در سرویس آپلود می کنید حفظ می کنید. با اشتراک گذاری محتوای خود از طریق سرویس، موافقت می کنید که به دیگران اجازه دهید مطابق با تنظیمات شما و این شرایط و ضوابط، محتوای شما را مشاهده، ویرایش و\/یا به اشتراک بگذارند. شما نشان می دهید و تضمین می کنید که یا مالک هستید یا اجازه استفاده از تمام مطالبی که در ایمیل های خود استفاده می کنید را دارید. ما ممکن است از مطالب شما استفاده کرده یا آن را افشا کنیم که در این شرایط و خط مشی رازداری ما توضیح داده شده است.",
        "privacy_statement": "منوآنلاین به حریم خصوصی کاربران خود احترام می گذارد. هر گونه اطلاعات شخصی که Menuonline از طریق این سایت یا سرویس جمع آوری می کند، مشمول بیانیه حریم خصوصی Menuonline است که با ارجاع به این شرایط گنجانده شده است. ما ممکن است از اطلاعات شما بر اساس بیانیه حریم خصوصی خود استفاده کرده و آن را افشا کنیم.",
        "access_to_your_content": "منوآنلاین دسترسی به محتوای شما را برای هیچکس به جز آن دسته از کارمندان و پیمانکاران منوآنلاین که قراردادهای محرمانه مناسبی را با ما منعقد کرده اند یا ما رضایت شما را داریم، فراهم نمی کند.",
        "governmentRequest": "اگر درخواستی از یک دولت یا مرجع نظارتی برای دسترسی به محتوای شما دریافت کنیم، قبل از انتشار هر یک از محتوای شما، شما را از این درخواست مطلع می‌کنیم و رضایت شما را جویا می‌شویم. ما در برابر هرگونه درخواستی برای محتوای شما مقاومت می کنیم و محتوای شما را در پاسخ به این نوع درخواست بدون اجازه شما یا الزام دادگاه صالح یا نهاد نظارتی مربوطه منتشر نمی کنیم.",
        "breachNotification": "در صورتی که متوجه شویم محتوای شما در معرض دسترسی غیرمجاز، استفاده، کپی، سرقت، از دست دادن، استفاده یا افشای نقض این شرایط قرار گرفته است (یک «نقض»)، فوراً به شما اطلاع خواهیم داد و شما را از آنچه رخ داده است مطلع خواهیم کرد. شما موافقت می کنید که تخلفات مطابق با قوانین قابل اجرا رسیدگی می شود، و اگر ما به شما نیاز داریم که لیست های توزیع ایمیل خود را به اعضای خود اطلاع دهید، در این صورت موافقت می کنید که این کار را به سرعت و طبق دستور ما انجام دهید.",
        "contentRetention": "ما محتوای شما را در کانادا حفظ می‌کنیم و استفاده و انتقال می‌دهیم، مگر با تأیید یا دستور قبلی کتبی شما (به عنوان مثال، اگر بخواهید محتوای خود را برای افراد خارج از کانادا ارسال کنید). سرورهای مورد استفاده برای ارائه خدمات در کانادا قرار دارند.",
        "accessTrouble": "اگر در دسترسی به محتوای خود مشکل دارید، Menuonline به شما کمک می کند تا محتوای خود را بر اساس زمان و مواد بازیابی کنید.",
        "reportingAbuse": "اگر فکر می کنید کسی هر یک از این شرایط را نقض می کند، لطفاً فوراً به ما اطلاع دهید. اگر هرزنامه‌هایی دریافت کرده‌اید که فکر می‌کنید از طرف کاربر دیگری از سرویس آمده است، اگر فکر می‌کنید کاربر دیگری از سرویس مطالبی را پست کرده است که هر گونه حق نسخه‌برداری را نقض می‌کند، یا اگر فکر می‌کنید کاربر دیگری از این سرویس به‌طور دیگری این شرایط را نقض می‌کند، لطفاً با ما در Support@menuonline.com تماس بگیرید.",
        "acceptableUse": "شما نشان می‌دهید و تضمین می‌کنید که استفاده شما از این سرویس با تمام قوانین و مقررات قابل اجرا مطابقت دارد. شما موافقت می‌کنید که مسئولیت تعیین اینکه آیا این سرویس با آن مطابقت دارد و برای استفاده در حوزه قضایی شما مناسب است، بر عهده شماست. برای اطمینان بیشتر، شما موافقت می کنید که (و موافقت می کنید اطمینان حاصل کنید که کارمندان، نمایندگان و کاربران مجاز شما باید) ...",
        "exportLaws": "ما هیچ ادعایی نداریم که سایت یا سرویس برای استفاده در خارج از کانادا مناسب یا در دسترس است و دسترسی به آنها از مناطقی که محتوای آنها غیرقانونی است ممنوع است. شما نمی توانید از محتوای منوآنلاین یا سایر مطالب موجود در این سایت یا هر کپی یا انطباق بر خلاف قوانین و مقررات قابل اجرا از جمله قوانین و مقررات صادرات کانادا و ایالات متحده بدون محدودیت استفاده کنید یا صادر کنید یا دوباره صادر کنید.",
        "disclaimers": "سرویس و این سایت بدون هیچ گونه نمایندگی، ضمانت یا شرایطی «همانطور که هست» ارائه می‌شود، و Menuonline صراحتاً تمام نمایندگی‌ها، ضمانت‌ها و شرایط، صریح یا ضمنی، بر اساس قانون یا موارد دیگر، از جمله بدون محدودیت، هرگونه ضمانت ضمنی و شرایط غیرمناسب یا غیرقابل استفاده برای هر هدف ثالث، تا حدی که توسط قوانین قابل اجرا مجاز است، رد می‌کند. حقوق...",
        "limitLiability": "مسئولیت کلی منوآنلاین طبق این توافقنامه، چه در قرارداد و چه در صورت تخلف (از جمله سهل انگاری)، در نتیجه نقض ضمانت، مسئولیت دقیق یا بر اساس هر نظریه دیگری از مسئولیت، محدود به خسارات مستقیم به مبلغی خواهد بود که از کل هزینه های پرداختی به Menuonline تحت این شرایط و ضوابط در دوازده ماه قبل از 1 ماه اخیر که حداکثر در دوازده ماه قبل از 1 ماه افزایش می یابد، محدود می شود. رخ داده است.",
        "liabilityExclusions": "هیچ یک از Menuonline، وابستگان آن یا مدیران مربوطه، افسران، کارمندان، نمایندگان یا سایر نمایندگان (مجموعاً «نمایندگان Menuonline») یا اپراتورهای شبکه‌های متصل هیچ‌گونه مسئولیت یا مسئولیتی در رابطه با این سایت یا سرویس در قبال موارد زیر نخواهند داشت: (i) هر گونه آسیب غیرمستقیم، تبعی، خاص، اتفاقی یا جزایی؛ یا (۲) برای هر گونه خسارت، اعم از مستقیم، غیرمستقیم، تبعی، اتفاقی، مثالی، تنبیهی یا خاص که به عنوان درآمد از دست رفته، پس‌انداز یا درآمد از دست رفته یا سود از دست رفته مشخص می‌شود...",
        "indemnity": "شما غرامت می‌دهید و نمایندگان Menuonline و\/یا Menuonline («احزاب غرامت‌شده Menuonline») را از هرگونه ادعایی که توسط اشخاص ثالث مطرح می‌شود، و هر گونه جریمه یا مجازاتی که به دلیل استفاده شما از اطلاعات دسترسی به این سایت یا استفاده شما از سرویس و هرگونه نقض این شرایط ناشی می‌شود، بی‌ضرر نگه می‌دارید...",
        "ipInfringement": "Menuonline از شما دفاع می کند، غرامت می دهد و شما را در برابر هرگونه ادعا، درخواست، اقدام، شکایت یا دادرسی که توسط شخص ثالثی علیه شما مطرح شده یا مطرح می شود مبنی بر اینکه دسترسی شما به خدمات یا استفاده از آن مطابق با این توافقنامه، حقوق مالکیت معنوی تحت قوانین کانادا برای شخص ثالث را نقض می کند، بی ضرر نگه می دارد (یک «ادعای نقض IP»).",
        "equitableRelief": "اگر این شرایط را نقض کنید، ممکن است به دنبال معافیت دستوری باشیم (به این معنی که ممکن است از دادگاه برای جلوگیری از شما درخواست کنیم) یا دیگر تسکین عادلانه. شما موافقت می کنید که این علاوه بر و بدون هیچ گونه لطمه ای به سایر حقوقی است که ممکن است در قانون یا برابری داشته باشیم.",
        "linksToOtherSites": "اگر ما پیوندهایی به وب سایت های شخص ثالث ارائه دهیم، آنها فقط برای سهولت ارائه می شوند. اگر از این لینک ها استفاده کنید، سایت ما را ترک خواهید کرد. ما این سایت های شخص ثالث را کنترل نمی کنیم و آنها را تأیید نمی کنیم. شما موافقت می کنید که ما مسئولیتی در قبال هیچ یک از این سایت های شخص ثالث یا محتوای آنها نداریم...",
        "thirdPartyRequests": "اگر ما مجبور به ارائه اطلاعاتی در پاسخ به درخواست قانونی یا دولتی در رابطه با حساب شما یا استفاده شما از این سرویس هستیم، در این صورت موافقت می کنید که ممکن است هزینه های خود را از شما دریافت کنیم. این هزینه ها ممکن است شامل هزینه های قانونی ما و هزینه های زمان کارمند ما برای آماده سازی پاسخ به درخواست باشد.",
        "assignment": "این توافقنامه، و هر گونه حقوق و مجوزهای اعطا شده در اینجا، نمی تواند توسط شما منتقل یا واگذار شود، اما ممکن است توسط Menuonline بدون محدودیت، پس از اطلاع قبلی منطقی به شما، واگذار شود.",
        "governingLaw": "این شرایط بر اساس قوانین فدرال قابل اجرا و قوانین انتاریو بدون در نظر گرفتن تضاد قوانین آن کنترل و تفسیر می شود. شما موافقت می کنید که برای حل هر گونه اختلاف در مورد این شرایط به صلاحیت انحصاری دادگاه های انتاریو تسلیم شوید.",
        "severability": "اگر به هر دلیلی دادگاه صلاحیتدار هر یک از مفاد این شرایط یا بخشی از آن را غیرقانونی یا به هر دلیلی غیرقابل اجرا تشخیص دهد، آن بند تا حداکثر حد مجاز برای اجرای مقصود این شرایط اجرا خواهد شد و باقیمانده این شرایط به قوت خود ادامه خواهد داد و قابل اجرا خواهد بود.",
        "waiver": "هرگونه چشم پوشی یا رضایت به خروج از الزامات این شرایط تنها در صورتی مؤثر خواهد بود که به صورت کتبی و امضا شده توسط ما باشد و فقط در مورد خاص و برای هدف خاصی که برای آن ارائه شده است. عدم استفاده یا تأخیر ما در اعمال، هر حقی تحت این شرایط به عنوان چشم پوشی از حقوق ما عمل نخواهد کرد.",
        "furtherAssurances": "هر یک از طرفین موافقت می کند که تمام اسناد و مدارک را امضا کند و ارائه دهد و اقدامات لازم (یا انجام آن اقدامات) را برای انجام تعهدات خود تحت این شرایط و ضوابط انجام دهد.",
        "notices": "تمام اعلان‌های منوآنلاین باید کتبی باشد و از طریق ایمیل برای ما ارسال شود. اعلان‌هایی برای ما باید به اطلاع نمایندگان خدمات مشتری ما در support@menuonline.com ارسال شود. ممکن است اعلامیه هایی برای شما به آدرس یا آدرس ایمیل ارائه شده توسط شما به عنوان بخشی از ثبت نام ارسال شود. علاوه بر این، ما ممکن است اعلامیه ها یا پیام هایی را از طریق این سایت برای اطلاع شما از تغییرات این سایت یا سایر موارد مهم منتشر کنیم، و این پخش ها در زمانی که ما آنها را پست می کنیم به شما اطلاع رسانی خواهد شد.",
        "entireAgreement": "این شرایط و بیانیه حفظ حریم خصوصی ما، کل توافق نامه بین شما و ما در رابطه با استفاده شما از سایت و سرویس را تشکیل می دهد و جایگزین همه توافقات، نمایندگی ها و تفاهمات قبلی بین منوآنلاین و شما در رابطه با این موضوع می شود. در صورت تضاد بین بیانیه حریم خصوصی و این شرایط، این شرایط و ضوابط حاکم خواهد بود.",
        "language": "طرفین صریحاً درخواست کرده اند و خواسته اند که این شرایط و ضوابط، خط مشی رازداری ما، و هر گونه اسناد مرتبط به زبان انگلیسی تهیه شود. Les Party conviennent et exigent expressément que ce Contrat et tous les document qui s'y rapportent soient rédigés en anglais. شما موافقت می کنید که نسخه انگلیسی این شرایط و خط مشی رازداری در صورت تضاد با هر نسخه ترجمه شده در اولویت قرار دارد.",
        "survival": "تمام مفاد این شرایط که به دلیل ماهیت یا وارداتشان برای بقای انقضا یا خاتمه این شرایط در نظر گرفته شده اند، از جمله، بدون محدودیت، بخش های 17-19، باقی خواهند ماند."
    },
    "collect_additional_info": "آیا می خواهید اطلاعات بیشتری جمع آوری کنید؟",
    "additionalInfoChoice": "هر گونه اطلاعات اضافی را که می خواهید جمع آوری کنید انتخاب کنید.",
    "additional_info_yes": "بله",
    "additional_info_noSkip": "نه، پرش",
    "additional_info_label": "اطلاعات تکمیلی",
    "confirm_business_location": "بیایید ابتدا مکان کسب و کار شما را تأیید کنیم",
    "custom_get_more_reviews_form": "سفارشی دریافت نظرات بیشتر فرم",
    "set_up_custom_form": "فرم سفارشی خود را تنظیم کنید دریافت نظرات بیشتر توسط گروه",
    "reputation_name_label": "دریافت نظرات بیشتر نام",
    "reputation_name_placeholder": "نام شهرت را وارد کنید",
    "group_label": "گروه",
    "group_desc_title": "عنوان توضیحات گروه",
    "group_desc": "توضیحات گروه",
    "select_group_placeholder": "گروه را انتخاب کنید",
    "required_group_name": "نام گروه الزامی است",
    "before_asking_review": "قبل از درخواست از مشتریان برای بررسی، بیایید 1 تا 4 سوال از آنها بپرسیم.",
    "incentive_settings": "تنظیمات تشویقی",
    "incentivize_customer": "آیا می خواهید مشتری خود را برای نظر دادن تشویق کنید؟",
    "choose_incentive": "انگیزه ای را که می خواهید ارجاع دهید انتخاب کنید.",
    "scan_qr_feedback": "کد QR را اسکن کنید و نظرات ارزشمند خود را در مورد تجربه خود با ما در میان بگذارید.",
    "qr_code": "کد QR",
    "scan_me": "مرا اسکن کن!",
    "or_title": "یا",
    "send_otp_title": "ارسال OTP",
    "sign_in_title": "وارد شوید",
    "email_addresss_title": "آدرس ایمیل",
    "reset_password_title": "بازنشانی رمز عبور",
    "remember_password_title": "رمز عبور را به خاطر آورده اید؟",
    "digit_code_title": "لطفا کد 4 رقمی ارسال شده را وارد کنید ",
    "not_received_title": "من کد تایید دریافت نکردم!",
    "resend_title": "لطفا دوباره بفرستید",
    "sign_up_form": "حساب کاربری خود را ایجاد کنید",
    "sign_up_sub_title": "برای دسترسی به آخرین اطلاعات، اکنون وارد شوید",
    "already_registered_title": "آیا از قبل یک حساب منوآنلاین دارید؟",
    "reserved_title": "حق چاپ ©2023 Menuonline. تمامی حقوق محفوظ است.",
    "terms_title": "شرایط خدمات",
    "policy_title": "سیاست حفظ حریم خصوصی",
    "sign_up_title": "ثبت نام کنید",
    "terms_conditions_title": "شرایط و ضوابط",
    "business_type_title": "نوع کسب و کار",
    "business_type_sub_title": "لطفاً یکی از انواع کسب و کار را انتخاب کنید که کسب و کار شما را بهتر توصیف می کند!",
    "salon_title": "سالن",
    "restaurant_title": "رستوران",
    "pizza_store_title": "فروشگاه پیتزا",
    "food_truck_title": "کامیون مواد غذایی",
    "check_domain_title": "نام دامنه را بررسی کنید",
    "start_exploring_title": "شروع به کاوش کنید",
    "sign_in_form": "وارد حساب کاربری خود شوید",
    "sign_in_sub_title": "برای دسترسی به آخرین اطلاعات، اکنون وارد شوید",
    "remember_title": "مرا به خاطر بسپار",
    "otp_title": "OTP",
    "password_title": "رمز عبور",
    "forgot_title": "رمز عبور را فراموش کرده اید؟",
    "not_registered_title": "حساب کاربری ندارید؟",
    "create_account_title": "همین الان ثبت نام کنید",
    "create_form_title": "فرم ایجاد کنید",
    "first_name_title": "نام کوچک",
    "last_name_title": "نام خانوادگی",
    "email_title": "ایمیل",
    "mobile_number_title": "شماره موبایل",
    "birthday_title": "تولد",
    "gender_title": "جنسیت",
    "address_title": "آدرس",
    "short_answer_title": "پاسخ کوتاه",
    "long_answer_title": "پاسخ طولانی",
    "single_answer_title": "تک جواب",
    "single_checkbox_title": "چک باکس تک",
    "multiple_choice_title": "چند انتخابی",
    "drop_down_title": "کشویی",
    "yes_no_title": "بله یا خیر",
    "description_text_title": "متن توضیحات",
    "file_upload": "آپلود فایل",
    "reset_password_form": "رمز عبور خود را بازنشانی کنید",
    "reset_password_sub_title": "به سرعت رمز عبور خود را بازنشانی کنید و به حساب خود دسترسی پیدا کنید.",
    "required_full_name_title": "نام کامل الزامی است",
    "required_first_name_title": "نام و نام خانوادگی الزامی است",
    "valid_first_name_title": "نام معتبر وارد کنید",
    "valid_full_name_title": "نام کامل معتبر وارد کنید",
    "required_block_time": "زمان بلوک مورد نیاز است",
    "required_last_name_title": "نام خانوادگی الزامی است",
    "valid_last_name_title": "نام خانوادگی معتبر وارد کنید",
    "valid_mobile_number_title": "شماره موبایل معتبر را وارد کنید",
    "required_mobile_number_title": "شماره موبایل الزامی است",
    "required_already_mobile_number_title": "شماره موبایل قبلاً مورد نیاز است",
    "valid_otp_title": "otp معتبر وارد کنید",
    "valid_email_title": "یک ایمیل معتبر وارد کنید",
    "required_email_title": "ایمیل مورد نیاز است",
    "required_description_title": "توضیحات لازم است",
    "required_description_500_characters": "لطفا حداقل 500 کاراکتر وارد کنید",
    "required_description_600_characters": "لطفا حداکثر 600 کاراکتر را وارد کنید",
    "domain_title": "نام دامنه",
    "required_password_title": "رمز عبور لازم است",
    "required_password_regex_title": "باید حداقل 8 نویسه، حداقل یک نویسه بزرگ، یک نویسه کوچک، یک عدد و یک نویسه کوچک خاص داشته باشد.",
    "required_terms_title": "لطفاً با شرایط و ضوابط موافقت کنید",
    "required_note_title": "توجه لازم است",
    "required_birthday_title": "تولد الزامی است",
    "no_access_title": "شما در حال حاضر دسترسی ندارید. لطفا با مدیر خود تماس بگیرید",
    "menu_online_open_txt": "باز کنید",
    "loyalty_program_description": "😊 برنامه وفاداری ایده آل خود را پیدا کنید و آن را مطابق با نام تجاری خود سفارشی کنید!",
    "lp_list_title": "لیست کارت پانچ وفاداری",
    "lp_n_title": "نام کارت پانچ وفاداری",
    "enter_details_title": "جزئیات را وارد کنید",
    "stamp_allowed_title": "تمبر مجاز است",
    "stamp_items_title": "اقلام تمبر",
    "lpn_req_title": "نام کارت پانچ وفاداری الزامی است",
    "valid_lpn_title": "نام کارت پانچ وفاداری معتبر را وارد کنید",
    "brand_color_error": "رنگ برند باید دقیقا 7 کاراکتر باشد",
    "select_dark_brand_color_title": "لطفا رنگ مارک معتبر را انتخاب کنید",
    "your_brand_color_title": "رنگ برند شما",
    "upload_logo_title": "لوگو را بارگذاری کنید",
    "locations_title": "مکان ها",
    "add_lp_title": "ایجاد برنامه",
    "location_error": "لطفا مکان ها را انتخاب کنید",
    "pvs_title": "یک مشتری در هر بازدید چند تمبر می تواند کسب کند؟",
    "max_apd_title": "حداکثر تعداد تمبرهایی که یک مشتری می تواند در یک روز کسب کند چقدر است؟",
    "logo_error": "لوگو مورد نیاز است",
    "expiry_title": "انقضا را روی جوایز تنظیم کنید",
    "add_more_rewards_title": "+ اضافه کردن جوایز بیشتر",
    "prev_step_1_title": "نمایش QR برای کسب امتیاز و لذت بردن از جوایز خود.",
    "delete_lp_title": "این کارت پانچ وفاداری برای همیشه حذف خواهد شد.",
    "delete_user_record_title": "این سابقه کاربر برای همیشه حذف خواهد شد.",
    "redeem_title": "بازخرید",
    "lp_button_title": "به کارت پانچ وفاداری بپیوندید",
    "lp_download_button_title": "کارت دانلود",
    "step_0_title": "اکنون بپیوندید، جوایز خوشمزه در انتظار غذاهای وفادار مانند شما است.",
    "step_1_title": "شما ملحق شده اید",
    "congrats_step_title": "اکنون شما واجد شرایط دریافت پیتزای رایگان هستید",
    "reward_title": "در هر بازدید پاداش دریافت کنید",
    "visit_title": "بازدید و باز کردن قفل",
    "rewards_title": "پاداش",
    "dummy_address": "3730 فرانکفورت، لوئیسویل کنتاکی، ایالات متحده آمریکا",
    "loyalty_qr_default_text": "کد QR را اسکن کنید، به کارت پانچ وفاداری ما بپیوندید و از جوایز لذت ببرید",
    "lp_earn_stamp_txt": "مشتریان در هر بازدید تمبر کسب می کنند.",
    "lp_get_rewards_txt": "در هر بار بازدید، جوایزی دریافت کنید",
    "lp_1_time_visit_txt": "1 بار بازدید دریافت خواهید کرد",
    "lp_uppercase_stamp_text": "تمبر",
    "lp_lowercase_stamp_text": "تمبر",
    "lp_txt": "وفاداری",
    "lp_per_visit_stamp_validation_msg": "هر تمبر بازدید باید کمتر از حداکثر تمبر مجاز در روز باشد",
    "lp_per_max_stamp_validation_msg": "حداکثر تمبر مجاز در روز باید بیشتر از هر تمبر بازدید باشد",
    "lp_valid_value_msg": "مقدار معتبر وارد کنید",
    "lp_required_field_msg": "این فیلد الزامی است",
    "lp_stamp_required_msg": "مهر الزامی است",
    "lp_valid_stamp_msg": "مقدار مهر معتبر را وارد کنید",
    "lp_reward_name_required_msg": "نام جایزه الزامی است",
    "lp_unique_stamp_msg": "ارزش تمبر باید منحصر به فرد باشد",
    "lp_benefits": "یک برنامه وفاداری بازدیدهای مکرر و درآمد را افزایش می دهد و در عین حال بینش مشتری را برای بازاریابی شخصی ارائه می دهد",
    "lp_how_customer_txt": "در اینجا نحوه مشتریان شما آمده است",
    "lp_punch_card_txt": "کارت پانچ وفاداری",
    "lp_look_txt": "نگاه خواهد کرد! 😍",
    "lp_desc": "مشتریان به ازای هر بازدید، تمبر دریافت می‌کنند و پس از جمع‌آوری کافی، می‌توانند پاداش‌های ویژه را دریافت کنند.",
    "lp_edit": "ویرایش کنید",
    "lp_looks_good_txt": "به نظر خوبه",
    "lp_customer_view_instruction": "این چیزی است که مشتری شما خواهد دید. لوگوی خود را آپلود کنید و تم رنگی را تنظیم کنید",
    "lp_customer_earn_stamps": "به مشتریان اجازه دهید در هر بازدید تمبر کسب کنند و جوایز خود را مدیریت کنند",
    "lp_stamp_rewards_setup": "جوایز کسب تمبر را تنظیم کنید، به مشتریان اجازه دهید برای هر تمبر پاداش دریافت کنند",
    "lp_loyalty_program_setup": "نام برنامه وفاداری خود را بدهید و مکان خود را برای داشتن این برنامه تنظیم کنید",
    "lp_prev_step1": "نمایش QR برای کسب امتیاز و لذت بردن از جوایز خود.",
    "lp_visit_1_time_txt": "1 بار بازدید کنید و دریافت کنید",
    "lp_get_free_txt": "رایگان دریافت کنید",
    "lp_visit_allow_txt": "مشاهده و باز کردن قفل",
    "lp_rewards": "پاداش",
    "repsT": "دریافت نظرات بیشتر",
    "offSup": "لوازم اداری",
    "repsNxtV": "در بازدید بعدی شما",
    "addRepT": "اضافه کردن دریافت نظرات بیشتر",
    "repEntrCpnTxt": "متن کوپن را وارد کنید",
    "repEntrCpnTxtBtn": "متن دکمه کوپن را وارد کنید",
    "repMngNxtV": "در دیدار بعدی شما؟",
    "repMngWldL": "آیا می خواهید دریافت کنید",
    "discount": "تخفیف",
    "repDisTyp": "نوع تخفیف",
    "repNameT": "شهرت خود را نام ببرید",
    "crtRepT": "ایجاد شهرت",
    "feedBackT": "بازخورد",
    "confLocT": "مکان را تأیید کنید",
    "businLocT": "محل کسب و کار",
    "addInfoT": "اطلاعات تکمیلی",
    "repNReqT": "دریافت نظرات بیشتر نام الزامی است",
    "validRepNT": "نام اعتبار معتبر را وارد کنید",
    "groupT": "گروه",
    "groupLDescTitleT": "به طور خودکار مخاطبین را به یک گروه فشار دهید",
    "groupLDescT": "تمامی مشتریانی که فرم را پر کنند به گروه انتخابی اضافه خواهند شد.",
    "que2": "غذاتون خوب بود؟",
    "que3": "آیا خدمات ما دوستانه بود؟",
    "que4": "سرویس ما سریع بود؟",
    "addAdditionalT": "آیا می خواهید اطلاعات بیشتری اضافه کنید؟",
    "phNoMandT": "شماره تلفن الزامی است!",
    "qrGenT": "QR ایجاد شد",
    "repFDT": "نظرات شما به تیم ما کمک می کند تا بهتر به شما خدمت کنند!",
    "letdiT": "بیایید آن را انجام دهیم",
    "tak15secT": "15 ثانیه طول می کشد",
    "defQue": "آیا دوست دارید هر از گاهی پیشنهادات و تبلیغات عالی دریافت کنید؟",
    "defNQue": "آیا دوست دارید کسی به شما پاسخ دهد؟",
    "rateOnGoogleDesc": "آیا آنقدر لطف دارید که به ما در گوگل امتیاز دهید؟",
    "mayBeNT": "شاید دفعه بعد",
    "yesSureT": "بله حتما",
    "valid_name": "نام معتبر وارد کنید",
    "nameReq": "نام الزامی است",
    "backToMainT": "بازگشت به صفحه اصلی",
    "attachCopT": "آیا دوست دارید مشتری خود را تشویق کنید؟",
    "thankYouT": "متشکرم",
    "lftyT": "ما مشتاقانه منتظر بازدید بعدی شما هستیم!",
    "repPreT": "پیش نمایش شهرت",
    "offer_exclusive_discounts": "برای جذب مشتریان خود، تخفیف ها و تبلیغات منحصر به فرد ارائه دهید.",
    "couponT": "کوپن",
    "locStepDesc": "مکان کسب و کار خود را تأیید کنید",
    "createRepStepDesc": "یک فرم شهرت سفارشی توسط گروه ها ایجاد کنید",
    "feedbackStepDesc": "مجموعه سوالات بازخورد مشتری",
    "addInfoStepDesc": "زمینه های مشتری و تنظیمات تشویقی",
    "FedbkkWDT": "بازخورد با داده ها",
    "repAnlytT": "تجزیه و تحلیل شهرت",
    "todayT": "امروز",
    "rep_last_7_days_t": "7 روز گذشته",
    "last30DaysT": "30 روز گذشته",
    "lastMT": "ماه گذشته",
    "custRangeT": "محدوده سفارشی",
    "queWiseAnltT": "تحلیل عاقلانه سوال کنید",
    "atL1QueErrT": "لطفا حداقل یک سوال را انتخاب کنید",
    "deleteRepTitle": "این شهرت برای همیشه حذف خواهد شد.",
    "incvCustT": "بله، مشتری من را تشویق کنید",
    "collAddInfoT": "بله، من می خواهم اطلاعات بیشتری را جمع آوری کنم",
    "totRewT": "بررسی کل",
    "totPosRewT": "مجموع بررسی مثبت",
    "negFeedT": "بازخورد منفی",
    "posFeedT": "بازخورد مثبت",
    "fineT": "چاپ خوب",
    "enterFineT": "متن چاپ دقیق را وارد کنید",
    "setThemeT": "تنظیم رنگ تم",
    "que1T": "سوال 1",
    "que2T": "سوال 2",
    "que3T": "سوال 3",
    "que4T": "سوال 4",
    "entMailT": "ایمیل خود را وارد کنید",
    "reputationQRDefTxt": "کد QR را اسکن کنید و نظرات ارزشمند خود را در مورد تجربه خود با ما در میان بگذارید.",
    "selUser": "کاربر را انتخاب کنید",
    "userT": "کاربر",
    "subUReq": "لطفا کاربران را انتخاب کنید",
    "updateLoc": "به روز رسانی مکان",
    "leadGenT": "نسل سرب",
    "displayT": "نمایش",
    "ratingT": "رتبه بندی",
    "rep_dashboard_negative_feedback": "بازخورد منفی 0%",
    "rep_dashboard_position_feedback": "بازخورد مثبت 0%",
    "rep_dashboard_total_Feedback_txt": "کل بازخورد",
    "rep_dashboard_anony_Feedback": "بازخورد ناشناس",
    "rep_dashboard_Feeedback_Data_txt": "بازخورد با داده ها",
    "rep_dashboard_review_txt": "دریافت نظرات بیشتر",
    "rep_dashboard_total_Review_txt": "بررسی کل",
    "rep_dashboard_total_positive_Review_txt": "مجموع بررسی مثبت",
    "rep_dashboard_negative_feedback_txt": "بازخورد منفی",
    "rep_dashboard_connection_txt": "اتصال",
    "rep_dashboard_question_wise_analytics_txt": "تحلیل عاقلانه سوال کنید",
    "rep_dashboard_date_label": "تاریخ را انتخاب کنید",
    "rep_dashboard_custom_range_txt": "محدوده سفارشی",
    "rep_tlt_min": "REP",
    "emojis": "ایموجی ها",
    "media_text": "رسانه اضافه کنید",
    "custom_field_text": "فیلدهای سفارشی را درج کنید",
    "shortlink_text": "لینک کوتاه اضافه کنید",
    "use_template_text": "از قالب استفاده کنید",
    "banner_must_have": "باید داشته باشد",
    "res_seamless": "بدون درز",
    "res_table_reservation": "رزرو میز",
    "res_system": "سیستم",
    "res_text": "رزرواسیون",
    "md_easily": "به راحتی",
    "md_manage_customers": "مشتریان را مدیریت کنید",
    "md_visits_interactions": "بازدیدها و تعاملات",
    "md_desc": "با رزرو میز در زمان واقعی غذا خوردن را برای مشتریان خود آسان کنید. رزروها را بدون دردسر مدیریت کنید، زمان انتظار را کاهش دهید و از تجربه ای روان برای کارکنان و مهمانان اطمینان حاصل کنید. رستوران خود را به طور کامل رزرو کنید و در عین حال خدمات درجه یک و رضایت شما را حفظ کنید.",
    "engage_convert": "تعامل و تبدیل پیامک",
    "coupon_campaign": "و کمپین کوپن",
    "sms_read_rate": "پیامک ها در 98 درصد مواقع خوانده می شوند",
    "real_time_promo": "تبلیغات را در زمان واقعی برای اقدام فوری ارائه دهید",
    "affordable_reach": "روشی مقرون به صرفه برای دستیابی به مشتریان با ROI جامد",
    "stay_in_touch": "همیشه با مشتری خود در ارتباط باشید",
    "bc_engage_customers": "درگیر کردن مشتریان تبدیل پیامک",
    "bc_coupon_campaign": "و کمپین کوپن",
    "bc_sms_read_rate": "پیامک ها در 98 درصد مواقع خوانده می شوند",
    "bc_real_time_promo": "تبلیغات را در زمان واقعی برای اقدام فوری ارائه دهید",
    "bc_affordable_reach": "روشی مقرون به صرفه برای دستیابی به مشتریان با ROI جامد",
    "bc_stay_in_touch": "همیشه با مشتری خود در ارتباط باشید",
    "ar_automate_engagement": "تعامل با مشتری خود را خودکار کنید",
    "ar_with": "با",
    "ar_with_auto_responders": "پاسخگوی خودکار",
    "ar_personalized_messages": "پیام‌های شخصی‌سازی‌شده را برای تولدها به‌طور خودکار ارسال کنید، به مشتریان جدید خوش آمدید، و دوباره با مشتریان قبلی ارتباط برقرار کنید. در زمان صرفه جویی کنید و هر تعامل را معنی دار و به موقع نگه دارید.",
    "sq_custom_forms_tools": "فرم ها و ابزارهای سفارشی - ساده کردن",
    "sq_custom_forms_ci": "تعامل با مشتری",
    "sq_smart_qr_desc": "فرم‌های سفارشی ایجاد کنید، امضاهای الکترونیکی را جمع‌آوری کنید و از کدهای QR هوشمند برای ساده‌سازی فرآیندهای کسب‌وکار خود استفاده کنید. هر ابزار را متناسب با نیازهای مشتریان خود تنظیم کنید و تجربه کاربری را افزایش دهید.",
    "gnc_get_new_cus": "مشتریان جدید بدست آورید",
    "gnc_get_customers": "از رسانه های اجتماعی - سریع و آسان!",
    "gnc_social_media_power": "از قدرت پلتفرم های اجتماعی برای جذب مشتریان جدید و هدایت فروش مستقیم به کسب و کار خود استفاده کنید. برای حداکثر کنترل و درآمد، سفارشات تحویل شخص ثالث را مستقیماً به فروشگاه یا پلت فرم سفارش آنلاین خود هدایت کنید.",
    "wifi_connect_guests": "ارتباط مهمان ها با",
    "wifi_smart": "وای فای هوشمند",
    "wifi_connect_grow": "بینش خود را رشد دهید",
    "wifi_smart_wifi_desc": "اتصال Wi-Fi بدون دردسر را از طریق یک صفحه نمایش مارک دار به مشتریان خود ارائه دهید و در عین حال داده های ارزشمند مشتری را برای افزایش تلاش ها و تعامل بازاریابی خود جمع آوری کنید.",
    "wifi_create_splash_page": "صفحه Splash Wifi Guest ایجاد کنید",
    "instant_menu_create": "خود را ایجاد کنید",
    "instant_menu_text": "منوی فوری",
    "instant_menu_wm": "در عرض چند دقیقه",
    "instant_menu_desc": "تجارب مشتری را با امکان به‌روزرسانی‌های بی‌درنگ، سفارشی‌سازی آسان و تعامل بهبودیافته بهبود می‌بخشد که نمایش پیشنهادات خود را آسان‌تر می‌کند و محتوای شما را تازه نگه می‌دارد.",
    "instant_menu_create_digital_menu": "یک منوی دیجیتال ایجاد کنید",
    "loyalty_program_rewards": "پاداش کسب کنید",
    "loyalty_program_on_visits": "در هر بازدید",
    "loyalty_program_create": "ایجاد برنامه وفاداری",
    "reputation_create": "ایجاد شهرت",
    "reputation_boost": "خود را تقویت کنید",
    "reputation_text": "شهرت",
    "reputation_reviews": "با نظرات بیشتر!",
    "reputation_desc": "بازخورد ارزشمندی از مشتریان خود جمع آوری کنید تا خدمات و شهرت خود را افزایش دهید. تجربه خود را به اشتراک بگذارید و به رشد ما کمک کنید - بررسی شما مهم است!",
    "dashboard": "داشبورد",
    "blast_campaign": "کمپین انفجار",
    "blast_campaigns": "کمپین های انفجار",
    "blast_campaign_sub_title": "کمپین های انفجاری را راه اندازی کنید تا با چند کلیک به همه مشتریان خود برسید",
    "auto_responders": "پاسخگوهای خودکار",
    "auto_responder": "پاسخگوی خودکار",
    "auto_responder_sub_title": "استفاده آسان از تبلیغات مبتنی بر رویداد برای مشتریان شما",
    "smart_qr_form": "QR هوشمند \/ فرم",
    "get_new_customer": "مشتری جدید دریافت کنید",
    "marketing": "بازاریابی",
    "clients": "مشتریان",
    "group_data": "داده های گروهی",
    "clients_data": "داده های مشتریان",
    "account": "حساب",
    "user_acc_info": "اطلاعات حساب کاربری",
    "current_plan": "طرح فعلی",
    "payments": "پرداخت ها",
    "location": "مکان",
    "location_list": "لیست مکان",
    "users": "کاربران",
    "users_sub_title": "لیست همه کاربران و مشخصات آنها",
    "online_profile": "نمایه آنلاین",
    "change_password": "رمز عبور را تغییر دهید",
    "menu": "منو",
    "reseller_dashboard": "داشبورد نمایندگی فروش",
    "companies": "شرکت ها",
    "plans_bought": "طرح های خریداری شده",
    "agreement_t_c": "قرارداد T&C",
    "wifi_dashboard": "داشبورد وای فای",
    "hot_spot": "هات اسپات",
    "splash_pages": "صفحات چلپ چلوپ",
    "get_more_reviews": "دریافت نظرات بیشتر",
    "office_supplies": "لوازم اداری",
    "reservation": "رزرو",
    "floor_table": "طبقه و میز",
    "guest_smart_wifi": "وای فای هوشمند مهمان",
    "digital_signage": "تابلوهای دیجیتال",
    "schedule_down": "برنامه ریزی",
    "business_settings": "تنظیمات کسب و کار",
    "sub_user_login": "ورود زیرکاربر",
    "help_center": "مرکز راهنمایی",
    "switch_to_hub": "به Hub بروید",
    "meeting_records": "سوابق جلسه",
    "logout": "خروج از سیستم",
    "no_access_text": "شما در حال حاضر دسترسی ندارید. لطفا با مدیر خود تماس بگیرید",
    "menu_item": "آیتم منو",
    "menu_capital": "MENU",
    "good_day": "روز بخیر",
    "no_permission_text": "شما اجازه انجام این عمل را ندارید!!",
    "enter_valid_pin": "پین معتبر وارد کنید",
    "add_reservation": "رزرو را اضافه کنید",
    "edit_reservation": "ویرایش رزرو",
    "per_req": "تعداد نفرات الزامی است",
    "req_block_time": "زمان بلوک مورد نیاز است",
    "pending_status": "در انتظار",
    "booked_status": "رزرو شده است",
    "completed_status": "تکمیل شد",
    "cancelled_status": "لغو شد",
    "reservation_slot": "اسلات رزرو",
    "block_time": "زمان بلوک کردن",
    "slot_time": "زمان اسلات رزرو:  ",
    "template_name_req": "نام قالب الزامی است",
    "template_type_req": "نوع قالب مورد نیاز است",
    "sms_template_req": "قالب پیامک الزامی است",
    "group_req": "گروه مورد نیاز است",
    "valid_date_time": "تاریخ و زمان معتبر را وارد کنید",
    "valid_group_name": "نام گروه معتبر را وارد کنید",
    "reservation_mark_completed": "این رزرو به عنوان تکمیل شده علامت گذاری می شود.",
    "customer_name": "نام مشتری",
    "date_time": "تاریخ زمان",
    "table_no": "جدول شماره",
    "floor": "طبقه",
    "customer_name_placeholder": "نام مشتری",
    "date_time_placeholder": "date_time",
    "table_no_placeholder": "جدول_شماره",
    "floor_placeholder": "طبقه",
    "select_floor": "طبقه را انتخاب کنید",
    "select_table": "جدول را انتخاب کنید",
    "customer_name_macro": "%% customer_name %%",
    "date_time_macro": "%% date_time %%",
    "table_no_macro": "%% جدول_خیر %%",
    "floor_macro": "%% طبقه %%",
    "template_name": "نام الگو",
    "login_with_4_digit_pin": "با پین 4 رقمی خود وارد شوید",
    "login_now_to_access_latest_insights": "برای دسترسی به آخرین نشانه ها اکنون وارد شوید",
    "mkt_BS_Title": "بلاست اس ام اس",
    "step_1": "سفارشی کردن پیام",
    "step_2": "مخاطب را انتخاب کنید",
    "step_3": "برنامه زمانبندی پیام",
    "step_4": "ارسال پیام",
    "step_5": "مدیریت پیام",
    "bs_tab_title": "خلاصه کمپین انفجار",
    "default_message": "سلام %% اسم %%, تخفیف ویژه برای برانچ! امروز با ما ناهار بخورید و 10٪ تخفیف منحصر به فرد در صورتحساب خود دریافت کنید!",
    "bs_Title": "نوع کمپین را انتخاب کنید",
    "sms_title": "SMS\/MMS",
    "reach_out_to_customer": "از طریق پیام های متنی و چند رسانه ای با مشتریان ارتباط برقرار کنید.",
    "wp_text": "واتس اپ",
    "connect_with_wp": "با استفاده از واتس اپ با مشتریان خود ارتباط برقرار کنید",
    "send_discount_offers": "ارسال پیشنهادات تخفیف از طریق پیامک 📲",
    "send_coupons_via_sms": "کوپن ها را از طریق پیامک برای مشتریان خود ارسال کنید 🎟️",
    "env_Cap": "برای جذب مشتریان خود، تخفیف ها و تبلیغات منحصر به فرد ارائه دهید",
    "cmt_Caption": "از طریق پیام های متنی و چند رسانه ای با مشتریان ارتباط برقرار کنید",
    "wp_caption": "برای ارتباط مستقیم و بدون درز با استفاده از واتس اپ با مشتریان ارتباط برقرار کنید",
    "msg_Prev_Title": "پیش نمایش پیام",
    "cou_Prev_Title": "پیش نمایش کوپن",
    "blast_SMS": "کمپین SMS\/MMS",
    "bs_P_Msg": "در صف",
    "bs_S_Msg_C": "شکست خورد",
    "bs_F_Msg_Count": "تحویل داده شد",
    "previous": "قبلی",
    "next_ST": "بعدی",
    "next_S": "مرحله بعدی",
    "req_Temp_Name_Title": "نام قالب الزامی است",
    "req_Template_Tx_Title": "متن الگو مورد نیاز است",
    "add_Temp_Title": "اضافه کردن الگو",
    "link_Desc": "پیوند دلخواه خود را اضافه کنید تا مشتریان بتوانند از طریق فیس بوک، گوگل یا وب سایت خود با شما رزرو کنند.",
    "audience_Title": "مخاطب",
    "client_G1_Title": "مشتریان جدید",
    "client_G2_Title": "مشتریان اخیر",
    "client_G3_Title": "مشتریان وفادار",
    "client_G4_Title": "مشتریان از کار افتاده",
    "client_G5_Title": "مشتریان بر اساس تاریخ اتصال",
    "on_Which_Day": "در کدام روز",
    "on_Day": "در روز",
    "immediate_Desc": "پیام شما بلافاصله ارسال خواهد شد، لطفاً برای شروع روی Next کلیک کنید.",
    "never_Desc": "این کمپین قرار است برای همیشه اجرا شود تا زمانی که به صورت دستی آن را خاتمه دهید.",
    "end_Desc": "کمپین در تاریخی که از اینجا انتخاب می کنید به پایان می رسد.",
    "success_Message": "کمپین با موفقیت ذخیره شد!",
    "send_Success_Message": "کمپین با موفقیت ارسال شد!",
    "msg_Name_Field_Title": "عنوان کمپین",
    "valid_campaign_title": "عنوان کمپین معتبر را وارد کنید",
    "msg_Type_Title": "نوع پیام",
    "sms_Desc": "1 اعتبار برای هر پیامک",
    "mms_Desc": "3 اعتبار در هر MMS",
    "client_GTO_1": "روز",
    "client_GTO_2": "ساعت",
    "minutes": "دقیقه",
    "client_GTO_4": "هفته",
    "time_Option_1": "روز",
    "time_Option_2": "ساعت",
    "c_Grp_Day_1": "90 روز",
    "c_Grp_Day_2": "60 روز",
    "c_Grp_Day_3": "30 روز",
    "first": "اول",
    "second_T": "دوم",
    "third_T": "سوم",
    "fourth_T": "چهارم",
    "fifth_T": "پنجم",
    "delete_BS_T": "این کمپین برای همیشه حذف خواهد شد.",
    "delete_MBS_T": "این کمپین ها برای همیشه حذف خواهند شد.",
    "cFT_1": "تلفن",
    "cFT_2": "نام تماس",
    "cFT_3": "نام خانوادگی تماس",
    "cFT_4": "ایمیل تماس",
    "cFT_5": "توجه داشته باشید",
    "cFT_6": "تولد",
    "cFT_7": "نام شرکت",
    "cF_Meta_Tag_1": "%% تلفن %%",
    "cF_Meta_Tag_2": "%% نام کوچک %%",
    "cF_Meta_Tag_3": "%% نام خانوادگی %%",
    "cF_Meta_Tag_4": "%% ایمیل %%",
    "cF_Meta_Tag_5": "%% یادداشت %%",
    "cF_Meta_Tag_6": "%% تولد %%",
    "cF_Meta_Tag_7": "%% شرکت_نام %%",
    "cF_Name_1": "تلفن",
    "cF_Name_2": "نام کوچک",
    "cF_Name_3": "نام خانوادگی",
    "cF_Name_4": "ایمیل",
    "cF_Name_5": "توجه داشته باشید",
    "cF_Name_6": "تولد",
    "cF_Name_7": "نام شرکت",
    "all_clients": "همه مشتریان",
    "aud_Type_2": "ClientGroups",
    "aud_Type_3": "گروه های مشتریان",
    "aud_Type_T1": "همه مشتریان",
    "aud_Type_T2": "گروه های مشتری",
    "aud_Type_T3": "گروه های مشتری",
    "new_clients": "مشتریان جدید",
    "client_G2": "مشتریان اخیر",
    "client_G3": "مشتریان وفادار",
    "client_G4": "مشتریان از کار افتاده",
    "client_G5": "مشتریان بر اساس تاریخ اتصال",
    "never_text": "هرگز",
    "ending_on": "پایان در",
    "send_T2": "برنامه ریزی شده است",
    "send_Type_3": "تکرار شونده",
    "daily": "روزانه",
    "weekly_T": "هفتگی",
    "monthly_T": "ماهانه",
    "yearly_T": "سالانه",
    "each": "هر کدام",
    "on_the": "در",
    "monday": "دوشنبه",
    "exclusive_offer": "پیشنهاد انحصاری برای شما",
    "redeem_now": "اکنون بازخرید کنید",
    "redeem_with_cashier": "لطفا با صندوقدار بازخرید کنید",
    "lg_Day_2": "سه شنبه",
    "lg_Day_3": "چهارشنبه",
    "lg_Day_4": "پنج شنبه",
    "lg_Day_5": "جمعه",
    "lg_Day_6": "شنبه",
    "lg_Day_7": "یکشنبه",
    "msg_Pre_T": "دومینو",
    "cA_Edit_T1": "همه",
    "cA_Edit_T2": "گنجانده شده است",
    "cA_Edit_T3": "مستثنی شده است",
    "SMS": "اس ام اس",
    "MMS": "MMS",
    "usd_T": "USD",
    "cad_T": "CAD",
    "msg": "پیام",
    "which_Day": "کدام روزها",
    "end_Date_Tx": "تاریخ پایان",
    "sDate_Err": "تاریخ شروع باید بزرگتر یا مساوی با امروز باشد",
    "eDate_Err": "تاریخ پایان باید دیرتر از تاریخ شروع باشد",
    "start_Date_Req": "تاریخ شروع الزامی است",
    "end_Date_Req": "تاریخ پایان الزامی است",
    "time_req": "زمان لازم است",
    "client_GT1": "مشتریان در آخرین مشترک",
    "client_GT2": "مشتریان با اتصال در آخرین",
    "client_GT3": "مشتریان با حداقل",
    "client_GT4": "با آخرین",
    "client_GT5": "که در آخرین بار برنگشت",
    "dummy_Phone_No": "+91987-654-3210",
    "test_T": "تست کنید",
    "dummy_Birth_Date": "01-01-2001",
    "image_Req": "رسانه یا URL مورد نیاز است",
    "time_Title": "زمان",
    "start_date": "تاریخ شروع",
    "end_date": "تاریخ پایان",
    "auto_Send_T": "ارسال خودکار به مشتریان",
    "add_Media": "رسانه اضافه کنید",
    "in_Title": "در",
    "c_Temp_T": "قالب کمپین",
    "temp_NT": "نام الگو",
    "type_T": "تایپ کنید",
    "select_template_type": "نوع قالب را انتخاب کنید",
    "sel_Temp_T": "نوع الگو را انتخاب کنید",
    "sms_temp_t": "قالب پیامک",
    "temp_T": "متن الگو",
    "default_Msg": "سلام %% اسم %%, تخفیف ویژه برای برانچ! امروز با ما ناهار بخورید و 10٪ تخفیف منحصر به فرد در صورتحساب خود دریافت کنید!",
    "refill_T": "دوباره پر کنید",
    "avl_Credit": "اعتبارات موجود",
    "req_Credit": "کل اعتبارات مورد نیاز",
    "rem_Credit": "اعتبار باقی مانده",
    "refill_Credit": "اعتبارهایی که باید دوباره پر شوند",
    "clients_Req": "حداقل یک مشتری را انتخاب کنید",
    "subclient_Req": "مشتریان منتخب مشترک نیستند.",
    "refill_Desc": "لطفا اعتبارات مورد نیاز را دوباره پر کنید",
    "url_Regex": "^((http|https):\/\/).....+$",
    "sd_Invalid": "تاریخ شروع نامعتبر است",
    "ed_Invalid": "تاریخ پایان نامعتبر است",
    "img_Url_Invalid": "URL تصویر نامعتبر است",
    "time_Invalid": "زمان نامعتبر است",
    "time_And_Date_Invalid": "تاریخ و زمان معتبری وارد کنید",
    "time_Invalid_Bfr": "زمانی را حداقل 5 دقیقه بعد از زمان فعلی انتخاب کنید",
    "sod_Req": "ارسال در روز الزامی است",
    "add_Card": "یک کارت جدید اضافه کنید",
    "sm_D_Rep_Title": "گزارش تحویل SMS\/MMS",
    "send_Now_T": "اکنون ارسال کنید",
    "schd_Now_T": "اس ام اس برنامه ریزی کنید",
    "test_SMS_T": "ارسال اس ام اس تست",
    "save_AD_T": "ذخیره به عنوان پیش نویس",
    "back_TE_T": "بازگشت به ویرایش",
    "reset_tex": "بازنشانی کنید",
    "update_tex": "به روز رسانی",
    "dum_msg1": "برای نمایش نام مشتری، %% customer_name %% در قالب استفاده کنید",
    "dum_msg2": "برای نمایش زمان رزرو، %% date_time %% در قالب استفاده کنید",
    "dum_msg3": "برای نمایش شماره جدول، %% table_no %% در قالب استفاده کنید",
    "embedded_link": "پیوند تعبیه شده",
    "img_title": "تصویر",
    "img_input_text": "متن:",
    "img_join_text": " برای پیوستن به لیست ما",
    "img_copy_text": "کپی کنید",
    "img_not_found": "تصویر تولید شده یافت نشد!!",
    "or_text": "یا",
    "web_signup_image_content": "با ارسال این فرم و ثبت نام از طریق متن، با دریافت پیامک های بازاریابی (مانند کدهای تبلیغاتی و یادآوری) موافقت می کنید. ممکن است نرخ پیام و داده اعمال شود. فرکانس پیام متفاوت است. می توانید در هر زمان با پاسخ دادن به STOP اشتراک خود را لغو کنید",
    "img_download_success": "تصویر با موفقیت دانلود شد",
    "embedded_link_copied": "پیوند جاسازی شده با موفقیت کپی شد",
    "media_url_required": "رسانه یا آدرس اینترنتی مورد نیاز است",
    "invalid_image_url": "آدرس اینترنتی تصویر نامعتبر است",
    "invalid_file": "فایل نامعتبر است",
    "image_error_1mb": "لطفا تصویری کمتر از 1 مگابایت وارد کنید",
    "image_error_2_5mb": "لطفاً تصویری کمتر از 2.5 مگابایت وارد کنید",
    "image_error_3mb": "لطفا تصویری کمتر از 3 مگابایت وارد کنید",
    "change_title": "تغییر دهید",
    "drop_file_text": "فایل را اینجا رها کنید یا برای آپلود کلیک کنید",
    "apply_text": "درخواست کنید",
    "search_here": "اینجا جستجو کنید",
    "update_status": "به روز رسانی وضعیت",
    "reservation_deleted_permanently": "این رزرو برای همیشه حذف خواهد شد.",
    "table_text": "جداول",
    "add_table_button": "اضافه کردن جدول جدید",
    "add_table_text": "اضافه کردن جدول",
    "edit_table_text": "ویرایش جدول",
    "table_delete_text": "این جدول برای همیشه حذف خواهد شد.",
    "multiple_table_delete_text": "این جداول برای همیشه حذف خواهند شد.",
    "table_error_name": "لطفا نام جدول را وارد کنید",
    "table_error_invalid_name": "لطفاً نام جدول معتبر را وارد کنید",
    "table_error_floor": "لطفاً طبقه را انتخاب کنید",
    "table_error_select": "لطفا جدول را انتخاب کنید",
    "table_capacity_text": "ظرفیت جدول",
    "capacity_text": "ظرفیت",
    "table_occasion_text": "میز خود را برای هر موقعیتی پیدا کنید",
    "table_name_text": "نام جدول",
    "table_capacity_error": "ظرفیت میز الزامی است",
    "cancel_text": "لغو کنید",
    "submit_text": "ارسال کنید",
    "select_valid_date": "تاریخ معتبر را انتخاب کنید",
    "select_valid_time": "زمان معتبر را انتخاب کنید",
    "valid_contact_number": "شماره تماس معتبر را وارد کنید",
    "date_req": "تاریخ لازم است",
    "date_error_later_than_today": "تاریخ باید دیرتر یا مساوی با تاریخ امروز باشد",
    "time_error_later_than_5_minutes": "زمان را حداقل 5 دقیقه بعد از زمان فعلی انتخاب کنید",
    "number_person_req": "تعداد نفر الزامی است",
    "contact_no_req": "شماره تماس الزامی است",
    "contact_no": "شماره تماس",
    "select_time": "زمان را انتخاب کنید",
    "for_how_many_person": "برای چند نفر؟",
    "f_name": "نام",
    "l_name": "نام خانوادگی",
    "business_name": "نام کسب و کار",
    "business_no": "شماره کسب و کار",
    "no_for_sign_in": "این شماره برای ورود استفاده می شود",
    "business_email": "ایمیل تجاری",
    "notes_tex": "یادداشت ها",
    "floor_deleted_permanently": "این طبقه برای همیشه حذف خواهد شد.",
    "add_floor": "اضافه کردن طبقه",
    "edit_floor": "ویرایش طبقه",
    "name_text": "نام",
    "mobile_no": "شماره موبایل",
    "person_text": "شخص",
    "date_and_time": "تاریخ و زمان",
    "actions_text": "اقدامات",
    "extra_text": "اضافی",
    "floor_name_req": "نام طبقه الزامی است",
    "floor_name": "نام طبقه",
    "status_text": "وضعیت",
    "category_status_req": "وضعیت دسته الزامی است",
    "table_deleted_permanently": "این جدول برای همیشه حذف خواهد شد.",
    "tables_deleted_permanently": "این جداول برای همیشه حذف خواهند شد.",
    "back_to_home": "بازگشت به خانه",
    "link_copied_text": "پیوند در کلیپ بورد کپی شد",
    "cust_dash_head_to_title": "به",
    "cust_dash_head_location_title": "مکان",
    "select_location_title": "مکان را انتخاب کنید",
    "all_locations_label": "all_locations",
    "rep_customer_feedback_analytics": "😊 بازخورد مشتریان خود را با تجزیه و تحلیل زمان واقعی پیگیری کنید",
    "rep_customer_txt": "مشتریان",
    "rep_delete_title": "این شهرت برای همیشه حذف خواهد شد.",
    "rep_qr_def_txt": "کد QR را اسکن کنید و نظرات ارزشمند خود را در مورد تجربه خود با ما در میان بگذارید.",
    "delete_title": "حذف کنید",
    "user_list_title": "لیست مشتریان",
    "os_nfc_txt": "کارت های NFC",
    "os_sign_holder_txt": "پایه نگهدارنده علامت",
    "os_store_Sign_holder_txt": "دارندگان علامت فروشگاه",
    "StaT": "آمار",
    "AllgT": "همه گروه ها",
    "FavT": "مورد علاقه",
    "MostActT": "فعال ترین",
    "grT": "نام گروه",
    "keywT": "کلمات کلیدی",
    "exSubT": "پیام برای مشترک موجود",
    "ArchT": "بایگانی شد",
    "gNotiSms": "هر مخاطب جدید را از طریق پیامک به من اطلاع دهید",
    "gNotiEmail": "در مورد هر مخاطب جدید از طریق ایمیل به من اطلاع دهید",
    "reqGName": "نام گروه الزامی است",
    "validGN": "نام گروه معتبر را وارد کنید",
    "valid_phone_no": "شماره تلفن معتبر را وارد کنید",
    "phone_no_req": "شماره تلفن الزامی است",
    "required_message_text": "پیام لازم است",
    "required_discount_text": "تخفیف الزامی است",
    "less_than_discount": "مبلغ تخفیف باید کمتر از 5000 باشد",
    "discount_percentage_invalid": "درصد تخفیف باید کمتر یا مساوی 100 باشد",
    "discount_type_req": "نوع تخفیف الزامی است",
    "discount_text_req": "متن کوپن الزامی است",
    "reqContactNo": "شماره تماس الزامی است",
    "reqMsgNT": "عنوان کمپین الزامی است",
    "reqLinkT": "پیوند لازم است",
    "delMGT": "این گروه ها برای همیشه حذف خواهند شد.",
    "defMemMsg": "پیام خود را اینجا تایپ کنید",
    "add_cust_to_grp_title": "افزودن مشتری به گروه",
    "group_title": "گروه ها",
    "group_create_title": "ایجاد گروه",
    "group_name_txt": "نام گروه",
    "group_table_keyword_title": "کلمه کلیدی",
    "actions_title": "اقدامات",
    "clients_title": "مشتریان",
    "send_title": "ارسال کنید",
    "qr_title": "QR",
    "delete_group_txt": "این گروه برای همیشه حذف خواهد شد.",
    "delete_groups_txt": "این گروه ها برای همیشه حذف خواهند شد.",
    "delete_client_title": "این مشتری برای همیشه حذف خواهد شد.",
    "delete_clients_title": "این کلاینت ها برای همیشه حذف خواهند شد.",
    "delete_multiple_title": "حذف چندگانه",
    "wel_sms_mms": "SMS\/MMS خوش آمدگویی ارسال شود؟",
    "key_words_title": "کلمات کلیدی",
    "srch_plch_txt": "اینجا جستجو کنید",
    "req_location_id_title": "مکان مورد نیاز است",
    "create_text": "ایجاد کنید",
    "view_text": "مشاهده کنید",
    "immediately": "بلافاصله",
    "business_name_req": "نام تجاری الزامی است",
    "business_no_req": "شماره کسب و کار الزامی است",
    "valid_business_name": "نام کسب و کار معتبر را وارد کنید",
    "valid_business_no": "شماره کسب و کار معتبر را وارد کنید",
    "address_req": "آدرس الزامی است",
    "valid_address": "لطفا آدرس معتبر را انتخاب کنید",
    "time_zone_req": "منطقه زمانی مورد نیاز است",
    "image_req": "لطفا تصویری کمتر از 2.5 مگابایت وارد کنید",
    "valid_file": "فایل نامعتبر است",
    "logo": "لوگو",
    "time_zone": "منطقه زمانی",
    "save": "ذخیره کنید",
    "account_type_req": "نوع حساب مورد نیاز است",
    "gst_no_req": "شماره GST مورد نیاز است",
    "gst_no_valid": "شماره GST معتبر وارد کنید",
    "set_up_payments": "پرداخت ها را تنظیم کنید",
    "billing_details": "جزئیات صورتحساب خود را وارد کنید",
    "billing_details_desc": "جزئیات صورتحساب شما در صورتحساب ماهانه شما از منوی آنلاین نمایش داده می شود.",
    "account_type": "نوع حساب",
    "select_account_type": "نوع حساب را انتخاب کنید",
    "gst_no": "شماره GST",
    "transaction_details": "جزئیات معامله",
    "payment_method": "روش پرداخت",
    "billing_period": "دوره صورتحساب",
    "paid_by": "پرداخت شده توسط",
    "download": "دانلود کنید",
    "pay_now": "اکنون پرداخت کنید",
    "pull_down_refresh": "برای تازه کردن، پایین بکشید",
    "release_refresh": "برای بازخوانی رها کنید",
    "billing_details_text": "جزئیات صورتحساب",
    "payment_methods": "روش های پرداخت",
    "invoice": "فاکتور",
    "invoice_to": "فاکتور به:",
    "issue_date": "تاریخ صدور",
    "due_date": "سررسید",
    "amount_due": "مبلغ سررسید",
    "charges": "اتهامات",
    "sub_total_capitalize": "زیر مجموع",
    "hst": "HST",
    "grand_total": "کل بزرگ",
    "invoice_generated_on": "فاکتور ایجاد شد",
    "contact_us": "تماس با ما",
    "invoice_issue": "اگر صورت حساب خود را بررسی کرده اید و هنوز سوالی دارید",
    "call": "تماس بگیرید",
    "send_sms": "ارسال اس ام اس",
    "payment_success": "پرداخت با موفقیت انجام شد",
    "edit_credit_card": "ویرایش کارت اعتباری",
    "add_credit_card": "اضافه کردن کارت اعتباری",
    "modify_card_info": "اطلاعات کارت خود را اصلاح کنید",
    "enter_card_info": "اطلاعات کارت خود را وارد کنید",
    "account_no_req": "شماره حساب الزامی است",
    "card_name_req": "نام روی کارت الزامی است",
    "expiry_date_req": "تاریخ انقضا الزامی است",
    "valid_expiry_date": "تاریخ انقضای معتبر را وارد کنید",
    "valid_cvv": "cvv معتبر وارد کنید",
    "cvv_req": "CVV الزامی است",
    "card_no": "شماره کارت",
    "name_of_card": "نام روی کارت",
    "expiry": "انقضا",
    "mm_text": "MM\/YY",
    "cvv": "CVV",
    "set_as_default": "به عنوان پیش فرض تنظیم کنید",
    "add_new_card": "اضافه کردن کارت جدید",
    "all_credit_card": "همه کارت های اعتباری شما",
    "fastest_checkout": "سریعترین بررسی با پلت فرم ایمن و قابل اعتماد، مورد اعتماد گروه بزرگی از مشاغل",
    "coupon_button_req": "متن دکمه کوپن مورد نیاز است",
    "max19_characters_allowed": "حداکثر 19 کاراکتر مجاز است",
    "fine_print_text_req": "متن چاپ دقیق مورد نیاز است",
    "clients_req": "حداقل یک مشتری را انتخاب کنید",
    "client_groups": "ClientGroups",
    "day_text": "روز",
    "valid_days": "مقدار روز خیلی بزرگ است روزهای معتبر را وارد کنید",
    "hours_text": "ساعت",
    "enter_valid_hours": "مقدار ساعت خیلی بزرگ است ساعت های معتبر را وارد کنید",
    "enter_valid_min": "مقدار دقیقه خیلی بزرگ است دقیقه های معتبر را وارد کنید",
    "clients_with_connection_required": "مشتریان با اتصال در آخرین مورد نیاز است",
    "connection_required": "اتصال مورد نیاز است",
    "connection_value_too_large": "مقدار اتصالات خیلی بزرگ است اتصال معتبر را وارد کنید",
    "minutes_required": "دقیقه لازم است",
    "clients_with_at_least_connection_required": "مشتریان با حداقل اتصال مورد نیاز است",
    "last_minutes_req": "با آخرین دقایق لازم است",
    "clients_not_returned_required": "کسانی که در دقایق پایانی برنگشتند الزامی است",
    "not_return_in_last_minutes_invalid": "عدم بازگشت در دقایق پایانی باید کمتر از دقایق پایانی باشد.",
    "customerGroups": "گروه های مشتریان",
    "select_customer_group": "لطفا گروه مشتری را انتخاب کنید",
    "location_required": "مکان مورد نیاز است",
    "start_date_required": "تاریخ شروع الزامی است",
    "start_date_invalid": "تاریخ شروع نامعتبر است",
    "start_date_invalid_error": "تاریخ شروع باید بزرگتر یا برابر با امروز باشد",
    "recurring": "تکرار شونده",
    "end_date_required": "تاریخ پایان الزامی است",
    "end_date_invalid": "تاریخ پایان نامعتبر است",
    "end_date_invalid_error": "تاریخ پایان باید دیرتر از تاریخ شروع باشد",
    "time_invalid": "زمان نامعتبر است",
    "monthly_text": "ماهانه",
    "send_day_req": "ارسال در روز الزامی است",
    "loyal_clients": "مشتریان وفادار",
    "recent_clients": "مشتریان اخیر",
    "lapsed_clients": "مشتریان از کار افتاده",
    "clients_connection_date": "مشتریان بر اساس تاریخ اتصال",
    "scheduled_text": "برنامه ریزی شده است",
    "weekly": "هفتگی",
    "selected_clients_not_subscriber": "مشتریان منتخب مشترک نیستند.",
    "message_preview_title": "پیش نمایش پیام",
    "coupon_preview_title": "پیش نمایش کوپن",
    "form_text": "فرم",
    "preview_text": "پیش نمایش",
    "next_text": "بعدی",
    "send_test_SMS": "ارسال اس ام اس تست",
    "save_draft": "ذخیره به عنوان پیش نویس",
    "back_to_edit": "بازگشت به ویرایش",
    "select_payment_method": "لطفا روش پرداخت را انتخاب کنید",
    "schedule_SMS": "اس ام اس برنامه ریزی کنید",
    "send_now": "اکنون ارسال کنید",
    "get_more_ratings": "رتبه بندی های بیشتری دریافت کنید",
    "overview": "نمای کلی",
    "reset_campaign": "بازنشانی کمپین",
    "permissions": "مجوزها",
    "location_name": "نام مکان",
    "phone_no": "شماره تلفن",
    "location_email_address": "آدرس ایمیل مکان",
    "located_business": "کسب و کار شما در کجا واقع شده است؟",
    "business_logo": "لوگوی کسب و کار",
    "congratulations": "تبریک میگم",
    "almost_done": "شما تقریباً تمام شده اید",
    "publish": "منتشر کنید",
    "about_your_business": "درباره کسب و کار شما",
    "add_your_location": "مکان خود را اضافه کنید",
    "publish_location": "انتشار مکان",
    "location_name_req": "نام مکان مورد نیاز است",
    "valid_location_name": "نام مکان معتبر را وارد کنید",
    "business_logo_req": "لوگوی کسب و کار الزامی است",
    "please_accept_terms": "لطفا شرایط و ضوابط را بپذیرید",
    "add_new_location": "افزودن مکان جدید",
    "edit_location": "ویرایش مکان",
    "add_location": "اضافه کردن مکان",
    "existing_msg_subscriber_txt": "پیام برای مشترک موجود",
    "msg_capitalize_txt": "پیام",
    "group_noti_sms": "هر مخاطب جدید را از طریق پیامک به من اطلاع دهید",
    "group_noti_email": "در مورد هر مخاطب جدید از طریق ایمیل به من اطلاع دهید",
    "group_member_msg": "به نظر می رسد شما در حال حاضر عضو گروه ما هستید!!",
    "group_mend_msg": "STOP تا پایان یابد. برای کمک کمک کنید. ممکن است نرخ پیامک و داده اعمال شود",
    "Disclaimer_title": "متن سلب مسئولیت: ",
    "group_def_msg": "ما الان آنلاین هستیم! منوی خدمات ما را بررسی کنید و قرار بعدی خود را امروز رزرو کنید.",
    "required_msg_txt": "پیام لازم است",
    "required_Key_txt": "کلمات کلیدی مورد نیاز است",
    "required_mem_msg": "پیام مشترک الزامی است",
    "client_list_title": "لیست مشتریان",
    "add_contact_txt": "افزودن مخاطب",
    "delete_all_clients_txt": "حذف همه مشتریان",
    "delete_all_clients_msg": "آیا مطمئن هستید که می خواهید همه مشتریان را حذف کنید؟ آنها را نمی توان بازیابی کرد.",
    "delete_all_txt": "حذف همه",
    "timeline_title": "خط زمانی",
    "unsubscribe_title": "لغو اشتراک",
    "subscribe_title": "مشترک شوید",
    "unsubscribe_confirm_msg": "آیا می‌خواهید این مشتری را به‌عنوان لغو اشتراک علامت‌گذاری کنید؟",
    "subscribe_confirm_msg": "آیا می خواهید این مشتری را به عنوان مشترک علامت گذاری کنید؟",
    "no_title": "خیر",
    "yes_title": "بله",
    "client_name_title": "نام مشتری",
    "source_title": "منبع",
    "contact_file_Req": "فایل مخاطبین مورد نیاز است",
    "req_title": "مورد نیاز",
    "opt_in_req": "مخاطبین وارد شده باید 100٪ شرکت کنند",
    "image_invalid_error": "فایل نامعتبر است",
    "import_contacts_msg": "وارد کردن مخاطبین",
    "csv_upload_title": "فایل CSV Contacts برای آپلود *",
    "csv_file_desc": "لطفاً فقط فایل csv را آپلود کنید - ستون‌های فایل csv - نام، نام خانوادگی، شناسه ایمیل، شماره تلفن همراه، جنسیت، DOB (MM\/DD\/YYYY) - بدون فاصله، خط تیره یا پرانتز در شماره تلفن همراه",
    "to_download_title": "برای دانلود فایل دمو اینجا کلیک کنید",
    "contains_header_title": "بله، این فایل حاوی هدر است",
    "opt_in_100_txt": "بله، این مخاطبین وارد شده 100% شرکت دارند",
    "DisT": "متن سلب مسئولیت: ",
    "gMendMsg": "STOP تا پایان یابد. برای کمک کمک کنید. ممکن است نرخ پیامک و داده اعمال شود",
    "reqKeyT": "کلمات کلیدی مورد نیاز است",
    "reqMemMsg": "ارسال پیام مشترک الزامی است",
    "reqMsgT": "پیام لازم است",
    "gMemberMsg": "به نظر می رسد شما در حال حاضر عضو گروه ما هستید!!",
    "gdefMsg": "ما الان آنلاین هستیم! منوی خدمات ما را بررسی کنید و قرار بعدی خود را امروز رزرو کنید.",
    "next_title": "بعدی",
    "male_title": "مرد",
    "male_val": "مرد",
    "female_title": "زن",
    "female_val": "زن",
    "others_txt": "دیگران",
    "others_val": "دیگران",
    "validBirthdate": "تاریخ تولد معتبر وارد کنید",
    "valid_phone_no_title": "شماره تلفن معتبر را وارد کنید",
    "required_phone_no_title": "شماره تلفن الزامی است",
    "add_new_client_txt": "یک مشتری جدید اضافه کنید",
    "update_client_txt": "به روز رسانی مشتری",
    "phone_num_text": "شماره تلفن",
    "dob_title": "DOB",
    "select_gender_title": "جنسیت را انتخاب کنید",
    "timelineTitle": "خط زمانی",
    "confirm_location": "مکان را تأیید کنید",
    "feedback_title": "بازخورد",
    "rep_question_1": "آیا تجارت تمیز بود؟",
    "rep_que_2": "غذاتون خوب بود؟",
    "rep_que_3": "آیا خدمات ما دوستانه بود؟",
    "rep_que_4": "سرویس ما سریع بود؟",
    "business_location_req_title": "محل کسب و کار الزامی است",
    "valid_location_err_txt": "لطفا آدرس معتبر را انتخاب کنید",
    "rep_management_title": "مدیریت نظرات بیشتر را دریافت کنید",
    "rep_about_desc": "شهرت به شما کمک می کند تا با درخواست بازخورد از مشتریان، رتبه گوگل را افزایش دهید",
    "preview_title": "پیش نمایش",
    "rep_preview_txt": "پیش نمایش شهرت",
    "back_title": "برگشت",
    "fine_print_txt": "لطفا با صندوقدار بازخرید کنید",
    "redeem_me_title": "مرا رستگار کن",
    "rep_nxt_visit_txt": "در بازدید بعدی شما",
    "device_type": "نوع دستگاه مورد استفاده",
    "connection_method": "روش اتصال",
    "peak_days": "روزهای اوج",
    "peak_hours": "ساعات اوج مصرف",
    "guest_by_day": "مهمان در روز",
    "guest_visit": "بازدید مهمان",
    "connection": "اتصال",
    "connection_duration": "مدت زمان اتصال",
    "guest_visit_1": "1 بار",
    "guest_visit_2": "2 بار",
    "guest_visit_3_5": "3-5 بار",
    "guest_visit_6_10": "6-10 بار",
    "guest_visit_11_25": "11-25 بار",
    "guest_visit_26_100": "26-100 بار",
    "guest_visit_100_plus": "100+ بار",
    "device_android_total": "کل اندروید",
    "device_android": "اندروید",
    "device_desktop": "دسکتاپ",
    "device_ios": "Ios",
    "device_ios_total": "کل Ios",
    "device_desktop_total": "کل دسکتاپ",
    "connection_duration_10": "<=10 دقیقه",
    "connection_duration_20": "11-20 دقیقه",
    "connection_duration_30": "21-30 دقیقه",
    "connection_duration_40": "31-45 دقیقه",
    "connection_duration_60": "46-60 دقیقه",
    "connection_method_email": "ایمیل",
    "connection_method_sms": "اس ام اس",
    "connection_method_google": "گوگل",
    "connection_method_facebook": "فیس بوک",
    "age_category_1": "<18",
    "age_category_2": "18-24",
    "age_category_3": "25-34",
    "age_category_4": "35-44",
    "age_category_5": "45-54",
    "age_category_6": "55+",
    "all_guest_txt": "همه مهمان",
    "new_Guest_txt": "مهمان جدید",
    "connections_txt": "اتصالات",
    "hotspot": "هات اسپات",
    "hotspot_list": "لیست هات اسپات",
    "add_new_hotspot": "هات اسپات جدید اضافه کنید",
    "hotspot_information": "اطلاعات هات اسپات",
    "edit_details_button": "ویرایش جزئیات",
    "wifi_info_message": "متصل شوید و از WiFi رایگان لذت ببرید",
    "connection_message": "عالی، شما به ",
    "connection_message_suffix": " وای فای",
    "wifi": "وای فای",
    "login_to_access": "برای دسترسی وارد شوید",
    "verification_code": "کد تایید",
    "verification_code_message": "لطفا کد تأیید ارسال شده را تایپ کنید ",
    "wifi_user_email": "adamo@gmail.com",
    "wifi_label": "فای",
    "your_name": "راستی اسمت چیه؟",
    "your_birthdate": "میشه بگید تاریخ تولدتون کیه؟",
    "request_guest_wifi_name": "لطفا نام وای فای مهمان را وارد کنید",
    "request_device_key": "لطفا کلید دستگاه را وارد کنید",
    "request_maximum_internet_access_length": "لطفا حداکثر طول دسترسی به اینترنت را انتخاب کنید",
    "mac_address": "آدرس مک",
    "device_port": "پورت دستگاه",
    "hardware": "سخت افزار",
    "current_uptime": "زمان کار فعلی",
    "nearby_devices": "دستگاه های نزدیک",
    "firmware": "سیستم عامل",
    "who_are_you": "تو کی هستی؟",
    "where_to_contact_you": "از کجا با شما در ارتباط باشیم؟",
    "your_area_code": "کد منطقه شما چیست؟",
    "connected": "متصل شد",
    "delete_hotspot_message": "این هات اسپات برای همیشه حذف خواهد شد.",
    "delete_multiple_hotspots_message": "این هات اسپات ها برای همیشه حذف خواهند شد.",
    "speed_error": "سرعت باید حداقل 0.01 باشد",
    "speed_max_error": "لطفاً یک مقدار تا 1024 برای سرعت نامحدود وارد کنید یا مقدار کمتری را در محدوده پشتیبانی شده انتخاب کنید",
    "device_ssid": "SSID دستگاه",
    "device_ssid_two": "SSID دستگاه دو",
    "device_ssid_two_wpa": "دستگاه SSID Two WPA",
    "device_key": "کلید دستگاه",
    "select_location": "مکان را انتخاب کنید",
    "select_maximum_internet_access_length": "حداکثر طول دسترسی به اینترنت را انتخاب کنید",
    "download_speed": "سرعت دانلود",
    "upload_speed": "سرعت آپلود",
    "network_length_1": "15 دقیقه",
    "network_length_2": "30 دقیقه",
    "network_length_3": "45 دقیقه",
    "network_length_4": "1 ساعت",
    "network_length_5": "2 ساعت",
    "network_length_6": "4 ساعت",
    "network_length_7": "6 ساعت",
    "network_length_8": "8 ساعت",
    "network_length_9": "10 ساعت",
    "network_length_10": "12 ساعت",
    "employee_wifi_name": "نام وای فای کارمند",
    "employee_wifi_password": "رمز عبور وای فای کارمند",
    "guest_wifi_name": "نام وای فای مهمان",
    "menu_other_products_txt": "سایر محصولات | منوی آنلاین",
    "menu_home_text": "صفحه اصلی | منوی آنلاین",
    "whatsapp_title": "واتساپ",
    "select_campaign_type": "نوع کمپین را انتخاب کنید",
    "select_readymade_templates": "از بین قالب های آماده انتخاب کنید یا خودتان بسازید",
    "campaign_title_required": "عنوان کمپین الزامی است",
    "type_here": "اینجا تایپ کنید...",
    "location_permission_req": "مجوز مکان مورد نیاز است",
    "platform_permission_req": "مجوز پلت فرم مورد نیاز است",
    "profile_picture": "عکس پروفایل",
    "click_to_upload": "برای آپلود کلیک کنید",
    "location_permission": "مجوز مکان",
    "pin": "سنجاق",
    "platform_permission": "مجوز پلتفرم",
    "set_pin": "پین را تنظیم کنید",
    "list_of_users": "لیست کاربران",
    "create_user": "کاربر ایجاد کنید",
    "terms_and_condition_req": "شرایط و ضوابط مورد نیاز است",
    "terms_and_conditions": "شرایط و ضوابط",
    "recommended": "توصیه می شود",
    "mo": "\/ماه",
    "unsubscribe": "لغو اشتراک",
    "cancel_subscription": "آیا مطمئن هستید که می خواهید اشتراک را لغو کنید؟",
    "upgrade": "ارتقا دهید",
    "plan_upgraded_successfully": "طرح با موفقیت ارتقا یافت!",
    "menu_online": "منوآنلاین",
    "support": "پشتیبانی کنید",
    "wifiC": "WIFI",
    "billing": "صورتحساب",
    "please_upgrade": "لطفا برای لذت بردن از همه ویژگی ها ارتقا دهید",
    "you_have_subscribed": "شما مشترک شده اید ",
    "plan": "برنامه ریزی کنید",
    "of_text": "از",
    "days": "روزها",
    "remaining_days_until_plan": " چند روز باقی مانده تا برنامه شما نیاز به به‌روزرسانی داشته باشد",
    "manage_your_screens": "صفحه نمایش خود را مدیریت کنید",
    "screens_you_save": "صفحه نمایش هایی که دارید",
    "add_more_screens": "اضافه کردن صفحه نمایش بیشتر",
    "addition_screen": "صفحه اضافه",
    "per_screen": "دلار در هر صفحه نمایش",
    "per_box": "دلار در هر جعبه",
    "shipping_changes": "هزینه های حمل و نقل",
    "upto4_boxes": "تا 4 جعبه ",
    "charge_now": "الان شارژ میگیری",
    "updated_reoccuring": "تکرار به روز شده شما خواهد بود",
    "current_reoccuring": "تکرار فعلی: ",
    "no_text": "خیر",
    "android_box": "اندروید باکس",
    "old_password_req": "رمز عبور قدیمی مورد نیاز است",
    "new_password_req": "رمز عبور جدید مورد نیاز است",
    "confirm_password_req": "تایید رمز عبور لازم است",
    "password_do_not_match": "گذرواژه ها مطابقت ندارند",
    "old_password": "رمز عبور قدیمی",
    "new_password": "رمز عبور جدید",
    "confirm_password": "رمز عبور را تایید کنید",
    "copy_working_hours": "این ساعات کاری را در تمام روزها کپی کنید؟",
    "yes_copy": "بله کپی کن",
    "closed": "بسته شد",
    "opening_time": "زمان افتتاحیه",
    "closing_time": "زمان بسته شدن",
    "description": "توضیحات",
    "file_exist": "فایل از قبل وجود دارد، لطفاً فایل دیگری را انتخاب کنید",
    "bussiness_images": "تصاویر کسب و کار",
    "display_info_on_market_place": "آیا می خواهید اطلاعات زیر را در بازار خود نمایش دهید؟",
    "profile_ready": "نمایه شما آماده است!",
    "client_book_with_online": "مشتریان اکنون می توانند به صورت آنلاین با شما رزرو کنند. برای شروع لینک زیر را به اشتراک بگذارید.",
    "copy_link": "لینک را کپی کنید",
    "see_your_profile": "نمایه خود را ببینید",
    "ok_got_it": "باشه فهمیدم",
    "preview_profile": "نمایه خود را پیش نمایش کنید و ببینید چگونه به نظر می رسد.",
    "opening_hours": "ساعات کار",
    "display_data": "نمایش داده ها",
    "manage_profile": "مدیریت نمایه",
    "phone_req": "تلفن الزامی است",
    "market_place_img_req": "تصاویر مکان بازار مورد نیاز است",
    "add_new_market_place": "بازار جدید را اضافه کنید",
    "edit_market_place": "بازار را ویرایش کنید",
    "no_reviews_yet": "هنوز نظری وجود ندارد",
    "good": "خوب",
    "average": "میانگین",
    "add_marketPlace": "بازار را اضافه کنید",
    "all_title": "همه",
    "included_title": "گنجانده شده است",
    "excluded_title": "مستثنی شده است",
    "clients_subscribed": "مشتریان در آخرین مشترک",
    "clients_groups": "گروه های مشتری",
    "customer_groups": "گروه های مشتری",
    "audience_title": "مخاطب",
    "client_gt5": "که در آخرین بار برنگشت",
    "select_all": "همه را انتخاب کنید",
    "modify": "اصلاح کنید",
    "campaign_title": "عنوان کمپین",
    "msg_type": "نوع پیام",
    "enter_discount": "تخفیف را وارد کنید",
    "discount_type": "نوع تخفیف",
    "coupon_text": "متن کوپن",
    "enter_coupon_text": "متن کوپن را وارد کنید",
    "coupon_button_text": "متن دکمه کوپن",
    "enter_coupon_button_text": "متن دکمه کوپن را وارد کنید",
    "fine_prin": "چاپ خوب",
    "enter_fine_prin": "متن چاپ دقیق را وارد کنید",
    "campaign_dec": "می توانید کمپین را 30 دقیقه قبل از زمان شروع آن تغییر دهید. هزینه کمپین پیامکی 30 دقیقه قبل از شروع کمپین انجام می شود.",
    "blast_text_message_dec": "پیام متنی انفجار شما آماده است. روش پرداخت خود را انتخاب کنید و پیام های خود را ارسال کنید.",
    "payment_completed": " پرداخت در ساعت تکمیل خواهد شد ",
    "total_cost": "هزینه کل",
    "close_title": "بستن",
    "friday": "جمعه",
    "saturday": "شنبه",
    "sunday": "یکشنبه",
    "thursday": "پنج شنبه",
    "tuesday": "سه شنبه",
    "wednesday": "چهارشنبه",
    "port_txt": "بندر",
    "today_title": "امروز",
    "yesterday_title": "دیروز",
    "last_30_days_txt": "30 روز گذشته",
    "this_month_txt": "این ماه",
    "last_month_txt": "ماه گذشته",
    "valid_date_title": "تاریخ معتبر را انتخاب کنید",
    "valid_business_name_txt": "نام کسب و کار معتبر را وارد کنید",
    "req_bus_add_txt": "آدرس کسب و کار الزامی است",
    "req_domain_name_txt": "نام دامنه مورد نیاز است",
    "basic_info_txt": "اطلاعات اولیه کسب و کار شما",
    "loyalty_qr_def_txt": "کد QR را اسکن کنید، به کارت پانچ وفاداری ما بپیوندید و از جوایز لذت ببرید.",
    "last_stamp_txt": "آخرین تمبر",
    "collected_on_txt": "جمع آوری شده در",
    "stamp_details_txt": "جزئیات تمبر",
    "add_stamp_txt": "اضافه کردن تمبر",
    "choose_brand_color_txt": "رنگ و زبان برند خود را انتخاب کنید 🎨",
    "change_anytime_txt": "شما می توانید این را در هر زمان تغییر دهید",
    "like_to_get_txt": "آیا می خواهید دریافت کنید",
    "your_next_visit_txt": "در دیدار بعدی شما؟",
    "time_to_time_get_offers": "آیا دوست دارید هر از گاهی پیشنهادات و تبلیغات عالی دریافت کنید؟",
    "respond_back_to_you": "آیا دوست دارید کسی به شما پاسخ دهد؟",
    "input_serve_better": "نظرات شما به تیم ما کمک می کند تا بهتر به شما خدمت کنند!",
    "do_it_txt": "بیایید آن را انجام دهیم",
    "take_15_s": "15 ثانیه طول می کشد",
    "rate_on_google_desc": "آیا آنقدر لطف دارید که به ما در گوگل امتیاز دهید؟",
    "may_be_next_time": "شاید دفعه بعد",
    "thank_you_txt": "متشکرم",
    "look_next_txt": "ما مشتاقانه منتظر بازدید بعدی شما هستیم!",
    "deleteUserTitle": "این کاربر برای همیشه حذف خواهد شد.",
    "deleteMUserTitle": "این کاربران برای همیشه حذف خواهند شد.",
    "change_pin": "پین را تغییر دهید",
    "area_code": "کد منطقه شما چیست؟ ",
    "add_menu": "افزودن منو",
    "menu_name": "نام منو",
    "add_menu_placeholder": "پیتزا، برگر، بیورج و غیره",
    "enable_data_Collection": "جمع آوری داده ها را فعال کنید",
    "add_new_menu": "افزودن منوی جدید",
    "rename_menu": "تغییر نام منو",
    "preview": "پیش نمایش",
    "generate_qr": "QR ایجاد شد",
    "edit_menu": "منوی ویرایش",
    "remove_menu": "حذف منو",
    "menu_delete_msg": "این منو برای همیشه حذف خواهد شد",
    "menus_delete_msg": "این منوها برای همیشه حذف خواهند شد",
    "view_menu_dialoage_msg": "دوست داری روز تولدت هدیه بگیری؟",
    "skip": "پرش کنید",
    "cliam_your_gift": "هدیه خود را مطالبه کنید",
    "collect_form": "جمع آوری فرم",
    "enter_first_name": "نام را وارد کنید",
    "enter_last_name": "نام خانوادگی را وارد کنید",
    "enter_email": "ایمیل را وارد کنید",
    "enter_dob": "تاریخ تولد را وارد کنید",
    "enter_number": "شماره را وارد کنید",
    "select_gender": "جنسیت را انتخاب کنید",
    "congratulations_desc": "🎉 تبریک می گویم! درخواست ادعای هدیه شما با موفقیت ارسال شد. تیم ما به زودی با شما تماس خواهد گرفت. 🎉",
    "male_heading": "مرد",
    "male_text": "مرد",
    "female_heading": "زن",
    "female_text": "زن",
    "others_heading": "دیگران",
    "other_text": "دیگران",
    "BirthD": "تاریخ تولد",
    "GenderT": "جنسیت",
    "EmailT": "ایمیل",
    "dobT": "DOB",
    "capitalize_menu": "منو",
    "select_menu": "منو را انتخاب کنید",
    "manage_variant": "مدیریت گزینه",
    "add_products": "افزودن محصولات",
    "add_category": "اضافه کردن دسته",
    "category_delete": "این دسته برای همیشه حذف خواهد شد",
    "variation_delete": "این نسخه از محصول حذف خواهد شد.",
    "product_delete": "این محصول برای همیشه حذف خواهد شد.",
    "categories_delete": "این دسته ها برای همیشه حذف خواهند شد.",
    "products_delete": "این محصولات برای همیشه حذف خواهند شد.",
    "category": "CATEGORY",
    "price": "PRICE",
    "food_product_placeholder": "پیتزا، برگر، بیورج و غیره",
    "active_title": "فعال",
    "inActive_title": "غیر فعال",
    "status_capital": "وضعیت",
    "cat_status_require": "وضعیت دسته الزامی است",
    "cat_name_require": "نام دسته الزامی است",
    "category_name": "نام دسته",
    "status": "وضعیت",
    "lgDay1": "دوشنبه",
    "lgDay2": "سه شنبه",
    "lgDay3": "چهارشنبه",
    "lgDay4": "پنج شنبه",
    "lgDay5": "جمعه",
    "lgDay6": "شنبه",
    "lgDay7": "یکشنبه",
    "is_closed_title": "بسته شد",
    "book_table_title": "کتاب یک جدول",
    "emailErrSub": "موضوع ایمیل الزامی است",
    "email_subject": "موضوع ایمیل",
    "contactUsfrmTitleEmail": "به ایمیل پاسخ دهید",
    "companyInfo": "لورم ایپسوم لورم هریگ است، لورم ایپسوم فریت است. لورم ایپسوم لورم هریگ است، لورم ایپسوم فریت است. لورم ایپسوم لورم هریگ است، لورم ایپسوم فریتز است.",
    "footerSTitle1": "درباره منوآنلاین",
    "footerSTitle1Lnk2": "ویژگی ها",
    "footerSTitle1Lnk3": "وبلاگ",
    "footerSTitle2": "قانونی",
    "footerSTitle2Lnk1": "شرایط و ضوابط",
    "footerSTitle2Lnk2": "سیاست حفظ حریم خصوصی",
    "footerSTitle3": "برای تجارت",
    "footerSTitle3Lnk1": "برای شرکا",
    "footerSTitle3Lnk2": "قیمت گذاری",
    "footerSTitle3Lnk3": "پشتیبانی از شرکا",
    "footerCopyRithgTxt": "  منوآنلاین یا شرکت های وابسته به آن",
    "homeTitle": "صفحه اصلی",
    "contactTitle": "تماس با ما",
    "aboutTitle": "درباره ما",
    "homeMenuTitle": "منو",
    "bookTableTitle": "کتاب یک جدول",
    "vDateT": "تاریخ معتبر را انتخاب کنید",
    "reqDateT": "تاریخ لازم است",
    "dateGtErr": "تاریخ باید دیرتر یا مساوی با تاریخ امروز باشد",
    "timeInvalid": "زمان نامعتبر است",
    "reqTimeT": "زمان لازم است",
    "timeInvalidBfr": "زمان را حداقل 5 دقیقه بعد از زمان فعلی انتخاب کنید ",
    "PerReq": "تعداد نفر الزامی است",
    "validcNoT": "شماره تماس معتبر را وارد کنید",
    "reqCrT": "ارز مورد نیاز است",
    "reqPrT": "قیمت الزامی است",
    "reCateT": "دسته بندی الزامی است",
    "reqVrT": "نوع مورد نیاز است",
    "reqVrVldT": "Valid Variant را وارد کنید ",
    "validLNameT": "نام خانوادگی معتبر وارد کنید",
    "sDateInValid": "از تاریخ نامعتبر است",
    "minmReq": "حداقل مورد نیاز است",
    "resLblDate": "تاریخ را انتخاب کنید",
    "resLblTime": "زمان را انتخاب کنید",
    "perT": "برای چند نفر؟",
    "resLblEmail": "لطفا ایمیل خود را ارائه دهید؟",
    "resLblNote": "یک یادداشت رزرو اضافه کنید",
    "imageError1MB": "لطفا تصویری کمتر از 1 مگابایت وارد کنید",
    "imageError": "لطفا تصویری کمتر از 2.5 مگابایت وارد کنید",
    "imageError3Mb": "لطفا تصویری کمتر از 3 مگابایت وارد کنید",
    "imageInVldError": "فایل نامعتبر است",
    "addProMT": "منوی محصول",
    "proT": "نام محصول",
    "reqProT": "نام محصول الزامی است",
    "proPT": "قیمت محصول",
    "reservationSuccTitle": "عالی است، رزرو شما انجام شده است",
    "book": "کتاب",
    "custzName": "نام سفارشی سازی",
    "always": "همیشه",
    "proImgReq": "تصویر محصول مورد نیاز است",
    "selCustmT": "لطفاً سفارشی سازی را انتخاب کنید یا جدید ایجاد کنید",
    "visStR": "وضعیت دید مورد نیاز است",
    "avlblScR": "لطفاً برنامه زمانی در دسترس را انتخاب کنید",
    "addonPR": "لطفا محصولات افزونه را انتخاب کنید",
    "upsellPR": "لطفا محصولات پرفروش را انتخاب کنید",
    "markItemR": "لطفاً محصولات را علامت گذاری کنید",
    "caloriesR": "کالری مورد نیاز است",
    "allergensR": "لطفا محصولات حساسیت زا را انتخاب کنید",
    "prepInstR": "دستورالعمل های آماده سازی مورد نیاز است",
    "staffNR": "یادداشت کارکنان الزامی است",
    "discountR": "تخفیف الزامی است",
    "validDiscE": "تخفیف معتبر وارد کنید",
    "disday": "به روز",
    "plSelDayT": "لطفا روز را انتخاب کنید",
    "sTimeReq": "زمان شروع مورد نیاز است",
    "sTimeInValid": "زمان شروع نامعتبر است",
    "eTimeReq": "زمان پایان مورد نیاز است",
    "eTimeInValid": "زمان پایان نامعتبر است",
    "sDateReq": "از تاریخ مورد نیاز است",
    "eDateReq": "تا به امروز لازم است",
    "eDateInValid": "تا به امروز نامعتبر است",
    "disdate": "از تاریخ",
    "disdate1": "به تاریخ",
    "disdate2": "از تاریخ",
    "currT": "ارز",
    "iconR": "لطفاً نماد علامت مورد را انتخاب کنید",
    "minT": "حداقل",
    "maxT": "حداکثر",
    "itemNT": "نام مورد",
    "itemPT": "قیمت مورد",
    "descProT": "محصول خود را شرح دهید",
    "cateT": "دسته بندی",
    "selProCateT": "دسته بندی محصول خود را انتخاب کنید",
    "reqMT": "نام منو الزامی است",
    "ViewMenu": "مشاهده منو",
    "CopyMenu": "منو کپی کنید",
    "EditMenu": "منوی ویرایش",
    "RemoveMenu": "حذف منو",
    "welcomeMnuTitle": "خوش آمدید به",
    "reviewT": "بررسی ها",
    "userErrorMsg": "با عرض پوزش، خطایی رخ داده است!!!",
    "reqCateT": "نام دسته الزامی است",
    "mngCustT": "مدیریت سفارشی سازی",
    "custNReq": "نام سفارشی سازی مورد نیاز است",
    "incReq": "شامل الزامی است",
    "minmValid": "حداقل مقدار معتبر را وارد کنید",
    "maxmReq": "حداکثر مورد نیاز است",
    "cust": "سفارشی سازی",
    "crCust": "سفارشی سازی جدید ایجاد کنید",
    "custList": "لیست سفارشی سازی",
    "delCustmzT": "این سفارشی سازی برای همیشه حذف خواهد شد.",
    "mkNameR": "علامت گذاری نام مورد الزامی است",
    "mkDelT": "این مورد علامت برای همیشه حذف خواهد شد.",
    "hideText": "پنهان کردن",
    "showText": "نمایش دهید",
    "device_mac_txt": "دستگاه مک",
    "delete_hotspot_txt": "این هات اسپات برای همیشه حذف خواهد شد.",
    "delete_hotspots_txt": "این هات اسپات برای همیشه حذف خواهد شد.",
    "emp_wifi_name": "نام وای فای کارمند",
    "max_limit_txt": "\"1024\" را برای سرعت وارد کنید تا آن را به عنوان نامحدود تعیین کنید. این مقدار هرگونه درپوش را حذف می کند و حداکثر توان عملیاتی را فراهم می کند",
    "device_port_txt": "پورت دستگاه",
    "firmware_txt": "سیستم عامل",
    "hotspot_info_txt": "اطلاعات هات اسپات",
    "editDBtn": "ویرایش جزئیات",
    "birth_date": "تاریخ تولد",
    "di_theme_clr": "رنگ تم دقیقا 7 رقمی است",
    "color_Invalid_txt": "کد رنگ معتبر را انتخاب کنید",
    "Req_theme_clr": "رنگ تم مورد نیاز است",
    "select_valid_color_txt": "لطفا رنگ تم معتبر را انتخاب کنید",
    "req_redir_link": "پیوند تغییر مسیر مورد نیاز است",
    "val_redir_link": "پیوند ریدایرکت معتبر را وارد کنید",
    "req_business_name_txt": "نام تجاری الزامی است",
    "splash_preview": "پیش نمایش اسپلش",
    "create_new_splash": "ایجاد اسپلش جدید",
    "splash_page": "صفحه چلپ چلوپ",
    "splash_page_builder": "Splash Page Builder",
    "redirect_link": "لینک تغییر مسیر",
    "theme_color": "رنگ تم",
    "enable_social_login": "گزینه های رسانه های اجتماعی را برای ورود کاربران فعال کنید",
    "google": "گوگل",
    "facebook": "فیس بوک",
    "is_mandatory": "آیا اجباری است؟",
    "field": "میدان",
    "name": "نام",
    "first_name": "نام",
    "last_name": "نام خانوادگی",
    "birthdate": "تاریخ تولد",
    "gender": "جنسیت",
    "email": "ایمیل",
    "dob": "DOB",
    "zip_code": "کد پستی",
    "required_redirect_link": "پیوند تغییر مسیر مورد نیاز است",
    "valid_redirect_link": "پیوند ریدایرکت معتبر را وارد کنید",
    "required_theme_color": "رنگ تم مورد نیاز است",
    "theme_color_length": "رنگ تم دقیقا 7 رقمی است",
    "required_name": "نام الزامی است",
    "delete_splash_message": "می خواهید این اسپلش را حذف کنید؟",
    "delete_multiple_splashes_message": "می خواهید این اسپلش ها را حذف کنید؟",
    "user_login_required": "ورود کاربر الزامی است",
    "set_theme_color": "تنظیم رنگ تم",
    "splash_colllect_additional_info_txt": "اطلاعات اضافی را جمع آوری کنید",
    "verify_mobile_otp_message": "آیا می خواهید OTP را برای تأیید شماره تلفن همراه ارسال کنید؟",
    "add_company": "اضافه کردن شرکت",
    "edit_company": "شرکت ویرایش",
    "ds_device": "دستگاه علامت دیجیتال",
    "ds_device_activation_fee": "هزینه فعال‌سازی دستگاه علامت دیجیتال",
    "wifi_hardware": "سخت افزار وای فای",
    "menu_design": "طراحی منو",
    "ds_hardware": "سخت افزار تابلوهای دیجیتال",
    "company_name_req": "نام شرکت الزامی است",
    "known_name_req": "نام شناخته شده الزامی است",
    "no_location_req": "شماره مکان مورد نیاز است",
    "minimum_location": "لطفا حداقل 1 مکان را وارد کنید",
    "terms_req": "شرایط لازم است",
    "notes_req": "یادداشت ها الزامی است",
    "sms_charge_req": "هزینه پیامک الزامی است",
    "menuonline_quantity_req": "منوی آنلاین تعداد مورد نیاز است",
    "menuonline_cost_req": "منو هزینه آنلاین مورد نیاز است",
    "ds_quantity_req": "تعداد تابلوهای دیجیتال مورد نیاز است",
    "ds_monthly_cost_req": "هزینه ماهانه تابلوهای دیجیتال مورد نیاز است",
    "reputation_quantity_req": "شهرت کمیت مورد نیاز است",
    "reputation_cost_req": "هزینه شهرت مورد نیاز است",
    "marketing_quantity_req": "مقدار بازاریابی مورد نیاز است",
    "marketing_cost_req": "هزینه بازاریابی الزامی است",
    "tax_percentage_req": "درصد مالیات الزامی است",
    "router_hardware_quantity_req": "مقدار سخت افزار روتر مورد نیاز است",
    "wifi_hardware_cost_req": "هزینه سخت افزار WIFI مورد نیاز است",
    "activation_cost_req": "هزینه فعال سازی الزامی است",
    "wifi_quantity_req": "مقدار WIFI مورد نیاز است",
    "wifi_router_req": "روتر Wifi مورد نیاز است",
    "menu_design_quantity_req": "تعداد طراحی منو مورد نیاز است",
    "menu_design_cost_req": "هزینه طراحی منو الزامی است",
    "quantity_req": "مقدار مورد نیاز است",
    "cost_req": "هزینه لازم است",
    "sheduledown_quantity_req": "کاهش مقدار مورد نیاز است",
    "sheduledown_cost_req": "کاهش هزینه الزامی است",
    "loyalty_program_quantity_req": "مقدار برنامه وفاداری مورد نیاز است",
    "loyalty_program_cost_req": "هزینه برنامه وفاداری الزامی است",
    "loyalty_program_sms_quantity_req": "تعداد پیامک برنامه وفاداری الزامی است",
    "loyalty_program_sms_cost_req": "هزینه پیامک برنامه وفاداری الزامی است",
    "comapny_created": "شرکت با موفقیت ایجاد شد",
    "comapny_updated": "شرکت با موفقیت به روز شد",
    "billing_info": "اطلاعات صورتحساب",
    "business_name_acc_holder": "نام تجاری (دارنده حساب)",
    "client_first_name": "نام مشتری",
    "client_last_name": "نام خانوادگی مشتری",
    "dba_known_as": "DBA شناخته شده به عنوان",
    "business_address": "آدرس کسب و کار",
    "no_of_locations": "تعداد مکان ها",
    "package_details": "جزئیات بسته",
    "terms": "شرایط",
    "one_month": "1 ماه",
    "per_sms_charge": "هزینه هر پیامک",
    "plan_test": "طرح: تست",
    "desc_capital": "توضیحات",
    "qty_capital": "تعداد",
    "cost_capital": "هزینه",
    "total_price_capital": "قیمت کل",
    "quantity": "مقدار",
    "can_not_access": "شما نمی توانید بیشتر از آن را انتخاب کنید",
    "licenses": "مجوزها",
    "monthly_cost": "هزینه ماهانه",
    "plan_cost": "هزینه طرح",
    "tax": "مالیات",
    "tax_percentage": "درصد مالیات",
    "monthly_cost_after_tax": "هزینه ماهانه پس از کسر مالیات",
    "activation_one_time_charge": "فعال سازی و یک بار شارژ",
    "sub_total": "SUB TOTAL",
    "action": "اقدام",
    "cost": "هزینه",
    "shipping_charge": "هزینه حمل و نقل",
    "other": "دیگر",
    "additional_cost": "هزینه اضافی (سایر هزینه ها)",
    "other_tax": "سایر مالیات ها",
    "total": "مجموع",
    "license_statistics": "آمار مجوز",
    "total_licenses": "مجموع مجوزها",
    "available_licenses": "مجوزهای موجود",
    "stock": "سهام",
    "has_permission": "مجوز دارد",
    "avg_stock_price": "قیمت سهام AVG",
    "average_price": "قیمت متوسط",
    "allocated": "اختصاص داده شده است",
    "reward_regulars": "به افراد عادی پاداش دهید",
    "not_add_coupon_url": "نمی توان URL کوپن اضافه کرد. حداکثر طول پیام 160 است",
    "like_attach_coupon": "آیا می خواهید یک کوپن پیوست کنید؟",
    "advance_scheduling": "برنامه ریزی قبلی",
    "choose_day": "روز(های) را برای ارسال پیام انتخاب کنید",
    "select_essage_window": "پنجره پیام را انتخاب کنید",
    "subscription_outside_delivery_window": "اگر کاربری خارج از پنجره تحویل مشترک شود، در زمان بعدی پاسخگوی خودکار شما را دریافت خواهد کرد.",
    "remaining": "باقی مانده است",
    "processing_request": "هنگام پردازش درخواست شما خطایی روی داد.",
    "list_companies": "لیست شرکت ها",
    "are_you_sure": "مطمئنی..؟",
    "signin_agreement": "با تایید این موضوع، شرکت بدون امضای قرارداد ایجاد خواهد کرد.",
    "status_updated": "به روز رسانی وضعیت با موفقیت",
    "status_failed": "وضعیت به‌روزرسانی نشد",
    "new_editor_status_updated": "وضعیت ویرایشگر جدید DS با موفقیت به روز شد.",
    "user_name": "نام کاربری",
    "known_as": "شناخته شده به عنوان",
    "agreement_signed": "توافق نامه امضا شد",
    "deactivate": "غیر فعال کردن",
    "activate": "فعال کنید",
    "login_to_user_acc": "وارد حساب کاربری شوید",
    "disable": "غیر فعال کردن",
    "enable": "فعال کردن",
    "ds_new_editor": "ویرایشگر جدید DS",
    "sign_agreement": "قرارداد امضا کنید",
    "agreement_sign": "امضای توافق نامه W\/O",
    "view_agreement": "مشاهده توافقنامه",
    "download_agreement": "توافقنامه دانلود",
    "add_deleted_user": "اضافه کردن کاربر حذف شده",
    "deleted_user": "حذف کاربر",
    "favourite": "مورد علاقه",
    "refresh": "تازه کردن",
    "delete_chat": "چت را حذف کنید",
    "responsive_drawer": "کشو پاسخگو",
    "delete_contact": "حذف مخاطب",
    "clear_chat_history": "تاریخچه چت را پاک کنید",
    "clear_chat_history_dec": "آیا مطمئن هستید که می خواهید این چت را حذف کنید؟",
    "clear_contact_dec": "آیا مطمئن هستید که می خواهید این مخاطب را حذف کنید؟",
    "select_contact": "مخاطب را انتخاب کنید",
    "new_conversation": "شروع مکالمه جدید",
    "type_msg": "پیام خود را اینجا تایپ کنید",
    "select_delete_client": "لطفاً مشتری را برای حذف انتخاب کنید",
    "select_delete_client_chat": "لطفاً مشتری را برای حذف چت انتخاب کنید",
    "select_delete_client_chat_err": "شما با این مشتری گپ ندارید",
    "acquire_customers_business": "مشتریان جدیدی را از سایر مشاغل به دست آورید.",
    "customers_from_events": "از رویدادها مشتری بگیرید و آن را برای خود بیاورید!",
    "customers_from_ads": "از تبلیغات روزنامه مشتری بگیرید.",
    "smart_pamphlets": "جزوه های هوشمند برای جمع آوری داده های مشتریان ایجاد کنید.",
    "smart_qr_group": "QR هوشمند برای پیوستن به یک گروه برای مشتریان.",
    "opt_in_number": "مشتریان را قادر به انتخاب تعداد کنید.",
    "qr_menu": "برای نمایش منوی خود کد QR ایجاد کنید.",
    "collect_birthdays": "روز تولد و سالگرد مشتری را جمع آوری کنید.",
    "join_loyalty_program": "مشتریان جدیدی را جذب کنید تا به برنامه وفاداری من بپیوندند.",
    "create_feedback_forms": "فرم هایی برای جمع آوری بازخورد مشتری ایجاد کنید.",
    "exclusive_discounts_promotions": "برای تبلیغات متقابل تخفیف و تبلیغات منحصر به فرد ارائه دهید.",
    "group_smart_qr_opt_in": "پس از ایجاد یک گروه، می توانید یک کد QR هوشمند ایجاد و انتخاب کنید، که به مشتریان امکان می دهد فوراً در یک گروه شرکت کنند.",
    "group_smart_qr_opt_in_dec": "پس از ایجاد یک گروه، ویجت تصویر به مشتریان امکان می دهد از متن استفاده کنند و تبلیغات را دریافت کنند، به عنوان مثال، \"پیتزا\" را به 555-555-5555 پیامک کنید.",
    "in_store_discount_next_visit": "در بازدید بعدی خود از محل ما از تخفیف داخل فروشگاه برخوردار شوید",
    "delivery_redirection": "تغییر مسیر تحویل را ایجاد کنید",
    "additional_info": "اطلاعات تکمیلی",
    "add_url": "URL را اضافه کنید",
    "custom_fields": "زمینه های سفارشی",
    "meta_tag": "متا تگ",
    "max_chars": "حداکثر کاراکترها",
    "add": "اضافه کنید",
    "update_campaign": "به روز رسانی کمپین",
    "last_week_required": "هفته گذشته لازم است",
    "large_week_value": "مقدار هفته خیلی بزرگ است هفته معتبر را وارد کنید",
    "less_than_last_week": "عدم بازگشت در هفته گذشته باید کمتر از هفته گذشته باشد.",
    "last_week_req": "عدم بازگشت در هفته گذشته الزامی است",
    "birthday_special": "ویژه تولد",
    "birthday_message": "پیام خودکار در روز تولد آنها با تبریک های شخصی و پیشنهادات وسوسه انگیز ارسال می شود",
    "birthday_sms": "ایجاد تبریک تولد شخصی برای SMS\/MMS",
    "target_audience": "مخاطب هدف را انتخاب کنید",
    "client_by_birthday": "مشتری با تولد",
    "clients_once_year": "این کمپین سالی یک بار به طور خودکار برای مشتریان ارسال می شود ",
    "auto_responder_default_msg_1": "شما یکی از بهترین مشتریان ما هستید که ما دوست داریم به آنها تخفیف بدهیم! انشالله همیشه ثروتمند و سلامت باشی تولدت مبارک! %% نام کوچک %%",
    "campaign_send_to_client_birthday": "این کمپین خودکار برای مشتریان با تولد ارسال می شود، می توانید هدف گذاری زیر را سفارشی کنید.",
    "get_more_engagement": "با ارسال پیامک شبانه روزی مشارکت بیشتری داشته باشید 🎂",
    "welcome_new_clients": "به مشتریان جدید خوش آمدید",
    "make_lasting_impression": "با خوشامدگویی گرم و پذیرایی از کسانی که برای اولین بار می‌آیند، تأثیری ماندگار بگذارید",
    "personalized_greetings": "برای مشتریان جدید احوالپرسی شخصی ایجاد کنید",
    "greetings_to_new_customers": "این کمپین در روز بعد از اولین تعامل آنها با کسب و کار شما، به مشتریان جدید خوشامدگویی ارسال می کند",
    "audience_predefined": "مخاطب این پاسخگوی خودکار از پیش تعریف شده است، ما این را داریم!",
    "send_clients_subscribed": "این پاسخگوی خودکار برای ارسال مشتریانی که در آخرین بار مشترک شده اند تنظیم شده است ",
    "first_interaction": "  پس از اولین تعامل آنها",
    "default_msg_2": "امیدواریم از اولین بازدید خود لذت برده باشید، مایلیم به زودی دوباره شما را ببینیم، بنابراین ما فقط برای شما تخفیف ارائه می دهیم! برای رزرو قرار بعدی خود با ما روی پیوند کلیک کنید و هم اکنون از پیشنهاد آنلاین ویژه خود استفاده کنید.",
    "new_clients_update": "به مشتریان جدید به‌روزرسانی متن کمپین خوش آمدید",
    "new_clients_warm_greeting": "با سلامی گرم از مشتریان جدید استقبال کنید 💌",
    "win_back_clients": "مشتریان را برگردانید",
    "re_engage_past_customers": "مشتریان گذشته را با پیشنهادات غیرقابل مقاومت مجدداً جذب کنید تا آنها را به بازگشت تشویق کنید",
    "target_disengaged_clients": "مشتریانی را هدف قرار دهید تا با یک پیشنهاد ویژه بازگردند، یک تاکتیک اثبات شده برای جذب مشتریان.",
    "campaign_sends_to_clients": "این کمپین برای مشتریانی ارسال می کند که پس از مدت معینی بازنگشته اند.",
    "customize_targeting": "برای ارسال SMS\/MMS به مشتریانی که از کار افتاده اند، هدف گذاری زیر را سفارشی کنید.",
    "clients_with_at_least": "مشتریان با حداقل ",
    "connection_last": " اتصال در آخرین ",
    "return_in_last_week": " هفته، اما در گذشته برنگشت ",
    "arr_menu_44": " هفته",
    "df_msg_4": "ما آنقدر می خواهیم که شما را برگردانید که فقط برای بازگشت به شما 10٪ تخفیف می دهیم! آیا می خواهید امروز دوباره ما را امتحان کنید؟",
    "update_campaign_text": "برگرداندن مشتریان به روز رسانی متن کمپین",
    "re_invite_customers": "مشتریانی را که مدتی است به آنجا مراجعه نکرده اند دوباره دعوت کنید",
    "loyal_patrons": "با تخفیف های ویژه و جوایز وفاداری از مشتریان وفادار قدردانی کنید",
    "surprize_top_spenders": "با پیشنهادات شخصی و ویژه، خرج‌کنندگان برتر را غافلگیر کنید.",
    "campaign_automatically_send": "این کمپین به طور خودکار جوایزی را برای تقویت سقوط پا در کسب و کار شما ارسال می کند.",
    "sms_loyal_clients": "این کمپین برای مشتریان وفادار پیامک\/ام ام اس ارسال می کند، می توانید هدف گذاری زیر را تغییر دهید.",
    "or_more_connection": " یا اتصال بیشتر، در آخرین ",
    "week_period": " دوره هفته",
    "default_msg_5": "شما مدت زیادی است که مشتری هستید! عجب زمان میگذره ما از شما قدردانی می کنیم و می خواهیم از اینکه همراه ما هستید سپاسگزاریم.",
    "reward_regulars_update": "به‌طور منظم متن کمپین را به‌روزرسانی کنید",
    "special_discounts": "قدردانی کنید و تخفیف های ویژه بدهید 💎",
    "leave_positive_online": "مشتریان را تشویق کنید تا نظرات مثبت آنلاین خود را برای افزایش شهرت رستوران ارائه دهند",
    "clients_service_online": "این کمپین مشتریان شما را تشویق می کند که خدمات شما را به صورت آنلاین رتبه بندی کنند.",
    "increase_your_company": "این پاسخگوی خودکار برای افزایش رتبه آنلاین شرکت شما ارسال می شود.",
    "send_message_after": "ارسال پیام بعد ",
    "min_connection": " دقیقه اتصال",
    "default_msg_6": "امیدواریم از اولین بازدید خود لذت برده باشید، مایلیم به زودی دوباره شما را ببینیم! [URL] برای امتیاز دادن به ما در Google روی پیوند کلیک کنید.",
    "ratings_update": "متن کمپین به‌روزرسانی رتبه‌بندی‌های بیشتری دریافت کنید",
    "friendly_nudge": "با یک حرکت دوستانه نظرات بیشتری دریافت کنید ⭐",
    "thanks_for_visiting": "با تشکر از شما برای بازدید",
    "express_gratitude": "با پیام های صمیمانه تشکر از مشتریان برای انتخاب کسب و کار شما ابراز قدردانی کنید",
    "thank_you_message": "برای مشتریان خود پیام تشکر ایجاد کنید.",
    "clients_for_visiting": "یک تشکر گرم از مشتریان خود برای بازدید از مکان شما ارسال کنید.",
    "campaign_audience": "این مخاطب کمپین خودکار از پیش تعریف شده است، ما این را داریم!",
    "campaign_automatically_sends": "این کمپین خودکار به طور خودکار پس از ارسال می شود ",
    "minutes_customer_visit": " دقیقه بازدید مشتری",
    "default_msg_7": "از اینکه مشتری ارزشمند ما هستید سپاسگزاریم از اینکه در خدمت شما عزیزان بوده ایم بسیار سپاسگزاریم و امیدواریم انتظارات شما را برآورده کرده باشیم.",
    "visiting_update_campaign": "از اینکه متن کمپین به روز رسانی را بازدید کردید متشکریم",
    "guests_thank_you": "از بازدید مهمانان شما سپاسگزاریم 😊",
    "download_title": "دانلود کنید",
    "qr_gen_title": "QR ایجاد شد",
    "qr_download_s_title": "کد QR با موفقیت دانلود شد",
    "sel_dark_brand_clr_msg": "لطفا رنگ باند معتبر را انتخاب کنید",
    "manage_customer_stamp_rewards": "جوایز تمبر مشتری را مدیریت کنید 🎁",
    "sel_loc_menu_title": "مکان ها را برای منوی خود انتخاب کنید",
    "ans_req": "پاسخ لازم است",
    "valid_reputation_name": "نام معتبر معتبر را وارد کنید",
    "reviews_name_req": "دریافت نظرات بیشتر نام الزامی است",
    "birthdate_required": "تاریخ تولد الزامی است",
    "gender_required": "جنسیت الزامی است",
    "valid_birthdate_required": "تاریخ تولد معتبر وارد کنید",
    "custom_delivery_redirection": "یک فرم تغییر مسیر تحویل سفارشی توسط گروه ها ایجاد کنید",
    "customer_fields_incentive_settings": "زمینه های مشتری و تنظیمات تشویقی",
    "delivery_redirection_text": "تغییر مسیر تحویل",
    "delivery_redirection_success": "تبریک میگم ویجت تغییر مسیر تحویل شما ایجاد شد! 🎉",
    "swipe_to_preview_redirection": "انگشت خود را بکشید تا ببینید فرم تغییر مسیر تحویل تحویل شما چگونه خواهد بود",
    "enter_item_name": "نام مورد را وارد کنید",
    "home_page_text": "متن صفحه اصلی",
    "settings": "تنظیمات",
    "anniversary_required": "تاریخ سالگرد الزامی است",
    "valid_anniversary": "تاریخ سالگرد معتبر را وارد کنید",
    "form_submited": "فرم با موفقیت ارسال شد",
    "notifications": "اطلاعیه ها",
    "discount_message": "همین حالا 20 درصد تخفیف خود را در پیتزا دریافت کنید!",
    "is_required": " مورد نیاز است",
    "section_title_required": "عنوان بخش الزامی است",
    "section_dec_required": "شرح بخش الزامی است",
    "client_details_required": "فیلدهای جزئیات مشتری الزامی است",
    "compliance": "انطباق",
    "SMS_campaign1": "کمپین پیامکی 1",
    "mobile_number_mandatory": "شماره موبایل الزامی است",
    "new_answer": "پاسخ جدید",
    "new_question": "سوال جدید",
    "add_new_question": "پاسخ جدید اضافه کنید",
    "select": "انتخاب کنید",
    "group_customers_question": "کجا می خواهید مشتریان خود را گروه بندی کنید؟",
    "contacts_added_to_group": "تمام مخاطبینی که فرم را ارسال می کنند به گروه انتخابی اضافه می شوند",
    "edit_client_details_section": "ویرایش بخش جزئیات مشتری",
    "client_details_fields": "فیلدهای جزئیات مشتری",
    "enter_client_details_section_title_desc": "عنوان و توضیحات بخش جزئیات مشتری را وارد کنید",
    "choose_fields_displayed_client_side": "فیلدهایی را برای نمایش در سمت مشتری انتخاب کنید",
    "section_title": "عنوان بخش",
    "add_title": "عنوان اضافه کنید",
    "section_description": "توضیحات بخش",
    "add_description": "اضافه کردن توضیحات",
    "enter_program_name_location": "نام و مکان برنامه را وارد کنید 📍",
    "brand_theme_clr_txt": "رنگ تم برند",
    "set_loyalty_punch_card_theme": "بیایید یک موضوع برای کارت پانچ وفاداری شما تنظیم کنیم 🎨",
    "upload_logo_txt": "لوگوی خود را آپلود کنید",
    "recommended_image_specs": "توصیه می کنیم برای بهترین نمایش از تصویری با ابعاد 512×512 پیکسل استفاده کنید. JPG، SVG یا PNG. حداکثر حجم 10 مگابایت",
    "valid_rep_name_txt": "نام اعتبار معتبر را وارد کنید",
    "rep_name_req_txt": "دریافت نظرات بیشتر نام الزامی است",
    "que_req": "سوال الزامی است",
    "day_of_birthday": "در روز تولد",
    "day_before_birthday": "3 روز قبل از تولد",
    "week_before_birthday": "یک هفته قبل از تولد",
    "two_week_before_birthday": "دو هفته قبل از تولد",
    "de_active": "غیر فعال",
    "campaign_details": "جزئیات کمپین",
    "link_clicked": "لینک کلیک شد",
    "history": "تاریخچه",
    "auto_responder_summary": "خلاصه خودکار پاسخگو",
    "vsop_1": "15 دقیقه",
    "vsop_2": "30 دقیقه",
    "vsop_3": "45 دقیقه",
    "vsop_4": "60 دقیقه",
    "vsop_5": "90 دقیقه",
    "vsop_6": "120 دقیقه",
    "in_the_last": " در آخرین ",
    "return_in_last": " اما آخرش برنگشت ",
    "of_the_connection": "از اتصال",
    "your_campaign": "کمپین شما",
    "you_have_successfully": "شما موفق شده اید",
    "published_successfully": "با موفقیت منتشر شد",
    "updated_smart_campaign": "کمپین هوشمند را به روز کرد",
    "almost_done_text": "تقریبا تمام شد!",
    "update_campaign_desc": "پاسخگوی خودکار شما کامل است، پس از فعال شدن، پیام هایی را به طور مداوم برای مشتریان ارسال می کند. این کمپین را می توان به راحتی در هر زمان تغییر داد یا متوقف کرد.",
    "update_and_publish": "به روز رسانی و انتشار",
    "reset_campaign_title": "متن، تصویر، پیشنهادهای تخفیف و مخاطبان هدف کمپین شما را به طور پیش فرض حذف می کند!",
    "space_wifi": " وای فای",
    "custom_forms": "فرم های سفارشی",
    "web_e_signup": "ثبت نام الکترونیکی وب",
    "import": "واردات",
    "permissions_req": "مجوزها مورد نیاز است",
    "redeemed": "بازخرید شد",
    "coupon_already_redeemed": "کوپن قبلاً بازخرید شده است",
    "autoresponder_campaigns": "کمپین های پاسخگوی خودکار",
    "autoresponder_campaign_desc": "پاسخ خودکار را فعال کنید و کمپین شخصی سازی شده را راه اندازی کنید",
    "mon": "دوشنبه",
    "tue": "سه شنبه",
    "wed": "چهارشنبه",
    "thu": "پنج شنبه",
    "fri": "FRI",
    "sat": "SAT",
    "sun": "خورشید",
    "duplicate": "تکراری",
    "visibility": "دید",
    "availability": "در دسترس بودن",
    "out_stok": "خارج از استوک",
    "edit_product": "ویرایش محصول",
    "create_product": "یک محصول ایجاد کنید",
    "basic_info": "اطلاعات پایه",
    "basic_sub_info": "لورم ایپسوم به سادگی متن ساختگی چاپ است...",
    "enter_product_price": "قیمت محصول را وارد کنید",
    "upload_image": "آپلود تصویر",
    "allowed_file_formats": "فقط فایل های JPG و PNG با حداکثر اندازه فایل 3 مگابایت مجاز است.",
    "pre_select": "از قبل انتخاب کنید",
    "promotions": "تبلیغات",
    "discount_availability": "در دسترس بودن تخفیف",
    "start_time": "زمان شروع",
    "end_time": "زمان پایان",
    "select_day": "روز را انتخاب کنید",
    "menu_required": "منو مورد نیاز است",
    "generated": "تولید شده است",
    "link": "پیوند",
    "variations": "تغییرات",
    "select_variant_to_add": "گزینه ای را که می خواهید اضافه کنید انتخاب کنید",
    "price_title": "قیمت",
    "choose_image": "تصویر را انتخاب کنید",
    "select_visiblity": "Visiblity را انتخاب کنید",
    "availability_schedule": "برنامه در دسترس بودن",
    "add_on_upsell": "افزودن در \/ افزایش فروش",
    "add_on_select_product": "افزودن به انتخاب محصول",
    "upsell_product": "فروش انتخابی محصول",
    "variant_deletion_warning": "این نوع برای همیشه حذف خواهد شد.",
    "search_variations": "جستجوی تغییرات",
    "add_variation": "تنوع را اضافه کنید",
    "variation_text": "نوع",
    "select_customization": "سفارشی سازی را انتخاب کنید",
    "add_new": "افزودن جدید",
    "delete_customization_warning": "آیا می خواهید این سفارشی سازی را از محصول حذف کنید؟",
    "nutritional_allergen_info": "اطلاعات تغذیه و آلرژن",
    "mark_item": "علامت گذاری مورد",
    "calories_text": "کالری",
    "allergens": "آلرژن ها",
    "select_allergens": "آلرژن ها را انتخاب کنید",
    "special_instructions": "دستورالعمل های ویژه",
    "preparation_instructions": "دستورالعمل های آماده سازی",
    "staff_notes": "یادداشت های کارکنان",
    "checkbox1": "یک کادر تأیید برای مجاز یا غیرمجاز کردن دستورالعمل‌های ویژه مشتریان برای این آیتم منو.",
    "menu_c": "MENU",
    "past_design": "طراحی گذشته",
    "file_name": "نام فایل",
    "variation_name": "نام واریاسیون",
    "grid_items_title1": "چادر رومیزی",
    "grid_items_title2": "منوی دیجیتال",
    "grid_items_title3": "منوی بیرون‌بر",
    "grid_items_title4": "منوی نیم صفحه",
    "grid_items_title5": "منوی دیجیتال 5",
    "grid_items_title6": "منوی دیجیتال 6",
    "grid_items_title7": "منوی دیجیتال 7",
    "grid_items_title8": "منوی دیجیتال 8",
    "grid_items_title9": "منوی دیجیتال 9",
    "enter_valid_form_name": "نام فرم معتبر را وارد کنید",
    "form_name_required": "نام فرم الزامی است",
    "enter_valid_section": "عنوان بخش معتبر را وارد کنید",
    "fraction_half": "1\/2",
    "fraction_two_by_two": "2\/2",
    "form_name": "نام فرم",
    "contact_list": "لیست تماس",
    "label_notify_email": "آیا می خواهید از طریق ایمیل به شما اطلاع داده شود؟",
    "add_client_details_section": "افزودن بخش جزئیات مشتری",
    "demo_purpose_only": "این فقط برای هدف آزمایشی است",
    "form_updated_success": "فرم با موفقیت به روز شد",
    "form_creat_success": "فرم با موفقیت ایجاد شد",
    "add_custom_section": "یک بخش سفارشی اضافه کنید",
    "edit_custom_section": "ویرایش یک بخش سفارشی",
    "forms_list": "فهرست فرم ها",
    "filled_custom_form_details": "جزئیات فرم سفارشی پر شده",
    "custom_form_deletion_warning": "این فرم سفارشی برای همیشه حذف خواهد شد",
    "menu_link": "لینک منو",
    "WebSignUpT": "ثبت نام الکترونیکی",
    "lifeTConT": "اتصالات مادام العمر",
    "repCustT": "مشتریان تکراری",
    "liftTSubT": "مشترکین مادام العمر",
    "overallT": "به طور کلی",
    "loginCustBDT": "مشتریان وارد شده در روز",
    "deviceT": "جمعیت شناسی دستگاه",
    "ageTitle": "جمعیت شناسی سنی",
    "ageT": "گروه سنی",
    "convert_social_media_followers_into_customers": "فالوورهای شبکه های اجتماعی را به مشتری تبدیل کنید",
    "signWidDesc": "ویجتی برای ثبت نام، ایجاد صفحات فرود و تولید کوپن از وب سایت یا رسانه های اجتماعی خود بسازید.",
    "qrWidT": "QR هوشمند",
    "imgWidT": "تعداد را انتخاب کنید",
    "mobile": "موبایل",
    "YESTitle": "بله",
    "NOTitle": "نه",
    "addDelRed": "تغییر مسیر تحویل را اضافه کنید",
    "dlredNameT": "تغییر مسیر تحویل خود را نام ببرید",
    "conf_text_req": "متن تایید لازم است",
    "cFDesc": "فرم‌های مناسب برای جمع‌آوری بازخورد و اطلاعات خاص از مشتریان به طور موثر ایجاد کنید",
    "pre_review_questions_enabled": "قبل از درخواست از مشتریان برای بررسی، بیایید 1 تا 4 سوال از آنها بپرسیم.",
    "feel_free_edit": "در صورت نیاز ویرایش کنید.",
    "option": "گزینه",
    "confFL": "این تایید پس از ارسال فرم ثبت نام وب توسط مخاطب شما نمایش داده می شود",
    "confT": "متن تاییدیه",
    "joinTitle": "بپیوندید",
    "mobValidL": "لطفا شماره موبایل معتبر را بررسی کنید",
    "mobRequiredL": "شماره موبایل الزامی است",
    "Business_location_label": "مکان کسب و کار شما",
    "qr_code_selection_txt": "انتخاب کد Qr",
    "choose_theme_txt": "تم را انتخاب کنید",
    "edit_qr_details_txt": "جزئیات QR را ویرایش کنید",
    "save_qr_code_txt": "کد QR را ذخیره کنید",
    "text_color_label": "رنگ متن",
    "background_color_label": "رنگ پس زمینه",
    "print_txt": "چاپ کنید",
    "customer_will_see_txt": "این چیزی است که مشتری شما خواهد دید. رنگ برند خود را در مرحله بعدی انتخاب کنید.",
    "choose_your_brand_color_&_langauge_title": "رنگ و زبان برند خود را انتخاب کنید",
    "custom_redirect_link_label": "لینک تغییر مسیر سفارشی",
    "redirect_user_to_menu": "هدایت کاربر به منو",
    "language": "زبان",
    "select_language_placeholder": "زبان را انتخاب کنید",
    "capitalize_location_name_txt": "نام مکان",
    "created_txt": "ایجاد شد",
    "swipe_reputation_management_txt": "انگشت خود را بکشید تا ببینید مدیریت شهرت شما چگونه خواهد بود",
    "sent_txt": "ارسال شد",
    "number_label": "شماره",
    "date_label": "تاریخ",
    "gender_demographics_title": "جمعیت شناسی جنسیتی",
    "step1_preview_desc": "فرم QR ثبت نام الکترونیکی خود را نام ببرید و به لیست گروه خود اضافه کنید",
    "main_head": "مشتریان جدید به دست آورید",
    "main_desc": "با قرار دادن یک کد QR که هنگام اسکن، کاربران را به یک فرم ثبت نام شخصی هدایت می کند، مشتریان را جذب کنید.",
    "step1_description": "مشتریان جدیدی را از سایر مشاغل به دست آورید",
    "form_name_field_placeholder": "Restro E-Signup",
    "last_step_title": "تبریک میگم ویجت ثبت نام الکترونیکی شما ایجاد شد! 🎉",
    "last_step_desc": "انگشت خود را بکشید تا ببینید فرم ثبت نام الکترونیکی شما چگونه خواهد بود",
    "events_step1_preview_desc": "فرم رویداد QR خود را نامگذاری کنید و به لیست گروه خود اضافه کنید",
    "events_main_head": "مشتریان را از رویدادها دریافت کنید",
    "events_main_desc": "با قرار دادن یک کد QR در رویداد، مشتریان را جذب کنید، هنگامی که اسکن می شود، کاربران را به یک فرم ثبت نام شخصی هدایت می کند.",
    "events_step1_desc": "مشتریان را از رویدادها جذب کنید و آنها را در فروشگاه بیاورید",
    "events_name": "نام رویداد",
    "events_form_name_field_placeholder": "رویداد تفریحی و غذایی Restro",
    "events_last_step_title": "تبریک میگم فرم رویداد شما ایجاد شد! 🎉",
    "events_last_step_desc": "انگشت خود را بکشید تا ببینید فرم رویداد شما چگونه خواهد بود",
    "birthday_anniversary_step1_preview_desc": "فرم QR خود را برای جمع آوری روز تولد یا سالگرد مشتری نامگذاری کنید و آن را به لیست گروه خود اضافه کنید",
    "birthday_anniversary_main_head": "B'day یا Anniversary را جمع آوری کنید",
    "birthday_anniversary_main_desc": "دریافت روز تولد یا سالگرد مشتری، هنگامی که اسکن می شود، کاربران را به یک فرم ثبت نام شخصی هدایت می کند.",
    "birthday_anniversary_step1_desc": "روز تولد و سالگرد مشتری را جمع آوری کنید",
    "restro_text": "Restro",
    "birthday_anniversary_last_step_title": "تبریک میگم دریافت B'day & Anniversary از مشتریان فرم ایجاد شده! 🎉",
    "newspaper_ads_step1_preview_desc": "فرم QR خود را نامگذاری کنید تا مشتریان را از تبلیغات روزنامه دریافت کنید و به لیست گروه شما اضافه شود",
    "newspaper_ads_main_head": "مشتریان را از تبلیغات دریافت کنید",
    "newspaper_ads_main_desc": "مشتریان را از تبلیغات روزنامه دریافت کنید، وقتی آنها اسکن می کنند، کاربران را به یک فرم ثبت نام شخصی هدایت می کند.",
    "newspaper_ads_step1_desc": "مشتریان را از آگهی روزنامه یا آگهی در فضای باز جذب کنید",
    "newspaper_ads_last_step_title": "تبریک میگم مشتریان را از فرم تبلیغات روزنامه ایجاد کنید! 🎉",
    "smart_pamphlets_step1_preview_desc": "فرم QR خود را نامگذاری کنید تا داده های مشتریان را از جزوه ها دریافت کنید و به لیست گروه خود اضافه کنید",
    "smart_pamphlets_main_head": "مشتریان را از جزوه ها دریافت کنید",
    "smart_pamphlets_main_desc": "داده‌های مشتری را از جزوه‌ها دریافت کنید، وقتی آنها اسکن می‌شوند، کاربران را به یک فرم ثبت‌نام شخصی هدایت می‌کند.",
    "smart_pamphlets_last_step_title": "تبریک میگم دریافت اطلاعات مشتری از فرم جزوات ایجاد شده! 🎉",
    "social_media_step1_preview_desc": "فرم QR خود را نام گذاری کنید تا مشتریان را از رسانه های اجتماعی و مشتریان جذب کنید و به لیست گروه شما اضافه شود",
    "social_media_main_desc": "مشتریان را از رسانه های اجتماعی دریافت کنید، وقتی آنها از پست ها اسکن می شوند، کاربران را به یک فرم ثبت نام شخصی هدایت می کند.",
    "social_media_form_name_field_placeholder": "پیشنهاد جشنواره Restro - پست فیس بوک",
    "social_media_last_step_title": "تبریک میگم فرم شبکه های اجتماعی را برای مشتریان ایجاد کنید! 🎉",
    "claim_offer_text": "مشخصات خود را پر کنید و اکنون پیشنهاد را درخواست کنید",
    "form_title_description": "عنوان و توضیحات فرم را برای مشتریان خود اضافه کنید تا جزئیات را وارد کنند",
    "no_subscribed_clients": "گروه مشتری منتخب هیچ مشتری مشترکی ندارد",
    "welcome_message_offer": "پس از ثبت نام به مشتریان خود پیام خوشامدگویی بفرستید و به آنها کوپن پیشنهاد بدهید",
    "system_grp": "گروه های سیستمی",
    "import_clients": "مشتریان وارداتی",
    "delivery_redirection_name": "نام تغییر مسیر تحویل",
    "created_at": "ایجاد شده در",
    "provide_stamp_user": "ارائه تمبر به کاربر",
    "scan_qr_code": "کد QR را اسکن کنید",
    "add_point": "نقطه اضافه کنید",
    "total_points": "مجموع امتیازها",
    "user_profile": "نمایه کاربر",
    "stamp_history": "تاریخچه تمبر",
    "reward_txt": "پاداش",
    "variant_placeholder": "کوچک، متوسط، بزرگ و غیره",
    "sent_coupon": "کوپن ارسال شده",
    "order_status": "وضعیت سفارش",
    "payment_status": "وضعیت پرداخت",
    "order_currency": "ارز سفارش",
    "card_data": "داده های کارت",
    "reward_expiring_on": "پاداش در حال انقضا است",
    "reward_status": "وضعیت پاداش",
    "reward_get_date": "پاداش دریافت تاریخ",
    "reward_name": "نام جایزه",
    "add_point_type": "اضافه کردن نوع نقطه",
    "restaurant_location": "محل رستوران",
    "collect_points": "جمع آوری امتیاز",
    "rewards_expire": "جوایز منقضی می شوند",
    "rewards_redeem": "پاداش بازخرید",
    "total_rewards": "مجموع جوایز",
    "created_by": "ایجاد شده توسط",
    "anniversary": "سالگرد",
    "remove_user_group_txt": "حذف کاربر از گروه",
    "delete_permanently_grp_msg": "این کلاینت برای همیشه از گروه حذف خواهد شد.",
    "delete_multiple_permanently_grp_msg": "این کلاینت ها برای همیشه از گروه حذف خواهند شد.",
    "delete_multiple_client": "این کلاینت ها برای همیشه حذف خواهند شد.",
    "messages": "پیام ها",
    "management": "مدیریت",
    "client": "مشتری",
    "country": "کشور",
    "state": "ایالت",
    "city": "شهر",
    "1_sparkly_point_txt": "🎉 شما 1 امتیاز درخشان کسب کرده اید! 🌟",
    "select_country_txt": "کشور را انتخاب کنید",
    "select_state": "حالت را انتخاب کنید",
    "ls_thanks_for_join": "ممنون که به کارت پانچ وفاداری ما پیوستید.🎁🎈",
    "month": "ماه",
    "welcome_message": "پس از ثبت نام، برای مشتریان خود پیام خوشامدگویی ارسال کنید",
    "all_rewards_txt": "همه جوایز",
    "active_rewards": "جوایز فعال",
    "expired_and_redeemed": "منقضی شده و بازخرید شده است",
    "redirect_3rd_party_delivery": "تحویل شخص ثالث را به فروشگاه خود یا پلت فرم سفارش آنلاین خود هدایت کنید",
    "search_or_start_new": "جستجو یا راه اندازی جدید",
    "contacts": "مخاطبین",
    "chats": "چت ها",
    "add_by_restaurant": "افزودن توسط رستوران",
    "using_scan_code": "با استفاده از کد اسکن",
    "step": "مرحله",
    "list_widget_text": "لیست ابزارک ها",
    "swipe_free_wifi_txt": "برای دریافت وای فای رایگان انگشت خود را بکشید",
    "create_widget_txt": "ویجت ایجاد کنید",
    "redeem_award_msg": "مطمئنی؟ آیا می خواهید از این پاداش استفاده کنید؟",
    "on_collect_msg": "در جمع آوری",
    "pause": "مکث کنید",
    "resume": "رزومه",
    "location_added": "مکان اضافه شد",
    "sub_user_admin_permissions_txt": "به کاربر فرعی اجازه دسترسی به مجوزهای مدیریت در Digital Signage را بدهید",
    "unlist": "لغو فهرست",
    "cannot_delete_default_terms": "نمی توان شرایط و ضوابط پیش فرض را حذف کرد",
    "something_went_wrong": "مشکلی پیش آمد!",
    "card_download_success": "کارت با موفقیت بارگیری شد",
    "permission_denied": "اجازه رد شد!",
    "invalid_qr_code": "لطفاً کد QR معتبر را اسکن کنید",
    "select_customer": "لطفا مشتری را انتخاب کنید",
    "online": "آنلاین",
    "offline": "آفلاین",
    "no_data": "اوه، هیچ داده ای یافت نشد!",
    "forever_free": "برای همیشه رایگان"
}

export default fa;