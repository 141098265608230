const de = {
    "rated_google_reviews": "Bewertet mit 5,0 bei Google-Bewertungen",
    "get_your_digital_menu": "Holen Sie sich Ihr digitales Menü—",
    "instant_popup_menu": "Sofortiges Popup-Menü ohne QR-Scan",
    "free_forever": "Für immer kostenlos",
    "the_future_is_here": "Die Zukunft ist da!",
    "upgrade_your_restaurant": "Werten Sie Ihr Restaurant kostenlos mit einer beeindruckenden digitalen Speisekarte auf. Gewinnen Sie ab heute mehr Kunden.",
    "get_my_free_menu_now": "Holen Sie sich jetzt mein kostenloses Menü",
    "no_credit_card_required": "*Keine Kreditkarte erforderlich",
    "join_largest_marketplace": "Werden Sie Teil des größten Restaurant-Marktplatzes!",
    "what_will_you_get": "Was erhalten Sie",
    "free": "FREI",
    "free_digital_menu": "Kostenloses digitales Menü 2D oder 3D",
    "free_digital_menu_desc": "Verabschieden Sie sich von Papiermenüs und teuren Nachdrucken",
    "quick_and_easy_signup": "Schnelle und einfache Anmeldung",
    "communicate_with_customers_title": "Kommunizieren Sie KOSTENLOS mit Kunden!",
    "features1": [
        "Sparen Sie Druckkosten und freuen Sie sich über sofortige Menü-Updates, wann immer Sie sie brauchen.",
        "Greifen Sie auf jedem Gerät auf Ihr digitales Menü zu",
        "Umweltfreundlich und unkompliziert"
    ],
    "features2": [
        "Lassen Sie Kunden Ihre Gerichte mit atemberaubenden 3D-Visuals virtuell erkunden.",
        "Steigern Sie das Engagement, indem Sie den Gästen eine realistische Vorschau auf ihre Mahlzeit geben.",
        "Heben Sie sich mit einem Menüerlebnis ab, das genauso verlockend ist, wie Ihr Essen schmeckt."
    ],
    "features3": [
        "Begrüßen Sie Ihre Gäste sofort mit Ihrer Speisekarte, wenn sie das Restaurant betreten.",
        "Senden Sie automatisch Updates zu Sonderangeboten und Werbeaktionen an ihre Telefone.",
        "Verbessern Sie das Speiseerlebnis mit schnellem, kontaktlosem Zugriff auf Ihr Menü."
    ],
    "feature4": [
        "Heben Sie saisonale Angebote, Tagesspezialitäten und exklusive Aktionen hervor.",
        "Lenken Sie die Aufmerksamkeit Ihrer Kunden auf Ihre beliebtesten Artikel.",
        "Erzeugen Sie mit auffälligen Bildern und Beschreibungen Dringlichkeit und Spannung."
    ],
    "feature5": [
        "Seien Sie Teil einer Online-Community mit Tausenden von Besuchern täglich.",
        "Steigern Sie Ihre Sichtbarkeit und erreichen Sie neue Kunden, die aktiv nach Essensmöglichkeiten suchen.",
        "Sichern Sie sich frühzeitig einen Top-Platz, um Ihre Präsenz zu maximieren und mehr Gäste anzulocken."
    ],
    "section3_heading": "Warum digitalisieren? Weil es kostenlos und einfach ist!",
    "section3_description": "Schalten Sie leistungsstarke Funktionen frei, die die Attraktivität Ihres Restaurants steigern.",
    "instant_menu": "Instant-Menü",
    "instant_menu_notification": "Popup und Benachrichtigung",
    "instant_menu_description": "Begrüßen Sie Ihre Gäste mit sofortigem Zugriff auf das Menü, sobald sie hereinkommen. Senden Sie ihnen automatisch das neueste Menü und die Sonderangebote direkt an ihre Mobiltelefone.",
    "digital_menu": "Digitale Menüs",
    "at_zero_cost": "zum Nulltarif",
    "digital_menu_description1": "Verabschieden Sie sich von Papiermenüs und teuren Nachdrucken. Wechseln Sie zu einem digitalen Menü, das immer auf dem neuesten Stand ist – und dauerhaft kostenlos!",
    "digital_menu_description2": "Mit unserer digitalen Speisekarte sind Ihre Angebote immer aktuell und auf jedem Gerät zugänglich. Sparen Sie Geld und vereinfachen Sie Menüaktualisierungen mit einer nahtlosen, kostenlosen Lösung.",
    "try_me_button": "Versuchen Sie es mit mir",
    "try_3d_menu_title": "3D-Menüs",
    "try_3d_menu_title2": "Kostenlos – WOW!",
    "try_3d_menu_description1": "Lassen Sie Ihre Gerichte vom Bildschirm springen. Laden Sie 3D-Bilder hoch und lassen Sie Kunden Ihr Essen virtuell probieren, bevor sie hereinkommen.",
    "try_3d_menu_description2": "Hochwertige 3D-Bilder sorgen für eine ansprechende Vorschau, erhöhen die Attraktivität und fördern die Bestellung.",
    "try_now": "Jetzt testen",
    "showcase_title": "Präsentieren Sie Ihre",
    "showcase_specials": "Besonderheiten",
    "showcase_specials_description1": "Sie haben ein leckeres Angebot oder eine unschlagbare Aktion? Heben Sie es in Ihrer digitalen Speisekarte hervor und locken Sie mehr hungrige Leute an.",
    "showcase_specials_description2": "Sorgen Sie dafür, dass Werbeaktionen nicht zu übersehen sind, indem Sie mit auffälligen Bildern und verlockenden Beschreibungen die Aufmerksamkeit auf sich ziehen.",
    "marketplace": "Marktplatz",
    "marketplace_description": "Wir bauen einen Marktplatz mit über 2.000 Besuchern täglich auf. Steigen Sie jetzt ein und Sie sind der Erste, der an der Reihe ist, wenn wir starten. Sie können neue Kunden erreichen, die aktiv nach Essensmöglichkeiten suchen. Sichern Sie sich Ihren Platz frühzeitig, um die Präsenz zu maximieren!",
    "see_other_products": "Siehe andere Produkte",
    "about_us": "Über uns",
    "why_serving_free": "Warum wir das servieren für",
    "serving_free_highlight": "Frei",
    "about_desc": "Wir sind hier vor Ort in Dallas und setzen uns dafür ein, dass Restaurants wie Ihres erfolgreich florieren.",
    "about_tool_free": "Wir bieten dieses leistungsstarke Tool absolut kostenlos an, weil uns die Unterstützung unserer Community am Herzen liegt.",
    "no_catch": "Kein Haken, kein Scherz. Wir wissen, dass unsere Community stärker wird, wenn Sie erfolgreich sind. 😊",
    "understanding_challenges": "Wir sind uns der Herausforderungen bewusst, die mit der Führung eines Unternehmens verbunden sind. Deshalb ist es unser Ziel, Ihnen Tools bereitzustellen, die Ihre Betriebsabläufe reibungsloser gestalten und Ihre Reichweite vergrößern.",
    "community_growth": "Wenn Sie wachsen, wächst auch unsere Community mit Ihnen und erzeugt einen Welleneffekt aus Erfolg und Unterstützung. Lassen Sie uns gemeinsam das kulinarische Erlebnis für alle verbessern.",
    "how_it_works": "So funktioniert es",
    "three_simple_steps": "Drei einfache Schritte zu",
    "get_started_highlight": "Erste Schritte",
    "no_tech_skills_needed": "Keine technischen Kenntnisse erforderlich. Wenn Sie einen Burger wenden können, können Sie das auch!",
    "step1_title": "Zeigen Sie Ihre Werbung KOSTENLOS an!",
    "step1_desc": "Legen Sie schneller los, als Sie „Bestellen!“ sagen können. Füllen Sie einfach ein einfaches Formular aus.",
    "step2_title": "Passen Sie Ihr Menü an",
    "step2_desc": "Fügen Sie Ihre Gerichte hinzu, laden Sie Fotos hoch (auch in 3D) und heben Sie Ihre Spezialitäten hervor.",
    "step3_title": "Teilen und wachsen",
    "step3_desc": "Teilen Sie Ihr digitales Menü über QR-Codes, soziale Medien oder Ihre Website.",
    "testimonials": "Referenzen",
    "testimonials_section_title": "Verlassen Sie sich nicht nur auf unser Wort",
    "testimonials_section_desc": "Lesen Sie, was andere Restaurantbesitzer in Dallas sagen.",
    "faq_section_title": "Sie haben Fragen? Wir haben Antworten",
    "faq_section_desc": "Alles, was Sie wissen müssen, bevor Sie beginnen.",
    "faqs": "FAQs",
    "faq_answers": [
        {
            "que": "Ist es wirklich für immer kostenlos?",
            "ans": "Absolut! Keine versteckten Gebühren, keine Kreditkarten, keine Verpflichtungen."
        },
        {
            "que": "Benötige ich technisches Know-How?",
            "ans": "Wenn Sie eine E-Mail senden können, können Sie das auch tun. So einfach ist das."
        },
        {
            "que": "Warum bieten Sie das kostenlos an?",
            "ans": "Bei uns steht das Geben an erster Stelle. Vielleicht haben wir später noch weitere Dienste im Angebot, die Ihnen gefallen werden – aber das liegt ganz bei Ihnen."
        },
        {
            "que": "Was ist mit dem Marktplatz los?",
            "ans": "Wir sind am Planen! Sobald wir genügend Restaurants an Bord haben, starten wir Ihren Marktplatz, um Ihnen noch mehr Aufmerksamkeit zu verschaffen."
        }
    ],
    "testimonials_content1": "Die Umstellung auf ein digitales Menü war für uns die beste Entscheidung! Unsere Kunden lieben die Benutzerfreundlichkeit und das moderne Design!",
    "testimonials_name1": "George Morris",
    "testimonials_restaurant_name1": "Das Mansion Restaurant",
    "testimonials_content2": "Unsere neue digitale Speisekarte erleichtert Gästen das Stöbern und sorgt für deutlich mehr Engagement!",
    "testimonials_name2": "Amelia Johnson",
    "testimonials_restaurant_name2": "Urbanes Essen",
    "testimonials_content3": "Die Gäste sind von der 3D-Grafik begeistert – sie hat uns geholfen, uns von der Masse abzuheben und ihr kulinarisches Erlebnis zu verbessern!",
    "testimonials_name3": "Davis Grün",
    "testimonials_restaurant_name3": "Stadtherd",
    "ready_to_boost_your_restaurant": "Bereit, Ihr Restaurant aufzuwerten?",
    "ready_to_boost_desc": "Bleiben Sie nicht zurück. Werden Sie noch heute Teil der digitalen Revolution!",
    "yes_i_want_my_free_digital_menu": "Ja, ich möchte mein kostenloses digitales Menü",
    "480_plus": "480+",
    "people_already_joined": "Personen sind bereits beigetreten",
    "menu_online_home": "Menü Online Startseite",
    "welcome": "Willkommen",
    "description_other": "Steigern wir Ihre Kundenzufriedenheit!",
    "benefits": "Vorteile",
    "greeting_text": "Herzlich willkommen 👋",
    "all_in_one_features": "🚀 All-in-One-Funktionen zur Verbesserung Ihres Restaurants",
    "menu_online_menu_name": "Menü Online",
    "menu_online_description": "Digitalisieren Sie Ihre Speisekarte, damit Ihre Kunden einfach online darauf zugreifen können.",
    "menu_online_benefits": "Verbessern Sie den Komfort Ihrer Kunden und steigern Sie die Bindung, indem Sie Ihren Kunden ermöglichen, Ihre Speisekarte jederzeit und überall einzusehen.",
    "ds_name": "Digitale Beschilderung",
    "ds_description": "Präsentieren Sie Angebote und Aktionen auf dynamischen Bildschirmen im Geschäft.",
    "ds_benefits": "Fesseln Sie die Aufmerksamkeit Ihrer Kunden mit ansprechenden visuellen Elementen und bewerben Sie mühelos Upselling und Echtzeitangebote.",
    "loyalty_punch_name": "Treue-Stempelkarte",
    "loyalty_punch_description": "Belohnen Sie Stammkunden mit einem digitalen Treueprogramm.",
    "loyalty_punch_benefits": "Fördern Sie die Kundenbindung und steigern Sie den Umsatz, indem Sie Treue mit einlösbaren Prämien für häufige Besuche belohnen.",
    "marketing_menu_name": "Marketing",
    "marketing_description": "Erreichen Sie mehr Kunden mit gezielten digitalen Marketingkampagnen.",
    "marketing_benefits": "Erweitern Sie die Reichweite Ihres Restaurants und steigern Sie die Kundenfrequenz mit individuellen Werbeaktionen, die auf die Gewinnung von Neu- und Stammkunden ausgerichtet sind.",
    "get_more_reviews_menu_name": "Mehr Bewertungen erhalten",
    "get_more_reviews_description": "Ermutigen Sie zufriedene Kunden, positive Online-Bewertungen zu hinterlassen.",
    "get_more_reviews_benefits": "Steigern Sie die Glaubwürdigkeit Ihres Restaurants und gewinnen Sie neue Gäste, indem Sie Ihren Online-Ruf durch mehr positive Bewertungen verbessern.",
    "guest_smart_wifi_menu_name": "Smart-WLAN für Gäste",
    "guest_smart_wifi_description": "Bieten Sie kostenloses WLAN an, um Gästedaten für Marketingzwecke zu erfassen.",
    "guest_smart_wifi_benefits": "Steigern Sie die Kundenzufriedenheit und sammeln Sie gleichzeitig wertvolle Kundendaten, um zukünftige Werbeaktionen zu personalisieren.",
    "reservation_menu_name": "Reservierung",
    "reservation_description": "Ermöglichen Sie Ihren Kunden die einfache Online-Reservierung von Tischen.",
    "reservation_benefits": "Verbessern Sie das Speiseerlebnis, indem Sie die Wartezeiten minimieren und Reservierungen optimieren, um die Sitzplätze besser zu verwalten.",
    "pos_integration_menu_name": "POS-Integration",
    "pos_integration_description": "Integrieren Sie unser System in Ihr POS-System.",
    "pos_integration_benefits": "Vereinfachen Sie die Auftragsabwicklung und reduzieren Sie Fehler, indem Sie Ihre Online- und In-Store-Systeme synchron halten.",
    "online_ordering_menu_name": "Online-Bestellung",
    "online_ordering_description": "Ermöglichen Sie Kunden, direkt über Ihre Website oder App Bestellungen aufzugeben.",
    "online_ordering_benefits": "Steigern Sie den Umsatz und reduzieren Sie die Abhängigkeit von Drittanbieterplattformen, indem Sie eine gebührensparende Direktbestellmöglichkeit anbieten.",
    "pro_label": "Pro",
    "coming_soon": "Demnächst verfügbar",
    "locked_label": "Gesperrt",
    "forever_label": "Für immer",
    "new_label": "Neu",
    "other_products_page_title": "Andere Produkte | Menü Online",
    "other_products_description": "Menü Online-Datenschutzrichtlinie",
    "other_products_header_title": "Andere Produkte",
    "privacy_policy": {
        "title": "Datenschutzrichtlinie",
        "lastUpdated": "Last Updated : Juli 01, 2024",
        "introduction": {
            "header": "Datenschutzrichtlinie für Neu- und Bestandskunden",
            "paragraph1": "DIE FOLGENDEN WICHTIGSTEN PUNKTE (EINSCHLIESSLICH ABSCHNITTVERWEISE) WERDEN NUR ZU IHRER NÜTZLICHKEIT BEREITGESTELLT UND ERSETZEN NICHT DIE VOLLSTÄNDIGEN GESCHÄFTSBEDINGUNGEN. ES LIEGT IN IHRER VERANTWORTUNG, DIESE GESCHÄFTSBEDINGUNGEN VOR DER VERWENDUNG DES SERVICES VON MENUONLINE INC ZU LESEN.",
            "paragraph2": "Durch die Nutzung von Menuonline erklären Sie sich mit den Allgemeinen Geschäftsbedingungen einverstanden und versichern und garantieren, dass Sie von Ihrer Organisation oder Firma dazu autorisiert sind. (Siehe Abschnitt 3 „So funktioniert es“)",
            "paragraph3": "Menuonline Inc ist ein genehmigungsbasierter Marketingdienst. Das absichtliche Versenden von „Spam“ oder die Verletzung der Allgemeinen Geschäftsbedingungen führt zur Schließung Ihres Kontos. (Siehe Abschnitt 16 „Akzeptable Nutzung des Dienstes“)",
            "paragraph4": "Daten, die Sie bei Menuonline Inc. hosten, wie etwa Kontaktdatensätze, E-Mail-Inhalte und Informationen, die Sie über Menuonline Inc. erfassen, gehören Ihnen. (Siehe Abschnitt 14 „Was gehört Ihnen?“).",
            "paragraph5": "Die Software von Menuonline Inc und die Inhalte auf unserer Website gehören uns. (Siehe Abschnitt 13 „Was gehört uns?“) Abgesehen von Ihren eigenen Benutzern können die Daten oder unveröffentlichten Inhalte, die Sie bei Menuonline Inc hosten, nur von Mitarbeitern und Partnern von Menuonline Inc eingesehen werden, die Vertraulichkeitsvereinbarungen unterzeichnet haben. (Siehe Abschnitt 14 „Was gehört Ihnen?“)",
            "paragraph6": "Alle personenbezogenen Daten (PII) werden ausschließlich auf Servern in Kanada gespeichert. (Siehe Abschnitt 14 „Was gehört Ihnen?“).",
            "paragraph7": "Der Service von Menuonline Inc unterliegt den Gesetzen Kanadas und der Provinz Ontario. (Siehe Abschnitt 24 „Geltendes Recht“)",
            "paragraph8": "Alle Anfragen nach Ihren Daten oder Inhalten durch Einzelpersonen, die Polizei oder andere Aufsichtsbehörden innerhalb oder außerhalb Kanadas werden immer an Sie weitergeleitet. Die einzige Ausnahme wäre, wenn wir dadurch gegen kanadisches oder Ontario-Recht verstoßen würden; in allen Fällen werden wir uns jedoch energisch gegen die Weitergabe Ihrer Daten durch andere als Sie wehren. (Siehe Abschnitt 14 „Was gehört Ihnen?“).",
            "paragraph9": "Die Sicherheit von Menuonline Inc ist nur so gut wie Ihre Verwaltung von Benutzernamen und Passwörtern. Wir berechnen keine Gebühren für Benutzerkonten von Menuonline Inc. Erstellen Sie daher für jeden Benutzer ein eigenes Konto und halten Sie die Passwörter geheim. Sie sind für Ihr Konto verantwortlich. (Siehe Abschnitt 3 „So funktioniert es“)",
            "paragraph10": "Sie können Ihr Konto jederzeit kündigen. Im Falle einer Kündigung erstatten wir Ihnen kein ungenutztes Guthaben zurück. (Siehe Abschnitt 3 „So funktioniert es“)"
        },
        "termsAndConditions": {
            "title": "Allgemeine Geschäftsbedingungen von MenuOnline Inc.",
            "lastUpdate": "LETZTE AKTUALISIERUNG: 14. Januar 2020",
            "paragraph1": "Bitte lesen Sie diese Servicebedingungen (die „Bedingungen“) sorgfältig durch, bevor Sie diese Website (die „Site“) nutzen. Diese Bedingungen befreien Menuonline Inc. („Menuonline Inc“, „wir“ oder „uns“) und andere von der Haftung und\/oder beschränken unsere und ihre Haftung und enthalten andere wichtige Bestimmungen, die für Ihre Nutzung dieser Site gelten. Indem Sie diese Site oder den Service besuchen oder nutzen, erklären Sie sich in Ihrem eigenen Namen und im Namen jeder Organisation, in deren Namen Sie handeln (im Folgenden gemeinsam als „Sie“ bezeichnet), damit einverstanden, diese Bedingungen bei jeder Nutzung und jedem Besuch dieser Site zu akzeptieren und einzuhalten."
        },
        "services": {
            "title": "Unsere Leistungen.",
            "paragraph": "Die Dienste, die wir über die Site anbieten, sind ein Dienst, der es Ihnen ermöglicht, E-Mail-Nachrichten („E-Mails“) an Empfänger Ihrer Wahl zu erstellen, zu senden und zu verwalten (der „Dienst“)."
        },
        "license": {
            "title": "Lizenz.",
            "paragraph": "Vorbehaltlich dieser Bedingungen gewährt Ihnen Menuonline Inc hiermit während der Laufzeit eine nicht exklusive, begrenzte, nicht übertragbare Lizenz zum Anzeigen der Site und zur Nutzung des Dienstes, wie es die Funktionen des Dienstes erlauben. Wir behalten uns alle Rechte vor, die hierin im Dienst und im Inhalt von Menuonline Inc (wie unten definiert) nicht ausdrücklich gewährt werden."
        },
        "emailingContent": {
            "title": "Inhalte per E-Mail versenden.",
            "paragraph": "Wenn Sie über diese Site oder den Service mit Menuonline Inc. interagieren, verpflichten Sie sich, wahrheitsgetreue und genaue Informationen bereitzustellen. Darüber hinaus verstehen Sie, dass die Übermittlung vertraulicher Informationen per E-Mail unsicher ist. Sie verstehen, dass Sie allein für alles verantwortlich sind, was Sie an Personen senden, die unseren Service nutzen."
        },
        "support": {
            "title": "Unterstützung.",
            "paragraph": "Wir bieten Ihnen Support, damit Sie den Service selbstständig nutzen können. Wir bieten Ihnen Support in Form von Dokumentationen und Videos auf der Site, dem Support-Ticketsystem von Menuonline Inc und per Telefon. Sie erreichen uns, indem Sie oben auf der Site auf die Registerkarte „Support“ klicken. Der Produktsupport ist kostenlos. Wir behalten uns jedoch das Recht vor, den Support für bestimmte Kundenkonten nach eigenem Ermessen einzustellen."
        }
    },
    "terms_condition": {
        "title": "Servicebedingungen | Menü Online",
        "header": "Allgemeine Geschäftsbedingungen für Neu- und Bestandskunden"
    },
    "privacy_policy_title": "DATENSCHUTZERKLÄRUNG",
    "privacy_policy_effective_date": "Gültig ab 16. Januar 2020",
    "privacy_policy_general_title": "ALLGEMEINE DATENSCHUTZERKLÄRUNG",
    "privacy_policy_intro": "menuonline.com (Menuonline und „uns“) ist sich der Bedeutung des Schutzes der Privatsphäre der persönlichen Daten von Einzelpersonen und der Bedeutung der Sicherheit vertraulicher Informationen aller Art bewusst. Wir haben diese Datenschutzrichtlinie (die „Richtlinie“) erstellt, um unser Engagement für den Schutz Ihrer „Privatsphäre“ zu demonstrieren. Diese Datenschutzrichtlinie erklärt:",
    "privacy_policy_points": [
        "Welche personenbezogenen Daten wir erheben und warum wir sie erheben;",
        "Wie wir Ihre personenbezogenen Daten verwenden;",
        "Mit wem wir Ihre persönlichen Daten teilen; und",
        "Sie haben die Möglichkeit, auf Ihre persönlichen Daten zuzugreifen, sie zu aktualisieren und sie aus dem Dienst zu entfernen."
    ],
    "privacy_policy_pipeda": "Menuonline verwaltet die Erfassung aller Informationen in Übereinstimmung mit dem kanadischen Personal Information Protection and Electronic Documents Act (PIPEDA).",
    "privacy_policy_update_notice": "Bitte beachten Sie, dass Menuonline sich das Recht vorbehält, diese Datenschutzrichtlinie jederzeit zu aktualisieren oder zu ändern. Wir werden Sie über alle Änderungen informieren, indem wir eine aktualisierte Richtlinie auf dieser Website veröffentlichen oder über die Dienste zur Verfügung stellen. Die aktuellste Version dieser Richtlinie regelt die Verwendung der persönlichen Daten der Benutzer durch mycircle. Indem Sie weiterhin auf die Website von mycircle zugreifen oder ihre Dienste weiterhin nutzen, nachdem Änderungen an dieser Datenschutzrichtlinie vorgenommen wurden, erklären Sie sich damit einverstanden, an die überarbeiteten Bedingungen gebunden zu sein.",
    "privacy_policy_consent_title": "ZUSTIMMUNG ZUR VERWENDUNG PERSÖNLICHER DATEN",
    "privacy_policy_consent_text": "Indem Sie Menuonline Ihre persönlichen Daten über unsere Website oder eines unserer Formulare zur Verfügung stellen, stimmen Sie der Erfassung, Verwendung und Offenlegung dieser Daten gemäß dieser Richtlinie oder für die Zwecke zu, die Ihnen zum Zeitpunkt der Bereitstellung der persönlichen Daten mitgeteilt wurden. Jede sekundäre Verwendung der persönlichen Daten erfolgt nur mit Ihrer ausdrücklichen Zustimmung, oder Sie erhalten die Möglichkeit, sich dagegen zu entscheiden.",
    "privacy_policy_opt_out_text": "Sie haben jederzeit die Möglichkeit, Ihre Einwilligung zu widerrufen oder sich abzumelden. Wenn Sie Ihre Einwilligung widerrufen oder sich abmelden möchten, können Sie unten in jeder E-Mail, die Sie von uns erhalten, auf den Link „Kommunikationseinstellungen“ klicken.",
    "privacy_policy_info_collection_title": "VON UNS ERHOBENE INFORMATIONEN",
    "privacy_policy_info_collection_text": "Menuonline erfasst und verarbeitet personenbezogene Daten gemäß dieser Richtlinie. Wir werden diese Informationen nicht an Dritte verkaufen, weitergeben oder vermieten, außer wie in dieser Richtlinie angegeben. Menuonline erfasst personenbezogene Daten, um Ihnen unsere Dienste oder Produkte bereitzustellen.",
    "privacy_policy_info_types_text": "Zu den von Menuonline gesammelten Informationen gehören:",
    "privacy_policy_account_holders_title": "Menuonline-Kontoinhaber",
    "privacy_policy_registration_info": "Registrierungsinformationen: Wenn Sie sich für die Nutzung von Diensten anmelden, benötigen Sie ein Menuonline-Konto, um Kontoinhaber („Kontoinhaber“) zu werden. Wenn Sie sich für ein Konto registrieren, werden Sie aufgefordert, bestimmte grundlegende Informationen anzugeben, wie z. B. Ihren Namen, Ihre E-Mail-Adresse, Ihren Benutzernamen, Ihr Passwort, Ihren Firmennamen, Ihren Beruf, Ihren Standort und Ihre Telefonnummer. Passwörter sind verschlüsselt – Mitarbeiter von Menuonline können Passwörter weder einsehen noch abrufen.",
    "privacy_policy_billing_info": "Rechnungsinformationen: Wenn Sie unsere Dienste abonnieren, müssen Sie uns Zahlungs- und Rechnungsinformationen zur Verfügung stellen.",
    "privacy_policy_cookies_info": "Cookies: Wir verwenden Cookies, um Besucherdaten zu verfolgen, beispielsweise die Quelle der Website-Besuche und die Anzahl der Website-Besuche auf unserer Website.",
    "privacy_policy_ip_info": "IP-Adressen: Im Rahmen Ihrer Nutzung der Dienste werden Ihre IP-Adressen erfasst (z. B. zum Erfassen des Standorts auf Länderebene und der bei der Nutzung unserer Dienste verwendeten IP-Adressen).",
    "privacy_policy_your_contacts_title": "Ihre Ansprechpartner",
    "privacy_policy_distribution_list": "Verteilerliste: Im Rahmen Ihrer Nutzung unserer Dienste können Sie uns personenbezogene Daten über Ihre Kontakte („Kontakte“) übermitteln oder wir können diese im Rahmen der Dienste erfassen. Diese Informationen umfassen E-Mail-Adressen und können andere identifizierbare Informationen wie Namen, Firmennamen oder andere Informationen enthalten, die Sie Menuonline bei der Nutzung der Dienste übermitteln. Sie müssen sicherstellen, dass Sie die entsprechende Zustimmung Ihrer Kontakte einholen, damit deren Informationen von uns verarbeitet werden können.",
    "privacy_policy_billing_info_contacts": "Rechnungsinformationen: Wenn Sie unsere Dienste abonnieren, müssen Sie uns Zahlungs- und Rechnungsinformationen zur Verfügung stellen.",
    "privacy_policy_cookies_contacts": "Cookies: Menuonline verwendet Cookies und ähnliche Tracking-Technologien nur dann, wenn Sie den Menuonline-Tracking-Code zu Ihrer Website hinzugefügt haben, um persönliche Informationen über Ihre Kontakte zu sammeln und zu verwenden. Alle von Menuonline gehosteten Zielseiten verfügen automatisch über einen Tracking-Code.",
    "privacy_policy_ip_contacts": "IP-Adressen: Im Rahmen der Dienste werden IP-Adressen Ihrer Kontakte erfasst (z. B. zum Erfassen des Standorts auf Länderebene und der beim Absenden eines Formulars und Erteilen der Zustimmung verwendeten IP-Adressen).",
    "privacy_policy_info_about_contacts_title": "Von uns über Ihre Kontakte erhobene Informationen",
    "privacy_policy_info_about_contacts_notice": "*Dieser Abschnitt gilt nur für Informationen, die Menuonline über die Kontakte eines Kontoinhabers zum Zwecke der Bereitstellung der Dienste sammelt, verwendet oder weitergibt.*",
    "privacy_policy_info_about_contacts_desc": "Menuonline wird nur dann personenbezogene Daten über Kontakte sammeln, verwenden oder weitergeben, wenn sich der Kontoinhaber für bestimmte Dienste registriert hat, bei denen er uns eine Verteilerliste zur Verfügung stellt. Kontoinhaber sind allein dafür verantwortlich, sicherzustellen, dass sie alle geltenden Gesetze eingehalten haben, wenn sie Menuonline über die Dienste personenbezogene Daten über ihre Kontakte zur Verfügung stellen.",
    "privacy_policy_info_auto_collect_title": "Informationen, die Menuonline automatisch sammelt",
    "privacy_policy_info_auto_collect_desc1": "Wenn Kontakte eines Kontoinhabers an einer E-Mail-Kampagne teilnehmen, die sie von einem Kontoinhaber erhalten haben, oder mit einem Link zu einer verbundenen Website eines Kontoinhabers interagieren, kann Menuonline mithilfe von Cookies Informationen über das Gerät dieses Kontakts und seine Interaktion mit der E-Mail sammeln. Diese Informationen werden nur gesammelt, um die Dienste bereitzustellen und dem Kontoinhaber Einblicke zu gewähren.",
    "privacy_policy_info_auto_collect_desc2": "Zu den Informationen, die wir über das Gerät eines Kontakts und die Anwendungen erfassen, die der Kontakt verwendet, um über unsere Dienste auf E-Mails zuzugreifen, die vom Kontoinhaber gesendet werden, können die IP-Adresse, das Betriebssystem, die Browser-ID und andere zugehörige Informationen über das System und die Verbindung des Kontakts gehören.",
    "privacy_policy_info_auto_collect_desc3": "Zu den von uns erfassten Informationen über die Interaktion von Contact mit über die Dienste gesendeten E-Mails können Datum und Uhrzeit des E-Mail-Zugriffs und der Browsing-Aktivitäten von Contact sowie Informationen zur Leistung der Dienste gehören, beispielsweise die Zustellbarkeit von E-Mails und anderen Mitteilungen, die von einem Kontoinhaber über die Dienste an Contact gesendet werden.",
    "privacy_policy_info_auto_collect_desc4": "Bitte beachten Sie, dass Menuonline keinen Ihrer Kontakte kontaktiert, es sei denn, dies ist gesetzlich vorgeschrieben.",
    "privacy_policy_why_we_collect_title": "WARUM WIR IHRE DATEN ERHEBEN",
    "privacy_policy_why_we_collect_desc": "Menuonline sammelt Informationen für die folgenden Zwecke:",
    "privacy_policy_why_collect_1": "um Menuonline-Kontoinhabern die Dienste bereitzustellen, die sie abonnieren, einschließlich der Verwendung der Kontoinhaberinformationen für die Dienste;",
    "privacy_policy_why_collect_2": "zur Administration und Kontoverwaltung sowie für den Zugriff auf die Website von mycircle;",
    "privacy_policy_why_collect_3": "wenn Sie dem Erhalt von Marketing- und Werbematerialien zugestimmt haben, um Sie über andere von Menuonline angebotene Produkte und Dienstleistungen zu informieren; und",
    "privacy_policy_why_collect_4": "um auf Mitteilungen des Kontoinhabers zu antworten oder um von Ihnen angeforderte Informationen zu senden. Dies kann Folgendes umfassen, ist aber nicht darauf beschränkt:",
    "privacy_policy_why_collect_5": "Sonderangebote und Updates;",
    "privacy_policy_why_collect_6": "Newsletter; und",
    "privacy_policy_why_collect_7": "Servicemitteilungen.",
    "privacy_policy_opt_out_desc": "Sie können den Erhalt von Nachrichten jederzeit ablehnen, indem Sie unten in jeder E-Mail, die Menuonline an Sie sendet, auf die Schaltfläche zum Abbestellen klicken.",
    "privacy_policy_use_disclosure_title": "Verwendung und Offenlegung persönlicher Daten",
    "privacy_policy_use_disclosure_desc1": "Persönliche Informationen, die Menuonline übermittelt werden, einschließlich über die Website von Menuonline oder ein vorgeschriebenes Formular, werden streng vertraulich behandelt, sofern sie nicht gemäß der ausdrücklichen Zustimmung eines Kontoinhabers oder gesetzlichen Anforderungen offengelegt werden. In jedem Fall ist die Angabe persönlicher Informationen auf einer „Menuonline“-Plattform durch Kontoinhaber freiwillig: Alle persönlichen Informationen, die ein Kontoinhaber angibt, werden nach eigenem Ermessen übermittelt. Bitte beachten Sie, dass der Widerruf Ihrer Zustimmung dazu führen kann, dass Sie auf bestimmte Menuonline-Dienste nicht zugreifen oder diese nicht nutzen können.",
    "privacy_policy_use_disclosure_desc2": "Menuonline nutzt für seine Website und Dienste Drittanbieter und externe Dienstleister. Die persönlichen Daten des Kontoinhabers können an diese Drittanbieter und Anbieter weitergegeben werden. Wir können auch aggregierte Statistiken über Kontoinhaber, Verkäufe, Verkehrsmuster und ähnliche Informationen an seriöse Drittanbieter weitergeben. Menuonline verlangt, dass jeder Drittanbieter, der Zugriff auf persönliche Daten hat, an Verpflichtungen gebunden ist, die mit dieser Datenschutzrichtlinie übereinstimmen, und dass diese Anbieter die persönlichen Daten des Kontoinhabers nur zum Zweck der Bereitstellung von Menuonline-Diensten erfassen, verwenden oder offenlegen.",
    "privacy_policy_use_disclosure_desc3": "Persönliche Informationen, die von Kontoinhabern bereitgestellt werden, werden nicht an externe Organisationen verkauft, weitergegeben oder vermietet, es sei denn, dies geschieht in Übereinstimmung mit Ihrer stillschweigenden oder ausdrücklichen Zustimmung. Menuonline behält sich das Recht vor, jeden Kontoinhaber zu kontaktieren, um Informationen weiterzuleiten, auf Fragen zu antworten oder über Änderungen an unserem Programm oder unseren Richtlinien zu informieren. Bitte beachten Sie, dass Menuonline keinen Ihrer Kontakte kontaktiert, es sei denn, dies ist gesetzlich vorgeschrieben.",
    "privacy_policy_use_disclosure_desc4": "Menuonline gibt personenbezogene Daten nur dann weiter, wenn die betreffende Person ihre Zustimmung erteilt hat oder:",
    "privacy_policy_disclosure_list_1": "wenn dies gesetzlich zulässig oder vorgeschrieben ist, beispielsweise als Reaktion auf eine Vorladung oder ein anderes Rechtsverfahren;",
    "privacy_policy_disclosure_list_2": "an einen Erwerber von Menuonline insgesamt oder von Teilen davon;",
    "privacy_policy_disclosure_list_3": "an die Dienstleister von Menuonline gemäß dieser Richtlinie; oder",
    "privacy_policy_disclosure_list_4": "um gesetzlichen Verpflichtungen nachzukommen, insbesondere gesetzlichen Meldepflichten.",
    "privacy_policy_service_providers_title": "Menuonline verwendet die folgenden Drittparteien und Dienstanbieter (persönliche Informationen Ihrer Kontakte werden „solchen“ Drittparteien nicht offengelegt):",
    "privacy_policy_service_provider_1": "Wir verwenden ManyChat, um Website-Chatdienste für Vertrieb und Support sowie Folge-E-Mails zu diesen Gesprächen bereitzustellen.",
    "privacy_policy_service_provider_2": "Wir nutzen AskNicely für die Befragung unserer Kunden zur Qualität der erhaltenen Dienstleistungen und Unterstützung.",
    "privacy_policy_service_provider_3": "Wir verwenden join.me, um Treffen mit Kunden und potenziellen Kunden, Supportanrufe und Softwaredemos zu planen.",
    "privacy_policy_service_provider_4": "Wir verwenden Google Analytics, um die anonyme Website-Aktivität zu verfolgen und die Nutzungsmuster auf unserer Website zu messen.",
    "privacy_policy_breach_notification_title": "BENACHRICHTIGUNG BEI VERLETZUNGEN",
    "privacy_policy_breach_notification_desc": "Menuonline verpflichtet sich, die geltenden Gesetze im Hinblick auf die unbefugte Nutzung, den Zugriff oder die Offenlegung personenbezogener Daten einzuhalten und den Kontoinhabern angemessene Unterstützung bei der Einhaltung dieser Gesetze zu bieten.",
    "privacy_policy_rights_access_title": "IHRE RECHTE UND ZUGANG",
    "privacy_policy_rights_access_desc": "Sie haben das Recht auf Zugriff auf Ihre persönlichen Daten, die Menuonline verarbeitet. Sie können von uns Informationen anfordern über:",
    "privacy_policy_rights_access_list_1": "Die persönlichen Daten, die wir über Sie speichern",
    "privacy_policy_rights_access_list_2": "Die Kategorien personenbezogener Daten, die verarbeitet werden",
    "privacy_policy_rights_access_list_3": "Zwecke der Verarbeitung",
    "privacy_policy_rights_access_list_4": "Angaben, an wen Ihre persönlichen Daten weitergegeben wurden\/werden",
    "privacy_policy_rights_access_list_5": "Wie lange wir Ihre persönlichen Daten speichern",
    "privacy_policy_rights_access_list_6": "Sofern wir die Daten nicht direkt bei Ihnen erhoben haben, Angaben zur Quelle",
    "privacy_policy_rights_access_list_7": "So reichen Sie eine Beschwerde bei der richtigen Aufsichtsbehörde ein",
    "privacy_policy_rights_access_list_8": "Sie können außerdem Folgendes von uns anfordern:",
    "privacy_policy_rights_access_list_9": "Dass wir unvollständige oder ungenaue Informationen über Sie aktualisieren",
    "privacy_policy_rights_access_list_10": "Fordern Sie, dass wir Ihre persönlichen Daten gemäß den geltenden Gesetzen löschen",
    "privacy_policy_rights_access_contact": "Sie können die Ausübung Ihrer Rechte verlangen, indem Sie uns unter 2851 Dufferin Street, Toronto, ON, Kanada kontaktieren oder uns eine E-Mail an {link} senden.",
    "privacy_policy_accountability_title": "RECHENSCHAFTSPFLICHT",
    "privacy_policy_accountability_desc": "Menuonline ist verpflichtet, die Verantwortung für die persönlichen und vertraulichen Informationen zu übernehmen, die Sie uns zur Verfügung stellen. Kontaktinformationen finden Sie am Ende dieser Richtlinie.",
    "privacy_policy_links_other_sites_title": "LINKS ZU ANDEREN WEBSITES",
    "privacy_policy_links_other_sites_desc1": "Die Website von Menuonline kann Links zu anderen Websites enthalten. Bitte beachten Sie, dass Menuonline nicht für die Datenschutzpraktiken anderer Websites verantwortlich ist. Wir empfehlen unseren Kontoinhabern, sich bewusst zu sein, dass sie beim Verlassen unserer Website die Datenschutzbestimmungen jeder einzelnen Website, die personenbezogene Daten sammelt, sorgfältig lesen sollten.",
    "privacy_policy_links_other_sites_desc2": "Diese Datenschutzrichtlinie gilt ausschließlich für von uns erfasste Informationen.",
    "privacy_policy_links_other_sites_desc3": "Wenn Sie über einen Link von der Site von Menuonline zum Kauf von Produkten oder Dienstleistungen gelangen, schließen Sie eine Kauftransaktion gemäß den Regeln und Bestimmungen des Verkäuferunternehmens ab, nicht gemäß denen von Menuonline.",
    "privacy_policy_cookies_title": "DATENSCHUTZERKLÄRUNG COOKIES",
    "privacy_policy_cookies_desc1": "Die Menuonline-Website und -Dienste verwenden Cookies, um bestimmte Informationen gemäß dieser Richtlinie zu sammeln und zu speichern. Ein „Cookie“ ist eine kleine Textdatei, die von einer Website gesendet und vom Webbrowser des Benutzers auf dem Computer des Benutzers gespeichert wird. Beim Besuch einer Website, die Cookies verwendet, wird ein Cookie auf Ihren Computer oder Ihr Mobilgerät heruntergeladen. Wenn Sie diese Website das nächste Mal besuchen, merkt sich Ihr Gerät nützliche Informationen wie Einstellungen, besuchte Seiten oder Anmeldeoptionen.",
    "privacy_policy_cookies_desc2": "Cookies werden häufig verwendet, um die Funktionalität von Websites zu erweitern oder um sicherzustellen, dass diese effizienter arbeiten. Unsere Website verwendet Cookies, um das Benutzererlebnis zu optimieren und sicherzustellen, dass die Dienste der Website ordnungsgemäß funktionieren.",
    "privacy_policy_cookies_desc3": "Die meisten Webbrowser ermöglichen über die Browsereinstellungen eine gewisse Kontrolle über die Einschränkung oder Blockierung von Cookies. Wenn Sie Cookies jedoch deaktivieren, kann dies Ihre Fähigkeit beeinträchtigen, bestimmte Teile unserer Website oder Dienste zu nutzen.",
    "privacy_policy_cookies_analytical_purpose": "<strong> Analytische Zwecke: <\/strong> Wir verwenden Cookies, um die Aktivitäten von Kontoinhabern zu analysieren und so unsere Website zu verbessern. Beispielsweise können wir Cookies verwenden, um aggregierte Muster zu untersuchen, wie etwa die Funktionen, die Kontoinhaber verwenden. Wir können solche Analysen verwenden, um Erkenntnisse darüber zu gewinnen, wie wir die Funktionalität und Benutzerfreundlichkeit unserer Website verbessern können.",
    "privacy_policy_cookies_preferences_user_experience": "<strong> Ihre Präferenzen und Benutzererfahrung: <\/strong> Wir verwenden Cookies, um bestimmte Informationen über Besucher und Kontoinhaber zu sammeln, wie etwa Browsertyp, Server, Spracheinstellungen und Ländereinstellungen, um die Präferenzen des Kontoinhabers auf unserer Website zu speichern und so die Erfahrung des Kontoinhabers konsistenter und bequemer zu gestalten. Wir verwenden Cookies, um Ihren Anmeldestatus beizubehalten, wenn Sie unsere Website wiederholt besuchen.",
    "privacy_policy_cookies_targeted_ads": "<strong> Bereitstellung von Messdiensten und besser zielgerichteter Werbung und Marketing: <\/strong> Wir verwenden Cookies, Web Beacons und andere Speichertechnologien von Drittanbietern wie Google und Facebook für Messdienste, besser zielgerichtete Werbung und für Marketingzwecke. Dies geschieht, wenn Sie unsere Website besuchen. Diese Cookies, Web Beacons und anderen Speichertechnologien ermöglichen es uns, Ihnen Werbematerial von Menuonline auf anderen Websites anzuzeigen, die Sie im Internet besuchen.",
    "privacy_policy_security_title": "SICHERHEIT",
    "privacy_policy_security_desc1": "Menuonline unternimmt Anstrengungen, um vertrauliche Informationen aller Art, einschließlich persönlicher Informationen, mit organisatorischen, physischen, mechanischen und elektronischen Sicherheitsvorkehrungen zu schützen, die der Sensibilität der Informationen angemessen sind. Unsere Server befinden sich in Kanada und entsprechen den Industriestandards in Bezug auf Sicherheit.",
    "privacy_policy_security_desc2": "Beachten Sie jedoch, dass wir nicht garantieren können, dass die von uns ergriffenen Maßnahmen die Sicherheit der Informationen gewährleisten.",
    "privacy_policy_retention_title": "ZURÜCKBEHALTUNG",
    "privacy_policy_retention_desc": "Wir bewahren personenbezogene Daten von Kontoinhabern und Kontakten so lange auf, wie dies für die in dieser Datenschutzrichtlinie dargelegten Zwecke erforderlich ist und in Übereinstimmung mit den Aufbewahrungsbestimmungen in den Nutzungsbedingungen.",
    "privacy_policy_access_questions_title": "ZUGANG, FRAGEN UND BEDENKEN",
    "privacy_policy_access_questions_desc": "Eine Person, die Menuonline personenbezogene Daten übermittelt hat, kann eine schriftliche Anfrage zum Zugriff auf ihre bei Menuonline gespeicherten personenbezogenen Daten oder zu deren Korrektur einreichen.",
    "terms_obj": {
        "terms_conditions_title": "GESCHÄFTSBEDINGUNGEN",
        "terms_conditions_subtitle": "Allgemeine Geschäftsbedingungen für Neu- und Bestandskunden",
        "terms_conditions_desc1": "DIE FOLGENDEN WICHTIGSTEN PUNKTE (EINSCHLIESSLICH ABSCHNITTVERWEISE) WERDEN NUR ZU IHRER KOMFORTABEL BEREITGESTELLT UND ERSETZEN NICHT DIE VOLLSTÄNDIGEN GESCHÄFTSBEDINGUNGEN. ES LIEGT IN IHRER VERANTWORTUNG, DIESE GESCHÄFTSBEDINGUNGEN VOR DER VERWENDUNG DES MENÜONLINE-DIENSTES ZU LESEN.",
        "terms_conditions_desc2": "Durch die Nutzung von Menuonline erklären Sie sich mit den Allgemeinen Geschäftsbedingungen einverstanden und versichern und garantieren, dass Sie von Ihrer Organisation oder Firma dazu autorisiert sind. (Siehe Abschnitt 3 „So funktioniert es“)",
        "terms_conditions_desc3": "Menuonline ist ein genehmigungsbasierter Marketingdienst. Das absichtliche Versenden von „Spam“ oder die Verletzung der Allgemeinen Geschäftsbedingungen führt zur Schließung Ihres Kontos. (Siehe Abschnitt 16 „Akzeptable Nutzung des Dienstes“)",
        "terms_conditions_desc4": "Daten, die Sie in Menuonline hosten, wie Kontaktdatensätze, E-Mail-Inhalte und Informationen, die Sie über Menuonline sammeln, gehören Ihnen. (Siehe Abschnitt 14 „Was gehört Ihnen?“).",
        "terms_conditions_desc5": "Die Menuonline-Software und der Inhalt unserer Website gehören uns. (Siehe Abschnitt 13 „Was gehört uns?“)",
        "terms_conditions_desc6": "Abgesehen von Ihren eigenen Benutzern können die Daten oder unveröffentlichten Inhalte, die Sie bei Menuonline hosten, nur von Mitarbeitern und Partnern von Menuonline eingesehen werden, die Vertraulichkeitsvereinbarungen unterzeichnet haben. (Siehe Abschnitt 14 „Was gehört Ihnen?“).",
        "terms_conditions_desc7": "Alle personenbezogenen Daten (PII) werden ausschließlich auf Servern in Kanada gespeichert. (Siehe Abschnitt 14 „Was gehört Ihnen?“).",
        "terms_conditions_desc8": "Der Menuonline-Dienst unterliegt den Gesetzen Kanadas und der Provinz Ontario. (Siehe Abschnitt 24 „Geltendes Recht“)",
        "terms_conditions_desc9": "Alle Anfragen nach Ihren Daten oder Inhalten durch Einzelpersonen, die Polizei oder andere Aufsichtsbehörden innerhalb oder außerhalb Kanadas werden immer an Sie weitergeleitet. Die einzige Ausnahme wäre, wenn wir dadurch gegen kanadisches oder Ontario-Recht verstoßen würden; in allen Fällen werden wir uns jedoch energisch gegen die Weitergabe Ihrer Daten durch andere als Sie wehren. (Siehe Abschnitt 14 „Was gehört Ihnen?“).",
        "terms_conditions_desc10": "Die Sicherheit von Menuonline ist nur so gut wie die Verwaltung Ihrer Benutzernamen und Passwörter. Wir berechnen keine Gebühren für Menuonline-Benutzerkonten. Erstellen Sie daher für jeden Benutzer ein eigenes Konto und halten Sie die Passwörter geheim. Sie sind für Ihr Konto verantwortlich. (Siehe Abschnitt 3 „So funktioniert es“)",
        "terms_conditions_desc11": "Sie können Ihr Konto jederzeit kündigen. Im Falle einer Kündigung erstatten wir Ihnen kein ungenutztes Guthaben zurück. (Siehe Abschnitt 3 „So funktioniert es“)",
        "terms_conditions_desc12": "Wir können Ihr Konto jederzeit kündigen. Wenn wir Ihr Konto kündigen, erstatten wir Ihnen E-Mail-Guthaben und ungenutzte Dienste. (Siehe Abschnitt 3 „So funktioniert es“)",
        "terms_conditions_desc13": "Ihre Daten werden nach der Kündigung gelöscht und können auch gelöscht werden, wenn sich ein Kontobenutzer 12 Monate oder länger nicht angemeldet hat. Wir bewahren aggregierte Daten aus Ihrem Konto auf, die keine personenbezogenen Daten enthalten, für statistische Zwecke. (Siehe Abschnitt 3 „So funktioniert es“)",
        "terms_conditions_desc14": "E-Mail-Guthaben werden auf Pay-as-you-go-Basis erworben und verfallen nie. Die einzige Ausnahme ist, wenn Sie sich 12 Monate lang nicht bei Ihrem Konto angemeldet haben. In diesem Fall behalten wir uns das Recht vor, Ihr Konto, Ihre Daten und die damit verbundenen Guthaben zu löschen. (Siehe Abschnitt 10 „Was bezahle ich für den Dienst?“)",
        "terms_conditions_desc15": "Wir bemühen uns nach Kräften, sicherzustellen, dass Menuonline jederzeit verfügbar ist, und informieren Sie im Voraus, wenn wir den Dienst vorübergehend wegen Wartungsarbeiten aussetzen müssen. Wir garantieren jedoch nicht, dass der Dienst jederzeit verfügbar ist, und haften nicht für Verluste, die Ihnen durch die Nutzung von Menuonline entstehen, einschließlich der Verluste, die Ihnen entstehen, wenn das System zu einem Zeitpunkt, zu dem Sie es benötigen, nicht läuft. (Siehe Abschnitt 17 „Haftungsausschlüsse“).",
        "terms_conditions_desc16": "Wir überwachen weder Ihre Inhalte noch die Personen, denen Sie Nachrichten senden. Wir überwachen jedoch Beschwerden und andere Probleme, die durch Ihre Aktivitäten entstehen. Zu viele Beschwerden oder Probleme können nach unserem Ermessen zur Kündigung Ihres Dienstes führen. (Siehe Abschnitt 7 „Überwachung“)",
        "terms_conditions_desc17": "Wir sind bestrebt, hervorragenden Produktsupport zu bieten, damit Sie mit Menuonline autark arbeiten können. Wir bieten Support in Form von Dokumentation und Videos auf unserer Website, dem Menuonline-Supportticketsystem und per Telefon. Der Produktsupport ist kostenlos; wir behalten uns jedoch das Recht vor, den Support für bestimmte Kundenkonten nach eigenem Ermessen einzustellen. (Siehe Abschnitt 5 „Support“)",
        "terms_conditions_desc18": "Produktservices sind für die Fälle gedacht, in denen Sie möchten, dass wir Arbeiten im Zusammenhang mit Menuonline übernehmen, anstatt sie selbst auszuführen. Unser Preis für Produktservices beträgt 135 USD pro Stunde. Wir bieten auch Beratungsleistungen zu höheren Preisen an. Wir behalten uns das Recht vor, unsere Preise jederzeit zu ändern. (Siehe Abschnitt 6 „Produkt- und Beratungsleistungen“)",
        "terms_conditions_desc19": "Wenn wir glauben, dass es zu einer Verletzung des Schutzes Ihrer Daten gekommen ist, werden wir Sie informieren und uns bemühen, Ihnen entsprechende Einzelheiten und Informationen zukommen zu lassen. (Siehe Abschnitt 14 „Was gehört Ihnen?“)",
        "terms_conditions_desc20": "Wir behalten uns das Recht vor, diese Vereinbarung jederzeit zu ändern. Wir werden versuchen, Sie über Änderungen der Geschäftsbedingungen zu informieren, aber die Nutzung des Dienstes setzt voraus, dass Sie den Änderungen der Bedingungen zustimmen. (Siehe Abschnitt 9 „Änderungen“).",
        "terms_conditions_desc21": "Die Allgemeinen Geschäftsbedingungen und unsere Datenschutzrichtlinie wurden nur in englischer Sprache verfasst und Sie erklären sich damit einverstanden, dass die englischsprachige Version der Allgemeinen Geschäftsbedingungen und der Datenschutzrichtlinie im Falle eines Konflikts mit einer übersetzten Version Vorrang hat.",
        "terms_conditions_mycircle": "MYCIRCLE AGB",
        "terms_conditions_last_update": "LETZTE AKTUALISIERUNG: 14. Januar 2020",
        "terms_conditions_usage_agreement": "Bitte lesen Sie diese Servicebedingungen (die „Bedingungen“) sorgfältig durch, bevor Sie diese Website (die „Site“) nutzen. Diese Bedingungen befreien Menuonline („Menuonline“, „wir“ oder „uns“) und andere von der Haftung und\/oder beschränken unsere und ihre Haftung und enthalten weitere wichtige Bestimmungen, die für Ihre Nutzung dieser Site gelten.",
        "terms_conditions_acceptance": "Indem Sie diese Site oder den Dienst besuchen oder verwenden, erklären Sie sich in Ihrem eigenen Namen und im Namen aller Organisationen, in deren Namen Sie handeln (im Folgenden gemeinsam als „Sie“ bezeichnet), damit einverstanden, diese Bedingungen bei jeder Verwendung und jedem Besuch dieser Site zu akzeptieren und einzuhalten.",
        "terms_conditions_services": "1. Unsere Leistungen.",
        "terms_conditions_services_desc": "Bei den Diensten, die wir über die Site anbieten, handelt es sich um einen Dienst, mit dem Sie E-Mail-Nachrichten („E-Mails“) an Empfänger Ihrer Wahl erstellen, senden und verwalten können (der „Dienst“).",
        "terms_conditions_license": "2. Lizenz.",
        "terms_conditions_license_desc": "Vorbehaltlich dieser Bedingungen gewährt Ihnen Menuonline hiermit während der Laufzeit eine nicht exklusive, begrenzte, nicht übertragbare Lizenz zum Anzeigen der Site und zur Nutzung des Dienstes, wie es die Funktionen des Dienstes erlauben. Wir behalten uns alle Rechte vor, die hierin im Dienst und im Menuonline-Inhalt (wie unten definiert) nicht ausdrücklich gewährt werden.",
        "terms_conditions_account": "3. So funktioniert es.",
        "terms_conditions_account_desc": "Um den Dienst nutzen zu können, müssen Sie sich für ein Konto registrieren. Ihr Konto ist kostenlos. Ihr Konto gibt Ihnen Zugriff auf den Dienst und „Funktionen“, die wir von Zeit zu Zeit einrichten und pflegen können. Um für ein Konto berechtigt zu sein, müssen Sie:",
        "terms_conditions_account_requirements": [
            "mindestens achtzehn (18) Jahre alt und geschäftsfähig sein;",
            "den von uns beschriebenen Registrierungsprozess abschließen;",
            "diesen Allgemeinen Geschäftsbedingungen zustimmen;",
            "Geben Sie wahrheitsgemäße, vollständige und aktuelle Kontakt- und Rechnungs-\/Zahlungsinformationen an."
        ],
        "responsibility": "Verantwortung.",
        "responsibilityText": "Sie sind allein verantwortlich für die Aktivitäten, die auf Ihrem Konto stattfinden, und Sie müssen Ihr Kontokennwort vertraulich und sicher aufbewahren. Konten sind kostenlos, daher empfehlen wir Ihnen, für jeden Benutzer ein anderes Konto einzurichten. Sie sind auch für jedes Konto verantwortlich, auf das Sie Zugriff haben, unabhängig davon, ob Sie die Nutzung dieses Kontos autorisiert haben oder nicht. Sie erklären sich damit einverstanden, dass Sie allein verantwortlich sind für Ihre Interaktionen mit denjenigen, denen Sie E-Mails senden, und dass Sie nur E-Mails an diejenigen Personen senden, deren Zustimmung Sie haben. Sie müssen uns unverzüglich über jede unbefugte Nutzung Ihrer Konten informieren.",
        "compliance_with_CASL": "Einhaltung der kanadischen Anti-Spam-Gesetzgebung (CASL).",
        "compliance_with_CASL_text": "Die Rolle von Menuonline besteht darin, die Verwaltung von Zustimmungseinstellungen zu erleichtern, nicht darin, Gesetze durchzusetzen. Es liegt in Ihrer Verantwortung, die CASL-Vorschriften einzuhalten. Wir helfen Ihnen, indem wir Standardfunktionen festlegen und das Einholen und Aktualisieren dokumentierter ausdrücklicher Zustimmungen sowie die Verwaltung fortlaufender Ablaufdaten für stillschweigende Zustimmungen erleichtern. Sie haben die volle Kontrolle über die Verwaltung der Zustimmung für Ihre Datenbank. Sie können den Zustimmungsstatus für alle Ihre Kontakte jederzeit stapelweise aktualisieren, Standardeinstellungen überschreiben und das automatische Ablaufen des stillschweigenden Zustimmungsstatus deaktivieren.",
        "closing_account": "Schließen Ihres Kontos.",
        "closing_account_text": "Sowohl Sie als auch Menuonline können diese Vereinbarung jederzeit und aus beliebigem Grund durch Mitteilung an die andere Partei kündigen. Wenn Sie unwahre, ungenaue, nicht aktuelle oder unvollständige Informationen angeben oder den Dienst in einer Weise nutzen, die gegen diese Allgemeinen Geschäftsbedingungen verstößt, haben wir das Recht, Ihr Konto sofort zu sperren und Ihren Zugang zum Dienst zu beenden, zusätzlich zu allen anderen uns zur Verfügung stehenden Rechtsmitteln. Wenn Sie Ihr Konto zwölf (12) Monate lang nicht nutzen, können wir Ihr Konto kündigen. Wenn wir Ihr Konto aus irgendeinem Grund kündigen, erstatten wir Ihnen alle verbleibenden Credits zurück. Nach der Kündigung löschen wir Ihr Konto und alle damit verbundenen Daten, einschließlich der von Ihnen gesendeten E-Mails, dauerhaft. Wir können aggregierte Daten aus Ihrem Konto, die keine personenbezogenen Daten enthalten, für statistische Zwecke aufbewahren.",
        "emailing_content": "Inhalte per E-Mail versenden.",
        "emailing_content_text": "Wenn Sie über diese Site oder den Service mit Menuonline interagieren, verpflichten Sie sich, wahrheitsgetreue und genaue Informationen bereitzustellen. Darüber hinaus verstehen Sie, dass die Übermittlung vertraulicher Informationen per E-Mail eine unsichere Praxis ist. Sie verstehen, dass Sie allein für alles verantwortlich sind, was Sie an Personen senden, die unseren Service nutzen.",
        "support": "Unterstützung.",
        "support_text": "Wir bieten Ihnen Support, damit Sie den Service selbstständig nutzen können. Wir bieten Ihnen Support in Form von Dokumentationen und Videos auf der Site, dem Menuonline-Supportticketsystem und per Telefon. Sie erreichen uns, indem Sie oben auf der Site auf die Registerkarte „Support“ klicken. Der Produktsupport ist kostenlos. Wir behalten uns jedoch das Recht vor, den Support für bestimmte Kundenkonten nach eigenem Ermessen einzustellen.",
        "product_and_consulting_services": "Produkt- und Beratungsdienste.",
        "product_and_consulting_services_text": "Produktservices sind für die Fälle gedacht, in denen Sie möchten, dass wir Arbeiten im Zusammenhang mit Menuonline übernehmen, anstatt sie selbst auszuführen. Unser Preis für Produktservices beträgt 125 USD pro Stunde. Wir bieten auch Beratungsleistungen zu höheren Preisen an. Wir behalten uns das Recht vor, unsere Preise jederzeit zu ändern.",
        "monitoring": "Überwachung.",
        "monitoring_text": "Wir überwachen oder beurteilen den Inhalt der über den Dienst übertragenen Informationen nicht, werden jedoch Beschwerden über eine mögliche unangemessene Nutzung nachgehen. Menuonline kann jederzeit und nach eigenem Ermessen mit oder ohne Benachrichtigung an Sie gemäß den folgenden Richtlinien entscheiden, ob bestimmte Nutzungen angemessen sind oder nicht.",
        "term": "Begriff.",
        "term_text": "Die Laufzeit beginnt, wenn Sie sich bei uns für ein Konto anmelden, und dauert an, solange Sie den Dienst nutzen. Sie erklären und garantieren, dass Sie die Befugnis und Fähigkeit haben, sich bei uns für ein Konto anzumelden (auch wenn Sie sich im Namen einer Organisation anmelden).",
        "changes": "Änderungen.",
        "changes_text": "Die Laufzeit beginnt, wenn Sie sich bei uns für ein Konto anmelden, und dauert an, solange Sie den Dienst nutzen. Sie erklären und garantieren, dass Sie die Befugnis und Fähigkeit haben, sich bei uns für ein Konto anzumelden (auch wenn Sie sich im Namen einer Organisation anmelden).",
        "payment": "ZAHLUNG",
        "what_do_i_pay_for_service": "Was bezahle ich für den Service?",
        "what_do_i_pay_for_service_text": "Sie können Prepaid-Credits kaufen, um E-Mails („Credits“) über unseren Service zu versenden, wie wir auf der Seite „Preise“ unserer Website ausführlich beschreiben. Die Credits verfallen nie und es fallen keine wiederkehrenden Gebühren für diese Credits an. Die einzige Ausnahme ist, dass wir Ihr Konto und alle ungenutzten Credits kündigen können, wenn Sie Ihr Konto zwölf (12) Monate lang nicht nutzen. Wir können unsere Preise jederzeit ändern, indem wir die Preisseiten unserer Website aktualisieren. Diese Änderungen gelten für Ihren nächsten Kauf von Credits.",
        "how_do_i_pay_for_service": "Wie bezahle ich für den Service?",
        "how_do_i_pay_for_service_text": "Sie können Credits mit allen gängigen Kreditkarten bezahlen. Wir können die akzeptierten Zahlungsmethoden jederzeit ändern.",
        "can_i_get_refund": "Kann ich eine Rückerstattung erhalten?",
        "can_i_get_refund_text": "Die Beträge, die Sie für die Nutzung des Dienstes bezahlt haben, sind bei Kündigung Ihres Kontos nicht erstattungsfähig. Wenn wir Ihr Konto kündigen, erstatten wir Ihnen alle verbleibenden Credits.",
        "rights": "RECHTE",
        "what_do_we_own": "Was besitzen wir?",
        "what_do_we_own_text": "Mit Ausnahme der Inhalte, die Sie uns bereitstellen (einschließlich, aber nicht beschränkt auf Kontaktdatensätze, E-Mail-Inhalte und Informationen, die Sie über den Dienst erfassen) („Ihre Inhalte“), sind alle auf unserer Site und im Dienst enthaltenen oder dadurch übertragenen Materialien, einschließlich, aber nicht beschränkt auf Software, Bilder, Texte, Grafiken, Illustrationen, Logos, Patente, Handelsmarken, Dienstleistungsmarken, Fotos, Audio- und Videodateien, Musik und Inhalte anderer Benutzer (die „Menuonline-Inhalte“) (und alle damit verbundenen geistigen Eigentumsrechte) unser Eigentum oder das Eigentum unserer Lizenzgeber.",
        "what_do_you_own": "Was besitzt du?",
        "what_do_you_own_text": "Wir erheben keinen Anspruch auf Eigentumsrechte an Ihren Inhalten. Ihre Inhalte gehören Ihnen und Sie behalten das Eigentum an den Inhalten, die Sie auf den Dienst hochladen. Indem Sie Ihre Inhalte über den Dienst teilen, erklären Sie sich damit einverstanden, dass andere Ihre Inhalte gemäß Ihren Einstellungen und diesen Geschäftsbedingungen anzeigen, bearbeiten und\/oder teilen dürfen. Sie erklären und gewährleisten, dass Sie entweder Eigentümer aller Materialien sind, die Sie in Ihren E-Mails verwenden, oder die Erlaubnis haben, diese zu verwenden. Wir dürfen Ihre Materialien nur so verwenden oder offenlegen, wie wir es in diesen Geschäftsbedingungen und unserer Datenschutzrichtlinie beschreiben.",
        "privacy_statement": "Menuonline respektiert die Privatsphäre seiner Benutzer. Alle persönlichen Daten, die Menuonline über diese Site oder den Service erfasst, unterliegen der Datenschutzrichtlinie von Menuonline, die durch Verweis in diese Bedingungen aufgenommen wird. Wir können Ihre Daten gemäß unserer Datenschutzrichtlinie verwenden und offenlegen.",
        "access_to_your_content": "Menuonline gewährt keinem anderen Benutzer Zugriff auf Ihre Inhalte als den Mitarbeitern und Vertragspartnern von Menuonline, die mit uns entsprechende Vertraulichkeitsvereinbarungen abgeschlossen haben oder für die wir Ihr Einverständnis haben.",
        "governmentRequest": "Wenn wir von einer Regierung oder einer Regulierungsbehörde eine Anfrage zum Zugriff auf Ihre Inhalte erhalten, benachrichtigen wir Sie über die Anfrage und holen Ihre Zustimmung ein, bevor wir Ihre Inhalte freigeben. Wir werden jegliche Anfragen nach Ihren Inhalten ablehnen und Ihre Inhalte als Reaktion auf derartige Anfragen nicht ohne Ihre Zustimmung oder auf Aufforderung eines zuständigen Gerichts oder der zuständigen Regulierungsbehörde freigeben.",
        "breachNotification": "Falls wir feststellen, dass Ihre Inhalte unbefugtem Zugriff, Verwendung, Kopieren, Diebstahl, Verlust oder Verwendung oder Offenlegung unter Verstoß gegen diese Bedingungen (ein „Verstoß“) ausgesetzt waren, benachrichtigen wir Sie umgehend und informieren Sie über den Vorfall. Sie erklären sich damit einverstanden, dass Verstöße gemäß geltendem Recht behandelt werden. Wenn Sie Mitglieder Ihrer E-Mail-Verteilerlisten informieren müssen, erklären Sie sich damit einverstanden, dies unverzüglich und gemäß unseren Anweisungen zu tun.",
        "contentRetention": "Wir werden Ihre Inhalte in Kanada speichern, verwenden und übermitteln, es sei denn, Sie haben uns zuvor schriftlich Ihre Zustimmung erteilt oder uns eine entsprechende Anweisung erteilt (beispielsweise, wenn Sie Ihre Inhalte an Personen außerhalb Kanadas senden möchten). Die zur Bereitstellung des Dienstes verwendeten Server befinden sich in Kanada.",
        "accessTrouble": "Wenn Sie Probleme beim Zugriff auf Ihre Inhalte haben, unterstützt Sie Menuonline gegen Aufpreis beim Abrufen Ihrer Inhalte.",
        "reportingAbuse": "Wenn Sie glauben, dass jemand gegen eine dieser Bedingungen verstößt, benachrichtigen Sie uns bitte umgehend. Wenn Sie Spam erhalten, von dem Sie glauben, dass er von einem anderen Benutzer des Dienstes stammt, wenn Sie glauben, dass ein anderer Benutzer des Dienstes Material veröffentlicht hat, das Urheberrechte verletzt, oder wenn Sie glauben, dass ein anderer Benutzer des Dienstes diese Bedingungen anderweitig verletzt, kontaktieren Sie uns bitte unter Support@menuonline.com.",
        "acceptableUse": "Sie erklären und garantieren, dass Ihre Nutzung des Dienstes allen geltenden Gesetzen und Vorschriften entspricht. Sie erklären sich damit einverstanden, dass Sie selbst dafür verantwortlich sind, festzustellen, ob der Dienst den Bestimmungen Ihrer Gerichtsbarkeit entspricht und für Ihre Nutzung in Ihrem Land geeignet ist. Zur größeren Sicherheit erklären Sie sich damit einverstanden, dass Sie (und Ihre Mitarbeiter, Vertreter und autorisierten Benutzer) ...",
        "exportLaws": "Wir übernehmen keine Gewähr dafür, dass die Site oder der Dienst für die Nutzung außerhalb Kanadas geeignet oder verfügbar sind, und der Zugriff auf sie aus Gebieten, in denen ihre Inhalte illegal sind, ist verboten. Sie dürfen den Menuonline-Inhalt oder andere Materialien auf dieser Site oder Kopien oder Adaptionen nicht unter Verletzung geltender Gesetze oder Vorschriften verwenden, exportieren oder reexportieren, einschließlich, aber nicht beschränkt auf kanadische und US-amerikanische Exportgesetze und -vorschriften.",
        "disclaimers": "Der Dienst und diese Site werden „wie besehen“ ohne jegliche Zusicherungen, Garantien oder Bedingungen jeglicher Art bereitgestellt und Menuonline lehnt im vollen, nach geltendem Recht zulässigen Umfang alle ausdrücklichen oder stillschweigenden Zusicherungen, Garantien und Bedingungen, kraft Gesetzes oder anderweitig, ab, einschließlich, aber nicht beschränkt auf stillschweigende Garantien und Bedingungen der Marktgängigkeit oder Eignung für einen bestimmten Zweck oder Gebrauch oder Nichtverletzung der Rechte Dritter...",
        "limitLiability": "Die Gesamthaftung von Menuonline im Rahmen dieser Vereinbarung, sei es aus Vertrag oder unerlaubter Handlung (einschließlich Fahrlässigkeit), infolge einer Garantieverletzung, verschuldensunabhängiger Haftung oder aufgrund irgendeiner anderen Haftungstheorie, ist auf direkte Schäden in einer Höhe beschränkt, die die Gesamtsumme der Gebühren, die an Menuonline im Rahmen dieser Allgemeinen Geschäftsbedingungen in den zwölf (12) Monaten unmittelbar vor dem Monat gezahlt wurden, in dem das jüngste haftungsbegründende Ereignis eingetreten ist, nicht übersteigt.",
        "liabilityExclusions": "Menuonline, seine verbundenen Unternehmen oder deren jeweilige Direktoren, leitenden Angestellten, Mitarbeiter, Agenten oder andere Vertreter (zusammen „Vertreter von Menuonline“) oder die Betreiber angeschlossener Netzwerke übernehmen in Verbindung mit dieser Site oder dem Service keine Verantwortung oder Haftung für: (i) indirekte Schäden, Folgeschäden, zufällige Schäden, exemplarische Schäden, Strafschäden oder besondere Schäden; oder (ii) Schäden jeglicher Art, gleich ob direkte, indirekte, Folgeschäden, zufällige Schäden, exemplarische Schäden, Strafschäden oder besondere Schäden, die als entgangene Einnahmen, entgangene Einsparungen oder Einnahmen oder entgangene Gewinne gekennzeichnet sind...",
        "indemnity": "Sie werden Menuonline und\/oder die Vertreter von Menuonline (die „freigestellten Parteien von Menuonline“) von allen Ansprüchen Dritter sowie von allen gegen uns verhängten Bußgeldern oder Strafen freistellen und schadlos halten, die sich aus Ihrer Verwendung der von dieser Site abgerufenen Informationen oder Ihrer Nutzung des Dienstes und einem Verstoß gegen diese Bedingungen ergeben.",
        "ipInfringement": "Menuonline wird Sie von allen Ansprüchen, Forderungen, Klagen, Prozessen oder Verfahren freistellen und schadlos halten, die von einem Dritten gegen Sie erhoben oder eingeleitet werden und in denen behauptet wird, dass Ihr Zugriff auf den Dienst oder Ihre Nutzung des Dienstes gemäß dieser Vereinbarung die geistigen Eigentumsrechte eines Dritten nach kanadischem Recht verletzt (ein „Anspruch wegen Verletzung geistigen Eigentums“).",
        "equitableRelief": "Wenn Sie gegen diese Bedingungen verstoßen, können wir eine einstweilige Verfügung (das heißt, wir können eine gerichtliche Anordnung beantragen, um Sie davon abzuhalten) oder andere angemessene Rechtsmittel einfordern. Sie erklären sich damit einverstanden, dass dies zusätzlich zu und unbeschadet aller anderen Rechte gilt, die uns gesetzlich oder nach Billigkeitsrecht zustehen.",
        "linksToOtherSites": "Wenn wir Links zu Websites Dritter bereitstellen, dienen diese nur der Benutzerfreundlichkeit. Wenn Sie diese Links verwenden, verlassen Sie unsere Website. Wir kontrollieren diese Websites Dritter nicht und unterstützen sie auch nicht. Sie erklären sich damit einverstanden, dass wir für diese Websites Dritter oder deren Inhalte nicht verantwortlich sind.",
        "thirdPartyRequests": "Wenn wir aufgrund einer rechtlichen oder behördlichen Anfrage Informationen zu Ihrem Konto oder Ihrer Nutzung des Dienstes bereitstellen müssen, erklären Sie sich damit einverstanden, dass wir Ihnen unsere Kosten in Rechnung stellen können. Diese Gebühren können unsere Anwaltskosten und die Kosten für die Zeit unserer Mitarbeiter umfassen, die für die Vorbereitung der Antwort auf die Anfrage aufgewendet wurde.",
        "assignment": "Diese Vereinbarung und alle darin gewährten Rechte und Lizenzen dürfen von Ihnen weder übertragen noch abgetreten werden. Menuonline kann sie jedoch nach angemessener Vorankündigung uneingeschränkt abtreten.",
        "governingLaw": "Diese Bedingungen unterliegen allen anwendbaren Bundesgesetzen und den Gesetzen von Ontario und werden gemäß diesen Gesetzen ausgelegt, ohne Rücksicht auf Kollisionsnormen. Sie erklären sich damit einverstanden, sich zur Beilegung aller Streitigkeiten bezüglich dieser Bedingungen der ausschließlichen Zuständigkeit der Gerichte von Ontario zu unterwerfen.",
        "severability": "Sollte ein zuständiges Gericht aus irgendeinem Grund feststellen, dass eine Bestimmung dieser Bedingungen oder Teile davon gesetzwidrig oder aus irgendeinem Grund nicht durchsetzbar ist, dann wird diese Bestimmung im maximal zulässigen Umfang durchgesetzt, um der Absicht dieser Bedingungen Wirksamkeit zu verleihen; die übrigen Bestimmungen dieser Bedingungen bleiben jedoch in vollem Umfang in Kraft und durchsetzbar.",
        "waiver": "Jeder Verzicht auf die Anforderungen dieser Bedingungen oder jede Zustimmung zur Abweichung von diesen Bedingungen ist nur wirksam, wenn sie schriftlich erfolgt und von uns unterzeichnet ist, und nur in dem konkreten Fall und für den konkreten Zweck, für den sie erteilt wurde. Unser Versäumnis oder unsere Verzögerung bei der Ausübung eines Rechts aus diesen Bedingungen gilt nicht als Verzicht auf unsere Rechte.",
        "furtherAssurances": "Jede Partei erklärt sich damit einverstanden, alle erforderlichen Dokumente zu unterzeichnen und bereitzustellen und alle erforderlichen Maßnahmen zu ergreifen (oder die Durchführung dieser Maßnahmen zu veranlassen), um ihren Verpflichtungen gemäß diesen Geschäftsbedingungen nachzukommen.",
        "notices": "Alle Mitteilungen an Menuonline müssen schriftlich erfolgen und per E-Mail an uns gesendet werden. Mitteilungen an uns müssen an unsere Kundendienstmitarbeiter unter support@menuonline.com gesendet werden. Mitteilungen an Sie können an die Adresse oder E-Mail-Adresse gesendet werden, die Sie bei Ihrer Registrierung angegeben haben. Darüber hinaus können wir Mitteilungen oder Nachrichten über diese Site senden, um Sie über Änderungen an dieser Site oder andere wichtige Angelegenheiten zu informieren. Diese Sendungen gelten zum Zeitpunkt ihrer Veröffentlichung als Mitteilung an Sie.",
        "entireAgreement": "Diese Bedingungen und unsere Datenschutzrichtlinie stellen die gesamte Vereinbarung zwischen Ihnen und uns in Bezug auf Ihre Nutzung der Website und des Dienstes dar und ersetzen alle vorherigen Vereinbarungen, Zusicherungen und Absprachen zwischen Menuonline und Ihnen in Bezug auf diesen Gegenstand. Im Falle eines Konflikts zwischen der Datenschutzrichtlinie und diesen Bedingungen gelten diese Bedingungen.",
        "language": "Die Parteien haben ausdrücklich darum gebeten und verlangt, dass diese Allgemeinen Geschäftsbedingungen, unsere Datenschutzrichtlinie und alle zugehörigen Dokumente in englischer Sprache verfasst werden. Die Parteien möchten und müssen klarstellen, dass dieser Vertrag und alle relevanten Dokumente auf Englisch verfasst werden. Sie stimmen zu, dass die englischsprachige Version dieser Allgemeinen Geschäftsbedingungen und Datenschutzrichtlinie im Falle von Konflikten mit einer übersetzten Version Vorrang hat.",
        "survival": "Alle Bestimmungen dieser Bedingungen, die aufgrund ihrer Natur oder Bedeutung auch nach Ablauf oder Kündigung dieser Bedingungen weiterhin gültig bleiben, bleiben insbesondere gemäß den Abschnitten 17 bis 19 bestehen."
    },
    "collect_additional_info": "Möchten Sie weitere Informationen einholen?",
    "additionalInfoChoice": "Wählen Sie alle zusätzlichen Informationen aus, die Sie sammeln möchten.",
    "additional_info_yes": "Ja",
    "additional_info_noSkip": "Nein, Überspringen",
    "additional_info_label": "Weitere Informationen",
    "confirm_business_location": "Lassen Sie uns zunächst Ihren Unternehmensstandort bestätigen",
    "custom_get_more_reviews_form": "Benutzerdefiniertes Formular „Weitere Bewertungen erhalten“",
    "set_up_custom_form": "Richten Sie Ihr individuelles Formular „Mehr Bewertungen erhalten“ nach Gruppe ein",
    "reputation_name_label": "Mehr Bewertungen erhalten Name",
    "reputation_name_placeholder": "Geben Sie den Reputationsnamen ein",
    "group_label": "Gruppe",
    "group_desc_title": "Titel der Gruppenbeschreibung",
    "group_desc": "Gruppenbeschreibung",
    "select_group_placeholder": "Gruppe auswählen",
    "required_group_name": "Gruppenname ist erforderlich",
    "before_asking_review": "Bevor wir die Kunden um eine Bewertung bitten, stellen wir ihnen 1 bis 4 Fragen.",
    "feel_free_edit": "Sie können es bei Bedarf gerne bearbeiten.",
    "incentive_settings": "Anreizeinstellungen",
    "incentivize_customer": "Möchten Sie Ihren Kunden einen Anreiz für die Abgabe einer Bewertung bieten?",
    "choose_incentive": "Wählen Sie den Anreiz aus, den Sie empfehlen möchten.",
    "scan_qr_feedback": "Scannen Sie den QR-Code und teilen Sie uns Ihr wertvolles Feedback zu Ihren Erfahrungen mit.",
    "qr_code": "QR-Code",
    "scan_me": "SCANNEN SIE MICH!",
    "or_title": "ODER",
    "send_otp_title": "OTP senden",
    "sign_in_title": "Anmelden",
    "email_addresss_title": "E-Mail-Adresse",
    "reset_password_title": "Passwort zurücksetzen",
    "remember_password_title": "Passwort vergessen?",
    "digit_code_title": "Bitte geben Sie den 4-stelligen Code ein, der gesendet wird ",
    "not_received_title": "Ich habe keinen Bestätigungscode erhalten!",
    "resend_title": "Bitte erneut senden",
    "sign_up_form": "Erstellen Sie Ihr Konto",
    "sign_up_sub_title": "Melden Sie sich jetzt an, um auf die neuesten Erkenntnisse zuzugreifen",
    "already_registered_title": "Sie haben bereits ein Menuonline-Konto?",
    "reserved_title": "Urheberrechte ©2023 Menuonline. Alle Rechte vorbehalten.",
    "terms_title": "Servicebedingungen",
    "policy_title": "Datenschutzrichtlinie",
    "sign_up_title": "Melden Sie sich an",
    "terms_conditions_title": "Allgemeine Geschäftsbedingungen",
    "business_type_title": "Unternehmenstätigkeit",
    "business_type_sub_title": "Wählen Sie bitte einen Geschäftstyp aus, der Ihr Unternehmen besser beschreibt!",
    "salon_title": "Salon",
    "restaurant_title": "Restaurant",
    "pizza_store_title": "Pizzeria",
    "food_truck_title": "Imbisswagen",
    "check_domain_title": "Domänennamen prüfen",
    "start_exploring_title": "Entdecken Sie",
    "sign_in_form": "Einloggen auf Ihr Konto",
    "sign_in_sub_title": "Melden Sie sich jetzt an, um auf die neuesten Erkenntnisse zuzugreifen",
    "remember_title": "Erinnere dich an mich",
    "otp_title": "OTP",
    "password_title": "Passwort",
    "forgot_title": "Passwort vergessen?",
    "not_registered_title": "Sie haben noch kein Konto?",
    "create_account_title": "Jetzt anmelden",
    "create_form_title": "Formular erstellen",
    "first_name_title": "Vorname",
    "last_name_title": "Nachname",
    "email_title": "E-Mail",
    "mobile_number_title": "Handynummer",
    "birthday_title": "Geburtstag",
    "gender_title": "Geschlecht",
    "address_title": "Adresse",
    "short_answer_title": "Kurze Antwort",
    "long_answer_title": "Lange Antwort",
    "single_answer_title": "Einzelne Antwort",
    "single_checkbox_title": "Einzelnes Kontrollkästchen",
    "multiple_choice_title": "Multiple Choice",
    "drop_down_title": "Runterfallen",
    "yes_no_title": "Ja oder nein",
    "description_text_title": "Beschreibungstext",
    "file_upload": "Datei-Upload",
    "reset_password_form": "Setzen Sie Ihr Passwort zurück",
    "reset_password_sub_title": "Setzen Sie Ihr Passwort schnell zurück und erhalten Sie Zugriff auf Ihr Konto.",
    "required_full_name_title": "Vollständiger Name ist erforderlich",
    "required_first_name_title": "Vorname ist erforderlich",
    "valid_first_name_title": "Geben Sie einen gültigen Vornamen ein",
    "valid_full_name_title": "Geben Sie einen gültigen vollständigen Namen ein",
    "required_block_time": "Blockzeit ist erforderlich",
    "required_last_name_title": "Nachname ist erforderlich",
    "valid_last_name_title": "Geben Sie einen gültigen Nachnamen ein",
    "valid_mobile_number_title": "Geben Sie eine gültige Mobiltelefonnummer ein",
    "required_mobile_number_title": "Handynummer ist erforderlich",
    "required_already_mobile_number_title": "Mobilnummer ist bereits erforderlich",
    "valid_otp_title": "Geben Sie einen gültigen OTP-Code ein.",
    "valid_email_title": "Geben Sie eine gültige E-Mail-Adresse ein",
    "required_email_title": "E-Mail ist erforderlich",
    "required_description_title": "Beschreibung ist erforderlich",
    "required_description_500_characters": "Bitte geben Sie mindestens 500 Zeichen ein",
    "required_description_600_characters": "Bitte geben Sie maximal 600 Zeichen ein",
    "domain_title": "Domänenname",
    "required_password_title": "Passwort erforderlich ist",
    "required_password_regex_title": "Muss mindestens 8 Zeichen, mindestens einen Großbuchstaben, einen Kleinbuchstaben, eine Zahl und ein Sonderzeichen enthalten",
    "required_terms_title": "Bitte stimmen Sie den Allgemeinen Geschäftsbedingungen zu",
    "required_note_title": "Hinweis ist erforderlich",
    "required_birthday_title": "Geburtstag ist erforderlich",
    "no_access_title": "Sie haben derzeit keinen Zugriff. Bitte wenden Sie sich an Ihren Administrator",
    "menu_online_open_txt": "Offen",
    "loyalty_program_description": "😊 Finden Sie Ihr ideales Treueprogramm und passen Sie es an Ihre Marke an!",
    "lp_list_title": "Liste der Treue-Stempelkarten",
    "lp_n_title": "Name der Treue-Stempelkarte",
    "enter_details_title": "Details eingeben",
    "stamp_allowed_title": "Stempel erlaubt",
    "stamp_items_title": "Stempelartikel",
    "lpn_req_title": "Name der Treuekarte ist erforderlich",
    "valid_lpn_title": "Geben Sie den gültigen Namen Ihrer Treue-Stempelkarte ein",
    "brand_color_error": "Die Markenfarbe muss genau 7 Zeichen lang sein",
    "select_dark_brand_color_title": "Bitte wählen Sie eine gültige Markenfarbe",
    "your_brand_color_title": "Ihre Markenfarbe",
    "upload_logo_title": "Logo hochladen",
    "locations_title": "Standorte",
    "add_lp_title": "Programm erstellen",
    "location_error": "Bitte wählen Sie Standorte",
    "pvs_title": "Wie viele Stempel kann ein Kunde pro Besuch sammeln?",
    "max_apd_title": "Wie viele Stempel kann ein Kunde maximal pro Tag sammeln?",
    "logo_error": "Logo ist erforderlich",
    "expiry_title": "Ablaufdatum für Prämien festlegen",
    "add_more_rewards_title": "+ Weitere Belohnungen hinzufügen",
    "prev_step_1_title": "Zeigen Sie den QR-Code vor, um Punkte zu sammeln und sich über Ihre Prämien zu freuen.",
    "delete_lp_title": "Diese Treuestempelkarte wird dauerhaft gelöscht.",
    "delete_user_record_title": "Dieser Benutzerdatensatz wird dauerhaft gelöscht.",
    "redeem_title": "Tilgen",
    "lp_button_title": "Treten Sie der Treue-Stempelkarte bei",
    "lp_download_button_title": "Karte herunterladen",
    "step_0_title": "Machen Sie jetzt mit, auf treue Feinschmecker wie Sie warten köstliche Belohnungen.",
    "step_1_title": "Du bist beigetreten",
    "congrats_step_title": "Jetzt haben Sie Anspruch auf eine kostenlose Pizza",
    "reward_title": "Erhalten Sie bei jedem Besuch Prämien",
    "visit_title": "Besuchen und freischalten",
    "rewards_title": "Belohnungen",
    "dummy_address": "3730 Frankfort Ave, Louisville, Kentucky, Vereinigte Staaten",
    "loyalty_qr_default_text": "Scannen Sie den QR-Code, melden Sie sich für unsere Treuekarte an und genießen Sie Prämien",
    "lp_earn_stamp_txt": "Kunden erhalten bei jedem Besuch Stempel.",
    "lp_get_rewards_txt": "Erhalten Sie bei jedem Besuch Prämien",
    "lp_1_time_visit_txt": "1 Mal Besuch Sie erhalten",
    "lp_uppercase_stamp_text": "Stempel",
    "lp_lowercase_stamp_text": "Stempel",
    "lp_txt": "Loyalität",
    "lp_per_visit_stamp_validation_msg": "Der Stempel pro Besuch muss kleiner sein als der maximal zulässige Stempel pro Tag",
    "lp_per_max_stamp_validation_msg": "Der maximal zulässige Stempel pro Tag muss größer sein als der Stempel pro Besuch",
    "lp_valid_value_msg": "Geben Sie einen gültigen Wert ein",
    "lp_required_field_msg": "Dieses Feld ist erforderlich",
    "lp_stamp_required_msg": "Stempel ist erforderlich",
    "lp_valid_stamp_msg": "Geben Sie einen gültigen Stempelwert ein",
    "lp_reward_name_required_msg": "Der Name der Belohnung ist erforderlich",
    "lp_unique_stamp_msg": "Der Stempelwert muss eindeutig sein",
    "lp_benefits": "Ein Treueprogramm steigert die Zahl wiederholter Besuche und den Umsatz und bietet zugleich Einblicke in die Kundenbedürfnisse für personalisiertes Marketing",
    "lp_how_customer_txt": "So können Ihre Kunden",
    "lp_punch_card_txt": "Treue-Stempelkarte",
    "lp_look_txt": "werde schauen! 😍",
    "lp_desc": "Kunden erhalten für jeden Besuch Stempel und können, wenn sie genug gesammelt haben, besondere Prämien einlösen.",
    "lp_edit": "Bearbeiten",
    "lp_looks_good_txt": "Sieht gut aus",
    "lp_customer_view_instruction": "Das sieht Ihr Kunde. Laden Sie Ihr Logo hoch und legen Sie das Farbschema fest",
    "lp_customer_earn_stamps": "Ermöglichen Sie Ihren Kunden, bei jedem Besuch Stempel zu sammeln und ihre Prämien zu verwalten",
    "lp_stamp_rewards_setup": "Legen Sie Prämien für das Sammeln von Stempeln fest und ermöglichen Sie Kunden, Prämien pro Stempel zu erhalten.",
    "lp_loyalty_program_setup": "Geben Sie den Namen Ihres Treueprogramms ein und legen Sie Ihren Standort fest, an dem dieses Programm durchgeführt werden soll.",
    "lp_prev_step1": "Zeigen Sie den QR-Code vor, um Punkte zu sammeln und sich über Ihre Prämien zu freuen.",
    "lp_visit_1_time_txt": "Besuchen Sie 1 Mal und erhalten Sie",
    "lp_get_free_txt": "Kostenlos herunterladen",
    "lp_visit_allow_txt": "Besuchen und freischalten",
    "lp_rewards": "Belohnungen",
    "repsT": "Mehr Bewertungen erhalten",
    "offSup": "Bürobedarf",
    "repsNxtV": "Bei Ihrem nächsten Besuch",
    "addRepT": "Mehr Bewertungen erhalten hinzufügen",
    "repEntrCpnTxt": "Gutscheintext eingeben",
    "repEntrCpnTxtBtn": "Geben Sie den Text für die Gutscheinschaltfläche ein",
    "repMngNxtV": "bei Ihrem nächsten Besuch?",
    "repMngWldL": "Möchten Sie",
    "discount": "Rabatt",
    "repDisTyp": "Rabattart",
    "repNameT": "Geben Sie Ihrem Ruf einen Namen",
    "crtRepT": "Reputation aufbauen",
    "feedBackT": "Rückmeldung",
    "confLocT": "Standort bestätigen",
    "businLocT": "Betriebsstandort",
    "addInfoT": "Weitere Informationen",
    "repNReqT": "„Get More Reviews“ (Weitere Bewertungen erhalten) – Name ist erforderlich",
    "validRepNT": "Geben Sie einen gültigen Reputationsnamen ein",
    "groupT": "Gruppe",
    "groupLDescTitleT": "Kontakte automatisch in eine Gruppe verschieben",
    "groupLDescT": "Alle Kunden, die das Formular ausfüllen, werden der ausgewählten Gruppe hinzugefügt.",
    "que2": "War Ihr Essen gut?",
    "que3": "War unser Service freundlich?",
    "que4": "War unser Service schnell?",
    "addAdditionalT": "Möchten Sie weitere Informationen hinzufügen?",
    "phNoMandT": "Telefonnummer ist ein Pflichtfeld!",
    "qrGenT": "QR generiert",
    "repFDT": "Ihre Eingabe hilft unserem Team, Ihnen besser zu dienen!",
    "letdiT": "Lass es uns tun",
    "tak15secT": "Dauert 15 Sekunden",
    "defQue": "Möchten Sie von Zeit zu Zeit tolle Angebote und Aktionen erhalten?",
    "defNQue": "Möchten Sie, dass Ihnen jemand antwortet?",
    "rateOnGoogleDesc": "Wären Sie so freundlich, uns bei Google zu bewerten?",
    "mayBeNT": "Vielleicht beim nächsten Mal",
    "yesSureT": "Ja sicher",
    "valid_name": "Geben Sie einen gültigen Namen ein",
    "nameReq": "Name ist erforderlich",
    "backToMainT": "Zurück zur Hauptseite",
    "attachCopT": "Möchten Sie Ihren Kunden Anreize bieten?",
    "thankYouT": "Danke",
    "lftyT": "Wir freuen uns auf Ihren nächsten Besuch!",
    "repPreT": "Reputationsvorschau",
    "offer_exclusive_discounts": "Bieten Sie exklusive Rabatte und Sonderangebote an, um Ihre Kunden anzulocken.",
    "couponT": "Coupon",
    "locStepDesc": "Bestätigen Sie Ihren Unternehmensstandort",
    "createRepStepDesc": "Erstellen Sie ein benutzerdefiniertes Reputationsformular nach Gruppen",
    "feedbackStepDesc": "Fragensatz für Kundenfeedback",
    "addInfoStepDesc": "Kundenfelder und Anreizeinstellungen",
    "FedbkkWDT": "Feedback mit Daten",
    "repAnlytT": "Reputationsanalyse",
    "todayT": "Heute",
    "rep_last_7_days_t": "Letzte 7 Tage",
    "last30DaysT": "Letzte 30 Tage",
    "lastMT": "Letzter Monat",
    "custRangeT": "Benutzerdefiniertes Sortiment",
    "queWiseAnltT": "Fragenweise Analysen",
    "atL1QueErrT": "Bitte wählen Sie mindestens eine Frage aus",
    "deleteRepTitle": "Dieser Ruf wird dauerhaft gelöscht.",
    "incvCustT": "Ja, meinen Kunden belohnen",
    "collAddInfoT": "Ja, ich möchte zusätzliche Informationen sammeln",
    "totRewT": "Gesamtbewertung",
    "totPosRewT": "Insgesamt positive Bewertung",
    "negFeedT": "Negatives Feedback",
    "posFeedT": "Positives Feedback",
    "fineT": "Kleingedrucktes",
    "enterFineT": "Geben Sie den Kleingedrucktentext ein",
    "setThemeT": "Designfarbe festlegen",
    "que1T": "Frage 1",
    "que2T": "Frage 2",
    "que3T": "Frage 3",
    "que4T": "Frage 4",
    "entMailT": "Geben Sie Ihre E-Mail ein",
    "reputationQRDefTxt": "Scannen Sie den QR-Code und teilen Sie uns Ihr wertvolles Feedback zu Ihren Erfahrungen mit.",
    "selUser": "Benutzer auswählen",
    "userT": "Benutzer",
    "subUReq": "Bitte wählen Sie Benutzer aus",
    "updateLoc": "Standort aktualisieren",
    "leadGenT": "Lead-Generierung",
    "displayT": "Anzeige",
    "ratingT": "Bewertungen",
    "rep_dashboard_negative_feedback": "Negatives Feedback 0%",
    "rep_dashboard_position_feedback": "Positives Feedback 0%",
    "rep_dashboard_total_Feedback_txt": "Gesamtfeedback",
    "rep_dashboard_anony_Feedback": "Anonymes Feedback",
    "rep_dashboard_Feeedback_Data_txt": "Feedback mit Daten",
    "rep_dashboard_review_txt": "Mehr Bewertungen erhalten",
    "rep_dashboard_total_Review_txt": "Gesamtbewertung",
    "rep_dashboard_total_positive_Review_txt": "Insgesamt positive Bewertung",
    "rep_dashboard_negative_feedback_txt": "Negatives Feedback",
    "rep_dashboard_connection_txt": "Verbindung",
    "rep_dashboard_question_wise_analytics_txt": "Fragenweise Analysen",
    "rep_dashboard_date_label": "Datum auswählen",
    "rep_dashboard_custom_range_txt": "Benutzerdefiniertes Sortiment",
    "rep_tlt_min": "REP",
    "emojis": "Emojis",
    "media_text": "Medien hinzufügen",
    "custom_field_text": "Benutzerdefinierte Felder einfügen",
    "shortlink_text": "Kurzlink hinzufügen",
    "use_template_text": "Vorlage verwenden",
    "banner_must_have": "Muss haben",
    "res_seamless": "Nahtlos",
    "res_table_reservation": "Tischreservierung",
    "res_system": "System",
    "res_text": "Reservierungen",
    "md_easily": "Leicht",
    "md_manage_customers": "Kunden verwalten",
    "md_visits_interactions": "Besuche & Interaktionen",
    "md_desc": "Erleichtern Sie Ihren Kunden das Essen mit Tischreservierungen in Echtzeit. Verwalten Sie Reservierungen mühelos, verkürzen Sie Wartezeiten und sorgen Sie für ein reibungsloses Erlebnis für Personal und Gäste. Sorgen Sie dafür, dass Ihr Restaurant ausgebucht ist, und sorgen Sie gleichzeitig für erstklassigen Service und Zufriedenheit.",
    "engage_convert": "SMS einbinden und konvertieren",
    "coupon_campaign": "und Coupon-Aktion",
    "sms_read_rate": "SMS-Nachrichten werden zu 98 % gelesen",
    "real_time_promo": "Liefern Sie Werbeaktionen in Echtzeit für sofortiges Handeln",
    "affordable_reach": "Kostengünstige Möglichkeit, Kunden mit einem soliden ROI zu erreichen",
    "stay_in_touch": "Bleiben Sie jederzeit mit Ihrem Kunden in Kontakt",
    "bc_engage_customers": "Kunden einbinden SMS konvertieren",
    "bc_coupon_campaign": "und Coupon-Aktion",
    "bc_sms_read_rate": "SMS-Nachrichten werden zu 98 % gelesen",
    "bc_real_time_promo": "Liefern Sie Werbeaktionen in Echtzeit für sofortiges Handeln",
    "bc_affordable_reach": "Kostengünstige Möglichkeit, Kunden mit einem soliden ROI zu erreichen",
    "bc_stay_in_touch": "Bleiben Sie jederzeit mit Ihrem Kunden in Kontakt",
    "ar_automate_engagement": "Automatisieren Sie Ihre Kundenbindung",
    "ar_with": "mit",
    "ar_with_auto_responders": "Auto-Responder",
    "ar_personalized_messages": "Senden Sie automatisch personalisierte Nachrichten zu Geburtstagen, begrüßen Sie neue Kunden und nehmen Sie wieder Kontakt mit ehemaligen Kunden auf. Sparen Sie Zeit und sorgen Sie dafür, dass jede Interaktion sinnvoll und zeitnah ist.",
    "sq_custom_forms_tools": "Benutzerdefinierte Formulare und Tools – Vereinfachen",
    "sq_custom_forms_ci": "Kundeninteraktionen",
    "sq_smart_qr_desc": "Erstellen Sie benutzerdefinierte Formulare, sammeln Sie elektronische Signaturen und nutzen Sie intelligente QR-Codes, um Ihre Geschäftsprozesse zu optimieren. Passen Sie jedes Tool an die Bedürfnisse Ihrer Kunden an und verbessern Sie das Benutzererlebnis.",
    "gnc_get_new_cus": "Neue Kunden gewinnen",
    "gnc_get_customers": "aus sozialen Medien – schnell und einfach!",
    "gnc_social_media_power": "Nutzen Sie die Macht sozialer Plattformen, um neue Kunden zu gewinnen und den Umsatz Ihres Unternehmens direkt anzukurbeln. Leiten Sie Lieferaufträge von Drittanbietern direkt an Ihr Geschäft oder Ihre Online-Bestellplattform weiter, um maximale Kontrolle und Umsatz zu erzielen.",
    "wifi_connect_guests": "Verbinden Sie Gäste mit",
    "wifi_smart": "Intelligentes WLAN",
    "wifi_connect_grow": "& Erweitern Sie Ihre Erkenntnisse",
    "wifi_smart_wifi_desc": "Bieten Sie Ihren Kunden eine problemlose WLAN-Verbindung über einen Begrüßungsbildschirm mit Ihrem Markennamen und sammeln Sie gleichzeitig wertvolle Kundendaten, um Ihre Marketingbemühungen und Ihr Engagement zu verbessern.",
    "wifi_create_splash_page": "Gast-WLAN-Splashpage erstellen",
    "instant_menu_create": "Erstellen Sie Ihre",
    "instant_menu_text": "Instant-Menü",
    "instant_menu_wm": "innerhalb von Minuten",
    "instant_menu_desc": "Verbessert das Kundenerlebnis durch Echtzeit-Updates, einfache Anpassung und verbessertes Engagement. So können Sie Ihre Angebote einfacher präsentieren und Ihre Inhalte aktuell halten.",
    "instant_menu_create_digital_menu": "Erstellen Sie ein digitales Menü",
    "loyalty_program_rewards": "Verdiene Belohnungen",
    "loyalty_program_on_visits": "bei jedem Besuch",
    "loyalty_program_create": "Treueprogramm erstellen",
    "reputation_create": "Reputation aufbauen",
    "reputation_boost": "Steigern Sie Ihre",
    "reputation_text": "Ruf",
    "reputation_reviews": "mit mehr Bewertungen!",
    "reputation_desc": "Sammeln Sie wertvolles Feedback von Ihren Kunden, um Ihren Service und Ihren Ruf zu verbessern. Teilen Sie Ihre Erfahrungen und helfen Sie uns, zu wachsen – Ihre Bewertung ist wichtig!",
    "dashboard": "Armaturenbrett",
    "blast_campaign": "Blast-Kampagne",
    "blast_campaigns": "Blast-Kampagnen",
    "blast_campaign_sub_title": "Starten Sie Massenkampagnen, um alle Ihre Kunden mit wenigen Klicks zu erreichen",
    "auto_responders": "Automatische Antworten",
    "auto_responder": "Auto-Responder",
    "auto_responder_sub_title": "Einfach zu verwendende ereignisbasierte Werbeaktionen für Ihre Kunden",
    "smart_qr_form": "Smart QR \/ Formular",
    "get_new_customer": "Neukunden gewinnen",
    "marketing": "Marketing",
    "clients": "Kunden",
    "group_data": "Gruppendaten",
    "clients_data": "Kundendaten",
    "account": "Konto",
    "user_acc_info": "Benutzerkontoinformationen",
    "current_plan": "Aktueller Plan",
    "payments": "Zahlungen",
    "location": "Standort",
    "location_list": "Standortliste",
    "users": "Benutzer",
    "users_sub_title": "Liste aller Benutzer und ihrer Details",
    "online_profile": "Online-Profil",
    "change_password": "Kennwort ändern",
    "menu": "Speisekarte",
    "reseller_dashboard": "Reseller-Dashboard",
    "companies": "Unternehmen",
    "plans_bought": "Gekaufte Pläne",
    "agreement_t_c": "Vereinbarung AGB",
    "wifi_dashboard": "WLAN-Dashboard",
    "hot_spot": "Hotspot",
    "splash_pages": "Splash-Seiten",
    "get_more_reviews": "Mehr Bewertungen erhalten",
    "office_supplies": "Bürobedarf",
    "reservation": "Reservierung",
    "floor_table": "Boden & Tisch",
    "guest_smart_wifi": "Smart-WLAN für Gäste",
    "digital_signage": "Digitale Beschilderung",
    "schedule_down": "Zeitplan",
    "business_settings": "Unternehmenseinstellungen",
    "sub_user_login": "Unterbenutzer-Login",
    "help_center": "Hilfezentrum",
    "switch_to_hub": "Zum Hub wechseln",
    "meeting_records": "Besprechungsprotokolle",
    "logout": "Ausloggen",
    "no_access_text": "Sie haben momentan keinen Zugriff. Bitte wenden Sie sich an Ihren Administrator",
    "menu_item": "MENÜPUNKT",
    "menu_capital": "SPEISEKARTE",
    "good_day": "Guten Tag",
    "no_permission_text": "Sie sind nicht berechtigt, diese Aktion auszuführen!!",
    "enter_valid_pin": "Geben Sie eine gültige PIN ein",
    "add_reservation": "Reservierung hinzufügen",
    "edit_reservation": "Reservierung bearbeiten",
    "per_req": "Anzahl der Personen ist erforderlich",
    "req_block_time": "Blockzeit ist erforderlich",
    "pending_status": "Ausstehend",
    "booked_status": "Gebucht",
    "completed_status": "Vollendet",
    "cancelled_status": "Abgesagt",
    "reservation_slot": "Reservierungsslot",
    "block_time": "Zeit blockieren",
    "slot_time": "Reservierungsfensterzeit:  ",
    "template_name_req": "Der Vorlagenname ist erforderlich",
    "template_type_req": "Vorlagentyp ist erforderlich",
    "sms_template_req": "SMS-Vorlage ist erforderlich",
    "group_req": "Gruppe ist erforderlich",
    "valid_date_time": "Geben Sie ein gültiges Datum und eine gültige Uhrzeit ein",
    "valid_group_name": "Geben Sie einen gültigen Gruppennamen ein",
    "reservation_mark_completed": "Diese Reservierung wird als abgeschlossen markiert.",
    "customer_name": "Kundenname",
    "date_time": "Datum Uhrzeit",
    "table_no": "Tabelle Nr",
    "floor": "Boden",
    "customer_name_placeholder": "Kundenname",
    "date_time_placeholder": "Datum_Uhrzeit",
    "table_no_placeholder": "Tabellennr.",
    "floor_placeholder": "Boden",
    "select_floor": "Etage auswählen",
    "select_table": "Tabelle auswählen",
    "customer_name_macro": "%% Kundenname %%",
    "date_time_macro": "%% Datum_Uhrzeit %%",
    "table_no_macro": "%% Tabellennr. %%",
    "floor_macro": "%% Boden %%",
    "template_name": "Vorlagenname",
    "login_with_4_digit_pin": "Melden Sie sich mit Ihrer 4-stelligen PIN an",
    "login_now_to_access_latest_insights": "Melden Sie sich jetzt an, um auf die neuesten Informationen zuzugreifen",
    "mkt_BS_Title": "SMS-Bombe",
    "step_1": "Nachricht anpassen",
    "step_2": "Zielgruppe auswählen",
    "step_3": "Nachricht planen",
    "step_4": "Eine Nachricht senden",
    "step_5": "Nachricht verwalten",
    "bs_tab_title": "Zusammenfassung der Blast-Kampagne",
    "default_message": "Hey %% Vorname %%, Sonderrabatt auf Brunch! Essen Sie heute bei uns und erhalten Sie exklusiv 10 % Rabatt auf Ihre Rechnung!",
    "bs_Title": "Wählen Sie einen Kampagnentyp",
    "sms_title": "SMS\/MMS",
    "reach_out_to_customer": "Erreichen Sie Kunden über Text- und Multimedianachrichten.",
    "wp_text": "WhatsApp",
    "connect_with_wp": "Verbinden Sie sich mit Ihren Kunden über WhatsApp 💬",
    "send_discount_offers": "Senden Sie Rabattangebote per SMS 📲",
    "send_coupons_via_sms": "Senden Sie Gutscheine per SMS an Ihre Kunden 🎟️",
    "env_Cap": "Bieten Sie exklusive Rabatte und Sonderangebote an, um Ihre Kunden anzulocken",
    "cmt_Caption": "Erreichen Sie Kunden über Text- und Multimedianachrichten",
    "wp_caption": "Verbinden Sie sich mit Ihren Kunden über WhatsApp für eine nahtlose und direkte Kommunikation",
    "msg_Prev_Title": "Nachrichtenvorschau",
    "cou_Prev_Title": "Gutscheinvorschau",
    "blast_SMS": "SMS\/MMS-Kampagne",
    "bs_P_Msg": "In der Warteschlange",
    "bs_S_Msg_C": "Fehlgeschlagen",
    "bs_F_Msg_Count": "Geliefert",
    "previous": "Vorherige",
    "next_ST": "Nächste",
    "next_S": "Nächster Schritt",
    "req_Temp_Name_Title": "Der Vorlagenname ist erforderlich",
    "req_Template_Tx_Title": "Vorlagentext ist erforderlich",
    "add_Temp_Title": "Vorlage hinzufügen",
    "link_Desc": "Fügen Sie einen Link Ihrer Wahl hinzu, damit Kunden über Facebook, Google oder Ihre eigene Website bei Ihnen buchen können.",
    "audience_Title": "Publikum",
    "client_G1_Title": "Neue Kunden",
    "client_G2_Title": "Aktuelle Kunden",
    "client_G3_Title": "Treue Kunden",
    "client_G4_Title": "Abgelaufene Kunden",
    "client_G5_Title": "Kunden nach Verbindungsdatum",
    "on_Which_Day": "An welchem Tag",
    "on_Day": "Am Tag",
    "immediate_Desc": "Ihre Nachricht wird sofort gesendet. Klicken Sie auf „Weiter“, um zu beginnen.",
    "never_Desc": "Die Ausführung dieser Kampagne ist zeitlich unbegrenzt, bis Sie sie manuell beenden.",
    "end_Desc": "Die Kampagne endet an dem Datum, das Sie hier auswählen.",
    "success_Message": "Kampagne erfolgreich gespeichert!",
    "send_Success_Message": "Kampagne erfolgreich gesendet!",
    "msg_Name_Field_Title": "Kampagnentitel",
    "valid_campaign_title": "Geben Sie einen gültigen Kampagnentitel ein",
    "msg_Type_Title": "Nachrichtentyp",
    "sms_Desc": "1 Guthaben pro SMS",
    "mms_Desc": "3 Credits pro MMS",
    "client_GTO_1": "Tag",
    "client_GTO_2": "Std",
    "minutes": "Minuten",
    "client_GTO_4": "Woche",
    "time_Option_1": "Tag",
    "time_Option_2": "Std",
    "c_Grp_Day_1": "90 Tage",
    "c_Grp_Day_2": "60 Tage",
    "c_Grp_Day_3": "30 Tage",
    "first": "Erste",
    "second_T": "Zweite",
    "third_T": "Dritte",
    "fourth_T": "Vierte",
    "fifth_T": "Fünfte",
    "delete_BS_T": "Diese Kampagne wird dauerhaft gelöscht.",
    "delete_MBS_T": "Diese Kampagnen werden dauerhaft gelöscht.",
    "cFT_1": "Telefon",
    "cFT_2": "Kontakt Vorname",
    "cFT_3": "Nachname des Kontakts",
    "cFT_4": "Kontakt-E-Mail",
    "cFT_5": "Notiz",
    "cFT_6": "Geburtstag",
    "cFT_7": "Name der Firma",
    "cF_Meta_Tag_1": "%% Telefon %%",
    "cF_Meta_Tag_2": "%% Vorname %%",
    "cF_Meta_Tag_3": "%% Nachname %%",
    "cF_Meta_Tag_4": "%% E-Mail %%",
    "cF_Meta_Tag_5": "%% Notiz %%",
    "cF_Meta_Tag_6": "%% Geburtstag %%",
    "cF_Meta_Tag_7": "%% Name der Firma %%",
    "cF_Name_1": "Telefon",
    "cF_Name_2": "Vorname",
    "cF_Name_3": "Nachname",
    "cF_Name_4": "E-Mail",
    "cF_Name_5": "Notiz",
    "cF_Name_6": "Geburtstag",
    "cF_Name_7": "Name der Firma",
    "all_clients": "AlleKunden",
    "aud_Type_2": "Kundengruppen",
    "aud_Type_3": "Kundengruppen",
    "aud_Type_T1": "Alle Kunden",
    "aud_Type_T2": "Kundengruppen",
    "aud_Type_T3": "Kundengruppen",
    "new_clients": "Neue Kunden",
    "client_G2": "Aktuelle Kunden",
    "client_G3": "Treue Kunden",
    "client_G4": "Abgelaufene Kunden",
    "client_G5": "Kunden nach Verbindungsdatum",
    "never_text": "Niemals",
    "ending_on": "Endet am",
    "send_T2": "Geplant",
    "send_Type_3": "Wiederkehrend",
    "daily": "Täglich",
    "weekly_T": "Wöchentlich",
    "monthly_T": "Monatlich",
    "yearly_T": "Jährlich",
    "each": "Jede",
    "on_the": "Auf der",
    "monday": "Montag",
    "exclusive_offer": "Exklusives Angebot für Sie",
    "redeem_now": "Jetzt einlösen",
    "redeem_with_cashier": "Bitte beim Kassierer einlösen",
    "lg_Day_2": "Dienstag",
    "lg_Day_3": "Mittwoch",
    "lg_Day_4": "Donnerstag",
    "lg_Day_5": "Freitag",
    "lg_Day_6": "Samstag",
    "lg_Day_7": "Sonntag",
    "msg_Pre_T": "Dominos",
    "cA_Edit_T1": "Alle",
    "cA_Edit_T2": "Inklusive",
    "cA_Edit_T3": "Ausgeschlossen",
    "SMS": "Direct Mail",
    "MMS": "MMS",
    "usd_T": "USD",
    "cad_T": "CAD",
    "msg": "Nachricht",
    "which_Day": "Welche Tage",
    "end_Date_Tx": "Enddatum",
    "sDate_Err": "Das Startdatum muss größer oder gleich dem heutigen Tag sein.",
    "eDate_Err": "Das Enddatum muss nach dem Startdatum liegen",
    "start_Date_Req": "Startdatum ist erforderlich",
    "end_Date_Req": "Enddatum ist erforderlich",
    "time_req": "Zeit ist erforderlich",
    "client_GT1": "Kunden haben sich in den letzten",
    "client_GT2": "Clients mit Verbindung in den letzten",
    "client_GT3": "Kunden mit mindestens",
    "client_GT4": "mit dem letzten",
    "client_GT5": "die im letzten Jahr nicht zurückgekehrt sind",
    "dummy_Phone_No": "+91987-654-3210",
    "test_T": "Prüfen",
    "dummy_Birth_Date": "01.01.2001",
    "image_Req": "Medium oder URL ist erforderlich",
    "time_Title": "Zeit",
    "start_date": "Startdatum",
    "end_date": "Enddatum",
    "auto_Send_T": "Automatisch an Kunden senden",
    "add_Media": "Medien hinzufügen",
    "in_Title": "In",
    "c_Temp_T": "Kampagnenvorlage",
    "temp_NT": "Vorlagenname",
    "type_T": "Typ",
    "select_template_type": "Vorlagentyp auswählen",
    "sel_Temp_T": "Vorlagentyp auswählen",
    "sms_temp_t": "SMS-Vorlage",
    "temp_T": "Vorlagentext",
    "default_Msg": "Hey %% Vorname %%, Sonderrabatt auf Brunch! Essen Sie heute bei uns und erhalten Sie exklusiv 10 % Rabatt auf Ihre Rechnung!",
    "refill_T": "Nachfüllung",
    "avl_Credit": "Verfügbare Credits",
    "req_Credit": "Insgesamt erforderliche Credits",
    "rem_Credit": "Restguthaben",
    "refill_Credit": "Aufzuladende Credits",
    "clients_Req": "Wählen Sie mindestens einen Kunden aus",
    "subclient_Req": "Ausgewählte Kunden sind keine Abonnenten.",
    "refill_Desc": "Bitte füllen Sie die erforderlichen Credits auf",
    "url_Regex": "^((http|https):\/\/).....+$",
    "sd_Invalid": "Das Startdatum ist ungültig",
    "ed_Invalid": "Das Enddatum ist ungültig",
    "img_Url_Invalid": "Ungültige Bild-URL",
    "time_Invalid": "Die Uhrzeit ist ungültig",
    "time_And_Date_Invalid": "Geben Sie ein gültiges Datum und eine gültige Uhrzeit ein",
    "time_Invalid_Bfr": "Wählen Sie eine Zeit, die mindestens 5 Minuten nach der aktuellen Zeit liegt",
    "sod_Req": "Senden am Tag ist erforderlich",
    "add_Card": "Neue Karte hinzufügen",
    "sm_D_Rep_Title": "SMS\/MMS-Zustellungsbericht",
    "send_Now_T": "Jetzt senden",
    "schd_Now_T": "SMS planen",
    "test_SMS_T": "Test-SMS versenden",
    "save_AD_T": "Als Entwurf speichern",
    "back_TE_T": "Zurück zur Bearbeitung",
    "reset_tex": "Zurücksetzen",
    "update_tex": "Aktualisieren",
    "dum_msg1": "Um den Kundennamen anzuzeigen, verwenden Sie in der Vorlage %% customer_name %%",
    "dum_msg2": "Um die Reservierungszeit anzuzeigen, verwenden Sie in der Vorlage %% date_time %%",
    "dum_msg3": "Um die Tabellennummer anzuzeigen, verwenden Sie in der Vorlage %% table_no %%",
    "embedded_link": "Eingebetteter Link",
    "img_title": "Bild",
    "img_input_text": "Text:",
    "img_join_text": " um unserer Liste beizutreten",
    "img_copy_text": "Kopie",
    "img_not_found": "Generiertes Bild nicht gefunden!!",
    "or_text": "ODER",
    "web_signup_image_content": "Indem Sie dieses Formular absenden und sich per SMS anmelden, stimmen Sie dem Erhalt von Marketing-SMS-Nachrichten (wie Aktionscodes und Erinnerungen) zu. Es können Nachrichten- und Datengebühren anfallen. Die Häufigkeit der Nachrichten variiert. Sie können sich jederzeit abmelden, indem Sie mit STOP antworten.",
    "img_download_success": "Bild erfolgreich heruntergeladen",
    "embedded_link_copied": "Eingebetteter Link erfolgreich kopiert",
    "media_url_required": "Medium oder URL sind erforderlich",
    "invalid_image_url": "Ungültige Bild-URL",
    "invalid_file": "Die Datei ist ungültig",
    "image_error_1mb": "Bitte fügen Sie ein Bild ein, das kleiner als 1 MB ist",
    "image_error_2_5mb": "Bitte fügen Sie ein Bild ein, das kleiner als 2,5 MB ist",
    "image_error_3mb": "Bitte fügen Sie ein Bild ein, das kleiner als 3 MB ist",
    "change_title": "Ändern",
    "drop_file_text": "Legen Sie die Datei hier ab, oder klicken Sie zum Hochladen",
    "apply_text": "Anwenden",
    "search_here": "Suche hier",
    "update_status": "Status aktualisieren",
    "reservation_deleted_permanently": "Diese Reservierung wird endgültig gelöscht.",
    "table_text": "Tabellen",
    "add_table_button": "Neue Tabelle hinzufügen",
    "add_table_text": "Tabelle hinzufügen",
    "edit_table_text": "Tabelle bearbeiten",
    "table_delete_text": "Diese Tabelle wird dauerhaft gelöscht.",
    "multiple_table_delete_text": "Diese Tabellen werden dauerhaft gelöscht.",
    "table_error_name": "Bitte geben Sie den Tabellennamen ein",
    "table_error_invalid_name": "Bitte geben Sie einen gültigen Tabellennamen ein",
    "table_error_floor": "Bitte wählen Sie den Boden",
    "table_error_select": "Bitte wählen Sie die Tabelle aus",
    "table_capacity_text": "Tischkapazität",
    "capacity_text": "Kapazität",
    "table_occasion_text": "Finden Sie Ihren Tisch für jeden Anlass",
    "table_name_text": "Tabellenname",
    "table_capacity_error": "Tischkapazität ist erforderlich",
    "cancel_text": "Stornieren",
    "submit_text": "Einreichen",
    "select_valid_date": "Gültiges Datum auswählen",
    "select_valid_time": "Gültige Zeit auswählen",
    "valid_contact_number": "Geben Sie eine gültige Kontaktnummer ein",
    "date_req": "Datum ist erforderlich",
    "date_error_later_than_today": "Das Datum muss nach oder gleich dem heutigen Datum liegen.",
    "time_error_later_than_5_minutes": "Wählen Sie eine Zeit, die mindestens 5 Minuten nach der aktuellen Zeit liegt",
    "number_person_req": "Anzahl der Personen ist erforderlich",
    "contact_no_req": "Kontaktnummer ist erforderlich",
    "contact_no": "Kontaktnummer",
    "select_time": "Wähle eine Uhrzeit",
    "for_how_many_person": "Für wie viele Personen?",
    "f_name": "Vorname",
    "l_name": "Nachname",
    "business_name": "Firmenname",
    "business_no": "Geschäftsnummer",
    "no_for_sign_in": "Diese Nummer wird für die Anmeldung verwendet.",
    "business_email": "Geschäftliche E-Mail",
    "notes_tex": "Hinweise",
    "floor_deleted_permanently": "Diese Etage wird dauerhaft gelöscht.",
    "add_floor": "Boden hinzufügen",
    "edit_floor": "Etage bearbeiten",
    "name_text": "Name",
    "mobile_no": "Mobilnr.",
    "person_text": "Person",
    "date_and_time": "Datum und Uhrzeit",
    "actions_text": "Aktionen",
    "extra_text": "Extra",
    "floor_name_req": "Der Etagenname ist erforderlich",
    "floor_name": "Etagenname",
    "status_text": "Status",
    "category_status_req": "Kategoriestatus ist erforderlich",
    "table_deleted_permanently": "Diese Tabelle wird dauerhaft gelöscht.",
    "tables_deleted_permanently": "Diese Tabellen werden dauerhaft gelöscht.",
    "back_to_home": "Zurück zur Startseite",
    "link_copied_text": "Link in die Zwischenablage kopiert",
    "cust_dash_head_to_title": "Zu",
    "cust_dash_head_location_title": "Standort",
    "select_location_title": "Standort auswählen",
    "all_locations_label": "alle_Standorte",
    "rep_customer_feedback_analytics": "😊 Verfolgen Sie Ihr Kundenfeedback mit Echtzeitanalysen",
    "rep_customer_txt": "Kunden",
    "rep_delete_title": "Dieser Ruf wird dauerhaft gelöscht.",
    "rep_qr_def_txt": "Scannen Sie den QR-Code und teilen Sie uns Ihr wertvolles Feedback zu Ihren Erfahrungen mit.",
    "delete_title": "Löschen",
    "user_list_title": "Liste der Kunden",
    "os_nfc_txt": "NFC-Karten",
    "os_sign_holder_txt": "Schilderhalterständer",
    "os_store_Sign_holder_txt": "Schilderhalter für Geschäfte",
    "StaT": "Statistiken",
    "AllgT": "Alle Gruppen",
    "FavT": "Favorit",
    "MostActT": "Am aktivsten",
    "grT": "Gruppenname",
    "keywT": "Schlagwörter",
    "exSubT": "Nachricht für bestehenden Abonnenten",
    "ArchT": "Archiviert",
    "gNotiSms": "Benachrichtigen Sie mich über jeden neuen Kontakt per SMS",
    "gNotiEmail": "Benachrichtigen Sie mich über jeden neuen Kontakt per E-Mail",
    "reqGName": "Gruppenname ist erforderlich",
    "validGN": "Geben Sie einen gültigen Gruppennamen ein",
    "valid_phone_no": "Geben Sie eine gültige Telefonnummer ein",
    "phone_no_req": "Telefonnummer ist erforderlich",
    "required_message_text": "Nachricht ist erforderlich",
    "required_discount_text": "Rabatt ist erforderlich",
    "less_than_discount": "Der Rabattbetrag muss kleiner als 5000 sein.",
    "discount_percentage_invalid": "Der Rabattprozentsatz muss kleiner oder gleich 100 sein.",
    "discount_type_req": "Rabatttyp ist erforderlich",
    "discount_text_req": "Gutscheintext ist erforderlich",
    "reqContactNo": "Kontaktnummer ist erforderlich",
    "reqMsgNT": "Der Kampagnentitel ist erforderlich",
    "reqLinkT": "Link ist erforderlich",
    "delMGT": "Diese Gruppen werden dauerhaft gelöscht.",
    "defMemMsg": "Geben Sie hier Ihre Nachricht ein",
    "add_cust_to_grp_title": "Client zur Gruppe hinzufügen",
    "group_title": "Gruppen",
    "group_create_title": "Gruppe erstellen",
    "group_name_txt": "Gruppenname",
    "group_table_keyword_title": "Stichwort",
    "actions_title": "Aktionen",
    "clients_title": "Kunden",
    "send_title": "Schicken",
    "qr_title": "QR",
    "delete_group_txt": "Diese Gruppe wird dauerhaft gelöscht.",
    "delete_groups_txt": "Diese Gruppen werden dauerhaft gelöscht.",
    "delete_client_title": "Dieser Client wird dauerhaft gelöscht.",
    "delete_clients_title": "Diese Kunden werden dauerhaft gelöscht.",
    "delete_multiple_title": "Mehrfaches Löschen",
    "wel_sms_mms": "Willkommens-SMS\/MMS senden?",
    "key_words_title": "Schlagwörter",
    "srch_plch_txt": "Suche hier",
    "req_location_id_title": "Standort ist erforderlich",
    "create_text": "Erstellen",
    "view_text": "Sicht",
    "immediately": "Sofort",
    "business_name_req": "Firmenname ist erforderlich",
    "business_no_req": "Unternehmensnummer ist erforderlich",
    "valid_business_name": "Geben Sie einen gültigen Firmennamen ein",
    "valid_business_no": "Geben Sie eine gültige Geschäftsnummer ein",
    "address_req": "Adresse ist erforderlich",
    "valid_address": "Bitte wählen Sie eine gültige Adresse",
    "time_zone_req": "Zeitzone ist erforderlich",
    "image_req": "Bitte fügen Sie ein Bild ein, das kleiner als 2,5 MB ist",
    "valid_file": "Die Datei ist ungültig.",
    "logo": "Logo",
    "time_zone": "Zeitzone",
    "save": "Speichern",
    "account_type_req": "Kontotyp ist erforderlich",
    "gst_no_req": "GST-Nummer ist erforderlich",
    "gst_no_valid": "Geben Sie eine gültige GST-Nummer ein",
    "set_up_payments": "Einrichten von Zahlungen",
    "billing_details": "Geben Sie Ihre Rechnungsdetails ein",
    "billing_details_desc": "Ihre Rechnungsdaten werden auf Ihrer monatlichen Rechnung von menuonline angezeigt.",
    "account_type": "Kontotyp",
    "select_account_type": "Kontotyp auswählen",
    "gst_no": "GST-Nummer",
    "transaction_details": "Details der Transaktion",
    "payment_method": "Zahlungsmethode",
    "billing_period": "Abrechnungszeitraum",
    "paid_by": "Bezahlt von",
    "download": "Herunterladen",
    "pay_now": "Jetzt bezahlen",
    "pull_down_refresh": "Zum Aktualisieren nach unten ziehen",
    "release_refresh": "Zum Aktualisieren loslassen",
    "billing_details_text": "Rechnungsdetails",
    "payment_methods": "Zahlungsarten",
    "invoice": "Rechnung",
    "invoice_to": "Rechnung an:",
    "issue_date": "Ausgabedatum",
    "due_date": "Fälligkeitsdatum",
    "amount_due": "Fälliger Betrag",
    "charges": "Gebühren",
    "sub_total_capitalize": "Zwischensumme",
    "hst": "HST",
    "grand_total": "Gesamtsumme",
    "invoice_generated_on": "Rechnung erstellt am",
    "contact_us": "Kontaktieren Sie uns",
    "invoice_issue": "Wenn Sie Ihre Rechnung durchgesehen haben und noch Fragen haben",
    "call": "Anruf",
    "send_sms": "SMS senden",
    "payment_success": "Zahlung erfolgreich",
    "edit_credit_card": "Kreditkarte bearbeiten",
    "add_credit_card": "Kreditkarte hinzufügen",
    "modify_card_info": "Ändern Sie Ihre Karteninformationen",
    "enter_card_info": "Geben Sie Ihre Karteninformationen ein",
    "account_no_req": "Kontonummer ist erforderlich",
    "card_name_req": "Name auf der Karte ist erforderlich",
    "expiry_date_req": "Ablaufdatum ist erforderlich",
    "valid_expiry_date": "Geben Sie ein gültiges Ablaufdatum ein",
    "valid_cvv": "Geben Sie einen gültigen CVV ein",
    "cvv_req": "CVV ist erforderlich",
    "card_no": "Kartennummer",
    "name_of_card": "Name auf der Karte",
    "expiry": "Ablauf",
    "mm_text": "MM\/JJ",
    "cvv": "CVV",
    "set_as_default": "Als Standard festlegen",
    "add_new_card": "Neue Karte hinzufügen",
    "all_credit_card": "Alle Ihre Kreditkarten",
    "fastest_checkout": "Schnellster Check-out mit sicherer und zuverlässiger Plattform, der eine große Gruppe von Unternehmen vertraut",
    "coupon_button_req": "Der Text für die Gutscheinschaltfläche ist erforderlich.",
    "max19_characters_allowed": "Maximal 19 Zeichen sind erlaubt",
    "fine_print_text_req": "Kleingedruckter Text ist erforderlich",
    "clients_req": "Wählen Sie mindestens einen Kunden aus",
    "client_groups": "Kundengruppen",
    "day_text": "Tag",
    "valid_days": "Der Tageswert ist zu groß. Geben Sie gültige Tage ein.",
    "hours_text": "Std",
    "enter_valid_hours": "Der Stundenwert ist zu groß. Geben Sie gültige Stunden ein.",
    "enter_valid_min": "Der Minutenwert ist zu groß. Geben Sie gültige Minuten ein.",
    "clients_with_connection_required": "Clients mit Verbindung in der letzten ist erforderlich",
    "connection_required": "Verbindung ist erforderlich",
    "connection_value_too_large": "Der Verbindungswert ist zu groß. Geben Sie eine gültige Verbindung ein.",
    "minutes_required": "Benötigte Minuten",
    "clients_with_at_least_connection_required": "Es sind Clients mit mindestens Verbindung erforderlich",
    "last_minutes_req": "Mit den letzten Minuten ist gefragt",
    "clients_not_returned_required": "Wer in den letzten Minuten nicht zurückgekehrt ist, ist erforderlich",
    "not_return_in_last_minutes_invalid": "Nicht in den letzten Minuten zurückkehren, muss kürzer sein als die letzten Minuten.",
    "customerGroups": "Kundengruppen",
    "select_customer_group": "Bitte wählen Sie die Kundengruppe",
    "location_required": "Standort ist erforderlich",
    "start_date_required": "Startdatum ist erforderlich",
    "start_date_invalid": "Das Startdatum ist ungültig",
    "start_date_invalid_error": "Das Startdatum muss größer oder gleich dem heutigen Tag sein.",
    "recurring": "Wiederkehrend",
    "end_date_required": "Enddatum ist erforderlich",
    "end_date_invalid": "Das Enddatum ist ungültig",
    "end_date_invalid_error": "Das Enddatum muss nach dem Startdatum liegen",
    "time_invalid": "Die Uhrzeit ist ungültig",
    "monthly_text": "Monatlich",
    "send_day_req": "Senden am Tag ist erforderlich",
    "loyal_clients": "Treue Kunden",
    "recent_clients": "Aktuelle Kunden",
    "lapsed_clients": "Abgelaufene Kunden",
    "clients_connection_date": "Kunden nach Verbindungsdatum",
    "scheduled_text": "Geplant",
    "weekly": "Wöchentlich",
    "selected_clients_not_subscriber": "Ausgewählte Kunden sind keine Abonnenten.",
    "message_preview_title": "Nachrichtenvorschau",
    "coupon_preview_title": "Gutscheinvorschau",
    "form_text": "Bilden",
    "preview_text": "Vorschau",
    "next_text": "Nächste",
    "send_test_SMS": "Test-SMS versenden",
    "save_draft": "Als Entwurf speichern",
    "back_to_edit": "Zurück zur Bearbeitung",
    "select_payment_method": "Bitte wählen Sie die Zahlungsart",
    "schedule_SMS": "SMS planen",
    "send_now": "Jetzt versenden",
    "get_more_ratings": "Erhalten Sie mehr Bewertungen",
    "overview": "Überblick",
    "reset_campaign": "Kampagne zurücksetzen",
    "permissions": "Berechtigungen",
    "location_name": "Standortname",
    "phone_no": "Telefonnummer",
    "location_email_address": "Standort E-Mail-Adresse",
    "located_business": "Wo befindet sich Ihr Unternehmen?",
    "business_logo": "Firmenlogo",
    "congratulations": "Glückwunsch",
    "almost_done": "Du bist fast fertig",
    "publish": "Veröffentlichen",
    "about_your_business": "Über Ihr Unternehmen",
    "add_your_location": "Fügen Sie Ihren Standort hinzu",
    "publish_location": "Veröffentlichungsort",
    "location_name_req": "Der Standortname ist erforderlich",
    "valid_location_name": "Geben Sie einen gültigen Standortnamen ein",
    "business_logo_req": "Firmenlogo ist erforderlich",
    "please_accept_terms": "Bitte akzeptieren Sie die Allgemeinen Geschäftsbedingungen",
    "add_new_location": "Neuen Standort hinzufügen",
    "edit_location": "Standort bearbeiten",
    "add_location": "Standort hinzufügen",
    "existing_msg_subscriber_txt": "Nachricht für bestehenden Abonnenten",
    "msg_capitalize_txt": "Nachricht",
    "group_noti_sms": "Benachrichtigen Sie mich über jeden neuen Kontakt per SMS",
    "group_noti_email": "Benachrichtigen Sie mich über jeden neuen Kontakt per E-Mail",
    "group_member_msg": "Es scheint, als wären Sie bereits Teil unserer Gruppe!!",
    "group_mend_msg": "STOP zum Beenden. HELP für Hilfe. Es können SMS- und Datengebühren anfallen.",
    "Disclaimer_title": "Haftungsausschlusstext: ",
    "group_def_msg": "Wir sind jetzt online! Schauen Sie sich unser Servicemenü an und buchen Sie noch heute Ihren nächsten Termin.",
    "required_msg_txt": "Nachricht ist erforderlich",
    "required_Key_txt": "Schlüsselwörter sind erforderlich",
    "required_mem_msg": "Abonnentennachricht ist erforderlich",
    "client_list_title": "Kundenliste",
    "add_contact_txt": "Kontakt hinzufügen",
    "delete_all_clients_txt": "Alle Clients löschen",
    "delete_all_clients_msg": "Möchten Sie wirklich alle Clients löschen? Sie können nicht wiederhergestellt werden.",
    "delete_all_txt": "Alle löschen",
    "timeline_title": "Zeitleiste",
    "unsubscribe_title": "Abbestellen",
    "subscribe_title": "Abonnieren",
    "unsubscribe_confirm_msg": "Möchten Sie diesen Kunden als Abbesteller markieren?",
    "subscribe_confirm_msg": "Möchten Sie diesen Kunden als Abonnenten markieren?",
    "no_title": "NEIN",
    "yes_title": "Ja",
    "client_name_title": "Kundenname",
    "source_title": "Quelle",
    "contact_file_Req": "Kontaktdatei ist erforderlich",
    "req_title": "Erforderlich",
    "opt_in_req": "Importierte Kontakte müssen zu 100 % mit Opt-in einverstanden sein.",
    "image_invalid_error": "Die Datei ist ungültig.",
    "import_contacts_msg": "Kontakte importieren",
    "csv_upload_title": "Hochzuladende CSV-Kontaktdatei *",
    "csv_file_desc": "Bitte laden Sie nur die CSV-Datei hoch - CSV-Dateispalten - Vorname, Nachname, E-Mail-ID, Handynummer, Geschlecht, Geburtsdatum (TT.MM.JJJJ) - keine Leerzeichen, Bindestriche oder Klammern in der Handynummer",
    "to_download_title": "Klicken Sie hier, um die Demodatei herunterzuladen.",
    "contains_header_title": "Ja, diese Datei enthält Header",
    "opt_in_100_txt": "Ja, diese importierten Kontakte sind zu 100 % Opt-in",
    "DisT": "Haftungsausschlusstext: ",
    "gMendMsg": "STOP zum Beenden. HELP für Hilfe. Es können SMS- und Datengebühren anfallen.",
    "reqKeyT": "Schlüsselwörter sind erforderlich",
    "reqMemMsg": "Abonnentennachricht ist erforderlich",
    "reqMsgT": "Nachricht ist erforderlich",
    "gMemberMsg": "Es scheint, als wären Sie bereits Teil unserer Gruppe!!",
    "gdefMsg": "Wir sind jetzt online! Schauen Sie sich unser Servicemenü an und buchen Sie noch heute Ihren nächsten Termin.",
    "next_title": "Nächste",
    "male_title": "Männlich",
    "male_val": "männlich",
    "female_title": "Weiblich",
    "female_val": "weiblich",
    "others_txt": "Sonstiges",
    "others_val": "Sonstiges",
    "validBirthdate": "Geben Sie ein gültiges Geburtsdatum ein",
    "valid_phone_no_title": "Geben Sie eine gültige Telefonnummer ein",
    "required_phone_no_title": "Telefonnummer ist erforderlich",
    "add_new_client_txt": "Neuen Kunden hinzufügen",
    "update_client_txt": "Update-Client",
    "phone_num_text": "Telefonnummer",
    "dob_title": "Geburtsdatum",
    "select_gender_title": "Geschlecht auswählen",
    "timelineTitle": "Zeitleiste",
    "confirm_location": "Standort bestätigen",
    "feedback_title": "Rückmeldung",
    "rep_question_1": "War der Betrieb sauber?",
    "rep_que_2": "War Ihr Essen gut?",
    "rep_que_3": "War unser Service freundlich?",
    "rep_que_4": "War unser Service schnell?",
    "business_location_req_title": "Geschäftssitz ist erforderlich",
    "valid_location_err_txt": "Bitte wählen Sie eine gültige Adresse",
    "rep_management_title": "Holen Sie sich mehr Bewertungsmanagement",
    "rep_about_desc": "Reputation hilft Ihnen, die Google-Bewertung zu verbessern, indem Sie Ihre Kunden um Feedback bitten",
    "preview_title": "Vorschau",
    "rep_preview_txt": "Reputationsvorschau",
    "back_title": "Zurück",
    "fine_print_txt": "Bitte beim Kassierer einlösen",
    "redeem_me_title": "Erlöse mich",
    "rep_nxt_visit_txt": "Bei Ihrem nächsten Besuch",
    "device_type": "Verwendeter Gerätetyp",
    "connection_method": "Verbindungsmethode",
    "peak_days": "Spitzentage",
    "peak_hours": "Spitzenzeiten",
    "guest_by_day": "Gast bei Tag",
    "guest_visit": "Gastbesuch",
    "connection": "Verbindung",
    "connection_duration": "Verbindungsdauer",
    "guest_visit_1": "1 Mal",
    "guest_visit_2": "2 mal",
    "guest_visit_3_5": "3-5 mal",
    "guest_visit_6_10": "6-10 mal",
    "guest_visit_11_25": "11-25 mal",
    "guest_visit_26_100": "26-100 mal",
    "guest_visit_100_plus": "100+ Mal",
    "device_android_total": "Total Android",
    "device_android": "Android",
    "device_desktop": "Desktop",
    "device_ios": "Ios",
    "device_ios_total": "Gesamt-Ios",
    "device_desktop_total": "Gesamt-Desktop",
    "connection_duration_10": "<=10 Minuten",
    "connection_duration_20": "11-20 Minuten",
    "connection_duration_30": "21-30 Minuten",
    "connection_duration_40": "31-45 Minuten",
    "connection_duration_60": "46-60 Minuten",
    "connection_method_email": "E-Mail",
    "connection_method_sms": "Direct Mail",
    "connection_method_google": "Google",
    "connection_method_facebook": "auf facebook.",
    "age_category_1": "<18",
    "age_category_2": "18-24",
    "age_category_3": "25-34",
    "age_category_4": "35-44",
    "age_category_5": "45-54",
    "age_category_6": "55+",
    "all_guest_txt": "Alle Gäste",
    "new_Guest_txt": "Neuer Gast",
    "connections_txt": "Anschlüsse",
    "hotspot": "Hotspot",
    "hotspot_list": "Hotspot-Liste",
    "add_new_hotspot": "Neuen Hotspot hinzufügen",
    "hotspot_information": "Hotspot Informationen",
    "edit_details_button": "Details bearbeiten",
    "wifi_info_message": "Verbinden Sie sich und genießen Sie kostenloses WLAN",
    "connection_message": "Großartig, Sie wurden verbunden mit ",
    "connection_message_suffix": " W-lan",
    "wifi": "W-lan",
    "login_to_access": "Melden Sie sich an, um Zugriff zu erhalten",
    "verification_code": "Bestätigungscode",
    "verification_code_message": "Bitte geben Sie den Bestätigungscode ein, der an folgende Adresse gesendet wurde: ",
    "wifi_user_email": "adamo@gmail.com",
    "wifi_label": "W-lan",
    "your_name": "Wie heißt du übrigens?",
    "your_birthdate": "Können Sie uns Ihr Geburtsdatum verraten?",
    "request_guest_wifi_name": "Bitte geben Sie den WLAN-Gastnamen ein",
    "request_device_key": "Bitte geben Sie den Geräteschlüssel ein",
    "request_maximum_internet_access_length": "Bitte wählen Sie die maximale Dauer des Internetzugangs",
    "mac_address": "MAC-Adresse",
    "device_port": "Geräteport",
    "hardware": "Hardware",
    "current_uptime": "Aktuelle Betriebszeit",
    "nearby_devices": "Geräte in der Nähe",
    "firmware": "Firmware",
    "who_are_you": "Wer bist du?",
    "where_to_contact_you": "Wie können wir Sie erreichen?",
    "your_area_code": "Wie lautet Ihre Vorwahl?",
    "connected": "Verbunden",
    "delete_hotspot_message": "Dieser Hotspot wird dauerhaft gelöscht.",
    "delete_multiple_hotspots_message": "Diese Hotspots werden dauerhaft gelöscht.",
    "speed_error": "Die Geschwindigkeit sollte mindestens 0,01 betragen",
    "speed_max_error": "Bitte geben Sie einen Wert bis 1024 für unbegrenzte Geschwindigkeit ein, oder wählen Sie einen niedrigeren Wert innerhalb des unterstützten Bereichs",
    "device_ssid": "Geräte-SSID",
    "device_ssid_two": "Geräte-SSID Zwei",
    "device_ssid_two_wpa": "Geräte-SSID Zwei WPA",
    "device_key": "Geräteschlüssel",
    "select_location": "Standort auswählen",
    "select_maximum_internet_access_length": "Wählen Sie die maximale Dauer des Internetzugangs",
    "download_speed": "Download-Geschwindigkeit",
    "upload_speed": "Upload-Geschwindigkeit",
    "network_length_1": "15 Minuten",
    "network_length_2": "30 Minuten",
    "network_length_3": "45 Minuten",
    "network_length_4": "1 Stunde",
    "network_length_5": "2 Stunden",
    "network_length_6": "4 Stunden",
    "network_length_7": "6 Stunden",
    "network_length_8": "8 Stunden",
    "network_length_9": "10 Stunden",
    "network_length_10": "12 Stunden",
    "employee_wifi_name": "WLAN-Name des Mitarbeiters",
    "employee_wifi_password": "WLAN-Passwort für Mitarbeiter",
    "guest_wifi_name": "Name des Gast-WLANs",
    "menu_other_products_txt": "Andere Produkte | Menü Online",
    "menu_home_text": "Startseite | Menü Online",
    "whatsapp_title": "Whatsapp",
    "select_campaign_type": "Wählen Sie einen Kampagnentyp",
    "select_readymade_templates": "Wählen Sie aus vorgefertigten Vorlagen oder erstellen Sie Ihre eigenen",
    "campaign_title_required": "Der Kampagnentitel ist erforderlich",
    "type_here": "Hier eingeben...",
    "location_permission_req": "Standortgenehmigung ist erforderlich",
    "platform_permission_req": "Plattformberechtigung ist erforderlich",
    "profile_picture": "Profilbild",
    "click_to_upload": "Zum Hochladen klicken",
    "location_permission": "Standortberechtigung",
    "pin": "Stift",
    "platform_permission": "Plattformberechtigung",
    "set_pin": "PIN festlegen",
    "list_of_users": "Liste der Benutzer",
    "create_user": "Benutzer erstellen",
    "terms_and_condition_req": "Allgemeine Geschäftsbedingungen sind erforderlich",
    "terms_and_conditions": "Geschäftsbedingungen",
    "recommended": "EMPFOHLEN",
    "mo": "\/Monat",
    "unsubscribe": "Abbestellen",
    "cancel_subscription": "Möchten Sie das Abonnement wirklich kündigen?",
    "upgrade": "Aktualisierung",
    "plan_upgraded_successfully": "Der Plan wurde erfolgreich aktualisiert!",
    "menu_online": "Menüonline",
    "support": "Unterstützung",
    "wifiC": "W-LAN",
    "billing": "Abrechnung",
    "please_upgrade": "Bitte aktualisieren Sie, um alle Funktionen nutzen zu können",
    "you_have_subscribed": "Sie haben den ",
    "plan": "planen",
    "of_text": "von",
    "days": "Tage",
    "remaining_days_until_plan": " Verbleibende Tage, bis Ihr Plan aktualisiert werden muss",
    "manage_your_screens": "Verwalten Sie Ihre Bildschirme",
    "screens_you_save": "Bildschirme, die Sie haben",
    "add_more_screens": "Weitere Bildschirme hinzufügen",
    "addition_screen": "Zusatzbildschirm",
    "per_screen": "$ pro Bildschirm",
    "per_box": "$ pro Karton",
    "shipping_changes": "Versandkosten",
    "upto4_boxes": "bis zu 4 Kartons ",
    "charge_now": "Sie erhalten jetzt eine Gebühr",
    "updated_reoccuring": "Ihre aktualisierte wiederkehrende wird",
    "current_reoccuring": "Aktuell wiederkehrend: ",
    "no_text": "NEIN",
    "android_box": "Android Box",
    "old_password_req": "Altes Passwort ist erforderlich",
    "new_password_req": "Neues Passwort erforderlich",
    "confirm_password_req": "Bestätigung des Passworts ist erforderlich",
    "password_do_not_match": "Passwörter stimmen nicht überein",
    "old_password": "Altes Passwort",
    "new_password": "Neues Passwort",
    "confirm_password": "Passwort bestätigen",
    "copy_working_hours": "Diese Arbeitszeiten auf alle Tage kopieren?",
    "yes_copy": "Ja, kopieren",
    "closed": "Geschlossen",
    "opening_time": "Öffnungszeit",
    "closing_time": "Schließzeit",
    "description": "Beschreibung",
    "file_exist": "Datei ist bereits vorhanden, bitte wählen Sie eine andere Datei",
    "bussiness_images": "Geschäftsbilder",
    "display_info_on_market_place": "Möchten Sie die folgenden Informationen auf Ihrem Marktplatz anzeigen?",
    "profile_ready": "Ihr Profil ist fertig!",
    "client_book_with_online": "Kunden können jetzt online bei Ihnen buchen. Geben Sie den untenstehenden Link frei, um loszulegen.",
    "copy_link": "Link kopieren",
    "see_your_profile": "Ihr Profil ansehen",
    "ok_got_it": "Okay, verstanden",
    "preview_profile": "Zeigen Sie eine Vorschau Ihres Profils an und sehen Sie, wie es aussehen würde.",
    "opening_hours": "Öffnungszeiten",
    "display_data": "Daten anzeigen",
    "manage_profile": "Profil verwalten",
    "phone_req": "Telefon erforderlich",
    "market_place_img_req": "Marktplatzbilder sind erforderlich",
    "add_new_market_place": "Neuen Marktplatz hinzufügen",
    "edit_market_place": "Marktplatz bearbeiten",
    "no_reviews_yet": "Noch keine Bewertungen",
    "good": "Gut",
    "average": "Durchschnitt",
    "add_marketPlace": "Marktplatz hinzufügen",
    "all_title": "Alle",
    "included_title": "Inklusive",
    "excluded_title": "Ausgeschlossen",
    "clients_subscribed": "Kunden haben sich in den letzten",
    "clients_groups": "Kundengruppen",
    "customer_groups": "Kundengruppen",
    "audience_title": "Publikum",
    "client_gt5": "die im letzten Jahr nicht zurückgekehrt sind",
    "select_all": "Alles auswählen",
    "modify": "Ändern",
    "campaign_title": "Kampagnentitel",
    "msg_type": "Nachrichtentyp",
    "enter_discount": "Rabatt eingeben",
    "discount_type": "Rabattart",
    "coupon_text": "Gutscheintext",
    "enter_coupon_text": "Gutscheintext eingeben",
    "coupon_button_text": "Text der Gutscheinschaltfläche",
    "enter_coupon_button_text": "Geben Sie den Text für die Gutscheinschaltfläche ein",
    "fine_prin": "Kleingedrucktes",
    "enter_fine_prin": "Geben Sie den Kleingedrucktentext ein",
    "campaign_dec": "Sie können die Kampagne 30 Minuten vor ihrer Startzeit ändern. Gebühren für die SMS-Kampagne werden 30 Minuten vor der Startzeit der Kampagne erhoben.",
    "blast_text_message_dec": "Ihre Massen-SMS ist fertig. Wählen Sie Ihre Zahlungsmethode und senden Sie Ihre Nachrichten.",
    "payment_completed": " Die Zahlung wird abgeschlossen am ",
    "total_cost": "Gesamtkosten",
    "close_title": "Schließen",
    "friday": "Freitag",
    "saturday": "Samstag",
    "sunday": "Sonntag",
    "thursday": "Donnerstag",
    "tuesday": "Dienstag",
    "wednesday": "Mittwoch",
    "port_txt": "Hafen",
    "today_title": "Heute",
    "yesterday_title": "Gestern",
    "last_30_days_txt": "Letzte 30 Tage",
    "this_month_txt": "Diesen Monat",
    "last_month_txt": "Letzter Monat",
    "valid_date_title": "Gültiges Datum auswählen",
    "valid_business_name_txt": "Geben Sie einen gültigen Firmennamen ein",
    "req_bus_add_txt": "Geschäftsadresse ist erforderlich",
    "req_domain_name_txt": "Domänenname ist erforderlich",
    "basic_info_txt": "Grundlegende Informationen zu Ihrem Unternehmen",
    "loyalty_qr_def_txt": "Scannen Sie den QR-Code, sichern Sie sich unsere Treuekarte und freuen Sie sich über Prämien.",
    "last_stamp_txt": "Letzter Stempel",
    "collected_on_txt": "Gesammelt am",
    "stamp_details_txt": "Stempeldetails",
    "add_stamp_txt": "Stempel hinzufügen",
    "choose_brand_color_txt": "Wählen Sie Ihre Markenfarbe und Sprache 🎨",
    "change_anytime_txt": "Sie können dies jederzeit ändern",
    "like_to_get_txt": "Möchten Sie",
    "your_next_visit_txt": "bei Ihrem nächsten Besuch?",
    "time_to_time_get_offers": "Möchten Sie von Zeit zu Zeit tolle Angebote und Aktionen erhalten?",
    "respond_back_to_you": "Möchten Sie, dass Ihnen jemand antwortet?",
    "input_serve_better": "Ihre Eingabe hilft unserem Team, Ihnen besser zu dienen!",
    "do_it_txt": "Lass es uns tun",
    "take_15_s": "Dauert 15 Sekunden",
    "rate_on_google_desc": "Wären Sie so freundlich, uns bei Google zu bewerten?",
    "may_be_next_time": "Vielleicht beim nächsten Mal",
    "thank_you_txt": "Danke",
    "look_next_txt": "Wir freuen uns auf Ihren nächsten Besuch!",
    "deleteUserTitle": "Dieser Benutzer wird dauerhaft gelöscht.",
    "deleteMUserTitle": "Diese Benutzer werden dauerhaft gelöscht.",
    "change_pin": "Pin ändern",
    "area_code": "Wie lautet Ihre Vorwahl? ",
    "add_menu": "Menü hinzufügen",
    "menu_name": "Menüname",
    "add_menu_placeholder": "Pizza, Burger, Getränke usw.",
    "enable_data_Collection": "Datenerfassung aktivieren",
    "add_new_menu": "Neues Menü hinzufügen",
    "rename_menu": "Menü umbenennen",
    "preview": "Vorschau",
    "generate_qr": "Generierter QR",
    "edit_menu": "Menü „Bearbeiten“",
    "remove_menu": "Menü entfernen",
    "menu_delete_msg": "Dieses Menü wird dauerhaft gelöscht",
    "menus_delete_msg": "Diese Menüs werden dauerhaft gelöscht",
    "view_menu_dialoage_msg": "Du möchtest zu deinem Geburtstag ein Geschenk bekommen?",
    "skip": "Überspringen",
    "cliam_your_gift": "Fordern Sie Ihr Geschenk an",
    "collect_form": "Formular abholen",
    "enter_first_name": "Vorname eingeben",
    "enter_last_name": "Geben Sie den Nachnamen ein",
    "enter_email": "E-Mail eingeben",
    "enter_dob": "Geburtsdatum eingeben",
    "enter_number": "Nummer eingeben",
    "select_gender": "Geschlecht auswählen",
    "congratulations_desc": "🎉 Herzlichen Glückwunsch! Ihr Geschenkantrag wurde erfolgreich eingereicht. Unser Team wird Sie in Kürze kontaktieren. 🎉",
    "male_heading": "Männlich",
    "male_text": "männlich",
    "female_heading": "Weiblich",
    "female_text": "weiblich",
    "others_heading": "Sonstiges",
    "other_text": "Sonstiges",
    "BirthD": "Geburtsdatum",
    "GenderT": "Geschlecht",
    "EmailT": "E-Mail",
    "dobT": "Geburtsdatum",
    "capitalize_menu": "Speisekarte",
    "select_menu": "Menü auswählen",
    "manage_variant": "Variante verwalten",
    "add_products": "Produkte hinzufügen",
    "add_category": "Kategorie hinzufügen",
    "category_delete": "Diese Kategorie wird dauerhaft gelöscht",
    "variation_delete": "Diese Variante wird aus dem Produkt gelöscht.",
    "product_delete": "Dieses Produkt wird dauerhaft gelöscht.",
    "categories_delete": "Diese Kategorien werden dauerhaft gelöscht.",
    "products_delete": "Diese Produkte werden dauerhaft gelöscht.",
    "category": "KATEGORIE",
    "price": "PREIS",
    "food_product_placeholder": "Pizza, Burger, Getränke usw.",
    "active_title": "Aktiv",
    "inActive_title": "Inaktiv",
    "status_capital": "STATUS",
    "cat_status_require": "Kategoriestatus ist erforderlich",
    "cat_name_require": "Kategoriename ist erforderlich",
    "category_name": "Kategoriename",
    "status": "Status",
    "lgDay1": "Montag",
    "lgDay2": "Dienstag",
    "lgDay3": "Mittwoch",
    "lgDay4": "Donnerstag",
    "lgDay5": "Freitag",
    "lgDay6": "Samstag",
    "lgDay7": "Sonntag",
    "is_closed_title": "Geschlossen",
    "book_table_title": "Tisch reservieren",
    "emailErrSub": "E-Mail-Betreff ist erforderlich",
    "email_subject": "E-Mail-Betreff",
    "contactUsfrmTitleEmail": "Auf E-Mail antworten",
    "companyInfo": "Lorem Ipsum ist Lorem Hrig, Lorem Ipsum ist Fraets.Lorem Ipsum ist Lorem Hrig, Lorem Ipsum ist Fraets.Lorem Ipsum ist Lorem Hrig, Lorem Ipsum ist Fraets.",
    "footerSTitle1": "Über Menuonline",
    "footerSTitle1Lnk2": "Merkmale",
    "footerSTitle1Lnk3": "Der Blog",
    "footerSTitle2": "Rechtliches",
    "footerSTitle2Lnk1": "Geschäftsbedingungen",
    "footerSTitle2Lnk2": "Datenschutzrichtlinie",
    "footerSTitle3": "Für Unternehmen",
    "footerSTitle3Lnk1": "Für Partner",
    "footerSTitle3Lnk2": "Preise",
    "footerSTitle3Lnk3": "Unterstützung für Partner",
    "footerCopyRithgTxt": "  Menuonline oder seine Tochtergesellschaften",
    "homeTitle": "Heim",
    "contactTitle": "Kontaktieren Sie uns",
    "aboutTitle": "Über uns",
    "homeMenuTitle": "Speisekarte",
    "bookTableTitle": "Tisch reservieren",
    "vDateT": "Gültiges Datum auswählen",
    "reqDateT": "Datum ist erforderlich",
    "dateGtErr": "Das Datum muss nach oder gleich dem heutigen Datum sein.",
    "timeInvalid": "Die Uhrzeit ist ungültig",
    "reqTimeT": "Zeit ist erforderlich",
    "timeInvalidBfr": "Wählen Sie eine Zeit, die mindestens 5 Minuten nach der aktuellen Zeit liegt ",
    "PerReq": "Anzahl der Personen ist erforderlich",
    "validcNoT": "Geben Sie eine gültige Kontaktnummer ein",
    "reqCrT": "Währung ist erforderlich",
    "reqPrT": "Preis ist erforderlich",
    "reCateT": "Kategorie ist erforderlich",
    "reqVrT": "Variante ist erforderlich",
    "reqVrVldT": "Geben Sie eine gültige Variante ein ",
    "validLNameT": "Geben Sie einen gültigen Nachnamen ein",
    "sDateInValid": "Das Ab-Datum ist ungültig",
    "minmReq": "Mindestens erforderlich",
    "resLblDate": "Datum auswählen",
    "resLblTime": "Wähle eine Uhrzeit",
    "perT": "Für wie viele Personen?",
    "resLblEmail": "Bitte geben Sie Ihre E-Mail-Adresse ein.",
    "resLblNote": "Eine Reservierungsnotiz hinzufügen",
    "imageError1MB": "Bitte fügen Sie ein Bild ein, das kleiner als 1 MB ist",
    "imageError": "Bitte fügen Sie ein Bild ein, das kleiner als 2,5 MB ist",
    "imageError3Mb": "Bitte fügen Sie ein Bild ein, das kleiner als 3 MB ist",
    "imageInVldError": "Die Datei ist ungültig.",
    "addProMT": "Produktmenü",
    "proT": "Produktname",
    "reqProT": "Produktname ist erforderlich",
    "proPT": "Produktpreis",
    "reservationSuccTitle": "Großartig, Ihre Reservierung ist abgeschlossen",
    "book": "Buch",
    "custzName": "Anpassungsname",
    "always": "Stets",
    "proImgReq": "Produktbild ist erforderlich",
    "selCustmT": "Bitte wählen Sie Anpassung oder erstellen Sie eine neue",
    "visStR": "Sichtbarkeitsstatus ist erforderlich",
    "avlblScR": "Bitte wählen Sie den Verfügbarkeitszeitplan",
    "addonPR": "Bitte wählen Sie Zusatzprodukte aus",
    "upsellPR": "Bitte wählen Sie Upselling-Produkte",
    "markItemR": "Bitte Artikel markieren Produkte auswählen",
    "caloriesR": "Kalorien sind erforderlich",
    "allergensR": "Bitte wählen Sie Allergene Produkte",
    "prepInstR": "Zubereitungshinweise sind erforderlich",
    "staffNR": "Mitarbeiternotizen sind erforderlich",
    "discountR": "Rabatt ist erforderlich",
    "validDiscE": "Gültigen Rabatt eingeben",
    "disday": "Tagtäglich",
    "plSelDayT": "Bitte wählen Sie einen Tag",
    "sTimeReq": "Startzeit ist erforderlich",
    "sTimeInValid": "Die Startzeit ist ungültig",
    "eTimeReq": "Endzeit ist erforderlich",
    "eTimeInValid": "Die Endzeit ist ungültig",
    "sDateReq": "Ab-Datum ist erforderlich",
    "eDateReq": "Bis heute ist erforderlich",
    "eDateInValid": "Bis Datum ist ungültig",
    "disdate": "Vom Datum",
    "disdate1": "Miteinander ausgehen",
    "disdate2": "Von Datum",
    "currT": "Währung",
    "iconR": "Bitte wählen Sie das Symbol „Element markieren“",
    "minT": "Minimum",
    "maxT": "Maximal",
    "itemNT": "Artikelname",
    "itemPT": "Artikelpreis",
    "descProT": "Beschreiben Sie Ihr Produkt",
    "cateT": "Kategorie",
    "selProCateT": "Wählen Sie Ihre Produktkategorie",
    "reqMT": "Menüname ist erforderlich",
    "ViewMenu": "Menü anzeigen",
    "CopyMenu": "Menü „Kopieren“",
    "EditMenu": "Menü „Bearbeiten“",
    "RemoveMenu": "Menü „Entfernen“",
    "welcomeMnuTitle": "Willkommen bei",
    "reviewT": "Bewertungen",
    "userErrorMsg": "Entschuldigung, ein Fehler ist aufgetreten!!!",
    "reqCateT": "Kategoriename ist erforderlich",
    "mngCustT": "Anpassung verwalten",
    "custNReq": "Anpassungsname ist erforderlich",
    "incReq": "Im Lieferumfang enthalten ist erforderlich",
    "minmValid": "Gültigen Mindestwert eingeben",
    "maxmReq": "Maximal ist erforderlich",
    "cust": "Anpassung",
    "crCust": "Neue Anpassung erstellen",
    "custList": "Anpassungsliste",
    "delCustmzT": "Diese Anpassung wird dauerhaft gelöscht.",
    "mkNameR": "Markieren Sie den Artikelnamen, der erforderlich ist",
    "mkDelT": "Dieses Markierungselement wird dauerhaft gelöscht.",
    "hideText": "Verstecken",
    "showText": "Zeigen",
    "device_mac_txt": "Gerät Mac",
    "delete_hotspot_txt": "Dieser Hotspot wird dauerhaft gelöscht.",
    "delete_hotspots_txt": "Diese Hotspots werden dauerhaft gelöscht.",
    "emp_wifi_name": "WLAN-Name des Mitarbeiters",
    "max_limit_txt": "Geben Sie „1024“ für die Geschwindigkeit ein, um sie als UNBEGRENZT festzulegen. Dieser Wert entfernt alle Begrenzungen und ermöglicht maximalen Durchsatz",
    "device_port_txt": "Geräteport",
    "firmware_txt": "Firmware",
    "hotspot_info_txt": "Hotspot Informationen",
    "editDBtn": "Details bearbeiten",
    "birth_date": "Geburtsdatum",
    "di_theme_clr": "Themenfarbe genau 7 Ziffern",
    "color_Invalid_txt": "Gültigen Farbcode auswählen",
    "Req_theme_clr": "Designfarbe ist erforderlich",
    "select_valid_color_txt": "Bitte wählen Sie eine gültige Designfarbe aus",
    "req_redir_link": "Umleitungslink ist erforderlich",
    "val_redir_link": "Geben Sie einen gültigen Weiterleitungslink ein",
    "req_business_name_txt": "Firmenname ist erforderlich",
    "splash_preview": "Splash-Vorschau",
    "create_new_splash": "Neuen Splash erstellen",
    "splash_page": "Splash-Seite",
    "splash_page_builder": "Splash-Seitenersteller",
    "redirect_link": "Weiterleitungslink",
    "theme_color": "Designfarbe",
    "enable_social_login": "Aktivieren Sie Social-Media-Optionen für die Benutzeranmeldung",
    "google": "Google",
    "facebook": "auf facebook.",
    "is_mandatory": "Ist es obligatorisch?",
    "field": "Feld",
    "name": "Name",
    "first_name": "Vorname",
    "last_name": "Nachname",
    "birthdate": "Geburtsdatum",
    "gender": "Geschlecht",
    "email": "E-Mail",
    "dob": "Geburtsdatum",
    "zip_code": "PLZ",
    "required_redirect_link": "Umleitungslink ist erforderlich",
    "valid_redirect_link": "Geben Sie einen gültigen Weiterleitungslink ein",
    "required_theme_color": "Designfarbe ist erforderlich",
    "theme_color_length": "Themenfarbe genau 7 Ziffern",
    "required_name": "Name ist erforderlich",
    "delete_splash_message": "Sie möchten diesen Splash löschen?",
    "delete_multiple_splashes_message": "Sie möchten diese Spritzer löschen?",
    "user_login_required": "Benutzeranmeldung ist erforderlich",
    "set_theme_color": "Designfarbe festlegen",
    "splash_colllect_additional_info_txt": "Sammeln Sie zusätzliche Informationen",
    "verify_mobile_otp_message": "Möchten Sie zur Bestätigung der Mobiltelefonnummer ein OTP senden?",
    "add_company": "Firma hinzufügen",
    "edit_company": "Firma bearbeiten",
    "ds_device": "Digital Signage Gerät",
    "ds_device_activation_fee": "Aktivierungsgebühr für Digital Signage-Geräte",
    "wifi_hardware": "WLAN-Hardware",
    "menu_design": "Menügestaltung",
    "ds_hardware": "Digital Signage Hardware",
    "company_name_req": "Firmenname ist erforderlich",
    "known_name_req": "Bekannter Name ist erforderlich",
    "no_location_req": "Standortnummer ist erforderlich",
    "minimum_location": "Bitte geben Sie mindestens 1 Ort ein",
    "terms_req": "Bedingungen sind erforderlich",
    "notes_req": "Notizen sind erforderlich",
    "sms_charge_req": "SMS-Gebühr ist erforderlich",
    "menuonline_quantity_req": "Menü Online Menge ist erforderlich",
    "menuonline_cost_req": "Menü Online Kosten sind erforderlich",
    "ds_quantity_req": "Digital Signage Menge ist erforderlich",
    "ds_monthly_cost_req": "Monatliche Kosten für Digital Signage sind erforderlich",
    "reputation_quantity_req": "Reputationsmenge ist erforderlich",
    "reputation_cost_req": "Reputationskosten sind erforderlich",
    "marketing_quantity_req": "Marketingmenge ist erforderlich",
    "marketing_cost_req": "Marketingkosten sind erforderlich",
    "tax_percentage_req": "Steuersatz ist erforderlich",
    "router_hardware_quantity_req": "Router-Hardwaremenge ist erforderlich",
    "wifi_hardware_cost_req": "Es fallen Kosten für die WIFI-Hardware an.",
    "activation_cost_req": "Aktivierungskosten sind erforderlich",
    "wifi_quantity_req": "WIFI-Menge ist erforderlich",
    "wifi_router_req": "WLAN-Router ist erforderlich",
    "menu_design_quantity_req": "Menüdesign Menge ist erforderlich",
    "menu_design_cost_req": "Kosten für das Menüdesign sind erforderlich",
    "quantity_req": "Menge ist erforderlich",
    "cost_req": "Kosten sind erforderlich",
    "sheduledown_quantity_req": "Anzahl der Tage ist erforderlich",
    "sheduledown_cost_req": "Kostenvoranschlag erforderlich",
    "loyalty_program_quantity_req": "Treueprogramm Menge ist erforderlich",
    "loyalty_program_cost_req": "Kosten für das Treueprogramm sind erforderlich",
    "loyalty_program_sms_quantity_req": "Treueprogramm SMS Menge ist erforderlich",
    "loyalty_program_sms_cost_req": "SMS-Kosten für Treueprogramm sind erforderlich",
    "comapny_created": "Unternehmen erfolgreich gegründet",
    "comapny_updated": "Unternehmen erfolgreich aktualisiert",
    "billing_info": "ABRECHNUNGSDATEN",
    "business_name_acc_holder": "Firmenname (Kontoinhaber)",
    "client_first_name": "Vorname des Kunden",
    "client_last_name": "Nachname des Kunden",
    "dba_known_as": "DBA Bekannt als",
    "business_address": "Geschäftsadresse",
    "no_of_locations": "Anzahl Standorte",
    "package_details": "PAKETDETAILS",
    "terms": "Bedingungen",
    "one_month": "1 Monat",
    "per_sms_charge": "Gebühr pro SMS",
    "plan_test": "PLAN: TEST",
    "desc_capital": "BESCHREIBUNG",
    "qty_capital": "Menge",
    "cost_capital": "KOSTEN",
    "total_price_capital": "GESAMTPREIS",
    "quantity": "Menge",
    "can_not_access": "Sie können nicht mehr auswählen als",
    "licenses": "Lizenzen",
    "monthly_cost": "Monatliche Kosten",
    "plan_cost": "Plankosten",
    "tax": "Steuer",
    "tax_percentage": "Steuersatz",
    "monthly_cost_after_tax": "Monatliche Kosten nach Steuern",
    "activation_one_time_charge": "Aktivierung und einmalige Gebühr",
    "sub_total": "ZWISCHENSUMME",
    "action": "Aktion",
    "cost": "Kosten",
    "shipping_charge": "Versandkosten",
    "other": "Andere",
    "additional_cost": "Zusätzliche Kosten (Andere Gebühren)",
    "other_tax": "Andere Steuern",
    "total": "Gesamt",
    "license_statistics": "Lizenzstatistik",
    "total_licenses": "Lizenzen gesamt",
    "available_licenses": "Verfügbare Lizenzen",
    "stock": "Aktie",
    "has_permission": "Hat die Erlaubnis",
    "avg_stock_price": "AVG-Aktienkurs",
    "average_price": "Durchschnittspreis",
    "allocated": "Zugewiesen",
    "reward_regulars": "Stammkunden belohnen",
    "not_add_coupon_url": "Gutschein-URL kann nicht hinzugefügt werden. Die maximale Länge der Nachricht beträgt 160",
    "like_attach_coupon": "Möchten Sie einen Coupon anhängen?",
    "advance_scheduling": "Vorausplanung",
    "choose_day": "Wählen Sie den\/die Tag(e) zum Senden der Nachricht aus",
    "select_essage_window": "Nachrichtenfenster auswählen",
    "subscription_outside_delivery_window": "Wenn sich ein Benutzer außerhalb des Lieferfensters anmeldet, erhält er Ihren Autoresponder zum nächsten verfügbaren Zeitpunkt.",
    "remaining": "Übrig",
    "processing_request": "Beim Verarbeiten Ihrer Anfrage ist ein Fehler aufgetreten.",
    "list_companies": "Liste der Unternehmen",
    "are_you_sure": "Bist du sicher..?",
    "signin_agreement": "Mit der Bestätigung dieser Datenschutzerklärung erklärt das Unternehmen, dass kein Vertrag unterzeichnet ist.",
    "status_updated": "Statusaktualisierung erfolgreich",
    "status_failed": "Status konnte nicht aktualisiert werden",
    "new_editor_status_updated": "Der DS-Neuer-Editor-Status wurde erfolgreich aktualisiert.",
    "user_name": "Benutzername",
    "known_as": "bekannt als",
    "agreement_signed": "Unterzeichnete Vereinbarung",
    "deactivate": "Deaktivieren",
    "activate": "Aktivieren",
    "login_to_user_acc": "Anmeldung zum Benutzerkonto",
    "disable": "Deaktivieren",
    "enable": "Aktivieren",
    "ds_new_editor": "DS Neuer Editor",
    "sign_agreement": "Vereinbarung unterzeichnen",
    "agreement_sign": "Vereinbarung ohne Unterschrift",
    "view_agreement": "Vereinbarung anzeigen",
    "download_agreement": "Vereinbarung herunterladen",
    "add_deleted_user": "Gelöschten Benutzer hinzufügen",
    "deleted_user": "Benutzer löschen",
    "favourite": "Favorit",
    "refresh": "Aktualisieren",
    "delete_chat": "Chat löschen",
    "responsive_drawer": "Reaktionsfähige Schublade",
    "delete_contact": "Kontakt löschen",
    "clear_chat_history": "Chatverlauf löschen",
    "clear_chat_history_dec": "Möchten Sie diesen Chat wirklich löschen?",
    "clear_contact_dec": "Möchten Sie diesen Kontakt wirklich löschen?",
    "select_contact": "Kontakt auswählen",
    "new_conversation": "Neue Unterhaltung beginnen",
    "type_msg": "Geben Sie hier Ihre Nachricht ein",
    "select_delete_client": "Bitte wählen Sie den zu löschenden Client aus",
    "select_delete_client_chat": "Bitte wählen Sie einen Client aus, um den Chat zu löschen",
    "select_delete_client_chat_err": "Sie chatten nicht mit diesem Kunden",
    "acquire_customers_business": "Gewinnen Sie neue Kunden von anderen Unternehmen.",
    "customers_from_events": "Gewinnen Sie Kunden durch Veranstaltungen und bringen Sie sie zu sich!",
    "customers_from_ads": "Gewinnen Sie Kunden durch Zeitungsanzeigen.",
    "smart_pamphlets": "Erstellen Sie intelligente Broschüren zur Erfassung von Kundendaten.",
    "smart_qr_group": "Ein Smart-QR zum Beitritt zu einer Gruppe für Kunden.",
    "opt_in_number": "Ermöglichen Sie Kunden die Angabe einer Opt-in-Nummer.",
    "qr_menu": "Erstellen Sie einen QR-Code zur Anzeige Ihres Menüs.",
    "collect_birthdays": "Erfassen Sie Geburtstage und Jubiläen von Kunden.",
    "join_loyalty_program": "Gewinnen Sie neue Kunden für mein Treueprogramm.",
    "create_feedback_forms": "Erstellen Sie Formulare, um Kundenfeedback zu sammeln.",
    "exclusive_discounts_promotions": "Bieten Sie exklusive Rabatte und Sonderangebote für Cross-Promotions an.",
    "group_smart_qr_opt_in": "Nachdem Sie eine Gruppe erstellt haben, können Sie einen intelligenten QR-Code generieren und auswählen, sodass Kunden sofort einer Gruppe beitreten können.",
    "group_smart_qr_opt_in_dec": "Nach dem Erstellen einer Gruppe ermöglicht das Bild-Widget den Kunden, sich per Text für den Erhalt von Werbeaktionen anzumelden. Senden Sie beispielsweise eine SMS mit dem Text „Pizza“ an 555-555-5555.",
    "in_store_discount_next_visit": "Erhalten Sie bei Ihrem nächsten Besuch in unserem Geschäft einen Rabatt",
    "delivery_redirection": "Lieferumleitung anlegen",
    "additional_info": "Weitere Informationen",
    "add_url": "URL hinzufügen",
    "custom_fields": "Benutzerdefinierte Felder",
    "meta_tag": "Meta-Tag",
    "max_chars": "Max. Zeichen",
    "add": "Hinzufügen",
    "update_campaign": "Kampagne aktualisieren",
    "last_week_required": "letzte Woche ist erforderlich",
    "large_week_value": "Der Wochenwert ist zu groß. Geben Sie eine gültige Woche ein.",
    "less_than_last_week": "Die in der letzten Woche nicht zurückgegebenen Beträge müssen geringer sein als in der letzten Woche.",
    "last_week_req": "Keine Rückgabe in der letzten Woche erforderlich",
    "birthday_special": "Geburtstagsspecial",
    "birthday_message": "Automatisierte Nachricht an ihren Geburtstagen mit personalisierten Grüßen und verlockenden Angeboten",
    "birthday_sms": "Erstellen Sie personalisierte Geburtstagsgrüße für SMS\/MMS",
    "target_audience": "Zielgruppe auswählen",
    "client_by_birthday": "Kunde nach Geburtstag",
    "clients_once_year": "Diese Kampagne wird automatisch einmal im Jahr an Kunden gesendet ",
    "auto_responder_default_msg_1": "Sie sind einer unserer besten Kunden, denen wir gerne Rabatte gewähren! Mögen Sie immer wohlhabend und gesund sein. Herzlichen Glückwunsch zum Geburtstag! %% Vorname %%",
    "campaign_send_to_client_birthday": "Diese automatische Kampagne wird an Kunden mit Geburtstagen gesendet. Sie können die Zielgruppe unten anpassen.",
    "get_more_engagement": "Sorgen Sie für mehr Engagement, indem Sie Geburtstags-SMS senden 🎂",
    "welcome_new_clients": "Begrüßen Sie neue Kunden",
    "make_lasting_impression": "Hinterlassen Sie einen bleibenden Eindruck, indem Sie Gäste, die zum ersten Mal im Restaurant sind, herzlich begrüßen und willkommen heißen.",
    "personalized_greetings": "Erstellen Sie personalisierte Begrüßungen für die neuen Kunden",
    "greetings_to_new_customers": "Mit dieser Kampagne werden neuen Kunden am Tag ihrer ersten Interaktion mit Ihrem Unternehmen Willkommensgrüße gesendet.",
    "audience_predefined": "Die Zielgruppe dieses Autoresponders ist vordefiniert, das haben wir im Griff!",
    "send_clients_subscribed": "Dieser Autoresponder ist so eingestellt, dass er Kunden, die sich in den letzten ",
    "first_interaction": "  nach ihrer ersten Interaktion.",
    "default_msg_2": "Wir hoffen, Ihr erster Besuch hat Ihnen gefallen. Wir würden uns freuen, Sie bald wiederzusehen. Deshalb bieten wir Ihnen einen Rabatt an! Klicken Sie auf den Link, um Ihren nächsten Termin bei uns zu buchen und Ihr spezielles Online-Angebot jetzt einzulösen.",
    "new_clients_update": "Begrüßung neuer Kunden – Aktualisierung des Kampagnentexts",
    "new_clients_warm_greeting": "Begrüßen Sie neue Kunden mit einem herzlichen Gruß 💌",
    "win_back_clients": "Kunden zurückgewinnen",
    "re_engage_past_customers": "Gewinnen Sie mit unwiderstehlichen Angeboten alte Kunden zurück und ermutigen Sie sie, wiederzukommen.",
    "target_disengaged_clients": "Fordern Sie desinteressierte Kunden mit einem Sonderangebot zur Rückkehr auf – eine bewährte Taktik, um Kunden zurückzulocken.",
    "campaign_sends_to_clients": "Diese Kampagne wird an Kunden gesendet, die nach einer bestimmten Zeit nicht zurückgekehrt sind.",
    "customize_targeting": "Passen Sie die Zielgruppenauswahl unten an, um SMS\/MMS an ehemalige Kunden zu senden.",
    "clients_with_at_least": "Kunden mit mindestens ",
    "connection_last": " Verbindung im letzten ",
    "return_in_last_week": " Woche, kehrte aber in der letzten ",
    "arr_menu_44": " Woche",
    "df_msg_4": "Wir möchten Sie SO SEHR zurück, dass wir Ihnen allein für Ihre Rückkehr 10 % Rabatt geben! Möchten Sie es heute noch einmal mit uns versuchen?",
    "update_campaign_text": "Kunden zurückgewinnen - Kampagnentext aktualisieren",
    "re_invite_customers": "Laden Sie Kunden erneut ein, die seit einiger Zeit nicht mehr da waren",
    "loyal_patrons": "Zeigen Sie treuen Stammkunden Ihre Wertschätzung mit Sonderrabatten und Treueprämien",
    "surprize_top_spenders": "Überraschen Sie Top-Ausgeber mit personalisierten Sonderangeboten.",
    "campaign_automatically_send": "Diese Kampagne versendet automatisch Prämien, um die Kundenfrequenz in Ihrem Geschäft zu steigern.",
    "sms_loyal_clients": "Diese Kampagne sendet SMS\/MMS an treue Kunden. Sie können die Zielgruppe unten ändern.",
    "or_more_connection": " oder mehr Verbindungen innerhalb der letzten ",
    "week_period": " Woche.",
    "default_msg_5": "Sie sind schon seit langer Zeit Kunde! Wow, die Zeit vergeht wie im Flug! Wir schätzen Sie und möchten uns bei Ihnen dafür bedanken, dass Sie bei uns sind.",
    "reward_regulars_update": "Stammkunden belohnen – Kampagnentext aktualisieren",
    "special_discounts": "Schätzen Sie es und geben Sie einen Sonderrabatt💎",
    "leave_positive_online": "Ermutigen Sie Kunden, positive Online-Bewertungen zu hinterlassen, um den Ruf des Restaurants zu verbessern",
    "clients_service_online": "Die Kampagne animiert Ihre Kunden dazu, Ihren Service online zu bewerten.",
    "increase_your_company": "Dieser Autoresponder wird gesendet, um die Online-Bewertung Ihres Unternehmens zu verbessern.",
    "send_message_after": "Nachricht senden nach ",
    "min_connection": " min der Verbindung",
    "default_msg_6": "Wir hoffen, Ihr erster Besuch hat Ihnen gefallen und würden uns freuen, Sie bald wiederzusehen! [URL] Klicken Sie auf den Link, um uns bei Google zu bewerten.",
    "ratings_update": "Holen Sie sich mehr Bewertungen Update-Kampagnentext",
    "friendly_nudge": "Erhalten Sie mehr Bewertungen mit einem freundlichen Anstoß ⭐",
    "thanks_for_visiting": "Vielen Dank für Ihren Besuch",
    "express_gratitude": "Drücken Sie Ihren Kunden mit herzlichen Dankesnachrichten Ihre Dankbarkeit dafür aus, dass sie sich für Ihr Unternehmen entschieden haben",
    "thank_you_message": "Erstellen Sie eine Dankesnachricht für Ihre Kunden.",
    "clients_for_visiting": "Bedanken Sie sich herzlich bei Ihren Kunden für ihren Besuch bei Ihnen.",
    "campaign_audience": "Dieses automatische Kampagnenpublikum ist vordefiniert, das haben wir im Griff!",
    "campaign_automatically_sends": "Diese Auto-Kampagne versendet automatisch nach ",
    "minutes_customer_visit": " Minuten Kundenbesuch",
    "default_msg_7": "Vielen Dank, dass Sie unser geschätzter Kunde sind. Wir sind sehr dankbar für die Freude, Sie bedienen zu dürfen und hoffen, dass wir Ihre Erwartungen erfüllen konnten.",
    "visiting_update_campaign": "Vielen Dank für Ihren Besuch. Kampagnentext aktualisieren",
    "guests_thank_you": "Bedanken Sie sich bei Ihren Gästen für ihren Besuch 😊",
    "download_title": "Herunterladen",
    "qr_gen_title": "QR generiert",
    "qr_download_s_title": "QR-Code erfolgreich heruntergeladen",
    "sel_dark_brand_clr_msg": "Bitte wählen Sie eine gültige Barnd-Farbe aus",
    "manage_customer_stamp_rewards": "Stempelprämien für Kunden verwalten 🎁",
    "sel_loc_menu_title": "Wählen Sie Standorte für Ihr Menü",
    "ans_req": "Antwort ist erforderlich",
    "valid_reputation_name": "Geben Sie einen gültigen Reputationsnamen ein",
    "reviews_name_req": "„Get More Reviews“ (Weitere Bewertungen erhalten) – Name ist erforderlich",
    "birthdate_required": "Geburtsdatum ist erforderlich",
    "gender_required": "Geschlecht ist erforderlich",
    "valid_birthdate_required": "Geben Sie ein gültiges Geburtsdatum ein",
    "custom_delivery_redirection": "Erstellen Sie ein benutzerdefiniertes Formular zur Umleitung von Lieferungen nach Gruppen",
    "customer_fields_incentive_settings": "Kundenfelder und Anreizeinstellungen",
    "delivery_redirection_text": "Lieferumleitung",
    "delivery_redirection_success": "Glückwunsch! Ihr Widget zur Lieferumleitung wurde erstellt! 🎉",
    "swipe_to_preview_redirection": "Wischen Sie, um zu sehen, wie Ihr Formular zur Lieferumleitung aussehen wird",
    "enter_item_name": "Artikelnamen eingeben",
    "home_page_text": "Homepagetext",
    "settings": "Einstellungen",
    "anniversary_required": "Jahrestag ist erforderlich",
    "valid_anniversary": "Geben Sie ein gültiges Jubiläumsdatum ein",
    "form_submited": "Formular erfolgreich abgeschickt",
    "notifications": "Benachrichtigungen",
    "discount_message": "Sichern Sie sich jetzt 20 % Rabatt auf Pizza!",
    "is_required": " ist erforderlich",
    "section_title_required": "Abschnittstitel ist erforderlich",
    "section_dec_required": "Abschnittsbeschreibung ist erforderlich",
    "client_details_required": "Die Felder für die Kundendetails sind Pflichtfelder.",
    "compliance": "Einhaltung",
    "SMS_campaign1": "SMS-Kampagne 1",
    "mobile_number_mandatory": "Handynummer ist Pflicht",
    "new_answer": "Neue Antwort",
    "new_question": "Neue Frage",
    "add_new_question": "Neue Antwort hinzufügen",
    "select": "Wählen",
    "group_customers_question": "Wo möchten Sie Ihre Kunden gruppieren?",
    "contacts_added_to_group": "Alle Kontakte, die das Formular abschicken, werden der ausgewählten Gruppe hinzugefügt",
    "edit_client_details_section": "Abschnitt „Clientdetails bearbeiten“",
    "client_details_fields": "Felder für Kundendetails",
    "enter_client_details_section_title_desc": "Geben Sie den Titel und die Beschreibung des Abschnitts mit den Kundendetails ein",
    "choose_fields_displayed_client_side": "Wählen Sie die Felder aus, die auf der Clientseite angezeigt werden sollen",
    "section_title": "Abschnittstitel",
    "add_title": "Titel hinzufügen",
    "section_description": "Abschnittsbeschreibung",
    "add_description": "Beschreibung hinzufügen",
    "enter_program_name_location": "Geben Sie den Programmnamen und den Ort ein 📍",
    "brand_theme_clr_txt": "Markenthemafarbe",
    "set_loyalty_punch_card_theme": "Lassen Sie uns ein Thema für Ihre Treue-Stempelkarte festlegen 🎨",
    "upload_logo_txt": "Laden Sie Ihr Logo hoch",
    "recommended_image_specs": "Für eine optimale Anzeige empfehlen wir die Verwendung eines Bilds mit 512 x 512 Pixeln. JPG, SVG oder PNG. Maximale Größe 10 MB.",
    "valid_rep_name_txt": "Geben Sie einen gültigen Reputationsnamen ein",
    "rep_name_req_txt": "„Get More Reviews“ (Weitere Bewertungen erhalten) – Name ist erforderlich",
    "que_req": "Frage ist erforderlich",
    "day_of_birthday": "am Tag des Geburtstags",
    "day_before_birthday": "3 Tage vor dem Geburtstag",
    "week_before_birthday": "eine Woche vor dem Geburtstag",
    "two_week_before_birthday": "zwei Wochen vor dem Geburtstag",
    "de_active": "Deaktivieren",
    "campaign_details": "Kampagnendetails",
    "link_clicked": "Link angeklickt",
    "history": "Geschichte",
    "auto_responder_summary": "Auto-Responder-Zusammenfassung",
    "vsop_1": "15 Minuten",
    "vsop_2": "30 Minuten",
    "vsop_3": "45 Minuten",
    "vsop_4": "60 Minuten",
    "vsop_5": "90 Minuten",
    "vsop_6": "120 Minuten",
    "in_the_last": " im letzten ",
    "return_in_last": " kehrte aber nicht zurück in der letzten ",
    "of_the_connection": "der Verbindung",
    "your_campaign": "Ihre Kampagne",
    "you_have_successfully": "Sie haben erfolgreich",
    "published_successfully": "erfolgreich veröffentlicht",
    "updated_smart_campaign": "die Smart-Kampagne aktualisiert",
    "almost_done_text": "Fast fertig!",
    "update_campaign_desc": "Ihr Autoresponder ist fertig. Sobald er aktiviert ist, sendet er fortlaufend Nachrichten an Kunden. Diese Kampagne kann jederzeit problemlos geändert oder angehalten werden.",
    "update_and_publish": "Aktualisieren und veröffentlichen",
    "reset_campaign_title": "Dadurch werden der benutzerdefinierte Text, das Bild, die Rabattangebote und die Zielgruppe Ihrer Kampagne dauerhaft gelöscht!",
    "space_wifi": " W-lan",
    "custom_forms": "Benutzerdefinierte Formulare",
    "web_e_signup": "Elektronische Web-Anmeldung",
    "import": "Import",
    "permissions_req": "Berechtigungen sind erforderlich",
    "redeemed": "Eingelöst",
    "coupon_already_redeemed": "Gutschein bereits eingelöst am",
    "autoresponder_campaigns": "Autoresponder-Kampagnen",
    "autoresponder_campaign_desc": "Aktivieren Sie den Autoresponder und starten Sie die personalisierte Kampagne",
    "mon": "MO",
    "tue": "DIE",
    "wed": "HEIRATEN",
    "thu": "DO",
    "fri": "FR",
    "sat": "SA",
    "sun": "SONNE",
    "duplicate": "Duplikat",
    "visibility": "Sichtweite",
    "availability": "Verfügbarkeit",
    "out_stok": "Nicht auf Lager",
    "edit_product": "Produkt bearbeiten",
    "create_product": "Erstellen Sie ein Produkt",
    "basic_info": "Grundlegende Informationen",
    "basic_sub_info": "Lorem Ipsum ist einfach ein Blindtext des Drucks ...",
    "enter_product_price": "Produktpreis eingeben",
    "upload_image": "Bild hochladen",
    "allowed_file_formats": "Es sind nur JPG- und PNG-Dateien mit einer maximalen Dateigröße von 3 MB zulässig.",
    "pre_select": "Vorwählen",
    "promotions": "Werbeaktionen",
    "discount_availability": "Rabattverfügbarkeit",
    "start_time": "Startzeit",
    "end_time": "Endzeit",
    "select_day": "Tag auswählen",
    "menu_required": "Menü ist erforderlich",
    "generated": "Generiert",
    "link": "Link",
    "variations": "Variationen",
    "select_variant_to_add": "Wählen Sie die Variante aus, die Sie hinzufügen möchten",
    "price_title": "Preis",
    "choose_image": "Bild auswählen",
    "select_visiblity": "Sichtbarkeit auswählen",
    "availability_schedule": "Verfügbarkeitsplan",
    "add_on_upsell": "Zusatzangebot \/ Upselling",
    "add_on_select_product": "Hinzufügen bei „Produkt auswählen“",
    "upsell_product": "Upsell Ausgewähltes Produkt",
    "variant_deletion_warning": "Diese Variante wird dauerhaft gelöscht.",
    "search_variations": "Suchvarianten",
    "add_variation": "Variation hinzufügen",
    "variation_text": "Variante",
    "select_customization": "Wählen Sie Anpassung",
    "add_new": "Neu hinzufügen",
    "delete_customization_warning": "Möchten Sie diese Anpassung aus dem Produkt löschen?",
    "nutritional_allergen_info": "Nährwert- und Allergeninformationen",
    "mark_item": "Artikel markieren",
    "calories_text": "Kalorien",
    "allergens": "Allergene",
    "select_allergens": "Allergene auswählen",
    "special_instructions": "Besondere Anweisungen",
    "preparation_instructions": "Zubereitungsanleitung",
    "staff_notes": "Anmerkungen des Personals",
    "checkbox1": "Ein Kontrollkästchen, um spezielle Anweisungen von Kunden für diesen Menüpunkt zuzulassen oder zu untersagen.",
    "menu_c": "SPEISEKARTE",
    "past_design": "Bisheriges Design",
    "file_name": "Dateiname",
    "variation_name": "Variantenname",
    "grid_items_title1": "Tischzelt",
    "grid_items_title2": "Digitales Menü",
    "grid_items_title3": "Menü zum Mitnehmen",
    "grid_items_title4": "Halbseitiges Menü",
    "grid_items_title5": "Digitales Menü 5",
    "grid_items_title6": "Digitales Menü 6",
    "grid_items_title7": "Digitales Menü 7",
    "grid_items_title8": "Digitales Menü 8",
    "grid_items_title9": "Digitales Menü 9",
    "enter_valid_form_name": "Geben Sie einen gültigen Formularnamen eingeben",
    "form_name_required": "Formularname ist erforderlich",
    "enter_valid_section": "Geben Sie einen gültigen Abschnittstitel eingeben",
    "fraction_half": "1\/2",
    "fraction_two_by_two": "2\/2",
    "form_name": "Formularname",
    "contact_list": "Kontaktliste",
    "label_notify_email": "Möchten Sie per E-Mail benachrichtigt werden?",
    "add_client_details_section": "Abschnitt „Clientdetails hinzufügen“",
    "demo_purpose_only": "Es dient nur zu Demozwecken",
    "form_updated_success": "Formular erfolgreich aktualisiert",
    "form_creat_success": "Formular erfolgreich erstellt",
    "add_custom_section": "Einen benutzerdefinierten Abschnitt hinzufügen",
    "edit_custom_section": "Einen benutzerdefinierten Abschnitt bearbeiten",
    "forms_list": "Liste der Formulare",
    "filled_custom_form_details": "Details zum ausgefüllten benutzerdefinierten Formular",
    "custom_form_deletion_warning": "Dieses benutzerdefinierte Formular wird dauerhaft gelöscht",

    "menu_link": "Menü-Link",
    "WebSignUpT": "Elektronische Anmeldung",
    "lifeTConT": "Lebenslange Verbindungen",
    "repCustT": "Stammkunden",
    "liftTSubT": "Lebenslange Abonnenten",
    "overallT": "GESAMT",
    "loginCustBDT": "Angemeldete Kunden nach Tag",
    "deviceT": "Demografische Daten zu Geräten",
    "ageTitle": "Altersdemografie",
    "ageT": "Altersgruppe",
    "convert_social_media_followers_into_customers": "Wandeln Sie Social-Media-Follower in Kunden um",
    "signWidDesc": "Erstellen Sie ein Widget zum Anmelden, Erstellen von Zielseiten und Generieren von Gutscheinen von Ihrer Website oder aus sozialen Medien",
    "qrWidT": "Intelligenter QR-Code",
    "imgWidT": "Opt-in-Nummer",
    "mobile": "Mobile",
    "YESTitle": "JA",
    "NOTitle": "NEIN",
    "addDelRed": "Lieferumleitung hinzufügen",
    "dlredNameT": "Geben Sie Ihrer Lieferumleitung einen Namen",
    "conf_text_req": "Bestätigungstext ist erforderlich",
    "cFDesc": "Erstellen Sie maßgeschneiderte Formulare, um effizient spezifisches Feedback und Informationen von Kunden zu sammeln",
    "pre_review_questions_enabled": "Bevor wir die Kunden um eine Bewertung bitten, stellen wir ihnen 1 bis 4 Fragen.",
    "feel_free_edit": "Sie können es bei Bedarf gerne bearbeiten.",
    "option": "Option",
    "confFL": "Diese Bestätigung wird angezeigt, nachdem Ihr Kontakt das Web-Anmeldeformular abgeschickt hat",
    "confT": "Bestätigungstext",
    "joinTitle": "Verbinden",
    "mobValidL": "Bitte überprüfen Sie die gültige Handynummer",
    "mobRequiredL": "Mobilnummer ist erforderlich",

    "Business_location_label": "Ihr Unternehmensstandort",
    "qr_code_selection_txt": "Qr-Code-Auswahl",
    "choose_theme_txt": "Thema auswählen",
    "edit_qr_details_txt": "QR-Details bearbeiten",
    "save_qr_code_txt": "QR-Code speichern",
    "text_color_label": "Textfarbe",
    "background_color_label": "Hintergrundfarbe",
    "print_txt": "Drucken",
    "customer_will_see_txt": "Dies ist, was Ihr Kunde sieht. Wählen Sie im nächsten Schritt Ihre Markenfarbe.",
    "choose_your_brand_color_&_langauge_title": "Wählen Sie Ihre Markenfarbe und -sprache",
    "custom_redirect_link_label": "Benutzerdefinierter Weiterleitungslink",
    "redirect_user_to_menu": "Benutzer zum Menü weiterleiten",
    "language": "Sprache",
    "select_language_placeholder": "Sprache auswählen",
    "capitalize_location_name_txt": "Standortname",
    "created_txt": "Erstellt",
    "swipe_reputation_management_txt": "Wischen Sie, um zu sehen, wie Ihr Reputationsmanagement aussehen wird",
    "sent_txt": "Gesendet",
    "number_label": "Nummer",
    "date_label": "Datum",
    "gender_demographics_title": "Geschlechtsdemografie",

    "step1_preview_desc": "Benennen Sie Ihr E-Signup-QR-Formular und fügen Sie es Ihrer Gruppenliste hinzu",
    "main_head": "Neue Kunden gewinnen",
    "main_desc": "Gewinnen Sie Kunden, indem Sie einen QR-Code platzieren, der Benutzer beim Scannen zu einem personalisierten Anmeldeformular weiterleitet.",
    "step1_description": "Gewinnen Sie neue Kunden aus anderen Unternehmen",
    "form_name_field_placeholder": "Restro E-Anmeldung",
    "last_step_title": "Glückwunsch! Ihr E-Signup-Widget wurde erstellt! 🎉",
    "last_step_desc": "Wischen Sie, um zu sehen, wie Ihr E-Anmeldeformular aussehen wird",
    "events_step1_preview_desc": "Geben Sie Ihrem Event-QR-Formular einen Namen und fügen Sie es zu Ihrer Gruppenliste hinzu",
    "events_main_head": "Gewinnen Sie Kunden durch Events",
    "events_main_desc": "Gewinnen Sie Kunden, indem Sie bei der Veranstaltung einen QR-Code platzieren. Beim Scannen werden die Benutzer zu einem personalisierten Anmeldeformular weitergeleitet.",
    "events_step1_desc": "Gewinnen Sie Kunden bei Events und bringen Sie sie in den Laden",
    "events_name": "Ereignisname",
    "events_form_name_field_placeholder": "Restro-Spaß und Food-Event",
    "events_last_step_title": "Glückwunsch! Ihr Veranstaltungsformular wurde erstellt! 🎉",
    "events_last_step_desc": "Wischen Sie, um zu sehen, wie Ihr Veranstaltungsformular aussehen wird",
    "birthday_anniversary_step1_preview_desc": "Benennen Sie Ihr QR-Formular, um den Geburtstag oder Jahrestag des Kunden zu erfassen, und fügen Sie es Ihrer Gruppenliste hinzu",
    "birthday_anniversary_main_head": "Sammeln Sie zum Geburtstag oder Jahrestag",
    "birthday_anniversary_main_desc": "Erfassen Sie den Geburtstag oder Jahrestag des Kunden. Beim Scannen werden die Benutzer zu einem personalisierten Anmeldeformular weitergeleitet.",
    "birthday_anniversary_step1_desc": "Erfassen Sie Geburtstage und Jubiläen von Kunden",
    "restro_text": "Restro",
    "birthday_anniversary_last_step_title": "Glückwunsch! Formular „Geburtstag & Jahrestag von Kunden“ erstellen! 🎉",
    "newspaper_ads_step1_preview_desc": "Geben Sie Ihrem QR-Formular einen Namen, um Kunden über Zeitungsanzeigen zu gewinnen, und fügen Sie es zu Ihrer Gruppenliste hinzu",
    "newspaper_ads_main_head": "Gewinnen Sie Kunden durch Anzeigen",
    "newspaper_ads_main_desc": "Gewinnen Sie Kunden durch Zeitungsanzeigen. Beim Scannen werden die Benutzer zu einem personalisierten Anmeldeformular weitergeleitet.",
    "newspaper_ads_step1_desc": "Gewinnen Sie Kunden durch Zeitungsanzeigen oder Außenwerbung",
    "newspaper_ads_last_step_title": "Glückwunsch! Formular „Kunden durch Zeitungsanzeigen gewinnen“ erstellt! 🎉",
    "smart_pamphlets_step1_preview_desc": "Benennen Sie Ihr QR-Formular, um Kundendaten aus Broschüren abzurufen und es zu Ihrer Gruppenliste hinzuzufügen",
    "smart_pamphlets_main_head": "Gewinnen Sie Kunden durch Broschüren",
    "smart_pamphlets_main_desc": "Holen Sie sich Kundendaten aus Broschüren. Wenn diese gescannt werden, werden die Benutzer zu einem personalisierten Anmeldeformular weitergeleitet.",
    "smart_pamphlets_last_step_title": "Glückwunsch! Holen Sie sich Kundendaten aus dem erstellten Broschürenformular! 🎉",
    "social_media_step1_preview_desc": "Geben Sie Ihrem QR-Formular einen Namen, um Kunden aus sozialen Medien zu gewinnen und es zu Ihrer Gruppenliste hinzuzufügen.",
    "social_media_main_desc": "Gewinnen Sie Kunden über soziale Medien. Wenn diese Beiträge gescannt haben, werden die Benutzer zu einem personalisierten Anmeldeformular weitergeleitet.",
    "social_media_form_name_field_placeholder": "Restro Festival-Angebot – Facebook-Beitrag",
    "social_media_last_step_title": "Glückwunsch! Erstellen Sie ein Social-Media-Formular für Kunden! 🎉",
    "claim_offer_text": "Füllen Sie Ihre Daten aus und fordern Sie das Angebot jetzt an",
    "form_title_description": "Fügen Sie einen Titel und eine Beschreibung des Formulars hinzu, damit Ihre Kunden die Details eingeben können",
    "no_subscribed_clients": "Die ausgewählte Kundengruppe hat keine Abonnenten",
    "welcome_message_offer": "Senden Sie Ihren Kunden nach der Anmeldung eine Willkommensnachricht und geben Sie ihnen einen Angebotscoupon",
    "system_grp": "Systemgruppen",
    "import_clients": "Kunden importieren",
    "delivery_redirection_name": "Name der Zustellungsumleitung",
    "created_at": "Erstellt am",
    "provide_stamp_user": "Stempel an Benutzer übermitteln",
    "scan_qr_code": "QR-Code scannen",
    "add_point": "Punkt hinzufügen",
    "total_points": "Gesamtpunktzahl",
    "user_profile": "Benutzerprofil",
    "stamp_history": "Briefmarkengeschichte",
    "reward_txt": "Belohnen",
    "variant_placeholder": "Klein, Mittel, Groß usw.",
    "sent_coupon": "Gutschein gesendet",
    "order_status": "Bestellstatus",
    "payment_status": "Zahlungsstatus",
    "order_currency": "Bestellwährung",
    "card_data": "Kartendaten",
    "reward_expiring_on": "Belohnung läuft ab am",
    "reward_status": "Belohnungsstatus",
    "reward_get_date": "Datum für den Erhalt der Belohnung",
    "reward_name": "Belohnungsname",
    "add_point_type": "Punkttyp hinzufügen",
    "restaurant_location": "Restaurantstandort",
    "collect_points": "Punkte sammeln",
    "rewards_expire": "Belohnungen verfallen",
    "rewards_redeem": "Prämien einlösen",
    "total_rewards": "Gesamtbelohnungen",
    "created_by": "Erstellt von",
    "anniversary": "Jubiläum",
    "remove_user_group_txt": "Benutzer aus Gruppe entfernen",
    "delete_permanently_grp_msg": "Dieser Client wird dauerhaft aus der Gruppe gelöscht.",
    "delete_multiple_permanently_grp_msg": "Diese Kunden werden dauerhaft aus der Gruppe gelöscht.",
    "delete_multiple_client": "Diese Kunden werden dauerhaft gelöscht.",
    "messages": "Nachrichten",
    "management": "Management",
    "client": "Kunde",
    "country": "Land",
    "state": "Zustand",
    "city": "Stadt",
    "zip_code": "PLZ",
    "1_sparkly_point_txt": "🎉 Du hast 1 Sparkly-Punkt verdient! 🌟",
    "select_country_txt": "Länderauswahl",
    "select_state": "Bundesland auswählen",
    "ls_thanks_for_join": "Vielen Dank, dass Sie sich für unsere Treuekarte angemeldet haben.🎁🎈",
    "month": "Monat",
    "welcome_message": "Senden Sie Ihren Kunden nach der Anmeldung eine Willkommensnachricht",
    "all_rewards_txt": "Alle Belohnungen",
    "active_rewards": "Aktive Belohnungen",
    "expired_and_redeemed": "Abgelaufen und eingelöst",
    "redirect_3rd_party_delivery": "Leiten Sie die Lieferung durch Drittanbieter an Ihr Geschäft oder Ihre Online-Bestellplattform weiter.",
    "search_or_start_new": "Suchen oder starten Sie eine neue",
    "contacts": "Kontakte",
    "chats": "Chats",
    "add_by_restaurant": "Nach Restaurant hinzufügen",
    "using_scan_code": "Scan-Code verwenden",
    "step": "Schritt",
    "list_widget_text": "Liste der Widgets",
    "swipe_free_wifi_txt": "Wischen Sie für kostenloses WLAN",
    "create_widget_txt": "Widget erstellen",
    "redeem_award_msg": "Sind Sie sicher? Möchten Sie diese Prämie einlösen?",
    "on_collect_msg": "Beim Sammeln",
    "pause": "Pause",
    "resume": "Wieder aufnehmen",
    "location_added": "Standort hinzugefügt",
    "sub_user_admin_permissions_txt": "Untergeordneten Benutzern den Zugriff auf Administratorrechte in Digital Signage erlauben",
    "unlist": "Aus der Liste entfernen",
    "cannot_delete_default_terms": "Standardmäßige Geschäftsbedingungen können nicht gelöscht werden",
    "something_went_wrong": "Etwas ist schiefgelaufen!",
    "card_download_success": "Karte erfolgreich heruntergeladen",
    "permission_denied": "Zugriff verweigert!",
    "invalid_qr_code": "Bitte scannen Sie einen gültigen QR-Code",
    "select_customer": "Bitte wählen Sie den Kunden",
    "online": "ONLINE",
    "offline": "OFFLINE",
    "no_data": "Hoppla, keine Daten gefunden!",
    "forever_free": "Für immer kostenlos",

};

export default de