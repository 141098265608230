const da = {
    "rated_google_reviews": "Bedømt 5,0 på Google Anmeldelser",
    "get_your_digital_menu": "Få din digitale menu—",
    "instant_popup_menu": "Øjeblikkelig pop op-menu uden QR-scanning",
    "free_forever": "Gratis for evigt",
    "the_future_is_here": "Fremtiden er her!",
    "upgrade_your_restaurant": "Opgrader din restaurant med en fantastisk digital menu uden omkostninger. Tiltræk flere kunder fra og med i dag.",
    "get_my_free_menu_now": "Få min gratis menu nu",
    "no_credit_card_required": "*Ingen kreditkort påkrævet",
    "join_largest_marketplace": "Deltag i den største restaurantmarkedsplads!",
    "what_will_you_get": "Hvad får du",
    "free": "GRATIS",
    "free_digital_menu": "Gratis digital menu 2D eller 3Din",
    "free_digital_menu_desc": "Sig farvel til papirmenuer og dyre genoptryk",
    "quick_and_easy_signup": "Hurtig og nem tilmelding",
    "communicate_with_customers_title": "Kommuniker med kunder GRATIS!",
    "features1": [
        "Spar på udskrivningsomkostninger, og nyd øjeblikkelige menuopdateringer, når du har brug for dem.",
        "Få adgang til din digitale menu på enhver enhed",
        "Miljøvenlig og problemfri"
    ],
    "features2": [
        "Lad kunderne virtuelt udforske dine retter med forbløffende 3D-billeder.",
        "Øg engagementet ved at give gæsterne en realistisk forhåndsvisning af deres måltid.",
        "Skil dig ud med en menuoplevelse, der føles lige så tillokkende, som din mad smager."
    ],
    "features3": [
        "Hils gæster med det samme med din menu, når de kommer ind i restauranten.",
        "Send automatisk opdateringer om tilbud og kampagner til deres telefoner.",
        "Forbedre madoplevelsen med hurtig, kontaktløs adgang til din menu."
    ],
    "feature4": [
        "Fremhæv sæsonbestemte tilbud, daglige tilbud og eksklusive kampagner.",
        "Henled kundernes opmærksomhed på dine mest populære varer.",
        "Skab haster og spænding med iøjnefaldende billeder og beskrivelser."
    ],
    "feature5": [
        "Bliv en del af et online-fællesskab med tusindvis af daglige besøgende.",
        "Øg synlighed og nå ud til nye kunder, der aktivt søger efter spisemuligheder.",
        "Sikre dig en førsteplads tidligt for at maksimere din eksponering og tiltrække flere spisende gæster."
    ],
    "section3_heading": "Hvorfor gå digitalt? Fordi det er gratis og nemt!",
    "section3_description": "Lås op for kraftfulde funktioner, der er designet til at øge din restaurants appel.",
    "instant_menu": "Øjeblikkelig menu",
    "instant_menu_notification": "Pop-up og notifikation",
    "instant_menu_description": "Byd gæster velkommen med øjeblikkelig menuadgang, så snart de kommer ind. Send automatisk den seneste menu og tilbud direkte til deres mobiltelefoner",
    "digital_menu": "Digitale menuer",
    "at_zero_cost": "til nul omkostninger",
    "digital_menu_description1": "Sig farvel til papirmenuer og dyre genoptryk. Skift til en digital menu, der altid er opdateret – og den er gratis for evigt!",
    "digital_menu_description2": "Med vores digitale menu er dine tilbud altid aktuelle og tilgængelige på enhver enhed. Spar penge og forenkle menuopdateringer med en problemfri, nul-omkostningsløsning.",
    "try_me_button": "Prøv mig",
    "try_3d_menu_title": "3D menuer",
    "try_3d_menu_title2": "Gratis - WOW!",
    "try_3d_menu_description1": "Få dine retter til at springe ud af skærmen. Upload 3D-billeder, og lad kunderne virtuelt smage din mad, før de går ind.",
    "try_3d_menu_description2": "3D-billeder af høj kvalitet skaber et mundvandsindtryk, der forbedrer appel og opmuntrer ordrer.",
    "try_now": "Prøv nu",
    "showcase_title": "Fremvis din",
    "showcase_specials": "Tilbud",
    "showcase_specials_description1": "Har du en lækker aftale eller en uovertruffen kampagne? Fremhæv det på din digitale menu, og træk flere sultne mennesker ind.",
    "showcase_specials_description2": "Gør kampagner umulige at gå glip af, og træk opmærksomhed med dristige billeder og lokkende beskrivelser.",
    "marketplace": "Markedsplads",
    "marketplace_description": "Vi pisker en markedsplads op med over 2.000 daglige besøgende. Hop ombord nu, så er du først i køen, når vi lancerer. Du kan nå ud til nye kunder, der aktivt leder efter spisemuligheder. Sikre din plads tidligt for at maksimere eksponeringen!",
    "see_other_products": "Se andre produkter",
    "about_us": "Om os",
    "why_serving_free": "Hvorfor vi serverer dette for",
    "serving_free_highlight": "Gratis",
    "about_desc": "Vi er lokale folk lige her i Dallas, forpligtet til at hjælpe restauranter som din med at trives.",
    "about_tool_free": "Vi tilbyder dette kraftfulde værktøj helt gratis, fordi vi tror på at støtte vores fællesskab.",
    "no_catch": "Ingen fangst, ingen sjov. Vi ved, at når du lykkes, bliver vores fællesskab stærkere. 😊",
    "understanding_challenges": "Vi forstår udfordringerne ved at drive en virksomhed, og det er grunden til, at vi er dedikerede til at levere værktøjer, der gør din drift smidigere og din rækkevidde bredere.",
    "community_growth": "Efterhånden som du vokser, vokser vores samfund med dig, hvilket skaber en ringvirkning af succes og støtte. Lad os sammen løfte madoplevelsen for alle.",
    "how_it_works": "Hvordan det virker",
    "three_simple_steps": "Tre enkle trin til",
    "get_started_highlight": "Kom i gang",
    "no_tech_skills_needed": "Ingen tekniske færdigheder nødvendige. Hvis du kan vende en burger, kan du gøre dette!",
    "step1_title": "Vis din kampagne GRATIS!",
    "step1_desc": "Kom i gang hurtigere, end du kan sige \"Bestil op!\" Bare udfyld en simpel formular.",
    "step2_title": "Tilpas din menu",
    "step2_desc": "Tilføj dine retter, upload billeder selv i 3D og fremhæv dine specialiteter.",
    "step3_title": "Del og vækst",
    "step3_desc": "Del din digitale menu via QR'er, sociale medier eller din hjemmeside.",
    "testimonials": "Udtalelser",
    "testimonials_section_title": "Tag ikke bare vores ord for det",
    "testimonials_section_desc": "Se, hvad andre Dallas-restaurantejere siger.",
    "faq_section_title": "Har du spørgsmål? Vi har svar",
    "faq_section_desc": "Alt hvad du behøver at vide, før du går i gang.",
    "faqs": "Ofte stillede spørgsmål",
    "faq_answers": [
        {
            "que": "Er det virkelig gratis for evigt?",
            "ans": "Absolut! Ingen skjulte gebyrer, ingen kreditkort, ingen bindinger."
        },
        {
            "que": "Har jeg brug for teknisk viden?",
            "ans": "Hvis du kan sende en e-mail, kan du gøre dette. Så enkelt er det."
        },
        {
            "que": "Hvorfor tilbyder du dette gratis?",
            "ans": "Vi tror på at give først. Nede i linjen har vi måske andre tjenester, du vil elske - men det er helt op til dig."
        },
        {
            "que": "Hvad er der med markedspladsen?",
            "ans": "Vi koger det op! Når vi har nok restauranter om bord, lancerer vi din markedsplads for at give dig endnu mere eksponering."
        }
    ],
    "testimonials_content1": "At skifte til en digital menu var det bedste træk, vi gjorde! Vores kunder elsker bekvemmeligheden og det opdaterede udseende!",
    "testimonials_name1": "Morris George",
    "testimonials_restaurant_name1": "The Mansion Restaurant",
    "testimonials_content2": "Vores nye digitale menu gør browsing nemmere for gæster, og det har virkelig boostet engagementet!",
    "testimonials_name2": "Amelia Johnson",
    "testimonials_restaurant_name2": "Urban spiser",
    "testimonials_content3": "Gæster bliver imponeret over 3D-visualiteten – det har hjulpet os med at skille sig ud og forbedret deres madoplevelse!",
    "testimonials_name3": "Davis Green",
    "testimonials_restaurant_name3": "Byens ildsted",
    "ready_to_boost_your_restaurant": "Klar til at booste din restaurant?",
    "ready_to_boost_desc": "Bliv ikke efterladt. Deltag i den digitale revolution i dag!",
    "yes_i_want_my_free_digital_menu": "Ja, jeg vil have min gratis digitale menu",
    "480_plus": "480+",
    "people_already_joined": "folk, der allerede har tilsluttet sig",
    "menu_online_home": "Menu Online Hjem",
    "welcome": "Velkomst",
    "description_other": "Lad os øge din kundetilfredshed!",
    "benefits": "Fordele",
    "greeting_text": "Velkommen 👋",
    "all_in_one_features": "🚀 Alt-i-én-funktioner til at løfte din restaurant",
    "menu_online_menu_name": "Menu online",
    "menu_online_description": "Digitaliser din menu for nem onlineadgang for kunderne.",
    "menu_online_benefits": "Forbedre kundernes bekvemmelighed og øge engagementet ved at give dem mulighed for at se din menu når som helst og hvor som helst.",
    "ds_name": "Digital skiltning",
    "ds_description": "Vis tilbud og kampagner på dynamiske skærme i butikken.",
    "ds_benefits": "Fang kundernes opmærksomhed med engagerende billeder, promover mersalg og realtidstilbud uden besvær.",
    "loyalty_punch_name": "Loyalitets hulkort",
    "loyalty_punch_description": "Beløn ​​gentagne kunder med et digitalt loyalitetsprogram.",
    "loyalty_punch_benefits": "Skab kundefastholdelse og øg salget ved at tilskynde til loyalitet med indløselige belønninger for hyppige besøg.",
    "marketing_menu_name": "Markedsføring",
    "marketing_description": "Nå ud til flere kunder med målrettede digitale marketingkampagner.",
    "marketing_benefits": "Udvid din restaurant rækkevidde, og få flere besøgende med brugerdefinerede kampagner, der er skræddersyet til at tiltrække nye og tilbagevendende kunder.",
    "get_more_reviews_menu_name": "Få flere anmeldelser",
    "get_more_reviews_description": "Tilskynd tilfredse kunder til at efterlade positive onlineanmeldelser.",
    "get_more_reviews_benefits": "Øg din restaurant troværdighed og tiltræk nye spisende gæster ved at forbedre dit online omdømme med flere positive anmeldelser.",
    "guest_smart_wifi_menu_name": "Gæst Smart Wifi",
    "guest_smart_wifi_description": "Tilbyd gratis Wi-Fi til at fange gæstedata til markedsføring.",
    "guest_smart_wifi_benefits": "Øg kundetilfredsheden, mens du indsamler værdifulde kundedata for at tilpasse fremtidige kampagner.",
    "reservation_menu_name": "Reservation",
    "reservation_description": "Giv kunderne mulighed for nemt at reservere bord online.",
    "reservation_benefits": "Forbedre madoplevelsen ved at minimere ventetider og strømline reservationer for bedre at administrere siddepladser.",
    "pos_integration_menu_name": "POS-integration",
    "pos_integration_description": "Integrer vores system med dit POS-system.",
    "pos_integration_benefits": "Forenkle ordrebehandlingen og reducere fejl ved at holde dine online- og butikssystemer synkroniseret.",
    "online_ordering_menu_name": "Online bestilling",
    "online_ordering_description": "Giv kunderne mulighed for at afgive ordrer direkte fra dit websted eller din app.",
    "online_ordering_benefits": "Øg salget og reducer afhængigheden af ​​tredjepartsplatforme ved at tilbyde en direkte bestillingsmulighed, der sparer gebyrer.",
    "pro_label": "Pro",
    "coming_soon": "Kommer snart",
    "locked_label": "Låst",
    "forever_label": "For altid",
    "new_label": "Ny",
    "other_products_page_title": "Andre produkter | Menu online",
    "other_products_description": "Menu Online Privatlivspolitik",
    "other_products_header_title": "Andre produkter",
    "privacy_policy": {
        "title": "Privatlivspolitik",
        "lastUpdated": "Sidst opdateret: 1. juli 2024",
        "introduction": {
            "header": "Privatlivspolitik for nye og eksisterende kunder",
            "paragraph1": "DE FØLGENDE NØGLEPUNKTER (INKLUSIVE AFSNITSHENVISNINGER) ER KUN TILGIVET FOR DIN GIVELSE OG ER IKKE I STEDET FOR DE FULDSTÆNDIGE VILKÅR OG BETINGELSER. DET ER DIT ANSVAR AT LÆSE DISSE VILKÅR OG BETINGELSER, FØR DU BRUGER MENUONLINE INC SERVICE.",
            "paragraph2": "Brug af Menuonline betyder, at du accepterer vilkårene og betingelserne og repræsenterer og garanterer, at du er autoriseret af din organisation eller virksomhed til at gøre det. (Se s.3 \"Sådan virker det\")",
            "paragraph3": "Menuonline Inc er en tilladelsesbaseret marketingtjeneste. Forsætlig afsendelse af \"spam\" eller overtrædelse af vilkår og betingelser vil resultere i lukning af din konto. (Se s.16 \"Acceptabel brug af tjenesten\")",
            "paragraph4": "Data, du hoster i Menuonline Inc, såsom kontaktoplysninger, e-mailindhold og oplysninger, du indsamler gennem Menuonline Inc., tilhører dig. (Se s.14 \"Hvad ejer du?\").",
            "paragraph5": "Menuonline Inc-softwaren og indholdet på vores hjemmeside tilhører os. (Se s.13 \"Hvad ejer vi?\") Bortset fra dine egne brugere, kan de data eller det upublicerede indhold, du hoster hos Menuonline Inc, kun ses af Menuonline Inc's medarbejdere og partnere, som har underskrevet fortrolighedsaftaler. (Se s.14 \"Hvad ejer du?\")",
            "paragraph6": "Alle personligt identificerbare oplysninger (PII) opbevares kun på servere i Canada. (Se s.14 \"Hvad ejer du?\").",
            "paragraph7": "Menuonline Inc-tjenesten er underlagt lovene i Canada og provinsen Ontario. (Se s.24 \"Gældende lov\")",
            "paragraph8": "Enhver anmodning om dine data eller indhold fra enkeltpersoner, politi eller andre reguleringsorganer fra Canada eller uden for Canada vil altid blive henvist til dig. Den eneste undtagelse ville være, hvis dette sætter os i strid med canadisk eller Ontario lov; dog vil vi i alle tilfælde ihærdigt modstå enhver frigivelse af dine data fra andre end dig. (Se s.14 \"Hvad ejer du?\").",
            "paragraph9": "Menuonline Inc. sikkerhed er kun så god som din administration af brugernavne og adgangskoder. Vi opkræver ikke betaling for Menuonline Inc-brugerkonti, så opret en separat for hver bruger og hold adgangskoder hemmelige. Du er ansvarlig for din konto. (Se s.3 \"Sådan virker det\")",
            "paragraph10": "Du kan til enhver tid annullere din konto. Vi refunderer ikke ubrugt kredit, hvis du annullerer. (Se s.3 \"Sådan virker det\")"
        },
        "termsAndConditions": {
            "title": "MENUONLINE INC VILKÅR OG BETINGELSER",
            "lastUpdate": "SIDSTE OPDATERING: 14. januar 2020",
            "paragraph1": "Læs venligst disse vilkår og betingelser for service (“Betingelserne”) omhyggeligt, før du bruger denne hjemmeside (“Siden”). Disse vilkår fritager Menuonline Inc. (\"Menuonline Inc\" \"vi\" eller \"os\") og andre fra ansvar og\/eller begrænser vores og deres ansvar og indeholder andre vigtige bestemmelser, der gælder for din brug af dette websted. Ved at besøge eller bruge dette websted eller tjenesten accepterer du på dine egne vegne og på vegne af enhver organisation, på hvis vegne du kan handle (samlet heri omtalt som \"dig\"), at acceptere og overholde disse vilkår for hver brug af og hvert besøg på dette websted."
        },
        "services": {
            "title": "Vores tjenester.",
            "paragraph": "De tjenester, som vi tilbyder via webstedet, er en tjeneste, der giver dig mulighed for at oprette, sende og administrere e-mails (\"E-mails\") til modtagere efter eget valg (\"tjenesten\")."
        },
        "license": {
            "title": "Licens.",
            "paragraph": "I henhold til disse vilkår giver Menuonline Inc. dig hermed en ikke-eksklusiv, begrænset, ikke-overførbar licens i løbet af perioden til at se webstedet og bruge tjenesten som tilladt af funktionerne i tjenesten. Vi forbeholder os alle rettigheder, der ikke udtrykkeligt er givet heri, til tjenesten og Menuonline Inc-indholdet (som defineret nedenfor)."
        },
        "emailingContent": {
            "title": "E-mail med indhold.",
            "paragraph": "Når du interagerer med Menuonline Inc via dette websted eller tjenesten, accepterer du at give sande og nøjagtige oplysninger. Derudover forstår du, at det er en usikker praksis at give fortrolige oplysninger via e-mail. Du forstår, at du er eneansvarlig for alt, hvad du sender til enhver, der bruger vores Tjeneste."
        },
        "support": {
            "title": "Støtte.",
            "paragraph": "Vi giver dig support til at hjælpe dig med at blive selvforsynende med Tjenesten. Vi giver dig support i form af dokumentation og videoer på webstedet, Menuonline Incs supportbilletsystem og telefonisk. Du kan nå os ved at klikke på fanen \"Support\" øverst på siden. Der er ingen omkostninger for produktsupport; vi forbeholder os dog retten til at afbryde support til specifikke kundekonti efter vores skøn."
        }
    },
    "terms_condition": {
        "title": "Servicevilkår | Menu online",
        "header": "Vilkår og betingelser for nye og eksisterende kunder"
    },
    "privacy_policy_title": "PRIVAT POLITIK",
    "privacy_policy_effective_date": "Gælder fra 16. januar 2020",
    "privacy_policy_general_title": "GENEREL PRIVAT POLITIK",
    "privacy_policy_intro": "menuonline.com (Menuonline og \"os\") forstår vigtigheden af ​​beskyttelsen af ​​privatlivets fred for enkeltpersoners personlige oplysninger og vigtigheden af ​​sikkerheden for fortrolige oplysninger af alle typer. Vi har oprettet denne private politik (\"politikken\") for at demonstrere vores forpligtelse til at beskytte dit \"privatliv\". Denne privatpolitik forklarer:",
    "privacy_policy_points": [
        "Hvilke personlige oplysninger vi indsamler, og hvorfor vi indsamler dem;",
        "Hvordan vi bruger dine personlige oplysninger;",
        "Hvem vi deler dine personlige oplysninger med; og",
        "De valg, du har for at få adgang til, opdatere og fjerne dine personlige oplysninger fra tjenesten."
    ],
    "privacy_policy_pipeda": "Menuonline administrerer indsamlingen af ​​alle oplysninger i overensstemmelse med Canadas lov om beskyttelse af personlige oplysninger og elektroniske dokumenter (PIPEDA).",
    "privacy_policy_update_notice": "Bemærk venligst, at Menuonline forbeholder sig retten til at opdatere eller ændre denne privatpolitik til enhver tid. Vi vil underrette dig om eventuelle ændringer ved at offentliggøre en opdateret politik på denne hjemmeside eller som gjort tilgængelig via tjenesterne. Den seneste version af denne politik vil regulere mycircles brug af brugernes personlige oplysninger. Ved at fortsætte med at få adgang til mycircles hjemmeside eller ved at fortsætte med at bruge dets tjenester efter ændringer er foretaget i denne Privatpolitik, accepterer du at være bundet af de reviderede vilkår.",
    "privacy_policy_consent_title": "SAMTYKKE TIL PERSONOPLYSNINGER",
    "privacy_policy_consent_text": "Ved at give Menuonline dine personlige oplysninger via vores hjemmeside eller en hvilken som helst af vores formularer, giver du dit samtykke til indsamling, brug og videregivelse af sådanne oplysninger i overensstemmelse med denne politik eller til de formål, der blev identificeret for dig på det tidspunkt, hvor du gav de personlige oplysninger. Enhver sekundær brug af personoplysningerne vil kun blive behandlet med dit udtrykkelige samtykke, eller du vil få mulighed for at fravælge.",
    "privacy_policy_opt_out_text": "Du har til enhver tid mulighed for at trække dit samtykke tilbage eller framelde dig. Hvis du ønsker at trække dit samtykke tilbage eller fravælge, kan du klikke på linket kommunikationspræferencer nederst i enhver e-mail, du modtager fra os.",
    "privacy_policy_info_collection_title": "INFORMATION VI SAMLER",
    "privacy_policy_info_collection_text": "Menuonline indsamler og behandler personoplysninger i overensstemmelse med denne politik. Vi vil ikke sælge, dele eller udleje disse oplysninger til andre, undtagen hvad der er redegjort for i denne politik. Menuonline indsamler personlige oplysninger med det formål at levere vores tjenester eller produkter til dig.",
    "privacy_policy_info_types_text": "De typer oplysninger, som Menuonline indsamler, omfatter:",
    "privacy_policy_account_holders_title": "Menuonline kontoindehavere",
    "privacy_policy_registration_info": "Registreringsoplysninger: Hvis du tilmelder dig for at bruge tjenester, skal du have en Menuonline-konto for at blive kontoindehaver (\"Kontoindehaver\"). Når du tilmelder dig en konto, vil du blive bedt om at give visse grundlæggende oplysninger, såsom dit navn, e-mailadresse, brugernavn, adgangskode, firmanavn, stilling, placering og telefonnummer. Adgangskoder er krypteret - Menuonline-personale er ikke i stand til at se eller hente adgangskoder.",
    "privacy_policy_billing_info": "Faktureringsoplysninger: Hvis du abonnerer på vores tjenester, skal du give os betalings- og faktureringsoplysninger.",
    "privacy_policy_cookies_info": "Cookies: Vi bruger cookies til at spore besøgendes data, såsom kilden til webstedsbesøg og antallet af webstedsbesøg på vores websted.",
    "privacy_policy_ip_info": "IP-adresser: IP-adresser indsamles om dig som en del af din brug af tjenesterne (f.eks. med det formål at registrere placering på landeniveau og IP-adresser, der bruges, når du bruger vores tjenester).",
    "privacy_policy_your_contacts_title": "Dine kontakter",
    "privacy_policy_distribution_list": "Distributionsliste: Som en del af din brug af vores tjenester kan du give os, eller vi kan indsamle som en del af tjenesterne, personlige oplysninger om dine kontakter (\"Kontakter\"). Disse oplysninger omfatter e-mailadresser og kan omfatte andre identificerbare oplysninger såsom navn, firmanavn eller andre oplysninger, du giver til Menuonline, mens du bruger tjenesterne. Du skal sikre dig, at du opnår det passende samtykke fra dine kontakter til at tillade, at deres oplysninger behandles af os.",
    "privacy_policy_billing_info_contacts": "Faktureringsoplysninger: Hvis du abonnerer på vores tjenester, skal du give os betalings- og faktureringsoplysninger.",
    "privacy_policy_cookies_contacts": "Cookies: Menuonline vil kun bruge cookies og lignende sporingsteknologier til at indsamle og bruge personlige oplysninger om dine kontakter, hvis du har tilføjet Menuonline-sporingskoden til din hjemmeside. Alle landingssider hostet af Menuonline vil automatisk have en sporingskode.",
    "privacy_policy_ip_contacts": "IP-adresser: IP-adresser indsamles om dine kontakter som en del af tjenesterne (f.eks. med det formål at registrere placering på landeniveau og de IP-adresser, der bruges ved indsendelse af en formular og afgivelse af samtykke).",
    "privacy_policy_info_about_contacts_title": "INFORMATION VI SAMLER OM DINE KONTAKTER",
    "privacy_policy_info_about_contacts_notice": "*Dette afsnit gælder kun for oplysninger indsamlet, brugt eller videregivet af Menuonline om en kontoindehavers kontakter med det formål at levere tjenesterne*",
    "privacy_policy_info_about_contacts_desc": "Menuonline vil kun indsamle, bruge eller videregive personlige oplysninger om kontakter, hvis kontoindehaveren har tilmeldt sig visse tjenester, hvor de giver os en distributionsliste. Kontoindehavere er alene ansvarlige for at sikre, at de har overholdt alle gældende love, når de giver personlige oplysninger om deres kontakter til Menuonline gennem tjenesterne.",
    "privacy_policy_info_auto_collect_title": "Oplysninger Menuonline indsamler automatisk",
    "privacy_policy_info_auto_collect_desc1": "Når kontoindehavers kontaktpersoner engagerer sig i en e-mail-kampagne modtaget fra en kontoindehaver, eller interagerer med et link til en kontoindehavers tilsluttede hjemmeside, kan Menuonline indsamle oplysninger om en sådan kontaktpersons enhed og interaktion med e-mailen ved brug af cookies. Disse oplysninger indsamles kun for at levere tjenesterne og for at give kontoindehaveren indsigt.",
    "privacy_policy_info_auto_collect_desc2": "Oplysninger, vi indsamler om en kontakts enhed og de applikationer, som kontakten bruger til at få adgang til e-mails sendt fra kontoindehaveren gennem vores tjenester, kan omfatte kontaktens IP-adresse, operativsystem, browser-id og andre relaterede oplysninger om kontaktens system og forbindelse.",
    "privacy_policy_info_auto_collect_desc3": "Oplysninger, vi indsamler om Contacts interaktion med e-mails, der sendes gennem tjenesterne, kan omfatte datoer og tidspunkter, hvor Contact får adgang til e-mail- og browsingaktiviteter, og oplysninger om udførelsen af ​​tjenesterne, såsom leveringsevnen af ​​e-mails og anden kommunikation sendt til Kontakt af en kontoindehaver gennem tjenesterne.",
    "privacy_policy_info_auto_collect_desc4": "Bemærk venligst, at Menuonline ikke vil kontakte nogen af ​​dine kontakter, medmindre det kræves af gældende lovgivning.",
    "privacy_policy_why_we_collect_title": "HVORFOR SAMLER VI DINE OPLYSNINGER",
    "privacy_policy_why_we_collect_desc": "Menuonline indsamler oplysninger til følgende formål:",
    "privacy_policy_why_collect_1": "at give Menuonline-kontoindehavere de tjenester, de abonnerer på, herunder at bruge kontoindehaveroplysninger til tjenesterne;",
    "privacy_policy_why_collect_2": "til administration og kontostyring, samt adgang til mycircles hjemmeside;",
    "privacy_policy_why_collect_3": "hvis du har givet samtykke til at modtage markedsførings- og reklamemateriale, for at informere dig om andre produkter og tjenester, der tilbydes af Menuonline; og",
    "privacy_policy_why_collect_4": "for at svare på kontoindehavers kommunikation eller for at sende oplysninger, som du anmoder om. Dette kan omfatte, men er ikke begrænset til:",
    "privacy_policy_why_collect_5": "særlige tilbud og opdateringer;",
    "privacy_policy_why_collect_6": "nyhedsbrev; og",
    "privacy_policy_why_collect_7": "servicemeddelelser.",
    "privacy_policy_opt_out_desc": "Du kan til enhver tid vælge at fravælge at modtage kommunikation ved at klikke på afmeld-knappen i bunden af ​​hver e-mail, der sendes fra Menuonline til dig.",
    "privacy_policy_use_disclosure_title": "BRUG OG OFFENTLIGGØRELSE AF PERSONOPLYSNINGER",
    "privacy_policy_use_disclosure_desc1": "Personlige oplysninger afgivet til Menuonline, herunder via Menuonlines hjemmeside eller en foreskrevet formular, holdes strengt fortrolige, medmindre de videregives i overensstemmelse med en kontoindehavers udtrykkelige samtykke eller lovkrav. Under alle omstændigheder er enhver personlig information, der gives på enhver \"Menuonline\"-platform af kontoindehavere, frivillig: enhver personlig information, der gives af en kontoindehaver, indsendes efter kontoindehaverens skøn. Bemærk venligst, at tilbagekaldelse af dit samtykke kan resultere i, at du ikke kan få adgang til eller bruge visse Menuonline-tjenester.",
    "privacy_policy_use_disclosure_desc2": "Menuonline bruger tredjeparter og eksterne tjenesteudbydere med hensyn til sin hjemmeside og tjenester. Kontoindehaverens personlige oplysninger kan blive delt med sådanne tredjeparter og udbydere. Vi kan også levere aggregerede statistikker om kontohavere, salg, trafikmønstre og relaterede oplysninger til velrenommerede tredjeparter. Menuonline kræver, at enhver tredjepartsudbyder, der har adgang til personlige oplysninger, er bundet af forpligtelser i overensstemmelse med denne Privatpolitik, og at sådanne udbydere kun indsamler, bruger eller videregiver kontoindehaverens personlige oplysninger med det formål at levere Menuonline-tjenester.",
    "privacy_policy_use_disclosure_desc3": "Personlige oplysninger leveret af kontoindehavere vil ikke blive solgt, delt eller udlejet til eksterne organisationer, medmindre det er i overensstemmelse med dit underforståede eller udtrykkelige samtykke. Menuonline forbeholder sig retten til at kontakte enhver kontoindehaver for at videresende information, svare på spørgsmål eller give besked om eventuelle ændringer i vores program eller vores politikker. Bemærk venligst, at Menuonline ikke kontakter nogen af ​​dine kontakter, medmindre det kræves af gældende lovgivning.",
    "privacy_policy_use_disclosure_desc4": "Menuonline vil kun videregive personlige oplysninger, hvis samtykke er givet af den relevante person eller:",
    "privacy_policy_disclosure_list_1": "når det er tilladt eller påkrævet ved lov, såsom som svar på en stævning eller anden juridisk proces;",
    "privacy_policy_disclosure_list_2": "til en erhverver af hele eller dele af Menuonline;",
    "privacy_policy_disclosure_list_3": "til Menuonlines tjenesteudbydere i overensstemmelse med denne politik; eller",
    "privacy_policy_disclosure_list_4": "at opfylde juridiske forpligtelser, herunder, men ikke begrænset til, lovpligtige rapporteringsforpligtelser.",
    "privacy_policy_service_providers_title": "Menuonline bruger følgende tredjeparter og tjenesteudbydere (personlige oplysninger om dine kontakter videregives ikke til \"sådanne\" tredjeparter):",
    "privacy_policy_service_provider_1": "Vi bruger ManyChat til at levere hjemmesidechattjenester til salg og support, samt opfølgende e-mails om disse samtaler.",
    "privacy_policy_service_provider_2": "Vi bruger AskNicely til at undersøge vores kunder med det formål at spørge om kvaliteten af ​​service og support, de modtager.",
    "privacy_policy_service_provider_3": "Vi bruger join.me til at planlægge kunde- og potentielle kundemøder, supportopkald og softwaredemoer.",
    "privacy_policy_service_provider_4": "Vi bruger Google Analytics til at spore anonym hjemmesideaktivitet og måle brugsmønstrene på vores hjemmeside.",
    "privacy_policy_breach_notification_title": "ANMELDELSE AF OVERTRÆDELSE",
    "privacy_policy_breach_notification_desc": "Menuonline skal overholde og give kontoindehavere rimelig assistance i overensstemmelse med gældende love med hensyn til enhver uautoriseret brug, adgang eller videregivelse af personlige oplysninger.",
    "privacy_policy_rights_access_title": "DINE RETTIGHEDER OG ADGANG",
    "privacy_policy_rights_access_desc": "Du har ret til at få adgang til personoplysninger, som Menuonline behandler om dig. Du kan anmode os om oplysninger om:",
    "privacy_policy_rights_access_list_1": "De personlige oplysninger, vi opbevarer om dig",
    "privacy_policy_rights_access_list_2": "De pågældende kategorier af personoplysninger",
    "privacy_policy_rights_access_list_3": "Formålene med behandlingen",
    "privacy_policy_rights_access_list_4": "Detaljer, til hvem dine personlige oplysninger har\/vil blive videregivet",
    "privacy_policy_rights_access_list_5": "Hvor længe vi opbevarer dine personlige oplysninger",
    "privacy_policy_rights_access_list_6": "Hvis vi ikke har indsamlet oplysningerne direkte fra dig, oplysninger om kilden",
    "privacy_policy_rights_access_list_7": "Sådan indgiver du en klage til den korrekte tilsynsmyndighed",
    "privacy_policy_rights_access_list_8": "Du kan også anmode os om følgende:",
    "privacy_policy_rights_access_list_9": "At vi opdaterer eventuelle ufuldstændige eller unøjagtige oplysninger om dig",
    "privacy_policy_rights_access_list_10": "Anmod om, at vi sletter dine personlige oplysninger i overensstemmelse med gældende lovgivning",
    "privacy_policy_rights_access_contact": "Du kan anmode om, at vi handler med dine rettigheder ved at kontakte os på 2851 Dufferin Street, Toronto, ON, Canada eller ved at sende en e-mail til os på {link}.",
    "privacy_policy_accountability_title": "ANSVARLIGHED",
    "privacy_policy_accountability_desc": "Menuonline er forpligtet til at være ansvarlig for de personlige og fortrolige oplysninger, som du giver os. For kontaktoplysninger, se venligst slutningen af ​​denne politik.",
    "privacy_policy_links_other_sites_title": "LINKS TIL ANDRE SITES",
    "privacy_policy_links_other_sites_desc1": "Menuonlines hjemmeside kan indeholde links til andre sider. Vær opmærksom på, at Menuonline ikke er ansvarlig for andre websteders privatlivspraksis. Vi opfordrer vores kontoindehavere til at være opmærksomme på, at når de forlader vores side, bør de grundigt læse de private politikerklæringer på hver eneste hjemmeside, der indsamler personligt identificerbare oplysninger.",
    "privacy_policy_links_other_sites_desc2": "Denne privatpolitik gælder udelukkende for oplysninger indsamlet af os.",
    "privacy_policy_links_other_sites_desc3": "Hvis du bruger et link til at gå fra Menuonline-siden for at købe produkter eller tjenester, gennemfører du en købstransaktion i henhold til reglerne og forskrifterne for leverandørvirksomheden, ikke Menuonline.",
    "privacy_policy_cookies_title": "PRIVATE POLITIKCOOKIES",
    "privacy_policy_cookies_desc1": "Menuonline-webstedet og -tjenesterne bruger cookies til at indsamle og gemme visse oplysninger i overensstemmelse med denne politik. En 'cookie' er en lille tekstfil, der sendes fra et websted og gemmes på brugerens computer af brugerens webbrowser. Når du besøger et websted, der bruger cookies, downloades en cookie til din computer eller mobilenhed. Næste gang du besøger dette websted, vil din enhed huske nyttige oplysninger såsom præferencer, besøgte sider eller login-muligheder.",
    "privacy_policy_cookies_desc2": "Cookies bruges i vid udstrækning til at tilføje funktionalitet til websteder eller for at sikre, at de fungerer mere effektivt. Vores websted er afhængigt af cookies for at optimere brugeroplevelsen og sikre, at webstedets tjenester fungerer korrekt.",
    "privacy_policy_cookies_desc3": "De fleste webbrowsere tillader en vis kontrol for at begrænse eller blokere cookies gennem browserindstillingerne, men hvis du deaktiverer cookies, kan du opleve, at dette påvirker din mulighed for at bruge visse dele af vores hjemmeside eller tjenester.",
    "privacy_policy_cookies_analytical_purpose": "<strong> Analytiske formål: <\/strong> Vi bruger cookies til at analysere kontoindehavers aktivitet for at forbedre vores hjemmeside. For eksempel kan vi bruge cookies til at se på aggregerede mønstre som de funktioner, kontohavere bruger. Vi kan bruge en sådan analyse til at få indsigt i, hvordan vi kan forbedre funktionaliteten og brugeroplevelsen af ​​vores hjemmeside.",
    "privacy_policy_cookies_preferences_user_experience": "<strong> Dine præferencer og brugeroplevelse: <\/strong> Vi bruger cookies til at indsamle visse oplysninger om besøgende og kontoindehavere, såsom browsertype, server, sprogpræferencer og landeindstillinger, for at gemme kontoindehavers præferencer på vores hjemmeside for at gøre kontoindehaverens oplevelse mere konsistent og bekvem. Vi bruger cookies til at bevare din loggede tilstand, når du besøger vores hjemmeside gentagne gange.",
    "privacy_policy_cookies_targeted_ads": "<strong> Lever måletjenester og bedre målrettede annoncer og markedsføring: <\/strong> Vi bruger cookies, web-beacons og andre lagringsteknologier fra tredjepartspartnere såsom Google og Facebook til måletjenester, bedre målretning af annoncer og til markedsføringsformål. Dette sker, når du besøger vores hjemmeside. Disse cookies, web-beacons og andre lagringsteknologier giver os mulighed for at vise Menuonline-kampagnemateriale til dig på andre websteder, du besøger på internettet.",
    "privacy_policy_security_title": "SIKKERHED",
    "privacy_policy_security_desc1": "Menuonline bestræber sig på at beskytte fortrolige oplysninger af alle typer, herunder personlige oplysninger, med organisatoriske, fysiske, mekaniske og elektroniske sikkerhedsforanstaltninger, der passer til informationernes følsomhed. Vores servere er placeret i Canada og følger industristandarder vedrørende sikkerhed.",
    "privacy_policy_security_desc2": "Bemærk dog, at vi ikke kan garantere, at de foranstaltninger, vi opretholder, garanterer oplysningernes sikkerhed.",
    "privacy_policy_retention_title": "TILBAGEHOLDELSE",
    "privacy_policy_retention_desc": "Vi opbevarer personlige oplysninger om kontoindehavere og kontakter, så længe det er nødvendigt til formålene angivet i denne privatpolitik og i overensstemmelse med opbevaringsbestemmelserne i brugsbetingelserne.",
    "privacy_policy_access_questions_title": "ADGANG, SPØRGSMÅL OG BEKYMRINGER",
    "privacy_policy_access_questions_desc": "En person, der har afgivet personlige oplysninger til Menuonline, kan indsende en skriftlig anmodning om at få adgang til eller korrigere hans eller hendes personlige oplysninger, der opbevares af Menuonline.",
    "terms_obj": {
        "terms_conditions_title": "VILKÅR OG BETINGELSER",
        "terms_conditions_subtitle": "Vilkår og betingelser for nye og eksisterende kunder",
        "terms_conditions_desc1": "DE FØLGENDE NØGLEPUNKTER (INKLUSIVE AFSNITSHENVISNINGER) ER KUN TILGIVET FOR DIN GIVELSE OG ER IKKE I STEDET FOR DE FULDSTÆNDIGE VILKÅR OG BETINGELSER. DET ER DIT ANSVAR AT LÆSE DISSE VILKÅR OG BETINGELSER, FØR DU BRUGER Menuonline-TJENESTEN.",
        "terms_conditions_desc2": "Brug af Menuonline betyder, at du accepterer vilkårene og betingelserne og repræsenterer og garanterer, at du er autoriseret af din organisation eller virksomhed til at gøre det. (Se s.3 \"Sådan virker det\")",
        "terms_conditions_desc3": "Menuonline er en tilladelsesbaseret marketingtjeneste. Forsætlig afsendelse af \"spam\" eller overtrædelse af vilkår og betingelser vil resultere i lukning af din konto. (Se s.16 \"Acceptabel brug af tjenesten\")",
        "terms_conditions_desc4": "Data, du hoster i Menuonline, såsom kontaktoplysninger, e-mailindhold og information, du indsamler gennem Menuonline, tilhører dig. (Se s.14 \"Hvad ejer du?\").",
        "terms_conditions_desc5": "Menuonline-softwaren og indholdet på vores hjemmeside tilhører os. (Se s.13 \"Hvad ejer vi?\")",
        "terms_conditions_desc6": "Bortset fra dine egne brugere kan de data eller det upublicerede indhold, du hoster hos Menuonline, kun ses af Menuonline-medarbejdere og partnere, der har underskrevet fortrolighedsaftaler. (Se s.14 \"Hvad ejer du?\").",
        "terms_conditions_desc7": "Alle personligt identificerbare oplysninger (PII) opbevares kun på servere i Canada. (Se s.14 \"Hvad ejer du?\").",
        "terms_conditions_desc8": "Menuonline-tjenesten er underlagt lovene i Canada og provinsen Ontario. (Se s.24 \"Gældende lov\")",
        "terms_conditions_desc9": "Enhver anmodning om dine data eller indhold fra enkeltpersoner, politi eller andre reguleringsorganer fra Canada eller uden for Canada vil altid blive henvist til dig. Den eneste undtagelse ville være, hvis dette sætter os i strid med canadisk eller Ontario lov; dog vil vi i alle tilfælde ihærdigt modstå enhver frigivelse af dine data fra andre end dig. (Se s.14 \"Hvad ejer du?\").",
        "terms_conditions_desc10": "Menuonline-sikkerhed er kun så god som din administration af brugernavne og adgangskoder. Vi opkræver ikke betaling for Menuonline brugerkonti, så opret en separat for hver bruger og hold adgangskoder hemmelige. Du er ansvarlig for din konto. (Se s.3 \"Sådan virker det\")",
        "terms_conditions_desc11": "Du kan til enhver tid annullere din konto. Vi refunderer ikke ubrugt kredit, hvis du annullerer. (Se s.3 \"Sådan virker det\")",
        "terms_conditions_desc12": "Vi kan til enhver tid annullere din konto. Vi yder refusion for e-mailkreditter og ubrugte tjenester, hvis vi annullerer din konto. (Se s.3 \"Sådan virker det\")",
        "terms_conditions_desc13": "Dine data vil blive slettet efter annullering og kan også blive slettet, hvis en kontobruger ikke har logget ind i 12 måneder eller mere. Vi vil vedligeholde aggregerede data fra din konto, som ikke omfatter personligt identificerbare oplysninger til statistiske formål. (Se s.3 \"Sådan virker det\")",
        "terms_conditions_desc14": "E-mail-kreditter købes på pay-as-you-go-basis og udløber aldrig. Den eneste undtagelse er, hvis du ikke har logget på din konto i 12 måneder. I dette tilfælde forbeholder vi os retten til at slette din konto, dine data og de tilhørende kreditter. (Se s.10 \"Hvad betaler jeg for tjenesten?\")",
        "terms_conditions_desc15": "Vi arbejder hårdt for at sikre, at Menuonline er oppe og køre til enhver tid og for at give forhåndsmeddelelse, hvis vi midlertidigt skal suspendere tjenesten for vedligeholdelse. Vi garanterer dog ikke, at tjenesten vil være oppe og køre på alle tidspunkter, og vi er ikke ansvarlige for eventuelle tab, du lider under at bruge Menuonline, herunder tab fra din side, hvis systemet ikke kører på et tidspunkt, du har brug for det. (Se s.17 \"Ansvarsfraskrivelser\").",
        "terms_conditions_desc16": "Vi overvåger ikke dit indhold eller hvem du sender beskeder til. Vi overvåger dog klager og andre problemer skabt af dine aktiviteter. For mange klager eller problemer kan resultere i annullering af din service efter vores skøn. (Se s.7 \"Overvågning\")",
        "terms_conditions_desc17": "Vi er forpligtet til at yde fremragende produktsupport for at hjælpe dig med at blive selvforsynende med Menuonline. Vi yder support i form af dokumentation og video på vores hjemmeside, Menuonline support billetsystem og telefonisk. Der er ingen gebyr for produktsupport; dog forbeholder vi os retten til at afbryde support til specifikke kundekonti efter vores skøn. (Se s.5 \"Support\")",
        "terms_conditions_desc18": "Produktservice er til de tidspunkter, hvor du ønsker, at vi skal udføre noget arbejde relateret til Menuonline i stedet for at gøre det selv. Vores pris for produktservice er $135 pr. time. Vi yder også konsulentydelser til højere priser. Vi forbeholder os retten til at ændre vores priser til enhver tid. (Se s.6 \"Produkt- og konsulentydelser\")",
        "terms_conditions_desc19": "Vi vil informere dig, hvis vi mener, at der har været et brud på dine data, og vi vil gøre en indsats for at give relaterede detaljer og oplysninger. (Se s.14 \"Hvad ejer du?\")",
        "terms_conditions_desc20": "Vi forbeholder os retten til at foretage ændringer i denne aftale til enhver tid. Vi vil forsøge at informere dig om ændringer af vilkårene og betingelserne, men brug af tjenesten betyder, at du accepterer ændringer i vilkårene. (Se s.9 \"Ændringer\").",
        "terms_conditions_desc21": "Vilkårene og betingelserne og vores privatlivspolitik er kun udarbejdet på engelsk, og du accepterer, at den engelsksprogede version af vilkårene og betingelserne og privatlivspolitikken vil have prioritet i tilfælde af konflikt med enhver oversat version.",
        "terms_conditions_mycircle": "MYCIRCLE VILKÅR OG BETINGELSER",
        "terms_conditions_last_update": "SIDSTE OPDATERING: 14. januar 2020",
        "terms_conditions_usage_agreement": "Læs venligst disse vilkår og betingelser for service ('Betingelser') omhyggeligt, før du bruger dette websted ('Site'). Disse vilkår fritager Menuonline ('Menuonline', 'vi' eller 'os') og andre fra ansvar og\/eller begrænser vores og deres ansvar og indeholder andre vigtige bestemmelser, der gælder for din brug af dette websted.",
        "terms_conditions_acceptance": "Ved at besøge eller bruge dette websted eller tjenesten accepterer du på dine egne vegne og på vegne af enhver organisation, på hvis vegne du kan handle (samlet heri kaldet \"dig\"), at acceptere og overholde disse vilkår for hver brug af og hvert besøg på dette websted.",
        "terms_conditions_services": "1. Vores tjenester.",
        "terms_conditions_services_desc": "De tjenester, som vi tilbyder via webstedet, er en tjeneste, der giver dig mulighed for at oprette, sende og administrere e-mails (\"E-mails\") til modtagere efter eget valg (\"tjenesten\").",
        "terms_conditions_license": "2. Licens.",
        "terms_conditions_license_desc": "I henhold til disse vilkår giver Menuonline dig hermed en ikke-eksklusiv, begrænset, ikke-overførbar licens i løbet af perioden til at se webstedet og bruge tjenesten som tilladt af funktionerne i tjenesten. Vi forbeholder os alle rettigheder, der ikke udtrykkeligt er givet heri til tjenesten og menuonlineindholdet (som defineret nedenfor).",
        "terms_conditions_account": "3. Hvordan det virker.",
        "terms_conditions_account_desc": "For at bruge tjenesten skal du oprette en konto. Din konto er gratis. Din konto giver dig adgang til Tjenesten og \"funktioner\", som vi kan etablere og vedligeholde fra tid til anden. For at være berettiget til en konto skal du:",
        "terms_conditions_account_requirements": [
            "være mindst atten (18) år gammel og i stand til at indgå kontrakter;",
            "fuldføre registreringsprocessen, som vi har beskrevet;",
            "acceptere disse vilkår og betingelser;",
            "give sande, fuldstændige og ajourførte kontakt- og fakturerings-\/betalingsoplysninger."
        ],
        "responsibility": "Ansvar.",
        "responsibilityText": "Du er eneansvarlig for den aktivitet, der finder sted på din konto, og du skal holde din kontoadgangskode fortrolig og sikker. Konti er gratis, så vi opfordrer dig til at oprette en anden konto for hver bruger, du har. Du er også ansvarlig for enhver konto, som du har adgang til, uanset om du har godkendt brugen af ​​denne konto eller ej. Du accepterer, at du alene er ansvarlig for din interaktion med dem, du sender e-mails til, og du vil kun sende e-mails til de personer, som du har samtykke fra. Du skal straks underrette os om enhver uautoriseret brug af dine konti.",
        "compliance_with_CASL": "Overholdelse af Canadian Anti Spam Legislation (CASL).",
        "compliance_with_CASL_text": "Menuonlines rolle er at lette administrationen af ​​samtykkeindstillinger, ikke at håndhæve lovgivning. Det er dit ansvar at overholde CASL. Vi hjælper dig ved at indstille standardfunktionalitet og ved at lette indhentning og opdatering af dokumenteret udtrykkeligt samtykke og ved at administrere rullende udløbsdatoer for underforstået samtykke. Du har fuld kontrol over at administrere samtykke til din database; du kan til enhver tid opdatere samtykkestatus for alle dine kontakter på én gang, tilsidesætte standardindstillinger og deaktivere automatisk udløb af underforstået samtykkestatus.",
        "closing_account": "Lukning af din konto.",
        "closing_account_text": "Enten du eller Menuonline kan opsige denne aftale til enhver tid og af enhver grund ved at give meddelelse til den anden part. Hvis du giver oplysninger, der er usande, unøjagtige, ikke aktuelle eller ufuldstændige eller bruger tjenesten på en måde, der overtræder disse vilkår og betingelser, har vi ret til øjeblikkeligt at suspendere din konto og afslutte din adgang til tjenesten ud over alle andre retsmidler, der er tilgængelige for os. Hvis du ikke bruger din konto i tolv (12) måneder, kan vi annullere din konto. Hvis vi af en eller anden grund lukker din konto, refunderer vi dig for alle kreditter, du har tilbage. Når den er afsluttet, sletter vi permanent din konto og alle de data, der er knyttet til den, inklusive de e-mails, du har sendt. Vi kan vedligeholde aggregerede data fra din konto, som ikke omfatter personligt identificerbare oplysninger til statistiske formål.",
        "emailing_content": "E-mail med indhold.",
        "emailing_content_text": "Når du interagerer med Menuonline via dette websted eller tjenesten, accepterer du at give sande og nøjagtige oplysninger. Derudover forstår du, at det er en usikker praksis at give fortrolige oplysninger via e-mail. Du forstår, at du er eneansvarlig for alt, hvad du sender til enhver, der bruger vores Tjeneste. formål.",
        "support": "Støtte.",
        "support_text": "Vi giver dig support til at hjælpe dig med at blive selvforsynende med Tjenesten. Vi yder dig support i form af dokumentation og videoer på siden, Menuonline support billetsystemet og telefonisk. Du kan nå os ved at klikke på fanen \"Support\" øverst på siden. Der er ingen gebyr for produktsupport; vi forbeholder os dog retten til at afbryde support til specifikke kundekonti efter vores skøn.",
        "product_and_consulting_services": "Produkt- og konsulentydelser.",
        "product_and_consulting_services_text": "Produktservice er til de tidspunkter, hvor du ønsker, at vi skal udføre noget arbejde relateret til Menuonline i stedet for at gøre det selv. Vores pris for produktservice er $125 pr. time. Vi yder også konsulentydelser til højere priser. Vi forbeholder os retten til at ændre vores priser til enhver tid.",
        "monitoring": "Overvågning.",
        "monitoring_text": "Vi vil ikke overvåge eller bedømme indholdet af oplysninger, der sendes over Tjenesten, men vil undersøge, om vi modtager klager over mulig upassende brug. Menuonline kan til enhver tid og efter eget skøn træffe afgørelser om, at bestemte anvendelser er eller ikke er passende med eller uden varsel til dig i henhold til følgende retningslinjer.",
        "term": "Semester.",
        "term_text": "Betingelsen begynder, når du tilmelder dig en konto hos os og fortsætter, så længe du bruger tjenesten. Du repræsenterer og garanterer, at du har autoriteten og evnen til at tilmelde dig en konto for os (inklusive hvis du tilmelder dig på vegne af en organisation).",
        "changes": "Ændringer.",
        "changes_text": "Betingelsen begynder, når du tilmelder dig en konto hos os og fortsætter, så længe du bruger tjenesten. Du repræsenterer og garanterer, at du har autoriteten og evnen til at tilmelde dig en konto for os (inklusive hvis du tilmelder dig på vegne af en organisation).",
        "payment": "BETALING",
        "what_do_i_pay_for_service": "Hvad betaler jeg for tjenesten?.",
        "what_do_i_pay_for_service_text": "Du kan købe forudbetalte kreditter til at sende e-mail (\"kreditter\") gennem vores tjeneste, som vi beskriver detaljeret på siden \"priser\" på vores websted. Kreditterne udløber aldrig, og der er ingen tilbagevendende gebyrer for disse kreditter. Den eneste undtagelse er, at hvis du ikke bruger din konto i tolv (12) måneder, kan vi annullere din konto og eventuelle ubrugte kreditter. Vi kan til enhver tid ændre vores prissætning ved at opdatere prissætningssiderne på vores websted, hvilket vil gælde for dit næste køb af kreditter.",
        "how_do_i_pay_for_service": "Hvordan betaler jeg for tjenesten?.",
        "how_do_i_pay_for_service_text": "Du kan betale for kreditter med et hvilket som helst større kreditkort. Vi kan til enhver tid ændre, hvilke betalingsmetoder vi accepterer.",
        "can_i_get_refund": "Kan jeg få en refusion?.",
        "can_i_get_refund_text": "De beløb, du har betalt for ved brug af tjenesten, kan ikke refunderes, hvis du annullerer din konto. Hvis vi annullerer din konto, refunderer vi dig for alle kreditter, du har tilbage.",
        "rights": "RETTIGHEDER",
        "what_do_we_own": "Hvad ejer vi?.",
        "what_do_we_own_text": "Bortset fra det indhold, du leverer til os (herunder, uden begrænsning, kontaktoplysninger, e-mail-indhold og information, du indsamler gennem tjenesten) (\"dit indhold\"), alt materiale, der er inkluderet på vores websted, tjenesten og alt materiale deri eller overføres derved, herunder uden begrænsning, software, billeder, tekst, grafik, illustrationer, logoer, patenter, varemærker, musik, servicemærker, fotografier, brugere, lyd- og servicemærker, fotografier, \"Menuonline-indhold\") (og alle intellektuelle ejendomsrettigheder relateret hertil) ejes af os eller vores licensgivere.",
        "what_do_you_own": "Hvad ejer du?.",
        "what_do_you_own_text": "Vi hævder ingen ejerskabsrettigheder over dit indhold. Dit indhold er dit, og du bevarer ejerskabet af dit indhold, som du uploader til tjenesten. Ved at dele dit indhold gennem tjenesten accepterer du at tillade andre at se, redigere og\/eller dele dit indhold i overensstemmelse med dine indstillinger og disse vilkår og betingelser. Du repræsenterer og garanterer, at du enten ejer eller har tilladelse til at bruge alt det materiale, du bruger i dine e-mails. Vi må kun bruge eller videregive dine materialer, som vi beskriver i disse vilkår og vores privatlivspolitik.",
        "privacy_statement": "Menuonline respekterer sine brugeres privatliv. Enhver personlig information, som Menuonline indsamler via dette websted eller tjenesten, er underlagt Menuonline-erklæringen om beskyttelse af personlige oplysninger, som er inkorporeret ved henvisning i disse vilkår. Vi kan bruge og videregive dine oplysninger i henhold til vores fortrolighedserklæring.",
        "access_to_your_content": "Menuonline vil ikke give adgang til dit indhold til andre end de medarbejdere og underleverandører af Menuonline, som har indgået passende fortrolighedsaftaler med os, eller vi har dit samtykke.",
        "governmentRequest": "Hvis vi modtager en anmodning fra en regering eller regulerende myndighed om at få adgang til dit indhold, vil vi give dig besked om anmodningen og søge dit samtykke, før vi frigiver noget af dit indhold. Vi vil modstå enhver anmodning om dit indhold og vil ikke frigive dit indhold som svar på denne type anmodning uden din tilladelse eller påkrævet af en domstol med kompetent jurisdiktion eller det relevante tilsynsorgan.",
        "breachNotification": "I tilfælde af, at vi bliver opmærksomme på, at dit indhold har været genstand for uautoriseret adgang, brug, kopiering, tyveri, tab eller brug eller videregivelse i strid med disse vilkår (et 'brud'), vil vi straks underrette dig og informere dig om, hvad der er sket. Du accepterer, at overtrædelser vil blive håndteret i overensstemmelse med gældende lovgivning, og hvis vi har brug for, at du informerer medlemmer om dine e-mail-distributionslister, vil du acceptere at gøre det omgående og som vi anviser.",
        "contentRetention": "Vi vil beholde og bruge og overføre dit indhold i Canada undtagen med din forudgående skriftlige godkendelse eller anvisning (f.eks. hvis du vælger at sende dit indhold til personer uden for Canada). De servere, der bruges til at levere tjenesten, er placeret i Canada.",
        "accessTrouble": "Hvis du har problemer med at få adgang til dit indhold, vil Menuonline hjælpe dig med at hente dit indhold på tids- og materialebasis.",
        "reportingAbuse": "Hvis du mener, at nogen overtræder nogen af ​​disse vilkår, bedes du straks give os besked. Hvis du har modtaget spams, som du tror kom fra en anden bruger af Tjenesten, hvis du mener, at en anden bruger af Tjenesten har postet materiale, der krænker ophavsrettigheder, eller hvis du mener, at en anden bruger af Tjenesten på anden måde overtræder disse vilkår, bedes du kontakte os på Support@menuonline.com.",
        "acceptableUse": "Du repræsenterer og garanterer, at din brug af tjenesten vil overholde alle gældende love og regler. Du accepterer, at du er ansvarlig for at bestemme, om Tjenesten vil overholde og er egnet til dig at bruge i din jurisdiktion. For større sikkerhed accepterer du, at du skal (og accepterer at sikre, at dine medarbejdere, agenter og autoriserede brugere skal) ...",
        "exportLaws": "Vi garanterer ikke, at webstedet eller tjenesten er passende eller tilgængelig til brug uden for Canada, og adgang til dem fra territorier, hvor deres indhold er ulovligt, er forbudt. Du må ikke bruge eller eksportere eller reeksportere Menuonline-indholdet eller andet materiale på dette websted eller nogen kopi eller tilpasning i strid med gældende love eller regler, herunder uden begrænsning canadiske og amerikanske eksportlove og -regler.",
        "disclaimers": "Tjenesten og dette websted leveres 'som de er' uden nogen repræsentationer, garantier eller betingelser af nogen art, og Menuonline fraskriver sig udtrykkeligt, i det fulde omfang, det er tilladt i henhold til gældende love, alle repræsentationer, garantier og betingelser, udtrykkelige eller underforståede, i henhold til loven eller på anden måde, herunder uden begrænsning enhver stiltiende garanti og betingelser for salgbarhed eller egnethed til et bestemt formål eller brug af ikke-tredjeparter...",
        "limitLiability": "Menuonlines samlede ansvar i henhold til denne aftale, uanset om det er kontraktmæssigt eller erstatningsansvarligt (herunder uagtsomhed), som følge af brud på garantien, objektivt ansvar eller under enhver anden teori om ansvar overhovedet, vil være begrænset til direkte skader i et beløb, der ikke overstiger de samlede gebyrer, der er betalt til Menuonline i henhold til disse vilkår og betingelser i de tolv (12) måneder, der opstod umiddelbart forud for den seneste erstatningsbegivenhed.",
        "liabilityExclusions": "Ingen af ​​Menuonline, dets tilknyttede selskaber eller deres respektive direktører, embedsmænd, medarbejdere, agenter eller andre repræsentanter (samlet 'Menuonline \"Repræsentanter\"') eller operatørerne af nogen tilknyttede netværk vil have noget ansvar eller erstatningsansvar i forbindelse med denne hjemmeside eller tjenesten for: (i) enhver indirekte, følgeskade, hændelig, eksemplarisk, strafbar eller speciel skade; eller (ii) for enhver skade, hvad enten den er direkte, indirekte, følgelig, tilfældig, eksemplarisk, strafbar eller speciel, karakteriseret som tabt omsætning, tabt opsparing eller indtægt eller tabt fortjeneste...",
        "indemnity": "Du vil holde Menuonline og\/eller Menuonline-repræsentanterne ('Menuonline skadesløse parter') skadesløse fra og imod ethvert krav fremsat af tredjeparter, og enhver bøder eller straf, der er idømt mod os, som følge af din brug af de oplysninger, du får adgang til fra dette websted eller din brug af tjenesten, og ethvert brud på disse vilkår...",
        "ipInfringement": "Menuonline skal forsvare, holde dig skadesløs og holde dig skadesløs fra og imod ethvert krav, krav, handlinger, søgsmål eller retssager fremsat eller anlagt mod dig af en tredjepart, der hævder, at din adgang til eller brug af Tjenesten i overensstemmelse med denne aftale krænker en tredjeparts intellektuelle ejendomsrettigheder i henhold til canadisk lov (et 'IP-krænkelseskrav')...",
        "equitableRelief": "Hvis du overtræder disse vilkår, kan vi søge påbud (hvilket betyder, at vi kan anmode om en retskendelse for at stoppe dig) eller anden rimelig retshjælp. Du accepterer, at dette vil være et supplement til og uden præjudice for eventuelle andre rettigheder, som vi måtte have ved lov eller egenkapital.",
        "linksToOtherSites": "Hvis vi leverer links til tredjeparters websteder, er de kun til rådighed for nemheds skyld. Hvis du bruger disse links, forlader du vores websted. Vi kontrollerer ikke disse tredjepartswebsteder eller støtter dem. Du accepterer, at vi ikke er ansvarlige for nogen af ​​disse tredjepartswebsteder eller deres indhold...",
        "thirdPartyRequests": "Hvis vi er nødt til at give oplysninger som svar på en juridisk eller regeringsanmodning vedrørende din konto eller din brug af Tjenesten, accepterer du, at vi kan debitere dig for vores omkostninger. Disse gebyrer kan omfatte vores advokatsalærer og omkostningerne ved vores medarbejders tid brugt på at forberede svaret på anmodningen.",
        "assignment": "Denne aftale og eventuelle rettigheder og licenser givet i henhold hertil, må ikke overføres eller tildeles af dig, men kan tildeles af Menuonline uden begrænsninger efter rimeligt forudgående varsel til dig.",
        "governingLaw": "Disse vilkår vil være underlagt og fortolket i overensstemmelse med alle gældende føderale love og lovene i Ontario uden hensyntagen til dets lovkonflikter. Du accepterer at underkaste dig den eksklusive jurisdiktion for domstolene i Ontario til afgørelse af enhver tvist vedrørende disse vilkår.",
        "severability": "Hvis en domstol med kompetent jurisdiktion af en eller anden grund finder, at en bestemmelse i disse vilkår eller en del heraf er ulovlig eller af en eller anden grund ikke kan håndhæves, så vil denne bestemmelse blive håndhævet i det maksimale tilladelige omfang for at give effekt til hensigten med disse vilkår, og resten af ​​disse vilkår vil fortsætte med fuld kraft og virkning og vil forblive håndhævelig.",
        "waiver": "Enhver frafaldelse af eller samtykke til at afvige fra kravene i disse vilkår vil kun være effektiv, hvis den er skriftlig og underskrevet af os, og kun i det specifikke tilfælde og til det specifikke formål, som det er givet til. Vores manglende udnyttelse eller vores forsinkelse i udøvelsen af ​​enhver ret i henhold til disse vilkår vil ikke fungere som et afkald på vores rettigheder.",
        "furtherAssurances": "Hver part er indforstået med at underskrive og levere alle dokumenter og foretage de handlinger (eller forårsage disse handlinger), der er nødvendige for at opfylde deres forpligtelser i henhold til disse vilkår og betingelser.",
        "notices": "Alle meddelelser til Menuonline skal være skriftlige og sendes til os på e-mail. Meddelelser til os skal sendes til vores kundeservicerepræsentanter på support@menuonline.com. Meddelelser til dig kan blive sendt til den adresse eller e-mailadresse, du har oplyst som en del af din registrering. Derudover kan vi udsende meddelelser eller meddelelser gennem dette websted for at informere dig om ændringer på dette websted eller andre vigtige forhold, og disse udsendelser vil udgøre meddelelse til dig på det tidspunkt, vi sender dem.",
        "entireAgreement": "Disse vilkår og vores fortrolighedserklæring udgør hele aftalen mellem dig og os med hensyn til din brug af webstedet og tjenesten og erstatter alle tidligere aftaler, repræsentationer og forståelser mellem Menuonline og dig med hensyn til dette emne. I tilfælde af konflikt mellem fortrolighedserklæringen og disse vilkår, er disse vilkår og betingelser gældende.",
        "language": "Parterne har udtrykkeligt anmodet om og krævet, at disse vilkår og betingelser, vores privatlivspolitik og alle relaterede dokumenter udfærdiges på engelsk. Les parter conviennent et presserende udtryk que ce Contrat et tous les dokumenter, qui s'y rapportent soient rédigés en anglais. Du accepterer, at den engelsksprogede version af disse vilkår og fortrolighedspolitik vil have prioritet i tilfælde af konflikt med en oversat version.",
        "survival": "Alle bestemmelser i disse vilkår, som i kraft af deres natur eller import er beregnet til at overleve udløb eller opsigelse af disse vilkår, skal overleve, inklusive, uden begrænsning, afsnit 17-19."
    },
    "collect_additional_info": "Vil du gerne indsamle yderligere oplysninger?",
    "additionalInfoChoice": "Vælg eventuelle yderligere oplysninger, du gerne vil indsamle.",
    "additional_info_yes": "Ja",
    "additional_info_noSkip": "Nej, Skip",
    "additional_info_label": "Yderligere oplysninger",
    "confirm_business_location": "Lad os først bekræfte din virksomhedsplacering",
    "custom_get_more_reviews_form": "Tilpasset formular til Hent flere anmeldelser",
    "set_up_custom_form": "Konfigurer din brugerdefinerede formular for få flere anmeldelser efter gruppe",
    "reputation_name_label": "Få flere anmeldelser Navn",
    "reputation_name_placeholder": "Indtast omdømmenavn",
    "group_label": "Gruppe",
    "group_desc_title": "Gruppebeskrivelses titel",
    "group_desc": "Gruppebeskrivelse",
    "select_group_placeholder": "Vælg Gruppe",
    "required_group_name": "Gruppenavn er påkrævet",
    "before_asking_review": "Før vi beder kunderne om anmeldelse, lad os stille dem 1 til 4 spørgsmål.",
    "feel_free_edit": "Du er velkommen til at redigere efter behov.",
    "incentive_settings": "Incitamentindstillinger",
    "incentivize_customer": "Vil du gerne opmuntre din kunde til at give en anmeldelse?",
    "choose_incentive": "Vælg det incitament, du gerne vil henvise.",
    "scan_qr_feedback": "Scan QR-koden og del din værdifulde feedback med os vedrørende din oplevelse.",
    "qr_code": "QR-kode",
    "scan_me": "SCAN MIG!",
    "or_title": "ELLER",
    "send_otp_title": "Send OTP",
    "sign_in_title": "Log ind",
    "email_addresss_title": "Email adresse",
    "reset_password_title": "Nulstil adgangskode",
    "remember_password_title": "Husket adgangskode?",
    "digit_code_title": "Indtast venligst den 4-cifrede kode, som er sendt videre ",
    "not_received_title": "Jeg har ikke modtaget en bekræftelseskode!",
    "resend_title": "Send venligst igen",
    "sign_up_form": "Opret din konto",
    "sign_up_sub_title": "Log ind nu for at få adgang til den seneste indsigt",
    "already_registered_title": "Har du allerede en Menuonline-konto?",
    "reserved_title": "Copyrights ©2023 Menuonline. Alle rettigheder forbeholdes.",
    "terms_title": "Servicevilkår",
    "policy_title": "Privatlivspolitik",
    "sign_up_title": "Tilmeld dig",
    "terms_conditions_title": "Vilkår og betingelser",
    "business_type_title": "Forretningstype",
    "business_type_sub_title": "Vælg venligst en af ​​de virksomhedstyper, der beskriver din virksomhed bedre!",
    "salon_title": "Salon",
    "restaurant_title": "Restaurant",
    "pizza_store_title": "Pizza butik",
    "food_truck_title": "Food Truck",
    "check_domain_title": "Tjek domænenavn",
    "start_exploring_title": "Begynd at udforske",
    "sign_in_form": "Log ind på din konto",
    "sign_in_sub_title": "Log ind nu for at få adgang til den seneste indsigt",
    "remember_title": "Husk mig",
    "otp_title": "OTP",
    "password_title": "Adgangskode",
    "forgot_title": "Glemt adgangskode?",
    "not_registered_title": "Har du ikke en konto?",
    "create_account_title": "Tilmeld dig nu",
    "create_form_title": "Opret formular",
    "first_name_title": "Fornavn",
    "last_name_title": "Efternavn",
    "email_title": "E-mail",
    "mobile_number_title": "Mobilnummer",
    "birthday_title": "Fødselsdag",
    "gender_title": "Køn",
    "address_title": "Adresse",
    "short_answer_title": "Kort svar",
    "long_answer_title": "Langt svar",
    "single_answer_title": "Enkelt svar",
    "single_checkbox_title": "Enkelt afkrydsningsfelt",
    "multiple_choice_title": "Multiple choice",
    "drop_down_title": "Drop-down",
    "yes_no_title": "Ja eller nej",
    "description_text_title": "Beskrivelsestekst",
    "file_upload": "Fil upload",
    "reset_password_form": "Nulstil din adgangskode",
    "reset_password_sub_title": "Nulstil hurtigt din adgangskode, og få adgang til din konto.",
    "required_full_name_title": "Fuldt navn er påkrævet",
    "required_first_name_title": "Fornavn er påkrævet",
    "valid_first_name_title": "Indtast gyldigt fornavn",
    "valid_full_name_title": "Indtast gyldigt fulde navn",
    "required_block_time": "Blokeringstid er påkrævet",
    "required_last_name_title": "Efternavn er påkrævet",
    "valid_last_name_title": "Indtast gyldigt efternavn",
    "valid_mobile_number_title": "Indtast gyldigt mobilnummer",
    "required_mobile_number_title": "Mobilnummer er påkrævet",
    "required_already_mobile_number_title": "Mobilnummer er allerede påkrævet",
    "valid_otp_title": "Indtast gyldig otp",
    "valid_email_title": "Indtast en gyldig e-mail",
    "required_email_title": "E-mail er påkrævet",
    "required_description_title": "Beskrivelse er påkrævet",
    "required_description_500_characters": "Indtast venligst mindst 500 tegn",
    "required_description_600_characters": "Indtast venligst maksimalt 600 tegn",
    "domain_title": "Domænenavn",
    "required_password_title": "Adgangskode er påkrævet",
    "required_password_regex_title": "Skal indeholde mindst 8 tegn, mindst et stort bogstav, et lille bogstav, et tal og et specialtegn",
    "required_terms_title": "Accepter venligst vilkår og betingelser",
    "required_note_title": "Bemærkning er påkrævet",
    "required_birthday_title": "Fødselsdag er påkrævet",
    "no_access_title": "Du har ingen adgang lige nu. Kontakt venligst din administrator",
    "menu_online_open_txt": "Åben",
    "loyalty_program_description": "😊 Find dit ideelle loyalitetsprogram og tilpas det, så det matcher dit brand!",
    "lp_list_title": "Liste over loyalitets hulkort",
    "lp_n_title": "Loyalitets hulkortnavn",
    "enter_details_title": "Indtast detaljer",
    "stamp_allowed_title": "Stempel tilladt",
    "stamp_items_title": "Frimærkegenstande",
    "lpn_req_title": "Loyalitets hulkortnavn er påkrævet",
    "valid_lpn_title": "Indtast et gyldigt loyalitetshulkortnavn",
    "brand_color_error": "Mærkefarve skal være nøjagtigt 7 tegn",
    "select_dark_brand_color_title": "Vælg venligst gyldig mærkefarve",
    "your_brand_color_title": "Din mærkefarve",
    "upload_logo_title": "Upload logo",
    "locations_title": "Steder",
    "add_lp_title": "Opret program",
    "location_error": "Vælg venligst lokationer",
    "pvs_title": "Hvor mange frimærker kan en kunde tjene pr. besøg?",
    "max_apd_title": "Hvad er det maksimale antal frimærker, en kunde kan tjene på en dag?",
    "logo_error": "Logo er påkrævet",
    "expiry_title": "Indstil udløb på belønninger",
    "add_more_rewards_title": "+ Tilføj flere belønninger",
    "prev_step_1_title": "Vis QR for at optjene point og nyde dine belønninger.",
    "delete_lp_title": "Dette loyalitetshulkort vil blive slettet permanent.",
    "delete_user_record_title": "Denne brugerpost vil blive slettet permanent.",
    "redeem_title": "Indløs",
    "lp_button_title": "Tilmeld dig Loyalty Punch Card",
    "lp_download_button_title": "Download kort",
    "step_0_title": "Tilmeld dig nu, lækre belønninger venter på loyale madelskere som dig.",
    "step_1_title": "Du er kommet med",
    "congrats_step_title": "Nu er du berettiget til gratis pizza",
    "reward_title": "Få belønninger ved hvert besøg",
    "visit_title": "Besøg og lås op",
    "rewards_title": "Belønninger",
    "dummy_address": "3730 Frankfort Ave, Louisville, Kentucky, USA",
    "loyalty_qr_default_text": "Scan QR-koden, tilmeld dig vores loyalitetshullekort, og forkæl dig selv med belønninger",
    "lp_earn_stamp_txt": "Kunder optjener stempler ved hvert besøg.",
    "lp_get_rewards_txt": "Få belønninger ved hvert besøg",
    "lp_1_time_visit_txt": "1 gang besøg får du",
    "lp_uppercase_stamp_text": "Stempel",
    "lp_lowercase_stamp_text": "stempel",
    "lp_txt": "Loyalitet",
    "lp_per_visit_stamp_validation_msg": "Stempel pr. besøg skal være mindre end det maksimalt tilladte stempel pr. dag",
    "lp_per_max_stamp_validation_msg": "Det maksimale tilladte stempel pr. dag skal være større end pr. besøgsstempel",
    "lp_valid_value_msg": "Indtast gyldig værdi",
    "lp_required_field_msg": "Dette felt er påkrævet",
    "lp_stamp_required_msg": "Stempel er påkrævet",
    "lp_valid_stamp_msg": "Indtast gyldig stempelværdi",
    "lp_reward_name_required_msg": "Belønningsnavn er påkrævet",
    "lp_unique_stamp_msg": "Stempelværdien skal være unik",
    "lp_benefits": "Et loyalitetsprogram øger gentagne besøg og omsætning, samtidig med at det giver kundeindsigt til personlig markedsføring",
    "lp_how_customer_txt": "Her er hvordan dine kunder",
    "lp_punch_card_txt": "loyalitets hulkort",
    "lp_look_txt": "vil se! 😍",
    "lp_desc": "Kunder vil optjene frimærker for hvert besøg, og når de har samlet nok, kan de indløse særlige belønninger.",
    "lp_edit": "Redigere",
    "lp_looks_good_txt": "Ser godt ud",
    "lp_customer_view_instruction": "Dette er, hvad din kunde vil se. Upload dit logo og indstil farvetema",
    "lp_customer_earn_stamps": "Tillad kunder at tjene stempler ved hvert besøg og administrere deres belønninger",
    "lp_stamp_rewards_setup": "Indstil frimærkeindtjening belønninger, tillad kunder at få belønninger pr. frimærker",
    "lp_loyalty_program_setup": "Giv dit loyalitetsprogram navn og indstil din placering til at have dette program",
    "lp_prev_step1": "Vis QR for at optjene point og nyde dine belønninger.",
    "lp_visit_1_time_txt": "Besøg 1 gang og få",
    "lp_get_free_txt": "Få gratis",
    "lp_visit_allow_txt": "Vis og lås op",
    "lp_rewards": "Belønninger",
    "repsT": "Få flere anmeldelser",
    "offSup": "Kontorartikler",
    "repsNxtV": "Ved dit næste besøg",
    "addRepT": "Tilføj Få flere anmeldelser",
    "repEntrCpnTxt": "Indtast kupontekst",
    "repEntrCpnTxtBtn": "Indtast kuponknaptekst",
    "repMngNxtV": "på dit næste besøg?",
    "repMngWldL": "Vil du gerne få",
    "discount": "Rabat",
    "repDisTyp": "Rabattype",
    "repNameT": "Navngiv dit omdømme",
    "crtRepT": "Skab omdømme",
    "feedBackT": "Feedback",
    "confLocT": "Bekræft placering",
    "businLocT": "Forretningssted",
    "addInfoT": "Yderligere oplysninger",
    "repNReqT": "Navnet på Hent flere anmeldelser er påkrævet",
    "validRepNT": "Indtast et gyldigt omdømmenavn",
    "groupT": "Gruppe",
    "groupLDescTitleT": "Skub automatisk kontakter ind i en gruppe",
    "groupLDescT": "Alle kunder, der udfylder formularen, vil blive tilføjet til den valgte gruppe.",
    "que2": "Var din mad god?",
    "que3": "Var vores service venlig?",
    "que4": "Var vores service hurtig?",
    "addAdditionalT": "Vil du tilføje yderligere oplysninger?",
    "phNoMandT": "Telefonnummer er obligatorisk!",
    "qrGenT": "QR genereret",
    "repFDT": "Dit input vil hjælpe vores team med at tjene dig bedre!",
    "letdiT": "Lad os gøre det",
    "tak15secT": "Tager 15 sekunder",
    "defQue": "Kunne du tænke dig at modtage gode tilbud og kampagner fra tid til anden?",
    "defNQue": "Vil du have nogen til at svare dig?",
    "rateOnGoogleDesc": "Vil du være så venlig at bedømme os på google?",
    "mayBeNT": "Måske næste gang",
    "yesSureT": "Ja, sikkert",
    "valid_name": "Indtast gyldigt navn",
    "nameReq": "Navn er påkrævet",
    "backToMainT": "Tilbage til hovedsiden",
    "attachCopT": "Vil du gerne motivere din kunde?",
    "thankYouT": "Tak",
    "lftyT": "Vi ser frem til dit næste besøg!",
    "repPreT": "Forhåndsvisning af omdømme",
    "offer_exclusive_discounts": "Tilbyd eksklusive rabatter og kampagner for at lokke dine kunder.",
    "couponT": "Kupon",
    "locStepDesc": "Bekræft din virksomheds placering",
    "createRepStepDesc": "Opret en brugerdefineret omdømmeformular efter grupper",
    "feedbackStepDesc": "Kundefeedback spørgsmål sæt",
    "addInfoStepDesc": "Kundefelter og incitamentsindstillinger",
    "FedbkkWDT": "Feedback med data",
    "repAnlytT": "Omdømmeanalyse",
    "todayT": "I dag",
    "rep_last_7_days_t": "Sidste 7 dage",
    "last30DaysT": "Sidste 30 dage",
    "lastMT": "Sidste måned",
    "custRangeT": "Brugerdefineret rækkevidde",
    "queWiseAnltT": "Spørgsmål Wise Analytics",
    "atL1QueErrT": "Vælg venligst mindst ét ​​spørgsmål",
    "deleteRepTitle": "Dette omdømme vil blive slettet permanent.",
    "incvCustT": "Ja, incitamenter min kunde",
    "collAddInfoT": "Ja, jeg vil gerne indsamle yderligere oplysninger",
    "totRewT": "Samlet anmeldelse",
    "totPosRewT": "Total positiv anmeldelse",
    "negFeedT": "Negativ feedback",
    "posFeedT": "Positiv feedback",
    "fineT": "Fint Print",
    "enterFineT": "Indtast tekst med småt",
    "setThemeT": "Indstil temafarve",
    "que1T": "Spørgsmål 1",
    "que2T": "Spørgsmål 2",
    "que3T": "Spørgsmål 3",
    "que4T": "Spørgsmål 4",
    "entMailT": "Indtast din e-mail",
    "reputationQRDefTxt": "Scan QR-koden og del din værdifulde feedback med os vedrørende din oplevelse.",
    "selUser": "Vælg Bruger",
    "userT": "Bruger",
    "subUReq": "Vælg venligst brugere",
    "updateLoc": "Opdater placering",
    "leadGenT": "Lead Generation",
    "displayT": "Vise",
    "ratingT": "Bedømmelse",
    "rep_dashboard_negative_feedback": "Negativ feedback 0 %",
    "rep_dashboard_position_feedback": "Positiv feedback 0 %",
    "rep_dashboard_total_Feedback_txt": "Samlet feedback",
    "rep_dashboard_anony_Feedback": "Anonym feedback",
    "rep_dashboard_Feeedback_Data_txt": "Feedback med data",
    "rep_dashboard_review_txt": "Få flere anmeldelser",
    "rep_dashboard_total_Review_txt": "Samlet anmeldelse",
    "rep_dashboard_total_positive_Review_txt": "Total positiv anmeldelse",
    "rep_dashboard_negative_feedback_txt": "Negativ feedback",
    "rep_dashboard_connection_txt": "Forbindelse",
    "rep_dashboard_question_wise_analytics_txt": "Spørgsmål Wise Analytics",
    "rep_dashboard_date_label": "Vælg dato",
    "rep_dashboard_custom_range_txt": "Brugerdefineret rækkevidde",
    "rep_tlt_min": "REP",
    "emojis": "Emojis",
    "media_text": "Tilføj medier",
    "custom_field_text": "Indsæt brugerdefinerede felter",
    "shortlink_text": "Tilføj Shortlink",
    "use_template_text": "Brug skabelon",
    "banner_must_have": "Skal have",
    "res_seamless": "Sømløs",
    "res_table_reservation": "Bordreservation",
    "res_system": "System",
    "res_text": "Forbehold",
    "md_easily": "Nemt",
    "md_manage_customers": "Administrer kunder",
    "md_visits_interactions": "besøg og interaktioner",
    "md_desc": "Gør spisning nemmere for dine kunder med bordreservationer i realtid. Administrer bookinger ubesværet, reducer ventetider og sørg for en smidig oplevelse for både personale og gæster. Hold din restaurant fuldt booket, mens du bevarer førsteklasses service og tilfredshed.",
    "engage_convert": "Engager og konverter SMS",
    "coupon_campaign": "og kuponkampagne",
    "sms_read_rate": "SMS-beskeder læses 98 % af tiden",
    "real_time_promo": "Lever kampagner i realtid til øjeblikkelig handling",
    "affordable_reach": "Overkommelig måde at nå ud til kunder med solidt investeringsafkast",
    "stay_in_touch": "Hold altid kontakten med din kunde",
    "bc_engage_customers": "Engager kunder Konverter SMS",
    "bc_coupon_campaign": "og kuponkampagne",
    "bc_sms_read_rate": "SMS-beskeder læses 98 % af tiden",
    "bc_real_time_promo": "Lever kampagner i realtid til øjeblikkelig handling",
    "bc_affordable_reach": "Overkommelig måde at nå ud til kunder med solidt investeringsafkast",
    "bc_stay_in_touch": "Hold altid kontakten med din kunde",
    "ar_automate_engagement": "Automatiser dit klientengagement",
    "ar_with": "med",
    "ar_with_auto_responders": "Auto-respondere",
    "ar_personalized_messages": "Send automatisk personlige beskeder til fødselsdage, byd nye kunder velkommen, og genskab forbindelsen til tidligere kunder. Spar tid, mens du holder enhver interaktion meningsfuld og rettidig.",
    "sq_custom_forms_tools": "Brugerdefinerede formularer og værktøjer – forenkle",
    "sq_custom_forms_ci": "Kundeinteraktioner",
    "sq_smart_qr_desc": "Opret brugerdefinerede formularer, indsaml e-signaturer, og brug smarte QR-koder til at strømline dine forretningsprocesser. Skræddersy hvert værktøj til at imødekomme dine kunders behov og forbedre brugeroplevelsen.",
    "gnc_get_new_cus": "Få nye kunder",
    "gnc_get_customers": "fra sociale medier - hurtigt og nemt!",
    "gnc_social_media_power": "Brug styrken ved sociale platforme til at tiltrække nye kunder og drive salg direkte til din virksomhed. Omdiriger tredjeparts leveringsordrer direkte til din butik eller online bestillingsplatform for maksimal kontrol og indtjening.",
    "wifi_connect_guests": "Forbind gæster med",
    "wifi_smart": "Smart Wi-Fi",
    "wifi_connect_grow": "& Udvid din indsigt",
    "wifi_smart_wifi_desc": "Giv dine kunder en problemfri Wi-Fi-forbindelse gennem en brandet splash-skærm, mens du indsamler værdifulde kundedata for at forbedre din marketingindsats og engagement.",
    "wifi_create_splash_page": "Opret gæste Wifi Splash-side",
    "instant_menu_create": "Opret din",
    "instant_menu_text": "Øjeblikkelig menu",
    "instant_menu_wm": "inden for få minutter",
    "instant_menu_desc": "Forbedrer kundeoplevelsen ved at tillade opdateringer i realtid, nem tilpasning og forbedret engagement, hvilket gør det nemmere at fremvise dine tilbud og holde dit indhold friskt.",
    "instant_menu_create_digital_menu": "Opret en digital menu",
    "loyalty_program_rewards": "Optjen belønninger",
    "loyalty_program_on_visits": "ved hvert besøg",
    "loyalty_program_create": "Opret loyalitetsprogram",
    "reputation_create": "Skab omdømme",
    "reputation_boost": "Boost din",
    "reputation_text": "Omdømme",
    "reputation_reviews": "med flere anmeldelser!",
    "reputation_desc": "Indsaml værdifuld feedback fra dine kunder for at forbedre din service og omdømme. Del din oplevelse og hjælp os med at vokse – din anmeldelse er vigtig!",
    "dashboard": "Dashboard",
    "blast_campaign": "Blast-kampagne",
    "blast_campaigns": "Blast kampagner",
    "blast_campaign_sub_title": "Start blast-kampagner for at nå ud til alle dine kunder med få klik",
    "auto_responders": "Auto-responders",
    "auto_responder": "Auto Responder",
    "auto_responder_sub_title": "Nem at bruge begivenhedsbaserede kampagner for dine kunder",
    "smart_qr_form": "Smart QR \/ Form",
    "get_new_customer": "Få ny kunde",
    "marketing": "Markedsføring",
    "clients": "Kunder",
    "group_data": "Grupper data",
    "clients_data": "Kunders data",
    "account": "Konto",
    "user_acc_info": "Brugerkontooplysninger",
    "current_plan": "Nuværende plan",
    "payments": "Betalinger",
    "location": "Beliggenhed",
    "location_list": "Lokationsliste",
    "users": "Brugere",
    "users_sub_title": "Liste over alle brugere og deres detaljer",
    "online_profile": "Online profil",
    "change_password": "Skift adgangskode",
    "menu": "Menu",
    "reseller_dashboard": "Forhandler Dashboard",
    "companies": "Virksomheder",
    "plans_bought": "Planer købt",
    "agreement_t_c": "Aftale T&C",
    "wifi_dashboard": "Wifi Dashboard",
    "hot_spot": "Hotspot",
    "splash_pages": "Splash Pages",
    "get_more_reviews": "Få flere anmeldelser",
    "office_supplies": "Kontorartikler",
    "reservation": "Reservation",
    "floor_table": "Gulv & Bord",
    "guest_smart_wifi": "Gæst Smart WiFi",
    "digital_signage": "Digital skiltning",
    "schedule_down": "Tidsplan",
    "business_settings": "Virksomhedsindstillinger",
    "sub_user_login": "Underbruger login",
    "help_center": "Hjælpecenter",
    "switch_to_hub": "Skift til Hub",
    "meeting_records": "Mødeprotokoller",
    "logout": "Log ud",
    "no_access_text": "Du har ingen adgang lige nu. kontakt venligst din administrator",
    "menu_item": "MENUVARE",
    "menu_capital": "MENU",
    "good_day": "God dag",
    "no_permission_text": "Du har ikke tilladelse til at udføre denne handling!!",
    "enter_valid_pin": "Indtast gyldig pinkode",
    "add_reservation": "Tilføj reservation",
    "edit_reservation": "Rediger reservation",
    "per_req": "Antal personer er påkrævet",
    "req_block_time": "Blokeringstid er påkrævet",
    "pending_status": "Indtil",
    "booked_status": "Booket",
    "completed_status": "Færdiggjort",
    "cancelled_status": "Annulleret",
    "reservation_slot": "Reservationsplads",
    "block_time": "Bloktid",
    "slot_time": "Tidspunkt for reservation:  ",
    "template_name_req": "Skabelonnavn er påkrævet",
    "template_type_req": "Skabelontype er påkrævet",
    "sms_template_req": "SMS-skabelon er påkrævet",
    "group_req": "Gruppe er påkrævet",
    "valid_date_time": "Indtast gyldig dato og klokkeslæt",
    "valid_group_name": "Indtast et gyldigt gruppenavn",
    "reservation_mark_completed": "Denne reservation vil blive markeret som afsluttet.",
    "customer_name": "Kundens navn",
    "date_time": "Dato Tid",
    "table_no": "Tabel Nr",
    "floor": "Etage",
    "customer_name_placeholder": "kunde_navn",
    "date_time_placeholder": "dato_tid",
    "table_no_placeholder": "tabel_nr",
    "floor_placeholder": "etage",
    "select_floor": "Vælg Gulv",
    "select_table": "Vælg Tabel",
    "customer_name_macro": "%% kundenavn %%",
    "date_time_macro": "%% dato_klokkeslæt %%",
    "table_no_macro": "%% table_no %%",
    "floor_macro": "%% etage %%",
    "template_name": "Skabelonnavn",
    "login_with_4_digit_pin": "Log ind med din 4-cifrede pinkode",
    "login_now_to_access_latest_insights": "Log ind nu for at få adgang til de seneste insignier",
    "mkt_BS_Title": "Spræng SMS",
    "step_1": "Tilpas besked",
    "step_2": "Vælg Målgruppe",
    "step_3": "Planlæg besked",
    "step_4": "Send en besked",
    "step_5": "Administrer besked",
    "bs_tab_title": "Blast-kampagneoversigt",
    "default_message": "Hej %% fornavn %%, Særlig rabat på brunch! Spis hos os i dag og få eksklusiv 10% rabat på din regning!",
    "bs_Title": "Vælg en kampagnetype",
    "sms_title": "SMS\/MMS",
    "reach_out_to_customer": "Nå ud til kunderne gennem tekst- og multimediebeskeder.",
    "wp_text": "WhatsApp",
    "connect_with_wp": "Forbind med dine kunder ved hjælp af WhatsApp 💬",
    "send_discount_offers": "Send rabattilbud via SMS 📲",
    "send_coupons_via_sms": "Send kuponer via SMS til dine kunder 🎟️",
    "env_Cap": "Tilbyd eksklusive rabatter og kampagner for at lokke dine kunder",
    "cmt_Caption": "Nå ud til kunderne gennem tekst- og multimediebeskeder",
    "wp_caption": "Forbind med lånere ved hjælp af WhatsApp for problemfri og direkte kommunikation",
    "msg_Prev_Title": "Forhåndsvisning af besked",
    "cou_Prev_Title": "Forhåndsvisning af kupon",
    "blast_SMS": "SMS\/MMS kampagne",
    "bs_P_Msg": "I kø",
    "bs_S_Msg_C": "Mislykkedes",
    "bs_F_Msg_Count": "Leveret",
    "previous": "Tidligere",
    "next_ST": "Næste",
    "next_S": "Næste trin",
    "req_Temp_Name_Title": "Skabelonnavn er påkrævet",
    "req_Template_Tx_Title": "Skabelontekst er påkrævet",
    "add_Temp_Title": "Tilføj skabelon",
    "link_Desc": "Tilføj et link efter eget valg, så kunder kan booke hos dig via Facebook, Google eller din egen hjemmeside.",
    "audience_Title": "Publikum",
    "client_G1_Title": "Nye kunder",
    "client_G2_Title": "Seneste kunder",
    "client_G3_Title": "Loyale kunder",
    "client_G4_Title": "bortfaldne klienter",
    "client_G5_Title": "Klienter efter tilslutningsdato",
    "on_Which_Day": "På hvilken dag",
    "on_Day": "På dagen",
    "immediate_Desc": "Din besked vil blive sendt med det samme, klik venligst på Næste for at begynde.",
    "never_Desc": "Denne kampagne er planlagt til at køre for evigt, indtil du afslutter den manuelt.",
    "end_Desc": "Kampagnen slutter på den dato, du vælger herfra.",
    "success_Message": "Kampagnen blev gemt!",
    "send_Success_Message": "Kampagnen blev sendt!",
    "msg_Name_Field_Title": "Kampagnetitel",
    "valid_campaign_title": "Indtast gyldig kampagnetitel",
    "msg_Type_Title": "Meddelelsestype",
    "sms_Desc": "1 kredit pr SMS",
    "mms_Desc": "3 Credits pr. MMS",
    "client_GTO_1": "Dag",
    "client_GTO_2": "Timer",
    "minutes": "Referater",
    "client_GTO_4": "Uge",
    "time_Option_1": "Dag",
    "time_Option_2": "Timer",
    "c_Grp_Day_1": "90 dage",
    "c_Grp_Day_2": "60 dage",
    "c_Grp_Day_3": "30 dage",
    "first": "Først",
    "second_T": "Anden",
    "third_T": "Tredje",
    "fourth_T": "Fjerde",
    "fifth_T": "Femte",
    "delete_BS_T": "Denne kampagne vil blive slettet permanent.",
    "delete_MBS_T": "Disse kampagner slettes permanent.",
    "cFT_1": "Telefon",
    "cFT_2": "Kontakt Fornavn",
    "cFT_3": "Kontakt efternavn",
    "cFT_4": "Kontakt email",
    "cFT_5": "Note",
    "cFT_6": "Fødselsdag",
    "cFT_7": "Firmanavn",
    "cF_Meta_Tag_1": "%% telefon %%",
    "cF_Meta_Tag_2": "%% fornavn %%",
    "cF_Meta_Tag_3": "%% efternavn %%",
    "cF_Meta_Tag_4": "%% email %%",
    "cF_Meta_Tag_5": "%% note %%",
    "cF_Meta_Tag_6": "%% fødselsdag %%",
    "cF_Meta_Tag_7": "%% virksomhedsnavn %%",
    "cF_Name_1": "telefon",
    "cF_Name_2": "fornavn",
    "cF_Name_3": "efternavn",
    "cF_Name_4": "e-mail",
    "cF_Name_5": "note",
    "cF_Name_6": "fødselsdag",
    "cF_Name_7": "virksomhedsnavn",
    "all_clients": "Alle klienter",
    "aud_Type_2": "Kundegrupper",
    "aud_Type_3": "Kundegrupper",
    "aud_Type_T1": "Alle kunder",
    "aud_Type_T2": "Kundegrupper",
    "aud_Type_T3": "Kundegrupper",
    "new_clients": "Nye kunder",
    "client_G2": "Seneste kunder",
    "client_G3": "Loyale kunder",
    "client_G4": "bortfaldne kunder",
    "client_G5": "Klienter efter tilslutningsdato",
    "never_text": "Aldrig",
    "ending_on": "Slutter på",
    "send_T2": "Planlagt",
    "send_Type_3": "Tilbagevendende",
    "daily": "Daglig",
    "weekly_T": "Ugentlig",
    "monthly_T": "Månedlig",
    "yearly_T": "Årligt",
    "each": "hver",
    "on_the": "På The",
    "monday": "mandag",
    "exclusive_offer": "Eksklusivt tilbud til dig",
    "redeem_now": "Indløs nu",
    "redeem_with_cashier": "Indløs venligst med kassen",
    "lg_Day_2": "tirsdag",
    "lg_Day_3": "onsdag",
    "lg_Day_4": "torsdag",
    "lg_Day_5": "fredag",
    "lg_Day_6": "lørdag",
    "lg_Day_7": "søndag",
    "msg_Pre_T": "Dominos",
    "cA_Edit_T1": "Alle",
    "cA_Edit_T2": "Inkluderet",
    "cA_Edit_T3": "Udelukket",
    "SMS": "SMS",
    "MMS": "MMS",
    "usd_T": "USD",
    "cad_T": "CAD",
    "msg": "Besked",
    "which_Day": "Hvilke dage",
    "end_Date_Tx": "Slutdato",
    "sDate_Err": "Startdatoen skal være større end eller lig med i dag",
    "eDate_Err": "Slutdatoen skal være senere end startdatoen",
    "start_Date_Req": "Startdato er påkrævet",
    "end_Date_Req": "Slutdato er påkrævet",
    "time_req": "Der kræves tid",
    "client_GT1": "Kunder abonnerede i sidste",
    "client_GT2": "Klienter med forbindelse i den sidste",
    "client_GT3": "Kunder med mindst",
    "client_GT4": "med det sidste",
    "client_GT5": "som ikke vendte tilbage i det sidste",
    "dummy_Phone_No": "+91987-654-3210",
    "test_T": "Prøve",
    "dummy_Birth_Date": "01-01-2001",
    "image_Req": "Medier eller URL er påkrævet",
    "time_Title": "Tid",
    "start_date": "Startdato",
    "end_date": "Slutdato",
    "auto_Send_T": "Send automatisk til kunder",
    "add_Media": "Tilføj medier",
    "in_Title": "I",
    "c_Temp_T": "Kampagne skabelon",
    "temp_NT": "Skabelonnavn",
    "type_T": "Type",
    "select_template_type": "Vælg skabelontype",
    "sel_Temp_T": "Vælg skabelontype",
    "sms_temp_t": "SMS skabelon",
    "temp_T": "Skabelon tekst",
    "default_Msg": "Hej %% fornavn %%, Særlig rabat på brunch! Spis hos os i dag og få eksklusiv 10% rabat på din regning!",
    "refill_T": "Genfyld",
    "avl_Credit": "Tilgængelige kreditter",
    "req_Credit": "Samlede kreditter påkrævet",
    "rem_Credit": "Resterende kredit",
    "refill_Credit": "Kreditter skal genopfyldes",
    "clients_Req": "Vælg mindst én klient",
    "subclient_Req": "Udvalgte kunder er ikke abonnenter.",
    "refill_Desc": "Udfyld venligst de nødvendige kreditter",
    "url_Regex": "^((http|https):\/\/).....+$",
    "sd_Invalid": "Startdatoen er ugyldig",
    "ed_Invalid": "Slutdatoen er ugyldig",
    "img_Url_Invalid": "Ugyldig billedwebadresse",
    "time_Invalid": "Tiden er ugyldig",
    "time_And_Date_Invalid": "Indtast en gyldig dato og tid",
    "time_Invalid_Bfr": "Vælg et tidspunkt mindst 5 minutter senere fra det aktuelle tidspunkt",
    "sod_Req": "Send på dagen er påkrævet",
    "add_Card": "Tilføj et nyt kort",
    "sm_D_Rep_Title": "SMS\/MMS leveringsrapport",
    "send_Now_T": "Send nu",
    "schd_Now_T": "Planlæg SMS",
    "test_SMS_T": "Send test-sms",
    "save_AD_T": "Gem som kladde",
    "back_TE_T": "Tilbage til Rediger",
    "reset_tex": "Nulstil",
    "update_tex": "Opdatering",
    "dum_msg1": "For at vise kundenavn, brug %% kundenavn %% i skabelonen",
    "dum_msg2": "For at vise reservationstidspunkt skal du bruge %% date_time %% i skabelonen",
    "dum_msg3": "For at vise tabelnummeret, brug %% table_no %% i skabelonen",
    "embedded_link": "Indlejret link",
    "img_title": "Billede",
    "img_input_text": "Tekst:",
    "img_join_text": " at komme med på vores liste",
    "img_copy_text": "Kopi",
    "img_not_found": "Genereret billede blev ikke fundet!!",
    "or_text": "ELLER",
    "web_signup_image_content": "Ved at indsende denne formular og tilmelde dig via sms, giver du dit samtykke til at modtage markedsføringstekstbeskeder (såsom kampagnekoder og påmindelser). Besked- og datatakster kan være gældende. Beskedfrekvensen varierer. Du kan til enhver tid afmelde dig ved at svare STOP",
    "img_download_success": "Billedet blev downloadet",
    "embedded_link_copied": "Indlejret link blev kopieret",
    "media_url_required": "Medie eller url er påkrævet",
    "invalid_image_url": "Ugyldig billedwebadresse",
    "invalid_file": "Filen er ugyldig",
    "image_error_1mb": "Indsæt venligst et billede, der er mindre end 1 MB",
    "image_error_2_5mb": "Indsæt venligst et billede, der er mindre end 2,5 MB",
    "image_error_3mb": "Indsæt venligst et billede, der er mindre end 3 MB",
    "change_title": "Forandring",
    "drop_file_text": "Slip filen her, eller klik for at uploade",
    "apply_text": "Anvende",
    "search_here": "Søg her",
    "update_status": "Opdater status",
    "reservation_deleted_permanently": "Denne reservation vil blive slettet permanent.",
    "table_text": "Tabeller",
    "add_table_button": "Tilføj ny tabel",
    "add_table_text": "Tilføj tabel",
    "edit_table_text": "Rediger tabel",
    "table_delete_text": "Denne tabel vil blive slettet permanent.",
    "multiple_table_delete_text": "Disse tabeller vil blive slettet permanent.",
    "table_error_name": "Indtast venligst tabelnavnet",
    "table_error_invalid_name": "Indtast venligst et gyldigt tabelnavn",
    "table_error_floor": "Vælg venligst gulvet",
    "table_error_select": "Vælg venligst tabellen",
    "table_capacity_text": "Bordkapacitet",
    "capacity_text": "Kapacitet",
    "table_occasion_text": "Find dit bord til enhver lejlighed",
    "table_name_text": "Tabel navn",
    "table_capacity_error": "Bordkapacitet er påkrævet",
    "cancel_text": "Ophæve",
    "submit_text": "Indsend",
    "select_valid_date": "Vælg gyldig dato",
    "select_valid_time": "Vælg gyldig tid",
    "valid_contact_number": "Indtast gyldigt kontaktnummer",
    "date_req": "Dato er påkrævet",
    "date_error_later_than_today": "Datoen skal være senere end eller lig med dagens dato",
    "time_error_later_than_5_minutes": "Vælg tid mindst 5 minutter senere fra det aktuelle tidspunkt",
    "number_person_req": "Antal personer er påkrævet",
    "contact_no_req": "Kontaktnummer er påkrævet",
    "contact_no": "Kontaktnummer",
    "select_time": "Vælg Tid",
    "for_how_many_person": "For hvor mange personer?",
    "f_name": "Fornavn",
    "l_name": "Efternavn",
    "business_name": "Virksomhedens navn",
    "business_no": "Virksomhedsnummer",
    "no_for_sign_in": "Dette nummer bruges til login",
    "business_email": "Business e-mail",
    "notes_tex": "Noter",
    "floor_deleted_permanently": "Denne etage vil blive slettet permanent.",
    "add_floor": "Tilføj etage",
    "edit_floor": "Rediger etage",
    "name_text": "Navn",
    "mobile_no": "Mobil nr.",
    "person_text": "Person",
    "date_and_time": "Dato og tid",
    "actions_text": "Handlinger",
    "extra_text": "Ekstra",
    "floor_name_req": "Etagenavn er påkrævet",
    "floor_name": "Etagenavn",
    "status_text": "Status",
    "category_status_req": "Kategoristatus er påkrævet",
    "table_deleted_permanently": "Denne tabel vil blive slettet permanent.",
    "tables_deleted_permanently": "Disse tabeller vil blive slettet permanent.",
    "back_to_home": "Tilbage til Hjemmet",
    "link_copied_text": "Linket er kopieret til udklipsholderen",
    "cust_dash_head_to_title": "til",
    "cust_dash_head_location_title": "Beliggenhed",
    "select_location_title": "Vælg Placering",
    "all_locations_label": "alle_lokationer",
    "rep_customer_feedback_analytics": "😊 Spor din kundefeedback med analyser i realtid",
    "rep_customer_txt": "Kunder",
    "rep_delete_title": "Dette omdømme vil blive slettet permanent.",
    "rep_qr_def_txt": "Scan QR-koden og del din værdifulde feedback med os vedrørende din oplevelse.",
    "delete_title": "Slet",
    "user_list_title": "Liste over kunder",
    "os_nfc_txt": "NFC-kort",
    "os_sign_holder_txt": "Skilteholderstativ",
    "os_store_Sign_holder_txt": "Butiksskiltholdere",
    "StaT": "Statistik",
    "AllgT": "Alle grupper",
    "FavT": "Favorit",
    "MostActT": "Mest aktive",
    "grT": "Gruppenavn",
    "keywT": "Nøgleord",
    "exSubT": "Besked til eksisterende abonnent",
    "ArchT": "Arkiveret",
    "gNotiSms": "Giv mig besked om hver ny kontakt via SMS",
    "gNotiEmail": "Giv mig besked om hver ny kontakt via e-mail",
    "reqGName": "Gruppenavn er påkrævet",
    "validGN": "Indtast et gyldigt gruppenavn",
    "valid_phone_no": "Indtast gyldigt telefonnummer",
    "phone_no_req": "Telefonnummer er påkrævet",
    "required_message_text": "Besked er påkrævet",
    "required_discount_text": "Rabat er påkrævet",
    "less_than_discount": "Rabatbeløbet skal være mindre end 5000",
    "discount_percentage_invalid": "Rabatprocenten skal være mindre end eller lig med 100",
    "discount_type_req": "Rabattype er påkrævet",
    "discount_text_req": "Kupontekst er påkrævet",
    "reqContactNo": "Kontaktnummer er påkrævet",
    "reqMsgNT": "Kampagnetitel er påkrævet",
    "reqLinkT": "Link er påkrævet",
    "delMGT": "Disse grupper vil blive slettet permanent.",
    "defMemMsg": "Skriv din besked her",
    "add_cust_to_grp_title": "Føj klient til gruppe",
    "group_title": "Grupper",
    "group_create_title": "Opret gruppe",
    "group_name_txt": "Gruppenavn",
    "group_table_keyword_title": "Søgeord",
    "actions_title": "Handlinger",
    "clients_title": "Kunder",
    "send_title": "Sende",
    "qr_title": "QR",
    "delete_group_txt": "Denne gruppe vil blive slettet permanent.",
    "delete_groups_txt": "Disse grupper vil blive slettet permanent.",
    "delete_client_title": "Denne klient vil blive slettet permanent.",
    "delete_clients_title": "Denne klient vil blive slettet permanent.",
    "delete_multiple_title": "Slet flere",
    "wel_sms_mms": "Send velkomst SMS\/MMS?",
    "key_words_title": "Nøgleord",
    "srch_plch_txt": "Søg her",
    "req_location_id_title": "Placering er påkrævet",
    "create_text": "Skabe",
    "view_text": "Udsigt",
    "immediately": "Straks",
    "business_name_req": "Virksomhedsnavn er påkrævet",
    "business_no_req": "Virksomhedsnummer er påkrævet",
    "valid_business_name": "Indtast gyldigt virksomhedsnavn",
    "valid_business_no": "Indtast gyldigt virksomhedsnummer",
    "address_req": "Adresse er påkrævet",
    "valid_address": "Vælg venligst gyldig adresse",
    "time_zone_req": "Tidszone er påkrævet",
    "image_req": "Indsæt venligst billede, der er mindre end 2,5 MB",
    "valid_file": "Filen er ugyldig.",
    "logo": "Logo",
    "time_zone": "Tidszone",
    "save": "Spare",
    "account_type_req": "Kontotype er påkrævet",
    "gst_no_req": "GST-nummer er påkrævet",
    "gst_no_valid": "Indtast et gyldigt GST-nummer",
    "set_up_payments": "Opret betalinger",
    "billing_details": "Indtast dine faktureringsoplysninger",
    "billing_details_desc": "Dine faktureringsoplysninger vil blive vist på din månedlige faktura fra menuonline.",
    "account_type": "Kontotype",
    "select_account_type": "Vælg kontotype",
    "gst_no": "GST nummer",
    "transaction_details": "Transaktionsdetaljer",
    "payment_method": "Betalingsmetode",
    "billing_period": "Faktureringsperiode",
    "paid_by": "Betalt af",
    "download": "Download",
    "pay_now": "Betal nu",
    "pull_down_refresh": "Træk ned for at opdatere",
    "release_refresh": "Slip for at opdatere",
    "billing_details_text": "Faktureringsoplysninger",
    "payment_methods": "Betalingsmetoder",
    "invoice": "Faktura",
    "invoice_to": "Faktura til:",
    "issue_date": "Udstedelsesdato",
    "due_date": "Forfaldsdato",
    "amount_due": "Forfaldent beløb",
    "charges": "Afgifter",
    "sub_total_capitalize": "Subtotal",
    "hst": "HST",
    "grand_total": "Grand Total",
    "invoice_generated_on": "Faktura genereret den",
    "contact_us": "Kontakt os",
    "invoice_issue": "Hvis du har gennemgået din regning og stadig har spørgsmål",
    "call": "Ring",
    "send_sms": "Send SMS",
    "payment_success": "Betaling er vellykket",
    "edit_credit_card": "Rediger kreditkort",
    "add_credit_card": "Tilføj kreditkort",
    "modify_card_info": "Rediger dine kortoplysninger",
    "enter_card_info": "Indtast dine kortoplysninger",
    "account_no_req": "Kontonummer er påkrævet",
    "card_name_req": "Navn på kortet er påkrævet",
    "expiry_date_req": "Udløbsdato er påkrævet",
    "valid_expiry_date": "Indtast gyldig udløbsdato",
    "valid_cvv": "Indtast gyldig cvv",
    "cvv_req": "CVV er påkrævet",
    "card_no": "Kortnummer",
    "name_of_card": "Navn på kortet",
    "expiry": "Udløb",
    "mm_text": "MM\/ÅÅ",
    "cvv": "CVV",
    "set_as_default": "Indstil som standard",
    "add_new_card": "Tilføj nyt kort",
    "all_credit_card": "Alle dine kreditkort",
    "fastest_checkout": "Hurtigste check-out med sikker og pålidelig platform, betroet af en stor gruppe af virksomheder",
    "coupon_button_req": "Kuponknaptekst er påkrævet",
    "max19_characters_allowed": "Maksimalt 19 tegn er tilladt",
    "fine_print_text_req": "Tekst med småt er påkrævet",
    "clients_req": "Vælg mindst én klient",
    "client_groups": "Kundegrupper",
    "day_text": "Dag",
    "valid_days": "Dagværdien er for stor indtast gyldige dage",
    "hours_text": "Timer",
    "enter_valid_hours": "Timeværdien er for stor indtast gyldige timer",
    "enter_valid_min": "Minutters værdi er for stor indtast gyldige minutter",
    "clients_with_connection_required": "Klienter med forbindelse i den sidste er påkrævet",
    "connection_required": "Tilslutning er påkrævet",
    "connection_value_too_large": "Forbindelsesværdien er for stor indtast gyldig forbindelse",
    "minutes_required": "Referater påkrævet",
    "clients_with_at_least_connection_required": "Klienter med mindst forbindelse er påkrævet",
    "last_minutes_req": "Med de sidste minutter er påkrævet",
    "clients_not_returned_required": "Hvem der ikke vendte tilbage i de sidste minutter er påkrævet",
    "not_return_in_last_minutes_invalid": "Ikke retur i sidste minutter skal være mindre end de sidste minutter.",
    "customerGroups": "Kundegrupper",
    "select_customer_group": "Vælg venligst kundegruppen",
    "location_required": "Placering er påkrævet",
    "start_date_required": "Startdato er påkrævet",
    "start_date_invalid": "Startdatoen er ugyldig",
    "start_date_invalid_error": "Startdatoen skal være større end eller lig med i dag",
    "recurring": "Tilbagevendende",
    "end_date_required": "Slutdato er påkrævet",
    "end_date_invalid": "Slutdatoen er ugyldig",
    "end_date_invalid_error": "Slutdatoen skal være senere end startdatoen",
    "time_invalid": "Tiden er ugyldig",
    "monthly_text": "Månedlig",
    "send_day_req": "Send på dagen er påkrævet",
    "loyal_clients": "Loyale kunder",
    "recent_clients": "Seneste kunder",
    "lapsed_clients": "bortfaldne kunder",
    "clients_connection_date": "Klienter efter tilslutningsdato",
    "scheduled_text": "Planlagt",
    "weekly": "Ugentlig",
    "selected_clients_not_subscriber": "Udvalgte kunder er ikke abonnenter.",
    "message_preview_title": "Forhåndsvisning af besked",
    "coupon_preview_title": "Forhåndsvisning af kupon",
    "form_text": "Form",
    "preview_text": "Forhåndsvisning",
    "next_text": "Næste",
    "send_test_SMS": "Send test-sms",
    "save_draft": "Gem som kladde",
    "back_to_edit": "Tilbage til Rediger",
    "select_payment_method": "Vælg venligst betalingsmetoden",
    "schedule_SMS": "Planlæg SMS",
    "send_now": "Send nu",
    "get_more_ratings": "Få flere bedømmelser",
    "overview": "Oversigt",
    "reset_campaign": "Nulstil kampagne",
    "permissions": "Tilladelser",
    "location_name": "Stedets navn",
    "phone_no": "Telefonnummer",
    "location_email_address": "Placering e-mailadresse",
    "located_business": "Hvor ligger din virksomhed?",
    "business_logo": "Forretningslogo",
    "congratulations": "Tillykke",
    "almost_done": "Du er næsten færdig",
    "publish": "Offentliggøre",
    "about_your_business": "Om din virksomhed",
    "add_your_location": "Tilføj din placering",
    "publish_location": "Udgiv placering",
    "location_name_req": "Placeringsnavn er påkrævet",
    "valid_location_name": "Indtast et gyldigt stednavn",
    "business_logo_req": "Virksomhedslogo er påkrævet",
    "please_accept_terms": "Accepter venligst vilkår og betingelser",
    "add_new_location": "Tilføj ny placering",
    "edit_location": "Rediger placering",
    "add_location": "Tilføj placering",
    "existing_msg_subscriber_txt": "Besked til eksisterende abonnent",
    "msg_capitalize_txt": "Besked",
    "group_noti_sms": "Giv mig besked om hver ny kontakt via SMS",
    "group_noti_email": "Giv mig besked om hver ny kontakt via e-mail",
    "group_member_msg": "Det ser ud til, at du allerede er en del af vores gruppe!!",
    "group_mend_msg": "STOP for at afslutte. HJÆLP til hjælp. SMS- og datatakster kan være gældende",
    "Disclaimer_title": "Ansvarsfraskrivelsestekst: ",
    "group_def_msg": "Vi er online nu! Tjek vores servicemenu ud og bestil din næste tid i dag.",
    "required_msg_txt": "Besked er påkrævet",
    "required_Key_txt": "Nøgleord er påkrævet",
    "required_mem_msg": "Abonnentbesked er påkrævet",
    "client_list_title": "Kundeliste",
    "add_contact_txt": "Tilføj kontakt",
    "delete_all_clients_txt": "Slet alle klienter",
    "delete_all_clients_msg": "Er du sikker på, at du vil slette alle klienter? De kan ikke hentes.",
    "delete_all_txt": "Slet alle",
    "timeline_title": "Tidslinje",
    "unsubscribe_title": "Afmeld",
    "subscribe_title": "Abonner",
    "unsubscribe_confirm_msg": "Vil du markere denne kunde som afmelder?",
    "subscribe_confirm_msg": "Vil du markere denne kunde som abonnent?",
    "no_title": "Ingen",
    "yes_title": "Ja",
    "client_name_title": "Kundens navn",
    "source_title": "Kilde",
    "contact_file_Req": "Kontakter-fil er påkrævet",
    "req_title": "Påkrævet",
    "opt_in_req": "Importerede kontakter skal være 100 % opt-in",
    "image_invalid_error": "Filen er ugyldig.",
    "import_contacts_msg": "Importer kontakter",
    "csv_upload_title": "CSV-kontaktfil til upload *",
    "csv_file_desc": "Upload kun csv-fil - csv-filkolonner - Fornavn, Efternavn, E-mail-id, Mobilnr, Køn, DOB(MM\/DD\/ÅÅÅÅ) - ingen mellemrum, bindestreger eller parenteser i mobilnr.",
    "to_download_title": "For at downloade demofil klik her",
    "contains_header_title": "Ja, denne fil indeholder overskrifter",
    "opt_in_100_txt": "Ja, disse importerede kontakter er 100 % tilvalgte",
    "DisT": "Ansvarsfraskrivelsestekst: ",
    "gMendMsg": "STOP for at afslutte. HJÆLP til hjælp. SMS- og datatakster kan være gældende",
    "reqKeyT": "Nøgleord er påkrævet",
    "reqMemMsg": "Abonnentbesked er påkrævet",
    "reqMsgT": "Besked er påkrævet",
    "gMemberMsg": "Det ser ud til, at du allerede er en del af vores gruppe!!",
    "gdefMsg": "Vi er online nu! Tjek vores servicemenu ud og bestil din næste tid i dag.",
    "next_title": "Næste",
    "male_title": "Han",
    "male_val": "han-",
    "female_title": "Kvinde",
    "female_val": "kvinde",
    "others_txt": "Andre",
    "others_val": "andre",
    "validBirthdate": "Indtast gyldig fødselsdato",
    "valid_phone_no_title": "Indtast gyldigt telefonnummer",
    "required_phone_no_title": "Telefonnummer er påkrævet",
    "add_new_client_txt": "Tilføj en ny klient",
    "update_client_txt": "Opdater klient",
    "phone_num_text": "Telefonnummer",
    "dob_title": "DOB",
    "select_gender_title": "Vælg køn",
    "timelineTitle": "Tidslinje",
    "confirm_location": "Bekræft placering",
    "feedback_title": "Feedback",
    "rep_question_1": "Var forretningen ren?",
    "rep_que_2": "Var din mad god?",
    "rep_que_3": "Var vores service venlig?",
    "rep_que_4": "Var vores service hurtig?",
    "business_location_req_title": "Virksomhedsplacering er påkrævet",
    "valid_location_err_txt": "Vælg venligst gyldig adresse",
    "rep_management_title": "Få flere anmeldelser Management",
    "rep_about_desc": "Omdømme hjælper dig med at øge Google-vurderingen ved at bede dine kunder om feedback",
    "preview_title": "Forhåndsvisning",
    "rep_preview_txt": "Forhåndsvisning af omdømme",
    "back_title": "Tilbage",
    "fine_print_txt": "Indløs venligst med kassen",
    "redeem_me_title": "Forløs mig",
    "rep_nxt_visit_txt": "Ved dit næste besøg",
    "device_type": "Type anvendt enhed",
    "connection_method": "Tilslutningsmetode",
    "peak_days": "Højeste dage",
    "peak_hours": "Spidse timer",
    "guest_by_day": "Gæst om dagen",
    "guest_visit": "Gæstebesøg",
    "connection": "Forbindelse",
    "connection_duration": "Forbindelsens varighed",
    "guest_visit_1": "1 gang",
    "guest_visit_2": "2 gange",
    "guest_visit_3_5": "3-5 gange",
    "guest_visit_6_10": "6-10 gange",
    "guest_visit_11_25": "11-25 gange",
    "guest_visit_26_100": "26-100 gange",
    "guest_visit_100_plus": "100+ gange",
    "device_android_total": "Total Android",
    "device_android": "Android",
    "device_desktop": "Desktop",
    "device_ios": "Ios",
    "device_ios_total": "Total Ios",
    "device_desktop_total": "Total Desktop",
    "connection_duration_10": "<=10 min",
    "connection_duration_20": "11-20 min",
    "connection_duration_30": "21-30 min",
    "connection_duration_40": "31-45 min",
    "connection_duration_60": "46-60 min",
    "connection_method_email": "E-mail",
    "connection_method_sms": "SMS",
    "connection_method_google": "Google",
    "connection_method_facebook": "Facebook",
    "age_category_1": "<18",
    "age_category_2": "18-24",
    "age_category_3": "25-34",
    "age_category_4": "35-44",
    "age_category_5": "45-54",
    "age_category_6": "55+",
    "all_guest_txt": "Alle gæster",
    "new_Guest_txt": "Ny gæst",
    "connections_txt": "Forbindelser",
    "hotspot": "Hotspot",
    "hotspot_list": "Hotspot liste",
    "add_new_hotspot": "Tilføj nyt hotspot",
    "hotspot_information": "Hotspot information",
    "edit_details_button": "Rediger detaljer",
    "wifi_info_message": "Tilslut og nyd gratis WiFi",
    "connection_message": "Super, du er blevet forbundet til ",
    "connection_message_suffix": " WiFi",
    "wifi": "WiFi",
    "login_to_access": "Log ind for at få adgang",
    "verification_code": "Bekræftelseskode",
    "verification_code_message": "Indtast venligst bekræftelseskoden sendt til ",
    "wifi_user_email": "adamo@gmail.com",
    "wifi_label": "Wifi",
    "your_name": "Hvad er dit navn forresten?",
    "your_birthdate": "Kan du fortælle os, hvad er din fødselsdato?",
    "request_guest_wifi_name": "Indtast venligst gæstens Wifi-navn",
    "request_device_key": "Indtast venligst enhedsnøgle",
    "request_maximum_internet_access_length": "Vælg venligst maksimal internetadgangslængde",
    "mac_address": "MAC-adresse",
    "device_port": "Enhedsport",
    "hardware": "Hardware",
    "current_uptime": "Aktuel oppetid",
    "nearby_devices": "Enheder i nærheden",
    "firmware": "Firmware",
    "who_are_you": "Hvem er du?",
    "where_to_contact_you": "Hvor kommer vi i kontakt med dig?",
    "your_area_code": "Hvad er dit områdenummer?",
    "connected": "Forbundet",
    "delete_hotspot_message": "Dette hotspot vil blive slettet permanent.",
    "delete_multiple_hotspots_message": "Disse hotspots vil blive slettet permanent.",
    "speed_error": "Hastigheden skal være mindst 0,01",
    "speed_max_error": "Indtast venligst en værdi op til 1024 for ubegrænset hastighed, eller vælg en lavere værdi inden for det understøttede område",
    "device_ssid": "Enhedens SSID",
    "device_ssid_two": "Enhed SSID to",
    "device_ssid_two_wpa": "Enhedens SSID To WPA",
    "device_key": "Enhedsnøgle",
    "select_location": "Vælg Placering",
    "select_maximum_internet_access_length": "Vælg Maksimal internetadgangslængde",
    "download_speed": "Download hastighed",
    "upload_speed": "Uploadhastighed",
    "network_length_1": "15 minutter",
    "network_length_2": "30 minutter",
    "network_length_3": "45 minutter",
    "network_length_4": "1 time",
    "network_length_5": "2 timer",
    "network_length_6": "4 timer",
    "network_length_7": "6 timer",
    "network_length_8": "8 timer",
    "network_length_9": "10 timer",
    "network_length_10": "12 timer",
    "employee_wifi_name": "Medarbejder Wifi Navn",
    "employee_wifi_password": "Wifi-adgangskode for medarbejder",
    "guest_wifi_name": "Gæstens Wifi-navn",
    "menu_other_products_txt": "Andre produkter | Menu online",
    "menu_home_text": "Hjem | Menu online",
    "whatsapp_title": "Whatsapp",
    "select_campaign_type": "Vælg en kampagnetype",
    "select_readymade_templates": "Vælg mellem færdiglavede skabeloner eller lav dine egne",
    "campaign_title_required": "Kampagnetitel er påkrævet",
    "type_here": "Skriv her...",
    "location_permission_req": "Placeringstilladelse er påkrævet",
    "platform_permission_req": "Der kræves platformstilladelse",
    "profile_picture": "Profilbillede",
    "click_to_upload": "Klik for at uploade",
    "location_permission": "Placeringstilladelse",
    "pin": "Stift",
    "platform_permission": "Platform tilladelse",
    "set_pin": "Indstil pinkode",
    "list_of_users": "Liste over brugere",
    "create_user": "Opret bruger",
    "terms_and_condition_req": "Vilkår og betingelser er påkrævet",
    "terms_and_conditions": "Vilkår og betingelser",
    "recommended": "ANBEFALET",
    "mo": "\/md",
    "unsubscribe": "Afmeld",
    "cancel_subscription": "Er du sikker på, at du vil annullere abonnementet?",
    "upgrade": "Opgrader",
    "plan_upgraded_successfully": "Planen blev opgraderet med succes!",
    "menu_online": "Menu online",
    "support": "Støtte",
    "wifiC": "WIFI",
    "billing": "Fakturering",
    "please_upgrade": "Opgrader venligst for at nyde alle funktioner",
    "you_have_subscribed": "Du har abonneret på ",
    "plan": "plan",
    "of_text": "af",
    "days": "dage",
    "remaining_days_until_plan": " Dage tilbage, indtil din plan kræver opdatering",
    "manage_your_screens": "Administrer dine skærme",
    "screens_you_save": "Skærme du har",
    "add_more_screens": "Tilføj flere skærme",
    "addition_screen": "Tilføjelsesskærm",
    "per_screen": "$ per skærm",
    "per_box": "$ per kasse",
    "shipping_changes": "Forsendelsesomkostninger",
    "upto4_boxes": "op til 4 kasser ",
    "charge_now": "Du vil få opladning nu",
    "updated_reoccuring": "Din opdaterede gentagelse vil være",
    "current_reoccuring": "Aktuelt tilbagevendende: ",
    "no_text": "Ingen",
    "android_box": "Android boks",
    "old_password_req": "Gammel adgangskode er påkrævet",
    "new_password_req": "Ny adgangskode er påkrævet",
    "confirm_password_req": "Bekræft adgangskode er påkrævet",
    "password_do_not_match": "Adgangskoder stemmer ikke overens",
    "old_password": "Gammel adgangskode",
    "new_password": "Ny adgangskode",
    "confirm_password": "Bekræft adgangskode",
    "copy_working_hours": "Vil du kopiere disse arbejdstider til alle dage?",
    "yes_copy": "Ja, kopi",
    "closed": "Lukket",
    "opening_time": "Åbningstid",
    "closing_time": "Lukketid",
    "description": "Beskrivelse",
    "file_exist": "fil eksisterer allerede, vælg venligst en anden fil",
    "bussiness_images": "Business billeder",
    "display_info_on_market_place": "Vil du vise nedenstående oplysninger på din markedsplads?",
    "profile_ready": "Din profil er klar!",
    "client_book_with_online": "Kunder kan nu booke hos dig online. Del linket nedenfor for at komme i gang.",
    "copy_link": "Kopiér link",
    "see_your_profile": "Se din profil",
    "ok_got_it": "Ok, forstået",
    "preview_profile": "Se et eksempel på din profil og se, hvordan den ville se ud.",
    "opening_hours": "Åbningstider",
    "display_data": "Vis data",
    "manage_profile": "Administrer profil",
    "phone_req": "Telefon er påkrævet",
    "market_place_img_req": "Markedspladsbilleder er påkrævet",
    "add_new_market_place": "Tilføj ny markedsplads",
    "edit_market_place": "Rediger MarketPlace",
    "no_reviews_yet": "Ingen anmeldelser endnu",
    "good": "God",
    "average": "Gennemsnit",
    "add_marketPlace": "Tilføj MarketPlace",
    "all_title": "Alle",
    "included_title": "Inkluderet",
    "excluded_title": "Udelukket",
    "clients_subscribed": "Kunder abonnerede i sidste",
    "clients_groups": "Kundegrupper",
    "customer_groups": "Kundegrupper",
    "audience_title": "Publikum",
    "client_gt5": "som ikke vendte tilbage i det sidste",
    "select_all": "Vælg alle",
    "modify": "Modificere",
    "campaign_title": "Kampagnetitel",
    "msg_type": "Meddelelsestype",
    "enter_discount": "Indtast rabat",
    "discount_type": "Rabattype",
    "coupon_text": "Kupon tekst",
    "enter_coupon_text": "Indtast kupontekst",
    "coupon_button_text": "Kuponknaptekst",
    "enter_coupon_button_text": "Indtast kuponknaptekst",
    "fine_prin": "Fint Print",
    "enter_fine_prin": "Indtast tekst med småt",
    "campaign_dec": "Du kan ændre kampagnen 30 minutter før dens starttidspunkt. Gebyrer for SMS-kampagne vil blive foregået 30 minutter før kampagnens starttidspunkt.",
    "blast_text_message_dec": "Din blast-sms er klar. Vælg din betalingsmetode og send dine beskeder.",
    "payment_completed": " Betalingen gennemføres kl ",
    "total_cost": "Samlede omkostninger",
    "close_title": "Tæt",
    "friday": "fredag",
    "saturday": "lørdag",
    "sunday": "søndag",
    "thursday": "torsdag",
    "tuesday": "tirsdag",
    "wednesday": "onsdag",
    "port_txt": "Havn",
    "today_title": "I dag",
    "yesterday_title": "I går",
    "last_30_days_txt": "Sidste 30 dage",
    "this_month_txt": "Denne måned",
    "last_month_txt": "Sidste måned",
    "valid_date_title": "Vælg gyldig dato",
    "valid_business_name_txt": "Indtast gyldigt virksomhedsnavn",
    "req_bus_add_txt": "Virksomhedsadresse er påkrævet",
    "req_domain_name_txt": "Domænenavn er påkrævet",
    "basic_info_txt": "Grundlæggende oplysninger om din virksomhed",
    "loyalty_qr_def_txt": "Scan QR-koden, tilmeld dig vores loyalitetshullekort, og forkæl dig selv med belønninger.",
    "last_stamp_txt": "Sidste stempel",
    "collected_on_txt": "Samlet på",
    "stamp_details_txt": "Stempeldetaljer",
    "add_stamp_txt": "Tilføj stempel",
    "choose_brand_color_txt": "Vælg dit mærkefarve og sprog 🎨",
    "change_anytime_txt": "Du kan ændre dette når som helst",
    "like_to_get_txt": "Vil du gerne få",
    "your_next_visit_txt": "på dit næste besøg?",
    "time_to_time_get_offers": "Kunne du tænke dig at modtage gode tilbud og kampagner fra tid til anden?",
    "respond_back_to_you": "Vil du have nogen til at svare dig?",
    "input_serve_better": "Dit input vil hjælpe vores team med at tjene dig bedre!",
    "do_it_txt": "Lad os gøre det",
    "take_15_s": "Tager 15 sekunder",
    "rate_on_google_desc": "Vil du være så venlig at bedømme os på google?",
    "may_be_next_time": "Måske næste gang",
    "thank_you_txt": "Tak",
    "look_next_txt": "Vi ser frem til dit næste besøg!",
    "deleteUserTitle": "Denne bruger vil blive slettet permanent.",
    "deleteMUserTitle": "Denne bruger vil blive slettet permanent.",
    "change_pin": "Skift pin",
    "area_code": "Hvad er dit områdenummer? ",
    "add_menu": "Tilføj menu",
    "menu_name": "Menunavn",
    "add_menu_placeholder": "Pizza, Burger, Baverages osv",
    "enable_data_Collection": "Aktiver dataindsamling",
    "add_new_menu": "Tilføj ny menu",
    "rename_menu": "Omdøb menu",
    "preview": "Forhåndsvisning",
    "generate_qr": "Genereret QR",
    "edit_menu": "Rediger menu",
    "remove_menu": "Fjern menu",
    "menu_delete_msg": "Denne menu vil blive slettet permanent",
    "menus_delete_msg": "Disse menuer vil blive slettet permanent",
    "view_menu_dialoage_msg": "Vil du gerne have en gave på din fødselsdag?",
    "skip": "Springe",
    "cliam_your_gift": "Gør krav på din gave",
    "collect_form": "Saml formular",
    "enter_first_name": "Indtast fornavn",
    "enter_last_name": "Indtast efternavn",
    "enter_email": "Indtast e-mail",
    "enter_dob": "Indtast fødselsdato",
    "enter_number": "Indtast nummer",
    "select_gender": "Vælg køn",
    "congratulations_desc": "🎉 Tillykke! Din anmodning om gavekrav er blevet indsendt. Vores team vil kontakte dig snarest. 🎉",
    "male_heading": "Han",
    "male_text": "han-",
    "female_heading": "Kvinde",
    "female_text": "kvinde",
    "others_heading": "Andre",
    "other_text": "andre",
    "BirthD": "Fødselsdato",
    "GenderT": "Køn",
    "EmailT": "E-mail",
    "dobT": "DOB",
    "capitalize_menu": "Menu",
    "select_menu": "Vælg menu",
    "manage_variant": "Administrer variant",
    "add_products": "Tilføj produkter",
    "add_category": "Tilføj kategori",
    "category_delete": "Denne kategori vil blive slettet permanent",
    "variation_delete": "Denne variant vil blive slettet fra produktet.",
    "product_delete": "Dette produkt vil blive slettet permanent.",
    "categories_delete": "Disse kategorier vil blive slettet permanent.",
    "products_delete": "Disse produkter vil blive slettet permanent.",
    "category": "KATEGORI",
    "price": "PRIS",
    "food_product_placeholder": "Pizza, Burger, Baverages osv",
    "active_title": "Aktiv",
    "inActive_title": "Inaktiv",
    "status_capital": "STATUS",
    "cat_status_require": "Kategoristatus er påkrævet",
    "cat_name_require": "Kategorinavn er påkrævet",
    "category_name": "Kategorinavn",
    "status": "Status",
    "lgDay1": "mandag",
    "lgDay2": "tirsdag",
    "lgDay3": "onsdag",
    "lgDay4": "torsdag",
    "lgDay5": "fredag",
    "lgDay6": "lørdag",
    "lgDay7": "søndag",
    "is_closed_title": "Lukket",
    "book_table_title": "Book et bord",
    "emailErrSub": "E-mail Emne er påkrævet",
    "email_subject": "E-mail emne",
    "contactUsfrmTitleEmail": "Svar på e-mail",
    "companyInfo": "Lorem Ipsum er lorem hrig, lorem ipsum er fraets.Lorem Ipsum er lorem hrig, lorem ipsum er fraets.Lorem Ipsum er lorem hrig, lorem ipsum er fraets.",
    "footerSTitle1": "Om Menuonline",
    "footerSTitle1Lnk2": "Funktioner",
    "footerSTitle1Lnk3": "Blog",
    "footerSTitle2": "Legal",
    "footerSTitle2Lnk1": "Vilkår og betingelser",
    "footerSTitle2Lnk2": "Privatlivspolitik",
    "footerSTitle3": "Til Erhverv",
    "footerSTitle3Lnk1": "For partnere",
    "footerSTitle3Lnk2": "Prissætning",
    "footerSTitle3Lnk3": "Support til partnere",
    "footerCopyRithgTxt": "  Menuonline eller dets tilknyttede selskaber",
    "homeTitle": "Hjem",
    "contactTitle": "Kontakt os",
    "aboutTitle": "Om os",
    "homeMenuTitle": "Menu",
    "bookTableTitle": "Book et bord",
    "vDateT": "Vælg gyldig dato",
    "reqDateT": "Dato er påkrævet",
    "dateGtErr": "Datoen skal være senere end eller lig med dagens dato",
    "timeInvalid": "Tiden er ugyldig",
    "reqTimeT": "Der kræves tid",
    "timeInvalidBfr": "Vælg tid mindst 5 minutter senere fra det aktuelle tidspunkt ",
    "PerReq": "Antal personer er påkrævet",
    "validcNoT": "Indtast gyldigt kontaktnummer",
    "reqCrT": "Valuta er påkrævet",
    "reqPrT": "Pris er påkrævet",
    "reCateT": "Kategori er påkrævet",
    "reqVrT": "Variant er påkrævet",
    "reqVrVldT": "Indtast gyldig variant ",
    "validLNameT": "Indtast gyldigt efternavn",
    "sDateInValid": "Fra dato er ugyldig",
    "minmReq": "Minimum er påkrævet",
    "resLblDate": "Vælg dato",
    "resLblTime": "Vælg Tid",
    "perT": "For hvor mange personer?",
    "resLblEmail": "Angiv venligst din e-mail?",
    "resLblNote": "Tilføj en reservationsnote",
    "imageError1MB": "Indsæt venligst billede, der er mindre end 1mb",
    "imageError": "Indsæt venligst billede, der er mindre end 2,5 MB",
    "imageError3Mb": "Indsæt venligst billede, der er mindre end 3mb",
    "imageInVldError": "Filen er ugyldig.",
    "addProMT": "Produktmenu",
    "proT": "Produktnavn",
    "reqProT": "Produktnavn er påkrævet",
    "proPT": "Produkt Pris",
    "reservationSuccTitle": "Fantastisk, din reservation er udført",
    "book": "Bog",
    "custzName": "Tilpasningsnavn",
    "always": "Altid",
    "proImgReq": "Produktbillede er påkrævet",
    "selCustmT": "Vælg venligst Tilpasning eller opret ny",
    "visStR": "Synlighedsstatus er påkrævet",
    "avlblScR": "Vælg venligst tilgængelighedsplan",
    "addonPR": "Vælg venligst tilføjelsesprodukter",
    "upsellPR": "Vælg venligst mersalgsprodukter",
    "markItemR": "Vælg venligst marker vareprodukter",
    "caloriesR": "Kalorier er påkrævet",
    "allergensR": "Vælg venligst allergener produkter",
    "prepInstR": "Forberedelsesvejledning er påkrævet",
    "staffNR": "Medarbejdernotater er påkrævet",
    "discountR": "Rabat er påkrævet",
    "validDiscE": "Indtast gyldig rabat",
    "disday": "Om dagen",
    "plSelDayT": "Vælg venligst dag",
    "sTimeReq": "Starttidspunkt er påkrævet",
    "sTimeInValid": "Starttidspunktet er ugyldigt",
    "eTimeReq": "Sluttidspunkt er påkrævet",
    "eTimeInValid": "Sluttidspunktet er ugyldigt",
    "sDateReq": "Fra dato er påkrævet",
    "eDateReq": "Til dato er påkrævet",
    "eDateInValid": "Til dato er ugyldig",
    "disdate": "Fra Datoen",
    "disdate1": "Til dato",
    "disdate2": "Fra Dato",
    "currT": "Valuta",
    "iconR": "Vælg venligst markér elementikon",
    "minT": "Minimum",
    "maxT": "Maksimum",
    "itemNT": "Varens navn",
    "itemPT": "Varepris",
    "descProT": "Beskriv dit produkt",
    "cateT": "Kategori",
    "selProCateT": "Vælg din produktkategori",
    "reqMT": "Menunavn er påkrævet",
    "ViewMenu": "Vis menu",
    "CopyMenu": "Kopier menu",
    "EditMenu": "Rediger menu",
    "RemoveMenu": "Fjern menuen",
    "welcomeMnuTitle": "Velkommen til",
    "reviewT": "Anmeldelser",
    "userErrorMsg": "Beklager, der opstod en fejl!!!",
    "reqCateT": "Kategorinavn er påkrævet",
    "mngCustT": "Administrer tilpasning",
    "custNReq": "Tilpasningsnavn er påkrævet",
    "incReq": "Inkluderet er påkrævet",
    "minmValid": "Indtast en gyldig minimumsværdi",
    "maxmReq": "Maksimum er påkrævet",
    "cust": "Tilpasning",
    "crCust": "Opret ny tilpasning",
    "custList": "Tilpasningsliste",
    "delCustmzT": "Denne tilpasning vil blive slettet permanent.",
    "mkNameR": "Marker varenavn er påkrævet",
    "mkDelT": "Denne mærkevare vil blive slettet permanent.",
    "hideText": "Skjule",
    "showText": "Vise",
    "device_mac_txt": "Enhed Mac",
    "delete_hotspot_txt": "Dette hotspot vil blive slettet permanent.",
    "delete_hotspots_txt": "Disse hotspots vil blive slettet permanent.",
    "emp_wifi_name": "Medarbejder Wifi-navn",
    "max_limit_txt": "Indtast '1024' for hastighed for at angive den som UBEGRÆNSET. Denne værdi fjerner eventuelle hætter, hvilket tillader maksimal gennemstrømning",
    "device_port_txt": "Enhedsport",
    "firmware_txt": "Firmware",
    "hotspot_info_txt": "Hotspot information",
    "editDBtn": "Rediger detaljer",
    "birth_date": "Fødselsdato",
    "di_theme_clr": "Temafarve nøjagtigt 7 cifre",
    "color_Invalid_txt": "Vælg gyldig farvekode",
    "Req_theme_clr": "Temafarve er påkrævet",
    "select_valid_color_txt": "Vælg venligst gyldig temafarve",
    "req_redir_link": "Omdirigeringslink er påkrævet",
    "val_redir_link": "Indtast et gyldigt omdirigeringslink",
    "req_business_name_txt": "Virksomhedsnavn er påkrævet",
    "splash_preview": "Splash Preview",
    "create_new_splash": "Opret ny Splash",
    "splash_page": "Splash-side",
    "splash_page_builder": "Splash Page Builder",
    "redirect_link": "Omdirigeringslink",
    "theme_color": "Tema farve",
    "enable_social_login": "Aktiver muligheder for sociale medier for brugernes login",
    "google": "Google",
    "facebook": "Facebook",
    "is_mandatory": "Er det obligatorisk?",
    "field": "Felt",
    "name": "Navn",
    "first_name": "Fornavn",
    "last_name": "Efternavn",
    "birthdate": "Fødselsdato",
    "gender": "Køn",
    "email": "E-mail",
    "dob": "DOB",
    "zip_code": "Postnummer",
    "required_redirect_link": "Omdirigeringslink er påkrævet",
    "valid_redirect_link": "Indtast et gyldigt omdirigeringslink",
    "required_theme_color": "Temafarve er påkrævet",
    "theme_color_length": "Temafarve nøjagtigt 7 cifre",
    "required_name": "Navn er påkrævet",
    "delete_splash_message": "Vil du slette denne splash?",
    "delete_multiple_splashes_message": "Vil du slette disse stænk?",
    "user_login_required": "Brugerlogin er påkrævet",
    "set_theme_color": "Indstil temafarve",
    "splash_colllect_additional_info_txt": "Indsaml yderligere oplysninger",
    "verify_mobile_otp_message": "Vil du sende OTP for at bekræfte mobilnummeret?",
    "add_company": "Tilføj firma",
    "edit_company": "Rediger firma",
    "ds_device": "Digital Signage-enhed",
    "ds_device_activation_fee": "Aktiveringsgebyr for Digital Signage-enhed",
    "wifi_hardware": "Wifi hardware",
    "menu_design": "Menu design",
    "ds_hardware": "Digital Signage hardware",
    "company_name_req": "Firmanavn er påkrævet",
    "known_name_req": "Et kendt navn er påkrævet",
    "no_location_req": "Antal placering er påkrævet",
    "minimum_location": "Indtast venligst mindst 1 placering",
    "terms_req": "Vilkår er påkrævet",
    "notes_req": "Noter er påkrævet",
    "sms_charge_req": "SMS-gebyr er påkrævet",
    "menuonline_quantity_req": "Menu Online Antal er påkrævet",
    "menuonline_cost_req": "Menu Online Pris er påkrævet",
    "ds_quantity_req": "Digital skiltning mængde er påkrævet",
    "ds_monthly_cost_req": "Digital Signage Månedlig pris er påkrævet",
    "reputation_quantity_req": "Omdømmemængde er påkrævet",
    "reputation_cost_req": "Omdømmeomkostninger er påkrævet",
    "marketing_quantity_req": "Markedsføringsmængde er påkrævet",
    "marketing_cost_req": "Markedsføringsomkostninger er påkrævet",
    "tax_percentage_req": "Skatteprocent er påkrævet",
    "router_hardware_quantity_req": "Routerhardwaremængde er påkrævet",
    "wifi_hardware_cost_req": "WIFI hardware omkostninger er påkrævet",
    "activation_cost_req": "Aktiveringsomkostninger er påkrævet",
    "wifi_quantity_req": "WIFI-mængde er påkrævet",
    "wifi_router_req": "Wifi-router er påkrævet",
    "menu_design_quantity_req": "Menu Design Mængde er påkrævet",
    "menu_design_cost_req": "Menudesignomkostninger er påkrævet",
    "quantity_req": "Mængde er påkrævet",
    "cost_req": "Omkostninger er påkrævet",
    "sheduledown_quantity_req": "Der kræves et antal nedskrevne",
    "sheduledown_cost_req": "Regnskabsomkostninger er påkrævet",
    "loyalty_program_quantity_req": "Loyalitetsprogram Antal er påkrævet",
    "loyalty_program_cost_req": "Loyalitetsprogram Omkostninger er påkrævet",
    "loyalty_program_sms_quantity_req": "Loyalitetsprogram SMS-mængde er påkrævet",
    "loyalty_program_sms_cost_req": "Loyalitetsprogram SMS-omkostninger er påkrævet",
    "comapny_created": "Virksomhed oprettet med succes",
    "comapny_updated": "Virksomheden blev opdateret",
    "billing_info": "FAKTURERINGSOPLYSNINGER",
    "business_name_acc_holder": "Virksomhedsnavn (kontoindehaver)",
    "client_first_name": "Klientens fornavn",
    "client_last_name": "Klientens efternavn",
    "dba_known_as": "DBA kendt som",
    "business_address": "Forretningsadresse",
    "no_of_locations": "Antal lokationer",
    "package_details": "PAKKEDETALJER",
    "terms": "Vilkår",
    "one_month": "1 måned",
    "per_sms_charge": "Pris pr. SMS",
    "plan_test": "PLAN: TEST",
    "desc_capital": "BESKRIVELSE",
    "qty_capital": "ANTAL",
    "cost_capital": "KOSTE",
    "total_price_capital": "SAMLET PRIS",
    "quantity": "Mængde",
    "can_not_access": "Du kan ikke vælge mere end",
    "licenses": "licenser",
    "monthly_cost": "Månedlige omkostninger",
    "plan_cost": "Planomkostninger",
    "tax": "Skat",
    "tax_percentage": "Skatteprocent",
    "monthly_cost_after_tax": "Månedlige omkostninger efter skat",
    "activation_one_time_charge": "Aktivering og engangsopladning",
    "sub_total": "SUBTOTAL",
    "action": "Handling",
    "cost": "Koste",
    "shipping_charge": "Forsendelsesgebyr",
    "other": "Andre",
    "additional_cost": "Ekstra omkostninger (andre gebyrer)",
    "other_tax": "Anden skat",
    "total": "Total",
    "license_statistics": "Licensstatistik",
    "total_licenses": "Samlede licenser",
    "available_licenses": "Tilgængelige licenser",
    "stock": "Lager",
    "has_permission": "har tilladelse",
    "avg_stock_price": "AVG aktiekurs",
    "average_price": "Gennemsnitspris",
    "allocated": "Tildelt",
    "reward_regulars": "Beløn ​​stamgæster",
    "not_add_coupon_url": "Kan ikke tilføje kupon-URL. Beskedens maksimale længde er 160",
    "like_attach_coupon": "Vil du vedhæfte en kupon?",
    "advance_scheduling": "Forudgående planlægning",
    "choose_day": "Vælg dag(e) for at sende besked",
    "select_essage_window": "Vælg meddelelsesvindue",
    "subscription_outside_delivery_window": "Hvis en bruger abonnerer uden for leveringsvinduet, modtager de din autoresponder på det næste tilgængelige tidspunkt.",
    "remaining": "Tilbage",
    "processing_request": "Der opstod en fejl under behandlingen af ​​din anmodning.",
    "list_companies": "Liste over virksomheder",
    "are_you_sure": "Er du sikker..?",
    "signin_agreement": "Ved at bekræfte dette, vil virksomheden oprette uden at underskrive en aftale.",
    "status_updated": "Statusopdatering lykkedes",
    "status_failed": "Status kunne ikke opdateres",
    "new_editor_status_updated": "Status for DS New Editor er blevet opdateret.",
    "user_name": "Brugernavn",
    "known_as": "kendt som",
    "agreement_signed": "Aftale underskrevet",
    "deactivate": "Deaktiver",
    "activate": "Aktiver",
    "login_to_user_acc": "Log ind på brugerkonto",
    "disable": "Deaktiver",
    "enable": "Aktiver",
    "ds_new_editor": "DS Ny Redaktør",
    "sign_agreement": "Underskriv aftale",
    "agreement_sign": "Aftale uden underskrift",
    "view_agreement": "Se aftale",
    "download_agreement": "Download aftale",
    "add_deleted_user": "Tilføj slettet bruger",
    "deleted_user": "Slet bruger",
    "favourite": "Favorit",
    "refresh": "Opfriske",
    "delete_chat": "Slet chat",
    "responsive_drawer": "Responsiv skuffe",
    "delete_contact": "Slet kontakt",
    "clear_chat_history": "Ryd chathistorik",
    "clear_chat_history_dec": "Er du sikker på, at du vil slette denne chat?",
    "clear_contact_dec": "Er du sikker på, at du vil slette denne kontakt?",
    "select_contact": "Vælg Kontakt",
    "new_conversation": "Start en ny samtale",
    "type_msg": "Skriv din besked her",
    "select_delete_client": "Vælg den klient, der skal slettes",
    "select_delete_client_chat": "Vælg venligst klient for at slette chatten",
    "select_delete_client_chat_err": "Du har ikke chat med denne klient",
    "acquire_customers_business": "Få nye kunder fra andre virksomheder.",
    "customers_from_events": "Få kunder fra begivenheder og bring det til dig!",
    "customers_from_ads": "Få kunder fra avisannoncer.",
    "smart_pamphlets": "Opret smarte pjecer for at indsamle kundedata.",
    "smart_qr_group": "En smart QR til at deltage i en gruppe for kunder.",
    "opt_in_number": "Gør det muligt for kunder at tilmelde sig antal.",
    "qr_menu": "Opret QR-kode til at vise din menu.",
    "collect_birthdays": "Saml kundens fødselsdag og jubilæum.",
    "join_loyalty_program": "Få nye kunder til at deltage i mit loyalitetsprogram.",
    "create_feedback_forms": "Opret formularer til at indsamle kundens feedback.",
    "exclusive_discounts_promotions": "Tilbyd eksklusive rabatter og kampagner for krydskampagner.",
    "group_smart_qr_opt_in": "Når du har oprettet en gruppe, kan du generere og vælge en smart QR-kode, så kunderne kan tilmelde sig en gruppe med det samme",
    "group_smart_qr_opt_in_dec": "Efter oprettelse af en gruppe, billedwidget, der gør det muligt for kunder at tilmelde sig ved hjælp af tekst og modtage kampagner, f.eks. tekst \"pizza\" til 555-555-5555",
    "in_store_discount_next_visit": "Få en rabat i butikken ved dit næste besøg på vores lokation",
    "delivery_redirection": "Opret leveringsomdirigering",
    "additional_info": "Yderligere oplysninger",
    "add_url": "Tilføj URL",
    "custom_fields": "Brugerdefinerede felter",
    "meta_tag": "Meta tag",
    "max_chars": "Max tegn",
    "add": "Tilføje",
    "update_campaign": "Opdater kampagne",
    "last_week_required": "sidste uge er påkrævet",
    "large_week_value": "Ugeværdien er for stor indtast gyldig uge",
    "less_than_last_week": "Ikke retur i sidste uge skal være mindre end sidste uge.",
    "last_week_req": "Ikke returnering i sidste uge er påkrævet",
    "birthday_special": "Fødselsdagsspecial",
    "birthday_message": "Automatisk besked sendt ud på deres fødselsdage med personlige hilsner og fristende tilbud",
    "birthday_sms": "Opret personlige fødselsdagshilsener til SMS\/MMS",
    "target_audience": "Vælg målgruppe",
    "client_by_birthday": "Kunde inden fødselsdag",
    "clients_once_year": "Denne kampagne sendes automatisk til kunder en gang om året ",
    "auto_responder_default_msg_1": "Du er vores en af ​​de bedste kunder, som vi elsker at give rabat! Må du altid være velhavende og sund. Tillykke med fødselsdagen! %% fornavn %%",
    "campaign_send_to_client_birthday": "Denne automatiske kampagne sender til kunder med fødselsdage, du kan tilpasse målretningen nedenfor.",
    "get_more_engagement": "Få mere engagement ved at sende b'day SMS 🎂",
    "welcome_new_clients": "Velkommen til nye kunder",
    "make_lasting_impression": "Gør et varigt indtryk ved at hilse varmt velkommen og byde førstegangsspisende velkommen",
    "personalized_greetings": "Opret personlige hilsner til de nye kunder",
    "greetings_to_new_customers": "Denne kampagne udsender velkomsthilsener til nye kunder den følgende dag efter deres første interaktion med din virksomhed",
    "audience_predefined": "Denne autosvars målgruppe er foruddefineret, vi har dette!",
    "send_clients_subscribed": "Denne autoresponder er indstillet til at sende kunder, der har abonneret på det sidste ",
    "first_interaction": "  efter deres første interaktion.",
    "default_msg_2": "Vi håber, du nød dit første besøg, vi vil meget gerne se dig igen snart, så vi tilbyder en rabat kun til dig! Klik på linket for at booke din næste aftale med os og indløse dit særlige online tilbud nu.",
    "new_clients_update": "Byd nye kunder velkommen og opdater kampagneteksten",
    "new_clients_warm_greeting": "Byd nye kunder velkommen med en varm hilsen 💌",
    "win_back_clients": "Vind kunder tilbage",
    "re_engage_past_customers": "Genengager tidligere kunder med uimodståelige tilbud for at opmuntre dem til at vende tilbage",
    "target_disengaged_clients": "Målret uengagerede kunder for at vende tilbage med et særligt tilbud, en gennemprøvet taktik til at lokke kunder tilbage.",
    "campaign_sends_to_clients": "Denne kampagne sender til kunder, der ikke vendte tilbage efter en vis periode.",
    "customize_targeting": "Tilpas målretning nedenfor for at sende SMS\/MMS til udløbne klienter.",
    "clients_with_at_least": "Kunder med mindst ",
    "connection_last": " forbindelse i den sidste ",
    "return_in_last_week": " uge, men kom ikke tilbage sidst ",
    "arr_menu_44": " uge",
    "df_msg_4": "Vi ønsker dig SÅ VÆRLIGT tilbage, at vi vil give dig 10% rabat bare for at returnere! Vil du prøve os igen i dag?",
    "update_campaign_text": "Vind tilbage klienter opdatere kampagnetekst",
    "re_invite_customers": "Geninviter kunder, der ikke har været tilbage i et stykke tid",
    "loyal_patrons": "Vis påskønnelse over for loyale lånere med særlige rabatter og loyalitetsbelønninger",
    "surprize_top_spenders": "Overrask de bedste brugere med personlige og særlige tilbud.",
    "campaign_automatically_send": "Denne kampagne sender automatisk belønninger ud for at sætte gang i din virksomhed.",
    "sms_loyal_clients": "Denne kampagne sender SMS\/MMS til loyale kunder. Du kan ændre målretningen nedenfor.",
    "or_more_connection": " eller mere forbindelse, inden for den sidste ",
    "week_period": " uge periode.",
    "default_msg_5": "Du har været kunde i lang tid nu! Wow, tiden flyver! Vi sætter pris på dig og vil gerne takke dig for at være sammen med os.",
    "reward_regulars_update": "Belønningsfaste opdaterer kampagnetekst",
    "special_discounts": "Sæt pris på og giv særlige rabatter💎",
    "leave_positive_online": "Tilskynd kunder til at efterlade positive onlineanmeldelser for at booste restaurantens omdømme",
    "clients_service_online": "Kampagnen opfordrer dine kunder til at vurdere din service online.",
    "increase_your_company": "Denne autoresponder er sendt for at øge din virksomheds online rating.",
    "send_message_after": "Send besked efter ",
    "min_connection": " min af forbindelsen",
    "default_msg_6": "Vi håber, at du nød dit første besøg, vi vil meget gerne se dig snart igen! [URL] Klik på linket for at bedømme os på Google.",
    "ratings_update": "Få mere Bedømmelsesopdateringskampagnetekst",
    "friendly_nudge": "Få flere anmeldelser med et venligt skub ⭐",
    "thanks_for_visiting": "Tak for besøget",
    "express_gratitude": "Udtryk taknemmelighed til kunder for at have valgt din virksomhed med dybfølte takbeskeder",
    "thank_you_message": "Opret takbesked til dine kunder.",
    "clients_for_visiting": "Send en varm tak til dine kunder for at besøge dit sted.",
    "campaign_audience": "Denne automatiske kampagnemålgruppe er foruddefineret. Vi har dette!",
    "campaign_automatically_sends": "Denne automatiske kampagne sender automatisk efter ",
    "minutes_customer_visit": " minutter af kundebesøg",
    "default_msg_7": "Tak fordi du er vores værdsatte kunde. Vi er så taknemmelige for fornøjelsen af ​​at betjene dig og håber, at vi levede op til dine forventninger.",
    "visiting_update_campaign": "Tak, fordi du besøgte opdateringskampagneteksten",
    "guests_thank_you": "Tak til dine gæster for deres besøg 😊",
    "download_title": "Download",
    "qr_gen_title": "QR genereret",
    "qr_download_s_title": "QR-koden blev downloadet",
    "sel_dark_brand_clr_msg": "Vælg venligst gyldig barnd farve",
    "manage_customer_stamp_rewards": "Administrer kundestempelbelønninger 🎁",
    "sel_loc_menu_title": "Vælg placeringer til din menu",
    "ans_req": "Svar er påkrævet",
    "valid_reputation_name": "Indtast et gyldigt omdømmenavn",
    "reviews_name_req": "Navnet på Hent flere anmeldelser er påkrævet",
    "birthdate_required": "Fødselsdato er påkrævet",
    "gender_required": "Køn er påkrævet",
    "valid_birthdate_required": "Indtast gyldig fødselsdato",
    "custom_delivery_redirection": "Opret en tilpasset leveringsomdirigeringsformular efter grupper",
    "customer_fields_incentive_settings": "Kundefelter og incitamentsindstillinger",
    "delivery_redirection_text": "Omdirigering af levering",
    "delivery_redirection_success": "Tillykke! Din leveringsomdirigeringswidget er oprettet! 🎉",
    "swipe_to_preview_redirection": "Stryg for at se, hvordan din leveringsomdirigeringsformular vil se ud",
    "enter_item_name": "Indtast varens navn",
    "home_page_text": "Hjemmesidetekst",
    "settings": "Indstillinger",
    "anniversary_required": "Jubilæumsdato er påkrævet",
    "valid_anniversary": "Indtast gyldig jubilæumsdato",
    "form_submited": "formularen blev indsendt",
    "notifications": "Meddelelser",
    "discount_message": "Få 20 % rabat på pizza nu!",
    "is_required": " er påkrævet",
    "section_title_required": "Sektionstitel er påkrævet",
    "section_dec_required": "Sektionsbeskrivelse er påkrævet",
    "client_details_required": "Felter med klientoplysninger er påkrævet",
    "compliance": "Overholdelse",
    "SMS_campaign1": "SMS-kampagne 1",
    "mobile_number_mandatory": "Mobilnummer er obligatorisk",
    "new_answer": "Nyt svar",
    "new_question": "Nyt spørgsmål",
    "add_new_question": "Tilføj nyt svar",
    "select": "Vælge",
    "group_customers_question": "Hvor vil du gruppere dine kunder?",
    "contacts_added_to_group": "Alle kontakter, der indsender formularen, vil blive tilføjet til den valgte gruppe",
    "edit_client_details_section": "Rediger sektion med klientoplysninger",
    "client_details_fields": "Felter med klientoplysninger",
    "enter_client_details_section_title_desc": "Indtast sektionens titel og beskrivelse for klientoplysninger",
    "choose_fields_displayed_client_side": "Vælg de felter, der skal vises på klientsiden",
    "section_title": "Afsnitstitel",
    "add_title": "Tilføj titel",
    "section_description": "Afsnit Beskrivelse",
    "add_description": "Tilføj beskrivelse",
    "enter_program_name_location": "Indtast programnavn og lokation 📍",
    "brand_theme_clr_txt": "Mærketemafarve",
    "set_loyalty_punch_card_theme": "Lad os sætte et tema for dit loyalitetshullkort 🎨",
    "upload_logo_txt": "Upload dit logo",
    "recommended_image_specs": "Vi anbefaler at bruge et billede, der er 512 x 512 px for den bedste visning. JPG, SVG eller PNG. Max størrelse på 10MB.",
    "valid_rep_name_txt": "Indtast et gyldigt omdømmenavn",
    "rep_name_req_txt": "Navnet på Hent flere anmeldelser er påkrævet",
    "que_req": "Spørgsmål er påkrævet",
    "day_of_birthday": "på fødselsdagen",
    "day_before_birthday": "3 dage før fødselsdag",
    "week_before_birthday": "en uge før fødselsdag",
    "two_week_before_birthday": "to uger før fødselsdag",
    "de_active": "De-aktiv",
    "campaign_details": "Kampagnedetaljer",
    "link_clicked": "Linket klikket",
    "history": "Historie",
    "auto_responder_summary": "Oversigt over autosvar",
    "vsop_1": "15 min",
    "vsop_2": "30 min",
    "vsop_3": "45 min",
    "vsop_4": "60 min",
    "vsop_5": "90 min",
    "vsop_6": "120 min",
    "in_the_last": " i den sidste ",
    "return_in_last": " men kom ikke tilbage sidst ",
    "of_the_connection": "af forbindelsen",
    "your_campaign": "Din kampagne",
    "you_have_successfully": "Du har med succes",
    "published_successfully": "udgivet med succes",
    "updated_smart_campaign": "opdateret den smarte kampagne",
    "almost_done_text": "Næsten færdig!",
    "update_campaign_desc": "Din autoresponder er færdig, når den er aktiveret, vil den løbende sende beskeder til klienter. Denne kampagne kan nemt ændres eller sættes på pause når som helst.",
    "update_and_publish": "Opdater og udgiv",
    "reset_campaign_title": "Det vil foreløbigt slette din kampagnes tilpassede tekst, billede, rabattilbud og målgruppe!",
    "space_wifi": " WiFi",
    "custom_forms": "Brugerdefinerede formularer",
    "web_e_signup": "Web E-tilmelding",
    "import": "Importere",
    "permissions_req": "Tilladelser er påkrævet",
    "redeemed": "Forløst",
    "coupon_already_redeemed": "Kuponen er allerede indløst den",
    "autoresponder_campaigns": "Autoresponder-kampagner",
    "autoresponder_campaign_desc": "Aktiver autosvaret, og start den personlige kampagne",
    "mon": "MAN",
    "tue": "TIR",
    "wed": "ON",
    "thu": "THU",
    "fri": "FRI",
    "sat": "SAT",
    "sun": "SOL",
    "duplicate": "Duplikere",
    "visibility": "Sigtbarhed",
    "availability": "Tilgængelighed",
    "out_stok": "Ud af stok",
    "edit_product": "Rediger produkt",
    "create_product": "Opret et produkt",
    "basic_info": "Grundlæggende information",
    "basic_sub_info": "Lorem Ipsum er simpelthen dummy tekst af udskrivningen...",
    "enter_product_price": "Indtast produktpris",
    "upload_image": "Upload billede",
    "allowed_file_formats": "Kun JPG- og PNG-filer med maks. filstørrelse 3MB er tilladt.",
    "pre_select": "Forvalg",
    "promotions": "Kampagner",
    "discount_availability": "Tilgængelighed af rabat",
    "start_time": "Starttid",
    "end_time": "Sluttid",
    "select_day": "Vælg dag",
    "menu_required": "Menu er påkrævet",
    "generated": "Genereret",
    "link": "Forbindelse",
    "variations": "Variationer",
    "select_variant_to_add": "Vælg den variant, du vil tilføje",
    "price_title": "Pris",
    "choose_image": "Vælg Billede",
    "select_visiblity": "Vælg Synlighed",
    "availability_schedule": "Tilgængelighedsplan",
    "add_on_upsell": "Tilføjelse \/ Mersalg",
    "add_on_select_product": "Tilføj til Vælg produkt",
    "upsell_product": "Mersalg Vælg produkt",
    "variant_deletion_warning": "Denne variant vil blive slettet permanent.",
    "search_variations": "Søgevarianter",
    "add_variation": "Tilføj variation",
    "variation_text": "Variant",
    "select_customization": "Vælg tilpasning",
    "add_new": "Tilføj nyt",
    "delete_customization_warning": "Vil du slette denne tilpasning fra produktet?",
    "nutritional_allergen_info": "Ernærings- og allergeninformation",
    "mark_item": "Marker vare",
    "calories_text": "Kalorier",
    "allergens": "Allergener",
    "select_allergens": "Vælg allergener",
    "special_instructions": "Særlige instruktioner",
    "preparation_instructions": "Forberedelsesvejledning",
    "staff_notes": "Personale Noter",
    "checkbox1": "Et afkrydsningsfelt for at tillade eller afvise særlige instruktioner fra kunder for dette menupunkt.",
    "menu_c": "MENU",
    "past_design": "Tidligere design",
    "file_name": "Filnavn",
    "variation_name": "Variationsnavn",
    "grid_items_title1": "Bordtelt",
    "grid_items_title2": "Digital menu",
    "grid_items_title3": "Takeaway menu",
    "grid_items_title4": "Halvside menu",
    "grid_items_title5": "Digital menu 5",
    "grid_items_title6": "Digital menu 6",
    "grid_items_title7": "Digital menu 7",
    "grid_items_title8": "Digital menu 8",
    "grid_items_title9": "Digital menu 9",
    "enter_valid_form_name": "Indtast gyldigt formularnavn",
    "form_name_required": "Formularnavn er påkrævet",
    "enter_valid_section": "Indtast gyldig afsnitstitel",
    "fraction_half": "1\/2",
    "fraction_two_by_two": "2\/2",
    "form_name": "Formularnavn",
    "contact_list": "Kontaktliste",
    "label_notify_email": "Vil du have besked via e-mail?",
    "add_client_details_section": "Tilføj klientoplysninger",
    "demo_purpose_only": "Det er kun til demo formål",
    "form_updated_success": "formularen blev opdateret",
    "form_creat_success": "formular oprettet med succes",
    "add_custom_section": "Tilføj en brugerdefineret sektion",
    "edit_custom_section": "Rediger en brugerdefineret sektion",
    "forms_list": "Liste over formularer",
    "filled_custom_form_details": "Udfyldte brugerdefinerede formulardetaljer",
    "custom_form_deletion_warning": "Denne brugerdefinerede formular vil blive slettet permanent",


    "menu_link": "Menu link",
    "WebSignUpT": "E-tilmelding",
    "lifeTConT": "Livsvarige forbindelser",
    "repCustT": "Gentagende kunder",
    "liftTSubT": "Livsvarige abonnenter",
    "overallT": "SAMLET",
    "loginCustBDT": "Loggede kunder efter dag",
    "deviceT": "Enhedsdemografi",
    "ageTitle": "Aldersdemografi",
    "ageT": "Aldersgruppe",
    "convert_social_media_followers_into_customers": "Konverter følgere på sociale medier til kunder",
    "signWidDesc": "Byg en widget til tilmelding, oprettelse af landingssider og generering af kuponer fra dit websted eller sociale medier",
    "qrWidT": "Smart QR",
    "imgWidT": "Tilmeld antal",
    "mobile": "Mobil",
    "YESTitle": "JA",
    "NOTitle": "INGEN",
    "addDelRed": "Tilføj leveringsomdirigering",
    "dlredNameT": "Navngiv din leveringsomdirigering",
    "conf_text_req": "Bekræftelsestekst er påkrævet",
    "cFDesc": "Opret skræddersyede formularer for effektivt at indsamle specifik feedback og information fra kunder",
    "pre_review_questions_enabled": "Før vi beder kunderne om anmeldelse, lad os stille dem 1 til 4 spørgsmål.",
    "feel_free_edit": "Du er velkommen til at redigere efter behov.",
    "option": "Valgmulighed",
    "confFL": "Denne bekræftelse vil blive vist, når din kontaktperson har indsendt webtilmeldingsformularen",
    "confT": "Bekræftelsestekst",
    "joinTitle": "Deltag",
    "mobValidL": "Tjek venligst gyldigt mobilnr",
    "mobRequiredL": "Mobilnummer er påkrævet",

    "Business_location_label": "Din virksomhedsplacering",
    "qr_code_selection_txt": "Qr-kodevalg",
    "choose_theme_txt": "Vælg tema",
    "edit_qr_details_txt": "Rediger QR-detaljer",
    "save_qr_code_txt": "Gem QR-kode",
    "text_color_label": "Tekst farve",
    "background_color_label": "Baggrundsfarve",
    "print_txt": "Trykke",
    "customer_will_see_txt": "Dette er, hvad din kunde vil se. Vælg din brandfarve i næste trin.",
    "choose_your_brand_color_&_langauge_title": "Vælg dit mærke farve og sprog",
    "custom_redirect_link_label": "Tilpasset omdirigeringslink",
    "redirect_user_to_menu": "Omdiriger bruger til menu",
    "language": "Sprog",
    "select_language_placeholder": "Vælg sprog",
    "capitalize_location_name_txt": "Stedsnavn",
    "created_txt": "Oprettet",
    "swipe_reputation_management_txt": "Stryg for at se, hvordan din omdømmestyring vil se ud",
    "sent_txt": "Sendt",
    "number_label": "Antal",
    "date_label": "Dato",
    "gender_demographics_title": "Kønsdemografi",

    "step1_preview_desc": "Navngiv din QR-formular til e-tilmelding, og tilføj den til din gruppeliste",
    "main_head": "Få nye kunder",
    "main_desc": "Få kunder ved at placere en QR-kode, der, når den scannes, omdirigerer brugerne til en personlig tilmeldingsformular.",
    "step1_description": "Få nye kunder fra andre virksomheder",
    "form_name_field_placeholder": "Restro E-tilmelding",
    "last_step_title": "Tillykke! Din e-tilmeldingswidget er oprettet! 🎉",
    "last_step_desc": "Stryg for at se, hvordan din e-tilmeldingsformular vil se ud",
    "events_step1_preview_desc": "Navngiv din Event QR-formular og tilføj den til din gruppeliste",
    "events_main_head": "Få kunder fra begivenheder",
    "events_main_desc": "Få kunder ved at placere en QR-kode på begivenheden, når den scannes, omdirigerer brugerne til en personlig tilmeldingsformular.",
    "events_step1_desc": "Fang kunder fra begivenheder og bring dem i butikken",
    "events_name": "Begivenhedsnavn",
    "events_form_name_field_placeholder": "Restro Sjov og Mad Event",
    "events_last_step_title": "Tillykke! Din begivenhedsformular er oprettet! 🎉",
    "events_last_step_desc": "Stryg for at se, hvordan din begivenhedsformular vil se ud",
    "birthday_anniversary_step1_preview_desc": "Navngiv din QR-formular for at indsamle kundens fødselsdag eller jubilæum, og den tilføjes til din gruppeliste",
    "birthday_anniversary_main_head": "Saml B'day eller Jubilæum",
    "birthday_anniversary_main_desc": "Få kundens fødselsdag eller jubilæum, når den scannes, omdirigerer brugerne til en personlig tilmeldingsformular.",
    "birthday_anniversary_step1_desc": "Saml kundens fødselsdag og jubilæum",
    "restro_text": "Restro",
    "birthday_anniversary_last_step_title": "Tillykke! Få B'day & Anniversary fra kundeformularen oprettet! 🎉",
    "newspaper_ads_step1_preview_desc": "Navngiv din QR-formular for at få kunder fra avisannoncer, og den føjes til din gruppeliste",
    "newspaper_ads_main_head": "Få kunder fra annoncer",
    "newspaper_ads_main_desc": "Få kunder fra avisannoncer, når de scannede, omdirigerer det brugerne til en personlig tilmeldingsformular.",
    "newspaper_ads_step1_desc": "Få kunder fra avisannonce eller udendørsannonce",
    "newspaper_ads_last_step_title": "Tillykke! Få kunder fra avisannoncer oprettet! 🎉",
    "smart_pamphlets_step1_preview_desc": "Navngiv din QR-formular for at få kundedata fra pjecer, og den tilføjes til din gruppeliste",
    "smart_pamphlets_main_head": "Få kunder fra pjecer",
    "smart_pamphlets_main_desc": "Få kundens data fra pjecer, når de scannede, omdirigerer det brugerne til en personlig tilmeldingsformular.",
    "smart_pamphlets_last_step_title": "Tillykke! Få kundens data fra pamfletter oprettet! 🎉",
    "social_media_step1_preview_desc": "Navngiv din QR-formular for at få kunder fra sociale medier og til kunder, og den tilføjes til din gruppeliste",
    "social_media_main_desc": "Få kunder fra sociale medier, når de scannede fra indlæg, omdirigerer det brugerne til en personlig tilmeldingsformular.",
    "social_media_form_name_field_placeholder": "Restro Festival Tilbud - Facebook Opslag",
    "social_media_last_step_title": "Tillykke! Få kundernes sociale medieform oprettet! 🎉",
    "claim_offer_text": "Udfyld dine oplysninger og gør krav på tilbuddet nu",
    "form_title_description": "Tilføj formulartitel og beskrivelse, så dine kunder kan indtaste detaljerne",
    "no_subscribed_clients": "Den udvalgte kundegruppe har ingen abonnenter",
    "welcome_message_offer": "Send en velkomstbesked til dine kunder, efter at de har tilmeldt sig, og giv dem en tilbudskupon",
    "system_grp": "Systemgrupper",
    "import_clients": "Importer kunder",
    "delivery_redirection_name": "Navn på omdirigering af levering",
    "created_at": "Oprettet kl",
    "provide_stamp_user": "Giv stempel til bruger",
    "scan_qr_code": "Scan QR-kode",
    "add_point": "Tilføj punkt",
    "total_points": "Samlet antal point",
    "user_profile": "Brugerprofil",
    "stamp_history": "Frimærkehistorie",
    "reward_txt": "Belønne",
    "variant_placeholder": "Lille, mellem, stor osv",
    "sent_coupon": "Sendt kupon",
    "order_status": "Ordrestatus",
    "payment_status": "Betalingsstatus",
    "order_currency": "Ordre valuta",
    "card_data": "Kortdata",
    "reward_expiring_on": "Belønning udløber den",
    "reward_status": "Belønningsstatus",
    "reward_get_date": "Belønning Få dato",
    "reward_name": "Navn på belønning",
    "add_point_type": "Tilføj punkttype",
    "restaurant_location": "Restaurantens beliggenhed",
    "collect_points": "Saml point",
    "rewards_expire": "Belønninger udløber",
    "rewards_redeem": "Belønninger Indløs",
    "total_rewards": "Samlede belønninger",
    "created_by": "Skabt af",
    "anniversary": "Jubilæum",
    "remove_user_group_txt": "Fjern bruger fra gruppe",
    "delete_permanently_grp_msg": "Denne klient vil blive slettet permanent fra gruppen.",
    "delete_multiple_permanently_grp_msg": "Denne klient vil blive slettet permanent fra gruppen.",
    "delete_multiple_client": "Denne klient vil blive slettet permanent.",
    "messages": "Beskeder",
    "management": "Ledelse",
    "client": "Klient",
    "country": "Land",
    "state": "Tilstand",
    "city": "By",
    "zip_code": "Postnummer",
    "1_sparkly_point_txt": "🎉 Du har optjent 1 glimrende point! 🌟",
    "select_country_txt": "Vælg land",
    "select_state": "Vælg tilstand",
    "ls_thanks_for_join": "Tak, fordi du tilmelder dig vores loyalitetshullekort.🎁🎈",
    "month": "Måned",
    "welcome_message": "Send en velkomstbesked til dine kunder, efter de har tilmeldt sig",
    "all_rewards_txt": "Alle belønninger",
    "active_rewards": "Aktive belønninger",
    "expired_and_redeemed": "Udløbet og indløst",
    "redirect_3rd_party_delivery": "Omdiriger 3. parts levering til din butik eller til din online bestillingsplatform",
    "search_or_start_new": "Søg eller start en ny",
    "contacts": "Kontaktpersoner",
    "chats": "Chats",
    "add_by_restaurant": "Tilføj efter restaurant",
    "using_scan_code": "Brug af scanningskode",
    "step": "Trin",
    "list_widget_text": "Liste over widgets",
    "swipe_free_wifi_txt": "Swipe for gratis wifi",
    "create_widget_txt": "Opret widget",
    "redeem_award_msg": "Er du sikker? Ønsker du at indløse denne belønning",
    "on_collect_msg": "På Collect",
    "pause": "Pause",
    "resume": "Genoptage",
    "location_added": "placering tilføjet",
    "sub_user_admin_permissions_txt": "Tillad underbruger at få adgang til administratortilladelser i Digital Signage",
    "unlist": "Fjern listen",
    "cannot_delete_default_terms": "Kan ikke slette standardvilkår og -betingelser",
    "something_went_wrong": "Noget gik galt!",
    "card_download_success": "Kort blev downloadet",
    "permission_denied": "Tilladelse nægtet!",
    "invalid_qr_code": "Scan venligst gyldig QR-kode",
    "select_customer": "Vælg venligst kunde",
    "online": "ONLINE",
    "offline": "OFFLINE",
    "no_data": "Ups, ingen data fundet!",
    "forever_free": "For evigt gratis",
};

export default da