const sv = {
    "rated_google_reviews": "Betygsatt 5,0 på Google Recensioner",
    "get_your_digital_menu": "Få din digitala meny—",
    "instant_popup_menu": "Snabbmeny utan QR-skanning",
    "free_forever": "Gratis för alltid",
    "the_future_is_here": "Framtiden är här!",
    "upgrade_your_restaurant": "Uppgradera din restaurang med en fantastisk digital meny utan kostnad. Locka fler kunder från och med idag.",
    "get_my_free_menu_now": "Få min gratismeny nu",
    "no_credit_card_required": "*Inget kreditkort krävs",
    "join_largest_marketplace": "Gå med på den största restaurangmarknaden!",
    "what_will_you_get": "Vad kommer du att får",
    "free": "GRATIS",
    "free_digital_menu": "Gratis digital meny 2D eller 3D",
    "free_digital_menu_desc": "Säg adjö till pappersmenyer och dyra nytryck",
    "quick_and_easy_signup": "Snabb och enkel registrering",
    "communicate_with_customers_title": "Kommunicera med kunder GRATIS!",
    "features1": [
        "Spara på utskriftskostnaderna och njut av omedelbara menyuppdateringar när du behöver dem.",
        "Få åtkomst till din digitala meny på vilken enhet som helst",
        "Miljövänlig och problemfri"
    ],
    "features2": [
        "Låt kunderna virtuellt utforska dina rätter med fantastiska 3D-bilder.",
        "Förbättra engagemanget genom att ge middagsgästerna en realistisk förhandstitt på sin måltid.",
        "Stick ut med en menyupplevelse som känns lika lockande som din mat smakar."
    ],
    "features3": [
        "Hälsa omedelbart gästerna med din meny när de går in i restaurangen.",
        "Skicka automatiskt uppdateringar om specialerbjudanden och kampanjer till deras telefoner.",
        "Förbättra matupplevelsen med snabb, kontaktlös tillgång till din meny."
    ],
    "feature4": [
        "Markera säsongserbjudanden, dagliga specialerbjudanden och exklusiva kampanjer.",
        "Uppmärksamma kunderna på dina mest populära föremål.",
        "Skapa brådska och spänning med iögonfallande bilder och beskrivningar."
    ],
    "feature5": [
        "Bli en del av en onlinegemenskap med tusentals dagliga besökare.",
        "Öka synligheten och nå nya kunder som aktivt söker efter matställen.",
        "Säkra en topplacering tidigt för att maximera din exponering och locka fler matgäster."
    ],
    "section3_heading": "Varför gå digitalt? För det är gratis och enkelt!",
    "section3_description": "Lås upp kraftfulla funktioner utformade för att öka din restaurangs attraktionskraft.",
    "instant_menu": "Instant Meny",
    "instant_menu_notification": "Pop-up och meddelande",
    "instant_menu_description": "Välkomna gäster med omedelbar menyåtkomst så fort de kommer in. Skicka automatiskt den senaste menyn och specialerbjudanden direkt till sina mobiltelefoner",
    "digital_menu": "Digitala menyer",
    "at_zero_cost": "till nollkostnad",
    "digital_menu_description1": "Säg adjö till pappersmenyer och dyra nytryck. Byt till en digital meny som alltid är uppdaterad – och den är gratis för alltid!",
    "digital_menu_description2": "Med vår digitala meny är dina erbjudanden alltid aktuella och tillgängliga på vilken enhet som helst. Spara pengar och förenkla menyuppdateringar med en sömlös lösning utan kostnad.",
    "try_me_button": "Prova mig",
    "try_3d_menu_title": "3D-menyer",
    "try_3d_menu_title2": "Gratis - WOW!",
    "try_3d_menu_description1": "Få dina rätter att hoppa av skärmen. Ladda upp 3D-bilder och låt kunderna virtuellt smaka på din mat innan de går in.",
    "try_3d_menu_description2": "Högkvalitativa 3D-bilder skapar en aptitretande förhandsvisning, förstärker överklagandet och uppmuntrar beställningar.",
    "try_now": "Prova nu",
    "showcase_title": "Visa upp din",
    "showcase_specials": "Specialerbjudanden",
    "showcase_specials_description1": "Har du ett aptitretande erbjudande eller en oslagbar kampanj? Markera det på din digitala meny och dra in fler hungriga människor.",
    "showcase_specials_description2": "Gör kampanjer omöjliga att missa, väck uppmärksamhet med djärva bilder och lockande beskrivningar.",
    "marketplace": "Marknad",
    "marketplace_description": "Vi piska upp en marknadsplats med över 2 000 dagliga besökare. Hoppa ombord nu, så är du först i kön när vi lanserar. Du kan nå nya kunder som aktivt letar efter matställen. Säkra din plats tidigt för att maximera exponeringen!",
    "see_other_products": "Se Andra produkter",
    "about_us": "Om oss",
    "why_serving_free": "Varför vi serverar detta",
    "serving_free_highlight": "Gratis",
    "about_desc": "Vi är lokalbefolkning här i Dallas, engagerade i att hjälpa restauranger som din att blomstra.",
    "about_tool_free": "Vi erbjuder detta kraftfulla verktyg helt gratis eftersom vi tror på att stödja vårt samhälle.",
    "no_catch": "Ingen fångst, ingen skojar. Vi vet att när du lyckas blir vår gemenskap starkare. 😊",
    "understanding_challenges": "Vi förstår utmaningarna med att driva ett företag, och det är därför vi är dedikerade till att tillhandahålla verktyg som gör din verksamhet smidigare och din räckvidd bredare.",
    "community_growth": "När du växer växer vår gemenskap med dig, vilket skapar en ringeffekt av framgång och stöd. Låt oss tillsammans höja matupplevelsen för alla.",
    "how_it_works": "Hur det fungerar",
    "three_simple_steps": "Tre enkla steg till",
    "get_started_highlight": "Kom igång",
    "no_tech_skills_needed": "Inga tekniska kunskaper behövs. Om du kan vända en hamburgare kan du göra detta!",
    "step1_title": "Visa din kampanj GRATIS!",
    "step1_desc": "Kom igång snabbare än du kan säga \"Beställ!\" Fyll bara i ett enkelt formulär.",
    "step2_title": "Anpassa din meny",
    "step2_desc": "Lägg till dina rätter, ladda upp foton även i 3D och lyft fram dina specialerbjudanden.",
    "step3_title": "Dela och växa",
    "step3_desc": "Dela din digitala meny via QRs, sociala medier eller din webbplats.",
    "testimonials": "Rekommendationer",
    "testimonials_section_title": "Ta inte bara vårt ord för det",
    "testimonials_section_desc": "Se vad andra Dallas restaurangägare säger.",
    "faq_section_title": "Har du frågor? Vi har svar",
    "faq_section_desc": "Allt du behöver veta innan du börjar.",
    "faqs": "Vanliga frågor",
    "faq_answers": [
        {
            "que": "Är det verkligen gratis för alltid?",
            "ans": "Absolut! Inga dolda avgifter, inga kreditkort, inga villkor."
        },
        {
            "que": "Behöver jag något tekniskt kunnande?",
            "ans": "Om du kan skicka ett e-postmeddelande kan du göra detta. Så enkelt är det."
        },
        {
            "que": "Varför erbjuder du detta gratis?",
            "ans": "Vi tror på att ge först. I framtiden kanske vi har andra tjänster som du kommer att älska – men det är helt upp till dig."
        },
        {
            "que": "Vad är grejen med marknaden?",
            "ans": "Vi lagar det! När vi har tillräckligt med restauranger ombord kommer vi att lansera din marknadsplats för att ge dig ännu mer exponering."
        }
    ],
    "testimonials_content1": "Att byta till en digital meny var det bästa draget vi gjorde! Våra kunder älskar bekvämligheten och det uppdaterade utseendet!",
    "testimonials_name1": "Morris George",
    "testimonials_restaurant_name1": "The Mansion Restaurant",
    "testimonials_content2": "Vår nya digitala meny gör surfandet enklare för gästerna, och det har verkligen ökat engagemanget!",
    "testimonials_name2": "Amelia Johnson",
    "testimonials_restaurant_name2": "Urban Eats",
    "testimonials_content3": "Gästerna imponeras av 3D-bilden – det har hjälpt oss att sticka ut och förbättrat deras matupplevelse!",
    "testimonials_name3": "Davis Green",
    "testimonials_restaurant_name3": "Stadshärd",
    "ready_to_boost_your_restaurant": "Redo att boosta din restaurang?",
    "ready_to_boost_desc": "Bli inte kvar. Gå med i den digitala revolutionen idag!",
    "yes_i_want_my_free_digital_menu": "Ja, jag vill ha min gratis digitala meny",
    "480_plus": "480+",
    "people_already_joined": "personer som redan anslutit sig",
    "menu_online_home": "Meny Online Hem",
    "welcome": "Välkomna",
    "description_other": "Låt oss öka din kundnöjdhet!",
    "benefits": "Fördelar",
    "greeting_text": "Välkommen 👋",
    "all_in_one_features": "🚀 Allt-i-ett-funktioner för att lyfta din restaurang",
    "menu_online_menu_name": "Meny online",
    "menu_online_description": "Digitalisera din meny för enkel åtkomst online för kunder.",
    "menu_online_benefits": "Förbättra kundernas bekvämlighet och öka engagemanget genom att låta dem se din meny när som helst och var som helst.",
    "ds_name": "Digital skyltning",
    "ds_description": "Visa upp erbjudanden och kampanjer på dynamiska skärmar i butik.",
    "ds_benefits": "Fånga kundernas uppmärksamhet med engagerande bilder, främja merförsäljningar och realtidserbjudanden utan ansträngning.",
    "loyalty_punch_name": "Lojalitetshålkort",
    "loyalty_punch_description": "Belöna återkommande kunder med ett digitalt lojalitetsprogram.",
    "loyalty_punch_benefits": "Öka kundbehållningen och öka försäljningen genom att stimulera lojalitet med inlösbara belöningar för frekventa besök.",
    "marketing_menu_name": "Marknadsföring",
    "marketing_description": "Nå fler kunder med riktade digitala marknadsföringskampanjer.",
    "marketing_benefits": "Utöka din restaurangs räckvidd och få fler besökare med anpassade kampanjer som är skräddarsydda för att locka nya och återkommande kunder.",
    "get_more_reviews_menu_name": "Få fler recensioner",
    "get_more_reviews_description": "Uppmuntra nöjda kunder att lämna positiva onlinerecensioner.",
    "get_more_reviews_benefits": "Öka din restaurangs trovärdighet och locka nya matgäster genom att förbättra ditt rykte online med fler positiva recensioner.",
    "guest_smart_wifi_menu_name": "Gäst smart wifi",
    "guest_smart_wifi_description": "Erbjud gratis Wi-Fi för att fånga gästdata för marknadsföring.",
    "guest_smart_wifi_benefits": "Öka kundnöjdheten samtidigt som du samlar in värdefull kunddata för att anpassa framtida kampanjer.",
    "reservation_menu_name": "Bokning",
    "reservation_description": "Tillåt kunder att enkelt boka bord online.",
    "reservation_benefits": "Förbättra matupplevelsen genom att minimera väntetiderna och effektivisera bokningar för att bättre hantera sittplatser.",
    "pos_integration_menu_name": "POS-integration",
    "pos_integration_description": "Integrera vårt system med ditt POS-system.",
    "pos_integration_benefits": "Förenkla orderhanteringen och minska antalet fel genom att hålla dina system online och i butik synkroniserade.",
    "online_ordering_menu_name": "Online beställning",
    "online_ordering_description": "Gör det möjligt för kunder att göra beställningar direkt från din webbplats eller app.",
    "online_ordering_benefits": "Öka försäljningen och minska beroendet av tredjepartsplattformar genom att erbjuda ett direktbeställningsalternativ som sparar avgifter.",
    "pro_label": "Pro",
    "coming_soon": "Kommer snart",
    "locked_label": "Låst",
    "forever_label": "Evigt",
    "new_label": "Ny",
    "other_products_page_title": "Andra produkter | Meny online",
    "other_products_description": "Meny Online Sekretesspolicy",
    "other_products_header_title": "Andra produkter",
    "privacy_policy": {
        "title": "Sekretesspolicy",
        "lastUpdated": "Senast uppdaterad: 1 juli 2024",
        "introduction": {
            "header": "Sekretesspolicy för nya och befintliga kunder",
            "paragraph1": "FÖLJANDE NYCKELPUNKTER (INKLUSIVE AVSNITTSREFERENSER) TILLHANDAHÅLLS ENDAST FÖR DIN bekvämlighet och ERSÄTTER INTE DE FULLSTÄNDA VILLKOR OCH VILLKOR. DET ÄR DITT ANSVAR ATT LÄSA DESSA VILLKOR INNAN DU ANVÄNDER MENUONLINE INC TJÄNSTEN.",
            "paragraph2": "Att använda Menuonline innebär att du godkänner villkoren och representerar och garanterar att du är auktoriserad av din organisation eller ditt företag att göra det. (Se s.3 \"Hur det fungerar\")",
            "paragraph3": "Menuonline Inc är en tillståndsbaserad marknadsföringstjänst. Avsiktlig sändning av \"spam\" eller brott mot villkoren kommer att resultera i att ditt konto stängs. (Se s.16 \"Acceptabel användning av tjänsten\")",
            "paragraph4": "Data som du är värd för i Menuonline Inc, såsom kontaktuppgifter, e-postinnehåll och information du samlar in via Menuonline Inc, tillhör dig. (Se s.14 \"Vad äger du?\").",
            "paragraph5": "Programvaran Menuonline Inc och innehållet på vår webbplats tillhör oss. (Se avsnitt 13 \"Vad äger vi?\") Förutom dina egna användare, kan data eller opublicerat innehåll som du är värd hos Menuonline Inc endast ses av Menuonline Inc-anställda och partners som har undertecknat sekretessavtal. (Se s.14 \"Vad äger du?\")",
            "paragraph6": "All personligt identifierbar information (PII) lagras endast på servrar i Kanada. (Se s.14 \"Vad äger du?\").",
            "paragraph7": "Tjänsten Menuonline Inc är föremål för lagarna i Kanada och provinsen Ontario. (Se s.24 \"Gällande lag\")",
            "paragraph8": "Alla förfrågningar om din data eller ditt innehåll från individer, poliser eller andra tillsynsmyndigheter från Kanada eller utanför Kanada kommer alltid att hänvisas till dig. Det enda undantaget skulle vara om detta innebär att vi bryter mot kanadensisk eller Ontarios lag; dock kommer vi i alla fall att ihärdigt motstå alla utsläpp av dina uppgifter från någon annan än dig. (Se s.14 \"Vad äger du?\").",
            "paragraph9": "Menuonline Inc säkerhet är bara så bra som din hantering av användarnamn och lösenord. Vi tar inte betalt för Menuonline Inc användarkonton så skapa ett separat för varje användare och håll lösenord hemliga. Du är ansvarig för ditt konto. (Se s.3 \"Hur det fungerar\")",
            "paragraph10": "Du kan avsluta ditt konto när som helst. Vi ger inga återbetalningar på oanvänd kredit om du avbryter. (Se s.3 \"Hur det fungerar\")"
        },
        "termsAndConditions": {
            "title": "MENUONLINE INC ALLMÄNNA VILLKOR",
            "lastUpdate": "SENAST UPPDATERING: 14 januari 2020",
            "paragraph1": "Vänligen läs dessa användarvillkor (\"Villkoren\") noggrant innan du använder denna webbplats (\"Webbplatsen\"). Dessa villkor befriar Menuonline Inc. (\"Menuonline Inc\" \"vi\", eller \"oss\") och andra från ansvar och\/eller begränsar vårt och deras ansvar och innehåller andra viktiga bestämmelser som gäller för din användning av denna webbplats. Genom att besöka eller använda denna webbplats eller tjänsten samtycker du till att för din egen räkning, och på uppdrag av alla organisationer för vars räkning du agerar (tillsammans kallad \"du\"), acceptera och följa dessa villkor för varje användning av och varje besök på denna webbplats."
        },
        "services": {
            "title": "Våra tjänster.",
            "paragraph": "Tjänsterna som vi erbjuder via webbplatsen är en tjänst som låter dig skapa, skicka och hantera e-postmeddelanden (\"E-postmeddelanden\") till mottagare som du väljer (\"Tjänsten\")."
        },
        "license": {
            "title": "Licens.",
            "paragraph": "Med förbehåll för dessa villkor, ger Menuonline Inc dig härmed en icke-exklusiv, begränsad, icke-överlåtbar licens under giltighetstiden för att se webbplatsen och använda Tjänsten i enlighet med funktionerna i Tjänsten. Vi förbehåller oss alla rättigheter som inte uttryckligen beviljas här i tjänsten och innehållet i Menuonline Inc (enligt definitionen nedan)."
        },
        "emailingContent": {
            "title": "E-posta innehåll.",
            "paragraph": "När du interagerar med Menuonline Inc via denna webbplats eller tjänsten samtycker du till att tillhandahålla sann och korrekt information. Vidare förstår du att tillhandahållande av konfidentiell information via e-post är en osäker praxis. Du förstår att du är ensam ansvarig för allt som du skickar till någon som använder vår tjänst."
        },
        "support": {
            "title": "Stöd.",
            "paragraph": "Vi ger dig stöd för att hjälpa dig att bli självförsörjande med Tjänsten. Vi ger dig support i form av dokumentation och videor på webbplatsen, Menuonline Incs supportbiljettsystem och per telefon. Du kan nå oss genom att klicka på fliken \"Support\" högst upp på sidan. Det finns ingen kostnad för produktsupport; vi förbehåller oss dock rätten att avbryta support för specifika kundkonton efter eget gottfinnande."
        }
    },
    "terms_condition": {
        "title": "Användarvillkor | Meny online",
        "header": "Villkor för nya och befintliga kunder"
    },
    "privacy_policy_title": "PRIVAT POLICY",
    "privacy_policy_effective_date": "Gäller från och med 16 januari 2020",
    "privacy_policy_general_title": "ALLMÄN PRIVAT POLICY",
    "privacy_policy_intro": "menuonline.com (Menuonline och \"oss\") förstår vikten av att skydda privatlivet för individers personliga information och vikten av säkerheten för konfidentiell information av alla slag. Vi har skapat denna privata policy (”policyn”) för att visa vårt engagemang för att skydda din \"integritet\". Denna privata policy förklarar:",
    "privacy_policy_points": [
        "Vilken personlig information vi samlar in och varför vi samlar in den;",
        "Hur vi använder dina personuppgifter;",
        "Vem vi delar din personliga information med; och",
        "De val du har för att komma åt, uppdatera och ta bort din personliga information från tjänsten."
    ],
    "privacy_policy_pipeda": "Menuonline hanterar insamlingen av all information i enlighet med Kanadas lag om personlig informationsskydd och elektroniska dokument (PIPEDA).",
    "privacy_policy_update_notice": "Observera att Menuonline förbehåller sig rätten att uppdatera eller ändra denna privata policy när som helst. Vi kommer att meddela dig om eventuella ändringar genom att publicera en uppdaterad policy på denna webbplats eller som görs tillgänglig via tjänsterna. Den senaste versionen av denna policy kommer att styra mycircles användning av användarnas personliga information. Genom att fortsätta att komma åt mycircles webbplats eller genom att fortsätta använda dess tjänster efter att ändringar har gjorts i denna privata policy, samtycker du till att vara bunden av de reviderade villkoren.",
    "privacy_policy_consent_title": "SAMTYCKE FÖR PERSONUPPGIFTER",
    "privacy_policy_consent_text": "Genom att förse Menuonline med din personliga information via vår webbplats eller något av våra formulär, samtycker du till insamling, användning och avslöjande av sådan information i enlighet med denna policy eller för de syften som identifierades för dig när du lämnade den personliga informationen. All sekundär användning av personuppgifterna kommer endast att behandlas med ditt uttryckliga samtycke, eller så kommer du att ges en möjlighet att välja bort det.",
    "privacy_policy_opt_out_text": "Du har möjlighet att när som helst återkalla ditt samtycke eller att välja bort det. Om du vill återkalla ditt samtycke eller välja bort det kan du klicka på länken för kommunikationsinställningar längst ner i alla e-postmeddelanden du får från oss.",
    "privacy_policy_info_collection_title": "INFORMATION VI SAMLAR IN",
    "privacy_policy_info_collection_text": "Menuonline samlar in och behandlar personuppgifter i enlighet med denna policy. Vi kommer inte att sälja, dela eller hyra ut denna information till andra, förutom vad som redovisas i denna policy. Menuonline samlar in personlig information i syfte att tillhandahålla våra tjänster eller produkter till dig.",
    "privacy_policy_info_types_text": "De typer av information som Menuonline samlar in inkluderar:",
    "privacy_policy_account_holders_title": "Menuonline kontoinnehavare",
    "privacy_policy_registration_info": "Registreringsinformation: Om du registrerar dig för att använda tjänsterna behöver du ett Menuonline-konto för att bli kontoinnehavare (\"Kontoinnehavare\"). När du registrerar dig för ett konto kommer du att bli ombedd att ange viss grundläggande information, såsom ditt namn, e-postadress, användarnamn, lösenord, företagsnamn, yrke, plats och telefonnummer. Lösenord är krypterade – Menuonline-personalen kan inte se eller hämta lösenord.",
    "privacy_policy_billing_info": "Faktureringsinformation: Om du prenumererar på våra tjänster måste du förse oss med betalnings- och faktureringsinformation.",
    "privacy_policy_cookies_info": "Cookies: Vi använder cookies för att spåra besökardata, såsom källan till webbplatsbesök och antalet webbplatsbesök på vår webbplats.",
    "privacy_policy_ip_info": "IP-adresser: IP-adresser samlas in om dig som en del av din användning av tjänsterna (t.ex. i syfte att registrera plats på landsnivå och IP-adresser som används när du använder våra tjänster).",
    "privacy_policy_your_contacts_title": "Dina kontakter",
    "privacy_policy_distribution_list": "Distributionslista: Som en del av din användning av våra tjänster kan du förse oss med, eller så kan vi samla in som en del av tjänsterna, personlig information om dina kontakter (\"Kontakter\"). Denna information inkluderar e-postadresser och kan innehålla annan identifierbar information såsom namn, företagsnamn eller annan information som du tillhandahåller Menuonline när du använder tjänsterna. Du måste se till att du får lämpligt samtycke från dina kontakter för att tillåta att deras information behandlas av oss.",
    "privacy_policy_billing_info_contacts": "Faktureringsinformation: Om du prenumererar på våra tjänster måste du förse oss med betalnings- och faktureringsinformation.",
    "privacy_policy_cookies_contacts": "Cookies: Menuonline kommer endast att använda cookies och liknande spårningstekniker för att samla in och använda personlig information om dina kontakter om du har lagt till Menuonline-spårningskoden på din webbplats. Alla målsidor som Menuonline är värd för kommer automatiskt att ha en spårningskod.",
    "privacy_policy_ip_contacts": "IP-adresser: IP-adresser samlas in om dina kontakter som en del av tjänsterna (t.ex. i syfte att registrera plats på landsnivå och de IP-adresser som används när du skickar ett formulär och lämnar samtycke).",
    "privacy_policy_info_about_contacts_title": "INFORMATION VI SAMLAR IN OM DINA KONTAKTER",
    "privacy_policy_info_about_contacts_notice": "*Detta avsnitt gäller endast information som samlas in, används eller avslöjas av Menuonline om en kontoinnehavares kontakter i syfte att tillhandahålla tjänsterna*",
    "privacy_policy_info_about_contacts_desc": "Menuonline kommer endast att samla in, använda eller avslöja personlig information om Kontakter om Kontoinnehavaren har registrerat sig för vissa tjänster där de förser oss med en distributionslista. Kontoinnehavare är ensamt ansvariga för att se till att de har följt alla tillämpliga lagar när de tillhandahåller personlig information om sina kontakter till Menuonline via tjänsterna.",
    "privacy_policy_info_auto_collect_title": "Information som Menuonline samlar in automatiskt",
    "privacy_policy_info_auto_collect_desc1": "När kontoinnehavarens kontakter engagerar sig i en e-postkampanj som tagits emot från en kontoinnehavare, eller interagerar med en länk till en kontoinnehavares anslutna webbplats, kan Menuonline samla in information om sådan kontakts enhet och interaktion med e-posten genom användning av cookies. Denna information samlas endast in för att tillhandahålla tjänsterna och för att ge insikt till Kontoinnehavaren.",
    "privacy_policy_info_auto_collect_desc2": "Information vi samlar in om en kontakts enhet och de applikationer som kontakten använder för att komma åt e-postmeddelanden som skickas från kontoinnehavaren, via våra tjänster, kan innefatta kontaktens IP-adress, operativsystem, webbläsar-ID och annan relaterad information om kontaktens system och anslutning.",
    "privacy_policy_info_auto_collect_desc3": "Information vi samlar in om Contacts interaktion med e-postmeddelanden som skickas via tjänsterna kan inkludera datum och tider då Contact får åtkomst till e-post och surfaktiviteter, och information om tjänsternas prestanda, såsom leveransbarheten av e-postmeddelanden och annan kommunikation som skickas till Contact av en Kontoinnehavare via tjänsterna.",
    "privacy_policy_info_auto_collect_desc4": "Observera att Menuonline inte kommer att kontakta någon av dina kontakter, såvida det inte krävs enligt tillämplig lag.",
    "privacy_policy_why_we_collect_title": "VARFÖR VI SAMLAR IN DIN INFORMATION",
    "privacy_policy_why_we_collect_desc": "Menuonline samlar in information för följande ändamål:",
    "privacy_policy_why_collect_1": "att tillhandahålla Menuonline-kontoinnehavare de tjänster de prenumererar på, inklusive att använda kontoinnehavarinformation för tjänsterna;",
    "privacy_policy_why_collect_2": "för administration och kontohantering, samt tillgång till mycircles hemsida;",
    "privacy_policy_why_collect_3": "om du har samtyckt till att ta emot marknadsförings- och reklammaterial, för att informera dig om andra produkter och tjänster som erbjuds av Menuonline; och",
    "privacy_policy_why_collect_4": "för att svara på kontoinnehavarens meddelanden eller för att skicka information som du begär. Detta kan innefatta, men är inte begränsat till:",
    "privacy_policy_why_collect_5": "specialerbjudanden och uppdateringar;",
    "privacy_policy_why_collect_6": "nyhetsbrev; och",
    "privacy_policy_why_collect_7": "servicemeddelanden.",
    "privacy_policy_opt_out_desc": "Du kan när som helst välja att välja bort att ta emot kommunikation genom att klicka på knappen för att avsluta prenumeration längst ned i varje e-postmeddelande som skickas från Menuonline till dig.",
    "privacy_policy_use_disclosure_title": "ANVÄNDNING OCH UTSLÄPP AV PERSONINFORMATION",
    "privacy_policy_use_disclosure_desc1": "Personlig information som lämnas till Menuonline, inklusive via Menuonlines webbplats eller en föreskriven blankett, hålls strikt konfidentiell såvida den inte avslöjas i enlighet med en Kontoinnehavares uttryckliga samtycke eller lagkrav. Under alla omständigheter är all personlig information som tillhandahålls på någon \"Menuonline\"-plattform av kontoinnehavare frivillig: all personlig information som tillhandahålls av en kontoinnehavare skickas efter kontoinnehavarens gottfinnande. Observera att återkallande av ditt samtycke kan leda till att du inte kan komma åt eller använda vissa Menuonline-tjänster.",
    "privacy_policy_use_disclosure_desc2": "Menuonline använder tredje part och externa tjänsteleverantörer med avseende på sin webbplats och tjänster. Kontoinnehavarens personuppgifter kan komma att delas med sådana tredje parter och leverantörer. Vi kan också tillhandahålla samlad statistik om kontoinnehavare, försäljning, trafikmönster och relaterad information till välrenommerade tredje parter. Menuonline kräver att alla tredjepartsleverantörer som har tillgång till personlig information är bundna av skyldigheter i enlighet med denna privata policy och att sådana leverantörer endast samlar in, använder eller avslöjar kontoinnehavarens personliga information i syfte att tillhandahålla Menuonline-tjänster.",
    "privacy_policy_use_disclosure_desc3": "Personlig information som tillhandahålls av kontoinnehavare kommer inte att säljas, delas eller hyras ut till externa organisationer om inte i enlighet med ditt underförstådda eller uttryckliga samtycke. Menuonline förbehåller sig rätten att kontakta alla kontoinnehavare för att vidarebefordra information, svara på frågor eller meddela eventuella ändringar i vårt program eller vår policy. Observera att Menuonline inte kommer att kontakta någon av dina kontakter, såvida det inte krävs enligt tillämplig lag.",
    "privacy_policy_use_disclosure_desc4": "Menuonline kommer endast att avslöja personlig information om samtycke ges av den relevanta personen eller:",
    "privacy_policy_disclosure_list_1": "när det är tillåtet eller krävs enligt lag, till exempel som svar på en stämning eller annan juridisk process;",
    "privacy_policy_disclosure_list_2": "till en mottagare av hela eller delar av Menuonline;",
    "privacy_policy_disclosure_list_3": "till Menuonlines tjänsteleverantörer i enlighet med denna policy; eller",
    "privacy_policy_disclosure_list_4": "för att uppfylla rättsliga skyldigheter, inklusive men inte begränsat till regulatoriska rapporteringsskyldigheter.",
    "privacy_policy_service_providers_title": "Menuonline använder följande tredje parter och tjänsteleverantörer (personlig information om dina kontakter lämnas inte ut till \"sådana\" tredje parter):",
    "privacy_policy_service_provider_1": "Vi använder ManyChat för att tillhandahålla webbchatttjänster för försäljning och support, samt uppföljande e-postmeddelanden om dessa konversationer.",
    "privacy_policy_service_provider_2": "Vi använder AskNicely för att undersöka våra kunder i syfte att fråga om kvaliteten på service och support de får.",
    "privacy_policy_service_provider_3": "Vi använder join.me för att schemalägga kund- och potentiella kundmöten, supportsamtal och programvarudemonstrationer.",
    "privacy_policy_service_provider_4": "Vi använder Google Analytics för att spåra anonym webbplatsaktivitet och mäta användningsmönstren på vår webbplats.",
    "privacy_policy_breach_notification_title": "ANMÄLAN AV BROTT",
    "privacy_policy_breach_notification_desc": "Menuonline ska följa och ge kontoinnehavare rimlig hjälp i överensstämmelse med tillämpliga lagar med avseende på all obehörig användning, åtkomst eller avslöjande av personlig information.",
    "privacy_policy_rights_access_title": "DINA RÄTTIGHETER OCH TILLGÅNG",
    "privacy_policy_rights_access_desc": "Du har rätt att få tillgång till personlig information som Menuonline behandlar om dig. Du kan begära information från oss om:",
    "privacy_policy_rights_access_list_1": "De personuppgifter vi har om dig",
    "privacy_policy_rights_access_list_2": "De berörda kategorierna av personlig information",
    "privacy_policy_rights_access_list_3": "Syften med behandlingen",
    "privacy_policy_rights_access_list_4": "Detaljer till vilka din personliga information har\/kommer att lämnas ut",
    "privacy_policy_rights_access_list_5": "Hur länge vi behåller dina personuppgifter",
    "privacy_policy_rights_access_list_6": "Om vi ​​inte samlat in informationen direkt från dig, information om källan",
    "privacy_policy_rights_access_list_7": "Hur man lämnar in ett klagomål till rätt tillsynsmyndighet",
    "privacy_policy_rights_access_list_8": "Du kan också begära följande från oss:",
    "privacy_policy_rights_access_list_9": "Att vi uppdaterar eventuell ofullständig eller felaktig information om dig",
    "privacy_policy_rights_access_list_10": "Begär att vi raderar dina personuppgifter i enlighet med gällande lagar",
    "privacy_policy_rights_access_contact": "Du kan begära att vi vidtar dina rättigheter genom att kontakta oss på 2851 Dufferin Street, Toronto, ON, Kanada, eller genom att maila oss på {link}.",
    "privacy_policy_accountability_title": "ANSVARIGHET",
    "privacy_policy_accountability_desc": "Menuonline har åtagit sig att vara ansvarig för den personliga och konfidentiella information som du lämnar till oss. För kontaktinformation, se slutet av denna policy.",
    "privacy_policy_links_other_sites_title": "LÄNKAR TILL ANDRA WEBBPLATSER",
    "privacy_policy_links_other_sites_desc1": "Menuonlines webbplats kan innehålla länkar till andra webbplatser. Var medveten om att Menuonline inte är ansvarigt för andra webbplatsers sekretesspraxis. Vi uppmuntrar våra kontoinnehavare att vara medvetna om att när de lämnar vår webbplats bör de noggrant läsa de privata policyförklaringarna för varje webbplats som samlar in personligt identifierbar information.",
    "privacy_policy_links_other_sites_desc2": "Denna privata policy gäller endast för information som samlas in av oss.",
    "privacy_policy_links_other_sites_desc3": "Om du använder en länk för att gå från Menuonline-webbplatsen för att köpa produkter eller tjänster, genomför du en köptransaktion enligt leverantörens regler och föreskrifter, inte Menuonline.",
    "privacy_policy_cookies_title": "PRIVATA POLICYCOOKIES",
    "privacy_policy_cookies_desc1": "Menuonlines webbplats och tjänster använder cookies för att samla in och lagra viss information i enlighet med denna policy. En \"cookie\" är en liten textfil som skickas från en webbplats och lagras på användarens dator av användarens webbläsare. När du besöker en webbplats som använder cookies, laddas en cookie ner till din dator eller mobila enhet. Nästa gång du besöker den webbplatsen kommer din enhet att komma ihåg användbar information som inställningar, besökta sidor eller inloggningsalternativ.",
    "privacy_policy_cookies_desc2": "Cookies används ofta för att lägga till funktionalitet till webbplatser eller för att säkerställa att de fungerar mer effektivt. Vår webbplats är beroende av cookies för att optimera användarupplevelsen och säkerställa att webbplatsens tjänster fungerar korrekt.",
    "privacy_policy_cookies_desc3": "De flesta webbläsare tillåter viss kontroll för att begränsa eller blockera cookies genom webbläsarinställningarna, men om du inaktiverar cookies kan du upptäcka att detta påverkar din förmåga att använda vissa delar av vår webbplats eller tjänster.",
    "privacy_policy_cookies_analytical_purpose": "<strong> Analytiska syften: <\/strong> Vi använder cookies för att analysera kontoinnehavarens aktivitet för att förbättra vår webbplats. Till exempel kan vi använda cookies för att titta på aggregerade mönster som de funktioner som kontoinnehavare använder. Vi kan använda sådan analys för att få insikter om hur vi kan förbättra funktionaliteten och användarupplevelsen av vår webbplats.",
    "privacy_policy_cookies_preferences_user_experience": "<strong> Dina inställningar och användarupplevelse: <\/strong> Vi använder cookies för att samla in viss information om besökare och kontoinnehavare, såsom webbläsartyp, server, språkinställningar och landsinställningar, för att lagra kontoinnehavarens inställningar på vår webbplats för att göra kontoinnehavarens upplevelse mer konsekvent och bekväm. Vi använder cookies för att behålla ditt inloggade tillstånd när du besöker vår webbplats upprepade gånger.",
    "privacy_policy_cookies_targeted_ads": "<strong> Tillhandahålla mättjänster och bättre riktade annonser och marknadsföring: <\/strong> Vi använder cookies, webbsignaler och andra lagringstekniker från tredjepartspartners som Google och Facebook för mättjänster, bättre inriktning av annonser och för marknadsföringsändamål. Detta sker när du besöker vår webbplats. Dessa cookies, webbfyrar och andra lagringstekniker tillåter oss att visa Menuonline-kampanjmaterial för dig på andra webbplatser som du besöker på Internet.",
    "privacy_policy_security_title": "SÄKERHET",
    "privacy_policy_security_desc1": "Menuonline gör ansträngningar för att skydda konfidentiell information av alla slag, inklusive personlig information, med organisatoriska, fysiska, mekaniska och elektroniska skyddsåtgärder som är lämpliga för informationens känslighet. Våra servrar finns i Kanada och följer branschstandarder för säkerhet.",
    "privacy_policy_security_desc2": "Observera dock att vi inte kan garantera att de åtgärder vi upprätthåller garanterar informationens säkerhet.",
    "privacy_policy_retention_title": "RETENTION",
    "privacy_policy_retention_desc": "Vi behåller personlig information om kontoinnehavare och kontakter så länge som krävs för de syften som anges i denna privata policy och i enlighet med lagringsbestämmelserna i användarvillkoren.",
    "privacy_policy_access_questions_title": "TILLGÅNG, FRÅGOR OCH FRÅGOR",
    "privacy_policy_access_questions_desc": "En individ som har lämnat personlig information till Menuonline kan lämna in en skriftlig begäran om att få tillgång till eller korrigera hans eller hennes personuppgifter som behålls av Menuonline.",
    "terms_obj": {
        "terms_conditions_title": "VILLKOR OCH VILLKOR",
        "terms_conditions_subtitle": "Villkor för nya och befintliga kunder",
        "terms_conditions_desc1": "FÖLJANDE NYCKELPUNKTER (INKLUSIVE AVSNITTSREFERENSER) TILLHANDAHÅLLS ENDAST FÖR DIN bekvämlighet och ERSÄTTER INTE DE FULLSTÄNDA VILLKOR OCH VILLKOR. DET ÄR DITT ANSVAR ATT LÄSA DESSA VILLKOR INNAN DU ANVÄNDER Menuonline-TJÄNSTEN.",
        "terms_conditions_desc2": "Att använda Menuonline innebär att du godkänner villkoren och representerar och garanterar att du är auktoriserad av din organisation eller ditt företag att göra det. (Se s.3 \"Hur det fungerar\")",
        "terms_conditions_desc3": "Menuonline är en tillståndsbaserad marknadsföringstjänst. Avsiktlig sändning av \"spam\" eller brott mot villkoren kommer att resultera i att ditt konto stängs. (Se s.16 \"Acceptabel användning av tjänsten\")",
        "terms_conditions_desc4": "Data som du lagrar i Menuonline såsom kontaktuppgifter, e-postinnehåll och information du samlar in via Menuonline tillhör dig. (Se s.14 \"Vad äger du?\").",
        "terms_conditions_desc5": "Menuonline-mjukvaran och innehållet på vår webbplats tillhör oss. (Se s.13 \"Vad äger vi?\")",
        "terms_conditions_desc6": "Förutom dina egna användare kan data eller opublicerat innehåll som du är värd hos Menuonline endast ses av Menuonline-anställda och partners som har undertecknat sekretessavtal. (Se s.14 \"Vad äger du?\").",
        "terms_conditions_desc7": "All personligt identifierbar information (PII) lagras endast på servrar i Kanada. (Se s.14 \"Vad äger du?\").",
        "terms_conditions_desc8": "Tjänsten Menuonline är föremål för lagarna i Kanada och provinsen Ontario. (Se s.24 \"Gällande lag\")",
        "terms_conditions_desc9": "Alla förfrågningar om din data eller ditt innehåll från individer, poliser eller andra tillsynsmyndigheter från Kanada eller utanför Kanada kommer alltid att hänvisas till dig. Det enda undantaget skulle vara om detta innebär att vi bryter mot kanadensisk eller Ontarios lag; dock kommer vi i alla fall att motsätta oss all utgivning av dina uppgifter från någon annan än dig. (Se s.14 \"Vad äger du?\").",
        "terms_conditions_desc10": "Menuonline säkerhet är bara så bra som din hantering av användarnamn och lösenord. Vi tar inte betalt för Menuonline användarkonton så skapa ett separat för varje användare och håll lösenord hemliga. Du är ansvarig för ditt konto. (Se s.3 \"Hur det fungerar\")",
        "terms_conditions_desc11": "Du kan avsluta ditt konto när som helst. Vi ger inga återbetalningar på oanvänd kredit om du avbryter. (Se s.3 \"Hur det fungerar\")",
        "terms_conditions_desc12": "Vi kan avsluta ditt konto när som helst. Vi ger återbetalningar för e-postkrediter och oanvända tjänster om vi avslutar ditt konto. (Se s.3 \"Hur det fungerar\")",
        "terms_conditions_desc13": "Dina uppgifter kommer att raderas efter avbokning och kan även raderas om en kontoanvändare inte har loggat in på 12 månader eller mer. Vi kommer att behålla aggregerade data från ditt konto som inte inkluderar personligt identifierbar information för statistiska ändamål. (Se s.3 \"Hur det fungerar\")",
        "terms_conditions_desc14": "E-postkrediter köps på en pay-as-you-go-basis och går aldrig ut. Det enda undantaget är om du inte har loggat in på ditt konto på 12 månader. I det här fallet förbehåller vi oss rätten att radera ditt konto, dina data och tillhörande krediter. (Se s.10 \"Vad betalar jag för tjänsten?\")",
        "terms_conditions_desc15": "Vi arbetar hårt för att säkerställa att Menuonline är igång hela tiden och för att meddela förhandsmeddelande om vi behöver tillfälligt avbryta tjänsten för underhåll. Vi garanterar dock inte att tjänsten kommer att vara igång hela tiden och vi är inte ansvariga för eventuella förluster du lider av att använda Menuonline, inklusive förluster från din sida om systemet inte körs vid en tidpunkt som du behöver det. (Se s.17 \"Ansvarsfriskrivningar\").",
        "terms_conditions_desc16": "Vi övervakar inte ditt innehåll eller vem du skickar meddelanden till. Däremot övervakar vi klagomål och andra problem som skapas av dina aktiviteter. För många klagomål eller problem kan leda till att din tjänst avbryts efter vårt gottfinnande. (Se s.7 \"Övervakning\")",
        "terms_conditions_desc17": "Vi är fast beslutna att tillhandahålla utmärkt produktsupport för att hjälpa dig att bli självförsörjande med Menuonline. Vi tillhandahåller support i form av dokumentation och video på vår hemsida, Menuonline supportbiljettsystem och per telefon. Det finns ingen kostnad för produktsupport; vi förbehåller oss dock rätten att avbryta support för specifika kundkonton efter eget gottfinnande. (Se s.5 \"Support\")",
        "terms_conditions_desc18": "Produkttjänster är till för de tillfällen då du vill att vi ska göra något arbete relaterat till Menuonline istället för att göra det själv. Vårt pris för produkttjänster är 135 USD per timme. Vi erbjuder även konsulttjänster till högre priser. Vi förbehåller oss rätten att ändra våra priser när som helst. (Se s.6 \"Produkt- och konsulttjänster\")",
        "terms_conditions_desc19": "Vi kommer att informera dig om vi tror att det har skett ett brott mot dina uppgifter och kommer att anstränga oss för att tillhandahålla relaterad information och information. (Se s.14 \"Vad äger du?\")",
        "terms_conditions_desc20": "Vi förbehåller oss rätten att göra ändringar i detta avtal när som helst. Vi kommer att försöka informera dig om ändringar av villkoren men användningen av tjänsten innebär att du godkänner ändringar i villkoren. (Se s.9 “Ändringar”).",
        "terms_conditions_desc21": "Villkoren och vår integritetspolicy har utarbetats endast på engelska, och du samtycker till att den engelska versionen av villkoren och sekretesspolicyn kommer att ha prioritet i händelse av konflikt med någon översatt version.",
        "terms_conditions_mycircle": "MYCIRCLE VILLKOR OCH VILLKOR",
        "terms_conditions_last_update": "SENAST UPPDATERING: 14 januari 2020",
        "terms_conditions_usage_agreement": "Vänligen läs dessa användarvillkor ('Villkoren') noggrant innan du använder den här webbplatsen ('Webbplatsen'). Dessa villkor befriar Menuonline ('Menuonline', 'vi' eller 'oss') och andra från ansvar och\/eller begränsar vårt och deras ansvar och innehåller andra viktiga bestämmelser som gäller för din användning av denna webbplats.",
        "terms_conditions_acceptance": "Genom att besöka eller använda denna webbplats eller tjänsten samtycker du för din egen räkning, och på uppdrag av alla organisationer för vars räkning du kan agera (sammantaget kallat \"du\") att acceptera och följa dessa villkor för varje användning av och varje besök på denna webbplats.",
        "terms_conditions_services": "1. Våra tjänster.",
        "terms_conditions_services_desc": "Tjänsterna som vi erbjuder via webbplatsen är en tjänst som låter dig skapa, skicka och hantera e-postmeddelanden (\"E-postmeddelanden\") till mottagare som du väljer (\"Tjänsten\").",
        "terms_conditions_license": "2. Licens.",
        "terms_conditions_license_desc": "Med förbehåll för dessa villkor ger Menuonline dig härmed en icke-exklusiv, begränsad, icke-överlåtbar licens under giltighetstiden för att se webbplatsen och använda Tjänsten i enlighet med funktionerna i Tjänsten. Vi förbehåller oss alla rättigheter som inte uttryckligen beviljas här i tjänsten och Menyonline-innehållet (enligt definitionen nedan).",
        "terms_conditions_account": "3. Hur det fungerar.",
        "terms_conditions_account_desc": "För att använda Tjänsten måste du registrera dig för ett konto. Ditt konto är gratis. Ditt konto ger dig tillgång till Tjänsten och \"funktioner\" som vi kan etablera och underhålla från tid till annan. För att vara berättigad till ett konto måste du:",
        "terms_conditions_account_requirements": [
            "vara minst arton (18) år gammal och kunna ingå avtal;",
            "slutföra registreringsprocessen som vi har angivit;",
            "godkänner dessa villkor;",
            "tillhandahålla sann, fullständig och uppdaterad kontakt- och fakturerings-\/betalningsinformation."
        ],
        "responsibility": "Ansvar.",
        "responsibilityText": "Du är ensam ansvarig för aktiviteten som sker på ditt konto, och du måste hålla ditt kontolösenord konfidentiellt och säkert. Konton är gratis, så vi uppmuntrar dig att skapa ett annat konto för varje användare du har. Du är också ansvarig för alla konton som du har tillgång till, oavsett om du har godkänt användningen av det kontot eller inte. Du godkänner att du är ensam ansvarig för din interaktion med dem som du skickar e-postmeddelanden till, och du kommer endast att skicka e-postmeddelanden till de personer som du har samtycke från. Du måste omedelbart meddela oss om all obehörig användning av dina konton.",
        "compliance_with_CASL": "Efterlevnad av Canadian Anti Spam Legislation (CASL).",
        "compliance_with_CASL_text": "Menuonlines roll är att underlätta hanteringen av samtyckesinställningar, inte att upprätthålla lagstiftning. Det är ditt ansvar att följa CASL. Vi hjälper dig genom att ställa in standardfunktioner och genom att underlätta att erhålla och uppdatera dokumenterat uttryckligt samtycke och hantera löpande utgångsdatum för underförstått samtycke. Du har full kontroll över hanteringen av samtycke för din databas; du kan uppdatera samtyckesstatusen för alla dina kontakter samtidigt när som helst, åsidosätta standardinställningar och inaktivera automatiskt upphörande av underförstått samtycke.",
        "closing_account": "Avsluta ditt konto.",
        "closing_account_text": "Antingen du eller Menuonline kan säga upp detta avtal när som helst och av vilken anledning som helst genom att meddela den andra parten. Om du tillhandahåller någon information som är osann, felaktig, inte aktuell eller ofullständig eller använder tjänsten på ett sätt som bryter mot dessa villkor, har vi rätt att omedelbart stänga av ditt konto och avsluta din åtkomst till tjänsten utöver alla andra åtgärder som är tillgängliga för oss. Om du inte använder ditt konto på tolv (12) månader kan vi komma att avsluta ditt konto. Om vi ​​avslutar ditt konto av någon anledning kommer vi att återbetala dig för eventuella krediter du har kvar. När vi har avslutat det kommer vi att permanent radera ditt konto och all data som är kopplad till det, inklusive de e-postmeddelanden du har skickat. Vi kan behålla aggregerade data från ditt konto som inte inkluderar personligt identifierbar information för statistiska ändamål.",
        "emailing_content": "E-posta innehåll.",
        "emailing_content_text": "När du interagerar med Menuonline via denna webbplats eller tjänsten samtycker du till att tillhandahålla sann och korrekt information. Vidare förstår du att tillhandahållande av konfidentiell information via e-post är en osäker praxis. Du förstår att du är ensam ansvarig för allt som du skickar till någon som använder vår tjänst. syften.",
        "support": "Stöd.",
        "support_text": "Vi ger dig stöd för att hjälpa dig att bli självförsörjande med Tjänsten. Vi ger dig support i form av dokumentation och videor på webbplatsen, Menuonline supportbiljettsystem och per telefon. Du kan nå oss genom att klicka på fliken \"Support\" högst upp på sidan. Det finns ingen kostnad för produktsupport; vi förbehåller oss dock rätten att avbryta support för specifika kundkonton efter eget gottfinnande.",
        "product_and_consulting_services": "Produkt- och konsulttjänster.",
        "product_and_consulting_services_text": "Produkttjänster är till för de tillfällen då du vill att vi ska göra något arbete relaterat till Menuonline istället för att göra det själv. Vårt pris för produkttjänster är 125 USD per timme. Vi erbjuder även konsulttjänster till högre priser. Vi förbehåller oss rätten att ändra våra priser när som helst.",
        "monitoring": "Övervakning.",
        "monitoring_text": "Vi kommer inte att övervaka eller bedöma innehållet i information som överförs via Tjänsten, men kommer att undersöka om vi får klagomål om möjlig olämplig användning. Menuonline kan, när som helst och efter eget gottfinnande, fatta beslut om att särskilda användningar är eller inte är lämpliga med eller utan meddelande till dig, enligt följande riktlinjer.",
        "term": "Kalla.",
        "term_text": "Perioden börjar när du registrerar dig för ett konto hos oss och fortsätter så länge du använder Tjänsten. Du representerar och garanterar att du har befogenhet och förmåga att registrera dig för ett konto för oss (inklusive om du registrerar dig för en organisations räkning).",
        "changes": "Ändringar.",
        "changes_text": "Perioden börjar när du registrerar dig för ett konto hos oss och fortsätter så länge du använder Tjänsten. Du representerar och garanterar att du har befogenhet och förmåga att registrera dig för ett konto för oss (inklusive om du registrerar dig för en organisations räkning).",
        "payment": "BETALNING",
        "what_do_i_pay_for_service": "Vad betalar jag för tjänsten?.",
        "what_do_i_pay_for_service_text": "Du kan köpa förbetalda krediter för att skicka e-post (\"krediter\") via vår tjänst, som vi beskriver i detalj på sidan \"prissättning\" på vår webbplats. Krediterna förfaller aldrig och det finns inga återkommande avgifter för dessa krediter. Det enda undantaget är att om du inte använder ditt konto under tolv (12) månader kan vi avsluta ditt konto och eventuella oanvända krediter. Vi kan ändra vår prissättning när som helst genom att uppdatera prissidorna på vår webbplats, vilket kommer att gälla för ditt nästa köp av krediter.",
        "how_do_i_pay_for_service": "Hur betalar jag för tjänsten?.",
        "how_do_i_pay_for_service_text": "Du kan betala för krediter med vilket kreditkort som helst. Vi kan ändra vilka betalningsmetoder vi accepterar när som helst.",
        "can_i_get_refund": "Kan jag få en återbetalning?.",
        "can_i_get_refund_text": "De belopp du har betalat för med hjälp av Tjänsten återbetalas inte om du säger upp ditt konto. Om vi ​​avslutar ditt konto kommer vi att återbetala dig för eventuella krediter du har kvar.",
        "rights": "RÄTTIGHETER",
        "what_do_we_own": "Vad äger vi?.",
        "what_do_we_own_text": "Förutom innehållet som du tillhandahåller till oss (inklusive, utan begränsning, kontaktuppgifter, e-postinnehåll och information som du samlar in via tjänsten) (\"Ditt innehåll\"), allt material som ingår på vår webbplats, tjänsten och allt material däri eller överförs därigenom, inklusive utan begränsning, programvara, bilder, text, grafik, illustrationer, logotyper, patent, varumärken, musik, tjänstemärken, fotografier, videor och andra användare. \"Menuonline-innehåll\") (och alla immateriella rättigheter relaterade till detta) ägs av oss eller våra licensgivare.",
        "what_do_you_own": "Vad äger du?.",
        "what_do_you_own_text": "Vi hävdar ingen äganderätt till ditt innehåll. Ditt innehåll är ditt och du behåller äganderätten till ditt innehåll som du laddar upp till tjänsten. Genom att dela ditt innehåll via tjänsten samtycker du till att tillåta andra att se, redigera och\/eller dela ditt innehåll i enlighet med dina inställningar och dessa villkor. Du intygar och garanterar att du antingen äger eller har tillstånd att använda allt material som du använder i dina e-postmeddelanden. Vi får använda eller avslöja ditt material endast så som vi beskriver i dessa villkor och vår integritetspolicy.",
        "privacy_statement": "Menuonline respekterar sina användares integritet. All personlig information som Menuonline samlar in via denna webbplats eller tjänsten är föremål för Menuonlines sekretesspolicy som är införlivad genom hänvisning i dessa villkor. Vi kan komma att använda och avslöja din information enligt vår integritetspolicy.",
        "access_to_your_content": "Menuonline kommer inte att ge åtkomst till ditt innehåll till någon annan än de anställda och entreprenörer på Menuonline som har utfört lämpliga sekretessavtal med oss ​​eller så har vi ditt samtycke.",
        "governmentRequest": "Om vi ​​får en begäran från en regering eller tillsynsmyndighet om att få tillgång till ditt innehåll kommer vi att meddela dig om begäran och begära ditt samtycke innan vi släpper något av ditt innehåll. Vi kommer att motstå alla förfrågningar om Ditt Innehåll och kommer inte att släppa Ditt Innehåll som svar på denna typ av begäran utan din tillåtelse eller om det krävs av en domstol med behörig jurisdiktion eller tillämpligt tillsynsorgan.",
        "breachNotification": "I händelse av att vi blir medvetna om att ditt innehåll har varit föremål för obehörig åtkomst, användning, kopiering, stöld, förlust eller användning eller avslöjande i strid med dessa villkor (ett \"överträdelse\"), kommer vi att meddela dig omedelbart och informera dig om vad som hände. Du samtycker till att intrång kommer att hanteras i enlighet med tillämplig lag, och om vi behöver att du informerar medlemmarna om dina distributionslistor för e-post kommer du att acceptera att göra det omgående och enligt anvisningar.",
        "contentRetention": "Vi kommer att behålla och använda och överföra Ditt Innehåll i Kanada förutom med ditt föregående skriftliga godkännande eller anvisning (till exempel om du väljer att skicka Ditt Innehåll till individer utanför Kanada). Servrarna som används för att tillhandahålla tjänsten finns i Kanada.",
        "accessTrouble": "Om du har problem med att komma åt ditt innehåll, kommer Menuonline att hjälpa dig att hämta ditt innehåll på basis av tid och material.",
        "reportingAbuse": "Om du tror att någon bryter mot något av dessa villkor, vänligen meddela oss omedelbart. Om du har fått skräppost som du tror kom från en annan användare av tjänsten, om du tror att en annan användare av tjänsten har postat material som bryter mot någon upphovsrätt, eller om du tror att en annan användare av tjänsten på annat sätt bryter mot dessa villkor, kontakta oss på support@menuonline.com.",
        "acceptableUse": "Du intygar och garanterar att din användning av tjänsten kommer att följa alla tillämpliga lagar och förordningar. Du samtycker till att du är ansvarig för att avgöra om Tjänsten kommer att följa och är lämplig för dig att använda i din jurisdiktion. För större säkerhet samtycker du till att du ska (och samtycker till att säkerställa att dina anställda, agenter och auktoriserade användare ska) ...",
        "exportLaws": "Vi garanterar inte att webbplatsen eller tjänsten är lämplig eller tillgänglig för användning utanför Kanada, och åtkomst till dem från territorier där deras innehåll är olagligt är förbjuden. Du får inte använda eller exportera eller återexportera Menuonline-innehållet eller annat material på denna webbplats eller någon kopia eller anpassning i strid med tillämpliga lagar eller förordningar inklusive men inte begränsat till kanadensiska och amerikanska exportlagar och förordningar.",
        "disclaimers": "Tjänsten och denna webbplats tillhandahålls \"i befintligt skick\" utan några representationer, garantier eller villkor av något slag, och Menuonline avsäger sig uttryckligen, i den utsträckning det är tillåtet enligt tillämpliga lagar, alla representationer, garantier och villkor, uttryckliga eller underförstådda, enligt lag eller på annat sätt, inklusive utan begränsning alla underförstådda garantier och villkor för säljbarhet, eller lämplighet för något speciellt syfte eller användning av tredje part...",
        "limitLiability": "Menuonlines sammanlagda ansvar enligt detta avtal, oavsett om det är kontrakt eller skadeståndsansvar (inklusive vårdslöshet), som ett resultat av garantibrott, strikt ansvar eller enligt någon annan teori om ansvar överhuvudtaget, kommer att begränsas till direkta skador till ett belopp som inte överstiger de totala avgifter som betalats till Menuonline enligt dessa villkor under de tolv (12) månaderna som inträffade närmast före den senaste händelsen som inträffade närmast före den senaste skadeståndsskyldigheten.",
        "liabilityExclusions": "Ingen av Menuonline, dess dotterbolag eller deras respektive styrelseledamöter, tjänstemän, anställda, agenter eller andra representanter (gemensamt \"Menuonline \"Representanter\") eller operatörerna av några anslutna nätverk kommer att ha något ansvar eller ansvar i samband med denna webbplats eller tjänsten för: (i) eventuella indirekta, följdskador, tillfälliga, exemplariska, bestraffande eller speciella skador; eller (ii) för eventuella skador, vare sig direkta, indirekta, följdskada, tillfälliga, exemplariska, bestraffande eller speciella, karakteriserade som förlorade intäkter, förlorade besparingar eller inkomster eller förlorad vinst...",
        "indemnity": "Du kommer att hålla Menuonline och\/eller Menuonline-representanterna ('Menuonline skadeslösa parter') skadeslösa från och mot alla anspråk från tredje part, och alla böter eller straff som utdöms mot oss, som uppstår till följd av din användning av informationen som du kommer åt från denna webbplats eller din användning av tjänsten, och eventuella brott mot dessa villkor...",
        "ipInfringement": "Menuonline ska försvara, gottgöra och hålla dig skadeslös från och mot alla anspråk, krav, åtgärder, stämningar eller förfaranden som riktas eller väcks mot dig av en tredje part som hävdar att din tillgång till eller användning av Tjänsten i enlighet med detta Avtal gör intrång i tredje parts immateriella rättigheter enligt kanadensisk lag (ett \"IP-intrångsanspråk\")...",
        "equitableRelief": "Om du bryter mot dessa villkor kan vi begära föreläggande (vilket innebär att vi kan begära ett domstolsbeslut för att stoppa dig) eller annan rättvis befrielse. Du samtycker till att detta kommer att vara ett tillägg till och utan att det påverkar eventuella andra rättigheter vi kan ha enligt lag eller i eget kapital.",
        "linksToOtherSites": "Om vi ​​tillhandahåller länkar till tredje parts webbplatser tillhandahålls de endast för bekvämlighets skull. Om du använder dessa länkar kommer du att lämna vår webbplats. Vi kontrollerar inte dessa tredje parts webbplatser eller stöder dem. Du samtycker till att vi inte är ansvariga för någon av dessa tredje parts webbplatser eller deras innehåll...",
        "thirdPartyRequests": "Om vi ​​måste tillhandahålla information som svar på en juridisk eller myndighetsförfrågan som rör ditt konto eller din användning av tjänsten, samtycker du till att vi kan debitera dig för våra kostnader. Dessa avgifter kan inkludera våra juridiska arvoden och kostnaderna för vår anställdas tid som ägnas åt att förbereda svaret på begäran.",
        "assignment": "Detta avtal, och alla rättigheter och licenser som beviljas härunder, får inte överföras eller tilldelas av dig, men kan tilldelas av Menuonline utan begränsningar, efter rimligt förhandsmeddelande till dig.",
        "governingLaw": "Dessa villkor kommer att styras av och tolkas i enlighet med alla tillämpliga federala lagar och lagarna i Ontario utan hänsyn till dess lagkonflikts bestämmelser. Du samtycker till att underkasta dig den exklusiva jurisdiktionen för domstolarna i Ontario för att avgöra eventuella tvister rörande dessa villkor.",
        "severability": "Om en domstol med behörig jurisdiktion av någon anledning finner att någon bestämmelse i dessa villkor eller delar därav är olaglig eller av någon anledning omöjlig att verkställa, kommer denna bestämmelse att verkställas i den maximala omfattning som är tillåten för att ge verkan åt avsikten med dessa villkor, och resten av dessa villkor kommer att fortsätta med full kraft och effekt och förbli verkställbar.",
        "waiver": "Varje avstående från eller samtycke till att avvika från kraven i dessa villkor kommer endast att gälla om det är skriftligt och undertecknat av oss, och endast i det specifika fallet och för det specifika syftet för vilket det har getts. Vår underlåtenhet att utöva eller vår försening med att utöva, någon rättighet enligt dessa villkor kommer inte att fungera som ett avstående från våra rättigheter.",
        "furtherAssurances": "Varje part samtycker till att underteckna och tillhandahålla alla dokument och vidta alla åtgärder (eller orsaka att dessa åtgärder utförs) som är nödvändiga för att uppfylla sina skyldigheter enligt dessa villkor.",
        "notices": "Alla meddelanden till Menuonline måste vara skriftliga och skickas till oss via e-post. Meddelanden till oss måste skickas till våra kundtjänstrepresentanter på support@menuonline.com. Meddelanden till dig kan skickas till den adress eller e-postadress som du angett som en del av din registrering. Dessutom kan vi sända meddelanden eller meddelanden via denna webbplats för att informera dig om ändringar på denna webbplats eller andra viktiga frågor, och dessa sändningar kommer att utgöra meddelande till dig när vi publicerar dem.",
        "entireAgreement": "Dessa villkor och vår sekretesspolicy utgör hela avtalet mellan dig och oss med avseende på din användning av webbplatsen och tjänsten, och ersätter alla tidigare avtal, representationer och överenskommelser mellan Menuonline och dig med avseende på detta ämne. I händelse av konflikt mellan integritetspolicyn och dessa villkor kommer dessa villkor att gälla.",
        "language": "Parterna har uttryckligen begärt och krävt att dessa villkor, vår integritetspolicy och alla relaterade dokument ska vara avfattade på engelska. Les parties conviennent et exigent expressément que ce Contrat et tous les documents qui s'y rapportent soient rédigés en anglais. Du samtycker till att den engelska versionen av dessa villkor och sekretesspolicy kommer att ha prioritet i händelse av konflikt med någon översatt version.",
        "survival": "Alla bestämmelser i dessa villkor som till sin natur eller import är avsedda att överleva utgången eller uppsägningen av dessa villkor, ska fortsätta, inklusive, utan begränsning, avsnitten 17-19."
    },
    "collect_additional_info": "Vill du samla in ytterligare information?",
    "additionalInfoChoice": "Välj eventuell ytterligare information du vill samla in.",
    "additional_info_yes": "Ja",
    "additional_info_noSkip": "Nej, Skip",
    "additional_info_label": "Ytterligare information",
    "confirm_business_location": "Låt oss först bekräfta din företagsplats",
    "custom_get_more_reviews_form": "Anpassat formulär för få fler recensioner",
    "set_up_custom_form": "Ställ in ditt anpassade formulär för få fler recensioner per grupp",
    "reputation_name_label": "Få fler recensioner Namn",
    "reputation_name_placeholder": "Ange ryktesnamn",
    "group_label": "Grupp",
    "group_desc_title": "Gruppbeskrivningstitel",
    "group_desc": "Gruppbeskrivning",
    "select_group_placeholder": "Välj Grupp",
    "required_group_name": "Gruppnamn krävs",
    "before_asking_review": "Innan vi ber kunderna om recension, låt oss ställa dem 1 till 4 frågor.",
    "feel_free_edit": "Redigera gärna vid behov.",
    "incentive_settings": "Incitamentinställningar",
    "incentivize_customer": "Vill du uppmuntra din kund att ge en recension?",
    "choose_incentive": "Välj det incitament du vill hänvisa till.",
    "scan_qr_feedback": "Skanna QR-koden och dela din värdefulla feedback med oss ​​angående din upplevelse.",
    "qr_code": "QR-kod",
    "scan_me": "SKANNA MIG!",
    "or_title": "ELLER",
    "send_otp_title": "Skicka OTP",
    "sign_in_title": "Logga in",
    "email_addresss_title": "E-postadress",
    "reset_password_title": "Återställ lösenord",
    "remember_password_title": "Kom ihåg lösenordet?",
    "digit_code_title": "Vänligen ange den 4-siffriga koden som skickades vidare ",
    "not_received_title": "Jag fick ingen verifieringskod!",
    "resend_title": "Vänligen skicka igen",
    "sign_up_form": "Skapa ditt konto",
    "sign_up_sub_title": "Logga in nu för att få tillgång till de senaste insikterna",
    "already_registered_title": "Har du redan ett Menuonline-konto?",
    "reserved_title": "Upphovsrätt ©2023 Menuonline. Alla rättigheter reserverade.",
    "terms_title": "Användarvillkor",
    "policy_title": "Sekretesspolicy",
    "sign_up_title": "Registrera dig",
    "terms_conditions_title": "Allmänna villkor",
    "business_type_title": "Affärstyp",
    "business_type_sub_title": "Vänligen välj en av företagstyperna som beskriver ditt företag bättre!",
    "salon_title": "Salong",
    "restaurant_title": "Restaurang",
    "pizza_store_title": "Pizzaaffär",
    "food_truck_title": "Food Truck",
    "check_domain_title": "Kontrollera domännamn",
    "start_exploring_title": "Börja utforska",
    "sign_in_form": "Logga in på ditt konto",
    "sign_in_sub_title": "Logga in nu för att få tillgång till de senaste insikterna",
    "remember_title": "Kom ihåg mig",
    "otp_title": "OTP",
    "password_title": "Lösenord",
    "forgot_title": "Glömt lösenordet?",
    "not_registered_title": "Har du inget konto?",
    "create_account_title": "Registrera dig nu",
    "create_form_title": "Skapa formulär",
    "first_name_title": "Förnamn",
    "last_name_title": "Efternamn",
    "email_title": "E-post",
    "mobile_number_title": "Mobilnummer",
    "birthday_title": "Födelsedag",
    "gender_title": "Kön",
    "address_title": "Adress",
    "short_answer_title": "Kort svar",
    "long_answer_title": "Långt svar",
    "single_answer_title": "Ett enda svar",
    "single_checkbox_title": "Enkel kryssruta",
    "multiple_choice_title": "Flerval",
    "drop_down_title": "Drop-down",
    "yes_no_title": "Ja eller Nej",
    "description_text_title": "Beskrivningstext",
    "file_upload": "Filuppladdning",
    "reset_password_form": "Återställ ditt lösenord",
    "reset_password_sub_title": "Återställ snabbt ditt lösenord och få tillgång till ditt konto.",
    "required_full_name_title": "Fullständigt namn krävs",
    "required_first_name_title": "Förnamn krävs",
    "valid_first_name_title": "Ange ett giltigt förnamn",
    "valid_full_name_title": "Ange ett giltigt fullständigt namn",
    "required_block_time": "Blocktid krävs",
    "required_last_name_title": "Efternamn krävs",
    "valid_last_name_title": "Ange ett giltigt efternamn",
    "valid_mobile_number_title": "Ange ett giltigt mobilnummer",
    "required_mobile_number_title": "Mobilnummer krävs",
    "required_already_mobile_number_title": "Mobilnummer krävs redan",
    "valid_otp_title": "Ange giltig otp",
    "valid_email_title": "Ange en giltig e-postadress",
    "required_email_title": "E-post krävs",
    "required_description_title": "Beskrivning krävs",
    "required_description_500_characters": "Ange minst 500 tecken",
    "required_description_600_characters": "Ange maximalt 600 tecken",
    "domain_title": "Domännamn",
    "required_password_title": "Lösenord krävs",
    "required_password_regex_title": "Måste innehålla minst 8 tecken, minst ett versal, ett gement tecken, ett nummer och ett specialtecken",
    "required_terms_title": "Vänligen godkänn villkoren",
    "required_note_title": "Obs krävs",
    "required_birthday_title": "Födelsedag krävs",
    "no_access_title": "Du har ingen tillgång just nu. Kontakta din administratör",
    "menu_online_open_txt": "Öppna",
    "loyalty_program_description": "😊 Hitta ditt idealiska lojalitetsprogram och anpassa det för att matcha ditt varumärke!",
    "lp_list_title": "Lista över lojalitetshålkort",
    "lp_n_title": "Lojalitetshålkortsnamn",
    "enter_details_title": "Ange detaljer",
    "stamp_allowed_title": "Stämpel tillåten",
    "stamp_items_title": "Stämpelföremål",
    "lpn_req_title": "Lojalitetshålkortsnamn krävs",
    "valid_lpn_title": "Ange ett giltigt lojalitetshålkortsnamn",
    "brand_color_error": "Märkesfärgen måste vara exakt 7 tecken",
    "select_dark_brand_color_title": "Vänligen välj giltig märkesfärg",
    "your_brand_color_title": "Din varumärkesfärg",
    "upload_logo_title": "Ladda upp logotyp",
    "locations_title": "Platser",
    "add_lp_title": "Skapa program",
    "location_error": "Välj platser",
    "pvs_title": "Hur många frimärken kan en kund tjäna per besök?",
    "max_apd_title": "Vad är det maximala antalet stämplar en kund kan tjäna på en dag?",
    "logo_error": "Logotyp krävs",
    "expiry_title": "Ställ in utgångsdatum för belöningar",
    "add_more_rewards_title": "+ Lägg till fler belöningar",
    "prev_step_1_title": "Visa QR för att tjäna poäng och njuta av dina belöningar.",
    "delete_lp_title": "Detta lojalitetshålkort kommer att raderas permanent.",
    "delete_user_record_title": "Denna användarpost kommer att raderas permanent.",
    "redeem_title": "Återlösa",
    "lp_button_title": "Gå med i lojalitetshålkort",
    "lp_download_button_title": "Ladda ner kort",
    "step_0_title": "Gå med nu, läckra belöningar väntar för lojala matälskare som dig.",
    "step_1_title": "Du har gått med",
    "congrats_step_title": "Nu är du berättigad till gratis pizza",
    "reward_title": "Få belöningar vid varje besök",
    "visit_title": "Besök & Lås upp",
    "rewards_title": "Belöningar",
    "dummy_address": "3730 Frankfort Ave, Louisville, Kentucky, USA",
    "loyalty_qr_default_text": "Skanna QR-koden, gå med i vårt lojalitetshålkort och njut av belöningar",
    "lp_earn_stamp_txt": "Kunder tjänar stämplar vid varje besök.",
    "lp_get_rewards_txt": "Få belöningar vid varje besök",
    "lp_1_time_visit_txt": "1 besök får du",
    "lp_uppercase_stamp_text": "Stämpel",
    "lp_lowercase_stamp_text": "stämpel",
    "lp_txt": "Lojalitet",
    "lp_per_visit_stamp_validation_msg": "Stämpeln per besök måste vara mindre än den högsta tillåtna stämpeln per dag",
    "lp_per_max_stamp_validation_msg": "Maximalt tillåtna stämpel per dag måste vara större än stämpel per besök",
    "lp_valid_value_msg": "Ange ett giltigt värde",
    "lp_required_field_msg": "Detta fält är obligatoriskt",
    "lp_stamp_required_msg": "Stämpel krävs",
    "lp_valid_stamp_msg": "Ange ett giltigt stämpelvärde",
    "lp_reward_name_required_msg": "Belöningsnamn krävs",
    "lp_unique_stamp_msg": "Stämpelvärdet ska vara unikt",
    "lp_benefits": "Ett lojalitetsprogram ökar återkommande besök och intäkter samtidigt som det ger kundinsikter för personlig marknadsföring",
    "lp_how_customer_txt": "Så här är dina kunder",
    "lp_punch_card_txt": "lojalitetshålkort",
    "lp_look_txt": "ska titta! 😍",
    "lp_desc": "Kunder kommer att tjäna stämplar för varje besök, och när de samlat in tillräckligt kan de lösa in speciella belöningar.",
    "lp_edit": "Redigera",
    "lp_looks_good_txt": "Ser bra ut",
    "lp_customer_view_instruction": "Detta är vad din kund kommer att se. Ladda upp din logotyp och ställ in färgtema",
    "lp_customer_earn_stamps": "Tillåt kunder att tjäna stämplar vid varje besök och hantera sina belöningar",
    "lp_stamp_rewards_setup": "Ställ in stämpel för att tjäna belöningar, låt kunder få belöningar per stämpel",
    "lp_loyalty_program_setup": "Ge ditt lojalitetsprogram namn och ange din plats för att ha detta program",
    "lp_prev_step1": "Visa QR för att tjäna poäng och njuta av dina belöningar.",
    "lp_visit_1_time_txt": "Besök 1 gång och få",
    "lp_get_free_txt": "Få gratis",
    "lp_visit_allow_txt": "Visa och lås upp",
    "lp_rewards": "Belöningar",
    "repsT": "Få fler recensioner",
    "offSup": "Kontorsmaterial",
    "repsNxtV": "Vid ditt nästa besök",
    "addRepT": "Lägg till Få fler recensioner",
    "repEntrCpnTxt": "Ange kupongtext",
    "repEntrCpnTxtBtn": "Ange kupongknappstext",
    "repMngNxtV": "på ditt nästa besök?",
    "repMngWldL": "Vill du få",
    "discount": "Rabatt",
    "repDisTyp": "Rabatttyp",
    "repNameT": "Namnge ditt rykte",
    "crtRepT": "Skapa rykte",
    "feedBackT": "Feed-back",
    "confLocT": "Bekräfta plats",
    "businLocT": "Affärsläge",
    "addInfoT": "Ytterligare information",
    "repNReqT": "Namn på Få fler recensioner krävs",
    "validRepNT": "Ange ett giltigt ryktenamn",
    "groupT": "Grupp",
    "groupLDescTitleT": "Skjut automatiskt in kontakter i en grupp",
    "groupLDescT": "Alla kunder som fyller i formuläret kommer att läggas till i den valda gruppen.",
    "que2": "Var din mat god?",
    "que3": "Var vår service vänlig?",
    "que4": "Var vår service snabb?",
    "addAdditionalT": "Vill du lägga till ytterligare information?",
    "phNoMandT": "Telefonnummer är obligatoriskt!",
    "qrGenT": "QR genererad",
    "repFDT": "Din input kommer att hjälpa vårt team att hjälpa dig bättre!",
    "letdiT": "Låt oss göra det",
    "tak15secT": "Tar 15 sekunder",
    "defQue": "Vill du få bra erbjudanden och kampanjer då och då?",
    "defNQue": "Vill du att någon ska svara dig?",
    "rateOnGoogleDesc": "Skulle du vara snäll nog att betygsätta oss på google?",
    "mayBeNT": "Kanske nästa gång",
    "yesSureT": "Ja, visst",
    "valid_name": "Ange ett giltigt namn",
    "nameReq": "Namn krävs",
    "backToMainT": "Tillbaka till huvudsidan",
    "attachCopT": "Vill du stimulera din kund?",
    "thankYouT": "Tack",
    "lftyT": "Vi ser fram emot ditt nästa besök!",
    "repPreT": "Reputation Preview",
    "offer_exclusive_discounts": "Erbjud exklusiva rabatter och kampanjer för att locka dina kunder.",
    "couponT": "Kupong",
    "locStepDesc": "Bekräfta din företagsplats",
    "createRepStepDesc": "Skapa ett anpassat rykteformulär efter grupper",
    "feedbackStepDesc": "Frågeuppsättning för kundfeedback",
    "addInfoStepDesc": "Kundfält och incitamentinställningar",
    "FedbkkWDT": "Feedback med data",
    "repAnlytT": "Reputation Analytics",
    "todayT": "I dag",
    "rep_last_7_days_t": "Senaste 7 dagarna",
    "last30DaysT": "Senaste 30 dagarna",
    "lastMT": "Förra månaden",
    "custRangeT": "Anpassat intervall",
    "queWiseAnltT": "Fråga Wise Analytics",
    "atL1QueErrT": "Välj minst en fråga",
    "deleteRepTitle": "Detta rykte kommer att raderas permanent.",
    "incvCustT": "Ja, stimulera min kund",
    "collAddInfoT": "Ja, jag skulle vilja samla in ytterligare information",
    "totRewT": "Total recension",
    "totPosRewT": "Totalt positiv recension",
    "negFeedT": "Negativ feedback",
    "posFeedT": "Positiv feedback",
    "fineT": "Finstilt",
    "enterFineT": "Ange finstilt text",
    "setThemeT": "Ställ in temafärg",
    "que1T": "Fråga 1",
    "que2T": "Fråga 2",
    "que3T": "Fråga 3",
    "que4T": "Fråga 4",
    "entMailT": "Ange din e-postadress",
    "reputationQRDefTxt": "Skanna QR-koden och dela din värdefulla feedback med oss ​​angående din upplevelse.",
    "selUser": "Välj Användare",
    "userT": "Användare",
    "subUReq": "Välj användare",
    "updateLoc": "Uppdatera plats",
    "leadGenT": "Generering av leads",
    "displayT": "Visa",
    "ratingT": "Gradering",
    "rep_dashboard_negative_feedback": "Negativ feedback 0 %",
    "rep_dashboard_position_feedback": "Positiv feedback 0%",
    "rep_dashboard_total_Feedback_txt": "Total feedback",
    "rep_dashboard_anony_Feedback": "Anonym feedback",
    "rep_dashboard_Feeedback_Data_txt": "Feedback med data",
    "rep_dashboard_review_txt": "Få fler recensioner",
    "rep_dashboard_total_Review_txt": "Total recension",
    "rep_dashboard_total_positive_Review_txt": "Totalt positiv recension",
    "rep_dashboard_negative_feedback_txt": "Negativ feedback",
    "rep_dashboard_connection_txt": "Förbindelse",
    "rep_dashboard_question_wise_analytics_txt": "Fråga Wise Analytics",
    "rep_dashboard_date_label": "Välj Datum",
    "rep_dashboard_custom_range_txt": "Anpassat intervall",
    "rep_tlt_min": "REP",
    "emojis": "Emojis",
    "media_text": "Lägg till media",
    "custom_field_text": "Infoga anpassade fält",
    "shortlink_text": "Lägg till kortlänk",
    "use_template_text": "Använd mall",
    "banner_must_have": "Måste ha",
    "res_seamless": "Sömlös",
    "res_table_reservation": "Bordsbokning",
    "res_system": "System",
    "res_text": "Bokningar",
    "md_easily": "Lätt",
    "md_manage_customers": "Hantera kunder",
    "md_visits_interactions": "besök & interaktioner",
    "md_desc": "Gör middagen enklare för dina kunder med bordsbokningar i realtid. Hantera bokningar utan ansträngning, minska väntetiderna och säkerställa en smidig upplevelse för både personal och gäster. Håll din restaurang fullbokad samtidigt som du behåller förstklassig service och tillfredsställelse.",
    "engage_convert": "Engagera och konvertera SMS",
    "coupon_campaign": "och kupongkampanj",
    "sms_read_rate": "SMS läses 98 % av tiden",
    "real_time_promo": "Leverera kampanjer i realtid för omedelbar handling",
    "affordable_reach": "Prisvärt sätt att nå kunder med solid ROI",
    "stay_in_touch": "Håll kontakten med din kund hela tiden",
    "bc_engage_customers": "Engagera kunder Konvertera SMS",
    "bc_coupon_campaign": "och kupongkampanj",
    "bc_sms_read_rate": "SMS läses 98 % av tiden",
    "bc_real_time_promo": "Leverera kampanjer i realtid för omedelbar handling",
    "bc_affordable_reach": "Prisvärt sätt att nå kunder med solid ROI",
    "bc_stay_in_touch": "Håll kontakten med din kund hela tiden",
    "ar_automate_engagement": "Automatisera ditt klientengagemang",
    "ar_with": "med",
    "ar_with_auto_responders": "Autosvarare",
    "ar_personalized_messages": "Skicka automatiskt personliga meddelanden för födelsedagar, välkomna nya kunder och återansluta med tidigare kunder. Spara tid samtidigt som du håller varje interaktion meningsfull och läglig.",
    "sq_custom_forms_tools": "Anpassade formulär och verktyg - Förenkla",
    "sq_custom_forms_ci": "Kundinteraktioner",
    "sq_smart_qr_desc": "Skapa anpassade formulär, samla in e-signaturer och använd smarta QR-koder för att effektivisera dina affärsprocesser. Skräddarsy varje verktyg för att möta dina kunders behov och förbättra användarupplevelsen.",
    "gnc_get_new_cus": "Skaffa nya kunder",
    "gnc_get_customers": "från sociala medier – snabbt och enkelt!",
    "gnc_social_media_power": "Använd kraften i sociala plattformar för att locka nya kunder och driva försäljning direkt till ditt företag. Omdirigera leveransbeställningar från tredje part direkt till din butik eller onlinebeställningsplattform för maximal kontroll och intäkter.",
    "wifi_connect_guests": "Anslut gäster med",
    "wifi_smart": "Smart Wi-Fi",
    "wifi_connect_grow": "& Öka dina insikter",
    "wifi_smart_wifi_desc": "Tillhandahåll en problemfri Wi-Fi-anslutning till dina kunder via en välkänd startskärm samtidigt som du samlar in värdefull kunddata för att förbättra dina marknadsföringsinsatser och engagemang.",
    "wifi_create_splash_page": "Skapa Gäst Wifi Splash-sida",
    "instant_menu_create": "Skapa din",
    "instant_menu_text": "Omedelbar meny",
    "instant_menu_wm": "inom några minuter",
    "instant_menu_desc": "Förbättrar kundupplevelsen genom att möjliggöra realtidsuppdateringar, enkel anpassning och förbättrat engagemang, vilket gör det lättare att visa upp dina erbjudanden och hålla ditt innehåll fräscht.",
    "instant_menu_create_digital_menu": "Skapa en digital meny",
    "loyalty_program_rewards": "Tjäna belöningar",
    "loyalty_program_on_visits": "vid varje besök",
    "loyalty_program_create": "Skapa lojalitetsprogram",
    "reputation_create": "Skapa rykte",
    "reputation_boost": "Boosta din",
    "reputation_text": "Rykte",
    "reputation_reviews": "med fler recensioner!",
    "reputation_desc": "Samla in värdefull feedback från dina kunder för att förbättra din service och ditt rykte. Dela din erfarenhet och hjälp oss att växa – din recension är viktig!",
    "dashboard": "Instrumentbräda",
    "blast_campaign": "Blast-kampanj",
    "blast_campaigns": "Blast-kampanjer",
    "blast_campaign_sub_title": "Starta sprängkampanjer för att nå alla dina kunder med några få klick",
    "auto_responders": "Autosvar",
    "auto_responder": "Autosvarare",
    "auto_responder_sub_title": "Lättanvända evenemangsbaserade kampanjer för dina kunder",
    "smart_qr_form": "Smart QR \/ Form",
    "get_new_customer": "Skaffa ny kund",
    "marketing": "Marknadsföring",
    "clients": "Kunder",
    "group_data": "Grupper data",
    "clients_data": "Kunddata",
    "account": "Konto",
    "user_acc_info": "Användarkontoinformation",
    "current_plan": "Nuvarande plan",
    "payments": "Betalningar",
    "location": "Plats",
    "location_list": "Platslista",
    "users": "Användare",
    "users_sub_title": "Lista över alla användare och deras uppgifter",
    "online_profile": "Online-profil",
    "change_password": "Ändra lösenord",
    "menu": "Meny",
    "reseller_dashboard": "Återförsäljares instrumentpanel",
    "companies": "Företag",
    "plans_bought": "Planer köpta",
    "agreement_t_c": "Avtal T&C",
    "wifi_dashboard": "Wifi instrumentbräda",
    "hot_spot": "Hotspot",
    "splash_pages": "Splash Pages",
    "get_more_reviews": "Få fler recensioner",
    "office_supplies": "Kontorsmaterial",
    "reservation": "Bokning",
    "floor_table": "Golv & Bord",
    "guest_smart_wifi": "Gäst Smart WiFi",
    "digital_signage": "Digital skyltning",
    "schedule_down": "Schemaläggning",
    "business_settings": "Företagsinställningar",
    "sub_user_login": "Subanvändarinloggning",
    "help_center": "Hjälpcenter",
    "switch_to_hub": "Byt till Hub",
    "meeting_records": "Mötesrekord",
    "logout": "Utloggning",
    "no_access_text": "Du har ingen åtkomst just nu. kontakta din administratör",
    "menu_item": "MENYPOST",
    "menu_capital": "MENY",
    "good_day": "God dag",
    "no_permission_text": "Du har inte behörighet att utföra den här åtgärden!!",
    "enter_valid_pin": "Ange giltig pinkod",
    "add_reservation": "Lägg till reservation",
    "edit_reservation": "Redigera reservation",
    "per_req": "Antal personer krävs",
    "req_block_time": "Blocktid krävs",
    "pending_status": "I avvaktan på",
    "booked_status": "Bokad",
    "completed_status": "Avslutad",
    "cancelled_status": "Avbruten",
    "reservation_slot": "Bokningsplats",
    "block_time": "Blocktid",
    "slot_time": "Tid för bokning:  ",
    "template_name_req": "Mallnamn krävs",
    "template_type_req": "Malltyp krävs",
    "sms_template_req": "SMS-mall krävs",
    "group_req": "Grupp krävs",
    "valid_date_time": "Ange giltigt datum och tid",
    "valid_group_name": "Ange ett giltigt gruppnamn",
    "reservation_mark_completed": "Denna reservation kommer att markeras som avslutad.",
    "customer_name": "Kundens namn",
    "date_time": "Datum Tid",
    "table_no": "Tabell nr",
    "floor": "Golv",
    "customer_name_placeholder": "kundnamn",
    "date_time_placeholder": "datum_tid",
    "table_no_placeholder": "table_no",
    "floor_placeholder": "golv",
    "select_floor": "Välj Golv",
    "select_table": "Välj Tabell",
    "customer_name_macro": "%% kundnamn %%",
    "date_time_macro": "%% date_time %%",
    "table_no_macro": "%% table_no %%",
    "floor_macro": "%% golv %%",
    "template_name": "Mallnamn",
    "login_with_4_digit_pin": "Logga in med din 4-siffriga PIN-kod",
    "login_now_to_access_latest_insights": "Logga in nu för att komma åt de senaste insignena",
    "mkt_BS_Title": "Spränga SMS",
    "step_1": "Anpassa meddelande",
    "step_2": "Välj Målgrupp",
    "step_3": "Schemalägg meddelande",
    "step_4": "Skicka ett meddelande",
    "step_5": "Hantera meddelande",
    "bs_tab_title": "Blast-kampanjsammanfattning",
    "default_message": "Hej %% förnamn %%, Specialrabatt på brunch! Ät med oss ​​idag och få exklusiv 10% rabatt på din faktura!",
    "bs_Title": "Välj en kampanjtyp",
    "sms_title": "SMS\/MMS",
    "reach_out_to_customer": "Nå ut till kunder genom text- och multimediameddelanden.",
    "wp_text": "WhatsApp",
    "connect_with_wp": "Anslut till dina kunder med WhatsApp 💬",
    "send_discount_offers": "Skicka rabatterbjudanden via SMS 📲",
    "send_coupons_via_sms": "Skicka kuponger via SMS till dina kunder 🎟️",
    "env_Cap": "Erbjud exklusiva rabatter och kampanjer för att locka dina kunder",
    "cmt_Caption": "Nå ut till kunder genom text- och multimediameddelanden",
    "wp_caption": "Anslut med kunder med WhatsApp för sömlös och direkt kommunikation",
    "msg_Prev_Title": "Förhandsgranskning av meddelande",
    "cou_Prev_Title": "Kupongförhandsgranskning",
    "blast_SMS": "SMS\/MMS-kampanj",
    "bs_P_Msg": "I kö",
    "bs_S_Msg_C": "Misslyckades",
    "bs_F_Msg_Count": "Levereras",
    "previous": "Tidigare",
    "next_ST": "Nästa",
    "next_S": "Nästa steg",
    "req_Temp_Name_Title": "Mallnamn krävs",
    "req_Template_Tx_Title": "Malltext krävs",
    "add_Temp_Title": "Lägg till mall",
    "link_Desc": "Lägg till en valfri länk så att kunder kan boka med dig via Facebook, Google eller din egen webbplats.",
    "audience_Title": "Publik",
    "client_G1_Title": "Nya kunder",
    "client_G2_Title": "Senaste kunder",
    "client_G3_Title": "Lojala kunder",
    "client_G4_Title": "Förfallna kunder",
    "client_G5_Title": "Klienter efter anslutningsdatum",
    "on_Which_Day": "Vilken dag",
    "on_Day": "På dagen",
    "immediate_Desc": "Ditt meddelande kommer att skickas omedelbart, klicka på Nästa för att börja.",
    "never_Desc": "Den här kampanjen är schemalagd att köras för alltid tills du avslutar den manuellt.",
    "end_Desc": "Kampanjen avslutas det datum du väljer här.",
    "success_Message": "Kampanjen har sparats!",
    "send_Success_Message": "Kampanjen har skickats!",
    "msg_Name_Field_Title": "Kampanjens titel",
    "valid_campaign_title": "Ange giltig kampanjtitel",
    "msg_Type_Title": "Meddelandetyp",
    "sms_Desc": "1 kredit per SMS",
    "mms_Desc": "3 poäng per MMS",
    "client_GTO_1": "Dag",
    "client_GTO_2": "Timmar",
    "minutes": "Minuter",
    "client_GTO_4": "Vecka",
    "time_Option_1": "Dag",
    "time_Option_2": "Timmar",
    "c_Grp_Day_1": "90 dagar",
    "c_Grp_Day_2": "60 dagar",
    "c_Grp_Day_3": "30 dagar",
    "first": "Första",
    "second_T": "Andra",
    "third_T": "Tredje",
    "fourth_T": "Fjärde",
    "fifth_T": "Femte",
    "delete_BS_T": "Denna kampanj kommer att raderas permanent.",
    "delete_MBS_T": "Dessa kampanjer kommer att raderas permanent.",
    "cFT_1": "Telefon",
    "cFT_2": "Kontakta Förnamn",
    "cFT_3": "Kontaktens efternamn",
    "cFT_4": "Kontakt E-post",
    "cFT_5": "Notera",
    "cFT_6": "Födelsedag",
    "cFT_7": "Företagsnamn",
    "cF_Meta_Tag_1": "%% telefon %%",
    "cF_Meta_Tag_2": "%% förnamn %%",
    "cF_Meta_Tag_3": "%% efternamn %%",
    "cF_Meta_Tag_4": "%% email %%",
    "cF_Meta_Tag_5": "%% notering %%",
    "cF_Meta_Tag_6": "%% födelsedag %%",
    "cF_Meta_Tag_7": "%% företagsnamn %%",
    "cF_Name_1": "telefon",
    "cF_Name_2": "förnamn",
    "cF_Name_3": "efternamn",
    "cF_Name_4": "e-post",
    "cF_Name_5": "notera",
    "cF_Name_6": "födelsedag",
    "cF_Name_7": "företagsnamn",
    "all_clients": "Alla klienter",
    "aud_Type_2": "Klientgrupper",
    "aud_Type_3": "Kundgrupper",
    "aud_Type_T1": "Alla kunder",
    "aud_Type_T2": "Kundgrupper",
    "aud_Type_T3": "Kundgrupper",
    "new_clients": "Nya kunder",
    "client_G2": "Senaste kunder",
    "client_G3": "Lojala kunder",
    "client_G4": "Förfallna kunder",
    "client_G5": "Klienter efter anslutningsdatum",
    "never_text": "Aldrig",
    "ending_on": "Slutar på",
    "send_T2": "Schemalagt",
    "send_Type_3": "Återkommande",
    "daily": "Dagligen",
    "weekly_T": "Varje vecka",
    "monthly_T": "Månatlig",
    "yearly_T": "Årlig",
    "each": "Varje",
    "on_the": "På The",
    "monday": "måndag",
    "exclusive_offer": "Exklusivt erbjudande för dig",
    "redeem_now": "Lös in nu",
    "redeem_with_cashier": "Lös in med kassan",
    "lg_Day_2": "tisdag",
    "lg_Day_3": "onsdag",
    "lg_Day_4": "torsdag",
    "lg_Day_5": "fredag",
    "lg_Day_6": "lördag",
    "lg_Day_7": "söndag",
    "msg_Pre_T": "Dominos",
    "cA_Edit_T1": "Alla",
    "cA_Edit_T2": "Ingår",
    "cA_Edit_T3": "Utesluten",
    "SMS": "SMS",
    "MMS": "MMS",
    "usd_T": "USD",
    "cad_T": "CAD",
    "msg": "Meddelande",
    "which_Day": "Vilka dagar",
    "end_Date_Tx": "Slutdatum",
    "sDate_Err": "Startdatum måste vara större än eller lika med idag",
    "eDate_Err": "Slutdatum måste vara senare än startdatum",
    "start_Date_Req": "Startdatum krävs",
    "end_Date_Req": "Slutdatum krävs",
    "time_req": "Tid krävs",
    "client_GT1": "Kunder prenumererade i den senaste",
    "client_GT2": "Klienter med anslutning i sista",
    "client_GT3": "Kunder med minst",
    "client_GT4": "med den sista",
    "client_GT5": "som inte kom tillbaka i det sista",
    "dummy_Phone_No": "+91987-654-3210",
    "test_T": "Testa",
    "dummy_Birth_Date": "2001-01-01",
    "image_Req": "Media eller URL krävs",
    "time_Title": "Tid",
    "start_date": "Startdatum",
    "end_date": "Slutdatum",
    "auto_Send_T": "Skicka automatiskt till kunder",
    "add_Media": "Lägg till media",
    "in_Title": "I",
    "c_Temp_T": "Kampanjmall",
    "temp_NT": "Mallnamn",
    "type_T": "Typ",
    "select_template_type": "Välj malltyp",
    "sel_Temp_T": "Välj Malltyp",
    "sms_temp_t": "SMS-mall",
    "temp_T": "Malltext",
    "default_Msg": "Hej %% förnamn %%, Specialrabatt på brunch! Ät middag hos oss idag och få exklusiva 10 % rabatt på din faktura!",
    "refill_T": "Påfyllning",
    "avl_Credit": "Tillgängliga krediter",
    "req_Credit": "Totala poäng som krävs",
    "rem_Credit": "Återstående kredit",
    "refill_Credit": "Krediter som ska fyllas på",
    "clients_Req": "Välj minst en klient",
    "subclient_Req": "Utvalda kunder är inte prenumeranter.",
    "refill_Desc": "Vänligen fyll på de nödvändiga krediterna",
    "url_Regex": "^((http|https):\/\/).....+$",
    "sd_Invalid": "Startdatumet är ogiltigt",
    "ed_Invalid": "Slutdatumet är ogiltigt",
    "img_Url_Invalid": "Ogiltig bildadress",
    "time_Invalid": "Tiden är ogiltig",
    "time_And_Date_Invalid": "Ange ett giltigt datum och tid",
    "time_Invalid_Bfr": "Välj en tid minst 5 minuter senare från den aktuella tiden",
    "sod_Req": "Skicka på dag krävs",
    "add_Card": "Lägg till ett nytt kort",
    "sm_D_Rep_Title": "SMS\/MMS leveransrapport",
    "send_Now_T": "Skicka nu",
    "schd_Now_T": "Schemalägg SMS",
    "test_SMS_T": "Skicka test-SMS",
    "save_AD_T": "Spara som utkast",
    "back_TE_T": "Tillbaka till Redigera",
    "reset_tex": "Återställa",
    "update_tex": "Uppdatera",
    "dum_msg1": "För att visa kundnamn, använd %% customer_name %% i mallen",
    "dum_msg2": "För att visa bokningstid, använd %% date_time %% i mallen",
    "dum_msg3": "För att visa tabellnummer, använd %% table_no %% i mallen",
    "embedded_link": "Inbäddad länk",
    "img_title": "Bild",
    "img_input_text": "Text:",
    "img_join_text": " att gå med i vår lista",
    "img_copy_text": "Kopiera",
    "img_not_found": "Den genererade bilden hittades inte!!",
    "or_text": "ELLER",
    "web_signup_image_content": "Genom att skicka in det här formuläret och registrera dig via sms samtycker du till att ta emot marknadsföringstextmeddelanden (som kampanjkoder och påminnelser). Meddelande- och datahastigheter kan tillkomma. Meddelandefrekvensen varierar. Du kan avsluta prenumerationen när som helst genom att svara STOP",
    "img_download_success": "Bilden har laddats ned",
    "embedded_link_copied": "Den inbäddade länken har kopierats",
    "media_url_required": "Media eller webbadress krävs",
    "invalid_image_url": "Ogiltig bildadress",
    "invalid_file": "Filen är ogiltig",
    "image_error_1mb": "Vänligen infoga en bild som är mindre än 1 MB",
    "image_error_2_5mb": "Vänligen infoga en bild som är mindre än 2,5 MB",
    "image_error_3mb": "Vänligen infoga en bild som är mindre än 3MB",
    "change_title": "Ändra",
    "drop_file_text": "Släpp filen här eller klicka för att ladda upp",
    "apply_text": "Tillämpas",
    "search_here": "Sök här",
    "update_status": "Uppdatera status",
    "reservation_deleted_permanently": "Denna reservation kommer att raderas permanent.",
    "table_text": "Tabeller",
    "add_table_button": "Lägg till ny tabell",
    "add_table_text": "Lägg till tabell",
    "edit_table_text": "Redigera tabell",
    "table_delete_text": "Denna tabell kommer att raderas permanent.",
    "multiple_table_delete_text": "Dessa tabeller kommer att raderas permanent.",
    "table_error_name": "Vänligen ange tabellnamnet",
    "table_error_invalid_name": "Vänligen ange ett giltigt tabellnamn",
    "table_error_floor": "Vänligen välj våningen",
    "table_error_select": "Vänligen välj tabellen",
    "table_capacity_text": "Tabellkapacitet",
    "capacity_text": "Kapacitet",
    "table_occasion_text": "Hitta ditt bord för alla tillfällen",
    "table_name_text": "Tabellnamn",
    "table_capacity_error": "Bordskapacitet krävs",
    "cancel_text": "Avboka",
    "submit_text": "Överlämna",
    "select_valid_date": "Välj ett giltigt datum",
    "select_valid_time": "Välj giltig tid",
    "valid_contact_number": "Ange ett giltigt kontaktnummer",
    "date_req": "Datum krävs",
    "date_error_later_than_today": "Datum måste vara senare än eller lika med dagens datum",
    "time_error_later_than_5_minutes": "Välj tid minst 5 minuter senare från aktuell tid",
    "number_person_req": "Antal personer krävs",
    "contact_no_req": "Kontaktnummer krävs",
    "contact_no": "Kontaktnummer",
    "select_time": "Välj Tid",
    "for_how_many_person": "För hur många personer?",
    "f_name": "Förnamn",
    "l_name": "Efternamn",
    "business_name": "Företagsnamn",
    "business_no": "Företagsnummer",
    "no_for_sign_in": "Detta nummer används för inloggning",
    "business_email": "Företags-e-post",
    "notes_tex": "Anteckningar",
    "floor_deleted_permanently": "Den här våningen kommer att raderas permanent.",
    "add_floor": "Lägg till golv",
    "edit_floor": "Redigera våning",
    "name_text": "Namn",
    "mobile_no": "Mobilnr.",
    "person_text": "Person",
    "date_and_time": "Datum & tid",
    "actions_text": "Åtgärder",
    "extra_text": "Extra",
    "floor_name_req": "Våningsnamn krävs",
    "floor_name": "Våningens namn",
    "status_text": "Status",
    "category_status_req": "Kategoristatus krävs",
    "table_deleted_permanently": "Denna tabell kommer att raderas permanent.",
    "tables_deleted_permanently": "Dessa tabeller kommer att raderas permanent.",
    "back_to_home": "Tillbaka Till Hem",
    "link_copied_text": "Länken har kopierats till urklipp",
    "cust_dash_head_to_title": "till",
    "cust_dash_head_location_title": "Plats",
    "select_location_title": "Välj Plats",
    "all_locations_label": "alla_platser",
    "rep_customer_feedback_analytics": "😊 Spåra din kundfeedback med realtidsanalys",
    "rep_customer_txt": "Kunder",
    "rep_delete_title": "Detta rykte kommer att raderas permanent.",
    "rep_qr_def_txt": "Skanna QR-koden och dela din värdefulla feedback med oss ​​angående din upplevelse.",
    "delete_title": "Radera",
    "user_list_title": "Lista över kunder",
    "os_nfc_txt": "NFC-kort",
    "os_sign_holder_txt": "Stativ för skylthållare",
    "os_store_Sign_holder_txt": "Butiksskylthållare",
    "StaT": "Statistik",
    "AllgT": "Alla grupper",
    "FavT": "Favorit",
    "MostActT": "Mest aktiva",
    "grT": "Gruppnamn",
    "keywT": "Nyckelord",
    "exSubT": "Meddelande till befintlig prenumerant",
    "ArchT": "Arkiverad",
    "gNotiSms": "Meddela mig om varje ny kontakt via SMS",
    "gNotiEmail": "Meddela mig om varje ny kontakt via e-post",
    "reqGName": "Gruppnamn krävs",
    "validGN": "Ange ett giltigt gruppnamn",
    "valid_phone_no": "Ange ett giltigt telefonnummer",
    "phone_no_req": "Telefonnummer krävs",
    "required_message_text": "Meddelande krävs",
    "required_discount_text": "Rabatt krävs",
    "less_than_discount": "Rabattbeloppet måste vara mindre än 5000",
    "discount_percentage_invalid": "Rabattprocenten måste vara mindre än eller lika med 100",
    "discount_type_req": "Rabatttyp krävs",
    "discount_text_req": "Kupongtext krävs",
    "reqContactNo": "Kontaktnummer krävs",
    "reqMsgNT": "Kampanjens titel krävs",
    "reqLinkT": "Länk krävs",
    "delMGT": "Dessa grupper kommer att raderas permanent.",
    "defMemMsg": "Skriv ditt meddelande här",
    "add_cust_to_grp_title": "Lägg till klient till grupp",
    "group_title": "Grupper",
    "group_create_title": "Skapa grupp",
    "group_name_txt": "Gruppnamn",
    "group_table_keyword_title": "Nyckelord",
    "actions_title": "Åtgärder",
    "clients_title": "Kunder",
    "send_title": "Skicka",
    "qr_title": "QR",
    "delete_group_txt": "Denna grupp kommer att raderas permanent.",
    "delete_groups_txt": "Dessa grupper kommer att raderas permanent.",
    "delete_client_title": "Denna klient kommer att raderas permanent.",
    "delete_clients_title": "Denna klient kommer att raderas permanent.",
    "delete_multiple_title": "Flera radera",
    "wel_sms_mms": "Skicka välkomst-SMS\/MMS?",
    "key_words_title": "Nyckelord",
    "srch_plch_txt": "Sök här",
    "req_location_id_title": "Plats krävs",
    "create_text": "Skapa",
    "view_text": "Se",
    "immediately": "Omedelbart",
    "business_name_req": "Företagsnamn krävs",
    "business_no_req": "Företagsnummer krävs",
    "valid_business_name": "Ange ett giltigt företagsnamn",
    "valid_business_no": "Ange ett giltigt företagsnummer",
    "address_req": "Adress krävs",
    "valid_address": "Vänligen välj giltig adress",
    "time_zone_req": "Tidszon krävs",
    "image_req": "Vänligen infoga en bild som är mindre än 2,5 MB",
    "valid_file": "Filen är ogiltig.",
    "logo": "Logotyp",
    "time_zone": "Tidszon",
    "save": "Spara",
    "account_type_req": "Kontotyp krävs",
    "gst_no_req": "GST-nummer krävs",
    "gst_no_valid": "Ange ett giltigt GST-nummer",
    "set_up_payments": "Ställ in betalningar",
    "billing_details": "Ange dina faktureringsuppgifter",
    "billing_details_desc": "Dina faktureringsuppgifter kommer att visas på din månadsfaktura från menuonline.",
    "account_type": "Kontotyp",
    "select_account_type": "Välj kontotyp",
    "gst_no": "GST-nummer",
    "transaction_details": "Transaktionsdetaljer",
    "payment_method": "Betalningsmetod",
    "billing_period": "Faktureringsperiod",
    "paid_by": "Betalas av",
    "download": "Ladda ner",
    "pay_now": "Betala nu",
    "pull_down_refresh": "Dra ned för att uppdatera",
    "release_refresh": "Släpp för att uppdatera",
    "billing_details_text": "Faktureringsinformation",
    "payment_methods": "Betalningsmetoder",
    "invoice": "Faktura",
    "invoice_to": "Faktura till:",
    "issue_date": "Utgivningsdatum",
    "due_date": "Förfallodatum",
    "amount_due": "Förfallna belopp",
    "charges": "Kostnader",
    "sub_total_capitalize": "Subtotal",
    "hst": "HST",
    "grand_total": "Totalsumma",
    "invoice_generated_on": "Faktura genererad den",
    "contact_us": "Kontakta oss",
    "invoice_issue": "Om du har gått igenom din faktura och fortfarande har frågor",
    "call": "Samtal",
    "send_sms": "Skicka SMS",
    "payment_success": "Betalningen är framgångsrik",
    "edit_credit_card": "Redigera kreditkort",
    "add_credit_card": "Lägg till kreditkort",
    "modify_card_info": "Ändra din kortinformation",
    "enter_card_info": "Ange din kortinformation",
    "account_no_req": "Kontonummer krävs",
    "card_name_req": "Namn på kortet krävs",
    "expiry_date_req": "Utgångsdatum krävs",
    "valid_expiry_date": "Ange ett giltigt utgångsdatum",
    "valid_cvv": "Ange giltigt cvv",
    "cvv_req": "CVV krävs",
    "card_no": "Kortnummer",
    "name_of_card": "Namn på kortet",
    "expiry": "Upphörande",
    "mm_text": "MM\/ÅÅ",
    "cvv": "CVV",
    "set_as_default": "Ställ in som standard",
    "add_new_card": "Lägg till nytt kort",
    "all_credit_card": "Dina alla kreditkort",
    "fastest_checkout": "Snabbaste utcheckningen med säker och pålitlig plattform, betrodd av en stor grupp företag",
    "coupon_button_req": "Kupongknappstext krävs",
    "max19_characters_allowed": "Högst 19 tecken är tillåtna",
    "fine_print_text_req": "Finstilt text krävs",
    "clients_req": "Välj minst en klient",
    "client_groups": "Klientgrupper",
    "day_text": "Dag",
    "valid_days": "Dagens värde är för stort ange giltiga dagar",
    "hours_text": "Timmar",
    "enter_valid_hours": "Värdet för timmar är för stort ange giltiga timmar",
    "enter_valid_min": "Minutvärdet är för stort ange giltiga minuter",
    "clients_with_connection_required": "Klienter med anslutning i den sista krävs",
    "connection_required": "Anslutning krävs",
    "connection_value_too_large": "Anslutningsvärdet är för stort ange giltig anslutning",
    "minutes_required": "Minuter krävs",
    "clients_with_at_least_connection_required": "Klienter med minst anslutning krävs",
    "last_minutes_req": "Med de sista minuterna krävs",
    "clients_not_returned_required": "Vem som inte kom tillbaka under de sista minuterna krävs",
    "not_return_in_last_minutes_invalid": "Ej återvändande i sista minuter måste vara mindre än de sista minuterna.",
    "customerGroups": "Kundgrupper",
    "select_customer_group": "Välj kundgrupp",
    "location_required": "Plats krävs",
    "start_date_required": "Startdatum krävs",
    "start_date_invalid": "Startdatum är ogiltigt",
    "start_date_invalid_error": "Startdatum måste vara större då eller lika med idag",
    "recurring": "Återkommande",
    "end_date_required": "Slutdatum krävs",
    "end_date_invalid": "Slutdatumet är ogiltigt",
    "end_date_invalid_error": "Slutdatum måste vara senare än startdatum",
    "time_invalid": "Tiden är ogiltig",
    "monthly_text": "Månatlig",
    "send_day_req": "Skicka på dag krävs",
    "loyal_clients": "Lojala kunder",
    "recent_clients": "Senaste kunder",
    "lapsed_clients": "Förfallna kunder",
    "clients_connection_date": "Klienter efter anslutningsdatum",
    "scheduled_text": "Schemalagt",
    "weekly": "Varje vecka",
    "selected_clients_not_subscriber": "Utvalda kunder är inte prenumeranter.",
    "message_preview_title": "Förhandsgranskning av meddelande",
    "coupon_preview_title": "Kupongförhandsgranskning",
    "form_text": "Form",
    "preview_text": "Förhandsvisning",
    "next_text": "Nästa",
    "send_test_SMS": "Skicka test-SMS",
    "save_draft": "Spara som utkast",
    "back_to_edit": "Tillbaka till Redigera",
    "select_payment_method": "Välj betalningsmetod",
    "schedule_SMS": "Schemalägg SMS",
    "send_now": "Skicka nu",
    "get_more_ratings": "Få fler betyg",
    "overview": "Översikt",
    "reset_campaign": "Återställ kampanj",
    "permissions": "Behörigheter",
    "location_name": "Platsnamn",
    "phone_no": "Telefonnummer",
    "location_email_address": "Plats e-postadress",
    "located_business": "Var ligger ditt företag?",
    "business_logo": "Business logotyp",
    "congratulations": "Grattis",
    "almost_done": "Du är nästan klar",
    "publish": "Publicera",
    "about_your_business": "Om ditt företag",
    "add_your_location": "Lägg till din plats",
    "publish_location": "Publicera plats",
    "location_name_req": "Platsnamn krävs",
    "valid_location_name": "Ange ett giltigt platsnamn",
    "business_logo_req": "Företagslogotyp krävs",
    "please_accept_terms": "Vänligen acceptera villkoren",
    "add_new_location": "Lägg till ny plats",
    "edit_location": "Redigera plats",
    "add_location": "Lägg till plats",
    "existing_msg_subscriber_txt": "Meddelande till befintlig prenumerant",
    "msg_capitalize_txt": "Meddelande",
    "group_noti_sms": "Meddela mig om varje ny kontakt via SMS",
    "group_noti_email": "Meddela mig om varje ny kontakt via e-post",
    "group_member_msg": "Det verkar som att du redan är en del av vår grupp!!",
    "group_mend_msg": "STOPP för att avsluta. HJÄLP för hjälp. SMS och dataavgifter kan tillkomma",
    "Disclaimer_title": "Ansvarsfriskrivningstext: ",
    "group_def_msg": "Vi är online nu! Kolla in vår servicemeny och boka din nästa tid redan idag.",
    "required_msg_txt": "Meddelande krävs",
    "required_Key_txt": "Nyckelord krävs",
    "required_mem_msg": "Prenumerantmeddelande krävs",
    "client_list_title": "Kundlista",
    "add_contact_txt": "Lägg till kontakt",
    "delete_all_clients_txt": "Ta bort alla klienter",
    "delete_all_clients_msg": "Är du säker på att du vill ta bort alla klienter? De går inte att hämta.",
    "delete_all_txt": "Ta bort alla",
    "timeline_title": "Tidslinje",
    "unsubscribe_title": "Avsluta prenumeration",
    "subscribe_title": "Prenumerera",
    "unsubscribe_confirm_msg": "Vill du markera den här kunden som en avprenumerant?",
    "subscribe_confirm_msg": "Vill du markera den här kunden som prenumerant?",
    "no_title": "Inga",
    "yes_title": "Ja",
    "client_name_title": "Kundens namn",
    "source_title": "Källa",
    "contact_file_Req": "Kontaktfil krävs",
    "req_title": "Nödvändig",
    "opt_in_req": "Importerade kontakter måste vara 100 % opt-in",
    "image_invalid_error": "Filen är ogiltig.",
    "import_contacts_msg": "Importera kontakter",
    "csv_upload_title": "CSV-kontaktfil att ladda upp *",
    "csv_file_desc": "Ladda upp endast csv-fil - csv-filkolumner - Förnamn, Efternamn, E-post-id, Mobilnr, Kön, DOB(MM\/DD\/ÅÅÅÅ) - inga mellanslag, bindestreck eller parenteser i mobilnummer",
    "to_download_title": "För att ladda ner demofil klicka här",
    "contains_header_title": "Ja, den här filen innehåller rubriker",
    "opt_in_100_txt": "Ja, dessa importerade kontakter är 100 % opt-in",
    "DisT": "Ansvarsfriskrivningstext: ",
    "gMendMsg": "STOPP för att avsluta. HJÄLP för hjälp. SMS och dataavgifter kan tillkomma",
    "reqKeyT": "Nyckelord krävs",
    "reqMemMsg": "Prenumerantmeddelande krävs",
    "reqMsgT": "Meddelande krävs",
    "gMemberMsg": "Det verkar som att du redan är en del av vår grupp!!",
    "gdefMsg": "Vi är online nu! Kolla in vår servicemeny och boka din nästa tid redan idag.",
    "next_title": "Nästa",
    "male_title": "Manlig",
    "male_val": "manlig",
    "female_title": "Kvinnlig",
    "female_val": "kvinnlig",
    "others_txt": "Andra",
    "others_val": "andra",
    "validBirthdate": "Ange ett giltigt födelsedatum",
    "valid_phone_no_title": "Ange ett giltigt telefonnummer",
    "required_phone_no_title": "Telefonnummer krävs",
    "add_new_client_txt": "Lägg till en ny klient",
    "update_client_txt": "Uppdatera klient",
    "phone_num_text": "Telefonnummer",
    "dob_title": "DOB",
    "select_gender_title": "Välj Kön",
    "timelineTitle": "Tidslinje",
    "confirm_location": "Bekräfta plats",
    "feedback_title": "Feed-back",
    "rep_question_1": "Var verksamheten ren?",
    "rep_que_2": "Var din mat god?",
    "rep_que_3": "Var vår service vänlig?",
    "rep_que_4": "Var vår service snabb?",
    "business_location_req_title": "Företagsplats krävs",
    "valid_location_err_txt": "Vänligen välj giltig adress",
    "rep_management_title": "Få fler recensionerhantering",
    "rep_about_desc": "Rykte hjälper dig att öka Googles betyg genom att be dina kunder om feedback",
    "preview_title": "Förhandsvisning",
    "rep_preview_txt": "Reputation Preview",
    "back_title": "Tillbaka",
    "fine_print_txt": "Lös in med kassan",
    "redeem_me_title": "Lös in mig",
    "rep_nxt_visit_txt": "Vid ditt nästa besök",
    "device_type": "Typ av enhet som används",
    "connection_method": "Anslutningsmetod",
    "peak_days": "Högsta dagar",
    "peak_hours": "Högtrafik",
    "guest_by_day": "Gäst per dag",
    "guest_visit": "Gästbesök",
    "connection": "Förbindelse",
    "connection_duration": "Anslutningens varaktighet",
    "guest_visit_1": "1 gång",
    "guest_visit_2": "2 gånger",
    "guest_visit_3_5": "3-5 gånger",
    "guest_visit_6_10": "6-10 gånger",
    "guest_visit_11_25": "11-25 gånger",
    "guest_visit_26_100": "26-100 gånger",
    "guest_visit_100_plus": "100+ gånger",
    "device_android_total": "Totalt Android",
    "device_android": "Android",
    "device_desktop": "Skrivbord",
    "device_ios": "Ios",
    "device_ios_total": "Totalt Ios",
    "device_desktop_total": "Totalt skrivbord",
    "connection_duration_10": "<=10 min",
    "connection_duration_20": "11-20 min",
    "connection_duration_30": "21-30 min",
    "connection_duration_40": "31-45 min",
    "connection_duration_60": "46-60 min",
    "connection_method_email": "E-post",
    "connection_method_sms": "SMS",
    "connection_method_google": "Google",
    "connection_method_facebook": "Facebook",
    "age_category_1": "<18",
    "age_category_2": "18-24",
    "age_category_3": "25-34",
    "age_category_4": "35-44",
    "age_category_5": "45-54",
    "age_category_6": "55+",
    "all_guest_txt": "Alla gäster",
    "new_Guest_txt": "Ny gäst",
    "connections_txt": "Anslutningar",
    "hotspot": "Hotspot",
    "hotspot_list": "Lista över hotspots",
    "add_new_hotspot": "Lägg till ny hotspot",
    "hotspot_information": "Hotspot-information",
    "edit_details_button": "Redigera detaljer",
    "wifi_info_message": "Anslut och njut av gratis WiFi",
    "connection_message": "Bra, du har varit kopplad till ",
    "connection_message_suffix": " WiFi",
    "wifi": "WiFi",
    "login_to_access": "Logga in för att få tillgång",
    "verification_code": "Verifieringskod",
    "verification_code_message": "Vänligen ange verifieringskoden som skickas till ",
    "wifi_user_email": "adamo@gmail.com",
    "wifi_label": "Wifi",
    "your_name": "Vad heter du förresten?",
    "your_birthdate": "Kan du berätta vad du har för födelsedatum?",
    "request_guest_wifi_name": "Vänligen ange gästens wifi-namn",
    "request_device_key": "Vänligen ange enhetsnyckel",
    "request_maximum_internet_access_length": "Välj maximal längd för internetåtkomst",
    "mac_address": "MAC-adress",
    "device_port": "Enhetsport",
    "hardware": "Hårdvara",
    "current_uptime": "Aktuell drifttid",
    "nearby_devices": "Enheter i närheten",
    "firmware": "Firmware",
    "who_are_you": "Vem är du?",
    "where_to_contact_you": "Var kommer vi i kontakt med dig?",
    "your_area_code": "Vad är ditt riktnummer?",
    "connected": "Ansluten",
    "delete_hotspot_message": "Denna hotspot kommer att raderas permanent.",
    "delete_multiple_hotspots_message": "Dessa hotspots kommer att raderas permanent.",
    "speed_error": "Hastigheten bör vara minst 0,01",
    "speed_max_error": "Ange ett värde upp till 1024 för obegränsad hastighet, eller välj ett lägre värde inom det stödda området",
    "device_ssid": "Enhetens SSID",
    "device_ssid_two": "Enhets SSID två",
    "device_ssid_two_wpa": "Enhets SSID två WPA",
    "device_key": "Enhetsnyckel",
    "select_location": "Välj Plats",
    "select_maximum_internet_access_length": "Välj Maximal Internet Access Length",
    "download_speed": "Ladda ner Speed",
    "upload_speed": "Uppladdningshastighet",
    "network_length_1": "15 minuter",
    "network_length_2": "30 minuter",
    "network_length_3": "45 minuter",
    "network_length_4": "1 timme",
    "network_length_5": "2 timmar",
    "network_length_6": "4 timmar",
    "network_length_7": "6 timmar",
    "network_length_8": "8 timmar",
    "network_length_9": "10 timmar",
    "network_length_10": "12 timmar",
    "employee_wifi_name": "Anställds wifi-namn",
    "employee_wifi_password": "Lösenord för anställd wifi",
    "guest_wifi_name": "Gästens wifi-namn",
    "menu_other_products_txt": "Andra produkter | Meny online",
    "menu_home_text": "Hem | Meny online",
    "whatsapp_title": "Whatsapp",
    "select_campaign_type": "Välj en kampanjtyp",
    "select_readymade_templates": "Välj bland färdiga mallar eller gör dina egna",
    "campaign_title_required": "Kampanjens titel krävs",
    "type_here": "Skriv här...",
    "location_permission_req": "Platstillstånd krävs",
    "platform_permission_req": "Plattformstillstånd krävs",
    "profile_picture": "Profilbild",
    "click_to_upload": "Klicka för att ladda upp",
    "location_permission": "Platstillstånd",
    "pin": "Stift",
    "platform_permission": "Plattformstillstånd",
    "set_pin": "Ställ in PIN-kod",
    "list_of_users": "Lista över användare",
    "create_user": "Skapa användare",
    "terms_and_condition_req": "Regler och villkor krävs",
    "terms_and_conditions": "Regler och villkor",
    "recommended": "REKOMMENDERAD",
    "mo": "\/mån",
    "unsubscribe": "Avsluta prenumeration",
    "cancel_subscription": "Är du säker på att du vill avsluta prenumerationen?",
    "upgrade": "Uppgradera",
    "plan_upgraded_successfully": "Planen har uppgraderats!",
    "menu_online": "Meny online",
    "support": "Stöd",
    "wifiC": "WIFI",
    "billing": "Fakturering",
    "please_upgrade": "Vänligen uppgradera för att njuta av alla funktioner",
    "you_have_subscribed": "Du har prenumererat på ",
    "plan": "planera",
    "of_text": "av",
    "days": "dagar",
    "remaining_days_until_plan": " Dagar kvar tills din plan behöver uppdateras",
    "manage_your_screens": "Hantera dina skärmar",
    "screens_you_save": "Skärmar du har",
    "add_more_screens": "Lägg till fler skärmar",
    "addition_screen": "Tilläggsskärm",
    "per_screen": "$ per skärm",
    "per_box": "$ per låda",
    "shipping_changes": "Fraktkostnader",
    "upto4_boxes": "upp till 4 lådor ",
    "charge_now": "Du kommer att få avgift nu",
    "updated_reoccuring": "Din uppdaterade återkommande kommer att vara",
    "current_reoccuring": "Aktuellt återkommande: ",
    "no_text": "Inga",
    "android_box": "Android Box",
    "old_password_req": "Ett gammalt lösenord krävs",
    "new_password_req": "Nytt lösenord krävs",
    "confirm_password_req": "Bekräfta lösenord krävs",
    "password_do_not_match": "Lösenord stämmer inte överens",
    "old_password": "Gammalt lösenord",
    "new_password": "Nytt lösenord",
    "confirm_password": "Bekräfta lösenord",
    "copy_working_hours": "Kopiera dessa arbetstider till alla dagar?",
    "yes_copy": "Ja, kopiera",
    "closed": "Stängd",
    "opening_time": "Öppettid",
    "closing_time": "Stängningstid",
    "description": "Beskrivning",
    "file_exist": "filen finns redan, välj en annan fil",
    "bussiness_images": "Företagsbilder",
    "display_info_on_market_place": "Vill du visa nedanstående information på din marknadsplats?",
    "profile_ready": "Din profil är klar!",
    "client_book_with_online": "Kunder kan nu boka hos dig online. Dela länken nedan för att komma igång.",
    "copy_link": "Kopiera länk",
    "see_your_profile": "Se din profil",
    "ok_got_it": "Okej, förstår",
    "preview_profile": "Förhandsgranska din profil och se hur den skulle se ut.",
    "opening_hours": "Öppettider",
    "display_data": "Visa data",
    "manage_profile": "Hantera profil",
    "phone_req": "Telefon krävs",
    "market_place_img_req": "Marknadsbilder krävs",
    "add_new_market_place": "Lägg till ny marknadsplats",
    "edit_market_place": "Redigera MarketPlace",
    "no_reviews_yet": "Inga recensioner ännu",
    "good": "Bra",
    "average": "Genomsnitt",
    "add_marketPlace": "Lägg till MarketPlace",
    "all_title": "Alla",
    "included_title": "Ingår",
    "excluded_title": "Utesluten",
    "clients_subscribed": "Kunder prenumererade i den senaste",
    "clients_groups": "Kundgrupper",
    "customer_groups": "Kundgrupper",
    "audience_title": "Publik",
    "client_gt5": "som inte kom tillbaka i det sista",
    "select_all": "Välj Alla",
    "modify": "Ändra",
    "campaign_title": "Kampanjens titel",
    "msg_type": "Meddelandetyp",
    "enter_discount": "Ange rabatt",
    "discount_type": "Rabatttyp",
    "coupon_text": "Kupongtext",
    "enter_coupon_text": "Ange kupongtext",
    "coupon_button_text": "Kupongknappstext",
    "enter_coupon_button_text": "Ange kupongknappstext",
    "fine_prin": "Finstilt",
    "enter_fine_prin": "Ange finstilt text",
    "campaign_dec": "Du kan ändra kampanjen 30 minuter innan dess starttid. Avgifter för SMS-kampanj kommer att debiteras 30 minuter före kampanjens starttid.",
    "blast_text_message_dec": "Ditt blast-sms är klart. Välj din betalningsmetod och skicka dina meddelanden.",
    "payment_completed": " Betalningen genomförs kl ",
    "total_cost": "Total kostnad",
    "close_title": "Nära",
    "friday": "fredag",
    "saturday": "lördag",
    "sunday": "söndag",
    "thursday": "torsdag",
    "tuesday": "tisdag",
    "wednesday": "onsdag",
    "port_txt": "Hamn",
    "today_title": "I dag",
    "yesterday_title": "I går",
    "last_30_days_txt": "Senaste 30 dagarna",
    "this_month_txt": "Denna månad",
    "last_month_txt": "Förra månaden",
    "valid_date_title": "Välj ett giltigt datum",
    "valid_business_name_txt": "Ange ett giltigt företagsnamn",
    "req_bus_add_txt": "Företagsadress krävs",
    "req_domain_name_txt": "Domännamn krävs",
    "basic_info_txt": "Grundläggande information om ditt företag",
    "loyalty_qr_def_txt": "Skanna QR-koden, gå med i vårt lojalitetshålkort och njut av belöningar.",
    "last_stamp_txt": "Sista stämpeln",
    "collected_on_txt": "Samlade på",
    "stamp_details_txt": "Stämpeldetaljer",
    "add_stamp_txt": "Lägg till stämpel",
    "choose_brand_color_txt": "Välj din varumärkesfärg och ditt språk 🎨",
    "change_anytime_txt": "Du kan ändra detta när som helst",
    "like_to_get_txt": "Skulle du vilja få",
    "your_next_visit_txt": "på ditt nästa besök?",
    "time_to_time_get_offers": "Vill du få bra erbjudanden och kampanjer då och då?",
    "respond_back_to_you": "Vill du att någon ska svara dig?",
    "input_serve_better": "Din input kommer att hjälpa vårt team att hjälpa dig bättre!",
    "do_it_txt": "Låt oss göra det",
    "take_15_s": "Tar 15 sekunder",
    "rate_on_google_desc": "Skulle du vara snäll nog att betygsätta oss på google?",
    "may_be_next_time": "Kanske nästa gång",
    "thank_you_txt": "Tack",
    "look_next_txt": "Vi ser fram emot ditt nästa besök!",
    "deleteUserTitle": "Denna användare kommer att raderas permanent.",
    "deleteMUserTitle": "Dessa användare kommer att raderas permanent.",
    "change_pin": "Byt stift",
    "area_code": "Vad är ditt riktnummer? ",
    "add_menu": "Lägg till meny",
    "menu_name": "Menynamn",
    "add_menu_placeholder": "Pizza, hamburgare, baverages, etc",
    "enable_data_Collection": "Aktivera datainsamling",
    "add_new_menu": "Lägg till ny meny",
    "rename_menu": "Byt namn på menyn",
    "preview": "Förhandsvisning",
    "generate_qr": "Genererade QR",
    "edit_menu": "Redigera meny",
    "remove_menu": "Ta bort menyn",
    "menu_delete_msg": "Denna meny kommer att raderas permanent",
    "menus_delete_msg": "Dessa menyer kommer att raderas permanent",
    "view_menu_dialoage_msg": "Vill du få en present på din födelsedag?",
    "skip": "Hoppa",
    "cliam_your_gift": "Gör anspråk på din gåva",
    "collect_form": "Samla formulär",
    "enter_first_name": "Ange förnamn",
    "enter_last_name": "Ange efternamn",
    "enter_email": "Ange e-post",
    "enter_dob": "Ange födelsedatum",
    "enter_number": "Ange nummer",
    "select_gender": "Välj Kön",
    "congratulations_desc": "🎉 Grattis! Din begäran om gåvoanmälan har skickats. Vårt team kommer att kontakta dig inom kort. 🎉",
    "male_heading": "Manlig",
    "male_text": "manlig",
    "female_heading": "Kvinnlig",
    "female_text": "kvinnlig",
    "others_heading": "Andra",
    "other_text": "andra",
    "BirthD": "Födelsedatum",
    "GenderT": "Kön",
    "EmailT": "E-post",
    "dobT": "DOB",
    "capitalize_menu": "Meny",
    "select_menu": "Välj meny",
    "manage_variant": "Hantera variant",
    "add_products": "Lägg till produkter",
    "add_category": "Lägg till kategori",
    "category_delete": "Denna kategori kommer att raderas permanent",
    "variation_delete": "Denna variant kommer att tas bort från produkten.",
    "product_delete": "Denna produkt kommer att raderas permanent.",
    "categories_delete": "Dessa kategorier kommer att raderas permanent.",
    "products_delete": "Dessa produkter kommer att raderas permanent.",
    "category": "KATEGORI",
    "price": "PRIS",
    "food_product_placeholder": "Pizza, hamburgare, baverages, etc",
    "active_title": "Aktiv",
    "inActive_title": "Inaktiv",
    "status_capital": "STATUS",
    "cat_status_require": "Kategoristatus krävs",
    "cat_name_require": "Kategorinamn krävs",
    "category_name": "Kategorinamn",
    "status": "Status",
    "lgDay1": "måndag",
    "lgDay2": "tisdag",
    "lgDay3": "onsdag",
    "lgDay4": "torsdag",
    "lgDay5": "fredag",
    "lgDay6": "lördag",
    "lgDay7": "söndag",
    "is_closed_title": "Stängd",
    "book_table_title": "Boka ett bord",
    "emailErrSub": "E-postämne krävs",
    "email_subject": "E-postämne",
    "contactUsfrmTitleEmail": "Svara på mejl",
    "companyInfo": "Lorem Ipsum är lorem hrig, lorem ipsum är fraets.Lorem Ipsum är lorem hrig, lorem ipsum är fraets.Lorem Ipsum är lorem hrig, lorem ipsum är fraets.",
    "footerSTitle1": "Om Menuonline",
    "footerSTitle1Lnk2": "Drag",
    "footerSTitle1Lnk3": "Blogg",
    "footerSTitle2": "Rättslig",
    "footerSTitle2Lnk1": "Regler och villkor",
    "footerSTitle2Lnk2": "Sekretesspolicy",
    "footerSTitle3": "För företag",
    "footerSTitle3Lnk1": "För partners",
    "footerSTitle3Lnk2": "Prissättning",
    "footerSTitle3Lnk3": "Support för partners",
    "footerCopyRithgTxt": "  Menuonline eller dess dotterbolag",
    "homeTitle": "Hem",
    "contactTitle": "Kontakta oss",
    "aboutTitle": "Om oss",
    "homeMenuTitle": "Meny",
    "bookTableTitle": "Boka ett bord",
    "vDateT": "Välj ett giltigt datum",
    "reqDateT": "Datum krävs",
    "dateGtErr": "Datum måste vara senare än eller lika med dagens datum",
    "timeInvalid": "Tiden är ogiltig",
    "reqTimeT": "Tid krävs",
    "timeInvalidBfr": "Välj tid minst 5 minuter senare från aktuell tid ",
    "PerReq": "Antal personer krävs",
    "validcNoT": "Ange ett giltigt kontaktnummer",
    "reqCrT": "Valuta krävs",
    "reqPrT": "Pris krävs",
    "reCateT": "Kategori krävs",
    "reqVrT": "Variant krävs",
    "reqVrVldT": "Ange giltig variant ",
    "validLNameT": "Ange ett giltigt efternamn",
    "sDateInValid": "Från datum är ogiltigt",
    "minmReq": "Minimum krävs",
    "resLblDate": "Välj Datum",
    "resLblTime": "Välj Tid",
    "perT": "För hur många personer?",
    "resLblEmail": "Vänligen ange din e-post?",
    "resLblNote": "Lägg till en reservationsanteckning",
    "imageError1MB": "Vänligen infoga en bild som är mindre än 1mb",
    "imageError": "Vänligen infoga en bild som är mindre än 2,5 MB",
    "imageError3Mb": "Vänligen infoga en bild som är mindre än 3 MB",
    "imageInVldError": "Filen är ogiltig.",
    "addProMT": "Produktmeny",
    "proT": "Produktnamn",
    "reqProT": "Produktnamn krävs",
    "proPT": "Produktpris",
    "reservationSuccTitle": "Bra, din bokning är klar",
    "book": "Boka",
    "custzName": "Anpassningsnamn",
    "always": "Alltid",
    "proImgReq": "Produktbild krävs",
    "selCustmT": "Välj Anpassning eller skapa ny",
    "visStR": "Synlighetsstatus krävs",
    "avlblScR": "Vänligen välj tillgänglighetsschema",
    "addonPR": "Vänligen välj tilläggsprodukter",
    "upsellPR": "Välj merförsäljningsprodukter",
    "markItemR": "Vänligen välj markera artikelprodukter",
    "caloriesR": "Kalorier krävs",
    "allergensR": "Välj allergenprodukter",
    "prepInstR": "Förberedelseinstruktioner krävs",
    "staffNR": "Personalanteckningar krävs",
    "discountR": "Rabatt krävs",
    "validDiscE": "Ange giltig rabatt",
    "disday": "Dag för dag",
    "plSelDayT": "Välj dag",
    "sTimeReq": "Starttid krävs",
    "sTimeInValid": "Starttiden är ogiltig",
    "eTimeReq": "Sluttid krävs",
    "eTimeInValid": "Sluttiden är ogiltig",
    "sDateReq": "Från datum krävs",
    "eDateReq": "Till datum krävs",
    "eDateInValid": "Hittills är ogiltig",
    "disdate": "Från datumet",
    "disdate1": "Hittills",
    "disdate2": "Från datum",
    "currT": "Valuta",
    "iconR": "Vänligen välj markeringsikonen",
    "minT": "Minimum",
    "maxT": "Maximal",
    "itemNT": "Objektets namn",
    "itemPT": "Artikelpris",
    "descProT": "Beskriv din produkt",
    "cateT": "Kategori",
    "selProCateT": "Välj din produktkategori",
    "reqMT": "Menynamn krävs",
    "ViewMenu": "Visa meny",
    "CopyMenu": "Kopiera meny",
    "EditMenu": "Redigera meny",
    "RemoveMenu": "Ta bort menyn",
    "welcomeMnuTitle": "Välkommen till",
    "reviewT": "Recensioner",
    "userErrorMsg": "Tyvärr, ett fel uppstod!!!",
    "reqCateT": "Kategorinamn krävs",
    "mngCustT": "Hantera anpassning",
    "custNReq": "Anpassningsnamn krävs",
    "incReq": "Ingår krävs",
    "minmValid": "Ange ett giltigt lägsta värde",
    "maxmReq": "Maximalt krävs",
    "cust": "Anpassning",
    "crCust": "Skapa ny anpassning",
    "custList": "Anpassningslista",
    "delCustmzT": "Denna anpassning kommer att raderas permanent.",
    "mkNameR": "Markera objektets namn krävs",
    "mkDelT": "Detta märkesobjekt kommer att raderas permanent.",
    "hideText": "Dölja",
    "showText": "Visa",
    "device_mac_txt": "Enhet Mac",
    "delete_hotspot_txt": "Denna hotspot kommer att raderas permanent.",
    "delete_hotspots_txt": "Dessa hotspots kommer att raderas permanent.",
    "emp_wifi_name": "Anställds wifi-namn",
    "max_limit_txt": "Ange '1024' för hastighet för att beteckna den som OBEGÄRAD. Detta värde tar bort eventuella tak, vilket tillåter maximal genomströmning",
    "device_port_txt": "Enhetsport",
    "firmware_txt": "Firmware",
    "hotspot_info_txt": "Hotspot-information",
    "editDBtn": "Redigera detaljer",
    "birth_date": "Födelsedatum",
    "di_theme_clr": "Temafärg exakt 7 siffror",
    "color_Invalid_txt": "Välj giltig färgkod",
    "Req_theme_clr": "Temafärg krävs",
    "select_valid_color_txt": "Välj giltig temafärg",
    "req_redir_link": "Omdirigeringslänk krävs",
    "val_redir_link": "Ange en giltig omdirigeringslänk",
    "req_business_name_txt": "Företagsnamn krävs",
    "splash_preview": "Splash Preview",
    "create_new_splash": "Skapa New Splash",
    "splash_page": "Splash-sida",
    "splash_page_builder": "Splash Page Builder",
    "redirect_link": "Omdirigera länk",
    "theme_color": "Tema färg",
    "enable_social_login": "Aktivera alternativ för sociala medier för användarnas inloggning",
    "google": "Google",
    "facebook": "Facebook",
    "is_mandatory": "Är det obligatoriskt?",
    "field": "Fält",
    "name": "Namn",
    "first_name": "Förnamn",
    "last_name": "Efternamn",
    "birthdate": "Födelsedatum",
    "gender": "Kön",
    "email": "E-post",
    "dob": "DOB",
    "zip_code": "Postnummer",
    "required_redirect_link": "Omdirigeringslänk krävs",
    "valid_redirect_link": "Ange en giltig omdirigeringslänk",
    "required_theme_color": "Temafärg krävs",
    "theme_color_length": "Temafärg exakt 7 siffror",
    "required_name": "Namn krävs",
    "delete_splash_message": "Vill du ta bort detta stänk?",
    "delete_multiple_splashes_message": "Vill du ta bort dessa stänk?",
    "user_login_required": "Användarinloggning krävs",
    "set_theme_color": "Ställ in temafärg",
    "splash_colllect_additional_info_txt": "Samla in ytterligare information",
    "verify_mobile_otp_message": "Vill du skicka OTP för att verifiera mobilnummer?",
    "add_company": "Lägg till företag",
    "edit_company": "Redigera företag",
    "ds_device": "Digital Signage-enhet",
    "ds_device_activation_fee": "Aktiveringsavgift för Digital Signage-enhet",
    "wifi_hardware": "Wifi hårdvara",
    "menu_design": "Menydesign",
    "ds_hardware": "Digital Signage hårdvara",
    "company_name_req": "Företagsnamn krävs",
    "known_name_req": "Känt namn krävs",
    "no_location_req": "Antal plats krävs",
    "minimum_location": "Ange minst 1 plats",
    "terms_req": "Villkor krävs",
    "notes_req": "Anteckningar krävs",
    "sms_charge_req": "SMS-avgift krävs",
    "menuonline_quantity_req": "Meny Online Kvantitet krävs",
    "menuonline_cost_req": "Meny Online Kostnad krävs",
    "ds_quantity_req": "Antalet Digital Signage krävs",
    "ds_monthly_cost_req": "Digital Signage Månadskostnad krävs",
    "reputation_quantity_req": "Rykte Kvantitet krävs",
    "reputation_cost_req": "Rykte Kostnad krävs",
    "marketing_quantity_req": "Marknadsföringsmängd krävs",
    "marketing_cost_req": "Marknadsföringskostnad krävs",
    "tax_percentage_req": "Skatteprocent krävs",
    "router_hardware_quantity_req": "Routerhårdvara Kvantitet krävs",
    "wifi_hardware_cost_req": "Kostnad för WIFI-hårdvara krävs",
    "activation_cost_req": "Aktiveringskostnad krävs",
    "wifi_quantity_req": "WIFI-kvantitet krävs",
    "wifi_router_req": "Wifi-router krävs",
    "menu_design_quantity_req": "Menydesign Kvantitet krävs",
    "menu_design_cost_req": "Menydesignkostnad krävs",
    "quantity_req": "Kvantitet krävs",
    "cost_req": "Kostnad krävs",
    "sheduledown_quantity_req": "Schema ner Kvantitet krävs",
    "sheduledown_cost_req": "Schema ner kostnad krävs",
    "loyalty_program_quantity_req": "Lojalitetsprogram Kvantitet krävs",
    "loyalty_program_cost_req": "Kostnad för lojalitetsprogram krävs",
    "loyalty_program_sms_quantity_req": "Lojalitetsprogram SMS Kvantitet krävs",
    "loyalty_program_sms_cost_req": "SMS-kostnad för lojalitetsprogram krävs",
    "comapny_created": "Företaget skapades framgångsrikt",
    "comapny_updated": "Företaget har uppdaterats framgångsrikt",
    "billing_info": "FAKTURERINGSINFORMATION",
    "business_name_acc_holder": "Företagsnamn (kontoinnehavare)",
    "client_first_name": "Klientens förnamn",
    "client_last_name": "Klientens efternamn",
    "dba_known_as": "DBA känd som",
    "business_address": "Företagsadress",
    "no_of_locations": "Antal platser",
    "package_details": "PAKET DETALJER",
    "terms": "Villkor",
    "one_month": "1 månad",
    "per_sms_charge": "Per SMS avgift",
    "plan_test": "PLAN: TEST",
    "desc_capital": "BESKRIVNING",
    "qty_capital": "ANTAL",
    "cost_capital": "KOSTA",
    "total_price_capital": "TOTALPRIS",
    "quantity": "Kvantitet",
    "can_not_access": "Du kan inte välja mer än",
    "licenses": "licenser",
    "monthly_cost": "Månadskostnad",
    "plan_cost": "Planeringskostnad",
    "tax": "Beskatta",
    "tax_percentage": "Skatteprocent",
    "monthly_cost_after_tax": "Månadskostnad efter skatt",
    "activation_one_time_charge": "Aktivering och engångsladdning",
    "sub_total": "SUBTOTAL",
    "action": "Handling",
    "cost": "Kosta",
    "shipping_charge": "Fraktavgift",
    "other": "Andra",
    "additional_cost": "Extra kostnad (andra avgifter)",
    "other_tax": "Annan skatt",
    "total": "Total",
    "license_statistics": "Licensstatistik",
    "total_licenses": "Totalt antal licenser",
    "available_licenses": "Tillgängliga licenser",
    "stock": "Stock",
    "has_permission": "Har tillstånd",
    "avg_stock_price": "AVG aktiekurs",
    "average_price": "Genomsnittligt pris",
    "allocated": "Tilldelad",
    "reward_regulars": "Belöna stamgästerna",
    "not_add_coupon_url": "Det går inte att lägga till kupong-URL. Meddelandets maximala längd är 160",
    "like_attach_coupon": "Vill du bifoga en kupong?",
    "advance_scheduling": "Förhandsschemaläggning",
    "choose_day": "Välj dag(ar) för att skicka meddelandet",
    "select_essage_window": "Välj meddelandefönster",
    "subscription_outside_delivery_window": "Om en användare prenumererar utanför leveransfönstret kommer de att få ditt autosvar vid nästa tillgängliga tidpunkt.",
    "remaining": "Återstående",
    "processing_request": "Ett fel uppstod när din begäran bearbetades.",
    "list_companies": "Lista över företag",
    "are_you_sure": "Är du säker..?",
    "signin_agreement": "Genom att bekräfta detta kommer företaget att skapa utan att teckna avtal.",
    "status_updated": "Statusuppdateringen lyckades",
    "status_failed": "Det gick inte att uppdatera status",
    "new_editor_status_updated": "Status för DS New Editor har uppdaterats.",
    "user_name": "Användarnamn",
    "known_as": "känd som",
    "agreement_signed": "Avtal undertecknat",
    "deactivate": "Avaktivera",
    "activate": "Aktivera",
    "login_to_user_acc": "Logga in på användarkonto",
    "disable": "Inaktivera",
    "enable": "Aktivera",
    "ds_new_editor": "DS Ny redaktör",
    "sign_agreement": "Underteckna avtal",
    "agreement_sign": "Avtal utan underskrift",
    "view_agreement": "Visa avtal",
    "download_agreement": "Ladda ner avtal",
    "add_deleted_user": "Lägg till raderad användare",
    "deleted_user": "Ta bort användare",
    "favourite": "Favorit",
    "refresh": "Uppdatera",
    "delete_chat": "Ta bort chatt",
    "responsive_drawer": "Responsiv låda",
    "delete_contact": "Ta bort kontakt",
    "clear_chat_history": "Rensa chatthistorik",
    "clear_chat_history_dec": "Är du säker på att du vill ta bort den här chatten?",
    "clear_contact_dec": "Är du säker på att du vill ta bort den här kontakten?",
    "select_contact": "Välj Kontakt",
    "new_conversation": "Starta en ny konversation",
    "type_msg": "Skriv ditt meddelande här",
    "select_delete_client": "Välj klient att ta bort",
    "select_delete_client_chat": "Välj klient för att ta bort chatten",
    "select_delete_client_chat_err": "Du har inte chatt med den här klienten",
    "acquire_customers_business": "Skaffa nya kunder från andra företag.",
    "customers_from_events": "Få kunder från evenemang och ta med det till dig!",
    "customers_from_ads": "Få kunder från tidningsannonser.",
    "smart_pamphlets": "Skapa smarta pamfletter för att samla in kunddata.",
    "smart_qr_group": "En smart QR för att gå med i en grupp för kunder.",
    "opt_in_number": "Gör det möjligt för kunder att välja antal.",
    "qr_menu": "Skapa QR-kod för att visa din meny.",
    "collect_birthdays": "Samla kundens födelsedag och årsdag.",
    "join_loyalty_program": "Få nya kunder att gå med i mitt lojalitetsprogram.",
    "create_feedback_forms": "Skapa formulär för att samla in kundens feedback.",
    "exclusive_discounts_promotions": "Erbjud exklusiva rabatter och kampanjer för korskampanjer.",
    "group_smart_qr_opt_in": "När du har skapat en grupp kan du generera och välja en smart QR-kod som gör det möjligt för kunder att omedelbart delta i en grupp",
    "group_smart_qr_opt_in_dec": "Efter att ha skapat en grupp, bildwidget som gör det möjligt för kunder att välja att använda text och ta emot kampanjer, till exempel sms:a \"pizza\" till 555-555-5555",
    "in_store_discount_next_visit": "Få rabatt i butiken vid ditt nästa besök på vår plats",
    "delivery_redirection": "Skapa leveransomdirigering",
    "additional_info": "Ytterligare information",
    "add_url": "Lägg till URL",
    "custom_fields": "Anpassade fält",
    "meta_tag": "Metatagg",
    "max_chars": "Max tecken",
    "add": "Tillägga",
    "update_campaign": "Uppdatera kampanj",
    "last_week_required": "sista veckan krävs",
    "large_week_value": "Veckovärdet är för stort ange giltig vecka",
    "less_than_last_week": "Ej retur under förra veckan måste vara mindre än förra veckan.",
    "last_week_req": "Ej återlämnande under förra veckan krävs",
    "birthday_special": "Födelsedag special",
    "birthday_message": "Automatiskt meddelande som skickas ut på deras födelsedagar med personliga hälsningar och frestande erbjudanden",
    "birthday_sms": "Skapa personliga födelsedagshälsningar för SMS\/MMS",
    "target_audience": "Välj målgrupp",
    "client_by_birthday": "Kund efter födelsedag",
    "clients_once_year": "Denna kampanj skickas automatiskt till kunder en gång om året ",
    "auto_responder_default_msg_1": "Du är vår en av de bästa kunderna som vi älskar att ge rabatt till! Må du alltid vara rik och frisk. Grattis på födelsedagen! %% förnamn %%",
    "campaign_send_to_client_birthday": "Denna automatiska kampanj skickar till kunder med födelsedagar, du kan anpassa inriktningen nedan.",
    "get_more_engagement": "Få mer engagemang genom att skicka b'day SMS 🎂",
    "welcome_new_clients": "Välkomna nya kunder",
    "make_lasting_impression": "Gör ett bestående intryck genom att varmt hälsa och välkomna förstagångsgäster",
    "personalized_greetings": "Skapa personliga hälsningar till de nya kunderna",
    "greetings_to_new_customers": "Den här kampanjen kommer att skicka ut välkomsthälsningar till nya kunder dagen efter deras första interaktion med ditt företag",
    "audience_predefined": "Den här autosvararens målgrupp är fördefinierad, vi har det här!",
    "send_clients_subscribed": "Denna autosvarare är inställd för att skicka kunder som prenumererade på den senaste ",
    "first_interaction": "  efter deras första interaktion.",
    "default_msg_2": "Vi hoppas att du gillade ditt första besök, vi vill gärna se dig snart igen så vi erbjuder en rabatt bara för dig! Klicka på länken för att boka ditt nästa möte med oss ​​och lösa in ditt specialerbjudande online nu.",
    "new_clients_update": "Välkommen nya kunder uppdatera kampanjtext",
    "new_clients_warm_greeting": "Välkommen nya kunder med en varm hälsning 💌",
    "win_back_clients": "Vinn tillbaka kunder",
    "re_engage_past_customers": "Återkoppla tidigare kunder med oemotståndliga erbjudanden för att uppmuntra dem att återvända",
    "target_disengaged_clients": "Rikta upp oengagerade kunder att återvända med ett specialerbjudande, en beprövad taktik för att locka tillbaka kunder.",
    "campaign_sends_to_clients": "Denna kampanj skickas till kunder som inte återvänt efter en viss tid.",
    "customize_targeting": "Anpassa inriktningen nedan för att skicka ut SMS\/MMS till förfallna klienter.",
    "clients_with_at_least": "Kunder med minst ",
    "connection_last": " anslutning i den sista ",
    "return_in_last_week": " vecka, men kom inte tillbaka senast ",
    "arr_menu_44": " vecka",
    "df_msg_4": "Vi vill ha dig tillbaka SÅ VILL att vi kommer att ge dig 10% rabatt bara för att du returnerar! Vill du ge oss ett försök igen idag?",
    "update_campaign_text": "Vinn tillbaka klienter uppdatera kampanjtext",
    "re_invite_customers": "Bjud in kunder som inte har varit tillbaka på ett tag igen",
    "loyal_patrons": "Visa uppskattning till lojala kunder med speciella rabatter och lojalitetsbelöningar",
    "surprize_top_spenders": "Överraska de bästa spenderarna med personliga och specialerbjudanden.",
    "campaign_automatically_send": "Den här kampanjen skickar automatiskt ut belöningar för att öka fotfästet i ditt företag.",
    "sms_loyal_clients": "Denna kampanj skickar SMS\/MMS till lojala kunder, du kan ändra inriktningen nedan.",
    "or_more_connection": " eller fler anslutning, inom den sista ",
    "week_period": " vecka period.",
    "default_msg_5": "Du har varit kund länge nu! Wow, tiden går fort! Vi uppskattar dig och vill tacka dig för att du är med oss.",
    "reward_regulars_update": "Belöna stamgästerna uppdaterar kampanjtexten",
    "special_discounts": "Uppskatta och ge speciella rabatter💎",
    "leave_positive_online": "Uppmuntra kunder att lämna positiva onlinerecensioner för att stärka restaurangens rykte",
    "clients_service_online": "Kampanjen uppmuntrar dina kunder att betygsätta din tjänst online.",
    "increase_your_company": "Denna autosvarare skickas för att öka ditt företags onlinebetyg.",
    "send_message_after": "Skicka meddelande efter ",
    "min_connection": " min av anslutningen",
    "default_msg_6": "Vi hoppas att du gillade ditt första besök, vi vill gärna se dig snart igen! [URL] Klicka på länken för att betygsätta oss på Google.",
    "ratings_update": "Få mer betygsuppdateringskampanjtext",
    "friendly_nudge": "Få fler recensioner med en vänlig knuff ⭐",
    "thanks_for_visiting": "Tack för ditt besök",
    "express_gratitude": "Uttryck tacksamhet till kunder för att de valt ditt företag med hjärtliga tackmeddelanden",
    "thank_you_message": "Skapa ett tackmeddelande till dina kunder.",
    "clients_for_visiting": "Skicka ett varmt tack till dina kunder för att du besökte din plats.",
    "campaign_audience": "Denna automatiska kampanjmålgrupp är fördefinierad, vi har det här!",
    "campaign_automatically_sends": "Denna automatiska kampanj skickas automatiskt efter ",
    "minutes_customer_visit": " minuter av kundbesök",
    "default_msg_7": "Tack för att du är vår uppskattade kund. Vi är så tacksamma för nöjet att betjäna dig och hoppas att vi motsvarade dina förväntningar.",
    "visiting_update_campaign": "Tack för att du besöker uppdateringskampanjtexten",
    "guests_thank_you": "Tacka dina gäster för besöket 😊",
    "download_title": "Ladda ner",
    "qr_gen_title": "QR genererad",
    "qr_download_s_title": "QR-koden har laddats ned",
    "sel_dark_brand_clr_msg": "Vänligen välj giltig barnfärg",
    "manage_customer_stamp_rewards": "Hantera kundstämpelbelöningar 🎁",
    "sel_loc_menu_title": "Välj platser för din meny",
    "ans_req": "Svar krävs",
    "valid_reputation_name": "Ange ett giltigt ryktenamn",
    "reviews_name_req": "Namn på Få fler recensioner krävs",
    "birthdate_required": "Födelsedatum krävs",
    "gender_required": "Kön krävs",
    "valid_birthdate_required": "Ange ett giltigt födelsedatum",
    "custom_delivery_redirection": "Skapa ett anpassat leveransomdirigeringsformulär efter grupper",
    "customer_fields_incentive_settings": "Kundfält och incitamentinställningar",
    "delivery_redirection_text": "Omdirigering av leverans",
    "delivery_redirection_success": "grattis! Din leveransomdirigeringswidget skapad! 🎉",
    "swipe_to_preview_redirection": "Svep för att se hur ditt formulär för leveransomdirigering kommer att se ut",
    "enter_item_name": "Ange objektets namn",
    "home_page_text": "Hemsidans text",
    "settings": "Inställningar",
    "anniversary_required": "Jubileumsdatum krävs",
    "valid_anniversary": "Ange ett giltigt årsdagsdatum",
    "form_submited": "formuläret har skickats in",
    "notifications": "Aviseringar",
    "discount_message": "Få 20 % rabatt på pizza nu!",
    "is_required": " krävs",
    "section_title_required": "Avsnittstitel krävs",
    "section_dec_required": "Sektionsbeskrivning krävs",
    "client_details_required": "Kundinformationsfält är obligatoriska",
    "compliance": "Efterlevnad",
    "SMS_campaign1": "SMS-kampanj 1",
    "mobile_number_mandatory": "Mobilnummer är obligatoriskt",
    "new_answer": "Nytt svar",
    "new_question": "Ny fråga",
    "add_new_question": "Lägg till nytt svar",
    "select": "Välja",
    "group_customers_question": "Var vill du gruppera dina kunder?",
    "contacts_added_to_group": "Alla kontakter som skickar in formuläret kommer att läggas till i den valda gruppen",
    "edit_client_details_section": "Redigera avsnittet med klientinformation",
    "client_details_fields": "Fält för kundinformation",
    "enter_client_details_section_title_desc": "Ange rubrik och beskrivning för avsnittet med kundinformation",
    "choose_fields_displayed_client_side": "Välj de fält som ska visas på klientsidan",
    "section_title": "Avsnittsrubrik",
    "add_title": "Lägg till titel",
    "section_description": "Avsnitt Beskrivning",
    "add_description": "Lägg till beskrivning",
    "enter_program_name_location": "Ange programnamn och plats 📍",
    "brand_theme_clr_txt": "Varumärkes temafärg",
    "set_loyalty_punch_card_theme": "Låt oss sätta ett tema för ditt lojalitetshålkort 🎨",
    "upload_logo_txt": "Ladda upp din logotyp",
    "recommended_image_specs": "Vi rekommenderar att du använder en bild som är 512 x 512 px för bästa visning. JPG, SVG eller PNG. Max storlek på 10MB.",
    "valid_rep_name_txt": "Ange ett giltigt ryktenamn",
    "rep_name_req_txt": "Namn på Få fler recensioner krävs",
    "que_req": "Fråga krävs",
    "day_of_birthday": "på födelsedagen",
    "day_before_birthday": "3 dagar innan födelsedagen",
    "week_before_birthday": "en vecka innan födelsedagen",
    "two_week_before_birthday": "två veckor innan födelsedagen",
    "de_active": "De-aktiv",
    "campaign_details": "Kampanjdetaljer",
    "link_clicked": "Länken klickad",
    "history": "Historia",
    "auto_responder_summary": "Sammanfattning av autosvar",
    "vsop_1": "15 min",
    "vsop_2": "30 min",
    "vsop_3": "45 min",
    "vsop_4": "60 min",
    "vsop_5": "90 min",
    "vsop_6": "120 min",
    "in_the_last": " i den sista ",
    "return_in_last": " men kom inte tillbaka sist ",
    "of_the_connection": "av anslutningen",
    "your_campaign": "Din kampanj",
    "you_have_successfully": "Du har lyckats",
    "published_successfully": "publiceras framgångsrikt",
    "updated_smart_campaign": "uppdaterade den smarta kampanjen",
    "almost_done_text": "Nästan klart!",
    "update_campaign_desc": "Din autosvarare är klar, när den väl har aktiverats kommer den att skicka meddelanden till kunder på en löpande basis. Den här kampanjen kan enkelt ändras eller pausas när som helst.",
    "update_and_publish": "Uppdatera och publicera",
    "reset_campaign_title": "Det kommer premanent att ta bort din kampanjs anpassade text, bild, rabatterbjudanden och målgrupp!",
    "space_wifi": " WiFi",
    "custom_forms": "Anpassade formulär",
    "web_e_signup": "E-registrering på webben",
    "import": "Importera",
    "permissions_req": "Behörigheter krävs",
    "redeemed": "Förlöst",
    "coupon_already_redeemed": "Kupongen har redan lösts in",
    "autoresponder_campaigns": "Autosvarskampanjer",
    "autoresponder_campaign_desc": "Aktivera autosvaret och starta den personliga kampanjen",
    "mon": "MÅN",
    "tue": "TI",
    "wed": "ON",
    "thu": "THU",
    "fri": "FRI",
    "sat": "SAT",
    "sun": "SOL",
    "duplicate": "Duplicera",
    "visibility": "Synlighet",
    "availability": "Tillgänglighet",
    "out_stok": "Slut på stok",
    "edit_product": "Redigera produkt",
    "create_product": "Skapa en produkt",
    "basic_info": "Grundläggande information",
    "basic_sub_info": "Lorem Ipsum är helt enkelt dummy text av trycket...",
    "enter_product_price": "Ange produktpris",
    "upload_image": "Ladda upp bild",
    "allowed_file_formats": "Endast JPG- och PNG-filer med maximal filstorlek 3MB är tillåtna.",
    "pre_select": "Förval",
    "promotions": "Kampanjer",
    "discount_availability": "Rabatt tillgänglig",
    "start_time": "Starttid",
    "end_time": "Sluttid",
    "select_day": "Välj Dag",
    "menu_required": "Meny krävs",
    "generated": "Genererad",
    "link": "Länk",
    "variations": "Variationer",
    "select_variant_to_add": "Välj den variant du vill lägga till",
    "price_title": "Pris",
    "choose_image": "Välj Bild",
    "select_visiblity": "Välj Synlighet",
    "availability_schedule": "Tillgänglighetsschema",
    "add_on_upsell": "Tillägg \/ Merförsäljning",
    "add_on_select_product": "Lägg till på Välj produkt",
    "upsell_product": "Merförsäljning Välj produkt",
    "variant_deletion_warning": "Denna variant kommer att raderas permanent.",
    "search_variations": "Sök varianter",
    "add_variation": "Lägg till variation",
    "variation_text": "Variant",
    "select_customization": "Välj anpassning",
    "add_new": "Lägg till ny",
    "delete_customization_warning": "Vill du ta bort denna anpassning från produkten?",
    "nutritional_allergen_info": "Närings- och allergeninformation",
    "mark_item": "Markera objekt",
    "calories_text": "Kalorier",
    "allergens": "Allergener",
    "select_allergens": "Välj allergener",
    "special_instructions": "Särskilda instruktioner",
    "preparation_instructions": "Förberedelseinstruktioner",
    "staff_notes": "Personalanteckningar",
    "checkbox1": "En kryssruta för att tillåta eller förbjuda särskilda instruktioner från kunder för detta menyalternativ.",
    "menu_c": "MENY",
    "past_design": "Tidigare design",
    "file_name": "Filnamn",
    "variation_name": "Variationsnamn",
    "grid_items_title1": "Bordstält",
    "grid_items_title2": "Digital meny",
    "grid_items_title3": "Avhämtningsmeny",
    "grid_items_title4": "Halvsidesmeny",
    "grid_items_title5": "Digital meny 5",
    "grid_items_title6": "Digital meny 6",
    "grid_items_title7": "Digital meny 7",
    "grid_items_title8": "Digital meny 8",
    "grid_items_title9": "Digital meny 9",
    "enter_valid_form_name": "Ange ett giltigt formulärnamn",
    "form_name_required": "Formulärnamn krävs",
    "enter_valid_section": "Ange giltig avsnittstitel",
    "fraction_half": "1\/2",
    "fraction_two_by_two": "2\/2",
    "form_name": "Form Namn",
    "contact_list": "Kontaktlista",
    "label_notify_email": "Vill du bli meddelad via e-post?",
    "add_client_details_section": "Avsnittet Lägg till kundinformation",
    "demo_purpose_only": "Det är endast för demoändamål",
    "form_updated_success": "formuläret har uppdaterats framgångsrikt",
    "form_creat_success": "formuläret har skapats framgångsrikt",
    "add_custom_section": "Lägg till ett anpassat avsnitt",
    "edit_custom_section": "Redigera ett anpassat avsnitt",
    "forms_list": "Lista över formulär",
    "filled_custom_form_details": "Ifyllda anpassade formulärdetaljer",
    "custom_form_deletion_warning": "Detta anpassade formulär kommer att raderas permanent",

    "menu_link": "Menylänk",
    "WebSignUpT": "E-registrering",
    "lifeTConT": "Livstidsanslutningar",
    "repCustT": "Återkommande kunder",
    "liftTSubT": "Livstidsprenumeranter",
    "overallT": "TOTAL",
    "loginCustBDT": "Inloggade kunder per dag",
    "deviceT": "Enhetsdemografi",
    "ageTitle": "Ålderdemografi",
    "ageT": "Åldersgrupp",
    "convert_social_media_followers_into_customers": "Konvertera följare på sociala medier till kunder",
    "signWidDesc": "Bygg en widget för att registrera dig, skapa målsidor och generera kuponger från din webbplats eller sociala medier",
    "qrWidT": "Smart QR",
    "imgWidT": "Välj antal",
    "mobile": "Mobil",
    "YESTitle": "JA",
    "NOTitle": "INGA",
    "addDelRed": "Lägg till leveransomdirigering",
    "dlredNameT": "Namnge din leveransomdirigering",
    "conf_text_req": "Bekräftelsetext krävs",
    "cFDesc": "Skapa skräddarsydda formulär för att effektivt samla in specifik feedback och information från kunder",
    "pre_review_questions_enabled": "Innan vi ber kunderna om recension, låt oss ställa dem 1 till 4 frågor.",
    "feel_free_edit": "Redigera gärna vid behov.",
    "option": "Alternativ",
    "confFL": "Denna bekräftelse kommer att visas efter att din kontakt har skickat in webbregistreringsformuläret",
    "confT": "Bekräftelsetext",
    "joinTitle": "Ansluta sig till",
    "mobValidL": "Kontrollera giltigt mobilnummer",
    "mobRequiredL": "Mobilnummer krävs",

    "Business_location_label": "Din företagsplats",
    "qr_code_selection_txt": "Qr-kod Val",
    "choose_theme_txt": "Välj Tema",
    "edit_qr_details_txt": "Redigera QR-detaljer",
    "save_qr_code_txt": "Spara QR-koden",
    "text_color_label": "Text färg",
    "background_color_label": "Bakgrundsfärg",
    "print_txt": "Skriva ut",
    "customer_will_see_txt": "Detta är vad din kund kommer att se. Välj din varumärkesfärg i nästa steg.",
    "choose_your_brand_color_&_langauge_title": "Välj din varumärkesfärg och språk",
    "custom_redirect_link_label": "Anpassad omdirigeringslänk",
    "redirect_user_to_menu": "Omdirigera användare till menyn",
    "language": "Språk",
    "select_language_placeholder": "Välj Språk",
    "capitalize_location_name_txt": "Platsnamn",
    "created_txt": "Skapad",
    "swipe_reputation_management_txt": "Svep för att se hur din rykteshantering kommer att se ut",
    "sent_txt": "Skickat",
    "number_label": "Antal",
    "date_label": "Datum",
    "gender_demographics_title": "Könsdemografi",

    "step1_preview_desc": "Namnge ditt QR-formulär för e-registrering och lägg till i din grupplista",
    "main_head": "Skaffa nya kunder",
    "main_desc": "Få kunder genom att placera en QR-kod som, när den skannas, omdirigerar användare till ett personligt registreringsformulär.",
    "step1_description": "Skaffa nya kunder från andra företag",
    "form_name_field_placeholder": "Restro E-registrering",
    "last_step_title": "grattis! Din e-registreringswidget skapad! 🎉",
    "last_step_desc": "Svep för att se hur ditt e-registreringsformulär kommer att se ut",
    "events_step1_preview_desc": "Namnge ditt Event QR-formulär och lägg till i din grupplista",
    "events_main_head": "Få kunder från evenemang",
    "events_main_desc": "Få kunder genom att placera en QR-kod på händelsen, när den skannas, omdirigerar användarna till ett personligt registreringsformulär.",
    "events_step1_desc": "Fånga kunder från evenemang och ta med dem i butiken",
    "events_name": "Händelsenamn",
    "events_form_name_field_placeholder": "Restro kul och matevenemang",
    "events_last_step_title": "grattis! Ditt evenemangsformulär skapat! 🎉",
    "events_last_step_desc": "Svep för att se hur ditt evenemangsformulär kommer att se ut",
    "birthday_anniversary_step1_preview_desc": "Namnge ditt QR-formulär för att samla in kundens födelsedag eller årsdag och det läggs till i din grupplista",
    "birthday_anniversary_main_head": "Samla B'day eller Anniversary",
    "birthday_anniversary_main_desc": "Få kundens födelsedag eller årsdag, när den skannas, omdirigerar användarna till ett personligt registreringsformulär.",
    "birthday_anniversary_step1_desc": "Samla kundens födelsedag och årsdag",
    "restro_text": "Restro",
    "birthday_anniversary_last_step_title": "grattis! Få B'day & Anniversary från kundformulär skapat! 🎉",
    "newspaper_ads_step1_preview_desc": "Namnge ditt QR-formulär för att få kunder från tidningsannonser och det läggs till i din grupplista",
    "newspaper_ads_main_head": "Få kunder från Ads",
    "newspaper_ads_main_desc": "Få kunder från tidningsannonser, när de skannade omdirigerar det användarna till ett personligt registreringsformulär.",
    "newspaper_ads_step1_desc": "Få kunder från tidningsannons eller utomhusannons",
    "newspaper_ads_last_step_title": "grattis! Få kunder från tidningsannonser formulär skapat! 🎉",
    "smart_pamphlets_step1_preview_desc": "Namnge ditt QR-formulär för att få kunddata från broschyrer och det läggs till i din grupplista",
    "smart_pamphlets_main_head": "Få kunder från pamfletter",
    "smart_pamphlets_main_desc": "Få kundens data från broschyrer, när de skannade omdirigerar den användare till ett personligt registreringsformulär.",
    "smart_pamphlets_last_step_title": "grattis! Få kundens data från pamfletter skapade formulär! 🎉",
    "social_media_step1_preview_desc": "Namnge ditt QR-formulär för att få kunder från sociala medier och till kunder och det läggs till i din grupplista",
    "social_media_main_desc": "Få kunder från sociala medier, när de skannade från inlägg omdirigerar det användare till ett personligt registreringsformulär.",
    "social_media_form_name_field_placeholder": "Restro Festival Erbjudande - Facebook-inlägg",
    "social_media_last_step_title": "grattis! Få kunders sociala medier-formulär skapat! 🎉",
    "claim_offer_text": "Fyll i dina uppgifter och hämta erbjudandet nu",
    "form_title_description": "Lägg till formulärrubrik och beskrivning så att dina kunder kan ange detaljerna",
    "no_subscribed_clients": "Den valda kundgruppen har inga prenumererade kunder",
    "welcome_message_offer": "Skicka ett välkomstmeddelande till dina kunder efter att de har registrerat sig och ge dem en erbjudandekupong",
    "system_grp": "Systemgrupper",
    "import_clients": "Importera kunder",
    "delivery_redirection_name": "Namn på leveransomdirigering",
    "created_at": "Skapad på",
    "provide_stamp_user": "Ge stämpel till användaren",
    "scan_qr_code": "Skanna QR-koden",
    "add_point": "Lägg till punkt",
    "total_points": "Totalt antal poäng",
    "user_profile": "Användarprofil",
    "stamp_history": "Frimärkshistorik",
    "reward_txt": "Belöna",
    "variant_placeholder": "Small, Medium, Large, etc",
    "sent_coupon": "Skickat kupong",
    "order_status": "Orderstatus",
    "payment_status": "Betalningsstatus",
    "order_currency": "Beställningsvaluta",
    "card_data": "Kortdata",
    "reward_expiring_on": "Belöning löper ut",
    "reward_status": "Belöningsstatus",
    "reward_get_date": "Belöning Get Date",
    "reward_name": "Belöningsnamn",
    "add_point_type": "Lägg till punkttyp",
    "restaurant_location": "Restaurangens läge",
    "collect_points": "Samla poäng",
    "rewards_expire": "Belöningar löper ut",
    "rewards_redeem": "Belöningar Lös in",
    "total_rewards": "Totala belöningar",
    "created_by": "Skapad av",
    "anniversary": "Årsdag",
    "remove_user_group_txt": "Ta bort användare från grupp",
    "delete_permanently_grp_msg": "Denna klient kommer att raderas permanent från gruppen.",
    "delete_multiple_permanently_grp_msg": "Denna klient kommer att raderas permanent från gruppen.",
    "delete_multiple_client": "Denna klient kommer att raderas permanent.",
    "messages": "Meddelanden",
    "management": "Förvaltning",
    "client": "Klient",
    "country": "Land",
    "state": "Ange",
    "city": "Stad",
    "zip_code": "Postnummer",
    "1_sparkly_point_txt": "🎉 Du har tjänat 1 gnistrande poäng! 🌟",
    "select_country_txt": "Välj land",
    "select_state": "Välj tillstånd",
    "ls_thanks_for_join": "Tack för att du gick med i vårt lojalitetshålkort.🎁🎈",
    "month": "Månad",
    "welcome_message": "Skicka ett välkomstmeddelande till dina kunder efter att de har registrerat sig",
    "all_rewards_txt": "Alla belöningar",
    "active_rewards": "Aktiva belöningar",
    "expired_and_redeemed": "Utgått och inlöst",
    "redirect_3rd_party_delivery": "Omdirigera leverans från tredje part till din butik eller till din beställningsplattform online",
    "search_or_start_new": "Sök eller starta en ny",
    "contacts": "Kontakter",
    "chats": "Chattar",
    "add_by_restaurant": "Lägg till efter restaurang",
    "using_scan_code": "Använder skanningskod",
    "step": "Steg",
    "list_widget_text": "Lista över widgets",
    "swipe_free_wifi_txt": "Svep för gratis wifi",
    "create_widget_txt": "Skapa widget",
    "redeem_award_msg": "Är du säker? Vill du lösa in denna belöning",
    "on_collect_msg": "På Collect",
    "pause": "Paus",
    "resume": "Resume",
    "location_added": "plats tillagd",
    "sub_user_admin_permissions_txt": "Tillåt underanvändare att komma åt administratörsbehörigheter i Digital Signage",
    "unlist": "Avlista",
    "cannot_delete_default_terms": "Kan inte ta bort standardvillkor",
    "something_went_wrong": "Något gick fel!",
    "card_download_success": "Kortet har laddats ned",
    "permission_denied": "Tillstånd nekad!",
    "invalid_qr_code": "Skanna giltig QR-kod",
    "select_customer": "Välj kund",
    "online": "ONLINE",
    "offline": "OFF-LINE",
    "no_data": "Hoppsan, ingen data hittades!",
    "forever_free": "För alltid gratis"
};

export default sv