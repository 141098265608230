const ur = {
    "rated_google_reviews": "گوگل ریویو پر 5.0 کا درجہ دیا گیا۔",
    "get_your_digital_menu": "اپنا ڈیجیٹل مینو حاصل کریں-",
    "instant_popup_menu": "QR اسکیننگ کے بغیر فوری پاپ اپ مینو",
    "free_forever": "ہمیشہ کے لیے مفت",
    "the_future_is_here": "مستقبل یہاں ہے!",
    "upgrade_your_restaurant": "اپنے ریستوراں کو ایک شاندار ڈیجیٹل مینو کے ساتھ صفر قیمت پر اپ گریڈ کریں۔ آج سے مزید گاہکوں کو متوجہ کریں۔",
    "get_my_free_menu_now": "میرا مفت مینو ابھی حاصل کریں۔",
    "no_credit_card_required": "*کریڈٹ کارڈ کی ضرورت نہیں۔",
    "join_largest_marketplace": "ریستوران کے سب سے بڑے بازار میں شامل ہوں!",
    "what_will_you_get": "آپ کو کیا ملے گا۔",
    "free": "مفت",
    "free_digital_menu": "مفت ڈیجیٹل مینو 2D یا 3D",
    "free_digital_menu_desc": "کاغذی مینوز اور قیمتی دوبارہ پرنٹس کو الوداع کہیں۔",
    "quick_and_easy_signup": "فوری اور آسان سائن اپ",
    "communicate_with_customers_title": "صارفین کے ساتھ مفت میں بات چیت کریں!",
    "features1": [
        "پرنٹنگ کے اخراجات کو بچائیں اور جب بھی آپ کو ان کی ضرورت ہو فوری مینو اپ ڈیٹس سے لطف اندوز ہوں۔",
        "کسی بھی ڈیوائس پر اپنے ڈیجیٹل مینو تک رسائی حاصل کریں۔",
        "ماحول دوست اور پریشانی سے پاک"
    ],
    "features2": [
        "صارفین کو شاندار 3D ویژولز کے ساتھ آپ کے پکوان کو عملی طور پر دریافت کرنے دیں۔",
        "کھانے والوں کو ان کے کھانے کا حقیقت پسندانہ پیش نظارہ دے کر مشغولیت کو بہتر بنائیں۔",
        "ایک ایسے مینو تجربے کے ساتھ نمایاں ہوں جو آپ کے کھانے کے ذائقے کی طرح دلکش محسوس ہوتا ہے۔"
    ],
    "features3": [
        "ریستوراں میں داخل ہوتے ہی اپنے مینو کے ساتھ مہمانوں کا استقبال کریں۔",
        "خصوصی اور پروموشنز کے بارے میں اپ ڈیٹس خود بخود ان کے فون پر بھیجیں۔",
        "اپنے مینو تک فوری، کنٹیکٹ لیس رسائی کے ساتھ کھانے کے تجربے کو بہتر بنائیں۔"
    ],
    "feature4": [
        "موسمی پیشکشوں، روزانہ کی خصوصی، اور خصوصی پروموشنز کو نمایاں کریں۔",
        "صارفین کی توجہ اپنی مقبول ترین اشیاء کی طرف مبذول کروائیں۔",
        "دلکش منظروں اور وضاحتوں کے ساتھ عجلت اور جوش پیدا کریں۔"
    ],
    "feature5": [
        "روزانہ ہزاروں زائرین کے ساتھ ایک آن لائن کمیونٹی کا حصہ بنیں۔",
        "مرئیت کو فروغ دیں اور کھانے کے اختیارات تلاش کرنے والے نئے صارفین تک پہنچیں۔",
        "اپنی نمائش کو زیادہ سے زیادہ کرنے اور مزید کھانے والوں کو اپنی طرف متوجہ کرنے کے لیے ابتدائی جگہ کو محفوظ کریں۔"
    ],
    "section3_heading": "ڈیجیٹل کیوں جائیں؟ کیونکہ یہ مفت اور آسان ہے!",
    "section3_description": "اپنے ریستوراں کی اپیل کو بڑھانے کے لیے ڈیزائن کردہ طاقتور خصوصیات کو غیر مقفل کریں۔",
    "instant_menu": "فوری مینو",
    "instant_menu_notification": "پاپ اپ اور اطلاع",
    "instant_menu_description": "مہمانوں کو داخل ہوتے ہی فوری مینو رسائی کے ساتھ خوش آمدید کہتے ہیں۔ خودکار طور پر تازہ ترین مینو اور خصوصی براہ راست ان کے موبائل فون پر بھیجیں۔",
    "digital_menu": "ڈیجیٹل مینو",
    "at_zero_cost": "صفر لاگت پر",
    "digital_menu_description1": "کاغذی مینوز اور قیمتی دوبارہ پرنٹس کو الوداع کہیں۔ ایک ایسے ڈیجیٹل مینو پر جائیں جو ہمیشہ اپ ٹو ڈیٹ ہوتا ہے — اور یہ ہمیشہ کے لیے مفت ہے!",
    "digital_menu_description2": "ہمارے ڈیجیٹل مینو کے ساتھ، آپ کی پیشکشیں ہمیشہ موجودہ اور کسی بھی ڈیوائس پر قابل رسائی ہوتی ہیں۔ بغیر کسی ہموار، صفر لاگت والے حل کے ساتھ پیسے بچائیں اور مینو اپ ڈیٹس کو آسان بنائیں۔",
    "try_me_button": "مجھے آزمائیں",
    "try_3d_menu_title": "3D مینو",
    "try_3d_menu_title2": "مفت میں - واہ!",
    "try_3d_menu_description1": "اپنے پکوانوں کو اسکرین سے باہر کریں۔ 3D تصاویر اپ لوڈ کریں اور گاہک اندر جانے سے پہلے انہیں آپ کے کھانے کا مزہ چکھنے دیں۔",
    "try_3d_menu_description2": "اعلیٰ معیار کی 3D تصاویر منہ کو پانی دینے والا پیش نظارہ بناتی ہیں، اپیل کو بڑھاتی ہیں اور آرڈرز کی حوصلہ افزائی کرتی ہیں۔",
    "try_now": "ابھی کوشش کریں۔",
    "showcase_title": "اپنی نمائش کریں۔",
    "showcase_specials": "خصوصی",
    "showcase_specials_description1": "منہ میں پانی لانے والا سودا یا ناقابل شکست پروموشن مل گیا؟ اسے اپنے ڈیجیٹل مینو پر نمایاں کریں اور مزید بھوکے لوگوں کو کھینچیں۔",
    "showcase_specials_description2": "پروموشنز کو کھونا ناممکن بنائیں، جرات مندانہ بصری اور دلکش وضاحتوں کے ساتھ توجہ مبذول کریں۔",
    "marketplace": "مارکیٹ پلیس",
    "marketplace_description": "ہم روزانہ 2,000 سے زیادہ زائرین کے ساتھ ایک بازار تیار کر رہے ہیں۔ ابھی سوار ہو جائیں، اور جب ہم لانچ کریں گے تو آپ سب سے پہلے لائن میں ہوں گے۔ آپ کھانے کے اختیارات کی تلاش میں سرگرم نئے صارفین تک پہنچ سکتے ہیں۔ زیادہ سے زیادہ نمائش کے لیے اپنی جگہ کو جلد محفوظ کریں!",
    "see_other_products": "دیگر مصنوعات دیکھیں",
    "about_us": "ہمارے بارے میں",
    "why_serving_free": "ہم اس کی خدمت کیوں کر رہے ہیں۔",
    "serving_free_highlight": "مفت",
    "about_desc": "ہم یہیں ڈلاس میں مقامی لوگ ہیں، جو آپ جیسے ریستوراں کی ترقی میں مدد کرنے کے لیے پرعزم ہیں۔",
    "about_tool_free": "ہم یہ طاقتور ٹول بالکل مفت پیش کر رہے ہیں کیونکہ ہم اپنی کمیونٹی کو سپورٹ کرنے میں یقین رکھتے ہیں۔",
    "no_catch": "کوئی پکڑ نہیں، کوئی مذاق نہیں۔ ہم جانتے ہیں کہ جب آپ کامیاب ہوتے ہیں تو ہماری کمیونٹی مضبوط ہو جاتی ہے۔ 😊",
    "understanding_challenges": "ہم کاروبار چلانے کے چیلنجوں کو سمجھتے ہیں، یہی وجہ ہے کہ ہم ایسے ٹولز فراہم کرنے کے لیے وقف ہیں جو آپ کے کاموں کو ہموار اور آپ کی رسائی کو وسیع تر بناتے ہیں۔",
    "community_growth": "جیسے جیسے آپ بڑھتے ہیں، ہماری کمیونٹی آپ کے ساتھ بڑھتی ہے، جس سے کامیابی اور حمایت کا ایک زبردست اثر پیدا ہوتا ہے۔ آئیے ایک ساتھ مل کر سب کے لیے کھانے کے تجربے کو بلند کریں۔",
    "how_it_works": "یہ کیسے کام کرتا ہے۔",
    "three_simple_steps": "تین آسان اقدامات",
    "get_started_highlight": "شروع کریں",
    "no_tech_skills_needed": "کسی تکنیکی مہارت کی ضرورت نہیں ہے۔ اگر آپ برگر پلٹ سکتے ہیں، تو آپ یہ کر سکتے ہیں!",
    "step1_title": "اپنا پرومو مفت میں دکھائیں!",
    "step1_desc": "اس سے زیادہ تیزی سے شروع کریں کہ آپ کہہ سکتے ہیں \"آرڈر اپ!\" بس ایک سادہ سا فارم پُر کریں۔",
    "step2_title": "اپنے مینو کو حسب ضرورت بنائیں",
    "step2_desc": "اپنے پکوان شامل کریں، 3D میں بھی تصاویر اپ لوڈ کریں اور اپنی خصوصی چیزوں کو نمایاں کریں۔",
    "step3_title": "شیئر کریں اور بڑھیں۔",
    "step3_desc": "QRs، سوشل میڈیا، یا اپنی ویب سائٹ کے ذریعے اپنے ڈیجیٹل مینو کا اشتراک کریں۔",
    "testimonials": "تعریفیں",
    "testimonials_section_title": "اس کے لیے صرف ہمارا کلام نہ لیں۔",
    "testimonials_section_desc": "دیکھیں کہ دوسرے ڈلاس ریستوراں کے مالکان کیا کہہ رہے ہیں۔",
    "faq_section_title": "سوالات ہیں؟ ہمارے پاس جوابات ہیں۔",
    "faq_section_desc": "وہ سب کچھ جو آپ کو شروع کرنے سے پہلے جاننے کی ضرورت ہے۔",
    "faqs": "اکثر پوچھے گئے سوالات",
    "faq_answers": [
        {
            "que": "کیا یہ واقعی ہمیشہ کے لیے مفت ہے؟",
            "ans": "بالکل! کوئی پوشیدہ فیس، کوئی کریڈٹ کارڈ، کوئی تار منسلک نہیں۔"
        },
        {
            "que": "کیا مجھے کسی تکنیکی جانکاری کی ضرورت ہے؟",
            "ans": "اگر آپ ای میل بھیج سکتے ہیں، تو آپ یہ کر سکتے ہیں۔ یہ اتنا آسان ہے۔"
        },
        {
            "que": "آپ یہ مفت میں کیوں پیش کر رہے ہیں؟",
            "ans": "ہم پہلے دینے پر یقین رکھتے ہیں۔ لائن کے نیچے، ہمارے پاس دوسری خدمات ہو سکتی ہیں جو آپ کو پسند آئیں گی — لیکن یہ مکمل طور پر آپ پر منحصر ہے۔"
        },
        {
            "que": "بازار کے ساتھ کیا معاملہ ہے؟",
            "ans": "ہم اسے پکا رہے ہیں! ایک بار جب ہمارے پاس بورڈ پر کافی ریستوراں ہو جائیں، تو ہم آپ کو اور بھی زیادہ نمائش کے لیے آپ کا بازار شروع کریں گے۔"
        }
    ],
    "testimonials_content1": "ڈیجیٹل مینو پر سوئچ کرنا ہم نے کیا بہترین اقدام تھا! ہمارے گاہکوں کو سہولت اور اپ ڈیٹ شدہ شکل پسند ہے!",
    "testimonials_name1": "مورس جارج",
    "testimonials_restaurant_name1": "مینشن ریسٹورنٹ",
    "testimonials_content2": "ہمارا نیا ڈیجیٹل مینو مہمانوں کے لیے براؤزنگ کو آسان بناتا ہے، اور یہ واقعی مصروفیت کو بڑھاتا ہے!",
    "testimonials_name2": "امیلیا جانسن",
    "testimonials_restaurant_name2": "شہری کھاتا ہے۔",
    "testimonials_content3": "مہمانوں کو 3D ویژولز نے حیرت زدہ کر دیا ہے—اس نے ہمیں نمایاں ہونے اور ان کے کھانے کے تجربے کو بہتر بنانے میں مدد کی ہے!",
    "testimonials_name3": "ڈیوس گرین",
    "testimonials_restaurant_name3": "ٹاؤن ہارتھ",
    "ready_to_boost_your_restaurant": "اپنے ریستوراں کو فروغ دینے کے لیے تیار ہیں؟",
    "ready_to_boost_desc": "پیچھے نہ ہٹیں۔ آج ہی ڈیجیٹل انقلاب میں شامل ہوں!",
    "yes_i_want_my_free_digital_menu": "ہاں، میں اپنا مفت ڈیجیٹل مینو چاہتا ہوں۔",
    "480_plus": "480+",
    "people_already_joined": "لوگ پہلے ہی شامل ہو چکے ہیں۔",
    "menu_online_home": "مینو آن لائن ہوم",
    "welcome": "خوش آمدید",
    "description_other": "آئیے آپ کے گاہک کی اطمینان کو بڑھاتے ہیں!",
    "benefits": "فوائد",
    "greeting_text": "خوش آمدید 👋",
    "all_in_one_features": "🚀 آپ کے ریسٹورنٹ کو بلند کرنے کے لیے سب ان ون خصوصیات",
    "menu_online_menu_name": "مینو آن لائن",
    "menu_online_description": "صارفین کے لیے آسان آن لائن رسائی کے لیے اپنے مینو کو ڈیجیٹل بنائیں۔",
    "menu_online_benefits": "گاہک کی سہولت میں اضافہ کریں اور انہیں کسی بھی وقت، کہیں بھی آپ کا مینو دیکھنے کی اجازت دے کر مشغولیت کو فروغ دیں۔",
    "ds_name": "ڈیجیٹل اشارے",
    "ds_description": "ڈائنامک ان اسٹور اسکرینز پر پیشکشیں اور پروموشنز دکھائیں۔",
    "ds_benefits": "پرکشش بصریوں، اپ سیلز کو فروغ دینے اور ریئل ٹائم پیشکشوں کے ساتھ صارفین کی توجہ اپنی طرف مبذول کریں۔",
    "loyalty_punch_name": "وفاداری پنچ کارڈ",
    "loyalty_punch_description": "دہرائے گئے صارفین کو ڈیجیٹل لائلٹی پروگرام کے ساتھ انعام دیں۔",
    "loyalty_punch_benefits": "بار بار وزٹ کرنے پر قابل تلافی انعامات کے ساتھ وفاداری کی ترغیب دے کر کسٹمر کو برقرار رکھنے اور فروخت میں اضافہ کریں۔",
    "marketing_menu_name": "مارکیٹنگ",
    "marketing_description": "ٹارگٹڈ ڈیجیٹل مارکیٹنگ مہمات کے ساتھ مزید صارفین تک پہنچیں۔",
    "marketing_benefits": "نئے اور بار بار گاہکوں کو متوجہ کرنے کے لیے تیار کردہ حسب ضرورت پروموشنز کے ساتھ اپنے ریستوراں کی پہنچ کو وسعت دیں اور پیدل ٹریفک چلائیں۔",
    "get_more_reviews_menu_name": "مزید جائزے حاصل کریں۔",
    "get_more_reviews_description": "مطمئن صارفین کو مثبت آن لائن جائزے چھوڑنے کی ترغیب دیں۔",
    "get_more_reviews_benefits": "مزید مثبت جائزوں کے ساتھ اپنی آن لائن ساکھ کو بڑھا کر اپنے ریستوراں کی ساکھ کو فروغ دیں اور نئے کھانے پینے والوں کو راغب کریں۔",
    "guest_smart_wifi_menu_name": "مہمان اسمارٹ وائی فائی",
    "guest_smart_wifi_description": "مارکیٹنگ کے لیے مہمانوں کا ڈیٹا حاصل کرنے کے لیے مفت وائی فائی کی پیشکش کریں۔",
    "guest_smart_wifi_benefits": "مستقبل کی پروموشنز کو ذاتی بنانے کے لیے کسٹمر کا قیمتی ڈیٹا اکٹھا کرتے ہوئے کسٹمر کی اطمینان میں اضافہ کریں۔",
    "reservation_menu_name": "ریزرویشن",
    "reservation_description": "صارفین کو آسانی کے ساتھ آن لائن ٹیبل ریزرو کرنے کی اجازت دیں۔",
    "reservation_benefits": "انتظار کے اوقات کو کم سے کم کرکے کھانے کے تجربے کو بہتر بنائیں، اور بیٹھنے کا بہتر انتظام کرنے کے لیے ریزرویشن کو ہموار کریں۔",
    "pos_integration_menu_name": "POS انٹیگریشن",
    "pos_integration_description": "ہمارے سسٹم کو اپنے POS سسٹم کے ساتھ مربوط کریں۔",
    "pos_integration_benefits": "آرڈر پروسیسنگ کو آسان بنائیں اور اپنے آن لائن اور ان اسٹور سسٹمز کو ہم آہنگی میں رکھ کر غلطیوں کو کم کریں۔",
    "online_ordering_menu_name": "آن لائن آرڈرنگ",
    "online_ordering_description": "صارفین کو براہ راست اپنی ویب سائٹ یا ایپ سے آرڈر دینے کے قابل بنائیں۔",
    "online_ordering_benefits": "فیس کی بچت کرنے والے براہ راست آرڈرنگ آپشن کی پیشکش کرکے سیلز میں اضافہ کریں اور تھرڈ پارٹی پلیٹ فارمز پر انحصار کم کریں۔",
    "pro_label": "پرو",
    "coming_soon": "جلد آرہا ہے۔",
    "locked_label": "مقفل",
    "forever_label": "ہمیشہ کے لیے",
    "new_label": "نیا",
    "other_products_page_title": "دیگر مصنوعات | مینو آن لائن",
    "other_products_description": "مینو آن لائن رازداری کی پالیسی",
    "other_products_header_title": "دیگر مصنوعات",
    "privacy_policy": {
        "title": "رازداری کی پالیسی",
        "lastUpdated": "آخری تازہ کاری: 01 جولائی 2024",
        "introduction": {
            "header": "نئے اور موجودہ صارفین کے لیے رازداری کی پالیسی",
            "paragraph1": "مندرجہ ذیل کلیدی نکات (بشمول سیکشن حوالہ جات) صرف آپ کی سہولت کے لیے فراہم کیے گئے ہیں اور مکمل شرائط و ضوابط کے بدلے میں نہیں ہیں۔ یہ آپ کی ذمہ داری ہے کہ مینو آن لائن INC سروس استعمال کرنے سے پہلے ان شرائط و ضوابط کو پڑھ لیں۔",
            "paragraph2": "مینو آن لائن استعمال کرنے کا مطلب ہے کہ آپ شرائط و ضوابط سے اتفاق کرتے ہیں اور نمائندگی اور ضمانت دیتے ہیں کہ آپ کو آپ کی تنظیم یا کمپنی ایسا کرنے کے لیے مجاز ہے۔ (دیکھیں s.3 \"یہ کیسے کام کرتا ہے\")",
            "paragraph3": "Menuonline Inc ایک اجازت پر مبنی مارکیٹنگ سروس ہے۔ جان بوجھ کر \"سپیم\" بھیجنے یا شرائط و ضوابط کی خلاف ورزی کے نتیجے میں آپ کا اکاؤنٹ بند ہو جائے گا۔ (دیکھیں s.16 \"سروس کا قابل قبول استعمال\")",
            "paragraph4": "وہ ڈیٹا جسے آپ Menuonline Inc میں میزبانی کرتے ہیں جیسے کہ رابطہ ریکارڈ، ای میل مواد اور معلومات جو آپ Menuonline Inc کے ذریعے جمع کرتے ہیں وہ آپ کا ہے۔ (دیکھیں s.14 \"آپ کی ملکیت کیا ہے؟\")۔",
            "paragraph5": "Menuonline Inc سافٹ ویئر اور ہماری ویب سائٹ پر موجود مواد ہمارا ہے۔ (دیکھیں s.13 \"ہم کس چیز کے مالک ہیں؟\") آپ کے اپنے صارفین کے علاوہ، آپ Menuonline Inc کے ساتھ جس ڈیٹا یا غیر مطبوعہ مواد کی میزبانی کرتے ہیں اسے صرف Menuonline Inc کے ملازمین اور شراکت دار ہی دیکھ سکتے ہیں جنہوں نے رازداری کے معاہدوں پر دستخط کیے ہیں۔ (دیکھیں s.14 \"آپ کے پاس کیا ہے؟\")",
            "paragraph6": "تمام ذاتی طور پر قابل شناخت معلومات (PII) صرف کینیڈا میں واقع سرورز پر رکھی جاتی ہیں۔ (دیکھیں s.14 \"آپ کی ملکیت کیا ہے؟\")۔",
            "paragraph7": "Menuonline Inc سروس کینیڈا اور صوبہ اونٹاریو کے قوانین کے تابع ہے۔ (دیکھیں s.24 \"گورننگ لاء\")",
            "paragraph8": "کینیڈا کے اندر یا کینیڈا سے باہر افراد، پولیس یا دیگر ریگولیٹری ایجنسیوں کی طرف سے آپ کے ڈیٹا یا مواد کی کوئی بھی درخواست ہمیشہ آپ کو بھیجی جائے گی۔ صرف استثناء ہو گا اگر یہ ہمیں کینیڈا یا اونٹاریو کے قانون کی خلاف ورزی میں ڈالتا ہے۔ تاہم، تمام صورتوں میں ہم آپ کے علاوہ کسی اور کے ذریعہ آپ کے ڈیٹا کے اجراء کی سختی سے مزاحمت کریں گے۔ (دیکھیں s.14 \"آپ کی ملکیت کیا ہے؟\")۔",
            "paragraph9": "مینو آن لائن انک سیکیورٹی صرف اتنی ہی اچھی ہے جتنی کہ آپ کے صارف ناموں اور پاس ورڈز کا انتظام۔ ہم Menuonline Inc کے صارف اکاؤنٹس کے لیے چارج نہیں کرتے ہیں اس لیے ہر صارف کے لیے ایک علیحدہ اکاؤنٹ بنائیں اور پاس ورڈ خفیہ رکھیں۔ آپ اپنے اکاؤنٹ کے ذمہ دار ہیں۔ (دیکھیں s.3 \"یہ کیسے کام کرتا ہے\")",
            "paragraph10": "آپ کسی بھی وقت اپنا اکاؤنٹ منسوخ کر سکتے ہیں۔ اگر آپ منسوخ کرتے ہیں تو ہم کسی غیر استعمال شدہ کریڈٹ پر رقم کی واپسی فراہم نہیں کرتے ہیں۔ (دیکھیں s.3 \"یہ کیسے کام کرتا ہے\")"
        },
        "termsAndConditions": {
            "title": "مینو آن لائن انک شرائط و ضوابط",
            "lastUpdate": "آخری اپ ڈیٹ: جنوری 14، 2020",
            "paragraph1": "براہ کرم اس ویب سائٹ (\"سائٹ\") کو استعمال کرنے سے پہلے ان شرائط و ضوابط (\"شرائط\") کو احتیاط سے پڑھیں۔ یہ شرائط Menuonline Inc. (\"Menuonline Inc\" \"ہم\"، یا \"ہم\") اور دوسروں کو ذمہ داری سے مستثنیٰ رکھتی ہیں اور\/یا ہماری اور ان کی ذمہ داری کو محدود کرتی ہیں اور دیگر اہم دفعات پر مشتمل ہوتی ہیں جو اس سائٹ کے آپ کے استعمال پر لاگو ہوتی ہیں۔ اس سائٹ یا سروس پر جا کر یا استعمال کر کے، آپ اپنی طرف سے، اور کسی بھی تنظیم کی جانب سے جس کی طرف سے آپ کام کر سکتے ہیں (اجتماعی طور پر یہاں \"آپ\" کہا جاتا ہے)، اس سائٹ کے ہر استعمال اور ہر دورے کے لیے ان شرائط کو قبول کرنے اور ان کی پابندی کرنے سے اتفاق کرتے ہیں۔"
        },
        "services": {
            "title": "ہماری خدمات۔",
            "paragraph": "وہ خدمات جو ہم سائٹ کے ذریعے پیش کرتے ہیں ایک ایسی خدمت جو آپ کو اپنی پسند کے وصول کنندگان (\"سروس\") کو ای میل پیغامات (\"ای میلز\") بنانے، بھیجنے اور ان کا نظم کرنے کی اجازت دیتی ہے۔"
        },
        "license": {
            "title": "لائسنس۔",
            "paragraph": "ان شرائط کے تابع، Menuonline Inc اس کے ذریعے آپ کو سائٹ کو دیکھنے اور سروس کے فیچرز کی اجازت کے مطابق سروس استعمال کرنے کی مدت کے دوران ایک غیر خصوصی، محدود، ناقابل منتقلی لائسنس فراہم کرتا ہے۔ ہم وہ تمام حقوق محفوظ رکھتے ہیں جو یہاں سروس اور مینو آن لائن انک مواد میں واضح طور پر نہیں دیے گئے ہیں (جیسا کہ ذیل میں بیان کیا گیا ہے)۔"
        },
        "emailingContent": {
            "title": "ای میل کرنے والا مواد۔",
            "paragraph": "اس سائٹ یا سروس کے ذریعے Menuonline Inc کے ساتھ بات چیت کرتے وقت، آپ صحیح اور درست معلومات فراہم کرنے سے اتفاق کرتے ہیں۔ مزید یہ کہ آپ سمجھتے ہیں کہ ای میل کے ذریعے خفیہ معلومات فراہم کرنا ایک غیر محفوظ عمل ہے۔ آپ سمجھتے ہیں کہ آپ ہماری سروس استعمال کرنے والے کسی کو بھیجنے والے کسی بھی چیز کے مکمل طور پر ذمہ دار ہیں۔"
        },
        "support": {
            "title": "حمایت.",
            "paragraph": "ہم آپ کو سروس کے ساتھ خود کفیل بننے میں مدد کے لیے مدد فراہم کرتے ہیں۔ ہم آپ کو سائٹ پر دستاویزات اور ویڈیوز کی شکل میں، Menuonline Inc سپورٹ ٹکٹ سسٹم اور ٹیلی فون کے ذریعے مدد فراہم کرتے ہیں۔ آپ سائٹ کے اوپری حصے میں \"سپورٹ\" ٹیب پر کلک کر کے ہم تک پہنچ سکتے ہیں۔ پروڈکٹ سپورٹ کے لیے کوئی چارج نہیں ہے۔ تاہم، ہم اپنی صوابدید پر مخصوص کلائنٹ اکاؤنٹس کے لیے سپورٹ بند کرنے کا حق محفوظ رکھتے ہیں۔"
        }
    },
    "terms_condition": {
        "title": "سروس کی شرائط | مینو آن لائن",
        "header": "نئے اور موجودہ صارفین کے لیے شرائط و ضوابط"
    },
    "privacy_policy_title": "پرائیویٹ پالیسی",
    "privacy_policy_effective_date": "16 جنوری 2020 سے موثر ہے۔",
    "privacy_policy_general_title": "عمومی پرائیویٹ پالیسی",
    "privacy_policy_intro": "menuonline.com (Menuonline اور \"ہم\") افراد کی ذاتی معلومات کی رازداری کے تحفظ کی اہمیت اور ہر قسم کی خفیہ معلومات کی حفاظت کی اہمیت کو سمجھتا ہے۔ ہم نے یہ پرائیویٹ پالیسی (\"پالیسی\") آپ کی \"رازداری\" کے تحفظ کے لیے اپنی وابستگی کو ظاہر کرنے کے لیے بنائی ہے۔ یہ نجی پالیسی وضاحت کرتی ہے:",
    "privacy_policy_points": [
        "ہم کون سی ذاتی معلومات جمع کرتے ہیں اور ہم اسے کیوں جمع کرتے ہیں؛",
        "ہم آپ کی ذاتی معلومات کو کس طرح استعمال کرتے ہیں؛",
        "ہم کس کے ساتھ آپ کی ذاتی معلومات کا اشتراک کرتے ہیں؛ اور",
        "وہ انتخاب جو آپ کو سروس سے اپنی ذاتی معلومات تک رسائی، اپ ڈیٹ اور ہٹانے کے لیے ہیں۔"
    ],
    "privacy_policy_pipeda": "مینو آن لائن کینیڈا کے پرسنل انفارمیشن پروٹیکشن اینڈ الیکٹرانک ڈاکومینٹس ایکٹ (PIPEDA) کے مطابق تمام معلومات کے جمع کرنے کا انتظام کرتا ہے۔",
    "privacy_policy_update_notice": "براہ کرم نوٹ کریں کہ مینو آن لائن کسی بھی وقت اس نجی پالیسی کو اپ ڈیٹ یا تبدیل کرنے کا حق محفوظ رکھتا ہے۔ ہم آپ کو کسی بھی تبدیلی کے بارے میں اس ویب سائٹ پر اپ ڈیٹ کردہ پالیسی پوسٹ کر کے یا خدمات کے ذریعے دستیاب ہونے پر مطلع کریں گے۔ اس پالیسی کا جدید ترین ورژن mycircle کے صارفین کی ذاتی معلومات کے استعمال کو کنٹرول کرے گا۔ mycircle کی ویب سائٹ تک رسائی جاری رکھ کر یا اس پرائیویٹ پالیسی میں تبدیلیوں کے بعد اس کی خدمات کا استعمال جاری رکھ کر، آپ نظرثانی شدہ شرائط کے پابند ہونے سے اتفاق کرتے ہیں۔",
    "privacy_policy_consent_title": "ذاتی معلومات کے لیے رضامندی۔",
    "privacy_policy_consent_text": "ہماری ویب سائٹ یا ہمارے کسی بھی فارم کے ذریعے مینو آن لائن کو اپنی ذاتی معلومات فراہم کرنے سے، آپ اس پالیسی کے مطابق یا آپ کی ذاتی معلومات فراہم کرنے کے وقت آپ کی نشاندہی کردہ مقاصد کے لیے اس طرح کی معلومات کو جمع کرنے، استعمال کرنے، اور افشاء کرنے کی رضامندی دیتے ہیں۔ ذاتی معلومات کے کسی بھی ثانوی استعمال پر صرف آپ کی واضح رضامندی سے کارروائی کی جائے گی، یا آپ کو آپٹ آؤٹ کرنے کا موقع فراہم کیا جائے گا۔",
    "privacy_policy_opt_out_text": "آپ کو کسی بھی وقت اپنی رضامندی واپس لینے یا آپٹ آؤٹ کرنے کا موقع ہے۔ اگر آپ اپنی رضامندی واپس لینا چاہتے ہیں یا آپٹ آؤٹ کرنا چاہتے ہیں، تو آپ ہماری طرف سے موصول ہونے والی کسی بھی ای میل کے نیچے موجود مواصلاتی ترجیحات کے لنک پر کلک کر سکتے ہیں۔",
    "privacy_policy_info_collection_title": "معلومات جو ہم جمع کرتے ہیں۔",
    "privacy_policy_info_collection_text": "مینو آن لائن اس پالیسی کے مطابق ذاتی معلومات اکٹھا کرتا ہے اور اس پر کارروائی کرتا ہے۔ ہم اس معلومات کو دوسروں کو فروخت، اشتراک یا کرایہ پر نہیں دیں گے، سوائے اس کے کہ اس پالیسی میں درج ہے۔ مینو آن لائن آپ کو ہماری خدمات یا مصنوعات فراہم کرنے کے مقاصد کے لیے ذاتی معلومات جمع کرتا ہے۔",
    "privacy_policy_info_types_text": "مینیو آن لائن جمع کی جانے والی معلومات کی اقسام میں شامل ہیں:",
    "privacy_policy_account_holders_title": "مینو آن لائن اکاؤنٹ ہولڈرز",
    "privacy_policy_registration_info": "رجسٹریشن کی معلومات: اگر آپ سروسز استعمال کرنے کے لیے سائن اپ کرتے ہیں، تو آپ کو اکاؤنٹ ہولڈر (\"اکاؤنٹ ہولڈر\") بننے کے لیے ایک مینو آن لائن اکاؤنٹ کی ضرورت ہے۔ جب آپ کسی اکاؤنٹ کے لیے رجسٹر ہوتے ہیں، تو آپ سے کچھ بنیادی معلومات فراہم کرنے کے لیے کہا جائے گا، جیسے آپ کا نام، ای میل پتہ، صارف نام، پاس ورڈ، کمپنی کا نام، پیشہ، مقام اور فون نمبر۔ پاس ورڈز انکرپٹڈ ہیں - مینو آن لائن عملہ پاس ورڈ دیکھنے یا بازیافت کرنے سے قاصر ہے۔",
    "privacy_policy_billing_info": "بلنگ کی معلومات: اگر آپ ہماری خدمات کو سبسکرائب کرتے ہیں، تو آپ کو ہمیں ادائیگی اور بلنگ کی معلومات فراہم کرنے کی ضرورت ہوگی۔",
    "privacy_policy_cookies_info": "کوکیز: ہم وزیٹر کے ڈیٹا کو ٹریک کرنے کے لیے کوکیز کا استعمال کرتے ہیں، جیسے کہ ویب سائٹ وزٹ کا ذریعہ اور ہماری ویب سائٹ پر ویب سائٹ وزٹ کی تعداد۔",
    "privacy_policy_ip_info": "IP پتے: IP پتے آپ کے بارے میں خدمات کے استعمال کے حصے کے طور پر جمع کیے جاتے ہیں (مثال کے طور پر ملکی سطح کے مقام اور IP پتوں کو ریکارڈ کرنے کے مقصد کے لیے جو ہماری خدمات کا استعمال کرتے وقت استعمال ہوتے ہیں)۔",
    "privacy_policy_your_contacts_title": "آپ کے رابطے",
    "privacy_policy_distribution_list": "تقسیم کی فہرست: ہماری خدمات کے اپنے استعمال کے حصے کے طور پر، آپ ہمیں فراہم کر سکتے ہیں، یا ہم خدمات کے حصے کے طور پر آپ کے رابطوں (\"رابطے\") کے بارے میں ذاتی معلومات جمع کر سکتے ہیں۔ اس معلومات میں ای میل پتے شامل ہیں اور اس میں دیگر قابل شناخت معلومات شامل ہو سکتی ہیں جیسے کہ نام، کمپنی کا نام یا دیگر معلومات جو آپ خدمات کا استعمال کرتے ہوئے مینو آن لائن کو فراہم کرتے ہیں۔ آپ کو یہ یقینی بنانا ہوگا کہ آپ اپنے رابطوں سے مناسب رضامندی حاصل کرتے ہیں تاکہ ان کی معلومات پر ہمارے ذریعہ کارروائی کی جاسکے۔",
    "privacy_policy_billing_info_contacts": "بلنگ کی معلومات: اگر آپ ہماری خدمات کو سبسکرائب کرتے ہیں، تو آپ کو ہمیں ادائیگی اور بلنگ کی معلومات فراہم کرنے کی ضرورت ہوگی۔",
    "privacy_policy_cookies_contacts": "کوکیز: اگر آپ نے اپنی ویب سائٹ میں مینو آن لائن ٹریکنگ کوڈ شامل کیا ہے تو Menuonline آپ کے رابطوں کے بارے میں ذاتی معلومات اکٹھا کرنے اور استعمال کرنے کے لیے صرف کوکیز اور اسی طرح کی ٹریکنگ ٹیکنالوجیز کا استعمال کرے گا۔ مینو آن لائن کے زیر اہتمام کسی بھی لینڈنگ صفحات پر خودکار طور پر ایک ٹریکنگ کوڈ ہوگا۔",
    "privacy_policy_ip_contacts": "آئی پی ایڈریسز: آئی پی ایڈریس آپ کے رابطوں کے بارے میں خدمات کے حصے کے طور پر جمع کیے جاتے ہیں (مثال کے طور پر ملک کی سطح کے مقام کو ریکارڈ کرنے کے مقصد کے لیے اور فارم جمع کرنے اور رضامندی فراہم کرتے وقت استعمال کیے جانے والے IP پتے)۔",
    "privacy_policy_info_about_contacts_title": "معلومات جو ہم آپ کے رابطوں کے بارے میں جمع کرتے ہیں۔",
    "privacy_policy_info_about_contacts_notice": "*یہ سیکشن صرف ان معلومات پر لاگو ہوتا ہے جو خدمات فراہم کرنے کے مقاصد کے لیے اکاؤنٹ ہولڈر کے رابطوں کے بارے میں مینو آن لائن کے ذریعے جمع، استعمال یا ظاہر کی جاتی ہے*",
    "privacy_policy_info_about_contacts_desc": "مینو آن لائن صرف رابطوں کے بارے میں ذاتی معلومات اکٹھا کرے گا، استعمال کرے گا یا ظاہر کرے گا اگر اکاؤنٹ ہولڈر نے کچھ ایسی خدمات کے لیے رجسٹر کیا ہو جہاں وہ ہمیں تقسیم کی فہرست فراہم کرتے ہیں۔ اکاؤنٹ ہولڈرز اس بات کو یقینی بنانے کے مکمل طور پر ذمہ دار ہیں کہ انہوں نے خدمات کے ذریعے مینو آن لائن کو اپنے رابطوں کے بارے میں ذاتی معلومات فراہم کرتے وقت تمام قابل اطلاق قوانین کی تعمیل کی ہے۔",
    "privacy_policy_info_auto_collect_title": "معلومات مینو آن لائن خود بخود جمع کرتی ہے۔",
    "privacy_policy_info_auto_collect_desc1": "جب اکاؤنٹ ہولڈر کے رابطے اکاؤنٹ ہولڈر سے موصول ہونے والی ای میل مہم کے ساتھ مشغول ہوتے ہیں، یا اکاؤنٹ ہولڈر کی منسلک ویب سائٹ کے لنک کے ساتھ تعامل کرتے ہیں، تو مینو آن لائن کوکیز کے استعمال کے ذریعے ایسے رابطے کے آلے اور ای میل کے ساتھ تعامل کے بارے میں معلومات اکٹھا کر سکتا ہے۔ یہ معلومات صرف خدمات فراہم کرنے اور اکاؤنٹ ہولڈر کو بصیرت فراہم کرنے کے لیے جمع کی جاتی ہیں۔",
    "privacy_policy_info_auto_collect_desc2": "وہ معلومات جو ہم کسی رابطہ کے آلے کے بارے میں جمع کرتے ہیں اور جو ایپلیکیشنز رابطہ ہماری خدمات کے ذریعے اکاؤنٹ ہولڈر سے بھیجی گئی ای میلز تک رسائی کے لیے استعمال کرتے ہیں، ان میں رابطہ کا IP پتہ، آپریٹنگ سسٹم، براؤزر آئی ڈی، اور رابطہ کے سسٹم اور کنکشن کے بارے میں دیگر متعلقہ معلومات شامل ہو سکتی ہیں۔",
    "privacy_policy_info_auto_collect_desc3": "خدمات کے ذریعے بھیجی گئی ای میلز کے ساتھ رابطہ کے تعامل کے بارے میں جو معلومات ہم جمع کرتے ہیں اس میں وہ تاریخیں اور اوقات شامل ہو سکتے ہیں جب رابطہ ای میل اور براؤزنگ کی سرگرمیوں تک رسائی حاصل کرتا ہے، اور خدمات کی کارکردگی سے متعلق معلومات، جیسے کہ خدمات کے ذریعے اکاؤنٹ ہولڈر کے ذریعے رابطہ کو بھیجی گئی ای میلز اور دیگر مواصلات کی فراہمی۔",
    "privacy_policy_info_auto_collect_desc4": "براہ کرم نوٹ کریں، مینو آن لائن آپ کے کسی بھی رابطے سے رابطہ نہیں کرے گا، جب تک کہ قابل اطلاق قانون کی ضرورت نہ ہو۔",
    "privacy_policy_why_we_collect_title": "ہم آپ کی معلومات کیوں جمع کرتے ہیں۔",
    "privacy_policy_why_we_collect_desc": "مینو آن لائن درج ذیل مقاصد کے لیے معلومات جمع کرتا ہے:",
    "privacy_policy_why_collect_1": "مینو آن لائن اکاؤنٹ ہولڈرز کو وہ خدمات فراہم کرنا جن کے لیے وہ سبسکرائب کرتے ہیں، بشمول خدمات کے لیے اکاؤنٹ ہولڈر کی معلومات کا استعمال؛",
    "privacy_policy_why_collect_2": "انتظامیہ اور اکاؤنٹ کے انتظام کے ساتھ ساتھ mycircle کی ویب سائٹ تک رسائی کے لیے؛",
    "privacy_policy_why_collect_3": "اگر آپ نے مارکیٹنگ اور پروموشنل مواد حاصل کرنے کے لیے رضامندی دی ہے، تو آپ کو مینو آن لائن کی طرف سے پیش کردہ دیگر مصنوعات اور خدمات کے بارے میں مطلع کرنے کے لیے؛ اور",
    "privacy_policy_why_collect_4": "اکاؤنٹ ہولڈر مواصلات کا جواب دینے یا آپ کی طرف سے درخواست کردہ معلومات بھیجنے کے لیے۔ اس میں شامل ہوسکتا ہے، لیکن یہ ان تک محدود نہیں ہے:",
    "privacy_policy_why_collect_5": "خصوصی پیشکش اور اپ ڈیٹس؛",
    "privacy_policy_why_collect_6": "نیوز لیٹر اور",
    "privacy_policy_why_collect_7": "سروس کے اعلانات.",
    "privacy_policy_opt_out_desc": "آپ، کسی بھی وقت، مینو آن لائن سے آپ کو بھیجے گئے ہر ای میل کے نیچے اَن سبسکرائب بٹن پر کلک کر کے مواصلات وصول کرنے سے آپٹ آؤٹ کرنے کا انتخاب کر سکتے ہیں۔",
    "privacy_policy_use_disclosure_title": "ذاتی معلومات کا استعمال اور انکشاف",
    "privacy_policy_use_disclosure_desc1": "Menuonline پر جمع کرائی گئی ذاتی معلومات، بشمول Menuonline کی ویب سائٹ یا ایک مقررہ فارم کے ذریعے، کو سختی سے خفیہ رکھا جاتا ہے جب تک کہ اسے اکاؤنٹ ہولڈر کی واضح رضامندی یا قانونی تقاضوں کے مطابق ظاہر نہ کیا جائے۔ کسی بھی صورت میں، اکاؤنٹ ہولڈرز کی طرف سے کسی بھی \"Menuonline\" پلیٹ فارم پر فراہم کردہ کوئی بھی ذاتی معلومات رضاکارانہ ہے: اکاؤنٹ ہولڈر کی طرف سے فراہم کردہ کوئی بھی ذاتی معلومات اکاؤنٹ ہولڈر کی صوابدید پر جمع کرائی جاتی ہے۔ براہ کرم نوٹ کریں کہ آپ کی رضامندی کو منسوخ کرنے کے نتیجے میں بعض مینو آن لائن خدمات تک رسائی یا استعمال کرنے کے قابل نہیں ہوسکتا ہے۔",
    "privacy_policy_use_disclosure_desc2": "مینو آن لائن اپنی ویب سائٹ اور خدمات کے حوالے سے تیسرے فریق اور باہر کے سروس فراہم کنندگان کا استعمال کرتا ہے۔ اکاؤنٹ ہولڈر کی ذاتی معلومات ایسے تیسرے فریقوں اور فراہم کنندگان کے ساتھ شیئر کی جا سکتی ہیں۔ ہم اکاؤنٹ ہولڈرز کے بارے میں مجموعی اعدادوشمار، سیلز، ٹریفک پیٹرن اور متعلقہ معلومات باوقار تیسرے فریق کو بھی فراہم کر سکتے ہیں۔ مینو آن لائن کا تقاضہ ہے کہ کوئی بھی فریق ثالث فراہم کنندہ جس کی ذاتی معلومات تک رسائی ہو اس پرائیویٹ پالیسی کے مطابق ذمہ داریوں کا پابند ہو اور ایسے فراہم کنندگان صرف مینو آن لائن خدمات فراہم کرنے کے مقاصد کے لیے اکاؤنٹ ہولڈر کی ذاتی معلومات اکٹھا، استعمال یا ظاہر کریں۔",
    "privacy_policy_use_disclosure_desc3": "اکاؤنٹ ہولڈرز کے ذریعے فراہم کردہ ذاتی معلومات کو باہر کی تنظیموں کو فروخت، اشتراک یا کرائے پر نہیں دیا جائے گا جب تک کہ آپ کی مضمر یا واضح رضامندی کے مطابق نہ ہو۔ مینو آن لائن معلومات کو آگے بڑھانے، سوالات کے جوابات دینے یا ہمارے پروگرام یا ہماری پالیسیوں میں کسی تبدیلی کی اطلاع فراہم کرنے کے لیے کسی بھی اکاؤنٹ ہولڈر سے رابطہ کرنے کا حق محفوظ رکھتا ہے۔ براہ کرم نوٹ کریں، مینو آن لائن آپ کے کسی بھی رابطے سے رابطہ نہیں کرے گا، جب تک کہ قابل اطلاق قانون کی ضرورت نہ ہو۔",
    "privacy_policy_use_disclosure_desc4": "مینو آن لائن ذاتی معلومات کو صرف اسی صورت میں ظاہر کرے گا جب متعلقہ فرد کی طرف سے رضامندی دی جائے یا:",
    "privacy_policy_disclosure_list_1": "جب قانون کی طرف سے اجازت یا ضرورت ہو، جیسے کہ عرضی یا دیگر قانونی عمل کے جواب میں؛",
    "privacy_policy_disclosure_list_2": "مینو آن لائن کے تمام یا کچھ حصے کو منتقل کرنے والے کو؛",
    "privacy_policy_disclosure_list_3": "اس پالیسی کے مطابق مینو آن لائن کے سروس فراہم کنندگان کو؛ یا",
    "privacy_policy_disclosure_list_4": "قانونی ذمہ داریوں کو پورا کرنے کے لیے، بشمول ریگولیٹری رپورٹنگ کی ذمہ داریاں لیکن ان تک محدود نہیں۔",
    "privacy_policy_service_providers_title": "مینو آن لائن درج ذیل فریق ثالث اور خدمت فراہم کنندگان کا استعمال کرتا ہے (آپ کے رابطوں کی ذاتی معلومات \"ایسے\" تیسرے فریق کو ظاہر نہیں کی جاتی ہیں):",
    "privacy_policy_service_provider_1": "ہم سیلز اور سپورٹ کے لیے ویب سائٹ چیٹ سروسز فراہم کرنے کے ساتھ ساتھ ان بات چیت کے بارے میں فالو اپ ای میلز کے لیے ManyChat کا استعمال کرتے ہیں۔",
    "privacy_policy_service_provider_2": "ہم AskNicely کا استعمال اپنے صارفین سے سروے کرنے کے لیے اس مقصد کے لیے کرتے ہیں کہ وہ سروس کے معیار اور انھیں ملنے والی مدد کے بارے میں پوچھیں۔",
    "privacy_policy_service_provider_3": "ہم گاہک اور ممکنہ کسٹمر میٹنگز، سپورٹ کالز، اور سافٹ ویئر ڈیمو کو شیڈول کرنے کے لیے join.me کا استعمال کرتے ہیں۔",
    "privacy_policy_service_provider_4": "ہم گمنام ویب سائٹ کی سرگرمی کو ٹریک کرنے اور اپنی ویب سائٹ پر استعمال کے نمونوں کی پیمائش کرنے کے لیے Google Analytics کا استعمال کرتے ہیں۔",
    "privacy_policy_breach_notification_title": "خلاف ورزی کی اطلاع",
    "privacy_policy_breach_notification_desc": "مینو آن لائن کسی بھی غیر مجاز استعمال، رسائی یا ذاتی معلومات کے افشاء کے سلسلے میں قابل اطلاق قوانین کی تعمیل میں اکاؤنٹ ہولڈرز کو مناسب مدد فراہم کرے گا۔",
    "privacy_policy_rights_access_title": "آپ کے حقوق اور رسائی",
    "privacy_policy_rights_access_desc": "آپ کو ذاتی معلومات تک رسائی کا حق حاصل ہے جس پر مینیو آن لائن آپ کے بارے میں کارروائی کرتا ہے۔ آپ ہم سے اس بارے میں معلومات طلب کر سکتے ہیں:",
    "privacy_policy_rights_access_list_1": "ذاتی معلومات جو ہم آپ کے بارے میں رکھتے ہیں۔",
    "privacy_policy_rights_access_list_2": "متعلقہ ذاتی معلومات کے زمرے",
    "privacy_policy_rights_access_list_3": "پروسیسنگ کے مقاصد",
    "privacy_policy_rights_access_list_4": "تفصیلات جن کے لیے آپ کی ذاتی معلومات کا انکشاف کیا جائے گا۔",
    "privacy_policy_rights_access_list_5": "ہم کتنی دیر تک آپ کی ذاتی معلومات کو برقرار رکھتے ہیں۔",
    "privacy_policy_rights_access_list_6": "اگر ہم نے براہ راست آپ سے معلومات اکٹھی نہیں کیں تو ماخذ کے بارے میں معلومات",
    "privacy_policy_rights_access_list_7": "صحیح سپروائزری اتھارٹی کے پاس شکایت کیسے درج کی جائے۔",
    "privacy_policy_rights_access_list_8": "آپ ہم سے درج ذیل بھی درخواست کر سکتے ہیں:",
    "privacy_policy_rights_access_list_9": "کہ ہم آپ کے بارے میں کسی بھی نامکمل یا غلط معلومات کو اپ ڈیٹ کرتے ہیں۔",
    "privacy_policy_rights_access_list_10": "درخواست کریں کہ ہم قابل اطلاق قوانین کے مطابق آپ کی ذاتی معلومات کو حذف کر دیں۔",
    "privacy_policy_rights_access_contact": "آپ 2851 Dufferin Street, Toronto, ON, Canada پر ہم سے رابطہ کر کے یا {link} پر ہمیں ای میل کر کے ہم سے اپنے حقوق پر عمل کرنے کی درخواست کر سکتے ہیں۔",
    "privacy_policy_accountability_title": "احتساب",
    "privacy_policy_accountability_desc": "مینو آن لائن آپ کی فراہم کردہ ذاتی اور خفیہ معلومات کے لیے جوابدہ ہونے کے لیے پرعزم ہے۔ رابطہ کی معلومات کے لیے، براہ کرم اس پالیسی کا اختتام دیکھیں۔",
    "privacy_policy_links_other_sites_title": "دیگر سائٹس کے لنکس",
    "privacy_policy_links_other_sites_desc1": "مینو آن لائن کی ویب سائٹ دیگر سائٹس کے لنکس پر مشتمل ہو سکتی ہے۔ براہ کرم آگاہ رہیں کہ مینو آن لائن دیگر سائٹس کے رازداری کے طریقوں کے لیے ذمہ دار نہیں ہے۔ ہم اپنے اکاؤنٹ ہولڈرز کی حوصلہ افزائی کرتے ہیں کہ وہ آگاہ رہیں کہ جب وہ ہماری سائٹ چھوڑتے ہیں، تو انہیں ہر اس ویب سائٹ کے نجی پالیسی بیانات کو اچھی طرح سے پڑھنا چاہیے جو ذاتی طور پر قابل شناخت معلومات جمع کرتی ہے۔",
    "privacy_policy_links_other_sites_desc2": "یہ پرائیویٹ پالیسی صرف اور صرف ہمارے ذریعہ جمع کی گئی معلومات پر لاگو ہوتی ہے۔",
    "privacy_policy_links_other_sites_desc3": "اگر آپ مینو آن لائن سائٹ سے پروڈکٹس یا خدمات خریدنے کے لیے ایک لنک استعمال کرتے ہیں، تو آپ مینو آن لائن کے نہیں بلکہ وینڈر کمپنی کے قواعد و ضوابط کے مطابق خریداری کا لین دین مکمل کر رہے ہیں۔",
    "privacy_policy_cookies_title": "پرائیویٹ پالیسی کوکیز",
    "privacy_policy_cookies_desc1": "مینو آن لائن ویب سائٹ اور خدمات اس پالیسی کے مطابق کچھ معلومات جمع کرنے اور ذخیرہ کرنے کے لیے کوکیز کا استعمال کرتی ہیں۔ ایک 'کوکی' ایک چھوٹی ٹیکسٹ فائل ہے جو کسی ویب سائٹ سے بھیجی جاتی ہے اور صارف کے ویب براؤزر کے ذریعے صارف کے کمپیوٹر پر محفوظ کی جاتی ہے۔ کوکیز استعمال کرنے والی سائٹ پر جانے پر، آپ کے کمپیوٹر یا موبائل ڈیوائس پر ایک کوکی ڈاؤن لوڈ ہو جاتی ہے۔ اگلی بار جب آپ اس سائٹ پر جائیں گے، تو آپ کا آلہ مفید معلومات جیسے ترجیحات، ملاحظہ کیے گئے صفحات یا لاگ ان کرنے کے اختیارات کو یاد رکھے گا۔",
    "privacy_policy_cookies_desc2": "کوکیز کا وسیع پیمانے پر استعمال ویب سائٹس میں فعالیت شامل کرنے، یا یہ یقینی بنانے کے لیے کیا جاتا ہے کہ وہ زیادہ موثر طریقے سے کام کریں۔ ہماری سائٹ صارف کے تجربے کو بہتر بنانے اور سائٹس کی خدمات کو صحیح طریقے سے کام کرنے کو یقینی بنانے کے لیے کوکیز پر انحصار کرتی ہے۔",
    "privacy_policy_cookies_desc3": "زیادہ تر ویب براؤزرز براؤزر کی ترتیبات کے ذریعے کوکیز کو محدود یا بلاک کرنے کے لیے کچھ کنٹرول کی اجازت دیتے ہیں، تاہم اگر آپ کوکیز کو غیر فعال کرتے ہیں تو آپ کو معلوم ہو سکتا ہے کہ یہ ہماری ویب سائٹ یا خدمات کے کچھ حصوں کو استعمال کرنے کی آپ کی صلاحیت کو متاثر کرتا ہے۔",
    "privacy_policy_cookies_analytical_purpose": "<strong> تجزیاتی مقاصد: <\/strong> ہم اپنی ویب سائٹ کو بہتر بنانے کے لیے اکاؤنٹ ہولڈر کی سرگرمی کا تجزیہ کرنے کے لیے کوکیز کا استعمال کرتے ہیں۔ مثال کے طور پر، ہم مجموعی نمونوں کو دیکھنے کے لیے کوکیز کا استعمال کر سکتے ہیں جیسے کہ اکاؤنٹ ہولڈرز کے استعمال کی خصوصیات۔ ہم اپنی ویب سائٹ کی فعالیت اور صارف کے تجربے کو بہتر بنانے کے بارے میں بصیرت حاصل کرنے کے لیے اس طرح کے تجزیے کا استعمال کر سکتے ہیں۔",
    "privacy_policy_cookies_preferences_user_experience": "<strong> آپ کی ترجیحات اور صارف کا تجربہ: <\/strong> ہم مہمانوں اور اکاؤنٹ ہولڈرز کے بارے میں مخصوص معلومات، جیسے کہ براؤزر کی قسم، سرور، زبان کی ترجیح، اور ملک کی ترتیب کو اکٹھا کرنے کے لیے کوکیز کا استعمال کرتے ہیں، تاکہ اکاؤنٹ ہولڈر کے تجربے کو مزید مستقل اور آسان بنانے کے لیے اکاؤنٹ ہولڈر کی ترجیحات کو ہماری ویب سائٹ پر اسٹور کیا جا سکے۔ جب آپ ہماری ویب سائٹ کو بار بار دیکھتے ہیں تو ہم آپ کی لاگ ان حالت کو برقرار رکھنے کے لیے کوکیز کا استعمال کرتے ہیں۔",
    "privacy_policy_cookies_targeted_ads": "<strong> پیمائش کی خدمات اور بہتر ہدف والے اشتہارات، اور مارکیٹنگ فراہم کریں: <\/strong> ہم پیمائش کی خدمات، بہتر ہدف سازی اشتہارات، اور مارکیٹنگ کے مقاصد کے لیے فریق ثالث کے شراکت داروں جیسے Google اور Facebook سے کوکیز، ویب بیکنز اور دیگر اسٹوریج ٹیکنالوجیز استعمال کرتے ہیں۔ یہ اس وقت ہوتا ہے جب آپ ہماری ویب سائٹ پر جاتے ہیں۔ یہ کوکیز، ویب بیکنز، اور دیگر سٹوریج ٹیکنالوجیز ہمیں آپ کو مینو آن لائن پروموشنل مواد کو دوسری سائٹس پر دکھانے کی اجازت دیتی ہیں جن پر آپ انٹرنیٹ پر جاتے ہیں۔",
    "privacy_policy_security_title": "سیکورٹی",
    "privacy_policy_security_desc1": "مینو آن لائن ہر قسم کی خفیہ معلومات کی حفاظت کے لیے کوششیں کرتا ہے، بشمول ذاتی معلومات، معلومات کی حساسیت کے لیے مناسب تنظیمی، جسمانی، مکینیکل اور الیکٹرانک تحفظات کے ساتھ۔ ہمارے سرورز کینیڈا میں واقع ہیں، اور سیکورٹی سے متعلق صنعتی معیارات کی پیروی کرتے ہیں۔",
    "privacy_policy_security_desc2": "تاہم، براہ کرم نوٹ کریں کہ ہم اس بات کی ضمانت نہیں دے سکتے کہ جو اقدامات ہم برقرار رکھتے ہیں وہ معلومات کی حفاظت کی ضمانت دیں گے۔",
    "privacy_policy_retention_title": "برقرار رکھنا",
    "privacy_policy_retention_desc": "ہم اکاؤنٹ ہولڈرز اور رابطوں کے بارے میں ذاتی معلومات کو اس پرائیویٹ پالیسی میں بیان کردہ مقاصد کے لیے اور استعمال کی شرائط میں برقرار رکھنے کے ضوابط کے مطابق اس وقت تک اپنے پاس رکھتے ہیں۔",
    "privacy_policy_access_questions_title": "رسائی، سوالات اور خدشات",
    "privacy_policy_access_questions_desc": "ایک فرد جس نے مینو آن لائن کو ذاتی معلومات جمع کرائی ہیں وہ مینو آن لائن کے ذریعہ اپنی ذاتی معلومات تک رسائی یا اسے درست کرنے کے لیے تحریری درخواست جمع کرا سکتا ہے۔",
    "terms_obj": {
        "terms_conditions_title": "شرائط و ضوابط",
        "terms_conditions_subtitle": "نئے اور موجودہ صارفین کے لیے شرائط و ضوابط",
        "terms_conditions_desc1": "مندرجہ ذیل کلیدی نکات (بشمول سیکشن حوالہ جات) صرف آپ کی سہولت کے لیے فراہم کیے گئے ہیں اور مکمل شرائط و ضوابط کے بدلے میں نہیں ہیں۔ مینو آن لائن سروس استعمال کرنے سے پہلے ان شرائط و ضوابط کو پڑھنا آپ کی ذمہ داری ہے۔",
        "terms_conditions_desc2": "مینو آن لائن استعمال کرنے کا مطلب ہے کہ آپ شرائط و ضوابط سے اتفاق کرتے ہیں اور نمائندگی اور ضمانت دیتے ہیں کہ آپ کو آپ کی تنظیم یا کمپنی ایسا کرنے کے لیے مجاز ہے۔ (دیکھیں s.3 \"یہ کیسے کام کرتا ہے\")",
        "terms_conditions_desc3": "مینو آن لائن ایک اجازت پر مبنی مارکیٹنگ سروس ہے۔ جان بوجھ کر \"سپام\" بھیجنے یا شرائط و ضوابط کی خلاف ورزی کے نتیجے میں آپ کا اکاؤنٹ بند ہو جائے گا۔ (دیکھیں s.16 \"سروس کا قابل قبول استعمال\")",
        "terms_conditions_desc4": "وہ ڈیٹا جسے آپ مینو آن لائن میں میزبانی کرتے ہیں جیسے کہ رابطہ ریکارڈ، ای میل مواد اور معلومات جو آپ Menuonline کے ذریعے جمع کرتے ہیں وہ آپ کا ہے۔ (دیکھیں s.14 \"آپ کی ملکیت کیا ہے؟\")۔",
        "terms_conditions_desc5": "مینو آن لائن سافٹ ویئر اور ہماری ویب سائٹ پر موجود مواد ہمارا ہے۔ (دیکھیں s.13 \"ہم کس چیز کے مالک ہیں؟\")",
        "terms_conditions_desc6": "آپ کے اپنے صارفین کے علاوہ، آپ Menuonline کے ساتھ جس ڈیٹا یا غیر مطبوعہ مواد کی میزبانی کرتے ہیں اسے صرف Menuonline کے ملازمین اور شراکت دار ہی دیکھ سکتے ہیں جنہوں نے رازداری کے معاہدوں پر دستخط کیے ہیں۔ (دیکھیں s.14 \"آپ کی ملکیت کیا ہے؟\")۔",
        "terms_conditions_desc7": "تمام ذاتی طور پر قابل شناخت معلومات (PII) صرف کینیڈا میں واقع سرورز پر رکھی جاتی ہیں۔ (دیکھیں s.14 \"آپ کی ملکیت کیا ہے؟\")۔",
        "terms_conditions_desc8": "مینو آن لائن سروس کینیڈا اور صوبہ اونٹاریو کے قوانین کے تابع ہے۔ (دیکھئے s.24 \"گورننگ لاء\")",
        "terms_conditions_desc9": "کینیڈا کے اندر یا کینیڈا سے باہر افراد، پولیس یا دیگر ریگولیٹری ایجنسیوں کی طرف سے آپ کے ڈیٹا یا مواد کی کوئی بھی درخواست ہمیشہ آپ کو بھیجی جائے گی۔ صرف استثناء ہو گا اگر یہ ہمیں کینیڈا یا اونٹاریو کے قانون کی خلاف ورزی میں ڈالتا ہے۔ تاہم، تمام صورتوں میں ہم آپ کے علاوہ کسی کے ذریعے آپ کے ڈیٹا کے اجراء کی سختی سے مزاحمت کریں گے۔ (دیکھیں s.14 \"آپ کی ملکیت کیا ہے؟\")۔",
        "terms_conditions_desc10": "مینو آن لائن سیکیورٹی صرف اتنی ہی اچھی ہے جتنی کہ آپ کے صارف ناموں اور پاس ورڈز کا انتظام۔ ہم مینو آن لائن صارف اکاؤنٹس کے لیے چارج نہیں کرتے ہیں اس لیے ہر صارف کے لیے ایک علیحدہ اکاؤنٹ بنائیں اور پاس ورڈ خفیہ رکھیں۔ آپ اپنے اکاؤنٹ کے ذمہ دار ہیں۔ (دیکھیں s.3 \"یہ کیسے کام کرتا ہے\")",
        "terms_conditions_desc11": "آپ کسی بھی وقت اپنا اکاؤنٹ منسوخ کر سکتے ہیں۔ اگر آپ منسوخ کرتے ہیں تو ہم کسی غیر استعمال شدہ کریڈٹ پر رقم کی واپسی فراہم نہیں کرتے ہیں۔ (دیکھیں s.3 \"یہ کیسے کام کرتا ہے\")",
        "terms_conditions_desc12": "ہم آپ کا اکاؤنٹ کسی بھی وقت منسوخ کر سکتے ہیں۔ اگر ہم آپ کا اکاؤنٹ منسوخ کر دیتے ہیں تو ہم ای میل کریڈٹس اور غیر استعمال شدہ خدمات کے لیے رقم کی واپسی فراہم کرتے ہیں۔ (دیکھیں s.3 \"یہ کیسے کام کرتا ہے\")",
        "terms_conditions_desc13": "آپ کا ڈیٹا منسوخی کے بعد حذف کر دیا جائے گا اور اگر کسی اکاؤنٹ صارف نے 12 ماہ یا اس سے زیادہ عرصے سے لاگ ان نہیں کیا ہے تو اسے بھی حذف کیا جا سکتا ہے۔ ہم آپ کے اکاؤنٹ سے مجموعی ڈیٹا کو برقرار رکھیں گے جس میں شماریاتی مقاصد کے لیے ذاتی طور پر قابل شناخت معلومات شامل نہیں ہیں۔ (دیکھیں s.3 \"یہ کیسے کام کرتا ہے\")",
        "terms_conditions_desc14": "ای میل کریڈٹس بطور ادائیگی کی بنیاد پر خریدے جاتے ہیں اور کبھی ختم نہیں ہوتے۔ صرف استثناء ہے اگر آپ نے اپنے اکاؤنٹ میں 12 ماہ سے لاگ ان نہیں کیا ہے۔ اس صورت میں، ہم آپ کے اکاؤنٹ، آپ کے ڈیٹا اور متعلقہ کریڈٹس کو حذف کرنے کا حق محفوظ رکھتے ہیں۔ (دیکھیں s.10 \"میں سروس کے لیے کیا ادائیگی کروں؟\")",
        "terms_conditions_desc15": "ہم اس بات کو یقینی بنانے کے لیے سخت محنت کرتے ہیں کہ مینو آن لائن ہر وقت چل رہا ہے اور اگر ہمیں بحالی کے لیے سروس کو عارضی طور پر معطل کرنے کی ضرورت ہو تو پیشگی اطلاع فراہم کریں۔ تاہم، ہم اس بات کی ضمانت نہیں دیتے کہ سروس ہر وقت چلتی رہے گی اور مینو آن لائن استعمال کرنے سے آپ کو ہونے والے نقصانات کے لیے ہم ذمہ دار نہیں ہیں، بشمول آپ کی طرف سے ہونے والے نقصانات اگر سسٹم آپ کی ضرورت کے وقت نہیں چل رہا ہے۔ (دیکھیں s.17 \"اعلانات\")۔",
        "terms_conditions_desc16": "ہم آپ کے مواد یا آپ کس کو پیغام بھیجتے ہیں اس کی نگرانی نہیں کرتے ہیں۔ تاہم، ہم آپ کی سرگرمیوں سے پیدا ہونے والی شکایات اور دیگر مسائل کی نگرانی کرتے ہیں۔ بہت ساری شکایات یا مسائل کا نتیجہ ہماری صوابدید پر آپ کی سروس کو منسوخ کر سکتا ہے۔ (دیکھیں s.7 \"مانیٹرنگ\")",
        "terms_conditions_desc17": "مینو آن لائن کے ساتھ آپ کو خود کفیل بننے میں مدد کے لیے ہم بہترین پروڈکٹ سپورٹ فراہم کرنے کے لیے پرعزم ہیں۔ ہم اپنی ویب سائٹ، مینو آن لائن سپورٹ ٹکٹ سسٹم اور ٹیلی فون کے ذریعے دستاویزات اور ویڈیو کی شکل میں مدد فراہم کرتے ہیں۔ پروڈکٹ سپورٹ کے لیے کوئی چارج نہیں ہے۔ تاہم، ہم اپنی صوابدید پر مخصوص کلائنٹ اکاؤنٹس کے لیے سپورٹ بند کرنے کا حق محفوظ رکھتے ہیں۔ (دیکھیں s.5 \"سپورٹ\")",
        "terms_conditions_desc18": "پروڈکٹ سروسز ان اوقات کے لیے ہیں جب آپ چاہتے ہیں کہ ہم مینو آن لائن سے متعلق کچھ کام خود کرنے کی بجائے کریں۔ مصنوعات کی خدمات کے لیے ہماری شرح $135 فی گھنٹہ ہے۔ ہم اعلیٰ نرخوں پر مشاورتی خدمات بھی فراہم کرتے ہیں۔ ہم کسی بھی وقت اپنے نرخ تبدیل کرنے کا حق محفوظ رکھتے ہیں۔ (دیکھیں s.6 \"پروڈکٹ اور مشاورتی خدمات\")",
        "terms_conditions_desc19": "اگر ہمیں یقین ہے کہ آپ کے ڈیٹا کی خلاف ورزی ہوئی ہے تو ہم آپ کو مطلع کریں گے اور متعلقہ تفصیلات اور معلومات فراہم کرنے کی کوشش کریں گے۔ (دیکھیں s.14 \"آپ کے پاس کیا ہے؟\")",
        "terms_conditions_desc20": "ہم کسی بھی وقت اس معاہدے میں تبدیلیاں کرنے کا حق محفوظ رکھتے ہیں۔ ہم آپ کو شرائط و ضوابط میں تبدیلیوں کے بارے میں مطلع کرنے کی کوشش کریں گے لیکن سروس کے استعمال کا مطلب ہے کہ آپ شرائط میں تبدیلیوں سے اتفاق کرتے ہیں۔ (s.9 \"تبدیلیاں\" دیکھیں)۔",
        "terms_conditions_desc21": "شرائط و ضوابط اور ہماری رازداری کی پالیسی کا مسودہ صرف انگریزی میں تیار کیا گیا ہے، اور آپ شرائط و ضوابط کے انگریزی زبان کے ورژن سے اتفاق کرتے ہیں اور رازداری کی پالیسی کسی بھی ترجمہ شدہ ورژن کے ساتھ کسی تنازعہ کی صورت میں ترجیح دی جائے گی۔",
        "terms_conditions_mycircle": "MYCIRCLE شرائط و ضوابط",
        "terms_conditions_last_update": "آخری اپ ڈیٹ: جنوری 14، 2020",
        "terms_conditions_usage_agreement": "براہ کرم اس ویب سائٹ ('سائٹ') کو استعمال کرنے سے پہلے ان شرائط و ضوابط ('شرائط') کو احتیاط سے پڑھیں۔ یہ شرائط مینو آن لائن ('مینو آن لائن'، 'ہم'، یا 'ہم') اور دوسروں کو ذمہ داری سے مستثنیٰ کرتی ہیں اور\/یا ہماری اور ان کی ذمہ داری کو محدود کرتی ہیں اور دیگر اہم دفعات پر مشتمل ہوتی ہیں جو اس سائٹ کے آپ کے استعمال پر لاگو ہوتی ہیں۔",
        "terms_conditions_acceptance": "اس سائٹ یا سروس پر جا کر یا استعمال کر کے، آپ اپنی طرف سے، اور کسی بھی تنظیم کی طرف سے جس کی طرف سے آپ کام کر سکتے ہیں (اجتماعی طور پر یہاں 'آپ' کے طور پر حوالہ دیا گیا ہے)، اس سائٹ کے ہر استعمال اور ہر دورے کے لیے ان شرائط کو قبول کرنے اور ان کی پابندی کرنے سے اتفاق کرتے ہیں۔",
        "terms_conditions_services": "1. ہماری خدمات۔",
        "terms_conditions_services_desc": "وہ خدمات جو ہم سائٹ کے ذریعے پیش کرتے ہیں وہ ایک خدمت ہے جو آپ کو اپنی پسند کے وصول کنندگان ('سروس') کو ای میل پیغامات ('ای میلز') بنانے، بھیجنے اور ان کا نظم کرنے کی اجازت دیتی ہے۔",
        "terms_conditions_license": "2. لائسنس۔",
        "terms_conditions_license_desc": "ان شرائط کے تابع، مینو آن لائن آپ کو مدت کے دوران ایک غیر خصوصی، محدود، ناقابل منتقلی لائسنس فراہم کرتا ہے تاکہ وہ سائٹ کو دیکھیں اور سروس کی خصوصیات کے مطابق سروس استعمال کریں۔ ہم وہ تمام حقوق محفوظ رکھتے ہیں جو یہاں سروس اور مینو آن لائن مواد میں واضح طور پر نہیں دیئے گئے ہیں (جیسا کہ ذیل میں بیان کیا گیا ہے)۔",
        "terms_conditions_account": "3. یہ کیسے کام کرتا ہے۔",
        "terms_conditions_account_desc": "سروس کو استعمال کرنے کے لیے آپ کو ایک اکاؤنٹ کے لیے رجسٹر کرنا ہوگا۔ آپ کا اکاؤنٹ مفت ہے۔ آپ کا اکاؤنٹ آپ کو سروس اور \"فنکشنز\" تک رسائی فراہم کرتا ہے جسے ہم وقتاً فوقتاً قائم اور برقرار رکھ سکتے ہیں۔ اکاؤنٹ کے اہل ہونے کے لیے آپ کو:",
        "terms_conditions_account_requirements": [
            "کم از کم اٹھارہ (18) سال کی عمر ہو اور معاہدے کرنے کے قابل ہو؛",
            "رجسٹریشن کے عمل کو مکمل کریں جو ہم نے ترتیب دیا ہے؛",
            "ان شرائط و ضوابط سے اتفاق کرتے ہیں؛",
            "درست، مکمل، اور تازہ ترین رابطہ اور بلنگ\/ادائیگی کی معلومات فراہم کریں۔"
        ],
        "responsibility": "ذمہ داری۔",
        "responsibilityText": "آپ اپنے اکاؤنٹ پر ہونے والی سرگرمی کے مکمل طور پر ذمہ دار ہیں، اور آپ کو اپنے اکاؤنٹ کا پاس ورڈ خفیہ اور محفوظ رکھنا چاہیے۔ اکاؤنٹس مفت ہیں، اس لیے ہم آپ کی حوصلہ افزائی کرتے ہیں کہ آپ کے پاس موجود ہر صارف کے لیے ایک مختلف اکاؤنٹ ترتیب دیں۔ آپ کسی بھی اکاؤنٹ کے ذمہ دار ہیں جس تک آپ کی رسائی ہے، چاہے آپ نے اس اکاؤنٹ کے استعمال کی اجازت دی ہو یا نہ ہو۔ آپ اس بات سے اتفاق کرتے ہیں کہ جن لوگوں کو آپ ای میلز بھیجتے ہیں ان کے ساتھ آپ کے تعامل کے مکمل طور پر ذمہ دار ہیں، اور آپ صرف ان لوگوں کو ای میل بھیجیں گے جن سے آپ کی رضامندی ہے۔ آپ کو اپنے اکاؤنٹس کے کسی بھی غیر مجاز استعمال کے بارے میں ہمیں فوری طور پر مطلع کرنا چاہیے۔",
        "compliance_with_CASL": "کینیڈین اینٹی سپیم قانون سازی (CASL) کی تعمیل۔",
        "compliance_with_CASL_text": "مینو آن لائن کا کردار رضامندی کی ترتیبات کے انتظام کو آسان بنانا ہے، قانون سازی کو نافذ کرنا نہیں۔ CASL کے ساتھ تعمیل کرنا آپ کی ذمہ داری ہے۔ ہم پہلے سے طے شدہ فعالیت کو ترتیب دے کر اور دستاویزی ایکسپریس رضامندی حاصل کرنے اور اپ ڈیٹ کرنے میں سہولت فراہم کرکے اور مضمر رضامندی کے لیے رولنگ ختم ہونے کی تاریخوں کا انتظام کرکے آپ کی مدد کرتے ہیں۔ آپ اپنے ڈیٹا بیس کے لیے رضامندی کے انتظام کے مکمل کنٹرول میں ہیں؛ آپ کسی بھی وقت اپنے تمام رابطوں کے لیے رضامندی کی حیثیت کو بڑی تعداد میں اپ ڈیٹ کر سکتے ہیں، پہلے سے طے شدہ ترتیبات کو اوور رائیڈ کر سکتے ہیں اور رضامندی کی شرط کی خودکار مدت ختم ہونے کو غیر فعال کر سکتے ہیں۔",
        "closing_account": "آپ کا اکاؤنٹ بند کرنا۔",
        "closing_account_text": "یا تو آپ یا مینو آن لائن کسی بھی وقت اور کسی بھی وجہ سے دوسرے فریق کو نوٹس دے کر اس معاہدے کو ختم کر سکتے ہیں۔ اگر آپ کوئی ایسی معلومات فراہم کرتے ہیں جو غلط، غلط، موجودہ یا نامکمل نہیں ہے یا سروس کو اس طرح استعمال کرتے ہیں جس سے ان شرائط و ضوابط کی خلاف ورزی ہوتی ہو، تو ہمیں آپ کے اکاؤنٹ کو فوری طور پر معطل کرنے اور سروس تک آپ کی رسائی کو ختم کرنے کا حق ہے اور ہمارے پاس دستیاب دیگر علاج کے علاوہ۔ اگر آپ بارہ (12) ماہ تک اپنا اکاؤنٹ استعمال نہیں کرتے ہیں، تو ہم آپ کا اکاؤنٹ منسوخ کر سکتے ہیں۔ اگر ہم آپ کا اکاؤنٹ کسی بھی وجہ سے ختم کر دیتے ہیں، تو ہم آپ کو آپ کے چھوڑے گئے کسی بھی کریڈٹ کے لیے واپس کر دیں گے۔ ایک بار ختم ہونے کے بعد، ہم آپ کا اکاؤنٹ اور اس سے وابستہ تمام ڈیٹا کو مستقل طور پر حذف کر دیں گے، بشمول آپ کی بھیجی گئی ای میلز۔ ہم آپ کے اکاؤنٹ سے مجموعی ڈیٹا کو برقرار رکھ سکتے ہیں جس میں شماریاتی مقاصد کے لیے ذاتی طور پر قابل شناخت معلومات شامل نہیں ہیں۔",
        "emailing_content": "ای میل کرنے والا مواد۔",
        "emailing_content_text": "اس سائٹ یا سروس کے ذریعے مینو آن لائن کے ساتھ بات چیت کرتے وقت، آپ صحیح اور درست معلومات فراہم کرنے سے اتفاق کرتے ہیں۔ مزید یہ کہ آپ سمجھتے ہیں کہ ای میل کے ذریعے خفیہ معلومات فراہم کرنا ایک غیر محفوظ عمل ہے۔ آپ سمجھتے ہیں کہ آپ ہماری سروس استعمال کرنے والے کسی کو بھیجنے والے کسی بھی چیز کے مکمل طور پر ذمہ دار ہیں۔ مقاصد",
        "support": "حمایت.",
        "support_text": "ہم آپ کو سروس کے ساتھ خود کفیل بننے میں مدد کے لیے مدد فراہم کرتے ہیں۔ ہم آپ کو سائٹ پر دستاویزات اور ویڈیوز کی شکل میں، مینو آن لائن سپورٹ ٹکٹ سسٹم اور ٹیلی فون کے ذریعے مدد فراہم کرتے ہیں۔ آپ سائٹ کے اوپری حصے میں \"سپورٹ\" ٹیب پر کلک کر کے ہم تک پہنچ سکتے ہیں۔ پروڈکٹ سپورٹ کے لیے کوئی چارج نہیں ہے۔ تاہم، ہم اپنی صوابدید پر مخصوص کلائنٹ اکاؤنٹس کے لیے سپورٹ بند کرنے کا حق محفوظ رکھتے ہیں۔",
        "product_and_consulting_services": "مصنوعات اور مشاورتی خدمات۔",
        "product_and_consulting_services_text": "پروڈکٹ سروسز ان اوقات کے لیے ہیں جب آپ چاہتے ہیں کہ ہم مینو آن لائن سے متعلق کچھ کام خود کرنے کی بجائے کریں۔ مصنوعات کی خدمات کے لیے ہماری شرح $125 فی گھنٹہ ہے۔ ہم اعلیٰ نرخوں پر مشاورتی خدمات بھی فراہم کرتے ہیں۔ ہم کسی بھی وقت اپنے نرخ تبدیل کرنے کا حق محفوظ رکھتے ہیں۔",
        "monitoring": "نگرانی۔",
        "monitoring_text": "ہم سروس پر منتقل ہونے والی معلومات کے مواد کی نگرانی یا فیصلہ نہیں کریں گے، لیکن اگر ہمیں ممکنہ نامناسب استعمال کی شکایات موصول ہوتی ہیں تو ہم تحقیقات کریں گے۔ مینو آن لائن، کسی بھی وقت اور اپنی صوابدید پر، درج ذیل رہنما خطوط کے مطابق، آپ کے لیے کسی اطلاع کے ساتھ یا اس کے بغیر یہ تعین کر سکتا ہے کہ مخصوص استعمالات مناسب ہیں یا نہیں ہیں۔",
        "term": "مدت",
        "term_text": "اصطلاح اس وقت شروع ہوتی ہے جب آپ ہمارے ساتھ کسی اکاؤنٹ کے لیے سائن اپ کرتے ہیں اور جب تک آپ سروس استعمال کرتے ہیں جاری رہے گی۔ آپ نمائندگی کرتے ہیں اور ضمانت دیتے ہیں کہ آپ کے پاس ہمارے لیے اکاؤنٹ کے لیے سائن اپ کرنے کا اختیار اور اہلیت ہے (بشمول اگر آپ کسی تنظیم کی جانب سے سائن اپ کرتے ہیں)۔",
        "changes": "تبدیلیاں",
        "changes_text": "اصطلاح اس وقت شروع ہوتی ہے جب آپ ہمارے ساتھ کسی اکاؤنٹ کے لیے سائن اپ کرتے ہیں اور جب تک آپ سروس استعمال کرتے ہیں جاری رہے گی۔ آپ نمائندگی کرتے ہیں اور ضمانت دیتے ہیں کہ آپ کے پاس ہمارے لیے اکاؤنٹ کے لیے سائن اپ کرنے کا اختیار اور اہلیت ہے (بشمول اگر آپ کسی تنظیم کی جانب سے سائن اپ کرتے ہیں)۔",
        "payment": "ادائیگی",
        "what_do_i_pay_for_service": "میں سروس کے لیے کیا ادائیگی کروں؟",
        "what_do_i_pay_for_service_text": "آپ ہماری سروس کے ذریعے ای میل (\"کریڈٹس\") بھیجنے کے لیے پری پیڈ کریڈٹ خرید سکتے ہیں، جیسا کہ ہم اپنی سائٹ کے \"قیمتوں کا تعین\" صفحہ پر تفصیل سے بیان کرتے ہیں۔ کریڈٹ کبھی ختم نہیں ہوتے ہیں اور ان کریڈٹس کے لیے کوئی اعادی فیس نہیں ہے۔ صرف استثناء یہ ہے کہ اگر آپ بارہ (12) مہینوں تک اپنا اکاؤنٹ استعمال نہیں کرتے ہیں، تو ہم آپ کا اکاؤنٹ اور کوئی بھی غیر استعمال شدہ کریڈٹ منسوخ کر سکتے ہیں۔ ہم اپنی سائٹ کے قیمتوں کے صفحات کو اپ ڈیٹ کر کے کسی بھی وقت اپنی قیمتوں کو تبدیل کر سکتے ہیں، جس کا اطلاق آپ کی کریڈٹ کی اگلی خریداری پر ہو گا۔",
        "how_do_i_pay_for_service": "میں سروس کی ادائیگی کیسے کروں؟",
        "how_do_i_pay_for_service_text": "آپ کسی بھی بڑے کریڈٹ کارڈ کا استعمال کرکے کریڈٹس کی ادائیگی کر سکتے ہیں۔ ہم کسی بھی وقت تبدیل کر سکتے ہیں کہ ہم ادائیگی کے کون سے طریقے قبول کرتے ہیں۔",
        "can_i_get_refund": "کیا میں رقم کی واپسی حاصل کر سکتا ہوں؟",
        "can_i_get_refund_text": "اگر آپ اپنا اکاؤنٹ منسوخ کر دیتے ہیں تو آپ نے سروس استعمال کرنے کے لیے جو رقوم ادا کی ہیں وہ ناقابل واپسی ہیں۔ اگر ہم آپ کا اکاؤنٹ منسوخ کر دیتے ہیں، تو آپ کے چھوڑے گئے کسی بھی کریڈٹ کے لیے ہم آپ کو واپس کر دیں گے۔",
        "rights": "حقوق",
        "what_do_we_own": "ہم کیا مالک ہیں؟",
        "what_do_we_own_text": "سوائے اس مواد کے جو آپ ہمیں فراہم کرتے ہیں (بشمول، بغیر کسی حد کے، رابطے کے ریکارڈ، ای میل کا مواد اور معلومات جو آپ سروس کے ذریعے جمع کرتے ہیں) (\"آپ کا مواد\")، ہماری سائٹ پر شامل تمام مواد، سروس اور اس میں موجود یا اس کے ذریعے منتقل کردہ تمام مواد، بشمول بغیر کسی حد کے، سافٹ ویئر، تصاویر، متن، گرافکس، عکاسی، لوگو، پیٹنٹس، آڈیو مارکس، ویڈیو مارکس، ویڈیو مارکس، ٹریڈ مارک سروس۔ دوسرے صارفین سے تعلق رکھنے والا (\"Menuonline Content\") (اور اس سے متعلق تمام دانشورانہ املاک کے حقوق) ہماری یا ہمارے لائسنس دہندگان کی ملکیت ہیں۔",
        "what_do_you_own": "آپ کی ملکیت کیا ہے؟",
        "what_do_you_own_text": "ہم آپ کے مواد پر ملکیت کے حقوق کا دعوی نہیں کرتے ہیں۔ آپ کا مواد آپ کا ہے اور آپ اپنے مواد کی ملکیت برقرار رکھتے ہیں جسے آپ سروس پر اپ لوڈ کرتے ہیں۔ سروس کے ذریعے اپنے مواد کا اشتراک کرکے، آپ دوسروں کو اپنی ترتیبات اور ان شرائط و ضوابط کے مطابق اپنے مواد کو دیکھنے، اس میں ترمیم کرنے، اور\/یا اشتراک کرنے کی اجازت دینے سے اتفاق کرتے ہیں۔ آپ نمائندگی کرتے ہیں اور ضمانت دیتے ہیں کہ یا تو آپ کے پاس ہے یا آپ کے پاس وہ تمام مواد استعمال کرنے کی اجازت ہے جو آپ اپنی ای میلز میں استعمال کرتے ہیں۔ ہم آپ کے مواد کو صرف اسی طرح استعمال یا ظاہر کر سکتے ہیں جیسا کہ ہم ان شرائط اور ہماری رازداری کی پالیسی میں بیان کرتے ہیں۔",
        "privacy_statement": "مینو آن لائن اپنے صارفین کی رازداری کا احترام کرتا ہے۔ کوئی بھی ذاتی معلومات جو مینو آن لائن اس سائٹ یا سروس کے ذریعے جمع کرتی ہے وہ مینو آن لائن پرائیویسی سٹیٹمنٹ سے مشروط ہے جو ان شرائط کے حوالے سے شامل کی گئی ہے۔ ہم اپنے رازداری کے بیان کے مطابق آپ کی معلومات کا استعمال اور انکشاف کر سکتے ہیں۔",
        "access_to_your_content": "Menuonline کسی کو آپ کے مواد تک رسائی فراہم نہیں کرے گا سوائے Menuonline کے ان ملازمین اور ٹھیکیداروں کے جنہوں نے ہمارے ساتھ رازداری کے مناسب معاہدے کیے ہیں یا ہمیں آپ کی رضامندی حاصل ہے۔",
        "governmentRequest": "اگر ہمیں حکومت یا ریگولیٹری اتھارٹی کی طرف سے آپ کے مواد تک رسائی کی درخواست موصول ہوتی ہے، تو ہم آپ کو اس درخواست کے بارے میں مطلع کریں گے اور آپ کے کسی بھی مواد کو جاری کرنے سے پہلے آپ کی رضامندی حاصل کریں گے۔ ہم آپ کے مواد کے لیے کسی بھی درخواست کی مزاحمت کریں گے اور اس قسم کی درخواست کے جواب میں آپ کی اجازت کے بغیر یا مجاز دائرہ اختیار کی عدالت یا قابل اطلاق ریگولیٹری باڈی کے ذریعہ آپ کے مواد کو جاری نہیں کریں گے۔",
        "breachNotification": "ایسی صورت میں جب ہمیں معلوم ہو جاتا ہے کہ آپ کا مواد ان شرائط کی خلاف ورزی میں غیر مجاز رسائی، استعمال، کاپی، چوری، نقصان، یا استعمال یا افشاء سے مشروط ہوا ہے، ہم آپ کو فوری طور پر مطلع کریں گے اور آپ کو مطلع کریں گے کہ کیا ہوا ہے۔ آپ اتفاق کرتے ہیں کہ خلاف ورزیوں کو قابل اطلاق قانون کے مطابق ہینڈل کیا جائے گا، اور اگر ہمیں آپ کو اپنی ای میل کی تقسیم کی فہرستوں کے اراکین کو مطلع کرنے کی ضرورت ہے تو آپ فوری طور پر اور ہماری ہدایت کے مطابق ایسا کرنے سے اتفاق کریں گے۔",
        "contentRetention": "ہم کینیڈا میں آپ کے مواد کو برقرار رکھیں گے اور استعمال کریں گے اور منتقل کریں گے سوائے آپ کی پیشگی تحریری منظوری یا ہدایت کے (مثال کے طور پر، اگر آپ اپنا مواد کینیڈا سے باہر کے افراد کو بھیجنے کا انتخاب کرتے ہیں)۔ سروس فراہم کرنے کے لیے استعمال ہونے والے سرورز کینیڈا میں واقع ہیں۔",
        "accessTrouble": "اگر آپ کو اپنے مواد تک رسائی حاصل کرنے میں دشواری پیش آتی ہے، تو مینو آن لائن آپ کو وقت اور مواد کی بنیاد پر آپ کے مواد کی بازیافت میں مدد کرے گا۔",
        "reportingAbuse": "اگر آپ کو یقین ہے کہ کوئی بھی ان شرائط میں سے کسی کی خلاف ورزی کر رہا ہے، تو براہ کرم ہمیں فوری طور پر مطلع کریں۔ اگر آپ کو ایسے سپیم موصول ہوئے ہیں جو آپ کے خیال میں سروس کے کسی دوسرے صارف کی طرف سے آئے ہیں، اگر آپ کو لگتا ہے کہ سروس کے کسی دوسرے صارف نے ایسا مواد شائع کیا ہے جس سے کسی کاپی رائٹس کی خلاف ورزی ہوتی ہے، یا اگر آپ کو لگتا ہے کہ سروس کا دوسرا صارف ان شرائط کی خلاف ورزی کر رہا ہے تو براہ کرم ہم سے Support@menuonline.com پر رابطہ کریں۔",
        "acceptableUse": "آپ نمائندگی کرتے ہیں اور ضمانت دیتے ہیں کہ آپ کی خدمت کا استعمال تمام قابل اطلاق قوانین اور ضوابط کی تعمیل کرے گا۔ آپ اس بات سے اتفاق کرتے ہیں کہ آپ اس بات کا تعین کرنے کے ذمہ دار ہیں کہ آیا سروس اس کی تعمیل کرے گی اور آپ کے دائرہ اختیار میں استعمال کرنے کے لیے آپ کے لیے موزوں ہے۔ زیادہ یقین کے لیے، آپ اس بات سے اتفاق کرتے ہیں کہ آپ (اور اس بات کو یقینی بنانے سے اتفاق کرتے ہیں کہ آپ کے ملازمین، ایجنٹس اور مجاز صارفین کریں گے)...",
        "exportLaws": "ہم اس بات کی کوئی نمائندگی نہیں کرتے کہ سائٹ یا سروس کینیڈا سے باہر استعمال کے لیے موزوں یا دستیاب ہے، اور ان علاقوں سے ان تک رسائی جہاں ان کے مواد غیر قانونی ہیں ممنوع ہیں۔ آپ اس سائٹ پر مینو آن لائن مواد یا دیگر مواد کو استعمال یا برآمد یا دوبارہ برآمد نہیں کر سکتے ہیں یا کسی بھی قابل اطلاق قوانین یا ضوابط کی خلاف ورزی کرتے ہوئے کسی بھی کاپی یا موافقت بشمول کینیڈا اور امریکی برآمدی قوانین اور ضوابط کو بغیر کسی پابندی کے۔",
        "disclaimers": "سروس اور اس سائٹ کو کسی بھی قسم کی نمائندگی، وارنٹی، یا شرائط کے بغیر 'جیسا ہے' فراہم کیا جاتا ہے، اور مینو آن لائن واضح طور پر دستبرداری کرتا ہے، قابل اطلاق قوانین، تمام نمائندگیوں، وارنٹیوں، اور شرائط کے ذریعے مکمل طور پر قابل اجازت، ظاہر یا مضمر، قانون کے عمل کے ذریعے یا دوسری صورت میں، بشمول بغیر کسی حد کے کسی بھی مضمر وارنٹی یا شرائط، کسی خاص مقصد کے لیے مناسب استعمال کی ضمانت یا شرائط۔ تیسرے فریق کے حقوق کی خلاف ورزی نہ کرنا...",
        "limitLiability": "اس معاہدے کے تحت مینو آن لائن کی مجموعی ذمہ داری، خواہ معاہدے میں ہو یا ٹارٹ (بشمول غفلت)، وارنٹی کی خلاف ورزی کے نتیجے میں، سخت ذمہ داری یا ذمہ داری کے کسی بھی دوسرے نظریہ کے تحت، براہ راست نقصانات تک محدود ہو گی جو ان شرائط و ضوابط کے تحت مینو آن لائن کو ادا کی گئی کل فیس سے زیادہ نہیں ہو گی جو کہ حالیہ مہینوں میں فوری طور پر 12 ماہ میں ذمہ داری کو جنم دینے والا واقعہ پیش آیا۔",
        "liabilityExclusions": "مینو آن لائن، اس کے ملحقہ یا ان کے متعلقہ ڈائریکٹرز، افسران، ملازمین، ایجنٹوں یا دیگر نمائندوں (اجتماعی طور پر 'Menuonline \"نمائندے\") یا کسی بھی منسلک نیٹ ورک کے آپریٹرز کی اس سائٹ یا سروس کے سلسلے میں کوئی ذمہ داری یا ذمہ داری نہیں ہوگی: (i) کوئی بالواسطہ، نتیجہ خیز، حادثاتی، خصوصی نقصان؛ یا (ii) کسی بھی نقصان کے لیے، چاہے براہِ راست، بالواسطہ، نتیجہ خیز، واقعاتی، مثالی، تعزیری یا خصوصی، کھوئی ہوئی آمدنی، کھوئی ہوئی بچت یا محصول یا کھوئے ہوئے منافع کے طور پر۔",
        "indemnity": "آپ مینو آن لائن اور\/یا مینو آن لائن نمائندوں ('مینو آن لائن معاوضہ یافتہ فریقین') کو فریق ثالث کی طرف سے لائے گئے کسی بھی دعوے سے اور اس کے خلاف بے ضرر رہیں گے، اور اس سائٹ سے حاصل کردہ معلومات کے آپ کے استعمال یا سروس کے آپ کے استعمال سے پیدا ہونے والے کسی بھی جرمانے یا جرمانے کی وجہ سے، آپ کو معاوضہ دیں گے۔",
        "ipInfringement": "مینو آن لائن آپ کو کسی بھی اور تمام دعووں، مطالبات، کارروائیوں، مقدموں یا کارروائیوں سے اور آپ کے خلاف کسی فریق ثالث کی طرف سے کیے گئے یا لائے جانے سے اور اس کے خلاف آپ کا دفاع کرے گا اور آپ کو نقصان دہ رکھے گا جس میں الزام لگایا گیا ہے کہ اس معاہدے کے مطابق سروس تک آپ کی رسائی یا استعمال کسی تیسرے فریق کے کینیڈا کے قانون کے تحت دانشورانہ املاک کے حقوق کی خلاف ورزی کرتا ہے۔",
        "equitableRelief": "اگر آپ ان شرائط کی خلاف ورزی کرتے ہیں تو ہم حکم امتناعی ریلیف (یعنی ہم آپ کو روکنے کے لیے عدالتی حکم کی درخواست کر سکتے ہیں) یا دیگر منصفانہ ریلیف حاصل کر سکتے ہیں۔ آپ اس بات سے اتفاق کرتے ہیں کہ یہ قانون یا مساوات میں ہمارے پاس موجود کسی بھی دوسرے حقوق کے علاوہ اور اس کے بغیر ہوگا۔",
        "linksToOtherSites": "اگر ہم تھرڈ پارٹی ویب سائیٹس کو کوئی لنک فراہم کرتے ہیں تو وہ صرف سہولت کے لیے فراہم کیے جاتے ہیں۔ اگر آپ ان لنکس کو استعمال کرتے ہیں، تو آپ ہماری سائٹ چھوڑ دیں گے۔ ہم ان تھرڈ پارٹی سائٹس کو کنٹرول نہیں کرتے اور نہ ہی ان کی توثیق کرتے ہیں۔ آپ اس بات سے اتفاق کرتے ہیں کہ ہم ان تیسری پارٹی کی کسی بھی سائٹ یا ان کے مواد کے ذمہ دار نہیں ہیں...",
        "thirdPartyRequests": "اگر ہمیں آپ کے اکاؤنٹ یا سروس کے آپ کے استعمال سے متعلق کسی قانونی یا حکومتی درخواست کے جواب میں معلومات فراہم کرنی ہیں، تو آپ اس بات سے اتفاق کرتے ہیں کہ ہم آپ سے اپنے اخراجات وصول کر سکتے ہیں۔ ان فیسوں میں ہماری قانونی فیس اور درخواست کے جواب کی تیاری میں ہمارے ملازم کے وقت کے اخراجات شامل ہو سکتے ہیں۔",
        "assignment": "یہ معاہدہ، اور اس کے تحت دیے گئے کوئی بھی حقوق اور لائسنس آپ کے ذریعے منتقل یا تفویض نہیں کیے جا سکتے ہیں، لیکن آپ کو معقول پیشگی اطلاع پر، مینو آن لائن کے ذریعے بغیر کسی پابندی کے تفویض کیا جا سکتا ہے۔",
        "governingLaw": "یہ شرائط قانون کی شقوں کے تصادم کی پرواہ کیے بغیر تمام قابل اطلاق وفاقی قوانین اور اونٹاریو کے قوانین کے مطابق ہوں گی اور ان کی تشکیل کی جائے گی۔ آپ ان شرائط سے متعلق کسی بھی تنازعہ کے تعین کے لیے اونٹاریو کی عدالتوں کے خصوصی دائرہ اختیار میں جمع کرانے سے اتفاق کرتے ہیں۔",
        "severability": "اگر کسی بھی وجہ سے مجاز دائرہ اختیار کی عدالت کو ان شرائط کی کوئی شق یا اس کے حصے کو غیر قانونی یا کسی بھی وجہ سے ناقابل نفاذ پایا جاتا ہے، تو اس شق کو ان شرائط کے ارادے پر اثر انداز ہونے کے لیے زیادہ سے زیادہ قابل اجازت حد تک نافذ کیا جائے گا، اور ان شرائط کی بقیہ پوری قوت اور اثر کے ساتھ جاری رہے گی اور قابل نفاذ رہے گی۔",
        "waiver": "ان شرائط کی کوئی بھی چھوٹ، یا ان سے رخصت ہونے کی رضامندی صرف اس صورت میں موثر ہوگی جب یہ تحریری طور پر ہو اور ہمارے دستخط شدہ ہوں، اور صرف مخصوص مثال میں اور اس مخصوص مقصد کے لیے جس کے لیے یہ دیا گیا ہے۔ ورزش کرنے میں ہماری ناکامی یا مشق میں ہماری تاخیر، ان شرائط کے تحت کوئی بھی حق ہمارے حقوق کی چھوٹ کے طور پر کام نہیں کرے گا۔",
        "furtherAssurances": "ہر فریق تمام دستاویزات پر دستخط کرنے اور فراہم کرنے اور ان شرائط و ضوابط کے تحت اپنی ذمہ داریوں کو پورا کرنے کے لیے ضروری اقدامات کرنے (یا ان کارروائیوں کو انجام دینے کا سبب بننے) سے اتفاق کرتا ہے۔",
        "notices": "مینو آن لائن کو تمام نوٹس تحریری طور پر اور ہمیں ای میل کے ذریعے بھیجے جانے چاہئیں۔ ہمیں نوٹس ہمارے کسٹمر سروس کے نمائندوں کی توجہ کے لیے support@menuonline.com پر بھیجے جائیں۔ آپ کو نوٹس آپ کے رجسٹریشن کے حصے کے طور پر آپ کی طرف سے فراہم کردہ ایڈریس یا ای میل ایڈریس پر بھیجے جا سکتے ہیں۔ اس کے علاوہ، ہم اس سائٹ کے ذریعے نوٹس یا پیغامات نشر کر سکتے ہیں تاکہ آپ کو اس سائٹ میں ہونے والی تبدیلیوں یا دیگر اہم معاملات سے آگاہ کیا جا سکے، اور یہ نشریات اس وقت آپ کے لیے نوٹس بنیں گی جب ہم انہیں پوسٹ کریں گے۔",
        "entireAgreement": "یہ شرائط اور ہمارا رازداری کا بیان آپ کے اور ہمارے درمیان سائٹ اور سروس کے آپ کے استعمال کے حوالے سے پورا معاہدہ بناتا ہے، اور اس موضوع کے حوالے سے مینو آن لائن اور آپ کے درمیان تمام سابقہ ​​معاہدوں، نمائندگیوں، اور سمجھوتوں کی جگہ لے لیتا ہے۔ رازداری کے بیان اور ان شرائط کے درمیان کسی بھی تنازعہ کی صورت میں، یہ شرائط و ضوابط حکومت کریں گے۔",
        "language": "فریقین نے واضح طور پر درخواست کی ہے کہ یہ شرائط و ضوابط، ہماری رازداری کی پالیسی، اور کوئی بھی متعلقہ دستاویزات انگریزی میں تیار کی جائیں۔ لیس جماعتوں conviennent یٹ exigent expressément que CE Contrat et tous les دستاویزات qui s'y rapportent soient rédigés این anglais. آپ اس بات سے اتفاق کرتے ہیں کہ ان شرائط اور رازداری کی پالیسی کے انگریزی زبان کے ورژن کو کسی بھی ترجمہ شدہ ورژن کے ساتھ کسی تنازعہ کی صورت میں ترجیح دی جائے گی۔",
        "survival": "ان شرائط کی تمام دفعات جو اپنی نوعیت یا درآمد کے لحاظ سے ان شرائط کی میعاد ختم ہونے یا ختم ہونے تک زندہ رہیں گی، بشمول، بغیر کسی حد کے، سیکشن 17-19۔"
    },
    "collect_additional_info": "کیا آپ اضافی معلومات جمع کرنا چاہیں گے؟",
    "additionalInfoChoice": "کوئی بھی اضافی معلومات منتخب کریں جو آپ جمع کرنا چاہتے ہیں۔",
    "additional_info_yes": "جی ہاں",
    "additional_info_noSkip": "نہیں، چھوڑیں۔",
    "additional_info_label": "اضافی معلومات",
    "confirm_business_location": "آئیے پہلے آپ کے کاروباری مقام کی تصدیق کریں۔",
    "custom_get_more_reviews_form": "حسب ضرورت مزید جائزے کا فارم حاصل کریں۔",
    "set_up_custom_form": "اپنی مرضی کے مطابق سیٹ اپ کریں گروپ کے لحاظ سے مزید جائزے کا فارم حاصل کریں۔",
    "reputation_name_label": "مزید جائزے کے نام حاصل کریں۔",
    "reputation_name_placeholder": "شہرت کا نام درج کریں۔",
    "group_label": "گروپ",
    "group_desc_title": "گروپ کی تفصیل کا عنوان",
    "group_desc": "گروپ کی تفصیل",
    "select_group_placeholder": "گروپ کو منتخب کریں۔",
    "required_group_name": "گروپ کا نام درکار ہے۔",
    "before_asking_review": "صارفین سے جائزہ لینے سے پہلے، آئیے ان سے 1 سے 4 سوالات پوچھیں۔",
    "incentive_settings": "ترغیب کی ترتیبات",
    "incentivize_customer": "کیا آپ اپنے گاہک کو جائزہ دینے کی ترغیب دینا چاہیں گے؟",
    "choose_incentive": "وہ ترغیب منتخب کریں جس کا آپ حوالہ دینا چاہتے ہیں۔",
    "scan_qr_feedback": "QR کوڈ اسکین کریں اور اپنے تجربے کے حوالے سے اپنے قیمتی تاثرات ہمارے ساتھ شیئر کریں۔",
    "qr_code": "کیو آر کوڈ",
    "scan_me": "مجھے اسکین کریں!",
    "or_title": "یا",
    "send_otp_title": "OTP بھیجیں۔",
    "sign_in_title": "سائن ان کریں۔",
    "email_addresss_title": "ای میل ایڈریس",
    "reset_password_title": "پاس ورڈ ری سیٹ کریں۔",
    "remember_password_title": "پاس ورڈ یاد ہے؟",
    "digit_code_title": "برائے مہربانی 4 ہندسوں کا کوڈ درج کریں جو بھیجا گیا ہے۔ ",
    "not_received_title": "مجھے توثیقی کوڈ موصول نہیں ہوا!",
    "resend_title": "براہ کرم دوبارہ بھیجیں۔",
    "sign_up_form": "اپنا اکاؤنٹ بنائیں",
    "sign_up_sub_title": "تازہ ترین بصیرت تک رسائی کے لیے ابھی لاگ ان کریں۔",
    "already_registered_title": "پہلے سے ہی مینو آن لائن اکاؤنٹ ہے؟",
    "reserved_title": "کاپی رائٹس ©2023 مینو آن لائن۔ جملہ حقوق محفوظ ہیں۔",
    "terms_title": "سروس کی شرائط",
    "policy_title": "رازداری کی پالیسی",
    "sign_up_title": "سائن اپ کریں۔",
    "terms_conditions_title": "شرائط و ضوابط",
    "business_type_title": "کاروبار کی قسم",
    "business_type_sub_title": "برائے مہربانی کاروباری قسموں میں سے ایک کا انتخاب کریں جو آپ کے کاروبار کو بہتر انداز میں بیان کرتی ہے!",
    "salon_title": "سیلون",
    "restaurant_title": "ریستوراں",
    "pizza_store_title": "پیزا اسٹور",
    "food_truck_title": "فوڈ ٹرک",
    "check_domain_title": "ڈومین کا نام چیک کریں۔",
    "start_exploring_title": "دریافت کرنا شروع کریں۔",
    "sign_in_form": "اپنے اکاؤنٹ میں لاگ ان کریں۔",
    "sign_in_sub_title": "تازہ ترین بصیرت تک رسائی کے لیے ابھی لاگ ان کریں۔",
    "remember_title": "مجھے یاد رکھیں",
    "otp_title": "OTP",
    "password_title": "پاس ورڈ",
    "forgot_title": "پاس ورڈ بھول گئے؟",
    "not_registered_title": "اکاؤنٹ نہیں ہے؟",
    "create_account_title": "ابھی سائن اپ کریں۔",
    "create_form_title": "فارم بنائیں",
    "first_name_title": "پہلا نام",
    "last_name_title": "آخری نام",
    "email_title": "ای میل",
    "mobile_number_title": "موبائل نمبر",
    "birthday_title": "سالگرہ",
    "gender_title": "جنس",
    "address_title": "پتہ",
    "short_answer_title": "مختصر جواب",
    "long_answer_title": "لمبا جواب",
    "single_answer_title": "ایک ہی جواب",
    "single_checkbox_title": "سنگل چیک باکس",
    "multiple_choice_title": "متعدد انتخاب",
    "drop_down_title": "ڈراپ ڈاؤن",
    "yes_no_title": "ہاں یا نہیں۔",
    "description_text_title": "تفصیل کا متن",
    "file_upload": "فائل اپ لوڈ کریں۔",
    "reset_password_form": "اپنا پاس ورڈ ری سیٹ کریں۔",
    "reset_password_sub_title": "اپنا پاس ورڈ فوری طور پر دوبارہ ترتیب دیں، اور اپنے اکاؤنٹ تک رسائی حاصل کریں۔",
    "required_full_name_title": "پورا نام درکار ہے۔",
    "required_first_name_title": "پہلا نام درکار ہے۔",
    "valid_first_name_title": "درست پہلا نام درج کریں۔",
    "valid_full_name_title": "درست پورا نام درج کریں۔",
    "required_block_time": "بلاک ٹائم درکار ہے۔",
    "required_last_name_title": "آخری نام درکار ہے۔",
    "valid_last_name_title": "درست آخری نام درج کریں۔",
    "valid_mobile_number_title": "درست موبائل نمبر درج کریں۔",
    "required_mobile_number_title": "موبائل نمبر درکار ہے۔",
    "required_already_mobile_number_title": "موبائل نمبر پہلے ہی درکار ہے۔",
    "valid_otp_title": "درست او ٹی پی درج کریں۔",
    "valid_email_title": "ایک درست ای میل درج کریں۔",
    "required_email_title": "ای میل درکار ہے۔",
    "required_description_title": "تفصیل درکار ہے۔",
    "required_description_500_characters": "براہ کرم کم از کم 500 حروف درج کریں۔",
    "required_description_600_characters": "براہ کرم زیادہ سے زیادہ 600 حروف درج کریں۔",
    "domain_title": "ڈومین کا نام",
    "required_password_title": "پاس ورڈ درکار ہے۔",
    "required_password_regex_title": "کم از کم 8 حروف، کم از کم ایک بڑے حروف، ایک چھوٹے حروف، ایک نمبر، اور ایک خصوصی کیس کریکٹر پر مشتمل ہونا ضروری ہے",
    "required_terms_title": "براہ کرم شرائط و ضوابط سے اتفاق کریں۔",
    "required_note_title": "نوٹ ضروری ہے۔",
    "required_birthday_title": "سالگرہ درکار ہے۔",
    "no_access_title": "آپ کو ابھی رسائی نہیں ہے۔ براہ کرم اپنے منتظم سے رابطہ کریں۔",
    "menu_online_open_txt": "کھولیں۔",
    "loyalty_program_description": "😊 اپنا مثالی لائلٹی پروگرام تلاش کریں اور اسے اپنے برانڈ سے مماثل بنانے کے لیے اپنی مرضی کے مطابق بنائیں!",
    "lp_list_title": "لائلٹی پنچ کارڈ کی فہرست",
    "lp_n_title": "لائلٹی پنچ کارڈ کا نام",
    "enter_details_title": "تفصیلات درج کریں۔",
    "stamp_allowed_title": "ڈاک ٹکٹ کی اجازت ہے۔",
    "stamp_items_title": "ڈاک ٹکٹ کی اشیاء",
    "lpn_req_title": "وفاداری پنچ کارڈ کا نام درکار ہے۔",
    "valid_lpn_title": "وفاداری پنچ کارڈ کا درست نام درج کریں۔",
    "brand_color_error": "برانڈ کا رنگ بالکل 7 حرف کا ہونا چاہیے۔",
    "select_dark_brand_color_title": "براہ کرم درست برانڈ کا رنگ منتخب کریں۔",
    "your_brand_color_title": "آپ کے برانڈ کا رنگ",
    "upload_logo_title": "لوگو اپ لوڈ کریں۔",
    "locations_title": "مقامات",
    "add_lp_title": "پروگرام بنائیں",
    "location_error": "براہ کرم مقامات منتخب کریں۔",
    "pvs_title": "ایک گاہک فی وزٹ کتنے ڈاک ٹکٹ حاصل کرسکتا ہے؟",
    "max_apd_title": "ایک صارف ایک دن میں زیادہ سے زیادہ کتنی ڈاک ٹکٹیں کما سکتا ہے؟",
    "logo_error": "لوگو درکار ہے۔",
    "expiry_title": "انعامات پر ایکسپائری سیٹ کریں۔",
    "add_more_rewards_title": "+ مزید انعامات شامل کریں۔",
    "prev_step_1_title": "پوائنٹس حاصل کرنے اور اپنے انعامات سے لطف اندوز ہونے کے لیے QR دکھائیں۔",
    "delete_lp_title": "یہ لائلٹی پنچ کارڈ مستقل طور پر حذف کر دیا جائے گا۔",
    "delete_user_record_title": "یہ صارف ریکارڈ مستقل طور پر حذف کر دیا جائے گا۔",
    "redeem_title": "چھڑانا",
    "lp_button_title": "لائلٹی پنچ کارڈ میں شامل ہوں۔",
    "lp_download_button_title": "کارڈ ڈاؤن لوڈ کریں۔",
    "step_0_title": "ابھی شامل ہوں، آپ جیسے وفادار کھانے والوں کے لیے مزیدار انعامات کا انتظار ہے۔",
    "step_1_title": "آپ نے شمولیت اختیار کی ہے۔",
    "congrats_step_title": "اب آپ مفت پیزا کے اہل ہیں۔",
    "reward_title": "ہر دورے پر انعامات حاصل کریں۔",
    "visit_title": "ملاحظہ کریں اور غیر مقفل کریں۔",
    "rewards_title": "انعامات",
    "dummy_address": "3730 Frankfort Ave, Louisville Kentucky, United States",
    "loyalty_qr_default_text": "QR کوڈ اسکین کریں، ہمارے لائلٹی پنچ کارڈ میں شامل ہوں، اور انعامات میں شامل ہوں۔",
    "lp_earn_stamp_txt": "گاہک ہر دورے پر ڈاک ٹکٹ حاصل کرتے ہیں۔",
    "lp_get_rewards_txt": "ہر بار وزٹ کرنے پر انعامات حاصل کریں۔",
    "lp_1_time_visit_txt": "1 ٹائم وزٹ آپ کو ملے گا۔",
    "lp_uppercase_stamp_text": "ڈاک ٹکٹ",
    "lp_lowercase_stamp_text": "ڈاک ٹکٹ",
    "lp_txt": "وفاداری۔",
    "lp_per_visit_stamp_validation_msg": "فی وزٹ سٹیمپ فی دن زیادہ سے زیادہ اجازت شدہ ڈاک ٹکٹ سے کم ہونا چاہیے۔",
    "lp_per_max_stamp_validation_msg": "فی دن زیادہ سے زیادہ اجازت شدہ ڈاک ٹکٹ فی وزٹ اسٹیمپ سے زیادہ ہونا چاہیے۔",
    "lp_valid_value_msg": "درست قدر درج کریں۔",
    "lp_required_field_msg": "یہ فیلڈ درکار ہے۔",
    "lp_stamp_required_msg": "ڈاک ٹکٹ درکار ہے۔",
    "lp_valid_stamp_msg": "ڈاک ٹکٹ کی درست قیمت درج کریں۔",
    "lp_reward_name_required_msg": "انعام کا نام درکار ہے۔",
    "lp_unique_stamp_msg": "ڈاک ٹکٹ کی قیمت منفرد ہونی چاہیے۔",
    "lp_benefits": "ایک لائلٹی پروگرام ذاتی نوعیت کی مارکیٹنگ کے لیے گاہک کی بصیرت فراہم کرتے ہوئے دوبارہ دوروں اور آمدنی کو بڑھاتا ہے۔",
    "lp_how_customer_txt": "یہاں آپ کے گاہکوں کا طریقہ ہے۔",
    "lp_punch_card_txt": "وفاداری پنچ کارڈ",
    "lp_look_txt": "نظر آئے گا! 😍",
    "lp_desc": "گاہک ہر وزٹ کے لیے ڈاک ٹکٹ حاصل کریں گے، اور ایک بار جب وہ کافی جمع کر لیں، تو وہ خصوصی انعامات کو چھڑا سکتے ہیں۔",
    "lp_edit": "ترمیم کریں۔",
    "lp_looks_good_txt": "اچھا لگتا ہے۔",
    "lp_customer_view_instruction": "یہ وہی ہے جو آپ کے گاہک کو نظر آئے گا۔ اپنا لوگو اپ لوڈ کریں اور رنگین تھیم سیٹ کریں۔",
    "lp_customer_earn_stamps": "صارفین کو ہر وزٹ پر ڈاک ٹکٹ حاصل کرنے اور اپنے انعامات کا انتظام کرنے دیں۔",
    "lp_stamp_rewards_setup": "اسٹامپ کمانے والے انعامات سیٹ کریں، صارفین کو فی اسٹامپ انعامات حاصل کرنے کی اجازت دیں۔",
    "lp_loyalty_program_setup": "اپنے لائلٹی پروگرام کا نام دیں اور اس پروگرام کے لیے اپنا مقام سیٹ کریں۔",
    "lp_prev_step1": "پوائنٹس حاصل کرنے اور اپنے انعامات سے لطف اندوز ہونے کے لیے QR دکھائیں۔",
    "lp_visit_1_time_txt": "1 بار ملاحظہ کریں اور حاصل کریں۔",
    "lp_get_free_txt": "مفت حاصل کریں۔",
    "lp_visit_allow_txt": "دیکھیں اور غیر مقفل کریں۔",
    "lp_rewards": "انعامات",
    "repsT": "مزید جائزے حاصل کریں۔",
    "offSup": "دفتری سامان",
    "repsNxtV": "آپ کے اگلے دورے پر",
    "addRepT": "شامل کریں مزید جائزے حاصل کریں۔",
    "repEntrCpnTxt": "کوپن کا متن درج کریں۔",
    "repEntrCpnTxtBtn": "کوپن بٹن کا متن درج کریں۔",
    "repMngNxtV": "آپ کے اگلے دورے پر؟",
    "repMngWldL": "کیا آپ حاصل کرنا چاہیں گے۔",
    "discount": "رعایت",
    "repDisTyp": "ڈسکاؤنٹ کی قسم",
    "repNameT": "اپنی ساکھ کو نام دیں۔",
    "crtRepT": "ساکھ بنائیں",
    "feedBackT": "تاثرات",
    "confLocT": "مقام کی تصدیق کریں۔",
    "businLocT": "کاروباری مقام",
    "addInfoT": "اضافی معلومات",
    "repNReqT": "مزید جائزے حاصل کریں نام درکار ہے۔",
    "validRepNT": "درست شہرت کا نام درج کریں۔",
    "groupT": "گروپ",
    "groupLDescTitleT": "رابطوں کو خودکار طور پر گروپ میں دھکیلیں۔",
    "groupLDescT": "فارم پُر کرنے والے تمام صارفین کو منتخب گروپ میں شامل کیا جائے گا۔",
    "que2": "کیا آپ کا کھانا اچھا تھا؟",
    "que3": "کیا ہماری خدمت دوستانہ تھی؟",
    "que4": "کیا ہماری سروس تیز تھی؟",
    "addAdditionalT": "کیا آپ کوئی اضافی معلومات شامل کرنا چاہیں گے؟",
    "phNoMandT": "فون نمبر لازمی ہے!",
    "qrGenT": "QR تیار کیا گیا۔",
    "repFDT": "آپ کا ان پٹ ہماری ٹیم کو آپ کی بہتر خدمت کرنے میں مدد کرے گا!",
    "letdiT": "آئیے یہ کرتے ہیں۔",
    "tak15secT": "15 سیکنڈ لگتے ہیں۔",
    "defQue": "کیا آپ وقتاً فوقتاً زبردست پیشکشیں اور پروموشن حاصل کرنا چاہیں گے؟",
    "defNQue": "کیا آپ چاہیں گے کہ کوئی آپ کو جواب دے؟",
    "rateOnGoogleDesc": "کیا آپ ہمیں گوگل پر درجہ بندی کرنے کے لیے کافی مہربان ہوں گے؟",
    "mayBeNT": "شاید اگلی بار",
    "yesSureT": "ہاں، ضرور",
    "valid_name": "درست نام درج کریں۔",
    "nameReq": "نام درکار ہے۔",
    "backToMainT": "واپس مرکزی صفحہ پر",
    "attachCopT": "کیا آپ اپنے گاہک کی حوصلہ افزائی کرنا چاہیں گے؟",
    "thankYouT": "شکریہ",
    "lftyT": "ہم آپ کے اگلے دورے کے منتظر ہیں!",
    "repPreT": "ساکھ کا پیش نظارہ",
    "offer_exclusive_discounts": "اپنے صارفین کو راغب کرنے کے لیے خصوصی رعایتیں اور پروموشنز پیش کریں۔",
    "couponT": "کوپن",
    "locStepDesc": "اپنے کاروباری مقام کی تصدیق کریں۔",
    "createRepStepDesc": "گروپوں کے ذریعہ اپنی مرضی کے مطابق ساکھ کا فارم بنائیں",
    "feedbackStepDesc": "کسٹمر فیڈ بیک سوال سیٹ",
    "addInfoStepDesc": "کسٹمر فیلڈز اور ترغیب کی ترتیبات",
    "FedbkkWDT": "ڈیٹا کے ساتھ تاثرات",
    "repAnlytT": "ساکھ کے تجزیات",
    "todayT": "آج",
    "rep_last_7_days_t": "آخری 7 دن",
    "last30DaysT": "آخری 30 دن",
    "lastMT": "پچھلے مہینے",
    "custRangeT": "اپنی مرضی کی حد",
    "queWiseAnltT": "سوال وائز تجزیات",
    "atL1QueErrT": "براہ کرم کم از کم ایک سوال منتخب کریں۔",
    "deleteRepTitle": "اس شہرت کو ہمیشہ کے لیے حذف کر دیا جائے گا۔",
    "incvCustT": "ہاں، میرے گاہک کی حوصلہ افزائی کریں۔",
    "collAddInfoT": "ہاں، میں اضافی معلومات اکٹھا کرنا چاہوں گا۔",
    "totRewT": "کل جائزہ",
    "totPosRewT": "کل مثبت جائزہ",
    "negFeedT": "منفی رائے",
    "posFeedT": "مثبت فیڈ بیک",
    "fineT": "عمدہ پرنٹ",
    "enterFineT": "ٹھیک پرنٹ متن درج کریں۔",
    "setThemeT": "تھیم کا رنگ سیٹ کریں۔",
    "que1T": "سوال 1",
    "que2T": "سوال 2",
    "que3T": "سوال 3",
    "que4T": "سوال 4",
    "entMailT": "اپنا ای میل درج کریں۔",
    "reputationQRDefTxt": "QR کوڈ اسکین کریں اور اپنے تجربے کے حوالے سے اپنے قیمتی تاثرات ہمارے ساتھ شیئر کریں۔",
    "selUser": "صارف کو منتخب کریں۔",
    "userT": "صارف",
    "subUReq": "براہ کرم صارفین کو منتخب کریں۔",
    "updateLoc": "مقام کو اپ ڈیٹ کریں۔",
    "leadGenT": "لیڈ جنریشن",
    "displayT": "ڈسپلے",
    "ratingT": "درجہ بندی",
    "rep_dashboard_negative_feedback": "منفی رائے 0%",
    "rep_dashboard_position_feedback": "مثبت فیڈ بیک 0%",
    "rep_dashboard_total_Feedback_txt": "ٹوٹل فیڈ بیک",
    "rep_dashboard_anony_Feedback": "گمنام تاثرات",
    "rep_dashboard_Feeedback_Data_txt": "ڈیٹا کے ساتھ تاثرات",
    "rep_dashboard_review_txt": "مزید جائزے حاصل کریں۔",
    "rep_dashboard_total_Review_txt": "کل جائزہ",
    "rep_dashboard_total_positive_Review_txt": "کل مثبت جائزہ",
    "rep_dashboard_negative_feedback_txt": "منفی رائے",
    "rep_dashboard_connection_txt": "کنکشن",
    "rep_dashboard_question_wise_analytics_txt": "سوال وائز تجزیات",
    "rep_dashboard_date_label": "تاریخ منتخب کریں۔",
    "rep_dashboard_custom_range_txt": "اپنی مرضی کی حد",
    "rep_tlt_min": "REP",
    "emojis": "ایموجیز",
    "media_text": "میڈیا شامل کریں۔",
    "custom_field_text": "اپنی مرضی کے فیلڈز داخل کریں۔",
    "shortlink_text": "شارٹ لنک شامل کریں۔",
    "use_template_text": "ٹیمپلیٹ استعمال کریں۔",
    "banner_must_have": "ہونا ضروری ہے۔",
    "res_seamless": "ہموار",
    "res_table_reservation": "ٹیبل ریزرویشن",
    "res_system": "سسٹم",
    "res_text": "تحفظات",
    "md_easily": "آسانی سے",
    "md_manage_customers": "صارفین کا نظم کریں۔",
    "md_visits_interactions": "دورے اور تعاملات",
    "md_desc": "ریئل ٹائم ٹیبل ریزرویشن کے ساتھ اپنے صارفین کے لیے کھانے کو آسان بنائیں۔ آسانی سے بکنگ کا انتظام کریں، انتظار کے اوقات کو کم کریں، اور عملے اور مہمانوں دونوں کے لیے ایک ہموار تجربہ کو یقینی بنائیں۔ اعلی درجے کی سروس اور اطمینان کو برقرار رکھتے ہوئے اپنے ریستوراں کو مکمل طور پر بک کروائیں۔",
    "engage_convert": "مشغول ہوں اور SMS کو تبدیل کریں۔",
    "coupon_campaign": "اور کوپن مہم",
    "sms_read_rate": "SMS پیغامات 98% وقت پڑھے جاتے ہیں۔",
    "real_time_promo": "فوری کارروائی کے لیے حقیقی وقت میں پروموشنز فراہم کریں۔",
    "affordable_reach": "ٹھوس ROI کے ساتھ صارفین تک پہنچنے کا سستا طریقہ",
    "stay_in_touch": "ہر وقت اپنے گاہک کے ساتھ رابطے میں رہیں",
    "bc_engage_customers": "صارفین کو مشغول کریں SMS کو تبدیل کریں۔",
    "bc_coupon_campaign": "اور کوپن مہم",
    "bc_sms_read_rate": "SMS پیغامات 98% وقت پڑھے جاتے ہیں۔",
    "bc_real_time_promo": "فوری کارروائی کے لیے حقیقی وقت میں پروموشنز فراہم کریں۔",
    "bc_affordable_reach": "ٹھوس ROI کے ساتھ صارفین تک پہنچنے کا سستا طریقہ",
    "bc_stay_in_touch": "اپنے گاہک کے ساتھ ہر وقت رابطے میں رہیں",
    "ar_automate_engagement": "اپنے کلائنٹ کی مصروفیت کو خودکار بنائیں",
    "ar_with": "کے ساتھ",
    "ar_with_auto_responders": "خودکار جواب دہندگان",
    "ar_personalized_messages": "سالگرہ کے لیے ذاتی نوعیت کے پیغامات خود بخود بھیجیں، نئے کلائنٹس کا خیرمقدم کریں، اور ماضی کے صارفین سے دوبارہ رابطہ کریں۔ ہر تعامل کو بامعنی اور بروقت رکھتے ہوئے وقت کی بچت کریں۔",
    "sq_custom_forms_tools": "حسب ضرورت فارم اور ٹولز - آسان بنائیں",
    "sq_custom_forms_ci": "کلائنٹ کے تعاملات",
    "sq_smart_qr_desc": "اپنی مرضی کے مطابق فارم بنائیں، ای دستخط جمع کریں، اور اپنے کاروباری عمل کو ہموار کرنے کے لیے اسمارٹ QR کوڈز کا فائدہ اٹھائیں۔ اپنے کلائنٹس کی ضروریات کو پورا کرنے اور صارف کے تجربے کو بڑھانے کے لیے ہر ٹول کو تیار کریں۔",
    "gnc_get_new_cus": "نئے گاہک حاصل کریں۔",
    "gnc_get_customers": "سوشل میڈیا سے — تیز اور آسان!",
    "gnc_social_media_power": "نئے گاہکوں کو متوجہ کرنے اور براہ راست اپنے کاروبار میں فروخت بڑھانے کے لیے سوشل پلیٹ فارمز کی طاقت کا استعمال کریں۔ زیادہ سے زیادہ کنٹرول اور آمدنی کے لیے فریق ثالث کے ڈیلیوری آرڈرز کو براہ راست اپنے اسٹور یا آن لائن آرڈرنگ پلیٹ فارم پر ری ڈائریکٹ کریں۔",
    "wifi_connect_guests": "مہمانوں کو اس کے ساتھ جوڑیں۔",
    "wifi_smart": "اسمارٹ وائی فائی",
    "wifi_connect_grow": "اور اپنی بصیرت میں اضافہ کریں۔",
    "wifi_smart_wifi_desc": "اپنی مارکیٹنگ کی کوششوں اور مصروفیت کو بڑھانے کے لیے کسٹمرز کا قیمتی ڈیٹا اکٹھا کرتے ہوئے برانڈڈ اسپلش اسکرین کے ذریعے اپنے صارفین کو پریشانی سے پاک وائی فائی کنکشن فراہم کریں۔",
    "wifi_create_splash_page": "گیسٹ وائی فائی سپلیش پیج بنائیں",
    "instant_menu_create": "اپنا بنائیں",
    "instant_menu_text": "فوری مینو",
    "instant_menu_wm": "منٹوں کے اندر",
    "instant_menu_desc": "ریئل ٹائم اپ ڈیٹس، آسان حسب ضرورت اور بہتر مصروفیت کی اجازت دے کر کسٹمر کے تجربات کو بہتر بناتا ہے جس سے آپ کی پیشکشوں کو ظاہر کرنا اور آپ کے مواد کو تازہ رکھنا آسان ہو جاتا ہے۔",
    "instant_menu_create_digital_menu": "ڈیجیٹل مینو بنائیں",
    "loyalty_program_rewards": "انعامات کمائیں۔",
    "loyalty_program_on_visits": "ہر دورے پر",
    "loyalty_program_create": "لائلٹی پروگرام بنائیں",
    "reputation_create": "ساکھ بنائیں",
    "reputation_boost": "اپنے کو فروغ دیں۔",
    "reputation_text": "شہرت",
    "reputation_reviews": "مزید جائزوں کے ساتھ!",
    "reputation_desc": "اپنی سروس اور ساکھ کو بڑھانے کے لیے اپنے گاہکوں سے قیمتی آراء جمع کریں۔ اپنے تجربے کا اشتراک کریں اور بڑھنے میں ہماری مدد کریں — آپ کا جائزہ اہم ہے!",
    "dashboard": "ڈیش بورڈ",
    "blast_campaign": "دھماکے کی مہم",
    "blast_campaigns": "دھماکے کی مہمات",
    "blast_campaign_sub_title": "چند کلکس میں اپنے تمام کلائنٹس تک پہنچنے کے لیے بلاسٹ مہمات شروع کریں۔",
    "auto_responders": "خودکار جواب دہندگان",
    "auto_responder": "خودکار جواب دہندہ",
    "auto_responder_sub_title": "اپنے کلائنٹس کے لیے ایونٹ پر مبنی پروموشنز کا استعمال کرنا آسان ہے۔",
    "smart_qr_form": "اسمارٹ QR\/فارم",
    "get_new_customer": "نیا کسٹمر حاصل کریں۔",
    "marketing": "مارکیٹنگ",
    "clients": "کلائنٹس",
    "group_data": "گروپ ڈیٹا",
    "clients_data": "کلائنٹس کا ڈیٹا",
    "account": "اکاؤنٹ",
    "user_acc_info": "صارف کے اکاؤنٹ کی معلومات",
    "current_plan": "موجودہ منصوبہ",
    "payments": "ادائیگیاں",
    "location": "مقام",
    "location_list": "مقام کی فہرست",
    "users": "صارفین",
    "users_sub_title": "تمام صارفین کی فہرست اور ان کی تفصیلات",
    "online_profile": "آن لائن پروفائل",
    "change_password": "پاس ورڈ تبدیل کریں۔",
    "menu": "مینو",
    "reseller_dashboard": "ری سیلر ڈیش بورڈ",
    "companies": "کمپنیاں",
    "plans_bought": "منصوبے خریدے گئے۔",
    "agreement_t_c": "معاہدہ T&C",
    "wifi_dashboard": "وائی ​​فائی ڈیش بورڈ",
    "hot_spot": "ہاٹ سپاٹ",
    "splash_pages": "سپلیش پیجز",
    "get_more_reviews": "مزید جائزے حاصل کریں۔",
    "office_supplies": "دفتری سامان",
    "reservation": "ریزرویشن",
    "floor_table": "فرش اور میز",
    "guest_smart_wifi": "مہمان اسمارٹ وائی فائی",
    "digital_signage": "ڈیجیٹل اشارے",
    "schedule_down": "شیڈول ڈاؤن",
    "business_settings": "کاروباری ترتیبات",
    "sub_user_login": "سب یوزر لاگ ان",
    "help_center": "امدادی مرکز",
    "switch_to_hub": "حب پر سوئچ کریں۔",
    "meeting_records": "میٹنگ ریکارڈز",
    "logout": "لاگ آؤٹ",
    "no_access_text": "آپ کو ابھی رسائی نہیں ہے۔ براہ کرم اپنے منتظم سے رابطہ کریں۔",
    "menu_item": "مینو آئٹم",
    "menu_capital": "مینو",
    "good_day": "اچھا دن",
    "no_permission_text": "آپ کو یہ کارروائی کرنے کی اجازت نہیں ہے!!",
    "enter_valid_pin": "درست پن درج کریں۔",
    "add_reservation": "ریزرویشن شامل کریں۔",
    "edit_reservation": "ریزرویشن میں ترمیم کریں۔",
    "per_req": "افراد کی تعداد درکار ہے۔",
    "req_block_time": "بلاک ٹائم درکار ہے۔",
    "pending_status": "زیر التواء",
    "booked_status": "بک کروایا",
    "completed_status": "مکمل",
    "cancelled_status": "منسوخ",
    "reservation_slot": "ریزرویشن سلاٹ",
    "block_time": "بلاک ٹائم",
    "slot_time": "ریزرویشن سلاٹ کا وقت:  ",
    "template_name_req": "ٹیمپلیٹ کا نام درکار ہے۔",
    "template_type_req": "ٹیمپلیٹ کی قسم درکار ہے۔",
    "sms_template_req": "SMS ٹیمپلیٹ درکار ہے۔",
    "group_req": "گروپ درکار ہے۔",
    "valid_date_time": "درست تاریخ اور وقت درج کریں۔",
    "valid_group_name": "درست گروپ کا نام درج کریں۔",
    "reservation_mark_completed": "اس ریزرویشن کو مکمل کے بطور نشان زد کیا جائے گا۔",
    "customer_name": "گاہک کا نام",
    "date_time": "تاریخ کا وقت",
    "table_no": "ٹیبل نمبر",
    "floor": "فرش",
    "customer_name_placeholder": "کسٹمر_نام",
    "date_time_placeholder": "تاریخ_وقت",
    "table_no_placeholder": "ٹیبل_نمبر",
    "floor_placeholder": "فرش",
    "select_floor": "فرش منتخب کریں۔",
    "select_table": "ٹیبل منتخب کریں۔",
    "customer_name_macro": "%% گاہک_نام %%",
    "date_time_macro": "%% تاریخ_وقت %%",
    "table_no_macro": "%% ٹیبل_نہیں %%",
    "floor_macro": "%% منزل %%",
    "template_name": "ٹیمپلیٹ کا نام",
    "login_with_4_digit_pin": "اپنے 4 عدد پن کے ساتھ لاگ ان کریں۔",
    "login_now_to_access_latest_insights": "تازہ ترین نشانات تک رسائی کے لیے ابھی لاگ ان کریں۔",
    "mkt_BS_Title": "بلاسٹ ایس ایم ایس",
    "step_1": "پیغام کو حسب ضرورت بنائیں",
    "step_2": "سامعین کا انتخاب کریں۔",
    "step_3": "میسج شیڈول کریں۔",
    "step_4": "ایک پیغام بھیجیں۔",
    "step_5": "پیغام کا نظم کریں۔",
    "bs_tab_title": "دھماکے کی مہم کا خلاصہ",
    "default_message": "ارے %% پہلا نام %% ، برنچ پر خصوصی رعایت! آج ہی ہمارے ساتھ کھانا کھائیں اور اپنے بل پر خصوصی 10% رعایت حاصل کریں!",
    "bs_Title": "مہم کی قسم منتخب کریں۔",
    "sms_title": "SMS\/MMS",
    "reach_out_to_customer": "ٹیکسٹ اور ملٹی میڈیا پیغامات کے ذریعے صارفین تک پہنچیں۔",
    "wp_text": "واٹس ایپ",
    "connect_with_wp": "واٹس ایپ 💬 استعمال کرکے اپنے کلائنٹس سے جڑیں۔",
    "send_discount_offers": "ایس ایم ایس 📲 کے ذریعے ڈسکاؤنٹ آفرز بھیجیں۔",
    "send_coupons_via_sms": "اپنے صارفین کو بذریعہ SMS کوپن بھیجیں 🎟️",
    "env_Cap": "اپنے صارفین کو راغب کرنے کے لیے خصوصی رعایتیں اور پروموشنز پیش کریں۔",
    "cmt_Caption": "ٹیکسٹ اور ملٹی میڈیا پیغامات کے ذریعے صارفین تک پہنچیں۔",
    "wp_caption": "بغیر کسی رکاوٹ اور براہ راست مواصلت کے لیے WhatsApp کا استعمال کرتے ہوئے سرپرستوں سے جڑیں۔",
    "msg_Prev_Title": "پیغام کا پیش نظارہ",
    "cou_Prev_Title": "کوپن کا پیش نظارہ",
    "blast_SMS": "SMS\/MMS مہم",
    "bs_P_Msg": "قطار میں",
    "bs_S_Msg_C": "ناکام",
    "bs_F_Msg_Count": "پہنچایا",
    "previous": "پچھلا",
    "next_ST": "اگلا",
    "next_S": "اگلا مرحلہ",
    "req_Temp_Name_Title": "ٹیمپلیٹ کا نام درکار ہے۔",
    "req_Template_Tx_Title": "ٹیمپلیٹ کا متن درکار ہے۔",
    "add_Temp_Title": "ٹیمپلیٹ شامل کریں۔",
    "link_Desc": "اپنی پسند کا ایک لنک شامل کریں تاکہ کلائنٹ آپ کے ساتھ فیس بک، گوگل، یا آپ کی اپنی ویب سائٹ کے ذریعے بک کر سکیں۔",
    "audience_Title": "سامعین",
    "client_G1_Title": "نئے کلائنٹس",
    "client_G2_Title": "حالیہ کلائنٹس",
    "client_G3_Title": "وفادار کلائنٹس",
    "client_G4_Title": "ختم شدہ کلائنٹس",
    "client_G5_Title": "کنکشن کی تاریخ کے مطابق کلائنٹ",
    "on_Which_Day": "کس دن",
    "on_Day": "دن پر",
    "immediate_Desc": "آپ کا پیغام فوراً بھیج دیا جائے گا، براہ کرم شروع کرنے کے لیے اگلا پر کلک کریں۔",
    "never_Desc": "جب تک آپ اسے دستی طور پر ختم نہیں کر دیتے اس مہم کو ہمیشہ کے لیے چلانے کے لیے مقرر کیا گیا ہے۔",
    "end_Desc": "مہم اس تاریخ کو ختم ہو جائے گی جو آپ یہاں سے منتخب کرتے ہیں۔",
    "success_Message": "مہم کامیابی سے محفوظ ہو گئی!",
    "send_Success_Message": "مہم کامیابی سے بھیج دی گئی!",
    "msg_Name_Field_Title": "مہم کا عنوان",
    "valid_campaign_title": "مہم کا درست عنوان درج کریں۔",
    "msg_Type_Title": "پیغام کی قسم",
    "sms_Desc": "1 کریڈٹ فی SMS",
    "mms_Desc": "3 کریڈٹس فی MMS",
    "client_GTO_1": "دن",
    "client_GTO_2": "گھنٹے",
    "minutes": "منٹس",
    "client_GTO_4": "ہفتہ",
    "time_Option_1": "دن",
    "time_Option_2": "گھنٹے",
    "c_Grp_Day_1": "90 دن",
    "c_Grp_Day_2": "60 دن",
    "c_Grp_Day_3": "30 دن",
    "first": "سب سے پہلے",
    "second_T": "دوسرا",
    "third_T": "تیسرا",
    "fourth_T": "چوتھا",
    "fifth_T": "پانچواں",
    "delete_BS_T": "اس مہم کو مستقل طور پر حذف کر دیا جائے گا۔",
    "delete_MBS_T": "یہ مہمات مستقل طور پر حذف کر دی جائیں گی۔",
    "cFT_1": "فون",
    "cFT_2": "پہلے نام سے رابطہ کریں۔",
    "cFT_3": "آخری نام سے رابطہ کریں۔",
    "cFT_4": "ای میل سے رابطہ کریں۔",
    "cFT_5": "نوٹ",
    "cFT_6": "سالگرہ",
    "cFT_7": "کمپنی کا نام",
    "cF_Meta_Tag_1": "%% فون %%",
    "cF_Meta_Tag_2": "%% پہلا نام %%",
    "cF_Meta_Tag_3": "%% آخری نام %%",
    "cF_Meta_Tag_4": "%% ای میل %%",
    "cF_Meta_Tag_5": "%% نوٹ %%",
    "cF_Meta_Tag_6": "%% سالگرہ %%",
    "cF_Meta_Tag_7": "%% کمپنی_نام %%",
    "cF_Name_1": "فون",
    "cF_Name_2": "پہلا نام",
    "cF_Name_3": "آخری نام",
    "cF_Name_4": "ای میل",
    "cF_Name_5": "نوٹ",
    "cF_Name_6": "سالگرہ",
    "cF_Name_7": "کمپنی_نام",
    "all_clients": "تمام کلائنٹس",
    "aud_Type_2": "کلائنٹ گروپس",
    "aud_Type_3": "کسٹمر گروپس",
    "aud_Type_T1": "تمام کلائنٹس",
    "aud_Type_T2": "کلائنٹ گروپس",
    "aud_Type_T3": "کسٹمر گروپس",
    "new_clients": "نئے کلائنٹس",
    "client_G2": "حالیہ کلائنٹس",
    "client_G3": "وفادار کلائنٹس",
    "client_G4": "ختم شدہ کلائنٹس",
    "client_G5": "کنکشن کی تاریخ کے مطابق کلائنٹ",
    "never_text": "کبھی نہیں",
    "ending_on": "ختم ہو رہا ہے۔",
    "send_T2": "طے شدہ",
    "send_Type_3": "بار بار چلنے والا",
    "daily": "روزانہ",
    "weekly_T": "ہفتہ وار",
    "monthly_T": "ماہانہ",
    "yearly_T": "سالانہ",
    "each": "ہر ایک",
    "on_the": "پر",
    "monday": "پیر",
    "exclusive_offer": "آپ کے لیے خصوصی پیشکش",
    "redeem_now": "ابھی چھڑا لیں۔",
    "redeem_with_cashier": "براہ کرم کیشئر کے ساتھ بھنائیں۔",
    "lg_Day_2": "منگل",
    "lg_Day_3": "بدھ",
    "lg_Day_4": "جمعرات",
    "lg_Day_5": "جمعہ",
    "lg_Day_6": "ہفتہ",
    "lg_Day_7": "اتوار",
    "msg_Pre_T": "ڈومینوز",
    "cA_Edit_T1": "تمام",
    "cA_Edit_T2": "شامل",
    "cA_Edit_T3": "خارج",
    "SMS": "ایس ایم ایس",
    "MMS": "ایم ایم ایس",
    "usd_T": "USD",
    "cad_T": "CAD",
    "msg": "پیغام",
    "which_Day": "کون سے دن",
    "end_Date_Tx": "اختتامی تاریخ",
    "sDate_Err": "تاریخ آغاز آج سے زیادہ یا اس کے برابر ہونی چاہیے۔",
    "eDate_Err": "اختتامی تاریخ شروع ہونے کی تاریخ سے بعد کی ہونی چاہیے۔",
    "start_Date_Req": "آغاز کی تاریخ درکار ہے۔",
    "end_Date_Req": "آخری تاریخ درکار ہے۔",
    "time_req": "وقت درکار ہے۔",
    "client_GT1": "کلائنٹس نے آخری میں سبسکرائب کیا۔",
    "client_GT2": "آخری میں کنکشن والے کلائنٹ",
    "client_GT3": "کم از کم کے ساتھ کلائنٹ",
    "client_GT4": "آخری کے ساتھ",
    "client_GT5": "جو آخری بار واپس نہیں آیا",
    "dummy_Phone_No": "+91987-654-3210",
    "test_T": "ٹیسٹ",
    "dummy_Birth_Date": "01-01-2001",
    "image_Req": "میڈیا یا URL درکار ہے۔",
    "time_Title": "وقت",
    "start_date": "آغاز کی تاریخ",
    "end_date": "اختتامی تاریخ",
    "auto_Send_T": "خودکار طور پر گاہکوں کو بھیجیں۔",
    "add_Media": "میڈیا شامل کریں۔",
    "in_Title": "میں",
    "c_Temp_T": "مہم کا سانچہ",
    "temp_NT": "ٹیمپلیٹ کا نام",
    "type_T": "قسم",
    "select_template_type": "ٹیمپلیٹ کی قسم منتخب کریں۔",
    "sel_Temp_T": "ٹیمپلیٹ کی قسم منتخب کریں۔",
    "sms_temp_t": "ایس ایم ایس ٹیمپلیٹ",
    "temp_T": "سانچہ متن",
    "default_Msg": "ارے %% پہلا نام %% ، برنچ پر خصوصی رعایت! آج ہی ہمارے ساتھ کھانا کھائیں اور اپنے بل پر خصوصی 10% رعایت حاصل کریں!",
    "refill_T": "دوبارہ بھرنا",
    "avl_Credit": "دستیاب کریڈٹس",
    "req_Credit": "کل کریڈٹ درکار ہیں۔",
    "rem_Credit": "باقی کریڈٹ",
    "refill_Credit": "دوبارہ بھرے جانے والے کریڈٹ",
    "clients_Req": "کم از کم ایک کلائنٹ منتخب کریں۔",
    "subclient_Req": "منتخب کلائنٹس سبسکرائبرز نہیں ہیں۔",
    "refill_Desc": "براہ کرم مطلوبہ کریڈٹس کو دوبارہ بھریں۔",
    "url_Regex": "^((http|https):\/\/).....$",
    "sd_Invalid": "تاریخ آغاز غلط ہے۔",
    "ed_Invalid": "اختتامی تاریخ غلط ہے۔",
    "img_Url_Invalid": "غلط تصویر کا URL",
    "time_Invalid": "وقت غلط ہے۔",
    "time_And_Date_Invalid": "ایک درست تاریخ اور وقت درج کریں۔",
    "time_Invalid_Bfr": "موجودہ وقت سے کم از کم 5 منٹ بعد کا وقت منتخب کریں۔",
    "sod_Req": "دن پر بھیجنا ضروری ہے۔",
    "add_Card": "نیا کارڈ شامل کریں۔",
    "sm_D_Rep_Title": "SMS\/MMS ڈیلیوری رپورٹ",
    "send_Now_T": "ابھی بھیجیں۔",
    "schd_Now_T": "ایس ایم ایس شیڈول کریں۔",
    "test_SMS_T": "ٹیسٹ ایس ایم ایس بھیجیں۔",
    "save_AD_T": "ڈرافٹ کے طور پر محفوظ کریں۔",
    "back_TE_T": "ترمیم پر واپس جائیں۔",
    "reset_tex": "دوبارہ ترتیب دیں۔",
    "update_tex": "اپ ڈیٹ کریں۔",
    "dum_msg1": "کسٹمر کا نام ظاہر کرنے کے لیے، ٹیمپلیٹ میں %% customer_name %% استعمال کریں۔",
    "dum_msg2": "ریزرویشن کا وقت ظاہر کرنے کے لیے، ٹیمپلیٹ میں %% date_time %% استعمال کریں۔",
    "dum_msg3": "ٹیبل نمبر ظاہر کرنے کے لیے، ٹیمپلیٹ میں %% table_no %% استعمال کریں۔",
    "embedded_link": "ایمبیڈڈ لنک",
    "img_title": "تصویر",
    "img_input_text": "متن:",
    "img_join_text": " ہماری فہرست میں شامل ہونے کے لیے",
    "img_copy_text": "کاپی",
    "img_not_found": "تیار کردہ تصویر نہیں ملی!!",
    "or_text": "یا",
    "web_signup_image_content": "اس فارم کو جمع کر کے اور ٹیکسٹ کے ذریعے سائن اپ کرنے سے، آپ مارکیٹنگ کے ٹیکسٹ پیغامات (جیسے پروموشن کوڈز اور یاد دہانیاں) وصول کرنے کی رضامندی دیتے ہیں۔ پیغام اور ڈیٹا کے نرخ لاگو ہو سکتے ہیں۔ پیغام کی تعدد مختلف ہوتی ہے۔ آپ کسی بھی وقت STOP کا جواب دے کر رکنیت ختم کر سکتے ہیں۔",
    "img_download_success": "تصویر کامیابی سے ڈاؤن لوڈ ہو گئی۔",
    "embedded_link_copied": "سرایت شدہ لنک کامیابی کے ساتھ کاپی ہو گیا۔",
    "media_url_required": "میڈیا یا یو آر ایل درکار ہے۔",
    "invalid_image_url": "غلط تصویری یو آر ایل",
    "invalid_file": "فائل غلط ہے۔",
    "image_error_1mb": "براہ کرم ایک ایسی تصویر داخل کریں جو 1MB سے کم ہو۔",
    "image_error_2_5mb": "براہ کرم ایک ایسی تصویر داخل کریں جو 2.5MB سے کم ہو۔",
    "image_error_3mb": "براہ کرم ایک ایسی تصویر داخل کریں جو 3MB سے کم ہو۔",
    "change_title": "تبدیلی",
    "drop_file_text": "فائل یہاں چھوڑیں یا اپ لوڈ کرنے کے لیے کلک کریں۔",
    "apply_text": "لگائیں",
    "search_here": "یہاں تلاش کریں۔",
    "update_status": "اسٹیٹس کو اپ ڈیٹ کریں۔",
    "reservation_deleted_permanently": "یہ ریزرویشن مستقل طور پر حذف کر دیا جائے گا۔",
    "table_text": "میزیں",
    "add_table_button": "نیا ٹیبل شامل کریں۔",
    "add_table_text": "ٹیبل شامل کریں۔",
    "edit_table_text": "ٹیبل میں ترمیم کریں۔",
    "table_delete_text": "یہ ٹیبل مستقل طور پر حذف کر دیا جائے گا۔",
    "multiple_table_delete_text": "یہ میزیں مستقل طور پر حذف کر دی جائیں گی۔",
    "table_error_name": "براہ کرم ٹیبل کا نام درج کریں۔",
    "table_error_invalid_name": "براہ کرم درست ٹیبل کا نام درج کریں۔",
    "table_error_floor": "براہ کرم فرش کو منتخب کریں۔",
    "table_error_select": "براہ کرم ٹیبل کو منتخب کریں۔",
    "table_capacity_text": "ٹیبل کی گنجائش",
    "capacity_text": "صلاحیت",
    "table_occasion_text": "کسی بھی موقع کے لیے اپنی میز تلاش کریں۔",
    "table_name_text": "ٹیبل کا نام",
    "table_capacity_error": "ٹیبل کی گنجائش درکار ہے۔",
    "cancel_text": "منسوخ کریں۔",
    "submit_text": "جمع کروائیں۔",
    "select_valid_date": "درست تاریخ منتخب کریں۔",
    "select_valid_time": "درست وقت منتخب کریں۔",
    "valid_contact_number": "درست رابطہ نمبر درج کریں۔",
    "date_req": "تاریخ درکار ہے۔",
    "date_error_later_than_today": "تاریخ آج کی تاریخ سے بعد کی یا اس کے برابر ہونی چاہیے۔",
    "time_error_later_than_5_minutes": "موجودہ وقت سے کم از کم 5 منٹ بعد کا وقت منتخب کریں۔",
    "number_person_req": "افراد کی تعداد درکار ہے۔",
    "contact_no_req": "رابطہ نمبر درکار ہے۔",
    "contact_no": "رابطہ نمبر",
    "select_time": "وقت منتخب کریں۔",
    "for_how_many_person": "کتنے لوگوں کے لیے؟",
    "f_name": "پہلا نام",
    "l_name": "آخری نام",
    "business_name": "کاروبار کا نام",
    "business_no": "بزنس نمبر",
    "no_for_sign_in": "یہ نمبر سائن ان کے لیے استعمال ہوتا ہے۔",
    "business_email": "بزنس ای میل",
    "notes_tex": "نوٹس",
    "floor_deleted_permanently": "یہ منزل مستقل طور پر حذف ہو جائے گی۔",
    "add_floor": "فرش شامل کریں۔",
    "edit_floor": "فرش میں ترمیم کریں۔",
    "name_text": "نام",
    "mobile_no": "موبائل نمبر",
    "person_text": "شخص",
    "date_and_time": "تاریخ اور وقت",
    "actions_text": "اعمال",
    "extra_text": "اضافی",
    "floor_name_req": "منزل کا نام درکار ہے۔",
    "floor_name": "فرش کا نام",
    "status_text": "حیثیت",
    "category_status_req": "زمرہ کی حیثیت درکار ہے۔",
    "table_deleted_permanently": "یہ ٹیبل مستقل طور پر حذف کر دیا جائے گا۔",
    "tables_deleted_permanently": "یہ میزیں مستقل طور پر حذف کر دی جائیں گی۔",
    "back_to_home": "واپس گھر پر",
    "link_copied_text": "لنک کلپ بورڈ پر کاپی ہو گیا۔",
    "cust_dash_head_to_title": "کو",
    "cust_dash_head_location_title": "مقام",
    "select_location_title": "مقام منتخب کریں۔",
    "all_locations_label": "تمام_مقامات",
    "rep_customer_feedback_analytics": "😊 ریئل ٹائم اینالیٹکس کے ساتھ اپنے کسٹمر کے تاثرات کو ٹریک کریں۔",
    "rep_customer_txt": "گاہکوں",
    "rep_delete_title": "اس شہرت کو ہمیشہ کے لیے حذف کر دیا جائے گا۔",
    "rep_qr_def_txt": "QR کوڈ اسکین کریں اور اپنے تجربے کے حوالے سے اپنے قیمتی تاثرات ہمارے ساتھ شیئر کریں۔",
    "delete_title": "حذف کریں۔",
    "user_list_title": "کلائنٹس کی فہرست",
    "os_nfc_txt": "این ایف سی کارڈز",
    "os_sign_holder_txt": "سائن ہولڈر اسٹینڈ",
    "os_store_Sign_holder_txt": "سٹور سائن ہولڈرز",
    "StaT": "شماریات",
    "AllgT": "تمام گروپس",
    "FavT": "پسندیدہ",
    "MostActT": "سب سے زیادہ فعال",
    "grT": "گروپ کا نام",
    "keywT": "مطلوبہ الفاظ",
    "exSubT": "موجودہ سبسکرائبر کے لیے پیغام",
    "ArchT": "محفوظ شدہ",
    "gNotiSms": "مجھے SMS کے ذریعے ہر نئے رابطے کے بارے میں مطلع کریں۔",
    "gNotiEmail": "مجھے ای میل کے ذریعے ہر نئے رابطے کے بارے میں مطلع کریں۔",
    "reqGName": "گروپ کا نام درکار ہے۔",
    "validGN": "درست گروپ کا نام درج کریں۔",
    "valid_phone_no": "درست فون نمبر درج کریں۔",
    "phone_no_req": "فون نمبر درکار ہے۔",
    "required_message_text": "پیغام درکار ہے۔",
    "required_discount_text": "رعایت درکار ہے۔",
    "less_than_discount": "رعایت کی رقم 5000 سے کم ہونی چاہیے۔",
    "discount_percentage_invalid": "رعایت کا فیصد 100 سے کم یا اس کے برابر ہونا چاہیے۔",
    "discount_type_req": "رعایت کی قسم درکار ہے۔",
    "discount_text_req": "کوپن کا متن درکار ہے۔",
    "reqContactNo": "رابطہ نمبر درکار ہے۔",
    "reqMsgNT": "مہم کا عنوان درکار ہے۔",
    "reqLinkT": "لنک درکار ہے۔",
    "delMGT": "اس گروپ کو ہمیشہ کے لیے ڈیلیٹ کر دیا جائے گا۔",
    "defMemMsg": "اپنا پیغام یہاں ٹائپ کریں۔",
    "add_cust_to_grp_title": "کلائنٹ کو گروپ میں شامل کریں۔",
    "group_title": "گروپس",
    "group_create_title": "گروپ بنائیں",
    "group_name_txt": "گروپ کا نام",
    "group_table_keyword_title": "کلیدی لفظ",
    "actions_title": "اعمال",
    "clients_title": "کلائنٹس",
    "send_title": "بھیجیں۔",
    "qr_title": "QR",
    "delete_group_txt": "یہ گروپ ہمیشہ کے لیے ڈیلیٹ کر دیا جائے گا۔",
    "delete_groups_txt": "اس گروپ کو ہمیشہ کے لیے ڈیلیٹ کر دیا جائے گا۔",
    "delete_client_title": "اس کلائنٹ کو مستقل طور پر حذف کر دیا جائے گا۔",
    "delete_clients_title": "اس کلائنٹس کو مستقل طور پر حذف کر دیا جائے گا۔",
    "delete_multiple_title": "ایک سے زیادہ حذف کریں۔",
    "wel_sms_mms": "خوش آمدید SMS\/MMS بھیجیں؟",
    "key_words_title": "مطلوبہ الفاظ",
    "srch_plch_txt": "یہاں تلاش کریں۔",
    "req_location_id_title": "مقام درکار ہے۔",
    "create_text": "بنائیں",
    "view_text": "دیکھیں",
    "immediately": "فوراً",
    "business_name_req": "کاروبار کا نام درکار ہے۔",
    "business_no_req": "کاروباری نمبر درکار ہے۔",
    "valid_business_name": "درست کاروباری نام درج کریں۔",
    "valid_business_no": "درست کاروباری نمبر درج کریں۔",
    "address_req": "پتہ درکار ہے۔",
    "valid_address": "براہ کرم درست پتہ منتخب کریں۔",
    "time_zone_req": "ٹائم زون درکار ہے۔",
    "image_req": "براہ کرم 2.5mb سے کم کی تصویر داخل کریں۔",
    "valid_file": "فائل غلط ہے۔",
    "logo": "لوگو",
    "time_zone": "ٹائم زون",
    "save": "محفوظ کریں۔",
    "account_type_req": "اکاؤنٹ کی قسم درکار ہے۔",
    "gst_no_req": "جی ایس ٹی نمبر درکار ہے۔",
    "gst_no_valid": "درست جی ایس ٹی نمبر درج کریں۔",
    "set_up_payments": "ادائیگیاں ترتیب دیں۔",
    "billing_details": "اپنی بلنگ کی تفصیلات درج کریں۔",
    "billing_details_desc": "آپ کی بلنگ کی تفصیلات مینو آن لائن سے آپ کے ماہانہ انوائس پر ظاہر ہوں گی۔",
    "account_type": "اکاؤنٹ کی قسم",
    "select_account_type": "اکاؤنٹ کی قسم منتخب کریں۔",
    "gst_no": "جی ایس ٹی نمبر",
    "transaction_details": "لین دین کی تفصیلات",
    "payment_method": "ادائیگی کا طریقہ",
    "billing_period": "بلنگ کی مدت",
    "paid_by": "کی طرف سے ادا کیا",
    "download": "ڈاؤن لوڈ کریں۔",
    "pay_now": "ابھی ادائیگی کریں۔",
    "pull_down_refresh": "تازہ دم کرنے کے لیے نیچے کھینچیں۔",
    "release_refresh": "ریفریش کرنے کے لیے ریلیز کریں۔",
    "billing_details_text": "بلنگ کی تفصیلات",
    "payment_methods": "ادائیگی کے طریقے",
    "invoice": "رسید",
    "invoice_to": "انوائس کو:",
    "issue_date": "جاری ہونے کی تاریخ",
    "due_date": "مقررہ تاریخ",
    "amount_due": "واجب الادا رقم",
    "charges": "چارجز",
    "sub_total_capitalize": "ذیلی کل",
    "hst": "HST",
    "grand_total": "گرینڈ ٹوٹل",
    "invoice_generated_on": "انوائس تیار کی گئی۔",
    "contact_us": "ہم سے رابطہ کریں۔",
    "invoice_issue": "اگر آپ اپنے بل کے ذریعے گزر چکے ہیں اور پھر بھی سوال ہیں۔",
    "call": "کال کریں۔",
    "send_sms": "ایس ایم ایس بھیجیں۔",
    "payment_success": "ادائیگی کامیاب ہے۔",
    "edit_credit_card": "کریڈٹ کارڈ میں ترمیم کریں۔",
    "add_credit_card": "کریڈٹ کارڈ شامل کریں۔",
    "modify_card_info": "اپنے کارڈ کی معلومات میں ترمیم کریں۔",
    "enter_card_info": "اپنے کارڈ کی معلومات درج کریں۔",
    "account_no_req": "اکاؤنٹ نمبر درکار ہے۔",
    "card_name_req": "کارڈ پر نام درکار ہے۔",
    "expiry_date_req": "میعاد ختم ہونے کی تاریخ درکار ہے۔",
    "valid_expiry_date": "درست میعاد ختم ہونے کی تاریخ درج کریں۔",
    "valid_cvv": "درست سی وی وی درج کریں۔",
    "cvv_req": "CVV درکار ہے۔",
    "card_no": "کارڈ نمبر",
    "name_of_card": "کارڈ پر نام",
    "expiry": "ختم",
    "mm_text": "MM\/YY",
    "cvv": "سی وی وی",
    "set_as_default": "بطور ڈیفالٹ سیٹ کریں۔",
    "add_new_card": "نیا کارڈ شامل کریں۔",
    "all_credit_card": "آپ کے تمام کریڈٹ کارڈز",
    "fastest_checkout": "محفوظ اور قابل بھروسہ پلیٹ فارم کے ساتھ تیز ترین چیک آؤٹ، کاروبار کے بڑے گروپ کے ذریعے بھروسہ",
    "coupon_button_req": "کوپن بٹن کا متن درکار ہے۔",
    "max19_characters_allowed": "زیادہ سے زیادہ 19 حروف کی اجازت ہے۔",
    "fine_print_text_req": "عمدہ پرنٹ متن کی ضرورت ہے۔",
    "clients_req": "کم از کم ایک کلائنٹ منتخب کریں۔",
    "client_groups": "کلائنٹ گروپس",
    "day_text": "دن",
    "valid_days": "دن کی قدر بہت بڑی ہے درست دن درج کریں۔",
    "hours_text": "گھنٹے",
    "enter_valid_hours": "اوقات کی قدر بہت بڑی ہے درست گھنٹے درج کریں۔",
    "enter_valid_min": "منٹ کی قدر بہت بڑی ہے درست منٹ درج کریں۔",
    "clients_with_connection_required": "آخری کنکشن والے کلائنٹ کی ضرورت ہے۔",
    "connection_required": "کنکشن درکار ہے۔",
    "connection_value_too_large": "کنکشنز کی قدر بہت بڑی ہے درست کنکشن درج کریں۔",
    "minutes_required": "منٹ درکار ہیں۔",
    "clients_with_at_least_connection_required": "کم از کم کنکشن والے کلائنٹ کی ضرورت ہے۔",
    "last_minutes_req": "آخری منٹ کے ساتھ ضروری ہے",
    "clients_not_returned_required": "جو آخری منٹوں میں واپس نہیں آیا وہ مطلوب ہے۔",
    "not_return_in_last_minutes_invalid": "آخری منٹوں میں واپس نہ آنا آخری منٹ سے کم ہونا چاہیے۔",
    "customerGroups": "کسٹمر گروپس",
    "select_customer_group": "براہ کرم کسٹمر گروپ کو منتخب کریں۔",
    "location_required": "مقام درکار ہے۔",
    "start_date_required": "آغاز کی تاریخ درکار ہے۔",
    "start_date_invalid": "تاریخ آغاز غلط ہے۔",
    "start_date_invalid_error": "تاریخ آغاز آج سے زیادہ یا اس کے برابر ہونی چاہیے۔",
    "recurring": "بار بار چلنے والا",
    "end_date_required": "اختتامی تاریخ درکار ہے۔",
    "end_date_invalid": "اختتامی تاریخ غلط ہے۔",
    "end_date_invalid_error": "اختتامی تاریخ شروع ہونے کی تاریخ سے بعد کی ہونی چاہیے۔",
    "time_invalid": "وقت غلط ہے۔",
    "monthly_text": "ماہانہ",
    "send_day_req": "دن پر بھیجنا ضروری ہے۔",
    "loyal_clients": "وفادار کلائنٹس",
    "recent_clients": "حالیہ کلائنٹس",
    "lapsed_clients": "ختم شدہ کلائنٹس",
    "clients_connection_date": "کنکشن کی تاریخ کے مطابق کلائنٹ",
    "scheduled_text": "طے شدہ",
    "weekly": "ہفتہ وار",
    "selected_clients_not_subscriber": "منتخب کلائنٹ سبسکرائبر نہیں ہیں۔",
    "message_preview_title": "پیغام کا پیش نظارہ",
    "coupon_preview_title": "کوپن کا پیش نظارہ",
    "form_text": "فارم",
    "preview_text": "پیش نظارہ",
    "next_text": "اگلا",
    "send_test_SMS": "ٹیسٹ ایس ایم ایس بھیجیں۔",
    "save_draft": "ڈرافٹ کے طور پر محفوظ کریں۔",
    "back_to_edit": "ترمیم پر واپس جائیں۔",
    "select_payment_method": "براہ کرم ادائیگی کا طریقہ منتخب کریں۔",
    "schedule_SMS": "ایس ایم ایس شیڈول کریں۔",
    "send_now": "ابھی بھیجیں۔",
    "get_more_ratings": "مزید درجہ بندی حاصل کریں۔",
    "overview": "جائزہ",
    "reset_campaign": "مہم کو دوبارہ ترتیب دیں۔",
    "permissions": "اجازتیں",
    "location_name": "مقام کا نام",
    "phone_no": "فون نمبر",
    "location_email_address": "مقام کا ای میل پتہ",
    "located_business": "آپ کا کاروبار کہاں واقع ہے؟",
    "business_logo": "کاروباری لوگو",
    "congratulations": "مبارک ہو",
    "almost_done": "آپ تقریباً مکمل کر چکے ہیں۔",
    "publish": "شائع کریں۔",
    "about_your_business": "آپ کے کاروبار کے بارے میں",
    "add_your_location": "اپنا مقام شامل کریں۔",
    "publish_location": "مقام شائع کریں۔",
    "location_name_req": "مقام کا نام درکار ہے۔",
    "valid_location_name": "درست مقام کا نام درج کریں۔",
    "business_logo_req": "کاروبار کا لوگو درکار ہے۔",
    "please_accept_terms": "براہ کرم شرائط و ضوابط کو قبول کریں۔",
    "add_new_location": "نیا مقام شامل کریں۔",
    "edit_location": "مقام میں ترمیم کریں۔",
    "add_location": "مقام شامل کریں۔",
    "existing_msg_subscriber_txt": "موجودہ سبسکرائبر کے لیے پیغام",
    "msg_capitalize_txt": "پیغام",
    "group_noti_sms": "مجھے SMS کے ذریعے ہر نئے رابطے کے بارے میں مطلع کریں۔",
    "group_noti_email": "مجھے ای میل کے ذریعے ہر نئے رابطے کے بارے میں مطلع کریں۔",
    "group_member_msg": "ایسا لگتا ہے کہ آپ پہلے ہی ہمارے گروپ کا حصہ ہیں!!",
    "group_mend_msg": "ختم کرنے کے لیے روکیں۔ مدد کے لیے مدد کریں۔ ایس ایم ایس اور ڈیٹا ریٹس لاگو ہو سکتے ہیں۔",
    "Disclaimer_title": "دستبرداری کا متن: ",
    "group_def_msg": "ہم اب آن لائن ہیں! ہمارا سروس مینو دیکھیں اور اپنی اگلی ملاقات آج ہی بک کریں۔",
    "required_msg_txt": "پیغام درکار ہے۔",
    "required_Key_txt": "مطلوبہ الفاظ کی ضرورت ہے۔",
    "required_mem_msg": "سبسکرائبر کا پیغام درکار ہے۔",
    "client_list_title": "کلائنٹ کی فہرست",
    "add_contact_txt": "رابطہ شامل کریں۔",
    "delete_all_clients_txt": "تمام کلائنٹس کو حذف کریں۔",
    "delete_all_clients_msg": "کیا آپ واقعی تمام کلائنٹس کو حذف کرنا چاہتے ہیں؟ انہیں بازیافت نہیں کیا جاسکتا۔",
    "delete_all_txt": "سبھی کو حذف کریں۔",
    "timeline_title": "ٹائم لائن",
    "unsubscribe_title": "ان سبسکرائب کریں۔",
    "subscribe_title": "سبسکرائب کریں۔",
    "unsubscribe_confirm_msg": "کیا آپ اس صارف کو ان سبسکرائبر کے بطور نشان زد کرنا چاہیں گے؟",
    "subscribe_confirm_msg": "کیا آپ اس گاہک کو بطور سبسکرائبر نشان زد کرنا چاہیں گے؟",
    "no_title": "نہیں",
    "yes_title": "جی ہاں",
    "client_name_title": "کلائنٹ کا نام",
    "source_title": "ماخذ",
    "contact_file_Req": "رابطوں کی فائل درکار ہے۔",
    "req_title": "درکار ہے۔",
    "opt_in_req": "درآمد شدہ رابطے 100% آپٹ ان ہونے چاہئیں",
    "image_invalid_error": "فائل غلط ہے۔",
    "import_contacts_msg": "رابطے درآمد کریں۔",
    "csv_upload_title": "اپ لوڈ کرنے کے لیے CSV رابطوں کی فائل *",
    "csv_file_desc": "براہ کرم صرف csv فائل اپ لوڈ کریں - csv فائل کالم - پہلا نام، آخری نام، ای میل آئی ڈی، موبائل نمبر، صنف، DOB(MM\/DD\/YYYY) - موبائل نمبر میں کوئی خالی جگہ، ڈیش یا قوسین نہیں",
    "to_download_title": "ڈیمو فائل ڈاؤن لوڈ کرنے کے لیے یہاں کلک کریں۔",
    "contains_header_title": "ہاں، اس فائل میں ہیڈر شامل ہیں۔",
    "opt_in_100_txt": "ہاں، یہ درآمد شدہ رابطے 100% آپٹ ان ہیں۔",
    "DisT": "دستبرداری کا متن: ",
    "gMendMsg": "ختم کرنے کے لیے روکیں۔ مدد کے لیے مدد کریں۔ ایس ایم ایس اور ڈیٹا ریٹس لاگو ہو سکتے ہیں۔",
    "reqKeyT": "مطلوبہ الفاظ کی ضرورت ہے۔",
    "reqMemMsg": "سبسکرائبر کا پیغام درکار ہے۔",
    "reqMsgT": "پیغام درکار ہے۔",
    "gMemberMsg": "ایسا لگتا ہے کہ آپ پہلے ہی ہمارے گروپ کا حصہ ہیں!!",
    "gdefMsg": "ہم اب آن لائن ہیں! ہمارا سروس مینو دیکھیں اور اپنی اگلی ملاقات آج ہی بک کریں۔",
    "next_title": "اگلا",
    "male_title": "مرد",
    "male_val": "مرد",
    "female_title": "خاتون",
    "female_val": "خاتون",
    "others_txt": "دوسرے",
    "others_val": "دوسرے",
    "validBirthdate": "درست تاریخ پیدائش درج کریں۔",
    "valid_phone_no_title": "درست فون نمبر درج کریں۔",
    "required_phone_no_title": "فون نمبر درکار ہے۔",
    "add_new_client_txt": "ایک نیا کلائنٹ شامل کریں۔",
    "update_client_txt": "کلائنٹ کو اپ ڈیٹ کریں۔",
    "phone_num_text": "فون نمبر",
    "dob_title": "ڈی او بی",
    "select_gender_title": "صنف منتخب کریں۔",
    "timelineTitle": "ٹائم لائن",
    "confirm_location": "مقام کی تصدیق کریں۔",
    "feedback_title": "تاثرات",
    "rep_question_1": "کیا کاروبار صاف تھا؟",
    "rep_que_2": "کیا آپ کا کھانا اچھا تھا؟",
    "rep_que_3": "کیا ہماری خدمت دوستانہ تھی؟",
    "rep_que_4": "کیا ہماری سروس تیز تھی؟",
    "business_location_req_title": "کاروباری مقام درکار ہے۔",
    "valid_location_err_txt": "براہ کرم درست پتہ منتخب کریں۔",
    "rep_management_title": "مزید جائزے کا انتظام حاصل کریں۔",
    "rep_about_desc": "ساکھ آپ کے صارفین سے تاثرات مانگ کر گوگل کی درجہ بندی بڑھانے میں مدد کرتی ہے۔",
    "preview_title": "پیش نظارہ",
    "rep_preview_txt": "ساکھ کا پیش نظارہ",
    "back_title": "پیچھے",
    "fine_print_txt": "براہ کرم کیشئر کے ساتھ چھڑا لیں۔",
    "redeem_me_title": "مجھے چھڑانا",
    "rep_nxt_visit_txt": "آپ کے اگلے دورے پر",
    "device_type": "استعمال شدہ ڈیوائس کی قسم",
    "connection_method": "کنکشن کا طریقہ",
    "peak_days": "چوٹی کے دن",
    "peak_hours": "چوٹی کے اوقات",
    "guest_by_day": "دن بہ دن مہمان",
    "guest_visit": "مہمان کا دورہ",
    "connection": "کنکشن",
    "connection_duration": "کنکشن کا دورانیہ",
    "guest_visit_1": "1 بار",
    "guest_visit_2": "2 بار",
    "guest_visit_3_5": "3-5 بار",
    "guest_visit_6_10": "6-10 بار",
    "guest_visit_11_25": "11-25 بار",
    "guest_visit_26_100": "26-100 بار",
    "guest_visit_100_plus": "100+ بار",
    "device_android_total": "کل اینڈرائیڈ",
    "device_android": "اینڈرائیڈ",
    "device_desktop": "ڈیسک ٹاپ",
    "device_ios": "آئی او ایس",
    "device_ios_total": "کل آئی او ایس",
    "device_desktop_total": "کل ڈیسک ٹاپ",
    "connection_duration_10": "<=10 منٹ",
    "connection_duration_20": "11-20 منٹ",
    "connection_duration_30": "21-30 منٹ",
    "connection_duration_40": "31-45 منٹ",
    "connection_duration_60": "46-60 منٹ",
    "connection_method_email": "ای میل",
    "connection_method_sms": "ایس ایم ایس",
    "connection_method_google": "گوگل",
    "connection_method_facebook": "فیس بک",
    "age_category_1": "<18",
    "age_category_2": "18-24",
    "age_category_3": "25-34",
    "age_category_4": "35-44",
    "age_category_5": "45-54",
    "age_category_6": "55+",
    "all_guest_txt": "تمام مہمان",
    "new_Guest_txt": "نیا مہمان",
    "connections_txt": "کنکشنز",
    "hotspot": "ہاٹ سپاٹ",
    "hotspot_list": "ہاٹ سپاٹ کی فہرست",
    "add_new_hotspot": "نیا ہاٹ سپاٹ شامل کریں۔",
    "hotspot_information": "ہاٹ سپاٹ کی معلومات",
    "edit_details_button": "تفصیلات میں ترمیم کریں۔",
    "wifi_info_message": "مربوط ہوں اور مفت وائی فائی سے لطف اندوز ہوں۔",
    "connection_message": "بہت اچھا، آپ سے منسلک کیا گیا ہے ",
    "connection_message_suffix": " وائی ​​فائی",
    "wifi": "وائی ​​فائی",
    "login_to_access": "رسائی حاصل کرنے کے لیے لاگ ان کریں۔",
    "verification_code": "تصدیقی کوڈ",
    "verification_code_message": "براہ کرم بھیجے گئے تصدیقی کوڈ کو ٹائپ کریں۔ ",
    "wifi_user_email": "adamo@gmail.com",
    "wifi_label": "وائی ​​فائی",
    "your_name": "ویسے آپ کا نام کیا ہے؟",
    "your_birthdate": "کیا آپ ہمیں بتا سکتے ہیں، آپ کی تاریخ پیدائش کیا ہے؟",
    "request_guest_wifi_name": "براہ کرم مہمان وائی فائی کا نام درج کریں۔",
    "request_device_key": "براہ کرم ڈیوائس کلید درج کریں۔",
    "request_maximum_internet_access_length": "براہ کرم انٹرنیٹ تک رسائی کی زیادہ سے زیادہ لمبائی منتخب کریں۔",
    "mac_address": "میک ایڈریس",
    "device_port": "ڈیوائس پورٹ",
    "hardware": "ہارڈ ویئر",
    "current_uptime": "موجودہ اپ ٹائم",
    "nearby_devices": "قریبی آلات",
    "firmware": "فرم ویئر",
    "who_are_you": "تم کون ہو؟",
    "where_to_contact_you": "ہم آپ سے کہاں رابطہ کریں گے؟",
    "your_area_code": "آپ کا ایریا کوڈ کیا ہے؟",
    "connected": "جڑا ہوا",
    "delete_hotspot_message": "یہ ہاٹ اسپاٹ مستقل طور پر حذف کر دیا جائے گا۔",
    "delete_multiple_hotspots_message": "یہ ہاٹ سپاٹ مستقل طور پر حذف کر دیے جائیں گے۔",
    "speed_error": "رفتار کم از کم 0.01 ہونی چاہیے۔",
    "speed_max_error": "براہ کرم لامحدود رفتار کے لیے 1024 تک کی قدر درج کریں، یا معاون رینج کے اندر کم قدر منتخب کریں",
    "device_ssid": "ڈیوائس SSID",
    "device_ssid_two": "ڈیوائس SSID ٹو",
    "device_ssid_two_wpa": "ڈیوائس SSID دو WPA",
    "device_key": "ڈیوائس کی کلید",
    "select_location": "مقام منتخب کریں۔",
    "select_maximum_internet_access_length": "انٹرنیٹ تک رسائی کی زیادہ سے زیادہ لمبائی منتخب کریں۔",
    "download_speed": "ڈاؤن لوڈ کی رفتار",
    "upload_speed": "اپ لوڈ کی رفتار",
    "network_length_1": "15 منٹ",
    "network_length_2": "30 منٹ",
    "network_length_3": "45 منٹ",
    "network_length_4": "1 گھنٹہ",
    "network_length_5": "2 گھنٹے",
    "network_length_6": "4 گھنٹے",
    "network_length_7": "6 گھنٹے",
    "network_length_8": "8 گھنٹے",
    "network_length_9": "10 گھنٹے",
    "network_length_10": "12 گھنٹے",
    "employee_wifi_name": "ملازم وائی فائی کا نام",
    "employee_wifi_password": "ملازم کا وائی فائی پاس ورڈ",
    "guest_wifi_name": "مہمان وائی فائی کا نام",
    "menu_other_products_txt": "دیگر مصنوعات | مینو آن لائن",
    "menu_home_text": "ہوم | مینو آن لائن",
    "whatsapp_title": "واٹس ایپ",
    "select_campaign_type": "مہم کی قسم منتخب کریں۔",
    "select_readymade_templates": "ریڈی میڈ ٹیمپلیٹس میں سے انتخاب کریں یا خود بنائیں",
    "campaign_title_required": "مہم کا عنوان درکار ہے۔",
    "type_here": "یہاں ٹائپ کریں...",
    "location_permission_req": "مقام کی اجازت درکار ہے۔",
    "platform_permission_req": "پلیٹ فارم کی اجازت درکار ہے۔",
    "profile_picture": "پروفائل تصویر",
    "click_to_upload": "اپ لوڈ کرنے کے لیے کلک کریں۔",
    "location_permission": "مقام کی اجازت",
    "pin": "پن",
    "platform_permission": "پلیٹ فارم کی اجازت",
    "set_pin": "PIN سیٹ کریں۔",
    "list_of_users": "صارفین کی فہرست",
    "create_user": "صارف بنائیں",
    "terms_and_condition_req": "شرائط و ضوابط درکار ہیں۔",
    "terms_and_conditions": "شرائط و ضوابط",
    "recommended": "تجویز کردہ",
    "mo": "\/ماہ",
    "unsubscribe": "ان سبسکرائب کریں۔",
    "cancel_subscription": "کیا آپ واقعی سبسکرپشن منسوخ کرنا چاہتے ہیں؟",
    "upgrade": "اپ گریڈ کریں۔",
    "plan_upgraded_successfully": "منصوبہ کامیابی کے ساتھ اپ گریڈ ہو گیا!",
    "menu_online": "مینو آن لائن",
    "support": "حمایت",
    "wifiC": "وائی ​​فائی",
    "billing": "بلنگ",
    "please_upgrade": "براہ کرم تمام خصوصیات سے لطف اندوز ہونے کے لیے اپ گریڈ کریں۔",
    "you_have_subscribed": "آپ نے سبسکرائب کیا ہے۔ ",
    "plan": "منصوبہ",
    "of_text": "کی",
    "days": "دن",
    "remaining_days_until_plan": " آپ کے پلان کو اپ ڈیٹ کرنے میں دن باقی ہیں۔",
    "manage_your_screens": "اپنی اسکرینوں کا نظم کریں۔",
    "screens_you_save": "اسکرینیں جو آپ کے پاس ہیں۔",
    "add_more_screens": "مزید اسکرینیں شامل کریں۔",
    "addition_screen": "اضافی اسکرین",
    "per_screen": "$ فی اسکرین",
    "per_box": "$ فی باکس",
    "shipping_changes": "شپنگ چارجز",
    "upto4_boxes": "4 خانوں تک ",
    "charge_now": "آپ کو ابھی چارج ملے گا۔",
    "updated_reoccuring": "آپ کی تازہ کاری دوبارہ ہو جائے گی۔",
    "current_reoccuring": "موجودہ تکرار: ",
    "no_text": "نہیں",
    "android_box": "اینڈرائیڈ باکس",
    "old_password_req": "پرانا پاس ورڈ درکار ہے۔",
    "new_password_req": "نیا پاس ورڈ درکار ہے۔",
    "confirm_password_req": "تصدیق کریں کہ پاس ورڈ درکار ہے۔",
    "password_do_not_match": "پاس ورڈز مماثل نہیں ہیں۔",
    "old_password": "پرانا پاس ورڈ",
    "new_password": "نیا پاس ورڈ",
    "confirm_password": "پاس ورڈ کی تصدیق کریں۔",
    "copy_working_hours": "ان کام کے اوقات کو تمام دنوں میں کاپی کریں؟",
    "yes_copy": "جی ہاں، کاپی",
    "closed": "بند",
    "opening_time": "کھلنے کا وقت",
    "closing_time": "بند ہونے کا وقت",
    "description": "تفصیل",
    "file_exist": "فائل پہلے سے موجود ہے، براہ کرم دوسری فائل منتخب کریں۔",
    "bussiness_images": "بزنس امیجز",
    "display_info_on_market_place": "کیا آپ اپنی مارکیٹ پلیس پر درج ذیل معلومات کو ظاہر کرنا چاہیں گے؟",
    "profile_ready": "آپ کا پروفائل تیار ہے!",
    "client_book_with_online": "کلائنٹ اب آپ کے ساتھ آن لائن بک کر سکتے ہیں۔ شروع کرنے کے لیے نیچے دیے گئے لنک کا اشتراک کریں۔",
    "copy_link": "لنک کاپی کریں۔",
    "see_your_profile": "اپنا پروفائل دیکھیں",
    "ok_got_it": "ٹھیک ہے، سمجھ گیا۔",
    "preview_profile": "اپنے پروفائل کا پیش نظارہ کریں اور دیکھیں کہ یہ کیسا نظر آئے گا۔",
    "opening_hours": "کھلنے کے اوقات",
    "display_data": "ڈیٹا ڈسپلے کریں۔",
    "manage_profile": "پروفائل کا نظم کریں۔",
    "phone_req": "فون درکار ہے۔",
    "market_place_img_req": "مارکیٹ پلیس کی تصاویر درکار ہیں۔",
    "add_new_market_place": "نیو مارکیٹ پلیس شامل کریں۔",
    "edit_market_place": "مارکیٹ پلیس میں ترمیم کریں۔",
    "no_reviews_yet": "ابھی تک کوئی جائزہ نہیں ہے۔",
    "good": "اچھا",
    "average": "اوسط",
    "add_marketPlace": "مارکیٹ پلیس شامل کریں۔",
    "all_title": "تمام",
    "included_title": "شامل",
    "excluded_title": "خارج",
    "clients_subscribed": "کلائنٹس نے آخری میں سبسکرائب کیا۔",
    "clients_groups": "کلائنٹ گروپس",
    "customer_groups": "کسٹمر گروپس",
    "audience_title": "سامعین",
    "client_gt5": "جو آخر میں واپس نہیں آیا",
    "select_all": "سبھی کو منتخب کریں۔",
    "modify": "ترمیم کریں۔",
    "campaign_title": "مہم کا عنوان",
    "msg_type": "پیغام کی قسم",
    "enter_discount": "رعایت درج کریں۔",
    "discount_type": "ڈسکاؤنٹ کی قسم",
    "coupon_text": "کوپن کا متن",
    "enter_coupon_text": "کوپن کا متن درج کریں۔",
    "coupon_button_text": "کوپن بٹن کا متن",
    "enter_coupon_button_text": "کوپن بٹن کا متن درج کریں۔",
    "fine_prin": "عمدہ پرنٹ",
    "enter_fine_prin": "ٹھیک پرنٹ متن درج کریں۔",
    "campaign_dec": "آپ مہم کے آغاز کے وقت سے 30 منٹ پہلے اس میں ترمیم کر سکتے ہیں۔ ایس ایم ایس مہم کے لیے چارجز مہم کے آغاز کے وقت سے 30 منٹ پہلے لیے جائیں گے۔",
    "blast_text_message_dec": "آپ کا بلاسٹ ٹیکسٹ میسج تیار ہے۔ اپنی ادائیگی کا طریقہ منتخب کریں اور اپنے پیغامات بھیجیں۔",
    "payment_completed": " ادائیگی پر مکمل ہو جائے گا ",
    "total_cost": "کل لاگت",
    "close_title": "بند",
    "friday": "جمعہ",
    "saturday": "ہفتہ",
    "sunday": "اتوار",
    "thursday": "جمعرات",
    "tuesday": "منگل",
    "wednesday": "بدھ",
    "port_txt": "بندرگاہ",
    "today_title": "آج",
    "yesterday_title": "کل",
    "last_30_days_txt": "آخری 30 دن",
    "this_month_txt": "اس مہینے",
    "last_month_txt": "پچھلے مہینے",
    "valid_date_title": "درست تاریخ منتخب کریں۔",
    "valid_business_name_txt": "درست کاروباری نام درج کریں۔",
    "req_bus_add_txt": "کاروباری پتہ درکار ہے۔",
    "req_domain_name_txt": "ڈومین کا نام درکار ہے۔",
    "basic_info_txt": "آپ کے کاروبار کی بنیادی معلومات",
    "loyalty_qr_def_txt": "QR کوڈ اسکین کریں، ہمارے لائلٹی پنچ کارڈ میں شامل ہوں، اور انعامات میں شامل ہوں۔",
    "last_stamp_txt": "آخری ڈاک ٹکٹ",
    "collected_on_txt": "پر جمع کیا گیا۔",
    "stamp_details_txt": "ڈاک ٹکٹ کی تفصیلات",
    "add_stamp_txt": "ڈاک ٹکٹ شامل کریں۔",
    "choose_brand_color_txt": "اپنے برانڈ کا رنگ اور زبان 🎨 منتخب کریں۔",
    "change_anytime_txt": "آپ اسے کسی بھی وقت تبدیل کر سکتے ہیں۔",
    "like_to_get_txt": "کیا آپ حاصل کرنا چاہیں گے۔",
    "your_next_visit_txt": "آپ کے اگلے دورے پر؟",
    "time_to_time_get_offers": "کیا آپ وقتاً فوقتاً زبردست پیشکشیں اور پروموشن حاصل کرنا چاہیں گے؟",
    "respond_back_to_you": "کیا آپ چاہیں گے کہ کوئی آپ کو جواب دے؟",
    "input_serve_better": "آپ کا ان پٹ ہماری ٹیم کو آپ کی بہتر خدمت کرنے میں مدد کرے گا!",
    "do_it_txt": "آئیے یہ کرتے ہیں۔",
    "take_15_s": "15 سیکنڈ لگتے ہیں۔",
    "rate_on_google_desc": "کیا آپ ہمیں گوگل پر درجہ بندی کرنے کے لیے کافی مہربان ہوں گے؟",
    "may_be_next_time": "شاید اگلی بار",
    "thank_you_txt": "شکریہ",
    "look_next_txt": "ہم آپ کے اگلے دورے کے منتظر ہیں!",
    "deleteUserTitle": "اس صارف کو مستقل طور پر حذف کر دیا جائے گا۔",
    "deleteMUserTitle": "اس صارف کو مستقل طور پر حذف کر دیا جائے گا۔",
    "change_pin": "پن تبدیل کریں۔",
    "area_code": "آپ کا علاقہ کا کوڈ کیا ہے؟ ",
    "add_menu": "مینو شامل کریں۔",
    "menu_name": "مینو کا نام",
    "add_menu_placeholder": "پیزا، برگر، مشروبات وغیرہ",
    "enable_data_Collection": "ڈیٹا اکٹھا کرنے کو فعال کریں۔",
    "add_new_menu": "نیا مینو شامل کریں۔",
    "rename_menu": "مینو کا نام تبدیل کریں۔",
    "preview": "پیش نظارہ",
    "generate_qr": "تیار کردہ QR",
    "edit_menu": "مینو میں ترمیم کریں۔",
    "remove_menu": "مینو کو ہٹا دیں۔",
    "menu_delete_msg": "یہ مینو مستقل طور پر حذف کر دیا جائے گا۔",
    "menus_delete_msg": "یہ مینو مستقل طور پر حذف کر دیا جائے گا۔",
    "view_menu_dialoage_msg": "کیا آپ اپنی سالگرہ پر تحفہ لینا چاہیں گے؟",
    "skip": "چھوڑیں۔",
    "cliam_your_gift": "اپنے تحفے کا دعوی کریں۔",
    "collect_form": "فارم جمع کریں۔",
    "enter_first_name": "پہلا نام درج کریں۔",
    "enter_last_name": "آخری نام درج کریں۔",
    "enter_email": "ای میل درج کریں۔",
    "enter_dob": "تاریخ پیدائش درج کریں۔",
    "enter_number": "نمبر درج کریں۔",
    "select_gender": "صنف منتخب کریں۔",
    "congratulations_desc": "🎉 مبارک ہو! آپ کے گفٹ کلیم کی درخواست کامیابی کے ساتھ جمع کر دی گئی ہے۔ ہماری ٹیم جلد ہی آپ سے رابطہ کرے گی۔ 🎉",
    "male_heading": "مرد",
    "male_text": "مرد",
    "female_heading": "خاتون",
    "female_text": "خاتون",
    "others_heading": "دوسرے",
    "other_text": "دوسرے",
    "BirthD": "تاریخ پیدائش",
    "GenderT": "جنس",
    "EmailT": "ای میل",
    "dobT": "ڈی او بی",
    "capitalize_menu": "مینو",
    "select_menu": "مینو منتخب کریں۔",
    "manage_variant": "ویرینٹ کا نظم کریں۔",
    "add_products": "مصنوعات شامل کریں۔",
    "add_category": "زمرہ شامل کریں۔",
    "category_delete": "یہ زمرہ ہمیشہ کے لیے حذف کر دیا جائے گا۔",
    "variation_delete": "اس تغیر کو پروڈکٹ سے حذف کر دیا جائے گا۔",
    "product_delete": "اس پروڈکٹ کو مستقل طور پر حذف کر دیا جائے گا۔",
    "categories_delete": "اس زمرے کو مستقل طور پر حذف کر دیا جائے گا۔",
    "products_delete": "یہ مصنوعات مستقل طور پر حذف کر دی جائیں گی۔",
    "category": "زمرہ",
    "price": "قیمت",
    "food_product_placeholder": "پیزا، برگر، مشروبات وغیرہ",
    "active_title": "فعال",
    "inActive_title": "غیر فعال",
    "status_capital": "اسٹیٹس",
    "cat_status_require": "زمرہ کی حیثیت درکار ہے۔",
    "cat_name_require": "زمرہ کا نام درکار ہے۔",
    "category_name": "زمرہ کا نام",
    "status": "حیثیت",
    "lgDay1": "پیر",
    "lgDay2": "منگل",
    "lgDay3": "بدھ",
    "lgDay4": "جمعرات",
    "lgDay5": "جمعہ",
    "lgDay6": "ہفتہ",
    "lgDay7": "اتوار",
    "is_closed_title": "بند",
    "book_table_title": "بک اے ٹیبل",
    "emailErrSub": "ای میل کا موضوع درکار ہے۔",
    "email_subject": "ای میل کا موضوع",
    "contactUsfrmTitleEmail": "ای میل کا جواب دیں۔",
    "companyInfo": "Lorem Ipsum lorem hrig ہے، lorem ipsum fraets ہے۔ Lorem Ipsum lorem hrig ہے، lorem ipsum fraets ہے۔ Lorem Ipsum lorem hrig ہے، lorem ipsum fraets ہے۔",
    "footerSTitle1": "مینو آن لائن کے بارے میں",
    "footerSTitle1Lnk2": "خصوصیات",
    "footerSTitle1Lnk3": "بلاگ",
    "footerSTitle2": "قانونی",
    "footerSTitle2Lnk1": "شرائط و ضوابط",
    "footerSTitle2Lnk2": "رازداری کی پالیسی",
    "footerSTitle3": "کاروبار کے لیے",
    "footerSTitle3Lnk1": "شراکت داروں کے لیے",
    "footerSTitle3Lnk2": "قیمتوں کا تعین",
    "footerSTitle3Lnk3": "شراکت داروں کے لیے تعاون",
    "footerCopyRithgTxt": "  مینو آن لائن یا اس سے وابستہ افراد",
    "homeTitle": "گھر",
    "contactTitle": "ہم سے رابطہ کریں۔",
    "aboutTitle": "ہمارے بارے میں",
    "homeMenuTitle": "مینو",
    "bookTableTitle": "بک اے ٹیبل",
    "vDateT": "درست تاریخ منتخب کریں۔",
    "reqDateT": "تاریخ درکار ہے۔",
    "dateGtErr": "تاریخ آج کی تاریخ سے بعد کی یا اس کے برابر ہونی چاہیے۔",
    "timeInvalid": "وقت غلط ہے۔",
    "reqTimeT": "وقت درکار ہے۔",
    "timeInvalidBfr": "موجودہ وقت سے کم از کم 5 منٹ بعد کا وقت منتخب کریں۔ ",
    "PerReq": "افراد کی تعداد درکار ہے۔",
    "validcNoT": "درست رابطہ نمبر درج کریں۔",
    "reqCrT": "کرنسی درکار ہے۔",
    "reqPrT": "قیمت درکار ہے۔",
    "reCateT": "زمرہ درکار ہے۔",
    "reqVrT": "تغیر درکار ہے۔",
    "reqVrVldT": "درست قسم درج کریں۔ ",
    "validLNameT": "درست آخری نام درج کریں۔",
    "sDateInValid": "تاریخ سے غلط ہے۔",
    "minmReq": "کم از کم درکار ہے۔",
    "resLblDate": "تاریخ منتخب کریں۔",
    "resLblTime": "وقت منتخب کریں۔",
    "perT": "کتنے لوگوں کے لیے؟",
    "resLblEmail": "براہ کرم اپنا ای میل فراہم کریں؟",
    "resLblNote": "ریزرویشن نوٹ شامل کریں۔",
    "imageError1MB": "براہ کرم 1 ایم بی سے کم کی تصویر داخل کریں۔",
    "imageError": "براہ کرم 2.5mb سے کم کی تصویر داخل کریں۔",
    "imageError3Mb": "براہ کرم 3 ایم بی سے کم کی تصویر داخل کریں۔",
    "imageInVldError": "فائل غلط ہے۔",
    "addProMT": "پروڈکٹ مینو",
    "proT": "پروڈکٹ کا نام",
    "reqProT": "پروڈکٹ کا نام درکار ہے۔",
    "proPT": "پروڈکٹ کی قیمت",
    "reservationSuccTitle": "بہت اچھا، آپ کی ریزرویشن ہو گئی۔",
    "book": "کتاب",
    "custzName": "حسب ضرورت نام",
    "always": "ہمیشہ",
    "proImgReq": "پروڈکٹ کی تصویر درکار ہے۔",
    "selCustmT": "براہ کرم حسب ضرورت منتخب کریں یا نیا بنائیں",
    "visStR": "مرئیت کی حیثیت درکار ہے۔",
    "avlblScR": "براہ کرم دستیابی کا شیڈول منتخب کریں۔",
    "addonPR": "براہ کرم ایڈون مصنوعات کو منتخب کریں۔",
    "upsellPR": "براہ کرم فروخت شدہ مصنوعات کو منتخب کریں۔",
    "markItemR": "براہ کرم آئٹم کی مصنوعات کو نشان زد کریں۔",
    "caloriesR": "کیلوریز کی ضرورت ہے۔",
    "allergensR": "براہ کرم الرجین مصنوعات کو منتخب کریں۔",
    "prepInstR": "تیاری کی ہدایات درکار ہیں۔",
    "staffNR": "عملے کے نوٹس درکار ہیں۔",
    "discountR": "رعایت درکار ہے۔",
    "validDiscE": "درست رعایت درج کریں۔",
    "disday": "دن کی طرف سے",
    "plSelDayT": "براہ کرم دن منتخب کریں۔",
    "sTimeReq": "آغاز کا وقت درکار ہے۔",
    "sTimeInValid": "آغاز کا وقت غلط ہے۔",
    "eTimeReq": "اختتامی وقت درکار ہے۔",
    "eTimeInValid": "اختتامی وقت غلط ہے۔",
    "sDateReq": "تاریخ سے درکار ہے۔",
    "eDateReq": "آج تک کی ضرورت ہے۔",
    "eDateInValid": "آج تک غلط ہے۔",
    "disdate": "تاریخ سے",
    "disdate1": "آج تک",
    "disdate2": "تاریخ سے",
    "currT": "کرنسی",
    "iconR": "براہ کرم نشان آئٹم آئیکن کو منتخب کریں۔",
    "minT": "کم از کم",
    "maxT": "زیادہ سے زیادہ",
    "itemNT": "آئٹم کا نام",
    "itemPT": "آئٹم کی قیمت",
    "descProT": "اپنی مصنوعات کی وضاحت کریں۔",
    "cateT": "زمرہ",
    "selProCateT": "اپنے پروڈکٹ کا زمرہ منتخب کریں۔",
    "reqMT": "مینو کا نام درکار ہے۔",
    "ViewMenu": "مینو دیکھیں",
    "CopyMenu": "مینو کاپی کریں۔",
    "EditMenu": "مینو میں ترمیم کریں۔",
    "RemoveMenu": "مینو کو ہٹا دیں۔",
    "welcomeMnuTitle": "میں خوش آمدید",
    "reviewT": "جائزے",
    "userErrorMsg": "معذرت، ایک غلطی ہو گئی!!!",
    "reqCateT": "زمرہ کا نام درکار ہے۔",
    "mngCustT": "حسب ضرورت کا نظم کریں۔",
    "custNReq": "حسب ضرورت نام درکار ہے۔",
    "incReq": "شامل ضروری ہے۔",
    "minmValid": "درست کم از کم قدر درج کریں۔",
    "maxmReq": "زیادہ سے زیادہ کی ضرورت ہے۔",
    "cust": "حسب ضرورت",
    "crCust": "نئی حسب ضرورت بنائیں",
    "custList": "حسب ضرورت فہرست",
    "delCustmzT": "اس حسب ضرورت کو مستقل طور پر حذف کر دیا جائے گا۔",
    "mkNameR": "آئٹم کے نام کو نشان زد کرنا ضروری ہے۔",
    "mkDelT": "یہ نشان والی چیز ہمیشہ کے لیے حذف کر دی جائے گی۔",
    "hideText": "چھپائیں",
    "showText": "دکھائیں۔",
    "device_mac_txt": "ڈیوائس میک",
    "delete_hotspot_txt": "اس ہاٹ اسپاٹ کو مستقل طور پر حذف کر دیا جائے گا۔",
    "delete_hotspots_txt": "اس ہاٹ سپاٹ کو مستقل طور پر حذف کر دیا جائے گا۔",
    "emp_wifi_name": "ملازم وائی فائی کا نام",
    "max_limit_txt": "اسے لامحدود کے طور پر نامزد کرنے کے لیے رفتار کے لیے '1024' درج کریں۔ یہ قدر کسی بھی کیپس کو ہٹا دیتی ہے، زیادہ سے زیادہ تھرو پٹ کی اجازت دیتی ہے۔",
    "device_port_txt": "ڈیوائس پورٹ",
    "firmware_txt": "فرم ویئر",
    "hotspot_info_txt": "ہاٹ سپاٹ کی معلومات",
    "editDBtn": "تفصیلات میں ترمیم کریں۔",
    "birth_date": "تاریخ پیدائش",
    "di_theme_clr": "تھیم کا رنگ بالکل 7 ہندسوں کا ہے۔",
    "color_Invalid_txt": "درست رنگ کا کوڈ منتخب کریں۔",
    "Req_theme_clr": "تھیم کا رنگ درکار ہے۔",
    "select_valid_color_txt": "براہ کرم درست تھیم کا رنگ منتخب کریں۔",
    "req_redir_link": "ری ڈائریکٹ لنک درکار ہے۔",
    "val_redir_link": "درست ری ڈائریکٹ لنک درج کریں۔",
    "req_business_name_txt": "کاروبار کا نام درکار ہے۔",
    "splash_preview": "سپلیش پیش نظارہ",
    "create_new_splash": "نیا سپلیش بنائیں",
    "splash_page": "سپلیش پیج",
    "splash_page_builder": "سپلیش پیج بلڈر",
    "redirect_link": "ری ڈائریکٹ لنک",
    "theme_color": "تھیم کا رنگ",
    "enable_social_login": "صارفین کے لاگ ان کے لیے سوشل میڈیا کے اختیارات کو فعال کریں۔",
    "google": "گوگل",
    "facebook": "فیس بک",
    "is_mandatory": "کیا یہ لازمی ہے؟",
    "field": "میدان",
    "name": "نام",
    "first_name": "پہلا نام",
    "last_name": "آخری نام",
    "birthdate": "تاریخ پیدائش",
    "gender": "جنس",
    "email": "ای میل",
    "dob": "ڈی او بی",
    "zip_code": "زپ کوڈ",
    "required_redirect_link": "ری ڈائریکٹ لنک درکار ہے۔",
    "valid_redirect_link": "درست ری ڈائریکٹ لنک درج کریں۔",
    "required_theme_color": "تھیم کا رنگ درکار ہے۔",
    "theme_color_length": "تھیم کا رنگ بالکل 7 ہندسوں کا ہے۔",
    "required_name": "نام درکار ہے۔",
    "delete_splash_message": "آپ اس سپلیش کو حذف کرنا چاہتے ہیں؟",
    "delete_multiple_splashes_message": "آپ ان سپلیشز کو حذف کرنا چاہتے ہیں؟",
    "user_login_required": "صارف لاگ ان کی ضرورت ہے۔",
    "set_theme_color": "تھیم کا رنگ سیٹ کریں۔",
    "splash_colllect_additional_info_txt": "اضافی معلومات جمع کریں۔",
    "verify_mobile_otp_message": "کیا آپ موبائل نمبر کی تصدیق کے لیے OTP بھیجنا چاہیں گے؟",
    "add_company": "کمپنی شامل کریں۔",
    "edit_company": "کمپنی میں ترمیم کریں۔",
    "ds_device": "ڈیجیٹل اشارے کا آلہ",
    "ds_device_activation_fee": "ڈیجیٹل سائنیج ڈیوائس ایکٹیویشن فیس",
    "wifi_hardware": "وائی ​​فائی ہارڈ ویئر",
    "menu_design": "مینو ڈیزائن",
    "ds_hardware": "ڈیجیٹل سگنل ہارڈ ویئر",
    "company_name_req": "کمپنی کا نام درکار ہے۔",
    "known_name_req": "معلوم نام درکار ہے۔",
    "no_location_req": "مقام کی تعداد درکار ہے۔",
    "minimum_location": "براہ کرم کم از کم 1 مقام درج کریں۔",
    "terms_req": "شرائط درکار ہیں۔",
    "notes_req": "نوٹس درکار ہیں۔",
    "sms_charge_req": "SMS چارج درکار ہے۔",
    "menuonline_quantity_req": "مینو آن لائن مقدار درکار ہے۔",
    "menuonline_cost_req": "مینو آن لائن لاگت درکار ہے۔",
    "ds_quantity_req": "ڈیجیٹل اشارے کی مقدار درکار ہے۔",
    "ds_monthly_cost_req": "ڈیجیٹل اشارے کی ماہانہ لاگت درکار ہے۔",
    "reputation_quantity_req": "شہرت کی مقدار درکار ہے۔",
    "reputation_cost_req": "ساکھ کی لاگت درکار ہے۔",
    "marketing_quantity_req": "مارکیٹنگ کی مقدار درکار ہے۔",
    "marketing_cost_req": "مارکیٹنگ لاگت درکار ہے۔",
    "tax_percentage_req": "ٹیکس فیصد درکار ہے۔",
    "router_hardware_quantity_req": "راؤٹر ہارڈ ویئر کی مقدار درکار ہے۔",
    "wifi_hardware_cost_req": "وائی ​​فائی ہارڈ ویئر کی قیمت درکار ہے۔",
    "activation_cost_req": "چالو کرنے کی لاگت درکار ہے۔",
    "wifi_quantity_req": "وائی ​​فائی کی مقدار درکار ہے۔",
    "wifi_router_req": "وائی ​​فائی راؤٹر درکار ہے۔",
    "menu_design_quantity_req": "مینو ڈیزائن کی مقدار درکار ہے۔",
    "menu_design_cost_req": "مینو ڈیزائن لاگت درکار ہے۔",
    "quantity_req": "مقدار درکار ہے۔",
    "cost_req": "لاگت درکار ہے۔",
    "sheduledown_quantity_req": "شیڈیول کم مقدار کی ضرورت ہے۔",
    "sheduledown_cost_req": "شیڈیول ڈاون لاگت درکار ہے۔",
    "loyalty_program_quantity_req": "وفاداری پروگرام کی مقدار درکار ہے۔",
    "loyalty_program_cost_req": "وفاداری پروگرام کی لاگت درکار ہے۔",
    "loyalty_program_sms_quantity_req": "لائلٹی پروگرام ایس ایم ایس کی مقدار درکار ہے۔",
    "loyalty_program_sms_cost_req": "لائلٹی پروگرام ایس ایم ایس لاگت درکار ہے۔",
    "comapny_created": "کمپنی کامیابی کے ساتھ بنائی گئی۔",
    "comapny_updated": "کمپنی کامیابی کے ساتھ اپ ڈیٹ ہو گئی۔",
    "billing_info": "بلنگ کی معلومات",
    "business_name_acc_holder": "کاروباری نام (اکاؤنٹ ہولڈر)",
    "client_first_name": "کلائنٹ کا پہلا نام",
    "client_last_name": "کلائنٹ کا آخری نام",
    "dba_known_as": "DBA کے نام سے جانا جاتا ہے۔",
    "business_address": "کاروباری پتہ",
    "no_of_locations": "مقامات کی تعداد",
    "package_details": "پیکیج کی تفصیلات",
    "terms": "شرائط",
    "one_month": "1 مہینہ",
    "per_sms_charge": "فی SMS چارج",
    "plan_test": "منصوبہ: ٹیسٹ",
    "desc_capital": "تفصیل",
    "qty_capital": "مقدار",
    "cost_capital": "لاگت",
    "total_price_capital": "کل قیمت",
    "quantity": "مقدار",
    "can_not_access": "آپ اس سے زیادہ کا انتخاب نہیں کر سکتے",
    "licenses": "لائسنس",
    "monthly_cost": "ماہانہ لاگت",
    "plan_cost": "منصوبہ لاگت",
    "tax": "ٹیکس",
    "tax_percentage": "ٹیکس فیصد",
    "monthly_cost_after_tax": "ٹیکس کے بعد ماہانہ لاگت",
    "activation_one_time_charge": "ایکٹیویشن اور ون ٹائم چارج",
    "sub_total": "SUB TOTAL",
    "action": "ایکشن",
    "cost": "لاگت",
    "shipping_charge": "شپنگ چارج",
    "other": "دیگر",
    "additional_cost": "اضافی قیمت (دیگر چارجز)",
    "other_tax": "دیگر ٹیکس",
    "total": "کل",
    "license_statistics": "لائسنس کے اعدادوشمار",
    "total_licenses": "کل لائسنس",
    "available_licenses": "دستیاب لائسنس",
    "stock": "اسٹاک",
    "has_permission": "کی اجازت ہے۔",
    "avg_stock_price": "AVG اسٹاک کی قیمت",
    "average_price": "اوسط قیمت",
    "allocated": "مختص",
    "reward_regulars": "ریگولر انعامات",
    "not_add_coupon_url": "کوپن URL شامل نہیں کیا جا سکتا۔ پیغام کی زیادہ سے زیادہ لمبائی 160 ہے۔",
    "like_attach_coupon": "کیا آپ کوپن منسلک کرنا چاہیں گے؟",
    "advance_scheduling": "ایڈوانس شیڈولنگ",
    "choose_day": "پیغام بھیجنے کے لیے دن کا انتخاب کریں۔",
    "select_essage_window": "میسج ونڈو کو منتخب کریں۔",
    "subscription_outside_delivery_window": "اگر کوئی صارف ڈیلیوری ونڈو کے باہر سبسکرائب کرتا ہے، تو وہ اگلے دستیاب وقت پر آپ کا خودکار جواب دہندہ وصول کرے گا۔",
    "remaining": "باقی",
    "processing_request": "آپ کی درخواست پر کارروائی کرتے وقت ایک خرابی پیش آگئی۔",
    "list_companies": "کمپنیوں کی فہرست",
    "are_you_sure": "کیا آپ کو یقین ہے..؟",
    "signin_agreement": "اس کی تصدیق کرکے، کمپنی کسی معاہدے پر دستخط کیے بغیر تخلیق کرے گی۔",
    "status_updated": "اسٹیٹس کو کامیابی سے اپ ڈیٹ کر دیا گیا۔",
    "status_failed": "اسٹیٹس کو اپ ڈیٹ کرنے میں ناکام",
    "new_editor_status_updated": "ڈی ایس کے نئے ایڈیٹر کی حیثیت کو کامیابی کے ساتھ اپ ڈیٹ کر دیا گیا ہے۔",
    "user_name": "صارف کا نام",
    "known_as": "کے طور پر جانا جاتا ہے",
    "agreement_signed": "معاہدے پر دستخط ہوئے۔",
    "deactivate": "غیر فعال کریں۔",
    "activate": "چالو کریں۔",
    "login_to_user_acc": "صارف اکاؤنٹ میں لاگ ان کریں۔",
    "disable": "غیر فعال کریں۔",
    "enable": "فعال کریں۔",
    "ds_new_editor": "ڈی ایس نئے ایڈیٹر",
    "sign_agreement": "معاہدے پر دستخط کریں۔",
    "agreement_sign": "معاہدہ W\/O دستخط",
    "view_agreement": "معاہدہ دیکھیں",
    "download_agreement": "معاہدہ ڈاؤن لوڈ کریں۔",
    "add_deleted_user": "حذف شدہ صارف شامل کریں۔",
    "deleted_user": "صارف کو حذف کریں۔",
    "favourite": "پسندیدہ",
    "refresh": "ریفریش کریں۔",
    "delete_chat": "چیٹ کو حذف کریں۔",
    "responsive_drawer": "ذمہ دار دراز",
    "delete_contact": "رابطہ حذف کریں۔",
    "clear_chat_history": "چیٹ کی سرگزشت صاف کریں۔",
    "clear_chat_history_dec": "کیا آپ واقعی اس چیٹ کو حذف کرنا چاہتے ہیں؟",
    "clear_contact_dec": "کیا آپ واقعی اس رابطے کو حذف کرنا چاہتے ہیں؟",
    "select_contact": "رابطہ منتخب کریں۔",
    "new_conversation": "نئی گفتگو شروع کریں۔",
    "type_msg": "اپنا پیغام یہاں ٹائپ کریں۔",
    "select_delete_client": "حذف کرنے کے لیے براہ کرم کلائنٹ کو منتخب کریں۔",
    "select_delete_client_chat": "براہ کرم چیٹ کو حذف کرنے کے لیے کلائنٹ کو منتخب کریں۔",
    "select_delete_client_chat_err": "آپ کی اس کلائنٹ کے ساتھ بات چیت نہیں ہے۔",
    "acquire_customers_business": "دوسرے کاروبار سے نئے گاہک حاصل کریں۔",
    "customers_from_events": "واقعات سے گاہکوں کو حاصل کریں اور اسے اپنے پاس لائیں!",
    "customers_from_ads": "اخباری اشتہارات سے صارفین حاصل کریں۔",
    "smart_pamphlets": "کسٹمر ڈیٹا اکٹھا کرنے کے لیے سمارٹ پمفلٹ بنائیں۔",
    "smart_qr_group": "صارفین کے لیے گروپ میں شامل ہونے کے لیے ایک اسمارٹ QR۔",
    "opt_in_number": "صارفین کو نمبر آپٹ ان کرنے کے قابل بنائیں۔",
    "qr_menu": "اپنے مینو کو دکھانے کے لیے QR کوڈ بنائیں۔",
    "collect_birthdays": "گاہک کی سالگرہ اور سالگرہ جمع کریں۔",
    "join_loyalty_program": "میرے لائلٹی پروگرام میں شامل ہونے کے لیے نئے گاہک حاصل کریں۔",
    "create_feedback_forms": "کلائنٹ کے تاثرات جمع کرنے کے لیے فارم بنائیں۔",
    "exclusive_discounts_promotions": "کراس پروموشنز کے لیے خصوصی رعایتیں اور پروموشنز پیش کریں۔",
    "group_smart_qr_opt_in": "ایک گروپ بنانے کے بعد، آپ ایک سمارٹ QR کوڈ تیار اور منتخب کر سکتے ہیں، جس سے صارفین فوری طور پر گروپ میں آپٹ ان کر سکتے ہیں۔",
    "group_smart_qr_opt_in_dec": "ایک گروپ بنانے کے بعد، امیج ویجیٹ صارفین کو ٹیکسٹ استعمال کرنے اور پروموشنز حاصل کرنے کے قابل بناتا ہے، مثال کے طور پر، \"پیزا\" کو 555-555-5555 پر ٹیکسٹ کریں۔",
    "in_store_discount_next_visit": "ہمارے مقام پر اپنے اگلے دورے کے دوران اسٹور میں رعایت حاصل کریں۔",
    "delivery_redirection": "ڈیلیوری ری ڈائریکشن بنائیں",
    "additional_info": "اضافی معلومات",
    "add_url": "URL شامل کریں۔",
    "custom_fields": "حسب ضرورت فیلڈز",
    "meta_tag": "میٹا ٹیگ",
    "max_chars": "زیادہ سے زیادہ حروف",
    "add": "شامل کریں۔",
    "update_campaign": "مہم کو اپ ڈیٹ کریں۔",
    "last_week_required": "گزشتہ ہفتے کی ضرورت ہے",
    "large_week_value": "ہفتے کی قدر بہت بڑی ہے درست ہفتہ درج کریں۔",
    "less_than_last_week": "پچھلے ہفتے میں واپسی نہیں ہونی چاہیے پچھلے ہفتے سے کم۔",
    "last_week_req": "پچھلے ہفتے میں واپسی کی ضرورت نہیں ہے۔",
    "birthday_special": "سالگرہ کا خصوصی",
    "birthday_message": "ذاتی مبارکبادوں اور دلکش پیشکشوں کے ساتھ ان کی سالگرہ پر خودکار پیغام بھیجا گیا۔",
    "birthday_sms": "SMS\/MMS کے لیے ذاتی نوعیت کی سالگرہ کی مبارکبادیں بنائیں",
    "target_audience": "ہدف کے سامعین کا انتخاب کریں۔",
    "client_by_birthday": "کلائنٹ کی طرف سے سالگرہ",
    "clients_once_year": "یہ مہم سال میں ایک بار گاہکوں کو خود بخود بھیجتی ہے۔ ",
    "auto_responder_default_msg_1": "آپ ہمارے بہترین صارفین میں سے ایک ہیں جنہیں ہم رعایت دینا پسند کرتے ہیں! آپ ہمیشہ دولت مند اور صحت مند رہیں۔ سالگرہ مبارک ہو! %% پہلا نام %%",
    "campaign_send_to_client_birthday": "یہ آٹو مہم کلائنٹس کو سالگرہ کے ساتھ بھیجتی ہے، آپ نیچے ہدف کو اپنی مرضی کے مطابق بنا سکتے ہیں۔",
    "get_more_engagement": "b'day SMS بھیج کر مزید مصروفیت حاصل کریں۔",
    "welcome_new_clients": "نئے گاہکوں کو خوش آمدید",
    "make_lasting_impression": "پہلی بار کھانے والوں کو گرمجوشی سے سلام اور خوش آمدید کہہ کر ایک دیرپا تاثر بنائیں",
    "personalized_greetings": "نئے کلائنٹس کے لیے ذاتی نوعیت کی مبارکبادیں بنائیں",
    "greetings_to_new_customers": "یہ مہم نئے صارفین کو آپ کے کاروبار کے ساتھ ان کے پہلے تعامل کے اگلے دن خوش آمدید کہے گی۔",
    "audience_predefined": "اس خودکار جواب دہندہ کے سامعین پہلے سے طے شدہ ہیں، ہمیں یہ مل گیا ہے!",
    "send_clients_subscribed": "یہ خودکار جواب دہندہ آخر میں سبسکرائب کیے گئے کلائنٹس کو بھیجنے کے لیے تیار ہے۔ ",
    "first_interaction": "  ان کی پہلی بات چیت کے بعد۔",
    "default_msg_2": "ہم امید کرتے ہیں کہ آپ نے اپنی پہلی ملاقات کا لطف اٹھایا، ہم آپ کو جلد ہی دوبارہ دیکھنا پسند کریں گے اس لیے ہم صرف آپ کے لیے رعایت پیش کر رہے ہیں! ہمارے ساتھ اپنی اگلی ملاقات بُک کرنے کے لیے لنک پر کلک کریں اور اپنی خصوصی آن لائن پیشکش کو ابھی بھنائیں۔",
    "new_clients_update": "نئے کلائنٹس کو اپ ڈیٹ مہم کے متن میں خوش آمدید",
    "new_clients_warm_greeting": "نئے کلائنٹس کو پرتپاک سلام کے ساتھ خوش آمدید 💌",
    "win_back_clients": "گاہکوں کو واپس جیتو",
    "re_engage_past_customers": "ماضی کے صارفین کو واپس آنے کی ترغیب دینے کے لیے ناقابل تلافی پیشکشوں کے ساتھ دوبارہ مشغول کریں۔",
    "target_disengaged_clients": "غیر منقطع کلائنٹس کو خصوصی پیشکش کے ساتھ واپس آنے کا ہدف بنائیں، کلائنٹس کو واپس راغب کرنے کا ایک ثابت شدہ حربہ۔",
    "campaign_sends_to_clients": "یہ مہم ان کلائنٹس کو بھیجتی ہے جو مقررہ مدت کے بعد واپس نہیں آئے۔",
    "customize_targeting": "ختم شدہ کلائنٹس کو SMS\/MMS بھیجنے کے لیے نیچے ہدف کو حسب ضرورت بنائیں۔",
    "clients_with_at_least": "کم از کم کے ساتھ کلائنٹ ",
    "connection_last": " آخری میں کنکشن ",
    "return_in_last_week": " ہفتہ، لیکن آخری میں واپس نہیں آیا ",
    "arr_menu_44": " ہفتہ",
    "df_msg_4": "ہم آپ کو واپس چاہتے ہیں بہت برا کہ ہم آپ کو صرف واپسی پر 10% کی چھوٹ دینے جا رہے ہیں! آج ہمیں دوبارہ کوشش کرنا چاہتے ہیں؟",
    "update_campaign_text": "جیت واپس کلائنٹس اپ ڈیٹ مہم کے متن",
    "re_invite_customers": "ان صارفین کو دوبارہ مدعو کریں جو تھوڑی دیر سے واپس نہیں آئے ہیں۔",
    "loyal_patrons": "خصوصی رعایتوں اور وفاداری کے انعامات کے ساتھ وفادار سرپرستوں کی تعریف کریں۔",
    "surprize_top_spenders": "ذاتی اور خصوصی پیشکشوں کے ساتھ سب سے زیادہ خرچ کرنے والوں کو سرپرائز کریں۔",
    "campaign_automatically_send": "یہ مہم آپ کے کاروبار میں قدموں کو بڑھانے کے لیے خود بخود انعامات بھیجتی ہے۔",
    "sms_loyal_clients": "یہ مہم وفادار کلائنٹس کو SMS\/MMS بھیجتی ہے، آپ نیچے ہدف کو تبدیل کر سکتے ہیں۔",
    "or_more_connection": " یا زیادہ کنکشن، آخری کے اندر ",
    "week_period": " ہفتے کی مدت.",
    "default_msg_5": "آپ اب طویل عرصے سے ایک گاہک ہیں! واہ، وقت اڑتا ہے! ہم آپ کی تعریف کرتے ہیں اور ہمارے ساتھ رہنے کے لیے آپ کا شکریہ ادا کرنا چاہتے ہیں۔",
    "reward_regulars_update": "ریوارڈ ریگولر مہم کے متن کو اپ ڈیٹ کریں۔",
    "special_discounts": "تعریف کریں اور خصوصی رعایت دیں💎",
    "leave_positive_online": "ریستوراں کی ساکھ کو بڑھانے کے لیے گاہکوں کو مثبت آن لائن جائزے دینے کی ترغیب دیں۔",
    "clients_service_online": "مہم آپ کے گاہکوں کو آپ کی سروس کی آن لائن درجہ بندی کرنے کی ترغیب دیتی ہے۔",
    "increase_your_company": "یہ خودکار جواب دہندہ آپ کی کمپنی کی آن لائن ریٹنگ بڑھانے کے لیے بھیجا جاتا ہے۔",
    "send_message_after": "اس کے بعد پیغام بھیجیں۔ ",
    "min_connection": " کنکشن کا منٹ",
    "default_msg_6": "ہم امید کرتے ہیں کہ آپ نے اپنی پہلی ملاقات کا لطف اٹھایا، ہم جلد ہی آپ سے دوبارہ ملنا پسند کریں گے! [URL] گوگل پر ہماری درجہ بندی کرنے کے لیے لنک پر کلک کریں۔",
    "ratings_update": "مزید ریٹنگز اپ ڈیٹ مہم کا متن حاصل کریں۔",
    "friendly_nudge": "دوستانہ نج کے ساتھ مزید جائزے حاصل کریں ⭐",
    "thanks_for_visiting": "تشریف لانے کا شکریہ",
    "express_gratitude": "دل کی گہرائیوں سے شکریہ کے پیغامات کے ساتھ اپنے کاروبار کا انتخاب کرنے کے لیے صارفین کا شکریہ ادا کریں۔",
    "thank_you_message": "اپنے گاہکوں کے لیے شکریہ کا پیغام بنائیں۔",
    "clients_for_visiting": "اپنے کلائنٹس کو آپ کی جگہ آنے کے لیے دل سے شکریہ بھیجیں۔",
    "campaign_audience": "یہ آٹو مہم کے سامعین پہلے سے طے شدہ ہیں، ہمارے پاس یہ ہے!",
    "campaign_automatically_sends": "یہ آٹو مہم خود بخود بعد میں بھیجتی ہے۔ ",
    "minutes_customer_visit": " کسٹمر کے دورے کے منٹ",
    "default_msg_7": "ہمارے قابل قدر کسٹمر ہونے کے لیے آپ کا شکریہ۔ ہم آپ کی خدمت کرنے کی خوشی کے لیے بہت مشکور ہیں اور امید کرتے ہیں کہ ہم آپ کی توقعات پر پورا اتریں گے۔",
    "visiting_update_campaign": "اپ ڈیٹ مہم کے متن کو دیکھنے کے لیے آپ کا شکریہ",
    "guests_thank_you": "اپنے مہمانوں کی آمد کے لیے ان کا شکریہ 😊",
    "download_title": "ڈاؤن لوڈ کریں۔",
    "qr_gen_title": "QR تیار کیا گیا۔",
    "qr_download_s_title": "QR کوڈ کامیابی سے ڈاؤن لوڈ ہو گیا۔",
    "sel_dark_brand_clr_msg": "براہ کرم درست بارنڈ رنگ منتخب کریں۔",
    "manage_customer_stamp_rewards": "کسٹمر سٹیمپ انعامات کا نظم کریں 🎁",
    "sel_loc_menu_title": "اپنے مینو کے لیے مقامات کا انتخاب کریں۔",
    "ans_req": "جواب مطلوب ہے۔",
    "valid_reputation_name": "درست شہرت کا نام درج کریں۔",
    "reviews_name_req": "مزید جائزے حاصل کریں نام درکار ہے۔",
    "birthdate_required": "تاریخ پیدائش درکار ہے۔",
    "gender_required": "جنس درکار ہے۔",
    "valid_birthdate_required": "درست تاریخ پیدائش درج کریں۔",
    "custom_delivery_redirection": "گروپس کے ذریعہ ایک حسب ضرورت ڈیلیوری ری ڈائریکشن فارم بنائیں",
    "customer_fields_incentive_settings": "کسٹمر فیلڈز اور ترغیب کی ترتیبات",
    "delivery_redirection_text": "ڈیلیوری ری ڈائریکشن",
    "delivery_redirection_success": "مبارک ہو! آپ کا ڈیلیوری ری ڈائریکشن ویجیٹ بنایا گیا! 🎉",
    "swipe_to_preview_redirection": "یہ دیکھنے کے لیے سوائپ کریں کہ آپ کا ڈیلیوری ری ڈائریکشن فارم کیسا نظر آئے گا۔",
    "enter_item_name": "آئٹم کا نام درج کریں۔",
    "home_page_text": "ہوم پیج کا متن",
    "settings": "ترتیبات",
    "anniversary_required": "سالگرہ کی تاریخ درکار ہے۔",
    "valid_anniversary": "درست سالگرہ کی تاریخ درج کریں۔",
    "form_submited": "فارم کامیابی سے جمع کرایا",
    "notifications": "اطلاعات",
    "discount_message": "اب پیزا پر اپنی 20% چھوٹ کا دعوی کریں!",
    "is_required": " کی ضرورت ہے",
    "section_title_required": "سیکشن کا عنوان درکار ہے۔",
    "section_dec_required": "سیکشن کی تفصیل درکار ہے۔",
    "client_details_required": "کلائنٹ کی تفصیلات والے فیلڈز درکار ہیں۔",
    "compliance": "تعمیل",
    "SMS_campaign1": "SMS مہم 1",
    "mobile_number_mandatory": "موبائل نمبر لازمی ہے۔",
    "new_answer": "نیا جواب",
    "new_question": "نیا سوال",
    "add_new_question": "نیا جواب شامل کریں۔",
    "select": "منتخب کریں۔",
    "group_customers_question": "آپ اپنے گاہکوں کو کہاں گروپ کرنا چاہتے ہیں؟",
    "contacts_added_to_group": "فارم جمع کرانے والے تمام رابطوں کو منتخب گروپ میں شامل کر دیا جائے گا۔",
    "edit_client_details_section": "کلائنٹ کی تفصیلات کے سیکشن میں ترمیم کریں۔",
    "client_details_fields": "کلائنٹ کی تفصیلات کے فیلڈز",
    "enter_client_details_section_title_desc": "کلائنٹ کی تفصیلات کے سیکشن کا عنوان اور تفصیل درج کریں۔",
    "choose_fields_displayed_client_side": "کلائنٹ کی طرف دکھائے جانے والے فیلڈز کا انتخاب کریں۔",
    "section_title": "سیکشن کا عنوان",
    "add_title": "عنوان شامل کریں۔",
    "section_description": "سیکشن کی تفصیل",
    "add_description": "تفصیل شامل کریں۔",
    "enter_program_name_location": "پروگرام کا نام اور مقام 📍 درج کریں۔",
    "brand_theme_clr_txt": "برانڈ تھیم کا رنگ",
    "set_loyalty_punch_card_theme": "آئیے آپ کے لائلٹی پنچ کارڈ 🎨 کے لیے ایک تھیم سیٹ کریں۔",
    "upload_logo_txt": "اپنا لوگو اپ لوڈ کریں۔",
    "recommended_image_specs": "بہترین ڈسپلے کے لیے ہم 512 x 512px والی تصویر استعمال کرنے کی تجویز کرتے ہیں۔ JPG، SVG یا PNG۔ 10MB کا زیادہ سے زیادہ سائز۔",
    "valid_rep_name_txt": "درست شہرت کا نام درج کریں۔",
    "rep_name_req_txt": "مزید جائزے حاصل کریں نام درکار ہے۔",
    "que_req": "سوال درکار ہے۔",
    "day_of_birthday": "سالگرہ کے دن",
    "day_before_birthday": "سالگرہ سے 3 دن پہلے",
    "week_before_birthday": "سالگرہ سے ایک ہفتہ پہلے",
    "two_week_before_birthday": "سالگرہ سے دو ہفتے پہلے",
    "de_active": "غیر فعال",
    "campaign_details": "مہم کی تفصیلات",
    "link_clicked": "لنک پر کلک کیا گیا۔",
    "history": "تاریخ",
    "auto_responder_summary": "خودکار جواب دہندہ کا خلاصہ",
    "vsop_1": "15 منٹ",
    "vsop_2": "30 منٹ",
    "vsop_3": "45 منٹ",
    "vsop_4": "60 منٹ",
    "vsop_5": "90 منٹ",
    "vsop_6": "120 منٹ",
    "in_the_last": " آخری میں ",
    "return_in_last": " لیکن آخر میں واپس نہیں آیا ",
    "of_the_connection": "کنکشن کے",
    "your_campaign": "آپ کی مہم",
    "you_have_successfully": "آپ نے کامیابی حاصل کی ہے۔",
    "published_successfully": "کامیابی سے شائع",
    "updated_smart_campaign": "سمارٹ مہم کو اپ ڈیٹ کیا۔",
    "almost_done_text": "تقریباً ہو گیا!",
    "update_campaign_desc": "آپ کا خودکار جواب دہندہ مکمل ہو گیا ہے، ایک بار فعال ہونے کے بعد، یہ مسلسل بنیادوں پر کلائنٹس کو پیغامات بھیجے گا۔ اس مہم میں کسی بھی وقت آسانی سے ترمیم یا موقوف کیا جا سکتا ہے۔",
    "update_and_publish": "اپ ڈیٹ اور شائع کریں۔",
    "reset_campaign_title": "یہ آپ کی مہم کے حسب ضرورت متن، تصویر، رعایتی پیشکشوں اور ہدف شدہ سامعین کو پہلے سے ہی حذف کر دے گا!",
    "space_wifi": " وائی ​​فائی",
    "custom_forms": "حسب ضرورت فارم",
    "web_e_signup": "ویب ای سائن اپ",
    "import": "درآمد کریں۔",
    "permissions_req": "اجازتیں درکار ہیں۔",
    "redeemed": "چھڑایا",
    "coupon_already_redeemed": "کوپن پہلے ہی بھنایا جا چکا ہے۔",
    "autoresponder_campaigns": "خودکار جواب دینے والی مہمات",
    "autoresponder_campaign_desc": "خودکار جواب دہندہ کو فعال کریں اور ذاتی نوعیت کی مہم شروع کریں۔",
    "mon": "MON",
    "tue": "منگل",
    "wed": "WED",
    "thu": "ٹی ایچ یو",
    "fri": "ایف آر آئی",
    "sat": "SAT",
    "sun": "سورج",
    "duplicate": "نقل",
    "visibility": "مرئیت",
    "availability": "دستیابی",
    "out_stok": "سٹاک سے باہر",
    "edit_product": "پروڈکٹ میں ترمیم کریں۔",
    "create_product": "ایک پروڈکٹ بنائیں",
    "basic_info": "بنیادی معلومات",
    "basic_sub_info": "Lorem Ipsum پرنٹنگ کا محض ڈمی ٹیکسٹ ہے...",
    "enter_product_price": "مصنوعات کی قیمت درج کریں۔",
    "upload_image": "تصویر اپ لوڈ کریں۔",
    "allowed_file_formats": "زیادہ سے زیادہ فائل سائز 3MB والی صرف JPG اور PNG فائلوں کی اجازت ہے۔",
    "pre_select": "پہلے سے منتخب کریں۔",
    "promotions": "پروموشنز",
    "discount_availability": "ڈسکاؤنٹ کی دستیابی",
    "start_time": "آغاز کا وقت",
    "end_time": "اختتامی وقت",
    "select_day": "دن منتخب کریں۔",
    "menu_required": "مینو درکار ہے۔",
    "generated": "پیدا کیا",
    "link": "لنک",
    "variations": "تغیرات",
    "select_variant_to_add": "متغیر کو منتخب کریں جو آپ شامل کرنا چاہتے ہیں۔",
    "price_title": "قیمت",
    "choose_image": "تصویر کا انتخاب کریں۔",
    "select_visiblity": "مرئیت کو منتخب کریں۔",
    "availability_schedule": "دستیابی کا شیڈول",
    "add_on_upsell": "شامل کریں \/ اپسیل",
    "add_on_select_product": "سلیکٹ پروڈکٹ پر شامل کریں۔",
    "upsell_product": "اپ سیل سلیکٹ پروڈکٹ",
    "variant_deletion_warning": "یہ متغیر مستقل طور پر حذف کر دیا جائے گا۔",
    "search_variations": "تغیرات تلاش کریں۔",
    "add_variation": "تغیرات شامل کریں۔",
    "variation_text": "متغیر",
    "select_customization": "حسب ضرورت منتخب کریں۔",
    "add_new": "نیا شامل کریں۔",
    "delete_customization_warning": "کیا آپ اس حسب ضرورت کو پروڈکٹ سے حذف کرنا چاہتے ہیں؟",
    "nutritional_allergen_info": "غذائیت اور الرجین کی معلومات",
    "mark_item": "آئٹم کو نشان زد کریں۔",
    "calories_text": "کیلوریز",
    "allergens": "الرجین",
    "select_allergens": "الرجین کا انتخاب کریں۔",
    "special_instructions": "خصوصی ہدایات",
    "preparation_instructions": "تیاری کی ہدایات",
    "staff_notes": "عملے کے نوٹس",
    "checkbox1": "اس مینو آئٹم کے لیے صارفین کی طرف سے خصوصی ہدایات کی اجازت دینے یا نامنظور کرنے کے لیے ایک چیک باکس۔",
    "menu_c": "مینو",
    "past_design": "ماضی کا ڈیزائن",
    "file_name": "فائل کا نام",
    "variation_name": "تغیر کا نام",
    "grid_items_title1": "ٹیبل ٹینٹ",
    "grid_items_title2": "ڈیجیٹل مینو",
    "grid_items_title3": "ٹیک آؤٹ مینو",
    "grid_items_title4": "ہاف پیج مینو",
    "grid_items_title5": "ڈیجیٹل مینو 5",
    "grid_items_title6": "ڈیجیٹل مینو 6",
    "grid_items_title7": "ڈیجیٹل مینو 7",
    "grid_items_title8": "ڈیجیٹل مینو 8",
    "grid_items_title9": "ڈیجیٹل مینو 9",
    "enter_valid_form_name": "فارم کا درست نام درج کریں۔",
    "form_name_required": "فارم کا نام درکار ہے۔",
    "enter_valid_section": "سیکشن کا درست عنوان درج کریں۔",
    "fraction_half": "1\/2",
    "fraction_two_by_two": "2\/2",
    "form_name": "فارم کا نام",
    "contact_list": "رابطے کی فہرست",
    "label_notify_email": "کیا آپ چاہیں گے کہ ای میل کے ذریعے مطلع کیا جائے؟",
    "add_client_details_section": "کلائنٹ کی تفصیلات کا سیکشن شامل کریں۔",
    "demo_purpose_only": "یہ صرف ڈیمو مقصد کے لیے ہے۔",
    "form_updated_success": "فارم کو کامیابی سے اپ ڈیٹ کر دیا گیا۔",
    "form_creat_success": "فارم کامیابی کے ساتھ بنایا گیا۔",
    "add_custom_section": "ایک حسب ضرورت سیکشن شامل کریں۔",
    "edit_custom_section": "اپنی مرضی کے سیکشن میں ترمیم کریں۔",
    "forms_list": "فارم کی فہرست",
    "filled_custom_form_details": "اپنی مرضی کے مطابق فارم کی تفصیلات بھریں۔",
    "custom_form_deletion_warning": "یہ حسب ضرورت فارم ہمیشہ کے لیے حذف کر دیا جائے گا۔",
    "menu_link": "مینو لنک",
    "WebSignUpT": "ای سائن اپ",
    "lifeTConT": "زندگی بھر کے رابطے",
    "repCustT": "صارفین کو دہرائیں۔",
    "liftTSubT": "لائف ٹائم سبسکرائبرز",
    "overallT": "مجموعی طور پر",
    "loginCustBDT": "دن کے حساب سے لاگ ان صارفین",
    "deviceT": "ڈیوائس ڈیموگرافکس",
    "ageTitle": "عمر ڈیموگرافکس",
    "ageT": "عمر گروپ",
    "convert_social_media_followers_into_customers": "سوشل میڈیا کے پیروکاروں کو صارفین میں تبدیل کریں۔",
    "signWidDesc": "سائن اپ کرنے، لینڈنگ پیجز بنانے اور اپنی ویب سائٹ یا سوشل میڈیا سے کوپن بنانے کے لیے ایک ویجیٹ بنائیں",
    "qrWidT": "اسمارٹ QR",
    "imgWidT": "آپٹ ان نمبر",
    "mobile": "موبائل",
    "YESTitle": "جی ہاں",
    "NOTitle": "NO",
    "addDelRed": "ڈیلیوری ری ڈائریکشن شامل کریں۔",
    "dlredNameT": "اپنی ڈیلیوری ری ڈائریکشن کو نام دیں۔",
    "conf_text_req": "تصدیقی متن درکار ہے۔",
    "cFDesc": "گاہکوں سے مؤثر طریقے سے مخصوص تاثرات اور معلومات جمع کرنے کے لیے موزوں فارم بنائیں",
    "pre_review_questions_enabled": "صارفین سے جائزہ لینے سے پہلے، آئیے ان سے 1 سے 4 سوالات پوچھیں۔",
    "feel_free_edit": "ضرورت کے مطابق بلا جھجھک ترمیم کریں۔",
    "option": "آپشن",
    "confFL": "آپ کے رابطہ کے ویب سائن اپ فارم جمع کرنے کے بعد یہ تصدیق دکھائی جائے گی۔",
    "confT": "تصدیقی متن",
    "joinTitle": "شمولیت",
    "mobValidL": "براہ کرم درست موبائل نمبر چیک کریں۔",
    "mobRequiredL": "موبائل نمبر درکار ہے۔",
    "Business_location_label": "آپ کا کاروبار کا مقام",
    "qr_code_selection_txt": "کیو آر کوڈ کا انتخاب",
    "choose_theme_txt": "تھیم کا انتخاب کریں۔",
    "edit_qr_details_txt": "QR تفصیلات میں ترمیم کریں۔",
    "save_qr_code_txt": "کیو آر کوڈ محفوظ کریں۔",
    "text_color_label": "متن کا رنگ",
    "background_color_label": "پس منظر کا رنگ",
    "print_txt": "پرنٹ",
    "customer_will_see_txt": "یہ وہی ہے جو آپ کے گاہک کو نظر آئے گا۔ اگلے مرحلے میں اپنے برانڈ کا رنگ منتخب کریں۔",
    "choose_your_brand_color_&_langauge_title": "اپنے برانڈ کا رنگ اور زبان منتخب کریں۔",
    "custom_redirect_link_label": "کسٹم ری ڈائریکٹ لنک",
    "redirect_user_to_menu": "صارف کو مینو میں ری ڈائریکٹ کریں۔",
    "language": "زبان",
    "select_language_placeholder": "زبان منتخب کریں۔",
    "capitalize_location_name_txt": "مقام کا نام",
    "created_txt": "بنایا",
    "swipe_reputation_management_txt": "آپ کی ساکھ کا انتظام کیسا نظر آئے گا یہ دیکھنے کے لیے سوائپ کریں۔",
    "sent_txt": "بھیجا",
    "number_label": "نمبر",
    "date_label": "تاریخ",
    "gender_demographics_title": "جینڈر ڈیموگرافکس",
    "step1_preview_desc": "اپنے ای سائن اپ QR فارم کو نام دیں اور اپنے گروپ کی فہرست میں شامل کریں۔",
    "main_head": "نئے صارفین حاصل کریں۔",
    "main_desc": "ایک QR کوڈ رکھ کر گاہکوں کو حاصل کریں جو، اسکین ہونے پر، صارفین کو ذاتی نوعیت کے سائن اپ فارم پر ری ڈائریکٹ کرتا ہے۔",
    "step1_description": "دوسرے کاروبار سے نئے گاہک حاصل کریں۔",
    "form_name_field_placeholder": "ریسٹرو ای سائن اپ",
    "last_step_title": "مبارک ہو! آپ کا ای سائن اپ ویجیٹ بنایا گیا! 🎉",
    "last_step_desc": "یہ دیکھنے کے لیے سوائپ کریں کہ آپ کا ای سائن اپ فارم کیسا نظر آئے گا۔",
    "events_step1_preview_desc": "اپنے ایونٹ کے QR فارم کو نام دیں اور اپنے گروپ کی فہرست میں شامل کریں۔",
    "events_main_head": "واقعات سے صارفین حاصل کریں۔",
    "events_main_desc": "ایونٹ پر ایک QR کوڈ رکھ کر صارفین کو حاصل کریں، اسکین ہونے پر، صارفین کو ذاتی نوعیت کے سائن اپ فارم پر ری ڈائریکٹ کرتا ہے۔",
    "events_step1_desc": "صارفین کو ایونٹس سے پکڑیں ​​اور انہیں اسٹور میں لے آئیں",
    "events_name": "ایونٹ کا نام",
    "events_form_name_field_placeholder": "ریسٹرو تفریح ​​​​اور کھانے کی تقریب",
    "events_last_step_title": "مبارک ہو! آپ کا ایونٹ فارم بنایا گیا! 🎉",
    "events_last_step_desc": "یہ دیکھنے کے لیے سوائپ کریں کہ آپ کا ایونٹ فارم کیسا نظر آئے گا۔",
    "birthday_anniversary_step1_preview_desc": "گاہک کی سالگرہ یا سالگرہ جمع کرنے کے لیے اپنے QR فارم کو نام دیں اور اسے آپ کے گروپ کی فہرست میں شامل کریں۔",
    "birthday_anniversary_main_head": "سالگرہ یا سالگرہ جمع کریں۔",
    "birthday_anniversary_main_desc": "گاہک کی سالگرہ یا سالگرہ حاصل کریں، اسکین ہونے پر، صارفین کو ذاتی نوعیت کے سائن اپ فارم پر ری ڈائریکٹ کرتا ہے۔",
    "birthday_anniversary_step1_desc": "گاہک کی سالگرہ اور سالگرہ جمع کریں۔",
    "restro_text": "ریسٹرو",
    "birthday_anniversary_last_step_title": "مبارک ہو! تیار کردہ صارفین کے فارم سے سالگرہ اور سالگرہ حاصل کریں! 🎉",
    "newspaper_ads_step1_preview_desc": "اخباری اشتہارات سے کسٹمرز حاصل کرنے کے لیے اپنے QR فارم کو نام دیں اور یہ آپ کے گروپ کی فہرست میں شامل ہو جائے۔",
    "newspaper_ads_main_head": "اشتہارات سے صارفین حاصل کریں۔",
    "newspaper_ads_main_desc": "اخباری اشتہارات سے گاہکوں کو حاصل کریں، جب وہ اسکین کرتے ہیں، تو یہ صارفین کو ذاتی نوعیت کے سائن اپ فارم پر بھیج دیتا ہے۔",
    "newspaper_ads_step1_desc": "اخباری اشتہار یا آؤٹ ڈور اشتہار سے صارفین حاصل کریں۔",
    "newspaper_ads_last_step_title": "مبارک ہو! اخبار کے اشتہارات کے فارم سے صارفین حاصل کریں! 🎉",
    "smart_pamphlets_step1_preview_desc": "پمفلٹس سے صارفین کا ڈیٹا حاصل کرنے کے لیے اپنے QR فارم کو نام دیں اور یہ آپ کے گروپ کی فہرست میں شامل ہو جائے۔",
    "smart_pamphlets_main_head": "پمفلٹس سے کلائنٹ حاصل کریں۔",
    "smart_pamphlets_main_desc": "پمفلٹس سے گاہک کا ڈیٹا حاصل کریں، جب وہ اسکین کرتے ہیں، تو یہ صارفین کو ذاتی نوعیت کے سائن اپ فارم پر بھیج دیتا ہے۔",
    "smart_pamphlets_last_step_title": "مبارک ہو! تیار کردہ پمفلٹ فارم سے گاہک کا ڈیٹا حاصل کریں! 🎉",
    "social_media_step1_preview_desc": "سوشل میڈیا سے کسٹمرز حاصل کرنے کے لیے اپنے QR فارم کو نام دیں اور اسے آپ کے گروپ کی فہرست میں شامل کریں۔",
    "social_media_main_desc": "سوشل میڈیا سے صارفین حاصل کریں، جب وہ پوسٹس سے اسکین کرتے ہیں، تو یہ صارفین کو ذاتی نوعیت کے سائن اپ فارم پر بھیج دیتا ہے۔",
    "social_media_form_name_field_placeholder": "ریسٹرو فیسٹیول آفر - فیس بک پوسٹ",
    "social_media_last_step_title": "مبارک ہو! صارفین کو سوشل میڈیا فارم بنائیں! 🎉",
    "claim_offer_text": "اپنی تفصیلات پُر کریں اور ابھی پیشکش کا دعوی کریں۔",
    "form_title_description": "تفصیلات درج کرنے کے لیے اپنے صارفین کے لیے فارم کا عنوان اور تفصیل شامل کریں۔",
    "no_subscribed_clients": "منتخب کسٹمر گروپ کے پاس کوئی سبسکرائب کردہ کلائنٹ نہیں ہے۔",
    "welcome_message_offer": "اپنے صارفین کو ایک خوش آمدید پیغام بھیجیں، جب وہ سائن اپ کریں اور انہیں آفر کوپن دیں۔",
    "system_grp": "سسٹم گروپس",
    "import_clients": "کلائنٹس درآمد کریں۔",
    "delivery_redirection_name": "ڈیلیوری ری ڈائریکشن کا نام",
    "created_at": "پر تخلیق کیا گیا۔",
    "provide_stamp_user": "صارف کو ڈاک ٹکٹ فراہم کریں۔",
    "scan_qr_code": "کیو آر کوڈ اسکین کریں۔",
    "add_point": "پوائنٹ شامل کریں۔",
    "total_points": "کل پوائنٹس",
    "user_profile": "صارف پروفائل",
    "stamp_history": "ڈاک ٹکٹ کی تاریخ",
    "reward_txt": "انعام",
    "variant_placeholder": "چھوٹے، درمیانے، بڑے، وغیرہ",
    "sent_coupon": "کوپن بھیجا۔",
    "order_status": "آرڈر کی حیثیت",
    "payment_status": "ادائیگی کی حیثیت",
    "order_currency": "آرڈر کرنسی",
    "card_data": "کارڈ ڈیٹا",
    "reward_expiring_on": "انعام کی میعاد ختم ہو رہی ہے۔",
    "reward_status": "انعام کی حیثیت",
    "reward_get_date": "انعام حاصل کرنے کی تاریخ",
    "reward_name": "انعام کا نام",
    "add_point_type": "پوائنٹ کی قسم شامل کریں۔",
    "restaurant_location": "ریستوراں کا مقام",
    "collect_points": "پوائنٹس جمع کریں۔",
    "rewards_expire": "انعامات کی میعاد ختم",
    "rewards_redeem": "انعامات کو چھڑانا",
    "total_rewards": "کل انعامات",
    "created_by": "کے ذریعہ تخلیق کیا گیا۔",
    "anniversary": "سالگرہ",
    "remove_user_group_txt": "صارف کو گروپ سے ہٹا دیں۔",
    "delete_permanently_grp_msg": "اس کلائنٹ کو گروپ سے مستقل طور پر حذف کر دیا جائے گا۔",
    "delete_multiple_permanently_grp_msg": "اس کلائنٹس کو گروپ سے مستقل طور پر حذف کر دیا جائے گا۔",
    "delete_multiple_client": "اس کلائنٹس کو مستقل طور پر حذف کر دیا جائے گا۔",
    "messages": "پیغامات",
    "management": "انتظام",
    "client": "کلائنٹ",
    "country": "ملک",
    "state": "ریاست",
    "city": "شہر",
    "1_sparkly_point_txt": "🎉 آپ نے 1 شاندار پوائنٹ حاصل کیا ہے! 🌟",
    "select_country_txt": "ملک منتخب کریں۔",
    "select_state": "ریاست منتخب کریں۔",
    "ls_thanks_for_join": "ہمارے لائلٹی پنچ کارڈ میں شامل ہونے کا شکریہ۔🎁🎈",
    "month": "مہینہ",
    "welcome_message": "اپنے صارفین کے سائن اپ کرنے کے بعد انہیں خوش آمدید کا پیغام بھیجیں۔",
    "all_rewards_txt": "تمام انعامات",
    "active_rewards": "فعال انعامات",
    "expired_and_redeemed": "میعاد ختم اور چھڑا لیا گیا۔",
    "redirect_3rd_party_delivery": "تیسری پارٹی کی ڈیلیوری کو اپنے اسٹور یا اپنے آن لائن آرڈرنگ پلیٹ فارم پر دوبارہ ڈائریکٹ کریں۔",
    "search_or_start_new": "تلاش کریں یا نیا شروع کریں۔",
    "contacts": "رابطے",
    "chats": "چیٹس",
    "add_by_restaurant": "ریستوراں کے ذریعہ شامل کریں۔",
    "using_scan_code": "اسکین کوڈ کا استعمال",
    "step": "قدم",
    "list_widget_text": "وجیٹس کی فہرست",
    "swipe_free_wifi_txt": "مفت وائی فائی کے لیے سوائپ کریں۔",
    "create_widget_txt": "ویجیٹ بنائیں",
    "redeem_award_msg": "کیا آپ کو یقین ہے؟ کیا آپ اس انعام کو چھڑانا چاہتے ہیں؟",
    "on_collect_msg": "جمع کرنے پر",
    "pause": "توقف",
    "resume": "دوبارہ شروع کریں۔",
    "location_added": "مقام شامل کیا گیا۔",
    "sub_user_admin_permissions_txt": "ذیلی صارف کو ڈیجیٹل اشارے میں منتظم کی اجازتوں تک رسائی کی اجازت دیں۔",
    "unlist": "فہرست ختم کریں۔",
    "cannot_delete_default_terms": "پہلے سے طے شدہ شرائط و ضوابط کو حذف نہیں کیا جا سکتا",
    "something_went_wrong": "کچھ غلط ہو گیا!",
    "card_download_success": "کارڈ کامیابی کے ساتھ ڈاؤن لوڈ ہو گیا۔",
    "permission_denied": "اجازت سے انکار!",
    "invalid_qr_code": "براہ کرم درست QR کوڈ اسکین کریں۔",
    "select_customer": "براہ کرم کسٹمر منتخب کریں۔",
    "online": "آن لائن",
    "offline": "آف لائن",
    "no_data": "افوہ، کوئی ڈیٹا نہیں ملا!",
    "forever_free": "ہمیشہ کے لیے مفت"
}

export default ur;