const en = {
    "rated_google_reviews": "Rated 5.0 on Google Reviews",
    "get_your_digital_menu": "Get Your Digital Menu—",
    "instant_popup_menu": "Instant pop-up menu without QR scanning",
    "free_forever": "Free Forever",
    "the_future_is_here": "The future is here!",
    "upgrade_your_restaurant": "Upgrade your restaurant with a stunning digital menu at zero cost. Attract more customers starting today.",
    "get_my_free_menu_now": "Get My Free Menu Now",
    "no_credit_card_required": "*No Credit Card required",
    "join_largest_marketplace": "Join the largest restaurant marketplace!",
    "what_will_you_get": "What Will You Get",
    "free": "FREE",
    "free_digital_menu": "Free Digital Menu 2D or 3D",
    "free_digital_menu_desc": "Say goodbye to paper menus and pricey reprints",
    "quick_and_easy_signup": "Quick & Easy Sign-Up",
    "communicate_with_customers_title": "Communicate with customers for FREE!",
    "features1": [
        "Save on printing costs and enjoy instant menu updates whenever you need them.",
        "Access your digital menu on any device",
        "Eco-friendly and hassle-free"
    ],
    "features2": [
        "Let customers virtually explore your dishes with stunning 3D visuals.",
        "Enhance engagement by giving diners a realistic preview of their meal.",
        "Stand out with a menu experience that feels as enticing as your food tastes."
    ],
    "features3": [
        "Instantly greet guests with your menu as they enter the restaurant.",
        "Automatically send updates on specials and promotions to their phones.",
        "Enhance the dining experience with quick, contactless access to your menu."
    ],
    "feature4": [
        "Highlight seasonal offers, daily specials, and exclusive promotions.",
        "Draw customers’ attention to your most popular items.",
        "Create urgency and excitement with eye-catching visuals and descriptions."
    ],
    "feature5": [
        "Be part of an online community with thousands of daily visitors.",
        "Boost visibility and reach new customers actively searching for dining options.",
        "Secure a top spot early to maximize your exposure and attract more diners."
    ],
    "section3_heading": "Why Go Digital? Because It's Free and Easy!",
    "section3_description": "Unlock powerful features designed to boost your restaurant's appeal.",
    "instant_menu": "Instant Menu",
    "instant_menu_notification": "Pop-up and Notification",
    "instant_menu_description": "Welcome guests with instant menu access as soon as they enter. Automatically send the latest menu and specials directly to their mobile phones",
    "digital_menu": "Digital Menus",
    "at_zero_cost": "at Zero Cost",
    "digital_menu_description1": "Say goodbye to paper menus and pricey reprints. Switch to a digital menu that's always up-to-date—and it's free forever!",
    "digital_menu_description2": "With our digital menu, your offerings are always current and accessible on any device. Save money and simplify menu updates with a seamless, zero-cost solution.",
    "try_me_button": "Try Me",
    "try_3d_menu_title": "3D Menus",
    "try_3d_menu_title2": "For Free — WOW!",
    "try_3d_menu_description1": "Make your dishes pop off the screen. Upload 3D images and let customers virtually taste your food before they walk in.",
    "try_3d_menu_description2": "High-quality 3D images create a mouth-watering preview, enhancing appeal and encouraging orders.",
    "try_now": "Try Now",
    "showcase_title": "Showcase Your",
    "showcase_specials": "Specials",
    "showcase_specials_description1": "Got a mouth-watering deal or an unbeatable promotion? Highlight it on your digital menu and draw in more hungry folks.",
    "showcase_specials_description2": "Make promotions impossible to miss, drawing attention with bold visuals and enticing descriptions.",
    "marketplace": "MarketPlace",
    "marketplace_description": "We're whipping up a marketplace with over 2,000 daily visitors. Hop on board now, and you'll be first in line when we launch. You can reach new customers actively looking for dining options. Secure your place early to maximize exposure!",
    "see_other_products": "See Other Products",
    "about_us": "About us",
    "why_serving_free": "Why We're Serving This Up for",
    "serving_free_highlight": "Free",
    "about_desc": "We're local folks right here in Dallas, committed to helping restaurants like yours thrive.",
    "about_tool_free": "We're offering this powerful tool absolutely free because we believe in supporting our community.",
    "no_catch": "No catch, no kidding. We know that when you succeed, our community becomes stronger. 😊",
    "understanding_challenges": "We understand the challenges of running a business, which is why we’re dedicated to providing tools that make your operations smoother and your reach wider.",
    "community_growth": "As you grow, our community grows with you, creating a ripple effect of success and support. Together, let’s elevate the dining experience for everyone.",
    "how_it_works": "How it works",
    "three_simple_steps": "Three Simple Steps to",
    "get_started_highlight": "Get Started",
    "no_tech_skills_needed": "No tech skills needed. If you can flip a burger, you can do this!",
    "step1_title": "Display your promo for FREE!",
    "step1_desc": "Get started faster than you can say \"Order up!\" Just fill out a simple form.",
    "step2_title": "Customize Your Menu",
    "step2_desc": "Add your dishes, upload photos even in 3D & highlight your specials.",
    "step3_title": "Share and Grow",
    "step3_desc": "Share your digital menu via QRs, social media, or your website.",
    "testimonials": "Testimonials",
    "testimonials_section_title": "Don't Just Take Our Word for It",
    "testimonials_section_desc": "See what other Dallas restaurant owners are saying.",
    "faq_section_title": "Got questions? We've got answers",
    "faq_section_desc": "Everything you need to know before getting started.",
    "faqs": "FAQs",
    "faq_answers": [
        {
            "que": "Is it really free forever?",
            "ans": "Absolutely! No hidden fees, no credit cards, no strings attached."
        },
        {
            "que": "Do I need any technical know-how?",
            "ans": "If you can send an email, you can do this. It's that simple."
        },
        {
            "que": "Why are you offering this for free?",
            "ans": "We believe in giving first. Down the line, we might have other services you'll love—but that's entirely up to you."
        },
        {
            "que": "What's the deal with the marketplace?",
            "ans": "We're cooking it up! Once we have enough restaurants on board, we'll launch your marketplace to get you even more exposure."
        }
    ],
    "testimonials_content1": "Switching to a digital menu was the best move we made! Our customers love the convenience and updated looks!",
    "testimonials_name1": "Morris George",
    "testimonials_restaurant_name1": "The Mansion Restaurant",
    "testimonials_content2": "Our new digital menu makes browsing easier for guests, and it’s really boosted engagement!",
    "testimonials_name2": "Amelia Johnson",
    "testimonials_restaurant_name2": "Urban Eats",
    "testimonials_content3": "Guests are wowed by the 3D visuals—it’s helped us stand out and enhanced their dining experience!",
    "testimonials_name3": "Davis Green",
    "testimonials_restaurant_name3": "Town Hearth",
    "ready_to_boost_your_restaurant": "Ready to Boost Your Restaurant?",
    "ready_to_boost_desc": "Don't get left behind. Join the digital revolution today!",
    "yes_i_want_my_free_digital_menu": "Yes, I Want My Free Digital Menu",
    "480_plus": "480+",
    "people_already_joined": "people already joined",
    "menu_online_home": "Menu Online Home",
    "welcome": "Welcome",
    "description_other": "Let’s Boost up your customer satisfaction!",
    "benefits": "Benefits",
    "greeting_text": "Welcome 👋",
    "all_in_one_features": "🚀 All-in-One Features to Elevate Your Restaurant",
    "menu_online_menu_name": "Menu Online",
    "menu_online_description": "Digitalize your menu for easy online access by customers.",
    "menu_online_benefits": "Enhance customer convenience and boost engagement by allowing them to view your menu anytime, anywhere.",
    "ds_name": "Digital Signage",
    "ds_description": "Showcase offers and promotions on dynamic in-store screens.",
    "ds_benefits": "Captivate customers’ attention with engaging visuals, promoting upsells and real-time offers effortlessly.",
    "loyalty_punch_name": "Loyalty Punch Card",
    "loyalty_punch_description": "Reward repeat customers with a digital loyalty program.",
    "loyalty_punch_benefits": "Drive customer retention and increase sales by incentivizing loyalty with redeemable rewards for frequent visits.",
    "marketing_menu_name": "Marketing",
    "marketing_description": "Reach more customers with targeted digital marketing campaigns.",
    "marketing_benefits": "Expand your restaurant’s reach and drive foot traffic with custom promotions tailored to attract new and repeat customers.",
    "get_more_reviews_menu_name": "Get More Reviews",
    "get_more_reviews_description": "Encourage satisfied customers to leave positive online reviews.",
    "get_more_reviews_benefits": "Boost your restaurant's credibility and attract new diners by enhancing your online reputation with more positive reviews.",
    "guest_smart_wifi_menu_name": "Guest Smart Wifi",
    "guest_smart_wifi_description": "Offer free Wi-Fi to capture guest data for marketing.",
    "guest_smart_wifi_benefits": "Increase customer satisfaction while collecting valuable customer data to personalize future promotions.",
    "reservation_menu_name": "Reservation",
    "reservation_description": "Allow customers to reserve tables online with ease.",
    "reservation_benefits": "Improve dining experience by minimizing wait times, and streamline reservations to better manage seating.",
    "pos_integration_menu_name": "POS Integration",
    "pos_integration_description": "Integrate our system with your POS system.",
    "pos_integration_benefits": "Simplify order processing and reduce errors by keeping your online and in-store systems in sync.",
    "online_ordering_menu_name": "Online Ordering",
    "online_ordering_description": "Enable customers to place orders directly from your website or app.",
    "online_ordering_benefits": "Increase sales and reduce reliance on third-party platforms by offering a direct ordering option that saves fees.",
    "pro_label": "Pro",
    "coming_soon": "Coming Soon",
    "locked_label": "Locked",
    "forever_label": "Forever",
    "new_label": "New",
    "other_products_page_title": "Other Products | Menu Online",
    "other_products_description": "Menu Online Privacy Policy",
    "other_products_header_title": "Other Products",
    "privacy_policy": {
        "title": "Privacy Policy",
        "lastUpdated": "Last Updated : July 01, 2024",
        "introduction": {
            "header": "Privacy Policy for New and Existing Customers",
            "paragraph1": "THE FOLLOWING KEY POINTS (INCLUDING SECTION REFERENCES) ARE PROVIDED FOR YOUR CONVENIENCE ONLY AND ARE NOT IN LIEU OF THE FULL TERMS AND CONDITIONS. IT IS YOUR RESPONSIBILITY TO READ THOSE TERMS AND CONDITIONS BEFORE USING THE MENUONLINE INC SERVICE.",
            "paragraph2": "Using Menuonline means you agree to the Terms and Conditions and represent and warrant you’re authorized by your organization or company to do so. (See s.3 “How it works”)",
            "paragraph3": "Menuonline Inc is a permission-based marketing service. Deliberate sending of “spam” or violating the Terms and Conditions will result in the closing of your account. (See s.16 “Acceptable Use of the Service”)",
            "paragraph4": "Data you host in Menuonline Inc such as contact records, email content and information you collect through Menuonline Inc belongs to you. (See s.14 “What do you own?”).",
            "paragraph5": "The Menuonline Inc software and the content on our website belong to us. (See s.13 “What do we own?”) Apart from your own users, the data or unpublished content you host with Menuonline Inc can be only be viewed by Menuonline Inc employees and partners who have signed confidentiality agreements. (See s.14 “What do you own?”)",
            "paragraph6": "All Personally Identifiable Information (PII) is held only on servers located in Canada. (See s.14 “What do you own?”).",
            "paragraph7": "The Menuonline Inc service is subject to the laws of Canada and the province of Ontario. (See s.24 “Governing Law”)",
            "paragraph8": "Any requests for your data or content by individuals, police or other regulatory agencies from within Canada or outside of Canada will always be referred to you. The only exception would be if this puts us in violation of Canadian or Ontario law; however, in all cases we will strenuously resist any release of your data by anyone but you. (See s.14 “What do you own?”).",
            "paragraph9": "Menuonline Inc security is only as good as your management of usernames and passwords. We don’t charge for Menuonline Inc user accounts so create a separate one for each user and keep passwords secret. You are responsible for your account. (See s.3 “How it works”)",
            "paragraph10": "You can cancel your account at any time. We do not provide refunds on any unused credit if you cancel. (See s.3 “How it works”)"
        },
        "termsAndConditions": {
            "title": "MENUONLINE INC TERMS AND CONDITIONS",
            "lastUpdate": "LAST UPDATE: January 14, 2020",
            "paragraph1": "Please read these terms and conditions of service (the “Terms“) carefully before using this web site (the “Site“). These Terms exempt Menuonline Inc. (“Menuonline Inc” “we“, or “us“) and others from liability and\/or limit our and their liability and contain other important provisions that apply to your use of this Site. By visiting or using this Site or the Service, you agree on your own behalf, and on behalf of any organization on whose behalf you may act (collectively referred to herein as “you“), to accept and abide by these Terms for each use of and each visit to this Site."
        },
        "services": {
            "title": "Our Services.",
            "paragraph": "The services that we offer through the Site a service that allows you to create, send, and manage email messages (“Emails“) to recipients of your choosing (the “Service“)."
        },
        "license": {
            "title": "License.",
            "paragraph": "Subject to these Terms, Menuonline Inc hereby grants you a non-exclusive, limited, non-transferable license during the Term to view the Site and use the Service as permitted by the features of the Service. We reserve all rights not expressly granted herein in the Service and the Menuonline Inc Content (as defined below)."
        },
        "emailingContent": {
            "title": "Emailing Content.",
            "paragraph": "When interacting with Menuonline Inc via this Site or the Service, you agree to provide true and accurate information. Further you understand that providing confidential information via email is an unsafe practice. You understand that you are solely responsible for anything that you send to anyone using our Service."
        },
        "support": {
            "title": "Support.",
            "paragraph": "We provide you support to help you become self-sufficient with the Service. We provide you with support in the form of documentation and videos on the Site, the Menuonline Inc support ticket system and by telephone. You can reach us by clicking on the “Support” tab at the top of the Site. There’s no charge for product support; however, we reserve the right to discontinue support for specific client accounts at our discretion."
        }
    },
    "terms_condition": {
        "title": "Terms of Service | Menu Online",
        "header": "Terms & Conditions for New and Existing Customers"
    },
    "privacy_policy_title": "PRIVATE POLICY",
    "privacy_policy_effective_date": "Effective as of January 16, 2020",
    "privacy_policy_general_title": "GENERAL PRIVATE POLICY",
    "privacy_policy_intro": "menuonline.com (Menuonline and “us”) understands the importance of the protection of the privacy of the personal information of individuals and the importance of security of confidential information of all types. We have created this Private Policy (the “Policy”) to demonstrate our commitment to protecting your \"privacy\". This Private Policy explains:",
    "privacy_policy_points": [
        "What personal information we collect and why we collect it;",
        "How we use your personal information;",
        "Who we share your personal information with; and",
        "The choices you have to access, update, and remove your personal information from the service."
    ],
    "privacy_policy_pipeda": "Menuonline manages the collection of all information in accordance with Canada’s Personal Information Protection and Electronic Documents Act (PIPEDA).",
    "privacy_policy_update_notice": "Please note that Menuonline reserves the right to update or change this Private Policy at any time. We will notify you of any changes by posting an updated Policy on this website or as made available through the services. The most current version of this Policy will govern mycircle’s use of users’ personal information. By continuing to access mycircle’s website or by continuing to use its services after changes are made to this Private Policy, you agree to be bound by the revised terms.",
    "privacy_policy_consent_title": "CONSENT FOR PERSONAL INFORMATION",
    "privacy_policy_consent_text": "By providing Menuonline with your personal information through our website or any of our forms, you consent to the collection, use, and disclosure of such information in accordance with this Policy or for the purposes identified to you at the time you provided the personal information. Any secondary use of the personal information will only be processed with your express consent, or you will be provided with an opportunity to opt out.",
    "privacy_policy_opt_out_text": "You have the opportunity to withdraw your consent or to opt out at any time. If you wish to withdraw your consent or to opt out, you may click on the communications preferences link at the bottom of any email you receive from us.",
    "privacy_policy_info_collection_title": "INFORMATION WE COLLECT",
    "privacy_policy_info_collection_text": "Menuonline collects and processes personal information in accordance with this Policy. We will not sell, share, or rent this information to others, except as accounted for in this Policy. Menuonline collects personal information for the purposes of providing our services or products to you.",
    "privacy_policy_info_types_text": "The types of information that Menuonline collects includes:",
    "privacy_policy_account_holders_title": "Menuonline Account Holders",
    "privacy_policy_registration_info": "Registration Information: If you sign up to use services, you need a Menuonline account to become an account holder (\"Account Holder\"). When you register for an account, you will be asked to provide certain basic information, such as your name, email address, username, password, company name, occupation, location, and phone number. Passwords are encrypted – Menuonline staff is unable to view or retrieve passwords.",
    "privacy_policy_billing_info": "Billing information: If you subscribe to our services, you will need to provide us with payment and billing information.",
    "privacy_policy_cookies_info": "Cookies: We use cookies to track visitor data, such as the source of website visits and the number of website visits on our website.",
    "privacy_policy_ip_info": "IP Addresses: IP addresses are collected about you as part of your use of the services (e.g. for the purpose of recording country-level location and IP addresses used when using our services).",
    "privacy_policy_your_contacts_title": "Your Contacts",
    "privacy_policy_distribution_list": "Distribution list: As part of your use of our services, you may provide us with, or we may collect as part of the services, personal information about your contacts (\"Contacts\"). This information includes email addresses and may include other identifiable information such as name, company name or other information you provide to Menuonline while using the services. You must ensure that you acquire the appropriate consent from your Contacts to allow their information to be processed by us.",
    "privacy_policy_billing_info_contacts": "Billing information: If you subscribe to our services, you will need to provide us with payment and billing information.",
    "privacy_policy_cookies_contacts": "Cookies: Menuonline will only use cookies and similar tracking technologies to collect and use personal information about your Contacts if you have added the Menuonline tracking code to your website. Any landing pages hosted by Menuonline will automatically have a tracking code.",
    "privacy_policy_ip_contacts": "IP Addresses: IP addresses are collected about your Contacts as part of the services (e.g. for the purpose of recording country-level location and the IP addresses used when submitting a form and supplying consent).",
    "privacy_policy_info_about_contacts_title": "INFORMATION WE COLLECT ABOUT YOUR CONTACTS",
    "privacy_policy_info_about_contacts_notice": "*This section only applies to information collected, used or disclosed by Menuonline about an Account Holder’s Contacts for the purposes of providing the services*",
    "privacy_policy_info_about_contacts_desc": "Menuonline will only collect, use or disclose personal information about Contacts if the Account Holder has registered for certain services where they provide us with a distribution list. Account Holders are solely responsible for ensuring that they have complied with all applicable laws when providing personal information about their Contacts to Menuonline through the services.",
    "privacy_policy_info_auto_collect_title": "Information Menuonline automatically collects",
    "privacy_policy_info_auto_collect_desc1": "When Account Holder’s Contacts engage with an email campaign received from an Account Holder, or interact with a link to an Account Holder’s connected website, Menuonline may collect information about such Contact’s device and interaction with the email through the use of cookies. This information is only collected to provide the services and to provide insight to the Account Holder.",
    "privacy_policy_info_auto_collect_desc2": "Information we collect about a Contact’s device and the applications Contact uses to access emails sent from the Account Holder, through our services, may include Contact’s IP address, operating system, browser ID, and other related information about Contact’s system and connection.",
    "privacy_policy_info_auto_collect_desc3": "Information we collect about Contact’s interaction with emails sent through the services may include dates and times that Contact accesses email and browsing activities, and information regarding the performance of the services, such as the deliverability of emails and other communications sent to Contact by an Account Holder through the services.",
    "privacy_policy_info_auto_collect_desc4": "Please note, Menuonline will not contact any of Your Contacts, unless required by applicable law.",
    "privacy_policy_why_we_collect_title": "WHY WE COLLECT YOUR INFORMATION",
    "privacy_policy_why_we_collect_desc": "Menuonline collects information for the following purposes:",
    "privacy_policy_why_collect_1": "to provide Menuonline Account Holders with the services they subscribe for, including using Account Holder information for the services;",
    "privacy_policy_why_collect_2": "for administration and account management, as well as access to mycircle’s website;",
    "privacy_policy_why_collect_3": "if you have consented to receiving marketing and promotional materials, to inform you of other products and services offered by Menuonline; and",
    "privacy_policy_why_collect_4": "to respond to Account Holder communications or to send information requested by you. This may include, but is not limited to:",
    "privacy_policy_why_collect_5": "special offers and updates;",
    "privacy_policy_why_collect_6": "newsletter; and",
    "privacy_policy_why_collect_7": "service announcements.",
    "privacy_policy_opt_out_desc": "You may, at any time, choose to opt-out of receiving communications by clicking the unsubscribe button at the bottom of each email sent from Menuonline to you.",
    "privacy_policy_use_disclosure_title": "USE AND DISCLOSURE OF PERSONAL INFORMATION",
    "privacy_policy_use_disclosure_desc1": "Personal information submitted to Menuonline, including through Menuonline’s website or a prescribed form, is kept strictly confidential unless disclosed in accordance with an Account Holder’s express consent or legal requirements. In any event, any personal information provided on any \"Menuonline\" platform by Account Holders is voluntary: any personal information provided by an Account Holder is submitted at the Account Holder’s discretion. Please note that revoking your consent may result in not being able to access or utilize certain Menuonline services.",
    "privacy_policy_use_disclosure_desc2": "Menuonline uses third parties and outside service providers with respect to its website and services. Account Holder’s personal information may be shared with such third parties and providers. We may also provide aggregated statistics about Account Holders, sales, traffic patterns and related information to reputable third parties. Menuonline requires that any third party provider that has access to personal information be bound by obligations consistent with this Private Policy and that such providers only collect, use or disclose Account Holder’s personal information for the purposes of providing Menuonline services.",
    "privacy_policy_use_disclosure_desc3": "Personal information provided by Account Holders will not be sold, shared, or rented to outside organizations unless in accordance with your implied or express consent. Menuonline reserves the right to contact any Account Holders in order to forward information, respond to questions or provide notification of any changes to our program or our policies. Please note, Menuonline will not contact any of your Contacts, unless required by applicable law.",
    "privacy_policy_use_disclosure_desc4": "Menuonline will disclose personal information only if consent is given by the relevant individual or:",
    "privacy_policy_disclosure_list_1": "when permitted or required by law, such as in response to a subpoena or other legal process;",
    "privacy_policy_disclosure_list_2": "to a transferee of all or part of Menuonline;",
    "privacy_policy_disclosure_list_3": "to Menuonline’s service providers in accordance with this Policy; or",
    "privacy_policy_disclosure_list_4": "to meet legal obligations, including but not limited to regulatory reporting obligations.",
    "privacy_policy_service_providers_title": "Menuonline uses the following third parties and service providers (personal information of your Contacts is not disclosed to \"such\" third parties):",
    "privacy_policy_service_provider_1": "We use ManyChat to provide website chat services for sales and support, as well as follow-up emails about these conversations.",
    "privacy_policy_service_provider_2": "We use AskNicely to survey our customers for the purpose of asking about the quality of service and support they receive.",
    "privacy_policy_service_provider_3": "We use join.me to schedule customer and prospective customer meetings, support calls, and software demos.",
    "privacy_policy_service_provider_4": "We use Google Analytics to track anonymous website activity and measure the usage patterns on our website.",
    "privacy_policy_breach_notification_title": "BREACH NOTIFICATION",
    "privacy_policy_breach_notification_desc": "Menuonline shall comply with, and provide Account Holders with reasonable assistance in compliance with applicable laws with respect to any unauthorized use, access or disclosure of personal information.",
    "privacy_policy_rights_access_title": "YOUR RIGHTS AND ACCESS",
    "privacy_policy_rights_access_desc": "You have the right to access personal information that Menuonline processes about you. You can request from us information about:",
    "privacy_policy_rights_access_list_1": "The personal information we hold about you",
    "privacy_policy_rights_access_list_2": "The categories of personal information concerned",
    "privacy_policy_rights_access_list_3": "The purposes of the processing",
    "privacy_policy_rights_access_list_4": "Details to whom your personal information has\/will be disclosed",
    "privacy_policy_rights_access_list_5": "How long we retain your personal information",
    "privacy_policy_rights_access_list_6": "If we did not collect the information directly from you, information about the source",
    "privacy_policy_rights_access_list_7": "How to lodge a complaint with the correct supervisory authority",
    "privacy_policy_rights_access_list_8": "You may also request from us the following:",
    "privacy_policy_rights_access_list_9": "That we update any incomplete or inaccurate information about you",
    "privacy_policy_rights_access_list_10": "Request that we delete your personal information in accordance with applicable laws",
    "privacy_policy_rights_access_contact": "You may request we action your rights by contacting us at 2851 Dufferin Street, Toronto, ON, Canada, or by emailing us at {link}.",
    "privacy_policy_accountability_title": "ACCOUNTABILITY",
    "privacy_policy_accountability_desc": "Menuonline is committed to being accountable for the personal and confidential information that you provide to us. For contact information, please see the end of this Policy.",
    "privacy_policy_links_other_sites_title": "LINKS TO OTHER SITES",
    "privacy_policy_links_other_sites_desc1": "Menuonline’s website may contain links to other sites. Please be aware that Menuonline is not responsible for the privacy practices of other sites. We encourage our Account Holders to be aware that when they leave our site, they should thoroughly read the private policy statements of each and every website that collects personally identifiable information.",
    "privacy_policy_links_other_sites_desc2": "This Private Policy applies solely to information collected by us.",
    "privacy_policy_links_other_sites_desc3": "If you use a link to go from the Menuonline site to purchase products or services, you are completing a purchase transaction according to the rules and regulations of the vendor company, not Menuonline.",
    "privacy_policy_cookies_title": "PRIVATE POLICY COOKIES",
    "privacy_policy_cookies_desc1": "The Menuonline website and services uses cookies to collect and store certain information in accordance with this policy. A ‘cookie’ is a small text file sent from a website and stored on the user’s computer by the user’s web browser. Upon visiting a site that uses cookies, a cookie is downloaded onto your computer or mobile device. The next time you visit that site, your device will remember useful information such as preferences, visited pages or logging in options.",
    "privacy_policy_cookies_desc2": "Cookies are widely used to add functionality to websites, or to ensure they work more efficiently. Our site relies on cookies to optimize the user experience and ensure the sites services function properly.",
    "privacy_policy_cookies_desc3": "Most web browsers allow some control to restrict or block cookies through the browser settings, however if you disable cookies you may find this affects your ability to use certain parts of our website or services.",
    "privacy_policy_cookies_analytical_purpose": "<strong>Analytical Purposes:<\/strong> We use cookies to analyze Account Holder activity in order to improve our website. For example, we can use cookies to look at aggregate patterns like the features Account Holders use. We can use such analysis to gain insights about how to improve the functionality and user experience of our website.",
    "privacy_policy_cookies_preferences_user_experience": "<strong>Your Preferences & User Experience:<\/strong> We use cookies to gather certain information about visitors and Account Holders, such as browser type, server, language preference, and country setting, in order to store Account Holder preferences on our website to make Account Holder experience more consistent and convenient. We use cookies to maintain your logged-in-state when you visit our website repeatedly.",
    "privacy_policy_cookies_targeted_ads": "<strong>Provide Measurement Services and Better Targeted Ads, & Marketing:<\/strong> We use cookies, web beacons, and other storage technologies from third-party partners such as Google and Facebook for measurement services, better targeting ads, and for marketing purposes. This takes place when you visit our website. These cookies, web beacons, and other storage technologies allow us to display Menuonline promotional material to you on other sites you visit across the Internet.",
    "privacy_policy_security_title": "SECURITY",
    "privacy_policy_security_desc1": "Menuonline makes efforts to protect confidential information of all types, including personal information, with organizational, physical, mechanical and electronic safeguards appropriate to the sensitivity of the information. Our servers are located in Canada, and follow industry standards pertaining to security.",
    "privacy_policy_security_desc2": "Please note, however, that we cannot guarantee that the measures we maintain will guarantee the security of the information.",
    "privacy_policy_retention_title": "RETENTION",
    "privacy_policy_retention_desc": "We retain personal information about Account Holders and Contacts for as long as required for the purposes set out in this Private Policy and in accordance with the retention provisions in the Terms of Use.",
    "privacy_policy_access_questions_title": "ACCESS, QUESTIONS AND CONCERNS",
    "privacy_policy_access_questions_desc": "An individual who has submitted personal information to Menuonline may submit a written request to access or correct his or her personal information retained by Menuonline.",
    "terms_obj": {
        "terms_conditions_title": "TERMS AND CONDITIONS",
        "terms_conditions_subtitle": "Terms & Conditions for New and Existing Customers",
        "terms_conditions_desc1": "THE FOLLOWING KEY POINTS (INCLUDING SECTION REFERENCES) ARE PROVIDED FOR YOUR CONVENIENCE ONLY AND ARE NOT IN LIEU OF THE FULL TERMS AND CONDITIONS. IT IS YOUR RESPONSIBILITY TO READ THOSE TERMS AND CONDITIONS BEFORE USING THE Menuonline SERVICE.",
        "terms_conditions_desc2": "Using Menuonline means you agree to the Terms and Conditions and represent and warrant you’re authorized by your organization or company to do so. (See s.3 “How it works”)",
        "terms_conditions_desc3": "Menuonline is a permission-based marketing service. Deliberate sending of “spam” or violating the Terms and Conditions will result in the closing of your account. (See s.16 “Acceptable Use of the Service”)",
        "terms_conditions_desc4": "Data you host in Menuonline such as contact records, email content and information you collect through Menuonline belongs to you. (See s.14 “What do you own?”).",
        "terms_conditions_desc5": "The Menuonline software and the content on our website belong to us. (See s.13 “What do we own?”)",
        "terms_conditions_desc6": "Apart from your own users, the data or unpublished content you host with Menuonline can be only be viewed by Menuonline employees and partners who have signed confidentiality agreements. (See s.14 “What do you own?”).",
        "terms_conditions_desc7": "All Personally Identifiable Information (PII) is held only on servers located in Canada. (See s.14 “What do you own?”).",
        "terms_conditions_desc8": "The Menuonline service is subject to the laws of Canada and the province of Ontario. (See s.24 “Governing Law”)",
        "terms_conditions_desc9": "Any requests for your data or content by individuals, police or other regulatory agencies from within Canada or outside of Canada will always be referred to you. The only exception would be if this puts us in violation of Canadian or Ontario law; however, in all cases we will strenuously resist any release of your data by anyone but you. (See s.14 “What do you own?”).",
        "terms_conditions_desc10": "Menuonline security is only as good as your management of usernames and passwords. We don’t charge for Menuonline user accounts so create a separate one for each user and keep passwords secret. You are responsible for your account. (See s.3 “How it works”)",
        "terms_conditions_desc11": "You can cancel your account at any time. We do not provide refunds on any unused credit if you cancel. (See s.3 “How it works”)",
        "terms_conditions_desc12": "We can cancel your account at any time. We do provide refunds for email credits and unused services if we cancel your account. (See s.3 “How it works”)",
        "terms_conditions_desc13": "Your data will be deleted after cancellation and may also be deleted if an account user has not logged in for 12 months or more. We will maintain aggregated data from your account that does not include personally identifiable information for statistical purposes. (See s.3 “How it works”)",
        "terms_conditions_desc14": "Email credits are purchased on a pay-as-you-go basis and never expire. The only exception is if you haven’t logged on to your account for 12 months. In this case, we reserve the right to delete your account, your data and the associated credits. (See s.10 “What do I pay for the Service?”)",
        "terms_conditions_desc15": "We work hard to ensure Menuonline is up and running at all times and to provide advance notice if we need to temporarily suspend the service for maintenance. However, we do not guarantee the service will be up and running at all times and we’re not liable for any losses you suffer from using Menuonline, including losses on your part if the system is not running at a time you need it. (See s.17 “Disclaimers”).",
        "terms_conditions_desc16": "We don’t monitor your content or who you send messages to. However, we do monitor complaints and other problems created by your activities. Too many complaints or problems can result in the cancellation of your service at our discretion. (See s.7 “Monitoring”)",
        "terms_conditions_desc17": "We’re committed to providing excellent Product Support to help you become self-sufficient with Menuonline. We provide support in the form of documentation and video on our website, the Menuonline support ticket system and by telephone. There’s no charge for product support; however, we reserve the right to discontinue support for specific client accounts at our discretion. (See s.5 “Support”)",
        "terms_conditions_desc18": "Product Services is for those times when you want us to do some work related to Menuonline rather than do it yourself. Our rate for Product Services is $135 per hour. We also provide consulting services at higher rates. We reserve the right to change our rates at any time. (See s.6 “Product and Consulting Services”)",
        "terms_conditions_desc19": "We will inform you if we believe there’s been a breach of your data and will make an effort to provide related details and information. (See s.14 “What do you own?”)",
        "terms_conditions_desc20": "We reserve the right to make changes to this agreement at any time. We will attempt to inform you about changes to the terms and conditions but use of the service means you agree to changes in the Terms. (See s.9 “Changes”).",
        "terms_conditions_desc21": "The Terms and Conditions and our Privacy Policy have been drafted in English only, and you agree the English language version of the Terms and Conditions and Privacy Policy will take priority in the event of any conflict with any translated version.",
        "terms_conditions_mycircle": "MYCIRCLE TERMS AND CONDITIONS",
        "terms_conditions_last_update": "LAST UPDATE: January 14, 2020",
        "terms_conditions_usage_agreement": "Please read these terms and conditions of service (the 'Terms') carefully before using this web site (the 'Site'). These Terms exempt Menuonline ('Menuonline', 'we', or 'us') and others from liability and\/or limit our and their liability and contain other important provisions that apply to your use of this Site.",
        "terms_conditions_acceptance": "By visiting or using this Site or the Service, you agree on your own behalf, and on behalf of any organization on whose behalf you may act (collectively referred to herein as 'you'), to accept and abide by these Terms for each use of and each visit to this Site.",
        "terms_conditions_services": "1. Our Services.",
        "terms_conditions_services_desc": "The services that we offer through the Site are a service that allows you to create, send, and manage email messages ('Emails') to recipients of your choosing (the 'Service').",
        "terms_conditions_license": "2. License.",
        "terms_conditions_license_desc": "Subject to these Terms, Menuonline hereby grants you a non-exclusive, limited, non-transferable license during the Term to view the Site and use the Service as permitted by the features of the Service. We reserve all rights not expressly granted herein in the Service and the Menuonline Content (as defined below).",
        "terms_conditions_account": "3. How it works.",
        "terms_conditions_account_desc": "To use the Service you need to register for an account. Your account is free. Your account gives you access to the Service and \"functions\" that we may establish and maintain from time to time. To be eligible for an account you must:",
        "terms_conditions_account_requirements": [
            "be at least eighteen (18) years old and able to enter into contracts;",
            "complete the registration process that we have set out;",
            "agree to these Terms and Conditions;",
            "provide true, complete, and up-to-date contact and billing\/payment information."
        ],
        "responsibility": "Responsibility.",
        "responsibilityText": "You are solely responsible for the activity that occurs on your account, and you must keep your account password confidential and secure. Accounts are free, so we encourage you to set up a different account for each user you have. You are also responsible for any account that you have access to, whether or not you authorized the use of that account. You agree that are solely responsible for your interactions with those to whom you send Emails, and you will only send Emails to those people from whom you have consent. You must immediately notify us of any unauthorized use of your accounts.",
        "compliance_with_CASL": "Compliance with the Canadian Anti Spam Legislation (CASL).",
        "compliance_with_CASL_text": "Menuonline role is to facilitate the management of consent settings, not to enforce legislation. It is your responsibility to be compliant with CASL. We help you by setting default functionality and by facilitating obtaining and updating documented express consent and managing rolling expiry dates for implied consent. You are in full control of managing consent for your database; you can update the consent status for all of your contacts in bulk any time, override default settings and disable auto-expiry of implied consent status.",
        "closing_account": "Closing Your Account.",
        "closing_account_text": "Either you or Menuonline may terminate this Agreement at any time and for any reason by giving Notice to the other party. If you provide any information that is untrue, inaccurate, not current or incomplete or use the Service in a way that violates these Terms and Conditions, we have the right to immediately suspend your account and terminate your access to the Service in addition to any other remedies available to us. If you do not use your account for twelve (12) months, we may cancel your account. If we terminate your account for any reason, we will refund you for any Credits you have left. Once terminated, we will permanently delete your account and all the data associated with it, including the Emails you have sent. We may maintain aggregated data from your account that does not include personally identifiable information for statistical purposes.",
        "emailing_content": "Emailing Content.",
        "emailing_content_text": "When interacting with Menuonline via this Site or the Service, you agree to provide true and accurate information. Further you understand that providing confidential information via email is an unsafe practice. You understand that you are solely responsible for anything that you send to anyone using our Service. purposes.",
        "support": "Support.",
        "support_text": "We provide you support to help you become self-sufficient with the Service. We provide you with support in the form of documentation and videos on the Site, the Menuonline support ticket system and by telephone. You can reach us by clicking on the “Support” tab at the top of the Site. There’s no charge for product support; however, we reserve the right to discontinue support for specific client accounts at our discretion.",
        "product_and_consulting_services": "Product and Consulting Services.",
        "product_and_consulting_services_text": "Product Services is for those times when you want us to do some work related to Menuonline rather than do it yourself. Our rate for Product Services is $125 per hour. We also provide consulting services at higher rates. We reserve the right to change our rates at any time.",
        "monitoring": "Monitoring.",
        "monitoring_text": "We will not monitor or judge the content of information transmitted over the Service, but will investigate if we receive complaints of possible inappropriate use. Menuonline may, at any time and in its sole discretion, make determinations that particular uses are or are not appropriate with or without notice to you, according to the following guidelines.",
        "term": "Term.",
        "term_text": "The Term begins when you sign up for an account with us and continues as long as you use the Service. You represent and warrant that you have the authority and ability to sign up for an account for us (including if you sign up on behalf of an organization).",
        "changes": "Changes.",
        "changes_text": "The Term begins when you sign up for an account with us and continues as long as you use the Service. You represent and warrant that you have the authority and ability to sign up for an account for us (including if you sign up on behalf of an organization).",
        "payment": "PAYMENT",
        "what_do_i_pay_for_service": "What do I pay for the Service?.",
        "what_do_i_pay_for_service_text": "You may buy pre-paid credits to send Email (“Credits“) through our Service, as we describe in detail on the “pricing” page of our Site. The Credits never expire and there are no recurring fees for these Credits. The only exception is that if you do not use your account for twelve (12) months, we may cancel your account and any unused Credits. We may change our pricing at any time by updating the pricing pages of our Site, which will take apply to your next purchase of Credits.",
        "how_do_i_pay_for_service": "How do I pay for the Service?.",
        "how_do_i_pay_for_service_text": "You can pay for Credits using any major credit card. We may change which methods of payment we accept at any time.",
        "can_i_get_refund": "Can I get a refund?.",
        "can_i_get_refund_text": "The amounts you have paid for using the Service are non-refundable if you cancel your account. If we cancel your account, we will refund you for any Credits you have left.",
        "rights": "RIGHTS",
        "what_do_we_own": "What do we own?.",
        "what_do_we_own_text": "Except for the content that you provide to us (including, without limitation, contact records, email content and information you collect through the Service) (“Your Content“), all materials included on our Site, the Service and all materials therein or transferred thereby, including without limitation, software, images, text, graphics, illustrations, logos, patents, trade-marks, service marks, photographs, audio, videos, music, and content belonging to other Users (the “Menuonline Content“) (and all intellectual property rights related thereto) are owned by us or our licensors.",
        "what_do_you_own": "What do you own?.",
        "what_do_you_own_text": "We claim no ownership rights over Your Content. Your Content is yours and you retain ownership of Your Content that you upload to the Service. By sharing Your Content through the Service, you agree to allow others to view, edit, and\/or share Your Content in accordance with your settings and these Terms and Conditions. You represent and warrant that you either own or have permission to use all of the material that you use in your Emails. We may use or disclose your materials only as we describe in these Terms and our Privacy Policy.",
        "privacy_statement": "Menuonline respects the privacy of its users. Any personal information which Menuonline collects via this Site or the Service is subject to the Menuonline Privacy Statement which is incorporated by reference into these Terms. We may use and disclose your information according to our Privacy Statement.",
        "access_to_your_content": "Menuonline will not provide access to Your Content to anyone except those employees and contractors of Menuonline who have executed appropriate confidentiality agreements with us or we have your consent.",
        "governmentRequest": "If we receive a request from a government or regulatory authority to have access to Your Content, we will notify you of the request and seek your consent before we release any of Your Content. We will resist any requests for Your Content and will not release Your Content in response to this type of request without your permission or being required to by a court of competent jurisdiction or the applicable regulatory body.",
        "breachNotification": "In the event that we become aware that Your Content has been subject to unauthorized access, use, copying, theft, loss, or use or disclosure in violation of these Terms (a ‘Breach’), we will notify you promptly and inform you of what took place. You agree that Breaches will be handled in accordance with applicable law, and if we need you to inform members of your Email distribution lists then you will agree to do so promptly and as we direct.",
        "contentRetention": "We will retain and use and transmit Your Content in Canada except with your prior written approval or direction (for example, if you choose to send Your Content to individuals outside of Canada). The servers used to provide the Service are located in Canada.",
        "accessTrouble": "If you have trouble accessing Your Content, Menuonline will assist you to retrieve Your Content on a time and materials basis.",
        "reportingAbuse": "If you believe anyone is violating any of these Terms, please notify us immediately. If you received spams you think came from another user of the Service, if you think another user of the Service has posted material that violates any copyrights, or if you think another user of the Service is otherwise violating these Terms please contact us at Support@menuonline.com.",
        "acceptableUse": "You represent and warrant that your use of the Service will comply with all applicable laws and regulations. You agree that you are responsible for determining whether the Service will comply with and are suitable for you to use in your jurisdiction. For greater certainty, you agree that you shall (and agree to ensure that your employees, agents and authorized users shall) ...",
        "exportLaws": "We make no representation that the Site or the Service is appropriate or available for use outside of Canada, and access to them from territories where their contents are illegal is prohibited. You may not use or export or re-export the Menuonline Content or other materials at this Site or any copy or adaptation in violation of any applicable laws or regulations including without limitation Canadian and U.S. export laws and regulations.",
        "disclaimers": "The Service and this Site are provided ‘as is’ without any representations, warranties, or conditions of any kind, and Menuonline expressly disclaims, to the full extent permissible by applicable laws, all representations, warranties, and conditions, express or implied, by operation of law or otherwise, including without limitation any implied warranties and conditions of merchantability, or fitness for any particular purpose or use, or non-infringement of third party rights...",
        "limitLiability": "Menuonline aggregate liability under this Agreement, whether in contract or tort (including negligence), as a result of breach of warranty, strict liability or under any other theory of liability whatsoever, will be limited to direct damages in an amount not exceeding the total fees paid to Menuonline under these Terms and Conditions in the twelve (12) months immediately prior to the month in which the most recent event giving rise to liability occurred.",
        "liabilityExclusions": "None of Menuonline, its affiliates or their respective directors, officers, employees, agents or other representatives (collectively 'Menuonline \"Representatives\"') or the operators of any attached networks will have any responsibility or liability in connection with this Site or the Service for: (i) any indirect, consequential, incidental, exemplary, punitive or special damages; or (ii) for any damages, whether direct, indirect, consequential, incidental, exemplary, punitive or special, characterized as lost revenue, lost savings or revenue or lost profits...",
        "indemnity": "You will indemnify and hold Menuonline and\/or the Menuonline Representatives (the 'Menuonline indemnified parties') harmless from and against any claims brought by third parties, and any fines or penalties awarded against us, arising out of your use of the information accessed from this Site or your use of the Service, and any breach of these Terms...",
        "ipInfringement": "Menuonline shall defend, indemnify and hold you harmless from and against any and all claims, demands, actions, suits or proceedings made or brought against you by a third party alleging that your access to or use of Service in accordance with this Agreement infringes the intellectual property rights under Canadian law of a third party (an 'IP Infringement Claim')...",
        "equitableRelief": "If you violate these Terms then we may seek injunctive relief (meaning we may request a court order to stop you) or other equitable relief. You agree that this will be in addition to and without prejudice to any other rights we may have at law or in equity.",
        "linksToOtherSites": "If we provide any links to third party web sites, they are provided only for convenience. If you use these links, you will leave our Site. We do not control these third party sites or endorse them. You agree that we are not responsible for any of these third party sites or their content...",
        "thirdPartyRequests": "If we have to provide information in response to a legal or government request relating to your account or your use of the Service, then you agree that we may charge you for our costs. These fees may include our legal fees and the costs of our employee time spent preparing the response to the request.",
        "assignment": "This Agreement, and any rights and licenses granted hereunder, may not be transferred or assigned by you, but may be assigned by Menuonline without restriction, upon reasonable advanced notice to you.",
        "governingLaw": "These terms will be governed by and construed in accordance with all applicable federal laws and the laws of Ontario without regard to its conflict of law’s provisions. You agree to submit to the exclusive jurisdiction of the courts of Ontario for the determination of any dispute concerning these Terms.",
        "severability": "If for any reason a court of competent jurisdiction finds any provision of these Terms or portion thereof to be unlawful or for any reason unenforceable, then that provision will be enforced to the maximum extent permissible to give effect to the intent of these Terms, and the remainder of these Terms will continue in full force and effect and will remain enforceable.",
        "waiver": "Any waiver of, or consent to depart from, the requirements of these Terms will be effective only if it is in writing and signed by us, and only in the specific instance and for the specific purpose for which it has been given. Our failure to exercise or our delay in exercising, any right under these Terms will not operate as a waiver of our rights.",
        "furtherAssurances": "Each party agrees to sign and provide all documents and take any actions (or cause the doing of those actions) necessary to meet its obligations under these Terms and conditions.",
        "notices": "All notices to Menuonline must be in writing and sent to us by email. Notices to us must be sent to the attention of our customer service representatives at support@menuonline.com. Notices to you may be sent to the address or email address supplied by you as part of your registration. In addition, we may broadcast notices or messages through this Site to inform you of changes to this Site or other matters of importance, and these broadcasts will constitute notice to you at the time we post them.",
        "entireAgreement": "These Terms and our Privacy Statement make up the entire agreement between you and us with respect to your use of the Site and the Service, and supersede all prior agreements, representations, and understandings between Menuonline and you with respect to this subject matter. In the case of any conflict between the Privacy Statement and these Terms, these Terms and conditions will govern.",
        "language": "The parties have expressly requested and required that these Terms and conditions, our Privacy Policy, and any related documents be drafted in English. Les parties conviennent et exigent expressément que ce Contrat et tous les documents qui s’y rapportent soient rédigés en anglais. You agree that the English language version of these Terms and Privacy Policy will take priority in the event of any conflict with any translated version.",
        "survival": "All provisions of these Terms which by their nature or import are intended to survive expiration or termination of these Terms, shall survive, including, without limitation, Sections 17-19."
    },
    "collect_additional_info": "Would you like to collect additional information?",
    "additionalInfoChoice": "Choose any additional information you would like to collect.",
    "additional_info_yes": "Yes",
    "additional_info_noSkip": "No, Skip",
    "additional_info_label": "Additional information",
    "confirm_business_location": "Let’s first confirm your business location",
    "custom_get_more_reviews_form": "Custom Get More Reviews Form",
    "set_up_custom_form": "Set Up Your Custom Get More Reviews Form by Group",
    "reputation_name_label": "Get More Reviews Name",
    "reputation_name_placeholder": "Enter reputation name",
    "group_label": "Group",
    "group_desc_title": "Group description title",
    "group_desc": "Group description",
    "select_group_placeholder": "Select Group",
    "required_group_name": "Group name is required",
    "before_asking_review": "Before asking the customers for review, Let’s ask them 1 to 4 questions.",
    "feel_free_edit": "Feel free to edit as needed.",
    "incentive_settings": "Incentive settings",
    "incentivize_customer": "Would you like to incentivize your customer for giving a review?",
    "choose_incentive": "Choose the incentive you would like to refer.",
    "scan_qr_feedback": "Scan the QR code and share your valuable feedback with us regarding your experience.",
    "qr_code": "QR Code",
    "scan_me": "SCAN ME!",
    "or_title": "OR",
    "send_otp_title": "Send OTP",
    "sign_in_title": "Sign In",
    "email_addresss_title": "Email Address",
    "reset_password_title": "Reset Password",
    "remember_password_title": "Remembered password?",
    "digit_code_title": "Kindly enter 4 digit code which sent on ",
    "not_received_title": "I didn't receive a verification code!",
    "resend_title": "Please resend",
    "sign_up_form": "Create Your Account",
    "sign_up_sub_title": "Login now to access the latest insights",
    "already_registered_title": "Already have a Menuonline Account?",
    "reserved_title": "Copyrights ©2023 Menuonline. All rights reserved.",
    "terms_title": "Terms of Service",
    "policy_title": "Privacy Policy",
    "sign_up_title": "Sign Up",
    "terms_conditions_title": "Terms & Conditions",
    "business_type_title": "Business Type",
    "business_type_sub_title": "Kindly select one of the business types which describes your business better!",
    "salon_title": "Salon",
    "restaurant_title": "Restaurant",
    "pizza_store_title": "Pizza Store",
    "food_truck_title": "Food Truck",
    "check_domain_title": "Check Domain Name",
    "start_exploring_title": "Start Exploring",
    "sign_in_form": "Login to your account",
    "sign_in_sub_title": "Login now to access the latest insights",
    "remember_title": "Remember Me",
    "otp_title": "OTP",
    "password_title": "Password",
    "forgot_title": "Forgot Password?",
    "not_registered_title": "Don’t have an account?",
    "create_account_title": "Sign up now",
    "create_form_title": "Create Form",
    "first_name_title": "First name",
    "last_name_title": "Last name",
    "email_title": "Email",
    "mobile_number_title": "Mobile Number",
    "birthday_title": "Birthday",
    "gender_title": "Gender",
    "address_title": "Address",
    "short_answer_title": "Short answer",
    "long_answer_title": "Long answer",
    "single_answer_title": "Single answer",
    "single_checkbox_title": "Single checkbox",
    "multiple_choice_title": "Multiple choice",
    "drop_down_title": "Drop-down",
    "yes_no_title": "Yes or No",
    "description_text_title": "Description text",
    "file_upload": "File upload",
    "reset_password_form": "Reset Your Password",
    "reset_password_sub_title": "Quickly reset your password, and get access to your account.",
    "required_full_name_title": "Full Name is required",
    "required_first_name_title": "First name is required",
    "valid_first_name_title": "Enter valid first name",
    "valid_full_name_title": "Enter valid full name",
    "required_block_time": "Block Time is required",
    "required_last_name_title": "Last name is required",
    "valid_last_name_title": "Enter valid last name",
    "valid_mobile_number_title": "Enter valid mobile number",
    "required_mobile_number_title": "Mobile number is required",
    "required_already_mobile_number_title": "Mobile number is already required",
    "valid_otp_title": "Enter valid otp",
    "valid_email_title": "Enter a valid email",
    "required_email_title": "Email is required",
    "required_description_title": "Description is required",
    "required_description_500_characters": "Please enter at least 500 characters",
    "required_description_600_characters": "Please enter Maximum 600 characters",
    "domain_title": "Domain Name",
    "required_password_title": "Password is required",
    "required_password_regex_title": "Must contain a minimum of 8 characters, at least one uppercase character, one lowercase character, one number, and one special case character",
    "required_terms_title": "Please agree to terms & conditions",
    "required_note_title": "Note is required",
    "required_birthday_title": "Birthday is required",
    "no_access_title": "You have no access right now. Please contact your administrator",
    "menu_online_open_txt": "Open",
    "loyalty_program_description": "😊 Find your ideal loyalty program and customize it to match your brand!",
    "lp_list_title": "List Of Loyalty Punch Card",
    "lp_n_title": "Loyalty Punch Card Name",
    "enter_details_title": "Enter Details",
    "stamp_allowed_title": "Stamp Allowed",
    "stamp_items_title": "Stamp Items",
    "lpn_req_title": "Loyalty punch card name is required",
    "valid_lpn_title": "Enter valid loyalty punch card name",
    "brand_color_error": "Brand color must be exactly 7 character",
    "select_dark_brand_color_title": "Please select valid brand color",
    "your_brand_color_title": "Your Brand Color",
    "upload_logo_title": "Upload Logo",
    "locations_title": "Locations",
    "add_lp_title": "Create Program",
    "location_error": "Please select locations",
    "pvs_title": "How many stamps can a customer earn per visit?",
    "max_apd_title": "What's the maximum number of stamps a customer can earn in a day?",
    "logo_error": "Logo is required",
    "expiry_title": "Set expiry on rewards",
    "add_more_rewards_title": "+ Add More Rewards",
    "prev_step_1_title": "Show QR To Earn Points & Enjoy Your Rewards.",
    "delete_lp_title": "This loyalty punch card will be deleted permanently.",
    "delete_user_record_title": "This user record will be deleted permanently.",
    "redeem_title": "Redeem",
    "lp_button_title": "Join Loyalty Punch Card",
    "lp_download_button_title": "Download Card",
    "step_0_title": "Join now, delicious rewards await for loyal foodies like you.",
    "step_1_title": "You have joined",
    "congrats_step_title": "Now you are eligible for Free Pizza",
    "reward_title": "Get Rewards on every visit",
    "visit_title": "Visit & Unlock",
    "rewards_title": "Rewards",
    "dummy_address": "3730 Frankfort Ave ,Louisville Kentucky ,United States",
    "loyalty_qr_default_text": "Scan the QR code, join our loyalty punch card, and indulge in rewards",
    "lp_earn_stamp_txt": "Customers earn stamps on each visit.",
    "lp_get_rewards_txt": "Get Rewards on every time Visit",
    "lp_1_time_visit_txt": "1 Time Visit You Will Get",
    "lp_uppercase_stamp_text": "Stamp",
    "lp_lowercase_stamp_text": "stamp",
    "lp_txt": "Loyalty",
    "lp_per_visit_stamp_validation_msg": "Per visit stamp must be less then maximum allowed stamp per day",
    "lp_per_max_stamp_validation_msg": "Maximum allowed stamp per day must be greater then per visit stamp",
    "lp_valid_value_msg": "Enter valid value",
    "lp_required_field_msg": "This field is required",
    "lp_stamp_required_msg": "Stamp is required",
    "lp_valid_stamp_msg": "Enter valid stamp value",
    "lp_reward_name_required_msg": "Reward name is required",
    "lp_unique_stamp_msg": "The stamp value should be unique",
    "lp_benefits": "A loyalty program boosts repeat visits and revenue while providing customer insights for personalized marketing",
    "lp_how_customer_txt": "Here's how your customers",
    "lp_punch_card_txt": "loyalty punch card",
    "lp_look_txt": "will look! 😍",
    "lp_desc": "Customers will earn stamps for each visit, and once they collect enough, they can redeem special rewards.",
    "lp_edit": "Edit",
    "lp_looks_good_txt": "Looks's Good",
    "lp_customer_view_instruction": "This is what your customer will see. Upload your logo & set color theme",
    "lp_customer_earn_stamps": "Allow customers to earn stamps on every visit and manage their rewards",
    "lp_stamp_rewards_setup": "Set stamp earning rewards, allow customers to get rewards per stamps",
    "lp_loyalty_program_setup": "Give your loyalty program name and set your location to have this program",
    "lp_prev_step1": "Show QR To Earn Points & Enjoy Your Rewards.",
    "lp_visit_1_time_txt": "Visit 1 Time and Get",
    "lp_get_free_txt": "Get Free",
    "lp_visit_allow_txt": "Vist & Unlock",
    "lp_rewards": "Rewards",
    "repsT": "Get More Reviews",
    "offSup": "Office Supplies",
    "repsNxtV": "On your next visit",
    "addRepT": "Add Get More Reviews",
    "repEntrCpnTxt": "Enter Coupon Text",
    "repEntrCpnTxtBtn": "Enter Coupon Button Text",
    "repMngNxtV": "on your next visit?",
    "repMngWldL": "Would you like to get",
    "discount": "Discount",
    "repDisTyp": "Discount Type",
    "repNameT": "Name your reputation",
    "crtRepT": "Create Reputation",
    "feedBackT": "Feedback",
    "confLocT": "Confirm Location",
    "businLocT": "Bussiness Location",
    "addInfoT": "Additional Information",
    "repNReqT": "Get More Reviews name is required",
    "validRepNT": "Enter valid reputation name",
    "groupT": "Group",
    "groupLDescTitleT": "Automatically push contacts into a group",
    "groupLDescT": "All customers who fill out the form will be added to selected group.",
    "que2": "Was your food good?",
    "que3": "Was our service friendly?",
    "que4": "Was our service fast?",
    "addAdditionalT": "Would you like to add any additional information?",
    "phNoMandT": "Phone number is mandatory!",
    "qrGenT": "QR Generated",
    "repFDT": "Your input will help our team serve you better!",
    "letdiT": "Let's do it",
    "tak15secT": "Takes 15 seconds",
    "defQue": "Would you like to receive great offers and promotion from time to time?",
    "defNQue": "Would you like someone to respond back to you?",
    "rateOnGoogleDesc": "Would you be kind enough to rate us on google?",
    "mayBeNT": "Maybe Next Time",
    "yesSureT": "Yes, sure",
    "valid_name": "Enter valid name",
    "nameReq": "Name is required",
    "backToMainT": "Back to Main Page",
    "attachCopT": "Would you like to incentivize your customer?",
    "thankYouT": "Thank you",
    "lftyT": "We do look forward to your next visit!",
    "repPreT": "Reputation Preview",
    "offer_exclusive_discounts": "Offer exclusive discounts and promotions to entice your customers.",
    "couponT": "Coupon",
    "locStepDesc": "Confirm your business location",
    "createRepStepDesc": "Create a custom reputation form  by groups",
    "feedbackStepDesc": "Customer feedback question set",
    "addInfoStepDesc": "Customer fields and incentive settings",
    "FedbkkWDT": "Feedback With Data",
    "repAnlytT": "Reputation Analytics",
    "todayT": "Today",
    "rep_last_7_days_t": "Last 7 Days",
    "last30DaysT": "Last 30 Days",
    "lastMT": "Last Month",
    "custRangeT": "Custom Range",
    "queWiseAnltT": "Question Wise Analytics",
    "atL1QueErrT": "Please select at least one question",
    "deleteRepTitle": "This reputation will be deleted permanetly.",
    "incvCustT": "Yes, Incentivize my customer",
    "collAddInfoT": "Yes, I would like to collect additional information",
    "totRewT": "Total Review",
    "totPosRewT": "Total Positive Review",
    "negFeedT": "Negative Feedback",
    "posFeedT": "Positive Feedback",
    "fineT": "Fine Print",
    "enterFineT": "Enter fine print text",
    "setThemeT": "Set Theme Color",
    "que1T": "Question 1",
    "que2T": "Question 2",
    "que3T": "Question 3",
    "que4T": "Question 4",
    "entMailT": "Enter your email",
    "reputationQRDefTxt": "Scan the QR code and share your valuable feedback with us regarding your experience.",
    "selUser": "Select User",
    "userT": "User",
    "subUReq": "Please select users",
    "updateLoc": "Update Location",
    "leadGenT": "Lead Generation",
    "displayT": "Display",
    "ratingT": "Rating",
    "rep_dashboard_negative_feedback": "Negative Feedback 0%",
    "rep_dashboard_position_feedback": "Positive Feedback 0%",
    "rep_dashboard_total_Feedback_txt": "Total Feedback",
    "rep_dashboard_anony_Feedback": "Anonymous Feedback",
    "rep_dashboard_Feeedback_Data_txt": "Feedback With Data",
    "rep_dashboard_review_txt": "Get More Reviews",
    "rep_dashboard_total_Review_txt": "Total Review",
    "rep_dashboard_total_positive_Review_txt": "Total Positive Review",
    "rep_dashboard_negative_feedback_txt": "Negative Feedback",
    "rep_dashboard_connection_txt": "Connection",
    "rep_dashboard_question_wise_analytics_txt": "Question Wise Analytics",
    "rep_dashboard_date_label": "Select Date",
    "rep_dashboard_custom_range_txt": "Custom Range",
    "rep_tlt_min": "REP",
    "emojis": "Emojis",
    "media_text": "Add Media",
    "custom_field_text": "Insert Custom Fields",
    "shortlink_text": "Add Shortlink",
    "use_template_text": "Use Template",
    "banner_must_have": "Must Have",
    "res_seamless": "Seamless",
    "res_table_reservation": "Table Reservation",
    "res_system": "System",
    "res_text": "Reservations",
    "md_easily": "Easily",
    "md_manage_customers": "Manage Customers",
    "md_visits_interactions": "visits & Interactions",
    "md_desc": "Make dining easier for your customers with real-time table reservations. Manage bookings effortlessly, reduce wait times, and ensure a smooth experience for both staff and guests. Keep your restaurant fully booked while maintaining top-notch service and satisfaction.",
    "engage_convert": "Engage & Convert SMS",
    "coupon_campaign": "and Coupon Campaign",
    "sms_read_rate": "SMS messages are read 98% of the time",
    "real_time_promo": "Deliver promotions in real-time for immediate action",
    "affordable_reach": "Affordable way to reach customers with solid ROI",
    "stay_in_touch": "Stay in touch with your customer at all times",
    "bc_engage_customers": "Engage Customers Convert SMS",
    "bc_coupon_campaign": "and Coupon Campaign",
    "bc_sms_read_rate": "SMS messages are read 98% of the time",
    "bc_real_time_promo": "Deliver promotions in real-time for immediate action",
    "bc_affordable_reach": "Affordable way to reach customers with solid ROI",
    "bc_stay_in_touch": "Stay in touch with your customer at all times",
    "ar_automate_engagement": "Automate Your Client Engagement",
    "ar_with": "with",
    "ar_with_auto_responders": "Auto-Responders",
    "ar_personalized_messages": "Automatically send personalized messages for birthdays, welcome new clients, and reconnect with past customers. Save time while keeping every interaction meaningful and timely.",
    "sq_custom_forms_tools": "Custom Forms & Tools - Simplify",
    "sq_custom_forms_ci": "Client Interactions",
    "sq_smart_qr_desc": "Create custom forms, collect e-signatures, and leverage smart QR codes to streamline your business processes. Tailor each tool to meet your clients' needs and enhance the user experience.",
    "gnc_get_new_cus": "Get New Customers",
    "gnc_get_customers": "from Social Media— Fast and Easy!",
    "gnc_social_media_power": "Use the power of social platforms to attract new customers and drive sales directly to your business. Redirect third-party delivery orders straight to your store or online ordering platform for maximum control and revenue.",
    "wifi_connect_guests": "Connect Guests with",
    "wifi_smart": "Smart Wi-Fi",
    "wifi_connect_grow": "& Grow Your Insights",
    "wifi_smart_wifi_desc": "Provide a hassle-free Wi-Fi connection to your customers through a branded splash screen while collecting valuable customer data to enhance your marketing efforts and engagement.",
    "wifi_create_splash_page": "Create Guest Wifi Splash Page",
    "instant_menu_create": "Create your",
    "instant_menu_text": "Instant menu",
    "instant_menu_wm": "within minutes",
    "instant_menu_desc": "Enhances customer experiences by allowing for real time updates, easy-customization and improved engagement making it easier to showcase your offerings and keep your content fresh.",
    "instant_menu_create_digital_menu": "Create a Digital Menu",
    "loyalty_program_rewards": "Earn Rewards",
    "loyalty_program_on_visits": "on every visit",
    "loyalty_program_create": "Create Loyalty Program",
    "reputation_create": "Create Reputation",
    "reputation_boost": "Boost Your",
    "reputation_text": "Reputation",
    "reputation_reviews": "with More Reviews!",
    "reputation_desc": "Collect valuable feedback from your customers to enhance your service and reputation. Share your experience and help us grow—your review matters!",
    "dashboard": "Dashboard",
    "blast_campaign": "Blast Campaign",
    "blast_campaigns": "Blast Campaigns",
    "blast_campaign_sub_title": "Launch blast campaigns to reach your all clients in few clicks",
    "auto_responders": "Auto Responders",
    "auto_responder": "Auto Responder",
    "auto_responder_sub_title": "Easy to use event-based promotions for your clients",
    "smart_qr_form": "Smart QR \/ Form",
    "get_new_customer": "Get New Customer",
    "marketing": "Marketing",
    "clients": "Clients",
    "group_data": "Groups Data",
    "clients_data": "Clients Data",
    "account": "Account",
    "user_acc_info": "User Account Information",
    "current_plan": "Current Plan",
    "payments": "Payments",
    "location": "Location",
    "location_list": "Location List",
    "users": "Users",
    "users_sub_title": "List of all users and their details",
    "online_profile": "Online Profile",
    "change_password": "Change Password",
    "menu": "Menu",
    "reseller_dashboard": "Reseller Dashboard",
    "companies": "Companies",
    "plans_bought": "Plans Bought",
    "agreement_t_c": "Agreement T&C",
    "wifi_dashboard": "Wifi Dashboard",
    "hot_spot": "Hotspot",
    "splash_pages": "Splash Pages",
    "get_more_reviews": "Get More Reviews",
    "office_supplies": "Office Supplies",
    "reservation": "Reservation",
    "floor_table": "Floor & Table",
    "guest_smart_wifi": "Guest Smart WiFi",
    "digital_signage": "Digital Signage",
    "schedule_down": "Scheduledown",
    "business_settings": "Business Settings",
    "sub_user_login": "Subuser Login",
    "help_center": "Help Center",
    "switch_to_hub": "Switch to Hub",
    "meeting_records": "Meeting Records",
    "logout": "Logout",
    "no_access_text": "You have a no access right now. please contact your administrator",
    "menu_item": "MENU ITEM",
    "menu_capital": "MENU",
    "good_day": "Good day",
    "no_permission_text": "You don't have permission to perform this action!!",
    "enter_valid_pin": "Enter valid pin",
    "add_reservation": "Add Reservation",
    "edit_reservation": "Edit Reservation",
    "per_req": "Number of persons is required",
    "req_block_time": "Block Time is required",
    "pending_status": "Pending",
    "booked_status": "Booked",
    "completed_status": "Completed",
    "cancelled_status": "Cancelled",
    "reservation_slot": "Reservation Slot",
    "block_time": "Block Time",
    "slot_time": "Reservation Slot Time:  ",
    "template_name_req": "Template name is required",
    "template_type_req": "Template type is required",
    "sms_template_req": "SMS template is required",
    "group_req": "Group is required",
    "valid_date_time": "Enter valid date and time",
    "valid_group_name": "Enter valid group name",
    "reservation_mark_completed": "This reservation will be marked as completed.",
    "customer_name": "Customer Name",
    "date_time": "Date Time",
    "table_no": "Table No",
    "floor": "Floor",
    "customer_name_placeholder": "customer_name",
    "date_time_placeholder": "date_time",
    "table_no_placeholder": "table_no",
    "floor_placeholder": "floor",
    "select_floor": "Select Floor",
    "select_table": "Select Table",
    "customer_name_macro": "%%customer_name%%",
    "date_time_macro": "%%date_time%%",
    "table_no_macro": "%%table_no%%",
    "floor_macro": "%%floor%%",
    "template_name": "Template Name",
    "login_with_4_digit_pin": "Login With Your 4 Digit Pin",
    "login_now_to_access_latest_insights": "Login now to access the latest insignts",
    "mkt_BS_Title": "Blast SMS",
    "step_1": "Customize Message",
    "step_2": "Choose Audience",
    "step_3": "Schedule Message",
    "step_4": "Send a Message",
    "step_5": "Manage Message",
    "bs_tab_title": "Blast Campaign Summary",
    "default_message": "Hey %%firstname%%, Special discount on brunch! Dine with us today and get exclusive 10% discount on your bill!",
    "bs_Title": "Choose a Campaign Type",
    "sms_title": "SMS\/MMS",
    "reach_out_to_customer": "Reach out to customers through text and multimedia messages.",
    "wp_text": "WhatsApp",
    "connect_with_wp": "Connect with your clients using WhatsApp  💬",
    "send_discount_offers": "Send Discounts offers via SMS 📲",
    "send_coupons_via_sms": "Send coupons via SMS to your customers 🎟️",
    "env_Cap": "Offer exclusive discounts and promotions to entice your customers",
    "cmt_Caption": "Reach out to customers through text and multimedia messages",
    "wp_caption": "Connect with patrons using WhatsApp for seamless and direct communication",
    "msg_Prev_Title": "Message Preview",
    "cou_Prev_Title": "Coupon Preview",
    "blast_SMS": "SMS\/MMS Campaign",
    "bs_P_Msg": "In Queue",
    "bs_S_Msg_C": "Failed",
    "bs_F_Msg_Count": "Delivered",
    "previous": "Previous",
    "next_ST": "Next",
    "next_S": "Next Step",
    "req_Temp_Name_Title": "Template name is required",
    "req_Template_Tx_Title": "Template text is required",
    "add_Temp_Title": "Add Template",
    "link_Desc": "Add a link of your choice so clients can book with you via Facebook, Google, or your own website.",
    "audience_Title": "Audience",
    "client_G1_Title": "New Clients",
    "client_G2_Title": "Recent Clients",
    "client_G3_Title": "Loyal Clients",
    "client_G4_Title": "Lapsed Clients",
    "client_G5_Title": "Clients by Connection Date",
    "on_Which_Day": "On Which Day",
    "on_Day": "On Day",
    "immediate_Desc": "Your message will be sent immediately, please click Next to begin.",
    "never_Desc": "This campaign is scheduled to run forever until you end it manually.",
    "end_Desc": "The campaign will end on the date you choose from here.",
    "success_Message": "Campaign saved successfully!",
    "send_Success_Message": "Campaign sent successfully!",
    "msg_Name_Field_Title": "Campaign Title",
    "valid_campaign_title": "Enter valid Campaign title",
    "msg_Type_Title": "Message Type",
    "sms_Desc": "1 Credit per SMS",
    "mms_Desc": "3 Credits per MMS",
    "client_GTO_1": "Day",
    "client_GTO_2": "Hours",
    "minutes": "Minutes",
    "client_GTO_4": "Week",
    "time_Option_1": "Day",
    "time_Option_2": "Hours",
    "c_Grp_Day_1": "90 Days",
    "c_Grp_Day_2": "60 Days",
    "c_Grp_Day_3": "30 Days",
    "first": "First",
    "second_T": "Second",
    "third_T": "Third",
    "fourth_T": "Fourth",
    "fifth_T": "Fifth",
    "delete_BS_T": "This campaign will be deleted permanently.",
    "delete_MBS_T": "These campaigns will be deleted permanently.",
    "cFT_1": "Phone",
    "cFT_2": "Contact First Name",
    "cFT_3": "Contact Last Name",
    "cFT_4": "Contact Email",
    "cFT_5": "Note",
    "cFT_6": "Birthday",
    "cFT_7": "Company Name",
    "cF_Meta_Tag_1": "%%phone%%",
    "cF_Meta_Tag_2": "%%firstname%%",
    "cF_Meta_Tag_3": "%%lastname%%",
    "cF_Meta_Tag_4": "%%email%%",
    "cF_Meta_Tag_5": "%%note%%",
    "cF_Meta_Tag_6": "%%birthday%%",
    "cF_Meta_Tag_7": "%%company_name%%",
    "cF_Name_1": "phone",
    "cF_Name_2": "firstname",
    "cF_Name_3": "lastname",
    "cF_Name_4": "email",
    "cF_Name_5": "note",
    "cF_Name_6": "birthday",
    "cF_Name_7": "company_name",
    "all_clients": "AllClients",
    "aud_Type_2": "ClientGroups",
    "aud_Type_3": "CustomerGroups",
    "aud_Type_T1": "All Clients",
    "aud_Type_T2": "Client Groups",
    "aud_Type_T3": "Customer Groups",
    "new_clients": "New Clients",
    "client_G2": "Recent Clients",
    "client_G3": "Loyal Clients",
    "client_G4": "Lapsed Clients",
    "client_G5": "Clients by Connection Date",
    "never_text": "Never",
    "ending_on": "Ending on",
    "send_T2": "Scheduled",
    "send_Type_3": "Recurring",
    "daily": "Daily",
    "weekly_T": "Weekly",
    "monthly_T": "Monthly",
    "yearly_T": "Yearly",
    "each": "Each",
    "on_the": "On The",
    "monday": "Monday",
    "exclusive_offer": "Exclusive offer for you",
    "redeem_now": "Redeem Now",
    "redeem_with_cashier": "Please redeem with cashier",
    "lg_Day_2": "Tuesday",
    "lg_Day_3": "Wednesday",
    "lg_Day_4": "Thursday",
    "lg_Day_5": "Friday",
    "lg_Day_6": "Saturday",
    "lg_Day_7": "Sunday",
    "msg_Pre_T": "Dominos",
    "cA_Edit_T1": "All",
    "cA_Edit_T2": "Included",
    "cA_Edit_T3": "Excluded",
    "SMS": "SMS",
    "MMS": "MMS",
    "usd_T": "USD",
    "cad_T": "CAD",
    "msg": "Message",
    "which_Day": "Which Days",
    "end_Date_Tx": "End Date",
    "sDate_Err": "Start date must be greater than or equal to today",
    "eDate_Err": "End date must be later than start date",
    "start_Date_Req": "Start date is required",
    "end_Date_Req": "End date is required",
    "time_req": "Time is required",
    "client_GT1": "Clients subscribed in the last",
    "client_GT2": "Clients with connection in the last",
    "client_GT3": "Clients with at least",
    "client_GT4": "with the last",
    "client_GT5": "who did not return in the last",
    "dummy_Phone_No": "+91987-654-3210",
    "test_T": "Test",
    "dummy_Birth_Date": "01-01-2001",
    "image_Req": "Media or URL is required",
    "time_Title": "Time",
    "start_date": "Start Date",
    "end_date": "End Date",
    "auto_Send_T": "Automatically send to clients",
    "add_Media": "Add Media",
    "in_Title": "In",
    "c_Temp_T": "Campaign Template",
    "temp_NT": "Template Name",
    "type_T": "Type",
    "select_template_type": "Select template type",
    "sel_Temp_T": "Select Template Type",
    "sms_temp_t": "SMS Template",
    "temp_T": "Template Text",
    "default_Msg": "Hey %%firstname%%, Special discount on brunch! Dine with us today and get an exclusive 10% discount on your bill!",
    "refill_T": "Refill",
    "avl_Credit": "Available Credits",
    "req_Credit": "Total Credits Required",
    "rem_Credit": "Remaining Credit",
    "refill_Credit": "Credits to be Refilled",
    "clients_Req": "Select at least one client",
    "subclient_Req": "Selected clients are not subscribers.",
    "refill_Desc": "Please refill the required credits",
    "url_Regex": "^((http|https):\/\/).....+$",
    "sd_Invalid": "Start date is invalid",
    "ed_Invalid": "End date is invalid",
    "img_Url_Invalid": "Invalid image URL",
    "time_Invalid": "Time is invalid",
    "time_And_Date_Invalid": "Enter a valid date and time",
    "time_Invalid_Bfr": "Select a time at least 5 minutes later from the current time",
    "sod_Req": "Send on day is required",
    "add_Card": "Add a New Card",
    "sm_D_Rep_Title": "SMS\/MMS Delivery Report",
    "send_Now_T": "Send Now",
    "schd_Now_T": "Schedule SMS",
    "test_SMS_T": "Send Test SMS",
    "save_AD_T": "Save as Draft",
    "back_TE_T": "Back to Edit",
    "reset_tex": "Reset",
    "update_tex": "Update",
    "dum_msg1": "To display customer name, use %%customer_name%% in the template",
    "dum_msg2": "To display reservation time, use %%date_time%% in the template",
    "dum_msg3": "To display table number, use %%table_no%% in the template",
    "embedded_link": "Embedded Link",
    "img_title": "Image",
    "img_input_text": "Text:",
    "img_join_text": " to join our list",
    "img_copy_text": "Copy",
    "img_not_found": "Generated image not found!!",
    "or_text": "OR",
    "web_signup_image_content": "By submitting this form and signing up via text, you consent to receive marketing text messages (such as promotion codes and reminders). Message and data rates may apply. Message frequency varies. You can unsubscribe at any time by replying STOP",
    "img_download_success": "Image downloaded successfully",
    "embedded_link_copied": "Embedded link copied successfully",
    "media_url_required": "Media or url is required",
    "invalid_image_url": "Invalid image url",
    "invalid_file": "File is invalid",
    "image_error_1mb": "Please insert an image that is less than 1MB",
    "image_error_2_5mb": "Please insert an image that is less than 2.5MB",
    "image_error_3mb": "Please insert an image that is less than 3MB",
    "change_title": "Change",
    "drop_file_text": "Drop file here or click to upload",
    "apply_text": "Apply",
    "search_here": "Search Here",
    "update_status": "Update Status",
    "reservation_deleted_permanently": "This reservation will be deleted permanently.",
    "table_text": "Tables",
    "add_table_button": "Add New Table",
    "add_table_text": "Add Table",
    "edit_table_text": "Edit Table",
    "table_delete_text": "This table will be deleted permanently.",
    "multiple_table_delete_text": "These tables will be deleted permanently.",
    "table_error_name": "Please Enter The Table Name",
    "table_error_invalid_name": "Please Enter Valid Table Name",
    "table_error_floor": "Please Select The Floor",
    "table_error_select": "Please Select The Table",
    "table_capacity_text": "Table Capacity",
    "capacity_text": "Capacity",
    "table_occasion_text": "Find Your Table For Any Occasion",
    "table_name_text": "Table Name",
    "table_capacity_error": "Table capacity is required",
    "cancel_text": "Cancel",
    "submit_text": "Submit",
    "select_valid_date": "Select valid date",
    "select_valid_time": "Select valid time",
    "valid_contact_number": "Enter valid contact number",
    "date_req": "Date is required",
    "date_error_later_than_today": "Date must be later than or equal to today's date",
    "time_error_later_than_5_minutes": "Select time at least 5 minutes later from current time",
    "number_person_req": "Number of person is required",
    "contact_no_req": "Contact Number is required",
    "contact_no": "Contact Number",
    "select_time": "Select Time",
    "for_how_many_person": "For how many persons?",
    "f_name": "First Name",
    "l_name": "Last Name",
    "business_name": "Business Name",
    "business_no": "Business Number",
    "no_for_sign_in": "This number is used for signin",
    "business_email": "Business Email",
    "notes_tex": "Notes",
    "floor_deleted_permanently": "This floor will be deleted permanently.",
    "add_floor": "Add Floor",
    "edit_floor": "Edit Floor",
    "name_text": "Name",
    "mobile_no": "Mobile No.",
    "person_text": "Person",
    "date_and_time": "Date & Time",
    "actions_text": "Actions",
    "extra_text": "Extra",
    "floor_name_req": "Floor Name is required",
    "floor_name": "Floor Name",
    "status_text": "Status",
    "category_status_req": "Category Status is required",
    "table_deleted_permanently": "This table will be deleted permanently.",
    "tables_deleted_permanently": "This tables will be deleted permanetly.",
    "back_to_home": "Back To Home",
    "link_copied_text": "Link copied to clipboard",
    "cust_dash_head_to_title": "to",
    "cust_dash_head_location_title": "Location",
    "select_location_title": "Select Location",
    "all_locations_label": "all_locations",
    "rep_customer_feedback_analytics": "😊 Track your customer feedback with real-time analytics",
    "rep_customer_txt": "Customers",
    "rep_delete_title": "This reputation will be deleted permanetly.",
    "rep_qr_def_txt": "Scan the QR code and share your valuable feedback with us regarding your experience.",
    "delete_title": "Delete",
    "user_list_title": "List of Clients",
    "os_nfc_txt": "NFC Cards",
    "os_sign_holder_txt": "Sign Holder Stand",
    "os_store_Sign_holder_txt": "Store Sign Holders",
    "StaT": "Statistics",
    "AllgT": "All Groups",
    "FavT": "Favourite",
    "MostActT": "Most Active",
    "grT": "Group Name",
    "keywT": "Keywords",
    "exSubT": "Message for existing subscriber",
    "ArchT": "Archived",
    "gNotiSms": "Notify me about each new contact through SMS",
    "gNotiEmail": "Notify me about each new contact through Email",
    "reqGName": "Group name is required",
    "validGN": "Enter valid group name",
    "valid_phone_no": "Enter valid Phone Number",
    "phone_no_req": "Phone Number is required",
    "required_message_text": "Message is required",
    "required_discount_text": "Discount is required",
    "less_than_discount": "Discount amount must be less then 5000",
    "discount_percentage_invalid": "Discount percentage must be less then or equal to 100",
    "discount_type_req": "Discount type is required",
    "discount_text_req": "Coupon text is required",
    "reqContactNo": "Contact Number is required",
    "reqMsgNT": "Campaign title is required",
    "reqLinkT": "Link is required",
    "delMGT": "This groups will be deleted permanetly.",
    "defMemMsg": "Type your message here",
    "add_cust_to_grp_title": "Add Client to Group",
    "group_title": "Groups",
    "group_create_title": "Create Group",
    "group_name_txt": "Group Name",
    "group_table_keyword_title": "Keyword",
    "actions_title": "Actions",
    "clients_title": "Clients",
    "send_title": "Send",
    "qr_title": "QR",
    "delete_group_txt": "This group will be deleted permanetly.",
    "delete_groups_txt": "This groups will be deleted permanetly.",
    "delete_client_title": "This client will be deleted permanetly.",
    "delete_clients_title": "This clients will be deleted permanetly.",
    "delete_multiple_title": "Multiple Delete",
    "wel_sms_mms": "Send welcome SMS\/MMS?",
    "key_words_title": "Keywords",
    "srch_plch_txt": "Search Here",
    "req_location_id_title": "Location is Required",
    "create_text": "Create",
    "view_text": "View",
    "immediately": "Immediately",
    "business_name_req": "Business name is required",
    "business_no_req": "Business number is required",
    "valid_business_name": "Enter valid business name",
    "valid_business_no": "Enter valid business Number",
    "address_req": "Address is required",
    "valid_address": "Please select valid address",
    "time_zone_req": "Time zone is required",
    "image_req": "Please insert image that is less than 2.5mb",
    "valid_file": "File is invalid.",
    "logo": "Logo",
    "time_zone": "Time Zone",
    "save": "Save",
    "account_type_req": "Account type is required",
    "gst_no_req": "GST number is required",
    "gst_no_valid": "Enter valid GST number",
    "set_up_payments": "Set up payments",
    "billing_details": "Enter your billing details",
    "billing_details_desc": "Your billing details will be displayed on your monthly invoice from menuonline.",
    "account_type": "Account Type",
    "select_account_type": "Select account type",
    "gst_no": "GST Number",
    "transaction_details": "Transaction Details",
    "payment_method": "Payment method",
    "billing_period": "Billing period",
    "paid_by": "Paid by",
    "download": "Download",
    "pay_now": "Pay Now",
    "pull_down_refresh": "Pull down to refresh",
    "release_refresh": "Release to refresh",
    "billing_details_text": "Billing Details",
    "payment_methods": "Payment Methods",
    "invoice": "Invoice",
    "invoice_to": "Invoice To :",
    "issue_date": "Issue Date",
    "due_date": "Due Date",
    "amount_due": "Amount Due",
    "charges": "Charges",
    "sub_total_capitalize": "Sub Total",
    "hst": "HST",
    "grand_total": "Grand Total",
    "invoice_generated_on": "Invoice Generated on",
    "contact_us": "Contact Us",
    "invoice_issue": "If you have gone through your bill and still have question",
    "call": "Call",
    "send_sms": "Send SMS",
    "payment_success": "Payment is successfull",
    "edit_credit_card": "Edit Credit Card",
    "add_credit_card": "Add Credit Card",
    "modify_card_info": "Modify your card Information",
    "enter_card_info": "Enter your card Information",
    "account_no_req": "Account number is required",
    "card_name_req": "Name on card is required",
    "expiry_date_req": "Expiry date is required",
    "valid_expiry_date": "Enter valid expiry date",
    "valid_cvv": "Enter valid cvv",
    "cvv_req": "CVV is required",
    "card_no": "Card Number",
    "name_of_card": "Name on Card",
    "expiry": "Expiry",
    "mm_text": "MM\/YY",
    "cvv": "CVV",
    "set_as_default": "Set as Default",
    "add_new_card": "Add New Card",
    "all_credit_card": "Your All Credit Cards",
    "fastest_checkout": "Fastest check out with secure and reliable platform, Trusted by large group of business",
    "coupon_button_req": "Coupon button text is required",
    "max19_characters_allowed": "Maximum 19 characters are allowed",
    "fine_print_text_req": "Fine print text is required",
    "clients_req": "Select at least one client",
    "client_groups": "ClientGroups",
    "day_text": "Day",
    "valid_days": "Day value is too large enter valid days",
    "hours_text": "Hours",
    "enter_valid_hours": "Hours value is too large enter valid hours",
    "enter_valid_min": "Minutes value is too large enter valid minutes",
    "clients_with_connection_required": "Clients with connection in the last is required",
    "connection_required": "Connection is required",
    "connection_value_too_large": "Connections value is too large enter valid connection",
    "minutes_required": "Minutes required",
    "clients_with_at_least_connection_required": "Clients with at least connection is required",
    "last_minutes_req": "With the last minutes is required",
    "clients_not_returned_required": "Who did not return in the last minutes is required",
    "not_return_in_last_minutes_invalid": "Not return in last minutes must be less than the last minutes.",
    "customerGroups": "CustomerGroups",
    "select_customer_group": "Please select the customer group",
    "location_required": "Location is required",
    "start_date_required": "Start date is required",
    "start_date_invalid": "Start date is invalid",
    "start_date_invalid_error": "Start date must be greater then or equal to today",
    "recurring": "Recurring",
    "end_date_required": "End date is required",
    "end_date_invalid": "End date is invalid",
    "end_date_invalid_error": "End date must be later than start date",
    "time_invalid": "Time is invalid",
    "monthly_text": "Monthly",
    "send_day_req": "Send on day is required",
    "loyal_clients": "Loyal Clients",
    "recent_clients": "Recent Clients",
    "lapsed_clients": "Lapsed Clients",
    "clients_connection_date": "Clients by Connection Date",
    "scheduled_text": "Scheduled",
    "weekly": "Weekly",
    "selected_clients_not_subscriber": "Selected clients are not subscriber.",
    "message_preview_title": "Message Preview",
    "coupon_preview_title": "Coupon Preview",
    "form_text": "Form",
    "preview_text": "Preview",
    "next_text": "Next",
    "send_test_SMS": "Send Test SMS",
    "save_draft": "Save as Draft",
    "back_to_edit": "Back to Edit",
    "select_payment_method": "Please select the payment method",
    "schedule_SMS": "Schedule SMS",
    "send_now": "Send now",
    "get_more_ratings": "Get more Ratings",
    "overview": "Overview",
    "reset_campaign": "Reset Campaign",
    "permissions": "Permissions",
    "location_name": "Location name",
    "phone_no": "Phone Number",
    "location_email_address": "Location Email Address",
    "located_business": "Where is your business located?",
    "business_logo": "Business logo",
    "congratulations": "Congratulations",
    "almost_done": "You're almost done",
    "publish": "Publish",
    "about_your_business": "About Your Business",
    "add_your_location": "Add Your Location",
    "publish_location": "Publish Location",
    "location_name_req": "Location name is required",
    "valid_location_name": "Enter valid location name",
    "business_logo_req": "Business logo is required",
    "please_accept_terms": "Please accept terms and conditions",
    "add_new_location": "Add New Location",
    "edit_location": "Edit Location",
    "add_location": "Add Location",
    "existing_msg_subscriber_txt": "Message for existing subscriber",
    "msg_capitalize_txt": "Message",
    "group_noti_sms": "Notify me about each new contact through SMS",
    "group_noti_email": "Notify me about each new contact through Email",
    "group_member_msg": "It seems like you already part of our group!!",
    "group_mend_msg": "STOP to end. HELP for help. SMS & data rates may apply",
    "Disclaimer_title": "Disclaimer Text: ",
    "group_def_msg": "We are online now! Check out our service menu and book your next appointment today.",
    "required_msg_txt": "Message is required",
    "required_Key_txt": "Keywords are required",
    "required_mem_msg": "Subscriber message is required",
    "client_list_title": "Client List",
    "add_contact_txt": "Add Contact",
    "delete_all_clients_txt": "Delete All Clients",
    "delete_all_clients_msg": "Are you sure you want to delete all clients? They cannot be retrieved.",
    "delete_all_txt": "Delete All",
    "timeline_title": "TimeLine",
    "unsubscribe_title": "Unsubscribe",
    "subscribe_title": "Subscribe",
    "unsubscribe_confirm_msg": "Would you like to mark this customer as an unsubscriber?",
    "subscribe_confirm_msg": "Would you like to mark this customer as a subscriber?",
    "no_title": "No",
    "yes_title": "Yes",
    "client_name_title": "Client Name",
    "source_title": "Source",
    "contact_file_Req": "Contacts file is required",
    "req_title": "Required",
    "opt_in_req": "Imported contacts must be 100% opt-in",
    "image_invalid_error": "File is invalid.",
    "import_contacts_msg": "Import Contacts",
    "csv_upload_title": "CSV Contacts file to upload *",
    "csv_file_desc": "Please upload csv file only - csv file columns - First name, Last name, Email id, Mobile no, Gender, DOB(MM\/DD\/YYYY) -  no spaces, dashes, or parentheses in Mobile no",
    "to_download_title": "To download demo file click here",
    "contains_header_title": "Yes, this file contains headers",
    "opt_in_100_txt": "Yes, these imported contacts are 100% opt-in",
    "DisT": "Disclaimer Text: ",
    "gMendMsg": "STOP to end. HELP for help. SMS & data rates may apply",
    "reqKeyT": "Keywords are required",
    "reqMemMsg": "Subscriber message is required",
    "reqMsgT": "Message is required",
    "gMemberMsg": "It seems like you already part of our group!!",
    "gdefMsg": "We are online now! Check out our service menu and book your next appointment today.",
    "next_title": "Next",
    "male_title": "Male",
    "male_val": "male",
    "female_title": "Female",
    "female_val": "female",
    "others_txt": "Others",
    "others_val": "others",
    "validBirthdate": "Enter valid birthdate",
    "valid_phone_no_title": "Enter valid Phone Number",
    "required_phone_no_title": "Phone Number is required",
    "add_new_client_txt": "Add a new client",
    "update_client_txt": "Update Client",
    "phone_num_text": "Phone Number",
    "dob_title": "DOB",
    "select_gender_title": "Select Gender",
    "timelineTitle": "TimeLine",
    "confirm_location": "Confirm Location",
    "feedback_title": "Feedback",
    "rep_question_1": "Was the business clean?",
    "rep_que_2": "Was your food good?",
    "rep_que_3": "Was our service friendly?",
    "rep_que_4": "Was our service fast?",
    "business_location_req_title": "Bussiness location is required",
    "valid_location_err_txt": "Please select valid address",
    "rep_management_title": "Get More Reviews Management",
    "rep_about_desc": "Reputation help you increase google rating by asking your customers for feedback",
    "preview_title": "Preview",
    "rep_preview_txt": "Reputation Preview",
    "back_title": "Back",
    "fine_print_txt": "Please redeem with cashier",
    "redeem_me_title": "Redeem Me",
    "rep_nxt_visit_txt": "On your next visit",
    "device_type": "Type of Device Used",
    "connection_method": "Connection Method",
    "peak_days": "Peak Days",
    "peak_hours": "Peak Hours",
    "guest_by_day": "Guest by Day",
    "guest_visit": "Guest Visit",
    "connection": "Connection",
    "connection_duration": "Connection Duration",
    "guest_visit_1": "1 time",
    "guest_visit_2": "2 times",
    "guest_visit_3_5": "3-5 times",
    "guest_visit_6_10": "6-10 times",
    "guest_visit_11_25": "11-25 times",
    "guest_visit_26_100": "26-100 times",
    "guest_visit_100_plus": "100+ times",
    "device_android_total": "Total Android",
    "device_android": "Android",
    "device_desktop": "Desktop",
    "device_ios": "Ios",
    "device_ios_total": "Total Ios",
    "device_desktop_total": "Total Desktop",
    "connection_duration_10": "<=10 Min",
    "connection_duration_20": "11-20 Min",
    "connection_duration_30": "21-30 Min",
    "connection_duration_40": "31-45 Min",
    "connection_duration_60": "46-60 Min",
    "connection_method_email": "Email",
    "connection_method_sms": "SMS",
    "connection_method_google": "Google",
    "connection_method_facebook": "Facebook",
    "age_category_1": "<18",
    "age_category_2": "18-24",
    "age_category_3": "25-34",
    "age_category_4": "35-44",
    "age_category_5": "45-54",
    "age_category_6": "55+",
    "all_guest_txt": "All Guest",
    "new_Guest_txt": "New Guest",
    "connections_txt": "Connections",
    "hotspot": "Hotspot",
    "hotspot_list": "Hotspot List",
    "add_new_hotspot": "Add New Hotspot",
    "hotspot_information": "Hotspot Information",
    "edit_details_button": "Edit Details",
    "wifi_info_message": "Connect and Enjoy free WiFi",
    "connection_message": "Great, You have been connected to ",
    "connection_message_suffix": " WiFi",
    "wifi": "WiFi",
    "login_to_access": "Login to get access",
    "verification_code": "Verification Code",
    "verification_code_message": "Please type the verification code sent to ",
    "wifi_user_email": "adamo@gmail.com",
    "wifi_label": "Wifi",
    "your_name": "What’s your name by the way?",
    "your_birthdate": "Can you tell us, what’s your birthdate?",
    "request_guest_wifi_name": "Please Enter Guest Wifi Name",
    "request_device_key": "Please Enter Device Key",
    "request_maximum_internet_access_length": "Please Select Maximum Internet Access Length",
    "mac_address": "MAC address",
    "device_port": "Device Port",
    "hardware": "Hardware",
    "current_uptime": "Current Uptime",
    "nearby_devices": "Nearby Devices",
    "firmware": "Firmware",
    "who_are_you": "Who are you?",
    "where_to_contact_you": "Where do we get in touch with you?",
    "your_area_code": "What is your area code?",
    "connected": "Connected",
    "delete_hotspot_message": "This hotspot will be deleted permanently.",
    "delete_multiple_hotspots_message": "These hotspots will be deleted permanently.",
    "speed_error": "Speed should be at least 0.01",
    "speed_max_error": "Please enter a value up to 1024 for unlimited speed, or select a lower value within the supported range",
    "device_ssid": "Device SSID",
    "device_ssid_two": "Device SSID Two",
    "device_ssid_two_wpa": "Device SSID Two WPA",
    "device_key": "Device Key",
    "select_location": "Select Location",
    "select_maximum_internet_access_length": "Select Maximum Internet Access Length",
    "download_speed": "Download Speed",
    "upload_speed": "Upload Speed",
    "network_length_1": "15 Minutes",
    "network_length_2": "30 Minutes",
    "network_length_3": "45 Minutes",
    "network_length_4": "1 Hour",
    "network_length_5": "2 Hours",
    "network_length_6": "4 Hours",
    "network_length_7": "6 Hours",
    "network_length_8": "8 Hours",
    "network_length_9": "10 Hours",
    "network_length_10": "12 Hours",
    "employee_wifi_name": "Employee Wifi Name",
    "employee_wifi_password": "Employee Wifi Password",
    "guest_wifi_name": "Guest Wifi Name",
    "menu_other_products_txt": "Other Products | Menu Online",
    "menu_home_text": "Home | Menu Online",
    "whatsapp_title": "Whatsapp",
    "select_campaign_type": "Choose a Campaign Type",
    "select_readymade_templates": "Choose from readymade templates or make your own",
    "campaign_title_required": "Campaign title is required",
    "type_here": "Type here...",
    "location_permission_req": "Location permission is required",
    "platform_permission_req": "Platform permission is required",
    "profile_picture": "Profile picture",
    "click_to_upload": "Click to upload",
    "location_permission": "Location Permission",
    "pin": "Pin",
    "platform_permission": "Platform Permission",
    "set_pin": "Set PIN",
    "list_of_users": "List of Users",
    "create_user": "Create User",
    "terms_and_condition_req": "Terms and Conditions are required",
    "terms_and_conditions": "Terms and Conditions",
    "recommended": "RECOMMENDED",
    "mo": "\/mo",
    "unsubscribe": "Unsubscribe",
    "cancel_subscription": "Are you sure you want to cancel subscription?",
    "upgrade": "Upgrade",
    "plan_upgraded_successfully": "The plan upgraded successfully!",
    "menu_online": "Menuonline",
    "support": "Support",
    "wifiC": "WIFI",
    "billing": "Billing",
    "please_upgrade": "Please upgrade to enjoy all features",
    "you_have_subscribed": "You have subscribed the ",
    "plan": "plan",
    "of_text": "of",
    "days": "Days",
    "remaining_days_until_plan": " Days remaining until your plan requires update",
    "manage_your_screens": "Manage Your screens",
    "screens_you_save": "Screens you have",
    "add_more_screens": "Add more Screens",
    "addition_screen": "Addition Screen",
    "per_screen": "$ per screen",
    "per_box": "$ per box",
    "shipping_changes": "Shipping Charges",
    "upto4_boxes": "upto 4 boxes ",
    "charge_now": "You will get charge now",
    "updated_reoccuring": "Your updated reoccuring will be",
    "current_reoccuring": "Current reoccuring : ",
    "no_text": "No",
    "android_box": "Android Box",
    "old_password_req": "Old password is required",
    "new_password_req": "New password is required",
    "confirm_password_req": "Confirm password is required",
    "password_do_not_match": "Passwords do not match",
    "old_password": "Old Password",
    "new_password": "New Password",
    "confirm_password": "Confirm Password",
    "copy_working_hours": "Copy these working hours to all days ?",
    "yes_copy": "Yes, copy",
    "closed": "Closed",
    "opening_time": "Opening Time",
    "closing_time": "Closing Time",
    "description": "Description",
    "file_exist": "file already exist, please select other file",
    "bussiness_images": "Bussiness Images",
    "display_info_on_market_place": "Would you like to display below information on your Marketplace?",
    "profile_ready": "Your profile is ready!",
    "client_book_with_online": "Clients can now book with you online. Share the link below to get started.",
    "copy_link": "Copy Link",
    "see_your_profile": "See your profile",
    "ok_got_it": "Ok, got it",
    "preview_profile": "Preview your profile and see how it would look.",
    "opening_hours": "Opening Hours",
    "display_data": "Display data",
    "manage_profile": "Manage Profile",
    "phone_req": "Phone is required",
    "market_place_img_req": "Market place images is required",
    "add_new_market_place": "Add New MarketPlace",
    "edit_market_place": "Edit MarketPlace",
    "no_reviews_yet": "No Reviews Yet",
    "good": "Good",
    "average": "Average",
    "add_marketPlace": "Add MarketPlace",
    "all_title": "All",
    "included_title": "Included",
    "excluded_title": "Excluded",
    "clients_subscribed": "Clients subscribed in the last",
    "clients_groups": "Client Groups",
    "customer_groups": "Customer Groups",
    "audience_title": "Audience",
    "client_gt5": "who did not return in the last",
    "select_all": "Select All",
    "modify": "Modify",
    "campaign_title": "Campaign Title",
    "msg_type": "Message Type",
    "enter_discount": "Enter discount",
    "discount_type": "Discount Type",
    "coupon_text": "Coupon Text",
    "enter_coupon_text": "Enter Coupon Text",
    "coupon_button_text": "Coupon Button Text",
    "enter_coupon_button_text": "Enter Coupon Button Text",
    "fine_prin": "Fine Print",
    "enter_fine_prin": "Enter fine print text",
    "campaign_dec": "You can modify the campaign 30 minutes before its start time. Charges for SMS campaign will be proceed 30 minutes prior to the campaign's start time.",
    "blast_text_message_dec": "Your blast text message is ready. Select your payment method and send your messages.",
    "payment_completed": " The payment will be completed at ",
    "total_cost": "Total Cost",
    "close_title": "Close",
    "friday": "Friday",
    "saturday": "Saturday",
    "sunday": "Sunday",
    "thursday": "Thursday",
    "tuesday": "Tuesday",
    "wednesday": "Wednesday",
    "port_txt": "Port",
    "today_title": "Today",
    "yesterday_title": "Yesterday",
    "last_30_days_txt": "Last 30 Days",
    "this_month_txt": "This Month",
    "last_month_txt": "Last Month",
    "valid_date_title": "Select valid date",
    "valid_business_name_txt": "Enter valid business name",
    "req_bus_add_txt": "Business address is required",
    "req_domain_name_txt": "Domain name is required",
    "basic_info_txt": "Basic Info of Your Business",
    "loyalty_qr_def_txt": "Scan the QR code, join our loyalty punch card, and indulge in rewards.",
    "last_stamp_txt": "Last Stamp",
    "collected_on_txt": "Collected on",
    "stamp_details_txt": "Stamp Details",
    "add_stamp_txt": "Add Stamp",
    "choose_brand_color_txt": "Choose your Brand color & Language 🎨",
    "change_anytime_txt": "You can change this anytime",
    "like_to_get_txt": "Would you like to get",
    "your_next_visit_txt": "on your next visit?",
    "time_to_time_get_offers": "Would you like to receive great offers and promotion from time to time?",
    "respond_back_to_you": "Would you like someone to respond back to you?",
    "input_serve_better": "Your input will help our team serve you better!",
    "do_it_txt": "Let's do it",
    "take_15_s": "Takes 15 seconds",
    "rate_on_google_desc": "Would you be kind enough to rate us on google?",
    "may_be_next_time": "Maybe Next Time",
    "thank_you_txt": "Thank you",
    "look_next_txt": "We do look forward to your next visit!",
    "deleteUserTitle": "This user will be deleted permanetly.",
    "deleteMUserTitle": "This users will be deleted permanetly.",
    "change_pin": "Change Pin",
    "area_code": "What is your area code? ",
    "add_menu": "Add Menu",
    "menu_name": "Menu Name",
    "add_menu_placeholder": "Pizza, Burger, Baverages, etc",
    "enable_data_Collection": "Enable Data Collection",
    "add_new_menu": "Add New Menu",
    "rename_menu": "Rename Menu",
    "preview": "Preview",
    "generate_qr": "Generated QR",
    "edit_menu": "Edit Menu",
    "remove_menu": "Remove Menu",
    "menu_delete_msg": "This menu will be deleted permanetly",
    "menus_delete_msg": "This menus will be deleted permanetly",
    "view_menu_dialoage_msg": "Would you like to get a gift on your birthday?",
    "skip": "Skip",
    "cliam_your_gift": "Claim Your Gift",
    "collect_form": "Collect Form",
    "enter_first_name": "Enter First Name",
    "enter_last_name": "Enter Last Name",
    "enter_email": "Enter E-mail",
    "enter_dob": "Enter BirthDate",
    "enter_number": "Enter Number",
    "select_gender": "Select Gender",
    "congratulations_desc": "🎉 Congratulations! Your gift claim request has been submitted successfully. Our team will contact you shortly. 🎉",
    "male_heading": "Male",
    "male_text": "male",
    "female_heading": "Female",
    "female_text": "female",
    "others_heading": "Others",
    "other_text": "others",
    "BirthD": "Birthdate",
    "GenderT": "Gender",
    "EmailT": "Email",
    "dobT": "DOB",
    "capitalize_menu": "Menu",
    "select_menu": "Select menu",
    "manage_variant": "Manage Variant",
    "add_products": "Add Products",
    "add_category": "Add Category",
    "category_delete": "This category will be deleted permanetly",
    "variation_delete": "This varition will be deleted from the Product.",
    "product_delete": "This product will be deleted permanetly.",
    "categories_delete": "This categories will be deleted permanetly.",
    "products_delete": "This products will be deleted permanetly.",
    "category": "CATEGORY",
    "price": "PRICE",
    "food_product_placeholder": "Pizza, Burger, Baverages, etc",
    "active_title": "Active",
    "inActive_title": "InActive",
    "status_capital": "STATUS",
    "cat_status_require": "Category Status is required",
    "cat_name_require": "Category Name is required",
    "category_name": "Category Name",
    "status": "Status",
    "lgDay1": "Monday",
    "lgDay2": "Tuesday",
    "lgDay3": "Wednesday",
    "lgDay4": "Thursday",
    "lgDay5": "Friday",
    "lgDay6": "Saturday",
    "lgDay7": "Sunday",
    "is_closed_title": "Closed",
    "book_table_title": "Book A Table",
    "emailErrSub": "Email Subject is required",
    "email_subject": "Email Subject",
    "contactUsfrmTitleEmail": "Reply to email",
    "companyInfo": "Lorem Ipsum is lorem hrig, lorem ipsum is fraets.Lorem Ipsum is lorem hrig, lorem ipsum is fraets.Lorem Ipsum is lorem hrig, lorem ipsum is fraets.",
    "footerSTitle1": "About Menuonline",
    "footerSTitle1Lnk2": "Features",
    "footerSTitle1Lnk3": "Blog",
    "footerSTitle2": "Legal",
    "footerSTitle2Lnk1": "Terms and Conditions",
    "footerSTitle2Lnk2": "Privacy Policy",
    "footerSTitle3": "For Business",
    "footerSTitle3Lnk1": "For Partners",
    "footerSTitle3Lnk2": "Pricing",
    "footerSTitle3Lnk3": "Support for Partners",
    "footerCopyRithgTxt": "  Menuonline or its affiliates",
    "homeTitle": "Home",
    "contactTitle": "Contact Us",
    "aboutTitle": "About Us",
    "homeMenuTitle": "Menu",
    "bookTableTitle": "Book A Table",
    "vDateT": "Select valid date",
    "reqDateT": "Date is required",
    "dateGtErr": "Date must be later than or equal to today's date",
    "timeInvalid": "Time is invalid",
    "reqTimeT": "Time is required",
    "timeInvalidBfr": "Select time at least 5 minutes later from current time ",
    "PerReq": "Number of person is required",
    "validcNoT": "Enter valid contact number",
    "reqCrT": "Currency is required",
    "reqPrT": "Price is required",
    "reCateT": "Category is required",
    "reqVrT": "Variant is required",
    "reqVrVldT": "Enter Valid Variant ",
    "validLNameT": "Enter valid last name",
    "sDateInValid": "From date is invalid",
    "minmReq": "Minimum is required",
    "resLblDate": "Select Date",
    "resLblTime": "Select Time",
    "perT": "For How Many Person?",
    "resLblEmail": "Please provide your Email ?",
    "resLblNote": "Add a reservation note",
    "imageError1MB": "Please insert image that is less than 1mb",
    "imageError": "Please insert image that is less than 2.5mb",
    "imageError3Mb": "Please insert image that is less than 3mb",
    "imageInVldError": "File is invalid.",
    "addProMT": "Product Menu",
    "proT": "Product Name",
    "reqProT": "Product Name is required",
    "proPT": "Product Price",
    "reservationSuccTitle": "Great, Your Reservation Is Done",
    "book": "Book",
    "custzName": "Customization Name",
    "always": "Always",
    "proImgReq": "Product image is required",
    "selCustmT": "Please Select Customization or create new",
    "visStR": "Visibility status is required",
    "avlblScR": "Please select availability schedule",
    "addonPR": "Please select addon products",
    "upsellPR": "Please select upsell products",
    "markItemR": "Please select mark item products",
    "caloriesR": "Calories is required",
    "allergensR": "Please select allergens products",
    "prepInstR": "Preparation instructions is required",
    "staffNR": "Staff notes is required",
    "discountR": "Discount is required",
    "validDiscE": "Enter valid discount",
    "disday": "By the day",
    "plSelDayT": "Please select day",
    "sTimeReq": "Start time is required",
    "sTimeInValid": "Start time is invalid",
    "eTimeReq": "End time is required",
    "eTimeInValid": "End time is invalid",
    "sDateReq": "From date is required",
    "eDateReq": "To date is required",
    "eDateInValid": "To date is invalid",
    "disdate": "From the Date",
    "disdate1": "To Date",
    "disdate2": "From  Date",
    "currT": "Currency",
    "iconR": "Please select mark item icon",
    "minT": "Minimum",
    "maxT": "Maximum",
    "itemNT": "Item Name",
    "itemPT": "Item Price",
    "descProT": "Describe your product",
    "cateT": "Category",
    "selProCateT": "Select your product category",
    "reqMT": "Menu Name is required",
    "ViewMenu": "View menu",
    "CopyMenu": "Copy menu",
    "EditMenu": "Edit menu",
    "RemoveMenu": "Remove menu",
    "welcomeMnuTitle": "Welcome to",
    "reviewT": "Reviews",
    "userErrorMsg": "Sorry, An Error Occured !!!",
    "reqCateT": "Category Name is required",
    "mngCustT": "Manage Customization",
    "custNReq": "Customization name is required",
    "incReq": "Included is required",
    "minmValid": "Enter valid minimum value",
    "maxmReq": "Maximum is required",
    "cust": "Customization",
    "crCust": "Create New Customization",
    "custList": "Customization List",
    "delCustmzT": "This customization will be deleted permanetly.",
    "mkNameR": "Mark item name is required",
    "mkDelT": "This mark item will be deleted permanetly.",
    "hideText": "Hide",
    "showText": "Show",
    "device_mac_txt": "Device Mac",
    "delete_hotspot_txt": "This hotspot will be deleted permanetly.",
    "delete_hotspots_txt": "This hotspots will be deleted permanetly.",
    "emp_wifi_name": "Employee Wifi Name",
    "max_limit_txt": "Enter '1024' for speed to designate it as UNLIMITED. This value removes any caps, allowing maximum throughput",
    "device_port_txt": "Device Port",
    "firmware_txt": "Firmware",
    "hotspot_info_txt": "Hotspot Information",
    "editDBtn": "Edit Details",
    "birth_date": "Birthdate",
    "di_theme_clr": "Theme Color exactly 7 digit",
    "color_Invalid_txt": "Select valid color code",
    "Req_theme_clr": "Theme Color is required",
    "select_valid_color_txt": "Please select valid theme color",
    "req_redir_link": "Redirect Link is required",
    "val_redir_link": "Enter valid redirect link",
    "req_business_name_txt": "Business name is required",
    "splash_preview": "Splash Preview",
    "create_new_splash": "Create New Splash",
    "splash_page": "Splash Page",
    "splash_page_builder": "Splash Page Builder",
    "redirect_link": "Redirect Link",
    "theme_color": "Theme Color",
    "enable_social_login": "Enable social media options for users’ login",
    "google": "Google",
    "facebook": "Facebook",
    "is_mandatory": "Is it mandatory?",
    "field": "Field",
    "name": "Name",
    "first_name": "First Name",
    "last_name": "Last Name",
    "birthdate": "Birthdate",
    "gender": "Gender",
    "email": "Email",
    "dob": "DOB",
    "zip_code": "Zip Code",
    "required_redirect_link": "Redirect Link is required",
    "valid_redirect_link": "Enter valid redirect link",
    "required_theme_color": "Theme Color is required",
    "theme_color_length": "Theme Color exactly 7 digit",
    "required_name": "Name is required",
    "delete_splash_message": "You want to delete this splash?",
    "delete_multiple_splashes_message": "You want to delete these splashes?",
    "user_login_required": "User Login is required",
    "set_theme_color": "Set Theme Color",
    "splash_colllect_additional_info_txt": "Collect Additional Information",
    "verify_mobile_otp_message": "Would you like to send OTP to verify mobile number?",
    "add_company": "Add Company",
    "edit_company": "Edit Company",
    "ds_device": "Digital Signage Device",
    "ds_device_activation_fee": "Digital Signage Device Activation Fee",
    "wifi_hardware": "Wifi Hardware",
    "menu_design": "Menu Design",
    "ds_hardware": "Digital Signage Hardware",
    "company_name_req": "Company name is required",
    "known_name_req": "Known name is required",
    "no_location_req": "No. of location is required",
    "minimum_location": "Please enter minimum 1 location",
    "terms_req": "Terms is required",
    "notes_req": "Notes is required",
    "sms_charge_req": "SMS Charge is required",
    "menuonline_quantity_req": "Menu Online Quantity is required",
    "menuonline_cost_req": "Menu Online Cost is required",
    "ds_quantity_req": "Digital Signage Quantity is required",
    "ds_monthly_cost_req": "Digital Signage Monthly Cost is required",
    "reputation_quantity_req": "Reputation Quantity is required",
    "reputation_cost_req": "Reputation Cost is required",
    "marketing_quantity_req": "Marketing Quantity is required",
    "marketing_cost_req": "Marketing Cost is required",
    "tax_percentage_req": "Tax Percentage is required",
    "router_hardware_quantity_req": "Router Hardware Quantity is required",
    "wifi_hardware_cost_req": "WIFI Hardware Cost is required",
    "activation_cost_req": "Activation cost is required",
    "wifi_quantity_req": "WIFI Quantity is required",
    "wifi_router_req": "Wifi Router is required",
    "menu_design_quantity_req": "Menu Design Quantity is required",
    "menu_design_cost_req": "Menu Design Cost is required",
    "quantity_req": "Quantity is required",
    "cost_req": "Cost is required",
    "sheduledown_quantity_req": "Shedule Down Quantity is required",
    "sheduledown_cost_req": "Shedule Down Cost is required",
    "loyalty_program_quantity_req": "Loyality Program Quantity is required",
    "loyalty_program_cost_req": "Loyality Program Cost is required",
    "loyalty_program_sms_quantity_req": "Loyality Program SMS Quantity is required",
    "loyalty_program_sms_cost_req": "Loyality Program SMS Cost is required",
    "comapny_created": "Company Created Successfully",
    "comapny_updated": "Company Updated Successfully",
    "billing_info": "BILLING INFORMATION",
    "business_name_acc_holder": "Bussiness Name (Account Holder)",
    "client_first_name": "Client First Name",
    "client_last_name": "Client Last Name",
    "dba_known_as": "DBA Known As",
    "business_address": "Business Address",
    "no_of_locations": "No. of Locations",
    "package_details": "PACKAGE DETAILS",
    "terms": "Terms",
    "one_month": "1 month",
    "per_sms_charge": "Per SMS Charge",
    "plan_test": "PLAN : TEST",
    "desc_capital": "DESCRIPTION",
    "qty_capital": "QTY",
    "cost_capital": "COST",
    "total_price_capital": "TOTAL PRICE",
    "quantity": "Quantity",
    "can_not_access": "You cannot select more than",
    "licenses": "licenses",
    "monthly_cost": "Monthly Cost",
    "plan_cost": "Plan Cost",
    "tax": "Tax",
    "tax_percentage": "Tax Percentage",
    "monthly_cost_after_tax": "Monthly Cost After Tax",
    "activation_one_time_charge": "Activation & One Time Charge",
    "sub_total": "SUB TOTAL",
    "action": "Action",
    "cost": "Cost",
    "shipping_charge": "Shipping Charge",
    "other": "Other",
    "additional_cost": "Additional cost (Other charges)",
    "other_tax": "Other Tax",
    "total": "Total",
    "license_statistics": "License Statistics",
    "total_licenses": "Total Licenses",
    "available_licenses": "Available Licenses",
    "stock": "Stock",
    "has_permission": "Has Permission",
    "avg_stock_price": "AVG Stock Price",
    "average_price": "Average Price",
    "allocated": "Allocated",
    "reward_regulars": "Reward regulars",
    "not_add_coupon_url": "Can't add coupon URL.Message's maximum length is 160",
    "like_attach_coupon": "Would you like to attach a coupon?",
    "advance_scheduling": "Advance Scheduling",
    "choose_day": "Choose day(s) to send message",
    "select_essage_window": "Select Message Window",
    "subscription_outside_delivery_window": "If a user subscribes outside of the delivery window, they’ll receive your autoresponder at the next available time.",
    "remaining": "Remaining",
    "processing_request": "An error occurred while processing your request.",
    "list_companies": "List of Companies",
    "are_you_sure": "Are you sure..?",
    "signin_agreement": "By confirming this, The company will create without signing an agreement.",
    "status_updated": "Status update successfully",
    "status_failed": "Failed to update status",
    "new_editor_status_updated": "DS New Editor status has been updated successfully.",
    "user_name": "User Name",
    "known_as": "known as",
    "agreement_signed": "Agreement Signed",
    "deactivate": "Deactivate",
    "activate": "Activate",
    "login_to_user_acc": "Login to user account",
    "disable": "Disable",
    "enable": "Enable",
    "ds_new_editor": "DS New Editor",
    "sign_agreement": "Sign Agreement",
    "agreement_sign": "Agreement W\/O Signature",
    "view_agreement": "View Agreement",
    "download_agreement": "Download Agreement",
    "add_deleted_user": "Add Deleted User",
    "deleted_user": "Delete User",
    "favourite": "Favourite",
    "refresh": "Refresh",
    "delete_chat": "Delete Chat",
    "responsive_drawer": "Responsive drawer",
    "delete_contact": "Delete Contact",
    "clear_chat_history": "Clear Chat history",
    "clear_chat_history_dec": "Are you sure you want to delete this chat?",
    "clear_contact_dec": "Are you sure you want to delete this contact?",
    "select_contact": "Select Contact",
    "new_conversation": "Start new conversation",
    "type_msg": "Type your messaage here",
    "select_delete_client": "Please select client to delete",
    "select_delete_client_chat": "Please select client to delete chat",
    "select_delete_client_chat_err": "You don't have chat with this client",
    "acquire_customers_business": "Acquire new customers from other businesses.",
    "customers_from_events": "Get customers from events & bring it to you!",
    "customers_from_ads": "Get customers from newspaper Ads.",
    "smart_pamphlets": "Create smart pamphlets to collect customer data.",
    "smart_qr_group": "A Smart QR for joining a group for customers.",
    "opt_in_number": "Enable customers to Opt in number.",
    "qr_menu": "Create QR code for displaying your menu.",
    "collect_birthdays": "Collect customer’s birthday & anniversary.",
    "join_loyalty_program": "Get new customers to join my loyalty program.",
    "create_feedback_forms": "Create forms to collect client’s feedback.",
    "exclusive_discounts_promotions": "Offer exclusive discounts and promotions for cross-promotions.",
    "group_smart_qr_opt_in": "After creating a group, you can generate and select a smart QR code, enabling customers to opt in instantly into a group",
    "group_smart_qr_opt_in_dec": "After creating a group, Image widget enabling customers to opt in using text and receive promotions, for example, text \"pizza\" to 555-555-5555",
    "in_store_discount_next_visit": "Get an in-store discount during your next visit to our location",
    "delivery_redirection": "Create Delivery Redirection",
    "additional_info": "Additional Information",
    "add_url": "Add URL",
    "custom_fields": "Custom fields",
    "meta_tag": "Meta tag",
    "max_chars": "Max chars",
    "add": "Add",
    "update_campaign": "Update Campaign",
    "last_week_required": "last week is required",
    "large_week_value": "Week value is too large enter valid week",
    "less_than_last_week": "Not return in last week must be less than last week.",
    "last_week_req": "Not return in last week is required",
    "birthday_special": "Birthday special",
    "birthday_message": "Automated message sent out on their birthdays with personalized greetings and tempting offers",
    "birthday_sms": "Create personalized birthday greetings for SMS\/MMS",
    "target_audience": "Choose target audience",
    "client_by_birthday": "Client by Birthday",
    "clients_once_year": "This campaign automatically sends to clients once a year ",
    "auto_responder_default_msg_1": "You are our one of the best customers to whom we love to give discount! May you be always wealthy and healthy. Happy birthday! %%firstname%%",
    "campaign_send_to_client_birthday": "This auto campaign sends to clients with birthdays, you can customize the targeting below.",
    "get_more_engagement": "Get more engagement by sending b’day SMS 🎂",
    "welcome_new_clients": "Welcome new clients",
    "make_lasting_impression": "Make a lasting impression by warmly greeting and welcoming first-time diners",
    "personalized_greetings": "Create personalized greetings for the new clients",
    "greetings_to_new_customers": "This campaign will send out welcome greetings to new customers on following day of their first interaction with your business",
    "audience_predefined": "This autoresponder's audience is predefined, we've got this!",
    "send_clients_subscribed": "This autoresponder is set to send clients subscribed in the last ",
    "first_interaction": "  after their first interaction.",
    "default_msg_2": "We hope you enjoyed your first visit, we'd love to see you again soon so we're offering a discount just for you! Click the link to book your next appointment with us and redeem your special online offer now.",
    "new_clients_update": "Welcome new clients update campaign text",
    "new_clients_warm_greeting": "Welcome New Clients with a Warm Greeting 💌",
    "win_back_clients": "Win back clients",
    "re_engage_past_customers": "Re-engage past customers with irresistible offers to encourage them to return",
    "target_disengaged_clients": "Target disengaged clients to return with a special offer, a proven tactic to lure clients back.",
    "campaign_sends_to_clients": "This campaign sends to clients who did not return after certain period of time.",
    "customize_targeting": "Customize targeting below to send out SMS\/MMS to lapsed clients.",
    "clients_with_at_least": "Clients with at least ",
    "connection_last": " connection in the last ",
    "return_in_last_week": " week, but did not return in last ",
    "arr_menu_44": " week",
    "df_msg_4": "We want you back SO BAD that we’re going to give you 10% off just for returning! Want to give us a try again today?",
    "update_campaign_text": "Win back clients update campaign text",
    "re_invite_customers": "Re-invite customers that haven't been back for a while",
    "loyal_patrons": "Show appreciation to loyal patrons with special discounts and loyalty rewards",
    "surprize_top_spenders": "Surprize top spenders with personalized and special offers.",
    "campaign_automatically_send": "This campaign automatically Send out rewards to boost the foot fall in your business.",
    "sms_loyal_clients": "This camapaign sends SMS\/MMS to loyal clients, you can change the targeting below.",
    "or_more_connection": " or more connection, within the last ",
    "week_period": " week period.",
    "default_msg_5": "You’ve been a customer for long time now! Wow, time flies! We appreciate you and want to thank you for being with us.",
    "reward_regulars_update": "Reward regulars update campaign text",
    "special_discounts": "Appreciate and give a special discounts💎",
    "leave_positive_online": "Encourage customers to leave positive online reviews to boost the restaurant's reputation",
    "clients_service_online": "The campaign encourages your clients to rate your service online.",
    "increase_your_company": "This autoresponder is sent to increase your company's online rating.",
    "send_message_after": "Send message after ",
    "min_connection": " min of the connection",
    "default_msg_6": "We hope you enjoyed your first visit, we'd love to see you again soon! [URL] Click the link to rate us on Google.",
    "ratings_update": "Get more Ratings update campaign text",
    "friendly_nudge": "Get More Reviews with a Friendly Nudge ⭐",
    "thanks_for_visiting": "Thank you for visiting",
    "express_gratitude": "Express gratitude to customers  for choosing your business with heartfelt thank-you messages",
    "thank_you_message": "Create thank you message for your clients.",
    "clients_for_visiting": "Send a warm thank you to your clients for visiting your place.",
    "campaign_audience": "This auto campaign audience is predefined, we've got this!",
    "campaign_automatically_sends": "This auto campaign automatically sends after ",
    "minutes_customer_visit": " minutes of customer visit",
    "default_msg_7": "Thank you for being our valued customer. We are so grateful for the pleasure of serving you and hope we met your expectations.",
    "visiting_update_campaign": "Thank you for visiting update campaign text",
    "guests_thank_you": "Thank Your Guests for Their Visit 😊",
    "download_title": "Download",
    "qr_gen_title": "QR Generated",
    "qr_download_s_title": "QR Code downloaded successfully",
    "sel_dark_brand_clr_msg": "Please select valid barnd color",
    "manage_customer_stamp_rewards": "Manage Customer Stamp Rewards 🎁",
    "sel_loc_menu_title": "Select locations for your menu",
    "ans_req": "Answer is required",
    "valid_reputation_name": "Enter valid reputation name",
    "reviews_name_req": "Get More Reviews name is required",
    "birthdate_required": "Birthdate is required",
    "gender_required": "Gender is required",
    "valid_birthdate_required": "Enter valid birthdate",
    "custom_delivery_redirection": "Create a custom delivery redirection form  by groups",
    "customer_fields_incentive_settings": "Customer fields and incentive settings",
    "delivery_redirection_text": "Delivery Redirection",
    "delivery_redirection_success": "Congrats! Your Delivery Redirection Widget created! 🎉",
    "swipe_to_preview_redirection": "Swipe to see how your Delivery Redirection form will look like",
    "enter_item_name": "Enter item name",
    "home_page_text": "Home page text",
    "settings": "Settings",
    "anniversary_required": "Anniversary date is required",
    "valid_anniversary": "Enter valid anniversary date",
    "form_submited": "form submited successfully",
    "notifications": "Notifications",
    "discount_message": "Claim your 20% off on Pizza now!",
    "is_required": " is required",
    "section_title_required": "Section title is required",
    "section_dec_required": "Section description is required",
    "client_details_required": "Client details fields is required",
    "compliance": "Compliance",
    "SMS_campaign1": "SMS Campaign 1",
    "mobile_number_mandatory": "Mobile number is mandatory",
    "new_answer": "New answer",
    "new_question": "New question",
    "add_new_question": "Add new answer",
    "select": "Select",
    "group_customers_question": "Where do you want to group your customers ?",
    "contacts_added_to_group": "All contacts that submit the form will be added to selected group",
    "edit_client_details_section": "Edit Client Details Section",
    "client_details_fields": "Client Details Fields",
    "enter_client_details_section_title_desc": "Enter the client details section title and description",
    "choose_fields_displayed_client_side": "Choose the fields to be  displayed on client side",
    "section_title": "Section Title",
    "add_title": "Add Title",
    "section_description": "Section Description",
    "add_description": "Add Description",
    "enter_program_name_location": "Enter Program name & location 📍",
    "brand_theme_clr_txt": "Brand Theme Color",
    "set_loyalty_punch_card_theme": "Let’s set a theme for your loyalty punch card 🎨",
    "upload_logo_txt": "Upload Your Logo",
    "recommended_image_specs": "We recommend using an image that is 512 x 512px for the best display. JPG, SVG or PNG. Max size of 10MB.",
    "valid_rep_name_txt": "Enter valid reputation name",
    "rep_name_req_txt": "Get More Reviews name is required",
    "que_req": "Question is required",
    "day_of_birthday": "on the day of birthday",
    "day_before_birthday": "3 days before  birthday",
    "week_before_birthday": "one week before birthday",
    "two_week_before_birthday": "two weeks before birthday",
    "de_active": "De-Active",
    "campaign_details": "Campaign details",
    "link_clicked": "Link clicked",
    "history": "History",
    "auto_responder_summary": "Auto Responder Summary",
    "vsop_1": "15 min",
    "vsop_2": "30 min",
    "vsop_3": "45 min",
    "vsop_4": "60 min",
    "vsop_5": "90 min",
    "vsop_6": "120 min",
    "in_the_last": " in the last ",
    "return_in_last": " but did not return in last ",
    "of_the_connection": "of the connection",
    "your_campaign": "Your campaign",
    "you_have_successfully": "You have successfully",
    "published_successfully": "published successfully",
    "updated_smart_campaign": "updated the smart campaign",
    "almost_done_text": "Almost done!",
    "update_campaign_desc": "Your autoresponder is complete, once enabled, it will send messages to clients on an ongoing basis. This campaign can be easily modified or paused at any time.",
    "update_and_publish": "Update and Publish",
    "reset_campaign_title": "It will premanently delete your camapign's custom text,image, discount offers & targeted audience!",
    "space_wifi": " WiFi",
    "custom_forms": "Custom Forms",
    "web_e_signup": "Web E-signup",
    "import": "Import",
    "permissions_req": "Permissions is required",
    "redeemed": "Redeemed",
    "coupon_already_redeemed": "Coupon already redeemed on",
    "autoresponder_campaigns": "Autoresponder Campaigns",
    "autoresponder_campaign_desc": "Enable the autoresponder and launch the personalized campaign",
    "mon": "MON",
    "tue": "TUE",
    "wed": "WED",
    "thu": "THU",
    "fri": "FRI",
    "sat": "SAT",
    "sun": "SUN",
    "duplicate": "Duplicate",
    "visibility": "Visibility",
    "availability": "Availability",
    "out_stok": "Out of stok",
    "edit_product": "Edit Product",
    "create_product": "Create a Product",
    "basic_info": "Basic Information",
    "basic_sub_info": "Lorem Ipsum is simply dummy text of the printing...",
    "enter_product_price": "Enter product price",
    "upload_image": "Upload Image",
    "allowed_file_formats": "Only JPG and PNG Files with Max-File size 3MB is allowed.",
    "pre_select": "Pre-Select",
    "promotions": "Promotions",
    "discount_availability": "Discount Availability",
    "start_time": "Start Time",
    "end_time": "End Time",
    "select_day": "Select Day",
    "menu_required": "Menu is required",
    "generated": "Generated",
    "link": "Link",
    "variations": "Variations",
    "select_variant_to_add": "Select Variant you would like to add",
    "price_title": "Price",
    "choose_image": "Choose Image",
    "select_visiblity": "Select Visiblity",
    "availability_schedule": "Availability Schedule",
    "add_on_upsell": "Add on \/ Upsell",
    "add_on_select_product": "Add on Select Product",
    "upsell_product": "Upsell Select Product",
    "variant_deletion_warning": "This Variant will be deleted permanetly.",
    "search_variations": "Search Variations",
    "add_variation": "Add Variation",
    "variation_text": "Variant",
    "select_customization": "Select customization",
    "add_new": "Add New",
    "delete_customization_warning": "Do you want to delete this customization from product ?",
    "nutritional_allergen_info": "Nutritional and Allergen Information",
    "mark_item": "Mark Item",
    "calories_text": "Calories",
    "allergens": "Allergens",
    "select_allergens": "Select allergens",
    "special_instructions": "Special Instructions",
    "preparation_instructions": "Preparation Instructions",
    "staff_notes": "Staff Notes",
    "checkbox1": "A checkbox to allow or disallow special instructions from customers for this menu item.",
    "menu_c": "MENU",
    "past_design": "Past Design",
    "file_name": "File Name",
    "variation_name": "Variation Name",
    "grid_items_title1": "Table Tent",
    "grid_items_title2": "Digital Menu",
    "grid_items_title3": "Takeout Menu",
    "grid_items_title4": "Halfpage Menu",
    "grid_items_title5": "Digital Menu 5",
    "grid_items_title6": "Digital Menu 6",
    "grid_items_title7": "Digital Menu 7",
    "grid_items_title8": "Digital Menu 8",
    "grid_items_title9": "Digital Menu 9",
    "enter_valid_form_name": "Enter valid form name",
    "form_name_required": "Form name is required",
    "enter_valid_section": "Enter valid section title",
    "fraction_half": "1\/2",
    "fraction_two_by_two": "2\/2",
    "form_name": "Form Name",
    "contact_list": "Contact List",
    "label_notify_email": "Would you like to be notified through email?",
    "add_client_details_section": "Add Client Details Section",
    "demo_purpose_only": "It is for demo purpose only",
    "form_updated_success": "form updated successfully",
    "form_creat_success": "form created successfully",
    "add_custom_section": "Add a Custom Section",
    "edit_custom_section": "Edit a Custom Section",
    "forms_list": "List of Forms",
    "filled_custom_form_details": "Filled Custom Form Details",
    "custom_form_deletion_warning": "This custom form will be deleted permanetly",


    "menu_link": "Menu Link",
    "WebSignUpT": "E-signup",
    "lifeTConT": "Lifetime Connections",
    "repCustT": "Repeat Customers",
    "liftTSubT": "Lifetime Subscribers",
    "overallT": "OVERALL",
    "loginCustBDT": "Logged-in Customers by Day",
    "deviceT": "Device Demographics",
    "ageTitle": "Age Demographics",
    "ageT": "Age Group",
    "convert_social_media_followers_into_customers": "Convert social media followers into customers",
    "signWidDesc": "Build a widget for signing up, creating landing pages, and generating coupons from  your website or social media",
    "qrWidT": "Smart QR",
    "imgWidT": "Opt in number",
    "mobile": "Mobile",
    "YESTitle": "YES",
    "NOTitle": "NO",
    "addDelRed": "Add Delivery Redirection",
    "dlredNameT": "Name your delivery redirection",
    "conf_text_req": "Confirmation text is required",
    "cFDesc": "Create tailored forms to collect specific feedback and information from customers efficiently",
    "pre_review_questions_enabled": "Before asking the customers for review, Let’s ask them 1 to 4 questions.",
    "feel_free_edit": "Feel free to edit as needed.",
    "option": "Option",
    "confFL": "This confirmation will be shown after your contact submits the Web Sign-up Form",
    "confT": "Confirmation text",
    "joinTitle": "Join",
    "mobValidL": "Please check valid mobile no",
    "mobRequiredL": "Mobile no is required",

    //new added by dhruvam
    "Business_location_label": "Your Business Location",
    "qr_code_selection_txt": "Qr code Selection",
    "choose_theme_txt": "Choose Theme",
    "edit_qr_details_txt": "Edit QR Details",
    "save_qr_code_txt": "Save QR Code",
    "text_color_label": "Text Color",
    "background_color_label": "Background Color",
    "print_txt": "Print",
    "customer_will_see_txt": "This is what your customer will see.Choose your brand color in the next step.",
    "choose_your_brand_color_&_langauge_title": "Choose your Brand color & Langauge",
    "custom_redirect_link_label": "Custom Redirect Link",
    "redirect_user_to_menu": "Redirect User To Menu",
    "language": "Langauge",
    "select_language_placeholder": "Select Language",
    "capitalize_location_name_txt": "Location Name",
    "created_txt": "Created",
    "swipe_reputation_management_txt": "Swipe to see what your reputation management will look like",
    "sent_txt": "Sent",
    "number_label": "Number",
    "date_label": "Date",
    "gender_demographics_title": "Gender Demographics",


    "step1_preview_desc": "Name your E-signup QR Form and add into your group list",
    "main_head": "Acquire New Customers",
    "main_desc": "Get customers by placing a QR code that, when scanned, redirects users to a personalized sign-up form.",
    "step1_description": "Acquire new customers from other businesses",
    "form_name_field_placeholder": "Restro E-signup",
    "last_step_title": "Congrats! Your E-signup Widget created! 🎉",
    "last_step_desc": "Swipe to see how your E-signup form will look like",
    "events_step1_preview_desc": "Name your Event QR Form and add into your group list",
    "events_main_head": "Get Customers From Events",
    "events_main_desc": "Get customers by placing a QR code on event, when scanned, redirects users to a personalized sign-up form.",
    "events_step1_desc": "Capture customers from events & bring them in-store",
    "events_name": "Event Name",
    "events_form_name_field_placeholder": "Restro Fun and Food Event",
    "events_last_step_title": "Congrats! Your Event Form created! 🎉",
    "events_last_step_desc": "Swipe to see how your event form will look like",
    "birthday_anniversary_step1_preview_desc": "Name your QR Form to collect customer’s bday or anniversary and it add into your group list",
    "birthday_anniversary_main_head": "Collect B’day or Anniversary",
    "birthday_anniversary_main_desc": "Get customer’s birthday or anniversary, when scanned, redirects users to a personalized sign-up form.",
    "birthday_anniversary_step1_desc": "Collect customer's birthday and anniversary",
    "restro_text": "Restro",
    "birthday_anniversary_last_step_title": "Congrats! Get B’day & Anniversary from customers form created! 🎉",
    "newspaper_ads_step1_preview_desc": "Name your QR Form to get customers from newspaper ads and it add into your group list",
    "newspaper_ads_main_head": "Get customers from Ads",
    "newspaper_ads_main_desc": "Get customers from newspaper Ads, when they scanned, it redirects users to a personalized sign-up form.",
    "newspaper_ads_step1_desc": "Get customers from newspaper Ad or outdoor Ad",
    "newspaper_ads_last_step_title": "Congrats! Get customers from newspaper ads form created! 🎉",
    "smart_pamphlets_step1_preview_desc": "Name your QR Form to get customers data from pamphlets and it add into your group list",
    "smart_pamphlets_main_head": "Get clients from pamphlets",
    "smart_pamphlets_main_desc": "Get customer’s data from pamphlets, when they scanned, it redirects users to a personalized sign-up form.",
    "smart_pamphlets_last_step_title": "Congrats! Get customer’s data from pamphlets form created! 🎉",
    "social_media_step1_preview_desc": "Name your QR Form to get customers from social media and into customers and it add into your group list",
    "social_media_main_desc": "Get customers from social media, when they scanned from posts, it redirects users to a personalized sign-up form.",
    "social_media_form_name_field_placeholder": "Restro Festival Offer - Facebook Post",
    "social_media_last_step_title": "Congrats! Get customers social media form created! 🎉",
    "claim_offer_text": "Fill your details and claim the offer now",
    "form_title_description": "Add Form title & description for your customers to enter the details",
    "no_subscribed_clients": "Selected customer group does not have any subscribed clients",
    "welcome_message_offer": "Send a welcome message to your customers, after they signup & give them a offer coupon",
    "system_grp": "System Groups",
    "import_clients": "Import Clients",
    "delivery_redirection_name": "Delivery Redirection Name",
    "created_at": "Created At",
    "provide_stamp_user": "Provide Stamp to User",
    "scan_qr_code": "Scan QR Code",
    "add_point": "Add Point",
    "total_points": "Total Points",
    "user_profile": "User Profile",
    "stamp_history": "Stamp History",
    "reward_txt": "Reward",
    "variant_placeholder": "Small, Medium, Large, etc",
    "sent_coupon": "Sent Coupon",
    "order_status": "Order Status",
    "payment_status": "Payment Status",
    "order_currency": "Order Currency",
    "card_data": "Card Data",
    "reward_expiring_on": "Reward Expiring On",
    "reward_status": "Reward Status",
    "reward_get_date": "Reward Get Date",
    "reward_name": "Reward Name",
    "add_point_type": "Add Point Type",
    "restaurant_location": "Restaurant Location",
    "collect_points": "Collect Points",
    "rewards_expire": "Rewards Expire",
    "rewards_redeem": "Rewards Redeem",
    "total_rewards": "Total Rewards",
    "created_by": "Created By",
    "anniversary": "Anniversary",
    "remove_user_group_txt": "Remove User From Group",
    "delete_permanently_grp_msg": "This client will be deleted permanetly from group.",
    "delete_multiple_permanently_grp_msg": "This clients will be deleted permanetly from group.",
    "delete_multiple_client": "This clients will be deleted permanetly.",
    "messages": "Messages",
    "management": "Management",
    "client": "Client",
    "country": "Country",
    "state": "State",
    "city": "City",
    "zip_code": "Zip code",
    "1_sparkly_point_txt": "🎉 You've earned 1 sparkly point! 🌟",
    "select_country_txt": "Select country",
    "select_state": "Select state",
    "ls_thanks_for_join": "Thank you for joining our loyalty punch card.🎁🎈",
    "month": "Month",
    "welcome_message": "Send a welcome message to your customers, after they signup",
    "all_rewards_txt": "All Rewards",
    "active_rewards": "Active Rewards",
    "expired_and_redeemed": "Expired & Redeemed",
    "redirect_3rd_party_delivery": "Re-direct 3rd party delivery to your store or to your online ordering platform",
    "search_or_start_new": "Search or Start a new",
    "contacts": "Contacts",
    "chats": "Chats",
    "add_by_restaurant": "Add by Restaurant",
    "using_scan_code": "Using Scan Code",
    "step": "Step",
    "list_widget_text": "List of Widgets",
    "swipe_free_wifi_txt": "Swipe for free wifi",
    "create_widget_txt": "Create Widget",
    "redeem_award_msg": "Are you sure ? Do you want to redeem this reward",
    "on_collect_msg": "On Collect",
    "pause": "Pause",
    "resume": "Resume",
    "location_added": "location added",
    "sub_user_admin_permissions_txt": "Allow sub user to access admin permissions in Digital Signage",
    "unlist": "Unlist",
    "cannot_delete_default_terms": "Can't Delete Default Terms & Condition",
    "something_went_wrong": "Something went wrong!",
    "card_download_success": "Card Downloaded Successfully",
    "permission_denied": "Permission denied!",
    "invalid_qr_code": "Please scan valid QR code",
    "select_customer": "Please select customer",
    "online": "ONLINE",
    "offline": "OFFLINE",
    "no_data": "Oops, No data found!",
    "forever_free": "Forever Free",
};

export default en;