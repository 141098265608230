const it = {
    "rated_google_reviews": "Valutato 5.0 su Google Reviews",
    "get_your_digital_menu": "Ottieni il tuo menu digitale:",
    "instant_popup_menu": "Menu pop-up istantaneo senza scansione QR",
    "free_forever": "Libero per sempre",
    "the_future_is_here": "Il futuro è qui!",
    "upgrade_your_restaurant": "Aggiorna il tuo ristorante con uno straordinario menu digitale a costo zero. Attira più clienti a partire da oggi.",
    "get_my_free_menu_now": "Ottieni subito il mio menù gratuito",
    "no_credit_card_required": "*Non è richiesta alcuna carta di credito",
    "join_largest_marketplace": "Unisciti al più grande mercato di ristoranti!",
    "what_will_you_get": "Cosa otterrai?",
    "free": "GRATUITO",
    "free_digital_menu": "Menu digitale gratuito 2D o 3Digitale",
    "free_digital_menu_desc": "Di' addio ai menu cartacei e alle costose ristampe",
    "quick_and_easy_signup": "Registrazione rapida e semplice",
    "communicate_with_customers_title": "Comunica con i clienti GRATIS!",
    "features1": [
        "Risparmia sui costi di stampa e goditi aggiornamenti immediati del menu ogni volta che ne hai bisogno.",
        "Accedi al tuo menu digitale su qualsiasi dispositivo",
        "Ecologico e senza problemi"
    ],
    "features2": [
        "Consenti ai clienti di esplorare virtualmente i tuoi piatti con straordinarie immagini 3D.",
        "Aumenta il coinvolgimento offrendo ai commensali un'anteprima realistica del loro pasto.",
        "Distinguiti con un menù che sia allettante tanto quanto il sapore dei tuoi piatti."
    ],
    "features3": [
        "Accogli subito i tuoi ospiti con il tuo menù non appena entrano nel ristorante.",
        "Invia automaticamente aggiornamenti su offerte speciali e promozioni ai loro telefoni.",
        "Migliora l'esperienza culinaria con un accesso rapido e senza contatto al tuo menù."
    ],
    "feature4": [
        "Metti in evidenza le offerte stagionali, le specialità del giorno e le promozioni esclusive.",
        "Attira l'attenzione dei clienti sui tuoi articoli più popolari.",
        "Crea urgenza ed entusiasmo con immagini e descrizioni accattivanti."
    ],
    "feature5": [
        "Entra a far parte di una community online con migliaia di visitatori giornalieri.",
        "Aumenta la visibilità e raggiungi nuovi clienti che cercano attivamente soluzioni per la ristorazione.",
        "Assicurati subito un posto in prima fila per massimizzare la tua visibilità e attrarre più clienti."
    ],
    "section3_heading": "Perché passare al digitale? Perché è gratuito e facile!",
    "section3_description": "Sblocca potenti funzionalità pensate per aumentare l'attrattiva del tuo ristorante.",
    "instant_menu": "Menù istantaneo",
    "instant_menu_notification": "Pop-up e notifica",
    "instant_menu_description": "Accogli gli ospiti con accesso immediato al menu non appena entrano. Invia automaticamente il menu più recente e le offerte speciali direttamente ai loro telefoni cellulari",
    "digital_menu": "Menù digitali",
    "at_zero_cost": "a costo zero",
    "digital_menu_description1": "Di' addio ai menu cartacei e alle costose ristampe. Passa a un menu digitale sempre aggiornato, e gratuito per sempre!",
    "digital_menu_description2": "Con il nostro menu digitale, le tue offerte sono sempre aggiornate e accessibili su qualsiasi dispositivo. Risparmia denaro e semplifica gli aggiornamenti del menu con una soluzione fluida e a costo zero.",
    "try_me_button": "Provami",
    "try_3d_menu_title": "Menù 3D",
    "try_3d_menu_title2": "Gratis — WOW!",
    "try_3d_menu_description1": "Fai risaltare i tuoi piatti sullo schermo. Carica immagini 3D e lascia che i clienti assaggino virtualmente il tuo cibo prima di entrare.",
    "try_3d_menu_description2": "Le immagini 3D di alta qualità creano un'anteprima accattivante, aumentando l'attrattiva e incoraggiando gli ordini.",
    "try_now": "Prova ora",
    "showcase_title": "Mostra il tuo",
    "showcase_specials": "Speciali",
    "showcase_specials_description1": "Hai un'offerta appetitosa o una promozione imbattibile? Evidenziala sul tuo menu digitale e attira più persone affamate.",
    "showcase_specials_description2": "Rendi le promozioni invisibili, catturando l'attenzione con immagini audaci e descrizioni accattivanti.",
    "marketplace": "Mercato",
    "marketplace_description": "Stiamo creando un marketplace con oltre 2.000 visitatori al giorno. Sali a bordo ora e sarai il primo in fila quando lanceremo. Puoi raggiungere nuovi clienti che cercano attivamente opzioni di ristorazione. Assicurati il tuo posto in anticipo per massimizzare l'esposizione!",
    "see_other_products": "Vedi altri prodotti",
    "about_us": "Chi siamo",
    "why_serving_free": "Perché lo stiamo servendo per",
    "serving_free_highlight": "Gratuito",
    "about_desc": "Siamo gente del posto, proprio qui a Dallas, impegnata ad aiutare ristoranti come il vostro a prosperare.",
    "about_tool_free": "Offriamo questo potente strumento in modo completamente gratuito perché crediamo nel supporto della nostra comunità.",
    "no_catch": "Nessuna fregatura, non sto scherzando. Sappiamo che quando hai successo, la nostra comunità diventa più forte. 😊",
    "understanding_challenges": "Comprendiamo le sfide che comporta gestire un'azienda, ecco perché ci impegniamo a fornire strumenti che rendano le tue operazioni più fluide e la tua portata più ampia.",
    "community_growth": "Man mano che cresci, la nostra comunità cresce con te, creando un effetto a catena di successo e supporto. Insieme, miglioriamo l'esperienza culinaria per tutti.",
    "how_it_works": "Come funziona",
    "three_simple_steps": "Tre semplici passaggi per",
    "get_started_highlight": "Iniziare",
    "no_tech_skills_needed": "Non servono competenze tecniche. Se sai girare un hamburger, puoi fare anche questo!",
    "step1_title": "Mostra la tua promozione GRATIS!",
    "step1_desc": "Inizia prima di poter dire \"Ordina!\". Basta compilare un semplice modulo.",
    "step2_title": "Personalizza il tuo menu",
    "step2_desc": "Aggiungi i tuoi piatti, carica foto anche in 3D e metti in risalto le tue specialità.",
    "step3_title": "Condividi e cresci",
    "step3_desc": "Condividi il tuo menù digitale tramite QR code, social media o il tuo sito web.",
    "testimonials": "Testimonianze",
    "testimonials_section_title": "Non fidarti solo della nostra parola",
    "testimonials_section_desc": "Scopri cosa dicono gli altri ristoratori di Dallas.",
    "faq_section_title": "Hai domande? Abbiamo le risposte",
    "faq_section_desc": "Tutto quello che devi sapere prima di iniziare.",
    "faqs": "Domande frequenti",
    "faq_answers": [
        {
            "que": "È davvero gratis per sempre?",
            "ans": "Assolutamente! Nessuna commissione nascosta, nessuna carta di credito, nessun vincolo."
        },
        {
            "que": "Ho bisogno di qualche competenza tecnica?",
            "ans": "Se sai inviare un'email, puoi fare questo. È così semplice."
        },
        {
            "que": "Perché offrite questo servizio gratuitamente?",
            "ans": "Crediamo nel dare prima di tutto. In futuro, potremmo avere altri servizi che amerai, ma questo dipende interamente da te."
        },
        {
            "que": "Cosa succede al mercato?",
            "ans": "Stiamo cucinando! Una volta che avremo abbastanza ristoranti a bordo, lanceremo il tuo marketplace per darti ancora più visibilità."
        }
    ],
    "testimonials_content1": "Passare a un menu digitale è stata la mossa migliore che abbiamo fatto! I nostri clienti adorano la praticità e l'aspetto aggiornato!",
    "testimonials_name1": "Giorgio Mori",
    "testimonials_restaurant_name1": "Il ristorante Mansion",
    "testimonials_content2": "Il nostro nuovo menù digitale semplifica la consultazione da parte dei clienti e aumenta notevolmente il coinvolgimento!",
    "testimonials_name2": "Amelia Johnson",
    "testimonials_restaurant_name2": "Cibo urbano",
    "testimonials_content3": "Gli ospiti sono rimasti incantati dalle immagini 3D: ci hanno aiutato a distinguerci e hanno migliorato la loro esperienza culinaria!",
    "testimonials_name3": "Verde Davis",
    "testimonials_restaurant_name3": "Focolare della città",
    "ready_to_boost_your_restaurant": "Pronti a dare una spinta al vostro ristorante?",
    "ready_to_boost_desc": "Non rimanere indietro. Unisciti alla rivoluzione digitale oggi stesso!",
    "yes_i_want_my_free_digital_menu": "Sì, voglio il mio menu digitale gratuito",
    "480_plus": "480+",
    "people_already_joined": "persone già iscritte",
    "menu_online_home": "Menù Online Home",
    "welcome": "Benvenuto",
    "description_other": "Aumentiamo la soddisfazione dei tuoi clienti!",
    "benefits": "Benefici",
    "greeting_text": "Benvenuti 👋",
    "all_in_one_features": "🚀 Funzionalità all-in-one per migliorare il tuo ristorante",
    "menu_online_menu_name": "Menù in linea",
    "menu_online_description": "Digitalizza il tuo menù per facilitarne l'accesso online da parte dei clienti.",
    "menu_online_benefits": "Aumenta la comodità e il coinvolgimento dei clienti consentendo loro di visualizzare il tuo menu sempre e ovunque.",
    "ds_name": "Segnaletica digitale",
    "ds_description": "Presenta offerte e promozioni su schermi dinamici in negozio.",
    "ds_benefits": "Cattura l'attenzione dei clienti con immagini accattivanti, promuovendo upsell e offerte in tempo reale senza sforzo.",
    "loyalty_punch_name": "Tessera fedeltà",
    "loyalty_punch_description": "Premia i clienti abituali con un programma fedeltà digitale.",
    "loyalty_punch_benefits": "Aumenta la fidelizzazione dei clienti e le vendite incentivando la fedeltà con premi riscattabili per le visite frequenti.",
    "marketing_menu_name": "Marketing",
    "marketing_description": "Raggiungi più clienti con campagne di marketing digitale mirate.",
    "marketing_benefits": "Espandi la portata del tuo ristorante e attira visitatori con promozioni personalizzate pensate per attrarre clienti nuovi e abituali.",
    "get_more_reviews_menu_name": "Ottieni più recensioni",
    "get_more_reviews_description": "Incoraggia i clienti soddisfatti a lasciare recensioni positive online.",
    "get_more_reviews_benefits": "Aumenta la credibilità del tuo ristorante e attrai nuovi clienti migliorando la tua reputazione online con più recensioni positive.",
    "guest_smart_wifi_menu_name": "Wifi intelligente per gli ospiti",
    "guest_smart_wifi_description": "Offrire la connessione Wi-Fi gratuita per raccogliere dati sugli ospiti a scopo di marketing.",
    "guest_smart_wifi_benefits": "Aumenta la soddisfazione dei clienti raccogliendo dati preziosi per personalizzare le promozioni future.",
    "reservation_menu_name": "Prenotazione",
    "reservation_description": "Consenti ai clienti di prenotare i tavoli online con facilità.",
    "reservation_benefits": "Migliora l'esperienza culinaria riducendo al minimo i tempi di attesa e semplifica le prenotazioni per gestire meglio i posti a sedere.",
    "pos_integration_menu_name": "Integrazione POS",
    "pos_integration_description": "Integra il nostro sistema con il tuo sistema POS.",
    "pos_integration_benefits": "Semplifica l'elaborazione degli ordini e riduci gli errori sincronizzando i tuoi sistemi online e in negozio.",
    "online_ordering_menu_name": "Ordinazione online",
    "online_ordering_description": "Consenti ai clienti di effettuare ordini direttamente dal tuo sito web o dalla tua app.",
    "online_ordering_benefits": "Aumenta le vendite e riduci la dipendenza da piattaforme di terze parti offrendo un'opzione di ordinazione diretta che consente di risparmiare sulle commissioni.",
    "pro_label": "Professionista",
    "coming_soon": "Prossimamente",
    "locked_label": "Bloccato",
    "forever_label": "Per sempre",
    "new_label": "Nuovo",
    "other_products_page_title": "Altri prodotti | Menu Online",
    "other_products_description": "Menù Informativa sulla privacy online",
    "other_products_header_title": "Altri prodotti",
    "privacy_policy": {
        "title": "politica sulla riservatezza",
        "lastUpdated": "Ultimo aggiornamento: 1 luglio 2024",
        "introduction": {
            "header": "Informativa sulla privacy per clienti nuovi ed esistenti",
            "paragraph1": "I SEGUENTI PUNTI CHIAVE (INCLUSI I RIFERIMENTI DI SEZIONE) SONO FORNITI ESCLUSIVAMENTE PER LA VOSTRA COMODITÀ E NON SOSTITUISCONO I TERMINI E LE CONDIZIONI COMPLETI. È VOSTRA RESPONSABILITÀ LEGGERE TALI TERMINI E CONDIZIONI PRIMA DI UTILIZZARE IL SERVIZIO MENUONLINE INC.",
            "paragraph2": "Utilizzando Menuonline accetti i Termini e le Condizioni e dichiari e garantisci di essere autorizzato dalla tua organizzazione o azienda a farlo. (Vedi s.3 \"Come funziona\")",
            "paragraph3": "Menuonline Inc è un servizio di marketing basato su autorizzazione. L'invio deliberato di \"spam\" o la violazione dei Termini e Condizioni comporterà la chiusura del tuo account. (Vedi s.16 \"Uso accettabile del servizio\")",
            "paragraph4": "I dati che ospiti su Menuonline Inc, come i record dei contatti, il contenuto delle e-mail e le informazioni che raccogli tramite Menuonline Inc, appartengono a te. (Vedi s.14 \"Cosa possiedi?\").",
            "paragraph5": "Il software Menuonline Inc e il contenuto del nostro sito web ci appartengono. (Vedi s.13 \"Cosa possediamo?\") A parte i tuoi utenti, i dati o i contenuti non pubblicati che ospiti con Menuonline Inc possono essere visualizzati solo dai dipendenti e dai partner di Menuonline Inc che hanno firmato accordi di riservatezza. (Vedi s.14 \"Cosa possiedi?\")",
            "paragraph6": "Tutte le informazioni di identificazione personale (PII) sono conservate solo su server situati in Canada. (Vedi s.14 \"Cosa possiedi?\").",
            "paragraph7": "Il servizio Menuonline Inc è soggetto alle leggi del Canada e della provincia dell'Ontario. (Vedi s.24 \"Legge applicabile\")",
            "paragraph8": "Qualsiasi richiesta di dati o contenuti da parte di individui, polizia o altre agenzie di regolamentazione dall'interno del Canada o dall'esterno del Canada verrà sempre inoltrata a te. L'unica eccezione sarebbe se ciò ci ponesse in violazione della legge canadese o dell'Ontario; tuttavia, in tutti i casi resisteremo strenuamente a qualsiasi divulgazione dei tuoi dati da parte di chiunque altro che tu. (Vedi s.14 \"Cosa possiedi?\").",
            "paragraph9": "La sicurezza di Menuonline Inc è valida solo quanto la tua gestione di nomi utente e password. Non addebitiamo costi per gli account utente di Menuonline Inc, quindi creane uno separato per ogni utente e mantieni segrete le password. Sei responsabile del tuo account. (Vedi s.3 \"Come funziona\")",
            "paragraph10": "Puoi annullare il tuo account in qualsiasi momento. Non forniamo rimborsi su alcun credito inutilizzato se annulli. (Vedi s.3 \"Come funziona\")"
        },
        "termsAndConditions": {
            "title": "TERMINI E CONDIZIONI DI MENUONLINE INC",
            "lastUpdate": "ULTIMO AGGIORNAMENTO: 14 gennaio 2020",
            "paragraph1": "Si prega di leggere attentamente i presenti termini e condizioni di servizio (i \"Termini\") prima di utilizzare questo sito web (il \"Sito\"). Questi Termini esentano Menuonline Inc. (\"Menuonline Inc\" \"noi\" o \"ci\") e altri da responsabilità e\/o limitano la nostra e la loro responsabilità e contengono altre importanti disposizioni che si applicano al tuo utilizzo di questo Sito. Visitando o utilizzando questo Sito o il Servizio, accetti per conto tuo e per conto di qualsiasi organizzazione per conto della quale potresti agire (collettivamente denominati nel presente documento \"tu\") di accettare e rispettare questi Termini per ogni utilizzo e ogni visita a questo Sito."
        },
        "services": {
            "title": "I nostri servizi.",
            "paragraph": "I servizi che offriamo tramite il Sito sono un servizio che ti consente di creare, inviare e gestire messaggi di posta elettronica (\"E-mail\") ai destinatari da te scelti (il \"Servizio\")."
        },
        "license": {
            "title": "Licenza.",
            "paragraph": "Fatti salvi i presenti Termini, Menuonline Inc ti concede una licenza non esclusiva, limitata e non trasferibile durante il Termine per visualizzare il Sito e utilizzare il Servizio come consentito dalle funzionalità del Servizio. Ci riserviamo tutti i diritti non espressamente concessi nel presente documento nel Servizio e nel Contenuto di Menuonline Inc (come definito di seguito)."
        },
        "emailingContent": {
            "title": "Invio di contenuti tramite e-mail.",
            "paragraph": "Quando interagisci con Menuonline Inc tramite questo Sito o il Servizio, accetti di fornire informazioni vere e accurate. Inoltre, comprendi che fornire informazioni riservate tramite e-mail è una pratica non sicura. Comprendi di essere l'unico responsabile di qualsiasi cosa tu invii a chiunque utilizzi il nostro Servizio."
        },
        "support": {
            "title": "Supporto.",
            "paragraph": "Ti forniamo supporto per aiutarti a diventare autosufficiente con il Servizio. Ti forniamo supporto sotto forma di documentazione e video sul Sito, il sistema di ticket di supporto Menuonline Inc e per telefono. Puoi contattarci cliccando sulla scheda \"Supporto\" nella parte superiore del Sito. Non ci sono costi per il supporto del prodotto; tuttavia, ci riserviamo il diritto di interrompere il supporto per account client specifici a nostra discrezione."
        }
    },
    "terms_condition": {
        "title": "Termini di servizio | Menu Online",
        "header": "Termini e condizioni per clienti nuovi ed esistenti"
    },
    "privacy_policy_title": "POLITICA SULLA PRIVATA",
    "privacy_policy_effective_date": "Efficace dal 16 gennaio 2020",
    "privacy_policy_general_title": "POLITICA GENERALE SULLA PRIVATA",
    "privacy_policy_intro": "menuonline.com (Menuonline e \"noi\") comprende l'importanza della protezione della privacy delle informazioni personali degli individui e l'importanza della sicurezza delle informazioni riservate di tutti i tipi. Abbiamo creato questa Politica sulla privacy (la \"Politica\") per dimostrare il nostro impegno nel proteggere la tua \"privacy\". Questa Politica sulla privacy spiega:",
    "privacy_policy_points": [
        "Quali informazioni personali raccogliamo e perché le raccogliamo;",
        "Come utilizziamo i tuoi dati personali;",
        "Con chi condividiamo le tue informazioni personali; e",
        "Le opzioni a tua disposizione per accedere, aggiornare e rimuovere i tuoi dati personali dal servizio."
    ],
    "privacy_policy_pipeda": "Menuonline gestisce la raccolta di tutte le informazioni in conformità alla Legge canadese sulla protezione delle informazioni personali e dei documenti elettronici (PIPEDA).",
    "privacy_policy_update_notice": "Si prega di notare che Menuonline si riserva il diritto di aggiornare o modificare questa Informativa sulla privacy in qualsiasi momento. Ti informeremo di eventuali modifiche pubblicando un'Informativa aggiornata su questo sito Web o come reso disponibile tramite i servizi. La versione più aggiornata di questa Informativa regolerà l'uso da parte di mycircle delle informazioni personali degli utenti. Continuando ad accedere al sito Web di mycircle o continuando a utilizzare i suoi servizi dopo che sono state apportate modifiche a questa Informativa sulla privacy, accetti di essere vincolato dai termini rivisti.",
    "privacy_policy_consent_title": "CONSENSO PER I DATI PERSONALI",
    "privacy_policy_consent_text": "Fornendo a Menuonline le tue informazioni personali tramite il nostro sito web o uno qualsiasi dei nostri moduli, acconsenti alla raccolta, all'uso e alla divulgazione di tali informazioni in conformità con la presente Politica o per gli scopi identificati al momento in cui hai fornito le informazioni personali. Qualsiasi uso secondario delle informazioni personali verrà elaborato solo con il tuo esplicito consenso, oppure ti verrà fornita l'opportunità di rinunciare.",
    "privacy_policy_opt_out_text": "Hai la possibilità di revocare il tuo consenso o di rinunciare in qualsiasi momento. Se desideri revocare il tuo consenso o rinunciare, puoi cliccare sul link delle preferenze di comunicazione in fondo a qualsiasi e-mail che ricevi da noi.",
    "privacy_policy_info_collection_title": "INFORMAZIONI CHE RACCOGLIAMO",
    "privacy_policy_info_collection_text": "Menuonline raccoglie ed elabora informazioni personali in conformità con la presente Politica. Non venderemo, condivideremo o affitteremo queste informazioni ad altri, salvo quanto previsto nella presente Politica. Menuonline raccoglie informazioni personali allo scopo di fornirti i nostri servizi o prodotti.",
    "privacy_policy_info_types_text": "I tipi di informazioni che Menuonline raccoglie includono:",
    "privacy_policy_account_holders_title": "Titolari di account Menuonline",
    "privacy_policy_registration_info": "Informazioni di registrazione: se ti registri per utilizzare i servizi, hai bisogno di un account Menuonline per diventare un titolare di account (\"Titolare di account\"). Quando ti registri per un account, ti verrà chiesto di fornire alcune informazioni di base, come nome, indirizzo e-mail, nome utente, password, nome dell'azienda, occupazione, posizione e numero di telefono. Le password sono crittografate: lo staff di Menuonline non è in grado di visualizzare o recuperare le password.",
    "privacy_policy_billing_info": "Informazioni di fatturazione: se ti abboni ai nostri servizi, dovrai fornirci le informazioni di pagamento e di fatturazione.",
    "privacy_policy_cookies_info": "Cookie: utilizziamo i cookie per tracciare i dati dei visitatori, come ad esempio la provenienza delle visite al sito web e il numero di visite al nostro sito web.",
    "privacy_policy_ip_info": "Indirizzi IP: gli indirizzi IP vengono raccolti durante l'utilizzo dei servizi (ad esempio per registrare la posizione geografica a livello nazionale e gli indirizzi IP utilizzati durante l'utilizzo dei nostri servizi).",
    "privacy_policy_your_contacts_title": "I tuoi contatti",
    "privacy_policy_distribution_list": "Elenco di distribuzione: come parte del tuo utilizzo dei nostri servizi, potresti fornirci, o potremmo raccogliere come parte dei servizi, informazioni personali sui tuoi contatti (\"Contatti\"). Queste informazioni includono indirizzi e-mail e possono includere altre informazioni identificabili come nome, nome dell'azienda o altre informazioni che fornisci a Menuonline durante l'utilizzo dei servizi. Devi assicurarti di acquisire il consenso appropriato dai tuoi Contatti per consentire che le loro informazioni vengano elaborate da noi.",
    "privacy_policy_billing_info_contacts": "Informazioni di fatturazione: se ti abboni ai nostri servizi, dovrai fornirci le informazioni di pagamento e di fatturazione.",
    "privacy_policy_cookies_contacts": "Cookie: Menuonline utilizzerà cookie e tecnologie di tracciamento simili per raccogliere e utilizzare informazioni personali sui tuoi Contatti solo se hai aggiunto il codice di tracciamento di Menuonline al tuo sito web. Tutte le landing page ospitate da Menuonline avranno automaticamente un codice di tracciamento.",
    "privacy_policy_ip_contacts": "Indirizzi IP: gli indirizzi IP dei tuoi Contatti vengono raccolti come parte dei servizi (ad esempio allo scopo di registrare la posizione a livello di Paese e gli indirizzi IP utilizzati durante l'invio di un modulo e la fornitura del consenso).",
    "privacy_policy_info_about_contacts_title": "INFORMAZIONI CHE RACCOGLIAMO SUI TUOI CONTATTI",
    "privacy_policy_info_about_contacts_notice": "*Questa sezione si applica solo alle informazioni raccolte, utilizzate o divulgate da Menuonline sui Contatti del Titolare di un Account ai fini della fornitura dei servizi*",
    "privacy_policy_info_about_contacts_desc": "Menuonline raccoglierà, utilizzerà o divulgherà informazioni personali sui Contatti solo se il Titolare dell'Account si è registrato per determinati servizi in cui ci fornisce una lista di distribuzione. I Titolari dell'Account sono gli unici responsabili di garantire di aver rispettato tutte le leggi applicabili quando forniscono informazioni personali sui loro Contatti a Menuonline tramite i servizi.",
    "privacy_policy_info_auto_collect_title": "Informazioni Menuonline raccoglie automaticamente",
    "privacy_policy_info_auto_collect_desc1": "Quando i Contatti del Titolare dell'Account interagiscono con una campagna e-mail ricevuta da un Titolare dell'Account o interagiscono con un collegamento al sito Web collegato di un Titolare dell'Account, Menuonline può raccogliere informazioni sul dispositivo di tale Contatto e sull'interazione con l'e-mail tramite l'uso di cookie. Queste informazioni vengono raccolte solo per fornire i servizi e per fornire informazioni al Titolare dell'Account.",
    "privacy_policy_info_auto_collect_desc2": "Le informazioni che raccogliamo sul dispositivo di un Contatto e sulle applicazioni che il Contatto utilizza per accedere alle e-mail inviate dal Titolare dell'account tramite i nostri servizi possono includere l'indirizzo IP del Contatto, il sistema operativo, l'ID del browser e altre informazioni correlate sul sistema e sulla connessione del Contatto.",
    "privacy_policy_info_auto_collect_desc3": "Le informazioni che raccogliamo sull'interazione di Contact con le email inviate tramite i servizi possono includere date e orari in cui Contact accede alle email e alle attività di navigazione, nonché informazioni relative alle prestazioni dei servizi, come la recapitabilità delle email e di altre comunicazioni inviate a Contact da un Titolare di account tramite i servizi.",
    "privacy_policy_info_auto_collect_desc4": "Si prega di notare che Menuonline non contatterà nessuno dei tuoi contatti, a meno che non sia richiesto dalla legge applicabile.",
    "privacy_policy_why_we_collect_title": "PERCHÉ RACCOGLIAMO LE TUE INFORMAZIONI",
    "privacy_policy_why_we_collect_desc": "Menuonline raccoglie informazioni per i seguenti scopi:",
    "privacy_policy_why_collect_1": "per fornire ai Titolari di Account Menuonline i servizi a cui si iscrivono, incluso l'utilizzo delle informazioni del Titolare di Account per i servizi;",
    "privacy_policy_why_collect_2": "per l'amministrazione e la gestione degli account, nonché l'accesso al sito web di mycircle;",
    "privacy_policy_why_collect_3": "se hai acconsentito a ricevere materiale promozionale e di marketing, per informarti di altri prodotti e servizi offerti da Menuonline; e",
    "privacy_policy_why_collect_4": "per rispondere alle comunicazioni del Titolare dell'Account o per inviare le informazioni da te richieste. Ciò può includere, ma non è limitato a:",
    "privacy_policy_why_collect_5": "offerte speciali e aggiornamenti;",
    "privacy_policy_why_collect_6": "notiziario; e",
    "privacy_policy_why_collect_7": "annunci di servizio.",
    "privacy_policy_opt_out_desc": "Puoi scegliere in qualsiasi momento di non ricevere più comunicazioni cliccando sul pulsante di annullamento dell'iscrizione presente in fondo a ogni e-mail inviata da Menuonline.",
    "privacy_policy_use_disclosure_title": "USO E DIVULGAZIONE DELLE INFORMAZIONI PERSONALI",
    "privacy_policy_use_disclosure_desc1": "Le informazioni personali inviate a Menuonline, anche tramite il sito Web di Menuonline o un modulo prescritto, sono mantenute strettamente riservate a meno che non vengano divulgate in conformità con il consenso espresso di un Titolare di account o requisiti legali. In ogni caso, qualsiasi informazione personale fornita su qualsiasi piattaforma \"Menuonline\" dai Titolari di account è volontaria: qualsiasi informazione personale fornita da un Titolare di account è inviata a discrezione del Titolare di account. Si prega di notare che la revoca del consenso potrebbe comportare l'impossibilità di accedere o utilizzare determinati servizi Menuonline.",
    "privacy_policy_use_disclosure_desc2": "Menuonline si avvale di terze parti e fornitori di servizi esterni relativamente al suo sito Web e ai suoi servizi. Le informazioni personali del Titolare dell'account possono essere condivise con tali terze parti e fornitori. Possiamo anche fornire statistiche aggregate sui Titolari dell'account, vendite, modelli di traffico e informazioni correlate a terze parti affidabili. Menuonline richiede che qualsiasi fornitore terzo che abbia accesso a informazioni personali sia vincolato da obblighi coerenti con la presente Informativa sulla privacy e che tali fornitori raccolgano, utilizzino o divulghino le informazioni personali del Titolare dell'account solo allo scopo di fornire i servizi Menuonline.",
    "privacy_policy_use_disclosure_desc3": "Le informazioni personali fornite dai Titolari di account non saranno vendute, condivise o affittate a organizzazioni esterne, a meno che non siano in conformità con il tuo consenso implicito o esplicito. Menuonline si riserva il diritto di contattare qualsiasi Titolare di account per inoltrare informazioni, rispondere a domande o fornire notifiche di eventuali modifiche al nostro programma o alle nostre politiche. Si prega di notare che Menuonline non contatterà nessuno dei tuoi Contatti, a meno che non sia richiesto dalla legge applicabile.",
    "privacy_policy_use_disclosure_desc4": "Menuonline divulgherà le informazioni personali solo se il consenso è dato dall'individuo interessato o:",
    "privacy_policy_disclosure_list_1": "quando consentito o richiesto dalla legge, ad esempio in risposta a una citazione in giudizio o altro procedimento legale;",
    "privacy_policy_disclosure_list_2": "a un cessionario di tutto o di parte di Menuonline;",
    "privacy_policy_disclosure_list_3": "ai fornitori di servizi di Menuonline in conformità con la presente Politica; o",
    "privacy_policy_disclosure_list_4": "per soddisfare gli obblighi di legge, inclusi, a titolo esemplificativo ma non esaustivo, gli obblighi di segnalazione normativa.",
    "privacy_policy_service_providers_title": "Menuonline si avvale delle seguenti terze parti e fornitori di servizi (le informazioni personali dei tuoi Contatti non vengono divulgate a \"tali\" terze parti):",
    "privacy_policy_service_provider_1": "Utilizziamo ManyChat per fornire servizi di chat sul sito web per vendite e supporto, nonché e-mail di follow-up su queste conversazioni.",
    "privacy_policy_service_provider_2": "Utilizziamo AskNicely per intervistare i nostri clienti allo scopo di chiedere loro informazioni sulla qualità del servizio e dell'assistenza che ricevono.",
    "privacy_policy_service_provider_3": "Utilizziamo join.me per programmare riunioni con clienti e potenziali clienti, chiamate di supporto e demo software.",
    "privacy_policy_service_provider_4": "Utilizziamo Google Analytics per monitorare in forma anonima l'attività del sito web e misurare i modelli di utilizzo del nostro sito.",
    "privacy_policy_breach_notification_title": "NOTIFICA DI VIOLAZIONE",
    "privacy_policy_breach_notification_desc": "Menuonline si impegna a rispettare e a fornire ai Titolari di Account un'assistenza ragionevole nel rispetto delle leggi applicabili in relazione a qualsiasi uso, accesso o divulgazione non autorizzati di informazioni personali.",
    "privacy_policy_rights_access_title": "I TUOI DIRITTI E ACCESSO",
    "privacy_policy_rights_access_desc": "Hai il diritto di accedere alle informazioni personali che Menuonline elabora su di te. Puoi richiederci informazioni su:",
    "privacy_policy_rights_access_list_1": "Le informazioni personali che conserviamo su di te",
    "privacy_policy_rights_access_list_2": "Le categorie di dati personali interessati",
    "privacy_policy_rights_access_list_3": "Le finalità del trattamento",
    "privacy_policy_rights_access_list_4": "Dettagli a chi sono\/saranno divulgate le tue informazioni personali",
    "privacy_policy_rights_access_list_5": "Per quanto tempo conserviamo le tue informazioni personali",
    "privacy_policy_rights_access_list_6": "Se non abbiamo raccolto le informazioni direttamente da te, le informazioni sulla fonte",
    "privacy_policy_rights_access_list_7": "Come presentare un reclamo all'autorità di controllo competente",
    "privacy_policy_rights_access_list_8": "Puoi anche richiederci quanto segue:",
    "privacy_policy_rights_access_list_9": "Che aggiorniamo qualsiasi informazione incompleta o inesatta su di te",
    "privacy_policy_rights_access_list_10": "Richiedi che cancelliamo i tuoi dati personali in conformità con le leggi applicabili",
    "privacy_policy_rights_access_contact": "Puoi richiederci di esercitare i tuoi diritti contattandoci all'indirizzo 2851 Dufferin Street, Toronto, ON, Canada, oppure inviandoci un'e-mail all'indirizzo {link}.",
    "privacy_policy_accountability_title": "RESPONSABILITÀ",
    "privacy_policy_accountability_desc": "Menuonline si impegna a essere responsabile delle informazioni personali e riservate che ci fornisci. Per le informazioni di contatto, consulta la fine di questa Politica.",
    "privacy_policy_links_other_sites_title": "LINK AD ALTRI SITI",
    "privacy_policy_links_other_sites_desc1": "Il sito Web di Menuonline potrebbe contenere link ad altri siti. Si prega di notare che Menuonline non è responsabile delle pratiche sulla privacy di altri siti. Incoraggiamo i nostri titolari di account a essere consapevoli che quando lasciano il nostro sito, devono leggere attentamente le dichiarazioni sulla politica sulla privacy di ogni sito Web che raccoglie informazioni di identificazione personale.",
    "privacy_policy_links_other_sites_desc2": "La presente Informativa sulla privacy si applica esclusivamente alle informazioni da noi raccolte.",
    "privacy_policy_links_other_sites_desc3": "Se utilizzi un collegamento per andare dal sito Menuonline all'acquisto di prodotti o servizi, stai completando una transazione di acquisto secondo le norme e i regolamenti della società fornitrice, non di Menuonline.",
    "privacy_policy_cookies_title": "INFORMATIVA SULLA PRIVACY DEI COOKIE",
    "privacy_policy_cookies_desc1": "Il sito Web e i servizi Menuonline utilizzano i cookie per raccogliere e archiviare determinate informazioni in conformità con questa politica. Un \"cookie\" è un piccolo file di testo inviato da un sito Web e archiviato sul computer dell'utente dal browser Web dell'utente. Quando si visita un sito che utilizza i cookie, un cookie viene scaricato sul computer o sul dispositivo mobile. La volta successiva che si visita quel sito, il dispositivo ricorderà informazioni utili come preferenze, pagine visitate o opzioni di accesso.",
    "privacy_policy_cookies_desc2": "I cookie sono ampiamente utilizzati per aggiungere funzionalità ai siti Web o per garantire che funzionino in modo più efficiente. Il nostro sito si basa sui cookie per ottimizzare l'esperienza dell'utente e garantire il corretto funzionamento dei servizi del sito.",
    "privacy_policy_cookies_desc3": "La maggior parte dei browser web consente di limitare o bloccare i cookie tramite le impostazioni del browser; tuttavia, disabilitare i cookie potrebbe influire sulla possibilità di utilizzare alcune parti del nostro sito web o dei nostri servizi.",
    "privacy_policy_cookies_analytical_purpose": "<strong> Scopi analitici: <\/strong> Utilizziamo i cookie per analizzare l'attività del Titolare dell'account al fine di migliorare il nostro sito Web. Ad esempio, possiamo utilizzare i cookie per esaminare modelli aggregati come le funzionalità utilizzate dai Titolari dell'account. Possiamo utilizzare tale analisi per ottenere informazioni su come migliorare la funzionalità e l'esperienza utente del nostro sito Web.",
    "privacy_policy_cookies_preferences_user_experience": "<strong> Le tue preferenze e la tua esperienza utente: <\/strong> Utilizziamo i cookie per raccogliere determinate informazioni sui visitatori e sui titolari di account, come il tipo di browser, il server, la preferenza della lingua e l'impostazione del paese, al fine di memorizzare le preferenze del titolare dell'account sul nostro sito Web per rendere l'esperienza del titolare dell'account più coerente e comoda. Utilizziamo i cookie per mantenere il tuo stato di accesso quando visiti ripetutamente il nostro sito Web.",
    "privacy_policy_cookies_targeted_ads": "<strong> Fornire servizi di misurazione e annunci pubblicitari più mirati e marketing: <\/strong> Utilizziamo cookie, web beacon e altre tecnologie di archiviazione di partner terzi come Google e Facebook per servizi di misurazione, annunci pubblicitari più mirati e per scopi di marketing. Ciò avviene quando visiti il nostro sito Web. Questi cookie, web beacon e altre tecnologie di archiviazione ci consentono di mostrarti materiale promozionale di Menuonline su altri siti che visiti su Internet.",
    "privacy_policy_security_title": "SICUREZZA",
    "privacy_policy_security_desc1": "Menuonline si impegna a proteggere le informazioni riservate di tutti i tipi, comprese le informazioni personali, con misure di sicurezza organizzative, fisiche, meccaniche ed elettroniche appropriate alla sensibilità delle informazioni. I nostri server si trovano in Canada e seguono gli standard del settore in materia di sicurezza.",
    "privacy_policy_security_desc2": "Si prega di notare, tuttavia, che non possiamo garantire che le misure da noi adottate garantiscano la sicurezza delle informazioni.",
    "privacy_policy_retention_title": "RITENZIONE",
    "privacy_policy_retention_desc": "Conserviamo le informazioni personali sui Titolari di Account e sui Contatti per tutto il tempo necessario alle finalità indicate nella presente Informativa sulla Privacy e in conformità con le disposizioni sulla conservazione previste nei Termini di Utilizzo.",
    "privacy_policy_access_questions_title": "ACCESSO, DOMANDE E PREOCCUPAZIONI",
    "privacy_policy_access_questions_desc": "Chiunque abbia inviato informazioni personali a Menuonline può inviare una richiesta scritta per accedere o correggere le proprie informazioni personali conservate da Menuonline.",
    "terms_obj": {
        "terms_conditions_title": "TERMINI E CONDIZIONI",
        "terms_conditions_subtitle": "Termini e condizioni per clienti nuovi ed esistenti",
        "terms_conditions_desc1": "I SEGUENTI PUNTI CHIAVE (INCLUSI I RIFERIMENTI DI SEZIONE) SONO FORNITI ESCLUSIVAMENTE PER LA VOSTRA COMODITÀ E NON SOSTITUISCONO I TERMINI E LE CONDIZIONI COMPLETI. È VOSTRA RESPONSABILITÀ LEGGERE TALI TERMINI E CONDIZIONI PRIMA DI UTILIZZARE IL SERVIZIO Menuonline.",
        "terms_conditions_desc2": "Utilizzando Menuonline accetti i Termini e le Condizioni e dichiari e garantisci di essere autorizzato dalla tua organizzazione o azienda a farlo. (Vedi s.3 \"Come funziona\")",
        "terms_conditions_desc3": "Menuonline è un servizio di marketing basato sul permesso. L'invio deliberato di \"spam\" o la violazione dei Termini e Condizioni comporterà la chiusura del tuo account. (Vedi s.16 \"Uso accettabile del servizio\")",
        "terms_conditions_desc4": "I dati che ospiti su Menuonline, come i record dei contatti, il contenuto delle e-mail e le informazioni che raccogli tramite Menuonline, appartengono a te. (Vedi s.14 \"Cosa possiedi?\").",
        "terms_conditions_desc5": "Il software Menuonline e il contenuto del nostro sito web ci appartengono. (Vedi s.13 “Cosa possediamo?”)",
        "terms_conditions_desc6": "Oltre ai tuoi utenti, i dati o i contenuti non pubblicati che ospiti con Menuonline possono essere visualizzati solo dai dipendenti e dai partner di Menuonline che hanno firmato accordi di riservatezza. (Vedi s.14 \"Cosa possiedi?\").",
        "terms_conditions_desc7": "Tutte le informazioni di identificazione personale (PII) sono conservate solo su server situati in Canada. (Vedi s.14 \"Cosa possiedi?\").",
        "terms_conditions_desc8": "Il servizio Menuonline è soggetto alle leggi del Canada e della provincia dell'Ontario. (Vedi s.24 \"Legge applicabile\")",
        "terms_conditions_desc9": "Qualsiasi richiesta di dati o contenuti da parte di individui, polizia o altre agenzie di regolamentazione dall'interno del Canada o dall'esterno del Canada verrà sempre inoltrata a te. L'unica eccezione sarebbe se ciò ci ponesse in violazione della legge canadese o dell'Ontario; tuttavia, in tutti i casi resisteremo strenuamente a qualsiasi divulgazione dei tuoi dati da parte di chiunque altro che tu. (Vedi s.14 \"Cosa possiedi?\").",
        "terms_conditions_desc10": "La sicurezza di Menuonline è valida solo quanto la tua gestione di nomi utente e password. Non addebitiamo costi per gli account utente di Menuonline, quindi creane uno separato per ogni utente e mantieni segrete le password. Sei responsabile del tuo account. (Vedi s.3 \"Come funziona\")",
        "terms_conditions_desc11": "Puoi annullare il tuo account in qualsiasi momento. Non forniamo rimborsi su alcun credito inutilizzato se annulli. (Vedi s.3 \"Come funziona\")",
        "terms_conditions_desc12": "Possiamo annullare il tuo account in qualsiasi momento. Forniamo rimborsi per i crediti e-mail e i servizi non utilizzati se annulliamo il tuo account. (Vedi s.3 \"Come funziona\")",
        "terms_conditions_desc13": "I tuoi dati saranno cancellati dopo la cancellazione e potrebbero essere cancellati anche se un utente dell'account non ha effettuato l'accesso per 12 mesi o più. Conserveremo dati aggregati dal tuo account che non includono informazioni di identificazione personale per scopi statistici. (Vedi s.3 \"Come funziona\")",
        "terms_conditions_desc14": "I crediti e-mail vengono acquistati su base pay-as-you-go e non scadono mai. L'unica eccezione è se non hai effettuato l'accesso al tuo account per 12 mesi. In questo caso, ci riserviamo il diritto di eliminare il tuo account, i tuoi dati e i crediti associati. (Vedi s.10 \"Cosa pago per il Servizio?\")",
        "terms_conditions_desc15": "Ci impegniamo per garantire che Menuonline sia sempre attivo e funzionante e per fornire un preavviso se dovessimo sospendere temporaneamente il servizio per manutenzione. Tuttavia, non garantiamo che il servizio sarà sempre attivo e funzionante e non siamo responsabili per eventuali perdite subite dall'utilizzo di Menuonline, comprese le perdite da parte vostra se il sistema non è in esecuzione in un momento in cui ne avete bisogno. (Vedere s.17 \"Esclusioni di responsabilità\").",
        "terms_conditions_desc16": "Non monitoriamo i tuoi contenuti o a chi invii messaggi. Tuttavia, monitoriamo reclami e altri problemi creati dalle tue attività. Troppi reclami o problemi possono comportare la cancellazione del tuo servizio a nostra discrezione. (Vedi s.7 \"Monitoraggio\")",
        "terms_conditions_desc17": "Ci impegniamo a fornire un eccellente Supporto Prodotto per aiutarti a diventare autosufficiente con Menuonline. Forniamo supporto sotto forma di documentazione e video sul nostro sito Web, sul sistema di ticket di supporto Menuonline e tramite telefono. Non ci sono costi per il supporto prodotto; tuttavia, ci riserviamo il diritto di interrompere il supporto per account cliente specifici a nostra discrezione. (Vedi s.5 \"Supporto\")",
        "terms_conditions_desc18": "I Servizi Prodotto sono per quei momenti in cui vuoi che facciamo un po' di lavoro correlato a Menuonline piuttosto che farlo tu stesso. La nostra tariffa per i Servizi Prodotto è di $ 135 all'ora. Forniamo anche servizi di consulenza a tariffe più elevate. Ci riserviamo il diritto di modificare le nostre tariffe in qualsiasi momento. (Vedi s.6 \"Servizi Prodotto e Consulenza\")",
        "terms_conditions_desc19": "Ti informeremo se riteniamo che si sia verificata una violazione dei tuoi dati e faremo il possibile per fornirti i dettagli e le informazioni correlate. (Vedi s.14 \"Cosa possiedi?\")",
        "terms_conditions_desc20": "Ci riserviamo il diritto di apportare modifiche al presente accordo in qualsiasi momento. Cercheremo di informarti sulle modifiche ai termini e alle condizioni, ma l'utilizzo del servizio implica l'accettazione delle modifiche ai Termini. (Vedi s.9 \"Modifiche\").",
        "terms_conditions_desc21": "I Termini e Condizioni e la nostra Informativa sulla Privacy sono stati redatti esclusivamente in inglese e l'utente accetta che la versione in lingua inglese dei Termini e Condizioni e dell'Informativa sulla Privacy avrà la priorità in caso di conflitto con qualsiasi versione tradotta.",
        "terms_conditions_mycircle": "TERMINI E CONDIZIONI DI MYCIRCLE",
        "terms_conditions_last_update": "ULTIMO AGGIORNAMENTO: 14 gennaio 2020",
        "terms_conditions_usage_agreement": "Si prega di leggere attentamente i presenti termini e condizioni di servizio (i \"Termini\") prima di utilizzare questo sito web (il \"Sito\"). I presenti Termini esentano Menuonline ('Menuonline', 'noi' o 'ci') e altri da ogni responsabilità e\/o limitano la nostra e la loro responsabilità e contengono altre importanti disposizioni che si applicano al tuo utilizzo di questo Sito.",
        "terms_conditions_acceptance": "Visitando o utilizzando questo Sito o il Servizio, accetti per te stesso e per conto di qualsiasi organizzazione per conto della quale potresti agire (collettivamente denominati nel presente documento \"tu\") di accettare e rispettare i presenti Termini per ogni utilizzo e ogni visita a questo Sito.",
        "terms_conditions_services": "1. I nostri servizi.",
        "terms_conditions_services_desc": "I servizi che offriamo tramite il Sito sono un servizio che ti consente di creare, inviare e gestire messaggi di posta elettronica (\"Email\") ai destinatari da te scelti (il \"Servizio\").",
        "terms_conditions_license": "2. Licenza.",
        "terms_conditions_license_desc": "Fatti salvi i presenti Termini, Menuonline ti concede una licenza non esclusiva, limitata e non trasferibile durante il Termine per visualizzare il Sito e utilizzare il Servizio come consentito dalle funzionalità del Servizio. Ci riserviamo tutti i diritti non espressamente concessi nel presente documento nel Servizio e nel Contenuto Menuonline (come definito di seguito).",
        "terms_conditions_account": "3. Come funziona.",
        "terms_conditions_account_desc": "Per utilizzare il Servizio devi registrarti per un account. Il tuo account è gratuito. Il tuo account ti dà accesso al Servizio e alle \"funzioni\" che potremmo stabilire e mantenere di volta in volta. Per essere idoneo per un account devi:",
        "terms_conditions_account_requirements": [
            "avere almeno diciotto (18) anni ed essere in grado di stipulare contratti;",
            "completare la procedura di registrazione da noi indicata;",
            "accettare i presenti Termini e Condizioni;",
            "fornire informazioni di contatto e di fatturazione\/pagamento veritiere, complete e aggiornate."
        ],
        "responsibility": "Responsabilità.",
        "responsibilityText": "Sei l'unico responsabile dell'attività che avviene sul tuo account e devi mantenere la password del tuo account riservata e sicura. Gli account sono gratuiti, quindi ti invitiamo a creare un account diverso per ogni utente che possiedi. Sei inoltre responsabile di qualsiasi account a cui hai accesso, indipendentemente dal fatto che tu ne abbia autorizzato o meno l'uso. Accetti di essere l'unico responsabile delle tue interazioni con coloro a cui invii e-mail e invierai e-mail solo a quelle persone da cui hai il consenso. Devi immediatamente informarci di qualsiasi utilizzo non autorizzato dei tuoi account.",
        "compliance_with_CASL": "Conformità alla legislazione canadese anti-spam (CASL).",
        "compliance_with_CASL_text": "Il ruolo di Menuonline è quello di facilitare la gestione delle impostazioni del consenso, non di far rispettare la legislazione. È tua responsabilità essere conforme al CASL. Ti aiutiamo impostando funzionalità predefinite e facilitando l'ottenimento e l'aggiornamento del consenso espresso documentato e la gestione delle date di scadenza continue per il consenso implicito. Hai il pieno controllo della gestione del consenso per il tuo database; puoi aggiornare lo stato del consenso per tutti i tuoi contatti in blocco in qualsiasi momento, ignorare le impostazioni predefinite e disabilitare la scadenza automatica dello stato del consenso implicito.",
        "closing_account": "Chiusura del tuo account.",
        "closing_account_text": "Sia tu che Menuonline potete recedere dal presente Contratto in qualsiasi momento e per qualsiasi motivo, inviando un Avviso all'altra parte. Se fornisci informazioni false, inaccurate, non aggiornate o incomplete o utilizzi il Servizio in un modo che viola questi Termini e Condizioni, abbiamo il diritto di sospendere immediatamente il tuo account e di terminare il tuo accesso al Servizio, oltre a qualsiasi altro rimedio a nostra disposizione. Se non utilizzi il tuo account per dodici (12) mesi, potremmo annullarlo. Se dovessimo terminare il tuo account per qualsiasi motivo, ti rimborseremo tutti i Crediti che hai lasciato. Una volta terminato, elimineremo definitivamente il tuo account e tutti i dati ad esso associati, comprese le Email che hai inviato. Potremmo conservare dati aggregati dal tuo account che non includono informazioni di identificazione personale per scopi statistici.",
        "emailing_content": "Invio di contenuti tramite e-mail.",
        "emailing_content_text": "Quando interagisci con Menuonline tramite questo Sito o il Servizio, accetti di fornire informazioni vere e accurate. Inoltre, comprendi che fornire informazioni riservate tramite e-mail è una pratica non sicura. Comprendi di essere l'unico responsabile per qualsiasi cosa tu invii a chiunque utilizzi il nostro Servizio. scopi.",
        "support": "Supporto.",
        "support_text": "Ti forniamo supporto per aiutarti a diventare autosufficiente con il Servizio. Ti forniamo supporto sotto forma di documentazione e video sul Sito, il sistema di ticket di supporto Menuonline e per telefono. Puoi contattarci cliccando sulla scheda \"Supporto\" nella parte superiore del Sito. Non ci sono costi per il supporto del prodotto; tuttavia, ci riserviamo il diritto di interrompere il supporto per account client specifici a nostra discrezione.",
        "product_and_consulting_services": "Servizi di consulenza e prodotti.",
        "product_and_consulting_services_text": "Product Services è per quei momenti in cui vuoi che facciamo un po' di lavoro correlato a Menuonline piuttosto che farlo tu stesso. La nostra tariffa per Product Services è di $ 125 all'ora. Forniamo anche servizi di consulenza a tariffe più elevate. Ci riserviamo il diritto di modificare le nostre tariffe in qualsiasi momento.",
        "monitoring": "Monitoraggio.",
        "monitoring_text": "Non monitoreremo né giudicheremo il contenuto delle informazioni trasmesse tramite il Servizio, ma investigheremo se riceviamo reclami di possibile uso inappropriato. Menuonline può, in qualsiasi momento e a sua esclusiva discrezione, stabilire se determinati usi sono o non sono appropriati con o senza preavviso, secondo le seguenti linee guida.",
        "term": "Termine.",
        "term_text": "Il Termine inizia quando ti registri per un account con noi e continua finché utilizzi il Servizio. Dichiari e garantisci di avere l'autorità e la capacità di registrarti per un account per noi (anche se ti registri per conto di un'organizzazione).",
        "changes": "Cambiamenti.",
        "changes_text": "Il Termine inizia quando ti registri per un account con noi e continua finché utilizzi il Servizio. Dichiari e garantisci di avere l'autorità e la capacità di registrarti per un account per noi (anche se ti registri per conto di un'organizzazione).",
        "payment": "PAGAMENTO",
        "what_do_i_pay_for_service": "Quanto devo pagare per il Servizio?",
        "what_do_i_pay_for_service_text": "Puoi acquistare crediti prepagati per inviare e-mail (\"Crediti\") tramite il nostro Servizio, come descritto in dettaglio nella pagina \"prezzi\" del nostro Sito. I Crediti non scadono mai e non ci sono commissioni ricorrenti per questi Crediti. L'unica eccezione è che se non utilizzi il tuo account per dodici (12) mesi, potremmo annullare il tuo account e tutti i Crediti non utilizzati. Possiamo modificare i nostri prezzi in qualsiasi momento aggiornando le pagine dei prezzi del nostro Sito, che si applicheranno al tuo prossimo acquisto di Crediti.",
        "how_do_i_pay_for_service": "Come posso pagare il Servizio?.",
        "how_do_i_pay_for_service_text": "Puoi pagare i Crediti utilizzando qualsiasi carta di credito principale. Potremmo cambiare i metodi di pagamento che accettiamo in qualsiasi momento.",
        "can_i_get_refund": "Posso ottenere un rimborso?",
        "can_i_get_refund_text": "Gli importi che hai pagato per utilizzare il Servizio non sono rimborsabili se annulli il tuo account. Se annulliamo il tuo account, ti rimborseremo tutti i Crediti che ti sono rimasti.",
        "rights": "DIRITTI",
        "what_do_we_own": "Cosa possediamo?.",
        "what_do_we_own_text": "Ad eccezione dei contenuti che ci fornisci (inclusi, senza limitazioni, registri di contatto, contenuti e-mail e informazioni che raccogli tramite il Servizio) (\"I tuoi Contenuti\"), tutti i materiali inclusi nel nostro Sito, nel Servizio e tutti i materiali ivi contenuti o trasferiti tramite esso, inclusi, senza limitazioni, software, immagini, testo, grafica, illustrazioni, loghi, brevetti, marchi commerciali, marchi di servizio, fotografie, audio, video, musica e contenuti appartenenti ad altri Utenti (i \"Contenuti Menuonline\") (e tutti i diritti di proprietà intellettuale correlati) sono di nostra proprietà o dei nostri licenziatari.",
        "what_do_you_own": "Cosa possiedi?",
        "what_do_you_own_text": "Non rivendichiamo alcun diritto di proprietà sui Tuoi Contenuti. I Tuoi Contenuti sono tuoi e tu mantieni la proprietà dei Tuoi Contenuti che carichi sul Servizio. Condividendo i Tuoi Contenuti tramite il Servizio, accetti di consentire ad altri di visualizzare, modificare e\/o condividere i Tuoi Contenuti in conformità con le tue impostazioni e questi Termini e Condizioni. Dichiari e garantisci di possedere o di avere il permesso di utilizzare tutto il materiale che utilizzi nelle tue E-mail. Possiamo utilizzare o divulgare i tuoi materiali solo come descritto in questi Termini e nella nostra Informativa sulla privacy.",
        "privacy_statement": "Menuonline rispetta la privacy dei suoi utenti. Qualsiasi informazione personale che Menuonline raccoglie tramite questo Sito o il Servizio è soggetta all'Informativa sulla privacy di Menuonline che è incorporata per riferimento in questi Termini. Possiamo utilizzare e divulgare le tue informazioni in base alla nostra Informativa sulla privacy.",
        "access_to_your_content": "Menuonline non fornirà l'accesso ai Tuoi Contenuti a nessuno, ad eccezione dei dipendenti e dei collaboratori di Menuonline che hanno stipulato con noi opportuni accordi di riservatezza o che hanno ottenuto il tuo consenso.",
        "governmentRequest": "Se riceviamo una richiesta da un governo o da un'autorità di regolamentazione per avere accesso ai Tuoi Contenuti, ti informeremo della richiesta e chiederemo il tuo consenso prima di rilasciare qualsiasi Tuo Contenuto. Resisteremo a qualsiasi richiesta per i Tuoi Contenuti e non rilasceremo i Tuoi Contenuti in risposta a questo tipo di richiesta senza la tua autorizzazione o senza essere tenuti a farlo da un tribunale di giurisdizione competente o dall'organismo di regolamentazione applicabile.",
        "breachNotification": "Nel caso in cui venissimo a conoscenza che il Tuo Contenuto è stato oggetto di accesso, utilizzo, copia, furto, perdita o utilizzo o divulgazione non autorizzati in violazione di questi Termini (una \"Violazione\"), ti informeremo tempestivamente e ti informeremo di quanto accaduto. Accetti che le Violazioni saranno gestite in conformità con la legge applicabile e, se abbiamo bisogno che tu informi i membri delle tue liste di distribuzione e-mail, accetterai di farlo tempestivamente e come da noi indicato.",
        "contentRetention": "Conserveremo, utilizzeremo e trasmetteremo i tuoi Contenuti in Canada, salvo tua previa approvazione o indicazione scritta (ad esempio, se scegli di inviare i tuoi Contenuti a persone al di fuori del Canada). I server utilizzati per fornire il Servizio si trovano in Canada.",
        "accessTrouble": "Se riscontri problemi nell'accedere ai Tuoi Contenuti, Menuonline ti aiuterà a recuperarli in base al tempo e ai materiali richiesti.",
        "reportingAbuse": "Se ritieni che qualcuno stia violando uno qualsiasi di questi Termini, ti preghiamo di segnalarcelo immediatamente. Se hai ricevuto spam che ritieni provengano da un altro utente del Servizio, se ritieni che un altro utente del Servizio abbia pubblicato materiale che viola i diritti d'autore o se ritieni che un altro utente del Servizio stia violando in altro modo questi Termini, ti preghiamo di contattarci all'indirizzo Support@menuonline.com.",
        "acceptableUse": "Dichiari e garantisci che il tuo utilizzo del Servizio sarà conforme a tutte le leggi e normative applicabili. Accetti di essere responsabile della determinazione se il Servizio sarà conforme e idoneo al tuo utilizzo nella tua giurisdizione. Per maggiore certezza, accetti di (e accetti di garantire che i tuoi dipendenti, agenti e utenti autorizzati) ...",
        "exportLaws": "Non rilasciamo alcuna dichiarazione che il Sito o il Servizio siano appropriati o disponibili per l'uso al di fuori del Canada, e l'accesso a essi da territori in cui i loro contenuti sono illegali è proibito. Non puoi usare, esportare o riesportare il Contenuto Menuonline o altri materiali su questo Sito o qualsiasi copia o adattamento in violazione di qualsiasi legge o regolamento applicabile, comprese, senza limitazioni, le leggi e i regolamenti sulle esportazioni canadesi e statunitensi.",
        "disclaimers": "Il Servizio e questo Sito sono forniti \"così come sono\", senza alcuna dichiarazione, garanzia o condizione di alcun tipo, e Menuonline declina espressamente, nella misura massima consentita dalle leggi applicabili, tutte le dichiarazioni, garanzie e condizioni, espresse o implicite, per effetto di legge o altrimenti, comprese, senza limitazioni, tutte le garanzie e condizioni implicite di commerciabilità o idoneità per qualsiasi scopo o utilizzo particolare, o non violazione dei diritti di terzi...",
        "limitLiability": "La responsabilità complessiva di Menuonline ai sensi del presente Contratto, sia contrattuale che extracontrattuale (inclusa la negligenza), derivante da violazione di garanzia, responsabilità oggettiva o da qualsiasi altra teoria di responsabilità, sarà limitata ai danni diretti in un importo non superiore alle commissioni totali pagate a Menuonline ai sensi dei presenti Termini e Condizioni nei dodici (12) mesi immediatamente precedenti al mese in cui si è verificato l'evento più recente che ha dato origine alla responsabilità.",
        "liabilityExclusions": "Nessuno di Menuonline, delle sue affiliate o dei rispettivi direttori, funzionari, dipendenti, agenti o altri rappresentanti (collettivamente 'Rappresentanti di Menuonline') o degli operatori di reti collegate avrà alcuna responsabilità in relazione a questo Sito o al Servizio per: (i) eventuali danni indiretti, consequenziali, incidentali, esemplari, punitivi o speciali; o (ii) per eventuali danni, diretti, indiretti, consequenziali, incidentali, esemplari, punitivi o speciali, caratterizzati come mancati guadagni, mancati risparmi o guadagni o mancati profitti...",
        "indemnity": "L'utente si impegna a indennizzare e tenere indenni Menuonline e\/o i rappresentanti di Menuonline (le \"parti indennizzate di Menuonline\") da e contro qualsiasi reclamo presentato da terzi e da qualsiasi multa o sanzione comminata nei nostri confronti, derivanti dall'utilizzo delle informazioni accessibili da questo Sito o dall'utilizzo del Servizio, nonché da qualsiasi violazione dei presenti Termini...",
        "ipInfringement": "Menuonline ti difenderà, indennizzerà e ti terrà indenne da e contro qualsiasi reclamo, richiesta, azione, causa o procedimento intentato o presentato contro di te da una terza parte che sostenga che il tuo accesso o utilizzo del Servizio in conformità al presente Contratto viola i diritti di proprietà intellettuale previsti dalla legge canadese di una terza parte (un \"Reclamo per violazione della proprietà intellettuale\")...",
        "equitableRelief": "Se violi questi Termini, potremmo richiedere un provvedimento ingiuntivo (il che significa che potremmo richiedere un ordine del tribunale per fermarti) o un altro provvedimento equitativo. Accetti che ciò si aggiungerà e non pregiudicherà alcun altro diritto che potremmo avere per legge o in equità.",
        "linksToOtherSites": "Se forniamo link a siti web di terze parti, sono forniti solo per comodità. Se utilizzi questi link, abbandonerai il nostro Sito. Non controlliamo questi siti di terze parti né li approviamo. Accetti che non siamo responsabili per nessuno di questi siti di terze parti o per il loro contenuto...",
        "thirdPartyRequests": "Se dobbiamo fornire informazioni in risposta a una richiesta legale o governativa relativa al tuo account o al tuo utilizzo del Servizio, accetti che possiamo addebitarti i nostri costi. Tali commissioni possono includere le nostre spese legali e i costi del tempo impiegato dai nostri dipendenti per preparare la risposta alla richiesta.",
        "assignment": "Il presente Contratto e tutti i diritti e le licenze concessi ai sensi dello stesso non possono essere trasferiti o ceduti dall'utente, ma possono essere ceduti da Menuonline senza restrizioni, previo ragionevole preavviso.",
        "governingLaw": "Questi termini saranno regolati e interpretati in conformità con tutte le leggi federali applicabili e le leggi dell'Ontario, senza riguardo alle disposizioni sui conflitti di legge. Accetti di sottoporti alla giurisdizione esclusiva dei tribunali dell'Ontario per la determinazione di qualsiasi controversia relativa a questi Termini.",
        "severability": "Se per qualsiasi motivo un tribunale di giurisdizione competente ritiene che una qualsiasi disposizione di questi Termini o parte di essa sia illegale o per qualsiasi motivo inapplicabile, tale disposizione sarà applicata nella misura massima consentita per dare effetto all'intento di questi Termini, e le restanti disposizioni di questi Termini continueranno ad avere pieno vigore ed efficacia e rimarranno applicabili.",
        "waiver": "Qualsiasi rinuncia o consenso a discostarsi dai requisiti di questi Termini sarà efficace solo se in forma scritta e firmata da noi, e solo nell'istanza specifica e per lo scopo specifico per cui è stata data. Il nostro mancato esercizio o il nostro ritardo nell'esercizio di qualsiasi diritto ai sensi di questi Termini non costituirà una rinuncia ai nostri diritti.",
        "furtherAssurances": "Ciascuna parte accetta di firmare e fornire tutti i documenti e di intraprendere tutte le azioni (o di far sì che vengano intraprese tali azioni) necessarie per adempiere ai propri obblighi ai sensi dei presenti Termini e condizioni.",
        "notices": "Tutte le notifiche a Menuonline devono essere in forma scritta e inviate a noi tramite e-mail. Le notifiche a noi devono essere inviate all'attenzione dei nostri rappresentanti del servizio clienti all'indirizzo support@menuonline.com. Le notifiche a te possono essere inviate all'indirizzo o all'indirizzo e-mail da te fornito come parte della tua registrazione. Inoltre, potremmo trasmettere notifiche o messaggi tramite questo Sito per informarti di modifiche a questo Sito o di altre questioni importanti, e queste trasmissioni costituiranno una notifica a te al momento della loro pubblicazione.",
        "entireAgreement": "Questi Termini e la nostra Informativa sulla privacy costituiscono l'intero accordo tra te e noi relativamente al tuo utilizzo del Sito e del Servizio e sostituiscono tutti gli accordi, le dichiarazioni e le intese precedenti tra Menuonline e te relativamente a questo argomento. In caso di conflitto tra l'Informativa sulla privacy e questi Termini, questi Termini e condizioni prevarranno.",
        "language": "Le parti hanno espressamente richiesto e preteso che i presenti Termini e condizioni, la nostra Informativa sulla privacy e tutti i documenti correlati siano redatti in inglese. Les parties conviennent ed exigent expressément que ce Contrat et tous les documents qui s'y rapportent soient rédigés en anglais. Accetti che la versione in lingua inglese dei presenti Termini e Informativa sulla privacy avrà la priorità in caso di conflitto con qualsiasi versione tradotta.",
        "survival": "Tutte le disposizioni dei presenti Termini che per loro natura o importanza sono destinate a sopravvivere alla scadenza o alla risoluzione dei presenti Termini, sopravvivranno, comprese, senza limitazioni, le Sezioni 17-19."
    },
    "collect_additional_info": "Desideri raccogliere ulteriori informazioni?",
    "additionalInfoChoice": "Seleziona le informazioni aggiuntive che desideri raccogliere.",
    "additional_info_yes": "SÌ",
    "additional_info_noSkip": "No, salta",
    "additional_info_label": "Informazioni aggiuntive",
    "confirm_business_location": "Per prima cosa confermiamo la sede della tua attività",
    "custom_get_more_reviews_form": "Modulo personalizzato per ottenere più recensioni",
    "set_up_custom_form": "Imposta il tuo modulo personalizzato Ottieni più recensioni per gruppo",
    "reputation_name_label": "Ottieni più recensioni Nome",
    "reputation_name_placeholder": "Inserisci il nome della reputazione",
    "group_label": "Gruppo",
    "group_desc_title": "Titolo descrizione gruppo",
    "group_desc": "Descrizione del gruppo",
    "select_group_placeholder": "Seleziona gruppo",
    "required_group_name": "Il nome del gruppo è obbligatorio",
    "before_asking_review": "Prima di chiedere una recensione ai clienti, poniamo loro da 1 a 4 domande.",
    "feel_free_edit": "Sentiti libero di apportare le modifiche che preferisci.",
    "incentive_settings": "Impostazioni degli incentivi",
    "incentivize_customer": "Vuoi incentivare i tuoi clienti a lasciare una recensione?",
    "choose_incentive": "Seleziona l'incentivo che desideri segnalare.",
    "scan_qr_feedback": "Scansiona il codice QR e condividi con noi il tuo prezioso feedback sulla tua esperienza.",
    "qr_code": "Codice QR",
    "scan_me": "SCANSIONAMI!",
    "or_title": "O",
    "send_otp_title": "Invia OTP",
    "sign_in_title": "Registrazione",
    "email_addresss_title": "Indirizzo e-mail",
    "reset_password_title": "Reimposta password",
    "remember_password_title": "Password dimenticata?",
    "digit_code_title": "Inserisci il codice di 4 cifre inviato su ",
    "not_received_title": "Non ho ricevuto un codice di verifica!",
    "resend_title": "Per favore, invia di nuovo",
    "sign_up_form": "Crea il tuo account",
    "sign_up_sub_title": "Accedi ora per accedere alle ultime informazioni",
    "already_registered_title": "Hai già un account Menuonline?",
    "reserved_title": "Copyright ©2023 Menuonline. Tutti i diritti riservati.",
    "terms_title": "Termini di servizio",
    "policy_title": "politica sulla riservatezza",
    "sign_up_title": "Iscrizione",
    "terms_conditions_title": "Termini e condizioni",
    "business_type_title": "Tipo di attività",
    "business_type_sub_title": "Seleziona la tipologia di attività che meglio descrive la tua attività!",
    "salon_title": "Salone",
    "restaurant_title": "Ristorante",
    "pizza_store_title": "Negozio di pizza",
    "food_truck_title": "Camion di cibo",
    "check_domain_title": "Controlla il nome di dominio",
    "start_exploring_title": "Inizia ad esplorare",
    "sign_in_form": "Accedi al tuo account",
    "sign_in_sub_title": "Accedi ora per accedere alle ultime informazioni",
    "remember_title": "Ricordati di me",
    "otp_title": "OTP",
    "password_title": "Password",
    "forgot_title": "Ha dimenticato la password?",
    "not_registered_title": "Non hai un account?",
    "create_account_title": "Iscriviti ora",
    "create_form_title": "Crea modulo",
    "first_name_title": "Nome di battesimo",
    "last_name_title": "Cognome",
    "email_title": "E-mail",
    "mobile_number_title": "Numero di cellulare",
    "birthday_title": "Compleanno",
    "gender_title": "Genere",
    "address_title": "Indirizzo",
    "short_answer_title": "Risposta breve",
    "long_answer_title": "Risposta lunga",
    "single_answer_title": "Risposta singola",
    "single_checkbox_title": "Singola casella di controllo",
    "multiple_choice_title": "Scelta multipla",
    "drop_down_title": "Cadere in picchiata",
    "yes_no_title": "Sì o no",
    "description_text_title": "Testo descrittivo",
    "file_upload": "Caricamento file",
    "reset_password_form": "Reimposta la tua password",
    "reset_password_sub_title": "Reimposta rapidamente la tua password e accedi al tuo account.",
    "required_full_name_title": "Il nome completo è obbligatorio",
    "required_first_name_title": "Il nome è obbligatorio",
    "valid_first_name_title": "Inserisci un nome valido",
    "valid_full_name_title": "Inserisci un nome completo valido",
    "required_block_time": "Il tempo di blocco è obbligatorio",
    "required_last_name_title": "Il cognome è obbligatorio",
    "valid_last_name_title": "Inserisci un cognome valido",
    "valid_mobile_number_title": "Inserisci un numero di cellulare valido",
    "required_mobile_number_title": "È obbligatorio il numero di cellulare",
    "required_already_mobile_number_title": "Il numero di cellulare è già obbligatorio",
    "valid_otp_title": "Inserisci un OTP valido",
    "valid_email_title": "Inserisci un indirizzo email valido",
    "required_email_title": "L'email è obbligatoria",
    "required_description_title": "La descrizione è obbligatoria",
    "required_description_500_characters": "Inserisci almeno 500 caratteri",
    "required_description_600_characters": "Inserisci massimo 600 caratteri",
    "domain_title": "Nome di dominio",
    "required_password_title": "La password è obbligatoria",
    "required_password_regex_title": "Deve contenere almeno 8 caratteri, almeno un carattere maiuscolo, un carattere minuscolo, un numero e un carattere maiuscolo speciale",
    "required_terms_title": "Si prega di accettare i termini e le condizioni",
    "required_note_title": "La nota è obbligatoria",
    "required_birthday_title": "La data di nascita è obbligatoria",
    "no_access_title": "Al momento non hai accesso. Contatta il tuo amministratore",
    "menu_online_open_txt": "Aprire",
    "loyalty_program_description": "😊 Trova il programma fedeltà ideale e personalizzalo in base al tuo brand!",
    "lp_list_title": "Elenco delle tessere fedeltà",
    "lp_n_title": "Nome della tessera fedeltà",
    "enter_details_title": "Inserisci i dettagli",
    "stamp_allowed_title": "Timbro consentito",
    "stamp_items_title": "Articoli di francobolli",
    "lpn_req_title": "È obbligatorio il nome della tessera fedeltà",
    "valid_lpn_title": "Inserisci un nome valido per la tessera fedeltà",
    "brand_color_error": "Il colore del marchio deve essere esattamente di 7 caratteri",
    "select_dark_brand_color_title": "Seleziona un colore del marchio valido",
    "your_brand_color_title": "Il colore del tuo marchio",
    "upload_logo_title": "Carica il logo",
    "locations_title": "Posizioni",
    "add_lp_title": "Crea programma",
    "location_error": "Seleziona le località",
    "pvs_title": "Quanti timbri può guadagnare un cliente per ogni visita?",
    "max_apd_title": "Qual è il numero massimo di francobolli che un cliente può guadagnare in un giorno?",
    "logo_error": "Il logo è obbligatorio",
    "expiry_title": "Imposta la scadenza per i premi",
    "add_more_rewards_title": "+ Aggiungi altri premi",
    "prev_step_1_title": "Mostra il codice QR per guadagnare punti e usufruire dei tuoi premi.",
    "delete_lp_title": "Questa tessera fedeltà verrà eliminata definitivamente.",
    "delete_user_record_title": "Questo record utente verrà eliminato definitivamente.",
    "redeem_title": "Riscattare",
    "lp_button_title": "Iscriviti alla tessera fedeltà",
    "lp_download_button_title": "Scarica la scheda",
    "step_0_title": "Iscriviti subito, deliziosi premi attendono i buongustai fedeli come te.",
    "step_1_title": "Ti sei unito",
    "congrats_step_title": "Ora hai diritto alla pizza gratis",
    "reward_title": "Ottieni premi ad ogni visita",
    "visit_title": "Visita e sblocca",
    "rewards_title": "Ricompense",
    "dummy_address": "3730 Frankfort Ave, Louisville Kentucky, Stati Uniti",
    "loyalty_qr_default_text": "Scansiona il codice QR, unisciti alla nostra tessera fedeltà e goditi i premi",
    "lp_earn_stamp_txt": "I clienti guadagnano dei timbri a ogni visita.",
    "lp_get_rewards_txt": "Ottieni premi ogni volta che visiti",
    "lp_1_time_visit_txt": "1 volta visita che riceverai",
    "lp_uppercase_stamp_text": "Timbro",
    "lp_lowercase_stamp_text": "timbro",
    "lp_txt": "Lealtà",
    "lp_per_visit_stamp_validation_msg": "Il timbro per visita deve essere inferiore al timbro massimo consentito al giorno",
    "lp_per_max_stamp_validation_msg": "Il massimo timbro consentito al giorno deve essere maggiore del timbro per visita",
    "lp_valid_value_msg": "Inserisci un valore valido",
    "lp_required_field_msg": "Questo campo è obbligatorio",
    "lp_stamp_required_msg": "È richiesto il timbro",
    "lp_valid_stamp_msg": "Inserisci un valore di timbro valido",
    "lp_reward_name_required_msg": "Il nome della ricompensa è obbligatorio",
    "lp_unique_stamp_msg": "Il valore del francobollo deve essere univoco",
    "lp_benefits": "Un programma fedeltà aumenta le visite ripetute e i ricavi, fornendo al contempo informazioni sui clienti per un marketing personalizzato",
    "lp_how_customer_txt": "Ecco come i tuoi clienti",
    "lp_punch_card_txt": "tessera fedeltà",
    "lp_look_txt": "guarderò! 😍",
    "lp_desc": "I clienti guadagneranno dei timbri per ogni visita e, una volta accumulati abbastanza timbri, potranno riscattare premi speciali.",
    "lp_edit": "Modificare",
    "lp_looks_good_txt": "Sembra buono",
    "lp_customer_view_instruction": "Ecco cosa vedrà il tuo cliente. Carica il tuo logo e imposta il tema colore",
    "lp_customer_earn_stamps": "Consenti ai clienti di guadagnare timbri a ogni visita e di gestire i loro premi",
    "lp_stamp_rewards_setup": "Imposta premi per l'accumulo di timbri, consenti ai clienti di ottenere premi per ogni timbro",
    "lp_loyalty_program_setup": "Assegna un nome al tuo programma fedeltà e imposta la tua posizione per usufruire di questo programma",
    "lp_prev_step1": "Mostra il codice QR per guadagnare punti e usufruire dei tuoi premi.",
    "lp_visit_1_time_txt": "Visita 1 volta e ottieni",
    "lp_get_free_txt": "Ottieni gratis",
    "lp_visit_allow_txt": "Visita e sblocca",
    "lp_rewards": "Ricompense",
    "repsT": "Ottieni più recensioni",
    "offSup": "Forniture per ufficio",
    "repsNxtV": "Alla tua prossima visita",
    "addRepT": "Aggiungi Ottieni più recensioni",
    "repEntrCpnTxt": "Inserisci il testo del coupon",
    "repEntrCpnTxtBtn": "Inserisci il testo del pulsante del coupon",
    "repMngNxtV": "alla tua prossima visita?",
    "repMngWldL": "Vorresti ottenere",
    "discount": "Sconto",
    "repDisTyp": "Tipo di sconto",
    "repNameT": "Dai un nome alla tua reputazione",
    "crtRepT": "Crea reputazione",
    "feedBackT": "Feedback",
    "confLocT": "Conferma la posizione",
    "businLocT": "Posizione aziendale",
    "addInfoT": "Informazioni aggiuntive",
    "repNReqT": "Ottieni più recensioni il nome è obbligatorio",
    "validRepNT": "Inserisci un nome di reputazione valido",
    "groupT": "Gruppo",
    "groupLDescTitleT": "Inserisci automaticamente i contatti in un gruppo",
    "groupLDescT": "Tutti i clienti che compilano il modulo verranno aggiunti al gruppo selezionato.",
    "que2": "Il cibo era buono?",
    "que3": "Il nostro servizio è stato cordiale?",
    "que4": "Il nostro servizio è stato veloce?",
    "addAdditionalT": "Desideri aggiungere altre informazioni?",
    "phNoMandT": "Il numero di telefono è obbligatorio!",
    "qrGenT": "QR generato",
    "repFDT": "Il tuo contributo aiuterà il nostro team a servirti meglio!",
    "letdiT": "Facciamolo",
    "tak15secT": "Ci vogliono 15 secondi",
    "defQue": "Vorresti ricevere di tanto in tanto fantastiche offerte e promozioni?",
    "defNQue": "Vorresti che qualcuno ti rispondesse?",
    "rateOnGoogleDesc": "Potresti essere così gentile da darci una valutazione su Google?",
    "mayBeNT": "Magari la prossima volta",
    "yesSureT": "Sì, certo",
    "valid_name": "Inserisci un nome valido",
    "nameReq": "Il nome è obbligatorio",
    "backToMainT": "Torna alla pagina principale",
    "attachCopT": "Vuoi incentivare il tuo cliente?",
    "thankYouT": "Grazie",
    "lftyT": "Non vediamo l'ora della tua prossima visita!",
    "repPreT": "Anteprima della reputazione",
    "offer_exclusive_discounts": "Offri sconti e promozioni esclusive per invogliare i tuoi clienti.",
    "couponT": "Buono",
    "locStepDesc": "Conferma la sede della tua attività",
    "createRepStepDesc": "Crea un modulo di reputazione personalizzato per gruppi",
    "feedbackStepDesc": "Set di domande di feedback dei clienti",
    "addInfoStepDesc": "Campi cliente e impostazioni incentivi",
    "FedbkkWDT": "Feedback con dati",
    "repAnlytT": "Analisi della reputazione",
    "todayT": "Oggi",
    "rep_last_7_days_t": "Ultimi 7 giorni",
    "last30DaysT": "Ultimi 30 giorni",
    "lastMT": "Il mese scorso",
    "custRangeT": "Intervallo personalizzato",
    "queWiseAnltT": "Analisi per domande",
    "atL1QueErrT": "Seleziona almeno una domanda",
    "deleteRepTitle": "Questa reputazione verrà eliminata definitivamente.",
    "incvCustT": "Sì, incentiva il mio cliente",
    "collAddInfoT": "Sì, vorrei raccogliere ulteriori informazioni",
    "totRewT": "Recensione totale",
    "totPosRewT": "Recensione positiva totale",
    "negFeedT": "Feedback negativo",
    "posFeedT": "Feedback positivo",
    "fineT": "Piccola stampa",
    "enterFineT": "Inserisci il testo in caratteri piccoli",
    "setThemeT": "Imposta il colore del tema",
    "que1T": "Domanda 1",
    "que2T": "Domanda 2",
    "que3T": "Domanda 3",
    "que4T": "Domanda 4",
    "entMailT": "Inserisci la tua email",
    "reputationQRDefTxt": "Scansiona il codice QR e condividi con noi il tuo prezioso feedback sulla tua esperienza.",
    "selUser": "Seleziona utente",
    "userT": "Utente",
    "subUReq": "Seleziona gli utenti",
    "updateLoc": "Aggiorna posizione",
    "leadGenT": "Generazione di lead",
    "displayT": "Display",
    "ratingT": "Valutazione",
    "rep_dashboard_negative_feedback": "Feedback negativo 0%",
    "rep_dashboard_position_feedback": "Feedback positivo 0%",
    "rep_dashboard_total_Feedback_txt": "Feedback totale",
    "rep_dashboard_anony_Feedback": "Feedback anonimo",
    "rep_dashboard_Feeedback_Data_txt": "Feedback con dati",
    "rep_dashboard_review_txt": "Ottieni più recensioni",
    "rep_dashboard_total_Review_txt": "Recensione totale",
    "rep_dashboard_total_positive_Review_txt": "Recensione positiva totale",
    "rep_dashboard_negative_feedback_txt": "Feedback negativo",
    "rep_dashboard_connection_txt": "Connessione",
    "rep_dashboard_question_wise_analytics_txt": "Analisi per domande",
    "rep_dashboard_date_label": "Seleziona Data",
    "rep_dashboard_custom_range_txt": "Intervallo personalizzato",
    "rep_tlt_min": "RAPPRESENTANTE",
    "emojis": "Emoji",
    "media_text": "Aggiungi media",
    "custom_field_text": "Inserisci campi personalizzati",
    "shortlink_text": "Aggiungi collegamento breve",
    "use_template_text": "Usa il modello",
    "banner_must_have": "Da avere assolutamente",
    "res_seamless": "Senza soluzione di continuità",
    "res_table_reservation": "Prenotazione del tavolo",
    "res_system": "Sistema",
    "res_text": "Prenotazioni",
    "md_easily": "Facilmente",
    "md_manage_customers": "Gestire i clienti",
    "md_visits_interactions": "visite e interazioni",
    "md_desc": "Rendi più semplice la ristorazione per i tuoi clienti con le prenotazioni dei tavoli in tempo reale. Gestisci le prenotazioni senza sforzo, riduci i tempi di attesa e assicurati un'esperienza fluida sia per il personale che per gli ospiti. Mantieni il tuo ristorante completamente prenotato mantenendo un servizio e una soddisfazione di prim'ordine.",
    "engage_convert": "Coinvolgi e converti SMS",
    "coupon_campaign": "e campagna coupon",
    "sms_read_rate": "I messaggi SMS vengono letti nel 98% dei casi",
    "real_time_promo": "Fornisci promozioni in tempo reale per un'azione immediata",
    "affordable_reach": "Un modo conveniente per raggiungere i clienti con un ROI solido",
    "stay_in_touch": "Rimani sempre in contatto con il tuo cliente",
    "bc_engage_customers": "Coinvolgi i clienti Converti SMS",
    "bc_coupon_campaign": "e campagna coupon",
    "bc_sms_read_rate": "I messaggi SMS vengono letti nel 98% dei casi",
    "bc_real_time_promo": "Fornisci promozioni in tempo reale per un'azione immediata",
    "bc_affordable_reach": "Un modo conveniente per raggiungere i clienti con un ROI solido",
    "bc_stay_in_touch": "Rimani sempre in contatto con il tuo cliente",
    "ar_automate_engagement": "Automatizza il coinvolgimento dei tuoi clienti",
    "ar_with": "con",
    "ar_with_auto_responders": "Risposte automatiche",
    "ar_personalized_messages": "Invia automaticamente messaggi personalizzati per i compleanni, dai il benvenuto ai nuovi clienti e riconnettiti con i clienti passati. Risparmia tempo mantenendo ogni interazione significativa e tempestiva.",
    "sq_custom_forms_tools": "Moduli e strumenti personalizzati - Semplifica",
    "sq_custom_forms_ci": "Interazioni con i clienti",
    "sq_smart_qr_desc": "Crea moduli personalizzati, raccogli firme elettroniche e sfrutta codici QR intelligenti per semplificare i tuoi processi aziendali. Adatta ogni strumento alle esigenze dei tuoi clienti e migliora l'esperienza utente.",
    "gnc_get_new_cus": "Ottieni nuovi clienti",
    "gnc_get_customers": "dai social media: veloce e facile!",
    "gnc_social_media_power": "Usa la potenza delle piattaforme social per attrarre nuovi clienti e indirizzare le vendite direttamente alla tua attività. Reindirizza gli ordini di consegna di terze parti direttamente al tuo negozio o alla piattaforma di ordinazione online per il massimo controllo e fatturato.",
    "wifi_connect_guests": "Connetti gli ospiti con",
    "wifi_smart": "Wi-Fi intelligente",
    "wifi_connect_grow": "& Fai crescere le tue intuizioni",
    "wifi_smart_wifi_desc": "Offri ai tuoi clienti una connessione Wi-Fi senza problemi tramite una schermata iniziale brandizzata, raccogliendo al contempo dati preziosi sui clienti per migliorare i tuoi sforzi di marketing e il coinvolgimento.",
    "wifi_create_splash_page": "Crea una pagina iniziale per il Wi-Fi degli ospiti",
    "instant_menu_create": "Crea il tuo",
    "instant_menu_text": "Menù istantaneo",
    "instant_menu_wm": "in pochi minuti",
    "instant_menu_desc": "Migliora l'esperienza dei clienti consentendo aggiornamenti in tempo reale, una facile personalizzazione e un maggiore coinvolgimento, rendendo più facile presentare le tue offerte e mantenere i tuoi contenuti aggiornati.",
    "instant_menu_create_digital_menu": "Crea un menu digitale",
    "loyalty_program_rewards": "Guadagnare premi",
    "loyalty_program_on_visits": "ad ogni visita",
    "loyalty_program_create": "Crea un programma fedeltà",
    "reputation_create": "Crea reputazione",
    "reputation_boost": "Potenzia il tuo",
    "reputation_text": "Reputazione",
    "reputation_reviews": "con altre recensioni!",
    "reputation_desc": "Raccogli feedback preziosi dai tuoi clienti per migliorare il tuo servizio e la tua reputazione. Condividi la tua esperienza e aiutaci a crescere: la tua recensione è importante!",
    "dashboard": "Pannello di controllo",
    "blast_campaign": "Campagna Blast",
    "blast_campaigns": "Campagne Blast",
    "blast_campaign_sub_title": "Lancia campagne pubblicitarie mirate per raggiungere tutti i tuoi clienti in pochi clic",
    "auto_responders": "Risposte automatiche",
    "auto_responder": "Risponditore automatico",
    "auto_responder_sub_title": "Promozioni basate su eventi facili da usare per i tuoi clienti",
    "smart_qr_form": "QR intelligente \/ Modulo",
    "get_new_customer": "Ottieni un nuovo cliente",
    "marketing": "Marketing",
    "clients": "Clienti",
    "group_data": "Dati dei gruppi",
    "clients_data": "Dati dei clienti",
    "account": "Account",
    "user_acc_info": "Informazioni sull'account utente",
    "current_plan": "Piano attuale",
    "payments": "Pagamenti",
    "location": "Posizione",
    "location_list": "Elenco delle posizioni",
    "users": "Utenti",
    "users_sub_title": "Elenco di tutti gli utenti e dei loro dettagli",
    "online_profile": "Profilo online",
    "change_password": "Cambiare la password",
    "menu": "Menu",
    "reseller_dashboard": "Dashboard del rivenditore",
    "companies": "Aziende",
    "plans_bought": "Piani acquistati",
    "agreement_t_c": "Termini e condizioni dell'accordo",
    "wifi_dashboard": "Pannello di controllo Wi-Fi",
    "hot_spot": "Punto caldo",
    "splash_pages": "Pagine Splash",
    "get_more_reviews": "Ottieni più recensioni",
    "office_supplies": "Forniture per ufficio",
    "reservation": "Prenotazione",
    "floor_table": "Pavimento e tavolo",
    "guest_smart_wifi": "WiFi intelligente per gli ospiti",
    "digital_signage": "Segnaletica digitale",
    "schedule_down": "Programmazione in ritardo",
    "business_settings": "Impostazioni aziendali",
    "sub_user_login": "Accesso sub-utente",
    "help_center": "Centro assistenza",
    "switch_to_hub": "Passa all'hub",
    "meeting_records": "verbali delle riunioni",
    "logout": "Esci",
    "no_access_text": "Al momento non hai accesso. Contatta l'amministratore.",
    "menu_item": "VOCE DI MENU",
    "menu_capital": "MENU",
    "good_day": "Buona giornata",
    "no_permission_text": "Non hai l'autorizzazione per eseguire questa azione!!",
    "enter_valid_pin": "Inserisci un PIN valido",
    "add_reservation": "Aggiungi prenotazione",
    "edit_reservation": "Modifica prenotazione",
    "per_req": "È richiesto il numero di persone",
    "req_block_time": "Il tempo di blocco è obbligatorio",
    "pending_status": "In attesa di",
    "booked_status": "Prenotato",
    "completed_status": "Completato",
    "cancelled_status": "Annullato",
    "reservation_slot": "Slot di prenotazione",
    "block_time": "Tempo di blocco",
    "slot_time": "Orario di prenotazione:  ",
    "template_name_req": "Il nome del modello è obbligatorio",
    "template_type_req": "Il tipo di modello è obbligatorio",
    "sms_template_req": "È richiesto il modello SMS",
    "group_req": "Il gruppo è obbligatorio",
    "valid_date_time": "Inserisci data e ora valide",
    "valid_group_name": "Inserisci un nome di gruppo valido",
    "reservation_mark_completed": "Questa prenotazione verrà contrassegnata come completata.",
    "customer_name": "Nome del cliente",
    "date_time": "Data Ora",
    "table_no": "Tabella n.",
    "floor": "Pavimento",
    "customer_name_placeholder": "nome_cliente",
    "date_time_placeholder": "data_ora",
    "table_no_placeholder": "numero_tabella",
    "floor_placeholder": "pavimento",
    "select_floor": "Seleziona piano",
    "select_table": "Seleziona tabella",
    "customer_name_macro": "%% nome_cliente %%",
    "date_time_macro": "%% data_ora %%",
    "table_no_macro": "%% numero_tabella %%",
    "floor_macro": "%% pavimento %%",
    "template_name": "Nome del modello",
    "login_with_4_digit_pin": "Accedi con il tuo PIN a 4 cifre",
    "login_now_to_access_latest_insights": "Accedi ora per accedere alle ultime novità",
    "mkt_BS_Title": "SMS di esplosione",
    "step_1": "Personalizza messaggio",
    "step_2": "Scegli il pubblico",
    "step_3": "Messaggio di pianificazione",
    "step_4": "Invia un messaggio",
    "step_5": "Gestisci messaggio",
    "bs_tab_title": "Riepilogo della campagna Blast",
    "default_message": "Ciao %% firstname %%, Sconto speciale sul brunch! Cena con noi oggi e ottieni uno sconto esclusivo del 10% sul tuo conto!",
    "bs_Title": "Scegli un tipo di campagna",
    "sms_title": "Messaggi di testo\/MMS",
    "reach_out_to_customer": "Raggiungi i clienti tramite messaggi di testo e multimediali.",
    "wp_text": "Messaggio di WhatsApp",
    "connect_with_wp": "Connettiti con i tuoi clienti tramite WhatsApp 💬",
    "send_discount_offers": "Invia offerte di sconto tramite SMS 📲",
    "send_coupons_via_sms": "Invia coupon via SMS ai tuoi clienti 🎟️",
    "env_Cap": "Offri sconti e promozioni esclusive per invogliare i tuoi clienti",
    "cmt_Caption": "Raggiungi i clienti tramite messaggi di testo e multimediali",
    "wp_caption": "Connettiti con i clienti tramite WhatsApp per una comunicazione fluida e diretta",
    "msg_Prev_Title": "Anteprima del messaggio",
    "cou_Prev_Title": "Anteprima del coupon",
    "blast_SMS": "Campagna SMS\/MMS",
    "bs_P_Msg": "In coda",
    "bs_S_Msg_C": "Fallito",
    "bs_F_Msg_Count": "Consegnato",
    "previous": "Precedente",
    "next_ST": "Prossimo",
    "next_S": "Passo successivo",
    "req_Temp_Name_Title": "Il nome del modello è obbligatorio",
    "req_Template_Tx_Title": "Il testo del modello è obbligatorio",
    "add_Temp_Title": "Aggiungi modello",
    "link_Desc": "Aggiungi un link a tua scelta in modo che i clienti possano prenotare con te tramite Facebook, Google o il tuo sito web.",
    "audience_Title": "Pubblico",
    "client_G1_Title": "Nuovi clienti",
    "client_G2_Title": "Clienti recenti",
    "client_G3_Title": "Clienti fedeli",
    "client_G4_Title": "Clienti scaduti",
    "client_G5_Title": "Clienti per data di connessione",
    "on_Which_Day": "In quale giorno",
    "on_Day": "Il giorno",
    "immediate_Desc": "Il tuo messaggio verrà inviato immediatamente, clicca su Avanti per iniziare.",
    "never_Desc": "Questa campagna è programmata per durare indefinitamente finché non la terminerai manualmente.",
    "end_Desc": "La campagna terminerà nella data che sceglierai qui.",
    "success_Message": "Campagna salvata con successo!",
    "send_Success_Message": "Campagna inviata con successo!",
    "msg_Name_Field_Title": "Titolo della campagna",
    "valid_campaign_title": "Inserisci un titolo valido per la campagna",
    "msg_Type_Title": "Tipo di messaggio",
    "sms_Desc": "1 Credito per SMS",
    "mms_Desc": "3 crediti per MMS",
    "client_GTO_1": "Giorno",
    "client_GTO_2": "Ore",
    "minutes": "Minuti",
    "client_GTO_4": "Settimana",
    "time_Option_1": "Giorno",
    "time_Option_2": "Ore",
    "c_Grp_Day_1": "90 giorni",
    "c_Grp_Day_2": "60 giorni",
    "c_Grp_Day_3": "30 giorni",
    "first": "Primo",
    "second_T": "Secondo",
    "third_T": "Terzo",
    "fourth_T": "Quarto",
    "fifth_T": "Quinto",
    "delete_BS_T": "Questa campagna verrà eliminata definitivamente.",
    "delete_MBS_T": "Queste campagne verranno eliminate definitivamente.",
    "cFT_1": "Telefono",
    "cFT_2": "Nome del contatto",
    "cFT_3": "Cognome del contatto",
    "cFT_4": "Contatto Email",
    "cFT_5": "Nota",
    "cFT_6": "Compleanno",
    "cFT_7": "Nome dell'azienda",
    "cF_Meta_Tag_1": "%% telefono %%",
    "cF_Meta_Tag_2": "%% nome di battesimo %%",
    "cF_Meta_Tag_3": "%% cognome %%",
    "cF_Meta_Tag_4": "%% e-mail %%",
    "cF_Meta_Tag_5": "%% nota %%",
    "cF_Meta_Tag_6": "%% compleanno %%",
    "cF_Meta_Tag_7": "%% nome_azienda %%",
    "cF_Name_1": "telefono",
    "cF_Name_2": "nome di battesimo",
    "cF_Name_3": "cognome",
    "cF_Name_4": "e-mail",
    "cF_Name_5": "nota",
    "cF_Name_6": "compleanno",
    "cF_Name_7": "nome_azienda",
    "all_clients": "Tutti i clienti",
    "aud_Type_2": "Gruppi di clienti",
    "aud_Type_3": "Gruppi di clienti",
    "aud_Type_T1": "Tutti i clienti",
    "aud_Type_T2": "Gruppi di clienti",
    "aud_Type_T3": "Gruppi di clienti",
    "new_clients": "Nuovi clienti",
    "client_G2": "Clienti recenti",
    "client_G3": "Clienti fedeli",
    "client_G4": "Clienti scaduti",
    "client_G5": "Clienti per data di connessione",
    "never_text": "Mai",
    "ending_on": "Termina il",
    "send_T2": "Programmato",
    "send_Type_3": "Ricorrente",
    "daily": "Quotidiano",
    "weekly_T": "Settimanale",
    "monthly_T": "Mensile",
    "yearly_T": "Annuale",
    "each": "Ogni",
    "on_the": "Sul",
    "monday": "Lunedi",
    "exclusive_offer": "Offerta esclusiva per te",
    "redeem_now": "Riscatta ora",
    "redeem_with_cashier": "Si prega di riscattare con la cassa",
    "lg_Day_2": "Martedì",
    "lg_Day_3": "Mercoledì",
    "lg_Day_4": "Giovedì",
    "lg_Day_5": "Venerdì",
    "lg_Day_6": "Sabato",
    "lg_Day_7": "Domenica",
    "msg_Pre_T": "Domino",
    "cA_Edit_T1": "Tutto",
    "cA_Edit_T2": "Incluso",
    "cA_Edit_T3": "Escluso",
    "SMS": "sms",
    "MMS": "MMS",
    "usd_T": "Dollaro statunitense",
    "cad_T": "CAD",
    "msg": "Messaggio",
    "which_Day": "Quali giorni",
    "end_Date_Tx": "Data di fine",
    "sDate_Err": "La data di inizio deve essere maggiore o uguale a oggi",
    "eDate_Err": "La data di fine deve essere successiva alla data di inizio",
    "start_Date_Req": "La data di inizio è obbligatoria",
    "end_Date_Req": "La data di fine è obbligatoria",
    "time_req": "Il tempo è necessario",
    "client_GT1": "Clienti abbonati negli ultimi",
    "client_GT2": "Clienti con connessione nell'ultimo",
    "client_GT3": "Clienti con almeno",
    "client_GT4": "con l'ultimo",
    "client_GT5": "che non è tornato nell'ultimo",
    "dummy_Phone_No": "+91987-654-3210",
    "test_T": "Test",
    "dummy_Birth_Date": "01-01-2001",
    "image_Req": "È richiesto il supporto o l'URL",
    "time_Title": "Tempo",
    "start_date": "Data di inizio",
    "end_date": "Data di fine",
    "auto_Send_T": "Invia automaticamente ai clienti",
    "add_Media": "Aggiungi media",
    "in_Title": "In",
    "c_Temp_T": "Modello di campagna",
    "temp_NT": "Nome del modello",
    "type_T": "Tipo",
    "select_template_type": "Seleziona il tipo di modello",
    "sel_Temp_T": "Seleziona il tipo di modello",
    "sms_temp_t": "Modello SMS",
    "temp_T": "Testo modello",
    "default_Msg": "Ciao %% firstname %%, Sconto speciale sul brunch! Cena con noi oggi e ottieni uno sconto esclusivo del 10% sul tuo conto!",
    "refill_T": "Ricarica",
    "avl_Credit": "Crediti disponibili",
    "req_Credit": "Totale crediti richiesti",
    "rem_Credit": "Credito rimanente",
    "refill_Credit": "Crediti da ricaricare",
    "clients_Req": "Seleziona almeno un cliente",
    "subclient_Req": "Alcuni clienti selezionati non sono abbonati.",
    "refill_Desc": "Si prega di ricaricare i crediti richiesti",
    "url_Regex": "^((http|https):\/\/).....+$",
    "sd_Invalid": "La data di inizio non è valida",
    "ed_Invalid": "La data di fine non è valida",
    "img_Url_Invalid": "URL dell'immagine non valido",
    "time_Invalid": "Il tempo non è valido",
    "time_And_Date_Invalid": "Inserisci una data e un'ora valide",
    "time_Invalid_Bfr": "Seleziona un orario almeno 5 minuti dopo l'orario corrente",
    "sod_Req": "È obbligatorio inviare il giorno",
    "add_Card": "Aggiungi una nuova carta",
    "sm_D_Rep_Title": "Rapporto di consegna SMS\/MMS",
    "send_Now_T": "Invia ora",
    "schd_Now_T": "Pianifica SMS",
    "test_SMS_T": "Invia SMS di prova",
    "save_AD_T": "Salva come bozza",
    "back_TE_T": "Torna a Modifica",
    "reset_tex": "Reset",
    "update_tex": "Aggiornamento",
    "dum_msg1": "Per visualizzare il nome del cliente, utilizzare %% customer_name %% nel modello",
    "dum_msg2": "Per visualizzare l'orario della prenotazione, utilizzare %% date_time %% nel modello",
    "dum_msg3": "Per visualizzare il numero della tabella, utilizzare %% table_no %% nel modello",
    "embedded_link": "Link incorporato",
    "img_title": "Immagine",
    "img_input_text": "Testo:",
    "img_join_text": " per unirti alla nostra lista",
    "img_copy_text": "Copia",
    "img_not_found": "Immagine generata non trovata!!",
    "or_text": "O",
    "web_signup_image_content": "Inviando questo modulo e registrandoti tramite SMS, acconsenti a ricevere SMS di marketing (come codici promozionali e promemoria). Potrebbero essere applicate tariffe per messaggi e dati. La frequenza dei messaggi varia. Puoi annullare l'iscrizione in qualsiasi momento rispondendo STOP",
    "img_download_success": "Immagine scaricata correttamente",
    "embedded_link_copied": "Link incorporato copiato correttamente",
    "media_url_required": "È richiesto il supporto o l'URL",
    "invalid_image_url": "URL dell'immagine non valido",
    "invalid_file": "Il file non è valido",
    "image_error_1mb": "Inserisci un'immagine di dimensioni inferiori a 1 MB",
    "image_error_2_5mb": "Inserisci un'immagine di dimensioni inferiori a 2,5 MB",
    "image_error_3mb": "Inserisci un'immagine di dimensioni inferiori a 3 MB",
    "change_title": "Modifica",
    "drop_file_text": "Trascina il file qui o clicca per caricarlo",
    "apply_text": "Fare domanda a",
    "search_here": "Cerca qui",
    "update_status": "Aggiorna stato",
    "reservation_deleted_permanently": "Questa prenotazione verrà eliminata definitivamente.",
    "table_text": "Tabelle",
    "add_table_button": "Aggiungi nuova tabella",
    "add_table_text": "Aggiungi tabella",
    "edit_table_text": "Modifica tabella",
    "table_delete_text": "Questa tabella verrà eliminata definitivamente.",
    "multiple_table_delete_text": "Queste tabelle verranno eliminate definitivamente.",
    "table_error_name": "Inserisci il nome della tabella",
    "table_error_invalid_name": "Inserisci un nome di tabella valido",
    "table_error_floor": "Seleziona il piano",
    "table_error_select": "Seleziona la tabella",
    "table_capacity_text": "Capacità del tavolo",
    "capacity_text": "Capacità",
    "table_occasion_text": "Trova il tuo tavolo per ogni occasione",
    "table_name_text": "Nome della tabella",
    "table_capacity_error": "È richiesta la capacità del tavolo",
    "cancel_text": "Cancellare",
    "submit_text": "Invia",
    "select_valid_date": "Seleziona una data valida",
    "select_valid_time": "Seleziona un orario valido",
    "valid_contact_number": "Inserisci un numero di contatto valido",
    "date_req": "La data è obbligatoria",
    "date_error_later_than_today": "La data deve essere successiva o uguale alla data odierna",
    "time_error_later_than_5_minutes": "Seleziona un orario almeno 5 minuti dopo l'orario corrente",
    "number_person_req": "È richiesto il numero di persone",
    "contact_no_req": "Il numero di contatto è obbligatorio",
    "contact_no": "Numero di contatto",
    "select_time": "Seleziona Ora",
    "for_how_many_person": "Per quante persone?",
    "f_name": "Nome di battesimo",
    "l_name": "Cognome",
    "business_name": "Nome dell'azienda",
    "business_no": "Numero aziendale",
    "no_for_sign_in": "Questo numero viene utilizzato per l'accesso",
    "business_email": "E-mail aziendale",
    "notes_tex": "Appunti",
    "floor_deleted_permanently": "Questo piano verrà eliminato definitivamente.",
    "add_floor": "Aggiungi piano",
    "edit_floor": "Modifica piano",
    "name_text": "Nome",
    "mobile_no": "Numero di cellulare",
    "person_text": "Persona",
    "date_and_time": "Data e ora",
    "actions_text": "Azioni",
    "extra_text": "Extra",
    "floor_name_req": "Il nome del piano è obbligatorio",
    "floor_name": "Nome del piano",
    "status_text": "Stato",
    "category_status_req": "Lo stato della categoria è obbligatorio",
    "table_deleted_permanently": "Questa tabella verrà eliminata definitivamente.",
    "tables_deleted_permanently": "Queste tabelle verranno eliminate definitivamente.",
    "back_to_home": "Torna a casa",
    "link_copied_text": "Link copiato negli appunti",
    "cust_dash_head_to_title": "A",
    "cust_dash_head_location_title": "Posizione",
    "select_location_title": "Seleziona la posizione",
    "all_locations_label": "tutte_le_posizioni",
    "rep_customer_feedback_analytics": "😊 Tieni traccia del feedback dei tuoi clienti con analisi in tempo reale",
    "rep_customer_txt": "Clienti",
    "rep_delete_title": "Questa reputazione verrà eliminata definitivamente.",
    "rep_qr_def_txt": "Scansiona il codice QR e condividi con noi il tuo prezioso feedback sulla tua esperienza.",
    "delete_title": "Eliminare",
    "user_list_title": "Elenco dei clienti",
    "os_nfc_txt": "Carte NFC",
    "os_sign_holder_txt": "Supporto per cartello",
    "os_store_Sign_holder_txt": "Portacartelli per negozi",
    "StaT": "Statistiche",
    "AllgT": "Tutti i gruppi",
    "FavT": "Preferito",
    "MostActT": "Più attivo",
    "grT": "Nome del gruppo",
    "keywT": "Parole chiave",
    "exSubT": "Messaggio per l'abbonato esistente",
    "ArchT": "Archiviato",
    "gNotiSms": "Avvisami tramite SMS di ogni nuovo contatto",
    "gNotiEmail": "Avvisami di ogni nuovo contatto tramite e-mail",
    "reqGName": "Il nome del gruppo è obbligatorio",
    "validGN": "Inserisci un nome di gruppo valido",
    "valid_phone_no": "Inserisci un numero di telefono valido",
    "phone_no_req": "Il numero di telefono è obbligatorio",
    "required_message_text": "Il messaggio è obbligatorio",
    "required_discount_text": "È richiesto lo sconto",
    "less_than_discount": "L'importo dello sconto deve essere inferiore a 5000",
    "discount_percentage_invalid": "La percentuale di sconto deve essere inferiore o uguale a 100",
    "discount_type_req": "Il tipo di sconto è obbligatorio",
    "discount_text_req": "Il testo del coupon è obbligatorio",
    "reqContactNo": "Il numero di contatto è obbligatorio",
    "reqMsgNT": "Il titolo della campagna è obbligatorio",
    "reqLinkT": "Il collegamento è obbligatorio",
    "delMGT": "Questo gruppo verrà eliminato definitivamente.",
    "defMemMsg": "Scrivi qui il tuo messaggio",
    "add_cust_to_grp_title": "Aggiungi cliente al gruppo",
    "group_title": "Gruppi",
    "group_create_title": "Crea gruppo",
    "group_name_txt": "Nome del gruppo",
    "group_table_keyword_title": "Parola chiave",
    "actions_title": "Azioni",
    "clients_title": "Clienti",
    "send_title": "Inviare",
    "qr_title": "Domanda",
    "delete_group_txt": "Questo gruppo verrà eliminato definitivamente.",
    "delete_groups_txt": "Questo gruppo verrà eliminato definitivamente.",
    "delete_client_title": "Questo client verrà eliminato definitivamente.",
    "delete_clients_title": "Questi client verranno eliminati definitivamente.",
    "delete_multiple_title": "Eliminazione multipla",
    "wel_sms_mms": "Inviare SMS\/MMS di benvenuto?",
    "key_words_title": "Parole chiave",
    "srch_plch_txt": "Cerca qui",
    "req_location_id_title": "La posizione è obbligatoria",
    "create_text": "Creare",
    "view_text": "Visualizzazione",
    "immediately": "Immediatamente",
    "business_name_req": "Il nome dell'azienda è obbligatorio",
    "business_no_req": "Il numero aziendale è obbligatorio",
    "valid_business_name": "Inserisci un nome commerciale valido",
    "valid_business_no": "Inserisci un numero aziendale valido",
    "address_req": "L'indirizzo è obbligatorio",
    "valid_address": "Seleziona un indirizzo valido",
    "time_zone_req": "Il fuso orario è obbligatorio",
    "image_req": "Inserisci un'immagine inferiore a 2,5 MB",
    "valid_file": "Il file non è valido.",
    "logo": "Logo",
    "time_zone": "Fuso orario",
    "save": "Salva",
    "account_type_req": "Il tipo di account è obbligatorio",
    "gst_no_req": "È richiesto il numero GST",
    "gst_no_valid": "Inserisci un numero GST valido",
    "set_up_payments": "Imposta i pagamenti",
    "billing_details": "Inserisci i tuoi dati di fatturazione",
    "billing_details_desc": "I tuoi dati di fatturazione saranno visualizzati sulla fattura mensile di menuonline.",
    "account_type": "Tipo di account",
    "select_account_type": "Seleziona il tipo di account",
    "gst_no": "Numero GST",
    "transaction_details": "Dettagli della transazione",
    "payment_method": "Metodo di pagamento",
    "billing_period": "Periodo di fatturazione",
    "paid_by": "Pagato da",
    "download": "Scaricamento",
    "pay_now": "Paga ora",
    "pull_down_refresh": "Tirare verso il basso per aggiornare",
    "release_refresh": "Rilascia per aggiornare",
    "billing_details_text": "Dettagli di fatturazione",
    "payment_methods": "Metodi di pagamento",
    "invoice": "Fattura",
    "invoice_to": "Fattura a :",
    "issue_date": "Data di emissione",
    "due_date": "Scadenza",
    "amount_due": "Importo dovuto",
    "charges": "Addebiti",
    "sub_total_capitalize": "Subtotale",
    "hst": "Alta velocità",
    "grand_total": "Somma totale",
    "invoice_generated_on": "Fattura generata il",
    "contact_us": "Contattaci",
    "invoice_issue": "Se hai esaminato la tua bolletta e hai ancora domande",
    "call": "Chiamata",
    "send_sms": "Invia SMS",
    "payment_success": "Il pagamento è avvenuto con successo",
    "edit_credit_card": "Modifica carta di credito",
    "add_credit_card": "Aggiungi carta di credito",
    "modify_card_info": "Modifica le informazioni della tua carta",
    "enter_card_info": "Inserisci le informazioni della tua carta",
    "account_no_req": "Il numero di conto è obbligatorio",
    "card_name_req": "Il nome sulla carta è obbligatorio",
    "expiry_date_req": "La data di scadenza è obbligatoria",
    "valid_expiry_date": "Inserisci una data di scadenza valida",
    "valid_cvv": "Inserisci un CVV valido",
    "cvv_req": "È richiesto il CVV",
    "card_no": "Numero della carta",
    "name_of_card": "Nome sulla carta",
    "expiry": "Scadenza",
    "mm_text": "MM\/AA",
    "cvv": "Codice CVV",
    "set_as_default": "Imposta come predefinito",
    "add_new_card": "Aggiungi nuova carta",
    "all_credit_card": "Tutte le tue carte di credito",
    "fastest_checkout": "Check-out più veloce con piattaforma sicura e affidabile, scelta da un vasto gruppo di aziende",
    "coupon_button_req": "Il testo del pulsante del coupon è obbligatorio",
    "max19_characters_allowed": "Sono consentiti al massimo 19 caratteri",
    "fine_print_text_req": "È richiesto il testo in caratteri piccoli",
    "clients_req": "Seleziona almeno un cliente",
    "client_groups": "Gruppi di clienti",
    "day_text": "Giorno",
    "valid_days": "Il valore del giorno è troppo grande, inserisci giorni validi",
    "hours_text": "Ore",
    "enter_valid_hours": "Il valore delle ore è troppo grande, inserisci ore valide",
    "enter_valid_min": "Il valore dei minuti è troppo grande, inserisci minuti validi",
    "clients_with_connection_required": "Sono richiesti clienti con connessione nell'ultimo",
    "connection_required": "È richiesta la connessione",
    "connection_value_too_large": "Il valore delle connessioni è troppo grande, inserisci una connessione valida",
    "minutes_required": "Minuti richiesti",
    "clients_with_at_least_connection_required": "Sono richiesti client con almeno una connessione",
    "last_minutes_req": "Con gli ultimi minuti è necessario",
    "clients_not_returned_required": "Chi non è rientrato negli ultimi minuti è tenuto",
    "not_return_in_last_minutes_invalid": "Il mancato ritorno negli ultimi minuti deve essere inferiore agli ultimi minuti.",
    "customerGroups": "Gruppi di clienti",
    "select_customer_group": "Seleziona il gruppo clienti",
    "location_required": "La posizione è obbligatoria",
    "start_date_required": "La data di inizio è obbligatoria",
    "start_date_invalid": "La data di inizio non è valida",
    "start_date_invalid_error": "La data di inizio deve essere maggiore o uguale a oggi",
    "recurring": "Ricorrente",
    "end_date_required": "La data di fine è obbligatoria",
    "end_date_invalid": "La data di fine non è valida",
    "end_date_invalid_error": "La data di fine deve essere successiva alla data di inizio",
    "time_invalid": "Il tempo non è valido",
    "monthly_text": "Mensile",
    "send_day_req": "È obbligatorio inviare il giorno",
    "loyal_clients": "Clienti fedeli",
    "recent_clients": "Clienti recenti",
    "lapsed_clients": "Clienti scaduti",
    "clients_connection_date": "Clienti per data di connessione",
    "scheduled_text": "Programmato",
    "weekly": "Settimanale",
    "selected_clients_not_subscriber": "Alcuni clienti selezionati non sono abbonati.",
    "message_preview_title": "Anteprima del messaggio",
    "coupon_preview_title": "Anteprima del coupon",
    "form_text": "Modulo",
    "preview_text": "Anteprima",
    "next_text": "Prossimo",
    "send_test_SMS": "Invia SMS di prova",
    "save_draft": "Salva come bozza",
    "back_to_edit": "Torna a Modifica",
    "select_payment_method": "Seleziona il metodo di pagamento",
    "schedule_SMS": "Pianifica SMS",
    "send_now": "Invia ora",
    "get_more_ratings": "Ottieni più valutazioni",
    "overview": "Panoramica",
    "reset_campaign": "Reimposta campagna",
    "permissions": "Permessi",
    "location_name": "Nome della posizione",
    "phone_no": "Numero di telefono",
    "location_email_address": "Posizione Indirizzo e-mail",
    "located_business": "Dove si trova la tua attività?",
    "business_logo": "Logo aziendale",
    "congratulations": "Congratulazioni",
    "almost_done": "Hai quasi finito",
    "publish": "Pubblicare",
    "about_your_business": "Informazioni sulla tua attività",
    "add_your_location": "Aggiungi la tua posizione",
    "publish_location": "Pubblica posizione",
    "location_name_req": "Il nome della posizione è obbligatorio",
    "valid_location_name": "Inserisci un nome di posizione valido",
    "business_logo_req": "È richiesto il logo aziendale",
    "please_accept_terms": "Si prega di accettare i termini e le condizioni",
    "add_new_location": "Aggiungi nuova posizione",
    "edit_location": "Modifica posizione",
    "add_location": "Aggiungi posizione",
    "existing_msg_subscriber_txt": "Messaggio per l'abbonato esistente",
    "msg_capitalize_txt": "Messaggio",
    "group_noti_sms": "Avvisami tramite SMS di ogni nuovo contatto",
    "group_noti_email": "Avvisami di ogni nuovo contatto tramite e-mail",
    "group_member_msg": "Sembra che tu faccia già parte del nostro gruppo!!",
    "group_mend_msg": "STOP per terminare. HELP per assistenza. Potrebbero essere applicate tariffe per SMS e dati",
    "Disclaimer_title": "Testo di esclusione di responsabilità: ",
    "group_def_msg": "Siamo online ora! Dai un'occhiata al nostro menu dei servizi e prenota il tuo prossimo appuntamento oggi stesso.",
    "required_msg_txt": "Il messaggio è obbligatorio",
    "required_Key_txt": "Le parole chiave sono obbligatorie",
    "required_mem_msg": "Il messaggio dell'abbonato è obbligatorio",
    "client_list_title": "Elenco clienti",
    "add_contact_txt": "Aggiungi contatto",
    "delete_all_clients_txt": "Elimina tutti i clienti",
    "delete_all_clients_msg": "Vuoi davvero eliminare tutti i client? Non possono essere recuperati.",
    "delete_all_txt": "Elimina tutto",
    "timeline_title": "Linea temporale",
    "unsubscribe_title": "Annulla iscrizione",
    "subscribe_title": "Iscriviti",
    "unsubscribe_confirm_msg": "Vuoi contrassegnare questo cliente come cancellato dall'iscrizione?",
    "subscribe_confirm_msg": "Vuoi contrassegnare questo cliente come abbonato?",
    "no_title": "NO",
    "yes_title": "SÌ",
    "client_name_title": "Nome del cliente",
    "source_title": "Fonte",
    "contact_file_Req": "Il file dei contatti è obbligatorio",
    "req_title": "Necessario",
    "opt_in_req": "I contatti importati devono essere opt-in al 100%",
    "image_invalid_error": "Il file non è valido.",
    "import_contacts_msg": "Importa contatti",
    "csv_upload_title": "File CSV dei contatti da caricare *",
    "csv_file_desc": "Carica solo il file csv - colonne del file csv - Nome, Cognome, ID e-mail, Numero di cellulare, Sesso, Data di nascita (MM\/GG\/AAAA) - senza spazi, trattini o parentesi nel numero di cellulare",
    "to_download_title": "Per scaricare il file demo clicca qui",
    "contains_header_title": "Sì, questo file contiene intestazioni",
    "opt_in_100_txt": "Sì, questi contatti importati sono opt-in al 100%",
    "DisT": "Testo di esclusione di responsabilità: ",
    "gMendMsg": "STOP per terminare. HELP per assistenza. Potrebbero essere applicate tariffe per SMS e dati",
    "reqKeyT": "Le parole chiave sono obbligatorie",
    "reqMemMsg": "Il messaggio dell'abbonato è obbligatorio",
    "reqMsgT": "Il messaggio è obbligatorio",
    "gMemberMsg": "Sembra che tu faccia già parte del nostro gruppo!!",
    "gdefMsg": "Siamo online ora! Dai un'occhiata al nostro menu dei servizi e prenota il tuo prossimo appuntamento oggi stesso.",
    "next_title": "Prossimo",
    "male_title": "Maschio",
    "male_val": "maschio",
    "female_title": "Femmina",
    "female_val": "femmina",
    "others_txt": "Altri",
    "others_val": "altri",
    "validBirthdate": "Inserisci una data di nascita valida",
    "valid_phone_no_title": "Inserisci un numero di telefono valido",
    "required_phone_no_title": "Il numero di telefono è obbligatorio",
    "add_new_client_txt": "Aggiungi un nuovo cliente",
    "update_client_txt": "Aggiorna il client",
    "phone_num_text": "Numero di telefono",
    "dob_title": "Data di nascita",
    "select_gender_title": "Seleziona il genere",
    "timelineTitle": "Linea temporale",
    "confirm_location": "Conferma la posizione",
    "feedback_title": "Feedback",
    "rep_question_1": "L'attività era pulita?",
    "rep_que_2": "Il cibo era buono?",
    "rep_que_3": "Il nostro servizio è stato cordiale?",
    "rep_que_4": "Il nostro servizio è stato veloce?",
    "business_location_req_title": "È richiesta la posizione aziendale",
    "valid_location_err_txt": "Seleziona un indirizzo valido",
    "rep_management_title": "Ottieni più recensioni Gestione",
    "rep_about_desc": "La reputazione ti aiuta ad aumentare la valutazione di Google chiedendo feedback ai tuoi clienti",
    "preview_title": "Anteprima",
    "rep_preview_txt": "Anteprima della reputazione",
    "back_title": "Indietro",
    "fine_print_txt": "Si prega di riscattare con la cassa",
    "redeem_me_title": "Riscattami",
    "rep_nxt_visit_txt": "Alla tua prossima visita",
    "device_type": "Tipo di dispositivo utilizzato",
    "connection_method": "Metodo di connessione",
    "peak_days": "Giorni di punta",
    "peak_hours": "Ore di punta",
    "guest_by_day": "Ospite di giorno",
    "guest_visit": "Visita dell'ospite",
    "connection": "Connessione",
    "connection_duration": "Durata della connessione",
    "guest_visit_1": "1 volta",
    "guest_visit_2": "2 volte",
    "guest_visit_3_5": "3-5 volte",
    "guest_visit_6_10": "6-10 volte",
    "guest_visit_11_25": "11-25 volte",
    "guest_visit_26_100": "26-100 volte",
    "guest_visit_100_plus": "100+ volte",
    "device_android_total": "Android totale",
    "device_android": "Android",
    "device_desktop": "Scrivania",
    "device_ios": "Ios",
    "device_ios_total": "Totale Ios",
    "device_desktop_total": "Totale Desktop",
    "connection_duration_10": "<=10 minuti",
    "connection_duration_20": "11-20 minuti",
    "connection_duration_30": "21-30 minuti",
    "connection_duration_40": "31-45 minuti",
    "connection_duration_60": "46-60 minuti",
    "connection_method_email": "E-mail",
    "connection_method_sms": "sms",
    "connection_method_google": "Google",
    "connection_method_facebook": "Fai clic su Mi Piace",
    "age_category_1": "<18",
    "age_category_2": "18-24",
    "age_category_3": "25-34",
    "age_category_4": "35-44",
    "age_category_5": "45-54",
    "age_category_6": "55+",
    "all_guest_txt": "Tutti gli ospiti",
    "new_Guest_txt": "Nuovo ospite",
    "connections_txt": "Connessioni",
    "hotspot": "Punto caldo",
    "hotspot_list": "Elenco degli hotspot",
    "add_new_hotspot": "Aggiungi nuovo hotspot",
    "hotspot_information": "Informazioni hotspot",
    "edit_details_button": "Modifica dettagli",
    "wifi_info_message": "Connettiti e goditi il WiFi gratuito",
    "connection_message": "Ottimo, sei stato connesso a ",
    "connection_message_suffix": " Wifi",
    "wifi": "Wifi",
    "login_to_access": "Accedi per ottenere l'accesso",
    "verification_code": "Codice di verifica",
    "verification_code_message": "Si prega di digitare il codice di verifica inviato a ",
    "wifi_user_email": "adamo@gmail.com",
    "wifi_label": "Wifi",
    "your_name": "A proposito, come ti chiami?",
    "your_birthdate": "Puoi dirci qual è la tua data di nascita?",
    "request_guest_wifi_name": "Inserisci il nome del Wi-Fi ospite",
    "request_device_key": "Inserisci la chiave del dispositivo",
    "request_maximum_internet_access_length": "Seleziona la lunghezza massima di accesso a Internet",
    "mac_address": "Indirizzo MAC",
    "device_port": "Porta del dispositivo",
    "hardware": "Hardware",
    "current_uptime": "Tempo di attività attuale",
    "nearby_devices": "Dispositivi nelle vicinanze",
    "firmware": "Firmware",
    "who_are_you": "Chi sei?",
    "where_to_contact_you": "Come possiamo contattarti?",
    "your_area_code": "Qual è il tuo prefisso telefonico?",
    "connected": "Collegato",
    "delete_hotspot_message": "Questo hotspot verrà eliminato definitivamente.",
    "delete_multiple_hotspots_message": "Questi hotspot verranno eliminati definitivamente.",
    "speed_error": "La velocità dovrebbe essere almeno 0,01",
    "speed_max_error": "Inserisci un valore fino a 1024 per una velocità illimitata oppure seleziona un valore inferiore nell'intervallo supportato",
    "device_ssid": "SSID del dispositivo",
    "device_ssid_two": "Dispositivo SSID Due",
    "device_ssid_two_wpa": "Dispositivo SSID Due WPA",
    "device_key": "Chiave del dispositivo",
    "select_location": "Seleziona la posizione",
    "select_maximum_internet_access_length": "Seleziona la lunghezza massima di accesso a Internet",
    "download_speed": "Velocità di download",
    "upload_speed": "Velocità di caricamento",
    "network_length_1": "15 minuti",
    "network_length_2": "30 minuti",
    "network_length_3": "45 minuti",
    "network_length_4": "1 ora",
    "network_length_5": "2 ore",
    "network_length_6": "4 ore",
    "network_length_7": "6 ore",
    "network_length_8": "8 ore",
    "network_length_9": "10 ore",
    "network_length_10": "12 ore",
    "employee_wifi_name": "Nome Wi-Fi dipendente",
    "employee_wifi_password": "Password Wi-Fi per dipendenti",
    "guest_wifi_name": "Nome Wi-Fi ospite",
    "menu_other_products_txt": "Altri prodotti | Menu Online",
    "menu_home_text": "Home | Menù Online",
    "whatsapp_title": "WhatsApp",
    "select_campaign_type": "Scegli un tipo di campagna",
    "select_readymade_templates": "Scegli tra modelli già pronti o creane uno tuo",
    "campaign_title_required": "Il titolo della campagna è obbligatorio",
    "type_here": "Digita qui...",
    "location_permission_req": "È richiesta l'autorizzazione alla posizione",
    "platform_permission_req": "È richiesta l'autorizzazione della piattaforma",
    "profile_picture": "Immagine del profilo",
    "click_to_upload": "Clicca per caricare",
    "location_permission": "Autorizzazione alla posizione",
    "pin": "Spillo",
    "platform_permission": "Autorizzazione della piattaforma",
    "set_pin": "Imposta PIN",
    "list_of_users": "Elenco degli utenti",
    "create_user": "Crea utente",
    "terms_and_condition_req": "Sono richiesti i termini e le condizioni",
    "terms_and_conditions": "Termini e Condizioni",
    "recommended": "RACCOMANDATO",
    "mo": "\/mese",
    "unsubscribe": "Annulla iscrizione",
    "cancel_subscription": "Sei sicuro di voler annullare l'abbonamento?",
    "upgrade": "Aggiornamento",
    "plan_upgraded_successfully": "Il piano è stato aggiornato con successo!",
    "menu_online": "Menu online",
    "support": "Supporto",
    "wifiC": "WIFI",
    "billing": "Fatturazione",
    "please_upgrade": "Effettua l'aggiornamento per usufruire di tutte le funzionalità",
    "you_have_subscribed": "Ti sei iscritto al ",
    "plan": "piano",
    "of_text": "Di",
    "days": "Giorni",
    "remaining_days_until_plan": " Giorni rimanenti prima che il tuo piano richieda un aggiornamento",
    "manage_your_screens": "Gestisci i tuoi schermi",
    "screens_you_save": "Schermi che hai",
    "add_more_screens": "Aggiungi altri schermi",
    "addition_screen": "Schermata di aggiunta",
    "per_screen": "$ per schermo",
    "per_box": "$ per scatola",
    "shipping_changes": "Spese di spedizione",
    "upto4_boxes": "fino a 4 scatole ",
    "charge_now": "Riceverai la carica ora",
    "updated_reoccuring": "Il tuo aggiornamento ricorrente sarà",
    "current_reoccuring": "Ricorrente attuale: ",
    "no_text": "NO",
    "android_box": "Scatola Android",
    "old_password_req": "È richiesta la vecchia password",
    "new_password_req": "È richiesta una nuova password",
    "confirm_password_req": "È richiesta la conferma della password",
    "password_do_not_match": "Le password non corrispondono",
    "old_password": "Vecchia password",
    "new_password": "Nuova password",
    "confirm_password": "Conferma password",
    "copy_working_hours": "Copiare questi orari di lavoro per tutti i giorni?",
    "yes_copy": "Sì, copia",
    "closed": "Chiuso",
    "opening_time": "Orario di apertura",
    "closing_time": "Orario di chiusura",
    "description": "Descrizione",
    "file_exist": "il file esiste già, seleziona un altro file",
    "bussiness_images": "Immagini aziendali",
    "display_info_on_market_place": "Desideri visualizzare le informazioni seguenti sul tuo Marketplace?",
    "profile_ready": "Il tuo profilo è pronto!",
    "client_book_with_online": "I clienti ora possono prenotare con te online. Condividi il link qui sotto per iniziare.",
    "copy_link": "Copia collegamento",
    "see_your_profile": "Guarda il tuo profilo",
    "ok_got_it": "Ok, capito",
    "preview_profile": "Visualizza in anteprima il tuo profilo e guarda come apparirebbe.",
    "opening_hours": "Orari di apertura",
    "display_data": "Visualizza i dati",
    "manage_profile": "Gestisci profilo",
    "phone_req": "Il telefono è obbligatorio",
    "market_place_img_req": "Sono richieste immagini del mercato",
    "add_new_market_place": "Aggiungi nuovo MarketPlace",
    "edit_market_place": "Modifica MarketPlace",
    "no_reviews_yet": "Nessuna recensione ancora",
    "good": "Bene",
    "average": "Media",
    "add_marketPlace": "Aggiungi MarketPlace",
    "all_title": "Tutto",
    "included_title": "Incluso",
    "excluded_title": "Escluso",
    "clients_subscribed": "Clienti abbonati negli ultimi",
    "clients_groups": "Gruppi di clienti",
    "customer_groups": "Gruppi di clienti",
    "audience_title": "Pubblico",
    "client_gt5": "che non è tornato nell'ultimo",
    "select_all": "Seleziona tutto",
    "modify": "Modificare",
    "campaign_title": "Titolo della campagna",
    "msg_type": "Tipo di messaggio",
    "enter_discount": "Inserisci sconto",
    "discount_type": "Tipo di sconto",
    "coupon_text": "Testo del coupon",
    "enter_coupon_text": "Inserisci il testo del coupon",
    "coupon_button_text": "Testo del pulsante del coupon",
    "enter_coupon_button_text": "Inserisci il testo del pulsante del coupon",
    "fine_prin": "Piccola stampa",
    "enter_fine_prin": "Inserisci il testo in caratteri piccoli",
    "campaign_dec": "Puoi modificare la campagna 30 minuti prima del suo orario di inizio. Gli addebiti per la campagna SMS saranno effettuati 30 minuti prima dell'orario di inizio della campagna.",
    "blast_text_message_dec": "Il tuo messaggio di testo blast è pronto. Seleziona il tuo metodo di pagamento e invia i tuoi messaggi.",
    "payment_completed": " Il pagamento verrà completato presso ",
    "total_cost": "Costo totale",
    "close_title": "Vicino",
    "friday": "Venerdì",
    "saturday": "Sabato",
    "sunday": "Domenica",
    "thursday": "Giovedì",
    "tuesday": "Martedì",
    "wednesday": "Mercoledì",
    "port_txt": "Porta",
    "today_title": "Oggi",
    "yesterday_title": "Ieri",
    "last_30_days_txt": "Ultimi 30 giorni",
    "this_month_txt": "Questo mese",
    "last_month_txt": "Il mese scorso",
    "valid_date_title": "Seleziona una data valida",
    "valid_business_name_txt": "Inserisci un nome commerciale valido",
    "req_bus_add_txt": "È obbligatorio l'indirizzo aziendale",
    "req_domain_name_txt": "Il nome di dominio è obbligatorio",
    "basic_info_txt": "Informazioni di base sulla tua attività",
    "loyalty_qr_def_txt": "Scansiona il codice QR, unisciti alla nostra tessera fedeltà e goditi i premi.",
    "last_stamp_txt": "Ultimo timbro",
    "collected_on_txt": "Raccolti su",
    "stamp_details_txt": "Dettagli del francobollo",
    "add_stamp_txt": "Aggiungi timbro",
    "choose_brand_color_txt": "Scegli il colore e la lingua del tuo marchio 🎨",
    "change_anytime_txt": "Puoi cambiare questa impostazione in qualsiasi momento",
    "like_to_get_txt": "Vorresti ottenere",
    "your_next_visit_txt": "alla tua prossima visita?",
    "time_to_time_get_offers": "Vorresti ricevere di tanto in tanto fantastiche offerte e promozioni?",
    "respond_back_to_you": "Vorresti che qualcuno ti rispondesse?",
    "input_serve_better": "Il tuo contributo aiuterà il nostro team a servirti meglio!",
    "do_it_txt": "Facciamolo",
    "take_15_s": "Ci vogliono 15 secondi",
    "rate_on_google_desc": "Potresti essere così gentile da darci una valutazione su Google?",
    "may_be_next_time": "Magari la prossima volta",
    "thank_you_txt": "Grazie",
    "look_next_txt": "Non vediamo l'ora della tua prossima visita!",
    "deleteUserTitle": "Questo utente verrà eliminato definitivamente.",
    "deleteMUserTitle": "Questi utenti verranno eliminati definitivamente.",
    "change_pin": "Cambia PIN",
    "area_code": "Qual è il tuo prefisso telefonico? ",
    "add_menu": "Aggiungi menu",
    "menu_name": "Nome del menu",
    "add_menu_placeholder": "Pizza, hamburger, bevande, ecc.",
    "enable_data_Collection": "Abilita raccolta dati",
    "add_new_menu": "Aggiungi nuovo menu",
    "rename_menu": "Rinomina menu",
    "preview": "Anteprima",
    "generate_qr": "QR generato",
    "edit_menu": "Modifica menu",
    "remove_menu": "Rimuovi Menu",
    "menu_delete_msg": "Questo menu verrà eliminato definitivamente",
    "menus_delete_msg": "Questi menu verranno eliminati definitivamente",
    "view_menu_dialoage_msg": "Vorresti ricevere un regalo per il tuo compleanno?",
    "skip": "Saltare",
    "cliam_your_gift": "Richiedi il tuo regalo",
    "collect_form": "Modulo di raccolta",
    "enter_first_name": "Inserisci il nome",
    "enter_last_name": "Inserisci il cognome",
    "enter_email": "Inserisci l'e-mail",
    "enter_dob": "Inserisci la data di nascita",
    "enter_number": "Inserisci il numero",
    "select_gender": "Seleziona il genere",
    "congratulations_desc": "🎉 Congratulazioni! La tua richiesta di richiesta regalo è stata inviata con successo. Il nostro team ti contatterà a breve. 🎉",
    "male_heading": "Maschio",
    "male_text": "maschio",
    "female_heading": "Femmina",
    "female_text": "femmina",
    "others_heading": "Altri",
    "other_text": "altri",
    "BirthD": "Data di nascita",
    "GenderT": "Genere",
    "EmailT": "E-mail",
    "dobT": "Data di nascita",
    "capitalize_menu": "Menu",
    "select_menu": "Seleziona il menu",
    "manage_variant": "Gestisci Variante",
    "add_products": "Aggiungi prodotti",
    "add_category": "Aggiungi categoria",
    "category_delete": "Questa categoria verrà eliminata definitivamente",
    "variation_delete": "Questa variante verrà eliminata dal Prodotto.",
    "product_delete": "Questo prodotto verrà eliminato definitivamente.",
    "categories_delete": "Questa categoria verrà eliminata definitivamente.",
    "products_delete": "Questi prodotti verranno eliminati definitivamente.",
    "category": "CATEGORIA",
    "price": "PREZZO",
    "food_product_placeholder": "Pizza, hamburger, bevande, ecc.",
    "active_title": "Attivo",
    "inActive_title": "Inattivo",
    "status_capital": "STATO",
    "cat_status_require": "Lo stato della categoria è obbligatorio",
    "cat_name_require": "Il nome della categoria è obbligatorio",
    "category_name": "Nome della categoria",
    "status": "Stato",
    "lgDay1": "Lunedi",
    "lgDay2": "Martedì",
    "lgDay3": "Mercoledì",
    "lgDay4": "Giovedì",
    "lgDay5": "Venerdì",
    "lgDay6": "Sabato",
    "lgDay7": "Domenica",
    "is_closed_title": "Chiuso",
    "book_table_title": "Prenota un tavolo",
    "emailErrSub": "L'oggetto dell'email è obbligatorio",
    "email_subject": "Oggetto e-mail",
    "contactUsfrmTitleEmail": "Rispondi all'email",
    "companyInfo": "Lorem Ipsum è lorem hrig, lorem ipsum is fraets.Lorem Ipsum è lorem hrig, lorem ipsum is fraets.Lorem Ipsum è lorem hrig, lorem ipsum is fraets.",
    "footerSTitle1": "Informazioni su Menuonline",
    "footerSTitle1Lnk2": "Caratteristiche",
    "footerSTitle1Lnk3": "Blog",
    "footerSTitle2": "Legal",
    "footerSTitle2Lnk1": "Termini e Condizioni",
    "footerSTitle2Lnk2": "politica sulla riservatezza",
    "footerSTitle3": "Per le aziende",
    "footerSTitle3Lnk1": "Per i partner",
    "footerSTitle3Lnk2": "Prezzi",
    "footerSTitle3Lnk3": "Supporto per i partner",
    "footerCopyRithgTxt": "  Menuonline o suoi affiliati",
    "homeTitle": "Casa",
    "contactTitle": "Contattaci",
    "aboutTitle": "Chi siamo",
    "homeMenuTitle": "Menu",
    "bookTableTitle": "Prenota un tavolo",
    "vDateT": "Seleziona una data valida",
    "reqDateT": "La data è obbligatoria",
    "dateGtErr": "La data deve essere successiva o uguale alla data odierna",
    "timeInvalid": "Il tempo non è valido",
    "reqTimeT": "Il tempo è necessario",
    "timeInvalidBfr": "Seleziona un orario almeno 5 minuti dopo l'orario corrente ",
    "PerReq": "È richiesto il numero di persone",
    "validcNoT": "Inserisci un numero di contatto valido",
    "reqCrT": "La valuta è obbligatoria",
    "reqPrT": "Il prezzo è obbligatorio",
    "reCateT": "La categoria è obbligatoria",
    "reqVrT": "La variante è obbligatoria",
    "reqVrVldT": "Inserisci una variante valida ",
    "validLNameT": "Inserisci un cognome valido",
    "sDateInValid": "La data di inizio non è valida",
    "minmReq": "Il minimo è richiesto",
    "resLblDate": "Seleziona Data",
    "resLblTime": "Seleziona Ora",
    "perT": "Per quante persone?",
    "resLblEmail": "Per favore inserisci la tua email?",
    "resLblNote": "Aggiungi una nota di prenotazione",
    "imageError1MB": "Inserisci un'immagine inferiore a 1 MB",
    "imageError": "Inserisci un'immagine inferiore a 2,5 MB",
    "imageError3Mb": "Inserisci un'immagine inferiore a 3 MB",
    "imageInVldError": "Il file non è valido.",
    "addProMT": "Menu dei prodotti",
    "proT": "Nome del prodotto",
    "reqProT": "Il nome del prodotto è obbligatorio",
    "proPT": "Prezzo del prodotto",
    "reservationSuccTitle": "Ottimo, la tua prenotazione è stata effettuata",
    "book": "Libro",
    "custzName": "Nome personalizzazione",
    "always": "Sempre",
    "proImgReq": "È richiesta l'immagine del prodotto",
    "selCustmT": "Seleziona Personalizzazione o creane una nuova",
    "visStR": "È richiesto lo stato di visibilità",
    "avlblScR": "Seleziona la disponibilità del programma",
    "addonPR": "Seleziona i prodotti aggiuntivi",
    "upsellPR": "Seleziona i prodotti da vendere",
    "markItemR": "Seleziona i prodotti contrassegnati",
    "caloriesR": "Sono necessarie calorie",
    "allergensR": "Selezionare i prodotti con allergeni",
    "prepInstR": "Sono necessarie istruzioni per la preparazione",
    "staffNR": "Sono richieste note del personale",
    "discountR": "È richiesto lo sconto",
    "validDiscE": "Inserisci uno sconto valido",
    "disday": "Di giorno in giorno",
    "plSelDayT": "Si prega di selezionare il giorno",
    "sTimeReq": "L'orario di inizio è obbligatorio",
    "sTimeInValid": "L'ora di inizio non è valida",
    "eTimeReq": "L'ora di fine è obbligatoria",
    "eTimeInValid": "L'ora di fine non è valida",
    "sDateReq": "La data di inizio è obbligatoria",
    "eDateReq": "Ad oggi è obbligatorio",
    "eDateInValid": "Ad oggi non è valido",
    "disdate": "Dalla data",
    "disdate1": "Ad oggi",
    "disdate2": "Da Data",
    "currT": "Valuta",
    "iconR": "Selezionare l'icona per contrassegnare l'articolo",
    "minT": "Minimo",
    "maxT": "Massimo",
    "itemNT": "Nome dell'articolo",
    "itemPT": "Prezzo dell'articolo",
    "descProT": "Descrivi il tuo prodotto",
    "cateT": "Categoria",
    "selProCateT": "Seleziona la categoria del tuo prodotto",
    "reqMT": "Il nome del menu è obbligatorio",
    "ViewMenu": "Visualizza il menu",
    "CopyMenu": "Copia il menu",
    "EditMenu": "Modifica menu",
    "RemoveMenu": "Rimuovi menu",
    "welcomeMnuTitle": "Benvenuti a",
    "reviewT": "Recensioni",
    "userErrorMsg": "Spiacenti, si è verificato un errore!!!",
    "reqCateT": "Il nome della categoria è obbligatorio",
    "mngCustT": "Gestisci la personalizzazione",
    "custNReq": "Il nome di personalizzazione è obbligatorio",
    "incReq": "Incluso è obbligatorio",
    "minmValid": "Inserisci un valore minimo valido",
    "maxmReq": "Il massimo è richiesto",
    "cust": "Personalizzazione",
    "crCust": "Crea nuova personalizzazione",
    "custList": "Elenco di personalizzazione",
    "delCustmzT": "Questa personalizzazione verrà eliminata definitivamente.",
    "mkNameR": "Il nome dell'articolo contrassegnato è obbligatorio",
    "mkDelT": "Questo elemento contrassegnato verrà eliminato definitivamente.",
    "hideText": "Nascondere",
    "showText": "Spettacolo",
    "device_mac_txt": "Dispositivo Mac",
    "delete_hotspot_txt": "Questo hotspot verrà eliminato definitivamente.",
    "delete_hotspots_txt": "Questi hotspot verranno eliminati definitivamente.",
    "emp_wifi_name": "Nome Wi-Fi dipendente",
    "max_limit_txt": "Inserisci '1024' per la velocità per designarla come ILLIMITATA. Questo valore rimuove qualsiasi limite, consentendo la massima produttività",
    "device_port_txt": "Porta del dispositivo",
    "firmware_txt": "Firmware",
    "hotspot_info_txt": "Informazioni hotspot",
    "editDBtn": "Modifica dettagli",
    "birth_date": "Data di nascita",
    "di_theme_clr": "Colore tema esattamente 7 cifre",
    "color_Invalid_txt": "Seleziona un codice colore valido",
    "Req_theme_clr": "Il colore del tema è obbligatorio",
    "select_valid_color_txt": "Seleziona un colore tema valido",
    "req_redir_link": "Il collegamento di reindirizzamento è obbligatorio",
    "val_redir_link": "Inserisci un link di reindirizzamento valido",
    "req_business_name_txt": "Il nome dell'azienda è obbligatorio",
    "splash_preview": "Anteprima Splash",
    "create_new_splash": "Crea nuovo Splash",
    "splash_page": "Pagina iniziale",
    "splash_page_builder": "Generatore di pagine Splash",
    "redirect_link": "Collegamento di reindirizzamento",
    "theme_color": "Colore tema",
    "enable_social_login": "Abilita le opzioni dei social media per l'accesso degli utenti",
    "google": "Google",
    "facebook": "Fai clic su Mi Piace",
    "is_mandatory": "È obbligatorio?",
    "field": "Campo",
    "name": "Nome",
    "first_name": "Nome di battesimo",
    "last_name": "Cognome",
    "birthdate": "Data di nascita",
    "gender": "Genere",
    "email": "E-mail",
    "dob": "Data di nascita",
    "zip_code": "Cap",
    "required_redirect_link": "Il collegamento di reindirizzamento è obbligatorio",
    "valid_redirect_link": "Inserisci un link di reindirizzamento valido",
    "required_theme_color": "Il colore del tema è obbligatorio",
    "theme_color_length": "Colore tema esattamente 7 cifre",
    "required_name": "Il nome è obbligatorio",
    "delete_splash_message": "Vuoi eliminare questa immagine?",
    "delete_multiple_splashes_message": "Vuoi eliminare questi schizzi?",
    "user_login_required": "È richiesto l'accesso utente",
    "set_theme_color": "Imposta il colore del tema",
    "splash_colllect_additional_info_txt": "Raccogli informazioni aggiuntive",
    "verify_mobile_otp_message": "Desideri inviare un OTP per verificare il numero di cellulare?",
    "add_company": "Aggiungi azienda",
    "edit_company": "Modifica azienda",
    "ds_device": "Dispositivo di segnaletica digitale",
    "ds_device_activation_fee": "Tariffa di attivazione del dispositivo di segnaletica digitale",
    "wifi_hardware": "Hardware Wi-Fi",
    "menu_design": "Progettazione del menù",
    "ds_hardware": "Hardware per segnaletica digitale",
    "company_name_req": "Il nome dell'azienda è obbligatorio",
    "known_name_req": "È obbligatorio il nome noto",
    "no_location_req": "È richiesto il numero di posizione",
    "minimum_location": "Inserisci almeno 1 posizione",
    "terms_req": "I termini sono obbligatori",
    "notes_req": "Le note sono obbligatorie",
    "sms_charge_req": "È richiesto il costo dell'SMS",
    "menuonline_quantity_req": "Menu Online Quantità richiesta",
    "menuonline_cost_req": "Il costo del menu online è obbligatorio",
    "ds_quantity_req": "La quantità di segnaletica digitale è obbligatoria",
    "ds_monthly_cost_req": "È richiesto il costo mensile della segnaletica digitale",
    "reputation_quantity_req": "La quantità di reputazione è obbligatoria",
    "reputation_cost_req": "È richiesto il costo della reputazione",
    "marketing_quantity_req": "Quantità di marketing richiesta",
    "marketing_cost_req": "È richiesto il costo di marketing",
    "tax_percentage_req": "La percentuale di imposta è obbligatoria",
    "router_hardware_quantity_req": "Quantità hardware router richiesta",
    "wifi_hardware_cost_req": "È richiesto il costo dell'hardware WIFI",
    "activation_cost_req": "È richiesto il costo di attivazione",
    "wifi_quantity_req": "La quantità WIFI è obbligatoria",
    "wifi_router_req": "È richiesto un router Wi-Fi",
    "menu_design_quantity_req": "La quantità del design del menu è richiesta",
    "menu_design_cost_req": "È richiesto il costo del design del menu",
    "quantity_req": "La quantità è obbligatoria",
    "cost_req": "Il costo è obbligatorio",
    "sheduledown_quantity_req": "Quantità di pianificazione richiesta",
    "sheduledown_cost_req": "È richiesto il costo di programmazione",
    "loyalty_program_quantity_req": "La quantità del programma fedeltà è obbligatoria",
    "loyalty_program_cost_req": "Il costo del programma fedeltà è obbligatorio",
    "loyalty_program_sms_quantity_req": "La quantità di SMS del programma fedeltà è obbligatoria",
    "loyalty_program_sms_cost_req": "Il costo SMS del programma fedeltà è obbligatorio",
    "comapny_created": "Azienda creata con successo",
    "comapny_updated": "Azienda aggiornata con successo",
    "billing_info": "INFORMAZIONI DI FATTURAZIONE",
    "business_name_acc_holder": "Nome dell'azienda (titolare del conto)",
    "client_first_name": "Nome del cliente",
    "client_last_name": "Cognome del cliente",
    "dba_known_as": "DBA noto come",
    "business_address": "Indirizzo commerciale",
    "no_of_locations": "Numero di posizioni",
    "package_details": "DETTAGLI DEL PACCHETTO",
    "terms": "Termini",
    "one_month": "1 mese",
    "per_sms_charge": "Addebito per SMS",
    "plan_test": "PIANO : TEST",
    "desc_capital": "DESCRIZIONE",
    "qty_capital": "Quantità",
    "cost_capital": "COSTO",
    "total_price_capital": "PREZZO TOTALE",
    "quantity": "Quantità",
    "can_not_access": "Non puoi selezionare più di",
    "licenses": "licenze",
    "monthly_cost": "Costo mensile",
    "plan_cost": "Costo del piano",
    "tax": "Tassare",
    "tax_percentage": "Percentuale di imposta",
    "monthly_cost_after_tax": "Costo mensile dopo le tasse",
    "activation_one_time_charge": "Attivazione e addebito una tantum",
    "sub_total": "SUBTOTALE",
    "action": "Azione",
    "cost": "Costo",
    "shipping_charge": "Spese di spedizione",
    "other": "Altro",
    "additional_cost": "Costi aggiuntivi (Altri oneri)",
    "other_tax": "Altre imposte",
    "total": "Totale",
    "license_statistics": "Statistiche della licenza",
    "total_licenses": "Licenze totali",
    "available_licenses": "Licenze disponibili",
    "stock": "Azione",
    "has_permission": "Ha il permesso",
    "avg_stock_price": "Prezzo medio delle azioni",
    "average_price": "Prezzo medio",
    "allocated": "Assegnato",
    "reward_regulars": "Premia i clienti abituali",
    "not_add_coupon_url": "Impossibile aggiungere l'URL del coupon. La lunghezza massima del messaggio è 160",
    "like_attach_coupon": "Vuoi allegare un coupon?",
    "advance_scheduling": "Pianificazione anticipata",
    "choose_day": "Seleziona il\/i giorno\/i in cui inviare il messaggio",
    "select_essage_window": "Seleziona la finestra del messaggio",
    "subscription_outside_delivery_window": "Se un utente si iscrive al di fuori dell'intervallo di consegna, riceverà la tua risposta automatica al primo orario disponibile.",
    "remaining": "Rimanente",
    "processing_request": "Si è verificato un errore durante l'elaborazione della richiesta.",
    "list_companies": "Elenco delle aziende",
    "are_you_sure": "Sei sicuro..?",
    "signin_agreement": "Confermando ciò, la società creerà senza firmare alcun accordo.",
    "status_updated": "Aggiornamento dello stato eseguito correttamente",
    "status_failed": "Impossibile aggiornare lo stato",
    "new_editor_status_updated": "Lo stato del nuovo editor DS è stato aggiornato correttamente.",
    "user_name": "Nome utente",
    "known_as": "conosciuto come",
    "agreement_signed": "Accordo firmato",
    "deactivate": "Disattivare",
    "activate": "Attivare",
    "login_to_user_acc": "Accedi all'account utente",
    "disable": "Disabilitare",
    "enable": "Abilitare",
    "ds_new_editor": "Nuovo editore DS",
    "sign_agreement": "Firmare l'accordo",
    "agreement_sign": "Accordo senza firma",
    "view_agreement": "Visualizza l'accordo",
    "download_agreement": "Scarica l'accordo",
    "add_deleted_user": "Aggiungi utente eliminato",
    "deleted_user": "Elimina utente",
    "favourite": "Preferito",
    "refresh": "Aggiornare",
    "delete_chat": "Elimina chat",
    "responsive_drawer": "Cassetto reattivo",
    "delete_contact": "Elimina contatto",
    "clear_chat_history": "Cancella cronologia chat",
    "clear_chat_history_dec": "Sei sicuro di voler eliminare questa chat?",
    "clear_contact_dec": "Sei sicuro di voler eliminare questo contatto?",
    "select_contact": "Seleziona Contatto",
    "new_conversation": "Inizia una nuova conversazione",
    "type_msg": "Digita qui il tuo messaggio",
    "select_delete_client": "Seleziona il cliente da eliminare",
    "select_delete_client_chat": "Seleziona il client per eliminare la chat",
    "select_delete_client_chat_err": "Non hai chattato con questo cliente",
    "acquire_customers_business": "Acquisire nuovi clienti da altre aziende.",
    "customers_from_events": "Ottieni clienti dagli eventi e portali a casa tua!",
    "customers_from_ads": "Ottieni clienti tramite gli annunci sui giornali.",
    "smart_pamphlets": "Crea opuscoli intelligenti per raccogliere i dati dei clienti.",
    "smart_qr_group": "Un codice QR intelligente per unirsi a un gruppo di clienti.",
    "opt_in_number": "Consentire ai clienti di scegliere il numero.",
    "qr_menu": "Crea un codice QR per visualizzare il tuo menù.",
    "collect_birthdays": "Raccogli le date del compleanno e dell'anniversario del cliente.",
    "join_loyalty_program": "Convinci nuovi clienti ad aderire al mio programma fedeltà.",
    "create_feedback_forms": "Crea moduli per raccogliere il feedback dei clienti.",
    "exclusive_discounts_promotions": "Offri sconti e promozioni esclusive per promozioni incrociate.",
    "group_smart_qr_opt_in": "Dopo aver creato un gruppo, puoi generare e selezionare un codice QR intelligente, consentendo ai clienti di aderire immediatamente a un gruppo",
    "group_smart_qr_opt_in_dec": "Dopo aver creato un gruppo, widget Immagine che consente ai clienti di aderire tramite testo e ricevere promozioni, ad esempio, inviare un SMS con la parola \"pizza\" al numero 555-555-5555",
    "in_store_discount_next_visit": "Ottieni uno sconto in negozio durante la tua prossima visita presso la nostra sede",
    "delivery_redirection": "Crea reindirizzamento della consegna",
    "additional_info": "Informazioni aggiuntive",
    "add_url": "Aggiungi URL",
    "custom_fields": "Campi personalizzati",
    "meta_tag": "Meta-tag",
    "max_chars": "Numero massimo di caratteri",
    "add": "Aggiungere",
    "update_campaign": "Aggiorna campagna",
    "last_week_required": "la settimana scorsa è richiesta",
    "large_week_value": "Il valore della settimana è troppo grande, inserisci una settimana valida",
    "less_than_last_week": "Il mancato rientro nell'ultima settimana deve essere inferiore a quello della settimana scorsa.",
    "last_week_req": "Non è obbligatorio tornare la settimana scorsa",
    "birthday_special": "Speciale compleanno",
    "birthday_message": "Messaggio automatico inviato per i loro compleanni con auguri personalizzati e offerte allettanti",
    "birthday_sms": "Crea auguri di compleanno personalizzati per SMS\/MMS",
    "target_audience": "Scegli il pubblico di destinazione",
    "client_by_birthday": "Cliente per compleanno",
    "clients_once_year": "Questa campagna viene inviata automaticamente ai clienti una volta all'anno ",
    "auto_responder_default_msg_1": "Sei uno dei nostri migliori clienti a cui amiamo fare sconti! Che tu possa essere sempre ricco e in salute. Buon compleanno! %% firstname %%",
    "campaign_send_to_client_birthday": "Questa campagna automatica viene inviata ai clienti in base alla data di nascita; puoi personalizzare il targeting di seguito.",
    "get_more_engagement": "Ottieni più coinvolgimento inviando SMS di compleanno 🎂",
    "welcome_new_clients": "Benvenuti nuovi clienti",
    "make_lasting_impression": "Lascia un'impressione duratura accogliendo calorosamente e dando il benvenuto ai clienti che vengono per la prima volta",
    "personalized_greetings": "Crea saluti personalizzati per i nuovi clienti",
    "greetings_to_new_customers": "Questa campagna invierà saluti di benvenuto ai nuovi clienti il giorno successivo alla loro prima interazione con la tua attività",
    "audience_predefined": "Il pubblico di questo risponditore automatico è predefinito, ci pensiamo noi!",
    "send_clients_subscribed": "Questo risponditore automatico è impostato per inviare ai clienti iscritti nell'ultimo ",
    "first_interaction": "  dopo la loro prima interazione.",
    "default_msg_2": "Ci auguriamo che la tua prima visita sia stata di tuo gradimento, ci piacerebbe rivederti presto, quindi ti offriamo uno sconto solo per te! Clicca sul link per prenotare il tuo prossimo appuntamento con noi e riscattare subito la tua speciale offerta online.",
    "new_clients_update": "Testo della campagna di aggiornamento dei nuovi clienti di benvenuto",
    "new_clients_warm_greeting": "Accogliamo i nuovi clienti con un caloroso saluto 💌",
    "win_back_clients": "Riconquista i clienti",
    "re_engage_past_customers": "Coinvolgi nuovamente i clienti passati con offerte irresistibili per incoraggiarli a tornare",
    "target_disengaged_clients": "Invitare i clienti disinteressati a tornare con un'offerta speciale è una tattica collaudata per invogliarli a tornare.",
    "campaign_sends_to_clients": "Questa campagna viene inviata ai clienti che non sono tornati dopo un certo periodo di tempo.",
    "customize_targeting": "Personalizza il targeting qui sotto per inviare SMS\/MMS ai clienti non più attivi.",
    "clients_with_at_least": "Clienti con almeno ",
    "connection_last": " collegamento nell'ultimo ",
    "return_in_last_week": " settimana, ma non è tornato nell'ultima ",
    "arr_menu_44": " settimana",
    "df_msg_4": "Ti vogliamo di nuovo COSÌ TANTO che ti faremo uno sconto del 10% solo per il tuo ritorno! Vuoi riprovarci oggi?",
    "update_campaign_text": "Testo della campagna di aggiornamento per riconquistare i clienti",
    "re_invite_customers": "Invita nuovamente i clienti che non tornano da un po'",
    "loyal_patrons": "Mostra apprezzamento ai clienti fedeli con sconti speciali e premi fedeltà",
    "surprize_top_spenders": "Sorprendi i top spender con offerte personalizzate e speciali.",
    "campaign_automatically_send": "Questa campagna invia automaticamente premi per aumentare il traffico nella tua attività.",
    "sms_loyal_clients": "Questa campagna invia SMS\/MMS ai clienti fedeli. Puoi modificare il targeting qui sotto.",
    "or_more_connection": " o più connessioni, entro l'ultimo ",
    "week_period": " periodo di una settimana.",
    "default_msg_5": "Sei un cliente da molto tempo ormai! Wow, il tempo vola! Ti siamo grati e vogliamo ringraziarti per essere con noi.",
    "reward_regulars_update": "Testo della campagna di aggiornamento dei clienti abituali",
    "special_discounts": "Apprezza e fai sconti speciali💎",
    "leave_positive_online": "Incoraggia i clienti a lasciare recensioni positive online per aumentare la reputazione del ristorante",
    "clients_service_online": "La campagna incoraggia i tuoi clienti a valutare il tuo servizio online.",
    "increase_your_company": "Questa risposta automatica viene inviata per aumentare la valutazione online della tua azienda.",
    "send_message_after": "Invia messaggio dopo ",
    "min_connection": " min della connessione",
    "default_msg_6": "Ci auguriamo che la tua prima visita sia stata di tuo gradimento, ci piacerebbe rivederti presto! [URL] Clicca sul link per valutarci su Google.",
    "ratings_update": "Ottieni più testo della campagna di aggiornamento delle valutazioni",
    "friendly_nudge": "Ottieni più recensioni con un amichevole suggerimento ⭐",
    "thanks_for_visiting": "Grazie per la visita",
    "express_gratitude": "Esprimi la tua gratitudine ai clienti per aver scelto la tua attività con messaggi di ringraziamento sentiti",
    "thank_you_message": "Crea un messaggio di ringraziamento per i tuoi clienti.",
    "clients_for_visiting": "Invia un caloroso ringraziamento ai tuoi clienti per aver visitato la tua struttura.",
    "campaign_audience": "Questo pubblico della campagna automatica è predefinito, ci pensiamo noi!",
    "campaign_automatically_sends": "Questa campagna automatica viene inviata automaticamente dopo ",
    "minutes_customer_visit": " minuti di visita del cliente",
    "default_msg_7": "Grazie per essere il nostro stimato cliente. Siamo così grati per il piacere di servirvi e speriamo di aver soddisfatto le vostre aspettative.",
    "visiting_update_campaign": "Grazie per aver visitato il testo della campagna di aggiornamento",
    "guests_thank_you": "Ringrazia i tuoi ospiti per la loro visita 😊",
    "download_title": "Scaricamento",
    "qr_gen_title": "QR generato",
    "qr_download_s_title": "Codice QR scaricato correttamente",
    "sel_dark_brand_clr_msg": "Selezionare un colore di barnd valido",
    "manage_customer_stamp_rewards": "Gestisci i premi dei timbri dei clienti 🎁",
    "sel_loc_menu_title": "Seleziona le località per il tuo menu",
    "ans_req": "La risposta è obbligatoria",
    "valid_reputation_name": "Inserisci un nome di reputazione valido",
    "reviews_name_req": "Ottieni più recensioni il nome è obbligatorio",
    "birthdate_required": "La data di nascita è obbligatoria",
    "gender_required": "Il genere è obbligatorio",
    "valid_birthdate_required": "Inserisci una data di nascita valida",
    "custom_delivery_redirection": "Crea un modulo di reindirizzamento delle consegne personalizzato per gruppi",
    "customer_fields_incentive_settings": "Campi cliente e impostazioni incentivi",
    "delivery_redirection_text": "Reindirizzamento della consegna",
    "delivery_redirection_success": "Congratulazioni! Il tuo widget di reindirizzamento delle consegne è stato creato! 🎉",
    "swipe_to_preview_redirection": "Scorri per vedere come apparirà il tuo modulo di reindirizzamento della consegna",
    "enter_item_name": "Inserisci il nome dell'articolo",
    "home_page_text": "Testo della home page",
    "settings": "Impostazioni",
    "anniversary_required": "La data dell'anniversario è obbligatoria",
    "valid_anniversary": "Inserisci una data di anniversario valida",
    "form_submited": "modulo inviato con successo",
    "notifications": "Notifiche",
    "discount_message": "Richiedi subito il tuo sconto del 20% sulla pizza!",
    "is_required": " è obbligatorio",
    "section_title_required": "Il titolo della sezione è obbligatorio",
    "section_dec_required": "La descrizione della sezione è obbligatoria",
    "client_details_required": "I campi dei dettagli del cliente sono obbligatori",
    "compliance": "Conformità",
    "SMS_campaign1": "Campagna SMS 1",
    "mobile_number_mandatory": "Il numero di cellulare è obbligatorio",
    "new_answer": "Nuova risposta",
    "new_question": "Nuova domanda",
    "add_new_question": "Aggiungi nuova risposta",
    "select": "Selezionare",
    "group_customers_question": "Dove vuoi raggruppare i tuoi clienti?",
    "contacts_added_to_group": "Tutti i contatti che inviano il modulo verranno aggiunti al gruppo selezionato",
    "edit_client_details_section": "Modifica sezione dettagli cliente",
    "client_details_fields": "Campi dei dettagli del cliente",
    "enter_client_details_section_title_desc": "Inserisci il titolo e la descrizione della sezione dei dettagli del cliente",
    "choose_fields_displayed_client_side": "Seleziona i campi da visualizzare sul lato client",
    "section_title": "Titolo della sezione",
    "add_title": "Aggiungi titolo",
    "section_description": "Descrizione della sezione",
    "add_description": "Aggiungi descrizione",
    "enter_program_name_location": "Inserisci il nome e la posizione del programma 📍",
    "brand_theme_clr_txt": "Colore del tema del marchio",
    "set_loyalty_punch_card_theme": "Impostiamo un tema per la tua tessera fedeltà 🎨",
    "upload_logo_txt": "Carica il tuo logo",
    "recommended_image_specs": "Consigliamo di usare un'immagine di 512 x 512px per una visualizzazione ottimale. JPG, SVG o PNG. Dimensione massima di 10 MB.",
    "valid_rep_name_txt": "Inserisci un nome di reputazione valido",
    "rep_name_req_txt": "Ottieni più recensioni il nome è obbligatorio",
    "que_req": "La domanda è obbligatoria",
    "day_of_birthday": "il giorno del compleanno",
    "day_before_birthday": "3 giorni prima del compleanno",
    "week_before_birthday": "una settimana prima del compleanno",
    "two_week_before_birthday": "due settimane prima del compleanno",
    "de_active": "Disattivato",
    "campaign_details": "Dettagli della campagna",
    "link_clicked": "Link cliccato",
    "history": "Storia",
    "auto_responder_summary": "Riepilogo della risposta automatica",
    "vsop_1": "15 minuti",
    "vsop_2": "30 minuti",
    "vsop_3": "45 minuti",
    "vsop_4": "60 minuti",
    "vsop_5": "Durata: 90 minuti",
    "vsop_6": "120 minuti",
    "in_the_last": " nell'ultimo ",
    "return_in_last": " ma non è tornato nell'ultimo ",
    "of_the_connection": "della connessione",
    "your_campaign": "La tua campagna",
    "you_have_successfully": "Hai avuto successo",
    "published_successfully": "pubblicato con successo",
    "updated_smart_campaign": "aggiornato la campagna intelligente",
    "almost_done_text": "Quasi finito!",
    "update_campaign_desc": "Il tuo autoresponder è completo, una volta abilitato, invierà messaggi ai clienti in modo continuativo. Questa campagna può essere facilmente modificata o messa in pausa in qualsiasi momento.",
    "update_and_publish": "Aggiorna e pubblica",
    "reset_campaign_title": "Eliminerà definitivamente il testo personalizzato, l'immagine, le offerte scontate e il pubblico di destinazione della tua campagna!",
    "space_wifi": " Wifi",
    "custom_forms": "Moduli personalizzati",
    "web_e_signup": "Registrazione online tramite Web",
    "import": "Importare",
    "permissions_req": "Sono necessarie autorizzazioni",
    "redeemed": "Riscattato",
    "coupon_already_redeemed": "Buono già riscattato su",
    "autoresponder_campaigns": "Campagne di risposta automatica",
    "autoresponder_campaign_desc": "Abilita il risponditore automatico e lancia la campagna personalizzata",
    "mon": "LUNEDÌ",
    "tue": "MARTEDÌ",
    "wed": "MER",
    "thu": "GIO",
    "fri": "VEN",
    "sat": "SAB",
    "sun": "SOLE",
    "duplicate": "Duplicato",
    "visibility": "Visibilità",
    "availability": "Disponibilità",
    "out_stok": "Fuori stock",
    "edit_product": "Modifica prodotto",
    "create_product": "Crea un prodotto",
    "basic_info": "Informazioni di base",
    "basic_sub_info": "Lorem Ipsum è semplicemente un testo fittizio della tipografia...",
    "enter_product_price": "Inserisci il prezzo del prodotto",
    "upload_image": "Carica Immagine",
    "allowed_file_formats": "Sono consentiti solo file JPG e PNG con dimensione massima di 3 MB.",
    "pre_select": "Pre-selezionare",
    "promotions": "Promozioni",
    "discount_availability": "Disponibilità scontata",
    "start_time": "Ora di inizio",
    "end_time": "Ora di fine",
    "select_day": "Seleziona giorno",
    "menu_required": "Il menu è obbligatorio",
    "generated": "Generato",
    "link": "Collegamento",
    "variations": "Variazioni",
    "select_variant_to_add": "Seleziona la variante che desideri aggiungere",
    "price_title": "Prezzo",
    "choose_image": "Scegli Immagine",
    "select_visiblity": "Seleziona Visibilità",
    "availability_schedule": "Orario di disponibilità",
    "add_on_upsell": "Aggiungi\/Upsell",
    "add_on_select_product": "Aggiungi su Seleziona Prodotto",
    "upsell_product": "Upsell Seleziona Prodotto",
    "variant_deletion_warning": "Questa variante verrà eliminata definitivamente.",
    "search_variations": "Cerca varianti",
    "add_variation": "Aggiungi variazione",
    "variation_text": "Variante",
    "select_customization": "Seleziona personalizzazione",
    "add_new": "Aggiungi nuovo",
    "delete_customization_warning": "Vuoi eliminare questa personalizzazione dal prodotto?",
    "nutritional_allergen_info": "Informazioni nutrizionali e sugli allergeni",
    "mark_item": "Contrassegna l'articolo",
    "calories_text": "Calorie",
    "allergens": "Allergeni",
    "select_allergens": "Seleziona allergeni",
    "special_instructions": "Istruzioni speciali",
    "preparation_instructions": "Istruzioni per la preparazione",
    "staff_notes": "Note del personale",
    "checkbox1": "Una casella di controllo per consentire o impedire ai clienti di ricevere istruzioni speciali per questa voce di menu.",
    "menu_c": "MENU",
    "past_design": "Design passato",
    "file_name": "Nome del file",
    "variation_name": "Nome della variante",
    "grid_items_title1": "Tenda da tavolo",
    "grid_items_title2": "Menù digitale",
    "grid_items_title3": "Menù da asporto",
    "grid_items_title4": "Menu mezza pagina",
    "grid_items_title5": "Menù digitale 5",
    "grid_items_title6": "Menù digitale 6",
    "grid_items_title7": "Menù digitale 7",
    "grid_items_title8": "Menù digitale 8",
    "grid_items_title9": "Menù digitale 9",
    "enter_valid_form_name": "Inserisci un nome di modulo valido",
    "form_name_required": "Il nome del modulo è obbligatorio",
    "enter_valid_section": "Inserisci un titolo di sezione valido",
    "fraction_half": "1\/2",
    "fraction_two_by_two": "2\/2",
    "form_name": "Nome del modulo",
    "contact_list": "Elenco dei contatti",
    "label_notify_email": "Desideri essere avvisato tramite email?",
    "add_client_details_section": "Aggiungi sezione dettagli cliente",
    "demo_purpose_only": "È solo a scopo dimostrativo",
    "form_updated_success": "modulo aggiornato con successo",
    "form_creat_success": "modulo creato con successo",
    "add_custom_section": "Aggiungi una sezione personalizzata",
    "edit_custom_section": "Modifica una sezione personalizzata",
    "forms_list": "Elenco dei moduli",
    "filled_custom_form_details": "Dettagli del modulo personalizzato compilato",
    "custom_form_deletion_warning": "Questo modulo personalizzato verrà eliminato definitivamente",

    "menu_link": "Collegamento al menu",
    "WebSignUpT": "Registrazione elettronica",
    "lifeTConT": "Connessioni per tutta la vita",
    "repCustT": "Clienti abituali",
    "liftTSubT": "Abbonati a vita",
    "overallT": "COMPLESSIVAMENTE",
    "loginCustBDT": "Clienti registrati per giorno",
    "deviceT": "Dati demografici del dispositivo",
    "ageTitle": "Dati demografici per età",
    "ageT": "Fascia d'età",
    "convert_social_media_followers_into_customers": "Convertire i follower dei social media in clienti",
    "signWidDesc": "Crea un widget per registrarti, creare landing page e generare coupon dal tuo sito web o dai social media",
    "qrWidT": "Codice QR intelligente",
    "imgWidT": "Numero di adesione",
    "mobile": "Mobile",
    "YESTitle": "SÌ",
    "NOTitle": "NO",
    "addDelRed": "Aggiungi reindirizzamento della consegna",
    "dlredNameT": "Assegna un nome al reindirizzamento della consegna",
    "conf_text_req": "È richiesto il testo di conferma",
    "cFDesc": "Crea moduli personalizzati per raccogliere in modo efficiente feedback e informazioni specifiche dai clienti",
    "pre_review_questions_enabled": "Prima di chiedere una recensione ai clienti, poniamo loro da 1 a 4 domande.",
    "feel_free_edit": "Sentiti libero di apportare le modifiche che preferisci.",
    "option": "Opzione",
    "confFL": "Questa conferma verrà mostrata dopo che il tuo contatto avrà inviato il modulo di registrazione Web",
    "confT": "Testo di conferma",
    "joinTitle": "Giuntura",
    "mobValidL": "Si prega di controllare il numero di cellulare valido",
    "mobRequiredL": "Il numero di cellulare è obbligatorio",

    "Business_location_label": "La posizione della tua attività",
    "qr_code_selection_txt": "Selezione del codice QR",
    "choose_theme_txt": "Scegli il tema",
    "edit_qr_details_txt": "Modifica i dettagli QR",
    "save_qr_code_txt": "Salva il codice QR",
    "text_color_label": "Colore del testo",
    "background_color_label": "Colore di sfondo",
    "print_txt": "Stampa",
    "customer_will_see_txt": "Ecco cosa vedrà il tuo cliente. Nel passaggio successivo, scegli il colore del tuo marchio.",
    "choose_your_brand_color_&_langauge_title": "Scegli il colore del tuo marchio e la lingua",
    "custom_redirect_link_label": "Link di reindirizzamento personalizzato",
    "redirect_user_to_menu": "Reindirizza l'utente al menu",
    "language": "Lingua",
    "select_language_placeholder": "Seleziona la lingua",
    "capitalize_location_name_txt": "Nome della posizione",
    "created_txt": "Creato",
    "swipe_reputation_management_txt": "Scorri per vedere come apparirà la gestione della tua reputazione",
    "sent_txt": "Inviato",
    "number_label": "Numero",
    "date_label": "Data",
    "gender_demographics_title": "Dati demografici di genere",

    "step1_preview_desc": "Assegna un nome al tuo modulo QR di iscrizione elettronica e aggiungilo all'elenco del tuo gruppo",
    "main_head": "Acquisire nuovi clienti",
    "main_desc": "Ottieni clienti inserendo un codice QR che, una volta scansionato, reindirizza gli utenti a un modulo di registrazione personalizzato.",
    "step1_description": "Acquisire nuovi clienti da altre aziende",
    "form_name_field_placeholder": "Registrazione elettronica Restro",
    "last_step_title": "Congratulazioni! Il tuo widget di iscrizione elettronica è stato creato! 🎉",
    "last_step_desc": "Scorri per vedere come apparirà il tuo modulo di iscrizione elettronica",
    "events_step1_preview_desc": "Assegna un nome al tuo modulo QR per l'evento e aggiungilo all'elenco del tuo gruppo",
    "events_main_head": "Ottieni clienti dagli eventi",
    "events_main_desc": "Ottieni clienti inserendo un codice QR sull'evento: una volta scansionato, gli utenti vengono reindirizzati a un modulo di registrazione personalizzato.",
    "events_step1_desc": "Cattura i clienti dagli eventi e portali in negozio",
    "events_name": "Nome dell'evento",
    "events_form_name_field_placeholder": "Evento di divertimento e cibo Restro",
    "events_last_step_title": "Congratulazioni! Il tuo modulo evento è stato creato! 🎉",
    "events_last_step_desc": "Scorri per vedere come apparirà il modulo del tuo evento",
    "birthday_anniversary_step1_preview_desc": "Assegna un nome al tuo modulo QR per raccogliere i compleanni o gli anniversari dei clienti e aggiungerlo all'elenco del tuo gruppo",
    "birthday_anniversary_main_head": "Colleziona compleanno o anniversario",
    "birthday_anniversary_main_desc": "Ottieni la data di nascita o l'anniversario del cliente e, una volta scansionata, reindirizza gli utenti a un modulo di registrazione personalizzato.",
    "birthday_anniversary_step1_desc": "Raccogli il compleanno e l'anniversario del cliente",
    "restro_text": "Resto",
    "birthday_anniversary_last_step_title": "Congratulazioni! Ottieni il modulo B'day & Anniversary dai clienti creato! 🎉",
    "newspaper_ads_step1_preview_desc": "Assegna un nome al tuo modulo QR per ottenere clienti dagli annunci sui giornali e aggiungilo all'elenco del tuo gruppo",
    "newspaper_ads_main_head": "Ottieni clienti dagli annunci",
    "newspaper_ads_main_desc": "Ottieni clienti dagli annunci sui giornali: quando li scansionano, vengono reindirizzati a un modulo di registrazione personalizzato.",
    "newspaper_ads_step1_desc": "Ottieni clienti tramite annunci sui giornali o annunci pubblicitari all'aperto",
    "newspaper_ads_last_step_title": "Congratulazioni! Ottieni clienti dal modulo di annunci sui giornali creato! 🎉",
    "smart_pamphlets_step1_preview_desc": "Assegna un nome al tuo modulo QR per ottenere i dati dei clienti dagli opuscoli e aggiungerli all'elenco del tuo gruppo",
    "smart_pamphlets_main_head": "Ottieni clienti dagli opuscoli",
    "smart_pamphlets_main_desc": "Ottieni i dati dei clienti dagli opuscoli e, una volta scansionati, reindirizza gli utenti a un modulo di registrazione personalizzato.",
    "smart_pamphlets_last_step_title": "Congratulazioni! Ottieni i dati del cliente dal modulo opuscoli creato! 🎉",
    "social_media_step1_preview_desc": "Assegna un nome al tuo modulo QR per ottenere clienti dai social media e trasformarli in clienti e aggiungerli all'elenco del tuo gruppo",
    "social_media_main_desc": "Ottieni clienti dai social media: quando scansionano i post, gli utenti vengono reindirizzati a un modulo di iscrizione personalizzato.",
    "social_media_form_name_field_placeholder": "Offerta Restro Festival - Post su Facebook",
    "social_media_last_step_title": "Congratulazioni! Crea il modulo social media per i clienti! 🎉",
    "claim_offer_text": "Inserisci i tuoi dati e richiedi subito l'offerta",
    "form_title_description": "Aggiungi il titolo e la descrizione del modulo affinché i tuoi clienti possano inserire i dettagli",
    "no_subscribed_clients": "Il gruppo di clienti selezionato non ha clienti abbonati",
    "welcome_message_offer": "Invia un messaggio di benvenuto ai tuoi clienti, dopo che si sono registrati e dai loro un coupon di offerta",
    "system_grp": "Gruppi di sistema",
    "import_clients": "Importare i clienti",
    "delivery_redirection_name": "Nome di reindirizzamento della consegna",
    "created_at": "Creato a",
    "provide_stamp_user": "Fornire il timbro all'utente",
    "scan_qr_code": "Scansiona il codice QR",
    "add_point": "Aggiungi punto",
    "total_points": "Punti totali",
    "user_profile": "Profilo utente",
    "stamp_history": "Cronologia dei francobolli",
    "reward_txt": "Ricompensa",
    "variant_placeholder": "Piccolo, medio, grande, ecc.",
    "sent_coupon": "Buono inviato",
    "order_status": "Stato dell'ordine",
    "payment_status": "Stato del pagamento",
    "order_currency": "Valuta dell'ordine",
    "card_data": "Dati della carta",
    "reward_expiring_on": "Ricompensa in scadenza il",
    "reward_status": "Stato di ricompensa",
    "reward_get_date": "Ricompensa Ottieni Data",
    "reward_name": "Nome della ricompensa",
    "add_point_type": "Aggiungi tipo di punto",
    "restaurant_location": "Posizione del ristorante",
    "collect_points": "Raccogliere punti",
    "rewards_expire": "Scadenza dei premi",
    "rewards_redeem": "Riscatta i premi",
    "total_rewards": "Ricompense totali",
    "created_by": "Creato da",
    "anniversary": "Anniversario",
    "remove_user_group_txt": "Rimuovi utente dal gruppo",
    "delete_permanently_grp_msg": "Questo client verrà eliminato definitivamente dal gruppo.",
    "delete_multiple_permanently_grp_msg": "Questi client verranno eliminati definitivamente dal gruppo.",
    "delete_multiple_client": "Questi client verranno eliminati definitivamente.",
    "messages": "Messaggi",
    "management": "Gestione",
    "client": "Cliente",
    "country": "Paese",
    "state": "Stato",
    "city": "Città",
    "zip_code": "Cap",
    "1_sparkly_point_txt": "🎉 Hai guadagnato 1 punto scintillante! 🌟",
    "select_country_txt": "Seleziona il paese",
    "select_state": "Seleziona stato",
    "ls_thanks_for_join": "Grazie per esserti iscritto alla nostra tessera fedeltà.🎁🎈",
    "month": "Mese",
    "welcome_message": "Invia un messaggio di benvenuto ai tuoi clienti, dopo la registrazione",
    "all_rewards_txt": "Tutti i premi",
    "active_rewards": "Ricompense attive",
    "expired_and_redeemed": "Scaduto e riscattato",
    "redirect_3rd_party_delivery": "Reindirizza la consegna di terze parti al tuo negozio o alla tua piattaforma di ordinazione online",
    "search_or_start_new": "Cerca o inizia una nuova ricerca",
    "contacts": "Contatti",
    "chats": "Le chat",
    "add_by_restaurant": "Aggiungi per ristorante",
    "using_scan_code": "Utilizzo del codice di scansione",
    "step": "Fare un passo",
    "list_widget_text": "Elenco dei widget",
    "swipe_free_wifi_txt": "Scorri per il wifi gratuito",
    "create_widget_txt": "Crea widget",
    "redeem_award_msg": "Sei sicuro? Vuoi riscattare questa ricompensa",
    "on_collect_msg": "In Collezionamento",
    "pause": "Pausa",
    "resume": "Riprendere",
    "location_added": "posizione aggiunta",
    "sub_user_admin_permissions_txt": "Consenti all'utente secondario di accedere alle autorizzazioni di amministratore in Digital Signage",
    "unlist": "Non elencare",
    "cannot_delete_default_terms": "Impossibile eliminare i termini e le condizioni predefiniti",
    "something_went_wrong": "Qualcosa è andato storto!",
    "card_download_success": "Scheda scaricata correttamente",
    "permission_denied": "Permesso negato!",
    "invalid_qr_code": "Si prega di scansionare il codice QR valido",
    "select_customer": "Seleziona il cliente",
    "online": "IN LINEA",
    "offline": "NON IN LINEA",
    "no_data": "Oops, nessun dato trovato!",
    "forever_free": "Per sempre libero",
};

export default it