const no = {
    "rated_google_reviews": "Vurdert 5,0 på Google Anmeldelser",
    "get_your_digital_menu": "Få din digitale meny—",
    "instant_popup_menu": "Øyeblikkelig hurtigmeny uten QR-skanning",
    "free_forever": "Gratis for alltid",
    "the_future_is_here": "Fremtiden er her!",
    "upgrade_your_restaurant": "Oppgrader restauranten din med en fantastisk digital meny uten kostnad. Tiltrekk flere kunder fra og med i dag.",
    "get_my_free_menu_now": "Få min gratismeny nå",
    "no_credit_card_required": "*Ingen kredittkort kreves",
    "join_largest_marketplace": "Bli med på den største restaurantmarkedsplassen!",
    "what_will_you_get": "Hva får du",
    "free": "GRATIS",
    "free_digital_menu": "Gratis digital meny 2D eller 3D",
    "free_digital_menu_desc": "Si farvel til papirmenyer og dyre opptrykk",
    "quick_and_easy_signup": "Rask og enkel påmelding",
    "communicate_with_customers_title": "Kommuniser med kunder GRATIS!",
    "features1": [
        "Spar på utskriftskostnader og nyt umiddelbare menyoppdateringer når du trenger dem.",
        "Få tilgang til din digitale meny på hvilken som helst enhet",
        "Miljøvennlig og problemfri"
    ],
    "features2": [
        "La kundene virtuelt utforske rettene dine med imponerende 3D-bilder.",
        "Øk engasjementet ved å gi middagsgjestene en realistisk forhåndsvisning av måltidet deres.",
        "Skil deg ut med en menyopplevelse som føles like fristende som maten din smaker."
    ],
    "features3": [
        "Hils gjestene umiddelbart med menyen din når de kommer inn i restauranten.",
        "Send automatisk oppdateringer om spesialtilbud og kampanjer til telefonene deres.",
        "Forbedre matopplevelsen med rask, kontaktløs tilgang til menyen din."
    ],
    "feature4": [
        "Fremhev sesongtilbud, daglige spesialiteter og eksklusive kampanjer.",
        "Trekk kundenes oppmerksomhet til de mest populære varene dine.",
        "Skap haster og spenning med iøynefallende grafikk og beskrivelser."
    ],
    "feature5": [
        "Bli en del av et nettsamfunn med tusenvis av daglige besøkende.",
        "Øk synligheten og nå ut til nye kunder som aktivt søker etter spisesteder.",
        "Sikre deg en topplassering tidlig for å maksimere eksponeringen din og tiltrekke flere spisegjester."
    ],
    "section3_heading": "Hvorfor gå digitalt? Fordi det er gratis og enkelt!",
    "section3_description": "Lås opp kraftige funksjoner designet for å øke restaurantens appell.",
    "instant_menu": "Øyeblikkelig meny",
    "instant_menu_notification": "Pop-up og varsling",
    "instant_menu_description": "Velkommen gjester med umiddelbar menytilgang så snart de kommer inn. Send automatisk siste meny og spesialtilbud direkte til mobiltelefonene deres",
    "digital_menu": "Digitale menyer",
    "at_zero_cost": "til null pris",
    "digital_menu_description1": "Si farvel til papirmenyer og dyre opptrykk. Bytt til en digital meny som alltid er oppdatert – og den er gratis for alltid!",
    "digital_menu_description2": "Med vår digitale meny er tilbudene dine alltid oppdaterte og tilgjengelige på alle enheter. Spar penger og forenkle menyoppdateringer med en sømløs, nullkostnadsløsning.",
    "try_me_button": "Prøv meg",
    "try_3d_menu_title": "3D-menyer",
    "try_3d_menu_title2": "Gratis - WOW!",
    "try_3d_menu_description1": "Få oppvasken til å sprette ut av skjermen. Last opp 3D-bilder og la kundene virtuelt smake på maten din før de går inn.",
    "try_3d_menu_description2": "3D-bilder av høy kvalitet skaper en appetittvekkende forhåndsvisning, forbedrer appellen og oppmuntrer bestillinger.",
    "try_now": "Prøv nå",
    "showcase_title": "Vis frem din",
    "showcase_specials": "Spesialtilbud",
    "showcase_specials_description1": "Har du en appetittvekkende avtale eller en uslåelig kampanje? Fremhev det på den digitale menyen din og trekk inn flere sultne folk.",
    "showcase_specials_description2": "Gjør kampanjer umulige å gå glipp av, vekk oppmerksomhet med dristige bilder og fristende beskrivelser.",
    "marketplace": "Markedsplass",
    "marketplace_description": "Vi piskes opp en markedsplass med over 2000 daglige besøkende. Hopp om bord nå, så står du først i køen når vi lanserer. Du kan nå nye kunder som aktivt leter etter spisesteder. Sikre deg plass tidlig for å maksimere eksponeringen!",
    "see_other_products": "Se Andre produkter",
    "about_us": "Om oss",
    "why_serving_free": "Hvorfor vi serverer dette for",
    "serving_free_highlight": "Gratis",
    "about_desc": "Vi er lokale folk her i Dallas, forpliktet til å hjelpe restauranter som din til å trives.",
    "about_tool_free": "Vi tilbyr dette kraftige verktøyet helt gratis fordi vi tror på å støtte fellesskapet vårt.",
    "no_catch": "Ingen fangst, ingen spøk. Vi vet at når du lykkes, blir samfunnet vårt sterkere. 😊",
    "understanding_challenges": "Vi forstår utfordringene ved å drive en bedrift, og det er grunnen til at vi er dedikerte til å tilby verktøy som gjør driften din jevnere og rekkevidden din bredere.",
    "community_growth": "Etter hvert som du vokser, vokser samfunnet vårt med deg, og skaper en ringvirkning av suksess og støtte. La oss sammen heve matopplevelsen for alle.",
    "how_it_works": "Hvordan det fungerer",
    "three_simple_steps": "Tre enkle trinn til",
    "get_started_highlight": "Kom i gang",
    "no_tech_skills_needed": "Ingen tekniske ferdigheter nødvendig. Hvis du kan snu en burger, kan du gjøre dette!",
    "step1_title": "Vis kampanjen din GRATIS!",
    "step1_desc": "Kom i gang raskere enn du kan si «Bestill!» Bare fyll ut et enkelt skjema.",
    "step2_title": "Tilpass menyen din",
    "step2_desc": "Legg til rettene dine, last opp bilder selv i 3D og fremhev dine spesialiteter.",
    "step3_title": "Del og voks",
    "step3_desc": "Del din digitale meny via QR-er, sosiale medier eller nettstedet ditt.",
    "testimonials": "Attester",
    "testimonials_section_title": "Ikke bare ta vårt ord for det",
    "testimonials_section_desc": "Se hva andre Dallas-restauranteiere sier.",
    "faq_section_title": "Har du spørsmål? Vi har svar",
    "faq_section_desc": "Alt du trenger å vite før du begynner.",
    "faqs": "Vanlige spørsmål",
    "faq_answers": [
        {
            "que": "Er det virkelig gratis for alltid?",
            "ans": "Absolutt! Ingen skjulte avgifter, ingen kredittkort, ingen bindinger."
        },
        {
            "que": "Trenger jeg noe teknisk kunnskap?",
            "ans": "Hvis du kan sende en e-post, kan du gjøre dette. Så enkelt er det."
        },
        {
            "que": "Hvorfor tilbyr du dette gratis?",
            "ans": "Vi tror på å gi først. Nedover linjen har vi kanskje andre tjenester du vil elske – men det er helt opp til deg."
        },
        {
            "que": "Hva er greia med markedsplassen?",
            "ans": "Vi koker det opp! Når vi har nok restauranter om bord, lanserer vi markedsplassen din for å gi deg enda mer eksponering."
        }
    ],
    "testimonials_content1": "Å bytte til en digital meny var det beste trekket vi gjorde! Kundene våre elsker bekvemmeligheten og det oppdaterte utseendet!",
    "testimonials_name1": "Morris George",
    "testimonials_restaurant_name1": "The Mansion Restaurant",
    "testimonials_content2": "Vår nye digitale meny gjør surfingen enklere for gjestene, og den har virkelig økt engasjementet!",
    "testimonials_name2": "Amelia Johnson",
    "testimonials_restaurant_name2": "Urban spiser",
    "testimonials_content3": "Gjestene blir imponert over 3D-bildene – det har hjulpet oss å skille oss ut og forbedret matopplevelsen deres!",
    "testimonials_name3": "Davis Green",
    "testimonials_restaurant_name3": "Byildsted",
    "ready_to_boost_your_restaurant": "Klar til å øke restauranten din?",
    "ready_to_boost_desc": "Ikke bli etterlatt. Bli med i den digitale revolusjonen i dag!",
    "yes_i_want_my_free_digital_menu": "Ja, jeg vil ha min gratis digitale meny",
    "480_plus": "480+",
    "people_already_joined": "folk har allerede blitt med",
    "menu_online_home": "Meny Online Hjem",
    "welcome": "Velkomst",
    "description_other": "La oss øke kundetilfredsheten din!",
    "benefits": "Fordeler",
    "greeting_text": "Velkommen 👋",
    "all_in_one_features": "🚀 Alt-i-ett-funksjoner for å heve restauranten din",
    "menu_online_menu_name": "Meny på nett",
    "menu_online_description": "Digitaliser menyen din for enkel nettilgang for kunder.",
    "menu_online_benefits": "Forbedre brukervennligheten og øke engasjementet ved å la dem se menyen din når som helst og hvor som helst.",
    "ds_name": "Digital skilting",
    "ds_description": "Vis frem tilbud og kampanjer på dynamiske skjermer i butikken.",
    "ds_benefits": "Fang kundenes oppmerksomhet med engasjerende bilder, fremme mersalg og sanntidstilbud uten problemer.",
    "loyalty_punch_name": "Lojalitetshullkort",
    "loyalty_punch_description": "Belønn gjentatte kunder med et digitalt lojalitetsprogram.",
    "loyalty_punch_benefits": "Skap kundebevaring og øk salget ved å stimulere lojalitet med innløselige belønninger for hyppige besøk.",
    "marketing_menu_name": "Markedsføring",
    "marketing_description": "Nå ut til flere kunder med målrettede digitale markedsføringskampanjer.",
    "marketing_benefits": "Utvid restaurantens rekkevidde og øk fottrafikk med tilpassede kampanjer skreddersydd for å tiltrekke seg nye og tilbakevendende kunder.",
    "get_more_reviews_menu_name": "Få flere anmeldelser",
    "get_more_reviews_description": "Oppmuntre fornøyde kunder til å legge igjen positive anmeldelser på nett.",
    "get_more_reviews_benefits": "Øk restaurantens troverdighet og tiltrekke deg nye spisegjester ved å forbedre ditt rykte på nettet med flere positive anmeldelser.",
    "guest_smart_wifi_menu_name": "Gjestes Smart Wifi",
    "guest_smart_wifi_description": "Tilby gratis Wi-Fi for å fange gjestedata for markedsføring.",
    "guest_smart_wifi_benefits": "Øk kundetilfredsheten samtidig som du samler inn verdifull kundedata for å tilpasse fremtidige kampanjer.",
    "reservation_menu_name": "Reservasjon",
    "reservation_description": "La kunder enkelt reservere bord online.",
    "reservation_benefits": "Forbedre matopplevelsen ved å minimere ventetidene, og effektivisere reservasjoner for bedre å administrere sitteplasser.",
    "pos_integration_menu_name": "POS-integrasjon",
    "pos_integration_description": "Integrer systemet vårt med ditt POS-system.",
    "pos_integration_benefits": "Forenkle ordrebehandlingen og reduser feil ved å holde nett- og butikksystemene dine synkronisert.",
    "online_ordering_menu_name": "Online bestilling",
    "online_ordering_description": "Gjør det mulig for kunder å legge inn bestillinger direkte fra nettstedet eller appen din.",
    "online_ordering_benefits": "Øk salget og reduser avhengigheten av tredjepartsplattformer ved å tilby et direkte bestillingsalternativ som sparer gebyrer.",
    "pro_label": "Pro",
    "coming_soon": "Kommer snart",
    "locked_label": "Låst",
    "forever_label": "For alltid",
    "new_label": "Ny",
    "other_products_page_title": "Andre produkter | Meny på nett",
    "other_products_description": "Meny Online Personvernerklæring",
    "other_products_header_title": "Andre produkter",
    "privacy_policy": {
        "title": "Personvernerklæring",
        "lastUpdated": "Sist oppdatert: 1. juli 2024",
        "introduction": {
            "header": "Personvernerklæring for nye og eksisterende kunder",
            "paragraph1": "FØLGENDE NØKKELPUNKTER (INKLUDERT SEKSJONSREFERANSER) GIVER KUN FOR DIN bekvemmelighet, og ER IKKE I STEDET FOR DE FULLSTENDIGE VILKÅRENE OG BETINGELSENE. DET ER DITT ANSVAR Å LESE DISSE VILKÅR OG BETINGELSER FØR DU BRUKER MENUONLINE INC-TJENESTEN.",
            "paragraph2": "Å bruke Menuonline betyr at du godtar vilkårene og betingelsene og representerer og garanterer at du er autorisert av din organisasjon eller bedrift til å gjøre det. (Se s.3 \"Slik fungerer det\")",
            "paragraph3": "Menuonline Inc er en tillatelsesbasert markedsføringstjeneste. Bevisst sending av \"spam\" eller brudd på vilkårene og betingelsene vil resultere i stenging av kontoen din. (Se s.16 \"Akseptabel bruk av tjenesten\")",
            "paragraph4": "Data du er vert for i Menuonline Inc, slik som kontaktoppføringer, e-postinnhold og informasjon du samler inn gjennom Menuonline Inc, tilhører deg. (Se s.14 \"Hva eier du?\").",
            "paragraph5": "Menuonline Inc-programvaren og innholdet på nettstedet vårt tilhører oss. (Se s.13 \"Hva eier vi?\") Bortsett fra dine egne brukere, kan dataene eller det upubliserte innholdet du hoster hos Menuonline Inc bare sees av Menuonline Inc-ansatte og partnere som har signert konfidensialitetsavtaler. (Se s.14 \"Hva eier du?\")",
            "paragraph6": "All personlig identifiserbar informasjon (PII) lagres kun på servere i Canada. (Se s.14 \"Hva eier du?\").",
            "paragraph7": "Menuonline Inc-tjenesten er underlagt lovene i Canada og provinsen Ontario. (Se s.24 \"Gjeldende lov\")",
            "paragraph8": "Alle forespørsler om dine data eller innhold fra enkeltpersoner, politi eller andre reguleringsbyråer fra Canada eller utenfor Canada vil alltid bli henvist til deg. Det eneste unntaket ville være hvis dette setter oss i strid med kanadisk eller Ontario lov; imidlertid vil vi i alle tilfeller iherdig motstå enhver utgivelse av dataene dine fra andre enn deg. (Se s.14 \"Hva eier du?\").",
            "paragraph9": "Menuonline Inc sikkerhet er bare så god som din administrasjon av brukernavn og passord. Vi tar ikke betalt for Menuonline Inc-brukerkontoer, så opprett en separat konto for hver bruker og hold passord hemmelig. Du er ansvarlig for kontoen din. (Se s.3 \"Slik fungerer det\")",
            "paragraph10": "Du kan avslutte kontoen din når som helst. Vi gir ikke refusjon på ubrukt kreditt hvis du avbestiller. (Se s.3 \"Slik fungerer det\")"
        },
        "termsAndConditions": {
            "title": "MENUONLINE INC VILKÅR OG BETINGELSER",
            "lastUpdate": "SISTE OPPDATERING: 14. januar 2020",
            "paragraph1": "Vennligst les disse vilkårene og betingelsene for tjenesten (“Vilkårene”) nøye før du bruker denne nettsiden (“Siden”). Disse vilkårene fritar Menuonline Inc. (\"Menuonline Inc\" \"vi\", eller \"oss\") og andre fra ansvar og\/eller begrenser vårt og deres ansvar og inneholder andre viktige bestemmelser som gjelder for din bruk av dette nettstedet. Ved å besøke eller bruke dette nettstedet eller tjenesten, samtykker du på dine egne vegne, og på vegne av enhver organisasjon på hvis vegne du kan opptre (samlet referert til her som \"deg\"), å akseptere og overholde disse vilkårene for hver bruk av og hvert besøk på dette nettstedet."
        },
        "services": {
            "title": "Våre tjenester.",
            "paragraph": "Tjenestene som vi tilbyr gjennom nettstedet er en tjeneste som lar deg opprette, sende og administrere e-postmeldinger (\"E-poster\") til mottakere du velger (\"Tjenesten\")."
        },
        "license": {
            "title": "Tillatelse.",
            "paragraph": "I henhold til disse vilkårene gir Menuonline Inc deg herved en ikke-eksklusiv, begrenset, ikke-overførbar lisens i løpet av perioden til å se nettstedet og bruke tjenesten som tillatt av funksjonene i tjenesten. Vi forbeholder oss alle rettigheter som ikke er uttrykkelig gitt her i tjenesten og Menuonline Inc-innholdet (som definert nedenfor)."
        },
        "emailingContent": {
            "title": "E-post med innhold.",
            "paragraph": "Når du samhandler med Menuonline Inc via denne siden eller tjenesten, godtar du å gi sann og nøyaktig informasjon. Videre forstår du at å gi konfidensiell informasjon via e-post er en usikker praksis. Du forstår at du er eneansvarlig for alt du sender til alle som bruker tjenesten vår."
        },
        "support": {
            "title": "Støtte.",
            "paragraph": "Vi gir deg støtte for å hjelpe deg med å bli selvforsynt med tjenesten. Vi gir deg støtte i form av dokumentasjon og videoer på nettstedet, Menuonline Incs støttebillettsystem og via telefon. Du kan nå oss ved å klikke på \"Support\"-fanen øverst på siden. Det er ingen kostnad for produktstøtte; Vi forbeholder oss imidlertid retten til å avbryte støtten for spesifikke kundekontoer etter eget skjønn."
        }
    },
    "terms_condition": {
        "title": "Vilkår for bruk | Meny på nett",
        "header": "Vilkår og betingelser for nye og eksisterende kunder"
    },
    "privacy_policy_title": "PRIVAT POLITIK",
    "privacy_policy_effective_date": "Gjelder fra 16. januar 2020",
    "privacy_policy_general_title": "GENERELL PRIVAT POLITIK",
    "privacy_policy_intro": "menuonline.com (Menuonline og \"oss\") forstår viktigheten av beskyttelse av personvernet til personopplysninger til enkeltpersoner og viktigheten av sikkerheten til konfidensiell informasjon av alle typer. Vi har laget denne private policyen (\"Retningslinjene\") for å demonstrere vår forpliktelse til å beskytte ditt \"personvern\". Denne private policyen forklarer:",
    "privacy_policy_points": [
        "Hvilken personlig informasjon vi samler inn og hvorfor vi samler den;",
        "Hvordan vi bruker din personlige informasjon;",
        "Hvem vi deler din personlige informasjon med; og",
        "Valgene du har for å få tilgang til, oppdatere og fjerne din personlige informasjon fra tjenesten."
    ],
    "privacy_policy_pipeda": "Menuonline administrerer innsamlingen av all informasjon i samsvar med Canadas lov om beskyttelse av personlige opplysninger og elektroniske dokumenter (PIPEDA).",
    "privacy_policy_update_notice": "Vær oppmerksom på at Menuonline forbeholder seg retten til å oppdatere eller endre denne private policyen når som helst. Vi vil varsle deg om eventuelle endringer ved å legge ut en oppdatert policy på denne nettsiden eller slik den gjøres tilgjengelig gjennom tjenestene. Den nyeste versjonen av denne policyen vil styre mycircles bruk av brukernes personlige opplysninger. Ved å fortsette å få tilgang til mycircles nettside eller ved å fortsette å bruke tjenestene etter at endringer er gjort i denne private policyen, godtar du å være bundet av de reviderte vilkårene.",
    "privacy_policy_consent_title": "SAMTYKKE FOR PERSONOPPLYSNINGER",
    "privacy_policy_consent_text": "Ved å gi Menuonline din personlige informasjon gjennom nettstedet vårt eller noen av våre skjemaer, samtykker du til innsamling, bruk og avsløring av slik informasjon i samsvar med disse retningslinjene eller for formålene identifisert for deg på det tidspunktet du ga personopplysningene. All sekundær bruk av personopplysningene vil kun bli behandlet med ditt uttrykkelige samtykke, eller du vil få en mulighet til å velge bort.",
    "privacy_policy_opt_out_text": "Du har muligheten til å trekke tilbake samtykket ditt eller melde deg ut når som helst. Hvis du ønsker å trekke tilbake samtykket ditt eller velge bort det, kan du klikke på lenken for kommunikasjonspreferanser nederst i enhver e-post du mottar fra oss.",
    "privacy_policy_info_collection_title": "INFORMASJON VI SAMLER inn",
    "privacy_policy_info_collection_text": "Menuonline samler inn og behandler personopplysninger i samsvar med denne policyen. Vi vil ikke selge, dele eller leie ut denne informasjonen til andre, bortsett fra det som er redegjort for i denne policyen. Menuonline samler inn personlig informasjon med det formål å tilby våre tjenester eller produkter til deg.",
    "privacy_policy_info_types_text": "Typen informasjon som Menuonline samler inn inkluderer:",
    "privacy_policy_account_holders_title": "Menuonline kontoinnehavere",
    "privacy_policy_registration_info": "Registreringsinformasjon: Hvis du registrerer deg for å bruke tjenester, trenger du en Menuonline-konto for å bli kontoinnehaver (\"Kontoinnehaver\"). Når du registrerer deg for en konto, vil du bli bedt om å oppgi visse grunnleggende opplysninger, for eksempel navn, e-postadresse, brukernavn, passord, firmanavn, yrke, plassering og telefonnummer. Passord er kryptert - Menuonline-ansatte kan ikke se eller hente passord.",
    "privacy_policy_billing_info": "Faktureringsinformasjon: Hvis du abonnerer på våre tjenester, må du gi oss betalings- og faktureringsinformasjon.",
    "privacy_policy_cookies_info": "Informasjonskapsler: Vi bruker informasjonskapsler for å spore besøksdata, for eksempel kilden til nettstedbesøk og antall nettstedbesøk på nettstedet vårt.",
    "privacy_policy_ip_info": "IP-adresser: IP-adresser samles inn om deg som en del av din bruk av tjenestene (f.eks. med det formål å registrere plassering på landnivå og IP-adresser som brukes når du bruker tjenestene våre).",
    "privacy_policy_your_contacts_title": "Dine kontakter",
    "privacy_policy_distribution_list": "Distribusjonsliste: Som en del av din bruk av tjenestene våre, kan du gi oss, eller vi kan samle inn som en del av tjenestene, personlig informasjon om dine kontakter (\"Kontakter\"). Denne informasjonen inkluderer e-postadresser og kan inkludere annen identifiserbar informasjon som navn, firmanavn eller annen informasjon du oppgir til Menuonline mens du bruker tjenestene. Du må sørge for at du får riktig samtykke fra kontaktene dine for å tillate at informasjonen deres behandles av oss.",
    "privacy_policy_billing_info_contacts": "Faktureringsinformasjon: Hvis du abonnerer på våre tjenester, må du gi oss betalings- og faktureringsinformasjon.",
    "privacy_policy_cookies_contacts": "Informasjonskapsler: Menuonline vil kun bruke informasjonskapsler og lignende sporingsteknologier for å samle inn og bruke personlig informasjon om dine kontakter hvis du har lagt til Menuonline-sporingskoden på nettstedet ditt. Eventuelle landingssider som er vert for Menuonline vil automatisk ha en sporingskode.",
    "privacy_policy_ip_contacts": "IP-adresser: IP-adresser samles inn om kontaktene dine som en del av tjenestene (f.eks. med det formål å registrere plassering på landnivå og IP-adressene som brukes når du sender inn et skjema og gir samtykke).",
    "privacy_policy_info_about_contacts_title": "INFORMASJON VI SAMLER INN OM KONTAKTERNE DINE",
    "privacy_policy_info_about_contacts_notice": "*Denne delen gjelder kun for informasjon som samles inn, brukes eller avsløres av Menuonline om en kontoinnehavers kontakter med det formål å tilby tjenestene*",
    "privacy_policy_info_about_contacts_desc": "Menuonline vil kun samle inn, bruke eller utlevere personopplysninger om kontakter dersom kontoinnehaveren har registrert seg for visse tjenester der de gir oss en distribusjonsliste. Kontoinnehavere er eneansvarlig for å sikre at de har overholdt alle gjeldende lover når de gir personlig informasjon om sine kontakter til Menuonline gjennom tjenestene.",
    "privacy_policy_info_auto_collect_title": "Informasjon Menuonline samler inn automatisk",
    "privacy_policy_info_auto_collect_desc1": "Når kontoinnehavers kontakter deltar i en e-postkampanje mottatt fra en kontoinnehaver, eller samhandler med en lenke til en kontoinnehavers tilkoblede nettside, kan Menuonline samle inn informasjon om slik kontakts enhet og interaksjon med e-posten gjennom bruk av informasjonskapsler. Denne informasjonen samles kun inn for å levere tjenestene og for å gi innsikt til kontoinnehaveren.",
    "privacy_policy_info_auto_collect_desc2": "Informasjon vi samler inn om en kontakts enhet og applikasjonene kontakten bruker for å få tilgang til e-poster sendt fra kontoinnehaveren, gjennom våre tjenester, kan inkludere kontaktens IP-adresse, operativsystem, nettleser-ID og annen relatert informasjon om kontaktens system og tilkobling.",
    "privacy_policy_info_auto_collect_desc3": "Informasjon vi samler inn om Kontakts interaksjon med e-poster sendt gjennom tjenestene kan inkludere datoer og klokkeslett da Kontakt får tilgang til e-post og surfeaktiviteter, og informasjon om ytelsen til tjenestene, slik som levering av e-poster og annen kommunikasjon sendt til Kontakt av en kontoinnehaver gjennom tjenestene.",
    "privacy_policy_info_auto_collect_desc4": "Vær oppmerksom på at Menuonline ikke vil kontakte noen av dine kontakter, med mindre det kreves av gjeldende lov.",
    "privacy_policy_why_we_collect_title": "HVORFOR SAMLER VI IN INFORMASJONEN DIN",
    "privacy_policy_why_we_collect_desc": "Menuonline samler inn informasjon for følgende formål:",
    "privacy_policy_why_collect_1": "å gi Menuonline-kontoinnehavere tjenestene de abonnerer på, inkludert bruk av kontoinnehaverinformasjon for tjenestene;",
    "privacy_policy_why_collect_2": "for administrasjon og kontoadministrasjon, samt tilgang til mycircles nettside;",
    "privacy_policy_why_collect_3": "hvis du har samtykket til å motta markedsførings- og reklamemateriell, for å informere deg om andre produkter og tjenester som tilbys av Menuonline; og",
    "privacy_policy_why_collect_4": "for å svare på kommunikasjon fra kontoinnehaver eller for å sende informasjon du har bedt om. Dette kan inkludere, men er ikke begrenset til:",
    "privacy_policy_why_collect_5": "spesialtilbud og oppdateringer;",
    "privacy_policy_why_collect_6": "nyhetsbrev; og",
    "privacy_policy_why_collect_7": "tjenestemeldinger.",
    "privacy_policy_opt_out_desc": "Du kan når som helst velge å velge bort å motta kommunikasjon ved å klikke på avmeldingsknappen nederst i hver e-post som sendes fra Menuonline til deg.",
    "privacy_policy_use_disclosure_title": "BRUK OG OFFENTLIGGJØRING AV PERSONOPPLYSNINGER",
    "privacy_policy_use_disclosure_desc1": "Personlig informasjon som sendes inn til Menuonline, inkludert via Menuonlines nettside eller et foreskrevet skjema, holdes strengt konfidensielt med mindre det avsløres i samsvar med en kontoinnehavers uttrykkelige samtykke eller juridiske krav. I alle tilfeller er all personlig informasjon gitt på en \"Menuonline\"-plattform av kontoinnehavere frivillig: all personlig informasjon gitt av en kontoinnehaver sendes inn etter kontoinnehaverens skjønn. Vær oppmerksom på at tilbakekalling av ditt samtykke kan føre til at du ikke kan få tilgang til eller bruke visse Menuonline-tjenester.",
    "privacy_policy_use_disclosure_desc2": "Menuonline bruker tredjeparter og eksterne tjenesteleverandører med hensyn til sin nettside og tjenester. Kontoinnehaverens personopplysninger kan deles med slike tredjeparter og leverandører. Vi kan også gi aggregert statistikk om kontoinnehavere, salg, trafikkmønstre og relatert informasjon til anerkjente tredjeparter. Menuonline krever at enhver tredjepartsleverandør som har tilgang til personlig informasjon er bundet av forpliktelser i samsvar med denne private policyen og at slike leverandører kun samler inn, bruker eller avslører kontoinnehavers personopplysninger med det formål å tilby Menuonline-tjenester.",
    "privacy_policy_use_disclosure_desc3": "Personlig informasjon gitt av kontoinnehavere vil ikke selges, deles eller leies ut til eksterne organisasjoner med mindre i samsvar med ditt underforståtte eller uttrykkelige samtykke. Menuonline forbeholder seg retten til å kontakte alle kontoinnehavere for å videresende informasjon, svare på spørsmål eller gi melding om eventuelle endringer i programmet eller retningslinjene våre. Vær oppmerksom på at Menuonline ikke vil kontakte noen av kontaktene dine, med mindre det kreves av gjeldende lov.",
    "privacy_policy_use_disclosure_desc4": "Menuonline vil avsløre personlig informasjon bare hvis samtykke er gitt av den aktuelle personen eller:",
    "privacy_policy_disclosure_list_1": "når det er tillatt eller påkrevd ved lov, for eksempel som svar på en stevning eller annen juridisk prosess;",
    "privacy_policy_disclosure_list_2": "til en mottaker av hele eller deler av Menuonline;",
    "privacy_policy_disclosure_list_3": "til Menuonlines tjenesteleverandører i samsvar med denne policyen; eller",
    "privacy_policy_disclosure_list_4": "for å oppfylle juridiske forpliktelser, inkludert men ikke begrenset til regulatoriske rapporteringsplikter.",
    "privacy_policy_service_providers_title": "Menuonline bruker følgende tredjeparter og tjenesteleverandører (personlig informasjon om dine kontakter blir ikke utlevert til \"slike\" tredjeparter):",
    "privacy_policy_service_provider_1": "Vi bruker ManyChat til å tilby nettchattjenester for salg og support, samt oppfølgings-e-poster om disse samtalene.",
    "privacy_policy_service_provider_2": "Vi bruker AskNicely til å spørre kundene våre med det formål å spørre om kvaliteten på tjenesten og støtten de mottar.",
    "privacy_policy_service_provider_3": "Vi bruker join.me til å planlegge kunde- og potensielle kundemøter, supportsamtaler og programvaredemoer.",
    "privacy_policy_service_provider_4": "Vi bruker Google Analytics til å spore anonym nettsideaktivitet og måle bruksmønstrene på nettsiden vår.",
    "privacy_policy_breach_notification_title": "BRUDDSMELDING",
    "privacy_policy_breach_notification_desc": "Menuonline skal overholde og gi kontoinnehavere rimelig hjelp i samsvar med gjeldende lover med hensyn til enhver uautorisert bruk, tilgang eller utlevering av personlig informasjon.",
    "privacy_policy_rights_access_title": "DINE RETTIGHETER OG TILGANG",
    "privacy_policy_rights_access_desc": "Du har rett til innsyn i personopplysninger som Menuonline behandler om deg. Du kan be om informasjon fra oss om:",
    "privacy_policy_rights_access_list_1": "Personopplysningene vi har om deg",
    "privacy_policy_rights_access_list_2": "Kategoriene av personopplysninger det gjelder",
    "privacy_policy_rights_access_list_3": "Formålet med behandlingen",
    "privacy_policy_rights_access_list_4": "Detaljer til hvem din personlige informasjon har\/vil bli utlevert til",
    "privacy_policy_rights_access_list_5": "Hvor lenge vi beholder personopplysningene dine",
    "privacy_policy_rights_access_list_6": "Hvis vi ikke har samlet inn informasjonen direkte fra deg, informasjon om kilden",
    "privacy_policy_rights_access_list_7": "Hvordan sende inn en klage til riktig tilsynsmyndighet",
    "privacy_policy_rights_access_list_8": "Du kan også be om følgende fra oss:",
    "privacy_policy_rights_access_list_9": "At vi oppdaterer ufullstendig eller unøyaktig informasjon om deg",
    "privacy_policy_rights_access_list_10": "Be om at vi sletter personopplysningene dine i samsvar med gjeldende lover",
    "privacy_policy_rights_access_contact": "Du kan be om at vi gjør dine rettigheter ved å kontakte oss på 2851 Dufferin Street, Toronto, ON, Canada, eller ved å sende oss en e-post på {link}.",
    "privacy_policy_accountability_title": "ANSVAR",
    "privacy_policy_accountability_desc": "Menuonline er forpliktet til å være ansvarlig for den personlige og konfidensielle informasjonen du gir oss. For kontaktinformasjon, se slutten av denne policyen.",
    "privacy_policy_links_other_sites_title": "LENKER TIL ANDRE SIDER",
    "privacy_policy_links_other_sites_desc1": "Menuonlines nettside kan inneholde lenker til andre sider. Vær oppmerksom på at Menuonline ikke er ansvarlig for personvernpraksisen til andre nettsteder. Vi oppfordrer våre kontoinnehavere til å være klar over at når de forlater nettstedet vårt, bør de lese de private retningslinjene for hver enkelt nettside som samler inn personlig identifiserbar informasjon.",
    "privacy_policy_links_other_sites_desc2": "Denne private policyen gjelder kun for informasjon som samles inn av oss.",
    "privacy_policy_links_other_sites_desc3": "Hvis du bruker en lenke for å gå fra Menuonline-siden for å kjøpe produkter eller tjenester, fullfører du en kjøpstransaksjon i henhold til reglene og forskriftene til leverandørselskapet, ikke Menuonline.",
    "privacy_policy_cookies_title": "PRIVATE POLITIKKE INFORMASJON",
    "privacy_policy_cookies_desc1": "Menuonline-nettstedet og -tjenestene bruker informasjonskapsler for å samle inn og lagre visse opplysninger i samsvar med denne policyen. En \"informasjonskapsel\" er en liten tekstfil som sendes fra et nettsted og lagres på brukerens datamaskin av brukerens nettleser. Når du besøker et nettsted som bruker informasjonskapsler, lastes en informasjonskapsel ned til datamaskinen eller mobilenheten din. Neste gang du besøker det nettstedet, vil enheten din huske nyttig informasjon som preferanser, besøkte sider eller påloggingsalternativer.",
    "privacy_policy_cookies_desc2": "Informasjonskapsler er mye brukt for å legge til funksjonalitet til nettsider, eller for å sikre at de fungerer mer effektivt. Nettstedet vårt er avhengig av informasjonskapsler for å optimalisere brukeropplevelsen og sikre at nettstedtjenestene fungerer som de skal.",
    "privacy_policy_cookies_desc3": "De fleste nettlesere tillater en viss kontroll for å begrense eller blokkere informasjonskapsler gjennom nettleserinnstillingene, men hvis du deaktiverer informasjonskapsler, kan du finne at dette påvirker din evne til å bruke visse deler av nettstedet eller tjenestene våre.",
    "privacy_policy_cookies_analytical_purpose": "<strong> Analytiske formål: <\/strong> Vi bruker informasjonskapsler for å analysere kontoinnehavers aktivitet for å forbedre nettstedet vårt. For eksempel kan vi bruke informasjonskapsler for å se på samlede mønstre som funksjonene kontoinnehavere bruker. Vi kan bruke en slik analyse for å få innsikt om hvordan vi kan forbedre funksjonaliteten og brukeropplevelsen til nettstedet vårt.",
    "privacy_policy_cookies_preferences_user_experience": "<strong> Dine preferanser og brukeropplevelse: <\/strong> Vi bruker informasjonskapsler for å samle inn viss informasjon om besøkende og kontoinnehavere, for eksempel nettlesertype, server, språkpreferanser og landinnstilling, for å lagre kontoinnehaverens preferanser på nettstedet vårt for å gjøre kontoinnehaverens opplevelse mer konsistent og praktisk. Vi bruker informasjonskapsler for å opprettholde din påloggede tilstand når du besøker nettstedet vårt gjentatte ganger.",
    "privacy_policy_cookies_targeted_ads": "<strong> Tilby måletjenester og bedre målrettede annonser og markedsføring: <\/strong> Vi bruker informasjonskapsler, web-beacons og andre lagringsteknologier fra tredjepartspartnere som Google og Facebook for måletjenester, bedre målretting av annonser og til markedsføringsformål. Dette skjer når du besøker nettsiden vår. Disse informasjonskapslene, web-beacons og andre lagringsteknologier lar oss vise Menuonline-kampanjemateriale til deg på andre nettsteder du besøker på Internett.",
    "privacy_policy_security_title": "SIKKERHET",
    "privacy_policy_security_desc1": "Menuonline gjør anstrengelser for å beskytte konfidensiell informasjon av alle typer, inkludert personlig informasjon, med organisatoriske, fysiske, mekaniske og elektroniske sikkerhetstiltak som er tilpasset sensitiviteten til informasjonen. Serverne våre er lokalisert i Canada, og følger bransjestandarder knyttet til sikkerhet.",
    "privacy_policy_security_desc2": "Vær imidlertid oppmerksom på at vi ikke kan garantere at tiltakene vi opprettholder vil garantere sikkerheten til informasjonen.",
    "privacy_policy_retention_title": "BEVARING",
    "privacy_policy_retention_desc": "Vi beholder personopplysninger om kontoinnehavere og kontakter så lenge det kreves for formålene som er angitt i denne private policyen og i samsvar med oppbevaringsbestemmelsene i bruksvilkårene.",
    "privacy_policy_access_questions_title": "TILGANG, SPØRSMÅL OG BEKYMRINGER",
    "privacy_policy_access_questions_desc": "En person som har sendt inn personopplysninger til Menuonline kan sende inn en skriftlig forespørsel om å få tilgang til eller korrigere hans eller hennes personopplysninger oppbevart av Menuonline.",
    "terms_obj": {
        "terms_conditions_title": "VILKÅR OG BETINGELSER",
        "terms_conditions_subtitle": "Vilkår og betingelser for nye og eksisterende kunder",
        "terms_conditions_desc1": "FØLGENDE NØKKELPUNKTER (INKLUDERT SEKSJONSREFERANSER) GIVER KUN FOR DIN bekvemmelighet, og ER IKKE I STEDET FOR DE FULLSTENDIGE VILKÅRENE OG BETINGELSENE. DET ER DITT ANSVAR Å LESE DISSE VILKÅR OG BETINGELSER FØR DU BRUKER Menuonline-TJENESTEN.",
        "terms_conditions_desc2": "Å bruke Menuonline betyr at du godtar vilkårene og betingelsene og representerer og garanterer at du er autorisert av din organisasjon eller bedrift til å gjøre det. (Se s.3 \"Slik fungerer det\")",
        "terms_conditions_desc3": "Menuonline er en tillatelsesbasert markedsføringstjeneste. Bevisst sending av \"spam\" eller brudd på vilkårene og betingelsene vil resultere i stenging av kontoen din. (Se s.16 \"Akseptabel bruk av tjenesten\")",
        "terms_conditions_desc4": "Data du er vert for i Menuonline, slik som kontaktoppføringer, e-postinnhold og informasjon du samler inn gjennom Menuonline, tilhører deg. (Se s.14 \"Hva eier du?\").",
        "terms_conditions_desc5": "Menuonline-programvaren og innholdet på nettsiden vår tilhører oss. (Se s.13 \"Hva eier vi?\")",
        "terms_conditions_desc6": "Bortsett fra dine egne brukere, kan dataene eller det upubliserte innholdet du hoster med Menuonline kun sees av Menuonline-ansatte og partnere som har signert konfidensialitetsavtaler. (Se s.14 \"Hva eier du?\").",
        "terms_conditions_desc7": "All personlig identifiserbar informasjon (PII) lagres kun på servere i Canada. (Se s.14 \"Hva eier du?\").",
        "terms_conditions_desc8": "Menuonline-tjenesten er underlagt lovene i Canada og provinsen Ontario. (Se s.24 \"Gjeldende lov\")",
        "terms_conditions_desc9": "Alle forespørsler om dine data eller innhold fra enkeltpersoner, politi eller andre reguleringsbyråer fra Canada eller utenfor Canada vil alltid bli henvist til deg. Det eneste unntaket ville være hvis dette setter oss i strid med kanadisk eller Ontario lov; imidlertid vil vi i alle tilfeller iherdig motstå enhver utgivelse av dataene dine fra andre enn deg. (Se s.14 \"Hva eier du?\").",
        "terms_conditions_desc10": "Menuonline-sikkerhet er bare så god som din administrasjon av brukernavn og passord. Vi tar ikke betalt for Menuonline-brukerkontoer, så lag en separat konto for hver bruker og hold passord hemmelig. Du er ansvarlig for kontoen din. (Se s.3 \"Slik fungerer det\")",
        "terms_conditions_desc11": "Du kan avslutte kontoen din når som helst. Vi gir ikke refusjon på ubrukt kreditt hvis du avbestiller. (Se s.3 \"Slik fungerer det\")",
        "terms_conditions_desc12": "Vi kan avslutte kontoen din når som helst. Vi gir refusjon for e-postkreditter og ubrukte tjenester hvis vi avslutter kontoen din. (Se s.3 \"Slik fungerer det\")",
        "terms_conditions_desc13": "Dataene dine vil bli slettet etter kansellering og kan også bli slettet hvis en kontobruker ikke har logget på på 12 måneder eller mer. Vi vil opprettholde aggregerte data fra kontoen din som ikke inkluderer personlig identifiserbar informasjon for statistiske formål. (Se s.3 \"Slik fungerer det\")",
        "terms_conditions_desc14": "E-postkreditter kjøpes på en betal-som-du-go-basis og utløper aldri. Det eneste unntaket er hvis du ikke har logget på kontoen din på 12 måneder. I dette tilfellet forbeholder vi oss retten til å slette kontoen din, dataene dine og tilhørende kreditter. (Se s.10 \"Hva betaler jeg for tjenesten?\")",
        "terms_conditions_desc15": "Vi jobber hardt for å sikre at Menuonline er oppe og går til enhver tid og for å gi forhåndsvarsel hvis vi må midlertidig stanse tjenesten for vedlikehold. Vi garanterer imidlertid ikke at tjenesten vil være oppe og kjører til enhver tid, og vi er ikke ansvarlige for eventuelle tap du lider av å bruke Menuonline, inkludert tap fra din side hvis systemet ikke kjører på et tidspunkt du trenger det. (Se s.17 “Ansvarsfraskrivelser”).",
        "terms_conditions_desc16": "Vi overvåker ikke innholdet ditt eller hvem du sender meldinger til. Vi overvåker imidlertid klager og andre problemer skapt av aktivitetene dine. For mange klager eller problemer kan resultere i kansellering av tjenesten din etter vårt skjønn. (Se s.7 “Overvåking”)",
        "terms_conditions_desc17": "Vi er forpliktet til å tilby utmerket produktstøtte for å hjelpe deg med å bli selvforsynt med Menuonline. Vi gir støtte i form av dokumentasjon og video på vår nettside, Menuonline support billettsystem og på telefon. Det er ingen kostnad for produktstøtte; Vi forbeholder oss imidlertid retten til å avbryte støtten for spesifikke kundekontoer etter eget skjønn. (Se s.5 \"Støtte\")",
        "terms_conditions_desc18": "Produkttjenester er for de gangene du vil at vi skal gjøre noe arbeid relatert til Menuonline i stedet for å gjøre det selv. Vår pris for produkttjenester er $135 per time. Vi tilbyr også konsulenttjenester til høyere priser. Vi forbeholder oss retten til å endre våre priser når som helst. (Se s.6 \"Produkt- og konsulenttjenester\")",
        "terms_conditions_desc19": "Vi vil informere deg hvis vi mener det har vært et brudd på dataene dine og vil gjøre en innsats for å gi relaterte detaljer og informasjon. (Se s.14 \"Hva eier du?\")",
        "terms_conditions_desc20": "Vi forbeholder oss retten til å gjøre endringer i denne avtalen når som helst. Vi vil forsøke å informere deg om endringer i vilkårene og betingelsene, men bruk av tjenesten betyr at du godtar endringer i vilkårene. (Se s.9 “Endringer”).",
        "terms_conditions_desc21": "Vilkårene og betingelsene og personvernerklæringen vår er kun utarbeidet på engelsk, og du godtar at den engelskspråklige versjonen av vilkårene og personvernreglene vil ha prioritet i tilfelle konflikt med en oversatt versjon.",
        "terms_conditions_mycircle": "MYCIRCLE VILKÅR OG BETINGELSER",
        "terms_conditions_last_update": "SISTE OPPDATERING: 14. januar 2020",
        "terms_conditions_usage_agreement": "Vennligst les disse vilkårene og betingelsene for bruk ('Vilkårene') nøye før du bruker denne nettsiden ('Siden'). Disse vilkårene fritar Menuonline ('Menuonline', 'vi' eller 'oss') og andre fra ansvar og\/eller begrenser vårt og deres ansvar og inneholder andre viktige bestemmelser som gjelder for din bruk av dette nettstedet.",
        "terms_conditions_acceptance": "Ved å besøke eller bruke dette nettstedet eller tjenesten, samtykker du på dine egne vegne, og på vegne av enhver organisasjon på hvis vegne du kan opptre (samlet referert til her som \"deg\"), å akseptere og overholde disse vilkårene for hver bruk av og hvert besøk på dette nettstedet.",
        "terms_conditions_services": "1. Våre tjenester.",
        "terms_conditions_services_desc": "Tjenestene som vi tilbyr gjennom nettstedet er en tjeneste som lar deg opprette, sende og administrere e-postmeldinger ('E-poster') til mottakere du velger ('Tjenesten').",
        "terms_conditions_license": "2. Lisens.",
        "terms_conditions_license_desc": "I henhold til disse vilkårene gir Menuonline deg herved en ikke-eksklusiv, begrenset, ikke-overførbar lisens i løpet av perioden til å se nettstedet og bruke tjenesten som tillatt av funksjonene i tjenesten. Vi forbeholder oss alle rettigheter som ikke uttrykkelig er gitt her i tjenesten og Menyonline-innholdet (som definert nedenfor).",
        "terms_conditions_account": "3. Hvordan det fungerer.",
        "terms_conditions_account_desc": "For å bruke tjenesten må du registrere deg for en konto. Kontoen din er gratis. Kontoen din gir deg tilgang til Tjenesten og \"funksjoner\" som vi kan etablere og vedlikeholde fra tid til annen. For å være kvalifisert for en konto må du:",
        "terms_conditions_account_requirements": [
            "være minst atten (18) år gammel og i stand til å inngå kontrakter;",
            "fullføre registreringsprosessen som vi har satt opp;",
            "godta disse vilkårene og betingelsene;",
            "gi sann, fullstendig og oppdatert kontakt- og fakturerings-\/betalingsinformasjon."
        ],
        "responsibility": "Ansvar.",
        "responsibilityText": "Du er alene ansvarlig for aktiviteten som skjer på kontoen din, og du må holde kontopassordet ditt konfidensielt og sikkert. Kontoer er gratis, så vi oppfordrer deg til å sette opp en annen konto for hver bruker du har. Du er også ansvarlig for enhver konto du har tilgang til, enten du har godkjent bruken av den kontoen eller ikke. Du godtar at du er eneansvarlig for din interaksjon med de du sender e-post til, og du vil kun sende e-poster til de personene du har samtykke fra. Du må umiddelbart varsle oss om enhver uautorisert bruk av kontoene dine.",
        "compliance_with_CASL": "Overholdelse av Canadian Anti Spam Legislation (CASL).",
        "compliance_with_CASL_text": "Menuonlines rolle er å lette administrasjonen av samtykkeinnstillinger, ikke å håndheve lovgivning. Det er ditt ansvar å være kompatibel med CASL. Vi hjelper deg ved å angi standardfunksjonalitet og ved å legge til rette for å innhente og oppdatere dokumentert uttrykkelig samtykke og administrere rullerende utløpsdatoer for underforstått samtykke. Du har full kontroll over å administrere samtykke for databasen din; du kan når som helst oppdatere samtykkestatusen for alle kontaktene dine samtidig, overstyre standardinnstillinger og deaktivere automatisk utløp av underforstått samtykkestatus.",
        "closing_account": "Lukke kontoen din.",
        "closing_account_text": "Enten du eller Menuonline kan si opp denne avtalen når som helst og uansett grunn ved å gi varsel til den andre parten. Hvis du oppgir informasjon som er usann, unøyaktig, ikke aktuell eller ufullstendig eller bruker tjenesten på en måte som bryter disse vilkårene og betingelsene, har vi rett til å umiddelbart suspendere kontoen din og avslutte tilgangen din til tjenesten i tillegg til andre rettsmidler tilgjengelig for oss. Hvis du ikke bruker kontoen din på tolv (12) måneder, kan vi kansellere kontoen din. Hvis vi avslutter kontoen din av en eller annen grunn, vil vi refundere deg for eventuelle kreditter du har igjen. Når den er avsluttet, vil vi permanent slette kontoen din og alle data knyttet til den, inkludert e-postene du har sendt. Vi kan opprettholde aggregerte data fra kontoen din som ikke inkluderer personlig identifiserbar informasjon for statistiske formål.",
        "emailing_content": "E-posting av innhold.",
        "emailing_content_text": "Når du samhandler med Menuonline via denne siden eller tjenesten, godtar du å gi sann og nøyaktig informasjon. Videre forstår du at å gi konfidensiell informasjon via e-post er en usikker praksis. Du forstår at du er eneansvarlig for alt du sender til alle som bruker tjenesten vår. formål.",
        "support": "Støtte.",
        "support_text": "Vi gir deg støtte for å hjelpe deg med å bli selvforsynt med tjenesten. Vi gir deg støtte i form av dokumentasjon og videoer på nettstedet, Menuonline-støttebillettsystemet og via telefon. Du kan nå oss ved å klikke på \"Support\"-fanen øverst på siden. Det er ingen kostnad for produktstøtte; Vi forbeholder oss imidlertid retten til å avbryte støtten for spesifikke kundekontoer etter eget skjønn.",
        "product_and_consulting_services": "Produkt- og konsulenttjenester.",
        "product_and_consulting_services_text": "Produkttjenester er for de gangene du vil at vi skal gjøre noe arbeid relatert til Menuonline i stedet for å gjøre det selv. Vår pris for produkttjenester er $125 per time. Vi tilbyr også konsulenttjenester til høyere priser. Vi forbeholder oss retten til å endre våre priser når som helst.",
        "monitoring": "Overvåking.",
        "monitoring_text": "Vi vil ikke overvåke eller bedømme innholdet i informasjon som overføres over tjenesten, men vil undersøke om vi mottar klager på mulig upassende bruk. Menuonline kan, når som helst og etter eget skjønn, ta avgjørelser om at bestemte bruksområder er eller ikke er hensiktsmessig med eller uten varsel til deg, i henhold til følgende retningslinjer.",
        "term": "Periode.",
        "term_text": "Betingelsen begynner når du registrerer deg for en konto hos oss og fortsetter så lenge du bruker tjenesten. Du representerer og garanterer at du har myndighet og evne til å registrere deg for en konto for oss (inkludert hvis du registrerer deg på vegne av en organisasjon).",
        "changes": "Endringer.",
        "changes_text": "Betingelsen begynner når du registrerer deg for en konto hos oss og fortsetter så lenge du bruker tjenesten. Du representerer og garanterer at du har myndighet og evne til å registrere deg for en konto for oss (inkludert hvis du registrerer deg på vegne av en organisasjon).",
        "payment": "BETALING",
        "what_do_i_pay_for_service": "Hva betaler jeg for tjenesten?.",
        "what_do_i_pay_for_service_text": "Du kan kjøpe forhåndsbetalte kreditter for å sende e-post (\"kreditter\") gjennom tjenesten vår, som vi beskriver i detalj på siden \"priser\" på nettstedet vårt. Kredittene utløper aldri, og det er ingen gjentakende gebyrer for disse kredittene. Det eneste unntaket er at hvis du ikke bruker kontoen din på tolv (12) måneder, kan vi kansellere kontoen din og eventuelle ubrukte kreditter. Vi kan endre prisene våre når som helst ved å oppdatere prissidene på nettstedet vårt, som vil gjelde for ditt neste kjøp av kreditter.",
        "how_do_i_pay_for_service": "Hvordan betaler jeg for tjenesten?.",
        "how_do_i_pay_for_service_text": "Du kan betale for kreditter med et hvilket som helst større kredittkort. Vi kan endre hvilke betalingsmåter vi godtar når som helst.",
        "can_i_get_refund": "Kan jeg få refusjon?.",
        "can_i_get_refund_text": "Beløpene du har betalt for ved bruk av tjenesten kan ikke refunderes hvis du avslutter kontoen din. Hvis vi kansellerer kontoen din, refunderer vi deg for eventuelle kreditter du har igjen.",
        "rights": "RETTIGHETER",
        "what_do_we_own": "Hva eier vi?.",
        "what_do_we_own_text": "Bortsett fra innholdet du gir til oss (inkludert, uten begrensning, kontaktoppføringer, e-postinnhold og informasjon du samler inn gjennom tjenesten) (\"ditt innhold\"), alt materiale som er inkludert på nettstedet vårt, tjenesten og alt materiale deri eller overført derved, inkludert uten begrensning, programvare, bilder, tekst, grafikk, illustrasjoner, logoer, patenter, varemerker, musikk, servicemerker, fotografier, andre brukere, lydmerker, fotografier, \"Menuonline-innhold\") (og alle immaterielle rettigheter knyttet til dette) eies av oss eller våre lisensgivere.",
        "what_do_you_own": "Hva eier du?.",
        "what_do_you_own_text": "Vi krever ingen eierskapsrettigheter over innholdet ditt. Innholdet ditt er ditt og du beholder eierskapet til innholdet du laster opp til tjenesten. Ved å dele innholdet ditt gjennom tjenesten godtar du å la andre se, redigere og\/eller dele innholdet ditt i samsvar med innstillingene dine og disse vilkårene og betingelsene. Du representerer og garanterer at du enten eier eller har tillatelse til å bruke alt materialet du bruker i e-postene dine. Vi kan bruke eller avsløre materialet ditt bare slik vi beskriver i disse vilkårene og våre retningslinjer for personvern.",
        "privacy_statement": "Menuonline respekterer personvernet til sine brukere. All personlig informasjon som Menuonline samler inn via denne siden eller tjenesten er underlagt Menuonlines personvernerklæring som er innlemmet ved referanse i disse vilkårene. Vi kan bruke og avsløre informasjonen din i henhold til vår personvernerklæring.",
        "access_to_your_content": "Menuonline vil ikke gi tilgang til innholdet ditt til noen bortsett fra de ansatte og kontraktører av Menuonline som har inngått passende konfidensialitetsavtaler med oss ​​eller vi har ditt samtykke.",
        "governmentRequest": "Hvis vi mottar en forespørsel fra en myndighet eller reguleringsmyndighet om å få tilgang til innholdet ditt, vil vi varsle deg om forespørselen og be om ditt samtykke før vi frigir noe av innholdet ditt. Vi vil motstå alle forespørsler om innholdet ditt og vil ikke frigi innholdet ditt som svar på denne typen forespørsler uten din tillatelse eller kreves av en domstol med kompetent jurisdiksjon eller gjeldende reguleringsorgan.",
        "breachNotification": "I tilfelle vi blir oppmerksomme på at innholdet ditt har vært gjenstand for uautorisert tilgang, bruk, kopiering, tyveri, tap eller bruk eller avsløring i strid med disse vilkårene (et \"brudd\"), vil vi varsle deg umiddelbart og informere deg om hva som har skjedd. Du godtar at brudd vil bli håndtert i samsvar med gjeldende lov, og hvis vi trenger at du informerer medlemmene om e-postdistribusjonslistene dine, vil du godta å gjøre det umiddelbart og som vi instruerer.",
        "contentRetention": "Vi vil beholde og bruke og overføre innholdet ditt i Canada med unntak av din skriftlige forhåndsgodkjenning eller anvisning (for eksempel hvis du velger å sende innholdet ditt til enkeltpersoner utenfor Canada). Serverne som brukes til å levere tjenesten er lokalisert i Canada.",
        "accessTrouble": "Hvis du har problemer med å få tilgang til innholdet ditt, vil Menuonline hjelpe deg med å hente innholdet ditt på tids- og materialbasis.",
        "reportingAbuse": "Hvis du mener at noen bryter noen av disse vilkårene, vennligst gi oss beskjed umiddelbart. Hvis du mottok spam du tror kom fra en annen bruker av tjenesten, hvis du mener at en annen bruker av tjenesten har lagt ut materiale som bryter med opphavsrett, eller hvis du mener at en annen bruker av tjenesten på annen måte bryter disse vilkårene, vennligst kontakt oss på support@menuonline.com.",
        "acceptableUse": "Du representerer og garanterer at din bruk av tjenesten vil være i samsvar med alle gjeldende lover og forskrifter. Du godtar at du er ansvarlig for å avgjøre om tjenesten vil være i samsvar med og er egnet for deg å bruke i din jurisdiksjon. For større sikkerhet godtar du at du skal (og godtar å sikre at dine ansatte, agenter og autoriserte brukere skal) ...",
        "exportLaws": "Vi gir ingen garanti for at nettstedet eller tjenesten er passende eller tilgjengelig for bruk utenfor Canada, og tilgang til dem fra territorier der innholdet deres er ulovlig er forbudt. Du kan ikke bruke eller eksportere eller re-eksportere Menuonline-innholdet eller annet materiale på dette nettstedet eller noen kopi eller tilpasning i strid med gjeldende lover eller forskrifter, inkludert uten begrensning kanadiske og amerikanske eksportlover og -forskrifter.",
        "disclaimers": "Tjenesten og denne siden tilbys \"som den er\" uten noen representasjoner, garantier eller betingelser av noe slag, og Menuonline fraskriver seg uttrykkelig, i den fulle utstrekning det er tillatt i henhold til gjeldende lover, alle representasjoner, garantier og betingelser, uttrykt eller underforstått, i henhold til lov eller på annen måte, inkludert uten begrensning eventuelle underforståtte garantier og betingelser for salgbarhet, eller egnethet for noen spesielle formål eller bruk av tredjeparter...",
        "limitLiability": "Menuonlines samlede ansvar i henhold til denne avtalen, enten det er i kontrakt eller tort (inkludert uaktsomhet), som et resultat av garantibrudd, strengt ansvar eller under noen annen teori om ansvar overhodet, vil være begrenset til direkte skader i et beløp som ikke overstiger de totale gebyrene betalt til Menuonline i henhold til disse vilkårene og betingelsene i de tolv (12) månedene som skjedde umiddelbart før erstatningsansvaret skjedde umiddelbart før de siste månedene.",
        "liabilityExclusions": "Ingen av Menuonline, dets tilknyttede selskaper eller deres respektive direktører, offiserer, ansatte, agenter eller andre representanter (samlet \"Menuonline \"Representanter\") eller operatørene av noen tilknyttede nettverk vil ha noe ansvar eller ansvar i forbindelse med dette nettstedet eller tjenesten for: (i) eventuelle indirekte, følgeskader, tilfeldige, eksemplariske, straffbare eller spesielle skader; eller (ii) for eventuelle skader, enten direkte, indirekte, følgeskader, tilfeldig, eksemplarisk, straffende eller spesiell, karakterisert som tapt inntekt, tapt sparing eller inntekt eller tapt fortjeneste...",
        "indemnity": "Du vil holde Menuonline og\/eller Menuonline-representantene ('Menuonline skadesløse parter') skadesløse fra og mot eventuelle krav fremsatt av tredjeparter, og eventuelle bøter eller straffer som er tildelt mot oss, som oppstår som følge av din bruk av informasjonen du får tilgang til fra denne siden eller din bruk av tjenesten, og ethvert brudd på disse vilkårene...",
        "ipInfringement": "Menuonline skal forsvare, holde deg skadesløs og holde deg skadesløs fra og mot alle krav, krav, handlinger, søksmål eller søksmål fremsatt eller ført mot deg av en tredjepart som påstår at din tilgang til eller bruk av Tjenesten i samsvar med denne Avtalen krenker immaterielle rettigheter i henhold til kanadisk lov til en tredjepart (et \"IP Krenkelseskrav\")...",
        "equitableRelief": "Hvis du bryter disse vilkårene, kan vi søke om forføyning (som betyr at vi kan be om en rettskjennelse for å stoppe deg) eller annen rettferdig lettelse. Du godtar at dette vil komme i tillegg til og uten at det berører andre rettigheter vi måtte ha ved lov eller egenkapital.",
        "linksToOtherSites": "Hvis vi gir noen lenker til tredjeparts nettsteder, er de kun gitt for enkelhets skyld. Hvis du bruker disse koblingene, vil du forlate nettstedet vårt. Vi kontrollerer ikke disse tredjepartssidene eller støtter dem. Du godtar at vi ikke er ansvarlige for noen av disse tredjepartssidene eller innholdet deres...",
        "thirdPartyRequests": "Hvis vi må gi informasjon som svar på en juridisk eller myndighetsforespørsel knyttet til kontoen din eller din bruk av tjenesten, godtar du at vi kan belaste deg for kostnadene våre. Disse gebyrene kan inkludere våre advokathonorarer og kostnadene for vår ansattes tid brukt på å forberede svaret på forespørselen.",
        "assignment": "Denne avtalen, og eventuelle rettigheter og lisenser gitt under denne, kan ikke overføres eller tildeles av deg, men kan tildeles av Menuonline uten begrensning, etter rimelig forhåndsvarsel til deg.",
        "governingLaw": "Disse vilkårene vil bli styrt av og tolket i samsvar med alle gjeldende føderale lover og lovene i Ontario uten hensyn til lovkonfliktens bestemmelser. Du samtykker i å underkaste deg den eksklusive jurisdiksjonen til domstolene i Ontario for avgjørelse av enhver tvist angående disse vilkårene.",
        "severability": "Hvis en domstol med kompetent jurisdiksjon av en eller annen grunn finner at en bestemmelse i disse vilkårene eller deler av dem er ulovlige eller av en eller annen grunn ikke kan håndheves, vil denne bestemmelsen bli håndhevet i den maksimale utstrekning som er tillatt for å gi effekt til hensikten med disse vilkårene, og resten av disse vilkårene vil fortsette i full kraft og effekt og vil forbli håndhevbare.",
        "waiver": "Enhver fraskrivelse av, eller samtykke til å avvike fra, kravene i disse vilkårene vil være effektive bare hvis de er skriftlige og signert av oss, og bare i det spesifikke tilfellet og for det spesifikke formålet det er gitt for. Vår unnlatelse av å utøve eller vår forsinkelse i å utøve, enhver rettighet under disse vilkårene vil ikke fungere som en fraskrivelse av våre rettigheter.",
        "furtherAssurances": "Hver part godtar å signere og levere alle dokumenter og iverksette alle handlinger (eller føre til at disse handlingene utføres) som er nødvendige for å oppfylle sine forpliktelser i henhold til disse vilkårene og betingelsene.",
        "notices": "Alle meldinger til Menuonline må være skriftlige og sendes til oss på e-post. Varsler til oss må sendes til våre kundeservicerepresentanter på support@menuonline.com. Varsler til deg kan sendes til adressen eller e-postadressen du oppgir som en del av registreringen. I tillegg kan vi kringkaste meldinger eller meldinger gjennom dette nettstedet for å informere deg om endringer på dette nettstedet eller andre viktige saker, og disse sendingene vil utgjøre varsel til deg på det tidspunktet vi legger dem ut.",
        "entireAgreement": "Disse vilkårene og vår personvernerklæring utgjør hele avtalen mellom deg og oss med hensyn til din bruk av nettstedet og tjenesten, og erstatter alle tidligere avtaler, representasjoner og forståelser mellom Menuonline og deg med hensyn til dette emnet. I tilfelle konflikt mellom personvernerklæringen og disse vilkårene, vil disse vilkårene og betingelsene gjelde.",
        "language": "Partene har uttrykkelig bedt om og krevd at disse vilkårene og betingelsene, våre retningslinjer for personvern og eventuelle relaterte dokumenter skal skrives på engelsk. Les parties conviennent et exigent expressément que ce Contrat et tous les documents qui s'y rapportent soient rédigés en anglais. Du godtar at den engelskspråklige versjonen av disse vilkårene og personvernreglene vil ha prioritet i tilfelle konflikt med en oversatt versjon.",
        "survival": "Alle bestemmelser i disse vilkårene som etter sin natur eller import er ment å overleve utløp eller oppsigelse av disse vilkårene, skal overleve, inkludert, uten begrensning, seksjonene 17-19."
    },
    "collect_additional_info": "Ønsker du å samle inn ytterligere informasjon?",
    "additionalInfoChoice": "Velg eventuell tilleggsinformasjon du ønsker å samle inn.",
    "additional_info_yes": "Ja",
    "additional_info_noSkip": "Nei, Skip",
    "additional_info_label": "Ytterligere informasjon",
    "confirm_business_location": "La oss først bekrefte bedriftens beliggenhet",
    "custom_get_more_reviews_form": "Egendefinert Få flere anmeldelser-skjema",
    "set_up_custom_form": "Sett opp ditt tilpassede skjema for få flere anmeldelser etter gruppe",
    "reputation_name_label": "Få flere anmeldelser Navn",
    "reputation_name_placeholder": "Skriv inn omdømmenavn",
    "group_label": "Gruppe",
    "group_desc_title": "Gruppebeskrivelsestittel",
    "group_desc": "Gruppebeskrivelse",
    "select_group_placeholder": "Velg Gruppe",
    "required_group_name": "Gruppenavn er påkrevd",
    "before_asking_review": "Før du ber kundene om anmeldelse, la oss stille dem 1 til 4 spørsmål.",
    "feel_free_edit": "Rediger gjerne etter behov.",
    "incentive_settings": "Incentivinnstillinger",
    "incentivize_customer": "Vil du oppmuntre kunden din til å gi en anmeldelse?",
    "choose_incentive": "Velg insentivet du vil referere.",
    "scan_qr_feedback": "Skann QR-koden og del din verdifulle tilbakemelding med oss ​​angående opplevelsen din.",
    "qr_code": "QR-kode",
    "scan_me": "SKAN MEG!",
    "or_title": "ELLER",
    "send_otp_title": "Send OTP",
    "sign_in_title": "Logg på",
    "email_addresss_title": "E-postadresse",
    "reset_password_title": "Tilbakestill passord",
    "remember_password_title": "Husket passord?",
    "digit_code_title": "Vennligst skriv inn 4-sifret kode som ble sendt videre ",
    "not_received_title": "Jeg mottok ingen bekreftelseskode!",
    "resend_title": "Vennligst send på nytt",
    "sign_up_form": "Opprett din konto",
    "sign_up_sub_title": "Logg på nå for å få tilgang til den nyeste innsikten",
    "already_registered_title": "Har du allerede en Menuonline-konto?",
    "reserved_title": "Opphavsrett ©2023 Menuonline. Alle rettigheter forbeholdt.",
    "terms_title": "Vilkår for bruk",
    "policy_title": "Personvernerklæring",
    "sign_up_title": "Registrer deg",
    "terms_conditions_title": "Vilkår og betingelser",
    "business_type_title": "Forretningstype",
    "business_type_sub_title": "Velg en av virksomhetstypene som beskriver virksomheten din bedre!",
    "salon_title": "Salong",
    "restaurant_title": "Restaurant",
    "pizza_store_title": "Pizzabutikk",
    "food_truck_title": "Food Truck",
    "check_domain_title": "Sjekk domenenavn",
    "start_exploring_title": "Begynn å utforske",
    "sign_in_form": "Logg på kontoen din",
    "sign_in_sub_title": "Logg på nå for å få tilgang til den nyeste innsikten",
    "remember_title": "Husk meg",
    "otp_title": "OTP",
    "password_title": "Passord",
    "forgot_title": "Glemt passord?",
    "not_registered_title": "Har du ikke en konto?",
    "create_account_title": "Registrer deg nå",
    "create_form_title": "Lag skjema",
    "first_name_title": "Fornavn",
    "last_name_title": "Etternavn",
    "email_title": "E-post",
    "mobile_number_title": "Mobilnummer",
    "birthday_title": "Fødselsdag",
    "gender_title": "Kjønn",
    "address_title": "Adresse",
    "short_answer_title": "Kort svar",
    "long_answer_title": "Langt svar",
    "single_answer_title": "Enkelt svar",
    "single_checkbox_title": "Enkelt avkrysningsboks",
    "multiple_choice_title": "Flervalg",
    "drop_down_title": "Rullegardin",
    "yes_no_title": "Ja eller Nei",
    "description_text_title": "Beskrivelsestekst",
    "file_upload": "Filopplasting",
    "reset_password_form": "Tilbakestill passordet ditt",
    "reset_password_sub_title": "Tilbakestill passordet ditt raskt og få tilgang til kontoen din.",
    "required_full_name_title": "Fullt navn kreves",
    "required_first_name_title": "Fornavn er påkrevd",
    "valid_first_name_title": "Skriv inn gyldig fornavn",
    "valid_full_name_title": "Skriv inn gyldig fullt navn",
    "required_block_time": "Blokkeringstid er påkrevd",
    "required_last_name_title": "Etternavn er påkrevd",
    "valid_last_name_title": "Skriv inn gyldig etternavn",
    "valid_mobile_number_title": "Skriv inn gyldig mobilnummer",
    "required_mobile_number_title": "Mobilnummer er påkrevd",
    "required_already_mobile_number_title": "Mobilnummer er allerede påkrevd",
    "valid_otp_title": "Skriv inn gyldig otp",
    "valid_email_title": "Skriv inn en gyldig e-post",
    "required_email_title": "E-post er påkrevd",
    "required_description_title": "Beskrivelse er nødvendig",
    "required_description_500_characters": "Vennligst skriv inn minst 500 tegn",
    "required_description_600_characters": "Vennligst skriv inn maksimalt 600 tegn",
    "domain_title": "Domenenavn",
    "required_password_title": "Passord kreves",
    "required_password_regex_title": "Må inneholde minst 8 tegn, minst ett stort tegn, ett lite tegn, ett tall og ett spesialtegn",
    "required_terms_title": "Vennligst godta vilkår og betingelser",
    "required_note_title": "Merknad er påkrevd",
    "required_birthday_title": "Bursdag er påkrevd",
    "no_access_title": "Du har ikke tilgang akkurat nå. Kontakt administratoren din",
    "menu_online_open_txt": "Åpne",
    "loyalty_program_description": "😊 Finn ditt ideelle lojalitetsprogram og tilpass det for å matche merkevaren din!",
    "lp_list_title": "Liste over lojalitetshullkort",
    "lp_n_title": "Lojalitetshullkortnavn",
    "enter_details_title": "Skriv inn detaljer",
    "stamp_allowed_title": "Stempel tillatt",
    "stamp_items_title": "Stempelgjenstander",
    "lpn_req_title": "Lojalitetshullkortnavn er påkrevd",
    "valid_lpn_title": "Skriv inn et gyldig lojalitetshullkortnavn",
    "brand_color_error": "Merkefargen må ha nøyaktig 7 tegn",
    "select_dark_brand_color_title": "Velg gyldig merkefarge",
    "your_brand_color_title": "Din merkevarefarge",
    "upload_logo_title": "Last opp logo",
    "locations_title": "Steder",
    "add_lp_title": "Lag program",
    "location_error": "Velg steder",
    "pvs_title": "Hvor mange frimerker kan en kunde tjene per besøk?",
    "max_apd_title": "Hva er det maksimale antallet frimerker en kunde kan tjene på en dag?",
    "logo_error": "Logo er påkrevd",
    "expiry_title": "Angi utløp på belønninger",
    "add_more_rewards_title": "+ Legg til flere belønninger",
    "prev_step_1_title": "Vis QR for å tjene poeng og nyte belønningene dine.",
    "delete_lp_title": "Dette lojalitetshullkortet vil bli slettet permanent.",
    "delete_user_record_title": "Denne brukerposten vil bli slettet permanent.",
    "redeem_title": "Løs inn",
    "lp_button_title": "Bli med Lojalitetshullkort",
    "lp_download_button_title": "Last ned kort",
    "step_0_title": "Bli med nå, deilige belønninger venter for lojale matelskere som deg.",
    "step_1_title": "Du har blitt med",
    "congrats_step_title": "Nå er du kvalifisert for gratis pizza",
    "reward_title": "Få belønninger ved hvert besøk",
    "visit_title": "Besøk og lås opp",
    "rewards_title": "Belønninger",
    "dummy_address": "3730 Frankfort Ave, Louisville, Kentucky, USA",
    "loyalty_qr_default_text": "Skann QR-koden, bli med på lojalitetshullkortet vårt og nyt belønninger",
    "lp_earn_stamp_txt": "Kunder tjener frimerker ved hvert besøk.",
    "lp_get_rewards_txt": "Få belønninger hver gang du besøker",
    "lp_1_time_visit_txt": "1 gangsbesøk vil du få",
    "lp_uppercase_stamp_text": "Stemple",
    "lp_lowercase_stamp_text": "stemple",
    "lp_txt": "Lojalitet",
    "lp_per_visit_stamp_validation_msg": "Stempel per besøk må være mindre enn maksimalt tillatt stempel per dag",
    "lp_per_max_stamp_validation_msg": "Maksimalt tillatt stempel per dag må være større enn per besøksstempel",
    "lp_valid_value_msg": "Skriv inn gyldig verdi",
    "lp_required_field_msg": "Dette feltet er obligatorisk",
    "lp_stamp_required_msg": "Stempel er påkrevd",
    "lp_valid_stamp_msg": "Angi gyldig stempelverdi",
    "lp_reward_name_required_msg": "Belønningsnavn er påkrevd",
    "lp_unique_stamp_msg": "Stempelverdien skal være unik",
    "lp_benefits": "Et lojalitetsprogram øker gjentatte besøk og inntekter samtidig som det gir kundeinnsikt for personlig tilpasset markedsføring",
    "lp_how_customer_txt": "Her er hvordan kundene dine",
    "lp_punch_card_txt": "lojalitetshullkort",
    "lp_look_txt": "vil se! 😍",
    "lp_desc": "Kunder vil tjene frimerker for hvert besøk, og når de har samlet inn nok, kan de løse inn spesielle belønninger.",
    "lp_edit": "Redigere",
    "lp_looks_good_txt": "Ser bra ut",
    "lp_customer_view_instruction": "Dette er hva kunden din vil se. Last opp logoen din og angi fargetema",
    "lp_customer_earn_stamps": "La kunder tjene frimerker ved hvert besøk og administrere belønningene deres",
    "lp_stamp_rewards_setup": "Angi frimerke for å tjene belønninger, la kunder få belønninger per frimerke",
    "lp_loyalty_program_setup": "Gi lojalitetsprogrammet ditt navn og angi hvor du skal ha dette programmet",
    "lp_prev_step1": "Vis QR for å tjene poeng og nyte belønningene dine.",
    "lp_visit_1_time_txt": "Besøk 1 gang og få",
    "lp_get_free_txt": "Få gratis",
    "lp_visit_allow_txt": "Vis og lås opp",
    "lp_rewards": "Belønninger",
    "repsT": "Få flere anmeldelser",
    "offSup": "Kontorrekvisita",
    "repsNxtV": "På ditt neste besøk",
    "addRepT": "Legg til Få flere anmeldelser",
    "repEntrCpnTxt": "Skriv inn kupongtekst",
    "repEntrCpnTxtBtn": "Skriv inn kupongknapptekst",
    "repMngNxtV": "på ditt neste besøk?",
    "repMngWldL": "Vil du få",
    "discount": "Rabatt",
    "repDisTyp": "Rabatttype",
    "repNameT": "Gi navnet ditt rykte",
    "crtRepT": "Skap omdømme",
    "feedBackT": "Tilbakemelding",
    "confLocT": "Bekreft plassering",
    "businLocT": "Bedriftssted",
    "addInfoT": "Tilleggsinformasjon",
    "repNReqT": "Hent flere anmeldelser-navn er påkrevd",
    "validRepNT": "Skriv inn et gyldig omdømmenavn",
    "groupT": "Gruppe",
    "groupLDescTitleT": "Send kontakter automatisk inn i en gruppe",
    "groupLDescT": "Alle kunder som fyller ut skjemaet vil bli lagt til valgt gruppe.",
    "que2": "Var maten din god?",
    "que3": "Var tjenesten vår vennlig?",
    "que4": "Var tjenesten vår rask?",
    "addAdditionalT": "Vil du legge til ytterligere informasjon?",
    "phNoMandT": "Telefonnummer er obligatorisk!",
    "qrGenT": "QR generert",
    "repFDT": "Dine innspill vil hjelpe teamet vårt til å tjene deg bedre!",
    "letdiT": "La oss gjøre det",
    "tak15secT": "Tar 15 sekunder",
    "defQue": "Ønsker du å motta gode tilbud og kampanjer fra tid til annen?",
    "defNQue": "Vil du at noen skal svare deg?",
    "rateOnGoogleDesc": "Vil du være så snill å rangere oss på google?",
    "mayBeNT": "Kanskje neste gang",
    "yesSureT": "Ja, sikkert",
    "valid_name": "Skriv inn gyldig navn",
    "nameReq": "Navn er påkrevd",
    "backToMainT": "Tilbake til hovedsiden",
    "attachCopT": "Vil du motivere kunden din?",
    "thankYouT": "Takk",
    "lftyT": "Vi ser frem til ditt neste besøk!",
    "repPreT": "Forhåndsvisning av omdømme",
    "offer_exclusive_discounts": "Tilby eksklusive rabatter og kampanjer for å lokke kundene dine.",
    "couponT": "Kupong",
    "locStepDesc": "Bekreft bedriftens beliggenhet",
    "createRepStepDesc": "Lag et tilpasset omdømmeskjema etter grupper",
    "feedbackStepDesc": "Tilbakemeldinger fra kunder",
    "addInfoStepDesc": "Kundefelt og insentivinnstillinger",
    "FedbkkWDT": "Tilbakemelding med data",
    "repAnlytT": "Omdømmeanalyse",
    "todayT": "I dag",
    "rep_last_7_days_t": "Siste 7 dager",
    "last30DaysT": "Siste 30 dager",
    "lastMT": "Siste måned",
    "custRangeT": "Egendefinert område",
    "queWiseAnltT": "Spørsmål Wise Analytics",
    "atL1QueErrT": "Velg minst ett spørsmål",
    "deleteRepTitle": "Dette omdømmet vil bli slettet permanent.",
    "incvCustT": "Ja, stimuler kunden min",
    "collAddInfoT": "Ja, jeg vil gjerne samle inn ytterligere informasjon",
    "totRewT": "Total gjennomgang",
    "totPosRewT": "Total positiv anmeldelse",
    "negFeedT": "Negativ tilbakemelding",
    "posFeedT": "Positiv tilbakemelding",
    "fineT": "Fine Print",
    "enterFineT": "Skriv inn tekst med liten skrift",
    "setThemeT": "Angi temafarge",
    "que1T": "Spørsmål 1",
    "que2T": "Spørsmål 2",
    "que3T": "Spørsmål 3",
    "que4T": "Spørsmål 4",
    "entMailT": "Skriv inn e-postadressen din",
    "reputationQRDefTxt": "Skann QR-koden og del din verdifulle tilbakemelding med oss ​​angående opplevelsen din.",
    "selUser": "Velg Bruker",
    "userT": "Bruker",
    "subUReq": "Vennligst velg brukere",
    "updateLoc": "Oppdater plassering",
    "leadGenT": "Leadgenerering",
    "displayT": "Utstilling",
    "ratingT": "Vurdering",
    "rep_dashboard_negative_feedback": "Negativ tilbakemelding 0 %",
    "rep_dashboard_position_feedback": "Positiv tilbakemelding 0 %",
    "rep_dashboard_total_Feedback_txt": "Total tilbakemelding",
    "rep_dashboard_anony_Feedback": "Anonym tilbakemelding",
    "rep_dashboard_Feeedback_Data_txt": "Tilbakemelding med data",
    "rep_dashboard_review_txt": "Få flere anmeldelser",
    "rep_dashboard_total_Review_txt": "Total gjennomgang",
    "rep_dashboard_total_positive_Review_txt": "Total positiv anmeldelse",
    "rep_dashboard_negative_feedback_txt": "Negativ tilbakemelding",
    "rep_dashboard_connection_txt": "Forbindelse",
    "rep_dashboard_question_wise_analytics_txt": "Spørsmål Wise Analytics",
    "rep_dashboard_date_label": "Velg Dato",
    "rep_dashboard_custom_range_txt": "Egendefinert område",
    "rep_tlt_min": "REP",
    "emojis": "Emojis",
    "media_text": "Legg til media",
    "custom_field_text": "Sett inn egendefinerte felt",
    "shortlink_text": "Legg til kortlenke",
    "use_template_text": "Bruk mal",
    "banner_must_have": "Må ha",
    "res_seamless": "Sømløs",
    "res_table_reservation": "Bordreservasjon",
    "res_system": "System",
    "res_text": "Reservasjoner",
    "md_easily": "Enkelt",
    "md_manage_customers": "Administrer kunder",
    "md_visits_interactions": "besøk og interaksjoner",
    "md_desc": "Gjør servering enklere for kundene dine med bordbestillinger i sanntid. Administrer bestillinger enkelt, reduser ventetidene og sørg for en jevn opplevelse for både ansatte og gjester. Hold restauranten fullbooket mens du opprettholder førsteklasses service og tilfredshet.",
    "engage_convert": "Engasjere og konvertere SMS",
    "coupon_campaign": "og kupongkampanje",
    "sms_read_rate": "SMS-meldinger leses 98 % av tiden",
    "real_time_promo": "Lever kampanjer i sanntid for umiddelbar handling",
    "affordable_reach": "Rimelig måte å nå kunder med solid avkastning",
    "stay_in_touch": "Hold kontakten med kunden din til enhver tid",
    "bc_engage_customers": "Engasjer kunder Konverter SMS",
    "bc_coupon_campaign": "og kupongkampanje",
    "bc_sms_read_rate": "SMS-meldinger leses 98 % av tiden",
    "bc_real_time_promo": "Lever kampanjer i sanntid for umiddelbar handling",
    "bc_affordable_reach": "Rimelig måte å nå kunder med solid avkastning",
    "bc_stay_in_touch": "Hold kontakten med kunden din til enhver tid",
    "ar_automate_engagement": "Automatiser klientengasjementet ditt",
    "ar_with": "med",
    "ar_with_auto_responders": "Autosvarere",
    "ar_personalized_messages": "Send automatisk tilpassede meldinger til bursdager, velkommen nye kunder, og gjenopprett kontakt med tidligere kunder. Spar tid samtidig som du holder hver interaksjon meningsfull og tidsriktig.",
    "sq_custom_forms_tools": "Egendefinerte skjemaer og verktøy – forenkle",
    "sq_custom_forms_ci": "Kundeinteraksjoner",
    "sq_smart_qr_desc": "Lag egendefinerte skjemaer, samle e-signaturer og bruk smarte QR-koder for å strømlinjeforme forretningsprosessene dine. Skreddersy hvert verktøy for å møte kundenes behov og forbedre brukeropplevelsen.",
    "gnc_get_new_cus": "Få nye kunder",
    "gnc_get_customers": "fra sosiale medier – raskt og enkelt!",
    "gnc_social_media_power": "Bruk kraften til sosiale plattformer for å tiltrekke nye kunder og drive salg direkte til bedriften din. Omdiriger tredjeparts leveringsordrer rett til butikken din eller online bestillingsplattform for maksimal kontroll og inntekt.",
    "wifi_connect_guests": "Koble gjester med",
    "wifi_smart": "Smart Wi-Fi",
    "wifi_connect_grow": "& Utvid din innsikt",
    "wifi_smart_wifi_desc": "Gi en problemfri Wi-Fi-tilkobling til kundene dine gjennom en merket splash-skjerm mens du samler inn verdifull kundedata for å forbedre markedsføringstiltak og engasjement.",
    "wifi_create_splash_page": "Opprett gjeste-Wifi Splash-side",
    "instant_menu_create": "Lag din",
    "instant_menu_text": "Øyeblikkelig meny",
    "instant_menu_wm": "innen minutter",
    "instant_menu_desc": "Forbedrer kundeopplevelsene ved å tillate sanntidsoppdateringer, enkel tilpasning og forbedret engasjement, noe som gjør det enklere å vise frem tilbudene dine og holde innholdet ditt ferskt.",
    "instant_menu_create_digital_menu": "Lag en digital meny",
    "loyalty_program_rewards": "Tjen belønninger",
    "loyalty_program_on_visits": "ved hvert besøk",
    "loyalty_program_create": "Lag et lojalitetsprogram",
    "reputation_create": "Skap omdømme",
    "reputation_boost": "Øk din",
    "reputation_text": "Rykte",
    "reputation_reviews": "med flere anmeldelser!",
    "reputation_desc": "Samle inn verdifull tilbakemelding fra kundene dine for å forbedre tjenesten og omdømmet ditt. Del opplevelsen din og hjelp oss å vokse – anmeldelsen din er viktig!",
    "dashboard": "Dashbord",
    "blast_campaign": "Blast-kampanje",
    "blast_campaigns": "Blast-kampanjer",
    "blast_campaign_sub_title": "Start blast-kampanjer for å nå alle kundene dine med få klikk",
    "auto_responders": "Autosvar",
    "auto_responder": "Autosvar",
    "auto_responder_sub_title": "Enkel å bruke arrangementsbaserte kampanjer for kundene dine",
    "smart_qr_form": "Smart QR \/ Form",
    "get_new_customer": "Få ny kunde",
    "marketing": "Markedsføring",
    "clients": "Kunder",
    "group_data": "Grupper data",
    "clients_data": "Kundedata",
    "account": "Konto",
    "user_acc_info": "Brukerkontoinformasjon",
    "current_plan": "Gjeldende plan",
    "payments": "Betalinger",
    "location": "Sted",
    "location_list": "Stedsliste",
    "users": "Brukere",
    "users_sub_title": "Liste over alle brukere og deres detaljer",
    "online_profile": "Online profil",
    "change_password": "Endre passord",
    "menu": "Meny",
    "reseller_dashboard": "Forhandlerdashbord",
    "companies": "Bedrifter",
    "plans_bought": "Planer kjøpt",
    "agreement_t_c": "Avtale T&C",
    "wifi_dashboard": "Wifi Dashboard",
    "hot_spot": "Hotspot",
    "splash_pages": "Splash Pages",
    "get_more_reviews": "Få flere anmeldelser",
    "office_supplies": "Kontorrekvisita",
    "reservation": "Reservasjon",
    "floor_table": "Gulv og bord",
    "guest_smart_wifi": "Gjestes Smart WiFi",
    "digital_signage": "Digital skilting",
    "schedule_down": "Tidsplan",
    "business_settings": "Bedriftsinnstillinger",
    "sub_user_login": "Subuser pålogging",
    "help_center": "Hjelpesenter",
    "switch_to_hub": "Bytt til Hub",
    "meeting_records": "Møteprotokoller",
    "logout": "Logg ut",
    "no_access_text": "Du har ingen tilgang akkurat nå. vennligst kontakt administratoren din",
    "menu_item": "MENYELEMENT",
    "menu_capital": "MENY",
    "good_day": "God dag",
    "no_permission_text": "Du har ikke tillatelse til å utføre denne handlingen!!",
    "enter_valid_pin": "Skriv inn gyldig pinkode",
    "add_reservation": "Legg til reservasjon",
    "edit_reservation": "Rediger reservasjon",
    "per_req": "Antall personer er påkrevd",
    "req_block_time": "Blokkeringstid er påkrevd",
    "pending_status": "I påvente av",
    "booked_status": "Bestilt",
    "completed_status": "Fullført",
    "cancelled_status": "Kansellert",
    "reservation_slot": "Reservasjonsplass",
    "block_time": "Blokkertid",
    "slot_time": "Tidspunkt for reservasjon:  ",
    "template_name_req": "Malnavn er påkrevd",
    "template_type_req": "Maltype er påkrevd",
    "sms_template_req": "SMS-mal kreves",
    "group_req": "Gruppe er påkrevd",
    "valid_date_time": "Skriv inn gyldig dato og klokkeslett",
    "valid_group_name": "Skriv inn et gyldig gruppenavn",
    "reservation_mark_completed": "Denne reservasjonen vil bli merket som fullført.",
    "customer_name": "Kundens navn",
    "date_time": "Dato Tid",
    "table_no": "Tabell nr",
    "floor": "Gulv",
    "customer_name_placeholder": "kundenavn",
    "date_time_placeholder": "dato_klokkeslett",
    "table_no_placeholder": "table_no",
    "floor_placeholder": "gulv",
    "select_floor": "Velg Etasje",
    "select_table": "Velg Tabell",
    "customer_name_macro": "%% kundenavn %%",
    "date_time_macro": "%% dato_klokkeslett %%",
    "table_no_macro": "%% table_no %%",
    "floor_macro": "%% etasje %%",
    "template_name": "Malnavn",
    "login_with_4_digit_pin": "Logg på med din 4-sifrede PIN-kode",
    "login_now_to_access_latest_insights": "Logg på nå for å få tilgang til de siste insignene",
    "mkt_BS_Title": "Blast SMS",
    "step_1": "Tilpass melding",
    "step_2": "Velg Målgruppe",
    "step_3": "Planlegg melding",
    "step_4": "Send en melding",
    "step_5": "Administrer melding",
    "bs_tab_title": "Blast-kampanjesammendrag",
    "default_message": "Hei %% fornavn %%, Spesialrabatt på brunsj! Spis hos oss i dag og få eksklusiv 10 % rabatt på regningen din!",
    "bs_Title": "Velg en kampanjetype",
    "sms_title": "SMS\/MMS",
    "reach_out_to_customer": "Nå ut til kunder gjennom tekst- og multimediemeldinger.",
    "wp_text": "WhatsApp",
    "connect_with_wp": "Koble til kundene dine ved hjelp av WhatsApp 💬",
    "send_discount_offers": "Send rabatttilbud på SMS 📲",
    "send_coupons_via_sms": "Send kuponger via SMS til dine kunder 🎟️",
    "env_Cap": "Tilby eksklusive rabatter og kampanjer for å lokke kundene dine",
    "cmt_Caption": "Nå ut til kunder gjennom tekst- og multimediemeldinger",
    "wp_caption": "Koble til kunder ved hjelp av WhatsApp for sømløs og direkte kommunikasjon",
    "msg_Prev_Title": "Forhåndsvisning av melding",
    "cou_Prev_Title": "Forhåndsvisning av kupong",
    "blast_SMS": "SMS\/MMS-kampanje",
    "bs_P_Msg": "I kø",
    "bs_S_Msg_C": "Mislyktes",
    "bs_F_Msg_Count": "Levert",
    "previous": "Tidligere",
    "next_ST": "Neste",
    "next_S": "Neste trinn",
    "req_Temp_Name_Title": "Malnavn er påkrevd",
    "req_Template_Tx_Title": "Maltekst er påkrevd",
    "add_Temp_Title": "Legg til mal",
    "link_Desc": "Legg til en lenke du ønsker, slik at kunder kan bestille hos deg via Facebook, Google eller din egen nettside.",
    "audience_Title": "Publikum",
    "client_G1_Title": "Nye kunder",
    "client_G2_Title": "Nylige kunder",
    "client_G3_Title": "Lojale kunder",
    "client_G4_Title": "Forfalte kunder",
    "client_G5_Title": "Klienter etter tilkoblingsdato",
    "on_Which_Day": "På hvilken dag",
    "on_Day": "På dagen",
    "immediate_Desc": "Meldingen din vil bli sendt umiddelbart. Klikk Neste for å begynne.",
    "never_Desc": "Denne kampanjen er planlagt å kjøre for alltid til du avslutter den manuelt.",
    "end_Desc": "Kampanjen avsluttes på datoen du velger her.",
    "success_Message": "Kampanjen er lagret!",
    "send_Success_Message": "Kampanjen ble sendt!",
    "msg_Name_Field_Title": "Kampanjetittel",
    "valid_campaign_title": "Skriv inn gyldig kampanjetittel",
    "msg_Type_Title": "Meldingstype",
    "sms_Desc": "1 kreditt per SMS",
    "mms_Desc": "3 studiepoeng per MMS",
    "client_GTO_1": "Dag",
    "client_GTO_2": "Timer",
    "minutes": "Minutter",
    "client_GTO_4": "Uke",
    "time_Option_1": "Dag",
    "time_Option_2": "Timer",
    "c_Grp_Day_1": "90 dager",
    "c_Grp_Day_2": "60 dager",
    "c_Grp_Day_3": "30 dager",
    "first": "Først",
    "second_T": "Sekund",
    "third_T": "Tredje",
    "fourth_T": "Fjerde",
    "fifth_T": "Femte",
    "delete_BS_T": "Denne kampanjen vil bli slettet permanent.",
    "delete_MBS_T": "Disse kampanjene vil bli slettet permanent.",
    "cFT_1": "Telefon",
    "cFT_2": "Kontakt Fornavn",
    "cFT_3": "Kontakt Etternavn",
    "cFT_4": "Kontakt e-post",
    "cFT_5": "Note",
    "cFT_6": "Fødselsdag",
    "cFT_7": "Firmanavn",
    "cF_Meta_Tag_1": "%% telefon %%",
    "cF_Meta_Tag_2": "%% fornavn %%",
    "cF_Meta_Tag_3": "%% etternavn %%",
    "cF_Meta_Tag_4": "%% e-post %%",
    "cF_Meta_Tag_5": "%% merknad %%",
    "cF_Meta_Tag_6": "%% bursdag %%",
    "cF_Meta_Tag_7": "%% firmanavn %%",
    "cF_Name_1": "telefon",
    "cF_Name_2": "fornavn",
    "cF_Name_3": "etternavn",
    "cF_Name_4": "e-post",
    "cF_Name_5": "note",
    "cF_Name_6": "fødselsdag",
    "cF_Name_7": "firmanavn",
    "all_clients": "Alle klienter",
    "aud_Type_2": "Klientgrupper",
    "aud_Type_3": "Kundegrupper",
    "aud_Type_T1": "Alle klienter",
    "aud_Type_T2": "Klientgrupper",
    "aud_Type_T3": "Kundegrupper",
    "new_clients": "Nye kunder",
    "client_G2": "Nylige kunder",
    "client_G3": "Lojale kunder",
    "client_G4": "Forfalte kunder",
    "client_G5": "Klienter etter tilkoblingsdato",
    "never_text": "Aldri",
    "ending_on": "Ender på",
    "send_T2": "Planlagt",
    "send_Type_3": "Tilbakevendende",
    "daily": "Daglig",
    "weekly_T": "Ukentlig",
    "monthly_T": "Månedlig",
    "yearly_T": "Årlig",
    "each": "Hver",
    "on_the": "På The",
    "monday": "mandag",
    "exclusive_offer": "Eksklusivt tilbud til deg",
    "redeem_now": "Løs inn nå",
    "redeem_with_cashier": "Løs inn med kassen",
    "lg_Day_2": "tirsdag",
    "lg_Day_3": "onsdag",
    "lg_Day_4": "torsdag",
    "lg_Day_5": "fredag",
    "lg_Day_6": "lørdag",
    "lg_Day_7": "søndag",
    "msg_Pre_T": "Dominos",
    "cA_Edit_T1": "Alle",
    "cA_Edit_T2": "Inkludert",
    "cA_Edit_T3": "Ekskludert",
    "SMS": "SMS",
    "MMS": "MMS",
    "usd_T": "USD",
    "cad_T": "CAD",
    "msg": "Beskjed",
    "which_Day": "Hvilke dager",
    "end_Date_Tx": "Sluttdato",
    "sDate_Err": "Startdatoen må være større enn eller lik i dag",
    "eDate_Err": "Sluttdatoen må være senere enn startdatoen",
    "start_Date_Req": "Startdato er påkrevd",
    "end_Date_Req": "Sluttdato er obligatorisk",
    "time_req": "Tid kreves",
    "client_GT1": "Kunder abonnerte i siste",
    "client_GT2": "Klienter med tilknytning i siste",
    "client_GT3": "Kunder med minst",
    "client_GT4": "med den siste",
    "client_GT5": "som ikke kom tilbake i det siste",
    "dummy_Phone_No": "+91987-654-3210",
    "test_T": "Test",
    "dummy_Birth_Date": "01-01-2001",
    "image_Req": "Media eller URL er påkrevd",
    "time_Title": "Tid",
    "start_date": "Startdato",
    "end_date": "Sluttdato",
    "auto_Send_T": "Send automatisk til klienter",
    "add_Media": "Legg til media",
    "in_Title": "I",
    "c_Temp_T": "Kampanjemal",
    "temp_NT": "Malnavn",
    "type_T": "Type",
    "select_template_type": "Velg maltype",
    "sel_Temp_T": "Velg Maltype",
    "sms_temp_t": "SMS-mal",
    "temp_T": "Maltekst",
    "default_Msg": "Hei %% fornavn %%, Spesialrabatt på brunsj! Spis hos oss i dag og få en eksklusiv 10% rabatt på regningen din!",
    "refill_T": "Fylle på",
    "avl_Credit": "Tilgjengelige kreditter",
    "req_Credit": "Totalt antall kreditter som kreves",
    "rem_Credit": "Gjenværende kreditt",
    "refill_Credit": "Kreditter som skal fylles på",
    "clients_Req": "Velg minst én klient",
    "subclient_Req": "Utvalgte kunder er ikke abonnenter.",
    "refill_Desc": "Fyll inn de nødvendige studiepoengene",
    "url_Regex": "^((http|https):\/\/).....+$",
    "sd_Invalid": "Startdatoen er ugyldig",
    "ed_Invalid": "Sluttdatoen er ugyldig",
    "img_Url_Invalid": "Ugyldig bildenettadresse",
    "time_Invalid": "Tiden er ugyldig",
    "time_And_Date_Invalid": "Skriv inn en gyldig dato og klokkeslett",
    "time_Invalid_Bfr": "Velg et tidspunkt minst 5 minutter senere fra gjeldende tid",
    "sod_Req": "Send på dagen kreves",
    "add_Card": "Legg til et nytt kort",
    "sm_D_Rep_Title": "SMS\/MMS-leveringsrapport",
    "send_Now_T": "Send nå",
    "schd_Now_T": "Planlegg SMS",
    "test_SMS_T": "Send test-SMS",
    "save_AD_T": "Lagre som utkast",
    "back_TE_T": "Tilbake til Rediger",
    "reset_tex": "Tilbakestill",
    "update_tex": "Oppdater",
    "dum_msg1": "For å vise kundenavn, bruk %% customer_name %% i malen",
    "dum_msg2": "For å vise reservasjonstid, bruk %% date_time %% i malen",
    "dum_msg3": "For å vise tabellnummer, bruk %% table_no %% i malen",
    "embedded_link": "Innebygd lenke",
    "img_title": "Bilde",
    "img_input_text": "Tekst:",
    "img_join_text": " for å bli med på listen vår",
    "img_copy_text": "Kopiere",
    "img_not_found": "Fant ikke generert bilde!!",
    "or_text": "ELLER",
    "web_signup_image_content": "Ved å sende inn dette skjemaet og registrere deg via tekst, samtykker du til å motta markedsføringstekstmeldinger (som kampanjekoder og påminnelser). Meldings- og datatakster kan gjelde. Meldingsfrekvensen varierer. Du kan melde deg av når som helst ved å svare STOPP",
    "img_download_success": "Bildet ble lastet ned",
    "embedded_link_copied": "Den innebygde lenken ble kopiert",
    "media_url_required": "Media eller url er obligatorisk",
    "invalid_image_url": "Ugyldig bildenettadresse",
    "invalid_file": "Filen er ugyldig",
    "image_error_1mb": "Vennligst sett inn et bilde som er mindre enn 1 MB",
    "image_error_2_5mb": "Vennligst sett inn et bilde som er mindre enn 2,5 MB",
    "image_error_3mb": "Vennligst sett inn et bilde som er mindre enn 3 MB",
    "change_title": "Endre",
    "drop_file_text": "Slipp filen her eller klikk for å laste opp",
    "apply_text": "Søke",
    "search_here": "Søk her",
    "update_status": "Oppdater status",
    "reservation_deleted_permanently": "Denne reservasjonen vil bli slettet permanent.",
    "table_text": "Tabeller",
    "add_table_button": "Legg til ny tabell",
    "add_table_text": "Legg til tabell",
    "edit_table_text": "Rediger tabell",
    "table_delete_text": "Denne tabellen vil bli slettet permanent.",
    "multiple_table_delete_text": "Disse tabellene vil bli slettet permanent.",
    "table_error_name": "Vennligst skriv inn tabellnavnet",
    "table_error_invalid_name": "Vennligst skriv inn gyldig tabellnavn",
    "table_error_floor": "Vennligst velg gulvet",
    "table_error_select": "Vennligst velg tabellen",
    "table_capacity_text": "Tabell kapasitet",
    "capacity_text": "Kapasitet",
    "table_occasion_text": "Finn bordet ditt for enhver anledning",
    "table_name_text": "Tabellnavn",
    "table_capacity_error": "Bordkapasitet er nødvendig",
    "cancel_text": "Kansellere",
    "submit_text": "Send inn",
    "select_valid_date": "Velg gyldig dato",
    "select_valid_time": "Velg gyldig tid",
    "valid_contact_number": "Skriv inn gyldig kontaktnummer",
    "date_req": "Dato er påkrevd",
    "date_error_later_than_today": "Datoen må være senere enn eller lik dagens dato",
    "time_error_later_than_5_minutes": "Velg tid minst 5 minutter senere fra gjeldende tid",
    "number_person_req": "Antall personer er påkrevd",
    "contact_no_req": "Kontaktnummer er påkrevd",
    "contact_no": "Kontaktnummer",
    "select_time": "Velg Tid",
    "for_how_many_person": "For hvor mange personer?",
    "f_name": "Fornavn",
    "l_name": "Etternavn",
    "business_name": "Bedriftsnavn",
    "business_no": "Bedriftsnummer",
    "no_for_sign_in": "Dette nummeret brukes til pålogging",
    "business_email": "Bedrifts-e-post",
    "notes_tex": "Notater",
    "floor_deleted_permanently": "Denne etasjen vil bli slettet permanent.",
    "add_floor": "Legg til gulv",
    "edit_floor": "Rediger etasje",
    "name_text": "Navn",
    "mobile_no": "Mobilnr.",
    "person_text": "Person",
    "date_and_time": "Dato og tid",
    "actions_text": "Handlinger",
    "extra_text": "Ekstra",
    "floor_name_req": "Etasjenavn er påkrevd",
    "floor_name": "Etasjenavn",
    "status_text": "Status",
    "category_status_req": "Kategoristatus er påkrevd",
    "table_deleted_permanently": "Denne tabellen vil bli slettet permanent.",
    "tables_deleted_permanently": "Disse tabellene vil bli slettet permanent.",
    "back_to_home": "Tilbake til Hjemmet",
    "link_copied_text": "Linken er kopiert til utklippstavlen",
    "cust_dash_head_to_title": "til",
    "cust_dash_head_location_title": "Sted",
    "select_location_title": "Velg Sted",
    "all_locations_label": "alle_steder",
    "rep_customer_feedback_analytics": "😊 Spor tilbakemeldinger fra kunder med sanntidsanalyse",
    "rep_customer_txt": "Kunder",
    "rep_delete_title": "Dette omdømmet vil bli slettet permanent.",
    "rep_qr_def_txt": "Skann QR-koden og del din verdifulle tilbakemelding med oss ​​angående opplevelsen din.",
    "delete_title": "Slett",
    "user_list_title": "Liste over kunder",
    "os_nfc_txt": "NFC-kort",
    "os_sign_holder_txt": "Skiltholderstativ",
    "os_store_Sign_holder_txt": "Butikkskiltholdere",
    "StaT": "Statistikk",
    "AllgT": "Alle grupper",
    "FavT": "Favoritt",
    "MostActT": "Mest aktive",
    "grT": "Gruppenavn",
    "keywT": "Nøkkelord",
    "exSubT": "Melding til eksisterende abonnent",
    "ArchT": "Arkivert",
    "gNotiSms": "Gi meg beskjed om hver ny kontakt via SMS",
    "gNotiEmail": "Gi meg beskjed om hver ny kontakt via e-post",
    "reqGName": "Gruppenavn er påkrevd",
    "validGN": "Skriv inn et gyldig gruppenavn",
    "valid_phone_no": "Skriv inn gyldig telefonnummer",
    "phone_no_req": "Telefonnummer er påkrevd",
    "required_message_text": "Melding er påkrevd",
    "required_discount_text": "Rabatt kreves",
    "less_than_discount": "Rabattbeløpet må være mindre enn 5000",
    "discount_percentage_invalid": "Rabattprosenten må være mindre enn eller lik 100",
    "discount_type_req": "Rabatttype er påkrevd",
    "discount_text_req": "Kupongtekst er påkrevd",
    "reqContactNo": "Kontaktnummer er påkrevd",
    "reqMsgNT": "Kampanjetittel er påkrevd",
    "reqLinkT": "Link er nødvendig",
    "delMGT": "Disse gruppene vil bli slettet permanent.",
    "defMemMsg": "Skriv inn meldingen din her",
    "add_cust_to_grp_title": "Legg klient til gruppe",
    "group_title": "Grupper",
    "group_create_title": "Opprett gruppe",
    "group_name_txt": "Gruppenavn",
    "group_table_keyword_title": "Nøkkelord",
    "actions_title": "Handlinger",
    "clients_title": "Kunder",
    "send_title": "Sende",
    "qr_title": "QR",
    "delete_group_txt": "Denne gruppen vil bli slettet permanent.",
    "delete_groups_txt": "Disse gruppene vil bli slettet permanent.",
    "delete_client_title": "Denne klienten vil bli slettet permanent.",
    "delete_clients_title": "Denne klienten vil bli slettet permanent.",
    "delete_multiple_title": "Slett flere",
    "wel_sms_mms": "Send velkomst SMS\/MMS?",
    "key_words_title": "Nøkkelord",
    "srch_plch_txt": "Søk her",
    "req_location_id_title": "Plassering er påkrevd",
    "create_text": "Skape",
    "view_text": "Utsikt",
    "immediately": "Øyeblikkelig",
    "business_name_req": "Bedriftsnavn er obligatorisk",
    "business_no_req": "Bedriftsnummer er påkrevd",
    "valid_business_name": "Skriv inn gyldig bedriftsnavn",
    "valid_business_no": "Skriv inn gyldig bedriftsnummer",
    "address_req": "Adresse er påkrevd",
    "valid_address": "Vennligst velg gyldig adresse",
    "time_zone_req": "Tidssone er påkrevd",
    "image_req": "Vennligst sett inn et bilde som er mindre enn 2,5 MB",
    "valid_file": "Filen er ugyldig.",
    "logo": "Logo",
    "time_zone": "Tidssone",
    "save": "Spare",
    "account_type_req": "Kontotype er obligatorisk",
    "gst_no_req": "GST-nummer er påkrevd",
    "gst_no_valid": "Skriv inn gyldig GST-nummer",
    "set_up_payments": "Sett opp betalinger",
    "billing_details": "Skriv inn faktureringsdetaljene dine",
    "billing_details_desc": "Faktureringsdetaljene dine vises på din månedlige faktura fra menuonline.",
    "account_type": "Kontotype",
    "select_account_type": "Velg kontotype",
    "gst_no": "GST-nummer",
    "transaction_details": "Transaksjonsdetaljer",
    "payment_method": "Betalingsmåte",
    "billing_period": "Faktureringsperiode",
    "paid_by": "Betalt av",
    "download": "Last ned",
    "pay_now": "Betal nå",
    "pull_down_refresh": "Trekk ned for å oppdatere",
    "release_refresh": "Slipp for å oppdatere",
    "billing_details_text": "Faktureringsdetaljer",
    "payment_methods": "Betalingsmetoder",
    "invoice": "Faktura",
    "invoice_to": "Faktura til:",
    "issue_date": "Utstedelsesdato",
    "due_date": "Forfallsdato",
    "amount_due": "Forfalt beløp",
    "charges": "Avgifter",
    "sub_total_capitalize": "Subtotal",
    "hst": "HST",
    "grand_total": "Grand Total",
    "invoice_generated_on": "Faktura generert den",
    "contact_us": "Kontakt oss",
    "invoice_issue": "Hvis du har gått gjennom regningen din og fortsatt har spørsmål",
    "call": "Ringe",
    "send_sms": "Send SMS",
    "payment_success": "Betalingen er vellykket",
    "edit_credit_card": "Rediger kredittkort",
    "add_credit_card": "Legg til kredittkort",
    "modify_card_info": "Endre kortinformasjonen din",
    "enter_card_info": "Skriv inn kortinformasjonen din",
    "account_no_req": "Kontonummer er påkrevd",
    "card_name_req": "Navn på kortet er påkrevd",
    "expiry_date_req": "Utløpsdato er påkrevd",
    "valid_expiry_date": "Skriv inn gyldig utløpsdato",
    "valid_cvv": "Skriv inn gyldig cvv",
    "cvv_req": "CVV er påkrevd",
    "card_no": "Kortnummer",
    "name_of_card": "Navn på kortet",
    "expiry": "Utløp",
    "mm_text": "MM\/ÅÅ",
    "cvv": "CVV",
    "set_as_default": "Sett som standard",
    "add_new_card": "Legg til nytt kort",
    "all_credit_card": "Alle dine kredittkort",
    "fastest_checkout": "Raskeste utsjekking med sikker og pålitelig plattform, klarert av store bedrifter",
    "coupon_button_req": "Kupongknapptekst er påkrevd",
    "max19_characters_allowed": "Maksimalt 19 tegn er tillatt",
    "fine_print_text_req": "Fin skrift er påkrevd",
    "clients_req": "Velg minst én klient",
    "client_groups": "Klientgrupper",
    "day_text": "Dag",
    "valid_days": "Dagverdien er for stor skriv inn gyldige dager",
    "hours_text": "Timer",
    "enter_valid_hours": "Timeverdien er for stor skriv inn gyldige timer",
    "enter_valid_min": "Minuttverdien er for stor skriv inn gyldige minutter",
    "clients_with_connection_required": "Klienter med tilkobling i den siste kreves",
    "connection_required": "Tilkobling er nødvendig",
    "connection_value_too_large": "Tilkoblingsverdien er for stor skriv inn gyldig tilkobling",
    "minutes_required": "Det kreves minutter",
    "clients_with_at_least_connection_required": "Det kreves klienter med minst tilkobling",
    "last_minutes_req": "Med de siste minuttene er nødvendig",
    "clients_not_returned_required": "Hvem som ikke kom tilbake i de siste minuttene kreves",
    "not_return_in_last_minutes_invalid": "Ikke retur i siste minutter må være mindre enn de siste minuttene.",
    "customerGroups": "Kundegrupper",
    "select_customer_group": "Velg kundegruppe",
    "location_required": "Plassering er påkrevd",
    "start_date_required": "Startdato er påkrevd",
    "start_date_invalid": "Startdatoen er ugyldig",
    "start_date_invalid_error": "Startdatoen må være større da eller lik i dag",
    "recurring": "Tilbakevendende",
    "end_date_required": "Sluttdato er obligatorisk",
    "end_date_invalid": "Sluttdatoen er ugyldig",
    "end_date_invalid_error": "Sluttdatoen må være senere enn startdatoen",
    "time_invalid": "Tiden er ugyldig",
    "monthly_text": "Månedlig",
    "send_day_req": "Send på dag kreves",
    "loyal_clients": "Lojale kunder",
    "recent_clients": "Nylige kunder",
    "lapsed_clients": "Forfalte kunder",
    "clients_connection_date": "Klienter etter tilkoblingsdato",
    "scheduled_text": "Planlagt",
    "weekly": "Ukentlig",
    "selected_clients_not_subscriber": "Utvalgte kunder er ikke abonnenter.",
    "message_preview_title": "Forhåndsvisning av melding",
    "coupon_preview_title": "Forhåndsvisning av kupong",
    "form_text": "Skjema",
    "preview_text": "Forhåndsvisning",
    "next_text": "Neste",
    "send_test_SMS": "Send test-SMS",
    "save_draft": "Lagre som utkast",
    "back_to_edit": "Tilbake til Rediger",
    "select_payment_method": "Velg betalingsmetode",
    "schedule_SMS": "Planlegg SMS",
    "send_now": "Send nå",
    "get_more_ratings": "Få flere vurderinger",
    "overview": "Oversikt",
    "reset_campaign": "Tilbakestill kampanje",
    "permissions": "Tillatelser",
    "location_name": "Stedsnavn",
    "phone_no": "Telefonnummer",
    "location_email_address": "Steds e-postadresse",
    "located_business": "Hvor ligger bedriften din?",
    "business_logo": "Bedriftslogo",
    "congratulations": "Gratulerer",
    "almost_done": "Du er nesten ferdig",
    "publish": "Utgi",
    "about_your_business": "Om bedriften din",
    "add_your_location": "Legg til din plassering",
    "publish_location": "Publiser plassering",
    "location_name_req": "Stedsnavn er obligatorisk",
    "valid_location_name": "Skriv inn et gyldig stedsnavn",
    "business_logo_req": "Bedriftslogo er påkrevd",
    "please_accept_terms": "Vennligst godta vilkår og betingelser",
    "add_new_location": "Legg til ny plassering",
    "edit_location": "Rediger plassering",
    "add_location": "Legg til plassering",
    "existing_msg_subscriber_txt": "Melding til eksisterende abonnent",
    "msg_capitalize_txt": "Beskjed",
    "group_noti_sms": "Gi meg beskjed om hver ny kontakt via SMS",
    "group_noti_email": "Gi meg beskjed om hver ny kontakt via e-post",
    "group_member_msg": "Det virker som om du allerede er en del av gruppen vår!!",
    "group_mend_msg": "STOPP for å avslutte. HJELP for hjelp. SMS- og datapriser kan gjelde",
    "Disclaimer_title": "Ansvarsfraskrivelsestekst: ",
    "group_def_msg": "Vi er online nå! Sjekk ut vår servicemeny og bestill din neste time i dag.",
    "required_msg_txt": "Melding er påkrevd",
    "required_Key_txt": "Nøkkelord kreves",
    "required_mem_msg": "Abonnentmelding er påkrevd",
    "client_list_title": "Kundeliste",
    "add_contact_txt": "Legg til kontakt",
    "delete_all_clients_txt": "Slett alle klienter",
    "delete_all_clients_msg": "Er du sikker på at du vil slette alle klienter? De kan ikke hentes.",
    "delete_all_txt": "Slett alle",
    "timeline_title": "Tidslinje",
    "unsubscribe_title": "Avslutt abonnementet",
    "subscribe_title": "Abonner",
    "unsubscribe_confirm_msg": "Vil du merke denne kunden som en avmelding?",
    "subscribe_confirm_msg": "Vil du merke denne kunden som abonnent?",
    "no_title": "Ingen",
    "yes_title": "Ja",
    "client_name_title": "Kundens navn",
    "source_title": "Kilde",
    "contact_file_Req": "Kontaktfil er nødvendig",
    "req_title": "Obligatorisk",
    "opt_in_req": "Importerte kontakter må være 100 % opt-in",
    "image_invalid_error": "Filen er ugyldig.",
    "import_contacts_msg": "Importer kontakter",
    "csv_upload_title": "CSV-kontaktfil for å laste opp *",
    "csv_file_desc": "Last opp kun csv-fil - csv-filkolonner - Fornavn, Etternavn, E-post-ID, Mobilnr, Kjønn, DOB(MM\/DD\/ÅÅÅÅ) - ingen mellomrom, bindestreker eller parenteser i mobilnr.",
    "to_download_title": "For å laste ned demofilen klikk her",
    "contains_header_title": "Ja, denne filen inneholder overskrifter",
    "opt_in_100_txt": "Ja, disse importerte kontaktene er 100 % opt-in",
    "DisT": "Ansvarsfraskrivelsestekst: ",
    "gMendMsg": "STOPP for å avslutte. HJELP for hjelp. SMS- og datapriser kan gjelde",
    "reqKeyT": "Nøkkelord kreves",
    "reqMemMsg": "Abonnentmelding er påkrevd",
    "reqMsgT": "Melding er påkrevd",
    "gMemberMsg": "Det virker som om du allerede er en del av gruppen vår!!",
    "gdefMsg": "Vi er online nå! Sjekk ut vår servicemeny og bestill din neste time i dag.",
    "next_title": "Neste",
    "male_title": "Mann",
    "male_val": "mann",
    "female_title": "Hunn",
    "female_val": "hunn",
    "others_txt": "Andre",
    "others_val": "andre",
    "validBirthdate": "Skriv inn gyldig fødselsdato",
    "valid_phone_no_title": "Skriv inn gyldig telefonnummer",
    "required_phone_no_title": "Telefonnummer er påkrevd",
    "add_new_client_txt": "Legg til en ny klient",
    "update_client_txt": "Oppdater klient",
    "phone_num_text": "Telefonnummer",
    "dob_title": "DOB",
    "select_gender_title": "Velg Kjønn",
    "timelineTitle": "Tidslinje",
    "confirm_location": "Bekreft plassering",
    "feedback_title": "Tilbakemelding",
    "rep_question_1": "Var virksomheten ren?",
    "rep_que_2": "Var maten din god?",
    "rep_que_3": "Var tjenesten vår vennlig?",
    "rep_que_4": "Var tjenesten vår rask?",
    "business_location_req_title": "Bedriftssted er nødvendig",
    "valid_location_err_txt": "Vennligst velg gyldig adresse",
    "rep_management_title": "Få flere vurderingsadministrasjon",
    "rep_about_desc": "Omdømme hjelper deg med å øke Google-vurderingen ved å be kundene dine om tilbakemelding",
    "preview_title": "Forhåndsvisning",
    "rep_preview_txt": "Forhåndsvisning av omdømme",
    "back_title": "Tilbake",
    "fine_print_txt": "Løs inn med kassen",
    "redeem_me_title": "Løs inn meg",
    "rep_nxt_visit_txt": "På ditt neste besøk",
    "device_type": "Type enhet som brukes",
    "connection_method": "Tilkoblingsmetode",
    "peak_days": "Toppdager",
    "peak_hours": "Peak timer",
    "guest_by_day": "Gjest etter dag",
    "guest_visit": "Gjestebesøk",
    "connection": "Forbindelse",
    "connection_duration": "Tilkoblingsvarighet",
    "guest_visit_1": "1 gang",
    "guest_visit_2": "2 ganger",
    "guest_visit_3_5": "3-5 ganger",
    "guest_visit_6_10": "6-10 ganger",
    "guest_visit_11_25": "11-25 ganger",
    "guest_visit_26_100": "26-100 ganger",
    "guest_visit_100_plus": "100+ ganger",
    "device_android_total": "Totalt Android",
    "device_android": "Android",
    "device_desktop": "Skrivebord",
    "device_ios": "Ios",
    "device_ios_total": "Totalt Ios",
    "device_desktop_total": "Totalt skrivebord",
    "connection_duration_10": "<=10 min",
    "connection_duration_20": "11-20 min",
    "connection_duration_30": "21-30 min",
    "connection_duration_40": "31-45 min",
    "connection_duration_60": "46-60 min",
    "connection_method_email": "E-post",
    "connection_method_sms": "SMS",
    "connection_method_google": "Google",
    "connection_method_facebook": "Facebook",
    "age_category_1": "<18",
    "age_category_2": "18-24",
    "age_category_3": "25-34",
    "age_category_4": "35-44",
    "age_category_5": "45-54",
    "age_category_6": "55+",
    "all_guest_txt": "Alle gjester",
    "new_Guest_txt": "Ny gjest",
    "connections_txt": "Tilkoblinger",
    "hotspot": "Hotspot",
    "hotspot_list": "Hotspot-liste",
    "add_new_hotspot": "Legg til nytt hotspot",
    "hotspot_information": "Hotspot-informasjon",
    "edit_details_button": "Rediger detaljer",
    "wifi_info_message": "Koble til og nyt gratis WiFi",
    "connection_message": "Flott, du har blitt koblet til ",
    "connection_message_suffix": " WiFi",
    "wifi": "WiFi",
    "login_to_access": "Logg inn for å få tilgang",
    "verification_code": "Verifikasjonskode",
    "verification_code_message": "Vennligst skriv inn bekreftelseskoden sendt til ",
    "wifi_user_email": "adamo@gmail.com",
    "wifi_label": "Wifi",
    "your_name": "Hva heter du forresten?",
    "your_birthdate": "Kan du fortelle oss, hva er fødselsdatoen din?",
    "request_guest_wifi_name": "Vennligst skriv inn gjestens Wifi-navn",
    "request_device_key": "Vennligst skriv inn enhetsnøkkel",
    "request_maximum_internet_access_length": "Velg maksimal lengde på Internett-tilgang",
    "mac_address": "MAC-adresse",
    "device_port": "Enhetsport",
    "hardware": "Maskinvare",
    "current_uptime": "Gjeldende oppetid",
    "nearby_devices": "Enheter i nærheten",
    "firmware": "Fastvare",
    "who_are_you": "Hvem er du?",
    "where_to_contact_you": "Hvor kommer vi i kontakt med deg?",
    "your_area_code": "Hva er retningsnummeret ditt?",
    "connected": "Tilkoblet",
    "delete_hotspot_message": "Dette hotspotet vil bli slettet permanent.",
    "delete_multiple_hotspots_message": "Disse hotspotene vil bli slettet permanent.",
    "speed_error": "Hastigheten bør være minst 0,01",
    "speed_max_error": "Angi en verdi på opptil 1024 for ubegrenset hastighet, eller velg en lavere verdi innenfor det støttede området",
    "device_ssid": "Enhetens SSID",
    "device_ssid_two": "Enhets SSID to",
    "device_ssid_two_wpa": "Enhets SSID To WPA",
    "device_key": "Enhetsnøkkel",
    "select_location": "Velg Sted",
    "select_maximum_internet_access_length": "Velg Maksimal Internett-tilgangslengde",
    "download_speed": "Last ned Speed",
    "upload_speed": "Opplastingshastighet",
    "network_length_1": "15 minutter",
    "network_length_2": "30 minutter",
    "network_length_3": "45 minutter",
    "network_length_4": "1 time",
    "network_length_5": "2 timer",
    "network_length_6": "4 timer",
    "network_length_7": "6 timer",
    "network_length_8": "8 timer",
    "network_length_9": "10 timer",
    "network_length_10": "12 timer",
    "employee_wifi_name": "Ansatt Wifi-navn",
    "employee_wifi_password": "Wifi-passord for ansatt",
    "guest_wifi_name": "Gjestenes Wifi-navn",
    "menu_other_products_txt": "Andre produkter | Meny på nett",
    "menu_home_text": "Hjem | Meny på nett",
    "whatsapp_title": "Whatsapp",
    "select_campaign_type": "Velg en kampanjetype",
    "select_readymade_templates": "Velg mellom ferdige maler eller lag dine egne",
    "campaign_title_required": "Kampanjetittel er påkrevd",
    "type_here": "Skriv her...",
    "location_permission_req": "Plasseringstillatelse kreves",
    "platform_permission_req": "Plattformtillatelse kreves",
    "profile_picture": "Profilbilde",
    "click_to_upload": "Klikk for å laste opp",
    "location_permission": "Stedstillatelse",
    "pin": "Pin",
    "platform_permission": "Plattformtillatelse",
    "set_pin": "Angi PIN-kode",
    "list_of_users": "Liste over brukere",
    "create_user": "Opprett bruker",
    "terms_and_condition_req": "Vilkår og betingelser er obligatoriske",
    "terms_and_conditions": "Vilkår og betingelser",
    "recommended": "ANBEFALT",
    "mo": "\/mnd",
    "unsubscribe": "Avslutt abonnementet",
    "cancel_subscription": "Er du sikker på at du vil kansellere abonnementet?",
    "upgrade": "Oppgrader",
    "plan_upgraded_successfully": "Planen ble oppgradert!",
    "menu_online": "Meny på nett",
    "support": "Støtte",
    "wifiC": "WIFI",
    "billing": "Fakturering",
    "please_upgrade": "Vennligst oppgrader for å nyte alle funksjonene",
    "you_have_subscribed": "Du har abonnert på ",
    "plan": "plan",
    "of_text": "av",
    "days": "Dager",
    "remaining_days_until_plan": " Dager igjen til planen din krever oppdatering",
    "manage_your_screens": "Administrer skjermene dine",
    "screens_you_save": "Skjermer du har",
    "add_more_screens": "Legg til flere skjermer",
    "addition_screen": "Tilleggsskjerm",
    "per_screen": "$ per skjerm",
    "per_box": "$ per boks",
    "shipping_changes": "Fraktkostnader",
    "upto4_boxes": "opptil 4 bokser ",
    "charge_now": "Du vil bli belastet nå",
    "updated_reoccuring": "Din oppdaterte gjentakelse vil være",
    "current_reoccuring": "Nåværende gjentakende: ",
    "no_text": "Ingen",
    "android_box": "Android Box",
    "old_password_req": "Gammelt passord kreves",
    "new_password_req": "Nytt passord kreves",
    "confirm_password_req": "Bekreft passord er nødvendig",
    "password_do_not_match": "Passord stemmer ikke",
    "old_password": "Gammelt passord",
    "new_password": "Nytt passord",
    "confirm_password": "Bekreft passord",
    "copy_working_hours": "Vil du kopiere disse arbeidstidene til alle dager?",
    "yes_copy": "Ja, kopi",
    "closed": "Lukket",
    "opening_time": "Åpningstid",
    "closing_time": "Stengetid",
    "description": "Beskrivelse",
    "file_exist": "filen eksisterer allerede, velg en annen fil",
    "bussiness_images": "Bedriftsbilder",
    "display_info_on_market_place": "Vil du vise informasjonen nedenfor på markedsplassen din?",
    "profile_ready": "Profilen din er klar!",
    "client_book_with_online": "Kunder kan nå bestille hos deg online. Del lenken nedenfor for å komme i gang.",
    "copy_link": "Kopier lenke",
    "see_your_profile": "Se profilen din",
    "ok_got_it": "Ok, skjønner det",
    "preview_profile": "Forhåndsvis profilen din og se hvordan den vil se ut.",
    "opening_hours": "Åpningstider",
    "display_data": "Vis data",
    "manage_profile": "Administrer profil",
    "phone_req": "Telefon kreves",
    "market_place_img_req": "Markedsplassbilder er påkrevd",
    "add_new_market_place": "Legg til ny markedsplass",
    "edit_market_place": "Rediger MarketPlace",
    "no_reviews_yet": "Ingen anmeldelser ennå",
    "good": "God",
    "average": "Gjennomsnittlig",
    "add_marketPlace": "Legg til MarketPlace",
    "all_title": "Alle",
    "included_title": "Inkludert",
    "excluded_title": "Ekskludert",
    "clients_subscribed": "Kunder abonnerte i siste",
    "clients_groups": "Klientgrupper",
    "customer_groups": "Kundegrupper",
    "audience_title": "Publikum",
    "client_gt5": "som ikke kom tilbake i det siste",
    "select_all": "Velg alle",
    "modify": "Endre",
    "campaign_title": "Kampanjetittel",
    "msg_type": "Meldingstype",
    "enter_discount": "Angi rabatt",
    "discount_type": "Rabatttype",
    "coupon_text": "Kupongtekst",
    "enter_coupon_text": "Skriv inn kupongtekst",
    "coupon_button_text": "Kupongknapptekst",
    "enter_coupon_button_text": "Skriv inn kupongknapptekst",
    "fine_prin": "Fine Print",
    "enter_fine_prin": "Skriv inn tekst med liten skrift",
    "campaign_dec": "Du kan endre kampanjen 30 minutter før starttidspunktet. Kostnader for SMS-kampanje vil bli fortsett 30 minutter før kampanjens starttidspunkt.",
    "blast_text_message_dec": "Blast-tekstmeldingen din er klar. Velg betalingsmåte og send meldingene dine.",
    "payment_completed": " Betalingen gjennomføres kl ",
    "total_cost": "Total kostnad",
    "close_title": "Lukke",
    "friday": "fredag",
    "saturday": "lørdag",
    "sunday": "søndag",
    "thursday": "torsdag",
    "tuesday": "tirsdag",
    "wednesday": "onsdag",
    "port_txt": "Havn",
    "today_title": "I dag",
    "yesterday_title": "I går",
    "last_30_days_txt": "Siste 30 dager",
    "this_month_txt": "Denne måneden",
    "last_month_txt": "Siste måned",
    "valid_date_title": "Velg gyldig dato",
    "valid_business_name_txt": "Skriv inn gyldig bedriftsnavn",
    "req_bus_add_txt": "Bedriftsadresse er påkrevd",
    "req_domain_name_txt": "Domenenavn er påkrevd",
    "basic_info_txt": "Grunnleggende informasjon om bedriften din",
    "loyalty_qr_def_txt": "Skann QR-koden, bli med på lojalitetshullkortet vårt og nyt belønninger.",
    "last_stamp_txt": "Siste stempel",
    "collected_on_txt": "Samlet på",
    "stamp_details_txt": "Stempeldetaljer",
    "add_stamp_txt": "Legg til stempel",
    "choose_brand_color_txt": "Velg merkefarge og språk 🎨",
    "change_anytime_txt": "Du kan endre dette når som helst",
    "like_to_get_txt": "Vil du få",
    "your_next_visit_txt": "på ditt neste besøk?",
    "time_to_time_get_offers": "Ønsker du å motta gode tilbud og kampanjer fra tid til annen?",
    "respond_back_to_you": "Vil du at noen skal svare deg?",
    "input_serve_better": "Dine innspill vil hjelpe teamet vårt til å tjene deg bedre!",
    "do_it_txt": "La oss gjøre det",
    "take_15_s": "Tar 15 sekunder",
    "rate_on_google_desc": "Vil du være så snill å rangere oss på google?",
    "may_be_next_time": "Kanskje neste gang",
    "thank_you_txt": "Takk",
    "look_next_txt": "Vi ser frem til ditt neste besøk!",
    "deleteUserTitle": "Denne brukeren vil bli slettet permanent.",
    "deleteMUserTitle": "Denne brukeren vil bli slettet permanent.",
    "change_pin": "Bytt pin",
    "area_code": "Hva er retningsnummeret ditt? ",
    "add_menu": "Legg til meny",
    "menu_name": "Menynavn",
    "add_menu_placeholder": "Pizza, burger, drikke, etc",
    "enable_data_Collection": "Aktiver datainnsamling",
    "add_new_menu": "Legg til ny meny",
    "rename_menu": "Gi nytt navn til menyen",
    "preview": "Forhåndsvisning",
    "generate_qr": "Generert QR",
    "edit_menu": "Rediger meny",
    "remove_menu": "Fjern menyen",
    "menu_delete_msg": "Denne menyen vil bli slettet permanent",
    "menus_delete_msg": "Disse menyene vil bli slettet permanent",
    "view_menu_dialoage_msg": "Vil du få en gave på bursdagen din?",
    "skip": "Hopp over",
    "cliam_your_gift": "Gjør krav på gaven din",
    "collect_form": "Samle skjema",
    "enter_first_name": "Skriv inn fornavn",
    "enter_last_name": "Skriv inn etternavn",
    "enter_email": "Skriv inn e-post",
    "enter_dob": "Skriv inn fødselsdato",
    "enter_number": "Skriv inn nummer",
    "select_gender": "Velg Kjønn",
    "congratulations_desc": "🎉 Gratulerer! Forespørselen om gavekrav er sendt inn. Teamet vårt vil kontakte deg innen kort tid. 🎉",
    "male_heading": "Mann",
    "male_text": "mann",
    "female_heading": "Hunn",
    "female_text": "hunn",
    "others_heading": "Andre",
    "other_text": "andre",
    "BirthD": "Fødselsdato",
    "GenderT": "Kjønn",
    "EmailT": "E-post",
    "dobT": "DOB",
    "capitalize_menu": "Meny",
    "select_menu": "Velg meny",
    "manage_variant": "Administrer variant",
    "add_products": "Legg til produkter",
    "add_category": "Legg til kategori",
    "category_delete": "Denne kategorien vil bli slettet permanent",
    "variation_delete": "Denne varianten vil bli slettet fra produktet.",
    "product_delete": "Dette produktet vil bli slettet permanent.",
    "categories_delete": "Disse kategoriene vil bli slettet permanent.",
    "products_delete": "Disse produktene vil bli slettet permanent.",
    "category": "KATEGORI",
    "price": "PRIS",
    "food_product_placeholder": "Pizza, burger, drikke, etc",
    "active_title": "Aktiv",
    "inActive_title": "Inaktiv",
    "status_capital": "STATUS",
    "cat_status_require": "Kategoristatus er påkrevd",
    "cat_name_require": "Kategorinavn er obligatorisk",
    "category_name": "Kategorinavn",
    "status": "Status",
    "lgDay1": "mandag",
    "lgDay2": "tirsdag",
    "lgDay3": "onsdag",
    "lgDay4": "torsdag",
    "lgDay5": "fredag",
    "lgDay6": "lørdag",
    "lgDay7": "søndag",
    "is_closed_title": "Lukket",
    "book_table_title": "Bestill et bord",
    "emailErrSub": "E-postemne er påkrevd",
    "email_subject": "E-postemne",
    "contactUsfrmTitleEmail": "Svar på e-post",
    "companyInfo": "Lorem Ipsum er lorem hrig, lorem ipsum er fraets.Lorem Ipsum er lorem hrig, lorem ipsum er fraets.Lorem Ipsum er lorem hrig, lorem ipsum er fraets.",
    "footerSTitle1": "Om Menuonline",
    "footerSTitle1Lnk2": "Funksjoner",
    "footerSTitle1Lnk3": "Blogg",
    "footerSTitle2": "Lovlig",
    "footerSTitle2Lnk1": "Vilkår og betingelser",
    "footerSTitle2Lnk2": "Personvernerklæring",
    "footerSTitle3": "For Business",
    "footerSTitle3Lnk1": "For partnere",
    "footerSTitle3Lnk2": "Prissetting",
    "footerSTitle3Lnk3": "Støtte for partnere",
    "footerCopyRithgTxt": "  Menuonline eller dets tilknyttede selskaper",
    "homeTitle": "Hjem",
    "contactTitle": "Kontakt oss",
    "aboutTitle": "Om oss",
    "homeMenuTitle": "Meny",
    "bookTableTitle": "Bestill et bord",
    "vDateT": "Velg gyldig dato",
    "reqDateT": "Dato er påkrevd",
    "dateGtErr": "Datoen må være senere enn eller lik dagens dato",
    "timeInvalid": "Tiden er ugyldig",
    "reqTimeT": "Tid kreves",
    "timeInvalidBfr": "Velg tid minst 5 minutter senere fra gjeldende tid ",
    "PerReq": "Antall personer er påkrevd",
    "validcNoT": "Skriv inn gyldig kontaktnummer",
    "reqCrT": "Valuta er påkrevd",
    "reqPrT": "Pris kreves",
    "reCateT": "Kategori er påkrevd",
    "reqVrT": "Variant kreves",
    "reqVrVldT": "Skriv inn gyldig variant ",
    "validLNameT": "Skriv inn gyldig etternavn",
    "sDateInValid": "Fra dato er ugyldig",
    "minmReq": "Minimum kreves",
    "resLblDate": "Velg Dato",
    "resLblTime": "Velg Tid",
    "perT": "For hvor mange personer?",
    "resLblEmail": "Vennligst oppgi din e-post?",
    "resLblNote": "Legg til en reservasjonsnotat",
    "imageError1MB": "Vennligst sett inn et bilde som er mindre enn 1 MB",
    "imageError": "Vennligst sett inn et bilde som er mindre enn 2,5 MB",
    "imageError3Mb": "Vennligst sett inn et bilde som er mindre enn 3 MB",
    "imageInVldError": "Filen er ugyldig.",
    "addProMT": "Produktmeny",
    "proT": "Produktnavn",
    "reqProT": "Produktnavn er obligatorisk",
    "proPT": "Produktpris",
    "reservationSuccTitle": "Flott, reservasjonen din er fullført",
    "book": "Bok",
    "custzName": "Tilpasningsnavn",
    "always": "Alltid",
    "proImgReq": "Produktbilde er påkrevd",
    "selCustmT": "Velg Tilpasning eller opprett ny",
    "visStR": "Synlighetsstatus er påkrevd",
    "avlblScR": "Vennligst velg tilgjengelighetsplan",
    "addonPR": "Vennligst velg tilleggsprodukter",
    "upsellPR": "Velg mersalgsprodukter",
    "markItemR": "Vennligst velg merk vareprodukter",
    "caloriesR": "Kalorier kreves",
    "allergensR": "Velg allergenprodukter",
    "prepInstR": "Forberedelsesinstruksjoner er nødvendig",
    "staffNR": "Personalnotater kreves",
    "discountR": "Rabatt kreves",
    "validDiscE": "Oppgi gyldig rabatt",
    "disday": "Om dagen",
    "plSelDayT": "Velg dag",
    "sTimeReq": "Starttid er nødvendig",
    "sTimeInValid": "Starttiden er ugyldig",
    "eTimeReq": "Slutttidspunkt er påkrevd",
    "eTimeInValid": "Sluttiden er ugyldig",
    "sDateReq": "Fra dato kreves",
    "eDateReq": "Til dags dato er påkrevd",
    "eDateInValid": "Til dags dato er ugyldig",
    "disdate": "Fra datoen",
    "disdate1": "Til dags dato",
    "disdate2": "Fra dato",
    "currT": "Valuta",
    "iconR": "Vennligst velg merk elementikonet",
    "minT": "Minimum",
    "maxT": "Maksimum",
    "itemNT": "Varenavn",
    "itemPT": "Varepris",
    "descProT": "Beskriv produktet ditt",
    "cateT": "Kategori",
    "selProCateT": "Velg din produktkategori",
    "reqMT": "Menynavn er påkrevd",
    "ViewMenu": "Vis meny",
    "CopyMenu": "Kopier meny",
    "EditMenu": "Rediger-menyen",
    "RemoveMenu": "Fjern menyen",
    "welcomeMnuTitle": "Velkommen til",
    "reviewT": "Anmeldelser",
    "userErrorMsg": "Beklager, det oppstod en feil!!!",
    "reqCateT": "Kategorinavn er obligatorisk",
    "mngCustT": "Administrer tilpasning",
    "custNReq": "Tilpasningsnavn kreves",
    "incReq": "Inkludert er påkrevd",
    "minmValid": "Angi gyldig minimumsverdi",
    "maxmReq": "Maksimum kreves",
    "cust": "Tilpasning",
    "crCust": "Opprett ny tilpasning",
    "custList": "Tilpasningsliste",
    "delCustmzT": "Denne tilpasningen vil bli slettet permanent.",
    "mkNameR": "Merk elementnavn er påkrevd",
    "mkDelT": "Dette merkeelementet vil bli slettet permanent.",
    "hideText": "Gjemme",
    "showText": "Vise",
    "device_mac_txt": "Enhet Mac",
    "delete_hotspot_txt": "Dette hotspotet vil bli slettet permanent.",
    "delete_hotspots_txt": "Disse hotspotene vil bli slettet permanent.",
    "emp_wifi_name": "Ansatt Wifi-navn",
    "max_limit_txt": "Skriv inn '1024' for hastighet for å angi den som UBEGRENSET. Denne verdien fjerner eventuelle tak, og tillater maksimal gjennomstrømning",
    "device_port_txt": "Enhetsport",
    "firmware_txt": "Fastvare",
    "hotspot_info_txt": "Hotspot-informasjon",
    "editDBtn": "Rediger detaljer",
    "birth_date": "Fødselsdato",
    "di_theme_clr": "Temafarge nøyaktig 7 siffer",
    "color_Invalid_txt": "Velg gyldig fargekode",
    "Req_theme_clr": "Temafarge er påkrevd",
    "select_valid_color_txt": "Velg gyldig temafarge",
    "req_redir_link": "Viderekoblingskobling er nødvendig",
    "val_redir_link": "Skriv inn gyldig viderekoblingslink",
    "req_business_name_txt": "Bedriftsnavn er obligatorisk",
    "splash_preview": "Splash forhåndsvisning",
    "create_new_splash": "Opprett ny Splash",
    "splash_page": "Splash-side",
    "splash_page_builder": "Splash Page Builder",
    "redirect_link": "Viderekobling",
    "theme_color": "Tema farge",
    "enable_social_login": "Aktiver alternativer for sosiale medier for brukernes pålogging",
    "google": "Google",
    "facebook": "Facebook",
    "is_mandatory": "Er det obligatorisk?",
    "field": "Felt",
    "name": "Navn",
    "first_name": "Fornavn",
    "last_name": "Etternavn",
    "birthdate": "Fødselsdato",
    "gender": "Kjønn",
    "email": "E-post",
    "dob": "DOB",
    "zip_code": "Postnummer",
    "required_redirect_link": "Viderekoblingskobling er nødvendig",
    "valid_redirect_link": "Skriv inn gyldig viderekoblingslink",
    "required_theme_color": "Temafarge er påkrevd",
    "theme_color_length": "Temafarge nøyaktig 7 siffer",
    "required_name": "Navn er påkrevd",
    "delete_splash_message": "Vil du slette denne splashen?",
    "delete_multiple_splashes_message": "Vil du slette disse sprutene?",
    "user_login_required": "Brukerpålogging er nødvendig",
    "set_theme_color": "Angi temafarge",
    "splash_colllect_additional_info_txt": "Samle inn tilleggsinformasjon",
    "verify_mobile_otp_message": "Vil du sende OTP for å bekrefte mobilnummer?",
    "add_company": "Legg til selskap",
    "edit_company": "Rediger selskap",
    "ds_device": "Digital Signage-enhet",
    "ds_device_activation_fee": "Aktiveringsavgift for digital signage-enhet",
    "wifi_hardware": "Wifi maskinvare",
    "menu_design": "Menydesign",
    "ds_hardware": "Maskinvare for digital skilting",
    "company_name_req": "Firmanavn er obligatorisk",
    "known_name_req": "Kjent navn kreves",
    "no_location_req": "Antall plassering kreves",
    "minimum_location": "Vennligst skriv inn minst 1 plassering",
    "terms_req": "Vilkår er påkrevd",
    "notes_req": "Merknader kreves",
    "sms_charge_req": "SMS-avgift kreves",
    "menuonline_quantity_req": "Meny Online Antall er påkrevd",
    "menuonline_cost_req": "Meny Online Kostnad er påkrevd",
    "ds_quantity_req": "Antall digital skilting kreves",
    "ds_monthly_cost_req": "Digital Signage Månedlig kostnad er påkrevd",
    "reputation_quantity_req": "Omdømme Antall er påkrevd",
    "reputation_cost_req": "Omdømmekostnad er påkrevd",
    "marketing_quantity_req": "Markedsføringsmengde er påkrevd",
    "marketing_cost_req": "Markedsføringskostnader er påkrevd",
    "tax_percentage_req": "Skatteprosent kreves",
    "router_hardware_quantity_req": "Rutermaskinvaremengde er nødvendig",
    "wifi_hardware_cost_req": "WIFI maskinvarekostnad er nødvendig",
    "activation_cost_req": "Aktiveringskostnad er nødvendig",
    "wifi_quantity_req": "WIFI-mengde er nødvendig",
    "wifi_router_req": "Wifi-ruter kreves",
    "menu_design_quantity_req": "Menydesign Mengde er nødvendig",
    "menu_design_cost_req": "Menydesignkostnad er påkrevd",
    "quantity_req": "Mengde er påkrevd",
    "cost_req": "Kostnad er påkrevd",
    "sheduledown_quantity_req": "Planlegg ned mengde er påkrevd",
    "sheduledown_cost_req": "Nedsettelseskostnad er nødvendig",
    "loyalty_program_quantity_req": "Lojalitetsprogram Antall er påkrevd",
    "loyalty_program_cost_req": "Lojalitetsprogramkostnad er påkrevd",
    "loyalty_program_sms_quantity_req": "Lojalitetsprogram SMS Antall er påkrevd",
    "loyalty_program_sms_cost_req": "Lojalitetsprogram SMS-kostnad er påkrevd",
    "comapny_created": "Selskapet ble opprettet",
    "comapny_updated": "Selskapet ble oppdatert",
    "billing_info": "FAKTURERINGSINFORMASJON",
    "business_name_acc_holder": "Bedriftsnavn (kontoinnehaver)",
    "client_first_name": "Klientens fornavn",
    "client_last_name": "Klientens etternavn",
    "dba_known_as": "DBA kjent som",
    "business_address": "Forretningsadresse",
    "no_of_locations": "Antall steder",
    "package_details": "PAKKEDETALJER",
    "terms": "Vilkår",
    "one_month": "1 måned",
    "per_sms_charge": "Pris per SMS",
    "plan_test": "PLAN: TEST",
    "desc_capital": "BESKRIVELSE",
    "qty_capital": "ANTALL",
    "cost_capital": "KOSTE",
    "total_price_capital": "TOTALPRIS",
    "quantity": "Mengde",
    "can_not_access": "Du kan ikke velge mer enn",
    "licenses": "lisenser",
    "monthly_cost": "Månedlig kostnad",
    "plan_cost": "Plankostnad",
    "tax": "Avgift",
    "tax_percentage": "Skatteprosent",
    "monthly_cost_after_tax": "Månedlig kostnad etter skatt",
    "activation_one_time_charge": "Aktivering og engangslading",
    "sub_total": "SUBTOTAL",
    "action": "Handling",
    "cost": "Koste",
    "shipping_charge": "Fraktgebyr",
    "other": "Annen",
    "additional_cost": "Ekstra kostnader (andre kostnader)",
    "other_tax": "Annen skatt",
    "total": "Total",
    "license_statistics": "Lisensstatistikk",
    "total_licenses": "Totalt antall lisenser",
    "available_licenses": "Tilgjengelige lisenser",
    "stock": "Lager",
    "has_permission": "Har tillatelse",
    "avg_stock_price": "AVG aksjekurs",
    "average_price": "Gjennomsnittlig pris",
    "allocated": "Tildelt",
    "reward_regulars": "Belønn stamgjester",
    "not_add_coupon_url": "Kan ikke legge til kupong-URL. Meldingens maksimale lengde er 160",
    "like_attach_coupon": "Vil du legge ved en kupong?",
    "advance_scheduling": "Forhåndsplanlegging",
    "choose_day": "Velg dag(er) for å sende melding",
    "select_essage_window": "Velg meldingsvindu",
    "subscription_outside_delivery_window": "Hvis en bruker abonnerer utenfor leveringsvinduet, vil de motta autosvaret ditt ved neste tilgjengelige tidspunkt.",
    "remaining": "Gjenværende",
    "processing_request": "Det oppsto en feil under behandlingen av forespørselen din.",
    "list_companies": "Liste over selskaper",
    "are_you_sure": "Er du sikker..?",
    "signin_agreement": "Ved å bekrefte dette vil selskapet opprette uten å signere en avtale.",
    "status_updated": "Statusoppdatering vellykket",
    "status_failed": "Kunne ikke oppdatere status",
    "new_editor_status_updated": "Status for DS New Editor har blitt oppdatert.",
    "user_name": "Brukernavn",
    "known_as": "kjent som",
    "agreement_signed": "Avtale signert",
    "deactivate": "Deaktiver",
    "activate": "Aktiver",
    "login_to_user_acc": "Logg inn på brukerkonto",
    "disable": "Deaktiver",
    "enable": "Aktiver",
    "ds_new_editor": "DS Ny redaktør",
    "sign_agreement": "Signer avtale",
    "agreement_sign": "Avtale U\/U signatur",
    "view_agreement": "Se avtalen",
    "download_agreement": "Last ned avtale",
    "add_deleted_user": "Legg til slettet bruker",
    "deleted_user": "Slett bruker",
    "favourite": "Favoritt",
    "refresh": "Forfriske",
    "delete_chat": "Slett Chat",
    "responsive_drawer": "Responsiv skuff",
    "delete_contact": "Slett kontakt",
    "clear_chat_history": "Tøm chat-loggen",
    "clear_chat_history_dec": "Er du sikker på at du vil slette denne chatten?",
    "clear_contact_dec": "Er du sikker på at du vil slette denne kontakten?",
    "select_contact": "Velg Kontakt",
    "new_conversation": "Start ny samtale",
    "type_msg": "Skriv inn meldingen din her",
    "select_delete_client": "Velg klienten som skal slettes",
    "select_delete_client_chat": "Velg klient for å slette chatten",
    "select_delete_client_chat_err": "Du har ikke chat med denne klienten",
    "acquire_customers_business": "Skaff nye kunder fra andre virksomheter.",
    "customers_from_events": "Få kunder fra arrangementer og ta det til deg!",
    "customers_from_ads": "Få kunder fra avisannonser.",
    "smart_pamphlets": "Lag smarte hefter for å samle kundedata.",
    "smart_qr_group": "En smart QR for å bli med i en gruppe for kunder.",
    "opt_in_number": "Gjør det mulig for kunder å velge antall.",
    "qr_menu": "Lag QR-kode for å vise menyen din.",
    "collect_birthdays": "Samle kundens bursdag og jubileum.",
    "join_loyalty_program": "Få nye kunder til å bli med i lojalitetsprogrammet mitt.",
    "create_feedback_forms": "Lag skjemaer for å samle inn tilbakemeldinger fra kunden.",
    "exclusive_discounts_promotions": "Tilby eksklusive rabatter og kampanjer for krysskampanjer.",
    "group_smart_qr_opt_in": "Etter å ha opprettet en gruppe, kan du generere og velge en smart QR-kode, slik at kunder kan registrere seg umiddelbart i en gruppe",
    "group_smart_qr_opt_in_dec": "Etter å ha opprettet en gruppe, bilde-widget som lar kunder velge å bruke tekst og motta kampanjer, for eksempel tekst \"pizza\" til 555-555-5555",
    "in_store_discount_next_visit": "Få rabatt i butikk neste gang du besøker vårt sted",
    "delivery_redirection": "Opprett leveringsomdirigering",
    "additional_info": "Tilleggsinformasjon",
    "add_url": "Legg til URL",
    "custom_fields": "Egendefinerte felt",
    "meta_tag": "Metatag",
    "max_chars": "Maks tegn",
    "add": "Legge til",
    "update_campaign": "Oppdater kampanje",
    "last_week_required": "siste uke er nødvendig",
    "large_week_value": "Ukeverdien er for stor skriv inn gyldig uke",
    "less_than_last_week": "Ikke retur i forrige uke må være mindre enn forrige uke.",
    "last_week_req": "Det kreves ikke retur i forrige uke",
    "birthday_special": "Bursdagspesial",
    "birthday_message": "Automatisert melding sendt ut på bursdagen deres med personlige hilsener og fristende tilbud",
    "birthday_sms": "Lag personlige bursdagshilsener for SMS\/MMS",
    "target_audience": "Velg målgruppe",
    "client_by_birthday": "Kunde innen bursdag",
    "clients_once_year": "Denne kampanjen sendes automatisk til kunder én gang i året ",
    "auto_responder_default_msg_1": "Du er vår en av de beste kundene som vi elsker å gi rabatt til! Måtte du alltid være rik og sunn. Gratulerer med dagen! %% fornavn %%",
    "campaign_send_to_client_birthday": "Denne automatiske kampanjen sender til kunder med bursdager. Du kan tilpasse målrettingen nedenfor.",
    "get_more_engagement": "Få mer engasjement ved å sende b'day SMS 🎂",
    "welcome_new_clients": "Velkommen nye kunder",
    "make_lasting_impression": "Gjør et varig inntrykk ved å hilse hjertelig velkommen og ønske førstegangsbesøkende velkommen",
    "personalized_greetings": "Lag personlige hilsener for de nye kundene",
    "greetings_to_new_customers": "Denne kampanjen vil sende ut velkomsthilsener til nye kunder dagen etter deres første interaksjon med bedriften din",
    "audience_predefined": "Denne autosvarerens publikum er forhåndsdefinert, vi har dette!",
    "send_clients_subscribed": "Denne autosvaren er satt til å sende klienter som abonnerte på sist ",
    "first_interaction": "  etter deres første interaksjon.",
    "default_msg_2": "Vi håper du likte ditt første besøk, vi vil gjerne se deg igjen snart, så vi tilbyr en rabatt kun for deg! Klikk på lenken for å bestille din neste time hos oss og løse inn ditt spesialtilbud på nett nå.",
    "new_clients_update": "Velkommen nye kunder oppdater kampanjetekst",
    "new_clients_warm_greeting": "Velkommen nye kunder med en varm hilsen 💌",
    "win_back_clients": "Vinn tilbake kunder",
    "re_engage_past_customers": "Engasjer tidligere kunder på nytt med uimotståelige tilbud for å oppmuntre dem til å komme tilbake",
    "target_disengaged_clients": "Målrett uengasjerte kunder for å komme tilbake med et spesialtilbud, en velprøvd taktikk for å lokke kunder tilbake.",
    "campaign_sends_to_clients": "Denne kampanjen sendes til kunder som ikke kom tilbake etter en viss tidsperiode.",
    "customize_targeting": "Tilpass målrettingen nedenfor for å sende ut SMS\/MMS til utgåtte klienter.",
    "clients_with_at_least": "Kunder med minst ",
    "connection_last": " forbindelse i den siste ",
    "return_in_last_week": " uke, men kom ikke tilbake sist ",
    "arr_menu_44": " uke",
    "df_msg_4": "Vi vil ha deg SÅ ​​VILL tilbake at vi kommer til å gi deg 10 % rabatt bare for retur! Vil du prøve oss igjen i dag?",
    "update_campaign_text": "Vinn tilbake klienter oppdater kampanjetekst",
    "re_invite_customers": "Inviter på nytt kunder som ikke har vært tilbake på en stund",
    "loyal_patrons": "Vis takknemlighet til lojale kunder med spesielle rabatter og lojalitetsbelønninger",
    "surprize_top_spenders": "Overrask toppbrukere med personlige og spesialtilbud.",
    "campaign_automatically_send": "Denne kampanjen sender automatisk ut belønninger for å øke fotfallet i virksomheten din.",
    "sms_loyal_clients": "Denne kampanjen sender SMS\/MMS til lojale kunder. Du kan endre målrettingen nedenfor.",
    "or_more_connection": " eller flere tilkoblinger, innen den siste ",
    "week_period": " ukes periode.",
    "default_msg_5": "Du har vært kunde i lang tid nå! Wow, tiden flyr! Vi setter pris på deg og vil takke deg for at du er med oss.",
    "reward_regulars_update": "Belønningsgjengere oppdaterer kampanjetekst",
    "special_discounts": "Sett pris på og gi spesielle rabatter💎",
    "leave_positive_online": "Oppmuntre kunder til å legge igjen positive anmeldelser på nett for å øke restaurantens omdømme",
    "clients_service_online": "Kampanjen oppfordrer kundene dine til å vurdere tjenesten din på nettet.",
    "increase_your_company": "Denne autosvaren sendes for å øke bedriftens nettvurdering.",
    "send_message_after": "Send melding etterpå ",
    "min_connection": " min av tilkoblingen",
    "default_msg_6": "Vi håper du likte ditt første besøk, vi vil gjerne se deg igjen snart! [URL] Klikk på linken for å rangere oss på Google.",
    "ratings_update": "Få mer kampanjetekst for rangeringer",
    "friendly_nudge": "Få flere anmeldelser med et vennlig dytt ⭐",
    "thanks_for_visiting": "Takk for besøket",
    "express_gratitude": "Uttrykk takknemlighet til kunder for at de valgte virksomheten din med hjertelig takkemeldinger",
    "thank_you_message": "Lag en takkemelding til kundene dine.",
    "clients_for_visiting": "Send en varm takk til kundene dine for besøket ditt.",
    "campaign_audience": "Denne automatiske kampanjemålgruppen er forhåndsdefinert, vi har dette!",
    "campaign_automatically_sends": "Denne automatiske kampanjen sender automatisk etter ",
    "minutes_customer_visit": " minutter av kundebesøk",
    "default_msg_7": "Takk for at du er vår verdsatte kunde. Vi er så takknemlige for gleden av å tjene deg og håper vi innfridde dine forventninger.",
    "visiting_update_campaign": "Takk for at du besøkte oppdateringskampanjetekst",
    "guests_thank_you": "Takk dine gjester for besøket 😊",
    "download_title": "Last ned",
    "qr_gen_title": "QR generert",
    "qr_download_s_title": "QR-koden ble lastet ned",
    "sel_dark_brand_clr_msg": "Vennligst velg gyldig barnd farge",
    "manage_customer_stamp_rewards": "Administrer kundestempelbelønninger 🎁",
    "sel_loc_menu_title": "Velg steder for menyen din",
    "ans_req": "Svar kreves",
    "valid_reputation_name": "Skriv inn et gyldig omdømmenavn",
    "reviews_name_req": "Hent flere anmeldelser-navn er obligatorisk",
    "birthdate_required": "Fødselsdato er obligatorisk",
    "gender_required": "Kjønn er påkrevd",
    "valid_birthdate_required": "Skriv inn gyldig fødselsdato",
    "custom_delivery_redirection": "Opprett et tilpasset leveringsomdirigeringsskjema etter grupper",
    "customer_fields_incentive_settings": "Kundefelt og insentivinnstillinger",
    "delivery_redirection_text": "Omdirigering av levering",
    "delivery_redirection_success": "Gratulerer! Din leveringsomdirigeringswidget opprettet! 🎉",
    "swipe_to_preview_redirection": "Sveip for å se hvordan omdirigeringsskjemaet for levering vil se ut",
    "enter_item_name": "Skriv inn elementnavn",
    "home_page_text": "Hjemmesidetekst",
    "settings": "Innstillinger",
    "anniversary_required": "Jubileumsdato er påkrevd",
    "valid_anniversary": "Angi gyldig jubileumsdato",
    "form_submited": "skjemaet er sendt inn",
    "notifications": "Varsler",
    "discount_message": "Få 20 % rabatt på pizza nå!",
    "is_required": " er nødvendig",
    "section_title_required": "Seksjonstittel er påkrevd",
    "section_dec_required": "Seksjonsbeskrivelse er nødvendig",
    "client_details_required": "Felt for klientdetaljer er obligatoriske",
    "compliance": "Overholdelse",
    "SMS_campaign1": "SMS-kampanje 1",
    "mobile_number_mandatory": "Mobilnummer er obligatorisk",
    "new_answer": "Nytt svar",
    "new_question": "Nytt spørsmål",
    "add_new_question": "Legg til nytt svar",
    "select": "Velge",
    "group_customers_question": "Hvor vil du gruppere kundene dine?",
    "contacts_added_to_group": "Alle kontakter som sender inn skjemaet vil bli lagt til valgt gruppe",
    "edit_client_details_section": "Rediger seksjon med klientdetaljer",
    "client_details_fields": "Klientdetaljer-felt",
    "enter_client_details_section_title_desc": "Skriv inn tittelen og beskrivelsen av delen med klientdetaljer",
    "choose_fields_displayed_client_side": "Velg feltene som skal vises på klientsiden",
    "section_title": "Seksjonstittel",
    "add_title": "Legg til tittel",
    "section_description": "Seksjonsbeskrivelse",
    "add_description": "Legg til beskrivelse",
    "enter_program_name_location": "Skriv inn programnavn og plassering 📍",
    "brand_theme_clr_txt": "Merketemafarge",
    "set_loyalty_punch_card_theme": "La oss sette et tema for lojalitetshullkortet ditt 🎨",
    "upload_logo_txt": "Last opp logoen din",
    "recommended_image_specs": "Vi anbefaler å bruke et bilde som er 512 x 512px for best mulig visning. JPG, SVG eller PNG. Maks størrelse på 10MB.",
    "valid_rep_name_txt": "Skriv inn et gyldig omdømmenavn",
    "rep_name_req_txt": "Hent flere anmeldelser-navn er påkrevd",
    "que_req": "Spørsmål er påkrevd",
    "day_of_birthday": "på bursdagsdagen",
    "day_before_birthday": "3 dager før bursdag",
    "week_before_birthday": "en uke før bursdag",
    "two_week_before_birthday": "to uker før bursdag",
    "de_active": "De-aktiv",
    "campaign_details": "Kampanjedetaljer",
    "link_clicked": "Link klikket",
    "history": "Historie",
    "auto_responder_summary": "Sammendrag av autosvar",
    "vsop_1": "15 min",
    "vsop_2": "30 min",
    "vsop_3": "45 min",
    "vsop_4": "60 min",
    "vsop_5": "90 min",
    "vsop_6": "120 min",
    "in_the_last": " i det siste ",
    "return_in_last": " men kom ikke tilbake sist ",
    "of_the_connection": "av forbindelsen",
    "your_campaign": "Kampanjen din",
    "you_have_successfully": "Du har lykkes",
    "published_successfully": "publisert vellykket",
    "updated_smart_campaign": "oppdatert smartkampanjen",
    "almost_done_text": "Nesten ferdig!",
    "update_campaign_desc": "Din autosvar er fullført, når den er aktivert, vil den sende meldinger til klienter fortløpende. Denne kampanjen kan enkelt endres eller settes på pause når som helst.",
    "update_and_publish": "Oppdater og publiser",
    "reset_campaign_title": "Det vil foreløpig slette kampanjens egendefinerte tekst, bilde, rabatttilbud og målrettet publikum!",
    "space_wifi": " WiFi",
    "custom_forms": "Egendefinerte skjemaer",
    "web_e_signup": "E-registrering på nett",
    "import": "Import",
    "permissions_req": "Tillatelser kreves",
    "redeemed": "Innløst",
    "coupon_already_redeemed": "Kupongen er allerede innløst den",
    "autoresponder_campaigns": "Autoresponder-kampanjer",
    "autoresponder_campaign_desc": "Aktiver autosvaret og start den personlige kampanjen",
    "mon": "MAN",
    "tue": "TIR",
    "wed": "ON",
    "thu": "THU",
    "fri": "FRI",
    "sat": "SAT",
    "sun": "SOL",
    "duplicate": "Kopiere",
    "visibility": "Synlighet",
    "availability": "Tilgjengelighet",
    "out_stok": "Ute av stokk",
    "edit_product": "Rediger produkt",
    "create_product": "Lag et produkt",
    "basic_info": "Grunnleggende informasjon",
    "basic_sub_info": "Lorem Ipsum er rett og slett dummy tekst av utskriften ...",
    "enter_product_price": "Skriv inn produktpris",
    "upload_image": "Last opp bilde",
    "allowed_file_formats": "Kun JPG- og PNG-filer med maksimal filstørrelse 3MB er tillatt.",
    "pre_select": "Forhåndsvelg",
    "promotions": "Kampanjer",
    "discount_availability": "Rabatt tilgjengelig",
    "start_time": "Starttid",
    "end_time": "Slutttid",
    "select_day": "Velg dag",
    "menu_required": "Meny er påkrevd",
    "generated": "Generert",
    "link": "Link",
    "variations": "Variasjoner",
    "select_variant_to_add": "Velg variant du vil legge til",
    "price_title": "Pris",
    "choose_image": "Velg Bilde",
    "select_visiblity": "Velg Synlighet",
    "availability_schedule": "Tilgjengelighetsplan",
    "add_on_upsell": "Add on \/ Mersalg",
    "add_on_select_product": "Legg til på Velg produkt",
    "upsell_product": "Mersalg Velg produkt",
    "variant_deletion_warning": "Denne varianten vil bli slettet permanent.",
    "search_variations": "Søkevarianter",
    "add_variation": "Legg til variant",
    "variation_text": "Variant",
    "select_customization": "Velg tilpasning",
    "add_new": "Legg til ny",
    "delete_customization_warning": "Vil du slette denne tilpasningen fra produktet?",
    "nutritional_allergen_info": "Ernærings- og allergeninformasjon",
    "mark_item": "Merk element",
    "calories_text": "Kalorier",
    "allergens": "Allergener",
    "select_allergens": "Velg allergener",
    "special_instructions": "Spesielle instruksjoner",
    "preparation_instructions": "Forberedelsesinstruksjoner",
    "staff_notes": "Personalnotater",
    "checkbox1": "En avmerkingsboks for å tillate eller ikke tillate spesielle instruksjoner fra kunder for dette menyelementet.",
    "menu_c": "MENY",
    "past_design": "Tidligere design",
    "file_name": "Filnavn",
    "variation_name": "Variasjonsnavn",
    "grid_items_title1": "Bordtelt",
    "grid_items_title2": "Digital meny",
    "grid_items_title3": "Takeaway-meny",
    "grid_items_title4": "Halvsidemeny",
    "grid_items_title5": "Digital meny 5",
    "grid_items_title6": "Digital meny 6",
    "grid_items_title7": "Digital meny 7",
    "grid_items_title8": "Digital meny 8",
    "grid_items_title9": "Digital meny 9",
    "enter_valid_form_name": "Skriv inn et gyldig skjemanavn",
    "form_name_required": "Skjemanavn er påkrevd",
    "enter_valid_section": "Skriv inn gyldig seksjonstittel",
    "fraction_half": "1\/2",
    "fraction_two_by_two": "2\/2",
    "form_name": "Skjemanavn",
    "contact_list": "Kontaktliste",
    "label_notify_email": "Vil du bli varslet via e-post?",
    "add_client_details_section": "Seksjonen Legg til kundedetaljer",
    "demo_purpose_only": "Det er kun for demoformål",
    "form_updated_success": "skjemaet ble oppdatert",
    "form_creat_success": "skjemaet ble opprettet",
    "add_custom_section": "Legg til en egendefinert seksjon",
    "edit_custom_section": "Rediger en egendefinert seksjon",
    "forms_list": "Liste over skjemaer",
    "filled_custom_form_details": "Utfylte tilpassede skjemadetaljer",
    "custom_form_deletion_warning": "Dette tilpassede skjemaet vil bli slettet permanent",

    "menu_link": "Menykobling",
    "WebSignUpT": "E-påmelding",
    "lifeTConT": "Livstidstilkoblinger",
    "repCustT": "Gjenta kunder",
    "liftTSubT": "Livstidsabonnenter",
    "overallT": "SAMLET",
    "loginCustBDT": "Påloggede kunder etter dag",
    "deviceT": "Enhetsdemografi",
    "ageTitle": "aldersdemografi",
    "ageT": "Aldersgruppe",
    "convert_social_media_followers_into_customers": "Konverter følgere på sosiale medier til kunder",
    "signWidDesc": "Bygg en widget for å registrere deg, lage landingssider og generere kuponger fra nettstedet ditt eller sosiale medier",
    "qrWidT": "Smart QR",
    "imgWidT": "Velg antall",
    "mobile": "Mobil",
    "YESTitle": "JA",
    "NOTitle": "INGEN",
    "addDelRed": "Legg til leveringsomdirigering",
    "dlredNameT": "Navngi leveringsomdirigeringen",
    "conf_text_req": "Bekreftelsestekst kreves",
    "cFDesc": "Lag skreddersydde skjemaer for å samle inn spesifikk tilbakemelding og informasjon fra kunder effektivt",
    "pre_review_questions_enabled": "Før du ber kundene om anmeldelse, la oss stille dem 1 til 4 spørsmål.",
    "feel_free_edit": "Rediger gjerne etter behov.",
    "option": "Alternativ",
    "confFL": "Denne bekreftelsen vises etter at kontakten din har sendt inn nettregistreringsskjemaet",
    "confT": "Bekreftelsestekst",
    "joinTitle": "Bli med",
    "mobValidL": "Vennligst sjekk gyldig mobilnr",
    "mobRequiredL": "Mobilnummer kreves",

    "Business_location_label": "Din bedriftsbeliggenhet",
    "qr_code_selection_txt": "Qr-kodevalg",
    "choose_theme_txt": "Velg tema",
    "edit_qr_details_txt": "Rediger QR-detaljer",
    "save_qr_code_txt": "Lagre QR-koden",
    "text_color_label": "Tekstfarge",
    "background_color_label": "Bakgrunnsfarge",
    "print_txt": "Trykk",
    "customer_will_see_txt": "Dette er hva kunden din vil se. Velg merkefargen din i neste trinn.",
    "choose_your_brand_color_&_langauge_title": "Velg merkefarge og språk",
    "custom_redirect_link_label": "Tilpasset viderekoblingskobling",
    "redirect_user_to_menu": "Omdiriger bruker til menyen",
    "language": "Språk",
    "select_language_placeholder": "Velg Språk",
    "capitalize_location_name_txt": "Stedsnavn",
    "created_txt": "Opprettet",
    "swipe_reputation_management_txt": "Sveip for å se hvordan omdømmebehandlingen din vil se ut",
    "sent_txt": "Sendt",
    "number_label": "Tall",
    "date_label": "Dato",
    "gender_demographics_title": "Kjønnsdemografi",


    "step1_preview_desc": "Navngi ditt QR-skjema for e-registrering og legg til i gruppelisten din",
    "main_head": "Skaff nye kunder",
    "main_desc": "Skaff kunder ved å plassere en QR-kode som, når den skannes, omdirigerer brukere til et personlig tilpasset registreringsskjema.",
    "step1_description": "Skaff nye kunder fra andre virksomheter",
    "form_name_field_placeholder": "Restro E-registrering",
    "last_step_title": "Gratulerer! Din e-registreringswidget opprettet! 🎉",
    "last_step_desc": "Sveip for å se hvordan e-registreringsskjemaet ditt vil se ut",
    "events_step1_preview_desc": "Navngi ditt Event QR-skjema og legg til i gruppelisten din",
    "events_main_head": "Få kunder fra arrangementer",
    "events_main_desc": "Skaff kunder ved å plassere en QR-kode på arrangementet, når det skannes, omdirigerer brukerne til et personlig tilpasset registreringsskjema.",
    "events_step1_desc": "Fang kunder fra arrangementer og ta dem med i butikken",
    "events_name": "Navn på hendelse",
    "events_form_name_field_placeholder": "Restro Moro og matarrangement",
    "events_last_step_title": "Gratulerer! Eventskjemaet ditt er opprettet! 🎉",
    "events_last_step_desc": "Sveip for å se hvordan eventskjemaet ditt vil se ut",
    "birthday_anniversary_step1_preview_desc": "Gi QR-skjemaet et navn for å samle inn kundens bursdag eller jubileum, og det legges til i gruppelisten din",
    "birthday_anniversary_main_head": "Samle B'day eller Jubileum",
    "birthday_anniversary_main_desc": "Få kundens bursdag eller jubileum, når skannet, omdirigerer brukere til et personlig registreringsskjema.",
    "birthday_anniversary_step1_desc": "Samle kundens bursdag og jubileum",
    "restro_text": "Restro",
    "birthday_anniversary_last_step_title": "Gratulerer! Få B'day & Anniversary fra kundeskjemaet opprettet! 🎉",
    "newspaper_ads_step1_preview_desc": "Gi QR-skjemaet ditt et navn for å få kunder fra avisannonser, og det legges til i gruppelisten din",
    "newspaper_ads_main_head": "Få kunder fra Ads",
    "newspaper_ads_main_desc": "Få kunder fra avisannonser, når de skannet, omdirigerer det brukere til et personlig påmeldingsskjema.",
    "newspaper_ads_step1_desc": "Få kunder fra avisannonse eller utendørsannonse",
    "newspaper_ads_last_step_title": "Gratulerer! Få kunder fra avisannonser skjema opprettet! 🎉",
    "smart_pamphlets_step1_preview_desc": "Gi QR-skjemaet et navn for å få kundedata fra brosjyrer, og det legges til i gruppelisten din",
    "smart_pamphlets_main_head": "Få kunder fra brosjyrer",
    "smart_pamphlets_main_desc": "Få kundens data fra brosjyrer, når de skannet, omdirigerer det brukere til et personlig tilpasset registreringsskjema.",
    "smart_pamphlets_last_step_title": "Gratulerer! Få kundens data fra brosjyrer opprettet! 🎉",
    "social_media_step1_preview_desc": "Gi QR-skjemaet et navn for å få kunder fra sosiale medier og til kunder, og det legges til i gruppelisten din",
    "social_media_main_desc": "Få kunder fra sosiale medier, når de skannet fra innlegg, omdirigerer det brukere til et personlig påmeldingsskjema.",
    "social_media_form_name_field_placeholder": "Restro Festivaltilbud - Facebook-innlegg",
    "social_media_last_step_title": "Gratulerer! Få kundenes sosiale medier-skjema opprettet! 🎉",
    "claim_offer_text": "Fyll inn opplysningene dine og hent tilbudet nå",
    "form_title_description": "Legg til skjematittel og beskrivelse slik at kundene dine kan skrive inn detaljene",
    "no_subscribed_clients": "Den valgte kundegruppen har ingen abonnenter",
    "welcome_message_offer": "Send en velkomstmelding til kundene dine etter at de har registrert seg og gi dem en tilbudskupong",
    "system_grp": "Systemgrupper",
    "import_clients": "Importer klienter",
    "delivery_redirection_name": "Navn på leveringsomadressering",
    "created_at": "Opprettet kl",
    "provide_stamp_user": "Gi stempel til brukeren",
    "scan_qr_code": "Skann QR-kode",
    "add_point": "Legg til punkt",
    "total_points": "Totalt antall poeng",
    "user_profile": "Brukerprofil",
    "stamp_history": "Frimerkehistorie",
    "reward_txt": "Belønne",
    "variant_placeholder": "Liten, middels, stor, etc",
    "sent_coupon": "Sendt kupong",
    "order_status": "Ordrestatus",
    "payment_status": "Betalingsstatus",
    "order_currency": "Bestillingsvaluta",
    "card_data": "Kortdata",
    "reward_expiring_on": "Belønning utløper",
    "reward_status": "Belønningsstatus",
    "reward_get_date": "Belønning Få dato",
    "reward_name": "Navn på belønning",
    "add_point_type": "Legg til punkttype",
    "restaurant_location": "Restaurant plassering",
    "collect_points": "Samle poeng",
    "rewards_expire": "Belønninger utløper",
    "rewards_redeem": "Belønninger Løs inn",
    "total_rewards": "Totale belønninger",
    "created_by": "Laget av",
    "anniversary": "Jubileum",
    "remove_user_group_txt": "Fjern bruker fra gruppe",
    "delete_permanently_grp_msg": "Denne klienten vil bli slettet permanent fra gruppen.",
    "delete_multiple_permanently_grp_msg": "Denne klienten vil bli slettet permanent fra gruppen.",
    "delete_multiple_client": "Denne klienten vil bli slettet permanent.",
    "messages": "Meldinger",
    "management": "Ledelse",
    "client": "Klient",
    "country": "Land",
    "state": "Tilstand",
    "city": "By",
    "zip_code": "Postnummer",
    "1_sparkly_point_txt": "🎉 Du har tjent 1 glitrende poeng! 🌟",
    "select_country_txt": "Velg land",
    "select_state": "Velg tilstand",
    "ls_thanks_for_join": "Takk for at du ble med på lojalitetshullkortet vårt.🎁🎈",
    "month": "Måned",
    "welcome_message": "Send en velkomstmelding til kundene dine etter at de har registrert seg",
    "all_rewards_txt": "Alle belønninger",
    "active_rewards": "Aktive belønninger",
    "expired_and_redeemed": "Utløpt og innløst",
    "redirect_3rd_party_delivery": "Omdiriger tredjepartslevering til butikken din eller til din online bestillingsplattform",
    "search_or_start_new": "Søk eller start en ny",
    "contacts": "Kontakter",
    "chats": "Chatter",
    "add_by_restaurant": "Legg til etter restaurant",
    "using_scan_code": "Bruke skannekode",
    "step": "Skritt",
    "list_widget_text": "Liste over widgets",
    "swipe_free_wifi_txt": "Sveip for gratis wifi",
    "create_widget_txt": "Opprett widget",
    "redeem_award_msg": "Er du sikker? Ønsker du å løse inn denne belønningen",
    "on_collect_msg": "På Collect",
    "pause": "Pause",
    "resume": "Gjenoppta",
    "location_added": "plassering lagt til",
    "sub_user_admin_permissions_txt": "Tillat underbruker å få tilgang til administratortillatelser i Digital Signage",
    "unlist": "Avliste",
    "cannot_delete_default_terms": "Kan ikke slette standard vilkår og betingelser",
    "something_went_wrong": "Noe gikk galt!",
    "card_download_success": "Kortet ble lastet ned",
    "permission_denied": "Tillatelse nektet!",
    "invalid_qr_code": "Vennligst skann gyldig QR-kode",
    "select_customer": "Velg kunde",
    "online": "PÅ NETT",
    "offline": "OFFLINE",
    "no_data": "Beklager, ingen data funnet!",
    "forever_free": "For alltid gratis",
};

export default no