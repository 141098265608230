const ru = {
    "rated_google_reviews": "Рейтинг 5.0 в Google Reviews",
    "get_your_digital_menu": "Получите свое цифровое меню —",
    "instant_popup_menu": "Мгновенное всплывающее меню без сканирования QR-кода",
    "free_forever": "Бесплатно навсегда",
    "the_future_is_here": "Будущее уже здесь!",
    "upgrade_your_restaurant": "Обновите свой ресторан с помощью потрясающего цифрового меню без затрат. Привлекайте больше клиентов, начиная с сегодняшнего дня.",
    "get_my_free_menu_now": "Получите мое бесплатное меню прямо сейчас",
    "no_credit_card_required": "*Кредитная карта не требуется",
    "join_largest_marketplace": "Присоединяйтесь к крупнейшему ресторанному рынку!",
    "what_will_you_get": "Что вы получите",
    "free": "БЕСПЛАТНО",
    "free_digital_menu": "Бесплатное цифровое меню 2D или 3D",
    "free_digital_menu_desc": "Попрощайтесь с бумажными меню и дорогими перепечатками",
    "quick_and_easy_signup": "Быстрая и простая регистрация",
    "communicate_with_customers_title": "Общайтесь с клиентами БЕСПЛАТНО!",
    "features1": [
        "Экономьте на расходах на печать и получайте мгновенные обновления меню, когда бы они вам ни понадобились.",
        "Получите доступ к своему цифровому меню на любом устройстве",
        "Экологично и без проблем"
    ],
    "features2": [
        "Позвольте клиентам виртуально исследовать ваши блюда с помощью потрясающих 3D-визуализаций.",
        "Повысьте вовлеченность, предоставив посетителям реалистичный предварительный просмотр своего блюда.",
        "Выделитесь из толпы благодаря меню, которое покажется таким же заманчивым, как и вкус ваших блюд."
    ],
    "features3": [
        "Встречайте гостей своим меню сразу при входе в ресторан.",
        "Автоматически отправляйте обновления о специальных предложениях и акциях на их телефоны.",
        "Улучшите впечатления от еды с помощью быстрого бесконтактного доступа к меню."
    ],
    "feature4": [
        "Выделите сезонные предложения, ежедневные специальные предложения и эксклюзивные акции.",
        "Привлеките внимание покупателей к вашим самым популярным товарам.",
        "Создайте ощущение срочности и волнения с помощью привлекательных визуальных эффектов и описаний."
    ],
    "feature5": [
        "Станьте частью интернет-сообщества с тысячами ежедневных посетителей.",
        "Повысьте узнаваемость и привлеките новых клиентов, активно ищущих рестораны.",
        "Займите выгодное место заранее, чтобы максимально увеличить свою посещаемость и привлечь больше посетителей."
    ],
    "section3_heading": "Зачем переходить на цифровые технологии? Потому что это бесплатно и просто!",
    "section3_description": "Откройте для себя мощные функции, призванные повысить привлекательность вашего ресторана.",
    "instant_menu": "Мгновенное меню",
    "instant_menu_notification": "Всплывающие окна и уведомления",
    "instant_menu_description": "Приветствуйте гостей с мгновенным доступом к меню, как только они входят. Автоматически отправляйте последние меню и специальные предложения прямо на их мобильные телефоны",
    "digital_menu": "Цифровые меню",
    "at_zero_cost": "по нулевой стоимости",
    "digital_menu_description1": "Попрощайтесь с бумажными меню и дорогими перепечатками. Перейдите на цифровое меню, которое всегда актуально — и оно будет бесплатным навсегда!",
    "digital_menu_description2": "С нашим цифровым меню ваши предложения всегда актуальны и доступны на любом устройстве. Экономьте деньги и упростите обновления меню с помощью бесшовного, не требующего затрат решения.",
    "try_me_button": "Попробуй меня",
    "try_3d_menu_title": "3D-меню",
    "try_3d_menu_title2": "Бесплатно — ВАУ!",
    "try_3d_menu_description1": "Заставьте свои блюда выделяться на экране. Загружайте 3D-изображения и дайте клиентам возможность виртуально попробовать вашу еду, прежде чем они зайдут в заведение.",
    "try_3d_menu_description2": "Высококачественные 3D-изображения создают аппетитный предварительный просмотр, повышая привлекательность и побуждая делать заказы.",
    "try_now": "Попробуйте сейчас",
    "showcase_title": "Покажите свой",
    "showcase_specials": "Специальные предложения",
    "showcase_specials_description1": "У вас есть заманчивая сделка или непревзойденная акция? Выделите ее в своем цифровом меню и привлеките больше голодных людей.",
    "showcase_specials_description2": "Сделайте рекламные акции невозможными для пропуска, привлекая внимание яркими визуальными эффектами и заманчивыми описаниями.",
    "marketplace": "MarketPlace",
    "marketplace_description": "Мы готовим маркетплейс с более чем 2000 ежедневными посетителями. Заходите сейчас, и вы будете первыми в очереди, когда мы запустимся. Вы сможете привлечь новых клиентов, которые активно ищут варианты питания. Забронируйте свое место заранее, чтобы максимизировать охват!",
    "see_other_products": "Посмотреть другие продукты",
    "about_us": "О нас",
    "why_serving_free": "Почему мы это подаем",
    "serving_free_highlight": "Бесплатно",
    "about_desc": "Мы местные жители Далласа, и наша цель — помогать процветать таким ресторанам, как ваш.",
    "about_tool_free": "Мы предлагаем этот мощный инструмент абсолютно бесплатно, потому что мы верим в поддержку нашего сообщества.",
    "no_catch": "Никакого подвоха, никаких шуток. Мы знаем, что когда вы добиваетесь успеха, наше сообщество становится сильнее. 😊",
    "understanding_challenges": "Мы понимаем сложности ведения бизнеса, поэтому стремимся предоставлять инструменты, которые сделают вашу деятельность более гладкой и расширят ваш охват.",
    "community_growth": "По мере того, как вы растете, наше сообщество растет вместе с вами, создавая волновой эффект успеха и поддержки. Вместе давайте поднимем качество обедов для всех.",
    "how_it_works": "Как это работает",
    "three_simple_steps": "Три простых шага к",
    "get_started_highlight": "Начать",
    "no_tech_skills_needed": "Никаких технических навыков не требуется. Если вы можете перевернуть бургер, вы сможете сделать это!",
    "step1_title": "Покажите свою рекламу БЕСПЛАТНО!",
    "step1_desc": "Начните быстрее, чем вы успеете сказать «Заказать!» Просто заполните простую форму.",
    "step2_title": "Настройте свое меню",
    "step2_desc": "Добавляйте свои блюда, загружайте фотографии (даже в формате 3D) и подчеркивайте свои фирменные блюда.",
    "step3_title": "Делитесь и развивайтесь",
    "step3_desc": "Поделитесь своим цифровым меню через QR-коды, социальные сети или ваш веб-сайт.",
    "testimonials": "Отзывы",
    "testimonials_section_title": "Не верьте нам на слово",
    "testimonials_section_desc": "Посмотрите, что говорят владельцы других ресторанов в Далласе.",
    "faq_section_title": "Есть вопросы? У нас есть ответы",
    "faq_section_desc": "Все, что вам нужно знать перед началом работы.",
    "faqs": "Часто задаваемые вопросы",
    "faq_answers": [
        {
            "que": "Действительно ли это навсегда бесплатно?",
            "ans": "Конечно! Никаких скрытых платежей, никаких кредитных карт, никаких дополнительных условий."
        },
        {
            "que": "Нужны ли мне какие-либо технические знания?",
            "ans": "Если вы можете отправить электронное письмо, вы можете сделать это. Это так просто."
        },
        {
            "que": "Почему вы предлагаете это бесплатно?",
            "ans": "Мы верим в то, что сначала нужно отдавать. В дальнейшем у нас могут быть и другие услуги, которые вам понравятся, но это полностью зависит от вас."
        },
        {
            "que": "Что происходит на рынке?",
            "ans": "Мы готовим! Как только у нас будет достаточно ресторанов, мы запустим вашу торговую площадку, чтобы вы стали еще более заметны."
        }
    ],
    "testimonials_content1": "Переход на цифровое меню был лучшим нашим шагом! Наши клиенты в восторге от удобства и обновленного внешнего вида!",
    "testimonials_name1": "Моррис Джордж",
    "testimonials_restaurant_name1": "Ресторан «Особняк»",
    "testimonials_content2": "Наше новое цифровое меню упрощает навигацию для гостей и действительно повышает вовлеченность!",
    "testimonials_name2": "Амелия Джонсон",
    "testimonials_restaurant_name2": "Городская еда",
    "testimonials_content3": "Гости были поражены 3D-визуализацией — она помогла нам выделиться и улучшить их впечатления от еды!",
    "testimonials_name3": "Дэвис Грин",
    "testimonials_restaurant_name3": "Городской очаг",
    "ready_to_boost_your_restaurant": "Готовы ли вы продвинуть свой ресторан?",
    "ready_to_boost_desc": "Не отставайте. Присоединяйтесь к цифровой революции сегодня!",
    "yes_i_want_my_free_digital_menu": "Да, я хочу получить бесплатное цифровое меню",
    "480_plus": "480+",
    "people_already_joined": "люди уже присоединились",
    "menu_online_home": "Меню Онлайн Главная",
    "welcome": "Добро пожаловать",
    "description_other": "Давайте повысим удовлетворенность ваших клиентов!",
    "benefits": "Преимущества",
    "greeting_text": "Добро пожаловать 👋",
    "all_in_one_features": "🚀 Комплексные функции, которые выведут ваш ресторан на новый уровень",
    "menu_online_menu_name": "Меню Онлайн",
    "menu_online_description": "Оцифруйте свое меню, чтобы клиенты могли легко получить к нему доступ онлайн.",
    "menu_online_benefits": "Повысьте удобство для клиентов и повысьте их вовлеченность, предоставив им возможность просматривать ваше меню в любое время и в любом месте.",
    "ds_name": "Цифровые вывески",
    "ds_description": "Демонстрируйте предложения и акции на динамических экранах в магазине.",
    "ds_benefits": "Привлекайте внимание клиентов с помощью привлекательных визуальных материалов, продвигайте дополнительные продажи и предложения в режиме реального времени.",
    "loyalty_punch_name": "Перфокарта лояльности",
    "loyalty_punch_description": "Поощряйте постоянных клиентов с помощью цифровой программы лояльности.",
    "loyalty_punch_benefits": "Удерживайте клиентов и увеличивайте продажи, стимулируя лояльность с помощью вознаграждений за частые посещения.",
    "marketing_menu_name": "Маркетинг",
    "marketing_description": "Привлекайте больше клиентов с помощью целевых кампаний цифрового маркетинга.",
    "marketing_benefits": "Расширьте охват вашего ресторана и увеличьте посещаемость с помощью индивидуальных акций, разработанных для привлечения новых и постоянных клиентов.",
    "get_more_reviews_menu_name": "Получить больше отзывов",
    "get_more_reviews_description": "Поощряйте довольных клиентов оставлять положительные отзывы в Интернете.",
    "get_more_reviews_benefits": "Повысьте авторитет вашего ресторана и привлеките новых посетителей, улучшив свою репутацию в Интернете с помощью большего количества положительных отзывов.",
    "guest_smart_wifi_menu_name": "Гостевой смарт-Wi-Fi",
    "guest_smart_wifi_description": "Предложите бесплатный Wi-Fi для сбора данных гостей в маркетинговых целях.",
    "guest_smart_wifi_benefits": "Повышайте удовлетворенность клиентов, собирая ценные данные о них для персонализации будущих рекламных акций.",
    "reservation_menu_name": "Бронирование",
    "reservation_description": "Позвольте клиентам с легкостью бронировать столики онлайн.",
    "reservation_benefits": "Улучшите качество обслуживания, сократив время ожидания и оптимизировав бронирование для более эффективного управления рассадкой.",
    "pos_integration_menu_name": "Интеграция POS-терминалов",
    "pos_integration_description": "Интегрируйте нашу систему с вашей POS-системой.",
    "pos_integration_benefits": "Упростите обработку заказов и сократите количество ошибок, синхронизировав свои онлайн- и магазинные системы.",
    "online_ordering_menu_name": "Онлайн-заказ",
    "online_ordering_description": "Позвольте клиентам размещать заказы прямо с вашего сайта или из приложения.",
    "online_ordering_benefits": "Увеличьте продажи и сократите зависимость от сторонних платформ, предложив возможность прямого заказа, которая позволяет сэкономить на комиссиях.",
    "pro_label": "Профи",
    "coming_soon": "Вскоре",
    "locked_label": "Заблокировано",
    "forever_label": "Навсегда",
    "new_label": "Новый",
    "other_products_page_title": "Другие продукты | Меню онлайн",
    "other_products_description": "Меню Онлайн Политика конфиденциальности",
    "other_products_header_title": "Другие продукты",
    "privacy_policy": {
        "title": "политика конфиденциальности",
        "lastUpdated": "Последнее обновление: 01 июля 2024 г.",
        "introduction": {
            "header": "Политика конфиденциальности для новых и существующих клиентов",
            "paragraph1": "СЛЕДУЮЩИЕ КЛЮЧЕВЫЕ ПУНКТЫ (ВКЛЮЧАЯ ССЫЛКИ НА РАЗДЕЛЫ) ПРЕДОСТАВЛЕНЫ ТОЛЬКО ДЛЯ ВАШЕГО УДОБСТВА И НЕ ЗАМЕНЯЮТ ПОЛНЫЕ УСЛОВИЯ И ПОЛОЖЕНИЯ. ВЫ ОБЯЗАНЫ ПРОЧИТАТЬ ЭТИ УСЛОВИЯ И ПОЛОЖЕНИЯ ПЕРЕД ИСПОЛЬЗОВАНИЕМ УСЛУГИ MENUONLINE INC.",
            "paragraph2": "Использование Menuonline означает, что вы соглашаетесь с Условиями и положениями, а также подтверждаете и гарантируете, что ваша организация или компания уполномочила вас на это. (См. раздел 3 «Как это работает»).",
            "paragraph3": "Menuonline Inc — это маркетинговая служба, основанная на разрешении. Намеренная отправка «спама» или нарушение Условий приведет к закрытию вашего аккаунта. (См. п. 16 «Приемлемое использование Сервиса»)",
            "paragraph4": "Данные, которые вы размещаете в Menuonline Inc, такие как контактные данные, содержимое электронной почты и информация, которую вы собираете через Menuonline Inc, принадлежат вам. (См. раздел 14 «Чем вы владеете?»).",
            "paragraph5": "Программное обеспечение Menuonline Inc и контент на нашем веб-сайте принадлежат нам. (См. п. 13 «Чем мы владеем?») Помимо ваших собственных пользователей, данные или неопубликованный контент, которые вы размещаете на Menuonline Inc, могут просматривать только сотрудники и партнеры Menuonline Inc, подписавшие соглашения о конфиденциальности. (См. п. 14 «Чем вы владеете?»)",
            "paragraph6": "Вся персональная информация (PII) хранится только на серверах, расположенных в Канаде. (См. раздел 14 «Чем вы владеете?»).",
            "paragraph7": "Сервис Menuonline Inc подчиняется законам Канады и провинции Онтарио. (См. раздел 24 «Применимое законодательство»).",
            "paragraph8": "Любые запросы на ваши данные или контент от частных лиц, полиции или других регулирующих органов из Канады или за ее пределами всегда будут направлены вам. Единственным исключением будет, если это нарушит канадские или канцелярские законы Онтарио; однако во всех случаях мы будем решительно противиться любой разглашению ваших данных кем-либо, кроме вас. (См. раздел 14 «Чем вы владеете?»).",
            "paragraph9": "Безопасность Menuonline Inc зависит только от вашего управления именами пользователей и паролями. Мы не взимаем плату за учетные записи пользователей Menuonline Inc, поэтому создайте отдельную учетную запись для каждого пользователя и держите пароли в секрете. Вы несете ответственность за свою учетную запись. (См. раздел 3 «Как это работает»)",
            "paragraph10": "Вы можете отменить свой аккаунт в любое время. Мы не предоставляем возврат средств за неиспользованный кредит, если вы отмените. (См. раздел 3 «Как это работает»)"
        },
        "termsAndConditions": {
            "title": "УСЛОВИЯ И ПОЛОЖЕНИЯ MENUONLINE INC",
            "lastUpdate": "ПОСЛЕДНЕЕ ОБНОВЛЕНИЕ: 14 января 2020 г.",
            "paragraph1": "Пожалуйста, внимательно прочтите настоящие положения и условия обслуживания (далее — «Условия») перед использованием данного веб-сайта (далее — «Сайт»). Настоящие Условия освобождают Menuonline Inc. («Menuonline Inc», «мы» или «нас») и других от ответственности и\/или ограничивают нашу и их ответственность и содержат другие важные положения, которые применяются к вашему использованию данного Сайта. Посещая или используя данный Сайт или Услугу, вы соглашаетесь от своего имени и от имени любой организации, от имени которой вы можете действовать (далее совместно именуемые «вы»), принять и соблюдать настоящие Условия при каждом использовании и каждом посещении данного Сайта."
        },
        "services": {
            "title": "Наши услуги.",
            "paragraph": "Услуги, которые мы предлагаем через Сайт, позволяют вам создавать, отправлять и управлять сообщениями электронной почты («Электронные письма») получателям по вашему выбору («Услуга»)."
        },
        "license": {
            "title": "Лицензия.",
            "paragraph": "В соответствии с настоящими Условиями Menuonline Inc настоящим предоставляет вам неисключительную, ограниченную, непередаваемую лицензию в течение Срока для просмотра Сайта и использования Сервиса в соответствии с функциями Сервиса. Мы оставляем за собой все права, не предоставленные явно в настоящем документе на Сервис и Контент Menuonline Inc (как определено ниже)."
        },
        "emailingContent": {
            "title": "Отправка контента по электронной почте.",
            "paragraph": "При взаимодействии с Menuonline Inc через этот Сайт или Сервис вы соглашаетесь предоставлять правдивую и точную информацию. Кроме того, вы понимаете, что предоставление конфиденциальной информации по электронной почте является небезопасной практикой. Вы понимаете, что вы несете исключительную ответственность за все, что вы отправляете кому-либо, использующему наш Сервис."
        },
        "support": {
            "title": "Поддерживать.",
            "paragraph": "Мы оказываем вам поддержку, чтобы помочь вам стать самодостаточными с помощью Сервиса. Мы оказываем вам поддержку в виде документации и видео на Сайте, в системе тикетов поддержки Menuonline Inc и по телефону. Вы можете связаться с нами, нажав на вкладку «Поддержка» в верхней части Сайта. Поддержка продукта бесплатна; однако мы оставляем за собой право прекратить поддержку определенных клиентских учетных записей по нашему усмотрению."
        }
    },
    "terms_condition": {
        "title": "Условия обслуживания | Меню онлайн",
        "header": "Условия и положения для новых и существующих клиентов"
    },
    "privacy_policy_title": "КОНФИДЕНЦИАЛЬНАЯ ПОЛИТИКА",
    "privacy_policy_effective_date": "Действует с 16 января 2020 г.",
    "privacy_policy_general_title": "ОБЩАЯ ЧАСТНАЯ ПОЛИТИКА",
    "privacy_policy_intro": "menuonline.com (Menuonline и «мы») понимает важность защиты конфиденциальности личной информации отдельных лиц и важность безопасности конфиденциальной информации всех типов. Мы создали эту Политику конфиденциальности («Политика»), чтобы продемонстрировать нашу приверженность защите вашей «конфиденциальности». Эта Политика конфиденциальности объясняет:",
    "privacy_policy_points": [
        "Какую личную информацию мы собираем и почему мы ее собираем;",
        "Как мы используем вашу личную информацию;",
        "С кем мы делимся вашей личной информацией; и",
        "Возможности доступа, обновления и удаления вашей личной информации из сервиса."
    ],
    "privacy_policy_pipeda": "Menuonline управляет сбором всей информации в соответствии с Законом Канады о защите личной информации и электронных документах (PIPEDA).",
    "privacy_policy_update_notice": "Обратите внимание, что Menuonline оставляет за собой право обновлять или изменять данную Политику конфиденциальности в любое время. Мы уведомим вас о любых изменениях, разместив обновленную Политику на этом веб-сайте или сделав ее доступной через службы. Самая последняя версия этой Политики будет регулировать использование mycircle личной информации пользователей. Продолжая посещать веб-сайт mycircle или продолжая пользоваться его услугами после внесения изменений в данную Политику конфиденциальности, вы соглашаетесь соблюдать пересмотренные условия.",
    "privacy_policy_consent_title": "СОГЛАСИЕ НА ПЕРСОНАЛЬНУЮ ИНФОРМАЦИЮ",
    "privacy_policy_consent_text": "Предоставляя Menuonline свою личную информацию через наш веб-сайт или любую из наших форм, вы даете согласие на сбор, использование и раскрытие такой информации в соответствии с настоящей Политикой или в целях, определенных вам на момент предоставления личной информации. Любое вторичное использование личной информации будет обрабатываться только с вашего прямого согласия, или вам будет предоставлена возможность отказаться.",
    "privacy_policy_opt_out_text": "У вас есть возможность отозвать свое согласие или отказаться в любое время. Если вы хотите отозвать свое согласие или отказаться, вы можете нажать на ссылку «Настройки коммуникаций» в нижней части любого электронного письма, которое вы получаете от нас.",
    "privacy_policy_info_collection_title": "ИНФОРМАЦИЯ, КОТОРУЮ МЫ СОБИРАЕМ",
    "privacy_policy_info_collection_text": "Menuonline собирает и обрабатывает персональные данные в соответствии с настоящей Политикой. Мы не будем продавать, делиться или сдавать эту информацию в аренду другим лицам, за исключением случаев, предусмотренных настоящей Политикой. Menuonline собирает персональные данные в целях предоставления вам наших услуг или продуктов.",
    "privacy_policy_info_types_text": "Menuonline собирает следующие типы информации:",
    "privacy_policy_account_holders_title": "Владельцы счетов Menuonline",
    "privacy_policy_registration_info": "Регистрационная информация: Если вы регистрируетесь для использования услуг, вам нужна учетная запись Menuonline, чтобы стать владельцем учетной записи («Владелец учетной записи»). При регистрации учетной записи вам будет предложено предоставить определенную базовую информацию, такую как ваше имя, адрес электронной почты, имя пользователя, пароль, название компании, род занятий, местоположение и номер телефона. Пароли зашифрованы — сотрудники Menuonline не могут просматривать или извлекать пароли.",
    "privacy_policy_billing_info": "Платежная информация: Если вы подписываетесь на наши услуги, вам необходимо предоставить нам платежную и платежную информацию.",
    "privacy_policy_cookies_info": "Файлы cookie: Мы используем файлы cookie для отслеживания данных посетителей, таких как источник посещений веб-сайта и количество посещений нашего веб-сайта.",
    "privacy_policy_ip_info": "IP-адреса: IP-адреса собираются о вас в ходе использования вами услуг (например, с целью регистрации местоположения на уровне страны и IP-адресов, используемых при использовании наших услуг).",
    "privacy_policy_your_contacts_title": "Ваши контакты",
    "privacy_policy_distribution_list": "Список рассылки: В рамках вашего использования наших услуг вы можете предоставить нам, или мы можем собирать в рамках услуг, персональные данные о ваших контактах («Контакты»). Эта информация включает адреса электронной почты и может включать другую идентифицируемую информацию, такую как имя, название компании или другую информацию, которую вы предоставляете Menuonline при использовании услуг. Вы должны убедиться, что вы получили соответствующее согласие от ваших Контактов, чтобы разрешить нам обрабатывать их информацию.",
    "privacy_policy_billing_info_contacts": "Платежная информация: Если вы подписываетесь на наши услуги, вам необходимо предоставить нам платежную и платежную информацию.",
    "privacy_policy_cookies_contacts": "Файлы cookie: Menuonline будет использовать файлы cookie и аналогичные технологии отслеживания для сбора и использования личной информации о ваших контактах, только если вы добавили код отслеживания Menuonline на свой веб-сайт. Любые целевые страницы, размещенные Menuonline, будут автоматически иметь код отслеживания.",
    "privacy_policy_ip_contacts": "IP-адреса: IP-адреса ваших контактов собираются в рамках услуг (например, с целью регистрации местоположения на уровне страны и IP-адресов, используемых при отправке формы и предоставлении согласия).",
    "privacy_policy_info_about_contacts_title": "ИНФОРМАЦИЯ, КОТОРУЮ МЫ СОБИРАЕМ О ВАШИХ КОНТАКТАХ",
    "privacy_policy_info_about_contacts_notice": "*Этот раздел применяется только к информации, собираемой, используемой или раскрываемой Menuonline о контактах владельца счета в целях предоставления услуг*",
    "privacy_policy_info_about_contacts_desc": "Menuonline будет собирать, использовать или раскрывать персональную информацию о Контактах только в том случае, если Владелец учетной записи зарегистрировался для определенных услуг, где они предоставляют нам список рассылки. Владельцы учетных записей несут исключительную ответственность за обеспечение соблюдения всех применимых законов при предоставлении персональной информации о своих Контактах Menuonline через услуги.",
    "privacy_policy_info_auto_collect_title": "Информация, которую Menuonline собирает автоматически",
    "privacy_policy_info_auto_collect_desc1": "Когда контакты владельца учетной записи взаимодействуют с кампанией по электронной почте, полученной от владельца учетной записи, или взаимодействуют со ссылкой на связанный веб-сайт владельца учетной записи, Menuonline может собирать информацию об устройстве такого контакта и взаимодействии с электронной почтой посредством использования файлов cookie. Эта информация собирается только для предоставления услуг и предоставления информации владельцу учетной записи.",
    "privacy_policy_info_auto_collect_desc2": "Информация, которую мы собираем об устройстве Контакта и приложениях, которые Контакт использует для доступа к электронным письмам, отправленным Владельцем учетной записи, через наши сервисы, может включать IP-адрес Контакта, операционную систему, идентификатор браузера и другую связанную информацию о системе и подключении Контакта.",
    "privacy_policy_info_auto_collect_desc3": "Информация, которую мы собираем о взаимодействии Contact с электронными письмами, отправленными через службы, может включать даты и время, когда Contact получает доступ к электронной почте и совершает действия в браузере, а также информацию о производительности служб, например, о доставке электронных писем и других сообщений, отправленных Contact Владельцем учетной записи через службы.",
    "privacy_policy_info_auto_collect_desc4": "Обратите внимание, что Menuonline не будет связываться ни с одним из Ваших контактов, если это не требуется действующим законодательством.",
    "privacy_policy_why_we_collect_title": "ПОЧЕМУ МЫ СОБИРАЕМ ВАШУ ИНФОРМАЦИЮ",
    "privacy_policy_why_we_collect_desc": "Menuonline собирает информацию в следующих целях:",
    "privacy_policy_why_collect_1": "предоставлять Владельцам учетных записей Menuonline услуги, на которые они подписаны, включая использование информации Владельца учетной записи для услуг;",
    "privacy_policy_why_collect_2": "для администрирования и управления аккаунтом, а также доступа к веб-сайту mycircle;",
    "privacy_policy_why_collect_3": "если вы дали согласие на получение маркетинговых и рекламных материалов, для информирования вас о других продуктах и услугах, предлагаемых Menuonline; и",
    "privacy_policy_why_collect_4": "для ответа на сообщения Владельца счета или для отправки запрошенной вами информации. Это может включать, но не ограничиваться:",
    "privacy_policy_why_collect_5": "специальные предложения и обновления;",
    "privacy_policy_why_collect_6": "информационный бюллетень; и",
    "privacy_policy_why_collect_7": "служебные объявления.",
    "privacy_policy_opt_out_desc": "Вы можете в любое время отказаться от получения сообщений, нажав кнопку «Отписаться» в нижней части каждого электронного письма, отправленного вам Menuonline.",
    "privacy_policy_use_disclosure_title": "ИСПОЛЬЗОВАНИЕ И РАСКРЫТИЕ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ",
    "privacy_policy_use_disclosure_desc1": "Персональная информация, предоставленная Menuonline, в том числе через веб-сайт Menuonline или предписанную форму, хранится строго конфиденциально, если только она не раскрывается в соответствии с явным согласием Владельца счета или требованиями законодательства. В любом случае любая персональная информация, предоставленная на любой платформе «Menuonline» Владельцами счета, является добровольной: любая персональная информация, предоставленная Владельцем счета, предоставляется по усмотрению Владельца счета. Обратите внимание, что отзыв вашего согласия может привести к невозможности доступа или использования определенных услуг Menuonline.",
    "privacy_policy_use_disclosure_desc2": "Menuonline использует третьих лиц и внешних поставщиков услуг в отношении своего веб-сайта и услуг. Персональная информация Владельца счета может быть передана таким третьим лицам и поставщикам. Мы также можем предоставлять обобщенную статистику о Владельцах счетов, продажах, схемах трафика и связанную информацию авторитетным третьим лицам. Menuonline требует, чтобы любой сторонний поставщик, имеющий доступ к личной информации, был связан обязательствами, соответствующими настоящей Политике конфиденциальности, и чтобы такие поставщики собирали, использовали или раскрывали личную информацию Владельца счета только в целях предоставления услуг Menuonline.",
    "privacy_policy_use_disclosure_desc3": "Персональная информация, предоставленная Владельцами счетов, не будет продана, передана или сдана в аренду сторонним организациям, если только это не соответствует вашему подразумеваемому или явному согласию. Menuonline оставляет за собой право связаться с любыми Владельцами счетов для пересылки информации, ответа на вопросы или предоставления уведомлений о любых изменениях в нашей программе или наших политиках. Обратите внимание, что Menuonline не будет связываться ни с одним из ваших Контактов, если только это не требуется применимым законодательством.",
    "privacy_policy_use_disclosure_desc4": "Menuonline будет раскрывать персональные данные только в том случае, если на это получено согласие соответствующего лица или:",
    "privacy_policy_disclosure_list_1": "когда это разрешено или требуется по закону, например, в ответ на повестку в суд или другой судебный процесс;",
    "privacy_policy_disclosure_list_2": "получателю всего или части Menuonline;",
    "privacy_policy_disclosure_list_3": "поставщикам услуг Menuonline в соответствии с настоящей Политикой; или",
    "privacy_policy_disclosure_list_4": "для выполнения юридических обязательств, включая, помимо прочего, обязательства по предоставлению нормативной отчетности.",
    "privacy_policy_service_providers_title": "Menuonline использует следующих третьих лиц и поставщиков услуг (персональная информация ваших контактов не раскрывается «таким» третьим лицам):",
    "privacy_policy_service_provider_1": "Мы используем ManyChat для предоставления услуг чата на сайте для продаж и поддержки, а также для отправки последующих писем по итогам этих разговоров.",
    "privacy_policy_service_provider_2": "Мы используем AskNicely для опроса наших клиентов с целью получения информации о качестве обслуживания и поддержки, которую они получают.",
    "privacy_policy_service_provider_3": "Мы используем join.me для планирования встреч с клиентами и потенциальными клиентами, звонков в службу поддержки и демонстраций программного обеспечения.",
    "privacy_policy_service_provider_4": "Мы используем Google Analytics для отслеживания анонимной активности на веб-сайте и оценки моделей использования нашего веб-сайта.",
    "privacy_policy_breach_notification_title": "УВЕДОМЛЕНИЕ О НАРУШЕНИИ",
    "privacy_policy_breach_notification_desc": "Menuonline обязуется соблюдать и оказывать Владельцам счетов разумную помощь в соответствии с применимым законодательством в отношении любого несанкционированного использования, доступа или раскрытия личной информации.",
    "privacy_policy_rights_access_title": "ВАШИ ПРАВА И ДОСТУП",
    "privacy_policy_rights_access_desc": "Вы имеете право на доступ к персональным данным, которые Menuonline обрабатывает о вас. Вы можете запросить у нас информацию о:",
    "privacy_policy_rights_access_list_1": "Персональная информация о вас, которую мы храним",
    "privacy_policy_rights_access_list_2": "Категории персональной информации, о которой идет речь",
    "privacy_policy_rights_access_list_3": "Цели обработки",
    "privacy_policy_rights_access_list_4": "Подробная информация о том, кому была\/будет раскрыта ваша личная информация",
    "privacy_policy_rights_access_list_5": "Как долго мы храним вашу личную информацию",
    "privacy_policy_rights_access_list_6": "Если мы не собирали информацию напрямую от вас, информация об источнике",
    "privacy_policy_rights_access_list_7": "Как подать жалобу в соответствующий надзорный орган",
    "privacy_policy_rights_access_list_8": "Вы также можете запросить у нас следующее:",
    "privacy_policy_rights_access_list_9": "Чтобы мы обновили любую неполную или неточную информацию о вас",
    "privacy_policy_rights_access_list_10": "Попросите нас удалить вашу личную информацию в соответствии с действующим законодательством.",
    "privacy_policy_rights_access_contact": "Вы можете потребовать, чтобы мы действовали в соответствии с вашими правами, связавшись с нами по адресу 2851 Dufferin Street, Toronto, ON, Canada, или отправив нам электронное письмо по адресу {link}.",
    "privacy_policy_accountability_title": "ОТВЕТСТВЕННОСТЬ",
    "privacy_policy_accountability_desc": "Menuonline обязуется нести ответственность за личную и конфиденциальную информацию, которую вы нам предоставляете. Контактную информацию см. в конце этой Политики.",
    "privacy_policy_links_other_sites_title": "ССЫЛКИ НА ДРУГИЕ САЙТЫ",
    "privacy_policy_links_other_sites_desc1": "Сайт Menuonline может содержать ссылки на другие сайты. Пожалуйста, имейте в виду, что Menuonline не несет ответственности за политику конфиденциальности других сайтов. Мы призываем наших Владельцев учетных записей помнить, что когда они покидают наш сайт, они должны внимательно прочитать заявления о политике конфиденциальности каждого сайта, который собирает персональную информацию.",
    "privacy_policy_links_other_sites_desc2": "Настоящая Политика конфиденциальности применяется исключительно к информации, собираемой нами.",
    "privacy_policy_links_other_sites_desc3": "Если вы переходите по ссылке с сайта Menuonline для покупки товаров или услуг, вы совершаете транзакцию покупки в соответствии с правилами и положениями компании-поставщика, а не Menuonline.",
    "privacy_policy_cookies_title": "ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ ФАЙЛОВ COOKIE",
    "privacy_policy_cookies_desc1": "Веб-сайт и службы Menuonline используют файлы cookie для сбора и хранения определенной информации в соответствии с этой политикой. Файл cookie — это небольшой текстовый файл, отправленный с веб-сайта и сохраненный на компьютере пользователя веб-браузером пользователя. При посещении сайта, использующего файлы cookie, файл cookie загружается на ваш компьютер или мобильное устройство. В следующий раз, когда вы посетите этот сайт, ваше устройство запомнит полезную информацию, такую как предпочтения, посещенные страницы или параметры входа.",
    "privacy_policy_cookies_desc2": "Файлы cookie широко используются для добавления функциональности веб-сайтам или для обеспечения их более эффективной работы. Наш сайт использует файлы cookie для оптимизации пользовательского опыта и обеспечения надлежащей работы сервисов сайта.",
    "privacy_policy_cookies_desc3": "Большинство веб-браузеров позволяют ограничить или заблокировать файлы cookie через настройки браузера, однако, если вы отключите файлы cookie, вы можете обнаружить, что это повлияет на вашу возможность использовать определенные разделы нашего веб-сайта или сервисов.",
    "privacy_policy_cookies_analytical_purpose": "<strong> Аналитические цели: <\/strong> Мы используем файлы cookie для анализа активности владельцев счетов с целью улучшения нашего веб-сайта. Например, мы можем использовать файлы cookie для просмотра совокупных шаблонов, таких как функции, используемые владельцами счетов. Мы можем использовать такой анализ для получения информации о том, как улучшить функциональность и пользовательский опыт нашего веб-сайта.",
    "privacy_policy_cookies_preferences_user_experience": "<strong> Ваши предпочтения и пользовательский опыт: <\/strong> Мы используем файлы cookie для сбора определенной информации о посетителях и владельцах учетных записей, такой как тип браузера, сервер, языковые предпочтения и настройки страны, чтобы сохранить предпочтения владельца учетной записи на нашем веб-сайте, чтобы сделать опыт владельца учетной записи более последовательным и удобным. Мы используем файлы cookie для поддержания вашего состояния входа в систему при повторном посещении нашего веб-сайта.",
    "privacy_policy_cookies_targeted_ads": "<strong> Предоставление услуг измерения и более целенаправленной рекламы и маркетинга: <\/strong> Мы используем файлы cookie, веб-маяки и другие технологии хранения от сторонних партнеров, таких как Google и Facebook, для услуг измерения, более целенаправленной рекламы и в маркетинговых целях. Это происходит, когда вы посещаете наш веб-сайт. Эти файлы cookie, веб-маяки и другие технологии хранения позволяют нам отображать вам рекламные материалы Menuonline на других сайтах, которые вы посещаете в Интернете.",
    "privacy_policy_security_title": "БЕЗОПАСНОСТЬ",
    "privacy_policy_security_desc1": "Menuonline прилагает усилия для защиты конфиденциальной информации всех типов, включая персональные данные, с помощью организационных, физических, механических и электронных мер безопасности, соответствующих степени конфиденциальности информации. Наши серверы находятся в Канаде и соответствуют отраслевым стандартам безопасности.",
    "privacy_policy_security_desc2": "Однако обратите внимание, что мы не можем гарантировать, что принимаемые нами меры обеспечат безопасность информации.",
    "privacy_policy_retention_title": "УДЕРЖАНИЕ",
    "privacy_policy_retention_desc": "Мы храним персональные данные Владельцев счетов и Контактов в течение срока, необходимого для целей, изложенных в настоящей Политике конфиденциальности, и в соответствии с положениями о хранении, изложенными в Условиях использования.",
    "privacy_policy_access_questions_title": "ДОСТУП, ВОПРОСЫ И ПРОБЛЕМЫ",
    "privacy_policy_access_questions_desc": "Лицо, предоставившее персональные данные Menuonline, может подать письменный запрос на доступ или исправление своих персональных данных, хранящихся Menuonline.",
    "terms_obj": {
        "terms_conditions_title": "УСЛОВИЯ И ПОЛОЖЕНИЯ",
        "terms_conditions_subtitle": "Условия и положения для новых и существующих клиентов",
        "terms_conditions_desc1": "СЛЕДУЮЩИЕ КЛЮЧЕВЫЕ ПУНКТЫ (ВКЛЮЧАЯ ССЫЛКИ НА РАЗДЕЛЫ) ПРЕДОСТАВЛЕНЫ ТОЛЬКО ДЛЯ ВАШЕГО УДОБСТВА И НЕ ЗАМЕНЯЮТ ПОЛНЫЕ УСЛОВИЯ И ПОЛОЖЕНИЯ. ВЫ ОБЯЗАНЫ ПРОЧИТАТЬ ЭТИ УСЛОВИЯ И ПОЛОЖЕНИЯ ПЕРЕД ИСПОЛЬЗОВАНИЕМ УСЛУГИ Menuonline.",
        "terms_conditions_desc2": "Использование Menuonline означает, что вы соглашаетесь с Условиями и положениями, а также заявляете и гарантируете, что ваша организация или компания уполномочила вас на это. (См. раздел 3 «Как это работает»).",
        "terms_conditions_desc3": "Menuonline — это маркетинговая служба, основанная на разрешении. Намеренная отправка «спама» или нарушение Условий приведет к закрытию вашего аккаунта. (См. п. 16 «Приемлемое использование Сервиса»)",
        "terms_conditions_desc4": "Данные, которые вы размещаете в Menuonline, такие как контактные данные, содержимое электронной почты и информация, которую вы собираете через Menuonline, принадлежат вам. (См. раздел 14 «Чем вы владеете?»).",
        "terms_conditions_desc5": "Программное обеспечение Menuonline и содержимое нашего веб-сайта принадлежат нам. (См. п. 13 «Чем мы владеем?»)",
        "terms_conditions_desc6": "Помимо ваших собственных пользователей, данные или неопубликованный контент, размещенные вами в Menuonline, могут просматривать только сотрудники и партнеры Menuonline, подписавшие соглашения о конфиденциальности. (См. раздел 14 «Чем вы владеете?»).",
        "terms_conditions_desc7": "Вся персональная информация (PII) хранится только на серверах, расположенных в Канаде. (См. раздел 14 «Чем вы владеете?»).",
        "terms_conditions_desc8": "Сервис Menuonline подчиняется законам Канады и провинции Онтарио. (См. раздел 24 «Применимое законодательство»).",
        "terms_conditions_desc9": "Любые запросы на ваши данные или контент от частных лиц, полиции или других регулирующих органов из Канады или за ее пределами всегда будут направлены вам. Единственным исключением будет, если это нарушит канадские или канцелярские законы Онтарио; однако во всех случаях мы будем решительно противиться любой разглашению ваших данных кем-либо, кроме вас. (См. раздел 14 «Чем вы владеете?»).",
        "terms_conditions_desc10": "Безопасность Menuonline зависит только от вашего управления именами пользователей и паролями. Мы не взимаем плату за учетные записи пользователей Menuonline, поэтому создайте отдельную учетную запись для каждого пользователя и держите пароли в секрете. Вы несете ответственность за свою учетную запись. (См. раздел 3 «Как это работает»)",
        "terms_conditions_desc11": "Вы можете отменить свой аккаунт в любое время. Мы не предоставляем возврат средств за неиспользованный кредит, если вы отмените. (См. раздел 3 «Как это работает»)",
        "terms_conditions_desc12": "Мы можем отменить ваш аккаунт в любое время. Мы предоставляем возврат средств за кредиты электронной почты и неиспользованные услуги, если мы отменим ваш аккаунт. (См. раздел 3 «Как это работает»)",
        "terms_conditions_desc13": "Ваши данные будут удалены после отмены, а также могут быть удалены, если пользователь учетной записи не входил в систему в течение 12 месяцев или более. Мы будем хранить агрегированные данные из вашей учетной записи, которые не включают персонально идентифицируемую информацию для статистических целей. (См. s.3 «Как это работает»)",
        "terms_conditions_desc14": "Кредиты электронной почты приобретаются по принципу «оплата по мере использования» и никогда не истекают. Единственное исключение — если вы не заходили в свою учетную запись в течение 12 месяцев. В этом случае мы оставляем за собой право удалить вашу учетную запись, ваши данные и связанные кредиты. (См. раздел 10 «Сколько я плачу за Услугу?»)",
        "terms_conditions_desc15": "Мы прилагаем все усилия, чтобы обеспечить постоянную работу Menuonline и заранее уведомить вас, если нам нужно временно приостановить обслуживание для проведения технического обслуживания. Однако мы не гарантируем, что обслуживание будет работать постоянно, и мы не несем ответственности за любые убытки, которые вы понесете при использовании Menuonline, включая убытки с вашей стороны, если система не работает в то время, когда она вам нужна. (См. раздел 17 «Отказ от ответственности»).",
        "terms_conditions_desc16": "Мы не отслеживаем ваш контент или то, кому вы отправляете сообщения. Однако мы отслеживаем жалобы и другие проблемы, вызванные вашей деятельностью. Слишком большое количество жалоб или проблем может привести к отмене вашего сервиса по нашему усмотрению. (См. п. 7 «Мониторинг»)",
        "terms_conditions_desc17": "Мы стремимся предоставлять отличную поддержку продукта, чтобы помочь вам стать самодостаточными с Menuonline. Мы предоставляем поддержку в форме документации и видео на нашем веб-сайте, в системе тикетов поддержки Menuonline и по телефону. Поддержка продукта бесплатна; однако мы оставляем за собой право прекратить поддержку определенных клиентских учетных записей по нашему усмотрению. (См. раздел 5 «Поддержка»)",
        "terms_conditions_desc18": "Product Services — для тех случаев, когда вы хотите, чтобы мы выполнили некоторую работу, связанную с Menuonline, а не делали это сами. Наша ставка за Product Services составляет 135 долларов в час. Мы также предоставляем консультационные услуги по более высоким ставкам. Мы оставляем за собой право изменять наши ставки в любое время. (См. раздел 6 «Product and Consulting Services»)",
        "terms_conditions_desc19": "Мы сообщим вам, если посчитаем, что произошла утечка ваших данных, и приложим все усилия, чтобы предоставить соответствующие сведения и информацию. (См. раздел 14 «Чем вы владеете?»)",
        "terms_conditions_desc20": "Мы оставляем за собой право вносить изменения в это соглашение в любое время. Мы постараемся информировать вас об изменениях в положениях и условиях, но использование сервиса означает, что вы соглашаетесь с изменениями в Условиях. (См. п. 9 «Изменения»).",
        "terms_conditions_desc21": "Условия и положения, а также наша Политика конфиденциальности составлены только на английском языке, и вы соглашаетесь с тем, что версия Условий и положений на английском языке и Политики конфиденциальности на английском языке будет иметь приоритет в случае возникновения противоречий с любой переведенной версией.",
        "terms_conditions_mycircle": "УСЛОВИЯ И ПОЛОЖЕНИЯ MYCIRCLE",
        "terms_conditions_last_update": "ПОСЛЕДНЕЕ ОБНОВЛЕНИЕ: 14 января 2020 г.",
        "terms_conditions_usage_agreement": "Пожалуйста, внимательно прочтите настоящие положения и условия обслуживания (далее «Условия») перед использованием этого веб-сайта (далее «Сайт»). Настоящие Условия освобождают Menuonline («Menuonline», «мы» или «нас») и других от ответственности и\/или ограничивают нашу и их ответственность и содержат другие важные положения, которые применяются к вашему использованию этого Сайта.",
        "terms_conditions_acceptance": "Посещая или используя этот Сайт или Услугу, вы соглашаетесь от своего имени и от имени любой организации, от имени которой вы можете действовать (далее совместно именуемые «вы»), принять и соблюдать настоящие Условия при каждом использовании и каждом посещении этого Сайта.",
        "terms_conditions_services": "1. Наши услуги.",
        "terms_conditions_services_desc": "Услуги, которые мы предлагаем через Сайт, представляют собой сервис, позволяющий вам создавать, отправлять и управлять сообщениями электронной почты (далее — «Электронные письма») получателям по вашему выбору (далее — «Сервис»).",
        "terms_conditions_license": "2. Лицензия.",
        "terms_conditions_license_desc": "В соответствии с настоящими Условиями Menuonline настоящим предоставляет вам неисключительную, ограниченную, непередаваемую лицензию в течение Срока для просмотра Сайта и использования Сервиса в соответствии с функциями Сервиса. Мы оставляем за собой все права, не предоставленные явно в настоящем документе на Сервис и Контент Menuonline (как определено ниже).",
        "terms_conditions_account": "3. Как это работает.",
        "terms_conditions_account_desc": "Чтобы использовать Сервис, вам необходимо зарегистрироваться для получения учетной записи. Ваша учетная запись бесплатна. Ваша учетная запись дает вам доступ к Сервису и «функциям», которые мы можем устанавливать и поддерживать время от времени. Чтобы иметь право на учетную запись, вы должны:",
        "terms_conditions_account_requirements": [
            "быть не моложе восемнадцати (18) лет и иметь право заключать договоры;",
            "завершить установленный нами процесс регистрации;",
            "соглашаетесь с настоящими Условиями;",
            "предоставить достоверную, полную и актуальную контактную информацию, а также информацию для выставления счетов\/платежей."
        ],
        "responsibility": "Ответственность.",
        "responsibilityText": "Вы несете исключительную ответственность за действия, которые происходят в вашей учетной записи, и вы должны хранить пароль своей учетной записи в тайне и безопасности. Учетные записи бесплатны, поэтому мы рекомендуем вам создать отдельную учетную запись для каждого пользователя, который у вас есть. Вы также несете ответственность за любую учетную запись, к которой у вас есть доступ, независимо от того, разрешили ли вы использование этой учетной записи или нет. Вы соглашаетесь, что несете исключительную ответственность за свое взаимодействие с теми, кому вы отправляете электронные письма, и вы будете отправлять электронные письма только тем людям, от которых у вас есть согласие. Вы должны немедленно уведомить нас о любом несанкционированном использовании ваших учетных записей.",
        "compliance_with_CASL": "Соблюдение канадского законодательства о борьбе со спамом (CASL).",
        "compliance_with_CASL_text": "Роль Menuonline заключается в содействии управлению настройками согласия, а не в обеспечении соблюдения законодательства. Вы несете ответственность за соответствие CASL. Мы помогаем вам, устанавливая функциональность по умолчанию и содействуя получению и обновлению документированного явного согласия и управлению скользящими датами истечения срока действия подразумеваемого согласия. Вы полностью контролируете управление согласием для своей базы данных; вы можете в любое время обновить статус согласия для всех своих контактов оптом, переопределить настройки по умолчанию и отключить автоматическое истечение срока действия подразумеваемого согласия.",
        "closing_account": "Закрытие вашего аккаунта.",
        "closing_account_text": "Вы или Menuonline можете расторгнуть настоящее Соглашение в любое время и по любой причине, уведомив об этом другую сторону. Если вы предоставите какую-либо информацию, которая является ложной, неточной, неактуальной или неполной, или будете использовать Сервис таким образом, который нарушает настоящие Условия, мы имеем право немедленно приостановить действие вашей учетной записи и прекратить ваш доступ к Сервису в дополнение к любым другим доступным нам средствам правовой защиты. Если вы не используете свою учетную запись в течение двенадцати (12) месяцев, мы можем аннулировать вашу учетную запись. Если мы аннулируем вашу учетную запись по любой причине, мы вернем вам все оставшиеся у вас Кредиты. После аннулирования мы навсегда удалим вашу учетную запись и все связанные с ней данные, включая отправленные вами электронные письма. Мы можем хранить обобщенные данные из вашей учетной записи, которые не включают персонально идентифицируемую информацию, для статистических целей.",
        "emailing_content": "Отправка контента по электронной почте.",
        "emailing_content_text": "При взаимодействии с Menuonline через этот Сайт или Сервис вы соглашаетесь предоставлять правдивую и точную информацию. Кроме того, вы понимаете, что предоставление конфиденциальной информации по электронной почте является небезопасной практикой. Вы понимаете, что вы несете исключительную ответственность за все, что вы отправляете кому-либо, использующему наш Сервис. целей.",
        "support": "Поддерживать.",
        "support_text": "Мы оказываем вам поддержку, чтобы помочь вам стать самодостаточными с помощью Сервиса. Мы оказываем вам поддержку в виде документации и видео на Сайте, в системе тикетов поддержки Menuonline и по телефону. Вы можете связаться с нами, нажав на вкладку «Поддержка» в верхней части Сайта. Поддержка продукта бесплатна; однако мы оставляем за собой право прекратить поддержку определенных клиентских учетных записей по нашему усмотрению.",
        "product_and_consulting_services": "Продуктовые и консультационные услуги.",
        "product_and_consulting_services_text": "Product Services — это для тех случаев, когда вы хотите, чтобы мы выполнили некоторую работу, связанную с Menuonline, а не делали это сами. Наша ставка за Product Services составляет 125 долларов в час. Мы также предоставляем консультационные услуги по более высоким ставкам. Мы оставляем за собой право изменять наши ставки в любое время.",
        "monitoring": "Мониторинг.",
        "monitoring_text": "Мы не будем отслеживать или оценивать содержание информации, передаваемой через Сервис, но будем расследовать, если получим жалобы на возможное ненадлежащее использование. Menuonline может в любое время и по своему усмотрению принимать решения о том, что конкретное использование является или не является надлежащим, с уведомлением вас или без него, в соответствии со следующими рекомендациями.",
        "term": "Срок.",
        "term_text": "Срок начинается с момента регистрации вами учетной записи у нас и продолжается до тех пор, пока вы пользуетесь Услугой. Вы заявляете и гарантируете, что у вас есть полномочия и возможность зарегистрировать учетную запись для нас (включая случаи, когда вы регистрируетесь от имени организации).",
        "changes": "Изменения.",
        "changes_text": "Срок начинается с момента регистрации вами учетной записи у нас и продолжается до тех пор, пока вы пользуетесь Услугой. Вы заявляете и гарантируете, что у вас есть полномочия и возможность зарегистрировать учетную запись для нас (включая случаи, когда вы регистрируетесь от имени организации).",
        "payment": "ОПЛАТА",
        "what_do_i_pay_for_service": "Сколько я плачу за Услугу?",
        "what_do_i_pay_for_service_text": "Вы можете купить предоплаченные кредиты для отправки электронной почты («Кредиты») через нашу Услугу, как мы подробно описываем на странице «цены» нашего Сайта. Кредиты никогда не истекают, и за эти Кредиты не взимается повторяющаяся плата. Единственным исключением является то, что если вы не используете свою учетную запись в течение двенадцати (12) месяцев, мы можем аннулировать вашу учетную запись и любые неиспользованные Кредиты. Мы можем изменить наши цены в любое время, обновив страницы с ценами нашего Сайта, что вступит в силу при вашей следующей покупке Кредитов.",
        "how_do_i_pay_for_service": "Как мне оплатить Услугу?",
        "how_do_i_pay_for_service_text": "Вы можете оплатить кредиты с помощью любой основной кредитной карты. Мы можем изменить принимаемые нами способы оплаты в любое время.",
        "can_i_get_refund": "Могу ли я получить возврат денег?",
        "can_i_get_refund_text": "Суммы, которые вы заплатили за использование Сервиса, не подлежат возврату, если вы отмените свой аккаунт. Если мы отменим ваш аккаунт, мы вернем вам все оставшиеся у вас Кредиты.",
        "rights": "ПРАВА",
        "what_do_we_own": "Чем мы владеем?",
        "what_do_we_own_text": "За исключением контента, который вы нам предоставляете (включая, помимо прочего, контактные данные, контент электронной почты и информацию, которую вы собираете через Сервис) («Ваш контент»), все материалы, включенные в наш Сайт, Сервис и все материалы, размещенные в нем или переданные им, включая, помимо прочего, программное обеспечение, изображения, текст, графику, иллюстрации, логотипы, патенты, товарные знаки, знаки обслуживания, фотографии, аудио, видео, музыку и контент, принадлежащий другим Пользователям («Контент Menuonline») (и все права интеллектуальной собственности, связанные с ним), принадлежат нам или нашим лицензиарам.",
        "what_do_you_own": "Чем вы владеете?",
        "what_do_you_own_text": "Мы не претендуем на права собственности на Ваш контент. Ваш контент принадлежит вам, и вы сохраняете право собственности на Ваш контент, который вы загружаете в Сервис. Делясь своим контентом через Сервис, вы соглашаетесь разрешить другим просматривать, редактировать и\/или делиться Вашим контентом в соответствии с вашими настройками и настоящими Условиями. Вы заявляете и гарантируете, что вы либо владеете, либо имеете разрешение на использование всех материалов, которые вы используете в своих электронных письмах. Мы можем использовать или раскрывать ваши материалы только так, как мы описываем в настоящих Условиях и нашей Политике конфиденциальности.",
        "privacy_statement": "Menuonline уважает конфиденциальность своих пользователей. Любая личная информация, которую Menuonline собирает через этот Сайт или Сервис, подпадает под действие Заявления о конфиденциальности Menuonline, которое включено в настоящие Условия посредством ссылки. Мы можем использовать и раскрывать вашу информацию в соответствии с нашим Заявлением о конфиденциальности.",
        "access_to_your_content": "Menuonline не будет предоставлять доступ к Вашему контенту никому, кроме тех сотрудников и подрядчиков Menuonline, которые заключили с нами соответствующие соглашения о конфиденциальности или получили ваше согласие.",
        "governmentRequest": "Если мы получим запрос от правительства или регулирующего органа на доступ к Вашему контенту, мы уведомим вас о запросе и запросим ваше согласие, прежде чем мы опубликуем какой-либо Ваш контент. Мы будем отклонять любые запросы на Ваш контент и не опубликуем Ваш контент в ответ на этот тип запроса без вашего разрешения или требования суда компетентной юрисдикции или соответствующего регулирующего органа.",
        "breachNotification": "В случае, если нам станет известно, что Ваш Контент подвергся несанкционированному доступу, использованию, копированию, краже, потере или использованию или раскрытию с нарушением настоящих Условий (далее — «Нарушение»), мы незамедлительно уведомим вас и проинформируем о том, что произошло. Вы соглашаетесь с тем, что Нарушения будут рассматриваться в соответствии с применимым законодательством, и если нам потребуется, чтобы вы проинформировали участников ваших списков рассылки по электронной почте, вы согласитесь сделать это незамедлительно и в соответствии с нашими указаниями.",
        "contentRetention": "Мы будем хранить, использовать и передавать Ваш контент в Канаде, за исключением случаев, когда у Вас есть предварительное письменное согласие или указание (например, если Вы решите отправить Ваш контент лицам за пределами Канады). Серверы, используемые для предоставления Сервиса, расположены в Канаде.",
        "accessTrouble": "Если у вас возникли проблемы с доступом к вашему контенту, Menuonline поможет вам восстановить ваш контент с учетом затрат времени и средств.",
        "reportingAbuse": "Если вы считаете, что кто-то нарушает какие-либо из этих Условий, сообщите нам об этом немедленно. Если вы получили спам, который, по вашему мнению, пришел от другого пользователя Сервиса, если вы считаете, что другой пользователь Сервиса разместил материал, нарушающий какие-либо авторские права, или если вы считаете, что другой пользователь Сервиса иным образом нарушает эти Условия, свяжитесь с нами по адресу Support@menuonline.com.",
        "acceptableUse": "Вы заявляете и гарантируете, что ваше использование Сервиса будет соответствовать всем применимым законам и правилам. Вы соглашаетесь с тем, что вы несете ответственность за определение того, будет ли Сервис соответствовать и будет ли он пригоден для использования в вашей юрисдикции. Для большей уверенности вы соглашаетесь с тем, что вы должны (и соглашаетесь гарантировать, что ваши сотрудники, агенты и авторизованные пользователи должны) ...",
        "exportLaws": "Мы не делаем никаких заявлений о том, что Сайт или Сервис подходят или доступны для использования за пределами Канады, и доступ к ним с территорий, где их содержимое является незаконным, запрещен. Вы не можете использовать, экспортировать или реэкспортировать Контент Menuonline или другие материалы на этом Сайте или любую копию или адаптацию в нарушение любых применимых законов или правил, включая без ограничений канадские и американские экспортные законы и правила.",
        "disclaimers": "Сервис и этот Сайт предоставляются «как есть» без каких-либо заявлений, гарантий или условий любого рода, и Menuonline прямо отказывается, в полной мере, допустимой применимым законодательством, от всех заявлений, гарантий и условий, явных или подразумеваемых, в силу закона или иным образом, включая без ограничений любые подразумеваемые гарантии и условия товарной пригодности или пригодности для какой-либо конкретной цели или использования, или ненарушения прав третьих лиц...",
        "limitLiability": "Совокупная ответственность Menuonline по настоящему Соглашению, будь то по договору или деликту (включая халатность), в результате нарушения гарантии, строгой ответственности или по любой другой теории ответственности, будет ограничена прямыми убытками в размере, не превышающем общую сумму гонораров, уплаченных Menuonline в соответствии с настоящими Условиями за двенадцать (12) месяцев, непосредственно предшествующих месяцу, в котором произошло последнее событие, послужившее основанием для возникновения ответственности.",
        "liabilityExclusions": "Ни Menuonline, ни ее филиалы, ни их соответствующие директора, должностные лица, сотрудники, агенты или другие представители (совместно именуемые «Представители» Menuonline) или операторы любых присоединенных сетей не будут нести никакой ответственности или обязательств в связи с данным Сайтом или Сервисом за: (i) любые косвенные, последующие, случайные, примерные, штрафные или особые убытки; или (ii) за любые убытки, будь то прямые, косвенные, последующие, случайные, примерные, штрафные или особые, характеризующиеся как упущенная выгода, упущенная сбережения или доход или упущенная выгода...",
        "indemnity": "Вы обязуетесь возмещать убытки и ограждать Menuonline и\/или представителей Menuonline («стороны, освобожденные от ответственности Menuonline») от любых претензий третьих лиц, а также от любых штрафов или санкций, наложенных на нас в связи с использованием вами информации, полученной с этого Сайта, или использованием вами Сервиса, а также от любого нарушения настоящих Условий...",
        "ipInfringement": "Menuonline обязуется защищать, возмещать убытки и ограждать вас от любых претензий, требований, действий, исков или судебных разбирательств, предъявленных или возбужденных против вас третьей стороной, утверждающей, что ваш доступ к Услуге или ее использование в соответствии с настоящим Соглашением нарушает права интеллектуальной собственности третьей стороны в соответствии с канадским законодательством («Иск о нарушении прав интеллектуальной собственности»)…",
        "equitableRelief": "Если вы нарушаете настоящие Условия, мы можем потребовать судебного запрета (то есть мы можем запросить постановление суда, чтобы остановить вас) или иного справедливого средства правовой защиты. Вы соглашаетесь, что это будет дополнением и не наносит ущерба любым другим правам, которые мы можем иметь по закону или по справедливости.",
        "linksToOtherSites": "Если мы предоставляем какие-либо ссылки на сторонние веб-сайты, они предоставляются только для удобства. Если вы используете эти ссылки, вы покидаете наш Сайт. Мы не контролируем эти сторонние сайты и не одобряем их. Вы соглашаетесь с тем, что мы не несем ответственности за какие-либо из этих сторонних сайтов или их содержимое...",
        "thirdPartyRequests": "Если нам необходимо предоставить информацию в ответ на юридический или правительственный запрос, касающийся вашей учетной записи или вашего использования Сервиса, вы соглашаетесь с тем, что мы можем взимать с вас плату за наши расходы. Эти сборы могут включать наши юридические сборы и расходы на время наших сотрудников, потраченное на подготовку ответа на запрос.",
        "assignment": "Настоящее Соглашение, а также любые права и лицензии, предоставленные по нему, не могут быть переданы или уступлены вами, но могут быть уступлены Menuonline без ограничений, после заблаговременного уведомления вас в разумные сроки.",
        "governingLaw": "Эти условия будут регулироваться и толковаться в соответствии со всеми применимыми федеральными законами и законами Онтарио без учета положений коллизионного права. Вы соглашаетесь подчиняться исключительной юрисдикции судов Онтарио для определения любого спора, касающегося этих Условий.",
        "severability": "Если по какой-либо причине суд компетентной юрисдикции признает какое-либо положение настоящих Условий или его часть незаконным или по какой-либо причине не имеющим исковой силы, то такое положение будет применяться в максимально допустимой степени для реализации цели настоящих Условий, а остальная часть настоящих Условий продолжит действовать в полной мере и останется подлежащей исполнению.",
        "waiver": "Любой отказ от требований настоящих Условий или согласие на отступление от них будет иметь силу только в том случае, если он оформлен в письменной форме и подписан нами, и только в конкретном случае и для конкретной цели, для которой он был дан. Неиспользование или задержка с использованием нами любого права в соответствии с настоящими Условиями не будет считаться отказом от наших прав.",
        "furtherAssurances": "Каждая сторона соглашается подписать и предоставить все документы, а также предпринять любые действия (или обеспечить совершение таких действий), необходимые для выполнения своих обязательств в соответствии с настоящими Условиями.",
        "notices": "Все уведомления Menuonline должны быть в письменной форме и отправлены нам по электронной почте. Уведомления нам должны быть отправлены на имя наших представителей службы поддержки клиентов по адресу support@menuonline.com. Уведомления вам могут быть отправлены на адрес или адрес электронной почты, предоставленные вами при регистрации. Кроме того, мы можем транслировать уведомления или сообщения через этот Сайт, чтобы информировать вас об изменениях на этом Сайте или о других важных вопросах, и эти трансляции будут считаться уведомлением вам в момент их публикации.",
        "entireAgreement": "Настоящие Условия и наше Заявление о конфиденциальности составляют полное соглашение между вами и нами относительно вашего использования Сайта и Сервиса и заменяют все предыдущие соглашения, заявления и договоренности между Menuonline и вами относительно этого предмета. В случае любого противоречия между Заявлением о конфиденциальности и настоящими Условиями, настоящие Условия и положения будут иметь преимущественную силу.",
        "language": "Стороны прямо запросили и потребовали, чтобы настоящие Условия, наша Политика конфиденциальности и любые связанные с ними документы были составлены на английском языке. Сторонам удобно и необходимо выразить свое мнение по поводу этого Контракта и всех документов, которые s'y rapportent soient rédigés en anglais. Вы соглашаетесь с тем, что версия настоящих Условий и Политики конфиденциальности на английском языке будет иметь приоритет в случае любого конфликта с любой переведенной версией.",
        "survival": "Все положения настоящих Условий, которые по своей природе или значению должны оставаться в силе после истечения срока действия или прекращения действия настоящих Условий, остаются в силе, включая, помимо прочего, разделы 17–19."
    },
    "collect_additional_info": "Хотите ли вы собрать дополнительную информацию?",
    "additionalInfoChoice": "Выберите любую дополнительную информацию, которую вы хотели бы собрать.",
    "additional_info_yes": "Да",
    "additional_info_noSkip": "Нет, пропустить",
    "additional_info_label": "Дополнительная информация",
    "confirm_business_location": "Давайте сначала подтвердим местоположение вашего бизнеса.",
    "custom_get_more_reviews_form": "Пользовательская форма «Получить больше отзывов»",
    "set_up_custom_form": "Настройте свою индивидуальную форму «Получить больше отзывов» по группам",
    "reputation_name_label": "Получить больше отзывов Имя",
    "reputation_name_placeholder": "Введите имя репутации",
    "group_label": "Группа",
    "group_desc_title": "Название описания группы",
    "group_desc": "Описание группы",
    "select_group_placeholder": "Выбрать группу",
    "required_group_name": "Требуется название группы",
    "before_asking_review": "Прежде чем просить клиентов оставить отзыв, давайте зададим им от 1 до 4 вопросов.",
    "incentive_settings": "Настройки стимулирования",
    "incentivize_customer": "Хотите ли вы поощрить своих клиентов за написание отзыва?",
    "choose_incentive": "Выберите поощрение, которое вы хотели бы предоставить.",
    "scan_qr_feedback": "Отсканируйте QR-код и поделитесь с нами ценным отзывом о вашем опыте.",
    "qr_code": "QR-код",
    "scan_me": "СКАНИРУЙ МЕНЯ!",
    "or_title": "ИЛИ",
    "send_otp_title": "Отправить одноразовый пароль",
    "sign_in_title": "Войти",
    "email_addresss_title": "Адрес электронной почты",
    "reset_password_title": "Сбросить пароль",
    "remember_password_title": "Запомнили пароль?",
    "digit_code_title": "Пожалуйста, введите 4-значный код, который был отправлен ",
    "not_received_title": "Я не получил проверочный код!",
    "resend_title": "Пожалуйста, отправьте повторно",
    "sign_up_form": "Создайте свою учетную запись",
    "sign_up_sub_title": "Войдите сейчас, чтобы получить доступ к последним новостям",
    "already_registered_title": "У вас уже есть учетная запись Menuonline?",
    "reserved_title": "Авторские права ©2023 Menuonline. Все права защищены.",
    "terms_title": "Условия обслуживания",
    "policy_title": "политика конфиденциальности",
    "sign_up_title": "Зарегистрироваться",
    "terms_conditions_title": "Условия и положения",
    "business_type_title": "Тип бизнеса",
    "business_type_sub_title": "Пожалуйста, выберите один из типов бизнеса, который лучше всего описывает ваш бизнес!",
    "salon_title": "Салон",
    "restaurant_title": "Ресторан",
    "pizza_store_title": "Магазин пиццы",
    "food_truck_title": "Фуд-трак",
    "check_domain_title": "Проверить доменное имя",
    "start_exploring_title": "Начать исследование",
    "sign_in_form": "Войдите в свою учетную запись",
    "sign_in_sub_title": "Войдите сейчас, чтобы получить доступ к последним новостям",
    "remember_title": "Запомнить меня",
    "otp_title": "ОТП",
    "password_title": "Пароль",
    "forgot_title": "Забыли пароль?",
    "not_registered_title": "У вас нет учетной записи?",
    "create_account_title": "Зарегистрируйтесь сейчас",
    "create_form_title": "Создать форму",
    "first_name_title": "Имя",
    "last_name_title": "Фамилия",
    "email_title": "Электронная почта",
    "mobile_number_title": "Номер мобильного телефона",
    "birthday_title": "День рождения",
    "gender_title": "Пол",
    "address_title": "Адрес",
    "short_answer_title": "Короткий ответ",
    "long_answer_title": "Длинный ответ",
    "single_answer_title": "Один ответ",
    "single_checkbox_title": "Один флажок",
    "multiple_choice_title": "Множественный выбор",
    "drop_down_title": "Падать",
    "yes_no_title": "Да или нет",
    "description_text_title": "Текст описания",
    "file_upload": "Загрузка файла",
    "reset_password_form": "Сбросьте свой пароль",
    "reset_password_sub_title": "Быстро сбросьте пароль и получите доступ к своей учетной записи.",
    "required_full_name_title": "Полное имя обязательно",
    "required_first_name_title": "Имя обязательно",
    "valid_first_name_title": "Введите действительное имя",
    "valid_full_name_title": "Введите действительное полное имя",
    "required_block_time": "Требуется время блока",
    "required_last_name_title": "Фамилия обязательна",
    "valid_last_name_title": "Введите действительную фамилию",
    "valid_mobile_number_title": "Введите действительный номер мобильного телефона",
    "required_mobile_number_title": "Требуется номер мобильного телефона",
    "required_already_mobile_number_title": "Номер мобильного телефона уже требуется",
    "valid_otp_title": "Введите действительный одноразовый пароль",
    "valid_email_title": "Введите действительный адрес электронной почты",
    "required_email_title": "Требуется адрес электронной почты",
    "required_description_title": "Описание требуется",
    "required_description_500_characters": "Введите не менее 500 символов.",
    "required_description_600_characters": "Введите максимум 600 символов.",
    "domain_title": "Доменное имя",
    "required_password_title": "Требуется пароль",
    "required_password_regex_title": "Должен содержать не менее 8 символов, по крайней мере одну заглавную букву, одну строчную букву, одну цифру и один специальный символ.",
    "required_terms_title": "Пожалуйста, примите условия и положения",
    "required_note_title": "Примечание обязательно",
    "required_birthday_title": "День рождения обязателен",
    "no_access_title": "У вас сейчас нет доступа. Обратитесь к администратору",
    "menu_online_open_txt": "Открыть",
    "loyalty_program_description": "😊 Найдите идеальную программу лояльности и настройте ее в соответствии с вашим брендом!",
    "lp_list_title": "Список карт лояльности",
    "lp_n_title": "Название перфокарты лояльности",
    "enter_details_title": "Введите данные",
    "stamp_allowed_title": "Штамп разрешен",
    "stamp_items_title": "Штампы",
    "lpn_req_title": "Требуется имя карты лояльности",
    "valid_lpn_title": "Введите действительное имя карты лояльности",
    "brand_color_error": "Цвет бренда должен состоять ровно из 7 символов.",
    "select_dark_brand_color_title": "Пожалуйста, выберите допустимый цвет бренда",
    "your_brand_color_title": "Цвет вашего бренда",
    "upload_logo_title": "Загрузить логотип",
    "locations_title": "Места",
    "add_lp_title": "Создать программу",
    "location_error": "Пожалуйста, выберите местоположение",
    "pvs_title": "Сколько марок может заработать клиент за одно посещение?",
    "max_apd_title": "Какое максимальное количество марок может заработать клиент за день?",
    "logo_error": "Логотип обязателен",
    "expiry_title": "Установить срок действия вознаграждений",
    "add_more_rewards_title": "+ Добавить больше наград",
    "prev_step_1_title": "Покажите QR-код, чтобы заработать баллы и получить награды.",
    "delete_lp_title": "Эта карта лояльности будет удалена навсегда.",
    "delete_user_record_title": "Эта запись пользователя будет удалена навсегда.",
    "redeem_title": "Выкупать",
    "lp_button_title": "Присоединяйтесь к карте лояльности",
    "lp_download_button_title": "Скачать карту",
    "step_0_title": "Присоединяйтесь сейчас, и таких преданных гурманов, как вы, ждут вкусные награды.",
    "step_1_title": "Вы присоединились",
    "congrats_step_title": "Теперь вы имеете право на бесплатную пиццу",
    "reward_title": "Получайте вознаграждения за каждое посещение",
    "visit_title": "Посетите и разблокируйте",
    "rewards_title": "Награды",
    "dummy_address": "3730 Frankfort Ave, Луисвилл, Кентукки, США",
    "loyalty_qr_default_text": "Отсканируйте QR-код, подпишитесь на нашу карту лояльности и получите вознаграждения",
    "lp_earn_stamp_txt": "За каждое посещение клиенты получают марки.",
    "lp_get_rewards_txt": "Получайте вознаграждения за каждый визит",
    "lp_1_time_visit_txt": "1 раз визит, который вы получите",
    "lp_uppercase_stamp_text": "Штамп",
    "lp_lowercase_stamp_text": "штамп",
    "lp_txt": "Верность",
    "lp_per_visit_stamp_validation_msg": "Количество штампов за посещение должно быть меньше максимально допустимого количества штампов за день.",
    "lp_per_max_stamp_validation_msg": "Максимально допустимое количество штампов в день должно быть больше, чем количество штампов за посещение.",
    "lp_valid_value_msg": "Введите допустимое значение",
    "lp_required_field_msg": "Это поле обязательно к заполнению",
    "lp_stamp_required_msg": "Требуется штамп.",
    "lp_valid_stamp_msg": "Введите допустимую стоимость марки",
    "lp_reward_name_required_msg": "Необходимо указать название награды",
    "lp_unique_stamp_msg": "Стоимость марки должна быть уникальной.",
    "lp_benefits": "Программа лояльности увеличивает повторные посещения и доход, предоставляя при этом информацию о клиентах для персонализированного маркетинга.",
    "lp_how_customer_txt": "Вот как ваши клиенты",
    "lp_punch_card_txt": "перфокарта лояльности",
    "lp_look_txt": "посмотрю! 😍",
    "lp_desc": "За каждое посещение клиенты будут получать марки, а когда их накопится достаточно, они смогут получить специальные вознаграждения.",
    "lp_edit": "Редактировать",
    "lp_looks_good_txt": "Выглядит хорошо",
    "lp_customer_view_instruction": "Это то, что увидит ваш клиент. Загрузите свой логотип и установите цветовую тему",
    "lp_customer_earn_stamps": "Позвольте клиентам зарабатывать штампы за каждое посещение и управлять своими вознаграждениями",
    "lp_stamp_rewards_setup": "Установите награды за получение штампов, разрешите клиентам получать награды за штампы",
    "lp_loyalty_program_setup": "Дайте название вашей программе лояльности и укажите свое местоположение, чтобы эта программа была доступна",
    "lp_prev_step1": "Покажите QR-код, чтобы заработать баллы и получить награды.",
    "lp_visit_1_time_txt": "Посетите 1 раз и получите",
    "lp_get_free_txt": "Получить бесплатно",
    "lp_visit_allow_txt": "Вист и разблокировка",
    "lp_rewards": "Награды",
    "repsT": "Получить больше отзывов",
    "offSup": "Офисные принадлежности",
    "repsNxtV": "В ваш следующий визит",
    "addRepT": "Добавить Получить больше отзывов",
    "repEntrCpnTxt": "Введите текст купона",
    "repEntrCpnTxtBtn": "Введите текст кнопки купона",
    "repMngNxtV": "в ваш следующий визит?",
    "repMngWldL": "Хотите получить",
    "discount": "Скидка",
    "repDisTyp": "Тип скидки",
    "repNameT": "Назовите свою репутацию",
    "crtRepT": "Создать репутацию",
    "feedBackT": "Обратная связь",
    "confLocT": "Подтвердите местоположение",
    "businLocT": "Расположение бизнеса",
    "addInfoT": "Дополнительная информация",
    "repNReqT": "Получить больше отзывов имя обязательно",
    "validRepNT": "Введите действительное имя репутации",
    "groupT": "Группа",
    "groupLDescTitleT": "Автоматически помещать контакты в группу",
    "groupLDescT": "Все клиенты, заполнившие форму, будут добавлены в выбранную группу.",
    "que2": "Была ли еда вкусной?",
    "que3": "Было ли наше обслуживание дружелюбным?",
    "que4": "Было ли обслуживание быстрым?",
    "addAdditionalT": "Хотите ли вы добавить дополнительную информацию?",
    "phNoMandT": "Номер телефона обязателен!",
    "qrGenT": "QR-код сгенерирован",
    "repFDT": "Ваш вклад поможет нашей команде лучше обслуживать вас!",
    "letdiT": "Давай сделаем это",
    "tak15secT": "Занимает 15 секунд.",
    "defQue": "Хотите ли вы время от времени получать выгодные предложения и акции?",
    "defNQue": "Хотите ли вы, чтобы кто-то вам ответил?",
    "rateOnGoogleDesc": "Не могли бы вы оценить нас в Google?",
    "mayBeNT": "Возможно, в следующий раз",
    "yesSureT": "Да, конечно",
    "valid_name": "Введите действительное имя",
    "nameReq": "Имя обязательно",
    "backToMainT": "Вернуться на главную страницу",
    "attachCopT": "Хотите ли вы поощрить своих клиентов?",
    "thankYouT": "Спасибо",
    "lftyT": "Мы с нетерпением ждем вашего следующего визита!",
    "repPreT": "Предварительный просмотр репутации",
    "offer_exclusive_discounts": "Предлагайте эксклюзивные скидки и акции, чтобы привлечь ваших клиентов.",
    "couponT": "Купон",
    "locStepDesc": "Подтвердите местоположение вашего бизнеса",
    "createRepStepDesc": "Создайте пользовательскую форму репутации по группам",
    "feedbackStepDesc": "Набор вопросов для обратной связи с клиентами",
    "addInfoStepDesc": "Поля клиентов и настройки стимулов",
    "FedbkkWDT": "Обратная связь с данными",
    "repAnlytT": "Аналитика репутации",
    "todayT": "Сегодня",
    "rep_last_7_days_t": "Последние 7 дней",
    "last30DaysT": "Последние 30 дней",
    "lastMT": "Прошлый месяц",
    "custRangeT": "Пользовательский диапазон",
    "queWiseAnltT": "Аналитика мудрых вопросов",
    "atL1QueErrT": "Пожалуйста, выберите хотя бы один вопрос",
    "deleteRepTitle": "Эта репутация будет удалена навсегда.",
    "incvCustT": "Да, поощрить моего клиента",
    "collAddInfoT": "Да, я хотел бы собрать дополнительную информацию",
    "totRewT": "Полный обзор",
    "totPosRewT": "Полный положительный отзыв",
    "negFeedT": "Отрицательный отзыв",
    "posFeedT": "Положительный отзыв",
    "fineT": "Мелкий шрифт",
    "enterFineT": "Введите текст мелким шрифтом",
    "setThemeT": "Установить цвет темы",
    "que1T": "Вопрос 1",
    "que2T": "Вопрос 2",
    "que3T": "Вопрос 3",
    "que4T": "Вопрос 4",
    "entMailT": "Введите свой адрес электронной почты",
    "reputationQRDefTxt": "Отсканируйте QR-код и поделитесь с нами ценным отзывом о вашем опыте.",
    "selUser": "Выберите пользователя",
    "userT": "Пользователь",
    "subUReq": "Пожалуйста, выберите пользователей",
    "updateLoc": "Обновить местоположение",
    "leadGenT": "Генерация лидов",
    "displayT": "Отображать",
    "ratingT": "Рейтинг",
    "rep_dashboard_negative_feedback": "Отрицательный отзыв 0%",
    "rep_dashboard_position_feedback": "Положительный отзыв 0%",
    "rep_dashboard_total_Feedback_txt": "Общая обратная связь",
    "rep_dashboard_anony_Feedback": "Анонимный отзыв",
    "rep_dashboard_Feeedback_Data_txt": "Обратная связь с данными",
    "rep_dashboard_review_txt": "Получить больше отзывов",
    "rep_dashboard_total_Review_txt": "Полный обзор",
    "rep_dashboard_total_positive_Review_txt": "Полный положительный отзыв",
    "rep_dashboard_negative_feedback_txt": "Отрицательный отзыв",
    "rep_dashboard_connection_txt": "Связь",
    "rep_dashboard_question_wise_analytics_txt": "Аналитика мудрых вопросов",
    "rep_dashboard_date_label": "Выберите дату",
    "rep_dashboard_custom_range_txt": "Пользовательский диапазон",
    "rep_tlt_min": "РЕП",
    "emojis": "Эмодзи",
    "media_text": "Добавить медиа",
    "custom_field_text": "Вставить пользовательские поля",
    "shortlink_text": "Добавить короткую ссылку",
    "use_template_text": "Использовать шаблон",
    "banner_must_have": "Обязательно иметь",
    "res_seamless": "Бесшовный",
    "res_table_reservation": "Резервирование столика",
    "res_system": "Система",
    "res_text": "Бронирование",
    "md_easily": "Легко",
    "md_manage_customers": "Управление клиентами",
    "md_visits_interactions": "Визиты и взаимодействия",
    "md_desc": "Сделайте прием пищи проще для ваших клиентов с помощью бронирования столиков в реальном времени. Управляйте бронированием без усилий, сокращайте время ожидания и обеспечьте комфортный опыт как для персонала, так и для гостей. Поддерживайте полную забронированность вашего ресторана, сохраняя при этом первоклассное обслуживание и удовлетворение.",
    "engage_convert": "Привлекайте и конвертируйте SMS",
    "coupon_campaign": "и купон кампании",
    "sms_read_rate": "СМС-сообщения читаются в 98% случаев",
    "real_time_promo": "Предоставляйте рекламные акции в режиме реального времени для немедленного принятия мер",
    "affordable_reach": "Доступный способ привлечения клиентов с надежной окупаемостью инвестиций",
    "stay_in_touch": "Оставайтесь на связи со своими клиентами в любое время",
    "bc_engage_customers": "Привлекайте клиентов, конвертируйте SMS",
    "bc_coupon_campaign": "и купон кампании",
    "bc_sms_read_rate": "СМС-сообщения читаются в 98% случаев",
    "bc_real_time_promo": "Предоставляйте рекламные акции в режиме реального времени для немедленного принятия мер",
    "bc_affordable_reach": "Доступный способ привлечения клиентов с надежной окупаемостью инвестиций",
    "bc_stay_in_touch": "Оставайтесь на связи со своими клиентами в любое время",
    "ar_automate_engagement": "Автоматизируйте взаимодействие с клиентами",
    "ar_with": "с",
    "ar_with_auto_responders": "Автоответчики",
    "ar_personalized_messages": "Автоматически отправляйте персонализированные сообщения на дни рождения, приветствуйте новых клиентов и возобновляйте связь с прошлыми клиентами. Экономьте время, сохраняя при этом каждое взаимодействие значимым и своевременным.",
    "sq_custom_forms_tools": "Пользовательские формы и инструменты — упрощение",
    "sq_custom_forms_ci": "Взаимодействие с клиентами",
    "sq_smart_qr_desc": "Создавайте пользовательские формы, собирайте электронные подписи и используйте интеллектуальные QR-коды для оптимизации бизнес-процессов. Настройте каждый инструмент для удовлетворения потребностей ваших клиентов и улучшения пользовательского опыта.",
    "gnc_get_new_cus": "Привлекайте новых клиентов",
    "gnc_get_customers": "из социальных сетей — быстро и просто!",
    "gnc_social_media_power": "Используйте силу социальных платформ для привлечения новых клиентов и стимулирования продаж напрямую в вашем бизнесе. Перенаправляйте заказы на доставку сторонних поставщиков прямо в ваш магазин или на платформу онлайн-заказов для максимального контроля и дохода.",
    "wifi_connect_guests": "Свяжите гостей с",
    "wifi_smart": "Умный Wi-Fi",
    "wifi_connect_grow": "И расширяйте свои знания",
    "wifi_smart_wifi_desc": "Предоставьте своим клиентам удобное подключение к Wi-Fi с помощью фирменного экрана-заставки, одновременно собирая ценные данные о клиентах для повышения эффективности ваших маркетинговых усилий и вовлеченности.",
    "wifi_create_splash_page": "Создать гостевую страницу Wi-Fi",
    "instant_menu_create": "Создайте свой",
    "instant_menu_text": "Мгновенное меню",
    "instant_menu_wm": "в течение нескольких минут",
    "instant_menu_desc": "Улучшает качество обслуживания клиентов, позволяя получать обновления в режиме реального времени, легко настраивать и повышать вовлеченность, что упрощает демонстрацию ваших предложений и поддержание актуальности контента.",
    "instant_menu_create_digital_menu": "Создать цифровое меню",
    "loyalty_program_rewards": "Получайте награды",
    "loyalty_program_on_visits": "при каждом посещении",
    "loyalty_program_create": "Создать программу лояльности",
    "reputation_create": "Создать репутацию",
    "reputation_boost": "Повысьте свой",
    "reputation_text": "Репутация",
    "reputation_reviews": "с большим количеством отзывов!",
    "reputation_desc": "Собирайте ценные отзывы от ваших клиентов, чтобы улучшить ваш сервис и репутацию. Поделитесь своим опытом и помогите нам расти — ваш отзыв имеет значение!",
    "dashboard": "Панель инструментов",
    "blast_campaign": "Взрывная кампания",
    "blast_campaigns": "Взрывные кампании",
    "blast_campaign_sub_title": "Запустите рекламные кампании, чтобы охватить всех ваших клиентов за несколько кликов",
    "auto_responders": "Автоответчики",
    "auto_responder": "Автоответчик",
    "auto_responder_sub_title": "Простые в использовании акции на основе событий для ваших клиентов",
    "smart_qr_form": "Умный QR-код \/ Форма",
    "get_new_customer": "Получить нового клиента",
    "marketing": "Маркетинг",
    "clients": "Клиенты",
    "group_data": "Группы данных",
    "clients_data": "Данные клиентов",
    "account": "Счет",
    "user_acc_info": "Информация об учетной записи пользователя",
    "current_plan": "Текущий план",
    "payments": "Платежи",
    "location": "Расположение",
    "location_list": "Список местоположений",
    "users": "Пользователи",
    "users_sub_title": "Список всех пользователей и их данные",
    "online_profile": "Онлайн-профиль",
    "change_password": "Изменить пароль",
    "menu": "Меню",
    "reseller_dashboard": "Панель управления реселлера",
    "companies": "Компании",
    "plans_bought": "Планы куплены",
    "agreement_t_c": "Соглашение Условия и положения",
    "wifi_dashboard": "Панель управления Wi-Fi",
    "hot_spot": "Точка доступа",
    "splash_pages": "Всплеск страницы",
    "get_more_reviews": "Получить больше отзывов",
    "office_supplies": "Офисные принадлежности",
    "reservation": "Бронирование",
    "floor_table": "Пол и стол",
    "guest_smart_wifi": "Гостевой Smart WiFi",
    "digital_signage": "Цифровые вывески",
    "schedule_down": "Расписаниевниз",
    "business_settings": "Настройки бизнеса",
    "sub_user_login": "Вход для субпользователей",
    "help_center": "Центр помощи",
    "switch_to_hub": "Переключиться на хаб",
    "meeting_records": "Отчеты о встречах",
    "logout": "Выйти",
    "no_access_text": "У вас сейчас нет доступа. Обратитесь к администратору.",
    "menu_item": "ПУНКТ МЕНЮ",
    "menu_capital": "МЕНЮ",
    "good_day": "Добрый день",
    "no_permission_text": "У вас нет разрешения на выполнение этого действия!!",
    "enter_valid_pin": "Введите действительный пин-код",
    "add_reservation": "Добавить бронирование",
    "edit_reservation": "Редактировать бронирование",
    "per_req": "Необходимое количество человек",
    "req_block_time": "Требуется время блока",
    "pending_status": "В ожидании",
    "booked_status": "Забронировано",
    "completed_status": "Завершенный",
    "cancelled_status": "Отменено",
    "reservation_slot": "Слот для бронирования",
    "block_time": "Время блока",
    "slot_time": "Время резервирования слота:  ",
    "template_name_req": "Имя шаблона обязательно",
    "template_type_req": "Требуется тип шаблона",
    "sms_template_req": "Требуется шаблон SMS",
    "group_req": "Группа обязательна",
    "valid_date_time": "Введите правильную дату и время",
    "valid_group_name": "Введите допустимое имя группы",
    "reservation_mark_completed": "Это бронирование будет отмечено как выполненное.",
    "customer_name": "Имя клиента",
    "date_time": "Дата Время",
    "table_no": "Таблица №",
    "floor": "Пол",
    "customer_name_placeholder": "имя_клиента",
    "date_time_placeholder": "дата_время",
    "table_no_placeholder": "table_no",
    "floor_placeholder": "пол",
    "select_floor": "Выберите этаж",
    "select_table": "Выбрать таблицу",
    "customer_name_macro": "%% имя_клиента %%",
    "date_time_macro": "%% дата_время %%",
    "table_no_macro": "%% table_no %%",
    "floor_macro": "%% пол %%",
    "template_name": "Название шаблона",
    "login_with_4_digit_pin": "Войдите в систему, используя 4-значный PIN-код",
    "login_now_to_access_latest_insights": "Войдите сейчас, чтобы получить доступ к последним новостям",
    "mkt_BS_Title": "Взрывная СМС",
    "step_1": "Настроить сообщение",
    "step_2": "Выберите аудиторию",
    "step_3": "Расписание сообщений",
    "step_4": "Отправить сообщение",
    "step_5": "Управлять сообщением",
    "bs_tab_title": "Резюме кампании Blast",
    "default_message": "Привет %% firstname %%, Специальная скидка на бранч! Пообедайте с нами сегодня и получите эксклюзивную скидку 10% на ваш счет!",
    "bs_Title": "Выберите тип кампании",
    "sms_title": "СМС\/ММС",
    "reach_out_to_customer": "Обращайтесь к клиентам с помощью текстовых и мультимедийных сообщений.",
    "wp_text": "WhatsApp",
    "connect_with_wp": "Общайтесь со своими клиентами с помощью WhatsApp 💬",
    "send_discount_offers": "Отправляйте предложения о скидках по SMS 📲",
    "send_coupons_via_sms": "Отправляйте купоны по SMS своим клиентам 🎟️",
    "env_Cap": "Предлагайте эксклюзивные скидки и акции, чтобы привлечь ваших клиентов",
    "cmt_Caption": "Свяжитесь с клиентами с помощью текстовых и мультимедийных сообщений",
    "wp_caption": "Свяжитесь с клиентами через WhatsApp для удобного и прямого общения",
    "msg_Prev_Title": "Предварительный просмотр сообщения",
    "cou_Prev_Title": "Предварительный просмотр купона",
    "blast_SMS": "SMS\/MMS-кампания",
    "bs_P_Msg": "В очереди",
    "bs_S_Msg_C": "Неуспешный",
    "bs_F_Msg_Count": "Доставленный",
    "previous": "Предыдущий",
    "next_ST": "Следующий",
    "next_S": "Следующий шаг",
    "req_Temp_Name_Title": "Имя шаблона обязательно",
    "req_Template_Tx_Title": "Текст шаблона обязателен",
    "add_Temp_Title": "Добавить шаблон",
    "link_Desc": "Добавьте ссылку по вашему выбору, чтобы клиенты могли бронировать у вас столик через Facebook, Google или ваш собственный сайт.",
    "audience_Title": "Аудитория",
    "client_G1_Title": "Новые клиенты",
    "client_G2_Title": "Недавние клиенты",
    "client_G3_Title": "Постоянные клиенты",
    "client_G4_Title": "Просроченные клиенты",
    "client_G5_Title": "Клиенты по дате подключения",
    "on_Which_Day": "В какой день",
    "on_Day": "В день",
    "immediate_Desc": "Ваше сообщение будет отправлено немедленно. Нажмите «Далее», чтобы начать.",
    "never_Desc": "Эта кампания будет действовать вечно, пока вы не завершите ее вручную.",
    "end_Desc": "Акция завершится в указанную вами дату.",
    "success_Message": "Кампания успешно сохранена!",
    "send_Success_Message": "Кампания успешно отправлена!",
    "msg_Name_Field_Title": "Название кампании",
    "valid_campaign_title": "Введите допустимое название кампании",
    "msg_Type_Title": "Тип сообщения",
    "sms_Desc": "1 кредит за SMS",
    "mms_Desc": "3 кредита за MMS",
    "client_GTO_1": "День",
    "client_GTO_2": "Часы",
    "minutes": "Минуты",
    "client_GTO_4": "Неделя",
    "time_Option_1": "День",
    "time_Option_2": "Часы",
    "c_Grp_Day_1": "90 дней",
    "c_Grp_Day_2": "60 дней",
    "c_Grp_Day_3": "30 дней",
    "first": "Первый",
    "second_T": "Второй",
    "third_T": "Третий",
    "fourth_T": "Четвертый",
    "fifth_T": "Пятый",
    "delete_BS_T": "Эта кампания будет удалена навсегда.",
    "delete_MBS_T": "Эти кампании будут удалены навсегда.",
    "cFT_1": "Телефон",
    "cFT_2": "Контактное имя",
    "cFT_3": "Контактная Фамилия",
    "cFT_4": "Контактный адрес электронной почты",
    "cFT_5": "Примечание",
    "cFT_6": "День рождения",
    "cFT_7": "Название компании",
    "cF_Meta_Tag_1": "%% телефон %%",
    "cF_Meta_Tag_2": "%% имя %%",
    "cF_Meta_Tag_3": "%% фамилия %%",
    "cF_Meta_Tag_4": "%% электронная почта %%",
    "cF_Meta_Tag_5": "%% примечание %%",
    "cF_Meta_Tag_6": "%% день рождения %%",
    "cF_Meta_Tag_7": "%% Название компании %%",
    "cF_Name_1": "телефон",
    "cF_Name_2": "имя",
    "cF_Name_3": "фамилия",
    "cF_Name_4": "электронная почта",
    "cF_Name_5": "примечание",
    "cF_Name_6": "день рождения",
    "cF_Name_7": "Название компании",
    "all_clients": "ВсеКлиенты",
    "aud_Type_2": "ГруппыКлиентов",
    "aud_Type_3": "Группы клиентов",
    "aud_Type_T1": "Все клиенты",
    "aud_Type_T2": "Группы клиентов",
    "aud_Type_T3": "Группы клиентов",
    "new_clients": "Новые клиенты",
    "client_G2": "Недавние клиенты",
    "client_G3": "Постоянные клиенты",
    "client_G4": "Просроченные клиенты",
    "client_G5": "Клиенты по дате подключения",
    "never_text": "Никогда",
    "ending_on": "Окончание на",
    "send_T2": "Запланировано",
    "send_Type_3": "Повторяющийся",
    "daily": "Ежедневно",
    "weekly_T": "Еженедельно",
    "monthly_T": "Ежемесячно",
    "yearly_T": "Ежегодно",
    "each": "Каждый",
    "on_the": "На",
    "monday": "Понедельник",
    "exclusive_offer": "Эксклюзивное предложение для вас",
    "redeem_now": "Использовать сейчас",
    "redeem_with_cashier": "Пожалуйста, воспользуйтесь купоном через кассу.",
    "lg_Day_2": "Вторник",
    "lg_Day_3": "Среда",
    "lg_Day_4": "Четверг",
    "lg_Day_5": "Пятница",
    "lg_Day_6": "Суббота",
    "lg_Day_7": "Воскресенье",
    "msg_Pre_T": "Домино",
    "cA_Edit_T1": "Все",
    "cA_Edit_T2": "Включено",
    "cA_Edit_T3": "Исключено",
    "SMS": "SMS",
    "MMS": "ММС",
    "usd_T": "доллар США",
    "cad_T": "САПР",
    "msg": "Сообщение",
    "which_Day": "Какие дни",
    "end_Date_Tx": "Дата окончания",
    "sDate_Err": "Дата начала должна быть больше или равна сегодняшнему дню.",
    "eDate_Err": "Дата окончания должна быть позже даты начала.",
    "start_Date_Req": "Требуется дата начала",
    "end_Date_Req": "Дата окончания обязательна",
    "time_req": "Требуется время",
    "client_GT1": "Клиенты подписались за последний",
    "client_GT2": "Клиенты с подключением в последний",
    "client_GT3": "Клиенты с по крайней мере",
    "client_GT4": "с последним",
    "client_GT5": "кто не вернулся в последний",
    "dummy_Phone_No": "+91987-654-3210",
    "test_T": "Тест",
    "dummy_Birth_Date": "01-01-2001",
    "image_Req": "Требуется медиа или URL",
    "time_Title": "Время",
    "start_date": "Дата начала",
    "end_date": "Дата окончания",
    "auto_Send_T": "Автоматически отправлять клиентам",
    "add_Media": "Добавить медиа",
    "in_Title": "В",
    "c_Temp_T": "Шаблон кампании",
    "temp_NT": "Название шаблона",
    "type_T": "Тип",
    "select_template_type": "Выберите тип шаблона",
    "sel_Temp_T": "Выберите тип шаблона",
    "sms_temp_t": "Шаблон СМС",
    "temp_T": "Текст шаблона",
    "default_Msg": "Привет %% firstname %%, Специальная скидка на бранч! Пообедайте с нами сегодня и получите эксклюзивную скидку 10% на ваш счет!",
    "refill_T": "Пополнение",
    "avl_Credit": "Доступные кредиты",
    "req_Credit": "Всего требуется кредитов",
    "rem_Credit": "Оставшийся кредит",
    "refill_Credit": "Кредиты для пополнения",
    "clients_Req": "Выберите хотя бы одного клиента",
    "subclient_Req": "Избранные клиенты не являются подписчиками.",
    "refill_Desc": "Пожалуйста, пополните требуемые кредиты",
    "url_Regex": "^((http|https):\/\/).....+$",
    "sd_Invalid": "Дата начала недействительна",
    "ed_Invalid": "Дата окончания недействительна",
    "img_Url_Invalid": "Неверный URL-адрес изображения",
    "time_Invalid": "Время недействительно",
    "time_And_Date_Invalid": "Введите правильную дату и время",
    "time_Invalid_Bfr": "Выберите время как минимум на 5 минут позже текущего времени.",
    "sod_Req": "Требуется отправить в тот же день",
    "add_Card": "Добавить новую карту",
    "sm_D_Rep_Title": "Отчет о доставке SMS\/MMS",
    "send_Now_T": "Отправить сейчас",
    "schd_Now_T": "Расписание СМС",
    "test_SMS_T": "Отправить тестовое СМС",
    "save_AD_T": "Сохранить как черновик",
    "back_TE_T": "Вернуться к редактированию",
    "reset_tex": "Перезагрузить",
    "update_tex": "Обновлять",
    "dum_msg1": "Чтобы отобразить имя клиента, используйте %% customer_name %% в шаблоне",
    "dum_msg2": "Для отображения времени бронирования используйте %% date_time %% в шаблоне",
    "dum_msg3": "Для отображения номера таблицы используйте %% table_no %% в шаблоне",
    "embedded_link": "Встроенная ссылка",
    "img_title": "Изображение",
    "img_input_text": "Текст:",
    "img_join_text": " присоединиться к нашему списку",
    "img_copy_text": "Копировать",
    "img_not_found": "Сгенерированное изображение не найдено!!",
    "or_text": "ИЛИ",
    "web_signup_image_content": "Отправляя эту форму и регистрируясь с помощью текста, вы соглашаетесь получать маркетинговые текстовые сообщения (например, промокоды и напоминания). Могут применяться тарифы на сообщения и данные. Частота сообщений варьируется. Вы можете отписаться в любое время, ответив STOP",
    "img_download_success": "Изображение успешно загружено",
    "embedded_link_copied": "Встроенная ссылка успешно скопирована",
    "media_url_required": "Требуется медиа или URL",
    "invalid_image_url": "Неверный URL-адрес изображения",
    "invalid_file": "Файл недействителен",
    "image_error_1mb": "Пожалуйста, вставьте изображение размером менее 1 МБ",
    "image_error_2_5mb": "Пожалуйста, вставьте изображение размером менее 2,5 МБ",
    "image_error_3mb": "Пожалуйста, вставьте изображение размером менее 3 МБ",
    "change_title": "Изменять",
    "drop_file_text": "Перетащите файл сюда или нажмите, чтобы загрузить",
    "apply_text": "Применять",
    "search_here": "Поиск здесь",
    "update_status": "Обновить статус",
    "reservation_deleted_permanently": "Это бронирование будет удалено навсегда.",
    "table_text": "Таблицы",
    "add_table_button": "Добавить новую таблицу",
    "add_table_text": "Добавить таблицу",
    "edit_table_text": "Редактировать таблицу",
    "table_delete_text": "Эта таблица будет удалена навсегда.",
    "multiple_table_delete_text": "Эти таблицы будут удалены навсегда.",
    "table_error_name": "Введите название таблицы",
    "table_error_invalid_name": "Введите правильное имя таблицы",
    "table_error_floor": "Пожалуйста, выберите этаж",
    "table_error_select": "Пожалуйста, выберите таблицу",
    "table_capacity_text": "Вместимость стола",
    "capacity_text": "Емкость",
    "table_occasion_text": "Найдите свой стол для любого случая",
    "table_name_text": "Название таблицы",
    "table_capacity_error": "Требуется вместимость стола",
    "cancel_text": "Отмена",
    "submit_text": "Представлять на рассмотрение",
    "select_valid_date": "Выберите действительную дату",
    "select_valid_time": "Выберите действительное время",
    "valid_contact_number": "Введите действительный контактный номер",
    "date_req": "Дата обязательна",
    "date_error_later_than_today": "Дата должна быть позже или равна сегодняшней дате.",
    "time_error_later_than_5_minutes": "Выберите время как минимум на 5 минут позже текущего времени",
    "number_person_req": "Требуется количество человек",
    "contact_no_req": "Контактный номер обязателен",
    "contact_no": "Контактный номер",
    "select_time": "Выберите время",
    "for_how_many_person": "На сколько человек?",
    "f_name": "Имя",
    "l_name": "Фамилия",
    "business_name": "Название компании",
    "business_no": "Номер компании",
    "no_for_sign_in": "Этот номер используется для входа",
    "business_email": "Деловая электронная почта",
    "notes_tex": "Примечания",
    "floor_deleted_permanently": "Этот этаж будет удален навсегда.",
    "add_floor": "Добавить этаж",
    "edit_floor": "Изменить этаж",
    "name_text": "Имя",
    "mobile_no": "Номер мобильного телефона",
    "person_text": "Человек",
    "date_and_time": "Дата и время",
    "actions_text": "Действия",
    "extra_text": "Дополнительный",
    "floor_name_req": "Требуется название этажа",
    "floor_name": "Название этажа",
    "status_text": "Статус",
    "category_status_req": "Категория Статус обязателен",
    "table_deleted_permanently": "Эта таблица будет удалена навсегда.",
    "tables_deleted_permanently": "Эти таблицы будут удалены навсегда.",
    "back_to_home": "Вернуться домой",
    "link_copied_text": "Ссылка скопирована в буфер обмена",
    "cust_dash_head_to_title": "к",
    "cust_dash_head_location_title": "Расположение",
    "select_location_title": "Выберите местоположение",
    "all_locations_label": "все_местоположения",
    "rep_customer_feedback_analytics": "😊 Отслеживайте отзывы клиентов с помощью аналитики в реальном времени",
    "rep_customer_txt": "Клиенты",
    "rep_delete_title": "Эта репутация будет удалена навсегда.",
    "rep_qr_def_txt": "Отсканируйте QR-код и поделитесь с нами ценным отзывом о вашем опыте.",
    "delete_title": "Удалить",
    "user_list_title": "Список клиентов",
    "os_nfc_txt": "NFC-карты",
    "os_sign_holder_txt": "Подставка для держателя знака",
    "os_store_Sign_holder_txt": "Держатели вывесок для магазинов",
    "StaT": "Статистика",
    "AllgT": "Все группы",
    "FavT": "Любимый",
    "MostActT": "Самый активный",
    "grT": "Название группы",
    "keywT": "Ключевые слова",
    "exSubT": "Сообщение для существующего подписчика",
    "ArchT": "Архивировано",
    "gNotiSms": "Уведомляйте меня о каждом новом контакте по SMS",
    "gNotiEmail": "Уведомляйте меня о каждом новом контакте по электронной почте",
    "reqGName": "Требуется название группы",
    "validGN": "Введите допустимое имя группы",
    "valid_phone_no": "Введите действительный номер телефона",
    "phone_no_req": "Номер телефона обязателен",
    "required_message_text": "Сообщение обязательно",
    "required_discount_text": "Требуется скидка",
    "less_than_discount": "Сумма скидки должна быть меньше 5000",
    "discount_percentage_invalid": "Процент скидки должен быть меньше или равен 100",
    "discount_type_req": "Требуется тип скидки",
    "discount_text_req": "Текст купона обязателен",
    "reqContactNo": "Контактный номер обязателен",
    "reqMsgNT": "Название кампании обязательно",
    "reqLinkT": "Ссылка обязательна",
    "delMGT": "Эти группы будут удалены навсегда.",
    "defMemMsg": "Введите ваше сообщение здесь",
    "add_cust_to_grp_title": "Добавить клиента в группу",
    "group_title": "Группы",
    "group_create_title": "Создать группу",
    "group_name_txt": "Название группы",
    "group_table_keyword_title": "Ключевое слово",
    "actions_title": "Действия",
    "clients_title": "Клиенты",
    "send_title": "Отправлять",
    "qr_title": "QR",
    "delete_group_txt": "Эта группа будет удалена навсегда.",
    "delete_groups_txt": "Эти группы будут удалены навсегда.",
    "delete_client_title": "Этот клиент будет удален навсегда.",
    "delete_clients_title": "Эти клиенты будут удалены навсегда.",
    "delete_multiple_title": "Множественное удаление",
    "wel_sms_mms": "Отправить приветственное SMS\/MMS?",
    "key_words_title": "Ключевые слова",
    "srch_plch_txt": "Поиск здесь",
    "req_location_id_title": "Местоположение обязательно",
    "create_text": "Создавать",
    "view_text": "Вид",
    "immediately": "Немедленно",
    "business_name_req": "Название компании обязательно",
    "business_no_req": "Требуется номер компании",
    "valid_business_name": "Введите действительное название компании",
    "valid_business_no": "Введите действительный номер компании",
    "address_req": "Адрес обязателен",
    "valid_address": "Пожалуйста, выберите действительный адрес",
    "time_zone_req": "Требуется часовой пояс",
    "image_req": "Пожалуйста, вставьте изображение размером менее 2,5 МБ",
    "valid_file": "Файл недействителен.",
    "logo": "Логотип",
    "time_zone": "Часовой пояс",
    "save": "Сохранять",
    "account_type_req": "Требуется тип учетной записи",
    "gst_no_req": "Требуется номер плательщика НДС",
    "gst_no_valid": "Введите действительный номер плательщика НДС",
    "set_up_payments": "Настройте платежи",
    "billing_details": "Введите ваши платежные данные",
    "billing_details_desc": "Ваши платежные реквизиты будут отображены в ежемесячном счете от menuonline.",
    "account_type": "Тип учетной записи",
    "select_account_type": "Выберите тип учетной записи",
    "gst_no": "Номер НДС",
    "transaction_details": "Подробности транзакции",
    "payment_method": "Способ оплаты",
    "billing_period": "Расчетный период",
    "paid_by": "Оплачено",
    "download": "Скачать",
    "pay_now": "Оплатить сейчас",
    "pull_down_refresh": "Потяните вниз, чтобы обновить",
    "release_refresh": "Отпустите, чтобы обновить",
    "billing_details_text": "Реквизиты для выставления счетов",
    "payment_methods": "Способы оплаты",
    "invoice": "Счет",
    "invoice_to": "Счет-фактура для:",
    "issue_date": "Дата выпуска",
    "due_date": "Срок оплаты",
    "amount_due": "Сумма к оплате",
    "charges": "Обвинения",
    "sub_total_capitalize": "Подытог",
    "hst": "HST",
    "grand_total": "Общий итог",
    "invoice_generated_on": "Счет-фактура создан",
    "contact_us": "Связаться с нами",
    "invoice_issue": "Если вы просмотрели свой счет и у вас все еще остались вопросы",
    "call": "Вызов",
    "send_sms": "Отправить СМС",
    "payment_success": "Платеж прошел успешно",
    "edit_credit_card": "Редактировать кредитную карту",
    "add_credit_card": "Добавить кредитную карту",
    "modify_card_info": "Измените информацию о вашей карте",
    "enter_card_info": "Введите данные вашей карты",
    "account_no_req": "Требуется номер счета",
    "card_name_req": "Имя на карте обязательно",
    "expiry_date_req": "Требуется указать дату истечения срока действия.",
    "valid_expiry_date": "Введите действительную дату истечения срока действия",
    "valid_cvv": "Введите действительный cvv",
    "cvv_req": "CVV-код обязателен",
    "card_no": "Номер карты",
    "name_of_card": "Имя на карте",
    "expiry": "Истечение срока действия",
    "mm_text": "ММ\/ГГ",
    "cvv": "CVV",
    "set_as_default": "Установить как значение по умолчанию",
    "add_new_card": "Добавить новую карту",
    "all_credit_card": "Все ваши кредитные карты",
    "fastest_checkout": "Самая быстрая оплата с помощью безопасной и надежной платформы, которой доверяет большая группа предприятий",
    "coupon_button_req": "Текст кнопки купона обязателен",
    "max19_characters_allowed": "Разрешено максимум 19 символов.",
    "fine_print_text_req": "Требуется мелкий шрифт.",
    "clients_req": "Выберите хотя бы одного клиента",
    "client_groups": "ГруппыКлиентов",
    "day_text": "День",
    "valid_days": "Значение дня слишком велико, введите допустимые дни",
    "hours_text": "Часы",
    "enter_valid_hours": "Значение часов слишком велико, введите допустимое количество часов",
    "enter_valid_min": "Значение минут слишком велико, введите допустимое количество минут",
    "clients_with_connection_required": "Клиенты с подключением в последнем требуются",
    "connection_required": "Требуется подключение",
    "connection_value_too_large": "Значение подключений слишком велико, введите допустимое подключение",
    "minutes_required": "Требуется минут",
    "clients_with_at_least_connection_required": "Требуются клиенты с как минимум подключением",
    "last_minutes_req": "С последними минутами требуется",
    "clients_not_returned_required": "Кто не вернулся в последние минуты, требуется",
    "not_return_in_last_minutes_invalid": "Невозвращение в последние минуты должно быть меньше последних минут.",
    "customerGroups": "Группы клиентов",
    "select_customer_group": "Пожалуйста, выберите группу клиентов",
    "location_required": "Местоположение обязательно",
    "start_date_required": "Требуется дата начала",
    "start_date_invalid": "Дата начала недействительна",
    "start_date_invalid_error": "Дата начала должна быть больше или равна сегодняшнему дню.",
    "recurring": "Повторяющийся",
    "end_date_required": "Дата окончания обязательна",
    "end_date_invalid": "Дата окончания недействительна",
    "end_date_invalid_error": "Дата окончания должна быть позже даты начала.",
    "time_invalid": "Время недействительно",
    "monthly_text": "Ежемесячно",
    "send_day_req": "Требуется отправить в тот же день",
    "loyal_clients": "Постоянные клиенты",
    "recent_clients": "Недавние клиенты",
    "lapsed_clients": "Просроченные клиенты",
    "clients_connection_date": "Клиенты по дате подключения",
    "scheduled_text": "Запланировано",
    "weekly": "Еженедельно",
    "selected_clients_not_subscriber": "Отдельные клиенты не являются подписчиками.",
    "message_preview_title": "Предварительный просмотр сообщения",
    "coupon_preview_title": "Предварительный просмотр купона",
    "form_text": "Форма",
    "preview_text": "Предварительный просмотр",
    "next_text": "Следующий",
    "send_test_SMS": "Отправить тестовое СМС",
    "save_draft": "Сохранить как черновик",
    "back_to_edit": "Вернуться к редактированию",
    "select_payment_method": "Пожалуйста, выберите способ оплаты",
    "schedule_SMS": "Расписание СМС",
    "send_now": "Отправить сейчас",
    "get_more_ratings": "Получить больше оценок",
    "overview": "Обзор",
    "reset_campaign": "Сброс кампании",
    "permissions": "Разрешения",
    "location_name": "Название местоположения",
    "phone_no": "Номер телефона",
    "location_email_address": "Местоположение Адрес электронной почты",
    "located_business": "Где находится ваш бизнес?",
    "business_logo": "Логотип компании",
    "congratulations": "Поздравления",
    "almost_done": "Вы почти закончили.",
    "publish": "Публиковать",
    "about_your_business": "О вашем бизнесе",
    "add_your_location": "Добавьте свое местоположение",
    "publish_location": "Опубликовать Местоположение",
    "location_name_req": "Требуется указать название местоположения",
    "valid_location_name": "Введите допустимое название местоположения",
    "business_logo_req": "Требуется логотип компании",
    "please_accept_terms": "Пожалуйста, примите условия и положения",
    "add_new_location": "Добавить новое местоположение",
    "edit_location": "Изменить местоположение",
    "add_location": "Добавить местоположение",
    "existing_msg_subscriber_txt": "Сообщение для существующего подписчика",
    "msg_capitalize_txt": "Сообщение",
    "group_noti_sms": "Уведомляйте меня о каждом новом контакте по SMS",
    "group_noti_email": "Уведомляйте меня о каждом новом контакте по электронной почте",
    "group_member_msg": "Похоже, вы уже являетесь частью нашей группы!!",
    "group_mend_msg": "STOP для завершения. HELP для помощи. Могут применяться тарифы на SMS и передачу данных",
    "Disclaimer_title": "Текст отказа от ответственности: ",
    "group_def_msg": "Мы уже онлайн! Ознакомьтесь с нашим меню услуг и запишитесь на следующий прием уже сегодня.",
    "required_msg_txt": "Сообщение обязательно",
    "required_Key_txt": "Ключевые слова обязательны",
    "required_mem_msg": "Требуется сообщение подписчика",
    "client_list_title": "Список клиентов",
    "add_contact_txt": "Добавить контакт",
    "delete_all_clients_txt": "Удалить всех клиентов",
    "delete_all_clients_msg": "Вы уверены, что хотите удалить всех клиентов? Их невозможно восстановить.",
    "delete_all_txt": "Удалить все",
    "timeline_title": "Хронология",
    "unsubscribe_title": "Отписаться",
    "subscribe_title": "Подписаться",
    "unsubscribe_confirm_msg": "Хотите ли вы отметить этого клиента как отписавшегося от рассылки?",
    "subscribe_confirm_msg": "Хотите ли вы отметить этого клиента как подписчика?",
    "no_title": "Нет",
    "yes_title": "Да",
    "client_name_title": "Имя клиента",
    "source_title": "Источник",
    "contact_file_Req": "Требуется файл контактов",
    "req_title": "Необходимый",
    "opt_in_req": "Импортированные контакты должны быть на 100% согласованы.",
    "image_invalid_error": "Файл недействителен.",
    "import_contacts_msg": "Импорт Контакты",
    "csv_upload_title": "Файл контактов CSV для загрузки *",
    "csv_file_desc": "Пожалуйста, загрузите только файл CSV - столбцы файла CSV - Имя, Фамилия, Идентификатор электронной почты, Номер мобильного телефона, Пол, ДР (ММ\/ДД\/ГГГГ) - без пробелов, тире или скобок в номере мобильного телефона.",
    "to_download_title": "Чтобы загрузить демо-файл нажмите здесь",
    "contains_header_title": "Да, этот файл содержит заголовки",
    "opt_in_100_txt": "Да, эти импортированные контакты на 100% являются подписными.",
    "DisT": "Текст отказа от ответственности: ",
    "gMendMsg": "STOP для завершения. HELP для помощи. Могут применяться тарифы на SMS и передачу данных",
    "reqKeyT": "Ключевые слова обязательны",
    "reqMemMsg": "Требуется сообщение подписчика",
    "reqMsgT": "Сообщение обязательно",
    "gMemberMsg": "Похоже, вы уже являетесь частью нашей группы!!",
    "gdefMsg": "Мы уже онлайн! Ознакомьтесь с нашим меню услуг и запишитесь на следующий прием уже сегодня.",
    "next_title": "Следующий",
    "male_title": "Мужской",
    "male_val": "мужской",
    "female_title": "Женский",
    "female_val": "женский",
    "others_txt": "Другие",
    "others_val": "другие",
    "validBirthdate": "Введите действительную дату рождения",
    "valid_phone_no_title": "Введите действительный номер телефона",
    "required_phone_no_title": "Номер телефона обязателен",
    "add_new_client_txt": "Добавить нового клиента",
    "update_client_txt": "Обновление клиента",
    "phone_num_text": "Номер телефона",
    "dob_title": "дата рождения",
    "select_gender_title": "Выберите пол",
    "timelineTitle": "Хронология",
    "confirm_location": "Подтвердите местоположение",
    "feedback_title": "Обратная связь",
    "rep_question_1": "Был ли бизнес чистым?",
    "rep_que_2": "Была ли еда вкусной?",
    "rep_que_3": "Было ли наше обслуживание дружелюбным?",
    "rep_que_4": "Было ли обслуживание быстрым?",
    "business_location_req_title": "Требуется указать местоположение компании",
    "valid_location_err_txt": "Пожалуйста, выберите действительный адрес",
    "rep_management_title": "Получить больше отзывов Управление",
    "rep_about_desc": "Репутация поможет вам повысить рейтинг Google, попросив ваших клиентов оставить отзыв",
    "preview_title": "Предварительный просмотр",
    "rep_preview_txt": "Предварительный просмотр репутации",
    "back_title": "Назад",
    "fine_print_txt": "Пожалуйста, воспользуйтесь купоном через кассу.",
    "redeem_me_title": "Выкупи меня",
    "rep_nxt_visit_txt": "В ваш следующий визит",
    "device_type": "Тип используемого устройства",
    "connection_method": "Метод подключения",
    "peak_days": "Пиковые дни",
    "peak_hours": "Часы пик",
    "guest_by_day": "Гость дня",
    "guest_visit": "Гостевой визит",
    "connection": "Связь",
    "connection_duration": "Продолжительность соединения",
    "guest_visit_1": "1 раз",
    "guest_visit_2": "2 раза",
    "guest_visit_3_5": "3-5 раз",
    "guest_visit_6_10": "6-10 раз",
    "guest_visit_11_25": "11-25 раз",
    "guest_visit_26_100": "26-100 раз",
    "guest_visit_100_plus": "100+ раз",
    "device_android_total": "Всего Android",
    "device_android": "андроид",
    "device_desktop": "Рабочий стол",
    "device_ios": "Иос",
    "device_ios_total": "Всего Ios",
    "device_desktop_total": "Всего рабочего стола",
    "connection_duration_10": "<=10 мин.",
    "connection_duration_20": "11-20 мин.",
    "connection_duration_30": "21-30 мин.",
    "connection_duration_40": "31-45 мин.",
    "connection_duration_60": "46-60 мин.",
    "connection_method_email": "Электронная почта",
    "connection_method_sms": "SMS",
    "connection_method_google": "Google",
    "connection_method_facebook": "Фейсбук",
    "age_category_1": "<18",
    "age_category_2": "18-24",
    "age_category_3": "25-34",
    "age_category_4": "35-44",
    "age_category_5": "45-54",
    "age_category_6": "55+",
    "all_guest_txt": "Все гости",
    "new_Guest_txt": "Новый гость",
    "connections_txt": "Связи",
    "hotspot": "Точка доступа",
    "hotspot_list": "Список точек доступа",
    "add_new_hotspot": "Добавить новую точку доступа",
    "hotspot_information": "Информация о точке доступа",
    "edit_details_button": "Изменить детали",
    "wifi_info_message": "Подключитесь и наслаждайтесь бесплатным Wi-Fi",
    "connection_message": "Отлично, вы подключены к ",
    "connection_message_suffix": " Wi-Fi",
    "wifi": "Wi-Fi",
    "login_to_access": "Войдите, чтобы получить доступ",
    "verification_code": "Проверочный код",
    "verification_code_message": "Пожалуйста, введите проверочный код, отправленный на ",
    "wifi_user_email": "adamo@gmail.com",
    "wifi_label": "Wi-Fi",
    "your_name": "Кстати, как тебя зовут?",
    "your_birthdate": "Можете ли вы сказать нам, какая у вас дата рождения?",
    "request_guest_wifi_name": "Введите имя гостевого Wi-Fi",
    "request_device_key": "Введите ключ устройства",
    "request_maximum_internet_access_length": "Пожалуйста, выберите максимальную продолжительность доступа в Интернет",
    "mac_address": "MAC-адрес",
    "device_port": "Порт устройства",
    "hardware": "Аппаратное обеспечение",
    "current_uptime": "Текущее время безотказной работы",
    "nearby_devices": "Устройства поблизости",
    "firmware": "Прошивка",
    "who_are_you": "Кто ты?",
    "where_to_contact_you": "Как с вами связаться?",
    "your_area_code": "Какой у вас телефонный код?",
    "connected": "Подключен",
    "delete_hotspot_message": "Эта точка доступа будет удалена навсегда.",
    "delete_multiple_hotspots_message": "Эти точки доступа будут удалены навсегда.",
    "speed_error": "Скорость должна быть не менее 0,01",
    "speed_max_error": "Введите значение до 1024 для неограниченной скорости или выберите меньшее значение в поддерживаемом диапазоне.",
    "device_ssid": "SSID устройства",
    "device_ssid_two": "SSID устройства Два",
    "device_ssid_two_wpa": "SSID устройства Два WPA",
    "device_key": "Ключ устройства",
    "select_location": "Выберите местоположение",
    "select_maximum_internet_access_length": "Выберите максимальную продолжительность доступа в Интернет",
    "download_speed": "Скорость загрузки",
    "upload_speed": "Скорость загрузки",
    "network_length_1": "15 минут",
    "network_length_2": "30 минут",
    "network_length_3": "45 минут",
    "network_length_4": "1 час",
    "network_length_5": "2 часа",
    "network_length_6": "4 часа",
    "network_length_7": "6 часов",
    "network_length_8": "8 часов",
    "network_length_9": "10 часов",
    "network_length_10": "12 часов",
    "employee_wifi_name": "Имя Wi-Fi для сотрудников",
    "employee_wifi_password": "Пароль Wi-Fi для сотрудников",
    "guest_wifi_name": "Имя гостевого Wi-Fi",
    "menu_other_products_txt": "Другие продукты | Меню онлайн",
    "menu_home_text": "Главная | Меню Онлайн",
    "whatsapp_title": "Ватсап",
    "select_campaign_type": "Выберите тип кампании",
    "select_readymade_templates": "Выбирайте из готовых шаблонов или создайте свой собственный",
    "campaign_title_required": "Название кампании обязательно",
    "type_here": "Введите здесь...",
    "location_permission_req": "Требуется разрешение на местоположение",
    "platform_permission_req": "Требуется разрешение платформы",
    "profile_picture": "Фотография профиля",
    "click_to_upload": "Нажмите, чтобы загрузить",
    "location_permission": "Разрешение на местоположение",
    "pin": "Приколоть",
    "platform_permission": "Разрешение платформы",
    "set_pin": "Установить ПИН-код",
    "list_of_users": "Список пользователей",
    "create_user": "Создать пользователя",
    "terms_and_condition_req": "Условия и положения обязательны",
    "terms_and_conditions": "Условия и положения",
    "recommended": "РЕКОМЕНДУЕТСЯ",
    "mo": "\/мес.",
    "unsubscribe": "Отписаться",
    "cancel_subscription": "Вы уверены, что хотите отменить подписку?",
    "upgrade": "Обновление",
    "plan_upgraded_successfully": "План успешно обновлен!",
    "menu_online": "Менюонлайн",
    "support": "Поддерживать",
    "wifiC": "WI-FI",
    "billing": "Биллинг",
    "please_upgrade": "Пожалуйста, обновите версию, чтобы воспользоваться всеми функциями",
    "you_have_subscribed": "Вы подписались на ",
    "plan": "план",
    "of_text": "из",
    "days": "Дни",
    "remaining_days_until_plan": " Осталось дней до обновления вашего плана",
    "manage_your_screens": "Управляйте своими экранами",
    "screens_you_save": "Экраны у вас есть",
    "add_more_screens": "Добавить больше экранов",
    "addition_screen": "Дополнительный экран",
    "per_screen": "$ за экран",
    "per_box": "$ за коробку",
    "shipping_changes": "Стоимость доставки",
    "upto4_boxes": "до 4 коробок ",
    "charge_now": "Вы получите заряд сейчас",
    "updated_reoccuring": "Ваше обновленное повторение будет",
    "current_reoccuring": "Текущее повторяющееся: ",
    "no_text": "Нет",
    "android_box": "Android-приставка",
    "old_password_req": "Требуется старый пароль",
    "new_password_req": "Требуется новый пароль",
    "confirm_password_req": "Подтвердите пароль. Требуется",
    "password_do_not_match": "Пароли не совпадают",
    "old_password": "Старый пароль",
    "new_password": "Новый пароль",
    "confirm_password": "Подтвердите пароль",
    "copy_working_hours": "Копировать эти рабочие часы на все дни?",
    "yes_copy": "Да, копия",
    "closed": "Закрыто",
    "opening_time": "Время открытия",
    "closing_time": "Время закрытия",
    "description": "Описание",
    "file_exist": "файл уже существует, пожалуйста, выберите другой файл",
    "bussiness_images": "Бизнес-изображения",
    "display_info_on_market_place": "Хотите ли вы отобразить следующую информацию на своей торговой площадке?",
    "profile_ready": "Ваш профиль готов!",
    "client_book_with_online": "Клиенты теперь могут бронировать у вас онлайн. Поделитесь ссылкой ниже, чтобы начать.",
    "copy_link": "Копировать ссылку",
    "see_your_profile": "Посмотреть ваш профиль",
    "ok_got_it": "Хорошо, понял.",
    "preview_profile": "Просмотрите свой профиль и посмотрите, как он будет выглядеть.",
    "opening_hours": "Часы работы",
    "display_data": "Отображение данных",
    "manage_profile": "Управляйте профилем",
    "phone_req": "Телефон обязателен",
    "market_place_img_req": "Требуется изображение на торговой площадке",
    "add_new_market_place": "Добавить новый рынок",
    "edit_market_place": "Редактировать MarketPlace",
    "no_reviews_yet": "Пока нет отзывов",
    "good": "Хороший",
    "average": "Средний",
    "add_marketPlace": "Добавить MarketPlace",
    "all_title": "Все",
    "included_title": "Включено",
    "excluded_title": "Исключено",
    "clients_subscribed": "Клиенты подписались за последний",
    "clients_groups": "Группы клиентов",
    "customer_groups": "Группы клиентов",
    "audience_title": "Аудитория",
    "client_gt5": "кто не вернулся в последний",
    "select_all": "Выбрать все",
    "modify": "Изменить",
    "campaign_title": "Название кампании",
    "msg_type": "Тип сообщения",
    "enter_discount": "Введите скидку",
    "discount_type": "Тип скидки",
    "coupon_text": "Текст купона",
    "enter_coupon_text": "Введите текст купона",
    "coupon_button_text": "Текст кнопки купона",
    "enter_coupon_button_text": "Введите текст кнопки купона",
    "fine_prin": "Мелкий шрифт",
    "enter_fine_prin": "Введите текст мелким шрифтом",
    "campaign_dec": "Вы можете изменить кампанию за 30 минут до ее начала. Плата за SMS-кампанию будет взиматься за 30 минут до начала кампании.",
    "blast_text_message_dec": "Ваше взрывное текстовое сообщение готово. Выберите способ оплаты и отправьте сообщения.",
    "payment_completed": " Оплата будет завершена в ",
    "total_cost": "Общая стоимость",
    "close_title": "Закрывать",
    "friday": "Пятница",
    "saturday": "Суббота",
    "sunday": "Воскресенье",
    "thursday": "Четверг",
    "tuesday": "Вторник",
    "wednesday": "Среда",
    "port_txt": "Порт",
    "today_title": "Сегодня",
    "yesterday_title": "Вчера",
    "last_30_days_txt": "Последние 30 дней",
    "this_month_txt": "В этом месяце",
    "last_month_txt": "Прошлый месяц",
    "valid_date_title": "Выберите действительную дату",
    "valid_business_name_txt": "Введите действительное название компании",
    "req_bus_add_txt": "Требуется юридический адрес",
    "req_domain_name_txt": "Требуется доменное имя",
    "basic_info_txt": "Основная информация о вашем бизнесе",
    "loyalty_qr_def_txt": "Отсканируйте QR-код, подпишитесь на нашу карту лояльности и получите вознаграждения.",
    "last_stamp_txt": "Последняя марка",
    "collected_on_txt": "Собрано на",
    "stamp_details_txt": "Подробности почтовой марки",
    "add_stamp_txt": "Добавить штамп",
    "choose_brand_color_txt": "Выберите цвет и язык вашего бренда 🎨",
    "change_anytime_txt": "Вы можете изменить это в любое время.",
    "like_to_get_txt": "Хотите получить",
    "your_next_visit_txt": "в ваш следующий визит?",
    "time_to_time_get_offers": "Хотите ли вы время от времени получать выгодные предложения и акции?",
    "respond_back_to_you": "Хотите ли вы, чтобы кто-то вам ответил?",
    "input_serve_better": "Ваш вклад поможет нашей команде лучше обслуживать вас!",
    "do_it_txt": "Давай сделаем это",
    "take_15_s": "Занимает 15 секунд.",
    "rate_on_google_desc": "Не могли бы вы оценить нас в Google?",
    "may_be_next_time": "Возможно, в следующий раз",
    "thank_you_txt": "Спасибо",
    "look_next_txt": "Мы с нетерпением ждем вашего следующего визита!",
    "deleteUserTitle": "Этот пользователь будет удален навсегда.",
    "deleteMUserTitle": "Эти пользователи будут удалены навсегда.",
    "change_pin": "Изменить пин-код",
    "area_code": "Какой у вас телефонный код? ",
    "add_menu": "Добавить меню",
    "menu_name": "Название меню",
    "add_menu_placeholder": "Пицца, бургеры, напитки и т.д.",
    "enable_data_Collection": "Включить сбор данных",
    "add_new_menu": "Добавить новое меню",
    "rename_menu": "Переименовать меню",
    "preview": "Предварительный просмотр",
    "generate_qr": "Сгенерированный QR-код",
    "edit_menu": "Редактировать меню",
    "remove_menu": "Удалить меню",
    "menu_delete_msg": "Это меню будет удалено навсегда.",
    "menus_delete_msg": "Это меню будет удалено навсегда.",
    "view_menu_dialoage_msg": "Хотите получить подарок на день рождения?",
    "skip": "Пропускать",
    "cliam_your_gift": "Получите свой подарок",
    "collect_form": "Собрать форму",
    "enter_first_name": "Введите имя",
    "enter_last_name": "Введите фамилию",
    "enter_email": "Введите адрес электронной почты",
    "enter_dob": "Введите дату рождения",
    "enter_number": "Введите номер",
    "select_gender": "Выберите пол",
    "congratulations_desc": "🎉 Поздравляем! Ваш запрос на получение подарка был успешно отправлен. Наша команда свяжется с вами в ближайшее время. 🎉",
    "male_heading": "Мужской",
    "male_text": "мужской",
    "female_heading": "Женский",
    "female_text": "женский",
    "others_heading": "Другие",
    "other_text": "другие",
    "BirthD": "Дата рождения",
    "GenderT": "Пол",
    "EmailT": "Электронная почта",
    "dobT": "дата рождения",
    "capitalize_menu": "Меню",
    "select_menu": "Выбрать меню",
    "manage_variant": "Управление вариантом",
    "add_products": "Добавить продукты",
    "add_category": "Добавить категорию",
    "category_delete": "Эта категория будет удалена навсегда.",
    "variation_delete": "Этот вариант будет удален из продукта.",
    "product_delete": "Этот продукт будет удален навсегда.",
    "categories_delete": "Эти категории будут удалены навсегда.",
    "products_delete": "Эти продукты будут удалены навсегда.",
    "category": "КАТЕГОРИЯ",
    "price": "ЦЕНА",
    "food_product_placeholder": "Пицца, бургеры, напитки и т.д.",
    "active_title": "Активный",
    "inActive_title": "Неактивный",
    "status_capital": "СТАТУС",
    "cat_status_require": "Категория Статус обязателен",
    "cat_name_require": "Название категории обязательно",
    "category_name": "Название категории",
    "status": "Статус",
    "lgDay1": "Понедельник",
    "lgDay2": "Вторник",
    "lgDay3": "Среда",
    "lgDay4": "Четверг",
    "lgDay5": "Пятница",
    "lgDay6": "Суббота",
    "lgDay7": "Воскресенье",
    "is_closed_title": "Закрыто",
    "book_table_title": "Забронировать столик",
    "emailErrSub": "Тема электронного письма обязательна",
    "email_subject": "Тема электронного письма",
    "contactUsfrmTitleEmail": "Ответить на письмо",
    "companyInfo": "Lorem Ipsum — это lorem hrig, lorem ipsum — fraets. Lorem Ipsum — lorem hrig, lorem ipsum — fraets. Lorem Ipsum — lorem hrig, lorem ipsum — fraets.",
    "footerSTitle1": "О Menuonline",
    "footerSTitle1Lnk2": "Функции",
    "footerSTitle1Lnk3": "Блог",
    "footerSTitle2": "Юридический",
    "footerSTitle2Lnk1": "Условия и положения",
    "footerSTitle2Lnk2": "политика конфиденциальности",
    "footerSTitle3": "Для бизнеса",
    "footerSTitle3Lnk1": "Для партнеров",
    "footerSTitle3Lnk2": "Ценообразование",
    "footerSTitle3Lnk3": "Поддержка партнеров",
    "footerCopyRithgTxt": "  Menuonline или его филиалы",
    "homeTitle": "Дом",
    "contactTitle": "Связаться с нами",
    "aboutTitle": "О нас",
    "homeMenuTitle": "Меню",
    "bookTableTitle": "Забронировать столик",
    "vDateT": "Выберите действительную дату",
    "reqDateT": "Дата обязательна",
    "dateGtErr": "Дата должна быть позже или равна сегодняшней дате.",
    "timeInvalid": "Время недействительно",
    "reqTimeT": "Требуется время",
    "timeInvalidBfr": "Выберите время как минимум на 5 минут позже текущего времени ",
    "PerReq": "Требуется количество человек",
    "validcNoT": "Введите действительный контактный номер",
    "reqCrT": "Требуется валюта",
    "reqPrT": "Цена обязательна",
    "reCateT": "Категория обязательна",
    "reqVrT": "Требуется вариант",
    "reqVrVldT": "Введите действительный вариант ",
    "validLNameT": "Введите действительную фамилию",
    "sDateInValid": "Недействительная дата.",
    "minmReq": "Требуется минимум",
    "resLblDate": "Выберите дату",
    "resLblTime": "Выберите время",
    "perT": "На сколько человек?",
    "resLblEmail": "Укажите, пожалуйста, ваш адрес электронной почты?",
    "resLblNote": "Добавить примечание о бронировании",
    "imageError1MB": "Пожалуйста, вставьте изображение размером менее 1 МБ",
    "imageError": "Пожалуйста, вставьте изображение размером менее 2,5 МБ",
    "imageError3Mb": "Пожалуйста, вставьте изображение размером менее 3 МБ",
    "imageInVldError": "Файл недействителен.",
    "addProMT": "Меню продукта",
    "proT": "Название продукта",
    "reqProT": "Название продукта обязательно",
    "proPT": "Цена продукта",
    "reservationSuccTitle": "Отлично, Ваше бронирование завершено",
    "book": "Книга",
    "custzName": "Имя настройки",
    "always": "Всегда",
    "proImgReq": "Требуется изображение продукта",
    "selCustmT": "Пожалуйста, выберите настройку или создайте новую",
    "visStR": "Требуется статус видимости",
    "avlblScR": "Пожалуйста, выберите график доступности",
    "addonPR": "Пожалуйста, выберите дополнительные продукты",
    "upsellPR": "Пожалуйста, выберите продукты для дополнительной продажи",
    "markItemR": "Пожалуйста, выберите отметьте товар",
    "caloriesR": "Калории требуются",
    "allergensR": "Пожалуйста, выберите аллергенные продукты",
    "prepInstR": "Требуются инструкции по приготовлению",
    "staffNR": "Заметки персонала обязательны",
    "discountR": "Требуется скидка",
    "validDiscE": "Введите действительную скидку",
    "disday": "Днем",
    "plSelDayT": "Пожалуйста, выберите день",
    "sTimeReq": "Требуется время начала",
    "sTimeInValid": "Время начала недействительно",
    "eTimeReq": "Время окончания обязательно",
    "eTimeInValid": "Время окончания недействительно",
    "sDateReq": "Требуется указать дату",
    "eDateReq": "На сегодняшний день требуется",
    "eDateInValid": "На сегодняшний день недействительно",
    "disdate": "С даты",
    "disdate1": "На сегодняшний день",
    "disdate2": "От даты",
    "currT": "Валюта",
    "iconR": "Пожалуйста, выберите значок отметки элемента",
    "minT": "Минимум",
    "maxT": "Максимум",
    "itemNT": "Название товара",
    "itemPT": "Цена товара",
    "descProT": "Опишите ваш продукт",
    "cateT": "Категория",
    "selProCateT": "Выберите категорию продукта",
    "reqMT": "Имя меню обязательно",
    "ViewMenu": "Посмотреть меню",
    "CopyMenu": "Копировать меню",
    "EditMenu": "Редактировать меню",
    "RemoveMenu": "Удалить меню",
    "welcomeMnuTitle": "Добро пожаловать в",
    "reviewT": "Обзоры",
    "userErrorMsg": "Извините, произошла ошибка!!!",
    "reqCateT": "Название категории обязательно",
    "mngCustT": "Управление настройкой",
    "custNReq": "Требуется имя настройки",
    "incReq": "Включено обязательно",
    "minmValid": "Введите допустимое минимальное значение",
    "maxmReq": "Требуется максимум",
    "cust": "Настройка",
    "crCust": "Создать новую настройку",
    "custList": "Список настроек",
    "delCustmzT": "Эта настройка будет удалена навсегда.",
    "mkNameR": "Отметить имя элемента обязательно",
    "mkDelT": "Этот элемент отметки будет удален навсегда.",
    "hideText": "Скрывать",
    "showText": "Показывать",
    "device_mac_txt": "Устройство Mac",
    "delete_hotspot_txt": "Эта точка доступа будет удалена навсегда.",
    "delete_hotspots_txt": "Эти точки доступа будут удалены навсегда.",
    "emp_wifi_name": "Имя Wi-Fi для сотрудников",
    "max_limit_txt": "Введите «1024» для скорости, чтобы обозначить ее как НЕОГРАНИЧЕННУЮ. Это значение снимает любые ограничения, позволяя максимальную пропускную способность",
    "device_port_txt": "Порт устройства",
    "firmware_txt": "Прошивка",
    "hotspot_info_txt": "Информация о точке доступа",
    "editDBtn": "Изменить детали",
    "birth_date": "Дата рождения",
    "di_theme_clr": "Цвет темы ровно 7 цифр",
    "color_Invalid_txt": "Выберите действительный цветовой код",
    "Req_theme_clr": "Требуется цвет темы",
    "select_valid_color_txt": "Пожалуйста, выберите допустимый цвет темы",
    "req_redir_link": "Требуется ссылка для перенаправления",
    "val_redir_link": "Введите действительную ссылку перенаправления",
    "req_business_name_txt": "Название компании обязательно",
    "splash_preview": "Предварительный просмотр всплеска",
    "create_new_splash": "Создать новый всплеск",
    "splash_page": "Всплеск страницы",
    "splash_page_builder": "Конструктор страниц-заставок",
    "redirect_link": "Перенаправить ссылку",
    "theme_color": "Цвет темы",
    "enable_social_login": "Включить параметры социальных сетей для входа пользователей",
    "google": "Google",
    "facebook": "Фейсбук",
    "is_mandatory": "Это обязательно?",
    "field": "Поле",
    "name": "Имя",
    "first_name": "Имя",
    "last_name": "Фамилия",
    "birthdate": "Дата рождения",
    "gender": "Пол",
    "email": "Электронная почта",
    "dob": "дата рождения",
    "zip_code": "Почтовый индекс",
    "required_redirect_link": "Требуется ссылка для перенаправления",
    "valid_redirect_link": "Введите действительную ссылку перенаправления",
    "required_theme_color": "Требуется цвет темы",
    "theme_color_length": "Цвет темы ровно 7 цифр",
    "required_name": "Имя обязательно",
    "delete_splash_message": "Вы хотите удалить эту заставку?",
    "delete_multiple_splashes_message": "Вы хотите удалить эти брызги?",
    "user_login_required": "Требуется вход в систему",
    "set_theme_color": "Установить цвет темы",
    "splash_colllect_additional_info_txt": "Соберите дополнительную информацию",
    "verify_mobile_otp_message": "Хотите ли вы отправить одноразовый пароль для проверки номера мобильного телефона?",
    "add_company": "Добавить компанию",
    "edit_company": "Редактировать компанию",
    "ds_device": "Устройство цифровой вывески",
    "ds_device_activation_fee": "Плата за активацию устройства Digital Signage",
    "wifi_hardware": "Оборудование Wi-Fi",
    "menu_design": "Дизайн меню",
    "ds_hardware": "Оборудование для цифровых вывесок",
    "company_name_req": "Название компании обязательно",
    "known_name_req": "Требуется известное имя",
    "no_location_req": "Требуется указать номер местоположения",
    "minimum_location": "Пожалуйста, введите минимум 1 местоположение",
    "terms_req": "Условия обязательны",
    "notes_req": "Примечания обязательны",
    "sms_charge_req": "Требуется оплата за SMS",
    "menuonline_quantity_req": "Меню Онлайн Количество обязательно",
    "menuonline_cost_req": "Меню Онлайн Стоимость обязательна",
    "ds_quantity_req": "Требуется количество цифровых вывесок",
    "ds_monthly_cost_req": "Требуется ежемесячная стоимость Digital Signage",
    "reputation_quantity_req": "Репутация Количество обязательно",
    "reputation_cost_req": "Требуется стоимость репутации",
    "marketing_quantity_req": "Маркетинговое количество обязательно",
    "marketing_cost_req": "Требуется стоимость маркетинга",
    "tax_percentage_req": "Требуется процент налога",
    "router_hardware_quantity_req": "Требуется количество оборудования маршрутизатора",
    "wifi_hardware_cost_req": "Требуется стоимость оборудования WIFI",
    "activation_cost_req": "Требуется стоимость активации",
    "wifi_quantity_req": "WIFI Количество требуется",
    "wifi_router_req": "Требуется Wi-Fi-маршрутизатор",
    "menu_design_quantity_req": "Дизайн меню Количество требуется",
    "menu_design_cost_req": "Требуется стоимость дизайна меню",
    "quantity_req": "Количество обязательно",
    "cost_req": "Стоимость требуется",
    "sheduledown_quantity_req": "Требуется количество для сокращения графика",
    "sheduledown_cost_req": "Требуется график снижения стоимости",
    "loyalty_program_quantity_req": "Программа лояльности Количество обязательно",
    "loyalty_program_cost_req": "Стоимость программы лояльности обязательна",
    "loyalty_program_sms_quantity_req": "Программа лояльности Количество СМС обязательно",
    "loyalty_program_sms_cost_req": "Стоимость СМС-сообщения программы лояльности обязательна",
    "comapny_created": "Компания создана успешно",
    "comapny_updated": "Компания успешно обновлена",
    "billing_info": "ПЛАТЕЖНАЯ ИНФОРМАЦИЯ",
    "business_name_acc_holder": "Название компании (владелец счета)",
    "client_first_name": "Имя клиента",
    "client_last_name": "Фамилия клиента",
    "dba_known_as": "DBA, известный как",
    "business_address": "Юридический адрес",
    "no_of_locations": "Количество локаций",
    "package_details": "ДЕТАЛИ УПАКОВКИ",
    "terms": "Условия",
    "one_month": "1 месяц",
    "per_sms_charge": "Плата за SMS",
    "plan_test": "ПЛАН: ТЕСТ",
    "desc_capital": "ОПИСАНИЕ",
    "qty_capital": "КОЛ-ВО",
    "cost_capital": "РАСХОДЫ",
    "total_price_capital": "ОБЩАЯ ЦЕНА",
    "quantity": "Количество",
    "can_not_access": "Вы не можете выбрать больше, чем",
    "licenses": "лицензии",
    "monthly_cost": "Ежемесячная стоимость",
    "plan_cost": "Стоимость плана",
    "tax": "Налог",
    "tax_percentage": "Налоговый процент",
    "monthly_cost_after_tax": "Ежемесячная стоимость после уплаты налогов",
    "activation_one_time_charge": "Активация и единовременная оплата",
    "sub_total": "ИТОГО",
    "action": "Действие",
    "cost": "Расходы",
    "shipping_charge": "Стоимость доставки",
    "other": "Другой",
    "additional_cost": "Дополнительные расходы (другие расходы)",
    "other_tax": "Другие налоги",
    "total": "Общий",
    "license_statistics": "Статистика лицензий",
    "total_licenses": "Всего лицензий",
    "available_licenses": "Доступные лицензии",
    "stock": "Запас",
    "has_permission": "Имеет разрешение",
    "avg_stock_price": "Средняя цена акций",
    "average_price": "Средняя цена",
    "allocated": "Выделено",
    "reward_regulars": "Наградить постоянных клиентов",
    "not_add_coupon_url": "Невозможно добавить URL-адрес купона. Максимальная длина сообщения — 160.",
    "like_attach_coupon": "Хотите прикрепить купон?",
    "advance_scheduling": "Предварительное планирование",
    "choose_day": "Выберите день(ы) для отправки сообщения",
    "select_essage_window": "Выберите окно сообщения",
    "subscription_outside_delivery_window": "Если пользователь подпишется за пределами окна доставки, он получит ваш автоответчик в ближайшее доступное время.",
    "remaining": "Оставшийся",
    "processing_request": "При обработке вашего запроса произошла ошибка.",
    "list_companies": "Список компаний",
    "are_you_sure": "Вы уверены..?",
    "signin_agreement": "Подтвердив это, компания будет создавать без подписания соглашения.",
    "status_updated": "Статус обновлен успешно",
    "status_failed": "Не удалось обновить статус",
    "new_editor_status_updated": "Статус DS New Editor успешно обновлен.",
    "user_name": "Имя пользователя",
    "known_as": "известный как",
    "agreement_signed": "Соглашение подписано",
    "deactivate": "Деактивировать",
    "activate": "Активировать",
    "login_to_user_acc": "Войти в учетную запись пользователя",
    "disable": "Запрещать",
    "enable": "Давать возможность",
    "ds_new_editor": "DS Новый редактор",
    "sign_agreement": "Подписать соглашение",
    "agreement_sign": "Соглашение без подписи",
    "view_agreement": "Посмотреть соглашение",
    "download_agreement": "Скачать соглашение",
    "add_deleted_user": "Добавить удаленного пользователя",
    "deleted_user": "Удалить пользователя",
    "favourite": "Любимый",
    "refresh": "Обновить",
    "delete_chat": "Удалить чат",
    "responsive_drawer": "Отзывчивый ящик",
    "delete_contact": "Удалить контакт",
    "clear_chat_history": "Очистить историю чата",
    "clear_chat_history_dec": "Вы уверены, что хотите удалить этот чат?",
    "clear_contact_dec": "Вы уверены, что хотите удалить этот контакт?",
    "select_contact": "Выбрать контакт",
    "new_conversation": "Начать новый разговор",
    "type_msg": "Введите ваше сообщение здесь",
    "select_delete_client": "Пожалуйста, выберите клиента для удаления",
    "select_delete_client_chat": "Пожалуйста, выберите клиента для удаления чата",
    "select_delete_client_chat_err": "У вас нет чата с этим клиентом",
    "acquire_customers_business": "Привлекайте новых клиентов из других компаний.",
    "customers_from_events": "Привлекайте клиентов с мероприятий и приводите их к себе!",
    "customers_from_ads": "Привлекайте клиентов из объявлений в газетах.",
    "smart_pamphlets": "Создавайте интеллектуальные брошюры для сбора данных о клиентах.",
    "smart_qr_group": "Умный QR-код для присоединения к группе клиентов.",
    "opt_in_number": "Дайте клиентам возможность выбрать номер.",
    "qr_menu": "Создайте QR-код для отображения вашего меню.",
    "collect_birthdays": "Соберите информацию о днях рождения и годовщинах клиентов.",
    "join_loyalty_program": "Привлекайте новых клиентов к моей программе лояльности.",
    "create_feedback_forms": "Создавайте формы для сбора отзывов клиентов.",
    "exclusive_discounts_promotions": "Предлагайте эксклюзивные скидки и акции для перекрестных акций.",
    "group_smart_qr_opt_in": "После создания группы вы можете сгенерировать и выбрать интеллектуальный QR-код, позволяющий клиентам мгновенно присоединиться к группе.",
    "group_smart_qr_opt_in_dec": "После создания группы виджет изображения позволяет клиентам подписаться с помощью текста и получать рекламные акции, например, отправьте сообщение «пицца» на номер 555-555-5555",
    "in_store_discount_next_visit": "Получите скидку в магазине во время вашего следующего визита в наш магазин",
    "delivery_redirection": "Создать перенаправление доставки",
    "additional_info": "Дополнительная информация",
    "add_url": "Добавить URL-адрес",
    "custom_fields": "Пользовательские поля",
    "meta_tag": "Мета-тег",
    "max_chars": "Макс. кол-во символов",
    "add": "Добавлять",
    "update_campaign": "Обновление кампании",
    "last_week_required": "требуется последняя неделя",
    "large_week_value": "Значение недели слишком велико, введите допустимую неделю",
    "less_than_last_week": "Невозвращение на прошлой неделе должно быть меньше, чем на прошлой неделе.",
    "last_week_req": "Не требуется возврат на прошлой неделе",
    "birthday_special": "Специальное предложение на день рождения",
    "birthday_message": "Автоматическая рассылка сообщений в дни рождения с персонализированными поздравлениями и заманчивыми предложениями",
    "birthday_sms": "Создавайте персонализированные поздравления с днем рождения для SMS\/MMS",
    "target_audience": "Выберите целевую аудиторию",
    "client_by_birthday": "Клиент по дню рождения",
    "clients_once_year": "Эта кампания автоматически отправляется клиентам один раз в год ",
    "auto_responder_default_msg_1": "Вы наш один из лучших клиентов, которому мы любим давать скидки! Пусть вы всегда будете богаты и здоровы. С днем рождения! %% имя %%",
    "campaign_send_to_client_birthday": "Эта автоматическая кампания отправляется клиентам, у которых день рождения. Вы можете настроить таргетинг ниже.",
    "get_more_engagement": "Привлеките больше внимания, отправив SMS на день рождения 🎂",
    "welcome_new_clients": "Приветствуем новых клиентов",
    "make_lasting_impression": "Произведите неизгладимое впечатление, тепло поприветствовав и приняв радушно тех, кто пришел к вам впервые",
    "personalized_greetings": "Создавайте персонализированные приветствия для новых клиентов",
    "greetings_to_new_customers": "Эта кампания будет отправлять приветственные сообщения новым клиентам на следующий день после их первого взаимодействия с вашей компанией.",
    "audience_predefined": "Аудитория этого автоответчика предопределена, у нас это есть!",
    "send_clients_subscribed": "Этот автоответчик настроен на отправку клиентам, подписавшимся в последний раз ",
    "first_interaction": "  после их первого взаимодействия.",
    "default_msg_2": "Надеемся, вам понравился ваш первый визит, мы будем рады снова увидеть вас в ближайшее время, поэтому мы предлагаем скидку специально для вас! Нажмите на ссылку, чтобы записаться на следующий прием у нас и воспользоваться специальным предложением онлайн прямо сейчас.",
    "new_clients_update": "Приветствуем новых клиентов, обновите текст кампании",
    "new_clients_warm_greeting": "Тепло приветствуйте новых клиентов 💌",
    "win_back_clients": "Возвращение клиентов",
    "re_engage_past_customers": "Повторно привлекайте бывших клиентов с помощью выгодных предложений, чтобы побудить их вернуться",
    "target_disengaged_clients": "Предложите отозванным клиентам вернуться с помощью специального предложения — проверенная тактика привлечения клиентов обратно.",
    "campaign_sends_to_clients": "Эта кампания рассылается клиентам, которые не вернулись по истечении определенного периода времени.",
    "customize_targeting": "Настройте таргетинг ниже, чтобы отправлять SMS\/MMS-сообщения клиентам, которым вы отказались от услуг.",
    "clients_with_at_least": "Клиенты с по крайней мере ",
    "connection_last": " соединение в последнем ",
    "return_in_last_week": " неделю, но не вернулся в прошлом ",
    "arr_menu_44": " неделя",
    "df_msg_4": "Мы ТАК СИЛЬНО хотим, чтобы вы вернулись, что дадим вам скидку 10% просто за то, что вы вернулись! Хотите попробовать еще раз сегодня?",
    "update_campaign_text": "Текст кампании обновления «Вернуть клиентов»",
    "re_invite_customers": "Повторно пригласите клиентов, которые не возвращались некоторое время",
    "loyal_patrons": "Выразите признательность постоянным клиентам, предложив им специальные скидки и вознаграждения за лояльность.",
    "surprize_top_spenders": "Удивите самых щедрых покупателей персонализированными и специальными предложениями.",
    "campaign_automatically_send": "Эта кампания автоматически рассылает вознаграждения для увеличения посещаемости вашего бизнеса.",
    "sms_loyal_clients": "В рамках этой кампании постоянным клиентам отправляются SMS\/MMS-сообщения. Вы можете изменить таргетинг ниже.",
    "or_more_connection": " или больше связи, в течение последнего ",
    "week_period": " недельный период.",
    "default_msg_5": "Вы уже давно являетесь нашим клиентом! Ого, как летит время! Мы ценим вас и хотим поблагодарить вас за то, что вы с нами.",
    "reward_regulars_update": "Текст кампании обновления вознаграждения постоянным клиентам",
    "special_discounts": "Оцените и предложите специальные скидки💎",
    "leave_positive_online": "Поощряйте клиентов оставлять положительные отзывы в Интернете, чтобы повысить репутацию ресторана.",
    "clients_service_online": "Кампания побуждает ваших клиентов оценивать ваши услуги онлайн.",
    "increase_your_company": "Этот автоответчик отправляется для повышения рейтинга вашей компании в Интернете.",
    "send_message_after": "Отправить сообщение после ",
    "min_connection": " мин. соединения",
    "default_msg_6": "Надеемся, вам понравился ваш первый визит, мы будем рады увидеть вас снова! [URL] Нажмите на ссылку, чтобы оценить нас в Google.",
    "ratings_update": "Получить больше Рейтинги обновить текст кампании",
    "friendly_nudge": "Получите больше отзывов с помощью дружеского толчка ⭐",
    "thanks_for_visiting": "Спасибо за посещение",
    "express_gratitude": "Выразите признательность клиентам за выбор вашей компании с помощью сердечных благодарственных сообщений.",
    "thank_you_message": "Создайте благодарственное сообщение для ваших клиентов.",
    "clients_for_visiting": "Поблагодарите своих клиентов за посещение вашего заведения.",
    "campaign_audience": "Эта аудитория автоматической кампании предопределена, у нас это есть!",
    "campaign_automatically_sends": "Эта автоматическая кампания автоматически отправляется после ",
    "minutes_customer_visit": " минут визита клиента",
    "default_msg_7": "Спасибо, что вы наш ценный клиент. Мы очень благодарны за удовольствие обслуживать вас и надеемся, что мы оправдали ваши ожидания.",
    "visiting_update_campaign": "Спасибо за посещение обновления текста кампании",
    "guests_thank_you": "Поблагодарите своих гостей за визит 😊",
    "download_title": "Скачать",
    "qr_gen_title": "QR-код сгенерирован",
    "qr_download_s_title": "QR-код успешно загружен",
    "sel_dark_brand_clr_msg": "Пожалуйста, выберите допустимый цвет рамки",
    "manage_customer_stamp_rewards": "Управляйте наградами за печатки клиентов 🎁",
    "sel_loc_menu_title": "Выберите места для вашего меню",
    "ans_req": "Ответ обязателен",
    "valid_reputation_name": "Введите действительное имя репутации",
    "reviews_name_req": "Получить больше отзывов имя обязательно",
    "birthdate_required": "Дата рождения обязательна",
    "gender_required": "Пол обязателен",
    "valid_birthdate_required": "Введите действительную дату рождения",
    "custom_delivery_redirection": "Создайте пользовательскую форму перенаправления доставки по группам",
    "customer_fields_incentive_settings": "Поля клиентов и настройки стимулов",
    "delivery_redirection_text": "Перенаправление доставки",
    "delivery_redirection_success": "Поздравляем! Ваш виджет перенаправления доставки создан! 🎉",
    "swipe_to_preview_redirection": "Проведите пальцем, чтобы увидеть, как будет выглядеть ваша форма перенаправления доставки",
    "enter_item_name": "Введите название товара",
    "home_page_text": "Текст домашней страницы",
    "settings": "Настройки",
    "anniversary_required": "Требуется указать дату годовщины",
    "valid_anniversary": "Введите действительную дату годовщины",
    "form_submited": "Форма успешно отправлена",
    "notifications": "Уведомления",
    "discount_message": "Получите скидку 20% на пиццу прямо сейчас!",
    "is_required": " требуется",
    "section_title_required": "Название раздела обязательно",
    "section_dec_required": "Описание раздела обязательно",
    "client_details_required": "Поля сведений о клиенте обязательны для заполнения",
    "compliance": "Согласие",
    "SMS_campaign1": "СМС-кампания 1",
    "mobile_number_mandatory": "Номер мобильного телефона обязателен",
    "new_answer": "Новый ответ",
    "new_question": "Новый вопрос",
    "add_new_question": "Добавить новый ответ",
    "select": "Выбирать",
    "group_customers_question": "Где вы хотите сгруппировать своих клиентов?",
    "contacts_added_to_group": "Все контакты, отправившие форму, будут добавлены в выбранную группу.",
    "edit_client_details_section": "Редактировать раздел сведений о клиенте",
    "client_details_fields": "Поля сведений о клиенте",
    "enter_client_details_section_title_desc": "Введите заголовок и описание раздела сведений о клиенте.",
    "choose_fields_displayed_client_side": "Выберите поля, которые будут отображаться на стороне клиента.",
    "section_title": "Название раздела",
    "add_title": "Добавить заголовок",
    "section_description": "Описание раздела",
    "add_description": "Добавить описание",
    "enter_program_name_location": "Введите название и местоположение программы 📍",
    "brand_theme_clr_txt": "Цвет фирменной темы",
    "set_loyalty_punch_card_theme": "Давайте определим тему для вашей карты лояльности 🎨",
    "upload_logo_txt": "Загрузите свой логотип",
    "recommended_image_specs": "Для лучшего отображения мы рекомендуем использовать изображение размером 512 x 512 пикселей. JPG, SVG или PNG. Максимальный размер 10 МБ.",
    "valid_rep_name_txt": "Введите действительное имя репутации",
    "rep_name_req_txt": "Получить больше отзывов имя обязательно",
    "que_req": "Вопрос обязателен",
    "day_of_birthday": "в день рождения",
    "day_before_birthday": "3 дня до дня рождения",
    "week_before_birthday": "за неделю до дня рождения",
    "two_week_before_birthday": "за две недели до дня рождения",
    "de_active": "Деактивирован",
    "campaign_details": "Подробности кампании",
    "link_clicked": "Ссылка нажата",
    "history": "История",
    "auto_responder_summary": "Резюме автоответчика",
    "vsop_1": "15 мин.",
    "vsop_2": "30 мин.",
    "vsop_3": "45 мин.",
    "vsop_4": "60 мин.",
    "vsop_5": "90 мин.",
    "vsop_6": "120 мин.",
    "in_the_last": " в последний раз ",
    "return_in_last": " но не вернулся в прошлом ",
    "of_the_connection": "соединения",
    "your_campaign": "Ваша кампания",
    "you_have_successfully": "Вы успешно",
    "published_successfully": "успешно опубликовано",
    "updated_smart_campaign": "обновил умную кампанию",
    "almost_done_text": "Почти готово!",
    "update_campaign_desc": "Ваш автоответчик готов, после включения он будет отправлять сообщения клиентам на постоянной основе. Эту кампанию можно легко изменить или приостановить в любое время.",
    "update_and_publish": "Обновить и опубликовать",
    "reset_campaign_title": "Он навсегда удалит пользовательский текст, изображение, предложения скидок и целевую аудиторию вашей кампании!",
    "space_wifi": " Wi-Fi",
    "custom_forms": "Пользовательские формы",
    "web_e_signup": "Электронная регистрация через Интернет",
    "import": "Импорт",
    "permissions_req": "Требуются разрешения",
    "redeemed": "Выкуплен",
    "coupon_already_redeemed": "Купон уже использован",
    "autoresponder_campaigns": "Автоответчики кампании",
    "autoresponder_campaign_desc": "Включите автоответчик и запустите персонализированную кампанию",
    "mon": "ПН",
    "tue": "ВТ",
    "wed": "ОБВЕНЧАЛИСЬ",
    "thu": "ЧТ",
    "fri": "ПТ",
    "sat": "СБ",
    "sun": "СОЛНЦЕ",
    "duplicate": "Дубликат",
    "visibility": "Видимость",
    "availability": "Доступность",
    "out_stok": "Нет в наличии",
    "edit_product": "Изменить продукт",
    "create_product": "Создать продукт",
    "basic_info": "Основная информация",
    "basic_sub_info": "Lorem Ipsum — это просто макет печатного текста...",
    "enter_product_price": "Введите цену продукта",
    "upload_image": "Загрузить изображение",
    "allowed_file_formats": "Разрешены только файлы JPG и PNG с максимальным размером файла 3 МБ.",
    "pre_select": "Предварительный выбор",
    "promotions": "Акции",
    "discount_availability": "Наличие скидки",
    "start_time": "Время начала",
    "end_time": "Время окончания",
    "select_day": "Выберите день",
    "menu_required": "Меню обязательно",
    "generated": "Сгенерировано",
    "link": "Связь",
    "variations": "Вариации",
    "select_variant_to_add": "Выберите вариант, который вы хотели бы добавить",
    "price_title": "Цена",
    "choose_image": "Выбрать изображение",
    "select_visiblity": "Выберите видимость",
    "availability_schedule": "График доступности",
    "add_on_upsell": "Добавить\/продать дополнительно",
    "add_on_select_product": "Добавить к выбранному продукту",
    "upsell_product": "Выбрать продукт для покупки подороже",
    "variant_deletion_warning": "Этот вариант будет удален навсегда.",
    "search_variations": "Варианты поиска",
    "add_variation": "Добавить вариацию",
    "variation_text": "Вариант",
    "select_customization": "Выберите настройку",
    "add_new": "Добавить новый",
    "delete_customization_warning": "Хотите удалить эту настройку из продукта?",
    "nutritional_allergen_info": "Информация о пищевой ценности и аллергенах",
    "mark_item": "Отметить элемент",
    "calories_text": "Калории",
    "allergens": "Аллергены",
    "select_allergens": "Выберите аллергены",
    "special_instructions": "Специальные инструкции",
    "preparation_instructions": "Инструкции по приготовлению",
    "staff_notes": "Заметки персонала",
    "checkbox1": "Флажок, позволяющий разрешить или запретить получение специальных инструкций от клиентов для этого пункта меню.",
    "menu_c": "МЕНЮ",
    "past_design": "Прошлый дизайн",
    "file_name": "Имя файла",
    "variation_name": "Название вариации",
    "grid_items_title1": "Палатка-стол",
    "grid_items_title2": "Цифровое меню",
    "grid_items_title3": "Меню на вынос",
    "grid_items_title4": "Меню на полстраницы",
    "grid_items_title5": "Цифровое меню 5",
    "grid_items_title6": "Цифровое меню 6",
    "grid_items_title7": "Цифровое меню 7",
    "grid_items_title8": "Цифровое меню 8",
    "grid_items_title9": "Цифровое меню 9",
    "enter_valid_form_name": "Введите допустимое имя формы",
    "form_name_required": "Имя формы обязательно",
    "enter_valid_section": "Введите допустимое название раздела",
    "fraction_half": "1\/2",
    "fraction_two_by_two": "2\/2",
    "form_name": "Имя формы",
    "contact_list": "Список контактов",
    "label_notify_email": "Хотите ли вы получать уведомления по электронной почте?",
    "add_client_details_section": "Добавить раздел сведений о клиенте",
    "demo_purpose_only": "Это только для демонстрационных целей.",
    "form_updated_success": "форма успешно обновлена",
    "form_creat_success": "форма создана успешно",
    "add_custom_section": "Добавить пользовательский раздел",
    "edit_custom_section": "Редактировать пользовательский раздел",
    "forms_list": "Список форм",
    "filled_custom_form_details": "Заполненные данные пользовательской формы",
    "custom_form_deletion_warning": "Эта пользовательская форма будет удалена навсегда.",
    "menu_link": "Ссылка на меню",
    "WebSignUpT": "Электронная регистрация",
    "lifeTConT": "Связи на всю жизнь",
    "repCustT": "Постоянные клиенты",
    "liftTSubT": "Пожизненные подписчики",
    "overallT": "ОБЩИЙ",
    "loginCustBDT": "Зарегистрированные клиенты по дням",
    "deviceT": "Демографические данные устройств",
    "ageTitle": "Возраст Демография",
    "ageT": "Возрастная группа",
    "convert_social_media_followers_into_customers": "Превращайте подписчиков в социальных сетях в клиентов",
    "signWidDesc": "Создайте виджет для регистрации, создания целевых страниц и генерации купонов на вашем веб-сайте или в социальных сетях.",
    "qrWidT": "Умный QR-код",
    "imgWidT": "Выберите номер",
    "mobile": "Мобильный",
    "YESTitle": "ДА",
    "NOTitle": "НЕТ",
    "addDelRed": "Добавить перенаправление доставки",
    "dlredNameT": "Назовите перенаправление доставки",
    "conf_text_req": "Требуется текст подтверждения",
    "cFDesc": "Создавайте специальные формы для эффективного сбора конкретных отзывов и информации от клиентов.",
    "pre_review_questions_enabled": "Прежде чем просить клиентов оставить отзыв, давайте зададим им от 1 до 4 вопросов.",
    "feel_free_edit": "Не стесняйтесь редактировать по мере необходимости.",
    "option": "Вариант",
    "confFL": "Это подтверждение будет показано после того, как ваш контакт отправит форму веб-регистрации.",
    "confT": "Текст подтверждения",
    "joinTitle": "Присоединиться",
    "mobValidL": "Пожалуйста, проверьте действительный номер мобильного телефона.",
    "mobRequiredL": "Требуется номер мобильного телефона",
    "Business_location_label": "Местоположение вашего бизнеса",
    "qr_code_selection_txt": "Выбор QR-кода",
    "choose_theme_txt": "Выбрать тему",
    "edit_qr_details_txt": "Редактировать данные QR",
    "save_qr_code_txt": "Сохранить QR-код",
    "text_color_label": "Цвет текста",
    "background_color_label": "Цвет фона",
    "print_txt": "Печать",
    "customer_will_see_txt": "Это то, что увидит ваш клиент. Выберите цвет вашего бренда на следующем шаге.",
    "choose_your_brand_color_&_langauge_title": "Выберите цвет и язык вашего бренда",
    "custom_redirect_link_label": "Пользовательская ссылка перенаправления",
    "redirect_user_to_menu": "Перенаправить пользователя в меню",
    "language": "Язык",
    "select_language_placeholder": "Выберите язык",
    "capitalize_location_name_txt": "Название местоположения",
    "created_txt": "Созданный",
    "swipe_reputation_management_txt": "Проведите пальцем, чтобы увидеть, как будет выглядеть ваше управление репутацией",
    "sent_txt": "Отправил",
    "number_label": "Число",
    "date_label": "Дата",
    "gender_demographics_title": "Гендерная демография",
    "step1_preview_desc": "Дайте название вашей QR-форме электронной регистрации и добавьте ее в список вашей группы.",
    "main_head": "Привлекайте новых клиентов",
    "main_desc": "Привлекайте клиентов, размещая QR-код, который при сканировании перенаправляет пользователей на персонализированную форму регистрации.",
    "step1_description": "Привлекайте новых клиентов из других компаний",
    "form_name_field_placeholder": "Электронная регистрация Restro",
    "last_step_title": "Поздравляем! Ваш виджет электронной регистрации создан! 🎉",
    "last_step_desc": "Проведите пальцем, чтобы увидеть, как будет выглядеть ваша форма электронной регистрации",
    "events_step1_preview_desc": "Дайте название QR-форме вашего мероприятия и добавьте ее в список вашей группы.",
    "events_main_head": "Привлекайте клиентов с мероприятий",
    "events_main_desc": "Привлекайте клиентов, размещая на мероприятии QR-код, при сканировании которого пользователи перенаправляются на персонализированную форму регистрации.",
    "events_step1_desc": "Привлекайте клиентов на мероприятия и приводите их в магазин",
    "events_name": "Название события",
    "events_form_name_field_placeholder": "Restro Fun и гастрономическое мероприятие",
    "events_last_step_title": "Поздравляем! Ваша форма мероприятия создана! 🎉",
    "events_last_step_desc": "Проведите пальцем, чтобы увидеть, как будет выглядеть форма вашего мероприятия",
    "birthday_anniversary_step1_preview_desc": "Назовите свою QR-форму, чтобы собирать дни рождения или годовщины клиентов, и добавьте ее в свой групповой список",
    "birthday_anniversary_main_head": "Собирайте на день рождения или годовщину",
    "birthday_anniversary_main_desc": "Получите дату рождения или годовщины клиента, при сканировании перенаправьте пользователей на персонализированную форму регистрации.",
    "birthday_anniversary_step1_desc": "Соберите день рождения и годовщину клиента",
    "restro_text": "Рестро",
    "birthday_anniversary_last_step_title": "Поздравляю! Получите B'day & Anniversary от клиентов, форма создана! 🎉",
    "newspaper_ads_step1_preview_desc": "Дайте название вашей QR-форме, чтобы получать клиентов из газетных объявлений и добавлять ее в список вашей группы",
    "newspaper_ads_main_head": "Получайте клиентов из рекламы",
    "newspaper_ads_main_desc": "Получайте клиентов из газетных объявлений, при сканировании которых пользователи перенаправляются на персонализированную форму регистрации.",
    "newspaper_ads_step1_desc": "Привлекайте клиентов из рекламы в газете или наружной рекламы",
    "newspaper_ads_last_step_title": "Поздравляем! Создана форма «Привлекайте клиентов из газетных объявлений»! 🎉",
    "smart_pamphlets_step1_preview_desc": "Дайте название вашей QR-форме, чтобы получить данные клиентов из брошюр и добавить их в список вашей группы",
    "smart_pamphlets_main_head": "Получите клиентов из брошюр",
    "smart_pamphlets_main_desc": "Получайте данные клиентов из брошюр, при сканировании которых пользователи перенаправляются на персонализированную форму регистрации.",
    "smart_pamphlets_last_step_title": "Поздравляем! Получение данных клиентов из буклетов создано! 🎉",
    "social_media_step1_preview_desc": "Дайте название вашей QR-форме, чтобы привлекать клиентов из социальных сетей и добавлять ее в список вашей группы.",
    "social_media_main_desc": "Получайте клиентов из социальных сетей. При просмотре публикаций пользователи перенаправляются на персонализированную форму регистрации.",
    "social_media_form_name_field_placeholder": "Предложение Фестиваля Рестро – сообщение в Facebook",
    "social_media_last_step_title": "Поздравляем! Создайте форму для клиентов в социальных сетях! 🎉",
    "claim_offer_text": "Заполните свои данные и воспользуйтесь предложением прямо сейчас",
    "form_title_description": "Добавьте заголовок и описание формы, чтобы ваши клиенты могли ввести данные",
    "no_subscribed_clients": "Выбранная группа клиентов не имеет подписанных клиентов",
    "welcome_message_offer": "Отправьте приветственное сообщение своим клиентам после их регистрации и предоставьте им купон на скидку",
    "system_grp": "Системные группы",
    "import_clients": "Импорт клиентов",
    "delivery_redirection_name": "Имя перенаправления доставки",
    "created_at": "Создано в",
    "provide_stamp_user": "Предоставить штамп пользователю",
    "scan_qr_code": "Сканировать QR-код",
    "add_point": "Добавить точку",
    "total_points": "Всего очков",
    "user_profile": "Профиль пользователя",
    "stamp_history": "История марки",
    "reward_txt": "Награда",
    "variant_placeholder": "Маленький, средний, большой и т.д.",
    "sent_coupon": "Отправленный купон",
    "order_status": "Статус заказа",
    "payment_status": "Статус платежа",
    "order_currency": "Валюта заказа",
    "card_data": "Данные карты",
    "reward_expiring_on": "Срок действия вознаграждения истекает",
    "reward_status": "Статус награды",
    "reward_get_date": "Дата получения награды",
    "reward_name": "Название награды",
    "add_point_type": "Добавить тип точки",
    "restaurant_location": "Расположение ресторана",
    "collect_points": "Набирайте баллы",
    "rewards_expire": "Срок действия наград истекает",
    "rewards_redeem": "Награды Выкупить",
    "total_rewards": "Всего наград",
    "created_by": "Создано",
    "anniversary": "Годовщина",
    "remove_user_group_txt": "Удалить пользователя из группы",
    "delete_permanently_grp_msg": "Этот клиент будет навсегда удален из группы.",
    "delete_multiple_permanently_grp_msg": "Эти клиенты будут навсегда удалены из группы.",
    "delete_multiple_client": "Эти клиенты будут удалены навсегда.",
    "messages": "Сообщения",
    "management": "Управление",
    "client": "Клиент",
    "country": "Страна",
    "state": "Состояние",
    "city": "Город",
    "1_sparkly_point_txt": "🎉 Вы заработали 1 блестящее очко! 🌟",
    "select_country_txt": "Выберите страну",
    "select_state": "Выберите штат",
    "ls_thanks_for_join": "Спасибо, что присоединились к нашей карте лояльности.🎁🎈",
    "month": "Месяц",
    "welcome_message": "Отправьте приветственное сообщение своим клиентам после их регистрации",
    "all_rewards_txt": "Все награды",
    "active_rewards": "Активные награды",
    "expired_and_redeemed": "Истек срок действия и погашен",
    "redirect_3rd_party_delivery": "Перенаправьте доставку третьей стороной в ваш магазин или на вашу платформу онлайн-заказов",
    "search_or_start_new": "Поиск или Начать новый",
    "contacts": "Контакты",
    "chats": "Чаты",
    "add_by_restaurant": "Добавить по ресторану",
    "using_scan_code": "Использование скан-кода",
    "step": "Шаг",
    "list_widget_text": "Список виджетов",
    "swipe_free_wifi_txt": "Проведите пальцем для бесплатного Wi-Fi",
    "create_widget_txt": "Создать виджет",
    "redeem_award_msg": "Вы уверены? Вы хотите получить эту награду?",
    "on_collect_msg": "На сборе",
    "pause": "Пауза",
    "resume": "Резюме",
    "location_added": "местоположение добавлено",
    "sub_user_admin_permissions_txt": "Разрешить второстепенному пользователю доступ к административным полномочиям в Digital Signage",
    "unlist": "Удалить из списка",
    "cannot_delete_default_terms": "Невозможно удалить положения и условия по умолчанию",
    "something_went_wrong": "Что-то пошло не так!",
    "card_download_success": "Карта успешно загружена",
    "permission_denied": "Доступ запрещен!",
    "invalid_qr_code": "Пожалуйста, отсканируйте действительный QR-код.",
    "select_customer": "Пожалуйста, выберите клиента",
    "online": "ОНЛАЙН",
    "offline": "ОФФЛАЙН",
    "no_data": "Упс, данные не найдены!",
    "forever_free": "Навсегда бесплатно"
}

export default ru;