const sk = {
    "rated_google_reviews": "Hodnotenie 5,0 v recenziách Google",
    "get_your_digital_menu": "Získajte svoje digitálne menu —",
    "instant_popup_menu": "Okamžité kontextové menu bez skenovania QR kódov",
    "free_forever": "Navždy zadarmo",
    "the_future_is_here": "Budúcnosť je tu!",
    "upgrade_your_restaurant": "Vylepšite svoju reštauráciu úžasným digitálnym menu za nulové náklady. Prilákajte viac zákazníkov už dnes.",
    "get_my_free_menu_now": "Získajte moje bezplatné menu",
    "no_credit_card_required": "* Nevyžaduje sa žiadna kreditná karta",
    "join_largest_marketplace": "Pridajte sa k najväčšiemu reštauračnému trhu!",
    "what_will_you_get": "Čo získate",
    "free": "ZDARMA",
    "free_digital_menu": "Bezplatné digitálne menu 2D alebo 3D",
    "free_digital_menu_desc": "Rozlúčte sa s papierovými menu a drahými dotlačami",
    "quick_and_easy_signup": "Rýchla a jednoduchá registrácia",
    "communicate_with_customers_title": "Komunikujte so zákazníkmi ZDARMA!",
    "features1": [
        "Ušetrite náklady na tlač a užívajte si okamžité aktualizácie ponuky, kedykoľvek ich potrebujete.",
        "Prístup k digitálnemu menu na akomkoľvek zariadení",
        "Ekologické a bezproblémové"
    ],
    "features2": [
        "Umožnite zákazníkom virtuálne preskúmať vaše jedlá pomocou ohromujúcich 3D vizuálov.",
        "Zvýšte zapojenie tým, že poskytnete hosťom realistický náhľad na ich jedlo.",
        "Vyniknite so zážitkom z menu, ktoré je rovnako lákavé, ako chutí vaše jedlo."
    ],
    "features3": [
        "Okamžite privítajte hostí svojim menu, keď vstúpia do reštaurácie.",
        "Automaticky odosielať aktualizácie o špeciálnych akciách a akciách na ich telefóny.",
        "Vylepšite zážitok zo stolovania rýchlym, bezkontaktným prístupom k vášmu menu."
    ],
    "feature4": [
        "Zvýraznite sezónne ponuky, denné špeciálne ponuky a exkluzívne akcie.",
        "Upútajte pozornosť zákazníkov na svoje najobľúbenejšie položky.",
        "Vytvorte naliehavosť a vzrušenie pomocou pútavých vizuálov a popisov."
    ],
    "feature5": [
        "Staňte sa súčasťou online komunity s tisíckami návštevníkov denne.",
        "Zvýšte viditeľnosť a oslovte nových zákazníkov, ktorí aktívne hľadajú možnosti stravovania.",
        "Zaistite si prvé miesto včas, aby ste maximalizovali svoju expozíciu a prilákali viac hostí."
    ],
    "section3_heading": "Prečo ísť na digitál? Pretože je to zadarmo a jednoduché!",
    "section3_description": "Odomknite výkonné funkcie navrhnuté na zvýšenie príťažlivosti vašej reštaurácie.",
    "instant_menu": "Okamžité menu",
    "instant_menu_notification": "Vyskakovacie okno a upozornenie",
    "instant_menu_description": "Privítajte hostí s okamžitým prístupom k menu hneď, ako vstúpia. Automaticky posielať najnovšie menu a špeciálne ponuky priamo na ich mobilné telefóny",
    "digital_menu": "Digitálne menu",
    "at_zero_cost": "za nulové náklady",
    "digital_menu_description1": "Rozlúčte sa s papierovými menu a drahými dotlačami. Prepnite na digitálne menu, ktoré je vždy aktuálne – a je navždy zadarmo!",
    "digital_menu_description2": "Vďaka našej digitálnej ponuke sú vaše ponuky vždy aktuálne a dostupné na akomkoľvek zariadení. Ušetrite peniaze a zjednodušte aktualizácie menu s bezproblémovým riešením s nulovými nákladmi.",
    "try_me_button": "Skúste ma",
    "try_3d_menu_title": "3D menu",
    "try_3d_menu_title2": "Zadarmo — WOW!",
    "try_3d_menu_description1": "Nechajte svoj riad vyskočiť z obrazovky. Nahrajte 3D obrázky a umožnite zákazníkom virtuálne ochutnať vaše jedlo predtým, ako vstúpia.",
    "try_3d_menu_description2": "Vysokokvalitné 3D obrázky vytvárajú pútavý náhľad, zvyšujúci príťažlivosť a povzbudzujúcu objednávku.",
    "try_now": "Skúste teraz",
    "showcase_title": "Predveďte svoje",
    "showcase_specials": "Špeciálne ponuky",
    "showcase_specials_description1": "Máte lákavú ponuku alebo bezkonkurenčnú propagáciu? Zvýraznite to vo svojom digitálnom menu a prilákajte viac hladných ľudí.",
    "showcase_specials_description2": "Nenechajte si ujsť propagácie, upútajte pozornosť odvážnymi vizuálmi a lákavými popismi.",
    "marketplace": "Trhovisko",
    "marketplace_description": "Vytvárame trh s viac ako 2 000 návštevníkmi denne. Teraz nastúpte a budete prvý v rade, keď spustíme. Môžete osloviť nových zákazníkov, ktorí aktívne hľadajú možnosti stravovania. Zabezpečte si svoje miesto včas, aby ste maximalizovali expozíciu!",
    "see_other_products": "Pozrite si Iné produkty",
    "about_us": "O nás",
    "why_serving_free": "Prečo to servírujeme",
    "serving_free_highlight": "Zadarmo",
    "about_desc": "Sme miestni ľudia priamo tu v Dallase, ktorí sa zaviazali pomáhať reštauráciám, ako je tá vaša, aby prosperovali.",
    "about_tool_free": "Tento výkonný nástroj ponúkame úplne zadarmo, pretože veríme v podporu našej komunity.",
    "no_catch": "Žiadny úlovok, žiadna sranda. Vieme, že keď uspejete, naša komunita sa posilní. 😊",
    "understanding_challenges": "Rozumieme výzvam podnikania, a preto sme odhodlaní poskytovať nástroje, vďaka ktorým budú vaše operácie plynulejšie a váš dosah širší.",
    "community_growth": "Ako rastiete, naša komunita rastie s vami a vytvára dominový efekt úspechu a podpory. Spoločne pozdvihnime kulinársky zážitok pre všetkých.",
    "how_it_works": "Ako to funguje",
    "three_simple_steps": "Tri jednoduché kroky",
    "get_started_highlight": "Začíname",
    "no_tech_skills_needed": "Nie sú potrebné žiadne technické zručnosti. Ak viete otočiť burger, dokážete to!",
    "step1_title": "Zobrazte svoje promo ZDARMA!",
    "step1_desc": "Začnite rýchlejšie, ako stihnete povedať „Objednať!“ Stačí vyplniť jednoduchý formulár.",
    "step2_title": "Prispôsobte si svoje menu",
    "step2_desc": "Pridajte svoje jedlá, odovzdajte fotografie aj v 3D a zvýraznite svoje špeciality.",
    "step3_title": "Zdieľajte a rásť",
    "step3_desc": "Zdieľajte svoje digitálne menu prostredníctvom QR, sociálnych médií alebo svojej webovej stránky.",
    "testimonials": "Posudky",
    "testimonials_section_title": "Neberte len naše slovo",
    "testimonials_section_desc": "Pozrite sa, čo hovoria iní majitelia reštaurácií v Dallase.",
    "faq_section_title": "Máte otázky? Máme odpovede",
    "faq_section_desc": "Všetko, čo potrebujete vedieť, než začnete.",
    "faqs": "často kladené otázky",
    "faq_answers": [
        {
            "que": "Je to naozaj navždy zadarmo?",
            "ans": "Absolútne! Žiadne skryté poplatky, žiadne kreditné karty, žiadne obmedzenia."
        },
        {
            "que": "Potrebujem nejaké technické know-how?",
            "ans": "Ak môžete poslať e-mail, môžete to urobiť. Je to také jednoduché."
        },
        {
            "que": "Prečo to ponúkaš zadarmo?",
            "ans": "Veríme v dávanie ako prvé. Ďalej môžeme mať ďalšie služby, ktoré sa vám budú páčiť – ale to je úplne na vás."
        },
        {
            "que": "Ako je to s trhom?",
            "ans": "Varíme to! Keď budeme mať na palube dostatok reštaurácií, spustíme váš trh, aby ste sa ešte viac zviditeľnili."
        }
    ],
    "testimonials_content1": "Prechod na digitálne menu bol najlepší krok, aký sme urobili! Naši zákazníci milujú pohodlie a aktualizovaný vzhľad!",
    "testimonials_name1": "Morris George",
    "testimonials_restaurant_name1": "Reštaurácia Mansion",
    "testimonials_content2": "Naša nová digitálna ponuka uľahčuje hosťom prehliadanie a skutočne zvyšuje zapojenie!",
    "testimonials_name2": "Amelia Johnsonová",
    "testimonials_restaurant_name2": "Urban Eats",
    "testimonials_content3": "Hostia sú ohromení 3D vizuálmi – pomohlo nám to vyniknúť a zlepšilo ich zážitok z jedla!",
    "testimonials_name3": "Davis Green",
    "testimonials_restaurant_name3": "Mestský krb",
    "ready_to_boost_your_restaurant": "Ste pripravení posilniť svoju reštauráciu?",
    "ready_to_boost_desc": "Nezostávajte pozadu. Pripojte sa k digitálnej revolúcii ešte dnes!",
    "yes_i_want_my_free_digital_menu": "Áno, chcem svoje bezplatné digitálne menu",
    "480_plus": "480+",
    "people_already_joined": "ľudia sa už pripojili",
    "menu_online_home": "Menu online Domov",
    "welcome": "Vitajte",
    "description_other": "Zvýšme spokojnosť vašich zákazníkov!",
    "benefits": "Výhody",
    "greeting_text": "Vitajte 👋",
    "all_in_one_features": "🚀 Funkcie typu všetko v jednom na pozdvihnutie vašej reštaurácie",
    "menu_online_menu_name": "Menu online",
    "menu_online_description": "Digitalizujte svoje menu pre jednoduchý online prístup pre zákazníkov.",
    "menu_online_benefits": "Zvýšte pohodlie zákazníkov a zvýšte zapojenie tým, že im umožníte zobraziť si vašu ponuku kedykoľvek a kdekoľvek.",
    "ds_name": "Digital Signage",
    "ds_description": "Prezentujte ponuky a propagačné akcie na dynamických obrazovkách v obchode.",
    "ds_benefits": "Upútajte pozornosť zákazníkov pomocou pútavých vizuálov, propagácie upsells a ponúk v reálnom čase bez námahy.",
    "loyalty_punch_name": "Vernostná karta",
    "loyalty_punch_description": "Odmeňte opakovaných zákazníkov digitálnym vernostným programom.",
    "loyalty_punch_benefits": "Podporte udržanie zákazníkov a zvýšte predaj podnecovaním lojality pomocou odmien za časté návštevy.",
    "marketing_menu_name": "marketing",
    "marketing_description": "Oslovte viac zákazníkov pomocou cielených digitálnych marketingových kampaní.",
    "marketing_benefits": "Rozšírte dosah svojej reštaurácie a zvýšte návštevnosť pomocou vlastných propagačných akcií prispôsobených na prilákanie nových a opakovaných zákazníkov.",
    "get_more_reviews_menu_name": "Získajte viac recenzií",
    "get_more_reviews_description": "Povzbudzujte spokojných zákazníkov, aby zanechali pozitívne online recenzie.",
    "get_more_reviews_benefits": "Zvýšte dôveryhodnosť svojej reštaurácie a prilákajte nových hostí zlepšením svojej online reputácie o pozitívnejšie recenzie.",
    "guest_smart_wifi_menu_name": "Inteligentné Wifi pre hostí",
    "guest_smart_wifi_description": "Ponúknite bezplatné Wi-Fi na zaznamenávanie údajov hostí na marketing.",
    "guest_smart_wifi_benefits": "Zvýšte spokojnosť zákazníkov pri zhromažďovaní cenných údajov o zákazníkoch na prispôsobenie budúcich akcií.",
    "reservation_menu_name": "Rezervácia",
    "reservation_description": "Umožnite zákazníkom jednoducho si rezervovať stoly online.",
    "reservation_benefits": "Zlepšite kulinársky zážitok minimalizovaním čakacích dôb a zefektívnite rezervácie, aby ste lepšie spravovali sedenie.",
    "pos_integration_menu_name": "Integrácia POS",
    "pos_integration_description": "Integrujte náš systém s vašim POS systémom.",
    "pos_integration_benefits": "Zjednodušte spracovanie objednávok a znížte počet chýb tým, že budete svoje online systémy a systémy v obchode synchronizovať.",
    "online_ordering_menu_name": "Online objednávka",
    "online_ordering_description": "Umožnite zákazníkom zadávať objednávky priamo z vášho webu alebo aplikácie.",
    "online_ordering_benefits": "Zvýšte predaj a znížte závislosť na platformách tretích strán tým, že ponúknete možnosť priameho objednávania, ktorá šetrí poplatky.",
    "pro_label": "Pro",
    "coming_soon": "Už čoskoro",
    "locked_label": "Zamknuté",
    "forever_label": "navždy",
    "new_label": "Nové",
    "other_products_page_title": "Ostatné produkty | Menu online",
    "other_products_description": "Menu Zásady ochrany osobných údajov online",
    "other_products_header_title": "Ostatné produkty",
    "privacy_policy": {
        "title": "Zásady ochrany osobných údajov",
        "lastUpdated": "Posledná aktualizácia: 1. júla 2024",
        "introduction": {
            "header": "Zásady ochrany osobných údajov pre nových a existujúcich zákazníkov",
            "paragraph1": "NASLEDUJÚCE KĽÚČOVÉ BODY (VRÁTANE REFERENCIÍ SEKCIÍ) SÚ POSKYTOVANÉ LEN PRE VAŠE POHODLIE A NAHRAĎUJÚ ÚPLNÉ ZMLUVNÉ PODMIENKY. JE VAŠOU ZODPOVEDNOSŤOU PREČÍTAŤ SI TIETO ZMLUVNÉ PODMIENKY PRED POUŽITÍM SLUŽBY MENUONLINE INC.",
            "paragraph2": "Používanie Menuonline znamená, že súhlasíte so Zmluvnými podmienkami a vyhlasujete a zaručujete, že ste na to oprávnení vašou organizáciou alebo spoločnosťou. (Pozri časť 3 „Ako to funguje“)",
            "paragraph3": "Menuonline Inc je marketingová služba založená na povoleniach. Zámerné odosielanie „spamu“ alebo porušenie Podmienok bude mať za následok zatvorenie vášho účtu. (Pozri časť 16 „Prijateľné používanie služby“)",
            "paragraph4": "Údaje, ktoré hosťujete v Menuonline Inc, ako sú záznamy kontaktov, obsah e-mailov a informácie, ktoré zhromažďujete prostredníctvom Menuonline Inc, patria vám. (Pozri s.14 „Čo vlastníte?“).",
            "paragraph5": "Softvér Menuonline Inc a obsah našej webovej stránky patria nám. (Pozri s.13 „Čo vlastníme?“) Okrem vašich vlastných používateľov si údaje alebo nezverejnený obsah, ktorý hosťujete v Menuonline Inc, môžu prezerať iba zamestnanci a partneri Menuonline Inc, ktorí podpísali zmluvy o dôvernosti. (Pozri s.14 „Čo vlastníte?“)",
            "paragraph6": "Všetky osobné údaje (PII) sa uchovávajú iba na serveroch umiestnených v Kanade. (Pozri s.14 „Čo vlastníte?“).",
            "paragraph7": "Služba Menuonline Inc podlieha zákonom Kanady a provincie Ontario. (Pozri s.24 „Rozhodné právo“)",
            "paragraph8": "Akékoľvek žiadosti o vaše údaje alebo obsah od jednotlivcov, polície alebo iných regulačných úradov z Kanady alebo mimo Kanady budú vždy postúpené vám. Jedinou výnimkou by bolo, ak by sme tým porušili kanadské alebo ontárijské právo; vo všetkých prípadoch však budeme tvrdo odolávať akémukoľvek uvoľneniu vašich údajov kýmkoľvek okrem vás. (Pozri s.14 „Čo vlastníte?“).",
            "paragraph9": "Bezpečnosť Menuonline Inc je len taká dobrá, ako dobrá je vaša správa používateľských mien a hesiel. Za používateľské účty Menuonline Inc neúčtujeme poplatky, preto vytvorte pre každého používateľa samostatný a heslá uchovávajte v tajnosti. Za svoj účet zodpovedáte vy. (Pozri časť 3 „Ako to funguje“)",
            "paragraph10": "Svoj účet môžete kedykoľvek zrušiť. V prípade zrušenia neposkytujeme vrátenie peňazí za nevyužitý kredit. (Pozri časť 3 „Ako to funguje“)"
        },
        "termsAndConditions": {
            "title": "PODMIENKY MENUONLINE",
            "lastUpdate": "POSLEDNÁ AKTUALIZÁCIA: 14. januára 2020",
            "paragraph1": "Pred použitím tejto webovej stránky (ďalej len „stránka“) si pozorne prečítajte tieto zmluvné podmienky (ďalej len „Podmienky“). Tieto podmienky oslobodzujú spoločnosť Menuonline Inc. („Menuonline Inc“ „my“ alebo „nás“) a iných zo zodpovednosti a\/alebo obmedzujú našu a ich zodpovednosť a obsahujú ďalšie dôležité ustanovenia, ktoré sa vzťahujú na vaše používanie tejto stránky. Návštevou alebo používaním tejto stránky alebo služby súhlasíte vo svojom vlastnom mene a v mene akejkoľvek organizácie, v mene ktorej môžete konať (ďalej spoločne len „vy“), že pri každom použití a každej návšteve tejto stránky prijmete a budete sa nimi riadiť."
        },
        "services": {
            "title": "Naše služby.",
            "paragraph": "Služby, ktoré ponúkame prostredníctvom Stránky službu, ktorá vám umožňuje vytvárať, odosielať a spravovať e-mailové správy („E-maily“) príjemcom podľa vášho výberu („Služba“)."
        },
        "license": {
            "title": "Licencia.",
            "paragraph": "V súlade s týmito podmienkami vám spoločnosť Menuonline Inc týmto udeľuje nevýhradnú, obmedzenú a neprenosnú licenciu počas obdobia na prezeranie stránky a používanie služby tak, ako to umožňujú funkcie služby. Vyhradzujeme si všetky práva, ktoré tu nie sú výslovne udelené v rámci Služby a Obsahu Menuonline Inc (ako je definované nižšie)."
        },
        "emailingContent": {
            "title": "Obsah odosielania e-mailov.",
            "paragraph": "Pri interakcii s Menuonline Inc prostredníctvom tejto stránky alebo služby súhlasíte s poskytnutím pravdivých a presných informácií. Ďalej chápete, že poskytovanie dôverných informácií prostredníctvom e-mailu je nebezpečný postup. Beriete na vedomie, že nesiete výhradnú zodpovednosť za všetko, čo pošlete komukoľvek, kto používa našu službu."
        },
        "support": {
            "title": "Podpora.",
            "paragraph": "Poskytujeme vám podporu, ktorá vám pomôže stať sa sebestačnými so Službou. Poskytujeme vám podporu vo forme dokumentácie a videí na Stránke, v systéme lístkov podpory Menuonline Inc a telefonicky. Môžete nás kontaktovať kliknutím na kartu „Podpora“ v hornej časti stránky. Za podporu produktu sa neúčtuje žiadny poplatok; vyhradzujeme si však právo prerušiť podporu pre konkrétne klientske účty podľa nášho uváženia."
        }
    },
    "terms_condition": {
        "title": "Podmienky služby | Menu online",
        "header": "Podmienky pre nových a existujúcich zákazníkov"
    },
    "privacy_policy_title": "SÚKROMNÁ POLITIKA",
    "privacy_policy_effective_date": "Účinné od 16. januára 2020",
    "privacy_policy_general_title": "VŠEOBECNÉ SÚKROMNÉ ZÁSADY",
    "privacy_policy_intro": "menuonline.com (Menuonline a „nás“) chápe dôležitosť ochrany súkromia osobných údajov jednotlivcov a dôležitosť bezpečnosti dôverných informácií všetkých typov. Vytvorili sme túto súkromnú politiku (ďalej len „zásady“), aby sme demonštrovali náš záväzok chrániť vaše „súkromie“. Táto súkromná politika vysvetľuje:",
    "privacy_policy_points": [
        "Aké osobné údaje zhromažďujeme a prečo ich zhromažďujeme;",
        "Ako používame vaše osobné údaje;",
        "S kým zdieľame vaše osobné údaje; a",
        "Možnosti, ktoré máte na prístup, aktualizáciu a odstránenie vašich osobných údajov zo služby."
    ],
    "privacy_policy_pipeda": "Menuonline spravuje zhromažďovanie všetkých informácií v súlade s kanadským zákonom o ochrane osobných údajov a elektronických dokumentoch (PIPEDA).",
    "privacy_policy_update_notice": "Upozorňujeme, že Menuonline si vyhradzuje právo kedykoľvek aktualizovať alebo zmeniť tieto súkromné ​​zásady. O akýchkoľvek zmenách vás budeme informovať zverejnením aktualizovaných Zásad na tejto webovej stránke alebo tak, ako budú dostupné prostredníctvom služieb. Najaktuálnejšia verzia týchto Zásad bude upravovať používanie osobných údajov používateľov spoločnosťou mycircle. Pokračovaním v prístupe na webovú stránku mycircle alebo pokračovaním v používaní jej služieb po vykonaní zmien v týchto súkromných zásadách súhlasíte s tým, že budete viazaní upravenými podmienkami.",
    "privacy_policy_consent_title": "SÚHLAS PRE OSOBNÉ INFORMÁCIE",
    "privacy_policy_consent_text": "Poskytnutím svojich osobných údajov Menuonline prostredníctvom našej webovej stránky alebo ktoréhokoľvek z našich formulárov súhlasíte so zhromažďovaním, používaním a zverejňovaním takýchto informácií v súlade s týmito Zásadami alebo na účely, ktoré ste identifikovali v čase, keď ste osobné údaje poskytli. Akékoľvek sekundárne použitie osobných údajov bude spracované iba s vaším výslovným súhlasom, alebo vám bude poskytnutá možnosť odmietnuť.",
    "privacy_policy_opt_out_text": "Svoj súhlas máte možnosť kedykoľvek odvolať alebo sa odhlásiť. Ak chcete odvolať svoj súhlas alebo sa odhlásiť, môžete kliknúť na odkaz predvoľby komunikácie v spodnej časti každého e-mailu, ktorý od nás dostanete.",
    "privacy_policy_info_collection_title": "INFORMÁCIE, KTORÉ Zhromažďujeme",
    "privacy_policy_info_collection_text": "Menuonline zhromažďuje a spracúva osobné údaje v súlade s týmito Zásadami. Tieto informácie nebudeme predávať, zdieľať ani prenajímať iným osobám, s výnimkou prípadov uvedených v týchto zásadách. Menuonline zhromažďuje osobné údaje na účely poskytovania našich služieb alebo produktov vám.",
    "privacy_policy_info_types_text": "Typy informácií, ktoré Menuonline zhromažďuje, zahŕňajú:",
    "privacy_policy_account_holders_title": "Majitelia účtov Menuonline",
    "privacy_policy_registration_info": "Registračné informácie: Ak sa prihlásite na používanie služieb, potrebujete účet Menuonline, aby ste sa stali majiteľom účtu (ďalej len „majiteľ účtu“). Pri registrácii účtu budete požiadaní o poskytnutie určitých základných informácií, ako je vaše meno, e-mailová adresa, používateľské meno, heslo, názov spoločnosti, povolanie, miesto a telefónne číslo. Heslá sú šifrované – personál Menuonline nedokáže zobraziť ani získať heslá.",
    "privacy_policy_billing_info": "Fakturačné údaje: Ak si predplatíte naše služby, budete nám musieť poskytnúť platobné a fakturačné údaje.",
    "privacy_policy_cookies_info": "Súbory cookie: Súbory cookie používame na sledovanie údajov o návštevníkoch, ako je zdroj návštev webovej lokality a počet návštev webovej lokality na našej webovej lokalite.",
    "privacy_policy_ip_info": "IP adresy: IP adresy sa o vás zhromažďujú ako súčasť vášho používania služieb (napr. na účely zaznamenávania polohy na úrovni krajiny a IP adries používaných pri používaní našich služieb).",
    "privacy_policy_your_contacts_title": "Vaše kontakty",
    "privacy_policy_distribution_list": "Distribučný zoznam: V rámci vášho používania našich služieb nám môžete poskytnúť alebo môžeme v rámci služieb zhromažďovať osobné údaje o vašich kontaktoch („Kontakty“). Tieto informácie zahŕňajú e-mailové adresy a môžu zahŕňať ďalšie identifikovateľné informácie, ako je meno, názov spoločnosti alebo iné informácie, ktoré poskytnete spoločnosti Menuonline pri používaní služieb. Musíte zabezpečiť, aby ste od svojich kontaktov získali príslušný súhlas, aby sme umožnili spracovanie ich údajov.",
    "privacy_policy_billing_info_contacts": "Fakturačné údaje: Ak si predplatíte naše služby, budete nám musieť poskytnúť platobné a fakturačné údaje.",
    "privacy_policy_cookies_contacts": "Súbory cookie: Spoločnosť Menuonline bude používať súbory cookie a podobné technológie sledovania na zhromažďovanie a používanie osobných údajov o vašich kontaktoch iba vtedy, ak ste na svoju webovú stránku pridali kód sledovania Menuonline. Všetky vstupné stránky hostené službou Menuonline budú mať automaticky kód sledovania.",
    "privacy_policy_ip_contacts": "IP adresy: IP adresy sa zhromažďujú o vašich kontaktoch ako súčasť služieb (napr. na účely zaznamenania polohy na úrovni krajiny a IP adries použitých pri odoslaní formulára a poskytnutí súhlasu).",
    "privacy_policy_info_about_contacts_title": "INFORMÁCIE, KTORÉ ZHROMAŽDUJEME O VAŠICH KONTAKTOCH",
    "privacy_policy_info_about_contacts_notice": "*Táto časť sa vzťahuje iba na informácie zhromaždené, použité alebo zverejnené spoločnosťou Menuonline o kontaktoch majiteľa účtu na účely poskytovania služieb*",
    "privacy_policy_info_about_contacts_desc": "Spoločnosť Menuonline bude zhromažďovať, používať alebo zverejňovať osobné údaje o Kontaktoch iba vtedy, ak sa majiteľ účtu zaregistroval pre určité služby, v ktorých nám poskytuje distribučný zoznam. Majitelia účtov sú výlučne zodpovední za to, že pri poskytovaní osobných údajov o svojich Kontaktoch spoločnosti Menuonline prostredníctvom služieb dodržiavajú všetky príslušné zákony.",
    "privacy_policy_info_auto_collect_title": "Informácie Menuonline automaticky zhromažďuje",
    "privacy_policy_info_auto_collect_desc1": "Keď sa kontakty majiteľa účtu zapoja do e-mailovej kampane prijatej od majiteľa účtu alebo interagujú s odkazom na prepojenú webovú stránku majiteľa účtu, spoločnosť Menuonline môže zhromažďovať informácie o zariadení takéhoto kontaktu a interakcii s e-mailom pomocou súborov cookie. Tieto informácie sa zhromažďujú iba za účelom poskytovania služieb a poskytnutia prehľadu majiteľovi účtu.",
    "privacy_policy_info_auto_collect_desc2": "Informácie, ktoré zhromažďujeme o zariadení kontaktu a aplikáciách, ktoré kontakt používa na prístup k e-mailom odoslaným od majiteľa účtu prostredníctvom našich služieb, môžu zahŕňať adresu IP kontaktu, operačný systém, ID prehliadača a ďalšie súvisiace informácie o systéme a pripojení kontaktu.",
    "privacy_policy_info_auto_collect_desc3": "Informácie, ktoré zhromažďujeme o interakcii Kontaktu s e-mailami odoslanými prostredníctvom služieb, môžu zahŕňať dátumy a časy, kedy Kontakt pristupuje k e-mailom a aktivitám prehliadania, a informácie týkajúce sa výkonu služieb, ako je napríklad doručovanie e-mailov a inej komunikácie odoslanej Kontaktu majiteľom účtu prostredníctvom služieb.",
    "privacy_policy_info_auto_collect_desc4": "Upozorňujeme, že spoločnosť Menuonline nebude kontaktovať žiadny z vašich kontaktov, pokiaľ to nevyžaduje príslušný zákon.",
    "privacy_policy_why_we_collect_title": "PREČO Zhromažďujeme VAŠE INFORMÁCIE",
    "privacy_policy_why_we_collect_desc": "Menuonline zhromažďuje informácie na nasledujúce účely:",
    "privacy_policy_why_collect_1": "poskytovať majiteľom účtov Menuonline služby, ktoré si predplatia, vrátane používania informácií o držiteľovi účtu pre služby;",
    "privacy_policy_why_collect_2": "pre správu a správu účtu, ako aj prístup na webovú stránku mycircle;",
    "privacy_policy_why_collect_3": "ak ste súhlasili s prijímaním marketingových a propagačných materiálov, informovať vás o ďalších produktoch a službách ponúkaných spoločnosťou Menuonline; a",
    "privacy_policy_why_collect_4": "reagovať na oznámenia majiteľa účtu alebo posielať informácie, ktoré požadujete. To môže zahŕňať, ale nie je obmedzené na:",
    "privacy_policy_why_collect_5": "špeciálne ponuky a aktualizácie;",
    "privacy_policy_why_collect_6": "newsletter; a",
    "privacy_policy_why_collect_7": "servisné oznámenia.",
    "privacy_policy_opt_out_desc": "Kedykoľvek sa môžete rozhodnúť odhlásiť sa z prijímania správ kliknutím na tlačidlo odhlásenia v spodnej časti každého e-mailu zaslaného z Menuonline.",
    "privacy_policy_use_disclosure_title": "POUŽÍVANIE A ZVEREJŇOVANIE OSOBNÝCH ÚDAJOV",
    "privacy_policy_use_disclosure_desc1": "Osobné informácie odoslané spoločnosti Menuonline, a to aj prostredníctvom webovej stránky spoločnosti Menuonline alebo predpísaného formulára, sú prísne dôverné, pokiaľ nebudú zverejnené v súlade s výslovným súhlasom majiteľa účtu alebo zákonnými požiadavkami. V každom prípade sú akékoľvek osobné údaje poskytnuté na akejkoľvek platforme „Menuonline“ majiteľmi účtov dobrovoľné: akékoľvek osobné údaje poskytnuté majiteľom účtu sú poskytnuté podľa uváženia majiteľa účtu. Upozorňujeme, že odvolanie vášho súhlasu môže viesť k tomu, že nebudete môcť pristupovať alebo využívať niektoré služby Menuonline.",
    "privacy_policy_use_disclosure_desc2": "Spoločnosť Menuonline využíva tretie strany a externých poskytovateľov služieb, pokiaľ ide o jej webové stránky a služby. Osobné údaje majiteľa účtu môžu byť zdieľané s týmito tretími stranami a poskytovateľmi. Môžeme tiež poskytovať súhrnné štatistiky o majiteľoch účtov, predajoch, vzorcoch návštevnosti a súvisiace informácie renomovaným tretím stranám. Menuonline vyžaduje, aby každý poskytovateľ tretej strany, ktorý má prístup k osobným údajom, bol viazaný povinnosťami v súlade s týmito Zásadami ochrany osobných údajov a aby títo poskytovatelia zhromažďovali, používali alebo zverejňovali osobné údaje majiteľa účtu len na účely poskytovania služieb Menuonline.",
    "privacy_policy_use_disclosure_desc3": "Osobné údaje poskytnuté majiteľmi účtov nebudú predané, zdieľané ani prenajaté externým organizáciám, pokiaľ to nebude v súlade s vaším implicitným alebo výslovným súhlasom. Menuonline si vyhradzuje právo kontaktovať ktoréhokoľvek Majiteľa účtu za účelom preposielania informácií, odpovedí na otázky alebo poskytovania upozornení na akékoľvek zmeny nášho programu alebo našich zásad. Upozorňujeme, že Menuonline nebude kontaktovať žiadny z vašich kontaktov, pokiaľ to nevyžaduje príslušný zákon.",
    "privacy_policy_use_disclosure_desc4": "Menuonline zverejní osobné údaje iba v prípade, že k tomu dá súhlas príslušná osoba alebo:",
    "privacy_policy_disclosure_list_1": "ak to povoľuje alebo vyžaduje zákon, napríklad v reakcii na predvolanie alebo iný právny proces;",
    "privacy_policy_disclosure_list_2": "na nadobúdateľa celej alebo časti Menuonline;",
    "privacy_policy_disclosure_list_3": "poskytovateľom služieb Menuonline v súlade s týmito Zásadami; alebo",
    "privacy_policy_disclosure_list_4": "na splnenie zákonných povinností vrátane, ale nie výlučne, regulačných povinností týkajúcich sa podávania správ.",
    "privacy_policy_service_providers_title": "Menuonline využíva nasledujúce tretie strany a poskytovateľov služieb (osobné údaje vašich Kontaktov nie sú „takým“ tretím stranám poskytnuté):",
    "privacy_policy_service_provider_1": "ManyChat používame na poskytovanie internetových chatových služieb na predaj a podporu, ako aj na následné e-maily o týchto konverzáciách.",
    "privacy_policy_service_provider_2": "AskNicely používame na prieskum našich zákazníkov s cieľom opýtať sa na kvalitu služieb a podpory, ktorú dostávajú.",
    "privacy_policy_service_provider_3": "Používame join.me na plánovanie stretnutí so zákazníkmi a potenciálnymi zákazníkmi, hovorov podpory a ukážok softvéru.",
    "privacy_policy_service_provider_4": "Službu Google Analytics používame na sledovanie anonymných webových aktivít a meranie vzorov používania na našich webových stránkach.",
    "privacy_policy_breach_notification_title": "OZNÁMENIE O PORUŠENÍ",
    "privacy_policy_breach_notification_desc": "Spoločnosť Menuonline bude dodržiavať a poskytovať majiteľom účtov primeranú pomoc v súlade s platnými zákonmi, pokiaľ ide o akékoľvek neoprávnené použitie, prístup alebo zverejnenie osobných údajov.",
    "privacy_policy_rights_access_title": "VAŠE PRÁVA A PRÍSTUP",
    "privacy_policy_rights_access_desc": "Máte právo na prístup k osobným údajom, ktoré o vás Menuonline spracúva. Môžete si od nás vyžiadať informácie o:",
    "privacy_policy_rights_access_list_1": "Osobné údaje, ktoré o vás uchovávame",
    "privacy_policy_rights_access_list_2": "Kategórie dotknutých osobných údajov",
    "privacy_policy_rights_access_list_3": "Účely spracovania",
    "privacy_policy_rights_access_list_4": "Podrobnosti o tom, komu sú\/budú poskytnuté vaše osobné údaje",
    "privacy_policy_rights_access_list_5": "Ako dlho uchovávame vaše osobné údaje",
    "privacy_policy_rights_access_list_6": "Ak sme informácie nezískali priamo od vás, informácie o zdroji",
    "privacy_policy_rights_access_list_7": "Ako podať sťažnosť správnemu dozornému orgánu",
    "privacy_policy_rights_access_list_8": "Môžete od nás požadovať aj nasledovné:",
    "privacy_policy_rights_access_list_9": "Že aktualizujeme akékoľvek neúplné alebo nepresné informácie o vás",
    "privacy_policy_rights_access_list_10": "Požiadajte o vymazanie vašich osobných údajov v súlade s platnými zákonmi",
    "privacy_policy_rights_access_contact": "Môžete nás požiadať, aby sme postupovali proti vašim právam tak, že nás kontaktujete na adrese 2851 Dufferin Street, Toronto, ON, Kanada, alebo nám pošlete e-mail na adresu {link}.",
    "privacy_policy_accountability_title": "ZODPOVEDNOSŤ",
    "privacy_policy_accountability_desc": "Menuonline sa zaväzuje niesť zodpovednosť za osobné a dôverné informácie, ktoré nám poskytnete. Kontaktné informácie nájdete na konci týchto zásad.",
    "privacy_policy_links_other_sites_title": "ODKAZY NA INÉ STRÁNKY",
    "privacy_policy_links_other_sites_desc1": "Webová stránka Menuonline môže obsahovať odkazy na iné stránky. Prosím, uvedomte si, že Menuonline nezodpovedá za praktiky ochrany osobných údajov iných stránok. Odporúčame našim majiteľom účtov, aby si boli vedomí toho, že keď opustia našu stránku, mali by si dôkladne prečítať vyhlásenie o súkromných zásadách každej webovej lokality, ktorá zhromažďuje osobné údaje.",
    "privacy_policy_links_other_sites_desc2": "Tieto zásady ochrany osobných údajov sa vzťahujú výlučne na nami zhromaždené informácie.",
    "privacy_policy_links_other_sites_desc3": "Ak na nákup produktov alebo služieb použijete odkaz na prechod zo stránky Menuonline, dokončujete nákupnú transakciu podľa pravidiel a predpisov spoločnosti predajcu, nie podľa pravidiel Menuonline.",
    "privacy_policy_cookies_title": "SÚKROMNÉ POLITICKÉ COOKIES",
    "privacy_policy_cookies_desc1": "Webová stránka a služby Menuonline používajú súbory cookie na zhromažďovanie a ukladanie určitých informácií v súlade s týmito zásadami. „Cookie“ je malý textový súbor odoslaný z webovej stránky a uložený v počítači používateľa prostredníctvom webového prehliadača používateľa. Pri návšteve stránky, ktorá používa súbory cookie, sa do vášho počítača alebo mobilného zariadenia stiahne súbor cookie. Pri ďalšej návšteve tejto stránky si vaše zariadenie zapamätá užitočné informácie, ako sú preferencie, navštívené stránky alebo možnosti prihlásenia.",
    "privacy_policy_cookies_desc2": "Súbory cookie sa široko používajú na pridávanie funkcií na webové stránky alebo na zabezpečenie ich efektívnejšej práce. Naša stránka sa spolieha na súbory cookie na optimalizáciu používateľského zážitku a zabezpečenie správneho fungovania služieb stránok.",
    "privacy_policy_cookies_desc3": "Väčšina webových prehliadačov umožňuje určitú kontrolu na obmedzenie alebo blokovanie súborov cookie prostredníctvom nastavení prehliadača, avšak ak zakážete súbory cookie, možno zistíte, že to ovplyvní vašu schopnosť používať určité časti našej webovej stránky alebo služieb.",
    "privacy_policy_cookies_analytical_purpose": "<strong> Analytické účely: <\/strong> Súbory cookie používame na analýzu aktivity majiteľa účtu s cieľom zlepšiť našu webovú stránku. Súbory cookie môžeme napríklad použiť na zobrazenie súhrnných vzorcov, ako sú funkcie, ktoré používajú majitelia účtov. Takúto analýzu môžeme použiť na získanie informácií o tom, ako zlepšiť funkčnosť a používateľskú skúsenosť našej webovej stránky.",
    "privacy_policy_cookies_preferences_user_experience": "<strong> Vaše preferencie a používateľská skúsenosť: <\/strong> Súbory cookie používame na zhromažďovanie určitých informácií o návštevníkoch a majiteľoch účtov, ako je typ prehliadača, server, jazykové preferencie a nastavenie krajiny, aby sme uložili preferencie majiteľa účtu na našej webovej stránke, aby bola skúsenosť majiteľa účtu konzistentnejšia a pohodlnejšia. Súbory cookie používame na udržanie vášho stavu prihlásenia, keď opakovane navštívite našu webovú stránku.",
    "privacy_policy_cookies_targeted_ads": "<strong> Poskytovanie služieb merania a lepšie zacielené reklamy a marketing: <\/strong> Používame súbory cookie, webové majáky a ďalšie technológie ukladania od partnerov tretích strán, ako sú Google a Facebook, na služby merania, lepšie zacielenie reklám a na marketingové účely. To sa deje, keď navštívite našu webovú stránku. Tieto súbory cookie, webové majáky a ďalšie technológie ukladania nám umožňujú zobrazovať vám propagačný materiál Menuonline na iných stránkach, ktoré navštívite na internete.",
    "privacy_policy_security_title": "BEZPEČNOSŤ",
    "privacy_policy_security_desc1": "Menuonline vynakladá úsilie na ochranu dôverných informácií všetkých typov, vrátane osobných informácií, s organizačnými, fyzickými, mechanickými a elektronickými zárukami primeranými citlivosti informácií. Naše servery sa nachádzajú v Kanade a dodržiavajú priemyselné štandardy týkajúce sa bezpečnosti.",
    "privacy_policy_security_desc2": "Upozorňujeme však, že nemôžeme zaručiť, že opatrenia, ktoré dodržiavame, zaručia bezpečnosť informácií.",
    "privacy_policy_retention_title": "ZACHOVANIE",
    "privacy_policy_retention_desc": "Osobné údaje o majiteľoch účtov a kontaktoch uchovávame tak dlho, ako je to potrebné na účely uvedené v týchto zásadách ochrany osobných údajov av súlade s ustanoveniami o uchovávaní v podmienkach používania.",
    "privacy_policy_access_questions_title": "PRÍSTUP, OTÁZKY A OBAVY",
    "privacy_policy_access_questions_desc": "Jednotlivec, ktorý poskytol osobné údaje spoločnosti Menuonline, môže podať písomnú žiadosť o prístup alebo opravu svojich osobných údajov uchovávaných spoločnosťou Menuonline.",
    "terms_obj": {
        "terms_conditions_title": "ZMLUVNÉ PODMIENKY",
        "terms_conditions_subtitle": "Podmienky pre nových a existujúcich zákazníkov",
        "terms_conditions_desc1": "NASLEDUJÚCE KĽÚČOVÉ BODY (VRÁTANE REFERENCIÍ SEKCIÍ) SÚ POSKYTOVANÉ LEN PRE VAŠE POHODLIE A NAHRAĎUJÚ ÚPLNÉ ZMLUVNÉ PODMIENKY. JE VAŠOU ZODPOVEDNOSŤOU PREČÍTAŤ SI TIETO ZMLUVNÉ PODMIENKY PRED POUŽITÍM SLUŽBY Menuonline.",
        "terms_conditions_desc2": "Používanie Menuonline znamená, že súhlasíte so Zmluvnými podmienkami a vyhlasujete a zaručujete, že ste na to oprávnení vašou organizáciou alebo spoločnosťou. (Pozri časť 3 „Ako to funguje“)",
        "terms_conditions_desc3": "Menuonline je marketingová služba založená na povoleniach. Zámerné odosielanie „spamu“ alebo porušenie Podmienok bude mať za následok zatvorenie vášho účtu. (Pozri časť 16 „Prijateľné používanie služby“)",
        "terms_conditions_desc4": "Údaje, ktoré hosťujete v Menuonline, ako sú záznamy kontaktov, obsah e-mailov a informácie, ktoré zhromažďujete prostredníctvom Menuonline, patria vám. (Pozri s.14 „Čo vlastníte?“).",
        "terms_conditions_desc5": "Softvér Menuonline a obsah našej webovej stránky patria nám. (Pozri s.13 „Čo vlastníme?“)",
        "terms_conditions_desc6": "Okrem vašich vlastných používateľov si údaje alebo nezverejnený obsah, ktorý hosťujete v Menuonline, môžu prezerať iba zamestnanci a partneri Menuonline, ktorí podpísali zmluvy o dôvernosti. (Pozri s.14 „Čo vlastníte?“).",
        "terms_conditions_desc7": "Všetky osobné údaje (PII) sa uchovávajú iba na serveroch umiestnených v Kanade. (Pozri s.14 „Čo vlastníte?“).",
        "terms_conditions_desc8": "Služba Menuonline podlieha zákonom Kanady a provincie Ontario. (Pozri s.24 „Rozhodné právo“)",
        "terms_conditions_desc9": "Akékoľvek žiadosti o vaše údaje alebo obsah od jednotlivcov, polície alebo iných regulačných úradov z Kanady alebo mimo Kanady budú vždy postúpené vám. Jedinou výnimkou by bolo, ak by sme tým porušili kanadské alebo ontárijské právo; vo všetkých prípadoch však budeme tvrdo odolávať akémukoľvek uvoľneniu vašich údajov kýmkoľvek okrem vás. (Pozri s.14 „Čo vlastníte?“).",
        "terms_conditions_desc10": "Zabezpečenie ponuky online je len také dobré, ako dobrá je vaša správa používateľských mien a hesiel. Za užívateľské účty Menuonline neúčtujeme poplatky, preto vytvorte pre každého užívateľa samostatný účet a heslá uchovávajte v tajnosti. Za svoj účet zodpovedáte vy. (Pozri časť 3 „Ako to funguje“)",
        "terms_conditions_desc11": "Svoj účet môžete kedykoľvek zrušiť. V prípade zrušenia neposkytujeme vrátenie peňazí za nevyužitý kredit. (Pozri časť 3 „Ako to funguje“)",
        "terms_conditions_desc12": "Váš účet môžeme kedykoľvek zrušiť. V prípade zrušenia vášho účtu vám vrátime peniaze za e-mailové kredity a nevyužité služby. (Pozri časť 3 „Ako to funguje“)",
        "terms_conditions_desc13": "Vaše údaje budú po zrušení vymazané a môžu byť tiež vymazané, ak sa používateľ účtu neprihlásil 12 mesiacov alebo dlhšie. Na štatistické účely budeme uchovávať súhrnné údaje z vášho účtu, ktoré neobsahujú osobné údaje. (Pozri časť 3 „Ako to funguje“)",
        "terms_conditions_desc14": "E-mailové kredity sa kupujú na princípe priebežného platenia a ich platnosť nikdy nevyprší. Jedinou výnimkou je, ak ste sa na svoj účet neprihlásili 12 mesiacov. V tomto prípade si vyhradzujeme právo vymazať váš účet, vaše údaje a súvisiace kredity. (Pozri časť 10 „Čo platím za Službu?“)",
        "terms_conditions_desc15": "Usilovne pracujeme na tom, aby sme zaistili, že Menuonline je vždy v prevádzke, a aby sme vás vopred informovali, ak potrebujeme dočasne pozastaviť službu z dôvodu údržby. Nezaručujeme však, že služba bude vždy v prevádzke a nezodpovedáme za žiadne straty, ktoré utrpíte používaním Menuonline, vrátane strát na vašej strane, ak systém nefunguje v čase, keď ho potrebujete. (Pozri s.17 „Vylúčenie zodpovednosti“).",
        "terms_conditions_desc16": "Nesledujeme váš obsah ani to, komu posielate správy. Sťažnosti a iné problémy spôsobené vašimi aktivitami však monitorujeme. Príliš veľa sťažností alebo problémov môže mať za následok zrušenie vašej služby podľa nášho uváženia. (Pozri časť 7 „Monitorovanie“)",
        "terms_conditions_desc17": "Zaviazali sme sa poskytovať vynikajúcu produktovú podporu, ktorá vám pomôže stať sa sebestačnými s Menuonline. Poskytujeme podporu vo forme dokumentácie a videa na našej webovej stránke, v systéme lístkov podpory Menuonline a telefonicky. Podpora produktu nie je spoplatnená; vyhradzujeme si však právo prerušiť podporu pre konkrétne klientske účty podľa nášho uváženia. (Pozri časť 5 „Podpora“)",
        "terms_conditions_desc18": "Produktové služby sú pre chvíle, keď chcete, aby sme nejakú prácu súvisiacu s Menuonline robili my, a nie sami. Naša sadzba za produktové služby je 135 USD za hodinu. Poskytujeme aj poradenské služby za vyššie ceny. Vyhradzujeme si právo kedykoľvek zmeniť naše sadzby. (Pozri časť 6 „Produkty a poradenské služby“)",
        "terms_conditions_desc19": "Budeme vás informovať, ak sa domnievame, že došlo k porušeniu vašich údajov, a budeme sa snažiť poskytnúť súvisiace podrobnosti a informácie. (Pozri s.14 „Čo vlastníte?“)",
        "terms_conditions_desc20": "Vyhradzujeme si právo kedykoľvek vykonať zmeny v tejto zmluve. Pokúsime sa vás informovať o zmenách zmluvných podmienok, ale používanie služby znamená, že súhlasíte so zmenami zmluvných podmienok. (Pozri časť 9 „Zmeny“).",
        "terms_conditions_desc21": "Zmluvné podmienky a naše Zásady ochrany osobných údajov boli navrhnuté iba v angličtine a súhlasíte s tým, že anglická verzia Zmluvných podmienok a Zásad ochrany osobných údajov bude mať prednosť v prípade akéhokoľvek konfliktu s akoukoľvek preloženou verziou.",
        "terms_conditions_mycircle": "ZMLUVNÉ PODMIENKY MYCIRCLE",
        "terms_conditions_last_update": "POSLEDNÁ AKTUALIZÁCIA: 14. januára 2020",
        "terms_conditions_usage_agreement": "Pred použitím tejto webovej stránky (ďalej len „Stránka“) si pozorne prečítajte tieto zmluvné podmienky (ďalej len „Podmienky“). Tieto podmienky oslobodzujú spoločnosť Menuonline („Menuonline“, „my“ alebo „nás“) a iné zo zodpovednosti a\/alebo obmedzujú našu a ich zodpovednosť a obsahujú ďalšie dôležité ustanovenia, ktoré sa vzťahujú na vaše používanie tejto stránky.",
        "terms_conditions_acceptance": "Návštevou alebo používaním tejto stránky alebo služby súhlasíte vo svojom vlastnom mene a v mene akejkoľvek organizácie, v mene ktorej môžete konať (ďalej spoločne len „vy“), že pri každom použití a pri každej návšteve tejto stránky prijmete a budete sa nimi riadiť.",
        "terms_conditions_services": "1. Naše služby.",
        "terms_conditions_services_desc": "Služby, ktoré ponúkame prostredníctvom stránky, sú službou, ktorá vám umožňuje vytvárať, odosielať a spravovať e-mailové správy (ďalej len „e-maily“) príjemcom podľa vášho výberu (ďalej len „služba“).",
        "terms_conditions_license": "2. Licencia.",
        "terms_conditions_license_desc": "V súlade s týmito podmienkami vám spoločnosť Menuonline týmto udeľuje nevýhradnú, obmedzenú a neprenosnú licenciu počas Obdobia na prezeranie stránky a používanie služby tak, ako to umožňujú funkcie služby. Vyhradzujeme si všetky práva, ktoré tu nie sú výslovne udelené v rámci Služby a Obsahu Menuonline (ako je definované nižšie).",
        "terms_conditions_account": "3. Ako to funguje.",
        "terms_conditions_account_desc": "Ak chcete používať službu, musíte si zaregistrovať účet. Váš účet je bezplatný. Váš účet vám poskytuje prístup k Službe a „funkciám“, ktoré môžeme z času na čas vytvoriť a udržiavať. Ak chcete mať nárok na účet, musíte:",
        "terms_conditions_account_requirements": [
            "mať aspoň osemnásť (18) rokov a byť schopný uzatvárať zmluvy;",
            "dokončiť proces registrácie, ktorý sme stanovili;",
            "súhlasiť s týmito Podmienkami;",
            "uveďte pravdivé, úplné a aktuálne kontaktné a fakturačné\/platobné údaje."
        ],
        "responsibility": "Zodpovednosť.",
        "responsibilityText": "Ste výhradne zodpovední za aktivitu, ktorá sa vyskytuje na vašom účte, a heslo k účtu musíte uchovávať v tajnosti a bezpečnosti. Účty sú bezplatné, preto vám odporúčame, aby ste si pre každého používateľa zriadili iný účet. Zodpovedáte aj za akýkoľvek účet, ku ktorému máte prístup, bez ohľadu na to, či ste používanie tohto účtu povolili alebo nie. Súhlasíte s tým, že nesiete výhradnú zodpovednosť za svoje interakcie s tými, ktorým posielate e-maily, a že e-maily budete posielať iba tým ľuďom, od ktorých máte súhlas. Musíte nás okamžite informovať o akomkoľvek neoprávnenom použití vašich účtov.",
        "compliance_with_CASL": "Súlad s kanadskou legislatívou proti spamu (CASL).",
        "compliance_with_CASL_text": "Úlohou Menuonline je uľahčiť správu nastavení súhlasu, nie presadzovať legislatívu. Je vašou zodpovednosťou dodržiavať CASL. Pomôžeme vám nastavením predvolenej funkcie a uľahčením získania a aktualizácie zdokumentovaného výslovného súhlasu a spravovaním pohyblivých dátumov uplynutia platnosti implicitného súhlasu. Máte plnú kontrolu nad správou súhlasu pre vašu databázu; môžete kedykoľvek hromadne aktualizovať stav súhlasu pre všetky svoje kontakty, prepísať predvolené nastavenia a zakázať automatické uplynutie platnosti implicitného stavu súhlasu.",
        "closing_account": "Zatvorenie vášho účtu.",
        "closing_account_text": "Vy alebo Menuonline môžete túto zmluvu kedykoľvek a z akéhokoľvek dôvodu ukončiť oznámením druhej strane. Ak poskytnete akékoľvek informácie, ktoré sú nepravdivé, nepresné, neaktuálne alebo neúplné, alebo použijete Službu spôsobom, ktorý porušuje tieto Podmienky, máme právo okamžite pozastaviť váš účet a ukončiť váš prístup k Službe okrem akýchkoľvek iných nápravných opatrení, ktoré máme k dispozícii. Ak nebudete svoj účet používať dvanásť (12) mesiacov, môžeme váš účet zrušiť. Ak z akéhokoľvek dôvodu zrušíme váš účet, vrátime vám všetky kredity, ktoré vám zostali. Po ukončení natrvalo vymažeme váš účet a všetky údaje s ním spojené, vrátane e-mailov, ktoré ste nám poslali. Môžeme uchovávať súhrnné údaje z vášho účtu, ktoré neobsahujú informácie umožňujúce identifikáciu osôb na štatistické účely.",
        "emailing_content": "Obsah odosielania e-mailov.",
        "emailing_content_text": "Pri interakcii s Menuonline prostredníctvom tejto Stránky alebo Služby súhlasíte s poskytnutím pravdivých a presných informácií. Ďalej chápete, že poskytovanie dôverných informácií prostredníctvom e-mailu je nebezpečný postup. Beriete na vedomie, že nesiete výhradnú zodpovednosť za všetko, čo pošlete komukoľvek, kto používa našu službu. účely.",
        "support": "Podpora.",
        "support_text": "Poskytujeme vám podporu, ktorá vám pomôže stať sa sebestačnými so Službou. Poskytujeme vám podporu vo forme dokumentácie a videí na Stránke, v systéme lístkov podpory Menuonline a telefonicky. Môžete nás kontaktovať kliknutím na kartu „Podpora“ v hornej časti stránky. Podpora produktu nie je spoplatnená; vyhradzujeme si však právo prerušiť podporu pre konkrétne klientske účty podľa nášho uváženia.",
        "product_and_consulting_services": "Produktové a poradenské služby.",
        "product_and_consulting_services_text": "Produktové služby sú pre chvíle, keď chcete, aby sme nejakú prácu súvisiacu s Menuonline robili my, a nie sami. Naša sadzba za produktové služby je 125 USD za hodinu. Poskytujeme aj poradenské služby za vyššie ceny. Vyhradzujeme si právo kedykoľvek zmeniť naše sadzby.",
        "monitoring": "Monitorovanie.",
        "monitoring_text": "Nebudeme monitorovať ani posudzovať obsah informácií prenášaných prostredníctvom Služby, ale budeme vyšetrovať, či dostaneme sťažnosti na možné nevhodné použitie. Spoločnosť Menuonline môže kedykoľvek a podľa vlastného uváženia rozhodnúť, či konkrétne použitie je alebo nie je vhodné, s upozornením alebo bez neho, v súlade s nasledujúcimi pokynmi.",
        "term": "Termín.",
        "term_text": "Obdobie sa začína, keď si u nás zaregistrujete účet a pokračuje, pokiaľ používate Službu. Vyhlasujete a zaručujete, že máte oprávnenie a schopnosť zaregistrovať si pre nás účet (vrátane prípadov, keď sa zaregistrujete v mene organizácie).",
        "changes": "Zmeny.",
        "changes_text": "Obdobie sa začína, keď si u nás zaregistrujete účet a pokračuje, pokiaľ používate Službu. Vyhlasujete a zaručujete, že máte oprávnenie a schopnosť zaregistrovať si pre nás účet (vrátane prípadov, keď sa zaregistrujete v mene organizácie).",
        "payment": "PLATBA",
        "what_do_i_pay_for_service": "Čo platím za Službu?.",
        "what_do_i_pay_for_service_text": "Môžete si kúpiť predplatené kredity na odosielanie e-mailu („Kredity“) prostredníctvom našej Služby, ako je to podrobne opísané na stránke „ceny“ našej Stránky. Platnosť kreditov nikdy nevyprší a za tieto kredity sa neúčtujú žiadne opakované poplatky. Jedinou výnimkou je, že ak nebudete svoj účet používať dvanásť (12) mesiacov, môžeme zrušiť váš účet a všetky nevyužité kredity. Naše ceny môžeme kedykoľvek zmeniť aktualizáciou stránok s cenami na našej lokalite, ktorá sa bude vzťahovať na váš ďalší nákup kreditov.",
        "how_do_i_pay_for_service": "Ako zaplatím za Službu?.",
        "how_do_i_pay_for_service_text": "Za kredity môžete platiť akoukoľvek väčšou kreditnou kartou. Spôsoby platby, ktoré akceptujeme, môžeme kedykoľvek zmeniť.",
        "can_i_get_refund": "Môžem dostať náhradu?.",
        "can_i_get_refund_text": "Čiastky, za ktoré ste zaplatili pomocou Služby, sú nevratné, ak zrušíte svoj účet. Ak zrušíme váš účet, vrátime vám všetky kredity, ktoré vám zostali.",
        "rights": "PRÁVA",
        "what_do_we_own": "Čo vlastníme?.",
        "what_do_we_own_text": "S výnimkou obsahu, ktorý nám poskytnete (vrátane, bez obmedzenia, kontaktných záznamov, obsahu e-mailov a informácií, ktoré zhromažďujete prostredníctvom Služby) („Váš obsah“), všetky materiály zahrnuté na našej stránke, Služba a všetky materiály na nej alebo ňou prenesené, vrátane, bez obmedzenia, softvéru, obrázkov, textu, grafiky, ilustrácií, log, patentov, ochranných známok, servisných značiek, fotografií, zvuku, videí, hudby a obsahu všetkých obsahov patriacich k „duševnému obsahu súvisiacemu s „ostatnými používateľmi“ k nim) sú vo vlastníctve nás alebo našich poskytovateľov licencií.",
        "what_do_you_own": "Čo vlastníte?",
        "what_do_you_own_text": "Nenárokujeme si žiadne vlastnícke práva na váš obsah. Váš obsah je váš a zostáva vám vlastníctvom vášho obsahu, ktorý nahráte do Služby. Zdieľaním svojho obsahu prostredníctvom služby súhlasíte s tým, že umožníte ostatným prezerať, upravovať a\/alebo zdieľať váš obsah v súlade s vašimi nastaveniami a týmito zmluvnými podmienkami. Vyhlasujete a zaručujete, že buď vlastníte alebo máte povolenie na použitie všetkého materiálu, ktorý používate vo svojich e-mailoch. Vaše materiály môžeme použiť alebo zverejniť len tak, ako je to opísané v týchto podmienkach a našich zásadách ochrany osobných údajov.",
        "privacy_statement": "Menuonline rešpektuje súkromie svojich používateľov. Akékoľvek osobné údaje, ktoré Menuonline zhromažďuje prostredníctvom tejto Stránky alebo Služby, podliehajú Vyhláseniu o ochrane osobných údajov Menuonline, ktoré je odkazom zahrnuté do týchto Podmienok. Vaše informácie môžeme použiť a zverejniť v súlade s naším Vyhlásením o ochrane osobných údajov.",
        "access_to_your_content": "Menuonline neposkytne prístup k Vášmu obsahu nikomu okrem tých zamestnancov a zmluvných partnerov Menuonline, ktorí s nami uzavreli príslušné zmluvy o dôvernosti alebo na to máme váš súhlas.",
        "governmentRequest": "Ak od vládneho alebo regulačného orgánu dostaneme žiadosť o prístup k vášmu obsahu, upozorníme vás na túto žiadosť a požiadame vás o súhlas skôr, ako zverejníme akýkoľvek váš obsah. Budeme odolávať akýmkoľvek žiadostiam o váš obsah a nezverejníme váš obsah v reakcii na tento typ žiadosti bez vášho súhlasu alebo bez toho, aby to vyžadoval súd kompetentnej jurisdikcie alebo príslušný regulačný orgán.",
        "breachNotification": "V prípade, že sa dozvieme, že váš obsah bol predmetom neoprávneného prístupu, používania, kopírovania, krádeže, straty alebo použitia alebo zverejnenia v rozpore s týmito Podmienkami („Porušenie“), okamžite vás upozorníme a budeme vás informovať o tom, čo sa stalo. Súhlasíte s tým, že Porušenia sa budú riešiť v súlade s platnými zákonmi, a ak budeme potrebovať, aby ste informovali členov vašich distribučných zoznamov e-mailov, súhlasíte s tým, že tak urobíte okamžite a podľa našich pokynov.",
        "contentRetention": "Váš obsah budeme uchovávať, používať a prenášať v Kanade, s výnimkou vášho predchádzajúceho písomného súhlasu alebo pokynov (napríklad ak sa rozhodnete poslať svoj obsah jednotlivcom mimo Kanady). Servery používané na poskytovanie Služby sa nachádzajú v Kanade.",
        "accessTrouble": "Ak máte problémy s prístupom k svojmu obsahu, Menuonline vám pomôže získať váš obsah na základe času a materiálov.",
        "reportingAbuse": "Ak sa domnievate, že niekto porušuje ktorúkoľvek z týchto podmienok, okamžite nás o tom informujte. Ak ste dostali spamy, o ktorých si myslíte, že prišli od iného používateľa Služby, ak si myslíte, že iný používateľ Služby uverejnil materiál, ktorý porušuje akékoľvek autorské práva, alebo ak si myslíte, že iný používateľ Služby porušuje tieto Podmienky, kontaktujte nás na adrese Support@menuonline.com.",
        "acceptableUse": "Vyhlasujete a zaručujete, že vaše používanie Služby bude v súlade so všetkými platnými zákonmi a predpismi. Súhlasíte s tým, že nesiete zodpovednosť za určenie, či bude Služba v súlade s požiadavkami a či je vhodná na používanie vo vašej jurisdikcii. Pre väčšiu istotu súhlasíte s tým, že budete (a súhlasíte s tým, že zabezpečíte, aby vaši zamestnanci, zástupcovia a oprávnení používatelia) ...",
        "exportLaws": "Neprehlasujeme, že stránka alebo služba sú vhodné alebo dostupné na použitie mimo Kanady a prístup k nim z území, kde je ich obsah nezákonný, je zakázaný. Nesmiete používať alebo exportovať alebo reexportovať obsah Menuonline alebo iné materiály na tejto lokalite ani žiadne kópie alebo úpravy v rozpore s akýmikoľvek platnými zákonmi alebo predpismi vrátane, bez obmedzenia, kanadských a amerických exportných zákonov a nariadení.",
        "disclaimers": "Služba a táto stránka sa poskytujú „tak, ako sú“ bez akýchkoľvek vyhlásení, záruk alebo podmienok akéhokoľvek druhu a Menuonline výslovne odmieta, v plnom rozsahu povolenom príslušnými zákonmi, všetky vyjadrenia, záruky a podmienky, výslovné alebo predpokladané, na základe zákona alebo inak, vrátane, bez obmedzenia, akýchkoľvek implicitných záruk a podmienok obchodovateľnosti, vhodnosti alebo práv tretích strán na používanie, ktoré nie sú určené pre tretie strany...",
        "limitLiability": "Celková zodpovednosť Menuonline podľa tejto Zmluvy, či už na základe zmluvy alebo deliktu (vrátane nedbanlivosti), v dôsledku porušenia záruky, objektívnej zodpovednosti alebo akejkoľvek inej teórie zodpovednosti, bude obmedzená na priame škody vo výške nepresahujúcej celkové poplatky zaplatené spoločnosti Menuonline podľa týchto Zmluvných podmienok počas dvanástich (12) mesiacov bezprostredne pred mesiacom, v ktorom nastala posledná udalosť, ktorá viedla k vzniku zodpovednosti.",
        "liabilityExclusions": "Žiadna z Menuonline, jej pridružených spoločností alebo ich príslušných riaditeľov, úradníkov, zamestnancov, agentov alebo iných zástupcov (spoločne „Zástupcovia Menuonline“) alebo prevádzkovateľov akýchkoľvek pripojených sietí nebude mať žiadnu zodpovednosť ani zodpovednosť v súvislosti s touto stránkou alebo službou za: (i) akékoľvek nepriame, následné, náhodné, exemplárne, represívne alebo špeciálne škody; alebo (ii) za akékoľvek škody, či už priame, nepriame, následné, náhodné, exemplárne, trestné alebo špeciálne, charakterizované ako ušlý príjem, ušlé úspory alebo výnosy alebo ušlý zisk...",
        "indemnity": "Odškodníte a podržíte Menuonline a\/alebo zástupcov Menuonline (ďalej len „odškodňované strany Menuonline“) pred a proti akýmkoľvek nárokom vzneseným tretími stranami a akýmkoľvek pokutám alebo sankciám udeleným proti nám, ktoré vyplývajú z vášho používania informácií prístupných z tejto Stránky alebo vášho používania Služby a akéhokoľvek porušenia týchto Podmienok...",
        "ipInfringement": "Menuonline vás bude obhajovať, odškodniť a chrániť vás pred všetkými nárokmi, požiadavkami, žalobami, žalobami alebo konaniami, ktoré proti vám podala alebo podala tretia strana, ktorá tvrdí, že váš prístup k Službe alebo jej používanie v súlade s touto dohodou porušuje práva duševného vlastníctva podľa kanadského práva tretej strany (ďalej len „nárok z porušenia IP“)...",
        "equitableRelief": "Ak porušíte tieto Podmienky, môžeme požiadať o súdny príkaz (čo znamená, že môžeme požiadať o súdny príkaz, aby sme vás zastavili) alebo inú spravodlivú nápravu. Súhlasíte s tým, že to bude doplnkové a bez toho, aby boli dotknuté akékoľvek iné práva, ktoré môžeme mať zo zákona alebo vlastného imania.",
        "linksToOtherSites": "Ak poskytujeme akékoľvek odkazy na webové stránky tretích strán, poskytujeme ich len pre pohodlie. Ak použijete tieto odkazy, opustíte našu stránku. Tieto stránky tretích strán nekontrolujeme ani ich neschvaľujeme. Súhlasíte s tým, že nie sme zodpovední za žiadne z týchto stránok tretích strán ani ich obsah...",
        "thirdPartyRequests": "Ak musíme poskytnúť informácie v reakcii na zákonnú alebo vládnu žiadosť týkajúcu sa vášho účtu alebo vášho používania Služby, súhlasíte s tým, že vám môžeme účtovať naše náklady. Tieto poplatky môžu zahŕňať naše právne poplatky a náklady nášho zamestnanca na čas strávený prípravou odpovede na žiadosť.",
        "assignment": "Túto zmluvu a akékoľvek práva a licencie udelené na základe tejto zmluvy nemôžete previesť ani postúpiť vy, ale spoločnosť Menuonline ich môže postúpiť bez obmedzenia na základe oznámenia vopred.",
        "governingLaw": "Tieto podmienky sa budú riadiť a vykladať v súlade so všetkými platnými federálnymi zákonmi a zákonmi štátu Ontario bez ohľadu na ustanovenia o konflikte zákonov. Súhlasíte s tým, že sa pri rozhodovaní o akomkoľvek spore týkajúcom sa týchto Podmienok podrobíte výlučnej jurisdikcii súdov v Ontáriu.",
        "severability": "Ak z akéhokoľvek dôvodu súd príslušnej jurisdikcie zistí, že akékoľvek ustanovenie týchto podmienok alebo ich časť je nezákonné alebo z akéhokoľvek dôvodu nevykonateľné, potom bude toto ustanovenie vymáhané v maximálnom povolenom rozsahu, aby sa dosiahol účinok týchto podmienok, a zvyšok týchto podmienok bude naďalej plne platný a účinný a zostane vymáhateľný.",
        "waiver": "Akékoľvek zrieknutie sa požiadaviek týchto Podmienok alebo súhlas s odchýlením sa od nich bude účinný iba vtedy, ak bude písomný a podpísaný nami, a to iba v konkrétnom prípade a na konkrétny účel, na ktorý bol daný. Naše neuplatnenie alebo omeškanie s uplatnením akéhokoľvek práva podľa týchto Podmienok nebude fungovať ako vzdanie sa našich práv.",
        "furtherAssurances": "Každá strana súhlasí s tým, že podpíše a poskytne všetky dokumenty a podnikne akékoľvek kroky (alebo zapríčiní vykonanie týchto krokov) potrebné na splnenie svojich záväzkov podľa týchto podmienok.",
        "notices": "Všetky oznámenia pre Menuonline musia byť písomné a zaslané nám e-mailom. Oznámenia nám musia byť zaslané do pozornosti našich zástupcov zákazníckeho servisu na support@menuonline.com. Oznámenia vám môžu byť zaslané na adresu alebo e-mailovú adresu, ktorú ste uviedli ako súčasť vašej registrácie. Okrem toho môžeme prostredníctvom tejto lokality vysielať oznámenia alebo správy, aby sme vás informovali o zmenách na tejto lokalite alebo iných dôležitých záležitostiach, a tieto vysielania budú predstavovať oznámenie pre vás v čase, keď ich uverejníme.",
        "entireAgreement": "Tieto podmienky a naše vyhlásenie o ochrane osobných údajov tvoria celú dohodu medzi vami a nami, pokiaľ ide o vaše používanie stránky a služby, a nahrádzajú všetky predchádzajúce dohody, vyhlásenia a dorozumenia medzi spoločnosťou Menuonline a vami v súvislosti s týmto predmetom. V prípade akéhokoľvek rozporu medzi Vyhlásením o ochrane osobných údajov a týmito Podmienkami sa budú riadiť týmito Podmienkami.",
        "language": "Zmluvné strany výslovne požiadali a požadovali, aby boli tieto zmluvné podmienky, naše zásady ochrany osobných údajov a všetky súvisiace dokumenty vypracované v angličtine. Les parties conviennent et exigent expressément que ce Contrat et tous les documents qui s'y rapportent soient rédigés en anglais. Súhlasíte s tým, že anglická jazyková verzia týchto Podmienok a Zásad ochrany osobných údajov bude mať prednosť v prípade akéhokoľvek konfliktu s akoukoľvek preloženou verziou.",
        "survival": "Všetky ustanovenia týchto Podmienok, ktoré sú svojou povahou alebo importom určené na to, aby prežili aj po vypršaní alebo ukončení týchto Podmienok, zostanú v platnosti, vrátane, bez obmedzenia, oddielov 17-19."
    },
    "collect_additional_info": "Chcete získať ďalšie informácie?",
    "additionalInfoChoice": "Vyberte akékoľvek ďalšie informácie, ktoré chcete zhromažďovať.",
    "additional_info_yes": "áno",
    "additional_info_noSkip": "Nie, preskočiť",
    "additional_info_label": "Ďalšie informácie",
    "confirm_business_location": "Najprv potvrďte adresu vašej firmy",
    "custom_get_more_reviews_form": "Vlastný formulár na získanie ďalších recenzií",
    "set_up_custom_form": "Nastavte si svoj vlastný Získajte viac recenzií Formulár podľa skupiny",
    "reputation_name_label": "Získajte viac recenzií Názov",
    "reputation_name_placeholder": "Zadajte názov reputácie",
    "group_label": "Skupina",
    "group_desc_title": "Názov popisu skupiny",
    "group_desc": "Popis skupiny",
    "select_group_placeholder": "Vyberte položku Skupina",
    "required_group_name": "Vyžaduje sa názov skupiny",
    "before_asking_review": "Predtým, ako požiadame zákazníkov o recenziu, položme im 1 až 4 otázky.",
    "feel_free_edit": "Neváhajte a upravte podľa potreby.",
    "incentive_settings": "Nastavenia stimulov",
    "incentivize_customer": "Chceli by ste motivovať svojho zákazníka k napísaniu recenzie?",
    "choose_incentive": "Vyberte stimul, ktorý by ste chceli odporučiť.",
    "scan_qr_feedback": "Naskenujte QR kód a podeľte sa s nami o cennú spätnú väzbu týkajúcu sa vašich skúseností.",
    "qr_code": "QR kód",
    "scan_me": "SKENOVAŤ MA!",
    "or_title": "ALEBO",
    "send_otp_title": "Odoslať jednorazové heslo",
    "sign_in_title": "Prihlásiť sa",
    "email_addresss_title": "E-mailová adresa",
    "reset_password_title": "Obnoviť heslo",
    "remember_password_title": "Zapamätané heslo?",
    "digit_code_title": "Zadajte 4-miestny kód, ktorý bol odoslaný ",
    "not_received_title": "Nedostal som overovací kód!",
    "resend_title": "Pošlite znova",
    "sign_up_form": "Vytvorte si účet",
    "sign_up_sub_title": "Prihláste sa teraz a získajte prístup k najnovším štatistikám",
    "already_registered_title": "Máte už účet v Menuonline?",
    "reserved_title": "Autorské práva ©2023 Menuonline. Všetky práva vyhradené.",
    "terms_title": "Zmluvné podmienky",
    "policy_title": "Zásady ochrany osobných údajov",
    "sign_up_title": "Zaregistrujte sa",
    "terms_conditions_title": "Zmluvné podmienky",
    "business_type_title": "Typ podnikania",
    "business_type_sub_title": "Vyberte si jeden z typov podnikania, ktorý lepšie vystihuje vašu firmu!",
    "salon_title": "Salón",
    "restaurant_title": "Reštaurácia",
    "pizza_store_title": "Predajňa pizze",
    "food_truck_title": "Food Truck",
    "check_domain_title": "Skontrolujte názov domény",
    "start_exploring_title": "Začnite skúmať",
    "sign_in_form": "Prihláste sa do svojho účtu",
    "sign_in_sub_title": "Prihláste sa teraz a získajte prístup k najnovším štatistikám",
    "remember_title": "Pamätaj na mňa",
    "otp_title": "OTP",
    "password_title": "heslo",
    "forgot_title": "Zabudli ste heslo?",
    "not_registered_title": "Nemáte účet?",
    "create_account_title": "Zaregistrujte sa teraz",
    "create_form_title": "Vytvoriť formulár",
    "first_name_title": "Krstné meno",
    "last_name_title": "Priezvisko",
    "email_title": "Email",
    "mobile_number_title": "Mobilné číslo",
    "birthday_title": "narodeniny",
    "gender_title": "Pohlavie",
    "address_title": "Adresa",
    "short_answer_title": "Krátka odpoveď",
    "long_answer_title": "Dlhá odpoveď",
    "single_answer_title": "Jediná odpoveď",
    "single_checkbox_title": "Jedno začiarkavacie políčko",
    "multiple_choice_title": "Viacnásobný výber",
    "drop_down_title": "Rozbaľovacia ponuka",
    "yes_no_title": "Áno alebo Nie",
    "description_text_title": "Popisný text",
    "file_upload": "Nahranie súboru",
    "reset_password_form": "Obnovte svoje heslo",
    "reset_password_sub_title": "Rýchlo obnovte svoje heslo a získajte prístup k svojmu účtu.",
    "required_full_name_title": "Vyžaduje sa celé meno",
    "required_first_name_title": "Vyžaduje sa krstné meno",
    "valid_first_name_title": "Zadajte platné krstné meno",
    "valid_full_name_title": "Zadajte platné celé meno",
    "required_block_time": "Vyžaduje sa blokovací čas",
    "required_last_name_title": "Vyžaduje sa priezvisko",
    "valid_last_name_title": "Zadajte platné priezvisko",
    "valid_mobile_number_title": "Zadajte platné mobilné číslo",
    "required_mobile_number_title": "Vyžaduje sa mobilné číslo",
    "required_already_mobile_number_title": "Číslo mobilného telefónu sa už vyžaduje",
    "valid_otp_title": "Zadajte platné otp",
    "valid_email_title": "Zadajte platný e-mail",
    "required_email_title": "Vyžaduje sa e-mail",
    "required_description_title": "Vyžaduje sa popis",
    "required_description_500_characters": "Zadajte aspoň 500 znakov",
    "required_description_600_characters": "Zadajte maximálne 600 znakov",
    "domain_title": "Názov domény",
    "required_password_title": "Vyžaduje sa heslo",
    "required_password_regex_title": "Musí obsahovať minimálne 8 znakov, aspoň jedno veľké písmeno, jedno malé písmeno, jedno číslo a jeden špeciálny znak",
    "required_terms_title": "Súhlaste so zmluvnými podmienkami",
    "required_note_title": "Vyžaduje sa poznámka",
    "required_birthday_title": "Vyžaduje sa dátum narodenia",
    "no_access_title": "Momentálne nemáte prístup. Kontaktujte svojho správcu",
    "menu_online_open_txt": "OTVORENÉ",
    "loyalty_program_description": "😊 Nájdite si svoj ideálny vernostný program a prispôsobte si ho tak, aby zodpovedal vašej značke!",
    "lp_list_title": "Zoznam vernostných diernych kariet",
    "lp_n_title": "Názov vernostnej karty",
    "enter_details_title": "Zadajte Podrobnosti",
    "stamp_allowed_title": "Pečiatka povolená",
    "stamp_items_title": "Pečiatkové položky",
    "lpn_req_title": "Vyžaduje sa názov vernostného štítku",
    "valid_lpn_title": "Zadajte platný názov vernostného štítku",
    "brand_color_error": "Farba značky musí mať presne 7 znakov",
    "select_dark_brand_color_title": "Vyberte platnú farbu značky",
    "your_brand_color_title": "Farba vašej značky",
    "upload_logo_title": "Nahrať logo",
    "locations_title": "Miesta",
    "add_lp_title": "Vytvoriť program",
    "location_error": "Vyberte miesta",
    "pvs_title": "Koľko pečiatok môže zákazník získať za návštevu?",
    "max_apd_title": "Aký je maximálny počet pečiatok, ktoré môže zákazník získať za deň?",
    "logo_error": "Vyžaduje sa logo",
    "expiry_title": "Nastavte platnosť odmien",
    "add_more_rewards_title": "+ Pridať ďalšie odmeny",
    "prev_step_1_title": "Ukážte QR kód a získajte body a užívajte si odmeny.",
    "delete_lp_title": "Táto vernostná karta bude natrvalo odstránená.",
    "delete_user_record_title": "Tento používateľský záznam bude natrvalo odstránený.",
    "redeem_title": "Uplatniť",
    "lp_button_title": "Pripojte sa k vernostnej karte",
    "lp_download_button_title": "Stiahnuť kartu",
    "step_0_title": "Pripojte sa teraz, na verných gurmánov, ako ste vy, čakajú chutné odmeny.",
    "step_1_title": "Pripojili ste sa",
    "congrats_step_title": "Teraz máte nárok na pizzu zadarmo",
    "reward_title": "Získajte odmeny pri každej návšteve",
    "visit_title": "Navštívte a odomknite",
    "rewards_title": "Odmeny",
    "dummy_address": "3730 Frankfort Ave, Louisville Kentucky, Spojené štáty americké",
    "loyalty_qr_default_text": "Naskenujte QR kód, pripojte sa k našej vernostnej karte a doprajte si odmeny",
    "lp_earn_stamp_txt": "Zákazníci získavajú pečiatky pri každej návšteve.",
    "lp_get_rewards_txt": "Získajte odmeny pri každej návšteve",
    "lp_1_time_visit_txt": "1 krát návšteva, ktorú dostanete",
    "lp_uppercase_stamp_text": "Pečiatka",
    "lp_lowercase_stamp_text": "pečiatka",
    "lp_txt": "Vernosť",
    "lp_per_visit_stamp_validation_msg": "Pečiatka za návštevu musí byť menšia ako maximálna povolená známka za deň",
    "lp_per_max_stamp_validation_msg": "Maximálny povolený počet pečiatok za deň musí byť väčší ako počet pečiatok za návštevu",
    "lp_valid_value_msg": "Zadajte platnú hodnotu",
    "lp_required_field_msg": "Toto pole je povinné",
    "lp_stamp_required_msg": "Vyžaduje sa pečiatka",
    "lp_valid_stamp_msg": "Zadajte platnú hodnotu pečiatky",
    "lp_reward_name_required_msg": "Vyžaduje sa názov odmeny",
    "lp_unique_stamp_msg": "Hodnota pečiatky by mala byť jedinečná",
    "lp_benefits": "Vernostný program zvyšuje opakované návštevy a výnosy a zároveň poskytuje informácie o zákazníkoch pre personalizovaný marketing",
    "lp_how_customer_txt": "Takto vaši zákazníci",
    "lp_punch_card_txt": "vernostná dierna karta",
    "lp_look_txt": "bude vyzerať! 😍",
    "lp_desc": "Zákazníci za každú návštevu získajú pečiatky a keď nazbierajú dostatok, môžu si uplatniť špeciálne odmeny.",
    "lp_edit": "Upraviť",
    "lp_looks_good_txt": "Vyzerá dobre",
    "lp_customer_view_instruction": "Toto uvidí váš zákazník. Nahrajte svoje logo a nastavte farebný motív",
    "lp_customer_earn_stamps": "Umožnite zákazníkom získavať pečiatky pri každej návšteve a spravujte ich odmeny",
    "lp_stamp_rewards_setup": "Nastavte odmeny za získavanie pečiatok a umožnite zákazníkom získať odmeny za pečiatky",
    "lp_loyalty_program_setup": "Zadajte názov svojho vernostného programu a nastavte miesto, kde chcete mať tento program",
    "lp_prev_step1": "Ukážte QR kód a získajte body a užívajte si odmeny.",
    "lp_visit_1_time_txt": "Navštívte 1 Time a získajte",
    "lp_get_free_txt": "Získajte zadarmo",
    "lp_visit_allow_txt": "Vist & Unlock",
    "lp_rewards": "Odmeny",
    "repsT": "Získajte viac recenzií",
    "offSup": "Kancelárske potreby",
    "repsNxtV": "Pri ďalšej návšteve",
    "addRepT": "Pridať Získajte viac recenzií",
    "repEntrCpnTxt": "Zadajte text kupónu",
    "repEntrCpnTxtBtn": "Zadajte text tlačidla kupónu",
    "repMngNxtV": "pri ďalšej návšteve?",
    "repMngWldL": "Chceli by ste dostať",
    "discount": "Zľava",
    "repDisTyp": "Typ zľavy",
    "repNameT": "Pomenujte svoju povesť",
    "crtRepT": "Vytvorte si reputáciu",
    "feedBackT": "Spätná väzba",
    "confLocT": "Potvrďte polohu",
    "businLocT": "Miesto podnikania",
    "addInfoT": "Ďalšie informácie",
    "repNReqT": "Vyžaduje sa názov Získať ďalšie recenzie",
    "validRepNT": "Zadajte platný názov reputácie",
    "groupT": "Skupina",
    "groupLDescTitleT": "Automaticky vložiť kontakty do skupiny",
    "groupLDescT": "Všetci zákazníci, ktorí vyplnia formulár, budú pridaní do vybranej skupiny.",
    "que2": "Bolo vaše jedlo dobré?",
    "que3": "Bola naša služba priateľská?",
    "que4": "Bola naša služba rýchla?",
    "addAdditionalT": "Chcete pridať ďalšie informácie?",
    "phNoMandT": "Telefónne číslo je povinné!",
    "qrGenT": "Vygenerované QR",
    "repFDT": "Váš príspevok pomôže nášmu tímu lepšie vám slúžiť!",
    "letdiT": "Poďme na to",
    "tak15secT": "Trvá 15 sekúnd",
    "defQue": "Chceli by ste z času na čas dostávať skvelé ponuky a propagačné akcie?",
    "defNQue": "Chceli by ste, aby vám niekto odpovedal?",
    "rateOnGoogleDesc": "Boli by ste tak láskaví a ohodnotili nás na Googli?",
    "mayBeNT": "Možno nabudúce",
    "yesSureT": "Áno, určite",
    "valid_name": "Zadajte platný názov",
    "nameReq": "Meno je povinné",
    "backToMainT": "Späť na hlavnú stránku",
    "attachCopT": "Chceli by ste motivovať svojho zákazníka?",
    "thankYouT": "dakujem",
    "lftyT": "Tešíme sa na Vašu ďalšiu návštevu!",
    "repPreT": "Ukážka reputácie",
    "offer_exclusive_discounts": "Ponúkajte exkluzívne zľavy a propagačné akcie, aby ste prilákali svojich zákazníkov.",
    "couponT": "kupón",
    "locStepDesc": "Potvrďte polohu svojej firmy",
    "createRepStepDesc": "Vytvorte si vlastný formulár reputácie podľa skupín",
    "feedbackStepDesc": "Sada otázok spätnej väzby od zákazníkov",
    "addInfoStepDesc": "Polia zákazníkov a nastavenia stimulov",
    "FedbkkWDT": "Spätná väzba s údajmi",
    "repAnlytT": "Analýza reputácie",
    "todayT": "Dnes",
    "rep_last_7_days_t": "Posledných 7 dní",
    "last30DaysT": "Posledných 30 dní",
    "lastMT": "Minulý mesiac",
    "custRangeT": "Vlastný rozsah",
    "queWiseAnltT": "Otázka Wise Analytics",
    "atL1QueErrT": "Vyberte aspoň jednu otázku",
    "deleteRepTitle": "Táto reputácia bude natrvalo vymazaná.",
    "incvCustT": "Áno, stimulujte môjho zákazníka",
    "collAddInfoT": "Áno, rád by som zhromaždil ďalšie informácie",
    "totRewT": "Celková recenzia",
    "totPosRewT": "Celkom kladná recenzia",
    "negFeedT": "Negatívna spätná väzba",
    "posFeedT": "Pozitívna spätná väzba",
    "fineT": "Jemná tlač",
    "enterFineT": "Zadajte drobný text",
    "setThemeT": "Nastaviť farbu motívu",
    "que1T": "Otázka 1",
    "que2T": "Otázka 2",
    "que3T": "Otázka 3",
    "que4T": "Otázka 4",
    "entMailT": "Zadajte svoj email",
    "reputationQRDefTxt": "Naskenujte QR kód a podeľte sa s nami o cennú spätnú väzbu týkajúcu sa vašich skúseností.",
    "selUser": "Vyberte položku Používateľ",
    "userT": "Používateľ",
    "subUReq": "Vyberte používateľov",
    "updateLoc": "Aktualizovať polohu",
    "leadGenT": "Generovanie olova",
    "displayT": "Displej",
    "ratingT": "Hodnotenie",
    "rep_dashboard_negative_feedback": "Negatívna spätná väzba 0 %",
    "rep_dashboard_position_feedback": "Pozitívna spätná väzba 0 %",
    "rep_dashboard_total_Feedback_txt": "Celková spätná väzba",
    "rep_dashboard_anony_Feedback": "Anonymná spätná väzba",
    "rep_dashboard_Feeedback_Data_txt": "Spätná väzba s údajmi",
    "rep_dashboard_review_txt": "Získajte viac recenzií",
    "rep_dashboard_total_Review_txt": "Celková recenzia",
    "rep_dashboard_total_positive_Review_txt": "Celkom kladná recenzia",
    "rep_dashboard_negative_feedback_txt": "Negatívna spätná väzba",
    "rep_dashboard_connection_txt": "Pripojenie",
    "rep_dashboard_question_wise_analytics_txt": "Otázka Wise Analytics",
    "rep_dashboard_date_label": "Vyberte Dátum",
    "rep_dashboard_custom_range_txt": "Vlastný rozsah",
    "rep_tlt_min": "REP",
    "emojis": "Emojis",
    "media_text": "Pridať médiá",
    "custom_field_text": "Vložiť vlastné polia",
    "shortlink_text": "Pridať krátky odkaz",
    "use_template_text": "Použiť šablónu",
    "banner_must_have": "Musíte mať",
    "res_seamless": "Bezšvové",
    "res_table_reservation": "Rezervácia stola",
    "res_system": "Systém",
    "res_text": "Rezervácie",
    "md_easily": "Jednoducho",
    "md_manage_customers": "Spravovať zákazníkov",
    "md_visits_interactions": "návštevy a interakcie",
    "md_desc": "Uľahčite svojim zákazníkom stolovanie vďaka rezerváciám stolov v reálnom čase. Spravujte rezervácie bez námahy, skráťte čakacie doby a zaistite bezproblémový zážitok pre zamestnancov aj hostí. Udržujte svoju reštauráciu plne rezervovanú pri zachovaní špičkových služieb a spokojnosti.",
    "engage_convert": "Engage & Convert SMS",
    "coupon_campaign": "a kupónová kampaň",
    "sms_read_rate": "SMS správy sa čítajú 98 % času",
    "real_time_promo": "Poskytujte propagačné akcie v reálnom čase, aby ste mohli okamžite konať",
    "affordable_reach": "Cenovo dostupný spôsob, ako osloviť zákazníkov so solídnou návratnosťou investícií",
    "stay_in_touch": "Zostaňte v kontakte so svojím zákazníkom po celú dobu",
    "bc_engage_customers": "Zapojte zákazníkov Konvertujte SMS",
    "bc_coupon_campaign": "a kupónová kampaň",
    "bc_sms_read_rate": "SMS správy sa čítajú 98 % času",
    "bc_real_time_promo": "Zobrazujte propagačné akcie v reálnom čase pre okamžitú akciu",
    "bc_affordable_reach": "Cenovo dostupný spôsob, ako osloviť zákazníkov so solídnou návratnosťou investícií",
    "bc_stay_in_touch": "Zostaňte v kontakte so svojím zákazníkom po celú dobu",
    "ar_automate_engagement": "Automatizujte zapojenie svojich klientov",
    "ar_with": "s",
    "ar_with_auto_responders": "Automatické odpovede",
    "ar_personalized_messages": "Automaticky odosielajte prispôsobené správy na narodeniny, vítajte nových klientov a znova sa spojte s minulými zákazníkmi. Ušetrite čas, pričom každá interakcia bude zmysluplná a včasná.",
    "sq_custom_forms_tools": "Vlastné formuláre a nástroje – zjednodušiť",
    "sq_custom_forms_ci": "Klientske interakcie",
    "sq_smart_qr_desc": "Vytvárajte vlastné formuláre, zbierajte elektronické podpisy a využívajte inteligentné QR kódy na zefektívnenie vašich obchodných procesov. Prispôsobte každý nástroj tak, aby vyhovoval potrebám vašich klientov a zlepšil používateľskú skúsenosť.",
    "gnc_get_new_cus": "Získajte nových zákazníkov",
    "gnc_get_customers": "zo sociálnych médií – rýchlo a jednoducho!",
    "gnc_social_media_power": "Využite silu sociálnych platforiem na prilákanie nových zákazníkov a podporu predaja priamo do vašej firmy. Presmerujte objednávky na doručenie od tretích strán priamo do vášho obchodu alebo online objednávkovej platformy, aby ste získali maximálnu kontrolu a výnosy.",
    "wifi_connect_guests": "Spojte hostí s",
    "wifi_smart": "Smart Wi-Fi",
    "wifi_connect_grow": "& Rozšírte svoje poznatky",
    "wifi_smart_wifi_desc": "Poskytnite svojim zákazníkom bezproblémové Wi-Fi pripojenie prostredníctvom značkovej úvodnej obrazovky a zároveň zbierajte cenné údaje o zákazníkoch, aby ste zvýšili svoje marketingové úsilie a zapojenie.",
    "wifi_create_splash_page": "Vytvorte úvodnú stránku Wifi pre hostí",
    "instant_menu_create": "Vytvorte si svoj",
    "instant_menu_text": "Okamžité menu",
    "instant_menu_wm": "v priebehu niekoľkých minút",
    "instant_menu_desc": "Zlepšuje skúsenosti zákazníkov tým, že umožňuje aktualizácie v reálnom čase, jednoduché prispôsobenie a lepšie zapojenie, čo uľahčuje prezentovať vaše ponuky a udržiavať váš obsah aktuálny.",
    "instant_menu_create_digital_menu": "Vytvorte digitálne menu",
    "loyalty_program_rewards": "Získajte odmeny",
    "loyalty_program_on_visits": "pri každej návšteve",
    "loyalty_program_create": "Vytvorte vernostný program",
    "reputation_create": "Vytvorte si reputáciu",
    "reputation_boost": "Zvýšte svoje",
    "reputation_text": "povesť",
    "reputation_reviews": "s ďalšími recenziami!",
    "reputation_desc": "Získajte cennú spätnú väzbu od svojich zákazníkov, aby ste zlepšili svoje služby a reputáciu. Podeľte sa o svoje skúsenosti a pomôžte nám rásť – na vašej recenzii záleží!",
    "dashboard": "Dashboard",
    "blast_campaign": "Odstreľovacia kampaň",
    "blast_campaigns": "Výbušné kampane",
    "blast_campaign_sub_title": "Spustite skvelé kampane a oslovte všetkých svojich klientov niekoľkými kliknutiami",
    "auto_responders": "Automatické odpovedače",
    "auto_responder": "Automatická odpoveď",
    "auto_responder_sub_title": "Jednoducho použiteľné akcie pre vašich klientov",
    "smart_qr_form": "Inteligentný QR \/ formulár",
    "get_new_customer": "Získajte nového zákazníka",
    "marketing": "marketing",
    "clients": "klientov",
    "group_data": "Údaje o skupinách",
    "clients_data": "Údaje o klientoch",
    "account": "účtu",
    "user_acc_info": "Informácie o používateľskom účte",
    "current_plan": "Aktuálny plán",
    "payments": "Platby",
    "location": "Poloha",
    "location_list": "Zoznam miest",
    "users": "Používatelia",
    "users_sub_title": "Zoznam všetkých používateľov a ich podrobnosti",
    "online_profile": "Online profil",
    "change_password": "Zmeniť heslo",
    "menu": "Menu",
    "reseller_dashboard": "Informačný panel predajcu",
    "companies": "Spoločnosti",
    "plans_bought": "Plány Kúpené",
    "agreement_t_c": "Zmluva T&C",
    "wifi_dashboard": "Wifi Dashboard",
    "hot_spot": "Hotspot",
    "splash_pages": "Úvodné stránky",
    "get_more_reviews": "Získajte viac recenzií",
    "office_supplies": "Kancelárske potreby",
    "reservation": "Rezervácia",
    "floor_table": "Podlaha a stôl",
    "guest_smart_wifi": "Inteligentné WiFi pre hostí",
    "digital_signage": "Digital Signage",
    "schedule_down": "Harmonogram",
    "business_settings": "Obchodné nastavenia",
    "sub_user_login": "Subuser Login",
    "help_center": "Centrum pomoci",
    "switch_to_hub": "Prepnúť na Hub",
    "meeting_records": "Záznamy zo stretnutí",
    "logout": "Odhlásiť sa",
    "no_access_text": "Momentálne nemáte prístup. kontaktujte svojho správcu",
    "menu_item": "POLOŽKA MENU",
    "menu_capital": "MENU",
    "good_day": "Dobry den",
    "no_permission_text": "Na vykonanie tejto akcie nemáte povolenie!!",
    "enter_valid_pin": "Zadajte platný PIN",
    "add_reservation": "Pridať rezerváciu",
    "edit_reservation": "Upraviť rezerváciu",
    "per_req": "Vyžaduje sa počet osôb",
    "req_block_time": "Vyžaduje sa blokovací čas",
    "pending_status": "Čaká na spracovanie",
    "booked_status": "Rezervované",
    "completed_status": "Dokončené",
    "cancelled_status": "Zrušené",
    "reservation_slot": "Rezervačný slot",
    "block_time": "Blokovať čas",
    "slot_time": "Čas rezervačného slotu:  ",
    "template_name_req": "Vyžaduje sa názov šablóny",
    "template_type_req": "Vyžaduje sa typ šablóny",
    "sms_template_req": "Vyžaduje sa šablóna SMS",
    "group_req": "Skupina je povinná",
    "valid_date_time": "Zadajte platný dátum a čas",
    "valid_group_name": "Zadajte platný názov skupiny",
    "reservation_mark_completed": "Táto rezervácia bude označená ako dokončená.",
    "customer_name": "Meno zákazníka",
    "date_time": "Dátum Čas",
    "table_no": "Tabuľka č",
    "floor": "Poschodie",
    "customer_name_placeholder": "meno_zákazníka",
    "date_time_placeholder": "dátum_čas",
    "table_no_placeholder": "tabuľka_č",
    "floor_placeholder": "poschodie",
    "select_floor": "Vyberte Poschodie",
    "select_table": "Vyberte položku Tabuľka",
    "customer_name_macro": "%% meno_zákazníka %%",
    "date_time_macro": "%% dátum_čas %%",
    "table_no_macro": "%% table_no %%",
    "floor_macro": "%% podlaha %%",
    "template_name": "Názov šablóny",
    "login_with_4_digit_pin": "Prihláste sa pomocou svojho 4-ciferného PIN",
    "login_now_to_access_latest_insights": "Prihláste sa teraz a získajte prístup k najnovším odznakom",
    "mkt_BS_Title": "Blast SMS",
    "step_1": "Prispôsobiť správu",
    "step_2": "Vyberte položku Publikum",
    "step_3": "Naplánovať správu",
    "step_4": "Odoslať správu",
    "step_5": "Spravovať správu",
    "bs_tab_title": "Súhrnný prehľad kampane",
    "default_message": "Ahoj %% meno %%, Špeciálna zľava na neskoré raňajky! Najedzte sa s nami ešte dnes a získajte exkluzívnu 10% zľavu na váš účet!",
    "bs_Title": "Vyberte typ kampane",
    "sms_title": "SMS\/MMS",
    "reach_out_to_customer": "Oslovte zákazníkov prostredníctvom textových a multimediálnych správ.",
    "wp_text": "WhatsApp",
    "connect_with_wp": "Spojte sa so svojimi klientmi pomocou WhatsApp 💬",
    "send_discount_offers": "Ponuky zliav posielajte cez SMS 📲",
    "send_coupons_via_sms": "Posielajte kupóny cez SMS svojim zákazníkom 🎟️",
    "env_Cap": "Ponúkajte exkluzívne zľavy a propagačné akcie, aby ste prilákali svojich zákazníkov",
    "cmt_Caption": "Oslovte zákazníkov prostredníctvom textových a multimediálnych správ",
    "wp_caption": "Spojte sa s patrónmi pomocou WhatsApp pre bezproblémovú a priamu komunikáciu",
    "msg_Prev_Title": "Ukážka správy",
    "cou_Prev_Title": "Ukážka kupónu",
    "blast_SMS": "SMS\/MMS kampaň",
    "bs_P_Msg": "Vo fronte",
    "bs_S_Msg_C": "Nepodarilo sa",
    "bs_F_Msg_Count": "Doručené",
    "previous": "Predchádzajúce",
    "next_ST": "Ďalej",
    "next_S": "Ďalší krok",
    "req_Temp_Name_Title": "Vyžaduje sa názov šablóny",
    "req_Template_Tx_Title": "Vyžaduje sa text šablóny",
    "add_Temp_Title": "Pridať šablónu",
    "link_Desc": "Pridajte odkaz podľa vlastného výberu, aby si klienti mohli u vás rezervovať cez Facebook, Google alebo vašu vlastnú webovú stránku.",
    "audience_Title": "publikum",
    "client_G1_Title": "Noví klienti",
    "client_G2_Title": "Nedávni klienti",
    "client_G3_Title": "Verní klienti",
    "client_G4_Title": "Nefunkční klienti",
    "client_G5_Title": "Klienti podľa dátumu pripojenia",
    "on_Which_Day": "V ktorý deň",
    "on_Day": "V deň",
    "immediate_Desc": "Vaša správa bude odoslaná okamžite, začnite kliknutím na Ďalej.",
    "never_Desc": "Spustenie tejto kampane je naplánované natrvalo, kým ju neukončíte manuálne.",
    "end_Desc": "Kampaň sa skončí v deň, ktorý si tu vyberiete.",
    "success_Message": "Kampaň bola úspešne uložená!",
    "send_Success_Message": "Kampaň bola úspešne odoslaná!",
    "msg_Name_Field_Title": "Názov kampane",
    "valid_campaign_title": "Zadajte platný názov kampane",
    "msg_Type_Title": "Typ správy",
    "sms_Desc": "1 kredit na SMS",
    "mms_Desc": "3 kredity za MMS",
    "client_GTO_1": "deň",
    "client_GTO_2": "Hodiny",
    "minutes": "Minúty",
    "client_GTO_4": "týždeň",
    "time_Option_1": "deň",
    "time_Option_2": "Hodiny",
    "c_Grp_Day_1": "90 dní",
    "c_Grp_Day_2": "60 dní",
    "c_Grp_Day_3": "30 dní",
    "first": "Po prvé",
    "second_T": "Po druhé",
    "third_T": "Po tretie",
    "fourth_T": "Po štvrté",
    "fifth_T": "Po piate",
    "delete_BS_T": "Táto kampaň bude natrvalo odstránená.",
    "delete_MBS_T": "Tieto kampane budú natrvalo odstránené.",
    "cFT_1": "Telefón",
    "cFT_2": "Kontaktné meno",
    "cFT_3": "Priezvisko kontaktu",
    "cFT_4": "Kontaktný e-mail",
    "cFT_5": "Poznámka",
    "cFT_6": "narodeniny",
    "cFT_7": "Názov spoločnosti",
    "cF_Meta_Tag_1": "%% telefón %%",
    "cF_Meta_Tag_2": "%% krstné meno %%",
    "cF_Meta_Tag_3": "%% priezvisko %%",
    "cF_Meta_Tag_4": "%% email %%",
    "cF_Meta_Tag_5": "%% poznámka %%",
    "cF_Meta_Tag_6": "%% narodeniny %%",
    "cF_Meta_Tag_7": "%% názov_spoločnosti %%",
    "cF_Name_1": "telefón",
    "cF_Name_2": "krstné meno",
    "cF_Name_3": "priezvisko",
    "cF_Name_4": "e-mailom",
    "cF_Name_5": "poznámka",
    "cF_Name_6": "narodeniny",
    "cF_Name_7": "názov_spoločnosti",
    "all_clients": "AllClients",
    "aud_Type_2": "ClientGroups",
    "aud_Type_3": "Zákaznícke skupiny",
    "aud_Type_T1": "Všetci klienti",
    "aud_Type_T2": "Skupiny klientov",
    "aud_Type_T3": "Skupiny zákazníkov",
    "new_clients": "Noví klienti",
    "client_G2": "Nedávni klienti",
    "client_G3": "Verní klienti",
    "client_G4": "Nefunkční klienti",
    "client_G5": "Klienti podľa dátumu pripojenia",
    "never_text": "Nikdy",
    "ending_on": "Koniec dňa",
    "send_T2": "Naplánované",
    "send_Type_3": "Opakujúce sa",
    "daily": "Denne",
    "weekly_T": "Týždenne",
    "monthly_T": "Mesačne",
    "yearly_T": "Ročne",
    "each": "Každý",
    "on_the": "Na stránke The",
    "monday": "pondelok",
    "exclusive_offer": "Exkluzívna ponuka pre vás",
    "redeem_now": "Uplatniť teraz",
    "redeem_with_cashier": "Uplatnite prosím u pokladníka",
    "lg_Day_2": "utorok",
    "lg_Day_3": "streda",
    "lg_Day_4": "štvrtok",
    "lg_Day_5": "piatok",
    "lg_Day_6": "sobota",
    "lg_Day_7": "nedeľa",
    "msg_Pre_T": "Dominos",
    "cA_Edit_T1": "Všetky",
    "cA_Edit_T2": "Zahrnuté",
    "cA_Edit_T3": "Vylúčené",
    "SMS": "SMS",
    "MMS": "MMS",
    "usd_T": "USD",
    "cad_T": "CAD",
    "msg": "Správa",
    "which_Day": "Ktoré dni",
    "end_Date_Tx": "Dátum ukončenia",
    "sDate_Err": "Dátum začiatku musí byť väčší alebo rovnaký ako dnešný deň",
    "eDate_Err": "Dátum ukončenia musí byť neskorší ako dátum začiatku",
    "start_Date_Req": "Vyžaduje sa dátum začiatku",
    "end_Date_Req": "Vyžaduje sa dátum ukončenia",
    "time_req": "Vyžaduje sa čas",
    "client_GT1": "Klienti sa prihlásili v posledn",
    "client_GT2": "Klienti s pripojením v posl",
    "client_GT3": "Klienti s min",
    "client_GT4": "s posledným",
    "client_GT5": "ktorý sa nevrátil v poslednom",
    "dummy_Phone_No": "+91987-654-3210",
    "test_T": "Test",
    "dummy_Birth_Date": "01-01-2001",
    "image_Req": "Vyžaduje sa médium alebo adresa URL",
    "time_Title": "čas",
    "start_date": "Dátum začiatku",
    "end_date": "Dátum ukončenia",
    "auto_Send_T": "Automaticky odosielať klientom",
    "add_Media": "Pridať médiá",
    "in_Title": "In",
    "c_Temp_T": "Šablóna kampane",
    "temp_NT": "Názov šablóny",
    "type_T": "Typ",
    "select_template_type": "Vyberte typ šablóny",
    "sel_Temp_T": "Vyberte Typ šablóny",
    "sms_temp_t": "Šablóna SMS",
    "temp_T": "Text šablóny",
    "default_Msg": "Ahoj %% meno %%, Špeciálna zľava na neskoré raňajky! Najedzte sa s nami ešte dnes a získajte exkluzívnu 10% zľavu na váš účet!",
    "refill_T": "Doplňte",
    "avl_Credit": "Dostupné kredity",
    "req_Credit": "Celkový počet požadovaných kreditov",
    "rem_Credit": "Zostávajúci kredit",
    "refill_Credit": "Kredity na doplnenie",
    "clients_Req": "Vyberte aspoň jedného klienta",
    "subclient_Req": "Vybraní klienti nie sú predplatiteľmi.",
    "refill_Desc": "Prosím, doplňte požadované kredity",
    "url_Regex": "^((http|https):\/\/).....+$",
    "sd_Invalid": "Dátum začiatku je neplatný",
    "ed_Invalid": "Dátum ukončenia je neplatný",
    "img_Url_Invalid": "Neplatná adresa URL obrázka",
    "time_Invalid": "Čas je neplatný",
    "time_And_Date_Invalid": "Zadajte platný dátum a čas",
    "time_Invalid_Bfr": "Vyberte čas aspoň o 5 minút neskôr od aktuálneho času",
    "sod_Req": "Vyžaduje sa odoslanie v deň",
    "add_Card": "Pridať novú kartu",
    "sm_D_Rep_Title": "Správa o doručení SMS\/MMS",
    "send_Now_T": "Odoslať teraz",
    "schd_Now_T": "Naplánujte si SMS",
    "test_SMS_T": "Odoslať testovaciu SMS",
    "save_AD_T": "Uložiť ako koncept",
    "back_TE_T": "Späť na Upraviť",
    "reset_tex": "Resetovať",
    "update_tex": "Aktualizovať",
    "dum_msg1": "Ak chcete zobraziť meno zákazníka, použite v šablóne %% customer_name %%",
    "dum_msg2": "Na zobrazenie času rezervácie použite %% date_time %% v šablóne",
    "dum_msg3": "Na zobrazenie čísla tabuľky použite %% table_no %% v šablóne",
    "embedded_link": "Vložený odkaz",
    "img_title": "Obrázok",
    "img_input_text": "Text:",
    "img_join_text": " aby ste sa pridali k nášmu zoznamu",
    "img_copy_text": "Kopírovať",
    "img_not_found": "Vygenerovaný obrázok sa nenašiel!!",
    "or_text": "ALEBO",
    "web_signup_image_content": "Odoslaním tohto formulára a registráciou prostredníctvom SMS súhlasíte s prijímaním marketingových textových správ (ako sú propagačné kódy a pripomienky). Môžu sa účtovať poplatky za správy a dáta. Frekvencia správ sa líši. Z odberu sa môžete kedykoľvek odhlásiť odpovedaním STOP",
    "img_download_success": "Obrázok bol úspešne stiahnutý",
    "embedded_link_copied": "Vložený odkaz bol úspešne skopírovaný",
    "media_url_required": "Vyžaduje sa médium alebo adresa URL",
    "invalid_image_url": "Neplatná adresa URL obrázka",
    "invalid_file": "Súbor je neplatný",
    "image_error_1mb": "Vložte obrázok, ktorý je menší ako 1 MB",
    "image_error_2_5mb": "Vložte obrázok, ktorý je menší ako 2,5 MB",
    "image_error_3mb": "Vložte obrázok, ktorý má menej ako 3 MB",
    "change_title": "Zmeniť",
    "drop_file_text": "Sem presuňte súbor alebo ho nahrajte kliknutím",
    "apply_text": "Použiť",
    "search_here": "Hľadajte tu",
    "update_status": "Stav aktualizácie",
    "reservation_deleted_permanently": "Táto rezervácia bude natrvalo odstránená.",
    "table_text": "Tabuľky",
    "add_table_button": "Pridať novú tabuľku",
    "add_table_text": "Pridať tabuľku",
    "edit_table_text": "Upraviť tabuľku",
    "table_delete_text": "Táto tabuľka bude natrvalo odstránená.",
    "multiple_table_delete_text": "Tieto tabuľky budú natrvalo odstránené.",
    "table_error_name": "Zadajte názov tabuľky",
    "table_error_invalid_name": "Zadajte platný názov tabuľky",
    "table_error_floor": "Vyberte podlahu",
    "table_error_select": "Vyberte tabuľku",
    "table_capacity_text": "Kapacita stola",
    "capacity_text": "Kapacita",
    "table_occasion_text": "Nájdite si svoj stôl na každú príležitosť",
    "table_name_text": "Názov tabuľky",
    "table_capacity_error": "Vyžaduje sa kapacita stola",
    "cancel_text": "Zrušiť",
    "submit_text": "Odoslať",
    "select_valid_date": "Vyberte platný dátum",
    "select_valid_time": "Vyberte platný čas",
    "valid_contact_number": "Zadajte platné kontaktné číslo",
    "date_req": "Vyžaduje sa dátum",
    "date_error_later_than_today": "Dátum musí byť neskorší alebo rovnaký ako dnešný dátum",
    "time_error_later_than_5_minutes": "Vyberte čas aspoň o 5 minút neskôr od aktuálneho času",
    "number_person_req": "Vyžaduje sa počet osôb",
    "contact_no_req": "Vyžaduje sa kontaktné číslo",
    "contact_no": "Kontaktné číslo",
    "select_time": "Vyberte položku Čas",
    "for_how_many_person": "Pre koľko osôb?",
    "f_name": "Krstné meno",
    "l_name": "Priezvisko",
    "business_name": "Obchodné meno",
    "business_no": "Obchodné číslo",
    "no_for_sign_in": "Toto číslo sa používa na prihlásenie",
    "business_email": "Obchodný e-mail",
    "notes_tex": "Poznámky",
    "floor_deleted_permanently": "Toto poschodie bude natrvalo odstránené.",
    "add_floor": "Pridať podlahu",
    "edit_floor": "Upraviť poschodie",
    "name_text": "Meno",
    "mobile_no": "Mobil č.",
    "person_text": "Osoba",
    "date_and_time": "Dátum a čas",
    "actions_text": "Akcie",
    "extra_text": "Extra",
    "floor_name_req": "Vyžaduje sa názov poschodia",
    "floor_name": "Názov poschodia",
    "status_text": "Stav",
    "category_status_req": "Vyžaduje sa stav kategórie",
    "table_deleted_permanently": "Táto tabuľka bude natrvalo odstránená.",
    "tables_deleted_permanently": "Tieto tabuľky budú natrvalo odstránené.",
    "back_to_home": "Back To Home",
    "link_copied_text": "Odkaz bol skopírovaný do schránky",
    "cust_dash_head_to_title": "do",
    "cust_dash_head_location_title": "Poloha",
    "select_location_title": "Vyberte umiestnenie",
    "all_locations_label": "all_locations",
    "rep_customer_feedback_analytics": "😊 Sledujte spätnú väzbu od zákazníkov pomocou analýzy v reálnom čase",
    "rep_customer_txt": "zákazníkov",
    "rep_delete_title": "Táto reputácia bude natrvalo vymazaná.",
    "rep_qr_def_txt": "Naskenujte QR kód a podeľte sa s nami o cennú spätnú väzbu týkajúcu sa vašich skúseností.",
    "delete_title": "Odstrániť",
    "user_list_title": "Zoznam klientov",
    "os_nfc_txt": "NFC karty",
    "os_sign_holder_txt": "Stojan na znamenie",
    "os_store_Sign_holder_txt": "Držiaky obchodných značiek",
    "StaT": "Štatistiky",
    "AllgT": "Všetky skupiny",
    "FavT": "Obľúbené",
    "MostActT": "Najaktívnejší",
    "grT": "Názov skupiny",
    "keywT": "Kľúčové slová",
    "exSubT": "Správa pre existujúceho predplatiteľa",
    "ArchT": "Archivované",
    "gNotiSms": "Informujte ma o každom novom kontakte prostredníctvom SMS",
    "gNotiEmail": "Informujte ma o každom novom kontakte prostredníctvom e-mailu",
    "reqGName": "Vyžaduje sa názov skupiny",
    "validGN": "Zadajte platný názov skupiny",
    "valid_phone_no": "Zadajte platné telefónne číslo",
    "phone_no_req": "Vyžaduje sa telefónne číslo",
    "required_message_text": "Vyžaduje sa správa",
    "required_discount_text": "Vyžaduje sa zľava",
    "less_than_discount": "Výška zľavy musí byť nižšia ako 5000",
    "discount_percentage_invalid": "Percento zľavy musí byť menšie alebo rovné 100",
    "discount_type_req": "Vyžaduje sa typ zľavy",
    "discount_text_req": "Vyžaduje sa text kupónu",
    "reqContactNo": "Vyžaduje sa kontaktné číslo",
    "reqMsgNT": "Vyžaduje sa názov kampane",
    "reqLinkT": "Vyžaduje sa odkaz",
    "delMGT": "Tieto skupiny budú natrvalo odstránené.",
    "defMemMsg": "Sem zadajte svoju správu",
    "add_cust_to_grp_title": "Pridať klienta do skupiny",
    "group_title": "skupiny",
    "group_create_title": "Vytvoriť skupinu",
    "group_name_txt": "Názov skupiny",
    "group_table_keyword_title": "Kľúčové slovo",
    "actions_title": "Akcie",
    "clients_title": "klientov",
    "send_title": "Odoslať",
    "qr_title": "QR",
    "delete_group_txt": "Táto skupina bude natrvalo odstránená.",
    "delete_groups_txt": "Tieto skupiny budú natrvalo odstránené.",
    "delete_client_title": "Tento klient bude natrvalo odstránený.",
    "delete_clients_title": "Títo klienti budú natrvalo vymazaní.",
    "delete_multiple_title": "Viacnásobné odstránenie",
    "wel_sms_mms": "Chcete poslať uvítaciu SMS\/MMS?",
    "key_words_title": "Kľúčové slová",
    "srch_plch_txt": "Hľadajte tu",
    "req_location_id_title": "Poloha je povinná",
    "create_text": "Vytvorte",
    "view_text": "Zobraziť",
    "immediately": "Okamžite",
    "business_name_req": "Vyžaduje sa obchodný názov",
    "business_no_req": "Vyžaduje sa firemné číslo",
    "valid_business_name": "Zadajte platný obchodný názov",
    "valid_business_no": "Zadajte platné obchodné číslo",
    "address_req": "Vyžaduje sa adresa",
    "valid_address": "Vyberte platnú adresu",
    "time_zone_req": "Vyžaduje sa časové pásmo",
    "image_req": "Vložte obrázok, ktorý je menší ako 2,5 MB",
    "valid_file": "Súbor je neplatný.",
    "logo": "Logo",
    "time_zone": "Časové pásmo",
    "save": "Uložiť",
    "account_type_req": "Vyžaduje sa typ účtu",
    "gst_no_req": "Vyžaduje sa číslo GST",
    "gst_no_valid": "Zadajte platné číslo GST",
    "set_up_payments": "Nastavte platby",
    "billing_details": "Zadajte svoje fakturačné údaje",
    "billing_details_desc": "Vaše fakturačné údaje sa zobrazia na vašej mesačnej faktúre z menuonline.",
    "account_type": "Typ účtu",
    "select_account_type": "Vyberte typ účtu",
    "gst_no": "Číslo GST",
    "transaction_details": "Podrobnosti transakcie",
    "payment_method": "Spôsob platby",
    "billing_period": "Fakturačné obdobie",
    "paid_by": "Zaplatené",
    "download": "Stiahnuť",
    "pay_now": "Zaplať teraz",
    "pull_down_refresh": "Potiahnutím nadol obnovíte",
    "release_refresh": "Uvoľnením obnovíte",
    "billing_details_text": "Fakturačné údaje",
    "payment_methods": "Spôsoby platby",
    "invoice": "Faktúra",
    "invoice_to": "Fakturovať komu:",
    "issue_date": "Dátum vydania",
    "due_date": "Dátum splatnosti",
    "amount_due": "Splatná suma",
    "charges": "Poplatky",
    "sub_total_capitalize": "Medzisúčet",
    "hst": "HST",
    "grand_total": "Celkový súčet",
    "invoice_generated_on": "Faktúra vygenerovaná dňa",
    "contact_us": "Kontaktujte nás",
    "invoice_issue": "Ak ste si prešli svoj účet a stále máte otázku",
    "call": "Zavolajte",
    "send_sms": "Pošlite SMS",
    "payment_success": "Platba prebehla úspešne",
    "edit_credit_card": "Upraviť kreditnú kartu",
    "add_credit_card": "Pridať kreditnú kartu",
    "modify_card_info": "Upravte informácie o svojej karte",
    "enter_card_info": "Zadajte informácie o svojej karte",
    "account_no_req": "Vyžaduje sa číslo účtu",
    "card_name_req": "Vyžaduje sa meno na karte",
    "expiry_date_req": "Vyžaduje sa dátum vypršania platnosti",
    "valid_expiry_date": "Zadajte platný dátum vypršania platnosti",
    "valid_cvv": "Zadajte platný cvv",
    "cvv_req": "Vyžaduje sa CVV",
    "card_no": "Číslo karty",
    "name_of_card": "Meno na karte",
    "expiry": "Expirácia",
    "mm_text": "MM\/RR",
    "cvv": "CVV",
    "set_as_default": "Nastaviť ako predvolené",
    "add_new_card": "Pridať novú kartu",
    "all_credit_card": "Všetky vaše kreditné karty",
    "fastest_checkout": "Najrýchlejšia platba vďaka bezpečnej a spoľahlivej platforme, ktorej dôveruje veľká skupina podnikov",
    "coupon_button_req": "Vyžaduje sa text tlačidla kupónu",
    "max19_characters_allowed": "Povolených je maximálne 19 znakov",
    "fine_print_text_req": "Vyžaduje sa drobný text",
    "clients_req": "Vyberte aspoň jedného klienta",
    "client_groups": "ClientGroups",
    "day_text": "deň",
    "valid_days": "Hodnota dňa je príliš veľká, zadajte platné dni",
    "hours_text": "hodiny",
    "enter_valid_hours": "Hodnota hodín je príliš veľká, zadajte platné hodiny",
    "enter_valid_min": "Hodnota minút je príliš veľká, zadajte platné minúty",
    "clients_with_connection_required": "Vyžaduje sa klient s pripojením v poslednom",
    "connection_required": "Vyžaduje sa pripojenie",
    "connection_value_too_large": "Hodnota pripojení je príliš veľká, zadajte platné pripojenie",
    "minutes_required": "Vyžaduje sa niekoľko minút",
    "clients_with_at_least_connection_required": "Vyžaduje sa klient s aspoň pripojením",
    "last_minutes_req": "S poslednými minútami sa vyžaduje",
    "clients_not_returned_required": "Kto sa nevrátil v posledných minútach je povinný",
    "not_return_in_last_minutes_invalid": "Nenávrat v posledných minútach musí byť kratší ako v posledných minútach.",
    "customerGroups": "Zákaznícke skupiny",
    "select_customer_group": "Vyberte skupinu zákazníkov",
    "location_required": "Poloha je povinná",
    "start_date_required": "Vyžaduje sa dátum začiatku",
    "start_date_invalid": "Dátum začiatku je neplatný",
    "start_date_invalid_error": "Dátum začatia musí byť vyšší ako dnešný alebo rovnaký ako dnes",
    "recurring": "Opakujúce sa",
    "end_date_required": "Vyžaduje sa dátum ukončenia",
    "end_date_invalid": "Dátum ukončenia je neplatný",
    "end_date_invalid_error": "Dátum ukončenia musí byť neskorší ako dátum začiatku",
    "time_invalid": "Čas je neplatný",
    "monthly_text": "Mesačne",
    "send_day_req": "Vyžaduje sa odoslanie v deň",
    "loyal_clients": "Verní klienti",
    "recent_clients": "Nedávni klienti",
    "lapsed_clients": "Nefunkční klienti",
    "clients_connection_date": "Klienti podľa dátumu pripojenia",
    "scheduled_text": "Naplánované",
    "weekly": "Týždenne",
    "selected_clients_not_subscriber": "Vybraní klienti nie sú predplatiteľmi.",
    "message_preview_title": "Ukážka správy",
    "coupon_preview_title": "Ukážka kupónu",
    "form_text": "Formulár",
    "preview_text": "Ukážka",
    "next_text": "Ďalej",
    "send_test_SMS": "Odoslať testovaciu SMS",
    "save_draft": "Uložiť ako koncept",
    "back_to_edit": "Späť na Upraviť",
    "select_payment_method": "Vyberte spôsob platby",
    "schedule_SMS": "Naplánujte si SMS",
    "send_now": "Odoslať teraz",
    "get_more_ratings": "Získajte viac hodnotení",
    "overview": "Prehľad",
    "reset_campaign": "Obnoviť kampaň",
    "permissions": "Povolenia",
    "location_name": "Názov miesta",
    "phone_no": "Telefónne číslo",
    "location_email_address": "E-mailová adresa miesta",
    "located_business": "Kde sa nachádza vaša firma?",
    "business_logo": "Obchodné logo",
    "congratulations": "gratulujem",
    "almost_done": "Už ste takmer hotoví",
    "publish": "Publikovať",
    "about_your_business": "O vašom podnikaní",
    "add_your_location": "Pridajte svoju polohu",
    "publish_location": "Miesto zverejnenia",
    "location_name_req": "Vyžaduje sa názov miesta",
    "valid_location_name": "Zadajte platný názov miesta",
    "business_logo_req": "Vyžaduje sa firemné logo",
    "please_accept_terms": "Prijmite prosím zmluvné podmienky",
    "add_new_location": "Pridať novú polohu",
    "edit_location": "Upraviť polohu",
    "add_location": "Pridať polohu",
    "existing_msg_subscriber_txt": "Správa pre existujúceho predplatiteľa",
    "msg_capitalize_txt": "Správa",
    "group_noti_sms": "Informujte ma o každom novom kontakte prostredníctvom SMS",
    "group_noti_email": "Informujte ma o každom novom kontakte prostredníctvom e-mailu",
    "group_member_msg": "Zdá sa, že už ste súčasťou našej skupiny!!",
    "group_mend_msg": "STOP na koniec. HELP o pomoc. Môžu sa účtovať poplatky za SMS a dáta",
    "Disclaimer_title": "Text vylúčenia zodpovednosti: ",
    "group_def_msg": "Teraz sme online! Pozrite si našu ponuku služieb a zarezervujte si ďalší termín už dnes.",
    "required_msg_txt": "Vyžaduje sa správa",
    "required_Key_txt": "Kľúčové slová sú povinné",
    "required_mem_msg": "Vyžaduje sa správa odberateľa",
    "client_list_title": "Zoznam klientov",
    "add_contact_txt": "Pridať kontakt",
    "delete_all_clients_txt": "Odstrániť všetkých klientov",
    "delete_all_clients_msg": "Naozaj chcete vymazať všetkých klientov? Nie je možné ich získať späť.",
    "delete_all_txt": "Odstrániť všetko",
    "timeline_title": "Časová os",
    "unsubscribe_title": "Zrušte odber",
    "subscribe_title": "Prihlásiť sa na odber",
    "unsubscribe_confirm_msg": "Chcete označiť tohto zákazníka ako odhláseného?",
    "subscribe_confirm_msg": "Chcete označiť tohto zákazníka ako odberateľa?",
    "no_title": "Nie",
    "yes_title": "áno",
    "client_name_title": "Meno klienta",
    "source_title": "Zdroj",
    "contact_file_Req": "Vyžaduje sa súbor kontaktov",
    "req_title": "Povinné",
    "opt_in_req": "Importované kontakty musia mať 100% súhlas",
    "image_invalid_error": "Súbor je neplatný.",
    "import_contacts_msg": "Importovať kontakty",
    "csv_upload_title": "Súbor CSV kontaktov na odovzdanie *",
    "csv_file_desc": "Nahrajte iba súbor csv – stĺpce súboru csv – meno, priezvisko, e-mailová adresa, mobilné číslo, pohlavie, dátum narodenia (MM\/DD\/RRRR) – žiadne medzery, pomlčky alebo zátvorky v mobilnom čísle",
    "to_download_title": "Pre stiahnutie demo súboru kliknite sem",
    "contains_header_title": "Áno, tento súbor obsahuje hlavičky",
    "opt_in_100_txt": "Áno, tieto importované kontakty sú na 100 % prihlásené",
    "DisT": "Text vylúčenia zodpovednosti: ",
    "gMendMsg": "STOP na koniec. HELP o pomoc. Môžu sa účtovať poplatky za SMS a dáta",
    "reqKeyT": "Kľúčové slová sú povinné",
    "reqMemMsg": "Vyžaduje sa správa odberateľa",
    "reqMsgT": "Vyžaduje sa správa",
    "gMemberMsg": "Zdá sa, že už ste súčasťou našej skupiny!!",
    "gdefMsg": "Teraz sme online! Pozrite si našu ponuku služieb a zarezervujte si ďalší termín už dnes.",
    "next_title": "Ďalej",
    "male_title": "Mužský",
    "male_val": "mužský",
    "female_title": "Žena",
    "female_val": "samica",
    "others_txt": "Iní",
    "others_val": "iní",
    "validBirthdate": "Zadajte platný dátum narodenia",
    "valid_phone_no_title": "Zadajte platné telefónne číslo",
    "required_phone_no_title": "Vyžaduje sa telefónne číslo",
    "add_new_client_txt": "Pridať nového klienta",
    "update_client_txt": "Aktualizovať klienta",
    "phone_num_text": "Telefónne číslo",
    "dob_title": "nar",
    "select_gender_title": "Vyberte Pohlavie",
    "timelineTitle": "Časová os",
    "confirm_location": "Potvrďte polohu",
    "feedback_title": "Spätná väzba",
    "rep_question_1": "Bol podnik čistý?",
    "rep_que_2": "Bolo vaše jedlo dobré?",
    "rep_que_3": "Bola naša služba priateľská?",
    "rep_que_4": "Bola naša služba rýchla?",
    "business_location_req_title": "Vyžaduje sa sídlo firmy",
    "valid_location_err_txt": "Vyberte platnú adresu",
    "rep_management_title": "Získajte viac recenzií Správa",
    "rep_about_desc": "Reputácia vám pomôže zvýšiť hodnotenie Google tým, že požiadate svojich zákazníkov o spätnú väzbu",
    "preview_title": "Ukážka",
    "rep_preview_txt": "Ukážka reputácie",
    "back_title": "Späť",
    "fine_print_txt": "Uplatnite prosím u pokladníka",
    "redeem_me_title": "Vykúp ma",
    "rep_nxt_visit_txt": "Pri ďalšej návšteve",
    "device_type": "Typ použitého zariadenia",
    "connection_method": "Spôsob pripojenia",
    "peak_days": "Vrcholové dni",
    "peak_hours": "Špičkové hodiny",
    "guest_by_day": "Hosť podľa dňa",
    "guest_visit": "Návšteva hosťa",
    "connection": "Pripojenie",
    "connection_duration": "Trvanie pripojenia",
    "guest_visit_1": "1 krát",
    "guest_visit_2": "2 krát",
    "guest_visit_3_5": "3-5 krát",
    "guest_visit_6_10": "6-10 krát",
    "guest_visit_11_25": "11-25 krát",
    "guest_visit_26_100": "26-100 krát",
    "guest_visit_100_plus": "100+ krát",
    "device_android_total": "Celkový Android",
    "device_android": "Android",
    "device_desktop": "Desktop",
    "device_ios": "Ios",
    "device_ios_total": "Celkový Ios",
    "device_desktop_total": "Celková plocha",
    "connection_duration_10": "<=10 min",
    "connection_duration_20": "11-20 min",
    "connection_duration_30": "21-30 min",
    "connection_duration_40": "31-45 min",
    "connection_duration_60": "46-60 min",
    "connection_method_email": "Email",
    "connection_method_sms": "SMS",
    "connection_method_google": "Google",
    "connection_method_facebook": "Facebook",
    "age_category_1": "<18",
    "age_category_2": "18-24",
    "age_category_3": "25-34",
    "age_category_4": "35-44",
    "age_category_5": "45-54",
    "age_category_6": "55+",
    "all_guest_txt": "Všetci hostia",
    "new_Guest_txt": "Nový hosť",
    "connections_txt": "Spojenia",
    "hotspot": "Hotspot",
    "hotspot_list": "Zoznam hotspotov",
    "add_new_hotspot": "Pridať nový hotspot",
    "hotspot_information": "Informácie o hotspote",
    "edit_details_button": "Upraviť podrobnosti",
    "wifi_info_message": "Pripojte sa a užívajte si bezplatné Wi-Fi",
    "connection_message": "Skvelé, boli ste pripojení k ",
    "connection_message_suffix": " WiFi",
    "wifi": "WiFi",
    "login_to_access": "Ak chcete získať prístup, prihláste sa",
    "verification_code": "Overovací kód",
    "verification_code_message": "Zadajte overovací kód odoslaný na adresu ",
    "wifi_user_email": "adamo@gmail.com",
    "wifi_label": "Wifi",
    "your_name": "Mimochodom, ako sa voláš?",
    "your_birthdate": "Môžete nám povedať, aký je váš dátum narodenia?",
    "request_guest_wifi_name": "Zadajte názov Wifi pre hosťa",
    "request_device_key": "Zadajte kľúč zariadenia",
    "request_maximum_internet_access_length": "Vyberte maximálnu dĺžku prístupu na internet",
    "mac_address": "MAC adresu",
    "device_port": "Port zariadenia",
    "hardware": "Hardvér",
    "current_uptime": "Aktuálna doba prevádzkyschopnosti",
    "nearby_devices": "Zariadenia v okolí",
    "firmware": "Firmvér",
    "who_are_you": "kto si ty?",
    "where_to_contact_you": "Kde vás môžeme kontaktovať?",
    "your_area_code": "Aká je vaša predvoľba?",
    "connected": "Pripojené",
    "delete_hotspot_message": "Tento hotspot bude natrvalo odstránený.",
    "delete_multiple_hotspots_message": "Tieto hotspoty budú natrvalo odstránené.",
    "speed_error": "Rýchlosť by mala byť aspoň 0,01",
    "speed_max_error": "Zadajte hodnotu do 1024 pre neobmedzenú rýchlosť alebo vyberte nižšiu hodnotu v rámci podporovaného rozsahu",
    "device_ssid": "SSID zariadenia",
    "device_ssid_two": "SSID zariadenia dva",
    "device_ssid_two_wpa": "SSID zariadenia dva WPA",
    "device_key": "Kľúč zariadenia",
    "select_location": "Vyberte umiestnenie",
    "select_maximum_internet_access_length": "Vyberte Maximálna dĺžka internetového prístupu",
    "download_speed": "Rýchlosť sťahovania",
    "upload_speed": "Rýchlosť nahrávania",
    "network_length_1": "15 minút",
    "network_length_2": "30 minút",
    "network_length_3": "45 minút",
    "network_length_4": "1 hodina",
    "network_length_5": "2 hodiny",
    "network_length_6": "4 hodiny",
    "network_length_7": "6 hodín",
    "network_length_8": "8 hodín",
    "network_length_9": "10 hodín",
    "network_length_10": "12 hodín",
    "employee_wifi_name": "Wifi meno zamestnanca",
    "employee_wifi_password": "Heslo zamestnanca Wifi",
    "guest_wifi_name": "Názov Wi-Fi pre hosťa",
    "menu_other_products_txt": "Ostatné produkty | Menu online",
    "menu_home_text": "Domov | Menu online",
    "whatsapp_title": "Whatsapp",
    "select_campaign_type": "Vyberte typ kampane",
    "select_readymade_templates": "Vyberte si z hotových šablón alebo si vytvorte vlastné",
    "campaign_title_required": "Vyžaduje sa názov kampane",
    "type_here": "Napíšte sem...",
    "location_permission_req": "Vyžaduje sa povolenie na umiestnenie",
    "platform_permission_req": "Vyžaduje sa povolenie platformy",
    "profile_picture": "Profilová fotka",
    "click_to_upload": "Kliknutím nahráte",
    "location_permission": "Povolenie na umiestnenie",
    "pin": "Pin",
    "platform_permission": "Povolenie platformy",
    "set_pin": "Nastavte PIN",
    "list_of_users": "Zoznam užívateľov",
    "create_user": "Vytvoriť používateľa",
    "terms_and_condition_req": "Vyžadujú sa zmluvné podmienky",
    "terms_and_conditions": "Zmluvné podmienky",
    "recommended": "ODPORÚČAME",
    "mo": "\/mes",
    "unsubscribe": "Zrušte odber",
    "cancel_subscription": "Naozaj chcete zrušiť odber?",
    "upgrade": "Inovovať",
    "plan_upgraded_successfully": "Plán bol úspešne inovovaný!",
    "menu_online": "Menu online",
    "support": "Podpora",
    "wifiC": "WIFI",
    "billing": "Fakturácia",
    "please_upgrade": "Ak chcete využívať všetky funkcie, inovujte",
    "you_have_subscribed": "Prihlásili ste sa na odber ",
    "plan": "plánovať",
    "of_text": "z",
    "days": "dní",
    "remaining_days_until_plan": " Počet zostávajúcich dní, kým bude váš plán vyžadovať aktualizáciu",
    "manage_your_screens": "Spravujte svoje obrazovky",
    "screens_you_save": "Obrazovky, ktoré máte",
    "add_more_screens": "Pridať ďalšie obrazovky",
    "addition_screen": "Obrazovka pridania",
    "per_screen": "$ za obrazovku",
    "per_box": "$ za krabicu",
    "shipping_changes": "Poplatky za dopravu",
    "upto4_boxes": "až 4 boxy ",
    "charge_now": "Teraz dostanete poplatok",
    "updated_reoccuring": "Vaše aktualizované obnovenie bude",
    "current_reoccuring": "Aktuálne sa opakujúce: ",
    "no_text": "Nie",
    "android_box": "Android Box",
    "old_password_req": "Vyžaduje sa staré heslo",
    "new_password_req": "Vyžaduje sa nové heslo",
    "confirm_password_req": "Vyžaduje sa potvrdenie hesla",
    "password_do_not_match": "Heslá sa nezhodujú",
    "old_password": "Staré heslo",
    "new_password": "Nové heslo",
    "confirm_password": "Potvrďte heslo",
    "copy_working_hours": "Kopírovať tieto pracovné hodiny na všetky dni?",
    "yes_copy": "Áno, kopírovať",
    "closed": "ZATVORENÉ",
    "opening_time": "Otváracia doba",
    "closing_time": "Čas zatvárania",
    "description": "Popis",
    "file_exist": "súbor už existuje, vyberte iný súbor",
    "bussiness_images": "Obchodné obrázky",
    "display_info_on_market_place": "Chceli by ste na svojom Marketplace zobraziť nižšie uvedené informácie?",
    "profile_ready": "Váš profil je pripravený!",
    "client_book_with_online": "Klienti si teraz môžu u vás rezervovať online. Ak chcete začať, zdieľajte nižšie uvedený odkaz.",
    "copy_link": "Kopírovať odkaz",
    "see_your_profile": "Pozrite si svoj profil",
    "ok_got_it": "Dobre, rozumiem",
    "preview_profile": "Ukážte svoj profil a uvidíte, ako by vyzeral.",
    "opening_hours": "OTVÁRACIE HODINY",
    "display_data": "Zobrazenie údajov",
    "manage_profile": "Spravovať profil",
    "phone_req": "Vyžaduje sa telefón",
    "market_place_img_req": "Vyžaduje sa obrázok trhoviska",
    "add_new_market_place": "Pridať nový trhovisko",
    "edit_market_place": "Upraviť MarketPlace",
    "no_reviews_yet": "Zatiaľ žiadne recenzie",
    "good": "Dobre",
    "average": "Priemerná",
    "add_marketPlace": "Pridať MarketPlace",
    "all_title": "Všetky",
    "included_title": "Zahrnuté",
    "excluded_title": "Vylúčené",
    "clients_subscribed": "Klienti sa prihlásili v posledn",
    "clients_groups": "Skupiny klientov",
    "customer_groups": "Skupiny zákazníkov",
    "audience_title": "publikum",
    "client_gt5": "ktorý sa nevrátil v poslednom",
    "select_all": "Vyberte možnosť Všetky",
    "modify": "Upraviť",
    "campaign_title": "Názov kampane",
    "msg_type": "Typ správy",
    "enter_discount": "Zadajte zľavu",
    "discount_type": "Typ zľavy",
    "coupon_text": "Text kupónu",
    "enter_coupon_text": "Zadajte text kupónu",
    "coupon_button_text": "Text tlačidla kupónu",
    "enter_coupon_button_text": "Zadajte text tlačidla kupónu",
    "fine_prin": "Jemná tlač",
    "enter_fine_prin": "Zadajte drobný text",
    "campaign_dec": "Kampaň môžete upraviť 30 minút pred jej začiatkom. Poplatky za SMS kampaň budú účtované 30 minút pred začiatkom kampane.",
    "blast_text_message_dec": "Vaša blesková textová správa je pripravená. Vyberte spôsob platby a odošlite správy.",
    "payment_completed": " Platba bude dokončená o ",
    "total_cost": "Celkové náklady",
    "close_title": "Zavrieť",
    "friday": "piatok",
    "saturday": "sobota",
    "sunday": "nedeľa",
    "thursday": "štvrtok",
    "tuesday": "utorok",
    "wednesday": "streda",
    "port_txt": "Port",
    "today_title": "Dnes",
    "yesterday_title": "včera",
    "last_30_days_txt": "Posledných 30 dní",
    "this_month_txt": "Tento mesiac",
    "last_month_txt": "Minulý mesiac",
    "valid_date_title": "Vyberte platný dátum",
    "valid_business_name_txt": "Zadajte platný obchodný názov",
    "req_bus_add_txt": "Vyžaduje sa adresa firmy",
    "req_domain_name_txt": "Vyžaduje sa názov domény",
    "basic_info_txt": "Základné informácie o vašej firme",
    "loyalty_qr_def_txt": "Naskenujte QR kód, pripojte sa k našej vernostnej karte a doprajte si odmeny.",
    "last_stamp_txt": "Posledná pečiatka",
    "collected_on_txt": "Zhromaždené dňa",
    "stamp_details_txt": "Podrobnosti o pečiatke",
    "add_stamp_txt": "Pridať pečiatku",
    "choose_brand_color_txt": "Vyberte si farbu a jazyk značky 🎨",
    "change_anytime_txt": "Toto môžete kedykoľvek zmeniť",
    "like_to_get_txt": "Chceli by ste dostať",
    "your_next_visit_txt": "pri ďalšej návšteve?",
    "time_to_time_get_offers": "Chceli by ste z času na čas dostávať skvelé ponuky a propagačné akcie?",
    "respond_back_to_you": "Chceli by ste, aby vám niekto odpovedal?",
    "input_serve_better": "Váš príspevok pomôže nášmu tímu lepšie vám slúžiť!",
    "do_it_txt": "Poďme na to",
    "take_15_s": "Trvá 15 sekúnd",
    "rate_on_google_desc": "Boli by ste tak láskaví a ohodnotili nás na Googli?",
    "may_be_next_time": "Možno nabudúce",
    "thank_you_txt": "dakujem",
    "look_next_txt": "Tešíme sa na Vašu ďalšiu návštevu!",
    "deleteUserTitle": "Tento používateľ bude natrvalo odstránený.",
    "deleteMUserTitle": "Títo používatelia budú natrvalo odstránení.",
    "change_pin": "Zmeniť PIN",
    "area_code": "Aká je vaša predvoľba? ",
    "add_menu": "Pridať menu",
    "menu_name": "Názov menu",
    "add_menu_placeholder": "Pizza, Burger, Baverages atď",
    "enable_data_Collection": "Povoliť zhromažďovanie údajov",
    "add_new_menu": "Pridať nové menu",
    "rename_menu": "Menu premenovať",
    "preview": "Ukážka",
    "generate_qr": "Vygenerované QR",
    "edit_menu": "Menu Upraviť",
    "remove_menu": "Menu Odstrániť",
    "menu_delete_msg": "Táto ponuka bude natrvalo vymazaná",
    "menus_delete_msg": "Tieto ponuky budú natrvalo odstránené",
    "view_menu_dialoage_msg": "Chceli by ste dostať darček k narodeninám?",
    "skip": "Preskočiť",
    "cliam_your_gift": "Nárokujte si svoj darček",
    "collect_form": "Zhromažďovať formulár",
    "enter_first_name": "Zadajte krstné meno",
    "enter_last_name": "Zadajte priezvisko",
    "enter_email": "Zadajte e-mail",
    "enter_dob": "Zadajte dátum narodenia",
    "enter_number": "Zadajte Číslo",
    "select_gender": "Vyberte Pohlavie",
    "congratulations_desc": "🎉 Gratulujeme! Vaša žiadosť o nárok na darček bola úspešne odoslaná. Náš tím vás bude čoskoro kontaktovať. 🎉",
    "male_heading": "Mužský",
    "male_text": "mužský",
    "female_heading": "Žena",
    "female_text": "samica",
    "others_heading": "Iní",
    "other_text": "iní",
    "BirthD": "Dátum narodenia",
    "GenderT": "Pohlavie",
    "EmailT": "Email",
    "dobT": "nar",
    "capitalize_menu": "Menu",
    "select_menu": "Vyberte ponuku",
    "manage_variant": "Spravovať variant",
    "add_products": "Pridať produkty",
    "add_category": "Pridať kategóriu",
    "category_delete": "Táto kategória bude natrvalo odstránená",
    "variation_delete": "Táto variácia bude z Produktu vymazaná.",
    "product_delete": "Tento produkt bude natrvalo odstránený.",
    "categories_delete": "Tieto kategórie budú natrvalo odstránené.",
    "products_delete": "Tieto produkty budú natrvalo odstránené.",
    "category": "KATEGÓRIA",
    "price": "CENA",
    "food_product_placeholder": "Pizza, Burger, Baverages atď",
    "active_title": "Aktívne",
    "inActive_title": "Neaktívne",
    "status_capital": "STATUS",
    "cat_status_require": "Vyžaduje sa stav kategórie",
    "cat_name_require": "Vyžaduje sa názov kategórie",
    "category_name": "Názov kategórie",
    "status": "Stav",
    "lgDay1": "pondelok",
    "lgDay2": "utorok",
    "lgDay3": "streda",
    "lgDay4": "štvrtok",
    "lgDay5": "piatok",
    "lgDay6": "sobota",
    "lgDay7": "nedeľa",
    "is_closed_title": "ZATVORENÉ",
    "book_table_title": "Kniha A Stôl",
    "emailErrSub": "Vyžaduje sa predmet e-mailu",
    "email_subject": "Predmet e-mailu",
    "contactUsfrmTitleEmail": "Odpovedať na email",
    "companyInfo": "Lorem Ipsum je lorem hrig, lorem ipsum je fraets.Lorem Ipsum je lorem hrig, lorem ipsum je fraets. Lorem Ipsum je lorem hrig, lorem ipsum je fraets.",
    "footerSTitle1": "O Menuonline",
    "footerSTitle1Lnk2": "Vlastnosti",
    "footerSTitle1Lnk3": "Blog",
    "footerSTitle2": "Právne",
    "footerSTitle2Lnk1": "Zmluvné podmienky",
    "footerSTitle2Lnk2": "Zásady ochrany osobných údajov",
    "footerSTitle3": "Pre podnikanie",
    "footerSTitle3Lnk1": "Pre partnerov",
    "footerSTitle3Lnk2": "Stanovenie cien",
    "footerSTitle3Lnk3": "Podpora pre partnerov",
    "footerCopyRithgTxt": "  Menuonline alebo jej pridružených spoločností",
    "homeTitle": "Domov",
    "contactTitle": "Kontaktujte nás",
    "aboutTitle": "O nás",
    "homeMenuTitle": "Menu",
    "bookTableTitle": "Kniha A Stôl",
    "vDateT": "Vyberte platný dátum",
    "reqDateT": "Vyžaduje sa dátum",
    "dateGtErr": "Dátum musí byť neskorší alebo rovnaký ako dnešný dátum",
    "timeInvalid": "Čas je neplatný",
    "reqTimeT": "Vyžaduje sa čas",
    "timeInvalidBfr": "Vyberte čas aspoň o 5 minút neskôr od aktuálneho času ",
    "PerReq": "Vyžaduje sa počet osôb",
    "validcNoT": "Zadajte platné kontaktné číslo",
    "reqCrT": "Vyžaduje sa mena",
    "reqPrT": "Cena je povinná",
    "reCateT": "Kategória je povinná",
    "reqVrT": "Vyžaduje sa variant",
    "reqVrVldT": "Zadajte platný variant ",
    "validLNameT": "Zadajte platné priezvisko",
    "sDateInValid": "Dátum od je neplatný",
    "minmReq": "Vyžaduje sa minimum",
    "resLblDate": "Vyberte Dátum",
    "resLblTime": "Vyberte položku Čas",
    "perT": "Pre koľko osôb?",
    "resLblEmail": "Uveďte prosím svoj email?",
    "resLblNote": "Pridajte poznámku o rezervácii",
    "imageError1MB": "Vložte obrázok, ktorý je menší ako 1 MB",
    "imageError": "Vložte obrázok, ktorý je menší ako 2,5 MB",
    "imageError3Mb": "Vložte obrázok, ktorý je menší ako 3 MB",
    "imageInVldError": "Súbor je neplatný.",
    "addProMT": "Ponuka produktov",
    "proT": "Názov produktu",
    "reqProT": "Vyžaduje sa názov produktu",
    "proPT": "Cena produktu",
    "reservationSuccTitle": "Skvelé, vaša rezervácia je hotová",
    "book": "Kniha",
    "custzName": "Názov prispôsobenia",
    "always": "Vždy",
    "proImgReq": "Vyžaduje sa obrázok produktu",
    "selCustmT": "Vyberte Prispôsobenie alebo vytvorte nový",
    "visStR": "Vyžaduje sa stav viditeľnosti",
    "avlblScR": "Vyberte plán dostupnosti",
    "addonPR": "Vyberte doplnkové produkty",
    "upsellPR": "Vyberte produkty na ďalší predaj",
    "markItemR": "Prosím, vyberte označiť položku produkty",
    "caloriesR": "Potrebné sú kalórie",
    "allergensR": "Vyberte alergénové produkty",
    "prepInstR": "Vyžaduje sa návod na prípravu",
    "staffNR": "Vyžadujú sa poznámky personálu",
    "discountR": "Vyžaduje sa zľava",
    "validDiscE": "Zadajte platnú zľavu",
    "disday": "Podľa dňa",
    "plSelDayT": "Vyberte deň",
    "sTimeReq": "Vyžaduje sa čas začiatku",
    "sTimeInValid": "Čas začiatku je neplatný",
    "eTimeReq": "Vyžaduje sa čas ukončenia",
    "eTimeInValid": "Čas ukončenia je neplatný",
    "sDateReq": "Vyžaduje sa dátum od",
    "eDateReq": "Vyžaduje sa dátum",
    "eDateInValid": "K dnešnému dňu je neplatný",
    "disdate": "Od dátumu",
    "disdate1": "K dnešnému dňu",
    "disdate2": "Od dátumu",
    "currT": "mena",
    "iconR": "Vyberte ikonu označenia položky",
    "minT": "Minimum",
    "maxT": "Maximálne",
    "itemNT": "Názov položky",
    "itemPT": "Cena položky",
    "descProT": "Popíšte svoj produkt",
    "cateT": "Kategória",
    "selProCateT": "Vyberte kategóriu produktu",
    "reqMT": "Vyžaduje sa názov ponuky",
    "ViewMenu": "Zobraziť menu",
    "CopyMenu": "Kopírovať menu",
    "EditMenu": "Upraviť menu",
    "RemoveMenu": "Odstrániť menu",
    "welcomeMnuTitle": "Vitajte na",
    "reviewT": "Recenzie",
    "userErrorMsg": "Prepáčte, vyskytla sa chyba!!!",
    "reqCateT": "Vyžaduje sa názov kategórie",
    "mngCustT": "Spravovať prispôsobenie",
    "custNReq": "Vyžaduje sa názov prispôsobenia",
    "incReq": "Vyžaduje sa zahrnutie",
    "minmValid": "Zadajte platnú minimálnu hodnotu",
    "maxmReq": "Vyžaduje sa maximum",
    "cust": "Prispôsobenie",
    "crCust": "Vytvoriť nové prispôsobenie",
    "custList": "Zoznam prispôsobení",
    "delCustmzT": "Toto prispôsobenie bude natrvalo odstránené.",
    "mkNameR": "Vyžaduje sa označenie názvu položky",
    "mkDelT": "Táto označená položka bude natrvalo odstránená.",
    "hideText": "Skryť",
    "showText": "Zobraziť",
    "device_mac_txt": "Zariadenie Mac",
    "delete_hotspot_txt": "Tento hotspot bude natrvalo odstránený.",
    "delete_hotspots_txt": "Tieto aktívne body budú natrvalo odstránené.",
    "emp_wifi_name": "Meno Wifi zamestnanca",
    "max_limit_txt": "Zadajte '1024' pre rýchlosť, aby ste ju označili ako NEOBMEDZENÉ. Táto hodnota odstráni všetky uzávery, čo umožňuje maximálnu priepustnosť",
    "device_port_txt": "Port zariadenia",
    "firmware_txt": "Firmvér",
    "hotspot_info_txt": "Informácie o hotspote",
    "editDBtn": "Upraviť podrobnosti",
    "birth_date": "Dátum narodenia",
    "di_theme_clr": "Farba motívu presne 7 číslic",
    "color_Invalid_txt": "Vyberte platný kód farby",
    "Req_theme_clr": "Vyžaduje sa farba motívu",
    "select_valid_color_txt": "Vyberte platnú farbu motívu",
    "req_redir_link": "Vyžaduje sa odkaz na presmerovanie",
    "val_redir_link": "Zadajte platný odkaz na presmerovanie",
    "req_business_name_txt": "Vyžaduje sa obchodný názov",
    "splash_preview": "Úvodná ukážka",
    "create_new_splash": "Vytvorte nový Splash",
    "splash_page": "Úvodná stránka",
    "splash_page_builder": "Tvorca úvodných stránok",
    "redirect_link": "Presmerovať odkaz",
    "theme_color": "Farba motívu",
    "enable_social_login": "Povoliť možnosti sociálnych médií pre prihlásenie používateľov",
    "google": "Google",
    "facebook": "Facebook",
    "is_mandatory": "Je to povinné?",
    "field": "Pole",
    "name": "Meno",
    "first_name": "Krstné meno",
    "last_name": "Priezvisko",
    "birthdate": "Dátum narodenia",
    "gender": "Pohlavie",
    "email": "Email",
    "dob": "nar",
    "zip_code": "PSČ",
    "required_redirect_link": "Vyžaduje sa presmerovanie",
    "valid_redirect_link": "Zadajte platný odkaz na presmerovanie",
    "required_theme_color": "Vyžaduje sa farba motívu",
    "theme_color_length": "Farba motívu presne 7 číslic",
    "required_name": "Meno je povinné",
    "delete_splash_message": "Chcete odstrániť tento úvod?",
    "delete_multiple_splashes_message": "Chcete tieto postriekania odstrániť?",
    "user_login_required": "Vyžaduje sa prihlásenie používateľa",
    "set_theme_color": "Nastaviť farbu motívu",
    "splash_colllect_additional_info_txt": "Zhromažďovať ďalšie informácie",
    "verify_mobile_otp_message": "Chcete poslať jednorazové heslo na overenie mobilného čísla?",
    "add_company": "Pridať spoločnosť",
    "edit_company": "Upraviť spoločnosť",
    "ds_device": "Digital Signage Device",
    "ds_device_activation_fee": "Poplatok za aktiváciu zariadenia Digital Signage",
    "wifi_hardware": "Wifi hardvér",
    "menu_design": "Dizajn menu",
    "ds_hardware": "Hardvér na digitálne značenie",
    "company_name_req": "Vyžaduje sa názov spoločnosti",
    "known_name_req": "Vyžaduje sa známe meno",
    "no_location_req": "Vyžaduje sa číslo miesta",
    "minimum_location": "Zadajte minimálne 1 miesto",
    "terms_req": "Podmienky sú povinné",
    "notes_req": "Poznámky sú povinné",
    "sms_charge_req": "Vyžaduje sa poplatok za SMS",
    "menuonline_quantity_req": "Menu Online Množstvo je povinné",
    "menuonline_cost_req": "Vyžaduje sa cena ponuky online",
    "ds_quantity_req": "Vyžaduje sa množstvo digitálneho podpisu",
    "ds_monthly_cost_req": "Vyžaduje sa mesačná cena digitálneho podpisu",
    "reputation_quantity_req": "Vyžaduje sa množstvo reputácie",
    "reputation_cost_req": "Vyžaduje sa reputácia",
    "marketing_quantity_req": "Vyžaduje sa marketingové množstvo",
    "marketing_cost_req": "Vyžaduje sa marketingová cena",
    "tax_percentage_req": "Percento dane je povinné",
    "router_hardware_quantity_req": "Vyžaduje sa množstvo hardvéru smerovača",
    "wifi_hardware_cost_req": "Vyžaduje sa hardvér WIFI",
    "activation_cost_req": "Vyžaduje sa aktivačný poplatok",
    "wifi_quantity_req": "Vyžaduje sa množstvo WIFI",
    "wifi_router_req": "Vyžaduje sa smerovač Wi-Fi",
    "menu_design_quantity_req": "Vyžaduje sa množstvo dizajnu ponuky",
    "menu_design_cost_req": "Vyžaduje sa cena za návrh ponuky",
    "quantity_req": "Vyžaduje sa množstvo",
    "cost_req": "Vyžaduje sa cena",
    "sheduledown_quantity_req": "Vyžaduje sa zníženie množstva",
    "sheduledown_cost_req": "Vyžaduje sa časové zníženie nákladov",
    "loyalty_program_quantity_req": "Vyžaduje sa množstvo vernostného programu",
    "loyalty_program_cost_req": "Vyžaduje sa cena vernostného programu",
    "loyalty_program_sms_quantity_req": "Vernostný program Počet SMS je povinný",
    "loyalty_program_sms_cost_req": "Vyžaduje sa cena SMS vernostného programu",
    "comapny_created": "Spoločnosť bola úspešne vytvorená",
    "comapny_updated": "Spoločnosť bola úspešne aktualizovaná",
    "billing_info": "FAKTURAČNÉ ÚDAJE",
    "business_name_acc_holder": "Obchodné meno (majiteľ účtu)",
    "client_first_name": "Krstné meno klienta",
    "client_last_name": "Priezvisko klienta",
    "dba_known_as": "DBA známy ako",
    "business_address": "Obchodná adresa",
    "no_of_locations": "Počet miest",
    "package_details": "PODROBNOSTI O BALÍKU",
    "terms": "Podmienky",
    "one_month": "1 mesiac",
    "per_sms_charge": "Poplatok za SMS",
    "plan_test": "PLÁN: TEST",
    "desc_capital": "POPIS",
    "qty_capital": "MNOŽSTVO",
    "cost_capital": "NÁKLADY",
    "total_price_capital": "CENA CELKOVÁ",
    "quantity": "Množstvo",
    "can_not_access": "Nemôžete vybrať viac ako",
    "licenses": "licencií",
    "monthly_cost": "Mesačné náklady",
    "plan_cost": "Náklady na plán",
    "tax": "daň",
    "tax_percentage": "Percento dane",
    "monthly_cost_after_tax": "Mesačné náklady po zdanení",
    "activation_one_time_charge": "Aktivácia a jednorazové nabitie",
    "sub_total": "MEDZISÚČET",
    "action": "Akcia",
    "cost": "náklady",
    "shipping_charge": "Poplatok za dopravu",
    "other": "Iné",
    "additional_cost": "Dodatočné poplatky (iné poplatky)",
    "other_tax": "Iná daň",
    "total": "Celkom",
    "license_statistics": "Štatistika licencií",
    "total_licenses": "Celkový počet licencií",
    "available_licenses": "Dostupné licencie",
    "stock": "skladom",
    "has_permission": "Má povolenie",
    "avg_stock_price": "Akciová cena AVG",
    "average_price": "Priemerná cena",
    "allocated": "Pridelené",
    "reward_regulars": "Odmeňovanie štamgastov",
    "not_add_coupon_url": "Nie je možné pridať adresu URL kupónu. Maximálna dĺžka správy je 160",
    "like_attach_coupon": "Chcete priložiť kupón?",
    "advance_scheduling": "Predbežné plánovanie",
    "choose_day": "Vyberte dni na odoslanie správy",
    "select_essage_window": "Vyberte Okno správy",
    "subscription_outside_delivery_window": "Ak sa používateľ prihlási na odber mimo obdobia doručenia, dostane vašu automatickú odpoveď v najbližšom dostupnom čase.",
    "remaining": "Zostávajúce",
    "processing_request": "Pri spracovaní vašej požiadavky sa vyskytla chyba.",
    "list_companies": "Zoznam spoločností",
    "are_you_sure": "Si si istý..?",
    "signin_agreement": "Potvrdením tohto vytvorí spoločnosť bez podpisu zmluvy.",
    "status_updated": "Aktualizácia stavu bola úspešná",
    "status_failed": "Aktualizácia stavu zlyhala",
    "new_editor_status_updated": "Stav nového editora DS bol úspešne aktualizovaný.",
    "user_name": "Používateľské meno",
    "known_as": "známy ako",
    "agreement_signed": "Dohoda podpísaná",
    "deactivate": "Deaktivovať",
    "activate": "Aktivovať",
    "login_to_user_acc": "Prihláste sa do používateľského účtu",
    "disable": "Zakázať",
    "enable": "Povoliť",
    "ds_new_editor": "Nový editor DS",
    "sign_agreement": "Podpísať dohodu",
    "agreement_sign": "Zmluva bez podpisu",
    "view_agreement": "Zobraziť dohodu",
    "download_agreement": "Stiahnite si zmluvu",
    "add_deleted_user": "Pridať odstráneného používateľa",
    "deleted_user": "Odstrániť používateľa",
    "favourite": "Obľúbené",
    "refresh": "Obnoviť",
    "delete_chat": "Odstrániť chat",
    "responsive_drawer": "Responzívna zásuvka",
    "delete_contact": "Odstrániť kontakt",
    "clear_chat_history": "Vymazať históriu rozhovoru",
    "clear_chat_history_dec": "Naozaj chcete odstrániť tento rozhovor?",
    "clear_contact_dec": "Naozaj chcete odstrániť tento kontakt?",
    "select_contact": "Vyberte položku Kontakt",
    "new_conversation": "Začnite novú konverzáciu",
    "type_msg": "Tu napíšte svoju správu",
    "select_delete_client": "Vyberte klienta, ktorý chcete odstrániť",
    "select_delete_client_chat": "Vyberte klienta na odstránenie chatu",
    "select_delete_client_chat_err": "Nemáte rozhovor s týmto klientom",
    "acquire_customers_business": "Získajte nových zákazníkov z iných firiem.",
    "customers_from_events": "Získajte zákazníkov z podujatí a priveďte ich k vám!",
    "customers_from_ads": "Získajte zákazníkov z inzercie v novinách.",
    "smart_pamphlets": "Vytvárajte inteligentné brožúry na zhromažďovanie údajov o zákazníkoch.",
    "smart_qr_group": "Inteligentný QR kód na pripojenie k skupine pre zákazníkov.",
    "opt_in_number": "Umožnite zákazníkom prihlásiť sa na číslo.",
    "qr_menu": "Vytvorte QR kód na zobrazenie vášho jedálneho lístka.",
    "collect_birthdays": "Zhromaždite narodeniny a výročie zákazníka.",
    "join_loyalty_program": "Získajte nových zákazníkov, aby sa zapojili do môjho vernostného programu.",
    "create_feedback_forms": "Vytvorte formuláre na zhromažďovanie spätnej väzby od klientov.",
    "exclusive_discounts_promotions": "Ponúkajte exkluzívne zľavy a propagačné akcie pre krížové akcie.",
    "group_smart_qr_opt_in": "Po vytvorení skupiny môžete vygenerovať a vybrať inteligentný QR kód, ktorý zákazníkom umožní okamžite sa prihlásiť do skupiny",
    "group_smart_qr_opt_in_dec": "Po vytvorení skupiny miniaplikácia Obrázok, ktorá umožňuje zákazníkom prihlásiť sa pomocou textu a dostávať propagačné akcie, napríklad text „pizza“ na číslo 555-555-5555",
    "in_store_discount_next_visit": "Získajte zľavu v obchode pri vašej ďalšej návšteve u nás",
    "delivery_redirection": "Vytvorte presmerovanie doručenia",
    "additional_info": "Ďalšie informácie",
    "add_url": "Pridať adresu URL",
    "custom_fields": "Vlastné polia",
    "meta_tag": "Meta tag",
    "max_chars": "Maximálny počet znakov",
    "add": "Pridať",
    "update_campaign": "Aktualizovať kampaň",
    "last_week_required": "minulý týždeň je povinný",
    "large_week_value": "Hodnota týždňa je príliš veľká, zadajte platný týždeň",
    "less_than_last_week": "Nevrátenie za posledný týždeň musí byť menšie ako minulý týždeň.",
    "last_week_req": "Nevyžaduje sa návrat v minulom týždni",
    "birthday_special": "Narodeninový špeciál",
    "birthday_message": "Automatická správa odoslaná na ich narodeniny s personalizovanými pozdravmi a lákavými ponukami",
    "birthday_sms": "Vytvorte si personalizované blahoželania k narodeninám pre SMS\/MMS",
    "target_audience": "Vyberte cieľové publikum",
    "client_by_birthday": "Klient do narodenín",
    "clients_once_year": "Táto kampaň sa automaticky odosiela klientom raz ročne ",
    "auto_responder_default_msg_1": "Ste náš jeden z najlepších zákazníkov, ktorým radi dávame zľavy! Nech ste vždy bohatí a zdraví. Všetko najlepšie k narodeninám! %% krstné meno %%",
    "campaign_send_to_client_birthday": "Táto automatická kampaň sa odosiela klientom s narodeninami, nižšie si môžete prispôsobiť zacielenie.",
    "get_more_engagement": "Získajte väčšiu angažovanosť odoslaním novorodeneckej SMS 🎂",
    "welcome_new_clients": "Vitajte nových klientov",
    "make_lasting_impression": "Urobte trvalý dojem tým, že srdečne pozdravíte a privítate hostí, ktorí sú tu prvýkrát",
    "personalized_greetings": "Vytvorte personalizované pozdravy pre nových klientov",
    "greetings_to_new_customers": "Táto kampaň odošle uvítacie pozdravy novým zákazníkom nasledujúci deň po ich prvej interakcii s vašou firmou",
    "audience_predefined": "Publikum tejto automatickej odpovede je preddefinované, máme to!",
    "send_clients_subscribed": "Táto automatická odpoveď je nastavená na odosielanie klientov, ktorí sa prihlásili na odber v poslednej dobe ",
    "first_interaction": "  po ich prvej interakcii.",
    "default_msg_2": "Dúfame, že sa vám prvá návšteva páčila, radi vás čoskoro opäť uvidíme, preto ponúkame zľavu len pre vás! Kliknite na odkaz, aby ste si u nás zarezervovali ďalšie stretnutie a využite svoju špeciálnu online ponuku už teraz.",
    "new_clients_update": "Vitajte nových klientov s aktualizáciou textu kampane",
    "new_clients_warm_greeting": "Privítajte nových klientov srdečným pozdravom 💌",
    "win_back_clients": "Získajte späť klientov",
    "re_engage_past_customers": "Opätovne oslovte minulých zákazníkov neodolateľnými ponukami, ktoré ich povzbudia k návratu",
    "target_disengaged_clients": "Zacieľte na nezainteresovaných klientov, aby sa vrátili, pomocou špeciálnej ponuky, osvedčenej taktiky, ako prilákať klientov späť.",
    "campaign_sends_to_clients": "Táto kampaň posiela klientom, ktorí sa nevrátili po určitom čase.",
    "customize_targeting": "Prispôsobte si zacielenie nižšie, aby ste mohli posielať SMS\/MMS nefunkčným klientom.",
    "clients_with_at_least": "Klienti s min ",
    "connection_last": " spojenie v posl ",
    "return_in_last_week": " týždeň, ale nevrátili sa naposledy ",
    "arr_menu_44": " týždeň",
    "df_msg_4": "TAK SA VÁS CHCEME Späť, že vám dáme zľavu 10 % len za to, že sa vrátite! Chcete nám to ešte dnes vyskúšať?",
    "update_campaign_text": "Získať späť klientov aktualizuje text kampane",
    "re_invite_customers": "Znova pozvite zákazníkov, ktorí sa nejaký čas nevrátili",
    "loyal_patrons": "Ukážte uznanie verným patrónom pomocou špeciálnych zliav a vernostných odmien",
    "surprize_top_spenders": "Prekvapte tých, ktorí míňajú peniaze, personalizovanými a špeciálnymi ponukami.",
    "campaign_automatically_send": "Táto kampaň automaticky posiela odmeny, aby ste podporili pád vašej firmy.",
    "sms_loyal_clients": "Táto kampaň posiela SMS\/MMS verným klientom, nižšie môžete zmeniť zacielenie.",
    "or_more_connection": " alebo viac spojení v rámci posledného ",
    "week_period": " týždňové obdobie.",
    "default_msg_5": "Už dlho ste zákazníkom! Páni, ten čas letí! Vážime si vás a chceme vám poďakovať, že ste s nami.",
    "reward_regulars_update": "Pravidelné odmeny aktualizujú text kampane",
    "special_discounts": "Oceňte a dajte špeciálne zľavy💎",
    "leave_positive_online": "Povzbudzujte zákazníkov, aby zanechali pozitívne online recenzie, aby ste zvýšili reputáciu reštaurácie",
    "clients_service_online": "Kampaň povzbudzuje vašich klientov, aby ohodnotili vašu službu online.",
    "increase_your_company": "Táto automatická odpoveď sa odosiela na zvýšenie online hodnotenia vašej spoločnosti.",
    "send_message_after": "Pošlite správu po ",
    "min_connection": " min spojenia",
    "default_msg_6": "Dúfame, že sa vám prvá návšteva páčila, radi vás čoskoro opäť uvidíme! [URL] Kliknutím na odkaz nás ohodnotíte na stránkach Google.",
    "ratings_update": "Získajte viac textu kampane aktualizácie hodnotení",
    "friendly_nudge": "Získajte viac recenzií s priateľským štuchnutím ⭐",
    "thanks_for_visiting": "ďakujem za návštevu",
    "express_gratitude": "Vyjadrite vďaku zákazníkom za to, že si vybrali vašu firmu, prostredníctvom úprimných ďakovných správ",
    "thank_you_message": "Vytvorte ďakovnú správu pre svojich klientov.",
    "clients_for_visiting": "Pošlite srdečné poďakovanie svojim klientom za návštevu u vás.",
    "campaign_audience": "Toto publikum automatickej kampane je preddefinované, máme to!",
    "campaign_automatically_sends": "Táto automatická kampaň sa automaticky odošle po ",
    "minutes_customer_visit": " minút návštevy zákazníka",
    "default_msg_7": "Ďakujeme, že ste našim cteným zákazníkom. Sme veľmi vďační za potešenie z vašej služby a dúfame, že sme splnili vaše očakávania.",
    "visiting_update_campaign": "Ďakujeme, že ste navštívili text aktualizácie kampane",
    "guests_thank_you": "Ďakujeme svojim hosťom za návštevu 😊",
    "download_title": "Stiahnuť",
    "qr_gen_title": "Vygenerované QR",
    "qr_download_s_title": "QR kód bol úspešne stiahnutý",
    "sel_dark_brand_clr_msg": "Vyberte platnú farbu pruhu",
    "manage_customer_stamp_rewards": "Spravujte odmeny za pečiatku zákazníka 🎁",
    "sel_loc_menu_title": "Vyberte miesta pre svoje menu",
    "ans_req": "Vyžaduje sa odpoveď",
    "valid_reputation_name": "Zadajte platný názov reputácie",
    "reviews_name_req": "Vyžaduje sa názov Získať ďalšie recenzie",
    "birthdate_required": "Vyžaduje sa dátum narodenia",
    "gender_required": "Vyžaduje sa pohlavie",
    "valid_birthdate_required": "Zadajte platný dátum narodenia",
    "custom_delivery_redirection": "Vytvorte vlastný formulár presmerovania doručenia podľa skupín",
    "customer_fields_incentive_settings": "Polia zákazníkov a nastavenia stimulov",
    "delivery_redirection_text": "Presmerovanie doručenia",
    "delivery_redirection_success": "gratulujem! Váš widget na presmerovanie doručenia bol vytvorený! 🎉",
    "swipe_to_preview_redirection": "Potiahnutím prstom zobrazíte, ako bude vyzerať váš formulár presmerovania doručenia",
    "enter_item_name": "Zadajte názov položky",
    "home_page_text": "Text domovskej stránky",
    "settings": "Nastavenia",
    "anniversary_required": "Vyžaduje sa dátum výročia",
    "valid_anniversary": "Zadajte platný dátum výročia",
    "form_submited": "formulár bol úspešne odoslaný",
    "notifications": "Upozornenia",
    "discount_message": "Uplatnite si teraz zľavu 20 % na pizzu!",
    "is_required": " sa vyžaduje",
    "section_title_required": "Vyžaduje sa názov sekcie",
    "section_dec_required": "Vyžaduje sa popis sekcie",
    "client_details_required": "Polia s podrobnosťami o klientovi sú povinné",
    "compliance": "Súlad",
    "SMS_campaign1": "SMS kampaň 1",
    "mobile_number_mandatory": "Mobilné číslo je povinné",
    "new_answer": "Nová odpoveď",
    "new_question": "Nová otázka",
    "add_new_question": "Pridať novú odpoveď",
    "select": "Vyberte",
    "group_customers_question": "Kde chcete zoskupiť svojich zákazníkov?",
    "contacts_added_to_group": "Všetky kontakty, ktoré odoslali formulár, budú pridané do vybranej skupiny",
    "edit_client_details_section": "Upraviť sekciu Podrobnosti klienta",
    "client_details_fields": "Polia s podrobnosťami o klientovi",
    "enter_client_details_section_title_desc": "Zadajte názov a popis sekcie podrobností klienta",
    "choose_fields_displayed_client_side": "Vyberte polia, ktoré sa majú zobraziť na strane klienta",
    "section_title": "Názov sekcie",
    "add_title": "Pridať názov",
    "section_description": "Popis sekcie",
    "add_description": "Pridať popis",
    "enter_program_name_location": "Zadajte názov programu a umiestnenie 📍",
    "brand_theme_clr_txt": "Farba motívu značky",
    "set_loyalty_punch_card_theme": "Poďme nastaviť tému pre váš vernostný dierovací lístok 🎨",
    "upload_logo_txt": "Nahrajte svoje logo",
    "recommended_image_specs": "Pre najlepšie zobrazenie odporúčame použiť obrázok s rozmermi 512 x 512 pixelov. JPG, SVG alebo PNG. Maximálna veľkosť 10 MB.",
    "valid_rep_name_txt": "Zadajte platný názov reputácie",
    "rep_name_req_txt": "Vyžaduje sa názov Získať ďalšie recenzie",
    "que_req": "Otázka je povinná",
    "day_of_birthday": "v deň narodenín",
    "day_before_birthday": "3 dni pred narodeninami",
    "week_before_birthday": "týždeň pred narodeninami",
    "two_week_before_birthday": "dva týždne pred narodeninami",
    "de_active": "De-Active",
    "campaign_details": "Podrobnosti o kampani",
    "link_clicked": "Odkaz klikol",
    "history": "História",
    "auto_responder_summary": "Súhrn automatickej odpovede",
    "vsop_1": "15 min",
    "vsop_2": "30 min",
    "vsop_3": "45 min",
    "vsop_4": "60 min",
    "vsop_5": "90 min",
    "vsop_6": "120 min",
    "in_the_last": " v poslednom ",
    "return_in_last": " ale nevrátil sa posledný ",
    "of_the_connection": "spojenia",
    "your_campaign": "Vaša kampaň",
    "you_have_successfully": "Úspešne ste",
    "published_successfully": "úspešne zverejnené",
    "updated_smart_campaign": "aktualizovali inteligentnú kampaň",
    "almost_done_text": "Takmer hotovo!",
    "update_campaign_desc": "Vaša automatická odpoveď je dokončená a po aktivácii bude priebežne odosielať správy klientom. Túto kampaň je možné kedykoľvek jednoducho upraviť alebo pozastaviť.",
    "update_and_publish": "Aktualizovať a publikovať",
    "reset_campaign_title": "Predbežne odstráni vlastný text, obrázok, ponuky zliav a cieľové publikum vašej kampane!",
    "space_wifi": " WiFi",
    "custom_forms": "Vlastné formuláre",
    "web_e_signup": "Webová elektronická prihláška",
    "import": "Importovať",
    "permissions_req": "Vyžaduje sa povolenie",
    "redeemed": "Vykúpené",
    "coupon_already_redeemed": "Kupón už bol uplatnený",
    "autoresponder_campaigns": "Kampane s automatickou odpoveďou",
    "autoresponder_campaign_desc": "Povoľte automatickú odpoveď a spustite prispôsobenú kampaň",
    "mon": "PO",
    "tue": "UTOROK",
    "wed": "ST",
    "thu": "ŠT",
    "fri": "PI",
    "sat": "SAT",
    "sun": "SLNKO",
    "duplicate": "Duplicitné",
    "visibility": "Viditeľnosť",
    "availability": "Dostupnosť",
    "out_stok": "Mimo stok",
    "edit_product": "Upraviť produkt",
    "create_product": "Vytvorte produkt",
    "basic_info": "Základné informácie",
    "basic_sub_info": "Lorem Ipsum je jednoducho fiktívny text tlače...",
    "enter_product_price": "Zadajte cenu produktu",
    "upload_image": "Nahrať obrázok",
    "allowed_file_formats": "Povolené sú iba súbory JPG a PNG s maximálnou veľkosťou súboru 3 MB.",
    "pre_select": "Pre-Select",
    "promotions": "Propagácie",
    "discount_availability": "Dostupnosť zľavy",
    "start_time": "Čas začiatku",
    "end_time": "Čas ukončenia",
    "select_day": "Vyberte Deň",
    "menu_required": "Menu je povinné",
    "generated": "Vygenerované",
    "link": "Odkaz",
    "variations": "Variácie",
    "select_variant_to_add": "Vyberte Variant, ktorý chcete pridať",
    "price_title": "Cena",
    "choose_image": "Vyberte Obrázok",
    "select_visiblity": "Vyberte položku Viditeľnosť",
    "availability_schedule": "Plán dostupnosti",
    "add_on_upsell": "Add on \/ Upsell",
    "add_on_select_product": "Pridať k výberu produktu",
    "upsell_product": "Upsell Select Product",
    "variant_deletion_warning": "Tento variant bude natrvalo odstránený.",
    "search_variations": "Variácie vyhľadávania",
    "add_variation": "Pridať variáciu",
    "variation_text": "Variant",
    "select_customization": "Vyberte prispôsobenie",
    "add_new": "Pridať nový",
    "delete_customization_warning": "Chcete odstrániť toto prispôsobenie z produktu?",
    "nutritional_allergen_info": "Výživové a alergénne informácie",
    "mark_item": "Označiť položku",
    "calories_text": "Kalórie",
    "allergens": "Alergény",
    "select_allergens": "Vyberte alergény",
    "special_instructions": "Špeciálne pokyny",
    "preparation_instructions": "Pokyny na prípravu",
    "staff_notes": "Poznámky zamestnancov",
    "checkbox1": "Začiarkavacie políčko na povolenie alebo zakázanie špeciálnych pokynov od zákazníkov pre túto položku ponuky.",
    "menu_c": "MENU",
    "past_design": "Minulý dizajn",
    "file_name": "Názov súboru",
    "variation_name": "Názov variácie",
    "grid_items_title1": "Stolný stan",
    "grid_items_title2": "Digitálne menu",
    "grid_items_title3": "Menu so sebou",
    "grid_items_title4": "Polstránkové menu",
    "grid_items_title5": "Digitálne menu 5",
    "grid_items_title6": "Digitálne menu 6",
    "grid_items_title7": "Digitálne menu 7",
    "grid_items_title8": "Digitálne menu 8",
    "grid_items_title9": "Digitálne menu 9",
    "enter_valid_form_name": "Zadajte platný názov formulára",
    "form_name_required": "Vyžaduje sa názov formulára",
    "enter_valid_section": "Zadajte platný názov sekcie",
    "fraction_half": "1\/2",
    "fraction_two_by_two": "2\/2",
    "form_name": "Názov formulára",
    "contact_list": "Zoznam kontaktov",
    "label_notify_email": "Chcete byť informovaní prostredníctvom e-mailu?",
    "add_client_details_section": "Pridať sekciu Podrobnosti klienta",
    "demo_purpose_only": "Je to len na demo účely",
    "form_updated_success": "formulár bol úspešne aktualizovaný",
    "form_creat_success": "formulár bol úspešne vytvorený",
    "add_custom_section": "Pridať vlastnú sekciu",
    "edit_custom_section": "Upraviť vlastnú sekciu",
    "forms_list": "Zoznam formulárov",
    "filled_custom_form_details": "Podrobnosti vyplneného vlastného formulára",
    "custom_form_deletion_warning": "Tento vlastný formulár bude natrvalo odstránený",

    "menu_link": "Odkaz na menu",
    "WebSignUpT": "Elektronická prihláška",
    "lifeTConT": "Doživotné spojenia",
    "repCustT": "Opakovaní zákazníci",
    "liftTSubT": "Doživotní odberatelia",
    "overallT": "CELKOVO",
    "loginCustBDT": "Prihlásení zákazníci podľa dňa",
    "deviceT": "Demografické údaje zariadenia",
    "ageTitle": "Demografické údaje podľa veku",
    "ageT": "Veková skupina",
    "convert_social_media_followers_into_customers": "Premeňte sledovateľov sociálnych médií na zákazníkov",
    "signWidDesc": "Vytvorte miniaplikáciu na registráciu, vytváranie vstupných stránok a generovanie kupónov z vášho webu alebo sociálnych médií",
    "qrWidT": "Inteligentný QR kód",
    "imgWidT": "Objednajte si číslo",
    "mobile": "Mobil",
    "YESTitle": "ÁNO",
    "NOTitle": "NIE",
    "addDelRed": "Pridať presmerovanie doručenia",
    "dlredNameT": "Pomenujte svoje presmerovanie doručenia",
    "conf_text_req": "Vyžaduje sa text potvrdenia",
    "cFDesc": "Vytvárajte prispôsobené formuláre na efektívne zhromažďovanie špecifickej spätnej väzby a informácií od zákazníkov",
    "pre_review_questions_enabled": "Predtým, ako požiadame zákazníkov o recenziu, položme im 1 až 4 otázky.",
    "feel_free_edit": "Neváhajte a upravte podľa potreby.",
    "option": "Možnosť",
    "confFL": "Toto potvrdenie sa zobrazí, keď váš kontakt odošle webový registračný formulár",
    "confT": "Potvrdzovací text",
    "joinTitle": "Pripojte sa",
    "mobValidL": "Skontrolujte si prosím platné číslo mobilu",
    "mobRequiredL": "Vyžaduje sa mobil č",

    "Business_location_label": "Miesto vašej firmy",
    "qr_code_selection_txt": "Výber Qr kódu",
    "choose_theme_txt": "Vyberte tému",
    "edit_qr_details_txt": "Upraviť podrobnosti QR",
    "save_qr_code_txt": "Uložiť QR kód",
    "text_color_label": "Farba textu",
    "background_color_label": "Farba pozadia",
    "print_txt": "Tlačiť",
    "customer_will_see_txt": "Toto uvidí váš zákazník. V ďalšom kroku si vyberte farbu značky.",
    "choose_your_brand_color_&_langauge_title": "Vyberte si farbu a jazyk značky",
    "custom_redirect_link_label": "Odkaz na vlastné presmerovanie",
    "redirect_user_to_menu": "Presmerovať používateľa do ponuky",
    "language": "Langauge",
    "select_language_placeholder": "Vyberte Jazyk",
    "capitalize_location_name_txt": "Názov miesta",
    "created_txt": "Vytvorené",
    "swipe_reputation_management_txt": "Potiahnutím prstom zobrazíte, ako bude vyzerať správa vašej reputácie",
    "sent_txt": "Odoslané",
    "number_label": "číslo",
    "date_label": "Dátum",
    "gender_demographics_title": "Rodová demografia",


    "step1_preview_desc": "Pomenujte svoj QR formulár elektronickej prihlášky a pridajte ho do svojho skupinového zoznamu",
    "main_head": "Získajte nových zákazníkov",
    "main_desc": "Získajte zákazníkov umiestnením QR kódu, ktorý po naskenovaní presmeruje používateľov na prispôsobený registračný formulár.",
    "step1_description": "Získajte nových zákazníkov z iných firiem",
    "form_name_field_placeholder": "Restro elektronická registrácia",
    "last_step_title": "gratulujem! Váš widget elektronickej registrácie bol vytvorený! 🎉",
    "last_step_desc": "Potiahnutím prstom zobrazíte, ako bude vyzerať váš elektronický registračný formulár",
    "events_step1_preview_desc": "Pomenujte svoj QR formulár udalosti a pridajte ho do svojho skupinového zoznamu",
    "events_main_head": "Získajte zákazníkov z podujatí",
    "events_main_desc": "Získajte zákazníkov umiestnením QR kódu na udalosť, ktorá po naskenovaní presmeruje používateľov na prispôsobený registračný formulár.",
    "events_step1_desc": "Zachyťte zákazníkov z podujatí a priveďte ich do obchodu",
    "events_name": "Názov udalosti",
    "events_form_name_field_placeholder": "Restro zábava a jedlo",
    "events_last_step_title": "gratulujem! Váš formulár udalosti bol vytvorený! 🎉",
    "events_last_step_desc": "Potiahnutím prstom zobrazíte, ako bude vyzerať váš formulár udalosti",
    "birthday_anniversary_step1_preview_desc": "Pomenujte svoj QR formulár, aby ste mohli zhromaždiť narodeniny alebo výročie zákazníka a pridajte ho do svojho skupinového zoznamu",
    "birthday_anniversary_main_head": "Zbierajte B'day alebo Anniversary",
    "birthday_anniversary_main_desc": "Získanie narodenín alebo výročia zákazníka po naskenovaní presmeruje používateľov na prispôsobený registračný formulár.",
    "birthday_anniversary_step1_desc": "Zbierajte narodeniny a výročie zákazníka",
    "restro_text": "Restro",
    "birthday_anniversary_last_step_title": "gratulujem! Získajte B'day & Anniversary od zákazníkov vytvorený formulár! 🎉",
    "newspaper_ads_step1_preview_desc": "Pomenujte svoj QR formulár, aby ste získali zákazníkov z novinových reklám a pridali sa do vášho skupinového zoznamu",
    "newspaper_ads_main_head": "Získajte zákazníkov z reklám",
    "newspaper_ads_main_desc": "Získajte zákazníkov z novinových reklám, keď ich naskenujú, presmeruje používateľov na prispôsobený prihlasovací formulár.",
    "newspaper_ads_step1_desc": "Získajte zákazníkov z reklamy v novinách alebo vonkajšej reklamy",
    "newspaper_ads_last_step_title": "gratulujem! Získajte zákazníkov z vytvoreného formulára inzercie v novinách! 🎉",
    "smart_pamphlets_step1_preview_desc": "Pomenujte svoj QR formulár, aby ste získali údaje o zákazníkoch z brožúr a pridali sa do vášho skupinového zoznamu",
    "smart_pamphlets_main_head": "Získajte klientov z letákov",
    "smart_pamphlets_main_desc": "Získajte údaje zákazníkov z brožúr, keď ich naskenujú, presmeruje používateľov na prispôsobený registračný formulár.",
    "smart_pamphlets_last_step_title": "gratulujem! Získajte údaje o zákazníkovi z vytvoreného formulára letákov! 🎉",
    "social_media_step1_preview_desc": "Pomenujte svoj QR formulár, aby ste získali zákazníkov zo sociálnych médií a medzi zákazníkov a pridali sa do vášho skupinového zoznamu",
    "social_media_main_desc": "Získajte zákazníkov zo sociálnych médií, keď naskenujú príspevky, presmeruje používateľov na prispôsobený prihlasovací formulár.",
    "social_media_form_name_field_placeholder": "Ponuka festivalu Restro - príspevok na Facebooku",
    "social_media_last_step_title": "gratulujem! Nechajte zákazníkov vytvoriť formulár sociálnych médií! 🎉",
    "claim_offer_text": "Vyplňte svoje údaje a požiadajte o ponuku hneď teraz",
    "form_title_description": "Pridajte názov a popis formulára pre svojich zákazníkov, aby mohli zadať podrobnosti",
    "no_subscribed_clients": "Vybraná skupina zákazníkov nemá žiadnych prihlásených klientov",
    "welcome_message_offer": "Pošlite svojim zákazníkom po registrácii uvítaciu správu a dajte im kupón na ponuku",
    "system_grp": "Systémové skupiny",
    "import_clients": "Importovať klientov",
    "delivery_redirection_name": "Názov presmerovania doručenia",
    "created_at": "Vytvorené o",
    "provide_stamp_user": "Poskytnite používateľovi pečiatku",
    "scan_qr_code": "Naskenujte QR kód",
    "add_point": "Pridať bod",
    "total_points": "Celkový počet bodov",
    "user_profile": "Používateľský profil",
    "stamp_history": "História pečiatok",
    "reward_txt": "Odmena",
    "variant_placeholder": "Malý, Stredný, Veľký atď",
    "sent_coupon": "Odoslaný kupón",
    "order_status": "Stav objednávky",
    "payment_status": "Stav platby",
    "order_currency": "Mena objednávky",
    "card_data": "Údaje o karte",
    "reward_expiring_on": "Platnosť odmeny vyprší",
    "reward_status": "Stav odmeny",
    "reward_get_date": "Dátum získania odmeny",
    "reward_name": "Názov odmeny",
    "add_point_type": "Pridať typ bodu",
    "restaurant_location": "Umiestnenie reštaurácie",
    "collect_points": "Zbierajte body",
    "rewards_expire": "Platnosť odmien vyprší",
    "rewards_redeem": "Uplatnenie odmien",
    "total_rewards": "Celkové odmeny",
    "created_by": "Vytvoril",
    "anniversary": "výročie",
    "remove_user_group_txt": "Odstrániť používateľa zo skupiny",
    "delete_permanently_grp_msg": "Tento klient bude natrvalo odstránený zo skupiny.",
    "delete_multiple_permanently_grp_msg": "Títo klienti budú natrvalo odstránení zo skupiny.",
    "delete_multiple_client": "Títo klienti budú natrvalo vymazaní.",
    "messages": "Správy",
    "management": "Manažment",
    "client": "Klient",
    "country": "Krajina",
    "state": "štátu",
    "city": "Mesto",
    "zip_code": "PSČ",
    "1_sparkly_point_txt": "🎉 Získali ste 1 iskrivý bod! 🌟",
    "select_country_txt": "Vyberte krajinu",
    "select_state": "Vyberte štát",
    "ls_thanks_for_join": "Ďakujeme, že ste sa pripojili k našej vernostnej karte.🎁🎈",
    "month": "mesiac",
    "welcome_message": "Pošlite svojim zákazníkom po registrácii uvítaciu správu",
    "all_rewards_txt": "Všetky odmeny",
    "active_rewards": "Aktívne odmeny",
    "expired_and_redeemed": "Platnosť vypršala a bola uplatnená",
    "redirect_3rd_party_delivery": "Presmerujte doručenie tretej strany do vášho obchodu alebo na vašu online objednávkovú platformu",
    "search_or_start_new": "Vyhľadajte alebo Začnite nové",
    "contacts": "Kontakty",
    "chats": "Chaty",
    "add_by_restaurant": "Pridať podľa reštaurácie",
    "using_scan_code": "Pomocou skenovania kódu",
    "step": "Krok",
    "list_widget_text": "Zoznam miniaplikácií",
    "swipe_free_wifi_txt": "Prejdením prstom získate bezplatné Wi-Fi",
    "create_widget_txt": "Vytvoriť miniaplikáciu",
    "redeem_award_msg": "si si istý? Chcete si uplatniť túto odmenu?",
    "on_collect_msg": "On Collect",
    "pause": "Pauza",
    "resume": "Obnoviť",
    "location_added": "miesto pridané",
    "sub_user_admin_permissions_txt": "Povoliť podpoužívateľom prístup k administrátorským povoleniam v Digital Signage",
    "unlist": "Zrušiť zoznam",
    "cannot_delete_default_terms": "Predvolené zmluvné podmienky sa nedajú odstrániť",
    "something_went_wrong": "Niečo sa pokazilo!",
    "card_download_success": "Karta bola úspešne stiahnutá",
    "permission_denied": "Povolenie zamietnuté!",
    "invalid_qr_code": "Naskenujte platný QR kód",
    "select_customer": "Vyberte zákazníka",
    "online": "ONLINE",
    "offline": "OFFLINE",
    "no_data": "Hops, nenašli sa žiadne údaje!",
    "forever_free": "Navždy zadarmo",

};

export default sk