const el = {
    "rated_google_reviews": "Βαθμολογία 5.0 στις Κριτικές Google",
    "get_your_digital_menu": "Αποκτήστε το ψηφιακό μενού σας—",
    "instant_popup_menu": "Άμεσο αναδυόμενο μενού χωρίς σάρωση QR",
    "free_forever": "Δωρεάν για πάντα",
    "the_future_is_here": "Το μέλλον είναι εδώ!",
    "upgrade_your_restaurant": "Αναβαθμίστε το εστιατόριό σας με ένα εκπληκτικό ψηφιακό μενού με μηδενικό κόστος. Προσελκύστε περισσότερους πελάτες από σήμερα.",
    "get_my_free_menu_now": "Αποκτήστε τώρα το δωρεάν μενού μου",
    "no_credit_card_required": "*Δεν απαιτείται πιστωτική κάρτα",
    "join_largest_marketplace": "Εγγραφείτε στη μεγαλύτερη αγορά εστιατορίου!",
    "what_will_you_get": "Τι θα πάρετε",
    "free": "ΔΩΡΕΑΝ",
    "free_digital_menu": "Δωρεάν ψηφιακό μενού 2D ή 3D",
    "free_digital_menu_desc": "Πείτε αντίο στα χάρτινα μενού και τις ακριβές ανατυπώσεις",
    "quick_and_easy_signup": "Γρήγορη & Εύκολη Εγγραφή",
    "communicate_with_customers_title": "Επικοινωνήστε με τους πελάτες ΔΩΡΕΑΝ!",
    "features1": [
        "Εξοικονομήστε κόστος εκτύπωσης και απολαύστε άμεσες ενημερώσεις μενού όποτε τις χρειάζεστε.",
        "Πρόσβαση στο ψηφιακό μενού σας από οποιαδήποτε συσκευή",
        "Φιλικό προς το περιβάλλον και χωρίς προβλήματα"
    ],
    "features2": [
        "Αφήστε τους πελάτες να εξερευνήσουν εικονικά τα πιάτα σας με εκπληκτικά γραφικά 3D.",
        "Ενισχύστε την αφοσίωση δίνοντας στους πελάτες μια ρεαλιστική προεπισκόπηση του γεύματός τους.",
        "Ξεχωρίστε με μια εμπειρία μενού που είναι τόσο δελεαστική όσο και η γεύση του φαγητού σας."
    ],
    "features3": [
        "Χαιρετίστε αμέσως τους επισκέπτες με το μενού σας καθώς μπαίνουν στο εστιατόριο.",
        "Αυτόματη αποστολή ενημερώσεων για ειδικές προσφορές και προσφορές στα τηλέφωνά τους.",
        "Βελτιώστε την γευστική εμπειρία με γρήγορη, ανέπαφη πρόσβαση στο μενού σας."
    ],
    "feature4": [
        "Επισημάνετε τις εποχιακές προσφορές, τις καθημερινές προσφορές και τις αποκλειστικές προσφορές.",
        "Τραβήξτε την προσοχή των πελατών στα πιο δημοφιλή σας είδη.",
        "Δημιουργήστε επείγουσα ανάγκη και ενθουσιασμό με εντυπωσιακά γραφικά και περιγραφές."
    ],
    "feature5": [
        "Γίνετε μέλος μιας διαδικτυακής κοινότητας με χιλιάδες καθημερινούς επισκέπτες.",
        "Ενισχύστε την ορατότητα και προσεγγίστε νέους πελάτες που αναζητούν ενεργά επιλογές για φαγητό.",
        "Εξασφαλίστε νωρίς μια πρώτη θέση για να μεγιστοποιήσετε την έκθεσή σας και να προσελκύσετε περισσότερους επισκέπτες."
    ],
    "section3_heading": "Γιατί να Go Digital; Επειδή είναι δωρεάν και εύκολο!",
    "section3_description": "Ξεκλειδώστε ισχυρές λειτουργίες που έχουν σχεδιαστεί για να ενισχύσουν την ελκυστικότητα του εστιατορίου σας.",
    "instant_menu": "Στιγμιαίο μενού",
    "instant_menu_notification": "Αναδυόμενο παράθυρο και ειδοποίηση",
    "instant_menu_description": "Καλωσορίστε τους επισκέπτες με άμεση πρόσβαση στο μενού αμέσως μόλις εισέλθουν. Στέλνουν αυτόματα το πιο πρόσφατο μενού και τις προσφορές απευθείας στα κινητά τους τηλέφωνα",
    "digital_menu": "Ψηφιακά Μενού",
    "at_zero_cost": "με μηδενικό κόστος",
    "digital_menu_description1": "Πείτε αντίο στα χάρτινα μενού και τις ακριβές ανατυπώσεις. Μεταβείτε σε ένα ψηφιακό μενού που είναι πάντα ενημερωμένο—και είναι δωρεάν για πάντα!",
    "digital_menu_description2": "Με το ψηφιακό μας μενού, οι προσφορές σας είναι πάντα επίκαιρες και προσβάσιμες σε οποιαδήποτε συσκευή. Εξοικονομήστε χρήματα και απλοποιήστε τις ενημερώσεις μενού με μια απρόσκοπτη λύση μηδενικού κόστους.",
    "try_me_button": "Δοκιμάστε με",
    "try_3d_menu_title": "Τρισδιάστατα μενού",
    "try_3d_menu_title2": "Δωρεάν — WOW!",
    "try_3d_menu_description1": "Κάντε τα πιάτα σας να ξεπροβάλλουν από την οθόνη. Ανεβάστε τρισδιάστατες εικόνες και αφήστε τους πελάτες να δοκιμάσουν εικονικά το φαγητό σας πριν μπουν μέσα.",
    "try_3d_menu_description2": "Οι εικόνες 3D υψηλής ποιότητας δημιουργούν μια εκπληκτική προεπισκόπηση, βελτιώνοντας την ελκυστικότητα και ενθαρρύνοντας τις παραγγελίες.",
    "try_now": "Δοκιμάστε τώρα",
    "showcase_title": "Παρουσιάστε το δικό σας",
    "showcase_specials": "Ειδικά",
    "showcase_specials_description1": "Έχετε μια εκπληκτική συμφωνία ή μια ασυναγώνιστη προώθηση; Τονίστε το στο ψηφιακό μενού σας και προσελκύστε περισσότερους πεινασμένους.",
    "showcase_specials_description2": "Κάντε τις προσφορές αδύνατο να χάσετε, τραβώντας την προσοχή με τολμηρά γραφικά και δελεαστικές περιγραφές.",
    "marketplace": "Αγορά",
    "marketplace_description": "Δημιουργούμε μια αγορά με περισσότερους από 2.000 επισκέπτες καθημερινά. Επιβιβαστείτε τώρα και θα είστε πρώτοι στη σειρά όταν κυκλοφορήσουμε. Μπορείτε να προσεγγίσετε νέους πελάτες που αναζητούν ενεργά επιλογές για φαγητό. Εξασφαλίστε τη θέση σας νωρίς για να μεγιστοποιήσετε την έκθεση!",
    "see_other_products": "Δείτε άλλα προϊόντα",
    "about_us": "Σχετικά με εμάς",
    "why_serving_free": "Γιατί το σερβίρουμε αυτό",
    "serving_free_highlight": "Δωρεάν",
    "about_desc": "Είμαστε ντόπιοι εδώ στο Ντάλας, δεσμευμένοι να βοηθήσουμε εστιατόρια όπως το δικό σας να ευδοκιμήσουν.",
    "about_tool_free": "Προσφέρουμε αυτό το ισχυρό εργαλείο εντελώς δωρεάν γιατί πιστεύουμε στην υποστήριξη της κοινότητάς μας.",
    "no_catch": "Χωρίς πιάσιμο, χωρίς πλάκα. Γνωρίζουμε ότι όταν πετύχετε, η κοινότητά μας γίνεται πιο δυνατή. 😊",
    "understanding_challenges": "Κατανοούμε τις προκλήσεις της λειτουργίας μιας επιχείρησης, γι' αυτό είμαστε αφοσιωμένοι στην παροχή εργαλείων που κάνουν τις δραστηριότητές σας πιο ομαλές και την προσέγγισή σας ευρύτερη.",
    "community_growth": "Καθώς μεγαλώνετε, η κοινότητά μας μεγαλώνει μαζί σας, δημιουργώντας ένα κυματιστό αποτέλεσμα επιτυχίας και υποστήριξης. Μαζί, ας αναβαθμίσουμε την γευστική εμπειρία για όλους.",
    "how_it_works": "Πώς λειτουργεί",
    "three_simple_steps": "Τρία απλά βήματα για να",
    "get_started_highlight": "Ξεκινήστε",
    "no_tech_skills_needed": "Δεν χρειάζονται τεχνολογικές δεξιότητες. Αν μπορείτε να ρίξετε ένα μπέργκερ, μπορείτε να το κάνετε αυτό!",
    "step1_title": "Προβάλετε το promo σας ΔΩΡΕΑΝ!",
    "step1_desc": "Ξεκινήστε πιο γρήγορα από όσο μπορείτε να πείτε \"Παραγγελία!\" Απλώς συμπληρώστε μια απλή φόρμα.",
    "step2_title": "Προσαρμόστε το μενού σας",
    "step2_desc": "Προσθέστε τα πιάτα σας, ανεβάστε φωτογραφίες ακόμα και σε 3D και επισημάνετε τις σπεσιαλιτέ σας.",
    "step3_title": "Μοιραστείτε και μεγαλώστε",
    "step3_desc": "Μοιραστείτε το ψηφιακό μενού σας μέσω QR, μέσων κοινωνικής δικτύωσης ή του ιστότοπού σας.",
    "testimonials": "Μαρτυρίες",
    "testimonials_section_title": "Μην παίρνετε απλώς τον λόγο μας",
    "testimonials_section_desc": "Δείτε τι λένε άλλοι ιδιοκτήτες εστιατορίων στο Ντάλας.",
    "faq_section_title": "Έχετε ερωτήσεις; Έχουμε απαντήσεις",
    "faq_section_desc": "Όλα όσα πρέπει να γνωρίζετε πριν ξεκινήσετε.",
    "faqs": "Συχνές ερωτήσεις",
    "faq_answers": [
        {
            "que": "Είναι πραγματικά δωρεάν για πάντα;",
            "ans": "Απολύτως! Χωρίς κρυφές χρεώσεις, χωρίς πιστωτικές κάρτες, χωρίς δεσμεύσεις."
        },
        {
            "que": "Χρειάζομαι κάποια τεχνική τεχνογνωσία;",
            "ans": "Εάν μπορείτε να στείλετε ένα email, μπορείτε να το κάνετε αυτό. Είναι τόσο απλό."
        },
        {
            "que": "Γιατί το προσφέρετε δωρεάν;",
            "ans": "Πιστεύουμε στο να δίνουμε πρώτα. Στη συνέχεια, ενδέχεται να έχουμε άλλες υπηρεσίες που θα σας αρέσουν—αλλά αυτό εξαρτάται αποκλειστικά από εσάς."
        },
        {
            "que": "Ποια είναι η συμφωνία με την αγορά;",
            "ans": "Το μαγειρεύουμε! Μόλις έχουμε αρκετά εστιατόρια στο πλοίο, θα λανσάρουμε την αγορά σας για να σας προσφέρουμε ακόμη μεγαλύτερη έκθεση."
        }
    ],
    "testimonials_content1": "Η μετάβαση σε ψηφιακό μενού ήταν η καλύτερη κίνηση που κάναμε! Οι πελάτες μας λατρεύουν την άνεση και την ενημερωμένη εμφάνιση!",
    "testimonials_name1": "Μόρις Τζορτζ",
    "testimonials_restaurant_name1": "The Mansion Restaurant",
    "testimonials_content2": "Το νέο μας ψηφιακό μενού διευκολύνει την περιήγηση για τους επισκέπτες και ενισχύει πραγματικά την αφοσίωση!",
    "testimonials_name2": "Αμέλια Τζόνσον",
    "testimonials_restaurant_name2": "Urban Eats",
    "testimonials_content3": "Οι επισκέπτες εντυπωσιάζονται από τα τρισδιάστατα γραφικά—μας βοήθησαν να ξεχωρίσουμε και να βελτιώσουμε την γευστική τους εμπειρία!",
    "testimonials_name3": "Ντέιβις Γκριν",
    "testimonials_restaurant_name3": "Εστία της πόλης",
    "ready_to_boost_your_restaurant": "Είστε έτοιμοι να ενισχύσετε το εστιατόριο σας;",
    "ready_to_boost_desc": "Μην μείνετε πίσω. Λάβετε μέρος στην ψηφιακή επανάσταση σήμερα!",
    "yes_i_want_my_free_digital_menu": "Ναι, θέλω το δωρεάν ψηφιακό μενού μου",
    "480_plus": "480+",
    "people_already_joined": "άτομα έχουν ήδη ενταχθεί",
    "menu_online_home": "Μενού Online Αρχική",
    "welcome": "Καλωσόρισμα",
    "description_other": "Ας ενισχύσουμε την ικανοποίηση των πελατών σας!",
    "benefits": "Οφέλη",
    "greeting_text": "Καλώς ήλθατε 👋",
    "all_in_one_features": "🚀 Λειτουργίες Όλα σε Ένα για να αναβαθμίσετε το εστιατόριό σας",
    "menu_online_menu_name": "Μενού Online",
    "menu_online_description": "Ψηφιοποιήστε το μενού σας για εύκολη διαδικτυακή πρόσβαση από τους πελάτες.",
    "menu_online_benefits": "Βελτιώστε την άνεση των πελατών και ενισχύστε την αφοσίωση επιτρέποντάς τους να βλέπουν το μενού σας οποτεδήποτε, οπουδήποτε.",
    "ds_name": "Ψηφιακή Σήμανση",
    "ds_description": "Προβάλετε προσφορές και προωθήσεις σε δυναμικές οθόνες στο κατάστημα.",
    "ds_benefits": "Αιχμαλωτίστε την προσοχή των πελατών με ελκυστικά γραφικά, προωθώντας ανοδικές πωλήσεις και προσφορές σε πραγματικό χρόνο χωρίς κόπο.",
    "loyalty_punch_name": "Κάρτα διάτρησης αφοσίωσης",
    "loyalty_punch_description": "Επιβραβεύστε τους επαναλαμβανόμενους πελάτες με ένα ψηφιακό πρόγραμμα αφοσίωσης.",
    "loyalty_punch_benefits": "Αυξήστε τη διατήρηση των πελατών και αυξήστε τις πωλήσεις παρέχοντας κίνητρα αφοσίωσης με εξαργυρώσιμες ανταμοιβές για συχνές επισκέψεις.",
    "marketing_menu_name": "Εμπορία",
    "marketing_description": "Προσεγγίστε περισσότερους πελάτες με στοχευμένες καμπάνιες ψηφιακού μάρκετινγκ.",
    "marketing_benefits": "Επεκτείνετε την εμβέλεια του εστιατορίου σας και αυξήστε την επισκεψιμότητα με προσαρμοσμένες προσφορές προσαρμοσμένες ώστε να προσελκύουν νέους και επαναλαμβανόμενους πελάτες.",
    "get_more_reviews_menu_name": "Λάβετε περισσότερες κριτικές",
    "get_more_reviews_description": "Ενθαρρύνετε τους ικανοποιημένους πελάτες να αφήνουν θετικές κριτικές στο διαδίκτυο.",
    "get_more_reviews_benefits": "Ενισχύστε την αξιοπιστία του εστιατορίου σας και προσελκύστε νέους πελάτες, ενισχύοντας τη φήμη σας στο διαδίκτυο με περισσότερες θετικές κριτικές.",
    "guest_smart_wifi_menu_name": "Guest Smart Wifi",
    "guest_smart_wifi_description": "Προσφέρετε δωρεάν Wi-Fi για τη λήψη δεδομένων επισκεπτών για μάρκετινγκ.",
    "guest_smart_wifi_benefits": "Αυξήστε την ικανοποίηση των πελατών συλλέγοντας πολύτιμα δεδομένα πελατών για να εξατομικεύσετε τις μελλοντικές προσφορές.",
    "reservation_menu_name": "Κράτηση",
    "reservation_description": "Επιτρέψτε στους πελάτες να κάνουν κράτηση τραπεζιών online με ευκολία.",
    "reservation_benefits": "Βελτιώστε την εμπειρία φαγητού ελαχιστοποιώντας τους χρόνους αναμονής και απλοποιήστε τις κρατήσεις για καλύτερη διαχείριση των θέσεων.",
    "pos_integration_menu_name": "Ενσωμάτωση POS",
    "pos_integration_description": "Ενσωματώστε το σύστημά μας με το σύστημα POS σας.",
    "pos_integration_benefits": "Απλοποιήστε την επεξεργασία των παραγγελιών και μειώστε τα σφάλματα διατηρώντας τα ηλεκτρονικά σας συστήματα και τα συστήματα του καταστήματος συγχρονισμένα.",
    "online_ordering_menu_name": "Online Παραγγελία",
    "online_ordering_description": "Δώστε τη δυνατότητα στους πελάτες να κάνουν παραγγελίες απευθείας από τον ιστότοπο ή την εφαρμογή σας.",
    "online_ordering_benefits": "Αυξήστε τις πωλήσεις και μειώστε την εξάρτηση από πλατφόρμες τρίτων, προσφέροντας μια επιλογή άμεσης παραγγελίας που εξοικονομεί τέλη.",
    "pro_label": "Pro",
    "coming_soon": "Προσεχώς",
    "locked_label": "Κλειδωμένο",
    "forever_label": "Πάντα",
    "new_label": "Νέος",
    "other_products_page_title": "Άλλα προϊόντα | Μενού Online",
    "other_products_description": "Μενού Online Πολιτική Απορρήτου",
    "other_products_header_title": "Άλλα Προϊόντα",
    "privacy_policy": {
        "title": "Πολιτική Απορρήτου",
        "lastUpdated": "Τελευταία ενημέρωση: 01 Ιουλίου 2024",
        "introduction": {
            "header": "Πολιτική απορρήτου για νέους και υφιστάμενους πελάτες",
            "paragraph1": "ΤΑ ΑΚΟΛΟΥΘΑ ΒΑΣΙΚΑ ΣΗΜΕΙΑ (ΣΥΜΠΕΡΙΛΑΜΒΑΝΟΜΕΝΩΝ ΑΝΑΦΟΡΕΣ ΤΗΣ ΕΝΟΤΗΤΑΣ) ΠΑΡΕΧΟΝΤΑΙ ΜΟΝΟ ΓΙΑ ΤΗΝ ΕΥΚΟΛΥΝΗ ΣΑΣ ΚΑΙ ΔΕΝ ΑΝΤΙΚΑΤΑΣΤΑΙ ΤΟΥΣ ΠΛΗΡΟΥΣ ΟΡΟΥΣ ΚΑΙ ΠΡΟΫΠΟΘΕΣΕΙΣ. ΕΙΝΑΙ ΔΙΚΗ ΣΑΣ ΕΥΘΥΝΗ ΝΑ ΔΙΑΒΑΣΕΤΕ ΑΥΤΟΥΣ ΤΟΥΣ ΟΡΟΥΣ ΚΑΙ ΠΡΟΫΠΟΘΕΣΕΙΣ ΠΡΙΝ ΧΡΗΣΙΜΟΠΟΙΗΣΕΤΕ ΤΗΝ ΥΠΗΡΕΣΙΑ MENUONLINE INC.",
            "paragraph2": "Η χρήση του Menuonline σημαίνει ότι συμφωνείτε με τους Όρους και Προϋποθέσεις και δηλώνετε και εγγυάστε ότι είστε εξουσιοδοτημένος από τον οργανισμό ή την εταιρεία σας να το πράξετε. (Βλ. 3 «Πώς λειτουργεί»)",
            "paragraph3": "Η Menuonline Inc είναι μια υπηρεσία μάρκετινγκ που βασίζεται σε άδεια. Η σκόπιμη αποστολή «spam» ή η παραβίαση των Όρων και Προϋποθέσεων θα έχει ως αποτέλεσμα το κλείσιμο του λογαριασμού σας. (Βλ. s.16 «Αποδεκτή χρήση της υπηρεσίας»)",
            "paragraph4": "Τα δεδομένα που φιλοξενείτε στη Menuonline Inc, όπως αρχεία επαφών, περιεχόμενο email και πληροφορίες που συλλέγετε μέσω της Menuonline Inc, ανήκουν σε εσάς. (Βλέπε εδάφιο 14 «Τι κατέχεις;»).",
            "paragraph5": "Το λογισμικό Menuonline Inc και το περιεχόμενο στον ιστότοπό μας ανήκουν σε εμάς. (Βλ. 13 «Τι κατέχουμε;») Εκτός από τους δικούς σας χρήστες, τα δεδομένα ή το μη δημοσιευμένο περιεχόμενο που φιλοξενείτε στη Menuonline Inc μπορούν να προβληθούν μόνο από υπαλλήλους και συνεργάτες της Menuonline Inc που έχουν υπογράψει συμφωνίες εμπιστευτικότητας. (Βλέπε εδάφιο 14 «Τι κατέχεις;»)",
            "paragraph6": "Όλες οι πληροφορίες προσωπικής ταυτοποίησης (PII) διατηρούνται μόνο σε διακομιστές που βρίσκονται στον Καναδά. (Βλέπε εδάφιο 14 «Τι κατέχεις;»).",
            "paragraph7": "Η υπηρεσία Menuonline Inc υπόκειται στους νόμους του Καναδά και της επαρχίας του Οντάριο. (Βλ. εδάφιο 24 «Εφαρμόσιμος Νόμος»)",
            "paragraph8": "Οποιαδήποτε αιτήματα για τα δεδομένα ή το περιεχόμενό σας από άτομα, αστυνομία ή άλλους ρυθμιστικούς φορείς εντός του Καναδά ή εκτός Καναδά θα παραπέμπονται πάντα σε εσάς. Η μόνη εξαίρεση θα ήταν εάν αυτό μας παραβιάζει την καναδική νομοθεσία ή τη νομοθεσία του Οντάριο. Ωστόσο, σε όλες τις περιπτώσεις θα αντισταθούμε σθεναρά σε οποιαδήποτε αποδέσμευση των δεδομένων σας από οποιονδήποτε εκτός από εσάς. (Βλέπε εδάφιο 14 «Τι κατέχεις;»).",
            "paragraph9": "Η ασφάλεια του Menuonline Inc είναι τόσο καλή όσο η διαχείριση των ονομάτων χρήστη και των κωδικών πρόσβασης. Δεν χρεώνουμε για λογαριασμούς χρηστών Menuonline Inc, επομένως δημιουργήστε έναν ξεχωριστό για κάθε χρήστη και κρατήστε τους κωδικούς πρόσβασης μυστικούς. Είστε υπεύθυνοι για τον λογαριασμό σας. (Βλ. 3 «Πώς λειτουργεί»)",
            "paragraph10": "Μπορείτε να ακυρώσετε τον λογαριασμό σας ανά πάσα στιγμή. Δεν παρέχουμε επιστροφές χρημάτων για τυχόν αχρησιμοποίητη πίστωση εάν ακυρώσετε. (Βλ. 3 «Πώς λειτουργεί»)"
        },
        "termsAndConditions": {
            "title": "ΟΡΟΙ ΚΑΙ ΠΡΟΫΠΟΘΕΣΕΙΣ MENUONLINE INC",
            "lastUpdate": "ΤΕΛΕΥΤΑΙΑ ΕΝΗΜΕΡΩΣΗ: 14 Ιανουαρίου 2020",
            "paragraph1": "Παρακαλούμε διαβάστε αυτούς τους όρους και τις προϋποθέσεις παροχής υπηρεσιών (τους «Όρους») προσεκτικά πριν χρησιμοποιήσετε αυτήν την τοποθεσία web (η «Ιστοσελίδα»). Αυτοί οι Όροι απαλλάσσουν την Menuonline Inc. (\"Menuonline Inc\" \"εμείς\", ή \"εμάς\") και άλλους από την ευθύνη ή\/και περιορίζουν τη δική μας και την ευθύνη τους και περιέχουν άλλες σημαντικές διατάξεις που ισχύουν για τη χρήση αυτού του ιστότοπου από εσάς. Επισκεπτόμενοι ή χρησιμοποιώντας αυτόν τον Ιστότοπο ή την Υπηρεσία, συμφωνείτε εκ μέρους σας και εκ μέρους οποιουδήποτε οργανισμού για λογαριασμό του οποίου μπορείτε να ενεργείτε (συλλογικά αναφερόμενοι στο παρόν ως \"εσείς\"), να αποδέχεστε και να συμμορφώνεστε με αυτούς τους Όρους για κάθε χρήση και κάθε επίσκεψη σε αυτόν τον Ιστότοπο."
        },
        "services": {
            "title": "Οι υπηρεσίες μας.",
            "paragraph": "Οι υπηρεσίες που προσφέρουμε μέσω του ιστότοπου είναι μια υπηρεσία που σας επιτρέπει να δημιουργείτε, να στέλνετε και να διαχειρίζεστε μηνύματα ηλεκτρονικού ταχυδρομείου (\"Email\") σε παραλήπτες της επιλογής σας (η \"Υπηρεσία\")."
        },
        "license": {
            "title": "Αδεια.",
            "paragraph": "Με την επιφύλαξη αυτών των Όρων, η Menuonline Inc σας παραχωρεί με το παρόν μια μη αποκλειστική, περιορισμένη, μη μεταβιβάσιμη άδεια χρήσης κατά τη διάρκεια της Διάρκειας, για να δείτε τον Ιστότοπο και να χρησιμοποιήσετε την Υπηρεσία όπως επιτρέπεται από τα χαρακτηριστικά της Υπηρεσίας. Διατηρούμε όλα τα δικαιώματα που δεν παραχωρούνται ρητά στο παρόν στην Υπηρεσία και στο Περιεχόμενο Menuonline Inc (όπως ορίζονται παρακάτω)."
        },
        "emailingContent": {
            "title": "Περιεχόμενο αποστολής email.",
            "paragraph": "Όταν αλληλεπιδράτε με την Menuonline Inc μέσω αυτού του ιστότοπου ή της Υπηρεσίας, συμφωνείτε να παρέχετε αληθείς και ακριβείς πληροφορίες. Επιπλέον, κατανοείτε ότι η παροχή εμπιστευτικών πληροφοριών μέσω email είναι μια μη ασφαλής πρακτική. Κατανοείτε ότι είστε αποκλειστικά υπεύθυνοι για οτιδήποτε στέλνετε σε οποιονδήποτε χρησιμοποιεί την Υπηρεσία μας."
        },
        "support": {
            "title": "Υποστήριξη.",
            "paragraph": "Σας παρέχουμε υποστήριξη για να σας βοηθήσουμε να γίνετε αυτάρκεις με την Υπηρεσία. Σας παρέχουμε υποστήριξη με τη μορφή τεκμηρίωσης και βίντεο στον Ιστότοπο, στο σύστημα εισιτηρίων υποστήριξης Menuonline Inc και μέσω τηλεφώνου. Μπορείτε να επικοινωνήσετε μαζί μας κάνοντας κλικ στην καρτέλα \"Υποστήριξη\" στο επάνω μέρος του ιστότοπου. Δεν υπάρχει χρέωση για την υποστήριξη προϊόντων. Ωστόσο, διατηρούμε το δικαίωμα να διακόψουμε την υποστήριξη για συγκεκριμένους λογαριασμούς πελατών κατά την κρίση μας."
        }
    },
    "terms_condition": {
        "title": "Όροι Παροχής Υπηρεσιών | Μενού Online",
        "header": "Όροι & Προϋποθέσεις για νέους και υφιστάμενους πελάτες"
    },
    "privacy_policy_title": "ΙΔΙΩΤΙΚΗ ΠΟΛΙΤΙΚΗ",
    "privacy_policy_effective_date": "Σε ισχύ από 16 Ιανουαρίου 2020",
    "privacy_policy_general_title": "ΓΕΝΙΚΗ ΙΔΙΩΤΙΚΗ ΠΟΛΙΤΙΚΗ",
    "privacy_policy_intro": "Το menuonline.com (Menuonline και «εμάς») κατανοεί τη σημασία της προστασίας του απορρήτου των προσωπικών πληροφοριών των ατόμων και τη σημασία της ασφάλειας των εμπιστευτικών πληροφοριών κάθε είδους. Δημιουργήσαμε αυτήν την Ιδιωτική Πολιτική (η «Πολιτική») για να αποδείξουμε τη δέσμευσή μας να προστατεύσουμε το «απόρρητό» σας. Αυτή η ιδιωτική πολιτική εξηγεί:",
    "privacy_policy_points": [
        "Ποιες προσωπικές πληροφορίες συλλέγουμε και γιατί τις συλλέγουμε.",
        "Πώς χρησιμοποιούμε τα προσωπικά σας στοιχεία.",
        "Με ποιους μοιραζόμαστε τα προσωπικά σας στοιχεία. και",
        "Οι επιλογές που έχετε για πρόσβαση, ενημέρωση και κατάργηση των προσωπικών σας στοιχείων από την υπηρεσία."
    ],
    "privacy_policy_pipeda": "Το Menuonline διαχειρίζεται τη συλλογή όλων των πληροφοριών σύμφωνα με τον νόμο περί προστασίας προσωπικών πληροφοριών και ηλεκτρονικών εγγράφων του Καναδά (PIPEDA).",
    "privacy_policy_update_notice": "Λάβετε υπόψη ότι το Menuonline διατηρεί το δικαίωμα να ενημερώσει ή να αλλάξει αυτήν την Ιδιωτική Πολιτική ανά πάσα στιγμή. Θα σας ειδοποιήσουμε για οποιεσδήποτε αλλαγές δημοσιεύοντας μια ενημερωμένη Πολιτική σε αυτόν τον ιστότοπο ή όπως διατίθεται μέσω των υπηρεσιών. Η πιο πρόσφατη έκδοση αυτής της Πολιτικής θα διέπει τη χρήση των προσωπικών στοιχείων των χρηστών από το mycircle. Συνεχίζοντας την πρόσβαση στον ιστότοπο του mycircle ή συνεχίζοντας να χρησιμοποιείτε τις υπηρεσίες του μετά την πραγματοποίηση αλλαγών στην παρούσα Πολιτική Ιδιωτικού Απορρήτου, συμφωνείτε ότι δεσμεύεστε από τους αναθεωρημένους όρους.",
    "privacy_policy_consent_title": "ΣΥΝΑΙΝΕΣΗ ΓΙΑ ΠΡΟΣΩΠΙΚΕΣ ΠΛΗΡΟΦΟΡΙΕΣ",
    "privacy_policy_consent_text": "Παρέχοντας στο Menuonline τα προσωπικά σας στοιχεία μέσω του ιστότοπού μας ή οποιασδήποτε από τις φόρμες μας, συναινείτε στη συλλογή, χρήση και αποκάλυψη τέτοιων πληροφοριών σύμφωνα με την παρούσα Πολιτική ή για τους σκοπούς που προσδιορίστηκαν σε εσάς τη στιγμή που παρείχατε τις προσωπικές πληροφορίες. Οποιαδήποτε δευτερεύουσα χρήση των προσωπικών πληροφοριών θα υποβληθεί σε επεξεργασία μόνο με τη ρητή συγκατάθεσή σας ή θα σας δοθεί η ευκαιρία να εξαιρεθείτε.",
    "privacy_policy_opt_out_text": "Έχετε την ευκαιρία να αποσύρετε τη συγκατάθεσή σας ή να εξαιρεθείτε ανά πάσα στιγμή. Εάν θέλετε να αποσύρετε τη συγκατάθεσή σας ή να εξαιρεθείτε, μπορείτε να κάνετε κλικ στον σύνδεσμο προτιμήσεων επικοινωνίας στο κάτω μέρος οποιουδήποτε email λαμβάνετε από εμάς.",
    "privacy_policy_info_collection_title": "ΠΛΗΡΟΦΟΡΙΕΣ ΠΟΥ ΣΥΛΛΕΓΟΥΜΕ",
    "privacy_policy_info_collection_text": "Το Menuonline συλλέγει και επεξεργάζεται προσωπικές πληροφορίες σύμφωνα με την παρούσα Πολιτική. Δεν θα πουλήσουμε, δεν θα μοιραστούμε ή θα νοικιάσουμε αυτές τις πληροφορίες σε άλλους, εκτός από όσα αναφέρονται στην παρούσα Πολιτική. Το Menuonline συλλέγει προσωπικές πληροφορίες για τους σκοπούς της παροχής των υπηρεσιών ή των προϊόντων μας σε εσάς.",
    "privacy_policy_info_types_text": "Οι τύποι πληροφοριών που συλλέγει το Menuonline περιλαμβάνουν:",
    "privacy_policy_account_holders_title": "Κάτοχοι λογαριασμών Online Menu",
    "privacy_policy_registration_info": "Πληροφορίες εγγραφής: Εάν εγγραφείτε για να χρησιμοποιήσετε υπηρεσίες, χρειάζεστε έναν λογαριασμό Menuonline για να γίνετε κάτοχος λογαριασμού (\"Κάτοχος Λογαριασμού\"). Όταν εγγράφεστε για έναν λογαριασμό, θα σας ζητηθεί να δώσετε ορισμένες βασικές πληροφορίες, όπως το όνομα, τη διεύθυνση email, το όνομα χρήστη, τον κωδικό πρόσβασης, το όνομα της εταιρείας, το επάγγελμα, την τοποθεσία και τον αριθμό τηλεφώνου σας. Οι κωδικοί πρόσβασης είναι κρυπτογραφημένοι – Το προσωπικό του Menuonline δεν μπορεί να δει ή να ανακτήσει κωδικούς πρόσβασης.",
    "privacy_policy_billing_info": "Στοιχεία χρέωσης: Εάν εγγραφείτε στις υπηρεσίες μας, θα χρειαστεί να μας παρέχετε πληροφορίες πληρωμής και χρέωσης.",
    "privacy_policy_cookies_info": "Cookies: Χρησιμοποιούμε cookies για να παρακολουθούμε δεδομένα επισκεπτών, όπως την πηγή των επισκέψεων στον ιστότοπο και τον αριθμό των επισκέψεων στον ιστότοπό μας.",
    "privacy_policy_ip_info": "Διευθύνσεις IP: Οι διευθύνσεις IP συλλέγονται για εσάς ως μέρος της χρήσης των υπηρεσιών από εσάς (π.χ. για τον σκοπό της καταγραφής της τοποθεσίας σε επίπεδο χώρας και των διευθύνσεων IP που χρησιμοποιούνται κατά τη χρήση των υπηρεσιών μας).",
    "privacy_policy_your_contacts_title": "Οι Επαφές σας",
    "privacy_policy_distribution_list": "Λίστα διανομής: Ως μέρος της χρήσης των υπηρεσιών μας, ενδέχεται να μας παρέχετε ή να συλλέγουμε ως μέρος των υπηρεσιών, προσωπικές πληροφορίες σχετικά με τις επαφές σας (\"Επαφές\"). Αυτές οι πληροφορίες περιλαμβάνουν διευθύνσεις email και ενδέχεται να περιλαμβάνουν άλλες αναγνωρίσιμες πληροφορίες, όπως όνομα, όνομα εταιρείας ή άλλες πληροφορίες που παρέχετε στο Menuonline ενώ χρησιμοποιείτε τις υπηρεσίες. Πρέπει να διασφαλίσετε ότι λαμβάνετε την κατάλληλη συγκατάθεση από τις Επαφές σας για να επιτρέψετε την επεξεργασία των πληροφοριών τους από εμάς.",
    "privacy_policy_billing_info_contacts": "Στοιχεία χρέωσης: Εάν εγγραφείτε στις υπηρεσίες μας, θα χρειαστεί να μας παρέχετε πληροφορίες πληρωμής και χρέωσης.",
    "privacy_policy_cookies_contacts": "Cookies: Το Menuonline θα χρησιμοποιεί cookies και παρόμοιες τεχνολογίες παρακολούθησης για τη συλλογή και χρήση προσωπικών πληροφοριών σχετικά με τις Επαφές σας μόνο εάν έχετε προσθέσει τον κώδικα παρακολούθησης Menuonline στον ιστότοπό σας. Τυχόν σελίδες προορισμού που φιλοξενούνται από το Menuonline θα έχουν αυτόματα έναν κώδικα παρακολούθησης.",
    "privacy_policy_ip_contacts": "Διευθύνσεις IP: Οι διευθύνσεις IP συλλέγονται σχετικά με τις Επαφές σας ως μέρος των υπηρεσιών (π.χ. με σκοπό την καταγραφή της τοποθεσίας σε επίπεδο χώρας και των διευθύνσεων IP που χρησιμοποιούνται κατά την υποβολή μιας φόρμας και την παροχή συναίνεσης).",
    "privacy_policy_info_about_contacts_title": "ΠΛΗΡΟΦΟΡΙΕΣ ΣΥΛΛΕΓΟΥΜΕ ΓΙΑ ΤΙΣ ΕΠΑΦΕΣ ΣΑΣ",
    "privacy_policy_info_about_contacts_notice": "*Αυτή η ενότητα ισχύει μόνο για πληροφορίες που συλλέγονται, χρησιμοποιούνται ή αποκαλύπτονται από το Menuonline σχετικά με τις Επαφές κατόχου λογαριασμού για τους σκοπούς παροχής των υπηρεσιών*",
    "privacy_policy_info_about_contacts_desc": "Το Menuonline θα συλλέξει, θα χρησιμοποιήσει ή θα αποκαλύψει προσωπικές πληροφορίες σχετικά με τις Επαφές μόνο εάν ο Κάτοχος Λογαριασμού έχει εγγραφεί για ορισμένες υπηρεσίες όπου μας παρέχει μια λίστα διανομής. Οι Κάτοχοι Λογαριασμού είναι αποκλειστικά υπεύθυνοι για τη διασφάλιση της συμμόρφωσης με όλους τους ισχύοντες νόμους όταν παρέχουν προσωπικές πληροφορίες σχετικά με τις Επαφές τους στο Menuonline μέσω των υπηρεσιών.",
    "privacy_policy_info_auto_collect_title": "Το Information Menuonline συλλέγεται αυτόματα",
    "privacy_policy_info_auto_collect_desc1": "Όταν οι Επαφές του Κατόχου Λογαριασμού αλληλεπιδρούν με μια καμπάνια ηλεκτρονικού ταχυδρομείου που λαμβάνεται από έναν Κάτοχο Λογαριασμού ή αλληλεπιδρούν με έναν σύνδεσμο προς τον συνδεδεμένο ιστότοπο ενός κατόχου λογαριασμού, το Menuonline μπορεί να συλλέγει πληροφορίες σχετικά με τη συσκευή αυτής της Επαφής και την αλληλεπίδραση με το email μέσω της χρήσης cookie. Αυτές οι πληροφορίες συλλέγονται μόνο για την παροχή των υπηρεσιών και για την παροχή πληροφοριών στον Κάτοχο Λογαριασμού.",
    "privacy_policy_info_auto_collect_desc2": "Οι πληροφορίες που συλλέγουμε σχετικά με τη συσκευή μιας Επαφής και τις εφαρμογές που χρησιμοποιεί η Επαφή για πρόσβαση στα email που αποστέλλονται από τον Κάτοχο λογαριασμού, μέσω των υπηρεσιών μας, μπορεί να περιλαμβάνουν τη διεύθυνση IP, το λειτουργικό σύστημα, το αναγνωριστικό προγράμματος περιήγησης και άλλες σχετικές πληροφορίες σχετικά με το σύστημα και τη σύνδεση της Επαφής.",
    "privacy_policy_info_auto_collect_desc3": "Οι πληροφορίες που συλλέγουμε σχετικά με την αλληλεπίδραση της Επαφής με μηνύματα ηλεκτρονικού ταχυδρομείου που αποστέλλονται μέσω των υπηρεσιών ενδέχεται να περιλαμβάνουν ημερομηνίες και ώρες κατά τις οποίες η Επικοινωνία αποκτά πρόσβαση σε email και δραστηριότητες περιήγησης, καθώς και πληροφορίες σχετικά με την απόδοση των υπηρεσιών, όπως η δυνατότητα παράδοσης μηνυμάτων ηλεκτρονικού ταχυδρομείου και άλλων επικοινωνιών που αποστέλλονται στην Επικοινωνία από Κάτοχο Λογαριασμού μέσω των υπηρεσιών.",
    "privacy_policy_info_auto_collect_desc4": "Λάβετε υπόψη ότι το Menuonline δεν θα επικοινωνήσει με καμία από τις Επαφές σας, εκτός εάν απαιτείται από την ισχύουσα νομοθεσία.",
    "privacy_policy_why_we_collect_title": "ΓΙΑΤΙ ΣΥΛΛΕΓΟΥΜΕ ΤΙΣ ΠΛΗΡΟΦΟΡΙΕΣ ΣΑΣ",
    "privacy_policy_why_we_collect_desc": "Το Menuonline συλλέγει πληροφορίες για τους ακόλουθους σκοπούς:",
    "privacy_policy_why_collect_1": "να παρέχει στους κατόχους λογαριασμού Menuonline τις υπηρεσίες για τις οποίες είναι συνδρομητές, συμπεριλαμβανομένης της χρήσης πληροφοριών κατόχου λογαριασμού για τις υπηρεσίες·",
    "privacy_policy_why_collect_2": "για διαχείριση και διαχείριση λογαριασμού, καθώς και πρόσβαση στον ιστότοπο του mycircle.",
    "privacy_policy_why_collect_3": "εάν έχετε συναινέσει στη λήψη υλικού μάρκετινγκ και προώθησης, να σας ενημερώσουμε για άλλα προϊόντα και υπηρεσίες που προσφέρει το Menuonline. και",
    "privacy_policy_why_collect_4": "για να απαντήσετε στις επικοινωνίες του κατόχου λογαριασμού ή να στείλετε πληροφορίες που ζητήσατε. Αυτό μπορεί να περιλαμβάνει, αλλά δεν περιορίζεται σε:",
    "privacy_policy_why_collect_5": "ειδικές προσφορές και ενημερώσεις·",
    "privacy_policy_why_collect_6": "εγκύκλιος; και",
    "privacy_policy_why_collect_7": "ανακοινώσεις υπηρεσιών.",
    "privacy_policy_opt_out_desc": "Μπορείτε, ανά πάσα στιγμή, να επιλέξετε να εξαιρεθείτε από τη λήψη επικοινωνιών κάνοντας κλικ στο κουμπί απεγγραφής στο κάτω μέρος κάθε email που σας αποστέλλεται από το Menuonline.",
    "privacy_policy_use_disclosure_title": "ΧΡΗΣΗ ΚΑΙ ΑΠΟΚΑΛΥΨΗ ΠΡΟΣΩΠΙΚΩΝ ΠΛΗΡΟΦΟΡΙΩΝ",
    "privacy_policy_use_disclosure_desc1": "Οι προσωπικές πληροφορίες που υποβάλλονται στο Menuonline, συμπεριλαμβανομένου του ιστότοπου του Menuonline ή μιας προκαθορισμένης φόρμας, διατηρούνται αυστηρά εμπιστευτικές, εκτός εάν αποκαλυφθούν σύμφωνα με τη ρητή συγκατάθεση του Κατόχου Λογαριασμού ή τις νομικές απαιτήσεις. Σε κάθε περίπτωση, οποιαδήποτε προσωπική πληροφορία παρέχεται σε οποιαδήποτε πλατφόρμα \"Menuonline\" από τους Κατόχους Λογαριασμού είναι εθελοντική: οποιαδήποτε προσωπική πληροφορία παρέχεται από έναν Κάτοχο Λογαριασμού υποβάλλεται κατά την κρίση του Κατόχου Λογαριασμού. Λάβετε υπόψη ότι η ανάκληση της συγκατάθεσής σας ενδέχεται να έχει ως αποτέλεσμα τη μη δυνατότητα πρόσβασης ή χρήσης ορισμένων υπηρεσιών Menuonline.",
    "privacy_policy_use_disclosure_desc2": "Το Menuonline χρησιμοποιεί τρίτα μέρη και εξωτερικούς παρόχους υπηρεσιών σε σχέση με τον ιστότοπο και τις υπηρεσίες του. Τα προσωπικά στοιχεία του Κατόχου Λογαριασμού ενδέχεται να κοινοποιηθούν σε τέτοιους τρίτους και παρόχους. Ενδέχεται επίσης να παρέχουμε συγκεντρωτικά στατιστικά στοιχεία σχετικά με τους κατόχους λογαριασμού, τις πωλήσεις, τα μοτίβα επισκεψιμότητας και σχετικές πληροφορίες σε αξιόπιστα τρίτα μέρη. Το Menuonline απαιτεί ότι οποιοσδήποτε τρίτος πάροχος που έχει πρόσβαση σε προσωπικές πληροφορίες δεσμεύεται από υποχρεώσεις που συνάδουν με την παρούσα Ιδιωτική Πολιτική και ότι αυτοί οι πάροχοι συλλέγουν, χρησιμοποιούν ή αποκαλύπτουν προσωπικά στοιχεία του κατόχου λογαριασμού μόνο για τους σκοπούς της παροχής υπηρεσιών Menuonline.",
    "privacy_policy_use_disclosure_desc3": "Οι προσωπικές πληροφορίες που παρέχονται από τους Κατόχους Λογαριασμού δεν θα πωληθούν, κοινοποιηθούν ή ενοικιαστούν σε εξωτερικούς οργανισμούς, εκτός εάν είναι σύμφωνα με τη σιωπηρή ή ρητή συγκατάθεσή σας. Το Menuonline διατηρεί το δικαίωμα να επικοινωνήσει με οποιουσδήποτε Κατόχους Λογαριασμού προκειμένου να προωθήσει πληροφορίες, να απαντήσει σε ερωτήσεις ή να ειδοποιήσει τυχόν αλλαγές στο πρόγραμμα ή τις πολιτικές μας. Λάβετε υπόψη ότι το Menuonline δεν θα επικοινωνήσει με καμία από τις Επαφές σας, εκτός εάν απαιτείται από την ισχύουσα νομοθεσία.",
    "privacy_policy_use_disclosure_desc4": "Το Menuonline θα αποκαλύψει προσωπικές πληροφορίες μόνο εάν δοθεί η συγκατάθεσή του από το σχετικό άτομο ή:",
    "privacy_policy_disclosure_list_1": "όταν επιτρέπεται ή απαιτείται από το νόμο, όπως ως απάντηση σε κλήτευση ή άλλη νομική διαδικασία·",
    "privacy_policy_disclosure_list_2": "σε έναν εκδοχέα του συνόλου ή μέρους του Menuonline·",
    "privacy_policy_disclosure_list_3": "στους παρόχους υπηρεσιών του Menuonline σύμφωνα με την παρούσα Πολιτική· ή",
    "privacy_policy_disclosure_list_4": "για την εκπλήρωση νομικών υποχρεώσεων, συμπεριλαμβανομένων, ενδεικτικά, των υποχρεώσεων ρυθμιστικής αναφοράς.",
    "privacy_policy_service_providers_title": "Το Menuonline χρησιμοποιεί τα ακόλουθα τρίτα μέρη και παρόχους υπηρεσιών (τα προσωπικά στοιχεία των Επαφών σας δεν αποκαλύπτονται σε \"τέτοια\" τρίτα μέρη):",
    "privacy_policy_service_provider_1": "Χρησιμοποιούμε το ManyChat για να παρέχουμε υπηρεσίες συνομιλίας στον ιστότοπο για πωλήσεις και υποστήριξη, καθώς και επακόλουθα μηνύματα ηλεκτρονικού ταχυδρομείου σχετικά με αυτές τις συνομιλίες.",
    "privacy_policy_service_provider_2": "Χρησιμοποιούμε το AskNicely για να ερευνήσουμε τους πελάτες μας με σκοπό να ρωτήσουμε για την ποιότητα των υπηρεσιών και την υποστήριξη που λαμβάνουν.",
    "privacy_policy_service_provider_3": "Χρησιμοποιούμε το join.me για να προγραμματίσουμε συναντήσεις πελατών και μελλοντικών πελατών, κλήσεις υποστήριξης και επιδείξεις λογισμικού.",
    "privacy_policy_service_provider_4": "Χρησιμοποιούμε το Google Analytics για να παρακολουθούμε τη δραστηριότητα ανώνυμων ιστοτόπων και να μετράμε τα μοτίβα χρήσης στον ιστότοπό μας.",
    "privacy_policy_breach_notification_title": "ΕΙΔΟΠΟΙΗΣΗ ΠΑΡΑΒΑΣΗΣ",
    "privacy_policy_breach_notification_desc": "Το Menuonline θα συμμορφώνεται και θα παρέχει στους Κατόχους Λογαριασμού εύλογη βοήθεια σύμφωνα με την ισχύουσα νομοθεσία σχετικά με οποιαδήποτε μη εξουσιοδοτημένη χρήση, πρόσβαση ή αποκάλυψη προσωπικών πληροφοριών.",
    "privacy_policy_rights_access_title": "ΔΙΚΑΙΩΜΑΤΑ ΚΑΙ ΠΡΟΣΒΑΣΗ ΣΑΣ",
    "privacy_policy_rights_access_desc": "Έχετε το δικαίωμα πρόσβασης σε προσωπικές πληροφορίες που επεξεργάζεται το Menuonline σχετικά με εσάς. Μπορείτε να ζητήσετε από εμάς πληροφορίες σχετικά με:",
    "privacy_policy_rights_access_list_1": "Τα προσωπικά στοιχεία που διατηρούμε για εσάς",
    "privacy_policy_rights_access_list_2": "Οι σχετικές κατηγορίες προσωπικών πληροφοριών",
    "privacy_policy_rights_access_list_3": "Οι σκοποί της επεξεργασίας",
    "privacy_policy_rights_access_list_4": "Λεπτομέρειες σε ποιον έχουν\/θα γνωστοποιηθούν τα προσωπικά σας στοιχεία",
    "privacy_policy_rights_access_list_5": "Πόσο καιρό διατηρούμε τα προσωπικά σας στοιχεία",
    "privacy_policy_rights_access_list_6": "Εάν δεν συλλέξαμε τις πληροφορίες απευθείας από εσάς, πληροφορίες σχετικά με την πηγή",
    "privacy_policy_rights_access_list_7": "Πώς να υποβάλετε καταγγελία στη σωστή εποπτική αρχή",
    "privacy_policy_rights_access_list_8": "Μπορείτε επίσης να ζητήσετε από εμάς τα ακόλουθα:",
    "privacy_policy_rights_access_list_9": "Ότι ενημερώνουμε τυχόν ελλιπείς ή ανακριβείς πληροφορίες για εσάς",
    "privacy_policy_rights_access_list_10": "Ζητήστε να διαγράψουμε τα προσωπικά σας στοιχεία σύμφωνα με την ισχύουσα νομοθεσία",
    "privacy_policy_rights_access_contact": "Μπορείτε να ζητήσετε να ενεργήσουμε για τα δικαιώματά σας επικοινωνώντας μαζί μας στο 2851 Dufferin Street, Τορόντο, ON, Καναδάς ή στέλνοντάς μας email στο {link}.",
    "privacy_policy_accountability_title": "ΕΥΘΥΝΗ",
    "privacy_policy_accountability_desc": "Η Menuonline δεσμεύεται να είναι υπόλογη για τις προσωπικές και εμπιστευτικές πληροφορίες που μας παρέχετε. Για πληροφορίες επικοινωνίας, ανατρέξτε στο τέλος αυτής της Πολιτικής.",
    "privacy_policy_links_other_sites_title": "ΣΥΝΔΕΣΜΟΙ ΠΡΟΣ ΑΛΛΟΥΣ ΙΣΤΟΤΟΠΟΥΣ",
    "privacy_policy_links_other_sites_desc1": "Ο ιστότοπος του Menuonline ενδέχεται να περιέχει συνδέσμους προς άλλους ιστότοπους. Λάβετε υπόψη ότι το Menuonline δεν είναι υπεύθυνο για τις πρακτικές απορρήτου άλλων τοποθεσιών. Ενθαρρύνουμε τους Κατόχους Λογαριασμού μας να γνωρίζουν ότι όταν εγκαταλείπουν τον ιστότοπό μας, θα πρέπει να διαβάσουν διεξοδικά τις δηλώσεις ιδιωτικής πολιτικής κάθε ιστότοπου που συλλέγει στοιχεία προσωπικής ταυτοποίησης.",
    "privacy_policy_links_other_sites_desc2": "Αυτή η ιδιωτική πολιτική ισχύει αποκλειστικά για πληροφορίες που συλλέγουμε από εμάς.",
    "privacy_policy_links_other_sites_desc3": "Εάν χρησιμοποιείτε έναν σύνδεσμο για να μεταβείτε από τον ιστότοπο Menuonline για να αγοράσετε προϊόντα ή υπηρεσίες, ολοκληρώνετε μια συναλλαγή αγοράς σύμφωνα με τους κανόνες και τους κανονισμούς της εταιρείας προμηθευτή και όχι του Menuonline.",
    "privacy_policy_cookies_title": "COOKI ΤΗΣ ΙΔΙΩΤΙΚΗΣ ΠΟΛΙΤΙΚΗΣ",
    "privacy_policy_cookies_desc1": "Ο ιστότοπος και οι υπηρεσίες Menuonline χρησιμοποιούν cookies για τη συλλογή και αποθήκευση ορισμένων πληροφοριών σύμφωνα με αυτήν την πολιτική. Ένα «cookie» είναι ένα μικρό αρχείο κειμένου που αποστέλλεται από έναν ιστότοπο και αποθηκεύεται στον υπολογιστή του χρήστη από το πρόγραμμα περιήγησης ιστού του χρήστη. Κατά την επίσκεψη σε έναν ιστότοπο που χρησιμοποιεί cookies, γίνεται λήψη ενός cookie στον υπολογιστή ή την κινητή συσκευή σας. Την επόμενη φορά που θα επισκεφτείτε αυτόν τον ιστότοπο, η συσκευή σας θα θυμάται χρήσιμες πληροφορίες όπως προτιμήσεις, σελίδες που έχετε επισκεφτεί ή επιλογές σύνδεσης.",
    "privacy_policy_cookies_desc2": "Τα cookies χρησιμοποιούνται ευρέως για την προσθήκη λειτουργικότητας σε ιστότοπους ή για να διασφαλιστεί ότι λειτουργούν πιο αποτελεσματικά. Ο ιστότοπός μας βασίζεται σε cookies για να βελτιστοποιήσει την εμπειρία του χρήστη και να διασφαλίσει ότι οι υπηρεσίες του ιστότοπου λειτουργούν σωστά.",
    "privacy_policy_cookies_desc3": "Τα περισσότερα προγράμματα περιήγησης ιστού επιτρέπουν κάποιο έλεγχο για τον περιορισμό ή τον αποκλεισμό των cookies μέσω των ρυθμίσεων του προγράμματος περιήγησης, ωστόσο, εάν απενεργοποιήσετε τα cookies, ενδέχεται να διαπιστώσετε ότι αυτό επηρεάζει την ικανότητά σας να χρησιμοποιείτε ορισμένα μέρη του ιστότοπου ή των υπηρεσιών μας.",
    "privacy_policy_cookies_analytical_purpose": "<strong> Αναλυτικοί σκοποί: <\/strong> Χρησιμοποιούμε cookies για να αναλύσουμε τη δραστηριότητα του κατόχου λογαριασμού προκειμένου να βελτιώσουμε τον ιστότοπό μας. Για παράδειγμα, μπορούμε να χρησιμοποιήσουμε cookies για να εξετάσουμε συγκεντρωτικά μοτίβα όπως τα χαρακτηριστικά που χρησιμοποιούν οι Κάτοχοι Λογαριασμού. Μπορούμε να χρησιμοποιήσουμε μια τέτοια ανάλυση για να αποκτήσουμε πληροφορίες σχετικά με το πώς να βελτιώσουμε τη λειτουργικότητα και την εμπειρία χρήστη του ιστότοπού μας.",
    "privacy_policy_cookies_preferences_user_experience": "<strong> Οι προτιμήσεις σας και η εμπειρία χρήστη: <\/strong> Χρησιμοποιούμε cookies για να συλλέξουμε ορισμένες πληροφορίες σχετικά με τους επισκέπτες και τους κατόχους λογαριασμού, όπως τύπο προγράμματος περιήγησης, διακομιστή, προτίμηση γλώσσας και ρύθμιση χώρας, προκειμένου να αποθηκεύουμε τις προτιμήσεις κατόχου λογαριασμού στον ιστότοπό μας για να κάνουμε την εμπειρία του κατόχου λογαριασμού πιο συνεπή και βολική. Χρησιμοποιούμε cookies για να διατηρήσουμε την κατάστασή σας όταν είστε συνδεδεμένοι όταν επισκέπτεστε επανειλημμένα τον ιστότοπό μας.",
    "privacy_policy_cookies_targeted_ads": "<strong> Παρέχουμε υπηρεσίες μέτρησης και καλύτερες στοχευμένες διαφημίσεις και μάρκετινγκ: <\/strong> Χρησιμοποιούμε cookie, web beacons και άλλες τεχνολογίες αποθήκευσης από τρίτους συνεργάτες όπως η Google και το Facebook για υπηρεσίες μέτρησης, καλύτερη στόχευση διαφημίσεων και για σκοπούς μάρκετινγκ. Αυτό πραγματοποιείται όταν επισκέπτεστε τον ιστότοπό μας. Αυτά τα cookie, τα web beacons και άλλες τεχνολογίες αποθήκευσης μας επιτρέπουν να σας προβάλλουμε διαφημιστικό υλικό Menuonline σε άλλους ιστότοπους που επισκέπτεστε στο Διαδίκτυο.",
    "privacy_policy_security_title": "ΑΣΦΑΛΕΙΑ",
    "privacy_policy_security_desc1": "Το Menuonline καταβάλλει προσπάθειες για την προστασία των εμπιστευτικών πληροφοριών παντός τύπου, συμπεριλαμβανομένων των προσωπικών πληροφοριών, με οργανωτικές, φυσικές, μηχανικές και ηλεκτρονικές διασφαλίσεις κατάλληλες για την ευαισθησία των πληροφοριών. Οι διακομιστές μας βρίσκονται στον Καναδά και ακολουθούν τα βιομηχανικά πρότυπα που σχετίζονται με την ασφάλεια.",
    "privacy_policy_security_desc2": "Λάβετε υπόψη, ωστόσο, ότι δεν μπορούμε να εγγυηθούμε ότι τα μέτρα που διατηρούμε θα εγγυώνται την ασφάλεια των πληροφοριών.",
    "privacy_policy_retention_title": "ΚΡΑΤΗΣΗ",
    "privacy_policy_retention_desc": "Διατηρούμε προσωπικές πληροφορίες σχετικά με τους κατόχους λογαριασμών και τις επαφές για όσο χρονικό διάστημα απαιτείται για τους σκοπούς που ορίζονται στην παρούσα Ιδιωτική Πολιτική και σύμφωνα με τις διατάξεις διατήρησης στους Όρους Χρήσης.",
    "privacy_policy_access_questions_title": "ΠΡΟΣΒΑΣΗ, ΕΡΩΤΗΣΕΙΣ ΚΑΙ ΑΝΗΣΥΧΙΕΣ",
    "privacy_policy_access_questions_desc": "Ένα άτομο που έχει υποβάλει προσωπικά στοιχεία στο Menuonline μπορεί να υποβάλει γραπτό αίτημα πρόσβασης ή διόρθωσης των προσωπικών του στοιχείων που διατηρεί το Menuonline.",
    "terms_obj": {
        "terms_conditions_title": "ΟΡΟΙ ΚΑΙ ΠΡΟΫΠΟΘΕΣΕΙΣ",
        "terms_conditions_subtitle": "Όροι & Προϋποθέσεις για νέους και υφιστάμενους πελάτες",
        "terms_conditions_desc1": "ΤΑ ΑΚΟΛΟΥΘΑ ΒΑΣΙΚΑ ΣΗΜΕΙΑ (ΣΥΜΠΕΡΙΛΑΜΒΑΝΟΜΕΝΩΝ ΑΝΑΦΟΡΕΣ ΤΗΣ ΕΝΟΤΗΤΑΣ) ΠΑΡΕΧΟΝΤΑΙ ΜΟΝΟ ΓΙΑ ΤΗΝ ΕΥΚΟΛΥΝΗ ΣΑΣ ΚΑΙ ΔΕΝ ΑΝΤΙΚΑΤΑΣΤΑΙ ΤΟΥΣ ΠΛΗΡΟΥΣ ΟΡΟΥΣ ΚΑΙ ΠΡΟΫΠΟΘΕΣΕΙΣ. ΕΙΝΑΙ ΔΙΚΗ ΣΑΣ ΕΥΘΥΝΗ ΝΑ ΔΙΑΒΑΣΕΤΕ ΑΥΤΟΥΣ ΤΟΥΣ ΟΡΟΥΣ ΚΑΙ ΠΡΟΫΠΟΘΕΣΕΙΣ ΠΡΙΝ ΧΡΗΣΙΜΟΠΟΙΗΣΕΤΕ ΤΗΝ ΥΠΗΡΕΣΙΑ Online Menuon.",
        "terms_conditions_desc2": "Η χρήση του Menuonline σημαίνει ότι συμφωνείτε με τους Όρους και Προϋποθέσεις και δηλώνετε και εγγυάστε ότι είστε εξουσιοδοτημένος από τον οργανισμό ή την εταιρεία σας να το πράξετε. (Βλ. 3 «Πώς λειτουργεί»)",
        "terms_conditions_desc3": "Το Menuonline είναι μια υπηρεσία μάρκετινγκ που βασίζεται σε άδεια. Η σκόπιμη αποστολή «spam» ή η παραβίαση των Όρων και Προϋποθέσεων θα έχει ως αποτέλεσμα το κλείσιμο του λογαριασμού σας. (Βλ. s.16 «Αποδεκτή χρήση της υπηρεσίας»)",
        "terms_conditions_desc4": "Τα δεδομένα που φιλοξενείτε στο Menuonline, όπως αρχεία επαφών, περιεχόμενο email και πληροφορίες που συλλέγετε μέσω του Menuonline, ανήκουν σε εσάς. (Βλέπε εδάφιο 14 «Τι κατέχεις;»).",
        "terms_conditions_desc5": "Το λογισμικό Menuonline και το περιεχόμενο της ιστοσελίδας μας ανήκουν σε εμάς. (Βλέπε εδάφιο 13 «Τι κατέχουμε;»)",
        "terms_conditions_desc6": "Εκτός από τους δικούς σας χρήστες, τα δεδομένα ή το μη δημοσιευμένο περιεχόμενο που φιλοξενείτε στο Menuonline μπορούν να προβληθούν μόνο από υπαλλήλους και συνεργάτες του Menuonline που έχουν υπογράψει συμφωνίες εμπιστευτικότητας. (Βλέπε εδάφιο 14 «Τι κατέχεις;»).",
        "terms_conditions_desc7": "Όλες οι πληροφορίες προσωπικής ταυτοποίησης (PII) διατηρούνται μόνο σε διακομιστές που βρίσκονται στον Καναδά. (Βλέπε εδάφιο 14 «Τι κατέχεις;»).",
        "terms_conditions_desc8": "Η υπηρεσία Menuonline υπόκειται στους νόμους του Καναδά και της επαρχίας του Οντάριο. (Βλ. εδάφιο 24 «Εφαρμόσιμος Νόμος»)",
        "terms_conditions_desc9": "Οποιαδήποτε αιτήματα για τα δεδομένα ή το περιεχόμενό σας από άτομα, αστυνομία ή άλλους ρυθμιστικούς φορείς εντός του Καναδά ή εκτός Καναδά θα παραπέμπονται πάντα σε εσάς. Η μόνη εξαίρεση θα ήταν εάν αυτό μας θέτει σε παραβίαση της καναδικής νομοθεσίας ή της νομοθεσίας του Οντάριο. Ωστόσο, σε όλες τις περιπτώσεις θα αντισταθούμε σθεναρά σε οποιαδήποτε αποδέσμευση των δεδομένων σας από οποιονδήποτε εκτός από εσάς. (Βλέπε εδάφιο 14 «Τι κατέχεις;»).",
        "terms_conditions_desc10": "Η ασφάλεια του μενού στο διαδίκτυο είναι τόσο καλή όσο η διαχείριση των ονομάτων χρήστη και των κωδικών πρόσβασης. Δεν χρεώνουμε για λογαριασμούς χρηστών Menuonline, επομένως δημιουργήστε έναν ξεχωριστό για κάθε χρήστη και κρατήστε τους κωδικούς πρόσβασης μυστικούς. Είστε υπεύθυνοι για τον λογαριασμό σας. (Βλ. 3 «Πώς λειτουργεί»)",
        "terms_conditions_desc11": "Μπορείτε να ακυρώσετε τον λογαριασμό σας ανά πάσα στιγμή. Δεν παρέχουμε επιστροφές χρημάτων για τυχόν αχρησιμοποίητη πίστωση εάν ακυρώσετε. (Βλ. 3 «Πώς λειτουργεί»)",
        "terms_conditions_desc12": "Μπορούμε να ακυρώσουμε τον λογαριασμό σας ανά πάσα στιγμή. Παρέχουμε επιστροφές χρημάτων για πιστώσεις email και αχρησιμοποίητες υπηρεσίες εάν ακυρώσουμε τον λογαριασμό σας. (Βλ. 3 «Πώς λειτουργεί»)",
        "terms_conditions_desc13": "Τα δεδομένα σας θα διαγραφούν μετά την ακύρωση και ενδέχεται επίσης να διαγραφούν εάν ένας χρήστης λογαριασμού δεν έχει συνδεθεί για 12 μήνες ή περισσότερους. Θα διατηρήσουμε συγκεντρωτικά δεδομένα από τον λογαριασμό σας που δεν περιλαμβάνουν στοιχεία προσωπικής ταυτοποίησης για στατιστικούς σκοπούς. (Βλ. 3 «Πώς λειτουργεί»)",
        "terms_conditions_desc14": "Οι πιστώσεις ηλεκτρονικού ταχυδρομείου αγοράζονται βάσει πληρωμής και δεν λήγουν ποτέ. Η μόνη εξαίρεση είναι εάν δεν έχετε συνδεθεί στον λογαριασμό σας για 12 μήνες. Σε αυτήν την περίπτωση, διατηρούμε το δικαίωμα να διαγράψουμε τον λογαριασμό σας, τα δεδομένα σας και τις σχετικές πιστώσεις. (Βλ. 10 «Τι πληρώνω για την Υπηρεσία;»)",
        "terms_conditions_desc15": "Εργαζόμαστε σκληρά για να διασφαλίσουμε ότι το Menuonline είναι σε λειτουργία ανά πάσα στιγμή και για να παρέχουμε εκ των προτέρων ειδοποίηση εάν χρειαστεί να αναστείλουμε προσωρινά την υπηρεσία για συντήρηση. Ωστόσο, δεν εγγυόμαστε ότι η υπηρεσία θα λειτουργεί και θα λειτουργεί ανά πάσα στιγμή και δεν φέρουμε ευθύνη για τυχόν απώλειες που θα υποστείτε από τη χρήση του Menuonline, συμπεριλαμβανομένων των απωλειών εκ μέρους σας εάν το σύστημα δεν λειτουργεί τη στιγμή που το χρειάζεστε. (Βλ. s.17 «Αποποιήσεις ευθυνών»).",
        "terms_conditions_desc16": "Δεν παρακολουθούμε το περιεχόμενό σας ή σε ποιους στέλνετε μηνύματα. Ωστόσο, παρακολουθούμε τα παράπονα και άλλα προβλήματα που δημιουργούνται από τις δραστηριότητές σας. Πάρα πολλά παράπονα ή προβλήματα μπορεί να έχουν ως αποτέλεσμα την ακύρωση της υπηρεσίας σας κατά την κρίση μας. (Βλ. s.7 «Παρακολούθηση»)",
        "terms_conditions_desc17": "Δεσμευόμαστε να παρέχουμε εξαιρετική υποστήριξη προϊόντων για να σας βοηθήσουμε να γίνετε αυτάρκεις με το Menuonline. Παρέχουμε υποστήριξη με τη μορφή τεκμηρίωσης και βίντεο στον ιστότοπό μας, στο σύστημα εισιτηρίων υποστήριξης Menuonline και μέσω τηλεφώνου. Δεν υπάρχει χρέωση για την υποστήριξη προϊόντων. Ωστόσο, διατηρούμε το δικαίωμα να διακόψουμε την υποστήριξη για συγκεκριμένους λογαριασμούς πελατών κατά την κρίση μας. (Βλέπε s.5 «Υποστήριξη»)",
        "terms_conditions_desc18": "Οι Υπηρεσίες προϊόντων είναι για εκείνες τις στιγμές που θέλετε να κάνουμε κάποια εργασία που σχετίζεται με το Menuonline αντί να την κάνουμε μόνοι σας. Η τιμή μας για τις υπηρεσίες προϊόντων είναι 135 $ ανά ώρα. Παρέχουμε επίσης συμβουλευτικές υπηρεσίες σε υψηλότερες τιμές. Διατηρούμε το δικαίωμα να αλλάξουμε τις τιμές μας ανά πάσα στιγμή. (Βλ. s.6 «Προϊόντα και συμβουλευτικές υπηρεσίες»)",
        "terms_conditions_desc19": "Θα σας ενημερώσουμε εάν πιστεύουμε ότι υπήρξε παραβίαση των δεδομένων σας και θα καταβάλουμε προσπάθεια να παρέχουμε σχετικές λεπτομέρειες και πληροφορίες. (Βλέπε εδάφιο 14 «Τι κατέχεις;»)",
        "terms_conditions_desc20": "Διατηρούμε το δικαίωμα να κάνουμε αλλαγές στην παρούσα συμφωνία ανά πάσα στιγμή. Θα προσπαθήσουμε να σας ενημερώσουμε για αλλαγές στους όρους και τις προϋποθέσεις, αλλά η χρήση της υπηρεσίας σημαίνει ότι συμφωνείτε με αλλαγές στους Όρους. (Βλ. s.9 «Αλλαγές»).",
        "terms_conditions_desc21": "Οι Όροι και Προϋποθέσεις και η Πολιτική Απορρήτου μας έχουν συνταχθεί μόνο στα Αγγλικά και συμφωνείτε ότι η αγγλική έκδοση των Όρων και Προϋποθέσεων και η Πολιτική Απορρήτου θα έχουν προτεραιότητα σε περίπτωση σύγκρουσης με οποιαδήποτε μεταφρασμένη έκδοση.",
        "terms_conditions_mycircle": "ΟΡΟΙ ΚΑΙ ΠΡΟΫΠΟΘΕΣΕΙΣ MYCIRCLE",
        "terms_conditions_last_update": "ΤΕΛΕΥΤΑΙΑ ΕΝΗΜΕΡΩΣΗ: 14 Ιανουαρίου 2020",
        "terms_conditions_usage_agreement": "Παρακαλούμε διαβάστε αυτούς τους όρους και τις προϋποθέσεις παροχής υπηρεσιών (τους «Όρους») προσεκτικά πριν χρησιμοποιήσετε αυτόν τον ιστότοπο (η «Ιστοσελίδα»). Αυτοί οι Όροι απαλλάσσουν το Menuonline (\"Menuonline\", \"εμείς\" ή \"εμάς\") και άλλους από την ευθύνη ή\/και περιορίζουν την ευθύνη μας και τους ίδιους και περιέχουν άλλες σημαντικές διατάξεις που ισχύουν για τη χρήση αυτού του ιστότοπου από εσάς.",
        "terms_conditions_acceptance": "Επισκεπτόμενοι ή χρησιμοποιώντας αυτόν τον Ιστότοπο ή την Υπηρεσία, συμφωνείτε εκ μέρους σας και εκ μέρους οποιουδήποτε οργανισμού για λογαριασμό του οποίου μπορείτε να ενεργείτε (συλλογικά αναφερόμενοι στο παρόν ως «εσείς»), να αποδέχεστε και να συμμορφώνεστε με αυτούς τους Όρους για κάθε χρήση και κάθε επίσκεψη σε αυτόν τον Ιστότοπο.",
        "terms_conditions_services": "1. Οι υπηρεσίες μας.",
        "terms_conditions_services_desc": "Οι υπηρεσίες που προσφέρουμε μέσω του ιστότοπου είναι μια υπηρεσία που σας επιτρέπει να δημιουργείτε, να στέλνετε και να διαχειρίζεστε μηνύματα ηλεκτρονικού ταχυδρομείου («Emails») σε παραλήπτες της επιλογής σας (η «Υπηρεσία»).",
        "terms_conditions_license": "2. Άδεια.",
        "terms_conditions_license_desc": "Σύμφωνα με αυτούς τους Όρους, το Menuonline σας χορηγεί με το παρόν μια μη αποκλειστική, περιορισμένη, μη μεταβιβάσιμη άδεια κατά τη διάρκεια της Διάρκειας για να προβάλετε τον Ιστότοπο και να χρησιμοποιήσετε την Υπηρεσία όπως επιτρέπεται από τα χαρακτηριστικά της Υπηρεσίας. Διατηρούμε όλα τα δικαιώματα που δεν παραχωρούνται ρητά στο παρόν στην Υπηρεσία και στο Περιεχόμενο Online Menu (όπως ορίζονται παρακάτω).",
        "terms_conditions_account": "3. Πώς λειτουργεί.",
        "terms_conditions_account_desc": "Για να χρησιμοποιήσετε την Υπηρεσία πρέπει να εγγραφείτε για λογαριασμό. Ο λογαριασμός σας είναι δωρεάν. Ο λογαριασμός σας σάς δίνει πρόσβαση στην Υπηρεσία και στις \"λειτουργίες\" που ενδέχεται να δημιουργήσουμε και να διατηρούμε κατά καιρούς. Για να πληροίτε τα κριτήρια για λογαριασμό πρέπει:",
        "terms_conditions_account_requirements": [
            "να είναι τουλάχιστον δεκαοκτώ (18) ετών και να μπορεί να συνάπτει συμβάσεις·",
            "ολοκληρώστε τη διαδικασία εγγραφής που έχουμε ορίσει·",
            "συμφωνείτε με αυτούς τους Όρους και Προϋποθέσεις·",
            "παρέχετε αληθή, πλήρη και ενημερωμένα στοιχεία επικοινωνίας και χρέωσης\/πληρωμής."
        ],
        "responsibility": "Ευθύνη.",
        "responsibilityText": "Είστε αποκλειστικά υπεύθυνοι για τη δραστηριότητα που λαμβάνει χώρα στον λογαριασμό σας και πρέπει να διατηρήσετε τον κωδικό πρόσβασης του λογαριασμού σας εμπιστευτικό και ασφαλή. Οι λογαριασμοί είναι δωρεάν, επομένως σας ενθαρρύνουμε να δημιουργήσετε διαφορετικό λογαριασμό για κάθε χρήστη που έχετε. Είστε επίσης υπεύθυνοι για οποιονδήποτε λογαριασμό στον οποίο έχετε πρόσβαση, είτε έχετε εξουσιοδοτήσει είτε όχι τη χρήση αυτού του λογαριασμού. Συμφωνείτε ότι είστε αποκλειστικά υπεύθυνοι για τις αλληλεπιδράσεις σας με αυτούς στους οποίους στέλνετε μηνύματα ηλεκτρονικού ταχυδρομείου και θα στέλνετε μηνύματα ηλεκτρονικού ταχυδρομείου μόνο σε εκείνα τα άτομα από τα οποία έχετε τη συγκατάθεσή σας. Πρέπει να μας ειδοποιήσετε αμέσως για οποιαδήποτε μη εξουσιοδοτημένη χρήση των λογαριασμών σας.",
        "compliance_with_CASL": "Συμμόρφωση με την καναδική νομοθεσία κατά των ανεπιθύμητων μηνυμάτων (CASL).",
        "compliance_with_CASL_text": "Ο ρόλος του μενού στο Διαδίκτυο είναι να διευκολύνει τη διαχείριση των ρυθμίσεων συναίνεσης και όχι να επιβάλλει τη νομοθεσία. Είναι δική σας ευθύνη να συμμορφώνεστε με το CASL. Σας βοηθάμε ορίζοντας προεπιλεγμένη λειτουργικότητα και διευκολύνοντας τη λήψη και ενημέρωση της τεκμηριωμένης ρητής συναίνεσης και τη διαχείριση κυλιόμενων ημερομηνιών λήξης για σιωπηρή συγκατάθεση. Έχετε τον πλήρη έλεγχο της διαχείρισης της συναίνεσης για τη βάση δεδομένων σας. μπορείτε να ενημερώσετε μαζικά την κατάσταση συναίνεσης για όλες τις επαφές σας ανά πάσα στιγμή, να παρακάμψετε τις προεπιλεγμένες ρυθμίσεις και να απενεργοποιήσετε την αυτόματη λήξη της κατάστασης σιωπηρής συναίνεσης.",
        "closing_account": "Κλείσιμο του Λογαριασμού σας.",
        "closing_account_text": "Είτε εσείς είτε το Menuonline μπορείτε να καταγγείλετε την παρούσα Συμφωνία ανά πάσα στιγμή και για οποιονδήποτε λόγο δίνοντας Ειδοποίηση στο άλλο μέρος. Εάν παρέχετε οποιαδήποτε πληροφορία που είναι αναληθής, ανακριβής, μη τρέχουσα ή ελλιπής ή χρησιμοποιείτε την Υπηρεσία με τρόπο που παραβιάζει τους παρόντες Όρους και Προϋποθέσεις, έχουμε το δικαίωμα να αναστείλουμε αμέσως τον λογαριασμό σας και να τερματίσουμε την πρόσβασή σας στην Υπηρεσία επιπλέον οποιωνδήποτε άλλων ένδικων μέσων που έχουμε στη διάθεσή μας. Εάν δεν χρησιμοποιήσετε τον λογαριασμό σας για δώδεκα (12) μήνες, ενδέχεται να ακυρώσουμε τον λογαριασμό σας. Εάν τερματίσουμε τον λογαριασμό σας για οποιονδήποτε λόγο, θα σας επιστρέψουμε τα χρήματα για τυχόν πιστώσεις που έχετε απομείνει. Μετά τον τερματισμό, θα διαγράψουμε οριστικά τον λογαριασμό σας και όλα τα δεδομένα που σχετίζονται με αυτόν, συμπεριλαμβανομένων των email που έχετε στείλει. Ενδέχεται να διατηρήσουμε συγκεντρωτικά δεδομένα από τον λογαριασμό σας που δεν περιλαμβάνουν στοιχεία προσωπικής ταυτοποίησης για στατιστικούς σκοπούς.",
        "emailing_content": "Περιεχόμενο αποστολής email.",
        "emailing_content_text": "Όταν αλληλεπιδράτε με το Menuonline μέσω αυτού του ιστότοπου ή της Υπηρεσίας, συμφωνείτε να παρέχετε αληθείς και ακριβείς πληροφορίες. Επιπλέον, κατανοείτε ότι η παροχή εμπιστευτικών πληροφοριών μέσω email είναι μια μη ασφαλής πρακτική. Κατανοείτε ότι είστε αποκλειστικά υπεύθυνοι για οτιδήποτε στέλνετε σε οποιονδήποτε χρησιμοποιεί την Υπηρεσία μας. σκοπούς.",
        "support": "Υποστήριξη.",
        "support_text": "Σας παρέχουμε υποστήριξη για να σας βοηθήσουμε να γίνετε αυτάρκεις με την Υπηρεσία. Σας παρέχουμε υποστήριξη με τη μορφή τεκμηρίωσης και βίντεο στον Ιστότοπο, στο σύστημα εισιτηρίων υποστήριξης Menuonline και μέσω τηλεφώνου. Μπορείτε να επικοινωνήσετε μαζί μας κάνοντας κλικ στην καρτέλα \"Υποστήριξη\" στο επάνω μέρος του ιστότοπου. Δεν υπάρχει χρέωση για την υποστήριξη προϊόντων. Ωστόσο, διατηρούμε το δικαίωμα να διακόψουμε την υποστήριξη για συγκεκριμένους λογαριασμούς πελατών κατά την κρίση μας.",
        "product_and_consulting_services": "Προϊόντα και Συμβουλευτικές Υπηρεσίες.",
        "product_and_consulting_services_text": "Οι Υπηρεσίες προϊόντων είναι για εκείνες τις στιγμές που θέλετε να κάνουμε κάποια εργασία που σχετίζεται με το Menuonline αντί να την κάνουμε μόνοι σας. Η τιμή μας για τις υπηρεσίες προϊόντων είναι 125 $ ανά ώρα. Παρέχουμε επίσης συμβουλευτικές υπηρεσίες σε υψηλότερες τιμές. Διατηρούμε το δικαίωμα να αλλάξουμε τις τιμές μας ανά πάσα στιγμή.",
        "monitoring": "Παρακολούθηση.",
        "monitoring_text": "Δεν θα παρακολουθούμε ούτε θα κρίνουμε το περιεχόμενο των πληροφοριών που μεταδίδονται μέσω της Υπηρεσίας, αλλά θα διερευνήσουμε εάν λάβουμε παράπονα για πιθανή ακατάλληλη χρήση. Η Menuonline μπορεί, ανά πάσα στιγμή και κατά την απόλυτη διακριτική της ευχέρεια, να αποφασίσει ότι συγκεκριμένες χρήσεις είναι ή δεν είναι κατάλληλες με ή χωρίς ειδοποίηση προς εσάς, σύμφωνα με τις ακόλουθες οδηγίες.",
        "term": "Ορος.",
        "term_text": "Ο Όρος αρχίζει όταν εγγραφείτε για έναν λογαριασμό μαζί μας και συνεχίζεται για όσο διάστημα χρησιμοποιείτε την Υπηρεσία. Δηλώνετε και εγγυάστε ότι έχετε την εξουσία και τη δυνατότητα να εγγραφείτε για λογαριασμό για εμάς (συμπεριλαμβανομένης της εγγραφής σας για λογαριασμό ενός οργανισμού).",
        "changes": "Αλλαγές.",
        "changes_text": "Ο Όρος ξεκινά όταν εγγραφείτε για έναν λογαριασμό μαζί μας και συνεχίζεται για όσο διάστημα χρησιμοποιείτε την Υπηρεσία. Δηλώνετε και εγγυάστε ότι έχετε την εξουσία και τη δυνατότητα να εγγραφείτε για λογαριασμό για εμάς (συμπεριλαμβανομένης της εγγραφής σας για λογαριασμό ενός οργανισμού).",
        "payment": "ΠΛΗΡΩΜΗ",
        "what_do_i_pay_for_service": "Τι πληρώνω για την Υπηρεσία;.",
        "what_do_i_pay_for_service_text": "Μπορείτε να αγοράσετε προπληρωμένες πιστώσεις για να στείλετε email (\"Πιστώσεις\") μέσω της Υπηρεσίας μας, όπως περιγράφουμε λεπτομερώς στη σελίδα \"τιμολόγηση\" του ιστότοπού μας. Οι πιστώσεις δεν λήγουν ποτέ και δεν υπάρχουν επαναλαμβανόμενες χρεώσεις για αυτές τις πιστώσεις. Η μόνη εξαίρεση είναι ότι εάν δεν χρησιμοποιήσετε τον λογαριασμό σας για δώδεκα (12) μήνες, ενδέχεται να ακυρώσουμε τον λογαριασμό σας και τυχόν πιστώσεις που δεν έχουν χρησιμοποιηθεί. Μπορούμε να αλλάξουμε την τιμολόγησή μας ανά πάσα στιγμή ενημερώνοντας τις σελίδες τιμολόγησης του ιστότοπού μας, κάτι που θα ισχύει για την επόμενη αγορά Πιστώσεων.",
        "how_do_i_pay_for_service": "Πώς πληρώνω για την Υπηρεσία;.",
        "how_do_i_pay_for_service_text": "Μπορείτε να πληρώσετε για Πιστώσεις χρησιμοποιώντας οποιαδήποτε κύρια πιστωτική κάρτα. Μπορούμε να αλλάξουμε τους τρόπους πληρωμής που δεχόμαστε ανά πάσα στιγμή.",
        "can_i_get_refund": "Μπορώ να λάβω επιστροφή χρημάτων;.",
        "can_i_get_refund_text": "Τα ποσά που έχετε πληρώσει για τη χρήση της Υπηρεσίας δεν επιστρέφονται εάν ακυρώσετε τον λογαριασμό σας. Εάν ακυρώσουμε τον λογαριασμό σας, θα σας επιστρέψουμε τα χρήματα για τυχόν πιστώσεις που έχετε απομείνει.",
        "rights": "ΔΙΚΑΙΩΜΑΤΑ",
        "what_do_we_own": "Τι κατέχουμε;.",
        "what_do_we_own_text": "Εκτός από το περιεχόμενο που μας παρέχετε (συμπεριλαμβανομένων, χωρίς περιορισμό, αρχείων επικοινωνίας, περιεχομένου email και πληροφοριών που συλλέγετε μέσω της Υπηρεσίας) (\"Το Περιεχόμενό σας\"), όλα τα υλικά που περιλαμβάνονται στον Ιστότοπό μας, την Υπηρεσία και όλα τα υλικά σε αυτήν ή μεταφέρονται από αυτήν, συμπεριλαμβανομένων χωρίς περιορισμό, λογισμικό, εικόνες, κείμενο, γραφικά, εικονογραφήσεις, λογότυπα, πατέντες, μουσική, εμπορικά σήματα, βίντεο, εμπορικά σήματα Οι χρήστες (το «Περιεχόμενο Online Menuonline») (και όλα τα δικαιώματα πνευματικής ιδιοκτησίας που σχετίζονται με αυτό) ανήκουν σε εμάς ή στους δικαιοπαρόχους μας.",
        "what_do_you_own": "Τι κατέχεις;.",
        "what_do_you_own_text": "Δεν διεκδικούμε δικαιώματα ιδιοκτησίας στο Περιεχόμενό σας. Το Περιεχόμενό σας είναι δικό σας και διατηρείτε την κυριότητα του Περιεχομένου σας που ανεβάζετε στην Υπηρεσία. Με την κοινή χρήση του Περιεχομένου σας μέσω της Υπηρεσίας, συμφωνείτε να επιτρέπετε σε άλλους να προβάλλουν, να επεξεργάζονται και\/ή να μοιράζονται το Περιεχόμενό σας σύμφωνα με τις ρυθμίσεις σας και τους παρόντες Όρους και Προϋποθέσεις. Δηλώνετε και εγγυάστε ότι είτε είστε κάτοχος είτε έχετε άδεια να χρησιμοποιήσετε όλο το υλικό που χρησιμοποιείτε στα email σας. Μπορούμε να χρησιμοποιήσουμε ή να αποκαλύψουμε το υλικό σας μόνο όπως περιγράφουμε στους παρόντες Όρους και στην Πολιτική Απορρήτου μας.",
        "privacy_statement": "Το Menuonline σέβεται το απόρρητο των χρηστών του. Οποιεσδήποτε προσωπικές πληροφορίες που συλλέγει το Menuonline μέσω αυτής της Ιστοσελίδας ή της Υπηρεσίας υπόκεινται στη Δήλωση Προστασίας Προσωπικών Δεδομένων Menuonline που ενσωματώνεται με αναφορά στους παρόντες Όρους. Μπορούμε να χρησιμοποιήσουμε και να αποκαλύψουμε τις πληροφορίες σας σύμφωνα με τη Δήλωση Απορρήτου μας.",
        "access_to_your_content": "Το Menuonline δεν θα παρέχει πρόσβαση στο Περιεχόμενό σας σε κανέναν, εκτός από εκείνους τους υπαλλήλους και τους εργολάβους του Menuonline που έχουν συνάψει κατάλληλες συμφωνίες εμπιστευτικότητας μαζί μας ή έχουμε τη συγκατάθεσή σας.",
        "governmentRequest": "Εάν λάβουμε αίτημα από μια κυβέρνηση ή ρυθμιστική αρχή για πρόσβαση στο Περιεχόμενό σας, θα σας ειδοποιήσουμε για το αίτημα και θα ζητήσουμε τη συγκατάθεσή σας προτού αποδεσμεύσουμε οποιοδήποτε από το Περιεχόμενό σας. Θα αντισταθούμε σε τυχόν αιτήματα για το Περιεχόμενό σας και δεν θα αποδεσμεύσουμε το Περιεχόμενό σας ως απάντηση σε αυτό το είδος αιτήματος χωρίς την άδειά σας ή χωρίς να απαιτείται από το αρμόδιο δικαστήριο ή τον αρμόδιο ρυθμιστικό φορέα.",
        "breachNotification": "Σε περίπτωση που αντιληφθούμε ότι το Περιεχόμενό σας έχει υποστεί μη εξουσιοδοτημένη πρόσβαση, χρήση, αντιγραφή, κλοπή, απώλεια ή χρήση ή αποκάλυψη κατά παράβαση των παρόντων Όρων («Παράβαση»), θα σας ειδοποιήσουμε αμέσως και θα σας ενημερώσουμε για το τι συνέβη. Συμφωνείτε ότι οι Παραβιάσεις θα αντιμετωπίζονται σύμφωνα με την ισχύουσα νομοθεσία και εάν χρειαζόμαστε να ενημερώσετε τα μέλη για τις λίστες διανομής email σας, τότε θα συμφωνήσετε να το πράξετε αμέσως και σύμφωνα με τις οδηγίες μας.",
        "contentRetention": "Θα διατηρήσουμε, θα χρησιμοποιήσουμε και θα μεταδώσουμε το Περιεχόμενό σας στον Καναδά εκτός από την προηγούμενη γραπτή έγκριση ή καθοδήγησή σας (για παράδειγμα, εάν επιλέξετε να στείλετε το Περιεχόμενό σας σε άτομα εκτός Καναδά). Οι διακομιστές που χρησιμοποιούνται για την παροχή της Υπηρεσίας βρίσκονται στον Καναδά.",
        "accessTrouble": "Εάν αντιμετωπίζετε προβλήματα με την πρόσβαση στο Περιεχόμενό σας, το Menuonline θα σας βοηθήσει να ανακτήσετε το Περιεχόμενό σας σε βάση χρόνου και υλικού.",
        "reportingAbuse": "Εάν πιστεύετε ότι κάποιος παραβιάζει κάποιον από αυτούς τους Όρους, ενημερώστε μας αμέσως. Εάν λάβατε ανεπιθύμητα μηνύματα που πιστεύετε ότι προήλθαν από άλλο χρήστη της Υπηρεσίας, εάν πιστεύετε ότι κάποιος άλλος χρήστης της Υπηρεσίας έχει δημοσιεύσει υλικό που παραβιάζει τυχόν πνευματικά δικαιώματα ή εάν πιστεύετε ότι κάποιος άλλος χρήστης της Υπηρεσίας παραβιάζει με άλλον τρόπο αυτούς τους Όρους, επικοινωνήστε μαζί μας στη διεύθυνση Support@menuonline.com.",
        "acceptableUse": "Δηλώνετε και εγγυάστε ότι η χρήση της Υπηρεσίας από εσάς θα συμμορφώνεται με όλους τους ισχύοντες νόμους και κανονισμούς. Συμφωνείτε ότι είστε υπεύθυνοι για τον καθορισμό του εάν η Υπηρεσία θα συμμορφώνεται και θα είναι κατάλληλη για χρήση στη δικαιοδοσία σας. Για μεγαλύτερη βεβαιότητα, συμφωνείτε ότι θα (και συμφωνείτε να διασφαλίσετε ότι οι υπάλληλοί σας, οι αντιπρόσωποι και οι εξουσιοδοτημένοι χρήστες σας θα) ...",
        "exportLaws": "Δεν δηλώνουμε ότι η Ιστοσελίδα ή η Υπηρεσία είναι κατάλληλη ή διαθέσιμη για χρήση εκτός του Καναδά και η πρόσβαση σε αυτές από περιοχές όπου το περιεχόμενό τους είναι παράνομο απαγορεύεται. Δεν μπορείτε να χρησιμοποιήσετε ή να εξάγετε ή να επανεξάγετε το Περιεχόμενο του Menuonline ή άλλο υλικό σε αυτόν τον Ιστότοπο ή οποιοδήποτε αντίγραφο ή προσαρμογή κατά παράβαση οποιουδήποτε ισχύοντος νόμου ή κανονισμού, συμπεριλαμβανομένων, ενδεικτικά, των νόμων και κανονισμών περί εξαγωγών του Καναδά και των ΗΠΑ.",
        "disclaimers": "Η Υπηρεσία και αυτός ο ιστότοπος παρέχονται «ως έχουν» χωρίς καμία αντιπροσώπευση, εγγύηση ή όρου οποιουδήποτε είδους και η Menuonline αποποιείται ρητά, στο μέγιστο βαθμό που επιτρέπεται από την ισχύουσα νομοθεσία, όλες τις δηλώσεις, εγγυήσεις και όρους, ρητές ή σιωπηρές, βάσει νόμου ή άλλως, συμπεριλαμβανομένων χωρίς περιορισμό τυχόν σιωπηρών εγγυήσεων και όρων μη καταλληλότητας ή εμπορευσιμότητας για οποιονδήποτε συγκεκριμένο σκοπό. δικαιώματα...",
        "limitLiability": "Η συνολική ευθύνη του Menuonline βάσει της παρούσας Συμφωνίας, είτε σε σύμβαση είτε σε αδικοπραξία (συμπεριλαμβανομένης της αμέλειας), ως αποτέλεσμα παραβίασης της εγγύησης, της αυστηρής ευθύνης ή βάσει οποιασδήποτε άλλης θεωρίας ευθύνης, θα περιορίζεται σε άμεσες ζημιές σε ποσό που δεν υπερβαίνει τα συνολικά τέλη που καταβάλλονται στο Menuonline σύμφωνα με αυτούς τους Όρους και Προϋποθέσεις τους δώδεκα μήνες που ισχύουν αμέσως πριν από το 1 μήνα. συνέβη.",
        "liabilityExclusions": "Κανένας από το Menuonline, τις θυγατρικές του ή τους αντίστοιχους διευθυντές, αξιωματούχους, υπαλλήλους, αντιπροσώπους ή άλλους αντιπροσώπους τους (συλλογικά «Menuonline \"Representatives\") ή οι χειριστές οποιωνδήποτε συνδεδεμένων δικτύων δεν θα έχουν καμία ευθύνη ή ευθύνη σε σχέση με αυτόν τον ιστότοπο ή την Υπηρεσία για: (i) οποιαδήποτε έμμεση, επακόλουθη, ειδική, συμπτωματική ή παραδειγματική ζημία, ή (ii) για οποιεσδήποτε ζημίες, άμεσες, έμμεσες, επακόλουθες, τυχαίες, παραδειγματικές, τιμωρητικές ή ειδικές, που χαρακτηρίζονται ως διαφυγόντα έσοδα, χαμένες αποταμιεύσεις ή έσοδα ή διαφυγόντα κέρδη...",
        "indemnity": "Θα αποζημιώσετε και θα κρατήσετε το Menuonline ή\/και τους Αντιπροσώπους Menuonline (τα «αποζημιωμένα μέρη του Menuonline») αβλαβείς από και έναντι οποιωνδήποτε αξιώσεων από τρίτα μέρη, καθώς και τυχόν πρόστιμα ή κυρώσεις που επιβλήθηκαν εναντίον μας, που προκύπτουν από τη χρήση των πληροφοριών που έχετε πρόσβαση από αυτόν τον ιστότοπο ή τη χρήση της Υπηρεσίας και οποιαδήποτε παραβίαση αυτών των Όρων...",
        "ipInfringement": "Το Menuonline θα υπερασπιστεί, θα αποζημιώσει και θα σας κρατήσει ακίνδυνους από και έναντι όλων των αξιώσεων, αξιώσεων, αγωγών ή διαδικασιών που έγιναν ή ασκήθηκαν εναντίον σας από τρίτο μέρος που ισχυρίζεται ότι η πρόσβασή σας ή η χρήση της Υπηρεσίας σύμφωνα με την παρούσα Συμφωνία παραβιάζει τα δικαιώματα πνευματικής ιδιοκτησίας σύμφωνα με την καναδική νομοθεσία τρίτων («Αξίωση παραβίασης IP»).",
        "equitableRelief": "Εάν παραβιάσετε αυτούς τους Όρους, τότε ενδέχεται να ζητήσουμε ασφαλιστικά μέτρα (που σημαίνει ότι μπορεί να ζητήσουμε δικαστική απόφαση για να σας σταματήσει) ή άλλη δίκαιη απαλλαγή. Συμφωνείτε ότι αυτό θα είναι επιπρόσθετο και με την επιφύλαξη τυχόν άλλων δικαιωμάτων που μπορεί να έχουμε βάσει νόμου ή δικαιοσύνης.",
        "linksToOtherSites": "Εάν παρέχουμε συνδέσμους σε ιστότοπους τρίτων, παρέχονται μόνο για διευκόλυνση. Εάν χρησιμοποιείτε αυτούς τους συνδέσμους, θα εγκαταλείψετε τον Ιστότοπό μας. Δεν ελέγχουμε αυτούς τους ιστότοπους τρίτων και δεν τους υποστηρίζουμε. Συμφωνείτε ότι δεν είμαστε υπεύθυνοι για κανέναν από αυτούς τους ιστότοπους τρίτων ή το περιεχόμενό τους...",
        "thirdPartyRequests": "Εάν πρέπει να παράσχουμε πληροφορίες ως απάντηση σε ένα νομικό ή κρατικό αίτημα σχετικά με τον λογαριασμό σας ή τη χρήση της Υπηρεσίας από εσάς, τότε συμφωνείτε ότι ενδέχεται να σας χρεώσουμε για το κόστος μας. Αυτές οι αμοιβές μπορεί να περιλαμβάνουν τις νομικές αμοιβές μας και το κόστος του χρόνου που αφιερώθηκε στους υπαλλήλους μας για την προετοιμασία της απάντησης στο αίτημα.",
        "assignment": "Αυτή η Συμφωνία, καθώς και τυχόν δικαιώματα και άδειες που χορηγούνται βάσει αυτής, δεν επιτρέπεται να μεταβιβαστούν ή να εκχωρηθούν από εσάς, αλλά μπορεί να εκχωρηθούν από το Menuonline χωρίς περιορισμό, κατόπιν εύλογης εκ των προτέρων ειδοποίησης προς εσάς.",
        "governingLaw": "Αυτοί οι όροι θα διέπονται και θα ερμηνεύονται σύμφωνα με όλους τους ισχύοντες ομοσπονδιακούς νόμους και τους νόμους του Οντάριο, χωρίς να λαμβάνονται υπόψη οι διατάξεις περί σύγκρουσης νόμων. Συμφωνείτε να υποβάλλεστε στην αποκλειστική δικαιοδοσία των δικαστηρίων του Οντάριο για την επίλυση οποιασδήποτε διαφοράς σχετικά με αυτούς τους Όρους.",
        "severability": "Εάν για οποιονδήποτε λόγο το αρμόδιο δικαστήριο κρίνει ότι οποιαδήποτε διάταξη των παρόντων Όρων ή τμήμα τους είναι παράνομη ή για οποιονδήποτε λόγο μη εκτελεστή, τότε αυτή η διάταξη θα εφαρμοστεί στο μέγιστο βαθμό που επιτρέπεται για να εφαρμοστεί η πρόθεση αυτών των Όρων και οι υπόλοιποι όροι θα συνεχίσουν να ισχύουν πλήρως και θα παραμείνουν εκτελεστοί.",
        "waiver": "Οποιαδήποτε παραίτηση ή συναίνεση για απόκλιση από τις απαιτήσεις των παρόντων Όρων θα ισχύει μόνο εάν είναι γραπτή και υπογεγραμμένη από εμάς, και μόνο στη συγκεκριμένη περίπτωση και για τον συγκεκριμένο σκοπό για τον οποίο έχει δοθεί. Η αδυναμία μας να ασκήσουμε ή η καθυστέρηση στην άσκησή μας, οποιοδήποτε δικαίωμα βάσει αυτών των Όρων δεν θα λειτουργεί ως παραίτηση από τα δικαιώματά μας.",
        "furtherAssurances": "Κάθε συμβαλλόμενο μέρος συμφωνεί να υπογράψει και να παράσχει όλα τα έγγραφα και να προβεί σε οποιεσδήποτε ενέργειες (ή να προκαλέσει την πραγματοποίηση αυτών των ενεργειών) που είναι απαραίτητες για την εκπλήρωση των υποχρεώσεών του βάσει αυτών των Όρων και Προϋποθέσεων.",
        "notices": "Όλες οι ειδοποιήσεις στο Menuonline πρέπει να είναι γραπτές και να μας αποστέλλονται μέσω email. Οι ειδοποιήσεις προς εμάς πρέπει να γνωστοποιούνται στους εκπροσώπους εξυπηρέτησης πελατών στη διεύθυνση support@menuonline.com. Οι ειδοποιήσεις προς εσάς ενδέχεται να αποσταλούν στη διεύθυνση ή τη διεύθυνση ηλεκτρονικού ταχυδρομείου που παρείχατε ως μέρος της εγγραφής σας. Επιπλέον, ενδέχεται να μεταδίδουμε ειδοποιήσεις ή μηνύματα μέσω αυτού του ιστότοπου για να σας ενημερώσουμε για αλλαγές σε αυτόν τον Ιστότοπο ή άλλα σημαντικά θέματα, και αυτές οι εκπομπές θα αποτελούν ειδοποίηση προς εσάς τη στιγμή που θα τις δημοσιεύσουμε.",
        "entireAgreement": "Αυτοί οι Όροι και η Δήλωση Προστασίας Προσωπικών Δεδομένων αποτελούν τη συνολική συμφωνία μεταξύ εσάς και εμάς σχετικά με τη χρήση του Ιστότοπου και της Υπηρεσίας από εσάς και αντικαθιστούν όλες τις προηγούμενες συμφωνίες, παραστάσεις και συνεννοήσεις μεταξύ του Menuonline και εσάς σχετικά με αυτό το θέμα. Σε περίπτωση οποιασδήποτε σύγκρουσης μεταξύ της Δήλωσης Απορρήτου και των παρόντων Όρων, θα διέπουν τους παρόντες Όρους και προϋποθέσεις.",
        "language": "Τα μέρη έχουν ρητά ζητήσει και απαιτήσει οι παρόντες Όροι και προϋποθέσεις, η Πολιτική Απορρήτου μας και τυχόν σχετικά έγγραφα να συντάσσονται στα Αγγλικά. Les party conviennent et exigent expressément que ce Contrat et tous les document qui s'y rapportent soient rédigés en anglais. Συμφωνείτε ότι η αγγλική έκδοση αυτών των Όρων και της Πολιτικής Απορρήτου θα έχει προτεραιότητα σε περίπτωση οποιασδήποτε σύγκρουσης με οποιαδήποτε μεταφρασμένη έκδοση.",
        "survival": "Όλες οι διατάξεις αυτών των Όρων οι οποίες από τη φύση ή την εισαγωγή τους προορίζονται να επιβιώσουν μετά τη λήξη ή τον τερματισμό αυτών των Όρων, θα ισχύουν, συμπεριλαμβανομένων, χωρίς περιορισμό, των Ενοτήτων 17-19."
    },
    "collect_additional_info": "Θα θέλατε να συλλέξετε πρόσθετες πληροφορίες;",
    "additionalInfoChoice": "Επιλέξτε τυχόν πρόσθετες πληροφορίες που θέλετε να συλλέξετε.",
    "additional_info_yes": "Ναί",
    "additional_info_noSkip": "Όχι, Σκιπ",
    "additional_info_label": "Πρόσθετες πληροφορίες",
    "confirm_business_location": "Ας επιβεβαιώσουμε πρώτα την τοποθεσία της επιχείρησής σας",
    "custom_get_more_reviews_form": "Προσαρμοσμένη φόρμα λήψης περισσότερων κριτικών",
    "set_up_custom_form": "Ρυθμίστε την προσαρμοσμένη φόρμα λήψης περισσότερων κριτικών ανά ομάδα",
    "reputation_name_label": "Λάβετε περισσότερες κριτικές Όνομα",
    "reputation_name_placeholder": "Εισαγάγετε το όνομα φήμης",
    "group_label": "Ομάδα",
    "group_desc_title": "Τίτλος περιγραφής ομάδας",
    "group_desc": "Περιγραφή ομάδας",
    "select_group_placeholder": "Επιλέξτε Ομάδα",
    "required_group_name": "Απαιτείται όνομα ομάδας",
    "before_asking_review": "Πριν ζητήσουμε από τους πελάτες για κριτική, ας τους κάνουμε 1 έως 4 ερωτήσεις.",
    "feel_free_edit": "Μη διστάσετε να επεξεργαστείτε όπως χρειάζεται.",
    "incentive_settings": "Ρυθμίσεις κινήτρων",
    "incentivize_customer": "Θα θέλατε να δώσετε κίνητρο στον πελάτη σας να κάνει μια κριτική;",
    "choose_incentive": "Επιλέξτε το κίνητρο που θα θέλατε να αναφέρετε.",
    "scan_qr_feedback": "Σαρώστε τον κωδικό QR και μοιραστείτε τα πολύτιμα σχόλιά σας μαζί μας σχετικά με την εμπειρία σας.",
    "qr_code": "Κωδικός QR",
    "scan_me": "ΣΑΡΩΣΤΕ ΜΕ!",
    "or_title": "Ή",
    "send_otp_title": "Αποστολή OTP",
    "sign_in_title": "Είσοδος",
    "email_addresss_title": "Διεύθυνση Email",
    "reset_password_title": "Επαναφορά κωδικού πρόσβασης",
    "remember_password_title": "Θυμηθήκατε τον κωδικό πρόσβασης;",
    "digit_code_title": "Παρακαλούμε εισάγετε 4ψήφιο κωδικό που στάλθηκε ",
    "not_received_title": "Δεν έλαβα κωδικό επαλήθευσης!",
    "resend_title": "Παρακαλώ στείλτε ξανά",
    "sign_up_form": "Δημιουργήστε τον λογαριασμό σας",
    "sign_up_sub_title": "Συνδεθείτε τώρα για πρόσβαση στις πιο πρόσφατες πληροφορίες",
    "already_registered_title": "Έχετε ήδη λογαριασμό στο Menuonline;",
    "reserved_title": "Πνευματικά δικαιώματα ©2023 Menuonline. Με την επιφύλαξη παντός δικαιώματος.",
    "terms_title": "Όροι Παροχής Υπηρεσιών",
    "policy_title": "Πολιτική Απορρήτου",
    "sign_up_title": "Εγγραφείτε",
    "terms_conditions_title": "Όροι & Προϋποθέσεις",
    "business_type_title": "Τύπος επιχείρησης",
    "business_type_sub_title": "Επιλέξτε έναν από τους τύπους επιχείρησης που περιγράφει καλύτερα την επιχείρησή σας!",
    "salon_title": "Σαλόνι",
    "restaurant_title": "Εστιατόριο",
    "pizza_store_title": "Πίτσα κατάστημα",
    "food_truck_title": "Φορτηγό τροφίμων",
    "check_domain_title": "Ελέγξτε το όνομα τομέα",
    "start_exploring_title": "Ξεκινήστε την Εξερεύνηση",
    "sign_in_form": "Συνδεθείτε στον λογαριασμό σας",
    "sign_in_sub_title": "Συνδεθείτε τώρα για να αποκτήσετε πρόσβαση στις πιο πρόσφατες πληροφορίες",
    "remember_title": "Να με θυμάσαι",
    "otp_title": "OTP",
    "password_title": "Σύνθημα",
    "forgot_title": "Ξεχάσατε τον κωδικό πρόσβασης;",
    "not_registered_title": "Δεν έχετε λογαριασμό;",
    "create_account_title": "Εγγραφείτε τώρα",
    "create_form_title": "Δημιουργία Φόρμας",
    "first_name_title": "Ονομα",
    "last_name_title": "Επώνυμο",
    "email_title": "E-mail",
    "mobile_number_title": "Αριθμός κινητού",
    "birthday_title": "Γενέθλια",
    "gender_title": "Γένος",
    "address_title": "Διεύθυνση",
    "short_answer_title": "Σύντομη απάντηση",
    "long_answer_title": "Μεγάλη απάντηση",
    "single_answer_title": "Ενιαία απάντηση",
    "single_checkbox_title": "Μονό πλαίσιο ελέγχου",
    "multiple_choice_title": "Πολλαπλή επιλογή",
    "drop_down_title": "Αναπτυσσόμενο",
    "yes_no_title": "Ναι ή Όχι",
    "description_text_title": "Κείμενο περιγραφής",
    "file_upload": "Μεταφόρτωση αρχείου",
    "reset_password_form": "Επαναφέρετε τον κωδικό πρόσβασής σας",
    "reset_password_sub_title": "Επαναφέρετε γρήγορα τον κωδικό πρόσβασής σας και αποκτήστε πρόσβαση στον λογαριασμό σας.",
    "required_full_name_title": "Απαιτείται πλήρες όνομα",
    "required_first_name_title": "Το όνομα είναι υποχρεωτικό",
    "valid_first_name_title": "Εισαγάγετε έγκυρο όνομα",
    "valid_full_name_title": "Εισαγάγετε έγκυρο πλήρες όνομα",
    "required_block_time": "Απαιτείται χρόνος αποκλεισμού",
    "required_last_name_title": "Το επώνυμο είναι υποχρεωτικό",
    "valid_last_name_title": "Εισαγάγετε έγκυρο επώνυμο",
    "valid_mobile_number_title": "Εισαγάγετε έγκυρο αριθμό κινητού τηλεφώνου",
    "required_mobile_number_title": "Απαιτείται αριθμός κινητού",
    "required_already_mobile_number_title": "Ο αριθμός κινητού είναι ήδη απαραίτητος",
    "valid_otp_title": "Εισαγάγετε έγκυρο otp",
    "valid_email_title": "Εισαγάγετε ένα έγκυρο email",
    "required_email_title": "Απαιτείται email",
    "required_description_title": "Απαιτείται περιγραφή",
    "required_description_500_characters": "Εισαγάγετε τουλάχιστον 500 χαρακτήρες",
    "required_description_600_characters": "Εισαγάγετε το μέγιστο 600 χαρακτήρες",
    "domain_title": "Όνομα Τομέα",
    "required_password_title": "Απαιτείται κωδικός πρόσβασης",
    "required_password_regex_title": "Πρέπει να περιέχει τουλάχιστον 8 χαρακτήρες, τουλάχιστον έναν κεφαλαίο χαρακτήρα, έναν πεζό χαρακτήρα, έναν αριθμό και έναν ειδικό χαρακτήρα",
    "required_terms_title": "Παρακαλούμε συμφωνήστε με τους όρους και τις προϋποθέσεις",
    "required_note_title": "Σημείωση απαιτείται",
    "required_birthday_title": "Τα γενέθλια είναι υποχρεωτικά",
    "no_access_title": "Δεν έχετε πρόσβαση αυτήν τη στιγμή. Επικοινωνήστε με τον διαχειριστή σας",
    "menu_online_open_txt": "Ανοιχτό",
    "loyalty_program_description": "😊 Βρείτε το ιδανικό πρόγραμμα αφοσίωσης και προσαρμόστε το ώστε να ταιριάζει με την επωνυμία σας!",
    "lp_list_title": "Λίστα Παντ Κάρτας Πιστότητας",
    "lp_n_title": "Όνομα κάρτας αφοσίωσης",
    "enter_details_title": "Εισαγάγετε Στοιχεία",
    "stamp_allowed_title": "Επιτρέπεται η σφραγίδα",
    "stamp_items_title": "Είδη γραμματοσήμων",
    "lpn_req_title": "Απαιτείται το όνομα της κάρτας επιβράβευσης επιβράβευσης",
    "valid_lpn_title": "Εισαγάγετε έγκυρο όνομα κάρτας επιβράβευσης επιβράβευσης",
    "brand_color_error": "Το χρώμα της μάρκας πρέπει να είναι ακριβώς 7 χαρακτήρες",
    "select_dark_brand_color_title": "Επιλέξτε έγκυρο χρώμα επωνυμίας",
    "your_brand_color_title": "Το χρώμα της επωνυμίας σας",
    "upload_logo_title": "Μεταφόρτωση λογότυπου",
    "locations_title": "Τοποθεσίες",
    "add_lp_title": "Δημιουργία προγράμματος",
    "location_error": "Επιλέξτε τοποθεσίες",
    "pvs_title": "Πόσα γραμματόσημα μπορεί να κερδίσει ένας πελάτης ανά επίσκεψη;",
    "max_apd_title": "Ποιος είναι ο μέγιστος αριθμός γραμματοσήμων που μπορεί να κερδίσει ένας πελάτης σε μια ημέρα;",
    "logo_error": "Απαιτείται λογότυπο",
    "expiry_title": "Ορίστε τη λήξη στις ανταμοιβές",
    "add_more_rewards_title": "+ Προσθήκη περισσότερων ανταμοιβών",
    "prev_step_1_title": "Εμφάνιση QR για να κερδίσετε πόντους και να απολαύσετε τις ανταμοιβές σας.",
    "delete_lp_title": "Αυτή η κάρτα επιβράβευσης επιβράβευσης θα διαγραφεί οριστικά.",
    "delete_user_record_title": "Αυτή η εγγραφή χρήστη θα διαγραφεί οριστικά.",
    "redeem_title": "Εξαργυρώνω",
    "lp_button_title": "Εγγραφείτε στην κάρτα Loyalty Punch",
    "lp_download_button_title": "Λήψη κάρτας",
    "step_0_title": "Εγγραφείτε τώρα, περιμένουν νόστιμες ανταμοιβές για πιστούς καλοφαγάδες όπως εσείς.",
    "step_1_title": "Έχετε εγγραφεί",
    "congrats_step_title": "Τώρα πληροίτε τις προϋποθέσεις για Δωρεάν πίτσα",
    "reward_title": "Λάβετε ανταμοιβές σε κάθε επίσκεψη",
    "visit_title": "Επισκεφτείτε και ξεκλειδώστε",
    "rewards_title": "Ανταμοιβές",
    "dummy_address": "3730 Frankfort Ave, Louisville Kentucky, Ηνωμένες Πολιτείες",
    "loyalty_qr_default_text": "Σαρώστε τον κωδικό QR, εγγραφείτε στην κάρτα αφοσίωσης και απολαύστε ανταμοιβές",
    "lp_earn_stamp_txt": "Οι πελάτες κερδίζουν γραμματόσημα σε κάθε επίσκεψη.",
    "lp_get_rewards_txt": "Λάβετε ανταμοιβές σε κάθε επίσκεψη",
    "lp_1_time_visit_txt": "Θα λάβετε 1 φορά επίσκεψη",
    "lp_uppercase_stamp_text": "Σφραγίδα",
    "lp_lowercase_stamp_text": "σφραγίδα",
    "lp_txt": "Πίστη",
    "lp_per_visit_stamp_validation_msg": "Η σφραγίδα ανά επίσκεψη πρέπει να είναι μικρότερη από τη μέγιστη επιτρεπόμενη σφραγίδα ανά ημέρα",
    "lp_per_max_stamp_validation_msg": "Η μέγιστη επιτρεπόμενη σφραγίδα ανά ημέρα πρέπει να είναι μεγαλύτερη από τη σφραγίδα ανά επίσκεψη",
    "lp_valid_value_msg": "Εισαγάγετε έγκυρη τιμή",
    "lp_required_field_msg": "Αυτό το πεδίο είναι υποχρεωτικό",
    "lp_stamp_required_msg": "Απαιτείται σφραγίδα",
    "lp_valid_stamp_msg": "Εισαγάγετε έγκυρη τιμή σφραγίδας",
    "lp_reward_name_required_msg": "Απαιτείται όνομα επιβράβευσης",
    "lp_unique_stamp_msg": "Η αξία της σφραγίδας πρέπει να είναι μοναδική",
    "lp_benefits": "Ένα πρόγραμμα αφοσίωσης ενισχύει τις επαναλαμβανόμενες επισκέψεις και τα έσοδα, ενώ παρέχει πληροφορίες στους πελάτες για εξατομικευμένο μάρκετινγκ",
    "lp_how_customer_txt": "Δείτε πώς οι πελάτες σας",
    "lp_punch_card_txt": "κάρτα διάτρησης πίστης",
    "lp_look_txt": "θα κοιτάξει! 😍",
    "lp_desc": "Οι πελάτες θα κερδίζουν γραμματόσημα για κάθε επίσκεψη και μόλις συγκεντρώσουν αρκετά, μπορούν να εξαργυρώσουν ειδικές ανταμοιβές.",
    "lp_edit": "Εκδίδω",
    "lp_looks_good_txt": "Φαίνεται καλό",
    "lp_customer_view_instruction": "Αυτό θα δει ο πελάτης σας. Ανεβάστε το λογότυπό σας και ορίστε το χρωματικό θέμα",
    "lp_customer_earn_stamps": "Επιτρέψτε στους πελάτες να κερδίζουν γραμματόσημα σε κάθε επίσκεψη και να διαχειριστούν τις ανταμοιβές τους",
    "lp_stamp_rewards_setup": "Ορίστε ανταμοιβές απόκτησης γραμματοσήμων, επιτρέψτε στους πελάτες να λάβουν ανταμοιβές ανά γραμματόσημα",
    "lp_loyalty_program_setup": "Δώστε το όνομα του προγράμματος αφοσίωσης και ορίστε την τοποθεσία σας για να έχετε αυτό το πρόγραμμα",
    "lp_prev_step1": "Εμφάνιση QR για να κερδίσετε πόντους και να απολαύσετε τις ανταμοιβές σας.",
    "lp_visit_1_time_txt": "Επισκεφθείτε 1 φορά και λάβετε",
    "lp_get_free_txt": "Αποκτήστε δωρεάν",
    "lp_visit_allow_txt": "Vist & Unlock",
    "lp_rewards": "Ανταμοιβές",
    "repsT": "Λάβετε περισσότερες κριτικές",
    "offSup": "Προμήθειες γραφείου",
    "repsNxtV": "Στην επόμενη επίσκεψή σας",
    "addRepT": "Προσθήκη Λάβετε περισσότερες κριτικές",
    "repEntrCpnTxt": "Εισαγάγετε κείμενο κουπονιού",
    "repEntrCpnTxtBtn": "Εισαγάγετε κείμενο κουμπιού κουπονιού",
    "repMngNxtV": "στην επόμενη επίσκεψή σας;",
    "repMngWldL": "Θα θέλατε να πάρετε",
    "discount": "Εκπτωση",
    "repDisTyp": "Τύπος έκπτωσης",
    "repNameT": "Ονομάστε τη φήμη σας",
    "crtRepT": "Δημιουργήστε Φήμη",
    "feedBackT": "Ανατροφοδότηση",
    "confLocT": "Επιβεβαίωση τοποθεσίας",
    "businLocT": "Τοποθεσία επιχείρησης",
    "addInfoT": "Πρόσθετες Πληροφορίες",
    "repNReqT": "Το όνομα Λήψη περισσότερων κριτικών είναι υποχρεωτικό",
    "validRepNT": "Εισαγάγετε έγκυρο όνομα φήμης",
    "groupT": "Ομάδα",
    "groupLDescTitleT": "Αυτόματη ώθηση επαφών σε μια ομάδα",
    "groupLDescT": "Όλοι οι πελάτες που συμπληρώνουν τη φόρμα θα προστεθούν στην επιλεγμένη ομάδα.",
    "que2": "Ήταν καλό το φαγητό σας;",
    "que3": "Ήταν φιλική η υπηρεσία μας;",
    "que4": "Ήταν γρήγορη η υπηρεσία μας;",
    "addAdditionalT": "Θα θέλατε να προσθέσετε επιπλέον πληροφορίες;",
    "phNoMandT": "Ο αριθμός τηλεφώνου είναι υποχρεωτικός!",
    "qrGenT": "Δημιουργήθηκε QR",
    "repFDT": "Η συμβολή σας θα βοηθήσει την ομάδα μας να σας εξυπηρετήσει καλύτερα!",
    "letdiT": "Ας το κάνουμε",
    "tak15secT": "Διαρκεί 15 δευτερόλεπτα",
    "defQue": "Θα θέλατε να λαμβάνετε μεγάλες προσφορές και προωθήσεις κατά καιρούς;",
    "defNQue": "Θα θέλατε κάποιος να σας απαντήσει;",
    "rateOnGoogleDesc": "Θα κάνατε την καλοσύνη να μας αξιολογήσετε στο google;",
    "mayBeNT": "Ισως την επόμενη φορά",
    "yesSureT": "Ναι, σίγουρα",
    "valid_name": "Εισαγάγετε έγκυρο όνομα",
    "nameReq": "Το όνομα απαιτείται",
    "backToMainT": "Επιστροφή στην Κεντρική Σελίδα",
    "attachCopT": "Θα θέλατε να δώσετε κίνητρο στον πελάτη σας;",
    "thankYouT": "Σας ευχαριστώ",
    "lftyT": "Ανυπομονούμε για την επόμενη επίσκεψή σας!",
    "repPreT": "Προεπισκόπηση φήμης",
    "offer_exclusive_discounts": "Προσφέρετε αποκλειστικές εκπτώσεις και προσφορές για να προσελκύσετε τους πελάτες σας.",
    "couponT": "Κουπόνι",
    "locStepDesc": "Επιβεβαιώστε την τοποθεσία της επιχείρησής σας",
    "createRepStepDesc": "Δημιουργήστε μια προσαρμοσμένη φόρμα φήμης ανά ομάδες",
    "feedbackStepDesc": "Σύνολο ερωτήσεων για σχόλια πελατών",
    "addInfoStepDesc": "Πεδία πελατών και ρυθμίσεις κινήτρων",
    "FedbkkWDT": "Ανατροφοδότηση με δεδομένα",
    "repAnlytT": "Reputation Analytics",
    "todayT": "Σήμερα",
    "rep_last_7_days_t": "Τελευταίες 7 ημέρες",
    "last30DaysT": "Τελευταίες 30 ημέρες",
    "lastMT": "τον περασμένο μήνα",
    "custRangeT": "Προσαρμοσμένο εύρος",
    "queWiseAnltT": "Question Wise Analytics",
    "atL1QueErrT": "Επιλέξτε τουλάχιστον μία ερώτηση",
    "deleteRepTitle": "Αυτή η φήμη θα διαγραφεί οριστικά.",
    "incvCustT": "Ναι, Δώστε κίνητρα στον πελάτη μου",
    "collAddInfoT": "Ναι, θα ήθελα να συλλέξω πρόσθετες πληροφορίες",
    "totRewT": "Συνολική κριτική",
    "totPosRewT": "Συνολική θετική κριτική",
    "negFeedT": "Αρνητική ανατροφοδότηση",
    "posFeedT": "Θετική Ανατροφοδότηση",
    "fineT": "Ψιλά γράμματα",
    "enterFineT": "Εισαγάγετε κείμενο με ψιλά γράμματα",
    "setThemeT": "Ορισμός χρώματος θέματος",
    "que1T": "Ερώτηση 1",
    "que2T": "Ερώτηση 2",
    "que3T": "Ερώτηση 3",
    "que4T": "Ερώτηση 4",
    "entMailT": "Εισαγάγετε το email σας",
    "reputationQRDefTxt": "Σαρώστε τον κωδικό QR και μοιραστείτε τα πολύτιμα σχόλιά σας μαζί μας σχετικά με την εμπειρία σας.",
    "selUser": "Επιλέξτε Χρήστης",
    "userT": "Μεταχειριζόμενος",
    "subUReq": "Επιλέξτε χρήστες",
    "updateLoc": "Ενημέρωση τοποθεσίας",
    "leadGenT": "Lead Generation",
    "displayT": "Επίδειξη",
    "ratingT": "Εκτίμηση",
    "rep_dashboard_negative_feedback": "Αρνητικά σχόλια 0%",
    "rep_dashboard_position_feedback": "Θετικά σχόλια 0%",
    "rep_dashboard_total_Feedback_txt": "Συνολική ανατροφοδότηση",
    "rep_dashboard_anony_Feedback": "Ανώνυμη ανατροφοδότηση",
    "rep_dashboard_Feeedback_Data_txt": "Ανατροφοδότηση με δεδομένα",
    "rep_dashboard_review_txt": "Λάβετε περισσότερες κριτικές",
    "rep_dashboard_total_Review_txt": "Συνολική κριτική",
    "rep_dashboard_total_positive_Review_txt": "Συνολική θετική κριτική",
    "rep_dashboard_negative_feedback_txt": "Αρνητική ανατροφοδότηση",
    "rep_dashboard_connection_txt": "Σύνδεση",
    "rep_dashboard_question_wise_analytics_txt": "Question Wise Analytics",
    "rep_dashboard_date_label": "Επιλέξτε Ημερομηνία",
    "rep_dashboard_custom_range_txt": "Προσαρμοσμένο εύρος",
    "rep_tlt_min": "ΜΑΛΛΟΜΕΤΑΞΟ ΥΦΑΣΜΑ",
    "emojis": "Emojis",
    "media_text": "Προσθήκη πολυμέσων",
    "custom_field_text": "Εισαγάγετε προσαρμοσμένα πεδία",
    "shortlink_text": "Προσθήκη Shortlink",
    "use_template_text": "Χρησιμοποιήστε το πρότυπο",
    "banner_must_have": "Πρέπει να έχει",
    "res_seamless": "Χωρίς ραφή",
    "res_table_reservation": "Κράτηση τραπεζιού",
    "res_system": "Σύστημα",
    "res_text": "κρατήσεις",
    "md_easily": "Εύκολα",
    "md_manage_customers": "Διαχείριση πελατών",
    "md_visits_interactions": "επισκέψεις & αλληλεπιδράσεις",
    "md_desc": "Κάντε το φαγητό πιο εύκολο για τους πελάτες σας με κρατήσεις τραπεζιού σε πραγματικό χρόνο. Διαχειριστείτε τις κρατήσεις χωρίς κόπο, μειώστε τους χρόνους αναμονής και εξασφαλίστε μια ομαλή εμπειρία τόσο για το προσωπικό όσο και για τους επισκέπτες. Κρατήστε το εστιατόριό σας πλήρως κρατημένο, διατηρώντας παράλληλα κορυφαία εξυπηρέτηση και ικανοποίηση.",
    "engage_convert": "Αλληλεπίδραση και μετατροπή SMS",
    "coupon_campaign": "και Καμπάνια Κουπονιών",
    "sms_read_rate": "Τα μηνύματα SMS διαβάζονται στο 98% των περιπτώσεων",
    "real_time_promo": "Παραδώστε προσφορές σε πραγματικό χρόνο για άμεση δράση",
    "affordable_reach": "Προσιτός τρόπος προσέγγισης πελατών με σταθερή απόδοση επένδυσης (ROI).",
    "stay_in_touch": "Μείνετε σε επαφή με τον πελάτη σας ανά πάσα στιγμή",
    "bc_engage_customers": "Προσέλκυση πελατών Μετατροπή SMS",
    "bc_coupon_campaign": "και Καμπάνια Κουπονιών",
    "bc_sms_read_rate": "Τα μηνύματα SMS διαβάζονται στο 98% των περιπτώσεων",
    "bc_real_time_promo": "Παραδώστε προσφορές σε πραγματικό χρόνο για άμεση δράση",
    "bc_affordable_reach": "Προσιτός τρόπος προσέγγισης πελατών με σταθερή απόδοση επένδυσης (ROI).",
    "bc_stay_in_touch": "Μείνετε σε επαφή με τον πελάτη σας ανά πάσα στιγμή",
    "ar_automate_engagement": "Αυτοματοποιήστε την δέσμευση πελατών σας",
    "ar_with": "με",
    "ar_with_auto_responders": "Auto-Responders",
    "ar_personalized_messages": "Στείλτε αυτόματα εξατομικευμένα μηνύματα για γενέθλια, καλωσορίστε νέους πελάτες και επανασυνδεθείτε με προηγούμενους πελάτες. Εξοικονομήστε χρόνο διατηρώντας παράλληλα ουσιαστική και έγκαιρη κάθε αλληλεπίδραση.",
    "sq_custom_forms_tools": "Προσαρμοσμένες φόρμες και εργαλεία - Απλοποίηση",
    "sq_custom_forms_ci": "Αλληλεπιδράσεις με πελάτες",
    "sq_smart_qr_desc": "Δημιουργήστε προσαρμοσμένες φόρμες, συλλέξτε ηλεκτρονικές υπογραφές και αξιοποιήστε έξυπνους κωδικούς QR για να βελτιστοποιήσετε τις επιχειρηματικές σας διαδικασίες. Προσαρμόστε κάθε εργαλείο για να καλύψετε τις ανάγκες των πελατών σας και βελτιώστε την εμπειρία χρήστη.",
    "gnc_get_new_cus": "Αποκτήστε νέους πελάτες",
    "gnc_get_customers": "από τα Social Media— Γρήγορα και εύκολα!",
    "gnc_social_media_power": "Χρησιμοποιήστε τη δύναμη των κοινωνικών πλατφορμών για να προσελκύσετε νέους πελάτες και να προωθήσετε τις πωλήσεις απευθείας στην επιχείρησή σας. Ανακατευθύνετε τις παραγγελίες παράδοσης από τρίτους απευθείας στο κατάστημά σας ή στην ηλεκτρονική πλατφόρμα παραγγελιών για μέγιστο έλεγχο και έσοδα.",
    "wifi_connect_guests": "Συνδέστε τους επισκέπτες με",
    "wifi_smart": "Έξυπνο Wi-Fi",
    "wifi_connect_grow": "& Αναπτύξτε τις πληροφορίες σας",
    "wifi_smart_wifi_desc": "Παρέχετε μια απρόσκοπτη σύνδεση Wi-Fi στους πελάτες σας μέσω μιας επώνυμης splash οθόνης, ενώ συλλέγετε πολύτιμα δεδομένα πελατών για να βελτιώσετε τις προσπάθειες μάρκετινγκ και τη δέσμευσή σας.",
    "wifi_create_splash_page": "Δημιουργία Guest Wifi Splash Page",
    "instant_menu_create": "Δημιουργήστε το δικό σας",
    "instant_menu_text": "Στιγμιαίο μενού",
    "instant_menu_wm": "μέσα σε λίγα λεπτά",
    "instant_menu_desc": "Βελτιώνει τις εμπειρίες των πελατών επιτρέποντας ενημερώσεις σε πραγματικό χρόνο, εύκολη προσαρμογή και βελτιωμένη αφοσίωση που διευκολύνει την προβολή των προσφορών σας και τη διατήρηση του περιεχομένου σας φρέσκο.",
    "instant_menu_create_digital_menu": "Δημιουργήστε ένα ψηφιακό μενού",
    "loyalty_program_rewards": "Κερδίστε ανταμοιβές",
    "loyalty_program_on_visits": "σε κάθε επίσκεψη",
    "loyalty_program_create": "Δημιουργία προγράμματος αφοσίωσης",
    "reputation_create": "Δημιουργήστε Φήμη",
    "reputation_boost": "Ενισχύστε σας",
    "reputation_text": "Φήμη",
    "reputation_reviews": "με περισσότερες κριτικές!",
    "reputation_desc": "Συλλέξτε πολύτιμα σχόλια από τους πελάτες σας για να βελτιώσετε τις υπηρεσίες και τη φήμη σας. Μοιραστείτε την εμπειρία σας και βοηθήστε μας να αναπτυχθούμε—η κριτική σας έχει σημασία!",
    "dashboard": "Ταμπλό",
    "blast_campaign": "Εκστρατεία έκρηξης",
    "blast_campaigns": "Εκστρατείες έκρηξης",
    "blast_campaign_sub_title": "Ξεκινήστε καμπάνιες blast για να προσεγγίσετε όλους τους πελάτες σας με λίγα κλικ",
    "auto_responders": "Αυτόματοι ανταποκριτές",
    "auto_responder": "Αυτόματη απόκριση",
    "auto_responder_sub_title": "Εύκολες στη χρήση προωθήσεις που βασίζονται σε εκδηλώσεις για τους πελάτες σας",
    "smart_qr_form": "Έξυπνο QR \/ Φόρμα",
    "get_new_customer": "Αποκτήστε νέο πελάτη",
    "marketing": "Εμπορία",
    "clients": "Πελάτες",
    "group_data": "Δεδομένα Ομάδων",
    "clients_data": "Δεδομένα Πελατών",
    "account": "Λογαριασμός",
    "user_acc_info": "Πληροφορίες λογαριασμού χρήστη",
    "current_plan": "Τρέχον Σχέδιο",
    "payments": "Πληρωμές",
    "location": "Τοποθεσία",
    "location_list": "Λίστα τοποθεσιών",
    "users": "Χρήστες",
    "users_sub_title": "Λίστα όλων των χρηστών και τα στοιχεία τους",
    "online_profile": "Διαδικτυακό προφίλ",
    "change_password": "Αλλαγή κωδικού πρόσβασης",
    "menu": "Μενού",
    "reseller_dashboard": "Πίνακας ελέγχου μεταπωλητή",
    "companies": "Εταιρείες",
    "plans_bought": "Αγορασμένα σχέδια",
    "agreement_t_c": "T&C της συμφωνίας",
    "wifi_dashboard": "Πίνακας ελέγχου Wifi",
    "hot_spot": "Hotspot",
    "splash_pages": "Splash Pages",
    "get_more_reviews": "Λάβετε περισσότερες κριτικές",
    "office_supplies": "Προμήθειες γραφείου",
    "reservation": "Κράτηση",
    "floor_table": "Δάπεδο & Τραπέζι",
    "guest_smart_wifi": "Έξυπνο WiFi επισκεπτών",
    "digital_signage": "Ψηφιακή Σήμανση",
    "schedule_down": "Προγραμματισμός",
    "business_settings": "Ρυθμίσεις επιχειρήσεων",
    "sub_user_login": "Είσοδος υποχρήστη",
    "help_center": "Κέντρο βοήθειας",
    "switch_to_hub": "Μετάβαση στο Hub",
    "meeting_records": "Αρχεία Συνεδριάσεων",
    "logout": "Αποσύνδεση",
    "no_access_text": "Δεν έχετε πρόσβαση αυτήν τη στιγμή. επικοινωνήστε με τον διαχειριστή σας",
    "menu_item": "ΣΤΟΙΧΕΙΟ ΜΕΝΟΥ",
    "menu_capital": "ΜΕΝΟΥ",
    "good_day": "Καλημέρα",
    "no_permission_text": "Δεν έχετε άδεια να εκτελέσετε αυτήν την ενέργεια!!",
    "enter_valid_pin": "Εισαγάγετε έγκυρο pin",
    "add_reservation": "Προσθήκη κράτησης",
    "edit_reservation": "Επεξεργασία κράτησης",
    "per_req": "Απαιτείται αριθμός ατόμων",
    "req_block_time": "Απαιτείται χρόνος αποκλεισμού",
    "pending_status": "Εκκρεμής",
    "booked_status": "Κράτηση",
    "completed_status": "Ολοκληρώθηκε το",
    "cancelled_status": "Ακυρώθηκε",
    "reservation_slot": "Υποδοχή κράτησης",
    "block_time": "Χρόνος αποκλεισμού",
    "slot_time": "Χρόνος υποδοχής κράτησης:  ",
    "template_name_req": "Απαιτείται όνομα προτύπου",
    "template_type_req": "Απαιτείται τύπος προτύπου",
    "sms_template_req": "Απαιτείται πρότυπο SMS",
    "group_req": "Απαιτείται ομάδα",
    "valid_date_time": "Εισαγάγετε έγκυρη ημερομηνία και ώρα",
    "valid_group_name": "Εισαγάγετε έγκυρο όνομα ομάδας",
    "reservation_mark_completed": "Αυτή η κράτηση θα επισημανθεί ως ολοκληρωμένη.",
    "customer_name": "Όνομα πελάτη",
    "date_time": "Ημερομηνία Ώρα",
    "table_no": "Πίνακας Αρ",
    "floor": "Πάτωμα",
    "customer_name_placeholder": "όνομα_πελάτη",
    "date_time_placeholder": "ημερομηνία_ώρα",
    "table_no_placeholder": "πίνακας_αρ",
    "floor_placeholder": "πάτωμα",
    "select_floor": "Επιλέξτε Όροφος",
    "select_table": "Επιλέξτε Πίνακας",
    "customer_name_macro": "%% όνομα_πελάτη %%",
    "date_time_macro": "%% ημερομηνία_ώρα %%",
    "table_no_macro": "%% πίνακα_όχι %%",
    "floor_macro": "%% όροφος %%",
    "template_name": "Όνομα προτύπου",
    "login_with_4_digit_pin": "Συνδεθείτε με το 4ψήφιο pin σας",
    "login_now_to_access_latest_insights": "Συνδεθείτε τώρα για να αποκτήσετε πρόσβαση στα πιο πρόσφατα ένσημα",
    "mkt_BS_Title": "Blast SMS",
    "step_1": "Προσαρμογή μηνύματος",
    "step_2": "Επιλέξτε Κοινό",
    "step_3": "Προγραμματισμός μηνύματος",
    "step_4": "Στείλτε ένα μήνυμα",
    "step_5": "Διαχείριση μηνύματος",
    "bs_tab_title": "Σύνοψη καμπάνιας Blast",
    "default_message": "Γεια %% firstname %%, Ειδική έκπτωση σε brunch! Δειπνήστε μαζί μας σήμερα και κερδίστε αποκλειστική έκπτωση 10% στον λογαριασμό σας!",
    "bs_Title": "Επιλέξτε έναν τύπο καμπάνιας",
    "sms_title": "SMS\/MMS",
    "reach_out_to_customer": "Επικοινωνήστε με τους πελάτες μέσω μηνυμάτων κειμένου και πολυμέσων.",
    "wp_text": "WhatsApp",
    "connect_with_wp": "Συνδεθείτε με τους πελάτες σας χρησιμοποιώντας το WhatsApp 💬",
    "send_discount_offers": "Στείλτε προσφορές εκπτώσεων μέσω SMS 📲",
    "send_coupons_via_sms": "Στείλτε κουπόνια μέσω SMS στους πελάτες σας 🎟️",
    "env_Cap": "Προσφέρετε αποκλειστικές εκπτώσεις και προσφορές για να προσελκύσετε τους πελάτες σας",
    "cmt_Caption": "Επικοινωνήστε με τους πελάτες μέσω μηνυμάτων κειμένου και πολυμέσων",
    "wp_caption": "Συνδεθείτε με πελάτες χρησιμοποιώντας το WhatsApp για απρόσκοπτη και άμεση επικοινωνία",
    "msg_Prev_Title": "Προεπισκόπηση μηνύματος",
    "cou_Prev_Title": "Προεπισκόπηση κουπονιού",
    "blast_SMS": "Καμπάνια SMS\/MMS",
    "bs_P_Msg": "Στην ουρά",
    "bs_S_Msg_C": "Αποτυχημένος",
    "bs_F_Msg_Count": "Παραδόθηκε",
    "previous": "Προηγούμενος",
    "next_ST": "Επόμενος",
    "next_S": "Επόμενο Βήμα",
    "req_Temp_Name_Title": "Απαιτείται όνομα προτύπου",
    "req_Template_Tx_Title": "Απαιτείται κείμενο προτύπου",
    "add_Temp_Title": "Προσθήκη προτύπου",
    "link_Desc": "Προσθέστε έναν σύνδεσμο της επιλογής σας, ώστε οι πελάτες να μπορούν να κάνουν κράτηση μαζί σας μέσω του Facebook, της Google ή του δικού σας ιστότοπου.",
    "audience_Title": "Ακροατήριο",
    "client_G1_Title": "Νέοι Πελάτες",
    "client_G2_Title": "Πρόσφατοι πελάτες",
    "client_G3_Title": "Πιστοί Πελάτες",
    "client_G4_Title": "Πελάτες που έχουν λήξει",
    "client_G5_Title": "Πελάτες κατά Ημερομηνία Σύνδεσης",
    "on_Which_Day": "Ποια Ημέρα",
    "on_Day": "Την ημέρα",
    "immediate_Desc": "Το μήνυμά σας θα σταλεί αμέσως, κάντε κλικ στο Επόμενο για να ξεκινήσετε.",
    "never_Desc": "Αυτή η καμπάνια έχει προγραμματιστεί να τρέχει για πάντα μέχρι να τη τερματίσετε χειροκίνητα.",
    "end_Desc": "Η καμπάνια θα λήξει την ημερομηνία που θα επιλέξετε από εδώ.",
    "success_Message": "Η καμπάνια αποθηκεύτηκε με επιτυχία!",
    "send_Success_Message": "Η καμπάνια στάλθηκε με επιτυχία!",
    "msg_Name_Field_Title": "Τίτλος καμπάνιας",
    "valid_campaign_title": "Εισαγάγετε έγκυρο τίτλο καμπάνιας",
    "msg_Type_Title": "Τύπος μηνύματος",
    "sms_Desc": "1 πίστωση ανά SMS",
    "mms_Desc": "3 πιστώσεις ανά MMS",
    "client_GTO_1": "Ημέρα",
    "client_GTO_2": "Ώρες",
    "minutes": "Πρακτικά",
    "client_GTO_4": "Εβδομάδα",
    "time_Option_1": "Ημέρα",
    "time_Option_2": "Ώρες",
    "c_Grp_Day_1": "90 Ημέρες",
    "c_Grp_Day_2": "60 Ημέρες",
    "c_Grp_Day_3": "30 Ημέρες",
    "first": "Πρώτα",
    "second_T": "Δεύτερος",
    "third_T": "Τρίτος",
    "fourth_T": "Τέταρτος",
    "fifth_T": "Πέμπτος",
    "delete_BS_T": "Αυτή η καμπάνια θα διαγραφεί οριστικά.",
    "delete_MBS_T": "Αυτές οι καμπάνιες θα διαγραφούν οριστικά.",
    "cFT_1": "Τηλέφωνο",
    "cFT_2": "Όνομα Επικοινωνίας",
    "cFT_3": "Επώνυμο Επικοινωνίας",
    "cFT_4": "Email επικοινωνίας",
    "cFT_5": "Σημείωμα",
    "cFT_6": "Γενέθλια",
    "cFT_7": "Όνομα εταιρείας",
    "cF_Meta_Tag_1": "%% τηλέφωνο %%",
    "cF_Meta_Tag_2": "%% όνομα %%",
    "cF_Meta_Tag_3": "%% επώνυμο %%",
    "cF_Meta_Tag_4": "%% email %%",
    "cF_Meta_Tag_5": "%% σημείωση %%",
    "cF_Meta_Tag_6": "%% γενέθλια %%",
    "cF_Meta_Tag_7": "%% εταιρικό_όνομα %%",
    "cF_Name_1": "τηλέφωνο",
    "cF_Name_2": "όνομα",
    "cF_Name_3": "επώνυμο",
    "cF_Name_4": "e-mail",
    "cF_Name_5": "σημείωμα",
    "cF_Name_6": "γενέθλια",
    "cF_Name_7": "όνομα_εταιρίας",
    "all_clients": "Όλοι οι πελάτες",
    "aud_Type_2": "ClientGroups",
    "aud_Type_3": "Ομάδες Πελατών",
    "aud_Type_T1": "Όλοι οι Πελάτες",
    "aud_Type_T2": "Ομάδες Πελατών",
    "aud_Type_T3": "Ομάδες Πελατών",
    "new_clients": "Νέοι Πελάτες",
    "client_G2": "Πρόσφατοι πελάτες",
    "client_G3": "Πιστοί Πελάτες",
    "client_G4": "Πελάτες που έχουν λήξει",
    "client_G5": "Πελάτες κατά Ημερομηνία Σύνδεσης",
    "never_text": "Ποτέ",
    "ending_on": "Τελειώνει στις",
    "send_T2": "Προγραμματισμένος",
    "send_Type_3": "Επαναλαμβανόμενο",
    "daily": "Καθημερινά",
    "weekly_T": "Εβδομαδιαίος",
    "monthly_T": "Μηνιαίος",
    "yearly_T": "Ετήσια",
    "each": "Κάθε",
    "on_the": "Στο The",
    "monday": "Δευτέρα",
    "exclusive_offer": "Αποκλειστική προσφορά για εσάς",
    "redeem_now": "Εξαργυρώστε τώρα",
    "redeem_with_cashier": "Παρακαλώ εξαργυρώστε με ταμείο",
    "lg_Day_2": "Τρίτη",
    "lg_Day_3": "Τετάρτη",
    "lg_Day_4": "Πέμπτη",
    "lg_Day_5": "Παρασκευή",
    "lg_Day_6": "Σάββατο",
    "lg_Day_7": "Κυριακή",
    "msg_Pre_T": "Dominos",
    "cA_Edit_T1": "Ολοι",
    "cA_Edit_T2": "Συμπεριλαμβανομένος",
    "cA_Edit_T3": "Εξαιρείται",
    "SMS": "SMS",
    "MMS": "MMS",
    "usd_T": "USD",
    "cad_T": "ΠΑΛΗΑΝΘΡΩΠΟΣ",
    "msg": "Μήνυμα",
    "which_Day": "Ποιες Ημέρες",
    "end_Date_Tx": "Ημερομηνία λήξης",
    "sDate_Err": "Η ημερομηνία έναρξης πρέπει να είναι μεγαλύτερη ή ίση με τη σημερινή",
    "eDate_Err": "Η ημερομηνία λήξης πρέπει να είναι μεταγενέστερη από την ημερομηνία έναρξης",
    "start_Date_Req": "Απαιτείται ημερομηνία έναρξης",
    "end_Date_Req": "Απαιτείται ημερομηνία λήξης",
    "time_req": "Απαιτείται χρόνος",
    "client_GT1": "Πελάτες εγγεγραμμένοι στο τελευταίο",
    "client_GT2": "Πελάτες με σύνδεση στο τελευταίο",
    "client_GT3": "Πελάτες με τουλάχιστον",
    "client_GT4": "με το τελευταίο",
    "client_GT5": "που δεν γύρισε στο τελευταίο",
    "dummy_Phone_No": "+91987-654-3210",
    "test_T": "Δοκιμή",
    "dummy_Birth_Date": "01-01-2001",
    "image_Req": "Απαιτείται μέσο ή διεύθυνση URL",
    "time_Title": "Φορά",
    "start_date": "Ημερομηνία έναρξης",
    "end_date": "Ημερομηνία λήξης",
    "auto_Send_T": "Αυτόματη αποστολή σε πελάτες",
    "add_Media": "Προσθήκη πολυμέσων",
    "in_Title": "Σε",
    "c_Temp_T": "Πρότυπο καμπάνιας",
    "temp_NT": "Όνομα προτύπου",
    "type_T": "Τύπος",
    "select_template_type": "Επιλέξτε τύπο προτύπου",
    "sel_Temp_T": "Επιλέξτε Τύπος προτύπου",
    "sms_temp_t": "Πρότυπο SMS",
    "temp_T": "Κείμενο προτύπου",
    "default_Msg": "Γεια %% firstname %%, Ειδική έκπτωση σε brunch! Δειπνήστε μαζί μας σήμερα και κερδίστε αποκλειστική έκπτωση 10% στον λογαριασμό σας!",
    "refill_T": "Ξαναγέμισμα",
    "avl_Credit": "Διαθέσιμες πιστώσεις",
    "req_Credit": "Συνολικές πιστώσεις που απαιτούνται",
    "rem_Credit": "Υπόλοιπη πίστωση",
    "refill_Credit": "Πιστώσεις προς αναπλήρωση",
    "clients_Req": "Επιλέξτε τουλάχιστον έναν πελάτη",
    "subclient_Req": "Οι επιλεγμένοι πελάτες δεν είναι συνδρομητές.",
    "refill_Desc": "Συμπληρώστε ξανά τις απαιτούμενες μονάδες",
    "url_Regex": "^((http|https):\/\/).....+$",
    "sd_Invalid": "Η ημερομηνία έναρξης δεν είναι έγκυρη",
    "ed_Invalid": "Η ημερομηνία λήξης δεν είναι έγκυρη",
    "img_Url_Invalid": "Μη έγκυρη διεύθυνση URL εικόνας",
    "time_Invalid": "Ο χρόνος δεν είναι έγκυρος",
    "time_And_Date_Invalid": "Εισαγάγετε μια έγκυρη ημερομηνία και ώρα",
    "time_Invalid_Bfr": "Επιλέξτε μια ώρα τουλάχιστον 5 λεπτά αργότερα από την τρέχουσα ώρα",
    "sod_Req": "Απαιτείται αποστολή την ημέρα",
    "add_Card": "Προσθήκη νέας κάρτας",
    "sm_D_Rep_Title": "Αναφορά παράδοσης SMS\/MMS",
    "send_Now_T": "Αποστολή τώρα",
    "schd_Now_T": "Προγραμματίστε SMS",
    "test_SMS_T": "Αποστολή δοκιμαστικού SMS",
    "save_AD_T": "Αποθήκευση ως πρόχειρο",
    "back_TE_T": "Επιστροφή στην Επεξεργασία",
    "reset_tex": "Επαναφορά",
    "update_tex": "Εκσυγχρονίζω",
    "dum_msg1": "Για να εμφανίσετε το όνομα πελάτη, χρησιμοποιήστε το %% customer_name %% στο πρότυπο",
    "dum_msg2": "Για να εμφανίσετε την ώρα κράτησης, χρησιμοποιήστε %% date_time %% στο πρότυπο",
    "dum_msg3": "Για να εμφανίσετε τον αριθμό του πίνακα, χρησιμοποιήστε %% table_no %% στο πρότυπο",
    "embedded_link": "Ενσωματωμένος σύνδεσμος",
    "img_title": "Εικών",
    "img_input_text": "Κείμενο:",
    "img_join_text": " για να μπείτε στη λίστα μας",
    "img_copy_text": "Αντίγραφο",
    "img_not_found": "Η δημιουργημένη εικόνα δεν βρέθηκε!!",
    "or_text": "Ή",
    "web_signup_image_content": "Με την υποβολή αυτής της φόρμας και την εγγραφή σας μέσω κειμένου, συναινείτε να λαμβάνετε μηνύματα κειμένου μάρκετινγκ (όπως κωδικούς προσφοράς και υπενθυμίσεις). Ενδέχεται να ισχύουν χρεώσεις μηνυμάτων και δεδομένων. Η συχνότητα των μηνυμάτων ποικίλλει. Μπορείτε να καταργήσετε την εγγραφή σας ανά πάσα στιγμή απαντώντας STOP",
    "img_download_success": "Η λήψη της εικόνας έγινε με επιτυχία",
    "embedded_link_copied": "Ο ενσωματωμένος σύνδεσμος αντιγράφηκε με επιτυχία",
    "media_url_required": "Απαιτείται μέσο ή διεύθυνση url",
    "invalid_image_url": "Μη έγκυρη διεύθυνση URL εικόνας",
    "invalid_file": "Το αρχείο δεν είναι έγκυρο",
    "image_error_1mb": "Εισαγάγετε μια εικόνα μικρότερη από 1 MB",
    "image_error_2_5mb": "Εισαγάγετε μια εικόνα μικρότερη από 2,5 MB",
    "image_error_3mb": "Εισαγάγετε μια εικόνα μικρότερη από 3 MB",
    "change_title": "Αλλαγή",
    "drop_file_text": "Αποθέστε το αρχείο εδώ ή κάντε κλικ για μεταφόρτωση",
    "apply_text": "Εφαρμόζω",
    "search_here": "Αναζήτηση εδώ",
    "update_status": "Ενημέρωση κατάστασης",
    "reservation_deleted_permanently": "Αυτή η κράτηση θα διαγραφεί οριστικά.",
    "table_text": "Πίνακες",
    "add_table_button": "Προσθήκη νέου πίνακα",
    "add_table_text": "Προσθήκη πίνακα",
    "edit_table_text": "Επεξεργασία πίνακα",
    "table_delete_text": "Αυτός ο πίνακας θα διαγραφεί οριστικά.",
    "multiple_table_delete_text": "Αυτοί οι πίνακες θα διαγραφούν οριστικά.",
    "table_error_name": "Εισαγάγετε το όνομα του πίνακα",
    "table_error_invalid_name": "Εισαγάγετε έγκυρο όνομα πίνακα",
    "table_error_floor": "Επιλέξτε τον όροφο",
    "table_error_select": "Επιλέξτε τον πίνακα",
    "table_capacity_text": "Χωρητικότητα τραπεζιού",
    "capacity_text": "Ικανότητα",
    "table_occasion_text": "Βρείτε το τραπέζι σας για κάθε περίσταση",
    "table_name_text": "Όνομα πίνακα",
    "table_capacity_error": "Απαιτείται χωρητικότητα τραπεζιού",
    "cancel_text": "Ματαίωση",
    "submit_text": "Υποτάσσομαι",
    "select_valid_date": "Επιλέξτε έγκυρη ημερομηνία",
    "select_valid_time": "Επιλέξτε έγκυρη ώρα",
    "valid_contact_number": "Εισαγάγετε έγκυρο αριθμό επικοινωνίας",
    "date_req": "Απαιτείται ημερομηνία",
    "date_error_later_than_today": "Η ημερομηνία πρέπει να είναι μεταγενέστερη ή ίση με τη σημερινή ημερομηνία",
    "time_error_later_than_5_minutes": "Επιλέξτε ώρα τουλάχιστον 5 λεπτά αργότερα από την τρέχουσα ώρα",
    "number_person_req": "Απαιτείται αριθμός ατόμων",
    "contact_no_req": "Απαιτείται ο αριθμός επικοινωνίας",
    "contact_no": "Αριθμός Επικοινωνίας",
    "select_time": "Επιλέξτε Ώρα",
    "for_how_many_person": "Για πόσα άτομα;",
    "f_name": "Ονομα",
    "l_name": "Επώνυμο",
    "business_name": "Εμπορική επωνυμία",
    "business_no": "Αριθμός Επιχείρησης",
    "no_for_sign_in": "Αυτός ο αριθμός χρησιμοποιείται για σύνδεση",
    "business_email": "Email επιχείρησης",
    "notes_tex": "Σημειώσεις",
    "floor_deleted_permanently": "Αυτός ο όροφος θα διαγραφεί οριστικά.",
    "add_floor": "Προσθήκη ορόφου",
    "edit_floor": "Επεξεργασία ορόφου",
    "name_text": "Ονομα",
    "mobile_no": "Κινητό Αρ.",
    "person_text": "Πρόσωπο",
    "date_and_time": "Ημερομηνία & Ώρα",
    "actions_text": "Δράσεις",
    "extra_text": "Επιπλέον",
    "floor_name_req": "Απαιτείται όνομα ορόφου",
    "floor_name": "Όνομα ορόφου",
    "status_text": "Κατάσταση",
    "category_status_req": "Απαιτείται η κατάσταση κατηγορίας",
    "table_deleted_permanently": "Αυτός ο πίνακας θα διαγραφεί οριστικά.",
    "tables_deleted_permanently": "Αυτοί οι πίνακες θα διαγραφούν οριστικά.",
    "back_to_home": "Επιστροφή στο σπίτι",
    "link_copied_text": "Ο σύνδεσμος αντιγράφηκε στο πρόχειρο",
    "cust_dash_head_to_title": "να",
    "cust_dash_head_location_title": "Τοποθεσία",
    "select_location_title": "Επιλέξτε Τοποθεσία",
    "all_locations_label": "all_locations",
    "rep_customer_feedback_analytics": "😊 Παρακολουθήστε τα σχόλια των πελατών σας με αναλυτικά στοιχεία σε πραγματικό χρόνο",
    "rep_customer_txt": "Πελάτες",
    "rep_delete_title": "Αυτή η φήμη θα διαγραφεί οριστικά.",
    "rep_qr_def_txt": "Σαρώστε τον κωδικό QR και μοιραστείτε τα πολύτιμα σχόλιά σας μαζί μας σχετικά με την εμπειρία σας.",
    "delete_title": "Διαγράφω",
    "user_list_title": "Κατάλογος πελατών",
    "os_nfc_txt": "Κάρτες NFC",
    "os_sign_holder_txt": "Βάση στήριξης πινακίδας",
    "os_store_Sign_holder_txt": "Κάτοχοι πινακίδων καταστήματος",
    "StaT": "Στατιστική",
    "AllgT": "Όλες οι Ομάδες",
    "FavT": "Ευνοούμενος",
    "MostActT": "Πιο ενεργός",
    "grT": "Όνομα ομάδας",
    "keywT": "Λέξεις-κλειδιά",
    "exSubT": "Μήνυμα για υπάρχοντα συνδρομητή",
    "ArchT": "Αρχειοθετήθηκε",
    "gNotiSms": "Ειδοποιήστε με για κάθε νέα επαφή μέσω SMS",
    "gNotiEmail": "Ειδοποιήστε με για κάθε νέα επαφή μέσω email",
    "reqGName": "Απαιτείται όνομα ομάδας",
    "validGN": "Εισαγάγετε έγκυρο όνομα ομάδας",
    "valid_phone_no": "Εισαγάγετε έγκυρο αριθμό τηλεφώνου",
    "phone_no_req": "Απαιτείται αριθμός τηλεφώνου",
    "required_message_text": "Απαιτείται μήνυμα",
    "required_discount_text": "Απαιτείται έκπτωση",
    "less_than_discount": "Το ποσό της έκπτωσης πρέπει να είναι μικρότερο από 5000",
    "discount_percentage_invalid": "Το ποσοστό έκπτωσης πρέπει να είναι μικρότερο ή ίσο με 100",
    "discount_type_req": "Απαιτείται τύπος έκπτωσης",
    "discount_text_req": "Απαιτείται κείμενο κουπονιού",
    "reqContactNo": "Απαιτείται ο αριθμός επικοινωνίας",
    "reqMsgNT": "Απαιτείται τίτλος καμπάνιας",
    "reqLinkT": "Απαιτείται σύνδεσμος",
    "delMGT": "Αυτές οι ομάδες θα διαγραφούν οριστικά.",
    "defMemMsg": "Πληκτρολογήστε το μήνυμά σας εδώ",
    "add_cust_to_grp_title": "Προσθήκη πελάτη στην ομάδα",
    "group_title": "Ομάδες",
    "group_create_title": "Δημιουργία ομάδας",
    "group_name_txt": "Όνομα ομάδας",
    "group_table_keyword_title": "Λέξη-κλειδί",
    "actions_title": "Δράσεις",
    "clients_title": "Πελάτες",
    "send_title": "Στέλνω",
    "qr_title": "QR",
    "delete_group_txt": "Αυτή η ομάδα θα διαγραφεί οριστικά.",
    "delete_groups_txt": "Αυτές οι ομάδες θα διαγραφούν οριστικά.",
    "delete_client_title": "Αυτός ο πελάτης θα διαγραφεί οριστικά.",
    "delete_clients_title": "Αυτοί οι πελάτες θα διαγραφούν οριστικά.",
    "delete_multiple_title": "Πολλαπλή Διαγραφή",
    "wel_sms_mms": "Αποστολή SMS\/MMS καλωσορίσματος;",
    "key_words_title": "Λέξεις-κλειδιά",
    "srch_plch_txt": "Αναζήτηση εδώ",
    "req_location_id_title": "Η τοποθεσία είναι υποχρεωτική",
    "create_text": "Δημιουργώ",
    "view_text": "Θέα",
    "immediately": "Αμέσως",
    "business_name_req": "Απαιτείται επωνυμία επιχείρησης",
    "business_no_req": "Απαιτείται αριθμός επιχείρησης",
    "valid_business_name": "Εισαγάγετε έγκυρο όνομα επιχείρησης",
    "valid_business_no": "Εισαγάγετε έγκυρο αριθμό επιχείρησης",
    "address_req": "Απαιτείται διεύθυνση",
    "valid_address": "Επιλέξτε έγκυρη διεύθυνση",
    "time_zone_req": "Απαιτείται ζώνη ώρας",
    "image_req": "Εισαγάγετε εικόνα μικρότερη από 2,5 mb",
    "valid_file": "Το αρχείο δεν είναι έγκυρο.",
    "logo": "Λογότυπο",
    "time_zone": "Ζώνη ώρας",
    "save": "Εκτός",
    "account_type_req": "Απαιτείται τύπος λογαριασμού",
    "gst_no_req": "Απαιτείται αριθμός GST",
    "gst_no_valid": "Εισαγάγετε έγκυρο αριθμό GST",
    "set_up_payments": "Ρύθμιση πληρωμών",
    "billing_details": "Εισαγάγετε τα στοιχεία χρέωσής σας",
    "billing_details_desc": "Τα στοιχεία χρέωσής σας θα εμφανίζονται στο μηνιαίο τιμολόγιο από το menuonline.",
    "account_type": "Τύπος λογαριασμού",
    "select_account_type": "Επιλέξτε τύπο λογαριασμού",
    "gst_no": "Αριθμός GST",
    "transaction_details": "Στοιχεία συναλλαγής",
    "payment_method": "Τρόπος πληρωμής",
    "billing_period": "Περίοδος χρέωσης",
    "paid_by": "Πληρώθηκε από",
    "download": "Λήψη",
    "pay_now": "Πληρώστε τώρα",
    "pull_down_refresh": "Τραβήξτε προς τα κάτω για ανανέωση",
    "release_refresh": "Αφήστε για ανανέωση",
    "billing_details_text": "Στοιχεία χρέωσης",
    "payment_methods": "Τρόποι Πληρωμής",
    "invoice": "Τιμολόγιο",
    "invoice_to": "Τιμολόγιο προς:",
    "issue_date": "Ημερομηνία Έκδοσης",
    "due_date": "Ημερομηνία λήξης",
    "amount_due": "Οφειλόμενο Ποσό",
    "charges": "Ταρίφα",
    "sub_total_capitalize": "Υπο Σύνολο",
    "hst": "HST",
    "grand_total": "Μεγάλο Σύνολο",
    "invoice_generated_on": "Δημιουργήθηκε τιμολόγιο στις",
    "contact_us": "Επικοινωνήστε μαζί μας",
    "invoice_issue": "Εάν έχετε εξετάσει το λογαριασμό σας και εξακολουθείτε να έχετε ερωτήσεις",
    "call": "Κλήση",
    "send_sms": "Αποστολή SMS",
    "payment_success": "Η πληρωμή ολοκληρώθηκε με επιτυχία",
    "edit_credit_card": "Επεξεργασία πιστωτικής κάρτας",
    "add_credit_card": "Προσθήκη πιστωτικής κάρτας",
    "modify_card_info": "Τροποποιήστε τα στοιχεία της κάρτας σας",
    "enter_card_info": "Εισαγάγετε τα στοιχεία της κάρτας σας",
    "account_no_req": "Απαιτείται αριθμός λογαριασμού",
    "card_name_req": "Το όνομα στην κάρτα είναι υποχρεωτικό",
    "expiry_date_req": "Απαιτείται ημερομηνία λήξης",
    "valid_expiry_date": "Εισαγάγετε έγκυρη ημερομηνία λήξης",
    "valid_cvv": "Εισαγάγετε έγκυρο cvv",
    "cvv_req": "Απαιτείται CVV",
    "card_no": "Αριθμός κάρτας",
    "name_of_card": "Όνομα στην κάρτα",
    "expiry": "Λήξη",
    "mm_text": "ΜΜ\/ΕΕ",
    "cvv": "CVV",
    "set_as_default": "Ορισμός ως προεπιλογή",
    "add_new_card": "Προσθήκη νέας κάρτας",
    "all_credit_card": "Όλες οι πιστωτικές σας κάρτες",
    "fastest_checkout": "Το πιο γρήγορο check out με ασφαλή και αξιόπιστη πλατφόρμα, Εμπιστευμένο από μεγάλο όμιλο επιχειρήσεων",
    "coupon_button_req": "Απαιτείται κείμενο κουμπιού κουπονιού",
    "max19_characters_allowed": "Επιτρέπονται το πολύ 19 χαρακτήρες",
    "fine_print_text_req": "Απαιτείται κείμενο με ψιλά γράμματα",
    "clients_req": "Επιλέξτε τουλάχιστον έναν πελάτη",
    "client_groups": "Ομάδες πελατών",
    "day_text": "Ημέρα",
    "valid_days": "Η τιμή ημέρας είναι πολύ μεγάλη, εισάγετε έγκυρες ημέρες",
    "hours_text": "Ώρες",
    "enter_valid_hours": "Η τιμή των ωρών είναι πολύ μεγάλη, εισάγετε έγκυρες ώρες",
    "enter_valid_min": "Η τιμή των λεπτών είναι πολύ μεγάλη, εισάγετε έγκυρα λεπτά",
    "clients_with_connection_required": "Απαιτούνται πελάτες με σύνδεση στο τελευταίο",
    "connection_required": "Απαιτείται σύνδεση",
    "connection_value_too_large": "Η τιμή των συνδέσεων είναι πολύ μεγάλη, εισάγετε έγκυρη σύνδεση",
    "minutes_required": "Απαιτούνται λεπτά",
    "clients_with_at_least_connection_required": "Απαιτούνται πελάτες με τουλάχιστον σύνδεση",
    "last_minutes_req": "Με τα τελευταία λεπτά απαιτείται",
    "clients_not_returned_required": "Επιβάλλεται όποιος δεν επέστρεψε τα τελευταία λεπτά",
    "not_return_in_last_minutes_invalid": "Η μη επιστροφή στα τελευταία λεπτά πρέπει να είναι μικρότερη από τα τελευταία λεπτά.",
    "customerGroups": "Ομάδες Πελατών",
    "select_customer_group": "Επιλέξτε την ομάδα πελατών",
    "location_required": "Απαιτείται τοποθεσία",
    "start_date_required": "Απαιτείται ημερομηνία έναρξης",
    "start_date_invalid": "Η ημερομηνία έναρξης δεν είναι έγκυρη",
    "start_date_invalid_error": "Η ημερομηνία έναρξης πρέπει να είναι μεγαλύτερη τότε ή ίση με τη σημερινή",
    "recurring": "Επαναλαμβανόμενο",
    "end_date_required": "Απαιτείται ημερομηνία λήξης",
    "end_date_invalid": "Η ημερομηνία λήξης δεν είναι έγκυρη",
    "end_date_invalid_error": "Η ημερομηνία λήξης πρέπει να είναι μεταγενέστερη από την ημερομηνία έναρξης",
    "time_invalid": "Ο χρόνος δεν είναι έγκυρος",
    "monthly_text": "Μηνιαίος",
    "send_day_req": "Απαιτείται αποστολή την ημέρα",
    "loyal_clients": "Πιστοί Πελάτες",
    "recent_clients": "Πρόσφατοι πελάτες",
    "lapsed_clients": "Πελάτες που έχουν λήξει",
    "clients_connection_date": "Πελάτες κατά Ημερομηνία Σύνδεσης",
    "scheduled_text": "Προγραμματισμένος",
    "weekly": "Εβδομαδιαίος",
    "selected_clients_not_subscriber": "Οι επιλεγμένοι πελάτες δεν είναι συνδρομητές.",
    "message_preview_title": "Προεπισκόπηση μηνύματος",
    "coupon_preview_title": "Προεπισκόπηση κουπονιού",
    "form_text": "Μορφή",
    "preview_text": "Πρεμιέρα",
    "next_text": "Επόμενος",
    "send_test_SMS": "Αποστολή δοκιμαστικού SMS",
    "save_draft": "Αποθήκευση ως πρόχειρο",
    "back_to_edit": "Επιστροφή στην Επεξεργασία",
    "select_payment_method": "Επιλέξτε τον τρόπο πληρωμής",
    "schedule_SMS": "Προγραμματίστε SMS",
    "send_now": "Αποστολή τώρα",
    "get_more_ratings": "Λάβετε περισσότερες αξιολογήσεις",
    "overview": "Επισκόπηση",
    "reset_campaign": "Επαναφορά καμπάνιας",
    "permissions": "Άδειες",
    "location_name": "Όνομα τοποθεσίας",
    "phone_no": "Αριθμός τηλεφώνου",
    "location_email_address": "Τοποθεσία Διεύθυνση Email",
    "located_business": "Πού βρίσκεται η επιχείρησή σας;",
    "business_logo": "Λογότυπο επιχείρησης",
    "congratulations": "Συγχαρητήρια",
    "almost_done": "Έχεις σχεδόν τελειώσει",
    "publish": "Δημοσιεύω",
    "about_your_business": "Σχετικά με την Επιχείρησή σας",
    "add_your_location": "Προσθέστε την τοποθεσία σας",
    "publish_location": "Τοποθεσία δημοσίευσης",
    "location_name_req": "Απαιτείται το όνομα τοποθεσίας",
    "valid_location_name": "Εισαγάγετε έγκυρο όνομα τοποθεσίας",
    "business_logo_req": "Απαιτείται το λογότυπο της επιχείρησης",
    "please_accept_terms": "Παρακαλούμε αποδεχτείτε τους όρους και τις προϋποθέσεις",
    "add_new_location": "Προσθήκη νέας τοποθεσίας",
    "edit_location": "Επεξεργασία τοποθεσίας",
    "add_location": "Προσθήκη τοποθεσίας",
    "existing_msg_subscriber_txt": "Μήνυμα για υπάρχοντα συνδρομητή",
    "msg_capitalize_txt": "Μήνυμα",
    "group_noti_sms": "Ειδοποιήστε με για κάθε νέα επαφή μέσω SMS",
    "group_noti_email": "Ειδοποιήστε με για κάθε νέα επαφή μέσω email",
    "group_member_msg": "Φαίνεται ότι είσαι ήδη μέλος της παρέας μας!!",
    "group_mend_msg": "ΣΤΑΜΑΤΗΣΤΕ για να τελειώσετε. ΒΟΗΘΕΙΑ για βοήθεια. Ενδέχεται να ισχύουν χρεώσεις SMS και δεδομένων",
    "Disclaimer_title": "Κείμενο αποποίησης ευθύνης: ",
    "group_def_msg": "Είμαστε online τώρα! Δείτε το μενού υπηρεσιών μας και κλείστε το επόμενο ραντεβού σας σήμερα.",
    "required_msg_txt": "Απαιτείται μήνυμα",
    "required_Key_txt": "Απαιτούνται λέξεις-κλειδιά",
    "required_mem_msg": "Απαιτείται μήνυμα συνδρομητή",
    "client_list_title": "Λίστα πελατών",
    "add_contact_txt": "Προσθήκη επαφής",
    "delete_all_clients_txt": "Διαγραφή όλων των πελατών",
    "delete_all_clients_msg": "Είστε βέβαιοι ότι θέλετε να διαγράψετε όλους τους πελάτες; Δεν μπορούν να ανακτηθούν.",
    "delete_all_txt": "Διαγραφή όλων",
    "timeline_title": "Γραμμή χρόνου",
    "unsubscribe_title": "Απεγγραφή",
    "subscribe_title": "Συνεισφέρω",
    "unsubscribe_confirm_msg": "Θα θέλατε να επισημάνετε αυτόν τον πελάτη ως μη συνδρομητή;",
    "subscribe_confirm_msg": "Θα θέλατε να επισημάνετε αυτόν τον πελάτη ως συνδρομητή;",
    "no_title": "Οχι",
    "yes_title": "Ναί",
    "client_name_title": "Όνομα πελάτη",
    "source_title": "Πηγή",
    "contact_file_Req": "Απαιτείται αρχείο επαφών",
    "req_title": "Υποχρεούμαι",
    "opt_in_req": "Οι εισαγόμενες επαφές πρέπει να είναι 100% opt-in",
    "image_invalid_error": "Το αρχείο δεν είναι έγκυρο.",
    "import_contacts_msg": "Εισαγωγή επαφών",
    "csv_upload_title": "Αρχείο Επαφών CSV για μεταφόρτωση *",
    "csv_file_desc": "Μεταφορτώστε μόνο αρχείο csv - στήλες αρχείου csv - Όνομα, Επώνυμο, Αναγνωριστικό email, Αριθμός κινητού, Φύλο, DOB(MM\/ΗΗ\/ΕΕΕΕ) - χωρίς κενά, παύλες ή παρενθέσεις στον αριθμό κινητού",
    "to_download_title": "Για να κατεβάσετε το αρχείο επίδειξης κάντε κλικ εδώ",
    "contains_header_title": "Ναι, αυτό το αρχείο περιέχει κεφαλίδες",
    "opt_in_100_txt": "Ναι, αυτές οι εισαγόμενες επαφές είναι 100% opt-in",
    "DisT": "Κείμενο αποποίησης ευθύνης: ",
    "gMendMsg": "ΣΤΑΜΑΤΗΣΤΕ για να τελειώσετε. ΒΟΗΘΕΙΑ για βοήθεια. Ενδέχεται να ισχύουν χρεώσεις SMS και δεδομένων",
    "reqKeyT": "Απαιτούνται λέξεις-κλειδιά",
    "reqMemMsg": "Απαιτείται μήνυμα συνδρομητή",
    "reqMsgT": "Απαιτείται μήνυμα",
    "gMemberMsg": "Φαίνεται ότι είσαι ήδη μέλος της παρέας μας!!",
    "gdefMsg": "Είμαστε online τώρα! Δείτε το μενού υπηρεσιών μας και κλείστε το επόμενο ραντεβού σας σήμερα.",
    "next_title": "Επόμενος",
    "male_title": "Αρσενικός",
    "male_val": "αρσενικός",
    "female_title": "Θηλυκός",
    "female_val": "θηλυκός",
    "others_txt": "Άλλοι",
    "others_val": "άλλοι",
    "validBirthdate": "Εισαγάγετε έγκυρη ημερομηνία γέννησης",
    "valid_phone_no_title": "Εισαγάγετε έγκυρο αριθμό τηλεφώνου",
    "required_phone_no_title": "Απαιτείται αριθμός τηλεφώνου",
    "add_new_client_txt": "Προσθήκη νέου πελάτη",
    "update_client_txt": "Ενημέρωση πελάτη",
    "phone_num_text": "Αριθμός τηλεφώνου",
    "dob_title": "DOB",
    "select_gender_title": "Επιλέξτε Φύλο",
    "timelineTitle": "Γραμμή χρόνου",
    "confirm_location": "Επιβεβαίωση τοποθεσίας",
    "feedback_title": "Ανατροφοδότηση",
    "rep_question_1": "Ήταν η επιχείρηση καθαρή;",
    "rep_que_2": "Ήταν καλό το φαγητό σας;",
    "rep_que_3": "Ήταν φιλική η υπηρεσία μας;",
    "rep_que_4": "Ήταν γρήγορη η υπηρεσία μας;",
    "business_location_req_title": "Απαιτείται τοποθεσία επιχείρησης",
    "valid_location_err_txt": "Επιλέξτε έγκυρη διεύθυνση",
    "rep_management_title": "Λάβετε περισσότερες κριτικές Διαχείριση",
    "rep_about_desc": "Η φήμη σάς βοηθά να αυξήσετε την αξιολόγηση της Google ζητώντας από τους πελάτες σας σχόλια",
    "preview_title": "Πρεμιέρα",
    "rep_preview_txt": "Προεπισκόπηση φήμης",
    "back_title": "Πίσω",
    "fine_print_txt": "Παρακαλώ εξαργυρώστε με ταμείο",
    "redeem_me_title": "Λύτρωσέ με",
    "rep_nxt_visit_txt": "Στην επόμενη επίσκεψή σας",
    "device_type": "Τύπος συσκευής που χρησιμοποιείται",
    "connection_method": "Μέθοδος Σύνδεσης",
    "peak_days": "Ημέρες Αιχμής",
    "peak_hours": "Ωρες αιχμής",
    "guest_by_day": "Επισκέπτης την ημέρα",
    "guest_visit": "Επίσκεψη επισκέπτη",
    "connection": "Σύνδεση",
    "connection_duration": "Διάρκεια σύνδεσης",
    "guest_visit_1": "1 φορά",
    "guest_visit_2": "2 φορές",
    "guest_visit_3_5": "3-5 φορές",
    "guest_visit_6_10": "6-10 φορές",
    "guest_visit_11_25": "11-25 φορές",
    "guest_visit_26_100": "26-100 φορές",
    "guest_visit_100_plus": "100+ φορές",
    "device_android_total": "Σύνολο Android",
    "device_android": "Android",
    "device_desktop": "Επιτραπέζιος υπολογιστής",
    "device_ios": "Ίος",
    "device_ios_total": "Σύνολο Ίος",
    "device_desktop_total": "Total Desktop",
    "connection_duration_10": "<=10 λεπτά",
    "connection_duration_20": "11-20 Λεπ",
    "connection_duration_30": "21-30 Λεπ",
    "connection_duration_40": "31-45 Λεπ",
    "connection_duration_60": "46-60 Min",
    "connection_method_email": "E-mail",
    "connection_method_sms": "SMS",
    "connection_method_google": "Google",
    "connection_method_facebook": "Facebook",
    "age_category_1": "<18",
    "age_category_2": "18-24",
    "age_category_3": "25-34",
    "age_category_4": "35-44",
    "age_category_5": "45-54",
    "age_category_6": "55+",
    "all_guest_txt": "Όλοι οι επισκέπτες",
    "new_Guest_txt": "Νέος Επισκέπτης",
    "connections_txt": "Συνδέσεις",
    "hotspot": "Hotspot",
    "hotspot_list": "Λίστα hotspot",
    "add_new_hotspot": "Προσθήκη νέου σημείου πρόσβασης",
    "hotspot_information": "Πληροφορίες Hotspot",
    "edit_details_button": "Επεξεργασία λεπτομερειών",
    "wifi_info_message": "Συνδεθείτε και απολαύστε δωρεάν WiFi",
    "connection_message": "Ωραία, έχετε συνδεθεί ",
    "connection_message_suffix": " WiFi",
    "wifi": "WiFi",
    "login_to_access": "Συνδεθείτε για να αποκτήσετε πρόσβαση",
    "verification_code": "Κωδικός επαλήθευσης",
    "verification_code_message": "Πληκτρολογήστε τον κωδικό επαλήθευσης που στάλθηκε στο ",
    "wifi_user_email": "adamo@gmail.com",
    "wifi_label": "Wifi",
    "your_name": "Πώς σε λένε παρεμπιπτόντως;",
    "your_birthdate": "Μπορείς να μας πεις ποια είναι η ημερομηνία γέννησής σου;",
    "request_guest_wifi_name": "Εισαγάγετε το όνομα Wi-Fi επισκέπτη",
    "request_device_key": "Εισαγάγετε το κλειδί συσκευής",
    "request_maximum_internet_access_length": "Επιλέξτε Μέγιστο μήκος πρόσβασης στο Διαδίκτυο",
    "mac_address": "Διεύθυνση MAC",
    "device_port": "Θύρα συσκευής",
    "hardware": "Μηχανήματα υπολογιστών",
    "current_uptime": "Τρέχον χρόνο λειτουργίας",
    "nearby_devices": "Κοντινές συσκευές",
    "firmware": "Υλικολογισμικό",
    "who_are_you": "Ποιος είσαι;",
    "where_to_contact_you": "Πού ερχόμαστε σε επαφή μαζί σας;",
    "your_area_code": "Ποιος είναι ο κωδικός περιοχής σας;",
    "connected": "Συνδεδεμένος",
    "delete_hotspot_message": "Αυτό το hotspot θα διαγραφεί οριστικά.",
    "delete_multiple_hotspots_message": "Αυτά τα hotspot θα διαγραφούν οριστικά.",
    "speed_error": "Η ταχύτητα πρέπει να είναι τουλάχιστον 0,01",
    "speed_max_error": "Εισαγάγετε μια τιμή έως και 1024 για απεριόριστη ταχύτητα ή επιλέξτε μια χαμηλότερη τιμή εντός του υποστηριζόμενου εύρους",
    "device_ssid": "SSID συσκευής",
    "device_ssid_two": "SSID συσκευής δύο",
    "device_ssid_two_wpa": "Συσκευή SSID Two WPA",
    "device_key": "Κλειδί συσκευής",
    "select_location": "Επιλέξτε Τοποθεσία",
    "select_maximum_internet_access_length": "Επιλέξτε Μέγιστο μήκος πρόσβασης στο Διαδίκτυο",
    "download_speed": "Ταχύτητα λήψης",
    "upload_speed": "Ταχύτητα μεταφόρτωσης",
    "network_length_1": "15 λεπτά",
    "network_length_2": "30 Λεπτά",
    "network_length_3": "45 λεπτά",
    "network_length_4": "1 ώρα",
    "network_length_5": "2 Ώρες",
    "network_length_6": "4 Ώρες",
    "network_length_7": "6 Ώρες",
    "network_length_8": "8 Ώρες",
    "network_length_9": "10 Ώρες",
    "network_length_10": "12 Ώρες",
    "employee_wifi_name": "Όνομα Wi-Fi υπαλλήλου",
    "employee_wifi_password": "Κωδικός πρόσβασης Wifi υπαλλήλου",
    "guest_wifi_name": "Όνομα Wi-Fi επισκέπτη",
    "menu_other_products_txt": "Άλλα προϊόντα | Μενού Online",
    "menu_home_text": "Αρχική σελίδα | Μενού Online",
    "whatsapp_title": "Whatsapp",
    "select_campaign_type": "Επιλέξτε έναν τύπο καμπάνιας",
    "select_readymade_templates": "Επιλέξτε από έτοιμα πρότυπα ή φτιάξτε τα δικά σας",
    "campaign_title_required": "Απαιτείται τίτλος καμπάνιας",
    "type_here": "Πληκτρολογήστε εδώ...",
    "location_permission_req": "Απαιτείται άδεια τοποθεσίας",
    "platform_permission_req": "Απαιτείται άδεια πλατφόρμας",
    "profile_picture": "Φωτογραφία προφίλ",
    "click_to_upload": "Κάντε κλικ για μεταφόρτωση",
    "location_permission": "Άδεια τοποθεσίας",
    "pin": "Καρφίτσα",
    "platform_permission": "Άδεια πλατφόρμας",
    "set_pin": "Ορισμός PIN",
    "list_of_users": "Κατάλογος Χρηστών",
    "create_user": "Δημιουργία χρήστη",
    "terms_and_condition_req": "Απαιτούνται όροι και προϋποθέσεις",
    "terms_and_conditions": "Όροι και Προϋποθέσεις",
    "recommended": "ΣΥΝΙΣΤΕΙΤΑΙ",
    "mo": "\/μήνα",
    "unsubscribe": "Απεγγραφή",
    "cancel_subscription": "Είστε βέβαιοι ότι θέλετε να ακυρώσετε τη συνδρομή;",
    "upgrade": "Αναβαθμίζω",
    "plan_upgraded_successfully": "Το σχέδιο αναβαθμίστηκε με επιτυχία!",
    "menu_online": "Μενού σε απευθείας σύνδεση",
    "support": "Υποστήριξη",
    "wifiC": "WIFI",
    "billing": "Χρέωση",
    "please_upgrade": "Κάντε αναβάθμιση για να απολαύσετε όλες τις λειτουργίες",
    "you_have_subscribed": "Έχετε εγγραφεί στο ",
    "plan": "σχέδιο",
    "of_text": "του",
    "days": "Μέρες",
    "remaining_days_until_plan": " Μέρες απομένουν έως ότου το σχέδιό σας απαιτεί ενημέρωση",
    "manage_your_screens": "Διαχειριστείτε τις οθόνες σας",
    "screens_you_save": "Οθόνες που έχετε",
    "add_more_screens": "Προσθήκη περισσότερων οθονών",
    "addition_screen": "Οθόνη προσθήκης",
    "per_screen": "$ ανά οθόνη",
    "per_box": "$ ανά κουτί",
    "shipping_changes": "Χρεώσεις αποστολής",
    "upto4_boxes": "έως 4 κουτιά ",
    "charge_now": "Θα πάρετε χρέωση τώρα",
    "updated_reoccuring": "Η ενημερωμένη επανάληψη θα είναι",
    "current_reoccuring": "Τρέχουσα επανεμφάνιση: ",
    "no_text": "Οχι",
    "android_box": "Android Box",
    "old_password_req": "Απαιτείται παλιός κωδικός πρόσβασης",
    "new_password_req": "Απαιτείται νέος κωδικός πρόσβασης",
    "confirm_password_req": "Απαιτείται επιβεβαίωση κωδικού πρόσβασης",
    "password_do_not_match": "Οι κωδικοί πρόσβασης δεν ταιριάζουν",
    "old_password": "Παλιός κωδικός πρόσβασης",
    "new_password": "Νέος κωδικός πρόσβασης",
    "confirm_password": "Επιβεβαίωση κωδικού πρόσβασης",
    "copy_working_hours": "Αντιγραφή αυτών των ωρών εργασίας σε όλες τις ημέρες;",
    "yes_copy": "Ναι, αντιγράψτε",
    "closed": "Κλειστό",
    "opening_time": "Ώρα έναρξης",
    "closing_time": "Ώρα κλεισίματος",
    "description": "Περιγραφή",
    "file_exist": "υπάρχει ήδη αρχείο, επιλέξτε άλλο αρχείο",
    "bussiness_images": "Επιχειρηματικές εικόνες",
    "display_info_on_market_place": "Θέλετε να εμφανίζονται παρακάτω πληροφορίες στο Marketplace σας;",
    "profile_ready": "Το προφίλ σας είναι έτοιμο!",
    "client_book_with_online": "Οι πελάτες μπορούν πλέον να κάνουν κράτηση μαζί σας online. Μοιραστείτε τον παρακάτω σύνδεσμο για να ξεκινήσετε.",
    "copy_link": "Αντιγραφή συνδέσμου",
    "see_your_profile": "Δείτε το προφίλ σας",
    "ok_got_it": "Εντάξει, κατάλαβα",
    "preview_profile": "Κάντε προεπισκόπηση του προφίλ σας και δείτε πώς θα ήταν.",
    "opening_hours": "Ώρες Λειτουργίας",
    "display_data": "Εμφάνιση δεδομένων",
    "manage_profile": "Διαχείριση προφίλ",
    "phone_req": "Απαιτείται τηλέφωνο",
    "market_place_img_req": "Απαιτούνται εικόνες αγοράς",
    "add_new_market_place": "Προσθήκη Νέας Αγοράς",
    "edit_market_place": "Επεξεργασία MarketPlace",
    "no_reviews_yet": "Δεν υπάρχουν ακόμη κριτικές",
    "good": "Καλός",
    "average": "Μέσος",
    "add_marketPlace": "Προσθήκη MarketPlace",
    "all_title": "Ολοι",
    "included_title": "Συμπεριλαμβανομένος",
    "excluded_title": "Εξαιρείται",
    "clients_subscribed": "Πελάτες εγγεγραμμένοι στο τελευταίο",
    "clients_groups": "Ομάδες Πελατών",
    "customer_groups": "Ομάδες Πελατών",
    "audience_title": "Ακροατήριο",
    "client_gt5": "που δεν γύρισε στο τελευταίο",
    "select_all": "Επιλέξτε Όλα",
    "modify": "Τροποποιώ",
    "campaign_title": "Τίτλος καμπάνιας",
    "msg_type": "Τύπος μηνύματος",
    "enter_discount": "Εισαγάγετε την έκπτωση",
    "discount_type": "Τύπος έκπτωσης",
    "coupon_text": "Κείμενο κουπονιού",
    "enter_coupon_text": "Εισαγάγετε κείμενο κουπονιού",
    "coupon_button_text": "Κείμενο κουμπιού κουπονιού",
    "enter_coupon_button_text": "Εισαγάγετε το κείμενο του κουμπιού κουπονιού",
    "fine_prin": "Ψιλά γράμματα",
    "enter_fine_prin": "Εισαγάγετε κείμενο με ψιλά γράμματα",
    "campaign_dec": "Μπορείτε να τροποποιήσετε την καμπάνια 30 λεπτά πριν από την ώρα έναρξης της. Οι χρεώσεις για την καμπάνια SMS θα πραγματοποιηθούν 30 λεπτά πριν από την ώρα έναρξης της καμπάνιας.",
    "blast_text_message_dec": "Το εκρηκτικό σας μήνυμα κειμένου είναι έτοιμο. Επιλέξτε τον τρόπο πληρωμής σας και στείλτε τα μηνύματά σας.",
    "payment_completed": " Η πληρωμή θα ολοκληρωθεί στις ",
    "total_cost": "Συνολικό Κόστος",
    "close_title": "Κοντά",
    "friday": "Παρασκευή",
    "saturday": "Σάββατο",
    "sunday": "Κυριακή",
    "thursday": "Πέμπτη",
    "tuesday": "Τρίτη",
    "wednesday": "Τετάρτη",
    "port_txt": "Λιμάνι",
    "today_title": "Σήμερα",
    "yesterday_title": "Εχθές",
    "last_30_days_txt": "Τελευταίες 30 ημέρες",
    "this_month_txt": "Αυτόν τον Μήνα",
    "last_month_txt": "τον περασμένο μήνα",
    "valid_date_title": "Επιλέξτε έγκυρη ημερομηνία",
    "valid_business_name_txt": "Εισαγάγετε έγκυρο όνομα επιχείρησης",
    "req_bus_add_txt": "Απαιτείται διεύθυνση επιχείρησης",
    "req_domain_name_txt": "Απαιτείται όνομα τομέα",
    "basic_info_txt": "Βασικές πληροφορίες της επιχείρησής σας",
    "loyalty_qr_def_txt": "Σαρώστε τον κωδικό QR, εγγραφείτε στην κάρτα αφοσίωσης και απολαύστε ανταμοιβές.",
    "last_stamp_txt": "Τελευταίο γραμματόσημο",
    "collected_on_txt": "Συλλέγεται στις",
    "stamp_details_txt": "Στοιχεία σφραγίδας",
    "add_stamp_txt": "Προσθήκη σφραγίδας",
    "choose_brand_color_txt": "Επιλέξτε το χρώμα και τη γλώσσα της επωνυμίας σας 🎨",
    "change_anytime_txt": "Μπορείτε να το αλλάξετε ανά πάσα στιγμή",
    "like_to_get_txt": "Θα θέλατε να πάρετε",
    "your_next_visit_txt": "στην επόμενη επίσκεψή σας;",
    "time_to_time_get_offers": "Θα θέλατε να λαμβάνετε μεγάλες προσφορές και προωθήσεις κατά καιρούς;",
    "respond_back_to_you": "Θα θέλατε κάποιος να σας απαντήσει;",
    "input_serve_better": "Η συμβολή σας θα βοηθήσει την ομάδα μας να σας εξυπηρετήσει καλύτερα!",
    "do_it_txt": "Ας το κάνουμε",
    "take_15_s": "Διαρκεί 15 δευτερόλεπτα",
    "rate_on_google_desc": "Θα κάνατε την καλοσύνη να μας αξιολογήσετε στο google;",
    "may_be_next_time": "Ισως την επόμενη φορά",
    "thank_you_txt": "Σας ευχαριστώ",
    "look_next_txt": "Ανυπομονούμε για την επόμενη επίσκεψή σας!",
    "deleteUserTitle": "Αυτός ο χρήστης θα διαγραφεί οριστικά.",
    "deleteMUserTitle": "Αυτοί οι χρήστες θα διαγραφούν οριστικά.",
    "change_pin": "Αλλαγή Pin",
    "area_code": "Ποιος είναι ο κωδικός περιοχής σας; ",
    "add_menu": "Προσθήκη μενού",
    "menu_name": "Όνομα μενού",
    "add_menu_placeholder": "Πίτσα, Burger, Baverages κ.λπ",
    "enable_data_Collection": "Ενεργοποίηση συλλογής δεδομένων",
    "add_new_menu": "Προσθήκη νέου μενού",
    "rename_menu": "Μετονομασία Μενού",
    "preview": "Πρεμιέρα",
    "generate_qr": "Δημιουργήθηκε QR",
    "edit_menu": "Μενού Επεξεργασίας",
    "remove_menu": "Κατάργηση Μενού",
    "menu_delete_msg": "Αυτό το μενού θα διαγραφεί οριστικά",
    "menus_delete_msg": "Αυτά τα μενού θα διαγραφούν οριστικά",
    "view_menu_dialoage_msg": "Θα θέλατε να πάρετε ένα δώρο για τα γενέθλιά σας;",
    "skip": "Παραλείπω",
    "cliam_your_gift": "Διεκδικήστε το δώρο σας",
    "collect_form": "Φόρμα συλλογής",
    "enter_first_name": "Εισαγάγετε Όνομα",
    "enter_last_name": "Εισαγάγετε Επώνυμο",
    "enter_email": "Εισαγάγετε E-mail",
    "enter_dob": "Εισαγάγετε Ημερομηνία Γέννησης",
    "enter_number": "Εισαγάγετε τον αριθμό",
    "select_gender": "Επιλέξτε Φύλο",
    "congratulations_desc": "🎉 Συγχαρητήρια! Το αίτημα αξίωσης δώρου υποβλήθηκε με επιτυχία. Η ομάδα μας θα επικοινωνήσει μαζί σας σύντομα. 🎉",
    "male_heading": "Αρσενικός",
    "male_text": "αρσενικός",
    "female_heading": "Θηλυκός",
    "female_text": "θηλυκός",
    "others_heading": "Άλλοι",
    "other_text": "άλλοι",
    "BirthD": "Ημερομηνία γέννησης",
    "GenderT": "Γένος",
    "EmailT": "E-mail",
    "dobT": "DOB",
    "capitalize_menu": "Μενού",
    "select_menu": "Επιλογή μενού",
    "manage_variant": "Διαχείριση παραλλαγής",
    "add_products": "Προσθήκη προϊόντων",
    "add_category": "Προσθήκη κατηγορίας",
    "category_delete": "Αυτή η κατηγορία θα διαγραφεί οριστικά",
    "variation_delete": "Αυτή η παραλλαγή θα διαγραφεί από το Προϊόν.",
    "product_delete": "Αυτό το προϊόν θα διαγραφεί οριστικά.",
    "categories_delete": "Αυτές οι κατηγορίες θα διαγραφούν οριστικά.",
    "products_delete": "Αυτά τα προϊόντα θα διαγραφούν οριστικά.",
    "category": "ΚΑΤΗΓΟΡΙΑ",
    "price": "ΤΙΜΗ",
    "food_product_placeholder": "Πίτσα, Burger, Baverages κ.λπ",
    "active_title": "Ενεργός",
    "inActive_title": "Αδρανής",
    "status_capital": "ΚΑΤΑΣΤΑΣΗ",
    "cat_status_require": "Απαιτείται η κατάσταση κατηγορίας",
    "cat_name_require": "Απαιτείται όνομα κατηγορίας",
    "category_name": "Όνομα κατηγορίας",
    "status": "Κατάσταση",
    "lgDay1": "Δευτέρα",
    "lgDay2": "Τρίτη",
    "lgDay3": "Τετάρτη",
    "lgDay4": "Πέμπτη",
    "lgDay5": "Παρασκευή",
    "lgDay6": "Σάββατο",
    "lgDay7": "Κυριακή",
    "is_closed_title": "Κλειστό",
    "book_table_title": "Κάντε κράτηση για ένα τραπέζι",
    "emailErrSub": "Απαιτείται Θέμα email",
    "email_subject": "Θέμα email",
    "contactUsfrmTitleEmail": "Απάντηση στο email",
    "companyInfo": "Το Lorem Ipsum είναι lorem hrig, lorem ipsum είναι fraets. Lorem Ipsum είναι lorem hrig, lorem ipsum είναι fraets. Lorem ipsum είναι lorem hrig, lorem ipsum είναι fraets.",
    "footerSTitle1": "Σχετικά με το Menuonline",
    "footerSTitle1Lnk2": "Χαρακτηριστικά",
    "footerSTitle1Lnk3": "Ιστολόγιο",
    "footerSTitle2": "Νομικός",
    "footerSTitle2Lnk1": "Όροι και Προϋποθέσεις",
    "footerSTitle2Lnk2": "Πολιτική Απορρήτου",
    "footerSTitle3": "Για Επιχειρήσεις",
    "footerSTitle3Lnk1": "Για Συνεργάτες",
    "footerSTitle3Lnk2": "Τιμολόγηση",
    "footerSTitle3Lnk3": "Υποστήριξη για Συνεργάτες",
    "footerCopyRithgTxt": "  Menuonline ή οι θυγατρικές του",
    "homeTitle": "Σπίτι",
    "contactTitle": "Επικοινωνήστε μαζί μας",
    "aboutTitle": "Σχετικά με εμάς",
    "homeMenuTitle": "Μενού",
    "bookTableTitle": "Κάντε κράτηση για ένα τραπέζι",
    "vDateT": "Επιλέξτε έγκυρη ημερομηνία",
    "reqDateT": "Απαιτείται ημερομηνία",
    "dateGtErr": "Η ημερομηνία πρέπει να είναι μεταγενέστερη ή ίση με τη σημερινή ημερομηνία",
    "timeInvalid": "Ο χρόνος δεν είναι έγκυρος",
    "reqTimeT": "Απαιτείται χρόνος",
    "timeInvalidBfr": "Επιλέξτε ώρα τουλάχιστον 5 λεπτά αργότερα από την τρέχουσα ώρα ",
    "PerReq": "Απαιτείται αριθμός ατόμων",
    "validcNoT": "Εισαγάγετε έγκυρο αριθμό επικοινωνίας",
    "reqCrT": "Απαιτείται νόμισμα",
    "reqPrT": "Απαιτείται τιμή",
    "reCateT": "Απαιτείται κατηγορία",
    "reqVrT": "Απαιτείται παραλλαγή",
    "reqVrVldT": "Εισαγάγετε έγκυρη παραλλαγή ",
    "validLNameT": "Εισαγάγετε έγκυρο επώνυμο",
    "sDateInValid": "Η ημερομηνία δεν είναι έγκυρη",
    "minmReq": "Απαιτείται ελάχιστο",
    "resLblDate": "Επιλέξτε Ημερομηνία",
    "resLblTime": "Επιλέξτε Ώρα",
    "perT": "Για Πόσα Άτομα;",
    "resLblEmail": "Δώστε το email σας;",
    "resLblNote": "Προσθέστε μια σημείωση κράτησης",
    "imageError1MB": "Εισαγάγετε μια εικόνα που είναι μικρότερη από 1 mb",
    "imageError": "Εισαγάγετε μια εικόνα μικρότερη από 2,5 mb",
    "imageError3Mb": "Εισαγάγετε εικόνα μικρότερη από 3 mb",
    "imageInVldError": "Το αρχείο δεν είναι έγκυρο.",
    "addProMT": "Μενού προϊόντος",
    "proT": "Όνομα προϊόντος",
    "reqProT": "Το όνομα προϊόντος είναι υποχρεωτικό",
    "proPT": "Τιμή προϊόντος",
    "reservationSuccTitle": "Τέλεια, η κράτησή σας ολοκληρώθηκε",
    "book": "Βιβλίο",
    "custzName": "Όνομα προσαρμογής",
    "always": "Πάντοτε",
    "proImgReq": "Απαιτείται εικόνα προϊόντος",
    "selCustmT": "Επιλέξτε Προσαρμογή ή δημιουργήστε νέο",
    "visStR": "Απαιτείται κατάσταση ορατότητας",
    "avlblScR": "Επιλέξτε χρονοδιάγραμμα διαθεσιμότητας",
    "addonPR": "Επιλέξτε προϊόντα πρόσθετου",
    "upsellPR": "Παρακαλώ επιλέξτε προϊόντα ανώτερης πώλησης",
    "markItemR": "Επιλέξτε επισήμανση προϊόντων",
    "caloriesR": "Απαιτούνται θερμίδες",
    "allergensR": "Επιλέξτε προϊόντα αλλεργιογόνων",
    "prepInstR": "Απαιτούνται οδηγίες προετοιμασίας",
    "staffNR": "Απαιτούνται σημειώσεις προσωπικού",
    "discountR": "Απαιτείται έκπτωση",
    "validDiscE": "Εισαγάγετε έγκυρη έκπτωση",
    "disday": "Με τη μέρα",
    "plSelDayT": "Επιλέξτε ημέρα",
    "sTimeReq": "Απαιτείται χρόνος έναρξης",
    "sTimeInValid": "Η ώρα έναρξης δεν είναι έγκυρη",
    "eTimeReq": "Απαιτείται ώρα λήξης",
    "eTimeInValid": "Η ώρα λήξης δεν είναι έγκυρη",
    "sDateReq": "Απαιτείται από ημερομηνία",
    "eDateReq": "Μέχρι σήμερα απαιτείται",
    "eDateInValid": "Μέχρι σήμερα δεν είναι έγκυρο",
    "disdate": "Από την Ημερομηνία",
    "disdate1": "Μέχρι σήμερα",
    "disdate2": "Από Ημερομηνία",
    "currT": "Νόμισμα",
    "iconR": "Επιλέξτε το εικονίδιο επισήμανσης στοιχείου",
    "minT": "Ελάχιστο",
    "maxT": "Ανώτατο όριο",
    "itemNT": "Όνομα αντικειμένου",
    "itemPT": "Τιμή είδους",
    "descProT": "Περιγράψτε το προϊόν σας",
    "cateT": "Κατηγορία",
    "selProCateT": "Επιλέξτε την κατηγορία του προϊόντος σας",
    "reqMT": "Απαιτείται όνομα μενού",
    "ViewMenu": "Προβολή μενού",
    "CopyMenu": "Αντιγραφή μενού",
    "EditMenu": "Επεξεργασία μενού",
    "RemoveMenu": "Κατάργηση μενού",
    "welcomeMnuTitle": "Καλώς ήρθατε στο",
    "reviewT": "Κριτικές",
    "userErrorMsg": "Συγγνώμη, Παρουσιάστηκε σφάλμα!!!",
    "reqCateT": "Απαιτείται όνομα κατηγορίας",
    "mngCustT": "Διαχείριση προσαρμογής",
    "custNReq": "Απαιτείται όνομα προσαρμογής",
    "incReq": "Περιλαμβάνεται απαιτείται",
    "minmValid": "Εισαγάγετε έγκυρη ελάχιστη τιμή",
    "maxmReq": "Απαιτείται μέγιστο",
    "cust": "Προσαρμογή",
    "crCust": "Δημιουργία νέας προσαρμογής",
    "custList": "Λίστα προσαρμογής",
    "delCustmzT": "Αυτή η προσαρμογή θα διαγραφεί οριστικά.",
    "mkNameR": "Επισήμανση ονόματος στοιχείου απαιτείται",
    "mkDelT": "Αυτό το στοιχείο σήμανσης θα διαγραφεί οριστικά.",
    "hideText": "Κρύβω",
    "showText": "Επίδειξη",
    "device_mac_txt": "Συσκευή Mac",
    "delete_hotspot_txt": "Αυτό το hotspot θα διαγραφεί οριστικά.",
    "delete_hotspots_txt": "Αυτά τα hotspot θα διαγραφούν οριστικά.",
    "emp_wifi_name": "Όνομα Wi-Fi υπαλλήλου",
    "max_limit_txt": "Εισαγάγετε '1024' για ταχύτητα για να την ορίσετε ως ΑΠΕΡΙΟΡΙΣΤΗ. Αυτή η τιμή αφαιρεί τυχόν ανώτατα όρια, επιτρέποντας τη μέγιστη απόδοση",
    "device_port_txt": "Θύρα συσκευής",
    "firmware_txt": "Υλικολογισμικό",
    "hotspot_info_txt": "Πληροφορίες Hotspot",
    "editDBtn": "Επεξεργασία λεπτομερειών",
    "birth_date": "Ημερομηνία γέννησης",
    "di_theme_clr": "Χρώμα θέματος ακριβώς 7 ψηφία",
    "color_Invalid_txt": "Επιλέξτε έγκυρο κωδικό χρώματος",
    "Req_theme_clr": "Χρώμα θέματος απαιτείται",
    "select_valid_color_txt": "Επιλέξτε έγκυρο χρώμα θέματος",
    "req_redir_link": "Απαιτείται σύνδεσμος ανακατεύθυνσης",
    "val_redir_link": "Εισαγάγετε έγκυρο σύνδεσμο ανακατεύθυνσης",
    "req_business_name_txt": "Απαιτείται επωνυμία επιχείρησης",
    "splash_preview": "Προεπισκόπηση Splash",
    "create_new_splash": "Δημιουργία New Splash",
    "splash_page": "Splash Page",
    "splash_page_builder": "Splash Page Builder",
    "redirect_link": "Σύνδεσμος ανακατεύθυνσης",
    "theme_color": "Χρώμα θέματος",
    "enable_social_login": "Ενεργοποιήστε τις επιλογές μέσων κοινωνικής δικτύωσης για τη σύνδεση των χρηστών",
    "google": "Google",
    "facebook": "Facebook",
    "is_mandatory": "Είναι υποχρεωτικό;",
    "field": "Πεδίο",
    "name": "Ονομα",
    "first_name": "Όνομα",
    "last_name": "Επώνυμο",
    "birthdate": "Ημερομηνία γέννησης",
    "gender": "Γένος",
    "email": "E-mail",
    "dob": "DOB",
    "zip_code": "Τ.Κ",
    "required_redirect_link": "Απαιτείται σύνδεσμος ανακατεύθυνσης",
    "valid_redirect_link": "Εισαγάγετε έγκυρο σύνδεσμο ανακατεύθυνσης",
    "required_theme_color": "Χρώμα θέματος απαιτείται",
    "theme_color_length": "Χρώμα θέματος ακριβώς 7 ψηφία",
    "required_name": "Το όνομα απαιτείται",
    "delete_splash_message": "Θέλετε να διαγράψετε αυτό το splash;",
    "delete_multiple_splashes_message": "Θέλετε να διαγράψετε αυτές τις πιτσιλιές;",
    "user_login_required": "Απαιτείται σύνδεση χρήστη",
    "set_theme_color": "Ορισμός χρώματος θέματος",
    "splash_colllect_additional_info_txt": "Συλλέξτε πρόσθετες πληροφορίες",
    "verify_mobile_otp_message": "Θέλετε να στείλετε OTP για επαλήθευση του αριθμού κινητού τηλεφώνου;",
    "add_company": "Προσθήκη εταιρείας",
    "edit_company": "Επεξεργασία Εταιρείας",
    "ds_device": "Συσκευή ψηφιακής σήμανσης",
    "ds_device_activation_fee": "Τέλος ενεργοποίησης συσκευής ψηφιακής σήμανσης",
    "wifi_hardware": "Υλικό Wifi",
    "menu_design": "Σχεδιασμός μενού",
    "ds_hardware": "Υλικό Ψηφιακής Σήμανσης",
    "company_name_req": "Απαιτείται το όνομα της εταιρείας",
    "known_name_req": "Απαιτείται γνωστό όνομα",
    "no_location_req": "Απαιτείται αριθμός τοποθεσίας",
    "minimum_location": "Εισαγάγετε τουλάχιστον 1 τοποθεσία",
    "terms_req": "Απαιτούνται όροι",
    "notes_req": "Απαιτούνται σημειώσεις",
    "sms_charge_req": "Απαιτείται χρέωση SMS",
    "menuonline_quantity_req": "Μενού Online Απαιτείται ποσότητα",
    "menuonline_cost_req": "Απαιτείται Online Κόστος Μενού",
    "ds_quantity_req": "Απαιτείται ποσότητα ψηφιακής σήμανσης",
    "ds_monthly_cost_req": "Απαιτείται μηνιαίο κόστος ψηφιακής σήμανσης",
    "reputation_quantity_req": "Φήμη Απαιτείται ποσότητα",
    "reputation_cost_req": "Απαιτείται το κόστος φήμης",
    "marketing_quantity_req": "Απαιτείται ποσότητα μάρκετινγκ",
    "marketing_cost_req": "Απαιτείται Κόστος Μάρκετινγκ",
    "tax_percentage_req": "Απαιτείται ποσοστό φόρου",
    "router_hardware_quantity_req": "Απαιτείται ποσότητα υλικού δρομολογητή",
    "wifi_hardware_cost_req": "Απαιτείται κόστος υλικού WIFI",
    "activation_cost_req": "Απαιτείται κόστος ενεργοποίησης",
    "wifi_quantity_req": "Απαιτείται ποσότητα WIFI",
    "wifi_router_req": "Απαιτείται δρομολογητής Wi-Fi",
    "menu_design_quantity_req": "Σχεδιασμός μενού Απαιτείται ποσότητα",
    "menu_design_cost_req": "Απαιτείται κόστος σχεδίασης μενού",
    "quantity_req": "Απαιτείται ποσότητα",
    "cost_req": "Απαιτείται κόστος",
    "sheduledown_quantity_req": "Απαιτείται καθορισμένη ποσότητα",
    "sheduledown_cost_req": "Απαιτείται μείωση κόστους",
    "loyalty_program_quantity_req": "Απαιτείται Ποσότητα προγράμματος πιστότητας",
    "loyalty_program_cost_req": "Απαιτείται Κόστος προγράμματος πιστότητας",
    "loyalty_program_sms_quantity_req": "Πρόγραμμα πιστότητας Απαιτείται ποσότητα SMS",
    "loyalty_program_sms_cost_req": "Απαιτείται Κόστος SMS προγράμματος πιστότητας",
    "comapny_created": "Η εταιρεία δημιουργήθηκε με επιτυχία",
    "comapny_updated": "Η εταιρεία ενημερώθηκε με επιτυχία",
    "billing_info": "ΣΤΟΙΧΕΙΑ ΧΡΕΩΣΗΣ",
    "business_name_acc_holder": "Επωνυμία επιχείρησης (κάτοχος λογαριασμού)",
    "client_first_name": "Όνομα πελάτη",
    "client_last_name": "Επώνυμο Πελάτη",
    "dba_known_as": "DBA γνωστό ως",
    "business_address": "Διεύθυνση Επιχείρησης",
    "no_of_locations": "Αριθμός τοποθεσιών",
    "package_details": "ΣΤΟΙΧΕΙΑ ΠΑΚΕΤΟΣ",
    "terms": "Οροι",
    "one_month": "1 μήνα",
    "per_sms_charge": "Ανά χρέωση SMS",
    "plan_test": "ΣΧΕΔΙΟ: ΔΟΚΙΜΗ",
    "desc_capital": "ΠΕΡΙΓΡΑΦΗ",
    "qty_capital": "ΠΟΣΟΤΗΤΑ",
    "cost_capital": "ΚΟΣΤΟΣ",
    "total_price_capital": "ΣΥΝΟΛΙΚΗ ΤΙΜΗ",
    "quantity": "Ποσότητα",
    "can_not_access": "Δεν μπορείτε να επιλέξετε περισσότερα από",
    "licenses": "άδειες",
    "monthly_cost": "Μηνιαίο Κόστος",
    "plan_cost": "Κόστος σχεδίου",
    "tax": "Φόρος",
    "tax_percentage": "Ποσοστό φόρου",
    "monthly_cost_after_tax": "Μηνιαίο κόστος μετά από φόρους",
    "activation_one_time_charge": "Ενεργοποίηση & Εφάπαξ Φόρτιση",
    "sub_total": "ΥΠΟ ΣΥΝΟΛΟ",
    "action": "Δράση",
    "cost": "Κόστος",
    "shipping_charge": "Χρέωση αποστολής",
    "other": "Αλλος",
    "additional_cost": "Πρόσθετο κόστος (Άλλες χρεώσεις)",
    "other_tax": "Άλλοι φόροι",
    "total": "Σύνολο",
    "license_statistics": "Στατιστικά Άδειας",
    "total_licenses": "Σύνολο αδειών",
    "available_licenses": "Διαθέσιμες Άδειες",
    "stock": "Στοκ",
    "has_permission": "Έχει Άδεια",
    "avg_stock_price": "Τιμή μετοχής AVG",
    "average_price": "Μέση Τιμή",
    "allocated": "Κατανεμήθηκε",
    "reward_regulars": "Επιβραβεύστε τους τακτικούς",
    "not_add_coupon_url": "Δεν είναι δυνατή η προσθήκη διεύθυνσης URL κουπονιού. Το μέγιστο μήκος του μηνύματος είναι 160",
    "like_attach_coupon": "Θέλετε να επισυνάψετε ένα κουπόνι;",
    "advance_scheduling": "Προγραμματισμός εκ των προτέρων",
    "choose_day": "Επιλέξτε ημέρα(ες) για αποστολή μηνύματος",
    "select_essage_window": "Επιλέξτε Παράθυρο μηνυμάτων",
    "subscription_outside_delivery_window": "Εάν ένας χρήστης εγγραφεί εκτός του παραθύρου παράδοσης, θα λάβει την αυτόματη απάντηση σας την επόμενη διαθέσιμη στιγμή.",
    "remaining": "Παραμένων",
    "processing_request": "Παρουσιάστηκε σφάλμα κατά την επεξεργασία του αιτήματός σας.",
    "list_companies": "Κατάλογος Εταιρειών",
    "are_you_sure": "Είσαι σίγουρος..;",
    "signin_agreement": "Επιβεβαιώνοντας αυτό, η εταιρεία θα δημιουργήσει χωρίς να υπογράψει συμφωνία.",
    "status_updated": "Επιτυχής ενημέρωση κατάστασης",
    "status_failed": "Η ενημέρωση της κατάστασης απέτυχε",
    "new_editor_status_updated": "Η κατάσταση του DS New Editor ενημερώθηκε με επιτυχία.",
    "user_name": "Ονομα χρήστη",
    "known_as": "γνωστός ώς",
    "agreement_signed": "Υπογραφή Συμφωνίας",
    "deactivate": "Απενεργοποίηση",
    "activate": "Δραστηριοποιώ",
    "login_to_user_acc": "Συνδεθείτε στο λογαριασμό χρήστη",
    "disable": "Καθιστώ ανίκανο",
    "enable": "Καθιστώ ικανό",
    "ds_new_editor": "Νέος επεξεργαστής DS",
    "sign_agreement": "Υπογραφή Συμφωνίας",
    "agreement_sign": "Συμφωνία W\/O Υπογραφή",
    "view_agreement": "Προβολή Συμφωνίας",
    "download_agreement": "Λήψη συμφωνίας",
    "add_deleted_user": "Προσθήκη διαγραμμένου χρήστη",
    "deleted_user": "Διαγραφή χρήστη",
    "favourite": "Ευνοούμενος",
    "refresh": "Φρεσκάρω",
    "delete_chat": "Διαγραφή συνομιλίας",
    "responsive_drawer": "Συρτάρι με απόκριση",
    "delete_contact": "Διαγραφή επαφής",
    "clear_chat_history": "Εκκαθάριση ιστορικού συνομιλίας",
    "clear_chat_history_dec": "Είστε βέβαιοι ότι θέλετε να διαγράψετε αυτήν τη συνομιλία;",
    "clear_contact_dec": "Είστε βέβαιοι ότι θέλετε να διαγράψετε αυτήν την επαφή;",
    "select_contact": "Επιλέξτε Επαφή",
    "new_conversation": "Έναρξη νέας συνομιλίας",
    "type_msg": "Πληκτρολογήστε το μήνυμά σας εδώ",
    "select_delete_client": "Επιλέξτε πελάτη για διαγραφή",
    "select_delete_client_chat": "Επιλέξτε πελάτη για να διαγράψετε τη συνομιλία",
    "select_delete_client_chat_err": "Δεν έχετε συνομιλία με αυτόν τον πελάτη",
    "acquire_customers_business": "Αποκτήστε νέους πελάτες από άλλες επιχειρήσεις.",
    "customers_from_events": "Λάβετε πελάτες από εκδηλώσεις και φέρτε το κοντά σας!",
    "customers_from_ads": "Λάβετε πελάτες από διαφημίσεις σε εφημερίδες.",
    "smart_pamphlets": "Δημιουργήστε έξυπνα φυλλάδια για τη συλλογή δεδομένων πελατών.",
    "smart_qr_group": "Ένα έξυπνο QR για τη συμμετοχή σε μια ομάδα για πελάτες.",
    "opt_in_number": "Δώστε τη δυνατότητα στους πελάτες να επιλέξουν τον αριθμό.",
    "qr_menu": "Δημιουργήστε κωδικό QR για την εμφάνιση του μενού σας.",
    "collect_birthdays": "Συλλέξτε τα γενέθλια και την επέτειο του πελάτη.",
    "join_loyalty_program": "Λάβετε νέους πελάτες να συμμετάσχουν στο πρόγραμμα αφοσίωσης μου.",
    "create_feedback_forms": "Δημιουργήστε φόρμες για τη συλλογή των σχολίων των πελατών.",
    "exclusive_discounts_promotions": "Προσφέρετε αποκλειστικές εκπτώσεις και προσφορές για πολλαπλές προσφορές.",
    "group_smart_qr_opt_in": "Αφού δημιουργήσετε μια ομάδα, μπορείτε να δημιουργήσετε και να επιλέξετε έναν έξυπνο κωδικό QR, επιτρέποντας στους πελάτες να συμμετέχουν άμεσα σε μια ομάδα",
    "group_smart_qr_opt_in_dec": "Μετά τη δημιουργία μιας ομάδας, το γραφικό στοιχείο εικόνας που επιτρέπει στους πελάτες να επιλέξουν τη χρήση κειμένου και να λάβουν προσφορές, για παράδειγμα, στείλτε μήνυμα \"πίτσα\" στο 555-555-5555",
    "in_store_discount_next_visit": "Λάβετε έκπτωση στο κατάστημα κατά την επόμενη επίσκεψή σας στην τοποθεσία μας",
    "delivery_redirection": "Δημιουργία ανακατεύθυνσης παράδοσης",
    "additional_info": "Πρόσθετες Πληροφορίες",
    "add_url": "Προσθήκη διεύθυνσης URL",
    "custom_fields": "Προσαρμοσμένα πεδία",
    "meta_tag": "Μετα-ετικέτα",
    "max_chars": "Μέγιστος χαρακτήρες",
    "add": "Προσθέτω",
    "update_campaign": "Ενημέρωση καμπάνιας",
    "last_week_required": "απαιτείται η προηγούμενη εβδομάδα",
    "large_week_value": "Η τιμή της εβδομάδας είναι πολύ μεγάλη, εισάγετε έγκυρη εβδομάδα",
    "less_than_last_week": "Η μη επιστροφή την προηγούμενη εβδομάδα πρέπει να είναι μικρότερη από την προηγούμενη εβδομάδα.",
    "last_week_req": "Δεν απαιτείται επιστροφή την περασμένη εβδομάδα",
    "birthday_special": "Ειδικά γενεθλίων",
    "birthday_message": "Αυτοματοποιημένο μήνυμα που στάλθηκε στα γενέθλιά τους με εξατομικευμένους χαιρετισμούς και δελεαστικές προσφορές",
    "birthday_sms": "Δημιουργήστε εξατομικευμένες ευχές γενεθλίων για SMS\/MMS",
    "target_audience": "Επιλέξτε κοινό-στόχο",
    "client_by_birthday": "Πελάτης με γενέθλια",
    "clients_once_year": "Αυτή η καμπάνια αποστέλλεται αυτόματα στους πελάτες μία φορά το χρόνο ",
    "auto_responder_default_msg_1": "Είστε ένας από τους καλύτερους πελάτες μας στους οποίους αγαπάμε να κάνουμε έκπτωση! Να είστε πάντα πλούσιοι και υγιείς. Χρόνια πολλά! %% όνομα %%",
    "campaign_send_to_client_birthday": "Αυτή η αυτόματη καμπάνια στέλνει σε πελάτες με γενέθλια, μπορείτε να προσαρμόσετε τη στόχευση παρακάτω.",
    "get_more_engagement": "Αποκτήστε περισσότερη αφοσίωση στέλνοντας b'day SMS 🎂",
    "welcome_new_clients": "Καλωσορίστε νέους πελάτες",
    "make_lasting_impression": "Κάντε μια μόνιμη εντύπωση χαιρετίζοντας θερμά και καλωσορίζοντας όσους δείπνοι για πρώτη φορά",
    "personalized_greetings": "Δημιουργήστε εξατομικευμένες ευχές για τους νέους πελάτες",
    "greetings_to_new_customers": "Αυτή η καμπάνια θα στείλει χαιρετισμούς καλωσορίσματος σε νέους πελάτες την επόμενη ημέρα της πρώτης αλληλεπίδρασής τους με την επιχείρησή σας",
    "audience_predefined": "Το κοινό αυτού του autoresponder είναι προκαθορισμένο, το έχουμε αυτό!",
    "send_clients_subscribed": "Αυτή η αυτόματη απάντηση έχει ρυθμιστεί να στέλνει πελάτες που έχουν εγγραφεί στο τελευταίο ",
    "first_interaction": "  μετά την πρώτη τους αλληλεπίδραση.",
    "default_msg_2": "Ελπίζουμε να απολαύσατε την πρώτη σας επίσκεψη, θα χαρούμε να σας δούμε ξανά σύντομα, γι' αυτό προσφέρουμε έκπτωση μόνο για εσάς! Κάντε κλικ στον σύνδεσμο για να κλείσετε το επόμενο ραντεβού σας μαζί μας και να εξαργυρώσετε την ειδική online προσφορά σας τώρα.",
    "new_clients_update": "Το κείμενο της καμπάνιας ενημέρωσης καλωσορίσματος νέων πελατών",
    "new_clients_warm_greeting": "Καλωσορίστε νέους πελάτες με έναν θερμό χαιρετισμό 💌",
    "win_back_clients": "Κερδίστε πίσω πελάτες",
    "re_engage_past_customers": "Προσελκύστε ξανά παλιούς πελάτες με ακαταμάχητες προσφορές για να τους ενθαρρύνετε να επιστρέψουν",
    "target_disengaged_clients": "Στοχεύστε τους αποδεσμευμένους πελάτες να επιστρέψουν με μια ειδική προσφορά, μια δοκιμασμένη τακτική για να προσελκύσετε τους πελάτες πίσω.",
    "campaign_sends_to_clients": "Αυτή η καμπάνια στέλνει σε πελάτες που δεν επέστρεψαν μετά από συγκεκριμένο χρονικό διάστημα.",
    "customize_targeting": "Προσαρμόστε τη στόχευση παρακάτω για αποστολή SMS\/MMS σε πελάτες που έχουν λήξει.",
    "clients_with_at_least": "Πελάτες με τουλάχιστον ",
    "connection_last": " σύνδεση στο τελευταίο ",
    "return_in_last_week": " εβδομάδα, αλλά δεν επέστρεψε την τελευταία ",
    "arr_menu_44": " εβδομάδα",
    "df_msg_4": "Θέλουμε να επιστρέψετε ΤΟΣΟ ΠΟΛΥ που θα σας δώσουμε έκπτωση 10% μόνο για την επιστροφή! Θέλετε να μας δοκιμάσετε ξανά σήμερα;",
    "update_campaign_text": "Κερδίστε πίσω το κείμενο της καμπάνιας ενημέρωσης πελατών",
    "re_invite_customers": "Προσκαλέστε ξανά πελάτες που δεν έχουν επιστρέψει για λίγο",
    "loyal_patrons": "Δείξτε εκτίμηση στους πιστούς θαμώνες με ειδικές εκπτώσεις και επιβραβεύσεις αφοσίωσης",
    "surprize_top_spenders": "Εκπλήξτε τους κορυφαίους αγοραστές με εξατομικευμένες και ειδικές προσφορές.",
    "campaign_automatically_send": "Αυτή η καμπάνια Αποστέλλει αυτόματα ανταμοιβές για να ενισχύσει την πτώση του ποδιού στην επιχείρησή σας.",
    "sms_loyal_clients": "Αυτή η καμπάνια στέλνει SMS\/MMS σε πιστούς πελάτες, μπορείτε να αλλάξετε τη στόχευση παρακάτω.",
    "or_more_connection": " ή περισσότερη σύνδεση, εντός της τελευταίας ",
    "week_period": " περίοδο της εβδομάδας.",
    "default_msg_5": "Είσαι πελάτης εδώ και πολύ καιρό! Ουάου, ο χρόνος κυλά! Σας ευχαριστούμε και θέλουμε να σας ευχαριστήσουμε που είστε μαζί μας.",
    "reward_regulars_update": "Επιβράβευση τακτικών ατόμων ενημερώνουν το κείμενο της καμπάνιας",
    "special_discounts": "Εκτιμήστε και κάντε ειδικές εκπτώσεις💎",
    "leave_positive_online": "Ενθαρρύνετε τους πελάτες να αφήνουν θετικές κριτικές στο διαδίκτυο για να ενισχύσουν τη φήμη του εστιατορίου",
    "clients_service_online": "Η καμπάνια ενθαρρύνει τους πελάτες σας να βαθμολογήσουν την υπηρεσία σας στο διαδίκτυο.",
    "increase_your_company": "Αυτό το autoresponder αποστέλλεται για να αυξήσει την online αξιολόγηση της εταιρείας σας.",
    "send_message_after": "Στείλτε μήνυμα μετά ",
    "min_connection": " λεπτά της σύνδεσης",
    "default_msg_6": "Ελπίζουμε να απολαύσατε την πρώτη σας επίσκεψη, θα χαρούμε να σας δούμε ξανά σύντομα! [URL] Κάντε κλικ στον σύνδεσμο για να μας βαθμολογήσετε στο Google.",
    "ratings_update": "Λάβετε περισσότερο κείμενο καμπάνιας ενημέρωσης Βαθμολογίες",
    "friendly_nudge": "Λάβετε περισσότερες κριτικές με ένα Friendly Nudge ⭐",
    "thanks_for_visiting": "Σας ευχαριστούμε για την επίσκεψη",
    "express_gratitude": "Εκφράστε ευγνωμοσύνη στους πελάτες που επέλεξαν την επιχείρησή σας με εγκάρδια ευχαριστήρια μηνύματα",
    "thank_you_message": "Δημιουργήστε ευχαριστήριο μήνυμα για τους πελάτες σας.",
    "clients_for_visiting": "Στείλτε ένα θερμό ευχαριστώ στους πελάτες σας για την επίσκεψη στο χώρο σας.",
    "campaign_audience": "Αυτό το κοινό της αυτόματης καμπάνιας είναι προκαθορισμένο, το έχουμε αυτό!",
    "campaign_automatically_sends": "Αυτή η αυτόματη καμπάνια αποστέλλεται αυτόματα μετά ",
    "minutes_customer_visit": " λεπτά επίσκεψης πελάτη",
    "default_msg_7": "Σας ευχαριστούμε που είστε ο πολύτιμος πελάτης μας. Είμαστε τόσο ευγνώμονες για τη χαρά να σας εξυπηρετήσουμε και ελπίζουμε να ανταποκριθήκαμε στις προσδοκίες σας.",
    "visiting_update_campaign": "Σας ευχαριστούμε που επισκεφτήκατε το κείμενο ενημέρωσης της καμπάνιας",
    "guests_thank_you": "Ευχαριστούμε τους Καλεσμένους σας για την Επίσκεψή τους 😊",
    "download_title": "Λήψη",
    "qr_gen_title": "Δημιουργήθηκε QR",
    "qr_download_s_title": "Ο κωδικός QR λήφθηκε με επιτυχία",
    "sel_dark_brand_clr_msg": "Επιλέξτε έγκυρο χρώμα ράβδου",
    "manage_customer_stamp_rewards": "Διαχειριστείτε τις ανταμοιβές σφραγίδων πελατών 🎁",
    "sel_loc_menu_title": "Επιλέξτε τοποθεσίες για το μενού σας",
    "ans_req": "Απαιτείται απάντηση",
    "valid_reputation_name": "Εισαγάγετε έγκυρο όνομα φήμης",
    "reviews_name_req": "Το όνομα Λήψη περισσότερων κριτικών είναι υποχρεωτικό",
    "birthdate_required": "Απαιτείται ημερομηνία γέννησης",
    "gender_required": "Το φύλο είναι υποχρεωτικό",
    "valid_birthdate_required": "Εισαγάγετε έγκυρη ημερομηνία γέννησης",
    "custom_delivery_redirection": "Δημιουργήστε μια προσαρμοσμένη φόρμα ανακατεύθυνσης παράδοσης ανά ομάδες",
    "customer_fields_incentive_settings": "Πεδία πελατών και ρυθμίσεις κινήτρων",
    "delivery_redirection_text": "Ανακατεύθυνση παράδοσης",
    "delivery_redirection_success": "Συγχαρητήρια! Το γραφικό στοιχείο ανακατεύθυνσης παράδοσης δημιουργήθηκε! 🎉",
    "swipe_to_preview_redirection": "Σύρετε για να δείτε πώς θα μοιάζει η φόρμα ανακατεύθυνσης παράδοσης",
    "enter_item_name": "Εισαγάγετε το όνομα του στοιχείου",
    "home_page_text": "Κείμενο αρχικής σελίδας",
    "settings": "Ρυθμίσεις",
    "anniversary_required": "Απαιτείται ημερομηνία επετείου",
    "valid_anniversary": "Εισαγάγετε έγκυρη ημερομηνία επετείου",
    "form_submited": "φόρμα υποβλήθηκε με επιτυχία",
    "notifications": "Ειδοποιήσεις",
    "discount_message": "Διεκδικήστε την έκπτωση 20% στην Πίτσα τώρα!",
    "is_required": " απαιτείται",
    "section_title_required": "Απαιτείται ο τίτλος της ενότητας",
    "section_dec_required": "Απαιτείται περιγραφή ενότητας",
    "client_details_required": "Τα πεδία στοιχείων πελάτη είναι απαραίτητα",
    "compliance": "Συμμόρφωση",
    "SMS_campaign1": "Καμπάνια SMS 1",
    "mobile_number_mandatory": "Ο αριθμός κινητού είναι υποχρεωτικός",
    "new_answer": "Νέα απάντηση",
    "new_question": "Νέα ερώτηση",
    "add_new_question": "Προσθήκη νέας απάντησης",
    "select": "Επιλέγω",
    "group_customers_question": "Πού θέλετε να ομαδοποιήσετε τους πελάτες σας;",
    "contacts_added_to_group": "Όλες οι επαφές που υποβάλλουν τη φόρμα θα προστεθούν στην επιλεγμένη ομάδα",
    "edit_client_details_section": "Επεξεργασία ενότητας στοιχείων πελάτη",
    "client_details_fields": "Πεδία Στοιχείων Πελάτη",
    "enter_client_details_section_title_desc": "Εισαγάγετε τον τίτλο και την περιγραφή της ενότητας στοιχείων πελάτη",
    "choose_fields_displayed_client_side": "Επιλέξτε τα πεδία που θα εμφανίζονται στην πλευρά του πελάτη",
    "section_title": "Τίτλος Ενότητας",
    "add_title": "Προσθήκη τίτλου",
    "section_description": "Περιγραφή Ενότητας",
    "add_description": "Προσθήκη περιγραφής",
    "enter_program_name_location": "Εισαγάγετε όνομα και τοποθεσία προγράμματος 📍",
    "brand_theme_clr_txt": "Χρώμα θέματος επωνυμίας",
    "set_loyalty_punch_card_theme": "Ας ορίσουμε ένα θέμα για την κάρτα αφοσίωσης 🎨",
    "upload_logo_txt": "Ανεβάστε το λογότυπό σας",
    "recommended_image_specs": "Συνιστούμε να χρησιμοποιήσετε μια εικόνα 512 x 512 px για την καλύτερη εμφάνιση. JPG, SVG ή PNG. Μέγιστο μέγεθος 10MB.",
    "valid_rep_name_txt": "Εισαγάγετε έγκυρο όνομα φήμης",
    "rep_name_req_txt": "Το όνομα Λήψη περισσότερων κριτικών είναι υποχρεωτικό",
    "que_req": "Απαιτείται ερώτηση",
    "day_of_birthday": "την ημέρα των γενεθλίων",
    "day_before_birthday": "3 μέρες πριν τα γενέθλια",
    "week_before_birthday": "μια εβδομάδα πριν τα γενέθλια",
    "two_week_before_birthday": "δύο εβδομάδες πριν από τα γενέθλια",
    "de_active": "De-Active",
    "campaign_details": "Λεπτομέρειες καμπάνιας",
    "link_clicked": "Ο σύνδεσμος έκανε κλικ",
    "history": "Ιστορία",
    "auto_responder_summary": "Σύνοψη Auto Responder",
    "vsop_1": "15 λεπτά",
    "vsop_2": "30 λεπτά",
    "vsop_3": "45 λεπτά",
    "vsop_4": "60 λεπτά",
    "vsop_5": "90 λεπτά",
    "vsop_6": "120 λεπτά",
    "in_the_last": " στο τελευταίο ",
    "return_in_last": " αλλά δεν επέστρεψε στο τέλος ",
    "of_the_connection": "της σύνδεσης",
    "your_campaign": "Η καμπάνια σας",
    "you_have_successfully": "Έχετε με επιτυχία",
    "published_successfully": "δημοσιεύτηκε με επιτυχία",
    "updated_smart_campaign": "ενημέρωσε την έξυπνη καμπάνια",
    "almost_done_text": "Σχεδόν τελειωμένο!",
    "update_campaign_desc": "Η αυτόματη απάντηση σας έχει ολοκληρωθεί, αφού ενεργοποιηθεί, θα στέλνει μηνύματα στους πελάτες σε συνεχή βάση. Αυτή η καμπάνια μπορεί εύκολα να τροποποιηθεί ή να τεθεί σε παύση ανά πάσα στιγμή.",
    "update_and_publish": "Ενημέρωση και δημοσίευση",
    "reset_campaign_title": "Θα διαγράψει οριστικά το προσαρμοσμένο κείμενο, την εικόνα, τις προσφορές έκπτωσης και το στοχευμένο κοινό της καμπάνιας σας!",
    "space_wifi": " WiFi",
    "custom_forms": "Προσαρμοσμένες Φόρμες",
    "web_e_signup": "Ηλεκτρονική εγγραφή στο Web",
    "import": "Εισαγωγή",
    "permissions_req": "Απαιτούνται άδειες",
    "redeemed": "Εξαγοράστηκε",
    "coupon_already_redeemed": "Το κουπόνι έχει ήδη εξαργυρωθεί",
    "autoresponder_campaigns": "Καμπάνιες Autoresponder",
    "autoresponder_campaign_desc": "Ενεργοποιήστε την αυτόματη απάντηση και ξεκινήστε την εξατομικευμένη καμπάνια",
    "mon": "ΔΕΥ",
    "tue": "ΤΡΙ",
    "wed": "ΝΥΜΦΕΥΟΜΑΙ",
    "thu": "ΠΕΜ",
    "fri": "ΠΑΡ",
    "sat": "ΣΑΤ",
    "sun": "ΗΛΙΟΣ",
    "duplicate": "Αντίγραφο",
    "visibility": "Ορατότητα",
    "availability": "Διαθεσιμότητα",
    "out_stok": "Εκτός στοκ",
    "edit_product": "Επεξεργασία προϊόντος",
    "create_product": "Δημιουργήστε ένα προϊόν",
    "basic_info": "Βασικές Πληροφορίες",
    "basic_sub_info": "Το Lorem Ipsum είναι απλώς εικονικό κείμενο της εκτύπωσης...",
    "enter_product_price": "Εισαγάγετε την τιμή του προϊόντος",
    "upload_image": "Μεταφόρτωση εικόνας",
    "allowed_file_formats": "Επιτρέπονται μόνο αρχεία JPG και PNG με μέγιστο μέγεθος αρχείου 3 MB.",
    "pre_select": "Προεπιλογή",
    "promotions": "Προσφορές",
    "discount_availability": "Διαθεσιμότητα έκπτωσης",
    "start_time": "Ώρα έναρξης",
    "end_time": "Ώρα λήξης",
    "select_day": "Επιλέξτε Ημέρα",
    "menu_required": "Απαιτείται μενού",
    "generated": "Δημιουργήθηκε",
    "link": "Σύνδεσμος",
    "variations": "Παραλλαγές",
    "select_variant_to_add": "Επιλέξτε την παραλλαγή που θέλετε να προσθέσετε",
    "price_title": "Τιμή",
    "choose_image": "Επιλέξτε Εικόνα",
    "select_visiblity": "Επιλέξτε Ορατότητα",
    "availability_schedule": "Χρονοδιάγραμμα διαθεσιμότητας",
    "add_on_upsell": "Προσθήκη \/ Upsell",
    "add_on_select_product": "Προσθήκη στο Επιλέξτε προϊόν",
    "upsell_product": "Upsell Select Product",
    "variant_deletion_warning": "Αυτή η παραλλαγή θα διαγραφεί οριστικά.",
    "search_variations": "Παραλλαγές αναζήτησης",
    "add_variation": "Προσθήκη παραλλαγής",
    "variation_text": "Παραλαγή",
    "select_customization": "Επιλέξτε προσαρμογή",
    "add_new": "Προσθήκη νέου",
    "delete_customization_warning": "Θέλετε να διαγράψετε αυτήν την προσαρμογή από το προϊόν;",
    "nutritional_allergen_info": "Διατροφικές Πληροφορίες και Αλλεργιογόνα",
    "mark_item": "Επισήμανση αντικειμένου",
    "calories_text": "Θερμίδες",
    "allergens": "Αλλεργιογόνα",
    "select_allergens": "Επιλέξτε αλλεργιογόνα",
    "special_instructions": "Ειδικές οδηγίες",
    "preparation_instructions": "Οδηγίες προετοιμασίας",
    "staff_notes": "Σημειώσεις Προσωπικού",
    "checkbox1": "Ένα πλαίσιο ελέγχου για να επιτρέπονται ή να μην επιτρέπονται ειδικές οδηγίες από πελάτες για αυτό το στοιχείο μενού.",
    "menu_c": "ΜΕΝΟΥ",
    "past_design": "Παλιό Σχέδιο",
    "file_name": "Όνομα αρχείου",
    "variation_name": "Όνομα παραλλαγής",
    "grid_items_title1": "Επιτραπέζια Σκηνή",
    "grid_items_title2": "Ψηφιακό μενού",
    "grid_items_title3": "Μενού σε πακέτο",
    "grid_items_title4": "Μενού μισής σελίδας",
    "grid_items_title5": "Ψηφιακό μενού 5",
    "grid_items_title6": "Ψηφιακό μενού 6",
    "grid_items_title7": "Ψηφιακό μενού 7",
    "grid_items_title8": "Ψηφιακό μενού 8",
    "grid_items_title9": "Ψηφιακό μενού 9",
    "enter_valid_form_name": "Εισαγάγετε έγκυρο όνομα φόρμας",
    "form_name_required": "Απαιτείται το όνομα της φόρμας",
    "enter_valid_section": "Εισαγάγετε έγκυρο τίτλο ενότητας",
    "fraction_half": "1\/2",
    "fraction_two_by_two": "2\/2",
    "form_name": "Όνομα Φόρμας",
    "contact_list": "Λίστα επαφών",
    "label_notify_email": "Θα θέλατε να ενημερώνεστε μέσω email;",
    "add_client_details_section": "Προσθήκη ενότητας στοιχείων πελάτη",
    "demo_purpose_only": "Είναι μόνο για επίδειξης",
    "form_updated_success": "φόρμα ενημερώθηκε με επιτυχία",
    "form_creat_success": "φόρμα δημιουργήθηκε με επιτυχία",
    "add_custom_section": "Προσθέστε μια προσαρμοσμένη ενότητας",
    "edit_custom_section": "Επεξεργαστείτε μια προσαρμοσμένη ενότητας",
    "forms_list": "Κατάλογος εντύπων",
    "filled_custom_form_details": "Συμπληρωμένα στοιχεία προσαρμοσμένης φόρμας",
    "custom_form_deletion_warning": "Αυτή η προσαρμοσμένη φόρμα θα διαγραφεί οριστικά",

    "menu_link": "Σύνδεσμος μενού",
    "WebSignUpT": "Ηλεκτρονική εγγραφή",
    "lifeTConT": "Συνδέσεις διάρκειας ζωής",
    "repCustT": "Επαναλαμβανόμενοι πελάτες",
    "liftTSubT": "Συνδρομητές διάρκειας ζωής",
    "overallT": "ΟΛΙΚΟΣ",
    "loginCustBDT": "Συνδεδεμένοι πελάτες ανά ημέρα",
    "deviceT": "Δημογραφικά στοιχεία συσκευής",
    "ageTitle": "Δημογραφικά στοιχεία ηλικίας",
    "ageT": "Ηλικιακή ομάδα",
    "convert_social_media_followers_into_customers": "Μετατρέψτε τους οπαδούς των μέσων κοινωνικής δικτύωσης σε πελάτες",
    "signWidDesc": "Δημιουργήστε ένα widget για εγγραφή, δημιουργία σελίδων προορισμού και δημιουργία κουπονιών από τον ιστότοπο ή τα μέσα κοινωνικής δικτύωσης",
    "qrWidT": "Έξυπνο QR",
    "imgWidT": "Επιλέξτε τον αριθμό",
    "mobile": "Κινητός",
    "YESTitle": "ΝΑΙ",
    "NOTitle": "ΟΧΙ",
    "addDelRed": "Προσθήκη ανακατεύθυνσης παράδοσης",
    "dlredNameT": "Ονομάστε την ανακατεύθυνση παράδοσης",
    "conf_text_req": "Απαιτείται κείμενο επιβεβαίωσης",
    "cFDesc": "Δημιουργήστε προσαρμοσμένες φόρμες για τη συλλογή συγκεκριμένων σχολίων και πληροφοριών από τους πελάτες αποτελεσματικά",
    "pre_review_questions_enabled": "Πριν ζητήσουμε από τους πελάτες για κριτική, ας τους κάνουμε 1 έως 4 ερωτήσεις.",
    "feel_free_edit": "Μη διστάσετε να επεξεργαστείτε όπως χρειάζεται.",
    "option": "Επιλογή",
    "confFL": "Αυτή η επιβεβαίωση θα εμφανιστεί αφού η επαφή σας υποβάλει τη Φόρμα Εγγραφής στο Web",
    "confT": "Κείμενο επιβεβαίωσης",
    "joinTitle": "Ενώνω",
    "mobValidL": "Ελέγξτε τον έγκυρο αριθμό κινητού τηλεφώνου",
    "mobRequiredL": "Απαιτείται αριθμός κινητού",

    "Business_location_label": "Η τοποθεσία της επιχείρησής σας",
    "qr_code_selection_txt": "Επιλογή κωδικού Qr",
    "choose_theme_txt": "Επιλέξτε Θέμα",
    "edit_qr_details_txt": "Επεξεργασία λεπτομερειών QR",
    "save_qr_code_txt": "Αποθήκευση κωδικού QR",
    "text_color_label": "Χρώμα κειμένου",
    "background_color_label": "Χρώμα φόντου",
    "print_txt": "Αποτύπωμα",
    "customer_will_see_txt": "Αυτό θα δει ο πελάτης σας. Επιλέξτε το χρώμα της επωνυμίας σας στο επόμενο βήμα.",
    "choose_your_brand_color_&_langauge_title": "Επιλέξτε το χρώμα και τη γλώσσα της επωνυμίας σας",
    "custom_redirect_link_label": "Προσαρμοσμένος σύνδεσμος ανακατεύθυνσης",
    "redirect_user_to_menu": "Ανακατεύθυνση χρήστη στο μενού",
    "language": "Langauge",
    "select_language_placeholder": "Επιλέξτε Γλώσσα",
    "capitalize_location_name_txt": "Όνομα τοποθεσίας",
    "created_txt": "Δημιουργήθηκε",
    "swipe_reputation_management_txt": "Σύρετε για να δείτε πώς θα μοιάζει η διαχείριση της φήμης σας",
    "sent_txt": "Στάλθηκε",
    "number_label": "Αριθμός",
    "date_label": "Ημερομηνία",
    "gender_demographics_title": "Δημογραφικά στοιχεία φύλου",

    "step1_preview_desc": "Ονομάστε τη Φόρμα QR της ηλεκτρονικής εγγραφής και προσθέστε τη στη λίστα της ομάδας σας",
    "main_head": "Αποκτήστε νέους πελάτες",
    "main_desc": "Αποκτήστε πελάτες τοποθετώντας έναν κωδικό QR που, όταν σαρωθεί, ανακατευθύνει τους χρήστες σε μια εξατομικευμένη φόρμα εγγραφής.",
    "step1_description": "Αποκτήστε νέους πελάτες από άλλες επιχειρήσεις",
    "form_name_field_placeholder": "Restro E-εγγραφή",
    "last_step_title": "Συγχαρητήρια! Δημιουργήθηκε το γραφικό στοιχείο ηλεκτρονικής εγγραφής σας! 🎉",
    "last_step_desc": "Σύρετε για να δείτε πώς θα μοιάζει η φόρμα ηλεκτρονικής εγγραφής",
    "events_step1_preview_desc": "Ονομάστε τη Φόρμα QR της εκδήλωσης και προσθέστε τη στη λίστα της ομάδας σας",
    "events_main_head": "Λάβετε πελάτες από εκδηλώσεις",
    "events_main_desc": "Αποκτήστε πελάτες τοποθετώντας έναν κωδικό QR στο συμβάν, όταν σαρωθεί, ανακατευθύνει τους χρήστες σε μια εξατομικευμένη φόρμα εγγραφής.",
    "events_step1_desc": "Αιχμαλωτίστε πελάτες από εκδηλώσεις και φέρτε τους στο κατάστημα",
    "events_name": "Όνομα συμβάντος",
    "events_form_name_field_placeholder": "Εκδήλωση Διασκέδασης και Φαγητού Restro",
    "events_last_step_title": "Συγχαρητήρια! Η φόρμα εκδήλωσης δημιουργήθηκε! 🎉",
    "events_last_step_desc": "Σύρετε για να δείτε πώς θα μοιάζει η φόρμα εκδήλωσης",
    "birthday_anniversary_step1_preview_desc": "Ονομάστε τη Φόρμα QR για να συλλέξετε τη μέρα ή την επέτειο του πελάτη και να την προσθέσετε στη λίστα της ομάδας σας",
    "birthday_anniversary_main_head": "Συλλέξτε B'day ή Anniversary",
    "birthday_anniversary_main_desc": "Λάβετε τα γενέθλια ή την επέτειο του πελάτη, όταν σαρώνεται, ανακατευθύνει τους χρήστες σε μια εξατομικευμένη φόρμα εγγραφής.",
    "birthday_anniversary_step1_desc": "Συλλέξτε τα γενέθλια και την επέτειο του πελάτη",
    "restro_text": "Restro",
    "birthday_anniversary_last_step_title": "Συγχαρητήρια! Λάβετε τη φόρμα B'day & Anniversary από πελάτες! 🎉",
    "newspaper_ads_step1_preview_desc": "Ονομάστε τη Φόρμα QR για να προσελκύσετε πελάτες από διαφημίσεις σε εφημερίδες και να την προσθέσετε στη λίστα της ομάδας σας",
    "newspaper_ads_main_head": "Λάβετε πελάτες από τις διαφημίσεις",
    "newspaper_ads_main_desc": "Λάβετε πελάτες από τις διαφημίσεις εφημερίδων, όταν σαρώνουν, ανακατευθύνει τους χρήστες σε μια εξατομικευμένη φόρμα εγγραφής.",
    "newspaper_ads_step1_desc": "Λάβετε πελάτες από διαφημίσεις σε εφημερίδα ή υπαίθριες διαφημίσεις",
    "newspaper_ads_last_step_title": "Συγχαρητήρια! Λάβετε πελάτες από τη φόρμα διαφημίσεων που δημιουργήθηκε! 🎉",
    "smart_pamphlets_step1_preview_desc": "Ονομάστε τη Φόρμα QR για να λαμβάνετε δεδομένα πελατών από φυλλάδια και να την προσθέσετε στη λίστα της ομάδας σας",
    "smart_pamphlets_main_head": "Λάβετε πελάτες από φυλλάδια",
    "smart_pamphlets_main_desc": "Λάβετε τα δεδομένα των πελατών από φυλλάδια, όταν αυτά σαρώνονται, ανακατευθύνει τους χρήστες σε μια εξατομικευμένη φόρμα εγγραφής.",
    "smart_pamphlets_last_step_title": "Συγχαρητήρια! Λάβετε τα δεδομένα των πελατών από τη φόρμα φυλλαδίων που δημιουργήθηκε! 🎉",
    "social_media_step1_preview_desc": "Δώστε ένα όνομα στη Φόρμα QR για να προσελκύσετε πελάτες από τα μέσα κοινωνικής δικτύωσης και σε πελάτες και να την προσθέσετε στη λίστα της ομάδας σας",
    "social_media_main_desc": "Λάβετε πελάτες από τα μέσα κοινωνικής δικτύωσης, όταν σαρώνουν από αναρτήσεις, ανακατευθύνει τους χρήστες σε μια εξατομικευμένη φόρμα εγγραφής.",
    "social_media_form_name_field_placeholder": "Προσφορά Φεστιβάλ Restro - Ανάρτηση στο Facebook",
    "social_media_last_step_title": "Συγχαρητήρια! Δημιουργήστε τη φόρμα κοινωνικής δικτύωσης πελατών! 🎉",
    "claim_offer_text": "Συμπληρώστε τα στοιχεία σας και διεκδικήστε την προσφορά τώρα",
    "form_title_description": "Προσθέστε τίτλο και περιγραφή Φόρμας για να εισάγουν τα στοιχεία οι πελάτες σας",
    "no_subscribed_clients": "Η επιλεγμένη ομάδα πελατών δεν έχει εγγεγραμμένους πελάτες",
    "welcome_message_offer": "Στείλτε ένα μήνυμα καλωσορίσματος στους πελάτες σας, αφού εγγραφούν και δώστε τους ένα κουπόνι προσφοράς",
    "system_grp": "Ομάδες Συστήματος",
    "import_clients": "Πελάτες εισαγωγής",
    "delivery_redirection_name": "Όνομα ανακατεύθυνσης παράδοσης",
    "created_at": "Δημιουργήθηκε στο",
    "provide_stamp_user": "Παροχή σφραγίδας στον χρήστη",
    "scan_qr_code": "Σάρωση κωδικού QR",
    "add_point": "Προσθήκη σημείου",
    "total_points": "Σύνολο πόντων",
    "user_profile": "Προφίλ Χρήστη",
    "stamp_history": "Ιστορία γραμματοσήμων",
    "reward_txt": "Ανταμοιβή",
    "variant_placeholder": "Μικρό, Μεσαίο, Μεγάλο κ.λπ",
    "sent_coupon": "Απεσταλμένο κουπόνι",
    "order_status": "Κατάσταση παραγγελίας",
    "payment_status": "Κατάσταση πληρωμής",
    "order_currency": "Νόμισμα παραγγελίας",
    "card_data": "Δεδομένα κάρτας",
    "reward_expiring_on": "Επιβράβευση που λήγει",
    "reward_status": "Κατάσταση ανταμοιβής",
    "reward_get_date": "Ανταμοιβή Λήψη Ημερομηνίας",
    "reward_name": "Όνομα επιβράβευσης",
    "add_point_type": "Προσθήκη τύπου σημείου",
    "restaurant_location": "Τοποθεσία εστιατορίου",
    "collect_points": "Συλλέξτε πόντους",
    "rewards_expire": "Οι ανταμοιβές λήγουν",
    "rewards_redeem": "Ανταμοιβές Εξαργύρωση",
    "total_rewards": "Συνολικές ανταμοιβές",
    "created_by": "Δημιουργήθηκε από",
    "anniversary": "Επέτειος",
    "remove_user_group_txt": "Κατάργηση χρήστη από την ομάδα",
    "delete_permanently_grp_msg": "Αυτός ο πελάτης θα διαγραφεί οριστικά από την ομάδα.",
    "delete_multiple_permanently_grp_msg": "Αυτοί οι πελάτες θα διαγραφούν οριστικά από την ομάδα.",
    "delete_multiple_client": "Αυτοί οι πελάτες θα διαγραφούν οριστικά.",
    "messages": "Μηνύματα",
    "management": "Διαχείριση",
    "client": "Πελάτης",
    "country": "Χώρα",
    "state": "Κατάσταση",
    "city": "Πόλη",
    "zip_code": "Τ.Κ",
    "1_sparkly_point_txt": "🎉 Κέρδισες 1 πόντο! 🌟",
    "select_country_txt": "Επιλέξτε χώρα",
    "select_state": "Επιλέξτε κατάσταση",
    "ls_thanks_for_join": "Σας ευχαριστούμε που συμμετείχατε στην κάρτα αφοσίωσης.🎁🎈",
    "month": "Μήνας",
    "welcome_message": "Στείλτε ένα μήνυμα καλωσορίσματος στους πελάτες σας, αφού εγγραφούν",
    "all_rewards_txt": "Όλες οι ανταμοιβές",
    "active_rewards": "Ενεργές ανταμοιβές",
    "expired_and_redeemed": "Λήξη & Εξαγορά",
    "redirect_3rd_party_delivery": "Ανακατευθύνετε την παράδοση τρίτου μέρους στο κατάστημά σας ή στην ηλεκτρονική πλατφόρμα παραγγελιών σας",
    "search_or_start_new": "Αναζήτηση ή Ξεκινήστε μια νέα",
    "contacts": "Επαφές",
    "chats": "Συνομιλίες",
    "add_by_restaurant": "Προσθήκη από Εστιατόριο",
    "using_scan_code": "Χρήση κωδικού σάρωσης",
    "step": "Βήμα",
    "list_widget_text": "Λίστα γραφικών στοιχείων",
    "swipe_free_wifi_txt": "Σύρετε για δωρεάν wifi",
    "create_widget_txt": "Δημιουργία γραφικού στοιχείου",
    "redeem_award_msg": "Είσαι σίγουρος ; Θέλετε να εξαργυρώσετε αυτήν την ανταμοιβή",
    "on_collect_msg": "Στη συλλογή",
    "pause": "Παύση",
    "resume": "Περίληψη",
    "location_added": "προστέθηκε τοποθεσία",
    "sub_user_admin_permissions_txt": "Να επιτρέπεται στον δευτερεύοντα χρήστη να έχει πρόσβαση στα δικαιώματα διαχειριστή στο Digital Signage",
    "unlist": "Κατάργηση λίστας",
    "cannot_delete_default_terms": "Δεν είναι δυνατή η διαγραφή των προεπιλεγμένων Όρων και Προϋποθέσεων",
    "something_went_wrong": "Κάτι πήγε στραβά!",
    "card_download_success": "Η κάρτα λήφθηκε με επιτυχία",
    "permission_denied": "Απορρίφθηκε η άδεια!",
    "invalid_qr_code": "Σαρώστε έγκυρο κωδικό QR",
    "select_customer": "Επιλέξτε πελάτη",
    "online": "ONLINE",
    "offline": "ΕΚΤΟΣ ΣΥΝΔΕΣΗΣ",
    "no_data": "Ωχ, δεν βρέθηκαν δεδομένα!",
    "forever_free": "Για πάντα δωρεάν",
};

export default el