import en from './en';
import es from './es';
import fr from './fr';
import hi from './hi';
import cs from './cs';
import da from './da';
import fi from './fi';
import ja from './ja';
import nl from './nl';
import de from './de';
import el from './el';
import he from './he';
import hu from './hu';
import it from './it';
import ko from './ko';
import no from './no';
import pl from './pl';
import pt from './pt';
import sk from './sk';
import sv from './sv';
import ar from './ar';
import fa from './fa';
import ru from './ru';
import tr from './tr';
import ur from './ur';

const Localize: any = {
    en: { ...en },
    fr: { ...fr },
    es: { ...es },
    hi: { ...hi },
    cs: { ...cs },
    da: { ...da },
    fi: { ...fi },
    ja: { ...ja },
    nl: { ...nl },
    de: { ...de },
    el: { ...el },
    he: { ...he },
    hu: { ...hu },
    it: { ...it },
    ko: { ...ko },
    no: { ...no },
    pl: { ...pl },
    pt: { ...pt },
    sk: { ...sk },
    sv: { ...sv },
    ar: { ...ar },
    fa: { ...fa },
    ru: { ...ru },
    tr: { ...tr },
    ur: { ...ur }

};

export default Localize;
