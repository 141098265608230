// Created By ND
import React from "react";
import jwtDecode from "jwt-decode";
import { UAParser } from "ua-parser-js";
import moment from "moment";
import Resizer from "react-image-file-resizer";
// import _ from 'lodash';

// local files
import ConstantsHelper from "../../Helper/Constants";
import ColorsHelper from "../../Helper/Colors";
import staticIcons from "../../Helper/Images";
import countryCodes from "../../Helper/CoutryCode";
import {
  sbLocObj,
  sbLocCount,
} from "../../Configurations/ApolloClient/ApolloCache";
import { PublicVariables } from "../../Helper/Language/languageConstant";
import { updateVariableValue } from "../variableManager";

class AppUtils {
  // user data save and retrive
  // static getUserData = () => {
  //     if (window.localStorage.getItem('SUNSHINE') !== null) {
  //         return JSON.parse(window.localStorage.getItem('SUNSHINE'))
  //     }
  //     return null
  // };

  //get device's platform name
  static getDevicePlatform = () => {
    let platform = "Unknown";
    const uaData = new UAParser();
    if (uaData && uaData.getOS() !== null) {
      const osData = uaData.getOS();
      if (typeof osData?.name != "undefined" && osData?.name !== null) {
        platform = osData?.name;
        if (platform.indexOf("Win") !== -1) platform = "Windows";
        if (platform.indexOf("Mac") !== -1) platform = "iOS";
        if (platform.indexOf("Linux") !== -1) platform = "Android";
        if (platform.indexOf("Android") !== -1) platform = "Android";
        return platform;
      }
    }

    if (navigator.userAgent.indexOf("Win") !== -1) platform = "Windows";
    if (navigator.userAgent.indexOf("Mac") !== -1) platform = "iOS";
    if (navigator.userAgent.indexOf("Linux") !== -1) platform = "Android";
    if (navigator.userAgent.indexOf("Android") !== -1) platform = "Android";
    return platform;
  };

  // storage value to a key
  static setStorageData = (id: string, data: any) => {
    localStorage.setItem(id, data);
  };


  static setLanguageObject = (language: any) => {
    PublicVariables.currentLanguageObj = language;
    updateVariableValue(language);
  };

  // get storage value from a key
  static getSessionItemData = (itemName: string) => {
    if (localStorage.getItem(itemName) !== null) {
      const itemData = localStorage.getItem(itemName);
      if (itemData && JSON.parse(itemData)) {
        const data = JSON.parse(itemData);
        return data;
      }
    }
    return null;
  };

  // remove any items
  static removeItemData = (itemName: string) => {
    localStorage.removeItem(itemName);
  };

  static setUserData = (userData: any) => {
    // Assign value to a key
    // sessionStorage.setItem("MYCIRCLE", userData);
    this.setStorageData("MYCIRCLE", userData);
  };

  // for jwt token
  static getUserToken = () => {
    const userD = this.getSessionItemData("MYCIRCLE");
    if (userD && userD?.jwt_token) {
      return userD?.jwt_token;
    }
    return null;
  };

  static getUpdateUserToken = (access_token: any) => {
    if (!access_token) {
      return false;
    }
    let userD = this.getSessionItemData("MYCIRCLE");
    userD.jwt_token = access_token;
    this.setUserData(JSON.stringify(userD));
    return true;
  };

  static checkUserToken = () => {
    const userD = this.getSessionItemData("MYCIRCLE");
    if (userD && userD?.jwt_token) {
      if (this.isAuthTokenValid(userD?.jwt_token)) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  static isAuthTokenValid = (access_token: any) => {
    if (!access_token) {
      return false;
    }
    const decoded: any = jwtDecode(access_token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      console.warn("access token expired");
      return false;
    } else {
      return true;
    }
  };

  static isAuthTokenCheckUser = () => {
    const userD = this.getSessionItemData("MYCIRCLE");
    if (userD && userD?.jwt_token) {
      const decoded: any = jwtDecode(userD?.jwt_token);
      if (this.checkFieldValue(decoded, "user_type")) {
        if (decoded?.user_type === "sub_user") {
          return "subUser";
        } else {
          return "user";
        }
      }
      // if (
      //   this.checkFieldValue(decoded, "id") &&
      //   this.checkFieldValue(decoded, "client_id")
      // ) {
      //   if (Number(decoded?.id) === Number(decoded?.client_id)) {
      //     return "user";
      //   } else {
      //     return "subUser";
      //   }
      // }
    }
    return "";
  };

  // domain name
  static getDomainName = (isPublic = false) => {
    if (isPublic === true) {
      if (typeof window?.location?.pathname !== "undefined") {
        const splitArr = window?.location?.pathname?.split("/");
        if (this.isFromScheduledown()) {
          if (splitArr?.length >= 4) {
            return splitArr[3];
          }
        }
        if (splitArr?.length >= 2) {
          return splitArr[1];
        }
      }
    } else {
      const userD = this.getSessionItemData("MYCIRCLE");
      if (userD && userD?.domain_name) {
        return userD?.domain_name;
      }
      if (typeof window?.location?.pathname !== "undefined") {
        const splitArr = window?.location?.pathname?.split("/");
        if (this.isFromScheduledown()) {
          if (splitArr?.length >= 4) {
            return splitArr[3];
          }
        }
        if (splitArr?.length >= 2) {
          return splitArr[1];
        }
      }
    }
    return null;
  };

  // is remember set
  static setIsRememberUserData = (userData: any) => {
    this.setStorageData("MYCIRCLE_USER_REM", userData);
  };

  // for set Location Data
  static setLocationData = (locationData: any = sbLocObj()) => {
    this.setStorageData("LOCATION", JSON.stringify(locationData));
  };

  // for get location Data
  static getLocationData = () => {
    const locData = this.getSessionItemData("LOCATION");
    return locData;
  };

  // for get location Id/name/obj
  static getLocationObj = () => {
    const locObj = sbLocObj();
    let fLoc = null;
    if (
      typeof locObj?.location_id !== "undefined" &&
      locObj?.location_id !== null &&
      Number.isSafeInteger(locObj?.location_id)
    ) {
      fLoc = locObj;
    } else {
      const locData = sbLocCount();
      if (
        typeof locData?.locData !== "undefined" &&
        locData?.locData !== null
      ) {
        const { value, label }: any = locData.locData;
        fLoc = {
          location_id: Number(value),
          location_name: label,
        };
      } else {
        const locSData = this.getLocationData();
        if (
          typeof locSData?.location_id !== "undefined" &&
          locSData?.location_id !== null
        ) {
          fLoc = locSData;
        }
      }
    }
    return fLoc;
  };

  static getLocationId = () => {
    const locData = this.getLocationObj();
    let fLoc = null;
    if (locData !== null) {
      if (
        typeof locData?.location_id !== "undefined" &&
        locData?.location_id !== null
      ) {
        fLoc = Number(locData?.location_id);
      }
    }
    return fLoc;
  };

  // for get location Id
  static getLocationName = () => {
    const locData = this.getLocationObj();
    let fLoc = null;
    if (locData !== null) {
      if (
        typeof locData?.location_name !== "undefined" &&
        locData?.location_name !== null
      ) {
        fLoc = locData?.location_name;
      }
    }
    return fLoc;
  };

  // for get location Data from cache
  static getLocationDataFApollo = () => {
    const locData = this.getLocationObj();
    let fLoc = null;
    if (locData !== null) {
      if (
        typeof locData?.location_name !== "undefined" &&
        locData?.location_name !== null
      ) {
        const { location_name, location_id }: any = locData;
        fLoc = {
          label: location_name,
          value: location_id,
        };
      }
    }
    return fLoc;
  };

  // clear location data
  static clearLocationData = () => {
    sbLocObj({
      location_id: null,
      location_name: null,
    });
    sbLocCount({
      count: 0,
      locData: null,
    });
    this.removeItemData("LOCATION");
    return;
  };

  // check system all layout route
  static hasAllLayoutRoutesPermission = (currentRoute: string) => {
    const isDomainName = this.getDomainName(true);
    const splitArr = currentRoute.split("/");
    const accessRoutes = [
      "/call",
      "/cl-signin",
      "/cl-signup",
      "/cl-forgot-password",
      "/",
      "/signin",
      "/signup",
      "/forgot-password",
      "/logout",
      "/" + isDomainName + "/sub-user-login",
      "/switch_platform",
      "/partners/reputation/switch_platform",
      "/main",
      "/dashboard",
      "/cl-dashboard",
      "/marketing",
      "/MeetingRecords",
      "/blast-campaign",
      "/s",
      "/auto-responder",
      '/smart-qr',
      '/get-new-customer',
      '/get-new-customer/custom-forms',
      "/auto-responder/overview",
      "/" + isDomainName + "/vrr",
      "/" + isDomainName + "/vbc",
      "/" + isDomainName + "/vrc",
      "/" + isDomainName + "/vlp",
      "/" + isDomainName + "/vlc",
      "/" + isDomainName + "/wid",
      "/" + isDomainName + "/drc",
      "/marketing/custom-forms",
      "/smart-qr/custom-forms",
      "/" + isDomainName + "/cf",
      "/marketing/widgets",
      "/marketing/web-signup-qr",
      "/smart-qr/web-signup-qr",
      "/marketing/web-signup-image",
      "/smart-qr/web-signup-image",
      "/marketing/delivery-redirection",
      "/" + isDomainName + "/wjg",
      "/" + isDomainName + "/wsw",
      "/clients",
      "/cl-clients",
      "/cl-clients/add-client",
      "/cl-clients/edit-client",
      "/clients/add-client",
      "/clients/edit-client",
      "/clients/timeline",
      "/groups",
      "/groups/add-group",
      "/groups/edit-group",
      "/account-settings",
      "/plans",
      "/payments",
      "/payments/credit-cards",
      "/payments/credit-cards/add-credit-card",
      "/payments/credit-cards/edit-credit-card",
      "/location-settings",
      "/users",
      "/users/add-sub-user",
      "/users/edit-sub-user",
      "/change-password",
      "/terms-and-conditions",
      "/" + isDomainName + "/view-terms-and-conditions",
      "/sms-chat",
      "/notifications",
      "/wifi-dashboard",
      "/hotspots",
      "/hotspots/hotspot-info",
      "/hotspots/add-hotspot",
      "/hotspots/edit-hotspot",
      "/splashpages",
      "/splashpages/add-splashpage",
      "/splashpages/edit-splashpage",
      "/partners/wifi/wifi-dashboard",
      "/partners/wifi/hotspots",
      "/partners/wifi/hotspots/hotspot-info",
      "/partners/wifi/hotspots/add-hotspot",
      "/partners/wifi/hotspots/edit-hotspot",
      "/partners/wifi/splashpages",
      "/partners/wifi/splashpages/add-splashpage",
      "/partners/wifi/splashpages/edit-splashpage",
      "/" + isDomainName + "/wl",
      "/sdcust/wifi/" + isDomainName + "/wl",
      "/splash",
      "/sdcust/splash",
      "/reservation",
      "/reservation/add-reservation",
      "/reservation/edit-reservation",
      "/floors",
      "/floors/add-floor",
      "/floors/edit-floor",
      "/floors/add-table",
      "/floors/edit-table",
      "/menu",
      "/menu/add-menu",
      "/menu/category",
      "/menu/add-category",
      "/menu/add-product",
      "/menu/edit-product",
      "/menu/variations",
      "/menu/add-variations",
      "/menu-qr",
      "/menulist",
      "/menulist/category",
      "/menulist/category/customization",
      "/" + isDomainName + "/vmq",
      "/" + isDomainName + "/preview",
      "/" + isDomainName + "/arlq",
      "/marketplace",
      "/" + isDomainName + "/home",
      "/demo",
      "/error",
      "/rep-dashboard",
      "/partners/reputation/rep-dashboard",
      "/officeSupplies",
      "/partners/reputation/officeSupplies",
      "/reputation",
      "/partners/reputation/reputation",
      "/" + isDomainName + "/rp",
      "/sdcust/reputation/" + isDomainName + "/rp",
      "/" + isDomainName + "/vdr",
      "/loyalty-program",
      "/add-stamp",
      "/menu",
      "/res-dashboard",
      "/reseller",
      "/orderDevice",
      "/policies",
      "/terms-service",
      "/privacy-policy",
      "/other-products",
      "/pitchdeck",
    ];
    if (splitArr?.length >= 2) {
      let finalUrl = "";
      let checkUrl = false;
      for (let i = 0; i < splitArr.length; i++) {
        if (i > 0) {
          finalUrl = finalUrl + "/";
        }
        finalUrl = finalUrl + splitArr[i];
        if (checkUrl === false) {
          checkUrl = accessRoutes.includes(finalUrl);
        }
      }

      return checkUrl || accessRoutes.includes(finalUrl);
    } else {
      return accessRoutes.includes(currentRoute);
    }
  };

  // for get side bar Data
  static getSideBarData = (states: any, apcVar: any, item: any) => {
    let sData = null;
    if (typeof states !== "undefined" && states !== null) {
      const { cp, si }: any = states;
      if (
        typeof cp !== "undefined" &&
        cp !== null &&
        typeof si !== "undefined" &&
        si !== null
      ) {
        sData = { cp: cp, si: si };
      }
    }
    if (sData === null) {
      if (
        typeof apcVar?.sidebar_menu !== "undefined" &&
        apcVar?.sidebar_menu !== null
      ) {
        const { cp, si }: any = apcVar?.sidebar_menu;
        if (
          typeof cp !== "undefined" &&
          cp !== null &&
          typeof si !== "undefined" &&
          si !== null
        ) {
          sData = { cp: cp, si: si };
        }
      }
    }
    if (sData === null) {
      if (
        typeof item !== "undefined" &&
        item !== null &&
        typeof item?.url !== "undefined"
      ) {
        const newUrl = `${item?.url}`;
        const isNPFObj = this.checkRouteMenus(newUrl);
        sData = { cp: isNPFObj.currentPlatform, si: item };
      }
    }
    return sData;
  };

  // check layout route permissions
  static hasPublicLayoutRoutesPermission = (currentRoute: string) => {
    const isDomainName = this.getDomainName(true);
    const splitArr = currentRoute.split("/");
    const accessRoutes = [
      "/call",
      "/cl-signin",
      "/cl-signup",
      "/cl-forgot-password",
      "/",
      "/signin",
      "/signup",
      "/forgot-password",
      "/logout",
      "/" + isDomainName + "/sub-user-login",
      "/switch_platform",
      "/partners/reputation/switch_platform",
      "/main",
      "/s",
      "/" + isDomainName + "/wjg",
      "/" + isDomainName + "/cf",
      "/" + isDomainName + "/wsw",
      "/" + isDomainName + "/vmq",
      "/" + isDomainName + "/preview",
      "/" + isDomainName + "/arlq",
      "/" + isDomainName + "/home",
      "/" + isDomainName + "/wl",
      "/sdcust/wifi/" + isDomainName + "/wl",
      "/splash",
      "/sdcust/splash",
      "/" + isDomainName + "/vrr",
      "/" + isDomainName + "/vbc",
      "/" + isDomainName + "/drc",
      "/" + isDomainName + "/vrc",
      "/" + isDomainName + "/vlp",
      "/" + isDomainName + "/vlc",
      "/" + isDomainName + "/wid",
      "/splashpages/add-splashpage",
      "/partners/wifi/splashpages/add-splashpage",
      "/splashpages/edit-splashpage",
      "/partners/wifi/splashpages/edit-splashpage",
      "/" + isDomainName + "/rp",
      "/sdcust/reputation/" + isDomainName + "/rp",
      "/" + isDomainName + "/vdr",
      "/" + isDomainName + "/view-terms-and-conditions",
      "/error",
      "/terms-service",
      "/privacy-policy",
      "/other-products",
      "/pitchdeck",
    ];
    if (splitArr?.length >= 2) {
      let finalUrl = "";
      let checkUrl = false;
      for (let i = 0; i < splitArr.length; i++) {
        if (i > 0) {
          finalUrl = finalUrl + "/";
          if (i > 3) {
            if (!AppUtils.isFromScheduledown()) {
              break;
            }
          }
        }
        finalUrl = finalUrl + splitArr[i];
        if (checkUrl === false) {
          checkUrl = accessRoutes.includes(finalUrl);
        }
      }

      return checkUrl || accessRoutes.includes(finalUrl);
    } else {
      return accessRoutes.includes(currentRoute);
    }
  };

  // for platform and side bar menus
  static checkRouteMenus = (currentRoute: string) => {
    let currentPlatform = "";
    let finalUrl = "";
    const isDomainName = this.getDomainName(true);
    const splitArr = currentRoute.split("/");
    const mktRoutes = [
      "/main",
      "/call",
      "/switch_platform",
      "/partners/reputation/switch_platform",
      "/dashboard",
      "/cl-dashboard",
      "/marketing",
      "/blast-campaign",
      "/s",
      "/auto-responder",
      "/auto-responder/overview",
      '/smart-qr',
      '/get-new-customer',
      '/get-new-customer/custom-forms',
      "/vrr",
      "/vbc",
      "/drc",
      "/marketing/custom-forms",
      "/smart-qr/custom-forms",
      "/cf",
      "/marketing/widgets",
      "/marketing/web-signup-qr",
      '/smart-qr/web-signup-qr',
      "/marketing/web-signup-image",
      "/smart-qr/web-signup-image",
      "/marketing/delivery-redirection",
      "/wjg",
      "/wsw",
      "/wid",
      "/vdr",
    ];
    const menuonlineRoutes = [
      // "/reservation",
      // "/reservation/res-settings",
      // "/reservation/add-reservation",
      // "/reservation/edit-reservation",
      // "/floors",
      // "/floors/add-floor",
      // "/floors/edit-floor",
      // "/floors/add-table",
      // "/floors/edit-table",
      "/menu",
      "/menu/add-menu",
      "/menu/category",
      "/menu/add-category",
      "/menu/add-product",
      "/menu/edit-product",
      "/menu/variations",
      "/menu/add-variations",
      "/menu-qr",
      "/menulist",
      "/menulist/category",
      "/menulist/category/customization",
      "/vmq",
      "/preview",
      "/arlq",
      "/home",
      "/menu",
    ];
    const wifiRoutes = [
      "/wifi-dashboard",
      "/hotspots",
      "/hotspots/hotspot-info",
      "/hotspots/add-hotspot",
      "/hotspots/edit-hotspot",
      "/splashpages",
      "/splashpages/add-splashpage",
      "/splashpages/edit-splashpage",
      "/wl",
      "/sdcust/wifi/wl",
      "/splash",
      "/sdcust/splash",
      "/partners/wifi/wifi-dashboard",
      "/partners/wifi/hotspots",
      "/partners/wifi/hotspots/hotspot-info",
      "/partners/wifi/hotspots/add-hotspot",
      "/partners/wifi/hotspots/edit-hotspot",
      "/partners/wifi/splashpages",
      "/partners/wifi/splashpages/add-splashpage",
      "/partners/wifi/splashpages/edit-splashpage",
    ];
    const clientsRoutes = [
      "/clients",
      "/cl-clients",
      "/cl-clients/add-client",
      "/cl-clients/edit-client",
      "/clients/add-client",
      "/clients/edit-client",
      "/clients/timeline",
      "/groups",
      "/groups/add-group",
      "/groups/edit-group",
    ];
    const settingsRoutes = [
      "/account-settings",
      "/plans",
      "/payments",
      "/payments/credit-cards",
      "/payments/credit-cards/add-credit-card",
      "/payments/credit-cards/edit-credit-card",
      "/location-settings",
      "/MeetingRecords",
      "/users",
      "/users/add-sub-user",
      "/users/edit-sub-user",
      "/change-password",
      "/terms-and-conditions",
      "/view-terms-and-conditions",
      "/sms-chat",
      "/notifications",
      "/marketplace",
    ];
    const resellerRoutes = [
      "/res-dashboard",
      "/reseller",
      "/orderDevice",
      "/policies",
    ];
    const reservationRoutes = [
      "/reservation",
      "/reservation/res-settings",
      "/reservation/add-reservation",
      "/reservation/edit-reservation",
      "/floors",
      "/floors/add-floor",
      "/floors/edit-floor",
      "/floors/add-table",
      "/floors/edit-table",
    ];

    const reputationRoutes = [
      "/rep-dashboard",
      "/partners/reputation/rep-dashboard",
      "/reputation",
      "/partners/reputation/reputation",
      "/rp",
      "/sdcust/reputation/rp",
      "/vrc",
      "/officeSupplies",
      "/partners/reputation/officeSupplies",
    ];
    const LoyaltyProgramRoutes = [
      "/loyalty-program",
      "/add-stamp",
      "vlp",
      "vlc",
    ];

    const publicUrl = [
      "call",
      "vrr",
      "vrc",
      "cf",
      "wjg",
      "wsw",
      "vmq",
      "arlq",
      "home",
      "wl",
      "rp",
      "rp",
      "wid",
      "vlp",
      "vlc",
    ];

    if (splitArr?.length >= 2) {
      for (let i = 0; i < splitArr.length; i++) {
        if (i > 2) {
          if (!AppUtils.isFromScheduledown()) {
            break;
          }
        }
        let passNext = true;
        if (
          i > 0 &&
          splitArr?.length > i + 1 &&
          publicUrl.includes(splitArr[i + 1])
        ) {
          if (isDomainName === splitArr[i]) {
            passNext = false;
          }
        }
        if (passNext) {
          if (i > 0) {
            finalUrl = finalUrl + "/";
          }
          if (
            splitArr?.length === 3 &&
            ["s", "switch_platform"].includes(splitArr[i])
          ) {
            finalUrl = finalUrl + splitArr[i];
            break;
          }

          finalUrl = finalUrl + splitArr[i];
        }
      }
      if (mktRoutes.includes(finalUrl)) {
        currentPlatform = "marketing";
      }
      if (currentPlatform === "") {
        if (menuonlineRoutes.includes(finalUrl)) {
          currentPlatform = "menu-online";
        }
      }
      if (currentPlatform === "") {
        const isMatch = wifiRoutes.some(route => finalUrl.startsWith(route));
        if (isMatch) {
          currentPlatform = "wifi";
        }

        // } else {
        //   if (wifiRoutes.includes(finalUrl)) {
        //     currentPlatform = "wifi";
        //   }
        // }
      }
      if (currentPlatform === "") {
        if (reputationRoutes.includes(finalUrl)) {
          currentPlatform = "reputation";
        }
      }
      if (currentPlatform === "") {
        if (LoyaltyProgramRoutes.includes(finalUrl)) {
          currentPlatform = "loyalty_program";
        }
      }
      if (currentPlatform === "") {
        if (clientsRoutes.includes(finalUrl)) {
          currentPlatform = "clients";
        }
      }
      if (currentPlatform === "") {
        if (settingsRoutes.includes(finalUrl)) {
          currentPlatform = "settings";
        }
      }
      if (currentPlatform === "") {
        if (resellerRoutes.includes(finalUrl)) {
          currentPlatform = "reseller";
        }
      }
      if (currentPlatform === "") {
        if (reservationRoutes.includes(finalUrl)) {
          currentPlatform = "reservation";
        }
      }
    }

    return { currentPlatform, finalUrl };
  };

  static checkSubRouteMenus = (currentRoute: string, sideMenu: any) => {
    const splitArr = currentRoute.split("/");
    if (splitArr?.length >= 3) {
      let finalUrl = "";
      let checkUrl = false;
      for (let i = 0; i < splitArr.length; i++) {
        if (i > 0) {
          finalUrl = finalUrl + "/";
        }
        finalUrl = finalUrl + splitArr[i];
        if (checkUrl === false) {
          checkUrl = `${sideMenu.url}` === `${finalUrl}`;
        }
      }
      if (checkUrl || `${sideMenu.url}` === `${finalUrl}`) {
        return true;
      }
    }
    return false;
  };

  // check marketing route
  static checkMarketingRoutes = (currentRoute: string) => {
    const splitArr = currentRoute.split("/");
    const accessRoutes = [
      "/blast-campaign",
      "/auto-responder",
      "/auto-responder/overview",
      "/marketing/custom-forms",
      "/smart-qr/custom-forms",
      "/get-new-customer/custom-forms",
      "/marketing/widgets",
      "/marketing/web-signup-qr",
      "/smart-qr/web-signup-qr",
      "/marketing/web-signup-image",
      "/smart-qr/web-signup-image",
      "/marketing/delivery-redirection",
    ];
    let isMarketing = false;
    let mktUrl = "";
    if (splitArr?.length >= 2) {
      let finalUrl = "";
      let checkUrl = false;
      for (let i = 0; i < splitArr.length; i++) {
        if (i > 0) {
          finalUrl = finalUrl + "/";
        }
        finalUrl = finalUrl + splitArr[i];
        if (checkUrl === false) {
          checkUrl = accessRoutes.includes(finalUrl);
          if (checkUrl) {
            mktUrl = finalUrl;
          }
        }
      }
      isMarketing = checkUrl || accessRoutes.includes(finalUrl);
      if (mktUrl == "") {
        mktUrl = finalUrl;
      }
    } else {
      isMarketing = accessRoutes.includes(currentRoute);
      if (mktUrl == "") {
        mktUrl = currentRoute;
      }
    }
    let hT = "";
    let hSubT = "";
    if (isMarketing == true) {
      if (mktUrl === "/blast-campaign") {
        hT = 'blast_campaigns';
        hSubT = 'blast_campaign_sub_title';
      } else if (
        mktUrl === "/auto-responder" ||
        mktUrl === "/auto-responder/overview"
      ) {
        hT = 'auto_responder';
        hSubT = 'auto_responder_sub_title';
      } else if (mktUrl === "/marketing/custom-forms" || mktUrl === "/smart-qr/custom-forms" || mktUrl === "/get-new-customer/custom-forms") {
        hT = 'custom_forms';
        hSubT = 'cFDesc';
      } else if (mktUrl === "/marketing/widgets") {
        hT = 'WebSignUpT';
        hSubT = 'signWidDesc';
      } else if (mktUrl === "/marketing/web-signup-qr" || mktUrl === "/smart-qr/web-signup-qr") {
        hT = 'qrWidT';
        hSubT = 'group_smart_qr_opt_in';
      } else if (mktUrl === "/marketing/web-signup-image" || mktUrl === "/smart-qr/web-signup-image") {
        hT = 'imgWidT';
        hSubT = 'group_smart_qr_opt_in_dec';
      } else if (mktUrl === "/marketing/delivery-redirection") {
        hT = 'delivery_redirection_text';
        hSubT = 'in_store_discount_next_visit';
      }
    }
    return { isMarketing: isMarketing, hT: hT, hSubT: hSubT };
  };

  // check refresh layout route
  static refreshRoutesAfterLocation = (currentRoute: string) => {
    const splitArr = currentRoute.split("/");
    const accessRoutes = [
      "/account-settings",
      "/plans",
      "/payments",
      "/payments/credit-cards",
      "/payments/credit-cards/add-credit-card",
      "/payments/credit-cards/edit-credit-card",
      "/change-password",
      "/notifications",
      "/marketplace",
    ];
    if (splitArr?.length >= 2) {
      let finalUrl = "";
      let finalUrl1 = "";
      let checkUrl = false;
      for (let i = 0; i < splitArr.length; i++) {
        if (i > 0) {
          finalUrl = finalUrl + "/";
          if (!(i > 1)) {
            finalUrl1 = finalUrl1 + "/";
          }
        }
        finalUrl = finalUrl + splitArr[i];
        if (!(i > 1)) {
          finalUrl1 = finalUrl1 + splitArr[i];
        }
        if (checkUrl === false) {
          checkUrl = accessRoutes.includes(finalUrl);
        }
      }
      return {
        mUrl: finalUrl1,
        valid: checkUrl || accessRoutes.includes(finalUrl),
      };
    } else {
      return { mUrl: currentRoute, valid: accessRoutes.includes(currentRoute) };
    }
  };

  // check refresh layout route
  static getBackUrlRoute = (currentRoute: string, isBack: boolean) => {
    const accessRoutes = ["/auto-responder", "/auto-responder/overview"];
    const splitArr = currentRoute.split("/");
    if (splitArr?.length > 2) {
      let finalUrl = currentRoute;
      if (isBack) {
        finalUrl = "";
        for (let i = 0; i < splitArr.length; i++) {
          if (
            splitArr?.length > 4 ||
            (splitArr?.length > 4 && splitArr[i] === "edit-table") ||
            (splitArr?.length > 3 &&
              (splitArr[i] === "credit-cards" ||
                splitArr[i] === "add-product" ||
                splitArr[i] === "add-category" ||
                splitArr[i] === "edit-product"))
          ) {
            if (
              splitArr[i] === "add-product" ||
              splitArr[i] === "add-category"
            ) {
              finalUrl = `/menu/category/${splitArr[splitArr?.length - 1]}`;
              break;
            }
            if (splitArr[i] === "edit-product") {
              finalUrl = `/menu/category/${splitArr[splitArr?.length - 2]}`;
              break;
            }
            if (splitArr[i] === "edit-table") {
              finalUrl = `/floors`;
              break;
            }
            if (i > 2) {
              break;
            }
          } else if (splitArr?.length > 2) {
            if (i > 1) {
              break;
            }
          }
          if (i > 0) {
            finalUrl = finalUrl + "/";
          }
          finalUrl = finalUrl + splitArr[i];
        }
        if (accessRoutes.indexOf(finalUrl) != -1) {
          finalUrl = "/marketing";
        }
      }
      return { mUrl: finalUrl, valid: true };
    } else {
      return { mUrl: currentRoute, valid: false };
    }
  };

  // check Authorization allow or not
  static checkAuthorizationApi = (name: string) => {
    const apiList = [
      "GenerateRefreshtoken",
      "AddTempClient",
      "SendOrResendClientOtp",
      "SubmitClientOtp",
      "SubmitSignUpOtp",
      "AddClient",
      "ForgotPassword",
      "SubUserLogin",
      "CheckDomainName",
      "GetShortLink",
      "SwitchPlatform",
      "AutoResponderFindByCouponCode",
      "CustomFormFindByCode",
      "SubmitCustomFrom",
      "WidgetFindByCode",
      "SubmitWidgetFrom",
      // "CategoryList",
      "ClientHomeCategoryList",
      // "MenuDropdown",
      "CategoryListMP",
      "ClientMenuDropdown",
      "FrontendMenuData",
      "SubmitContactUsForm",
      "HotspotCustomerLogin",
      "HotspotCustomerResendOtp",
      "HotspotCustomerSubmitOtp",
      "EditHotspotCustomer",
      "SplashFindByCode",
      "SplashPagePreview",
      "MarketPlaceFindByCode",
      "AddReservationMP",
      "Logout",
      "ReputationFindByCode",
      "ReputationFormSubmit",
      "ReputationFindByCouponCode",
      "RedeemAutoResponderCoupon",
      "RedeemReputationCoupon",
      "BlastSmsFindByCouponCode",
      "RedeemBlastSmsCoupon",
      "TermsAndConditionsByDomainName",
      "WidgetFindByCouponCode",
      "RedeemWidgetCoupon",
      "LoyaltyUserFindByUniqueUserSide",
      "LoyaltyUserFindByIdUserSide",
      "LoyaltyPointListUserSide",
      "AddLoyaltyUserWithHubCustomerUserSide",
      "LoyaltyFindByIdUserSide",

      "LoyaltyRewardListUserSide",
      "DeliveryRedirectionFindByCode",
      "DeliveryRedirectionFormSubmit",
      "RedeemDeliveryRedirectionCoupon",
      "DeliveryRedirectionFindByCouponCode",
    ];
    return apiList.includes(name);
  };

  // check permission of routes
  static checkRoutesPermission = (menuRoot: string) => {
    let validRoot = false;
    if (menuRoot === "is_all_users_access") {
      validRoot = true;
    } else if (menuRoot === "is_main_users_access") {
      const userType = this.isAuthTokenCheckUser();
      if (userType === "user") {
        validRoot = true;
      } else if (userType === "subUser") {
        validRoot = false;
      }
    } else {
      const userD = this.getSessionItemData("MYCIRCLE");
      if (userD && typeof userD[menuRoot] !== "undefined") {
        validRoot = userD[menuRoot];
      }
    }

    return validRoot;
  };

  // check platform permission of routes
  static checkPlatformPermission = (strArr: boolean) => {
    let validPlatform: any[] = [];
    const userD = this.getSessionItemData("MYCIRCLE");
    if (userD) {
      if (typeof userD["is_marketing_access"] !== "undefined") {
        if (userD["is_marketing_access"]) {
          if (strArr) {
            validPlatform.push("marketing");
          } else {
            validPlatform.push({
              value: ConstantsHelper.marketT,
              label: ConstantsHelper.marketT,
            });
          }
        }
      }
      if (typeof userD["is_wifi_access"] !== "undefined") {
        if (userD["is_wifi_access"]) {
          if (strArr) {
            validPlatform.push("wifi");
          } else {
            validPlatform.push({
              value: ConstantsHelper.sWifiT,
              label: ConstantsHelper.sWifiT,
            });
          }
        }
      }
      if (typeof userD["is_menu_online_access"] !== "undefined") {
        if (userD["is_menu_online_access"]) {
          if (strArr) {
            validPlatform.push("menu-online");
          } else {
            validPlatform.push({
              value: ConstantsHelper.menuOnT,
              label: ConstantsHelper.menuOnT,
            });
          }
        }
      }
      if (typeof userD["is_signage_access"] !== "undefined") {
        if (userD["is_signage_access"]) {
          if (strArr) {
            validPlatform.push("ds");
          } else {
            validPlatform.push({
              value: ConstantsHelper.digiSignT,
              label: ConstantsHelper.digiSignT,
            });
          }
        }
      }
      if (typeof userD["is_schedule_access"] !== "undefined") {
        if (userD["is_schedule_access"]) {
          if (strArr) {
            validPlatform.push("schedule");
          } else {
            validPlatform.push({
              value: ConstantsHelper.scheduleT,
              label: ConstantsHelper.scheduleT,
            });
          }
        }
      }
      if (typeof userD["is_loyalty_program_access"] !== "undefined") {
        if (userD["is_loyalty_program_access"]) {
          if (strArr) {
            validPlatform.push("loyalty_program");
          } else {
            validPlatform.push({
              value: ConstantsHelper.loyalProT,
              label: ConstantsHelper.loyalProT,
            });
          }
        }
      }
      if (typeof userD["is_reputation_access"] !== "undefined") {
        if (userD["is_reputation_access"]) {
          if (strArr) {
            validPlatform.push("reputation");
          } else {
            validPlatform.push({
              value: ConstantsHelper.repuT,
              label: ConstantsHelper.repuT,
            });
          }
        }
      }
      if (typeof userD["is_reseller"] !== "undefined") {
        if (userD["is_reseller"]) {
          if (strArr) {
            validPlatform.push("reseller");
          } else {
            validPlatform.push({
              value: ConstantsHelper.ReSel,
              label: ConstantsHelper.ReSel,
            });
          }
        }
      }
    }
    return validPlatform;
  };

  // get api error message
  static apiErrorHandling = (formik: any, fields: string[], error: any) => {
    let isError = true;
    if (typeof error !== "undefined" && error !== null) {
      for (let i = 0; i < fields.length; i++) {
        if (
          typeof error[fields[i]] !== "undefined" &&
          error[fields[i]] !== null &&
          error[fields[i]] !== ""
        ) {
          formik.setFieldTouched(fields[i], true, false);
          formik.setFieldError(fields[i], error[fields[i]]);
          isError = false;
        }
      }
    }
    return isError;
  };

  static apiErrorHandlingWithField = (
    formik: any,
    field: string,
    efield: string,
    error: any
  ) => {
    let isError = true;
    if (this.checkFieldValue(error, efield)) {
      formik.setFieldTouched(field, true, false);
      formik.setFieldError(field, error[efield]);
      isError = false;
    }
    return isError;
  };

  // get error message
  static catchErrorHandling = (e: any) => {
    // console.log("catch error :: ", e);
    let errMsg = ConstantsHelper.fetchErrorMsg;
    try {
      if (e?.toString()) {
        const errArr = e.toString().split(".");
        if (errArr?.length > 0) {
          const errSArr = errArr[0].split("Error:");
          if (errSArr?.length > 1) {
            if (errSArr[1] && typeof errSArr[1] !== "undefined") {
              const nEStr =
                typeof errSArr[1] !== "string"
                  ? errSArr[1]?.toString()
                  : errSArr[1];
              const onlyCode = nEStr.replace(/[^0-9]/g, "");
              const errCode = [400, 401, 402, 500];
              if (errCode.includes(Number(onlyCode)) !== true) {
                errMsg = errSArr[1];
              } else {
                errMsg = ConstantsHelper.userErrorMsg;
              }
            }
          }
        }
      }
    } catch (ec: any) {
      // console.log("catch error1 :: ", ec);
      errMsg = ConstantsHelper.userErrorMsg;
    }
    return errMsg;
  };

  static checkErrorMsg = (e: any) => {
    // console.log("msg :: ", e);
    let errMsg = ConstantsHelper.userErrorMsg;
    try {
      if (this.checkDirectValue(e)) {
        errMsg = e;
      }
    } catch (ec: any) {
      // console.log("catch error1 :: ", ec);
      errMsg = ConstantsHelper.userErrorMsg;
    }
    return errMsg;
  };

  // set custom wishes of time
  static checkTimeWishes = () => {
    const today = new Date();
    const curHr = today.getHours();
    let currentTimeW = "";

    if (curHr < 12) {
      currentTimeW = ConstantsHelper.morT;
    } else if (curHr < 18) {
      currentTimeW = ConstantsHelper.afternT;
    } else {
      currentTimeW = ConstantsHelper.evenT;
    }
    return currentTimeW;
  };

  // check any field for value
  static checkFieldValue = (data: any, field: any) => {
    let isData = false;
    if (
      this.checkDirectValue(data) &&
      typeof data?.[field] !== "undefined" &&
      data?.[field] !== null
    ) {
      if (typeof data?.[field] === "string" && data?.[field]?.trim() !== "") {
        isData = true;
      } else if (typeof data?.[field] === "boolean") {
        isData = true;
      } else if (typeof data?.[field] === "number") {
        isData = true;
      } else if (typeof data?.[field] === "object") {
        isData = true;
      }
    }
    return isData;
  };

  // check any value
  static checkDirectValue = (field: any) => {
    let isData = false;
    if (typeof field !== "undefined" && field !== null) {
      if (typeof field === "string" && field?.trim() !== "") {
        isData = true;
      } else if (typeof field === "boolean") {
        isData = true;
      } else if (typeof field === "number") {
        isData = true;
      } else if (typeof field === "object") {
        isData = true;
      }
    }
    return isData;
  };

  // check any value from arry
  static checkValueFromArr = (arr: any, fields: any) => {
    let isValid = false;
    if (typeof arr !== "undefined" && arr !== null && arr?.length > 0) {
      let passVal = 0;
      for (let i = 0; i < fields.length; i++) {
        if (arr.includes(fields[i])) {
          passVal = passVal + 1;
        }
      }
      if (passVal === fields?.length) {
        isValid = true;
      }
    }
    return isValid;
  };

  // check no value
  static checkNoFieldValue = (field: any) => {
    let isData = false;
    if (typeof field === "undefined" || field === null || field?.length === 0) {
      isData = true;
    }
    return isData;
  };

  // get current view width and height
  static useContainerDimensions = (divRef: any) => {
    const getDimensions = () => ({
      width: divRef?.current?.offsetWidth ? divRef.current.offsetWidth : 0,
      height: divRef?.current?.offsetHeight ? divRef.current.offsetHeight : 0,
      top:
        (divRef?.current?.offsetTop ? divRef.current.offsetTop : 0) +
        (divRef?.current?.offsetHeight ? divRef.current.offsetHeight : 0),
    });

    const [dimensions, setDimensions] = React.useState({
      width: 0,
      height: 0,
      top: 0,
    });

    React.useEffect(() => {
      const handleResize = () => {
        setDimensions(getDimensions());
      };

      if (divRef?.current) {
        setDimensions(getDimensions());
      }

      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, [divRef]);

    return dimensions;
  };

  // // for remove null value
  // static updateNullValueObject = (userData: any) => {
  // 	const userObject = Object.entries(userData)
  // 	let finalUser = {}
  // 	for (let i=0; i < userObject.length; i++) {
  // 		const [key, value] = userObject[i];
  // 		if ((value === null) || (value === 'null')) {
  // 			finalUser[key] = ""
  // 		}
  // 		else {
  // 			finalUser[key] = value
  // 		}
  // 	}
  // 	return finalUser
  // };

  // capital letter set
  static setCapitalize = (str: any) => {
    if (str) {
      var splitStr = str.toLowerCase().split(" ");
      for (let i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      // Directly return the joined string
      return splitStr.join(" ");
    } else {
      return "";
    }
  };

  // user name with first letter
  static displayUserName(fn: any, ln: any) {
    let flName = "";
    let fnArr = null;
    if (this.checkDirectValue(fn)) {
      const splitStr = fn?.toString()?.split("");
      fnArr = splitStr;
      if (splitStr && splitStr?.length > 0) {
        for (let i = 0; i < splitStr.length; i++) {
          if (i < 1) {
            flName = flName + splitStr[i]?.charAt(0)?.toUpperCase();
            break;
          }
        }
      }
    }
    if (this.checkDirectValue(ln)) {
      const splitStr = ln?.toString()?.split("");
      if (splitStr && splitStr?.length > 0) {
        for (let i = 0; i < splitStr.length; i++) {
          if (i < 1) {
            flName = flName + splitStr[i]?.charAt(0)?.toUpperCase();
            break;
          }
        }
      }
    } else {
      if (fnArr && fnArr?.length > 0) {
        for (let i = 0; i < fnArr.length; i++) {
          if (i > 0 && i < 2) {
            flName = flName + fnArr[i]?.charAt(0)?.toUpperCase();
            break;
          }
        }
      }
    }
    if (flName === "") {
      flName = "N/A";
    }
    return flName;
  }

  // Any title with capital letter
  static displayTitleWithCapital(tn: any) {
    let tName = "";
    if (this.checkDirectValue(tn)) {
      const splitStr = tn?.toString()?.split("");
      if (splitStr && splitStr?.length > 0) {
        for (let i = 0; i < splitStr.length; i++) {
          if (i === 0) {
            tName = tName + splitStr[i]?.charAt(0)?.toUpperCase();
          } else {
            tName = tName + splitStr[i];
          }
        }
      }
    }
    return tName;
  }

  //convert num to usd formate with use of currencySign
  static onlyNumberVal = (num: any) => {
    const eVal = num ? num : "";
    // const mVal = eVal?.toString()?.replace(/[^\d-]/g, "")?.replace("-", "")?.replace("$", "")?.replace(/,/g, "");
    const mVal = eVal.replace(/[^0-9]/g, "");
    return mVal;
  };

  static onlyNumberWithDecimalVal = (num: any, oldVal: any) => {
    const eVal = num ? num : "";
    // const mVal = eVal?.toString()?.replace(/[^\d-]/g, "")?.replace("-", "")?.replace("$", "")?.replace(/,/g, "");
    const mVal = eVal.replace(/[^0-9.]/g, "");
    let newTVal = "";

    if (
      mVal !== "" &&
      (/^\d+$/.test(mVal) ||
        /^\d+\.\d{0,0}$/.test(mVal) ||
        /^\d+\.\d{0,1}$/.test(mVal) ||
        /^\d+\.\d{0,2}$/.test(mVal) ||
        /^\d+\.\d{0,3}$/.test(mVal))
    ) {
      newTVal = mVal;
    } else if (mVal !== "" && mVal === ".") {
      newTVal = "0.";
    } else if (mVal === "") {
      newTVal = mVal;
    } else {
      newTVal = oldVal;
    }
    return newTVal;
  };

  //convert num to usd formate with use of currencySign
  static currencyFormatWithCurrencySign = (num: any, crTx: string) => {
    const currencySign = crTx;
    if (num === null) {
      return currencySign + 0;
    } else if (num) {
      if (num !== "") {
        const checkAmount = (num + "").toString();
        const mainNum: string = checkAmount.replace("$", "").replace(/,/g, "");
        const regex = /^[0-9]+\.[0-9]{0,20}$/i;
        const regexDig = /^\d+$/;

        if (regex.test(mainNum) || regexDig.test(mainNum)) {
          if (isNaN(Number(mainNum))) {
            return (
              currencySign +
              Number(mainNum)
                .toFixed(2)
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
            );
          } else {
            const newNum = Number(mainNum);
            return (
              currencySign +
              newNum.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
            );
          }
        } else if (Number(mainNum) < 0) {
          const mainNum1: number = Number(mainNum) * -1;
          return (
            "-" +
            currencySign +
            Number(mainNum1)
              .toFixed(2)
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
          );
        } else {
          return num;
        }
      } else {
        return num;
      }
    } else if (num === "" || num === " ") {
      return num;
    } else {
      return currencySign + num;
    }
    // return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  };

  // convert money formate typing time
  static formatMoneyNumber = (
    nAmount: string,
    decimalCount = 2,
    decimal = ".",
    thousands = ","
  ) => {
    let amount = nAmount;
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

      const negativeSign = Number(amount) < 0 ? "-" : "";

      let i = parseInt(
        (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
      ).toString();
      let j = i.length > 3 ? i.length % 3 : 0;

      return (
        negativeSign +
        (j ? i.substring(0, j) + thousands : "") +
        i.substring(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
        (decimalCount
          ? decimal +
          Math.abs(Number(amount) - Number(i))
            .toFixed(decimalCount)
            .slice(2)
          : "")
      );
    } catch (e) {
      console.log("e : ", e);
      return "";
    }
  };

  static updateNumberWithCurrenySymbolString(
    targetValue: string,
    oldValue: string,
    update = false
  ) {
    const currency = "";
    let newTargetVal = "";
    if (targetValue !== "") {
      let mainResult = targetValue
        ?.toString()
        ?.replace(/[^\d.-]/g, "")
        ?.replace("-", "")
        ?.replace("$", "")
        ?.replace(/,/g, "");
      if (mainResult !== "" && /^\d+$/.test(mainResult)) {
        newTargetVal = currency + this.formatMoneyNumber(mainResult, 0);
      } else if (mainResult !== "" && /^\d+\.\d{0,0}$/.test(mainResult)) {
        newTargetVal = currency + mainResult;
      } else if (mainResult !== "" && /^\d+\.\d{0,1}$/.test(mainResult)) {
        newTargetVal = currency + this.formatMoneyNumber(mainResult, 1);
      } else if (mainResult !== "" && /^\d+\.\d{0,2}$/.test(mainResult)) {
        newTargetVal = currency + this.formatMoneyNumber(mainResult, 2);
      } else if (mainResult !== "" && /^\d+\.\d{0,3}$/.test(mainResult)) {
        if (update) {
          newTargetVal = currency + this.formatMoneyNumber(mainResult, 2);
        } else {
          newTargetVal = oldValue?.toString()?.replace("-", "");
        }
      } else if (mainResult !== "" && mainResult === ".") {
        newTargetVal = currency + this.formatMoneyNumber(mainResult, 0) + ".";
      } else if (
        mainResult === "" &&
        (targetValue === "$" || targetValue === "-$")
      ) {
        newTargetVal = mainResult;
      } else {
        if (update) {
          newTargetVal = currency + this.formatMoneyNumber(mainResult, 2);
        } else {
          newTargetVal = oldValue?.toString()?.replace("-", "");
        }
      }
    } else {
      let mainResult = targetValue
        ?.toString()
        ?.replace(/[^\d.-]/g, "")
        ?.replace("$", "")
        ?.replace(/,/g, "");
      if (mainResult !== "" && /^\d+$/.test(mainResult)) {
        newTargetVal = currency + this.formatMoneyNumber(mainResult, 0);
      } else {
        newTargetVal = mainResult;
      }
    }
    return newTargetVal;
  }

  // create random string
  static generateRandomString() {
    const result = Math.random().toString(36).substring(2, 7);
    return result;
  }

  // first letter capital
  static firstLetterCapitalize(str: string) {
    let splitStr: any[] = [];
    if (typeof str !== "undefined" && str !== null) {
      splitStr = str?.split(" ");
      // var splitStr = str.toLowerCase().split(' ');
      if (splitStr && splitStr?.length > 0) {
        for (let i = 0; i < splitStr.length; i++) {
          // You do not need to check if i is larger than splitStr length, as your for does that for you
          // Assign it back to the array
          splitStr[i] =
            splitStr[i]?.charAt(0)?.toUpperCase() + splitStr[i]?.substring(1);
        }
      }
    }

    // Directly return the joined string
    return splitStr.join(" ");
  }

  // random color choose
  static randomColorChoose = () => {
    const colors = [
      ColorsHelper.cardColor1,
      ColorsHelper.cardColor2,
      ColorsHelper.cardColor3,
      ColorsHelper.cardColor4,
      ColorsHelper.cardColor5,
      ColorsHelper.cardColor6,
    ];
    const randomColor = colors[Math.floor(Math.random() * colors?.length)];
    return randomColor;
  };

  // date formate setup
  static dateFormate = (value: string) => {
    let dStr = value;
    let formatted = "";
    if (typeof dStr !== "undefined" && dStr !== null) {
      dStr = dStr.replace(/[^0-9]/g, "");
      let block1 = "";
      let block2 = "";
      let block3 = "";

      //for set number formate 2-2-4
      block1 = dStr.substring(0, 2);

      if (block1?.length === 2 && dStr?.length > 2) {
        block1 = block1 + "/";
        if (parseInt(block1.split("/")[0]) > 31) block1 = "01/"; // added by Israil Gulzar
      }
      block2 = dStr.substring(2, 4);
      if (block2?.length === 2 && dStr?.length > 4) {
        if (parseInt(block2) > 12) block2 = "01"; // added by Israil Gulzar
        block2 = block2 + "/";
      }
      block3 = dStr.substring(4, 8);
      if (block3?.length === 4) {
        block3 = block3 + "";
      }

      formatted = block1 + block2 + block3;
    }
    return formatted;
  };

  // time formate setup
  static timeFormate = (value: string, is12H: boolean) => {
    let dStr = value;
    let formatted = "";
    if (typeof dStr !== "undefined" && dStr !== null) {
      dStr = dStr.replace(/[^0-9]/g, "");
      let block1 = "";
      let block2 = "";

      //for set number formate 2-2-4
      block1 = dStr.substring(0, 2);

      if (dStr?.length > 1 && parseInt(block1) > (is12H ? 12 : 23))
        block1 = "00"; // added by Israil Gulzar
      if (block1?.length === 2 && dStr?.length > 2) {
        block1 = block1 + ":";
      }
      block2 = dStr.substring(2, 4);
      if (dStr?.length > 3 && parseInt(block2) > 59) block2 = "00"; // added by Israil Gulzar
      if (block2?.length === 2 && dStr?.length > 4) {
        block2 = block2 + "";
      }

      formatted = block1 + block2;
    }
    return formatted;
  };

  // date formate setup with am/pm
  static dateFormateWithAmPm = (value: string) => {
    let dStr = value;
    let formatted = "";
    if (typeof dStr !== "undefined" && dStr !== null) {
      const isAmPm = dStr.replace(/[^apmAPM]/g, "");
      dStr = dStr.replace(/[^0-9]/g, "");
      let block1 = "";
      let block2 = "";
      let block3 = "";
      let block4 = "";
      let block5 = "";

      //for set number formate 2-2-4
      block1 = dStr.substring(0, 2);

      if (block1?.length === 2 && dStr?.length > 2) {
        block1 = block1 + "/";
        if (parseInt(block1.split("/")[0]) > 31) block1 = "01/"; // added by Israil Gulzar
      }
      block2 = dStr.substring(2, 4);
      if (block2?.length === 2 && dStr?.length > 4) {
        if (parseInt(block2) > 12) block2 = "01"; // added by Israil Gulzar
        block2 = block2 + "/";
      }
      block3 = dStr.substring(4, 8);
      if (block3?.length === 4) {
        block3 = block3 + "";
      }
      block4 = dStr.substring(8, 12);
      if (block4?.length >= 1) {
        const timeStr = this.timeFormate(block4, true);
        block4 = " " + timeStr + "";
      }
      block5 = isAmPm.substring(0, 2);
      if (block5?.length >= 1) {
        block5 = block5.toLowerCase();
        if (block5?.length === 1) {
          if (block5 === "a" || block5 === "p") {
            block5 = " " + block5 + "";
          } else {
            block5 = " ";
          }
        } else {
          if (block5 === "am" || block5 === "pm") {
            block5 = " " + block5 + "";
          } else {
            block5 = " " + isAmPm.substring(0, 1) + "";
          }
        }
      }

      formatted = block1 + block2 + block3 + block4 + block5;
    }
    return formatted;
  };

  //for mobile number setup
  static mobileNumberFormate = (value: string) => {
    let mNumString = value;
    let formatted = "";
    if (typeof mNumString !== "undefined" && mNumString !== null) {
      mNumString = mNumString.replace(/[^0-9]/g, "");
      // if (mNumString?.length === 10 && mNumString === "0000000000") {
      //   // added by Israil Gulzar
      //   formatted = "";
      // } else {
      let block1 = "";
      let block2 = "";
      let block3 = "";

      //for set number formate 3-3-4
      block1 = mNumString.substring(0, 3);
      if (block1?.length === 3 && mNumString?.length > 3) {
        block1 = "(" + block1 + ")-";
      }
      block2 = mNumString.substring(3, 6);
      if (block2?.length === 3 && mNumString?.length > 6) {
        block2 = block2 + "-";
      }
      block3 = mNumString.substring(6, 12);
      if (block3?.length === 4) {
        block3 = block3 + "";
      }

      formatted = block1 + block2 + block3;
      // }
    }
    return formatted;
  };

  // mask mobile number setup
  static maskMobileNumberFormate = (value: string) => {
    let mNumString = value;
    let formatted = "";
    if (typeof mNumString !== "undefined" && mNumString !== null) {
      mNumString = mNumString.replace(/[^0-9]/g, "");
      let block1 = "";
      let block2 = "";
      let block3 = "";
      let block4 = "";

      //for set number formate 3-3-4
      block1 = "+xx-xxx-";
      block2 = "xxx-";
      block3 = "xx";
      block4 = mNumString.substring(8, 10);
      if (block4?.length === 2) {
        block4 = block4 + "";
      }

      formatted = block1 + block2 + block3 + block4;
    }
    return formatted;
  };

  // get country code
  static mobileCountryCode = (value: string) => {
    let finalCode = countryCodes.filter(
      (newDic: any) => newDic?.value === value
    );
    if (finalCode?.length > 0) {
      return finalCode[0];
    }
    return null;
  };

  // check credit card validation
  static ValidateCreditCardNumber = (ccNum: string) => {
    const visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
    const mastercardRegEx = /^(?:5[1-5][0-9]{14})$/;
    const amexpRegEx = /^(?:3[47][0-9]{13})$/;
    const discoverRegEx = /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/;
    const dinersRegEx = /^(?:3(?:0[0-5]|[68][0-9])[0-9]{11})$/;
    const jcbRegEx = /^(?:(?:2131|1800|35[0-9]{3})[0-9]{11})$/;

    //     const regs = [
    //         ELECTRON => "/^(4026|417500|4405|4508|4844|4913|4917)\d+$/",
    //         MAESTRO  => "/^(?:50|5[6-9]|6[0-9])\d+$/",
    //         DANKORT  => "/^(5019|4571)\d+$/",
    //         CUP      => "/^(62|81)\d+$/",
    //         VISA     => "/^4[0-9]\d+$/",
    //         DINERS   => "/^(?:5[45]|36|30[0-5]|3095|3[8-9])\d+$/",
    //         MC       => "/^(?:5[1-5]|222[1-9]|22[3-9][0-9]|2[3-6][0-9][0-9]|27[0-1][0-9]|2720)\d+$/",
    //         AMEX     => "/^(34|37)\d+$/",
    //         DISCOVER => "/^6(?:011|22(12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])|5|4|2[4-6][0-9]{3}|28[2-8][0-9]{2})\d+$/",
    //         JCB      => "/^(?:35[2-8][0-9])\d+$/",
    //         INTERPAY => "/^(636)\d+$/",
    //         KOREAN   => "/^9[0-9]\d+$/",
    //         MIR      => "/^(?:220[0-4])\d+$/",
    //     ];

    let cc =
      /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|(222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11}|62[0-9]{14})$/;
    let isCreditCard = false;
    let ccBrand = "";
    let isValid = false;

    if (cc.test(ccNum)) {
      isCreditCard = true;
    }

    if (isCreditCard) {
      if (visaRegEx.test(ccNum)) {
        ccBrand = "visa";
        isValid = true;
      } else if (mastercardRegEx.test(ccNum)) {
        ccBrand = "master";
        isValid = true;
      } else if (amexpRegEx.test(ccNum)) {
        ccBrand = "amexp";
        isValid = true;
      } else if (discoverRegEx.test(ccNum)) {
        ccBrand = "discover";
        isValid = true;
      } else if (dinersRegEx.test(ccNum)) {
        ccBrand = "diners";
        isValid = true;
      } else if (jcbRegEx.test(ccNum)) {
        ccBrand = "jcb";
        isValid = true;
      }
    }
    return { isCreditCard, ccBrand, isValid };
  };

  static cc_format = (value: string, formate: boolean) => {
    // supports Amex, Master Card, Visa, and Discover
    let ccNumString = value;
    if (typeof ccNumString !== "undefined" && ccNumString !== null) {
      ccNumString = ccNumString.replace(/[^0-9]/g, "");
    }
    // mc, starts with - 51 to 55, 22
    // v, starts with - 4
    // dsc, starts with 6011, 622126-622925, 644-649, 65
    // amex, starts with 34 or 37
    let typeCheck = ccNumString.substring(0, 2);
    let cType = "";
    let block1 = "";
    let block2 = "";
    let block3 = "";
    let block4 = "";
    let formatted = "";

    if (typeCheck?.length === 2) {
      const typeCheckN: number = parseInt(typeCheck);
      if (typeCheckN >= 40 && typeCheckN <= 49) {
        cType = "Visa";
      } else if ((typeCheckN >= 51 && typeCheckN <= 55) || typeCheckN === 22) {
        cType = "Master Card";
      } else if (
        (typeCheckN >= 60 && typeCheckN <= 62) ||
        typeCheckN === 64 ||
        typeCheckN === 65
      ) {
        cType = "Discover";
      } else if (typeCheckN === 34 || typeCheckN === 37) {
        cType = "American Express";
      } else if (typeCheckN === 30) {
        cType = "Diners Club";
      } else if (typeCheckN === 36) {
        cType = "Diners Club 1";
      } else if (typeCheckN === 35) {
        cType = "JCB";
      } else {
        cType = "Invalid";
      }
    }

    if (formate === false) {
      return { cardBrand: cType, numberFormate: "" };
    }

    // all support card types have a 4 digit firt block
    block1 = ccNumString.substring(0, 4);
    if (block1?.length === 4 && ccNumString?.length > 4) {
      block1 = block1 + " ";
    }

    if (
      cType === "Visa" ||
      cType === "Master Card" ||
      cType === "Discover" ||
      cType === "Diners Club" ||
      cType === "JCB" ||
      cType === "Invalid"
    ) {
      // for 4X4 cards
      block2 = ccNumString.substring(4, 8);
      if (block2?.length === 4 && ccNumString?.length > 8) {
        block2 = block2 + " ";
      }
      block3 = ccNumString.substring(8, 12);
      if (block3?.length === 4 && ccNumString?.length > 12) {
        block3 = block3 + " ";
      }
      block4 = ccNumString.substring(12, 16);
    } else if (cType === "American Express" || cType === "Diners Club 1") {
      // for Amex cards
      block2 = ccNumString.substring(4, 10);
      if (block2?.length === 6 && ccNumString?.length > 6) {
        block2 = block2 + " ";
      }
      block3 = ccNumString.substring(10, 15);
      block4 = "";
    } else if (cType === "Invalid1") {
      // for invalid cards
      block1 = typeCheck;
      block2 = "";
      block3 = "";
      block4 = "";
      // alert('Invalid Card Number');
    }

    formatted = block1 + block2 + block3 + block4;
    return { cardBrand: cType, numberFormate: formatted };
  };

  static currentCardImg = (cardBrand: string) => {
    let ccImg = staticIcons.CCOtherIcon;
    if (cardBrand === "Visa" || cardBrand === "visa") {
      ccImg = staticIcons.CCVisaIcon;
    } else if (cardBrand === "Master Card" || cardBrand === "MasterCard") {
      ccImg = staticIcons.CCMasterIcon;
    } else if (cardBrand === "American Express") {
      ccImg = staticIcons.CCAmericaExpIcon;
    } else if (cardBrand === "Discover") {
      ccImg = staticIcons.CCDiscoverIcon;
    } else if (cardBrand === "JCB") {
      ccImg = staticIcons.CCJcbIcon;
    } else if (cardBrand === "Diners Club" || cardBrand === "Diners Club 1") {
      ccImg = staticIcons.CCOtherIcon;
    }
    return ccImg;
  };

  // set table filter array
  static setTblListFilterArrayString = (filterArr: any) => {
    let finalArray: string | any[] = [];
    // extra_param
    for (let key in filterArr) {
      if (key !== "extra_param") {
        finalArray.push({ [key]: filterArr[key] });
      }
    }
    return finalArray?.length === 0 ? "" : JSON.stringify(finalArray);
  };

  // sorting value update formate
  static updateSortChangeData = (sortModel: any) => {
    let sortingStr = "";
    let sorting = [];
    if (sortModel?.length > 0) {
      for (let i = 0; i < sortModel.length; i++) {
        let newDic = sortModel[i];
        let columnName = newDic.colId;
        sorting.push({ id: columnName, desc: newDic.sort !== "asc" });
      }
      sortingStr = sorting?.length === 0 ? "" : JSON.stringify(sorting);
    }
    return sortingStr;
  };

  // set table filter and sorting array
  static getTblListFilterAndSortingArrayString = (tblRef: any) => {
    const selFilter = tblRef?.current?.api?.getFilterModel();
    const selFinalFilter = this.setTblListFilterArrayString(selFilter);
    const colState = tblRef?.current?.columnApi?.getColumnState();
    const selSorting = colState
      .filter(function (s: any) {
        return s.sort != null;
      })
      .map(function (s: any) {
        return { colId: s.colId, sort: s.sort, sortIndex: s.sortIndex };
      });
    const selFinalSorting = this.updateSortChangeData(selSorting);
    return { filter: selFinalFilter, sorting: selFinalSorting };
  };

  // update table for new data
  static updateTableWithApiCall = (tableRef: any, extraParamFilter: any) => {
    if (tableRef?.current && tableRef?.current?.api) {
      tableRef.current.api.deselectAll();
      let selFilter = tableRef.current.api.getFilterModel();
      let extraF = extraParamFilter.extra_param;
      if (
        extraParamFilter.extra_param &&
        typeof extraParamFilter?.extra_param?.filter !== "undefined" &&
        typeof extraParamFilter?.extra_param?.filter === "string"
      ) {
        let extraFV = JSON.parse(extraParamFilter.extra_param.filter);
        extraFV.update = this.generateRandomString();
        extraF["filter"] = JSON.stringify(extraFV);
      }
      if (typeof selFilter !== "undefined") {
        selFilter["extra_param"] = extraF;
      } else {
        selFilter = {};
        selFilter["extra_param"] = extraF;
      }
      tableRef.current.api.setFilterModel(selFilter);
    }
  };

  //for update column ag-grid
  static checkUpdatedGridColumnArray(defColoumnArr: any, tableRef: any) {
    let finalVisibleSetCol = [];
    let finalHideSetCol = [];
    let finalSetColOrder = [];
    if (defColoumnArr && defColoumnArr?.length > 0) {
      if (tableRef?.current && tableRef?.current?.columnApi) {
        let allCols = tableRef.current.columnApi.getAllColumns();
        for (let i = 0; i < defColoumnArr.length; i++) {
          let columnId = defColoumnArr[i];
          const isPresentCol = allCols.find(
            (x: any) => x.colDef.field == columnId
          );
          if (isPresentCol) {
            finalSetColOrder.push(columnId);
            finalVisibleSetCol.push(isPresentCol);
          }
        }

        for (let i = 0; i < allCols.length; i++) {
          let columnObj = allCols[i];
          if (columnObj?.colDef?.field) {
            let isPresent = defColoumnArr.indexOf(columnObj?.colDef?.field);
            if (isPresent === -1) {
              finalHideSetCol.push(columnObj?.colDef?.field);
            }
          }
        }
      }
    }
    return {
      finalVisibleSetCol: finalVisibleSetCol,
      finalHideSetCol: finalHideSetCol,
      finalSetColOrder: finalSetColOrder,
    };
  }

  //To get message length based on custom field
  static getMessageLength = (message: any, customFields: any) => {
    let newMessage = message;
    for (let i = 0; i < customFields.length; i++) {
      newMessage = newMessage.replace(
        customFields[i].regex,
        ConstantsHelper.dummyText.slice(0, customFields[i].maxChars)
      );
    }
    return newMessage?.length;
  };

  //For search fuctionality and notification redirects route
  static getModuleRedirectionRoute = (data: any, from: string) => {
    //Modules open in dialog
    const openInDialogModules = [
      "BlastSms",
      "AutoResponder",
      "CustomForm",
      "Widget",
      "reputation",
      "location",
      "online_profile",
      "Plan",
      "Invoice",
      "Reservation",
    ];

    //Modules required parent module id in route
    const reqParentIdModules = ["Product", "Table"];

    //Module routes
    const modulesRoutes: any = {
      Marketing: { module_route: "/marketing" },
      MeetingRecords: { module_route: "/MeetingRecords" },
      Reseller: { module_route: "/res-dashboard" },
      BlastSms: { module_route: "/marketing" },
      // BlastSms: { module_route: "/blast-campaign" },
      AutoResponder: {
        module_route: "/marketing",
      },
      // AutoResponder: {
      //   module_route: "/auto-responder",
      // },
      CustomForm: {
        module_route: "/marketing",
      },
      // CustomForm: {
      //   module_route: "/custom-forms",
      // },
      Widget: {
        module_route: "/marketing",
      },
      // Widget: {
      //   module_route: "/widgets",
      // },
      Client: {
        module_route: "/clients",
        id_route: "/clients/edit-client",
      },
      ClientGroup: { module_route: "/groups", id_route: "/groups/edit-group" },
      reputation: { module_route: "/reputation" },
      Hotspot: {
        module_route: "/hotspots",
        id_route: "/hotspots/edit-hotspot",
      },
      Floor: { module_route: "/floors", id_route: "/floors/edit-floor" },
      Table: { module_route: "/floors", id_route: "/floors/edit-table" },
      Menu: {
        module_route: "/menu",
        id_route: "/menu/category",
      },
      Category: { module_route: "/menu", id_route: "/menu/category" },
      Product: { module_route: "/menu", id_route: "/menu/edit-product" },
      location: { module_route: "/location-settings" },
      online_profile: { module_route: "/marketplace" },
      sub_user: { module_route: "/users", id_route: "/users/edit-sub-user" },
      splash: {
        module_route: "/splashpages",
        id_route: "/splashpages/edit-splashpage",
      },
      Reservation: {
        module_route: "/reservation",
      },
      Plan: {
        module_route: "/plans",
      },
      Invoice: {
        module_route: "/payments",
      },
    };

    const module = from === "Notification" ? data.module_type : data.module;
    if (
      AppUtils.checkFieldValue(
        data,
        from === "Notification" ? "module_type" : "module"
      ) &&
      AppUtils.checkFieldValue(modulesRoutes, module)
    ) {
      if (
        AppUtils.checkFieldValue(data, "parent_id") &&
        AppUtils.checkFieldValue(data, "module_id")
      ) {
        if (
          reqParentIdModules.includes(module) &&
          AppUtils.checkFieldValue(modulesRoutes[module], "id_route")
        ) {
          return {
            route:
              modulesRoutes[module].id_route +
              `/${data.parent_id}/${data.module_id}`,
            state: null,
          };
        }
      } else if (AppUtils.checkFieldValue(data, "module_id")) {
        if (
          openInDialogModules.includes(module) &&
          AppUtils.checkFieldValue(modulesRoutes[module], "module_route")
        ) {
          const state: any = { openFromSearch: true, id: data.module_id };
          if (module === "Reservation") state["status"] = data?.status;
          return {
            route: modulesRoutes[module].module_route,
            state,
          };
        } else if (
          AppUtils.checkFieldValue(modulesRoutes[module], "id_route")
        ) {
          return {
            route: modulesRoutes[module].id_route + `/${data.module_id}`,
            state: null,
          };
        }
      } else {
        if (AppUtils.checkFieldValue(modulesRoutes[module], "module_route")) {
          let route = modulesRoutes[module].module_route;
          return {
            route: route,
            state: null,
          };
        }
      }
    }
    return null;
  };

  // For birthdate validation
  static isValidBirthdate = (val: any, isReq: boolean) => {
    if (isReq === false && this.checkDirectValue(val) === false) {
      return true;
    }
    const value = moment(val, "MM/DD/YYYY").format("MM/DD/YYYY");
    const nDate = moment(value);
    const validDate = nDate?.isValid();
    const diff = moment().diff(value);

    const dateArr = this.checkDirectValue(val) ? val.split("/") : [];

    if (
      this.checkDirectValue(value) === true &&
      validDate &&
      diff >= 0 &&
      this.checkDirectValue(val) === true &&
      val?.length === 10 &&
      this.checkFieldValue(dateArr, "length") &&
      dateArr?.length === 3 &&
      Number.isNaN(dateArr[2]) === false &&
      Number(dateArr[2]) !== 0
    ) {
      return true;
    }

    return false;
  };

  static isDummyUser = () => {
    const dummyUserMno = "2222211111";
    const dummyUserMno1 = "2222221111";
    const userD = this.getSessionItemData("MYCIRCLE");

    if (
      this.checkDirectValue(userD) &&
      this.checkFieldValue(userD, "mobile_no") &&
      (userD.mobile_no === dummyUserMno || userD.mobile_no === dummyUserMno1)
    )
      return true;

    return false;
  };

  static isRemoteAppUser = () => {
    const dummyUserMno = "2222221111";
    const dummyUserMno1 = "2222221110";
    const userD = this.getSessionItemData("MYCIRCLE");

    if (
      this.checkDirectValue(userD) &&
      this.checkFieldValue(userD, "mobile_no") &&
      (userD.mobile_no === dummyUserMno || userD.mobile_no === dummyUserMno1)
    )
      return true;

    return false;
  };

  static resizeFile = (args: any) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        args?.file,
        args?.maxWidth && args?.maxWidth !== undefined ? args?.maxWidth : 150,
        args?.maxHeight && args?.maxHeight !== undefined
          ? args?.maxHeight
          : 150,
        args?.compressFormat && args?.compressFormat !== undefined
          ? args?.compressFormat
          : "JPEG",
        args?.quality && args?.quality !== undefined ? args?.quality : 75,
        args?.rotation && args?.rotation !== undefined ? args?.rotation : 0,
        args?.responseUriFunc && args?.responseUriFunc !== undefined
          ? args?.responseUriFunc
          : (uri) => {
            resolve(uri);
          },
        args?.outputType && args?.outputType !== undefined
          ? args?.outputType
          : "file",
        args?.minWidth && args?.minWidth !== undefined && args?.minWidth,
        args?.minHeight && args?.minHeight !== undefined && args?.minHeight
      );
    });

  static isFromScheduledown = () => {
    return process.env.REACT_APP_PROJECT_TYPE === "SCHDULEDOWN";
  };
}

export default AppUtils;
