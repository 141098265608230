const pl = {
    "rated_google_reviews": "Oceniono na 5,0 w Google Reviews",
    "get_your_digital_menu": "Pobierz swoje cyfrowe menu —",
    "instant_popup_menu": "Natychmiastowe menu podręczne bez skanowania kodu QR",
    "free_forever": "Wolny na zawsze",
    "the_future_is_here": "Przyszłość jest już tutaj!",
    "upgrade_your_restaurant": "Ulepsz swoją restaurację dzięki oszałamiającemu cyfrowemu menu za darmo. Przyciągnij więcej klientów już dziś.",
    "get_my_free_menu_now": "Pobierz moje bezpłatne menu już teraz",
    "no_credit_card_required": "*Karta kredytowa nie jest wymagana",
    "join_largest_marketplace": "Dołącz do największego rynku restauracji!",
    "what_will_you_get": "Co otrzymasz?",
    "free": "BEZPŁATNY",
    "free_digital_menu": "Bezpłatne Menu Cyfrowe 2D lub 3D",
    "free_digital_menu_desc": "Pożegnaj się z papierowymi menu i drogimi przedrukami",
    "quick_and_easy_signup": "Szybka i łatwa rejestracja",
    "communicate_with_customers_title": "Komunikuj się z klientami ZA DARMO!",
    "features1": [
        "Zaoszczędź na kosztach drukowania i ciesz się natychmiastowymi aktualizacjami menu, kiedy tylko ich potrzebujesz.",
        "Uzyskaj dostęp do swojego cyfrowego menu na dowolnym urządzeniu",
        "Przyjazny dla środowiska i bezproblemowy"
    ],
    "features2": [
        "Pozwól klientom wirtualnie poznać Twoje dania dzięki oszałamiającym wizualizacjom 3D.",
        "Zwiększ zaangażowanie, dając gościom realistyczny podgląd ich posiłku.",
        "Wyróżnij się dzięki menu, które będzie tak samo kuszące, jak smak potraw."
    ],
    "features3": [
        "Powitaj gości menu już w chwili wejścia do restauracji.",
        "Automatycznie wysyłaj aktualizacje dotyczące ofert specjalnych i promocji na ich telefony.",
        "Ulepsz swoje doznania kulinarne dzięki szybkiemu, bezkontaktowemu dostępowi do menu."
    ],
    "feature4": [
        "Podkreśl oferty sezonowe, codzienne promocje i ekskluzywne promocje.",
        "Przykuj uwagę klientów do swoich najpopularniejszych produktów.",
        "Wzbudzaj poczucie pilności i ekscytacji za pomocą przyciągających wzrok materiałów wizualnych i opisów."
    ],
    "feature5": [
        "Bądź częścią internetowej społeczności, którą codziennie odwiedzają tysiące osób.",
        "Zwiększ widoczność i dotrzyj do nowych klientów, którzy aktywnie poszukują opcji gastronomicznych.",
        "Zapewnij sobie jak najwcześniej najlepsze miejsce, aby zwiększyć swoją widoczność i przyciągnąć więcej gości."
    ],
    "section3_heading": "Dlaczego warto przejść na wersję cyfrową? Ponieważ jest to bezpłatne i łatwe!",
    "section3_description": "Odblokuj zaawansowane funkcje zaprojektowane w celu zwiększenia atrakcyjności Twojej restauracji.",
    "instant_menu": "Menu natychmiastowe",
    "instant_menu_notification": "Okno pop-up i powiadomienie",
    "instant_menu_description": "Witaj gości z natychmiastowym dostępem do menu, gdy tylko wejdą. Automatycznie wysyłaj najnowsze menu i oferty specjalne bezpośrednio na ich telefony komórkowe",
    "digital_menu": "Cyfrowe menu",
    "at_zero_cost": "za zerowy koszt",
    "digital_menu_description1": "Pożegnaj się z papierowymi menu i drogimi przedrukami. Przejdź na cyfrowe menu, które jest zawsze aktualne — i jest darmowe na zawsze!",
    "digital_menu_description2": "Dzięki naszemu cyfrowemu menu Twoja oferta jest zawsze aktualna i dostępna na każdym urządzeniu. Oszczędzaj pieniądze i upraszczaj aktualizacje menu dzięki bezproblemowemu, bezkosztowemu rozwiązaniu.",
    "try_me_button": "Wypróbuj mnie",
    "try_3d_menu_title": "Menu 3D",
    "try_3d_menu_title2": "Za darmo — WOW!",
    "try_3d_menu_description1": "Spraw, aby Twoje dania wyróżniały się na ekranie. Prześlij obrazy 3D i pozwól klientom wirtualnie spróbować Twojego jedzenia, zanim wejdą.",
    "try_3d_menu_description2": "Wysokiej jakości obrazy 3D tworzą zachwycający podgląd, zwiększając atrakcyjność oferty i zachęcając do składania zamówień.",
    "try_now": "Spróbuj teraz",
    "showcase_title": "Zaprezentuj swoje",
    "showcase_specials": "Specjalne",
    "showcase_specials_description1": "Masz kuszącą ofertę lub nie do pobicia promocję? Wyróżnij ją w swoim cyfrowym menu i przyciągnij więcej głodnych ludzi.",
    "showcase_specials_description2": "Spraw, by promocje były niemożliwe do przegapienia, przyciągając uwagę wyrazistymi grafikami i kuszącymi opisami.",
    "marketplace": "Rynek",
    "marketplace_description": "Przygotowujemy rynek z ponad 2000 odwiedzających dziennie. Wskakuj na pokład już teraz, a będziesz pierwszy w kolejce, gdy wystartujemy. Możesz dotrzeć do nowych klientów aktywnie poszukujących opcji gastronomicznych. Zarezerwuj miejsce wcześniej, aby zmaksymalizować ekspozycję!",
    "see_other_products": "Zobacz inne produkty",
    "about_us": "O nas",
    "why_serving_free": "Dlaczego to serwujemy?",
    "serving_free_highlight": "Bezpłatny",
    "about_desc": "Jesteśmy mieszkańcami Dallas, którym zależy na tym, aby restauracje takie jak Państwa mogły się rozwijać.",
    "about_tool_free": "Udostępniamy to potężne narzędzie całkowicie za darmo, ponieważ wierzymy w konieczność wspierania naszej społeczności.",
    "no_catch": "Bez haczyka, bez żartów. Wiemy, że gdy odniesiesz sukces, nasza społeczność staje się silniejsza. 😊",
    "understanding_challenges": "Rozumiemy wyzwania związane z prowadzeniem biznesu, dlatego dokładamy wszelkich starań, aby dostarczać Ci narzędzia, które usprawnią Twoje działania i zwiększą Twój zasięg.",
    "community_growth": "W miarę jak Ty rośniesz, nasza społeczność rośnie wraz z Tobą, tworząc efekt domina sukcesu i wsparcia. Razem podnieśmy poziom doświadczenia kulinarnego dla każdego.",
    "how_it_works": "Jak to działa",
    "three_simple_steps": "Trzy proste kroki do",
    "get_started_highlight": "Rozpocznij",
    "no_tech_skills_needed": "Nie potrzeba żadnych umiejętności technicznych. Jeśli potrafisz obrócić burgera, potrafisz to zrobić!",
    "step1_title": "Wyświetlaj swoją promocję ZA DARMO!",
    "step1_desc": "Zacznij szybciej, niż zdążysz powiedzieć „Zamów!” Wystarczy wypełnić prosty formularz.",
    "step2_title": "Dostosuj swoje menu",
    "step2_desc": "Dodaj swoje dania, prześlij zdjęcia (nawet w 3D) i wyróżnij swoje dania specjalne.",
    "step3_title": "Dziel się i rozwijaj",
    "step3_desc": "Udostępnij swoje cyfrowe menu za pomocą kodów QR, mediów społecznościowych lub swojej witryny internetowej.",
    "testimonials": "Referencje",
    "testimonials_section_title": "Nie wierz nam na słowo",
    "testimonials_section_desc": "Zobacz, co mówią właściciele innych restauracji w Dallas.",
    "faq_section_title": "Masz pytania? Mamy odpowiedzi",
    "faq_section_desc": "Wszystko, co musisz wiedzieć zanim zaczniesz.",
    "faqs": "Często zadawane pytania",
    "faq_answers": [
        {
            "que": "Czy to naprawdę jest darmowe na zawsze?",
            "ans": "Oczywiście! Żadnych ukrytych opłat, żadnych kart kredytowych, żadnych zobowiązań."
        },
        {
            "que": "Czy potrzebuję jakiejś wiedzy technicznej?",
            "ans": "Jeśli możesz wysłać e-mail, możesz to zrobić. To takie proste."
        },
        {
            "que": "Dlaczego oferujecie to za darmo?",
            "ans": "Wierzymy w dawanie w pierwszej kolejności. W przyszłości możemy mieć inne usługi, które pokochasz — ale to zależy wyłącznie od Ciebie."
        },
        {
            "que": "O co chodzi z tym rynkiem?",
            "ans": "Gotujemy! Gdy tylko będziemy mieć wystarczająco dużo restauracji na pokładzie, uruchomimy Twój rynek, aby zapewnić Ci jeszcze większą widoczność."
        }
    ],
    "testimonials_content1": "Przejście na cyfrowe menu było najlepszym ruchem, jaki zrobiliśmy! Nasi klienci uwielbiają wygodę i zaktualizowany wygląd!",
    "testimonials_name1": "Morris George",
    "testimonials_restaurant_name1": "Restauracja Mansion",
    "testimonials_content2": "Nasze nowe cyfrowe menu ułatwia gościom przeglądanie menu i naprawdę zwiększa zaangażowanie!",
    "testimonials_name2": "Amelia Johnson",
    "testimonials_restaurant_name2": "Miejskie jedzenie",
    "testimonials_content3": "Goście są zachwyceni wizualizacjami 3D — dzięki nim wyróżniliśmy się i wzbogaciliśmy ich doznania kulinarne!",
    "testimonials_name3": "Davis Zielony",
    "testimonials_restaurant_name3": "Ognisko Miejskie",
    "ready_to_boost_your_restaurant": "Gotowy na rozbudowę swojej restauracji?",
    "ready_to_boost_desc": "Nie zostań w tyle. Dołącz do rewolucji cyfrowej już dziś!",
    "yes_i_want_my_free_digital_menu": "Tak, chcę moje bezpłatne cyfrowe menu",
    "480_plus": "480+",
    "people_already_joined": "ludzie już dołączyli",
    "menu_online_home": "Menu Online Strona główna",
    "welcome": "Powitanie",
    "description_other": "Zwiększmy zadowolenie Twoich klientów!",
    "benefits": "Korzyści",
    "greeting_text": "Witamy 👋",
    "all_in_one_features": "🚀 Kompleksowe funkcje podnoszące poziom Twojej restauracji",
    "menu_online_menu_name": "Menu online",
    "menu_online_description": "Zdigitalizuj swoje menu, aby klienci mieli do niego łatwy dostęp online.",
    "menu_online_benefits": "Zwiększ wygodę klientów i zaangażowanie, umożliwiając im przeglądanie menu w dowolnym czasie i miejscu.",
    "ds_name": "Oznakowanie cyfrowe",
    "ds_description": "Prezentuj oferty i promocje na dynamicznych ekranach w sklepie.",
    "ds_benefits": "Przykuwaj uwagę klientów angażującymi materiałami wizualnymi, promuj sprzedaż dodatkową i oferty w czasie rzeczywistym bez wysiłku.",
    "loyalty_punch_name": "Karta perforowana lojalnościowa",
    "loyalty_punch_description": "Nagradzaj stałych klientów za pomocą cyfrowego programu lojalnościowego.",
    "loyalty_punch_benefits": "Zwiększaj retencję klientów i sprzedaż, motywując lojalność nagrodami, które możesz wymienić na nagrody za częste wizyty.",
    "marketing_menu_name": "Marketing",
    "marketing_description": "Dotrzyj do większej liczby klientów dzięki ukierunkowanym kampaniom marketingu cyfrowego.",
    "marketing_benefits": "Zwiększ zasięg swojej restauracji i zwiększ ruch pieszy dzięki promocjom dostosowanym do potrzeb klientów, stworzonym z myślą o przyciągnięciu nowych i stałych klientów.",
    "get_more_reviews_menu_name": "Uzyskaj więcej recenzji",
    "get_more_reviews_description": "Zachęcaj zadowolonych klientów do pozostawiania pozytywnych opinii online.",
    "get_more_reviews_benefits": "Zwiększ wiarygodność swojej restauracji i przyciągnij nowych klientów, wzmacniając swoją reputację online za pomocą większej liczby pozytywnych opinii.",
    "guest_smart_wifi_menu_name": "Gościnny Smart Wifi",
    "guest_smart_wifi_description": "Zaoferuj bezpłatne Wi-Fi, aby zbierać dane gości na potrzeby marketingu.",
    "guest_smart_wifi_benefits": "Zwiększ poziom zadowolenia klientów, jednocześnie zbierając cenne dane o klientach, które umożliwią Ci personalizację przyszłych promocji.",
    "reservation_menu_name": "Rezerwacja",
    "reservation_description": "Pozwól klientom łatwo rezerwować stoliki online.",
    "reservation_benefits": "Ulepsz doświadczenia kulinarne, skracając czas oczekiwania i usprawnij proces rezerwacji, aby lepiej zarządzać miejscami siedzącymi.",
    "pos_integration_menu_name": "Integracja POS",
    "pos_integration_description": "Zintegruj nasz system ze swoim systemem POS.",
    "pos_integration_benefits": "Uprość przetwarzanie zamówień i zmniejsz liczbę błędów, synchronizując systemy internetowe i sklepowe.",
    "online_ordering_menu_name": "Zamówienia online",
    "online_ordering_description": "Umożliw klientom składanie zamówień bezpośrednio ze swojej strony internetowej lub aplikacji.",
    "online_ordering_benefits": "Zwiększ sprzedaż i zmniejsz zależność od platform zewnętrznych, oferując opcję bezpośredniego składania zamówień, która pozwala zaoszczędzić na opłatach.",
    "pro_label": "Zawodowiec",
    "coming_soon": "Już wkrótce",
    "locked_label": "Zamknięty",
    "forever_label": "Na zawsze",
    "new_label": "Nowy",
    "other_products_page_title": "Inne produkty | Menu online",
    "other_products_description": "Menu Polityka prywatności online",
    "other_products_header_title": "Inne produkty",
    "privacy_policy": {
        "title": "Polityka prywatności",
        "lastUpdated": "Ostatnia aktualizacja: 1 lipca 2024 r.",
        "introduction": {
            "header": "Polityka prywatności dla nowych i obecnych klientów",
            "paragraph1": "NASTĘPUJĄCE KLUCZOWE PUNKTY (W TYM ODNIESIENIA DO SEKCJI) SĄ PODANE WYŁĄCZNIE DLA TWOJEJ WYGODY I NIE ZASTĘPUJĄ PEŁNYCH WARUNKÓW I POSTANOWIEŃ. TWOJĄ ODPOWIEDZIALNOŚCIĄ JEST PRZECZYTANIE TYCH WARUNKÓW I POSTANOWIEŃ PRZED UŻYCIEM USŁUGI MENUONLINE INC.",
            "paragraph2": "Korzystanie z Menuonline oznacza, że zgadzasz się z Warunkami i Postanowieniami oraz oświadczasz i gwarantujesz, że jesteś upoważniony przez swoją organizację lub firmę do tego. (Zobacz sekcję 3 „Jak to działa”)",
            "paragraph3": "Menuonline Inc to usługa marketingowa oparta na zezwoleniu. Celowe wysyłanie „spamu” lub naruszanie Warunków i Postanowień spowoduje zamknięcie konta. (Zobacz s.16 „Dopuszczalne korzystanie z usługi”)",
            "paragraph4": "Dane, które przechowujesz w Menuonline Inc., takie jak zapisy kontaktów, treść wiadomości e-mail i informacje zbierane za pośrednictwem Menuonline Inc., należą do Ciebie. (Zobacz sekcję 14 „Co jest Twoją własnością?”).",
            "paragraph5": "Oprogramowanie Menuonline Inc i zawartość naszej witryny należą do nas. (Zobacz s.13 „Co posiadamy?”) Oprócz Twoich własnych użytkowników, dane lub niepublikowane treści, które hostujesz w Menuonline Inc, mogą być przeglądane tylko przez pracowników i partnerów Menuonline Inc, którzy podpisali umowy o zachowaniu poufności. (Zobacz s.14 „Co posiadasz?”)",
            "paragraph6": "Wszystkie dane osobowe (PII) przechowywane są wyłącznie na serwerach zlokalizowanych w Kanadzie. (Zobacz sekcję 14 „Co posiadasz?”).",
            "paragraph7": "Usługa Menuonline Inc podlega prawu Kanady i prowincji Ontario. (Zobacz s.24 „Prawo właściwe”)",
            "paragraph8": "Wszelkie żądania dotyczące Twoich danych lub treści składane przez osoby fizyczne, policję lub inne agencje regulacyjne z Kanady lub spoza Kanady będą zawsze kierowane do Ciebie. Jedynym wyjątkiem byłoby, gdyby to naraziło nas na naruszenie prawa kanadyjskiego lub prawa Ontario; jednak we wszystkich przypadkach będziemy stanowczo sprzeciwiać się jakiemukolwiek ujawnieniu Twoich danych przez kogokolwiek innego niż Ty. (Zobacz s.14 „Co posiadasz?”).",
            "paragraph9": "Bezpieczeństwo Menuonline Inc jest tak dobre, jak zarządzanie nazwami użytkowników i hasłami. Nie pobieramy opłat za konta użytkowników Menuonline Inc, więc utwórz osobne konto dla każdego użytkownika i zachowaj hasła w tajemnicy. Jesteś odpowiedzialny za swoje konto. (Zobacz s.3 „Jak to działa”)",
            "paragraph10": "Możesz anulować swoje konto w dowolnym momencie. Nie zapewniamy zwrotów za niewykorzystany kredyt, jeśli anulujesz. (Zobacz s.3 „Jak to działa”)"
        },
        "termsAndConditions": {
            "title": "WARUNKI I ZASADY MENUONLINE INC",
            "lastUpdate": "OSTATNIA AKTUALIZACJA: 14 stycznia 2020 r.",
            "paragraph1": "Przeczytaj uważnie niniejsze warunki korzystania z usługi (dalej „Warunki”) przed skorzystaniem z tej witryny internetowej (dalej „Witryna”). Niniejsze Warunki zwalniają Menuonline Inc. (dalej „Menuonline Inc”, „my” lub „nas”) i inne podmioty z odpowiedzialności i\/lub ograniczają naszą i ich odpowiedzialność oraz zawierają inne ważne postanowienia, które mają zastosowanie do korzystania z tej Witryny. Odwiedzając lub korzystając z tej Witryny lub Usługi, zgadzasz się w swoim imieniu i w imieniu każdej organizacji, w której imieniu możesz działać (zwanych dalej łącznie „Tobą”), zaakceptować i przestrzegać niniejszych Warunków dla każdego korzystania z tej Witryny i każdej wizyty na niej."
        },
        "services": {
            "title": "Nasze usługi.",
            "paragraph": "Usługi, które oferujemy za pośrednictwem Witryny, to usługa umożliwiająca tworzenie, wysyłanie i zarządzanie wiadomościami e-mail („Wiadomości E-mail”) do wybranych przez Ciebie odbiorców („Usługa”)."
        },
        "license": {
            "title": "Licencja.",
            "paragraph": "Z zastrzeżeniem niniejszych Warunków, Menuonline Inc niniejszym udziela Ci niewyłącznej, ograniczonej, niezbywalnej licencji na czas trwania Okresu, aby przeglądać Witrynę i korzystać z Usługi zgodnie z funkcjami Usługi. Zastrzegamy sobie wszelkie prawa, które nie zostały wyraźnie przyznane w niniejszym dokumencie w Usłudze i Treści Menuonline Inc (zgodnie z definicją poniżej)."
        },
        "emailingContent": {
            "title": "Treść wysyłana e-mailem.",
            "paragraph": "Podczas interakcji z Menuonline Inc za pośrednictwem tej Witryny lub Usługi, zgadzasz się podawać prawdziwe i dokładne informacje. Ponadto rozumiesz, że podawanie poufnych informacji za pośrednictwem poczty e-mail jest niebezpieczną praktyką. Rozumiesz, że ponosisz wyłączną odpowiedzialność za wszystko, co wysyłasz do kogokolwiek korzystającego z naszej Usługi."
        },
        "support": {
            "title": "Wsparcie.",
            "paragraph": "Zapewniamy Ci wsparcie, które pomoże Ci stać się samowystarczalnym w ramach Usługi. Zapewniamy Ci wsparcie w formie dokumentacji i filmów na Stronie, w systemie zgłoszeń pomocy technicznej Menuonline Inc oraz telefonicznie. Możesz się z nami skontaktować, klikając zakładkę „Pomoc” u góry Strony. Wsparcie produktu jest bezpłatne; jednak zastrzegamy sobie prawo do zaprzestania wsparcia dla określonych kont klientów według naszego uznania."
        }
    },
    "terms_condition": {
        "title": "Warunki korzystania z usługi | Menu online",
        "header": "Warunki korzystania z serwisu dla nowych i obecnych klientów"
    },
    "privacy_policy_title": "POLITYKA PRYWATNOŚCI",
    "privacy_policy_effective_date": "Obowiązuje od 16 stycznia 2020 r.",
    "privacy_policy_general_title": "OGÓLNA POLITYKA PRYWATNOŚCI",
    "privacy_policy_intro": "menuonline.com (Menuonline i „my”) rozumie znaczenie ochrony prywatności danych osobowych osób fizycznych i znaczenie bezpieczeństwa poufnych informacji wszelkiego rodzaju. Stworzyliśmy tę Politykę Prywatności (dalej „Polityka”), aby zademonstrować nasze zaangażowanie w ochronę Twojej „prywatności”. Niniejsza Polityka Prywatności wyjaśnia:",
    "privacy_policy_points": [
        "Jakie dane osobowe zbieramy i dlaczego je zbieramy;",
        "W jaki sposób wykorzystujemy Twoje dane osobowe;",
        "Z kim dzielimy się Twoimi danymi osobowymi; i",
        "Możliwości dostępu, aktualizacji i usuwania danych osobowych z usługi."
    ],
    "privacy_policy_pipeda": "Menuonline zarządza gromadzeniem wszystkich informacji zgodnie z kanadyjską Ustawą o ochronie danych osobowych i dokumentów elektronicznych (PIPEDA).",
    "privacy_policy_update_notice": "Należy pamiętać, że Menuonline zastrzega sobie prawo do aktualizacji lub zmiany niniejszej Polityki Prywatności w dowolnym momencie. Powiadomimy Cię o wszelkich zmianach, publikując zaktualizowaną Politykę na tej stronie internetowej lub udostępniając ją za pośrednictwem usług. Najnowsza wersja niniejszej Polityki będzie regulować korzystanie przez mycircle z danych osobowych użytkowników. Kontynuując dostęp do strony internetowej mycircle lub kontynuując korzystanie z jej usług po wprowadzeniu zmian do niniejszej Polityki Prywatności, zgadzasz się być związanym zmienionymi warunkami.",
    "privacy_policy_consent_title": "ZGODA NA DANE OSOBOWE",
    "privacy_policy_consent_text": "Podając Menuonline swoje dane osobowe za pośrednictwem naszej witryny internetowej lub dowolnego z naszych formularzy, wyrażasz zgodę na gromadzenie, używanie i ujawnianie takich informacji zgodnie z niniejszą Polityką lub w celach wskazanych Tobie w momencie podania danych osobowych. Wszelkie wtórne wykorzystanie danych osobowych będzie przetwarzane wyłącznie za Twoją wyraźną zgodą lub zostanie Ci udostępniona możliwość rezygnacji.",
    "privacy_policy_opt_out_text": "Masz możliwość cofnięcia zgody lub rezygnacji w dowolnym momencie. Jeśli chcesz cofnąć zgodę lub zrezygnować, możesz kliknąć na link preferencji komunikacyjnych na dole dowolnego e-maila, który od nas otrzymasz.",
    "privacy_policy_info_collection_title": "INFORMACJE, KTÓRE GROMADZIMY",
    "privacy_policy_info_collection_text": "Menuonline zbiera i przetwarza dane osobowe zgodnie z niniejszą Polityką. Nie będziemy sprzedawać, udostępniać ani wynajmować tych danych innym osobom, z wyjątkiem przypadków uwzględnionych w niniejszej Polityce. Menuonline zbiera dane osobowe w celu świadczenia Ci naszych usług lub produktów.",
    "privacy_policy_info_types_text": "Menuonline gromadzi następujące rodzaje informacji:",
    "privacy_policy_account_holders_title": "Posiadacze kont Menuonline",
    "privacy_policy_registration_info": "Informacje rejestracyjne: Jeśli zarejestrujesz się, aby korzystać z usług, potrzebujesz konta Menuonline, aby zostać posiadaczem konta („Posiadacz konta”). Podczas rejestracji konta zostaniesz poproszony o podanie pewnych podstawowych informacji, takich jak imię i nazwisko, adres e-mail, nazwa użytkownika, hasło, nazwa firmy, zawód, lokalizacja i numer telefonu. Hasła są szyfrowane – personel Menuonline nie może przeglądać ani odzyskiwać haseł.",
    "privacy_policy_billing_info": "Informacje dotyczące rozliczeń: Jeśli subskrybujesz nasze usługi, będziesz musiał podać nam informacje dotyczące płatności i rozliczeń.",
    "privacy_policy_cookies_info": "Pliki cookie: Używamy plików cookie do śledzenia danych odwiedzających, takich jak źródło odwiedzin witryny i liczba odwiedzin na naszej stronie internetowej.",
    "privacy_policy_ip_info": "Adresy IP: Adresy IP są gromadzone w związku z korzystaniem przez Ciebie z usług (np. w celu rejestrowania lokalizacji na poziomie kraju i adresów IP używanych podczas korzystania z naszych usług).",
    "privacy_policy_your_contacts_title": "Twoje kontakty",
    "privacy_policy_distribution_list": "Lista dystrybucyjna: W ramach korzystania z naszych usług możesz nam przekazać lub my możemy gromadzić w ramach usług dane osobowe dotyczące Twoich kontaktów („Kontakty”). Informacje te obejmują adresy e-mail i mogą obejmować inne dane identyfikacyjne, takie jak imię i nazwisko, nazwa firmy lub inne informacje, które przekazujesz Menuonline podczas korzystania z usług. Musisz upewnić się, że uzyskasz odpowiednią zgodę od swoich Kontaktów, aby umożliwić nam przetwarzanie ich danych.",
    "privacy_policy_billing_info_contacts": "Informacje dotyczące rozliczeń: Jeśli subskrybujesz nasze usługi, będziesz musiał podać nam informacje dotyczące płatności i rozliczeń.",
    "privacy_policy_cookies_contacts": "Pliki cookie: Menuonline będzie używać plików cookie i podobnych technologii śledzenia do zbierania i wykorzystywania danych osobowych o Twoich kontaktach tylko wtedy, gdy dodałeś kod śledzenia Menuonline do swojej witryny. Wszystkie strony docelowe hostowane przez Menuonline będą automatycznie miały kod śledzenia.",
    "privacy_policy_ip_contacts": "Adresy IP: Adresy IP Twoich Kontaktów są gromadzone w ramach świadczenia usług (np. w celu rejestrowania lokalizacji na poziomie kraju oraz adresów IP użytych podczas przesyłania formularza i wyrażania zgody).",
    "privacy_policy_info_about_contacts_title": "INFORMACJE, KTÓRE GROMADZIMY O TWOICH KONTAKTACH",
    "privacy_policy_info_about_contacts_notice": "*Niniejsza sekcja dotyczy wyłącznie informacji gromadzonych, wykorzystywanych lub ujawnianych przez Menuonline na temat kontaktów posiadacza konta w celu świadczenia usług*",
    "privacy_policy_info_about_contacts_desc": "Menuonline będzie zbierać, wykorzystywać lub ujawniać dane osobowe dotyczące Kontaktów tylko wtedy, gdy Posiadacz Konta zarejestrował się w określonych usługach, w ramach których udostępnia nam listę dystrybucyjną. Posiadacze Kont ponoszą wyłączną odpowiedzialność za zapewnienie, że przestrzegali wszystkich obowiązujących przepisów podczas udostępniania danych osobowych dotyczących swoich Kontaktów w Menuonline za pośrednictwem usług.",
    "privacy_policy_info_auto_collect_title": "Informacje zbierane automatycznie przez Menuonline",
    "privacy_policy_info_auto_collect_desc1": "Gdy Kontakty Posiadacza Konta angażują się w kampanię e-mailową otrzymaną od Posiadacza Konta lub wchodzą w interakcję z linkiem do połączonej witryny Posiadacza Konta, Menuonline może zbierać informacje o urządzeniu takiego Kontaktu i interakcji z wiadomością e-mail za pomocą plików cookie. Informacje te są zbierane wyłącznie w celu świadczenia usług i zapewnienia wglądu Posiadaczowi Konta.",
    "privacy_policy_info_auto_collect_desc2": "Informacje, które zbieramy o urządzeniu Kontaktu i aplikacjach, których Kontakt używa do uzyskiwania dostępu do wiadomości e-mail wysyłanych przez Posiadacza Konta za pośrednictwem naszych usług, mogą obejmować adres IP Kontaktu, system operacyjny, identyfikator przeglądarki i inne powiązane informacje o systemie i połączeniu Kontaktu.",
    "privacy_policy_info_auto_collect_desc3": "Informacje, które zbieramy na temat interakcji Contact z wiadomościami e-mail wysyłanymi za pośrednictwem usług, mogą obejmować daty i godziny dostępu Contact do poczty e-mail i aktywności przeglądania, a także informacje dotyczące realizacji usług, takie jak dostarczalność wiadomości e-mail i innych komunikatów wysyłanych do Contact przez Posiadacza konta za pośrednictwem usług.",
    "privacy_policy_info_auto_collect_desc4": "Należy pamiętać, że Menuonline nie będzie się kontaktować z żadnym z Twoich kontaktów, chyba że będzie to wymagane przez obowiązujące prawo.",
    "privacy_policy_why_we_collect_title": "DLACZEGO ZBIERAMY TWOJE INFORMACJE",
    "privacy_policy_why_we_collect_desc": "Menuonline zbiera informacje w następujących celach:",
    "privacy_policy_why_collect_1": "aby zapewnić Użytkownikom Kont Menuonline usługi, które subskrybują, w tym wykorzystanie danych Użytkownika Konta w celu korzystania z usług;",
    "privacy_policy_why_collect_2": "do administracji i zarządzania kontem, a także dostępu do witryny mycircle;",
    "privacy_policy_why_collect_3": "jeśli wyraziłeś zgodę na otrzymywanie materiałów marketingowych i promocyjnych, aby informować Cię o innych produktach i usługach oferowanych przez Menuonline; i",
    "privacy_policy_why_collect_4": "aby odpowiedzieć na komunikaty Posiadacza Konta lub wysłać żądane przez Ciebie informacje. Może to obejmować, ale nie ogranicza się do:",
    "privacy_policy_why_collect_5": "oferty specjalne i aktualizacje;",
    "privacy_policy_why_collect_6": "biuletyn; i",
    "privacy_policy_why_collect_7": "ogłoszenia serwisowe.",
    "privacy_policy_opt_out_desc": "Możesz w każdej chwili zrezygnować z otrzymywania wiadomości, klikając przycisk „Anuluj subskrypcję” znajdujący się na dole każdej wiadomości e-mail wysłanej do Ciebie przez Menuonline.",
    "privacy_policy_use_disclosure_title": "WYKORZYSTANIE I UJAWNIANIE DANYCH OSOBOWYCH",
    "privacy_policy_use_disclosure_desc1": "Dane osobowe przekazane do Menuonline, w tym za pośrednictwem strony internetowej Menuonline lub określonego formularza, są ściśle poufne, chyba że zostaną ujawnione zgodnie z wyraźną zgodą Posiadacza Konta lub wymogami prawnymi. W każdym przypadku wszelkie dane osobowe przekazane na dowolnej platformie „Menuonline” przez Posiadaczy Konta są dobrowolne: wszelkie dane osobowe przekazane przez Posiadacza Konta są przekazywane według uznania Posiadacza Konta. Należy pamiętać, że cofnięcie zgody może skutkować brakiem dostępu do niektórych usług Menuonline lub brakiem możliwości korzystania z nich.",
    "privacy_policy_use_disclosure_desc2": "Menuonline korzysta z usług stron trzecich i zewnętrznych dostawców usług w odniesieniu do swojej witryny i usług. Dane osobowe Posiadacza Konta mogą być udostępniane takim stronom trzecim i dostawcom. Możemy również udostępniać zagregowane statystyki dotyczące Posiadaczy Konta, sprzedaży, wzorców ruchu i powiązanych informacji renomowanym stronom trzecim. Menuonline wymaga, aby każdy zewnętrzny dostawca, który ma dostęp do danych osobowych, był związany zobowiązaniami zgodnymi z niniejszą Polityką Prywatności i aby tacy dostawcy gromadzili, wykorzystywali lub ujawniali dane osobowe Posiadacza Konta wyłącznie w celu świadczenia usług Menuonline.",
    "privacy_policy_use_disclosure_desc3": "Dane osobowe podane przez Posiadaczy Kont nie będą sprzedawane, udostępniane ani wynajmowane organizacjom zewnętrznym, chyba że zgodnie z Twoją dorozumianą lub wyraźną zgodą. Menuonline zastrzega sobie prawo do kontaktowania się z Posiadaczami Kont w celu przekazywania informacji, odpowiadania na pytania lub powiadamiania o wszelkich zmianach w naszym programie lub naszych zasadach. Należy pamiętać, że Menuonline nie będzie kontaktować się z żadnym z Twoich Kontaktów, chyba że wymaga tego obowiązujące prawo.",
    "privacy_policy_use_disclosure_desc4": "Menuonline ujawni dane osobowe wyłącznie za zgodą właściwej osoby lub:",
    "privacy_policy_disclosure_list_1": "gdy jest to dozwolone lub wymagane przez prawo, np. w odpowiedzi na wezwanie sądowe lub inny proces prawny;",
    "privacy_policy_disclosure_list_2": "nabywcy całości lub części Menuonline;",
    "privacy_policy_disclosure_list_3": "dostawcom usług Menuonline zgodnie z niniejszą Polityką; lub",
    "privacy_policy_disclosure_list_4": "w celu wypełnienia obowiązków prawnych, obejmujących między innymi obowiązki sprawozdawcze regulacyjne.",
    "privacy_policy_service_providers_title": "Menuonline korzysta z usług następujących podmiotów trzecich i dostawców usług (dane osobowe Twoich Kontaktów nie są udostępniane „takim” podmiotom trzecim):",
    "privacy_policy_service_provider_1": "Używamy ManyChat w celu zapewnienia usług czatu na stronie internetowej dla potrzeb sprzedaży i wsparcia, a także w celu wysyłania wiadomości e-mail z dalszymi informacjami na temat tych rozmów.",
    "privacy_policy_service_provider_2": "Używamy narzędzia AskNicely do przeprowadzania ankiet wśród naszych klientów w celu zapytania o jakość otrzymywanej obsługi i wsparcia.",
    "privacy_policy_service_provider_3": "Używamy join.me do planowania spotkań z obecnymi i potencjalnymi klientami, rozmów z działem pomocy technicznej i prezentacji oprogramowania.",
    "privacy_policy_service_provider_4": "Używamy Google Analytics do śledzenia anonimowej aktywności na stronie internetowej i pomiaru wzorców korzystania z naszej witryny.",
    "privacy_policy_breach_notification_title": "Powiadomienie o naruszeniu",
    "privacy_policy_breach_notification_desc": "Menuonline zobowiązuje się do przestrzegania i zapewnienia Posiadaczom Kont wszelkiej uzasadnionej pomocy w zakresie przestrzegania obowiązujących przepisów prawa w odniesieniu do nieautoryzowanego użycia, dostępu lub ujawnienia danych osobowych.",
    "privacy_policy_rights_access_title": "TWOJE PRAWA I DOSTĘP",
    "privacy_policy_rights_access_desc": "Masz prawo dostępu do danych osobowych, które Menuonline przetwarza na Twój temat. Możesz zażądać od nas informacji o:",
    "privacy_policy_rights_access_list_1": "Informacje osobiste, które o Tobie posiadamy",
    "privacy_policy_rights_access_list_2": "Kategorie przetwarzanych danych osobowych",
    "privacy_policy_rights_access_list_3": "Cele przetwarzania",
    "privacy_policy_rights_access_list_4": "Szczegóły, komu Twoje dane osobowe zostały\/zostaną ujawnione",
    "privacy_policy_rights_access_list_5": "Jak długo przechowujemy Twoje dane osobowe",
    "privacy_policy_rights_access_list_6": "Jeżeli nie zebraliśmy informacji bezpośrednio od Ciebie, informacje o źródle",
    "privacy_policy_rights_access_list_7": "Jak złożyć skargę do właściwego organu nadzorczego",
    "privacy_policy_rights_access_list_8": "Możesz również poprosić nas o:",
    "privacy_policy_rights_access_list_9": "Że aktualizujemy wszelkie niekompletne lub niedokładne informacje o Tobie",
    "privacy_policy_rights_access_list_10": "Poproś nas o usunięcie Twoich danych osobowych zgodnie z obowiązującymi przepisami prawa",
    "privacy_policy_rights_access_contact": "Możesz zażądać realizacji swoich praw, kontaktując się z nami pod adresem 2851 Dufferin Street, Toronto, ON, Kanada lub wysyłając wiadomość e-mail na adres {link}.",
    "privacy_policy_accountability_title": "ODPOWIEDZIALNOŚĆ",
    "privacy_policy_accountability_desc": "Menuonline zobowiązuje się do ponoszenia odpowiedzialności za informacje osobiste i poufne, które nam przekazujesz. Aby uzyskać informacje kontaktowe, zapoznaj się z końcem niniejszej Polityki.",
    "privacy_policy_links_other_sites_title": "LINKI DO INNYCH STRON",
    "privacy_policy_links_other_sites_desc1": "Witryna internetowa Menuonline może zawierać linki do innych witryn. Należy pamiętać, że Menuonline nie ponosi odpowiedzialności za praktyki dotyczące prywatności innych witryn. Zachęcamy naszych Posiadaczy Kont do uświadomienia sobie, że opuszczając naszą witrynę, powinni dokładnie przeczytać oświadczenia dotyczące polityki prywatności każdej witryny, która zbiera dane osobowe.",
    "privacy_policy_links_other_sites_desc2": "Niniejsza Polityka Prywatności ma zastosowanie wyłącznie do informacji zbieranych przez nas.",
    "privacy_policy_links_other_sites_desc3": "Jeśli korzystasz z linku, aby przejść ze strony Menuonline w celu zakupu produktów lub usług, dokonujesz transakcji zakupu zgodnie z zasadami i regulaminami firmy sprzedającej, a nie Menuonline.",
    "privacy_policy_cookies_title": "POLITYKA PRYWATNOŚCI COOKIES",
    "privacy_policy_cookies_desc1": "Witryna internetowa i usługi Menuonline wykorzystują pliki cookie do zbierania i przechowywania pewnych informacji zgodnie z niniejszą polityką. „Plik cookie” to mały plik tekstowy wysyłany ze strony internetowej i przechowywany na komputerze użytkownika przez przeglądarkę internetową użytkownika. Po odwiedzeniu witryny, która wykorzystuje pliki cookie, plik cookie jest pobierany na komputer lub urządzenie mobilne. Następnym razem, gdy odwiedzisz tę witrynę, Twoje urządzenie zapamięta przydatne informacje, takie jak preferencje, odwiedzane strony lub opcje logowania.",
    "privacy_policy_cookies_desc2": "Pliki cookie są szeroko stosowane w celu dodania funkcjonalności do witryn internetowych lub zapewnienia ich wydajniejszego działania. Nasza witryna opiera się na plikach cookie w celu optymalizacji doświadczeń użytkownika i zapewnienia prawidłowego działania usług witryny.",
    "privacy_policy_cookies_desc3": "Większość przeglądarek internetowych pozwala na ograniczenie lub zablokowanie plików cookie za pomocą ustawień przeglądarki. Jeśli jednak wyłączysz obsługę plików cookie, może to mieć wpływ na możliwość korzystania z niektórych części naszej witryny lub usług.",
    "privacy_policy_cookies_analytical_purpose": "<strong> Cele analityczne: <\/strong> Używamy plików cookie do analizowania aktywności Posiadacza konta w celu ulepszenia naszej witryny. Na przykład możemy używać plików cookie do przeglądania wzorców zbiorczych, takich jak funkcje używane przez Posiadaczy konta. Możemy używać takiej analizy, aby uzyskać wgląd w to, jak poprawić funkcjonalność i doświadczenie użytkownika naszej witryny.",
    "privacy_policy_cookies_preferences_user_experience": "<strong> Twoje preferencje i doświadczenie użytkownika: <\/strong> Używamy plików cookie, aby zbierać pewne informacje o odwiedzających i Posiadaczach kont, takie jak typ przeglądarki, serwer, preferencje językowe i ustawienia kraju, aby przechowywać preferencje Posiadacza kont na naszej stronie internetowej, aby zapewnić bardziej spójne i wygodne doświadczenie Posiadacza kont. Używamy plików cookie, aby zachować Twój stan zalogowania, gdy wielokrotnie odwiedzasz naszą stronę internetową.",
    "privacy_policy_cookies_targeted_ads": "<strong> Świadczenie usług pomiarowych i lepiej ukierunkowanych reklam i marketingu: <\/strong> Używamy plików cookie, sygnalizatorów WWW i innych technologii przechowywania od partnerów zewnętrznych, takich jak Google i Facebook, do usług pomiarowych, lepszego ukierunkowania reklam i celów marketingowych. Dzieje się tak, gdy odwiedzasz naszą witrynę. Te pliki cookie, sygnalizatory WWW i inne technologie przechowywania pozwalają nam wyświetlać materiały promocyjne Menuonline na innych odwiedzanych przez Ciebie stronach w Internecie.",
    "privacy_policy_security_title": "BEZPIECZEŃSTWO",
    "privacy_policy_security_desc1": "Menuonline dokłada wszelkich starań, aby chronić poufne informacje wszelkiego typu, w tym dane osobowe, za pomocą zabezpieczeń organizacyjnych, fizycznych, mechanicznych i elektronicznych odpowiednich do wrażliwości informacji. Nasze serwery znajdują się w Kanadzie i przestrzegają standardów branżowych dotyczących bezpieczeństwa.",
    "privacy_policy_security_desc2": "Należy jednak pamiętać, że nie możemy zagwarantować, iż środki, które stosujemy, zapewnią bezpieczeństwo informacji.",
    "privacy_policy_retention_title": "ZATRZYMANIE",
    "privacy_policy_retention_desc": "Przechowujemy dane osobowe Użytkowników Kont i Kontaktów tak długo, jak jest to konieczne do celów określonych w niniejszej Polityce Prywatności i zgodnie z postanowieniami dotyczącymi przechowywania danych zawartymi w Warunkach Korzystania.",
    "privacy_policy_access_questions_title": "DOSTĘP, PYTANIA I WĄTPLIWOŚCI",
    "privacy_policy_access_questions_desc": "Osoba, która przekazała swoje dane osobowe serwisowi Menuonline, może złożyć pisemny wniosek o dostęp do swoich danych osobowych przechowywanych przez Menuonline lub o ich poprawienie.",
    "terms_obj": {
        "terms_conditions_title": "WARUNKI KORZYSTANIA Z USŁUGI",
        "terms_conditions_subtitle": "Warunki korzystania z serwisu dla nowych i obecnych klientów",
        "terms_conditions_desc1": "NASTĘPUJĄCE KLUCZOWE PUNKTY (W TYM ODNIESIENIA DO SEKCJI) SĄ PODANE WYŁĄCZNIE DLA TWOJEJ WYGODY I NIE ZASTĘPUJĄ PEŁNYCH WARUNKÓW I POSTANOWIEŃ. TWOJĄ ODPOWIEDZIALNOŚCIĄ JEST PRZECZYTANIE TYCH WARUNKÓW I POSTANOWIEŃ PRZED UŻYCIEM SERWISU Menuonline.",
        "terms_conditions_desc2": "Korzystanie z Menuonline oznacza, że zgadzasz się z Warunkami i Postanowieniami oraz oświadczasz i gwarantujesz, że jesteś upoważniony przez swoją organizację lub firmę do tego. (Zobacz sekcję 3 „Jak to działa”)",
        "terms_conditions_desc3": "Menuonline to usługa marketingowa oparta na zezwoleniu. Celowe wysyłanie „spamu” lub naruszanie Warunków i Postanowień spowoduje zamknięcie konta. (Zobacz s.16 „Dopuszczalne korzystanie z usługi”)",
        "terms_conditions_desc4": "Dane, które przechowujesz w Menuonline, takie jak zapisy kontaktów, treść wiadomości e-mail i informacje zbierane za pośrednictwem Menuonline, należą do Ciebie. (Zobacz sekcję 14 „Co jest Twoją własnością?”).",
        "terms_conditions_desc5": "Oprogramowanie Menuonline i zawartość naszej witryny internetowej należą do nas. (Zobacz s.13 „Co jest naszą własnością?”)",
        "terms_conditions_desc6": "Oprócz Twoich własnych użytkowników, dane lub niepublikowane treści, które hostujesz w Menuonline, mogą być przeglądane tylko przez pracowników i partnerów Menuonline, którzy podpisali umowy o zachowaniu poufności. (Zobacz sekcję 14 „Co posiadasz?”).",
        "terms_conditions_desc7": "Wszystkie dane osobowe (PII) przechowywane są wyłącznie na serwerach zlokalizowanych w Kanadzie. (Zobacz sekcję 14 „Co posiadasz?”).",
        "terms_conditions_desc8": "Usługa Menuonline podlega prawu Kanady i prowincji Ontario. (Zobacz s.24 „Prawo właściwe”)",
        "terms_conditions_desc9": "Wszelkie żądania dotyczące Twoich danych lub treści składane przez osoby fizyczne, policję lub inne agencje regulacyjne z Kanady lub spoza Kanady będą zawsze kierowane do Ciebie. Jedynym wyjątkiem byłoby, gdyby to naraziło nas na naruszenie prawa kanadyjskiego lub prawa Ontario; jednak we wszystkich przypadkach będziemy stanowczo sprzeciwiać się jakiemukolwiek ujawnieniu Twoich danych przez kogokolwiek innego niż Ty. (Zobacz s.14 „Co posiadasz?”).",
        "terms_conditions_desc10": "Bezpieczeństwo Menuonline jest tak dobre, jak zarządzanie nazwami użytkowników i hasłami. Nie pobieramy opłat za konta użytkowników Menuonline, więc utwórz osobne dla każdego użytkownika i zachowaj hasła w tajemnicy. Jesteś odpowiedzialny za swoje konto. (Zobacz s.3 „Jak to działa”)",
        "terms_conditions_desc11": "Możesz anulować swoje konto w dowolnym momencie. Nie zapewniamy zwrotów za niewykorzystany kredyt, jeśli anulujesz. (Zobacz s.3 „Jak to działa”)",
        "terms_conditions_desc12": "Możemy anulować Twoje konto w dowolnym momencie. Zapewniamy zwroty za kredyty e-mail i niewykorzystane usługi, jeśli anulujemy Twoje konto. (Zobacz s.3 „Jak to działa”)",
        "terms_conditions_desc13": "Twoje dane zostaną usunięte po anulowaniu i mogą zostać usunięte również wtedy, gdy użytkownik konta nie zalogował się przez 12 miesięcy lub dłużej. Będziemy przechowywać zagregowane dane z Twojego konta, które nie zawierają danych osobowych do celów statystycznych. (Zobacz s.3 „Jak to działa”)",
        "terms_conditions_desc14": "Kredyty e-mail są kupowane na zasadzie pay-as-you-go i nigdy nie wygasają. Jedynym wyjątkiem jest sytuacja, gdy nie logowałeś się na swoje konto przez 12 miesięcy. W takim przypadku zastrzegamy sobie prawo do usunięcia Twojego konta, Twoich danych i powiązanych kredytów. (Zobacz s.10 „Ile płacę za Usługę?”)",
        "terms_conditions_desc15": "Dokładamy wszelkich starań, aby zapewnić, że Menuonline działa cały czas i aby zapewnić wcześniejsze powiadomienie, jeśli będziemy musieli tymczasowo zawiesić usługę w celu konserwacji. Nie gwarantujemy jednak, że usługa będzie działać cały czas i nie ponosimy odpowiedzialności za straty poniesione przez Ciebie w wyniku korzystania z Menuonline, w tym straty z Twojej strony, jeśli system nie działa w czasie, w którym tego potrzebujesz. (Zobacz s.17 „Zrzeczenie się odpowiedzialności”).",
        "terms_conditions_desc16": "Nie monitorujemy Twojej zawartości ani tego, do kogo wysyłasz wiadomości. Monitorujemy jednak skargi i inne problemy spowodowane przez Twoje działania. Zbyt wiele skarg lub problemów może skutkować anulowaniem Twojej usługi według naszego uznania. (Zobacz s.7 „Monitorowanie”)",
        "terms_conditions_desc17": "Zobowiązujemy się do zapewnienia doskonałego wsparcia produktu, aby pomóc Ci stać się samowystarczalnym dzięki Menuonline. Zapewniamy wsparcie w formie dokumentacji i wideo na naszej stronie internetowej, w systemie zgłoszeń pomocy technicznej Menuonline oraz telefonicznie. Wsparcie produktu jest bezpłatne; zastrzegamy sobie jednak prawo do zaprzestania wsparcia dla określonych kont klientów według własnego uznania. (Zobacz s.5 „Wsparcie”)",
        "terms_conditions_desc18": "Usługi produktowe są przeznaczone na te chwile, kiedy chcesz, abyśmy wykonali jakąś pracę związaną z Menuonline, zamiast robić to samemu. Nasza stawka za Usługi produktowe wynosi 135 USD za godzinę. Oferujemy również usługi konsultingowe po wyższych stawkach. Zastrzegamy sobie prawo do zmiany naszych stawek w dowolnym momencie. (Zobacz s.6 „Usługi produktowe i konsultingowe”)",
        "terms_conditions_desc19": "Poinformujemy Cię, jeśli uznamy, że doszło do naruszenia Twoich danych i podejmiemy wysiłki, aby dostarczyć powiązane szczegóły i informacje. (Zobacz s.14 „Co posiadasz?”)",
        "terms_conditions_desc20": "Zastrzegamy sobie prawo do wprowadzania zmian do niniejszej umowy w dowolnym momencie. Postaramy się poinformować Cię o zmianach w warunkach, ale korzystanie z usługi oznacza zgodę na zmiany w Warunkach. (Zobacz s.9 „Zmiany”).",
        "terms_conditions_desc21": "Warunki korzystania z usługi oraz nasza Polityka prywatności zostały sporządzone wyłącznie w języku angielskim. Użytkownik zgadza się, że w przypadku jakichkolwiek sprzeczności z wersją przetłumaczoną, wersja angielska Warunków korzystania z usługi oraz Polityki prywatności będzie miała pierwszeństwo.",
        "terms_conditions_mycircle": "WARUNKI KORZYSTANIA Z MYCIRCLE",
        "terms_conditions_last_update": "OSTATNIA AKTUALIZACJA: 14 stycznia 2020 r.",
        "terms_conditions_usage_agreement": "Przeczytaj uważnie niniejsze warunki korzystania z usługi (dalej „Warunki”) przed skorzystaniem z tej witryny internetowej (dalej „Witryna”). Niniejsze Warunki zwalniają Menuonline (dalej „Menuonline”, „my” lub „nas”) i inne osoby z odpowiedzialności i\/lub ograniczają naszą i ich odpowiedzialność oraz zawierają inne ważne postanowienia, które mają zastosowanie do korzystania z tej Witryny.",
        "terms_conditions_acceptance": "Odwiedzając lub korzystając z tej Witryny lub Usługi, zgadzasz się w swoim własnym imieniu, a także w imieniu każdej organizacji, w której imieniu możesz działać (zwanych łącznie „Tobą”), zaakceptować i przestrzegać niniejszych Warunków przy każdym korzystaniu z tej Witryny i każdej wizycie na niej.",
        "terms_conditions_services": "1. Nasze usługi.",
        "terms_conditions_services_desc": "Usługi, które oferujemy za pośrednictwem Witryny, umożliwiają tworzenie, wysyłanie i zarządzanie wiadomościami e-mail („Wiadomości E-mail”) do wybranych przez Ciebie odbiorców („Usługa”).",
        "terms_conditions_license": "2. Licencja.",
        "terms_conditions_license_desc": "Z zastrzeżeniem niniejszych Warunków, Menuonline niniejszym udziela Ci niewyłącznej, ograniczonej, niezbywalnej licencji na czas trwania Okresu, aby przeglądać Witrynę i korzystać z Usługi zgodnie z funkcjami Usługi. Zastrzegamy sobie wszelkie prawa, które nie zostały wyraźnie przyznane w niniejszym dokumencie w Usłudze i Treści Menuonline (zgodnie z definicją poniżej).",
        "terms_conditions_account": "3. Jak to działa.",
        "terms_conditions_account_desc": "Aby korzystać z Usługi, musisz zarejestrować konto. Twoje konto jest bezpłatne. Twoje konto daje Ci dostęp do Usługi i „funkcji”, które możemy od czasu do czasu ustanawiać i utrzymywać. Aby kwalifikować się do konta, musisz:",
        "terms_conditions_account_requirements": [
            "mieć ukończone osiemnaście (18) lat i być zdolnym do zawierania umów;",
            "ukończyć proces rejestracji, który przedstawiliśmy;",
            "wyrażam zgodę na niniejsze Warunki;",
            "podaj prawdziwe, kompletne i aktualne dane kontaktowe oraz informacje dotyczące rozliczeń\/płatności."
        ],
        "responsibility": "Odpowiedzialność.",
        "responsibilityText": "Jesteś wyłącznie odpowiedzialny za aktywność, która ma miejsce na Twoim koncie i musisz zachować poufność i bezpieczeństwo hasła do konta. Konta są bezpłatne, dlatego zachęcamy do założenia osobnego konta dla każdego użytkownika, którego posiadasz. Jesteś również odpowiedzialny za każde konto, do którego masz dostęp, niezależnie od tego, czy autoryzowałeś korzystanie z tego konta. Zgadzasz się, że jesteś wyłącznie odpowiedzialny za swoje interakcje z osobami, do których wysyłasz e-maile, i będziesz wysyłał e-maile tylko do osób, od których masz zgodę. Musisz natychmiast powiadomić nas o każdym nieautoryzowanym użyciu Twoich kont.",
        "compliance_with_CASL": "Zgodność z kanadyjskim ustawodawstwem antyspamowym (CASL).",
        "compliance_with_CASL_text": "Rolą Menuonline jest ułatwianie zarządzania ustawieniami zgody, a nie egzekwowanie przepisów. Twoim obowiązkiem jest przestrzeganie CASL. Pomagamy Ci, ustawiając domyślną funkcjonalność i ułatwiając uzyskiwanie i aktualizowanie udokumentowanej wyraźnej zgody oraz zarządzanie toczącymi się datami wygaśnięcia domniemanej zgody. Masz pełną kontrolę nad zarządzaniem zgodą dla swojej bazy danych; możesz aktualizować status zgody dla wszystkich swoich kontaktów zbiorczo w dowolnym momencie, zastępować ustawienia domyślne i wyłączać automatyczne wygasanie statusu domniemanej zgody.",
        "closing_account": "Zamknięcie konta.",
        "closing_account_text": "Zarówno Ty, jak i Menuonline możecie rozwiązać tę Umowę w dowolnym momencie i z dowolnego powodu, powiadamiając o tym drugą stronę. Jeśli podasz jakiekolwiek informacje, które są nieprawdziwe, niedokładne, nieaktualne lub niekompletne lub użyjesz Usługi w sposób naruszający niniejsze Warunki, mamy prawo natychmiast zawiesić Twoje konto i zakończyć Twój dostęp do Usługi, oprócz wszelkich innych środków zaradczych dostępnych dla nas. Jeśli nie będziesz korzystać ze swojego konta przez dwanaście (12) miesięcy, możemy je anulować. Jeśli zamkniemy Twoje konto z dowolnego powodu, zwrócimy Ci wszelkie pozostałe Kredyty. Po rozwiązaniu umowy trwale usuniemy Twoje konto i wszystkie powiązane z nim dane, w tym wysłane przez Ciebie wiadomości e-mail. Możemy przechowywać zagregowane dane z Twojego konta, które nie zawierają danych osobowych, w celach statystycznych.",
        "emailing_content": "Treść wysyłana e-mailem.",
        "emailing_content_text": "Podczas interakcji z Menuonline za pośrednictwem tej Witryny lub Usługi, zgadzasz się podawać prawdziwe i dokładne informacje. Ponadto rozumiesz, że podawanie poufnych informacji za pośrednictwem poczty e-mail jest niebezpieczną praktyką. Rozumiesz, że ponosisz wyłączną odpowiedzialność za wszystko, co wysyłasz do kogokolwiek korzystającego z naszej Usługi. celów.",
        "support": "Wsparcie.",
        "support_text": "Zapewniamy Ci wsparcie, które pomoże Ci stać się samowystarczalnym w ramach Usługi. Zapewniamy Ci wsparcie w formie dokumentacji i filmów na Stronie, w systemie zgłoszeń pomocy technicznej Menuonline oraz telefonicznie. Możesz się z nami skontaktować, klikając zakładkę „Pomoc” u góry Strony. Wsparcie produktu jest bezpłatne; zastrzegamy sobie jednak prawo do zaprzestania wsparcia dla określonych kont klientów według naszego uznania.",
        "product_and_consulting_services": "Usługi produktowe i konsultingowe.",
        "product_and_consulting_services_text": "Product Services jest dla tych czasów, kiedy chcesz, abyśmy wykonali jakąś pracę związaną z Menuonline, zamiast robić to samemu. Nasza stawka za Product Services wynosi 125 USD za godzinę. Oferujemy również usługi konsultingowe po wyższych stawkach. Zastrzegamy sobie prawo do zmiany naszych stawek w dowolnym momencie.",
        "monitoring": "Monitorowanie.",
        "monitoring_text": "Nie będziemy monitorować ani oceniać treści informacji przesyłanych za pośrednictwem Usługi, ale zbadamy, czy otrzymamy skargi na możliwe niewłaściwe użycie. Menuonline może w dowolnym momencie i według własnego uznania ustalić, czy konkretne użycie jest lub nie jest właściwe, z powiadomieniem lub bez powiadomienia, zgodnie z następującymi wytycznymi.",
        "term": "Termin.",
        "term_text": "Okres rozpoczyna się, gdy zarejestrujesz się na konto u nas i trwa tak długo, jak długo korzystasz z Usługi. Oświadczasz i gwarantujesz, że masz uprawnienia i zdolność do założenia konta dla nas (w tym, jeśli rejestrujesz się w imieniu organizacji).",
        "changes": "Zmiany.",
        "changes_text": "Okres rozpoczyna się, gdy zarejestrujesz się na konto u nas i trwa tak długo, jak długo korzystasz z Usługi. Oświadczasz i gwarantujesz, że masz uprawnienia i zdolność do zarejestrowania konta dla nas (w tym, jeśli rejestrujesz się w imieniu organizacji).",
        "payment": "ZAPŁATA",
        "what_do_i_pay_for_service": "Ile płacę za usługę?",
        "what_do_i_pay_for_service_text": "Możesz kupić przedpłacone kredyty, aby wysyłać e-maile („Kredyty”) za pośrednictwem naszej Usługi, jak szczegółowo opisujemy na stronie „cennik” w naszej Witrynie. Kredyty nigdy nie wygasają i nie ma żadnych cyklicznych opłat za te Kredyty. Jedynym wyjątkiem jest to, że jeśli nie korzystasz ze swojego konta przez dwanaście (12) miesięcy, możemy anulować Twoje konto i wszelkie niewykorzystane Kredyty. Możemy zmienić nasze ceny w dowolnym momencie, aktualizując strony cenowe w naszej Witrynie, które będą miały zastosowanie do Twojego następnego zakupu Kredytów.",
        "how_do_i_pay_for_service": "Jak mogę zapłacić za Usługę?",
        "how_do_i_pay_for_service_text": "Możesz zapłacić za Credits używając dowolnej głównej karty kredytowej. Możemy zmienić metody płatności, które akceptujemy w dowolnym momencie.",
        "can_i_get_refund": "Czy mogę otrzymać zwrot pieniędzy?",
        "can_i_get_refund_text": "Kwoty, które zapłaciłeś za korzystanie z Usługi, nie podlegają zwrotowi, jeśli anulujesz swoje konto. Jeśli anulujemy Twoje konto, zwrócimy Ci wszelkie pozostałe Kredyty.",
        "rights": "PRAWA",
        "what_do_we_own": "Co posiadamy?",
        "what_do_we_own_text": "Z wyjątkiem treści, które nam dostarczasz (w tym, bez ograniczeń, danych kontaktowych, treści wiadomości e-mail i informacji zbieranych za pośrednictwem Usługi) („Twoja Treść”), wszystkie materiały zawarte w naszej Witrynie, Usłudze oraz wszystkie materiały w niej zawarte lub przesyłane za jej pośrednictwem, w tym, bez ograniczeń, oprogramowanie, obrazy, tekst, grafiki, ilustracje, logo, patenty, znaki towarowe, znaki usługowe, fotografie, dźwięk, wideo, muzyka i treści należące do innych Użytkowników („Treść Menuonline”) (oraz wszelkie prawa własności intelektualnej z nimi związane) są naszą własnością lub własnością naszych licencjodawców.",
        "what_do_you_own": "Co posiadasz?",
        "what_do_you_own_text": "Nie rościmy sobie żadnych praw własności do Twojej Treści. Twoja Treść jest Twoja i zachowujesz prawo własności do Twojej Treści, którą przesyłasz do Usługi. Udostępniając swoją Treść za pośrednictwem Usługi, zgadzasz się zezwolić innym na przeglądanie, edytowanie i\/lub udostępnianie Twojej Treści zgodnie z Twoimi ustawieniami i niniejszymi Warunkami. Oświadczasz i gwarantujesz, że jesteś właścicielem lub masz pozwolenie na korzystanie ze wszystkich materiałów, których używasz w swoich E-mailach. Możemy korzystać z Twoich materiałów lub ujawniać je wyłącznie w sposób opisany w niniejszych Warunkach i naszej Polityce prywatności.",
        "privacy_statement": "Menuonline szanuje prywatność swoich użytkowników. Wszelkie dane osobowe, które Menuonline zbiera za pośrednictwem tej Witryny lub Usługi, podlegają Polityce prywatności Menuonline, która jest włączona przez odniesienie do niniejszych Warunków. Możemy wykorzystywać i ujawniać Twoje dane zgodnie z naszą Polityką prywatności.",
        "access_to_your_content": "Menuonline nie udostępni dostępu do Twoich Treści nikomu poza pracownikami i kontrahentami Menuonline, którzy zawarli z nami stosowne umowy o zachowaniu poufności lub uzyskaliśmy na to Twoją zgodę.",
        "governmentRequest": "Jeśli otrzymamy wniosek od rządu lub organu regulacyjnego o dostęp do Twojej Treści, powiadomimy Cię o tym wniosku i poprosimy o Twoją zgodę, zanim udostępnimy jakąkolwiek Twoją Treść. Będziemy sprzeciwiać się wszelkim prośbom o Twoją Treść i nie udostępnimy Twojej Treści w odpowiedzi na tego typu wniosek bez Twojej zgody lub bez wymogu sądu właściwej jurysdykcji lub właściwego organu regulacyjnego.",
        "breachNotification": "W przypadku, gdy dowiemy się, że Twoja Treść została poddana nieautoryzowanemu dostępowi, użyciu, kopiowaniu, kradzieży, utracie lub użyciu lub ujawnieniu z naruszeniem niniejszych Warunków („Naruszenie”), powiadomimy Cię o tym niezwłocznie i poinformujemy o tym, co się wydarzyło. Zgadzasz się, że Naruszenia będą traktowane zgodnie z obowiązującym prawem, a jeśli będziemy potrzebować, abyś poinformował członków swoich list dystrybucyjnych e-mail, zgodzisz się zrobić to niezwłocznie i zgodnie z naszymi poleceniami.",
        "contentRetention": "Będziemy przechowywać, wykorzystywać i przesyłać Twoje Treści w Kanadzie, chyba że uzyskasz Twoją uprzednią pisemną zgodę lub polecenie (na przykład, jeśli zdecydujesz się wysłać Twoje Treści osobom spoza Kanady). Serwery używane do świadczenia Usługi znajdują się w Kanadzie.",
        "accessTrouble": "Jeżeli masz trudności z dostępem do swoich Treści, Menuonline pomoże Ci je odzyskać, rozliczając się na podstawie naliczonego czasu i materiałów.",
        "reportingAbuse": "Jeśli uważasz, że ktoś narusza którykolwiek z niniejszych Warunków, powiadom nas o tym natychmiast. Jeśli otrzymałeś spam, który Twoim zdaniem pochodzi od innego użytkownika Usługi, jeśli uważasz, że inny użytkownik Usługi opublikował materiał naruszający jakiekolwiek prawa autorskie lub jeśli uważasz, że inny użytkownik Usługi w inny sposób narusza niniejsze Warunki, skontaktuj się z nami pod adresem Support@menuonline.com.",
        "acceptableUse": "Oświadczasz i gwarantujesz, że korzystanie z Usługi będzie zgodne ze wszystkimi obowiązującymi przepisami prawa i regulacjami. Zgadzasz się, że jesteś odpowiedzialny za ustalenie, czy Usługa będzie zgodna i czy będzie odpowiednia do korzystania z niej w Twojej jurysdykcji. Dla większej pewności zgadzasz się, że będziesz (i zgadzasz się zapewnić, że Twoi pracownicy, agenci i upoważnieni użytkownicy będą) ...",
        "exportLaws": "Nie składamy żadnych oświadczeń, że Witryna lub Usługa są odpowiednie lub dostępne do użytku poza Kanadą, a dostęp do nich z terytoriów, na których ich zawartość jest nielegalna, jest zabroniony. Nie możesz używać ani eksportować lub reeksportować Treści Menuonline ani innych materiałów w tej Witrynie ani żadnej kopii lub adaptacji z naruszeniem jakichkolwiek obowiązujących praw lub przepisów, w tym bez ograniczeń kanadyjskich i amerykańskich praw i przepisów eksportowych.",
        "disclaimers": "Usługa i ta Witryna są udostępniane „tak jak są”, bez jakichkolwiek oświadczeń, gwarancji ani warunków jakiegokolwiek rodzaju, a Menuonline wyraźnie zrzeka się, w najszerszym zakresie dozwolonym przez obowiązujące prawo, wszelkich oświadczeń, gwarancji i warunków, wyraźnych lub dorozumianych, z mocy prawa lub w inny sposób, w tym, bez ograniczeń, wszelkich dorozumianych gwarancji i warunków przydatności handlowej lub przydatności do określonego celu lub zastosowania lub nienaruszania praw osób trzecich...",
        "limitLiability": "Łączna odpowiedzialność Menuonline na mocy niniejszej Umowy, niezależnie od tego, czy wynika z umowy czy z czynu niedozwolonego (w tym zaniedbania), z tytułu naruszenia gwarancji, odpowiedzialności ścisłej lub z jakiejkolwiek innej teorii odpowiedzialności, będzie ograniczona do bezpośrednich szkód w kwocie nieprzekraczającej całkowitych opłat uiszczonych na rzecz Menuonline na mocy niniejszych Warunków w ciągu dwunastu (12) miesięcy bezpośrednio poprzedzających miesiąc, w którym nastąpiło ostatnie zdarzenie powodujące odpowiedzialność.",
        "liabilityExclusions": "Żadna z firm Menuonline, jej podmiotów stowarzyszonych ani ich dyrektorów, kierowników, pracowników, agentów lub innych przedstawicieli (zwanych łącznie „Przedstawicielami Menuonline”) ani operatorów jakichkolwiek powiązanych sieci nie ponosi żadnej odpowiedzialności w związku z tą Stroną lub Usługą za: (i) jakiekolwiek szkody pośrednie, wynikowe, przypadkowe, przykładowe, karne lub szczególne; lub (ii) za jakiekolwiek szkody, bezpośrednie, pośrednie, wynikowe, przypadkowe, przykładowe, karne lub szczególne, określane jako utracone dochody, utracone oszczędności lub dochody lub utracone zyski...",
        "indemnity": "Użytkownik zobowiązuje się chronić i zabezpieczyć firmę Menuonline i\/lub przedstawicieli Menuonline („strony chronione Menuonline”) przed wszelkimi roszczeniami wniesionymi przez osoby trzecie oraz przed wszelkimi grzywnami lub karami nałożonymi na nas, wynikającymi z korzystania przez Użytkownika z informacji uzyskanych za pośrednictwem tej Witryny lub z korzystania z Usługi, a także z jakiegokolwiek naruszenia niniejszych Warunków.",
        "ipInfringement": "Menuonline będzie bronić, zabezpieczać i chronić Cię przed wszelkimi roszczeniami, żądaniami, działaniami, pozwami lub postępowaniami zgłoszonymi lub wszczętymi przeciwko Tobie przez osoby trzecie, twierdzące, że Twój dostęp do Usługi lub korzystanie z niej zgodnie z niniejszą Umową narusza prawa własności intelektualnej osoby trzeciej na mocy prawa kanadyjskiego („Roszczenie o naruszenie praw własności intelektualnej”)...",
        "equitableRelief": "Jeśli naruszysz niniejsze Warunki, możemy ubiegać się o nakaz sądowy (co oznacza, że możemy poprosić o nakaz sądowy, aby Cię powstrzymać) lub inne środki słusznościowe. Zgadzasz się, że będzie to miało miejsce dodatkowo i bez uszczerbku dla wszelkich innych praw, które możemy mieć na mocy prawa lub w ramach kapitału własnego.",
        "linksToOtherSites": "Jeśli udostępniamy jakiekolwiek linki do stron internetowych osób trzecich, są one udostępniane wyłącznie dla wygody. Jeśli skorzystasz z tych linków, opuścisz naszą witrynę. Nie kontrolujemy tych stron osób trzecich ani ich nie popieramy. Zgadzasz się, że nie ponosimy odpowiedzialności za żadną z tych stron osób trzecich ani ich zawartość...",
        "thirdPartyRequests": "Jeśli musimy dostarczyć informacji w odpowiedzi na żądanie prawne lub rządowe dotyczące Twojego konta lub korzystania z Usługi, wówczas zgadzasz się, że możemy obciążyć Cię naszymi kosztami. Opłaty te mogą obejmować nasze honoraria prawne i koszty czasu pracy naszych pracowników poświęconego na przygotowanie odpowiedzi na żądanie.",
        "assignment": "Niniejsza Umowa oraz wszelkie prawa i licencje udzielone na jej podstawie nie mogą zostać przez Ciebie przeniesione ani cedowane. Mogą jednak zostać przeniesione przez Menuonline bez ograniczeń, po uprzednim powiadomieniu Cię o tym.",
        "governingLaw": "Niniejsze warunki będą regulowane i interpretowane zgodnie ze wszystkimi obowiązującymi przepisami federalnymi i prawami Ontario, bez względu na przepisy dotyczące kolizji praw. Zgadzasz się poddać wyłącznej jurysdykcji sądów Ontario w celu rozstrzygnięcia wszelkich sporów dotyczących niniejszych Warunków.",
        "severability": "Jeżeli z jakiegokolwiek powodu sąd właściwej jurysdykcji uzna którekolwiek postanowienie niniejszych Warunków lub jego część za niezgodne z prawem lub z jakiegokolwiek powodu niewykonalne, wówczas postanowienie to zostanie wyegzekwowane w maksymalnym zakresie dozwolonym w celu oddania w życie intencji niniejszych Warunków, a pozostała część niniejszych Warunków pozostanie w pełnej mocy i będzie wykonalna.",
        "waiver": "Wszelkie zrzeczenie się lub zgoda na odstępstwo od wymogów niniejszych Warunków będzie skuteczna tylko wtedy, gdy zostanie sporządzona na piśmie i podpisana przez nas, a także tylko w konkretnym przypadku i w konkretnym celu, dla którego została udzielona. Nasze niewykonanie lub opóźnienie w wykonaniu jakiegokolwiek prawa na mocy niniejszych Warunków nie będzie działać jako zrzeczenie się naszych praw.",
        "furtherAssurances": "Każda ze stron zgadza się podpisać i dostarczyć wszelkie dokumenty oraz podjąć wszelkie działania (lub spowodować podjęcie takich działań) niezbędne do wypełnienia swoich zobowiązań wynikających z niniejszych Warunków.",
        "notices": "Wszystkie powiadomienia do Menuonline muszą być sporządzone w formie pisemnej i wysłane do nas pocztą elektroniczną. Powiadomienia do nas muszą być wysyłane do wiadomości naszych przedstawicieli obsługi klienta na adres support@menuonline.com. Powiadomienia do Ciebie mogą być wysyłane na adres lub adres e-mail podany przez Ciebie podczas rejestracji. Ponadto możemy nadawać powiadomienia lub wiadomości za pośrednictwem tej Witryny, aby poinformować Cię o zmianach w tej Witrynie lub innych ważnych sprawach, a te transmisje będą stanowić powiadomienie dla Ciebie w momencie ich opublikowania.",
        "entireAgreement": "Niniejsze Warunki i nasza Deklaracja Prywatności stanowią całość umowy między Tobą a nami w odniesieniu do korzystania przez Ciebie ze Strony i Usługi oraz zastępują wszelkie wcześniejsze umowy, oświadczenia i porozumienia między Menuonline a Tobą w odniesieniu do tego przedmiotu. W przypadku jakiegokolwiek konfliktu między Deklaracją Prywatności a niniejszymi Warunkami, niniejsze Warunki i postanowienia będą miały pierwszeństwo.",
        "language": "Strony wyraźnie zażądały i zażądały, aby niniejsze Warunki, nasza Polityka prywatności i wszelkie powiązane dokumenty zostały sporządzone w języku angielskim. Les parties conviennent et exigent expressément que ce Contrat et tous les documents qui s'y rapportent soient redigés en anglais. Zgadzasz się, że wersja niniejszych Warunków i Polityki prywatności w języku angielskim będzie miała pierwszeństwo w przypadku jakiegokolwiek konfliktu z wersją przetłumaczoną.",
        "survival": "Wszystkie postanowienia niniejszych Warunków, które ze względu na swój charakter lub znaczenie mają zachować ważność po wygaśnięciu lub rozwiązaniu niniejszych Warunków, zachowają ważność, w tym, bez ograniczeń, Sekcje 17-19."
    },
    "collect_additional_info": "Czy chcesz zebrać dodatkowe informacje?",
    "additionalInfoChoice": "Wybierz wszelkie dodatkowe informacje, które chcesz zebrać.",
    "additional_info_yes": "Tak",
    "additional_info_noSkip": "Nie, pomiń",
    "additional_info_label": "Informacje dodatkowe",
    "confirm_business_location": "Najpierw potwierdźmy lokalizację Twojej firmy",
    "custom_get_more_reviews_form": "Niestandardowy formularz „Uzyskaj więcej opinii”",
    "set_up_custom_form": "Skonfiguruj swój niestandardowy formularz „Uzyskaj więcej opinii” według grupy",
    "reputation_name_label": "Uzyskaj więcej opinii Nazwa",
    "reputation_name_placeholder": "Wprowadź nazwę reputacji",
    "group_label": "Grupa",
    "group_desc_title": "Tytuł opisu grupy",
    "group_desc": "Opis grupy",
    "select_group_placeholder": "Wybierz grupę",
    "required_group_name": "Nazwa grupy jest wymagana",
    "before_asking_review": "Zanim poprosimy klientów o opinię, zadajmy im od 1 do 4 pytań.",
    "feel_free_edit": "Możesz edytować według potrzeb.",
    "incentive_settings": "Ustawienia zachęt",
    "incentivize_customer": "Czy chciałbyś zachęcić klienta do wystawienia recenzji?",
    "choose_incentive": "Wybierz zachętę, którą chcesz polecić.",
    "scan_qr_feedback": "Zeskanuj kod QR i podziel się z nami swoją cenną opinią na temat swoich doświadczeń.",
    "qr_code": "Kod QR",
    "scan_me": "SKANUJ MNIE!",
    "or_title": "LUB",
    "send_otp_title": "Wyślij OTP",
    "sign_in_title": "Zalogować się",
    "email_addresss_title": "Adres e-mail",
    "reset_password_title": "Zresetuj hasło",
    "remember_password_title": "Zapomniałeś hasło?",
    "digit_code_title": "Proszę wpisać 4-cyfrowy kod, który został wysłany ",
    "not_received_title": "Nie otrzymałem kodu weryfikacyjnego!",
    "resend_title": "Proszę o ponowne wysłanie",
    "sign_up_form": "Utwórz swoje konto",
    "sign_up_sub_title": "Zaloguj się teraz, aby uzyskać dostęp do najnowszych informacji",
    "already_registered_title": "Masz już konto Menuonline?",
    "reserved_title": "Prawa autorskie ©2023 Menuonline. Wszelkie prawa zastrzeżone.",
    "terms_title": "Warunki korzystania z usługi",
    "policy_title": "Polityka prywatności",
    "sign_up_title": "Zapisać się",
    "terms_conditions_title": "Warunki korzystania z serwisu",
    "business_type_title": "Rodzaj działalności",
    "business_type_sub_title": "Wybierz jeden z typów działalności, który lepiej opisuje Twoją firmę!",
    "salon_title": "Salon",
    "restaurant_title": "Restauracja",
    "pizza_store_title": "Sklep z pizzą",
    "food_truck_title": "Food Truck",
    "check_domain_title": "Sprawdź nazwę domeny",
    "start_exploring_title": "Zacznij eksplorować",
    "sign_in_form": "Zaloguj się na swoje konto",
    "sign_in_sub_title": "Zaloguj się teraz, aby uzyskać dostęp do najnowszych informacji",
    "remember_title": "Pamiętaj mnie",
    "otp_title": "OTP",
    "password_title": "Hasło",
    "forgot_title": "Zapomniałeś hasła?",
    "not_registered_title": "Nie masz konta?",
    "create_account_title": "Zarejestruj się teraz",
    "create_form_title": "Utwórz formularz",
    "first_name_title": "Imię",
    "last_name_title": "Nazwisko",
    "email_title": "E-mail",
    "mobile_number_title": "Numer telefonu komórkowego",
    "birthday_title": "Urodziny",
    "gender_title": "Płeć",
    "address_title": "Adres",
    "short_answer_title": "Krótka odpowiedź",
    "long_answer_title": "Długa odpowiedź",
    "single_answer_title": "Pojedyncza odpowiedź",
    "single_checkbox_title": "Pojedyncze pole wyboru",
    "multiple_choice_title": "Wielokrotny wybór",
    "drop_down_title": "Lista rozwijana",
    "yes_no_title": "Tak czy nie",
    "description_text_title": "Tekst opisu",
    "file_upload": "Przesyłanie pliku",
    "reset_password_form": "Zresetuj swoje hasło",
    "reset_password_sub_title": "Szybko zresetuj hasło i uzyskaj dostęp do swojego konta.",
    "required_full_name_title": "Pełne imię i nazwisko jest wymagane",
    "required_first_name_title": "Imię jest wymagane",
    "valid_first_name_title": "Wprowadź prawidłowe imię",
    "valid_full_name_title": "Wprowadź prawidłowe imię i nazwisko",
    "required_block_time": "Czas blokowania jest wymagany",
    "required_last_name_title": "Nazwisko jest wymagane",
    "valid_last_name_title": "Wprowadź prawidłowe nazwisko",
    "valid_mobile_number_title": "Wprowadź prawidłowy numer telefonu komórkowego",
    "required_mobile_number_title": "Numer telefonu komórkowego jest wymagany",
    "required_already_mobile_number_title": "Numer telefonu komórkowego jest już wymagany",
    "valid_otp_title": "Wprowadź prawidłowy kod jednorazowy",
    "valid_email_title": "Wprowadź prawidłowy adres e-mail",
    "required_email_title": "Adres e-mail jest wymagany",
    "required_description_title": "Opis jest wymagany",
    "required_description_500_characters": "Proszę wpisać co najmniej 500 znaków",
    "required_description_600_characters": "Proszę wpisać maksymalnie 600 znaków",
    "domain_title": "Nazwa domeny",
    "required_password_title": "Hasło jest wymagane",
    "required_password_regex_title": "Musi zawierać co najmniej 8 znaków, co najmniej jedną wielką literę, jedną małą literę, jedną cyfrę i jeden znak specjalny",
    "required_terms_title": "Proszę zaakceptować regulamin",
    "required_note_title": "Uwaga jest wymagana",
    "required_birthday_title": "Urodziny są wymagane",
    "no_access_title": "Nie masz teraz dostępu. Skontaktuj się z administratorem",
    "menu_online_open_txt": "Otwarte",
    "loyalty_program_description": "😊 Znajdź idealny program lojalnościowy i dostosuj go do swojej marki!",
    "lp_list_title": "Lista kart lojalnościowych",
    "lp_n_title": "Nazwa karty perforowanej lojalnościowej",
    "enter_details_title": "Wprowadź szczegóły",
    "stamp_allowed_title": "Znaczek dozwolony",
    "stamp_items_title": "Elementy znaczków",
    "lpn_req_title": "Wymagane jest podanie nazwy karty lojalnościowej",
    "valid_lpn_title": "Wprowadź prawidłową nazwę karty perforowanej lojalnościowej",
    "brand_color_error": "Kolor marki musi mieć dokładnie 7 znaków",
    "select_dark_brand_color_title": "Proszę wybrać prawidłowy kolor marki",
    "your_brand_color_title": "Kolor Twojej marki",
    "upload_logo_title": "Prześlij logo",
    "locations_title": "Lokalizacje",
    "add_lp_title": "Utwórz program",
    "location_error": "Proszę wybrać lokalizacje",
    "pvs_title": "Ile znaczków może zdobyć klient za jedną wizytę?",
    "max_apd_title": "Jaka jest maksymalna liczba znaczków, jaką klient może zdobyć w ciągu jednego dnia?",
    "logo_error": "Logo jest wymagane",
    "expiry_title": "Ustaw termin ważności nagród",
    "add_more_rewards_title": "+ Dodaj więcej nagród",
    "prev_step_1_title": "Pokaż kod QR, aby zdobyć punkty i cieszyć się nagrodami.",
    "delete_lp_title": "Ta karta perforowana lojalnościowa zostanie trwale usunięta.",
    "delete_user_record_title": "Ten rekord użytkownika zostanie trwale usunięty.",
    "redeem_title": "Odkupić",
    "lp_button_title": "Dołącz do Loyalty Punch Card",
    "lp_download_button_title": "Pobierz kartę",
    "step_0_title": "Dołącz już teraz i zdobądź pyszne nagrody dla wiernych miłośników jedzenia, takich jak Ty.",
    "step_1_title": "Dołączyłeś",
    "congrats_step_title": "Teraz możesz otrzymać darmową pizzę",
    "reward_title": "Zdobywaj nagrody za każdą wizytę",
    "visit_title": "Odwiedź i odblokuj",
    "rewards_title": "Nagrody",
    "dummy_address": "3730 Frankfort Ave ,Louisville Kentucky ,Stany Zjednoczone",
    "loyalty_qr_default_text": "Zeskanuj kod QR, dołącz do naszej karty lojalnościowej i ciesz się nagrodami",
    "lp_earn_stamp_txt": "Klienci zdobywają pieczątki za każdą wizytę.",
    "lp_get_rewards_txt": "Zdobądź nagrody za każdą wizytę",
    "lp_1_time_visit_txt": "1 wizyta, którą otrzymasz",
    "lp_uppercase_stamp_text": "Stempel",
    "lp_lowercase_stamp_text": "stempel",
    "lp_txt": "Lojalność",
    "lp_per_visit_stamp_validation_msg": "Liczba znaczków na wizytę musi być mniejsza niż maksymalna liczba znaczków dozwolona na dzień",
    "lp_per_max_stamp_validation_msg": "Maksymalna liczba znaczków na dzień musi być większa niż liczba znaczków na wizytę",
    "lp_valid_value_msg": "Wprowadź prawidłową wartość",
    "lp_required_field_msg": "To pole jest wymagane",
    "lp_stamp_required_msg": "Wymagany jest znaczek",
    "lp_valid_stamp_msg": "Wprowadź prawidłową wartość znaczka",
    "lp_reward_name_required_msg": "Nazwa nagrody jest wymagana",
    "lp_unique_stamp_msg": "Wartość znaczka powinna być unikatowa",
    "lp_benefits": "Program lojalnościowy zwiększa liczbę ponownych wizyt i przychody, a jednocześnie dostarcza klientom informacji na temat spersonalizowanego marketingu",
    "lp_how_customer_txt": "Oto jak Twoi klienci",
    "lp_punch_card_txt": "karta perforowana lojalnościowa",
    "lp_look_txt": "popatrzę! 😍",
    "lp_desc": "Klienci będą zdobywać pieczątki za każdą wizytę, a po uzbieraniu wystarczającej liczby pieczątek będą mogli wymienić je na specjalne nagrody.",
    "lp_edit": "Redagować",
    "lp_looks_good_txt": "Wygląda dobrze",
    "lp_customer_view_instruction": "To zobaczy Twój klient. Prześlij swoje logo i ustaw motyw kolorystyczny",
    "lp_customer_earn_stamps": "Pozwól klientom zdobywać pieczątki za każdą wizytę i zarządzać swoimi nagrodami",
    "lp_stamp_rewards_setup": "Ustaw nagrody za zdobywanie znaczków, pozwól klientom otrzymywać nagrody za każdy znaczek",
    "lp_loyalty_program_setup": "Podaj nazwę swojego programu lojalnościowego i ustaw swoją lokalizację, aby mieć ten program",
    "lp_prev_step1": "Pokaż kod QR, aby zdobyć punkty i cieszyć się nagrodami.",
    "lp_visit_1_time_txt": "Odwiedź 1 raz i otrzymaj",
    "lp_get_free_txt": "Otrzymaj za darmo",
    "lp_visit_allow_txt": "Odwiedź i odblokuj",
    "lp_rewards": "Nagrody",
    "repsT": "Uzyskaj więcej recenzji",
    "offSup": "Artykuły biurowe",
    "repsNxtV": "Podczas następnej wizyty",
    "addRepT": "Dodaj Uzyskaj Więcej Opinii",
    "repEntrCpnTxt": "Wprowadź tekst kuponu",
    "repEntrCpnTxtBtn": "Wprowadź tekst przycisku kuponu",
    "repMngNxtV": "podczas następnej wizyty?",
    "repMngWldL": "Czy chciałbyś dostać?",
    "discount": "Rabat",
    "repDisTyp": "Rodzaj zniżki",
    "repNameT": "Nazwij swoją reputację",
    "crtRepT": "Stwórz reputację",
    "feedBackT": "Informacja zwrotna",
    "confLocT": "Potwierdź lokalizację",
    "businLocT": "Lokalizacja firmy",
    "addInfoT": "Informacje dodatkowe",
    "repNReqT": "Uzyskaj więcej opinii nazwa jest wymagana",
    "validRepNT": "Wprowadź prawidłową nazwę reputacji",
    "groupT": "Grupa",
    "groupLDescTitleT": "Automatyczne przesyłanie kontaktów do grupy",
    "groupLDescT": "Wszyscy klienci, którzy wypełnią formularz, zostaną dodani do wybranej grupy.",
    "que2": "Czy jedzenie było dobre?",
    "que3": "Czy nasza obsługa była przyjazna?",
    "que4": "Czy nasza obsługa była szybka?",
    "addAdditionalT": "Czy chcesz dodać jakieś dodatkowe informacje?",
    "phNoMandT": "Podanie numeru telefonu jest obowiązkowe!",
    "qrGenT": "Wygenerowano kod QR",
    "repFDT": "Twoja opinia pomoże naszemu zespołowi lepiej Ci służyć!",
    "letdiT": "Zróbmy to",
    "tak15secT": "Zajmuje 15 sekund",
    "defQue": "Czy chciałbyś od czasu do czasu otrzymywać świetne oferty i promocje?",
    "defNQue": "Czy chcesz, żeby ktoś Ci odpisał?",
    "rateOnGoogleDesc": "Czy mógłbyś ocenić nas w Google?",
    "mayBeNT": "Może następnym razem",
    "yesSureT": "Tak, pewnie",
    "valid_name": "Wprowadź prawidłową nazwę",
    "nameReq": "Imię jest wymagane",
    "backToMainT": "Powrót do strony głównej",
    "attachCopT": "Czy chcesz zachęcić swojego klienta?",
    "thankYouT": "Dziękuję",
    "lftyT": "Czekamy z niecierpliwością na Państwa kolejną wizytę!",
    "repPreT": "Podgląd reputacji",
    "offer_exclusive_discounts": "Zaoferuj ekskluzywne zniżki i promocje, aby przyciągnąć klientów.",
    "couponT": "Kupon",
    "locStepDesc": "Potwierdź lokalizację swojej firmy",
    "createRepStepDesc": "Utwórz niestandardowy formularz reputacji według grup",
    "feedbackStepDesc": "Zestaw pytań dotyczących opinii klientów",
    "addInfoStepDesc": "Pola klientów i ustawienia zachęt",
    "FedbkkWDT": "Informacje zwrotne z danymi",
    "repAnlytT": "Analityka reputacji",
    "todayT": "Dzisiaj",
    "rep_last_7_days_t": "Ostatnie 7 dni",
    "last30DaysT": "Ostatnie 30 dni",
    "lastMT": "Ostatni miesiąc",
    "custRangeT": "Zakres niestandardowy",
    "queWiseAnltT": "Analityka pytań",
    "atL1QueErrT": "Proszę wybrać co najmniej jedno pytanie",
    "deleteRepTitle": "Ta reputacja zostanie trwale usunięta.",
    "incvCustT": "Tak, zachęć mojego klienta",
    "collAddInfoT": "Tak, chcę zebrać dodatkowe informacje",
    "totRewT": "Całkowita recenzja",
    "totPosRewT": "Całkowicie pozytywna recenzja",
    "negFeedT": "Negatywne opinie",
    "posFeedT": "Pozytywne opinie",
    "fineT": "Mały druk",
    "enterFineT": "Wprowadź tekst drobnym drukiem",
    "setThemeT": "Ustaw kolor motywu",
    "que1T": "Pytanie 1",
    "que2T": "Pytanie 2",
    "que3T": "Pytanie 3",
    "que4T": "Pytanie 4",
    "entMailT": "Wpisz swój adres e-mail",
    "reputationQRDefTxt": "Zeskanuj kod QR i podziel się z nami swoją cenną opinią na temat swoich doświadczeń.",
    "selUser": "Wybierz użytkownika",
    "userT": "Użytkownik",
    "subUReq": "Proszę wybrać użytkowników",
    "updateLoc": "Aktualizuj lokalizację",
    "leadGenT": "Generowanie leadów",
    "displayT": "Wyświetlacz",
    "ratingT": "Ocena",
    "rep_dashboard_negative_feedback": "Negatywne opinie 0%",
    "rep_dashboard_position_feedback": "Pozytywne opinie 0%",
    "rep_dashboard_total_Feedback_txt": "Całkowita liczba opinii",
    "rep_dashboard_anony_Feedback": "Anonimowa opinia",
    "rep_dashboard_Feeedback_Data_txt": "Informacje zwrotne z danymi",
    "rep_dashboard_review_txt": "Uzyskaj więcej recenzji",
    "rep_dashboard_total_Review_txt": "Całkowita recenzja",
    "rep_dashboard_total_positive_Review_txt": "Całkowicie pozytywna recenzja",
    "rep_dashboard_negative_feedback_txt": "Negatywne opinie",
    "rep_dashboard_connection_txt": "Połączenie",
    "rep_dashboard_question_wise_analytics_txt": "Analityka pytań",
    "rep_dashboard_date_label": "Wybierz datę",
    "rep_dashboard_custom_range_txt": "Zakres niestandardowy",
    "rep_tlt_min": "ROZPUSTNIK",
    "emojis": "Emoji",
    "media_text": "Dodaj media",
    "custom_field_text": "Wstaw pola niestandardowe",
    "shortlink_text": "Dodaj krótki link",
    "use_template_text": "Użyj szablonu",
    "banner_must_have": "Musi mieć",
    "res_seamless": "Bezszwowy",
    "res_table_reservation": "Rezerwacja stolika",
    "res_system": "System",
    "res_text": "Rezerwacje",
    "md_easily": "Łatwo",
    "md_manage_customers": "Zarządzaj klientami",
    "md_visits_interactions": "Wizyty i interakcje",
    "md_desc": "Ułatw swoim klientom jedzenie dzięki rezerwacjom stolików w czasie rzeczywistym. Zarządzaj rezerwacjami bez wysiłku, skróć czas oczekiwania i zapewnij płynne doświadczenie zarówno personelowi, jak i gościom. Utrzymuj pełną rezerwację w swojej restauracji, jednocześnie zapewniając obsługę i zadowolenie na najwyższym poziomie.",
    "engage_convert": "Angażuj i konwertuj SMS-y",
    "coupon_campaign": "i Kampania Kuponowa",
    "sms_read_rate": "Wiadomości SMS są odczytywane w 98% przypadków",
    "real_time_promo": "Dostarczaj promocje w czasie rzeczywistym, aby móc podjąć natychmiastowe działania",
    "affordable_reach": "Przystępny cenowo sposób na dotarcie do klientów z solidnym ROI",
    "stay_in_touch": "Bądź w stałym kontakcie ze swoim klientem",
    "bc_engage_customers": "Zaangażuj klientów, konwertuj SMS-y",
    "bc_coupon_campaign": "i Kampania Kuponowa",
    "bc_sms_read_rate": "Wiadomości SMS są odczytywane w 98% przypadków",
    "bc_real_time_promo": "Dostarczaj promocje w czasie rzeczywistym, aby móc podjąć natychmiastowe działania",
    "bc_affordable_reach": "Przystępny cenowo sposób na dotarcie do klientów z solidnym ROI",
    "bc_stay_in_touch": "Bądź w stałym kontakcie ze swoim klientem",
    "ar_automate_engagement": "Zautomatyzuj zaangażowanie swojego klienta",
    "ar_with": "z",
    "ar_with_auto_responders": "Automatyczne odpowiedzi",
    "ar_personalized_messages": "Automatycznie wysyłaj spersonalizowane wiadomości na urodziny, witaj nowych klientów i nawiązuj kontakt z poprzednimi klientami. Oszczędzaj czas, jednocześnie zachowując znaczenie i terminowość każdej interakcji.",
    "sq_custom_forms_tools": "Niestandardowe formularze i narzędzia – Uprość",
    "sq_custom_forms_ci": "Interakcje z klientami",
    "sq_smart_qr_desc": "Twórz niestandardowe formularze, zbieraj podpisy elektroniczne i wykorzystuj inteligentne kody QR, aby usprawnić procesy biznesowe. Dostosuj każde narzędzie do potrzeb klientów i popraw komfort użytkowania.",
    "gnc_get_new_cus": "Zdobądź nowych klientów",
    "gnc_get_customers": "z mediów społecznościowych — szybko i łatwo!",
    "gnc_social_media_power": "Wykorzystaj moc platform społecznościowych, aby przyciągnąć nowych klientów i zwiększyć sprzedaż bezpośrednio w swojej firmie. Przekieruj zamówienia z dostawą osób trzecich bezpośrednio do swojego sklepu lub platformy zamówień online, aby uzyskać maksymalną kontrolę i przychody.",
    "wifi_connect_guests": "Połącz gości z",
    "wifi_smart": "Inteligentne Wi-Fi",
    "wifi_connect_grow": "i rozwijaj swoje spostrzeżenia",
    "wifi_smart_wifi_desc": "Udostępnij swoim klientom bezproblemowe połączenie Wi-Fi za pośrednictwem firmowego ekranu powitalnego, jednocześnie zbierając cenne dane o klientach, aby zwiększyć skuteczność działań marketingowych i zaangażowanie.",
    "wifi_create_splash_page": "Utwórz stronę powitalną dla gości Wifi",
    "instant_menu_create": "Utwórz swój",
    "instant_menu_text": "Menu natychmiastowe",
    "instant_menu_wm": "w ciągu kilku minut",
    "instant_menu_desc": "Poprawia jakość obsługi klienta, umożliwiając aktualizacje w czasie rzeczywistym, łatwą personalizację i lepsze zaangażowanie, dzięki czemu łatwiej jest prezentować oferty i dbać o aktualność treści.",
    "instant_menu_create_digital_menu": "Utwórz cyfrowe menu",
    "loyalty_program_rewards": "Zdobądź nagrody",
    "loyalty_program_on_visits": "przy każdej wizycie",
    "loyalty_program_create": "Utwórz program lojalnościowy",
    "reputation_create": "Stwórz reputację",
    "reputation_boost": "Wzmocnij swój",
    "reputation_text": "Reputacja",
    "reputation_reviews": "z większą ilością recenzji!",
    "reputation_desc": "Zbieraj cenne opinie od swoich klientów, aby ulepszyć swoją obsługę i reputację. Podziel się swoimi doświadczeniami i pomóż nam się rozwijać — Twoja opinia ma znaczenie!",
    "dashboard": "Panel",
    "blast_campaign": "Kampania wybuchowa",
    "blast_campaigns": "Kampanie wybuchowe",
    "blast_campaign_sub_title": "Uruchom kampanie masowego przekazu, aby dotrzeć do wszystkich klientów za pomocą kilku kliknięć",
    "auto_responders": "Automatyczne odpowiedzi",
    "auto_responder": "Autoresponder",
    "auto_responder_sub_title": "Łatwe w obsłudze promocje oparte na wydarzeniach dla Twoich klientów",
    "smart_qr_form": "Inteligentny QR \/ Formularz",
    "get_new_customer": "Zdobądź nowego klienta",
    "marketing": "Marketing",
    "clients": "Klienci",
    "group_data": "Dane grup",
    "clients_data": "Dane klientów",
    "account": "Konto",
    "user_acc_info": "Informacje o koncie użytkownika",
    "current_plan": "Aktualny plan",
    "payments": "Płatności",
    "location": "Lokalizacja",
    "location_list": "Lista lokalizacji",
    "users": "Użytkownicy",
    "users_sub_title": "Lista wszystkich użytkowników i ich dane",
    "online_profile": "Profil online",
    "change_password": "Zmień hasło",
    "menu": "Menu",
    "reseller_dashboard": "Panel sprzedawcy",
    "companies": "Firmy",
    "plans_bought": "Plany kupione",
    "agreement_t_c": "Warunki umowy",
    "wifi_dashboard": "Panel Wi-Fi",
    "hot_spot": "Punkt aktywny",
    "splash_pages": "Strony powitalne",
    "get_more_reviews": "Uzyskaj więcej recenzji",
    "office_supplies": "Artykuły biurowe",
    "reservation": "Rezerwacja",
    "floor_table": "Podłoga i stół",
    "guest_smart_wifi": "Gościnny Smart WiFi",
    "digital_signage": "Oznakowanie cyfrowe",
    "schedule_down": "Zaplanuj",
    "business_settings": "Ustawienia biznesowe",
    "sub_user_login": "Logowanie podużytkownika",
    "help_center": "Centrum pomocy",
    "switch_to_hub": "Przełącz na Hub",
    "meeting_records": "Zapisy ze spotkań",
    "logout": "Wyloguj",
    "no_access_text": "Nie masz obecnie dostępu. Skontaktuj się z administratorem.",
    "menu_item": "ELEMENT MENU",
    "menu_capital": "MENU",
    "good_day": "Dzień dobry",
    "no_permission_text": "Nie masz uprawnień do wykonania tej czynności!!",
    "enter_valid_pin": "Wprowadź prawidłowy kod PIN",
    "add_reservation": "Dodaj rezerwację",
    "edit_reservation": "Edytuj rezerwację",
    "per_req": "Liczba osób jest wymagana",
    "req_block_time": "Czas blokowania jest wymagany",
    "pending_status": "Aż do",
    "booked_status": "Zarezerwowano",
    "completed_status": "Zakończony",
    "cancelled_status": "Odwołany",
    "reservation_slot": "Miejsce rezerwacji",
    "block_time": "Czas bloku",
    "slot_time": "Czas rezerwacji:  ",
    "template_name_req": "Nazwa szablonu jest wymagana",
    "template_type_req": "Typ szablonu jest wymagany",
    "sms_template_req": "Wymagany jest szablon SMS",
    "group_req": "Grupa jest wymagana",
    "valid_date_time": "Wprowadź prawidłową datę i godzinę",
    "valid_group_name": "Wprowadź prawidłową nazwę grupy",
    "reservation_mark_completed": "Ta rezerwacja zostanie oznaczona jako zrealizowana.",
    "customer_name": "Nazwa klienta",
    "date_time": "Data i godzina",
    "table_no": "Tabela nr",
    "floor": "Podłoga",
    "customer_name_placeholder": "nazwa_klienta",
    "date_time_placeholder": "data_godzina",
    "table_no_placeholder": "nr_tabeli",
    "floor_placeholder": "podłoga",
    "select_floor": "Wybierz piętro",
    "select_table": "Wybierz tabelę",
    "customer_name_macro": "%% nazwa_klienta %%",
    "date_time_macro": "%% data_godzina %%",
    "table_no_macro": "%% nr_tabeli %%",
    "floor_macro": "%% podłoga %%",
    "template_name": "Nazwa szablonu",
    "login_with_4_digit_pin": "Zaloguj się za pomocą 4-cyfrowego kodu PIN",
    "login_now_to_access_latest_insights": "Zaloguj się teraz, aby uzyskać dostęp do najnowszych insigntów",
    "mkt_BS_Title": "Wybuch SMS-ów",
    "step_1": "Dostosuj wiadomość",
    "step_2": "Wybierz odbiorców",
    "step_3": "Zaplanuj wiadomość",
    "step_4": "Wyślij wiadomość",
    "step_5": "Zarządzaj wiadomością",
    "bs_tab_title": "Podsumowanie kampanii Blast",
    "default_message": "Hej %% firstname %%, Specjalna zniżka na brunch! Zjedz z nami dziś i otrzymaj ekskluzywną zniżkę 10% na rachunek!",
    "bs_Title": "Wybierz typ kampanii",
    "sms_title": "SMS-y\/MMS-y",
    "reach_out_to_customer": "Skontaktuj się z klientami za pomocą wiadomości tekstowych i multimedialnych.",
    "wp_text": "WhatsApp",
    "connect_with_wp": "Połącz się ze swoimi klientami za pomocą WhatsApp 💬",
    "send_discount_offers": "Wyślij oferty rabatowe przez SMS 📲",
    "send_coupons_via_sms": "Wysyłaj kupony za pośrednictwem SMS-ów do swoich klientów 🎟️",
    "env_Cap": "Oferuj ekskluzywne rabaty i promocje, aby przyciągnąć klientów",
    "cmt_Caption": "Skontaktuj się z klientami za pomocą wiadomości tekstowych i multimedialnych",
    "wp_caption": "Nawiąż kontakt z klientami za pomocą WhatsApp, aby zapewnić sobie bezproblemową i bezpośrednią komunikację",
    "msg_Prev_Title": "Podgląd wiadomości",
    "cou_Prev_Title": "Podgląd kuponu",
    "blast_SMS": "Kampania SMS\/MMS",
    "bs_P_Msg": "W kolejce",
    "bs_S_Msg_C": "Przegrany",
    "bs_F_Msg_Count": "Dostarczony",
    "previous": "Poprzedni",
    "next_ST": "Następny",
    "next_S": "Następny krok",
    "req_Temp_Name_Title": "Nazwa szablonu jest wymagana",
    "req_Template_Tx_Title": "Tekst szablonu jest wymagany",
    "add_Temp_Title": "Dodaj szablon",
    "link_Desc": "Dodaj wybrany przez siebie link, aby klienci mogli dokonywać u Ciebie rezerwacji za pośrednictwem Facebooka, Google lub Twojej witryny.",
    "audience_Title": "Publiczność",
    "client_G1_Title": "Nowi klienci",
    "client_G2_Title": "Ostatni klienci",
    "client_G3_Title": "Lojalni klienci",
    "client_G4_Title": "Klienci, którzy zrezygnowali",
    "client_G5_Title": "Klienci według daty połączenia",
    "on_Which_Day": "W którym dniu",
    "on_Day": "W dzień",
    "immediate_Desc": "Twoja wiadomość zostanie natychmiast wysłana. Kliknij Dalej, aby rozpocząć.",
    "never_Desc": "Ta kampania będzie trwała wiecznie, dopóki jej ręcznie nie zakończysz.",
    "end_Desc": "Kampania zakończy się w wybranym przez Ciebie dniu.",
    "success_Message": "Kampania zapisana pomyślnie!",
    "send_Success_Message": "Kampania wysłana pomyślnie!",
    "msg_Name_Field_Title": "Tytuł kampanii",
    "valid_campaign_title": "Wprowadź prawidłowy tytuł kampanii",
    "msg_Type_Title": "Typ wiadomości",
    "sms_Desc": "1 kredyt za SMS",
    "mms_Desc": "3 kredyty za MMS",
    "client_GTO_1": "Dzień",
    "client_GTO_2": "Godziny",
    "minutes": "Protokół",
    "client_GTO_4": "Tydzień",
    "time_Option_1": "Dzień",
    "time_Option_2": "Godziny",
    "c_Grp_Day_1": "90 dni",
    "c_Grp_Day_2": "60 dni",
    "c_Grp_Day_3": "30 dni",
    "first": "Pierwszy",
    "second_T": "Drugi",
    "third_T": "Trzeci",
    "fourth_T": "Czwarty",
    "fifth_T": "Piąty",
    "delete_BS_T": "Ta kampania zostanie trwale usunięta.",
    "delete_MBS_T": "Te kampanie zostaną trwale usunięte.",
    "cFT_1": "Telefon",
    "cFT_2": "Imię kontaktowe",
    "cFT_3": "Nazwisko kontaktowe",
    "cFT_4": "E-mail kontaktowy",
    "cFT_5": "Notatka",
    "cFT_6": "Urodziny",
    "cFT_7": "Nazwa firmy",
    "cF_Meta_Tag_1": "%% telefon %%",
    "cF_Meta_Tag_2": "%% imię %%",
    "cF_Meta_Tag_3": "%% nazwisko %%",
    "cF_Meta_Tag_4": "%% e-mail %%",
    "cF_Meta_Tag_5": "%% notatka %%",
    "cF_Meta_Tag_6": "%% urodziny %%",
    "cF_Meta_Tag_7": "%% nazwa_firmy %%",
    "cF_Name_1": "telefon",
    "cF_Name_2": "imię",
    "cF_Name_3": "nazwisko",
    "cF_Name_4": "e-mail",
    "cF_Name_5": "notatka",
    "cF_Name_6": "urodziny",
    "cF_Name_7": "nazwa_firmy",
    "all_clients": "Wszyscy klienci",
    "aud_Type_2": "Grupy klientów",
    "aud_Type_3": "Grupy klientów",
    "aud_Type_T1": "Wszyscy klienci",
    "aud_Type_T2": "Grupy klientów",
    "aud_Type_T3": "Grupy klientów",
    "new_clients": "Nowi klienci",
    "client_G2": "Ostatni klienci",
    "client_G3": "Lojalni klienci",
    "client_G4": "Klienci, którzy zrezygnowali",
    "client_G5": "Klienci według daty połączenia",
    "never_text": "Nigdy",
    "ending_on": "Kończąc się na",
    "send_T2": "Zaplanowany",
    "send_Type_3": "Powtarzający się",
    "daily": "Codziennie",
    "weekly_T": "Tygodnik",
    "monthly_T": "Miesięczny",
    "yearly_T": "Rocznie",
    "each": "Każdy",
    "on_the": "Na",
    "monday": "Poniedziałek",
    "exclusive_offer": "Ekskluzywna oferta dla Ciebie",
    "redeem_now": "Zrealizuj teraz",
    "redeem_with_cashier": "Proszę wymienić u kasjera",
    "lg_Day_2": "Wtorek",
    "lg_Day_3": "Środa",
    "lg_Day_4": "Czwartek",
    "lg_Day_5": "Piątek",
    "lg_Day_6": "Sobota",
    "lg_Day_7": "Niedziela",
    "msg_Pre_T": "Domino",
    "cA_Edit_T1": "Wszystko",
    "cA_Edit_T2": "Dołączony",
    "cA_Edit_T3": "Wyłączony",
    "SMS": "SMS",
    "MMS": "MMS",
    "usd_T": "USD",
    "cad_T": "CHAM",
    "msg": "Wiadomość",
    "which_Day": "Które dni",
    "end_Date_Tx": "Data zakończenia",
    "sDate_Err": "Data rozpoczęcia musi być późniejsza lub równa dacie dzisiejszej",
    "eDate_Err": "Data zakończenia musi być późniejsza niż data rozpoczęcia",
    "start_Date_Req": "Data rozpoczęcia jest wymagana",
    "end_Date_Req": "Data zakończenia jest wymagana",
    "time_req": "Potrzebny jest czas",
    "client_GT1": "Klienci zapisali się w ciągu ostatnich",
    "client_GT2": "Klienci z połączeniem w ostatnim",
    "client_GT3": "Klienci posiadający co najmniej",
    "client_GT4": "z ostatnim",
    "client_GT5": "który nie wrócił w ostatnim",
    "dummy_Phone_No": "+91987-654-3210",
    "test_T": "Test",
    "dummy_Birth_Date": "01-01-2001",
    "image_Req": "Wymagane są media lub adres URL",
    "time_Title": "Czas",
    "start_date": "Data rozpoczęcia",
    "end_date": "Data zakończenia",
    "auto_Send_T": "Automatyczne wysyłanie do klientów",
    "add_Media": "Dodaj media",
    "in_Title": "W",
    "c_Temp_T": "Szablon kampanii",
    "temp_NT": "Nazwa szablonu",
    "type_T": "Typ",
    "select_template_type": "Wybierz typ szablonu",
    "sel_Temp_T": "Wybierz typ szablonu",
    "sms_temp_t": "Szablon SMS",
    "temp_T": "Tekst szablonu",
    "default_Msg": "Hej %% firstname %%, Specjalna zniżka na brunch! Zjedz z nami dziś i otrzymaj ekskluzywną zniżkę 10% na rachunek!",
    "refill_T": "Wkład",
    "avl_Credit": "Dostępne kredyty",
    "req_Credit": "Łączna liczba wymaganych punktów",
    "rem_Credit": "Pozostały kredyt",
    "refill_Credit": "Kredyty do uzupełnienia",
    "clients_Req": "Wybierz co najmniej jednego klienta",
    "subclient_Req": "Wybrani klienci nie są abonentami.",
    "refill_Desc": "Proszę uzupełnić wymagane kredyty",
    "url_Regex": "^((http|https):\/\/)......+$",
    "sd_Invalid": "Data rozpoczęcia jest nieprawidłowa",
    "ed_Invalid": "Data zakończenia jest nieprawidłowa",
    "img_Url_Invalid": "Nieprawidłowy adres URL obrazu",
    "time_Invalid": "Czas jest nieprawidłowy",
    "time_And_Date_Invalid": "Wprowadź prawidłową datę i godzinę",
    "time_Invalid_Bfr": "Wybierz czas co najmniej 5 minut późniejszy od czasu bieżącego",
    "sod_Req": "Wymagane jest wysłanie w dniu",
    "add_Card": "Dodaj nową kartę",
    "sm_D_Rep_Title": "Raport o dostarczeniu wiadomości SMS\/MMS",
    "send_Now_T": "Wyślij teraz",
    "schd_Now_T": "Zaplanuj SMS-a",
    "test_SMS_T": "Wyślij SMS testowy",
    "save_AD_T": "Zapisz jako wersję roboczą",
    "back_TE_T": "Powrót do edycji",
    "reset_tex": "Nastawić",
    "update_tex": "Aktualizacja",
    "dum_msg1": "Aby wyświetlić nazwę klienta, użyj %% customer_name %% w szablonie",
    "dum_msg2": "Aby wyświetlić czas rezerwacji, użyj %% date_time %% w szablonie",
    "dum_msg3": "Aby wyświetlić numer tabeli, użyj %% table_no %% w szablonie",
    "embedded_link": "Wbudowany link",
    "img_title": "Obraz",
    "img_input_text": "Tekst:",
    "img_join_text": " dołączyć do naszej listy",
    "img_copy_text": "Kopia",
    "img_not_found": "Nie znaleziono wygenerowanego obrazu!!",
    "or_text": "LUB",
    "web_signup_image_content": "Wysyłając ten formularz i zapisując się za pomocą wiadomości tekstowej, wyrażasz zgodę na otrzymywanie marketingowych wiadomości tekstowych (takich jak kody promocyjne i przypomnienia). Mogą obowiązywać stawki za wiadomości i dane. Częstotliwość wiadomości jest różna. Możesz zrezygnować z subskrypcji w dowolnym momencie, odpowiadając STOP",
    "img_download_success": "Obraz pobrano pomyślnie",
    "embedded_link_copied": "Osadzona linka została pomyślnie skopiowana",
    "media_url_required": "Wymagane jest podanie nośnika lub adresu URL",
    "invalid_image_url": "Nieprawidłowy adres URL obrazu",
    "invalid_file": "Plik jest nieprawidłowy",
    "image_error_1mb": "Proszę wstawić obraz mniejszy niż 1 MB",
    "image_error_2_5mb": "Proszę wstawić obraz mniejszy niż 2,5 MB",
    "image_error_3mb": "Proszę wstawić obraz mniejszy niż 3MB",
    "change_title": "Zmiana",
    "drop_file_text": "Upuść plik tutaj lub kliknij, aby przesłać",
    "apply_text": "Stosować",
    "search_here": "Szukaj tutaj",
    "update_status": "Aktualizuj status",
    "reservation_deleted_permanently": "Ta rezerwacja zostanie trwale usunięta.",
    "table_text": "Tabele",
    "add_table_button": "Dodaj nową tabelę",
    "add_table_text": "Dodaj tabelę",
    "edit_table_text": "Edytuj tabelę",
    "table_delete_text": "Ta tabela zostanie trwale usunięta.",
    "multiple_table_delete_text": "Te tabele zostaną trwale usunięte.",
    "table_error_name": "Proszę wprowadzić nazwę tabeli",
    "table_error_invalid_name": "Proszę wprowadzić prawidłową nazwę tabeli",
    "table_error_floor": "Proszę wybrać piętro",
    "table_error_select": "Proszę wybrać tabelę",
    "table_capacity_text": "Pojemność stołu",
    "capacity_text": "Pojemność",
    "table_occasion_text": "Znajdź swój stół na każdą okazję",
    "table_name_text": "Nazwa tabeli",
    "table_capacity_error": "Wymagana jest pojemność stołu",
    "cancel_text": "Anulować",
    "submit_text": "Składać",
    "select_valid_date": "Wybierz prawidłową datę",
    "select_valid_time": "Wybierz prawidłowy czas",
    "valid_contact_number": "Wprowadź prawidłowy numer kontaktowy",
    "date_req": "Data jest wymagana",
    "date_error_later_than_today": "Data musi być późniejsza lub równa dacie dzisiejszej",
    "time_error_later_than_5_minutes": "Wybierz czas co najmniej 5 minut późniejszy od czasu bieżącego",
    "number_person_req": "Wymagana jest liczba osób",
    "contact_no_req": "Numer kontaktowy jest wymagany",
    "contact_no": "Numer kontaktowy",
    "select_time": "Wybierz czas",
    "for_how_many_person": "Dla ilu osób?",
    "f_name": "Imię",
    "l_name": "Nazwisko",
    "business_name": "Nazwa firmy",
    "business_no": "Numer firmy",
    "no_for_sign_in": "Ten numer służy do logowania",
    "business_email": "E-mail firmowy",
    "notes_tex": "Notatki",
    "floor_deleted_permanently": "To piętro zostanie trwale usunięte.",
    "add_floor": "Dodaj piętro",
    "edit_floor": "Edytuj piętro",
    "name_text": "Nazwa",
    "mobile_no": "Numer telefonu komórkowego",
    "person_text": "Osoba",
    "date_and_time": "Data i godzina",
    "actions_text": "Akcje",
    "extra_text": "Dodatkowy",
    "floor_name_req": "Nazwa piętra jest wymagana",
    "floor_name": "Nazwa piętra",
    "status_text": "Status",
    "category_status_req": "Status kategorii jest wymagany",
    "table_deleted_permanently": "Ta tabela zostanie trwale usunięta.",
    "tables_deleted_permanently": "Ta tabela zostanie trwale usunięta.",
    "back_to_home": "Powrót do strony głównej",
    "link_copied_text": "Link skopiowany do schowka",
    "cust_dash_head_to_title": "Do",
    "cust_dash_head_location_title": "Lokalizacja",
    "select_location_title": "Wybierz lokalizację",
    "all_locations_label": "wszystkie_lokalizacje",
    "rep_customer_feedback_analytics": "😊 Śledź opinie klientów dzięki analizom w czasie rzeczywistym",
    "rep_customer_txt": "Klienci",
    "rep_delete_title": "Ta reputacja zostanie trwale usunięta.",
    "rep_qr_def_txt": "Zeskanuj kod QR i podziel się z nami swoją cenną opinią na temat swoich doświadczeń.",
    "delete_title": "Usuwać",
    "user_list_title": "Lista klientów",
    "os_nfc_txt": "Karty NFC",
    "os_sign_holder_txt": "Stojak na znaki",
    "os_store_Sign_holder_txt": "Uchwyty na znaki sklepowe",
    "StaT": "Statystyka",
    "AllgT": "Wszystkie grupy",
    "FavT": "Ulubiony",
    "MostActT": "Najbardziej aktywny",
    "grT": "Nazwa grupy",
    "keywT": "Słowa kluczowe",
    "exSubT": "Wiadomość dla obecnego abonenta",
    "ArchT": "Zarchiwizowano",
    "gNotiSms": "Powiadom mnie o każdym nowym kontakcie za pomocą SMS-a",
    "gNotiEmail": "Powiadom mnie o każdym nowym kontakcie za pośrednictwem poczty e-mail",
    "reqGName": "Nazwa grupy jest wymagana",
    "validGN": "Wprowadź prawidłową nazwę grupy",
    "valid_phone_no": "Wprowadź prawidłowy numer telefonu",
    "phone_no_req": "Numer telefonu jest wymagany",
    "required_message_text": "Wiadomość jest wymagana",
    "required_discount_text": "Wymagana zniżka",
    "less_than_discount": "Kwota rabatu musi być mniejsza niż 5000",
    "discount_percentage_invalid": "Procent rabatu musi być mniejszy lub równy 100",
    "discount_type_req": "Wymagany jest typ zniżki",
    "discount_text_req": "Tekst kuponu jest wymagany",
    "reqContactNo": "Numer kontaktowy jest wymagany",
    "reqMsgNT": "Tytuł kampanii jest wymagany",
    "reqLinkT": "Link jest wymagany",
    "delMGT": "Te grupy zostaną trwale usunięte.",
    "defMemMsg": "Wpisz tutaj swoją wiadomość",
    "add_cust_to_grp_title": "Dodaj Klienta do Grupy",
    "group_title": "Grupy",
    "group_create_title": "Utwórz grupę",
    "group_name_txt": "Nazwa grupy",
    "group_table_keyword_title": "Słowo kluczowe",
    "actions_title": "Akcje",
    "clients_title": "Klienci",
    "send_title": "Wysłać",
    "qr_title": "Kod QR",
    "delete_group_txt": "Ta grupa zostanie trwale usunięta.",
    "delete_groups_txt": "Te grupy zostaną trwale usunięte.",
    "delete_client_title": "Ten klient zostanie trwale usunięty.",
    "delete_clients_title": "Ci klienci zostaną trwale usunięci.",
    "delete_multiple_title": "Wielokrotne usuwanie",
    "wel_sms_mms": "Wysłać powitalny SMS\/MMS?",
    "key_words_title": "Słowa kluczowe",
    "srch_plch_txt": "Szukaj tutaj",
    "req_location_id_title": "Lokalizacja jest wymagana",
    "create_text": "Tworzyć",
    "view_text": "Pogląd",
    "immediately": "Natychmiast",
    "business_name_req": "Nazwa firmy jest wymagana",
    "business_no_req": "Numer firmy jest wymagany",
    "valid_business_name": "Wprowadź prawidłową nazwę firmy",
    "valid_business_no": "Wprowadź prawidłowy numer firmy",
    "address_req": "Adres jest wymagany",
    "valid_address": "Proszę wybrać prawidłowy adres",
    "time_zone_req": "Strefa czasowa jest wymagana",
    "image_req": "Proszę wstawić obraz mniejszy niż 2,5 MB",
    "valid_file": "Plik jest nieprawidłowy.",
    "logo": "Logo",
    "time_zone": "Strefa czasowa",
    "save": "Ratować",
    "account_type_req": "Typ konta jest wymagany",
    "gst_no_req": "Numer GST jest wymagany",
    "gst_no_valid": "Wprowadź prawidłowy numer GST",
    "set_up_payments": "Skonfiguruj płatności",
    "billing_details": "Wprowadź dane do rozliczeń",
    "billing_details_desc": "Szczegóły dotyczące rozliczeń będą wyświetlane na miesięcznej fakturze w serwisie menuonline.",
    "account_type": "Typ konta",
    "select_account_type": "Wybierz typ konta",
    "gst_no": "Numer GST",
    "transaction_details": "Szczegóły transakcji",
    "payment_method": "Metoda płatności",
    "billing_period": "Okres rozliczeniowy",
    "paid_by": "Zapłacone przez",
    "download": "Pobierać",
    "pay_now": "Zapłać teraz",
    "pull_down_refresh": "Przeciągnij w dół, aby odświeżyć",
    "release_refresh": "Zwolnij, aby odświeżyć",
    "billing_details_text": "Szczegóły rozliczeń",
    "payment_methods": "Metody płatności",
    "invoice": "Faktura",
    "invoice_to": "Faktura Do:",
    "issue_date": "Data wydania",
    "due_date": "Termin wykonania",
    "amount_due": "Kwota należna",
    "charges": "Opłaty",
    "sub_total_capitalize": "Podsuma",
    "hst": "HST",
    "grand_total": "Suma całkowita",
    "invoice_generated_on": "Faktura wygenerowana dnia",
    "contact_us": "Skontaktuj się z nami",
    "invoice_issue": "Jeśli przejrzałeś swój rachunek i nadal masz pytania",
    "call": "Dzwonić",
    "send_sms": "Wyślij SMS",
    "payment_success": "Płatność została zrealizowana pomyślnie",
    "edit_credit_card": "Edytuj kartę kredytową",
    "add_credit_card": "Dodaj kartę kredytową",
    "modify_card_info": "Modyfikuj informacje o swojej karcie",
    "enter_card_info": "Wprowadź dane swojej karty",
    "account_no_req": "Numer konta jest wymagany",
    "card_name_req": "Imię i nazwisko na karcie jest wymagane",
    "expiry_date_req": "Data ważności jest wymagana",
    "valid_expiry_date": "Wprowadź prawidłową datę ważności",
    "valid_cvv": "Wprowadź prawidłowy kod CVV",
    "cvv_req": "Kod CVV jest wymagany",
    "card_no": "Numer karty",
    "name_of_card": "Imię na karcie",
    "expiry": "Wygaśnięcie",
    "mm_text": "MM\/RR",
    "cvv": "CVV",
    "set_as_default": "Ustaw jako domyślne",
    "add_new_card": "Dodaj nową kartę",
    "all_credit_card": "Wszystkie Twoje karty kredytowe",
    "fastest_checkout": "Najszybsza płatność z bezpieczną i niezawodną platformą, zaufaną przez dużą grupę firm",
    "coupon_button_req": "Tekst przycisku kuponu jest wymagany",
    "max19_characters_allowed": "Maksymalnie dozwolonych jest 19 znaków",
    "fine_print_text_req": "Wymagany jest tekst drobnym drukiem",
    "clients_req": "Wybierz co najmniej jednego klienta",
    "client_groups": "Grupy klientów",
    "day_text": "Dzień",
    "valid_days": "Wartość dnia jest za duża. Wprowadź prawidłowe dni.",
    "hours_text": "Godziny",
    "enter_valid_hours": "Wartość godzin jest za duża. Wprowadź prawidłowe godziny.",
    "enter_valid_min": "Wartość minut jest za duża. Wprowadź prawidłowe minuty.",
    "clients_with_connection_required": "Klienci z połączeniem w ostatnim wymaganym",
    "connection_required": "Wymagane jest połączenie",
    "connection_value_too_large": "Wartość połączenia jest za duża. Wprowadź prawidłowe połączenie.",
    "minutes_required": "Wymagane minuty",
    "clients_with_at_least_connection_required": "Wymagane jest posiadanie co najmniej połączenia przez klientów",
    "last_minutes_req": "Z ostatnimi minutami jest to wymagane",
    "clients_not_returned_required": "Kto nie wrócił w ostatnich minutach jest zobowiązany",
    "not_return_in_last_minutes_invalid": "Niepowrót w ostatniej chwili musi trwać krócej niż ostatnie minuty.",
    "customerGroups": "Grupy klientów",
    "select_customer_group": "Proszę wybrać grupę klientów",
    "location_required": "Lokalizacja jest wymagana",
    "start_date_required": "Data rozpoczęcia jest wymagana",
    "start_date_invalid": "Data rozpoczęcia jest nieprawidłowa",
    "start_date_invalid_error": "Data rozpoczęcia musi być późniejsza lub równa dacie dzisiejszej",
    "recurring": "Powtarzający się",
    "end_date_required": "Data zakończenia jest wymagana",
    "end_date_invalid": "Data zakończenia jest nieprawidłowa",
    "end_date_invalid_error": "Data zakończenia musi być późniejsza niż data rozpoczęcia",
    "time_invalid": "Czas jest nieprawidłowy",
    "monthly_text": "Miesięczny",
    "send_day_req": "Wymagane jest wysłanie w dniu",
    "loyal_clients": "Lojalni klienci",
    "recent_clients": "Ostatni klienci",
    "lapsed_clients": "Klienci, którzy zrezygnowali",
    "clients_connection_date": "Klienci według daty połączenia",
    "scheduled_text": "Zaplanowany",
    "weekly": "Tygodnik",
    "selected_clients_not_subscriber": "Wybrani klienci nie są abonentami.",
    "message_preview_title": "Podgląd wiadomości",
    "coupon_preview_title": "Podgląd kuponu",
    "form_text": "Formularz",
    "preview_text": "Zapowiedź",
    "next_text": "Następny",
    "send_test_SMS": "Wyślij SMS testowy",
    "save_draft": "Zapisz jako wersję roboczą",
    "back_to_edit": "Powrót do edycji",
    "select_payment_method": "Proszę wybrać metodę płatności",
    "schedule_SMS": "Zaplanuj SMS-a",
    "send_now": "Wyślij teraz",
    "get_more_ratings": "Uzyskaj więcej ocen",
    "overview": "Przegląd",
    "reset_campaign": "Zresetuj kampanię",
    "permissions": "Uprawnienia",
    "location_name": "Nazwa lokalizacji",
    "phone_no": "Numer telefonu",
    "location_email_address": "Lokalizacja Adres e-mail",
    "located_business": "Gdzie znajduje się Twoja firma?",
    "business_logo": "Logo firmy",
    "congratulations": "Gratulacje",
    "almost_done": "Już prawie skończyłeś",
    "publish": "Publikować",
    "about_your_business": "O Twojej firmie",
    "add_your_location": "Dodaj swoją lokalizację",
    "publish_location": "Publikuj lokalizację",
    "location_name_req": "Nazwa lokalizacji jest wymagana",
    "valid_location_name": "Wprowadź prawidłową nazwę lokalizacji",
    "business_logo_req": "Wymagane jest logo firmy",
    "please_accept_terms": "Proszę zaakceptować regulamin",
    "add_new_location": "Dodaj nową lokalizację",
    "edit_location": "Edytuj lokalizację",
    "add_location": "Dodaj lokalizację",
    "existing_msg_subscriber_txt": "Wiadomość dla obecnego abonenta",
    "msg_capitalize_txt": "Wiadomość",
    "group_noti_sms": "Powiadom mnie o każdym nowym kontakcie za pomocą SMS-a",
    "group_noti_email": "Powiadom mnie o każdym nowym kontakcie za pośrednictwem poczty e-mail",
    "group_member_msg": "Wygląda na to, że już jesteś częścią naszej grupy!!",
    "group_mend_msg": "STOP, aby zakończyć. HELP, aby uzyskać pomoc. Mogą obowiązywać stawki za SMS-y i dane",
    "Disclaimer_title": "Tekst zastrzeżenia: ",
    "group_def_msg": "Jesteśmy już online! Sprawdź nasze menu usług i zarezerwuj kolejną wizytę już dziś.",
    "required_msg_txt": "Wiadomość jest wymagana",
    "required_Key_txt": "Słowa kluczowe są wymagane",
    "required_mem_msg": "Wiadomość od subskrybenta jest wymagana",
    "client_list_title": "Lista klientów",
    "add_contact_txt": "Dodaj kontakt",
    "delete_all_clients_txt": "Usuń wszystkich klientów",
    "delete_all_clients_msg": "Czy na pewno chcesz usunąć wszystkich klientów? Nie można ich odzyskać.",
    "delete_all_txt": "Usuń wszystko",
    "timeline_title": "Oś czasu",
    "unsubscribe_title": "Wypisz się",
    "subscribe_title": "Subskrybować",
    "unsubscribe_confirm_msg": "Czy chcesz oznaczyć tego klienta jako osobę rezygnującą z subskrypcji?",
    "subscribe_confirm_msg": "Czy chcesz oznaczyć tego klienta jako subskrybenta?",
    "no_title": "NIE",
    "yes_title": "Tak",
    "client_name_title": "Nazwa klienta",
    "source_title": "Źródło",
    "contact_file_Req": "Plik kontaktów jest wymagany",
    "req_title": "Wymagany",
    "opt_in_req": "Importowane kontakty muszą być w 100% opt-in",
    "image_invalid_error": "Plik jest nieprawidłowy.",
    "import_contacts_msg": "Importuj kontakty",
    "csv_upload_title": "Plik kontaktów CSV do przesłania *",
    "csv_file_desc": "Proszę przesłać tylko plik csv – kolumny pliku csv – imię, nazwisko, adres e-mail, numer telefonu komórkowego, płeć, data urodzenia (MM\/DD\/RRRR) – bez spacji, myślników ani nawiasów w polu Numer telefonu komórkowego",
    "to_download_title": "Aby pobrać plik demonstracyjny kliknij tutaj",
    "contains_header_title": "Tak, ten plik zawiera nagłówki",
    "opt_in_100_txt": "Tak, te importowane kontakty są w 100% opt-in",
    "DisT": "Tekst zastrzeżenia: ",
    "gMendMsg": "STOP, aby zakończyć. HELP, aby uzyskać pomoc. Mogą obowiązywać stawki za SMS-y i dane",
    "reqKeyT": "Słowa kluczowe są wymagane",
    "reqMemMsg": "Wiadomość od subskrybenta jest wymagana",
    "reqMsgT": "Wiadomość jest wymagana",
    "gMemberMsg": "Wygląda na to, że już jesteś częścią naszej grupy!!",
    "gdefMsg": "Jesteśmy już online! Sprawdź nasze menu usług i zarezerwuj kolejną wizytę już dziś.",
    "next_title": "Następny",
    "male_title": "Mężczyzna",
    "male_val": "Mężczyzna",
    "female_title": "Kobieta",
    "female_val": "Kobieta",
    "others_txt": "Inni",
    "others_val": "inni",
    "validBirthdate": "Wprowadź prawidłową datę urodzenia",
    "valid_phone_no_title": "Wprowadź prawidłowy numer telefonu",
    "required_phone_no_title": "Numer telefonu jest wymagany",
    "add_new_client_txt": "Dodaj nowego klienta",
    "update_client_txt": "Aktualizuj klienta",
    "phone_num_text": "Numer telefonu",
    "dob_title": "Data urodzenia",
    "select_gender_title": "Wybierz płeć",
    "timelineTitle": "Oś czasu",
    "confirm_location": "Potwierdź lokalizację",
    "feedback_title": "Informacja zwrotna",
    "rep_question_1": "Czy firma była czysta?",
    "rep_que_2": "Czy jedzenie było dobre?",
    "rep_que_3": "Czy nasza obsługa była przyjazna?",
    "rep_que_4": "Czy nasza obsługa była szybka?",
    "business_location_req_title": "Wymagane jest podanie lokalizacji firmy",
    "valid_location_err_txt": "Proszę wybrać prawidłowy adres",
    "rep_management_title": "Uzyskaj Więcej Zarządzania Recenzjami",
    "rep_about_desc": "Reputacja pomoże Ci zwiększyć ocenę w Google, prosząc klientów o opinię",
    "preview_title": "Zapowiedź",
    "rep_preview_txt": "Podgląd reputacji",
    "back_title": "Z powrotem",
    "fine_print_txt": "Proszę wymienić u kasjera",
    "redeem_me_title": "Odkup mnie",
    "rep_nxt_visit_txt": "Podczas następnej wizyty",
    "device_type": "Rodzaj użytego urządzenia",
    "connection_method": "Metoda połączenia",
    "peak_days": "Dni szczytowe",
    "peak_hours": "Godziny szczytu",
    "guest_by_day": "Gość w dzień",
    "guest_visit": "Wizyta gościnna",
    "connection": "Połączenie",
    "connection_duration": "Czas trwania połączenia",
    "guest_visit_1": "1 raz",
    "guest_visit_2": "2 razy",
    "guest_visit_3_5": "3-5 razy",
    "guest_visit_6_10": "6-10 razy",
    "guest_visit_11_25": "11-25 razy",
    "guest_visit_26_100": "26-100 razy",
    "guest_visit_100_plus": "100+ razy",
    "device_android_total": "Całkowity Android",
    "device_android": "Android",
    "device_desktop": "Pulpit",
    "device_ios": "iOS",
    "device_ios_total": "Razem iOS",
    "device_desktop_total": "Całkowity pulpit",
    "connection_duration_10": "<=10 minut",
    "connection_duration_20": "11-20 minut",
    "connection_duration_30": "21-30 minut",
    "connection_duration_40": "31-45 minut",
    "connection_duration_60": "46-60 minut",
    "connection_method_email": "E-mail",
    "connection_method_sms": "SMS",
    "connection_method_google": "Google",
    "connection_method_facebook": "Facebook",
    "age_category_1": "<18",
    "age_category_2": "18-24",
    "age_category_3": "25-34",
    "age_category_4": "35-44",
    "age_category_5": "45-54",
    "age_category_6": "55+",
    "all_guest_txt": "Wszyscy goście",
    "new_Guest_txt": "Nowy gość",
    "connections_txt": "Znajomości",
    "hotspot": "Punkt aktywny",
    "hotspot_list": "Lista punktów dostępowych",
    "add_new_hotspot": "Dodaj nowy punkt aktywny",
    "hotspot_information": "Informacje o hotspocie",
    "edit_details_button": "Edytuj szczegóły",
    "wifi_info_message": "Połącz się i korzystaj z bezpłatnego WiFi",
    "connection_message": "Świetnie, zostałeś połączony ",
    "connection_message_suffix": " Wi-Fi",
    "wifi": "Wi-Fi",
    "login_to_access": "Zaloguj się, aby uzyskać dostęp",
    "verification_code": "Kod weryfikacyjny",
    "verification_code_message": "Proszę wpisać kod weryfikacyjny wysłany na adres ",
    "wifi_user_email": "adamo@gmail.com",
    "wifi_label": "Wi-Fi",
    "your_name": "A tak w ogóle, to jak masz na imię?",
    "your_birthdate": "Czy możesz nam powiedzieć, jaka jest twoja data urodzenia?",
    "request_guest_wifi_name": "Proszę wprowadzić nazwę sieci Wi-Fi dla gości",
    "request_device_key": "Proszę wprowadzić klucz urządzenia",
    "request_maximum_internet_access_length": "Proszę wybrać maksymalną długość dostępu do Internetu",
    "mac_address": "Adres MAC",
    "device_port": "Port urządzenia",
    "hardware": "Sprzęt komputerowy",
    "current_uptime": "Aktualny czas sprawności",
    "nearby_devices": "Urządzenia w pobliżu",
    "firmware": "Oprogramowanie sprzętowe",
    "who_are_you": "Kim jesteś?",
    "where_to_contact_you": "Gdzie możemy się z Tobą skontaktować?",
    "your_area_code": "Jaki jest Twój numer kierunkowy?",
    "connected": "Połączony",
    "delete_hotspot_message": "Ten punkt dostępu zostanie trwale usunięty.",
    "delete_multiple_hotspots_message": "Te punkty aktywne zostaną trwale usunięte.",
    "speed_error": "Prędkość powinna wynosić co najmniej 0,01",
    "speed_max_error": "Proszę wprowadzić wartość do 1024, aby uzyskać nieograniczoną prędkość, lub wybrać niższą wartość z obsługiwanego zakresu",
    "device_ssid": "SSID urządzenia",
    "device_ssid_two": "Urządzenie SSID Dwa",
    "device_ssid_two_wpa": "Urządzenie SSID Dwa WPA",
    "device_key": "Klucz urządzenia",
    "select_location": "Wybierz lokalizację",
    "select_maximum_internet_access_length": "Wybierz maksymalną długość dostępu do Internetu",
    "download_speed": "Prędkość pobierania",
    "upload_speed": "Prędkość wysyłania",
    "network_length_1": "15 minut",
    "network_length_2": "30 minut",
    "network_length_3": "45 minut",
    "network_length_4": "1 godzina",
    "network_length_5": "2 godziny",
    "network_length_6": "4 godziny",
    "network_length_7": "6 godzin",
    "network_length_8": "8 godzin",
    "network_length_9": "10 godzin",
    "network_length_10": "12 godzin",
    "employee_wifi_name": "Nazwa Wi-Fi pracownika",
    "employee_wifi_password": "Hasło do sieci Wi-Fi dla pracowników",
    "guest_wifi_name": "Nazwa sieci Wi-Fi dla gości",
    "menu_other_products_txt": "Inne produkty | Menu online",
    "menu_home_text": "Strona główna | Menu Online",
    "whatsapp_title": "WhatsApp",
    "select_campaign_type": "Wybierz typ kampanii",
    "select_readymade_templates": "Wybierz spośród gotowych szablonów lub stwórz własny",
    "campaign_title_required": "Tytuł kampanii jest wymagany",
    "type_here": "Wpisz tutaj...",
    "location_permission_req": "Wymagane jest pozwolenie na lokalizację",
    "platform_permission_req": "Wymagane jest zezwolenie na platformę",
    "profile_picture": "Zdjęcie profilowe",
    "click_to_upload": "Kliknij, aby przesłać",
    "location_permission": "Zezwolenie na lokalizację",
    "pin": "Szpilka",
    "platform_permission": "Uprawnienia platformy",
    "set_pin": "Ustaw PIN",
    "list_of_users": "Lista użytkowników",
    "create_user": "Utwórz użytkownika",
    "terms_and_condition_req": "Warunki i postanowienia są wymagane",
    "terms_and_conditions": "Warunki i postanowienia",
    "recommended": "ZALECONY",
    "mo": "\/miesiąc",
    "unsubscribe": "Wypisz się",
    "cancel_subscription": "Czy na pewno chcesz anulować subskrypcję?",
    "upgrade": "Aktualizacja",
    "plan_upgraded_successfully": "Plan został pomyślnie zaktualizowany!",
    "menu_online": "Menuonline",
    "support": "Wsparcie",
    "wifiC": "Wi-Fi",
    "billing": "Rozliczanie",
    "please_upgrade": "Aby korzystać ze wszystkich funkcji, należy dokonać aktualizacji",
    "you_have_subscribed": "Zasubskrybowałeś ",
    "plan": "plan",
    "of_text": "z",
    "days": "Dni",
    "remaining_days_until_plan": " Dni pozostałe do momentu, gdy Twój plan będzie wymagał aktualizacji",
    "manage_your_screens": "Zarządzaj swoimi ekranami",
    "screens_you_save": "Ekrany, które masz",
    "add_more_screens": "Dodaj więcej ekranów",
    "addition_screen": "Ekran dodawania",
    "per_screen": "$ za ekran",
    "per_box": "$ za pudełko",
    "shipping_changes": "Opłaty za wysyłkę",
    "upto4_boxes": "do 4 pudełek ",
    "charge_now": "Teraz otrzymasz opłatę",
    "updated_reoccuring": "Twoje zaktualizowane cykliczne będzie",
    "current_reoccuring": "Obecnie powtarzające się: ",
    "no_text": "NIE",
    "android_box": "Pudełko z Androidem",
    "old_password_req": "Wymagane jest stare hasło",
    "new_password_req": "Wymagane jest nowe hasło",
    "confirm_password_req": "Potwierdź, że hasło jest wymagane",
    "password_do_not_match": "Hasła nie pasują",
    "old_password": "Stare hasło",
    "new_password": "Nowe hasło",
    "confirm_password": "Potwierdź hasło",
    "copy_working_hours": "Skopiować te godziny pracy na wszystkie dni?",
    "yes_copy": "Tak, kopiuj",
    "closed": "Zamknięte",
    "opening_time": "Godziny otwarcia",
    "closing_time": "Godzina zamknięcia",
    "description": "Opis",
    "file_exist": "plik już istnieje, proszę wybrać inny plik",
    "bussiness_images": "Obrazy biznesowe",
    "display_info_on_market_place": "Czy chcesz wyświetlić poniższe informacje na swoim Rynku?",
    "profile_ready": "Twój profil jest gotowy!",
    "client_book_with_online": "Klienci mogą teraz rezerwować u Ciebie online. Udostępnij poniższy link, aby rozpocząć.",
    "copy_link": "Kopiuj link",
    "see_your_profile": "Zobacz swój profil",
    "ok_got_it": "Ok, rozumiem.",
    "preview_profile": "Podgląd Twojego profilu i zobacz, jak będzie wyglądał.",
    "opening_hours": "Godziny otwarcia",
    "display_data": "Wyświetl dane",
    "manage_profile": "Zarządzaj profilem",
    "phone_req": "Wymagany jest telefon",
    "market_place_img_req": "Wymagane są zdjęcia z targowiska",
    "add_new_market_place": "Dodaj nowy MarketPlace",
    "edit_market_place": "Edytuj MarketPlace",
    "no_reviews_yet": "Brak recenzji",
    "good": "Dobry",
    "average": "Przeciętny",
    "add_marketPlace": "Dodaj MarketPlace",
    "all_title": "Wszystko",
    "included_title": "Dołączony",
    "excluded_title": "Wyłączony",
    "clients_subscribed": "Klienci zapisali się w ciągu ostatnich",
    "clients_groups": "Grupy klientów",
    "customer_groups": "Grupy klientów",
    "audience_title": "Publiczność",
    "client_gt5": "który nie wrócił w ostatnim",
    "select_all": "Zaznacz wszystko",
    "modify": "Modyfikować",
    "campaign_title": "Tytuł kampanii",
    "msg_type": "Typ wiadomości",
    "enter_discount": "Wprowadź zniżkę",
    "discount_type": "Rodzaj zniżki",
    "coupon_text": "Tekst kuponu",
    "enter_coupon_text": "Wprowadź tekst kuponu",
    "coupon_button_text": "Tekst przycisku kuponu",
    "enter_coupon_button_text": "Wprowadź tekst przycisku kuponu",
    "fine_prin": "Mały druk",
    "enter_fine_prin": "Wprowadź tekst drobnym drukiem",
    "campaign_dec": "Możesz modyfikować kampanię 30 minut przed jej rozpoczęciem. Opłaty za kampanię SMS będą pobierane 30 minut przed rozpoczęciem kampanii.",
    "blast_text_message_dec": "Twoja wiadomość tekstowa jest gotowa. Wybierz metodę płatności i wyślij wiadomości.",
    "payment_completed": " Płatność zostanie zrealizowana w ",
    "total_cost": "Koszt całkowity",
    "close_title": "Zamknąć",
    "friday": "Piątek",
    "saturday": "Sobota",
    "sunday": "Niedziela",
    "thursday": "Czwartek",
    "tuesday": "Wtorek",
    "wednesday": "Środa",
    "port_txt": "Port",
    "today_title": "Dzisiaj",
    "yesterday_title": "Wczoraj",
    "last_30_days_txt": "Ostatnie 30 dni",
    "this_month_txt": "W tym miesiącu",
    "last_month_txt": "Ostatni miesiąc",
    "valid_date_title": "Wybierz prawidłową datę",
    "valid_business_name_txt": "Wprowadź prawidłową nazwę firmy",
    "req_bus_add_txt": "Adres firmy jest wymagany",
    "req_domain_name_txt": "Nazwa domeny jest wymagana",
    "basic_info_txt": "Podstawowe informacje o Twojej firmie",
    "loyalty_qr_def_txt": "Zeskanuj kod QR, zapisz się na naszą kartę lojalnościową i korzystaj z nagród.",
    "last_stamp_txt": "Ostatni znaczek",
    "collected_on_txt": "Zebrano w dniu",
    "stamp_details_txt": "Szczegóły znaczka",
    "add_stamp_txt": "Dodaj znaczek",
    "choose_brand_color_txt": "Wybierz kolor i język swojej marki 🎨",
    "change_anytime_txt": "Możesz to zmienić w dowolnym momencie",
    "like_to_get_txt": "Czy chciałbyś dostać?",
    "your_next_visit_txt": "podczas następnej wizyty?",
    "time_to_time_get_offers": "Czy chciałbyś od czasu do czasu otrzymywać świetne oferty i promocje?",
    "respond_back_to_you": "Czy chcesz, żeby ktoś Ci odpisał?",
    "input_serve_better": "Twoja opinia pomoże naszemu zespołowi lepiej Ci służyć!",
    "do_it_txt": "Zróbmy to",
    "take_15_s": "Zajmuje 15 sekund",
    "rate_on_google_desc": "Czy mógłbyś nas ocenić w Google?",
    "may_be_next_time": "Może następnym razem",
    "thank_you_txt": "Dziękuję",
    "look_next_txt": "Czekamy z niecierpliwością na Państwa kolejną wizytę!",
    "deleteUserTitle": "Ten użytkownik zostanie trwale usunięty.",
    "deleteMUserTitle": "Ci użytkownicy zostaną trwale usunięci.",
    "change_pin": "Zmień pin",
    "area_code": "Jaki jest Twój numer kierunkowy? ",
    "add_menu": "Dodaj menu",
    "menu_name": "Nazwa menu",
    "add_menu_placeholder": "Pizza, burgery, napoje itp.",
    "enable_data_Collection": "Włącz zbieranie danych",
    "add_new_menu": "Dodaj nowe menu",
    "rename_menu": "Zmień nazwę menu",
    "preview": "Zapowiedź",
    "generate_qr": "Wygenerowany kod QR",
    "edit_menu": "Edytuj menu",
    "remove_menu": "Usuń menu",
    "menu_delete_msg": "To menu zostanie trwale usunięte",
    "menus_delete_msg": "Te menu zostaną trwale usunięte",
    "view_menu_dialoage_msg": "Chcesz dostać prezent na urodziny?",
    "skip": "Pominąć",
    "cliam_your_gift": "Odbierz swój prezent",
    "collect_form": "Formularz zbierania",
    "enter_first_name": "Wprowadź imię",
    "enter_last_name": "Wprowadź nazwisko",
    "enter_email": "Wprowadź adres e-mail",
    "enter_dob": "Wprowadź datę urodzenia",
    "enter_number": "Wprowadź numer",
    "select_gender": "Wybierz płeć",
    "congratulations_desc": "🎉 Gratulacje! Twoja prośba o przyznanie prezentu została pomyślnie wysłana. Nasz zespół wkrótce się z Tobą skontaktuje. 🎉",
    "male_heading": "Mężczyzna",
    "male_text": "Mężczyzna",
    "female_heading": "Kobieta",
    "female_text": "Kobieta",
    "others_heading": "Inni",
    "other_text": "inni",
    "BirthD": "Data urodzenia",
    "GenderT": "Płeć",
    "EmailT": "E-mail",
    "dobT": "Data urodzenia",
    "capitalize_menu": "Menu",
    "select_menu": "Wybierz menu",
    "manage_variant": "Zarządzaj wariantem",
    "add_products": "Dodaj produkty",
    "add_category": "Dodaj kategorię",
    "category_delete": "Ta kategoria zostanie trwale usunięta",
    "variation_delete": "Ta odmiana zostanie usunięta z Produktu.",
    "product_delete": "Ten produkt zostanie trwale usunięty.",
    "categories_delete": "Te kategorie zostaną trwale usunięte.",
    "products_delete": "Ten produkt zostanie trwale usunięty.",
    "category": "KATEGORIA",
    "price": "CENA",
    "food_product_placeholder": "Pizza, burgery, napoje itp.",
    "active_title": "Aktywny",
    "inActive_title": "Nieaktywny",
    "status_capital": "STATUS",
    "cat_status_require": "Status kategorii jest wymagany",
    "cat_name_require": "Nazwa kategorii jest wymagana",
    "category_name": "Nazwa kategorii",
    "status": "Status",
    "lgDay1": "Poniedziałek",
    "lgDay2": "Wtorek",
    "lgDay3": "Środa",
    "lgDay4": "Czwartek",
    "lgDay5": "Piątek",
    "lgDay6": "Sobota",
    "lgDay7": "Niedziela",
    "is_closed_title": "Zamknięte",
    "book_table_title": "Zarezerwuj stolik",
    "emailErrSub": "Temat wiadomości e-mail jest wymagany",
    "email_subject": "Temat wiadomości e-mail",
    "contactUsfrmTitleEmail": "Odpowiedz na e-mail",
    "companyInfo": "Lorem Ipsum to lorem hrig, lorem ipsum to fraets. Lorem Ipsum to lorem hrig, lorem ipsum to fraets. Lorem Ipsum to lorem hrig, lorem ipsum to fraets.",
    "footerSTitle1": "O Menuonline",
    "footerSTitle1Lnk2": "Cechy",
    "footerSTitle1Lnk3": "Blog",
    "footerSTitle2": "Prawny",
    "footerSTitle2Lnk1": "Warunki i postanowienia",
    "footerSTitle2Lnk2": "Polityka prywatności",
    "footerSTitle3": "Dla biznesu",
    "footerSTitle3Lnk1": "Dla partnerów",
    "footerSTitle3Lnk2": "Wycena",
    "footerSTitle3Lnk3": "Wsparcie dla partnerów",
    "footerCopyRithgTxt": "  Menuonline lub podmioty stowarzyszone",
    "homeTitle": "Dom",
    "contactTitle": "Skontaktuj się z nami",
    "aboutTitle": "O nas",
    "homeMenuTitle": "Menu",
    "bookTableTitle": "Zarezerwuj stolik",
    "vDateT": "Wybierz prawidłową datę",
    "reqDateT": "Data jest wymagana",
    "dateGtErr": "Data musi być późniejsza lub równa dacie dzisiejszej",
    "timeInvalid": "Czas jest nieprawidłowy",
    "reqTimeT": "Potrzebny jest czas",
    "timeInvalidBfr": "Wybierz czas co najmniej 5 minut późniejszy od czasu bieżącego ",
    "PerReq": "Wymagana jest liczba osób",
    "validcNoT": "Wprowadź prawidłowy numer kontaktowy",
    "reqCrT": "Waluta jest wymagana",
    "reqPrT": "Cena jest wymagana",
    "reCateT": "Kategoria jest wymagana",
    "reqVrT": "Wariant jest wymagany",
    "reqVrVldT": "Wprowadź prawidłowy wariant ",
    "validLNameT": "Wprowadź prawidłowe nazwisko",
    "sDateInValid": "Data od jest nieprawidłowa",
    "minmReq": "Wymagane jest minimum",
    "resLblDate": "Wybierz datę",
    "resLblTime": "Wybierz czas",
    "perT": "Dla ilu osób?",
    "resLblEmail": "Proszę podać swój adres e-mail?",
    "resLblNote": "Dodaj notatkę rezerwacyjną",
    "imageError1MB": "Proszę wstawić obraz mniejszy niż 1 MB",
    "imageError": "Proszę wstawić obraz mniejszy niż 2,5 MB",
    "imageError3Mb": "Proszę wstawić obraz mniejszy niż 3 MB",
    "imageInVldError": "Plik jest nieprawidłowy.",
    "addProMT": "Menu produktów",
    "proT": "Nazwa produktu",
    "reqProT": "Nazwa produktu jest wymagana",
    "proPT": "Cena produktu",
    "reservationSuccTitle": "Świetnie, Twoja rezerwacja została dokonana",
    "book": "Książka",
    "custzName": "Nazwa personalizacji",
    "always": "Zawsze",
    "proImgReq": "Obraz produktu jest wymagany",
    "selCustmT": "Wybierz opcję dostosowywania lub utwórz nowy",
    "visStR": "Status widoczności jest wymagany",
    "avlblScR": "Proszę wybrać harmonogram dostępności",
    "addonPR": "Proszę wybrać produkty dodatkowe",
    "upsellPR": "Proszę wybrać produkty do sprzedaży dodatkowej",
    "markItemR": "Proszę wybrać produkty oznaczone jako przedmioty",
    "caloriesR": "Kalorie są wymagane",
    "allergensR": "Proszę wybrać produkty alergenne",
    "prepInstR": "Wymagane są instrukcje dotyczące przygotowania",
    "staffNR": "Wymagane są notatki personelu",
    "discountR": "Wymagana zniżka",
    "validDiscE": "Wprowadź ważny rabat",
    "disday": "Za dnia",
    "plSelDayT": "Proszę wybrać dzień",
    "sTimeReq": "Wymagany jest czas rozpoczęcia",
    "sTimeInValid": "Godzina rozpoczęcia jest nieprawidłowa",
    "eTimeReq": "Wymagany jest czas zakończenia",
    "eTimeInValid": "Czas zakończenia jest nieprawidłowy",
    "sDateReq": "Wymagana jest data od",
    "eDateReq": "Do tej pory jest wymagane",
    "eDateInValid": "Do tej pory jest nieważne",
    "disdate": "Od daty",
    "disdate1": "Do tej pory",
    "disdate2": "Od daty",
    "currT": "Waluta",
    "iconR": "Proszę wybrać ikonę zaznaczenia elementu",
    "minT": "Minimum",
    "maxT": "Maksymalny",
    "itemNT": "Nazwa przedmiotu",
    "itemPT": "Cena przedmiotu",
    "descProT": "Opisz swój produkt",
    "cateT": "Kategoria",
    "selProCateT": "Wybierz kategorię swojego produktu",
    "reqMT": "Nazwa menu jest wymagana",
    "ViewMenu": "Wyświetl menu",
    "CopyMenu": "Kopiuj menu",
    "EditMenu": "Edytuj menu",
    "RemoveMenu": "Usuń menu",
    "welcomeMnuTitle": "Witamy w",
    "reviewT": "Recenzje",
    "userErrorMsg": "Przepraszamy, wystąpił błąd!!!",
    "reqCateT": "Nazwa kategorii jest wymagana",
    "mngCustT": "Zarządzaj dostosowywaniem",
    "custNReq": "Nazwa dostosowania jest wymagana",
    "incReq": "Wymagane jest dołączenie",
    "minmValid": "Wprowadź prawidłową wartość minimalną",
    "maxmReq": "Wymagane jest maksimum",
    "cust": "Personalizacja",
    "crCust": "Utwórz nową personalizację",
    "custList": "Lista dostosowań",
    "delCustmzT": "Ta personalizacja zostanie trwale usunięta.",
    "mkNameR": "Zaznacz nazwę elementu, która jest wymagana",
    "mkDelT": "Ten element zostanie trwale usunięty.",
    "hideText": "Ukrywać",
    "showText": "Pokazywać",
    "device_mac_txt": "Urządzenie Mac",
    "delete_hotspot_txt": "Ten punkt dostępu zostanie trwale usunięty.",
    "delete_hotspots_txt": "Te punkty aktywne zostaną trwale usunięte.",
    "emp_wifi_name": "Nazwa Wi-Fi pracownika",
    "max_limit_txt": "Wprowadź '1024' dla prędkości, aby oznaczyć ją jako NIEOGRANICZONĄ. Ta wartość usuwa wszelkie ograniczenia, umożliwiając maksymalną przepustowość",
    "device_port_txt": "Port urządzenia",
    "firmware_txt": "Oprogramowanie sprzętowe",
    "hotspot_info_txt": "Informacje o hotspotach",
    "editDBtn": "Edytuj szczegóły",
    "birth_date": "Data urodzenia",
    "di_theme_clr": "Kolor motywu dokładnie 7 cyfr",
    "color_Invalid_txt": "Wybierz prawidłowy kod koloru",
    "Req_theme_clr": "Kolor motywu jest wymagany",
    "select_valid_color_txt": "Proszę wybrać prawidłowy kolor motywu",
    "req_redir_link": "Wymagany jest link przekierowania",
    "val_redir_link": "Wprowadź prawidłowy link przekierowujący",
    "req_business_name_txt": "Nazwa firmy jest wymagana",
    "splash_preview": "Podgląd Splash",
    "create_new_splash": "Utwórz nowy Splash",
    "splash_page": "Strona powitalna",
    "splash_page_builder": "Kreator stron powitalnych",
    "redirect_link": "Przekieruj link",
    "theme_color": "Kolor motywu",
    "enable_social_login": "Włącz opcje mediów społecznościowych dla logowania użytkowników",
    "google": "Google",
    "facebook": "Facebook",
    "is_mandatory": "Czy jest to obowiązkowe?",
    "field": "Pole",
    "name": "Nazwa",
    "first_name": "Imię",
    "last_name": "Nazwisko",
    "birthdate": "Data urodzenia",
    "gender": "Płeć",
    "email": "E-mail",
    "dob": "Data urodzenia",
    "zip_code": "Kod pocztowy",
    "required_redirect_link": "Wymagany jest link przekierowania",
    "valid_redirect_link": "Wprowadź prawidłowy link przekierowujący",
    "required_theme_color": "Kolor motywu jest wymagany",
    "theme_color_length": "Kolor motywu dokładnie 7 cyfr",
    "required_name": "Imię jest wymagane",
    "delete_splash_message": "Chcesz usunąć ten ekran powitalny?",
    "delete_multiple_splashes_message": "Chcesz usunąć te plamy?",
    "user_login_required": "Wymagane jest zalogowanie użytkownika",
    "set_theme_color": "Ustaw kolor motywu",
    "splash_colllect_additional_info_txt": "Zbierz dodatkowe informacje",
    "verify_mobile_otp_message": "Czy chcesz wysłać OTP w celu weryfikacji numeru telefonu komórkowego?",
    "add_company": "Dodaj firmę",
    "edit_company": "Edytuj firmę",
    "ds_device": "Urządzenie do oznakowania cyfrowego",
    "ds_device_activation_fee": "Opłata za aktywację urządzenia Digital Signage",
    "wifi_hardware": "Sprzęt Wi-Fi",
    "menu_design": "Projekt menu",
    "ds_hardware": "Sprzęt do oznakowania cyfrowego",
    "company_name_req": "Nazwa firmy jest wymagana",
    "known_name_req": "Wymagane jest znane imię",
    "no_location_req": "Wymagana jest liczba lokalizacji",
    "minimum_location": "Proszę podać co najmniej 1 lokalizację",
    "terms_req": "Warunki są wymagane",
    "notes_req": "Notatki są wymagane",
    "sms_charge_req": "Wymagana jest opłata za SMS",
    "menuonline_quantity_req": "Menu Online Ilość jest wymagana",
    "menuonline_cost_req": "Menu Online Koszt jest wymagany",
    "ds_quantity_req": "Wymagana jest ilość Digital Signage",
    "ds_monthly_cost_req": "Wymagany jest miesięczny koszt oznakowania cyfrowego",
    "reputation_quantity_req": "Ilość reputacji jest wymagana",
    "reputation_cost_req": "Koszt reputacji jest wymagany",
    "marketing_quantity_req": "Ilość marketingowa jest wymagana",
    "marketing_cost_req": "Koszt marketingu jest wymagany",
    "tax_percentage_req": "Procent podatku jest wymagany",
    "router_hardware_quantity_req": "Wymagana jest ilość sprzętu routera",
    "wifi_hardware_cost_req": "Wymagany jest koszt sprzętu WIFI",
    "activation_cost_req": "Wymagany jest koszt aktywacji",
    "wifi_quantity_req": "Wymagana jest ilość WIFI",
    "wifi_router_req": "Wymagany jest router Wi-Fi",
    "menu_design_quantity_req": "Projekt menu Ilość jest wymagana",
    "menu_design_cost_req": "Koszt projektu menu jest wymagany",
    "quantity_req": "Ilość jest wymagana",
    "cost_req": "Koszt jest wymagany",
    "sheduledown_quantity_req": "Wymagana jest ilość harmonogramu",
    "sheduledown_cost_req": "Wymagany jest harmonogram obniżek kosztów",
    "loyalty_program_quantity_req": "Ilość programu lojalnościowego jest wymagana",
    "loyalty_program_cost_req": "Koszt programu lojalnościowego jest wymagany",
    "loyalty_program_sms_quantity_req": "Ilość SMS-ów programu lojalnościowego jest wymagana",
    "loyalty_program_sms_cost_req": "Koszt SMS-a w programie lojalnościowym jest wymagany",
    "comapny_created": "Firma stworzona pomyślnie",
    "comapny_updated": "Firma zaktualizowana pomyślnie",
    "billing_info": "INFORMACJE O ROZLICZENIU",
    "business_name_acc_holder": "Nazwa firmy (właściciel konta)",
    "client_first_name": "Imię klienta",
    "client_last_name": "Nazwisko klienta",
    "dba_known_as": "DBA znany jako",
    "business_address": "Adres firmy",
    "no_of_locations": "Liczba lokalizacji",
    "package_details": "SZCZEGÓŁY PAKIETU",
    "terms": "Warunki",
    "one_month": "1 miesiąc",
    "per_sms_charge": "Opłata za SMS",
    "plan_test": "PLAN : TEST",
    "desc_capital": "OPIS",
    "qty_capital": "ILOŚĆ",
    "cost_capital": "KOSZT",
    "total_price_capital": "CENA CAŁKOWITA",
    "quantity": "Ilość",
    "can_not_access": "Nie można wybrać więcej niż",
    "licenses": "licencje",
    "monthly_cost": "Koszt miesięczny",
    "plan_cost": "Koszt planu",
    "tax": "Podatek",
    "tax_percentage": "Procent podatku",
    "monthly_cost_after_tax": "Miesięczny koszt po opodatkowaniu",
    "activation_one_time_charge": "Aktywacja i jednorazowa opłata",
    "sub_total": "SUMA CZĄSTKOWA",
    "action": "Działanie",
    "cost": "Koszt",
    "shipping_charge": "Opłata za wysyłkę",
    "other": "Inny",
    "additional_cost": "Koszt dodatkowy (Inne opłaty)",
    "other_tax": "Inne podatki",
    "total": "Całkowity",
    "license_statistics": "Statystyki licencji",
    "total_licenses": "Łączna liczba licencji",
    "available_licenses": "Dostępne licencje",
    "stock": "Magazyn",
    "has_permission": "Ma pozwolenie",
    "avg_stock_price": "Średnia cena akcji",
    "average_price": "Średnia cena",
    "allocated": "Asygnowany",
    "reward_regulars": "Nagradzaj stałych bywalców",
    "not_add_coupon_url": "Nie można dodać adresu URL kuponu. Maksymalna długość wiadomości wynosi 160",
    "like_attach_coupon": "Czy chcesz dołączyć kupon?",
    "advance_scheduling": "Planowanie z wyprzedzeniem",
    "choose_day": "Wybierz dzień(dni), w których chcesz wysłać wiadomość",
    "select_essage_window": "Wybierz okno wiadomości",
    "subscription_outside_delivery_window": "Jeśli użytkownik zapisze się poza przedziałem czasowym dostarczania wiadomości, otrzyma Twoją automatyczną odpowiedź w najbliższym dostępnym terminie.",
    "remaining": "Pozostały",
    "processing_request": "Wystąpił błąd podczas przetwarzania Twojego żądania.",
    "list_companies": "Lista firm",
    "are_you_sure": "Jesteś pewien..?",
    "signin_agreement": "Potwierdzając to, Spółka powstanie bez podpisywania umowy.",
    "status_updated": "Aktualizacja statusu zakończona pomyślnie",
    "status_failed": "Nie udało się zaktualizować statusu",
    "new_editor_status_updated": "Status nowego edytora DS został pomyślnie zaktualizowany.",
    "user_name": "Nazwa użytkownika",
    "known_as": "znany jako",
    "agreement_signed": "Umowa podpisana",
    "deactivate": "Dezaktywować",
    "activate": "Aktywować",
    "login_to_user_acc": "Zaloguj się na konto użytkownika",
    "disable": "Wyłączyć",
    "enable": "Włączać",
    "ds_new_editor": "DS Nowy Edytor",
    "sign_agreement": "Podpisz Umowę",
    "agreement_sign": "Umowa bez podpisu",
    "view_agreement": "Wyświetl umowę",
    "download_agreement": "Pobierz Umowę",
    "add_deleted_user": "Dodaj usuniętego użytkownika",
    "deleted_user": "Usuń użytkownika",
    "favourite": "Ulubiony",
    "refresh": "Odświeżać",
    "delete_chat": "Usuń czat",
    "responsive_drawer": "Responsywna szuflada",
    "delete_contact": "Usuń kontakt",
    "clear_chat_history": "Wyczyść historię czatu",
    "clear_chat_history_dec": "Czy na pewno chcesz usunąć ten czat?",
    "clear_contact_dec": "Czy na pewno chcesz usunąć ten kontakt?",
    "select_contact": "Wybierz kontakt",
    "new_conversation": "Rozpocznij nową rozmowę",
    "type_msg": "Wpisz tutaj swoją wiadomość",
    "select_delete_client": "Proszę wybrać klienta do usunięcia",
    "select_delete_client_chat": "Proszę wybrać klienta, aby usunąć czat",
    "select_delete_client_chat_err": "Nie masz czatu z tym klientem",
    "acquire_customers_business": "Zdobywaj nowych klientów od innych firm.",
    "customers_from_events": "Zdobądź klientów z wydarzeń i sprowadź ich do siebie!",
    "customers_from_ads": "Zdobywaj klientów dzięki reklamom w gazetach.",
    "smart_pamphlets": "Twórz inteligentne broszury w celu zbierania danych klientów.",
    "smart_qr_group": "Inteligentny kod QR umożliwiający klientom dołączenie do grupy.",
    "opt_in_number": "Umożliw klientom wybór numeru Opt-in.",
    "qr_menu": "Utwórz kod QR, aby wyświetlić swoje menu.",
    "collect_birthdays": "Zbieraj informacje o urodzinach i rocznicach klientów.",
    "join_loyalty_program": "Zachęć nowych klientów do dołączenia do mojego programu lojalnościowego.",
    "create_feedback_forms": "Utwórz formularze do zbierania opinii klientów.",
    "exclusive_discounts_promotions": "Oferuj ekskluzywne zniżki i promocje w ramach promocji krzyżowych.",
    "group_smart_qr_opt_in": "Po utworzeniu grupy możesz wygenerować i wybrać inteligentny kod QR, dzięki któremu klienci będą mogli natychmiast dołączyć do grupy",
    "group_smart_qr_opt_in_dec": "Po utworzeniu grupy widżet Obraz umożliwia klientom zapisanie się do grupy za pomocą tekstu i otrzymywanie promocji, np. wyślij SMS-a o treści „pizza” na numer 555-555-5555",
    "in_store_discount_next_visit": "Otrzymaj zniżkę w sklepie podczas następnej wizyty w naszym punkcie",
    "delivery_redirection": "Utwórz przekierowanie dostawy",
    "additional_info": "Informacje dodatkowe",
    "add_url": "Dodaj adres URL",
    "custom_fields": "Pola niestandardowe",
    "meta_tag": "Znacznik meta",
    "max_chars": "Maksymalna liczba znaków",
    "add": "Dodać",
    "update_campaign": "Aktualizacja kampanii",
    "last_week_required": "ostatni tydzień jest wymagany",
    "large_week_value": "Wartość tygodnia jest za duża. Wprowadź prawidłowy tydzień.",
    "less_than_last_week": "Niepowrót w zeszłym tygodniu musi być krótszy niż w zeszłym tygodniu.",
    "last_week_req": "Wymagane jest, aby nie wracać w zeszłym tygodniu",
    "birthday_special": "Specjalne urodziny",
    "birthday_message": "Zautomatyzowana wiadomość wysyłana w dniu urodzin z spersonalizowanymi pozdrowieniami i kuszącymi ofertami",
    "birthday_sms": "Utwórz spersonalizowane życzenia urodzinowe w formie SMS-ów\/MMS-ów",
    "target_audience": "Wybierz grupę docelową",
    "client_by_birthday": "Klient według urodzin",
    "clients_once_year": "Ta kampania jest automatycznie wysyłana do klientów raz w roku ",
    "auto_responder_default_msg_1": "Jesteś jednym z naszych najlepszych klientów, którym uwielbiamy dawać rabaty! Obyś zawsze był bogaty i zdrowy. Wszystkiego najlepszego z okazji urodzin! %% imię %%",
    "campaign_send_to_client_birthday": "Ta automatyczna kampania jest wysyłana do klientów, którzy mają urodziny. Poniżej możesz dostosować grupę docelową.",
    "get_more_engagement": "Zdobądź więcej zaangażowania wysyłając SMS-y urodzinowe 🎂",
    "welcome_new_clients": "Witamy nowych klientów",
    "make_lasting_impression": "Wywrzyj trwałe wrażenie, serdecznie witając i witając gości, którzy przychodzą do Ciebie po raz pierwszy",
    "personalized_greetings": "Utwórz spersonalizowane powitania dla nowych klientów",
    "greetings_to_new_customers": "Ta kampania wyśle powitalne pozdrowienia do nowych klientów następnego dnia po ich pierwszej interakcji z Twoją firmą",
    "audience_predefined": "Grupa odbiorców tej automatycznej odpowiedzi jest wstępnie zdefiniowana, damy radę!",
    "send_clients_subscribed": "Ten autoresponder jest ustawiony tak, aby wysyłać klientom zapisanym w ciągu ostatnich ",
    "first_interaction": "  po pierwszej interakcji.",
    "default_msg_2": "Mamy nadzieję, że podobała Ci się Twoja pierwsza wizyta, chcielibyśmy Cię wkrótce znowu zobaczyć, dlatego oferujemy zniżkę tylko dla Ciebie! Kliknij link, aby zarezerwować kolejną wizytę u nas i skorzystać ze specjalnej oferty online już teraz.",
    "new_clients_update": "Witamy nowych klientów, aktualizujemy tekst kampanii",
    "new_clients_warm_greeting": "Witamy nowych klientów ciepłym pozdrowieniem 💌",
    "win_back_clients": "Odzyskaj klientów",
    "re_engage_past_customers": "Ponownie zaangażuj swoich byłych klientów, oferując im nieodparte oferty, aby zachęcić ich do powrotu",
    "target_disengaged_clients": "Zmotywuj niezainteresowanych klientów do powrotu, proponując im specjalną ofertę. To sprawdzona taktyka, pozwalająca na ponowne przyciągnięcie klientów.",
    "campaign_sends_to_clients": "Ta kampania jest wysyłana do klientów, którzy nie powrócili po upływie określonego czasu.",
    "customize_targeting": "Dostosuj poniżej targetowanie, aby wysyłać wiadomości SMS\/MMS do klientów, z którymi nie masz już kontaktu.",
    "clients_with_at_least": "Klienci posiadający co najmniej ",
    "connection_last": " połączenie w ostatnim ",
    "return_in_last_week": " tydzień, ale nie wrócił w zeszłym ",
    "arr_menu_44": " tydzień",
    "df_msg_4": "Tak BARDZO chcemy, żebyś wrócił, że damy ci 10% zniżki za sam powrót! Chcesz spróbować jeszcze raz dzisiaj?",
    "update_campaign_text": "Odzyskaj klientów, aktualizuj tekst kampanii",
    "re_invite_customers": "Ponownie zaproś klientów, którzy od dłuższego czasu nie odwiedzili Cię ponownie",
    "loyal_patrons": "Okaż wdzięczność lojalnym klientom, oferując im specjalne zniżki i nagrody za lojalność",
    "surprize_top_spenders": "Zaskocz największych klientów spersonalizowanymi i specjalnymi ofertami.",
    "campaign_automatically_send": "Ta kampania automatycznie wysyła nagrody, aby zwiększyć liczbę klientów odwiedzających Twoją firmę.",
    "sms_loyal_clients": "Ta kampania wysyła wiadomości SMS\/MMS do lojalnych klientów. Poniżej możesz zmienić grupę docelową.",
    "or_more_connection": " lub więcej połączeń w ciągu ostatniego ",
    "week_period": " okres tygodniowy.",
    "default_msg_5": "Jesteś naszym klientem już od dawna! Wow, czas leci! Doceniamy Cię i chcemy Ci podziękować za to, że jesteś z nami.",
    "reward_regulars_update": "Tekst kampanii aktualizacji nagród dla stałych bywalców",
    "special_discounts": "Doceniaj i oferuj specjalne zniżki💎",
    "leave_positive_online": "Zachęcaj klientów do pozostawiania pozytywnych opinii online, aby poprawić reputację restauracji",
    "clients_service_online": "Kampania zachęca klientów do oceniania Twoich usług online.",
    "increase_your_company": "Celem tej automatycznej odpowiedzi jest zwiększenie rankingu Twojej firmy w Internecie.",
    "send_message_after": "Wyślij wiadomość po ",
    "min_connection": " min połączenia",
    "default_msg_6": "Mamy nadzieję, że podobała Ci się Twoja pierwsza wizyta i chętnie Cię wkrótce zobaczymy! [URL] Kliknij link, aby ocenić nas w Google.",
    "ratings_update": "Uzyskaj więcej ocen aktualizuj tekst kampanii",
    "friendly_nudge": "Zdobądź więcej recenzji dzięki przyjaznemu kuksańcowi ⭐",
    "thanks_for_visiting": "Dziękujemy za odwiedziny",
    "express_gratitude": "Wyraź wdzięczność klientom za wybór Twojej firmy za pomocą szczerych wiadomości z podziękowaniami",
    "thank_you_message": "Utwórz wiadomość z podziękowaniami dla swoich klientów.",
    "clients_for_visiting": "Przekaż swoim klientom serdeczne podziękowania za odwiedzenie Twojego lokalu.",
    "campaign_audience": "Ta grupa odbiorców automatycznej kampanii jest wstępnie zdefiniowana, damy radę!",
    "campaign_automatically_sends": "Ta automatyczna kampania jest wysyłana automatycznie po ",
    "minutes_customer_visit": " minut wizyty klienta",
    "default_msg_7": "Dziękujemy za bycie naszym cenionym klientem. Jesteśmy bardzo wdzięczni za przyjemność obsługiwania Ciebie i mamy nadzieję, że spełniliśmy Twoje oczekiwania.",
    "visiting_update_campaign": "Dziękujemy za odwiedzenie tekstu kampanii aktualizacji",
    "guests_thank_you": "Podziękuj swoim gościom za wizytę 😊",
    "download_title": "Pobierać",
    "qr_gen_title": "Wygenerowano kod QR",
    "qr_download_s_title": "Kod QR został pomyślnie pobrany",
    "sel_dark_brand_clr_msg": "Proszę wybrać prawidłowy kolor stada",
    "manage_customer_stamp_rewards": "Zarządzaj nagrodami w postaci pieczątek klientów 🎁",
    "sel_loc_menu_title": "Wybierz lokalizacje dla swojego menu",
    "ans_req": "Odpowiedź jest wymagana",
    "valid_reputation_name": "Wprowadź prawidłową nazwę reputacji",
    "reviews_name_req": "Uzyskaj więcej opinii nazwa jest wymagana",
    "birthdate_required": "Data urodzenia jest wymagana",
    "gender_required": "Płeć jest wymagana",
    "valid_birthdate_required": "Wprowadź prawidłową datę urodzenia",
    "custom_delivery_redirection": "Utwórz niestandardowy formularz przekierowania dostawy według grup",
    "customer_fields_incentive_settings": "Pola klientów i ustawienia zachęt",
    "delivery_redirection_text": "Przekierowanie dostawy",
    "delivery_redirection_success": "Gratulacje! Twój widżet przekierowania dostawy został utworzony! 🎉",
    "swipe_to_preview_redirection": "Przesuń, aby zobaczyć, jak będzie wyglądał Twój formularz przekierowania dostawy",
    "enter_item_name": "Wprowadź nazwę przedmiotu",
    "home_page_text": "Tekst strony głównej",
    "settings": "Ustawienia",
    "anniversary_required": "Data rocznicy jest wymagana",
    "valid_anniversary": "Wprowadź prawidłową datę rocznicy",
    "form_submited": "formularz został pomyślnie wysłany",
    "notifications": "Powiadomienia",
    "discount_message": "Odbierz już teraz 20% zniżki na pizzę!",
    "is_required": " jest wymagane",
    "section_title_required": "Tytuł sekcji jest wymagany",
    "section_dec_required": "Opis sekcji jest wymagany",
    "client_details_required": "Pola danych klienta są wymagane",
    "compliance": "Zgodność",
    "SMS_campaign1": "Kampania SMS 1",
    "mobile_number_mandatory": "Numer telefonu komórkowego jest obowiązkowy",
    "new_answer": "Nowa odpowiedź",
    "new_question": "Nowe pytanie",
    "add_new_question": "Dodaj nową odpowiedź",
    "select": "Wybierać",
    "group_customers_question": "Gdzie chcesz grupować swoich klientów?",
    "contacts_added_to_group": "Wszystkie kontakty, które wyślą formularz, zostaną dodane do wybranej grupy",
    "edit_client_details_section": "Edytuj sekcję szczegółów klienta",
    "client_details_fields": "Pola danych klienta",
    "enter_client_details_section_title_desc": "Wprowadź tytuł i opis sekcji danych klienta",
    "choose_fields_displayed_client_side": "Wybierz pola, które mają być wyświetlane po stronie klienta",
    "section_title": "Tytuł sekcji",
    "add_title": "Dodaj tytuł",
    "section_description": "Opis sekcji",
    "add_description": "Dodaj opis",
    "enter_program_name_location": "Wprowadź nazwę programu i lokalizację 📍",
    "brand_theme_clr_txt": "Kolor motywu marki",
    "set_loyalty_punch_card_theme": "Ustalmy motyw dla Twojej karty lojalnościowej 🎨",
    "upload_logo_txt": "Prześlij swoje logo",
    "recommended_image_specs": "Zalecamy użycie obrazu o rozmiarze 512 x 512 px, aby uzyskać najlepszy wyświetlacz. JPG, SVG lub PNG. Maksymalny rozmiar 10 MB.",
    "valid_rep_name_txt": "Wprowadź prawidłową nazwę reputacji",
    "rep_name_req_txt": "Uzyskaj więcej opinii nazwa jest wymagana",
    "que_req": "Pytanie jest wymagane",
    "day_of_birthday": "w dniu urodzin",
    "day_before_birthday": "3 dni przed urodzinami",
    "week_before_birthday": "tydzień przed urodzinami",
    "two_week_before_birthday": "dwa tygodnie przed urodzinami",
    "de_active": "Dezaktywuj",
    "campaign_details": "Szczegóły kampanii",
    "link_clicked": "Kliknięto link",
    "history": "Historia",
    "auto_responder_summary": "Podsumowanie automatycznej odpowiedzi",
    "vsop_1": "15 minut",
    "vsop_2": "30 minut",
    "vsop_3": "45 minut",
    "vsop_4": "60 minut",
    "vsop_5": "90 minut",
    "vsop_6": "120 minut",
    "in_the_last": " w ostatnim ",
    "return_in_last": " ale nie wrócił w zeszłym roku ",
    "of_the_connection": "połączenia",
    "your_campaign": "Twoja kampania",
    "you_have_successfully": "Udało Ci się",
    "published_successfully": "opublikowano pomyślnie",
    "updated_smart_campaign": "zaktualizowano inteligentną kampanię",
    "almost_done_text": "Prawie gotowe!",
    "update_campaign_desc": "Twój autoresponder jest kompletny, po włączeniu będzie wysyłał wiadomości do klientów na bieżąco. Tę kampanię można łatwo zmodyfikować lub wstrzymać w dowolnym momencie.",
    "update_and_publish": "Aktualizuj i publikuj",
    "reset_campaign_title": "Spowoduje to trwałe usunięcie niestandardowego tekstu, obrazów, ofert rabatowych i grupy docelowej Twojej kampanii!",
    "space_wifi": " Wi-Fi",
    "custom_forms": "Formularze niestandardowe",
    "web_e_signup": "Rejestracja elektroniczna w sieci Web",
    "import": "Import",
    "permissions_req": "Wymagane są uprawnienia",
    "redeemed": "Odkupiony",
    "coupon_already_redeemed": "Kupon został już wykorzystany",
    "autoresponder_campaigns": "Kampanie autoresponderów",
    "autoresponder_campaign_desc": "Włącz autoresponder i uruchom spersonalizowaną kampanię",
    "mon": "Poniedziałek",
    "tue": "WT",
    "wed": "POŚLUBIĆ",
    "thu": "CZW",
    "fri": "PT",
    "sat": "OSOBA",
    "sun": "SŁONECZNY",
    "duplicate": "Duplikat",
    "visibility": "Widoczność",
    "availability": "Dostępność",
    "out_stok": "Poza stanem magazynowym",
    "edit_product": "Edytuj produkt",
    "create_product": "Utwórz produkt",
    "basic_info": "Podstawowe informacje",
    "basic_sub_info": "Lorem Ipsum to po prostu fikcyjny tekst druku...",
    "enter_product_price": "Wprowadź cenę produktu",
    "upload_image": "Prześlij obraz",
    "allowed_file_formats": "Dozwolone są wyłącznie pliki JPG i PNG o maksymalnym rozmiarze 3 MB.",
    "pre_select": "Wstępny wybór",
    "promotions": "Promocje",
    "discount_availability": "Dostępność rabatu",
    "start_time": "Czas rozpoczęcia",
    "end_time": "Czas zakończenia",
    "select_day": "Wybierz dzień",
    "menu_required": "Menu jest wymagane",
    "generated": "Wygenerowano",
    "link": "Połączyć",
    "variations": "Wariacje",
    "select_variant_to_add": "Wybierz wariant, który chcesz dodać",
    "price_title": "Cena",
    "choose_image": "Wybierz obraz",
    "select_visiblity": "Wybierz widoczność",
    "availability_schedule": "Harmonogram dostępności",
    "add_on_upsell": "Dodatek \/ Sprzedaż dodatkowa",
    "add_on_select_product": "Dodaj Wybierz produkt",
    "upsell_product": "Sprzedaż dodatkowa Wybierz produkt",
    "variant_deletion_warning": "Ten wariant zostanie trwale usunięty.",
    "search_variations": "Wyszukaj odmiany",
    "add_variation": "Dodaj odmianę",
    "variation_text": "Wariant",
    "select_customization": "Wybierz dostosowanie",
    "add_new": "Dodaj nowy",
    "delete_customization_warning": "Czy chcesz usunąć tę personalizację z produktu?",
    "nutritional_allergen_info": "Informacje żywieniowe i alergeny",
    "mark_item": "Zaznacz element",
    "calories_text": "Kalorie",
    "allergens": "Alergeny",
    "select_allergens": "Wybierz alergeny",
    "special_instructions": "Instrukcje specjalne",
    "preparation_instructions": "Instrukcje przygotowania",
    "staff_notes": "Notatki personelu",
    "checkbox1": "Pole wyboru umożliwiające zezwolenie lub zablokowanie specjalnych instrukcji od klientów dla tej pozycji menu.",
    "menu_c": "MENU",
    "past_design": "Poprzedni projekt",
    "file_name": "Nazwa pliku",
    "variation_name": "Nazwa wariantu",
    "grid_items_title1": "Namiot stołowy",
    "grid_items_title2": "Menu cyfrowe",
    "grid_items_title3": "Menu na wynos",
    "grid_items_title4": "Menu na pół strony",
    "grid_items_title5": "Menu cyfrowe 5",
    "grid_items_title6": "Menu cyfrowe 6",
    "grid_items_title7": "Menu cyfrowe 7",
    "grid_items_title8": "Menu cyfrowe 8",
    "grid_items_title9": "Menu cyfrowe 9",
    "enter_valid_form_name": "Wprowadź prawidłową nazwę formularza",
    "form_name_required": "Nazwa formularza jest wymagana",
    "enter_valid_section": "Wprowadź prawidłowy tytuł sekcji",
    "fraction_half": "1\/2",
    "fraction_two_by_two": "2\/2",
    "form_name": "Nazwa formularza",
    "contact_list": "Lista kontaktów",
    "label_notify_email": "Czy chcesz otrzymywać powiadomienia e-mailem?",
    "add_client_details_section": "Dodaj sekcję szczegółów klienta",
    "demo_purpose_only": "To tylko do celów demonstracyjnych",
    "form_updated_success": "formularz zaktualizowano pomyślnie",
    "form_creat_success": "formularz został pomyślnie utworzony",
    "add_custom_section": "Dodaj sekcję niestandardową",
    "edit_custom_section": "Edytuj sekcję niestandardową",
    "forms_list": "Lista formularzy",
    "filled_custom_form_details": "Wypełnione szczegóły formularza niestandardowego",
    "custom_form_deletion_warning": "Ten niestandardowy formularz zostanie trwale usunięty",

    "menu_link": "Link do menu",
    "WebSignUpT": "E-rejestracja",
    "lifeTConT": "Połączenia na całe życie",
    "repCustT": "Klienci powracający",
    "liftTSubT": "Subskrybenci na całe życie",
    "overallT": "OGÓLNIE",
    "loginCustBDT": "Zalogowani klienci według dnia",
    "deviceT": "Dane demograficzne urządzeń",
    "ageTitle": "Dane demograficzne dotyczące wieku",
    "ageT": "Grupa wiekowa",
    "convert_social_media_followers_into_customers": "Zmień obserwujących w mediach społecznościowych w klientów",
    "signWidDesc": "Zbuduj widget do rejestracji, tworzenia stron docelowych i generowania kuponów ze swojej witryny lub mediów społecznościowych",
    "qrWidT": "Inteligentny kod QR",
    "imgWidT": "Numer opt-in",
    "mobile": "Przenośny",
    "YESTitle": "TAK",
    "NOTitle": "NIE",
    "addDelRed": "Dodaj przekierowanie dostawy",
    "dlredNameT": "Nadaj nazwę przekierowaniu dostawy",
    "conf_text_req": "Tekst potwierdzający jest wymagany",
    "cFDesc": "Twórz dostosowane formularze, aby skutecznie zbierać konkretne opinie i informacje od klientów",
    "pre_review_questions_enabled": "Zanim poprosimy klientów o opinię, zadajmy im od 1 do 4 pytań.",
    "feel_free_edit": "Możesz edytować według potrzeb.",
    "option": "Opcja",
    "confFL": "To potwierdzenie zostanie wyświetlone po wysłaniu przez kontakt formularza rejestracyjnego w sieci Web",
    "confT": "Tekst potwierdzenia",
    "joinTitle": "Dołączyć",
    "mobValidL": "Proszę sprawdzić prawidłowy numer telefonu komórkowego",
    "mobRequiredL": "Wymagany jest numer telefonu komórkowego",

    "Business_location_label": "Lokalizacja Twojej firmy",
    "qr_code_selection_txt": "Wybór kodu QR",
    "choose_theme_txt": "Wybierz motyw",
    "edit_qr_details_txt": "Edytuj szczegóły QR",
    "save_qr_code_txt": "Zapisz kod QR",
    "text_color_label": "Kolor tekstu",
    "background_color_label": "Kolor tła",
    "print_txt": "Wydrukować",
    "customer_will_see_txt": "To właśnie zobaczy Twój klient. W następnym kroku wybierz kolor swojej marki.",
    "choose_your_brand_color_&_langauge_title": "Wybierz kolor i język swojej marki",
    "custom_redirect_link_label": "Niestandardowy link przekierowania",
    "redirect_user_to_menu": "Przekieruj użytkownika do menu",
    "language": "Język",
    "select_language_placeholder": "Wybierz język",
    "capitalize_location_name_txt": "Nazwa lokalizacji",
    "created_txt": "Stworzony",
    "swipe_reputation_management_txt": "Przesuń, aby zobaczyć, jak będzie wyglądać zarządzanie Twoją reputacją",
    "sent_txt": "Wysłano",
    "number_label": "Numer",
    "date_label": "Data",
    "gender_demographics_title": "Dane demograficzne dotyczące płci",

    "step1_preview_desc": "Nadaj nazwę swojemu formularzowi QR do rejestracji elektronicznej i dodaj go do swojej listy grupowej",
    "main_head": "Zdobywaj nowych klientów",
    "main_desc": "Zdobywaj klientów, umieszczając kod QR, który po zeskanowaniu przekieruje użytkowników do spersonalizowanego formularza rejestracyjnego.",
    "step1_description": "Zdobywaj nowych klientów od innych firm",
    "form_name_field_placeholder": "Rejestracja elektroniczna Restro",
    "last_step_title": "Gratulacje! Twój E-signup Widget został stworzony! 🎉",
    "last_step_desc": "Przesuń, aby zobaczyć, jak będzie wyglądał Twój formularz rejestracji elektronicznej",
    "events_step1_preview_desc": "Nadaj nazwę formularzowi QR swojego wydarzenia i dodaj go do listy grup",
    "events_main_head": "Zdobądź klientów z wydarzeń",
    "events_main_desc": "Zdobywaj klientów umieszczając na wydarzeniu kod QR, który po zeskanowaniu przekieruje użytkowników do spersonalizowanego formularza rejestracyjnego.",
    "events_step1_desc": "Zdobądź klientów z wydarzeń i przyprowadź ich do sklepu",
    "events_name": "Nazwa wydarzenia",
    "events_form_name_field_placeholder": "Restro Zabawa i Wydarzenie Jedzenia",
    "events_last_step_title": "Gratulacje! Twój formularz wydarzenia został utworzony! 🎉",
    "events_last_step_desc": "Przesuń, aby zobaczyć, jak będzie wyglądał formularz Twojego wydarzenia",
    "birthday_anniversary_step1_preview_desc": "Nadaj formularzowi QR nazwę, aby zbierać informacje o urodzinach lub rocznicach klientów i dodać go do listy grupowej",
    "birthday_anniversary_main_head": "Zbieraj urodziny lub rocznice",
    "birthday_anniversary_main_desc": "Po zeskanowaniu daty urodzin lub rocznicy klienta użytkownik jest przekierowywany do spersonalizowanego formularza rejestracyjnego.",
    "birthday_anniversary_step1_desc": "Zbieraj informacje o urodzinach i rocznicach klientów",
    "restro_text": "Restro",
    "birthday_anniversary_last_step_title": "Gratulacje! Otrzymaj formularz B'day & Anniversary od klientów! 🎉",
    "newspaper_ads_step1_preview_desc": "Nadaj nazwę swojemu formularzowi QR, aby pozyskać klientów z reklam w gazetach i dodać go do listy grup",
    "newspaper_ads_main_head": "Zdobądź klientów z reklam",
    "newspaper_ads_main_desc": "Zdobywaj klientów dzięki reklamom w gazetach. Po ich zeskanowaniu system przekieruje użytkowników do spersonalizowanego formularza rejestracyjnego.",
    "newspaper_ads_step1_desc": "Zdobądź klientów dzięki reklamie w gazecie lub reklamie zewnętrznej",
    "newspaper_ads_last_step_title": "Gratulacje! Utworzono formularz pozyskiwania klientów z reklam w gazetach! 🎉",
    "smart_pamphlets_step1_preview_desc": "Nadaj nazwę swojemu formularzowi QR, aby uzyskać dane klientów z ulotek i dodać go do listy grup",
    "smart_pamphlets_main_head": "Zdobywaj klientów dzięki ulotkom",
    "smart_pamphlets_main_desc": "Pobiera dane klientów z ulotek. Po zeskanowaniu użytkownicy są przekierowywani do spersonalizowanego formularza rejestracyjnego.",
    "smart_pamphlets_last_step_title": "Gratulacje! Utworzono formularz pobierania danych klienta z broszur! 🎉",
    "social_media_step1_preview_desc": "Nadaj nazwę swojemu formularzowi QR, aby pozyskać klientów z mediów społecznościowych i dodać go do listy grup",
    "social_media_main_desc": "Zdobywaj klientów z mediów społecznościowych. Po zeskanowaniu postów użytkownicy zostaną przekierowani do spersonalizowanego formularza rejestracyjnego.",
    "social_media_form_name_field_placeholder": "Oferta Festiwalu Restro - Post na Facebooku",
    "social_media_last_step_title": "Gratulacje! Utworzono formularz mediów społecznościowych dla klientów! 🎉",
    "claim_offer_text": "Wypełnij swoje dane i odbierz ofertę już teraz",
    "form_title_description": "Dodaj tytuł i opis formularza, aby Twoi klienci mogli wprowadzić szczegóły",
    "no_subscribed_clients": "Wybrana grupa klientów nie ma żadnych zapisanych klientów",
    "welcome_message_offer": "Wyślij wiadomość powitalną do swoich klientów po zarejestrowaniu się i wręcz im kupon promocyjny",
    "system_grp": "Grupy systemowe",
    "import_clients": "Importuj klientów",
    "delivery_redirection_name": "Nazwa przekierowania dostawy",
    "created_at": "Utworzono w",
    "provide_stamp_user": "Dostarcz użytkownikowi pieczątkę",
    "scan_qr_code": "Zeskanuj kod QR",
    "add_point": "Dodaj punkt",
    "total_points": "Łączna liczba punktów",
    "user_profile": "Profil użytkownika",
    "stamp_history": "Historia znaczków",
    "reward_txt": "Nagroda",
    "variant_placeholder": "Mały, średni, duży, itd.",
    "sent_coupon": "Wysłano kupon",
    "order_status": "Status zamówienia",
    "payment_status": "Status płatności",
    "order_currency": "Zamów walutę",
    "card_data": "Dane karty",
    "reward_expiring_on": "Nagroda wygasa",
    "reward_status": "Status nagrody",
    "reward_get_date": "Nagroda Otrzymaj datę",
    "reward_name": "Nazwa nagrody",
    "add_point_type": "Dodaj typ punktu",
    "restaurant_location": "Lokalizacja restauracji",
    "collect_points": "Zbieraj punkty",
    "rewards_expire": "Nagrody wygasają",
    "rewards_redeem": "Nagrody Zrealizuj",
    "total_rewards": "Łączne nagrody",
    "created_by": "Stworzone przez",
    "anniversary": "Rocznica",
    "remove_user_group_txt": "Usuń użytkownika z grupy",
    "delete_permanently_grp_msg": "Ten klient zostanie trwale usunięty z grupy.",
    "delete_multiple_permanently_grp_msg": "Ci klienci zostaną trwale usunięci z grupy.",
    "delete_multiple_client": "Ci klienci zostaną trwale usunięci.",
    "messages": "Wiadomości",
    "management": "Kierownictwo",
    "client": "Klient",
    "country": "Kraj",
    "state": "Państwo",
    "city": "Miasto",
    "zip_code": "Kod pocztowy",
    "1_sparkly_point_txt": "🎉 Zdobyłeś 1 błyszczący punkt! 🌟",
    "select_country_txt": "Wybierz kraj",
    "select_state": "Wybierz stan",
    "ls_thanks_for_join": "Dziękujemy za dołączenie do naszej karty lojalnościowej.🎁🎈",
    "month": "Miesiąc",
    "welcome_message": "Wyślij wiadomość powitalną do swoich klientów po ich rejestracji",
    "all_rewards_txt": "Wszystkie nagrody",
    "active_rewards": "Aktywne nagrody",
    "expired_and_redeemed": "Wygasło i zrealizowano",
    "redirect_3rd_party_delivery": "Przekieruj dostawę zewnętrzną do swojego sklepu lub na platformę do składania zamówień online",
    "search_or_start_new": "Wyszukaj lub rozpocznij nowy",
    "contacts": "Łączność",
    "chats": "Czat",
    "add_by_restaurant": "Dodaj według restauracji",
    "using_scan_code": "Korzystanie z kodu skanowania",
    "step": "Krok",
    "list_widget_text": "Lista widżetów",
    "swipe_free_wifi_txt": "Przesuń, aby uzyskać bezpłatne Wi-Fi",
    "create_widget_txt": "Utwórz widget",
    "redeem_award_msg": "Czy jesteś pewien? Czy chcesz odebrać tę nagrodę?",
    "on_collect_msg": "W trybie zbiorczym",
    "pause": "Pauza",
    "resume": "Wznawiać",
    "location_added": "dodano lokalizację",
    "sub_user_admin_permissions_txt": "Zezwól użytkownikowi podrzędnemu na dostęp do uprawnień administratora w Digital Signage",
    "unlist": "Usuń z listy",
    "cannot_delete_default_terms": "Nie można usunąć domyślnych warunków i postanowień",
    "something_went_wrong": "Coś poszło nie tak!",
    "card_download_success": "Karta pobrana pomyślnie",
    "permission_denied": "Odmowa dostępu!",
    "invalid_qr_code": "Proszę zeskanować prawidłowy kod QR",
    "select_customer": "Proszę wybrać klienta",
    "online": "ONLINE",
    "offline": "NIEZALEŻNIE",
    "no_data": "Ups, nie znaleziono danych!",
    "forever_free": "Na zawsze wolny",
};

export default pl