const he = {
    "rated_google_reviews": "מדורג 5.0 ב-Google ביקורות",
    "get_your_digital_menu": "קבל את התפריט הדיגיטלי שלך-",
    "instant_popup_menu": "תפריט מוקפץ מיידי ללא סריקת QR",
    "free_forever": "חינם לנצח",
    "the_future_is_here": "העתיד כבר כאן!",
    "upgrade_your_restaurant": "שדרג את המסעדה שלך עם תפריט דיגיטלי מדהים בעלות אפסית. משך עוד לקוחות החל מהיום.",
    "get_my_free_menu_now": "קבל את התפריט החינמי שלי עכשיו",
    "no_credit_card_required": "*אין צורך בכרטיס אשראי",
    "join_largest_marketplace": "הצטרפו לשוק המסעדות הגדול ביותר!",
    "what_will_you_get": "מה תקבלו",
    "free": "לְשַׁחְרֵר",
    "free_digital_menu": "תפריט דיגיטלי חינם 2D או 3D",
    "free_digital_menu_desc": "היפרדו מתפריטי נייר והדפסות חוזרות יקרות",
    "quick_and_easy_signup": "הרשמה מהירה וקלה",
    "communicate_with_customers_title": "צור קשר עם לקוחות בחינם!",
    "features1": [
        "חסוך בעלויות ההדפסה ותיהנה מעדכון תפריט מיידי בכל פעם שתזדקק להם.",
        "גש לתפריט הדיגיטלי שלך בכל מכשיר",
        "ידידותית לסביבה וללא טרחה"
    ],
    "features2": [
        "תן ללקוחות לחקור באופן וירטואלי את המנות שלך עם חזותיים תלת מימדיים מרהיבים.",
        "שפר את המעורבות על ידי מתן תצוגה מקדימה מציאותית לארוחה שלהם.",
        "התבלט עם חווית תפריט שמרגישה מפתה כמו טעם האוכל שלך."
    ],
    "features3": [
        "קבלו את פני האורחים מיד עם התפריט שלכם כשהם נכנסים למסעדה.",
        "שלח אוטומטית עדכונים על מבצעים ומבצעים לטלפונים שלהם.",
        "שפר את חווית האוכל עם גישה מהירה ללא מגע לתפריט שלך."
    ],
    "feature4": [
        "הדגש מבצעים עונתיים, מבצעים יומיים ומבצעים בלעדיים.",
        "הפנה את תשומת לב הלקוחות לפריטים הפופולריים ביותר שלך.",
        "צור דחיפות וריגוש עם תמונות ותיאורים מושכים את העין."
    ],
    "feature5": [
        "היו חלק מקהילה מקוונת עם אלפי מבקרים יומיים.",
        "שפר את הנראות והגע ללקוחות חדשים המחפשים באופן פעיל אפשרויות לסעודה.",
        "הבטיחו מקום ראשון מוקדם כדי למקסם את החשיפה שלכם ולמשוך עוד סועדים."
    ],
    "section3_heading": "למה לעבור לדיגיטל? כי זה בחינם וקל!",
    "section3_description": "פתח תכונות עוצמתיות שנועדו להגביר את המשיכה של המסעדה שלך.",
    "instant_menu": "תפריט מיידי",
    "instant_menu_notification": "פופ-אפ והתראה",
    "instant_menu_description": "קבלו את האורחים עם גישה מיידית לתפריט ברגע שהם נכנסים. שלח אוטומטית את התפריט והמבצעים העדכניים ביותר ישירות לטלפונים הניידים שלהם",
    "digital_menu": "תפריטים דיגיטליים",
    "at_zero_cost": "בעלות אפסית",
    "digital_menu_description1": "היפרדו מתפריטי נייר והדפסות חוזרות יקרות. עבור לתפריט דיגיטלי שתמיד מעודכן - והוא בחינם לנצח!",
    "digital_menu_description2": "עם התפריט הדיגיטלי שלנו, ההצעות שלך תמיד עדכניות ונגישות בכל מכשיר. חסוך כסף ופשט את עדכוני התפריט עם פתרון חלק ללא עלות.",
    "try_me_button": "נסה אותי",
    "try_3d_menu_title": "תפריטי תלת מימד",
    "try_3d_menu_title2": "בחינם - וואו!",
    "try_3d_menu_description1": "לגרום למנות שלך לקפוץ מהמסך. העלה תמונות תלת מימד ואפשר ללקוחות לטעום וירטואלית את האוכל שלך לפני שהם נכנסים.",
    "try_3d_menu_description2": "תמונות תלת מימד באיכות גבוהה יוצרות תצוגה מקדימה מעוררת תיאבון, משפרות את המשיכה ומעודדות הזמנות.",
    "try_now": "נסה עכשיו",
    "showcase_title": "הצג את שלך",
    "showcase_specials": "מבצעים",
    "showcase_specials_description1": "יש לכם עסקה מעוררת תיאבון או מבצע ללא תחרות? הדגש אותו בתפריט הדיגיטלי שלך וצייר אנשים רעבים נוספים.",
    "showcase_specials_description2": "הפוך את המבצעים לבלתי אפשריים לפספס, משך תשומת לב עם חזותיים נועזים ותיאורים מפתים.",
    "marketplace": "MarketPlace",
    "marketplace_description": "אנחנו מקימים שוק עם יותר מ-2,000 מבקרים יומיים. קפוץ לסיפון עכשיו, ותהיו הראשונים בתור כשאנחנו מושקים. אתה יכול להגיע ללקוחות חדשים המחפשים באופן פעיל אפשרויות לסעודה. הבטיחו את מקומכם מוקדם כדי למקסם את החשיפה!",
    "see_other_products": "ראה מוצרים אחרים",
    "about_us": "עלינו",
    "why_serving_free": "למה אנחנו מגישים את זה",
    "serving_free_highlight": "לְשַׁחְרֵר",
    "about_desc": "אנחנו אנשים מקומיים ממש כאן בדאלאס, מחויבים לעזור למסעדות כמו שלך לשגשג.",
    "about_tool_free": "אנו מציעים את הכלי העוצמתי הזה בחינם לחלוטין מכיוון שאנו מאמינים בתמיכה בקהילה שלנו.",
    "no_catch": "בלי תפיסה, בלי צחוק. אנחנו יודעים שכשאתה מצליח, הקהילה שלנו מתחזקת. 😊",
    "understanding_challenges": "אנו מבינים את האתגרים של ניהול עסק, וזו הסיבה שאנו מחויבים לספק כלים שהופכים את הפעילות שלך לחלקה יותר ולטווח הגעה רחב יותר.",
    "community_growth": "ככל שאתה גדל, הקהילה שלנו גדלה איתך, ויוצרת אפקט אדווה של הצלחה ותמיכה. יחד, בואו נעלה את חווית האוכל לכולם.",
    "how_it_works": "איך זה עובד",
    "three_simple_steps": "שלושה צעדים פשוטים ל",
    "get_started_highlight": "התחל",
    "no_tech_skills_needed": "אין צורך בכישורים טכניים. אם אתה יכול להעיף המבורגר, אתה יכול לעשות את זה!",
    "step1_title": "הצג את הפרומו שלך בחינם!",
    "step1_desc": "התחל מהר יותר ממה שאתה יכול לומר \"הסדר!\" פשוט מלא טופס פשוט.",
    "step2_title": "התאם אישית את התפריט שלך",
    "step2_desc": "הוסף את המנות שלך, העלה תמונות אפילו בתלת מימד והדגש את המבצעים שלך.",
    "step3_title": "שתף וגדל",
    "step3_desc": "שתף את התפריט הדיגיטלי שלך באמצעות QRs, מדיה חברתית או אתר האינטרנט שלך.",
    "testimonials": "המלצות",
    "testimonials_section_title": "אל תקח רק את המילה שלנו",
    "testimonials_section_desc": "ראה מה אומרים בעלי מסעדות אחרים בדאלאס.",
    "faq_section_title": "יש לך שאלות? יש לנו תשובות",
    "faq_section_desc": "כל מה שאתה צריך לדעת לפני שמתחילים.",
    "faqs": "שאלות נפוצות",
    "faq_answers": [
        {
            "que": "האם זה באמת בחינם לנצח?",
            "ans": "בְּהֶחלֵט! ללא עמלות נסתרות, ללא כרטיסי אשראי, ללא התחייבויות."
        },
        {
            "que": "האם אני צריך ידע טכני כלשהו?",
            "ans": "אם אתה יכול לשלוח אימייל, אתה יכול לעשות זאת. זה כל כך פשוט."
        },
        {
            "que": "למה אתה מציע את זה בחינם?",
            "ans": "אנחנו מאמינים קודם כל בנתינה. בהמשך הקו, אולי יהיו לנו שירותים אחרים שתאהבו - אבל זה תלוי לחלוטין בך."
        },
        {
            "que": "מה הקטע עם השוק?",
            "ans": "אנחנו מבשלים את זה! ברגע שיהיו לנו מספיק מסעדות על הסיפון, נשיק את השוק שלך כדי להביא לך עוד יותר חשיפה."
        }
    ],
    "testimonials_content1": "המעבר לתפריט דיגיטלי היה הצעד הטוב ביותר שעשינו! הלקוחות שלנו אוהבים את הנוחות והמראה המעודכן!",
    "testimonials_name1": "מוריס ג'ורג'",
    "testimonials_restaurant_name1": "מסעדת האחוזה",
    "testimonials_content2": "התפריט הדיגיטלי החדש שלנו מקל על הגלישה לאורחים, וזה באמת הגביר את המעורבות!",
    "testimonials_name2": "אמיליה ג'ונסון",
    "testimonials_restaurant_name2": "אורבן אוכל",
    "testimonials_content3": "האורחים נדהמים מהתמונות התלת-ממדיות - זה עזר לנו להתבלט ושיפר את חווית האוכל שלהם!",
    "testimonials_name3": "דייויס גרין",
    "testimonials_restaurant_name3": "מוקד העיר",
    "ready_to_boost_your_restaurant": "מוכן לשפר את המסעדה שלך?",
    "ready_to_boost_desc": "אל תישאר מאחור. הצטרפו למהפכה הדיגיטלית עוד היום!",
    "yes_i_want_my_free_digital_menu": "כן, אני רוצה את התפריט הדיגיטלי החינמי שלי",
    "480_plus": "480+",
    "people_already_joined": "אנשים כבר הצטרפו",
    "menu_online_home": "תפריט מקוון דף הבית",
    "welcome": "קַבָּלַת פָּנִים",
    "description_other": "בואו להגביר את שביעות רצון הלקוחות שלכם!",
    "benefits": "הטבות",
    "greeting_text": "ברוכים הבאים 👋",
    "all_in_one_features": "🚀 תכונות הכל-ב-אחד להעלאת המסעדה שלך",
    "menu_online_menu_name": "תפריט מקוון",
    "menu_online_description": "הפוך את התפריט שלך לדיגיטלי לקבלת גישה מקוונת קלה ללקוחות.",
    "menu_online_benefits": "שפר את נוחות הלקוחות והגבר את המעורבות על ידי כך שתאפשר להם לצפות בתפריט שלך בכל זמן ובכל מקום.",
    "ds_name": "שילוט דיגיטלי",
    "ds_description": "הצגת הצעות ומבצעים לראווה במסכים דינמיים בחנות.",
    "ds_benefits": "למשוך את תשומת הלב של הלקוחות עם חזותיים מרתקים, קידום מכירות גבוהות והצעות בזמן אמת ללא מאמץ.",
    "loyalty_punch_name": "כרטיס ניקוב נאמנות",
    "loyalty_punch_description": "תגמל לקוחות חוזרים עם תוכנית נאמנות דיגיטלית.",
    "loyalty_punch_benefits": "עודד שימור לקוחות והגדל את המכירות על ידי תמריץ נאמנות עם תגמולים שניתנים למימוש עבור ביקורים תכופים.",
    "marketing_menu_name": "שיווק",
    "marketing_description": "להגיע ליותר לקוחות עם קמפיינים שיווקיים דיגיטליים ממוקדים.",
    "marketing_benefits": "הרחב את טווח ההגעה של המסעדה שלך והגבר תנועת גולשים עם מבצעים מותאמים אישית המותאמים למשוך לקוחות חדשים וחוזרים.",
    "get_more_reviews_menu_name": "קבל עוד ביקורות",
    "get_more_reviews_description": "עודדו לקוחות מרוצים להשאיר ביקורות חיוביות באינטרנט.",
    "get_more_reviews_benefits": "שפר את האמינות של המסעדה שלך ומשוך סועדים חדשים על ידי שיפור המוניטין המקוון שלך עם ביקורות חיוביות יותר.",
    "guest_smart_wifi_menu_name": "Wifi חכם לאורחים",
    "guest_smart_wifi_description": "הצע Wi-Fi בחינם כדי ללכוד נתוני אורחים לשיווק.",
    "guest_smart_wifi_benefits": "הגדל את שביעות רצון הלקוחות תוך איסוף נתוני לקוחות יקרי ערך כדי להתאים אישית מבצעים עתידיים.",
    "reservation_menu_name": "הַזמָנָה",
    "reservation_description": "אפשר ללקוחות להזמין שולחנות באינטרנט בקלות.",
    "reservation_benefits": "שפר את חווית האוכל על ידי צמצום זמני ההמתנה, וייעל את ההזמנות כדי לנהל טוב יותר את מקומות הישיבה.",
    "pos_integration_menu_name": "שילוב POS",
    "pos_integration_description": "שלב את המערכת שלנו עם מערכת הקופה שלך.",
    "pos_integration_benefits": "פשט את עיבוד ההזמנות והפחת שגיאות על ידי שמירת המערכות המקוונות והחנות שלך מסונכרנות.",
    "online_ordering_menu_name": "הזמנה מקוונת",
    "online_ordering_description": "אפשר ללקוחות לבצע הזמנות ישירות מהאתר או האפליקציה שלך.",
    "online_ordering_benefits": "הגדל את המכירות והפחת את ההסתמכות על פלטפורמות של צד שלישי על ידי הצעת אפשרות הזמנה ישירה החוסכת עמלות.",
    "pro_label": "מִקצוֹעָן",
    "coming_soon": "בקרוב",
    "locked_label": "נָעוּל",
    "forever_label": "לָנֶצַח",
    "new_label": "חָדָשׁ",
    "other_products_page_title": "מוצרים אחרים | תפריט מקוון",
    "other_products_description": "תפריט מדיניות פרטיות מקוונת",
    "other_products_header_title": "מוצרים אחרים",
    "privacy_policy": {
        "title": "מדיניות פרטיות",
        "lastUpdated": "עדכון אחרון: 01 ביולי, 2024",
        "introduction": {
            "header": "מדיניות פרטיות ללקוחות חדשים וקיימים",
            "paragraph1": "נקודות המפתח הבאות (כולל הפניות לסעיפים) מסופקות לנוחיותך בלבד ואינן מחליפות את התנאים וההתניות המלאים. באחריותך לקרוא את התנאים וההגבלות הללו לפני השימוש בשירות MENUONLINE INC.",
            "paragraph2": "השימוש ב- Menuonline פירושו שאתה מסכים לתנאים וההגבלות ומייצג ומתחייב שאתה מורשה על ידי הארגון או החברה שלך לעשות זאת. (ראה סעיף 3 \"איך זה עובד\")",
            "paragraph3": "Menuonline Inc הוא שירות שיווק מבוסס הרשאות. שליחה מכוונת של \"דואר זבל\" או הפרת התנאים וההגבלות תגרום לסגירת חשבונך. (ראה סעיף 16 \"שימוש מקובל בשירות\")",
            "paragraph4": "הנתונים שאתה מארח ב- Menuonline Inc, כגון רשומות אנשי קשר, תוכן דואר אלקטרוני ומידע שאתה אוסף דרך Menuonline Inc. שייכים לך. (ראה ס' 14 \"מה בבעלותך?\").",
            "paragraph5": "תוכנת Menuonline Inc והתוכן באתר שלנו שייכים לנו. (ראה סעיף 13 \"מה בבעלותנו?\") מלבד המשתמשים שלך, הנתונים או התוכן הלא מפורסם שאתה מארח אצל Menuonline Inc ניתנים לצפייה רק ​​על ידי עובדי ושותפים של Menuonline Inc שחתמו על הסכמי סודיות. (ראה סעיף 14 \"מה בבעלותך?\")",
            "paragraph6": "כל המידע המאפשר זיהוי אישי (PII) מוחזק רק בשרתים הממוקמים בקנדה. (ראה ס' 14 \"מה בבעלותך?\").",
            "paragraph7": "השירות Menuonline Inc כפוף לחוקי קנדה ומחוז אונטריו. (ראה סעיף 24 \"חוק החל\")",
            "paragraph8": "כל בקשות לנתונים או לתוכן שלך על ידי יחידים, משטרה או סוכנויות רגולטוריות אחרות מתוך קנדה או מחוץ לקנדה, תמיד יופנו אליך. החריג היחיד יהיה אם זה מעמיד אותנו בהפרה של החוק הקנדי או אונטריו; עם זאת, בכל המקרים אנו נתנגד בתוקף לכל שחרור הנתונים שלך על ידי כל אחד מלבדך. (ראה ס' 14 \"מה בבעלותך?\").",
            "paragraph9": "האבטחה של Menuonline Inc טובה רק כמו הניהול שלך של שמות משתמש וסיסמאות. אנחנו לא גובים תשלום עבור חשבונות משתמש של Menuonline Inc, אז צור אחד נפרד עבור כל משתמש ושמור את הסיסמאות בסוד. אתה אחראי לחשבון שלך. (ראה סעיף 3 \"איך זה עובד\")",
            "paragraph10": "אתה יכול לבטל את חשבונך בכל עת. אנו לא מספקים החזרים על כל אשראי שלא נוצל אם תבטל. (ראה סעיף 3 \"איך זה עובד\")"
        },
        "termsAndConditions": {
            "title": "תנאים והגבלות של MENUONLINE INC",
            "lastUpdate": "עדכון אחרון: 14 בינואר 2020",
            "paragraph1": "אנא קרא את התנאים וההגבלות של השירות (להלן: \"התנאים\") בעיון לפני השימוש באתר אינטרנט זה (\"האתר\"). תנאים אלה פוטרים את Menuonline Inc. (\"Menuonline Inc\" \"אנחנו\", או \"אנחנו\") ואחרים מאחריות ו\/או מגבילים את החבות שלנו ושלהם ומכילים הוראות חשובות אחרות החלות על השימוש שלך באתר זה. על ידי ביקור או שימוש באתר זה או בשירות, אתה מסכים בשמך, ובשם כל ארגון שבשמו אתה רשאי לפעול (המכונה ביחד \"אתה\"), לקבל ולציית לתנאים אלה עבור כל שימוש וכל ביקור באתר זה."
        },
        "services": {
            "title": "השירותים שלנו.",
            "paragraph": "השירותים שאנו מציעים דרך האתר שירות המאפשר לך ליצור, לשלוח ולנהל הודעות דואר אלקטרוני (\"מיילים\") לנמענים לבחירתך (\"השירות\")."
        },
        "license": {
            "title": "רִשָׁיוֹן.",
            "paragraph": "בכפוף לתנאים אלה, Menuonline Inc מעניקה לך בזאת רישיון לא בלעדי, מוגבל, שאינו ניתן להעברה במהלך התקופה לצפות באתר ולהשתמש בשירות כפי שמתיר תכונות השירות. אנו שומרים לעצמנו את כל הזכויות שאינן מוענקות במפורש כאן בשירות ובתוכן Menuonline Inc (כפי שמוגדר להלן)."
        },
        "emailingContent": {
            "title": "שליחת תוכן בדוא\"ל.",
            "paragraph": "בעת אינטראקציה עם Menuonline Inc באמצעות אתר זה או השירות, אתה מסכים לספק מידע אמיתי ומדויק. יתרה מכך, אתה מבין כי מתן מידע סודי באמצעות דואר אלקטרוני הוא מנהג לא בטוח. אתה מבין שאתה האחראי הבלעדי לכל מה שאתה שולח לכל מי שמשתמש בשירות שלנו."
        },
        "support": {
            "title": "תְמִיכָה.",
            "paragraph": "אנו מספקים לך תמיכה כדי לעזור לך להיות עצמאי עם השירות. אנו מספקים לך תמיכה בצורת תיעוד וסרטונים באתר, מערכת כרטיסי התמיכה של Menuonline Inc ובטלפון. אתה יכול להגיע אלינו על ידי לחיצה על לשונית \"תמיכה\" בראש האתר. אין תשלום עבור תמיכת מוצר; עם זאת, אנו שומרים לעצמנו את הזכות להפסיק את התמיכה בחשבונות לקוח ספציפיים לפי שיקול דעתנו."
        }
    },
    "terms_condition": {
        "title": "תנאים והגבלות | תפריט מקוון",
        "header": "תנאים והגבלות עבור לקוחות חדשים וקיימים"
    },
    "privacy_policy_title": "מדיניות פרטית",
    "privacy_policy_effective_date": "בתוקף החל מ-16 בינואר 2020",
    "privacy_policy_general_title": "מדיניות פרטית כללית",
    "privacy_policy_intro": "menuonline.com (Menuonline ו\"אנחנו\") מבינים את החשיבות של ההגנה על פרטיות המידע האישי של אנשים ואת החשיבות של אבטחת מידע סודי מכל הסוגים. יצרנו מדיניות פרטית זו (\"המדיניות\") כדי להפגין את המחויבות שלנו להגן על \"פרטיותך\". מדיניות פרטית זו מסבירה:",
    "privacy_policy_points": [
        "איזה מידע אישי אנו אוספים ומדוע אנו אוספים אותו;",
        "כיצד אנו משתמשים במידע האישי שלך;",
        "עם מי אנו חולקים את המידע האישי שלך; ו",
        "האפשרויות שיש לך לגשת, לעדכן ולהסיר את המידע האישי שלך מהשירות."
    ],
    "privacy_policy_pipeda": "Menuonline מנהלת את איסוף כל המידע בהתאם לחוק הגנת מידע אישי ומסמכים אלקטרוניים (PIPEDA) של קנדה.",
    "privacy_policy_update_notice": "לידיעתך, Menuonline שומרת לעצמה את הזכות לעדכן או לשנות מדיניות פרטית זו בכל עת. אנו נודיע לך על כל שינוי על ידי פרסום מדיניות מעודכנת באתר זה או כפי שתהיה זמינה באמצעות השירותים. הגרסה העדכנית ביותר של מדיניות זו תסדיר את השימוש של mycircle במידע האישי של המשתמשים. על ידי המשך הגישה לאתר האינטרנט של mycircle או על ידי המשך השימוש בשירותיה לאחר ביצוע שינויים במדיניות פרטית זו, אתה מסכים להיות כפוף לתנאים המתוקנים.",
    "privacy_policy_consent_title": "הסכמה למידע אישי",
    "privacy_policy_consent_text": "על ידי מתן המידע האישי שלך ל- Menuonline דרך האתר שלנו או כל אחד מהטפסים שלנו, אתה מסכים לאיסוף, שימוש וחשיפה של מידע זה בהתאם למדיניות זו או למטרות שזוהו לך בזמן שמסרת את המידע האישי. כל שימוש משני במידע האישי יעובד רק בהסכמתך המפורשת, או שתינתן לך הזדמנות לבטל את הסכמתך.",
    "privacy_policy_opt_out_text": "יש לך הזדמנות לבטל את הסכמתך או לבטל את הסכמתך בכל עת. אם ברצונך לבטל את הסכמתך או לבטל את הסכמתך, תוכל ללחוץ על קישור העדפות התקשורת בתחתית כל דוא\"ל שתקבל מאיתנו.",
    "privacy_policy_info_collection_title": "מידע שאנו אוספים",
    "privacy_policy_info_collection_text": "Menuonline אוספת ומעבדת מידע אישי בהתאם למדיניות זו. לא נמכור, נשתף או נשכיר מידע זה לאחרים, למעט כפי שמופיע במדיניות זו. Menuonline אוספת מידע אישי למטרות מתן השירותים או המוצרים שלנו לך.",
    "privacy_policy_info_types_text": "סוגי המידע ש- Menuonline אוספת כוללים:",
    "privacy_policy_account_holders_title": "בעלי חשבון Menuonline",
    "privacy_policy_registration_info": "פרטי רישום: אם אתה נרשם לשימוש בשירותים, אתה צריך חשבון Menuonline כדי להפוך לבעל חשבון (\"בעל חשבון\"). כאשר אתה נרשם לחשבון, תתבקש לספק מידע בסיסי מסוים, כגון שמך, כתובת דואר אלקטרוני, שם משתמש, סיסמה, שם חברה, עיסוק, מיקום ומספר טלפון. סיסמאות מוצפנות - צוות Menuonline אינו מסוגל להציג או לאחזר סיסמאות.",
    "privacy_policy_billing_info": "פרטי חיוב: אם אתה נרשם לשירותים שלנו, תצטרך לספק לנו פרטי תשלום וחיוב.",
    "privacy_policy_cookies_info": "קובצי Cookie: אנו משתמשים בקובצי Cookie כדי לעקוב אחר נתוני מבקרים, כגון מקור הביקורים באתר ומספר הביקורים באתר האינטרנט שלנו.",
    "privacy_policy_ip_info": "כתובות IP: כתובות IP נאספות עליך כחלק מהשימוש שלך בשירותים (למשל לצורך הקלטת מיקום ברמת המדינה וכתובות IP המשמשות בעת השימוש בשירותים שלנו).",
    "privacy_policy_your_contacts_title": "אנשי הקשר שלך",
    "privacy_policy_distribution_list": "רשימת תפוצה: כחלק מהשימוש שלך בשירותים שלנו, אתה עשוי לספק לנו, או שאנו עשויים לאסוף כחלק מהשירותים, מידע אישי על אנשי הקשר שלך (\"אנשי קשר\"). מידע זה כולל כתובות דוא\"ל ויכול לכלול מידע מזהה אחר כגון שם, שם חברה או מידע אחר שאתה מספק ל-Menuonline בזמן השימוש בשירותים. עליך לוודא שאתה מקבל את ההסכמה המתאימה מאנשי הקשר שלך כדי לאפשר את עיבוד המידע שלהם על ידינו.",
    "privacy_policy_billing_info_contacts": "פרטי חיוב: אם אתה נרשם לשירותים שלנו, תצטרך לספק לנו פרטי תשלום וחיוב.",
    "privacy_policy_cookies_contacts": "קובצי Cookie: Menuonline תשתמש רק בקובצי Cookie ובטכנולוגיות מעקב דומות כדי לאסוף ולהשתמש במידע אישי על אנשי הקשר שלך אם הוספת את קוד המעקב של Menuonline לאתר האינטרנט שלך. לכל דפי נחיתה המתארחים על ידי Menuonline יהיה אוטומטית קוד מעקב.",
    "privacy_policy_ip_contacts": "כתובות IP: כתובות IP נאספות על אנשי הקשר שלך כחלק מהשירותים (למשל לצורך הקלטת מיקום ברמת המדינה וכתובות ה-IP המשמשות בעת שליחת טופס ומתן הסכמה).",
    "privacy_policy_info_about_contacts_title": "מידע שאנו אוספים על אנשי הקשר שלך",
    "privacy_policy_info_about_contacts_notice": "*סעיף זה חל רק על מידע שנאסף, נעשה בו שימוש או נחשף על ידי Menuonline אודות אנשי הקשר של בעל חשבון למטרות מתן השירותים*",
    "privacy_policy_info_about_contacts_desc": "Menuonline יאסוף, ישתמש או תחשוף מידע אישי על אנשי קשר רק אם בעל החשבון נרשם לשירותים מסוימים שבהם הוא מספק לנו רשימת תפוצה. בעלי החשבון הם האחראים הבלעדיים לוודא שהם צייתו לכל החוקים החלים בעת מתן מידע אישי על אנשי הקשר שלהם ל-Menuonline באמצעות השירותים.",
    "privacy_policy_info_auto_collect_title": "מידע Menuonline אוסף אוטומטית",
    "privacy_policy_info_auto_collect_desc1": "כאשר אנשי הקשר של בעל החשבון עוסקים במסע פרסום בדוא\"ל שהתקבל מבעל חשבון, או מקיימים אינטראקציה עם קישור לאתר האינטרנט המחובר של בעל החשבון, Menuonline עשויה לאסוף מידע על המכשיר של איש קשר כזה והאינטראקציה עם האימייל באמצעות שימוש בעוגיות. מידע זה נאסף רק כדי לספק את השירותים ולספק תובנה לבעל החשבון.",
    "privacy_policy_info_auto_collect_desc2": "מידע שאנו אוספים על המכשיר של איש קשר והיישומים שבהם משתמש איש הקשר כדי לגשת למיילים שנשלחו מבעל החשבון, באמצעות השירותים שלנו, עשוי לכלול את כתובת ה-IP של איש הקשר, מערכת ההפעלה, מזהה הדפדפן ומידע קשור אחר על המערכת והחיבור של איש הקשר.",
    "privacy_policy_info_auto_collect_desc3": "מידע שאנו אוספים על האינטראקציה של Contact עם מיילים שנשלחים דרך השירותים עשויים לכלול תאריכים ושעות שבהם Contact ניגש לדוא\"ל ופעילויות גלישה, ומידע לגבי ביצועי השירותים, כגון יכולת המסירה של מיילים ותקשורת אחרת שנשלחה ל Contact על ידי בעל חשבון באמצעות השירותים.",
    "privacy_policy_info_auto_collect_desc4": "שים לב, Menuonline לא תיצור קשר עם איש מאנשי הקשר שלך, אלא אם כן נדרש על פי החוק החל.",
    "privacy_policy_why_we_collect_title": "מדוע אנו אוספים את המידע שלך",
    "privacy_policy_why_we_collect_desc": "Menuonline אוספת מידע למטרות הבאות:",
    "privacy_policy_why_collect_1": "לספק לבעלי חשבון Menuonline את השירותים שהם מנויים עליהם, לרבות שימוש במידע של בעל החשבון עבור השירותים;",
    "privacy_policy_why_collect_2": "לניהול וניהול חשבונות, כמו גם גישה לאתר האינטרנט של mycircle;",
    "privacy_policy_why_collect_3": "אם הסכמת לקבל חומרים שיווקיים ופרסומיים, ליידע אותך על מוצרים ושירותים אחרים המוצעים על ידי Menuonline; ו",
    "privacy_policy_why_collect_4": "להגיב לתקשורת של בעל החשבון או לשלוח מידע שהתבקש על ידך. זה עשוי לכלול, אך לא מוגבל ל:",
    "privacy_policy_why_collect_5": "הצעות מיוחדות ועדכונים;",
    "privacy_policy_why_collect_6": "ניוזלטר; ו",
    "privacy_policy_why_collect_7": "הודעות שירות.",
    "privacy_policy_opt_out_desc": "אתה יכול, בכל עת, לבחור לבטל את הסכמתך לקבלת הודעות על ידי לחיצה על כפתור ביטול הרישום בתחתית כל אימייל שנשלח אליך מ- Menuonline.",
    "privacy_policy_use_disclosure_title": "שימוש וחשיפה של מידע אישי",
    "privacy_policy_use_disclosure_desc1": "מידע אישי הנמסר ל-Menuonline, לרבות דרך אתר האינטרנט של Menuonline או טופס שנקבע, נשמר בסודיות מוחלטת, אלא אם נחשף בהתאם להסכמה מפורשת של בעל חשבון או לדרישות משפטיות. בכל מקרה, כל מידע אישי הנמסר בכל פלטפורמת \"Menuonline\" על ידי בעלי החשבון הוא וולונטרי: כל מידע אישי הנמסר על ידי בעל חשבון נמסר על פי שיקול דעתו של בעל החשבון. שים לב שביטול הסכמתך עלול לגרום לכך שלא תוכל לגשת או להשתמש בשירותים מסוימים של Menuonline.",
    "privacy_policy_use_disclosure_desc2": "Menuonline משתמשת בצדדים שלישיים ובספקי שירותים חיצוניים לגבי האתר והשירותים שלה. המידע האישי של בעל החשבון עשוי להיות משותף עם צדדים שלישיים וספקים כאלה. אנו עשויים גם לספק נתונים סטטיסטיים מצטברים לגבי בעלי חשבונות, מכירות, דפוסי תנועה ומידע קשור לצדדים שלישיים בעלי מוניטין. Menuonline דורש שכל ספק צד שלישי שיש לו גישה למידע אישי יהיה מחויב בהתחייבויות העולה בקנה אחד עם מדיניות פרטית זו ושספקים כאלה יאספו, ישתמשו או יחשפו רק את המידע האישי של בעל החשבון למטרות אספקת שירותי Menuonline.",
    "privacy_policy_use_disclosure_desc3": "מידע אישי שיסופק על ידי בעלי חשבונות לא יימכר, ישותף או יושכר לארגונים חיצוניים אלא אם כן בהתאם להסכמתך המשתמעת או המפורשת. Menuonline שומרת לעצמה את הזכות ליצור קשר עם כל בעל חשבון על מנת להעביר מידע, להגיב לשאלות או לספק הודעה על כל שינוי בתוכנית שלנו או במדיניות שלנו. שים לב, Menuonline לא תיצור קשר עם איש מאנשי הקשר שלך, אלא אם כן נדרש על פי החוק החל.",
    "privacy_policy_use_disclosure_desc4": "Menuonline תחשוף מידע אישי רק אם תינתן הסכמה על ידי האדם הרלוונטי או:",
    "privacy_policy_disclosure_list_1": "כאשר מותר או נדרש על פי חוק, כגון בתגובה לזימון או הליך משפטי אחר;",
    "privacy_policy_disclosure_list_2": "למקבל העברה של Menuonline כולה או חלקה;",
    "privacy_policy_disclosure_list_3": "לספקי השירותים של Menuonline בהתאם למדיניות זו; אוֹ",
    "privacy_policy_disclosure_list_4": "לעמוד בהתחייבויות משפטיות, לרבות אך לא רק חובות דיווח רגולטוריות.",
    "privacy_policy_service_providers_title": "Menuonline משתמש בצדדים השלישיים ובספקי השירות הבאים (המידע האישי של אנשי הקשר שלך אינו נחשף לצדדים שלישיים \"כאלה\"):",
    "privacy_policy_service_provider_1": "אנו משתמשים ב-ManyChat כדי לספק שירותי צ'אט באתר למכירות ותמיכה, כמו גם הודעות דוא\"ל מעקב על שיחות אלו.",
    "privacy_policy_service_provider_2": "אנו משתמשים ב-AskNicely כדי לסקור את הלקוחות שלנו במטרה לשאול על איכות השירות והתמיכה שהם מקבלים.",
    "privacy_policy_service_provider_3": "אנו משתמשים ב-join.me לתזמן פגישות עם לקוחות ולקוחות פוטנציאליים, שיחות תמיכה והדגמות תוכנה.",
    "privacy_policy_service_provider_4": "אנו משתמשים ב-Google Analytics כדי לעקוב אחר פעילות אנונימית באתר ולמדוד את דפוסי השימוש באתר שלנו.",
    "privacy_policy_breach_notification_title": "הודעה על הפרה",
    "privacy_policy_breach_notification_desc": "Menuonline תציית, ותספק לבעלי החשבון סיוע סביר בציות לחוקים החלים ביחס לכל שימוש, גישה או חשיפה בלתי מורשית של מידע אישי.",
    "privacy_policy_rights_access_title": "הזכויות והגישה שלך",
    "privacy_policy_rights_access_desc": "יש לך זכות גישה למידע אישי ש-Menuonline מעבד עליך. אתה יכול לבקש מאיתנו מידע על:",
    "privacy_policy_rights_access_list_1": "המידע האישי שאנו מחזיקים עליך",
    "privacy_policy_rights_access_list_2": "קטגוריות המידע האישי הנוגעות בדבר",
    "privacy_policy_rights_access_list_3": "מטרות העיבוד",
    "privacy_policy_rights_access_list_4": "פרטים למי המידע האישי שלך נחשף\/יחשף",
    "privacy_policy_rights_access_list_5": "כמה זמן אנחנו שומרים את המידע האישי שלך",
    "privacy_policy_rights_access_list_6": "אם לא אספנו את המידע ישירות ממך, מידע על המקור",
    "privacy_policy_rights_access_list_7": "כיצד להגיש תלונה לרשות הפיקוח הנכונה",
    "privacy_policy_rights_access_list_8": "תוכל גם לבקש מאיתנו את הדברים הבאים:",
    "privacy_policy_rights_access_list_9": "שנעדכן כל מידע לא שלם או לא מדויק עליך",
    "privacy_policy_rights_access_list_10": "בקש שנמחק את המידע האישי שלך בהתאם לחוקים החלים",
    "privacy_policy_rights_access_contact": "אתה יכול לבקש שנפעל לזכויותיך על ידי יצירת קשר בכתובת 2851 Dufferin Street, Toronto, ON, קנדה, או על ידי שליחת אימייל אלינו בכתובת {link}.",
    "privacy_policy_accountability_title": "אחריות",
    "privacy_policy_accountability_desc": "Menuonline מחויבת להיות אחראית על המידע האישי והסודי שאתה מספק לנו. לפרטים ליצירת קשר, עיין בסוף מדיניות זו.",
    "privacy_policy_links_other_sites_title": "קישורים לאתרים אחרים",
    "privacy_policy_links_other_sites_desc1": "האתר של Menuonline עשוי להכיל קישורים לאתרים אחרים. אנא שימו לב כי Menuonline אינה אחראית לנוהלי הפרטיות של אתרים אחרים. אנו מעודדים את בעלי החשבון שלנו להיות מודעים לכך שכאשר הם עוזבים את האתר שלנו, עליהם לקרוא ביסודיות את הצהרות המדיניות הפרטיות של כל אתר ואתר שאוסף מידע אישי מזהה.",
    "privacy_policy_links_other_sites_desc2": "מדיניות פרטית זו חלה אך ורק על מידע שנאסף על ידינו.",
    "privacy_policy_links_other_sites_desc3": "אם אתה משתמש בקישור כדי לעבור מאתר Menuonline לרכישת מוצרים או שירותים, אתה משלים עסקת רכישה על פי הכללים והתקנות של חברת הספק, לא Menuonline.",
    "privacy_policy_cookies_title": "קובצי Cookie של מדיניות פרטית",
    "privacy_policy_cookies_desc1": "האתר והשירותים של Menuonline משתמשים בקובצי Cookie כדי לאסוף ולאחסן מידע מסוים בהתאם למדיניות זו. 'עוגייה' היא קובץ טקסט קטן שנשלח מאתר אינטרנט ומאוחסן במחשב המשתמש על ידי דפדפן האינטרנט של המשתמש. בביקור באתר המשתמש בקובצי Cookie, קובץ Cookie מוריד למחשב או למכשיר הנייד שלך. בפעם הבאה שתבקר באתר זה, המכשיר שלך יזכור מידע שימושי כגון העדפות, דפים שבהם ביקרת או אפשרויות התחברות.",
    "privacy_policy_cookies_desc2": "קובצי Cookie נמצאים בשימוש נרחב כדי להוסיף פונקציונליות לאתרים, או כדי להבטיח שהם עובדים ביעילות רבה יותר. האתר שלנו מסתמך על קובצי Cookie כדי לייעל את חווית המשתמש ולהבטיח ששירותי האתרים פועלים כראוי.",
    "privacy_policy_cookies_desc3": "רוב דפדפני האינטרנט מאפשרים שליטה מסוימת להגביל או לחסום קובצי Cookie דרך הגדרות הדפדפן, אולם אם תבטל קובצי Cookie, ייתכן שתגלה שהדבר משפיע על יכולתך להשתמש בחלקים מסוימים באתר או בשירותים שלנו.",
    "privacy_policy_cookies_analytical_purpose": "<strong> מטרות אנליטיות: <\/strong> אנו משתמשים בקובצי Cookie כדי לנתח פעילות של בעל החשבון על מנת לשפר את האתר שלנו. לדוגמה, אנו יכולים להשתמש בקובצי Cookie כדי להסתכל על דפוסים מצטברים כמו התכונות שבהן משתמשים בעלי חשבון. אנו יכולים להשתמש בניתוח כזה כדי לקבל תובנות לגבי איך לשפר את הפונקציונליות וחווית המשתמש של האתר שלנו.",
    "privacy_policy_cookies_preferences_user_experience": "<strong> ההעדפות שלך וחווית המשתמש: <\/strong> אנו משתמשים בקובצי Cookie כדי לאסוף מידע מסוים על מבקרים ובעלי חשבון, כגון סוג דפדפן, שרת, העדפת שפה והגדרת מדינה, על מנת לאחסן העדפות בעל החשבון באתר האינטרנט שלנו כדי להפוך את חוויית בעל החשבון לעקבית ונוחה יותר. אנו משתמשים בעוגיות כדי לשמור על מצב המחובר שלך כאשר אתה מבקר באתר האינטרנט שלנו שוב ושוב.",
    "privacy_policy_cookies_targeted_ads": "<strong> ספק שירותי מדידה ומודעות ממוקדות יותר ושיווק: <\/strong> אנו משתמשים בקובצי Cookie, משואות אינטרנט וטכנולוגיות אחסון אחרות משותפי צד שלישי כגון Google ו-Facebook לשירותי מדידה, מודעות מיקוד טוב יותר ולמטרות שיווק. זה מתרחש כאשר אתה מבקר באתר האינטרנט שלנו. עוגיות אלה, משואות אינטרנט וטכנולוגיות אחסון אחרות מאפשרות לנו להציג בפניך חומר פרסומי של Menuonline באתרים אחרים שבהם אתה מבקר ברחבי האינטרנט.",
    "privacy_policy_security_title": "בִּטָחוֹן",
    "privacy_policy_security_desc1": "Menuonline עושה מאמצים להגן על מידע סודי מכל הסוגים, לרבות מידע אישי, עם אמצעי הגנה ארגוניים, פיזיים, מכניים ואלקטרוניים המתאימים לרגישות המידע. השרתים שלנו ממוקמים בקנדה ועומדים בסטנדרטים של התעשייה הנוגעים לאבטחה.",
    "privacy_policy_security_desc2": "שים לב, עם זאת, איננו יכולים להבטיח שהאמצעים שאנו מקפידים על בטיחות את אבטחת המידע.",
    "privacy_policy_retention_title": "הַחזָקָה",
    "privacy_policy_retention_desc": "אנו שומרים מידע אישי על בעלי חשבון ואנשי קשר כל עוד נדרש למטרות המפורטות במדיניות פרטית זו ובהתאם להוראות השמירה בתנאי השימוש.",
    "privacy_policy_access_questions_title": "גישה, שאלות ודאגות",
    "privacy_policy_access_questions_desc": "אדם שמסר מידע אישי ל-Menuonline רשאי להגיש בקשה בכתב לגשת או לתקן את המידע האישי שלו שנשמר על ידי Menuonline.",
    "terms_obj": {
        "terms_conditions_title": "תנאים והגבלות",
        "terms_conditions_subtitle": "תנאים והגבלות עבור לקוחות חדשים וקיימים",
        "terms_conditions_desc1": "נקודות המפתח הבאות (כולל הפניות לסעיפים) מסופקות לנוחיותך בלבד ואינן מחליפות את התנאים וההתניות המלאים. באחריותך לקרוא את התנאים וההגבלות הללו לפני השימוש בשירות Menuonline.",
        "terms_conditions_desc2": "השימוש ב- Menuonline פירושו שאתה מסכים לתנאים וההגבלות ומייצג ומתחייב שאתה מורשה על ידי הארגון או החברה שלך לעשות זאת. (ראה סעיף 3 \"איך זה עובד\")",
        "terms_conditions_desc3": "Menuonline הוא שירות שיווק מבוסס הרשאות. שליחה מכוונת של \"דואר זבל\" או הפרת התנאים וההגבלות תגרום לסגירת חשבונך. (ראה סעיף 16 \"שימוש מקובל בשירות\")",
        "terms_conditions_desc4": "הנתונים שאתה מארח ב- Menuonline כגון רשומות אנשי קשר, תוכן דואר אלקטרוני ומידע שאתה אוסף דרך Menuonline שייכים לך. (ראה ס' 14 \"מה בבעלותך?\").",
        "terms_conditions_desc5": "תוכנת Menuonline והתוכן באתר שלנו שייכים לנו. (ראה סעיף 13 \"מה בבעלותנו?\")",
        "terms_conditions_desc6": "מלבד המשתמשים שלך, הנתונים או התוכן הלא פורסם שאתה מארח עם Menuonline יכולים להיראות רק על ידי עובדי ושותפים של Menuonline שחתמו על הסכמי סודיות. (ראה ס' 14 \"מה בבעלותך?\").",
        "terms_conditions_desc7": "כל המידע המאפשר זיהוי אישי (PII) מוחזק רק בשרתים הממוקמים בקנדה. (ראה ס' 14 \"מה בבעלותך?\").",
        "terms_conditions_desc8": "השירות Menuonline כפוף לחוקי קנדה ומחוז אונטריו. (ראה סעיף 24 \"חוק החל\")",
        "terms_conditions_desc9": "כל בקשות לנתונים או לתוכן שלך על ידי יחידים, משטרה או סוכנויות רגולטוריות אחרות מתוך קנדה או מחוץ לקנדה, תמיד יופנו אליך. החריג היחיד יהיה אם זה מעמיד אותנו בהפרה של החוק הקנדי או אונטריו; עם זאת, בכל המקרים אנו נתנגד בתוקף לכל שחרור הנתונים שלך על ידי כל אחד מלבדך. (ראה ס' 14 \"מה בבעלותך?\").",
        "terms_conditions_desc10": "אבטחת Menuonline טובה רק כמו הניהול שלך של שמות משתמש וסיסמאות. אנחנו לא גובים תשלום עבור חשבונות משתמש Menuonline, אז צור אחד נפרד עבור כל משתמש ושמור את הסיסמאות בסוד. אתה אחראי לחשבון שלך. (ראה סעיף 3 \"איך זה עובד\")",
        "terms_conditions_desc11": "אתה יכול לבטל את חשבונך בכל עת. אנו לא מספקים החזרים על כל אשראי שלא נוצל אם תבטל. (ראה סעיף 3 \"איך זה עובד\")",
        "terms_conditions_desc12": "אנו יכולים לבטל את חשבונך בכל עת. אנו מספקים החזרים עבור זיכויים בדוא\"ל ושירותים שאינם בשימוש אם נבטל את חשבונך. (ראה סעיף 3 \"איך זה עובד\")",
        "terms_conditions_desc13": "הנתונים שלך יימחקו לאחר הביטול ועשויים להימחק גם אם משתמש בחשבון לא התחבר במשך 12 חודשים או יותר. אנו נשמור על נתונים מצטברים מחשבונך שאינם כוללים מידע אישי מזהה למטרות סטטיסטיות. (ראה סעיף 3 \"איך זה עובד\")",
        "terms_conditions_desc14": "זיכוי דוא\"ל נרכשים על בסיס תשלום לפי דרכו ולעולם לא יפוג. החריג היחיד הוא אם לא נכנסת לחשבון שלך במשך 12 חודשים. במקרה זה, אנו שומרים לעצמנו את הזכות למחוק את חשבונך, הנתונים שלך והזיכויים הקשורים אליו. (ראה סעיף 10 \"מה אני משלם עבור השירות?\")",
        "terms_conditions_desc15": "אנו עובדים קשה כדי להבטיח ש- Menuonline פועל בכל עת ולספק הודעה מוקדמת אם עלינו להשעות זמנית את השירות לצורך תחזוקה. עם זאת, איננו מבטיחים שהשירות יהיה פעיל בכל עת ואיננו אחראים להפסדים שאתה סובל משימוש ב- Menuonline, לרבות הפסדים מצדך אם המערכת אינה פועלת בזמן שאתה זקוק לה. (ראה סעיף 17 \"כתבי ויתור\").",
        "terms_conditions_desc16": "אנחנו לא עוקבים אחר התוכן שלך או למי אתה שולח הודעות. עם זאת, אנו עוקבים אחר תלונות ובעיות אחרות שנוצרו כתוצאה מהפעילויות שלך. יותר מדי תלונות או בעיות עלולות לגרום לביטול השירות שלך לפי שיקול דעתנו. (ראה סעיף 7 \"ניטור\")",
        "terms_conditions_desc17": "אנו מחויבים לספק תמיכת מוצר מעולה כדי לעזור לך להיות עצמאי עם Menuonline. אנו מספקים תמיכה בצורת תיעוד ווידאו באתר האינטרנט שלנו, במערכת כרטיסי התמיכה של Menuonline ובטלפון. אין תשלום עבור תמיכת מוצר; עם זאת, אנו שומרים לעצמנו את הזכות להפסיק את התמיכה בחשבונות לקוח ספציפיים לפי שיקול דעתנו. (ראה סעיף 5 \"תמיכה\")",
        "terms_conditions_desc18": "שירותי מוצר מיועדים לזמנים שבהם אתה רוצה שנעשה עבודה כלשהי הקשורה ל- Menuonline במקום לעשות זאת בעצמך. התעריף שלנו עבור שירותי מוצר הוא $135 לשעה. כמו כן אנו מספקים שירותי ייעוץ בתעריפים גבוהים יותר. אנו שומרים לעצמנו את הזכות לשנות את התעריפים שלנו בכל עת. (ראה סעיף 6 \"שירותי מוצר וייעוץ\")",
        "terms_conditions_desc19": "אנו נודיע לך אם אנו סבורים שהייתה הפרה של הנתונים שלך ונעשה מאמץ לספק פרטים ומידע קשורים. (ראה סעיף 14 \"מה בבעלותך?\")",
        "terms_conditions_desc20": "אנו שומרים לעצמנו את הזכות לערוך שינויים בהסכם זה בכל עת. אנו ננסה להודיע ​​לך על שינויים בתנאים וההגבלות אך השימוש בשירות פירושו שאתה מסכים לשינויים בתנאים. (ראה סעיף 9 \"שינויים\").",
        "terms_conditions_desc21": "התנאים וההגבלות ומדיניות הפרטיות שלנו נוסחו באנגלית בלבד, ואתה מסכים שהגרסה בשפה האנגלית של התנאים וההגבלות ומדיניות הפרטיות תקבל עדיפות במקרה של התנגשות עם כל גרסה מתורגמת.",
        "terms_conditions_mycircle": "תנאים והגבלות של MYCIRCLE",
        "terms_conditions_last_update": "עדכון אחרון: 14 בינואר 2020",
        "terms_conditions_usage_agreement": "אנא קרא את התנאים וההגבלות של שירות אלה ('התנאים') בעיון לפני השימוש באתר אינטרנט זה ('האתר'). תנאים אלה פוטרים את Menuonline ('Menuonline', 'אנחנו' או 'אנחנו') ואחרים מאחריות ו\/או מגבילים את אחריותנו ושלהם ומכילים הוראות חשובות אחרות החלות על השימוש שלך באתר זה.",
        "terms_conditions_acceptance": "על ידי ביקור או שימוש באתר זה או בשירות, אתה מסכים בשמך, ובשם כל ארגון שבשמו אתה רשאי לפעול (המכונה ביחד \"אתה\"), לקבל ולציית לתנאים אלה עבור כל שימוש וכל ביקור באתר זה.",
        "terms_conditions_services": "1. השירותים שלנו.",
        "terms_conditions_services_desc": "השירותים שאנו מציעים דרך האתר הם שירות המאפשר לך ליצור, לשלוח ולנהל הודעות דואר אלקטרוני ('מיילים') לנמענים לבחירתך ('השירות').",
        "terms_conditions_license": "2. רישיון.",
        "terms_conditions_license_desc": "בכפוף לתנאים אלה, Menuonline מעניקה לך בזאת רישיון לא בלעדי, מוגבל, שאינו ניתן להעברה במהלך התקופה לצפות באתר ולהשתמש בשירות כפי שמתיר תכונות השירות. אנו שומרים לעצמנו את כל הזכויות שאינן מוענקות במפורש כאן בשירות ובתוכן ה- Menuonline (כפי שמוגדר להלן).",
        "terms_conditions_account": "3. איך זה עובד.",
        "terms_conditions_account_desc": "כדי להשתמש בשירות עליך להירשם לחשבון. החשבון שלך בחינם. חשבונך נותן לך גישה לשירות ול\"פונקציות\" שאנו עשויים להקים ולתחזק מעת לעת. כדי להיות זכאי לחשבון עליך:",
        "terms_conditions_account_requirements": [
            "להיות בן שמונה עשרה (18) לפחות ויכול להתקשר בחוזים;",
            "להשלים את תהליך הרישום שקבענו;",
            "להסכים לתנאים ולהגבלות אלה;",
            "לספק מידע אמיתי, מלא ומעודכן ליצירת קשר וחיוב\/תשלום."
        ],
        "responsibility": "אַחֲרָיוּת.",
        "responsibilityText": "אתה האחראי הבלעדי לפעילות המתרחשת בחשבונך, ועליך לשמור על סיסמת החשבון שלך בסודית ומאובטחת. החשבונות הם בחינם, לכן אנו ממליצים לך להגדיר חשבון אחר עבור כל משתמש שיש לך. אתה גם אחראי לכל חשבון שיש לך גישה אליו, בין אם אישרת את השימוש בחשבון ובין אם לאו. אתה מסכים שהאחריות הבלעדית לאינטראקציות שלך עם אלה שאליהם אתה שולח הודעות דוא\"ל, ותשלח הודעות דוא\"ל רק לאותם אנשים שיש לך הסכמה מהם. עליך להודיע ​​לנו מיד על כל שימוש לא מורשה בחשבונות שלך.",
        "compliance_with_CASL": "עמידה בחקיקה הקנדית נגד ספאם (CASL).",
        "compliance_with_CASL_text": "התפקיד של Menuonline הוא להקל על ניהול הגדרות ההסכמה, לא לאכוף חקיקה. באחריותך לעמוד בתקן CASL. אנו עוזרים לך על ידי הגדרת פונקציונליות ברירת מחדל ועל ידי הקלה על השגה ועדכון של הסכמה מפורשת מתועדת וניהול תאריכי תפוגה מתגלגלים להסכמה משתמעת. אתה בשליטה מלאה על ניהול ההסכמה עבור מסד הנתונים שלך; אתה יכול לעדכן את סטטוס ההסכמה עבור כל אנשי הקשר שלך בכמות גדולה בכל עת, לעקוף את הגדרות ברירת המחדל ולבטל את התפוגה האוטומטית של סטטוס ההסכמה המשתמעת.",
        "closing_account": "סגירת חשבונך.",
        "closing_account_text": "אתה או Menuonline רשאים לסיים הסכם זה בכל עת ומכל סיבה על ידי מתן הודעה לצד השני. אם תספק מידע כלשהו שהוא לא נכון, לא מדויק, לא עדכני או לא שלם או תשתמש בשירות באופן שמפר את התנאים וההגבלות האלה, יש לנו את הזכות להשעות מיד את חשבונך ולהפסיק את גישתך לשירות בנוסף לכל תרופות אחרות העומדות לרשותנו. אם לא תשתמש בחשבון שלך במשך שנים עשר (12) חודשים, אנו עשויים לבטל את חשבונך. אם נסגור את חשבונך מכל סיבה שהיא, נחזיר לך את כל הזיכויים שנותרו לך. לאחר סיום, אנו נמחק לצמיתות את חשבונך ואת כל הנתונים המשויכים אליו, כולל הודעות הדוא\"ל ששלחת. אנו עשויים לשמור נתונים מצטברים מחשבונך שאינם כוללים מידע אישי מזהה למטרות סטטיסטיות.",
        "emailing_content": "שליחת תוכן בדוא\"ל.",
        "emailing_content_text": "בעת אינטראקציה עם Menuonline באמצעות אתר זה או השירות, אתה מסכים לספק מידע אמיתי ומדויק. יתרה מכך, אתה מבין כי מתן מידע סודי באמצעות דואר אלקטרוני הוא מנהג לא בטוח. אתה מבין שאתה האחראי הבלעדי לכל מה שאתה שולח לכל מי שמשתמש בשירות שלנו. מטרות.",
        "support": "תְמִיכָה.",
        "support_text": "אנו מספקים לך תמיכה כדי לעזור לך להיות עצמאי עם השירות. אנו מספקים לך תמיכה בצורת תיעוד וסרטונים באתר, מערכת כרטיסי התמיכה Menuonline ובטלפון. אתה יכול להגיע אלינו על ידי לחיצה על לשונית \"תמיכה\" בראש האתר. אין תשלום עבור תמיכת מוצר; עם זאת, אנו שומרים לעצמנו את הזכות להפסיק את התמיכה בחשבונות לקוח ספציפיים לפי שיקול דעתנו.",
        "product_and_consulting_services": "שירותי מוצר וייעוץ.",
        "product_and_consulting_services_text": "שירותי מוצר מיועדים לזמנים שבהם אתה רוצה שנעשה עבודה כלשהי הקשורה ל- Menuonline במקום לעשות זאת בעצמך. התעריף שלנו עבור שירותי מוצר הוא $125 לשעה. כמו כן אנו מספקים שירותי ייעוץ בתעריפים גבוהים יותר. אנו שומרים לעצמנו את הזכות לשנות את התעריפים שלנו בכל עת.",
        "monitoring": "ניטור.",
        "monitoring_text": "לא ננטר או נשפוט את תוכן המידע המועבר בשירות, אך נבדוק אם נקבל תלונות על שימוש בלתי הולם אפשרי. Menuonline רשאית, בכל עת ולפי שיקול דעתה הבלעדי, לקבוע כי שימושים מסוימים מתאימים או אינם מתאימים עם או ללא הודעה אליך, בהתאם להנחיות הבאות.",
        "term": "מוּנָח.",
        "term_text": "התקופה מתחילה כאשר אתה נרשם לחשבון אצלנו וממשיכה כל עוד אתה משתמש בשירות. אתה מצהיר ומתחייב שיש לך את הסמכות והיכולת להירשם לחשבון עבורנו (כולל אם אתה נרשם מטעם ארגון).",
        "changes": "שינויים.",
        "changes_text": "התקופה מתחילה כאשר אתה נרשם לחשבון אצלנו וממשיכה כל עוד אתה משתמש בשירות. אתה מצהיר ומתחייב שיש לך את הסמכות והיכולת להירשם לחשבון עבורנו (כולל אם אתה נרשם מטעם ארגון).",
        "payment": "תַשְׁלוּם",
        "what_do_i_pay_for_service": "מה אני משלם עבור השירות?.",
        "what_do_i_pay_for_service_text": "אתה יכול לקנות זיכויים בתשלום מראש כדי לשלוח דוא\"ל (\"קרדיטים\") באמצעות השירות שלנו, כפי שאנו מתארים בפירוט בדף \"התמחור\" של האתר שלנו. הזיכויים לעולם אינם פוקעים ואין עמלות חוזרות עבור הזיכויים הללו. החריג היחיד הוא שאם לא תשתמש בחשבון שלך במשך שנים עשר (12) חודשים, אנו עשויים לבטל את חשבונך וכל זיכוי שלא נעשה בו שימוש. אנו עשויים לשנות את התמחור שלנו בכל עת על ידי עדכון דפי התמחור של האתר שלנו, אשר יחולו על הרכישה הבאה שלך של קרדיטים.",
        "how_do_i_pay_for_service": "איך אני משלם עבור השירות?.",
        "how_do_i_pay_for_service_text": "אתה יכול לשלם עבור זיכויים באמצעות כל כרטיס אשראי עיקרי. אנו עשויים לשנות את אמצעי התשלום שאנו מקבלים בכל עת.",
        "can_i_get_refund": "האם אוכל לקבל החזר?.",
        "can_i_get_refund_text": "הסכומים ששילמת עבור השימוש בשירות אינם ניתנים להחזר אם תבטל את חשבונך. אם נבטל את חשבונך, נחזיר לך את כל הזיכויים שנותרו לך.",
        "rights": "זכויות",
        "what_do_we_own": "מה בבעלותנו?.",
        "what_do_we_own_text": "למעט התוכן שאתה מספק לנו (כולל, ללא הגבלה, רשומות אנשי קשר, תוכן דואר אלקטרוני ומידע שאתה אוסף באמצעות השירות) (\"התוכן שלך\"), כל החומרים הכלולים באתר שלנו, השירות וכל החומרים שבו או הועברו על ידו, לרבות ללא הגבלה, תוכנות, תמונות, טקסט, גרפיקה, איורים, לוגואים, פטנטים, סימני מסחר, תוכן, מוזיקה ושירותים, צילומים אחרים למשתמשים, תוכן אודיו, ותצלומים, \"תוכן Menuonline\") (וכל זכויות הקניין הרוחני הקשורות אליו) הם בבעלותנו או של נותני הרישיונות שלנו.",
        "what_do_you_own": "מה בבעלותך?.",
        "what_do_you_own_text": "איננו תובעים זכויות בעלות על התוכן שלך. התוכן שלך הוא שלך ואתה שומר בעלות על התוכן שאתה מעלה לשירות. על ידי שיתוף התוכן שלך דרך השירות, אתה מסכים לאפשר לאחרים לצפות, לערוך ו\/או לשתף את התוכן שלך בהתאם להגדרות שלך ולתנאים וההגבלות הללו. אתה מצהיר ומתחייב שבבעלותך או שיש לך הרשאה להשתמש בכל החומר שבו אתה משתמש בהודעות האימייל שלך. אנו עשויים להשתמש או לחשוף את החומרים שלך רק כפי שאנו מתארים בתנאים אלה ובמדיניות הפרטיות שלנו.",
        "privacy_statement": "Menuonline מכבדת את פרטיות המשתמשים שלה. כל מידע אישי ש- Menuonline אוספת דרך אתר זה או השירות כפוף להצהרת הפרטיות של Menuonline המשולבת בהפניה בתנאים אלה. אנו עשויים להשתמש ולחשוף את המידע שלך בהתאם להצהרת הפרטיות שלנו.",
        "access_to_your_content": "Menuonline לא תספק גישה לתוכן שלך לאף אחד מלבד אותם עובדים וקבלנים של Menuonline אשר ביצעו איתנו הסכמי סודיות מתאימים או שיש לנו את הסכמתך.",
        "governmentRequest": "אם נקבל בקשה מממשלה או רשות רגולטורית לקבל גישה לתוכן שלך, אנו נודיע לך על הבקשה ונבקש את הסכמתך לפני שנשחרר חלק מהתוכן שלך. אנו נתנגד לכל בקשות לתוכן שלך ולא נשחרר את התוכן שלך בתגובה לבקשה מסוג זה ללא רשותך או שתידרש לכך על ידי בית משפט בעל סמכות שיפוט מוסמכת או הגוף הרגולטורי הרלוונטי.",
        "breachNotification": "במקרה שנודע לנו שהתוכן שלך היה נתון לגישה בלתי מורשית, שימוש, העתקה, גניבה, אובדן או שימוש או חשיפה תוך הפרה של תנאים אלה ('הפרה'), נודיע לך בהקדם ונודיע לך על מה שהתרחש. אתה מסכים שהפרות יטופלו בהתאם לחוק החל, ואם אנחנו צריכים שתודיע לחברים על רשימות התפוצה שלך בדוא\"ל, תסכים לעשות זאת בהקדם ולפי הנחייתנו.",
        "contentRetention": "אנו נשמור ונשתמש ונעביר את התוכן שלך בקנדה למעט באישור או הנחיות מראש ובכתב (לדוגמה, אם תבחר לשלוח את התוכן שלך לאנשים מחוץ לקנדה). השרתים המשמשים למתן השירות נמצאים בקנדה.",
        "accessTrouble": "אם אתה מתקשה לגשת לתוכן שלך, Menuonline יסייע לך לאחזר את התוכן שלך על בסיס זמן וחומרים.",
        "reportingAbuse": "אם אתה מאמין שמישהו מפר אחד מהתנאים האלה, אנא הודע לנו מיד. אם קיבלת דואר זבל שלדעתך הגיע ממשתמש אחר של השירות, אם אתה חושב שמשתמש אחר בשירות פרסם חומר שמפר זכויות יוצרים כלשהן, או אם אתה חושב שמשתמש אחר בשירות מפר תנאים אלה בדרך אחרת, אנא פנה אלינו בכתובת Support@menuonline.com.",
        "acceptableUse": "אתה מצהיר ומתחייב שהשימוש שלך בשירות יעמוד בכל החוקים והתקנות החלים. אתה מסכים שאתה אחראי לקבוע אם השירות יעמוד בדרישות ומתאים לך לשימוש בתחום השיפוט שלך. ליתר וודאות, אתה מסכים שתצטרך (ומסכים להבטיח שהעובדים, הסוכנים והמשתמשים המורשים שלך)...",
        "exportLaws": "אנו לא מצהירים שהאתר או השירות מתאימים או זמינים לשימוש מחוץ לקנדה, והגישה אליהם מטריטוריות שבהן תוכנם אינו חוקי אסורה. אינך רשאי להשתמש או לייצא או לייצא מחדש את התוכן Menuonline או חומרים אחרים באתר זה או כל עותק או התאמה תוך הפרה של חוקים או תקנות החלים כלשהם, לרבות וללא הגבלה חוקים ותקנות הייצוא הקנדיים והארה\"ב.",
        "disclaimers": "השירות ואתר זה מסופקים \"כמות שהם\" ללא כל מצג, אחריות או תנאים מכל סוג שהוא, ומנוonline מסירה במפורש, במידה המותרת על פי החוקים החלים, את כל ההצגות, התחייבויות והתנאים, מפורשים או משתמעים, על פי חוק או אחר, לרבות ללא הגבלה כל אחריות משתמעת ותנאי סחירות, או התאמה או התאמה לכל מטרה או שימוש מסוים של צד שלישי...",
        "limitLiability": "החבות המצטברת של Menuonline על פי הסכם זה, בין אם בחוזה ובין אם בנזיקין (לרבות רשלנות), כתוצאה מהפרת אחריות, אחריות קפידה או על פי כל תורת אחריות אחרת שהיא, תהיה מוגבלת לנזקים ישירים בסכום שלא יעלה על סך העמלות ששולמו ל- Menuonline על פי תנאים והגבלות אלה בשנים עשר (12) החודשים האחרונים שהתרחשו מיד לפני החבות בחודש האחרון.",
        "liabilityExclusions": "לאף אחד מ-Menuonline, השותפים שלה או הדירקטורים, נושאי המשרה, העובדים, הסוכנים או הנציגים האחרים שלהם (ביחד 'Menuonline \"נציגים\") או המפעילים של רשתות צמודות כלשהן לא תהיה כל אחריות או חבות בקשר לאתר זה או לשירות עבור: (i) כל נזק עקיף, תוצאתי, מקרי, דוגמה, עונשי או מיוחד; או (ii) בגין כל נזק, בין אם ישיר, עקיף, תוצאתי, מקרי, למופת, עונשי או מיוחד, המאופיין כהפסד הכנסה, אובדן חסכונות או הכנסה או אובדן רווחים...",
        "indemnity": "אתה תשפה ותחזק את Menuonline ו\/או את נציגי Menuonline ('הצדדים המשופיים של Menuonline') מכל תביעה שמוגשת על ידי צדדים שלישיים, וכל קנס או קנס שנגזרו נגדנו, הנובעים מהשימוש שלך במידע שאליו ניגש מאתר זה או השימוש שלך בשירות, וכל הפרה של תנאים אלה...",
        "ipInfringement": "Menuonline תגן, תשפה ותרחיק אותך מכל תביעה, דרישה, תביעה, תביעה או הליכים שיוגשו או יובאו נגדך על ידי צד שלישי בטענה שהגישה שלך או השימוש שלך בשירות בהתאם להסכם זה מפרים את זכויות הקניין הרוחני על פי החוק הקנדי של צד שלישי ('תביעה להפרת IP')...",
        "equitableRelief": "אם תפר את התנאים הללו, אנו עשויים לבקש סעד צו מניעה (כלומר, אנו עשויים לבקש צו בית משפט שיעצור אותך) או סעד הוגן אחר. אתה מסכים שזה יהיה בנוסף וללא פגיעה בכל זכויות אחרות שעשויות להיות לנו בחוק או בשוויון.",
        "linksToOtherSites": "אם אנו מספקים קישורים לאתרי צד שלישי, הם מסופקים רק מטעמי נוחות. אם תשתמש בקישורים אלה, תעזוב את האתר שלנו. אנחנו לא שולטים באתרי צד שלישי אלה או תומכים בהם. אתה מסכים שאיננו אחראים לאף אחד מאתרי צד שלישי אלה או לתוכן שלהם...",
        "thirdPartyRequests": "אם עלינו לספק מידע בתגובה לבקשה משפטית או ממשלתית הקשורה לחשבונך או לשימוש שלך בשירות, אז אתה מסכים שאנו עשויים לחייב אותך עבור העלויות שלנו. עמלות אלו עשויות לכלול את שכר הטרחה המשפטי שלנו ואת העלויות של זמן העובד שלנו בהכנת התגובה לבקשה.",
        "assignment": "הסכם זה, וכל זכויות ורישיונות המוענקים להלן, אינם ניתנים להעברה או להקצאה על ידך, אך ניתן להקצותו על ידי Menuonline ללא הגבלה, בהודעה מוקדמת סבירה אליך.",
        "governingLaw": "תנאים אלה יהיו כפופים לכל החוקים הפדרליים החלים ולחוקי אונטריו ויתפרשו בהתאם לכל החוקים של אונטריו ללא התחשבות בהוראות החוק שלה. אתה מסכים להיכנע לסמכות השיפוט הבלעדית של בתי המשפט של אונטריו לצורך הכרעה בכל מחלוקת הנוגעת לתנאים אלה.",
        "severability": "אם מסיבה כלשהי בית משפט בעל סמכות שיפוט מוסמכת ימצא תנאי כלשהו בתנאים אלה או חלק מהם כבלתי חוקי או מסיבה כלשהי בלתי אכיפה, אזי הוראה זו תיאכף במידה המרבית המותרת כדי לתת תוקף לכוונת תנאים אלה, ושאר התנאים הללו ימשיכו בתוקף ובתוקף ויישאר אכיפה.",
        "waiver": "כל ויתור על, או הסכמה לסטייה, מהדרישות של תנאים אלה יהיו בתוקף רק אם הם יהיו בכתב ונחתמים על ידינו, ורק במקרה הספציפי ולמטרה הספציפית שלשמה הוא ניתן. אי מימושנו או העיכוב שלנו במימוש, כל זכות לפי תנאים אלה לא תפעל כוויתור על זכויותינו.",
        "furtherAssurances": "כל צד מסכים לחתום ולספק את כל המסמכים ולנקוט כל פעולה (או לגרום לביצוע פעולות אלו) הדרושות כדי לעמוד בהתחייבויותיו על פי תנאים והגבלות אלה.",
        "notices": "כל ההודעות ל-Menuonline חייבות להיות בכתב ולשלוח אלינו במייל. יש לשלוח הודעות אלינו לתשומת לב נציגי שירות הלקוחות שלנו בכתובת support@menuonline.com. הודעות אליך עשויות להישלח לכתובת או לכתובת הדוא\"ל שסופקו על ידך כחלק מההרשמה שלך. בנוסף, אנו עשויים לשדר הודעות או הודעות דרך אתר זה כדי ליידע אותך על שינויים באתר זה או עניינים אחרים בעלי חשיבות, ושידורים אלו יהוו הודעה עבורך בזמן פרסום אותם.",
        "entireAgreement": "תנאים אלה והצהרת הפרטיות שלנו מהווים את כל ההסכם בינך לבינינו ביחס לשימוש שלך באתר ובשירות, ומחליפים את כל ההסכמים, המצגים וההבנות הקודמים בין Menuonline לבינך ביחס לנושא זה. במקרה של סתירה כלשהי בין הצהרת הפרטיות לתנאים אלה, תנאים והגבלות אלה ינהלו.",
        "language": "הצדדים ביקשו ודרשו במפורש שתנאים והגבלות אלה, מדיניות הפרטיות שלנו וכל מסמכים קשורים יהיו מנוסחים באנגלית. Les parties conviennent and exigent expressément que ce Contrat et tous les documents qui s'y reportt soient rédigés en anglais. אתה מסכים שהגרסה בשפה האנגלית של תנאים אלה ומדיניות הפרטיות תקבל עדיפות במקרה של התנגשות עם כל גרסה מתורגמת.",
        "survival": "כל הוראות תנאים אלה, אשר מטבען או ייבואן נועדו לשרוד תפוגה או סיום של תנאים אלה, ישמרו, לרבות, ללא הגבלה, סעיפים 17-19."
    },
    "collect_additional_info": "האם תרצה לאסוף מידע נוסף?",
    "additionalInfoChoice": "בחר כל מידע נוסף שתרצה לאסוף.",
    "additional_info_yes": "כֵּן",
    "additional_info_noSkip": "לא, סקיפ",
    "additional_info_label": "מידע נוסף",
    "confirm_business_location": "תחילה נאשר את מיקום העסק שלך",
    "custom_get_more_reviews_form": "טופס קבל עוד ביקורות מותאם אישית",
    "set_up_custom_form": "הגדר טופס קבל עוד ביקורות מותאם אישית לפי קבוצה",
    "reputation_name_label": "קבל שם ביקורות נוספות",
    "reputation_name_placeholder": "הזן שם מוניטין",
    "group_label": "קְבוּצָה",
    "group_desc_title": "כותרת תיאור הקבוצה",
    "group_desc": "תיאור הקבוצה",
    "select_group_placeholder": "בחר קבוצה",
    "required_group_name": "נדרש שם הקבוצה",
    "before_asking_review": "לפני שנבקש מהלקוחות לסקירה, בואו נשאל אותם 1 עד 4 שאלות.",
    "feel_free_edit": "אתה מוזמן לערוך לפי הצורך.",
    "incentive_settings": "הגדרות תמריצים",
    "incentivize_customer": "האם תרצה לתמרץ את הלקוח שלך לתת ביקורת?",
    "choose_incentive": "בחר את התמריץ שאתה רוצה להפנות.",
    "scan_qr_feedback": "סרוק את קוד ה-QR ושתף איתנו את המשוב היקר שלך לגבי החוויה שלך.",
    "qr_code": "קוד QR",
    "scan_me": "סרוק אותי!",
    "or_title": "אוֹ",
    "send_otp_title": "שלח OTP",
    "sign_in_title": "היכנס",
    "email_addresss_title": "כתובת אימייל",
    "reset_password_title": "אפס סיסמה",
    "remember_password_title": "זכרתם את הסיסמה?",
    "digit_code_title": "נא להזין קוד בן 4 ספרות שנשלח הלאה ",
    "not_received_title": "לא קיבלתי קוד אימות!",
    "resend_title": "נא לשלוח שוב",
    "sign_up_form": "צור את החשבון שלך",
    "sign_up_sub_title": "התחבר עכשיו כדי לגשת לתובנות האחרונות",
    "already_registered_title": "כבר יש לך חשבון Menuonline?",
    "reserved_title": "זכויות יוצרים ©2023 Menuonline. כֹּל הַזְכוּיוֹת שְׁמוּרוֹת.",
    "terms_title": "תנאים והגבלות",
    "policy_title": "מדיניות פרטיות",
    "sign_up_title": "הירשם",
    "terms_conditions_title": "תנאים והגבלות",
    "business_type_title": "סוג עסק",
    "business_type_sub_title": "אנא בחר באחד מסוגי העסקים שמתאר את העסק שלך טוב יותר!",
    "salon_title": "סָלוֹן",
    "restaurant_title": "מִסעָדָה",
    "pizza_store_title": "חנות פיצה",
    "food_truck_title": "משאית אוכל",
    "check_domain_title": "בדוק את שם הדומיין",
    "start_exploring_title": "התחל לחקור",
    "sign_in_form": "היכנס לחשבון שלך",
    "sign_in_sub_title": "התחבר עכשיו כדי לגשת לתובנות האחרונות",
    "remember_title": "זכור אותי",
    "otp_title": "OTP",
    "password_title": "סִיסמָה",
    "forgot_title": "שכחת סיסמא?",
    "not_registered_title": "אין לך חשבון?",
    "create_account_title": "הירשם עכשיו",
    "create_form_title": "צור טופס",
    "first_name_title": "שֵׁם פְּרַטִי",
    "last_name_title": "שֵׁם מִשׁפָּחָה",
    "email_title": "אֶלֶקטרוֹנִי",
    "mobile_number_title": "מספר נייד",
    "birthday_title": "יוֹם הוּלֶדֶת",
    "gender_title": "מִין",
    "address_title": "כְּתוֹבֶת",
    "short_answer_title": "תשובה קצרה",
    "long_answer_title": "תשובה ארוכה",
    "single_answer_title": "תשובה יחידה",
    "single_checkbox_title": "תיבת סימון בודדת",
    "multiple_choice_title": "בחירה מרובה",
    "drop_down_title": "תפריט נפתח",
    "yes_no_title": "כן או לא",
    "description_text_title": "טקסט תיאור",
    "file_upload": "העלאת קובץ",
    "reset_password_form": "אפס את הסיסמה שלך",
    "reset_password_sub_title": "אפס במהירות את הסיסמה שלך וקבל גישה לחשבון שלך.",
    "required_full_name_title": "נדרש שם מלא",
    "required_first_name_title": "נדרש שם פרטי",
    "valid_first_name_title": "הזן שם פרטי חוקי",
    "valid_full_name_title": "הזן שם מלא חוקי",
    "required_block_time": "נדרש זמן חסימה",
    "required_last_name_title": "נדרש שם משפחה",
    "valid_last_name_title": "הזן שם משפחה חוקי",
    "valid_mobile_number_title": "הזן מספר נייד חוקי",
    "required_mobile_number_title": "נדרש מספר נייד",
    "required_already_mobile_number_title": "מספר נייד כבר נדרש",
    "valid_otp_title": "הזן otp חוקי",
    "valid_email_title": "הזן אימייל חוקי",
    "required_email_title": "נדרש אימייל",
    "required_description_title": "נדרש תיאור",
    "required_description_500_characters": "נא להזין לפחות 500 תווים",
    "required_description_600_characters": "נא להזין עד 600 תווים",
    "domain_title": "שם דומיין",
    "required_password_title": "נדרשת סיסמה",
    "required_password_regex_title": "חייב להכיל לפחות 8 תווים, לפחות תו אחד גדול, תו קטן אחד, מספר אחד ותו אחד מיוחד.",
    "required_terms_title": "נא להסכים לתנאים וההגבלות",
    "required_note_title": "נדרשת הערה",
    "required_birthday_title": "חובה יום הולדת",
    "no_access_title": "אין לך גישה כרגע. אנא פנה למנהל המערכת שלך",
    "menu_online_open_txt": "לִפְתוֹחַ",
    "loyalty_program_description": "😊 מצא את תוכנית הנאמנות האידיאלית שלך והתאם אותה אישית כך שתתאים למותג שלך!",
    "lp_list_title": "רשימה של כרטיס ניקוב נאמנות",
    "lp_n_title": "שם כרטיס ניקוב נאמנות",
    "enter_details_title": "הזן פרטים",
    "stamp_allowed_title": "חותמת מותרת",
    "stamp_items_title": "פריטי חותמת",
    "lpn_req_title": "נדרש שם כרטיס ניקוב של נאמנות",
    "valid_lpn_title": "הזן שם כרטיס ניקוב נאמנות חוקי",
    "brand_color_error": "צבע המותג חייב להיות בן 7 תווים בדיוק",
    "select_dark_brand_color_title": "אנא בחר צבע מותג חוקי",
    "your_brand_color_title": "צבע המותג שלך",
    "upload_logo_title": "העלה לוגו",
    "locations_title": "מיקומים",
    "add_lp_title": "צור תוכנית",
    "location_error": "אנא בחר מיקומים",
    "pvs_title": "כמה בולים יכול לקוח להרוויח בכל ביקור?",
    "max_apd_title": "מה המספר המקסימלי של בולים שלקוח יכול להרוויח ביום?",
    "logo_error": "חובה לוגו",
    "expiry_title": "הגדר תפוגה על תגמולים",
    "add_more_rewards_title": "+ הוסף תגמולים נוספים",
    "prev_step_1_title": "הצג QR כדי לצבור נקודות וליהנות מהפרסים שלך.",
    "delete_lp_title": "כרטיס ניקוב נאמנות זה יימחק לצמיתות.",
    "delete_user_record_title": "רשומת משתמש זו תימחק לצמיתות.",
    "redeem_title": "לִפְדוֹת",
    "lp_button_title": "הצטרף לכרטיס ניקוב נאמנות",
    "lp_download_button_title": "הורד כרטיס",
    "step_0_title": "הצטרפו עכשיו, פרסים טעימים ממתינים לאוכלי אוכל נאמנים כמוכם.",
    "step_1_title": "אתה הצטרפת",
    "congrats_step_title": "עכשיו אתה זכאי לפיצה חינם",
    "reward_title": "קבל פרסים בכל ביקור",
    "visit_title": "בקר וביטול נעילה",
    "rewards_title": "תגמולים",
    "dummy_address": "3730 Frankfort Ave, לואיסוויל קנטקי, ארצות הברית",
    "loyalty_qr_default_text": "סרוק את קוד ה-QR, הצטרף לכרטיס הניקוב הנאמנות שלנו, והתמכר לפרסים",
    "lp_earn_stamp_txt": "לקוחות זוכים בחותמות בכל ביקור.",
    "lp_get_rewards_txt": "קבל פרסים על כל ביקור",
    "lp_1_time_visit_txt": "ביקור פעם אחת תקבל",
    "lp_uppercase_stamp_text": "חוֹתֶמֶת",
    "lp_lowercase_stamp_text": "חוֹתֶמֶת",
    "lp_txt": "נֶאֱמָנוּת",
    "lp_per_visit_stamp_validation_msg": "חותמת לכל ביקור חייבת להיות פחות מהחותמת המקסימלית המותרת ליום",
    "lp_per_max_stamp_validation_msg": "החותמת המקסימלית המותרת ליום חייבת להיות גדולה יותר מאשר חותמת הביקור",
    "lp_valid_value_msg": "הזן ערך חוקי",
    "lp_required_field_msg": "שדה זה חובה",
    "lp_stamp_required_msg": "נדרשת חותמת",
    "lp_valid_stamp_msg": "הזן ערך בול חוקי",
    "lp_reward_name_required_msg": "נדרש שם הפרס",
    "lp_unique_stamp_msg": "ערך הבול צריך להיות ייחודי",
    "lp_benefits": "תוכנית נאמנות מגבירה ביקורים חוזרים והכנסות תוך מתן תובנות ללקוחות לשיווק מותאם אישית",
    "lp_how_customer_txt": "הנה איך הלקוחות שלך",
    "lp_punch_card_txt": "כרטיס ניקוב נאמנות",
    "lp_look_txt": "יראה! 😍",
    "lp_desc": "לקוחות יזכו בחותמות עבור כל ביקור, וברגע שיאספו מספיק, הם יכולים לממש תגמולים מיוחדים.",
    "lp_edit": "לַעֲרוֹך",
    "lp_looks_good_txt": "נראה טוב",
    "lp_customer_view_instruction": "זה מה שהלקוח שלך יראה. העלה את הלוגו שלך וקבע נושא צבע",
    "lp_customer_earn_stamps": "אפשר ללקוחות לזכות בחותמות בכל ביקור ולנהל את התגמולים שלהם",
    "lp_stamp_rewards_setup": "הגדר פרסים להרוויח בולים, אפשר ללקוחות לקבל פרסים לכל בולים",
    "lp_loyalty_program_setup": "תן את שם תוכנית הנאמנות שלך והגדר את מיקומך לתוכנית זו",
    "lp_prev_step1": "הצג QR כדי לצבור נקודות וליהנות מהפרסים שלך.",
    "lp_visit_1_time_txt": "בקר פעם אחת וקבל",
    "lp_get_free_txt": "קבל חינם",
    "lp_visit_allow_txt": "בדוק וביטול נעילה",
    "lp_rewards": "תגמולים",
    "repsT": "קבל עוד ביקורות",
    "offSup": "ציוד משרדי",
    "repsNxtV": "בביקור הבא שלך",
    "addRepT": "הוסף קבל עוד ביקורות",
    "repEntrCpnTxt": "הזן טקסט קופון",
    "repEntrCpnTxtBtn": "הזן טקסט לחצן קופון",
    "repMngNxtV": "בביקור הבא שלך?",
    "repMngWldL": "האם תרצה לקבל",
    "discount": "דִיסקוֹנט",
    "repDisTyp": "סוג הנחה",
    "repNameT": "תן שם למוניטין שלך",
    "crtRepT": "צור מוניטין",
    "feedBackT": "מָשׁוֹב",
    "confLocT": "אשר מיקום",
    "businLocT": "מיקום העסק",
    "addInfoT": "מידע נוסף",
    "repNReqT": "נדרש שם 'קבל עוד ביקורות'",
    "validRepNT": "הזן שם מוניטין חוקי",
    "groupT": "קְבוּצָה",
    "groupLDescTitleT": "דחף אוטומטית אנשי קשר לקבוצה",
    "groupLDescT": "כל הלקוחות שימלאו את הטופס יתווספו לקבוצה שנבחרה.",
    "que2": "האוכל שלך היה טוב?",
    "que3": "האם השירות שלנו היה ידידותי?",
    "que4": "האם השירות שלנו היה מהיר?",
    "addAdditionalT": "האם תרצה להוסיף מידע נוסף?",
    "phNoMandT": "מספר טלפון חובה!",
    "qrGenT": "נוצר QR",
    "repFDT": "ההשקעה שלך תעזור לצוות שלנו לשרת אותך טוב יותר!",
    "letdiT": "בוא נעשה את זה",
    "tak15secT": "לוקח 15 שניות",
    "defQue": "האם תרצה לקבל מבצעים ומבצעים מעולים מעת לעת?",
    "defNQue": "האם תרצה שמישהו יענה לך בחזרה?",
    "rateOnGoogleDesc": "האם אתה מוכן לדרג אותנו בגוגל?",
    "mayBeNT": "אולי בפעם הבאה",
    "yesSureT": "כן, בטח",
    "valid_name": "הזן שם חוקי",
    "nameReq": "נדרש שם",
    "backToMainT": "חזרה לעמוד הראשי",
    "attachCopT": "האם תרצה לתמרץ את הלקוח שלך?",
    "thankYouT": "תודה לך",
    "lftyT": "אנו מצפים לביקור הבא שלך!",
    "repPreT": "תצוגה מקדימה של מוניטין",
    "offer_exclusive_discounts": "הצע הנחות ומבצעים בלעדיים כדי לפתות את הלקוחות שלך.",
    "couponT": "קוּפּוֹן",
    "locStepDesc": "אשר את מיקום העסק שלך",
    "createRepStepDesc": "צור טופס מוניטין מותאם אישית לפי קבוצות",
    "feedbackStepDesc": "סט שאלות משוב לקוחות",
    "addInfoStepDesc": "שדות לקוחות והגדרות תמריצים",
    "FedbkkWDT": "משוב עם נתונים",
    "repAnlytT": "ניתוח מוניטין",
    "todayT": "הַיוֹם",
    "rep_last_7_days_t": "7 הימים האחרונים",
    "last30DaysT": "30 הימים האחרונים",
    "lastMT": "חודש שעבר",
    "custRangeT": "טווח מותאם אישית",
    "queWiseAnltT": "שאלה ב-Wise Analytics",
    "atL1QueErrT": "אנא בחר שאלה אחת לפחות",
    "deleteRepTitle": "מוניטין זה יימחק לצמיתות.",
    "incvCustT": "כן, תמריץ את הלקוח שלי",
    "collAddInfoT": "כן, אני רוצה לאסוף מידע נוסף",
    "totRewT": "סקירה כוללת",
    "totPosRewT": "סקירה חיובית בסך הכל",
    "negFeedT": "משוב שלילי",
    "posFeedT": "משוב חיובי",
    "fineT": "האותיות הקטנות",
    "enterFineT": "הזן טקסט באותיות קטנות",
    "setThemeT": "הגדר צבע ערכת נושא",
    "que1T": "שאלה 1",
    "que2T": "שאלה 2",
    "que3T": "שאלה 3",
    "que4T": "שאלה 4",
    "entMailT": "הזן את המייל שלך",
    "reputationQRDefTxt": "סרוק את קוד ה-QR ושתף איתנו את המשוב היקר שלך לגבי החוויה שלך.",
    "selUser": "בחר משתמש",
    "userT": "מִשׁתַמֵשׁ",
    "subUReq": "אנא בחר משתמשים",
    "updateLoc": "עדכן מיקום",
    "leadGenT": "יצירת לידים",
    "displayT": "לְהַצִיג",
    "ratingT": "דֵרוּג",
    "rep_dashboard_negative_feedback": "משוב שלילי 0%",
    "rep_dashboard_position_feedback": "משוב חיובי 0%",
    "rep_dashboard_total_Feedback_txt": "משוב כולל",
    "rep_dashboard_anony_Feedback": "משוב אנונימי",
    "rep_dashboard_Feeedback_Data_txt": "משוב עם נתונים",
    "rep_dashboard_review_txt": "קבל עוד ביקורות",
    "rep_dashboard_total_Review_txt": "סקירה כוללת",
    "rep_dashboard_total_positive_Review_txt": "סקירה חיובית בסך הכל",
    "rep_dashboard_negative_feedback_txt": "משוב שלילי",
    "rep_dashboard_connection_txt": "קֶשֶׁר",
    "rep_dashboard_question_wise_analytics_txt": "שאלה ב-Wise Analytics",
    "rep_dashboard_date_label": "בחר תאריך",
    "rep_dashboard_custom_range_txt": "טווח מותאם אישית",
    "rep_tlt_min": "REP",
    "emojis": "אמוג'ים",
    "media_text": "הוסף מדיה",
    "custom_field_text": "הוסף שדות מותאמים אישית",
    "shortlink_text": "הוסף קישור קצר",
    "use_template_text": "השתמש בתבנית",
    "banner_must_have": "חובה",
    "res_seamless": "ללא תפרים",
    "res_table_reservation": "הזמנת שולחן",
    "res_system": "מַעֲרֶכֶת",
    "res_text": "הזמנות",
    "md_easily": "בְּקַלוּת",
    "md_manage_customers": "ניהול לקוחות",
    "md_visits_interactions": "ביקורים ואינטראקציות",
    "md_desc": "הפוך את האוכל לקלה יותר עבור הלקוחות שלך עם הזמנת שולחן בזמן אמת. נהל הזמנות ללא מאמץ, צמצם את זמני ההמתנה והבטח חוויה חלקה הן לצוות והן לאורחים. שמור על המסעדה שלך בתפוסה מלאה תוך שמירה על שירות ושביעות רצון מהשורה הראשונה.",
    "engage_convert": "מעורב והמר SMS",
    "coupon_campaign": "וקמפיין קופונים",
    "sms_read_rate": "הודעות SMS נקראות 98% מהזמן",
    "real_time_promo": "לספק מבצעים בזמן אמת לפעולה מיידית",
    "affordable_reach": "דרך משתלמת להגיע ללקוחות עם החזר ROI מוצק",
    "stay_in_touch": "שמור על קשר עם הלקוח שלך בכל עת",
    "bc_engage_customers": "Engage Customers המר SMS",
    "bc_coupon_campaign": "וקמפיין קופונים",
    "bc_sms_read_rate": "הודעות SMS נקראות 98% מהזמן",
    "bc_real_time_promo": "לספק מבצעים בזמן אמת לפעולה מיידית",
    "bc_affordable_reach": "דרך משתלמת להגיע ללקוחות עם החזר ROI מוצק",
    "bc_stay_in_touch": "שמור על קשר עם הלקוח שלך בכל עת",
    "ar_automate_engagement": "אוטומציה של מעורבות הלקוח שלך",
    "ar_with": "עִם",
    "ar_with_auto_responders": "מגיבים אוטומטיים",
    "ar_personalized_messages": "שלח באופן אוטומטי הודעות מותאמות אישית לימי הולדת, קבלו לקוחות חדשים והתחבר מחדש עם לקוחות קודמים. חסוך זמן תוך שמירה על כל אינטראקציה משמעותית ובזמן.",
    "sq_custom_forms_tools": "טפסים וכלים מותאמים אישית - פשט",
    "sq_custom_forms_ci": "אינטראקציות עם לקוחות",
    "sq_smart_qr_desc": "צור טפסים מותאמים אישית, אסוף חתימות אלקטרוניות ומנף קודי QR חכמים כדי לייעל את התהליכים העסקיים שלך. התאם כל כלי כדי לענות על צרכי הלקוחות שלך ולשפר את חווית המשתמש.",
    "gnc_get_new_cus": "קבל לקוחות חדשים",
    "gnc_get_customers": "ממדיה חברתית - מהיר וקל!",
    "gnc_social_media_power": "השתמש בכוחן של פלטפורמות חברתיות כדי למשוך לקוחות חדשים ולהניע מכירות ישירות לעסק שלך. הפנה מחדש הזמנות משלוחים של צד שלישי ישירות לחנות או לפלטפורמת ההזמנה המקוונת שלך לשליטה והכנסה מקסימלית.",
    "wifi_connect_guests": "חבר אורחים עם",
    "wifi_smart": "Wi-Fi חכם",
    "wifi_connect_grow": "&הגדל את התובנות שלך",
    "wifi_smart_wifi_desc": "ספק חיבור Wi-Fi ללא בעיות ללקוחות שלך באמצעות מסך פתיחה ממותג תוך איסוף נתוני לקוחות יקרי ערך כדי לשפר את מאמצי השיווק והמעורבות שלך.",
    "wifi_create_splash_page": "צור עמוד Wifi Splash לאורחים",
    "instant_menu_create": "צור את שלך",
    "instant_menu_text": "תפריט מיידי",
    "instant_menu_wm": "תוך דקות",
    "instant_menu_desc": "משפר את חוויות הלקוח על ידי מתן עדכונים בזמן אמת, התאמה אישית קלה ומעורבות משופרת, מה שמקל על הצגת ההצעות שלך ושמירה על רעננות התוכן שלך.",
    "instant_menu_create_digital_menu": "צור תפריט דיגיטלי",
    "loyalty_program_rewards": "להרוויח פרסים",
    "loyalty_program_on_visits": "בכל ביקור",
    "loyalty_program_create": "צור תוכנית נאמנות",
    "reputation_create": "צור מוניטין",
    "reputation_boost": "שפר את שלך",
    "reputation_text": "מוֹנֵיטִין",
    "reputation_reviews": "עם עוד ביקורות!",
    "reputation_desc": "אסוף משוב בעל ערך מהלקוחות שלך כדי לשפר את השירות והמוניטין שלך. שתף את החוויה שלך ועזור לנו לצמוח - הביקורת שלך חשובה!",
    "dashboard": "לוּחַ מַחווָנִים",
    "blast_campaign": "קמפיין פיצוץ",
    "blast_campaigns": "קמפיינים לפיצוצים",
    "blast_campaign_sub_title": "הפעל מסעות פרסום פיצוץ כדי להגיע לכל הלקוחות שלך בכמה קליקים",
    "auto_responders": "מגיבים אוטומטיים",
    "auto_responder": "מגיב אוטומטי",
    "auto_responder_sub_title": "קל לשימוש במבצעים מבוססי אירועים עבור הלקוחות שלך",
    "smart_qr_form": "QR \/ טופס חכם",
    "get_new_customer": "קבל לקוח חדש",
    "marketing": "שיווק",
    "clients": "לקוחות",
    "group_data": "קבוצות נתונים",
    "clients_data": "נתוני לקוחות",
    "account": "חֶשְׁבּוֹן",
    "user_acc_info": "פרטי חשבון משתמש",
    "current_plan": "תוכנית נוכחית",
    "payments": "תשלומים",
    "location": "מִקוּם",
    "location_list": "רשימת מיקומים",
    "users": "משתמשים",
    "users_sub_title": "רשימת כל המשתמשים והפרטים שלהם",
    "online_profile": "פרופיל מקוון",
    "change_password": "שנה סיסמה",
    "menu": "תַפרִיט",
    "reseller_dashboard": "לוח מחוונים למפיץ",
    "companies": "חברות",
    "plans_bought": "תוכניות קנו",
    "agreement_t_c": "T&C הסכם",
    "wifi_dashboard": "לוח מחוונים של Wifi",
    "hot_spot": "נקודה חמה",
    "splash_pages": "Splash Pages",
    "get_more_reviews": "קבל עוד ביקורות",
    "office_supplies": "ציוד משרדי",
    "reservation": "הַזמָנָה",
    "floor_table": "רצפה ושולחן",
    "guest_smart_wifi": "WiFi חכם לאורחים",
    "digital_signage": "שילוט דיגיטלי",
    "schedule_down": "לוח זמנים",
    "business_settings": "הגדרות עסקיות",
    "sub_user_login": "כניסה למשתמש משנה",
    "help_center": "מרכז העזרה",
    "switch_to_hub": "עבור אל Hub",
    "meeting_records": "פגישה שיאים",
    "logout": "התנתק",
    "no_access_text": "אין לך גישה כרגע. אנא פנה למנהל המערכת שלך",
    "menu_item": "פריט תפריט",
    "menu_capital": "תַפרִיט",
    "good_day": "יום טוב",
    "no_permission_text": "אין לך הרשאה לבצע פעולה זו!!",
    "enter_valid_pin": "הזן PIN חוקי",
    "add_reservation": "הוסף הזמנה",
    "edit_reservation": "ערוך הזמנה",
    "per_req": "נדרש מספר אנשים",
    "req_block_time": "נדרש זמן חסימה",
    "pending_status": "תָלוּי וְעוֹמֵד",
    "booked_status": "הוזמן",
    "completed_status": "הושלם",
    "cancelled_status": "בּוּטלָה",
    "reservation_slot": "משבצת הזמנה",
    "block_time": "זמן חסימה",
    "slot_time": "זמן משבצת הזמנה:  ",
    "template_name_req": "נדרש שם תבנית",
    "template_type_req": "נדרש סוג התבנית",
    "sms_template_req": "נדרשת תבנית SMS",
    "group_req": "נדרשת קבוצה",
    "valid_date_time": "הזן תאריך ושעה חוקיים",
    "valid_group_name": "הזן שם קבוצה חוקי",
    "reservation_mark_completed": "הזמנה זו תסומן כמושלמה.",
    "customer_name": "שם הלקוח",
    "date_time": "תאריך שעה",
    "table_no": "טבלה מס'",
    "floor": "קוֹמָה",
    "customer_name_placeholder": "שם הלקוח",
    "date_time_placeholder": "תאריך_שעה",
    "table_no_placeholder": "table_no",
    "floor_placeholder": "קוֹמָה",
    "select_floor": "בחר קומה",
    "select_table": "בחר טבלה",
    "customer_name_macro": "%% customer_name %%",
    "date_time_macro": "%% date_time %%",
    "table_no_macro": "%% table_no %%",
    "floor_macro": "%% קומה %%",
    "template_name": "שם התבנית",
    "login_with_4_digit_pin": "התחבר עם סיכה בת 4 ספרות שלך",
    "login_now_to_access_latest_insights": "התחבר עכשיו כדי לגשת לסימנים האחרונים",
    "mkt_BS_Title": "SMS פיצוץ",
    "step_1": "התאמה אישית של הודעה",
    "step_2": "בחר קהל",
    "step_3": "הודעת לוח זמנים",
    "step_4": "שלח הודעה",
    "step_5": "נהל הודעה",
    "bs_tab_title": "סיכום קמפיין פיצוץ",
    "default_message": "היי %% firstname %%, הנחה מיוחדת על בראנץ'! סעדו איתנו היום וקבלו 10% הנחה בלעדית על החשבון שלכם!",
    "bs_Title": "בחר סוג מסע פרסום",
    "sms_title": "SMS\/MMS",
    "reach_out_to_customer": "פנה ללקוחות באמצעות הודעות טקסט ומולטימדיה.",
    "wp_text": "וואטסאפ",
    "connect_with_wp": "התחבר ללקוחות שלך באמצעות WhatsApp 💬",
    "send_discount_offers": "שלח הצעות הנחות באמצעות SMS 📲",
    "send_coupons_via_sms": "שלחו קופונים ב-SMS ללקוחות שלכם 🎟️",
    "env_Cap": "הצע הנחות ומבצעים בלעדיים כדי לפתות את הלקוחות שלך",
    "cmt_Caption": "פנה ללקוחות באמצעות הודעות טקסט ומולטימדיה",
    "wp_caption": "התחבר עם לקוחות באמצעות WhatsApp לתקשורת חלקה וישירה",
    "msg_Prev_Title": "תצוגה מקדימה של הודעה",
    "cou_Prev_Title": "תצוגה מקדימה של קופון",
    "blast_SMS": "קמפיין SMS\/MMS",
    "bs_P_Msg": "בתור",
    "bs_S_Msg_C": "נִכשָׁל",
    "bs_F_Msg_Count": "נמסר",
    "previous": "קוֹדֵם",
    "next_ST": "הַבָּא",
    "next_S": "השלב הבא",
    "req_Temp_Name_Title": "נדרש שם תבנית",
    "req_Template_Tx_Title": "נדרש טקסט של תבנית",
    "add_Temp_Title": "הוסף תבנית",
    "link_Desc": "הוסף קישור לבחירתך כדי שלקוחות יוכלו להזמין איתך דרך פייסבוק, גוגל או אתר האינטרנט שלך.",
    "audience_Title": "קהל",
    "client_G1_Title": "לקוחות חדשים",
    "client_G2_Title": "לקוחות אחרונים",
    "client_G3_Title": "לקוחות נאמנים",
    "client_G4_Title": "לקוחות פגומים",
    "client_G5_Title": "לקוחות לפי תאריך חיבור",
    "on_Which_Day": "באיזה יום",
    "on_Day": "ביום",
    "immediate_Desc": "ההודעה שלך תישלח מיד, אנא לחץ על הבא כדי להתחיל.",
    "never_Desc": "מסע פרסום זה מתוכנן לפעול לנצח עד שתסיים אותו באופן ידני.",
    "end_Desc": "הקמפיין יסתיים בתאריך שתבחרו מכאן.",
    "success_Message": "הקמפיין נשמר בהצלחה!",
    "send_Success_Message": "הקמפיין נשלח בהצלחה!",
    "msg_Name_Field_Title": "כותרת מסע הפרסום",
    "valid_campaign_title": "הזן כותרת חוקית של מסע הפרסום",
    "msg_Type_Title": "סוג הודעה",
    "sms_Desc": "זיכוי 1 לכל SMS",
    "mms_Desc": "3 זיכויים לכל MMS",
    "client_GTO_1": "יְוֹם",
    "client_GTO_2": "שעות",
    "minutes": "פּרוֹטוֹקוֹל",
    "client_GTO_4": "שָׁבוּעַ",
    "time_Option_1": "יְוֹם",
    "time_Option_2": "שעות",
    "c_Grp_Day_1": "90 ימים",
    "c_Grp_Day_2": "60 ימים",
    "c_Grp_Day_3": "30 ימים",
    "first": "רֵאשִׁית",
    "second_T": "שְׁנִיָה",
    "third_T": "שְׁלִישִׁי",
    "fourth_T": "רְבִיעִית",
    "fifth_T": "חֲמִישִׁית",
    "delete_BS_T": "מסע פרסום זה יימחק לצמיתות.",
    "delete_MBS_T": "מסעות פרסום אלה יימחקו לצמיתות.",
    "cFT_1": "טֵלֵפוֹן",
    "cFT_2": "שם פרטי ליצירת קשר",
    "cFT_3": "שם משפחה ליצירת קשר",
    "cFT_4": "אימייל ליצירת קשר",
    "cFT_5": "פֶּתֶק",
    "cFT_6": "יוֹם הוּלֶדֶת",
    "cFT_7": "שם החברה",
    "cF_Meta_Tag_1": "%% טלפון %%",
    "cF_Meta_Tag_2": "%% שם פרטי %%",
    "cF_Meta_Tag_3": "%% שם משפחה %%",
    "cF_Meta_Tag_4": "%% מייל %%",
    "cF_Meta_Tag_5": "הערה %% %%",
    "cF_Meta_Tag_6": "%% יום הולדת %%",
    "cF_Meta_Tag_7": "%% company_name %%",
    "cF_Name_1": "טֵלֵפוֹן",
    "cF_Name_2": "שם פרטי",
    "cF_Name_3": "שם משפחה",
    "cF_Name_4": "אֶלֶקטרוֹנִי",
    "cF_Name_5": "פֶּתֶק",
    "cF_Name_6": "יוֹם הוּלֶדֶת",
    "cF_Name_7": "company_name",
    "all_clients": "AllClients",
    "aud_Type_2": "קבוצות לקוחות",
    "aud_Type_3": "קבוצות לקוחות",
    "aud_Type_T1": "כל הלקוחות",
    "aud_Type_T2": "קבוצות לקוחות",
    "aud_Type_T3": "קבוצות לקוחות",
    "new_clients": "לקוחות חדשים",
    "client_G2": "לקוחות אחרונים",
    "client_G3": "לקוחות נאמנים",
    "client_G4": "לקוחות פגומים",
    "client_G5": "לקוחות לפי תאריך חיבור",
    "never_text": "לְעוֹלָם לֹא",
    "ending_on": "מסתיים ב",
    "send_T2": "מתוזמן",
    "send_Type_3": "מַחזוֹרִי",
    "daily": "יוֹמִי",
    "weekly_T": "שְׁבוּעִי",
    "monthly_T": "יַרחוֹן",
    "yearly_T": "שְׁנָתִי",
    "each": "כֹּל",
    "on_the": "על ה",
    "monday": "יוֹם שֵׁנִי",
    "exclusive_offer": "הצעה בלעדית עבורך",
    "redeem_now": "פדה עכשיו",
    "redeem_with_cashier": "נא לממש עם הקופאית",
    "lg_Day_2": "יוֹם שְׁלִישִׁי",
    "lg_Day_3": "יוֹם רְבִיעִי",
    "lg_Day_4": "יוֹם חֲמִישִׁי",
    "lg_Day_5": "יוֹם שִׁישִׁי",
    "lg_Day_6": "שַׁבָּת",
    "lg_Day_7": "יוֹם רִאשׁוֹן",
    "msg_Pre_T": "דומינוס",
    "cA_Edit_T1": "כֹּל",
    "cA_Edit_T2": "כּוֹלֵל",
    "cA_Edit_T3": "לא נכלל",
    "SMS": "SMS",
    "MMS": "MMS",
    "usd_T": "דולר ארה\"ב",
    "cad_T": "CAD",
    "msg": "הוֹדָעָה",
    "which_Day": "אילו ימים",
    "end_Date_Tx": "תאריך סיום",
    "sDate_Err": "תאריך ההתחלה חייב להיות גדול או שווה להיום",
    "eDate_Err": "תאריך הסיום חייב להיות מאוחר מתאריך ההתחלה",
    "start_Date_Req": "נדרש תאריך התחלה",
    "end_Date_Req": "נדרש תאריך סיום",
    "time_req": "נדרש זמן",
    "client_GT1": "לקוחות נרשמו לאחרונה",
    "client_GT2": "לקוחות עם חיבור באחרון",
    "client_GT3": "לקוחות עם לפחות",
    "client_GT4": "עם האחרון",
    "client_GT5": "שלא חזר באחרונה",
    "dummy_Phone_No": "+91987-654-3210",
    "test_T": "מִבְחָן",
    "dummy_Birth_Date": "01-01-2001",
    "image_Req": "נדרשת מדיה או כתובת אתר",
    "time_Title": "זְמַן",
    "start_date": "תאריך התחלה",
    "end_date": "תאריך סיום",
    "auto_Send_T": "שליחה אוטומטית ללקוחות",
    "add_Media": "הוסף מדיה",
    "in_Title": "ב",
    "c_Temp_T": "תבנית מסע פרסום",
    "temp_NT": "שם התבנית",
    "type_T": "סוּג",
    "select_template_type": "בחר סוג תבנית",
    "sel_Temp_T": "בחר סוג תבנית",
    "sms_temp_t": "תבנית SMS",
    "temp_T": "טקסט תבנית",
    "default_Msg": "היי %% firstname %%, הנחה מיוחדת על בראנץ'! סעדו איתנו היום וקבלו 10% הנחה בלעדית על החשבון שלכם!",
    "refill_T": "מִלוּי",
    "avl_Credit": "קרדיטים זמינים",
    "req_Credit": "סך הזיכויים הנדרשים",
    "rem_Credit": "אשראי שנותר",
    "refill_Credit": "קרדיטים למילוי מחדש",
    "clients_Req": "בחר לקוח אחד לפחות",
    "subclient_Req": "לקוחות נבחרים אינם מנויים.",
    "refill_Desc": "נא למלא מחדש את הזיכויים הנדרשים",
    "url_Regex": "^((http|https):\/\/).....+$",
    "sd_Invalid": "תאריך ההתחלה אינו חוקי",
    "ed_Invalid": "תאריך הסיום אינו חוקי",
    "img_Url_Invalid": "כתובת אתר לא חוקית של תמונה",
    "time_Invalid": "הזמן אינו חוקי",
    "time_And_Date_Invalid": "הזן תאריך ושעה חוקיים",
    "time_Invalid_Bfr": "בחר זמן לפחות 5 דקות מאוחר יותר מהשעה הנוכחית",
    "sod_Req": "יש צורך לשלוח ביום",
    "add_Card": "הוסף כרטיס חדש",
    "sm_D_Rep_Title": "דוח מסירה של SMS\/MMS",
    "send_Now_T": "שלח עכשיו",
    "schd_Now_T": "תזמן SMS",
    "test_SMS_T": "שלח SMS לבדיקה",
    "save_AD_T": "שמור כטיוטה",
    "back_TE_T": "חזרה לעריכה",
    "reset_tex": "אִתחוּל",
    "update_tex": "לְעַדְכֵּן",
    "dum_msg1": "כדי להציג את שם הלקוח, השתמש ב %% customer_name %% בתבנית",
    "dum_msg2": "כדי להציג את זמן ההזמנה, השתמש ב %% date_time %% בתבנית",
    "dum_msg3": "כדי להציג את מספר הטבלה, השתמש ב %% table_no %% בתבנית",
    "embedded_link": "קישור מוטבע",
    "img_title": "תְמוּנָה",
    "img_input_text": "טֶקסט:",
    "img_join_text": " להצטרף לרשימה שלנו",
    "img_copy_text": "לְהַעְתִיק",
    "img_not_found": "התמונה שנוצרה לא נמצאה!!",
    "or_text": "אוֹ",
    "web_signup_image_content": "על ידי שליחת טופס זה והרשמה באמצעות טקסט, אתה מסכים לקבל הודעות טקסט שיווקיות (כגון קודי קידום מכירות ותזכורות). ייתכן שיחולו תעריפי הודעות ונתונים. תדירות ההודעות משתנה. אתה יכול לבטל את המנוי בכל עת על ידי מענה STOP",
    "img_download_success": "התמונה הורדה בהצלחה",
    "embedded_link_copied": "הקישור המוטבע הועתק בהצלחה",
    "media_url_required": "נדרשת מדיה או כתובת אתר",
    "invalid_image_url": "כתובת אתר לא חוקית של תמונה",
    "invalid_file": "הקובץ לא חוקי",
    "image_error_1mb": "נא להכניס תמונה שגודלה פחות מ-1MB",
    "image_error_2_5mb": "נא להכניס תמונה שגודלה פחות מ-2.5MB",
    "image_error_3mb": "נא להכניס תמונה שגודלה פחות מ-3MB",
    "change_title": "לְשַׁנוֹת",
    "drop_file_text": "שחרר את הקובץ כאן או לחץ כדי להעלות",
    "apply_text": "לִפְנוֹת",
    "search_here": "חפש כאן",
    "update_status": "עדכון סטטוס",
    "reservation_deleted_permanently": "הזמנה זו תימחק לצמיתות.",
    "table_text": "טבלאות",
    "add_table_button": "הוסף טבלה חדשה",
    "add_table_text": "הוסף טבלה",
    "edit_table_text": "ערוך טבלה",
    "table_delete_text": "טבלה זו תימחק לצמיתות.",
    "multiple_table_delete_text": "טבלאות אלו יימחקו לצמיתות.",
    "table_error_name": "נא להזין את שם הטבלה",
    "table_error_invalid_name": "נא להזין שם טבלה חוקי",
    "table_error_floor": "אנא בחר את הקומה",
    "table_error_select": "נא בחר את הטבלה",
    "table_capacity_text": "קיבולת שולחן",
    "capacity_text": "יְכוֹלֶת",
    "table_occasion_text": "מצא את השולחן שלך לכל אירוע",
    "table_name_text": "שם הטבלה",
    "table_capacity_error": "נדרשת קיבולת שולחן",
    "cancel_text": "לְבַטֵל",
    "submit_text": "לְהַגִישׁ",
    "select_valid_date": "בחר תאריך חוקי",
    "select_valid_time": "בחר זמן חוקי",
    "valid_contact_number": "הזן מספר איש קשר חוקי",
    "date_req": "נדרש תאריך",
    "date_error_later_than_today": "התאריך חייב להיות מאוחר או שווה לתאריך של היום",
    "time_error_later_than_5_minutes": "בחר זמן לפחות 5 דקות מאוחר יותר מהשעה הנוכחית",
    "number_person_req": "נדרש מספר אדם",
    "contact_no_req": "נדרש מספר איש קשר",
    "contact_no": "מספר איש קשר",
    "select_time": "בחר זמן",
    "for_how_many_person": "לכמה אנשים?",
    "f_name": "שֵׁם פְּרַטִי",
    "l_name": "שֵׁם מִשׁפָּחָה",
    "business_name": "שם העסק",
    "business_no": "מספר עסק",
    "no_for_sign_in": "מספר זה משמש לכניסה",
    "business_email": "אימייל עסקי",
    "notes_tex": "הערות",
    "floor_deleted_permanently": "קומה זו תימחק לצמיתות.",
    "add_floor": "הוסף קומה",
    "edit_floor": "ערוך קומה",
    "name_text": "שֵׁם",
    "mobile_no": "מספר נייד",
    "person_text": "אָדָם",
    "date_and_time": "תאריך ושעה",
    "actions_text": "פעולות",
    "extra_text": "תוֹסֶפֶת",
    "floor_name_req": "נדרש שם קומה",
    "floor_name": "שם קומה",
    "status_text": "סטָטוּס",
    "category_status_req": "נדרש סטטוס קטגוריה",
    "table_deleted_permanently": "טבלה זו תימחק לצמיתות.",
    "tables_deleted_permanently": "טבלאות אלו יימחקו לצמיתות.",
    "back_to_home": "חזרה לבית",
    "link_copied_text": "הקישור הועתק ללוח",
    "cust_dash_head_to_title": "אֶל",
    "cust_dash_head_location_title": "מִקוּם",
    "select_location_title": "בחר מיקום",
    "all_locations_label": "all_locations",
    "rep_customer_feedback_analytics": "😊 עקוב אחר משוב הלקוחות שלך עם ניתוח בזמן אמת",
    "rep_customer_txt": "לקוחות",
    "rep_delete_title": "מוניטין זה יימחק לצמיתות.",
    "rep_qr_def_txt": "סרוק את קוד ה-QR ושתף איתנו את המשוב היקר שלך לגבי החוויה שלך.",
    "delete_title": "לִמְחוֹק",
    "user_list_title": "רשימת לקוחות",
    "os_nfc_txt": "כרטיסי NFC",
    "os_sign_holder_txt": "מעמד למחזיק שלטים",
    "os_store_Sign_holder_txt": "מחזיקי שלטים לחנות",
    "StaT": "סטָטִיסטִיקָה",
    "AllgT": "כל הקבוצות",
    "FavT": "מוּעֲדָף",
    "MostActT": "הכי פעיל",
    "grT": "שם הקבוצה",
    "keywT": "מילות מפתח",
    "exSubT": "הודעה למנוי קיים",
    "ArchT": "בארכיון",
    "gNotiSms": "הודע לי על כל איש קשר חדש באמצעות SMS",
    "gNotiEmail": "הודע לי על כל איש קשר חדש באמצעות דואר אלקטרוני",
    "reqGName": "נדרש שם הקבוצה",
    "validGN": "הזן שם קבוצה חוקי",
    "valid_phone_no": "הזן מספר טלפון חוקי",
    "phone_no_req": "נדרש מספר טלפון",
    "required_message_text": "נדרשת הודעה",
    "required_discount_text": "נדרשת הנחה",
    "less_than_discount": "סכום ההנחה חייב להיות נמוך מ-5000",
    "discount_percentage_invalid": "אחוז ההנחה חייב להיות נמוך או שווה ל-100",
    "discount_type_req": "נדרש סוג הנחה",
    "discount_text_req": "נדרש טקסט של קופון",
    "reqContactNo": "נדרש מספר איש קשר",
    "reqMsgNT": "נדרשת כותרת מסע הפרסום",
    "reqLinkT": "נדרש קישור",
    "delMGT": "קבוצה זו תימחק לצמיתות.",
    "defMemMsg": "הקלד את הודעתך כאן",
    "add_cust_to_grp_title": "הוסף לקוח לקבוצה",
    "group_title": "קבוצות",
    "group_create_title": "צור קבוצה",
    "group_name_txt": "שם הקבוצה",
    "group_table_keyword_title": "מילת מפתח",
    "actions_title": "פעולות",
    "clients_title": "לקוחות",
    "send_title": "לִשְׁלוֹחַ",
    "qr_title": "QR",
    "delete_group_txt": "קבוצה זו תימחק לצמיתות.",
    "delete_groups_txt": "קבוצה זו תימחק לצמיתות.",
    "delete_client_title": "לקוח זה יימחק לצמיתות.",
    "delete_clients_title": "לקוח זה יימחק לצמיתות.",
    "delete_multiple_title": "מחק מרובה",
    "wel_sms_mms": "לשלוח SMS\/MMS ברוכים הבאים?",
    "key_words_title": "מילות מפתח",
    "srch_plch_txt": "חפש כאן",
    "req_location_id_title": "מיקום נדרש",
    "create_text": "לִיצוֹר",
    "view_text": "נוֹף",
    "immediately": "מִיָד",
    "business_name_req": "נדרש שם העסק",
    "business_no_req": "נדרש מספר עסק",
    "valid_business_name": "הזן שם עסק חוקי",
    "valid_business_no": "הזן מספר עסק חוקי",
    "address_req": "כתובת נדרשת",
    "valid_address": "אנא בחר כתובת חוקית",
    "time_zone_req": "נדרש אזור זמן",
    "image_req": "נא להכניס תמונה שגודלה פחות מ-2.5MB",
    "valid_file": "הקובץ לא חוקי.",
    "logo": "סֵמֶל",
    "time_zone": "אזור זמן",
    "save": "לְהַצִיל",
    "account_type_req": "נדרש סוג חשבון",
    "gst_no_req": "נדרש מספר GST",
    "gst_no_valid": "הזן מספר GST חוקי",
    "set_up_payments": "הגדר תשלומים",
    "billing_details": "הזן את פרטי החיוב שלך",
    "billing_details_desc": "פרטי החיוב שלך יוצגו בחשבונית החודשית שלך מ-menuonline.",
    "account_type": "סוג חשבון",
    "select_account_type": "בחר סוג חשבון",
    "gst_no": "מספר GST",
    "transaction_details": "פרטי עסקה",
    "payment_method": "אמצעי תשלום",
    "billing_period": "תקופת חיוב",
    "paid_by": "בתשלום על ידי",
    "download": "הורד",
    "pay_now": "שלם עכשיו",
    "pull_down_refresh": "משוך למטה כדי לרענן",
    "release_refresh": "שחרר כדי לרענן",
    "billing_details_text": "פרטי חיוב",
    "payment_methods": "דרכי תשלום",
    "invoice": "חֶשׁבּוֹנִית",
    "invoice_to": "חשבונית ל:",
    "issue_date": "תאריך הנפקה",
    "due_date": "תאריך יעד",
    "amount_due": "סכום לתשלום",
    "charges": "חיובים",
    "sub_total_capitalize": "תת סך הכל",
    "hst": "HST",
    "grand_total": "סך הכל",
    "invoice_generated_on": "חשבונית נוצרה ב",
    "contact_us": "צור קשר",
    "invoice_issue": "אם עברת על החשבון שלך ועדיין יש לך שאלה",
    "call": "שִׂיחָה",
    "send_sms": "שלח SMS",
    "payment_success": "התשלום עבר בהצלחה",
    "edit_credit_card": "ערוך כרטיס אשראי",
    "add_credit_card": "הוסף כרטיס אשראי",
    "modify_card_info": "שנה את פרטי הכרטיס שלך",
    "enter_card_info": "הזן את פרטי הכרטיס שלך",
    "account_no_req": "נדרש מספר חשבון",
    "card_name_req": "נדרש שם בכרטיס",
    "expiry_date_req": "נדרש תאריך תפוגה",
    "valid_expiry_date": "הזן תאריך תפוגה חוקי",
    "valid_cvv": "הזן cvv חוקי",
    "cvv_req": "נדרש CVV",
    "card_no": "מספר כרטיס",
    "name_of_card": "השם בכרטיס",
    "expiry": "תְפוּגָה",
    "mm_text": "MM\/YY",
    "cvv": "CVV",
    "set_as_default": "הגדר כברירת מחדל",
    "add_new_card": "הוסף כרטיס חדש",
    "all_credit_card": "כל כרטיסי האשראי שלך",
    "fastest_checkout": "הצ'ק-אאוט המהיר ביותר עם פלטפורמה מאובטחת ואמינה, מהימן על ידי קבוצה גדולה של עסקים",
    "coupon_button_req": "נדרש טקסט לחצן קופון",
    "max19_characters_allowed": "מותרים 19 תווים לכל היותר",
    "fine_print_text_req": "נדרש טקסט באותיות קטנות",
    "clients_req": "בחר לקוח אחד לפחות",
    "client_groups": "קבוצות לקוחות",
    "day_text": "יְוֹם",
    "valid_days": "ערך היום גדול מדי הזן ימים חוקיים",
    "hours_text": "שעות",
    "enter_valid_hours": "ערך השעות גדול מדי הזן שעות חוקיות",
    "enter_valid_min": "ערך הדקות גדול מדי הזן דקות חוקיות",
    "clients_with_connection_required": "נדרשים לקוחות עם חיבור באחרון",
    "connection_required": "נדרש חיבור",
    "connection_value_too_large": "ערך החיבורים גדול מדי הזן חיבור חוקי",
    "minutes_required": "נדרשות דקות",
    "clients_with_at_least_connection_required": "נדרשים לקוחות עם לפחות חיבור",
    "last_minutes_req": "עם הדקות האחרונות נדרש",
    "clients_not_returned_required": "מי שלא חזר בדקות האחרונות נדרש",
    "not_return_in_last_minutes_invalid": "אי החזרה בדקות האחרונות חייבת להיות פחותה מהדקות האחרונות.",
    "customerGroups": "קבוצות לקוחות",
    "select_customer_group": "אנא בחר את קבוצת הלקוחות",
    "location_required": "נדרש מיקום",
    "start_date_required": "נדרש תאריך התחלה",
    "start_date_invalid": "תאריך ההתחלה אינו חוקי",
    "start_date_invalid_error": "תאריך ההתחלה חייב להיות גדול יותר אז או שווה להיום",
    "recurring": "מַחזוֹרִי",
    "end_date_required": "נדרש תאריך סיום",
    "end_date_invalid": "תאריך הסיום אינו חוקי",
    "end_date_invalid_error": "תאריך הסיום חייב להיות מאוחר מתאריך ההתחלה",
    "time_invalid": "הזמן אינו חוקי",
    "monthly_text": "יַרחוֹן",
    "send_day_req": "יש צורך לשלוח ביום",
    "loyal_clients": "לקוחות נאמנים",
    "recent_clients": "לקוחות אחרונים",
    "lapsed_clients": "לקוחות פגומים",
    "clients_connection_date": "לקוחות לפי תאריך חיבור",
    "scheduled_text": "מתוזמן",
    "weekly": "שְׁבוּעִי",
    "selected_clients_not_subscriber": "לקוחות נבחרים אינם מנויים.",
    "message_preview_title": "תצוגה מקדימה של הודעה",
    "coupon_preview_title": "תצוגה מקדימה של קופון",
    "form_text": "טוֹפֶס",
    "preview_text": "תצוגה מקדימה",
    "next_text": "הַבָּא",
    "send_test_SMS": "שלח SMS לבדיקה",
    "save_draft": "שמור כטיוטה",
    "back_to_edit": "חזרה לעריכה",
    "select_payment_method": "אנא בחר את אמצעי התשלום",
    "schedule_SMS": "תזמן SMS",
    "send_now": "שלח עכשיו",
    "get_more_ratings": "קבל עוד דירוגים",
    "overview": "סקירה כללית",
    "reset_campaign": "אפס מסע פרסום",
    "permissions": "הרשאות",
    "location_name": "שם המיקום",
    "phone_no": "מספר טלפון",
    "location_email_address": "מיקום כתובת דוא\"ל",
    "located_business": "איפה העסק שלך ממוקם?",
    "business_logo": "לוגו העסק",
    "congratulations": "מזל טוב",
    "almost_done": "כמעט סיימת",
    "publish": "לְפַרְסֵם",
    "about_your_business": "על העסק שלך",
    "add_your_location": "הוסף את המיקום שלך",
    "publish_location": "פרסם מיקום",
    "location_name_req": "נדרש שם מיקום",
    "valid_location_name": "הזן שם מיקום חוקי",
    "business_logo_req": "דרוש לוגו לעסק",
    "please_accept_terms": "אנא קבל את התנאים וההגבלות",
    "add_new_location": "הוסף מיקום חדש",
    "edit_location": "ערוך מיקום",
    "add_location": "הוסף מיקום",
    "existing_msg_subscriber_txt": "הודעה למנוי קיים",
    "msg_capitalize_txt": "הוֹדָעָה",
    "group_noti_sms": "הודע לי על כל איש קשר חדש באמצעות SMS",
    "group_noti_email": "הודע לי על כל איש קשר חדש באמצעות דואר אלקטרוני",
    "group_member_msg": "נראה שאתה כבר חלק מהקבוצה שלנו!!",
    "group_mend_msg": "עצור כדי לסיים. עזרה לעזרה. ייתכן שיחולו תעריפי SMS ונתונים",
    "Disclaimer_title": "טקסט כתב ויתור: ",
    "group_def_msg": "אנחנו מחוברים עכשיו! בדוק את תפריט השירות שלנו והזמן את הפגישה הבאה שלך עוד היום.",
    "required_msg_txt": "נדרשת הודעה",
    "required_Key_txt": "נדרשות מילות מפתח",
    "required_mem_msg": "נדרשת הודעת מנוי",
    "client_list_title": "רשימת לקוחות",
    "add_contact_txt": "הוסף איש קשר",
    "delete_all_clients_txt": "מחק את כל הלקוחות",
    "delete_all_clients_msg": "האם אתה בטוח שברצונך למחוק את כל הלקוחות? לא ניתן לאחזר אותם.",
    "delete_all_txt": "מחק הכל",
    "timeline_title": "קו זמן",
    "unsubscribe_title": "בטל את הרישום",
    "subscribe_title": "הירשם",
    "unsubscribe_confirm_msg": "האם ברצונך לסמן לקוח זה כמבטל מנוי?",
    "subscribe_confirm_msg": "האם תרצה לסמן לקוח זה כמנוי?",
    "no_title": "לֹא",
    "yes_title": "כֵּן",
    "client_name_title": "שם הלקוח",
    "source_title": "מָקוֹר",
    "contact_file_Req": "נדרש קובץ אנשי קשר",
    "req_title": "דָרוּשׁ",
    "opt_in_req": "אנשי קשר מיובאים חייבים להיות מאושרים ב-100%.",
    "image_invalid_error": "הקובץ לא חוקי.",
    "import_contacts_msg": "ייבוא ​​אנשי קשר",
    "csv_upload_title": "קובץ CSV אנשי קשר להעלאה *",
    "csv_file_desc": "נא להעלות קובץ CSV בלבד - עמודות קובץ CSV - שם פרטי, שם משפחה, מזהה דוא\"ל, מספר נייד, מגדר, DOB(MM\/DD\/YYYY) - ללא רווחים, מקפים או סוגריים במספר נייד",
    "to_download_title": "להורדת קובץ הדגמה לחץ כאן",
    "contains_header_title": "כן, הקובץ הזה מכיל כותרות",
    "opt_in_100_txt": "כן, אנשי הקשר המיובאים האלה הם 100% הסכמה",
    "DisT": "טקסט כתב ויתור: ",
    "gMendMsg": "עצור כדי לסיים. עזרה לעזרה. ייתכן שיחולו תעריפי SMS ונתונים",
    "reqKeyT": "נדרשות מילות מפתח",
    "reqMemMsg": "נדרשת הודעת מנוי",
    "reqMsgT": "נדרשת הודעה",
    "gMemberMsg": "נראה שאתה כבר חלק מהקבוצה שלנו!!",
    "gdefMsg": "אנחנו מחוברים עכשיו! בדוק את תפריט השירות שלנו והזמן את הפגישה הבאה שלך עוד היום.",
    "next_title": "הַבָּא",
    "male_title": "זָכָר",
    "male_val": "זָכָר",
    "female_title": "נְקֵבָה",
    "female_val": "נְקֵבָה",
    "others_txt": "אחרים",
    "others_val": "אחרים",
    "validBirthdate": "הזן תאריך לידה חוקי",
    "valid_phone_no_title": "הזן מספר טלפון חוקי",
    "required_phone_no_title": "נדרש מספר טלפון",
    "add_new_client_txt": "הוסף לקוח חדש",
    "update_client_txt": "עדכן לקוח",
    "phone_num_text": "מספר טלפון",
    "dob_title": "DOB",
    "select_gender_title": "בחר מגדר",
    "timelineTitle": "ציר זמן",
    "confirm_location": "אשר מיקום",
    "feedback_title": "מָשׁוֹב",
    "rep_question_1": "האם העסק היה נקי?",
    "rep_que_2": "האוכל שלך היה טוב?",
    "rep_que_3": "האם השירות שלנו היה ידידותי?",
    "rep_que_4": "האם השירות שלנו היה מהיר?",
    "business_location_req_title": "נדרש מיקום העסק",
    "valid_location_err_txt": "אנא בחר כתובת חוקית",
    "rep_management_title": "קבל עוד ניהול ביקורות",
    "rep_about_desc": "מוניטין עוזר לך להגדיל את דירוג Google על ידי בקשת משוב מהלקוחות שלך",
    "preview_title": "תצוגה מקדימה",
    "rep_preview_txt": "תצוגה מקדימה של מוניטין",
    "back_title": "בְּחֲזָרָה",
    "fine_print_txt": "נא לממש עם הקופאית",
    "redeem_me_title": "פדה אותי",
    "rep_nxt_visit_txt": "בביקור הבא שלך",
    "device_type": "סוג המכשיר בשימוש",
    "connection_method": "שיטת חיבור",
    "peak_days": "ימי שיא",
    "peak_hours": "שעות שיא",
    "guest_by_day": "אורח ביום",
    "guest_visit": "ביקור אורח",
    "connection": "קֶשֶׁר",
    "connection_duration": "משך חיבור",
    "guest_visit_1": "פעם אחת",
    "guest_visit_2": "2 פעמים",
    "guest_visit_3_5": "3-5 פעמים",
    "guest_visit_6_10": "6-10 פעמים",
    "guest_visit_11_25": "11-25 פעמים",
    "guest_visit_26_100": "26-100 פעמים",
    "guest_visit_100_plus": "100+ פעמים",
    "device_android_total": "סך הכל אנדרואיד",
    "device_android": "דְמוּי אָדָם",
    "device_desktop": "שולחן עבודה",
    "device_ios": "איוס",
    "device_ios_total": "סך הכל Ios",
    "device_desktop_total": "שולחן עבודה כולל",
    "connection_duration_10": "<=10 דקות",
    "connection_duration_20": "11-20 דקות",
    "connection_duration_30": "21-30 דקות",
    "connection_duration_40": "31-45 דקות",
    "connection_duration_60": "46-60 דקות",
    "connection_method_email": "אֶלֶקטרוֹנִי",
    "connection_method_sms": "SMS",
    "connection_method_google": "גוגל",
    "connection_method_facebook": "פייסבוק",
    "age_category_1": "<18",
    "age_category_2": "18-24",
    "age_category_3": "25-34",
    "age_category_4": "35-44",
    "age_category_5": "45-54",
    "age_category_6": "55+",
    "all_guest_txt": "כל אורח",
    "new_Guest_txt": "אורח חדש",
    "connections_txt": "חיבורים",
    "hotspot": "נקודה חמה",
    "hotspot_list": "רשימת נקודות חמות",
    "add_new_hotspot": "הוסף נקודה חמה חדשה",
    "hotspot_information": "מידע על נקודה חמה",
    "edit_details_button": "ערוך פרטים",
    "wifi_info_message": "התחבר ותיהנה מ-WiFi בחינם",
    "connection_message": "נהדר, התחברת ",
    "connection_message_suffix": " WiFi",
    "wifi": "WiFi",
    "login_to_access": "התחבר כדי לקבל גישה",
    "verification_code": "קוד אימות",
    "verification_code_message": "אנא הקלד את קוד האימות שנשלח אל ",
    "wifi_user_email": "adamo@gmail.com",
    "wifi_label": "Wifi",
    "your_name": "איך קוראים לך דרך אגב?",
    "your_birthdate": "אתה יכול להגיד לנו מה תאריך הלידה שלך?",
    "request_guest_wifi_name": "אנא הזן את שם ה-Wi-Fi של האורח",
    "request_device_key": "נא להזין מפתח מכשיר",
    "request_maximum_internet_access_length": "אנא בחר אורך גישה מרבי לאינטרנט",
    "mac_address": "כתובת MAC",
    "device_port": "יציאת מכשיר",
    "hardware": "חוּמרָה",
    "current_uptime": "זמן פעולה נוכחי",
    "nearby_devices": "מכשירים בקרבת מקום",
    "firmware": "קושחה",
    "who_are_you": "מי אתה?",
    "where_to_contact_you": "איפה אנחנו יוצרים איתך קשר?",
    "your_area_code": "מה אזור החיוג שלך?",
    "connected": "מְחוּבָּר",
    "delete_hotspot_message": "נקודה חמה זו תימחק לצמיתות.",
    "delete_multiple_hotspots_message": "נקודות חמות אלה יימחקו לצמיתות.",
    "speed_error": "המהירות צריכה להיות לפחות 0.01",
    "speed_max_error": "אנא הזן ערך של עד 1024 למהירות בלתי מוגבלת, או בחר ערך נמוך יותר בטווח הנתמך",
    "device_ssid": "SSID של מכשיר",
    "device_ssid_two": "התקן SSID שני",
    "device_ssid_two_wpa": "התקן SSID Two WPA",
    "device_key": "מפתח מכשיר",
    "select_location": "בחר מיקום",
    "select_maximum_internet_access_length": "בחר אורך גישה מרבי לאינטרנט",
    "download_speed": "הורדת מהירות",
    "upload_speed": "מהירות העלאה",
    "network_length_1": "15 דקות",
    "network_length_2": "30 דקות",
    "network_length_3": "45 דקות",
    "network_length_4": "1 שעה",
    "network_length_5": "2 שעות",
    "network_length_6": "4 שעות",
    "network_length_7": "6 שעות",
    "network_length_8": "8 שעות",
    "network_length_9": "10 שעות",
    "network_length_10": "12 שעות",
    "employee_wifi_name": "שם ה-Wifi של העובד",
    "employee_wifi_password": "סיסמת Wifi לעובד",
    "guest_wifi_name": "שם Wifi של אורח",
    "menu_other_products_txt": "מוצרים אחרים | תפריט מקוון",
    "menu_home_text": "בית | תפריט מקוון",
    "whatsapp_title": "וואטסאפ",
    "select_campaign_type": "בחר סוג מסע פרסום",
    "select_readymade_templates": "בחר מתוך תבניות מוכנות או הכן בעצמך",
    "campaign_title_required": "נדרשת כותרת מסע הפרסום",
    "type_here": "הקלד כאן...",
    "location_permission_req": "נדרשת הרשאת מיקום",
    "platform_permission_req": "נדרשת אישור לפלטפורמה",
    "profile_picture": "תמונת פרופיל",
    "click_to_upload": "לחץ כדי להעלות",
    "location_permission": "הרשאת מיקום",
    "pin": "פִּין",
    "platform_permission": "הרשאת פלטפורמה",
    "set_pin": "הגדר PIN",
    "list_of_users": "רשימת משתמשים",
    "create_user": "צור משתמש",
    "terms_and_condition_req": "תנאים והגבלות נדרשים",
    "terms_and_conditions": "תנאים והגבלות",
    "recommended": "מוּמלָץ",
    "mo": "\/מו",
    "unsubscribe": "בטל את הרישום",
    "cancel_subscription": "האם אתה בטוח שברצונך לבטל את המנוי?",
    "upgrade": "שדרוג",
    "plan_upgraded_successfully": "התוכנית שודרגה בהצלחה!",
    "menu_online": "תפריט אונליין",
    "support": "תְמִיכָה",
    "wifiC": "WIFI",
    "billing": "חיוב",
    "please_upgrade": "אנא שדרג כדי ליהנות מכל התכונות",
    "you_have_subscribed": "נרשמת ל- ",
    "plan": "לְתַכְנֵן",
    "of_text": "שֶׁל",
    "days": "ימים",
    "remaining_days_until_plan": " נותרו ימים עד שהתוכנית שלך דורשת עדכון",
    "manage_your_screens": "נהל את המסכים שלך",
    "screens_you_save": "מסכים שיש לך",
    "add_more_screens": "הוסף מסכים נוספים",
    "addition_screen": "מסך הוספה",
    "per_screen": "$ לכל מסך",
    "per_box": "$ לכל קופסה",
    "shipping_changes": "דמי משלוח",
    "upto4_boxes": "עד 4 קופסאות ",
    "charge_now": "אתה תקבל תשלום עכשיו",
    "updated_reoccuring": "העדכון המעודכן שלך יהיה",
    "current_reoccuring": "חוזר נוכחי: ",
    "no_text": "לֹא",
    "android_box": "אנדרואיד בוקס",
    "old_password_req": "נדרשת סיסמה ישנה",
    "new_password_req": "נדרשת סיסמה חדשה",
    "confirm_password_req": "אשר נדרשת סיסמה",
    "password_do_not_match": "הסיסמאות אינן תואמות",
    "old_password": "סיסמה ישנה",
    "new_password": "סיסמה חדשה",
    "confirm_password": "אשר את הסיסמה",
    "copy_working_hours": "להעתיק את שעות העבודה הללו לכל הימים?",
    "yes_copy": "כן, העתק",
    "closed": "סָגוּר",
    "opening_time": "זמן פתיחה",
    "closing_time": "שעת סגירה",
    "description": "תֵאוּר",
    "file_exist": "הקובץ כבר קיים, אנא בחר קובץ אחר",
    "bussiness_images": "תמונות עסקיות",
    "display_info_on_market_place": "האם תרצה להציג מידע למטה ב-Marketplace שלך?",
    "profile_ready": "הפרופיל שלך מוכן!",
    "client_book_with_online": "לקוחות יכולים כעת להזמין אתכם באינטרנט. שתף את הקישור למטה כדי להתחיל.",
    "copy_link": "העתק קישור",
    "see_your_profile": "ראה את הפרופיל שלך",
    "ok_got_it": "אוקי, הבנתי",
    "preview_profile": "הצג תצוגה מקדימה של הפרופיל שלך וראה איך הוא ייראה.",
    "opening_hours": "שְׁעוֹת פְּתִיחָה",
    "display_data": "הצגת נתונים",
    "manage_profile": "נהל פרופיל",
    "phone_req": "נדרש טלפון",
    "market_place_img_req": "נדרשות תמונות ממקום השוק",
    "add_new_market_place": "הוסף New MarketPlace",
    "edit_market_place": "ערוך את MarketPlace",
    "no_reviews_yet": "אין ביקורות עדיין",
    "good": "טוֹב",
    "average": "מְמוּצָע",
    "add_marketPlace": "הוסף את MarketPlace",
    "all_title": "כֹּל",
    "included_title": "כּוֹלֵל",
    "excluded_title": "לא נכלל",
    "clients_subscribed": "לקוחות נרשמו לאחרונה",
    "clients_groups": "קבוצות לקוחות",
    "customer_groups": "קבוצות לקוחות",
    "audience_title": "קהל",
    "client_gt5": "שלא חזר באחרונה",
    "select_all": "בחר הכל",
    "modify": "לְשַׁנוֹת",
    "campaign_title": "כותרת מסע הפרסום",
    "msg_type": "סוג הודעה",
    "enter_discount": "הזן הנחה",
    "discount_type": "סוג הנחה",
    "coupon_text": "טקסט קופון",
    "enter_coupon_text": "הזן טקסט קופון",
    "coupon_button_text": "טקסט לחצן קופון",
    "enter_coupon_button_text": "הזן טקסט לחצן קופון",
    "fine_prin": "האותיות הקטנות",
    "enter_fine_prin": "הזן טקסט באותיות קטנות",
    "campaign_dec": "אתה יכול לשנות את הקמפיין 30 דקות לפני שעת ההתחלה שלו. חיובים עבור קמפיין SMS יתבצעו 30 דקות לפני מועד תחילת הקמפיין.",
    "blast_text_message_dec": "הודעת טקסט הפיצוץ שלך מוכנה. בחר את אמצעי התשלום שלך ושלח את ההודעות שלך.",
    "payment_completed": " התשלום יסתיים בשעה ",
    "total_cost": "עלות כוללת",
    "close_title": "לִסְגוֹר",
    "friday": "יוֹם שִׁישִׁי",
    "saturday": "שַׁבָּת",
    "sunday": "יוֹם רִאשׁוֹן",
    "thursday": "יוֹם חֲמִישִׁי",
    "tuesday": "יוֹם שְׁלִישִׁי",
    "wednesday": "יוֹם רְבִיעִי",
    "port_txt": "נָמָל",
    "today_title": "הַיוֹם",
    "yesterday_title": "אֶתמוֹל",
    "last_30_days_txt": "30 הימים האחרונים",
    "this_month_txt": "החודש הזה",
    "last_month_txt": "חודש שעבר",
    "valid_date_title": "בחר תאריך חוקי",
    "valid_business_name_txt": "הזן שם עסק חוקי",
    "req_bus_add_txt": "כתובת העסק נדרשת",
    "req_domain_name_txt": "נדרש שם דומיין",
    "basic_info_txt": "מידע בסיסי על העסק שלך",
    "loyalty_qr_def_txt": "סרוק את קוד ה-QR, הצטרף לכרטיס הניקוב הנאמנות שלנו, והתמכר לפרסים.",
    "last_stamp_txt": "חותמת אחרונה",
    "collected_on_txt": "נאסף על",
    "stamp_details_txt": "פרטי בול",
    "add_stamp_txt": "הוסף חותמת",
    "choose_brand_color_txt": "בחר את צבע המותג והשפה שלך 🎨",
    "change_anytime_txt": "אתה יכול לשנות זאת בכל עת",
    "like_to_get_txt": "האם תרצה לקבל",
    "your_next_visit_txt": "בביקור הבא שלך?",
    "time_to_time_get_offers": "האם תרצה לקבל מבצעים ומבצעים מעולים מעת לעת?",
    "respond_back_to_you": "האם תרצה שמישהו יענה לך בחזרה?",
    "input_serve_better": "ההשקעה שלך תעזור לצוות שלנו לשרת אותך טוב יותר!",
    "do_it_txt": "בוא נעשה את זה",
    "take_15_s": "לוקח 15 שניות",
    "rate_on_google_desc": "האם אתה מוכן לדרג אותנו בגוגל?",
    "may_be_next_time": "אולי בפעם הבאה",
    "thank_you_txt": "תודה לך",
    "look_next_txt": "אנו מצפים לביקור הבא שלך!",
    "deleteUserTitle": "משתמש זה יימחק לצמיתות.",
    "deleteMUserTitle": "משתמשים אלו יימחקו לצמיתות.",
    "change_pin": "שנה סיכה",
    "area_code": "מה אזור החיוג שלך? ",
    "add_menu": "הוסף תפריט",
    "menu_name": "שם התפריט",
    "add_menu_placeholder": "פיצה, בורגר, משקאות וכו'",
    "enable_data_Collection": "הפעל איסוף נתונים",
    "add_new_menu": "הוסף תפריט חדש",
    "rename_menu": "שנה שם תפריט",
    "preview": "תצוגה מקדימה",
    "generate_qr": "נוצר QR",
    "edit_menu": "ערוך תפריט",
    "remove_menu": "הסר את התפריט",
    "menu_delete_msg": "תפריט זה יימחק לצמיתות",
    "menus_delete_msg": "תפריט זה יימחק לצמיתות",
    "view_menu_dialoage_msg": "רוצה לקבל מתנה ביום ההולדת שלך?",
    "skip": "לְדַלֵג",
    "cliam_your_gift": "תבע את המתנה שלך",
    "collect_form": "איסוף טופס",
    "enter_first_name": "הזן שם פרטי",
    "enter_last_name": "הזן שם משפחה",
    "enter_email": "הזן דואר אלקטרוני",
    "enter_dob": "הזן את תאריך לידה",
    "enter_number": "הזן מספר",
    "select_gender": "בחר מגדר",
    "congratulations_desc": "🎉 מזל טוב! בקשת תביעת המתנה שלך נשלחה בהצלחה. הצוות שלנו ייצור איתך קשר בהקדם. 🎉",
    "male_heading": "זָכָר",
    "male_text": "זָכָר",
    "female_heading": "נְקֵבָה",
    "female_text": "נְקֵבָה",
    "others_heading": "אחרים",
    "other_text": "אחרים",
    "BirthD": "תאריך לידה",
    "GenderT": "מִין",
    "EmailT": "אֶלֶקטרוֹנִי",
    "dobT": "DOB",
    "capitalize_menu": "תַפרִיט",
    "select_menu": "בחר תפריט",
    "manage_variant": "נהל וריאנט",
    "add_products": "הוסף מוצרים",
    "add_category": "הוסף קטגוריה",
    "category_delete": "קטגוריה זו תימחק לצמיתות",
    "variation_delete": "גרסה זו תימחק מהמוצר.",
    "product_delete": "מוצר זה יימחק לצמיתות.",
    "categories_delete": "קטגוריות אלו יימחקו לצמיתות.",
    "products_delete": "מוצרים אלה יימחקו לצמיתות.",
    "category": "קָטֵגוֹרִיָה",
    "price": "מְחִיר",
    "food_product_placeholder": "פיצה, בורגר, משקאות וכו'",
    "active_title": "פָּעִיל",
    "inActive_title": "לֹא פָּעִיל",
    "status_capital": "סטָטוּס",
    "cat_status_require": "נדרש סטטוס קטגוריה",
    "cat_name_require": "נדרש שם קטגוריה",
    "category_name": "שם הקטגוריה",
    "status": "סטָטוּס",
    "lgDay1": "יוֹם שֵׁנִי",
    "lgDay2": "יוֹם שְׁלִישִׁי",
    "lgDay3": "יוֹם רְבִיעִי",
    "lgDay4": "יוֹם חֲמִישִׁי",
    "lgDay5": "יוֹם שִׁישִׁי",
    "lgDay6": "שַׁבָּת",
    "lgDay7": "יוֹם רִאשׁוֹן",
    "is_closed_title": "סָגוּר",
    "book_table_title": "הזמינו שולחן",
    "emailErrSub": "נדרש נושא הדוא\"ל",
    "email_subject": "נושא דוא\"ל",
    "contactUsfrmTitleEmail": "השב למייל",
    "companyInfo": "Lorem Ipsum הוא lorem hrig, lorem ipsum הוא fraets.Lorem Ipsum הוא lorem hrig, lorem ipsum הוא fraets.Lorem Ipsum הוא lorem hrig, lorem ipsum הוא fraets.",
    "footerSTitle1": "על Menuonline",
    "footerSTitle1Lnk2": "תכונות",
    "footerSTitle1Lnk3": "בלוג",
    "footerSTitle2": "מִשׁפָּטִי",
    "footerSTitle2Lnk1": "תנאים והגבלות",
    "footerSTitle2Lnk2": "מדיניות פרטיות",
    "footerSTitle3": "לעסקים",
    "footerSTitle3Lnk1": "עבור שותפים",
    "footerSTitle3Lnk2": "תמחור",
    "footerSTitle3Lnk3": "תמיכה בשותפים",
    "footerCopyRithgTxt": "  Menuonline או השותפים שלו",
    "homeTitle": "בַּיִת",
    "contactTitle": "צור קשר",
    "aboutTitle": "אודותינו",
    "homeMenuTitle": "תַפרִיט",
    "bookTableTitle": "הזמינו שולחן",
    "vDateT": "בחר תאריך חוקי",
    "reqDateT": "נדרש תאריך",
    "dateGtErr": "התאריך חייב להיות מאוחר או שווה לתאריך של היום",
    "timeInvalid": "הזמן אינו חוקי",
    "reqTimeT": "נדרש זמן",
    "timeInvalidBfr": "בחר זמן לפחות 5 דקות מאוחר יותר מהשעה הנוכחית ",
    "PerReq": "נדרש מספר אדם",
    "validcNoT": "הזן מספר איש קשר חוקי",
    "reqCrT": "נדרש מטבע",
    "reqPrT": "מחיר נדרש",
    "reCateT": "נדרשת קטגוריה",
    "reqVrT": "נדרשת וריאנט",
    "reqVrVldT": "הזן גרסה חוקית ",
    "validLNameT": "הזן שם משפחה חוקי",
    "sDateInValid": "מתאריך אינו חוקי",
    "minmReq": "נדרש מינימום",
    "resLblDate": "בחר תאריך",
    "resLblTime": "בחר זמן",
    "perT": "לכמה אנשים?",
    "resLblEmail": "אנא ספק את האימייל שלך?",
    "resLblNote": "הוסף הערת הזמנה",
    "imageError1MB": "נא להכניס תמונה שגודלה פחות מ-1MB",
    "imageError": "נא להכניס תמונה שגודלה פחות מ-2.5MB",
    "imageError3Mb": "נא להכניס תמונה שגודלה פחות מ-3MB",
    "imageInVldError": "הקובץ לא חוקי.",
    "addProMT": "תפריט מוצר",
    "proT": "שם המוצר",
    "reqProT": "שם המוצר נדרש",
    "proPT": "מחיר המוצר",
    "reservationSuccTitle": "נהדר, ההזמנה שלך הסתיימה",
    "book": "סֵפֶר",
    "custzName": "שם התאמה אישית",
    "always": "תָמִיד",
    "proImgReq": "נדרשת תמונת מוצר",
    "selCustmT": "נא בחר התאמה אישית או צור חדש",
    "visStR": "נדרש סטטוס נראות",
    "avlblScR": "אנא בחר את לוח הזמינות",
    "addonPR": "אנא בחר מוצרי תוספות",
    "upsellPR": "אנא בחר מוצרים למכירה נוספת",
    "markItemR": "אנא בחר סמן פריט מוצרים",
    "caloriesR": "יש צורך בקלוריות",
    "allergensR": "אנא בחר מוצרי אלרגנים",
    "prepInstR": "יש צורך בהוראות הכנה",
    "staffNR": "נדרשות הערות צוות",
    "discountR": "נדרשת הנחה",
    "validDiscE": "הזן הנחה תקפה",
    "disday": "לפי היום",
    "plSelDayT": "אנא בחר יום",
    "sTimeReq": "נדרשת שעת התחלה",
    "sTimeInValid": "שעת ההתחלה אינה חוקית",
    "eTimeReq": "נדרשת שעת סיום",
    "eTimeInValid": "שעת הסיום אינה חוקית",
    "sDateReq": "נדרש מתאריך",
    "eDateReq": "עד היום נדרש",
    "eDateInValid": "עד היום אינו חוקי",
    "disdate": "מהתאריך",
    "disdate1": "עד היום",
    "disdate2": "מתאריך",
    "currT": "מַטְבֵּעַ",
    "iconR": "אנא בחר בסמל סימון פריט",
    "minT": "מִינִימוּם",
    "maxT": "מַקסִימוּם",
    "itemNT": "שם פריט",
    "itemPT": "מחיר פריט",
    "descProT": "תאר את המוצר שלך",
    "cateT": "קָטֵגוֹרִיָה",
    "selProCateT": "בחר את קטגוריית המוצרים שלך",
    "reqMT": "נדרש שם התפריט",
    "ViewMenu": "הצג תפריט",
    "CopyMenu": "העתק תפריט",
    "EditMenu": "תפריט עריכה",
    "RemoveMenu": "הסר תפריט",
    "welcomeMnuTitle": "ברוכים הבאים ל",
    "reviewT": "ביקורות",
    "userErrorMsg": "סליחה, אירעה שגיאה!!!",
    "reqCateT": "נדרש שם קטגוריה",
    "mngCustT": "נהל התאמה אישית",
    "custNReq": "נדרש שם התאמה אישית",
    "incReq": "כלול נדרש",
    "minmValid": "הזן ערך מינימום חוקי",
    "maxmReq": "נדרש מקסימום",
    "cust": "התאמה אישית",
    "crCust": "צור התאמה אישית חדשה",
    "custList": "רשימת התאמה אישית",
    "delCustmzT": "התאמה אישית זו תימחק לצמיתות.",
    "mkNameR": "סמן שם פריט נדרש",
    "mkDelT": "פריט סימון זה יימחק לצמיתות.",
    "hideText": "לְהַסתִיר",
    "showText": "לְהַצִיג",
    "device_mac_txt": "מכשיר מק",
    "delete_hotspot_txt": "נקודה חמה זו תימחק לצמיתות.",
    "delete_hotspots_txt": "נקודות חמות אלה יימחקו לצמיתות.",
    "emp_wifi_name": "שם ה-Wifi של העובד",
    "max_limit_txt": "הזן '1024' עבור מהירות כדי לייעד אותה כבלתי מוגבלת. ערך זה מסיר כל מכסים, ומאפשר תפוקה מקסימלית",
    "device_port_txt": "יציאת מכשיר",
    "firmware_txt": "קושחה",
    "hotspot_info_txt": "מידע על נקודה חמה",
    "editDBtn": "ערוך פרטים",
    "birth_date": "תאריך לידה",
    "di_theme_clr": "צבע נושא בדיוק 7 ספרות",
    "color_Invalid_txt": "בחר קוד צבע חוקי",
    "Req_theme_clr": "נדרש צבע נושא",
    "select_valid_color_txt": "אנא בחר צבע נושא חוקי",
    "req_redir_link": "נדרש קישור להפניה מחדש",
    "val_redir_link": "הזן קישור חוקי להפניה מחדש",
    "req_business_name_txt": "נדרש שם העסק",
    "splash_preview": "תצוגה מקדימה של Splash",
    "create_new_splash": "צור ספלאש חדש",
    "splash_page": "Splash Page",
    "splash_page_builder": "Splash Page Builder",
    "redirect_link": "קישור להפניה מחדש",
    "theme_color": "צבע נושא",
    "enable_social_login": "אפשר אפשרויות מדיה חברתית לכניסה של משתמשים",
    "google": "גוגל",
    "facebook": "פייסבוק",
    "is_mandatory": "האם זה חובה?",
    "field": "שָׂדֶה",
    "name": "שֵׁם",
    "first_name": "שֵׁם פְּרַטִי",
    "last_name": "שֵׁם מִשׁפָּחָה",
    "birthdate": "תאריך לידה",
    "gender": "מִין",
    "email": "אֶלֶקטרוֹנִי",
    "dob": "DOB",
    "zip_code": "מיקוד",
    "required_redirect_link": "נדרש קישור להפניה מחדש",
    "valid_redirect_link": "הזן קישור חוקי להפניה מחדש",
    "required_theme_color": "נדרש צבע נושא",
    "theme_color_length": "צבע נושא בדיוק 7 ספרות",
    "required_name": "נדרש שם",
    "delete_splash_message": "אתה רוצה למחוק את הסנסציה הזו?",
    "delete_multiple_splashes_message": "אתה רוצה למחוק את השפריצים האלה?",
    "user_login_required": "נדרשת התחברות למשתמש",
    "set_theme_color": "הגדר צבע ערכת נושא",
    "splash_colllect_additional_info_txt": "אסוף מידע נוסף",
    "verify_mobile_otp_message": "האם תרצה לשלוח OTP לאימות מספר נייד?",
    "add_company": "הוסף חברה",
    "edit_company": "ערוך חברה",
    "ds_device": "מכשיר שילוט דיגיטלי",
    "ds_device_activation_fee": "עמלת הפעלת מכשיר שילוט דיגיטלי",
    "wifi_hardware": "חומרת Wifi",
    "menu_design": "עיצוב תפריט",
    "ds_hardware": "חומרת שילוט דיגיטלי",
    "company_name_req": "נדרש שם חברה",
    "known_name_req": "נדרש שם ידוע",
    "no_location_req": "נדרש מספר מיקום",
    "minimum_location": "אנא הזן מיקום אחד לפחות",
    "terms_req": "נדרשים תנאים",
    "notes_req": "יש צורך בהערות",
    "sms_charge_req": "נדרש תשלום SMS",
    "menuonline_quantity_req": "תפריט מקוון נדרשת כמות",
    "menuonline_cost_req": "עלות תפריט מקוונת נדרשת",
    "ds_quantity_req": "נדרשת כמות של שילוט דיגיטלי",
    "ds_monthly_cost_req": "נדרשת עלות חודשית של שילוט דיגיטלי",
    "reputation_quantity_req": "נדרשת כמות מוניטין",
    "reputation_cost_req": "נדרשת עלות מוניטין",
    "marketing_quantity_req": "נדרשת כמות שיווק",
    "marketing_cost_req": "עלות שיווק נדרשת",
    "tax_percentage_req": "נדרש אחוז מס",
    "router_hardware_quantity_req": "נדרשת כמות החומרה של הנתב",
    "wifi_hardware_cost_req": "נדרשת עלות חומרה של WIFI",
    "activation_cost_req": "נדרשת עלות הפעלה",
    "wifi_quantity_req": "נדרשת כמות WIFI",
    "wifi_router_req": "נדרש נתב Wifi",
    "menu_design_quantity_req": "נדרשת כמות עיצוב תפריט",
    "menu_design_cost_req": "נדרשת עלות עיצוב תפריט",
    "quantity_req": "נדרשת כמות",
    "cost_req": "נדרשת עלות",
    "sheduledown_quantity_req": "נדרשת כמות מופחתת",
    "sheduledown_cost_req": "נדרשת עלות ירידה",
    "loyalty_program_quantity_req": "נדרשת כמות של תוכנית נאמנות",
    "loyalty_program_cost_req": "נדרשת עלות תוכנית נאמנות",
    "loyalty_program_sms_quantity_req": "תוכנית נאמנות נדרשת כמות SMS",
    "loyalty_program_sms_cost_req": "נדרשת עלות SMS של תוכנית נאמנות",
    "comapny_created": "החברה נוצרה בהצלחה",
    "comapny_updated": "החברה עודכנה בהצלחה",
    "billing_info": "פרטי חיוב",
    "business_name_acc_holder": "שם העסק (בעל חשבון)",
    "client_first_name": "שם פרטי הלקוח",
    "client_last_name": "שם משפחה של הלקוח",
    "dba_known_as": "DBA ידוע בשם",
    "business_address": "כתובת העסק",
    "no_of_locations": "מספר מיקומים",
    "package_details": "פרטי החבילה",
    "terms": "תנאים",
    "one_month": "1 חודש",
    "per_sms_charge": "לכל תשלום SMS",
    "plan_test": "תוכנית: מבחן",
    "desc_capital": "תֵאוּר",
    "qty_capital": "כמות",
    "cost_capital": "עֲלוּת",
    "total_price_capital": "מחיר כולל",
    "quantity": "כַּמוּת",
    "can_not_access": "אתה לא יכול לבחור יותר מ",
    "licenses": "רישיונות",
    "monthly_cost": "עלות חודשית",
    "plan_cost": "עלות תוכנית",
    "tax": "מַס",
    "tax_percentage": "אחוז מס",
    "monthly_cost_after_tax": "עלות חודשית לאחר מס",
    "activation_one_time_charge": "הפעלה וטעינה חד פעמית",
    "sub_total": "SUB TOTAL",
    "action": "פְּעוּלָה",
    "cost": "עֲלוּת",
    "shipping_charge": "דמי משלוח",
    "other": "אַחֵר",
    "additional_cost": "עלות נוספת (חיובים אחרים)",
    "other_tax": "מס אחר",
    "total": "סַך הַכֹּל",
    "license_statistics": "סטטיסטיקת רישיון",
    "total_licenses": "סך כל הרישיונות",
    "available_licenses": "רישיונות זמינים",
    "stock": "מְנָיוֹת",
    "has_permission": "יש רשות",
    "avg_stock_price": "מחיר מניית AVG",
    "average_price": "מחיר ממוצע",
    "allocated": "מוּקצֶה",
    "reward_regulars": "תגמל קבועים",
    "not_add_coupon_url": "לא ניתן להוסיף כתובת קופון. האורך המרבי של ההודעה הוא 160",
    "like_attach_coupon": "האם תרצה לצרף קופון?",
    "advance_scheduling": "תזמון מוקדם",
    "choose_day": "בחר ימים לשליחת הודעה",
    "select_essage_window": "בחר חלון הודעות",
    "subscription_outside_delivery_window": "אם משתמש נרשם מחוץ לחלון המסירה, הוא יקבל את המשיב האוטומטי שלך במועד הפנוי הבא.",
    "remaining": "נוֹתָר",
    "processing_request": "אירעה שגיאה במהלך עיבוד הבקשה שלך.",
    "list_companies": "רשימת חברות",
    "are_you_sure": "אתה בטוח..?",
    "signin_agreement": "על ידי אישור זה, החברה תיצור ללא חתימה על הסכם.",
    "status_updated": "עדכון סטטוס בהצלחה",
    "status_failed": "עדכון הסטטוס נכשל",
    "new_editor_status_updated": "סטטוס העורך החדש של DS עודכן בהצלחה.",
    "user_name": "שֵׁם מִשׁתַמֵשׁ",
    "known_as": "המכונה",
    "agreement_signed": "הסכם נחתם",
    "deactivate": "השבת",
    "activate": "לְהַפְעִיל",
    "login_to_user_acc": "התחבר לחשבון משתמש",
    "disable": "השבת",
    "enable": "לְאַפשֵׁר",
    "ds_new_editor": "עורך חדש של DS",
    "sign_agreement": "חתום על הסכם",
    "agreement_sign": "הסכם ללא חתימה",
    "view_agreement": "צפה בהסכם",
    "download_agreement": "הסכם הורדה",
    "add_deleted_user": "הוסף משתמש שנמחק",
    "deleted_user": "מחק משתמש",
    "favourite": "מוּעֲדָף",
    "refresh": "לְרַעֲנֵן",
    "delete_chat": "מחק את הצ'אט",
    "responsive_drawer": "מגירה רספונסיבית",
    "delete_contact": "מחק איש קשר",
    "clear_chat_history": "נקה היסטוריית צ'אט",
    "clear_chat_history_dec": "האם אתה בטוח שברצונך למחוק את הצ'אט הזה?",
    "clear_contact_dec": "האם אתה בטוח שברצונך למחוק איש קשר זה?",
    "select_contact": "בחר איש קשר",
    "new_conversation": "התחל שיחה חדשה",
    "type_msg": "הקלד את הודעתך כאן",
    "select_delete_client": "אנא בחר לקוח למחיקה",
    "select_delete_client_chat": "אנא בחר לקוח כדי למחוק את הצ'אט",
    "select_delete_client_chat_err": "אין לך צ'אט עם הלקוח הזה",
    "acquire_customers_business": "רכישת לקוחות חדשים מעסקים אחרים.",
    "customers_from_events": "קבל לקוחות מאירועים והביא אותו אליך!",
    "customers_from_ads": "קבל לקוחות ממודעות בעיתון.",
    "smart_pamphlets": "צור חוברות חכמות לאיסוף נתוני לקוחות.",
    "smart_qr_group": "QR חכם להצטרפות לקבוצה ללקוחות.",
    "opt_in_number": "אפשר ללקוחות להצטרף למספר.",
    "qr_menu": "צור קוד QR להצגת התפריט שלך.",
    "collect_birthdays": "איסוף יום הולדת ויום נישואין של הלקוח.",
    "join_loyalty_program": "קבל לקוחות חדשים להצטרף לתוכנית הנאמנות שלי.",
    "create_feedback_forms": "צור טפסים לאיסוף משוב של הלקוח.",
    "exclusive_discounts_promotions": "הצע הנחות ומבצעים בלעדיים עבור מבצעים צולבים.",
    "group_smart_qr_opt_in": "לאחר יצירת קבוצה, אתה יכול ליצור ולבחור קוד QR חכם, המאפשר ללקוחות להצטרף מיידית לקבוצה",
    "group_smart_qr_opt_in_dec": "לאחר יצירת קבוצה, יישומון תמונה המאפשר ללקוחות להצטרף באמצעות טקסט ולקבל מבצעים, לדוגמה, הוסף טקסט \"פיצה\" למספר 555-555-5555",
    "in_store_discount_next_visit": "קבל הנחה בחנות בביקורך הבא במיקום שלנו",
    "delivery_redirection": "צור הפניית משלוח",
    "additional_info": "מידע נוסף",
    "add_url": "הוסף כתובת אתר",
    "custom_fields": "שדות מותאמים אישית",
    "meta_tag": "מטא תג",
    "max_chars": "מקסימום תווים",
    "add": "לְהוֹסִיף",
    "update_campaign": "עדכן מסע פרסום",
    "last_week_required": "שבוע שעבר נדרש",
    "large_week_value": "ערך השבוע גדול מדי הזן שבוע חוקי",
    "less_than_last_week": "אי החזרה בשבוע שעבר חייבת להיות פחותה משבוע שעבר.",
    "last_week_req": "לא נדרשת חזרה בשבוע שעבר",
    "birthday_special": "מיוחד ליום הולדת",
    "birthday_message": "הודעה אוטומטית שנשלחת בימי ההולדת שלהם עם ברכות מותאמות אישית והצעות מפתות",
    "birthday_sms": "צור ברכות יום הולדת מותאמות אישית עבור SMS\/MMS",
    "target_audience": "בחר קהל יעד",
    "client_by_birthday": "לקוח עד יום הולדת",
    "clients_once_year": "קמפיין זה נשלח אוטומטית ללקוחות פעם בשנה ",
    "auto_responder_default_msg_1": "אתה אחד הלקוחות הטובים שלנו שאנחנו אוהבים לתת להם הנחה! שתהיה תמיד עשיר ובריא. יום הולדת שמח! %% שם פרטי %%",
    "campaign_send_to_client_birthday": "מסע פרסום אוטומטי זה שולח ללקוחות עם ימי הולדת, אתה יכול להתאים אישית את המיקוד למטה.",
    "get_more_engagement": "קבל יותר מעורבות על ידי שליחת SMS ליום הולדת 🎂",
    "welcome_new_clients": "ברוך הבא לקוחות חדשים",
    "make_lasting_impression": "עשה רושם מתמשך על ידי קבלת פנים חמה וברכה של סועדים בפעם הראשונה",
    "personalized_greetings": "צור ברכות מותאמות אישית עבור הלקוחות החדשים",
    "greetings_to_new_customers": "מסע פרסום זה ישלח ברכות קבלת פנים ללקוחות חדשים ביום הבא של האינטראקציה הראשונה שלהם עם העסק שלך",
    "audience_predefined": "הקהל של המשיב האוטומטי הזה מוגדר מראש, יש לנו את זה!",
    "send_clients_subscribed": "משיב אוטומטי זה מוגדר לשלוח לקוחות שנרשמו לאחרונה ",
    "first_interaction": "  לאחר האינטראקציה הראשונה שלהם.",
    "default_msg_2": "אנו מקווים שנהנית מהביקור הראשון שלך, נשמח לראותך שוב בקרוב אז אנו מציעים הנחה רק בשבילך! לחץ על הקישור כדי להזמין את הפגישה הבאה שלך איתנו ולממש את ההצעה המקוונת המיוחדת שלך עכשיו.",
    "new_clients_update": "ברוך הבא לקוחות חדשים עדכון טקסט מסע הפרסום",
    "new_clients_warm_greeting": "קבלו לקוחות חדשים בברכה חמה 💌",
    "win_back_clients": "לזכות בלקוחות",
    "re_engage_past_customers": "צור קשר מחדש עם לקוחות עבר עם הצעות שאי אפשר לעמוד בפניהן כדי לעודד אותם לחזור",
    "target_disengaged_clients": "כוון ללקוחות מנותקים לחזור עם הצעה מיוחדת, טקטיקה מוכחת לפתות לקוחות בחזרה.",
    "campaign_sends_to_clients": "קמפיין זה שולח ללקוחות שלא חזרו לאחר פרק זמן מסוים.",
    "customize_targeting": "התאם אישית את המיקוד למטה כדי לשלוח SMS\/MMS ללקוחות שפג.",
    "clients_with_at_least": "לקוחות עם לפחות ",
    "connection_last": " חיבור באחרון ",
    "return_in_last_week": " שבוע, אך לא חזר לאחרונה ",
    "arr_menu_44": " שָׁבוּעַ",
    "df_msg_4": "אנחנו רוצים אותך בחזרה כל כך רע שאנחנו הולכים לתת לך 10% הנחה רק על החזרה! רוצה לנסות אותנו שוב היום?",
    "update_campaign_text": "החזר לקוחות עדכון טקסט מסע הפרסום",
    "re_invite_customers": "הזמן מחדש לקוחות שלא חזרו זמן מה",
    "loyal_patrons": "הפגינו הערכה לפטרונים נאמנים עם הנחות מיוחדות ותגמולי נאמנות",
    "surprize_top_spenders": "הפתיע את המוציאים המובילים עם מבצעים מותאמים אישית ומיוחדים.",
    "campaign_automatically_send": "מסע פרסום זה שולח באופן אוטומטי תגמולים כדי להגביר את ירידת הרגל בעסק שלך.",
    "sms_loyal_clients": "מסע פרסום זה שולח SMS\/MMS ללקוחות נאמנים, אתה יכול לשנות את המיקוד למטה.",
    "or_more_connection": " או יותר חיבור, בתוך האחרון ",
    "week_period": " תקופת שבוע.",
    "default_msg_5": "אתה לקוח כבר הרבה זמן! וואו, הזמן טס! אנו מעריכים אותך ורוצים להודות לך על שהיית איתנו.",
    "reward_regulars_update": "תגמול קבועים מעדכנים את טקסט הקמפיין",
    "special_discounts": "תעריכו ותתנו הנחות מיוחדות💎",
    "leave_positive_online": "עודדו לקוחות להשאיר ביקורות חיוביות באינטרנט כדי להגביר את המוניטין של המסעדה",
    "clients_service_online": "הקמפיין מעודד את הלקוחות שלך לדרג את השירות שלך באינטרנט.",
    "increase_your_company": "משיב אוטומטי זה נשלח כדי להגדיל את הדירוג המקוון של החברה שלך.",
    "send_message_after": "שלח הודעה אחרי ",
    "min_connection": " דקות מהחיבור",
    "default_msg_6": "אנו מקווים שנהניתם מביקורכם הראשון, נשמח לראותכם שוב בקרוב! [URL] לחץ על הקישור כדי לדרג אותנו ב-Google.",
    "ratings_update": "קבל עוד טקסט מסע פרסום לעדכון דירוגים",
    "friendly_nudge": "קבל עוד ביקורות עם דחיפה ידידותית ⭐",
    "thanks_for_visiting": "תודה שביקרת",
    "express_gratitude": "הבע תודה ללקוחות על שבחרו בעסק שלך עם הודעות תודה מכל הלב",
    "thank_you_message": "צור הודעת תודה ללקוחות שלך.",
    "clients_for_visiting": "שלח תודה חמה ללקוחות שלך על הביקור במקום שלך.",
    "campaign_audience": "קהל הקמפיין האוטומטי הזה מוגדר מראש, יש לנו את זה!",
    "campaign_automatically_sends": "מסע פרסום אוטומטי זה נשלח אוטומטית לאחר ",
    "minutes_customer_visit": " דקות של ביקור לקוחות",
    "default_msg_7": "תודה שהיית הלקוח המוערך שלנו. אנו כל כך אסירי תודה על התענוג לשרת אותך ומקווים שעמדנו בציפיות שלך.",
    "visiting_update_campaign": "תודה שביקרת לעדכן את טקסט מסע הפרסום",
    "guests_thank_you": "תודה לאורחים שלך על ביקורם 😊",
    "download_title": "הורד",
    "qr_gen_title": "נוצר QR",
    "qr_download_s_title": "קוד QR הורד בהצלחה",
    "sel_dark_brand_clr_msg": "אנא בחר צבע ברנד חוקי",
    "manage_customer_stamp_rewards": "ניהול תגמולי חותמת לקוחות 🎁",
    "sel_loc_menu_title": "בחר מיקומים עבור התפריט שלך",
    "ans_req": "נדרשת תשובה",
    "valid_reputation_name": "הזן שם מוניטין חוקי",
    "reviews_name_req": "נדרש שם 'קבל עוד ביקורות'",
    "birthdate_required": "תאריך לידה נדרש",
    "gender_required": "נדרש מגדר",
    "valid_birthdate_required": "הזן תאריך לידה חוקי",
    "custom_delivery_redirection": "צור טופס הפניית מסירה מותאם אישית לפי קבוצות",
    "customer_fields_incentive_settings": "שדות לקוחות והגדרות תמריצים",
    "delivery_redirection_text": "הפניית משלוח",
    "delivery_redirection_success": "מזל טוב! הווידג'ט שלך לניתוב מסירה נוצר! 🎉",
    "swipe_to_preview_redirection": "החלק כדי לראות איך ייראה טופס הפניית המשלוח שלך",
    "enter_item_name": "הזן את שם הפריט",
    "home_page_text": "טקסט בדף הבית",
    "settings": "הגדרות",
    "anniversary_required": "נדרש תאריך יום השנה",
    "valid_anniversary": "הזן תאריך יום נישואין חוקי",
    "form_submited": "הטופס נשלח בהצלחה",
    "notifications": "התראות",
    "discount_message": "קבל 20% הנחה על פיצה עכשיו!",
    "is_required": " נדרש",
    "section_title_required": "נדרשת כותרת המדור",
    "section_dec_required": "נדרש תיאור מדור",
    "client_details_required": "שדות פרטי הלקוח נדרשים",
    "compliance": "הַתאָמָה",
    "SMS_campaign1": "קמפיין SMS 1",
    "mobile_number_mandatory": "מספר נייד הוא חובה",
    "new_answer": "תשובה חדשה",
    "new_question": "שאלה חדשה",
    "add_new_question": "הוסף תשובה חדשה",
    "select": "לִבחוֹר",
    "group_customers_question": "איפה אתה רוצה לקבץ את הלקוחות שלך?",
    "contacts_added_to_group": "כל אנשי הקשר שישלחו את הטופס יתווספו לקבוצה שנבחרה",
    "edit_client_details_section": "ערוך מדור פרטי לקוח",
    "client_details_fields": "שדות פרטי לקוח",
    "enter_client_details_section_title_desc": "הזן את הכותרת והתיאור של סעיף פרטי הלקוח",
    "choose_fields_displayed_client_side": "בחר את השדות שיוצגו בצד הלקוח",
    "section_title": "כותרת המדור",
    "add_title": "הוסף כותרת",
    "section_description": "תיאור הסעיף",
    "add_description": "הוסף תיאור",
    "enter_program_name_location": "הזן את שם התוכנית ומיקום 📍",
    "brand_theme_clr_txt": "צבע נושא המותג",
    "set_loyalty_punch_card_theme": "בואו נקבע נושא לכרטיס הניקוב הנאמנות שלכם 🎨",
    "upload_logo_txt": "העלה את הלוגו שלך",
    "recommended_image_specs": "אנו ממליצים להשתמש בתמונה בגודל 512 x 512 פיקסלים לקבלת התצוגה הטובה ביותר. JPG, SVG או PNG. גודל מקסימלי של 10MB.",
    "valid_rep_name_txt": "הזן שם מוניטין חוקי",
    "rep_name_req_txt": "נדרש שם 'קבל עוד ביקורות'",
    "que_req": "נדרשת שאלה",
    "day_of_birthday": "ביום יום ההולדת",
    "day_before_birthday": "3 ימים לפני יום הולדת",
    "week_before_birthday": "שבוע לפני יום הולדת",
    "two_week_before_birthday": "שבועיים לפני יום הולדת",
    "de_active": "דה פעיל",
    "campaign_details": "פרטי הקמפיין",
    "link_clicked": "הקישור נלחץ",
    "history": "הִיסטוֹרִיָה",
    "auto_responder_summary": "סיכום מגיבים אוטומטיים",
    "vsop_1": "15 דקות",
    "vsop_2": "30 דקות",
    "vsop_3": "45 דקות",
    "vsop_4": "60 דקות",
    "vsop_5": "90 דקות",
    "vsop_6": "120 דקות",
    "in_the_last": " באחרון ",
    "return_in_last": " אבל לא חזר אחרון ",
    "of_the_connection": "של החיבור",
    "your_campaign": "הקמפיין שלך",
    "you_have_successfully": "יש לך בהצלחה",
    "published_successfully": "פורסם בהצלחה",
    "updated_smart_campaign": "עדכן את הקמפיין החכם",
    "almost_done_text": "כמעט גמור!",
    "update_campaign_desc": "המשיב האוטומטי שלך הושלם, לאחר הפעלתו, הוא ישלח הודעות ללקוחות באופן שוטף. ניתן לשנות או להשהות בקלות מסע פרסום זה בכל עת.",
    "update_and_publish": "עדכן ופרסם",
    "reset_campaign_title": "זה ימחק מראש את הטקסט, התמונה, הצעות ההנחה וקהל היעד המותאם אישית של מסע הפרסום שלך!",
    "space_wifi": " WiFi",
    "custom_forms": "טפסים מותאמים אישית",
    "web_e_signup": "הרשמה אלקטרונית באינטרנט",
    "import": "יְבוּא",
    "permissions_req": "נדרשות הרשאות",
    "redeemed": "נפדה",
    "coupon_already_redeemed": "קופון כבר מומש ב",
    "autoresponder_campaigns": "מסעות פרסום אוטומטיים",
    "autoresponder_campaign_desc": "הפעל את המשיב האוטומטי והפעל את הקמפיין המותאם אישית",
    "mon": "יום שני",
    "tue": "יום שלישי",
    "wed": "לְהִתְחַתֵן",
    "thu": "ה'",
    "fri": "יום שישי",
    "sat": "SAT",
    "sun": "שֶׁמֶש",
    "duplicate": "לְשַׁכְפֵּל",
    "visibility": "רְאוּת",
    "availability": "זְמִינוּת",
    "out_stok": "אזל",
    "edit_product": "ערוך מוצר",
    "create_product": "צור מוצר",
    "basic_info": "מידע בסיסי",
    "basic_sub_info": "Lorem Ipsum הוא פשוט טקסט דמה של ההדפסה...",
    "enter_product_price": "הזן מחיר המוצר",
    "upload_image": "העלה תמונה",
    "allowed_file_formats": "מותרים רק קובצי JPG ו-PNG בגודל מקסימלי של 3MB.",
    "pre_select": "בחירה מראש",
    "promotions": "מבצעים",
    "discount_availability": "זמינות הנחה",
    "start_time": "זמן התחלה",
    "end_time": "שעת סיום",
    "select_day": "בחר יום",
    "menu_required": "תפריט נדרש",
    "generated": "נוצר",
    "link": "לְקַשֵׁר",
    "variations": "וריאציות",
    "select_variant_to_add": "בחר וריאנט שברצונך להוסיף",
    "price_title": "מְחִיר",
    "choose_image": "בחר תמונה",
    "select_visiblity": "בחר נראות",
    "availability_schedule": "לוח זמינות",
    "add_on_upsell": "הוספה \/ מכירה נוספת",
    "add_on_select_product": "הוסף על מוצר בחר",
    "upsell_product": "מכירה נוספת בחר מוצר",
    "variant_deletion_warning": "וריאנט זה יימחק לצמיתות.",
    "search_variations": "חיפוש וריאציות",
    "add_variation": "הוסף וריאציה",
    "variation_text": "גִרְסָה אַחֶרֶת",
    "select_customization": "בחר התאמה אישית",
    "add_new": "הוסף חדש",
    "delete_customization_warning": "האם ברצונך למחוק התאמה אישית זו מהמוצר?",
    "nutritional_allergen_info": "מידע תזונתי ואלרגנים",
    "mark_item": "סמן פריט",
    "calories_text": "קלוריות",
    "allergens": "אלרגנים",
    "select_allergens": "בחר אלרגנים",
    "special_instructions": "הוראות מיוחדות",
    "preparation_instructions": "הוראות הכנה",
    "staff_notes": "הערות הצוות",
    "checkbox1": "תיבת סימון לאפשר או לא לאפשר הוראות מיוחדות מלקוחות עבור פריט זה בתפריט.",
    "menu_c": "תַפרִיט",
    "past_design": "עיצוב עבר",
    "file_name": "שם הקובץ",
    "variation_name": "שם וריאציה",
    "grid_items_title1": "אוהל שולחן",
    "grid_items_title2": "תפריט דיגיטלי",
    "grid_items_title3": "תפריט אוכל לקחת",
    "grid_items_title4": "תפריט חצי עמוד",
    "grid_items_title5": "תפריט דיגיטלי 5",
    "grid_items_title6": "תפריט דיגיטלי 6",
    "grid_items_title7": "תפריט דיגיטלי 7",
    "grid_items_title8": "תפריט דיגיטלי 8",
    "grid_items_title9": "תפריט דיגיטלי 9",
    "enter_valid_form_name": "הזן שם טופס חוקית",
    "form_name_required": "נדרש שם הטופס",
    "enter_valid_section": "הזן כותרת סעיף חוקית",
    "fraction_half": "1\/2",
    "fraction_two_by_two": "2\/2",
    "form_name": "שם הטופס",
    "contact_list": "רשימת אנשי קשר",
    "label_notify_email": "האם תרצה לקבל הודעה בדוא\"ל?",
    "add_client_details_section": "הוספת מדור פרטי לקוח'",
    "demo_purpose_only": "זה למטרת הדגמה בלבד",
    "form_updated_success": "הטופס עודכן בהצלחה",
    "form_creat_success": "טופס נוצר בהצלחה",
    "add_custom_section": "הוסף מדור מותאם אישית",
    "edit_custom_section": "ערוך מדור מותאם אישית",
    "forms_list": "רשימת טפסים",
    "filled_custom_form_details": "מילוי פרטי טופס מותאם אישית",
    "custom_form_deletion_warning": "טופס מותאם אישית זה יימחק לצמיתות",

    "menu_link": "קישור לתפריט",
    "WebSignUpT": "הרשמה אלקטרונית",
    "lifeTConT": "חיבורים לכל החיים",
    "repCustT": "לקוחות חוזרים",
    "liftTSubT": "מנויים לכל החיים",
    "overallT": "בסך הכל",
    "loginCustBDT": "לקוחות מחוברים לפי יום",
    "deviceT": "דמוגרפיה של המכשיר",
    "ageTitle": "דמוגרפיה של גיל",
    "ageT": "קבוצת גיל",
    "convert_social_media_followers_into_customers": "המרת עוקבים במדיה חברתית ללקוחות",
    "signWidDesc": "בניית ווידג'ט להרשמה, יצירת דפי נחיתה ויצירת קופונים מהאתר שלך או מהמדיה החברתית",
    "qrWidT": "QR חכם",
    "imgWidT": "הצטרפו למספר",
    "mobile": "נייד",
    "YESTitle": "כֵּן",
    "NOTitle": "לֹא",
    "addDelRed": "הוסף ניתוב משלוח",
    "dlredNameT": "תן שם לניתוב המשלוח שלך",
    "conf_text_req": "נדרש טקסט אישור",
    "cFDesc": "צור טפסים מותאמים לאיסוף משוב ומידע ספציפיים מלקוחות ביעילות",
    "pre_review_questions_enabled": "לפני שנבקש מהלקוחות לסקירה, בואו נשאל אותם 1 עד 4 שאלות.",
    "feel_free_edit": "אתה מוזמן לערוך לפי הצורך.",
    "option": "אוֹפְּצִיָה",
    "confFL": "אישור זה יוצג לאחר שאיש הקשר שלך ישלח את טופס ההרשמה לאינטרנט",
    "confT": "טקסט אישור",
    "joinTitle": "לְהִצְטַרֵף",
    "mobValidL": "נא לבדוק מספר נייד תקף",
    "mobRequiredL": "חובה מס' נייד",

    "Business_location_label": "מיקום העסק שלך",
    "qr_code_selection_txt": "בחירת קוד QR",
    "choose_theme_txt": "בחר ערכת נושא",
    "edit_qr_details_txt": "ערוך פרטי QR",
    "save_qr_code_txt": "שמור קוד QR",
    "text_color_label": "צבע טקסט",
    "background_color_label": "צבע רקע",
    "print_txt": "הֶדפֵּס",
    "customer_will_see_txt": "זה מה שהלקוח שלך יראה. בחר את צבע המותג שלך בשלב הבא.",
    "choose_your_brand_color_&_langauge_title": "בחר את צבע המותג והשפה שלך",
    "custom_redirect_link_label": "קישור להפניה מחדש מותאמת אישית",
    "redirect_user_to_menu": "הפנה מחדש את המשתמש לתפריט",
    "language": "שפה",
    "select_language_placeholder": "בחר שפה",
    "capitalize_location_name_txt": "שם מיקום",
    "created_txt": "נוצר",
    "swipe_reputation_management_txt": "החלק כדי לראות איך ייראה ניהול המוניטין שלך",
    "sent_txt": "נשלח",
    "number_label": "מִספָּר",
    "date_label": "תַאֲרִיך",
    "gender_demographics_title": "Δημογραφικά στοιχεία φύλου",

    "step1_preview_desc": "תן שם לטופס QR ההרשמה האלקטרוני שלך והוסף לרשימת הקבוצה שלך",
    "main_head": "רכשו לקוחות חדשים",
    "main_desc": "השג לקוחות על ידי הצבת קוד QR שבזמן סריקה מפנה משתמשים לטופס הרשמה מותאם אישית.",
    "step1_description": "רכישת לקוחות חדשים מעסקים אחרים",
    "form_name_field_placeholder": "הרשמה אלקטרונית רסטרו",
    "last_step_title": "מזל טוב! יישומון ההרשמה האלקטרונית שלך נוצר! 🎉",
    "last_step_desc": "החלק כדי לראות איך ייראה טופס ההרשמה האלקטרוני שלך",
    "events_step1_preview_desc": "תן שם לטופס QR לאירוע שלך והוסף לרשימת הקבוצה שלך",
    "events_main_head": "קבל לקוחות מאירועים",
    "events_main_desc": "השג לקוחות על ידי הצבת קוד QR באירוע, כאשר נסרק, מפנה משתמשים לטופס הרשמה מותאם אישית.",
    "events_step1_desc": "ללכוד לקוחות מאירועים ולהביא אותם לחנות",
    "events_name": "שם האירוע",
    "events_form_name_field_placeholder": "אירוע כיף ואוכל רסטרו",
    "events_last_step_title": "מזל טוב! טופס האירוע שלך נוצר! 🎉",
    "events_last_step_desc": "החלק כדי לראות איך ייראה טופס האירוע שלך",
    "birthday_anniversary_step1_preview_desc": "תן שם לטופס ה-QR שלך כדי לאסוף את יום הולדתו או יום השנה של הלקוח והוא יוסיף לרשימת הקבוצה שלך",
    "birthday_anniversary_main_head": "אסוף B'day או יום נישואין",
    "birthday_anniversary_main_desc": "קבל את יום ההולדת או יום השנה של הלקוח, כאשר נסרק, מפנה את המשתמשים לטופס הרשמה מותאם אישית.",
    "birthday_anniversary_step1_desc": "איסוף יום הולדת ויום נישואין של הלקוח",
    "restro_text": "רסטרו",
    "birthday_anniversary_last_step_title": "מזל טוב! קבל B'day & Anniversary מטופס לקוחות שנוצר! 🎉",
    "newspaper_ads_step1_preview_desc": "תן שם לטופס ה-QR שלך כדי להביא לקוחות ממודעות בעיתונים והוא יוסיף לרשימת הקבוצות שלך",
    "newspaper_ads_main_head": "קבל לקוחות מ-Ads",
    "newspaper_ads_main_desc": "קבל לקוחות ממודעות בעיתון, כאשר הם סרקו, זה מפנה את המשתמשים לטופס הרשמה מותאם אישית.",
    "newspaper_ads_step1_desc": "קבל לקוחות ממודעה בעיתון או מודעת חוצות",
    "newspaper_ads_last_step_title": "מזל טוב! קבל לקוחות מטופס מודעות בעיתון שנוצר! 🎉",
    "smart_pamphlets_step1_preview_desc": "תן שם לטופס ה-QR שלך כדי לקבל נתוני לקוחות מחוברות והוא יוסיף לרשימת הקבוצות שלך",
    "smart_pamphlets_main_head": "קבל לקוחות מחוברות",
    "smart_pamphlets_main_desc": "קבל את נתוני הלקוחות מחוברות, כאשר הם סרקו, זה מפנה את המשתמשים לטופס הרשמה מותאם אישית.",
    "smart_pamphlets_last_step_title": "מזל טוב! קבל את נתוני הלקוח מטופס חוברות שנוצרו! 🎉",
    "social_media_step1_preview_desc": "תן שם לטופס ה-QR שלך כדי להביא לקוחות ממדיה חברתית ואל לקוחות והוא יוסיף לרשימת הקבוצות שלך",
    "social_media_main_desc": "קבל לקוחות ממדיה חברתית, כאשר הם סרקו מפוסטים, זה מפנה משתמשים לטופס הרשמה מותאם אישית.",
    "social_media_form_name_field_placeholder": "מבצע פסטיבל רסטרו - פוסט בפייסבוק",
    "social_media_last_step_title": "מזל טוב! קבל לקוחות ליצור טופס מדיה חברתית! 🎉",
    "claim_offer_text": "מלא את הפרטים שלך וקבל את ההצעה עכשיו",
    "form_title_description": "הוסף כותרת ותיאור של טופס כדי שהלקוחות שלך יוכלו להזין את הפרטים",
    "no_subscribed_clients": "לקבוצת הלקוחות שנבחרה אין לקוחות מנויים",
    "welcome_message_offer": "שלח הודעת קבלת פנים ללקוחות שלך, לאחר שהם נרשמו ותן להם קופון הצעה",
    "system_grp": "קבוצות מערכת",
    "import_clients": "ייבוא ​​לקוחות",
    "delivery_redirection_name": "שם הפניית משלוח",
    "created_at": "נוצר ב-At",
    "provide_stamp_user": "ספק חותמת למשתמש",
    "scan_qr_code": "סרוק קוד QR",
    "add_point": "הוסף נקודה",
    "total_points": "סך נקודות",
    "user_profile": "פרופיל משתמש",
    "stamp_history": "היסטוריית בול",
    "reward_txt": "גְמוּל",
    "variant_placeholder": "קטן, בינוני, גדול וכו'",
    "sent_coupon": "נשלח קופון",
    "order_status": "סטטוס הזמנה",
    "payment_status": "מצב תשלום",
    "order_currency": "מטבע ההזמנה",
    "card_data": "נתוני כרטיס",
    "reward_expiring_on": "הפרס יפוג בתאריך",
    "reward_status": "סטטוס תגמול",
    "reward_get_date": "תגמול קבל תאריך",
    "reward_name": "שם פרס",
    "add_point_type": "הוסף סוג נקודה",
    "restaurant_location": "מיקום המסעדה",
    "collect_points": "איסוף נקודות",
    "rewards_expire": "תוקף התגמולים יפוג",
    "rewards_redeem": "תגמולים לממש",
    "total_rewards": "סך התגמולים",
    "created_by": "נוצר על ידי",
    "anniversary": "יוֹם הַשָׁנָה",
    "remove_user_group_txt": "הסר משתמש מהקבוצה",
    "delete_permanently_grp_msg": "לקוח זה יימחק לצמיתות מהקבוצה.",
    "delete_multiple_permanently_grp_msg": "לקוח זה יימחק לצמיתות מהקבוצה.",
    "delete_multiple_client": "לקוח זה יימחק לצמיתות.",
    "messages": "הודעות",
    "management": "הַנהָלָה",
    "client": "לָקוּחַ",
    "country": "מְדִינָה",
    "state": "מְדִינָה",
    "city": "עִיר",
    "zip_code": "מיקוד",
    "1_sparkly_point_txt": "🎉 הרווחת נקודה נוצצת אחת! 🌟",
    "select_country_txt": "בחר מדינה",
    "select_state": "בחר מצב",
    "ls_thanks_for_join": "תודה שהצטרפת לכרטיס ניקוב הנאמנות שלנו.🎁🎈",
    "month": "חוֹדֶשׁ",
    "welcome_message": "שלח הודעת קבלת פנים ללקוחות שלך, לאחר שהם נרשמים",
    "all_rewards_txt": "כל התגמולים",
    "active_rewards": "תגמולים פעילים",
    "expired_and_redeemed": "פג תוקף & מומש",
    "redirect_3rd_party_delivery": "הפנה מחדש משלוח של צד שלישי לחנות שלך או לפלטפורמת ההזמנה המקוונת שלך",
    "search_or_start_new": "חפש או התחל חדש",
    "contacts": "אנשי קשר",
    "chats": "צ'אטים",
    "add_by_restaurant": "הוסף לפי מסעדה",
    "using_scan_code": "שימוש בקוד סריקה",
    "step": "שָׁלָב",
    "list_widget_text": "רשימת ווידג'טים",
    "swipe_free_wifi_txt": "החלק עבור wifi בחינם",
    "create_widget_txt": "צור ווידג'ט",
    "redeem_award_msg": "אתה בטוח? האם אתה רוצה לממש את הפרס הזה",
    "on_collect_msg": "על איסוף",
    "pause": "הַפסָקָה",
    "resume": "קוֹרוֹת חַיִים",
    "location_added": "מיקום נוסף",
    "sub_user_admin_permissions_txt": "אפשר למשתמש משנה לגשת להרשאות מנהל בשילוט דיגיטלי",
    "unlist": "בטל רשימה",
    "cannot_delete_default_terms": "לא ניתן למחוק את תנאי ברירת המחדל",
    "something_went_wrong": "משהו השתבש!",
    "card_download_success": "הכרטיס הורד בהצלחה",
    "permission_denied": "הרשות נדחתה!",
    "invalid_qr_code": "אנא סרוק קוד QR חוקי",
    "select_customer": "אנא בחר לקוח",
    "online": "אונליין",
    "offline": "אופליין",
    "no_data": "אופס, לא נמצאו נתונים!",
    "forever_free": "לנצח חינם",
};

export default he